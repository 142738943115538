<template>
  <div
    v-click-outside="onClickOutside"
    :class="[
      $style.context_popup,
      'context_popup',
    ]"
    :style="{
      top: coordinates.top,
      bottom: coordinates.bottom,
      left: coordinates.left,
      right: coordinates.right,
      width: items.length ? width : 'auto',
      height: coordinates.height,
    }"
    @wheel.stop
  >
    <div
      v-if="items.length"
      :class="[$style.context_popup_items]"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <div
          :id="item.gpTestId"
          :class="[$style.context_popup_item_wrap]"
          @click.stop="selectItem(item)"
        >
          <div
            :class="[
              $style.context_popup_item, $style[size],
              item.disable ? $style.disable : '',
              item.feature ? item.pricingAccess ? 'tooltip-gantt' : 'pricing-tooltip' : ''
            ]"
            :data-feature="item.feature"
            :data-key="item.id === 'select_multiple' && item.disable ? locale('select_multiple_tooltip') : ''"
            data-x="10"
            data-y="-6"
            data-position="right"
          >
            <div :class="$style.context_menu_list_item">
              <div :class="$style.context_menu_list_item_left_part">
                <div
                  v-if="item.icon"
                  :class="$style.context_menu_list_item_icon"
                >
                  <svg-sprite
                    :style="{color: item.iconColor ? item.iconColor : ''}"
                    :name="item.icon"
                    :type="item.iconType"
                    :size="item.iconSize"
                  />
                </div>
                <div
                  :class="$style.context_menu_list_item_text"
                  :style="{color: item.labelColor ? item.labelColor : ''}"
                >
                  {{ locale(item.name) }}
                </div>
                <div
                  v-if="item.planName && !item.pricingAccess"
                  :class="$style.plan_title"
                >
                  {{ locale(item.planName) }}
                </div>
              </div>

              <div
                v-if="item.iconRight"
                :class="[$style.context_menu_list_item_icon, $style.right]"
              >
                <svg-sprite
                  :style="{color: item.iconRightColor ? item.iconRightColor : ''}"
                  :name="item.iconRight"
                  :type="item.iconRightType"
                  :size="item.iconRightSize"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot
      :class="[$style.context_popup_body]"
      name="body"
    />
  </div>
</template>

<script>

import _ from 'lodash';
import svgSprite from '../../VueIcon/svgSprite.vue';

export default {
  name: 'ContextMenu',
  components: {
    svgSprite,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    position: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    side: { type: String, required: false, default: '' },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator(value) {
        return ['medium', 'large'].includes(value);
      },
    },
    width: { type: String, required: false, default: 'auto' },
    height: { type: Number, default: null },
    horizontallyAlignCenter: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Object,
      default: () => ({
        top: null,
        right: null,
        bottom: null,
        left: null,
      }),
    },
  },
  data() {
    return {
      locale: __,
      coordinates: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
        height: 'auto',
      },
    };
  },
  computed: {
  },
  watch: {
    position() {
      this.getCoordinates();
    },
    height() {
      this.getCoordinates();
    },
  },
  mounted() {
    window.addEventListener('wheel', _.debounce(this.onScroll, 50));
    this.position && this.getCoordinates();
  },
  beforeDestroy() {
    window.removeEventListener('wheel', _.debounce(this.onScroll, 0));
  },
  methods: {
    selectItem(item) {
      if (item.disable) return;
      if (item.feature && !item.pricingAccess) return;
      this.$emit('selectItemMenu', item.id);
      this.$emit('closePopup', true);
    },
    onScroll() {
      this.$emit('closePopup', true);
      this.$emit('scroll', true);
    },
    onClickOutside(e) {
      this.$emit('onClickOutside', e);
      this.$emit('closePopup', true);
    },
    getCoordinates() {
      const coordinates = this.position;
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const elementWidth = this.$el.getBoundingClientRect().width;
      const elementHeight = this.height || this.$el.getBoundingClientRect().height;

      if (this.side && this.side === 'right') {
        this.dropToBottom = (coordinates.top + elementHeight) < clientHeight;
        if (this.dropToBottom) {
          this.coordinates = {
            top: `${coordinates.top}px`,
            left: `${coordinates.left + coordinates.width}px`,
          };
        } else {
          this.coordinates = {
            top: `${coordinates.top - elementHeight + coordinates.height + 8}px`,
            left: `${coordinates.left + coordinates.width}px`,
          };
        }
      } else if (this.side && this.side === 'left') {
        this.coordinates = {
          top: `${coordinates.top}px`,
          right: `${clientWidth - coordinates.left}px`,
        };
      } else {
        this.dropToBottom = (coordinates.bottom + elementHeight) < clientHeight || elementHeight >= clientHeight;
        const alignedRightSide = (clientWidth - coordinates.left - 10) < elementWidth;
        const left = `${coordinates.left - elementWidth + coordinates.width}px`;

        // 4 = indent between element and context menu (not one by one, need a bit space by design)
        if (this.dropToBottom) {
          this.coordinates = {
            top: `${coordinates.bottom + 4}px`,
            left: alignedRightSide ? left : `${coordinates.left}px`,
            height: elementHeight >= clientHeight ? `${clientHeight - 80}px` : 'auto',
          };
        } else {
          this.coordinates = {
            top: `${coordinates.top - elementHeight - 4}px`,
            left: alignedRightSide ? left : `${coordinates.left}px`,
          };
        }
      }

      if (this.horizontallyAlignCenter) {
        let centerPosition = ((coordinates.right + coordinates.left) / 2) - (elementWidth / 2);
        const isCenterPositionLargerThanWindowWidth = centerPosition + elementWidth >= window.innerWidth;

        if (isCenterPositionLargerThanWindowWidth) {
          centerPosition = window.innerWidth - elementWidth - 5;
        }

        this.coordinates = {
          ...this.coordinates,
          left: `${centerPosition}px`,
        };
      }

      if (this.offset.top) {
        const currentTopPosition = typeof this.coordinates.top === 'string'
          ? parseFloat(this.coordinates.top)
          : this.coordinates.top;

        this.coordinates = {
          ...this.coordinates,
          top: `${currentTopPosition + Number(this.offset.top)}px`,
        };
      }

      if (this.offset.right) {
        const currentLeftPosition = typeof this.coordinates.left === 'string'
          ? parseFloat(this.coordinates.left)
          : this.coordinates.left;

        this.coordinates = {
          ...this.coordinates,
          left: `${currentLeftPosition + Number(this.offset.right)}px`,
        };
      }
    },
  },
};
</script>

<style module src='./contextMenuPopup.less'></style>
