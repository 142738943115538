import constants from './constants';
import app from '../app';

const innerObject = {
  debug: (window.location.host !== 'app.ganttpro.com'),
  setup: {},
  open(setup) {
    if (window.Calendly) {
      innerObject.setup = setup;
      innerObject.setup.source = window.location.host;
      let userJobTitleTranslated = '';
      let userTeamSizeTranslated = '';

      constants.JOB_TITLES.map(obj => {
        if (Object.keys(obj)[0] === `${user.settings.user_job_title}`) {
          userJobTitleTranslated = __(obj[Object.keys(obj)[0]]);
        }
      });
      constants.TEAM_SIZES.map(obj => {
        // console.log(Object.keys(obj)[0], user.settings.user_team, __(obj[Object.keys(obj)[0]]))
        if (Object.keys(obj)[0] === `${user.settings.user_team}`) {
          userTeamSizeTranslated = __(obj[Object.keys(obj)[0]]);
        }
      });
      // ?utm-source=email&utm_campaign=d1

      const calendlyConfig = {
        url: window.user.locale === 'ru'
          ? 'https://calendly.com/d/4sv-h8t-4b4/meet-with-me' 
          : 'https://calendly.com/d/2wz-pqc-m8y/schedule-a-meeting',
        utm: {
          utmCampaign: innerObject.setup.campaign,
          utmSource: innerObject.setup.source,
          // utmMedium: "Ad",
          // utmContent: "Shoe and Shirts",
          // utmTerm: "Spring"
        },
        prefill: {
          name: `${user.firstName} ${user.lastName}`,
          email: `${user.email}`,
          phone: `${user.settings.user_phone || ''}`,
          customAnswers: {
            a1: user.settings.user_company,
            a2: userTeamSizeTranslated,
            a3: userJobTitleTranslated,
            // a3: `${user.settings.user_phone || ''}`,
            a4: '', // Что бы вы хотели обсудить на звонке? (оставить пустым)
            // a5: '', // Каким инструментом вы пользуетесь сейчас? (оставить пустым)
            // a6: userTeamSizeTranslated,
          },
        },
      };

      window.Calendly.initPopupWidget(calendlyConfig);
      // console.log('calendlyConfig==>',calendlyConfig)
    }
  },
  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  },
};

window.addEventListener('message', e => {
  // console.log(e)
  if (innerObject.isCalendlyEvent(e)) {
    userExtAnalytics.log(e.data.event, innerObject.setup);
  }
});

// open demo popup immidiately if url contains "democampaign"
// mostly we do this for demo scheduling from emails
// example https://app.ganttpro.com/?democampaign=appFirstEmailInlineLink
// Replace setImmediate with setTimeout
setTimeout(() => {
  app.on('app:initModels', () => {
    const URLKEY = 'democampaign';
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has(URLKEY)) {
      innerObject.open({
        campaign: searchParams.get(URLKEY),
      });
      document.querySelector('.calendly-popup-close').addEventListener('click', () => {
        window.history.pushState({}, null, `/${window.location.hash}`);
      });
    }
  });
}, 10); // Delay of 10 ms to mimic setImmediate

export default innerObject;
