var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.role_popup,
    },
    [
      _vm.currentRoleTitle
        ? _c(
            "div",
            {
              ref: "button",
              class: _vm.$style.button,
              on: {
                click: _vm.open,
                mouseenter: _vm.open,
                mouseleave: _vm.close,
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.currentRoleTitle) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPopup
        ? _c("context-menu", {
            class: _vm.$style.context_popup,
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { class: _vm.$style.popup }, [
                        _c("div", { class: _vm.$style.popup_head }, [
                          _c("div", { class: _vm.$style.popup_title }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentRoleTitle) +
                                "\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$style.popup_body },
                          [
                            _vm.isMultiview
                              ? [
                                  _c("p", { class: _vm.$style.popup_text }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales(
                                            "role_popup_text_multiview"
                                          )
                                        ) +
                                        ":\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { class: _vm.$style.projects },
                                    _vm._l(_vm.listProjects, function (item) {
                                      return _c("li", { key: item.id }, [
                                        _c(
                                          "div",
                                          { class: _vm.$style.project_name },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.name) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { class: _vm.$style.project_role },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.role) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              : [
                                  _c("p", { class: _vm.$style.popup_text }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales("role_popup_text_project")
                                        ) +
                                        ":\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.listRights, function (item) {
                                      return _c("li", { key: item.id }, [
                                        _c("div", [_vm._v(_vm._s(item.title))]),
                                      ])
                                    }),
                                    0
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2544635469
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }