<template>
  <div v-if="webixView">
    <p>Sample</p>
    <button v-on:click="count++">Inc Vue button</button>
    <p>Vue value: {{this.count}}</p>
    <webix-ui 
      :key="count" 
      :config="webixView(count)"
      v-on:inc-count="count++"
    />
  </div> 
</template>

<script>
import webixView from "./webixView";

export default {
    props: [],
    data() {
      return {
        count: 0,
        webixView: webixView
      }
    }
}
</script>

<style scoped>

</style>