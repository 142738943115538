<template>
  <div :class="[$style.filter_body, 'gantt_scroll']">
    <div
      v-for="(item) in fields"
      :key="item.id + '_filter_'+ item.property + componentKey"
    >
      <text-input
        v-if="item.type === 'search-input' "
        :gp-autotest-name="'gp_autotest_global_filter_option_' + item.property"
        :label="item.label"
        :placeholder="item.placeholder"
        :value="values[item.property]"
        :with-search-icon="true"
        @input="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />
      <multiselect
        v-if="item.type === 'multiselect' "
        :gp-autotest-name="'gp_autotest_global_filter_option_' + item.property"
        :label="item.label"
        :drop-items="item.data"
        :drop-template="item.itemTemplate"
        :placeholder="item.placeholder"
        :selected="values[item.property]"
        @check="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />

      <search-multiselect
        v-if="item.type === 'search-multiselect' "
        :gp-autotest-name="'gp_autotest_global_filter_option_' + item.property"
        :label="item.label"
        :drop-items="item.data"
        :drop-template="item.itemTemplate"
        :placeholder="item.placeholder"
        :group-by="'group'"
        :selected="values[item.property]"
        @check="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />

      <date-range-input
        v-if="item.type === 'datepicker' "
        :gp-autotest-name="'gp_autotest_global_filter_option_' + item.property"
        :label="item.label"
        :placeholder="item.placeholder"
        :range-only="item.rangeOnly"
        :value="values[item.property]"
        :property="item.property"
        @change="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />
      <toggle-field
        v-if="item.type === 'toggle' && checkPricingAccess('overdue_tasks')"
        :label="item.label"
        :tooltip="item.tooltip"
        :is-toggle="!!values[item.property]"
        @toggle="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />
      <from-to-time
        v-if="item.type === 'from-to' "
        :gp-autotest-name="'gp_autotest_global_filter_option_' + item.property"
        :label="item.label"
        :value="values[item.property]"
        @change="changeProperty(item.property, $event)"
        @reset="changeProperty(item.property, $event)"
      />
    </div>

    <template v-if="custom && custom.length && accessToColumns">
      <div :class="[$style.delimeter]" />
      <div
        v-for="(item, index) in custom"
        :key="'filter_custom_'+ index + componentKey"
      >
        <from-to-input
          v-if="item.type === 'from-to-date' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :type="'date'"
          :value="getCustomValues(item.id)"
          @change="changeCustomProperty(item.id, $event, 'from-to-date')"
          @reset="resetCustomCols(item.id)"
        />
        <from-to-input
          v-if="item.type === 'from-to-number' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :type="'number'"
          :value="getCustomValues(item.id)"
          @change="changeCustomProperty(item.id, $event, 'from-to-number')"
          @reset="resetCustomCols(item.id)"
        />
        <text-input
          v-if="item.type === 'text-input' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :placeholder="locales.search_placeholder"
          :value="getCustomValues(item.id)"
          :with-search-icon="false"
          @change="changeCustomProperty(item.id, $event)"
          @reset="resetCustomCols(item.id)"
        />
        <multiselect
          v-if="item.type === 'multiselect' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :drop-items="item.data"
          :drop-template="item.itemTemplate"
          :placeholder="locales.all_placeholder"
          :selected="getCustomValues(item.id)"
          @check="changeCustomProperty(item.id, $event)"
          @reset="resetCustomCols(item.id)"
        />

        <search-multiselect
          v-if="item.type === 'search-multiselect' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :drop-items="item.data"
          :drop-template="item.itemTemplate"
          :placeholder="locales.all_placeholder"
          :group-by="'group'"
          :selected="getCustomValues(item.id)"
          @check="changeCustomProperty(item.id, $event)"
          @reset="resetCustomCols(item.id)"
        />
        <single-select
          v-if="item.type === 'single-select' "
          :gp-autotest-name="'gp_autotest_global_filter_custom_option_' + item.id"
          :label="item.label"
          :drop-items="item.data"
          :drop-template="item.itemTemplate"
          :placeholder="locales.all_placeholder"
          :group-by="'group'"
          :selected="getCustomValues(item.id)"
          @check="changeCustomProperty(item.id, $event)"
          @reset="resetCustomCols(item.id)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import multiselect from '../inputs/multiselect.vue';
import searchMultiselect from '../inputs/searchMultiselect.vue';
import singleSelect from '../inputs/singleSelect.vue';
import dateRangeInput from '../inputs/dateInput/dateRangeInput.vue';
import textInput from '../inputs/textInput.vue';
import toggleField from './toggleField.vue';
import fromToTime from '../inputs/fromToTime.vue';
import fromToInput from '../inputs/fromToInput.vue';
import _ from '../../../libs/lodash';
import pricingHelpers from '../../../helpers/pricingHelper';
import pricingHelper from '../../../helpers/pricingHelper';

export default {
  name: 'BodyFilter',
  components: {
    multiselect, searchMultiselect, dateRangeInput, textInput, toggleField, fromToTime, fromToInput, singleSelect,
  },
  props: {
    fields: { type: Array, required: true, default() { return []; } },
    values: { type: Object, required: true },
    custom: { type: Array, required: false, default() { return []; } },
  },
  data() {
    return {
      locales: {
        search_placeholder: __('filter_text_type_custom_column'),
        all_placeholder: __('filter_select_type_custom_column'),
      },
      valueState: null,
    };
  },

  computed: {
    componentKey() {
      const min = 1000; const
        max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
    accessToColumns() {
      return pricingHelper.checkPricingAccess('custom_fields');
    },
  },

  watch: {
    values(val, oldVal) {
      this.valueState = _.clone(val);
    },
  },

  mounted() {
    this.valueState = _.clone(this.values);
  },

  methods: {
    checkPricingAccess(feature) {
      return pricingHelpers.checkPricingAccess(feature);
    },
    getCustomValues(id) {
      if (!this.values.customColumns) return null;

      return this.values.customColumns[+id];
    },
    changeProperty(property, newValue) {
      this.valueState[property] = newValue;
      this.$emit('change', this.valueState);
    },
    changeCustomProperty(property, val, component) {
      if (!this.valueState.customColumns) {
        this.valueState.customColumns = {};
      }
      this.valueState.customColumns[property] = val;
      this.$emit('change', this.valueState);
    },
    resetCustomCols(property) {
      if (this.valueState.customColumns) {
        this.valueState.customColumns[property] = '';
        delete this.valueState.customColumns[property];

        if (!Object.keys(this.valueState.customColumns).length) {
          delete this.valueState.customColumns;
        }
      }

      this.$emit('change', this.valueState);
    },
  },
};
</script>

<style module src="../filter.less" lang="less" />
