<template>
  <div
    class="custom-checkbox"
    @click="checkedHandler"
  >
    <button
      class="btn-checkbox"
      :class="{'chacked': shouldBeChecked && !block , 'disabled': block }"
    />
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckBox',
  props: {
    value: { type: Array, required: true },
    vModel: { type: Array, required: true },
    block: { type: Boolean, required: false, default: false },
  },
  data() {
    return { };
  },
  computed: {
    shouldBeChecked() {
      if (this.vModel instanceof Array) {
        return this.vModel.includes(this.value);
      }

      return false;
    },
  },
  methods: {
    checkedHandler() {
      if (this.block) return;

      const isChecked = this.shouldBeChecked;

      const newValue = [...this.vModel];

      if (!isChecked) {
        newValue.push(this.value);
      } else {
        newValue.splice(newValue.indexOf(this.value), 1);
      }

      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="less" scoped>
.custom-checkbox{
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  display: inline-flex;
}
.btn-checkbox{
    margin-right: 10px;
    display: inline-block;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #9e9e9e;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;

    &.chacked {
      border-color: #247ECC;
      background: #247ECC url(https://cdn.ganttpro.com/app/imgs/webix_material/check_white.svg) center no-repeat;
      background-size: 12px;
    }

    &.disabled{
      border: 1px solid #d9d9d9;
      cursor: auto !important;
    }
}
</style>
