import SharedUrlPassword from './sharedUrlPassword.vue';

export default Vue => {
  setTimeout(() => {
    document.getElementById('preLoaderWrap').style.opacity = 0;
    document.getElementById('preLoaderWrap').pointerEvents = 'none';
    setTimeout(() => {
      document.getElementById('preLoaderWrap').remove();
    }, 600);
  }, 500);
  new Vue({

    el: '#vue-container',
    components: {
      'shared-url-password': SharedUrlPassword,
    },
    render(createElement) {
      return createElement(SharedUrlPassword);
    },
  });
};
