var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vgp-core-popup", {
    attrs: {
      show: _vm.showBudgetSettings,
      title: _vm.locale("budget_field_editor_title"),
      closeIcon: true,
      buttonsSwitch: true,
      okButtonTitle: _vm.locale("common_apply"),
      footerStyle: "flex-end",
    },
    on: {
      onClickCancel: _vm.toggleBudgetPopup,
      onClickOk: _vm.applyBudgetSettings,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { class: _vm.$.currency_popup_wrapper },
              [
                _c("inline-notification", {
                  attrs: {
                    type: "warning",
                    text: _vm.locale("warning_all_projects_setting"),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$.field },
                  [
                    _c("div", { class: _vm.$.label }, [
                      _vm._v(_vm._s(_vm.locale("budget_currency"))),
                    ]),
                    _vm._v(" "),
                    _c("toggle", {
                      attrs: { isToggleOn: _vm.enableCurrency },
                      on: { onChange: _vm.currencySwitch },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: [_vm.$.field, _vm.$.select] },
                  [
                    _c("div", { class: _vm.$.label }, [
                      _vm._v(_vm._s(_vm.locale("budget_currency_sign"))),
                    ]),
                    _vm._v(" "),
                    _c("dropdown-select", {
                      attrs: {
                        multiselect: false,
                        showResetBtn: false,
                        disabled: !_vm.enableCurrency,
                        dropList: _vm.currencies,
                        selected: [_vm.currentCurrency],
                      },
                      on: { selected: _vm.setSelected },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }