var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-profile" },
    [
      _c("div", { staticClass: "settings-section personal-section" }, [
        _c("div", { staticClass: "user-info" }, [
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.locales("settings_profile_personal_title")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "edit-area" },
            [
              _c(
                "div",
                { staticClass: "fullname" },
                [
                  _c("input-with-label", {
                    attrs: {
                      id: "firstName",
                      css: "profile-settings-input margin-right",
                      label: _vm.locales("profile_first_name"),
                      placeholder: _vm.locales(
                        "profile_first_name_placeholder"
                      ),
                      value: _vm.firstName,
                      type: "text",
                      "handle-change": _vm.changeFirstName,
                    },
                  }),
                  _vm._v(" "),
                  _c("input-with-label", {
                    attrs: {
                      id: "lastName",
                      css: "profile-settings-input",
                      label: _vm.locales("profile_last_name"),
                      placeholder: _vm.locales("profile_last_name_placeholder"),
                      value: _vm.lastName,
                      type: "text",
                      "handle-change": _vm.changeLastName,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("input-with-label", {
                attrs: {
                  css: "profile-settings-input",
                  label: _vm.locales("admin_users_email"),
                  placeholder: _vm.locales("profile_password"),
                  value: _vm.email,
                  "is-disable": true,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "delete-profile-btn",
                  attrs: { id: "gp_autotest_delete_account_button" },
                  on: { click: _vm.deleteProfile },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("profile_cancel_account")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user-photo", on: { click: _vm.showAvaUploader } },
          [
            _c("img", {
              staticClass: "ava",
              attrs: { src: _vm.userAva, alt: "avatar" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "overlay" }, [
              _c("img", {
                staticClass: "camera",
                attrs: { src: _vm.cameraIcon, alt: "camera" },
              }),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("delimiter"),
      _vm._v(" "),
      _c("password-change-section", { staticClass: "settings-section" }),
      _vm._v(" "),
      _c("delimiter"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-section workspace-section" },
        [
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locales("settings_profile_workspace_title")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: _vm.locales("user_menu_lang"),
              items: _vm.languageOptions,
              "current-item": _vm.userLocale,
              "target-value": "value",
              "select-handler": _vm.changeLanguage,
              styles: { marginBottom: 12 + "px" },
              width: 244,
              position: "bottom",
            },
          }),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: _vm.locales("user_menu_dateformat"),
              items: _vm.dateOptions,
              "current-item": _vm.userCurrentDate,
              "target-value": "value",
              "select-handler": _vm.changeDateFormat,
              styles: { marginBottom: 18 + "px" },
              width: 244,
              position: "bottom",
            },
          }),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: _vm.locales("user_menu_numberformat"),
              items: _vm.numberFormats,
              "current-item": _vm.userNumberFormat,
              "target-value": "value",
              "select-handler": _vm.changeNumberFormat,
              styles: { marginBottom: 18 + "px" },
              width: 244,
              position: "bottom",
              "input-description": _vm.locales(
                "numberformat_input_description"
              ),
            },
          }),
          _vm._v(" "),
          _c("setting-row", {
            attrs: {
              "is-toggle-on": _vm.userTimeFormat,
              "toggle-handler": _vm.toggleTimeFormat,
              label: _vm.locales("common_24_time_format"),
            },
          }),
          _vm._v(" "),
          _c("setting-row", {
            attrs: {
              "is-toggle-on": _vm.isStartOnMonday,
              "toggle-handler": _vm.toggleMonday,
              label: _vm.locales("settings_start_on_monday"),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("image-uploader", {
        attrs: {
          show: _vm.isShowAvatarUploader,
          title: _vm.locales("update_profile_photo_title"),
          type: "avatar",
        },
        on: {
          onClose: function ($event) {
            _vm.isShowAvatarUploader = false
          },
          onApply: _vm.onApplyUploadAvatar,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }