import projectComponent from '../../Project';
import app from '../../../app';
import globalStore from "$$store/main";

function onProjectDeleted({ event, data: { project: { projectId } } }) {
  projectComponent.manualRemoveTeamProject(parseInt(projectId, 10));
  globalStore.commit('resourcesModel/removeAllResourcesFromProject', { projectId });

  gantt.callEvent('recalculateWorkload');

  app.trigger('onAfterCollaboration', {
    event,
    projects: [projectId],
  });
}
export default onProjectDeleted;
