<template>
  <div
    :class="[$style['task-list-main']]"
  >
    <div
      v-if="tasks.length || taskId"
      :class="[$style['task-list-header']]"
      :style="{minWidth: '275px'}"
    >
      <div :class="[$style['task-list-header_label']]">
        {{ locales('comment_hub_tasks_label') }}
      </div>

      <tooltip
        v-if="newComments"
        :class="[$style['task-list-header_icon'], 'tooltip-wrapper', 'gp_autotest_comment_hub_read_all_tooltip']"
        :content="locales('comment-hub-read-all')"
      >
        <template #body>
          <div
            :class="[$style['icon-hover']]"
            @click="readAllComments"
          >
            <svg-sprite
              name="email"
              :size="24"
            />
          </div>
        </template>
      </tooltip>
    </div>

    <recycle-scroller
      v-show="tasks.length"
      ref="tasksList"
      :class="[$style['suggestions-list'], 'gantt_scroll', needScroll ? '' : $style['suggestions-list-noScroll'], 'gp_autotest_comment_hub_tasks_list' ]"
      :style="{height : height + 'px'}"
      :items="tasks"
      :min-item-size="84"
      key-field="id"
    >
      <template #default="{ item }">
        <div
          @click="$emit('selectItem', item)"
        >
          <task-item
            :id="`gp_autotest_comment_hub_task_item_${item.id}`"
            :class="[taskId === item.id ? $style['task-item-selected'] : '']"
            :task-name="item.text"
            :task-id="item.id"
            :parent-path="parentPaths[item.id]"
            :counter="unreadCommentsCount(item.id)"
            :mention="mention(item.id)"
            :hide-tooltip="hideTooltip"
          />
          <div
            :class="[$style['delimiter']]"
          />
        </div>
      </template>
    </recycle-scroller>

    <div
      v-if="!tasks.length && taskId"
      :class="[$style['no-filtered-task-stub'], 'gp_autotest_comment_hub_no_filter_task_stub']"
      :style="{height : height + 'px'}"
    >
      <svgImage
        :name="currentStub.stubSvg"
        :class="[$style['current-stub']]"
      />
      <div
        :class="[$style['no-search-title']]"
      >
        {{
          locales(currentStub.stubTitle) }}
      </div>
      <div
        :class="[$style['no-search-text']]"
      >
        {{ locales(currentStub.stubText) }}
      </div>
    </div>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller';
import taskItem from './taskItem.vue';
import tooltip from '../../common/VueComponents/tooltips/tooltip.vue';
import svgImage from '../../common/VueComponents/VueIcon/vgpSvgImage.vue';

export default {
  name: 'TasksList',
  components: {
    svgImage,
    RecycleScroller,
    taskItem,
    tooltip,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: [],
    },
    // comments: {
    //   type: Object,
    //   required: false,
    //   default: {},
    // },
    // commentsActions: {
    //   type: Object,
    //   required: false,
    //   default: {},
    // },
    taskId: {
      type: String,
      required: false,
      default: '',
    },
    currentStub: {
      type: Object,
      required: false,
      default: {},
    },
    newComments: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scrollTop: {
      type: Boolean,
      required: false,
    },
    parentPaths: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      locales: __,
      hideTooltip: false,
      timerId: null,
    };
  },
  computed: {

    needScroll() {
      return this.tasks.length * 84 > this.height;
    },

  },
  watch: {
    scrollTop(val) {
      val && this.scrollToTop();
    },
  },
  mounted() {
    this.$refs.tasksList.$el.addEventListener('scroll', this.onScroll);
    this.$refs.tasksList.$el.addEventListener('scrollend', this.showTooltip);
  },

  beforeDestroy() {
    this.$refs.tasksList.$el.removeEventListener('scroll', this.onScroll);
    this.$refs.tasksList.$el.removeEventListener('scrollend', this.showTooltip);
  },
  methods: {
    unreadCommentsCount(taskId) {
      // let counter = 0;

      // this.comments[taskId]?.forEach(item => {
      //   if (this.commentsActions[item.id]?.read === 0) {
      //     counter++;
      //   }
      // });

      // return counter;

      return this.$store.state.comments.taskCommentsMetaData[taskId]?.unreadComments.size;
    },
    mention(taskId) {
      // let mention = false;

      // this.comments[taskId]?.some(item => {
      //   if (this.commentsActions[item.id]?.read === 0) {
      //     const mentions = item.content.match(/\[\~(\d+)\]/g);
      //     const ids = mentions ? mentions?.map(mention => parseInt(mention.match(/\d+/)[0], 10)) : [];

      //     if (ids.length && ids.includes(user.id)) {
      //       mention = true;
      //     }
      //   }
      // });

      // return mention;

      return !!this.$store.state.comments.taskCommentsMetaData[taskId]?.unreadMentions.size;
    },
    readAllComments() {
      this.$emit('readAllComments', true);
    },
    scrollToTop() {
      this.$refs.tasksList.scrollToPosition(0);
    },
    onScroll(e) {
      this.hideTooltip = true;
    },
    showTooltip() {
      this.hideTooltip = false;
    },
  },
};
</script>
<style module src="../less/commentsHub.less" lang="less"></style>
