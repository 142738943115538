<template>
  <div>
    <core-popup
      :show="show"
      :close-icon="true"
      :width="540"
      :title="locale(`${integrationName}_link_Generated`)"
      @onClickCancel="() => $emit('closePopup')"
    >
      <template #body>
        <vgp-label-slot
          :label="locale('integration_subscription_url_label')"
        >
          <template #body>
            <div
              :class="$.subscription_url_block"
            >
              <vgp-input
                :class="$.subscription_url_input"
                :value="`${generatedLink}${link.hash}`"
                class="input-area"
                :select-by-focus="true"
                :readonly="true"
                :border="false"
                @onClick="onClickLink"
              />
              <div :class="[$.integration_icon_item, $.integration_icon_item_link]">
                <svg-sprite
                  :name="'link-1'"
                  :color="'#1565C0'"
                  @click="onClickLink"
                />
              </div>
            </div>
            <div :class="$.instruction_container">
              <div
                :class="$.instruction_image"
                :style="{backgroundImage:`url(https://cdn.ganttpro.com/app/imgs/integration/${integrationName}-calendar/${integrationName}-calendar-integration-connection.png)`}"
              />

              <div :class="$.instruction_text">
                <div :class="$.instruction_steps_label">
                  {{ locale('instructions_title') }}
                </div>
                <div :class="$.instruction_steps">
                  <div
                    v-for="step in instructionSteps"
                    :class="$.instruction_step"
                  >
                    <svg-sprite
                      :name="'status-2'"
                      :size="16"
                      :color="'#191919'"
                    />
                    <div v-html="locale(step)" />
                  </div>
                </div>
              </div>
            </div>
            <div :class="$.instruction_description">
              {{ locale(`${integrationName}_instructions_description`) }}
            </div>
            <div :class="$.instruction_learn_more">
              <a
                :href="locale(`${integrationName}_integration_learn_more`)"
                target="_blank"
              >{{ locale(`${integrationName}_integration_learn_more_info`) }}</a>
            </div>
          </template>
        </vgp-label-slot>
      </template>
    </core-popup>
  </div>
</template>

<script>

import vgpLabelSlot from '../../common/VueComponents/label/vgpLabelSlot.vue';
import CorePopup from '../../common/VueComponents/popups/corePopup/corePopup.vue';

export default {
  name: 'IntegrationGeneratedLink',
  components: {
    CorePopup,
    vgpLabelSlot,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
    integrationName: {
      type: String,
      required: false,
      default: '',
    },

  },
  data() {
    return {
      locale: __,
      instructionSteps: [
        `${this.integrationName}_link_instruction_step_1`,
        `${this.integrationName}_link_instruction_step_2`,
        `${this.integrationName}_link_instruction_step_3`,
        `${this.integrationName}_link_instruction_step_4`,
      ],
      generatedLink: null,
    };
  },
  computed: {

  },
  created() {
    const root = `${window.location.protocol}//${window.location.host}`;

    this.generatedLink = `${root}/api/integrations/calendar/${this.integrationName}/`;
  },
  mounted() {

  },
  methods: {

    async onClickLink() {
      try {
        const linkUrl = `${this.generatedLink}${this.link.hash}`;

        navigator.clipboard.writeText(linkUrl);
        this.$toast.success(this.locale('integration_link_copied'));
        userExtAnalytics.log(`integration_${this.integrationName}_calendar_link_copied`, { type: 'popup' });
      } catch (e) {
        console.error('Failed to copy task: ', e);
      }
    },

  },
};
</script>

<style module="$" src="../googleOutlookIntegrations/googleOutlookIntegration.less"></style>
