import projectComponent from '../../Project';
import projectsModel from '../../../models/projects';
import helpers from '../helpers';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';
import globalStore from '../../../store/main';
import app from '../../../app';

function onProjectUpdated(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();

  event.data.updated.forEach(item => {
    const isSameProjectOpen = _.includes(ganttIDs, +item.projectId);
    const projectData = projectsModel.getItem(item.projectId);
    const isMultiview = gantt.config.multiview;

    if (projectData) {
      projectData.name = item.updatedFields.name || projectData.name;
      projectData.description = item.updatedFields.description !== undefined ? item.updatedFields.description : projectData.description;
      projectData.last_update = item.lastUpdate || projectData.last_update;
      projectComponent.onChangeProjectData(projectData);
    }

    if (item.updatedFields.projectStatusOptionId) {
      const updateData = {};

      updateData.action = 'update';
      updateData.id = item.updatedFields.projectStatusOptionId;
      updateData.currentProject = item.projectId;
      globalStore.commit('teamSetting/updateProjectsWithStatus', updateData);
    }

    if (isSameProjectOpen) {
      if (!isMultiview && item.updatedFields.name) {
        globalStore.commit('headerView/setHeadTitle', item.updatedFields.name);
      }
      helpers.notify({
        userPic: event.innerSettings.user.photo,
        locale: 'history_event_project_updated',
        data: {},
      });
    }
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: event.data.updated.map(i => i.projectId),
  });
}

export default onProjectUpdated;
