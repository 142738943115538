<template>
  <div
    ref="button"
    :class="[
      'vgp-interactive-element',
      'button',
      typeClasses,
      disabled ? 'disabled' : '',
      isLoading ? 'loading' : ''
    ]"
    :style="size"
    @click="onClick"
  >
    <div
      :style="margins"
      :class="'wrapper'"
    >
      <div
        :class="[sideClasses, 'text-area']"
      >
        <template v-if="!isLoading">
          <div class="text">
            {{ label }}
          </div>
          <div
            v-if="icon"
            :class="[iconClass]"
          >
            <sprite
              :name="icon.name"
              :type="icon.type"
              :size="icon.size"
            />
          </div>
        </template>
        <vue-ellipse-progress
          v-else
          v-bind="loaderConfig"
        />
      </div>
      <div
        v-if="pricingLabel"
        class="pricing-title-container"
      >
        <div class="text">
          {{ pricingLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress';
import { BUTTON_TYPE_TO_LOADER_CONFIG_MAP } from './vgpButton.config';
import sprite from '../VueIcon/svgSprite.vue';

export default {
  name: 'VgpButton',
  components: {
    sprite,
    VueEllipseProgress,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: Object, // { type, name, size }
      default: null,
    },
    iconSide: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    pricingLabel: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconSideData: this.iconSide,
      rememberedWidthBeforeLoadingState: null,
    };
  },
  computed: {
    typeClasses() {
      const size = this.small ? 'small' : this.xSmall ? 'x-small' : '';

      return `${this.type}-btn ${size}`;
    },
    sideClasses() {
      return this.iconSideData && `button-icon-${this.iconSideData}`;
    },
    iconClass() {
      return this.iconSideData && `button-icon-${this.iconSideData}`;
    },
    loaderConfig() {
      if (BUTTON_TYPE_TO_LOADER_CONFIG_MAP[this.type]) {
        return BUTTON_TYPE_TO_LOADER_CONFIG_MAP[this.type];
      }

      return BUTTON_TYPE_TO_LOADER_CONFIG_MAP.primary;
    },
    size() {
      const width = this.width || `${this.rememberedWidthBeforeLoadingState}px`;
      const height = this.xSmall ? 24 : this.small ? 32 : 40;

      return {
        padding: this.width === 'auto' ? '0 24px' : '',
        height: `${height}px`,
        width: `${width}`,
      };
    },
    margins() {
      const textBtn = this.type === 'text' || this.type === 'text-grey';
      const margin = this.xSmall ? 8 : this.small ? 24 : 32;

      return {
        margin: !textBtn ? `${this.width ? 0 : `0 ${margin}`}px` : '0 8px',
      };
    },
  },
  watch: {
    icon(val) {
      if (val) {
        this.iconSideData = this.iconSide ? this.iconSide : 'left';
        if (!this.label) {
          this.iconSideData = '';
        }
      }
    },
    iconSide(val) {
      if (val) {
        this.iconSideData = this.iconSide;
      }
    },
    label(val) {
      if (!val) {
        this.iconSideData = '';
      }
    },
    isLoading(val) {
      if (val && !this.width) {
        this.rememberedWidthBeforeLoadingState = this.$refs.button.offsetWidth;
      } else {
        this.rememberedWidthBeforeLoadingState = null;
      }
    },
  },
  methods: {
    onClick() {
      !this.disabled && !this.isLoading && this.$emit('onClick');
    },
  },
};
</script>

<style scoped src='./globalButton.less'></style>
