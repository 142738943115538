<template>
  <div :style="{ display: 'flex' }">
    <div
      class="expand-toggle"
      :style="{ cursor: 'pointer', paddingLeft: `${row.depth * 1}rem` }"
    >
      <div v-if="row.getCanExpand()" @click="row.toggleExpanded()">
      <svg-sprite
        v-if="row.getIsExpanded()"
        type="regular"
        :size="16"
        name="minus-2"
      />
      <svg-sprite 
        v-else
        type="regular"
        :size="16"
        name="plus-3"
      />
      </div>
    </div>
    <div>{{ cell.getValue() }}</div>
  </div>
</template>

<script>

export default {
  name: "ExpandCell",
  props: ['context'],
  computed: {
    row() {
      return this.context.row;
    },
    cell() {
      return this.context.cell;
    }
  }
}
</script>