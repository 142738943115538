<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader"
    >
      <vue-ellipse-progress
        :size="34"
        loading
        line="round"
        thickness="10%"
        empty-thickness="10%"
        color="#1565C0"
        empty-color="#73A3D94D"
      />
    </div>
  </transition>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress';

export default {
  components: {
    VueEllipseProgress,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .loader {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
