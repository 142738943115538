import { ONBOARDING_FLOWS, ONBOARDING_INITIAL_TASKS, STEPS_CONFIG } from './onboarding.config';
import { cloneDeep } from 'lodash';

export default {
  namespaced: true,
  state: {
    activeStepId: null,
    analyticType: null,
    currentOnboardingFlowKey: null,
    projectData: {
      name: null,
      tasks: cloneDeep(ONBOARDING_INITIAL_TASKS),
      teammates: [{ email: '' }, { email: '' }, { email: '' }],
    },
  },
  getters: {
    isRegistrationViaSocials(state) {
      return ['OWNER_SOCIAL_NETWORKS', 'TEAM_MEMBER_SOCIAL_NETWORKS'].includes(state.currentOnboardingFlowKey);
    },
    getCurrentStepConfig(state) {
      return STEPS_CONFIG.find(step => step.id === state.activeStepId);
    },
    getProjectTasks(state) {
      return state.projectData.tasks;
    },
    getTeammates(state) {
      return state.projectData.teammates;
    },
    getCurrentOnboardingFlow(state) {
      return ONBOARDING_FLOWS[state.currentOnboardingFlowKey];
    },
    getCurrentStepsOrder(state, getters) {
      return getters.getCurrentOnboardingFlow.stepsOrder;
    },
    getProjectName(state) {
      return state.projectData.name;
    },
    getCurrentStepIndex(state, getters) {
      return getters.getCurrentStepsOrder.findIndex(item => item === state.activeStepId);
    },
    getNextStepId(state, getters) {
      const nextStepIndex = getters.getCurrentStepIndex + 1;

      return getters.getCurrentStepsOrder[nextStepIndex] || null;
    },
  },
  actions: {
    async nextStep({ getters, commit, dispatch }) {
      const nextStepId = getters.getNextStepId;

      if (nextStepId) {
        await dispatch(
          'user/updateActivitySettings',
          { activityName: 'onboarding', settings: { lastActiveStep: nextStepId }},
          { root: true },
        );
        commit('setActiveStepId', nextStepId);

        return Promise.resolve();
      }

      return Promise.reject('Cannot find onboarding nextStepId');
    },
    async finish({ dispatch }) {
      return dispatch(
        'user/updateActivitySettings',
        { activityName: 'onboarding', settings: { isFinished: true } },
        { root: true },
      );
    },
  },
  mutations: {
    setCurrentOnboardingFlowKey(state, value) {
      state.currentOnboardingFlowKey = value;
    },
    setActiveStepId(state, value) {
      state.activeStepId = value;
    },
    updateTask(state, { value, index }) {
      Object.assign(state.projectData.tasks[index], { name: value });
    },
    updateProjectName(state, value) {
      state.projectData.name = value;
    },
    setAnalyticType(state, value) {
      state.analyticType = value;
    },
  },

}
