import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const columnsStore = {
  namespaced: true,
  state: {
    valuesLoaded: false,
    columns: [
      {
        id: 0,
        name: 'String',
        options: [],
        user_id: 0,
        team_id: 0,
        type: 0,
        sub_type: 0,
        is_removed: 0,
        create_date: 'String',
        last_update: 'String',
        last_update_by: 0,
        locale: 'String',
      },
    ],
    projectColumns: [
      {
        id: 0,
        columns: [],
        values: [],
        is_archived: 0,
      },
    ],
    staticColumns: [
      {
        id: 0,
        default_option_id: 0,
        name: 'String',
        type: 'String',
        locale: 'String',
        options: [],
      },
    ],
    jiraColumns: [],
  },
  getters,
  mutations,
  actions,
};

export default columnsStore;
