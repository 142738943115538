
import app from '../../../app';
import { getParentsIDs, refreshTasksByIDs, updateButtonInTask } from './helpers';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from "../../../store/main";
import massChangeModel from '../../../views/gantt/modules/massChange';

function onTaskDeleted(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const needClearAndParseContext = { needClearAndParse: false };
  const tasksMap = {};
  const tasks = [];
  const tasksParents = [];
  const tasksForDelete = []; 
  let queue = [];
  let qs = 0;
  let qe = 0;

  event.data.ids.forEach(id => {
    const task = globalStore.getters['tasksModel/getTask'](id);
    if (task) {
      tasks.push(task);
    }
  });

  tasks.forEach(task => {
    tasksMap[task.id] = {
      status: true,
      nodes: []
    };
  });

  tasks.forEach(task => {
    if (!tasksMap[task.parent]) {
      queue.push(task);
      qe++;
    } else {
      tasksMap[task.parent].nodes.push(task);
    }
  });

  while (qs < qe) {
    let task = queue[qs];

    queue = [...queue, ...tasksMap[task.id].nodes];
    qe = queue.length;

    qs++;
  }

  queue = queue.reverse();

  queue.forEach(task => {
    const taskID = task.id;

    const ganttTask = gantt.isTaskExists(taskID) && gantt.getTask(taskID);
    const taskData = globalStore.getters['tasksModel/getTask'](taskID);
    const isSameProjectOpen = _.includes(ganttIDs, +taskData.gantt_id);

    if (ganttTask) {
      ganttTask.callaborationAdd = true;
      if (isSameProjectOpen) {
        gantt.silent(() => {
          gantt.deleteTask(taskID);
        });
        tasksParents.push({
          parent: ganttTask.parent,
          gantt_id: ganttTask.gantt_id,
        });
      }
      //refreshTasksByIDs(parentsIds);
    }

    if (taskData && taskData.gantt_id) {
      massChangeModel.unselectTask(task.id, true);
      globalStore.dispatch('tasksModel/deleteTask', taskData);
    }
  });

  tasksParents.forEach(parentObj => {
    if (gantt.isTaskExists(parentObj.parent)) {
      updateButtonInTask(parentObj.parent, parentObj.gantt_id, needClearAndParseContext);
    }
  });

  if (gantt.config.highlight_critical_path) {
    gantt.callEvent('recalculateCriticalPath');
  }

  gantt.render();

  !gantt.is_workload_mode && needClearAndParseContext.needClearAndParse && gantt.callEvent('ganttClearAndParse', []);

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.ids,
    projects: event.data.projectIds,
  });
}

export default onTaskDeleted;
