<template>
  <div class="header">
    <div class="project_title_msteams">
      {{ locales("integration_settings_page_table_header_project") }}
    </div>
    <div class="team_title_msteams">
      {{ locales("integration_msteams_settings_page_table_header_team") }}
    </div>
    <div class="channel_title_msteams">
      {{ locales("integration_msteams_settings_page_table_header_channel") }}
    </div>
    <div class="settings_title_msteams">
      {{ locales("integration_settings_page_table_header_settings") }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      locales: __,
    };
  }
};
</script>
