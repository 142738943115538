var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: "",
      "close-icon": true,
      title: _vm.locales("convert_resources_title"),
      "class-list": _vm.popup.convert_modal,
    },
    on: { onClickCancel: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.currentStep === 1
              ? _c("div", { class: _vm.popup.convert_resources_popup }, [
                  _c(
                    "div",
                    { class: _vm.popup.convert_resources_popup_message },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("convert_resources_description")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.popup.convert_resources_popup_select_resource,
                    },
                    [
                      _c(
                        "div",
                        { class: _vm.popup.source_resource },
                        [
                          _c("vgp-avatar", {
                            attrs: {
                              size: "small",
                              src: _vm.sourceResource.photo,
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { class: _vm.popup.source_resource_info }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.sourceResource.name) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("svg-sprite", {
                            attrs: {
                              name: "arrow-right-2",
                              type: "regular",
                              size: 24,
                              color: "#B2B2B2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.popup.target_resource },
                        [
                          _c("vgp-label-slot", {
                            attrs: {
                              label: _vm.locales(
                                "convert_resources_real_dropdown_label"
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("dropdown-select", {
                                        attrs: {
                                          placeholder: _vm.locales(
                                            "convert_resources_real_dropdown_placeholder"
                                          ),
                                          "no-matches-message": _vm.locales(
                                            "convert_resources_real_dropdown_no_matches"
                                          ),
                                          "drop-list": _vm.dropListUsers,
                                          "group-by": "team",
                                          "devider-color": "#999999",
                                          "use-search": true,
                                          multiselect: false,
                                          "show-reset-btn": false,
                                          "item-template": "user",
                                          "track-by-img-url": "photo",
                                          selected: _vm.targetResource,
                                        },
                                        on: { change: _vm.onChangeSelected },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              464728727
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { class: _vm.popup.settings }, [
                    _c(
                      "div",
                      { class: _vm.popup.setting_checkbox },
                      [
                        _c("vgp-checkbox", {
                          attrs: { size: 16, checked: _vm.shouldRemoveSource },
                          on: { onClick: _vm.handleCheckbox },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: _vm.popup.setting_label,
                        on: { click: _vm.handleCheckbox },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.locales(
                                "convert_resources_remove_virtual_label"
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.popup.convert_resources_popup_footer },
                    [
                      _c(
                        "div",
                        { class: _vm.popup.buttons },
                        [
                          _c("vgp-button", {
                            attrs: {
                              type: "secondary",
                              label: _vm.locales("common_cancel"),
                              small: true,
                            },
                            on: { onClick: _vm.onClose },
                          }),
                          _vm._v(" "),
                          _c("vgp-button", {
                            style: { marginLeft: 16 + "px" },
                            attrs: {
                              type: "primary",
                              label: _vm.locales("next"),
                              small: true,
                              disabled: !_vm.targetResource,
                            },
                            on: {
                              onClick: function ($event) {
                                _vm.currentStep++
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStep === 2
              ? _c(
                  "div",
                  { class: _vm.popup.convert_resources_popup },
                  [
                    !_vm.isResourceCalendarApplied ||
                    _vm.isResourceCalendarTotalDifferent
                      ? _c(
                          "div",
                          { class: _vm.popup.convert_resources_warning },
                          [
                            _c(
                              "div",
                              [
                                _c("svg-sprite", {
                                  attrs: {
                                    name: "warning-1",
                                    type: "bold",
                                    size: 20,
                                    color: "#FF9A00",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.isResourceCalendarApplied
                              ? _c(
                                  "span",
                                  {
                                    class:
                                      _vm.popup.convert_resources_warning_text,
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.locales(
                                            "convert_resources_calendar_not_applied_warning_text"
                                          )
                                        ) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm.isResourceCalendarTotalDifferent
                              ? _c(
                                  "span",
                                  {
                                    class:
                                      _vm.popup.convert_resources_warning_text,
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.locales(
                                            "convert_resources_calendar_total_different_warning_text"
                                          )
                                        ) +
                                        "\n        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.modeList, function (mode) {
                      return _c(
                        "div",
                        {
                          key: mode.id,
                          class: [
                            _vm.popup.radio_input_wrapper,
                            mode.id === "updateTasks" &&
                            (!_vm.isResourceCalendarApplied ||
                              _vm.isResourceCalendarTotalDifferent)
                              ? _vm.popup.radio_disabled
                              : "",
                          ],
                        },
                        [
                          _c("label", { class: _vm.popup.radio_label }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedMode,
                                  expression: "selectedMode",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: mode.id,
                                checked: _vm._q(_vm.selectedMode, mode.id),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.selectedMode = mode.id
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { class: _vm.popup.radio_input }),
                            _vm._v(" "),
                            _c("span", { class: _vm.popup.radio_title }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.locales(mode.label)) +
                                  "\n          "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.popup.radio_description }, [
                            _vm._v(_vm._s(_vm.locales(mode.description))),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.popup.buttons },
                      [
                        _c("vgp-button", {
                          attrs: {
                            type: "secondary",
                            label: _vm.locales("back_btn"),
                            small: true,
                          },
                          on: {
                            onClick: function ($event) {
                              _vm.currentStep--
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("vgp-button", {
                          style: { marginLeft: 16 + "px" },
                          attrs: {
                            type: "primary",
                            label: _vm.locales(
                              "convert_resources_submit_button"
                            ),
                            small: true,
                            disabled: !_vm.targetResource || !_vm.selectedMode,
                          },
                          on: { onClick: _vm.onConvert },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }