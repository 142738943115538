<template>
    <div>
        <webix-ui :config="iframe"/>
    </div>
</template>

<script>
const Vue = window.Vue;
const exportFrame = "exportFrame";

gantt.attachEvent("onBeforeGanttRender", function () {
    const $$exportFrame = $$(exportFrame);

    if ($$exportFrame) {
        const rightBar = document.querySelector('.right-bar.collapsed');
        const body = document.querySelector('#vue-container .body');
        const clientWidth = document.documentElement.clientWidth;

        $$exportFrame.config.width = clientWidth;
        // if (rightBar) {
        //     $$exportFrame.config.width = body.offsetWidth;
        // } else {
        //     $$exportFrame.config.width = body.offsetWidth - 300;
        // }

        $$exportFrame.config.height = document.querySelector('#vue-container .gantt').offsetHeight - 60;
        $$exportFrame.resize();
    }
    return true;
});

export default {
    name: "GanttIframe",
    data() {
        const baselines = webix.storage.cookie.get('baselines');
        let baselineID = '';
        const ganttID = gantt.config.gantt_id;

        if (baselines && baselines[ganttID]) {
            baselineID = _.first(_.keys(baselines[ganttID]));
        }

        let iframeSrc = ``;

        if (gantt.config.multiview) {
            iframeSrc = `/view/export/portfolio?multiviewId=${gantt.config.multiview_id}&userId=${user.id}&teamId=${user.team.id}&ignoreFilters=0&lang=${user.locale}`;
        } else {
            iframeSrc = `/view/export/${ganttID}/${user.id}/team/${user.team.id}/${user.locale}/${baselineID}`;
        }

        return {
            iframe: {
                view: "iframe",
                id: exportFrame,
                src: iframeSrc,
                css: "history-window-frame export-iframe pos-relativ",
                gravity: 0,
                borderless: true,
                hidden: false,
                on: {},
            },
        };
    },

    mounted: function () {
        this.$nextTick(function () {
            $$(exportFrame).config.height = document.querySelector('#vue-container .gantt').offsetHeight - 60;
            $$(exportFrame).resize();
        });
    },
    components: {},
};
</script>

<style scoped>

</style>