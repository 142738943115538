<template>
  <div
    :class="['profile_settings', !['resources', 'project_rights', 'account_rights'].includes(type) ? 'gantt-scroll' : '']"
    :style="{paddingTop: header[type].height + 'px'}"
  >
    <profile-settings-header
      :title="header[type].title"
      :icon="header[type].icon"
      :tabs="header[type].tabs"
      :active-by="'type'"
      :active-tab="header[type].activeTab"
      @toggle="onToggle"
    />

    <div
      class="main"
      :style="{height: (clientHeight - header[type].height - 30) + 'px'}"
    >
      <div
        ref="settings"
        class="settings"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import headerConfig from './include/headerConfig';
import profileSettingsHeader from '$$vueCmp/headers/settingsHeader.vue';

export default {
  components: {
    profileSettingsHeader,
  },

  data() {
    return {
      locales: __,
      header: headerConfig,
      clientHeight: 0,
    };
  },

  computed: {
    type() {
      return this.$route.name;
    },
  },
  mounted() {
    const mainNode = this.$el;

    this.setClientHeight();
    document.addEventListener('click', this.checkClick);
    mainNode.addEventListener('scroll', this.hidePassStrengthPopup);
    mainNode.addEventListener('scroll', this.onSettingsScroll);
    window.addEventListener('resize', this.setClientHeight);
  },
  beforeDestroy() {
    const mainNode = this.$el;

    document.removeEventListener('click', this.checkClick);
    mainNode.removeEventListener('scroll', this.hidePassStrengthPopup);
    mainNode.removeEventListener('scroll', this.onSettingsScroll);
    window.removeEventListener('resize', this.setClientHeight);
  },
  methods: {
    setClientHeight() {
      this.clientHeight = document.documentElement.clientHeight;
    },
    onSettingsScroll() {
      this.$store.dispatch('profileSettings/onSettingsScroll');
    },

    checkClick(e) {
      if (e.target.id !== 'newPassInput') {
        this.hidePassStrengthPopup();
      }
    },

    hidePassStrengthPopup() {
      this.$store.dispatch('passStrengthPopup/close');
    },

    onToggle(tab) {
      const routeType = this.$route.name;

      if (this.header[routeType]) {
        this.header[routeType].activeTab = tab.type;
        this.$router.push({ name: 'resources', params: { activeView: tab.type } });
      }
    },
  },
};
</script>

<style scoped src='./less/profileSettings.less'></style>
<style src="../../less/_gantt_scroll.less"></style>
<style lang='less'>
  p {
    //margin-bottom: 10px !important;
  }

  .profile_settings {
    a {
      color: #257ECC;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .delete-icon {
    .webix_view {
      cursor: pointer;

      .webix_template {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        .webix_template {
          svg path {
            fill: #C62828;
          }
        }
      }
    }
  }

  .check-ic.active {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #00564C;
          }
        }
      }
    }
  }
</style>
