var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["v-global-input", _vm.css ? _vm.css : ""] }, [
    _vm.label
      ? _c("label", { staticClass: "label" }, [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputValue,
          expression: "inputValue",
        },
      ],
      staticClass: "text-area",
      class: _vm.disable ? "not_editable" : "",
      style: _vm.styles,
      attrs: {
        type: _vm.type ? _vm.type : null,
        placeholder: _vm.placeholder,
        readonly: _vm.disable,
      },
      domProps: { value: _vm.inputValue },
      on: {
        change: _vm.onHandleChange,
        input: [
          function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputValue = $event.target.value
          },
          _vm.onHandleInput,
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }