<template>
  <div
    v-if="show"
    :class="[$.skeleton_container]"
  >
    <div
      :class="[$.skeleton_popup]"
    >
      <div :class="$.loader_container">
        <div :class="$.loader_icon" />
      </div>
      <div
        :class="$.text"
        v-html="locale(text)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SkeletonPopup',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      locale: __,
    };
  },

};
</script>

<style module="$" lang="less" >
@import '../../../../../less/_fonts.less';

.skeleton{
  &_container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
  &_popup{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 8px;
    background: #ffffff;
    justify-content: center;
    flex: 1;
  }
}

.loader {
  &_container{
    width: 24px;
    height: 24px;
    display: inline-block;
  }
  &_icon{
    width: 17.5px;
    height: 17.5px;
    border: 1px solid #808080;
    border-bottom-color: #e5e5e5;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text {
  .FontLatoRegular();
  font-size: 14px;
  text-align: center;
  color: #999999;
  line-height: 20px;
  word-break: break-all;
}

</style>
