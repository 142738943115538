<template>
  <div
    :class="[$style.search_input]"
  >
    <div :class="[$style.input_label]">
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="outerValue.from || outerValue.to"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        @click="resetSelected()"
      >
        {{ locale.reset }}
      </div>
    </div>

    <div :class="[$style.input_field, outerValue.from || focusFrom ? $style.active : '']">
      <input
        :id="gpAutotestName + '_from'"
        :value="showValue.from"
        :placeholder="locale.from"
        @change="onChange('from', $event.target.value)"
        @focus="focusFrom=true"
        @blur="focusFrom=false"
      >
    </div>

    <div :class="[$style.input_field, outerValue.to || focusTo ? $style.active : '']">
      <input
        :id="gpAutotestName + '_to'"
        :value="showValue.to"
        :placeholder="locale.to"
        @change="onChange('to', $event.target.value)"
        @focus="focusTo=true"
        @blur="focusTo=false"
      >
    </div>
  </div>
</template>

<script>
import timeParser from '../../../helpers/timeParser';
import _ from '../../../libs/lodash';

export default {
  props: {
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
    label: { type: String, required: false, default: 'label' },
    value: { type: String, required: false, default: '' },
  },
  data() {
    return {
      locale: {
        reset: __('common_reset'),
        from: __('filter_number_type_custom_column_from'),
        to: __('filter_number_type_custom_column_to'),
      },
      focusFrom: false,
      focusTo: false,
      showValue: { from: '', to: '' },
      outerValue: { from: '', to: '' },
    };
  },

  watch: {
    value(val) {
      this.updateCurrentValues();
    },
  },

  mounted() {
    this.updateCurrentValues();
  },
  methods: {
    updateCurrentValues() {
      this.showValue.from = this.value.from ? this.outputValue(this.value.from) : '';
      this.showValue.to = this.value.to ? this.outputValue(this.value.to) : '';
      this.outerValue = this.value ? this.value : { from: '', to: '' };
    },

    outputValue(value) {
      return timeParser.output(value);
    },
    validValue(value) {
      return timeParser.input(value).value;
    },

    onChange(property, val) {
      const valid = this.validValue(val);

      this.outerValue[property] = valid;
      this.showValue[property] = this.outputValue(valid);
      this.$emit('change', this.outerValue);
    },

    resetSelected() {
      this.showValue = { from: '', to: '' };
      this.outerValue = { from: '', to: '' };
      this.$emit('reset', '');
    },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
