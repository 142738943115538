import timeParser from '../../../helpers/timeParser';
import dateFormats from '../../../helpers/dateFormats';
import icoSearch from '../../../svg/globalSearch/outline-search-24px.svg';
import timeIntervalHelper from '../../../helpers/timeIntervals';

const webix = window.webix;
const __ = window.__;

export default (function () {
  'use strict';

  // webix.debug_proto = true;
  const originallyWebixMessage = webix.message;

  const removeTopOrBottomPoint = function (context, webixSuggestPopup, suggestCss) {
    let staticInp;

    if (webixSuggestPopup && !webixSuggestPopup.config.suggestInit) {
      webixSuggestPopup.define('padding', 0);
      webixSuggestPopup.define('box-sizing', 'content-box');
      webixSuggestPopup.getNode().classList.add('suggest_popup');

      if (suggestCss) {
        webixSuggestPopup.getNode().classList.add(suggestCss);
      }

      webixSuggestPopup.resize();

      webixSuggestPopup.attachEvent('onshow', function () {
        const node = this.getNode();
        let nodeTriangles = document.querySelectorAll('.webix_point_top');
        let nodeTriangle = null;

        if (node.querySelector('.webix_list')) {
          node.setAttribute('title', node.innerText);
          const listItems = node.querySelector('.webix_list').querySelectorAll('.webix_list_item');

          listItems.forEach(item => item.setAttribute('title', item.innerText));

          if (node.classList.contains('filterOptionSuggest')) {
            node.style.width = '266px';
            const items = node.querySelector('.webix_list').querySelectorAll('.webix_list_item');

            if (items.length >= 5) {
              node.style.height = '180px';
            } else {
              node.style.height = `${items.length * 36}px`;
            }
          } else if (node.classList.contains('filter-multicombo-suggest')) {
            node.style.width = `${context.getNode().clientWidth}px`;
          }
        }

        if (nodeTriangles.length) {
          for (var i = 0; i < nodeTriangles.length; i++) {
            if (!nodeTriangle && nodeTriangles[i].style.display !== 'none') {
              nodeTriangle = nodeTriangles[i];
              nodeTriangle.style.display = 'none';
            }
          }
        }

        if (!nodeTriangle) {
          node.style.top = `${parseInt(node.style.top, 10) - 1}px`;
          node.style.zIndex = +node.style.zIndex + 1;
          node.classList.remove('suggest_popup_bottom');
          node.classList.add('suggest_popup_top');
          staticInp = $$(this.config.master).getNode().querySelector('.webix_inp_static');
          staticInp && staticInp.classList.add('suggest_popup_top');

          nodeTriangles = document.querySelectorAll('webix_point_bottom');
          nodeTriangle = null;

          if (nodeTriangles.length) {
            for (var i = 0; i < nodeTriangles.length; i++) {
              if (!nodeTriangle && nodeTriangles[i].style.display !== 'none') {
                nodeTriangle = nodeTriangles[i];
                nodeTriangle.style.display = 'none';
              }
            }
          }

          return true;
        }

        node.style.top = `${parseInt(this.getNode().style.top, 10)}px`;
        node.style.zIndex = +node.style.zIndex + 1;
        node.classList.remove('suggest_popup_top');
        node.classList.add('suggest_popup_bottom');
        staticInp = $$(this.config.master).getNode().querySelector('.webix_inp_static');
        staticInp && staticInp.classList.add('suggest_popup_bottom');
      });

      webixSuggestPopup.config.suggestInit = true;
    }
  };

  const definePopupSizeAndPosition = function (popup) {
    const containerNode = $$(popup.config.master).getNode();
    const suggestPopupNode = popup.getNode();

    definePopupHeight(popup);

    const containerYPos = containerNode.getBoundingClientRect().top;
    const suggestPopupYPos = suggestPopupNode.getBoundingClientRect().top;
    const suggestPopupHeight = suggestPopupNode.getBoundingClientRect().height;

    suggestPopupNode.style.width = `${containerNode.offsetWidth - 2}px`;

    if (containerYPos > suggestPopupYPos) {
      suggestPopupNode.style.top = `${(containerYPos - suggestPopupHeight - 1)}px`;
      suggestPopupNode.classList.remove('suggest_popup_bottom');
      suggestPopupNode.classList.add('suggest_popup_top');
    } else {
      suggestPopupNode.classList.remove('suggest_popup_top');
      suggestPopupNode.classList.add('suggest_popup_bottom');
    }
  };

  const definePopupHeight = function (popup) {
    if (popup.config.selectAll) {
      const selectAllButton = $$(popup.config.body.rows[0].id);

      if (selectAllButton.isVisible()) {
        const selectAllButtonHeight = 36;

        selectAllButton.getNode().style.height = `${selectAllButtonHeight}px`;
        popup.getNode().style.height = `${popup.getList().$height + selectAllButtonHeight}px`;
      }
    }
    if (!popup.getList().count()) {
      const overlayHeight = 48;

      popup.getNode().style.height = `${overlayHeight}px`;
    }
  };

  const initCustomListPopupSizeAndPosition = function (popup, isSearchField) {
    const isTaskView = popup.getNode().classList.contains('task_view');

    defineCustomListPopupWidth(popup, isTaskView);
    defineCustomListPopupHeight(popup, isSearchField);
    popup.getNode().classList.add('custom_select');

    let container = $$(popup.config.master).getNode();

    if (isTaskView) {
      container = $$(popup.config.master).getNode().parentNode;
    }

    const docHeight = document.body.clientHeight;
    let popupHeight = popup.getNode().offsetHeight;

    const topPos = container.getBoundingClientRect().top;
    const bottomPos = container.getBoundingClientRect().bottom;
    const domSizesTop = docHeight - bottomPos;

    if (domSizesTop > popupHeight) {
      const popupTopMargin = getPopuptTopMargin(container, isTaskView);

      popup.getNode().classList.remove('suggest_popup_top');
      popup.getNode().classList.add('suggest_popup_bottom');
      popup.$view.style.top = `${Math.floor(bottomPos) + popupTopMargin}px`;
    } else {
      const popupTopMargin = getPopuptTopMargin(container, isTaskView, true);

      popup.getNode().classList.remove('suggest_popup_bottom');
      popup.getNode().classList.add('suggest_popup_top');
      popupHeight = popup.getNode().offsetHeight;
      popup.$view.style.top = `${topPos - popupHeight - popupTopMargin}px`;
    }

    defineCustomListPopupLeftMargin(popup, isTaskView);
  };

  const iniColorListPopupSizeAndPosition = function (popup) {
    popup.getNode().classList.add('custom_select');

    const container = $$(popup.config.master);
    const resetHeight = popup.config.resetButton && container.getValue() ? 25 : 0;

    popup.config.width = container.config.popupWidth + 10;
    popup.config.height = container.config.popupHeight + resetHeight + 10;
    popup.config.padding = 5;
    popup.resize();

    popup.getNode().style.height = `${container.config.popupHeight + resetHeight + 10}px`;
    popup.getList().getNode().style.height = `${container.config.popupHeight}px`;

    const docHeight = document.body.clientHeight;
    const popupHeight = popup.$height;

    const topPos = container.getNode().getBoundingClientRectWrapper().y;
    const domSizesTop = docHeight - topPos;

    if (domSizesTop > (popupHeight + 50)) {
      popup.$view.style.top = `${topPos + container.getNode().offsetHeight}px`;
      popup.getNode().classList.remove('suggest_popup_top');
      popup.getNode().classList.add('suggest_popup_bottom');
    } else {
      popup.$view.style.top = `${topPos - popupHeight - 3}px`;
      popup.getNode().classList.remove('suggest_popup_bottom');
      popup.getNode().classList.add('suggest_popup_top');
    }
  };

  const recalculateCustomListPopupPosition = function (popup, isSearchField) {
    defineCustomListPopupHeight(popup, isSearchField);

    const isTaskView = popup.getNode().classList.contains('task_view');
    let container = $$(popup.config.master).getNode();

    if (isTaskView) {
      container = $$(popup.config.master).getNode().parentNode;
    }

    const popupHeight = popup.getNode().offsetHeight;
    const topPos = container.getBoundingClientRect().top;
    const bottomPos = container.getBoundingClientRect().bottom;

    if (popup.getNode().classList.contains('suggest_popup_top')) {
      const popupTopMargin = getPopuptTopMargin(container, isTaskView, true);

      popup.$view.style.top = `${topPos - popupHeight - popupTopMargin}px`;
    } else {
      const popupTopMargin = getPopuptTopMargin(container, isTaskView);

      popup.$view.style.top = `${bottomPos + popupTopMargin}px`;
    }

    defineCustomListPopupLeftMargin(popup, isTaskView);
  };

  const defineCustomListPopupHeight = function (popup, isSearchField) {
    const list = popup.getList();
    const overlayHeight = !list.count() ? 45 : 0;
    const searchHeight = isSearchField ? 39 : 0;
    const resetHeight = popup.config.resetButton && $$(popup.config.master).getValue() ? 30 : 0;

    popup.getNode().style.height = `${list.$height + overlayHeight + searchHeight + resetHeight}px`;
  };

  const defineCustomListPopupWidth = function (popup, isTaskView) {
    const taskViewPopupWidth = 224;

    if (isTaskView) {
      popup.config.width = taskViewPopupWidth;
    } else {
      const container = $$(popup.config.master).getNode().querySelector('.webix_inp_static');

      popup.config.width = container.offsetWidth - 2;
    }
    popup.resize();
  };

  const getPopuptTopMargin = function (container, isTaskView, isTopPosition) {
    if (isTaskView) {
      return isTopPosition ? 2 : 1;
    }

    return isTopPosition ? 1 : 0;
  };

  const defineCustomListPopupLeftMargin = function (popup, isTaskView) {
    if (isTaskView) {
      const leftMargin = 7;

      popup.$view.style.left = `${parseInt(popup.$view.style.left, 10) - leftMargin}px`;
    }
  };

  const toggleFocusStyle = function (id, bAdd) {
    if ($$(id)) {
      const container = $$(id).getNode().childNodes[0];

      bAdd ? container.classList.add('webix_focused') : container.classList.remove('webix_focused');
    }
  };

  const createSortedResourcesSet = function (reducedResources) {
    const unassigned = reducedResources.find(item => item.id === '-1');

    const resources = reducedResources.filter(item => !isDividerItem(item.id) && item.id !== '-1');
    const groupedObj = _.groupBy(resources, item => (_.isNull(item.userId) ? 'material' : 'people'));

    groupedObj.people = orderListItems(groupedObj.people);
    groupedObj.material = orderListItems(groupedObj.material);

    const peopleHeader = groupedObj.people.length ? [
      {
        id: 'divider__people',
        value: __('resources_layout_header_title_1'),
      },
    ] : [];

    const materialHeader = groupedObj.material.length ? [
      {
        id: 'divider_material',
        value: __('resources_layout_header_title_2'),
      },
    ] : [];

    return _.compact([
      unassigned,
      ...peopleHeader,
      ...groupedObj.people,
      ...materialHeader,
      ...groupedObj.material,
    ]);
  };

  const orderListItems = function (resources = []) {
    const resourcesForSorting = resources.map(item => {
      item.$checked = item.$checked ? item.$checked : 0;

      return item;
    });

    return _.orderBy(resourcesForSorting, ['$checked', 'sort_order'], ['desc', 'asc']);
  };

  const defineResourcesDividers = function (resources) {
    const resourcesForGrouping = resources.filter(item => item.id !== '-1');
    const groupedObj = _.groupBy(resourcesForGrouping, item => (_.isNull(item.userId) ? 'material' : 'people'));

    const dividers = [];

    groupedObj.people && groupedObj.people.length && dividers.push('divider__people');
    groupedObj.material && groupedObj.material.length && dividers.push('divider_material');

    return dividers;
  };

  const isDividerItem = function (id) {
    return _.isString(id) && id.includes('divider');
  };

  const isDividerRequired = function (items, itemId, text) {
    const filteredItems = items.filter(item => !isDividerItem(item.id) && item.value.toLowerCase().includes(text.toLowerCase()));

    if (!filteredItems.length) {
      return false;
    }
    const dividers = defineResourcesDividers(filteredItems);

    return !!dividers.find(id => id === itemId);
  };

  const stopEventPropagation = function (event) {
    event.stopPropagation();
  };

  const setMulticomboInputCustomActions = function (multicombo) {
    const input = multicombo.getInputNode();

    input.removeEventListener('click', stopEventPropagation);
    input.addEventListener('click', stopEventPropagation);
    _.delay(() => {
      input.placeholder = __('filter_search_placeholder');
    });
  };

  const resetMulticomboInputCustomActions = function (multicombo, defaultPlaceholder) {
    const input = multicombo.getInputNode();

    input.removeEventListener('click', stopEventPropagation);
    if (multicombo.getText()) {
      input.placeholder = '';
    } else {
      input.placeholder = defaultPlaceholder;
    }
    input.value = '';
    multicombo._inputValue = '';
    input.blur();
  };

  const setSelectAllButtonState = function (multicombo) {
    const popup = multicombo.getPopup();
    const itemsList = popup.getList();

    if (popup.config.selectAll) {
      const selectAllButton = $$(popup.config.body.rows[0].id);

      if (selectAllButton.isVisible()) {
        const allSelectedId = itemsList.data.order.map(id => id.toString());
        const allItems = itemsList.serialize(true).filter(listItem => !isDividerItem(listItem.id) && allSelectedId.includes(`${listItem.id}`));

        const isAllItemsSelected = !allItems.some(item => !item.$checked);

        selectAllButton.blockEvent();
        _.delay(() => {
          selectAllButton.unblockEvent();
          selectAllButton.setValue(isAllItemsSelected);
          selectAllButton.callEvent('onChange');
        });
      }
    }
  };

  const redefineSelectAllButtonText = function () {
    webix.i18n.combo.selectAll = __('multicombo_select_all_items');
    webix.i18n.combo.unselectAll = __('multicombo_unselect_all_items');
  };

  const showSelectAllSeparator = function (popup) {
    if (popup.config.selectAll) {
      const selectAllNode = $$(popup.config.body.rows[0].id).getNode();
      const listScrollNode = popup.getNode().getElementsByClassName('webix_scroll_cont')[0];
      const sellectAllBottomPos = selectAllNode.getBoundingClientRect().bottom;
      const listScrollTopPos = listScrollNode.getBoundingClientRect().top;

      if (sellectAllBottomPos > listScrollTopPos) {
        selectAllNode.classList.add('scrolled');
      } else {
        selectAllNode.classList.remove('scrolled');
      }
    }
  };

  const showResetSeparator = function (popup) {
    if (popup.config.resetButton) {
      const reset = popup.getNode().getElementsByClassName('custom_column_reset')[0];

      reset && reset.classList.remove('scrolled');

      if (reset && popup.config.body.yCount < popup.getList().count()) {
        const listScrollNode = popup.getNode().getElementsByClassName('webix_scroll_cont')[0];
        const resetBottomPos = reset.getBoundingClientRect().top;
        const listScrollTopPos = listScrollNode.getBoundingClientRect().bottom;

        if (resetBottomPos < listScrollTopPos) {
          reset.classList.add('scrolled');
        }
      }
    }
  };

  const defineOverlayDisplay = function (popup, isInit) {
    const resetBtn = popup.$view.querySelector('.custom_column_reset');

    if (!popup.getList().count()) {
      resetBtn ? resetBtn.classList.add('dnone') : '';
      popup.showOverlay(`<div class="empty_data">${isInit ? __('no_data_message_text') : __('no_matches_message_text')}</div>`);
    } else {
      resetBtn ? resetBtn.classList.remove('dnone') : '';
      popup.hideOverlay();
    }
  };

  const suggestSearchOnShow = function (isResources) {
    const suggestId = this.config.id;
    const popup = $$(suggestId);
    const list = popup.getList();
    const inputId = `listSearchInput-${suggestId}`;
    const isPopupCreated = this.getNode().classList.contains('search_view');

    if (isResources) {
      const listItems = createSortedResourcesSet(list.serialize(true));

      list.parse(listItems);
      list.data.order = listItems.map(item => item.id);
    } else {
      list.data.order = orderListItems(list.serialize(true)).map(item => item.id);
    }

    list.moveSelection = null;
    list.render();

    const isSearchField = list.count() > list.config.yCount;

    defineOverlayDisplay(this, true);
    initCustomListPopupSizeAndPosition(popup, isSearchField);

    if (!isPopupCreated) {
      list.attachEvent('onItemClick', () => {
        recalculateCustomListPopupPosition(popup, isSearchField);
      });
    }

    if (!isPopupCreated && isSearchField) {
      const headerId = `taskViewHead-${suggestId}`;
      const head = this.$view.getElementsByClassName('webix_win_head')[0];

      this.getNode().classList.add('search_view');
      head.id = headerId;

      webix.ui({
        container: headerId,
        rows: [
          {
            height: 4,
          },
          {
            height: 36,
            cols: [
              { width: 10 },
              {
                borderless: true,
                view: 'template',
                width: 36,
                height: 36,
                paddingX: 6,
                paddingY: 6,
                template: () => `<div style="width: 100%; height: 100%; padding: 6px 0;">${icoSearch}</div>`,
              },
              {
                id: inputId,
                view: 'text',
                css: 'list-search-input',
                width: 0,
                placeholder: __('resource_search_input_placeholder'),
                on: {
                  onKeyPress(key, e) {
                    _.delay(id => {
                      const popup = $$(id);
                      const list = popup.getList();
                      const master = $$(popup.config.master);
                      const selectedValue = parseInt(master.getValue());
                      const text = e.target.value;

                      list.filter(item => {
                        if (isDividerItem(item.id)) {
                          const items = list.serialize(true);

                          return isDividerRequired(items, item.id, text);
                        }

                        return item.value.toLowerCase().includes(text.toLowerCase());
                      });

                      const selectedItemNode = list.getItemNode(selectedValue);

                      if (popup.config.highlightSelection && selectedItemNode && !selectedItemNode.classList.contains('webix_selected')) {
                        selectedItemNode.classList.add('webix_selected');
                      }
                      defineOverlayDisplay(popup);
                      recalculateCustomListPopupPosition(popup, isSearchField);
                      showResetSeparator(popup);
                    }, 1, suggestId);
                  },
                },
              },
              { width: 10 },
            ],
          },
        ],
      });
    }

    if (isSearchField) {
      _.delay(() => {
        $$(inputId).setValue('');
        $$(inputId).focus();
        toggleFocusStyle(this.config.master, true);
      });
    }
  };

  const resetButtonOnShow = function (isSeparatorRequired) {
    const popup = this;
    const resetButton = popup.getNode().querySelector('.custom_column_reset');

    if (!resetButton && popup.config.resetButton && $$(popup.config.master).getValue()) {
      const select = $$(popup.config.master);
      const reset = document.createElement('div');

      reset.classList.add('custom_column_reset');
      reset.innerHTML = `<span>${__('custom_column_recet_button_text')}</span>`;
      popup.getNode().appendChild(reset);

      reset.addEventListener('click', () => {
        select.setValue('');
      });

      if (isSeparatorRequired) {
        popup.getList().attachEvent('onAfterScroll', () => {
          showResetSeparator(popup);
        });

        showResetSeparator(popup);
      }
    }

    if (popup.config.resetButton && popup.getList().getSelectedId()) {
      webix.html.addCss(popup.getNode(), 'custom_column_reset_show');
    }
  };

  const resetButtonOnHide = function () {
    webix.html.removeCss(this.getNode(), 'custom_column_reset_show');
  };

  webix.protoUI({
    name: 'popup',
    toggle() {
      if (this.isVisible()) {
        this.hide();
      } else {
        this.show.apply(this, arguments);
      }
    },
  }, webix.ui.popup);

  webix.protoUI({
    name: 'editDataView',
  }, webix.EditAbility, webix.ui.dataview);

  webix.protoUI({
    name: 'editlist',
  }, webix.EditAbility, webix.ui.list);

  webix.DataStore.prototype.sorting.as.lowerString = function (a, b) {
    return a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1;
  };

  webix.protoUI({
    name: 'richselectWithoutPoint',
    $cssName: 'richselect',
    $render(obj) {
      obj.height = obj.label && obj.labelPosition !== 'left' ? 72 : 36;

      const suggestPopup = webix.$$(this.config.popup);
      const scrollView = this.getNode().closest('[view_id="scrollview"]');

      if (scrollView) {
        scrollView.addEventListener('scroll', () => {
          suggestPopup.hide();
        });
      }

      removeTopOrBottomPoint(this, suggestPopup, this.config.suggestCss);

      if (webix.isUndefined(obj.value)) return;
      this.$setValue(obj.value);
    },
  }, webix.ui.richselect);

  webix.protoUI({
    name: 'contextMenuSubmenu',
    $cssName: 'submenu',
    _set_point(mode, left, top) {
    },
    _hide_point() {
    },
    setPosition(x, y) {
      if (x !== 0) {
        x += 5;
        y += 3;
      }

      this.getNode().style.top = `${y}px`;
      this.getNode().style.left = `${x}px`;

      this.config.left = x;
      this.config.top = y;
    },
  }, webix.ui.submenu);

  webix.protoUI({
    name: 'multiselectWithoutPoint',
    getPopup() {
      removeTopOrBottomPoint(this, webix.$$(this.config.popup), this.config.suggestCss);

      return webix.$$(this.config.popup);
    },
    $render(obj) {
      removeTopOrBottomPoint(this, webix.$$(this.config.popup), this.config.suggestCss);

      if (webix.isUndefined(obj.value)) return;
      this.$setValue(obj.value);
    },
    show(node, mode, point) {
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);
    },
  }, webix.ui.multiselect);

  webix.protoUI({
    name: 'multicombogrid',
    defaults: {
      tagMode: false,
    },
    _resizeToContent() {
    },
  }, webix.ui.multicombo);

  webix.protoUI({
    name: 'multicombosearch',
    $render() {
      const suggestPopup = webix.$$(this.config.popup);

      suggestPopup.getNode().classList.add('suggest_popup');

      suggestPopup.attachEvent('onShow', () => {
        definePopupSizeAndPosition(suggestPopup);
      });
      suggestPopup.getList().attachEvent('onAfterScroll', () => {
        showSelectAllSeparator(suggestPopup);
      });
    },
    $init() {
      this.attachEvent('onKeyPress', () => {
        _.delay(() => {
          defineOverlayDisplay(this.getPopup());
          definePopupSizeAndPosition(this.getPopup());
          setSelectAllButtonState(this);
        }, 1);
      });
    },
    defaults: {
      tagMode: false,
      keepText: true,
      css: 'filter-multicombo',
      tagTemplate(selectedIds) {
        const selectedItemsIds = selectedIds.toString().split(',');
        const allElems = $$(this.id).getList().serialize(true);
        const selectedElems = allElems.filter(elem => selectedItemsIds.includes(`${elem.id}`));

        let resultTag = '';

        if (selectedElems.length && selectedElems.length === 1) {
          const picture = selectedElems[0].picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${selectedElems[0].picture})"></span>` : '';
          const textTag = `<span class="tag-options-text">${selectedElems[0].value}</span>`;

          resultTag = `<span class="tag-options-item">${picture}${textTag}</span>`;
        } else if (selectedElems.length && selectedElems.length > 1) {
          const maxElemsToShow = 2;
          const iconTags = selectedElems.slice(0, maxElemsToShow).map(elem => {
            const picture = elem.picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${elem.picture})"></span>` : '';

            return picture ? `<span class="tag-options-item">${picture}</span>` : '';
          }).join('');
          const textTag = selectedElems.length > maxElemsToShow ? `<span class="tag-options-text">+${selectedElems.length - maxElemsToShow}</span>` : '';

          if (iconTags) {
            resultTag = `${iconTags}${textTag}`;
          } else {
            resultTag = `${selectedElems.length} ${__('tag_items')}`;
          }
        }

        return resultTag;
      },
    },
  }, webix.ui.multicombo);

  webix.protoUI({
    name: 'suggestoverlay',
    defaults: {
      keyPressTimeout: 0,
      css: 'filter-multicombo-suggest',
      filter: (item, text) => item.value.toLowerCase().includes(text.toLowerCase()),
      on: {
        onHide() {
          const multicombo = $$(this.config.master);

          if (multicombo) {
            this.hideOverlay();
            webix.html.removeCss(multicombo.getNode(), 'filter-multicombo-open');
            resetMulticomboInputCustomActions(multicombo, multicombo.config.placeholder);
          }
        },
        onShow() {
          const multicombo = $$(this.config.master);

          multicombo.getInputNode().value = '';
          defineOverlayDisplay(this);
          setMulticomboInputCustomActions(multicombo);
          webix.html.addCss(multicombo.getNode(), 'filter-multicombo-open');
        },
      },
    },
    _suggest_after_filter() {},
    setValue(value) {
      const list = this.getList();

      if (value) {
        if (list.exists(value)) {
          list.select(value);
          list.showItem(value);
        }
      } else {
        list.unselect();
        list.showItem(list.getFirstId());
      }

      return value;
    },
  }, webix.ui.suggest, webix.OverlayBox);

  webix.protoUI({
    name: 'suggestoverlaybase',
    defaults: {
      keyPressTimeout: 0,
    },
    _toggleOption(id, ev, all) {
      let values = [];
      const master = $$(this._settings.master);
      const selectedValues = this.getValue() ? this.getValue().split(this._settings.separator) : [];
      const visibleItemIds = this.getList().data.order.map(id => id.toString());

      if (_.isString(ev)) {
        if (all.length) {
          values = _.union(selectedValues, all);
        } else {
          values = _.difference(selectedValues, visibleItemIds);
        }
      } else if (selectedValues.includes(id)) {
        values = _.without(selectedValues, id);
      } else {
        values = _.union(selectedValues, [id]);
      }

      const data = values.join(this._settings.separator);
      const text = this.setValue(values).join(this._settings.separator);

      if (master) master.setValue(data); else if (this._last_input_target) this._last_input_target.value = text;
      this.callEvent('onValueSuggest', [{
        id: data,
        text,
      }]);

      if (ev) {
        // only for clicks in checksuggest
        const checkbox = this.getList().getItemNode(id).getElementsByTagName('SPAN');

        if (checkbox && checkbox.length) checkbox[0].focus();
      }
    },
    setPosition(x, y) {
      const containerNode = $$(this.config.master).getNode();
      const containerYPos = containerNode.getBoundingClientRect().top;

      this._viewobj.style.top = `${y}px`;
      this._viewobj.style.left = `${x}px`;
      this._settings.left = x;
      this._settings.top = y;

      if (containerYPos > y) {
        definePopupSizeAndPosition(this);
      }
    },
  }, webix.ui.checksuggest, webix.OverlayBox);

  webix.protoUI({
    name: 'checksuggestoverlay',
    defaults: {
      selectAll: true,
      css: 'filter-multicombo-suggest',
      filter: (item, text) => item.value.toLowerCase().includes(text.toLowerCase()),
      on: {
        onValueSuggest() {
          const multicombo = $$(this.config.master);

          setSelectAllButtonState(multicombo);
          setMulticomboInputCustomActions(multicombo);
        },
        onHide() {
          const multicombo = $$(this.config.master);

          if (multicombo) {
            this.hideOverlay();
            webix.html.removeCss(multicombo.getNode(), 'filter-multicombo-open');
            resetMulticomboInputCustomActions(multicombo, multicombo.config.placeholder);
          }
        },
        onBeforeShow() {
          redefineSelectAllButtonText();
        },
        onShow() {
          const multicombo = $$(this.config.master);
          const input = multicombo.getNode();
          const inputCoords = input.getBoundingClientRect();
          const suggestPopup = this.$view;
          const documentHeight = document.documentElement.clientHeight;

          multicombo.getInputNode().value = '';
          defineOverlayDisplay(this);
          setSelectAllButtonState(multicombo);
          setMulticomboInputCustomActions(multicombo);
          webix.html.addCss(multicombo.getNode(), 'filter-multicombo-open');

          const list = this.getList();

          list.moveSelection = null;
          list.data.order = orderListItems(list.serialize(true)).map(item => item.id);
          list.render();

          if (suggestPopup.clientHeight > documentHeight - inputCoords.bottom) {
            suggestPopup.classList.remove('suggest_popup_bottom');
            suggestPopup.classList.add('suggest_popup_top');
            suggestPopup.style.top = 'auto';
            suggestPopup.style.bottom = `${inputCoords.top}px`;
          }
        },
      },
    },
  }, webix.ui.suggestoverlaybase);

  webix.protoUI({
    name: 'checksuggestseparatorsoverlay',
    defaults: {
      selectAll: true,
      css: 'filter-multicombo-suggest',
      filter(item, text) {
        if (isDividerItem(item.id)) {
          const list = this.getList();
          const items = list.serialize(true);

          return isDividerRequired(items, item.id, text);
        }

        return item.value.toLowerCase().includes(text.toLowerCase());
      },
      on: {
        onValueSuggest(item) {
          const itemsList = this.getList();
          const multicombo = $$(this.config.master);

          item.id.split(this.config.separator).forEach(itemId => {
            if (isDividerItem(itemId)) {
              itemsList.unselect(itemId);
            }
          });

          setSelectAllButtonState(multicombo);
          setMulticomboInputCustomActions(multicombo);
        },
        onHide() {
          const multicombo = $$(this.config.master);

          if (multicombo) {
            this.hideOverlay();
            webix.html.removeCss(multicombo.getNode(), 'filter-multicombo-open');
            resetMulticomboInputCustomActions(multicombo, multicombo.config.placeholder);
          }
        },
        onBeforeShow() {
          redefineSelectAllButtonText();
        },
        onShow() {
          const multicombo = $$(this.config.master);

          multicombo.getInputNode().value = '';
          defineOverlayDisplay(this, true);
          setSelectAllButtonState(multicombo);
          setMulticomboInputCustomActions(multicombo);
          webix.html.addCss(multicombo.getNode(), 'filter-multicombo-open');

          const list = this.getList();
          const listItems = createSortedResourcesSet(list.serialize(true));

          list.moveSelection = null;
          list.parse(listItems);
          list.data.order = listItems.map(item => item.id);
          list.render();
        },
      },
    },
  }, webix.ui.suggestoverlaybase);

  webix.protoUI({
    name: 'suggestcolor',
    defaults: {
      keyPressTimeout: 0,
      on: {
        onShow() {
          iniColorListPopupSizeAndPosition(this);
          resetButtonOnShow.call(this);
        },
        onHide() {
          resetButtonOnHide.call(this);
        },
      },
    },
  }, webix.ui.suggest);

  webix.protoUI({
    name: 'suggestsearch',
    defaults: {
      highlightSelection: true,
      keyPressTimeout: 0,
      on: {
        onShow() {
          suggestSearchOnShow.call(this);
          resetButtonOnShow.call(this, true);
        },
        onHide() {
          toggleFocusStyle(this.config.master);
          resetButtonOnHide.call(this);
        },
      },
    },
  }, webix.ui.suggest, webix.OverlayBox);

  webix.protoUI({
    name: 'multisuggestsearch',
    defaults: {
      highlightSelection: false,
      keyPressTimeout: 0,
      on: {
        onShow: suggestSearchOnShow,
        onHide() {
          toggleFocusStyle(this.config.master);
        },
      },
    },
  }, webix.ui.checksuggest, webix.OverlayBox);

  webix.protoUI({
    name: 'separatormultisuggestsearch',
    defaults: {
      keyPressTimeout: 0,
      highlightSelection: false,
      on: {
        onShow() {
          suggestSearchOnShow.call(this, true);
        },
        onHide() {
          toggleFocusStyle(this.config.master);
        },
      },
    },
  }, webix.ui.checksuggest, webix.OverlayBox);

  webix.protoUI({
    name: 'combo',
    getPopup() {
      removeTopOrBottomPoint(this, webix.$$(this.config.popup), this.config.suggestCss);

      return webix.$$(this.config.popup);
    },
    $render(obj) {
      removeTopOrBottomPoint(this, webix.$$(this.config.popup), this.config.suggestCss);

      if (webix.isUndefined(obj.value)) return;
      this.$setValue(obj.value);
    },
  }, webix.ui.combo);

  webix.protoUI({
    name: 'datepicker',
    $render(obj) {
      removeTopOrBottomPoint(this, webix.$$(this.config.popup), this.config.suggestCss);

      if (webix.isUndefined(obj.value)) return;
      this.$setValue(obj.value);
    },
  }, webix.ui.datepicker);

  webix.protoUI({
    name: 'datepickeredit',
    defaults: {
      editable: true,
      emptyAllowed: false,
      dateLimitFormater: dateFormats.checkIfDateInLimit,
    },
    setValue(dateValue) {
      let value = this.$prepareValue(dateValue);
      const isValidDate = dateValue instanceof Date && !isNaN(dateValue);
      const isValidValue = isValidDate || (!dateValue && this.config.emptyAllowed);
      const oldvalue = this._settings.value;

      if (this.$compareValue(oldvalue, value)) {
        if (this._rendered_input && value != this.$getValue()) {
          this.$setValue(value);
        }
        value = dateValue;
        this.callEvent('onChange', [value || '', oldvalue, !isValidValue]);

        return;
      }

      this._settings.value = value;
      if (this._rendered_input) {
        this.$setValue(value);
      }

      if (!isValidDate) {
        value = dateValue;
      }

      this.callEvent('onChange', [value || '', oldvalue]);
    },
    getValue() {
      return this._get_value_single(this._settings.value);
    },
    _get_value_single(value) {
      if (this._settings.editable) {
        const formater = dateFormats.convertGanttDateFormat(this.config.timepicker);
        const strValue = this.getText();

        const date = moment(strValue, formater);
        const isValid = date instanceof Date && !isNaN(date);

        if (strValue.length !== 0) {
          value = date.toDate();
          if (value instanceof Date && !isNaN(value)) {
            const dateString = strValue.toUpperCase();
            const isAM = dateString.includes('AM');
            const isPM = dateString.includes('PM');

            if (isAM || isPM) {
              const timeString = `${value.getHours()}:${value.getMinutes()} ${isAM ? 'AM' : 'PM'}`;
              const timeDate = webix.i18n.timeFormatDate(timeString);

              value.setHours(timeDate.getHours());
            }
          }
        } else {
          value = strValue;
        }
      }

      return value;
    },
    _set_visible_text() {
      const node = this.getInputNode();
      const text = this._settings.text.trim();

      if (_.isUndefined(node.value)) {
        node.innerHTML = text || this._get_div_placeholder();
      } else {
        node.value = text || '';
      }
    },
    _onBlur() {
      const text$$1 = this.getText();

      if (this._settings.text.trim() == text$$1 || _.isUndefined(this._settings.text) && !text$$1) {
        return;
      }
      const value = this._settings.editable ? this.getValue() : this.getPopup().getValue();

      this.setValue(value || '');
    },
  }, webix.ui.datepicker);

  webix.protoUI({
    name: 'popupWithoutPadding',
    defaults: {
      padding: 0,
    },
    _stopHide: true,
  }, webix.ui.popup);

  webix.protoUI({
    name: 'popupWithAnimation',
    defaults: {
      padding: 0,
      animationTime: 500,
    },
    _is_popup_shown: false,
    _add_shadow() {
      const currentShadow = document.querySelector('.popup_shadow');

      if (!currentShadow) {
        this._shadow = document.createElement('div');

        document.getElementsByTagName('body')[0].appendChild(this._shadow);

        this._shadow.className = 'popup_shadow';
      } else {
        this._shadow = currentShadow;
      }

      _.delay(_.bind(function () {
        if (this._shadow) this._shadow.classList.add('show');
      }, this));
    },
    _remove_shadow() {
      if (this._shadow || this._modal) {
        this._shadow && this._shadow.classList.remove('show');

        if (document.querySelector('.webix_modal')) {
          document.querySelector('.webix_modal').hidden = true;
        }

        this._shadow = null;
        this._modal = null;
      } else {
        document.querySelector('.popup_shadow') && document.querySelector('.popup_shadow').classList.remove('show');
      }
    },
    // {} -> {Number}
    _getNewTranslateValue() {
      const { isRtl } = this.config;
      const __$sidebarNode = document.querySelector('.left-sidebar-wrapper');

      if (isRtl) {
        return -Math.abs(parseInt(this.$view.style.width, 10));
      } if (__$sidebarNode) {
        return Math.abs(parseInt(this.$view.style.width, 10)) + parseInt(0, 10);
      }

      return 0;
    },
    _toggle_transform_popup(isShow) {
      if (isShow) {
        const __transformVal = this._getNewTranslateValue();

        this.$view.style.transform = `translateX(${__transformVal}px)`;
        this.$view.classList.add('visible');

        document.addEventListener('click', e => {
          if (e.target.classList.contains('popup_shadow') || e.target.closest('.sidebar-close-popup') || e.target.closest('.link')) {
            if (this._is_popup_shown) {
              this.callEvent('onBeforeAnimationHide', []);
            }
            this._remove_shadow();
            this._toggle_transform_popup(false);
            this._is_popup_shown = false;
            this.callEvent('onAfterAnimationHide', [this]);
          }
        });
      } else {
        this.$view.style.transform = 'translateX(0)';
        this.$view.style.position = 'fixed';
        this.$view.style.left = `-${this.$view.style.width}`;
        this.$view.classList.remove('visible');
      }
    },
    show(node, mode, point) {
      if (this._is_popup_shown) {
        return;
      }

      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      _.delay(_.bind(function () {
        this._add_shadow();
        this.callEvent('onShow', []);

        this._toggle_transform_popup(true);
        this._is_popup_shown = true;
      }, this));
    },
    isShown() {
      return this._is_popup_shown;
    },
    hide() {
      // debugger
      // if (this._is_popup_shown) {
      //   this.callEvent("onBeforeAnimationHide", []);
      // }
      // debugger
      this._remove_shadow();
      this._toggle_transform_popup(false);
      this._is_popup_shown = false;
      this.callEvent('onAfterAnimationHide', [this]);
      setTimeout(() => this.$view.style.display = 'none', 500);
    },
  }, webix.ui.popup);

  (new Image()).src = 'https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg'; // preloading

  webix.protoUI({
    name: 'windowBG',
    defaults: {
      zIndex: 101,
      padding: 40,
      borderless: true,
      border: 0,
    },
    _is_popup_shown: false,
    _add_shadow() {
      const currentShadow = document.querySelector('.window_bg');

      if (!currentShadow) {
        this._shadow = document.createElement('div');
        this._shadow.style.backgroundImage = "url('https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg')";
        document.getElementsByTagName('body')[0].appendChild(this._shadow);
        this._shadow.addEventListener('click', e => {
          e.stopPropagation();
        });
        this._shadow.className = 'window_bg';
      } else {
        this._shadow = currentShadow;
      }
    },
    _remove_shadow() {
      if (this._shadow && this._shadow.parentNode) {
        this._shadow.parentNode.removeChild(this._shadow);
      }
    },
    show(node, mode, point) {
      if (this._is_popup_shown) {
        return;
      }
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);
      _.delay(_.bind(function () {
        this._add_shadow();
        // this.callEvent("onShow", []);
      }, this));
      this._is_popup_shown = true;
    },
    isShown() {
      return this._is_popup_shown;
    },
    hide(force) {
      if (!force) return; // to avoid hide on resizes
      this._remove_shadow();
      this._is_popup_shown = false;
      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      popupHide(force);
    },
  }, webix.ui.popup);

  webix.protoUI({
    name: 'notificationPopup',
    defaults: {
      padding: 0,
      borderless: true,
      border: 0,
    },
    _is_popup_shown: false,
    _toggle_transform_popup(isShow) {
      const that = this;

      this.$view.style.transition = 'transform 540ms, opacity 540ms';
      this.$view.style.opacity = '0';
      this.$view.style.transform = 'translateY(25px)';
      this.$view.style.pointerEvents = 'none';
      this.$view.style.display = 'none';

      const callMethod = function () {
        if (!that.$view) {
          return false;
        }
        if (isShow) {
          that.$view.style.opacity = '1';
          that.$view.style.pointerEvents = 'auto';
          that.$view.style.transform = 'translateY(0)';
        } else {
          that.$view.style.opacity = '0';
          that.$view.style.pointerEvents = 'none';
          that.$view.style.transform = 'translateY(25px)';
        }
      };

      isShow ? this.$view.style.display = 'block' : this.$view.style.display = 'none';
      setTimeout(callMethod, 50);
    },
    show(node, mode, point) {
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      this.$view.style.boxSizing = 'border-box';
      this.$view.style.padding = '18px 24px 24px';

      _.delay(_.bind(() => {

      }, this));

      this.callEvent('onShow', []);
      this._toggle_transform_popup(true);
      this._is_popup_shown = true;
    },
    isShown() {
      return this._is_popup_shown;
    },
    hide(force) {
      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      if (this._is_popup_shown) {
        this.callEvent('onBeforeAnimationHide', []);
      }
      this._toggle_transform_popup(false);
      this._is_popup_shown = false;
      this.callEvent('onAfterAnimationHide', [this]);
      popupHide(force);
    },
  }, webix.ui.window);

  webix.protoUI({
    name: 'popupWithShadow',
    defaults: {
      padding: 0,
      zIndex: 999,
    },
    _is_popup_shown: false,
    _add_shadow() {
      const currentShadow = document.querySelector('.popup_shadow');
      const that = this;

      if (!currentShadow) {
        this._shadow = document.createElement('div');

        document.getElementsByTagName('body')[0].appendChild(this._shadow);

        this._shadow.className = 'popup_shadow no_animate';
      } else {
        this._shadow = currentShadow;
      }

      this._shadow.onclick = function (e) {
        that.hide();
      };

      _.delay(() => {
        if (that.config.shadow) {
          that._shadow.classList.add('show');
        }
      });
    },
    _remove_shadow() {
      if (this._shadow) {
        this._shadow.classList.remove('show');
        this._shadow = null;
      }
    },
    show(node, mode, point) {
      if (this._is_popup_shown) {
        return;
      }

      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      _.delay(_.bind(function () {
        this._add_shadow();
        this.callEvent('onShow', []);
      }, this));

      this._is_popup_shown = true;
    },
    isShown() {
      return this._is_popup_shown;
    },
    hide(force) {
      this.callEvent('onBeforeAnimationHide', []);
      this._remove_shadow();
      this._is_popup_shown = false;

      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      popupHide(force);
    },
  }, webix.ui.window);

  webix.protoUI({
    name: 'windowWithoutPadding',
    defaults: {
      padding: 0,
      _stopHide: true,
    },
    hide(force) {
      if (this._stopHide) {
        return;
      }

      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      popupHide(force);
    },
    _hide_sub_popups() {
    },
    ny() {
    },
  }, webix.ui.window);

  webix.protoUI({
    name: 'windowWithShadow',
    defaults: {
      padding: 0,
      animationTime: 500,
    },
    _is_popup_shown: false,
    _add_shadow() {
      const currentShadow = document.querySelector('.window_shadow');
      const that = this;

      if (!currentShadow) {
        this._shadow = document.createElement('div');
        this._shadow.addEventListener('click', () => {
          that.hide();
        });

        document.getElementsByTagName('body')[0].appendChild(this._shadow);

        this._shadow.className = 'window_shadow';
      } else {
        this._shadow = currentShadow;
      }

      _.delay(_.bind(function () {
        if (this._shadow) this._shadow.classList.add('show');
      }, this));
    },
    _remove_shadow() {
      if (this._shadow) {
        this._shadow.classList.remove('show');
        this._shadow = null;
      }
    },
    show(node, mode, point) {
      if (this._is_popup_shown) {
        return;
      }

      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      _.delay(_.bind(function () {
        this._add_shadow();
        this.callEvent('onShow', []);
      }, this));

      this._is_popup_shown = true;
    },
    hide(force) {
      this.callEvent('onBeforeAnimationHide', []);
      this._remove_shadow();
      this._is_popup_shown = false;
      // var popupHide = _.bind(webix.ui.window.prototype.hide, this);

      // popupHide(force);
    },
  }, webix.ui.window);

  webix.protoUI({
    name: 'ganttSlider',
    defaults: {
      sliderHandleWidth: 12,
      sliderPadding: 8,
      sliderBorder: 0,
    },
    $skin() {
      this.defaults.sliderHandleWidth = 12;
      this.defaults.sliderPadding = 8;
      this.defaults.sliderBorder = 0;
    },
  }, webix.ui.slider);

  webix.protoUI({
    name: 'ganttRangeSlider',
    defaults: {
      sliderHandleWidth: 12,
      sliderPadding: 8,
      sliderBorder: 0,
    },
    $skin() {
      this.defaults.sliderHandleWidth = 12;
      this.defaults.sliderPadding = 8;
      this.defaults.sliderBorder = 0;
    },
  }, webix.ui.rangeslider);

  webix.protoUI({
    name: 'popupWith6Padding',
    defaults: {
      padding: 7, // not work...
    },
  }, webix.ui.popup);

  webix.protoUI({
    name: 'popupWithoutPoint',
    _set_point(mode, left, top) {

    },
    _hide_point() {

    },
    defaults: {
      padding: 0,
      hidden: true,
    },
    _stopHide: true,
    show(node, mode, point) {
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      this._stopHide = true;

      _.delay(_.bind(function () {
        this._stopHide = false;
      }, this), 100 * 1.2);
    },
    hide(force) {
      if (this._stopHide) {
        return;
      }

      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      popupHide(force);
    },
  }, webix.ui.popup);

  webix.protoUI({
    name: 'popupWithoutPointCustom',
    _set_point(mode, left, top) {

    },
    _hide_point() {

    },
    show(node, mode, point) {
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);
    },
    hide(force) {
      if (this._stopHide || this.config.stopHide) {
        return;
      }

      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      popupHide(force);
    },
    defaults: {
      padding: 0,
    },
  }, webix.ui.popup);

  webix.extend({
    _get_input_width(config) {
      const width = (this._input_width || 0) - (config.label ? config.labelWidth : 0) - (config.iconWidth || 0);
      // prevent js error in IE

      return (width < 0) ? 0 : width;
    },
  }, webix.ui.text);

  webix.protoUI({
    name: 'customUploader',
    defaults: {
      counter: 0,
    },
    addDropZone(id, hover_text) {
      const node = webix.toNode(id);
      let extra_css = '';
      const self = this;

      if (hover_text) extra_css = ` ${webix.html.createCss({ content: `"${hover_text}"` }, ':before')}`;

      const fullcss = `webix_drop_file${extra_css}`;

      // web
      webix.event(node, 'dragenter', webix.html.preventEvent);
      webix.event(node, 'dragenter', e => {
        self.config.counter += 1;
        webix.html.addCss(node, fullcss, true);
      });

      webix.event(node, 'dragover', webix.html.preventEvent);
      webix.event(node, 'dragleave', webix.html.preventEvent);
      webix.event(node, 'dragleave', e => {
        self.config.counter -= 1;

        if (self.config.counter === 0) {
          webix.html.removeCss(node, fullcss);
        }
      });

      webix.event(node, 'drop', webix.html.preventEvent);
      webix.event(node, 'drop', webix.bind(function (e) {
        e.stopPropagation();
        e.preventDefault();

        self.config.counter = 0;
        webix.html.removeCss(node, fullcss);

        const data = e.dataTransfer;

        if (data && data.files.length) for (let i = 0; i < data.files.length; i++) this.addFile(data.files[i]);

        return webix.html.preventEvent(e);
      }, this));
    },
  }, webix.ui.uploader);

  webix.protoUI({
    name: 'lagCounter',
    $cssName: 'lagCounter',
    _applyChanges() {
      const newvalue = this.getValue();

      if (newvalue !== this.config.value) this.setValueHere(newvalue, true);
    },
    getValueHere() {
      const getVal = webix.ui.text.prototype.getValue.call(this);

      return getVal;
    },
    setValueHere(value) {
      return webix.ui.button.prototype.setValueCounter.call(this, value);
    },
    getValue() {
      return this.getValueHere();
    },
    setValue(value) {
      // return this.setValueHere(value);
      // value = this.$prepareValue(value);
      const oldvalue = this.config.value;

      if (this.$compareValue(oldvalue, value)) {
        if (this._rendered_input && value != this.$getValue()) this.$setValue(value);

        return;
      }

      // value = isNaN(+value) ? !!value : value;
      this.config.value = value;
      // if (this._rendered_input) this.$setValue(value);
      this.callEvent('onChange', [value, oldvalue]);
    },

    next(step) {
      let stepVal = step || 1;

      this.config.step = this.config.step ? this.config.step : 1;
      stepVal = this.config.step;
      this.shift(stepVal);
    },
    prev(step) {
      let stepVal = step || 1;

      this.config.step = this.config.step ? this.config.step : 1;

      stepVal = (-1) * this.config.step;
      this.shift(stepVal);
    },
    getShiftStepValue(step = 1, durationView) {
      const configDurationView = durationView || gantt.config.duration_view;
      const customWorkingDays = gantt.config.durationData.showDay;
      const customWorkingHours = gantt.config.durationData.showTime;
      let shiftStepValue;
      let workHours = 0;

      const defHours = dateFormats.getHoursArrayByUserTimeFormat();
      const selectedHoursByIntervals = timeIntervalHelper.getHoursArrayByIntervalArray(customWorkingHours, defHours);

      workHours = selectedHoursByIntervals.length / 2;

      switch (configDurationView) {
      case 'day':
        shiftStepValue = workHours * step;
        break;
      case 'week':
        shiftStepValue = workHours * customWorkingDays.length * step;
        break;
      case 'month':
        shiftStepValue = workHours * 4 * customWorkingDays.length * step;
        break;
      default:
        shiftStepValue = step;
        break;
      }

      return shiftStepValue;
    },
    shift(step) {
      const valueNum = timeParser.input(this.getValue(), {
        durationData: gantt.config.durationData,
        durationStep: 'hour',
        prop: 'lag',
      });

      const stepVal = this.getShiftStepValue(step, gantt.config.duration_view);
      const intermediateResultVal = valueNum.value / 60 + stepVal;
      const resultVal = `${intermediateResultVal}${timeParser.locale.hour}`;

      this.setValue(resultVal);
    },
  }, webix.ui.counter);

  webix.editors.ganttRichEditor = {
    getPopup() {
      if (!this.config.popup) {
        this.config.popup = this.createPopup();
      }

      const webixSuggestPopup = webix.$$(this.config.popup);
      let staticInp;

      if (!this.suggestInit) {
        webixSuggestPopup.define('padding', 0);
        webixSuggestPopup.getNode().classList.add('suggest_popup');
        webixSuggestPopup.resize();

        webixSuggestPopup.attachEvent('onShow', function () {
          const node = this.getNode();
          const nodeTriangles = document.getElementsByClassName('webix_point_top');
          let nodeTriangle = null;

          if (nodeTriangles.length) {
            for (let i = 0; i < nodeTriangles.length; i++) {
              if (nodeTriangles[i].style.display !== 'none') {
                nodeTriangle = nodeTriangles[i];
              }
            }
          }

          if (!nodeTriangle) {
            return true;
          }

          this.getNode().style.top = `${parseInt(this.config.top, 10) - 9}px`;
          this.getNode().style.left = `${parseInt(this.config.left, 10) + 6}px`;
          this.getNode().style.width = `${parseInt(this.config.width, 10) - 6}px`;
          this.getNode().classList.add('suggest_popup_bottom');

          nodeTriangle.style.display = 'none';
        });

        this.suggestInit = true;
      }

      return webixSuggestPopup;
    },
  };

  webix.editors.commentPopup = webix.extend({
    getPopup() {
      if (!this.config.$popup) this.config.$popup = this.createPopup();

      $$(this.config.$popup).define('padding', 8);
      $$(this.config.$popup).define('width', this.config.width);
      $$(this.config.$popup).define('minWidth', 300);

      $$(this.config.$popup).getNode().classList.add('comment_editor_popup');
      $$(this.config.$popup).resize();

      $$(this.config.$popup).attachEvent('onShow', () => {
        $$('$textarea1').getInputNode().setSelectionRange($$(this.config.$popup).getBody().getValue().length, $$(this.config.$popup).getBody().getValue().length);
      });

      return $$(this.config.$popup);
    },
  }, webix.editors.popup);

  webix.extend(webix.editors.ganttRichEditor, webix.editors.richselect);

  webix.editors.textTypeNumber = webix.extend({
    render() {
      return webix.html.create('div', {
        class: 'webix_dt_editor',
      }, "<input type='number' />");
    },
  }, webix.editors.text);

  webix.editors.lagCounterEditor = webix.extend({
    render() {
      const self = this;

      const leftButton = webix.html.create('button', {
        class: 'webix_inp_counter_prev',
        type: 'button',
        tabindex: '-1',
      }, '-');

      leftButton.onclick = function () {
        // self.setValue(+self.getValue() - 1);
        self.prev();
      };

      const rightButton = webix.html.create('button', {
        class: 'webix_inp_counter_next',
        type: 'button',
        tabindex: '-1',
      }, '+');

      rightButton.onclick = function () {
        // self.setValue(+self.getValue() + 1);
        self.next();
      };

      const input = webix.html.create('input', {
        class: 'webix_inp_counter_value',
        type: 'text',
        'aria-live': 'assertive',
      }, '');

      const node = webix.html.create('div', {
        class: 'webix_dt_editor',
      }, '');

      const nodeContainer = webix.html.create('div', {
        class: 'editor_lagCounter',
      }, '');

      node.appendChild(nodeContainer);

      nodeContainer.appendChild(leftButton);
      nodeContainer.appendChild(input);
      nodeContainer.appendChild(rightButton);

      return node;
    },
    getValue() {
      return this.getValueHere();
    },
    getValueHere() {
      const getVal = this.getInputNode(this.node).value;

      return getVal;
    },
    setValue(value) {
      this.getInputNode(this.node).value = value;
    },
    getInputNode() {
      return this.node.childNodes[0].querySelector('input');
    },
    next(step) {
      let stepVal = step || 1;

      this.config.step = this.config.step ? this.config.step : 1;
      stepVal = this.config.step;
      this.shift(stepVal);
    },
    prev(step) {
      let stepVal = step || 1;

      this.config.step = this.config.step ? this.config.step : 1;

      stepVal = (-1) * this.config.step;
      this.shift(stepVal);
    },
    getShiftStepValue(step = 1, durationView) {
      const configDurationView = durationView || gantt.config.duration_view;
      const customWorkingDays = gantt.config.durationData.showDay;
      const customWorkingHours = gantt.config.durationData.showTime;
      let shiftStepValue;
      let workHours = 0;

      for (let i = 0; i < customWorkingHours.length; i += 2) {
        workHours += customWorkingHours[i + 1] - customWorkingHours[i];
      }

      switch (configDurationView) {
      case 'day':
        shiftStepValue = workHours * step;
        break;
      case 'week':
        shiftStepValue = workHours * customWorkingDays.length * step;
        break;
      case 'month':
        shiftStepValue = workHours * 4 * customWorkingDays.length * step;
        break;
      default:
        shiftStepValue = step;
        break;
      }

      return shiftStepValue;
    },
    shift(step) {
      const durationStep = gantt.config.duration_view;

      const valueNum = timeParser.input(this.getValue(), {
        durationData: gantt.config.durationData,
        durationStep: 'hour',
        prop: 'lag',
      });

      const stepVal = this.getShiftStepValue(step, durationStep);
      // let resultVal = +valueNum.value + stepVal + timeParser.locale['hour'];
      const intermediateResultVal = valueNum.value / 60 + stepVal;
      const resultVal = `${intermediateResultVal}${timeParser.locale.hour}`;

      const updatedValue = timeParser.input(resultVal, {
        durationData: gantt.config.durationData,
        durationStep,
        prop: 'lag',
      });

      this.setValue(updatedValue.text);
    },
  }, webix.editors.text);

  webix.editors.counter = webix.extend({
    render() {
      const self = this;

      const leftButton = webix.html.create('button', {
        class: 'webix_inp_counter_prev',
        type: 'button',
        tabindex: '-1',
      }, '-');

      leftButton.onclick = function () {
        self.setValue(+self.getValue() - 1);
      };

      const rightButton = webix.html.create('button', {
        class: 'webix_inp_counter_next',
        type: 'button',
        tabindex: '-1',
      }, '+');

      rightButton.onclick = function () {
        self.setValue(+self.getValue() + 1);
      };

      const input = webix.html.create('input', {
        class: 'webix_inp_counter_value',
        type: 'text',
        'aria-live': 'assertive',
      }, '');

      const node = webix.html.create('div', {
        class: 'webix_dt_editor',
      }, '');

      node.appendChild(leftButton);
      node.appendChild(input);
      node.appendChild(rightButton);

      return node;
    },
    getValue() {
      let value = _.floor(this.getInputNode(this.node).value, 2);

      if (!value || value < 0) {
        value = 0;
      }

      return value;
    },
    setValue(value) {
      value = parseInt(value);

      if (!value || value < 0) {
        value = 0;
      }

      this.getInputNode(this.node).value = +value;
    },
    getInputNode() {
      return this.node.childNodes[1];
    },
  }, webix.editors.text);

  webix.editors.progressCounter = webix.extend({
    render() {
      const self = this;

      const leftButton = webix.html.create('button', {
        class: 'webix_inp_counter_prev',
        type: 'button',
        tabindex: '-1',
      }, '-');

      leftButton.onclick = function () {
        self.setValue(self.getValue() - 0.01);
      };

      const rightButton = webix.html.create('button', {
        class: 'webix_inp_counter_next',
        type: 'button',
        tabindex: '-1',
      }, '+');

      rightButton.onclick = function () {
        self.setValue(self.getValue() + 0.01);
      };

      const input = webix.html.create('input', {
        class: 'webix_inp_counter_value',
        type: 'text',
        'aria-live': 'assertive',
      }, '');

      const node = webix.html.create('div', {
        class: 'webix_dt_editor',
      }, '');

      node.appendChild(leftButton);
      node.appendChild(input);
      node.appendChild(rightButton);

      return node;
    },
    getValue() {
      return _.floor((parseInt(this.getInputNode(this.node).value)) / 100, 2);
    },
    setValue(value, isInited) {
      value = Math.round(value * 100);

      if (!value || value < 0) {
        value = 0;
      }

      if (value > 100) {
        value = 100;
      }

      this.getInputNode(this.node).value = value;
    },
  }, webix.editors.counter);

  webix.editors.customRichselect = webix.extend({
    popupInit(popup) {
      popup.define('autofit', false);
      popup.define('fitMaster', false);
      popup.define('width', 186);
      popup.define('padding', 0);
      popup.resize();

      popup.$view.classList.add('inline_select_popup');
      popup.$view.classList.add('list_view');
      popup.getList().getNode().classList.add('icon_multiselect');
      popup.getList().getNode().classList.add('webix_multilist');
      popup.linkInput(document.body);
      webix.editors.richselect.popupInit(popup);
    },
    getPopup() {
      if (!this.config.popup) this.config.popup = this.createPopup();

      const popupStatus = webix.$$(this.config.popup);
      const popupStatusCell = this.node;

      if (!popupStatusCell) {
        return popupStatus;
      }

      const docHeight = document.body.clientHeight;
      const domSizesTop = docHeight - popupStatusCell.getBoundingClientRect().top;
      const popupHeight = popupStatus.$height;

      if (domSizesTop > (popupHeight + 30)) {
        popupStatus.getNode().style.top = `${parseInt(popupStatus.config.top, 10) + 36}px`;
        popupStatus.getNode().classList.remove('suggest_popup_top');
        popupStatus.getNode().classList.add('suggest_popup_bottom');
      } else {
        popupStatus.getNode().style.top = `${parseInt(popupStatus.config.top, 10) - 10 - popupHeight}px`;
        popupStatus.getNode().classList.remove('suggest_popup_bottom');
        popupStatus.getNode().classList.add('suggest_popup_top');
      }

      return popupStatus;
    },

  }, webix.editors.richselect);

  webix.protoUI({
    name: 'activeDataTable',
  }, webix.ui.datatable, webix.ActiveContent);

  webix.protoUI({
    name: 'colorboardCst',
    render() {
      if (!this.isVisible(this.config.id)) return;

      const palette = this.config.palette;

      this.callEvent('onBeforeRender', []);
      const config = this.config;
      const functionTpl = function (obj) {
        return `<div role='gridcell' tabindex='-1' aria-label="${obj.val}" style="width:${obj.width}px;height:${obj.height}px;" webix_val="${obj.val}">${config.template(obj)}</div>`;
      };
      const itemTpl = (typeof config.template === 'function') ? functionTpl : webix.template(`<div role='gridcell' tabindex='-1' aria-label="{obj.val}" style="width:{obj.width}px;height:{obj.height}px;" webix_val="{obj.val}">${config.template || ''}</div>`);
      const data = { width: 0, height: 0, val: 0 };
      let width = this.$width;
      let height = this.$height;
      const widths = [];

      let html = '<div class="webix_color_palette"role="rowgroup">';

      const firstRow = (typeof palette[0] === 'object') ? palette[0] : palette;

      for (let i = 0; i < firstRow.length; i++) {
        widths[i] = Math.floor(width / (firstRow.length - i));
        width -= widths[i];
      }

      if (typeof palette[0] === 'object') {
        for (let r = 0; r < palette.length; r++) {
          const cellHeight = Math.floor(height / (palette.length - r));

          height -= cellHeight;
          const row = palette[r];

          html += renderRow(row, widths, cellHeight);
        }
      } else {
        html += renderRow(palette, widths, height);
      }

      html += '</div>';
      this.getNode().innerHTML = html;

      function renderRow(row, widths, height) {
        let rowHtml = '<div class="webix_color_row" role="row">';

        for (let cell = 0; cell < row.length; cell++) {
          data.width = widths[cell];
          data.height = height;
          data.val = row[cell];
          rowHtml += itemTpl(data);
        }
        rowHtml += '</div>';

        return rowHtml;
      }

      // this._selectBox = null;
      if (this.config.value) this.$setValue(this.config.value);
      else this.getNode().lastChild.childNodes[0].childNodes[0].setAttribute('tabindex', '0');
      this.callEvent('onAfterRender', []);
    },
  }, webix.ui.colorboard);

  webix.protoUI(
    { name: 'activeList' },
    webix.ui.list,
    webix.ActiveContent,
  );

  webix.protoUI({
    name: 'iconsuggest',
    defaults: {
      button: false,
      body: {
        rows: [
          {
            view: 'list',
            css: 'webix_multilist icon_multiselect',
            borderless: true,
            autoheight: true,
            yCount: 5,
            select: true,
            type: 'checklist',
            template(item) {
              const colorValue = item.value === '#FFFFFF' ? '#50C7D6' : item.value;
              const picture = item.picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${item.picture})"></span>` : '';
              const value = item.isColor ? `<span class="webix_icon color" style="background:${colorValue};"></span>` : item.value;

              return picture + value;
            },
            on: {
              onItemClick(id, e) {
                const item = this.getItem(id);

                item.$checked = item.$checked ? 0 : 1;
                this.refresh(id);
                const popup = this.getParentView().getParentView();

                popup._toggleOption(id, e);
              },
            },
          },
          {
            view: 'button',
            click() {
              const suggest = this.getParentView().getParentView();

              suggest.setMasterValue({ id: suggest.getValue() });
              suggest.hide();
            },
          },
        ],
      },
    },
    _toggleOption(id, ev) {
      const value = this.getValue();
      const values = webix.toArray(value ? this.getValue().split(',') : []);

      if (values.find(id) < 0) {
        values.push(id);
      } else values.remove(id);
      const master = webix.$$(this.config.master);

      if (master) {
        master.setValue(values.join(','));
        if (master.config.emptySeparator) {
          master.config.separator = ' ';
        }
      } else this.setValue(values);

      if (ev) {
        const checkbox = this.getList().getItemNode(id).getElementsByTagName('SPAN');

        if (checkbox && checkbox.length) checkbox[0].focus();
      }
    },
    getButton() {
      return this.getBody().queryView({ view: 'button' });
    },
    getList() {
      return this.getBody().queryView({ view: 'list' });
    },
  }, webix.ui.checksuggest);

  webix.protoUI({
    name: 'tagsuggest',
    defaults: {
      button: false,
      body: {
        rows: [
          {
            view: 'list',
            css: 'webix_multilist tag_multiselect',
            borderless: true,
            autoheight: true,
            yCount: 5,
            select: true,
            type: 'checklist',
            template(item) {
              return `<span class='tag_item' style='background: ${item.color}'></span>${item.value}`;
            },
            on: {
              onItemClick(id, e) {
                const item = this.getItem(id);

                item.$checked = item.$checked ? 0 : 1;
                this.refresh(id);
                const popup = this.getParentView().getParentView();

                popup._toggleOption(id, e);
              },
            },
          },
          {
            view: 'button',
            click() {
              const suggest = this.getParentView().getParentView();

              suggest.setMasterValue({ id: suggest.getValue() });
              suggest.hide();
            },
          },
        ],
      },
    },
    _toggleOption(id, ev) {
      const value = this.getValue();
      const values = webix.toArray(value ? this.getValue().split(',') : []);

      if (values.find(id) < 0) {
        values.push(id);
      } else values.remove(id);
      const master = webix.$$(this.config.master);

      if (master) {
        master.setValue(values.join(','));
      } else this.setValue(values);

      if (ev) {
        const checkbox = this.getList().getItemNode(id).getElementsByTagName('SPAN');

        if (checkbox && checkbox.length) checkbox[0].focus();
      }
    },
    getButton() {
      return this.getBody().queryView({ view: 'button' });
    },
    getList() {
      return this.getBody().queryView({ view: 'list' });
    },
  }, webix.ui.checksuggest);

  webix.protoUI({ // fix bug with requirements to click twice to open one suggest after another one
    name: 'contextmenu',
    $init() {
      const exceptions = [];

      this.attachEvent('onBeforeShow', () => {
        if (!_.some(exceptions, el => $$(el).isVisible())) {
          webix.callEvent('onClick', []);
        }
      });
    },
  }, webix.ui.contextmenu);

  webix.protoUI({ // fix bug with requirements to click twice to open one suggest after another one
    name: 'suggest',
    $init() {
      const exceptions = ['customPopupDayOption', 'historyDatesPopupId', 'timeTrackingPopup', 'customGridPopup'];

      this.attachEvent('onBeforeShow', () => {
        if (!_.some(exceptions, el => $$(el).isVisible())) {
          webix.callEvent('onClick', []);
        }
      });
    },
  }, webix.ui.suggest);

  webix.message = function (options) {
    return originallyWebixMessage({
      type: `global-notification global-notification_${options.type || 'info'} ${options.isExport ? 'export_notification' : ''}`,
      text: options.text || options,
      expire: options.expire || 8000,
    });
  };

  webix.message.keyboard = true;

  webix.resizeScroll = function (view) {
    const event = new Event('mouseover');

    view.dispatchEvent(event);
    webix.callEvent('onReconstruct', [view]);
  };

  webix.protoUI({
    name: 'activeTable',
  }, webix.ui.datatable, webix.ActiveContent, webix.OverlayBox);

  webix.protoUI({
    name: 'list',
  }, webix.ui.list, webix.OverlayBox);

  webix.protoUI({
    name: 'treetableWithOverlay',
  }, webix.ui.treetable, webix.OverlayBox);

  webix.protoUI({
    name: 'datatableWithOverlay',
  }, webix.ui.datatable, webix.OverlayBox);

  // duplication
  webix.protoUI({
    name: 'activeList',
  }, webix.ui.list, webix.ActiveContent);

  webix.protoUI({
    name: 'daysList',
    defaults: {
      select: true,
      multiselect: 'touch',
      height: 38,
      width: 432,
      scroll: false,
      align: 'center',
      css: 'dataview-days',
      borderless: false,
      labelPosition: 'top',
      xCount: 7,
      // startOnMonday: true,
      startOnMonday: user.settings && user.settings.start_monday !== null ? +user.settings.start_monday : true,
      type: {
        width: 56,
        height: 36,
      },
      template: "<div class='dataview-days-item gp_autotest_calendar_days_item'>#value#</div>",
    },
  }, webix.ui.dataview);

  webix.protoUI({
    name: 'customDateRange',
    defaults: {
      button: false,
      icons: false,
      calendarCount: 2,
      borderless: false,
      calendarHeight: 300,
    },
    _string_to_date(date, format) {
      if (typeof date === 'string') {
        if (format) date = webix.Date.strToDate(format)(date);
        else date = webix.i18n.parseFormatDate(date);
      }

      return isNaN(date * 1) ? null : date;
    },
    _correct_value(value) {
      if (!value) value = { start: null, end: null };

      if (!value.start && !value.end) value = { start: value };

      value.end = this._string_to_date(value.end) || null;
      value.start = this._string_to_date(value.start) || null;

      if ((value.end && value.end < value.start) || !value.start) value.end = [value.start, value.start = value.end][0];

      return value;
    },
    _isInRange(date) {
      const v = this.getValue();
      const s = v.start ? webix.Date.datePart(webix.Date.copy(v.start)) : null;
      const e = v.end ? webix.Date.datePart(webix.Date.copy(v.end)) : null;
      const d = webix.Date.datePart(date);
      let css = '';

      if (d >= s && e && d <= e) css = 'webix_cal_range';
      if (webix.Date.equal(d, s)) css = 'webix_cal_range_start';
      if (webix.Date.equal(d, e)) css = 'webix_cal_range_end';

      const holiday = `${webix.Date.isHoliday(date)} ` || '';

      return `${css} ${holiday}`;
    },
    _types: {
      time: -1,
      month: 1,
      year: 2,
    },
    _steps: {
      0: 1,
      1: 12,
      2: 120,
    },
    $init(config) {
      config.calendar = config.calendar || {};
      config.value = this._correct_value(config.value);
      delete config.calendar.type; // other types are not implemented

      config.css += ' webix_daterange custom-date-range';
      this._zoom_level = this._types[config.calendar.type] || 0;

      const cols = [];
      const calendar = webix.extend({ view: 'calendar' }, config.calendar || {}, true);
      const count = config.calendarCount = this._zoom_level === 0 ? (config.calendarCount || this.defaults.calendarCount) : this.defaults.calendarCount;
      const basecss = `${calendar.css ? `${calendar.css} ` : ''}webix_range_`;
      const start = config.value.start || new Date();

      for (let i = 0; i < count; i++) {
        const date = webix.Date.add(start, this._steps[this._zoom_level] * i, 'month', true);

        webix.extend(calendar, {
          events: webix.bind(this._isInRange, this),
          css: basecss + (count === 1 ? '' : (i === 0 ? '0' : (i + 1 == count ? 'N' : '1'))),
          timepicker: this._zoom_level === 0 ? config.timepicker : false,
          borderless: true,
          date,
          master: config.id,
        }, true);

        cols.push(webix.copy(calendar));
      }

      config.rows = [
        this._header_row(config),
        {
          type: 'clean',
          height: 300,
          cols,
        },
        { height: 12 },
        this._footer_row(config),
      ];
      config.height = this.defaults.calendarHeight + 98 + 16;
      config.width = 536;
      config.padding = 8;
      config.type = 'line';
    },
    setValue(value, silent) {
      const setValueOriginal = _.bind(webix.ui.daterange.prototype.setValue, this);

      setValueOriginal(value, silent);

      const child = this.getNode().querySelector(`.webix_cal_header_button [data-period="${value.period}"]`);

      if (child) {
        if (!child.parentNode.parentNode.classList.contains('active')) {
          child.parentNode.parentNode.classList.add('active');
        }
      }
    },
    _header_row(config) {
      const defaultIcons = {
        css: 'webix_cal_header ',
        borderless: true,
        template() {
          return `<div class='webix_cal_icons'><span class='webix_cal_icon_today webix_cal_icon'>${__('datepiker_today')}</span>`
            + `<span class='webix_cal_icon_clear webix_cal_icon'>${webix.i18n.calendar.clear}</span></div>`;
        },
        onClick: {
          webix_cal_icon_today: webix.bind(function () {
            this.setValue(this._add_date(new Date()));
            this.callEvent('onTodaySet', [this.getValue()]);
          }, this),
          webix_cal_icon_clear: webix.bind(function () {
            this.setValue('');
            this.callEvent('onDateClear', []);
          }, this),
        },
      };

      let icons = [];

      if (config.icons) {
        icons = config.icons.map((icon, index) => ({
          css: 'webix_cal_header_button',
          borderless: true,
          height: 36,
          width: 86,
          onClick: {
            webix_cal_header_button() {
              const node = this.getNode();
              const activeCss = 'active';

              if (!node.classList.contains(activeCss)) {
                webix.html.addCss(node, activeCss);

                _.each(node.parentNode.querySelectorAll('.webix_cal_header_button'), item => {
                  if (node !== item) {
                    webix.html.removeCss(item, activeCss);
                  }
                });
              }

              icon.on_click();
            },
          },
          template() {
            return `<span role='button' data-period='${icon.period}' tabindex='${index}' class='webix_cal_icon'>${icon.text}</span>`;
          },
        }));
      } else {
        icons = defaultIcons;
      }

      return {
        css: 'webix_range_header',
        padding: 8,
        width: 520,
        cols: icons,
      };
    },
    _footer_row(config) {
      const buttonOk = {
        view: 'button',
        value: __('common_done'),
        width: 110,
        height: 36,
        click() {
          this.getParentView().getParentView().getParentView().hide();
        },
      };
      const buttonCancel = {
        view: 'button',
        value: 'cancel',
        css: 'button_gray',
        width: 101,
        height: 36,
        click() {
          const customDateRangeSuggest = this.getParentView().getParentView().getParentView().getParentView();

          customDateRangeSuggest.callEvent('cancel');
          this.getParentView().getParentView().getParentView().hide();
        },
      };

      return {
        css: 'webix_range_footer',
        cols: [
          {},
          {
            cols: [
              // buttonCancel,
              buttonOk,
              { width: 10 },
            ],
          },
        ],
      };
    },
  }, webix.ui.daterange);

  webix.protoUI({
    name: 'customDateRangeSuggest',
    defaults: {
      type: 'customDateRange',
      body: {
        view: 'customDateRange',
        calendarCount: 2,
        icons: true,
        button: true,
        borderless: true,
      },
    },
    $prepareValue(value) {
      if (!value) {
        value = {
          start: null,
          end: null,
        };
      }
      if (!value.start && !value.end) {
        value = {
          start: value,
        };
      }
      value.end = this._string_to_date(value.end) || null;
      value.start = this._string_to_date(value.start) || null;
      if (value.end && value.end < value.start || !value.start) value.end = [value.start, value.start = value.end][0];

      return value;
    },
  }, webix.ui.daterangesuggest);

  webix.protoUI({
    $cssName: 'datepicker',
    name: 'customDateRangePicker',
    _init_popup() {
      const obj = this._settings;

      if (obj.suggest) obj.popup = obj.suggest;
      else if (!obj.popup) {
        obj.popup = obj.suggest = this.suggest_setter({
          view: 'customDateRangeSuggest',
          body: {
            timepicker: obj.timepicker,
            calendarCount: obj.calendarCount,
          },
        });
      }
      this._init_once = function () {
      };
    },
    stringValueToDates(value) {
      const results = {};

      switch (value) {
      case 'today':
        results.start = webix.Date.datePart(new Date());
        results.end = new Date(webix.Date.datePart(webix.Date.add(new Date(), 1, 'day')) - 1);
        break;
      case 'yesterday':
        results.start = webix.Date.datePart(webix.Date.add(new Date(), -1, 'day'));
        results.end = new Date(webix.Date.datePart(new Date()) - 1);
        break;
      case 'this_week':
        results.start = webix.Date.weekStart(new Date());
        results.end = new Date(webix.Date.weekStart(webix.Date.add(new Date(), 1, 'week')) - 1);
        break;
      case 'last_week':
        results.start = webix.Date.weekStart(webix.Date.add(new Date(), -1, 'week'));
        results.end = new Date(webix.Date.add(webix.Date.copy(results.start), 1, 'week') - 1);
        break;
      case 'this_month':
        results.start = webix.Date.monthStart(new Date());
        results.end = new Date(webix.Date.monthStart(webix.Date.add(new Date(results.start), 1, 'month')) - 1);
        break;
      case 'last_month':
        results.start = webix.Date.monthStart(webix.Date.add(new Date(), -1, 'month'));
        results.end = new Date(webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1);
        break;
      case 'this_month_workload':
        results.start = webix.Date.monthStart(new Date());
        results.end = new Date(webix.Date.monthStart(webix.Date.add(new Date(results.start), 1, 'month')) - 1);
        break;
      case 'last_month_workload':
        results.start = webix.Date.monthStart(webix.Date.add(new Date(), -1, 'month'));
        results.end = new Date(webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1);
        break;
      case 'twelve_months_workload':
        results.start = webix.Date.add(new Date(), -4, 'month');
        results.end = webix.Date.add(new Date(), 8, 'month');
        break;
      case 'six_months_workload':
        results.start = webix.Date.add(new Date(), -2, 'month');
        results.end = webix.Date.add(new Date(), 4, 'month');
        break;
      case 'three_months_workload':
        results.start = webix.Date.add(new Date(), -1, 'month');
        results.end = webix.Date.add(new Date(), 2, 'month');
        break;
      }

      return results;
    },
    $setValue(value) {
      const $setValueOriginal = _.bind(webix.ui.daterangepicker.prototype.$setValue, this);

      value = value || {};

      const isStringValue = /* Object.keys(value).length === 1 && */value.end !== null && value.period;
      let originalValue = value;

      const daterange = this.getPopup().getRange();

      if (isStringValue) {
        originalValue = value;
        value = this.stringValueToDates(value.period);
        value.period = originalValue.period;
        const element = daterange.getNode().querySelector(`.webix_cal_header_button [data-period="${value.period}"]`);

        if (element) {
          element.classList.add('active');
        }
      } else {
        const element = daterange.getNode().querySelector('.webix_cal_header_button.active');

        this.config.type = null;
        this.config.period = null;
        value.period = null;

        if (element) {
          element.classList.remove('active');
        }
      }

      $setValueOriginal(value);

      daterange.setValue(value);
      // setValueOriginal(value);

      if (!isStringValue) {
        this.config.type = null;
        this.config.period = null;
      } else {
        // this._settings.text = __("datepiker_" + originalValue.period);
        this.config.type = 'relative';
        this.config.period = value.period;
        const node = this.getInputNode();

        node.innerHTML = __(`datepiker_${originalValue.period}`);
      }
    },
    getValue() {
      const getValueOriginal = _.bind(webix.ui.daterangepicker.prototype.getValue, this);

      let value = getValueOriginal();
      const type = this.config.type;

      if (type === 'relative') {
        value = this.stringValueToDates(this.config.period);
        value.period = this.config.period;
      }

      return value || null;
    },
    $prepareValue(value) {
      return value || {};
    },
  }, webix.ui.daterangepicker);

  webix.protoUI({
    name: 'popupAnimate',
    getConfigView: ({
      position,
    }) => ({
      autoheight: true,
      position: position || 'center',
      borderless: true,
      move: false,
      head: false,
    }),
    _id: null,
    _is_popup_shown: false,

    $init(config) {
      this._id = config.id;

      webix.extend(config, this.getConfigView({
        mainId: this._mainId.bind(this),
        closePopup: this.hide.bind(this),
      }));
    },
    _toggle_transform_popup(isShow) {
      const that = this;

      this.$view.style.transition = 'transform 240ms, opacity 240ms';
      this.$view.style.opacity = '0';
      this.$view.style.transform = 'translateY(25px)';
      this.$view.style.pointerEvents = 'none';
      this.$view.style.display = 'none';

      const callMethod = function () {
        if (!that.$view) {
          return false;
        }
        if (isShow) {
          that.$view.style.opacity = '1';
          that.$view.style.pointerEvents = 'auto';
          that.$view.style.transform = 'translateY(0)';
        } else {
          that.$view.style.opacity = '0';
          that.$view.style.pointerEvents = 'none';
          that.$view.style.transform = 'translateY(25px)';
        }
      };

      isShow ? this.$view.style.display = 'block' : this.$view.style.display = 'none';
      setTimeout(callMethod, 50);
    },
    show(node, mode, point) {
      const popupShow = _.bind(webix.ui.window.prototype.show, this);

      popupShow(node, mode, point);

      this.callEvent('onShow', []);
      this._toggle_transform_popup(true);
      this._is_popup_shown = true;
    },
    isShown() {
      return this._is_popup_shown;
    },
    hide(force) {
      const popupHide = _.bind(webix.ui.window.prototype.hide, this);

      if (this._is_popup_shown) {
        this.callEvent('onBeforeAnimationHide', []);
      }
      this._toggle_transform_popup(false);
      this._is_popup_shown = false;
      this.callEvent('onAfterAnimationHide', []);
      popupHide(force);
    },
    _mainId(id) {
      return `${this._id}${id}`;
    },
  }, webix.ui.window);
}());
