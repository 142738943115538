<template>
  <div
    :id="gpAutotestName"
    :class="[type === 'tab' ? $.tabs : $.button , small ? $.small : '']"
  >
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="[type === 'tab' ? $.tabs_item : $.button_item, tab[activeBy] === activeItem[activeBy] ? $.active : '', gpAutotestName ? gpAutotestName + `_${tab.type}` : '']"
      :style="{borderColor: tab[activeBy] === activeItem[activeBy] ? color : 'transparent', width: (tabs.length === 2 && type !== 'tab') ? '174px' : ''}"
      @click="selectTab(tab)"
    >
      <sprite
        v-if="tab.icon"
        :class="$.tabs_item__icon"
        :name="tab.icon.name"
        :size="tab.icon.size || 24"
        :type="tab.icon.type || 'regular'"
        :color="tab[activeBy] === activeItem[activeBy] ? color : '#b2b2b2'"
      />
      <span
        :class="[type === 'tab' ? $.tabs_item__title : $.button_item__title]"
        :style="{marginLeft: tab.icon ? '6px' : '', color: type === 'tab' && tab[activeBy] === activeItem[activeBy] ? color : type === 'button' && tab[activeBy] === activeItem[activeBy] ? '#191919' : '#b2b2b2'}"
      >{{ tab.title }} {{ amounts[tab.type] >= 100 ? '99+' : amounts[tab.type] }}</span>
      <div
        v-if="tabs.length > 2 && type === 'button' "
        :class="[ $.button_item__separator]"
      />
    </div>
  </div>
</template>

<script>
import sprite from '../VueIcon/svgSprite.vue';

export default {
  components: { sprite },
  props: {
    gpAutotestName: {
      type: String,
      required: true,
      default: '',
    },
    tabs: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: true,
    },
    activeBy: {
      type: String,
      required: false,
      default: 'id',
    },
    type: {
      type: String,
      required: true,
      default: 'tab',
    },
    active: {
      type: Object,
      required: true,
    },
    amounts: {
      type: Object,
      required: false,
      default: {},
    },
    color: {
      type: String,
      required: false,
      default: '#1565C0',
    },
  },

  data() {
    return {
      activeItem: this.active,
    };
  },

  watch: {
    active(val) {
      this.activeItem = val;
    },
  },

  methods: {
    selectTab(tab) {
      this.activeItem = tab;
      this.$emit('onToggle', tab);
    },
  },
};
</script>

<style module='$' src='./less/tabs.less'></style>
