import store from '../../../store/main';

function onProjectStatusOptionCreated({ data }) {
  data.items.forEach(item => {
    const updateData = {};

    updateData.statusData = item;
    updateData.action = 'add';
    updateData.id = data.projectStatusId;
    store.commit('teamSetting/updateProjectStatusesOptions', updateData);
  });
}

export default onProjectStatusOptionCreated;
