import jiraModel from "./../../../../models/jira";

const __ = window.__;

export default {
  getTemplate: function () {
    return "<div class='jira-view'>" +
      "<div class='jira-view-desc'>" +
      "<div class='jira-view-desc-item important'><b>1 </b>- " + __('jira_integration_first_step_1') + "</div>" +
      "<div class='jira-view-desc-item'><b>2 </b>- " + __('jira_integration_first_step_2') + "</div>" +
      "<div class='jira-view-desc-item'><div><b>3 </b>- " + __('jira_integration_first_step_3_1') + " </div><input readonly onClick='this.select();' type='text' value='" + jiraModel.commonFields.appJiraLink + "'/><div> " + __('jira_integration_first_step_3_2') + "</div></div>" +
      "</div>" +
      "<div class='jira-view-screen first'>" + '' + "</div>" +
      "</div>";
  }
}

