/* eslint-disable arrow-body-style */
/* eslint-disable no-useless-catch */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */

// ===================================
// TODO
// в этом файле много методов которые используются по всему коду app/public
// но есть проблема в том что:
// 1. user.subscription.activites - это устаревшая непонятная хрень (например она равна 2 для юзера на новом плане зарегистрированном и оплатившем план в 2024. И она равна 6 для другого юзера. На это надо время чтоб удалить это и переписать)
// 2. user.team.activites - это тоже устаревшая непонятная хрень от которой надо избавиться
// я сильно не трогал этот файл, но надо его переписать на основе 1 и 2 пунктов
// и почистить бекенд от этого мусора
// по хорошему это все надо перенести в user.accountPlanData
// и убрать все эти поля из user.subscription

// точно не должны анализироваться на клиенте user.subscription и тем более  user.subscription.activites или  user.subscription.status_code
// team_users.status - не нужна
// ===================================

import axios from 'axios';
import app from '../app';
import constants from '../helpers/constants';
import _ from '../libs/lodash';
import teamModel from './team';

const plans = {};

plans.getStatus = function () {
  if (user.team.activites > 0) {
    return user.team.activites;
  }

  if (user.subscription.activites > 0) {
    return user.subscription.activites;
  }

  if (user.subscription.activites < 0) {
    user.subscription.activites = 0;
  }

  return user.subscription.activites;
};

// todo навести порядок с этими статусами и волшебными цифрами

plans.isTerminated = () => user.subscription.status_code === constants.PAYMENT_STATUSES.SUBSCRIPTION_TERMINATED.CODE;
plans.isSubscriptionChargeFailed = () => user.subscription.status_code === constants.PAYMENT_STATUSES.SUBSCRIPTION_CHARGE_FAILED.CODE;
plans.isRefunded = () => user.subscription.status_code === constants.PAYMENT_STATUSES.ORDER_REFUNDED.CODE;
plans.isFinished = () => user.subscription.status_code === constants.PAYMENT_STATUSES.SUBSCRIPTION_FINISHED.CODE;
plans.isPartialyRefunded = () => user.subscription.status_code === constants.PAYMENT_STATUSES.ORDER_PARTIALLY_REFUNDED.CODE;
plans.isManualRenewal = () => user.accountPlanData.is_manual_renew;
plans.isOrderProcessing = () => user.subscription.status_code === constants.PAYMENT_STATUSES.ORDER_PROCESSING.CODE;
plans.isPlanManuallySetBySaleOnTrial = () => user.subscription.status_code === constants.PAYMENT_STATUSES.TRIAL_ACTIVE.CODE && user.subscription.payment_plans_id !== constants.PLANS.TEAM_TRIAL;

// Максимальное количество пользователей для приглашения без участия отдела продаж
plans.maxUserCountToInviteWithoutSale = () => 50; // Вынеси это значение в константы, если оно повторяется

plans.isMaxUserCountToInviteWithoutSaleReached = () => user.team.invitations >= plans.maxUserCountToInviteWithoutSale(); /// user.subscription.user_count

plans.isCanNotBeUpdatedWithoutSales = () => {
  const reasons = {
    manualRenewal: plans.isManualRenewal(),
    orderProcessing: plans.isOrderProcessing(),
    partiallyRefunded: plans.isPartialyRefunded(),
    manuallySetBySaleOnTrial: plans.isPlanManuallySetBySaleOnTrial(),
    isMaxUserCountToInviteWithoutSaleReached: plans.isMaxUserCountToInviteWithoutSaleReached(),
  };

  const out = Object.keys(reasons).find(reason => reasons[reason]);

  if (out) {
    console.info(`%cWe can't provide upgrade popup because of this reason: ${out}`, 'color: yellow;');

    return out;
  }

  return false;
};

// Эта подписка не может быть обновлена и требует полностью новой подписки
plans.isRequireNewSubscriptionInsteadOfUpdate = () => {
  return plans.isTrial() // trial
  || plans.isTerminated() // terminated
  || plans.isRefunded() // refunded
  || plans.isFinished(); // finished
};

plans.isPaidTeam = () => _.includes([constants.PLANS.ESSENTIAL, constants.PLANS.TEAM_5, constants.PLANS.TEAM_10, constants.PLANS.TEAM_15], user.subscription.activites);

plans.isTeam = () => user.subscription.activites > 0 && user.subscription.is_team === 1;

plans.isTrial = () => user.subscription.payment_plans_id === constants.PLANS.TEAM_TRIAL;

plans.isActiveTrial = () => user.subscription.activites === constants.PLANS.TEAM_TRIAL;

plans.isExpiredTrial = () => plans.isTrial() && user.subscription.activites === 0 || (plans.isResourcesMoreThanPaid() && !plans.isExpired());

plans.isResourcesMoreThanPaid = () => teamModel.getCountOfLeftInvitation() < 0;

plans.isExpired = () => user.subscription.activites < 1;

plans.isActiveAccount = () => plans.getStatus() > 0 && user.team.status !== 2;

plans.isActive = () => user.subscription.activites > 1;

plans.isActiveSubscription = () => user.subscription.activites > 1 && user.subscription.status_code !== constants.PAYMENT_STATUSES.SUBSCRIPTION_SUSPENDED.CODE;

plans.isOverSubscription = () => user.subscription.activites < 1 && !plans.isTrial();

plans.isActiveTeamAccount = () => {
  const isUserAccount = plans.getStatus() >= 3; // Это значение можно вынести в константы, если оно повторяется

  return isUserAccount && user.team.status !== 2;
};

plans.getSubscriptionStatus = async provider => {
  try {
    const response = await axios.get('/payment/plan/getSubscriptionPriceDetails', { params: { provider } });
    // console.log(response.data);

    return response.data;
  } catch (error) {
    console.error('getSubscriptionStatus error', error);
  }
};

plans.upgradePlan = async upgradeData => {
  try {
    const response = await axios.post('/payment/plan/upgradePaymentPlan', upgradeData, {
      headers: { 'Content-type': 'application/json' },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

plans.sendLoginEmailForChangePaymentMethod = async () => {
  try {
    await axios.post('/payment/plan/sendOnetimeLoginEmail');
  } catch (error) {
    console.error(error);
  }
};

plans.getAffiliate = () => {
  if (user.affiliate && user.affiliate.percent && user.affiliate.coupon && user.affiliate.isStillValid) {
    return user.affiliate.percent;
  }

  return false;
};

export default plans;
