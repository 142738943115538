<template>
  <div class="permission-popup">
    <div class="head-wrapper ">
      <div class="popup-title">
        {{ locales('push_permission_title') }}
      </div>
      <div class="closer-icon">
        <svg-sprite
          :name="'close-1'"
          :type="'bold'"
          :size="24"
          :color="'#999999'"
          @click="closeClick"
        />
      </div>
    </div>
    <div class="body">
      <div class="pic">
        <img
          :src="img"
          alt=""
        >
      </div>
      <div
        class="popup-text text"
        v-html="text"
      />
      <div class="permission-btn">
        <vgp-button
          class="gp_autotest_confirm_popap_btn_ok"
          :type="'primary'"
          :small="true"
          :width="'90px'"
          :label="locales('request_permission')"
          @onClick="clickPermission"
        />
      </div>
    </div>
  </div>
</template>

<script>
import app from '../../../app';
// import userNotificationsModel from '../../../models/userNotifications';
import _ from '../../../libs/lodash';
import closer from '../../common/VueComponents/closer/closer.vue';

export default {
  components: { closer },
  data() {
    return {
      locales: __,
      img: `${GT.cdn}/imgs/notifications/example_push_notification.png`,
      text: __('request_notification_permission_text'),
    };
  },
  methods: {
    async clickPermission() {
      await Notification.requestPermission(permission => {
        if (permission === 'granted') {
          this.$store.dispatch('userNotification/subscribeToPushNotifications');
          // userNotificationsModel.subscribeToPushNotifications();
          // userNotificationsModel.switchDesktopNotifications(1)
          this.$store.dispatch('userNotification/switchDesktopNotifications', { trigger: 1 }).then(() => {
            _.delay(() => {
              app.trigger('userProfile:showNotifications');
            }, 100);
          });
        }
        if (permission === 'denied') {
          // userNotificationsModel.switchDesktopNotifications(0);
          this.$store.dispatch('userNotification/switchDesktopNotifications', { trigger: 0 });
          webix.message({ type: 'warning', text: __('blocked_or_incognito_mode_push') });
        }
      });
      await this.$store.dispatch('globalPopup/close');
    },
    closeClick() {
      this.$store.dispatch('globalPopup/close');
    },
  },
};
</script>

<style scoped lang='less'>
.permission-popup {
  width: 568px;
  padding: 24px 0;
  border-radius: 4px;
  box-shadow: 0px 9px 22px -2px #00000033;

    .head-wrapper {

      padding: 0 24px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      .popup-title {
        font-family: "Lato-Regular", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #191919;
        text-transform: none;
      }
      .closer-icon{
        height: 24px;
        cursor: pointer;
      }
    }



  .body {
    padding: 0 24px !important;
    text-align: left;

  .pic {
    margin-bottom: 16px;
  }

  .text {
    margin-bottom: 16px;
      color: #191919;
    font-family: "Lato-Regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

  }
  }
  .permission-btn {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
</style>
