// views/newProject/_trialFinishedUser.js
// !TODO: description

import app from '../../app';
import common from './common';
import viewsModes from '../../helpers/viewModes';
import projectsModel from '../../models/projects';
import Store from '../../store/main';
import store from '../../store/main';

const __ = window.__;

const ID_VIEW_SUBSCRIBE_FINISHED_WINDOW = 'subscribeFinishedPopup';
const ID_VIEW_SUBSCRIBE_FINISHED_BODY = 'subscribeFinishedPopupBody';

const { templates: { overSubscription } } = common;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

const webixUI = {
  view: 'window',
  id: ID_VIEW_SUBSCRIBE_FINISHED_WINDOW,
  css: 'choose-subscribe owner',
  borderless: true,
  header: false,
  headHeight: 0,
  zIndex: 50,
  modal: false,
  move: false,
  hidden: true,
  unClosable: true,
  padding: 10,
  position(state) {
    const leftSideBarWidth = Store.getters.leftSidebarWidth;

    state.height = document.body.clientHeight + 2;
    state.width = document.body.clientWidth - leftSideBarWidth + 1;
    state.left = leftSideBarWidth;
    state.top = 0;
  },
  body: {
    borderless: true,
    rows: [{
      view: 'template',
      id: ID_VIEW_SUBSCRIBE_FINISHED_BODY,
      css: 'trial_inner',
      borderless: true,
      scroll: true,
      data: {
        icon: `${GT.cdn}/imgs/over_gantt_imgs/sub_owner_new.png`,
        title: __('over_subscription_title_owner'),
        text: __('over_subscription_text_owner'),
        btn: __('over_subscription_btn_owner'),
        desc: __('over_subscription_desc_owner'),
        userType: 'owner',
      },
      template: overSubscription,
      onClick: {
        'js-upd-trial': function (e) {
          e.preventDefault();

          // app.trigger('paymentPlanInfo:show', {
          //   type: 'default'
          // });
          store.commit('paymentPlans/show');
          userExtAnalytics.log('owner_subscription_suspended_popup_upgrade_account_click');
        },
        'js-init-lc': function () {
          app.trigger('app:footer:livechat:click');
        },
      },
    }],
  },
  on: {
    onHide: onPopupHide,
    onShow: onPopupShow,
  },
};

const _events = {
  'body:resize': {
    fn: data => {
      $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW) && $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).resize();
    },
  },
  'leftSideBar:changedMode': {
    fn: () => {
      $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW) && $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).resize();
    },
  },
};

function onPopupShow() {
  const allProjects = projectsModel.getAllProjects();

  document.querySelectorAll('.webix_view .webix_disabled').forEach(item => item.style['background-color'] = 'inherit');

  if (!allProjects || allProjects.length === 0) {
    $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).getNode().style['background-color'] = '#BFBFBF';
  }

  Object.keys(_events).forEach(event => {
    _events[event].id = app.on(event, _events[event].fn);
  });

  app.trigger('user:overTrialOrSubscription', true);
  $$(ID_VIEW_SUBSCRIBE_FINISHED_BODY).getNode().querySelector('.inner_template').classList.add('visible');

  userExtAnalytics.log('owner_subscription_suspended_popup_show');
}

function onPopupHide() {
  Object.keys(_events).forEach(event => {
    app.off(_events[event].id);
  });
}

function init() {
  console.info('[_subscribeFinishedOwner] -> init'); // !DEBUG

  $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).show();
}

function cleanup() {
  console.info('_subscribeFinishedOwner -> cleanup'); // !DEBUG

  $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).hide();
}

export { webixUI, init, cleanup };
