import Polyglot from '../libs/polyglot';

function createLocaleShortcut(lang) {
  window.__ = (function () {
    const poly = new Polyglot({ phrases: window.lang });

    poly.locale('default');

    return webix.bind(poly.t, poly);
  }());
}

export default {
  $oninit(app, config) {
    createLocaleShortcut();
  },
};
