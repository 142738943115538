/* eslint-disable no-console */
import globalStore from '$$store/main';
import projectsModel from '../models/projects';
import timeIntervals from './timeIntervals';
import helperDate from './dateFormats';

import moment from '../libs/moment';

import _ from '../libs/lodash';

const WORKING_HOURS_LIMIT = 8;

const isResourceCalendarTotalEqualToProject = (resourceId, projectId) => {
  const {
    workingDays: resourceWorkingDays,
    workingHours: resourceWorkingHours,
  } = globalStore.getters['resourcesModel/getResourceById'](+resourceId);

  const { durationData: { showDay: projectShowDay, showTime: projectShowTime } } = projectsModel.getProjectConfig(+projectId);
  const projectWorkingDays = projectShowDay;
  const projectWorkingHours = timeIntervals.getHoursArrayByIntervalArray(projectShowTime, helperDate.getHoursArrayByUserTimeFormat()).length;

  if (resourceWorkingHours !== projectWorkingHours) {
    return false;
  }

  return _.isEqual(resourceWorkingDays, projectWorkingDays);
};

const isResourceCalendarTotalDifferentFromProject = (resourceId, projectId) => {
  const {
    workingDays: resourceWorkingDays,
    workingHours: resourceWorkingHours,
    customDays: resourceCustomDays,
  } = globalStore.getters['resourcesModel/getResourceById'](+resourceId);

  const { durationData: { showDay: projectShowDay, showTime: projectShowTime } } = projectsModel.getProjectConfig(+projectId);
  const projectWorkingDays = projectShowDay;
  const projectWorkingHours = timeIntervals.getHoursArrayByIntervalArray(projectShowTime, helperDate.getHoursArrayByUserTimeFormat()).length;

  const workingDayStats = {};

  resourceWorkingDays.forEach(weekDay => {
    if (projectWorkingDays.includes(weekDay)) {
      workingDayStats[weekDay] = _.min([resourceWorkingHours, projectWorkingHours]);
    }
  });

  resourceCustomDays.forEach(customDay => {
    const weekDay = moment(customDay.from).day();

    if (customDay.repeat === 'week' && projectWorkingDays.includes(weekDay)) {
      workingDayStats[weekDay] = customDay.workingHours;
    }
  });

  const workingHoursTotal = _.sum(Object.values(workingDayStats));

  if (workingHoursTotal < WORKING_HOURS_LIMIT) {
    console.warn(`Resource and project calendars are very different. The resource has about ${workingHoursTotal} working hours per week, but should have at least ${WORKING_HOURS_LIMIT}.`);

    return true;
  }

  return false;
};

const isResourceCalendarAppliedOnProject = projectId => {
  const project = projectsModel.getProjectConfig(+projectId);

  return project.apply_resource_calendar;
};

export {
  isResourceCalendarTotalDifferentFromProject,
  isResourceCalendarTotalEqualToProject,
  isResourceCalendarAppliedOnProject,
};
