var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    _vm._g(
      _vm._b(
        {
          staticClass: "svg-icon",
          class: [_vm.data.staticClass, _vm.data.class],
          style: [_vm.data.staticStyle, _vm.data.style],
          attrs: {
            viewBox: _vm.$options.methods.viewBox(_vm.props.size),
            width: _vm.props.size,
            height: _vm.props.size,
            fill: "none",
            role: "presentation",
          },
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.methods.icon(
                _vm.props.size,
                _vm.props.name,
                _vm.props.type
              )
            ),
          },
        },
        "svg",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }