// newProject/common.js
// A shared views/methods object

import routerHelper from '../../helpers/router';
import projectsModel from '../../models/projects';
import calculateMainWindowSize from '../../helpers/calculateMainWindowSize';
import VueRouter from "../../router/main";

const common = {
  ids: {
    ID_VIEW_POPUP_HEADER: 'createProjectLayoutHeader',
    ID_VIEW_HEADER_TITLE: 'createProjectLayoutHeaderTitle',
    ID_VIEW_HEADER_CLOSE_ICON: 'idCloseProjIcon',
    ID_VIEW_FORM_WINDOW: 'createProjectFormWindow',
    ID_FEATURE_NEWPROJ: 'newProjectBlock',
    ID_VIEW_IMPORT_BACK_BTN: 'createProjectLayoutHeaderBack',
    // trial or subscription complete
  },
  helpers: {
    adjustPopupPosition(state) {
      calculateMainWindowSize.apply(this, [state]);
    },
  },
  uiHandlers: {
    onCloseBtnClick() {
      if (!projectsModel.getAllProjects().length) {
        webix.message({
          type: 'info',
          text: __('tabs_msg_create_first_project'),
        });

        return;
      }


      VueRouter.back();
    },
    onBackBtnClick() {
      VueRouter.back();
    },
  },
  globalHandlers: {},
  views: {
    spaceFillerView: width => ({
      width,
      borderless: true,
    }),
    headerTitleView: (template, data, id = common.ids.ID_VIEW_HEADER_TITLE) => ({
      id,
      data,
      template,
      view: 'template',
      borderless: true,
    }),
    headerCloseBtnView: (template, data, onClick = null, id = common.ids.ID_VIEW_HEADER_CLOSE_ICON) => ({
      id,
      data,
      template,
      view: 'template',
      borderless: true,
      width: 30,
      height: 30,
      onClick: { close_button: onClick },
    }),
    projectHelpView: (featureType, id = null) => ({
      view: 'helpIcon',
      id: id || `${featureType}View`,
      dataId: featureType,
      css: 'help_icon_block',
      width: 30,
      height: 30,
      zIndex: 1,
    }),
    headerBackBtnView: (template, data, onClick = null, id = common.ids.ID_VIEW_IMPORT_BACK_BTN) => ({
      id,
      data,
      template,
      onClick: { back_btn: onClick },
      view: 'template',
      width: 120,
      borderless: true,
    }),
  },
  templates: {
    headerTitle: ({ infoIcon, value }) => `
            <p class="header_title">
                <span class="icon">${infoIcon}</span>
                <span>${value}</span>
            </p>`,
    headerCloseBtn: ({ icon }) => `
            <span class="close_button">${icon}</span>`,
    headerBackBtn: ({ icon, value }) => `
            <span class="back_btn">${icon} ${value}</span>`,
    noProject: ({
      caption, icon, content, description, btn, footer,
    }) => `
            <div class="no-project-info-container">
                <div class="no-project-info-row">
                    <div class="no-project-info-icon">
                        <img src="${icon}" alt="welcome to GanttPRO">
                    </div>
                    <div class="no-project-info-header">
                       ${caption}
                    </div>
                    <div class="no-project-info-text">
                        ${content}
                    </div>
                    <div class="no-project-info-desc">
                        ${description}
                    </div>
                    <a href='mailto:support@ganttpro.com' class="no-project-info-btn js-init-contact">${btn.text}</a>
                </div>
                <div class="no-project-info-footer">
                     ${footer}
                </div>
            </div>`,
    trialFinished: ({
      icon, title, text, text_01, text_02, btn, desc, userType,
    }) => `
            <div class="inner_template">
                <div class="template_main">
                    <div class="main_img">
                      <img src="${icon}" alt="">
                    </div>
                    <div class="main_content">
                      <h2 class="main_head">${title}</h2>
                      <p class="main_text">${text}</p>
                      <p class="main_text_01">${text_01}</p>
                      <p class="main_text_02">${text_02}</p>
                      <a href="${userType !== 'owner' ? 'mailto:support@ganttpro.com' : ''}" class="main_btn ${userType === 'owner' ? 'js-upd-trial' : ''}">${btn}</a>
                    </div>
                    <p class="main_desc">${desc}</p>
                </div>
            </div>`,
    overSubscription: ({
      icon, title, text, btn, desc, userType,
    }) => `
           <div class="inner_template">
                <div class="template_main">
                    <div class="main_img">
                      <img src="${icon}" alt="">
                    </div>
                    <div class="main_content">
                      <h2 class="main_head">${title}</h2>
                      <p class="main_text_02">${text}</p>
                      <a href="${userType !== 'owner' ? 'mailto:support@ganttpro.com' : ''}" class="main_btn ${userType === 'owner' ? 'js-upd-trial' : ''}">${btn}</a>
                    </div>
                    <p class="main_desc">${desc}</p>
                </div>
            </div>>`,
  },
};

export default common;
