<template>
  <div :class="$style.filter_header_wrap">
    <div :class="[$style.filter_header, $style.jc_space_between]">
      <span :class="$style.filter_header_title">
        {{ locales.filters }}
      </span>
      <div :class="$style.flex">
        <div
          v-show="showCleareBtn"
          id="gp_autotest_global_filter_btn_clear"
          :class="$style.filter_cleare_btn"
          @click="$emit('reset')"
        >
          {{ locales.clear }}
        </div>
        <webix-ui
          v-if="showHelper"
          id="gp_autotest_global_filter_btn_help"
          ref="help_icon"
          :class="[$style.filter_header_icon, $style.icon_helper]"
          :config="icons.helper"
        />
        <webix-ui
          id="gp_autotest_global_filter_btn_close"
          :class="$style.filter_header_icon"
          :config="icons.close"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div
      v-if="checkPricingAccess('saving_filters') && showCurrent"
      id="gp_autotest_global_filter_toggle_save_popup"
      :class="[$style.filter_header, $style.filter_header_current,
               checkPricingAccess('saving_filters') ? '' : 'pricing-tooltip']"
      @click="$emit('toggleSavedPopup')"
    >
      <webix-ui :config="icons.left_arrow" />
      <span> {{ locales.filter }} </span>
      <span :class="[$style.filter_current, $style.text_elipsis]"> {{ name }} </span>
    </div>
  </div>
</template>

<script>
import icon_close from '../../../svg/ic_close.svg';
import icon_leftArrow from '../../../svg/filter/leftArrow.svg';
import svgIconForVue from '../../common/svgIconForVue';
import helpIconForVue from '../../common/helpIconForVue';
import pricingHelpers from '../../../helpers/pricingHelper';

export default {
  name: 'HeaderFilter',
  props: {
    name: { type: String, required: false, default: '' },
    showCleareBtn: { type: Boolean, required: true },
    showHelper: { type: Boolean, required: false, default: true },
    showCurrent: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      locales: {
        filters: __('popup_filter'),
        clear: __('common_clear'),
        filter: __('actual_filter'),
        current_filter: __('filter_current_filter'),
      },
      icons: {
        // helper: svgIconForVue(icon_help, 24, 24),
        helper: helpIconForVue('filterPopupHelpBlock', 'filterViewHelpPopup', 24, 24),
        close: svgIconForVue(icon_close, 24, 24),
        left_arrow: svgIconForVue(icon_leftArrow, 15, 29),
      },
    };
  },

  methods: {
    checkPricingAccess(feature) {
      return pricingHelpers.checkPricingAccess(feature);
    },
  },
};
</script>

<style module src="../filter.less" lang="less" />
