var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpenSideBar && _vm.favProjects.length >= 1
    ? _c("div", { class: [_vm.$style.favProjects] }, [
        _c("div", { class: _vm.$style.delimiter }),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [_vm.$style.title, _vm.activeTitle ? _vm.$style.active : ""],
            attrs: { id: "gp_autotest_sidebar_favorite_projects_title" },
            on: { click: _vm.hideShowFavProjects },
          },
          [
            _c(
              "div",
              { class: _vm.$style.title_wrapper },
              [
                _c("svg-sprite", {
                  class: _vm.$style.title_wrapper_star,
                  style: { marginTop: "-1px" },
                  attrs: { name: "star", type: "bold", size: 20 },
                }),
                _vm._v(" "),
                _c("div", { class: _vm.$style.title_text }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.locale("sidebar_favorite_projects_title")) +
                      "\n      "
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("svg-sprite", {
              style: { marginTop: "1px" },
              attrs: {
                name: _vm.openFavProjects ? "arrow-top" : "arrow-down",
                size: 24,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.openFavProjects,
                expression: "openFavProjects",
              },
            ],
            class: [
              _vm.$style.fav_list,
              _vm.isEndScrolled ? "" : _vm.$style.haze,
            ],
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "scroll",
                    rawName: "v-scroll.self",
                    value: _vm.handleScrollProjects,
                    expression: "handleScrollProjects",
                    modifiers: { self: true },
                  },
                ],
                ref: "projectsScroll",
                class: _vm.$style.fav_scroll,
                style: { height: _vm.favHeight + "px" },
              },
              _vm._l(_vm.favProjects, function (project) {
                return _c(
                  "div",
                  {
                    key: project.id,
                    class: [
                      _vm.$style.project_item,
                      +_vm.activeItem === project.id ? _vm.$style.active : "",
                      "gp_autotest_sidebar_favorite_projects_item",
                    ],
                    attrs: {
                      "data-id": project.id,
                      "data-gantt_id": project.gantt_id,
                      "data-type": project.type,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.projectClick(project)
                      },
                    },
                  },
                  [
                    _c("svg-sprite", {
                      class: _vm.$style.project_item_icon,
                      attrs: { name: _vm.getProjectIcon(project), size: 20 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: _vm.$style.project_item_text,
                        attrs: { title: project.name },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(project.name) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }