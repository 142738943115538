<template>
  <div
    ref="vgpQuillEditorWrapper"
    v-click-outside="{
      handler: onBlur,
      closeConditional: () => active
    }"
    :class="['vgp-quill-editor-wrapper', draftBlur ? 'ql-draft' : '']"
  >
    <div
      id="vgpQuillEditorArea"
      :ref="componentKey"
      :class="['vgp-interactive-element ql-container ql-snow', draftBlur ? 'ql-draft ' : '']"
    />
    <div
      v-if="withButtons"
      class="vgp-quill-editor-actions"
    >
      <vgp-button
        type="secondary"
        :label="locales('common_cancel')"
        small
        @onClick="handleCancel"
      />
      <vgp-button
        :disabled="submitButtonDisabled || !!isImageUploading.length"
        :label="submitButtonLabel"
        type="primary"
        small
        @onClick="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import Quill from 'quill';

export default {
  name: 'VgpQuillEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    submitButtonLabel: {
      type: String,
      default: __('common_save'),
    },
    allowEmptySubmit: {
      type: Boolean,
      default: false,
    },
    mentionItemsCallback: {
      type: Function,
      default: null,
    },
    uploadImageCallback: {
      type: Function,
      default: null,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    withButtons: {
      type: Boolean,
      default: true,
    },
    focusOnInit: {
      type: Boolean,
      default: true,
    },
    scrollingContainer: {
      type: [Object, String],
      default: null,
    },
    toolbarListOptions: {
      type: Array,
      default: () => ['ordered', 'bullet', 'check'],
    },
    draftBlur: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      quillInstance: null,
      submitButtonDisabled: false,
      isImageUploading: [],
      linkClicked: false,
    };
  },
  computed: {
    componentKey() {
      return `vgp-quill-editor-${new Date().getTime()}`;
    },
    toolbarListConfig() {
      return this.toolbarListOptions.map(listOption => ({ list: listOption }));
    },
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.submitButtonDisabled = true;
        this.quillInstance.setText('');
      } else {
        this.quillInstance.root.innerHTML = newVal;
      }
    },
    active(val) {
      if (val && this.focusOnInit) {
        this.$nextTick(() => {
          this.focusQuillEditor();
        });
      }
    },
    draftBlur(val) {
      if (!val && this.quillInstance.getLength()) {
        this.quillInstance.setSelection(this.quillInstance.getLength(), 0);
        // this.quillInstance.formatText(0, this.quillInstance.getLength());
      }
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
  beforeDestroy() {
    this.$refs[this.componentKey]?.removeEventListener('click', this.emitFocus);
    this.quillInstance = null;
    delete this.quillInstance;
  },
  methods: {
    init() {
      this.quillInstance = new Quill(this.$refs[this.componentKey], {
        theme: 'snow',
        scrollingContainer: this.scrollingContainer,
        bounds: this.$refs[this.componentKey], // https://github.com/quilljs/quill/issues/1103#issuecomment-584788741
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            ['bold', 'italic', 'underline'],
            [...this.toolbarListConfig, { align: [] }],
            ['link'],
            [{ color: [] }, { background: [] }],
            ['clean'],
            // ['link', 'image', 'video'],
          ],
          mention: this.mentionItemsCallback ? this.getQuillMentionPluginConfig() : false,
          imageUploader: this.uploadImageCallback ? this.getQuillImageUploaderConfig() : false,
          autoLinks: true,
        },
        placeholder: this.placeholder,
      });

      if (this.disabled) {
        this.quillInstance.disable();
      }

      if (this.value) {
        this.quillInstance.pasteHTML(this.value);
      } else {
        this.submitButtonDisabled = true;
      }

      this.setFontSize();

      if (this.maxHeight) {
        this.setMaxHeight();
      } else {
        this.makeToolbarSticky();
      }

      if (this.focusOnInit && !this.draftBlur) {
        this.focusQuillEditor();
      } else {
        this.quillInstance.setSelection();
      }

      this.quillInstance.on('text-change', this.onTextChangeHandler);
      this.quillInstance.on('selection-change', this.onSelectionChangeHandler);
      this.$refs[this.componentKey].addEventListener('click', this.emitFocus);
    },
    onBlur() {
      this.$emit('blur', this.getHtml());
    },
    makeToolbarSticky() {
      const toolbar = this.$refs.vgpQuillEditorWrapper.querySelector('.ql-toolbar');

      toolbar.style.position = 'sticky';
      toolbar.style.top = 0;
    },
    setFontSize() {
      this.quillInstance.root.style.fontSize = `${this.fontSize}px`;
    },
    setMaxHeight() {
      const editor = this.quillInstance.root;

      editor.style.maxHeight = `${this.maxHeight}px`;
      editor.classList.add('gantt_scroll');
    },
    getHtml() {
      if (!this.quillInstance) return;

      const innerText = this.quillInstance.getInnerText().trim();
      const innerHtml = this.quillInstance.getHtml().trim();
      const formattedInnerHtml = innerHtml
        .replace(/<p><br><\/p>/g, '')
        .replace(/<p>\s*<\/p>/g, '');

      if (!innerText && !formattedInnerHtml) return null;

      return innerHtml;
    },
    getQuillMentionPluginConfig() {
      return {
        mentionDenotationChars: ['@'],
        mentionContainerClass: 'ql-mention-list-container gantt_scroll',
        dataAttributes: ['id', 'email', 'username'],
        source: this.mentionItemsCallback,
        renderItem(item) {
          return `<b>${item.username}</b> ${item.email}`;
        },
      };
    },
    getQuillImageUploaderConfig() {
      return {
        upload: file => {
          this.isImageUploading.push(1);
          this.$emit('imageUpload', true);

          return this.uploadImageCallback(file).finally(() => {
            this.isImageUploading.pop();
          });
        },
      };
    },
    onSelectionChangeHandler(range, range2) {
      if (range || range2.length) {
        const format = range && this.quillInstance.getFormat(range);

        // do not open link if click was in the end of line
        const isSelectionInTheEndOfLine = this.quillInstance.getLength() - range?.index === 1;
        this.linkClicked = false;
        if (format?.link && !isSelectionInTheEndOfLine && !this.active) {
          window.open(format.link, '_blank');
          this.quillInstance.container.querySelector('.ql-tooltip').classList.add('ql-hidden');
          this.quillInstance.setSelection(null);
          this.linkClicked = true;
        }
      } else {
        this.quillInstance.container.querySelector('.ql-tooltip').classList.add('ql-hidden');
      }
    },

    onTextChangeHandler(delta, oldDelta, source) {
      const html = this.getHtml();

      if (html) {
        this.submitButtonDisabled = false;
      } else if (this.value && this.allowEmptySubmit) {
        this.submitButtonDisabled = false;
      } else {
        this.submitButtonDisabled = true;
      }

      this.$emit('imageUpload', !!this.isImageUploading.length);

      this.$emit('text-change', html, { delta, oldDelta, source });
    },
    handleSubmit() {
      if (this.submitButtonDisabled) return;
      this.$emit('submit', this.getHtml());
    },
    handleCancel() {
      if (this.quillInstance) {
        this.quillInstance.setSelection();
      }
      this.$emit('cancel');
    },
    emitFocus() {
      !this.linkClicked && this.$emit('focus');
    },
    focusQuillEditor() {
      this.focusOnInit && this.quillInstance?.setSelection(this.quillInstance.getLength(), 0);
    },
  },
};
</script>

<style lang="less">
//@import 'quill-image-uploader/dist/quill.imageUploader.min.css';

// overriding default quill styles imported in app.less
.ql-editor {
  // https://github.com/quilljs/quill/issues/759#issuecomment-1068101882

  font-family: Lato-Regular, sans-serif;

  ul[data-checked=true],
  ul[data-checked=false] {
    pointer-events: none;
    // need for safari
    cursor: pointer;
  }
  ul[data-checked=true] > li *,
  ul[data-checked=false] > li * {
    pointer-events: all;
  }

  ul[data-checked=true] > li::before {
    content: '\2611';
  }
  ul[data-checked=false] > li::before {
    content: '\2610';
  }

  ul[data-checked=true] > li::before,
  ul[data-checked=false] > li::before {
    display: inline-block;
    cursor: pointer;
    pointer-events: all;
    color: #1665c0 !important;
    text-decoration: none !important;
    font-size: 1.6em;
    line-height: 1em;
    margin-right: 0.5em;
    vertical-align: top;
    height: 0.8em;
    margin-bottom: 0.2em;
  }

  .mention {
    font-family: Lato-Regular, sans-serif;
    color: #1565C0;
  }

  li:not(.ql-direction-rtl)::before {
    margin-left: -1em;
    margin-right: 0.3em;
    text-align: right;
  }

  p {
    margin-bottom: 0 !important;
  }

  em {
    font-style: italic;
  }
}

// overriding quill styles within vgpQuillEditor component
.vgp-quill-editor-wrapper {
  .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    max-height: 40px;
    border: 2px solid #4484CD;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    padding: 10px 12px;
    z-index: 4;
    background-color: white;
    .ql-formats {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #191919;
    }

  }
  &.ql-draft {
    .ql-toolbar.ql-snow {
      border: 1px solid #cccccc !important;
      border-bottom: 0  !important;
    }
  }
  #vgpQuillEditorArea {
    border: 2px solid #4484CD;
    border-top: 0;
    border-radius: 0 0 4px 4px;

    padding: 4px 14px 14px 14px;

    .ql-editor {
      cursor: default;
      padding: 0;
      min-height: 80px;
      .mention {
        font-family: Lato-Bold, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #1565C0;
      }
    }

    // https://github.com/quilljs/quill/issues/1374
    .ql-clipboard {
      position: fixed;
    }

    // Placeholder
    .ql-editor.ql-blank::before {
      font-family: Lato-Regular, sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-style: unset;
      left: 14px;
      color: #999999;
    }

    &.ql-draft{
      border: 1px solid #cccccc;
      border-top: 0;
    }

    .ql-hidden {
      display: none !important;
    }

  }

  .vgp-quill-editor-actions {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    margin-top: 12px;
  }

}

.ql-mention-list-container {
  max-height: 176px;
  max-width: 226px;
  overflow-y: hidden;
  box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 999999;
  .ql-mention-list {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    list-style: none;
    padding: 8px 0;
  }

  .ql-mention-list-item {
    padding: 4px 12px;
    cursor: pointer;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    b {
      font-family: Lato-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #191919;
      margin-right: 3px;
    }

    &.selected {
      background: #4484CD;
      color: #FFFFFF;
      b {
        color: #FFFFFF;
      }
    }
  }
}

</style>
