<template>
  <div :class="[$.progress_bar_layout, $[layoutClass]]">
    <div
      v-for="(item, index) in targetEvents"
      :key="index"
      :class="[$.progress_bookmark, checkActive(item.token) ? $.show : '']"
      :data-bookmark-id="item.token"
      @click="showProgressToast(item)"
    >
      {{ `${item.progress}%` }}
    </div>
  </div>
</template>

<script>
import { createToastInterface } from 'vue-toastification';
import routerHelper from '../../helpers/router';
import projectsModel from '../../models/projects';
import progressToast from './components/progressToast.vue';
import constants from '../../helpers/constants';

export default {
  name: 'ProgressBar',
  data() {
    return {
      progressItems: [],
      activeBookmarks: [],
    };
  },
  computed: {
    layoutClass() {
      const fullOverflow = this.progressItems.find(item => item?.blockType === 'full'
      && item.status !== constants.PROGRESS_BAR.STATUS.DONE
      && item.status !== constants.PROGRESS_BAR.STATUS.FAIL);

      const projectOverflow = this.progressItems.find(item => item?.blockType === 'project'
      && item.status !== constants.PROGRESS_BAR.STATUS.DONE
      && item.status !== constants.PROGRESS_BAR.STATUS.FAIL);

      if (fullOverflow) {
        return 'full_overflow';
      }

      if (projectOverflow) {
        return 'project_overflow';
      }

      return '';
    },
    targetEvents() {
      const progressEvents = this.$store.getters['progress/progressEvents'];

      return this.filteredEvents(progressEvents);
    },
  },
  watch: {
    $route(to, from) {
      const progressEvents = this.$store.getters['progress/progressEvents'];

      const isFinishedEvent = progressEvents.find(e => e.status === constants.PROGRESS_BAR.STATUS.DONE
      || e.status === constants.PROGRESS_BAR.STATUS.FAIL);

      if (progressEvents.length === 1 && isFinishedEvent) {
        return;
      }

      const targetEvents = this.filteredEvents(progressEvents);

      this.clearAll();
      this.init(targetEvents);
    },
    targetEvents(newEvents) {
      this.init(newEvents);
    },
  },
  methods: {
    init(events) {
      events.forEach(item => {
        const isCurrentProject = !item.projectIds.length || item.projectIds.find(projectId => projectId === projectsModel.getActiveGanttId());
        const isProgressIndex = this.progressItems.indexOf(this.progressItems.find(i => i.token === item.token));

        if (item.status === constants.PROGRESS_BAR.STATUS.STARTED) {
          if (isCurrentProject && isProgressIndex === -1) {
            this.progressItems.push(item);
            this.createToast(item);
          }
        }

        if (item.status === constants.PROGRESS_BAR.STATUS.UPDATE) {
          if (isCurrentProject && isProgressIndex === -1) {
            this.progressItems.push(item);
            this.createToast(item);
          }
        }

        if (item.status === constants.PROGRESS_BAR.STATUS.DONE) {
          if (isProgressIndex !== -1) {
            this.progressItems.splice(isProgressIndex, 1);
          }
        }

        if (item.status === constants.PROGRESS_BAR.STATUS.FAIL) {
          if (isProgressIndex !== -1) {
            this.progressItems.splice(isProgressIndex, 1);
          }
        }
      });
      this.activeBookmarks.filter(id => this.progressItems.find(itm => itm.token === id));
    },
    createToast(event) {
      this.$toast({
        component: progressToast,
        props: { config: event },
        listeners: {
          onHide: this.hideToast,
        },
      },
      {
        id: event.token,
        toastClassName: 'progress-toast',
        timeout: false,
        icon: false,
        closeButton: false,
        closeOnClick: false,
        hideProgressBar: true,
        draggable: false,
      });
    },
    showProgressToast(progressEvent) {
      const idx = this.activeBookmarks.indexOf(this.activeBookmarks.find(id => id === progressEvent.token));

      if (idx !== -1) this.activeBookmarks.splice(idx, 1);

      this.createToast(progressEvent);
    },
    hideToast(id) {
      setTimeout(() => {
        this.activeBookmarks.push(id);
      }, 500);
    },
    checkActive(token) {
      return this.activeBookmarks.find(id => id === token);
    },
    clearAll() {
      this.$toast.clear();
      this.progressItems = [];
      this.activeBookmarks = [];
    },
    filteredEvents(progressEvents) {
      let targetEvents = [];

      if (this.$route.name === 'project' || this.$route.params?.parent === 'project') {
        targetEvents = progressEvents.filter(e => e.target === 'project');
      }

      if (this.$route.name === 'newProject' || this.$route.name === 'importProject' || this.$route.name === 'importPreview') {
        targetEvents = progressEvents.filter(e => e.target === 'newProject');
      }

      if (this.$route.name === 'newProject') {
        targetEvents = progressEvents.filter(e => {
          if (e.targetEvent) {
            if (e.targetEvent !== 'MSProjectImport' && e.targetEvent !== 'ExcelProjectImport') {
              return e;
            }
          }

          return e;
        });
      }

      return targetEvents;
    },
  },
};
</script>

<style module='$' src='./less/main.less'></style>
