import app from '../../app';
import icon_close from '../../svg/close.svg';
import upgradePopup from '../payment/upgradePopup';
import helperFeedback from '../../helpers/feedback';

const __ = window.__;

const innerHandlers = {
  formRatingConfig() {
    const rating = document.querySelector('.rating_list');
    const ratingItem = document.querySelectorAll('.rating_list_item');

    rating.onclick = function (e) {
      const target = e.target;

      rating.classList.add('checked');
      if (target.classList.contains('rating_list_item')) {
        removeClass(ratingItem, 'current-active');
        target.classList.add('active', 'current-active');
      }

      const ratingItemCheck = document.querySelector('.current-active');

      getCurrentAllNextSiblings(ratingItemCheck);
    };
    rating.onmouseover = function (e) {
      const target = e.target;
      const ratingItemCheck = document.querySelector('.current-active');

      if (target.classList.contains('rating_list_item')) {
        removeClass(ratingItem, 'active');
        target.classList.add('active');
        mouseOverActiveClass(ratingItem);
      }

      if (rating.classList.contains('checked')) {
        getCurrentAllPrevSiblings(ratingItemCheck);
      }
    };
    rating.onmouseout = function () {
      const ratingItemCheck = rating.querySelector('.current-active');

      if (rating.classList.contains('checked')) {
        getCurrentAllPrevSiblings(ratingItemCheck);
      }
      ratingItem.forEach(item => {
        if (item.classList.contains('uncheck')) {
          item.classList.remove('active');
        }
        if (!ratingItemCheck) {
          item.classList.remove('active');
        }
      });
    };

    function removeClass(arr) {
      for (let i = 0, iLen = arr.length; i < iLen; i++) {
        for (let j = 1; j < arguments.length; j++) {
          ratingItem[i].classList.remove(arguments[j]);
        }
      }
    }
    function addClass(arr) {
      for (let i = 0, iLen = arr.length; i < iLen; i++) {
        for (let j = 1; j < arguments.length; j++) {
          ratingItem[i].classList.add(arguments[j]);
        }
      }
    }

    function mouseOverActiveClass(arr) {
      for (let i = 0, iLen = arr.length; i < iLen; i++) {
        if (arr[i].classList.contains('active')) {
          break;
        } else {
          arr[i].classList.add('active');
        }
      }
    }

    function getCurrentAllNextSiblings(ratingItemCheck) {
      if (!ratingItemCheck) return;
      const out = [];

      while (ratingItemCheck.nextElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.nextElementSibling);
      }

      out.forEach((item, index, arr) => {
        item.classList.remove('active');
        item.classList.add('uncheck');
      });

      return out;
    }

    function getCurrentAllPrevSiblings(ratingItemCheck) {
      const out = [];

      while (ratingItemCheck.previousElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.previousElementSibling);
      }

      out.forEach((item, index, arr) => {
        item.classList.add('active');
        item.classList.remove('uncheck');
      });

      return out;
    }
  },
  getRating() {
    let ratingCurrentText = document.querySelector('.rating_list .current-active');
    const ratingCurrentDefaultText = document.querySelector('.rating_list .active');

    if (ratingCurrentText === null) {
      ratingCurrentText = ratingCurrentDefaultText.getAttribute('date-rate');
    } else {
      ratingCurrentText = ratingCurrentText.getAttribute('date-rate');
    }

    const resultRat = ratingCurrentText;

    return resultRat;
  },
  getTextArea() {
    const textareaCurrentText = document.querySelector('.main_textarea').value;

    return textareaCurrentText;
  },
  getSelect() {
    const getValueSelect = document.querySelector('.option_select');

    if (getValueSelect.classList.contains('checked')) {
      return getValueSelect.textContent;
    }

    return '';
  },
  getValidateForm() {
    const ratingList = document.querySelector('.rating_list');
    const ratingTitle = document.querySelector('.rating_title');
    const selectList = document.querySelector('.option_select');
    const selectTitle = document.querySelector('.select_title');

    !ratingList.classList.contains('checked') ? ratingTitle.classList.add('error') : ratingTitle.classList.remove('error');
    !selectList.classList.contains('checked') ? selectTitle.classList.add('error') : selectTitle.classList.remove('error');

    if (ratingList.classList.contains('checked') && selectList.classList.contains('checked')) {
      if (!innerHandlers.getTextArea()) {
        document.querySelector('.main_textarea').classList.add('empty_error');
        document.querySelector('.textarea_title').classList.add('empty_error');

        return false;
      }
      document.querySelector('.main_textarea').classList.remove('empty_error');
      document.querySelector('.textarea_title').classList.remove('empty_error');

      return true;
    }

    return false;
  },
  toggleShowOptionsDropdown(open) {
    const el = document.querySelector('.option_container');

    open ? el.classList.add('open') : el.classList.remove('open');
  },
};

const innerObject = {

  mainView: {
    id: 'userAccountSubscribeForm',
  },

  getUserAccountPopup() {
    return `
      <div class="user-popup-content">
        <div class="popup-container">
          <!--    HEADER    -->
          <div class="popup-container-header">
            <div class="popup-container-header__title">
                ${__('subscribe_form_popup_form_header_title')}
            </div>
            <div class="popup-container-header__icon">
                ${icon_close}
            </div>
          </div>
          <!--    BODY    -->
          <div class="popup-container-body">
            <div class="popup-container-body__text">
                ${__('subscribe_form_popup_body_text')}
            </div>
            <div class="popup-container-body__select">
                <div class="select_title"> ${__('subscribe_form_popup_body_select_text')} </div>
                <div class='select_container add_dropdown' >
                  <span class="option_select" data-value="${__('subscribe_form_popup_body_select_option_00')}">${__('subscribe_form_popup_body_select_option_00')}</span>
                  <div class="option_container">
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_01')}">${__('subscribe_form_popup_body_select_option_01')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_02')}">${__('subscribe_form_popup_body_select_option_02')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_03')}">${__('subscribe_form_popup_body_select_option_03')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_04')}">${__('subscribe_form_popup_body_select_option_04')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_05')}">${__('subscribe_form_popup_body_select_option_05')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_06')}">${__('subscribe_form_popup_body_select_option_06')}</div>
                      <div class="option" data-value="${__('subscribe_form_popup_body_select_option_07')}">${__('subscribe_form_popup_body_select_option_07')}</div>
                  </div>
                </div>
            </div>
            <!--     RATING     -->
            <div class="popup-container-body__rating">
                <div class="rating_title"> ${__('subscribe_form_popup_body_rat_text')} </div>
                <div class="rating_list">
                    <div class="rating_list_item" date-rate="1" data-rate="1"> ${__('subscribe_form_reit_01')} </div>
                    <div class="rating_list_item" date-rate="2" data-rate="2"> ${__('subscribe_form_reit_02')} </div>
                    <div class="rating_list_item" date-rate="3" data-rate="3"> ${__('subscribe_form_reit_03')} </div>
                    <div class="rating_list_item" date-rate="4" data-rate="4"> ${__('subscribe_form_reit_04')} </div>
                    <div class="rating_list_item" date-rate="5" data-rate="5"> ${__('subscribe_form_reit_05')} </div>
                </div>
                <div class="rating_desc">
                    <div class="desc_left"> ${__('subscribe-form_popup_rating_footer_item_01')} </div>
                    <div class="desc_right"> ${__('subscribe-form_popup_rating_footer_item_02')} </div>
                </div>
            </div>
            <!--     TEXTAREA     -->
            <div class="popup-container-body__textarea">
                <div class="textarea_title"> ${__('subscribe-form-popup-form-textarea-head-title')} </div>
                <div class="textarea_desc"> ${__('subscribe-form-popup-form-textarea-head-desc')} </div>
                <div class="textarea_main">
                    <textarea class="main_textarea" form="text" name="feedback__text" placeholder="${__('subscribe-form_popup_text_area')}"></textarea>
                </div>
            </div>
          </div>
          <!--    FOOTER   -->
          <div class="popup-container-footer">
            <div class="popup-container-footer__button send">
                ${__('subscribe_popup_form_footer_button_text_01')}
            </div>
            <div class="popup-container-footer__button cancel">
                ${__('subscribe_popup_form_footer_button_text_02')}
            </div>
          </div>
        </div>
      </div>
    `;
  },

};

webix.ui({
  view: 'ganttproWindowPopup',
  id: 'userAccountSubscribeForm',
  css: 'user-form-popup',
  width: 636,
  // height: 'auto',
  head: false,

  body: {
    autoheight: true,
    view: 'template',
    id: 'userAccountSubscribeFormBody',
    css: 'user-popup-wrapper',
    template() {
      return innerObject.getUserAccountPopup();
    },
    onClick: {
      'popup-container-header__icon': function (e) {
        $$('userAccountSubscribeForm').hide();
      },
      rating_list() {

      },
      cancel(e) {
        $$('userAccountSubscribeForm').hide();
      },
      async send(e) {
        if (innerHandlers.getValidateForm() === false) {
          return false;
        }

        const message = innerHandlers.getTextArea();
        const rating = innerHandlers.getRating();
        const unsubscribe_type = innerHandlers.getSelect();

        $$('userAccountSubscribeForm').hide();

        await helperFeedback.send({
          type: 'unsubscribe-form',
          unsubscribe_type: unsubscribe_type || '',
          message: message || '',
          rating: rating || '',
        });

        $$('mainApplication').showProgress({
          type: "icon"
        });

        await webix.ajax().post('/payment/plan/suspend');

      },
      select_input(e) {
        innerHandlers.clickSelectEvent();
      },
      'popup-container-body__select': function (e, c, n) {
        const eTarget = e.target;
        const elDropdown = document.querySelector('.add_dropdown');
        const options = document.querySelectorAll('.option');
        const select = document.querySelector('.option_select');

        elDropdown.classList.add('changed');

        options.forEach(item => {
          item.classList.remove('active');
        });

        if (eTarget.closest('.option')) {
          eTarget.classList.add('active');
          select.textContent = eTarget.getAttribute('data-value');
          select.classList.add('checked');
        }
      },
      main_textarea() {
        document.querySelector('.main_textarea').classList.add('changed');
      },
      'user-popup-wrapper': function (e) {
        let needToShow;

        needToShow = e.target.closest('.select_container.add_dropdown')
                     && !e.target.classList.contains('option')
                     && !document.querySelector('.option_container').classList.contains('open');

        needToShow ? innerHandlers.toggleShowOptionsDropdown(true) : innerHandlers.toggleShowOptionsDropdown(false);
      },
    },
    on: {
      onAfterRender() {
        innerHandlers.formRatingConfig();
        document.querySelector('.main_textarea').onfocus = function () {
          document.querySelector('.main_textarea').classList.remove('empty_error');
          document.querySelector('.textarea_title').classList.remove('empty_error');
        };
      },
    },
  },
  on: {
    onHide() {
      app.trigger('popup:hide');
    },
    onAfterRender() {
      innerHandlers.formRatingConfig();
    },
  },
}).hide();

const outputObject = {
  init() {
    $$('userAccountSubscribeForm').show();
    webix.callEvent('onClick', []);
  },
};

export default outputObject;
