import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import Store from '../../../store/main';

async function onCustomFieldToProjectAssigned(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const columnsToProjects = event.data.assignedCustomFields;
  const projectIds = [];
  const columnsToUpdateValues = [];

  columnsToProjects.forEach(el => {
    Store.commit('columns/addColumnsToProjects', { columnIds: [el.customFieldId], projectIds: el.projectIds });
    projectIds.push(...el.projectIds);

    const column = {
      id: el.customFieldId,
      ganttIds: el.projectIds.map(id => ({ id, status: 'update' })),
    };

    columnsToUpdateValues.push(column);
  });

  await Promise.all([
    columnsToUpdateValues.map(column => Store.dispatch('columns/updateColumn', { column, isShow: false })),
  ]);

  app.trigger('userCustomColumnsModel:change');

  app.trigger('onAfterCollaboration', {
    event: event.event,
    assignedCustomFields: columnsToProjects,
    projects: [...new Set(projectIds)],
  });
}

export default onCustomFieldToProjectAssigned;
