var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-popup" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "main-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.locales("payment_popup_title")) + "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "period-toggle" }, [
        _c(
          "div",
          { class: ["period-tabs", _vm.isAnnual ? "annual" : "month"] },
          [
            _c(
              "div",
              {
                staticClass: "tab annually",
                on: {
                  click: function ($event) {
                    return _vm.togglePeriod(true)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "save-item",
                      _vm.isAnnual && _vm.nextCount === 1 ? "dnone" : "",
                    ],
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("save_up_locale")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v(_vm._s(_vm.locales("plans_annually_locale"))),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab monthly",
                on: {
                  click: function ($event) {
                    return _vm.togglePeriod(false)
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("plans_monthly_locale")) +
                    "\n        "
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "plans" },
        _vm._l(_vm.plans, function (plan, index) {
          return _c("plan-item", {
            key: index,
            attrs: {
              plan: plan,
              "is-annual": _vm.isAnnual,
              "is-popup": true,
              "number-of-plan": _vm.numberOfPlanItems,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "contact-footer",
        domProps: {
          innerHTML: _vm._s(_vm.locales("payment_popup_contact_footer")),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }