var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", {
    staticClass: "svg-icon",
    attrs: {
      viewBox: _vm.viewBox,
      width: _vm.size,
      height: _vm.size,
      fill: "none",
      role: "presentation",
    },
    domProps: { innerHTML: _vm._s(_vm.icon) },
    on: {
      click: function ($event) {
        return _vm.$emit("click", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }