<template>
  <div :class="$style.skeleton__header">
    <div :class="$style.skeleton__title">
      {{ title || locales('onboarding_wizard_project_name_placeholder') }}
    </div>
    <ul :class="$style.skeleton__nav">
      <li
        v-for="item in navItems"
        :key="item.value"
        :class="[$style.nav__item, item.value === activeNavItem ? $style.nav__item_active : '']"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WizardRightSectionHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    activeNavItem: {
      type: String,
      validator(value) {
        return ['diagram', 'list', 'board', 'people'].includes(value);
      },
      default: 'diagram',
    },
  },
  data() {
    return {
      locales: __,
      navItems: [
        {
          value: 'diagram',
          label: __('onboarding_wizard_right_section_nav_diagram_label'),
        },
        {
          value: 'list',
          label: __('onboarding_wizard_right_section_nav_list_label'),
        },
        {
          value: 'board',
          label: __('onboarding_wizard_right_section_nav_board_label'),
        },
        {
          value: 'people',
          label: __('onboarding_wizard_right_section_nav_people_label'),
        },
      ],
    };
  },
};
</script>

<style module lang="less">

.skeleton__header {
  display: flex;
  flex-direction: column;
}

.skeleton__title {
  font-family: Lato-Bold, sans-serif;
  font-size: 13px;
  line-height: 31px;
  color: #A8BCC1;
  margin-bottom: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.skeleton__nav {
  display: flex;
  gap: 10px;
  padding-left: 0 !important;
}

.nav__item {
  color: #BECFD3;
  font-family: Lato-Regular, sans-serif;
  font-size: 13px;
  line-height: 31px;
  padding: 0 5px;
  border-radius: 3.86965px;

  transition: all 1.4s ease;

  &_active {
    padding: 0 9px;
    background: #E7ECEC;
    color: #A8BCC1;
    font-family: Lato-Bold, sans-serif;
  }
}

</style>
