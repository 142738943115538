import app from '../../../app';
import _ from '../../../libs/lodash';

import projectsComponent from '../../../components/Project';

import projectsModel from '../../../models/projects';
import baselineModel from '../../../models/baselines';

import baselineObject from '../../gantt/modules/baseline';
import correctDates from '../../../helpers/correctDates';
import routerHelper from '../../../helpers/router';

import trash_icon from '../../../svg/default/trash.svg';
import baseline_icon from '../../../svg/baselines/baseline.svg';
import edit_icon from '../../../svg/baselines/edit.svg';
import globalStore from '../../../store/main';
import ganttViewModel from '../../../models/ganttViewModel';

import rights from '../../../components/rights';

const __ = window.__;

// if (!webix.env.touch && webix.ui.scrollSize) {
//   webix.CustomScroll.init();
// }

const innerSettings = {
  enabledBaselines: {},
  tasksToUpdate: [],
  baselineDisableCss: 'baseline-disabled',
};

const innerViews = {
  mainPopupId: 'baselinesPopup',
  createBaselineButton: {
    view: 'button',
    id: 'baseline-header',
    css: 'baseline-header',
    value: __('create_baseline'),
    height: 36,
    on: {
      onItemClick() {
        innerHandlers.createBaseline(gantt.config.gantt_id);
        userExtAnalytics.log('create_baseline_done', { from: 'header' });
      },
    },
  },
  createBaselineButtonLayout: {
    id: 'createBaselineButtonLayout',
  },
  baselineDataTable: {
    view: 'activeDataTable',
    width: 270,
    rowHeight: 36,
    height: 36 * 6,
    css: 'baseline-table',
    id: 'baselineDataTable',
    hover: 'baseline-table-row-hover',
    borderless: true,
    header: false,
    select: false,
    scrollY: true,
    editable: true,
    hidden: true,
    editaction: 'custom',
    footer: false,
    activeContent: {
      filterActive: {
        view: 'checkbox',
        width: 54,
        borderless: true,
        on: {
          onChange(newValue, oldValue) {
            if (this.config.$masterId) {
              const item = $$(innerViews.baselineDataTable.id).getItem(this.config.$masterId.row);

              $$(innerViews.baselineDataTable.id).disable();

              if (item) {
                const options = {
                  id: item.id,
                  gantt_id: item.gantt_id,
                  event_id: item.event_id,
                };

                let promise;

                if (newValue === 1) {
                  promise = innerHandlers.addBaselineTaskLayer(options);
                  userExtAnalytics.log('project_compare_baseline_done');
                } else {
                  promise = innerHandlers.hideBaseline(options);
                }

                promise.then(() => {
                  _.keys(innerSettings.enabledBaselines).length < 1 && innerHandlers.enableCheckboxes();
                  gantt.callEvent('ganttRender');
                });
              }
            }
          },
        },
      },
    },
    columns: [
      {
        id: 'id',
        hidden: true,
      },
      {
        id: 'filterActive',
        template(item, common) {
          return common.filterActive(item, common);
        },
        width: 36,
        css: 'baseline-table-cell',
      },
      {
        id: 'name',
        css: 'baseline-name',
        editor: 'text',
        template(obj) {
          return `<div title='${obj.name}'>${obj.name}</div>`;
        },
        fillspace: true,
      },
      {
        id: 'edit',
        css: 'baseline-table-cell',
        template: `<div class='baseline-table-icon'>${edit_icon}</div>`,
        width: 24,
      },
      {
        id: 'delete',
        css: 'baseline-table-cell',
        template: `<div class='baseline-table-icon baseline-table-icon-trash'>${trash_icon}</div>`,
        width: 24,
      },
      {
        maxWidth: 12,
      },
    ],
    on: {
      onItemClick(item) {
        const baselineDataTable = $$(innerViews.baselineDataTable.id);
        const baseline = baselineDataTable.getItem(item.row);

        if (baselineDataTable.hasCss(item.row, 'baseline-disabled') !== 1 && baseline) {
          if (item.column === 'delete') {
            innerHandlers.deleteBaselineClick(baseline);
          } else if (item.column === 'edit') {
            baselineDataTable.editStop();
            baselineDataTable.editRow(item.row);
            baselineDataTable.focusEditor();
          }
        } else {
          baselineDataTable.editCancel();
        }
      },
      onAfterEditStop(state, editor) {
        const newValue = state.value.trim();
        const item = this.getItem(editor.row);

        if (state.old === newValue) return;

        if (!newValue.length) {
          this.getItem(editor.row).name = state.old;
        }

        innerHandlers.updateBaselineName(item);
      },
      onBeforeRender() {
        if (_.keys(innerSettings.enabledBaselines).length > 0) {
          innerHandlers.disableCheckboxes();
        } else if (gantt.config.checkboxDisabled) {
          innerHandlers.enableCheckboxes();
        }
      },
      onAfterLoad() {
        const baselineDataTable = $$(innerViews.baselineDataTable.id);

        _.each(innerSettings.enabledBaselines, (item, key) => {
          try {
            const checkBox = baselineDataTable.filterActive(key);

            if (!checkBox.getInputNode().getAttribute('checked')) {
              checkBox.setValue(1);
            }
          } catch (e) {
            // console.error(e);
          }
        });

        const count = this.count();

        if (count < 6) {
          this.define('height', count * 36 + 1);
        } else {
          this.define('height', 6 * 36);
        }

        this.resize();
      },
    },
  },
  messageTemplate: {
    view: 'template',
    css: 'baseline-message',
    borderless: true,
    template: `<div class='no-baselines-container'>
      <div class='no-baselines-icon'>${baseline_icon}</div>
      <div class='no-baselines-text'>${__('there_are_not_baselines')}</div>
      </div>`,
    height: 132,
    width: 270,
  },
  baselineHeaderBorder: {
    id: 'baselineHeaderBorder',
    paddingX: 12,
    paddingY: 1,
    cols: [
      {
        height: 1,
        css: 'baseline-header-border',
      },
    ],
  },
  baselineEmptyPaddingBottom: {
    id: 'baselineEmptyPaddingBottom',
    height: 12,
  },
};

const innerHandlers = {
  checkAccessForActiveProject(ganttId) {
    const baselineDataTable = $$(innerViews.baselineDataTable.id);
    const canEdit = rights.project.hasRight(projectsModel.getActiveProjectData('gantt_id'), 'gantt_chart');

    // $$(innerViews.toolBarBaselineButtonLayout.id).show();

    if (!canEdit) {
      if (baselineModel.isEmpty()) {
        // $$(innerViews.toolBarBaselineButtonLayout.id).hide();
      }

      innerHandlers.hideCreateButton();
      innerHandlers.disableEditing(baselineDataTable);

      return;
    }

    innerHandlers.showCreateButton();
    innerHandlers.enableEditing(baselineDataTable);
  },
  enableCheckboxes() {
    const baselineDataTable = $$(innerViews.baselineDataTable.id);

    gantt.config.checkboxDisabled = false;

    baselineDataTable.eachRow(row => {
      baselineDataTable.removeRowCss(row, innerSettings.baselineDisableCss);
    });
  },
  disableCheckboxes() {
    const baselineDataTable = $$(innerViews.baselineDataTable.id);
    const keys = _.keys(baselineDataTable.data.pull);

    baselineDataTable.eachRow(row => {
      const disabled = baselineDataTable.hasCss(row, innerSettings.baselineDisableCss) === 1;

      if (!innerSettings.enabledBaselines[row] && _.includes(keys, row.toString())) {
        !disabled && baselineDataTable.addRowCss(row, innerSettings.baselineDisableCss);
      } else {
        disabled && baselineDataTable.removeRowCss(row, innerSettings.baselineDisableCss);
      }
    });

    gantt.config.checkboxDisabled = true;
  },
  createBaseline(gantt_id) {
    $$(innerViews.baselineDataTable.id).editStop();

    let actionHashPromise;

    if (gantt._correctDatesActionHash) {
      actionHashPromise = Promise.resolve(gantt._correctDatesActionHash);
    } else {
      actionHashPromise = projectsComponent.getLastActionHash(gantt_id);
    }

    return actionHashPromise
      .then(actionHash => {
        gantt._correctDatesActionHash = actionHash;

        return correctDates.run()
          .then(() => {
            baselineModel.create(gantt_id);
          });
      });
  },
  getDataForBaseline(options) {
    if (app.config.mode.isExport && app.config.mode.ignoreFilters) {
      return Promise.resolve(GT.ganttData.baselineTasks);
    }

    return baselineModel.getByGanttIdAndEventId(options.gantt_id, options.event_id)
      .then(data => Promise.resolve(data));
  },
  addBaselineTaskLayer(options) {
    return innerHandlers.getDataForBaseline(options)
      .then(data => {
        if (data && data.tasks && options.id) {
          innerHandlers.putInCookie(options);
          innerSettings.enabledBaselines[options.id] = 1;
          baselineObject.handlers.buildBaseline(data.tasks, options.id);
          _.keys(innerSettings.enabledBaselines).length > 0 && innerHandlers.disableCheckboxes();
          $$(innerViews.baselineDataTable.id).refresh();
        }
      });
  },
  hideBaseline(options) {
    delete innerSettings.enabledBaselines[options.id];
    innerHandlers.removeFromCookie(options.gantt_id, options.id);
    baselineObject.handlers.removeBaseline(options.id);

    const promise = webix.promise.defer();

    promise.resolve();

    return promise;
  },
  hideBeforeDelete(baseline) {
    innerHandlers.hideBaseline(baseline);

    if (_.keys(innerSettings.enabledBaselines).length < 1) {
      innerHandlers.enableCheckboxes();
    }
  },
  updateDataTable() {
    const baselineDataTable = $$(innerViews.baselineDataTable.id);

    baselineDataTable.clearAll();
    const baselines = baselineModel.getAll();

    baselineDataTable.parse(baselines);

    baselineDataTable.sort({
      by: 'id',
      dir: 'asc',
      as: 'int',
    });

    baselineDataTable.refresh();
  },
  updateBaselineName(item) {
    if (item) {
      baselineModel.updateName(item);

      _.each(document.querySelectorAll(`.baseline-${item.id} .baseline_name`), baseline => {
        baseline.innerHTML = item.name;
      });

      gantt.callEvent('ganttRender');
    }
  },
  putInCookie(options) {
    if (app.config.mode.isExport) {
      return;
    }
    const baselines = webix.storage.cookie.get('baselines') || {};
    const newBaseline = {};

    newBaseline[options.id] = options;
    baselines[options.gantt_id] = _.assign(baselines[options.gantt_id], newBaseline);

    webix.storage.cookie.put('baselines', baselines);
  },
  removeFromCookie(gantt_id, id) {
    const baselines = webix.storage.cookie.get('baselines');

    if (baselines && baselines[gantt_id]) {
      delete baselines[gantt_id][id];

      if (_.keys(baselines[gantt_id]).length === 0) {
        delete baselines[gantt_id];
      }
    }

    webix.storage.cookie.put('baselines', baselines);
  },
  showOrHideDataTable() {
    if (baselineModel.isEmpty()) {
      $$(innerViews.messageTemplate.id).show();
      $$(innerViews.baselineEmptyPaddingBottom.id).show();
      $$(innerViews.createBaselineButtonLayout.id).show();
      $$(innerViews.baselineDataTable.id).hide();
      $$(innerViews.baselineHeaderBorder.id).hide();
    } else {
      $$(innerViews.baselineDataTable.id).show();
      $$(innerViews.createBaselineButtonLayout.id).isVisible() && $$(innerViews.baselineHeaderBorder.id).show();
      $$(innerViews.messageTemplate.id).hide();
      $$(innerViews.baselineEmptyPaddingBottom.id).hide();
    }

    const canEdit = rights.project.hasRight(projectsModel.getActiveProjectData('gantt_id'), 'gantt_chart');

    if (!canEdit) {
      if (baselineModel.isEmpty()) {
        $$(innerViews.toolBarBaselineButtonLayout.id).hide();
      }
    }
  },
  showCreateButton() {
    $$(innerViews.createBaselineButtonLayout.id).show();
    $$(innerViews.baselineHeaderBorder.id).show();
  },
  hideCreateButton() {
    $$(innerViews.createBaselineButtonLayout.id).hide();
    $$(innerViews.baselineHeaderBorder.id).hide();
  },
  enableEditing(baselineDataTable) {
    !baselineDataTable.isColumnVisible('delete') && baselineDataTable.showColumn('delete');
    !baselineDataTable.isColumnVisible('edit') && baselineDataTable.showColumn('edit');
    baselineDataTable.config.editable = true;
  },
  disableEditing(baselineDataTable) {
    baselineDataTable.isColumnVisible('delete') && baselineDataTable.hideColumn('delete');
    baselineDataTable.isColumnVisible('edit') && baselineDataTable.hideColumn('edit');
    baselineDataTable.config.editable = false;
  },
  deleteBaselineClick(baseline) {
    webix.confirm({
      ok: __('common_yes'),
      cancel: __('common_no'),
      text: __('settings_msg_delete_baseline_confirm_text'),
      width: 420,
    }).then(result => {
      if (result) {
        $$(innerViews.baselineDataTable.id).editCancel();
        innerSettings.enabledBaselines[baseline.id] && innerHandlers.hideBeforeDelete(baseline);
        baselineModel.delete(baseline.id);
      }
    });
  },
  buildBaselines(baselines = webix.storage.cookie.get('baselines')) {
    if (!baselines) {
      innerSettings.enabledBaselines = {};
      gantt.config.row_height = gantt.config.original_row_height;

      return;
    }

    if (!rights.project.hasRight(gantt.config.gantt_id, 'gantt_chart')) {
      const baselinesActive = baselines[gantt.config.gantt_id];

      if (baselinesActive) {
        for (const key in baselinesActive) {
          innerHandlers.hideBaseline(baselinesActive[key]);
        }

        gantt.callEvent('ganttRender');
      }

      return;
    }

    const gantt_id = gantt.config.gantt_id;

    innerSettings.enabledBaselines = {};
    gantt.config.row_height = gantt.config.original_row_height;

    if (baselines && baselines[gantt_id] && app.config.mode.isBase) {
      gantt.config.baseline = 0;

      baselineModel.init()
        .then(() => {
          const promises = _.reduce(baselines[gantt_id], (promises, baseline) => {
            if (baseline && baselineModel.getItem(baseline.id) && promises.length < 1) {
              promises.push(innerHandlers.addBaselineTaskLayer(baseline));
            } else {
              innerHandlers.removeFromCookie(gantt_id, baseline.id);
            }

            return promises;
          }, []);

          webix.promise.all(promises)
            .then(results => {
              gantt.callEvent('ganttRender');
              if (innerSettings.copyPasteTaskID) {
                app.trigger('paste:special', innerSettings.copyPasteTaskID);
                innerSettings.copyPasteTaskID = null;
              }
            });
        });
    }
  },
};

webix.ui({
  id: innerViews.mainPopupId,
  view: 'popupWithoutPadding',
  css: 'baseline-popup',
  body: {
    padding: 0,
    rows: [
      innerViews.messageTemplate,
      {
        id: innerViews.createBaselineButtonLayout.id,
        paddingX: 18,
        paddingY: 11,
        hidden: true,
        cols: [
          innerViews.createBaselineButton,
        ],
      },
      innerViews.baselineHeaderBorder,
      innerViews.baselineEmptyPaddingBottom,
      innerViews.baselineDataTable,
    ],
  },
  on: {
    onBeforeShow() {
      baselineModel.init();
    },
    onHide() {
      globalStore.commit('headerView/toggleBaselinePopup', false);
    },
  },
});

const outputObject = {
  handlers: {
    baselines: {
      addBaselineTaskLayer: innerHandlers.addBaselineTaskLayer,
      hideBaseline: innerHandlers.hideBaseline,
      build: innerHandlers.buildBaselines,
      updated() {
        innerHandlers.updateDataTable();
        innerHandlers.showOrHideDataTable();
      },
      enableBaselinesDataTable() {
        _.delay(() => {
          $$(innerViews.baselineDataTable.id).enable();
        });
      },
      updateProject(projectType, projectId) {
        if (projectsModel.getActiveGanttId() === parseInt(projectId, 10)) {
          innerHandlers.buildBaselines();
        }
      },
      afterGanttInit(ganttId, projectData) {
        baselineModel.init()
          .then(() => {
            innerHandlers.checkAccessForActiveProject(ganttId);
            outputObject.handlers.baselines.build();
          });
      },
    },
  },
};

app.on('onAfterCollaboration', ({ event, data, projects }) => {
  if (event === 'ResourceRoleUpdated' || event === 'ResourceOnProjectsUpdated') {
    if (rights.project.hasRight(gantt.config.gantt_id, 'gantt_chart')) {
      return;
    }

    const baselineDataTable = $$(innerViews.baselineDataTable.id);

    _.each(innerSettings.enabledBaselines, (item, key) => {
      const checkBox = baselineDataTable.filterActive(key);

      checkBox.setValue(0);
    });
    innerSettings.enabledBaselines = {};

    if (globalStore.getters['headerView/isShowBaselinePopup']) {
      $$('baselinesPopup').hide();
    }
  }
});

gantt.attachEvent('setSpecialPasteID', taskID => {
  innerSettings.copyPasteTaskID = taskID;
});

export default outputObject;
