import app, { appIds } from "./../../../app";

import notificationModal from "../../notificationModal/notificationModal";
import plansModel from '../../../models/plans';
import rights from '../../../components/rights/index';

const __ = window.__;

const {
  events: { ID_EVENT_DISABLEGANTT_TOGGLE_BTNS }
} = appIds;

const innerHelpers = {
  showModalForNotOwner: function () {
    notificationModal.init({
      title: __('you_can_not_buy_title'),
      width: 420,
      unClosable: true,
      body: {
        css: "",
        template: __('you_can_not_buy_body')
      },
      buttons: [
        {
          width: 180,
          text: __('you_can_not_buy_button'),
          css: "",
          callback: function () {
            app.trigger('popup:show');
          }
        }
      ]
    });
  },
};

const innerHandlers = {
  // a special case for the mass-change
  disableGanttForMassChange: (disable = true) => {
    const __cnMassChange = 'masschange-disable';
    const __cnDisableHov = 'hover-disable';

    const __dhxGanttContainer = document.querySelector('.dhx_gantt_container');
    const __ganttLayout = __dhxGanttContainer ? __dhxGanttContainer.querySelector('.gantt_layout_root') : null;

    if (!__ganttLayout || !__dhxGanttContainer) {
      return;
    }

    if (disable) {
      !__dhxGanttContainer.classList.contains(__cnMassChange) && __dhxGanttContainer.classList.add(__cnMassChange);
      !__ganttLayout.classList.contains(__cnDisableHov) && __ganttLayout.classList.add(__cnDisableHov);
    } else {
      __dhxGanttContainer.classList.contains(__cnMassChange) && __dhxGanttContainer.classList.remove(__cnMassChange);
      __ganttLayout.classList.contains(__cnDisableHov) && __ganttLayout.classList.remove(__cnDisableHov);
    }
  },
  disableGanttChart: function (disable, isArchived) {
    if (!plansModel.isActive() && !disable) {
      return;
    }

    const dhxGanttContainer = document.querySelector('.dhx_gantt_container');

    if (disable) {
      !dhxGanttContainer.classList.contains('disable') && dhxGanttContainer.classList.add('disable');
    } else {
      dhxGanttContainer.classList.contains('disable') && dhxGanttContainer.classList.remove('disable');
    }

    if (isArchived) {
      app.trigger('gantt:keyboard:disable');
      !dhxGanttContainer.classList.contains('archived') && dhxGanttContainer.classList.add('archived');
    } else {
      app.trigger('gantt:keyboard:enable');
      dhxGanttContainer.classList.contains('archived') && dhxGanttContainer.classList.remove('archived');
    }
  },
  init: {
    run: function () {
      if (plansModel.isActiveTrial() || plansModel.isActive()) {
        return;
      }
      const isOwnerExist = !_.isNull(user.subscription.payment_plans_id);
      var dhxGanttContainer = document.querySelector('.dhx_gantt_container');

      if (dhxGanttContainer) {
        innerHandlers.disableGanttChart(true);
        document.querySelectorAll('.webix_view .webix_disabled').forEach(item => item.style['background-color'] = 'inherit');

        if (rights.account.isOwner() && isOwnerExist) {
          app.trigger('user:overTrialOrSubscription', true);
        } else {
          dhxGanttContainer.addEventListener("click", function (e) {
            e.preventDefault();
            app.trigger("ownerWasRemovedModal", {type: "default"});
          });
        }
      }

      if (!rights.account.isOwner() && !isOwnerExist) {
        app.trigger("ownerWasRemovedModal", {type: "default"});
      }

      app.trigger('gantt:keyboard:disable');
      this.hideCommonLeftSideBarButtons();
    },
    hideCommonLeftSideBarButtons: function () {

    },
    showCommonLeftSideBarButtons: function () {

    },
  }
};

const outputObject = {
  init: {
    run: innerHandlers.init.run,
    afterInit: function () {
      // innerHandlers.init.showCommonLeftSideBarButtons();
    },
    showCommonLeftSideBarButtons: innerHandlers.init.showCommonLeftSideBarButtons,
    hideCommonLeftSideBarButtons: innerHandlers.init.hideCommonLeftSideBarButtons,
  },
  disableGanttChart: innerHandlers.disableGanttChart,
  disableGanttForMassChange: innerHandlers.disableGanttForMassChange
};

export default outputObject;
