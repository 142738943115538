var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.profile_settings },
    [
      _c(
        "div",
        { class: _vm.$.title },
        [
          _c("svg-sprite", {
            class: [_vm.$.icon],
            attrs: {
              id: "gp_autotest_sidebar_settings_close_button",
              name: "arrow-left",
              type: "bold",
            },
            on: { click: _vm.sideBarBack },
          }),
          _vm._v(
            "\n    " + _vm._s(_vm.locales("settings_global_title")) + "\n  "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$.menu_list },
        _vm._l(_vm.menu, function (menuItem, index1) {
          return _c(
            "div",
            { key: index1 },
            [
              _vm._l(menuItem, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: [
                      _vm.$.menu_item,
                      item.type === _vm.activeItem ? _vm.$.active : "",
                    ],
                  },
                  [
                    !item.hidden && item.type !== "logout"
                      ? _c(
                          "div",
                          {
                            class: _vm.$.wrapper,
                            attrs: {
                              id:
                                "gp_autotest_sidebar_settings_menu_item_" +
                                item.type,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onChageRoute(item.type)
                              },
                            },
                          },
                          [
                            _c("svg-sprite", {
                              class: [
                                _vm.$.icon,
                                item.type === "logout" ? _vm.$.logout : "",
                              ],
                              attrs: { name: item.icon, size: 20 },
                            }),
                            _vm._v(" "),
                            _c("div", { class: _vm.$.name }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.title) +
                                  "\n            "
                              ),
                              _vm.checkErrorsInSetting(item)
                                ? _c(
                                    "span",
                                    [
                                      _c("svg-sprite", {
                                        attrs: {
                                          name: "warning-1",
                                          size: "20",
                                          color: "#FF9A00",
                                          type: "bold",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.type === "logout"
                      ? _c(
                          "div",
                          { class: _vm.$.logout_wrapper },
                          [
                            _c("vgp-button", {
                              attrs: {
                                id: "gp_autotest_sidebar_settings_logout_button",
                                type: "text",
                                icon: { name: "logout" },
                                small: true,
                                "icon-side": "left",
                                label: item.title,
                              },
                              on: {
                                onClick: function ($event) {
                                  return _vm.onChageRoute(item.type)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              menuItem.length > 1
                ? _c("div", { class: _vm.$.delimiter })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: _vm.isShowLogoutPopup,
          "close-icon": "",
          title: _vm.locales("settings_confirm_logout_title"),
          text: _vm.locales("settings_confirm_logout"),
          "cancel-button": {
            title: _vm.locales("common_no"),
            type: "secondary",
            id: "gp_autotest_sidebar_settings_logout_confirm_cancel_button",
          },
          "ok-button": {
            title: _vm.locales("common_yes"),
            type: "destructive",
            id: "gp_autotest_sidebar_settings_logout_confirm_ok_button",
          },
        },
        on: {
          onClickCancel: function ($event) {
            _vm.isShowLogoutPopup = false
          },
          onClickOk: _vm.logout,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }