import { BrowserAuthError, ServerError, BrowserCacheLocation } from '@azure/msal-browser';
import * as msal from '@azure/msal-browser';
import { v4 as uuidv4 } from 'uuid';
import usersAttachmentTypeModel from '../../../../models/usersAttachmentType';
import globalStore from '../../../../store/main';

const OneDriveAttachmentsController = {

  loadMicrosoftApiAndSetMicrosoftUser() {
    return new Promise((resolve, reject) => {
      this.init();
      this.setMicrosoftUserToStore();
    });
  },

  createDocument(mimeType, taskData) {
    // todo need to check posibility to create
    return new Promise((resolve, reject) => {});
  },

  setActiveMicrosoftAccount(authResult) {
    this.app.setActiveAccount(authResult.account);
    const profile = authResult.account;

    usersAttachmentTypeModel.addUsersAttachmentType({
      access_token: authResult.accessToken,
      expires_at: (new Date(authResult.expiresOn).getTime()) / 1000, // extExpiresOn
      rawtoken: JSON.stringify({
        scopes: authResult.scopes,
      }),
      rawprofile: JSON.stringify(authResult), // profile
      email: profile.username,
      folder_id: null,
      folder_name: null,
      rawfolder: null,
      key: 'oneDrive',
    });
    OneDriveAttachmentsController.setMicrosoftUserToStore();
  },

  onFilesPicked({ selectedFilesData, taskData }) {
    return new Promise(async (resolve, reject) => {
      const payload = {
        ganttId: taskData.gantt_id,
        taskId: taskData.id,
        type: 'oneDrive',
        raw: JSON.stringify(selectedFilesData),
      };

      await globalStore.dispatch('attachments/createAttachmentFromExternalProvider', payload);
      // modelsAttachment.uploadFileFromExternalProvider(out, innerObject.task.gantt_id, innerObject.task.id);
      resolve();
    });
  },

  setMicrosoftUserToStore() {
    const user = usersAttachmentTypeModel.getAllUsersAttachmentTypes().find(item => item.key === 'oneDrive');

    globalStore.commit('attachments/updateOneDriveData', { user });
  },

  init() {
    const msalParams = {
      auth: {
        authority: 'https://login.microsoftonline.com/consumers',
        clientId: GT.attachmentExternal.oneDrive.clientId,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
    };

    this.app = new msal.PublicClientApplication(msalParams);
    this.baseUrl = 'https://onedrive.live.com/picker';
    this.params = {
      sdk: '8.0',
      entry: {
        oneDrive: {
          files: {},
        },
      },
      authentication: {},
      messaging: {
        origin: GT.siteConfig,
        channelId: uuidv4(),
      },
      selection: {
        mode: 'multiple',
      },
      typesAndSources: {
        mode: 'files',
        pivots: {
          oneDrive: true,
          recent: true,
          // sharedLibraries: true,
        },
      },
    };

    this.win = null;
    this.port = null;

    window.addEventListener('message', event => {
      if (event.source && event.source === this.win) {
        const message = event.data;

        if (message.type === 'initialize' && message.channelId === this.params.messaging.channelId) {
          this.port = event.ports[0];
          this.port.addEventListener('message', this.messageListener.bind(this));
          this.port.start();
          this.port.postMessage({
            type: 'activate',
          });
        }
      }
    });
  },

  async getToken(selectAccount = false) {
    let accessToken = '';
    let authParams = { scopes: GT.attachmentExternal.oneDrive.scope };
    let loginRequired = false;

    if (!selectAccount) {
      try {
        const resp = await this.app.acquireTokenSilent(authParams);

        accessToken = resp.accessToken;
      } catch (e) {
        loginRequired = true;
      }
    } else {
      authParams = {
        ...authParams,
        prompt: 'select_account',
      };
      loginRequired = true;
    }

    if (loginRequired) {
      try {
        const resp = await this.app.loginPopup(authParams);

        this.setActiveMicrosoftAccount(resp);
        if (resp.idToken) {
          const resp2 = await this.app.acquireTokenSilent(authParams);

          accessToken = resp2.accessToken;
        }

        // return empty to prevent block popup
        return;
      } catch (error) {
        // ignore user_cancelled: User cancelled the flow.
        // access_denied: The user has denied access to the scope requested by the client application.
        if (error instanceof BrowserAuthError || error instanceof ServerError) {
          console.error(error.message);

          return;
        }

        return Promise.reject(error);
      }
    }

    return accessToken;
  },

  async pickFiles(taskData) {
    this.taskData = taskData;
    await this.launchPicker();
  },

  async addAccount() {
    await this.getToken(true);
  },

  async changeAccount() {
    await this.getToken(true);
  },

  async logoutOneDrive72() {},

  async launchPicker(selectAccount = false) {
    const authToken = await this.getToken(selectAccount);

    if (!authToken || (this.win && this.win.closed !== true)) return;

    this.win = window.open('', 'Picker', 'width=800,height=600');
    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(this.params),
    });
    const url = `${this.baseUrl}?${queryString}`;

    const form = this.win.document.createElement('form');

    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');
    this.win.document.body.append(form);

    const input = this.win.document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'access_token');
    input.setAttribute('value', authToken);
    form.appendChild(input);

    form.submit();
  },

  async messageListener(message) {
    switch (message.data.type) {
    case 'notification':
      break;

    case 'command':
      this.port.postMessage({
        type: 'acknowledge',
        id: message.data.id,
      });

      const command = message.data.data;

      switch (command.command) {
      case 'authenticate':
        const token = await this.getToken();

        if (typeof token !== 'undefined' && token !== null) {
          this.port.postMessage({
            type: 'result',
            id: message.data.id,
            data: {
              result: 'token',
              token,
            },
          });
        } else {
          console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
        }
        break;

      case 'close':
        this.win.close();
        break;

      case 'pick':
        await this.onFilesPicked({
          selectedFilesData: command.items,
          taskData: this.taskData,
        });
        window.postMessage({ type: 'onedriveFileUploaded' });
        this.win.close();
        break;

      default:
        console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);

        this.port.postMessage({
          result: 'error',
          error: {
            code: 'unsupportedCommand',
            message: command.command,
          },
          isExpected: true,
        });
        break;
      }

      break;
    }
  },
};

export default OneDriveAttachmentsController;
