class Timer {
  constructor() {
    this._timers = {};
  }

  getTimer(name) {
    return this._timers[name];
  }

  getTime(name) {
    const timer = this._timers[name];

    if (!timer) {
      return null;
    }

    return timer.time;
  }

  start(name) {
    if (this._timers[name]) {
      console.error(`timer with name ${name} already exists`);
    }

    const timer = {
      start: new Date().getTime(),
      end: null,
      time: 'in progress',
    };

    this._timers[name] = timer;
  }

  end(name) {
    const timer = this._timers[name];

    if (!timer) {
      console.error(`timer with name ${name} not exists`);
    }

    timer.end = new Date().getTime();
    const t_MS = timer.end - timer.start;

    timer.time = t_MS;
  }

  clear(name) {
    if (this._timers[name]) {
      delete this._timers[name];
    }
  }
}

export default new Timer();
