<template>
  <core-popup
    id="gp_autotest_remove_resource_popup"
    :show="isShow"
    :class="popup.remove_popup"
    :close-icon="false"
  >
    <template #body>
      <div :class="popup.head">
        <div :class="popup.title">
          {{ title }}
        </div>
        <div
          :class="popup.message"
          v-html="message"
        />
      </div>
      <div :class="popup.buttons">
        <vgp-button
          id="gp_autotest_remove_resource_popup_btn_cancel"
          type="secondary"
          :label="locales('common_cancel')"
          :small="true"
          @onClick="$emit('onClose')"
        />
        <vgp-button
          id="gp_autotest_remove_resource_popup_btn_delete"
          :style="{marginLeft: 16 + 'px'}"
          type="destructive"
          :label="locales('common_delete')"
          :small="true"
          @onClick="onDelete"
        />
      </div>
    </template>
  </core-popup>
</template>

<script>
import CorePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import VgpButton from '$$vueCmp/globalButton/vgpButton.vue';

export default {
  components: {
    CorePopup,
    VgpButton,
  },
  props: {
    isShow: { type: Boolean, required: true, default: false },
    config: { type: Object, required: true, default: { type: 'account', resources: [], projectId: null } },
  },
  emits: {
    onClose: Boolean,
    removeResourcesFromAccount: Array,
    removeResourcesFromProject: Array,
  },
  data() {
    return {
      locales: __,
      keys: {
        account: {
          people: {
            title: __('remove_popup_people'),
            message: __('resources_member_delete_confirm_text'),
            assignedMessage: __('resources_delete_confirm_change_assign_text'),
          },
          material: {
            title: __('remove_popup_material'),
            message: __('resources_delete_confirm_text'),
            assignedMessage: __('resources_material_delete_confirm_change_assign_text'),
            multiDeleteMessage: __('resources_delete_confirm_text_multi'),
            multiDeleteMessageWithAssigned: __('resources_delete_confirm_change_assign_text_multi'),
          },
        },
        project: {
          people: {
            title: __('remove_popup_people_from_project'),
            message: __('resources_member_delete_confirm_text'),
            assignedMessage: __('resources_delete_confirm_change_assign_text'),
          },
          material: {
            title: __('remove_popup_material_from_project'),
            message: __('resources_delete_confirm_text'),
            assignedMessage: __('resources_material_delete_confirm_change_assign_text'),
            multiDeleteMessage: __('resources_delete_confirm_text_multi'),
            multiDeleteMessageWithAssigned: __('resources_delete_confirm_change_assign_text_multi'),
          },
        },
      },
    };
  },
  computed: {
    resourcesType() {
      const people = this.config.resources.filter(res => res.userId);

      return people.length ? 'people' : 'material';
    },
    isMulti() {
      return this.config.resources.length > 1;
    },
    hasAssignedToTasks() {
      let isAssigned = false;

      if (this.config.type === 'account') {
        this.config.resources.forEach(resource => {
          resource.resourceProjects.forEach(pr => {
            const tasks = this.$store.getters['tasksModel/getItem'](pr.projectId)?.tasks;

            tasks?.forEach(task => {
              const assignedTask = task.resources.find(res => res.resource_id === resource.id);

              if (assignedTask) {
                isAssigned = true;
              }
            });
          });
        });
      }

      if (this.config.type === 'project') {
        const tasks = this.$store.getters['tasksModel/getItem'](this.config.projectId)?.tasks;

        tasks?.forEach(task => {
          const assignedTask = task.resources.some(res => this.config.resources.find(resource => resource.id === res.resource_id));

          if (assignedTask) {
            isAssigned = true;
          }
        });
      }

      return isAssigned;
    },
    title() {
      return this.keys[this.config.type][this.resourcesType].title;
    },
    message() {
      let message = this.keys[this.config.type][this.resourcesType].message;

      if (this.hasAssignedToTasks) {
        message = this.keys[this.config.type][this.resourcesType].assignedMessage;
      }

      if (this.isMulti) {
        message = this.keys[this.config.type][this.resourcesType].multiDeleteMessage;

        if (this.hasAssignedToTasks) {
          message = this.keys[this.config.type][this.resourcesType].multiDeleteMessageWithAssigned;
        }
      }

      return message;
    },
  },
  methods: {
    onDelete() {
      if (this.config.type === 'account') {
        const resourcesIds = this.config.resources.map(res => res.id);

        this.$emit('removeResourcesFromAccount', resourcesIds);
      }

      if (this.config.type === 'project') {
        const resources = this.config.resources.map(res => ({
          resourceId: res.id,
          projectId: this.config.projectId,
        }));

        this.$emit('removeResourcesFromProject', resources);
      }
    },
  },
};
</script>

<style module='popup' src='./popups.less'></style>
