import globalStore from '$$store/main';
import getCurrentUserId from '../helpers/getCurrentUserId';
import constants from '../../../../protected/extensions/constants';
import accountRoles from '../account/index';

const cacheGetUserRole = new Map();

const projectRoles = {
  isOwner(ganttId) {
    const role = this.getCurrentUserRole(+ganttId);

    return role?.defaultType === constants.ROLES.DEFAULT_TYPE.OWNER;
  },
  clearCacheGetUserRole() {
    cacheGetUserRole.clear();
  },
  getUserRole(userId, ganttId) {
    const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](userId);

    const key = `${userId}-${ganttId}`;

    if (cacheGetUserRole.get(key)) {
      return cacheGetUserRole.get(key);
    }

    if (resourceData) {
      const roleId = resourceData.resourceProjects.find(settings => +settings.projectId === +ganttId)?.projectRoleId;

      // no projects created by a non-owner from the owner
      const role = globalStore.getters['roles/getProjectRoleById'](roleId);

      cacheGetUserRole.set(key, role);

      return role;
    }

    return null;
  },

  getCurrentUserRole(ganttId) {
    const userId = getCurrentUserId();
    const role = ganttId && this.getUserRole(userId, +ganttId);

    return role;
  },

  hasRight(ganttId, bitKey) {
    const role = this.getCurrentUserRole(ganttId);

    if (this.isOwner(+ganttId) || accountRoles.isOwner() || accountRoles.isKingMode()) {
      return true;
    }

    if (!role) {
      return false;
    }

    return role.rights.find(bitObj => bitObj.bitKey === bitKey)?.status;
  },

  userHasRight(userId, ganttId, bitKey) {
    const role = this.getUserRole(userId, ganttId);

    if ((role && role.defaultType === constants.ROLES.DEFAULT_TYPE.OWNER)
        || accountRoles.isUserOwner(userId)
        || accountRoles.isUserKingMode(userId)
    ) {
      return true;
    }

    if (!role) {
      return false;
    }

    return role.rights.find(bitObj => bitObj.bitKey === bitKey)?.status;
  },

  hasRightSomeOne(ganttId, bitKeys) {
    if (this.isOwner(+ganttId) || accountRoles.isOwner() || accountRoles.isKingMode()) {
      return true;
    }

    const role = this.getCurrentUserRole(+ganttId);

    if (!role) {
      return false;
    }

    return role.rights.some(bitObj => bitKeys.find(key => key === bitObj.bitKey && bitObj.status));
  },
  hasCostRight(ganttId) {
    if (accountRoles.isOwner()) {
      return true;
    }

    return this.hasRight(+ganttId, 'cost_field') || this.hasRight(ganttId, 'actual_cost_field');
  },

  getOwnerByGanttId(ganttId) {
    const _resources = globalStore.getters['resourcesModel/getResourcesByProjectId'](+ganttId);
    const ownerRole = globalStore.getters['roles/getProjectRoles'].find(role => role?.defaultType === constants.ROLES.DEFAULT_TYPE.OWNER);

    return _resources.filter(resource => resource.resourceProjects.find(settings => settings.projectId === ganttId && settings.projectRoleId === ownerRole.id))[0];
  },
};

export default projectRoles;
