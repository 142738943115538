<template>
  <div
    :class="[
      $style['assigned-resources-chips-wrapper'],
      isActive ? $style['assigned-resources-chips-wrapper_active'] : '',
      isFieldDisabled('resources') && resourcesLength <= 7 ? $style['cursor-default'] : '',
    ]"
    :style="{ width: `${containerWidth}px` }"
    @click="$emit('click', $event)"
    @mouseenter="showAssignedResourcesPopup"
    @mouseleave="hideAssignedResourcesPopup"
  >
    <rolling-items
      :key="'task-settings-assigned' + rollingItemsKey"
      :items="resources"
      :container-width="containerWidth"
      :max-items-show="7"
      :offset="singleResourceAssigned ? 4 : -4"
    >
      <template #default="{ item }">
        <vgp-chip
          :key="item.id"
          :img-url="item.avatar"
          :preset="singleResourceAssigned ? 'user' : 'avatar'"
          :text="singleResourceAssigned ? item.name : ''"
          is-avatar
          :txt-ellipsis="resources.length === 1"
        />
      </template>
    </rolling-items>
  </div>
</template>

<script>
import VgpChips from '$$vueCmp/dropdownSelect/includes/chip/vgpChip.vue';
import RollingItems from '$$vueCmp/rollItems/vgpRollItems.vue';

export default {
  name: 'AssignedResourcesChips',
  components: { RollingItems, VgpChip: VgpChips },
  inject: ['isFieldDisabled'],
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
    taskData: {
      type: Object,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rollingItemsKey: 0,
    };
  },
  computed: {
    resourcesLength() {
      return this.resources.length;
    },
    singleResourceAssigned() {
      return this.resourcesLength === 1;
    },
    containerWidth() {
      if (this.resourcesLength > 7) {
        return 177;
      }

      if (this.resourcesLength === 1) {
        // 21 = 4 (chip offset) + 15 (container offset; see calcNumberToShow method in vgpRollItems.vue)
        const chipWidth = this.getSingleResourceChipWidth(this.resources[0]) + 21;

        return chipWidth >= 177 ? 177 : chipWidth;
      }

      return 20 * this.resources.length + 15;
    },
  },
  methods: {
    showAssignedResourcesPopup(e) {
      if (!this.isFieldDisabled('resources') || this.resourcesLength <= 7) return;

      const pos = e.target.getBoundingClientRectWrapper();

      if (pos.x || pos.y) {
        gantt.callEvent('showResourcesPreviewListForTask', [pos, this.taskData.id, this.taskData.gantt_id]);
      }
    },
    hideAssignedResourcesPopup() {
      gantt.callEvent('hideResourcesPreviewListForTask');
    },
    getSingleResourceChipWidth(resource) {
      const chipPadding = 10;
      const chipImage = 20;
      const span = document.createElement('span');

      span.style.position = 'absolute';
      span.style.visibility = 'hidden';
      span.style.fontSize = '14px';
      span.style.fontFamily = 'Lato-Regular, sans-serif';
      span.innerHTML = resource.name;

      document.body.appendChild(span);
      const width = span.clientWidth + chipPadding + chipImage;

      document.body.removeChild(span);

      return width;
    },
  },
};
</script>

<style module lang="less">
.assigned-resources-chips-wrapper {
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 177px;
  padding: 3px 8px;
  border: 2px solid transparent;
  border-radius: 4px;
  &_active {
    border-color: #4484CD;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
    }
  &.cursor-default {
    cursor: default;
  }
}
</style>
