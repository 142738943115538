// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".colors_list_iXDkHJ1O {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  width: 216px;\n  padding: 4px 12px;\n}\n.colors_list_item_BtHashi9 {\n  width: 24px;\n  height: 24px;\n  border-radius: 4px;\n  cursor: pointer;\n  color: #fff;\n  margin: 4px;\n}\n", "",{"version":3,"sources":["webpack://./public/views/common/VueComponents/colorPalette/colorsList/colorsList.less","webpack://./colorsList.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,2BAAA;EACA,YAAA;EACA,iBAAA;ACCF;ADCE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,WAAA;ACCJ","sourcesContent":[".colors_list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  width: 216px;\n  padding: 4px 12px;\n\n  &_item {\n    width: 24px;\n    height: 24px;\n    border-radius: 4px;\n    cursor: pointer;\n    color: #fff;\n    margin: 4px;\n  }\n}\n\n",".colors_list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  width: 216px;\n  padding: 4px 12px;\n}\n.colors_list_item {\n  width: 24px;\n  height: 24px;\n  border-radius: 4px;\n  cursor: pointer;\n  color: #fff;\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors_list": "colors_list_iXDkHJ1O",
	"colors_list_item": "colors_list_item_BtHashi9"
};
export default ___CSS_LOADER_EXPORT___;
