var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "export-paper-with-svg" }, [
    _vm.options[_vm.exportType]
      ? _c("div", { staticClass: "export-paper-with-svg-container" }, [
          _c("div", {
            staticClass: "export-paper-with-svg-icon",
            domProps: { innerHTML: _vm._s(_vm.options[_vm.exportType].icon) },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "export-paper-with-svg-text",
              class: [
                _vm.options[_vm.exportType].css
                  ? _vm.options[_vm.exportType].css
                  : "",
              ],
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.options[_vm.exportType].text) + "\n    "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }