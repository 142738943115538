var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.calendar_pricing_stub }, [
    _c("div", { class: _vm.$style.head_section }, [
      _c("div", { class: _vm.$style.welcome_title }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.locale("calendar_view_pricing_title")) +
            "\n      "
        ),
        _c("div", { staticClass: "pricing-title-container" }, [
          _vm._v("\n        " + _vm._s(_vm.locale(_vm.planTitle)) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.description }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.locale("calendar_view_pricing_description")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.isOwner
        ? _c(
            "div",
            { class: _vm.$style.upgrade_button },
            [
              _c("vgp-button", {
                staticClass: "change-view-btn",
                attrs: {
                  type: "primary",
                  small: "",
                  label: _vm.locale("upgrade_rate_btn"),
                },
                on: { onClick: _vm.upgradeClick },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.demo_video },
      [
        _c("video-component", {
          attrs: {
            "poster-url":
              "https://cdn.ganttpro.com/statics/media/videos/calendar-view/CalendarViewDemo.jpg",
            "video-url":
              "https://cdn.ganttpro.com/statics/media/videos/calendar-view/CalendarViewVideo.MP4",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }