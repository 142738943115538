import app from '../../../app';
import globalStore from '../../../store/main';
import projectComponent from '../../Project';

function onTaskLinkUpdated(event) {
  event.innerSettings.collaborationItems.forEach(linkToAction => {
    if (projectComponent.userHasProject(linkToAction.gantt_id)) {
      globalStore.commit('tasksModel/updateLink', linkToAction);
      app.trigger('tasks:model:updateLink', linkToAction.id, linkToAction);
    }
  });
}

export default onTaskLinkUpdated;
