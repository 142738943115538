import app from '../app';
import _ from '../libs/lodash';
import projectsModel from '../models/projects';
import multiViewsProjects from '../models/multiViewsProjects';

import globalStore from '../store/main';
import plansModel from '../models/plans';
import rights from '../components/rights';

const __ = window.__;

// user -> Globals

const bitsAndModesMap = {
  gantt: 'gantt_chart',
  board: 'board_view',
  resources: 'resources_page',
  workload: 'workload',
  list: 'list_view',
  overview: 'overview',
  calendar: 'calendar',
};

function checkAccessToFeature(feature) {
  const access = globalStore.getters['paymentModel/getAccessToFeature']((user.paymentFeatures[feature].name));

  return access;
}

const checkAccessToTask = (taskId, projectId) => {
  const task = globalStore.getters['tasksModel/getTaskByGanttId'](projectId, taskId);

  if (!task.id) {
    webix.message({ type: 'info', text: __('no_task_by_route') });

    return false;
  }

  if (rights.project.hasRight(projectId, 'all_tasks')) {
    return true;
  }

  const userId = rights.getUserId();
  const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](userId)?.id;

  if (task.type === 'project') {
    const tasks = globalStore.getters['tasksModel/getTasksForGantt'](projectId).data;
    const children = gantt.getChildren(task.id);
    const check = ids => ids.reduce((access, id) => {
      if (access) return access;

      const _task = tasks.find(t => t.id === id);

      if (!_task) return access;

      if (_task.type === 'project') {
        const _children = gantt.getChildren(_task.id);

        return check(_children);
      }

      return _task.resources.some(r => r.resource_id === resourceId);
    }, false);

    return check(children);
  }

  return task.resources.some(r => r.resource_id === resourceId);
};

const defaultProjectRoute = from => {
  let activeProjectId = projectsModel.getActiveGanttId();

  if (activeProjectId === projectsModel.globalGanttId) {
    activeProjectId = projectsModel.getFirstId();
  }

  let defaultRoute = '/';

  if (activeProjectId) {
    defaultRoute = `/project/${activeProjectId}/${projectsModel.getActiveProjectMode()}`;
  }

  return defaultRoute;
};

const defaultMultiviewRoute = () => {
  const activeMultiview = multiViewsProjects.getActiveProjectData();
  let defaultRoute = '/';

  if (!activeMultiview) {
    return defaultRoute;
  }

  if (activeMultiview) {
    defaultRoute = `/projects/${activeMultiview.id}/gantt`;
  }

  return defaultRoute;
};

const routerGuard = {
  multiViewGuard(to, from, next) {
    const modesMap = {
      gantt: 'gantt_chart',
      list: 'list_view',
      workload: 'workload',
    };
    const projectTask = globalStore.getters['tasksModel/getTask'](to.params.taskId);
    const modeChanged = from.params.mode !== to.params.mode;

    if (!checkAccessToFeature('portfolio_view') || !rights.account.hasRight('multiview_all_features')) {
      return next(defaultProjectRoute());
    }

    const taskNotFound = to.name === 'multiviewTaskRoute' && (!projectTask || projectsModel.isArchived(projectTask.gantt_id));

    if (taskNotFound) {
      if (!projectTask) {
        webix.message({ type: 'info', text: __('no_task_by_route') });
      }

      return next(`/projects/${to.params.multiviewID}/${to.params.mode}`);
    }

    if (from.params.multiviewID === to.params.multiviewID && !modeChanged) {
      if (projectTask && (projectTask.parent === 1 || !projectTask.parent)) {
        return;
      }

      return next();
    }

    const multiviewID = +to.params.multiviewID;
    const multiviewData = multiViewsProjects.getItem(multiviewID);

    if (!multiviewData) {
      return next(from.path || '/');
    }

    if (!['export', 'projects', 'dashboard'].some(mode => mode === to.params.mode) && (!modesMap[to.params.mode] || !multiviewData.ganttIDs.some(gantt_id => rights.project.hasRight(gantt_id, modesMap[to.params.mode])))) {
      if (from.params.multiviewID === to.params.multiviewID) {
        return next(from.path);
      }

      const accessMode = Object.keys(modesMap).filter(key => key !== to.params.mode).find(key => multiviewData.ganttIDs.some(gantt_id => rights.project.hasRight(gantt_id, modesMap[key])));

      if (accessMode) {
        return next(`/projects/${multiviewID}/${accessMode}`);
      }
    }

    const activeMultiview = multiViewsProjects.getActiveProjectData();
    const isExport = to.params.mode === 'export';

    if (activeMultiview && +activeMultiview.id === multiviewID && !gantt._forceInitMultiview) {
      next();

      return;
    }

    const multiview = multiViewsProjects.setActiveMultiView(multiviewID);
    const isNoAccessTask = to.name === 'multiviewTaskRoute' && ((projectTask && (projectTask.parent === 1 || !projectTask.parent || projectsModel.isArchived(projectTask.gantt_id))) || !projectTask);

    if ((!gantt.config.inited && isExport && rights.account.hasRight('export')) || isNoAccessTask) {
      next(defaultMultiviewRoute());
      app.trigger('activeMultiProjects:set', multiviewID, multiview.ganttIDs);

      return;
    }

    app.trigger('gantt:progress:show');
    next();

    setTimeout(() => {
      app.trigger('activeMultiProjects:set', multiviewID, multiview.ganttIDs);
    }, 0);
    gantt._forceInitMultiview = false;
  },
  portfolioGuard(to, from, next) {
    if (!rights.account.hasRight('multiview_all_features')) {
      return next(defaultProjectRoute());
    }

    next();
  },
  guard(to, from, next) {
    const isLinkToProject = to.name === 'project';

    if (!app.config.mode.isBase) {
      if (isLinkToProject) {
        next('/');
      } else {
        next();
      }

      return;
    }

    let activeProjectId = projectsModel.getActiveGanttId();

    if (activeProjectId === projectsModel.globalGanttId) {
      activeProjectId = projectsModel.getFirstId();
    }

    multiViewsProjects.clearActiveMultiView();

    const projects = projectsModel.serialize();
    const projectId = +to.params.projectId;
    const taskId = +to.params.taskId;
    const mode = to.params.mode;
    let defaultRoute = '';

    if (from.name === 'newProject' || from.name === 'importProject' || from.name === 'importJiraProject') {
      defaultRoute = `/project/${activeProjectId}/gantt`;
    } else {
      defaultRoute = `/project/${activeProjectId}/${projectsModel.getActiveProjectMode()}`;
    }
    const projectData = _.find(projects, pt => pt.id === projectId);

    if (projectId && projectData) {
      const isExport = mode === 'export';
      const projectIdChanged = +from.params.projectId !== +to.params.projectId;
      const modeChanged = from.params.mode !== to.params.mode;

      if (modeChanged || projectIdChanged) {
        next();
        projectsModel.setActiveProject(projectId, false, true);
      }

      // if task view mode
      if ((taskId && !checkAccessToTask(taskId, projectId))) {
        next(defaultRoute);

        return;
      }

      if (!gantt.config.inited) {
        // if task view mode direct link
        if (taskId) {
          gantt.config.preInitedRoute = to.path;
          next(`/project/${projectId}/${mode}`);

          return;
        }

        // if export view mode direct link
        if (isExport && rights.account.hasRight('export')) {
          gantt.config.preInitedRoute = to.path;
          next(`/project/${projectId}/gantt`);

          return;
        }
      }

      if (mode !== 'export' && !rights.project.hasRight(projectId, bitsAndModesMap[mode])) {
        const accessMode = Object.keys(bitsAndModesMap).filter(key => key !== mode).find(key => rights.project.hasRight(projectId, bitsAndModesMap[key]));

        if (accessMode) {
          next(`/project/${projectId}/${accessMode}`);
        } else {
          next();
        }
      } else if (mode === 'export' && !rights.account.hasRight('export')) {
        next(defaultRoute);
      } else {
        next();
      }
    } else {
      const isArchived = projectsModel.isArchived(+projectId);

      if (isLinkToProject && !isArchived) {
        webix.message({ type: 'info', text: __('no_project_by_route') });
      }

      if (activeProjectId) {
        next(defaultRoute);
      } else {
        if (isLinkToProject) {
          next('/');
        }

        if (to.name === 'app') {
          next();
        }
      }
    }
  },
  guardListRoute(to, from, next) {
    multiViewsProjects.clearActiveMultiView();

    if (!plansModel.isActive()) {
      next(from.path);

      return;
    }

    let taskId = +to.params.taskId;

    if (taskId && !gantt.config.inited) {
      gantt.config.preInitedRoute = to.path;
      next('/list');

      return;
    }

    taskId = taskId || +from.params.taskId;
    const task = taskId && globalStore.getters['tasksModel/getTask'](taskId);

    if (!task) {
      webix.message({ type: 'info', text: __('no_task_by_route') });
      next('/tasks/list');

      return;
    }

    if (!checkAccessToTask(taskId, task.gantt_id)) {
      next('/tasks/list');

      return;
    }

    next();
  },

  adminGuard(to, from, next) {
    if (to.name === 'project') {
      next();

      return;
    }

    const defaultRoute = `/project/${projectsModel.getActiveGanttId()}/${projectsModel.getActiveProjectMode()}`;

    if ((to.name === 'reports' || to.name === 'reportsDetail') && !rights.account.hasRight('reports')) {
      next(defaultRoute);
    } else {
      next();
    }
    const accessToWorkload = projectsModel.getAllProjects().some(o => rights.project.hasRight(o.gantt_id, 'workload'));

    if ((to.name === 'workload') && !accessToWorkload) {
      next(defaultRoute);
    } else {
      next();
    }
  },
  activeAccountGuard(to, from, next) {
    if (!plansModel.isActive()) {
      next(from.path);
    } else {
      next();
    }
  },

  settingsGuard(to, from, next) {
    const defaultRoute = '/settings/profile';

    const isOverSubscription = plansModel.isOverSubscription() || plansModel.isExpiredTrial();
    const isTerminated = user.subscription.status_code !== 10 && user.subscription.status_code !== 2;
    const isOwner = rights.account.isOwner();
    const statusSettingsAccess = rights.account.hasRight('project_statuses_settings');
    const teamSettingAccess = rights.account.hasRight('team_settings');
    const teamAndResourcesSettingsAccess = rights.account.hasRight('team_and_resources_settings');
    const customFieldsAccess = rights.account.hasRight('custom_fields_settings');
    const accountRolesAccess = rights.account.checkRightByArrayOfBits(['account_roles_create', 'account_roles_update', 'account_roles_delete']);
    const projectRolesAccess = rights.account.checkRightByArrayOfBits(['project_roles_create', 'project_roles_update', 'project_roles_delete']);

    // check default route
    if (to.name === 'defaultSetting' && from.name === null) {
      next(defaultRoute);
    } else if (to.name === 'defaultSetting' && from.name !== null) {
      next(from.fullPath);
    }

    if (to.name === 'billing' && !isOwner) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'team' && (!teamSettingAccess || isOverSubscription || !isTerminated)) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'columns' && (!customFieldsAccess || isOverSubscription || !isTerminated)) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'statuses' && (!statusSettingsAccess || isOverSubscription || !isTerminated)) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'resources' && !teamAndResourcesSettingsAccess) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'account_rights' && !accountRolesAccess) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'project_rights' && !projectRolesAccess) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'resources' && !['people', 'material'].includes(to.params.activeView)) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if ((to.name === 'security' || to.name === 'integrations' || to.name === 'notifications') && (isOverSubscription || !isTerminated)) {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (to.name === 'admin' && user.userRole !== 'admin') {
      next(from.name === null ? defaultRoute : from.fullPath);
    } else if (_.includes(['billing', 'team', 'security', 'integrations', 'notifications', 'columns', 'admin', 'profile', 'statuses', 'resources', 'account_rights', 'project_rights'], to.name)) {
      next();
    }
  },
  passwordRestoreGuard(to, from, next) {
    if (!user.isPasswordRestore) {
      next(from.path);
    } else {
      next();
    }
  },
  onboardingGuard(to, from, next) {
    const onboardingActivitySettings = globalStore.getters['user/getActivitySettings']('onboarding');

    if (onboardingActivitySettings.isFinished) {
      next(from.path);
    } else {
      next();
    }
  },
  integrationsGuard(to, from, next) {
    const defaultRoute = 'settings/integrations';

    const accessToSettings = rights.account.hasRight('integration_settings');
    const integrations = ['integrationSlack', 'integrationMsteams', 'integrationGoogle', 'integrationOutlook'];

    if (integrations.includes(to.name) && !accessToSettings) {
      next(defaultRoute);
    } else {
      next();
    }
  },
};

export default routerGuard;
