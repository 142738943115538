import app from "./../app";
import _ from "./../libs/lodash";

var userProjectConfigs = new webix.DataCollection();

userProjectConfigs.userPropertyList = {
  "skin": "mainProperty",
  "skip_off_time": "property",
  "right_side_text": "property",
  "highlight_critical_path": "property",
  "resource_loading_type": "property",
  "durationData": {
    "mode": "property",
    "view": "property",
    "durationUnit": "property",
  },
  "view_mode": "property",
  "initGridColumns": "object",
  "current_day_marker": "property",
  "show_advanced_buttons": "property",
  "show_resource_avatar": "property",
  "highlight_overdue": "property",
  "cross_out_tasks": "property",
  "discoloration_tasks": "property"
};

userProjectConfigs.initProjects = function (projectsArray) {
  var projectsConfigArray = [];

  _.each(projectsArray, function (prConfig) {
    if (prConfig.userConfig) {
      projectsConfigArray.push({
        id: prConfig.gantt_id,
        gantt_id: prConfig.gantt_id,
        userConfig: prConfig.userConfig,
        userSkin: prConfig.userSkin,
        show_resource_avatar: prConfig.show_resource_avatar,
      });
    }
  });

  userProjectConfigs.parse(projectsConfigArray);
};

userProjectConfigs.getUserConfigByGanttId = function (ganttId) {
  var config = userProjectConfigs.getItem(ganttId);

  if (config && config.userConfig) {
    var userSkin = userProjectConfigs.getUserSkinByGanttId(ganttId);

    if (userSkin) {
      config.userConfig.userSkin = userSkin;
    }
    return config.userConfig;
  }

  return null;
};

userProjectConfigs.getUserSkinByGanttId = function (ganttId) {
  var config = userProjectConfigs.getItem(ganttId);

  if (config && config.userSkin) {
    return config.userSkin;
  }

  return null;
};

userProjectConfigs.mergeProjectConfigToUserConfig = function (projectConfig, userConfig) {
  var userConfigKeys = Object.keys(userConfig || {});
  var userConfigKeysLength = userConfigKeys.length;
  var userConfigKeysIndex = 0;

  for (userConfigKeysIndex = 0; userConfigKeysIndex < userConfigKeysLength; userConfigKeysIndex++) {
    var userIndex = userConfigKeys[userConfigKeysIndex];

    if (typeof projectConfig[userIndex] === "object" && typeof userConfig[userIndex] === "object") {
      projectConfig[userIndex] = userProjectConfigs.mergeProjectConfigToUserConfig(projectConfig[userIndex], userConfig[userIndex], true);
    } else {
      projectConfig[userIndex] = userConfig[userIndex];
    }
  }

  return projectConfig;
};

userProjectConfigs.getUserConfigByGanttIdAndMergeToProjectConfig = function (ganttId, projectConfig) {
  var userConfig = userProjectConfigs.getUserConfigByGanttId(ganttId);

  if (!userConfig) {
    return projectConfig;
  }

  var userSkin = userProjectConfigs.getUserSkinByGanttId(ganttId);

  if (userSkin) {
    projectConfig.userSkin = userSkin;
  }
  return userProjectConfigs.mergeProjectConfigToUserConfig(projectConfig, userConfig);
};

userProjectConfigs.updateUserConfig = function (ganttId, userConfig, advancedParams) {
  var userConfigData = userProjectConfigs.getItem(ganttId);

  if (userConfigData) {
    userProjectConfigs.updateUserConfigInModel(userConfigData, userConfig, advancedParams);

    if (!app.config.mode.isBase) {
      return true;
    }

    webix.ajax()
      .put("/api/userConfig/" + ganttId, {
        userSkin: advancedParams.skin || userConfigData.userSkin,
        userConfig: userConfigData.userConfig,
      })
      .fail(function (error) {
        console.warn(error);
      });
  }
};

userProjectConfigs.updateUserConfigInModel = function (userConfigData, userConfig, advancedParams) {
  userConfigData.userConfig = webix.copy(userConfig);
  userConfigData.userSkin = advancedParams.skin || userConfigData.userSkin;
  userProjectConfigs.updateItem(userConfigData.id, userConfigData);
};

userProjectConfigs.updateUserConfigInHistoryMode = function (ganttId, newDurationData) {
  var userConfigData = userProjectConfigs.getItem(ganttId);

  if (!userConfigData) {
    return;
  }

  var userConfig = userConfigData.userConfig;

  userConfig.durationData = newDurationData;
  userProjectConfigs.updateUserConfigInModel(userConfigData, userConfig, {});
};

userProjectConfigs.addUserConfig = function (ganttId, userConfig, advancedParams) {
  if (!app.config.mode.isBase) {
    // if (app.config.mode.isExport) {
    //   var userConfigData = {
    //     id: ganttId,
    //     gantt_id: ganttId,
    //     userConfig: webix.copy(userConfig),
    //     userSkin: advancedParams.skin
    //   };
    //
    //   userProjectConfigs.add(userConfigData);
    //   return true;
    // }
    return true;
  }

  var userConfigData = {
    id: ganttId,
    gantt_id: ganttId,
    userConfig: webix.copy(userConfig),
    userSkin: advancedParams.skin,
  };

  userProjectConfigs.add(userConfigData);

  webix.ajax()
    .post("/api/userConfig/" + ganttId, {
      userSkin: advancedParams.skin || userConfigData.userSkin,
      userConfig: userConfigData.userConfig,
    })
    .fail(function (error) {
      console.warn(error);
    });
};

userProjectConfigs.analyzeParamsAndSplitToUserConfig = function (projectParamsArray, ganttId, oldConfig) {
  var userConfig = userProjectConfigs.getUserConfigByGanttId(ganttId) || {};
  var mainParams = {};
  var needUpdate = false;
  //var paramsIndex = 0;

  _.eachRight(projectParamsArray, function (paramObject, index) {
    var userConfigPropertyListKey = userProjectConfigs.userPropertyList[paramObject.key];

    if (userConfigPropertyListKey) {
      if (userConfigPropertyListKey === "object") {
        if (userConfig[paramObject.key] !== paramObject.value) {
          userConfig[paramObject.key] = webix.copy(paramObject.value);
          needUpdate = true;
          projectParamsArray.splice(index, 1);
        }
      } else if (userConfigPropertyListKey === "property") {
        if (userConfig[paramObject.key] !== paramObject.value) {
          userConfig[paramObject.key] = paramObject.value;
          needUpdate = true;
          projectParamsArray.splice(index, 1);
        }
      } else if (userConfigPropertyListKey === "mainProperty") {
        if (mainParams[paramObject.key] !== paramObject.value) {
          mainParams[paramObject.key] = paramObject.value;
          needUpdate = true;

          projectParamsArray.splice(index, 1);
        }
      } else if (_.isObject(userConfigPropertyListKey)) {
        if (!userConfig[paramObject.key]) {
          userConfig[paramObject.key] = {};
        }

        Object.keys(userConfigPropertyListKey).forEach(function (userKey) {
          if (userConfig[paramObject.key][userKey] !== paramObject.value[userKey]) {
            const paramsIndex = projectParamsArray.findIndex(param => param.key === paramObject.key);

            userConfig[paramObject.key][userKey] = projectParamsArray[paramsIndex].value ? projectParamsArray[paramsIndex].value[userKey] : projectParamsArray[paramsIndex].value;
            needUpdate = true;
          }

          oldConfig[paramObject.key][userKey] = paramObject.value[userKey];
        });

        if (_.isEqual(paramObject.value, oldConfig[paramObject.key])) {
          projectParamsArray.splice(index, 1);
        }
      }
    }
  });

  if (needUpdate) {
    if (userProjectConfigs.exists(ganttId)) {
      userProjectConfigs.updateUserConfig(ganttId, userConfig, mainParams);
    } else {
      userProjectConfigs.addUserConfig(ganttId, userConfig, mainParams);
    }
  }

  return projectParamsArray;
};

export default userProjectConfigs;
