var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.doughnut_chart_wrapper }, [
    _c("div", { ref: "chart", class: _vm.$style.chart_body }),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.legend },
      _vm._l(_vm.items, function (item) {
        return _c("tooltip", {
          key: item.id,
          attrs: {
            content: item.name,
            position: "top",
            delay: 100,
            hide: !_vm.isOverflowed[item.name] || _vm.isGrayedOut(item.name),
          },
          scopedSlots: _vm._u(
            [
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$style.legend__item,
                          _vm.isGrayedOut(item.name) && _vm.$style.grayed_out,
                        ],
                        on: {
                          mouseover: function ($event) {
                            return _vm.highlightSeries(item.name)
                          },
                          mouseleave: function ($event) {
                            return _vm.downplay(item.name)
                          },
                          click: function ($event) {
                            return _vm.toggleSelect(item.name)
                          },
                        },
                      },
                      [
                        _c("div", {
                          class: _vm.$style.legend_color_box,
                          style: { backgroundColor: item.color },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "legendName",
                            refInFor: true,
                            class: _vm.$style.legend_name,
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }