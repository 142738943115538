var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["skeleton_row_wrapper"] },
    [
      _c("div", { class: _vm.$style["skeleton_row_name"] }, [
        _vm._v("\n    " + _vm._s(_vm.columnName) + "\n  "),
      ]),
      _vm._v(" "),
      _c("skeleton-wrapper", {
        style: { maxWidth: "260px" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.selectedRow === 0
                  ? _c(
                      "div",
                      { class: [_vm.$style["skeleton_row"]] },
                      [
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "big", type: "bar" },
                        }),
                      ],
                      1
                    )
                  : _vm.selectedRow === 1
                  ? _c(
                      "div",
                      { class: [_vm.$style["skeleton_row"]] },
                      [
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                      ],
                      1
                    )
                  : _vm.selectedRow === 2
                  ? _c(
                      "div",
                      { class: [_vm.$style["skeleton_row"]] },
                      [
                        _c("skeleton-item", {
                          style: {
                            height: "22px",
                            width: "22px",
                            marginRight: "8px",
                          },
                          attrs: { type: "square" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { height: "22px", width: "22px" },
                          attrs: { type: "square" },
                        }),
                      ],
                      1
                    )
                  : _vm.selectedRow === 3
                  ? _c(
                      "div",
                      { class: [_vm.$style["skeleton_row"]] },
                      [
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                      ],
                      1
                    )
                  : _vm.selectedRow === 4
                  ? _c(
                      "div",
                      { class: [_vm.$style["skeleton_row"]] },
                      [
                        _c("skeleton-item", {
                          style: { height: "22px" },
                          attrs: { width: "wide", type: "bar" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }