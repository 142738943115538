import Vue from 'vue';

export default {
  setAttachments(state, { projectId, attachments }) {
    Vue.set(state.projectAttachmentsMap, Number(projectId), attachments);
  },
  addAttachment(state, { projectId, attachment }) {
    if (state.projectAttachmentsMap[projectId]) {
      state.projectAttachmentsMap[projectId].push(attachment);
    }
  },
  removeAttachment(state, { projectId, attachmentId }) {
    const projectAttachments = state.projectAttachmentsMap[projectId];

    if (projectAttachments) {
      const attachmentIndex = projectAttachments.findIndex(attachment => attachment.id === attachmentId);

      if (attachmentIndex !== -1) {
        projectAttachments.splice(attachmentIndex, 1);
      }
    }
  },
  updateGoogleDriveData(state, payload) {
    state.googleDriveData = { ...state.googleDriveData, ...payload };
  },
  updateOneDriveData(state, payload) {
    state.oneDriveData = { ...state.googleDriveData, ...payload };
  },
  setIsFetching(state, flag) {
    state.isFetching = flag;
  },
  setIsUploading(state, flag) {
    state.isUploading = flag;
  },
};
