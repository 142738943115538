import app from "./../../../app";
import formats from "./../../../models/date_formats";
import constants from "./../../../helpers/constants";

const __ = window.__;


var i_suggest = {
  view: "importsuggest",
  padding: 0,
  css: 'import-suggest',
  autoheight: true,
  width: 200,
  maxHeight: 200,
  fitMaster: false,
  body: {
    autoheight: true,
    type: {
      height: 40
    },
    template: function (obj) {
      let typeName = '';

      if (obj.type) {
        const type = _.find(constants.CUSTOM_COLUMNS_TYPES, type => {
          return type.id === obj.type;
        });
        typeName = ` (${__(type.locale)})`;
      }

      return `<div>${obj.value}${typeName}</div>`
    }
  },
  data: []
};

var f_suggest = {
  view: "suggest",
  padding: 0,
  fitMaster: false,
  width: 135,
  css: 'import-date-formats-suggest',
  data: formats.data
};

export default {
  $i_suggest: i_suggest,
  $f_suggest: f_suggest
};

