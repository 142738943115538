import _ from "./../libs/lodash";
import moment from "./../libs/moment";

const getHeaderData = function (data, filterRange) {
  let result = {};
  let startDate = '';
  let endDate = '';

  const getSumTime = function (data) {
    return _.reduce(data, (sum, log) => {
      let newTime = log.data ? getSumTime(log.data) : Number(log.time);

      return sum + newTime;
    }, 0);
  };
  
  const getMinDate = function (data) {
    return _.reduce(data, (minDate, log) => {
      let logDate = log.data ? getMinDate(log.data) : moment(log.date).toDate();

      return moment((minDate || logDate) >= logDate ? logDate : minDate).toDate();
    }, 0);
  };

  const getMaxDate = function (data) {
    return _.reduce(data, (maxDate, log) => {
      let logDate = log.data ? getMaxDate(log.data) : moment(log.date).toDate();

      return moment((maxDate || logDate) <= logDate ? logDate : maxDate).toDate();
    }, 0);
  };

  let minDate = getMinDate(data);
  let maxDate = getMaxDate(data);

  if (filterRange) {
    startDate = filterRange.start;
    endDate = filterRange.end || (maxDate || startDate);
  } else {
    startDate = minDate;
    endDate = maxDate;
  }

  result.range = moment(startDate).format('D MMM, YYYY') + ' - ' + moment(endDate).format('D MMM, YYYY');
  result.sum = getSumTime(data);

  if (!data.length) {
    result.range = null;
  }

  return result;
}

export default getHeaderData;

