<template functional>
  <div
    :class="[`${props.name}-icon`, 'vue-icon', data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-on="listeners"
    v-bind="data.attrs"
  >
    <component
      :is="injections.components[props.name]"
    />
  </div>
</template>

<script>
import add from './addIcon.vue';
import more from './moreVert.vue';
import settings from './settingsIcon.vue';
import dustbin from './dustbinIcon.vue';
import clip from './clipIcon.vue';
import message from './messageIcon.vue';

export default {
  inject: {
    components: {
      default: { add, more, settings, dustbin, message, clip },
    },
  },
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return (['add', 'more', 'settings', 'dustbin', 'message', 'clip'].indexOf(value) !== -1);
      },
    },
  },
};
</script>

<style scoped lang="less">
.vue-icon{
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
