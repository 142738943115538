import jiraNewProject from './../integration/jira/newProject';
import jiraIntegration from './../integration/jira/index';
import jiraIcon from "./../../svg/integration/Jira_blue_33.svg";
import rights from "../../components/rights";

const __ = window.__;

var innerSettings = {};

var innerViews = {
  defaultView: {
    cols: [
      {},
      {
        id: 'integrationFirstDataView',
        css: 'integrationFirstDataView',
        hidden: (user.team && user.team.is_jira),
        borderless: true,
        height: 342,
        width: 950,
        template: function (obj) {
          let result = "<div class='jira-first-connect-layout'>" +
            "<div class='jira-first-connect-layout-icon'></div>" +
            "<div class='jira-first-connect-layout-desc'>" +
            "<div class='jira-first-connect-layout-desc-icon'>" + jiraIcon + "</div>" +
            "<div class='jira-first-connect-layout-desc-text'>" +
            (rights.account.hasRight('integration_settings') ? __("integration_jira_desc_popup") : __("jira-first-connect-layout-desc-no-rights"))
            + "</div>";

          if(rights.account.hasRight('integration_settings')) {
            result +=
                "<div class='jira-first-connect-layout-desc-button'>" + __("common_read_more") + "</div>" +
              "</div>" +
              "</div>"
          } else {
            result +=
              "</div>" +
              "</div>"
          }
          return result;
        },
        onClick: {
          "jira-first-connect-layout-desc-button": function () {
            jiraIntegration.init()
              .then(function () {
                $$("integrationsPopup").hide();
              });
          }
        }
      },
      {}
    ]
  }
};

var innerHandlers = {
  init: function () {
    if (user.team && user.team.is_jira) {
      jiraNewProject.init();
    }
  },
  getView: function () {
    if (user.team && user.team.is_jira) {
      return jiraNewProject.view;
    } else {
      return innerViews.defaultView;
    }
  }
};

var outputObject = {
  init: innerHandlers.init,
  view: innerHandlers.getView
};

export default outputObject;
