import app from "./../app";
import moment from "./../libs/moment";
import _ from "../libs/lodash";

const baselineData = {};
let baselines = new webix.DataCollection();

if (app.config.mode.isBase) {
  baselines = new webix.DataCollection({
    save: {
      url: "rest->/api/history/baseline",
      updateFromResponse: true
    }
  });
} else {
  baselines = new webix.DataCollection({
    scheme: {
      $init: function(item) {
        if (GT.ganttData.baselineTasks) {
          GT.ganttData.baselineTasks.tasks = baselines.prepareBaselineTasks(GT.ganttData.baselineTasks);
        }

        baselineData[item.gantt_id] = GT.ganttData.baselineTasks;
      }
    }
  });

  if (GT.ganttData.baselines) {
    baselines.parse(GT.ganttData.baselines);
  } else {
    baselines.parse([]);
  }
}

baselines.init = function () {
  this.clearAll();

  if (gantt && app.config.mode.isBase) {
    return webix.ajax().get(`/api/history/baseline/${gantt.config.gantt_id}?time=${Date.now()}`)
      .then(function (data) {
        baselines.parse(data.json());
        window.gantt.callEvent("baseline:updated");
      });
  } else {
    gantt && gantt.callEvent("baseline:updated");
    return webix.promise.defer().resolve();
  }
};

baselines.create = function (ganttID, eventID) {
  var format = 'MMM D, YYYY H:mm';

  baselines.add({
    gantt_id: ganttID,
    event_id: eventID,
    name: eventID ? null : moment().format(format)
  });
};

baselines.getUserCustomValuesMap = function (projectId) {
    if (!baselineData[projectId]) return {};

    const valuesMap = {};
    const userCustomColumnsData = baselineData[projectId]?.userCustomColumnsData;

    if (!userCustomColumnsData) {
      return valuesMap;
    }

    Object.keys(userCustomColumnsData.values).forEach(taskId => {
      const customColumnValues = userCustomColumnsData.values[taskId];

      customColumnValues.forEach(customColumnValueObject => {
        valuesMap[`${taskId}-${customColumnValueObject.column_id}`] = customColumnValueObject.task_value;
      });
    });

    return valuesMap;
};

baselines.getBaselineUserCustomValuesByParams = function (ganttID, columnID, taskID) {
  if (!baselineData[ganttID]) {
    return null;
  }

  const userCustomColumnsData = baselineData[ganttID].userCustomColumnsData;

  if (!userCustomColumnsData) {
    return null;
  }

  let result = userCustomColumnsData.values;

  if (taskID) {
    result = result[taskID];
  }

  if (columnID) {
    result = _.filter(result, o => o.column_id === +columnID);
  }

  return result;
};

baselines.getBaselineData = function (ganttID) {
  return baselineData[ganttID];
};

baselines.prepareBaselineTasks = function (data) {
  return _.map(data.tasks, function (task) {
    task.status = (data.customValues[task.id] && data.customValues[task.id].status) || data.defaultCustomValues.status;
    task.priority = (data.customValues[task.id] && data.customValues[task.id].priority) || data.defaultCustomValues.priority;
    task.jira_key = (data.customValues[task.id] && data.customValues[task.id].jira_key) || "";
    task.resources = data.resourcesToTasks[task.id] || [];

    return task;
  });
};

baselines.getByGanttIdAndEventId = function (ganttID, eventID) {
  return webix.ajax().get("/api/history/baseline/" + eventID + "/" + ganttID)
    .then((data) => {
      const parsedData = data.json();
      parsedData.tasks = baselines.prepareBaselineTasks(parsedData);

      if (!parsedData.userCustomColumnsData) {
        parsedData.userCustomColumnsData = {
          columns: [],
          id: ganttID,
          values: []
        };
      }

      baselineData[ganttID] = parsedData;
      return Promise.resolve(parsedData);
    });
};

baselines.delete = function (id) {
  baselines.remove(+id);
  gantt.callEvent("baseline:updated");
};

baselines.updateName = function (item) {
  this.exists(item.id) && this.updateItem(item.id, {
    name: item.name
  });
};

baselines.getAll = function () {
  return baselines.serialize();
};

baselines.getByGanttId = function (ganttID) {
  return _.filter(baselines.getAll(), function (item) {
    return +item.gantt_id === +ganttID;
  })
};

baselines.getName = function (id) {
  var baseline = this.getItem(id);

  if (baseline) {
    return baseline.name;
  } else {
    return '';
  }
};

baselines.isEmpty = function () {
  return baselines.count() === 0;
};

baselines.attachEvent("onDataUpdate", function (oldid, newid) {
  gantt.callEvent("baseline:updated");
});

export default baselines;
