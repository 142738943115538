<template>
  <div>
    <div
      v-if="!access"
      class="timelog-block-layout"
    >
      <div class="container">
        <div class="title-wrapper">
          <div class="main-title">
            {{ locales('timelog_access_title') }}
          </div>
          <div class="pricing-title-container">
            {{ locales(planTitle) }}
          </div>
        </div>
        <div class="desc">
          <div class="desc-text">
            {{ locales('timelog_access_desc') }}
            <div class="learn_more">
              <a
                class="pricing-popup-link"
                :href="locales('timelog_learn_more')"
                target="_blank"
                @click="linkClick"
              >{{ locales('help_read_more') }}</a>
              <webix-ui
                class="arrow-left"
                :config="icons.arrowLeft"
              />
            </div>
          </div>
        </div>
        <upgrade-btn v-if="isOwner"
                     :feature="'time_tracking'"/>
        <div class="pic">
          <img
            :src="pic"
            alt=""
          >
        </div>
      </div>
    </div>
    <webix-ui
      v-else
      :config="webixUI"
    />
    <router-view />
  </div>
</template>

<script>
import { webixUI, init } from './userLogs';
import pricingHelper from '../../helpers/pricingHelper';

import upgradeBtn from '../common/VueComponents/ugradeButton/upgradeButton.vue';
import svgIconForVue from "../common/svgIconForVue";
import arrowLeft from "../../svg/pricing/ic_arrow_left.svg";
import rights from '../../components/rights/index';

export default {
  name: 'Reports',
  components: {
    upgradeBtn,
  },
  data() {
    return {
      locales: __,
      webixUI,
      pic: `${GT.cdn}/imgs/myTimeLog/im_time_log.png`,
      icons: {
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
      },
    };
  },
  computed: {
    access() {
      return pricingHelper.checkPricingAccess('time_tracking');
    },
    planTitle() {
      const planTitle = `${pricingHelper.getPlans('time_tracking')}_plan_locale`;
      return planTitle;
    },
    isOwner() {
      return rights.account.isOwner();
    },
  },
  mounted() {
    const access = pricingHelper.checkPricingAccess('time_tracking');
    this.$nextTick(() => {
      if (access) {
        init();
      }
    });
  },
  methods: {
    linkClick() {
      userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'time_tracking' });
    },
  },
};
</script>

<style scoped src="./less/timeLog.less"></style>
