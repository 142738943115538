import Store from '../store/main'
import globalStore from "../store/main";


const calculate = function(state, withHeader) {
  const header = $$('header');

  if (!header ) {
    return true;
  }

  // const headerOffsetTop = withHeader ? 0 : header.getNode().offsetHeight;
  const headerOffsetTop = withHeader ? 0 : header.$view.offsetHeight;
  const leftSideBarWidth = Store.getters.leftSidebarWidth;
  const footerHeight = Store.getters['footer/height'];

  state.height = document.body.clientHeight - headerOffsetTop - footerHeight + 1;
  state.width = document.body.clientWidth - leftSideBarWidth;

  if (this && this.config) {
    this.config.height = state.height;
  }

  state.left = leftSideBarWidth+1;
  state.top = headerOffsetTop;
};

export default calculate;
