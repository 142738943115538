<template>
  <div :class="$style.progress_wrapper">
    <progress-bar :value="originalValue" />
    <div>{{ renderValue }}</div>
  </div>
  
</template>

<script>
import progressBar from "../../progressBar/progressBar.vue";

export default {
  name: 'ProgressCell',
  components: { progressBar },
  props: ['context'],
  computed: {
    originalValue() {
      return this.context.row.original.progress.percentage;
    },
    renderValue() {
      return `${this.context.cell.getValue().percentage} %`;
    }
  }
}
</script>

<style module src="./less/progressCell.less"></style>