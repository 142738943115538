"use strict";

webix.protoUI({
  name: "dhx-gantt",
  borderless: true,
  $init: function() {
    this.$ready.push(function() {
      this.$view.innerHTML = "<div class='dhx_gantt_container' style='width:100%; height:100%;'></div>";

    });
  }
}, webix.ui.view);