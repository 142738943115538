var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LayoutHeaderRightBar", {
        key: _vm.layoutTitle,
        attrs: {
          title: _vm.layoutTitle,
          iframe: _vm.GanttIframe,
          paperWithSvg: _vm.PaperWithSvg,
          rightBar: _vm.RightBar,
          appFilter: _vm.appFilter,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }