/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable import/no-mutable-exports */
import app from '../../../app';
import npsPopup from './npsPopup';
import helperFeedback from '../../../helpers/feedback';
import routerHelper from '../../../helpers/router';

const __ = window.__;

var innerSettings = {
  data: '',
  changeData() {
    return innerSettings.data;
  },
  planName: '',
};

const innerTemplate = {
  getBodyMessageUsForm: data => `
      <div class="message-view ${data}">
        <div class="view-title">${__(`message_view_text_${data}`)}</div>
        <div class="view-textarea">
            <p class="view-textarea--error">${__(`message_view_text_error`)}</p>
            <textarea id="messageTextArea" class="view-textarea--area" form="text" name="feedback__text" placeholder="${__(`message_view_placeholder_${data}`)}"></textarea>
        </div>
        <div class="view-footer">
            <div class="view-footer--button view-footer--button--add">${__(`message_view_button_${data}`)}</div>
            <div class="view-footer--privacy"><span>${__(`message_view_data_privacy`)}</span></div>
        </div>
      </div>
    `,
  getHeaderTitleText() {
    const dataName = innerHandlers.setDataName(innerSettings.data);

    if ($$(innerObject.views.main.id)) {
      return __(`message_header_title_${dataName}`);
    }
  },
};

var innerHandlers = {
  validateForm: () => {
    const textareaCurrent = document.querySelector('.view-textarea');
    const textareaCurrentText = document.querySelector('.view-textarea--area');

    if (!textareaCurrentText.value.trim()) {
      textareaCurrent.classList.add('error');

      return false;
    }
    textareaCurrent.classList.remove('error');

    return true;
  },
  setDataName: data => {
    const dataName = data;

    return dataName;
  },
  sendEmail() {
    const dataName = innerHandlers.setDataName(innerSettings.changeData());

    helperFeedback.send({
      message: document.getElementById('messageTextArea').value,
      type: dataName,
      send_followup_email: false,
    }).then(() => {
      document.getElementById('messageTextArea').value = '';
    });
  },
};

var innerObject = {
  views: {
    main: {
      id: 'messageUsForm',
    },
    body: {
      id: 'messageUsFormBody',
    },
  },
  headerMessage: {
    label: '',
  },
};

var outputObject = {
  view: 'ganttproWindowPopup',
  id: innerObject.views.main.id,
  css: 'message-us-form',
  width: 564,
  hidden: true,
  zIndex: 114,
  header: {
    label: '',
  },
  body: {
    id: innerObject.views.body.id,
    autoheight: true,
    view: 'template',
    borderless: true,
    template: '',
    onClick: {
      'view-footer--button--add': function () {
        if (innerHandlers.validateForm()) {
          innerHandlers.sendEmail();
          userExtAnalytics.log('pricing_contact_us_sent', innerSettings.planName);
          $$(outputObject.id).hide();
          npsPopup.initThankPopup('', innerSettings.data);
        }
      },
    },
    on: {
      onKeyPress() {
        _.delay(() => {
          innerHandlers.validateForm();
        });
      },
    },
  },
};

app.on('messageUsForm:show', (featuresName, planName, additionalKey) => {
  userExtAnalytics.log('pricing_contact_us_opened', planName);
  innerSettings.data = featuresName;
  innerSettings.planName = planName;
  innerHandlers.setDataName(innerSettings.data);
  innerTemplate.getHeaderTitleText(innerSettings.data);
  $$(innerObject.views.main.id).updateHead('', innerTemplate.getHeaderTitleText(innerSettings.data));
  $$(innerObject.views.body.id).define('template', innerTemplate.getBodyMessageUsForm(additionalKey || innerSettings.data));
  $$(innerObject.views.body.id).refresh();
  $$(innerObject.views.body.id).render();
  $$(innerObject.views.main.id).show();
  document.getElementById('messageTextArea').focus();

  analyticsMessageUsForm(featuresName);
});

function analyticsMessageUsForm(data) {
  const url = routerHelper.getCurrentRoute().fullPath;
  let from = '';

  if (url.includes('workload')) {
    from = 'general workload';
    if (url.includes('project/')) from = 'project workload';
    if (url.includes('projects/')) from = 'portfolio  workload';
  } else {
    if (url.includes('reports')) from = 'reports';
    if (url.includes('integrations')) from = 'integrations';
    if (url.includes('list') && url.includes('tasks')) from = 'mylist';
    if (url.includes('dashboard')) from = 'portfolio dashboard';
  }

  if (!from) return;
  userExtAnalytics.log('header_click_missed_features', { from });
}

export default outputObject;
