<template>
  <div :class="$style.vgp_label">
    <div :class="$style.vgp_label_header">
      <sprite
        v-if="icon"
        :class="$style.icon"
        color="#999999"
        :name="icon.name || 'check'"
        :type="icon.type || 'regular'"
        :size="24"
      />

      <span
        v-if="label"
        :class="[$style.vgp_label_text, $style.elipsis_text]"
      >
        {{ label }}
        <sup v-if="labelAsterix">*</sup>
      </span>
      <span
        v-if="action"
        :class="$style.vgp_label_action"
        @click="onClick"
        :style="actionStyle ? actionStyle : {}"
      >
        {{ action }}
      </span>
    </div>
    <div :class="$style.vgp_label_slot">
      <slot name="body" />
    </div>
    <div
      v-if="description"
      :class="$style.vgp_label_description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import sprite from '$$vueCmp/VueIcon/svgSprite.vue';

export default {
  name: 'VgpLabelSlot',
  components: {
    sprite,
  },
  props: {
    label: {
      type: String,
      required: true,
      default: 'Text',
    },
    labelAsterix: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: Object, // { name, type, size }
      required: false,
      default: null,
    },
    action: {
      type: String,
      required: false,
      default: null,
    },
    actionStyle: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: {
    onClickAction: null,
  },
  methods: {
    onClick() {
      this.$emit('onClickAction');
    },
  },
};
</script>

<style module src="./less/label.less">
</style>
