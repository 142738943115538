import app from '../../../app';
import timeTracking from '../../../models/timeTracking';
import { refreshParents } from './helpers';

function onTaskTimeLogsCreated(event) {
  const items = event.innerSettings.collaborationItems;
  const gantt_id = event.innerSettings.gantt_id;

  timeTracking.manualUpdateItemForTask({
    task_id: event.data.timeLogs[0].taskId,
    gantt_id,
  }, items);
  app.trigger('timeTrackingModel:change', event.data.timeLogs[0].taskId);

  refreshParents(event.data.timeLogs[0].taskId);
}

export default onTaskTimeLogsCreated;
