import app from '../app';

let usersAttachmentType = new webix.DataCollection();

if (app.config.mode.isBase) {
  usersAttachmentType = new webix.DataCollection({
    url: `rest->/api/usersAttachmentType/?${new Date().getTime()}`,
    save: {
      url: 'rest->/api/usersAttachmentType/',
      updateFromResponse: true,
    },
  });
}

usersAttachmentType.attachEvent('onDataUpdate', (oldid, newid) => {
  // console.log(1,this.serialize());
});

usersAttachmentType.getAllUsersAttachmentTypes = function () {
  return this.serialize();
};

usersAttachmentType.getProfileByToken = function (data) {
  return new Promise((resolve, reject) => {
    webix.ajax().sync().get('/api/usersAttachmentType/profileByToken/', data, response => {
      resolve(JSON.parse(response));
    });
  });
};

usersAttachmentType.update = function (id, data) {
  this.updateItem(id, data);
};

usersAttachmentType.createDocument = function (data) {
  return new Promise((resolve, reject) => {
    webix.ajax().post('/api/usersAttachmentType/createDocument/', data, response => {
      resolve(JSON.parse(response));
    });
  });
};

usersAttachmentType.addUsersAttachmentType = function (data) {
  const foundTokens = usersAttachmentType.find(obj => obj.key === data.key);
  const that = this;

  foundTokens.forEach(token => {
    that.data.blockEvent();
    usersAttachmentType.remove(token.id);
    that.data.unblockEvent();
  });
  usersAttachmentType.add(data);
};

usersAttachmentType.deleteUsersTemplate = function (id) {
  templates.remove(id);
};

usersAttachmentType.updateUsersTemplate = function (id, value) {
  templates.updateItem(id, { name: value });
};

export default usersAttachmentType;
