import store from '../../../store/main';
import constanst from '../../../helpers/constants';

function onProgressEventFinished(event) {
  const userIds = event.data.userIds;
  const userId = event.data.userId;

  console.log('====== finish', event);
  if (userIds.find(id => id === user.id) || userId === user.id) {
    store.commit('progress/update', event.data);
    store.commit('progress/setEventToMap', { token: event.data.token, event: event.event, progress: event.data.progress });
    setTimeout(() => {
      store.commit('progress/finish', event.data.token);
    }, constanst.PROGRESS_BAR.TIMEOUT);
  }
}

export default onProgressEventFinished;
