<template>
  <div
    :class="[$style.dropdown, $style.active]"
  >
    <ul>
      <li
        v-if="accessToEdit"
        @click="$emit('onClick')"
      >
        {{ locales(`roles_edit_${type}_dropdown`) }}
      </li>
      <li
        v-if="accessToDelete"
        class="element-li"
        @click="$emit('onDelete')"
      >
        {{ locales(`roles_delete_${type}_dropdown`) }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    accessToEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: 'account',
    },
    accessToDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      locales: __,
    };
  },

  mounted() {
    setTimeout(() => document.addEventListener('click', this.close), 1);
  },
  beforeDestroy() {
    setTimeout(() => document.removeEventListener('click', this.close), 1);
  },
  methods: {

    close(e) {
      if (e.target.closest('.vue-portal-target') === null && e.target.closest('.element-li') === null) {
        this.$emit('onClose');
      }
    },

  },
};
</script>

<style module src="../../less/role.less" lang="less"></style>
