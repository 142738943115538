// import commentsModel from '../../../models/comments2';
import app from '../../../app';
import projectsModel from '../../../models/projects';
import globalStore from '../../../store/main';

function onTaskCommentCreated(event) {
  // event.innerSettings.collaborationItems.forEach(comment => {
  //   const allGanttIds = projectsModel.getAllProjects().map(o => o.id);
  //
  //   if (allGanttIds.includes(comment.gantt_id)) {
  //     commentsModel.onWSAddComment({
  //       count: 1,
  //       data: comment,
  //     });
  //   }
  // });
  const allGanttIds = projectsModel.getAllProjects().map(o => o.id);

  event.data.comments.forEach(comment => {
    if (allGanttIds.includes(comment.projectId)) {
      globalStore.dispatch('comments/onWSCommentCreated', { projectId: comment.projectId, comment });
    }
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.comments.map(i => i.taskId),
  });
}

export default onTaskCommentCreated;
