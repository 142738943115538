import app from "./../app";
import notificationHelper from "./../helpers/notifications";
import teamModel from "./../models/team";

const __ = window.__;

var innerObject = {
  handlers: {
    addToTeam: function () {
      notificationHelper.show(
        __("notifications_teamUserAdd"),
        "info",
        80000000
      );
    },
    removeFromTeam: function () {
      notificationHelper.show(
        __("notifications_teamRemoveUser"),
        "warning",
        80000000
      );
    },
    changeUserStatus: function (newTeamMemberStatus) {
      newTeamMemberStatus === 1 && innerObject.handlers.userActivate();
      newTeamMemberStatus === 2 && innerObject.handlers.userDeactivate();
    },
    userActivate: function () {
      innerObject.helpers.prepareDataToNotify("team_change_status_active");
    },
    userDeactivate: function () {
      innerObject.helpers.prepareDataToNotify("team_change_status_inactive");
    },
    newCommentsByShareMessage: function (data) {
      var textData = this.prepareDataToMessage(data),
        text = textData.text;
      text += " " + __("notifications_shared");
      text += " <span class='txt_orange'>«" + data.projectData.name + "»</span>.";
      text += " " + __("notifications_share_access");
      text += " " + __("notifications_shared_access_" + textData.userRight);
      text += " " + __("notifications_currently_messages");
      text += " " + __("notifications_shared_unread_comments") + ":";
      text += " <b>" + data.count + "</b>";
      notificationHelper.show(text, "info");
    },
    prepareDataToMessage: function (data) {

      return {text: text, userRight: userRights};
    }
  },
  helpers: {
    prepareDataToNotify: function (langKey, langPart) {
      notificationHelper.show(
        __((langPart || "notifications_") + langKey),
        "info"
      );
    }
  },
  dataStoreEvents: {
    teamModel: {
      storeUpdateInit: function () {
        teamModel.data.attachEvent("teamChange", function (teamData, teamMode) {
          teamMode === "addToTeam" && innerObject.handlers.addToTeam(teamData);
          teamMode === "deleteFromTeam" && innerObject.handlers.removeFromTeam(teamData);
          teamMode === "teamMemberChangeStatus" && innerObject.handlers.changeUserStatus(teamData);
        });
      }
    }
  }
};

app.on("gantt:init:before", function () {
  innerObject.dataStoreEvents.teamModel.storeUpdateInit();
});

var outputObject = {
  event: {},
  showByEventAndLangKey: innerObject.helpers.prepareDataToNotify,
  userActivate: innerObject.handlers.userActivate,
  newSharedProject: innerObject.handlers.newCommentsByShareMessage,
  userDeactivate: innerObject.handlers.userDeactivate
};


export default outputObject;
