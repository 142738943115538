import app from "./../../../app";
import projectsModel from "./../../../models/projects";
import templatesModel from "./../../../models/templates";
import icon_template from "./../../../svg/template_icon.svg";
import logInternalHelper from "../../../helpers/logInternal";

const __ = window.__;

var innerObject = {
  views: {
    main: {
      id: "saveAsUserTemplate"
    },
    saveAsUserTemplateLayout: {
      id: "saveAsUserTemplateLayout"
    },

    saveUserTemplateForm: {
      id: "saveUserTemplateForm"
    },
    templateNameInput: {
      id: "userTemplateName",
      css: "userTemplateName",
      view: "text",
      label: __("settings_templateName"),
      labelPosition: "top",
      borderless: true,
      placeholder: __("templates_placeholders_type_here")
    },
    infoText: {
      view: "label",
      label: __("info_text_about_creating_templates"),
      css: 'text_label'
    },
    templateIconsSelect: {
      view: "dataview",
      align: "center",
      id: "userTemplateIcon",
      css: "user-template-project-list",
      borderless: true,
      yCount: 2,
      xCount: 4,
      height: 240,
      template: function (obj) {
        //return "<div class='userTemplates__elem'><div class='userTemplates__icon'>" + obj.icon + "</div></div>"
        //return "<div class='userTemplates__elem dataview_elem'><div class='dataview_icon'>" + obj.icon + "</div></div>",
        return "<div class='user-template-projects-popup-list-item'>" +
          "<div class='user-template-projects-popup-list-item-icon'>" + obj.icon + "</div>" +
          "<div class='user-template-projects-popup-list-item-label'></div>" +
          "</div>";
      },
      type: {
        width: 132,
        height: 120
      },
      data: [],
      on: {
        "onItemClick": function (id, e, node) {
          innerObject.handlers.changeIcon(id);
        },
        "onItemDblClick": function (id) {
          innerObject.handlers.changeIcon(id);
          innerObject.handlers.buttonClickCreateTemplate();
        }
      }
    },
    sharedBox: {
      view: "checkbox",
      id: "userTemplateShareCheckbox",
      css: "cursor-pointer tooltip-gantt public-link-checkbox",
      labelRight: __("share_template_checkbox"),
      labelWidth: 0,
      value: 0,
      width: 300,
      height: 36,
      on: {
        "onAfterRender": function () {
          this.getNode().dataset.key = __("share_template_checkbox_tooltip");
        }
      }
    },
    templateButtonSave: {
      view: "button",
      type: "success",
      align: "right",
      id: "templateButtonSave",
      width: 150,
      css: "webix_button_default button_raised",
      value: __("common_save"),
      on: {
        "onItemClick": function () {
          innerObject.handlers.buttonClickCreateTemplate();
        }
      }
    }
  },
  init: {
    run: function () {
      //var dataIcon = [];
      // for (var k in iconsObject) {
      //   if (iconsObject.hasOwnProperty(k) && k !== 'blank' && k !== 'jira') {
      //     dataIcon.push({
      //       name: k,
      //       icon: iconsObject[k]
      //     });
      //   }
      // }
      //$$(innerObject.views.templateIconsSelect.id).parse([]);
    }
  },
  currentSettings: {},
  settings: {
    // projectIcons: iconsObject,
    stopSaving: false
  },
  handlers: {
    buttonClickCreateTemplate: _.debounce(function () {
      var stopper = innerObject.settings.stopSaving;
      if (!stopper) {
        stopper = true;
        innerObject.handlers.saveTemplateOnServer();
        userExtAnalytics.log("project_template_created");
      }
    }, 200),
    showPopup: function () {
      $$(innerObject.views.main.id).show();
      innerObject.handlers.initFreeLayout();
      logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
    },
    initFreeLayout: function () {

    },
    buttonCloseClick: function () {
      $$(innerObject.views.main.id).hide();
    },
    clearForm: function () {
      $$(innerObject.views.templateNameInput.id).setValue('');
      $$(innerObject.views.templateIconsSelect.id).unselectAll();
    },
    saveTemplateOnServer: function (data, callback) {
      var projectData = projectsModel.getActiveProjectData(),
        icon = $$(innerObject.views.templateIconsSelect.id).getSelectedItem(),
        templateName = $$(innerObject.views.templateNameInput.id).getValue().trim(),
        isShare = $$(innerObject.views.sharedBox.id).getValue();

      /*if (!icon) {
        webix.message({ type: "info", text: __("settings_errorSelectIcon") });
        return false;
      }*/

      if (!templateName.length) {
        webix.message({type: "info", text: __("settings_errorTemplateName")});
        return false;
      }

      $$(innerObject.views.templateButtonSave.id).disable();

      webix.ajax().post("/api/usersTemplate/",
        {gantt_id: projectData.gantt_id, icon: 'default', name: templateName, skin: projectData.skin, is_shared: isShare})
        .then(function (response) {
          callback && callback();
          var templateData = response.json();

          $$(innerObject.views.templateButtonSave.id).enable();

          innerObject.handlers.clearForm();
          $$(innerObject.views.main.id).hide();
          webix.message({type: "success", text: __("settings_successSaveTemplate")});

          templatesModel.addUserTemplate(templateData.gantt_id, 'default', templateName, templateData.id, templateData.has_resources);
          innerObject.settings.stopSaving = false;
        });
    },
    changeIcon: function (id) {
      $$(innerObject.views.templateIconsSelect.id).unselectAll();
      $$(innerObject.views.templateIconsSelect.id).select([id], false, false);
    }
  }
};

webix.ui({
  view: 'ganttproWindowPopup',
  zIndex: 999,
  width: 636,
  id: innerObject.views.main.id,
  css: "creatingTemplatePopup creating-template",
  header: {
    icon: icon_template,
    label: __("settings_titleUserTemplate"),
    help: {
      id: 'createTemplateHelpBlockView',
      dataId: 'saveUserTemplateHelpBlock',
    }
  },
  body: {
    id: innerObject.views.saveAsUserTemplateLayout.id,
    css: "creating-template-body",
    width: 636,
    rows: [
      {
        view: "form",
        css: "creating-template-body-form",
        height: 130,
        borderless: true,
        paddingX: 24,
        paddingY: 0,
        margin: 12,
        elements: [
          innerObject.views.infoText,
          innerObject.views.templateNameInput,
          innerObject.views.templateIconsSelect,
        ]
      },
      {
        height: 18,
      },
      {
        height: 36,
        cols: [
          {
            width: 24,
          },
          {
            css: "creating-template-body-footer",
            cols: [
              //innerObject.views.buttonCancel,
              innerObject.views.sharedBox,
              {},
              innerObject.views.templateButtonSave
            ]
          },
          {
            width: 24,
          },
        ],
      },
      {
        height: 24,
      }
    ]
  },
  on: {
    'onBeforeShow': function () {
      app.trigger("popup:show");
      userExtAnalytics.log('project_save_template_open');
    },
    'onHide': function () {

    },
    "onShow": function () {
      logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
      $$('userTemplateName').focus();
    }
  }
}).hide();

var outputObject = {
  handlers: {
    show: innerObject.handlers.showPopup
  }
};
app.on("app:start", function () {
  innerObject.init.run();
});

app.on("popup:show", function () {
  $$('saveAsUserTemplate').hide();
});

export default outputObject;
