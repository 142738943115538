<template>
  <onboarding-popup />
</template>

<script>
import { mapState } from 'vuex';
import OnboardingPopup from './OnboardingPopup.vue';
import app from '../../app';
import { ANALYTIC_ACTIONS, ANALYTIC_TYPES } from './onboarding.config';
import templatesModel from '../../models/templates';
import profileModel from '../../models/profile';
import rights from '../..//components/rights/index';

const APP_EVENT_LISTENER_IDS = [];

export default {
  name: 'OnboardingPage',
  components: { OnboardingPopup },
  computed: {
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
  },
  created() {
    const projectComponentAddEventId = this.addAppProjectComponentAddListener();

    APP_EVENT_LISTENER_IDS.push(projectComponentAddEventId);

    this.defineAndSetOnboardingFlow();
    this.setAnalyticType();
  },
  beforeDestroy() {
    APP_EVENT_LISTENER_IDS.forEach(eventId => app.off(eventId));
  },
  methods: {
    addAppProjectComponentAddListener() {
      return app.on('project:component:add', async () => {
        // if project was created from landing (mpp or template) or imported from wizard form
        if (['personalInfo', 'wizardFirstProject'].includes(this.activeStepId)) {
          // This handler also fires if owner shared project with new team member
          // before he registered (pressed submit button on 1-PersonalInfo.vue)
          // In this case we don't end onboarding and do not pushing to the shared project
          if (!user.firstName || !user.lastName) return;

          if (rights.account.isOwner()) {
            userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
            userExtAnalytics.log('onboarding', { type: this.analyticType, step: 'finish', action: ANALYTIC_ACTIONS.DONE });
            userExtAnalytics.log('project_goal');
            userExtAnalytics.log('project_create_done');
          }
          await this.$store.dispatch('user/updateActivitySettings', {
            activityName: 'onboarding',
            settings: { isFinished: true },
          });
          this.$router.push({ name: 'app' });
          app.trigger("gantt:progress:show");
        }
      });
    },
    defineAndSetOnboardingFlow() {
      const isUserOwner = rights.account.isOwner();
      const onboardingActivitySettings = this.$store.getters['user/getActivitySettings']('onboarding');
      let onboardingFlowKey = null;

      // Defining key on first user session, because we dont have it in database yet
      if (!onboardingActivitySettings?.flowKey) {
        const ONBOARDING_FLOW_KEY_CONDITIONS = {
          OWNER_MANUALLY: isUserOwner && user.isPasswordNeverSet && !user.isPasswordNotRequired,
          OWNER_SOCIAL_NETWORKS: isUserOwner && user.isPasswordNotRequired,
          TEAM_MEMBER_MANUALLY: !isUserOwner && user.isPasswordNeverSet && !user.isPasswordNotRequired,
          TEAM_MEMBER_SOCIAL_NETWORKS: !isUserOwner && user.isPasswordNotRequired,
        };

        onboardingFlowKey = Object
          .keys(ONBOARDING_FLOW_KEY_CONDITIONS)
          .find(key => ONBOARDING_FLOW_KEY_CONDITIONS[key]);
      }

      // Get onboarding flow key from database if exists
      if (onboardingActivitySettings?.flowKey) {
        onboardingFlowKey = onboardingActivitySettings.flowKey;
      }

      if (!onboardingFlowKey) {
        console.warn('Failed to define onboardingFlowKey');

        return;
      }

      this.$store.commit('onboarding/setCurrentOnboardingFlowKey', onboardingFlowKey);
      this.$store.dispatch('user/updateActivitySettings', {
        activityName: 'onboarding',
        settings: {
          flowKey: onboardingFlowKey,
        },
      });

      if (onboardingActivitySettings?.lastActiveStep) {
        const isLastActiveStepWizardStep = onboardingActivitySettings.lastActiveStep.indexOf('wizard') >= 0;

        if (isLastActiveStepWizardStep) {
          this.$store.commit('onboarding/setActiveStepId', 'wizardFirstProject');
        } else {
          this.$store.commit('onboarding/setActiveStepId', onboardingActivitySettings.lastActiveStep);
        }

        return;
      }

      const initialOnboardingStep = this.$store.getters['onboarding/getCurrentOnboardingFlow'].stepsOrder[0];

      this.$store.commit('onboarding/setActiveStepId', initialOnboardingStep);
    },
    setAnalyticType() {
      if (!rights.account.isOwner()) {
        this.$store.commit('onboarding/setAnalyticType', ANALYTIC_TYPES.INVITED);

        return;
      }

      if (user.mpp?.js_url && user.mpp?.name) {
        this.$store.commit('onboarding/setAnalyticType', ANALYTIC_TYPES.MPP);

        return;
      }

      const allTemplatesData = _.cloneDeep(templatesModel.getBaseTemplates());
      const defaultTemplate = profileModel.getDefaultGanttTemplate(allTemplatesData);

      if (defaultTemplate) {
        this.$store.commit('onboarding/setAnalyticType', ANALYTIC_TYPES.TEMPLATE);

        return;
      }

      this.$store.commit('onboarding/setAnalyticType', ANALYTIC_TYPES.GENERAL);
    },
  },
};
</script>

<style module lang="less">

</style>
