// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".context_menu_iFO3MRNZ {\n  cursor: pointer;\n}\n.context_menu_iFO3MRNZ:hover svg {\n  color: #4C4C4C;\n}\n", "",{"version":3,"sources":["webpack://./public/views/common/VueComponents/table/cells/less/contextMenuCell.less","webpack://./contextMenuCell.less"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF;ADAE;EAEI,cAAA;ACCN","sourcesContent":[".context_menu {\n  cursor: pointer;\n  &:hover {\n    svg {\n      color: #4C4C4C;\n    }\n  }\n}",".context_menu {\n  cursor: pointer;\n}\n.context_menu:hover svg {\n  color: #4C4C4C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"context_menu": "context_menu_iFO3MRNZ"
};
export default ___CSS_LOADER_EXPORT___;
