import account from './account';
import project from './project';
import getUserId from './helpers/getCurrentUserId';

const rightsComponent = {
  account,
  project,
  getUserId
};

export default rightsComponent;
