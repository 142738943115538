<template>
  <div style="min-width: 790px">
    <div
      v-if="!accessToColumns"
      class="columns-block-layout"
    >
      <div class="container">
        <div class="title-wrapper">
          <div class="main-title">
            {{ locales('columns_access_title') }}
          </div>
          <div class="pricing-title-container">
            {{ locales(planTitle) }}
          </div>
        </div>
        <div class="desc">
          <div class="desc-text">
            {{ locales('columns_access_desc') }}
            <div class="learn_more">
              <a
                class="pricing-popup-link"
                :href="locales('custom_fields_learn_more')"
                target="_blank"
                @click="linkClick"
              >{{ locales('help_read_more') }}</a>
              <webix-ui
                class="arrow-left"
                :config="icons.arrowLeft"
              />
            </div>
          </div>
        </div>
        <upgrade-btn
          v-if="isOwner"
          :feature="'custom_fields'"
        />
        <div class="pic">
          <img
            :src="pic"
            alt=""
          >
        </div>
      </div>
    </div>
    <webix-ui
      v-else
      :config="webixUI"
    />
    <router-view />
  </div>
</template>

<script>
import upgradeBtn from '../../common/VueComponents/ugradeButton/upgradeButton.vue';

import columns from '../include/columns';
import pricingHelpers from '../../../helpers/pricingHelper';
import svgIconForVue from "../../common/svgIconForVue";
import arrowLeft from "../../../svg/pricing/ic_arrow_left.svg";
import rights from '../../../components/rights/index';

export default {
  name: 'Columns',
  components: {
    upgradeBtn,
  },
  data() {
    return {
      locales: __,
      webixUI: columns.webixUI,
      pic: `${GT.cdn}/imgs/custom_columns/blocked_im_custom_fields_2.png`,
      icons: {
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
      },
    };
  },
  computed: {
    accessToColumns() {
      return pricingHelpers.checkPricingAccess('custom_fields');
    },
    planTitle() {
      return `${pricingHelpers.getPlans('custom_fields')}_plan_locale`;
    },
    isOwner() {
      return rights.account.isOwner()
    },
  },
  mounted() {
    const accessToColumns = pricingHelpers.checkPricingAccess('custom_fields');

    if (accessToColumns) {
      this.$nextTick(() => {
        columns.handlers.init();
      });
    }
  },
  methods: {
    linkClick() {
      userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'custom_fields' });
    },
  },
};
</script>

<style scoped src="../less/columns.less">

</style>
