/* eslint-disable no-undef, no-param-reassign, no-unused-vars, no-use-before-define */
import app from '../../app';
import _ from '../../libs/lodash';
import moment from '../../libs/moment';

import projectsModel from '../../models/projects';
import timeTrackingModel from '../../models/timeTracking';

import constants from '../../helpers/constants';
import timeParser from '../../helpers/timeParser';
import assigneeTemplateHelper from '../../helpers/getAssigneeAvatarsTemplate';
import routerHelper from '../../helpers/router';
import customHelper from '../../helpers/custom';

import icon_comments from '../../svg/time_comment.svg';
import icon_edit from '../../svg/time_tracking_edit.svg';
import icon_delete from '../../svg/time_tracking_trash.svg';
import icon_clock from '../../svg/time_tracking_clock.svg';
import icon_arrow from '../../svg/arrow_timer.svg';
import icon_close from '../../svg/ic_close.svg';
import start_timer_icon from '../../svg/start_timer_icon.svg';
import stop_timer_icon from '../../svg/stop_timer_icon.svg';
import dateHelper from '../../helpers/dateFormats';
import globalStore from '$$store/main';
import rights from '../../components/rights';

const __ = window.__;

const MAX_TIME = (24 * 60) - 1;
const MAX_TIME_SECONDS = (24 * 60 * 60) - 1;

const POPUP_WIDTH = {
  global: 436,
  task: 436,
};

const POPUP_HEIGHT = {
  global: 510,
};

const constantValues = {
  maxHistoryVisibleItems: 8,
  minHistoryVisibleItems: 3,
  historyVisibleItems: 0,
  historyItemHeight: 36,
};

const POPUP_ID = 'timeTrackingPopup';
const POPUP_SEARCH_ID = 'timeTrackingSearchPopup';
const POPUP_SEARCH_WRAP_ID = 'timeTrackingSearchPopupWrapper';
const POPUP_SEARCH_LIST_ID = 'timeTrackingSearchList';
const POPUP_SELECTED_TASK_ID = 'timeTrackingSearchList-box';
const POPUP_WRAP_ID = 'timeTrackingPopupWrapper';
const POPUP_TABS_ID = 'timeTrackingMenu';
const POPUP_TABS_WRAP_ID = 'timeTrackingTabWrapper';
const POPUP_TIMER_ID = 'timeTrackingTimer';
const POPUP_TIMER_BUTTON_ID = 'timeTrackingTimerButton';
const POPUP_SAVE_BUTTON_ID = 'timeTrackingButton';
const POPUP_COMMENT_AREA_ID = 'timeTrackingComment';
const POPUP_SEARCH_FIELD_ID = 'timeTrackingSearch';

let _popupState = {};

const _views = {
  header() {
    return {
      view: 'template',
      height: 60,
      css: 'time_tracking_header',
      borderless: true,
      template() {
        return `<div class="title">${__('time_tracking_title')}</div><div class="close-container js_close">${icon_close}</div>`;
      },
      onClick: {
        js_close() {
          $$(POPUP_ID).hide();
        },
      },
    };
  },
  tabs() {
    return {
      view: 'menu',
      id: POPUP_TABS_ID,
      layout: 'x',
      css: 'time_tracking_menu',
      select: true,
      width: POPUP_WIDTH[_popupState.mode],
      borderless: true,
      type: {
        height: 50,
        width: 145,
      },
      data: [
        {
          id: 'editor',
          value: `<div class="item"><div class="item-text">${__('tracked_time_creator_tab')}</div></div>`,
        },
        {
          id: 'timer',
          value: `<div class="item"><div class="item-text">${__('tracked_time_timer_tab')}</div></div>`,
        },
        {
          id: 'history',
          value: `<div class="item"><div class="item-text">${__('tracked_time_history_tab')}</div></div>`,
        },
      ],
      template(item) {
        if (item.id === 'history') {
          const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);
          const taskLogsData = taskData && timeTrackingModel.getLogsByParams(taskData.gantt_id, taskData.id);
          const defaultValue = taskData ? '(0)' : '';

          return `<div class="item">
                    <div class="item-text">${__('tracked_time_history_tab')}
                        <span class="count">${taskLogsData ? `(${taskLogsData.logs.length})` : defaultValue}</span>
                    </div>
                  </div>`;
        }

        return item.value;
      },
      on: {
        onBeforeSelect(id) {
          if (this.getItemNode(id).classList.contains('webix_disabled')) {
            return false;
          }

          if (_popupState.currentTab !== id) {
            _handlers.selectTab(id);
          }
        },
      },
    };
  },
  search() {
    if (_popupState.taskId && !_popupState.forceSearch) {
      return _views.tasksList(90, '-box');
    }

    return {
      view: 'text',
      id: POPUP_SEARCH_FIELD_ID,
      inputHeight: 36,
      height: 36,
      css: 'time_tracking_search',
      placeholder: __('time_tracking_search_placeholder'),
      label: __('time_tracking_select_task_error'),
      // labelHeight: 0,
      labelPosition: 'top',
      on: {
        onAfterRender() {
          const input = this.getNode().querySelector('input');

          input.addEventListener('paste', _handlers.onSearchInputPaste);
        },
        onDestruct() {
          const input = this.getNode().querySelector('input');

          input.removeEventListener('paste', _handlers.onSearchInputPaste);
        },
        onKeyPress(code, e) {
          if (e.target.value) {
            const oldVal = e.target.value.trim();

            _.delay(() => {
              const newVal = e.target.value.trim();

              if (newVal !== oldVal) {
                _handlers.showSearchResults(newVal);
              }
            });
          }
        },
        onItemClick(id, e) {
          const value = this.getValue().trim();

          if (!value) {
            _handlers.showSearchResults('');
          }
        },
        onBlur() {
          _popupState.forceSearch = null;
        },
      },
    };
  },
  timer() {
    return {
      view: 'layout',
      css: 'overflow_visible',
      cols: [
        {
          view: 'template',
          css: 'timer',
          id: POPUP_TIMER_ID,
          borderless: true,
          data: {
            hours: '00',
            minutes: '00',
            disable: false,
          },
          width: 180,
          template({ hours, minutes, disable }) {
            $$(POPUP_TIMER_ID).getNode().classList.remove('error');

            return `<div class="field${disable ? ' disable' : ''}">
              <input class="number hour" value="${hours}" type="text"/>
              <span class="divider">:</span>
              <input class="number minute" value="${minutes}" type="text"/>
              <span class="arrow up hour">${icon_arrow}</span>
              <span class="arrow down hour">${icon_arrow}</span>
              <span class="arrow up minute">${icon_arrow}</span>
              <span class="arrow down minute">${icon_arrow}</span>
              <span class="clock">${icon_clock}</span>
              <span class="error_mess">${__('time_tracking_timer_error')}</span>
              </div>`;
          },
          on: {
            onAfterRender() {
              const timer = this;
              const fields = timer.getNode().querySelectorAll('input');

              fields.forEach(field => {
                let value = null;

                field.onkeyup = function (e) {
                  if (timer.data.disable) {
                    e.preventDefault();

                    return;
                  }

                  const input = e.target;
                  const oldData = timer.getValues();

                  const range = input.classList.contains('hour') ? 'hour' : 'minute';

                  if (!/^\d+$/.test(input.value)) {
                    input.value = oldData[`${range}s`];
                  } else {
                    value = input.value;
                  }

                  if (!_.isNull(value)) {
                    _.delay(() => {
                      _handlers.changeTimer(parseInt(value, 10), range);
                    }, 500);
                  }
                };
              });
            },
          },
          onClick: {
            arrow(e) {
              if (this.data.disable) {
                return;
              }

              const classList = e.target.classList;
              const classListClosest = e.target.closest('.arrow').classList;
              const data = this.getValues();
              let value = parseInt(data.hours, 10);
              let range = 'hour';
              let delta = 1;
              let max = 24;

              if (classList.contains('minute') || classListClosest.contains('minute')) {
                value = parseInt(data.minutes, 10);
                range = 'minute';
                delta = 10;
                max = 60;
              }

              if (classList.contains('down') || classListClosest.contains('down')) {
                if ((value - delta) < 0) {
                  value = max - (delta - value);
                } else {
                  value -= delta;
                }
              }

              if (classList.contains('up') || classListClosest.contains('up')) {
                if ((value + delta) >= max) {
                  value = delta - (max - value);
                } else {
                  value += delta;
                }
              }

              _handlers.changeTimer(value, range);
            },
            number(e) {
              e.target.select();
            },
          },
        },
        {
          view: 'label',
          width: 52,
          css: 'label_for',
          label: __('tracker_label_for'),
        },
        {
          view: 'datepickeredit',
          width: 180,
          id: 'timeTrackingDate',
          css: 'time_tracking_datepicker',
          value: new Date(),
          icon: 'fa-calendar',
          on: {
            onEnter() {
              const popup = this.getPopup();

              this.callEvent('onBlur');

              if (popup.isVisible()) {
                popup.hide();
              }

              webix.UIManager.setFocus($$('timeTrackingPopup'));
            },
            onChange(newValue, oldValue) {
              const isEmpty = (newValue === '');
              const isValid = newValue instanceof Date && !Number.isNaN(newValue.valueOf());

              if (!isValid || isEmpty) {
                this.blockEvent();
                this.setValue(oldValue);
                this.unblockEvent();

                webix.message({ type: 'warning', text: __('gantt_date_invalid') });

                this.config.isInvalid = true;

                return;
              }

              const value = dateHelper.checkIfDateInLimit(newValue, true);

              if (value.getTime() !== newValue.getTime()) {
                this.blockEvent('onChange');
                this.setValue(value);
                this.unblockEvent('onChange');
              }
            },
          },
        },
      ],
    };
  },
  timerButton() {
    return {
      view: 'template',
      id: POPUP_TIMER_BUTTON_ID,
      css: 'timer_button',
      borderless: true,
      height: 36,
      data: {
        disable: false,
        active: false,
      },
      template({
        disable, time, tooltip, active,
      }) {
        $$(POPUP_TIMER_BUTTON_ID).getNode().classList.remove('error');

        const parsedTime = _helpers.parseSecondsToTimeString(time);
        const icon = active ? stop_timer_icon : start_timer_icon;
        const label = time || active ? parsedTime : __('start_time');
        const disableClass = disable ? 'disable' : '';

        return `<div class="wrap${disable ? ' disable' : ''}${tooltip ? ' tooltip-gantt' : ''}${active ? ' stop_timer' : ''}"
                    data-key="${tooltip ? __(tooltip) : ''}">
                  <span class="icon">${icon}</span>
                  <span class="label">${label}</span>
                </div><div class="error_mess">${__('ticked_zero_time')}</div>`;
      },
      onClick: {
        timer_button() {
          if (this.data.disable) {
            return;
          }

          _handlers.onTimerButtonClick();
        },
      },
    };
  },
  comment(x, isGlobal = false) {
    return {
      rows: [
        {
          view: 'label',
          height: 30,
          label: __('tracked_time_comment_label'),
        },
        {
          view: 'textarea',
          id: POPUP_COMMENT_AREA_ID,
          css: 'time_tracking_comment',
          placeholder: __('tracked_time_comment_placeholder'),
          autoheight: true,
          inputHeight: x,
          minHeight: isGlobal ? 0 : x,
          on: {
            onKeyPress(code, e) {
              _.delay(() => {
                _popupState.commentText = e.target.value.trim();
              });
            },
          },
        },
      ],
    };
  },
  footer(tab) {
    const cols = [];
    let buttonLabel = __('common_save');

    if (tab === 'history') {
      buttonLabel = __('common_ok');
      cols.push({
        view: 'template',
        id: 'timeTrackingTotal',
        css: 'total',
        borderless: true,
        data: {
          value: '0',
        },
        template({ value }) {
          return `<div>${__('tracked_time_total_label')}: ${value}</div>`;
        },
      });
    } else {
      cols.push({});
    }

    cols.push({
      view: 'button',
      id: POPUP_SAVE_BUTTON_ID,
      css: 'button_blue',
      width: 90,
      align: 'right',
      label: buttonLabel,
      on: {
        onItemClick() {
          if (tab === 'history') {
            $$(POPUP_ID).hide();

            return;
          }

          if (tab === 'timer') {
            _handlers.saveTimerLog();

            return;
          }

          _handlers.saveLog();
        },
      },
    });

    return {
      view: 'layout',
      css: 'time_tracking_footer',
      cols,
    };
  },
  historyList() {
    return {
      view: 'list',
      css: 'timeTrackingHistoryList',
      id: 'timeTrackingHistoryList',
      borderless: true,
      width: 260,
      type: {
        height: constantValues.historyItemHeight,
      },
      template({
        id, full_name, resource_name, time, date, comment, resource_id,
      }) {
        if (!date) {
          return '';
        }

        const timeParsed = timeParser.output(time);
        const commentArea = comment
          ? `<span class="cell icon comment tooltip-gantt" data-id="${id}" data-html="true" data-y="-4" data-position="top">
                ${icon_comments}<span class='tooltip-gantt-html'>${comment}</span>
            </span>`
          : '<span class="cell icon comment "></span>';
        const editArea = _helpers.hasLogAccess(resource_id)
          ? `<span class="cell icon edit">${icon_edit}</span><span class="cell icon remove">${icon_delete}</span>`
          : '<span class="cell empty_edit"></span>';

        const result = `<div class="item">
            <span class="cell name">${(full_name && full_name.trim()) || resource_name}</span>
            <span class="cell time">${timeParsed}</span>
            <span class="cell date">${_helpers.getFormattedDate(date)}</span>
            ${commentArea}
            ${editArea}
            </div>`;

        return result;
      },
      onClick: {
        edit(e, id) {
          _.delay(() => { // fix webix bug with removing views
            _popupState.logId = id;
            _handlers.selectTab('editor');
          });
        },
        remove(e, id) {
          const item = this.getItem(id);
          const listView = this;
          const timeTrackingPopup = $$(POPUP_ID);

          timeTrackingPopup.define('stopHide', true);

          webix.confirm({
            text: __('confirm_delete_tracked_time_log'),
            ok: __('common_ok'),
            cancel: __('common_cancel'),
            type: 'confirm-error',
          }).then(res => {
            if (res) {
              // _.delay(() => {
              //   listView.scrollTo(0, 0);
              // }, 50);

              _handlers.removeLog(item, id);
            }

            timeTrackingPopup.define('stopHide', false);
          });
        },
      },
    };
  },
  tabWrapper() {
    return {
      view: 'scrollview',
      scroll: 'y',
      borderless: true,
      body: {
        rows: [
          {
            view: 'layout',
            id: POPUP_TABS_WRAP_ID,
            css: 'time_tracking_tab_wrapper',
            rows: [],
            autoheight: true,
          },
        ],
      },
    };
  },
  tasksList(height, prefix) {
    return {
      view: 'datatable',
      css: 'time_tracking_search_list',
      id: POPUP_SEARCH_LIST_ID + (prefix || ''),
      borderless: true,
      width: 412,
      height: height || 0,
      scrollY: true,
      scrollX: false,
      hover: 'hover_row',
      header: false,
      rowHeight: 90,
      data: [],
      scheme: {
        $change(item) {
          if (item.id === _popupState.taskId && !item.isBox) {
            item.$css = 'selected';
          }
          if (item.disabled) {
            item.$css = 'disabled';
          }
        },
      },
      columns: [
        {
          id: 'item',
          width: 412,
          template({
            id, name, startDate, endDate, status, isBox,
          }) {
            const taskData = globalStore.getters['tasksModel/getTask'](id);

            if (!taskData) {
              return '';
            }

            const resources = globalStore.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](taskData.gantt_id, id);
            const nameTpl = isBox ? customHelper.formatTaskName(name) : _helpers.matchSearchString(name, _popupState.searchText);
            const parentsPath = _helpers.getTaskParentsPath(taskData);
            let dateStr = _helpers.getFormattedDate(startDate);

            let statusTpl;

            if (taskData.type === 'project') statusTpl = '';
            else {
              const projectData = projectsModel.getProjectDataById(taskData.gantt_id);

              statusTpl = projectData && _helpers.getStatusTemplate(status, projectData.is_jira && projectData.id);
            }

            const assigneeTpl = assigneeTemplateHelper(resources);

            if (endDate) {
              dateStr += ` - ${_helpers.getFormattedDate(endDate)}`;
            }

            if (!parentsPath) {
              return;
            }

            return `<div class="search_item${isBox ? ' static' : ''}">
              <div class="name wrap" title="${customHelper.formatTaskName(name)}"><span>${nameTpl}</span></div>
              <div class="parents wrap">${parentsPath}</div>
              <div class="info">
                <div class="date">${dateStr}</div>
                ${statusTpl}
                <div class="assign">${assigneeTpl}</div>
              </div>
              ${isBox ? `<div class="close">${icon_close}</div>` : ''}
            </div>`;
          },
        },
      ],
      item: {
        borderless: true,
      },
      on: {
        onAfterLoad() {
          _handlers.onAfterSearchListLoad(this.serialize(), this);
        },
        onItemClick(obj, e, node) {
          if (node.classList.contains('disabled')) {
            return;
          }

          _.delay(() => {
            if (e.target.closest('.close')) {
              _handlers.selectTask(false);

              return;
            }

            if (!prefix) {
              _popupState.taskId = null;
            }

            _handlers.selectTask(obj.row);
          });
        },
        onMouseMove(obj, e, node) {
          const taskData = globalStore.getters['tasksModel/getTask'](obj.row);

          gantt.callEvent('hideResourcesPreviewListForTask');

          if (e.target.closest('.assign')) {
            gantt.callEvent('showResourcesPreviewListForTask', [e.target.closest('.assign').getBoundingClientRectWrapper(), taskData.id, taskData.gantt_id]);
          }
        },
      },
    };
  },
};

const _handlers = {
  resourcePopupTopPos(topPosition) {
    const popup = $$(POPUP_ID);

    if (topPosition) {
      popup.getNode().classList.add('suggest_popup');
      popup.getNode().classList.add('suggest_popup_top');
    } else {
      popup.getNode().classList.remove('suggest_popup');
      popup.getNode().classList.remove('suggest_popup_top');
    }
  },
  showPopup(pos) {
    const popup = $$(POPUP_ID);
    const isTaskSettingsOpened = routerHelper.isTaskSettingsRoute();
    const isListView = routerHelper.isListViewRoute() || routerHelper.isProjectList() || routerHelper.isMultiviewList();
    let openedFrom = 'grid';

    if (!pos) {
      return;
    }

    let showPopupPosition = { x: pos.x - 9, y: pos.bottom + 1 };

    if (isTaskSettingsOpened) {
      openedFrom = 'task_settings';
    } else if (isListView) {
      openedFrom = 'list';
    }

    if (_popupState.mode === 'global') {
      const leftSideBarWidth = globalStore.getters.leftSidebarWidth;
      const footerHeight = globalStore.getters['footer/height'];

      showPopupPosition = {
        x: leftSideBarWidth,
        y: document.documentElement.clientHeight - footerHeight - popup.config.height - 2,
      };
      _handlers.resourcePopupTopPos(true);
      openedFrom = 'global';
    }

    popup.show(showPopupPosition);

    const docHeight = document.body.clientHeight;
    const bottomHeight = docHeight - pos.bottom;
    const popupHeight = popup.config.height;

    if (_popupState.mode === 'task') {
      if (bottomHeight < popupHeight) {
        popup.config.top = Math.floor(pos.y) - popupHeight - 2;
        _handlers.resourcePopupTopPos(true);
      } else {
        _handlers.resourcePopupTopPos(false);
      }
    } else {
      _handlers.resourcePopupTopPos(false);
    }

    popup.resize();
    userExtAnalytics.log('time_tracking_popup_open', { from: openedFrom.replace(/_/g, ' ') });

    if (openedFrom === 'task_settings') {
      userExtAnalytics.log('task_settings_action', { action: 'time_log', type: 'focus' });
    }
    if (openedFrom === 'grid') {
      userExtAnalytics.log('task_grid_action', { action: 'time_log', type: 'focus' });
    }
  },
  selectTask(taskId) {
    if (_popupState.timerTicker) {
      $$(POPUP_SEARCH_ID).hide();

      return;
    }

    if (taskId === 'projectDeleted') {
      return;
    }

    if (!taskId) {
      _popupState.taskId = null;
    }

    _popupState.forceSearch = !!_popupState.taskId;
    _popupState.taskId = taskId;

    _handlers.initMenuTabs();
    $$(POPUP_SEARCH_ID).hide();

    _popupState.currentTab && _handlers.selectTab(_popupState.currentTab);
  },
  selectTab(tabId) {
    const tabWrap = $$(POPUP_TABS_WRAP_ID);
    const childViews = tabWrap.getChildViews();
    const tabViewConfig = {
      editor: _helpers.getEditTabInnerView(),
      timer: _helpers.getTimerTabInnerView(),
      history: _helpers.getHistoryTabInnerView(),
    };

    if (childViews.length) {
      tabWrap.removeView(childViews[0]);
    }

    tabWrap.addView(tabViewConfig[tabId]);

    _popupState.currentTab = tabId;
    $$(POPUP_TABS_ID).select(tabId);

    if (tabId === 'editor') {
      _handlers.initEditorTab();
    }

    if (tabId === 'timer') {
      _handlers.initTimerTab();
    }

    if (tabId === 'history') {
      _handlers.initHistoryTab();
    }

    _handlers.initMenuTabs();

    if (tabId === 'editor' || tabId === 'timer') {
      globalStore.dispatch('user/updateActivitySettings', {
        activityName: 'timeTrackerTab',
        settings: { tab: tabId },
      });
    }
  },

  onSearchInputPaste(event) {
    setTimeout(() => {
      _handlers.showSearchResults(event.target.value.trim());
    });
  },

  showSearchResults: _.debounce(query => {
    const popup = $$(POPUP_SEARCH_ID);
    const searchField = $$(POPUP_SEARCH_FIELD_ID);

    if (!_popupState.currentTab || !searchField || _popupState.timerTicker) {
      return;
    }

    _helpers.validateSearchField(true);

    _popupState.searchText = query;

    popup.show(searchField.getNode());
    searchField.focus();

    const searchWrap = $$(POPUP_SEARCH_WRAP_ID);
    const childViews = searchWrap.getChildViews();

    if (childViews.length) {
      searchWrap.removeView(childViews[0]);
    }

    searchWrap.addView(_views.tasksList());

    const list = $$(POPUP_SEARCH_LIST_ID);
    const preparedData = _helpers.prepareSearchResults(query);

    list.parse(preparedData);
  }, 200),

  initMenuTabs() {
    if ($$(POPUP_TABS_ID)) {
      $$(POPUP_TABS_ID).render();

      if (_popupState.taskId) {
        $$(POPUP_TABS_ID).enableItem('history');
      }
      if (!_popupState.taskId || _popupState.timerTicker) {
        $$(POPUP_TABS_ID).disableItem('history');
      }
      if (_popupState.timerTicker) {
        $$(POPUP_TABS_ID).disableItem('editor');
      } else {
        $$(POPUP_TABS_ID).enableItem('editor');
      }
      if (_popupState.logId) {
        $$(POPUP_TABS_ID).disableItem('timer');
      } else {
        $$(POPUP_TABS_ID).enableItem('timer');
      }
    } else return;
  },

  initEditorTab() {
    const trackerData = _helpers.getUserTrackerData();

    if (trackerData && _popupState.taskId === trackerData.task_id) {
      _handlers.selectTab('timer');

      return;
    }

    const taskData = _popupState.taskId && globalStore.getters['tasksModel/getTask'](_popupState.taskId);
    const taskLogsData = taskData && timeTrackingModel.getLogsByParams(taskData.gantt_id, taskData.id);
    const datePicker = $$('timeTrackingDate');
    const commentArea = $$(POPUP_COMMENT_AREA_ID);
    let comment = _popupState.commentText;
    let time = 0;
    let date = moment($$('timeTrackingDate').getValue()).toDate();

    if (_popupState.logId) {
      const logData = taskLogsData && _.find(taskLogsData.logs, log => log.id === +_popupState.logId);

      if (logData) {
        time = logData.time;
        date = moment(logData.date).toDate();
        comment = logData.comment;
      }

      _helpers.setTimerValue(time, false);
    }

    if (_popupState.mode === 'global') {
      _handlers.initTaskArea(taskData);
    }

    datePicker.setValue(date);
    commentArea.setValue(comment);
  },
  initTimerTab() {
    const taskData = _popupState.taskId && globalStore.getters['tasksModel/getTask'](_popupState.taskId);
    const commentArea = $$(POPUP_COMMENT_AREA_ID);
    const trackerData = _helpers.getUserTrackerData();
    const saveButton = $$(POPUP_SAVE_BUTTON_ID);
    const time = 0;

    _popupState.logId = null;

    if (trackerData) {
      _helpers.updateTimeTicker(trackerData);
    } else {
      _helpers.updateTimeTicker(false);
    }

    saveButton[_popupState.timerTicker ? 'disable' : 'enable']();

    if (_popupState.mode === 'global') {
      _handlers.initTaskArea(taskData);
    }

    commentArea.setValue(_popupState.commentText);
    commentArea[_popupState.timerTicker ? 'disable' : 'enable']();
  },
  initHistoryTab() {
    const list = $$('timeTrackingHistoryList');
    const totalArea = $$('timeTrackingTotal');
    const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);
    const taskLogsData = timeTrackingModel.getLogsByParams(taskData?.gantt_id, taskData?.id);
    const totalText = timeParser.output((taskLogsData && taskLogsData.sum) || 0);

    totalArea.setValues({ value: totalText }, true);

    _popupState.logId = null;

    if (!taskLogsData) {
      return;
    }

    list.parse(taskLogsData.logs);

    if (_popupState.mode !== 'global') {
      list.define('yCount', constantValues.historyVisibleItems);
      list.resize();
    }
  },
  initTaskArea(taskData) {
    const searchField = $$(POPUP_SEARCH_FIELD_ID);
    const tasksList = $$(POPUP_SELECTED_TASK_ID);
    let isBigTextArea = true;

    if (taskData) {
      const obj = {
        id: taskData.id,
        name: taskData.text,
        startDate: taskData.start_date,
        endDate: taskData.end_date,
        status: taskData.status,
        isBox: true,
        disabled: _popupState.logId || _popupState.timerTicker,
      };

      if (_popupState.forceSearch) {
        searchField.setValue(taskData.text);
        searchField.focus();
        _handlers.showSearchResults(taskData.text);
      } else {
        tasksList.parse([obj]);
        isBigTextArea = false;
      }
    }
  },
  saveLog() {
    if (!_helpers.validateSearchField() || !_helpers.validateTimer() || !_helpers.validateDatePicker()) {
      return;
    }

    const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);
    const timer = $$(POPUP_TIMER_ID);
    const datePicker = $$('timeTrackingDate');
    const commentArea = $$(POPUP_COMMENT_AREA_ID);
    const button = $$(POPUP_SAVE_BUTTON_ID);
    const time = _helpers.getTimerValue();
    const isUserAssignedToTask = globalStore.getters['resourcesModel/isUserAssignedToTask'](taskData.gantt_id, taskData.id);

    button.disable();

    if (!rights.project.hasRight(taskData.gantt_id, 'all_tasks')) {
      if (!isUserAssignedToTask) {
        $$(POPUP_ID).hide();

        return;
      }
    }

    if (_popupState.logId) {
      const oldLogData = timeTrackingModel.getLogsByParams(taskData.gantt_id, taskData.id, _popupState.logId);
      const newData = {
        time,
        date: moment(datePicker.getValue()).startOf('day').toDate(),
        comment: commentArea.getValue().trim(),
        gantt_id: taskData.gantt_id,
      };

      timeTrackingModel.updateLog(_.assign(oldLogData, newData))
        .then(() => {
          _popupState.logId = null;
          _popupState.commentText = '';

          _.delay(() => _handlers.selectTab('history'));
        });

      userExtAnalytics.log('time_tracking_popup_update_log_done', {
        'comment length': newData.comment.length,
        'logged time': newData.time,
      });

      return;
    }

    const newLogData = {
      task_id: taskData.id,
      time,
      date: moment(datePicker.getValue()).startOf('day').toDate(),
      comment: commentArea.getValue().trim(),
      resource_id: globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id,
      gantt_id: taskData.gantt_id,
    };

    timeTrackingModel.createLog(newLogData)
      .then(() => _.delay(() => {
        _popupState.prevTab = _popupState.currentTab;
        _handlers.selectTab('history');
      }))
      .then(() => {
        _popupState.logId = null;
        _popupState.commentText = '';
      });

    userExtAnalytics.log('time_tracking_popup_create_log_done', {
      'comment length': newLogData.comment.length,
      'logged time': newLogData.time,
    });
  },
  saveTimerLog() {
    if (!_helpers.validateSearchField() || !_helpers.validateTimer()) {
      return;
    }

    const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);
    const commentArea = $$(POPUP_COMMENT_AREA_ID);
    const button = $$(POPUP_SAVE_BUTTON_ID);

    button.disable();

    const newLogData = {
      task_id: taskData.id,
      time: _popupState.tickedTime,
      date: moment().startOf('day').toDate(),
      comment: commentArea.getValue().trim(),
      resource_id: globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id,
      gantt_id: taskData.gantt_id,
    };

    timeTrackingModel.createLog(newLogData)
      .then(() => {
        _popupState.tickedTime = null;
        _popupState.commentText = '';

        _.delay(() => _handlers.selectTab('history'));
      });
  },
  removeLog(logData, id) {
    const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);

    logData.gantt_id = taskData.gantt_id;

    globalStore.dispatch('user/updateActivitySettings', {
      activityName: 'timeTrackerTab',
      settings: { tab: 'history' },
    });

    timeTrackingModel.removeLog(logData)
      .then(() => {
        globalStore.dispatch('user/updateActivitySettings', {
          activityName: 'timeTrackerTab',
          settings: { tab: _popupState.prevTab },
        });
        $$('timeTrackingHistoryList').exists(+id) && $$('timeTrackingHistoryList').remove(+id);

        _.delay(() => {
          $$('timeTrackingMenu').refresh();
          _handlers.initHistoryTab();
        }, 50);
      });
    userExtAnalytics.log('time_tracking_popup_remove_log');
  },
  async onTimerButtonClick() {
    if (!_helpers.validateSearchField() || _popupState.blockTimerButton) {
      return;
    }

    _popupState.blockTimerButton = true;

    const trackerData = _helpers.getUserTrackerData();
    let settings = false;

    if (!trackerData) {
      settings = {
        task_id: _popupState.taskId,
        start: moment().subtract(_popupState.tickedTime, 'minutes').format(constants.TASK_DATES_FORMAT),
      };
    }

    userExtAnalytics.log(settings ? 'time_tracking_start_timer' : 'time_tracking_stop_timer');

    await globalStore.dispatch('user/updateActivitySettings', {
      activityName: 'timeTracker',
      settings,
    });
    _handlers.selectTab('timer');
    _handlers.initMenuTabs();
    _popupState.blockTimerButton = false;
    gantt.refreshData();
  },
  onAfterSearchListLoad(data, dataTable) {
    if (!data.length) {
      dataTable.showOverlay(`<div class="empty_data">${__('app_search_banner_noresult')}</div>`);
    } else {
      dataTable.hideOverlay();
    }
  },
  changeTimer(value, range) {
    const timer = $$(POPUP_TIMER_ID);
    const data = timer.getValues();

    if (value >= 0 && value < 10) {
      value = `0${value}`;
    }

    if (range === 'hour') {
      if (value > 23) {
        value = 23;
      }

      data.hours = value;
    }

    if (range === 'minute') {
      if (value > 59) {
        value = 59;
      }

      data.minutes = value;
    }

    timer.setValues(data, true);
  },
};

const _helpers = {
  prepareSearchResults(query) {
    const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);
    let allTasks = _.reduce(globalStore.getters['tasksModel/getData'], (tasks, projectData) => {
      let filteredTasks = _.filter(projectData.tasks,
        t => !projectsModel.isArchived(t.gantt_id) && t.parent && projectsModel.getProjectDataById(t.gantt_id));

      if (!rights.project.hasRight(projectData.id, 'all_tasks')) {
        filteredTasks = filteredTasks.filter(task => {
          const isUserAssignedToTask = globalStore.getters['resourcesModel/isUserAssignedToTask'](projectData.id, task.id);
          const staticFields = rights.project.hasRightSomeOne(projectData.id, ['static_fields_4_only', 'static_fields']);

          return isUserAssignedToTask && staticFields;
        });
      } else {
        filteredTasks = filteredTasks.filter(() => rights.project.hasRightSomeOne(projectData.id, ['static_fields_4_only', 'static_fields']));
      }

      return _.concat(tasks, filteredTasks);
    }, []);

    const statusSortRanks = {
      1: 20,
      2: 30,
      3: 10,
      4: 10,
    };

    allTasks = _.filter(allTasks, task => task.text.toLowerCase().includes(query.toLowerCase()));

    allTasks = _.orderBy(allTasks, task => {
      const rank = _.find(task.resources, res => resourceId === res.resource_id) ? 100 : 0;

      return rank + statusSortRanks[task.status];
    }, ['desc']);

    return _.map(allTasks, task => ({
      id: task.id,
      name: task.text,
      startDate: task.start_date,
      endDate: task.end_date,
      status: task.status,
    }));
  },
  updateTimeTicker(trackerData) {
    const timerButton = $$(POPUP_TIMER_BUTTON_ID);

    const ticker = function (start) {
      const currDiff = moment().diff(start, 'minutes');
      const currDiffSec = moment().diff(start, 'seconds');

      if (currDiff < MAX_TIME) {
        timerButton.$view && timerButton.setValues({ disable: false, time: currDiffSec, active: true }, true);
        _popupState.tickedTime = currDiffSec < 60 ? 1 : currDiff;
      } else {
        timerButton.$view && timerButton.setValues({ disable: false, time: MAX_TIME_SECONDS, active: false }, true);
        _helpers.clearInnerInterval();
        _popupState.tickedTime = MAX_TIME;
      }
    };

    _helpers.initGlobalTicker();

    if (!_popupState.taskId) {
      _popupState.tickedTime = 0;
    }

    if (!trackerData) {
      _helpers.clearInnerInterval();

      timerButton.setValues({ disable: false, time: _popupState.tickedTime * 60, active: false }, true);

      return;
    }

    const startDate = moment(trackerData.start);

    if (_popupState.mode === 'task' && _popupState.taskId !== trackerData.task_id) {
      timerButton.setValues({ disable: true, tooltip: 'time_tracker_run_on_another_task' }, true);

      return;
    }

    ticker(startDate);
    if (_popupState.timerTicker) clearInterval(_popupState.timerTicker);
    _popupState.timerTicker = setInterval(() => {
      ticker(startDate);
    }, 1000);
  },
  initGlobalTicker() {
    const trackerData = _helpers.getUserTrackerData();

    if (trackerData && !user.timeTrackingTicker) {
      const startDate = moment(trackerData.start);
      const __ticker = function (start) {
        const currDiff = moment().diff(start, 'seconds');
        let time = '';

        if (currDiff < MAX_TIME_SECONDS) {
          time = _helpers.parseSecondsToTimeString(currDiff);
        } else {
          time = _helpers.parseSecondsToTimeString(MAX_TIME_SECONDS);
        }

        app.trigger('updateFooterGlobalTimer', time);
      };

      __ticker(startDate);

      user.timeTrackingTicker = setInterval(() => {
        __ticker(startDate);
      }, 1000);
    }

    if (!trackerData && user.timeTrackingTicker) {
      _helpers.clearGlobalInterval();
      app.trigger('updateFooterGlobalTimer', '');
    }
  },
  clearInnerInterval() {
    clearInterval(_popupState.timerTicker);
    _popupState.timerTicker = null;
  },
  clearGlobalInterval() {
    clearInterval(user.timeTrackingTicker);
    user.timeTrackingTicker = null;
  },
  async updateGlobalTrackingTimer({ sourceGanttId, sourceTaskId, eventType } = {}) {
    const trackerData = _helpers.getUserTrackerData();

    if (!trackerData) {
      if (eventType === 'projectDeleted') {
        _handlers.selectTask(eventType);

        return;
      }

      _handlers.selectTask(false);

      return;
    }

    const taskData = globalStore.getters['tasksModel/getTask'](trackerData.task_id);
    const isNeedStopping = !taskData
      || (sourceGanttId && taskData.gantt_id === sourceGanttId)
      || (sourceTaskId && taskData.id === sourceTaskId);

    if (isNeedStopping) {
      await globalStore.dispatch('user/updateActivitySettings', {
        activityName: 'timeTracker',
        settings: false,
      });
      _helpers.clearGlobalInterval();
      app.trigger('updateFooterGlobalTimer', '');
      gantt.refreshData(); // needs for update grid for timer icon
    }
  },
  getUserTrackerData() {
    const userActivity = _.find(user.activity, o => o.name === 'timeTracker');
    let trackerData = null;

    if (userActivity) {
      trackerData = JSON.parse(userActivity.settings);
    }

    return trackerData;
  },
  getUserTrackerTabData() {
    const userActivity = _.find(user.activity, o => o.name === 'timeTrackerTab');
    let tabData = null;

    if (userActivity) {
      tabData = JSON.parse(userActivity.settings);
    }

    return tabData && tabData.tab;
  },
  hasLogAccess(resourceId) {
    const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);

    return globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id === resourceId;
  },
  validateSearchField(force) {
    const field = $$(POPUP_SEARCH_FIELD_ID) && $$(POPUP_SEARCH_FIELD_ID).getNode();

    if (!field) {
      return true;
    }

    field.classList[_popupState.taskId || force ? 'remove' : 'add']('error');

    return _popupState.taskId;
  },
  validateTimer() {
    if (_popupState.currentTab === 'editor') {
      const timerNode = $$(POPUP_TIMER_ID).getNode();
      const time = _helpers.getTimerValue();

      if (time === 0 || time > MAX_TIME) {
        timerNode.classList.add('error');

        return false;
      }
      timerNode.classList.remove('error');

      return true;
    }

    if (_popupState.currentTab === 'timer') {
      const timerButtonNode = $$(POPUP_TIMER_BUTTON_ID).getNode();

      if (!_popupState.tickedTime) {
        timerButtonNode.classList.add('error');

        return false;
      }
      timerButtonNode.classList.remove('error');

      return true;
    }
  },
  validateDatePicker() {
    const datePicker = $$('timeTrackingDate');
    const isValid = !datePicker.config.isInvalid;

    datePicker.config.isInvalid = false;

    return isValid;
  },
  getTimerValue() {
    const data = $$(POPUP_TIMER_ID).getValues();
    let time = 0;

    time += parseInt(data.hours, 10) * 60;
    time += parseInt(data.minutes, 10);

    return time;
  },
  setTimerValue(time, isDisableControls) {
    const timer = $$(POPUP_TIMER_ID);

    let hours = parseInt(time / 60, 10);
    let minutes = time % 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    timer.setValues({ hours, minutes, disable: isDisableControls }, true);
  },
  matchSearchString(srcString, searchText) {
    if (!searchText) {
      return customHelper.formatTaskName(srcString);
    }

    const foundIndex = srcString.toLowerCase().indexOf(searchText.toLowerCase());

    if (foundIndex === -1) {
      return customHelper.formatTaskName(srcString);
    }

    const preMatch = customHelper.formatTaskName(srcString.substring(0, foundIndex));
    const match = customHelper.formatTaskName(srcString.substring(foundIndex, foundIndex + searchText.length));
    const postMatch = customHelper.formatTaskName(srcString.substring(foundIndex + searchText.length, srcString.length));

    return `${preMatch}<span class="search_match">${match}</span>${postMatch}`;
  },
  getTaskParentsPath(taskData) {
    const breadcrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](taskData.id, taskData.gantt_id, {});
    const projectData = projectsModel.getProjectDataById(taskData.gantt_id);
    const breadcrumbsText = breadcrumbsTasks.reduce((sum, elem) => `${sum} / ${elem.text}`, '');
    const result = projectData ? customHelper.formatTaskName((projectData?.name + breadcrumbsText)) : '';

    return result && `<span title="${result}">${result}</span>`;
  },
  getFormattedDate(date) {
    return gantt.date.date_to_str(dateHelper.getDateFormat())(moment(date).toDate());
  },
  getStatusTemplate(status, jiraGanttId) {
    let taskStatus = constants.TASK_STATUSES[status];
    let isLongStatus = false;
    let toolTip = '';

    if (!taskStatus) {
      const columns = globalStore.getters['columns/getJiraColumnByNameAndProjectId']('status', jiraGanttId);

      taskStatus = _.clone(_.find(columns.options, { id: status }) || {});
      taskStatus.locale = taskStatus.value;
      isLongStatus = taskStatus.value && taskStatus.value.length > 11; // 11 - length of "in progress" - the longest standart status
    }
    if (isLongStatus) {
      toolTip = `<div class="tooltip-gantt-html">
      <div class="tooltip-gantt-html-title">${taskStatus.locale}</div>
    </div>`;
    }

    return `<div class="status ${isLongStatus ? 'tooltip-gantt long-status" data-html="true"' : '"'} style="background: ${taskStatus.color}">${toolTip}${__(taskStatus.locale)}</div>`;
  },
  getMainViews() {
    const rows = [];

    if (_popupState.mode === 'global') {
      rows.push(_views.header());
    }

    rows.push(
      _views.tabs(),
      _views.tabWrapper(),
    );

    return {
      view: 'layout',
      borderless: true,
      paddingX: 0,
      rows,
    };
  },
  getEditTabInnerView() {
    const rows = [];
    let textareaHeight;
    const isGlobal = _popupState.mode === 'global';

    if (isGlobal) {
      rows.push({ height: 12 }, _views.search());
      textareaHeight = 164;
    } else {
      textareaHeight = 36;
    }

    rows.push(
      { height: 30 },
      _views.timer(),
      { height: 30 },
      _views.comment(textareaHeight, isGlobal),
      { height: 13 },
      _views.footer('editor'),
      { height: 13 },
    );

    return {
      view: 'layout',
      css: 'time_tracking_editor',
      borderless: true,
      paddingX: 12,
      rows,
    };
  },
  getTimerTabInnerView() {
    const rows = [];
    let textareaHeight;
    const isGlobal = _popupState.mode === 'global';

    if (isGlobal) {
      rows.push({ height: 12 }, _views.search());
      textareaHeight = 224;
    } else {
      textareaHeight = 36;
    }

    rows.push(
      { height: 12 },
      _views.timerButton(),
      { height: 10 },
      _views.comment(textareaHeight, isGlobal),
      { height: 13 },
      _views.footer('timer'),
      { height: 13 },
    );

    return {
      view: 'layout',
      css: 'time_tracking_editor',
      borderless: true,
      paddingX: 12,
      rows,
    };
  },
  getHistoryTabInnerView() {
    return {
      view: 'layout',
      css: 'time_tracking_history',
      borderless: true,
      rows: [
        _views.historyList(),
        {
          view: 'layout',
          borderless: true,
          paddingX: 12,
          css: 'shadow',
          rows: [
            { height: 13 },
            _views.footer('history'),
            { height: 13 },
          ],
        },
      ],
    };
  },
  parseSecondsToTimeString(time) {
    let minutes = parseInt(time / 60, 10);
    let hours = parseInt(minutes / 60, 10);
    let seconds = time % 60;

    minutes %= 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  },
  definePopupHeight(popup, pos) {
    const topBottomPadding = 10;
    const constantHeight = 110;
    const listItemHeight = constantValues.historyItemHeight;
    const docHeight = document.body.clientHeight;
    const minElems = constantValues.minHistoryVisibleItems;

    const topHeight = pos.y;
    const bottomHeight = docHeight - pos.bottom;

    const topFinalHeight = topHeight - constantHeight - topBottomPadding;
    const bottomFinalHeight = bottomHeight - constantHeight - topBottomPadding;

    const topElems = Math.min(constantValues.maxHistoryVisibleItems, Math.floor(topFinalHeight / listItemHeight));
    const bottomElems = Math.min(constantValues.maxHistoryVisibleItems, Math.floor(bottomFinalHeight / listItemHeight));
    const resultElements = Math.max(topElems, bottomElems);

    constantValues.historyVisibleItems = resultElements > minElems ? resultElements : minElems;
    popup.config.height = constantHeight + constantValues.historyVisibleItems * listItemHeight;
  },
};

const init = function (mode, pos, taskId) {
  const popup = $$(POPUP_ID);
  const popupWrap = $$(POPUP_WRAP_ID);
  const childViews = popupWrap.getChildViews();
  const trackerData = _helpers.getUserTrackerData();

  if (trackerData && mode === 'global') {
    taskId = trackerData.task_id;
  }

  _popupState.mode = mode;
  _popupState.taskId = taskId;

  popup.config.width = POPUP_WIDTH[mode];
  popup.config.task_id = taskId;

  if (mode !== 'global') {
    _helpers.definePopupHeight(popup, pos);
  } else {
    popup.config.height = POPUP_HEIGHT[mode];
  }

  popup.resize();

  _handlers.showPopup(pos);

  if (childViews.length) {
    popupWrap.removeView(childViews[0]);
  }

  popupWrap.addView(_helpers.getMainViews());

  _handlers.selectTab(_helpers.getUserTrackerTabData() || 'editor');
};

webix.ui({
  view: 'popupWithoutPointCustom',
  id: POPUP_ID,
  css: 'time_tracking_popup',
  padding: 0,
  width: 460,
  height: 410,
  hidden: true,
  body: {
    rows: [
      {
        view: 'layout',
        id: POPUP_WRAP_ID,
        rows: [],
      },
    ],
  },
  on: {
    onHide() {
      _helpers.clearInnerInterval();
      _popupState.callbackAfterHidePopup && _popupState.callbackAfterHidePopup();
      _popupState = {};
    },
    onShow() {
      // check position
      if ($$(POPUP_ID).getNode().getBoundingClientRect().y >= 460 && $$(POPUP_ID).getNode().clientHeight === 0 && _popupState.mode === 'task') {
        $$(POPUP_ID).getNode().style.marginTop = `${-$$(POPUP_ID).config.height - 7}px`;
      } else {
        $$(POPUP_ID).getNode().style.marginTop = '0';
      }
    },
  },
});

webix.ui({
  view: 'popupWithoutPointCustom',
  id: POPUP_SEARCH_ID,
  css: 'time_tracking_search_popup',
  padding: 0,
  width: 412,
  height: 348,
  hidden: true,
  borderless: true,
  body: {
    rows: [
      {
        view: 'layout',
        id: POPUP_SEARCH_WRAP_ID,
        borderless: true,
        rows: [],
      },
    ],
  },
  on: {
    onHide() {
      if (_popupState.currentTab && !_popupState.timerTicker) {
        _handlers.selectTab(_popupState.currentTab);
      }
    },
    onShow() {

    },
  },
});

gantt.attachEvent('showTimeTrackingPopup', (mode, pos, taskId, callback) => {
  // if ($$(POPUP_ID).isVisible()) {
  //   $$(POPUP_ID).hide();
  //   return;
  // }

  init(mode, pos, taskId);

  _popupState.callbackAfterHidePopup = callback;
});

gantt.attachEvent('hideTimeTrackingPopup', () => {
  $$(POPUP_ID).hide();
});

gantt.attachEvent('onAfterTaskDelete', (id, taskData) => {
  _helpers.updateGlobalTrackingTimer({ sourceTaskId: id });
});

app.on('project:archive', (ganttId, isArchived) => {
  if (_popupState.mode !== 'global' && ganttId === gantt.config.gantt_id) {
    $$(POPUP_ID).hide();
  }
  if (isArchived) {
    _helpers.updateGlobalTrackingTimer({ sourceGanttId: ganttId });
  }
});

app.on('project:component:remove', ganttId => {
  _helpers.updateGlobalTrackingTimer({ sourceGanttId: ganttId, eventType: 'projectDeleted' });
});

app.on('collaboration:updatedModels', () => {
  _helpers.updateGlobalTrackingTimer();
});

app.on('timeTrackingModel:change', taskId => {
  // if (_popupState.taskId !== taskId) {
  //   return;
  // }

  const taskData = globalStore.getters['tasksModel/getTask'](_popupState.taskId);
  const isTaskSettingsOpened = routerHelper.isTaskSettingsRoute();
  const isListView = routerHelper.isListViewRoute() || routerHelper.isProjectList() || routerHelper.isMultiviewList();

  if (taskData) {
    gantt.silentUpdateTask(_popupState.taskId, taskData);
  }

  if (routerHelper.isGanttRoute()) {
    app.trigger('refreshKeyboardCursor');
  }

  if (isTaskSettingsOpened) {
    app.trigger('updateTaskViewTimeTracking');
  }

  if (isListView) {
    app.trigger('listView:task:updateTimeTracking', taskId);
  }

  if (_popupState.mode === 'task') {
    let openedFrom = 'grid';

    if (isTaskSettingsOpened) {
      openedFrom = 'task_settings';
    } else if (isListView) {
      openedFrom = 'list';
    }

    if (openedFrom === 'task_settings') {
      userExtAnalytics.log('task_settings_action', { action: 'time_log', type: 'change' });
    }
    if (openedFrom === 'grid') {
      userExtAnalytics.log('task_grid_action', { action: 'time_log', type: 'change' });
    }
  }

  gantt.refreshData();
});

app.on('gantt:init:after', () => {
  _helpers.initGlobalTicker();
  _helpers.updateGlobalTrackingTimer();
});

app.on('app:route:changed', () => {
  if ($$(POPUP_ID).isVisible()) {
    $$(POPUP_ID).hide();
  }
});
app.on('onAfterCollaboration', e => {
  if ((e.event === 'ResourceOnProjectsUpdated' || e.event === 'RoleAccountUpdated' || e.event === 'RoleProjectUpdated') && $$(POPUP_ID).isVisible()) {
    _helpers.updateGlobalTrackingTimer();
  }

  if (e.event === 'TaskDeleted') {
    _helpers.updateGlobalTrackingTimer();
  }

  if (e.event === 'TaskResourceUnassigned' && $$(POPUP_ID).isVisible()) {
    const activeGanttId = projectsModel.getActiveGanttId();
    const allTasksAccess = rights.project.hasRight(activeGanttId, 'all_tasks');
    const currentResourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);
    const isProject = e.projects.includes(+activeGanttId);
    const isTask = e.tasks.includes(_popupState.taskId);
    const isResource = e.unassignedResources.find(obj => obj.unassignedResourcesIds.includes(currentResourceId));

    if (!allTasksAccess && isProject && isTask && isResource) {
      _helpers.updateGlobalTrackingTimer();
    }
  }
});

const outputObject = {
  init,
};

export default outputObject;
