<template>
  <div
    v-click-outside="{
      handler: closeDropdown,
      closeConditional: () => isVisibleDropdown
    }"
    :class="[
      $style.wrapper,
      'vgp-interactive-element',
    ]"
  >
    <div
      ref="selectedItem"
      :class="[
        $style.selectedItem,
        isVisibleDropdown ? $style.active : '',
        disabled ? $style.disabled : '',
      ]"
      @click="toggleIsVisibleDropdown"
    >
      <svg-sprite
        v-if="iconType === 'svg'"
        name="status-2"
        :size="24"
        :style="{color: selectedItem.color}"
      />
      <img
        v-if="iconType === 'link'"
        :src="selectedItem.icon"
        :class="$style['image-icon']"
      >
      <span :class="$style.label">
        {{ selectedItem.value }}
      </span>
    </div>
    <ul
      ref="dropdown"
      v-show="isVisibleDropdown"
      :class="$style.dropdown"
    >
      <li
        v-for="item in items"
        :key="item.id"
        :class="[$style.dropdown__item, selectedItem.id === item.id ? $style.dropdown__item_selected : '']"
        @click="handleItemClick(item.id)"
      >
        <svg-sprite
          v-if="iconType === 'svg'"
          name="status-2"
          :style="{color: item.color}"
        />
        <img
          v-if="iconType === 'link'"
          :src="item.icon"
          :class="$style['image-icon']"
        >
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { throttle } from 'lodash';

export default {
  name: 'ButtonDropdownSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    iconType: {
      type: String,
      validator(value) {
        return ['svg', 'link'].includes(value);
      },
      default: 'svg',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    change: Object,
  },
  data() {
    return {
      isVisibleDropdown: false,
      scrollHandler: null,
    };
  },
  computed: {
    selectedItem() {
      return this.items.find(item => Number(item.id) === Number(this.value));
    },
  },
  watch: {
    disabled(value) {
      if (value) {
        this.closeDropdown();
      }
    },
  },
  mounted() {
    this.scrollHandler = throttle(this.closeDropdown, 100);
    const scrollDivs = document.querySelectorAll('div.gantt_scroll');

    scrollDivs.forEach(div => div.addEventListener('scroll', this.scrollHandler));
  },
  beforeDestroy() {
    const scrollDivs = document.querySelectorAll('div.gantt_scroll');

    scrollDivs.forEach(div => div.removeEventListener('scroll', this.scrollHandler));
  },
  methods: {
    closeDropdown() {
      this.isVisibleDropdown = false;
    },
    toggleIsVisibleDropdown() {
      if (this.disabled) return;

      if (!this.isVisibleDropdown) {
        this.setDropdownPosition();
      }

      this.isVisibleDropdown = !this.isVisibleDropdown;
    },
    handleItemClick(value) {
      if (value === this.selectedItem.id) {
        return;
      }
      this.$emit('change', value);
      this.toggleIsVisibleDropdown();
    },
    setDropdownPosition() {
      const selectedItemRect = this.$refs.selectedItem.getBoundingClientRect();
      this.$refs.dropdown.style.top = `${selectedItemRect.top + selectedItemRect.height + 10}px`;
      this.$refs.dropdown.style.left = `${selectedItemRect.left}px`;
    }
  },
};
</script>

<style module lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.selectedItem {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 2px;
  padding: 0 8px 0 4px;
  background: #F2F2F2;
  border-radius: 4px;
  border: 2px solid transparent;

  &:hover {
    background: #E5E5E5;
  }

  &.active {
    border: 2px solid #4484CD;
  }

  &.disabled {
    pointer-events: none;
  }

}

.label {
  font-family: Lato-Bold, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #808080;
}

.dropdown {
  width: 180px;
  position: fixed;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0 !important;

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 12px;
    background: #ffffff;

    span {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #191919;
      margin-left: 4px;
    }

    &:hover:not(&_selected) {
      background: #F2F2F2;
      transition: background .3s;
    }

    &_selected {
      background: #4484CD;
      span {
        color: #FFFFFF;
      }
    }

  }
}

.image-icon {
  width: 24px;
  height: 24px;
}
</style>
