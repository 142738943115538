export default {

  getCommentsActions(state) {
    return Object.values(state.commentsActions).flat();
  },

  getAllCommentsFull(state) {
    return Object.values(state.projectCommentsMap).flat();
  },

  getCommentById: (state, getters) => commentId => getters.getAllCommentsFull.find(comment => comment.id === commentId),

  getCommentsByProjectAndTaskId: state => (projectId, taskId) => {
    if (state.projectCommentsMap[projectId]) {
      return state.projectCommentsMap[projectId].filter(comment => comment.taskId === taskId);
    }

    return [];
  },

  getCommentsByGanttIds: state => ganttIds => {
    const comments = { };

    ganttIds.forEach(ganttId => {
      if (state.projectCommentsMap[ganttId]) {
        comments[ganttId] = state.projectCommentsMap[ganttId];
      }
    });

    return comments;
  },

  isCommentsAlreadyFetchedForProject: state => projectId => !!state.projectCommentsMap[projectId],
};
