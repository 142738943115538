<template>
  <div :class="[$style.field_wrap]">
    <div :class="[$style.input_label]">
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="isHasSelected"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        @click="resetSelected"
      >
        {{ locale.reset }}
      </div>
    </div>
    <div :key="'from_to_fields_' + redraw">
      <template v-if="type === 'number' || type === 'text' ">
        <textInput
          :placeholder="locale.from"
          :gp-autotest-name="gpAutotestName + '_start'"
          :value="selected.start"
          :type="type"
          @input="onInput( 'start', $event )"
        />
        <textInput
          :placeholder="locale.to"
          :gp-autotest-name="gpAutotestName + '_end'"
          :value="selected.end"
          :type="type"
          @input="onInput( 'end', $event )"
        />
      </template>

      <div v-if="type === 'date'">
        <dateInput
          :gp-autotest-name="gpAutotestName + '_start'"
          :placeholder="locale.from"
          :value="selected.start"
          @change="onChange('start', $event)"
        />
        <dateInput
          :gp-autotest-name="gpAutotestName + '_end'"
          :placeholder="locale.to"
          :value="selected.end"
          @change="onChange('end',$event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import textInput from './textInput.vue';
import dateInput from './dateInput/dateInput.vue';

export default {
  name: 'FromToDateInput',
  components: { textInput, dateInput },
  props: {
    type: {
      type: String,
      required: false,
      default: 'number',
      validator(value) {
        return (
          ['number', 'date'].indexOf(value) !== -1
        );
      },
    },
    value: { type: Object, required: false, default: null },
    label: { type: String, required: false, default: 'Label' },
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
  },

  data() {
    return {
      locale: {
        reset: __('common_reset'),
        from: __('filter_number_type_custom_column_from'),
        to: __('filter_number_type_custom_column_to'),
      },
      selected: { start: '', end: '' },
      input: { start: '', end: '' },
      redraw: 0,
    };
  },

  computed: {
    isHasSelected() {
      return this.input.start || this.input.end || this.selected.start || this.selected.end;
    },
  },

  watch: {
    value(val) {
      this.updateCurrentValues();
    },
  },

  mounted() {
    this.updateCurrentValues();
  },

  beforeDestroy() {
  },

  methods: {
    updateCurrentValues() {
      if (!this.value) {
        this.selected = { start: '', end: '' };
        this.input = { start: '', end: '' };

        return;
      }
      this.selected.start = 'start' in this.value ? this.value.start : '';
      this.selected.end = 'end' in this.value ? this.value.end : '';
      this.input.start = this.selected.start;
      this.input.end = this.selected.end;
    },

    onChange(property, val) {
      if (this.selected[property] === val) return;

      this.selected[property] = val;

      const isEmpty = !this.selected.start && !this.selected.end;

      if (isEmpty) this.$emit('reset', { ...this.selected });
      else this.$emit('change', { ...this.selected });
    },

    onInput(property, val) {
      this.onChange(property, val);
    },

    resetSelected() {
      this.selected = {
        start: '',
        end: '',
      };
      this.input = {
        start: '',
        end: '',
      };
      this.redraw++;
      this.$emit('reset');
    },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
