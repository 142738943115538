import globalStore from '$$store/main';
import app from '../../../app';

function onResourceCalendarUpdated(event) {
  event.data.updated.forEach(el => {
    if (el && el.worktime && el.worktime.days !== undefined && el.worktime.hours !== undefined) {
      const _workingDays = [];

      el.worktime.days.forEach(el2 => {
        if (el2.name === 'Monday' && el2.isWorkingDay) {
          _workingDays.push(1);
        }
        if (el2.name === 'Tuesday' && el2.isWorkingDay) {
          _workingDays.push(2);
        }
        if (el2.name === 'Wednesday' && el2.isWorkingDay) {
          _workingDays.push(3);
        }
        if (el2.name === 'Thursday' && el2.isWorkingDay) {
          _workingDays.push(4);
        }
        if (el2.name === 'Friday' && el2.isWorkingDay) {
          _workingDays.push(5);
        }
        if (el2.name === 'Saturday' && el2.isWorkingDay) {
          _workingDays.push(6);
        }
        if (el2.name === 'Sunday' && el2.isWorkingDay) {
          _workingDays.push(7);
        }
      });

      globalStore.commit('resourcesModel/updateResource', {
        resourceId: el.resourceId,
        updated: {
          workingDays: _workingDays,
          workingHours: el.worktime.hours,
        },
      });
      app.trigger('resources:model:changeWorkTime');
    }

    if (el && el.customDays && el.customDays.length) {
      const customDayIdsToRemove = [];

      el.customDays.forEach(customDay => {
        const action = customDay.action;

        if (action === 'add') {
          globalStore.commit('resourcesModel/addWorkCustomDays', {
            resourceId: el.resourceId,
            customDays: customDay.data,
          });
        }

        if (action === 'delete') {
          customDayIdsToRemove.push(customDay.ids[0]);
        }

        if (action === 'update') {
          globalStore.commit('resourcesModel/updateWorkCustomDay', {
            resourceId: el.resourceId,
            customDay: customDay.data[0],
          });
        }
      });

      customDayIdsToRemove.length && globalStore.commit('resourcesModel/removeWorkCustomDays', {
        resourceId: el.resourceId,
        customDayIds: customDayIdsToRemove,
      });

      app.trigger('resources:model:changeWorkCustomDays', el.resourceId);
    }
  });
}

export default onResourceCalendarUpdated;
