var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.field_wrap] }, [
    _c("div", { class: [_vm.$style.input_label] }, [
      _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isHasSelected,
              expression: "isHasSelected",
            },
          ],
          class: _vm.$style.reset,
          attrs: { id: _vm.gpAutotestName + "_reset_btn" },
          on: { click: _vm.resetSelected },
        },
        [_vm._v("\n      " + _vm._s(_vm.locale.reset) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { key: "from_to_fields_" + _vm.redraw },
      [
        _vm.type === "number" || _vm.type === "text"
          ? [
              _c("textInput", {
                attrs: {
                  placeholder: _vm.locale.from,
                  "gp-autotest-name": _vm.gpAutotestName + "_start",
                  value: _vm.selected.start,
                  type: _vm.type,
                },
                on: {
                  input: function ($event) {
                    return _vm.onInput("start", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("textInput", {
                attrs: {
                  placeholder: _vm.locale.to,
                  "gp-autotest-name": _vm.gpAutotestName + "_end",
                  value: _vm.selected.end,
                  type: _vm.type,
                },
                on: {
                  input: function ($event) {
                    return _vm.onInput("end", $event)
                  },
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "date"
          ? _c(
              "div",
              [
                _c("dateInput", {
                  attrs: {
                    "gp-autotest-name": _vm.gpAutotestName + "_start",
                    placeholder: _vm.locale.from,
                    value: _vm.selected.start,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChange("start", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("dateInput", {
                  attrs: {
                    "gp-autotest-name": _vm.gpAutotestName + "_end",
                    placeholder: _vm.locale.to,
                    value: _vm.selected.end,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChange("end", $event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }