var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll:#virtual-scroll-table",
          value: _vm.scrollAndResizeHandler,
          expression: "scrollAndResizeHandler",
          arg: "#virtual-scroll-table",
        },
        {
          name: "scroll",
          rawName: "v-scroll:#horizontal-scroll-table",
          value: _vm.scrollAndResizeHandler,
          expression: "scrollAndResizeHandler",
          arg: "#horizontal-scroll-table",
        },
      ],
      staticClass: "drop-cell no-select",
      class: { highlight: _vm.isOpenPopup && _vm.highlight },
    },
    [
      _c(
        "div",
        { staticClass: "trigger-wrap" },
        [_vm._t("trigger", null, { isOpen: _vm.isOpen })],
        2
      ),
      _vm._v(" "),
      _vm.isOpenPopup
        ? _c(
            "div",
            {
              ref: "drop",
              staticClass: "drop-cell--drop js-drop-cell-popup",
              style: {
                width: _vm.dropWidth + "px",
                top: _vm.dropCoords.top,
                bottom: _vm.dropCoords.bottom,
                right: _vm.dropCoords.right,
                left: _vm.dropCoords.left,
              },
            },
            [_vm._t("drop")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }