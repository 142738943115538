<template>
  <div
    :id="'gp_autotest_listview_create_new_item'"
    v-click-outside="{
      handler: outsideClick,
      include: includeOutside,
    }"
    class="wrap"
    :class="[ (createMode && !isDisabled ) ? 'creation' : 'base', [skins] ]"
  >
    <div
      v-if="createMode && !selectMode"
      key="input-creation"
      ref="inputWrap"
      class="input-container sticky-left"
    >
      <input
        :id="'gp_autotest_listview_create_new_item_input_name'"
        ref="nameInput"
        v-model="inputName"
        @keyup="onKeyup"
        @keydown.tab.prevent="onPreventTabClick"
      >
      <sprite
        :id="'gp_autotest_listview_create_new_item_input_btn_save'"
        name="enter-2"
        class="enter-arrow-icon"
        @click="saveItem"
      />
    </div>

    <div
      v-else
      class="sticky-left d-flex align-center create-actions"
    >
      <div
        v-show="selectMode"
        class="select-block"
        :style="{ width: '40px' }"
      />

      <div
        class="d-flex align-center"
        :class="{ 'disable-creation': isDisabled}"
        @click="addTask"
      >
        <sprite
          name="plus-2"
          :size="20"
          class="circled-plus-icon"
        />
        <span
          :id="'gp_autotest_listview_create_new_item_btn_add_task'"
          @click="addTask"
        > {{ locale.addTask }} </span>
        <span class="delimiter" />
        <span
          :id="'gp_autotest_listview_create_new_item_btn_add_milestone'"
          @click="addMilestone"
        > {{ locale.addMilestone }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import sprite from './icons/sprite.vue';

export default {
  name: 'CreationItem',
  components: { sprite },
  props: {
    disable: { type: Boolean, required: false, default: false },
    isCreateMode: { type: Boolean, required: false, default: false },
    selectMode: { type: Boolean, required: false, default: false },
    skins: { type: String, required: false, default: 'skins-all' },
  },

  data() {
    return {
      createMode: false,
      createType: '',
      inputName: '',
      locale: {
        addTask: __('gantt_add_task'),
        addMilestone: __('gantt_add_milestone'),
      },
    };
  },

  computed: {
    isDisabled() {
      return this.disable || this.selectMode;
    },
  },

  watch: {
    createMode(val) {
      this.$emit('update:isCreateMode', val);
    },
  },

  mounted() {
    this.addListheners();
  },

  beforeDestroy() {
    this.removeListheners();
  },

  methods: {
    addTask() {
      this.editModeStart('task');
    },

    addMilestone() {
      this.editModeStart('milestone');
    },

    onPreventTabClick(e) {

    },

    onKeyup(e) {
      if (e.keyCode === 13) { // enter
        this.saveItem();

        return;
      }

      if (e.keyCode === 27) { // Escape
        this.editModeEnd();
      }
    },

    editModeStart(type) {
      if (this.isDisabled) return;

      const component = this;

      this.createMode = true;
      if (this.createType === '') {
        this.createType = type;
      }

      const cells = ['text', 'comments', 'attachments'];
      let width = 0;

      cells.forEach(cell_name => {
        const cell = document.querySelector(`.table-header-cell[date-name=${cell_name}]`);

        cell && (width += cell.offsetWidth);
      });

      this.$nextTick(() => {
        this.$emit('beforeCreateItem');
        this.$refs.inputWrap.style.width = `${width - 20}px`;
      });

      setTimeout(() => {
        component.$refs.nameInput.focus();
      }, 100);
    },

    editModeEnd() {
      this.createMode = false;
      this.inputName = '';
    },

    saveItem() {
      this.$emit('create', {
        name: this.inputName.trim(),
        type: this.createType,
      });
      this.inputName = '';
      this.createType = '';
    },

    outsideClick(e) {
      this.editModeEnd();
    },

    includeOutside() {
      const els = document.querySelectorAll('.js_add_task_entity');

      return Array.from(els);
    },

    addListheners() {
      const addTasksBtn = document.querySelectorAll('.js_add_task');
      const addMilestonwBtn = document.querySelectorAll('.js_add_milestone');

      for (const elem of addTasksBtn) {
        elem.addEventListener('click', this.addTask);
      }

      for (const elem of addMilestonwBtn) {
        elem.addEventListener('click', this.addMilestone);
      }
    },

    removeListheners() {
      const addTasksBtn = document.querySelectorAll('.js_add_task');
      const addMilestonwBtn = document.querySelectorAll('.js_add_milestone');

      for (const elem of addTasksBtn) {
        elem.removeEventListener('click', this.addTask);
      }

      for (const elem of addMilestonwBtn) {
        elem.removeEventListener('click', this.addMilestone);
      }
    },

  },
};
</script>

<style scoped lang='less'>
.wrap{
  height: 31px;
  display: flex;
  align-items: center;

  & > div{
    left: 0px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &.base{
    border-bottom: 1px solid #E0E0E0;
    color: #1565C0;
    span:hover{
      text-decoration: underline;
    }
  }

  &.creation{
    background-color: #E3F2FD;
    border-bottom: #E3F2FD;
  }

  .delimiter{
    margin: 0 8px;
    width: 1px;
    margin-right: 6px;
    height: 14px;
    border-left: 1px solid #247ECC;
  }

  .input-container {
    height: 21px;
    border: 1px solid #247ECC;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-left: 12px;
    border-radius: 2px;

    input {
      width: 100%;
      height: 100%;
      line-height: 16px;
      padding: 2px 20px 2px 0px;
    }
  }

  .enter-arrow-icon {
    cursor: pointer;
    color: #b2b2b2;
    &:hover{
      color: #808080;
    }
  }

  .d-inline-flex{
    display: inline-flex;
  }

  .circled-plus-icon{
    cursor: pointer;
    margin-right: 8px;
    margin-left: 12px;
    transform: translateY(-2px);
  }
  .create-actions{
    cursor: pointer;
    height: 100%;
  }

}

.skins-all .select-block,
.skins-vert .select-block{
  border-right: 1px solid #E0E0E0;
  height: 100%;
}

.disable-creation{
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

</style>
