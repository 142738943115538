<template>
  <div :class="[$.settings_header]">
    <div
      :class="$.title"
      :style="tabsItems ? {paddingBottom: '13px'} : ''"
    >
      <sprite :name="icon" />
      <div :class="$.title_text">
        {{ title }}
      </div>
    </div>
    <vgp-tabs
      v-if="tabsItems"
      :tabs="tabsItems"
      :active="active"
      :active-by="'type'"
      @onToggle="(tab) => $emit('toggle', tab)"
    />
  </div>
</template>

<script>
import sprite from '../VueIcon/svgSprite.vue';

export default {
  components: { sprite },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    tabs: {
      type: Array,
      required: false,
    },
    activeTab: {
      type: String,
      required: false,
    },
    emits: {
      toggle: Object,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    tabsItems() {
      if (this.tabs && this.tabs.length) {
        return this.tabs;
      }

      return null;
    },
    active() {
      if (!this.tabsItems) return {};

      return this.tabsItems.find(t => t.type === this.$route.params.activeView) || this.tabsItems[0];
    },
  },
};
</script>

<style module='$' src='./less/settingsHeader.less'></style>
