import { render, staticRenderFns } from "./roleItem.vue?vue&type=template&id=e55d35e4&"
import script from "./roleItem.vue?vue&type=script&lang=js&"
export * from "./roleItem.vue?vue&type=script&lang=js&"
import style0 from "../../less/role.less?vue&type=style&index=0&module=true&lang=css&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["../../less/role.less?vue&type=style&index=0&module=true&lang=css&"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("../../less/role.less?vue&type=style&index=0&module=true&lang=css&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/home/ganttpro/app/node_modules/vue-hot-reload-api/dist/index.js").rerender("e55d35e4")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ganttpro/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e55d35e4')) {
      api.createRecord('e55d35e4', component.options)
    } else {
      api.reload('e55d35e4', component.options)
    }
    module.hot.accept("./roleItem.vue?vue&type=template&id=e55d35e4&", function () {
      api.rerender('e55d35e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/views/profileSettings/include/roles/roleItem.vue"
export default component.exports