<template>
  <div
    :class="[
      $.chart,
      $.chart_by_status,
    ]"
  >
    <div :class="$.header">
      <div :class="$.title">
        {{ locales(`chart_${chart.key}_title`) }}
      </div>
      <vgp-switch
        v-if="statusSwitch"
        :is-toggle-on="chart.status"
        @onChange="$emit('onToggleStatus')"
      />
    </div>
    <div
      v-if="description"
      :class="$.description"
    >
      {{ locales(`chart_${chart.key}_description`) }}
    </div>
    <div :class="$.wrapper">
      <doughnut-chart
        :class="$.body"
        :id="chart.key"
        :items="items"
        :tooltipTitle="tooltipTitle"
        :static="static"
      />
      <div
        :class="[
          $.footer,
          isShowStub ? $.invisible : '',
        ]"
      >
        <div
          :class="$.item"
          v-for="item in summary"
        >
          <div :class="$.label">
            {{ item.text }}
          </div>
          <div :class="$.value">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div
        v-if="isShowStub"
        :class="$.stub"
      >
        <div :class="$.stub_item" />
        <div :class="$.stub_item" />
        <div :class="$.stub_text">
          {{ locales(`chart_${chart.key}_stub_text`) }}
        </div>
      </div>
      <loader :show="isShowLoader" />
    </div>
  </div>
</template>

<script>
import Loader from './Loader.vue';
import VgpSwitch from '../../common/VueComponents/checkbox/squareToggle.vue';
import DoughnutChart from '../../common/VueComponents/charts/DoughnutChart.vue';

export default {
  components: {
    Loader,
    VgpSwitch,
    DoughnutChart,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    statusSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      id: 'statuses',
      timeout: null,
      isShowLoader: false,
      tooltipTitle: __(`chart_${this.chart.key}_tooltip_title`),
    };
  },
  computed: {
    isShowStub() {
      return !this.static && !this.data.items.length;
    },
    items() {
      return this.data.items.filter(item => !!item.value);
    },
    summary() {
      return Object.values(this.data.summary);
    }
  },
  watch: {
    data() {
      clearTimeout(this.timeout);
      this.isShowLoader = true;

      this.timeout = setTimeout(() => {
        this.isShowLoader = false;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style module="$" src="../less/chart.less"></style>
