<template>
  <div
    v-if="currentFilter"
    :id="componentId"
    ref="filter"
    :class="[
      $style.filter_wrap, isOpenFilter ? $style.show : '',
      currentFilter.exportMode ? $style.export : ''
    ]"
    :style="{...style, right: right}"
  >
    <saved-filters-popup
      :key="redrawSevedFiltersPopup"
      :is-show="isShowSavedFilters && isOpenFilter"
      :list="currentFilter.getSavedFilters"
      :active-item-id="currentFilter.id"
      @close="isShowSavedFilters = false"
      @delete="deleteSavedFilter"
      @edit="editSavedFilter"
      @select="selectSavedFilter"
    />
    <div :class="[$style.filter]">
      <div
        v-if="showOverlay"
        :class="[$style.filter_overlay]"
      />
      <filter-header
        :show-current="currentFilter.showSavedFilters"
        :show-cleare-btn="isActiveFilter"
        :show-helper="currentFilter.showHelpIcon"
        :name="filterName"
        @toggleSavedPopup="isShowSavedFilters = !isShowSavedFilters"
        @reset="resetFilterState"
        @close="closeFilter"
      />
      <filter-body
        :key="'filter-'+ currentFilter.type + currentFilter.id + '-' + filterKey"
        :fields="currentFilter.config()"
        :values="currentFilter.filterState"
        :custom="currentFilter.custom.columns"
        @change="changeFilterState"
      />
      <filter-footer
        v-if="currentFilter.showSaveBtn"
        :save-handler="saveFilter"
      />
    </div>
  </div>
</template>

<script>
import filterHeader from './includes/filterHeader.vue';
import filterFooter from './includes/filterFooter.vue';
import savedFiltersPopup from './includes/savedFiltersPopup.vue';
import filterBody from './includes/filterBody.vue';
import logInternalHelper from '../../helpers/logInternal';
import projectsModel from '../../models/projects';
import rights from '../../components/rights';
import constants from '../../helpers/constants';

import _ from '../../libs/lodash';
import app from '../../app';
import routerHelper from '../../helpers/router';

export default {
  name: 'FilterVue',
  components: {
    filterHeader,
    filterFooter,
    savedFiltersPopup,
    filterBody,
  },

  provide() {
    const filterProvide = {};

    Object.defineProperty(filterProvide, 'disableInput', {
      get: () => !this.isOpenFilter,
    });

    return { filterProvide };
  },

  data() {
    return {
      isShowSavedFilters: false,
      activeSavedFilterId: '',
      temptFilterState: {},
      filterTaskNoFound: false,
      prevFilter: '',
      showOverlay: false,
      filterKey: 0,
      ganttFilterRefresh: 0,
      redrawSevedFiltersPopup: 0,
    };
  },

  computed: {
    componentId() {
      const id = 'app-filter';

      return this.currentFilter.exportMode ? `${id}-export` : id;
    },
    filterName() {
      return this.currentFilter.name;
    },
    getRouteName() {
      const path = this.$route.path;
      const crumbs = path.substr(1).split('/');
      const txt_crumbs = crumbs.filter(item => !Number(item));
      const res = txt_crumbs.join('_');

      return res;
    },
    isOpenFilter() {
      return this.$store.getters['filter/isOpen'];
    },
    coords() {
      return this.$store.getters.contentParams;
    },
    right() {
      if (!this.currentFilter) {
        return '-300px';
      }
      const showFilterLeft = this.currentFilter.exportMode ? '300px' : '0px';

      return this.isOpenFilter ? showFilterLeft : '-333px';
    },
    style() {
      if (this.currentFilter.exportMode) {
        return {
          top: '0px',
          bottom: '0px',
          zIndex: 8,
          height: `${this.coords.height}px`,
        };
      }

      return {
        top: `${this.coords.top}px`,
        bottom: `${this.coords.bottom}px`,
        height: `${this.coords.height}px`,
        zIndex: 15,
      };
    },

    debounseChangeFilterState() {
      return _.debounce(this.changeState, 500);
    },

    currentFilter() {
      return this.$store.getters['filter/currentFilter'];
    },
    isActiveFilter() {
      return this.currentFilter && this.currentFilter.isActiveFilter;
    },
  },

  watch: {
    $route(to, from) {
      this.closeFilter();
      this.currentFilter && this.saveActiveFilter();

      const isSameMode = to.params.mode && from.params.mode && to.params.mode === from.params.mode;
      const isCommon = !to.params.multiviewID && !to.params.projectId && !from.params.multiviewID && !from.params.projectId;
      const isSameProject = +to.params.projectId === +from.params.projectId || +to.params.multiviewID === +from.params.multiviewID;

      if ((isSameProject || isCommon) && isSameMode && this.currentFilter) { return; }

      this.init();
    },
    isOpenFilter(newVal) {
      if (!newVal) {
        this.isShowSavedFilters = false;
        if (this.filterTaskNoFound) {
          this.resetFilterState();
        }
      }
    },
  },

  created() {
    this.init();
    const component = this;

    app.on('filter:close:export', () => {
      component.currentFilter && component.closeFilter();
    });

    app.on('filter:toggle:export', () => {
      component.currentFilter && component.toggleExportFilter();
    });

    app.on('filter:popup:toggle', () => {
      component.$store.dispatch('filter/toggleIsOpenFilter');
    });

    app.on('header:refresh:filter', () => {
      component.refreshFilter();
    });

    app.on('refresh:filter', () => {
      component.refreshFilter();
    });

    app.on('filter:refresh:gantt', () => {
      this.ganttFilterRefresh++;
      component.refreshFilter();
    });

    app.on('workload:filter:filtration', () => {
      if (component.currentFilter && component.currentFilter.type === 'workload') {
        component.showOverlay = true;
        component.currentFilter.runWorkloadFiltration();
        component.showOverlay = false;
      }
    });

    app.on('userCustomColumnsModel:change', (gantt_id, action, column_id) => {
      component.currentFilter && component.updateCustomColumns();
      component.refreshFilter();
    });

    app.on('activeMultiProjects:set', () => {
      component.currentFilter && component.updateCustomColumns();
    });

    app.on('filter:tasks:not:found', () => {
      if (!component.currentFilter) return;
      if (!component.currentFilter.resetIfnotFoundTasks) return;

      component.filterTaskNoFound = true;

      // webix.message({
      //   type: 'info',
      //   text: __('filter_no_items_to_display'),
      //   expire: 6000,
      //   id: 'filter_no_items_to_display',
      //   isExport: app.config.mode.isExport,
      // });

      const vueToastRightContainers = document.documentElement.querySelectorAll('.Vue-Toastification__container.top-right');

      if (app.config.mode.isExport) {
        vueToastRightContainers.forEach(el => {
          el.classList.add('export');
        });
      }

      this.$toast.info(__('filter_no_items_to_display'));

      _.debounce(() => {
        vueToastRightContainers.forEach(el => {
          el.classList.remove('export');
        });
      }, 5000);

      !component.isOpenFilter && component.resetFilterState();
      component.filterKey++;
    });

    app.on('changeUserDateTimeFormat', () => {
      // console.log('change User Date-Time Format')
    });
    app.on('hideFilterPopup', () => {
      component.currentFilter && component.closeFilter();
    });

    app.on('header:click:myTasks', () => {
      if (component.currentFilter && component.currentFilter.myIncompleteTasks && routerHelper.isListViewRoute()) {
        component.currentFilter.myIncompleteTasks();
      }
      component.setIsActiveFilter(component.isActiveFilter);
    });

    app.on('filter:reset', () => {
      component.resetFilterState();
      component.refreshFilter();
      component.filterKey++;
    });

    app.on('onAfterCollaboration', data => {
      if (data.event === 'TaskResourceUnassigned') {
        const gantt_id = projectsModel.getActiveGanttId();
        const canSeeAllTasks = rights.project.hasRight(gantt_id, 'all_tasks');
        const userResource = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);
        const currentResource = data.unassignedResources.find(obj => obj.unassignedResourcesIds.find(id => id === userResource.id));

        if (canSeeAllTasks || !currentResource) return;

        const tasks = this.$store.getters['tasksModel/getTasksByGanttIds']([gantt_id]);
        const accessTasks = tasks.filter(task => task.type === gantt.config.types.task || task.type === gantt.config.types.milestone);

        if (!accessTasks.length) {
          this.$store.dispatch('filter/setIsOpenFilter', false);
        }
      }
    });
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.saveActiveFilter);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.saveActiveFilter);
    });
  },

  mounted() {
    document.addEventListener('click', this.documentClickHandler);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.documentClickHandler);

    return this.saveActiveFilter();
  },

  methods: {
    init() {
      this.$store.dispatch('filter/init', this.getRouteName);
      this.prevFilter = this.getRouteName;
      this.setIsActiveFilter(this.isActiveFilter);
      this.filterKey++;

      if (this.ganttFilterRefresh && this.currentFilter && ['project', 'multiview'].includes(this.currentFilter.type)) {
        this.refreshFilter();
        this.ganttFilterRefresh = 0;
      }
    },

    updateCustomColumns() {
      this.currentFilter?.updateCustomColumns();
    },

    saveActiveFilter() {
      if (this.currentFilter && this.currentFilter.saveActiveFilter) {
        return this.currentFilter.saveActiveFilter();
      }
    },

    async deleteSavedFilter(item) {
      await this.currentFilter.removeSavedFilter(item);
      this.redrawSevedFiltersPopup++;
    },

    editSavedFilter(item) {
      this.currentFilter.editSavedFilter(item);
    },

    changeFilterState(filterState) {
      this.filterTaskNoFound = false;
      this.temptFilterState = filterState;

      return this.debounseChangeFilterState();
    },

    changeState() {
      this.currentFilter.changeFilterState(this.temptFilterState);
      this.$store.commit('filter/setFilterState', this.temptFilterState);
      this.setIsActiveFilter(this.isActiveFilter);
      this.sendAnalyticApplyFilter();
    },

    resetFilterState() {
      if (!this.isActiveFilter) return;

      this.filterTaskNoFound = false;
      this.currentFilter.filterState.assignee = [];
      this.currentFilter.filterState.customColumns = '';
      delete this.currentFilter.filterState.customColumns;

      this.currentFilter.resetFilter();
      this.setIsActiveFilter(this.isActiveFilter);
      this.sendAnalyticApplyFilter();
    },

    selectSavedFilter(filter) {
      this.currentFilter.selectSavedFilter(filter);
      this.setIsActiveFilter(this.isActiveFilter);
      this.sendAnalyticApplyFilter();
    },

    closeFilter() {
      if (this.filterTaskNoFound) {
        this.resetFilterState();
      }

      this.isShowSavedFilters = false;
      this.$store.dispatch('filter/setIsOpenFilter', false);
      this.saveActiveFilter();
    },

    toggleExportFilter() {
      this.$store.dispatch('filter/toggleIsOpenFilter');
      logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
    },

    setIsActiveFilter(flag) {
      this.$store.commit('filter/setIsActiveFilter', flag);
    },

    async saveFilter(name) {
      return await this.currentFilter.save(name);
    },
    refreshFilter() {
      this.currentFilter && this.currentFilter.refreshFilter();
    },

    documentClickHandler(e) {
      if (!this.currentFilter) return;
      if (!this.isOpenFilter) return;

      const filterBtn = document.querySelector('.header-filter-btn');
      const overlay = document.querySelector('.popup_proto_shadow.show');
      const savePopup = document.querySelector('.filter-save-popup-for-vue');

      const isFilterBtn = filterBtn && filterBtn.contains(e.target);
      const isSavePopup = savePopup && savePopup.contains(e.target);

      let path = e.path;

      if (!path && e.composedPath) { // for firefox and safari
        path = e.composedPath(e.target);
      }

      const isCalendar = path.find(elem => {
        if (!elem.classList) return;
        const is = elem.classList.contains('filter-datepicker');

        return is;
      });
      const isHelpBlock = path.find(elem => {
        if (!elem.classList) return false;
        const isHelpClose = elem.classList.contains('help-block-close');
        const isVideoClose = elem.classList.contains('js-show-global-video-fullscreen-close');

        return isHelpClose || isVideoClose;
      });

      if (isFilterBtn || overlay || isSavePopup || isCalendar || isHelpBlock) {
        return;
      }

      if (this.isOpenFilter && !this.$refs.filter.contains(e.target)) {
        this.closeFilter();
      }
    },

    sendAnalyticApplyFilter() {
      this.currentFilter && userExtAnalytics.log('filter_applied', {
        saved: !!this.currentFilter.id,
        from: this.getAnalyticFrom(),
        route: this.getAnalyticRoute(),
        type: this.getAnalyticType(),
      });
    },

    getAnalyticFrom() {
      const route = this.$route.path;

      if (route.includes('project') || app.config.mode.isExport) {
        if (route.includes('calendar')) {
          return 'calendar';
        }

        return 'project & portfolio';
      }
      if (route.includes('workload')) {
        return 'workload';
      }
      if (route.includes('tasks/list')) {
        return 'my tasks';
      }
      if (route.includes('timelog')) {
        return 'my time log';
      }
      if (route.includes('reports')) {
        return 'reports';
      }
    },

    getAnalyticRoute() {
      const route = this.$route.path;

      let from = '';

      if (app.config.mode.isExport) {
        const from = this.currentFilter.type === 'project' ? 'Project' : 'Portfolio';

        return `${from}/Export`;
      }

      if (route.includes('project')) {
        from = route.includes('project/') ? 'Project' : 'Portfolio';

        if (route.includes('gantt')) {
          return `${from}/Gantt`;
        }
        if (route.includes('board')) {
          return `${from}/Board`;
        }
        if (route.includes('workload')) {
          return `${from}/Worcload`;
        }

        if (route.includes('calendar')) {
          return `${from}/calendar`;
        }
      }

      if (route.includes('workload')) {
        return 'Worcload';
      }

      if (route.includes('tasks/list')) {
        return `${from}MyTasks`;
      }

      if (route.includes('timelog')) {
        return `${from}MyTimeLog`;
      }

      if (route.includes('reports')) {
        from = 'Reports';

        if (route.includes('timeloglist')) {
          return `${from}/Timelog`;
        }

        if (route.includes('costslist')) {
          return `${from}/Costs`;
        }
      }
    },

    getAnalyticType() {
      const types = [];
      const state = this.currentFilter.filterState;
      const keys = Object.keys(state);

      keys.forEach(key => {
        if (key === 'customColumns') {
          types.push('custom fields');

          return;
        }
        if (!this.isFillOption(state[key])) return;

        switch (key) {
        case 'task_name':
        case 'text': types.push('task name'); break;
        case 'gantt_id': types.push('projects'); break;

        case 'resource_id': {
          if (this.currentFilter.type === 'workload') {
            types.push('assignee');

            return;
          }
        }
        case 'resources': {
          if (this.currentFilter.type === 'workload') {
            types.push('assignee');

            return;
          }
          types.push('resources');

          return;
        }

        case 'assignee': {
          if (this.currentFilter.type === 'timeloglist') {
            types.push('user');

            return;
          }
          types.push('assignee');

          return;
        }
        case 'owner_id': types.push('creater'); break;

        case 'cost_range':
        case 'rangeDate': types.push('date range'); break;
        case 'start_date':
        case 'startDate': types.push('start date'); break;
        case 'end_date':
        case 'endDate': types.push('end date'); break;
        case 'created_at':
        case 'creationDate': types.push('creation date'); break;

        case 'overdue': types.push('overdue tasks'); break;
        case 'time': types.push('time (timelog report)'); break;
        case 'comment': types.push('comment (timelog report)'); break;
        default: types.push(key);
        }
      });

      return types;
    },

    isFillOption(option) {
      if (Array.isArray(option)) {
        return option.length;
      }
      if (_.isObject(option)) {
        return (option.start || option.period) || (option.from || option.to);
      }

      return !!option;
    },

  },
};
</script>

<style module src="./filter.less" lang="less" />
