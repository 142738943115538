import app from '../../../app';
import globalStore from '../../../store/main';

function onTaskAttachementDeleted(event) {
  let ids = [];

  if (event.data?.ids.length) {
    ids = event.data.ids;
  } else if (event.data?.taskIds.length) {
    event.data.taskIds.forEach(tid => {
      const taskData = globalStore.getters['tasksModel/getTask'](tid);

      if (taskData && taskData.attachments?.length) {
        ids.push(...taskData.attachments.map(attachment => attachment.id));
      }
    });
  }

  ids.forEach(id => globalStore.dispatch('attachments/onWSAttachmentDeleted', id));

  app.trigger('onAfterCollaboration', {
    event: event.event,
    ids: event.data.ids,
    tasks: event.innerSettings.attachmentForSockets.map(i => i.task_id),
  });
}

export default onTaskAttachementDeleted;
