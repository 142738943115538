import './helpers/gt';
import Vue from 'vue';
import './views/main';
import AutoLinks from 'quill-auto-links';
import './plugins/vueWebix/vue-view';
import './plugins/vueWebix/webix-controls';
import QuillMention from 'quill-mention';
import ImageUploader from 'quill-image-uploader';
import app from './app';
import router from './router/main';
import store from './store/main';
import App from './App.vue';
import './vgpVueComponents';
import vuetify from './plugins/vuetify';
import Quill from './libs/quill/ext';

import renderPublicPasswordPage from './views/publicUrl/renderPublicPasswordPage';
import rights from './components/rights/index';

import './helpers/cookieConsent';

window.webix_strict = true;

Quill.register('modules/autoLinks', AutoLinks);
Quill.register('modules/customMention', QuillMention);
Quill.register('modules/imageUploader', ImageUploader);

window.dataLayer = window.dataLayer || [];

const bodyRows = [
  {
    view: 'vue',
    id: 'header',
    css: 'js_header',
    template: '<header-vue></header-vue>',
    data: {
      height: 1, // for start watching
    },
  },
  {
    borderless: true,
    paddingY: 0,
    margin: 0,
    body: {
      id: 'ganttMainNode',
      css: 'body-inner ganttMainNode',
      borderless: true,
      rows: [
        {
          type: 'wide',
          id: 'mainSpace',
          borderless: true,
          cols: [
            {
              view: 'dhx-gantt',
              id: 'dhxView-gantt',
              borderless: true,
              initMode: true,
            },
          ],
        },
      ],
    },
  },
];

if (app.config.mode.isExport || app.config.mode.isHistory) {
  bodyRows.shift();
}

const layout = {
  id: 'mainApplication',
  css: app.config.mode.isHistory ? 'isHistory' : '',
  cols: [
    {
      view: 'vue',
      id: 'leftSideBar',
      css: 'left-sidebar-wrapper',
      hidden: !app.config.mode.isBase,
      width: 40,
      // zIndex: 124,
      template: '<left-sidebar-vue> </left-sidebar-vue>',
    },
    {
      rows: [
        {
          id: 'ganttProBody',
          paddingY: 0,
          margin: 0,
          css: 'gantt_pro_body_container',
          borderless: true,
          rows: bodyRows,
        },
        {
          view: 'vue',
          id: 'footer',
          css: 'footer_vue',
          borderless: true,
          height: app.config.mode.isBase ? store.getters['footer/height'] : 1,
          template: `
            <div>
              <footer-vue></footer-vue>
            </div>`,
          data: {
            height: 1,
          },
        },
      ],
    },
  ],
};

if (!app.config.mode.isLink
  || app.config.mode.isHistory
  || app.config.mode.isTemplate
  || (app.config.mode.isLink && app.config.mode.accessToPublicUrl)
) {
  webix.ready(() => {
    app.trigger('app:start');
    webix.ui(layout);
    webix.Touch.limit();
    webix.extend($$('mainApplication'), webix.ProgressBar);
    $$('webix:debugmenu').close(); // fix right click+ctrl in debug version
  });
}

app.on('gantt:init:after', () => {
  const mainApplication = $$('mainApplication');

  if (mainApplication) {
    $$('mainApplication').hideProgress();

    if (user.subscription.status_code === 110 && rights.account.isOwner()) {
      app.trigger('notify:payment:orderProcessing');
    }
  }
});

Vue.config.devtools = true;
Vue.config.productionTip = false;
const initVueApp = app => // it needs for init after load main models(temporary)
  new Promise((resolve, reject) => {
    const vueApp = new Vue({
      router,
      store,
      vuetify,
      el: '#vue-container',
      components: {
        App,
      },
      watch: {
        $route(value) {
          app.trigger('app:route:changed', value);
        },
      },
      mounted() {
        resolve();
      },
      render(createElement) {
        return createElement(App);
      },
    });
  });

if (app.config.mode.isLink
  && !app.config.mode.isHistory
  && !app.config.mode.isTemplate
  && !app.config.mode.accessToPublicUrl) {
  renderPublicPasswordPage(Vue);
}

export {
  initVueApp,
};
