var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: _vm.$style["lds-ring"] }, [
    _c("span"),
    _vm._v(" "),
    _c("span"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }