// !TODO: description: public/models/comments2.js

import app, { appIds } from '../app';
import globalStore from '$$store/main';
import rights from '../components/rights';

const __ = window.__;
const _ = window._;

const {
  events: {
    ID_EVENT_APP_START,
    ID_EVENT_COMMENTS_DELETE_COMMENTS,
    ID_EVENT_COMMENTS_ADD_COMMENT,
    ID_EVENT_COMMENTS_EDIT_COMMENT,
    ID_EVENT_COMMENTS_REMOVE_COMMENT,
    ID_EVENT_COMMENTS_MARK_AS_READ,
    ID_EVENT_COMMENTS_INIT_COMPLETE,
    ID_EVENT_PROJECT_ACTIVE_AFTERSET,
    ID_EVENT_COMMENTS_ADD_COMMENTS,
    ID_EVENT_WS_TEAM_DEL_PROJECT,
    ID_EVENT_COLLABORATION_UPDATEDMODELS,
    ID_EVENT_TASKS_MASSDELETE,
  },
  wsEvents: {
    ID_EVENT_WS_ADD_COMMENT,
    ID_EVENT_WS_EDIT_COMMENT,
    ID_EVENT_WS_RM_COMMENT,
  },
} = appIds;

const _api = {
  // {Number} -> {Promise}
  markCommentAsRead: commentIds => webix.ajax().post('/api/comments/new/markread', { commentIds })
    .then(response => response.json()),
  // {Number} -> {Promise}
  removeComment: commentId => webix.ajax().del('/api/comments/new', { commentId })
    .then(response => response.json()),
  // {Object} -> {Promise}
  addComment: commentData => webix.ajax().post('/api/comments/new', { commentData })
    .then(response => response.json()),
  editComment: commentData => webix.ajax().patch('/api/comments/edit', { commentData })
    .then(response => response.json()),
  // {} -> {Promise}
  getCommentsList: () => webix.ajax().get('/api/comments/new', { countTask: 0 })
    .then(response => response.json()),
};

/** @constructor */
const CommentsModel = function (app, api) {
  this.__name = 'comments';
  this.__app = app;
  this.__api = api;
  this.__comments = []; // null;
  this.__err = null;
  this.__enabled = false;

  Promise.resolve()
    .then(this._initData.bind(this))
    .then(this._initHandlers.bind(this))
    .then(this._enable.bind(this));
};

/** @private */
CommentsModel.prototype._initHandlers = function () {
  this.onWSAddCommentHandler = this.onWSAddComment.bind(this);
  this.onWSEditCommentHandler = this.onWSEditComment.bind(this);
  this.onWSRmCommentHandler = this.onWSRemoveComment.bind(this);

  this._addCommentToModelHandler = this._addCommentToModel.bind(this);
  this._editCommentInModelHandler = this._editCommentInModel.bind(this);
  this._removeCommentFromModelHandler = this._removeCommentFromModel.bind(this);
  this._markAsReadInModelHandler = this._markAsReadInModel.bind(this);
  this._deleteFromModelHandler = this._deleteFromModel.bind(this);
};

/** @private */
CommentsModel.prototype._enable = function () {
  const {
    onWSAddCommentHandler, onWSRmCommentHandler, onWSEditCommentHandler,
    __app, __name, __err,
  } = this;

  const __this = this;

  return new Promise(resolve => {
    __app.socket.on(ID_EVENT_WS_ADD_COMMENT, onWSAddCommentHandler);
    __app.socket.on(ID_EVENT_WS_EDIT_COMMENT, onWSEditCommentHandler);
    __app.socket.on(ID_EVENT_WS_RM_COMMENT, onWSRmCommentHandler);

    // __app.on(ID_EVENT_APP_START, () => {
    // __app.checkInit(__name);
    __app.trigger(ID_EVENT_COMMENTS_INIT_COMPLETE, __err);

    __this.__enabled = true;

    return resolve();
    // });
  });
};

// !DESCRIPTION
CommentsModel.prototype._initData = function () {
  // const __this = this;

  // return __this.__api.getCommentsList()
  //   .then(result => {
  //     __this.__comments = result;
  //   });
  // .catch((err) => {
  //     this.__err = err;
  // });
};

// @@public !TODO: verify input
CommentsModel.prototype.onWSAddComment = function (result) {
  // console.info('CommentsModel.prototype._onWSAddComment', result); // !DEBUG
  this._addCommentToModelHandler(result.data);
};

CommentsModel.prototype.onWSEditComment = function (result) {
  this._editCommentInModelHandler(result.data);
};

// @@public !TODO: verify input
CommentsModel.prototype.onWSRemoveComment = function (result) {
  // console.info('CommentsModel.prototype._onWSRemoveComment', result); // !DEBUG
  this._removeCommentFromModelHandler(+result.data);
};

// @public !DESCRIPTION {Number, Object} -> {Array}
CommentsModel.prototype.getTaskComments = function (idTask, params = { sort: 'asc' }) {
  const { sort } = params;
  const sortProc = sort === 'asc'
    ? (e1, e2) => e1.id - e2.id
    : (e1, e2) => e2.id - e1.id;

  return this.__comments
    .filter(entry => entry.task_id === idTask)
    .sort(sortProc);
};

// @public !DESCRIPTION {Number} -> {Object}
CommentsModel.prototype.getComment = function (idComment) {
  // console.info('CommentsModel.prototype.getComment'); // !DEBUG
  return this.__comments.find(e => e.id === idComment);
};

// @public  !DESCRIPTION {} -> {Array}
CommentsModel.prototype.getAllComments = function () {
  // console.info('CommentsModel::prototype::getAllComments'); // !DEBUG
  return this.__comments;
};

// @public
CommentsModel.prototype.isInit = function () {
  return this.__enabled;
};

/**
 * @public Adds a new comment to the domain data
 *
 * @param {Number} data.task_id
 * @param {Number} data.gantt_id
 * @param {Number} data.user_id
 * @param {String} data.comment
 * @param {String} data.date
 * @param {String} data.firstName
 * @param {String} data.lastName
 * @param {String} data.userName
 * @param {String} data.photo
 * @param {String} data.task_name
 * @param {Number} data.team_id
 * @param {Number} data.read
 *
 * @return {Promise}
 */
CommentsModel.prototype.addNewComment = function (data) {
  const { __api, _addCommentToModelHandler } = this;

  const _fields = _.pick(data, [
    'project_name', 'task_id', 'gantt_id',
    'user_id', 'comment', 'date', 'firstName',
    'lastName', 'username', 'photo', 'task_name',
    'team_id', 'read',
  ]);

  return __api
    .addComment(_fields)
    .then(_addCommentToModelHandler);
};

// We temporarily need it to sync data CommentsModel with data Vuex Comments store
// Remove this method and all its usages after comment hub will be implemented
CommentsModel.prototype.addNewCommentToModel = function (commentData) {
  const { __comments, __app } = this;

  // console.info('CommentsModel.prototype._addCommentToModel', commentData); // !DEBUG
  __comments.push(commentData);

  __app.trigger(ID_EVENT_COMMENTS_ADD_COMMENT, commentData, commentData.task_id);
};

// We temporarily need it to sync data CommentsModel with data Vuex Comments store
// Remove this method and all its usages after comment hub will be implemented
CommentsModel.prototype.removeCommentFromModel = function (commentId) {
  const { __comments, __app } = this;
  const __comment = __comments.find(entry => entry.id === commentId);

  if (__comment) {
    __comment.is_removed = 1;
    __app.trigger(ID_EVENT_COMMENTS_REMOVE_COMMENT, commentId, __comment.task_id);
  }
};

/**
 * @public Removes selected comment from the domain data
 *
 * @param {Number} idComment - comment identifier
 *
 * @return {Promise}
 */
CommentsModel.prototype.removeComment = function (idComment) {
  // console.info('CommentsModel::prototype::removeComment', idComment); // !DEBUG
  const { __api, _removeCommentFromModelHandler } = this;

  __api.removeComment(idComment)
    .then(_removeCommentFromModelHandler);
};

/**
 * @public Edit selected comment at the domain data
 *
 * @param {Number} data.task_id
 * @param {Number} data.gantt_id
 * @param {Number} data.user_id
 * @param {Number} data.id
 * @param {String} data.comment
 * @param {String} data.date_edited
 *
 * @return {Promise}
 */
CommentsModel.prototype.editComment = function (data) {
  const { __api, _editCommentInModelHandler } = this;

  const _fields = _.pick(data, [
    'id', 'comment', 'date_edited',
    'gantt_id', 'task_id', 'team_id',
    'user_id',
  ]);

  return __api
    .editComment(_fields)
    .then(_editCommentInModelHandler);
};

/**
 * !TODO: check typeof array????
 * @public Description
 *
 * @param {Array}
 *
 * @return {Promise}
 */
CommentsModel.prototype.markAsRead = function (commentIds) {
  // console.info('CommentsModel::prototype::markAsRead', commentIds); // !DEBUG
  const { __api, _markAsReadInModelHandler } = this;

  // console.info('CommentsModel.prototype.markAsRead')
  __api.markCommentAsRead(commentIds)
    .then(_markAsReadInModelHandler)
    .then(() => globalStore.commit('comments/markAsReadCommentsActions', commentIds));
};

/**
 * @public Returns the count of unread comments by current user
 *
 * @return {Number}
 */
CommentsModel.prototype.getUnreadCount = function () {
  const __unread = this.__comments.filter(entry => entry.read !== 1);
  const __uCount = __unread.length;
  // console.info('CommentsModel.prototype.getUnreadCount', __unread, __uCount); // !DEBUG

  return __uCount;
};

/**
 * @public Returns a list of unread comments by current user
 *
 * @return {Array} - a list of unread comments
 */
CommentsModel.prototype.getUnreadList = function () {
  return this.__comments.filter(entry => {
    let accessToComments = false;
    const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

    const tasksToCurrentResource = globalStore.getters['tasksModel/getResourcesForTask'](entry.gantt_id, entry.task_id);

    if (rights.project.hasRight(entry.gantt_id, 'comments')) {
      if (!rights.project.hasRight(entry.gantt_id, 'all_tasks')) {
        tasksToCurrentResource.forEach(resource => {
          if (resource.resource_id === resourceId) {
            accessToComments = true;
          }
        });
      } else {
        accessToComments = true;
      }
    }

    return entry.read !== 1 && accessToComments;
  });
};

/**
 *
 * @public !descr
 *
 * @return {Array}
 */
CommentsModel.prototype.getProjectsWithComments = function () {
  const __projIds = [];

  this.__comments.forEach(({ gantt_id }) => {
    if (__projIds.indexOf(gantt_id) === -1) {
      __projIds.push(gantt_id);
    }
  });

  return __projIds;
};

/**
 * @public !description
 * @param {Number}
 *
 * @return {Array}
 *
 */
CommentsModel.prototype.getCommentsByProject = function (idProject) {
  const { __comments } = this;

  const __commentIds = [];

  for (let i = 0, len = __comments.length; i < len; i++) {
    const __commentEntry = __comments[i];

    if (__commentEntry.gantt_id === idProject) {
      __commentIds.push(__commentEntry.id);
    }
  }

  return __commentIds;
};

/**
 * @public Returns the list of comments for the specifed task
 * @param {Number} idTask - the identifier of the task
 *
 * @return {Array} - an array of comment identifiers
 */
CommentsModel.prototype.getCommentsByTask = function (idTask) {
  const { __comments } = this;

  const __commentIds = [];

  for (let i = 0, len = __comments.length; i < len; i++) {
    const __commentEntry = __comments[i];

    if (__commentEntry.task_id === idTask) {
      __commentIds.push(__commentEntry.id);
    }
  }

  return __commentIds;
};

/**
 * @public
 */
CommentsModel.prototype.refreshData = function () {
  const { __comments, __api } = this;

  const __idsToRemove = [];
  const __commentsToAdd = [];

  __api.getCommentsList()
    .then(commentsData => {
      commentsData.forEach(comment => {
        if (!__comments.find(entry => entry.id === comment.id)) {
          __commentsToAdd.push(comment);
        }
      });

      __comments.forEach(comment => {
        if (!commentsData.find(entry => entry.id === comment.id)) {
          __idsToRemove.push(comment.id);
        }
      });

      // console.info('CommentsModel.prototype.refreshData', __idsToRemove, __commentsToAdd); // !DEBUG
      // return;

      if (__idsToRemove.length > 0) {
        this._deleteFromModel(__idsToRemove);
      }

      if (__commentsToAdd.length > 0) {
        this._addToModel(__commentsToAdd);
      }
    });
};

/**
 * @public Removes specified comments from the model.
 * Fires the appropriate events for subscribers
 *
 * @param {Array} - an array of comments identifiers to remove
 */
CommentsModel.prototype._deleteFromModel = function (idsToRemove) {
  const { __comments, __app } = this;
  const __idsToRead = [];

  for (let i = 0; i < __comments.length; i++) {
    const __commentEntry = __comments[i];

    if (idsToRemove.indexOf(__commentEntry.id) !== -1) {
      __comments.splice(i, 1);

      if (__commentEntry.read !== 1) {
        __idsToRead.push(__commentEntry.id);
      }

      i--;
    }
  }

  __app.trigger(ID_EVENT_COMMENTS_MARK_AS_READ, __idsToRead);
  __app.trigger(ID_EVENT_COMMENTS_DELETE_COMMENTS, idsToRemove);
};

/** @private */
CommentsModel.prototype._markAsReadInModel = function (commentIds) {
  const { __comments, __app } = this;

  if (
    !Array.isArray(commentIds)
        || commentIds.length === 0
  ) {
    return;
  }

  // const __commentEntry = __comments.find(entry => entry.id === idComment)

  const __updatedIds = [];

  commentIds.forEach(idComment => {
    const __commentEntry = __comments.find(entry => entry.id === idComment);

    if (__commentEntry) {
      __commentEntry.read = 1;
      __updatedIds.push(idComment);
    }
  });

  __app.trigger(ID_EVENT_COMMENTS_MARK_AS_READ, __updatedIds);
};

/** @private */
CommentsModel.prototype._addToModel = function (commentsData) {
  const { __comments, __app } = this;

  commentsData.forEach(entry => {
    __comments.push(entry);
  });

  __app.trigger(ID_EVENT_COMMENTS_ADD_COMMENTS, commentsData);
};

/** @private */
CommentsModel.prototype._addCommentToModel = function (commentData) {
  const { __comments, __app } = this;

  // console.info('CommentsModel.prototype._addCommentToModel', commentData); // !DEBUG
  __comments.push(commentData);

  const taskData = globalStore.getters['tasksModel/getTaskByGanttId'](commentData.gantt_id, commentData.task_id);

  if (!taskData) {
    console.warn(`[comment::_addCommentToModel] -> no task data for: ${commentData.gantt_id} ${commentData.task_id}`);

    return;
  }
  taskData.hasComments = +(taskData.hasComments || 0) + 1;
  taskData.newComments = true;
  globalStore.dispatch('tasksModel/updateTask', {
    taskChanges: taskData,
    taskId: taskData.id,
    ganttId: taskData.gantt_id,
  });

  __app.trigger(ID_EVENT_COMMENTS_ADD_COMMENT, commentData, commentData.task_id);
};

/** @private */
CommentsModel.prototype._editCommentInModel = function (commentData) {
  const { __comments, __app } = this;

  // console.info('CommentsModel.prototype._addCommentToModel', commentData); // !DEBUG
  const comment = __comments.find(entry => entry.id === +commentData.id);

  if (comment) {
    comment.comment = commentData.comment;
    comment.date_edited = commentData.date_edited;
  }

  __app.trigger(ID_EVENT_COMMENTS_EDIT_COMMENT, +commentData.id, commentData);
};

// !TODO: rename function "markAsRemoved"
CommentsModel.prototype._removeCommentFromModel = function (idComment) {
  const { __comments, __app } = this;
  const __comment = __comments.find(entry => entry.id === idComment);

  if (__comment) {
    // const taskData = globalStore.getters['tasksModel/getTaskByGanttId'](__comment.gantt_id, __comment.task_id);

    // if (!taskData) {
    //   console.warn(`[comment::_removeCommentFromModel] -> no task data for: ${__comment.gantt_id} ${__comment.task_id}`);

    //   return;
    // }
    // taskData.hasComments = +taskData.hasComments - 1;
    // taskData.newComments = false;
    // globalStore.dispatch('tasksModel/updateTask', {
    //   taskChanges: taskData,
    //   taskId: taskData.id,
    //   ganttId: taskData.gantt_id,
    // });

    if (__comment) {
      __comment.is_removed = 1;
      __app.trigger(ID_EVENT_COMMENTS_REMOVE_COMMENT, idComment, __comment.task_id);
    }
  }
};

CommentsModel.prototype.onArchivedProject = function (ganttId, isArchived) {
  if (isArchived === 1) {
    const __commentIds = commentsModel.getCommentsByProject(parseInt(ganttId, 10));

    commentsModel._deleteFromModel(__commentIds);
  } else {
    commentsModel.__api.getCommentsList()
      .then(comments => {
        const __req = comments.filter(entry => entry.gantt_id === ganttId);

        __req.forEach(de => {
          commentsModel._addCommentToModelHandler(de);
        });
      });
  }
};

let commentsModel = {};

if (app.config.mode.isBase) {
  commentsModel = new CommentsModel(app, _api);
} else {
  app.checkInit('comments');
}

export default commentsModel;

// events: "team:newProjects" and "removeProject" and "teamShare:addProject" don't fire
// !FIXME: move to the another place
app.socket.on(ID_EVENT_WS_TEAM_DEL_PROJECT, ganttId => {
  const __commentIds = commentsModel.getCommentsByProject(parseInt(ganttId, 10));

  commentsModel._deleteFromModel(__commentIds);
});

// app.on('project:archive', (ganttId, isArchived) => {
//   commentsModel.onArchivedProject(ganttId, isArchived);
// });

app.on(ID_EVENT_COMMENTS_ADD_COMMENT, commentData => {
  setTimeout(() => {
    gantt.callEvent('newTaskCommentNotification', [[commentData], commentData.read === 1]);
  }, 0);
});

app.on(ID_EVENT_COMMENTS_MARK_AS_READ, commentIds => {
  setTimeout(() => {
    const __taskIds = commentIds.reduce((result, idComment) => {
      const __comment = commentsModel.getComment(idComment);

      return (!__comment || result.indexOf(__comment.task_id) !== -1)
        ? result
        : result.concat(__comment.task_id);
    }, []);

    __taskIds.forEach(idTask => gantt.callEvent('removeTaskCommentNotification', [idTask]));
  }, 0);
});

app.on(ID_EVENT_COLLABORATION_UPDATEDMODELS, (payload = {}) => {
  const { actionType, data } = payload;
  const DELETE = 62;

  if (actionType === DELETE) {
    const __commentIds = commentsModel.getCommentsByTask(parseInt(data.taskId, 10));

    commentsModel._deleteFromModel(__commentIds);
  }
});

app.on('tasks:model:delete', idTask => {
  const __commentIds = commentsModel.getCommentsByTask(parseInt(idTask, 10));

  commentsModel._deleteFromModel(__commentIds);
});

app.on(ID_EVENT_TASKS_MASSDELETE, (ganttId, taskIds) => {
  taskIds.forEach(idTask => {
    const __commentIds = commentsModel.getCommentsByTask(parseInt(idTask, 10));

    commentsModel._deleteFromModel(__commentIds);
  });
});

app.on('resources:model:removeResourcesFromProjects', () => {
  commentsModel.refreshData();
});

// // adding new user to the project
// app.socket.on('update:project:lastUpdate', _.debounce(function (data) {
//     commentsModel.refreshData();
// }, 1000));
