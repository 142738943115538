<template>
  <div :class="$style.resource">
    <div
      v-if="person.photo"
      :class="$style.photo_wrap"
      :style="{'background-image': `url(${person.photo})`}"
    ></div>
    <div :class="$style.name">{{ person.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'PersonCell',
  props: ['context'],
  computed: {
    person() {
      return this.context.row.original.creator;
    }
  }
}
</script>

<style module src="./less/personCell.less"></style>