<template>
  <context-menu
    :position="position"
    :style="{ padding: 0 }"
    :horizontally-align-center="alignToCenter"
  >
    <template #body>
      <div class="dependency-preview__wrapper">
        <div class="dependency-preview__header">
          <span class="dependency-preview__header_name">{{ name }} </span>
          <span class="dependency-preview__header_description">{{ description }}</span>
        </div>
        <div class="dependency-preview__body">
          <div
            class="dependency-preview__predecessor"
            :style="predecessorStyles"
          >
            <span class="dependency-preview__item-name">
              {{ predecessorName }}
            </span>
            <div class="dependency-preview__predecessor_filled" />
          </div>
          <div
            class="dependency-preview__arrow"
            :style="arrowStyles"
            v-html="arrowIcon"
          />
          <div
            class="dependency-preview__successor"
            :style="successorStyles"
          >
            <span class="dependency-preview__item-name">
              {{ successorName }}
            </span>
            <div class="dependency-preview__successor_filled" />
          </div>
        </div>
      </div>
    </template>
  </context-menu>
</template>

<script>

import dependencyTypeArrow1 from '../../../../../svg/settings_depencency/1_arrow.svg';
import dependencyTypeArrow2 from '../../../../../svg/settings_depencency/2_arrow.svg';
import dependencyTypeArrow3 from '../../../../../svg/settings_depencency/3_arrow.svg';
import dependencyTypeArrow4 from '../../../../../svg/settings_depencency/4_arrow.svg';

const DEPENDENCY_NAME_BLOCKS_POSITIONS = {
  finish_to_start: {
    predecessor: {
      top: '17px',
      left: '18px',
    },
    successor: {
      top: '71px',
      left: '97px',
    },
    arrow: {
      top: '27px',
      left: '78px',
    },
    arrowIcon: dependencyTypeArrow1,
  },
  start_to_start: {
    predecessor: {
      top: '12px',
      left: '68px',
    },
    successor: {
      top: '70px',
      left: '68px',
    },
    arrow: {
      top: '21px',
      left: '38px',
    },
    arrowIcon: dependencyTypeArrow2,
  },
  start_to_finish: {
    predecessor: {
      top: '13px',
      left: '106px',
    },
    successor: {
      top: '70px',
      left: '9px',
    },
    arrow: {
      top: '21px',
      left: '93px',
    },
    arrowIcon: dependencyTypeArrow3,
  },
  finish_to_finish: {
    predecessor: {
      top: '12px',
      left: '37px',
    },
    successor: {
      top: '70px',
      left: '37px',
    },
    arrow: {
      top: '20px',
      left: '151px',
    },
    arrowIcon: dependencyTypeArrow4,
  },
};

export default {
  name: 'DependencyPreview',
  props: {
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    position: {
      type: Object,
      default: () => {},
    },
    predecessorName: {
      type: String,
      default: '',
    },
    successorName: {
      type: String,
      default: '',
    },
    alignToCenter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentBlocksPositionsConfig() {
      return DEPENDENCY_NAME_BLOCKS_POSITIONS[this.type];
    },
    predecessorStyles() {
      return this.currentBlocksPositionsConfig.predecessor;
    },
    successorStyles() {
      return this.currentBlocksPositionsConfig.successor;
    },
    arrowIcon() {
      return this.currentBlocksPositionsConfig.arrowIcon;
    },
    arrowStyles() {
      return this.currentBlocksPositionsConfig.arrow;
    },
  },
};
</script>

<style scoped lang="less">

.dependency-preview {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 128px;
    width: 225px;
    background-image: url('https://cdn.ganttpro.com/app/imgs/task-settings/dependecy-preview-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0 12.6148px 30.8361px -2.80328px rgba(0, 0, 0, 0.2);
    border-radius: 5.60656px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #191919;
    padding: 10px 10px 0 10px;
    &_name {
      font-family: Lato-Bold, sans-serif;
      margin-right: 10px;
    }
    &_description {
      font-family: Lato-Regular, sans-serif;
    }
  }

  &__body {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__item-name {
    position: absolute;
    max-width: calc(100% - 14px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Lato-Bold, sans-serif;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
  }

  &__predecessor, &__successor {
    width: 110px;
    height: 20px;
    border-radius: 5.60656px;
    position: absolute;
  }

  &__predecessor {
    background: #7986CB;
    &_filled {
      height: 100%;
      width: 54px;
      background: #454D79;
      border-radius: 5.60656px 0px 0px 5.60656px;
    }
  }

  &__successor {
    background: #82DCD7;
    &_filled {
      height: 100%;
      width: 54px;
      background: #2DA59D;
      border-radius: 5.60656px 0px 0px 5.60656px;
    }
  }

  &__arrow {
    position: absolute;
  }
}

</style>
