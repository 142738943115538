var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.$style["task-settings-attachments"] },
    [
      _c("div", { class: _vm.$style["header"] }, [
        _c(
          "div",
          { class: _vm.$style["title-wrap"] },
          [
            _c("h2", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.locales("settings_taskAttachment")) +
                  "\n        "
              ),
              _c("span", [_vm._v(_vm._s(_vm.formattedAttachments.length))]),
            ]),
            _vm._v(" "),
            !_vm.isFieldDisabled("attachments")
              ? _c("vgp-icon-button", {
                  ref: "newAttachmentButton",
                  attrs: {
                    active: !!_vm.newAttachmentContextMenuPosition,
                    icon: { name: "plus-1" },
                  },
                  on: { onClick: _vm.showNewAttachmentContextMenu },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.newAttachmentContextMenuPosition
              ? _c("new-attachment-context-menu", {
                  attrs: {
                    "position-context": _vm.newAttachmentContextMenuPosition,
                    "task-data": _vm.taskData,
                  },
                  on: {
                    "attachment-created": function ($event) {
                      return _vm.$emit("attachment-created")
                    },
                    close: function ($event) {
                      _vm.newAttachmentContextMenuPosition = null
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style["buttons-wrap"] },
          [
            _vm.selectedAttachmentsIds.length && _vm.activeViewMode === "list"
              ? [
                  !_vm.isFieldDisabled("attachments")
                    ? _c("vgp-icon-button", {
                        class: _vm.$style["delete-selected-btn"],
                        attrs: {
                          icon: { name: "delete" },
                          label: _vm.locales("common_delete"),
                        },
                        on: {
                          onClick: function ($event) {
                            return _vm.showDeleteConfirmPopup("multiple_delete")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("vgp-icon-button", {
                    attrs: {
                      disabled:
                        !_vm.isAnySelectedAttachmentUploadedFromLocalDevice,
                      icon: { name: "download" },
                      label: _vm.locales("common_download"),
                    },
                    on: { onClick: _vm.downloadSelected },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("vgp-icon-button", {
              ref: "sectionActionsBtn",
              class: _vm.$style["header-actions-btn"],
              attrs: { icon: { name: "more-1" } },
              on: { onClick: _vm.showSectionContextMenu },
            }),
            _vm._v(" "),
            _vm.sectionContextMenuPosition
              ? _c("context-menu", {
                  attrs: {
                    items: _vm.sectionContextItems,
                    position: _vm.sectionContextMenuPosition,
                  },
                  on: {
                    closePopup: function ($event) {
                      _vm.sectionContextMenuPosition = null
                    },
                    selectItemMenu: _vm.handleSelectItemMenu,
                  },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "KeepAlive",
        [
          _c(_vm.activeViewMode, {
            tag: "component",
            attrs: {
              items: _vm.visibleAttachments,
              "selected-items": _vm.selectedAttachmentsIds,
              "default-image-placeholder": _vm.defaultImagePlaceholder,
              "disabled-deletion": _vm.isFieldDisabled("attachments"),
            },
            on: {
              view: _vm.handleViewAttachment,
              select: _vm.handleSelectAttachment,
              delete: function ($event) {
                return _vm.showDeleteConfirmPopup("single_delete", $event)
              },
              download: _vm.downloadAttachment,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isViewAllBtnShown
        ? _c("vgp-icon-button", {
            staticClass: "mt-4",
            attrs: {
              size: "middle",
              bold: "",
              "icon-side": "right",
              icon: _vm.viewAll
                ? { name: "arrow-top" }
                : { name: "arrow-down" },
              label: _vm.viewAll
                ? _vm.locales("attachments_list_collapse_task_attachments")
                : _vm.locales("attachments_list_expand_task_attachments"),
              colors: {
                color: "#808080",
                labelColor: "#808080",
                activeBg: "transparent",
                activeHoverBg: "transparent",
              },
              sizes: { labelFontSize: "14px", lineHeight: "24px" },
            },
            on: {
              onClick: function ($event) {
                _vm.viewAll = !_vm.viewAll
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          "popup-width": 440,
          show: _vm.confirmDeletionData.showPopup,
          text: _vm.confirmDeletionData.text,
          "cancel-button": {
            title: _vm.locales("common_cancel"),
            type: "secondary",
          },
          "ok-button": {
            title: _vm.locales("common_delete"),
            type: "destructive",
          },
        },
        on: {
          onClickOk: _vm.handleConfirmDeletion,
          onClickCancel: _vm.cleanUpConfirmDeletionData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }