import axios from 'axios';
import { createToastInterface } from 'vue-toastification';
import constants from '$$helpers/constants';
import errorCmp from './errorToast.vue';
import app from '../app';

const toast = createToastInterface(constants.TOAST_CONFIG);

const internalApi = axios.create({
  baseURL: '/api/internal',
});

const api = axios.create({
  baseURL: '/api',
});

const socketInterceptor = req => {
  const methods = ['post', 'put', 'delete'];

  if (methods.includes(req.method)) {
    req.headers.socketId = app.socket?.id || '';
  }

  return req;
};

const metaInterceptor = req => {
  req.meta = req.meta || {};
  req.meta.requestStartedAt = new Date().getTime();

  if (req.data) {
    req.meta.data = req.data;
  }

  return req;
};

api.interceptors.request.use(metaInterceptor);
internalApi.interceptors.request.use(metaInterceptor);

api.interceptors.request.use(socketInterceptor);
internalApi.interceptors.request.use(socketInterceptor);

internalApi.interceptors.response.use(response => response,
  error => {
    // const errorData = error.response.data;
    // errorData.code = 1
    // errorData.msg = common

    // const errorData = error.response.data[0];
    // errorData.code = 3005
    // errorData.msg = specific

    // console.log(error.response.config.meta);
    // console.log(error.response.config.method);
    // console.log(error.response.config.headers);

    if (error.config.url.includes('/task')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/resources')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/custom_columns')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/comments')) {
      toast.error(errorCmp);
    }

    if (error.config.url.includes('/attachment')) {
      if (error.response?.data.code === 4005) {
        toast.error(__('fileUpload_limitStore', { limit: '' }));
      } else {
        toast.error(errorCmp);
      }
    }

    throw new Error(`Updating server error on '${error.config.url}'`);

    return Promise.reject(error);
  });

api.interceptors.response.use(response => response,
  error => {
    // const errorData = error.response.data;
    // errorData.message
    // errorData.details.user
    // errorData.details.request

    // console.log(error.response.config.meta);
    // console.log(error.response.config.method);
    // console.log(error.response.config.headers);

    if (error.config.url.includes('/task')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/link')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/resources')) {
      toast.error(errorCmp);
    }
    if (error.config.url.includes('/custom_columns')) {
      toast.error(errorCmp);
    }

    throw new Error(`Updating server error on '${error.config.url}'`);

    return Promise.reject(error);
  });

// old webix support

const debouncedToastCall = _.debounce(locale => {
  toast.error(locale ? __(locale) : errorCmp);
}, 300);

webix.attachEvent('onBeforeAjax', (mode, url, data, request, headers, files, promise) => {
  // add code
  headers.socketId = app.socket.id || '';
  promise.then(data => {
    // try {
    //   data.json();
    // } catch (e) {
    //   debauncedToastCall();
    // }
  }, error => {
    debouncedToastCall();
  });
});

export {
  internalApi,
  api,
};
