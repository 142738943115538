var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cell-checkbox", class: { not_allowed: !_vm.editable } },
    [
      _c("button", {
        staticClass: "btn-checkbox",
        class:
          ((_obj = { chacked: _vm.isChacked, discolor: _vm.discolor }),
          (_obj["box-" + _vm.theme] = true),
          (_obj["not_allowed"] = !_vm.editable),
          _obj),
        on: { click: _vm.clickOnBtn },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }