<template>
  <div class="settings-content settings-content-security">
    <div class="settings-section sso"
      v-if="accessToSsoSettings">
      <div class="section-title">
        <div :class="['title-ic check-ic', isShowSsoForm ? 'active' : '']">
          <webix-ui :config="icon.checkIcon" />
        </div>
        <div class="main-title">
          {{ locales('settings_sso_title') }}
        </div>
        <div
          v-if="!accessSsoPricing && planTitle"
          class="pricing-title-container"
        >
          {{ locales(planTitle) }}
        </div>
      </div>
      <div
        class="text"
        :style="!accessToSsoSettings ? {marginBottom: 0} : ''"
        v-html="accessToSsoSettings ? locales('settings_sso_text') : locales('settings_sso_text_if_not_enough_rights')"
      />
      <sso-form
        v-if="isShowSsoForm && accessToSsoSettings"
        :hide-s-s-o-form="disableSso"
        :add-sso-domain="addSsoDomain"
        :delete-domain="deleteDomain"
        :sso-config="ssoConfig"
        :check-sso-url="checkSsoUrl"
        :check-issuer="checkIssuer"
        :check-certificate="checkCertificate"
        :change-all-sso="changeAllSso"
        :add-sso-excluded-email="addSsoExcludedEmail"
        :delete-excluded-email="deleteExcludedEmail"
      />
      <button
        v-if="isShowSsoBtn"
        :class="['main-btn', !accessSsoPricing ? 'upgrade-btn' : '']"
        @click="activateSso"
      >
        {{ accessSsoPricing ? locales('sso_btn_activate') : locales('upgrade_rate_btn') }}
      </button>
    </div>
    <delimiter  v-if="accessToSsoSettings" />
    <div class="settings-section">
      <div class="section-title">
        <div :class="['title-ic check-ic', isActiveTwoFa ? 'active' : '']">
          <webix-ui :config="icon.checkIcon" />
        </div>
        <div class="main-title">
          {{ locales('settings_twofa_title') }}
        </div>
      </div>
      <div
        class="text"
        v-html="locales('settings_twofa_text')"
      />
      <button
        :class="['main-btn', isActiveTwoFa ? 'gray' : '']"
        @click="checkTwoFa"
      >
        {{ isActiveTwoFa ? locales('settings_twofa_disable_button') : locales('settings_twofa_enable_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import ssoModel from '../../../models/sso';
import plansModel from '../../../models/plans';
import pricingHelper, { getPlansObj } from '../../../helpers/pricingHelper';
import rights from '../../../components/rights/index';
import ssoForm from '../include/ssoForm.vue';

// icons
import check_ic from '../../../svg/profile/security/check.svg';
import svgIconForVue from '../../common/svgIconForVue';
import twoFaModel from '../../../models/twoFa';
import Delimiter from '../include/delimiter.vue';

export default {
  components: {
    ssoForm,
    Delimiter,
  },
  data() {
    return {
      locales: __,
      icon: {
        checkIcon: svgIconForVue(check_ic, 24, 24),
      },
      isShowSsoForm: false,
      ssoConfig: {},
      isOwner: '',
    };
  },
  computed: {
    planTitle() {
      return `${pricingHelper.getPlans('sso')}_plan_locale`;
    },
    isShowSsoBtn(){
      if(!rights.account.isOwner() && !this.accessSsoPricing)
        return false;

      return !this.isShowSsoForm && this.accessToSsoSettings ;
    },
    accessSsoPricing() {
      return pricingHelper.checkPricingAccess('sso');
    },
    isActiveTwoFa() {
      return this.$store.getters['profileSettings/twoFaPopup/isActiveTwoFa'];
    },
    accessToSsoSettings() {
      return rights.account.hasRight('sso_settings');
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const isOwner = rights.account.isOwner();

      this.isOwner = isOwner;

      this.setSsoConfig();

      if (user.twoFa && user.twoFa.active) {
        this.$store.commit('profileSettings/twoFaPopup/enableTwoFa', true);
      }
    },
    setSsoConfig() {
      const ssoConfig = {
        domains: [],
        ssoUrl: null,
        issuer: null,
        certificate: null,
        checkboxValue: false,
        excludedEmails: [],
        showEmails: null,
        isError: null,
        isExcludedEmailError: null,
        disableAllSsoCheckbox: false,
      };

      if (user.sso && user.sso.domains && user.sso.domains.length) {
        ssoConfig.domains = user.sso.domains;
      }

      if (user && user.sso && user.sso.enabled) {
        this.isShowSsoForm = true;
      }

      if (user && user.sso) {
        ssoConfig.ssoUrl = user.sso.url;
        ssoConfig.issuer = user.sso.issuer;
        ssoConfig.certificate = user.sso.certificate;
      }

      if (user.sso && user.sso.successful_first_auth === 0) {
        ssoConfig.disableAllSsoCheckbox = true;
      }

      if (user.sso && 'force_team_login_only_sso' in user.sso) {
        ssoConfig.checkboxValue = user.sso.force_team_login_only_sso;
      }

      if (user.sso && user.sso.excluded_emails) {
        ssoConfig.excludedEmails = user.sso.excluded_emails;
        ssoConfig.showEmails = true;
      } else {
        ssoConfig.showEmails = false;
      }

      this.ssoConfig = ssoConfig;
    },
    checkTwoFa() {
      if (user.twoFa && user.twoFa.active) {
        this.$store.dispatch('globalPopup/show', {
          type: 'confirm',
          ok: __('common_yes'),
          cancel: __('common_no'),
          message: __('settings_twofa_disable_confirm_text'),
          buttonWidth: '90px',
          popupType: 'confirm',
          popupWidth: 420,
          confirm: this.disableTwoFa,
        });
      } else {
        this.showTwoFaActivationPopup();
      }
    },
    showTwoFaActivationPopup() {
      this.$store.dispatch('profileSettings/twoFaPopup/loadQR');
      this.$store.dispatch('globalPopup/show', {
        type: 'twoFaPopup',
      });
      userExtAnalytics.log('twofa_activation_popup_open');
    },
    async disableTwoFa() {
      await twoFaModel.disable();
      if (user.twoFa) delete user.twoFa;
      await webix.message({
        type: 'info',
        text: __('settings_twofa_message_disabled'),
        expire: 10000,
      });

      this.$store.commit('profileSettings/twoFaPopup/enableTwoFa', false);
      this.$store.dispatch('globalPopup/close');
    },
    activateSso() {
      if (!pricingHelper.checkPricingAccess('sso') && !plansModel.isOverSubscription()) {
        const newPlanObj = getPlansObj('sso');
        const userPlanId = user.accountPlanData.payment_plans_id;
        const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

        this.$store.dispatch('globalPopup/show', {
          type: 'upgradePlan',
          title: 'upgrade_plan_popup_title',
          currentPlan: userPlan,
          newPlan: newPlanObj,
          // userCount: user.subscription.user_count,
          isAnnual: user.subscription.is_annual,
        });

        return false;
      }
      if (plansModel.isOverSubscription()) {
        this.$store.dispatch('globalPopup/show', {
          type: 'paymentPopup',
        });

        return false;
      }
      userExtAnalytics.log('sso_activation_popup_opened');
      this.isShowSsoForm = true;

      return ssoModel.activate()
        .then(() => this.setSsoConfig());
    },
    disableSso() {
      this.isShowSsoForm = false;
      ssoModel.disable();

      if (user.sso) {
        user.sso = { enabled: false };
      }
    },
    addSsoDomain(value) {
      if (!this.validateDomain(value)) {
        this.ssoConfig.isError = true;

        return false;
      }

      this.$store.dispatch('profileSettings/addDomainSso', value);

      this.ssoConfig.isError = false;
      this.init();
      userExtAnalytics.log('sso_domain_added');
    },
    deleteDomain(id) {
      ssoModel.deleteDomain(id);
      this.init();
    },
    checkSsoUrl(value) {
      const url = value.trim();

      if (url === this.ssoConfig.ssoUrl) {
        return;
      }

      if (user.url !== url) {
        user.sso.url = url;
        this.ssoConfig.ssoUrl = url;
        this.sendToServerToUpdateSSO({
          url,
        });
      }
    },
    checkIssuer(value) {
      const issuer = value.trim();

      if (issuer === this.ssoConfig.issuer) {
        return;
      }

      if (user.issuer !== issuer) {
        user.sso.issuer = issuer;
        this.ssoConfig.issuer = issuer;
        this.sendToServerToUpdateSSO({
          issuer,
        });
      }
    },
    checkCertificate(value) {
      const certificate = value.trim();

      if (certificate === this.ssoConfig.certificate) {
        return;
      }
      if (user.certificate !== certificate) {
        user.sso.certificate = certificate;
        this.ssoConfig.certificate = certificate;
        this.sendToServerToUpdateSSO({
          certificate,
        });
      }
    },
    async changeAllSso(val, isDisable) {
      if (isDisable) {
        return;
      }
      const newVal = val === 1 ? 0 : 1;

      await this.sendToServerToUpdateSSO({
        force_team_login_only_sso: newVal,
      });
      await this.init();
      this.ssoConfig.checkboxValue = newVal;
      user.sso.force_team_login_only_sso = newVal;
    },
    sendToServerToUpdateSSO(data) {
      this.$store.dispatch('profileSettings/sendToServerToUpdateSSO', data);
    },
    addSsoExcludedEmail(val) {
      this.ssoConfig.isExcludedEmailError = false;

      const validName = this.validateEmail(val)
        || this.validateDomain(val)
        || val === '*';

      if (!validName) {
        this.ssoConfig.isExcludedEmailError = true;

        return false;
      }

      this.saveSsoExcludedEmail(val);
    },
    async saveSsoExcludedEmail(val) {
      const name = await ssoModel.addExcludedEmail(val);

      if (name === 0) {
        webix.message({
          type: 'error',
          text: __('sso_error_text_when_excluded_emails_repeated'),
          expire: 10000,
        });

        return false;
      }
      await this.init();
    },
    deleteExcludedEmail(id) {
      ssoModel.deleteExcludedEmail(id);
      this.init();
    },
    validateEmail(email) {
      const pattern = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/;

      return pattern.test(email);
    },
    validateDomain(domain) {
      const pattern = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;

      return pattern.test(domain);
    },
  },
};
</script>

<style scoped src="../less/profileSettings.less"></style>
