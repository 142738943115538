<template>
  <div
    :class="[$style.sideBar, blockSideBar ? $style.blocked : '']"
    @click.capture="clickAllSideBar"
  >
    <div
      v-if="!isOpenProfileSettings"
      id="leftSideBar"
      :class="[$style.leftSideBar, isOpenSideBar ? $style.open : '', !isOpenSideBar && showIcon ? $style.clickable : '']"
      @mouseover="handleMouseOver"
      @mouseleave="showIcon=false"
      @click="handleClickSidebar"
    >
      <div ref="header">
        <sidebar-header
          :is-open-side-bar="isOpenSideBar"
          :show-icon="showIcon"
          :team-label="teamLabel"
          :user-rights="userRights"
          :add-project-click="addProjectClick"
          :position-context="positionContext"
          :menu="menu"
          :active-item="activeItem"
          :block-header-click="blockHeaderClick"
          :items-for-context="itemsForContext"
          :team-settings-access="accountRolesHasTeamSettingAccess"
          :unread-count="unreadCommentsCount"
          :mention="hasMentionComments"
          @clickSideBar="clickSideBar"
          @addProjectClickBtn="addProjectClickBtn"
          @onSelectItemMenu="onSelectItemMenu"
          @onClickOutside="onClickOutside"
          @clickMenuItem="clickMenuItem"
          @teamSettings="teamSettings"
        />
      </div>

      <favorite-projects
        :is-open-side-bar="isOpenSideBar"
        :fav-projects="favProjects"
        :open-fav-projects="openFavProjects"
        :fav-height="favProjectsHeight"
        :active-item="activeItem"
        @hideShowFavProjects="hideShowFavProjects"
        @projectClick="projectClick"
      />

      <!--footer-->
      <div
        ref="footer"
        :class="[$style.footer_items]"
      >
        <upgrade-button
          :is-open-side-bar="isOpenSideBar"
          :user-rights="userRights"
          :is-show-upgrade-popup="isShowUpgradePopup"
          @upgradeClick="upgradeClick"
        />
        <div
          :style="{marginTop: isShowUpgradePopup ? '' : '24px'}"
          :class="$style.menu"
        >
          <div
            v-for="(item, index) in getFooterItems"
            :key="index"
          >
            <menu-item
              :config="item"
              :is-open-side-bar="isOpenSideBar"
              @clickMenuItem="clickMenuItem"
            />
          </div>
        </div>
      </div>
    </div>

    <project-list-popup
      v-if="isOpenAllProjects"
      :user-rights="userRights"
      :multiselect-mode-from-sidebar="multiselectMode"
      :open="isOpenAllProjects"
      :multiselect-project="multiselectProject"
      :items-for-context="itemsForContext"
      :active-item="getActiveProject()"
      @sideBarBack="sideBarBackProjects"
      @clickFavProject="clickFavProject"
    />
    <profile-menu
      v-if="isOpenProfileSettings"
      @sideBarBack="sideBarBack"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import routerHelper from '../../helpers/router';
import app, { appIds } from '../../app';
import menuItem from './includes/sidebarMenuItem.vue';
import profileMenu from './includes/profileMenu.vue';
import ProjectListPopup from './includes/projectList.vue';
import globalStore from '$$store/main';
import projectComponent from '../../components/Project';
import notificationsPopup from '../userNotifications/main';
import plansModel from '../../models/plans';
// import upgradePopup from '../payment/upgradePopup';
import ganttViewModel from '../../models/ganttViewModel';
import pricingHelper from '../../helpers/pricingHelper';
import SidebarHeader from './includes/sidebarHeader.vue';
import UpgradeButton from './includes/upgradeButton.vue';
import FavoriteProjects from './includes/favoriteProjects.vue';
import leftSideBarMenu from './includes/leftSideBarMenu';
import viewModes from '../../helpers/viewModes';
import projectsModel from '../../models/projects';
// import resourceCalendar from '../calendar/resourceCalendar';
import multiViewsProjects from '../../models/multiViewsProjects';
import rights from '../../components/rights';

const {
  popupViews: { ID_VIEW_POPUP_GLOBALSEARCH },
} = appIds;
const WIDTH_WIDE = 260;
const WIDTH_TIDE = 40;

export default {
  name: 'LeftSidebarVue',
  components: {
    FavoriteProjects,
    UpgradeButton,
    SidebarHeader,
    ProjectListPopup,
    menuItem,
    profileMenu,
  },

  data() {
    return {
      isOpenSideBar: true,
      showIcon: false,
      locale: __,
      menu: [],
      footerMenu: [],
      width: 0,
      isOpenProfileSettings: false,
      isOpenAllProjects: false,
      addProjectClick: false,
      positionContext: null,
      multiviewItem: {
        name: 'project_popup_menu_select_multiple',
        id: 'select_multiple',
        icon: 'portfolio',
        feature: 'portfolio_view',
        gpTestId: 'gp_autotest_sidebar_context_menu_create_multiview',
        pricingAccess: pricingHelper.checkPricingAccess('portfolio_view'),
        planName: `${pricingHelper.getPlans('portfolio_view')}_plan_locale`,
        iconType: 'regular',
        iconSize: 24,
      },
      newProjectItem: {
        name: 'create_new_project',
        id: 'newProjectItem',
        icon: 'file',
        gpTestId: 'gp_autotest_sidebar_context_menu_create_project',
        iconType: 'regular',
        iconSize: 24,
      },
      favProjects: [],
      openFavProjects: true,
      favProjectsHeight: 0,
      multiselectMode: false,
      notificationsCount: 0,
      activeItem: null,
      multiselectProject: null,
      blockHeaderClick: false,
      routeBeforeSettings: null,
      userRights: {},
      teamLabel: {},
      projects: [],
      triggerRerender: false,
      projectsMapById: {},
      taskResourcesMap: {},
      commentsActionsMap: {},
      unreadCommentsCount: 0,
      hasMentionComments: false,
    };
  },
  computed: {
    blockSideBar() {
      return globalStore.getters.blockSideBar;
    },
    isUserActive() {
      return plansModel.isActive();
    },
    isShowUpgradePopup() {
      const isTrial = plansModel.isTrial();
      const isActive = plansModel.getStatus() > 0;
      // const hasCustomPlanToUpgrade = plansModel.hasCustomPlanToUpgrade();

      return (isActive && isTrial);// || hasCustomPlanToUpgrade;
    },
    isActive() {
      const route = this.$store.getters['headerView/currentRoute'];

      if (!route.path) return;

      if (this.isOpenAllProjects) {
        if (this.config.mainPath === 'projectsPopup') {
          return true;
        }
        if (route.path.startsWith('/project')) {
          const id = route.params.projectId || route.params.multiviewID;

          return +id === this.config.id;
        }

        return false;
      }

      if (route.path.startsWith('/project')) {
        const id = route.params.projectId || route.params.multiviewID;

        return +id === this.config.id;
      }

      return route.path.startsWith(this.config.mainPath);
    },
    accountRolesHasTeamSettingAccess() {
      return rights.account.hasRight('team_settings');
    },
    itemsForContext() {
      const items = [];

      if (rights.account.hasRight('project_create')) {
        items.push(this.newProjectItem);
      }
      if (rights.account.hasRight('multiview_all_features')) {
        items.push(this.multiviewItem);
      }

      return items;
    },
    getFooterItems() {
      // const count = this.$store ? this.$store.getters['userNotification/getNotificationsCount'] : 44;
      const notificationWelcome = globalStore.getters['userNotification/getNotificationsSettings'].notification_welcome;
      const count = globalStore.getters['userNotification/getNotificationsCount'] || notificationWelcome;

      return this.footerMenu.map(item => {
        if (item.id === 'notifications') {
          return { ...item, ...{ notificationsCount: count } };
        }

        return item;
      });
    },
    comments() {
      return globalStore.getters['comments/getAllCommentsFull'];
    },
    commentsActions() {
      return globalStore.state.comments.commentsActions;
    },
    // projects() {
    //   return projectsModel.getAllProjects();
    // },
    currentResource() {
      return globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
    },
    // calcUnreadCount() {
    //   let counter = 0;

    //   if (globalStore.state.comments.isFetching) return;

    //   const triggerRerender = this.triggerRerender;
    //   const random = Math.ceil(Math.random() * 100000000);

    //   console.time(`calcUnreadCount ${random}`);
    //   this.comments.forEach(item2 => {
    //     const comment = this.commentsActionsMap[item2.id];

    //     let accessToTasks = false;

    //     if ((this.projectsMapById[item2.projectId] || item2.projectId === this.restoredId)
    //         && (this.taskResourcesMap[`${item2.taskId}_${this.currentResource.id}`] || rights.project.hasRight(item2.projectId, 'all_tasks'))) {
    //       accessToTasks = true;
    //     }

    //     let correctProject;

    //     if (this.archivedId) {
    //       correctProject = this.archivedId && item2.projectId === this.archivedId ? false : !projectsModel.isArchived(item2.projectId);
    //     } else if (this.restoredId) {
    //       correctProject = this.restoredId && item2.projectId === this.restoredId ? true : !projectsModel.isArchived(item2.projectId);
    //     } else {
    //       correctProject = !projectsModel.isArchived(item2.projectId);
    //     }

    //     if (correctProject && comment?.read === 0 && accessToTasks) counter++;
    //   });
    //   console.timeEnd(`calcUnreadCount ${random}`);

    //   return counter;
    // },
    // checkMention() {
    //   let mention = false;

    //   if (globalStore.state.comments.isFetching) return;
    //   const triggerRerender = this.triggerRerender;

    //   this.comments.some(item2 => {
    //     const comment = this.commentsActionsMap[item2.id];

    //     let accessToTasks = false;

    //     if (this.projectsMapById[item2.projectId]
    //         && (this.taskResourcesMap[`${item2.taskId}_${this.currentResource.id}`] || rights.project.hasRight(item2.projectId, 'all_tasks'))) {
    //       accessToTasks = true;
    //     }
    //     if (item2.content.includes(`[~${user.id}]`) && comment?.read === 0 && accessToTasks) mention = true;
    //   });

    //   return mention;
    // },
  },

  watch: {
    comments() {
      this.updateCommentsCounts();
    },
    commentsActions() {
      this.updateCommentsCounts();
    },
  },

  mounted() {
    setTimeout(() => {
      this.calcFavProjectsHeight();

      this.updateUserRights();
    }, 100);

    window.addEventListener('resize', this.resizeFavProjectsHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFavProjectsHeight);
  },
  beforeCreate() {
    const component = this;

    app.on('app:initModels', () => {
      component.initData();
      setTimeout(() => {
        const allProjects = this.projects.map(project => project.id);

        globalStore.dispatch('comments/fetchCommentsByProjectIds', { projectIds: allProjects });
        globalStore.dispatch('comments/fetchCommentsActions');
      }, 100);
    });
    app.on('rolesModel:init', () => {
      this.updateUserRights();
    });
    app.on('app:route:after:changed', () => {
      this.setActiveItem();
    });
  },
  created() {
    this.updateTeamLabel();

    app.on('user:noProject', () => {
      this.updateUserRights();
      this.updateConfigMenu();
    });
    app.on('created:first:project', () => {
      this.updateConfigMenu();
    });
    app.on('sideBar:update:favorite', () => {
      this.setFavoriteProjects();
    });
    app.on('userNotifications:updateReadCount', num => {
      this.setNotificationsCount({
        count: num,
        isUp: false,
      });
    });

    app.on('userNotifications:addReadCount', num => {
      this.setNotificationsCount({
        count: num,
        isUp: true,
      });
    });

    // app.on('userNotifications:new', () => {
    //   this.setNotificationsCount({ count: 1, isUp: true });
    // });
    app.on('editMultiview', viewData => {
      this.multiselectMode = true;
      this.isOpenAllProjects = true;
      this.multiselectProject = viewData;
    });
    app.on('user:changedRole', () => {
      this.updateUserRights();
      this.updateConfigMenu();
    });
    app.on('userProfile:update', () => {
      this.updateTeamLabel();
    });

    app.on('refreshLogo', () => {
      this.updateTeamLabel();
    });

    app.on('sideBar:closeProjects', () => {
      this.sideBarBackProjects();
    });

    app.on('created:first:project', () => {
      this.projects = projectsModel.getAllProjects();
    });

    app.on('removed:last:project', () => {
      // console.log('removed:last:project');
      this.projects = projectsModel.getAllProjects();
    });

    const cnt = this;

    app.on('app:route:changed', value => {
      cnt.updateConfigMenu();

      const leftSideBar = $$('leftSideBar');

      if (value?.params?.mode === 'export') {
        leftSideBar.$view.classList.add('left_sidebar_down_index');
      } else {
        leftSideBar.$view.classList.remove('left_sidebar_down_index');
      }
    });

    if (app.config.mode.isBase) {
      multiViewsProjects.data.attachEvent('removeMultiview', () => {
        app.trigger('sideBar:update:favorite');
      });

      multiViewsProjects.data.attachEvent('onDataUpdate', () => {
        app.trigger('sideBar:update:favorite');
      });

      app.on('onAfterCollaboration', data => {
        const projectEvents = ['ProjectUpdated', 'ProjectRestored', 'ProjectArchived', 'ProjectDeleted', 'ResourceFromProjectsUnassigned', 'ResourceToProjectsAssigned'];

        if (projectEvents.includes(data.event)) {
          this.projects = projectsModel.getAllProjects();
          app.trigger('sideBar:update:favorite');
        }
        if (data.event === 'ResourceOnProjectsUpdated' || data.event === 'TaskResourceUnassigned' || data.event === 'TaskResourceAssigned') {
          this.triggerRerender = !this.triggerRerender;
        }

        this.updateCommentsCounts();
      });

      app.on('refresh:favoriteList', () => {
        this.setFavoriteProjects();
      });
    }
  },
  methods: {
    updateCommentsCounts() {
      this.unreadCommentsCount = Object.keys(globalStore.state.comments.taskCommentsMetaData).reduce((count, taskId) => {
        const data = globalStore.state.comments.taskCommentsMetaData[taskId];
        const accessToTask = globalStore.getters['tasksModel/checkAccessToTask'](taskId, data.projectId);
        const isArchived = projectsModel.isArchived(data.projectId);
        const project = projectsModel.getProjectDataById(data.projectId);

        if (data && accessToTask && !isArchived && project) {
          count += data.unreadComments.size;
        }

        return count;
      }, 0);
      this.hasMentionComments = Object.keys(globalStore.state.comments.taskCommentsMetaData).some(taskId => {
        const data = globalStore.state.comments.taskCommentsMetaData[taskId];
        const accessToTask = globalStore.getters['tasksModel/checkAccessToTask'](taskId, data.projectId);
        const isArchived = projectsModel.isArchived(data.projectId);
        const project = projectsModel.getProjectDataById(data.projectId);

        return accessToTask && !isArchived && project && data?.unreadMentions.size;
      });
    },
    resizeFavProjectsHeight() {
      this.calcFavProjectsHeight();
    },
    calcFavProjectsHeight() {
      if (this.isOpenSideBar) {
        this.favProjectsHeight = 0;
        const header = this.$refs.header?.getBoundingClientRect();
        const footer = this.$refs.footer?.getBoundingClientRect();

        const clientHeight = document.body.clientHeight;

        this.favProjectsHeight = clientHeight - header?.height - footer?.height - 50;
      }
    },
    updateTeamLabel() {
      this.teamLabel = {
        logo: user.team.logo,
        name: user.team.name,
        userPhoto: user.photo,
        favicon: (user.team.show_favicon) ? user.team.favicon : null,
        showFavicon: !!user.team.show_favicon,
      };
    },
    initData() {
      const sideBarCookie = JSON.parse(this.getCookie('leftSideBarWideMode'));
      const favoriteCookie = JSON.parse(this.getCookie('favoriteListMode'));

      this.projects = projectsModel.getAllProjects();
      if (sideBarCookie) {
        this.width = WIDTH_WIDE;

        this.isOpenSideBar = true;
      } else if (sideBarCookie !== false && sideBarCookie !== true) {
        this.setCookie('leftSideBarWideMode', true, 30);

        this.width = WIDTH_WIDE;

        this.isOpenSideBar = true;
      } else {
        this.width = WIDTH_TIDE;
        this.isOpenSideBar = false;
      }
      if (favoriteCookie) {
        this.openFavProjects = true;
      } else if (favoriteCookie !== false && favoriteCookie !== true) {
        this.setCookie('favoriteListMode', true, 30);

        this.openFavProjects = true;
      } else {
        this.openFavProjects = false;
      }

      globalStore.commit('setLeftSidebarWidth', this.width);

      this.setFavoriteProjects();
      this.updateConfigMenu();
      this.setSideBarWidth();

      plansModel.isResourcesMoreThanPaid() && this.closeSidebar();
    },

    closeSidebar() {
      this.isOpenSideBar = false;
      this.width = WIDTH_TIDE;

      this.setCookie('leftSideBarWideMode', this.isOpenSideBar, 30);
      this.setActiveItem();
    },
    clickSideBar() {
      let flag;

      this.isOpenSideBar = !this.isOpenSideBar;
      if (this.isOpenSideBar) {
        this.width = WIDTH_WIDE;
        flag = true;
      } else {
        this.width = WIDTH_TIDE;
        flag = false;
      }

      globalStore.commit('setLeftSidebarWidth', this.width);
      this.resizeAll();
      this.setCookie('leftSideBarWideMode', flag, 30);
      this.setActiveItem();
    },
    setSideBarWidth() {
      globalStore.commit('setLeftSidebarWidth', this.width);
      this.resizeAll();
    },
    updateWidthWebixWrap() {
      const $$sidebar = $$('leftSideBar');

      $$sidebar.define('width', this.width);
      $$sidebar.resize();
    },
    setCookie(name, value, days) {
      let expires = '';

      if (days) {
        const date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
      }
      document.cookie = `${name}=${value}${expires}; path=/`;
    },
    getCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }

      return null;
    },
    resizeAll() {
      this.updateWidthWebixWrap();

      setTimeout(() => {
        this.calcFavProjectsHeight();
      }, 100);

      if (gantt.config.gantt_id || gantt.config.multiview) {
        gantt.callEvent('ganttRender');
        app.trigger('resizeWorkload');
      }

      app.trigger('leftSideBar:changedMode');
    },
    clickMenuItem(path, id) {
      this.updateRouteBeforeSettings();
      this.onClickOutside();

      if (id === 'settings') {
        gantt.callEvent('hideResourcesListForTask');
      }
      if (id === 'allProjects') {
        this.isOpenAllProjects = true;
      }
      if (id === 'listView' || id === 'timelog' || id === 'reports') {
        if (!this.projects.length) {
          webix.message({ type: 'info', text: __('tabs_msg_create_first_project') });

          return;
        }
      }
      if (id === 'search') {
        this.searchClickBtn();
      }
      if (id === 'notifications') {
        this.notifyClickBtn();
      }

      if (id !== 'search' && id !== 'notifications') {
        routerHelper.changeRoute(path);

        const currentRoute = routerHelper.getCurrentRoute();

        if (currentRoute.name === 'newProject' || currentRoute.name === 'importproject' || currentRoute.name === 'jiraproject') {
          this.activeItem = null;

          return;
        }

        this.activeItem = id;
      }
    },
    sideBarBack() {
      const currentRoute = routerHelper.getCurrentRoute();

      if (this.routeBeforeSettings && this.routeBeforeSettings.includes('/integration')) {
        routerHelper.changeRoute('/');
      } else if (this.routeBeforeSettings) {
        routerHelper.changeRoute(this.routeBeforeSettings);
      } else if (currentRoute.path.includes('/settings')) {
        routerHelper.changeRoute('/');
      } else {
        routerHelper.backRoute();
      }
      this.routeBeforeSettings = null;
      this.resizeAll();
    },
    sideBarBackProjects() {
      this.isOpenAllProjects = false;
      this.multiselectMode = false;
      this.multiselectProject = null;
      this.setActiveItem();
    },
    notifyClickBtn() {
      if (!notificationsPopup.isOpened()) {
        notificationsPopup.show();
      }
    },
    searchClickBtn() {
      $$(ID_VIEW_POPUP_GLOBALSEARCH).show();
      userExtAnalytics.log('global_search_window_show');
    },
    onSelectItemMenu(id) {
      this.addProjectClick = false;
      const isShowPaymentPage = globalStore.getters['paymentPlans/isShowPaymentPage'];
      const currentRoute = routerHelper.getCurrentRoute();

      switch (id) {
      case 'newProjectItem':
        if (
          (currentRoute.name === 'newProject' || currentRoute.name === 'importproject' || currentRoute.name === 'jiraproject')
            && isShowPaymentPage
        ) {
          globalStore.commit('paymentPlans/close');
        }
        routerHelper.pushRoute({ name: 'newProject' });
        userExtAnalytics.log('project_create_open');
        break;
      case 'select_multiple':
        routerHelper.pushRoute({
          name: 'portfolio',
          query: {
            createNew: true
          }
        });
        break;
      }
    },
    onClickOutside() {
      this.addProjectClick = false;
    },
    addProjectClickBtn() {
      this.projects = projectComponent.getProjectsList();
      const sortedProjects = this.projects.filter(project => !!project.gantt_id && !project.is_jira);

      this.addProjectClick = true;
      this.multiviewItem.disable = sortedProjects.length < 1;
    },
    upgradeClick() {
      // if (plansModel.hasCustomPlanToUpgrade()) {
      //   upgradePopup.handlers.showUpgradePopup();
      // } else {
      //   resourceCalendar.hide();
        globalStore.commit('paymentPlans/show', null, { root: true });
      // }
    },
    setFavoriteProjects() {
      const favorite = ganttViewModel.getFavorite();
      const multiviewAccess = pricingHelper.checkPricingAccess('portfolio_view');
      let currentFavorite = [];

      currentFavorite = favorite;

      if (!multiviewAccess) {
        currentFavorite = currentFavorite.filter(pr => pr.config && !pr.config.multiview);
      }

      if (!pricingHelper.checkPricingAccess('jira_cloud')) {
        currentFavorite = currentFavorite.filter(project => !project.is_jira);
      }
      this.favProjects = currentFavorite;
      this.setActiveItem();
    },
    hideShowFavProjects() {
      this.openFavProjects = !this.openFavProjects;
      this.setCookie('favoriteListMode', this.openFavProjects, 30);
      setTimeout(() => {
        this.calcFavProjectsHeight();
      }, 100);
    },
    getActiveProject() {
      const route = globalStore.getters['headerView/currentRoute'];

      if (!route.path) return;

      if (route.path.startsWith('/project')) {
        const id = route.params.projectId || route.params.multiviewID;

        this.projectOpen = id;
      }

      return this.projectOpen;
    },
    projectClick(project) {
      const mainPath = (project.ganttIDs) ? '/projects/' : '/project/';
      const isShowPaymentPage = globalStore.getters['paymentPlans/isShowPaymentPage'];
      const activeProject = ganttViewModel.getActiveProjectData();

      if (
        (routerHelper.isSingleProjectRoute() || routerHelper.isMultiProjectsRoute())
          && activeProject
          && activeProject.id === +project.id
          && routerHelper.getCurrentRoute().params.mode === 'gantt'
          && isShowPaymentPage
      ) {
        globalStore.commit('paymentPlans/close');
      }
      routerHelper.pushRoute(`${mainPath + project.id}/gantt`);
    },
    clickFavProject() {
      this.setFavoriteProjects();
    },
    setNotificationsCount({ count, isUp }) {
      // this.notificationsCount = isUp ? (this.notificationsCount + count) : count;

      this.footerMenu = this.footerMenu.map(item => {
        if (item.id === 'notifications') {
          return { ...item, ...{ notificationsCount: this.getNotificationsCount } };
        }

        return item;
      });
    },
    setActiveItem() {
      const currentRoute = routerHelper.getCurrentRoute();
      const favorite = ganttViewModel.getFavorite();

      if (currentRoute.path.includes('/settings')) {
        this.isOpenProfileSettings = true;
        this.width = WIDTH_WIDE;
      } else {
        this.isOpenProfileSettings = false;
        this.width = this.isOpenSideBar ? WIDTH_WIDE : WIDTH_TIDE;
      }
      this.updateRouteBeforeSettings();
      this.updateWidthWebixWrap();
      globalStore.commit('setLeftSidebarWidth', this.width);

      if (currentRoute.path.includes('/project') || currentRoute.path.includes('/projectsMultiView')) {
        const id = currentRoute.params.projectId || currentRoute.params.multiviewID;

        const fromFavorite = favorite.find(item => item.id === +id);

        if (this.isOpenSideBar && fromFavorite) {
          this.activeItem = id;
        } else {
          this.activeItem = 'allProjects';

          if (currentRoute.name === 'projectsMultiView') {
            this.activeItem = 'portfolio';
          }
        }
      } else {
        const headerItem = leftSideBarMenu.getMenuHeaderItems().find(item => {
          if (currentRoute.path.startsWith(item.mainPath)) {
            return item.mainPath;
          }
        });

        if (currentRoute.name === 'newProject' || currentRoute.name === 'importproject' || currentRoute.name === 'jiraproject') {
          this.activeItem = null;

          return;
        }
        this.activeItem = headerItem ? headerItem.id : null;
      }
    },
    updateConfigMenu() {
      let menu = [];
      const currentRoute = routerHelper.getCurrentRoute();

      if (viewModes.overTrialOrSubscription() || (viewModes.noProjectMode() && !rights.account.hasRight('project_create')) || [2, 11].includes(user.subscription.status_code)) {
        this.menu = menu;
        this.isOpenSideBar = false;
        this.width = !currentRoute.path.includes('/settings') ? WIDTH_TIDE : WIDTH_WIDE;
        this.resizeAll();
        this.blockHeaderClick = true;
        globalStore.commit('setLeftSidebarWidth', this.width);

        this.footerMenu = leftSideBarMenu.getMenuFooterItems().map(item => {
          if (item.id === 'notifications') {
            item.hidden = true;
          } if (item.id === 'search') {
            item.hidden = true;
          }

          return item;
        });

        return;
      }

      const accessToWorkload = projectsModel.getAllProjects().some(o => rights.project.hasRight(o.gantt_id, 'workload'));
      const isResourcesMoreThanPaid = plansModel.isResourcesMoreThanPaid();
      const hasReportsRights = rights.account.hasRight('reports');

      menu = leftSideBarMenu.getMenuHeaderItems().map(item => {
        if (isResourcesMoreThanPaid) {
          item.hidden = true;
        }
        if (item.id === 'allProjects' && !this.isUserActive) {
          item.hidden = true;
        }
        if (item.id === 'portfolio' && !this.isUserActive) {
          item.hidden = true;
        }
        if (item.id === 'listView' && !this.isUserActive) {
          item.hidden = true;
        }
        if (item.id === 'myTimeLog' && !this.isUserActive) {
          item.hidden = true;
        }
        if (item.id === 'reports' && !hasReportsRights) {
          item.hidden = true;
        }
        if (item.id === 'workload' && !accessToWorkload) {
          item.hidden = true;
        }
        item.hidden = (item.id === 'allProjects' || item.id === 'listView' || item.id === 'myTimeLog') && !this.isUserActive
          ? true
          : (item.id === 'reports' && !hasReportsRights)
            ? true
            : (item.id === 'workload' && !accessToWorkload)
              ? true
              : (item.id === 'portfolio' && (!this.isUserActive || !rights.account.hasRight('multiview_all_features')));

        return item;
      });
      this.footerMenu = leftSideBarMenu.getMenuFooterItems().map(item => {
        if (item.id === 'notifications') {
          item.hidden = !this.isUserActive;
          item.notificationsCount = this.notificationsCount;
        }
        if (item.id === 'search' && !this.isUserActive) {
          item.hidden = true;
        }

        if (isResourcesMoreThanPaid && item.id !== 'settings') {
          item.hidden = true;
        }

        return item;
      });

      this.menu = menu;
      this.blockHeaderClick = false;

      if (isResourcesMoreThanPaid) {
        this.blockHeaderClick = true;
        this.closeSidebar();
      }

      setTimeout(() => {
        this.calcFavProjectsHeight();
      }, 100);
    },
    updateUserRights() {
      this.userRights = {
        owner: rights.account.isOwner(),
        projectAccess: rights.account.hasRight('project_create'),
      };
    },
    teamSettings() {
      if (!this.accountRolesHasTeamSettingAccess) return;
      const currentRoute = routerHelper.getCurrentRoute();

      if (currentRoute.params.type === 'team') {
        return;
      }
      this.updateRouteBeforeSettings();
      routerHelper.changeRoute('/settings/team');
    },
    updateRouteBeforeSettings() {
      const currentRoute = routerHelper.getCurrentRoute();

      const attachRoute = currentRoute.path.includes('/attachments');
      const settingtRoute = currentRoute.path.includes('/settings');

      if (!attachRoute && !settingtRoute) {
        this.routeBeforeSettings = currentRoute.path;
      }
    },
    clickAllSideBar(e) {
      if (this.blockSideBar) {
        e.stopPropagation();
      }
    },
    handleMouseOver(e) {
      if (viewModes.overTrialOrSubscription() || (viewModes.noProjectMode() && !rights.account.hasRight('project_create')) || [2, 11].includes(user.subscription.status_code)) {
        return;
      }
      this.showIcon = e.target.classList.contains(this.$style.leftSideBar)
        || e.target.classList.contains(this.$style.btns)
        || e.target.classList.contains(this.$style.footer_items)
        || e.target.classList.contains(this.$style.header_icons) || e.target.closest(`.${this.$style.header_icons}`);
    },
    handleClickSidebar() {
      if (plansModel.isResourcesMoreThanPaid()) { return; }
      if (!this.isOpenSideBar && this.showIcon) this.clickSideBar();
    },
    // prepareMapsData() {
    //   const tasks = globalStore.getters['tasksModel/tasksByIds'];

    //   console.log('comments', this.comments.length);
    //   console.log('tasks', Object.keys(tasks).length);
    //   console.log('projects', this.projects.length);
    //   console.log('commentsActions', this.commentsActions.length);

    //   this.projectsMapById = _.groupBy(this.projects, 'id');

    //   this.taskResourcesMap = {};

    //   for (const task in tasks) {
    //     tasks[task].resources.forEach(resource => {
    //       if (!this.taskResourcesMap[`${task}_${resource.resource_id}`]) {
    //         this.taskResourcesMap[`${task}_${resource.resource_id}`] = true;
    //       }
    //     });
    //   }

    //   this.commentsActionsMap = this.commentsActions.reduce((result, comment) => {
    //     result[comment.commentId] = comment;

    //     return result;
    //   }, {});
    // },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
