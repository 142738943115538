// Helper functions for work with the url:
// @parseQuery

/**
 * 
 * @param {String} queryString - the source query string from the url
 * @return {Object} - the parsed query as key-value map
 */
function parseQuery(queryString = '') {
  if (queryString[0] === '?') {
    queryString = queryString.substr(1);
  }

  return queryString.split('&').reduce((result, pairString) => {
    const pair = pairString.split('=');

    return {
      ...result,
      [decodeURIComponent(pair[0])]: decodeURIComponent(pair[1]) || ''
    };
  }, {});
}


export { parseQuery };