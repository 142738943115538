<template>
  <div>
    <integration-page
      :integration-name="'outlook'"
      :svg="outlookIcon"
      :links="links"
      :is-first-link="isFirstLink"
      @handleDelete="onOkDeletePopup"
      @firstLinkWasOpen="firstLinkWasOpen"
    />
  </div>
</template>

<script>

import outlookIcon from '../../../svg/integration/settings/outlookCalendarSmall.svg';
import IntegrationPage from '../googleOutlookIntegrations/integrationPage.vue';

export default {
  name: 'OutlookIntegration',
  components: {
    IntegrationPage,
  },
  data() {
    return {
      locale: __,
      outlookIcon,
    };
  },
  computed: {
    links() {
      return this.$store.getters['integrationOutlook/outlookLinks'] || [];
    },
    isFirstLink() {
      return this.$store.getters['integrationOutlook/isFirstLink'] || false;
    },

  },
  methods: {

    onOkDeletePopup(link) {
      this.$store.dispatch('integrationOutlook/deleteLink', link);
      userExtAnalytics.log('integration_outlook_calendar_deleted');
    },
    firstLinkWasOpen() {
      this.$store.commit('integrationOutlook/setFirstLink', false);
    },
  },
};
</script>

<style module="$" src="../googleOutlookIntegrations/googleOutlookIntegration.less"></style>
