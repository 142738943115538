var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-statuses" },
    [
      _c(
        "div",
        { staticClass: "settings-section statuses-section" },
        [
          _c(
            "div",
            { staticClass: "main-title" },
            [
              _c("div", { staticClass: "main-title-text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locale("project_status_locale")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("vgp-button", {
                class: { disabled: _vm.buttonDisable },
                attrs: {
                  label: _vm.locale("project_status_create_new"),
                  type: "primary",
                  icon: { name: "plus-1", type: "bold", size: 20 },
                  "icon-side": "left",
                  small: true,
                  "icon-color": "#fff",
                },
                on: { onClick: _vm.createNewStatus },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locale("project_status_description_locale")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "project-list-label" }, [
            _c("div", [
              _vm._v(_vm._s(_vm.locale("project_status_color_locale"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "project-list-label_name" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.locale("project_status_name_locale")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.statuses, function (status) {
            return _c(
              "div",
              { key: status.id },
              [
                _c(
                  "div",
                  { staticClass: "default_status" },
                  [
                    _c("div", {
                      staticStyle: { width: "46px", padding: "0 10px" },
                    }),
                    _vm._v(" "),
                    _c("status-row", {
                      attrs: {
                        colors: _vm.colors.data,
                        "color-value": [status.options[0].color],
                        "input-value": status.options[0].value,
                        "row-id": status.options[0].id,
                        "is-default": status.options[0].is_default,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.updateStatus(
                            status.id,
                            status.options[0].id,
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "Container",
                  {
                    attrs: {
                      "group-name": "status.id",
                      "drag-handle-selector": ".column-drag-handle",
                      "drag-class": "opacity-ghost",
                      "drop-class": "opacity-ghost-drop",
                      "lock-axis": "y",
                      "auto-scroll-enabled": true,
                    },
                    on: {
                      drop: function ($event) {
                        return _vm.onDrop(status.id, status.options, $event)
                      },
                    },
                  },
                  _vm._l(status.options, function (option) {
                    return _c(
                      "Draggable",
                      { key: option.id, staticClass: "status-list" },
                      [
                        _c(
                          "div",
                          { staticClass: "draggable-item" },
                          [
                            !option.is_default
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "drag-area column-drag-handle",
                                    staticStyle: {
                                      float: "left",
                                      padding: "0 10px",
                                    },
                                  },
                                  [
                                    _c("svg-sprite", {
                                      attrs: { name: "dragdrop" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !option.is_default
                              ? _c("status-row", {
                                  attrs: {
                                    colors: _vm.colors.data,
                                    "color-value": [option.color],
                                    "input-value": option.value,
                                    "row-id": option.id,
                                    "is-default": option.is_default,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateStatus(
                                        status.id,
                                        option.id,
                                        $event
                                      )
                                    },
                                    delete: function ($event) {
                                      return _vm.onDeleteStatus(
                                        status.id,
                                        option.id,
                                        $event
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }