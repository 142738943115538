var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-integrations" },
    [
      _c(
        "div",
        { staticClass: "settings-section" },
        [
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locales("integrations_section_title")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locales("integrations_section_feedback")) +
                "\n      "
            ),
            _c(
              "span",
              { staticClass: "link", on: { click: _vm.createFeedback } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("integrations_create")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.errorConnectMsTeams
            ? _c("inline-notification", {
                staticClass: "notify-block",
                attrs: { type: "warning", text: _vm.errorConnectMsTeams },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "integration-list",
                _vm.errorConnectMsTeams ? "integration-list-width-box" : "",
              ],
            },
            _vm._l(_vm.integrationItems, function (config, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: [
                    "integration-item",
                    _vm.errorConnectMsTeams && config.type === "msteams"
                      ? "error-integration"
                      : "",
                    _vm.errorConnectMsTeams ? "integration-item-width-box" : "",
                  ],
                },
                [
                  _c("div", { staticClass: "item-head" }, [
                    _c(
                      "div",
                      { staticClass: "wrapper" },
                      [
                        _c("webix-ui", {
                          attrs: { config: _vm.icons[config.type] },
                        }),
                        _vm._v(" "),
                        config.type === "jira" && !_vm.pricingAccessToJira
                          ? _c(
                              "div",
                              { staticClass: "pricing-title-container" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.locales(_vm.planTitle)) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    class: ["text"],
                    domProps: { innerHTML: _vm._s(config.text) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-footer" }, [
                    config.type === "jira" &&
                    !_vm.pricingAccessToJira &&
                    _vm.isOwner
                      ? _c(
                          "div",
                          {
                            staticClass: "main-btn upgrade-btn",
                            on: {
                              click: function ($event) {
                                return _vm.upgradePlanClick()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.locales("upgrade_button_label")) +
                                "\n          "
                            ),
                          ]
                        )
                      : config.showBtn
                      ? _c(
                          "div",
                          {
                            class: [
                              "main-btn",
                              config.cssActive ? "main-btn--active" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return config.handler(config)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(config.btnText) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    config.handlerMsTeams
                      ? _c(
                          "div",
                          {
                            class: [
                              "main-btn",
                              config.cssMsTeamsActive
                                ? "main-btn gray help"
                                : "",
                            ],
                            on: {
                              click: function ($event) {
                                return config.handlerMsTeams(config)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(config.btnMsTeamsText) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    config.buttonHelp
                      ? _c(
                          "div",
                          {
                            staticClass: "main-btn gray help",
                            on: {
                              click: function ($event) {
                                return _vm.integrationHelpClick(config.type)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.locales("integrations_help_btn")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.accessToApiAndWebhooks
        ? _c(
            "div",
            [
              _c("delimiter"),
              _vm._v(" "),
              _c("div", { staticClass: "settings-section" }, [
                _c("div", { staticClass: "main-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.locales("settings_api_keys_title")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text",
                  domProps: {
                    innerHTML: _vm._s(_vm.locales("settings_api_keys_text")),
                  },
                }),
                _vm._v(" "),
                _vm.accessToApiAndWebhooks
                  ? _c(
                      "button",
                      {
                        staticClass: "main-btn",
                        attrs: { id: "gp_autotest_api_key_create_button" },
                        on: { click: _vm.apiCreate },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.locales("settings_add_api_key_button")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.apiKeys.length
                  ? _c("div", { staticClass: "api-list list-container" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.locales("settings_api_keys_list_title")
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(_vm.apiKeys, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: [
                                "list-item api-item-" + item.id,
                                "gp_autotest_api_keys_list_item",
                              ],
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "text",
                                    _vm.isCopiedApi === item.id ? "copied" : "",
                                  ],
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.XAPIKey) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "btns" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "copy-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyApiKey(
                                          item.XAPIKey,
                                          item.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.locales(
                                            "settings_integrations_item_copy"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "delete-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteApiKey(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("webix-ui", {
                                      staticClass: "delete-icon",
                                      attrs: { config: _vm.icons.delete },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.accessToApiAndWebhooks
                  ? _c("div", {
                      staticClass: "not-allowed-description",
                      domProps: {
                        innerHTML: _vm._s(_vm.notAllowedDescription),
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("delimiter"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "settings-section" },
                [
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locales("settings_webhooks_title")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(_vm.locales("settings_webhooks_text")),
                    },
                  }),
                  _vm._v(" "),
                  _vm.accessToApiAndWebhooks
                    ? _c("input-with-button", {
                        attrs: {
                          "input-placeholder": _vm.locales(
                            "settings_webhook_form_input_placeholder"
                          ),
                          "button-text": _vm.locales(
                            "settings_webhook_form_input_btn"
                          ),
                          "get-value": _vm.sendToWebhooksModel,
                          "is-error": _vm.isWebhookError,
                          "on-input": _vm.onWebhookInput,
                          "error-text": _vm.locales(
                            "settings_webhook_form_input_error"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.webhooks.length
                    ? _c("div", { staticClass: "list-container" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("settings_webhooks_list_title")
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "list" },
                          _vm._l(_vm.webhooks, function (item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "list-item" },
                              [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.url) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "btns" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteWebhookUrl(item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("webix-ui", {
                                        staticClass: "delete-icon",
                                        attrs: { config: _vm.icons.delete },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.accessToApiAndWebhooks
                    ? _c("div", {
                        staticClass: "not-allowed-description",
                        domProps: {
                          innerHTML: _vm._s(_vm.notAllowedDescription),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isConfirmPopup
        ? _c("core-confirm-popup", {
            attrs: {
              show: _vm.isConfirmPopup,
              "close-icon": false,
              text: _vm.confirmPopupMessage,
              "ok-button-title": _vm.locales("common_ok"),
              "cancel-button": false,
              "popup-width": 440,
              "ok-button": {
                title: _vm.locales("common_ok"),
                type: "primary",
              },
            },
            on: {
              onClickOk: function ($event) {
                return _vm.toggleConfirmDelete(null)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("google-integration-popup", {
        attrs: { show: _vm.googlePopup },
        on: {
          linkCreated: _vm.redirectToGooglePage,
          closePopup: function ($event) {
            _vm.googlePopup = false
          },
        },
      }),
      _vm._v(" "),
      _c("outlook-integration-popup", {
        attrs: { show: _vm.outlookPopup },
        on: {
          linkCreated: _vm.redirectToOutlookPage,
          closePopup: function ($event) {
            _vm.outlookPopup = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }