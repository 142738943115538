import * as CookieConsent from 'vanilla-cookieconsent';

function getBaseDomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  if (parts.length >= 2) {
    const baseDomain = parts.slice(-2).join('.');

    return baseDomain;
  }

  return hostname;
}

function shouldShowPopup() {
  return window.location.pathname.includes('/shared/');
}

CookieConsent.run({
  cookie: {
    name: 'cc_login',
    domain: getBaseDomain(),
    expiresAfterDays: 365,
  },
  autoShow: shouldShowPopup(),
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
      services: {
        calendly: {
          label: 'Calendly',
          onAccept: () => {},
          onReject: () => {},
        },
        liveChatInc: {
          label: 'LiveChat',
          onAccept: () => {},
          onReject: () => {},
        },
        amplitude: {
          label: 'Amplitude',
          onAccept: () => {
          },
          onReject: () => {},
        },
      },
    },
    functional: {
      enabled: false,
      autoClear: {
        cookies: [
          {
            name: '/^.+$/',
            domain: 'accounts.google.com',
          },
        ],
      },
      services: {
        googleSignIn: {
          label: 'Google Sign-In',
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
    analytical: {
      enabled: false,
      autoClear: {
        cookies: [
          {
            name: '/^.+$/',
            domain: 'clarity.ms',
          },
          {
            name: '/^.+$/',
            domain: 'www.clarity.ms',
          },
        ],
      },
      services: {
        clarity: {
          label: 'Clarity',
          onAccept: () => {},
          onReject: () => {},
        },
        bugsnag: {
          label: 'BugSnag',
          onAccept: () => {
            loadBugSnag();
          },
          onReject: () => {},
        },
      },
    },
    advertising: {
      enabled: false,
      autoClear: {
        cookies: [
          { name: 'IDE', domain: 'doubleclick.net' },
          { name: 'AID', domain: 'google.com' },
        ],
      },
      services: {
        googleAds: {
          label: 'Google Ads',
          onAccept: () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'consent_given' });
          },
          onReject: () => {},
        },
      },
    },
  },
  guiOptions: {
    consentModal: {
      layout: 'bar inline',
      position: 'bottom',
      flipButtons: false,
      equalWeightButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'left',
      flipButtons: false,
      equalWeightButtons: true,
    },
  },
  language: {
    default: 'en',
    autoDetect: 'document',
    translations: {
      en: async () => {
        const res = await fetch('https://cdn.ganttpro.com/statics/cookieconsent/en.json');
        return await res.json();
      },
    },
  },
});

function loadAmplitude() {
  var amplitudeScript = document.createElement('script');

  amplitudeScript.src = 'https://cdn.ganttpro.com/statics/media/js/core/amplitude.dont.move.from.header.js';
  amplitudeScript.async = true;

  document.head.append(amplitudeScript);
}

function loadBugSnag() {
  var bugSnagScript = document.createElement('script');

  bugSnagScript.src = 'https://d2wy8f7a9ursnm.cloudfront.net/v7/bugsnag.min.js';
  bugSnagScript.async = true;

  document.head.appendChild(bugSnagScript);
}

loadAmplitude()
