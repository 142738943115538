import constants from '../../helpers/constants';
import moment from '../../libs/moment';
import helpers from './helpers';

export default {
  getAllResources: state => state.data,

  getResourceById: state => resourceId => state.data.find(o => o.id === +resourceId),

  getResourcesByProjectId: (state, getters, rootState) => projectId => {
    return state.data.filter(resourceData => resourceData.resourceProjects.find(o => o.projectId === projectId));
  },

  getResourcesByProjectIdAndType: (state, getters, rootState) => (projectId, type) => {
    return state.data.filter(resourceData => resourceData.resourceProjects.find(o => o.projectId === projectId && o.type === type));
  },

  getResourcesByProjectIdsAndType: (state, getters, rootState) => (projectIds, type) => {
    return state.data.filter(resourceData => resourceData.resourceProjects.find(o => projectIds.includes(o.projectId) && o.type === type));
  },

  getResourcesByProjectIdForUI: (state, getters, rootState) => projectId => {
    const preparedResources = [];

    state.data.forEach(resourceData => {
      const resourceProjectData = resourceData.resourceProjects.find(o => o.projectId === projectId);

      if (resourceProjectData) {
        preparedResources.push({
          id: resourceData.id,
          userId: resourceData.userId,
          name: resourceData.name,
          value: resourceData.name,
          type: resourceProjectData.type,
          price: resourceProjectData.price,
          picture: resourceData.photo || (`${GT.cdn}/imgs/default/default-ava.svg`),
        });
      }
    });

    return _.sortBy(preparedResources,resource => resource.name.toLowerCase());
  },

  getResourceIdByUserId: (state, getters) => userId => {
    let resourceData = state.data.find(o => o.userId === userId);

    return resourceData?.id;
  },

  getResourceByUserId: (state, getters, rootState) => userId => {
    return state.data.find(o => o.userId === userId);
  },

  getResourcesByTaskIdAndGanttId: (state, getters, rootState, rootGetters) => (projectId, taskId) => {
    const taskResources = rootGetters['tasksModel/getResourcesForTask'](projectId, taskId);
    const projectResources = getters.getResourcesByProjectId(projectId);
    const groupByResourceId = _.groupBy(projectResources, 'id');
    const result = [];

    taskResources.forEach(taskResource => {
      const resourceData = groupByResourceId[taskResource.resource_id];

      if (resourceData) {
        result.push(...resourceData);
      }
    });

    return result;
  },

  isUserAssignedToTask: (state, getters) => (projectId, taskId) => {
    const taskResources = getters.getResourcesByTaskIdAndGanttId(projectId, taskId);
    const userResource = getters.getResourceByUserId(user.id);

    return !!taskResources.some(res => res.id === userResource.id);
  },

  //getSettingsForResource
  getResourceProjectSettings: (state, getters) => (resourceId, projectId) => {
    const resourceData = getters.getResourceById(resourceId);

    if (!resourceData) {
      return null;
    }

    return resourceData.resourceProjects?.find(o => o.projectId === +projectId);
  },

  //getAllRealResources
  getAllRealResources: (state, getters) => {
    return state.data.filter(o => !!o.userId);
  },

  //getAllMaterialResources
  getAllMaterialResources: (state, getters) => {
    return state.data.filter(o => !o.userId);
  },

  //getResourceByEmail
  getResourceByEmail: (state, getters) => email => {
    return state.data.find(o => o.email === email);
  },

  //getRealResourcesByGanttId
  getRealResourcesByGanttId: (state, getters) => projectId => {
    return getters.getAllRealResources.filter(o => o.resourceProjects.find(o => o.projectId === projectId));
  },

  //getMaterialResourcesByGanttId
  getMaterialResourcesByGanttId: (state, getters) => projectId => {
    return getters.getAllMaterialResources.filter(o => o.resourceProjects.find(o => o.projectId === projectId));
  },

  //getUnaddedRealResourcesByGanttId
  getUnAddedRealResourcesByGanttId: (state, getters) => projectId => {
    return getters.getAllRealResources.filter(o => !o.resourceProjects.find(o => o.projectId === projectId));
  },

  //getUnaddedMaterialResourcesByGanttId
  getUnAddedMaterialResourcesByGanttId: (state, getters) => projectId => {
    return getters.getAllMaterialResources.filter(o => !o.resourceProjects.find(o => o.projectId === projectId));
  },

  getResourceTypeOnProject: (state, getters) => (resourceId, projectId) => {
    const resourceData = getters.getResourceById(resourceId);

    if (!resourceData) return;

    return resourceData.resourceProjects.find(o => o.projectId === projectId)?.type;
  },

  getResourcesByTypeOnProject: (state, getters) => type => {
    return state.data.filter(r => r.resourceProjects.find(rP => rP.type === type));
  },
};
