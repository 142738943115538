var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: [_vm.$style["comments-list-header"]] }, [
      _vm.taskData && !_vm.showTask
        ? _c(
            "div",
            {
              class: [
                _vm.$style["comments-list-header_label"],
                "gp_autotest_comment_hub_comments_list_header",
              ],
            },
            [
              _c(
                "span",
                { class: [_vm.$style["comments-list-header_label_text"]] },
                [
                  _vm._v(
                    "\n\n        " + _vm._s(_vm.taskData.text) + "\n      "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style["comments-list-header_button"],
            _vm.showTask ? _vm.$style["comments-list-header_button_open"] : "",
            "gp_autotest_comment_hub_comments_list_change_view_button",
          ],
        },
        [
          _vm.taskData
            ? _c("vgp-button", {
                staticClass: "change-view-btn",
                attrs: {
                  type: "text",
                  small: "",
                  label: _vm.locales(
                    !_vm.showTask
                      ? "comment-hub-open-task"
                      : "comment-hub-close-task"
                  ),
                },
                on: { onClick: _vm.changeView },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.$style["comments-list-body"],
          _vm.showTask ? _vm.$style["comments-list-body-with-task"] : "",
          _vm.taskData ? "" : _vm.$style["comments-list-body-without-task"],
        ],
      },
      [
        _c("div", [
          !_vm.showTask && !_vm.taskData
            ? _c(
                "div",
                {
                  class: [
                    _vm.$style["no-selected-task-stub"],
                    _vm.$style["static-comments-section"],
                    "gp_autotest_comment_hub_comments_list_no_selected_task_stub",
                  ],
                },
                [
                  _c("svgImage", {
                    class: [_vm.$style["current-stub"]],
                    attrs: { name: "comment-hub-no-selected-task" },
                  }),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$style["no-search-text"]] }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.stubTitle) + "\n        "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.taskId && _vm.taskData
          ? _c(
              "div",
              {
                ref: "taskSettingsBodySection",
                staticClass: "gantt_scroll",
                style: { height: _vm.currentHeight + "px" },
                on: { scroll: _vm.handleTaskSettingsBodyScroll },
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      !_vm.showTask ? _vm.$style["task-settings"] : "",
                      _vm.isShifted ? _vm.$style["element-shifted"] : "",
                      "gp_autotest_comment_hub_comments_list_task_settings",
                    ],
                  },
                  [
                    _c("task-settings-main", {
                      attrs: {
                        "task-id": _vm.taskId,
                        height: _vm.height,
                        "hide-for-hub": !_vm.showTask,
                        "comment-hub": true,
                      },
                      on: { comment: _vm.setCommentEditor },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.taskId && !_vm.comments.length
                  ? _c(
                      "div",
                      {
                        ref: "taskSettingsStubSection",
                        class: [
                          _vm.$style["static-comments-section"],
                          _vm.$style["no-selected-task-stub"],
                          _vm.isShifted ? _vm.$style["settings-main"] : "",
                          "gp_autotest_comment_hub_comments_list_no_comments_in_task_stub",
                        ],
                        style: { width: "100%", height: "100%" },
                      },
                      [
                        _c("svgImage", {
                          class: [_vm.$style["current-stub"]],
                          attrs: { name: "comment-hub-no-selected-task" },
                        }),
                        _vm._v(" "),
                        _c("div", { class: [_vm.$style["no-search-title"]] }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales(
                                  "comment-hub-no-comments-in-task-title"
                                )
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: [_vm.$style["no-search-text"]] }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("comment-hub-no-comments-in-task")
                              ) +
                              "\n        "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.comments.length
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.$style["static-comments-section_list"],
                          _vm.$style["static-comments-section"],
                          _vm.isShifted ? _vm.$style["settings-main"] : "",
                        ],
                        style: {
                          width: "100%",
                          height:
                            _vm.longComments ||
                            (!_vm.longComments && !_vm.isShifted)
                              ? "100%"
                              : "",
                        },
                      },
                      [
                        _c("comments-list", {
                          ref: "taskSettingsCommentsSection",
                          class: [
                            _vm.longComments
                              ? ""
                              : _vm.$style["static-comments-section_short"],
                            "gp_autotest_comment_hub_comments_list",
                          ],
                          attrs: {
                            comments: _vm.comments,
                            "task-data": _vm.taskData,
                            "task-id": _vm.taskId,
                            "show-task": _vm.showTask,
                          },
                          on: {
                            "delete-comment": _vm.handleDeleteComment,
                            "comments-height": _vm.checkHeight,
                            "edit-comment": _vm.handleClickEditComment,
                            "new-comment": _vm.handleTaskSettingsBodyScroll,
                          },
                          nativeOn: {
                            dragstart: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("task-settings-footer", {
                  ref: "taskSettingsFooter",
                  staticClass: "gp_autotest_comment_hub_comment_editor",
                  attrs: {
                    "current-edit-comment": _vm.currentEditComment,
                    "task-data": _vm.taskData,
                    "task-id": _vm.taskId,
                    "comment-hub": true,
                    "unread-comment-ids": _vm.unreadCommentIds,
                    mention: _vm.mention,
                    "show-scroll-to-bottom-button":
                      _vm.showScrollToBottomButton,
                    disabled: _vm.isCommentDisabled,
                  },
                  on: {
                    submit: _vm.handleSubmitCommentEditor,
                    scrollToBottom: _vm.scrollToBottom,
                    cancel: _vm.cancelEditor,
                    openEditor: _vm.openEditor,
                  },
                  nativeOn: {
                    dragleave: function ($event) {
                      $event.stopPropagation()
                    },
                    dragstart: function ($event) {
                      $event.preventDefault()
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }