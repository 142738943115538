/* eslint-disable no-undef, no-param-reassign, no-unused-vars */

import globalStore from '../../../store/main';

let _worker = null;
let _isWorkerInited = false;

function _createWBSWorker(workerPath) {
  const worker = new Worker(workerPath);

  return function (workerData) {
    return new Promise((resolve, reject) => {
      worker.onmessage = function (e) {
        worker.onmessage = null;
        worker.onerror = null;

        return resolve(e.data);
      };

      worker.onerror = function (e) {
        worker.onmessage = null;
        worker.onerror = null;

        return reject(e);
      };

      worker.postMessage(workerData);
    });
  };
}

const _init = function () {
  if (_isWorkerInited) return;
  let version = Date.now();

  if (GT.productVersion && GT.productVersion.buildVersion) {
    version = GT.productVersion.buildVersion;
  }

  _worker = _createWBSWorker(`/workers/wbs_worker.js?${version}`);
  _isWorkerInited = true;
};

const _innerHelpers = {
  /**
   * @param {number[]} ganttIds
   * @returns {Promise<Object.<number, string>|boolean>}
   * @example
   * const result = await gantt.wbsWorker.calculate([...ganttIds]);
   * result is an object with task id number keys and wbs code string values
   * Example: { [taskId1]: "1", [taskId2]: "1.1", [taskId3]: "1.1.1" }
   */
  calculate: async ganttIds => {
    if (!ganttIds.length) {
      return Promise.resolve(false);
    }

    const projectGanttTasksDataMap = ganttIds.reduce((result, ganttId) => {
      result[ganttId] = globalStore.getters['tasksModel/getTasksForGantt'](ganttId);

      return result;
    }, {});

    const tasksWBSCodes = await _worker({ projectGanttTasksDataMap });

    return Promise.resolve(tasksWBSCodes);
  },
};

const outputObject = {
  init: _init,
  calculate: _innerHelpers.calculate,
};

export default outputObject;
