var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: _vm.closeDropdown,
            closeConditional: function () {
              return _vm.isVisibleDropdown
            },
          },
          expression:
            "{\n    handler: closeDropdown,\n    closeConditional: () => isVisibleDropdown\n  }",
        },
      ],
      class: [_vm.$style.wrapper, "vgp-interactive-element"],
    },
    [
      _c(
        "div",
        {
          ref: "selectedItem",
          class: [
            _vm.$style.selectedItem,
            _vm.isVisibleDropdown ? _vm.$style.active : "",
            _vm.disabled ? _vm.$style.disabled : "",
          ],
          on: { click: _vm.toggleIsVisibleDropdown },
        },
        [
          _vm.iconType === "svg"
            ? _c("svg-sprite", {
                style: { color: _vm.selectedItem.color },
                attrs: { name: "status-2", size: 24 },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.iconType === "link"
            ? _c("img", {
                class: _vm.$style["image-icon"],
                attrs: { src: _vm.selectedItem.icon },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { class: _vm.$style.label }, [
            _vm._v("\n      " + _vm._s(_vm.selectedItem.value) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisibleDropdown,
              expression: "isVisibleDropdown",
            },
          ],
          ref: "dropdown",
          class: _vm.$style.dropdown,
        },
        _vm._l(_vm.items, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              class: [
                _vm.$style.dropdown__item,
                _vm.selectedItem.id === item.id
                  ? _vm.$style.dropdown__item_selected
                  : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.handleItemClick(item.id)
                },
              },
            },
            [
              _vm.iconType === "svg"
                ? _c("svg-sprite", {
                    style: { color: item.color },
                    attrs: { name: "status-2" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.iconType === "link"
                ? _c("img", {
                    class: _vm.$style["image-icon"],
                    attrs: { src: item.icon },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(item.value))]),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }