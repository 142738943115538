<template>
    <div>
        <webix-ui :config="webixUI"/>
    </div>
</template>

<script>
    import { webixUI, init, cleanup } from './_trialCompleteUser';

    export default {
        name: 'TrialCompleteUser',
        data() {
            return {
                webixUI
            }
        },
        mounted: function() {
            let component = this;
            this.$nextTick(function() {
                init();
            })
        }, 
        beforeDestroy: function() {
            cleanup();
        }
    }
</script>


<style scoped>
</style>