import app from '../../app';
import helpInfo from '../../models/helpInfo';
import icon_close from '../../svg/ic_close.svg';
import videoLayout from '../videoLayout/main';
import _ from '../../libs/lodash';

const __ = window.__;

const pushInfo = function (id) {
  // TODO: calls analytics

  const currentData = helpInfo.getInfoById(id);

  if (!currentData.title) {
    return;
  }

  const $$helpBlock = $$('helpBlock');
  const $$helpBlockTemplateTitle = $$('helpBlockTemplateTitle');
  const $$helpBlockTemplateText = $$('helpBlockTemplateText');

  $$helpBlockTemplateTitle.define('template', currentData.title);
  $$helpBlockTemplateText.define('template', currentData.text);

  $$helpBlock.define('height', currentData.height);
  $$helpBlock.define('width', currentData.width);

  $$helpBlock.getNode().classList.remove('major');
  $$helpBlockTemplateText.getNode().classList.add('default');

  // if (id !== "customGridHelpBlock") {
  //   $$helpBlock.getNode().classList.remove('major');
  //   $$helpBlockTemplateText.getNode().classList.add('default');
  // } else {
  //   $$helpBlock.getNode().classList.add('major');
  //   $$helpBlockTemplateText.getNode().classList.remove('default');
  // }

  $$helpBlock.resize();
  $$helpBlockTemplateTitle.refresh();
  $$helpBlockTemplateText.refresh();
  $$helpBlock.getNode().style.maxHeight = `${currentData.height}px`;
  $$helpBlock.getNode().style.maxWidth = `${currentData.width}px`;
};

const otherClick = function () {
  const popupLayout = $$('helpBlock').$view;

  popupLayout.addEventListener('click', event => {
    if (!event.target.classList.contains('help-video') && !event.target.classList.contains('window_header_icon') && !event.target.classList.contains('desc-link')) {
      event.stopPropagation();
    }
  });
};

const innerSettings = {
  isOpen: false,
};

const closeHelpPopup = function () {
  const $$helpBlock = $$('helpBlock');

  innerSettings.isOpen = false;

  $$helpBlock.getNode().classList.remove('shown-help');
  _.each(document.querySelectorAll('.help-icon.active-help'), e => {
    e.classList.remove('active-help');
  });
};

const toggleVisiblePopup = function (isShown) {
  const $$helpBlock = $$('helpBlock');

  if (isShown) {
    $$helpBlock.getNode().classList.add('shown-help');
    $$helpBlock.getNode().classList.add('show-help-animation');
  } else {
    $$helpBlock.getNode().classList.remove('shown-help');
    $$helpBlock.getNode().classList.remove('show-help-animation');
  }

  $$helpBlock.resize();
};

app.on('app:start', () => {
  webix.ui({
    id: 'helpBlock',
    view: 'window',
    css: 'help_block',
    width: 636,
    zIndex: 9999,
    borderless: true,
    hidden: true,
    isShown() {
      return innerSettings.isOpen;
    },
    position(state) {
      state.left = 'auto';
      state.top = 'auto';
    },
    head: {
      // width: 'auto',
      height: 60,
      paddingY: 15,
      cols: [
        {
          borderless: true,
          view: 'template',
          css: 'help-block-title',
          id: 'helpBlockTemplateTitle',
          template: '',
        },
        {
          view: 'icon',
          width: 30,
          height: 30,
          css: 'help-block-close',
          template: `<div class='window_header_icon'>${icon_close}</div>`,
          on: {
            onItemClick() {
              $$('helpBlock').hide();
              // closeHelpPopup();
            },
          },
        },
      ],
    },
    body: {
      view: 'template',
      id: 'helpBlockTemplateText',
      css: 'help-block-template',
      width: 587,
      // height: 'auto',
      padding: 0,
      scroll: 'y',
      template: '',
      onClick: {
        'js-show-global-video-fullscreen': function (e) {
          videoLayout.init(e);
        },
      },
    },
    on: {
      onBeforeShow() {
        otherClick();
      },
      onShow() {
        innerSettings.isOpen = true;
      },
      onHide() {
        const $$helpBlock = $$('helpBlock');
        innerSettings.isOpen = false;
        toggleVisiblePopup(false);
        if (!$$helpBlock.isVisible()) {
          _.each(document.querySelectorAll('.help-icon.active-help'), e => {
            // e.classList.remove('active-help');
          });
        }
      },
    },
  }).hide();
  // show();
});

app.on('helpPopup:close', closeHelpPopup);

export default {
  showHelpBlock(mouseEvent, id, data) {
    const $$helpBlock = $$('helpBlock');
    const name = id;

    !$$helpBlock.isVisible() && $$helpBlock.show();
    $$helpBlock.getNode().style.right = `${window.innerWidth - mouseEvent.clientX}px`;
    $$helpBlock.getNode().style.bottom = `${window.innerHeight - mouseEvent.clientY}px`;

    innerSettings.isOpen = true;

    _.delay(() => {
      pushInfo(id);
      toggleVisiblePopup(true);
      $$helpBlock.resize();
      // if (id === "workloadHelpBlock") {
      //   gantt.$ui.getView("workloadHeader").$view.querySelector('.help_icon_block .help-icon').classList.add('activity_used');
      // }
    }, 20);
  },
};
