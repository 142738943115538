var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.taskData
    ? _c(
        "div",
        {
          class: [
            (_vm.longComments && _vm.commentHub) || !_vm.hideForHub
              ? _vm.$style["task-settings__wrapper"]
              : _vm.$style["task-settings__short"],
            _vm.commentHub ? _vm.$style["task-settings__wrapper_hub"] : "",
            _vm.isDraggingFile ? _vm.$style["task-settings__drag-overlay"] : "",
          ],
          attrs: {
            "data-drag-over-text": _vm.locales("task_view_upload_text"),
          },
          on: {
            dragenter: function ($event) {
              $event.preventDefault()
              return _vm.showDragOverlay($event)
            },
            dragover: function ($event) {
              $event.preventDefault()
              return _vm.showDragOverlay($event)
            },
            dragleave: function ($event) {
              $event.preventDefault()
              return _vm.hideDragOverlay($event)
            },
            drop: function ($event) {
              $event.preventDefault()
              return _vm.handleDropFile($event)
            },
          },
        },
        [
          !_vm.commentHub
            ? _c("task-settings-header", {
                ref: "taskSettingsHeader",
                attrs: {
                  "task-data": _vm.taskData,
                  "comment-hub": _vm.commentHub,
                },
                on: {
                  change: _vm.handleChangeData,
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
                nativeOn: {
                  dragleave: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "taskSettingsInnerWrapper",
              class: [
                _vm.$style[
                  !_vm.hideForHub && _vm.commentHub ? "settings-main" : ""
                ],
                _vm.$style[
                  _vm.hideForHub && !_vm.comments.length ? "setting-test" : ""
                ],
                !_vm.commentHub ? "gantt_scroll" : "",
                _vm.$style["task-settings__inner-wrapper"],
              ],
              attrs: { id: "taskSettingsWrapper" },
              on: { scroll: _vm.handleTaskSettingsBodyScroll },
            },
            [
              _vm.commentHub
                ? _c("task-settings-header", {
                    ref: "taskSettingsHeader",
                    attrs: {
                      "task-data": _vm.taskData,
                      "comment-hub": _vm.commentHub,
                    },
                    on: {
                      change: _vm.handleChangeData,
                      close: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                    nativeOn: {
                      dragleave: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "taskSettingsBody",
                  class: [
                    "task-settings-body-scroll-container",
                    _vm.$style["task-settings__body"],
                  ],
                  on: {
                    scroll: _vm.handleTaskSettingsBodyScroll,
                    dragleave: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showScrollToTopButton && !_vm.hideForHub,
                          expression: "showScrollToTopButton && !hideForHub",
                        },
                      ],
                      class: [
                        _vm.$style["scroll-to-top-btn"],
                        "vgp-interactive-element",
                      ],
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.scrollToTop($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("settings_jump_to_top_btn")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.$style["task-settings__body-inner"]] },
                    [
                      _c("task-settings-primary-info", {
                        attrs: { "task-data": _vm.taskData },
                        on: { change: _vm.handleChangeData },
                        nativeOn: {
                          dragstart: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("task-settings-buttons", {
                        attrs: {
                          "task-data": _vm.taskData,
                          disabled: _vm.disabledFieldsWithReasons,
                        },
                        on: {
                          "attachment-created":
                            _vm.scrollToAttachmentsIfNotVisible,
                          "show-new-dependency-form": _vm.showNewDependencyForm,
                        },
                        nativeOn: {
                          dragstart: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("task-settings-dates", {
                        attrs: { "task-data": _vm.taskData },
                        on: { change: _vm.handleChangeData },
                        nativeOn: {
                          dragstart: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.showDescriptionSection
                        ? _c("task-settings-description", {
                            attrs: { "task-data": _vm.taskData },
                            on: { change: _vm.handleChangeData },
                            nativeOn: {
                              dragstart: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("task-settings-custom-fields", {
                        attrs: { "task-data": _vm.taskData },
                        on: { change: _vm.handleChangeCustomField },
                        nativeOn: {
                          dragstart: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.slaveLinks.length ||
                      _vm.masterLinks.length ||
                      _vm.newDependencyFormShown
                        ? _c("task-settings-dependencies", {
                            ref: "taskSettingsDependenciesSection",
                            attrs: {
                              "master-links": _vm.masterLinks,
                              "slave-links": _vm.slaveLinks,
                              "task-data": _vm.taskData,
                              disabled: _vm.disabledFieldsWithReasons,
                            },
                            on: {
                              "new-dependency-form-close": function ($event) {
                                _vm.newDependencyFormShown = false
                              },
                            },
                            nativeOn: {
                              dragstart: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.attachments.length
                        ? _c("task-settings-attachments", {
                            ref: "taskSettingsAttachmentsSection",
                            attrs: {
                              attachments: _vm.attachments,
                              "task-data": _vm.taskData,
                            },
                            on: {
                              "attachment-created":
                                _vm.scrollToAttachmentsIfNotVisible,
                            },
                            nativeOn: {
                              dragstart: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.commentHub && _vm.comments.length
                ? _c("task-settings-comments", {
                    ref: "taskSettingsCommentsSection",
                    class: [
                      _vm.$style["task-settings__body"],
                      _vm.$style["task-settings__comments"],
                    ],
                    attrs: {
                      comments: _vm.comments,
                      "hide-for-hub": _vm.hideForHub,
                      "comment-hub": _vm.commentHub,
                      "task-data": _vm.taskData,
                      "comment-editor": _vm.commentEditorCoordinates,
                    },
                    on: {
                      scroll: _vm.handleTaskSettingsBodyScroll,
                      "delete-comment": _vm.handleDeleteComment,
                      "edit-comment": _vm.handleClickEditComment,
                      "new-comment": _vm.handleTaskSettingsBodyScroll,
                    },
                    nativeOn: {
                      dragstart: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.commentHub
            ? _c("task-settings-footer", {
                ref: "taskSettingsFooter",
                attrs: {
                  "current-edit-comment": _vm.currentEditComment,
                  "show-scroll-to-bottom-button": _vm.showScrollToBottomButton,
                  "task-data": _vm.taskData,
                  "task-id": _vm.taskId,
                  "comment-hub": _vm.commentHub,
                  "unread-comment-ids": _vm.unreadCommentIds,
                },
                on: {
                  submit: _vm.handleSubmitCommentEditor,
                  scrollToBottom: _vm.scrollToBottom,
                  openEditor: _vm.handleOpenComment,
                  comment: function ($event) {
                    return _vm.$emit("comment", $event)
                  },
                  cancel: function ($event) {
                    _vm.currentEditComment = null
                  },
                },
                nativeOn: {
                  dragleave: function ($event) {
                    $event.stopPropagation()
                  },
                  dragstart: function ($event) {
                    $event.preventDefault()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }