/* eslint-disable */
import app from "./../../../app";
import projectsModel from "./../../../models/projects";
import constants from './../../../helpers/constants';
import dateHelper from "../../../helpers/dateFormats";
import routerHelper from "../../../helpers/router";

import icon_search from '../../../svg/globalSearch/outline-search-24px.svg';
import icon_settings from "../../../svg/ic_settings_dots_hor.svg";
import icon_edit from '../../../svg/webix_material/edit_icon.svg';
import icon_trash from "../../../svg/ic_delete.svg";
import icon_close from '../../../svg/ic_close.svg';
import icon_info from '../../../svg/integration/settings/yellow_info.svg';

import icon_checkbox from "../../../svg/customColumns/checkbox.svg";
import icon_colors from "../../../svg/customColumns/colors.svg";
import icon_date from "../../../svg/customColumns/date.svg";
import icon_select from "../../../svg/customColumns/list.svg";
import icon_multiselect from "../../../svg/customColumns/multiselect.svg";
import icon_number from "../../../svg/customColumns/number.svg";
import icon_resources from "../../../svg/customColumns/resources.svg";
import icon_tags from "../../../svg/customColumns/tags.svg";
import icon_text from "../../../svg/customColumns/text.svg";

import _ from "lodash";
import rights from "../../../components/rights";
import globalStore from '$$store/main';

const typeIcons = {
	1: icon_select,
	2: icon_text,
	3: icon_number,
	4: icon_date,
	5: icon_checkbox,
	6: icon_colors,
	7: icon_multiselect,
	8: icon_resources,
	9: icon_tags,
}


const innerObject = {
	settings: {
		columns: [],
		searchValue: '',
		selectedRow: null
	},
	views: {
		globalColumnsTitle: {
			id: 'globalColumnsTitle',
			height: 30,
			cols: [
				{
					view: 'template',
					borderless: true,
					css: 'section_title',
					template: __('settings_global_columns_title'),
				},
				{},
				{
					view: "button",
					type: "success",
					id: 'add_global_column_button',
					css: "add_global_column_button",
					width: 100,
					height: 30,
					inputHeight: 30,
					value: __('settings_add_global_column'),
					on: {
						onItemClick: function () {
							app.trigger('openUserCustomColumnsPopup', null, true);
						}
					}
				}
			]
		},
		globalColumnsSearch: {
			id: 'globalColumnsSearch',
      css: 'globa_columns_search_wrapper',
			height: 36,
			cols: [
				{
					view: 'template',
					css: 'global_column_search_icon',
					borderless: true,
					width: 35,
					height: 36,
					template: () =>
						`<div style="width: 100%; height: 100%; padding: 6px 0;">${icon_search}</div>`
				},
				{
					view: 'text',
					css: 'global_column_search_input',
					id: 'globalColumnsSearchInput',
					keyPressTimeout: 0,
					placeholder: __('settings_search_global_columns_placeholder'),
					on: {
						onTimedKeyPress: function () {
							const value = this.getValue();
							innerObject.helpers.defineClearButtonVisibility(value);
							innerObject.helpers.searchGlobalColumnsMatches(value);
						}
					}
				},
				{
					id: 'globalColumnsSearchClear',
					view: 'template',
					css: 'global_column_clear_icon hidden',
					borderless: true,
					autoheight: true,
					width: 24,
					template: function () {
						return `<div class="close_control_container">${icon_close}</div>`;
					},
					onClick: {
						'close_control_container': function (e, r, t) {
							innerObject.helpers.resetSearch();
						},
					}
				}
			]
		},
		globalColumnsTable: {
			view: "activeTable",
			id: 'globalColumnsTable',
			headerRowHeight: 35,
			minHeight: 36,
			fixedRowHeight: false,
			minRowHeight: 37,
			borderless: true,
			autoheight: true,
			css: "globalColumnsTable",
			columns: [
				{
					id: 'name',
					header: {
						text: __('settings_table_columns_row_name'),
					},
					fillspace: 3,
					css: 'data_cell column_cell',
					template: function (obj) {
						const types = Object.values(constants.CUSTOM_COLUMNS_TYPES);
						const columnType = types.find(type => {
							return type.id === obj.type;
						});

						const name = innerObject.helpers.matchedSearchStringTemplate(obj.name);

						return `<div class='column_info'>
								<div class='column_type'>
									<div class='column_type_icon'>${typeIcons[obj.type]}</div>
								</div>
								<div class='column_info_block'>
									<div class='column_info_name'>${name}</div>
								</div>
								${innerObject.helpers.userInfoTooltip(obj)}
							</div>`;
					}
				},
				{
					id: 'projects',
					header: {
						text: __('settings_table_columns_row_projects')
					},
					fillspace: 5,
					adjust: true,
					css: 'data_cell projects_cell',
					template: function (obj) {
						const namesLimit = 3;
            const projectIds = globalStore.getters['columns/getProjectsByColumnId'](obj.id);
            const projectNum = globalStore.getters['columns/getProjectsNumberByColumnId'](obj.id);
						const projects = projectIds.map(id => {
							const projectData = projectsModel.getProjectDataById(id);
							return projectData ? projectData.name : '';
						}).filter(project => project);

						const namesNumberToShow = obj.isExpand ? projects.length : namesLimit;

						let projectNames = projects.slice(0, namesNumberToShow).map(name => {
							return `<div class="project_item" style="height: 36px; white-space: nowrap;">${name}</div>`
						}).join('');

						let expandCollapsButton = '';

						if (projects.length > namesLimit) {
							if (obj.isExpand) {
								expandCollapsButton = `<div class="collaps_button">${__('settings_table_columns_collaps_items')}</div>`;
							} else {
								expandCollapsButton = `<div class="expand_button">${__('settings_table_columns_expand_items')}: ${projects.length - namesLimit}</div>`;
							}
						}

						if (!projectNames && (projectNum.visible || projectNum.archive)) {
							const projects = projectNum.visible ? __('columns_projects_number', {number: projectNum.visible}) : '';
							const archiveProjects = projectNum.archive ? __('columns_archive_projects_number', {number: projectNum.archive}) : '';
							const countMessage = `${projects ? `${projects}` : ''}${archiveProjects && projects ? ', ' : ''}${archiveProjects ? `${archiveProjects}` : ''}`

							return `<div class='other_projects'>${countMessage}</div>`;
						}

						if (!projectNum.visible && !projectNum.archive && !projectNames) {
							return `<div class='no_projects_message'>${__('settings_table_columns_no_projects')}</div>`
						}

						return `<div class='project_column_container'>
							<div class="project_name_container ${expandCollapsButton ? 'expandable' : ''}">
								${projectNames}
							</div>
							${expandCollapsButton}
						</div>`;
					}
				},
				{
					id: 'actions',
					header: {
						text: '',
					},
					width: 30,
					css: 'actions_cell',
					template: function (obj) {
						return `<div class='column-options'>${icon_settings}</div>`;
					}
				}
			],
			on: {
				onItemClick: function (obj, e, node) {
					const item = this.getItem(obj.row);
					if (e.target.closest(".column-options")) {
						innerObject.helpers.deselectRowSettings();
						innerObject.settings.selectedRow = obj.row;
						innerObject.helpers.selectRowSettings();
						innerObject.handlers.showContextMenu(item.id, node);
						return false;
					}

					if (e.target.className === 'expand_button') {
						item.isExpand = true;
						this.adjustRowHeight(obj.column);
					}

					if (e.target.className === 'collaps_button') {
						item.isExpand = false;
						this.adjustRowHeight(obj.column);
					}
				}
			}
		},
		noDataMessageElement: {
			id: 'noDataMessageElement',
			view: 'template',
			borderless: true,
			autoheight:true,
			css: '',
			template: function () {
				return `<div class='global_columns_nodata'>
					<p class='global_columns_nodata_header'>${__('settings_table_columns_search_title')}</p>
					<p class='global_columns_nodata_message'>${__('settings_table_columns_search_message')}</p>
				</div>`
			},
		},
		globalColumsActionsPopup: {
			id: 'globalColumsActionsPopup'
		},
		globalColumsActionsList: {
			id: 'globalColumsActionsList'
		},
		globalColumnsDeletePopup: {
			id: 'globalColumnsDeletePopup'
		},
		globalColumnsDeleteForm: {
			id: 'globalColumnsDeleteForm'
		},
		globalColumnsDeleteInput: {
			id: 'globalColumnsDeleteInput'
		}
	},
	helpers: {
		prepareDataForGlobalColumnsTable: function() {
      const allColumns = globalStore.getters['columns/getAllCustomColumns'];

			return _.cloneDeep(_.sortBy(allColumns, (col) => col.name.toLowerCase()));
		},
		userInfoTooltip: function(obj) {
			const createUser = globalStore.getters['resourcesModel/getResourceByUserId'](obj.user_id);
			const editUser = obj.last_update_by ? globalStore.getters['resourcesModel/getResourceByUserId'](obj.last_update_by) : createUser;
      const createDate = dateHelper.formatDateNoTime(obj.create_date);
      const updateDate = obj.last_update ? dateHelper.formatDateNoTime(obj.last_update) : createDate;
			const types = Object.values(constants.CUSTOM_COLUMNS_TYPES);
			const columnType = types.find(type => {
				return type.id === obj.type;
			});

			const tooltip = `<div class='tooltip-gantt-html'>
					<div class='tooltip-gantt-html-description global_columns_tooltip ql-snow ql-editor'>
						<div class='global_columns_tooltip_block'>
							<span>${__('settings_table_columns_tooltip_type')}:</span>
							<span>${__(columnType?.locale)}</span>
						</div>
						<div class='global_columns_tooltip_block'>
							<span>${__('settings_table_columns_tooltip_creator')}:</span>
							<span>${createUser ? createUser.name : __('creator_removed_from_team')}</span>
							<span>${createDate}</span>
						</div>
						<div class='global_columns_tooltip_block'>
							<span>${__('settings_table_columns_tooltip_updater')}:</span>
							<span>${editUser ? editUser.name : __('creator_removed_from_team')}</span>
							<span>${updateDate}</span>
						</div>
					</div>
				</div>`;
			return `<span class='columns_user_info'>
				<span class='tooltip-gantt' data-id="${obj.id}" data-html='true'>${tooltip}</span>
			</span>`;
		},
		defineElementsVisibility: function (columnsNumber) {
			const searchElem = $$(innerObject.views.globalColumnsSearch.id);
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);
			const noDataMessageElement = $$(innerObject.views.noDataMessageElement.id);

			if (columnsNumber) {
				searchElem && searchElem.show();
				globalColumnsTable && globalColumnsTable.show();
				noDataMessageElement && noDataMessageElement.hide();
			} else {
				searchElem && searchElem.hide();
				globalColumnsTable && globalColumnsTable.hide();
				noDataMessageElement && noDataMessageElement.show();
			}
		},
		defineClearButtonVisibility: function (value) {
			const clearButton = $$('globalColumnsSearchClear');

			if (clearButton) {
				if (value) {
					webix.html.removeCss(clearButton.getNode(),'hidden');
				} else {
					webix.html.removeCss(clearButton.getNode(),'hidden');
					webix.html.addCss(clearButton.getNode(), 'hidden');
				}
			}
		},
		searchGlobalColumnsMatches: function (text) {
			const searchText = text.toLowerCase();
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);

			innerObject.settings.searchValue = searchText;

			const columns = innerObject.settings.columns.filter(column => {
				return column.name.toLowerCase().includes(searchText);
			})

			innerObject.helpers.defineOverlayDisplay(!columns.length);

			globalColumnsTable.clearAll();

			globalColumnsTable.parse(columns);
			innerObject.helpers.resizeColumnsTable();
		},
		matchedSearchStringTemplate: function (name) {
			const searchValue = innerObject.settings.searchValue;
			const regEx = new RegExp(searchValue, "ig");

			if (!searchValue) {
				return name;
			}

			return name.replace(regEx, matchedText => {
				return `<span class="search-match">${matchedText}</span>`;
			});
   		},
		resetSearch: function () {
			const searchInput = $$('globalColumnsSearchInput');
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);

			innerObject.helpers.defineClearButtonVisibility();

			innerObject.settings.searchValue = '';
			globalColumnsTable.clearAll();
			globalColumnsTable.parse(innerObject.settings.columns);
			innerObject.helpers.resizeColumnsTable();
			searchInput.setValue();
			searchInput.blur();

			innerObject.helpers.defineOverlayDisplay(false);
		},
		reSearch: function () {
			const searchInput = $$('globalColumnsSearchInput');
			innerObject.helpers.searchGlobalColumnsMatches(searchInput.getValue());
		},
		defineOverlayDisplay: function (isShow) {
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);

			if (isShow) {
				globalColumnsTable.showOverlay(innerObject.helpers.getOverlayContent());
			} else {
				globalColumnsTable.hideOverlay();
			}
		},
		getOverlayContent: function () {
			return `<div class="empty_data">
				<div class='message_icon'>${icon_info}</div>
				<div>${__('settings_table_columns_no_matches')}</div>
			</div>`
		},
		resizeColumnsTable: function () {
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);

			globalColumnsTable.adjustRowHeight("projects", true);
			globalColumnsTable.refresh();
    },
		selectRowSettings: function () {
			const table = $$(innerObject.views.globalColumnsTable.id);
			table.addRowCss(innerObject.settings.selectedRow, "cell_sellected");
		},
		deselectRowSettings: function () {
			const table = $$(innerObject.views.globalColumnsTable.id);
			table.removeRowCss(innerObject.settings.selectedRow, "cell_sellected");
		},
		applyRowsSetting: function (columns) {
			if (innerObject.settings.columns.length) {
				columns.forEach(column => {
					const oldColumn = innerObject.settings.columns.find(item => {
						return item.id === column.id;
					});

					if (oldColumn && oldColumn.isExpand) {
						column.isExpand = oldColumn.isExpand
					}
				})
			}
		}
	},
	handlers: {
		showContextMenu: function (columnId, node) {
			$$(innerObject.views.globalColumsActionsList.id).config.columnId = columnId;
			$$(innerObject.views.globalColumsActionsPopup.id).show(node, {pos:"left"});
		},
		showDeleteConfirmMessage: function (columnId) {
			$$(innerObject.views.globalColumnsDeleteForm.id).config.columnId = columnId;
			$$(innerObject.views.globalColumnsDeletePopup.id).show();
		}
	}
}

const outputObject = {
	webixUI: {
		cols: [
			{
				width: 786,
				css: "global_columns_settings",
				borderless: true,
				body: {
					css: 'settings_section',
					cols: [
						{width: 24},
						{
							rows: [
								{
									css: 'global_columns_settings_header',
									rows: [
										{height: 24},
										innerObject.views.globalColumnsTitle,
										{height: 18},
										innerObject.views.globalColumnsSearch,
										innerObject.views.noDataMessageElement,
										{height: 24}
									]
								},
								innerObject.views.globalColumnsTable,
								{height: 24},
							]
						},
						{width: 24},
					]
				}
			}
		]
	},
	handlers: {
		init: () => {
			const globalColumnsTable = $$(innerObject.views.globalColumnsTable.id);

			const columns = innerObject.helpers.prepareDataForGlobalColumnsTable();
			innerObject.helpers.defineElementsVisibility(columns.length);
			globalColumnsTable.clearAll();

			innerObject.helpers.applyRowsSetting(columns);
			innerObject.settings.columns = columns;
			innerObject.helpers.reSearch();

			innerObject.helpers.resizeColumnsTable();

			if(!rights.account.hasRight('custom_fields_create')) {
				$$('add_global_column_button').hide();
			}

			if(!rights.account.hasRight('custom_fields_delete')) {
				$$('globalColumsActionsList').filter(item => item.id !== 'delete_column');
			}

			if(!rights.account.hasRight('custom_fields_edit')) {
				$$('globalColumsActionsList').filter(item => item.id !== 'edit_column')
			}
			if(!rights.account.hasRight('custom_fields_edit') && !rights.account.hasRight('custom_fields_delete')) {
				$$('globalColumsActionsList').clearAll();
			}

			if($$('globalColumsActionsList').serialize().length === 0 ) $$('globalColumnsTable').hideColumn("actions");
		}
	},
	getAllColumns: innerObject.helpers.prepareDataForGlobalColumnsTable
};

webix.ui({
	view: 'popup',
	id: innerObject.views.globalColumsActionsPopup.id,
	width: 140,
	css: 'global_colums_actions_popup',
	autoheight: true,
	zIndex: 113,
	body: {
		view: 'list',
		id: innerObject.views.globalColumsActionsList.id,
		borderless: true,
		autoheight: true,
		columnId: 0,
		type: {
			height: 30
		},
		data: [
			{id: 'edit_column', text: __('edit_column'), icon: icon_edit},
			{id: 'delete_column', text: __('common_delete'), icon: icon_trash}
		],
		template: function (obj) {
				return `<span class='global_colums_action_item'>${obj.icon}${obj.text}</span>`;
		},
		on: {
			onItemClick: function (id, e, node) {
				if (id === 'edit_column') {
          const customColumn = globalStore.getters['columns/getCustomColumnById'](this.config.columnId);
					app.trigger('openUserCustomColumnsPopup', customColumn, true);
				} else if (id === 'delete_column') {
					innerObject.handlers.showDeleteConfirmMessage(this.config.columnId);
				}
				$$(innerObject.views.globalColumsActionsPopup.id).hide();
			}
		},
	},
	on: {
		onHide: function () {
			innerObject.helpers.deselectRowSettings();
		}
	}
});

webix.ui({
	view: 'window',
	id: innerObject.views.globalColumnsDeletePopup.id,
	width: 525,
	css: 'global_columns_delete_popup',
	position: "center",
	autoheight: true,
	modal: true,
	head:false,
	body: {
		view:"form",
		id: innerObject.views.globalColumnsDeleteForm.id,
		columnId: 0,
		elements:[
			{
				cols: [
					{
						template: __('settings_popup_delete_title'),
						css: 'delete_popup_title',
						height: 24,
						borderless: true,
						autoheight: true,
					},
					{
						view:"template",
						borderless: true,
						autoheight: true,
						width: 30,
						height: 30,
						template: function () {
							return `<div class="close_control_container">${icon_close}</div>`;
						},
						onClick: {
							"close_control_container": function (e, r, t) {
								$$(innerObject.views.globalColumnsDeletePopup.id).hide();
							},
						}
					}
				]
			},
			{
				view: 'template',
				borderless: true,
				id: 'customColumnDeletePopupMessage',
				css: 'custom_column_popup_message',
				textValue: '',
				autoheight: true,
				template: function (obj, elem) {
					const textObj = elem.config.textValue;
					return `<div class='custom_column_message ${elem.config.messageType}'>
					<div class='message_icon'>${icon_info}</div>
					<div class='message_text_box'>
						${ textObj.visible ? `<div class='message_text'>${__('columns_projects_number', {number: textObj.visible})}</div>` : '' }
						${ textObj.archive ? `<div class='message_text'>${__('columns_archive_projects_number', {number: textObj.archive})}</div>` : '' }
					</div>
					</div>`
				}
			},
			{
				id: 'customColumnDeletePopupMessageText',
				view: 'template',
				borderless: true,
				autoheight: true,
				textValue: '',
				css: 'delete_popup_message',
				template: function (obj, elem) {
					return __('settings_popup_delete_message', {name: elem.config.textValue});
				}
			},
			{
				view: 'text',
				name: 'columnName',
				id: innerObject.views.globalColumnsDeleteInput.id,
				placeholder: __('settings_popup_delete_placeholder'),
				css: 'delete_popup_input',
				validate: function (text) {
					const form = $$(innerObject.views.globalColumnsDeleteForm.id);
					const input = $$(innerObject.views.globalColumnsDeleteInput.id);
          const columnName = globalStore.getters['columns/getCustomColumnById'](form.config.columnId).name;
					const isValid = (text.trim() === columnName.trim());

					if (!isValid) {
						const message = input.getNode().querySelector('.error_message');

						if (text) {
							message.innerHTML = __('settings_popup_delete_error_incorrect');
						} else {
							message.innerHTML = __('settings_popup_delete_error_empty');
						}

						message.hidden = false;
					}

					return isValid;
				},
				on: {
					onAfterRender: function () {
						const input = $$(innerObject.views.globalColumnsDeleteInput.id);
						const message = document.createElement('span');
						message.classList.add('error_message');
						message.hidden = true;
						input.getNode().appendChild(message);
					}
				}
			},
			{height: 6},
			{
				cols:[
					{
						view: 'button',
						value: __('common_yes'),
						width: 156,
						height: 36,
						required: true,
						css: 'delete_button',
						on: {
							onItemClick: function () {
								const input = $$(innerObject.views.globalColumnsDeleteInput.id);
								const isValid = input.validate();

								if (isValid) {
									const confirmPopup = $$(innerObject.views.globalColumnsDeleteForm.id);
                  globalStore.dispatch('columns/removeColumn', confirmPopup.config.columnId);
									app.trigger('closeUserCustomColumnsPopup');
									$$(innerObject.views.globalColumnsDeletePopup.id).hide();
								}
							}
						}
					},
					{},
					{
						view: 'button',
						width: 156,
						height: 36,
						value: __('common_no'),
						on: {
							onItemClick: function () {
								$$(innerObject.views.globalColumnsDeletePopup.id).hide();
							}
						}
					}
				]
			},
		]
	},
	on: {
		onShow: function () {
			const input = $$(innerObject.views.globalColumnsDeleteInput.id);
			const form = $$(innerObject.views.globalColumnsDeleteForm.id);
			const message = $$('customColumnDeletePopupMessage');
			const messageText = $$('customColumnDeletePopupMessageText');
      const columnProjects = globalStore.getters['columns/getProjectsNumberByColumnId'](form.config.columnId);
      const customColumn = globalStore.getters['columns/getCustomColumnById'](form.config.columnId);

			messageText.config.textValue = customColumn.name;
			messageText.refresh()

			if (columnProjects.archive || columnProjects.visible) {
				message.config.textValue = columnProjects;
				message.refresh()
				message.show();

			} else {
				message.hide();
			}

			input.focus();
		},
		onHide: function () {
			const input = $$(innerObject.views.globalColumnsDeleteInput.id);
			$$(innerObject.views.globalColumnsDeleteForm.id).clear();
			input.getNode().classList.remove('webix_invalid');
			input.refresh();
		}
	}
});

function reInitWithScrollPosition() {
  const settingLayout = document.querySelector('.profile_settings');
  const top = settingLayout.scrollTop;
	outputObject.handlers.init();
  settingLayout.scrollTo(0, top);
}

app.on('userCustomColumnsModel:change', function (ganttId, mode, deletedColumnId) {
	if (routerHelper.isColumnsSettingsRoute()) {
    reInitWithScrollPosition();
	}
});

app.on('openColumnGlobalDeletePopup', innerObject.handlers.showDeleteConfirmMessage);

app.on('project:archive', (ganttId, isArchived) => {
  globalStore.commit('columns/updateProject', { projectId: ganttId, updated: { is_archived: isArchived }});
  app.trigger('userCustomColumnsModel:change', ganttId, 'update');
});

app.on('project:del', function (projectData) {
  globalStore.commit('columns/removeProject', projectData.gantt_id)
});

app.on("onSettingsScroll", () => {
	const popup = $$(innerObject.views.globalColumsActionsPopup.id);
	if (popup) {
		popup.hide();
	}
});

app.on('onAfterCollaboration', event => {
  if (event.event === 'ProjectArchived' && routerHelper.isColumnsSettingsRoute()) {
    event.projects.forEach(id => {
      globalStore.commit('columns/updateProject', { projectId: id, updated: { is_archived: 1 }});
    })
    reInitWithScrollPosition();
  }

  if (event.event === 'ProjectRestored' && routerHelper.isColumnsSettingsRoute()) {
    event.projects.forEach(id => {
      globalStore.commit('columns/updateProject', { projectId: id, updated: { is_archived: 0 }});
    });
    reInitWithScrollPosition();
  }
})

export default outputObject;
