var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$.settings_header] },
    [
      _c(
        "div",
        {
          class: _vm.$.title,
          style: _vm.tabsItems ? { paddingBottom: "13px" } : "",
        },
        [
          _c("sprite", { attrs: { name: _vm.icon } }),
          _vm._v(" "),
          _c("div", { class: _vm.$.title_text }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tabsItems
        ? _c("vgp-tabs", {
            attrs: {
              tabs: _vm.tabsItems,
              active: _vm.active,
              "active-by": "type",
            },
            on: {
              onToggle: function (tab) {
                return _vm.$emit("toggle", tab)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }