<template>
  <div :class="['plan-item', plan.name]">
    <div class="title">
      <div class="plan-name">
        {{ locales(`${plan.name}_plan_locale`) }}
      </div>
      <div
        v-if="!isAnnual"
        class="people-tooltip tooltip-gantt"
        :data-key="locales('pricing_team_size_tooltip')"
        :data-position="'bottom'"
        :data-y="0"
        :data-x="0"
      >
        <webix-ui
          class="people-icon"
          :config="icons.peopleIcon"
        />
        <div class="count">
          {{ `${peopleCount}+` }}
        </div>
      </div>
    </div>
    <div
      ref="planDesc"
      class="desc"
    >
      {{ locales(`${plan.plan_name}_plan_info_locale`) }}
    </div>
    <div
      v-if="plan.plan_name !== 'enterprise'"
      class="price action-box"
    >
      <div class="panel">
        <div class="usd">
          {{ `$ ${currentPrice}` }}
        </div>
        <user-count-select
          :label="`${locales('per_user_locale')} ${locales('per_month_key')}`"
          :get-user-count="getUserCount"
          :is-annual="isAnnual"
          :is-popup="isPopup"
          :type="'buy-new-plan'"
          :number-of-plan="numberOfPlan"
          :class-name="'user-count-margin'"
          :plan="plan"
        />
      </div>
      <div
        v-if="!isPopup"
        class="btn upgrade-btn"
        @click="buyPlanClick(plan)"
      >
        {{ locales('upgrade_now_btn_locale') }}
      </div>
      <div
        v-else-if="isPopup && !isCurrentPlan || !isCurrentUserCount || !isCurrentPeriod"
        class="btn upgrade-btn"
        @click="upgradePlanClick(plan)"
      >
        {{ locales('upgrade_now_btn_locale') }}
      </div>
      <div
        v-else-if="isPopup && isCurrentPlan && isCurrentUserCount && isCurrentPeriod"
        :class="['current-plan-sticker', plan.name]"
      >
        <webix-ui
          class="plan-check-icon"
          :config="icons.check"
        />
        <div class="sticker-text">
          {{ locales('current_plan') }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="demo action-box"
    >
      <div class="panel">
        <webix-ui
          class="icon"
          :config="icons.bublesIcon"
        />
        <div class="demo-title">
          {{ locales('lets_talk_locale') }}
        </div>
      </div>
      <div
        class="btn demo-btn"
        @click="demoClick"
      >
        {{ locales('demo_btn_locale') }}
      </div>
    </div>
    <div
      ref="features"
      class="plan-features"
    >
      <div
        class="features-desc"
        v-html="locales(`${plan.plan_name}_features_list_description`)"
      />
      <ul class="features-list">
        <li class="item">
          {{ locales(`${plan.plan_name}_feature_1`) }}
        </li>
        <li class="item">
          {{ locales(`${plan.plan_name}_feature_2`) }}
        </li>
        <li class="item">
          {{ locales(`${plan.plan_name}_feature_3`) }}
        </li>
        <li class="item">
          {{ locales(`${plan.plan_name}_feature_4`) }}
        </li>
      </ul>
    </div>
    <div class="about-plan-link">
      <a
        :href="locales(`about_plan_href_${plan.plan_name}`)"
        target="_blank"
        class="link"
        @click="linkClick(plan.plan_name)"
      >{{ locales(`about_link_${plan.plan_name}`) }}</a>
      <webix-ui
        :config="icons.arrowLeft"
        :style="{height: '19px'}"
      />
    </div>
  </div>
</template>

<script>
import paymentInternal from '../../payment/paymentInternal';

import userCountSelect from './select.vue';

import svgIconForVue from '../../common/svgIconForVue';
import bublesIcon from '../../../svg/pricing/pricing_bubles_ic.svg';
import arrowLeft from '../../../svg/pricing/ic_arrow_left.svg';
import peopleIcon from '../../../svg/pricing/pricing_people_ic.svg';
import lockIcon from '../../../svg/ic_lock.svg';
import check from '../../../svg/check.svg';
import app from '../../../app';
import plansModel from '../../../models/plans';

export default {
  components: {
    userCountSelect,
  },
  props: [
    'plan',
    'isAnnual',
    'isPopup',
    'numberOfPlan',
  ],
  data() {
    return {
      locales: __,
      isCurrentPlan: '',
      isCurrentUserCount: '',
      isCurrentPeriod: '',
      // isShowUpgradeBtn: '',
      icons: {
        bublesIcon: svgIconForVue(bublesIcon, 62, 49),
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
        peopleIcon: svgIconForVue(peopleIcon, 24, 24),
        lockIcon: svgIconForVue(lockIcon, 24, 24),
        check: svgIconForVue(check, 24, 24),
      },
    };
  },
  computed: {
    peopleCount() {
      const count = {
        base: 5,
        pro: 5,
        business: 5,
        enterprise: 10,
      };

      return count[this.plan.plan_name];
    },
    nextPeriod() {
      return this.isAnnual;
    },
    nextCount() {
      return this.$store.getters['paymentPlans/nextCount'];
    },
    currentPrice() {
      const userCount = this.$store.getters['paymentPlans/nextCount'];

      // experiment for single annual subs
      return (this.isAnnual /* && userCount !== 1 */) ? this.plan.annual_price_usd : this.plan.month_price_usd;
    },
  },
  watch: {
    nextCount(newVal) {
      const currentUserCount = user.subscription.user_count;

      if (newVal !== currentUserCount) {
        this.isShowUpgradeBtn = true;
      } else {
        this.isShowUpgradeBtn = false;
      }

      if (newVal !== currentUserCount) {
        this.isCurrentUserCount = false;
      } else {
        this.isCurrentUserCount = true;
      }
    },
    nextPeriod(flag) {
      this.isCurrentPeriod = !this.isCurrentPeriod;
    },
  },
  async mounted() {
    const description = this.$refs.planDesc;
    const features = this.$refs.features;

    await this.$store.dispatch('paymentPlans/setItemHeigth', {
      description: description.scrollHeight,
      features: features.scrollHeight,
    });
    const heigthParams = await this.$store.getters['paymentPlans/itemHeigth'];

    description.style.height = `${heigthParams.maxDesc}px`;
    features.style.height = `${heigthParams.maxFeatures}px`;
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const nextCount = this.$store.getters['paymentPlans/nextCount'];

      this.isCurrentPlan = this.plan.id === user.accountPlanData.payment_plans_id;
      this.isCurrentUserCount = nextCount === user.subscription.user_count;
      this.isCurrentPeriod = this.isAnnual === user.accountPlanData.is_annual;
    },
    buyPlanClick(plan) {
      const userCount = this.$store.getters['paymentPlans/nextCount'];
      const newPlan = {
        planId: plan.plan_id,
        userCount,
      };

      const newPeriod = this.isAnnual ? 'year' : 'month';

      userExtAnalytics.log('pricing_upgrade_now_clicked', { type: this.isAnnual ? 'annually' : 'monthly', count: userCount, plan: plan.name });
      paymentInternal.handlers.showPopup(null, {
        newPlan,
        newPeriod,
        subjectView: 'default',
      });
    },
    async upgradePlanClick(newPlan) {
      //[upgrage] - change team size
      const userPlanId = user.accountPlanData.payment_plans_id;
      const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);
      const nextCount = this.$store.getters['paymentPlans/nextCount'];

      userExtAnalytics.log('pricing_upgrade_now_clicked', { type: this.isAnnual ? 'annually' : 'monthly', count: nextCount, plan: newPlan.name });

      if (plansModel.isCanNotBeUpdatedWithoutSales()) {
        app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

        return false;
      }

      await this.$store.dispatch('globalPopup/close');

      await this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'upgrade_plan_popup_title',
        mode: 'upgrade-plan',
        currentPlan: userPlan,
        newPlan,
        countForBuy: nextCount,
        isAnnual: this.isAnnual,
      });
    },
    demoClick() {
      const planName = this.plan.name;

      app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form', planName);
    },
    getUserCount(count) {
      this.userCount = count;
    },
    linkClick(plan) {
      userExtAnalytics.log('pricing_learn_more_clicked', { type: plan });
    },
  },
};
</script>

<style scoped src="../less/paymentPlans.less"></style>
<style lang="less">

.people-tooltip {
  .webix_view {
    cursor: pointer;
  }
}

.current-plan-sticker {
  &.pro {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #9F6000;
          }
        }
      }
    }
  }

  &.business {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #1976D2;
          }
        }
      }
    }
  }
}
</style>
