<template>
  <div
    v-if="statusData && statusData.options"
    v-click-outside="onClickOutside"
    :class="['status-dropdown']"
  >
    <div
      ref="statusDropdown"
      :class="['status-select', 'select-area', isOpen ? 'open' : '',
               statusData.options.length <= 4 ? 'scroll-hidden' : '',
               statusData.options.length <= 1 ? 'no-status' : '',
               isFirefox ? 'scroll-ident-ff' : '']"
      :style="{
        top: coordinates.top,
        bottom: coordinates.bottom,
        left: coordinates.left,
        right: coordinates.right,
      }"
    >
      <div
        :class="['select__options gantt_scroll']"
      >
        <div
          v-if="statusData.options.length > 1 && hasRightToChangeStatus"
          :class="['options-area']"
        >
          <div
            v-for="item in statusData.options"
            :key="item.id"
            :style="{backgroundColor: getColorBack(item.color), color: colorLabel(item.color)}"
            :class="['select__options-item', item.icon ? 'icon' : '', item.value === statusData.selectOption.value ? 'active' : '']"
            @click="changeValue(item.id, item.value)"
          >
            <span
              :class="['select__options-item-text', statusData.selectOption.id === item.id ? 'select__options-item-text_bold' : '']"
            >{{ localeArray.includes(item.value) ? locale(item.value) : item.value }}</span>
            <svg-sprite
              v-if="statusData.selectOption.id === item.id"
              :class="['select__options-item-icon']"
              :style="{ color: colorLabel(item.color)}"
              :name="'check'"
              :type="'regular'"
              :size="24"
            />
          </div>
        </div>
        <div
          v-if="statusData.options.length <= 1 && hasRightToStatusSettings && hasRightToChangeStatus"
          class="no-result-template"
        >
          <div class="text">
            {{ locale('no_result_locale') }}
          </div>
          <div class="delimiter">
            <div class="delimiter_line" />
          </div>
          <vgp-button
            class="button-area"
            :type="'text'"
            :label="locale('add_status_locale')"
            :small="true"
            :icon="{name: 'plus-1', type:'bold', size: 20}"
            @onClick="redirectToStatuses"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import routerHelper from '../../../../../helpers/router';
import app from '../../../../../app';
import globalStore from '../../../../../store/main';

export default {
  name: 'StatusSelectDropdown',
  props: {
    statusData: {
      type: Object,
      required: true,
    },
    blockSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectConfig: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hasRightToChangeStatus: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasRightToStatusSettings: {
      type: Boolean,
      required: false,
      default: true,
    },

  },

  data() {
    return {
      defaultColor: 'fff',
      locale: __,
      localeArray: ['no_status_locale', 'on_track_locale', 'at_risk_locale', 'off_track_locale'],
      isFirefox: false,
      coordinates: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      },
    };
  },
  computed: {
    colors() {
      return globalStore.getters['teamSetting/colors'].data;
    },
  },
  watch: {
    position() {
      this.getCoordinates();
    },
  },

  mounted() {
    document.addEventListener('click', this.close);
    const divs = document.querySelectorAll('div.gantt_scroll');

    divs.forEach(div => {
      div.addEventListener('scroll', _.debounce(this.onScroll, 50));
    });
    this.isFirefox = typeof InstallTrigger !== 'undefined';
    this.position && this.getCoordinates();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
    const divs = document.querySelectorAll('div.gantt_scroll');

    divs.forEach(div => {
      div.removeEventListener('scroll', _.debounce(this.onScroll, 0));
    });
  },
  beforeCreate() {
    app.on('onAfterCollaboration', data => {
      if (data.event === 'ResourceRoleUpdated'
          || data.event === 'ResourceOnProjectsUpdated'
          || (data.event === 'ResourceUpdated' && data?.isUpdatedRole && user.id === data.userId)
      ) {
        this.$emit('closeStatus', true);
      }
    });
  },
  methods: {
    getColorBack(id) {
      const itemColor = this.findColorItem(id);

      return itemColor ? itemColor.hex3 : this.defaultColor;
    },
    colorLabel(id) {
      const itemColor = this.findColorItem(id);

      return itemColor ? itemColor.hex4 : this.defaultColor;
    },
    findColorItem(id) {
      return this.colors?.find(color => color.id === id);
    },
    changeValue(value) {
      userExtAnalytics.log('project_status_header_changed');

      this.$emit('selectStatus', value, this.projectConfig);
      this.$emit('closeStatus', true);
    },
    onClickOutside(e) {
      if (!(e.target.closest('.status-dropdown')) && !(e.target.closest('.status-select'))) {
        this.$emit('closeStatus', true);
        this.$emit('scroll', true);
      }
    },
    redirectToStatuses() {
      this.$emit('closeStatus', true);
      this.$emit('closeProjectsList');
      routerHelper.changeRoute('/settings/statuses');
    },
    onScroll(e) {
      if (!this.$el.contains(e.target)) {
        this.$emit('closeStatus', true);
        this.$emit('scroll', true);
      }
    },
    getCoordinates() {
      const coordinates = this.position;
      const documentClientWidth = document.documentElement.clientWidth;
      const documentClientHeight = document.documentElement.clientHeight;

      const elementWidth = this.$refs.statusDropdown.clientWidth;
      const elementHeight = this.$refs.statusDropdown.clientHeight;

      this.dropToBottom = (coordinates.bottom + elementHeight) < documentClientHeight;
      const alignedRightSide = (documentClientWidth - coordinates.left - 10) < elementWidth;
      const left = `${coordinates.left - elementWidth + coordinates.width}px`;

      if (this.dropToBottom) {
        this.coordinates = {
          top: `${coordinates.bottom + 4}px`,
          left: alignedRightSide ? left : `${coordinates.left}px`,
        };
      } else {
        this.coordinates = {
          top: `${coordinates.top - elementHeight - 4}px`,
          left: alignedRightSide ? left : `${coordinates.left}px`,
        };
      }
    },
  },
};
</script>

<style scoped src="../less/select.less"></style>
