import app from '../../app';

import moment from '../../libs/moment';
import _ from '../../libs/lodash';

// import userNotificationsModel from '../../models/userNotifications';
import projectsModel from '../../models/projects';
// import popupForm from "./../../views/userNotifications/popupForm";
import notificationModal from '../notificationModal/notificationModal';
import videoLayout from '../videoLayout/main';
import teamComponent from '../../components/team/main';
import icon_settings from '../../svg/header_buttons/project_settings.svg';
import mention_icon from '../../svg/notifications/mention_icon.svg';
import assign_icon from '../../svg/notifications/assign_icon.svg';
import comment_icon from '../../svg/notifications/comment_icon.svg';
import attachment_icon from '../../svg/notifications/attachment_icon.svg';
import deadline_icon from '../../svg/deadline/deadline_flame.svg';
import task_start_icon from '../../svg/notifications/task_start_icon.svg';
import volume_icon from '../../svg/notifications/volume.svg';
import volume_off_icon from '../../svg/notifications/volume_off.svg';
import dateFormatHelper from '../../helpers/dateFormats';
import constants from '../../helpers/constants';
import routerHelper from '../../helpers/router';
import customHelper from '../../helpers/custom';
import globalStore from '$$store/main';
import arrow_back from '../../svg/arrowBack.svg';
import rights from '../../components/rights';

const __ = window.__;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

const innerObject = {
  views: {
    main: {
      id: 'notificationsTimeLineModal',
    },
    headNotificationsList: {
      css: 'head_notification_list',
      paddingX: 12,
      rows: [
        {
          height: 32,
          cols: [
            {
              view: 'icon',
              width: 32,
              height: 32,
              css: 'arrow-back_shadow',
              template: `<span class="icon sidebar-close-popup sidebar-arrow-back">${arrow_back}</span>`,
              on: {
                onItemClick() {
                },
              },
            },
            {
              height: 32,
              borderless: true,
              template: `<div class="title_notify main">${__('title_notify_popup')}</div>`,
            },
            {
              view: 'icon',
              width: 32,
              template: `<span class="icon settings_button sidebar-close-popup" style="color:#808080">${icon_settings}</span>`,
              on: {
                onItemClick() {
                  routerHelper.changeRoute('/settings/notifications');
                },
              },
            },
            { width: 5 },
            {
              view: 'icon',
              id: 'toggleSound',
              width: 32,
              template() {
                const notificationsStore = globalStore.getters['userNotification/getNotificationStore'];

                const icon = notificationsStore.settings.notification_sound ? volume_icon : volume_off_icon;

                return `<span class="icon sound_button" style="color:#808080">${icon}</span>`;
              },
              on: {
                onItemClick(id, e) {
                  const notificationsStore = globalStore.getters['userNotification/getNotificationStore'];

                  if (notificationsStore.settings.audioBlocked) {
                    webix.message({ type: 'warning', text: __('audio_auto_play_blocked') });

                    return;
                  }
                  innerObject.handlers.toggleSound();
                },
              },
            },
          ],
        },
      ],
    },
    userNotificationLoader: {
      view: 'scrollview',
      css: 'body_notification_list',
      id: 'notificationsTimeLineListLoader',
      scroll: 'y',
      borderless: true,
      body: {
        rows: [
          {
            // height: 100,
            borderless: true,
            id: 'userNotificationLoader',
            template: '<div class="webix_progress_icon_cover" role="progressbar" aria-valuemin="0" aria-valuemax="100" tabindex="0"><div class="webix_progress_state " style="transition-duration: 2000ms; width: 100%;"></div></div>',
          },
        ],
      },
    },
    listNotificationsActivity: {
      view: 'scrollview',
      css: 'body_notification_list',
      id: 'notificationsTimeLineListWrap',
      scroll: 'y',
      borderless: true,
      body: {
        rows: [
          {
            view: 'list',
            id: 'notificationsTimeLineList',
            template: '{common.headItem()}{common.listItem()}',
            scroll: false,
            borderless: true,
            type: {
              headItem(obj) {
                return `<div class="head_item"><span>${obj.date}</span></div>`;
              },
              listItem(obj) {
                return innerObject.helpers.getTemplateForList(obj);
              },
            },
            onClick: {
              js_project(e) {
                const id = e.target.getAttribute('data-project-id');

                innerObject.handlers.toTeamProject(+id, e.target.innerHTML);
              },
              js_task(e) {
                const taskId = e.target.getAttribute('data-task-id');
                const actionId = e.target.getAttribute('data-action-id');
                const projectId = e.target.closest('.js_description').querySelector('.js_project').getAttribute('data-project-id');
                const projectName = e.target.closest('.js_description').querySelector('.js_project').innerHTML;

                innerObject.handlers.toTeamTask(+taskId, +projectId, +actionId, e.target.innerHTML, projectName);
              },
              js_show_more() {
                innerObject.handlers.showMore();
              },
              // 'js_share_form': function (e) {
              //   var formId = e.target.getAttribute('data-form-id');
              //   popupForm.show(+formId);
              // },
              js_invite_existing_user(e) {
                const teamId = e.target.getAttribute('data-team-id');
                const token = e.target.getAttribute('data-token');
                const suspend = e.target.getAttribute('data-suspend');

                innerObject.handlers.acceptInviteToTeam(token, suspend === 'true');
              },
              'js-show-global-video-fullscreen': function (e) {
                videoLayout.init(e);
              },
            },
          },
          { height: 10 },
          {
            css: 'welcome_notification',
            id: 'welcomeNotificationId',
            height: user.locale === 'ru' ? 1017 : 630,
            borderless: true,
            template() {
              return `<div class="wrapper">${__('welcome_notification_message', { userName: user.username })}</div>`;
            },
            onClick: {
              'js-show-global-video-fullscreen': function (e) {
                videoLayout.init(e);
              },
            },
          },
          {
            paddingX: 30,
            rows: [
              {
                id: 'showMoreNotifications',
                hidden: true,
                rows: [
                  {
                    view: 'button',
                    css: 'button_gray',
                    label: __('show_more_notifications'),
                    on: {
                      onItemClick() {
                        innerObject.handlers.showMore();
                      },
                    },
                  },
                  { height: 20 },
                ],
              },
            ],
          },
          { height: 50 },
        ],
      },
    },
  },
  settings: {
    pageIndex: 0,
    displayShowMore: true,
    isOpened: false,
    normalNotificationActions: [
      constants.NOTIFICATION_ACTIONS.MENTION,
      constants.NOTIFICATION_ACTIONS.ASSIGN,
      constants.NOTIFICATION_ACTIONS.COMMENT,
      constants.NOTIFICATION_ACTIONS.ATTACHMENT,
      constants.NOTIFICATION_ACTIONS.START_TASK,
      constants.NOTIFICATION_ACTIONS.END_TASK,
      constants.NOTIFICATION_ACTIONS.DEADLINE,
    ],
    mapActions: {
      1: 'onTaskCommentsClick',
      3: 'onTaskCommentsClick',
      4: 'onTaskAttachmentsClick',
    },
  },
  init: {
    run() {
      innerObject.helpers.renderData();
      innerObject.helpers.renderShowMore();
    },
  },
  handlers: {
    acceptInviteToTeam(token, needToSuspend) {
      teamComponent.acceptInviteToTeam(token, needToSuspend);
    },
    toTeamProject(projectId, projectName) {
      const projectData = _.filter(projectsModel.serialize(), { project_id: projectId })[0];
      const isArchivedProject = _.filter(projectsModel.getArchivedProjects(), { project_id: projectId })[0];

      if (isArchivedProject) {
        webix.message({ type: 'warning', text: __('project_was_archived', { projectName }), expire: 10000 });

        return;
      }

      if (projectData) {
        innerObject.handlers.hidePopup();

        app.trigger('openProject', projectData.gantt_id);
      } else {
        webix.message({ type: 'warning', text: __('project_was_deleted', { projectName }), expire: 10000 });
      }
    },
    toTeamTask(taskId, projectId, actionId, taskName, projectName) {
      let tasksData = [];
      const projectData = _.filter(projectsModel.serialize(), { project_id: projectId })[0];
      const isArchivedProject = _.filter(projectsModel.getArchivedProjects(), { project_id: projectId })[0];

      innerObject.handlers.hidePopup();

      globalStore.getters['tasksModel/getData'].forEach(projectTasks => {
        tasksData = _.union(tasksData, projectTasks.tasks);
      });

      const task = _.filter(tasksData, { id: +taskId })[0];

      if (isArchivedProject) {
        webix.message({ type: 'warning', text: __('project_was_archived', { projectName }), expire: 10000 });

        return;
      }

      if (!task) {
        webix.message({ type: 'error', text: __('task_was_deleted', { taskName }), expire: 10000 });

        return;
      }

      if (!projectData) {
        webix.message({ type: 'warning', text: __('project_was_deleted', { projectName }), expire: 10000 });

        return;
      }

      if (!rights.project.hasRight(projectData.gantt_id, 'all_tasks')) {
        const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;
        const projectTasksData = globalStore.getters['tasksModel/getItem'](projectData.gantt_id);

        if (!projectTasksData.resourcesToTasks[task.id]?.find(o => o.resource_id === resourceId)) {
          webix.message({ type: 'warning', text: __('task_access_rights', { taskName }), expire: 10000 });

          return;
        }
      }

      app.trigger('openProject', projectData.gantt_id);
      app.trigger('taskSettings:init', task, projectData.gantt_id, {
        selectComment: [constants.NOTIFICATION_ACTIONS.COMMENT, constants.NOTIFICATION_ACTIONS.MENTION].includes(actionId),
        selectAttachments: actionId === constants.NOTIFICATION_ACTIONS.ATTACHMENT,
      });
    },
    toggleSound() {
      const notificationSetting = globalStore.getters['userNotification/getNotificationsSettings'];

      const val = notificationSetting.notification_sound ? 0 : 1;

      globalStore.dispatch('userNotification/updateUserSettings', { data: { notification_sound: val } }).then(() => {
        $$('toggleSound').getNode().querySelector('.sound_button').innerHTML = val ? volume_icon : volume_off_icon;
      });

      // userNotificationsModel.updateUserSettings({ notification_sound: val }).then(() => {
      //   $$('toggleSound').getNode().querySelector('.sound_button').innerHTML = val ? volume_icon : volume_off_icon;
      // });
    },
    showPopup() {
      const popupView = $$(innerObject.views.main.id);
      const notificationSetting = globalStore.getters['userNotification/getNotificationsSettings'];

      popupView.show();

      innerObject.settings.isOpened = true;

      $$('toggleSound').getNode().querySelector('.sound_button').innerHTML = notificationSetting.notification_sound ? volume_icon : volume_off_icon;

      // userNotificationsModel.readAll();
      globalStore.dispatch('userNotification/readAll');

      if (notificationSetting.notification_welcome) {
        globalStore.dispatch('userNotification/updateUserSettings', { data: { notification_welcome: 0 } });
        // userNotificationsModel.updateUserSettings({ notification_welcome: 0 });
      }
    },
    hidePopup() {
      if (!innerObject.settings.isOpened) return;

      const popupView = $$(innerObject.views.main.id);

      const notificationStore = globalStore.getters['userNotification/getNotificationStore'];

      popupView.hide();
      innerObject.settings.isOpened = false;
      if (globalStore.getters['userNotification/getUnreadCount']) {
        globalStore.dispatch('userNotification/readAll');
        // userNotificationsModel.readAll();
      }
      if (
        !notificationStore.settings.notification_first_setup
        && Notification.permission !== 'granted'
        && notificationStore.settings.browserSupport
      ) {
        // globalStore.dispatch('userNotification/updateUserSettingsDebounced', { data: { notification_first_setup: 1 }, showPermissionPopup: true });
        globalStore.dispatch('userNotification/updateUserSettings', { data: { notification_first_setup: 1 }, showPermissionPopup: true });
      }
    },
    async showMore() {
      const isLoaded = globalStore.getters['userNotification/getIsLoaded'];

      if (isLoaded) {
        innerObject.settings.pageIndex += globalStore.getters['userNotification/getNotificationStore'].limitPerPage;
      }

      await globalStore.dispatch('userNotification/loadData', innerObject.settings.pageIndex).then(() => {
        // innerObject.helpers.renderData();
        // $$(innerObject.views.listNotificationsActivity.id).resizeChildren();
        // innerObject.helpers.renderShowMore();

        const notificationData = globalStore.getters['userNotification/getNotifications'];

        const preparedData = innerObject.helpers.prepareData(notificationData);
        const timeLine = $$('notificationsTimeLineList');

        timeLine.clearAll();
        timeLine.parse(preparedData);
        $$(innerObject.views.listNotificationsActivity.id).resizeChildren();
        innerObject.helpers.renderShowMore();

        globalStore.dispatch('userNotification/isLoaded');
      });

      // userNotificationsModel.loadData(innerObject.settings.pageIndex).then(() => {
      //   innerObject.helpers.renderData();
      //   $$(innerObject.views.listNotificationsActivity.id).resizeChildren();
      //   innerObject.helpers.renderShowMore();
      // });
    },
    showInviteToTeamModal(data) {
      const bodyLocale = data.suspendSubscriptionMessage ? 'invite_existing_user_body_suspend' : 'invite_existing_user_body';
      const buttonLocale = data.suspendSubscriptionMessage ? 'invite_existing_user_button_suspend' : 'invite_existing_user_button';

      notificationModal.init({
        title: __('invite_existing_user_title'),
        width: 636,
        body: {
          css: '',
          template: __(bodyLocale, {
            creatorName: data.creatorName,
          }),
        },
        buttons: [
          {
            width: 180,
            text: __('common_close'),
            css: 'button_default',
            callback() {
              $$('notificationModal').hide();
            },
          },
          {
            width: 180,
            text: __(buttonLocale),
            css: 'button_active',
            callback() {
              teamComponent.acceptInviteToTeam(data.inviteToken, data.suspendSubscriptionMessage);
            },
          },
        ],
      });
    },
  },
  helpers: {
    renderData() {
      const isLoaded = globalStore.getters['userNotification/getIsLoaded'];

      if (!isLoaded) {
        $$('notificationsTimeLineListLoader').show();
        $$('notificationsTimeLineListWrap').hide();
        innerObject.handlers.showMore().then(() => {
          setTimeout(() => {
            $$('notificationsTimeLineListLoader').hide();
            $$('notificationsTimeLineListWrap').show();
          }, 500);
        });
      } else {
        const notificationData = globalStore.getters['userNotification/getNotifications'];

        const preparedData = innerObject.helpers.prepareData(notificationData);
        const timeLine = $$('notificationsTimeLineList');

        timeLine.clearAll();
        timeLine.parse(preparedData);
      }
    },
    prepareData(data) {
      const resArr = [];
      const formatTime = gantt.date.date_to_str(dateFormatHelper.getDateFormatForGanttGrid(true));

      const groupedData = _.groupBy(data, item => moment(item.action_date).format('Do MMMM'));

      Object.keys(groupedData).forEach(key => {
        const day = {};
        const group = groupedData[key];

        day.date = key;
        day.list = group.map(item => {
          item.time = formatTime(item.action_date);

          return item;
        });
        day.list.sort((a, b) => b.action_date - a.action_date);
        resArr.push(day);
      });

      resArr.sort((a, b) => b.list[0].action_date - a.list[0].action_date);

      return resArr;
    },
    renderShowMore() {
      const button = $$('showMoreNotifications');

      if (innerObject.settings.displayShowMore) {
        button.show();
        $$('welcomeNotificationId').hide();
      } else {
        button.hide();
        $$('welcomeNotificationId').show();
      }
    },
    getTemplateForList(obj) {
      const list = obj.list;
      let html = '<div class="list_notifications">';

      list.forEach(item => {
        const data = item.data;
        const icon = innerObject.helpers.getIcon(innerObject.helpers.getNotificationType(item.action_id));
        const readCss = item.read ? '' : ' highlight';
        const actionId = +item.action_id;

        if (_.includes(innerObject.settings.normalNotificationActions, actionId)) {
          const project = data.project || {};
          const task = data.task || {};
          const creator = data.creator || {};
          const localeKey = (actionId === constants.NOTIFICATION_ACTIONS.START_TASK && data.task_started)
            ? 'notification_action_task_started_mess' : data.text;
          let delay; let
            startDate;
          const timeFormat = user.timeFormat ? 'HH:mm' : 'hh:mm A';

          delay = moment().to(moment().add(data.delay || 1, 'h'));
          startDate = moment(data.date).utc().format(`${user.dateFormat} ${timeFormat}`);

          html += `<div class="item${readCss}">`
            + `<span class="time"><span>${item.time}</span></span>`
            + `<span class="icon"><span style="color:${icon.color}">${icon.svg}</span></span>`
            + `<span class="description js_description">${
              __(localeKey, {
                creatorName: creator.name || '',
                projectId: project.id,
                projectName: project.name,
                taskId: task.id,
                taskName: customHelper.formatTaskName(task.name),
                actionId: item.action_id,
                tasksCount: data.tasksCount,
                delay,
                date: startDate,
              })
            }</span>`
            + '</div>';
        } else if (_.includes([constants.NOTIFICATION_ACTIONS.FEATURES, constants.NOTIFICATION_ACTIONS.PROMO], actionId)) {
          html += `<div class="item">${data.content[user.locale]}</div>`;
        } else if (actionId === constants.NOTIFICATION_ACTIONS.WELCOME) {
          html += `<div class="item welcome_notification">${__(data.content, { userName: user.username })}</div>`;
        } else if (actionId === constants.NOTIFICATION_ACTIONS.INVITE_TO_TEAM) {
          html += `<div class="item">${
            __(data.text, {
              creatorName: data.creator.name,
              teamId: data.creator.team_id,
              token: data.token,
              suspendSubscriptionMessage: data.suspendSubscriptionMessage,
            })
          }</div>`;
        }
      });
      html += '</div>';

      return html;
    },
    getIcon(type) {
      const mapIcons = {
        mention: {
          svg: mention_icon,
          color: '#FF9A00',
        },
        assign: {
          svg: assign_icon,
          color: '#E74C3C',
        },
        comment: {
          svg: comment_icon,
          color: '#17BA41',
        },
        attachment: {
          svg: attachment_icon,
          color: '#257ECC',
        },
        task_start: {
          svg: task_start_icon,
          color: '#1d64a3',
        },
        task_end: {
          svg: task_start_icon,
          color: '#1d64a3',
        },
        deadline: {
          svg: deadline_icon,
          color: '#e706c1',
        },
        default: {
          svg: '',
          color: '',
        },
      };

      return mapIcons[type];
    },
    getNotificationType(id) {
      const userNotificationStore = globalStore.getters['userNotification/getNotificationStore'];

      const action = userNotificationStore.baseData.actions.filter(action => action.id === id)[0];

      return action ? action.type : 'default';
    },
  },
};

webix.ui({
  view: 'popupWithAnimation',
  width: 472,
  css: 'notifications_time_line',
  id: innerObject.views.main.id,
  // head: false,
  // modal: false,
  modal: false,
  head: false,
  move: false,
  hidden: true,
  borderless: true,
  zIndex: 112,
  body: {
    view: 'layout',
    id: 'notificationsTimeLineBody',
    width: 472,
    rows: [
      innerObject.views.headNotificationsList,
      { height: 10 },
      innerObject.views.userNotificationLoader,
      innerObject.views.listNotificationsActivity,
    ],
  },
  on: {
    onBeforeShow() {
      app.trigger('popup:closeOther', innerObject.views.main.id);
      userExtAnalytics.log('general_notifications_open');
    },
    onBeforeAnimationHide() {
      const route = routerHelper.getCurrentRoute();

      innerObject.handlers.hidePopup();
      // app.trigger('leftSideBar:setActiveButton', route.name, route.params.projectId);
    },
    onAfterAnimationHide() {
      app.trigger('popup:hide');
    },
    onKeyPress(code, e) {
      if (code === 27) {
        innerObject.handlers.hidePopup();
      }
    },
  },
  position(state) {
    state.height = document.body.clientHeight;
    state.width = 472;
    state.top = 0;
    state.left = -this.config.width;
  },
});

const outputObject = {
  show() {
    innerObject.init.run();
    innerObject.handlers.showPopup();
  },
  hide() {
    innerObject.handlers.hidePopup();
  },
  isOpened() {
    return innerObject.settings.isOpened;
  },
};

app.on('userNotifications:toggleSound', val => {
  if ($$('toggleSound').getNode().querySelector('.sound_button')) {
    $$('toggleSound').getNode().querySelector('.sound_button').innerHTML = val ? volume_icon : volume_off_icon;
  }
});

// app.on('showPushPermissionPopup', () => {
//   $$('requestPermissionPopup').show();
// });

app.on('leftSideBar:changedMode', () => {
  $$(innerObject.views.main.id).resize();
});

app.on('popup:show', () => {
  $$(innerObject.views.main.id).hide();
});

app.on('userNotifications:new', () => {
  innerObject.settings.pageIndex++;
  if (innerObject.settings.isOpened) {
    innerObject.helpers.renderData();
  }
});

app.on('userNotifications:removeShowMore', () => {
  innerObject.settings.displayShowMore = false;
  innerObject.helpers.renderShowMore();
});

app.socket.on('inviteToNewTeam', data => {
  innerObject.handlers.showInviteToTeamModal(data);
});

export default outputObject;
