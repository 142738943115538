var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("right-popup", {
        attrs: {
          show: _vm.isShow,
          "border-left": false,
          "border-top": false,
          width: 600,
          "exclude-footer": true,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.onClickOutside,
                        expression: "onClickOutside",
                      },
                    ],
                    class: _vm.$.resource_card,
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.resource_card_head,
                          _vm.isMaterial
                            ? _vm.$.resource_card_head__material
                            : "",
                          _vm.resourceData.description ||
                          _vm.descriptionIsEditable ||
                          _vm.descriptionIsLoading
                            ? _vm.$.has_description
                            : "",
                        ],
                      },
                      [
                        _c("div", { class: _vm.$.profile }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "click-outside",
                                  rawName: "v-click-outside",
                                  value: _vm.onClickAvatarOutside,
                                  expression: "onClickAvatarOutside",
                                },
                              ],
                              ref: "avatar",
                              class: _vm.$.avatar,
                            },
                            [
                              _c("vgp-avatar", {
                                attrs: {
                                  src: _vm.resourceData.photo,
                                  "custom-size": 100,
                                },
                              }),
                              _vm._v(" "),
                              _vm.isMaterial ||
                              (!_vm.isMaterial &&
                                _vm.meIsAccountOwner &&
                                !_vm.isPending)
                                ? _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$.avatar_edit,
                                        !_vm.isShowColorsDropdown
                                          ? _vm.$.with_hover
                                          : "",
                                      ],
                                      on: { click: _vm.onClickAvatar },
                                    },
                                    [
                                      _c("sprite", {
                                        class: _vm.$.avatar_edit_icon,
                                        attrs: { name: "pensil" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { class: _vm.$.avatar_edit_text },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.locales(
                                                  "common_avatar_edit"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isShowColorsDropdown
                                ? _c("context-menu", {
                                    attrs: {
                                      position: _vm.positionColorsDropdown,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "body",
                                          fn: function () {
                                            return [
                                              _c("vgp-colors-list", {
                                                attrs: {
                                                  colors: _vm.colors,
                                                  selected: _vm.resourceData
                                                    .colorId
                                                    ? [_vm.resourceData.colorId]
                                                    : [],
                                                },
                                                on: {
                                                  selectColors:
                                                    _vm.onChangeResourceColor,
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1385984606
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.info }, [
                            _c(
                              "div",
                              { class: _vm.$.info_name },
                              [
                                _c("vgp-row-input", {
                                  key: _vm.nameGeneratedKey,
                                  attrs: {
                                    value: _vm.resourceData.name,
                                    readonly: _vm.resourceData.email
                                      ? true
                                      : false,
                                    bold: true,
                                    fontSize: 16,
                                    "gp-autotest-name":
                                      "gp_autotest_resource_card_input_resource_name",
                                  },
                                  on: { onChange: _vm.onChangeResourceName },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.info_email,
                                  _vm.resourceData.email &&
                                  !_vm.descriptionIsEditable
                                    ? _vm.$.with_hover
                                    : "",
                                ],
                                attrs: {
                                  id: "gp_autotest_resource_card_copy_email",
                                },
                                on: { click: _vm.onCopyEmail },
                              },
                              [
                                !_vm.isMaterial
                                  ? _c("input", {
                                      ref: "email",
                                      class: _vm.$.hidden_email_input,
                                      domProps: {
                                        value: _vm.resourceData.email,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { class: _vm.$.text }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.resourceData.email
                                          ? _vm.resourceData.email
                                          : _vm.locales(
                                              "common_virtual_resources"
                                            )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isMaterial
                                  ? _c(
                                      "div",
                                      { class: _vm.$.copy_icon },
                                      [
                                        _c("sprite", {
                                          attrs: {
                                            name: "copy",
                                            color: "#808080",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { class: _vm.$.info_description },
                              [
                                !_vm.resourceData.description &&
                                !_vm.descriptionIsEditable &&
                                !_vm.descriptionIsLoading
                                  ? _c("vgp-button", {
                                      class: _vm.$.add_description_btn,
                                      attrs: {
                                        label: _vm.locales(
                                          "common_add_description"
                                        ),
                                        type: "text",
                                        small: "",
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          _vm.descriptionIsEditable = true
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.resourceData.description ||
                                _vm.descriptionIsEditable ||
                                _vm.descriptionIsLoading
                                  ? _c("vgp-responsive-textarea", {
                                      key: _vm.descriptionGeneratedKey,
                                      ref: "descriptionTextArea",
                                      class: _vm.$.description_text,
                                      attrs: {
                                        value:
                                          _vm.resourceData.description || "",
                                        "start-focus":
                                          !_vm.resourceData.description,
                                        height: _vm.descriptionIsEditable
                                          ? 62
                                          : 44,
                                        "is-error": _vm.isDescriptionError,
                                      },
                                      on: {
                                        onFocus: function ($event) {
                                          _vm.descriptionIsEditable = true
                                        },
                                        onBlur: _vm.onBlurResourceDescription,
                                        onChange:
                                          _vm.onChangeResourceDescription,
                                        onResize:
                                          _vm.onResizeResourceDescription,
                                        onKeyup: _vm.onKeyupDescription,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.descriptionIsEditable
                                  ? _c(
                                      "div",
                                      {
                                        class: [
                                          _vm.$.description_counter,
                                          _vm.isDescriptionError
                                            ? _vm.$.description_counter__error
                                            : "",
                                          _vm.descriptionIsEditable &&
                                          _vm.descriptionCount > 0
                                            ? _vm.$.description_counter__active
                                            : "",
                                        ],
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.descriptionCount)),
                                        ]),
                                        _vm._v(
                                          "/" +
                                            _vm._s(_vm.maxDescriptionCount) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.$.panel }, [
                          !_vm.isMaterial
                            ? _c(
                                "div",
                                { class: _vm.$.panel_role_dropdown },
                                [
                                  _c("vgp-label-slot", {
                                    attrs: {
                                      label: _vm.locales("common_account_role"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "body",
                                          fn: function () {
                                            return [
                                              _vm.resourceData
                                                .accessToChangeRole &&
                                              !_vm.resourceData.isOwner &&
                                              !_vm.actionsDisabled
                                                ? _c("dropdown-select", {
                                                    attrs: {
                                                      "drop-list":
                                                        _vm.accountRoles,
                                                      multiselect: false,
                                                      "show-selected-first": false,
                                                      "show-reset-btn": false,
                                                      "highlight-active-state": false,
                                                      "item-template": "text",
                                                      "track-by-text": "title",
                                                      outline: "always",
                                                      "fixed-width": 320,
                                                      "need-description": true,
                                                      size: "autoWidth",
                                                      "gp-autotest-name":
                                                        "gp_autotest_resource_card_dropdown_select_common_account_role",
                                                      selected:
                                                        _vm.accountRoles.find(
                                                          function (role) {
                                                            return (
                                                              role.id ===
                                                              _vm.resourceData
                                                                .accountRoleId
                                                            )
                                                          }
                                                        ),
                                                    },
                                                    on: {
                                                      selected: function (
                                                        $event
                                                      ) {
                                                        return _vm.onChangeAccountRole(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("vgp-input", {
                                                    class:
                                                      _vm.$.role_description,
                                                    attrs: {
                                                      value:
                                                        _vm.resourceData.role
                                                          .title,
                                                      small: true,
                                                      "is-disabled": true,
                                                      "gp-autotest-name":
                                                        "gp_autotest_resource_card_input_role_title",
                                                    },
                                                  }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1616205243
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.resourceCalendarAccess
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.$.panel_calendar,
                                    !_vm.pricingAccessToResourceCalendar
                                      ? "pricing-tooltip"
                                      : "",
                                  ],
                                  attrs: {
                                    "data-y": "-4",
                                    "data-position": "top",
                                    "data-feature": "personal_calendar",
                                  },
                                },
                                [
                                  _c("vgp-button", {
                                    class: _vm.$.panel_calendar_btn,
                                    attrs: {
                                      id: "gp_autotest_resource_btn_calendar",
                                      disabled:
                                        !_vm.pricingAccessToResourceCalendar,
                                      label: _vm.locales("common_view_calenar"),
                                      type: "text",
                                      icon: {
                                        name: "calendar",
                                        size: 24,
                                        type: "regular",
                                      },
                                      "icon-side": "left",
                                      small: true,
                                    },
                                    on: { onClick: _vm.openCalendar },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.icon_buttons },
                          [
                            _vm.resourceData.acсessToDelete &&
                            !_vm.resourceData.isJira
                              ? _c("icon-button", {
                                  attrs: {
                                    id: "gp_autotest_resource_btn_delete_resource",
                                    icon: { name: "delete" },
                                    size: "large",
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      return _vm.$emit(
                                        "onDeleteResource",
                                        _vm.resourceData
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("icon-button", {
                              style: { marginLeft: 6 + "px" },
                              attrs: {
                                id: "gp_autotest_resource_btn_close",
                                icon: { name: "close-1", type: "bold" },
                                size: "large",
                              },
                              on: { onClick: _vm.onClose },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$.resource_card_body },
                      [
                        _c("vgp-tabs", {
                          class: _vm.$.tabs,
                          attrs: {
                            tabs: _vm.tabs,
                            "active-by": "type",
                            active: _vm.tabs[0],
                            small: true,
                            "gp-autotest-name":
                              "gp_autotest_resource_card_tabs",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { ref: "panel", class: _vm.$.panel },
                          [
                            _c("input-search", {
                              style: { width: "290px" },
                              attrs: {
                                placeholder: _vm.locales(
                                  "common_search_by_name"
                                ),
                                value: _vm.searchValue,
                                "gp-autotest-name":
                                  "gp_autotest_resource_card_input_search_by_name",
                              },
                              on: { onInput: _vm.onSearch },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { class: _vm.$.panel_btn },
                              [
                                _c("vgp-button", {
                                  attrs: {
                                    type: "primary",
                                    label: _vm.locales("add_to_projects"),
                                    icon: {
                                      name: "add-user",
                                      size: "24",
                                      type: "regular",
                                    },
                                    "icon-side": "left",
                                    small: true,
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      _vm.isShowNewProjectsPopup = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { style: { padding: "16px" } },
                          [
                            _c("resource-projects", {
                              ref: "projectTable",
                              attrs: {
                                "is-material": _vm.isMaterial,
                                projects: _vm.resourceProjects,
                                "project-roles": _vm.projectRoles,
                                "pricing-access-to-cost-settings":
                                  _vm.pricingAccessToCostSettings,
                              },
                              on: {
                                onChangeCostType: _vm.onChangeCostType,
                                onChangeCost: _vm.onChangeCost,
                                onChangeRight: _vm.onChangeRight,
                                onDeleteFromProject: _vm.onDeleteFromProject,
                                onChangeProjectRole: _vm.onChangeProjectRole,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.resourceProjects.length
                          ? _c(
                              "div",
                              {
                                class: _vm.$.stub_block,
                                style: { height: _vm.stubHeight },
                              },
                              [
                                _c("div", { class: _vm.$.wrapper }, [
                                  _c("div", {
                                    ref: "picture",
                                    class: _vm.$.img,
                                    domProps: {
                                      innerHTML: _vm._s(_vm.stubImage),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { class: _vm.$.description }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.searchValue !== ""
                                            ? _vm.locales("no_search_result")
                                            : _vm.locales(
                                                "no_project_description"
                                              )
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("add-to-projects-popup", {
        attrs: {
          "is-show": _vm.isShowNewProjectsPopup,
          "project-roles": _vm.projectRoles,
          projects: _vm.anotherProjects,
          "resource-data": _vm.resourceData,
        },
        on: {
          onApply: _vm.assignToProjects,
          onClose: function ($event) {
            _vm.isShowNewProjectsPopup = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }