<template>
  <div :class="$.vgp_responsive_textarea">
    <div
      :class="[
        $.wrap,
        isFocus && !readonly ? $.focus : '',
        isFocus && !readonly && isError ? $.error : '',
        readonly ? $.readonly : '',
      ]"
    >
      <div
        ref="scroll"
        :class="$.scroll"
        :style="formatHeight && !isExpanded ? `height: ${formatHeight}px;` : ''"
      >
        <textarea
          ref="area"
          v-model="areaValue"
          :class="$.area"
          :readonly="readonly"
          :disabled="readonly"
          spellcheck="false"
          tabindex="-1"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @change="onChange"
        />
      </div>
    </div>
    <vgp-icon-button
      v-if="isShowButton"
      :style="{'margin-top': '4px'}"
      :icon="{ name: isExpanded ? 'arrow-top' : 'arrow-down', type: 'bold', size: 20 }"
      :label="isExpanded ? locales('common_collapse') : locales('common_expand')"
      icon-side="right"
      bold
      :colors="{
        color: '#808080',
        hoverBg: '#F8F8F8',
      }"
      @onClick="onToggleExpand"
    />
  </div>
</template>

<script>
import VgpIconButton from '../globalButton/withIcon/iconButton.vue';

export default {
  components: {
    VgpIconButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: null,
    },
    startFocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    onFocus: null,
    onBlur: null,
    onInput: String,
    onChange: String,
    onResize: null,
    onKeyup: String,
  },
  data() {
    return {
      locales: __,
      areaValue: '',
      isFocus: false,
      isExpanded: false,
      isOverflow: false,
    };
  },
  computed: {
    formatHeight() {
      if (!this.height) return null;

      return this.height;
    },
    isShowButton() {
      return !this.isFocus && this.isOverflow;
    },
  },
  watch: {
    value() {
      this.areaValue = this.value;
      setTimeout(this.calcHeight);
    },
    height() {
      setTimeout(this.calcHeight);
    },
    areaValue(newVal) {
      this.$emit('onKeyup', newVal);
    },
  },
  created() {
    this.areaValue = this.value;
  },
  mounted() {
    if (this.startFocus) this.$refs.area && this.$refs.area.focus();
    this.calcHeight();
  },
  methods: {
    calcHeight() {
      const area = this.$refs.area;

      if (!area) return;

      area.style.minHeight = this.formatHeight ? `${this.formatHeight}px` : '';
      area.style.height = '';

      const areaHeight = area.scrollHeight;

      area.style.height = `${areaHeight}px`;

      this.isOverflow = this.formatHeight && this.formatHeight < areaHeight;

      if (!this.isOverflow) {
        this.isExpanded = false;
      }

      this.$emit('onResize');
    },
    onFocus() {
      this.isFocus = true;
      if (!this.isError) this.areaValue = this.value;
      this.$emit('onFocus');
    },
    onBlur() {
      if (this.isError) return;
      this.isFocus = false;
      this.$emit('onBlur');

      if (this.$refs.scroll) this.$refs.scroll.scrollTop = 0;
    },
    onInput(e) {
      this.calcHeight();
      this.$emit('onInput', e.target.value);
    },
    onChange(e) {
      this.$emit('onChange', e.target.value);
    },
    onToggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.$emit('onResize');
    },
  },
};
</script>

<style module="$" src="./vInput.less"></style>
