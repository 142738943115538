
var innerObject = {
  isIE11: function () {
    return GT.agent.family === "IE" && GT.agent.major === 11;
  },
  isSafari: function () {
    return GT.agent.family === "Safari";
  }
};

export default innerObject;
