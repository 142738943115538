var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: { show: _vm.show, width: 283 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.create_new_event_popup }, [
              _c("div", { class: _vm.$.header }, [
                _c("div", { class: _vm.$.title }, [
                  _c("div", { class: _vm.$.title__date }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.date.dayWeek +
                            ", " +
                            _vm.date.dayMonth +
                            " " +
                            _vm.date.month
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.title__desc }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("create_new_event_title")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$.close },
                  [
                    _c("icon-button", {
                      attrs: {
                        icon: { name: "close-1", type: "bold" },
                        size: "small",
                      },
                      on: { onClick: _vm.onClose },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.body },
                [
                  _c("vgp-input", {
                    ref: "nameInput",
                    attrs: {
                      "initial-focus": true,
                      value: _vm.taskName,
                      small: true,
                      placeholder: _vm.locales("new_event_task_name"),
                    },
                    model: {
                      value: _vm.taskName,
                      callback: function ($$v) {
                        _vm.taskName = $$v
                      },
                      expression: "taskName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$.resources },
                    [
                      !_vm.selectedResources.length && !_vm.isShowResourcesList
                        ? _c(
                            "div",
                            { class: _vm.$.resources__assign_button },
                            [
                              _c("icon-button", {
                                attrs: {
                                  icon: { name: "assigned" },
                                  size: "middle",
                                  bold: "",
                                  label: _vm.locales(
                                    "settings_task_resources_add_new"
                                  ),
                                  colors: _vm.buttonColors,
                                  sizes: _vm.buttonLabelSizes,
                                },
                                on: { onClick: _vm.openResourcesList },
                              }),
                            ],
                            1
                          )
                        : _c("vgp-dropdown", {
                            ref: "dropdown",
                            staticClass:
                              "gp_autotest_calendar_view_event_popup_dropdown",
                            attrs: {
                              "drop-list": _vm.preparedResources,
                              selected: _vm.selectedResources,
                              icon: { name: "user", size: 20, type: "regular" },
                              multiselect: true,
                              "use-search": true,
                              "use-select-all": true,
                              "show-reset-btn": false,
                              "item-template": "user",
                              "track-by-img-url": "photo",
                              "track-by": "id",
                              "track-by-text": "name",
                              "track-by-icon-name": "icon",
                              "track-by-bg-color": "color",
                              "group-by": "group",
                              "show-selected-first": true,
                              placeholder: "",
                            },
                            on: {
                              change: _vm.onChange,
                              close: _vm.closeResourcesList,
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("vgp-button", {
                    attrs: {
                      type: "primary",
                      disabled: _vm.taskNameString === "",
                      label: _vm.locales("create_new_event_task"),
                    },
                    on: { onClick: _vm.onCreateTask },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }