<template>
  <div :class="$style.filter_footer">
    <div
      id="gp_autotest_global_filter_btn_save"
      :class="[$style.filter_footer_btn,
               checkPricingAccess('saving_filters') ? '' : 'pricing-tooltip']"
      style="{color: red, background: green}"
      data-position="left"
      data-feature="saving_filters"
      @click="clickSaveBtn"
    >
      {{ locales.save }}
      <div
        v-if="!checkPricingAccess('saving_filters')"
        class="pricing-title-container"
      >
        {{ locales.all(pricingPlan) }}
      </div>
    </div>
  </div>
</template>

<script>

import pricingHelpers, { getPlans } from '../../../helpers/pricingHelper';

export default {
  name: 'FooterFilter',
  props: {
    saveHandler: { type: Function, required: true },
  },

  data() {
    return {
      locales: {
        save: __('common_save'),
        all: __,
      },
      ids: {
        popup: 'filterSavePopup_v',
        submit: 'filterSavePopupSubmit_v',
        input: 'filterSavePopupNameField_v',
      },
    };
  },
  computed: {
    pricingPlan() {
      return `${getPlans('saving_filters')}_plan_locale`;
    },
  },
  mounted() {
    const component = this;

    const savePopup = {
      view: 'ganttproWindowPopup',
      id: component.ids.popup,
      css: 'filter-project_popup_save filter-save-popup-for-vue',
      rightAnimate: false,
      width: 440,
      hidden: true,
      zIndex: 112,
      header: {
        label: __('filter_save_popup_header'),
      },
      body: {
        paddingX: 24,
        view: 'form',
        css: 'filter-project_popup_save_body',
        minWidth: 372,
        height: 160,
        elements: [
          {
            id: component.ids.input,
            css: 'filterSavePopupNameField',
            view: 'text',
            keyPressTimeout: 100,
            label: __('filter_save_popup_name'),
            labelAlign: 'top',
            labelPosition: 'top',
            on: {
              onKeyPress(code, event) {
                if (code === 13) {
                  event.preventDefault();

                  if ($$(component.ids.submit).isEnabled()) {
                    return component.saveFilter();
                  }
                }
              },
              onTimedKeyPress() {
                const $$name = $$(component.ids.input);
                const $$save = $$(component.ids.submit);
                const value = $$name.getValue();

                if ((!value || !value.length || !value.replace(/\s/g, '').length)) {
                  $$save.disable();
                } else {
                  $$save.enable();
                }
              },
            },
          },
          { height: 10 },
          {
            rows: [
              {
                cols: [
                  {
                    id: component.ids.submit,
                    view: 'button',
                    width: 100,
                    value: __('filter_save_popup_submit'),
                    type: 'success',
                    css: 'filter-save-button',
                    align: 'left',
                    disabled: true,
                    on: {
                      onItemClick() {
                        return component.saveFilter();
                      },
                    },
                  },
                  {},
                  {
                    view: 'button',
                    width: 100,
                    css: 'button_default',
                    value: __('common_cancel'),
                    align: 'right',
                    on: {
                      onItemClick() {
                        $$(component.ids.popup).hide();
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      on: {
        onHide() {
          $$(component.ids.input).setValue('');
          $$(component.ids.submit).disable();
        },
      },
    };

    savePopup.$scope = component;
    if (!$$(this.ids.popup)) {
      webix.ui(savePopup);
    }
  },

  methods: {
    checkPricingAccess(feature) {
      return pricingHelpers.checkPricingAccess(feature);
    },
    clickSaveBtn() {
      if (pricingHelpers.checkPricingAccess('saving_filters')) {
        $$(this.ids.popup).show();
        const input = document.querySelector('.filter-project_popup_save input');

        input.focus();
      }
    },
    async saveFilter() {
      const name = $$(this.ids.input).getValue().trim();

      if (!name) {
        $$(this.ids.input).setValue('');
        $$(this.ids.submit).disable();
        const input = document.querySelector('.filter-project_popup_save input');

        input.focus();
      }
      if (name) {
        const saved = await this.saveHandler(name);

        if (!saved) {
          $$(this.ids.input).setValue('');
          $$(this.ids.submit).disable();

          const input = document.querySelector('.filter-project_popup_save input');

          input.focus();

          return;
        }

        $$(this.ids.popup).hide();
      }
    },
  },
};
</script>

<style module src="../filter.less" lang="less" />
