let twoFa = {};
twoFa.getQR = function () {
  return new Promise((resolve, reject)=>{
    webix.ajax().get('/api/twoFa/qr', {}, function (data) {
      resolve(JSON.parse(data));
    });
  });
};

twoFa.verify = function (obj) {
  return new Promise((resolve, reject)=>{
    webix.ajax().post('/api/twoFa/verify', obj, function (data) {
      resolve(JSON.parse(data));
    });
  });
};

twoFa.disable = function (obj) {
  return new Promise((resolve, reject)=>{
    webix.ajax().del('/api/twoFa', obj, function (data) {
      resolve(JSON.parse(data));
    });
  });
};


export default twoFa;

