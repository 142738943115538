import { api } from '$$store/api';
import MaskParser from '../libs/bitwiseMask';
import plansModel from '../models/plans';

const Mask = new MaskParser();

const PaymentStore = {
  namespaced: true,
  state: {
    plans: [],
  },
  getters: {
    getAccessToFeature: () => featureName => (plansModel.isTrial() ? true : Mask.checkPermission(user.accountPlanData.mask, user.paymentFeatures[featureName].id)),
    getPlans: state => state.plans,
  },
  mutations: {
    setPlans(state, payload) {
      state.plans = [...payload];
    },
  },
  actions: {
    loadData({ commit }) {
      // api.get('/payment/plans')
      //   .then(response => {
      //     console.log(response.data);
      //   });
    },
  },
};

export default PaymentStore;
