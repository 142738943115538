<template>
  <div
    class="status-row"
  >
    <vgp-color-palette
      :selected-values="color"
      :select-field="'select'"
      :select-type="'single'"
      :colors="colors"
      :select-width="'72px'"
      class="color-area"
      @selectColors="onChangeColor"
    />
    <vgp-input
      :type="'status'"
      :placeholder="locale('enter_name_locale')"
      :value="localeArray.includes(value) ? locale(value) : value"
      class="input-area"
      :select-by-focus="true"
      @onChange="onChangeName"
      @onKeydown="onKeyDown"
    />
    <div
      class="trash-area"
      :class="{'hoverable': !isDefault }"
    >
      <svg-sprite
        v-if="!isDefault"
        :name="'delete'"
        class="delete"
        @click="onShowDeletePopup"
      />
      <svg-sprite
        v-if="isDefault"
        :name="'warning-2'"
        class="tooltip-gantt warning-icon"
        data-key="project_statuses_default_status"
        data-position="top"
      />
    </div>
    <core-confirm-popup
      :show="!!deleteInfoData"
      :popup-width="440"
      :text="locale('project_status_delete_description')"
      :ok-button="{
        title: locale('common_delete'),
        type: 'destructive',
      }"
      :cancel-button="{
        title: locale('common_cancel'),
        type: 'secondary',
      }"
      @onClickOk="onCloseDeletePopup('ok')"
      @onClickCancel="onCloseDeletePopup"
    />
  </div>
</template>

<script>

import CoreConfirmPopup from '../../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';

export default {
  components: { CoreConfirmPopup },
  props: {
    colors: {
      type: Array,
      required: true,
    },
    colorValue:
    {
      type: Array,
      required: true,
    },
    inputValue:
   {
     type: String,
     required: true,
   },
    rowId:
   {
     type: Number,
     required: true,
   },
    isDefault: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: __,
      value: '',
      color: null,
      deleteInfoData: null,
      localeArray: ['no_status_locale', 'on_track_locale', 'at_risk_locale', 'off_track_locale'],
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.value = this.inputValue;
      this.color = this.colorValue;
    },
    onChangeColor(data) {
      this.$emit('change', {
        property: 'color',
        value: data[0].id,
      });
    },
    onChangeName(e, newName) {
      const name = newName.trim();

      this.$emit('change', {
        property: 'value',
        value: name,
      });
    },
    onShowDeletePopup() {
      this.deleteInfoData = { info: this.rowId };
    },
    onCloseDeletePopup(type) {
      this.deleteInfoData = null;
      if (type === 'ok') {
        this.$emit('delete');
      }
    },
    onKeyDown(e, value) {
      if (e.keyCode === 13) {
        this.onChangeName(e, value);
        e.target.blur();
      }
    },
  },
};

</script>

<style scoped src='../less/settingRow.less'></style>
