import constants from '../../helpers/constants';
import { DARKEST_COLORS } from './constants';
import rights from '../../components/rights/index';
import store from '../../store/main';
import projectsModel from '../../models/projects';
import moment from '../../libs/moment';

const calendarHelpers = {
  getTasksMap(tasks) {
    return tasks.reduce((tasksMap, task) => {
      tasksMap.set(task.id, task);

      return tasksMap;
    }, new Map());
  },
  prepareCalendarEvents(tasks) {
    return tasks.filter(task => task.type === 'task')
      .filter(task => this.hasAccessToTask(task))
      .map(task => this.prepareEvent(task));
  },
  prepareEvent(taskData) {
    const preparedColors = constants.PALETTE_CONFIG.palette.flat().map(item => ({
      ...item,
      dark_color: DARKEST_COLORS[item.color],
    }));
    const defaultColor = preparedColors.find(item => item.color === '#50C7D6');
    const taskColor = preparedColors.find(item => item.id === +taskData?.color);

    return {
      id: taskData.id,
      start_date: taskData.start_date,
      end_date: taskData.end_date,
      text: taskData.text,
      // resources: taskData.resources,
      ganttId: taskData.gantt_id,
      allDay: true,
      color: {
        background: taskColor?.color || defaultColor?.color,
        border: taskColor?.color || defaultColor?.color,
        darkColor: taskColor?.dark_color || defaultColor?.dark_color,
      },
    };
  },
  hasSomeTasksWithAccess(tasks) {
    return tasks.some(task => this.hasAccessToTask(task));
  },
  tasksWithAccess(tasks) {
    return tasks.filter(task => this.hasAccessToTask(task));
  },
  hasAccessToTask(task) {
    if (!task) return false;
    if (this.hasAccessToAllTasks(task.gantt_id)) return true;

    const userId = rights.getUserId();
    const resourceData = store.getters['resourcesModel/getResourceByUserId'](userId);

    return task.resources?.find(r => r.resource_id === resourceData.id);
  },
  hasAccessToAllTasks(ganttId) {
    return rights.project.hasRight(ganttId, 'all_tasks');
  },
  hasAccessToCreateTask(projectId) {
    return rights.project.hasRight(projectId, 'create_task');
  },
  checkAccessToTasks(projectId) {
    const tasks = store.getters['tasksModel/getTasksForGantt'](projectId).data;

    return this.hasAccessToAllTasks(projectId) || this.hasSomeTasksWithAccess(tasks);
  },
  getWeekendCssSelector(cellDate, ganttInstance) {
    const projectId = projectsModel.getActiveGanttId();
    const projectCalendar = ganttInstance.getCalendar(projectId);

    if (!projectCalendar) {
      return;
    }

    const dates = projectCalendar.getConfig().dates;
    const preparedDates = {};

    Object.entries(dates).forEach(([key, value]) => {
      let current = +key;

      if (key.length > 1) {
        const date = new Date(+key);
        const momentData = moment(date);
        const day = momentData.startOf('day');

        current = new Date(day).getTime();
      }

      preparedDates[current] = value;
    });

    const calendarDate = new Date(cellDate);
    const calendarDayTime = calendarDate.getTime();
    const dayOfWeek = calendarDate.getDay();

    if ((preparedDates[dayOfWeek] === false && !preparedDates[calendarDayTime]?.length) || preparedDates[calendarDayTime] === false) {
      return 'custom-weekend';
    }

    return '';
  },
};

export default calendarHelpers;
