var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.reports }, [
    _vm.accessToReports
      ? _c(
          "div",
          { class: _vm.$style.reportsCardList },
          _vm._l(_vm.reportCards, function (card) {
            return _c(
              "div",
              {
                key: card.id,
                class: _vm.$style.cardItem,
                on: {
                  click: function ($event) {
                    return _vm.selectReport(card.type)
                  },
                },
              },
              [
                _c("div", {
                  class: _vm.$style.icon,
                  style: { backgroundImage: "url(" + card.icon + ")" },
                }),
                _vm._v(" "),
                _c("div", { class: _vm.$style.title }, [
                  _vm._v("\n        " + _vm._s(card.title) + "\n      "),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.description }, [
                  _vm._v("\n        " + _vm._s(card.description) + "\n      "),
                ]),
                _vm._v(" "),
                card.linkName
                  ? _c("div", { class: _vm.$style.link }, [
                      _vm._v("\n        " + _vm._s(card.linkName) + "\n      "),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _c("div", { class: _vm.$style.reports_not_access }, [
          _c(
            "div",
            { class: _vm.$style.upgrade_info },
            [
              _c("div", { class: _vm.$style.title_wrapper }, [
                _c("div", { class: _vm.$style.title }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("reports_access_title")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pricing-title-container" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales(_vm.planTitle)) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.text }, [
                _c("div", { staticClass: "desc-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("reports_access_text")) +
                      "\n          "
                  ),
                  _c(
                    "div",
                    { staticClass: "learn_more" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pricing-popup-link",
                          attrs: {
                            href: _vm.locales("reports_learn_more"),
                            target: "_blank",
                          },
                          on: { click: _vm.linkClick },
                        },
                        [_vm._v(_vm._s(_vm.locales("help_read_more")))]
                      ),
                      _vm._v(" "),
                      _c("webix-ui", {
                        staticClass: "arrow-left",
                        attrs: { config: _vm.icons.arrowLeft },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.isOwner
                ? _c("upgrade-btn", { attrs: { feature: "reports" } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.pic_wrapper }, [
            _c("img", { attrs: { src: _vm.accessPic, alt: "" } }),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }