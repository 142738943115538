import moment from '../../libs/moment';
import constants from '../../helpers/constants';
import status from '../../helpers/status';
import dateHelper from '../../helpers/dateFormats';
import _ from '../../libs/lodash';
import ExcelJS from '../../libs/exceljs/dist/exceljs';
import filesaver from '../../libs/filesaver.min';

const colorIdToHash = {};

constants.PALETTE_CONFIG.palette.map(subarray => {
  subarray.map(obj => {
    colorIdToHash[obj.id] = obj.color;
  });
});

const hexTobackgroundAttributes = hex => {
  if (hex && hex.split && hex.split('#')[1]) {
    return {
      attributes: {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: `FF${hex.split('#')[1]}`,
          },
        },
      },
    };
  }

  return { attributes: {} };
};

const hexToforegroundAttributes = hex => {
  if (hex && hex.split && hex.split('#')[1]) {
    return {
      attributes: {
        font: {
          color: {
            argb: `FF${hex.split('#')[1]}`,
          },
        },
      },
    };
  }

  return { attributes: {} };
};

const __ = window.__;

var innerObject = {
  init: {
    run() { },
    afterInit() { },
  },

  createExcelWorkSheet(workbook, exportOptions, index) {
    exportOptions.headBannerRowSize = 3;
    const minHeaderCellSize = 5;
    const exportData = exportOptions.worksheets[index];
    const worksheet = workbook.addWorksheet(innerObject.safeNameForXlsx(exportData.title));
    const columnsSettings = innerObject.serializeColumns(exportOptions, index);
    const xlsxPreparedData = innerObject.serializeTable(exportOptions, columnsSettings, index);
    const data = columnsSettings.headers.concat(xlsxPreparedData).concat(columnsSettings.footers);

    // worksheet.views = [
    //   {state: 'frozen', ySplit: columnsSettings.headers.length}
    // ]; // displays badly on mac

    // worksheet.views = [
    //   {activeCell: ('A'+ (exportOptions.headBannerRowSize + 1))}
    // ]; // not working

    data.map((row, index) => {
      const rowNumber = index + 1;
      const workrow = worksheet.addRow(row.values.map(a => a.value));

      workrow.eachCell((cell, colNumber) => {
        const colIndexFromZero = colNumber - 1;
        const column = columnsSettings.columns[colIndexFromZero];
        let cellAttributes = row.values[colIndexFromZero].attributes || {};
        const merge = row.values[colIndexFromZero].merge;
        const colWidth = row.values[colIndexFromZero].colWidth;

        if (colWidth) {
          const col = worksheet.getColumn(colNumber);

          col.width = colWidth;
        }

        if (row.attributes) {
          cellAttributes = _.merge(_.assign({}, cellAttributes), _.assign({}, row.attributes));
        }

        if (column.attributes) {
          cellAttributes = _.merge(_.assign({}, cellAttributes), _.assign({}, column.attributes));
        }
        cell = _.merge(cell, cellAttributes);
        if (merge && merge.horizontal) {
          worksheet.mergeCells(rowNumber, colNumber, rowNumber, colNumber + merge.horizontal);
        }
      });
    });

    const logoCellLength = Math.ceil(columnsSettings.headers[0].values.length / 2) - Math.floor(minHeaderCellSize / 2); // должно быть минимум 5 колонок

    if (columnsSettings.headers[0].values.length >= minHeaderCellSize) {
      worksheet.mergeCells(1, 1, exportOptions.headBannerRowSize, logoCellLength);
      worksheet.mergeCells(Math.ceil(exportOptions.headBannerRowSize / 2), logoCellLength + 1, Math.ceil(exportOptions.headBannerRowSize / 2), columnsSettings.headers[0].values.length);
    }

    const img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAA8CAYAAABYfzddAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASpSURBVHgB7dyBddpIEMbxyVXg64ASch2QCu6ugvgq8F0F5iqIO8CpIOlA6sDuADpIOpjsBngRZHfZXe0IQf6/93i2kfzNSCCQVgIRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwT6q6cLdO29q421sp5P7nX3f7sv//lRQ6WZa1/1sq+N7d7WWf83K6LGqzzl4O/VrnU6OsxqwZLPxBV9jHfSBjWZjRnfz/puaB0B8bb3BZAtNb6abIj6wrixo3sRxW3kgDvlMx8sbJnde1sXE/Fid39y7inWSKLMury/hDCoRyhstivc6meEyokV/Dym8yb6+5M7rH4F5+3ni9pY7flfG7w2tpqxcbrxPlYwbmvAF/dbd/CuZ/SEx7L+P53fOVtOOXrfXG4PP+NszvB/m4FaGdf5mQK7c8cyziB7XuMrPOyXoxaLFOWmRY5rfqL5VzKzWszH0XOte5jcpvvA/SxpNWjI4DFswGsawP3ge1F+7HJmPWr66l3zPycl41t+72zuVtS3JK14n1eh2b36q/VM6t1LByC+/Aj4H7tvLzIM6dFp5SSli42yfN3C0HZu0S+/77ugsNe6/h4+Kz5+UieR8i969LcqRQiwzL/Fb9pXJupcasXapxV2YVKL0ZTP8SmL48kxlcFo2f7F+V5BQu3+gMy/xW/aVybqWGlcl3oXV3iWFowzqVcylaaPDq8+D3p8D02sEsf/pkG7j/UTNHpn8FWkEKaQVBXMkK07JL47pEzn3kfxaDefxxb+jF4i6RG10W3e2yh/L8fW9zc3K1yLDMV0OXriEVWuWUuMQgVskpmGViWmjw6nk4Mux+9xeDfA7MV/UuvM8OXcjgXxD8oNZCgAldYgPuZSSNXzb5HLjvY+A+/4mlqhFktxH37sd/gUkLYWS6lSku1+SS0IOSXQfdHQNvtHA35ySj0/EeItm5PSRHpnNzWq3XGmPz1Uanx4dBF6khFVrlTG7KFaDxU0elXmp7GMzbRbJXJTmJ/NEZlvnW/ZXU0N14R2x8xd9fNe5h0evsTLkCdPch+1aWNT0M5vVPmo1mSix6LH90hmW+dX+lNXT34r7RsHWLGq16nZWpVoC2e/c96Ep7CMwfG5kuyolkj86wzLfur6aGpk9TrlrUSNRukjO5qVaAtn33PViW9BDpe5lTSAq1yLDMt+6vtobGTzF6f7WoEanbJKfE1VwLrbtBh2Vgkv/GjSwSHgH/U0ZKjEzjAtzj8ex+rCKT18rpvmNqaFDj7IUbGX2G3imPPiuc6iEj/1EzlqUgb3SGZb51f2NruFk/hR+J78fJyce8FbkGamhQIzTCuK7odRPIeUgtS0F86knDBjxxj5oeZEw+5q2IsWv6OGHoNMD/Uu4pM7uW/yqbreDi9lfi+S803AYm38SXMrTagHux0Q9+355Me059oD7hYyDrNVLzdNpZiSdNL+V6GdFLRX4vZfqTvy2uburH1Ehc/pp6zFvp5Rro7lRK6+/v7TT85dv+mNVfBVX9rqk/rgbzWY+JZTnqoWKddGNyWvVi1aN1fy1r6PEX7Xd6fAxs/vwFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABX5Bu5jpj5ei04TAAAAABJRU5ErkJggg==';
    const imageId2 = workbook.addImage({
      base64: img,
      extension: 'png',
    });

    worksheet.addImage(imageId2, {
      tl: { col: 0, row: 0 },
      ext: { width: 240, height: 60 },
      editAs: 'oneCell',
      // hyperlinks: {
      //   hyperlink: 'https://ganttpro.com?utm_source=excel_generated_header_logo&title=' + exportOptions.titleDate,
      //   tooltip: 'GanttPRO.com'
      // }
    });

    const topBannerMiddleRow = worksheet.getRow(Math.ceil(exportOptions.headBannerRowSize / 2));

    topBannerMiddleRow.eachCell((cell, colNumber) => {
      if (colNumber === logoCellLength + 1 && columnsSettings.headers[0].values.length >= 10) { // if less then 10 columns - do not show at all
        // cell.value = {
        // text: __('excel_generated_header_text') + '   ',
        // hyperlink: 'https://ganttpro.com?utm_source=excel_generated_header_text&title=' + exportOptions.titleDate,
        // tooltip: 'GanttPRO.com'
        // };
        cell.value = `${__('excel_generated_header_text')}   `;
        cell.font = { bold: false, color: { argb: 'FFFFFFFF' } };
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
    });

    const titleCellLength = Math.floor(columnsSettings.headers[0].values.length / 2);

    worksheet.mergeCells(exportOptions.headBannerRowSize + 1, 1, exportOptions.headBannerRowSize + 1, titleCellLength);
    worksheet.mergeCells(exportOptions.headBannerRowSize + 1, titleCellLength + 1, exportOptions.headBannerRowSize + 1, columnsSettings.headers[0].values.length);

    const titleRow = worksheet.getRow(exportOptions.headBannerRowSize + 1);

    titleRow.eachCell((cell, colNumber) => {
      if (colNumber === 1) {
        cell.value = innerObject.safeNameForXlsx(exportData.title);
      }
      if (colNumber === titleCellLength + 1) {
        if (exportOptions.server && exportOptions.projectStartDate) {
          cell.value = {
            formula: 'TODAY()',
            result: new Date(),
          };
        } else {
          cell.value = new Date();
        }
      }
    });
  },

  serializeTable(exportOptions, columnsSettings, index) {
    const exportData = exportOptions.worksheets[index];
    const columns = columnsSettings.columns;
    const out = [];

    exportData.rows.map((row, rowIndex) => {
      const outRow = { values: [] };

      columns.map(column => {
        let value = 0;
        const preprocessor = (typeof column.preprocessor === 'function') ? column.preprocessor : innerObject.fieldTypeHandlers[column.type];

        if (preprocessor) {
          value = preprocessor(row.data, column);
        } else {
          console.error('Please specify preprocessor in xlsx preprocessors for key', column);
        }
        outRow.values.push(value);
      });
      outRow.attributes = row.attributes;
      out.push(outRow);
    });

    return out;
  },

  serializeColumns(exportOptions, index) {
    const exportData = exportOptions.worksheets[index];
    let isHeaderHasSubColumns = false;

    const out = {};

    out.labels = { values: [] };
    out.subLabels = { values: [] };
    out.titlebar = { values: [] };
    out.spacers = { values: [] };
    out.columns = [];
    out.headers = [];
    out.footers = [];

    const cols = exportData.columns;

    for (let i = 0; i < cols.length; i++) {
      if (cols[i]) {
        const column = cols[i];

        const addColumn = function (column) {
          out.columns.push(column);

          out.spacers.values.push({
            value: '',
            attributes: {
              alignment: {
                wrapText: false,
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FF' + '00564c',
                },
              },
            },
          });

          out.labels.values.push({
            value: column.label || '',
            colWidth: column.colWidth, // can be undefined
            merge: column.merge,
            attributes: {
              font: {
                bold: true,
                size: 9,
                color: { argb: 'FF222222' },
              },
              alignment: {
                vertical: 'middle',
                horizontal: 'left',
                indent: (column.label === 'export_excel_color') ? 3 : 1, // to hide name in column with small width
              },
              border: {
                right: { style: 'thin', color: { argb: 'FFC1E3D5' } },
                bottom: { style: 'thin', color: { argb: 'FFC1E3D5' } },
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FF' + 'dafff0',
                },
              },
            },
          });

          out.subLabels.values.push({
            value: column.subLabel || '',
            colWidth: column.colWidth, // can be undefined
            // merge: column.merge,
            attributes: {
              font: {
                bold: true,
                size: 9,
                color: { argb: 'FF222222' },
              },
              alignment: {
                vertical: 'middle',
                horizontal: 'left',
                indent: (column.label === 'export_excel_color') ? 3 : 1, // to hide name in column with small width
              },
              border: {
                right: { style: 'thin', color: { argb: 'FFC1E3D5' } },
                bottom: { style: 'thin', color: { argb: 'FFC1E3D5' } },
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FF' + 'dafff0',
                },
              },
            },
          });

          out.titlebar.values.push({
            value: '',
            attributes: {
              alignment: { indent: 1, vertical: 'middle' },
              font: {
                bold: true,
                size: 9,
                color: { argb: 'FFFFFFFF' },
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FF' + '1a7367',
                },
              },
            },
          });
        };

        if (column.subColumns) {
          Object.keys(column.subColumns).map((key, index) => {
            const subColumn = column.subColumns[key];

            if (index === 0) {
              subColumn.label = column.label;
              subColumn.merge = { horizontal: 1 };
            }
            addColumn(subColumn);
            isHeaderHasSubColumns = true;
          });
        } else {
          addColumn(column);
        }
      } else {
        break;
      }
    }

    for (let i = 0; i < exportOptions.headBannerRowSize; i++) {
      out.headers.push(out.spacers);
    }

    out.headers.push(out.titlebar);

    out.headers.push(out.labels);

    if (isHeaderHasSubColumns) {
      out.headers.push(out.subLabels);
    }

    out.footers.push({ values: [{ value: '' }] });

    [1, 2].map(c => {
      if (c === 2 && !exportOptions.server) return; // 2-nd footer is only for templates
      out.footers.push({
        values: [{
          // value: {
          // text: "  " + __(`excel_export_footer_postfix${c}`),
          // hyperlink: `https://ganttpro.com?utm_source=excel_generated_footer_text_${c}&title=${exportOptions.titleDate}`,
          // tooltip: 'GanttPRO.com'
          // },
          value: __(`excel_export_footer_postfix${c}`),
          merge: { horizontal: cols.length - 1 },
          attributes: { font: { bold: false, color: { argb: 'FF888888' } } },
        }],
      });
    });

    return out;
  },

  fieldTypeHandlers: {

    text(task, column) {
      const html = task[column.key];

      return { colWidth: 11, value: (html ? (`${html}`) : '').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s\s+/g, ' ') };
    },

    middleText(task, column) {
      return _.merge(innerObject.fieldTypeHandlers.text(task, column), { colWidth: 22 });
    },

    longText(task, column) {
      return _.merge(innerObject.fieldTypeHandlers.text(task, column), { colWidth: 44 });
    },

    color(task, column) {
      // for colors dfined as id of color (example: 1, 4)
      const color = task[column.key];
      const hex = colorIdToHash[color];
      // let out = { value: (hex ? hex : '') };
      let out = { value: '' };

      out = _.merge(out, hexTobackgroundAttributes(hex));
      out.attributes.alignment = { indent: 3 };

      return out;
    },

    tag(task, column) {
      const tags = task[column.key] ? task[column.key] : [];
      const elems = tags.reduce((result, tag, index, arr) => {
        result.push({
          font: {
            color: {
              argb: `FF${tag.color.split('#')[1]}`,
            },
          },
          text: tag.value,
        });

        if (arr.length !== index + 1) {
          result.push({
            text: ', ',
          });
        }

        return result;
      }, []);

      const out = {
        value: {
          richText: elems,
        },
      };

      return out;
    },

    hex(task, column) {
      // for colors dfined as hex (example: #fff4455)
      const hex = task[column.key];
      let out = { value: (hex || '') };

      out = _.merge(out, hexTobackgroundAttributes(hex));

      return out;
    },

    numberWithBackground(task, column) {
      let value = task[column.key].value;
      const floatNumber = parseFloat(value);

      if (!_.isNaN(floatNumber)) {
        value = floatNumber;
      }
      let out = { value };

      out = _.merge(out, hexTobackgroundAttributes(task[column.key].backgroundHash));
      out = _.merge(out, hexToforegroundAttributes(task[column.key].foregroundHash));
      out.attributes.alignment = { vertical: 'middle', horizontal: 'center' };
      out.attributes.border = {
        top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
        bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
        right: { style: 'thin', color: { argb: 'FFBFBFBF' } },
        left: { style: 'thin', color: { argb: 'FFBFBFBF' } },
      };

      return out;
    },

    date(task, column) {
      const date = task[column.key];

      if (!date) return { value: '' };
      const m = moment(date);
      let utcDate = moment(m).utc().add(m.utcOffset(), 'm').toDate();

      if (_.isNaN(utcDate)) utcDate = '';

      return { colWidth: 11, value: utcDate };
    },

    dateRelatedToNow(task, column) {
      const date = task[column.key];
      let value = '';

      if (!date) return { value: '' };
      const m = moment(date);
      let utcDate = moment(m).utc().add(m.utcOffset(), 'm');
      const projectStartDate = moment(task.projectStartDate);
      const diff = utcDate.diff(projectStartDate, 'days');

      if (utcDate) {
        value = {
          formula: `TODAY()+${diff}`,
          result: moment().add(diff, 'days').toDate(),
        };
      }
      if (_.isNaN(utcDate) || !projectStartDate) utcDate = '';

      return { colWidth: 11, value };
    },

    number(task, column) {
      let value = task[column.key];
      const floatNumber = parseFloat(value);

      if (!_.isNaN(floatNumber)) {
        value = floatNumber;
      }

      return { value };
    },

    jira_key(task, column) {
      const value = task[column.key];

      if (!value) return { value: '' };
      const rx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
      const match = value.match(rx);

      if (match && match.length && match[2]) {
        return { value: match[2] };
      }

      return { value: '' };
    },
  },

  safeNameForXlsx(name, stripStaces) {
    const nameSpecialEscaped = name.replace(/\//g, ' ').replace(/\:/g, ' ').trim();

    if (stripStaces) {
      nameSpecialEscaped.replace(/\s+/g, '_');
    }

    return nameSpecialEscaped;
  },

  export(exportOptions) {
    status.handlers.showMainSpinner(true);
    const workbook = new ExcelJS.Workbook();

    workbook.calcProperties.fullCalcOnLoad = true;
    exportOptions.titleDate = innerObject.safeNameForXlsx(`${exportOptions.title}_(GanttPRO.com)_${webix.Date.dateToStr(dateHelper.getDateFormatForGanttGrid())(new Date())}`, true);
    exportOptions.title = innerObject.safeNameForXlsx(exportOptions.title, false);
    exportOptions.worksheets.map((data, index) => {
      innerObject.createExcelWorkSheet(workbook, exportOptions, index);
    });

    if (exportOptions.server) {
      saveAsExcelServer();
    } else {
      saveAsExcelClient();
    }
    async function saveAsExcelClient() {
      const buf = await workbook.xlsx.writeBuffer();

      filesaver.saveAs(new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${exportOptions.titleDate}.xlsx`);
    }

    async function saveAsExcelServer() {
      const buf = await workbook.xlsx.writeBuffer();
      const request = new XMLHttpRequest();

      request.open('POST', `/templates/xlsx/?${new URLSearchParams(exportOptions.server).toString()}`, true);
      request.onload = function (e) {
        try {
          const links = JSON.parse(this.responseText);

          return webix.message({
            type: 'success',
            text: `
              Land:  <a href="${links.landingUrl}" target="_blank" onclick="event.stopPropagation();">${links.landingUrl}</a><br><br>
              Gantt: <a href="${links.templateUrl}" target="_blank" onclick="event.stopPropagation();">${links.templateUrl}</a><br><br>
              Excel: <a href="${links.azureExcelUrl}" target="_blank" onclick="event.stopPropagation();">${links.azureExcelUrl}</a><br><br>
              Sheet: <a href="${links.googleSheetUrl}" target="_blank" onclick="event.stopPropagation();">${links.googleSheetUrl}</a>
            `,
            expire: 100000,
          });
        } catch (e) {
          return webix.message({ type: 'error', text: `${this.responseText}` });
        }
      };
      const blob = new Blob([buf], { type: 'text/plain' });

      request.send(blob);
    }
    status.handlers.hideMainSpinner();
  },
};

const outputObject = {
  export: innerObject.export,
  fieldTypeHandlers: innerObject.fieldTypeHandlers,
};

export default outputObject;
