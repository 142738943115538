import app from './../../../app';

import baseMode from './base';
import exportMode from './export';
import historyMode from './history';
import linkMode from './link';

let config = baseMode;

if (app.config.mode.isHistory) {
  config = historyMode;
} else if (app.config.mode.isExport) {
  config = exportMode;
} else if (app.config.mode.isLink) {
  config = linkMode;
}

export default config;