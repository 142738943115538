var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["plan-item", _vm.plan.name] }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "plan-name" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.locales(_vm.plan.name + "_plan_locale")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      !_vm.isAnnual
        ? _c(
            "div",
            {
              staticClass: "people-tooltip tooltip-gantt",
              attrs: {
                "data-key": _vm.locales("pricing_team_size_tooltip"),
                "data-position": "bottom",
                "data-y": 0,
                "data-x": 0,
              },
            },
            [
              _c("webix-ui", {
                staticClass: "people-icon",
                attrs: { config: _vm.icons.peopleIcon },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "count" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.peopleCount + "+") + "\n      "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { ref: "planDesc", staticClass: "desc" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.locales(_vm.plan.plan_name + "_plan_info_locale")) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.plan.plan_name !== "enterprise"
      ? _c("div", { staticClass: "price action-box" }, [
          _c(
            "div",
            { staticClass: "panel" },
            [
              _c("div", { staticClass: "usd" }, [
                _vm._v(
                  "\n        " + _vm._s("$ " + _vm.currentPrice) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("user-count-select", {
                attrs: {
                  label:
                    _vm.locales("per_user_locale") +
                    " " +
                    _vm.locales("per_month_key"),
                  "get-user-count": _vm.getUserCount,
                  "is-annual": _vm.isAnnual,
                  "is-popup": _vm.isPopup,
                  type: "buy-new-plan",
                  "number-of-plan": _vm.numberOfPlan,
                  "class-name": "user-count-margin",
                  plan: _vm.plan,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isPopup
            ? _c(
                "div",
                {
                  staticClass: "btn upgrade-btn",
                  on: {
                    click: function ($event) {
                      return _vm.buyPlanClick(_vm.plan)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.locales("upgrade_now_btn_locale")) +
                      "\n    "
                  ),
                ]
              )
            : (_vm.isPopup && !_vm.isCurrentPlan) ||
              !_vm.isCurrentUserCount ||
              !_vm.isCurrentPeriod
            ? _c(
                "div",
                {
                  staticClass: "btn upgrade-btn",
                  on: {
                    click: function ($event) {
                      return _vm.upgradePlanClick(_vm.plan)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.locales("upgrade_now_btn_locale")) +
                      "\n    "
                  ),
                ]
              )
            : _vm.isPopup &&
              _vm.isCurrentPlan &&
              _vm.isCurrentUserCount &&
              _vm.isCurrentPeriod
            ? _c(
                "div",
                { class: ["current-plan-sticker", _vm.plan.name] },
                [
                  _c("webix-ui", {
                    staticClass: "plan-check-icon",
                    attrs: { config: _vm.icons.check },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sticker-text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locales("current_plan")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "demo action-box" }, [
          _c(
            "div",
            { staticClass: "panel" },
            [
              _c("webix-ui", {
                staticClass: "icon",
                attrs: { config: _vm.icons.bublesIcon },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "demo-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("lets_talk_locale")) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn demo-btn", on: { click: _vm.demoClick } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.locales("demo_btn_locale")) + "\n    "
              ),
            ]
          ),
        ]),
    _vm._v(" "),
    _c("div", { ref: "features", staticClass: "plan-features" }, [
      _c("div", {
        staticClass: "features-desc",
        domProps: {
          innerHTML: _vm._s(
            _vm.locales(_vm.plan.plan_name + "_features_list_description")
          ),
        },
      }),
      _vm._v(" "),
      _c("ul", { staticClass: "features-list" }, [
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales(_vm.plan.plan_name + "_feature_1")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales(_vm.plan.plan_name + "_feature_2")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales(_vm.plan.plan_name + "_feature_3")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales(_vm.plan.plan_name + "_feature_4")) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "about-plan-link" },
      [
        _c(
          "a",
          {
            staticClass: "link",
            attrs: {
              href: _vm.locales("about_plan_href_" + _vm.plan.plan_name),
              target: "_blank",
            },
            on: {
              click: function ($event) {
                return _vm.linkClick(_vm.plan.plan_name)
              },
            },
          },
          [_vm._v(_vm._s(_vm.locales("about_link_" + _vm.plan.plan_name)))]
        ),
        _vm._v(" "),
        _c("webix-ui", {
          style: { height: "19px" },
          attrs: { config: _vm.icons.arrowLeft },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }