<template>
  <div :class="$.project_page">
    <stub />

    <kanban v-if="mode==='board'" />
    <list-view v-if="mode==='list'" />

    <workload v-if="mode==='workload'" />
    <resources v-if="mode==='resources'" />
    <overview v-if="mode==='overview'" />
    <calendar v-if="mode==='calendar'" />
    <export v-if="mode==='export'" />

    <vgp-core-popup
      :show="showBudgetModeConfirm"
      :title="autoBudgetNewValue ? locale('budget_auto_mode_change_title') : locale('budget_manual_mode_change_title')"
      :closeIcon="false"
      :buttonsSwitch="true"
      :okButtonTitle="locale('common_confirm')"
      :cancelButtonTitle="locale('common_cancel')"
      :footerStyle="'flex-end'"
      @onClickCancel="toggleAutoBudgetPopup"
      @onClickOk="applyAutoBudget"
    >
      <template #body>
        <div :class="$.budget_mode_confirm_wrapper">
          <div :class="$.confirm_message">{{ autoBudgetNewValue ? locale('budget_auto_mode_confirm_message') : locale('budget_manual_mode_confirm_message') }}</div>
          <div
            :class="$.clear_costs_switcher"
            v-if="!autoBudgetNewValue"
          >
            <vgp-checkbox
              :class="$.checkbox"
              :size="16"
              :checked="clearCosts"
              @onClick="clearCosts = !clearCosts"
            />
            <div
              :class="$.checkbox_label"
              @click="clearCosts = !clearCosts"
            >
              {{ locale('budget_delete_costs_values') }}
            </div>
          </div>
        </div>
      </template>
    </vgp-core-popup>

    <router-view />
  </div>
</template>

<script>
import routerGuard from '../../router/guards';
import projectsModel from '../../models/projects';
import Kanban from '../kanban/Kanban.vue';
import Workload from './workloadPage/workloadPage.vue';
import Export from '../export/Export.vue';
import ProjectOverview from '../overview/ProjectOverview.vue';
import ProjectResources from '../resources/main.vue';
import listView from '../listView/listView.vue';
import stub from '../stubs/stubPage.vue';
import calendar from '../calendarView/main.vue';
import app from '../../app';

export default {
  name: 'ProjectPage',

  components: {
    kanban: Kanban,
    workload: Workload,
    export: Export,
    resources: ProjectResources,
    'list-view': listView,
    overview: ProjectOverview,
    stub,
    calendar,
  },
  beforeRouteUpdate: routerGuard.guard,
  props: {
    mode: {
      type: String,
      default: 'gantt',
    },
  },

  data() {
    return {
      locale: __,
      showBudgetModeConfirm: false,
      clearCosts: false,
      autoBudgetNewValue: false
    };
  },
  methods: {
    toggleAutoBudgetPopup() {
      if (!this.showBudgetModeConfirm) {
        this.clearCosts = false;
      }

      this.showBudgetModeConfirm = !this.showBudgetModeConfirm;
    },
    applyAutoBudget() {
      userExtAnalytics.log('budget_calculation_mode_switch', {
        mode: this.autoBudgetNewValue ? 'auto' : 'manual',
        clearCosts: this.clearCosts
      });
      this.$store.dispatch('tasksModel/updateTasksBudgetAndCost', {
        status: this.autoBudgetNewValue,
        isDeleteTask: this.clearCosts,
        projectId: this.$route.params.projectId,
        projectTasks: this.$store.getters['tasksModel/getTasksByGanttIds']([this.$route.params.projectId])
      });
      this.toggleAutoBudgetPopup();
    }
  },
  watch: {
    mode(mode) {
      projectsModel.setActiveProjectMode(mode);
    },
  },
  beforeCreate() {
    app.on('setting:autoBudget:showConfirm', (autoBudgetNewValue) => {
      this.autoBudgetNewValue = autoBudgetNewValue;
      this.toggleAutoBudgetPopup();
    });
  },
  beforeDestroy() {
    app.off('setting:autoBudget:showConfirm');
  },
  mounted() {
    projectsModel.setActiveProjectMode(this.mode); // init render
  },
}
</script>

<style module="$" src="./less/projectPage.less"></style>
