<template>
  <core-popup
    :show="show"
  >
    <template #body>
      <div :class="$.image_uploader">
        <div :class="$.head">
          <div :class="$.popup_title">
            {{ title }}
          </div>
        </div>
        <div :class="$.upload_body">
          <div
            v-if="croppedSrc"
            :class="[$.inner_box, $.inner_box__crop_area]"
          >
            <div
              ref="croppWrapper"
              :class="$.inner_wrapper"
            >
              <img
                ref="croppedImg"
                :class="$.crop_img"
                :src="croppedSrc"
                alt=""
              >
            </div>
          </div>
          <div
            v-else
            ref="dropArea"
            :class="[$.inner_box, $.inner_box__upload_area, $.drop_area]"
          >
            <div :class="$.inner_wrapper">
              <div :class="$.upload_icon">
                <img
                  :src="uploadIcon"
                  alt=""
                >
              </div>
              <div :class="$.popup_text">
                {{ locales('drop_area_description') }}
              </div>
              <div :class="$.upload_input">
                <input
                  id="userInput"
                  ref="userInput"
                  name="file"
                  :class="$.input"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  @change="handleChange"
                >
                <label
                  for="userInput"
                  :class="[$.popup_btn, $.upload]"
                >{{ locales('avatar_upload_button') }}</label>
              </div>
              <div :class="$.popup_text">
                {{ locales('drop_area_formats_label') + ': ' + formats.map(e => `.${e}`).join(', ') }}
              </div>
            </div>
          </div>
        </div>
        <div :class="$.footer">
          <vgp-button
            type="secondary"
            :small="true"
            :label="locales('common_cancel')"
            @onClick="closeUploader"
          />
          <vgp-button
            :style="{marginLeft: '12px'}"
            :small="true"
            :type="'primary'"
            :label=" locales('common_save')"
            :disabled="!file"
            @onClick="saveUploadImg"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import Cropper from '../../libs/cropper.min';
import dataUrlToBlob from '../../helpers/dataURItoBlob';
import corePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import vgpButton from '../common/VueComponents/globalButton/vgpButton.vue';

export default {
  name: 'ImageUploaderPopup',
  components: { corePopup, vgpButton },
  props: {
    show: { type: Boolean, required: true, default: false },
    title: { type: String, required: true, default: '' },
    type: { type: String, required: false, default: '' },
  },
  emits: {
    onLoad: Object,
    onClose: null,
  },
  data() {
    return {
      locales: __,
      uploadIcon: `${GT.cdn}/imgs/webix_material/upload.svg`,
      formats: ['jpg', 'png', 'jpeg'],
      cropper: null,
      file: null,
      dragFile: null,
      croppedSrc: null,
    };
  },
  watch: {
    show(flag) {
      if (flag) {
        this.$nextTick(() => this.setListeners());
      }
    },
  },
  methods: {
    setListeners() {
      const dropArea = this.$refs.dropArea;

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      function highlight() {
        dropArea.classList.add('highlight');
      }

      function unhighlight() {
        dropArea.classList.remove('highlight');
      }

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      dropArea.addEventListener('drop', this.handleDrop);
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const files = dt.files;

      this.setFile(files[0]);
    },
    handleChange() {
      const file = this.$refs.userInput.files[0];

      this.setFile(file);
    },
    setFile(file) {
      let type = file.type.toLowerCase();

      if (!type.includes('png') && !type.includes('jpg') && !type.includes('jpeg')) {
        webix.message({ type: 'warning', text: 'Only PNG or JPG images!' });
        type = '';

        return false;
      }

      this.file = file;
      const src = window.URL.createObjectURL(file);

      this.croppedSrc = src;
      setTimeout(this.initCropper, 0);
    },
    initCropper() {
      const img = this.$refs.croppedImg;

      const config = {
        autoCrop: true,
        viewMode: 3,
        minCropBoxWidth: 250,
        background: false,
      };

      if (this.type === 'avatar') {
        config.aspectRatio = 1;
      }

      this.cropper = new Cropper(img, config);
    },
    closeUploader() {
      this.croppedSrc = null;
      this.file = null;
      this.cropper = null;
      this.$emit('onClose');
    },
    saveUploadImg() {
      if (!this.file) {
        return false;
      }

      const originalFile = this.file;
      const b64Data = this.cropper.getCroppedCanvas().toDataURL(originalFile.type);
      const finalFile = dataUrlToBlob(b64Data, originalFile.name);

      this.$emit('onApply', finalFile);
      this.closeUploader();
    },
  },
};
</script>

<style module='$' src='./less/imageUploader.less'></style>
