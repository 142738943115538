var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "closer",
      style: {
        width: (_vm.size ? _vm.size : 24) + "px",
        height: (_vm.size ? _vm.size : 24) + "px",
      },
      on: { click: _vm.closeClick },
    },
    [
      _c("icon-button", {
        attrs: { icon: { name: "close-1", type: "bold", size: 24 } },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }