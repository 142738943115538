var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("portal", { attrs: { to: "confirm-popup", order: "5" } }, [
        _c(
          "div",
          {
            ref: "confirm",
            class: [_vm.$style.core_confirm_popup],
            attrs: { tabindex: "-5" },
            on: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  ) {
                    return null
                  }
                  return _vm.keyboardEsc($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.keyboardEnter($event)
                },
              ],
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              {
                class: [_vm.$style.container, _vm.$style.show],
                style: {
                  width: _vm.popupWidth ? _vm.popupWidth + "px" : "auto",
                },
                attrs: { id: "gp_autotest_confirm_popap" },
              },
              [
                _c(
                  "div",
                  { class: _vm.$style.body },
                  [
                    _vm.title || _vm.closeIcon
                      ? _c("div", { class: _vm.$style.head }, [
                          _vm.title
                            ? _c("div", { class: _vm.$style.head__left }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.title) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.closeIcon
                            ? _c(
                                "div",
                                { class: _vm.$style.head__right },
                                [
                                  _c("svg-sprite", {
                                    class: _vm.$style.close_icon,
                                    attrs: {
                                      name: "close-1",
                                      type: "bold",
                                      size: 24,
                                      color: "#999999",
                                    },
                                    on: { click: _vm.onClickCancel },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.text
                      ? _c("div", {
                          class: [
                            _vm.$style.confirm_message,
                            _vm.$style[_vm.confirmMessageClass],
                          ],
                          domProps: { innerHTML: _vm._s(_vm.text) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.additionalText
                      ? _c("div", {
                          class: [
                            _vm.$style.confirm_message,
                            _vm.$style.confirm_message_additional,
                          ],
                          domProps: { innerHTML: _vm._s(_vm.additionalText) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("body"),
                    _vm._v(" "),
                    _vm.confirmString
                      ? _c(
                          "div",
                          { class: [_vm.$style.confirm_input_container] },
                          [
                            _c("vgp-input", {
                              attrs: {
                                small: true,
                                placeholder: _vm.confirmStringInputPlaceholder,
                                border: true,
                              },
                              model: {
                                value: _vm.inputConfirmValue,
                                callback: function ($$v) {
                                  _vm.inputConfirmValue = $$v
                                },
                                expression: "inputConfirmValue",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.cancelButton || _vm.okButton
                  ? _c("div", { class: _vm.$style.footer }, [
                      _c(
                        "div",
                        { class: _vm.$style.actions },
                        [
                          _vm.cancelButton
                            ? _c("vgp-button", {
                                staticClass:
                                  "gp_autotest_confirm_popap_btn_cancel",
                                class: _vm.$style.action_btn,
                                attrs: {
                                  id: _vm.cancelButton.id,
                                  type: _vm.cancelButton.type,
                                  small: true,
                                  width: _vm.buttonWidth,
                                  label: _vm.cancelButton.title,
                                },
                                on: { onClick: _vm.onClickCancel },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.okButton
                            ? _c("vgp-button", {
                                staticClass: "gp_autotest_confirm_popap_btn_ok",
                                class: _vm.$style.action_btn,
                                attrs: {
                                  id: _vm.okButton.id,
                                  type: _vm.okButton.type,
                                  small: true,
                                  disabled: _vm.disabledOk,
                                  width: _vm.buttonWidth,
                                  label: _vm.okButton.title,
                                },
                                on: { onClick: _vm.onClickOk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }