import { refreshParents } from './helpers';
import app from '../../../app';
import globalStore from '../../../store/main';

function onTaskCustomFieldUpdated(event) {
  const updatedTaskIds = [];
  const projects = [];
  const projectColumns = globalStore.getters['columns/getProjectColumns'];
  const customValuesLoaded = globalStore.getters['columns/valuesLoaded'];
  const projectValuesMap = {};

  projectColumns.forEach(project => {
    projectValuesMap[project.id] = project.values;
  }); 

  event.data.customFields.forEach(item => {
    const taskFromModel = globalStore.getters['tasksModel/getTask'](item.taskId);

    if (!taskFromModel) return;
    const id = taskFromModel.id;
    const gantt_id = taskFromModel.gantt_id;
    let value = item.value;

    if (projectValuesMap[gantt_id] === null && !customValuesLoaded) {
      return;
    }

    projects.push(taskFromModel.gantt_id);

    if (typeof value === 'boolean') {
      value = value ? 1 : 0;
    }

    if (updatedTaskIds.indexOf(id) === -1) {
      updatedTaskIds.push(id);
    }

    globalStore.commit('columns/changeCustomValues', {
      task: { id, gantt_id },
      columnId: +item.customFieldId,
      value,
    });
  });

  updatedTaskIds.forEach(id => {
    refreshParents(id);
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.customFields.map(c => c.taskId),
    customFields: event.data.customFields,
    projects: [...new Set(projects)],
  });
}

export default onTaskCustomFieldUpdated;
