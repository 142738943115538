<template>
  <div
    v-if="dataLoaded"
    :class="[
      $style.header_toolbar,
      'header_toolbar_common',
      config.disable ? $style.disable : '',
    ]"
  >
    <div :class="$style.left_side">
      <div
        v-if="config.ui.includes('boardType')"
        :class="$style.btn_group"
      >
        <board-types />
      </div>

      <div
        v-if="config.ui.includes('workloadType')"
        :class="$style.btn_group"
      >
        <workload-types />
      </div>

      <div
        v-if="config.ui.includes('rangeCalendar')"
        :class="$style.btn_group"
      >
        <workload-range />
      </div>

      <div v-if="config.ui.includes('calendarStep')">
        <calendar-step />
      </div>

      <div
        v-if="config.ui.includes('calendarToday')"
        :class="[$style.btn_group, $style.delimiter_left]"
      >
        <div
          :class="$style.text_button"
          @click="onClickToday"
        >
          {{ locales("calendar_today_btn") }}
        </div>
      </div>

      <div
        v-if="config.ui.includes('tasksCount')"
        :class="$style.btn_group"
      >
        <div :class="$style.text_label">
          {{ locales("tasks_label") }}
          <span>{{ listTasksCount }}</span>
        </div>
      </div>

      <div
        v-if="config.ui.includes('myTasks')"
        :class="$style.btn_group"
      >
        <div
          :class="[
            $style.text_button,
            $style.my_tasks_btn,
            isMyTasksFilter ? $style.active : '',
          ]"
          @click="onClickMyTasks"
        >
          {{ locales("common_my_incompleted_tasks") }}
        </div>
      </div>

      <div
        v-if="config.ui.includes('missedFeature')"
        :class="$style.btn_group"
      >
        <div
          :class="$style.text_button"
          @click="onClickMissedFeature"
        >
          {{ locales("header_contact_btn") }}
        </div>
      </div>

      <div
        v-if="config.ui.includes('reportInfo') && (reportInfo.total_locale || reportInfo.range)"
        :class="$style.btn_group"
      >
        <div
          :class="[
            $style.text_label,
            $style.row,
          ]"
        >
          <div
            v-if="reportInfo.total_locale"
            :class="$style.group"
          >
            {{ locales(reportInfo.total_locale) }}:
            <span>{{ reportInfo.total }}</span>
          </div>
          <div
            v-if="reportInfo.range"
            :class="$style.group"
          >
            {{ reportInfo.range }}
          </div>
        </div>
      </div>

      <div
        v-if="config.ui.includes('groupBy')"
        :class="$style.btn_group"
      >
        <group-by-combo
          :on-select="onChangeGroupBy"
          :button-colors="buttonColors"
        />
      </div>

      <div
        v-if="config.ui.includes('bulkChange') || config.ui.includes('overdue') || config.ui.includes('criticalPath') || config.ui.includes('baseline')"
        :class="$style.btn_group"
      >
        <tooltip
          v-if="config.ui.includes('bulkChange')"
          :class="[
            $style.tooltip_wrapper,
            $style.inner_btn,
          ]"
          :content="locales('header_toolbar_bulk_tooltip')"
          :position="'bottom-start'"
          :interactive="true"
          :hide="!checkPricingAccess('bulk_change')"
          :with-animation="false"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'checkbox-filled', size: 20 }"
              :colors="buttonColors"
              :active="isBulkChange"
              :class="[
                checkPricingAccess('bulk_change') ? '' : 'pricing-tooltip',
              ]"
              data-feature="bulk_change"
              data-position="bottom"
              @onClick="onClickBulkChange('bulk_change')"
            />
          </template>
        </tooltip>

        <tooltip
          v-if="config.ui.includes('overdue')"
          :class="[
            $style.tooltip_wrapper,
            $style.inner_btn,
          ]"
          :content="locales('header_toolbar_overdue_tooltip')"
          :position="'bottom-start'"
          :interactive="true"
          :hide="!checkPricingAccess('overdue_tasks')"
          :with-animation="false"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'overdue', size: 20 }"
              :colors="buttonColors"
              :active="isOverdueTasks && checkPricingAccess('overdue_tasks')"
              :class="[
                checkPricingAccess('overdue_tasks') ? '' : 'pricing-tooltip',
              ]"
              data-feature="overdue_tasks"
              data-position="bottom"
              @onClick="onClickOverdue('overdue_tasks')"
            />
          </template>
        </tooltip>

        <tooltip
          v-if="config.ui.includes('criticalPath')"
          :class="[
            $style.tooltip_wrapper,
            $style.inner_btn,
          ]"
          :content="locales('header_toolbar_critical_path_tooltip')"
          :position="'bottom-start'"
          :interactive="true"
          :hide="!checkPricingAccess('overdue_tasks')"
          :with-animation="false"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'critical-path', size: 20 }"
              :colors="buttonColors"
              :active="isCriticalPath"
              @onClick="onClickCriticalPath"
            />
          </template>
        </tooltip>

        <tooltip
          v-if="config.ui.includes('baseline')"
          :class="[
            $style.tooltip_wrapper,
            $style.inner_btn,
          ]"
          :content="locales('header_toolbar_baseline_tooltip')"
          :position="'bottom-start'"
          :interactive="true"
          :with-animation="false"
          :hide="isShowBaselinePopup"
        >
          <template #body>
            <baseline
              :button-colors="buttonColors"
              @isShowBaselinePopup="changeShowBaselinePopup($event)"
            />
          </template>
        </tooltip>
      </div>

      <div
        v-if="config.ui.includes('expand') || config.ui.includes('collapse')"
        :class="$style.btn_group"
      >
        <tooltip
          v-if="config.ui.includes('expand')"
          :class="$style.tooltip_wrapper"
          :content="locales('header_toolbar_expand_tooltip')"
          :position="'bottom'"
          :interactive="true"
          :with-animation="false"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'expand-1', size: 20 }"
              :label="locales('expand_all')"
              :colors="buttonColors"
              @onClick="onClickExpandCollapse('expand')"
            />
          </template>
        </tooltip>

        <tooltip
          v-if="config.ui.includes('collapse')"
          :class="$style.tooltip_wrapper"
          :content="locales('header_toolbar_collapse_tooltip')"
          :position="'bottom'"
          :interactive="true"
          :with-animation="false"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'collaps-1', size: 20 }"
              :label="locales('collapse_all')"
              :colors="buttonColors"
              @onClick="onClickExpandCollapse('collapse')"
            />
          </template>
        </tooltip>
      </div>

      <div
        v-if="config.ui.includes('sort')"
        :class="$style.btn_group"
      >
        <tooltip
          :class="$style.tooltip_wrapper"
          :content="locales('header_toolbar_cascade_sorting_tooltip')"
          :position="'bottom'"
          :interactive="true"
          :with-animation="false"
          :hide="!checkPricingAccess('cascade_sorting')"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'cascade-sorting', size: 20 }"
              :label="locales('cascade_sorting')"
              :colors="buttonColors"
              :class="[
                checkPricingAccess('cascade_sorting') ? '' : 'pricing-tooltip',
              ]"
              data-position="bottom"
              data-feature="cascade_sorting"
              @onClick="onClickSorting('cascade_sorting')"
            />
          </template>
        </tooltip>
      </div>

      <div
        v-if="config.ui.includes('addTask') && accessToCreateTask"
        :class="$style.btn_group"
      >
        <add-task-btn />
      </div>

      <div v-if="config.ui.includes('addProject')">
        <add-project-button />
      </div>
    </div>

    <div :class="$style.right_side">
      <div
        v-if="config.ui.includes('filter')"
        :class="$style.btn_group"
      >
        <div :class="$style.filter">
          <vgp-icon-button
            v-if="isActiveFilter && config.ui.includes('filterRefresh')"
            :icon="{ name:'update', size: 20}"
            :colors="buttonColors"
            :active="isActiveFilter"
            :class="$style.filter_refresh"
            @onClick="onClickRefreshFilter"
          />
          <vgp-icon-button
            :icon="{ name:'filter', size: 20 }"
            :label="locales('common_filter')"
            :colors="buttonColors"
            :active="isActiveFilter || isShowFilter"
            :class="[
              $style.filter_button,
              'header-filter-btn',
              'js_filter_btn',
            ]"
            @onClick="onClickFilter"
          />
        </div>
      </div>

      <div
        v-if="config.ui.includes('zoom')"
        :class="$style.btn_group"
      >
        <zoom-slider
          :value="zoom"
          @change="onChangeZoom"
        />
      </div>

      <div
        v-if="config.ui.includes('export') && accessToExport"
        :class="$style.btn_group"
      >
        <tooltip
          :class="$style.tooltip_wrapper"
          :content="locales(getExportTooltipKey)"
          :position="'bottom-end'"
          :interactive="true"
        >
          <template #body>
            <vgp-icon-button
              :icon="{ name:'link-2', size: 20 }"
              :label="locales('common_export')"
              :colors="buttonColors"
              :class="[
                'js_export_button',
              ]"
              @onClick="onClickExport"
            />
          </template>
        </tooltip>
      </div>

      <div
        v-if="config.viewSettings"
        :class="$style.btn_group"
      >
        <view-settings
          :config="config.viewSettings"
          :button-colors="buttonColors"
        />
      </div>
    </div>

    <core-confirm-popup
      v-if="config.ui.includes('sort')"
      :show="isShowSortingPopup"
      popup-width="440"
      close-icon
      :title="locales('cascade_sorting')"
      :text="locales('cascade_popup_text')"
      :cancel-button="{ title: locales('common_cancel'), type: 'secondary' }"
      :ok-button="{ title: locales('common_yes'), type: 'primary' }"
      @onClickCancel="onSortingConfirmCancel"
      @onClickOk="onSortingConfirmOk"
    />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>

<script>
import { is } from 'vee-validate/dist/rules';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import coreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';

import zoomSlider from './include/zoomSlider.vue';
import boardTypes from './include/boardTypes.vue';
import workloadTypes from './include/workloadTypes.vue';
import workloadRange from './include/workloadRange.vue';
import viewSettings from './include/viewSettings.vue';
import groupByCombo from './include/groupByCombo.vue';
import rights from '../../components/rights';
import { checkPricingAccess } from '../../helpers/pricingHelper';
import addTaskBtn from './include/addTaskBtn.vue';
import addProjectButton from './include/addProjectButton.vue';
import baseline from './include/baseline.vue';
import Store from '../../store/main';
import app from '../../app';
import routerHelper from '../../helpers/router';
import Tooltip from '../common/VueComponents/tooltips/tooltip.vue';
import calendarStep from './include/calendarStep.vue';
import skeleton from './headerSkeleton.vue';
import TaskSettingsMain from '../taskSettings/TaskSettingsMain.vue';

export default {
  components: {
    TaskSettingsMain,
    viewSettings,
    zoomSlider,
    boardTypes,
    workloadTypes,
    workloadRange,
    groupByCombo,
    addTaskBtn,
    addProjectButton,
    vgpIconButton,
    coreConfirmPopup,
    baseline,
    Tooltip,
    calendarStep,
    skeleton,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
    dataLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      toolbarName: '',
      isShowSortingPopup: false,
      isShowBaselinePopup: false,
    };
  },
  computed: {
    typeProject() {
      return this.$store.getters['headerView/currentRoute'].path.startsWith('/projects/') ? 'portfolio' : 'project';
    },
    isActiveFilter() {
      return this.$store.getters['headerView/isActiveFilter'];
    },
    isOverdueTasks() {
      return this.$store.getters['headerView/isOverdueTasks'];
    },
    isCriticalPath() {
      return this.$store.getters['headerView/isCriticalPath'];
    },
    isBulkChange() {
      return this.$store.getters['headerView/isBulkChange'];
    },
    bulkChangeTitle() {
      return __('app_masschange_header_enable');
    },
    overdueTitle() {
      return __('overdue_tasks');
    },
    criticalPathTitle() {
      return __('critical_path');
    },
    zoom() {
      return this.$store.getters['headerView/zoom'];
    },
    boardType() {
      return this.$store.getters['headerView/activeBoardType'];
    },
    listTasksCount() {
      return this.$store.getters['headerView/listTasksCount'];
    },
    isMyTasksFilter() {
      return this.$store.getters['headerView/isMyTasksFilter'];
    },
    reportInfo() {
      return this.$store.getters['headerView/reportToolbarInfo'];
    },
    isShowFilter() {
      return this.$store.getters['headerView/isShowFilter'];
    },
    getExportTooltipKey() {
      const route = this.$store.getters['headerView/currentRoute'];
      let tooltipKey;

      if (route.path.includes('/gantt')) {
        tooltipKey = 'header_toolbar_export_tooltip';
      } else {
        tooltipKey = 'header_toolbar_export_tooltip_other';
      }

      return tooltipKey;
    },
    accessToExport() {
      return rights.account.hasRight('export');
    },
    accessToCreateTask() {
      if (routerHelper.isSingleProjectRoute()) {
        const route = routerHelper.getCurrentRoute();
        const projectId = route.params.projectId;

        return rights.project.hasRight(+projectId, 'create_task');
      }

      return false;
    },
    tooltipAlign() {
      return (this.$store.getters.leftSidebarWidth === 40) || app.config.mode.isExport;
    },
    changeZoomDebounced() {
      return gantt.debounce((newValue) => this.$store.dispatch('headerView/changeZoom', newValue), 300);
    }
  },
  watch: {
    isShowFilter(new_val) {
      if (new_val) {
        this.sendEventsAnalityc('filter_open');

        return;
      }
      this.sendEventsAnalityc('filter_close');
    },
  },
  methods: {
    checkPricingAccess(feature) {
      return checkPricingAccess(feature);
    },
    onClickSorting(n) {
      if (this.checkPricingAccess(n)) {
        this.isShowSortingPopup = true;
        userExtAnalytics.log('header_click_cascade_sorting_popup_opened', { from: this.typeProject });
      }
    },
    onSortingConfirmOk() {
      this.isShowSortingPopup = false;
      this.$store.dispatch('headerView/cascadeSorting');
      userExtAnalytics.log('header_click_cascade_sorting', { from: this.typeProject });
    },
    onSortingConfirmCancel() {
      this.isShowSortingPopup = false;
      userExtAnalytics.log('header_click_cascade_sorting_popup_closed', { from: this.typeProject });
    },
    onClickExpandCollapse(type) {
      userExtAnalytics.log(`header_click_${type}`, {
        from: this.getTypeRoute(),
      });
      this.$store.dispatch('headerView/expandCollapse', type);
    },
    onClickBulkChange(n) {
      if (this.checkPricingAccess(n)) {
        this.$store.dispatch('headerView/toggleBulkChange');
      }
    },
    onChangeZoom(newValue) {
      this.changeZoomDebounced(newValue);
    },
    sendEventsAnalityc(event_name) {
      let from = '';
      let route = '';

      const url = this.$store.getters['headerView/currentRoute'].path;
      const urlArr = url.slice(1).split('/');
      const urlItems = urlArr.filter(item => !Number.parseInt(item));

      switch (urlItems[0]) {
      case 'project':
        from = 'project & portfolio';
        route = `Project/${urlItems[1]}`;
        break;
      case 'projects':
        from = 'project & portfolio';
        route = `Portfolio/${urlItems[1]}`;
        break;
      case 'tasks':
        from = 'my tasks';
        route = 'MyTasks';
        break;
      case 'workload':
        from = 'workload';
        route = 'Workload';
        break;
      case 'timelog':
        from = 'my time log';
        route = 'MyTimeLog';
        break;
      default:
        from = urlItems[0];
        route = url;
      }

      userExtAnalytics.log(event_name, { from, route });
    },

    getTypeRoute() {
      const url = this.$store.getters['headerView/currentRoute'].path;

      if (url.includes('project/')) return 'project';
      if (url.includes('projects/')) return 'portfolio';

      return 'general';
    },

    onClickFilter() {
      this.$store.dispatch('headerView/openFilterPopup');
    },
    onClickRefreshFilter() {
      const routeMode = this.$store.getters['headerView/currentRoute'].params.mode;

      userExtAnalytics.log('header_click_filter_refresh_button', {
        from: routeMode === 'list' ? 'my tasks' : routeMode,
      });
      this.$store.dispatch('headerView/refreshFilter');
    },
    onClickOverdue(n) {
      if (this.checkPricingAccess(n)) {
        this.$store.dispatch('headerView/toggleOverdueTasks');
      }
    },
    onClickCriticalPath() {
      this.$store.dispatch('headerView/toggleCriticalPath');

      userExtAnalytics.log('header_click_critical_path', {
        type: this.$store.getters['headerView/isCriticalPath'] ? 'enabled' : 'disabled',
      });
    },
    onClickExport() {
      this.$store.dispatch('headerView/exportAction');
    },
    onClickMyTasks() {
      this.$store.dispatch('headerView/selectMyTasksFilter');
    },
    onClickMissedFeature() {
      this.$store.dispatch('headerView/selectMissedFeatures');
    },
    onChangeGroupBy(data) {
      this.$store.dispatch('headerView/changeReportGroupBy', data);
    },
    changeShowBaselinePopup(value) {
      this.isShowBaselinePopup = value;
    },
    onClickToday() {
      const currentDate = new Date();

      this.$store.commit('headerView/setCalendarTodayDate', currentDate);
    }
  }
};
</script>

<style module src="./less/toolbarView.less" lang="less"></style>
