const ui_plugins = [];
const url_plugins = [];
const require_plugins = [];

const app = {
  create(config) {
    app.config = webix.extend({
      name: 'App',
      version: '1.0',
      container: document.body,
    }, config, true);

    app.debug = config.debug;

    app.$layout = {
      root: app.config.container,
      index: 0,
      add: true,
    };

    webix.extend(app, webix.EventSystem);

    const node = app.config.container;

    webix.ready(() => {
      app.start();
      webix.html.removeCss(node, 'webixappstart');
      webix.html.addCss(node, 'webixapp');
    });

    const title = document.getElementsByTagName('title')[0];

    if (title) {
      title.innerHTML = app.config.name;
    }

    webix.html.addCss(node, 'webixappstart');

    return app;
  },
  start() {
    if (window.location.hash) {
      webix.ui.$freeze = false;
      webix.ui.resize();
    }
  },
  use(handler, config) {
    if (handler.$oninit) handler.$oninit(this, (config || {}));

    if (handler.$onurlchange) url_plugins.push(handler.$onurlchange);
    if (handler.$onurl) require_plugins.push(handler.$onurl);
    if (handler.$onui) ui_plugins.push(handler.$onui);
  },
  trigger(name) {
    app.apply(name, [].splice.call(arguments, 1));
  },
  off(idEvent) {
    return this.detachEvent(idEvent);
  },
  on(name, handler) {
    return this.attachEvent(name, handler);
  },
  // ui: create_temp_ui,

  apply(name, data) {
    app.callEvent(name, data);
  },
  // action: function (name) {
  //   return function () {
  //     app.apply(name, arguments);
  //   };
  // },

  _uis: [],
  _handlers: [],
};

export default app;
