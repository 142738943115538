<template>
  <svg
    :viewBox="viewBox"
    :width="size"
    :height="size"
    fill="none"
    role="presentation"
    class="svg-icon"
    @click="$emit('click', $event)"
    v-html="icon"
  />
</template>
<script>

export default {
  name: 'SvgSprite',
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String, // regular, bold, color-bold, color-regular
      required: false,
      default: 'regular',
    },
    size: {
      type: Number, // 16, 20, 24
      required: false,
      default: 24,
    },

  },
  data() {
    return {
    };
  },
  computed: {
    viewBox() {
      return `0 0 ${this.size} ${this.size}`;
    },
    icon() {
      const hrefForSvg = `${this.type}/${this.size}/${this.name}`;

      return `<use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#icons/${hrefForSvg}"
            href="#icons/${hrefForSvg}"
        ></use>`;
    },
  },
};
</script>
