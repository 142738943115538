var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { height: "100%" } },
    [
      _c("stub"),
      _vm._v(" "),
      _vm.mode === "projects" ? _c("portfolio-projects") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "list" ? _c("list-view") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "board" ? _c("kanban") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "workload" ? _c("workload") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "export" ? _c("export") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "resources" ? _c("resources") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "dashboard" ? _c("portfolio-dashboard") : _vm._e(),
      _vm._v(" "),
      _c("confirm-popup", {
        attrs: {
          show: _vm.portfolioDeleteObj,
          popupWidth: 450,
          closeIcon: true,
          title: _vm.locale("project_list_delete_multiview"),
          text: _vm.deleteConfirmMessage,
          confirmMessageClass: "multiline",
          cancelButton: {
            title: _vm.locale("common_cancel"),
            type: "secondary",
          },
          okButton: {
            title: _vm.locale("common_delete"),
            type: "destructive",
          },
        },
        on: { onClickCancel: _vm.closeModal, onClickOk: _vm.deletePortfolio },
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }