import Text from "./textCell.vue";
import Expand from "./expandCell.vue";
import Status from "./statusCell.vue";
import Progress from "./progressCell.vue";
import Person from "./personCell.vue";
import ContextMenu from "./contextMenuCell.vue";

const Cells = {
  Text,
  Expand,
  Status,
  Progress,
  Person,
  ContextMenu
};

export default Cells; 