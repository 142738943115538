/* eslint-disable */
import { cloneDeep } from 'lodash';
import app from '../../app';
import _ from '../../libs/lodash';
import moment from '../../libs/moment';
import templatesModel from '../../models/templates';
import profileModel from '../../models/profile';
import helpersCustom from '../../helpers/custom';
import constants from '../../helpers/constants';
import dateHelper from '../../helpers/dateFormats';
import calendarSettings from './include/calendarSettings';
import durationConfig from './include/durationConfig';
import projectComponent from '../../components/Project';
import routerHelper from '../../helpers/router';

import newProjectImportView from './import/main';

import icon_edit from '../../svg/webix_material/edit.svg';
import icon_delete from '../../svg/webix_material/trash.svg';
import icon_tooltip from '../../svg/tooltip.svg';

import blank from '../../svg/projects_icons/project_blank.svg';
import construction from '../../svg/projects_icons/project_building.svg';
import web_design from '../../svg/projects_icons/project_design.svg';
import event_planning from '../../svg/projects_icons/project_plan.svg';
import hr from '../../svg/projects_icons/project_recruting.svg';
import manufacturing from '../../svg/projects_icons/project_manufacturing.svg';
import software_development from '../../svg/projects_icons/project_software.svg';
import product_launch from '../../svg/projects_icons/product_launch.svg';
import retail from '../../svg/projects_icons/project_retail.svg';
import services from '../../svg/projects_icons/project_services.svg';
import marketing from '../../svg/projects_icons/project_marketing.svg';
import consulting from '../../svg/projects_icons/project_consulting.svg';
import learning from '../../svg/projects_icons/project_education.svg';
import methodology from '../../svg/projects_icons/methodology.svg';
import notificationModal from '../notificationModal/notificationModal';
import { getPlans, checkPricingAccess } from '../../helpers/pricingHelper';
import globalStore from '../../store/main';
import { ONBOARDING_INITIAL_TASKS } from '../onboarding/onboarding.config';

const projectsIcons = {
  blank,
  construction,
  web_design,
  event_planning,
  hr,
  manufacturing,
  software_development,
  product_launch,
  retail,
  services,
  marketing,
  consulting,
  learning,
  methodology,
};

const __ = window.__;

const innerSettings = {
  templatesData: [],
};

var innerViews = {
  newProjectLayoutBody: {
    id: 'newProjectLayoutBody',
    css: 'new_project_layout_body',
  },
  newProjectLayoutForm: {
    id: 'newProjectLayoutForm',
    css: 'new_project_layout_form',
  },
  newProjectNameText: {
    view: 'text',
    id: 'projectNameText',
    css: 'gp_autotest_newproject_name_input',
    inputHeight: 36,
    label: __('new_name'),
    height: 72,
    attributes: { maxlength: 250 },
    labelPosition: 'top',
    placeholder: __('new_project_placeholders_type_here'),
  },
  newProjectStartDaySelect: {
    view: 'datepickeredit',
    id: 'projectStartDaySelect',
    label: __('settings_start_date'),
    labelPosition: 'top',
    css: 'newProjectDaySelect gp_autotest_newproject_start_day_select',
    width: 280,
    inputHeight: 36,
    height: 72,
    icon: 'fa-calendar',
    suggestCss: 'newProjectSuggestDaySelect',
    on: {
      onChange(newValue, oldValue) {
        const isEmpty = (newValue === '');
        const isValid = newValue instanceof Date && !isNaN(newValue);

        if (!isValid || isEmpty) {
          this.blockEvent();
          this.setValue(oldValue);
          this.unblockEvent();

          webix.message({ type: 'warning', text: __('gantt_date_invalid') });
          this.config.isInvalid = true;

          return false;
        }

        const value = dateHelper.checkIfDateInLimit(newValue, true, { from: 1999 });

        if (value.getTime() !== newValue.getTime()) {
          this.blockEvent('onChange');
          this.setValue(value);
          this.unblockEvent('onChange');
          newValue = value;
        }

        if (!gantt.isWorkDay(newValue)) {
          const date = gantt.getClosestWorkTime({ date: newValue, dir: 'future' });

          this.blockEvent();
          this.setValue(date);
          this.unblockEvent();
        }
      },
      onEnter() {
        const popup = this.getPopup();

        this.callEvent('onBlur');

        if (popup.isVisible()) {
          popup.hide();
        }

        webix.UIManager.setFocus($$(innerViews.newProjectLayoutForm.id));
      },
      onItemClick() {
        const calendar = this.getPopup().getBody();
        const workDays = calendarSettings.handlers.getWorkDays();
        let blockDays = _.differenceWith([1, 2, 3, 4, 5, 6, 7], workDays, _.isEqual);

        blockDays = _.map(blockDays, day => (day === 7 ? 0 : day));

        calendar.define('blockDates', date => {
          if (!_.includes(blockDays, date.getDay())) {
            return false;
          }

          return true;
        });

        calendar.refresh();
      },
    },
  },
  newProjectDurationStepSelect: {
    view: 'richselectWithoutPoint',
    id: 'projectDurationStepSelect',
    css: 'gp_autotest_newproject_duration_step_select',
    options: durationConfig,
    value: 'hour',
    // label: __('new_label_duration'),
    // labelPosition: 'top',
    // width: 280,
    height: 36,
    inputHeight: 36,
    suggestCss: 'gp_autotest_newproject_duration_step_list',
  },
  newProjectTemplatesSelect: {
    view: 'text',
    id: 'projectTemplatesSelect',
    label: __('new_project_label_templates'),
    labelPosition: 'top',
    css: 'projectTemplatesSelect gp_autotest_newproject_template_select',
    readonly: true,
    inputHeight: 36,
    height: 72,
    on: {
      onItemClick(id, e) {
        $$(innerViews.templatesPopup.id).toggle(e.target, { y: -2 });
      },
    },

  },
  templatesPopup: {
    id: 'projectTemplatesPopup',
    css: 'projectTemplatesPopup gantt-scroll',
  },
  templatesCategoryList: {
    view: 'dataview',
    id: 'projectTemplatesCategoryList',
    css: 'templatesList',
    autoheight: true,
    borderless: true,
    template(obj) {
      return `${'<div class="item gp_autotest_newproject_template_by_category">'
        + '<span class="icon">'}${projectsIcons[obj.icon] || projectsIcons.blank}</span>`
        + `<span class="name">${__(obj.name)}</span>`
        + '</div>';
    },
    type: {
      width: 263,
      height: 42,
    },
    on: {
      onItemClick(id, e, node) {
        const item = this.getItem(id);

        innerHandlers.selectCategory(item);
      },
    },
  },
  templatesCategoryListLabel: {
    view: 'label',
    label: __('ganttpro_templates_category_label'),
    align: 'left',
    height: 40,
    css: 'title',
  },
  templatesDetailSelect: {
    view: 'text',
    id: 'templatesDetailSelect',
    label: __('templates_detail_select'),
    labelPosition: 'top',
    css: 'projectTemplatesSelect',
    hidden: true,
    readonly: true,
    inputHeight: 36,
    // height: 72,
    on: {
      onItemClick(id, e) {
        $$(innerViews.templatesDetailPopup.id).toggle(e.target, { y: -2 });
      },
    },
  },
  templatesDetailPopup: {
    id: 'projectTemplatesDetailPopup',
    css: 'projectTemplatesPopup projectTemplatesDetailPopup',
  },
  templatesDetailList: {
    view: 'dataview',
    id: 'projectTemplatesDetailList',
    css: 'templatesList detail',
    autoheight: true,
    borderless: true,
    template(obj) {
      return `<div class="item" title="${obj.name}">`
        + `<span class="name">${obj.name}</span>`
        + '</div>';
    },
    type: {
      width: 263,
      height: 42,
    },
    on: {
      onItemClick(id, e, node) {
        const item = this.getItem(id);

        innerHandlers.selectTemplate(item, 'detail');
      },
    },
  },
  templatesDetailListLabel: {
    view: 'label',
    label: __('ganttpro_templates_label'),
    align: 'left',
    height: 40,
    css: 'title',
  },
  templatesUserList: {
    view: 'editDataView',
    id: 'projectTemplatesUserList',
    css: 'templatesList user',
    borderless: true,
    autoheight: true,
    editable: true,
    editor: 'text',
    editValue: 'name',
    template(obj) {
      const isSameOwnerAsCurrentUser = (obj.owner_id && obj.owner_id === window.user.id);

      let res = `${'<div class="item gp_autotest_newproject_template_by_user">'
        + '<span class="icon">'}${projectsIcons.blank}</span>`
        + `<span class="name">${obj.name.replace(/>/g, '&gt;').replace(/</g, '&lt;')}</span>`;

      if (!obj.isPredefined && isSameOwnerAsCurrentUser) {
        res += `${'<span class="controls">'
          + '<span class="icon_edit control">'}${icon_edit}</span>`
          + `<span class="icon_delete control">${icon_delete}</span></span>`;
      }

      res += '</div>';

      return res;
    },
    type: {
      width: 263,
      height: 42,
    },
    onClick: {
      icon_delete(e, c) {
        innerSettings.clickedDeleteButton = true;
      },
    },
    on: {
      onItemClick(id, e, node) {
        const item = this.getItem(id);
        const dataView = this;

        if (e.target.closest('.icon_edit')) {
          return;
        }

        if (e.target.closest('.icon_delete')) {
          // webix.alert({
          //   text: __('new_confirmDeleteTemplate'),
          //   ok: __('common_ok'),
          //   cancel: __('common_cancel'),
          //   type: 'confirm-error',
          //   callback: function (res) {
          //     if (res) {
          //       innerHandlers.removeTemplate(item);

          //       if (dataView.isSelected(item.id)) {
          //         var templates = templatesModel.getBaseTemplates();

          //         $$(innerViews.newProjectTemplatesSelect.id).setValue(templates[0].name);
          //         $$(innerViews.templatesDefaultList.id).select([templates[0].id], false, false);
          //       }

          //       dataView.remove(item.id);

          //       if (!dataView.serialize().length) {
          //         $$(innerViews.templatesUserListWrapper.id).hide();
          //       }
          //     }
          //   }
          // });
          webix.confirm({
            text: __('new_confirmDeleteTemplate'),
            ok: __('common_ok'),
            cancel: __('common_cancel'),
            type: 'confirm-error',
          }).then(res => {
            if (res) {
              innerHandlers.removeTemplate(item);

              if (dataView.isSelected(item.id)) {
                const templates = templatesModel.getBaseTemplates();

                dataView.remove(item.id);
                $$(innerViews.newProjectTemplatesSelect.id).setValue(templates[0].name);
                $$('projectTemplatesPopup').hide();
                $$('projectTemplatesUserList').resize();
                $$(innerViews.templatesDefaultList.id).select([templates[0].id], false, false);
              }

              dataView.remove(item.id);
              $$('projectTemplatesUserList').resize();

              if (!dataView.serialize().length) {
                $$(innerViews.templatesUserListWrapper.id).hide();
              }
            }
          });

          return;
        }

        innerHandlers.selectTemplate(item, 'user');
      },
      onBeforeEditStart(id) {
        if (innerSettings.clickedDeleteButton) {
          innerSettings.clickedDeleteButton = false;

          return false;
        }
      },
      onAfterEditStop(state, editor) {
        if (state.old === state.value) {
          return;
        }

        const item = this.getItem(editor.id);

        innerHandlers.editTemplate(item);

        if (this.isSelected(editor.id)) {
          $$(innerViews.newProjectTemplatesSelect.id).setValue(item.name);
        }
      },
    },
  },
  templatesUserListLabel: {
    view: 'label',
    label: __('user_templates_label'),
    align: 'left',
    height: 30,
    css: 'title',
  },
  templatesUserListWrapper: {
    id: 'templatesUserListWrapper',
    css: 'templatesUserListWrapper',
  },
};

var innerHandlers = {
  init() {
    const startDayView = $$(innerViews.newProjectStartDaySelect.id);

    startDayView.blockEvent();
    startDayView.setValue(new Date());
    startDayView.unblockEvent();

    innerHandlers.initTemplates();

    calendarSettings.init();
    innerHandlers.checkAndUpdateStartDate(calendarSettings.handlers.getWorkDays());
    startDayView.refresh();
  },
  initTemplates(init) {
    const templatesPopup = $$(innerViews.templatesPopup.id).$view;
    const templatesDetailsPopup = $$(innerViews.templatesDetailPopup.id).$view;
    const templatesCategoryList = $$(innerViews.templatesCategoryList.id);
    const templatesUserList = $$(innerViews.templatesUserList.id);
    const allTemplatesData = _.cloneDeep(templatesModel.getBaseTemplates());
    const userTemplatesData = checkPricingAccess('saving_project_templates')
      ? templatesModel.getUserTemplates()
      : [];
    const categoriesData = [];
    let defaultTemplateFound = false;

    _.forIn(_.groupBy(allTemplatesData, 'icon'), (value, key) => {
      if (key === 'default') {
        if (defaultTemplateFound) {
          return console.error('GanttPRO: 2 default templates for this language', value, key);
        }
        defaultTemplateFound = true;
        userTemplatesData.unshift(value[0]);
      } else {
        categoriesData.push({
          name: constants.TEMPLATES_CATEGORIES[key],
          icon: key,
          data: value,
        });
      }
    });
    templatesPopup.style.maxHeight = `${document.documentElement.clientHeight - 330}px`;
    templatesDetailsPopup.style.maxHeight = `${document.documentElement.clientHeight - 410}px`;

    templatesCategoryList.clearAll();
    templatesCategoryList.parse(categoriesData);
    templatesUserList.clearAll();
    templatesUserList.parse(userTemplatesData);
    innerHandlers.selectTemplate(userTemplatesData[0], 'user');
  },
  selectCategory(category, selectedTemplate) {
    const popupCategoryList = $$(innerViews.templatesCategoryList.id);
    const popupDetailList = $$(innerViews.templatesDetailList.id);
    const mainSelectForTemplates = $$(innerViews.newProjectTemplatesSelect.id);
    const selectForDetail = $$(innerViews.templatesDetailSelect.id);
    const detailData = category.data;

    popupCategoryList.unselectAll();
    popupCategoryList.select([category.id], false, false);
    mainSelectForTemplates.setValue(__(category.name));

    popupDetailList.clearAll();
    popupDetailList.parse(detailData);

    selectForDetail.show();

    innerHandlers.selectTemplate(selectedTemplate || detailData[0], 'detail');
  },
  selectTemplate(item, type) {
    const templatesCategoryList = $$(innerViews.templatesCategoryList.id);
    const templatesDetailList = $$(innerViews.templatesDetailList.id);
    const templatesUserList = $$(innerViews.templatesUserList.id);
    const selectForDetail = $$(innerViews.templatesDetailSelect.id);
    const mainSelectForTemplates = $$(innerViews.newProjectTemplatesSelect.id);

    templatesDetailList.unselectAll();
    templatesUserList.unselectAll();

    if (type === 'detail') {
      templatesDetailList.select([item.id], false, false);
      selectForDetail.setValue(item.name);
    }

    if (type === 'user') {
      templatesCategoryList.unselectAll();
      templatesUserList.select([item.id], false, false);
      selectForDetail.hide();

      setTimeout(() => {
        mainSelectForTemplates.setValue(item.name);
        const inputNode = mainSelectForTemplates.$view.querySelector('input');

        if (inputNode) inputNode.blur();
      }, 0);
    }

    innerSettings.currentTemplate = item;
    innerSettings.currentTemplate.selectedTemplate = true;

    $$(innerViews.templatesPopup.id).hide();
    $$(innerViews.templatesDetailPopup.id).hide();
  },
  editTemplate(template) {
    templatesModel.updateUsersTemplate(template.id, template.name);
  },
  removeTemplate(template) {
    const defaultTemplate = templatesModel.getBaseTemplates()[0];

    innerSettings.currentTemplate = defaultTemplate;
    templatesModel.deleteUsersTemplate(template.id);
  },
  createNewProject(templateData) {
    const isBlankEmptyTemplate = innerSettings.currentTemplate.nameKey === 'default_template';
    const projectNameFromInput = $$(innerViews.newProjectNameText.id).getValue();
    let projectName = projectNameFromInput || __(innerSettings.currentTemplate.name) || innerSettings.currentTemplate.name; // !!!

    if (isBlankEmptyTemplate) {
      projectName = projectNameFromInput || __('default_template');
    }

    const datePicker = $$(innerViews.newProjectStartDaySelect.id);

    if (datePicker.config.isInvalid) {
      datePicker.config.isInvalid = false;

      return;
    }

    const startDate = templateData?.startDate || datePicker.getValue();
    const workHours = calendarSettings.handlers.getWorkHours();
    const timeString = workHours[0].split('-')[0];
    const [hours, minutes] = timeString.split(':');

    const date = new Date(startDate);
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));

    // if user picked blank template, we need to follow onboarding registration flow to use only one template at backend
    const ganttId = isBlankEmptyTemplate ? null : innerSettings.currentTemplate.gantt_id;
    const templateName = isBlankEmptyTemplate ? 'none' : innerSettings.currentTemplate.name;

    if (isBlankEmptyTemplate && innerSettings.currentTemplate?.selectedTemplate) {
      ONBOARDING_INITIAL_TASKS[1].defaultName = __('default_template_task1_default_name');
    }

    const onboardingProjectData = isBlankEmptyTemplate ? { tasks: ONBOARDING_INITIAL_TASKS.slice(0, 2) } : null;

    if (!innerSettings.currentTemplate.isPredefined && !templateData) {
      const templateStartDate = innerSettings.currentTemplate.startProject;
      const templateWorkDays = innerSettings.currentTemplate.workDays;
      const defaultWorkDays = calendarSettings.handlers.getWorkDays();

      app.trigger('showTemplatePopup', { templateStartDate, templateWorkDays, defaultWorkDays });

      return;
    }

    let projectSettings = {
      startProject: date.valueOf() - (new Date().getTimezoneOffset() * 60000), // get time by UTC
      durationConfig: innerHandlers.getDurationConfig(),
      showTime: workHours,
      showDay: calendarSettings.handlers.getWorkDays(),
      estimation_mode: checkPricingAccess('workload') ? gantt.estimationCalculator.MODES.fixEstimation : gantt.estimationCalculator.MODES.fixDuration,
      apply_resource_calendar: true,
      isFromTemplate: templateName,
      onboardingProjectData,
    };

    if (helpersCustom.validateName(projectName)) {
      if (templateData && !innerSettings.currentTemplate.isPredefined) {
        projectSettings = {
          ...projectSettings,
          ...templateData,
        };

        innerHandlers.createNewProjectFinalActions(projectName, ganttId, projectSettings, 0, isBlankEmptyTemplate, templateName, true);
      } else {
        innerHandlers.createNewProjectFinalActions(projectName, ganttId, projectSettings, 0, isBlankEmptyTemplate, templateName, false);
      }

      return true;
    }

    webix.message({ type: 'error', text: __('new_msg_invalid_name') });
    $$(innerViews.newProjectNameText.id).focus();
  },

  createNewProjectWithoutDialogIfUserCameFromTemplate() {
    const allTemplatesData = _.cloneDeep(templatesModel.getBaseTemplates());
    const defaultTemplate = profileModel.getDefaultGanttTemplate(allTemplatesData);

    if (!defaultTemplate) return false;
    const projectName = __(defaultTemplate.nameKey);
    const projectSettings = {
      startProject: moment().hours(9).startOf('hour'),
      durationConfig: { duration_view: 'hour' },
      showTime: ['9:00-13:00', '14:00-18:00'],
      showDay: [1, 2, 3, 4, 5],
    };

    innerHandlers.createNewProjectFinalActions(projectName, defaultTemplate.gantt_id, projectSettings, 0, false, defaultTemplate.nameKey, false);

    return true;
  },

  createNewProjectWithoutDialogIfUserCameFromMpp() {
    if (!user || !user.mpp || !user.mpp.js_url || !user.mpp.name) return false;
    window.__ganttproImportedMppJson = function (data) {
      app.trigger('gantt:progress:show');
      newProjectImportView.createNewProjectBasedOnMppJson(data, user.mpp.name);
    };
    const script = document.createElement('script');

    script.src = user.mpp.js_url;
    document.getElementsByTagName('head')[0].appendChild(script);

    return true;
  },

  createNewProjectFinalActions(projectName, ganttId, projectSettings, applyResources, isBlankEmptyTemplate, templateName, isCustomTemplate = false) {
    const newProjectPopup = $$('createProjectLayout');

    if (newProjectPopup) {
      !newProjectPopup.showProgress && webix.extend(newProjectPopup, webix.ProgressBar);

      newProjectPopup.disable();
      const x = templatesModel.getBaseTemplates();

      console.log(x);
      if (!isCustomTemplate) newProjectPopup.showProgress({ type: 'icon_cover' });
    }

    projectComponent.addNewProject({
      projectName, sourceGanttId: ganttId, projectSettings, applyResources,
    });
    app.trigger('tab:projectCreate', isBlankEmptyTemplate);
    userExtAnalytics.log('project_create_done', {
      type: isBlankEmptyTemplate ? 'blank' : 'template',
      'template category': isBlankEmptyTemplate ? 'none' : templateName || 'custom template',
    });
  },
  getDurationConfig() {
    let config = null;
    const value = $$(innerViews.newProjectDurationStepSelect.id).getValue();

    _.each(durationConfig, option => {
      if (option.id === value) {
        config = option.config;
      }
    });

    return config;
  },
  checkAndUpdateStartDate(workDays) {
    const daySelectView = $$(innerViews.newProjectStartDaySelect.id);
    const calendar = daySelectView.getPopup().getBody();
    const selectedDate = calendar.getSelectedDate();

    if (!selectedDate) {
      return;
    }

    let blockDays = _.differenceWith([1, 2, 3, 4, 5, 6, 7], workDays, _.isEqual);

    blockDays = _.map(blockDays, day => (day === 7 ? 0 : day));

    if (_.includes(blockDays, selectedDate.getDay())) {
      while (_.includes(blockDays, selectedDate.getDay())) {
        selectedDate.setDate(selectedDate.getDate() + 1);
      }

      calendar.selectDate(selectedDate);
      daySelectView.setValue(selectedDate);
    }
  },
};

const innerHelpers = {};

webix.ui({
  view: 'popup',
  id: innerViews.templatesPopup.id,
  css: innerViews.templatesPopup.css,
  width: 570,
  padding: 0,
  body: {
    paddingX: 18,
    paddingY: 6,
    borderless: true,
    width: 568,
    rows: [
      innerViews.templatesUserListLabel,
      innerViews.templatesUserList,
      {
        css: 'divider',
        height: 6,
      },
      innerViews.templatesCategoryListLabel,
      innerViews.templatesCategoryList,
      { height: 6 },
    ],
  },
  on: {
    onShow() {

    },
  },
}).hide();

webix.ui({
  view: 'popup',
  id: innerViews.templatesDetailPopup.id,
  css: innerViews.templatesDetailPopup.css,
  width: 570,
  padding: 0,
  body: {
    paddingY: 6,
    borderless: true,
    width: 568,
    rows: [
      {
        view: 'scrollview',
        css: '',
        scroll: 'y',
        height: 252,
        body: {
          borderless: true,
          paddingX: 18,
          rows: [
            innerViews.templatesDetailListLabel,
            innerViews.templatesDetailList,
            { height: 6 },
          ],
        },
      },
    ],
  },
}).hide();

const outputObject = {
  view: {
    view: 'layout',
    id: innerViews.newProjectLayoutBody.id,
    css: innerViews.newProjectLayoutBody.css,
    borderless: true,
    cols: [
      {},
      {
        width: 570,
        rows: [
          {
            view: 'form',
            id: innerViews.newProjectLayoutForm.id,
            padding: 0,
            borderless: true,
            elements: [
              innerViews.newProjectNameText,
              { height: 1 },
              {
                cols: [
                  innerViews.newProjectStartDaySelect,
                  { width: 10 },
                  {
                    rows: [
                      { height: 2 },
                      {
                        cols: [
                          {
                            view: 'label',
                            label: __('new_label_duration'),
                            height: 36,
                            css: 'new-label-duration',
                          },
                          {},
                          {
                            view: 'template',
                            borderless: true,
                            width: 36,
                            template() {
                              return `<div class='duration-step tooltip-gantt' data-key='duration_step' data-x='10'>${icon_tooltip}</div>`;
                            },
                          },
                        ],
                      },
                      innerViews.newProjectDurationStepSelect,
                    ],
                  },
                ],
              },
              { height: 1 },
              innerViews.newProjectTemplatesSelect,
              innerViews.templatesDetailSelect,
              { height: 1 },
              calendarSettings.view,
              { height: 8 },
              {
                view: 'button',
                id: 'mainButtonID',
                height: 46,
                value: __('new_create_btn'),
                css: 'green_btn gp_autotest_newproject_create_button',
                on: {
                  onItemClick: () => {
                    innerHandlers.createNewProject();
                  },
                },
              },
              { height: 1 },
              {
                cols: [
                  {
                    view: 'button',
                    // height: 46,
                    css: 'custom_white_btn gp_autotest_newproject_import_button',
                    value: __('import_from'),
                    on: {
                      onItemClick() {
                        routerHelper.pushRoute({ name: 'importProject' });
                        // app.trigger('tabs:newProject:changeForm', 'importProject'); // !FIXME
                        userExtAnalytics.log('project_create_import_open');
                      },
                    },
                  },
                  { width: 10 },
                  {
                    view: 'template',
                    height: 46,
                    value: __('project_from_jira'),
                    css: 'project_from_jira_btn gp_autotest_newproject_import_jira_button',
                    id: 'project_from_jira_btn',
                    template() {
                      const planName = __(`${getPlans('jira_cloud')}_plan_locale`);
                      const planToAccess = `<span class="pricing-title-container">${planName}</span>`;

                      return checkPricingAccess('jira_cloud') ? `<div class="custom_white_btn_jira">${this.value}</div>`
                        : `<div class="pricing-gray-button pricing-tooltip"  data-feature="jira_cloud" data-position="bottom"><div>${this.value}</div>
                        ${planToAccess}</div>`;
                    },
                    onClick: {
                      custom_white_btn_jira() {
                        if (checkPricingAccess('jira_cloud')) {
                          routerHelper.pushRoute({ name: 'importJiraProject' });
                        }
                        // app.trigger('tabs:newProject:changeForm', 'jiraProject'); // !FIXME
                        userExtAnalytics.log('project_create_jira_open');
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {},
    ],
  },
  init: innerHandlers.init,
  createNewProjectWithoutDialogIfUserCameFromTemplate: innerHandlers.createNewProjectWithoutDialogIfUserCameFromTemplate,
  createNewProjectWithoutDialogIfUserCameFromMpp: innerHandlers.createNewProjectWithoutDialogIfUserCameFromMpp,
};

app.on('newProject:updateWorkDays', workDays => {
  innerHandlers.checkAndUpdateStartDate(workDays);
});
app.on('templateData', templateData => {
  innerHandlers.createNewProject(templateData);
});

app.on('ownerWasRemovedModal', () => {
  notificationModal.init({
    title: __('owner_remove_account_title'),
    width: 420,
    unClosable: true,
    body: {
      css: '',
      template: __('owner_remove_account_body'),
    },
    buttons: [
      {
        width: 180,
        text: __('owner_remove_account_button_delete'),
        css: '',
        callback() {
          profileModel.deleteProfile().then(() => {
            document.location.href = `/logout/${user.locale}`;
          });
        },
      },
      {
        width: 180,
        text: __('common_logout'),
        css: '',
        callback() {
          _.delay(() => {
            document.location.href = `/logout/${user.locale}`;
          });
        },
      },
    ],
  });
});

export default outputObject;
