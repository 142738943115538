<template>
  <div>
    <v-data-table
      v-model="selected"
      class="elevation-0 personal-projects-table gantt_scroll"
      :height="tableHeight"
      :headers="tableHeaders"
      fixed-header
      :items="projects"
      :item-key="id"
      hide-default-footer
      disable-pagination
      :show-select="false"
      :calculate-widths="true"
    >
      <template #item.name="{ item }">
        <div
          class="name-cell"
          :style="{maxWidth: `${projectNameWidth}px`}"
        >
          <tooltip
            :class="['tooltip-wrapper']"
            :content="item.name"
          >
            <template #body>
              {{ item.name }}
            </template>
          </tooltip>
        </div>
      </template>
      <template #item.type="{ item }">
        <div
          v-if="item.costAccess"
          :key="costComponentKey"
          class="type-cell"
        >
          <div
            class="cost-dropdown"
            :style="isFreezeCost ? {'pointer-events': 'none'} : {}"
          >
            <dropdown-select
              class="gp_autotest_projects_table_dropdown_select_cost"
              :drop-list="costDropdown"
              :multiselect="false"
              :show-selected-first="false"
              :show-reset-btn="false"
              :highlight-active-state="false"
              item-template="text"
              track-by-text="text"
              outline="hover"
              track-by-item-tooltip="tooltip"
              :selected="costDropdown.find(i => i.id === item.type)"
              @selected="onSelectCostType($event, item)"
            />
          </div>
        </div>
        <div
          v-else
          class="no-rights-item"
        >
          <div class="no-rights-item__text">
            {{ locales('no_rights_key') }}
          </div>
          <svg-sprite
            class="icon"
            name="hiden"
            color="#B2B2B2"
          />
        </div>
      </template>
      <template #item.cost="{ item }">
        <div
          v-if="item.costAccess"
          :key="costComponentKey"
          class="cost-cell"
        >
          <div
            v-if="item.type === 3"
            class="cost-disable"
          >
            <div />
          </div>
          <div
            v-else-if="!autoBudget(item.projectId)"
            v-tippy="{ content: locales('budget_manual_mode_tooltip_notify') }"
            class="cost-disable"
          >
            <div />
          </div>
          <div
            v-else
            :style="isFreezeCost ? {'pointer-events': 'none'} : {}"
          >
            <!-- <vgp-input
              class="cost-input gp_autotest_projects_table_cost_input"
              input-type="number"
              :prefix="currencySign"
              :value="item.cost"
              :small="true"
              :blur-on-enter="true"
              :change-by-enter="true"
              @onChange="($event, value) => onChangeCost(value, item)"
              @onKeyup="($event, value) => onChangeCost(value, item)"
            /> -->
            <vgp-row-input
              class="cost-input gp_autotest_projects_table_cost_input"
              :value="item.cost"
              :font-size="12"
              :max-width="80"
              :input-type="'number'"
              :format="'currency'"
              :bold="true"
              @onChange="(value) => onChangeCost(value, item)"
              @onKeydown="(value) => onChangeCost(value, item)"
            />
          </div>
        </div>
      </template>
      <template #item.rights="{ item }">
        <div class="rights-cell">
          <div
            v-if="item.roleAccess"
            class="rights-cell__dropdown"
          >
            <dropdown-select
              class="gp_autotest_projects_table_dropdown_select_rights"
              :drop-list="dropdownRoles"
              :multiselect="false"
              :show-selected-first="false"
              :show-reset-btn="false"
              :highlight-active-state="false"
              item-template="text"
              track-by-text="title"
              outline="hover"
              drop-position="fixed"
              track-by-item-tooltip="tooltip"
              :fixed-width="320"
              :need-description="true"
              :size="'autoWidth'"
              parent-selector-for-calc-position-drop="personal-projects-table"
              :selected="dropdownRoles.find(role => role.id === item.projectRoleId)"
              @selected="$event => onChangeProjectRole($event, item)"
            />
          </div>
          <vgp-input
            v-else
            :value="item.roleOnProject.title"
            :small="true"
            :is-disabled="true"
            class="rights-cell__description gp_autotest_projects_table_input_rights"
          />
        </div>
      </template>
      <template #item.deleteButton="{ item }">
        <div
          v-if="item.removeAccess"
          class="del-cell"
        >
          <icon-button
            :icon="{ name:'delete', size: 20 }"
            class="gp_autotest_projects_table_btn_delete"
            size="small"
            @onClick="onDeleteFromProject(item)"
          />
        </div>
      </template>
    </v-data-table>
    <confirm-popup
      :is-show="isShowConfirmPopup"
      :title="locales('change_resource_type_title')"
      :message="locales('change_resource_type_warning')"
      @onApply="onApplyChangeType"
      @onCancel="onCancelChangeType"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import constants from '../../../../helpers/constants';
import projectsModel from '../../../../models/projects';

import dropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import Tooltip from '../../../common/VueComponents/tooltips/tooltip.vue';
import IconButton from '../../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import confirmPopup from '../popups/confirmPopup.vue';

export default {
  name: 'ProjectsTable',
  components: {
    dropdownSelect,
    Tooltip,
    IconButton,
    VgpInput,
    confirmPopup,
  },
  props: {
    projects: { type: Array, required: true },
    projectRoles: { type: Array, required: true, default: () => [] },
    isMaterial: { type: Boolean, required: false, default: false },
    pricingAccessToCostSettings: { type: Boolean, required: false, default: false },
  },
  emits: {
    onChangeCost: Object,
    onDeleteFromProject: Object,
    onChangeProjectRole: Object,
    onChangeCostType: Object,
  },
  data() {
    return {
      costComponentKey: 0,
      locales: __,
      costDropdown: [
        {
          id: constants.RESOURCE_TIME_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_TIME_TYPE]),
          tooltip: { content: __('team_resources_per_hour') },
          tooltipPosition: 'right',
        },
        {
          id: constants.RESOURCE_ITEM_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_ITEM_TYPE]),
          tooltip: { content: __('team_resources_per_item') },
          tooltipPosition: 'right',
        },
        {
          id: constants.RESOURCE_COST_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_COST_TYPE]),
          tooltip: { content: __('team_resources_cost') },
          tooltipPosition: 'right',
        },
      ],
      tooltips: {
        rightToProject: {
          admin: __('resources_card_icon_tooltip'),
          member: __('resources_card_icon_tooltip_mem'),
        },
        rightToEdit: {
          admin: __('resources_edit_icon_tooltip'),
          member: __('resources_edit_icon_tooltip_mem'),
        },
        rightToCost: {
          admin: __('resources_price_icon_tooltip'),
          member: __('resources_price_icon_tooltip_mem'),
        },
      },
      tableHeight: '',
      projectNameWidth: '',
      newCostType: {},
      isShowConfirmPopup: false,
      isFreezeCost: false,
    };
  },
  computed: {
    tableHeaders() {
      let tableHeaders = [
        {
          text: __('table_project_name'), value: 'name', sortable: true, width: 160, class: 'sticky-header',
        },
        {
          text: __('table_cost_type'), value: 'type', sortable: false, width: 100, class: 'sticky-header',
        },
        {
          text: __('table_resource_cost'), value: 'cost', sortable: false, width: 90, class: ['sticky-header', 'item-cost'], cellClass: 'item-cost',
        },
        {
          text: __('table_resource_role'), value: 'rights', sortable: false, width: 75, class: 'sticky-header',
        },
        {
          text: null, value: 'deleteButton', sortable: false, class: 'sticky-header', width: 24,
        },
      ];

      if (!this.pricingAccessToCostSettings) {
        tableHeaders = tableHeaders.filter(obj => !['cost', 'type'].includes(obj.value));
        tableHeaders[0].width = 345;

        if (this.isMaterial) {
          tableHeaders = tableHeaders.filter(obj => obj.value !== 'rights');
        }

        return tableHeaders;
      }

      if (this.isMaterial) {
        tableHeaders = tableHeaders.filter(obj => obj.value !== 'rights');
      }

      return tableHeaders;
    },
    dropdownRoles() {
      const roles = this.projectRoles.filter(role => role.defaultType !== 'OWNER');
      const withTooltip = roles.map(item => {
        const role = { ...item };

        role.tooltip = { content: item?.title };

        return role;
      });

      return withTooltip;
    },
    ...mapState('teamSetting', {
      currencySign: state => {
        if (!state.enableCurrency) {
          return '';
        }

        return GT.currency.find(item => item.ID === state.currency)?.VALUE || '$';
      },
    }),
  },
  watch: {
    projects() {
      this.calcHeight();
    },
  },
  mounted() {
    setTimeout(() => this.calcHeight(), 0);

    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    this.projectNameWidth = 160;

    if (!this.pricingAccessToCostSettings) {
      if (this.$route.params.activeView === 'people') {
        this.projectNameWidth = 320;
      }

      if (this.$route.params.activeView === 'material') {
        this.projectNameWidth = 450;
      }
    }
  },
  methods: {
    onDeleteFromProject(project) {
      this.$emit('onDeleteFromProject', project);
    },
    onFreezeCost(flag) {
      this.isFreezeCost = flag;
    },
    calcHeight() {
      const tableNode = this.$el;
      const coords = tableNode.getBoundingClientRect();

      this.tableHeight = `${document.documentElement.clientHeight - coords.top - 30}px`;

      if (!this.projects || !this.projects.length) {
        this.tableHeight = `${48}px`;
      }
    },
    onResize() {
      this.calcHeight();
    },
    onSelectCostType(newType, projectData) {
      if (newType[0].id === projectData.type) return;

      this.isShowConfirmPopup = true;
      this.newCostType = {
        newTypeId: newType[0].id,
        projectIds: [projectData.projectId],
      };
    },
    onApplyChangeType() {
      this.$emit('onChangeCostType', this.newCostType);
      this.isShowConfirmPopup = false;
    },
    onCancelChangeType() {
      this.costComponentKey++;
      this.newCostType = null;
      this.isShowConfirmPopup = false;
    },
    onChangeCost(value, project) {
      if (project.cost === 0 && value === '') {
        this.costComponentKey++;
      }

      const preparedValue = value.replace(/,/g, '.');

      this.onFreezeCost(true);
      this.$emit('onChangeCost', {
        newCost: preparedValue,
        projectIds: [project.projectId],
      });
      this.onFreezeCost(false);
    },

    onChangeProjectRole(roleData, project) {
      const data = {
        roleId: roleData[0].id,
        projectId: project.projectId,
      };

      this.$emit('onChangeProjectRole', data);
    },
    autoBudget(projectId) {
      return projectsModel.getProjectConfig(projectId).auto_budget;
    },
  },
};
</script>

<style src='./resourceProjects.less'></style>
