var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "vgp-chips-" + _vm.componentKey,
      class:
        ((_obj = {}),
        (_obj[_vm.$style.vgp_chips] = true),
        (_obj[_vm.$style.vgp_chips_label] = _vm.preset === "label"),
        (_obj[_vm.$style.ellipsis_true] = _vm.txtEllipsis),
        (_obj[_vm.$style.width100] = _vm.fillWidth),
        _obj),
      style: _vm.presetStyle,
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.isUse("imgUrl")
        ? _c("div", {
            class:
              ((_obj$1 = {}),
              (_obj$1[_vm.$style.avatar] = _vm.isUse("isAvatar")),
              (_obj$1[_vm.$style.vgp_chips_image] = true),
              _obj$1),
            style: {
              "background-image": "url(" + _vm.imgUrl + ")",
              width: _vm.imgSize + "px",
              height: _vm.imgSize + "px",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isUse("statusColor")
        ? _c("div", {
            class: [_vm.$style.circle_status],
            style: { "background-color": _vm.statusColor },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isUse("icon")
        ? _c("svg-sprite", {
            style: { "margin-right": "8px" },
            attrs: {
              name: _vm.icon.name,
              type: _vm.icon.type,
              size: _vm.icon.size,
              color: _vm.selected ? "inherit" : _vm.icon.color,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isUse("text") || (_vm.description && _vm.needDescription)
        ? _c(
            "div",
            { class: [_vm.$style.vgp_chips_name_block, _vm.$style.ellipsis] },
            [
              _c(
                "span",
                { class: [_vm.$style.vgp_chips_name, _vm.$style.ellipsis] },
                [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
              ),
              _vm._v(" "),
              _vm.description && _vm.needDescription
                ? _c(
                    "span",
                    {
                      class: [
                        _vm.$style.vgp_chips_description,
                        _vm.selected
                          ? _vm.$style.vgp_chips_description_select
                          : "",
                      ],
                    },
                    [_vm._v("\n      " + _vm._s(_vm.description) + "\n    ")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }