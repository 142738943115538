// mass-change select menu items
import massChangeModel from './massChange';

const __ = window.__;

const menuItemIds = {
    SELECT_ALL: 'idSelectAll',
    UNSELECT_ALL: 'idUnselectAll',
    SELECT_CURRENT: 'idSelectCurrent',
    UNSELECT_CURRENT: 'idUnselectCurrent'
};

const _selectMenuData = {
    [menuItemIds.SELECT_ALL]: {
        id: menuItemIds.SELECT_ALL,
        value: __('app_masschange_menu_sel_all'),
        handler: () => {
            const __idActiveGridTask = massChangeModel.getActive();

            massChangeModel.selectTask(__idActiveGridTask, true);
            userExtAnalytics.log('masschange_select_task', {from: 'context menu'}); 
        }
    },
    [menuItemIds.UNSELECT_ALL]: {
        id: menuItemIds.UNSELECT_ALL,
        value: __('app_masschange_menu_unsel_all'),
        handler: () => {
            const __idActiveGridTask = massChangeModel.getActive();

            massChangeModel.unselectTask(__idActiveGridTask, true);
            userExtAnalytics.log('masschange_unselect_task', {from: 'context menu'}); 
        }
    },
    [menuItemIds.SELECT_CURRENT]: {
        id: menuItemIds.SELECT_CURRENT,
        value: __('app_masschange_menu_sel_one'),
        handler: () => {
            const __idActiveGridTask = massChangeModel.getActive();
            
            massChangeModel.selectTask(__idActiveGridTask, false);
            userExtAnalytics.log('masschange_select_task', {from: 'context menu'}); 
        }
    },
    [menuItemIds.UNSELECT_CURRENT]: {
        id: menuItemIds.UNSELECT_CURRENT,
        value: __('app_masschange_menu_unsel_one'),
        handler: () => {
            const __idActiveGridTask = massChangeModel.getActive();

            massChangeModel.unselectTask(__idActiveGridTask, false);
            userExtAnalytics.log('masschange_unselect_task', {from: 'context menu'}); 
        }
    }
};

// {Array} -> {Array}
const getMenu = (dataArr /* idsArr */) =>
    dataArr.reduce((result, entry) =>
        _selectMenuData[entry.id] ? result.concat({..._selectMenuData[entry.id], disabled: entry.disabled }) : result, []);

// {Any} -> {Object}
const getMenuItem = idEntry => _selectMenuData[idEntry];

export {
    menuItemIds, getMenu, getMenuItem
};
