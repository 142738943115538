<template>
  <div
    :class="[$.toast_cmp]"
  >
    <div
      :class="$.message"
      v-html="message"
    />
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: 'text' },
  },
  data() {
    return {
      locales: __,
    };
  },
};
</script>

<style module="$" lang="less">
@import "../../../less/_fonts.less";
@import "../../../less/_mixins.less";
@import "../../../less/_variables.less";

.toast_cmp {
  .FontLatoRegular;
  .fs(16px, 24px);
  color: #191919;
}
</style>
