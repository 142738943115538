<template>
  <core-popup
    show
    :close-icon="true"
    :title="locales('convert_resources_title')"
    :class-list="popup.convert_modal"
    @onClickCancel="onClose"
  >
    <template #body>
      <div
        v-if="currentStep === 1"
        :class="popup.convert_resources_popup"
      >
        <div :class="popup.convert_resources_popup_message">
          {{ locales('convert_resources_description') }}
        </div>

        <div :class="popup.convert_resources_popup_select_resource">
          <div :class="popup.source_resource">
            <vgp-avatar
              size="small"
              :src="sourceResource.photo"
            />

            <div :class="popup.source_resource_info">
              {{ sourceResource.name }}
            </div>
          </div>

          <div>
            <svg-sprite
              :name="'arrow-right-2'"
              :type="'regular'"
              :size="24"
              color="#B2B2B2"
            />
          </div>

          <div :class="popup.target_resource">
            <vgp-label-slot :label="locales('convert_resources_real_dropdown_label')">
              <template #body>
                <dropdown-select
                  :placeholder="locales('convert_resources_real_dropdown_placeholder')"
                  :no-matches-message="locales('convert_resources_real_dropdown_no_matches')"
                  :drop-list="dropListUsers"
                  :group-by="'team'"
                  :devider-color="'#999999'"
                  :use-search="true"
                  :multiselect="false"
                  :show-reset-btn="false"
                  item-template="user"
                  track-by-img-url="photo"
                  :selected="targetResource"
                  @change="onChangeSelected"
                />
              </template>
            </vgp-label-slot>
          </div>
        </div>

        <div :class="popup.settings">
          <div :class="popup.setting_checkbox">
            <vgp-checkbox
              :size="16"
              :checked="shouldRemoveSource"
              @onClick="handleCheckbox"
            />
          </div>
          
          <div
            :class="popup.setting_label"
            @click="handleCheckbox"
          >
            {{ locales('convert_resources_remove_virtual_label') }}
          </div>
        </div>

        <div :class="popup.convert_resources_popup_footer">
          <div :class="popup.buttons">
            <vgp-button
              type="secondary"
              :label="locales('common_cancel')"
              :small="true"
              @onClick="onClose"
            />
            <vgp-button
              :style="{marginLeft: 16 + 'px'}"
              type="primary"
              :label="locales('next')"
              :small="true"
              :disabled="!targetResource"
              @onClick="currentStep++"
            />
          </div>
        </div>
      </div>

      <div
        v-if="currentStep === 2"
        :class="popup.convert_resources_popup"
      >
        <div
          v-if="!isResourceCalendarApplied || isResourceCalendarTotalDifferent"
          :class="popup.convert_resources_warning"
        >
          <div>
            <svg-sprite
              :name="'warning-1'"
              :type="'bold'"
              :size="20"
              :color="'#FF9A00'"
            />
          </div>

          <span
            v-if="!isResourceCalendarApplied"
            :class="popup.convert_resources_warning_text"
          >
            {{ locales('convert_resources_calendar_not_applied_warning_text') }}
          </span>

          <span
            v-else-if="isResourceCalendarTotalDifferent"
            :class="popup.convert_resources_warning_text"
          >
            {{ locales('convert_resources_calendar_total_different_warning_text') }}
          </span>
        </div>

        <div
          v-for="mode in modeList"
          :key="mode.id"
          :class="[
            popup.radio_input_wrapper,
            mode.id === 'updateTasks' && (!isResourceCalendarApplied || isResourceCalendarTotalDifferent) ? popup.radio_disabled : ''
          ]"
        >
          <label :class="popup.radio_label">
            <input
              v-model="selectedMode"
              type="radio"
              :value="mode.id"
            >

            <span :class="popup.radio_input" />

            <span :class="popup.radio_title">
              {{ locales(mode.label) }}
            </span>
          </label>

          <div :class="popup.radio_description">{{ locales(mode.description) }}</div>
        </div>

        <div :class="popup.buttons">
          <vgp-button
            type="secondary"
            :label="locales('back_btn')"
            :small="true"
            @onClick="currentStep--"
          />
          <vgp-button
            :style="{marginLeft: 16 + 'px'}"
            type="primary"
            :label="locales('convert_resources_submit_button')"
            :small="true"
            :disabled="!targetResource || !selectedMode"
            @onClick="onConvert"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import CorePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import vgpCheckbox from '$$vueCmp/checkbox/vgpCheckbox.vue';
import VgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import { isResourceCalendarTotalDifferentFromProject, isResourceCalendarAppliedOnProject } from '$$helpers/calendar';
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import VgpAvatar from '../../../common/VueComponents/avatar/vgpAvatar.vue';
import VgpLabelSlot from '../../../common/VueComponents/label/vgpLabelSlot.vue';
import app from '../../../../app';

export default {
  components: {
    CorePopup,
    vgpCheckbox,
    VgpButton,
    DropdownSelect,
    VgpAvatar,
    VgpLabelSlot,
    svgSprite,
  },
  props: {
    config: { type: Object, required: true },
  },
  emits: {
    onClose: Boolean,
    convertResourcesOnProject: Object,
  },
  data() {
    return {
      locales: __,
      targetResourceId: null,
      shouldRemoveSource: false,
      defaultAvatar: `${GT.cdn}/imgs/default/default-ava.svg`,
      currentStep: 1,
      modeList: [
        { id: 'updateTasks', label: 'convert_resources_update_tasks_mode_label', description: 'convert_resources_update_tasks_mode_description' },
        { id: 'reassignOnly', label: 'convert_resources_reassign_only_mode_label', description: 'convert_resources_reassign_only_mode_description' },
      ],
      selectedMode: null,
      isResourceCalendarApplied: false,
      isResourceCalendarTotalDifferent: false,
      eventsListeners: [],
    };
  },
  created() {
    this.getProjectData();

    this.eventsListeners.push(app.on('projectModel:updateProject', ganttId => {
      if (this.config.projectId === ganttId) {
        this.getProjectData();
      }
    }));

  },
  beforeDestroy() {
    this.eventsListeners.forEach(id => app.off(id));
    this.eventsListeners = [];
  },
  computed: {
    dropListUsers() {
      const [projectUsers, accountUsers] = this.$store.getters['resourcesModel/getAllRealResources'].reduce(([inProject, inAccount], user) => {
        if (user.invite.status) {
          return [inProject, inAccount];
        }

        const assignedToProject = user.resourceProjects.some(resourceProject => resourceProject.projectId === this.config.projectId);

        if (assignedToProject) {
          inProject.push({
            ...user,
            photo: user.photo || this.defaultAvatar,
            team: this.locales('convert_resources_real_dropdown_project'),
          });
        } else {
          inAccount.push({
            ...user,
            photo: user.photo || this.defaultAvatar,
            team: this.locales('convert_resources_real_dropdown_account'),
          });
        }

        return [inProject, inAccount];
      }, [[], []]);

      return [
        ...projectUsers,
        ...accountUsers,
      ];
    },
    targetResource() {
      const resource = this.$store.getters['resourcesModel/getAllRealResources'].find(user => user.id === this.targetResourceId && !user.invite.status);

      if (resource) {
        return { ...resource, photo: resource.photo || this.defaultAvatar };
      }

      return resource;
    },
    sourceResource() {
      const { sourceResourceId, projectId } = this.config;

      if (this.config.type === 'project') {
        return this.$store.getters['resourcesModel/getMaterialResourcesByGanttId'](projectId).find(resource => resource.id === sourceResourceId);
      }

      return this.$store.getters['resourcesModel/getAllMaterialResources'].find(resource => resource.id === sourceResourceId);
    },
  },
  watch: {
    sourceResource(value) {
      if (!value) {
        this.onClose();
      }
    },

    targetResource(value) {
      if (value) {
        this.isResourceCalendarTotalDifferent = isResourceCalendarTotalDifferentFromProject(value.id, this.config.projectId);
      }
    },

    isResourceCalendarApplied(value) {
      if (!value && this.selectedMode === 'updateTasks') {
        this.selectedMode = null;
      }
    },

    isResourceCalendarTotalDifferent(value) {
      if (value && this.selectedMode === 'updateTasks') {
        this.selectedMode = null;
      }
    },
  },
  methods: {
    getProjectData() {
      this.isResourceCalendarApplied = isResourceCalendarAppliedOnProject(this.config.projectId);

      if (this.targetResource) {
        this.isResourceCalendarTotalDifferent = isResourceCalendarTotalDifferentFromProject(this.targetResource.id, this.config.projectId);
      } else {
        this.isResourceCalendarTotalDifferent = false;
      }
    },

    onChangeSelected(selectedData) {
      this.targetResourceId = selectedData.length ? selectedData[0]?.id : null;
    },

    handleCheckbox() {
      this.shouldRemoveSource = !this.shouldRemoveSource;
    },

    onClose() {
      this.$emit('onClose');

      this.targetResourceId = null;
      this.shouldRemoveSource = false;
      this.selectedMode = null;
      this.currentStep = 1;
    },

    onConvert() {
      if (this.config.type === 'project') {
        this.$emit('convertResourcesOnProject', {
          projectId: this.config.projectId,
          shouldUpdateTasks: this.selectedMode === 'updateTasks',
          convertResources: [
            {
              sourceResourceId: this.sourceResource.id,
              targetResourceId: this.targetResource.id,
              shouldRemoveSource: this.shouldRemoveSource,
            },
          ],
        });

        this.onClose();
      }
    },
  },
};
</script>

<style module='popup' src='./popups.less'></style>
