<template>
  <div :class="['team-size-select', className ? className : '']">
    <div class="description">
      {{ label }}
    </div>
    <div class="select">
      <div
        :class="['dropdown', isShowSelectMenu ? 'active' : '']"
        @click="showSelect"
      >
        <div class="text">
          {{ `${nextCount} ${+nextCount === 1 ? locales('one_user_locale') : locales('users_locale')}` }}
        </div>
        <webix-ui
          class="arrow-icon"
          :config="icons.arrowMore"
        />
      </div>
      <div
        v-if="isShowSelectMenu"
        class="list gantt_scroll"
      >
        <div
          v-for="(count, index) in numberOfPlan"
          :key="index"
          :class="['select-item', count.value === nextCount ? 'active' : '', count.disabled ? 'disabled' : '']"
          @click="onSelectItem(count)"
        >
          <p class="select-item__text">
            <span>{{ count.value }}</span> {{ count.value === 1? locales('one_user_locale') : locales('users_locale') }}
          </p>
          <div
            v-if="!isAnnual && count.value === 1"
            class="select-item__icon tooltip-gantt"
            :data-key="locales('base_plan_info_tooltip')"
            :data-position="'bottom'"
            :data-y="0"
            :data-x="0"
          >
            <webix-ui
              class="info-icon"
              :config="icons.infoIcon"
            />
          </div>
          <div
            v-if="(!isPopup && type === 'buy-new-plan' && isAnnual && count.disabled)
              || (!isPopup && type === 'buy-new-plan' && !isAnnual && count.disabled && count.value !== 1)"
            class="select-item__icon tooltip-gantt"
            :data-key="locales('can_not_use_plan')"
            :data-position="'bottom'"
            :data-y="0"
            :data-x="0"
          >
            <webix-ui
              class="info-icon"
              :config="icons.infoIcon"
            />
          </div>
        </div>
        <div
          class="select-item contact-item"
          @click="contactClick"
        >
          {{ locales('contact_item') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plansModel from '../../../models/plans';

import svgIconForVue from '../../common/svgIconForVue';
import arrowMoreIcon from '../../../svg/ic_arrow_more.svg';
import infoIcon from '../../../svg/ic_info.svg';
import app from '../../../app';

export default {
  props: [
    'getUserCount',
    'isPopup',
    'className',
    'label',
    'isAnnual',
    'numberOfPlan',
    'type',
    'plan'
  ],
  data() {
    return {
      locales: __,
      // dropdownValue: '',
      // activeSelectItem: '',
      isShowSelectMenu: false,
      icons: {
        arrowMore: svgIconForVue(arrowMoreIcon, 16, 16),
        infoIcon: svgIconForVue(infoIcon, 24, 24),
      },
    };
  },
  computed: {
    userCount() {
      return user.subscription.user_count;
    },
    nextCount() {
      return this.$store.getters['paymentPlans/nextCount'];
    },
    isTrial() {
      return plansModel.isTrial();
    },
  },
  mounted() {
    document.addEventListener('click', this.hideSelect);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideSelect);
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
    showSelect() {
      this.isShowSelectMenu = true;
    },
    hideSelect(e) {
      if (e.target.closest('.popup_proto_shadow') || e.target.closest('.message-us-form')) {
        return;
      }
      if (!this.$el.contains(e.target)) {
        this.isShowSelectMenu = false;
      }
    },
    onSelectItem(count) {
      if (count.disabled) {
        return;
      }
      this.$store.commit('paymentPlans/setNextCount', count.value);
      this.isShowSelectMenu = false;
    },
    contactClick() {
      const planName = this.plan.name;
      debugger;
      app.trigger('messageUsForm:show', 'price-app-contact-us-form', planName);
    },
  },
};
</script>

<style scoped src="../less/select.less"></style>
<style lang="less">
.select-item__text {
  margin-bottom: 0 !important;
}

.arrow-icon {
  .webix_view {
    cursor: pointer;
  }
}

.select-item__icon {
  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .webix_view {
      cursor: pointer;

      .webix_template {
        svg {
          path {
            fill: #757575;
          }
        }

        &:hover {
          svg {
            path {
              fill: #424242;
            }
          }
        }
      }
    }
  }
}
</style>
