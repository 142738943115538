<template>
  <div id="vue-gallery" style="background-color: transparent">
    <LightBox 
      ref="lightbox"
      :show-caption="true"
      :show-light-box="isShow"
    ></LightBox>
  </div>
</template>

<script>

import LightBox from './components/LightBox.vue'
import storeGallery from '../../views/vueGallery/storeGallery';

export default {
  name: 'app-gallery',
  store: storeGallery,
  components: {
    LightBox
  }
}
</script>