
// От Оли Листопад из вебикса:
// Ситуацию с инпутами занесли в баг-трекер.
// Постараемся решить, но вариантов несколько и пока что точных сроков нет, к сожалению.

// Если сделать что-то  хочется прямо сейчас - могу предложить костыль.
// При должных проверках (условия можно скорректировать как угодно) он просто не даст вызваться нашему глобальному onClick и перенести фокус.
// https://snippet.webix.com/6n8shnc2
// Есть большой (возможно) минус - решение не глобальное.
// Придётся вешать такие штуки отдельно на top layout (в примере), отдельно на каждое окно, где это нужно.

// Причины, если нужно обоснование костыля:
// 1. структура наших вьюшек в DOM внутри body разделена на layout (или несколько, если они в разных html-контейнерах, и все окна (window, popup, context), которые тоже лежат отдельно.
// 2. глобальный onclick, на котором запускается логика нашего фокуса, висит как раз на body (т.е. блокируется снизу)


// УДАЛИТЕ ПЛИЗ КТО НИБУДЬ ВСЕ ЭТО КОГДА ВЕБИКС ПОФИКСИТ НА УРОВНЕ БИБЛИОТЕКИ

const innerObject = {
  fix: (topWebixNode) => {


    if(topWebixNode && !topWebixNode.___webix_mousedoun_outside_input_fix){ // do it once
      const topNode = topWebixNode.$view;
      let mdView = null;
      webix.event(topNode, "mousedown", e => {
        const targetView = $$(e);
        // use this only if we start mousedown in input
        if (targetView && targetView.getInputNode){
          mdView = targetView;
        }
      });

      webix.event(topNode, "click", e => {
        const clickedView = $$(e);
        // if cursor was dragged from input to any other Webix view, then released
        if (mdView && clickedView && clickedView.config.id !== mdView.config.id){
          e.cancelBubble = true;
        }
        mdView = null;
      });
      topWebixNode.___webix_mousedoun_outside_input_fix = true;
    }
  },
};

export default innerObject;