<template>
  <portal
    v-if="show"
    to="popup"
  >
    <div
      ref="popup"
      :class="[$style.core_popup, 'core_popup', classList]"
      :style="inlineStyles"
    >
      <div
        ref="popup_container"
        :class="[$style.container, $style.show, 'core_popup_block']"
        :style="width ? `max-width: ${width}px !important; min-width: ${width}px !important` : ''"
      >
        <div
          v-if="infoIcon || title || helpIcon || closeIcon"
          :class="[$style.head, $style[headerStyle]]"
        >
          <div :class="$style.head__left">
            <sprite
              v-if="infoIcon"
              color="#808080"
              :class="$style.head__info_icon"
              :name="infoIcon"
            />
            <span
              v-if="title"
              :class="[$style.head__title, $style[headerStyle]]"
            >
              {{ title }}
            </span>
          </div>
          <div :class="$style.head__right">
            <vgp-icon-button
              v-if="helpIcon"
              size="small"
              :icon="{ name:'help', type:'regular', size: 20 }"
              @click="onClickInfo"
            />
            <sprite
              v-if="closeIcon"
              :class="$style.head__close_icon"
              :name="'close-1'"
              :type="'bold'"
              :size="24"
              :color="'#808080'"
              @click="onClickCancel"
            />
          </div>
        </div>
        <div :class="[$style.body, $style[headerStyle], $style[bodyStyle]]">
          <slot name="body" />
        </div>
        <div
          v-if="okButtonTitle || cancelButtonTitle"
          :class="[$style.footer, $style[footerStyle]]"
        >
          <div :class="$style.actions">
            <vgp-button
              v-if="buttonsSwitch ? cancelButtonTitle : okButtonTitle"
              type="secondary"
              :small="true"
              :label="buttonsSwitch ? cancelButtonTitle : okButtonTitle"
              @onClick="buttonsSwitch ? onClickCancel() : onClickOk()"
            />
            <vgp-button
              v-if="buttonsSwitch ? okButtonTitle : cancelButtonTitle"
              type="primary"
              :small="true"
              :disabled="disabledCancel"
              :label="buttonsSwitch ? okButtonTitle : cancelButtonTitle"
              @onClick="buttonsSwitch ? onClickOk() : onClickCancel()"
            />
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import _ from 'lodash';
import sprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';

export default {
  name: 'CorePopup',
  components: {
    vgpButton,
    sprite,
    vgpIconButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    classList: {
      type: [String, Array],
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    okButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
    cancelButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
    infoIcon: {
      type: String,
      required: false,
    },
    outsideClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerStyle: {
      type: String,
      required: false,
      default: 'withoutBorder',
      validator(value) {
        return ['withBorder', 'withoutBorder'].includes(value);
      },
    },
    bodyStyle: {
      type: String,
      required: false,
      default: '',
    },
    footerStyle: {
      type: String,
      required: false,
      default: '',
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  helpIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundImage: {
      type: String,
      required: false,
      default: '',
    },
    disabledCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonsSwitch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inlineStyles() {
      if (this.backgroundImage) {
        return {
          background: `url(${this.backgroundImage}) no-repeat`,
          backgroundSize: 'cover',
        };
      }

      return {};
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs?.popup?.focus();
    });

    if (this.outsideClose) {
      // code for macrotask
      setTimeout(() => document.addEventListener('click', this.close), 1);
    }
  },

  beforeDestroy() {
    if (this.outsideClose) {
      // code for macrotask
      setTimeout(() => document.removeEventListener('click', this.close), 1);
    }
  },
  methods: {
    close(e) {
      if (this.$refs.popup_container && !this.$refs.popup_container.contains(e.target)) {
        this.$emit('onClickCancel');
      }
    },

    onClickOk: _.debounce(function () {
      this.$emit('onClickOk');
    }, 1500, {
      leading: true,
    }),

    onClickInfo() {
      this.$emit('onClickInfo');
    },
    onClickCancel() {
      this.$emit('onClickCancel');
    },
  },
};
</script>

<style module src='./less/corePopup.less'></style>
