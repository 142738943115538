<template>
  <section
    ref="descriptionContainer"
    :class="$style['task-settings-description']"
  >
    <vgp-quill-editor
      ref="descriptionEditor"
      :class="[
        isFieldDisabled('note') ? $style['task-settings-description-quill_disabled'] : '',
        !quillEditorActive ? $style['task-settings-description-quill_inactive'] : '',
        showExpandButton && !descriptionExpanded ? $style['task-settings-description-quill_collapsed'] : '',
      ]"
      :value="taskData.note"
      :upload-image-callback="addImageToTask"
      :disabled="isFieldDisabled('note')"
      :placeholder="locales('settings_taskDescription')"
      :font-size="16"
      :focus-on-init="false"
      :with-buttons="false"
      :active="quillEditorActive"
      scrolling-container=".task-settings-body-scroll-container"
      @blur="saveDescription"
      @focus="activateEditor"
      @text-change="handleOnTextChange"
    />
    <vgp-icon-button
      size="middle"
      bold
      icon-side="right"
      :class="[
        showExpandButton && !quillEditorActive ? '' : $style['collapse-button_hidden']
      ]"
      :icon="descriptionExpanded ? { name: 'arrow-top' } : { name: 'arrow-down' }"
      :label="descriptionExpanded ? locales('comment_list_collapse_task_comments') : locales('comment_list_expand_task_comments')"
      :colors="expandBtnColors"
      :sizes="{ labelFontSize: '14px', lineHeight: '24px' }"
      @onClick="toggleDescriptionVisibility"
    />
  </section>
</template>

<script>
import VgpQuillEditor from '../../vgpQuillEditor.vue';
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import { internalApi } from '$$store/api';

const MAX_DESCRIPTION_HEIGHT_WITHOUT_EXPAND_BTN = 188;

export default {
  name: 'TaskSettingsDescription',
  components: { VgpIconButton, VgpQuillEditor },
  inject: ['isFieldDisabled'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      descriptionExpanded: false,
      showExpandButton: false,
      expandBtnColors: {
        color: '#808080',
        labelColor: '#808080',
        activeBg: '#E5E5E5',
        hoverBg: '#F8F8F8',
      },
      quillEditorActive: false,
    };
  },
  watch: {
    'taskData.id': {
      handler() {
        this.$nextTick(() => {
          this.collapseContentIfNeeded(this.taskData.note);
        });
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    removeGoogleAnalyticTagFromLinks(content) {
      return content ? content.replace(/(\?|&|&amp;)_ga=[^"'&qoute;]+/gi, '') : content;
    },
    handleOnTextChange(content, { delta }) {
      const isListCheckboxesChanged = ['unchecked', 'checked'].includes(delta.ops[1]?.attributes?.list);

      if (isListCheckboxesChanged && !this.quillEditorActive) {
        this.saveDescription(content);
      }
    },
    handleBeforeUnload(event) {
      const hasUnsavedChanges = (this.taskData.note || null) !== this.removeGoogleAnalyticTagFromLinks(this.$refs.descriptionEditor.getHtml());

      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
        return;
      }
    },
    activateEditor() {
      if (this.isFieldDisabled('note') || this.quillEditorActive) return;
      if (this.showExpandButton && !this.descriptionExpanded) {
        this.descriptionExpanded = true;
      }
      this.quillEditorActive = true;
    },
    toggleDescriptionVisibility() {
      this.descriptionExpanded = !this.descriptionExpanded;
    },
    collapseContentIfNeeded(content, afterSave) {
      if (!content) {
        this.showExpandButton = false;
        this.descriptionExpanded = false;

        return;
      }

      const descriptionContentHeight = this.getDescriptionContentHeight(content);
      const needShowExpandButton = descriptionContentHeight > MAX_DESCRIPTION_HEIGHT_WITHOUT_EXPAND_BTN;

      this.showExpandButton = needShowExpandButton;
      this.descriptionExpanded = afterSave && needShowExpandButton;
    },
    saveDescription(value) {
      if (this.quillEditorActive) {
        this.quillEditorActive = false;
      }
      const newValue = this.removeGoogleAnalyticTagFromLinks(value);
      const oldValue = this.removeGoogleAnalyticTagFromLinks(this.taskData.note);

      if (newValue === oldValue || this.isFieldDisabled('note')) return;
      this.$emit('change', { property: 'note', payload: { newValue, taskIdForUpdate: this.taskData.id } });
      this.collapseContentIfNeeded(newValue, true);
    },
    getDescriptionContentHeight(content) {
      const descriptionContainerWidth = this.$refs.descriptionContainer.clientWidth;
      const div = document.createElement('div');

      div.classList.add('ql-editor');
      div.style.position = 'absolute';
      div.style.height = 'fit-content';
      div.style.width = `${descriptionContainerWidth}px`;
      div.style.visibility = 'hidden';
      div.innerHTML = content;
      this.$refs.descriptionContainer.appendChild(div);
      const height = div.clientHeight;

      this.$refs.descriptionContainer.removeChild(div);

      return height;
    },
    addImageToTask(file) {
      const formData = new FormData();

      formData.append('file', file);

      return internalApi
        .post(
          '/taskImages',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        .then(({ data }) => data.imageUrl);
    },
  },
};
</script>

<style module lang="less">

.task-settings-description-quill {
  &_inactive {
    :global {
      .ql-toolbar.ql-snow {
        opacity: 0;
        pointer-events: none;
      }

      #vgpQuillEditorArea {
        border-color: transparent;
        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }

  }

  &_disabled {
    :global {
      #vgpQuillEditorArea {
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }

  &_collapsed {
    :global {
      #vgpQuillEditorArea .ql-editor {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

}

.collapse-button_hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
</style>
