export default function (value, options, plh) {
  return {
    view: 'multiselectWithoutPoint',
    suggestCss: 'filterOptionSuggestIntegrationSlack',
    placeholder: plh,
    value,
    options: {
      body: {
        yCount: 6,
        data: options,
        template(item) {
          return `${item.value}`;
        },
      }
    },
    on: {
      onChange() {
        this.$scope.$emit('change', $$(this).getValue());
      },
      onFocus() {
        this.$scope.$emit('focus', this);
      }
    }
  };
}
