function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var byteStringLength = byteString.length;
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteStringLength);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteStringLength; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], {type: mimeString});
}

export default dataURItoBlob;
