import Vue from 'vue';

export default {
  setComments(state, { projectId, comments }) {
    Vue.set(state.projectCommentsMap, projectId, comments);

    comments.forEach(comment => {
      if (!state.commentsMap[comment.id]) {
        state.commentsMap[comment.id] = comment;
      }

      const taskMetaData = state.taskCommentsMetaData[comment.taskId];

      if (!taskMetaData) {
        state.taskCommentsMetaData[comment.taskId] = {
          unreadComments: new Set(),
          mentions: new Set(),
          unreadMentions: new Set(),
          projectId: comment.projectId,
        };
      }

      const isMentioned = comment.content.includes(`[~${user.id}]`);

      if (state.commentsActionsMap[comment.id]?.read === 0) {
        state.taskCommentsMetaData[comment.taskId].unreadComments.add(comment.id);
        isMentioned && state.taskCommentsMetaData[comment.taskId].unreadMentions.add(comment.id);
      }

      isMentioned && state.taskCommentsMetaData[comment.taskId].mentions.add(comment.id);
    });
  },
  setCommentsActions(state, payload) {
    payload.forEach(action => {
      state.commentsActionsMap[action.commentId] = action;
    });
    Vue.set(state, 'commentsActions', payload);
  },
  markAsReadCommentsActions(state, commentIds) {
    commentIds.forEach(commentId => {
      const commentActionIndex = state.commentsActions.findIndex(action => action.commentId === commentId);

      if (commentActionIndex !== -1) {
        // Object.assign(state.commentsActions[commentActionIndex], { read: 1 });
        Vue.set(state.commentsActions, commentActionIndex, { ...state.commentsActions[commentActionIndex], read: 1 });
      }

      const commentData = state.commentsMap[commentId];
      const metaData = state.taskCommentsMetaData[commentData.taskId];

      if (metaData && !state.commentsActionsMap[commentId]?.read) {
        metaData.unreadComments.delete(commentId);
        metaData.unreadMentions.delete(commentId);
      }

      if (state.commentsActionsMap[commentId]) {
        state.commentsActionsMap[commentId].read = 1;
      }
    });
  },
  addCommentAction(state, commentAction) {
    state.commentsActions.push(commentAction);

    Vue.set(state, 'commentsActions', state.commentsActions);

    const commentData = state.commentsMap[commentAction.commentId];
    const metaData = state.taskCommentsMetaData[commentData?.taskId];


    if (metaData && !commentAction.read && commentData.userId !== user.id) {
      metaData.unreadComments.add(commentAction.commentId);

      if (commentData.content.includes(`[~${user.id}]`)) {
        metaData.unreadMentions.add(commentAction.commentId);
        metaData.mentions.add(commentAction.commentId);
      }
    }

    if (state.commentsActionsMap[commentAction.commentId]) {
      state.commentsActionsMap[commentAction.commentId].read = commentAction.read;
    } else {
      state.commentsActionsMap[commentAction.commentId] = commentAction;
      if (commentData.userId === user.id) {
        state.commentsActionsMap[commentAction.commentId].read = 1;
      }
    }
  },
  addComment(state, comment) {
    if (state.projectCommentsMap[comment.projectId]) {
      state.projectCommentsMap[comment.projectId].push(comment);
      Vue.set(state.projectCommentsMap, comment.projectId, state.projectCommentsMap[comment.projectId]);
    } else {
      Vue.set(state.projectCommentsMap, comment.projectId, [comment]);
    }

    if (!state.commentsMap[comment.id]) {
      state.commentsMap[comment.id] = comment;
    }

    const commentData = state.commentsMap[comment.id];
    const metaData = state.taskCommentsMetaData[commentData.taskId];
    const isMentioned = commentData.content.includes(`[~${user.id}]`);

    if (metaData) {
      if (isMentioned) {
        metaData.mentions.add(comment.id);
      }
    } else {
      state.taskCommentsMetaData[commentData.taskId] = {
        unreadComments: new Set(),
        mentions: new Set(isMentioned ? [comment.id] : []),
        unreadMentions: new Set(),
        projectId: commentData.projectId,
      };
    }
  },
  updateComment(state, { projectId, commentId, payload }) {
    const projectComments = state.projectCommentsMap[projectId];

    if (!projectComments) return;

    const commentIndex = projectComments.findIndex(item => item.id === commentId);

    if (commentIndex !== -1) {
      Vue.set(projectComments, commentIndex, { ...projectComments[commentIndex], ...payload });
    }

    state.commentsMap[commentId] = { ...state.commentsMap[commentId], ...payload };

    const commentData = state.commentsMap[commentId];
    const metaData = state.taskCommentsMetaData[commentData?.taskId];

    if (metaData) {
      if (payload.content.includes(`[~${user.id}]`)) {
        metaData.mentions.add(commentId);
      } else {
        metaData.mentions.delete(commentId);
        metaData.unreadMentions.delete(commentId);
      }
    }
  },
  removeCommentAction(state, commentId) {
    const commentActionIndex = state.commentsActions.findIndex(commentAction => commentAction.commentId === commentId);

    if (commentActionIndex !== -1) {
      state.commentsActions.splice(commentActionIndex, 1);
    }

    const commentData = state.commentsMap[commentId];
    const metaData = state.taskCommentsMetaData[commentData?.taskId];

    if (metaData && state.commentsActionsMap[commentId]?.read) {
      metaData.unreadComments.add(commentId);

      if (commentData.content.includes(`[~${user.id}]`)) {
        metaData.unreadMentions.add(commentId);
        metaData.mentions.add(commentId);
      }
    }

    delete state.commentsActionsMap[commentId];
  },
  removeComment(state, { projectId, commentId }) {
    const projectComments = state.projectCommentsMap[projectId];

    if (projectComments) {
      const commentIndex = projectComments.findIndex(comment => comment.id === commentId);

      if (commentIndex !== -1) {
        projectComments.splice(commentIndex, 1);
      }
    }

    const commentData = state.commentsMap[commentId];
    const metaData = state.taskCommentsMetaData[commentData?.taskId];

    if (metaData) {
      metaData.unreadComments.delete(commentId);
      metaData.unreadMentions.delete(commentId);
      metaData.mentions.delete(commentId);
    }

    delete state.commentsMap[commentId];
  },
  setIsFetching(state, flag) {
    state.isFetching = flag;
  },
  addOrUpdateDraft(state, payload) {
    if (state.draftsMap[payload.projectId]) {
      Vue.set(state.draftsMap[payload.projectId], payload.taskId, payload.draft);
    } else {
      Vue.set(state.draftsMap, payload.projectId, { [payload.taskId]: payload.draft });
    }
  },
  deleteDraft(state, payload) {
    if (!payload.taskId) {
      Vue.delete(state.draftsMap, payload.projectId);
    } else if (state.draftsMap[payload.projectId]) {
      Vue.delete(state.draftsMap[payload.projectId], payload.taskId);
    }
  },
};
