<template>
  <div
    v-if="isOpenSideBar && favProjects.length >= 1"
    :class="[$style.favProjects]"
  >
    <div
      :class="$style.delimiter"
    />
    <div
      id="gp_autotest_sidebar_favorite_projects_title"
      :class="[$style.title, activeTitle ? $style.active : '']"
      @click="hideShowFavProjects"
    >
      <div :class="$style.title_wrapper">
        <svg-sprite
          name="star"
          type="bold"
          :size="20"
          :style="{marginTop: '-1px'}"
          :class="$style.title_wrapper_star"
        />
        <div :class="$style.title_text">
          {{ locale('sidebar_favorite_projects_title') }}
        </div>
      </div>

      <svg-sprite
        :name="openFavProjects ? 'arrow-top' : 'arrow-down'"
        :style="{marginTop: '1px'}"
        :size="24"
      />
    </div>
    <div
      v-show="openFavProjects"
      :class="[$style.fav_list, isEndScrolled ? '' : $style.haze]"
    >
      <div
        ref="projectsScroll"
        v-scroll.self="handleScrollProjects"
        :class="$style.fav_scroll"
        :style="{height: `${favHeight}px`}"
      >
        <div
          v-for="project in favProjects"
          :key="project.id"
          :data-id="project.id"
          :data-gantt_id="project.gantt_id"
          :data-type="project.type"
          :class="[$style.project_item, +activeItem === project.id ? $style.active : '', 'gp_autotest_sidebar_favorite_projects_item']"
          @click="projectClick(project)"
        >
          <svg-sprite
            :class="$style.project_item_icon"
            :name="getProjectIcon(project)"
            :size="20"
          />
          <div
            :title=" project.name "
            :class="$style.project_item_text"
          >
            {{ project.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FavoriteProjects',
  props: {
    isOpenSideBar: { type: Boolean, required: true },
    favProjects: { type: Array, required: true },
    openFavProjects: { type: Boolean, required: true },
    favHeight: { type: Number, required: true },
    projectOpen: { type: Number, required: true },
    activeItem: { type: String, required: false, default: '' },
  },
  data() {
    return {
      locale: __,
      isEndScrolled: false,
    };
  },
  computed: {
    activeTitle() {
      const activeItem = this.favProjects.find(project => +project.id === +this.activeItem);

      if (activeItem && !this.openFavProjects) {
        return true;
      }

      return false;
    },
  },
  watch: {
    openFavProjects(value) {
      if (value) setTimeout(this.handleScrollProjects);
    },
    favHeight() {
      setTimeout(this.handleScrollProjects);
    },
    favProjects() {
      setTimeout(this.handleScrollProjects);
    },
  },
  mounted() {
  },
  methods: {
    hideShowFavProjects() {
      this.$emit('hideShowFavProjects');
    },
    projectClick(project) {
      this.$emit('projectClick', project);
    },
    getProjectIcon(project) {
      let icon = '';

      if (project.is_jira) {
        icon = 'jira';
      } else {
        icon = (project.ganttIDs) ? 'portfolio' : '';
      }

      return icon;
    },
    handleScrollProjects() {
      const el = this.$refs.projectsScroll;

      if (!el) return false;

      const restDistance = el.scrollHeight - (el.scrollTop + el.offsetHeight);

      this.isEndScrolled = restDistance < 5;
    },
  },
};

</script>

<style module src="../style.less" lang="less"></style>
