<template>
  <onboarding-section :animate-leave="animateLeave">
    <template #right>
      <div :class="$style.right__wrapper">
        <div
          :class="[
            $style.select__wrapper,
            animateEnter && !animateLeave ? $style.animateEnter : '',
            animateLeave && !animateEnter ? $style.animateLeave : '',
          ]"
        >
          <div
            v-for="member in membersItems"
            :id="`gp_autotest_registration_2_step_selectItem_${member.value}`"
            :key="member.value"
            :class="[$style.select__item]"
            @click="handleItemClick(member.value)"
          >
            {{ member.label }}
          </div>
        </div>
        <portal
          to="onboarding-footer"
        >
          <vgp-button
            id="gp_autotest_registration_2_step_submit_button"
            :class="[
              $style.submit_button,
              animateEnter && !animateLeave ? $style.animateEnter : '',
              animateLeave && !animateEnter ? $style.animateLeave : '',
            ]"
            type="text-green"
            :label="locales('onboarding_skip_step_btn_label')"
            small
            @onClick="nextStep(true)"
          />
        </portal>
      </div>
    </template>
  </onboarding-section>
</template>

<script>
import OnboardingSection from '../OnboardingSection.vue';
import profile from '../../../../models/profile';
import constants from '../../../../helpers/constants';
import rights from '../../../../components/rights/index';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';
import { mapState } from 'vuex';

export default {
  name: 'MembersInfo',
  components: {
    OnboardingSection,
  },
  data() {
    return {
      locales: __,
      selectedMember: null,
      animateLeave: false,
      animateEnter: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
    membersItems() {
      return constants.TEAM_SIZES.map(size => {
        const [value, label] = Object.entries(size).flat();

        return { value, label: __(label)};
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateEnter = true;
    }, 0);
  },
  methods: {
    handleItemClick(value) {
      this.nextStep();
      profile.saveSettings({ user_team: value });
    },
    nextStep(isSkip = false) {
      if (this.animateLeave) {
        return;
      }

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
        if (isSkip) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
        }
      }

      this.animateEnter = false;
      this.animateLeave = true;
      setTimeout(() => {
        this.$store.dispatch('onboarding/nextStep');
      }, 750);
    },
  },
};
</script>

<style lang="less" module>
@import "../../../../less/_variables.less";

.right__wrapper {
  margin-right: 34px;
}

.select__wrapper {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 301px;
  justify-content: space-between;

  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }

}

.select__item {
  width: fit-content;
  transition: all .3s ease;
  font-family: Lato-Bold, sans-serif;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;

  padding: 7px 24px;

  color: #586972;

  background: #E3E8EC;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: #337870;
    box-shadow: 0 6px 11px rgba(0, 86, 76, 0.35);
    color: @white;
  }

  &:active {
    box-shadow: none;
  }
}

.submit_button {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  &.animateLeave {
    transition: opacity .2s, transform .2s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .3s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
