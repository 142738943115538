<template>
  <div
    :class="[$style['main-space']]"
  >
    <filter-header
      class="gp_autotest_comment_hub_header"
      :projects="projects"
      :filter-button="filterButton"
      :filtered-projects="filteredProjects"
      :tabs-items="tabsItems"
      :tabs-amounts="amounts"
      @change="selectProjects"
      @search="searchTasks"
      @toggle="buttonFilter"
    />
    <div
      :class="[$style['main-sides']]"
    >
      <tasks-list
        v-if="preparedTasks.length || projects.length || currentStub.stubSvg"
        :class="[$style['left-side'], 'gp_autotest_comment_hub_tasks_list_left_side']"
        :tasks="preparedTasks"
        :task-id="taskId"
        :new-comments="hasNewComments"
        :parent-paths="parentPathsMap"
        :height="calculatedHeight"
        :current-stub="currentStub"
        :scroll-top="scrollTasksList"
        @selectItem="selectItem"
        @readAllComments="readAllComments"
      />
      <comments-task-setting-list
        v-if="((!preparedTasks.length && taskData) || (preparedTasks.length && !taskData) || (preparedTasks.length && taskData))"
        ref="commentsTaskList"
        :class="[$style['right-side'], 'gp_autotest_comment_hub_comments_list_right_side']"
        :task-id="taskId"
        :height="calculatedHeight"
        @scrollTasksList="handleScrollTasksList"
      />
    </div>
    <div
      v-if="currentStub.stubSvg && !taskData"
      :class="[$style['global-task-stub'], 'gp_autotest_comment_hub_global_stub']"
      :style="{height : calculatedHeight + 'px'}"
    >
      <svgImage
        :name="currentStub.stubSvg"
        :class="[$style['current-stub']]"
      />
      <div
        :class="[$style['no-search-title']]"
      >
        {{
          locale(currentStub.stubTitle)
        }}
      </div>
      <div
        :class="[$style['no-search-text']]"
      >
        {{
          locale(currentStub.stubText)
        }}
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import projectsModel from '../../models/projects';
import filterHeader from './include/filterHeader.vue';
import tasksList from './include/tasksList.vue';
import CommentsTaskSettingList from './include/commentsTaskSettingList.vue';
import svgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';
import app from '../../app';
import rights from '../../components/rights';
import constants from '../../helpers/constants';

export default {
  name: 'CommentHub',
  components: {
    svgImage,
    filterHeader,
    tasksList,
    CommentsTaskSettingList,
  },
  data() {
    return {
      redrawKey: 1,
      isFirstRender: true,
      isPopupShown: false,
      taskId: null,
      searchStr: '',
      filterButton: 'all',
      filteredProjects: [],
      commentsForTask: [],
      locale: __,
      tabsItems: [{
        id: 1,
        title: __('comment_filter_all'),
        type: 'all',
      },
      {
        id: 2,
        title: __('comment_filter_new'),
        type: 'new',
      },
      {
        id: 3,
        title: __('comment_filter_mention'),
        type: 'mention',
      }, {
        id: 4,
        title: __('comment_filter_assign'),
        type: 'assign',
      }, {
        id: 5,
        title: __('comment_filter_creator'),
        type: 'creator',
      }],
      projects: [],
      scrollTasksList: false,
      parentPathsMap: {},
      commentsMapByTaskIds: {},
      filtredGroupedTasks: {},
      preparedTasks: [],
      prepareTasksDebounced: null,
    };
  },
  computed: {
    calculatedHeight() {
      const header = 50;
      const footerHeight = 30;
      const commentHubHeader = 112; // fixed
      const commentHubListTitle = 38; // fixed

      return document.body.clientHeight - header - footerHeight - commentHubHeader - commentHubListTitle;
    },
    tasks() {
      const projectsIds = this.projects.map(project => project.id);

      return this.$store.getters['tasksModel/getTasksByGanttIds'](projectsIds);
    },
    comments() {
      return this.$store.getters['comments/getAllCommentsFull'];
    },
    commentsActions() {
      return this.$store.getters['comments/getCommentsActions'];
    },
    currentResource() {
      return this.$store.getters['resourcesModel/getResourceByUserId'](user.id);
    },
    projectIds() {
      return new Set(this.filteredProjects.map(project => project.id));
    },
    amounts() {
      if (!this.searchStr) return {};

      return Object.entries(this.filtredGroupedTasks).reduce((result, [groupName, groupItems]) => {
        result[groupName] = groupItems.length;

        return result;
      }, {});
    },
    currentStub() {
      let stubTitle;
      let stubText;
      let stubSvg;

      if (!this.tasks.length) {
        stubText = 'comment-hub-no-tasks';
        stubTitle = 'comment-hub-no-tasks-title';
        stubSvg = 'comment-hub-no-tasks';
      }

      if (this.searchStr && !this.preparedTasks.length) {
        stubSvg = 'comment-hub-no-search';
        stubText = 'comment-hub-no-search-global';
      }

      if (this.filterButton && !this.preparedTasks.length && !this.searchStr) {
        stubSvg = 'comment-hub-no-tasks';
        stubText = 'comment-hub-no-any-tasks';
      }

      return { stubTitle, stubSvg, stubText };
    },
    hasNewComments() {
      return !!this.filtredGroupedTasks.new?.length;
    },
    taskData() {
      return this.$store.getters['tasksModel/getTask'](this.taskId);
    },
  },
  watch: {
    taskId(value) {
      if (value) {
        this.scrollTasksList = false;
      }
    },

    comments() {
      this.prepareMapsData();
      this.prepareTasksDebounced();
    },
    commentsActions() {
      this.prepareMapsData();
      this.prepareTasksDebounced();
    },
    tasks() {
      this.prepareTasksDebounced();
    },
    filteredProjects() {
      this.prepareTasks();
    },
    searchStr() {
      this.prepareTasks();
    },
    filterButton() {
      this.prepareTasks();
    },
  },
  created() {
    this.init();
    app.on('onAfterCollaboration', data => {
      if (data.event === 'ProjectDeleted' || data.event === 'ProjectArchived') {
        this.removeProjectAndTasksFromHub(data.projects[0]);
      }

      if (data.event === 'ProjectRestored') {
        this.addProjectAndTasksToHub(+data.projects[0]);
      }

      if (data.event === 'ProjectUpdated' || data.event === 'ResourceOnProjectsUpdated' || data.event === 'RoleAccountUpdated'
          || data.event === 'ResourceToProjectsAssigned' || data.event === 'ResourceFromProjectsUnassigned' || data.event === 'ProjectCreated'
          || data.event === 'ResourcesOnProjectsConverted' ) {
        this.projects = projectsModel.getAllProjects();
        this.filteredProjects = this.projects.filter(obj => this.filteredProjects.find(it => it.id === obj.id));
      }

      if (data.event === 'ResourceFromProjectsUnassigned' && this.taskData) {
        if (data.projects.includes(this.taskData.gantt_id)) this.taskId = null;
      }
      if (data.event === 'ResourceOnProjectsUpdated' && this.taskData) {
        if (!rights.project.hasRight(this.taskData.gantt_id, 'all_tasks')) this.taskId = null;
      }
      if (data.event === 'TaskResourceUnassigned' && this.taskData) {
        if (data.tasks.includes(this.taskData.id) && !rights.project.hasRight(this.taskData.gantt_id, 'all_tasks')) this.taskId = null;
      }
    });
    app.on('project:archive', (ganttId, isArchived, isCollaboration) => {
      if (isCollaboration && isArchived === 0) return;
      if (isArchived === 1) {
        this.removeProjectAndTasksFromHub(ganttId);
      } else if (isArchived === 0) {
        this.addProjectAndTasksToHub(ganttId);
      }
    });
    app.on('project:del', projectData => {
      this.removeProjectAndTasksFromHub(projectData.gantt_id);
    });

    this.prepareTasksDebounced = _.debounce(this.prepareTasks.bind(this), 1000);
  },
  mounted() {
    document.addEventListener('keyup', _.debounce(evt => {
      if (evt.keyCode === 27) {
        this.taskId = null;
      }
    }), 0);
  },
  methods: {
    init() {
      this.projects = projectsModel.getAllProjects();

      const selectedProjects = this.$store.getters['user/getActivitySettings'](constants.USER_ACTIVITIES.COMMENTS_SELECTED_PROJECTS);
      const filteredTab = this.$store.getters['user/getActivitySettings'](constants.USER_ACTIVITIES.COMMENTS_FILTERED_TAB);

      if (filteredTab?.tab) {
        this.filterButton = this.tabsItems.find(item => item.type === filteredTab.tab);
      } else {
        this.filterButton = this.tabsItems.find(item => item.type === 'all');
        this.setUserActivity(constants.USER_ACTIVITIES.COMMENTS_FILTERED_TAB, { tab: this.filterButton.type });
      }

      if (selectedProjects?.projects.length) {
        this.filteredProjects = this.projects.filter(obj => selectedProjects.projects.split(',')
          .includes(`${obj.id}`));
      } else {
        this.setUserActivity(constants.USER_ACTIVITIES.COMMENTS_SELECTED_PROJECTS, { projects: this.filteredProjects });
      }

      this.prepareMapsData();
    },
    prepareTasks() {
      if (this.$store.state.comments.isFetching) return;

      this.filtredGroupedTasks = this.prepareGroupedTasksData();

      const currentTasksByType = this.filtredGroupedTasks[this.filterButton?.type];

      this.prepareParentPathsMap(currentTasksByType);

      this.preparedTasks = currentTasksByType;
    },
    prepareMapsData() {
      this.commentsMapByTaskIds = _.groupBy(this.comments, 'taskId');
    },
    prepareGroupedTasksData() {
      const filteredTasks = this.filterTasks(this.tasks);
      const taskCommentsMetaData = this.$store.state.comments.taskCommentsMetaData;

      const res = filteredTasks.reduce((result, item) => {
        if (!item.parent) return result;

        result.all.push(item);

        if (item.resources.find(i => i.resource_id === this.currentResource.id)) {
          result.assign.push(item);
        }
        if (item.owner_id === this.currentResource.id) {
          result.creator.push(item);
        }

        if (taskCommentsMetaData[item.id]?.unreadComments.size > 0) {
          result.new.push(item);
        }
        if (taskCommentsMetaData[item.id]?.mentions.size) {
          result.mention.push(item);
        }

        return result;
      }, {
        all: [],
        mention: [],
        assign: [],
        new: [],
        creator: [],
      });

      return res;
    },
    filterTasks(tasks) {
      let filteredTasks = tasks;

      if (this.filteredProjects && this.filteredProjects.length) {
        filteredTasks = filteredTasks.filter(task => this.projectIds.has(task.gantt_id));
      }

      if (this.searchStr) {
        filteredTasks = this.filterTasksBySearchString(filteredTasks);
      }

      const resourcesMap = {};

      this.projects.forEach(project => {
        if (project) resourcesMap[project?.id] = _.keyBy(this.$store.getters['resourcesModel/getResourcesByProjectId'](project.id), 'userId');
      });

      const resourceAndComments = [];
      const noResourceAndComments = [];
      const noResourceAndNoComments = [];

      filteredTasks.forEach(task => {
        if (resourcesMap[task.gantt_id][user.id] && this.commentsMapByTaskIds[task.id]?.length) {
          resourceAndComments.push(task);

          return;
        }

        if (!resourcesMap[task.gantt_id][user.id] && this.commentsMapByTaskIds[task.id]?.length) {
          noResourceAndComments.push(task);

          return;
        }

        noResourceAndNoComments.push(task);
      });

      resourceAndComments.sort(this.sortTasks);
      noResourceAndComments.sort(this.sortTasks);

      noResourceAndNoComments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      return resourceAndComments.concat(noResourceAndComments, noResourceAndNoComments);
    },
    showPopup() {
      // need for animation
      setTimeout(() => {
        this.isPopupShown = true;
      }, 0);
    },
    setUserActivity(name, value) {
      this.$store.dispatch('user/updateActivitySettings', {
        activityName: name,
        settings: value,
      });
    },
    getTaskBreadcrumbs(taskData, projectTasksMap = {}) {
      const breadCrumbsTasks = this.$store.getters['tasksModel/getDataForBreadcrumbs'](taskData.id, taskData.gantt_id, projectTasksMap);
      const projectName = projectsModel.getItem(taskData.gantt_id).name;
      const breadcrumbsArray = [projectName];

      breadCrumbsTasks.forEach(task => breadcrumbsArray.push(task.text));

      return breadcrumbsArray;
    },
    selectItem(it) {
      this.$refs.commentsTaskList.$refs.taskSettingsFooter?.saveDraftToStore();
      this.taskId = it.id;
    },
    selectProjects(val) {
      this.filteredProjects = val;
      this.setUserActivity(constants.USER_ACTIVITIES.COMMENTS_SELECTED_PROJECTS, { projects: val.map(obj => obj.id).join(',') });
    },
    searchTasks(e) {
      setTimeout(() => this.searchStr = e, 0);
    },
    buttonFilter(e) {
      setTimeout(() => this.filterButton = e, 0);
      this.setUserActivity(constants.USER_ACTIVITIES.COMMENTS_FILTERED_TAB, { tab: e.type });
    },
    readAllComments() {
      const commentsMap = this.$store.getters['comments/getAllCommentsFull'].reduce((result, comment) => {
        result[comment.id] = comment;

        return result;
      }, {});

      const unreadComments = this.$store.getters['comments/getCommentsActions'].filter(it => it.read === 0 && commentsMap[it.commentId]).map(comm => comm.commentId);

      const tasksMap = [];

      unreadComments.forEach(comment => {
        if (commentsMap[comment] && !tasksMap.includes(commentsMap[comment].taskId)) {
          tasksMap.push(commentsMap[comment].taskId);
        }
      });

      this.$store.dispatch('comments/markAsRead', { taskIds: tasksMap, commentIds: unreadComments });
    },
    handleScrollTasksList() {
      this.scrollTasksList = true;
    },
    sortTasks(a, b) {
      const aV = this.commentsMapByTaskIds[a.id].sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0];
      const bV = this.commentsMapByTaskIds[b.id].sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))[0];

      if (aV && bV) {
        return new Date(bV.createdAt) - new Date(aV.createdAt);
      }

      if (!bV && !aV) return 0;
      if (!aV) return 1;
      if (!bV) return -1;
    },
    removeProjectAndTasksFromHub(ganttId) {
      if (this.filteredProjects) {
        this.filteredProjects = this.filteredProjects.filter(obj => +obj.id !== ganttId);
        this.setUserActivity(constants.USER_ACTIVITIES.COMMENTS_SELECTED_PROJECTS, { projects: this.filteredProjects.map(pr => pr.id).join(',') });
      } else {
        this.filteredProjects = [];
      }

      this.projects = this.projects.filter(project => project.id !== ganttId);

      if (this.taskId && this.$store.getters['tasksModel/getTask'](this.taskId).gantt_id === +ganttId) {
        this.taskId = null;
      }
    },
    prepareParentPathsMap(tasks) {
      const projectTasksMap = this.$store.getters['tasksModel/getProjectsTasksMap'];

      this.parentPathsMap = tasks.reduce((result, task) => {
        result[task.id] = this.getTaskBreadcrumbs(task, projectTasksMap[task.gantt_id]);

        return result;
      }, {});
    },
    addProjectAndTasksToHub(ganttId) {
      const project = projectsModel.getItem(+ganttId);
      let projectCopy = {
        ...project,
      };

      if (!projectCopy) {
        const _project = projectsModel.getArchivedProject(+ganttId);

        projectCopy = {
          ..._project,
        };
      }

      this.projects.push(projectCopy);
    },
    filterTasksBySearchString(tasks) {
      return tasks.filter(item => {
        const value = item.text.toLowerCase();
        const comm = this.commentsMapByTaskIds[item.id];

        const strIncl = comm && comm.find(i => i.comment.toLowerCase().includes(this.searchStr.toLowerCase()));

        if (value.includes(this.searchStr.toLowerCase()) || strIncl) {
          return item;
        }

        return false;
      });
    },
  },
};
</script>

<style module src="./less/commentsHub.less" lang="less"></style>
