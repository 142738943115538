var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [
        _vm.config.isGlobal ? _vm.$style.global_btn : _vm.$style.button,
        _vm.config.color ? _vm.$style[_vm.config.color] : "",
        _vm.config.size ? _vm.$style[_vm.config.size] : "",
        _vm.config.className ? _vm.$style[_vm.config.className] : "",
        _vm.config.isDisable ? _vm.$style.disabled : "",
      ],
      style: {
        width: _vm.config.width ? _vm.config.width : "auto",
      },
      on: { click: _vm.onClick },
    },
    [_vm._v("\n  " + _vm._s(_vm.config.locale) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }