var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.date_input] }, [
    _c("div", { class: [_vm.$style.input_label] }, [
      _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isHasSelected,
              expression: "isHasSelected",
            },
          ],
          class: _vm.$style.reset,
          attrs: { id: _vm.gpAutotestName + "_reset_btn" },
          on: { click: _vm.resetSelected },
        },
        [_vm._v("\n      " + _vm._s(_vm.reset_locale) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "inputField",
        class: [
          _vm.$style.input_field,
          _vm.isHasSelected || _vm.isShowCalendar ? _vm.$style.active : "",
        ],
        attrs: { id: _vm.gpAutotestName },
        on: { click: _vm.inputClick },
      },
      [
        _vm.isHasSelected
          ? _c("div", [
              _c("span", [_vm._v(" " + _vm._s(_vm.stringSelected) + " ")]),
            ])
          : _c("div", [
              _vm._v("\n      " + _vm._s(_vm.placeholder) + "\n    "),
            ]),
        _vm._v(" "),
        _c("webix-ui", {
          class: [_vm.$style.date_input_calendar_icon],
          attrs: { config: _vm.calendar_icon },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }