<template>
  <div :class="$style.calendar_pricing_stub">
    <div :class="$style.head_section">
      <div :class="$style.welcome_title">
        {{ locale('calendar_view_pricing_title') }}
        <div class="pricing-title-container">
          {{ locale(planTitle) }}
        </div>
      </div>
      <div :class="$style.description">
        {{ locale('calendar_view_pricing_description') }}
      </div>
      <div
        v-if="isOwner"
        :class="$style.upgrade_button"
      >
        <vgp-button
          class="change-view-btn"
          type="primary"
          small
          :label="locale('upgrade_rate_btn')"
          @onClick="upgradeClick"
        />
      </div>
    </div>

    <div :class="$style.demo_video">
      <video-component
        :poster-url="'https://cdn.ganttpro.com/statics/media/videos/calendar-view/CalendarViewDemo.jpg'"
        :video-url="'https://cdn.ganttpro.com/statics/media/videos/calendar-view/CalendarViewVideo.MP4'"
      />
    </div>
  </div>
</template>

<script>

import { getPlansObj } from '../../../../helpers/pricingHelper';

export default {
  name: 'CalendarPricingStub',
  props: {
    planTitle: { type: String, required: true, default: 'base' },
    isOwner: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      locale: __,
    };
  },
  methods: {
    upgradeClick() {
      const newPlanObj = getPlansObj('calendar_view');
      const userPlanId = user.accountPlanData.payment_plans_id;
      const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

      userExtAnalytics.log('pricing_deadlock_reached', { type: this.feature });
      this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'upgrade_plan_popup_title',
        currentPlan: userPlan,
        newPlan: newPlanObj,
        // userCount: user.subscription.user_count,
        isAnnual: user.subscription.is_annual,
      });
    },
  },
};
</script>

<style module src='./style.less'></style>
<style src="./pricing.less"></style>
