<template>
  <div>
    <div :class="$.integration_settings_window">
      <div :class="$.settings_header">
        <div :class="$.left_side">
          <div class="icon">
            <webix-ui :config="icon" />
          </div>
          <div
            :class="$.settings_header_logo"
            v-html="locale(`${integrationName}_calendar_integration_logo`)"
          />
        </div>
        <closer
          :on-close="closeWindow"
          :size="30"
        />
      </div>
      <div :class="$.settings_body">
        <div :class="$.container_description">
          <div :class="$.main_title">
            {{ locale(`${integrationName}_integration_settings_page_title`) }}
          </div>
          <div :class="$.description">
            <div :class="$.text">
              {{ locale(`${integrationName}_integration_settings_page_description`) }}
            </div>
            <vgp-button
              :label="locale('integrations_connect_btn')"
              icon-side="left"
              type="primary"
              :width="'101px'"
              small
              @onClick="createNewLink"
            />
          </div>
        </div>
        <div :class="$.settings_table">
          <div :class="$.settings_table_header">
            <div :class="[$.project_title, $.project_title_text]">
              {{ locale('integration_page_table_header_project') }}
            </div>
            <div :class="[$.sync_option_title, $.sync_option_title_text]">
              {{ locale('integration_page_table_header_sync_option') }}
            </div>
            <div :class="[$.link_title_header]">
              <div :class="$.link_title_block">
                <span>
                  {{ locale('integration_page_table_header_link') }}
                </span>

                <div :class="[$.link_title_header_icon]">
                  <tippy
                    :interactive="true"
                    :animate-fill="false"
                    :placement="'top'"
                    trigger="mouseenter"
                  >
                    <template #trigger>
                      <div
                        :class="$.link_title_icon"
                      >
                        <svg-sprite
                          :color="'#B2B2B2'"
                          :name="'info'"
                        />
                      </div>
                    </template>

                    <span>
                      {{ locale(`${integrationName}_instructions_info`) }}
                      <a
                        :href="locale(`${integrationName}_integration_learn_more`)"
                        target="_blank"
                      >{{ locale(`${integrationName}_integration_learn_more_info`) }}</a>

                    </span>
                  </tippy>
                </div>
              </div>

              <!--              <tooltip-->
              <!--                :content="locale(htmlContent)"-->
              <!--                :interactive="true"-->
              <!--              >-->
              <!--                <template #body />-->
              <!--              </tooltip>-->
            </div>
          </div>
          <div
            v-if="links.length"
            :class="[$.connections, 'gantt_scroll']"
            :style="{height: `${calculatedHeight}px`}"
          >
            <div
              v-for="(
                link, index
              ) in links"
              :key="index"
              :class="$.connection"
            >
              <tooltip
                :content="projectName(link)"
                :hide="setProjectFitInDiv(projectName(link))"
                :class="$.project_title"
              >
                <template #body>
                  <div :class="$.project_title_text">
                    {{ link.connectionType === 'all_tasks' ? locale(`my_tasks_label`) :link.projects[0].name }}
                  </div>
                </template>
              </tooltip>

              <tooltip
                :content="locale(`option_with_milestone_tooltip`)"
                :hide="link.connectionType !== 'assigned_to_me_and_milestones'"
                :class="$.sync_option_title"
              >
                <template #body>
                  <div
                    v-if="link.connectionType !== 'all_tasks'"
                    :class="$.sync_option_title_text"
                  >
                    <div
                      v-if="syncOptions[link.connectionType].icon"
                      :class="$.sync_option_title_icon"
                    >
                      <svg-sprite
                        :color="'#B2B2B2'"
                        :name="syncOptions[link.connectionType].icon.name"
                        :size="syncOptions[link.connectionType].icon.size"
                        :type="syncOptions[link.connectionType].icon.type"
                      />
                    </div>

                    {{ syncOptions[link.connectionType].name }}
                  </div>
                </template>
              </tooltip>

              <div :class="$.link_title_input">
                <vgp-input
                  :class="$.link_title_input_text"
                  :value="`${generatedLink}${link.hash}`"
                  class="input-area"
                  :select-by-focus="true"
                  :readonly="true"
                  :border="false"
                  @onClick="copyGeneratedLink(link)"
                />

                <div
                  :class="[$.integration_icon_item, $.integration_icon_item_link]"
                  @click="copyGeneratedLink(link)"
                >
                  <svg-sprite
                    :name="'link-1'"
                    :color="'#1565C0'"
                  />
                </div>
                <div :class="$.setting_title">
                  <div
                    :class="[$.integration_icon_item, $.setting_title_icon]"
                    @click="openSettings(link)"
                  >
                    <svg-sprite
                      :name="'settings'"
                      :color="'#999999'"
                    />
                  </div>
                  <div
                    :class="[$.integration_icon_item, $.setting_title_icon]"

                    @click="deleteLink(link)"
                  >
                    <svg-sprite
                      :name="'delete'"
                      :color="'#999999'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <outlook-integration-popup
      :show="outlookShowPopup"
      :settings-sync="settingsSync"
      :integration-name="integrationName"
      @linkCreated="openLinkPopup"
      @closePopup="outlookShowPopup = false"
    />
    <google-integration-popup
      :show="googleShowPopup"
      :settings-sync="settingsSync"
      :integration-name="integrationName"
      @linkCreated="openLinkPopup"
      @closePopup="googleShowPopup = false"
    />

    <integration-generated-link
      :show="isLinkGenerated"
      :link="linkForInstructions"
      :integration-name="integrationName"
      @closePopup="closeGeneratedLinkPopup"
    />
    <core-confirm-popup
      :show="!!deleteInfoData"
      :popup-width="440"
      :title="locale('integration_delete_popup_title')"
      :text="locale(`${integrationName}_integration_delete_popup_text`)"
      :ok-button="{
        title: locale('common_remove'),
        type: 'destructive',
      }"
      :cancel-button="{
        title: locale('common_cancel'),
        type: 'secondary',
      }"
      @onClickOk="onOkDeletePopup"
      @onClickCancel="deleteInfoData = null"
    />
  </div>
</template>

<script>

import GoogleIntegrationPopup from '../googleIntegration/googleIntegrationPopup.vue';
import closer from '../../common/VueComponents/closer/closer.vue';
import svgIconForVue from '../../common/svgIconForVue';
import routerHelper from '../../../helpers/router';
import coreConfirmPopup from '../../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import tooltip from '../../common/VueComponents/tooltips/tooltip.vue';
import { memoizedIsTextFitInDiv } from '../../taskSettings/helpers';
import IntegrationGeneratedLink from './integrationGeneratedLink.vue';
import OutlookIntegrationPopup from '../outlookIntegration/outlookIntegrationPopup.vue';
import app from '../../../app';
import globalStore from '$$store/main';
import projectsModel from '../../../models/projects';

export default {
  name: 'IntegrationPage',
  components: {
    OutlookIntegrationPopup,
    IntegrationGeneratedLink,
    tooltip,
    closer,
    GoogleIntegrationPopup,
    coreConfirmPopup,
  },
  props: {
    integrationName: {
      type: String,
      required: false,
      default: '',
    },
    svg: {
      type: String,
      required: false,
      default: '',
    },
    links: {
      type: Array,
      required: true,
    },
    isFirstLink: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      locale: __,
      icon: svgIconForVue(this.svg, 24, 24),
      generatedLink: null,
      deleteInfoData: null,
      isLinkGenerated: false,
      settingsSync: null,
      assignedAndMilestones: 'assigned_to_me_and_milestones',
      linkForInstructions: null,
      syncOptions: {
        all_tasks_project: {
          id: 'all_tasks_project',
          icon: {
            name: 'list-view',
            size: 24,
            type: 'regular',
          },
          advanced: {
            preset: 'text',
            text: __('all_tasks_advanced_sync'),
          },
          name: __('all_tasks_sync'),
        },
        assigned_to_me: {
          id: 'assigned_to_me',
          icon: {
            name: 'task',
            size: 24,
            type: 'regular',
          },
          name: __('assigned_to_me_sync'),
        },
        only_milestones: {
          id: 'only_milestones',
          icon: {
            name: 'milestone',
            size: 24,
            type: 'regular',
          },
          name: __('milestones_sync'),
        },
        assigned_to_me_and_milestones: {
          id: 'assigned_to_me_and_milestones',
          icon: {
            name: 'task',
            size: 24,
            type: 'regular',
          },
          name: __('assigned_to_me_and_milestones'),
        },
      },
      htmlContent: '',
      googleShowPopup: false,
      outlookShowPopup: false,
      existedLinks: [],
      appOnIds: [],
    };
  },
  computed: {
    calculatedHeight() {
      const header = 60;
      const footerHeight = 30;
      const tableHeader = 48; // fixed
      const description = 100; // fixed

      return document.body.clientHeight - header - footerHeight - tableHeader - description;
    },

  },
  watch: {
    isFirstLink: {
      handler(value) {
        if (value) {
          this.isLinkGenerated = true;
          this.linkForInstructions = this.links[0];
        }
      },
      immediate: true,

    },
    links() {
      this.existedLinks = this.links;
      if (!this.links.length) {
        routerHelper.changeRoute('/settings/integrations');
      }
    },
  },
  mounted() {
  },
  created() {
    const root = `${window.location.protocol}//${window.location.host}`;

    this.generatedLink = `${root}/api/integrations/calendar/${this.integrationName}/`;
    this.initEvents();
  },

  beforeDestroy() {
    this.appOnIds.forEach(id => {
      app.off(id);
    });
  },

  methods: {
    initEvents() {
      this.appOnIds.push(app.on('onAfterCollaboration', data => {
        if (data.event === 'ProjectDeleted' || data.event === 'ProjectArchived' || data.event === 'ResourceFromProjectsUnassigned') {
          const id = data.projects[0];

          Promise.all([
            this.$store.dispatch('integrationGoogle/removeAfterCollabaration', { id }),
            this.$store.dispatch('integrationOutlook/removeAfterCollabaration', { id }),
          ]);
        }
      }));
    },

    closeWindow() {
      routerHelper.backRoute();
    },
    createNewLink() {
      this.settingsSync = null;
      this.integrationName === 'google' ? this.googleShowPopup = true : this.outlookShowPopup = true;
    },
    onOkDeletePopup() {
      this.$emit('handleDelete', this.deleteInfoData.id);
      this.deleteInfoData = null;
    },
    openLinkPopup(link) {
      this.isLinkGenerated = true;
      this.linkForInstructions = link;
    },

    copyGeneratedLink(item) {
      try {
        const linkUrl = `${this.generatedLink}${item.hash}`;

        navigator.clipboard.writeText(linkUrl);
        this.$toast.success(this.locale(`${this.integrationName}_integration_link_copied`));
        userExtAnalytics.log(`integration_${this.integrationName}_calendar_link_copied`, { type: 'table' });
      } catch (e) {
        console.error('Failed to copy task: ', e);
      }
    },
    deleteLink(link) {
      this.deleteInfoData = link;
    },
    openSettings(link) {
      this.integrationName === 'google' ? this.googleShowPopup = true : this.outlookShowPopup = true;
      this.settingsSync = link;
    },
    setProjectFitInDiv(projectName) {
      return memoizedIsTextFitInDiv({
        textContent: projectName,
        classes: [this.$.project_title],
        cssTextStyle: 'font-family: Lato-Bold, sans-serif; font-size: 14px; line-height: 24px;',
        divWidth: 230,
      });
    },
    projectName(link) {
      return link.projects[0]?.name || '';
    },
    closeGeneratedLinkPopup() {
      this.isLinkGenerated = false;
      if (this.isFirstLink) {
        this.$emit('firstLinkWasOpen');
      }
    },
  },
};
</script>

<style module="$" src="../googleOutlookIntegrations/googleOutlookIntegration.less"></style>
