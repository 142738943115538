import app from '../../../app';
import templates from '../../../templates/labels';
import helper from '../helpers';
import ganttViewModel from '../../../models/ganttViewModel';
import { checkPricingAccess } from '../../../helpers/pricingHelper';

const __ = window.__;

const tooltip = webix.ui({
  view: 'tooltip',
  template: "<div style='max-width: 280px'>#content#</div>",
});

const innerObject = {
  views: {
    main: {
      id: 'durationLayout',
    },
    durationLayout: {
      id: 'durationLayout',
    },
    durationViewSelect: {
      height: 72,
      view: 'richselectWithoutPoint',
      id: 'durationViewDisplaySelect',
      suggestCss: 'project-settings-dropdown',
      label: __('settings_duration'),
      labelPosition: 'top',
      toolTip: {
        y: 4,
        x: 0,
        langKey: 'settings_tooltips_durationStep',
      },
      options: [
        { id: 'hour', value: __('new_durationOptionHours') },
        { id: 'day', value: __('new_durationOptionDays') },
        { id: 'week', value: __('new_durationOptionWeeks') },
        { id: 'month', value: __('new_durationOptionMonth') },
      ],
      on: {
        onChange(newValue, oldValue) {
          innerObject.handlers.changeDurationView(newValue, oldValue);
        },
        onAfterRender: helper.toolTipInitForTopLabels,
      },
    },
    resourceLoadingTypeLayout: {
      id: 'resourceLoadingTypeLayout',
    },
    resourceLoadingTypeSelect: {
      height: 72,
      view: 'richselectWithoutPoint',
      id: 'resourceLoadingTypeSelect',
      suggestCss: 'project-settings-dropdown',
      label: __('settings_resource_loading_type'),
      labelPosition: 'top',
      toolTip: {
        y: 4,
        x: 0,
        langKey: 'settings_tooltips_resource_loading_type',
      },
      options: [
        { id: 'hours', value: __('resource_loading_type_hours') },
        { id: 'percents', value: __('resource_loading_type_percents') },
      ],
      on: {
        onChange(newValue, oldValue) {
          innerObject.handlers.changeResourceLoadingType(newValue, oldValue);
        },
        onAfterRender: helper.toolTipInitForTopLabels,
      },
    },
  },
  init: {
    run() {
      const projectConfig = ganttViewModel.getProjectConfig();

      this.durationView(projectConfig);
      this.resourceLoadingTypeInit(projectConfig);
    },
    resourceLoadingTypeInit(projectConfig) {
      if (!checkPricingAccess('workload')) return;
      $$(innerObject.views.resourceLoadingTypeSelect.id).config.value = projectConfig.resource_loading_type ? projectConfig.resource_loading_type.toString() : 'hours';
      $$(innerObject.views.resourceLoadingTypeSelect.id).refresh();
    },
    durationView(projectConfig) {
      $$(innerObject.views.durationViewSelect.id).config.value = projectConfig.duration_view ? projectConfig.duration_view.toString() : projectConfig.duration_unit.toString();
      $$(innerObject.views.durationViewSelect.id).refresh();
    },
    showPopup() {
      this.durationView(ganttViewModel.getProjectConfig());
    },
  },
  handlers: {
    getCurrentDurationView() {
      return $$(innerObject.views.durationViewSelect.id).getValue();
    },
    getCurrentResourceLoadingType() {
      if (!checkPricingAccess('workload')) return;
      return $$(innerObject.views.resourceLoadingTypeSelect.id).getValue();
    },
    addCurrentSettingsParamToUpdate() {
      const durationView = this.getCurrentDurationView();
      const resourceLoadingType = this.getCurrentResourceLoadingType();
      const ganttConfig = ganttViewModel.getProjectConfig();

      this.checkToUpdate('resource_loading_type', resourceLoadingType, ganttConfig);
      this.checkToUpdate('duration_view', durationView, ganttConfig);
    },
    changeDurationView(newValue) {
      app.trigger('settings:durationView:change', newValue);
    },
    changeResourceLoadingType(newValue) {
      app.trigger('settings:resourceLoadingType:change', newValue);
    },
    changeStartOnMonday(newValue) {
      app.trigger('settings:startOnMonday:change', newValue);
    },
    checkToUpdate(checkParam, newValueParam, objectData) {
      if (objectData[checkParam] !== newValueParam) {
        ganttViewModel.addParamsToUpdate({ key: checkParam, value: newValueParam });
      }
    },
    initLinkMode() {
      $$(innerObject.views.durationLayout.id).hide();
      $$(innerObject.views.resourceLoadingTypeLayout.id).hide();
    },
    initBaseMode() {
      $$(innerObject.views.durationLayout.id).show();
      $$(innerObject.views.resourceLoadingTypeLayout.id).show();
    },
  },
  helpers: {
    createToggleLayout(layoutID, labelUI, toggleUI) {
      return {
        id: layoutID,
        height: 26,
        cols: [
          labelUI,
          toggleUI,
        ],
      };
    },
  },
  settings: {
    projectId: null,
    needUpdateConfig: false,
  },
};

const outputObject = {
  webixUIDurationView: {
    id: innerObject.views.durationLayout.id,
    width: 227,
    cols: [
      innerObject.views.durationViewSelect,
    ],
  },
  webixUIResourceLoadingType: checkPricingAccess('workload') ? {
    id: innerObject.views.resourceLoadingTypeLayout.id,
    width: 227,
    paddingY: 17,
    cols: [
      innerObject.views.resourceLoadingTypeSelect,
    ],
  } : { id: innerObject.views.resourceLoadingTypeLayout.id, height: 17 },
  handlers: {
    init(projectId) {
      innerObject.settings.projectId = projectId;
      innerObject.init.run();
    },
    saveSettings() {
      innerObject.handlers.addCurrentSettingsParamToUpdate();
    },
    showPopup() {
      innerObject.init.showPopup();
    },
    initLinkMode: innerObject.handlers.initLinkMode,
    initBaseMode: innerObject.handlers.initBaseMode,
  },
};

export default outputObject;
