/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import projectComponent from '../../Project';

import app from '../../../app';
import customDaysTemplatesModel from '../../../models/customDayTemplates';
import globalStore from '$$store/main';
import rights from '../../rights/index';

export async function assignResourceToProjects(resourcesToProjects, innerSettings) {
  const result = {
    projectIds: [],
  };

  const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

  for (const resourceToProject of resourcesToProjects) {
    if (resourceId === resourceToProject.resourceId) {
      const projectData = {
        gantt_id: resourceToProject.projectId,
        ownerUser: innerSettings.fromUser,
      };

      customDaysTemplatesModel.manualAddTemplateToShareProject([], resourceToProject.projectId);
      await projectComponent.addNewSharedProjectCollaboration(projectData);

      rights.project.clearCacheGetUserRole();
    }

    globalStore.commit('resourcesModel/addResourceToProject', resourceToProject);

    result.projectIds.push(+resourceToProject.projectId);
  }

  return result;
}

async function onResourceToProjectsAssigned(event) {
  const { data } = event;
  const { projectIds } = await assignResourceToProjects(data.resourcesToProjects, event.innerSettings);

  app.trigger('resources:model:addResourcesToProjects');
  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: [...new Set(projectIds)],
  });
}

export default onResourceToProjectsAssigned;
