<template>
  <div>
    <core-popup
      v-if="show"
      :title="!!settingsSync ? locale(`${integrationName}_integration_settings_up_header`) : locale(`${integrationName}_integration_popup_header`)"
      :show="show"
      :width="382"
    >
      <template #body>
        <div
          class="content"
        >
          <div
            v-if="syncAlreadyCreated"
            :class="$.integration_warning_message"
          >
            <div :class="$.integration_warning_message_icon">
              <svg-sprite
                :name="'warning-1'"
                :size="20"
                :type="'bold'"
                :color="'#FF9A00'"
              />
            </div>

            <div :class="$.integration_warning_message_text">
              {{ locale('integration_warning_message') }}
            </div>
          </div>
          <div
            v-for="option in options"
            :class="$.options"
          >
            <div :class="$.radioOption">
              <vgp-radio-button
                :checked="option.selected"
                :label="locale(`integration_option_${option.id}`)"
                @onChange="selectOption($event, option)"
              />
              <div
                v-if="option.info"
                :class="$.labelWrapper"
              >
                <tooltip
                  :content="locale(`integration_option_${option.info}_desc`)"
                  position="right"
                >
                  <template #body>
                    <svg-sprite
                      :color="'#B2B2B2'"
                      :name="'info'"
                      :size="20"
                    />
                  </template>
                </tooltip>
              </div>
            </div>
          </div>

          <div :class="$.sync_dropdowns_container">
            <vgp-label-slot
              :class="$.sync_dropdown"
              :label="locale('integration_project_to_sync_label')"
            >
              <template #body>
                <dropdown-select
                  :drop-list="projects"
                  :placeholder="locale('integration_project_dropdown_placeholder')"
                  :no-matches-message="locale('integration_project_dropdown_no_matches')"
                  :selected="selectedProject"
                  :multiselect="false"
                  :use-search="true"
                  :track-by="'id'"
                  item-template="text"
                  track-by-text="name"
                  :show-selected-first="true"
                  :show-reset-btn="false"
                  :disabled="disableProjectDrop"
                  @change="onChangeProjectSelected"
                />
              </template>
            </vgp-label-slot>
            <vgp-label-slot
              :class="$.sync_dropdown"
              :label="locale('integration_sync_options_label')"
            >
              <template #body>
                <dropdown-select
                  :drop-list="syncOptions"
                  :placeholder="locale('integration_sync_options_dropdown_placeholder')"
                  :selected="selectedSyncOption"
                  :track-by-img-url="'icon'"
                  :multiselect="false"
                  :use-search="false"
                  :track-by="'id'"
                  track-by-text="name"
                  :show-selected-first="false"
                  :show-reset-btn="false"
                  :item-template="'withIcon'"
                  :disabled="disableSyncDrop"
                  :track-by-additional-for-item="'advanced'"
                  @change="onChangeSyncSelected"
                />
              </template>
            </vgp-label-slot>
            <div
              v-if="selectedSyncOption && (selectedSyncOption.id === 'assigned_to_me' || selectedSyncOption.id === assignedAndMilestones)"
              :class="$.switch_item"
            >
              <div :class="$.integration_switch_label">
                {{ locale('integration_switch_to_milestones') }}
              </div>
              <square-toggle
                :is-disabled="disableToggle"
                :is-toggle-on="toggleOn"
                @onChange="activateElement"
              />
            </div>
            <div :class="$.action_buttons">
              <vgp-button
                :type="'secondary'"
                :label="locale('common_cancel')"
                :width="'90px'"
                small
                @onClick="handleCancel"
              />
              <vgp-button
                :type="'primary'"
                :class="$.action_btn"
                :label="settingsSync ? locale('common_save') : locale('integration_create')"
                :disabled="disableButton"
                :width="'90px'"
                small
                @onClick="handleCreateOrUpdate"
              />
            </div>
          </div>
        </div>
      </template>
    </core-popup>
  </div>
</template>

<script>

import _ from 'lodash';
import VgpRadioButton from '../../common/VueComponents/radioButton/vgpRadioButton.vue';
import projectComponent from '../../../components/Project';
import dropdownSelect from '../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import projectsModel from '../../../models/projects';
import vgpLabelSlot from '../../common/VueComponents/label/vgpLabelSlot.vue';
import SquareToggle from '../../common/VueComponents/checkbox/squareToggle.vue';
import CorePopup from '../../common/VueComponents/popups/corePopup/corePopup.vue';
import app from '../../../app';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';

export default {
  name: 'IntegrationPopup',
  components: {
    Tooltip,
    CorePopup,
    SquareToggle,
    dropdownSelect,
    VgpRadioButton,
    vgpLabelSlot,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    integrationName: {
      type: String,
      required: false,
      default: '',
    },
    existedLinks: {
      type: Array,
      required: true,
      default: [],
    },
    settingsSync: {
      type: Object,
      required: false,
      default: {},
    },

  },
  data() {
    return {
      locale: __,
      projects: [],
      options: [{ id: 'all_tasks', info: 'all_tasks', selected: false }, { id: 'project', selected: false }],
      syncOptions: [{
        id: 'all_tasks_project',
        icon: {
          name: 'list-view',
          size: 24,
          type: 'regular',
          color: '#b2b2b2',
        },
        advanced: {
          preset: 'text',
          text: __('all_tasks_advanced_sync'),
          txtColor: '#b2b2b2',
        },
        name: __('all_tasks_sync'),
      }, {
        id: 'assigned_to_me',
        icon: {
          name: 'task',
          size: 24,
          type: 'regular',
          color: '#b2b2b2',
        },
        name: __('assigned_to_me_sync'),
      }, {
        id: 'only_milestones',
        icon: {
          name: 'milestone',
          size: 24,
          type: 'regular',
          color: '#b2b2b2',
        },
        name: __('milestones_sync'),
      }],
      assignedAndMilestones: 'assigned_to_me_and_milestones',
      selectedProject: null,
      selectedSyncOption: null,
      toggleOn: false,
      disableProjectDrop: true,
      disableSyncDrop: true,
      disableButton: true,
      connectionType: null,
      syncAlreadyCreated: false,
      disableToggle: false,
      selectedOption: null,
      appOnIds: [],
    };
  },
  watch: {
    // show(mode) {
    //   if (mode && this.settingsSync) {
    //     if (this.settingsSync.connectionType !== 'all_tasks') {
    //       this.selectedProject = this.projects.find(project => project.id === this.settingsSync.projects[0].ganttId);
    //     }
    //     this.disableButton = true;
    //     this.options.forEach(option => {
    //       if (this.settingsSync.connectionType === 'all_tasks' && option.id === 'all_tasks') {
    //         option.selected = true;
    //         this.connectionType = this.settingsSync.connectionType;
    //         this.syncAlreadyCreated = true;
    //       } else if (this.settingsSync.connectionType !== 'all_tasks' && option.id === 'project') {
    //         option.selected = true;
    //         this.disableSyncDrop = false;
    //         this.disableProjectDrop = false;
    //         this.connectionType = this.settingsSync.connectionType;
    //         if (this.settingsSync.connectionType === this.assignedAndMilestones) {
    //           this.selectedSyncOption = this.syncOptions.find(i => i.id === 'assigned_to_me');
    //           this.toggleOn = true;
    //         } else {
    //           this.selectedSyncOption = this.syncOptions.find(i => i.id === this.settingsSync.connectionType);
    //         }
    //       }
    //     });
    //   }
    // },

  },

  beforeDestroy() {
    this.appOnIds.forEach(id => {
      app.off(id);
    });
  },

  created() {
    this.init();
    this.appOnIds.push(app.on('onAfterCollaboration', data => {
      if (data.event === 'ProjectDeleted' || data.event === 'ProjectArchived') {
        this.removeProjectFromList(data.projects[0]);
      }

      if (data.event === 'ProjectRestored') {
        this.addProjectToList(+data.projects[0]);
      }

      if (data.event === 'ProjectUpdated' || data.event === 'ResourceOnProjectsUpdated' || data.event === 'RoleAccountUpdated'
          || data.event === 'ResourceToProjectsAssigned' || data.event === 'ResourceFromProjectsUnassigned' || data.event === 'ProjectCreated'
          || data.event === 'ResourcesOnProjectsConverted') {
        this.projects = projectsModel.getAllProjects();

        if (this.selectedProject?.id === data.projects[0]) {
          this.selectedProject = this.projects.find(i => i.id === data.projects[0]);
        }

        if (data.event === 'ResourceFromProjectsUnassigned' && this.selectedProject?.id === data.projects[0]) {
          this.selectedProject = null;
          this.disableButton = true;
        }
      }
    }));
    this.appOnIds.push(app.on('project:archive', (ganttId, isArchived, isCollaboration) => {
      if (isCollaboration && isArchived === 0) return;
      if (isArchived === 1) {
        this.removeProjectFromList(ganttId);
        if (this.selectedProject?.id === ganttId) {
          this.selectedProject = null;
          this.disableButton = true;
        }
      } else if (isArchived === 0) {
        this.addProjectToList(ganttId);
      }
    }));
    this.appOnIds.push(app.on('project:del', projectData => {
      this.removeProjectFromList(projectData.gantt_id);
      if (this.selectedProject?.id === projectData.gantt_id) {
        this.selectedProject = null;
        this.disableButton = true;
      }
    }));
  },
  mounted() {
    if (this.settingsSync) {
      if (this.settingsSync.connectionType !== 'all_tasks') {
        this.selectedProject = this.projects.find(project => project.id === this.settingsSync.projects[0].ganttId);
      }
      this.disableButton = true;
      this.options.forEach(option => {
        if (this.settingsSync.connectionType === 'all_tasks' && option.id === 'all_tasks') {
          option.selected = true;
          this.connectionType = this.settingsSync.connectionType;
          this.syncAlreadyCreated = true;
        } else if (this.settingsSync.connectionType !== 'all_tasks' && option.id === 'project') {
          option.selected = true;
          this.disableSyncDrop = false;
          this.disableProjectDrop = false;
          this.connectionType = this.settingsSync.connectionType;
          if (this.settingsSync.connectionType === this.assignedAndMilestones) {
            this.selectedSyncOption = this.syncOptions.find(i => i.id === 'assigned_to_me');
            this.toggleOn = true;
          } else {
            this.selectedSyncOption = this.syncOptions.find(i => i.id === this.settingsSync.connectionType);
          }
        }
      });
    }
  },
  methods: {
    init() {
      const projectsData = projectComponent.getProjectsList();

      this.projects = _.cloneDeep(projectsData.filter(item => item.type !== 'multiview'));
    },
    selectOption(event, item) {
      this.options.forEach(option => {
        option.selected = option.id === item.id;
        this.selectedOption = item.id;
      });

      if (item.id === 'all_tasks') {
        this.connectionType = item.id;
        this.disableProjectDrop = true;
        this.disableSyncDrop = true;
        this.disableToggle = true;
        const existedLink = this.existedLinks.find(link => link.connectionType === 'all_tasks' && !link?.projects[0]?.ganttId);

        if (existedLink) {
          this.disableButton = true;
          this.syncAlreadyCreated = true;
        } else {
          this.disableButton = false;
          this.syncAlreadyCreated = false;
        }
      } else if (this.selectedProject && this.selectedSyncOption) {
        const existedLink = this.existedLinks.find(link => this.selectedProject.id === link.projects[0]?.ganttId && this.selectedSyncOption?.id === link.connectionType);

        if (existedLink) {
          this.disableButton = true;
          this.syncAlreadyCreated = true;
          this.disableProjectDrop = false;
          this.disableSyncDrop = false;
          this.disableToggle = false;
        } else {
          if (this.selectedSyncOption) this.connectionType = this.selectedSyncOption.id;
          this.disableProjectDrop = false;
          this.disableSyncDrop = false;
          this.disableButton = false;
          this.syncAlreadyCreated = false;
          if (this.selectedSyncOption.id === 'assigned_to_me' || this.selectedSyncOption.id === 'assigned_to_me_and_milestones') {
            this.disableToggle = false;
          }
        }
      } else {
        this.syncAlreadyCreated = false;
        this.disableSyncDrop = false;
        this.disableProjectDrop = false;
        this.disableButton = true;
        this.disableToggle = false;
      }
    },
    handleCreateOrUpdate() {
      const project = this.selectedOption === 'all_tasks' ? 0 : { ganttId: this.selectedProject?.id, name: this.selectedProject.name };

      const data = {
        projects: [project],
        connectionType: this.connectionType,
      };

      this.$emit('createOrUpdateLink', data);
      this.clearData();
      this.handleCancel();
    },
    removeProjectFromList(ganttId) {
      this.projects = this.projects.filter(project => project.id !== ganttId);
    },
    addProjectToList(ganttId) {
      const project = projectsModel.getItem(+ganttId);
      let projectCopy = {
        ...project,
      };

      if (!projectCopy) {
        const _project = projectsModel.getArchivedProject(+ganttId);

        projectCopy = {
          ..._project,
        };
      }

      this.projects.push(projectCopy);
    },
    onChangeProjectSelected(selectedData) {
      this.selectedProject = selectedData[0];

      if (this.settingsSync || this.selectedSyncOption) {
        const existedLink = this.existedLinks.find(link => selectedData[0].id === link.projects[0]?.ganttId && this.selectedSyncOption?.id === link.connectionType);

        if (existedLink) {
          this.disableButton = true;
          this.syncAlreadyCreated = true;
        } else {
          this.disableButton = false;
          this.syncAlreadyCreated = false;
        }
      } else if (this.selectedSyncOption) {
        this.disableButton = false;
      }
    },
    onChangeSyncSelected(selectedData) {
      this.selectedSyncOption = selectedData[0];
      const existedLink = this.existedLinks.find(link => this?.selectedProject?.id === link.projects[0]?.ganttId && selectedData[0].id === link.connectionType);

      if (existedLink) {
        this.disableButton = true;
        this.syncAlreadyCreated = true;
      } else if (existedLink === undefined && !this.selectedProject) {
        this.disableButton = true;
        this.syncAlreadyCreated = false;
      } else {
        this.disableButton = false;
        this.syncAlreadyCreated = false;
      }
      if (this.selectedSyncOption.id === 'assigned_to_me' || this.selectedSyncOption.id === 'assigned_to_me_and_milestones') {
        this.disableToggle = false;
      }
      this.connectionType = selectedData[0].id;
    },
    activateElement() {
      if (!this.toggleOn) {
        // this.connectionType = this.assignedAndMilestones;
        this.onChangeSyncSelected([{ ...this.selectedSyncOption, id: this.assignedAndMilestones }]);
      } else {
        this.onChangeSyncSelected([{ ...this.selectedSyncOption, id: 'assigned_to_me' }]);
      }
      this.toggleOn = !this.toggleOn;

      //       } else if (this.settingsSync.connectionType !== 'all_tasks' && option.id === 'project') {
      //         option.selected = true;
      //         this.disableSyncDrop = false;
      //         this.disableProjectDrop = false;
      //         this.connectionType = this.settingsSync.connectionType;
      //         if (this.settingsSync.connectionType === this.assignedAndMilestones) {
      //           this.selectedSyncOption = this.syncOptions.find(i => i.id === 'assigned_to_me');
      //           this.toggleOn = true;
      //         } else {
      //           this.selectedSyncOption = this.syncOptions.find(i => i.id === this.settingsSync.connectionType);
      //         }
    },
    handleCancel() {
      this.clearData();
      this.$emit('closePopup');
    },
    clearData() {
      this.selectedSyncOption = null;
      this.selectedProject = null;
      this.connectionType = null;
      this.syncAlreadyCreated = false;
      this.options.forEach(option => option.selected = false);
      this.toggleOn = false;
      this.disableButton = true;
    },
  },
};
</script>

<style module="$" src="./googleOutlookIntegration.less" lang="less"></style>
