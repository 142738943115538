import store from '../../../store/main';

function onProgressEventCreated(event) {
  const userIds = event.data.userIds;
  const userId = event.data.userId;

  console.log('====== created', event);
  if (userIds.find(id => id === user.id) || userId === user.id) {
    const eventsFromMap = store.getters['progress/eventsMap'];

    if (!eventsFromMap[event.data.token]) {
      store.commit('progress/setEventToMap', { token: event.data.token, event: event.event, progress: event.data.progress });
      store.commit('progress/start', event.data);
    }
  }
}

export default onProgressEventCreated;
