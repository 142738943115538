const NEW_FEATURES = {
  dashboard_view: {
    id: new Date(),
    name: 'dashboard_view',
    locale: 'dashboard_view_feature',
    category_locale: 'project_views_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  workload_view: {
    id: new Date(),
    name: 'workload_view',
    locale: 'workload_view_feature',
    category_locale: 'project_views_features_category',
    plans: {
      base: false,
      pro: false,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  one_drive_integration: {
    id: new Date(),
    name: 'one_drive_integration',
    locale: 'one_drive_integration_feature',
    category_locale: 'api_integrations_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  google_drive_integration: {
    id: new Date(),
    name: 'google_drive_integration',
    locale: 'google_drive_integration_feature',
    category_locale: 'api_integrations_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  two_factor_auth: {
    id: new Date(),
    name: 'two_factor_auth',
    locale: 'two_factor_auth_feature',
    category_locale: 'security_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  onboarding_assistance: {
    id: new Date(),
    name: 'onboarding_assistance',
    locale: 'onboarding_assistance_feature',
    category_locale: 'support_features_category',
    plans: {
      base: false,
      pro: false,
      business: false,
      enterprise_team: true,
      enterprise: true,
    },
  },
  import: {
    id: new Date(),
    name: 'import',
    locale: 'import_feature',
    category_locale: 'etc_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
  mobile_app: {
    id: new Date(),
    name: 'mobile_app',
    locale: 'mobile_app_feature',
    category_locale: 'etc_features_category',
    plans: {
      base: true,
      pro: true,
      business: true,
      enterprise_team: true,
      enterprise: true,
    },
  },
};

export default {
  prepareGroupFeatures(categoryName, features) {
    let sorted = [];

    switch (categoryName) {
    case 'project_views_features_category':
      const projectViews = [
        'gantt_view',
        'board_view',
        'list_view',
        'calendar_view',
        'dashboard_view',
        'portfolio_view',
        'workload_view',
      ];

      projectViews.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'project_planing_features_category':
      const projectPlanning = [
        'auto_scheduling',
        'baseline',
        'critical_path',
        'dependencies',
        'deadlines',
        'milestones',
        'overdue_tasks',
        'cascade_sorting',
        'saving_filters',
        'bulk_change',
        'saving_project_templates',
        'reports',
      ];

      projectPlanning.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'team_collaboration_features_category':
      sorted = features.filter(f => f.name !== 'permissions');

      break;

    case 'resource_management_features_category':
      const resourceManagement = [
        'project_calendar',
        'project_calendar_exceptions',
        'personal_calendar',
        'resource_types',
        'workload',
        'costs',
        'estimation',
        'time_tracking',
        'virtual_resources',
      ];

      resourceManagement.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'api_integrations_features_category':
      const apiIntegrations = [
        'slack',
        'one_drive_integration',
        'google_drive_integration',
        'ms_teams',
        'jira_cloud',
        'sso',
        'api',
      ];

      apiIntegrations.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'security_features_category':
      const securityFeatures = [
        'two_factor_auth',
        'saml_sso',
      ];

      securityFeatures.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'support_features_category':
      const supportFeatures = [
        'live_chat',
        'email_support',
        'tutorials',
        'cs_manager',
        'onboarding_assistance',
      ];

      supportFeatures.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    case 'etc_features_category':
      const etcFeatures = [
        'import',
        'export',
        'public_url',
        'mobile_app',
      ];

      etcFeatures.forEach(name => {
        const isFeature = features.find(f => f.name === name);

        if (isFeature) sorted.push(isFeature);
      });

      break;

    default:
      sorted = features;
    }

    return sorted;
  },
  getTableFeatures() {
    const paymentFeatures = user.paymentFeatures;
    const featuresData = { ...paymentFeatures, ...NEW_FEATURES };
    const tableConfig = [];
    const ETC_CATEGORY = 'etc_features_category';
    const SUPPORT_CATEGORY = 'support_features_category';

    featuresData.custom_permissions.category_locale = 'team_collaboration_features_category';
    featuresData.saml_sso.category_locale = 'security_features_category';
    featuresData.workload_view.id = featuresData.workload.id;

    const groupByFeatures = _.groupBy(_.values(featuresData), 'category_locale');
    const ETC_FEATURES = this.prepareGroupFeatures(ETC_CATEGORY, groupByFeatures[ETC_CATEGORY]);
    const SUPPORT_FEATURES = this.prepareGroupFeatures(SUPPORT_CATEGORY, groupByFeatures[SUPPORT_CATEGORY]);

    for (const key in groupByFeatures) {
      if (key === ETC_CATEGORY || key === SUPPORT_CATEGORY) {
        continue;
      }

      if (key !== 'null') {
        const features = this.prepareGroupFeatures(key, groupByFeatures[key]);

        tableConfig.push({
          title: key,
          features,
        });
      }
    }

    tableConfig.push({
      title: SUPPORT_CATEGORY,
      features: SUPPORT_FEATURES,
    });

    tableConfig.push({
      title: ETC_CATEGORY,
      features: ETC_FEATURES,
    });

    return tableConfig;
  },
};
