/* eslint-disable */
import app from "./../../../app";
import ganttViewModel from "./../../../models/ganttViewModel";

import _ from '../../../libs/lodash';
import globalStore from '../../../store/main';

const __ = window.__;

gantt.attachEvent('onInlineEdit', (taskId, propertyName, newValue, oldValue, isCustomColumn) => {
  if (propertyName === 'status') {
    if (newValue !== oldValue) {
      innerObject.settings.updateStatus = true;
    }
  } else if (propertyName === 'priority') {
    if (newValue !== oldValue) {
      innerObject.settings.updatePriority = true;
    }
  }
});

gantt.attachEvent('onBeforeTaskUpdate', (taskId, taskData) => {
  if (innerObject.settings.updateStatus) {
    innerObject.handlers.updateStatusValue(taskId, taskData);
    innerObject.settings.updateStatus = false;

    return false;
  }

  if (innerObject.settings.updatePriority) {
    innerObject.handlers.updatePriorityValue(taskId, taskData);
    innerObject.settings.updatePriority = false;

    return false;
  }

  return true;
});

gantt.attachEvent('updateCustomColumns', (changedColumns, taskId, taskData) => {
  if (_.includes(changedColumns, 'status')) {
    innerObject.handlers.updateStatusValue(taskId, taskData);
    innerObject.settings.updateStatus = false;
  }

  if (_.includes(changedColumns, 'priority')) {
    innerObject.handlers.updatePriorityValue(taskId, taskData);
    innerObject.settings.updatePriority = false;
  }
});

/* gantt.attachEvent("onTaskClick", function(id, e) {
  var task = gantt.getTask(id);

  var columns = gantt.config.columns;
  var cell = e.target || e.srcElement;
  var gridRow;// grid row dom-element
  var colIndex = 0;
  var i = 0;
  var isDefined = !!(cell.classList && cell.classList.contains);// for svg in IE11

  // if (gantt.config.readonly) {
  //   return;
  // }

  if (isDefined && cell.classList.contains('resource-in-grid')) {
    cell = cell.parentNode.parentNode;
  }

  if (task.type === gantt.config.types.button) {
    return;
  }

  if (isDefined && cell.classList.contains('gantt_tree_icon')) {
    return true;
  }

  if (cell.closest('.select_control') || cell.closest('.inline_editor')) {
    return true;
  }

  if (isDefined && cell.classList.contains('gantt_notification_comments')) {
    return true;
  }

  if (isDefined && cell.classList.contains('gantt_notification_attachments')) {
    return true;
  }

  if (!cell.parentNode) {
    return;
  }

  if (cell.parentNode.classList && cell.parentNode.classList.contains('gantt_cell')) {
    cell = cell.parentNode;
  }

  if (isDefined && cell.classList.contains('gantt_row')) {
    gridRow = cell;
  } else {
    gridRow = cell.parentNode;
  }

  var cellAttributeDataName = cell.getAttribute('data-name');

  //Calculate columns position
  _.each(gridRow.childNodes, function (item, index) {
    var isDefine = item.getAttribute && !_.isUndefined(item.getAttribute('data-name'));

    if (isDefine && item.getAttribute('data-name') === cellAttributeDataName) {
      colIndex = index;
    }
  });

  //
  // if (gantt.config.readonly) {
  //   return true;
  // }

  gantt.showTaskEditors(id, colIndex);

  return false;
}); */

var innerObject = {
  init: {
    beforeInit: function () {
      gantt.config.statusColumn = globalStore.getters['columns/getColumnByName']("status");
      gantt.config.priorityColumn = globalStore.getters['columns/getColumnByName']("priority");
    },
    run: function () {
      innerObject.init.initColumn(globalStore.getters['columns/getColumnByName']("status"));
      innerObject.init.initColumn(globalStore.getters['columns/getColumnByName']("priority"));
      innerObject.init.initColumn(globalStore.getters['columns/getColumnByName']("jira_key"));
    },
    afterInit(groupByHandler) {
      if (!innerObject.settings.modelEventListenerInit) {
        innerObject.modelEvents.customColumns.init();
      }
    },
    initColumn(column) {
      if (!column) {
        return;
      }

      const config = ganttViewModel.getProjectConfig();
      // TODO kostyl dly shatinga po linke
      const initCustomSizes = config.initGridColumns || { sizes: {} };

      if (!initCustomSizes.sizes) {
        initCustomSizes.sizes = {};
      }

      gantt.config.columns.push({
        align: 'left',
        label: __(column.locale),
        name: column.name,
        min_width: initCustomSizes.sizes[column.name] ? initCustomSizes.sizes[column.name].min_width : 50,
        width: initCustomSizes.sizes[column.name] ? initCustomSizes.sizes[column.name].width : 100,
        resize: true,
        hide: false,
        isShowCheckbox: true,
        order: 98,
        isCustomColumn: true,
        control_type: (column.name === 'jira_key') ? null : column.type,
        control_options: innerObject.helpers.optionsForStatusAndPriority(column),
        control_id: column.id,
        task_types: ['task', 'milestone'],
        template(item) {
          return innerObject.init.showColumnInGrid(item, column);
        },
      });

      gantt.config.columns = _.orderBy(gantt.config.columns, 'order');
    },
    showColumnInGrid(item, column) {
      let finishOption = {};

      if (!column) {
        return '';
      }

      column = globalStore.getters['columns/getColumnByName'](column.name);

      if (!column) {
        return '';
      }

      if (column.name === 'jira_key') {
        return (item.jira_key || '');
      }

      if (item.type === gantt.config.types.project || item.type === gantt.config.types.button) {
        return '';
      }

      finishOption = _.find(column.options, optionsItem => (+optionsItem.id === +item[column.name])) || _.find(column.options, optionsItem => optionsItem.is_default) || _.first(column.options);

      if (!finishOption) {
        return '';
      }

      if (finishOption.color && column.name === 'status') {
        return innerObject.helpers.getTemplateForStatus(finishOption);
      } if (finishOption.icon && column.name === 'priority') {
        return innerObject.helpers.getTemplateForPriority(finishOption);
      }

      return finishOption.locale ? __(finishOption.locale) : finishOption.name;
    },
    helpers: {
      findResource(item, resources) {
        return _.filter(resources, resourceItem => {
          if (parseInt(resourceItem.id, 10) === parseInt(item.resource_id, 10)) {
            return true;
          }
        })[0] || {};
      },
    },
  },
  handlers: {
    updateStatusValue(taskId, taskData) {
      gantt.callEvent('changeStatus', [taskData, true]);
    },
    updatePriorityValue(taskId, taskData) {
      return globalStore.dispatch('tasksModel/changePriority', {
        priorityValue: taskData.priority,
        taskData,
      });
    },
  },
  helpers: {
    getTemplateForStatus(option) {
      return `<span class="tag_grid_item" style="pointer-events:none;background: ${option.color}"></span><span class="status_list_item_focused">${option.locale ? __(option.locale) : option.name}</span>`;
    },
    getTemplateForPriority(option) {
      return `<span class="webix_icon icon_list_item" style="pointer-events:none;background-image: url(${option.icon})"></span><span class="priority_list_item">${option.locale ? __(option.locale) : option.name}</span>`;
    },
    optionsForStatusAndPriority(column) {
      if (!column || _.isEmpty(column)) {
        return [];
      }

      if (column.name === 'status') {
        return {
          body: {
            view: 'list',
            css: 'webix_multilist tag_multiselect',
            borderless: true,
            autoheight: true,
            yCount: 5,
            select: true,
            template(item) {
              return `<span class="tag_grid_item" style="background: ${item.color || '#55BED4'}"></span><span>${item.value}</span>`;
            },
          },
          data: column.options,
        };
      } if (column.name === 'priority') {
        return {
          view: 'suggest',
          body: {
            view: 'list',
            css: 'webix_multilist icon_multiselect',
            borderless: true,
            autoheight: true,
            yCount: 5,
            select: true,
            template(item) {
              return `<span class="webix_icon icon_list_item" style="background-image: url(${item.picture || item.icon || (`${GT.cdn}/imgs/medium.svg`)})"></span><span class="priority_list_item">${item.value}</span>`;
            },
            data: column.options,
          },
          data: column.options,
        };
      }

      return column.options;
    },
  },
  settings: {
    progressType: '',
    ganttId: 0,
    taskDataBeforeUpdate: {},
    currentResources: [],
    modelEventListenerInit: false,
    updateColumns: [],
    oldColumnsValues: [],
  },
  modelEvents: {
    customColumns: {
      init() {
        innerObject.settings.modelEventListenerInit = true;
      },
    },
  },
};

const setActiveStatusesAndPriority = function () {
  const statusColumn = _.find(gantt.config.columns, {
    name: 'status',
  });

  const priorityColumn = _.find(gantt.config.columns, {
    name: 'priority',
  });

  if (statusColumn) {
    statusColumn.control_options = innerObject.helpers.optionsForStatusAndPriority(globalStore.getters['columns/getColumnByName']("status"));
  }

  if (priorityColumn) {
    priorityColumn.control_options = innerObject.helpers.optionsForStatusAndPriority(globalStore.getters['columns/getColumnByName']("priority"));
  }
};

app.on('activeMultiProjects:set', (id, ganttIDs) => {
  setActiveStatusesAndPriority();
});

app.on('activeProject:set', (ganttId, ganttData) => {
  if (!user.team.is_jira) {
    return;
  }

  if (ganttData) {
    _.remove(gantt.config.columns, column => column.name === 'jira_key');

    if (ganttData.is_jira) {
      // dirty hack
      // queue init jira_key after active project was setted by another sync 'activeProject:set' handler
      // for case when navigate to jira project form multiview
      if (gantt.config.multiview_id) {
        Promise.resolve().then(()=>innerObject.init.initColumn(globalStore.getters['columns/getColumnByName']("jira_key")))
      } else innerObject.init.initColumn(globalStore.getters['columns/getColumnByName']("jira_key"));
    }
  }

  setActiveStatusesAndPriority();
});

const outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit,
  },
};

export default outputObject;
