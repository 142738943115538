<template>
  <div
    :class="['vgp-svg-image', `${name}-icon`]"
    @click="$emit('click', $event)"
  >
    <svg
      :viewBox="viewBox"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="{
        width: `${iconWidth}px`,
        height: `${iconHeight}px`,
        color: iconColor,
      }"
      v-html="iconHTML"
    />
  </div>
</template>
<script>
import icons from './imgLibrary/imagesLibrary';

export default {
  name: 'VgpSvgImage',
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return (Object.keys(icons).includes(value));
      },
    },
    width: { type: Number, required: false, default: 0 },
    height: { type: Number, required: false, default: 0 },
    color: { type: String, required: false, default: '' },
  },
  data() {
    return { };
  },
  computed: {
    icon() {
      const name = this.name || 'teammate';

      return icons[name];
    },

    iconWidth() {
      return this.width || this.icon.width;
    },

    iconHeight() {
      return this.height || this.icon.height;
    },

    iconColor() {
      return this.color || this.icon.color || 'inherit';
    },

    iconHTML() {
      return this.icon.path;
    },

    viewBox() {
      return `${`0 0 ${this.iconWidth} ${this.iconHeight}`}`;
    },
  },
};
</script>

<style lang="less" scoped>
.vgp-svg-image{
  display: flex;
  align-items: center;
}
</style>
