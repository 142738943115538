var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { display: "flex" } }, [
    _c(
      "div",
      {
        staticClass: "expand-toggle",
        style: { cursor: "pointer", paddingLeft: _vm.row.depth * 1 + "rem" },
      },
      [
        _vm.row.getCanExpand()
          ? _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.row.toggleExpanded()
                  },
                },
              },
              [
                _vm.row.getIsExpanded()
                  ? _c("svg-sprite", {
                      attrs: { type: "regular", size: 16, name: "minus-2" },
                    })
                  : _c("svg-sprite", {
                      attrs: { type: "regular", size: 16, name: "plus-3" },
                    }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.cell.getValue()))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }