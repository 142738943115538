var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "commentHistory",
      class: [_vm.$style["task-settings-comments"]],
      style: { width: "100%" },
    },
    [
      _vm.showTask
        ? _c(
            "div",
            {
              class: [
                _vm.$style.header,
                "gp_autotest_comment_hub_comments_list_header",
              ],
            },
            [
              _c("h2", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales("settings_taskComment")) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "commentList",
          class: [
            _vm.$style["comments__list"],
            "gp_autotest_comment_hub_comments_list",
          ],
        },
        [
          _vm._l(
            _vm.groupedCommentsByDate.grouped,
            function (groupComments, groupName) {
              return _c(
                "div",
                [
                  _vm.commentHistoryLabelsShown
                    ? _c(
                        "div",
                        { class: _vm.$style["comments__group-history-date"] },
                        [_vm._v("\n        " + _vm._s(groupName) + "\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(groupComments, function (comment, index) {
                    return _c(
                      "div",
                      { key: comment.id, attrs: { id: comment.id } },
                      [
                        _c("comment-item", {
                          attrs: {
                            id:
                              "gp_autotest_comment_hub_comment_item_" +
                              comment.id,
                            comment: comment,
                          },
                          on: {
                            "comment-delete": _vm.handleClickDeleteComment,
                            "edit-comment": function ($event) {
                              return _vm.$emit("edit-comment", comment)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }
          ),
          _vm._v(" "),
          _vm.groupedCommentsByDate.unread.length
            ? _c(
                "div",
                [
                  _vm.commentHistoryUnreadLabelsShown
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.$style["comments__group-history-date"],
                            "gp_autotest_comment_hub_comments_list_unread_label",
                          ],
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.locales(_vm.commentHistoryUnreadLabel)
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.groupedCommentsByDate.unread,
                    function (comment, index) {
                      return _c(
                        "div",
                        {
                          key: comment.id,
                          ref: "commentHistoryUnreadComments",
                          refInFor: true,
                          attrs: { id: comment.id },
                        },
                        [
                          _c("comment-item", {
                            attrs: {
                              id:
                                "gp_autotest_comment_hub_comment_item_unread_" +
                                comment.id,
                              comment: comment,
                            },
                            on: {
                              "comment-delete": _vm.handleClickDeleteComment,
                              "edit-comment": function ($event) {
                                return _vm.$emit("edit-comment", comment)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            style: { width: "100%", height: "24px", display: "flex" },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("core-confirm-popup", {
        staticClass: "gp_autotest_comment_hub_confirm_popup",
        attrs: {
          show: _vm.currentCommentIdForDelete,
          text: _vm.locales("settings_msg_delete_comment"),
          "ok-button": {
            type: "destructive",
            title: _vm.locales("common_yes"),
          },
          "cancel-button": {
            type: "secondary",
            title: _vm.locales("common_cancel"),
          },
        },
        on: {
          onClickOk: _vm.handleConfirmDeleteComment,
          onClickCancel: function ($event) {
            _vm.currentCommentIdForDelete = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }