import ssoHelper from '../../../../helpers/sso';
import store from '../../../../store/main';

function checkResourceInTeamByEmail(email) {
  const allResources = store.getters['resourcesModel/getAllResources'];
  const people = allResources.filter(resource => resource.userId);

  return people.find(resource => resource.email?.toLowerCase() === email.toLowerCase());
}

const multipleInviteValidation = [
  {
    classes: 'sso-email',
    rule: ({ text }) => !ssoHelper.checkSSOEmailWhitelisted(text, user.sso),
  },
  {
    classes: 'invalid-email',
    rule: ({ text }) => {
      const pattern = /^[-\w.]+@([-A-z0-9]+\.)+[A-z]{2,20}$/;

      return !pattern.test(text);
    },
  },
  {
    classes: 'has-in-team',
    rule: ({ text }) => checkResourceInTeamByEmail(text),
  },
];

export default multipleInviteValidation;
