import Vue from 'vue';
import store from '$$store/main';
import constants from '$$helpers/constants';
import integrationModel from '../models/integrations/integrations';
import rights from '../components/rights';

const checkMSTReconnect = () => {
  const activity = store.getters['user/getActivity'](constants.USER_ACTIVITIES.MSTRECONNECT);
  const integrationsData = integrationModel.getData();
  const msTeamsConnect = integrationsData.find(item => item.type === 'msteams');

  if (msTeamsConnect === undefined) return;

  const teamIsReconnected = !msTeamsConnect.needReconnect;
  const userId = +GT.user.id;
  let messageForNotify = ''; // it's message for notify
  let userConnected = false;

  msTeamsConnect.users.forEach(user => {
    if (user.user_id === userId) {
      userConnected = true;
    }

    if (teamIsReconnected) {
      messageForNotify = __('integrations_reconnect_message_only_connection');
      if (+user.user_id === userId && !user.reconnected) { //  && rights.account.hasRight('integration_settings')
        store.dispatch('teamSetting/setSettingErrorsByName', {
          name: 'integrations',
          data: {
            key: constants.USER_ACTIVITIES.MSTRECONNECT,
            message: __('integrations_reconnect_message_only_connection'),
            meassageLocaleKey: __('integrations_reconnect_message_only_connection'),
          },
        });
      }
    } else if (!teamIsReconnected) {
      if (rights.account.hasRight('integration_settings')) {
        // it wasn't reconnect of the team. This user haven't been reconnect yet
        messageForNotify = __('integrations_reconnect_message_team_connection');
        store.dispatch('teamSetting/setSettingErrorsByName', {
          name: 'integrations',
          data: {
            key: constants.USER_ACTIVITIES.MSTRECONNECT,
            message: __('integrations_reconnect_message_team_connection'),
            meassageLocaleKey: __('integrations_reconnect_message_team_connection'),
          },
        });
      } else if (!rights.account.hasRight('integration_settings')) {
        messageForNotify = __('integrations_reconnect_message_disabled');
        store.dispatch('teamSetting/setSettingErrorsByName', {
          name: 'integrations',
          data: {
            key: constants.USER_ACTIVITIES.MSTRECONNECT,
            message: __('integrations_reconnect_message_disabled'),
            meassageLocaleKey: __('integrations_reconnect_message_disabled'),
          },
        });
      }
    }
  });

  if (!activity.activity_status) {
    if (msTeamsConnect && msTeamsConnect.needReconnect && msTeamsConnect.isActive && userConnected) {
      setTimeout(() => {
        Vue.$toast.warning(messageForNotify, { timeout: 15000 });
      }, 2000);

      store.dispatch('user/updateActivityStatus', { activityName: constants.USER_ACTIVITIES.MSTRECONNECT, status: 1 });
    } else {
      store.dispatch('user/updateActivityStatus', { activityName: constants.USER_ACTIVITIES.MSTRECONNECT, status: 1 });
    }
  }
};

export default () => {
  checkMSTReconnect();
};
