import icon_task from '../../../svg/task.svg';
import icon_milestone from '../../../svg/milestone.svg';
import icon_highest from '../../../svg/highest.svg';
import icon_high from '../../../svg/high.svg';
import icon_medium from '../../../svg/medium.svg';
import icon_low from '../../../svg/low.svg';
import icon_lowest from '../../../svg/lowest.svg';
import icon_unassigned from '../../../svg/default/unassigned.svg';
import icon_resource from '../../../svg/default/default-ava.svg';

import constants from '../../../helpers/constants';
import projectsModel from '../../../models/projects';
import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import routerHelper from '../../../helpers/router';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';
import gt from '../../../helpers/gt';
import globalStore from '$$store/main';

const locale = {
  realResource: __('resources_layout_header_title_1'),
  virtualResource: __('resources_layout_header_title_2'),
};

class BaseProperties {
  static taskName(propety = 'text') {
    return {
      property: propety,
      label: __('filter_option_task_name'),
      placeholder: __('filter_placeholders_task_name'),
      type: 'search-input',
    };
  }

  static taskTypes(propety = 'type') {
    return {
      property: propety,
      label: __('filter_option_type_label'),
      placeholder: __('filter_task_type_any'),
      type: 'multiselect',
      itemTemplate: 'icon+text',
      data: [
        {
          id: 'task',
          value: __('filter_task_type_task'),
          icon: 'https://cdn.ganttpro.com/app/imgs/filter/task.svg',
        },
        {
          id: 'milestone',
          value: __('filter_task_type_milestone'),
          icon: 'https://cdn.ganttpro.com/app/imgs/filter/milestone.svg',
        },
      ],
    };
  }

  static statuses(propety = 'status') {
    const allStatuses = [];

    const ganttStatuses = [
      {
        id: '1',
        value: __('filter_status_open'),
        color: '#9b9b9b',
      },
      {
        id: '2',
        value: __('filter_status_in_progress'),
        color: '#ff9921',
      },
      {
        id: '3',
        value: __('filter_status_done'),
        color: '#55BED4',
      },
      {
        id: '4',
        value: __('filter_status_closed'),
        color: '#14bb4c',
      },
    ];

    const projects = BaseProperties.projects().data;

    projects.map(gantt => {
      if(!gantt.is_jira) return;
      
      let jiraStatuses = globalStore.getters['columns/getJiraColumnByNameAndProjectId']('status', gantt.id); 

      let ids = allStatuses.map(i => i.id);

      jiraStatuses.options.forEach(st => {
        !ids.includes(st.id) && allStatuses.push(st);
        ids = allStatuses.map(i => i.id);
      });
    });

    if (projects.find(i => !i.is_jira)) {
      allStatuses.unshift(...ganttStatuses);
    }

    return {
      property: propety,
      label: __('filter_option_status_label'),
      placeholder: __('filter_task_status_any'),
      type: 'multiselect',
      itemTemplate: 'color+text',
      data: allStatuses,
    };
  }

  static priority(property = 'priority') {
    const ganttPriority = [
      {
        id: '5',
        value: __('filter_priority_highest'),
        icon: 'https://cdn.ganttpro.com/app/imgs/highest.svg', // icon_highest,
      },
      {
        id: '6',
        value: __('filter_priority_high'),
        icon: 'https://cdn.ganttpro.com/app/imgs/high.svg', // icon_high,
      },
      {
        id: '7',
        value: __('filter_priority_medium'),
        icon: 'https://cdn.ganttpro.com/app/imgs/medium.svg', // icon_medium,
      },
      {
        id: '8',
        value: __('filter_priority_low'),
        icon: 'https://cdn.ganttpro.com/app/imgs/low.svg', // icon_low,
      },
      {
        id: '9',
        value: __('filter_priority_lowest'),
        icon: 'https://cdn.ganttpro.com/app/imgs/lowest.svg', // icon_lowest,
      },
    ];

    const allPriority = [];

    const projects = BaseProperties.projects().data;

    projects.map(gantt => {
      if (!gantt.is_jira) return;

      let jiraPriority = globalStore.getters['columns/getJiraColumnByNameAndProjectId']('priority', gantt.id); 

      let ids = allPriority.map(i => i.id);

      jiraPriority.options.forEach(st => {
        !ids.includes(st.id) && allPriority.push(st);
        ids = allPriority.map(i => i.id);
      });
    });

    if (projects.find(i => !i.is_jira)) {
      allPriority.unshift(...ganttPriority);
    }

    return {
      property,
      label: __('filter_option_priority_label'),
      placeholder: __('filter_task_priority_any'),
      type: 'multiselect',
      itemTemplate: 'icon+text',
      data: allPriority,
    };
  }

  static color(property = 'color') {
    const constantColor = constants.PALETTE_CONFIG.palette;
    const data = constantColor.reduce((a, b) => a.concat(b), []);

    return {
      property,
      label: __('filter_option_color_label'),
      placeholder: __('filter_task_color_any'),
      type: 'multiselect',
      itemTemplate: 'color',
      data,
    };
  }

  static dateRange(property = 'rangeDate') {
    return {
      property,
      label: __('filter_option_cost_range_label'),
      placeholder: __('filter_option_cost_range'),
      type: 'datepicker',
      rangeOnly: true,
    };
  }

  static startDate(property = 'startDate') {
    return {
      property,
      label: __('filter_option_start_date_label'),
      placeholder: __('filter_option_start_date'),
      type: 'datepicker',
      rangeOnly: false,
    };
  }

  static endDate(property = 'endDate') {
    return {
      property,
      label: __('filter_option_end_date_label'),
      placeholder: __('filter_option_end_date'),
      type: 'datepicker',
      rangeOnly: false,
    };
  }

  static creationDate(property = 'creationDate') {
    return {
      property,
      label: __('filter_option_created_at_label'),
      placeholder: __('filter_option_created_at'),
      type: 'datepicker',
      rangeOnly: false,
    };
  }

  static overdue(property = 'overdue') {
    return {
      property,
      label: __('filter_option_overdue_label'),
      type: 'toggle',
      tooltip: __('overdue_filter_tooltip'),
    };
  }

  static comment(property = 'comment') {
    return {
      property,
      label: __('filter_option_comment_label'),
      placeholder: __('filter_placeholders_comment'),
      type: 'search-input',
    };
  }

  static date(property = 'date') {
    return {
      property,
      label: __('filter_option_date_label'),
      placeholder: __('filter_option_date'),
      type: 'datepicker',
      rangeOnly: false,
    };
  }

  static time(property = 'time') {
    return {
      property,
      label: __('filter_option_time_label'),
      type: 'from-to',
    };
  }

  static projects(property = 'gantt_id') {
    let ganttIds = [];
    const avctiveProjects = projectsModel.getAllProjects();
    const archivedProjects = projectsModel.getArchivedProjects();
    let allProjects = avctiveProjects.concat(archivedProjects);

    if (!GT.appMode.isExport && routerHelper.isSingleProjectRoute()) {
      const id = +routerHelper.getCurrentRoute().params.projectId;

      id && ganttIds.push(id);
    }

    if (!GT.appMode.isExport && routerHelper.isMultiProjectsRoute()) {
      ganttIds = multiViewsProjectsModel.getActiveViewData().ganttIDs;
    }

    if (GT.appMode.isExport) {
      if (GT.ganttData.projects) {
        ganttIds.push(...GT.ganttData.projects.map(i => i.id));
      } else {
        ganttIds.push(GT.ganttData.project.id);
      }
    }

    if (!ganttIds.length) {
      const projectsIds = projectsModel.getAllProjects().map(p => p.gantt_id);

      ganttIds = projectsIds.filter(gantt_id => !projectsModel.isArchived(gantt_id));
    }

    allProjects = allProjects.filter(project => ganttIds.includes(project.gantt_id));

    return {
      property,
      label: __('filter_option_gantt_id_label'),
      placeholder: __('filter_task_gantt_id_any'),
      type: 'search-multiselect',
      itemTemplate: 'text',
      data: allProjects.map(proj => ({
        id: proj.id,
        value: proj.name,
        is_jira: proj.is_jira,
      })),
    };
  }

  static assignee(property = 'assignee') {
    const activeViewData = ganttViewModel.getActiveViewData();
    const viewGanttIDs = activeViewData ? (activeViewData.ganttIDs || [activeViewData.gantt_id]) : [];
    let resources = [];

    viewGanttIDs.forEach(ganttID => {
      const resourcesByGanttID = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttID);

      resources = _.unionBy(resources, resourcesByGanttID, 'id');
    });

    const resourcesData = _.map(resources, item => {
      const resource = item;

      resource.icon = item.picture || icon_resource;
      resource.value = item.name;
      resource.group = item.userId ? locale.realResource : locale.virtualResource;

      return resource;
    });

    return {
      property,
      label: __('filter_option_assignee_label'),
      placeholder: __('filter_task_assignee_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: [{
        id: '-1',
        value: __('notAssigned'),
        icon: icon_unassigned,
      }].concat(resourcesData),
    };
  }

  static assigneeByGanttIDs(property = 'assignee', ganttIDs) {
    let resources = [];

    if (ganttIDs) {
      ganttIDs.forEach(ganttID => {
        const resourcesByGanttID = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttID);

        resources = _.unionBy(resources, resourcesByGanttID, 'id');
      });
    } else {
      resources = globalStore.getters['resourcesModel/getAllResources'];
    }

    const virtual = [];
    const real = [];

    resources.forEach(i => {
      const item = { ...i };

      item.icon = i.photo || i.picture || icon_resource;
      item.value = i.name;
      item.group = i.userId ? locale.realResource : locale.virtualResource;

      if (i.userId) real.push(item);
      else virtual.push(item);
    });

    return {
      property,
      label: __('filter_option_assignee_label'),
      placeholder: __('filter_task_assignee_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: [{
        id: '-1',
        value: __('notAssigned'),
        icon: icon_unassigned,
      }, ...real, ...virtual],
    };
  }

  static resources(property = 'resource_id') {
    return {
      property,
      label: __('filter_option_resource_id_label'),
      placeholder: __('filter_task_resource_id_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: BaseProperties.resourcesAll(),
    };
  }

  static reporter(property = 'reporter') {
    return {
      property,
      label: __('filter_option_reporter_label'),
      placeholder: __('filter_task_reporter_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: BaseProperties.resourcesReal(),
    };
  }

  static realResources(property = 'resource_id') {
    return {
      property,
      label: __('filter_option_resource_id-timeloglist_label'),
      placeholder: __('filter_task_resource_id_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: BaseProperties.resourcesReal(),
    };
  }

  static resourcesReal() {
    const realResources = globalStore.getters['resourcesModel/getAllRealResources'];

    return realResources.map(i => ({
      id: i.id,
      userId: i.userId,
      value: i.name,
      icon: i.photo || icon_resource,
    }));
  }

  static resourcesAll() {
    const allResources = globalStore.getters['resourcesModel/getAllResources'];
    const real = allResources.filter(res => res.userId);
    const virtual = allResources.filter(res => !res.userId);

    return [...real, ...virtual].map(item => {
      const resource = { ...item };

      resource.icon = item.photo || icon_resource;
      resource.value = item.name;
      resource.group = item.userId ? locale.realResource : locale.virtualResource;

      return resource;
    });
  }
}

export default BaseProperties;
