import _ from "./../libs/lodash";
import moment from "./../libs/moment";
import customHelper from "./custom";
import timeParser from "./timeParser";
import dateHelper from "./dateFormats";
import projectsModel from "../models/projects";
import globalStore from '../store/main';

const __ = window.__;

const priorityMapById = {
  5: {
    value: __('filter_priority_highest'),
    icon: 'https://cdn.ganttpro.com/app/imgs/highest.svg'
  },
  6: {
    value: __('filter_priority_high'),
    icon: 'https://cdn.ganttpro.com/app/imgs/high.svg'
  },
  7: {
    value: __('filter_priority_medium'),
    icon: 'https://cdn.ganttpro.com/app/imgs/medium.svg'
  },
  8: {
    value: __('filter_priority_low'),
    icon: 'https://cdn.ganttpro.com/app/imgs/low.svg'
  },
  9: {
    value: __('filter_priority_lowest'),
    icon: 'https://cdn.ganttpro.com/app/imgs/lowest.svg'
  }
};

const statusMapById = {
  1: {
    value: __('filter_status_open'),
    color: '#9b9b9b'
  },
  2: {
    value: __('filter_status_in_progress'),
    color: '#ff9921'
  },
  3: {
    value: __('filter_status_done'),
    color: '#55BED4'
  },
  4: {
    value: __('filter_status_closed'),
    color: '#14bb4c'
  }
};

const iconsTaskTooltip = {
  calendar: {
    icon: 'https://cdn.ganttpro.com/app/imgs/workload/workload-task-tooltip/calendar.svg'
  },
  time: {
    icon: 'https://cdn.ganttpro.com/app/imgs/workload/workload-task-tooltip/timer.svg'
  },
  info: {
    icon: 'https://cdn.ganttpro.com/app/imgs/workload/workload-task-tooltip/info.svg'
  }
};

function getStatusAndPriorityPropsForJiraTask(taskData) {
  const activeProject = projectsModel.getActiveProjectData();
  const jiraCustomColumns = globalStore.getters['columns/getJiraColumnsByProjectId'](activeProject.gantt_id);

  let priorityColumn;
  let statusColumn;

  jiraCustomColumns.some(column => {
    if (column.name === 'priority') priorityColumn = column;
    if (column.name === 'status') statusColumn = column;
    return priorityColumn && statusColumn;
  });

  const status = statusColumn.options.find(({ id }) => id === taskData.status);
  const statusColor = status.color;
  const statusValue = status.value;

  const priority = priorityColumn.options.find(({ id }) => id === taskData.priority);
  const priorityIcon = priority.icon;
  const priorityValue = priority.value;

  return {
    statusColor,
    statusValue,
    priorityIcon,
    priorityValue,
  };
}

const getTaskTooltipTemplate = function (taskData) {
  const breadCrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](taskData.id, taskData.gantt_id, {});
  const projectData = projectsModel.getProjectDataById(taskData.gantt_id);
  const projectDataText = projectData.name.replace(/&nbsp;/gi, ' ');
  const breadcrumbsText = breadCrumbsTasks.reduce(function (sum, elem) {
    return sum + ' / ' + elem.text;
  }, '');
  const startDateText = gantt.date.date_to_str(dateHelper.getDateFormat())(moment(taskData.start_date).toDate());
  const endDateText = gantt.date.date_to_str(dateHelper.getDateFormat())(moment(taskData.end_date).toDate());
  const duration = gantt.calculateDuration(new Date(taskData.start_date), new Date(taskData.end_date), taskData);
  let durationText = timeParser.output(duration, {
    prop: 'duration',
    durationData: gantt.config.durationData,//TODO
    durationStep: gantt.config.duration_view
  });
  const durationStep = durationText[durationText.length - 1];
  let estimationText = timeParser.output(taskData.estimation, { prop: 'estimation' });
  const estimationStep = estimationText[estimationText.length - 1];
  const progress = parseFloat(taskData.progress * 100).toFixed(0);

  durationText = durationText.slice(0, durationText.length - 1);
  estimationText = estimationText.slice(0, estimationText.length - 1);

  const timeSection = `<div class="section">
    <span class="section_icon">
      <img src="${iconsTaskTooltip.time.icon}" alt="time">
    </span>
    <div class='section_item'>
      <span class="section_item-text">${__('gantt_grid_duration')}:</span>
      <span class="section_item-accent">${durationText}</span>
      <span class="section_item-desc">${durationStep}</span>
    </div>
    <div class='section_item'>
      <span class="section_item-text">${__('gantt_grid_estimation')}:</span>
      <span class="section_item-accent">${estimationText}</span>
      <span class="section_item-desc">${estimationStep}</span>
    </div>
  </div>`;

  let statusColor;
  let statusValue;
  let priorityIcon;
  let priorityValue;

  if (projectData.is_jira) {
    ({
      statusColor,
      statusValue,
      priorityIcon,
      priorityValue,
    } = getStatusAndPriorityPropsForJiraTask(taskData));
  } else {
    statusColor = statusMapById[taskData.status].color;
    statusValue = statusMapById[taskData.status].value;

    priorityIcon = priorityMapById[taskData.priority].icon;
    priorityValue = priorityMapById[taskData.priority].value;
  }

  const html = `
      <div class='head'>
        <div class='name'>${customHelper.formatTaskName(taskData.text)}</div>
        <div class='breadcrumbs'>${customHelper.formatTaskName((projectDataText + breadcrumbsText))}</div>
      </div>
      <div class='body'>
        <div class="section">
          <span class="section_icon">
            <img src="${iconsTaskTooltip.calendar.icon}" alt="calendar">
          </span>
          <div class='section_item'>
            <span class="section_item-text">${__('gantt_grid_start_date')}:</span>
            <span class="section_item-accent">${startDateText}</span>
          </div>
          <div class='section_item'>
            <span class="section_item-text">${__('gantt_grid_end_date')}:</span>
            <span class="section_item-accent">${endDateText}</span>
          </div>
        </div>
        ${taskData.type === gantt.config.types.task ? timeSection : ''}
        <div class="section">
          <span class="section_icon">
            <img src="${iconsTaskTooltip.info.icon}" alt="info">
          </span>
          <div class='section_item'>
            <span class="section_item-text">${__('gantt_grid_status')}:</span>
            <span class="section_item-circle" style="background: ${statusColor}"></span>
            <span class="bold">${statusValue}</span>
          </div>
          <div class='section_item'>
            <span class="section_item-text">${__('gantt_grid_progress')}:</span>
            <span class="section_item-accent">${progress}</span>%
          </div>
          <div class='section_item'>
            <span class="section_item-text">${__('gantt_grid_priority')}:</span>
            <span class="item_icon priority" style="background-image: url(${priorityIcon})"></span>
            <span class="bold">${priorityValue}</span>
          </div>
        </div>
      </div>`;
  return html;
};

const calculatePosition = function (tooltipNode, event, topDelta) {
  let topPosition = event.clientY - tooltipNode.offsetHeight - event.offsetY - 5;
  let leftPosition = event.clientX - tooltipNode.offsetWidth / 2;
  const documentWidth = document.documentElement.clientWidth;
  const documentHeight = document.documentElement.clientHeight;

  if (documentWidth <= leftPosition + tooltipNode.offsetWidth) {
    leftPosition = documentWidth - tooltipNode.offsetWidth - 5;
  }

  if (topPosition <= 0) {
    topPosition = event.clientY + topDelta - event.offsetY + 5;

    if ((documentHeight - topPosition) < tooltipNode.offsetHeight) {
      topPosition = 5;
      leftPosition = event.clientX - tooltipNode.offsetWidth - event.offsetX - 5;
    }
  }

  return {
    top: topPosition,
    left: leftPosition
  }
};

export default {
  getTaskTooltipTemplate,
  calculatePosition
};
