var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.switch },
    _vm._l(_vm.views, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "gp_autotes_resource_switch",
          class: [
            _vm.$.switch_item,
            item.type === _vm.active ? _vm.$.active : "",
          ],
          on: {
            click: function ($event) {
              return _vm.$emit("onToggleSwitch", item)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(item.text) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }