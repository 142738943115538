var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$.skeleton_container,
      style: { height: _vm.height + "px" },
      attrs: { id: "skeletonListView" },
    },
    [
      _c("skeleton-wrapper", {
        style: { width: "100%" },
        attrs: { id: "skeletonWrapper" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("table", { style: { width: "100%" } }, [
                  _c("thead", { class: _vm.$.tableHeader }, [
                    _c(
                      "tr",
                      { class: [_vm.$.headerRow] },
                      _vm._l(8, function (column) {
                        return _c(
                          "th",
                          [
                            _c("skeleton-item", {
                              style: { height: "12px" },
                              attrs: { type: "bar", width: "tiny" },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("div", { style: { width: "100%", height: "16px" } }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$.skeleton_table },
        [
          _c("skeleton-wrapper", {
            attrs: { id: "skeletonWrapper" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c("table", [
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c(
                              "td",
                              { class: [_vm.$.firstRow, _vm.$.skeleton_row] },
                              [
                                _c(
                                  "div",
                                  { class: _vm.$.firstCell },
                                  [
                                    _c("skeleton-item", {
                                      style: { width: "107px", height: "17px" },
                                      attrs: { type: "bar" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { class: _vm.$.lastCell },
                                  [
                                    _c("skeleton-item", {
                                      style: { width: "107px", height: "17px" },
                                      attrs: { type: "bar" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(10, function (row, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_c("skeleton-row")], 1),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }