<template>
  <div class="settings-content settings-content-billing">
    <div
      ref="settings_section"
      class="settings-section"
    >
      <div class="main-title">
        {{ locales('settings_billing_title') }}
      </div>
      <div class="row-info current-plan">
        <div class="info-label">
          {{ locales('billing_current_plan_label') }}
        </div>
        <div class="description name">
          <p class="details details">
            {{ currentPlanName }}
          </p>
        </div>
      </div>
      <div class="row-info plan">
        <div class="info-label">
          {{ locales('billing_upgrade_label') }}
        </div>
        <div class="description">
          <p
            class="details details-text"
            v-html="planLabel"
          />
          <p
            v-if="!isOverSubscription"
            class="details details-bold"
          >
            {{ planPeriod.dateTo }}
          </p>
          <global-button
            :config="{
              isGlobal: true,
              locale: locales(`${isTrial || isOverSubscription ? 'upgrade_plan_btn' : 'billing_upgrade_btn_text'}`),
              handler: upgradePlanClick,
              size: 'small',
              color: 'light-orange',
              isDisable: false
            }"
          />
        </div>
      </div>
      <div
        v-if="!isTrial"
        class="row-info editors"
      >
        <div class="info-label">
          {{ locales('billing_editors_label') }}
        </div>
        <div class="description">
          <p class="details details-text">
            {{ locales('billing_editors_text') }}
          </p>
          <p class="details details-bold">
            {{ userCount }}
          </p>
          <div
            v-if="isSubscriptionActive"
            :class="['string-btn', '']"
            @click="onChangeSize"
          >
            {{ locales('billing_change_team_size_btn') }}
          </div>
        </div>
      </div>
      <div
        v-if="!isTrial"
        class="row-info payment-method"
      >
        <div class="info-label">
          {{ locales('billing_payment_method_label') }}
        </div>
        <div class="description">
          <p class="details details-text">
            {{ locales('billing_payment_method_text') }}
          </p>
          <div
            :class="['string-btn','']"
            @click="changePaymentMethod"
          >
            {{ locales('billing_payment_method_btn') }}
          </div>
        </div>
      </div>
    </div>
    <delimiter v-if="paymentHistory.length" />
    <div
      v-if="paymentHistory.length"
      class="settings-section invoices"
    >
      <div class="main-title">
        {{ locales('settings_billing_invoices_title') }}
      </div>
      <div
        :class="['invoices-table', 'show-all']"
      >
        <div class="table-row table-row_head">
          <div class="table-cell table-cell_date">
            {{ locales('date_key') }}
          </div>
          <div class="table-cell table-cell_desc">
            {{ locales('desc_key') }}
          </div>
          <div class="table-cell table-cell_editors">
            {{ locales('editors_key') }}
          </div>
          <div class="table-cell table-cell_total">
            {{ locales('total_key') }}
          </div>
          <div class="table-cell table-cell_status">
            {{ locales('status_key') }}
          </div>
        </div>
        <div
          v-for="(item, index) in paymentHistory"
          :key="index"
          class="table-row"
        >
          <div class="table-cell table-cell_date">
            {{ item.date }}
          </div>
          <div
            class="table-cell table-cell_desc"
            :title="item.name"
          >
            {{ item.name }}
          </div>
          <div class="table-cell table-cell_editors">
            <span
              v-if="item.users"
              class="editors-count"
            >
              {{ item.users }}
            </span>
            <div
              v-else
              class="editors-no-data"
            >
              <span class="line" />
            </div>
          </div>
          <div class="table-cell table-cell_total">
            {{ `$ ${item.order_total}` }}
          </div>
          <div
            :class="['table-cell table-cell_status']"
            :style="{ color: getStatusColor(item.action) }"
            :title="item.actionName"
          >
            {{ item.actionName }}
          </div>
          <div
            v-if="item.invoiceLink"
            class="table-cell table-cell_invoice-link"
          >
            <a
              :href="item.invoiceLink"
              target="_blank"
            >{{ locales('view_invoice_link') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isSubscriptionActive"
      ref="billing_footer"
      class="billing-footer"
    >
      <div
        class="footer-btn footer-btn--cancel"
        @click="unsubscribeClick"
      >
        {{ locales('cancel_btn') }}
      </div>
    </div>
  </div>
</template>

<script>
import feedbackHelper from '../../../helpers/feedback';
import dateHelper from '../../../helpers/dateFormats';
import plansModel from '../../../models/plans';
import constants from '../../../admin/helpers/constants';

import globalButton from '../../common/VueComponents/globalButton/globalButton.vue';
import Delimiter from '../include/delimiter.vue';
import app from '../../../app';

export default {
  components: {
    globalButton,
    Delimiter,
  },
  data() {
    return {
      locales: __,
      provider: '',
      realUserCount: '',
      userPlan: {},
    };
  },
  computed: {
    isTrial() {
      return plansModel.isTrial();
    },
    isOverSubscription() {
      return plansModel.isOverSubscription();
    },
    isSubscriptionActive() {
      const isFinished = user.subscription.status_code === 11;
      const isTerminated = user.subscription.status_code === 10;
      const isRefunded = user.subscription.status_code === 2;
      const isSuspended = user.subscription.status_code === 8;

      return !isFinished && !isTerminated && !isRefunded && !isSuspended && !plansModel.isOverSubscription();
    },
    planLabel() {
      const isTerminated = user.subscription.status_code === 10 || user.subscription.status_code === 2;
      const isFinishedSubscription = user.subscription.status_code === 11;
      const isAnnual = user.subscription.is_annual;
      const isTrial = plansModel.isTrial();
      let label;

      if (isTerminated || isFinishedSubscription || plansModel.isOverSubscription()) {
        label = __(`${this.userPlan.name}_plan_expired`);

        return label;
      }

      if (isAnnual) {
        label = __(`${isTrial ? 'trial' : this.userPlan.name}_billing_plan_annual_label`);
      } else {
        label = __(`${isTrial ? 'trial' : this.userPlan.name}_billing_plan_month_label`);
      }

      return label;
    },
    currentPlanName() {
      const isTrial = plansModel.isTrial();
      const planName = isTrial ? __('billing_trial_description') : __(`${this.userPlan.name}_plan_locale`);

      return planName;
    },
    paymentHistory() {
      return this.$store.getters['profileSettings/paymentHistory'];
    },
    planPeriod() {
      const period = this.$store.getters['profileSettings/plansPeriod'];
      const planPeriod = {
        dateTo: dateHelper.formatDateNoTime(period.dateTo),
        dateFrom: dateHelper.formatDateNoTime(period.dateFrom),
      };

      return planPeriod;
    },
  },
  mounted() {
    // using for annual offer email
    if (this.$route.query.annual_offer === '1') {
      this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'upgrade_plan_popup_title',
        currentPlan: this.userPlan,
        newPlan: this.userPlan,
        isAnnual: 1,
      });
      userExtAnalytics.log('annual_offer_upgrade_popup_open', { plan: this.userPlan });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      userExtAnalytics.log('billing_opened');
      const userPlanId = user.subscription.payment_plans_id;

      this.provider = user.subscription.provider;
      this.userCount = user.subscription.user_count || 1;

      if (!plansModel.isTrial()) {
        this.userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);
      }
      this.$store.dispatch('profileSettings/getPlanPeriod');
      this.$store.dispatch('profileSettings/loadPaymentHistory');
    },
    getStatusColor(status) {
      return constants.PAYMENT_TYPE_COLORS[status] || "#000000"; // Default to black if no color is found
    },
    async onChangeSize() {
      //[upgrage] - change team size
      if (plansModel.isCanNotBeUpdatedWithoutSales()) {
        app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

        return false;
      }

      if (plansModel.isRequireNewSubscriptionInsteadOfUpdate()) {
        this.$store.commit('paymentPlans/show');

        return;
      }

      this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'change_team_size_title',
        mode: 'change-team-size',
        currentPlan: this.userPlan,
        newPlan: this.userPlan,
        isAnnual: user.subscription.is_annual,
      });
    },

    async changePaymentMethod() {
      if(plansModel.isOrderProcessing()) {
        webix.message({ type: 'info', text: __('order_processing_message_for_upgrade') });

        return false;
      }

      userExtAnalytics.log('billing_page_payment_method_change_clicked');

      await plansModel.sendLoginEmailForChangePaymentMethod();
      await this.$store.dispatch('globalPopup/show', {
        type: 'paymentConfirm',
        ok: __('common_ok'),
        message: `${__('comfirm_popup_change_payment_message')}: <a href="mailto:${user.accountPlanData.provider_payment_email}">${user.accountPlanData.provider_payment_email}</a>`,
        footerText: __('comfirm_popup_change_payment_footer'),
        width: 515,
      });
    },
    async upgradePlanClick() {
      //debugger;
      //[upgrage] - change team size
      // 1) settings-billing-upgrade (потом идет в show upgrade popup

      if (plansModel.isCanNotBeUpdatedWithoutSales()) {
        app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

        return false;
      }

      if (plansModel.isRequireNewSubscriptionInsteadOfUpdate()) {
        this.$store.commit('paymentPlans/show');

        return false;
      }
      // if (plansModel.isTrial() || isTerminated || isRefunded || isFinished) {
      //   this.$store.commit('paymentPlans/show');

      //   return;
      // }

      this.$store.dispatch('globalPopup/show', {
        type: 'paymentPopup',
      });
    },
    unsubscribeClick() {
      userExtAnalytics.log('billing_page_subscription_cancel_clicked');
      $$('userAccountSubscribe').show();
    },
    restoreClick() {
      this.$store.dispatch('profileSettings/restoreSubscription');
    },
    sendEmail() {
      feedbackHelper.send({
        type: 'billing-upgrade',
        send_followup_email: false,
      });
    },
  },
};
</script>

<style scoped src='../less/profileSettings.less'></style>
