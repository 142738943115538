import _ from "../libs/lodash";

var innerObject = {
  addInactiveClassToAllAlreadyUsedHelpers: function () {
    if (!user.activity) {
      return;
    }

    var userActivity = user.activity;
    var userActivityClear = _.uniqWith(user.activity, _.isEqual);

    userActivity.splice(0, userActivity.length, ...userActivityClear);

    _.map(userActivityClear, function (val) {
      var el = document.getElementById(val.name);

      // if(el){
      //   el.classList.add('activity_used');
      // }

    });
  }
};

export default innerObject;