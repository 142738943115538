var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    class: _vm.popup.remove_popup,
    attrs: {
      id: "gp_autotest_resources_confirm_popup",
      show: _vm.isShow,
      title: _vm.title,
      "close-icon": true,
    },
    on: {
      onClickCancel: function ($event) {
        return _vm.$emit("onCancel")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.popup.message_body }, [
              _c("div", {
                class: _vm.popup.message,
                domProps: { innerHTML: _vm._s(_vm.message) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.popup.buttons },
              [
                _c("vgp-button", {
                  attrs: {
                    id: "gp_autotest_resources_confirm_popup_btn_cancel",
                    type: "secondary",
                    label: _vm.locales("common_cancel"),
                    small: true,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.$emit("onCancel")
                    },
                  },
                }),
                _vm._v(" "),
                _c("vgp-button", {
                  style: { marginLeft: 16 + "px" },
                  attrs: {
                    id: "gp_autotest_resources_confirm_popup_btn_ok",
                    type: "primary",
                    label: _vm.locales("common_change"),
                    small: true,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.$emit("onApply")
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }