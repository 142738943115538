var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("portal", { attrs: { to: "popup" } }, [
        _c(
          "div",
          {
            ref: "popup",
            class: [_vm.$style.core_popup, "core_popup", _vm.classList],
            style: _vm.inlineStyles,
          },
          [
            _c(
              "div",
              {
                ref: "popup_container",
                class: [
                  _vm.$style.container,
                  _vm.$style.show,
                  "core_popup_block",
                ],
                style: _vm.width
                  ? "max-width: " +
                    _vm.width +
                    "px !important; min-width: " +
                    _vm.width +
                    "px !important"
                  : "",
              },
              [
                _vm.infoIcon || _vm.title || _vm.helpIcon || _vm.closeIcon
                  ? _c(
                      "div",
                      { class: [_vm.$style.head, _vm.$style[_vm.headerStyle]] },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.head__left },
                          [
                            _vm.infoIcon
                              ? _c("sprite", {
                                  class: _vm.$style.head__info_icon,
                                  attrs: {
                                    color: "#808080",
                                    name: _vm.infoIcon,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.title
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.$style.head__title,
                                      _vm.$style[_vm.headerStyle],
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.title) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$style.head__right },
                          [
                            _vm.helpIcon
                              ? _c("vgp-icon-button", {
                                  attrs: {
                                    size: "small",
                                    icon: {
                                      name: "help",
                                      type: "regular",
                                      size: 20,
                                    },
                                  },
                                  on: { click: _vm.onClickInfo },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.closeIcon
                              ? _c("sprite", {
                                  class: _vm.$style.head__close_icon,
                                  attrs: {
                                    name: "close-1",
                                    type: "bold",
                                    size: 24,
                                    color: "#808080",
                                  },
                                  on: { click: _vm.onClickCancel },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.body,
                      _vm.$style[_vm.headerStyle],
                      _vm.$style[_vm.bodyStyle],
                    ],
                  },
                  [_vm._t("body")],
                  2
                ),
                _vm._v(" "),
                _vm.okButtonTitle || _vm.cancelButtonTitle
                  ? _c(
                      "div",
                      {
                        class: [_vm.$style.footer, _vm.$style[_vm.footerStyle]],
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.actions },
                          [
                            (
                              _vm.buttonsSwitch
                                ? _vm.cancelButtonTitle
                                : _vm.okButtonTitle
                            )
                              ? _c("vgp-button", {
                                  attrs: {
                                    type: "secondary",
                                    small: true,
                                    label: _vm.buttonsSwitch
                                      ? _vm.cancelButtonTitle
                                      : _vm.okButtonTitle,
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      _vm.buttonsSwitch
                                        ? _vm.onClickCancel()
                                        : _vm.onClickOk()
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            (
                              _vm.buttonsSwitch
                                ? _vm.okButtonTitle
                                : _vm.cancelButtonTitle
                            )
                              ? _c("vgp-button", {
                                  attrs: {
                                    type: "primary",
                                    small: true,
                                    disabled: _vm.disabledCancel,
                                    label: _vm.buttonsSwitch
                                      ? _vm.okButtonTitle
                                      : _vm.cancelButtonTitle,
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      _vm.buttonsSwitch
                                        ? _vm.onClickOk()
                                        : _vm.onClickCancel()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }