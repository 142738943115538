<template>
  <div :class="$style.section__left">
    <h1
      ref="title"
      :class="[
        $style.section__title,
        animateLeave ? $style.animateLeave : '',
        animateEnter ? $style.animateEnter : '',
      ]"
    >
      {{ title }}
    </h1>
    <h2
      ref="description"
      :class="[
        $style.section__description,
        animateLeave ? $style.animateLeave : '',
        animateEnter ? $style.animateEnter : '',
      ]"
      v-html="description"
    />
    <slot />
  </div>
</template>

<script>

export default {
  name: 'WizardLeftSection',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    animateLeave: {
      type: Boolean,
      default: false,
    },
    animateEnter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="less">

.section__left {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section__title {
  opacity: 0;
  transform: translateY(40px);

  font-family: Lato-Regular, sans-serif;
  font-size: 29px;
  line-height: 35px;
  color: #000000;

  margin-bottom: 32px;
  margin-top: 34px;
  width: 302px;
}

.section__description {
  opacity: 0;
  transform: translateY(40px);
  font-family: Lato-Regular, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 74px;
  width: 302px;
}

.animateLeave {
  &.section__title {
    transition: all .25s cubic-bezier(0.42, 0, 1, 1);
    transform: translateY(-40px);
    opacity: 0;
  }

  &.section__description {
    transition: all .25s cubic-bezier(0.42, 0, 1, 1) .1s;
    transform: translateY(-40px);
    opacity: 0;
  }
}

.animateEnter {
  &.section__title {
    transition: all .25s cubic-bezier(0, 0, 0.58, 1);
    transform: translateY(0);
    opacity: 1;
  }

  &.section__description {
    transition: all .25s cubic-bezier(0, 0, 0.58, 1);
    transform: translateY(0);
    opacity: 1;
  }
}

</style>
