
import _ from "../../../libs/lodash";
import xlsxExport from "../xlsxExport";
import workloadCore from '../../../views/gantt/modules/workloadCore';


const __ = window.__;

var innerObject = {
  init: {
    run: function () { },
    afterInit: function () { }
  },

  handlers: {
    export: function (options) {
      let {viewMode, order, workload, scale } = options.data;
      let columns = [];
      var exportOptions = {
        title: options.title,
        worksheets: []
      };

      columns.push({
        key: 'resourceName',
        label: __('workload_resource_name'),
        type: "longText",
      });

      _.each(scale.trace_x, (val, i) => {
        let m = moment(val);
        let utcDate = moment(val).utc().add(m.utcOffset(), 'm').toDate();
        columns.push({
          colWidth: 9,
          key: i,
          label: utcDate,
          type: "numberWithBackground",
        });
      });

      let rows = [];
      _.forEach(order, (key) => {

        var val = workload[key];

        if(val.unassigned) return;
        if(val.isTasksItem) return;

        let rowData = {resourceName: val.name};

        _.each(val.cellData, (val, i) => {
          let info = val.resourceDayInfo;
          let totalTasksLength = val.totalTasksLength;
          let backgroundHash = "#ffffff";
          let foregroundHash = "#000000"
          let value = 0;

          if(viewMode === 'tasks'){
            value = totalTasksLength;
          } else if (viewMode === 'percents'){
            value = info.totalHours === 0 ? 0 : _.floor(info.totalHours / info.workHours * 100);

            if (!info.workHours && info.totalHours) {
              value = 100;
            }
          } else {
            value = workloadCore.formatNumber(info.totalHours);
          }

          if(info.totalHours === 0){
            backgroundHash = "#ffffff";
            foregroundHash = "#000000";
          } else if(info.totalHours < info.workHours){
            backgroundHash = "#f5fff5";
            foregroundHash = "#129534";
          } else if(info.totalHours === info.workHours){
            backgroundHash = "#cfffe0";
            foregroundHash = "#129534";
          } else if(info.totalHours > info.workHours){
            backgroundHash = "#ffb3b3";
            foregroundHash = "#B93D30";
          }

          if(info.isWeekend){
            if(value === 0) value = '';
            backgroundHash = "#F4F8FC";
          }

          rowData[i] = {
            value,
            backgroundHash,
            foregroundHash
          };
        });

        rows.push({
          attributes: {},
          data: rowData,
        });
      });

      exportOptions.worksheets.push({
        title: exportOptions.title,
        rows,
        columns
      });

      xlsxExport.export(exportOptions);
    }
  }
};

var outputObject = {
  init: {
    run: innerObject.init.run,
    afterInit: innerObject.init.afterInit,
  },
  export: innerObject.handlers.export
};

export default outputObject;

