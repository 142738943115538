import app from '../../app';
import helpers from './helpers';
import moment from '../../libs/moment';
import { api } from '$$store/api';

export default {
  async loadData({ dispatch }) {
    await Promise.all([
      await dispatch('loadStaticColumns'),
      await dispatch('loadCustomColumns'),
      await dispatch('loadProjectColumnsWithoutValues'),
    ]);
    app.checkInit('columns');
    await dispatch('loadProjectColumns');
  },
  async loadStaticColumns({ commit, rootState }) {
    let staticColumns = [];
    let jiraColumns = [];

    if (rootState.appMode.isBase) {
      const response = await api.get(`/custom_columns/?${Date.now()}`);

      staticColumns = response.data;
    } else if (rootState.appMode.isLink || rootState.appMode.isExport) {
      staticColumns = GT.ganttData.customColumns || [];
    }

    if ((rootState.appMode.isLink || rootState.appMode.isExport) && GT.ganttData.jiraCustomColumns) {
      jiraColumns = GT.ganttData.jiraCustomColumns;
    } else if (user.team.is_jira) {
      const response = await api.get(`/jira/custom_columns/?team_id=${user.team.id}`);

      jiraColumns = response.data;
    }
    commit('setStaticColumns', staticColumns);
    commit('setJiraColumns', jiraColumns);
    app.trigger('customColumns:init');
  },
  async loadCustomColumns({ commit, rootState }) {
    let customColumns = [];

    if (!rootState.appMode.isBase) {
      if (GT.ganttData.globalCustomColumnsData) {
        customColumns = GT.ganttData.globalCustomColumnsData;
      } else if (GT.ganttData.globalMultiviewCustomColumns) {
        customColumns = GT.ganttData.globalMultiviewCustomColumns;
      } else {
        customColumns = [];
      }
    } else {
      const response = await api.get(`/user_custom_columns/?${Date.now()}`);

      customColumns = helpers.prepareCustomColumnsData(response.data);
    }

    commit('setCustomColumns', customColumns);
  },
  async loadProjectColumnsWithoutValues({ commit, rootState }) {
    let projectColumns = [];
    projectColumns = await helpers.loadProjectColumns(true, rootState);
    commit('setProjectColumns', projectColumns);
  },
  async loadProjectColumns({ commit, rootState }) {
    // setTimeout(async () => {
    //   let projectColumns = [];
    //   projectColumns = await helpers.loadProjectColumns(false, rootState);
    //   commit('setProjectColumnsValues', projectColumns);
    //   commit('setValuesLoaded', true);
    // }, 20000);

    let projectColumns = [];
    projectColumns = await helpers.loadProjectColumns(false, rootState);
    commit('setProjectColumnsValues', projectColumns);
    commit('setValuesLoaded', true);
  },
  async createColumn({ commit }, payload) {
    try {
      const { column, isGlobal } = payload;
      const response = await api.post('/user_custom_columns/', column, {
        headers: { 'Content-type': 'application/json' },
      });
      const responseData = response.data;
      const newColumn = helpers.prepareCustomColumnsData([responseData.column]);

      commit('addCustomColumn', newColumn[0]);
      responseData.data.forEach(project => {
        commit('updateProject', { projectId: project.id, updated: { ...project } });
      });
      app.trigger('userCustomColumnsModel:change', gantt.config.gantt_id, 'create', responseData.column.id, !isGlobal);
    } catch (error) {
      console.error(error);
    }
  },
  async updateColumn({ commit }, payload) {
    try {
      const { column, isShow } = payload;

      column.last_update = moment(column.last_update).format('YYYY-MM-DD HH:mm:ss');
      const response = await api.put(`/user_custom_columns/${column.id}`, column, {
        headers: { 'Content-type': 'application/json' },
      });
      const responseData = response.data;
      const updatedColumn = helpers.prepareCustomColumnsData([responseData.column]);

      commit('updateCustomColumn', { columnId: updatedColumn[0].id, updated: { ...updatedColumn[0] } });
      responseData.data.forEach(project => {
        commit('updateProject', { projectId: project.id, updated: { ...project } });
      });

      app.trigger('userCustomColumnsModel:change', gantt.config.gantt_id, 'update', column.id, isShow);
    } catch (error) {
      console.error(error);
    }
  },
  async removeColumn({ getters, commit }, columnId) {
    try {
      const storeColumn = getters.getCustomColumnById(columnId);
      const response = await api.delete(`/user_custom_columns/${columnId}`, { data: { ...storeColumn } }, {
        headers: { 'Content-type': 'application/json' },
      });
      const responseData = response.data;

      commit('removeCustomColumn', columnId);
      responseData.data.forEach(project => {
        commit('updateProject', { projectId: project.id, updated: { ...project } });
      });

      app.trigger('userCustomColumnsModel:change', gantt.config.gantt_id, 'delete', columnId);
    } catch (error) {
      console.error(error);
    }
  },
  async changeCustomValues({ commit }, payload) {
    const { task, column, value } = payload;
    const isOptions = !!(column.options && column.options.length);
    let newValues = value;

    try {
      if (isOptions) {
        newValues = value ? value.split(',') : [];
      }

      const data = {
        value: newValues,
        taskId: task.id,
        taskName: task.text,
        columnName: column.name,
        columnId: column.id,
        ganttId: task.gantt_id,
        columnType: column.type,
        isOptions,
      };

      // Mutation first
      commit('changeCustomValues', {
        task,
        columnId: column.id,
        value: newValues,
      });

      app.trigger('userCustomValuesModel:change', task);

      await api.put('/task/update/user_custom_value', data, {
        headers: { 'Content-type': 'application/json' },
      });
    } catch (error) {
      console.error(error);
    }
  },
  async removeCustomValue({ getters, commit }, payload) {
    try {
      const { task, columnId } = payload;

      await api.put('/task/remove/user_custom_value', { taskId: task.id, columnId });
      const project = getters.getProjectById(task.gantt_id);

      project.values[task.id] = project.values[task.id].filter(val => val.column_id !== columnId);
      commit('updateProject', { projectId: project.id, updated: { values: project.values } });

      app.trigger('userCustomValuesModel:change', task);
    } catch (error) {
      console.error(error);
    }
  },
  async dynamicLoadValues({ getters, commit }, payload) {
    const { ganttIds } = payload;
    const ganttIdsToLoad = ganttIds.filter(id => !getters.getProjectById(id)?.values);

    try {
      if (!ganttIdsToLoad.length) return;
      const response = await api.post('/project_custom_columns/values', { ganttIds: ganttIdsToLoad });

      for (const ganttId of ganttIdsToLoad) {
        const project = getters.getProjectById(+ganttId);
        
        project && commit('updateProject', { projectId: project.id, updated: { values: response.data[+ganttId] || {} } });
      }
    } catch (error) {
      console.error(error);
    }
  }
};
