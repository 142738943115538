import plansModel from '../models/plans';
import plans from '../models/plans';
import projectsModel from '../models/projects';

const innerObject = {
  overTrialOrSubscription: () => (!plans.isActiveTrial() || !plans.getStatus() || plansModel.isExpiredTrial()) && !plans.isPaidTeam(),
  noProjectMode: () => {
    const allProjects = projectsModel.getAllProjects();

    return (!allProjects || allProjects.length === 0) && plansModel.isActiveAccount();
  },
};

export default innerObject;
