<template>
  <div
    v-if="requiredDataLoaded"
    id="listview-table"
    class="height100"
  >
    <resizable-table
      v-if="config && preparedTasks && preparedHeaders"
      ref="table"
      :headers-show="config.columns"
      :headers="preparedHeaders"
      :static-headers="staticHeaders"
      :prepend-headers="prependHeaders"
      :data-items="preparedTasks"
      :table-height="height"
      :active-task-id="activeTaskId"
      :closed-task-discolor="config.viewSettings.completedTask.includes('discolorationTasks')"
      :closed-task-crossout="config.viewSettings.completedTask.includes('crossOutTasks')"
      :highlight-overdue-task="config.overdue"
      :skins="skins"
      :show-custom-column-info="!viewData.projectId"
      :disable-create-items="!!activeTaskId"
      :select-mode="isBulkChange"
      :type-no-tasks-img="preparedTasks.length ? 'noFiltredTasks' : 'noTasks'"
      @select="onSelectRow"
      @change="changeTask"
      @change:custom="changeTaskCustom"
      @change:show:columns="changeShowColumnsDebounced"
      @show:itemSettings="showTaskSettings"
      @delete:item="showDeleteConfirm"
      @sorted="sortedColumn"
      @create:item="onCreateItem"
      @before:create="onBeforeCreate"
    />

    <confirmPopup
      :show="deleteConfirm.show"
      :text="deleteConfirm.body"
      :title="deleteConfirm.title"
      :ok-button="deleteConfirm.okBtn"
      :cancel-button="deleteConfirm.cancelBtn"
      :popup-width="440"
      @onClickOk="deleteTask"
      @onClickCancel="closeDeleteConfirm"
    />

    <router-view />
  </div>
  <div v-else>
    <div class="height100">
      <skeleton
        :height="height"
      />

      <skeleton-popup
        :show="needTimeForLoading && !requiredDataLoaded"
        :text="skeletonText"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import resizableTable from './table/table.vue';
import routerHelper from '../../helpers/router';
import app from '../../app';
import listToXlsx from '../export/xlsxExportHandlers/listToXlsx';
import columnSorts from './table/sorts';
import _ from '../../libs/lodash';
import projectsModel from '../../models/projects';
import massChangeModel from '../gantt/modules/massChange';
import timeTrackingModel from '../../models/timeTracking';
import multiViewsProjectsModel from '../../models/multiViewsProjects';
import confirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import customHelper from '../../helpers/custom';
import rights from '../../components/rights';
import ListViewController from './controllers';
import listViewConstants from './constants';
import Skeleton from './skeleton/skeletonMain.vue';
import skeletonPopup from '../common/VueComponents/popups/skeletonPopup/skeletonPopup.vue';

export default {
  name: 'ListView',
  components: {
    skeletonPopup,
    Skeleton,
    resizableTable,
    confirmPopup,
  },
  data() {
    return {
      deleteConfirm: {
        title: __('confirm_title_delete_task'),
        body: '',
        okBtn: {
          type: 'destructive',
          title: __('common_yes'),
        },
        cancelBtn: {
          type: 'secondary',
          title: __('common_cancel'),
        },
        show: false,
        taskToDelete: null,
      },
      eventsListeners: [],
      activeTaskId: '',
      config: null,
      viewData: { activeGanttIds: [], projectId: null, multiviewId: null },
      staticHeaders: listViewConstants.COLUMNS.STATIC_COLUMNS,
      prependHeaders: listViewConstants.COLUMNS.PREPEND_COLUMNS,
      sortedByColumn: null,
      listViewId: new Date().getTime(),
      changeShowColumnsDebounced: null,
      preparedTasks: null,
      prepareTasksDebounced: null,
      preparedHeaders: null,
      prepareHeadersDebounced: null,
      needTimeForLoading: false,
      skeletonText: 'listview_loading_popup',
    };
  },
  computed: {
    ...mapGetters({
      isBulkChange: 'headerView/isBulkChange',
      currentRoute: 'headerView/currentRoute',
    }),

    skins() {
      switch (this.config.viewSettings.skins) {
      case 1: return 'skins-horiz';
      case 2: return 'skins-none';
      case 3: return 'skins-vert';
      case 4: return 'skins-all';
      default: return 'skins-all';
      }
    },

    height() {
      const str = this.$store.getters.getWindowParams.height;

      return str ? parseInt(str.match(/\d+/)) : 0;
    },

    tasks() {
      return this.$store.getters['tasksModel/getTasksByGanttIds'](this.viewData.activeGanttIds);
    },

    allCustomColumns() {
      return this.$store.getters['columns/getAllCustomColumns'];
    },

    projectsColumns() {
      return this.$store.getters['columns/getProjectColumnsByIds'](this.viewData.activeGanttIds);
    },

    filterState() {
      return this.$store.getters['filter/isActive'] ? this.$store.getters['filter/filterState'] : null;
    },

    taskRouteName() {
      const routeName = this.currentRoute.name;

      switch (routeName) {
      case 'listView': return 'listTaskRoute';
      case 'project': return 'taskRoute';
      case 'projectsMultiView': return 'multiviewTaskRoute';
      }

      return routeName;
    },
    requiredDataLoaded() {
      let isCusomColumnsValuesLoaded = this.$store.getters['columns/valuesLoaded'];
      const routeName = this.currentRoute.name;

      if (routeName !== 'listView') {
        isCusomColumnsValuesLoaded = true;
      }

      return isCusomColumnsValuesLoaded;
    },
  },
  watch: {
    $route(to, from) {
      this.activeTaskId = +to.params.taskId ? +to.params.taskId : '';
      this.updateViewData();
    },

    tasks(val) {
      this.prepareTasks();

      if (this.deleteConfirm.show && !rights.project.hasRight(this.deleteConfirm.taskToDelete.gantt_id, 'delete_task')) {
        this.closeDeleteConfirm();
      }

      if (this.activeTaskId && !val.find(i => i.id === this.activeTaskId)) {
        app.trigger('taskView:close');
      }
    },

    filterState() {
      this.prepareTasks();
    },

    allCustomColumns() {
      this.prepareHeadersDebounced();
    },

    projectsColumns() {
      this.prepareTasksDebounced();
      this.prepareHeadersDebounced();
    },
  },
  created() {
    this.changeShowColumnsDebounced = _.debounce(this.changeShowColumns.bind(this), 0);
    this.prepareTasksDebounced = _.debounce(this.prepareTasks.bind(this), 500);
    this.prepareHeadersDebounced = _.debounce(this.prepareHeaders.bind(this), 500);
  },
  async beforeMount() {
    this.addEventListeners();

    this.activeTaskId = +this.$route.params.taskId ? +this.$route.params.taskId : '';
    await this.updateViewData();
  },
  beforeDestroy() {
    this.removeEventsListeners();
    this.needTimeForLoading = false;
    this.closeDeleteConfirm();
  },
  mounted() {
    setTimeout(() => {
      this.needTimeForLoading = true;
    }, 5000);
  },
  methods: {
    prepareTasks() {
      this.preparedTasks = ListViewController.getPreparedTasksByParams(this.tasks, this.projectsColumns, this.filterState);
    },

    prepareHeaders() {
      this.preparedHeaders = ListViewController.getPreparedHeadersByParams(this.projectsColumns, this.viewData.activeGanttIds, !!this.viewData.projectId);
    },

    async updateViewData(force = false) {
      const newViewData = { activeGanttIds: [], projectId: null, multiviewId: null };
      const currentRoute = routerHelper.getCurrentRoute();

      if (routerHelper.isListViewRoute()) {
        newViewData.activeGanttIds = projectsModel.getAllProjects().map(p => p.gantt_id);
      }

      if (currentRoute.path.includes('projects/')) {
        newViewData.activeGanttIds = multiViewsProjectsModel.getActiveViewData().ganttIDs;
        newViewData.multiviewId = multiViewsProjectsModel.getActiveGanttId().id;
      }

      if (currentRoute.path.includes('project/')) {
        newViewData.projectId = +routerHelper.getCurrentRoute().params.projectId;
        newViewData.activeGanttIds = [newViewData.projectId];
      }

      if (!newViewData.activeGanttIds.length) {
        newViewData.projectId = null;
        newViewData.multiviewId = null;
      }

      newViewData.activeGanttIds = newViewData.activeGanttIds.filter(ganttId => !projectsModel.isArchived(ganttId));
      newViewData.activeGanttIds.sort();

      if (_.isEqual(this.viewData, newViewData) && !force) {
        return;
      }

      this.config = await ListViewController.getConfig(newViewData.projectId, newViewData.multiviewId);
      this.viewData = newViewData;

      this.prepareHeaders();
    },

    onSelectRow(val) {
      if (this.isBulkChange && val.select) {
        userExtAnalytics.log('masschange_select_task', { from: 'list' });
        val.select.forEach(id => {
          if (!massChangeModel._isTaskSelected(id)) {
            massChangeModel.selectTask(id, true);
          }
        });
      }

      if (this.isBulkChange && val.unselect) {
        userExtAnalytics.log('masschange_unselect_task', { from: 'list' });
        val.unselect.forEach(id => {
          if (massChangeModel._isTaskSelected(id)) {
            massChangeModel.unselectTask(id, true);
          }
        });
      }
    },

    onCreateItem(item) {
      ListViewController.createItem(item, this.viewData.projectId);
    },

    async changeTask(edit) {
      await ListViewController.changeTask(edit);
    },

    changeTaskCustom(edit) {
      ListViewController.changeTaskCustom(edit);
    },

    async deleteTask() {
      await ListViewController.deleteTask(this.deleteConfirm.taskToDelete);
      userExtAnalytics.log('gantt_task_delete_confirmed');

      this.closeDeleteConfirm();
    },

    async changeShowColumns({ columns, extra }) {
      const newColumns = {};

      const selectedColumnsName = columns.map(i => i.property);

      this.prependHeaders.forEach(col => {
        const isShow = selectedColumnsName.find(c => c == col.property);

        newColumns[col.property] = {
          hidden: !isShow,
          custom: !!col.custom,
        };
      });

      this.staticHeaders.forEach(col => {
        newColumns[col.property] = { hidden: false, custom: !!col.custom };
      });

      this.preparedHeaders.forEach(col => {
        const isShow = selectedColumnsName.find(c => c == col.property);

        newColumns[col.property] = {
          hidden: !isShow,
          custom: !!col.custom,
        };

        const ifExtra = extra.find(name => name.includes(col.property));

        if (ifExtra) {
          newColumns[col.property].showTime = true;
        }
      });

      this.config = await ListViewController.updateConfig(this.config, { columns: newColumns });
    },

    showDeleteConfirm(task) {
      this.deleteConfirm.show = true;
      this.deleteConfirm.taskToDelete = task;
      const isJira = !!task.jira_key;
      const bodyKey = `settings_msg_delete_task${isJira ? '_jira' : ''}`;
      const taskName = __(bodyKey, { taskName: `<b>${customHelper.formatTaskName(task.text)}</b>` });

      this.deleteConfirm.body =  `<div>${taskName}</div>`;
    },

    closeDeleteConfirm() {
      this.deleteConfirm.show = false;
      this.deleteConfirm.taskToDelete = null;
    },

    showTaskSettings({ item, section }) {
      if (section) {
        window.taskSettingsData = {
          initOptions: {
            selectComment: section === 'comments',
            selectAttachments: section === 'attachments',
          },
        };
      }

      _.delay(() => {
        routerHelper.pushByNameAndParams(this.taskRouteName, {
          mode: routerHelper.getCurrentRoute().params.mode,
          taskId: item.id,
        });
      });

      userExtAnalytics.log('gantt_task_settings_open', {
        from: routerHelper.analyticsFromRoute(),
        route: `${routerHelper.analyticsRoute()}/ClickOnTasks`,
      });
    },

    sortedColumn(e) {
      this.sortedByColumn = e;
    },

    exportTasks() {
      const task = _.cloneDeep(this.preparedTasks);

      this.sortedByColumn && columnSorts.sort(task, [this.sortedByColumn.coll], [this.sortedByColumn.desc], this.config.columns);

      listToXlsx.export({
        title: __('gantt_tasks'),
        data: task,
      });
      userExtAnalytics.log('my_tasks_export');
    },

    addEventListeners() {
      this.removeEventsListeners();

      addAppEvent('header:export:listView', this.exportTasks, this);
      addAppEvent('reloadModal:show', this.closeDeleteConfirm, this);
      addAppEvent('listView:config:updateSettings', this.onUpdateConfigSettings, this);
      addAppEvent('afterGanttWorkerFinish', this.onAfterGanttWorkerFinish, this);
      addAppEvent('onAfterCollaboration', this.onAfterCollaboration, this);
      addAppEvent('project:archive', this.updateViewData, this);
      addAppEvent('listView:task:updateTimeTracking', this.onUpdateTimeTracking, this);
      addAppEvent('currencyUpdated', this.updateViewData.bind(null, true), this);

      function addAppEvent(eventName, eventHandler, context) {
        const e_id = app.on(eventName, eventHandler.bind(context));

        context.eventsListeners.push(e_id);
      }
    },

    removeEventsListeners() {
      this.eventsListeners.forEach(id => app.off(id));
      this.eventsListeners = [];
    },

    async onUpdateConfigSettings({ settings }) {
      this.config = await ListViewController.updateConfig(this.config, { settings });
    },

    onAfterGanttWorkerFinish() {
      app.trigger('gantt:progress:hide');
    },

    onAfterCollaboration(e) {
      if (e.event === 'ProjectCreated' || e.event === 'ProjectUpdated' || e.event === 'ProjectDeleted') {
        this.updateViewData(true);
        app.trigger('refresh:filter');
      }
    },

    onUpdateTimeTracking(taskId) {
      const task = this.preparedTasks.find(task => task.id === taskId);

      if (task) {
        task.time_tracking = timeTrackingModel.getLogsByParams(task.gantt_id, task.id)?.sum || 0;
      }
    },
  },
};
</script>

<style scoped lang="less">
.height100{
  height: 100%;
}
</style>
