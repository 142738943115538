<template>
  <div>
    <integration-page
      :integration-name="'google'"
      :svg="googleIcon"
      :links="links"
      :is-first-link="isFirstLink"
      @handleDelete="onOkDeletePopup"
      @firstLinkWasOpen="firstLinkWasOpen"
    />
  </div>
</template>

<script>

import googleIcon from '../../../svg/integration/settings/googleCalendarSmall.svg';
import IntegrationPage from '../googleOutlookIntegrations/integrationPage.vue';

export default {
  name: 'GoogleIntegration',
  components: {
    IntegrationPage,
  },
  data() {
    return {
      locale: __,
      googleIcon,
    };
  },
  computed: {
    links() {
      return this.$store.getters['integrationGoogle/googleLinks'] || [];
    },
    isFirstLink() {
      return this.$store.getters['integrationGoogle/isFirstLink'] || false;
    },

  },
  methods: {

    onOkDeletePopup(link) {
      this.$store.dispatch('integrationGoogle/deleteLink', link);
      userExtAnalytics.log('integration_google_calendar_deleted');
    },
    firstLinkWasOpen() {
      this.$store.commit('integrationGoogle/setFirstLink', false);
    },
  },
};
</script>

<style module="$" src="../googleOutlookIntegrations/googleOutlookIntegration.less"></style>
