var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$.chart, _vm.$.chart_milestones] }, [
    _c(
      "div",
      { class: _vm.$.header },
      [
        _c("div", { class: _vm.$.title }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("chart_" + _vm.chart.key + "_title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.statusSwitch
          ? _c("vgp-switch", {
              attrs: { "is-toggle-on": _vm.chart.status },
              on: {
                onChange: function ($event) {
                  return _vm.$emit("onToggleStatus")
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.description
      ? _c("div", { class: _vm.$.description }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.locales("chart_" + _vm.chart.key + "_description")) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wrapper },
      [
        _c("div", { class: _vm.$.row }, [
          _c("div", { class: _vm.$.col }, [
            _c("div", { ref: "chart", class: _vm.$.body }),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.$.footer, _vm.isShowStub ? _vm.$.invisible : ""] },
              [
                _c("div", { class: _vm.$.item }, [
                  _c("div", { class: _vm.$.label }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.locales("overview_total_milestones")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.value }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.data.total) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.item }, [
                  _c("div", { class: _vm.$.label }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.locales("overview_overdue_milestones")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.value }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.data.overdue) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$.col }, [
            _c(
              "div",
              { class: [_vm.$.table, this.static ? _vm.$.static : ""] },
              [
                _c(
                  "div",
                  {
                    class: _vm.$.table_head,
                    style: _vm.scrollbarWidth
                      ? "margin-right: " + _vm.scrollbarWidth + "px;"
                      : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.table_cell,
                          _vm.$.cell_name,
                          _vm.$.text_ellipsis,
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("overview_milestone")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.table_cell,
                          _vm.$.cell_date,
                          _vm.$.text_ellipsis,
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("overview_date")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.table_cell,
                          _vm.$.cell_status,
                          _vm.$.text_ellipsis,
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("overview_status")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "tableBody",
                    class: [_vm.$.table_body, "gantt_scroll"],
                  },
                  [
                    _c(
                      "div",
                      { ref: "tableInner" },
                      _vm._l(_vm.data.milestones, function (milestone, index) {
                        return _c(
                          "div",
                          {
                            key: milestone.id,
                            ref: "tableRow",
                            refInFor: true,
                            class: _vm.$.table_row,
                            attrs: { id: milestone.id },
                          },
                          [
                            _c("tooltip", {
                              class: [_vm.$.table_cell, _vm.$.cell_name],
                              attrs: {
                                content: "Project: " + milestone.projectName,
                                position: "top",
                                delay: 250,
                                triggerTarget:
                                  _vm.$refs.tableRow &&
                                  _vm.$refs.tableRow[index],
                                hide: !milestone.projectName,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          { class: _vm.$.text_ellipsis },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(milestone.text) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.table_cell,
                                  _vm.$.cell_date,
                                  _vm.$.text_ellipsis,
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(milestone.formatDate) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.table_cell,
                                  _vm.$.cell_status,
                                  _vm.$.text_ellipsis,
                                ],
                                style: "color: " + milestone.statusColor + ";",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(milestone.statusName) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.isShowStub
          ? _c("div", { class: _vm.$.stub }, [
              _c("div", { class: _vm.$.stub_item }),
              _vm._v(" "),
              _c("div", { class: _vm.$.stub_text }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.locales("chart_" + _vm.chart.key + "_stub_text")
                    ) +
                    "\n      "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.isShowLoader } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }