<template>
  <section :class="$style.section">
    <div
      ref="sectionWrapper"
      :class="[
        $style.section__wrapper,
        firstInitAnimation ? $style.section__wrapper_firstInitAnimation : '',
        ...animationClasses
      ]"
    >
      <wizard-left-section
        :title="title"
        :description="description"
        :animate-leave="leftSectionAnimationLeave"
        :animate-enter="leftSectionAnimationEnter"
      >
        <template>
          <label
            id="gp_autotest_onboarding_wizard_1_step_fileImport_dropZone"
            ref="dropZone"
            for="file"
            :class="[$style.dropzone, ...animationClasses]"
          >
            <input
              id="gp_autotest_onboarding_wizard_1_step_file_input"
              ref="fileUpload"
              name="file"
              type="file"
              :class="$style.fileInput"
              accept=".mpp"
              @change="handleChangeFileInput"
            >
            <template v-if="!isFileLoading">
              <vgp-button
                id="gp_autotest_onboarding_wizard_1_step_fileImport_button"
                :class="$style.importButton"
                type="secondary"
                :label="locales('onboarding_import_mpp_btn_label')"
                :icon="{name: 'import', type: 'bold'}"
                icon-side="left"
                @onClick="$refs.fileUpload.click()"
              />
            </template>
            <template v-else>
              <div :class="$style.fileProgressWrap">
                <v-progress-circular
                  width="4"
                  size="36"
                  indeterminate
                  color="#337870"
                />
                <span v-if="showImportLabelNotification">
                  {{ locales('import_label_notification') }}
                </span>
              </div>
            </template>
          </label>
        </template>
      </wizard-left-section>
      <div :class="$style.section__right">
        <div
          :class="[
            $style.right__wrapper,
            ...animationClasses
          ]"
        >
          <div
            :class="[
              $style.sidebar,
              ...animationClasses
            ]"
          >
            <div
              ref="sidebarHead"
              :class="[
                $style.sidebar__head,
                ...animationClasses
              ]"
            >
              <div
                ref="sidebarCompanyLogo"
                :class="[
                  $style['sidebar__company-logo'],
                  ...animationClasses
                ]"
              >
                <img
                  v-if="teamLabel.favicon"
                  :src="teamLabel.favicon"
                  alt="Company logo"
                >
              </div>
              <div
                ref="sidebarCompanyName"
                :class="[
                  $style['sidebar__company-name'],
                  !teamLabel.favicon ? 'order-first ml-0' : '',
                  ...animationClasses
                ]"
              >
                {{ teamLabel.name || '' }}
              </div>
            </div>
            <div
              ref="projectNameForm"
              :class="[
                $style.sidebar__form,
                ...animationClasses
              ]"
            >
              <div :class="$style.sidebar__input">
                <div :class="$style.sidebar__input_label">
                  {{ locales('onboarding_project_name_label') }}
                </div>
                <vgp-input
                  id="gp_autotest_onboarding_wizard_1_step_projectName_input"
                  ref="projectNameInput"
                  v-model.trim="projectName"
                  maxlength="250"
                  :is-disabled="isFileLoading"
                  :placeholder="locales('onboarding_create_project_placeholder')"
                  type="text"
                  small
                />
              </div>
              <vgp-button
                id="gp_autotest_onboarding_wizard_1_step_createProject_button"
                type="green"
                :class="$style.sidebar__button"
                :disabled="isFileLoading"
                :label="locales('onboarding_create_project_btn_label')"
                small
                @onClick="nextStep"
              />
            </div>

            <ul
              :class="[
                $style.sidebar__icons,
                ...animationClasses
              ]"
            >
              <li
                v-for="(iconConfig, index) in sidebarIcons"
                :key="index"
              >
                <vgpSvgImage v-bind="iconConfig" />
              </li>
            </ul>
          </div>
          <div
            :class="[
              $style.main,
              ...animationClasses
            ]"
          >
            <wizard-right-section-header
              :title="projectName || locales('onboarding_project_name_default_name')"
              :active-nav-item="isFourthWizardStep ? 'people' : 'diagram'"
            />
            <!-- 1-2 WIZARD STEP STARTS -->
            <div
              ref="tasksWrapper"
              :class="[
                $style.tasks__wrapper,
                ...animationClasses
              ]"
            >
              <div
                v-for="(task, index) in visibleTasks"
                :key="index"
                :class="[
                  $style.task__block,
                  ...animationClasses
                ]"
              >
                <span :class="$style.task__index">
                  {{ index + 1 }}
                </span>
                <vgp-input
                  :id="`gp_autotest_onboarding_wizard_2_step_task_${index + 1}_input`"
                  ref="taskInputs"
                  v-model.trim="task.name"
                  :is-disabled="isInputDisabled(index)"
                  :class="[$style.task__item, isInputDisabled(index) ? $style.customDisableInput : '']"
                  :placeholder="task.defaultName"
                  small
                  type="text"
                  @keyup.native.enter="handleOnTaskEnterPressed(index)"
                />
                <div :class="$style.task__arrow">
                  <vgpSvgImage
                    :name="'onboarding-task-link'"
                    color="#D9E2E4"
                  />
                </div>
              </div>
              <div
                v-for="i in 2"
                :key="i + visibleTasks.length"
                :class="[
                  $style.task__block,
                  ...animationClasses
                ]"
              >
                <span :class="$style.task__index">
                  {{ i + visibleTasks.length + 1 }}
                </span>
                <div :class="[$style.task__item]" />
              </div>
            </div>
            <!-- 1-2 WIZARD STEP ENDS -->

            <!-- 3 WIZARD STEP STARTS -->
            <!--          <div-->
            <!--            ref="subtasksWrapper"-->
            <!--            :class="[-->
            <!--              $style.subtasks__wrapper,-->
            <!--              ...animationClasses-->
            <!--            ]"-->
            <!--          >-->
            <!--            <div-->
            <!--              v-for="(task, taskIndex) in visibleTasks"-->
            <!--              :key="taskIndex"-->
            <!--              :class="$style.subtasks__task"-->
            <!--            >-->
            <!--              <div-->
            <!--                :class="[-->
            <!--                  $style.subtasks__task_wrap,-->
            <!--                  ...animationClasses-->
            <!--                ]"-->
            <!--              >-->
            <!--                <div :class="$style.subtasks__task_number">-->
            <!--                  {{ taskIndex + 1 }}-->
            <!--                </div>-->
            <!--                <div :class="$style.subtasks__task_name">-->
            <!--                  {{ task.name || task.defaultName }}-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-for="(subtask, subtaskIndex) in task.subtasks"-->
            <!--                :key="taskIndex + subtaskIndex + 1"-->
            <!--                :class="[-->
            <!--                  $style.subtasks__subtask,-->
            <!--                  ...animationClasses-->
            <!--                ]"-->
            <!--              >-->
            <!--                <div :class="[$style.subtasks__subtask_number]">-->
            <!--                  {{ `${taskIndex + 1}.${subtaskIndex + 1}` }}-->
            <!--                </div>-->
            <!--                <vgp-input-->
            <!--                  v-model="subtask.name"-->
            <!--                  :class="$style.subtasks__subtask_input"-->
            <!--                  type="text"-->
            <!--                  small-->
            <!--                  :placeholder="subtask.defaultName"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="taskIndex !== tasks.length - 1"-->
            <!--                :class="[-->
            <!--                  $style.subtasks__divider,-->
            <!--                  ...animationClasses-->

            <!--                ]"-->
            <!--              />-->
            <!--            </div>-->
            <!--          </div>-->
            <!-- 3 WIZARD STEP ENDS -->

            <!-- 4 WIZARD STEP STARTS -->

            <ValidationObserver
              ref="emailsForm"
              tag="div"
              :class="[
                $style.teammates__wrapper,
                ...animationClasses
              ]"
            >
              <div
                v-for="(teammate, index) in teammates"
                :key="index"
                :class="$style.teammate__wrap"
              >
                <vgpSvgImage
                  :name="'onboarding-team'"
                  color="#CED9DC"
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="emailValidationProvider"
                  slim
                  :rules="emailRules"
                  :custom-messages="{
                    email: locales('onboarding_email_validation_error'),
                    is_not: locales('onboarding_email_validation_error')
                  }"
                  :vid="`email${index}`"
                  :mode="() => ({ on: ['onBlur'] })"
                >
                  <vgp-input
                    :id="`gp_autotest_onboarding_wizard_3_step_teammate_${index + 1}_input`"
                    ref="teammateEmails"
                    v-model="teammate.email"
                    :class="$style.teammate__input"
                    :is-error="!!errors[0]"
                    :is-disabled="!isFourthWizardStep"
                    type="text"
                    small
                    :placeholder="locales('onboarding_email_placeholder')"
                    @onFocus="handleOnFocusEmail(index)"
                  />
                  <span
                    v-if="errors[0]"
                    :class="$style.teammate__input_error"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </ValidationObserver>

            <!-- 4 WIZARD STEP ENDS -->

            <div :class="$style['main__vertical-right-blur']" />
            <div
              :class="[
                $style['main__horizontal-header-blur'],
                ...animationClasses
              ]"
            />
          </div>

          <div
            v-if="!isFirstWizardStep"
            :class="[
              $style.submit__button,
              leftSectionAnimationEnter ? $style.animateEnter : '',
              leftSectionAnimationLeave ? $style.animateLeave : '',
            ]"
          >
            <vgp-button
              :id="`gp_autotest_onboarding_${activeStepId}_submit_button`"
              :type="submitButtonType"
              :label="submitButtonText"
              :is-loading="isValidatingEmails"
              small
              @onClick="nextStep"
            />
          </div>
        </div>
      </div>
    </div>
    <project-preparation-loader
      id="gp_autotest_onboarding_wizard_project_preparation_loader"
      :class="[$style.section__loader, ...animationClasses]"
    />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { extend } from 'vee-validate';
import { email, is_not } from 'vee-validate/dist/rules';
import { api } from '$$store/api';
import WizardRightSectionHeader from './WizardRightSectionHeader.vue';
import importOuterObject from '../../../newProject/import/main';
import WizardLeftSection from './WizardLeftSection.vue';
import ProjectPreparationLoader from '../ProjectPreparationLoader.vue';
import { createProjectWithTasksAndTeammates } from '../../helpers';

import { ANALYTIC_ACTIONS } from '../../onboarding.config';
import rights from '../../../../components/rights/index';
import constants from '../../../../helpers/constants';
import vgpSvgImage from '../../../common/VueComponents/VueIcon/vgpSvgImage.vue';

extend('email', email);
extend('is_not', is_not);

const PROJECT_NAME_MAX_LENGTH = 250;
const SECONDS_FOR_SHOWING_IMPORT_NOTIFICATION = 7;

export default {
  name: 'WizardMain',
  components: {
    ProjectPreparationLoader,
    WizardLeftSection,
    WizardRightSectionHeader,
    vgpSvgImage,
  },
  props: {
    teamLabel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      locales: __,
      isFileLoading: false,
      showImportLabelNotification: false,
      isProjectPreparing: false,
      isValidatingEmails: false,
      firstInitAnimation: false,
      title: null,
      description: null,
      leftSectionAnimationEnter: true,
      leftSectionAnimationLeave: false,
      submitButtonType: 'text-green',
      submitButtonText: __('onboarding_skip_step_btn_label'),
      sidebarIcons: [
        {
          name: 'onboarding-house',
          color: '#78909C',
        },
        {
          name: 'onboarding-triangle',
          color: '#CED9DC',
        },
        {
          name: 'onboarding-square',
          color: '#CED9DC',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tasks: 'onboarding/getProjectTasks',
      teammates: 'onboarding/getTeammates',
      currentStepConfig: 'onboarding/getCurrentStepConfig',
    }),
    ...mapState('onboarding', ['activeStepId', 'analyticType']),
    emailRules() {
      return {
        email: true,
        is_not: user.email,
      };
    },
    visibleTasks() {
      return this.tasks.filter(task => !task.hidden);
    },
    isFirstWizardStep() {
      return this.activeStepId === 'wizardFirstProject';
    },
    isSecondWizardStep() {
      return this.activeStepId === 'wizardFirstTask';
    },
    // isThirdWizardStep() {
    //   return this.activeStepId === 'wizardFirstSubtask';
    // },
    isFourthWizardStep() {
      return this.activeStepId === 'wizardFirstTeammate';
    },
    isLastWizardStep() {
      return this.isFourthWizardStep;
    },
    amountOfFilledTasks() {
      return this.visibleTasks.filter(task => Boolean(task.name)).length;
    },
    // isAnySubtaskFilled() {
    //   const allSubtasks = this.visibleTasks.map(task => task.subtasks)
    //     .flat();
    //
    //   return allSubtasks.some(subtask => Boolean(subtask.name));
    // },
    amountOfFilledEmails() {
      return this.teammates.filter(teammate => teammate.email).length;
    },
    animationClasses() {
      if (this.isSecondWizardStep) {
        return [this.$style.secondAnimationState];
      }

      // if (this.isThirdWizardStep) {
      //   return [this.$style.secondAnimationState, this.$style.thirdAnimationState];
      // }

      if (this.isFourthWizardStep && !this.isProjectPreparing) {
        return [this.$style.secondAnimationState, this.$style.thirdAnimationState, this.$style.fourthAnimationState];
      }

      if (this.isProjectPreparing) {
        return [
          this.$style.secondAnimationState,
          this.$style.thirdAnimationState,
          this.$style.fourthAnimationState,
          this.$style.lastAnimationState,
        ];
      }

      return [];
    },
    projectName: {
      get() {
        return this.$store.getters['onboarding/getProjectName'];
      },
      set(val) {
        if (val.length <= PROJECT_NAME_MAX_LENGTH) {
          this.$store.commit('onboarding/updateProjectName', val);
        }
      },
    },
  },
  watch: {
    isProjectPreparing(val) {
      if (val) {
        this.removeElementFromDOMIfZeroOpacity('sectionWrapper');
      }
    },
    tasks: {
      handler() {
        this.setSubmitButtonType();
      },
      deep: true,
    },
    teammates: {
      handler() {
        this.setSubmitButtonType();
      },
      deep: true,
    },
    submitButtonType() {
      this.setSubmitButtonText();
    },
    activeStepId: {
      handler() {
        // 300 = animation time for button leave animation
        setTimeout(() => {
          this.setSubmitButtonType();
          this.setSubmitButtonText();
        }, 300);

        if (this.isFirstWizardStep) {
          this.$nextTick(() => {
            this.$refs.projectNameInput.$refs.input_field_icons.focus();
            this.initDropZone();
          });
        }

        if (this.isSecondWizardStep) {
          setTimeout(() => {
            this.$refs.taskInputs[0].$refs.input_field_icons.focus();
          }, 1400);
          this.removeElementFromDOMIfZeroOpacity('projectNameForm');
          this.removeElementFromDOMIfZeroOpacity('sidebarCompanyName');
          this.removeElementFromDOMIfZeroOpacity('dropZone');
        }

        // if (this.isThirdWizardStep) {
        //   this.removeElementFromDOMIfZeroOpacity('tasksWrapper');
        // }

        if (this.isFourthWizardStep) {
          // this.removeElementFromDOMIfZeroOpacity('subtasksWrapper');
          this.removeElementFromDOMIfZeroOpacity('tasksWrapper');
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.title = this.currentStepConfig.title;
    this.description = this.currentStepConfig.description;
  },
  mounted() {
    setTimeout(() => {
      this.firstInitAnimation = true;
    }, 0);
  },
  methods: {
    setSubmitButtonType() {
      if (this.isSecondWizardStep) {
        this.submitButtonType = this.amountOfFilledTasks > 0 ? 'green' : 'text-green';

        return;
      }

      if (this.isFourthWizardStep) {
        this.submitButtonType = this.amountOfFilledEmails > 0 ? 'green' : 'text-green';

        return;
      }

      this.submitButtonType = 'text-green';
    },
    setSubmitButtonText() {
      if (this.submitButtonType === 'green') {
        this.submitButtonText = this.isFourthWizardStep
          ? __('onboarding_lets_start_btn_label')
          : __('onboarding_continue_btn_label');

        return;
      }

      this.submitButtonText = __('onboarding_skip_step_btn_label');
    },
    handleOnTaskEnterPressed(index) {
      const nextInputRefIndex = index + 1;

      if (nextInputRefIndex === this.visibleTasks.length) {
        return;
      }

      const nextInput = this.$refs.taskInputs[nextInputRefIndex];

      if (!this.isInputDisabled(nextInputRefIndex) && nextInput) {
        nextInput.$refs.input_field_icons.focus();
      }
    },
    initDropZone() {
      const dropArea = this.$refs.dropZone;

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      dropArea.addEventListener('drop', this.handleDrop);
    },
    startImport(file) {
      const type = file.name.split('.').pop();

      if (type !== 'mpp') {
        this.$toast.warning(__('import_check_the_file'));

        return;
      }

      this.isFileLoading = true;
      setTimeout(() => {
        this.showImportLabelNotification = true;
      }, SECONDS_FOR_SHOWING_IMPORT_NOTIFICATION * 1000);

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.IMPORT_STARTED });
      }

      try {
        importOuterObject.newProjectByImportFromOnboardingWizard(file);
      } catch (e) {
        this.isFileLoading = false;
        this.showImportLabelNotification = false;
      }
    },
    handleDrop(e) {
      this.startImport(e.dataTransfer.files[0]);
    },
    handleChangeFileInput() {
      this.startImport(this.$refs.fileUpload.files[0]);
    },
    removeElementFromDOMIfZeroOpacity(refName) {
      const element = this.$refs[refName];
      const interval = setInterval(() => {
        const opacity = window.getComputedStyle(element).getPropertyValue('opacity');

        if (Number(opacity) === 0) {
          element.remove();
          clearInterval(interval);
        }
      }, 50);
    },
    isInputDisabled(index) {
      if (this.isFirstWizardStep) {
        return true;
      }

      if (index === 0) {
        return false;
      }

      return index > this.amountOfFilledTasks;
    },
    async nextStep() {
      if (this.isValidatingEmails || this.isProjectPreparing || this.leftSectionAnimationLeave) {
        return;
      }

      if (!this.isLastWizardStep) {
        this.sendAnalytic();
        this.$store.dispatch('onboarding/nextStep');
        this.changeLeftSectionContentAndAnimate();

        return;
      }

      this.isValidatingEmails = true;

      const isValidEmails = await this.isValidTeammatesEmails();

      this.isValidatingEmails = false;

      if (!isValidEmails) return;

      this.sendAnalytic();

      try {
        await this.createProject();
        await this.finishOnboarding();
      } catch (e) {
        console.error(e);
      } finally {
        this.isProjectPreparing = false;
      }
    },
    async createProject() {
      try {
        this.isProjectPreparing = true;
        await createProjectWithTasksAndTeammates(this.$store.state.onboarding.projectData);
        userExtAnalytics.log('new_first_project_automatically_created_from_general_onboarding');
        userExtAnalytics.log('project_create_done');

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async finishOnboarding() {
      const { scoring } = await this.$store.dispatch('onboarding/finish');

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: 'finish', action: ANALYTIC_ACTIONS.DONE });
        userExtAnalytics.log('project_goal');
      }

      // if (scoring.user_scoring_percent >= constants.MIN_USER_SCORING_PERCENT_FOR_DEMO_SCHEDULE) {
      if ((user.isDemoTarget && user.settings.user_team && user.settings.user_team >= 4 && user.settings.user_planning_use_for !== 'study') || (user.settings.gp_utm && user.settings.gp_utm.includes('utm_medium=cpc'))) {
        // go to book demo step
        this.$store.dispatch('onboarding/nextStep');
        userExtAnalytics.log('demo_suggestion_shown', { type: 'onboarding' });

        return;
      }

      this.$store.dispatch('user/updateActivityStatus', {
        activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
        status: 1,
      });

      await this.$router.push({ name: 'app' });
    },
    changeLeftSectionContentAndAnimate() {
      this.leftSectionAnimationEnter = false;
      this.leftSectionAnimationLeave = true;
      setTimeout(() => {
        this.leftSectionAnimationLeave = false;
        this.title = this.currentStepConfig.title;
        this.description = this.currentStepConfig.description;
      }, 350);
      setTimeout(() => {
        this.leftSectionAnimationEnter = true;
      }, 1000);
    },
    async isValidTeammatesEmails() {
      if (this.amountOfFilledEmails === 0) {
        return true;
      }

      this.$refs.emailsForm.reset();
      const allEmails = this.teammates.map(teammate => teammate.email);
      const areAllEmailsUnique = this.teammates.every(({ email: emailToCheck }) => {
        // eslint-disable-next-line no-shadow
        const isEmailUnique = allEmails.filter(email => email === emailToCheck).length === 1;

        if (!isEmailUnique && emailToCheck !== '') {
          // eslint-disable-next-line no-shadow
          const lastIndexOfRepeatedEmail = allEmails.findLastIndex(email => email === emailToCheck);

          this.$refs.emailValidationProvider[lastIndexOfRepeatedEmail].setErrors([__('onboarding_email_validation_error')]);

          return false;
        }

        return true;
      });

      if (!areAllEmailsUnique) {
        return false;
      }

      const isValidEmailsFormats = await this.$refs.emailsForm.validate();

      if (!isValidEmailsFormats) {
        return false;
      }

      const isValidEmailsSparkpost = await this.validateEmailsViaSparkpost();

      if (!isValidEmailsSparkpost) {
        return false;
      }

      return true;
    },
    async validateEmailsViaSparkpost() {
      const promises = this.teammates.map(item => {
        if (item.email) {
          return api.post('/sparkpost/validate', { email: item.email });
        }

        return null;
      });

      const responses = await Promise.all(promises);

      let allEmailsValid = true;

      this.$refs.emailsForm.reset();
      responses.forEach((response, index) => {
        if (!response) {
          return;
        }

        const { valid } = response.data;

        if (!valid) {
          allEmailsValid = false;
          this.$refs.emailValidationProvider[index].setErrors([__('onboarding_email_validation_error')]);
        }
      });

      return allEmailsValid;
    },
    sendAnalytic() {
      const analyticActionDoneData = {
        type: this.analyticType,
        step: this.activeStepId,
        action: ANALYTIC_ACTIONS.DONE,
      };

      if (this.isSecondWizardStep && this.amountOfFilledTasks > 0) {
        Object.assign(analyticActionDoneData, { tasksCount: this.amountOfFilledTasks });
      }

      if (this.isFourthWizardStep && this.amountOfFilledEmails > 0) {
        Object.assign(analyticActionDoneData, { teammatesCount: this.amountOfFilledEmails });
      }

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', analyticActionDoneData);
      }

      if (this.isSecondWizardStep && this.amountOfFilledTasks === 0) {
        if (rights.account.isOwner()) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
        }

        return;
      }

      if (this.isFourthWizardStep && !this.amountOfFilledEmails) {
        if (rights.account.isOwner()) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
        }
      }
    },
    handleOnFocusEmail(index) {
      this.disableGoogleSuggestionsForEmail(index);
      this.$refs.emailValidationProvider[index].reset();
    },
    disableGoogleSuggestionsForEmail(index) {
      this.$refs.teammateEmails[index].$refs.input_field_icons.setAttribute('readonly', '');
      setTimeout(() => {
        this.$refs.teammateEmails[index].$refs.input_field_icons.removeAttribute('readonly');
      }, 0);
    },
  },
};
</script>

<style module lang="less" src="../../less/wizardMain.less">

</style>
