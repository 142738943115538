import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
  // cspNonce: 'dQw4w9WgXcQ', //https://vuetifyjs.com/en/features/theme/#csp-nonce
  icons: {
    iconfont: 'mdi',
  },
};

export default new Vuetify(opts);
