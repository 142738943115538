import app from '../../../app';
import globalStore from '../../../store/main';
import _ from '../../../libs/lodash';
import ganttViewModel from '../../../models/ganttViewModel';
import {
  prepareTaskToRefresh,
  refreshParents,
  updateButtonInTask,
} from './helpers';
// import projectsModel from "../../../models/projects";

import massChangeModel from '../../../views/gantt/modules/massChange';

function _refreshUserCustomColumnsAfterCreateTask(task) {
  const id = +task.id;
  const gantt_id = +task.projectId;

  if (task.customFields && task.customFields.length) {
    task.customFields.forEach(item => {
      let value;

      if (item.value !== null) {
        if (item.type === 'checkbox') {
          item.value = item.value ? 1 : 0;
        }
        if (Array.isArray(item.value)) {
          value = item.value.map(el => el.id);
        } else if (typeof item.value === 'object') {
          value = item.value.id;
        } else {
          value = item.value;
        }

        globalStore.commit('columns/changeCustomValues', {
          task: { id, gantt_id },
          columnId: +item.id,
          value,
        });
      }
    });

    refreshParents(id);
  }
}

function onTaskCreated(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  // const allGanttIds = projectsModel.getAllProjects().map(o => o.id);
  const tasksMap = {};
  let queue = [];
  let qs = 0;
  let qe = 0;
  const needClearAndParseContext = { needClearAndParse: false };

  event.data.tasks.forEach(task => {
    tasksMap[task.id] = {
      status: true,
      nodes: [],
    };
  });

  event.data.tasks.forEach(task => {
    // if (!allGanttIds.includes(task.projectId)) {
    //   return;
    // }
    if (!tasksMap[task.parent]) {
      queue.push(task);
      qe++;
    } else {
      tasksMap[task.parent].nodes.push(task);
    }
  });

  gantt._dp?.setUpdateMode('off');

  gantt.batchUpdate(() => {
    while (qs < qe) {
      const task = queue[qs];

      queue = [...queue, ...tasksMap[task.id].nodes];
      qe = queue.length;

      const isSameProjectOpen = _.includes(ganttIDs, +task.projectId);
      const taskToUpdate = prepareTaskToRefresh(task);
      const taskToUpdateForModel = _.cloneDeep(taskToUpdate);

      if (task.type === 'project') {
        taskToUpdate.open = 1;
        taskToUpdateForModel.open = 1;
      }

      globalStore.dispatch('tasksModel/updateCustomValues', {
        taskId: taskToUpdate.id,
        ganttId: taskToUpdate.gantt_id,
        newValue: {
          status: taskToUpdate.status,
          priority: taskToUpdate.priority,
        },
      });

      if (isSameProjectOpen) {
        const isTaskExistInFullOrder = gantt.$data.tasksStore.fullOrder.includes(taskToUpdate.id);

        taskToUpdate.callaborationAdd = true;
        // gantt.silent is necessary so that incoming tasks are not duplicated
        gantt.silent(() => {
          gantt.createTask(taskToUpdate, taskToUpdate.parent, taskToUpdate.sortorder);

          if (!isTaskExistInFullOrder) {
            gantt.$data.tasksStore.fullOrder.push(taskToUpdate.id);
          }
        }); //

        updateButtonInTask(taskToUpdate.parent, taskToUpdate.gantt_id, needClearAndParseContext);
        delete taskToUpdate.callaborationAdd;// because sometimes this prop does not delete in onBeforeDataSending
        // needClearAndParseContext.needClearAndParse = true;
        gantt.refreshData();
      }

      taskToUpdateForModel.isAPI = !!(event.innerSettings && event.innerSettings.isAPI);
      taskToUpdate.isAPI = !!(event.innerSettings && event.innerSettings.isAPI);
      globalStore.dispatch('tasksModel/updateSortorderTasksAfterAddTask', {
        ganttId: taskToUpdateForModel.gantt_id,
        newTaskData: taskToUpdateForModel,
      });
      globalStore.commit('tasksModel/addTask', taskToUpdateForModel);

      task.links.forEach(link => {
        if (!gantt.getLink(+link.id)) {
          const _link = link;

          _link.gantt_id = task.projectId;
          _link.is_removed = 0;
          globalStore.commit('tasksModel/addLink', _link);
          app.trigger('tasks:model:addLink', _link.id, _link);
        }
      });
      task.attachments.length && task.attachments.forEach(attachment => globalStore.dispatch('attachments/onWSAttachmentCreated', { projectId: attachment.projectId, attachment }));
      task.comments.length && task.comments.forEach(comment => globalStore.dispatch('comments/onWSCommentCreated', { projectId: comment.projectId, comment }));

      _refreshUserCustomColumnsAfterCreateTask(task);
      if (event.requestId && event.requestId.slice(36) === 'jira') {
        webix.ajax().get(`/jira/custom_columns/${task.id}`);
      }
      qs++;
    }
  });

  if (gantt.config.highlight_critical_path) {
    gantt.callEvent('recalculateCriticalPath');
  }

  gantt._dp?.setUpdateMode('cell');

  if (massChangeModel.isEnabled()) {
    massChangeModel.updateFiltredTasksForGantt();
  }

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.tasks.map(i => i.id),
    tasksData: event.data.tasks,
    projects: event.data.tasks.map(i => i.projectId),
  });

  !gantt.is_workload_mode && needClearAndParseContext.needClearAndParse && gantt.callEvent('ganttClearAndParse', []);
}

export default onTaskCreated;
