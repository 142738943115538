import collaborationMethods from './methods';
import CollabBlocker from './blocker';

class Manager {
  pushEvent(event) {
    if (CollabBlocker.active && CollabBlocker.isNeedBlockEvent(event)) {
      CollabBlocker.collectEventData(event);

      if (CollabBlocker.checkEventIsNeedEmergencyUnblockCollab(event)) {
        CollabBlocker.unblock();
      }
    } else {
      collaborationMethods[`on${event.event}`](event);
    }
  }
}

export default new Manager();
