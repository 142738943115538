<template>
  <core-popup
    :show="show"
  >
    <template #body>
      <div
        ref="popup"
        :class="[$style.template_popup ]"
        :style="{width: '440px'}"
      >
        <div
          ref="popup_container"
          :class="[$style.template_popup_container ]"
        >
          <div
            :class="[$style.header]"
          >
            <div :class="[$style.header__title]">
              <span>
                {{ locales('new_template_title') }}
              </span>
            </div>
            <div>
              <vgp-icon-button
                :class="[
                  $style.header__info_icon,
                  !helpActivityStatus ? $style.not_shown : '',
                ]"
                size="small"
                :icon="{ name:'help', type:'regular', size: 24 }"
                :colors="buttonColors"
                @onClick.stop="onClickInfo"
              />
              <vgp-icon-button
                size="small"
                :colors="buttonColors"
                :icon="{ name:'close-1', type:'bold', size: 24 }"
                @onClick="$emit('onClickCancel')"
              />
            </div>
          </div>
          <div
            :class="[$style.body]"
          >
            {{ locales('new_template_description') }}
          </div>

          <div
            :class="[$style.input]"
          >
            <div
              :class="[$style.input_title]"
            >
              {{ locales('new_template_input_label') }}
            </div>
            <vgp-input
              v-model="templateName"
              :class="[$style.input_field]"
              :border="true"
              small
              :is-error="templateName.length >= 255"
              :maxlength="255"
              :placeholder="locales('new_template_input_placeholder')"
            />
            <span
              v-if="templateName.length >= 255"
              :class="$style.input_error_message"
            >
              {{ locales('new_template_input_limit_exceeded') }}
            </span>
          </div>
          <div :class="[$style.footer]">
            <div
              :class="[$style.share_checkbox]"
              @click="handleCheckbox"
            >
              <vgp-checkbox
                :class="[$style.checkbox]"
                size="16"
                :checked="shareTemplate"
                @onChange="handleCheckbox"
              />
              <div :class="[$style.label]">
                {{ locales('new_template_share_label') }}
              </div>
            </div>
            <div>
              <vgp-button
                type="primary"
                :small="true"
                :label="locales('common_save')"
                :disabled="disableButton"
                @onClick="onClickOk"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>

import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import { internalApi } from '$$store/api';
import constants from '$$helpers/constants';
import projectsModel from '../../models/projects';
import helpPopup from '../help/helpPopup';
import app from '../../app';
import templatesModel from '../../models/templates';

export default {
  name: 'SaveTemplatePopup',
  components: {
    CorePopup,
    vgpIconButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      locales: __,
      buttonColors: {
        color: '#808080',
        hoverBg: '#F8F8F8',
      },
      projectId: null,
      projectSkin: null,
      templateName: '',
      shareTemplate: false,
      eventsListeners: [],
    };
  },
  created() {
    const projectData = projectsModel.getActiveProjectData();

    this.projectId = projectData.gantt_id;
    this.projectSkin = projectData.skin;

    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventsListeners();
  },
  computed: {
    disableButton() {
      let flag = true;

      if (this.templateName.trim()) {
        flag = false;
      }

      return flag;
    },
    helpActivityStatus() {
      return !!this.$store.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.HELP_USER_TEMPLATES);
    }
  },
  methods: {
    onClickInfo(e) {
      helpPopup.showHelpBlock('', 'saveUserTemplateHelpBlock');
      app.callGlobalStore('action', 'user/updateActivityStatus', [{
        activityName: 'saveUserTemplateHelpBlock',
        status: 1,
      }]);
    },
    handleCheckbox() {
      this.shareTemplate = !this.shareTemplate;
    },
    onClickOk() {
      if (!this.templateName.trim() || this.disableButton) {
        return;
      }
      const data = {
        ganttId: this.projectId,
        isShared: this.shareTemplate,
        name: this.templateName,
        icon: 'default',
        skin: this.projectSkin,
      };

      this.$emit('onClickCancel');
      internalApi.post('/templates', {
        ...data,
      })
        .then(response => {
          const templateData = response.data;

          templatesModel.addUserTemplate(templateData.gantt_id, 'default', this.templateName, templateData.id, templateData.has_resources, {
            startProject: templateData.startProject,
            workDays: templateData.workDays,
          });
          userExtAnalytics.log('project_template_created');

          this.templateName = '';
          this.shareTemplate = false;
          this.projectId = null;
          this.projectSkin = null;

          // this.$toast.success(this.locales('new_template_created'));
        });
    },
    addEventListeners() {
      this.removeEventsListeners();

      addAppEvent('project:archive', this.onArhiveProject, this);
      addAppEvent('project:component:remove', this.onDeleteProject, this);

      function addAppEvent(eventName, eventHandler, context) {
        const e_id = app.on(eventName, eventHandler.bind(context));

        context.eventsListeners.push(e_id);
      }
    },
    removeEventsListeners() {
      this.eventsListeners.forEach(id => app.off(id));
      this.eventsListeners = [];
    },
    onArhiveProject(ganttId, isArchived) {
      if (isArchived && this.projectId === ganttId) {
        this.$emit('onClickCancel');
      }
    },
    onDeleteProject(ganttId) {
      if (this.projectId === ganttId) {
        this.$emit('onClickCancel');
      }
    },
  },
};
</script>
<style module src="./templates.less" lang="less"></style>
