var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "students-page gantt_scroll",
      style: { width: _vm.width, height: "100vh" },
    },
    [
      _c("div", {
        staticClass: "students-page-head",
        style: { width: _vm.width },
        domProps: { innerHTML: _vm._s(_vm.headTemplate) },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "students-page-body",
        domProps: { innerHTML: _vm._s(_vm.bodyTemplate) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }