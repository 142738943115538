// views/newProject/_newProject.js
// A new project creation main window

import app from '../../app';
import _ from '../../libs/lodash';
import newProjectFormView from './newProject';
import routerHelper from '../../helpers/router';
import common from './common';

import closeIcon from '../../svg/close_black.svg';
import newProjectIcon from '../../svg/projects_icons/ic_create_new_project.svg';

const {
  helpers: { adjustPopupPosition },
  views: {
    spaceFillerView, headerTitleView, projectHelpView, headerCloseBtnView,
  },
  templates: { headerTitle, headerCloseBtn },
  uiHandlers: { onCloseBtnClick },
  ids: { ID_VIEW_POPUP_HEADER, ID_VIEW_FORM_WINDOW },
} = common;

const ID_VIEW_CREATEPROJ_WINDOW = 'createProjectLayout';
const ID_FEATURE_NEWPROJ = 'newProjectBlock';
const ID_WELCOME_LAYOUT = 'welcomeLayout';

const webixUI = {
  view: 'window',
  id: ID_VIEW_CREATEPROJ_WINDOW,
  css: 'create_project_layout',
  borderless: true,
  // header: false,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  // unClosable: true,
  position(state) {
    adjustPopupPosition.apply(this, [state]);
  },
  body: {
    rows: [{
      id: ID_VIEW_POPUP_HEADER,
      css: 'create_project_layout_header topHeaderShadow',
      height: 54,
      paddingY: 12,
      cols: [
        spaceFillerView(9),
        headerTitleView(headerTitle, {
          infoIcon: newProjectIcon,
          value: __('create_new_project'),
        }),
        { width: 6 },
        headerCloseBtnView(headerCloseBtn, {
          icon: closeIcon,
        }, onCloseBtnClick),
        { width: 9 },
      ],
    }, {
      view: 'scrollview',
      autoheight: true,
      scroll: 'y',
      borderless: true,
      body: {
        rows: [{
          height: 20,
        }, {
          id: ID_VIEW_FORM_WINDOW,
          css: 'create_project_form',
          rows: [],
        }],
      },
    }],
  },
  on: {
    onHide: onPopupHide,
    onShow: onPopupShow,
  },
};

const _events = {
  'body:resize': {
    fn: data => {
      console.info('[newproject] -> onBodyResize'); // !DEBUG
      $$(ID_VIEW_CREATEPROJ_WINDOW) && $$(ID_VIEW_CREATEPROJ_WINDOW).resize();
    },
  },
  'leftSideBar:changedMode': {
    fn: () => {
      console.info('[newproject] -> onChangedSideBarMode'); // !DEBUG
      $$(ID_VIEW_CREATEPROJ_WINDOW) && $$(ID_VIEW_CREATEPROJ_WINDOW).resize();
    },
  },
  'tab:projectCreate': {
    fn: () => {
      console.info('[newproject] -> onTabProjectCreate'); // !DEBUG
      // routerHelper.pushRoute({ name: 'app' });
    },
  },
};

function onPopupHide() {
  Object.keys(_events).forEach(event => {
    app.off(_events[event].id);
  });
}

function onPopupShow() {
  $$(ID_VIEW_POPUP_HEADER).addView(projectHelpView(ID_FEATURE_NEWPROJ, `${ID_FEATURE_NEWPROJ}View`), 2);

  Object.keys(_events).forEach(event => {
    _events[event].id = app.on(event, _events[event].fn);
  });
}

// @public
const init = () => {
  $$(ID_VIEW_FORM_WINDOW).addView(
    _.isFunction(newProjectFormView.view)
      ? newProjectFormView.view()
      : newProjectFormView.view,
  );

  newProjectFormView.init();

  $$(ID_VIEW_CREATEPROJ_WINDOW).show();
};

// @public
const cleanup = () => {
    $$(ID_VIEW_POPUP_HEADER).removeView(`${ID_FEATURE_NEWPROJ}View`);
    $$(ID_VIEW_CREATEPROJ_WINDOW).hide();
    // $$(ID_WELCOME_LAYOUT).hide(true);
};

export {
  webixUI,
  init,
  cleanup,
};
