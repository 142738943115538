import app from '../app';
import customDaysModel from './customDays';

let customDayTemplates = {};

if (!app.config.mode.isBase) {
  customDayTemplates = new webix.DataCollection();

  if (GT.ganttData.multiviewCustomDays) {
    customDayTemplates.parse(GT.ganttData.multiviewCustomDays);
  } else {
    customDayTemplates.parse([{
      ganttId: GT.ganttData.project.gantt_id,
      user_created: 0,
      customDays: GT.ganttData.customDays,
    }]);
  }
} else {
  customDayTemplates = new webix.DataCollection({
    url: `rest->/api/customdaytemplates/?${new Date().getTime()}`,
    save: {
      url: 'rest->/api/customdaytemplates',
      updateFromResponse: true,
    },
  });
}

if (app.debug) {
  GT.models.customDayTemplates = customDayTemplates;
}

customDayTemplates.activeTemplateId = 0;
customDayTemplates.needRefresh = false;
customDayTemplates.userAccess = true;

customDayTemplates.getTemplateDataByGanttIdAndActive = function (ganttId) {
  let result = {};

  _.some(customDayTemplates.serialize(), templateData => {
    if (parseInt(templateData.ganttId, 10) === parseInt(ganttId, 10) && !templateData.user_create) {
      result = webix.copy(templateData);

      return true;
    }
  });

  return result;
};

customDayTemplates.getTemplateDataByTemplateId = function (templateId) {
  return webix.copy(customDayTemplates.getItem(templateId));
};

customDayTemplates.initCustomDaysByGanttId = function (ganttId) {
  const templateData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  if (templateData.id) {
    customDayTemplates.activeTemplateId = templateData.id;
    const customDays = Array.isArray(templateData.customDays) ? templateData.customDays : [templateData.customDays];

    customDaysModel.initData(customDays, templateData.id);
  } else {
    customDaysModel.initData([], null);
  }
};

customDayTemplates.initCustomDaysByTemplateId = function (templateId) {
  let templateData = {};

  if (!templateId || templateId === 'virtualTemplate') {
    templateData = {
      customDays: [],
      id: 'virtualTemplate',
    };
  } else {
    templateData = customDayTemplates.getTemplateDataByTemplateId(templateId);
    customDayTemplates.activeTemplateId = templateId;
  }

  customDaysModel.initData(templateData.customDays, templateData.id);
};

customDayTemplates.getUserTemplateList = function () {
  const result = [];

  _.each(customDayTemplates.serialize(), templateData => {
    if (templateData.user_create) {
      result.push({
        id: templateData.id,
        name: templateData.name,
        templateOwner: templateData.template_owner,
        teamShare: !!templateData.team_template,
      });
    }
  });

  return result;
};

customDayTemplates.getActiveTemplateDataByGanttId = function (ganttId) {
  const templateData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  delete templateData.customDays;

  return templateData;
};

customDayTemplates.setUserAccess = function (userAccess) {
  customDayTemplates.userAccess = userAccess;
};

customDayTemplates.getUserAccessByGanttId = function (ganttId) {
  const templateData = customDayTemplates.getActiveTemplateDataByGanttId(ganttId);
  let access = true;

  if (templateData.rights && templateData.rights < 50) {
    access = false;
  }

  return access;
};

customDayTemplates.setUserTemplateAsProjectSettings = function (userTemplateId, ganttId, options) {
  const templateData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);
  const type = 'projectSettingsByUserTemplate';
  let newTemplateData = {};

  customDayTemplates.waitForGanttId = ganttId;

  if (!templateData.id) {
    customDayTemplates.add({
      type,
      name: options.name,
      ganttId,
      customDays: [],
      userTemplateId,
    });
  } else {
    newTemplateData = customDayTemplates.getTemplateDataByTemplateId(userTemplateId);
    templateData.type = type;
    templateData.userTemplateId = userTemplateId;
    templateData.customDays = newTemplateData.customDays;
    customDayTemplates.updateItem(templateData.id, templateData);
    customDayTemplates.needRefresh = true;
  }

  setTimeout(() => app.trigger('updateCalendarMonths'), 100);
};

customDayTemplates.setProjectSettingsAsUserTemplate = function (ganttId, options) {
  const projectTemplate = customDayTemplates.getActiveTemplateDataByGanttId(ganttId);

  customDayTemplates.waitForGanttId = false;
  customDayTemplates.needRefresh = options.needRefresh || false;
  customDayTemplates.add({
    type: 'userTemplateByProjectSettings',
    name: options.name,
    ganttId: null,
    projectTemplateId: projectTemplate.id,
    customDays: [],
  });
};

customDayTemplates.saveProjectSettingsAndApplyNewTemplate = function (ganttId, templateName, templateId) {
  const projectTemplate = customDayTemplates.getActiveTemplateDataByGanttId(ganttId);
  const newTemplateData = customDayTemplates.getTemplateDataByTemplateId(templateId);

  customDayTemplates.waitForGanttId = ganttId;
  customDayTemplates.add({
    type: 'copyProjectSettingsAndSetUserTemplate',
    name: templateName,
    ganttId,
    projectTemplateId: projectTemplate.id,
    userTemplateId: templateId,
    customDays: newTemplateData.customDays || [],
  });
};

customDayTemplates.workWithDataAfterSaveProjectAndUserTemplate = function (responseData) {
  customDayTemplates.data.order.push(responseData.newTemplate.id);
  customDayTemplates.data.pull[responseData.newTemplate.id] = webix.copy(responseData.newTemplate);
  customDayTemplates.data.pull[responseData.projectTemplateId].customDays = webix.copy(responseData.customDays);

  customDayTemplates.data.order.splice(customDayTemplates.data.order.indexOf(responseData.id), 1);
  delete customDayTemplates.data.pull[responseData.id];
};

customDayTemplates.clearDataAfterUpdate = function (templateId) {
  delete customDayTemplates.data.pull[templateId].type;
  delete customDayTemplates.data.pull[templateId].userTemplateId;
};

customDayTemplates.addNewCustomDay = function (customDayData, ganttId, options) {
  const templateData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  if (!templateData.id && !options.template_id) { // try create the first custom day and template didn't create
    customDayTemplates.waitCreateTemplate = true;
    customDayTemplates.waitForGanttId = ganttId;
    customDayTemplates.needRefresh = true;
    customDayTemplates.add({
      type: options.type,
      name: options.name,
      ganttId,
      customDays: customDayData,
    });
  } else {
    customDayTemplates.activeTemplateId = templateData.id || options.template_id;
    customDaysModel.addNewItem(customDayData);
  }
};

customDayTemplates.deleteUserTemplate = function (templateId) {
  customDayTemplates.needRefresh = true;
  customDayTemplates.remove(templateId);
};

customDayTemplates.deleteAllDaysFromProjectSettings = function (ganttId) {
  const projectData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  customDayTemplates.data.pull[projectData.id].customDays = [];
  customDayTemplates.sendToServer(
    'templateClearDays',
    'put',
    '',
    { projectId: projectData.id },
    response => {
      app.trigger('customDays:projectSettings:init', projectData.id);
    },
  );
};

customDayTemplates.addNewUserTemplate = function (templateName, needRefresh) {
  customDayTemplates.needRefresh = needRefresh;
  customDayTemplates.add({
    type: 'newUserTemplate',
    name: templateName,
    ganttId: null,
    customDays: [],
  });
};

customDayTemplates.copyUserTemplate = function (templateName, templateId) {
  customDayTemplates.needRefresh = true;

  customDayTemplates.add({
    type: 'copyUserTemplate',
    name: templateName,
    ganttId: null,
    userTemplateId: templateId,
    customDays: [],
  });
};

customDayTemplates.renameTemplate = function (templateName, userTemplateId) {
  customDayTemplates.data.pull[userTemplateId].name = templateName;
  customDayTemplates.sendToServer(
    'templateName/',
    'put',
    userTemplateId,
    { name: templateName },
    response => {
      app.trigger('customDays:projectSettings:init', userTemplateId);
    },
  );
};

customDayTemplates.updateCustomDayInTemplate = function (templateId) {
  const templateData = customDayTemplates.data.pull[templateId || customDayTemplates.activeTemplateId];

  if (templateData) {
    templateData.customDays = customDaysModel.getDaysByActiveProject();
  }
};

customDayTemplates.setAndGetCustomDaysForProject = function (ganttId) {
  customDayTemplates.initCustomDaysByGanttId(ganttId);

  return webix.copy(customDaysModel.getSortingDaysByActiveProject(ganttId));
};

customDayTemplates.sendToServer = function (advancedUrl, method, templateId, data, callback) {
  switch (method) {
  case 'put':
    webix.ajax().put(
      `/api/customdaytemplates/${advancedUrl}${templateId}`,
      data,
      callback,
    );
    break;
  case 'delete':
    webix.ajax().del(
      `/api/customdaytemplates/${advancedUrl}${templateId}`,
      data,
      callback,
    );
    break;
  case 'post':
    webix.ajax().post(
      `/api/customdaytemplates/${advancedUrl}${templateId}`,
      data,
      callback,
    );
    break;
  }
};

customDayTemplates.manualAddTemplateToShareProject = function (customDays, ganttId, mode) {
  if (customDays && customDays.length) {
    if (customDayTemplates.data.order.indexOf(customDays[0].template_id) === -1) {
      customDayTemplates.data.order.push(customDays[0].template_id);
    }

    customDayTemplates.data.pull[customDays[0].template_id] = {
      id: customDays[0].template_id,
      ganttId,
      name: '',
      customDays: webix.copy(customDays),
      user_create: 0,
    };
  } else {
    const projectData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

    if (customDayTemplates.data.pull[projectData.id]) {
      customDayTemplates.data.pull[projectData.id].customDays = [];
    }
  }
  app.trigger('customDays:live:update', ganttId);
};

customDayTemplates.addCustomDayByCollaboration = function (customDay, ganttId) {
  const projectData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  if (!projectData.id) {
    customDayTemplates.manualAddTemplateToShareProject([customDay], ganttId);
  } else if (customDayTemplates.data.pull[projectData.id]) {
    const customDays = customDayTemplates.data.pull[projectData.id].customDays;

    if (customDays.isArray) customDays.push(customDay);
  }
};

customDayTemplates.updateCustomDayByCollaboration = function (customDay, ganttId) {
  const projectData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  if (customDayTemplates.data.pull[projectData.id]) {
    let _index = -1;

    customDayTemplates.data.pull[projectData.id].customDays.forEach((el, index) => {
      if (el.id === customDay.id) {
        _index = index;
      }
    });

    if (_index >= 0) {
      customDayTemplates.data.pull[projectData.id].customDays[_index] = customDay;
    }
  }
};

customDayTemplates.deleteCustomDayByCollaboration = function (id, ganttId) {
  const projectData = customDayTemplates.getTemplateDataByGanttIdAndActive(ganttId);

  if (customDayTemplates.data.pull[projectData.id]) {
    let _index = -1;

    customDayTemplates.data.pull[projectData.id].customDays.forEach((el, index) => {
      if (el.id === id) {
        _index = index;
      }
    });

    if (_index >= 0) {
      customDayTemplates.data.pull[projectData.id].customDays.splice(_index, 1);
    }
  }
};

webix.dp(customDayTemplates).attachEvent('onAfterSync', (obj, dataRaw, data) => {
  const responseData = data;

  if (obj.status === 'insert' && data) {
    customDayTemplates.activeTemplateId = responseData.id;

    if (responseData.type === 'copyProjectSettingsAndSetUserTemplate') {
      customDayTemplates.workWithDataAfterSaveProjectAndUserTemplate(responseData);
      app.trigger('customDays:projectSettings:init', responseData.projectTemplateId);
    }

    if (customDayTemplates.activeTemplateId) {
      customDayTemplates.clearDataAfterUpdate(customDayTemplates.activeTemplateId);
    }

    if (customDayTemplates.waitForGanttId) {
      customDayTemplates.initCustomDaysByGanttId(customDayTemplates.waitForGanttId);
    }

    if (customDayTemplates.needRefresh) {
      app.trigger('customDays:projectSettings:init', responseData.id);
    }

    app.trigger('customDays:insert');
    customDayTemplates.needRefresh = false;
    customDayTemplates.waitCreateTemplate = false;
    customDayTemplates.waitForGanttId = null;
  }

  if (obj.status === 'update' && data) {
    customDayTemplates.clearDataAfterUpdate(customDayTemplates.activeTemplateId);

    if (customDayTemplates.waitForGanttId) {
      customDayTemplates.initCustomDaysByGanttId(customDayTemplates.waitForGanttId);
      customDayTemplates.waitForGanttId = null;
      customDayTemplates.waitCreateTemplate = false;
    }
    // customDays.prepareDataAfterSync(data.json());
    customDaysModel.initData(responseData.customDays, responseData.id);
    app.trigger('customDays:update');
  }

  if (obj.status === 'delete' && data) {
    if (customDayTemplates.needRefresh) {
      app.trigger('customDays:projectSettings:init', responseData.id);
    }

    customDayTemplates.needRefresh = false;
    customDayTemplates.waitCreateTemplate = false;
    customDayTemplates.waitForGanttId = null;
  }
});

app.on('customDays:insert', () => {
  customDayTemplates.updateCustomDayInTemplate();
});

app.on('customDays:delete', () => {
  customDayTemplates.updateCustomDayInTemplate();
});

app.on('customDays:update', () => {
  customDayTemplates.updateCustomDayInTemplate();
});

app.on('app:start', () => {
  webix.promise.all([customDayTemplates.waitData]).then(() => {
    app.checkInit('customDayTemplates');
    app.trigger('customDayTemplates:init');
  });
});

app.on('project:add:customDays', customDayTemplates.manualAddTemplateToShareProject);

export default customDayTemplates;
