// public/models/onBoarding/FeaturePointer.js
// !TODO: description

import './_featurePointer.less';

function FeaturePointer(idElem, rad) {
    this._pointer = window.document.createElement('div');
    this._pointer2 = window.document.createElement('div');
    this._pointer3 = window.document.createElement('div');

    this._pointer.classList.add('custom-onboard-pointer');
    this._pointer.id = 'idOnboardPoiner';

    this._pointer2.classList.add('custom-onboard-pointer');
    this._pointer3.classList.add('custom-onboard-pointer');

    this._pointer.style.width = `${rad * 2}px`;
    this._pointer.style.height = `${rad * 2}px`;

    this._pointer.style.animationDelay = '0s';

    this._pointer2.style.width = `${rad * 2}px`;
    this._pointer2.style.height = `${rad * 2}px`;

    this._pointer2.style.animationDelay = '-1s';

    this._pointer3.style.width = `${rad * 2}px`;
    this._pointer3.style.height = `${rad * 2}px`;

    this._pointer3.style.animationDelay = '-3s';

    this._idElem = idElem;
    this._rad = rad;

    this._pointer.addEventListener('animationend', this.onAnimationEnd.bind(this));
    this._pointer.classList.add('scaleIn');

    window.document.body.prepend(this._pointer); // !FIXME: append
    // window.document.body.prepend(this._pointer2); // !FIXME: append
    // window.document.body.prepend(this._pointer3); // !FIXME: append
}

// @private -> handler
FeaturePointer.prototype.onAnimationEnd = function(e) {
    const { _pointer } = this;

    if (e.animationName === 'scaleIn') {
        _pointer.classList.remove('scaleIn');
        _pointer.classList.add('fadeOut');
        return;
    }

    if (e.animationName === 'fadeOut') {
        _pointer.classList.remove('fadeOut');
        _pointer.classList.add('scaleIn');
        return;
    }
}


// @public {} -> {Number}
FeaturePointer.prototype.getRad = function() {
    return this._rad;
}

// @public
FeaturePointer.prototype.show = function(x, y) {
    this._pointer.style.display = 'block';
    this._pointer2.style.display = 'block';
    this._pointer3.style.display = 'block';

    this._pointer.style.left = `${x - this._rad}px`;
    this._pointer.style.top = `${y - this._rad}px`;

    this._pointer2.style.left = `${x - this._rad}px`;
    this._pointer2.style.top = `${y - this._rad}px`;

    this._pointer3.style.left = `${x - this._rad}px`;
    this._pointer3.style.top = `${y - this._rad}px`;
};

// @public
FeaturePointer.prototype.hide = function() {
    this._pointer.style.display = 'none';
    this._pointer2.style.display = 'none';
    this._pointer3.style.display = 'none';
};

export default FeaturePointer;
