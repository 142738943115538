import constants from '../../../helpers/constants';
import app from '../../../app';
import projectsModel from '../../../models/projects';
import multiViewsModel from '../../../models/multiViewsProjects';
import globalStore from '../../../store/main';
import checkAccessToHeaderItems from './checkAccessToHeaderItems';
import rights from '../../../components/rights';

const UIConfigsByRoute = new Map([
  [
    ['app', 'project', 'taskRoute'],
    {
      head: {
        ui: ['favorite', 'history', 'editTitle', 'more', 'settings', 'slack', 'msteams', 'rolePopup'],
        headIcon: 'project',
        moreItems: ['publicUrl', 'template', 'delimiter', 'archive', 'delete'],
      },
      tabs: ['timeline', 'list', /* 'timelog', */'board', 'workload', 'resources', 'overview', 'calendar'],
      toolbar: {
        gantt: {
          ui: ['bulkChange', 'overdue', 'criticalPath', 'delimiter', 'expand', 'collapse', 'sort',
            'filter', 'filterRefresh', 'zoom', 'export', 'baseline'],
          viewSettings: [
            {
              name: 'taskName',
              type: 'select',
              list: ['rightSideText', 'insideText', 'noTaskText'],
              minWidth: '200px',
            },
            {
              name: 'completedTask',
              type: 'multiselect',
              list: ['crossOutTasks', 'discolorationTasks'],
            },
            {
              name: 'divider',
            },
            {
              name: 'avatar',
              type: 'checkbox',
            },
            {
              name: 'todayMarker',
              type: 'checkbox',
            },
            {
              name: 'skipOffTime',
              type: 'checkbox',
            },
            {
              name: 'buttons',
              type: 'checkbox',
            },
            {
              name: 'divider',
            },
            {
              name: 'skins',
              type: 'select',
              list: [1, 2, 3, 4],
              icons: true,
            },
            {
              name: 'viewMode',
              type: 'select',
              list: ['auto', 'compact', 'touch'],
            },
          ],
        },
        board: {
          ui: ['boardType', 'delimiter', 'missedFeature', 'filter', 'filterRefresh'],
        },
        list: {
          // ui: ['tasksCount', 'myTasks', 'filter', 'filterRefresh', 'export'],
          ui: ['bulkChange', 'overdue', 'addTask', 'filter', 'filterRefresh', 'export'],
          viewSettings: [
            {
              name: 'completedTask',
              type: 'multiselect',
              list: ['crossOutTasks', 'discolorationTasks'],
            },
            {
              name: 'skins',
              type: 'select',
              list: [1, 2, 3, 4],
              icons: true,
            },
          ],
        },
        workload: {
          ui: ['workloadType', 'rangeCalendar', 'missedFeature', 'filter', 'zoom', 'export'],
          types: ['hours', 'tasks', 'percents'],
          filterType: constants.FILTER_TYPES.WORKLOAD,
        },
        calendar: {
          ui: ['calendarStep', 'delimiter', 'calendarToday', 'delimiter', 'missedFeature', 'filter', 'filterRefresh'],
        },
      },
      filterType: constants.FILTER_TYPES.PROJECT,
      toolbarHeight: 40,
      tabsHeight: 25,
      sumHeight: 115,
      sumHeightForTabs: {
        resources: 74,
        overview: 74,
      },
      headForTabs: {
        resources: {
          ui: ['favorite', 'history', 'editTitle', 'more', 'rolePopup'],
          headIcon: 'project',
          moreItems: ['publicUrl', 'template', 'delimiter', 'archive', 'delete'],
        },
      },
      _source: 'singleProject',
    },
  ],
  [
    ['projectsMultiView', 'multiviewTaskRoute'],
    {
      head: {
        ui: ['editTitle', 'favorite', 'settings', 'more', 'rolePopup', 'close'],
        headIcon: 'portfolio',
        moreItems: ['publicUrl', 'deletePortfolio'],
      },
      tabs: ['timeline', 'workload', 'list', 'projects', 'dashboard'/* , 'timelog', 'board' */],
      toolbar: {
        gantt: {
          ui: ['overdue', 'criticalPath', 'delimiter', 'expand', 'collapse',
            'filter', 'filterRefresh', 'zoom', 'export'],
          viewSettings: [
            {
              name: 'taskName',
              type: 'select',
              list: ['rightSideText', 'insideText', 'noTaskText'],
              minWidth: '200px',
            },
            {
              name: 'completedTask',
              type: 'multiselect',
              list: ['crossOutTasks', 'discolorationTasks'],
            },
            {
              name: 'divider',
            },
            {
              name: 'avatar',
              type: 'checkbox',
            },
            {
              name: 'todayMarker',
              type: 'checkbox',
            },
            {
              name: 'buttons',
              type: 'checkbox',
            },
            {
              name: 'divider',
            },
            {
              name: 'skins',
              type: 'select',
              list: [1, 2, 3, 4],
              icons: true,
            },
            {
              name: 'viewMode',
              type: 'select',
              list: ['auto', 'compact', 'touch'],
            },
          ],
        },
        board: {
          ui: ['boardType', 'filter', 'filterRefresh'],
        },
        list: {
          // ui: ['tasksCount', 'myTasks', 'filterRefresh', 'filter', 'export'],
          ui: ['overdue', 'filter', 'filterRefresh', 'export'],
          viewSettings: [
            {
              name: 'completedTask',
              type: 'multiselect',
              list: ['crossOutTasks', 'discolorationTasks'],
            },
            {
              name: 'skins',
              type: 'select',
              list: [1, 2, 3, 4],
              icons: true,
            },
          ],
        },
        workload: {
          ui: ['workloadType', 'rangeCalendar', 'missedFeature', 'filter', 'zoom', 'export'],
          filterType: constants.FILTER_TYPES.WORKLOAD,
        },
        projects: {
          ui: ['addProject']
        },
        dashboard: {
          ui: ['missedFeature']
        }
      },
      filterType: constants.FILTER_TYPES.MULTIVIEW,
      toolbarHeight: 40,
      tabsHeight: 25,
      sumHeight: 115,
      _source: 'multiProject',
    },
  ],
  [
    ['listView', 'listTaskRoute'],
    {
      head: {
        ui: ['close'],
        headIcon: 'listView',
        title: 'common_list_title',
      },
      tabs: ['list'/* , 'board' */],
      toolbar: {
        list: {
          ui: ['tasksCount', 'myTasks', 'filter', 'missedFeature', 'filterRefresh', 'export'],
        },
      },
      sumHeight: 115,
      filterType: constants.FILTER_TYPES.TASKSLIST,
    },
  ],
  [
    ['portfolio'],
    {
      head: {
        ui: [],
        headIcon: 'portfolio',
        title: 'common_portfolio_title',
        css: 'tall',
      },
      sumHeight: 50,
    }
  ],
  [
    ['workload', 'workloadTaskRoute'],
    {
      head: {
        ui: ['close'],
        headIcon: 'workload',
        title: 'workload_title',
        css: 'border_bottom',
      },
      toolbar: {
        ui: ['workloadType', 'rangeCalendar', 'missedFeature', 'filter', 'zoom', 'export'],
      },
      filterType: constants.FILTER_TYPES.WORKLOAD,
      sumHeight: 90,
    },
  ],
  [
    ['teamAndResources'],
    {
      head: {
        ui: ['close'],
        headIcon: 'teamResources',
        headHelpIcon: 'teamResources',
        title: 'team_and_resources',
      },
      sumHeight: 50,
    },
  ],
  [
    ['reports'],
    {
      head: {
        ui: ['close'],
        headIcon: 'reports',
        title: 'reports_title',
        css: 'tall',
      },
      sumHeight: 50,
    },
  ],
  [
    ['reportsDetail'],
    {
      head: {
        ui: ['close'],
        headIcon: 'reports',
        title: 'reports_title',
        css: 'border_bottom',
      },
      toolbar: {
        ui: ['reportInfo', 'groupBy', 'filter', 'export'],
      },
      filterType: {
        costslist: constants.FILTER_TYPES.COSTSFILTER,
        timeloglist: constants.FILTER_TYPES.TIMELOGS,
      },
      sumHeight: 90,
    },
  ],
  [
    ['userLogs'],
    {
      head: {
        ui: ['close'],
        headIcon: 'userLogs',
        title: 'common_my_logs_title',
        css: 'border_bottom',
      },
      toolbar: {
        ui: ['reportInfo', 'groupBy', 'filter', 'export'],
      },
      sumHeight: 90,
    },
  ],
  [
    ['comments'],
    {
      head: {
        ui: ['close'],
        headIcon: 'commentHub',
        title: 'common_comments_hub_title',
        css: 'border_bottom',
      },
      toolbar: {
        ui: [],
      },
      sumHeight: 90,
    },
  ],
]);

const UIConfigByAppModes = {
  link: {
    head: {
      ui: ['publicUrlInfo', 'signUpButton', 'noBaseTitle', 'logo'],
      css: 'border_bottom',
    },
    toolbar: {
      ui: ['zoom', 'filter'],
    },
    sumHeight: 90,
  },
  export: {},
  history: {},
};

const _prepareConfig = function (config, route) {
  const mode = route.params.mode || 'gantt';

  if (config.toolbar && !config.toolbar.ui) {
    config.toolbar = config.toolbar[mode];
  }

  if (config.headForTabs) {
    config.head = config.headForTabs[mode] || config.head;
  }

  if (config.toolbar) {
    config.toolbar.disable = route.path.includes('task/');
  }

  return config;
};

export default {
  getUI(route) {
    let currentConfig = false;

    const workloadAccess = globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures.workload.name);
    const timeLogAccess = globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures.time_tracking.name);
    const calendarAccess = globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures.calendar_view.name);

    if (app.config.mode.isHistory) {
      return UIConfigByAppModes.history;
    }
    if (app.config.mode.isExport) {
      return UIConfigByAppModes.export;
    }
    if (app.config.mode.isLink) {
      if (GT.ganttData && GT.ganttData.filter && GT.ganttData.filter.filter) {
        _.remove(UIConfigByAppModes.link.toolbar.ui, el => ['filter'].includes(el));
      }

      return UIConfigByAppModes.link;
    }

    UIConfigsByRoute.forEach((cfg, routes) => {
      const config = _.cloneDeep(cfg);

      if (routes.includes(route.name)) {
        const isApplyTimelineRange = gantt.config.is_apply_timeline_range;

        if (route.name === 'project' && route.path.includes('/workload') && !workloadAccess) {
          config.sumHeight = 74;
          config.tabs.css = 'no_border';
          delete config.toolbar;
          delete config.head.css;
        }

        if (route.name === 'workload' && !workloadAccess) {
          config.sumHeight = 50;
          delete config.toolbar;
          delete config.head.css;
        }

        if (route.name === 'userLogs' && !timeLogAccess) {
          config.sumHeight = 50;
          delete config.toolbar;
          delete config.head.css;
        }

        if (route.name === 'comments') {
          config.sumHeight = 50;
          delete config.toolbar;
          delete config.head.css;
        }

        const ganttId = route.params.projectId;

        if (config._source === 'singleProject') {
          const isArchivedProject = projectsModel.isArchived(ganttId);
          const { is_jira: isJira } = projectsModel.getProjectDataById(ganttId) || {};

          checkAccessToHeaderItems(config, +ganttId);

          if (!config.tabs.length) {
            config.sumHeight -= config.tabsHeight;
          }

          if (isArchivedProject) {
            _.remove(config.head.ui, el => ['editTitle', 'favorite', 'settings', 'slack', 'msteams'].includes(el));
            _.remove(config.head.moreItems, el => ['publicUrl', 'template', 'archive', 'delimiter'].includes(el));

            config.tabs = ['timeline'];
            config.toolbar.gantt.ui = ['filter', 'zoom'];
            _.remove(config.toolbar.gantt.viewSettings, el => ['buttons'].includes(el.name));
          }

          if (isJira) {
            config.head.headIcon = 'projectJira';
            config.headForTabs.resources.headIcon = 'projectJira';

            _.remove(config.head.moreItems, el => ['archive'].includes(el));
            _.remove(config.toolbar.gantt.viewSettings, el => ['completedTask'].includes(el.name));
            _.remove(config.toolbar.gantt.ui, el => ['bulkChange'].includes(el));
            _.remove(config.toolbar.list?.ui, el => ['bulkChange'].includes(el));
            _.remove(config.headForTabs.resources.moreItems, el => ['archive'].includes(el));
            _.remove(config.toolbar.list.ui, el => ['bulkChange'].includes(el));
          }

          if (isApplyTimelineRange) {
            _.remove(config.toolbar.gantt.viewSettings, el => ['taskName', 'avatar'].includes(el.name));
          }
        }

        if (config._source === 'multiProject') {
          const multiviewData = multiViewsModel.getActiveViewData(route.params.multiviewID);
          const tabsMap = {
            timeline: 'gantt_chart',
            list: 'list_view',
            workload: 'workload'
          };

          config.tabs = config.tabs.filter(tab => ['projects', 'dashboard'].some(tabItem => tabItem === tab) || multiviewData.ganttIDs.some(gantt_id => rights.project.hasRight(gantt_id, tabsMap[tab])));

          if (!config.tabs.length) {
            config.sumHeight -= config.tabsHeight;
          }

          if (isApplyTimelineRange) {
            _.remove(config.toolbar.gantt.viewSettings, el => ['taskName', 'avatar', 'divider'].includes(el.name));
          }
          if (rights.account.hasRight('multiview_all_features')) {
            _.remove(config.head.moreItems, el => ['editMultiview', 'delete', 'divider'].includes(el.name));
            if (!rights.account.hasRight('public_url')) _.remove(config.head.moreItems, el => ['publicUrl'].includes(el));
          }
        }

        if (route.name === 'project' && route.path.includes('/calendar') && !calendarAccess) {
          config.sumHeight = 74;
          config.tabs.css = 'no_border';
          delete config.toolbar;
          delete config.head.css;
        }

        currentConfig = config;
      }
    });

    return _prepareConfig(currentConfig, route);
  },
  hasRouteConfig(routeName) {
    let flag = false;

    for (const arr of UIConfigsByRoute.keys()) {
      if (arr.includes(routeName)) {
        flag = true;
        break;
      }
    }

    return flag;
  },
};
