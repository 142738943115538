import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const UserNotificationStore = {
  namespaced: true,
  state: {
    notifications: [],
    loaded: false,
    limitPerPage: 40,
    newNotificationCount: 0,
    settings: {
      setup: [],
    },
  },
  getters,
  mutations,
  actions,
};
// socket add there

export default UserNotificationStore;
