var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["gallery__wrapper"] },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          class: [_vm.$style["card"], "vgp-interactive-element"],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("view", item)
            },
          },
        },
        [
          item.attachmentType === _vm.attachmentTypeKeys.GOOGLE_DRIVE
            ? _c("svg-sprite", {
                class: _vm.$style["card__google-drive-icon"],
                attrs: { name: "google-drive", type: "color-regular" },
              })
            : _vm._e(),
          _vm._v(" "),
          item.attachmentType === _vm.attachmentTypeKeys.ONE_DRIVE
            ? _c("svg-sprite", {
                class: _vm.$style["card__one-drive-icon"],
                attrs: { name: "one-drive", type: "color-regular" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style["card__buttons"] },
            [
              !_vm.disabledDeletion
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "delete" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("delete", item)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !item.downloadUrl
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "link-2" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("view", item)
                      },
                    },
                  })
                : _c("vgp-icon-button", {
                    attrs: { icon: { name: "download" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("download", item)
                      },
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style["card__image-wrapper"] }, [
            _c("img", {
              class: _vm.$style["card__image"],
              attrs: { src: item.thumbnailLink, alt: "Attachment image" },
              on: {
                error: function ($event) {
                  $event.target.src = _vm.defaultImagePlaceholder
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style["card__info-wrapper"] }, [
            _c("div", { class: _vm.$style["card__name"] }, [
              _vm._v("\n        " + _vm._s(item.name) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style["card__inner-wrapper"] }, [
              _c("span", { class: _vm.$style["card__size"] }, [
                _vm._v(_vm._s(item.size)),
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.$style["card__date"] }, [
                _vm._v(_vm._s(item.uploadDate)),
              ]),
            ]),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }