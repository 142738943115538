import app from '../../app';
import showAuthWindow from '../../views/profileSettings/include/integrations/showAuthWindow';
import rights from '../../components/rights/index';

const integrationMSTeamsModel = app.config.mode.isBase
  ? new webix.DataCollection({
    url: 'rest->/api/integrations/msteams',
  })
  : new webix.DataCollection();

function processListDataFromTeams(response) {
  const data = response.json();
  if (data.error === 'invalid_token_scope') {
    showAuthWindow({ isOwner: rights.account.isOwner(), type: 'msteams', update_user_token: true });
    return [];
  }
  return data;
}

integrationMSTeamsModel.getTeams = () => webix.ajax()
  .get('/api/integrations/msteams/teams')
  .then(processListDataFromTeams)
  .catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.getChannels = teamId => webix.ajax()
  .get(`/api/integrations/msteams/teams/${teamId}/channels`)
  .then(processListDataFromTeams)
  .catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.removeConnection = data => webix.ajax()
  .del('/api/integrations/msteams', data)
  .then(data => {
    const result = data.json();
    if (result.status === 'ok') integrationMSTeamsModel.remove(result.id);
    return result;
  })
  .catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.getData = async () => webix.ajax()
  .get('/api/integrations/msteams')
  .then(result => {
    const data = result.json();
    integrationMSTeamsModel.clearAll();
    integrationMSTeamsModel.parse(data);
    const _data = integrationMSTeamsModel.serialize();
    _data.forEach(element => {
      element.editMode = false;
    });

    return _data;
  }).catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.addConnection = data => webix.ajax()
  .post('/api/integrations/msteams/addConnection', data)
  .then(data => {
    const result = data.json();
    if (result.status === 'ok') {
      integrationMSTeamsModel.add(result.item);
    }
    return result;
  }).catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.editConnection = data => {
  const itemId = data.channelProjectId;
  return webix.ajax()
    .post('/api/integrations/msteams/editConnection', data)
    .then(data => {
      const result = data.json();
      if (result.status === 'ok') {
        let cItem = integrationMSTeamsModel.getItem(itemId);
        cItem = result.item;
        integrationMSTeamsModel.updateItem(itemId, cItem);
      }
      return result;
    }).catch(err => {
      new Error(err);
    });
};

integrationMSTeamsModel.removeAllConnections = () => integrationMSTeamsModel.clearAll();

integrationMSTeamsModel.getDataFromCollection = () => integrationMSTeamsModel.serialize();

integrationMSTeamsModel.saveSetting = data => webix.ajax()
  .post('/api/integrations/msteams/saveSetting', data)
  .then(response => {
    const result = response.json();
    if (result.status === 'ok') {
      const cItem = integrationMSTeamsModel.getItem(data.id);
      cItem[data.settingType] = data.value;
      integrationMSTeamsModel.updateItem(data.id, cItem);
    }
    return result;
  }).catch(err => {
    new Error(err);
  });

integrationMSTeamsModel.attachEvent('onAfterAdd', id => {
  app.trigger('integration:msteams:updated');
  app.trigger('integration:msteams:addNewItem');
});

integrationMSTeamsModel.attachEvent('onDataUpdate', id => {
  app.trigger('integration:msteams:updated');
});

integrationMSTeamsModel.attachEvent('onAfterDelete', id => {
  app.trigger('integration:msteams:updated');
});

app.socket.on('integration:socket:service_url_updated', data => {
  const { connectionsList, serviceUrl } = data;
  let triggerUpdate = false;
  connectionsList.forEach(connectionId => {
    const connection = integrationMSTeamsModel.getItem(connectionId);
    if (!connection) return;
    if (!triggerUpdate) triggerUpdate = true;
    connection.service_url = serviceUrl;
    connection.waitingForActivation = false;
    integrationMSTeamsModel.updateItem(connectionId, connection);
  });

  triggerUpdate && app.trigger('integration:msteams:service_url_updated');
});

export { integrationMSTeamsModel };
