<template>
  <section :class="$style['task-settings-attachments']">
    <div :class="$style['header']">
      <div :class="$style['title-wrap']">
        <h2>
          {{ locales('settings_taskAttachment') }}
          <span>{{ formattedAttachments.length }}</span>
        </h2>
        <vgp-icon-button
          v-if="!isFieldDisabled('attachments')"
          ref="newAttachmentButton"
          :active="!!newAttachmentContextMenuPosition"
          :icon="{ name: 'plus-1' }"
          @onClick="showNewAttachmentContextMenu"
        />
        <new-attachment-context-menu
          v-if="newAttachmentContextMenuPosition"
          :position-context="newAttachmentContextMenuPosition"
          :task-data="taskData"
          @attachment-created="$emit('attachment-created')"
          @close="newAttachmentContextMenuPosition = null"
        />
      </div>
      <div :class="$style['buttons-wrap']">
        <template v-if="selectedAttachmentsIds.length && activeViewMode === 'list'">
          <vgp-icon-button
            v-if="!isFieldDisabled('attachments')"
            :class="$style['delete-selected-btn']"
            :icon="{ name: 'delete' }"
            :label="locales('common_delete')"
            @onClick="showDeleteConfirmPopup('multiple_delete')"
          />
          <vgp-icon-button
            :disabled="!isAnySelectedAttachmentUploadedFromLocalDevice"
            :icon="{ name: 'download' }"
            :label="locales('common_download')"
            @onClick="downloadSelected"
          />
        </template>
        <vgp-icon-button
          ref="sectionActionsBtn"
          :class="$style['header-actions-btn']"
          :icon="{ name: 'more-1' }"
          @onClick="showSectionContextMenu"
        />
        <context-menu
          v-if="sectionContextMenuPosition"
          :items="sectionContextItems"
          :position="sectionContextMenuPosition"
          @closePopup="sectionContextMenuPosition = null"
          @selectItemMenu="handleSelectItemMenu"
        />
      </div>
    </div>
    <KeepAlive>
      <component
        :is="activeViewMode"
        :items="visibleAttachments"
        :selected-items="selectedAttachmentsIds"
        :default-image-placeholder="defaultImagePlaceholder"
        :disabled-deletion="isFieldDisabled('attachments')"
        @view="handleViewAttachment"
        @select="handleSelectAttachment"
        @delete="showDeleteConfirmPopup('single_delete', $event)"
        @download="downloadAttachment"
      />
    </KeepAlive>
    <vgp-icon-button
      v-if="isViewAllBtnShown"
      class="mt-4"
      size="middle"
      bold
      icon-side="right"
      :icon="viewAll ? { name: 'arrow-top' } : { name: 'arrow-down' }"
      :label="viewAll ? locales('attachments_list_collapse_task_attachments') : locales('attachments_list_expand_task_attachments')"
      :colors="{
        color: '#808080',
        labelColor: '#808080',
        activeBg: 'transparent',
        activeHoverBg: 'transparent',
      }"
      :sizes="{ labelFontSize: '14px', lineHeight: '24px' }"
      @onClick="viewAll = !viewAll"
    />
    <core-confirm-popup
      :popup-width="440"
      :show="confirmDeletionData.showPopup"
      :text="confirmDeletionData.text"
      :cancel-button="{ title: locales('common_cancel'), type: 'secondary' }"
      :ok-button="{ title: locales('common_delete'), type: 'destructive' }"
      @onClickOk="handleConfirmDeletion"
      @onClickCancel="cleanUpConfirmDeletionData"
    />
  </section>
</template>

<script>
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import humanFileSize from '$$helpers/fileSize';
import GalleryView from './includes/GalleryView.vue';
import ListView from './includes/ListView.vue';
import NewAttachmentContextMenu from './includes/NewAttachmentContextMenu.vue';
import { downloadMultipleAsZip, downloadSingle } from '$$helpers/attachments';
import storeGallery from '../../../vueGallery/storeGallery';
import { previewAvailabilityByType } from '../../helpers/googleDrive';
import GoogleDriveAttachmentsController from '../../controllers/googleDrive';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import constants from '$$helpers/constants';
import globalStore from "$$store/main";
import app from '../../../../app';
import profile from '../../../../models/profile';

const IMAGE_EXTENSIONS = ['jpeg', 'png', 'svg', 'jpg', 'gif', 'bmp', 'tiff', 'exif'];
const MIN_ATTACHMENTS_AMOUNT_TO_SHOW_VIEW_ALL_BTN = 7;
const VIEW_MODES = {
  LIST: 'list',
  GALLERY: 'gallery',
};
let COMPONENT_EVENT_LISTENERS = [];

export default {
  name: 'TaskSettingsAttachments',
  components: {
    NewAttachmentContextMenu,
    list: ListView,
    gallery: GalleryView,
    VgpIconButton,
    CoreConfirmPopup,
  },
  inject: ['isFieldDisabled'],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      defaultImagePlaceholder: `${GT.cdn}/imgs/fileTypes/file.svg`,
      newAttachmentContextMenuPosition: null,
      sectionContextMenuPosition: null,
      activeViewMode: user.settings.task_settings_attachments_view_mode,
      selectedAttachmentsIds: [],
      isDeleting: false,
      viewAll: false,
      confirmDeletionData: {
        showPopup: false,
        text: '',
        type: null, // single_delete || multiple_delete || delete_all
        eventPayload: null,
      },
    };
  },
  computed: {
    isAnySelectedAttachmentUploadedFromExternalProvider() {
      return this.selectedAttachmentsIds.some(id => !this.attachmentIdsUploadedFromLocalDevice.includes(id));
    },
    isAnySelectedAttachmentUploadedFromLocalDevice() {
      return this.selectedAttachmentsIds.some(id => this.attachmentIdsUploadedFromLocalDevice.includes(id));
    },
    isViewAllBtnShown() {
      return this.formattedAttachments.length > MIN_ATTACHMENTS_AMOUNT_TO_SHOW_VIEW_ALL_BTN;
    },
    sectionContextItems() {
      const contextItems = [];
      const switchModeLabel = this.activeViewMode === VIEW_MODES.LIST
        ? __('settings_attachments_gallery_view_label')
        : __('settings_attachments_list_view_label');

      contextItems.push({ name: switchModeLabel, id: 'switch_view_mode' });

      // only attachments uploaded from local device can be downloaded, if there are none - hide button
      const downloadAllButtonVisible = this.attachmentIdsUploadedFromLocalDevice.length > 0;

      if (downloadAllButtonVisible) {
        contextItems.push({ name: __('common_download_all'), id: 'download_all' });
      }

      if (!this.isFieldDisabled('attachments')) {
        contextItems.push({ name: __('common_delete_all'), id: 'delete_all', labelColor: '#DD3636' });
      }

      return contextItems;
    },
    attachmentIdsUploadedFromLocalDevice() {
      return this.formattedAttachments
        .filter(({ attachmentType }) => attachmentType === constants.ATTACHMENT_TYPE_KEYS.FILE)
        .map(({ id }) => id);
    },
    formattedAttachments() {
      return [...this.attachments]
        .sort((a, b) => b.id - a.id)
        .map(attachment => {
          const attachmentExtension = attachment.name.split('.').at(-1).toLowerCase();
          const attachmentType = attachment.attachmentType;
          let thumbnailLink = attachment.link;
          let downloadUrl = null;

          if (attachmentType === constants.ATTACHMENT_TYPE_KEYS.FILE && !IMAGE_EXTENSIONS.includes(attachmentExtension)) {
            thumbnailLink = `${GT.cdn}/imgs/fileTypes/${attachmentExtension}.svg`;
          }

          switch (attachmentType) {
          case constants.ATTACHMENT_TYPE_KEYS.FILE:
            downloadUrl = attachment.link;
            break;
          case constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE:
            thumbnailLink = `https://drive.google.com/thumbnail?authuser=0&sz=w166&h=130&id=${attachment.attacherExternalAccessToken}`;
            break;
          }

          const userResource = globalStore.getters['resourcesModel/getResourceById'](attachment.user.resourceId);
          const author = userResource
            ? `${userResource.name},`
            : `${attachment.user.firstName} ${attachment.user.lastName} (${__('task_settings_author_deleted_label')}),`;

          return {
            ...attachment,
            author,
            size: humanFileSize(attachment.size),
            uploadDate: moment(attachment.uploadDate).format(user.dateFormat),
            thumbnailLink,
            downloadUrl,
          };
        });
    },
    visibleAttachments() {
      if (this.viewAll) {
        return this.formattedAttachments;
      }

      return this.formattedAttachments.slice(0, MIN_ATTACHMENTS_AMOUNT_TO_SHOW_VIEW_ALL_BTN);
    },
  },
  watch: {
    formattedAttachments(newVal, oldVal) {
      if (this.selectedAttachmentsIds.length && newVal.length < oldVal.length) {
        this.unselectDeletedAttachmentIds();
      }
    },
  },
  created() {
    const onAfterCollaborationListenerId = app.on('onAfterCollaboration', data => {
      if (data.event === 'TaskAttachementDeleted' && data.tasks.includes(this.taskData.id)) {
        this.onTaskAttachmentDeletedByCollaboration(data.ids);
      }
    });

    COMPONENT_EVENT_LISTENERS.push(onAfterCollaborationListenerId);
  },
  beforeDestroy() {
    COMPONENT_EVENT_LISTENERS.forEach(id => app.off(id));
    COMPONENT_EVENT_LISTENERS = [];
  },
  methods: {
    onTaskAttachmentDeletedByCollaboration(deletedAttachmentIds) {
      if (!this.confirmDeletionData.showPopup) return;

      if (
        this.confirmDeletionData.type === 'single_delete'
        && deletedAttachmentIds.includes(this.confirmDeletionData.eventPayload.id)
      ) {
        this.cleanUpConfirmDeletionData();
      }

      if (
        this.confirmDeletionData.type === 'multiple_delete'
        && deletedAttachmentIds.some(deletedId => this.selectedAttachmentsIds.includes(deletedId))
      ) {
        this.cleanUpConfirmDeletionData();
      }
    },
    unselectDeletedAttachmentIds() {
      const attachmentIds = this.formattedAttachments.map(attachment => attachment.id);

      this.selectedAttachmentsIds = this.selectedAttachmentsIds.filter(selectedId => attachmentIds.includes(selectedId));
    },
    cleanUpConfirmDeletionData() {
      this.confirmDeletionData.showPopup = false;
      this.confirmDeletionData.type = null;
      this.confirmDeletionData.eventPayload = null;
      this.confirmDeletionData.text = '';
    },
    handleConfirmDeletion() {
      switch (this.confirmDeletionData.type) {
      case 'single_delete':
        this.deleteAttachment(this.confirmDeletionData.eventPayload);
        break;
      case 'multiple_delete':
        this.deleteSelected();
        break;
      case 'delete_all':
        this.deleteAll();
        break;
      }
      this.cleanUpConfirmDeletionData();
    },
    showDeleteConfirmPopup(deletionType, eventData = null) {
      this.confirmDeletionData.type = deletionType;
      this.confirmDeletionData.eventPayload = eventData;
      switch (deletionType) {
      case 'single_delete':
        this.confirmDeletionData.text = __('attachment_delete_confirm_text', { fileName: eventData.name });
        break;
      case 'multiple_delete':
        this.confirmDeletionData.text = __('attachment_multiple_delete_confirm_text');
        break;
      case 'delete_all':
        this.confirmDeletionData.text = __('attachment_delete_all_confirm_text');
        break;
      }
      this.confirmDeletionData.showPopup = true;
    },
    handleViewAttachment(attachment) {
      const attachmentType = attachment.name.split('.').pop().toLowerCase();
      let isOneDrive = [constants.ATTACHMENT_TYPE_KEYS.ONE_DRIVE].includes(attachment.attachmentType)
      let isGoogleDrive = [constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE].includes(attachment.attachmentType)

      userExtAnalytics.log('task_attachment_opened', { from: 'task' });

      if (IMAGE_EXTENSIONS.includes(attachmentType) && !isOneDrive) {
        const imagesAttachments = this.formattedAttachments.filter(entry => {
          const entryType = entry.name.split('.').pop() || '';

          return IMAGE_EXTENSIONS.includes(entryType);
        });

        storeGallery.commit('setImages', _.map(_.cloneDeep(imagesAttachments), item => {
          item.src = item.link;

          if (item.attachmentType === constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE) {
            item.src = `https://drive.google.com/uc?id=${item.attacherExternalAccessToken}`;
          }

          if (item.id === attachment.id) {
            item.selected = true;
          }

          return item;
        }));

        return storeGallery.commit('showGallery');
      }

      if (attachment.attachmentType === constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE && previewAvailabilityByType(attachment.mimeType)) {
        return GoogleDriveAttachmentsController.showAndLoadPreviewPopup(attachment);
      }

      if (isOneDrive || isGoogleDrive) {
        window.open(attachment.link, '_blank');

        return;
      }

      downloadSingle(attachment);
    },
    async deleteSelected() {
      if (!this.selectedAttachmentsIds.length || this.isDeleting) return;
      try {
        this.isDeleting = true;
        await this.$store.dispatch('attachments/deleteAttachmentsMultiple', {
          attachmentIds: this.selectedAttachmentsIds,
          projectId: this.taskData.gantt_id,
        });
        this.selectedAttachmentsIds = [];
      } catch (e) {
        console.error(e);
      } finally {
        this.isDeleting = false;
      }
    },
    async downloadSelected() {
      if (!this.selectedAttachmentsIds.length || this.isDeleting) {
        return;
      }

      const selectedAttachments = this.formattedAttachments
        .filter(attachment => this.selectedAttachmentsIds.includes(attachment.id) && attachment.downloadUrl);

      selectedAttachments.length === 1
        ? await downloadSingle(selectedAttachments[0])
        : await downloadMultipleAsZip(selectedAttachments);

      if (this.isAnySelectedAttachmentUploadedFromExternalProvider) {
        this.$toast.info(__('task_settings_attachments_only_local_downloaded_notification'));
      }
    },
    handleSelectAttachment(attachmentId) {
      if (this.selectedAttachmentsIds.includes(attachmentId)) {
        this.selectedAttachmentsIds = this.selectedAttachmentsIds.filter(item => item !== attachmentId);
      } else {
        this.selectedAttachmentsIds.push(attachmentId);
      }
    },
    deleteAttachment({ id }) {
      return this.$store.dispatch('attachments/deleteAttachment', {
        attachmentId: id,
        projectId: this.taskData.gantt_id,
      });
    },
    downloadAttachment: downloadSingle,
    showNewAttachmentContextMenu() {
      this.newAttachmentContextMenuPosition = this.$refs.newAttachmentButton.$el.getBoundingClientRect();
    },
    showSectionContextMenu() {
      this.sectionContextMenuPosition = this.$refs.sectionActionsBtn.$el.getBoundingClientRect();
    },
    switchViewMode() {
      this.selectedAttachmentsIds = [];
      const newMode = this.activeViewMode === VIEW_MODES.LIST
        ? VIEW_MODES.GALLERY
        : VIEW_MODES.LIST;

      this.activeViewMode = newMode;
      profile.saveSettings({ task_settings_attachments_view_mode: newMode });
    },
    async deleteAll() {
      try {
        this.isDeleting = true;
        await this.$store.dispatch('attachments/deleteAttachmentsMultiple', {
          attachmentIds: this.formattedAttachments.map(item => item.id),
          projectId: this.taskData.gantt_id,
        });
      } catch (e) {
        console.error('deleteAll', e);
      } finally {
        this.isDeleting = false;
      }
    },
    async handleSelectItemMenu(id) {
      switch (id) {
      case 'switch_view_mode':
        this.switchViewMode();
        break;
      case 'delete_all':
        this.showDeleteConfirmPopup('delete_all');
        break;
      case 'download_all':
        const localUploadedAttachments = this.formattedAttachments.filter(item => item.downloadUrl);

        if (!localUploadedAttachments.length) return;

        if (localUploadedAttachments.length > 1) {
          await downloadMultipleAsZip(localUploadedAttachments);
        } else {
          await downloadSingle(localUploadedAttachments[0]);
        }
        if (this.formattedAttachments.length !== this.attachmentIdsUploadedFromLocalDevice.length) {
          this.$toast.info(__('task_settings_attachments_only_local_downloaded_notification'));
        }

        break;
      }
    },
  },
};
</script>

<style module lang="less">

.task-settings-attachments {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .buttons-wrap {
    display: flex;
    align-items: center;
    .header-actions-btn {
      margin-left: 12px;
    }

    .delete-selected-btn {
      margin-right: 4px;
    }
  }

  .title-wrap {
    display: flex;
    align-items: center;

    h2, h2 > span {
      font-family: Lato-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
    }

    h2 {
      margin-right: 8px;
      color: #191919;
      span {
        color: #B2B2B2;
      }
    }
  }

  .attachments__list {
    display: flex;
    flex-direction: column;
  }

  .attachment {
    &__row {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 9px 0;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: -25px;
        width: calc(100% + 25px);
        height: 1px;
        background: #CCCCCC;
      }
    }

    &__image {
      object-fit: contain;
      width: 50px;
      height: 32px;
    }

    &__data {
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #191919;
      margin-bottom: 5px;
    }

    &__info {
      display: flex;
    }

    &__author, &__date, &__size  {
      font-family: Lato-Regular, sans-serif;
      font-size: 10px;
      line-height: 12px;
      color: #999999;
    }

    &__size {
      margin-left: 9px;
    }

  }

}

</style>
