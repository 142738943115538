<template>
  <core-popup
    show
    close-icon
    :title="locales('app_learnwnd_caption')"
    :body-style="isNewsTab ? 'noPaddingRight': ''"
    @onClickCancel="$emit('close')"
  >
    <template #body>
      <div :class="$style['body-wrapper']">
        <div :class="[$style.subheader, isNewsTab ? $style.subheader__news : '']">
          <vgp-tabs
            :tabs="tabs"
            active-by="type"
            :active="{ type: currentTab }"
            :class="$style.tabs"
            @onToggle="handleOnToggleTab"
          />
          <div :class="$style.links">
            <a
              v-for="(link, index) in availableLinks"
              :key="index"
              :href="link.url"
              :class="$style.link"
              @click.prevent="handleLinkClick(link)"
            >
              <span :class="$style['link__text']">
                {{ link.name }}
              </span>
              <svg-sprite
                v-if="link.newTabIcon"
                :name="'link-2'"
              />
            </a>
            <span />
          </div>
        </div>
        <keep-alive>
          <component :is="currentTab" />
        </keep-alive>
      </div>
    </template>
  </core-popup>
</template>

<script>
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';

import LearningCenterGuideTab from './components/learningCenterGuideTab.vue';
import LearningCenterNewsTab from './components/learningCenterNewsTab.vue';
import { POPUP_LINKS, POPUP_TAB, POPUP_TABS } from './learningCenter.config';
import constants from '../../helpers/constants';

export default {
  name: 'LearningCenterPopup',
  components: {
    CorePopup,
    [POPUP_TAB.GUIDE]: LearningCenterGuideTab,
    [POPUP_TAB.NEWS]: LearningCenterNewsTab,
  },
  props: {
    lastClickedTab: {
      type: String,
      default: '',
      validator(value) {
        return Object.values(POPUP_TAB).includes(value);
      },
    },
  },
  data() {
    return {
      locales: __,
      tabs: POPUP_TABS,
      currentTab: POPUP_TAB.GUIDE,
      links: POPUP_LINKS,
    };
  },
  computed: {
    availableLinks() {
      return this.links.filter(link => !link.hidden);
    },
    isNewsTab() {
      return this.currentTab === POPUP_TAB.NEWS;
    },
  },
  watch: {
    lastClickedTab: {
      handler(val) {
        if (val) {
          this.currentTab = val;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    userExtAnalytics.log('learning_center_window_open');
    if (this.checkNewsAboutToShow()) {
      this.currentTab = POPUP_TAB.NEWS;
      this.$store.dispatch('user/updateActivityStatus', {
        activityName: constants.USER_ACTIVITIES.LAST_NEWS_ID,
        status: window.user.lastNewsEntryId,
      });
    }
  },
  beforeDestroy() {
    this.$emit('rememberLastClickedTab', this.currentTab);
    userExtAnalytics.log('learning_center_window_hide');
  },
  methods: {
    handleOnToggleTab({ type, analyticType }) {
      this.currentTab = type;
      userExtAnalytics.log('learning_center_window_open', { type: analyticType });
    },
    checkNewsAboutToShow() {
      const viewActivity = window.user.activity.find(entry => entry.name === 'lastViewedNewsEntryId');
      const lastDisplayedActivityId = viewActivity && viewActivity.activity_status;
      const alreadyDisplayed = lastDisplayedActivityId && lastDisplayedActivityId === window.user.lastNewsEntryId;

      return (
        window.user.lastNewsEntryId
        && moment(window.user.registrationTime) < moment(window.user.lastNewsEntryDate)
        && !alreadyDisplayed
      );
    },
    handleLinkClick(link) {
      userExtAnalytics.log('learning_center_window_open', { type: link.analyticType });
      if (link.clickHandler) {
        link.clickHandler();
      }
    },
  },
};
</script>

<style module src="./less/learningCenterPopup.less" lang="less">
</style>
