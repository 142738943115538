var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.colors_list },
    _vm._l(_vm.colors, function (color, index) {
      return _c(
        "div",
        {
          key: index,
          class: _vm.$.colors_list_item,
          style: {
            "background-color":
              _vm.hoverColor === color.id ? color.hex2 : color.hex,
          },
          on: {
            mouseover: function ($event) {
              _vm.hoverColor = color.id
            },
            mouseleave: function ($event) {
              _vm.hoverColor = null
            },
            click: function ($event) {
              return _vm.selectColor(color)
            },
          },
        },
        [
          _vm.isSelectedColor(color)
            ? _c("sprite", { attrs: { name: "check" } })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }