<template>
  <button
    ref="button"
    :class="[
      'vgp-interactive-element',
      $style.button,
      active ? $style.active : '',
      $style[size],
      $style[currentType],
      isLoading ? $style.loading : '',
      label ? $style.with_label : '',
      label ? $style[`icon_${iconSide}`] : '',
      bold ? $style.bold : '',
    ]"
    :style="customStyle"
    :disabled="disabled"
    @click="onClick"
  >
    <template v-if="!isLoading && icon">
      <sprite
        :key="icon.name + icon.type"
        :name="icon.name || 'check'"
        :type="icon.type || 'regular'"
        :size="icon.size || 24"
        :class="$style.icon"
      />
      <div
        v-if="label"
        :class="$style.label"
      >
        {{ label }}
      </div>
    </template>
    <vue-ellipse-progress
      v-else
      v-bind="loaderConfig"
    />
  </button>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress';
import sprite from '../../VueIcon/svgSprite.vue';
import { BUTTON_TYPE_TO_LOADER_CONFIG_MAP } from '../vgpButton.config';

export default {
  name: 'VgpIconButton',
  components: {
    sprite,
    VueEllipseProgress,
  },
  props: {
    icon: {
      type: Object, // { name, type, size }
      required: true,
      default: null,
    },
    iconSide: {
      type: String,
      required: false,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator(value) {
        return ['small', 'middle', 'large'].includes(value);
      },
    },
    type: {
      type: String,
      required: false,
      default: 'simple',
      validator(value) {
        return ['simple', 'colored'].includes(value);
      },
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    bold: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    colors: {
      type: Object,
      required: false,
      default() {
        return {
          color: '',
          labelColor: '',
          activeColor: '',
          hoverBg: '',
          activeBg: '',
          activeHoverBg: '',
        };
      },
    },
    sizes: {
      type: Object,
      required: false,
      default: () => ({
        labelFontSize: '',
        labelLineHeight: '',
      }),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    onClick: null,
  },
  data() {
    return {
      currentColor: '',
      currentEmptyColor: '',
    };
  },
  computed: {
    currentType() {
      const isCustom = Object.values(this.colors).some(value => value !== '');

      return isCustom ? 'custom' : this.type;
    },
    customStyle() {
      if (this.currentType !== 'custom') return {};

      return {
        '--color': this.colors?.color || 'inherit',
        '--label-color': this.colors?.labelColor || 'var(--color)',
        '--active-color': this.colors?.activeColor || 'var(--color)',
        '--bg': this.colors?.bg || 'transparent',
        '--hover-bg': this.colors?.hoverBg || 'var(--bg)',
        '--active-bg': this.colors?.activeBg || 'var(--hover-bg)',
        '--active-hover-bg': this.colors?.activeHoverBg || 'var(--active-bg)',
        '--label-font-size': this.sizes?.labelFontSize || '12px',
        '--label-line-height': this.sizes?.labelLineHeight || '18px',
      };
    },
    loaderConfig() {
      return {
        ...BUTTON_TYPE_TO_LOADER_CONFIG_MAP.primary,
        color: this.currentColor,
        emptyColor: this.currentEmptyColor,
      };
    },
  },
  watch: {
    isLoading(value) {
      if (value) this.getCurrentColors();
    },
  },
  methods: {
    getCurrentColors() {
      const styles = window.getComputedStyle(this.$refs.button);
      const color = styles.getPropertyValue('color');

      this.currentColor = color;

      if (color.includes('rgb') && !color.includes('rgba')) {
        this.currentEmptyColor = color.replace(')', ', 0.3)').replace('rgb', 'rgba');
      }
    },
    onClick(e) {
      this.$emit('onClick', e);
    },
  },
};
</script>

<style module src='./iconButton.style.less'></style>
