import Vue from 'vue';
import Vuex from 'vuex';
import app from '../app';
import TasksStore from './tasks';
import ResourcesStore from './resources';
import RolesStore from './roles';
import PaymentStore from './payment';
import HeaderStore from '../views/header/headerStore';
import ProfileSettingsStore from '../views/profileSettings/profileSettingsStore';
import GlobalPopup from '../views/common/VueComponents/popups/globalPopup/globalPopupStore';
import PassStrength from '../views/common/VueComponents/popups/passStrengthPopup/passStrengthStore';
import router from '../router/main';

import filterStore from '../views/filter/filterStore';
import FooterStore from '../views/footer/footerStore';
import TooltipStore from '../views/tooltip/tooltipStore';
import PaymentPlansStore from '../views/paymentPlans/paymentPlansStore';
import pricingHelper from '../helpers/pricingHelper';
import TeamStore from './teamStore';
import UserStore from './user';
import onboardingStore from '../views/onboarding/onboardingStore';
import CommentsStore from './comments';
import AttachmentsStore from './attachments';
import CopyPasteSettingsStore from './copyPaste/index';
import columnsStore from './columns/index';
import progressBarStore from './progressBar/index';
import userNotificationStore from './userNotification';
import IntegrationGoogleStore from './integrations/integrationGoogle';
import IntegrationOutlookStore from './integrations/integrationOutlook';

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    tasksModel: TasksStore,
    resourcesModel: ResourcesStore,
    roles: RolesStore,
    paymentModel: PaymentStore,
    filter: filterStore,
    profileSettings: ProfileSettingsStore,
    globalPopup: GlobalPopup,
    passStrengthPopup: PassStrength,
    headerView: HeaderStore,
    footer: FooterStore,
    tooltip: TooltipStore,
    paymentPlans: PaymentPlansStore,
    teamSetting: TeamStore,
    user: UserStore,
    onboarding: onboardingStore,
    comments: CommentsStore,
    attachments: AttachmentsStore,
    copyPaste: CopyPasteSettingsStore,
    columns: columnsStore,
    progress: progressBarStore,
    userNotification: userNotificationStore,
    integrationGoogle: IntegrationGoogleStore,
    integrationOutlook: IntegrationOutlookStore,
  },
  state: {
    windowParams: {},
    leftSidebarWidth: 0,
    headerHeight: 0,
    footerHeight: 30,
    appMode: {},
    contentParams: {},
    blockSideBar: false,
    globalToast: false,
    ganttInProgress: false,
  },
  getters: {
    getWindowParams: (state, getters) => {
      const accessToFeatures = {
        workload: pricingHelper.checkPricingAccess('workload'),
      };

      let params;
      const leftSidebarWidth = getters.leftSidebarWidth;
      const headerHeight = getters['headerView/height'];
      const footerHeight = getters.footerHeight;
      const isGanttVisible = router.currentRoute.path.includes('gantt');
      const isGlobalWorkloadRoute = router.currentRoute.name === 'workload';
      const isTaskInGlobalWorkload = router.currentRoute.name === 'workloadTaskRoute';
      const isProjectWorkloadRoute = (router.currentRoute.name === 'project'
        || router.currentRoute.name === 'projectsMultiView'
        || router.currentRoute.name === 'taskRoute')
        && router.currentRoute.params.mode === 'workload';
      const isTaskVisible = router.currentRoute.path.includes('task/');
      const isProfileSettingsPage = router.currentRoute.path.includes('settings');
      const isExportPage = router.currentRoute.path.includes('export');
      const isCommentRoute = router.currentRoute.name === 'commentsRoute';
      const isAttachmentsRoute = router.currentRoute.name === 'attachmentsRoute';
      const isImportPreview = router.currentRoute.path.includes('importpreview');
      // const isListTask = router.currentRoute.path.includes('list/task');
      const leftDelta = 0;
      const height = document.body.clientHeight - headerHeight - footerHeight;
      const reuqiredDynamicDataLoaded = getters.reuqiredDynamicDataLoaded;

      // if (isTaskVisible && isGanttVisible) {
      //   const taskNameNode = document.querySelector(`[data-column-id="${constants.GANTT_BASE_COLUMNS.TASK_NAME}"]`);
      //
      //   leftDelta = taskNameNode ? (taskNameNode.offsetLeft + taskNameNode.offsetWidth) : 0;
      // }

      // leftDelta = isListTask ? 0 : leftDelta;

      params = {
        height,
        width: document.body.clientWidth - leftSidebarWidth - leftDelta,
        left: leftSidebarWidth + leftDelta,
        top: headerHeight + 1,
        zIndex: 9,
        visibility: 'visible',
      };

      if (isTaskInGlobalWorkload && reuqiredDynamicDataLoaded) {
        params = { visibility: 'hidden' };
      }

      if ((isGanttVisible || (isProjectWorkloadRoute && accessToFeatures.workload)) /* && !isTaskVisible */) {
        params = { visibility: 'hidden' };
      }

      if (isGlobalWorkloadRoute && accessToFeatures.workload) {
        params = {
          height,
          width: document.body.clientWidth - leftSidebarWidth,
          left: leftSidebarWidth,
          top: headerHeight + 1,
          zIndex: 9,
          visibility: 'visible',
        };

        const result = { ...params, ...state.windowParams };

        Object.keys(result).forEach(key => {
          if (['left', 'top', 'height', 'width'].includes(key)) {
            if (typeof result[key] === 'number') {
              result[key] += 'px';
            }
          }
        });

        return result;
      }

      if (isExportPage) {
        params = {
          height: document.body.clientHeight,
          width: document.body.clientWidth,
          top: 0,
          left: 0,
          visibility: 'visible',
        };
      }

      // TODO should remove when comments and attachments will in pages
      if (isCommentRoute || isAttachmentsRoute || app.config.mode.isLink) {
        params = {
          height: 0,
          width: 0,
          top: 0,
          left: 0,
          zIndex: 1000,
          visibility: 'visible',
        };
      }

      if (isProfileSettingsPage) {
        params.height = '100vh';
      }

      if (app.config.mode.isExport) {
        params.pointerEvents = 'none';
        params.height = document.body.clientHeight;
        params.background = 'none';
      }

      if (isImportPreview) {
        params.zIndex = 1000;
      }

      const result = { ...state.windowParams, ...params };

      Object.keys(result).forEach(key => {
        if (['left', 'top', 'height', 'width'].includes(key)) {
          if (typeof result[key] === 'number') {
            result[key] += 'px';
          }
        }
      });

      return result;
    },
    contentParams: (state, getters) => state.contentParams,
    leftSidebarWidth: state => state.leftSidebarWidth,
    headerHeight: state => state.headerHeight,
    footerHeight: state => state.footerHeight,
    blockSideBar: state => state.blockSideBar,
    reuqiredDynamicDataLoaded: (state, getters) => {
      const customFieldsValuesLoaded = getters['columns/valuesLoaded'];

      return customFieldsValuesLoaded;
    },
  },
  mutations: {
    setGanttInProgress(state, flag) {
      state.ganttInProgress = flag;
    },
    setWindowParams(state, payload) {
      state.windowParams = { ...state.windowParams, ...payload };
    },
    setLeftSidebarWidth(state, width) {
      state.leftSidebarWidth = width;
    },
    setHeaderHeight(state, height) {
      state.headerHeight = height;
    },
    setAppMode(state, payload) {
      state.appMode = payload;
    },
    updateContentParams(state) {
      const headerHeight = this.getters['headerView/height'];
      const footerHeight = this.getters.footerHeight;
      const params = {
        top: headerHeight + 1,
        bottom: footerHeight,
        width: document.body.clientWidth - this.getters.leftSidebarWidth,
        height: document.body.clientHeight - headerHeight - footerHeight,
        left: this.getters.leftSidebarWidth,
        visibility: 'visible',
      };

      if (app.config.mode.isExport) {
        params.height = document.body.clientHeight;
        params.left = 0;
      }
      if (app.config.mode.isLink) {
        params.height = document.body.clientHeight - headerHeight;
        params.left = 0;
      }

      state.contentParams = params;
    },
    setBlockedSideBar(state, flag) {
      state.blockSideBar = flag;
    },
  },
  actions: {
    updateWindowParams({ commit }, payload) {
      commit('setWindowParams', payload);
      commit('updateContentParams');
    },
  },
});

store.subscribe((mutation, state) => {
  if (mutation.type.includes('resourcesModel')) {
    app.trigger('resources:model:afterMutation', mutation);
  }
});

app.on('app:start', () => {
  store.commit('setAppMode', app.config.mode);
  store.dispatch('roles/loadData');
  store.dispatch('tasksModel/loadData');
  store.dispatch('resourcesModel/loadData');
  store.dispatch('teamSetting/init');
  store.dispatch('user/getActivities');
  store.dispatch('columns/loadData');
  store.dispatch('userNotification/loadUserSettingsDataNotification');
  if (!app.config.mode.isLink) {
    store.dispatch('integrationGoogle/loadData');
    store.dispatch('integrationOutlook/loadData');
  }

  // store.dispatch('teamSetting/checkIntegrationReconnect');
});

// hack for migration from webix to vuex models
// use only if there is no other choice!!!
app.callGlobalStore = function (type, name, params) {
  if (type === 'getter') {
    return params ? store.getters[name](...params) : store.getters[name];
  }

  if (type === 'action') {
    return store.dispatch(name, ...params);
  }
};

export default store;
