/* eslint-disable */
import app, { appIds } from '../../../app';
import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';

import projectsModel from '../../../models/projects';
import ganttViewModel from '../../../models/ganttViewModel';
import timeTrackingModel from '../../../models/timeTracking';
import userProjCfg from '../../../models/userProjectConfigs';
import massChangeModel from './massChange';

import customHelper from '../../../helpers/custom';
import constantsCommon from '../../../helpers/constants';
import dateHelper from '../../../helpers/dateFormats';
import timeParser from '../../../helpers/timeParser';

import userCustomColumnModule from './userCustomColumns';

import overdue_icon from '../../../svg/deadline/deadline_time.svg';
import icon_edit from '../../../svg/webix_material/edit_icon.svg';
import icon_close from '../../../svg/ic_close.svg';
import icon_timer from '../../../svg/footerToolbar/timer.svg';
import add_new_subproject from '../../../svg/projects_icons/add_new_subproject.svg';

import routerHelper from '../../../helpers/router';
import logInternalHelper from '../../../helpers/logInternal';

import icArrow from '../../../svg/massChange/icoMenuArrow.svg';
import icNotSelected from '../../../svg/massChange/icoItemNotSelected.svg';
import icSelected from '../../../svg/massChange/icoItemSelected.svg';
import icSelectedPart from '../../../svg/massChange/icoItemPartSelected.svg';
import icMassChangeHeader from '../../../svg/massChange/icoHeader.svg';
import ic_project from '../../../svg/project_popup/ic_project_grid.svg';

import moduleDisableGantt from './disableGantt';
import { menuItemIds, getMenu, getMenuItem } from './mcSelectMenuData';

import { menuItemsIds, getMenuConfig } from '../../dataConfig/mcActionsMenuData';
import rights from '../../../components/rights';
import pricingHelper from '../../../helpers/pricingHelper';
import Store from '../../../store/main';
import globalStore from "$$store/main";

const __ = window.__;

if (!webix.env.touch && webix.env.scrollSize) {
  webix.CustomScroll.init();
}

const {
  SELECT_ALL, UNSELECT_ALL,
  SELECT_CURRENT, UNSELECT_CURRENT,
} = menuItemIds;

const {
  popupViews: {
    ID_VIEW_POPUP_MC_SELECT_MENU,
    ID_VIEW_POPUP_MC_ACTIONS_MENU,
    ID_VIEW_POPUP_CUSTOMGRID,
  },
  events: {
    ID_EVENT_MC_SELECT_ACTION,
    ID_EVENT_MASSCHANGE_UPDATE_TASKS,
    ID_EVENT_MASSCHANGE_UPDATESTATE,
    ID_EVENT_MC_ACTIONS_CLICK,
    ID_EVENT_APP_ROUTECHANGED,
  },
} = appIds;

const constants = {
  buttons: {
    width: 50,
    min_width: 50,
  },
  wbs: {
    width: 45,
    min_width: 45,
  },
  overdue: {
    width: 24,
    min_width: 24,
  },
  overload: {
    width: 24,
    min_width: 24,
  },
  comments: {
    width: 24,
    min_width: 24,
  },
  attachments: {
    width: 24,
    min_width: 24,
  },
};
const getWidthFromLocale = function () {
  let contextMenuWidth = 220;

  if (pricingHelper.getPlans('custom_fields')) {
    if (user.locale === 'es' || user.locale === 'de') contextMenuWidth = 240;
    if (user.locale === 'kr') contextMenuWidth = 200;
  }

  return contextMenuWidth;
};
let innerSettings = {
  getPosition: null,
  columns: [],
  clickMainCheckbox: null,
  clickOptionalCheckbox: null,
  hidePopup: null,
  addColumnWidth: getWidthFromLocale(),
};
const innerObject = {
  init: {
    run() {
      innerObject.init.helpers.initGridsByConfig();
    },
    afterInit() {
      userCustomColumnModule.init();
      innerObject.helpers.checkColumnsVisibilityAndOrder();
    },
    reinit() {
      userCustomColumnModule.reInit();
      innerObject.init.helpers.userSettingsInit();
      innerObject.helpers.checkColumnsVisibilityAndOrder();
    },
    helpers: {
      initGridsByConfig() {
        innerObject.init.helpers.userSettingsInit();

        _.each(innerObject.settings.initGridColumns.advancedOptions, (value, key) => {
          if (value && value.check) {
            innerObject.helpers.timeToStringHelper[key] = gantt.date.date_to_str(dateHelper.getDateFormatForGanttGrid());
          } else {
            innerObject.helpers.timeToStringHelper[key] = gantt.date.date_to_str(dateHelper.getDateFormat());
          }
        });

        gantt.config.date_grid = dateHelper.getDateFormatForGanttGrid();

        gantt.config.columns = [
          {
            name: 'masschange',
            min_width: 1,
            max_width: 1,
            width: 1,
            resize: false,
            hide: !innerObject.helpers.isMassChangeColVisible(),
            label: /* getMCHeaderTemplate(massChangeModel.isEnabled()) */'',
            order: 0,
            isShowCheckbox: false,
            task_types: ['project', 'task', 'milestone'],
            template: getMCItemTemplate,
          },
          {
            name: 'overdue',
            min_width: 24,
            max_width: 24,
            width: 24,
            resize: false,
            label: '',
            order: 0,
            task_types: ['project', 'task', 'milestone'],
            template(task) {
              const total_estimate = (task.type === 'project' && task.parent === 0);
              const dragIndicator = (app.config.mode.isBase && !total_estimate && rights.project.hasRight(task.gantt_id, 'drag_and_drop')) ? `<div class="drag-indicator">${gantt._getSvgIcon('dragdrop', 'regular', 24)}</div>` : '';

              if (task.deadline && task.end_date > task.deadline) {
                return `${dragIndicator}<div class="overdue-indicator">${overdue_icon}</div>`;
              }

              return dragIndicator;
            },
          },
          {
            name: 'overload',
            min_width: 24,
            max_width: 24,
            width: 24,
            resize: false,
            label: '',
            order: 0,
            task_types: ['task', 'milestone'],
            hide: true,
            isShowCheckbox: true,
            template(task) {
              if (task.type === gantt.config.types.button || task.type === gantt.config.types.project) {
                return '<div></div>';
              }

              if (gantt._getOverloadedResources({ taskData: task })) {
                return `<div class="overload-indicator tooltip-gantt" data-align="${((Store.getters['leftSidebarWidth'] === 40) || app.config.mode.isExport) ? 'left' : 'center'}" data-key="overload_tooltip_grid">${gantt._getSvgIcon('resource', 'regular', 24)}</div>`;
              }

              return '';
            },
          },
          {
            name: 'comments',
            min_width: 24,
            max_width: 24,
            width: 24,
            resize: false,
            label: '',
            order: 0,
            task_types: ['project', 'task', 'milestone'],
            hide: true,
            isShowCheckbox: true,
            template(task) {
              if (task.type === gantt.config.types.button) {
                return '<div></div>';
              }

              return '';
            },
          },
          {
            name: 'attachments',
            min_width: 24,
            max_width: 24,
            width: 24,
            resize: false,
            label: '',
            order: 0,
            task_types: ['project', 'task', 'milestone'],
            hide: true,
            isShowCheckbox: true,
            template(task) {
              if (task.type === gantt.config.types.button) {
                return '<div></div>';
              }

              return '';
            },
          },
          {
            name: 'wbs',
            label: ' ',
            task_types: ['project', 'task', 'milestone', 'button'],
            width: constants.wbs.width,
            min_width: constants.wbs.min_width,
            max_width: constants.wbs.min_width,
            hide: true,
            isShowCheckbox: true,
            order: 1,
            template(task) {
              if (
                !innerObject.settings.initGridColumns.wbs
                || !gantt.calculateTaskLevel(task.id)
                || massChangeModel.isEnabled()
              ) {
                return '';
              }

              if (gantt.config.multiview) {
                if (gantt.calculateTaskLevel(task) === 1) {
                  return `<div class='gantt_grid_wbs gantt_grid_wbs_icon'>${ic_project}</div>`;
                }
              }

              return `<span class='gantt_grid_wbs'>${innerObject.handlers.getTaskPath(task)}</span>`;
            },
          },
          {
            name: 'text',
            tree: true,
            resize: true,
            hide: false,
            order: 2,
            // width: "*",
            control_type: 'text',
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_task_name')}</span>`,
            task_types: ['project', 'task', 'milestone'],
            min_width: 50,
            template(task) {
              const isMultiview = gantt.config.multiview;

              if (task.type === gantt.config.types.button) {
                const addTaskAndMilestoneDisableLevel = 1;

                const addTaskMilestone = `<span class='button js_add_milestone'>${__('gantt_add_milestone')}</span>`;
                const addTaskButton = `<span class='button js_add_task'>${__('gantt_add_task_placeholder')}</span>`;
                const addTaskIconButton = `<span class='button button-icon js_add_task'>${gantt._getSvgIcon('plus-2', 'regular', 20)}</span>`;
                const separatorLine = `<span class='separator-line'></span>`;

                const baselineLine = gantt.config.baseline === 1 ? "<span class='line baseline_active'></span>" : "<span class='line'></span>";
                // const input = `<input type='text' class='gantt_create_task_field mousetrap' style='height: ${(gantt.config.row_height - 1)}px;' data-parent-id='${task.parent}'/>`;

                const addTaskAndMilestone = `
                  <div class='project-control'>
                    ${/* input + */baselineLine}
                    <div class='buttons'>
                      ${addTaskIconButton}
                      ${addTaskButton}${separatorLine}${addTaskMilestone}
                    </div>
                  </div>`;

                if (!(isMultiview && gantt.calculateTaskLevel(task.id) === addTaskAndMilestoneDisableLevel)) {
                  return addTaskAndMilestone;
                }

                return '';
              }

              if (isMultiview && task.$level === 1) {
                const project = projectsModel.getProjectDataById(task.gantt_id);

                if (project) {
                  return project.name;
                }
              }

              return customHelper.formatTaskName(task.text).trim();
            },
            inline_validate(value) {
              if (customHelper.validateTaskName(value)) {
                return true;
              }

              webix.message({ type: 'warning', text: __('settings_msg_task_name_invalid') });

              return false;
            },
          },
          {
            align: 'center',
            name: 'start_date',
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 3,
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_start_date')}</span>`,
            control_type: 'datepicker',
            task_types: ['project', 'task', 'milestone'],
            min_width: 50,
            template(task) {
              if (task.type === gantt.config.types.button) {
                return '';
              }

              return innerObject.helpers.timeToStringHelper.start_date(task.start_date);
            },
          },
          {
            align: 'center',
            name: 'end_date',
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 4,
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_end_date')}</span>`,
            control_type: 'datepicker',
            task_types: ['task'],
            min_width: 50,
            template(task) {
              return (task.type === gantt.config.types.button) ? '' : innerObject.helpers.timeToStringHelper.end_date(task.end_date);
            },
          },
          {
            align: 'center',
            name: 'create_date',
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 5,
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_create_date')}</span>`,
            control_type: 'datepicker',
            task_types: ['project', 'task', 'milestone'],
            min_width: 50,
            template(task) {
              if (gantt.config.multiview && task.$level === 0) {
                return '';
              }

              const date = task.created_at ? new Date(task.created_at) : task.start_date;

              return (task.type === gantt.config.types.button) ? '' : innerObject.helpers.timeToStringHelper.create_date(date);
            },
          },
          {
            align: 'center',
            label: __('gantt_grid_creator'),
            name: 'creator',
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 5,
            control_type: 'text',
            task_types: ['task', 'milestone'],
            min_width: 50,
            template(task) {
              if (task.type === gantt.config.types.button || task.type === gantt.config.types.project) {
                return '';
              }

              const resourceData = globalStore.getters['resourcesModel/getResourceById'](task.owner_id);

              if (resourceData) {
                return resourceData.name;
              }

              return __('creator_removed_from_team');
            },
          },
          {
            align: 'right',
            name: 'duration',
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_duration')}</span>`,
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 5,
            control_type: 'text',
            task_types: ['task'],
            template(task) {
              if (task.type === gantt.config.types.button || task.type === gantt.config.types.milestone) {
                return '';
              }

              if (gantt.config.multiview && task.$level === 0) {
                return '';
              }

              const value = gantt.calculateDuration(moment(task.start_date).toDate(), moment(task.end_date).toDate(), task);

              let durationData = gantt.config.durationData;
              const projectCalendar = gantt.getCalendar(task.gantt_id);

              if (projectCalendar) {
                durationData = {
                  showDay: Object.keys(projectCalendar.getConfig().dates).filter(key => +projectCalendar.getConfig().dates[key]),
                  showTime: _.clone(projectCalendar.getConfig().hours),
                };
              }

              const text = timeParser.output(value, {
                durationData,
                durationStep: gantt.config.duration_view,
                prop: 'duration',
              });

              return text;
            },
            inline_validate(value) {
              let durationData = gantt.config.durationData;
              const task = gantt.getTask(gantt._inline_edit._currentTask);

              if (task) {
                const projectCalendar = gantt.getCalendar(task.gantt_id);

                durationData = {
                  showDay: Object.keys(projectCalendar.getConfig().dates).filter(key => +projectCalendar.getConfig().dates[key]),
                  showTime: _.clone(projectCalendar.getConfig().hours),
                };
              }

              const result = timeParser.input(value, {
                durationData,
                durationStep: gantt.config.duration_view,
                prop: 'duration',
              });

              if (result.value > 0 && result.value <= constantsCommon.DURATION_LIMITS.minute) {
                return result;
              }

              return false;
            },
          },
          {
            align: 'right',
            name: 'time_tracking',
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_time_tracking')}</span>`,
            resize: true,
            hide: false,
            isShowCheckbox: true,
            order: 6,
            control_type: 'time_tracking',
            task_types: ['task', 'milestone', 'project'],
            template(task, excelExport) {
              if (task.type === gantt.config.types.button) {
                return '';
              }

              const taskLogs = timeTrackingModel.getLogsByParams(task.gantt_id, task.id);
              let value = taskLogs && taskLogs.sum || 0;
              let sumForParent = 0;

              if (task.type === gantt.config.types.project) {
                sumForParent = innerObject.helpers.getSumOfLogsForParent(task.id);
              }
              // else {
              const userActivity = _.find(user.activity, o => o.name === 'timeTracker');
              let trackerData = null;

              if (userActivity) {
                trackerData = JSON.parse(userActivity.settings);
              }

              if (trackerData && trackerData.task_id === task.id) {
                return icon_timer;
              }
              // }

              if (task.$level === 0) {
                value = sumForParent;
              }

              const text = timeParser.output(value, {});

              let template = `<div class="time_tracking_in_grid">${text}</div>`;

              if (task.type === gantt.config.types.project && (sumForParent + value) && task.$level !== 0) {
                const textSum = timeParser.output(sumForParent + value, {});

                template = `<div class="time_tracking_in_grid sum"><div class="sum_label">${`${__('sum_time_label')} ${textSum}`}</div><div class="text">${text}</div></div>`;
              }

              return excelExport ? (sumForParent + value) : template;
            },
          },
          {
            align: 'center',
            name: 'progress',
            label: `<span class='grid_head_cel_label'>${__('gantt_grid_progress')}</span>`,
            hide: false,
            isShowCheckbox: true,
            order: 6,
            resize: true,
            control_type: 'counter',
            task_types: ['task', 'milestone'],
            template(task) {
              if (task.type === gantt.config.types.button || (gantt.config.multiview && gantt.calculateTaskLevel(task.id) === 0)) {
                return '';
              }

              return `${parseFloat(task.progress * 100).toFixed(0)}%`;
            },
            inline_validate(value) {
              if (value >= 0 && value <= 100) {
                return true;
              }

              return false;
            },
          },
        ];
      },
      updateDurationGrid(newGridColumns) {
        const newGridKeys = newGridColumns && Object.keys(newGridColumns) || [];

        if (!gantt.getGridColumn('duration')) {
          return;
        }

        gantt.getGridColumn('duration').label = `<span class='grid_head_cel_label'>${__('gantt_grid_duration')}</span>`;

        if (newGridColumns && newGridKeys.length) {
          gantt.config.columns = gantt.config.columns.map((columnData, index) => {
            newGridKeys.forEach(gridName => {
              if (gridName === columnData.name) {
                columnData.hide = !newGridColumns[gridName];
              }
            });

            return columnData;
          });
        }
      },
      userSettingsInit() {
        const projectData = ganttViewModel.getActiveViewData();
        const projectConfig = ganttViewModel.getProjectConfig();

        if (app.config.mode.isLink && projectData.userConfig) {
          const userConfig = projectData.userConfig.userConfig;

          if (userConfig && userConfig.initGridColumns) {
            projectConfig.initGridColumns = userConfig.initGridColumns;
          }
        }

        innerObject.settings.initGridColumns = _.assignIn({
          overdue: true,
          overload: false,
          wbs: true,
          comments: false,
          attachments: false,
          start_date: false,
          end_date: false,
          create_date: false,
          creator: false,
          estimation: false,
          total_price: false,
          actual_cost: false,
          resource_id: true,
          owner_id: false,
          progress: false,
          duration: false,
          time_tracking: false, //! app.config.mode.isExport,
          priority: false,
          status: true,
          predecessors: false,
          jira_key: projectData && !!projectData.is_jira,
        }, projectConfig.initGridColumns || {});

        if (projectData && projectData.is_jira) innerObject.settings.initGridColumns.creator = false;

        innerObject.settings.initGridColumns.advancedOptions = _.assignIn({
          start_date: {
            check: false,
          },
          end_date: {
            check: false,
          },
          create_date: {
            check: false,
          },
        }, projectConfig.initGridColumns ? projectConfig.initGridColumns.advancedOptions || {} : {});
        innerObject.settings.initGridColumns.sizes = _.assignIn({
          masschange: {
            width: 1,
            min_width: 1,
          },
          overdue: {
            width: constants.overdue.width,
            min_width: constants.overdue.min_width,
          },
          overload: {
            width: constants.overload.width,
            min_width: constants.overload.min_width,
          },
          comments: {
            width: constants.comments.width,
            min_width: constants.comments.min_width,
          },
          attachments: {
            width: constants.attachments.width,
            min_width: constants.attachments.min_width,
          },
          wbs: {
            width: constants.wbs.width,
            min_width: constants.wbs.min_width,
          },
          text: {
            width: 200,
            min_width: 100,
          },
          start_date: {
            width: 130,
            min_width: gantt.config.min_grid_column_width,
          },
          end_date: {
            width: 130,
            min_width: gantt.config.min_grid_column_width,
          },
          create_date: {
            width: 96,
            min_width: gantt.config.min_grid_column_width,
          },
          creator: {
            width: 96,
            min_width: gantt.config.min_grid_column_width,
          },
          duration: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          estimation: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          time_tracking: {
            width: 140,
            min_width: gantt.config.min_grid_column_width,
          },
          progress: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          resource_id: {
            width: 80,
            min_width: gantt.config.min_grid_column_width,
          },
          owner_id: {
            width: 100,
            min_width: gantt.config.min_grid_column_width,
          },
          total_price: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          actual_cost: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          predecessors: {
            width: 70,
            min_width: gantt.config.min_grid_column_width,
          },
          buttons: {
            width: constants.buttons.width,
            min_width: gantt.config.min_grid_column_width,
          },
          default: {
            width: 80,
            min_width: gantt.config.min_grid_column_width,
          },
        }, projectConfig.initGridColumns ? projectConfig.initGridColumns.sizes || {} : {});

        innerObject.settings.initGridColumns.advancedOptions = _.assignIn({
          start_date: {
            check: false,
          },
          end_date: {
            check: false,
          },
          create_date: {
            check: false,
          },
        }, projectConfig.initGridColumns ? projectConfig.initGridColumns.advancedOptions || {} : {});

        innerObject.settings.initGridColumns.sizes.buttons = {
          width: constants.buttons.width,
          min_width: constants.buttons.width,
        };

        innerObject.settings.initGridColumns.sizes.wbs = {
          width: constants.wbs.width,
          min_width: constants.wbs.min_width,
        };

        innerObject.settings.initGridColumns.sizes.overdue = {
          width: constants.overdue.width,
          min_width: constants.overdue.min_width,
        };

        innerObject.settings.initGridColumns.sizes.overload = {
          width: constants.overdue.width,
          min_width: constants.overdue.min_width,
        };

        innerObject.settings.initGridColumns.sizes.masschange = {
          width: 1,
          min_width: 1,
        };

        innerObject.settings.initGridColumns = innerObject.helpers.validateColumnsSizes(innerObject.settings.initGridColumns);

        if (app.config.mode.isExport) {
          innerObject.settings.initGridColumns.comments = false;
          innerObject.settings.initGridColumns.attachments = false;
        }

        if (app.config.mode.isLink) {
          innerObject.settings.initGridColumns.buttons = false;
        }

        innerObject.handlers.projectConfigUpdate();
        innerObject.handlers.updateDateTimeColumns();
      },
    },
  },
  handlers: {
    //
    onMCUpdateState(enable) {
      moduleDisableGantt.disableGanttForMassChange(enable);

      // !TODO: update column
      // setTimeout(() => {
      //   innerObject.handlers.updateColumnsHeaders();
      // }, 0);
      // projectsModel.checkParamsToUpdateAndUpdate({key: "initGridColumns", value: innerObject.settings.initGridColumns});
      // innerObject.handlers.helpers.updateColumnTemplate("masschange");

      // const __rowSelector = '.dhx_gantt_container .gantt_layout_content .gantt_grid .gantt_grid_data .gantt_row';
      // setTimeout(() => {

      // document.querySelectorAll(__rowSelector).forEach((entry) => {
      //   const idTask = entry.getAttribute('task_id');

      //   if (idTask) {
      //     gantt.refreshTask(idTask);
      //   };
      // });

      // }, 0);

      gantt.callEvent('ganttRender'); // !FIXME: performance
    },
    onMCUpdateTasks(idTask, tasks, parents) {
      const __maxTaskCount = 3;

      if (tasks.length < __maxTaskCount) {
        tasks.forEach(innerObject.helpers.updateBySelector);
        setTimeout(() => parents.forEach(innerObject.helpers.updateBySelector), 0);

        return;
      }

      const __rowSelector = '.dhx_gantt_container .gantt_layout_content .gantt_grid .gantt_grid_data .gantt_row';

      setTimeout(() => {
        document.querySelectorAll(__rowSelector).forEach(entry => {
          const idTask = entry.getAttribute('task_id');

          if (idTask) {
            gantt.refreshTask(idTask);
          }
        });
      }, 0);
    },
    //
    onRouteChanged(routeEntry) {
      $$(ID_VIEW_POPUP_CUSTOMGRID).hide();

      if (
        (routeEntry.name === 'project' && routeEntry.params.mode === 'gantt')
        || routeEntry.name === 'projectsMultiView'
      ) {
        innerObject.helpers.refreshMCHeaderVisiblity();
      }
    },
    //
    onMassChangeShowActionsMenu(taskId, linkId, event) {
      const __eventPos = innerObject.handlers.helpers.getEventPos(event);
      const __isDisabled = massChangeModel.getSelectedCount() === 0;

      if (!massChangeModel.isEnabled()) {
        return;
      }

      $$(ID_VIEW_POPUP_MC_ACTIONS_MENU).menuConfig = getMenuConfig([
        { id: menuItemsIds.SET_COLOR, disabled: __isDisabled },
        { id: menuItemsIds.REMOVE, disabled: __isDisabled },
      ]);

      $$(ID_VIEW_POPUP_MC_ACTIONS_MENU).show(__eventPos); // !FIMXE
    },
    //
    onMassChangeShowSelectMenu(id, event) {
      const __$$mcSelectMenu = $$(ID_VIEW_POPUP_MC_SELECT_MENU);
      const __idTask = parseInt(id, 10);
      // const __clickElem = event.target || event.srcElement;
      // const __eventPos = innerObject.handlers.helpers.getEventPos(event);

      if (__$$mcSelectMenu.isVisible()) {
        __$$mcSelectMenu.hide();

        return;
      }

      __$$mcSelectMenu.activeItem = __idTask;
      __$$mcSelectMenu.menuData = composeMassChangeTaskMenu(__idTask); // !TODO: inner implementation

      const __node = event.target.nodeName === 'svg' ? event.target : event.target.parentNode;

      app.trigger('popup:show');

      __$$mcSelectMenu.show(__node, { pos: 'bottom' });
      // __$$mcSelectMenu.show(__eventPos);
    },
    onMassChangeCheckClick(idTask) {
      const __idTask = parseInt(idTask, 10);

      $$(ID_VIEW_POPUP_MC_SELECT_MENU).hide();

      // massChangeModel.selUnselTask(__idTask, !massChangeModel._isTaskSelected(__idTask), true);
      if (massChangeModel.isTaskSelected(__idTask)) {
        massChangeModel.unselectTask(__idTask, true);
        userExtAnalytics.log('masschange_unselect_task', { from: 'grid' });
      } else {
        massChangeModel.selectTask(__idTask, true);
        userExtAnalytics.log('masschange_select_task', { from: 'grid' });
      }
    },
    // !FIXME
    // onStoreUpdated(id, resourceData, mode, ganttId) {
    //   if (
    //     resourceData
    //     && resourceData.user_id === user.id
    //     && ['changeResourceOnProject', 'changeResourceRole'].indexOf(mode) !== -1
    //   ) {
    //     const __mcColumn = gantt.config.columns.find(({ name }) => name === 'masschange');
    //     const __hasEditRights = userRoleHelper.hasEditRightOnProject(window.user.id, projectsModel.getActiveGanttId());
    //
    //     if (__mcColumn) {
    //       __mcColumn.hide = !__hasEditRights;
    //     }
    //   }
    // },
    initCustomGridPopup() {
      const dataColumns = [];
      const dataUserColumns = [];

      const projectData = ganttViewModel.getActiveViewData() || {};

      _.each(gantt.config.columns, column => {
        const getColName = () => {
          if (column.name === 'total_price') {
            return 'costs';
          }
          if (column.name === 'overload') {
            return 'workload';
          }

          return column.name;
        };
        const isDateColumn = (column.name === 'start_date' || column.name === 'end_date');

        if (column.isShowCheckbox && !column.isUserCustomColumn
          && pricingHelper.checkPricingAccess(getColName())) {
          !(projectData.is_jira && column.name === 'creator')
            && !((!projectData.gantt_id && column.name === 'jira_key') || (projectData.gantt_id && !projectData.is_jira && column.name === 'jira_key'))
          && dataColumns.push({
            id: column.name,
            value: __(`gantt_grid_${column.name}`),
            tooltip: column.toolTip && column.toolTip.langKey ? column.toolTip.langKey : '',
            tooltipPos: column.toolTip && column.toolTip.position ? column.toolTip.position : '',
            optionValue: isDateColumn ? __(`gantt_grid_option_${column.name}`) : '',
            showGridCheckbox: innerObject.settings.initGridColumns[column.name] || false,
            showGridCheckboxOptions: isDateColumn ? innerObject.settings.initGridColumns.advancedOptions[column.name].check : 0,
          });
        }

        if (column.isUserCustomColumn) {
          dataUserColumns.push({
            id: column.name,
            value: column.label,
            ganttIds: column.ganttIds,
            // gantt_id: column.gantt_id,
            tooltip: column.toolTip && column.toolTip.langKey ? column.toolTip.langKey : '',
            tooltipPos: column.toolTip && column.toolTip.position ? column.toolTip.position : '',
            showGridCheckbox: innerObject.settings.initGridColumns[column.name] || false,
          });
        }
      });

      innerObject.handlers.openColumnsPopup({
        getPosition(state) {
          const gridNode = gantt.$grid;

          if (!gridNode) {
            return;
          }

          const nodeSizes = gridNode.getBoundingClientRectWrapper();
          const containerSizes = gantt.$container.getBoundingClientRectWrapper();

          state.top = -1;
          state.width = 382;
          // state.height += 2;
          state.height = document.body.clientHeight;
          state.left = nodeSizes.left + (containerSizes.width - state.width) / 2 + 1;
        },
        columns: dataColumns,
        userColumns: dataUserColumns,
        shadow: true,
        clickMainCheckbox(itemId, itemValue, checkBoxWebixId) {
          innerObject.handlers.changeMainCheckbox(itemId, itemValue, checkBoxWebixId);
        },
        clickOptionalCheckbox(itemId, itemValue, checkBoxWebixId) {
          const res = innerObject.handlers.changeOptionCheckbox(itemId, itemValue, checkBoxWebixId);

          innerObject.handlers.projectConfigUpdate();

          return res;
        },
        hidePopup() {
          // innerObject.handlers.updateInitGridSettings();
        },
      });
    },
    updateInitGridSettings(trigger, columnId, isShow) {
      _.each(gantt.config.columns, column => {
        if (column.isUserCustomColumn) {
          if ((trigger === 'create' || trigger === 'update') && +column.name === +columnId) {
            const wasShow = innerObject.settings.initGridColumns[column.name];

            innerObject.settings.initGridColumns[column.name] = wasShow || isShow;
          }
        }
      });

      if (trigger === 'delete') {
        delete innerObject.settings.initGridColumns[columnId];
        delete innerObject.settings.initGridColumns.sizes[columnId];
      }

      innerObject.handlers.projectConfigUpdate();
    },

    verifyColumnsOrder(columnsOrder) {
      // just "zapasochka" to be sure that we do not have bullshit in database in case of columns DND bug
      const columnsOrderOut = _.concat(constantsCommon.COLUMN_ORDER.ALWAYS_FIXED_START, _.difference(columnsOrder, constantsCommon.COLUMN_ORDER.ALWAYS_FIXED_START, constantsCommon.COLUMN_ORDER.ALWAYS_FIXED_END), constantsCommon.COLUMN_ORDER.ALWAYS_FIXED_END);

      return columnsOrderOut;
    },

    saveColumnsOrder() {
      const columnsOrder = [];

      gantt.config.columns.map(val => {
        columnsOrder.push(val.name);
      });

      innerObject.settings.initGridColumns.columnsOrder = innerObject.handlers.verifyColumnsOrder(columnsOrder);
      innerObject.handlers.projectConfigUpdate();
    },
    helpers: {

      // {Object} -> {Object}
      getEventPos(event) {
        const { body, documentElement } = document;

        const __eventPos = {
          x: event.clientX + body.scrollLeft + documentElement.scrollLeft,
          y: event.clientY + body.scrollTop + documentElement.scrollTop,
        };

        return __eventPos;
      },

      onColumnResizeEnd(columnIndex, column, newWidth) {
        if (!innerObject.settings.initGridColumns.sizes[column.name]) {
          innerObject.settings.initGridColumns.sizes[column.name] = {
            min_width: gantt.config.min_grid_column_width,
            width: 0,
          };
        }

        innerObject.settings.initGridColumns.sizes[column.name].width = newWidth;
        innerObject.settings.initGridColumns.grid_width += (newWidth - column.width);

        innerObject.settings.initGridColumns = innerObject.helpers.validateColumnsSizes(innerObject.settings.initGridColumns);

        gantt.config.grid_width = innerObject.settings.initGridColumns.grid_width;

        innerObject.handlers.projectConfigUpdate();

        return true;
      },

      onColumnResizeComplete(columns, totalWidth) {

      },

      onBeforeColumnDragStart(obj) {
        if (!obj.draggedColumn || constantsCommon.COLUMN_ORDER.NOT_DRAGABLE.indexOf(obj.draggedColumn.name) !== -1) {
          return false;
        }
        innerObject.handlers.disableColumnsHeadersControlls(obj);

        return true;
      },

      onColumnDragMove(obj) {
        if (!obj.targetColumn || constantsCommon.COLUMN_ORDER.NOT_MOVABLE_AFTER.indexOf(obj.targetColumn.name) !== -1) {
          return false;
        }
        const gantt_grid_target_marker = document.querySelector('.gantt_grid_target_marker');
        const gantt_grid_target_marker_long = document.querySelector('.gantt_grid_target_marker_long');

        const node_gantt_grid_target_marker = gantt_grid_target_marker && gantt_grid_target_marker.getBoundingClientRect();

        gantt_grid_target_marker_long && gantt.$grid.removeChild(gantt_grid_target_marker_long);
        if (node_gantt_grid_target_marker) {
          const targetMarker = document.createElement('div');
          const height = ((gantt.serialize().data.length * gantt.config.row_height) < gantt.$ui.getView('grid').$config.height) ? gantt.serialize().data.length * gantt.config.row_height : gantt.$ui.getView('grid').$config.height;
          const leftSideBarWidth = Store.getters.leftSidebarWidth;

          targetMarker.classList.add('gantt_grid_target_marker_long');
          targetMarker.style.height = `${height}px`;
          targetMarker.style.top = `${gantt.config.scale_height}px`;
          targetMarker.style.left = `${node_gantt_grid_target_marker.left - leftSideBarWidth + 3}px`;
          gantt.$grid.appendChild(targetMarker);
        }

        return true;
      },

      onBeforeColumnReorder(obj) {
        gantt.saveTaskEditors(false, true);
        innerObject.handlers.enableColumnsHeadersControlls(obj);
        if (!obj.targetColumn || constantsCommon.COLUMN_ORDER.NOT_DROPABLE_BEFORE.indexOf(obj.targetColumn.name) !== -1) {
          return false;
        }

        return true;
      },

      onAfterColumnReorder(obj) {
        innerObject.handlers.saveColumnsOrder();
      },

      onGridResizeEnd(oldWidth, newWidth) {
        const columns = gantt.config.columns;

        innerObject.settings.initGridColumns.grid_width = newWidth;

        _.each(columns, (gridColumn, index) => {
          const columnW = gridColumn.width;

          if (!innerObject.settings.initGridColumns.sizes[gridColumn.name]) {
            innerObject.settings.initGridColumns.sizes[gridColumn.name] = {
              min_width: gantt.config.min_grid_column_width,
              width: 0,
            };
          }

          innerObject.settings.initGridColumns.sizes[gridColumn.name].width = columnW;
        });

        innerObject.handlers.projectConfigUpdate();

        if (app.config.mode.isBase) {
          innerObject.handlers.updateColumnsHeaders();
        }

        return true;
      },
      updateColumnTemplate(key) {
        if (!innerObject.settings.initGridColumns.advancedOptions) {
          return;
        }

        if (innerObject.settings.initGridColumns.advancedOptions[key].check) {
          innerObject.helpers.timeToStringHelper[key] = gantt.date.date_to_str(dateHelper.getDateFormatForGanttGrid());
        } else {
          innerObject.helpers.timeToStringHelper[key] = gantt.date.date_to_str(dateHelper.getDateFormat());
        }
      },
    },
    parseUserCustomGridList(userColumns) {
      const userGridList = $$('userCustomGridList');
      const isMultiview = ganttViewModel.isMultiview();

      userGridList.clearAll();

      if (userColumns && userColumns.length) {
        if (isMultiview) {
          const columns = [{
            value: 'Custom fields',
            projectName: 'Custom fields ',
          },
          ...userColumns,
          ];

          userGridList.parse(columns);
        } else {
          userGridList.parse(userColumns);
        }

        userGridList.refresh();
      }
    },
    openColumnsPopup(options) {
      if (!app.config.mode.isBase) {
        return false;
      }

      const popup = $$(ID_VIEW_POPUP_CUSTOMGRID);

      popup.define('shadow', options.shadow);

      innerSettings = _.assign(innerSettings, _.pick(options, _.keys(innerSettings)));

      const userColumns = options.userColumns;

      const gridList = $$('customGridList');
      const createColumnArea = $$('createColumnArea');

      if (routerHelper.isListViewRoute() || gantt.config.multiview || gantt.config.readonly || !rights.project.hasRight(gantt.config.gantt_id, 'custom_fields_assing_to_project')) {
        createColumnArea.hide();
      } else {
        createColumnArea.show();
      }

      gridList.clearAll();
      gridList.parse(innerSettings.columns);
      gridList.refresh();

      innerObject.handlers.parseUserCustomGridList(userColumns);

      _.delay(() => {
        logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
        popup.show();
      });

      if (!rights.project.hasRight(gantt.config.gantt_id, 'custom_fields_assing_to_project')) {
        $$('createUserCustomColumn').hide();
      } else {
        $$('createUserCustomColumn').show();
      }

      popup.show();
      $$('customGridForm').refresh();

      return false;
    },
    changeDateFormat() {
      gantt.config.date_grid = dateHelper.getDateFormatForGanttGrid();
      gantt.templates.grid_date_format = gantt.date.date_to_str(gantt.config.date_grid);
      innerObject.handlers.updateDateTimeColumns();
      gantt.refreshData();
    },
    updateDateTimeColumns() {
      innerObject.handlers.helpers.updateColumnTemplate('start_date');
      innerObject.handlers.helpers.updateColumnTemplate('end_date');
      innerObject.handlers.helpers.updateColumnTemplate('create_date');
    },
    getTaskPath(taskData) {
      innerObject.settings.taskPathHas[taskData.id] = gantt.getWBSCode(taskData);

      return innerObject.settings.taskPathHas[taskData.id];
    },
    getGridWidthByColumns() {
      let gridWidth = 0;

      _.each(gantt.config.columns, c => {
        if (!innerObject.settings.initGridColumns.sizes) {
          return;
        }

        const sizeObject = innerObject.settings.initGridColumns.sizes[c.name] || innerObject.settings.initGridColumns.sizes.default;

        // fixed width of 1px for the "masschange" column
        if (c.name !== 'masschange') {
          c.width = sizeObject.width;
        }

        if (!c.hide) {
          gridWidth += c.width;
        }
      });

      return gridWidth;
    },
    projectConfigUpdate() {
      ganttViewModel.checkParamsToUpdateAndUpdate({
        key: 'initGridColumns',
        value: innerObject.settings.initGridColumns,
      });
      ganttViewModel.updateGanttSettings();
    },
    changeMainCheckbox(itemId, itemValue, checkBoxWebixId) {
      if (itemId === 'start_date' || itemId === 'end_date') {
        innerObject.settings.initGridColumns[itemId] = itemValue;
        innerObject.settings.initGridColumns.advancedOptions[itemId].check = false;

        if (checkBoxWebixId) {
          $$(checkBoxWebixId).blockEvent();
          $$(checkBoxWebixId).setValue(itemValue);
          $$(checkBoxWebixId).config.value = itemValue;
          $$(checkBoxWebixId).unblockEvent();
        }

        innerObject.handlers.helpers.updateColumnTemplate(itemId);
      } else {
        innerObject.settings.initGridColumns[itemId] = !!itemValue;
      }

      gantt.getGridColumn(itemId).hide = !itemValue;

      if (itemValue) {
        const oldGridWidth = gantt.config.grid_width + 1;

        gantt.config.grid_width += gantt.getGridColumn(itemId).width;

        if (gantt.$grid.clientWidth < gantt.config.grid_width) {
          let diff = gantt.getGridColumn(itemId).width - (gantt.$task.clientWidth - 5);

          if (diff > 0) {
            while (diff > 0) {
              const diffRatio = diff / gantt.config.grid_width;

              _.each(innerObject.settings.initGridColumns.sizes, (value, key) => {
                if (innerObject.settings.initGridColumns[key] !== false && innerObject.settings.initGridColumns.sizes[key].width > 50) {
                  let widthRatio = parseInt((innerObject.settings.initGridColumns.sizes[key].width * diffRatio)) || 1;

                  if (widthRatio > (innerObject.settings.initGridColumns.sizes[key].width - 50)) {
                    widthRatio = innerObject.settings.initGridColumns.sizes[key].width - 50;
                  }

                  if (widthRatio > diff) {
                    widthRatio = diff;
                  }

                  innerObject.settings.initGridColumns.sizes[key].width -= widthRatio;
                  diff -= widthRatio;
                }
              });
            }

            innerObject.settings.initGridColumns.grid_width = innerObject.handlers.getGridWidthByColumns();
            gantt.config.grid_width = innerObject.settings.initGridColumns.grid_width;
          }
        }
      } else {
        gantt.config.grid_width -= gantt.getGridColumn(itemId).width;
      }

      innerObject.handlers.projectConfigUpdate();

      this.debouncedRender();
    },
    debouncedRender: _.debounce(() => {
      gantt.callEvent('ganttRender');
      gantt.callEvent('onGridColumnsToggle');
    }, 1000),
    changeOptionCheckbox(itemId, itemValue, checkboxWebixId) {
      if (innerObject.settings.initGridColumns[itemId]) {
        innerObject.settings.initGridColumns.advancedOptions[itemId].check = !!itemValue;
        innerObject.handlers.helpers.updateColumnTemplate(itemId);
        gantt.callEvent('ganttRender');

        return true;
      }
      innerObject.settings.initGridColumns.advancedOptions[itemId].check = false;
      // $$(checkboxWebixId).setValue(innerObject.settings.initGridColumns.advancedOptions[itemId].check);

      // gantt.callEvent("ganttRender");

      return false;
    },

    disableColumnsHeadersControlls(obj) {
      const headsWrapper = document.querySelector('.gantt_grid_scale');
      const tableCells = obj && document.querySelectorAll(`[data-name='${obj.draggedColumn.name}']`);
      const tableCellsCustom = obj && document.querySelectorAll(`[data-type='${obj.draggedColumn.name}']`);

      headsWrapper && headsWrapper.classList.add('gantt_grid_head_disable_controlls');
      if (tableCells && tableCells.length) {
        _.each(tableCells, task => {
          task.classList.add('opacity-table-cell');
        });
      }
      if (tableCellsCustom && tableCellsCustom.length) {
        _.each(tableCellsCustom, task => {
          task.classList.add('opacity-table-cell');
        });
      }
    },

    enableColumnsHeadersControlls(obj) {
      const headsWrapper = document.querySelector('.gantt_grid_scale');
      const tableCells = obj && document.querySelectorAll(`[data-name='${obj.draggedColumn.name}']`);
      const tableCellsCustom = obj && document.querySelectorAll(`[data-type='${obj.draggedColumn.name}']`);

      if (tableCells && tableCells.length) {
        _.each(tableCells, task => {
          task.classList.remove('opacity-table-cell');
        });
      }
      if (tableCellsCustom && tableCellsCustom.length) {
        _.each(tableCellsCustom, task => {
          task.classList.remove('opacity-table-cell');
        });
      }
      const gantt_grid_target_marker_long = document.querySelector('.gantt_grid_target_marker_long');

      gantt_grid_target_marker_long && gantt.$grid.removeChild(gantt_grid_target_marker_long);

      headsWrapper && headsWrapper.classList.remove('gantt_grid_head_disable_controlls');
    },

    updateColumnsHeaders: _.debounce(disableHideLabel => {
      const button = document.querySelector('.gantt_grid_head_buttons');
      const elements = document.querySelectorAll('.gantt_grid_head_cell');
      const projectData = ganttViewModel.getActiveViewData();
      if(!projectData) return;
      if (button) {
        if (gantt.config.grid_width >= gantt.$container.offsetWidth) {
          button.classList.add('fixed');
          button.style.left = `${gantt.$container.offsetWidth - button.offsetWidth - 2}px`;
        } else {
          button.classList.remove('fixed');
        }
      }

      if (disableHideLabel) {
        innerObject.handlers.disableColumnsHeadersControlls();

        return;
      }
      innerObject.handlers.enableColumnsHeadersControlls();


      const jiraColumn = gantt.config.columns.find(({ name }) => name === 'jira_key');
      if(!projectData.is_jira && jiraColumn)  {
        jiraColumn.hide = true;
        gantt.callEvent('ganttRender');
      }

      _.each(elements, el => {
        const columnId = el.getAttribute('data-column-id');

        if (!_.includes(constantsCommon.COLUMN_ORDER.NOT_HIDABLE, columnId)) {
          el.classList.add('hideable_column');
          if (!el.querySelectorAll('.hide_column_button').length) {
            const node = document.createElement('div');

            !app.config.mode.isExport && node.classList.add('hide_column_button');
            node.classList.add('grid_header_control');
            if (!app.config.mode.isExport) {
              node.innerHTML = gantt._getSvgIcon('close-1', 'regular', 16);
            }
            el.appendChild(node);
          }
        }

        if (!_.includes(constantsCommon.COLUMN_ORDER.NOT_DRAGABLE, columnId)) {
          el.classList.add('dragable_column');
          if (!el.querySelectorAll('.drag-column-indicator').length) {
            const node = document.createElement('div');

            !app.config.mode.isExport && node.classList.add('drag-column-indicator');
            !app.config.mode.isExport && node.classList.add('grid_header_control');
            if (!app.config.mode.isExport) {
              node.innerHTML = gantt._getSvgIcon('dragdrop', 'regular', 24);
            }
            el.appendChild(node);
          }
        }
      });
    }, 200),
    updateConfigAfterResourceModelChange(projectId) {
      const projectData = ganttViewModel.getActiveViewData();
      let activeProjectIds = [];

      if (projectData) {
        if (!projectData.isSingleProject) {
          activeProjectIds = projectData.ganttIDs;
        } else {
          activeProjectIds.push(projectData.gantt_id);
        }
      }

      if (!activeProjectIds.includes(projectId)) {
        return;
      }

      if (!projectsModel.count()) {
        return false;
      }

      const hasCostRightOnProject = rights.project.hasCostRight(projectId);

      if (!hasCostRightOnProject) {
        innerObject.settings.initGridColumns.total_price = false;
        innerObject.settings.initGridColumns.actual_cost = false;
        innerObject.handlers.projectConfigUpdate();
      }
    }
  },
  settings: {
    ganttId: 0,
    initGridColumns: {},
    taskPathHas: [],
    svgDom: false,
  },
  helpers: {
    updateBySelector(idTask) {
      const __taskNode = document.querySelector(`.dhx_gantt_container .gantt_layout_content .gantt_grid .gantt_grid_data .gantt_row[task_id="${idTask}"]`);
      const __idTask = __taskNode && __taskNode.getAttribute('task_id');

      if (__idTask) {
        gantt.refreshTask(__idTask);
      }
    },
    isMassChangeColVisible() {
      const __hasEditRights = rights.project.hasRightSomeOne(projectsModel.getActiveGanttId(), [
        'static_fields',
        'delete_task',
        'static_fields_4_only',
        'custom_field_edit',
      ]);
      const __isVisible = app.config.mode.isBase && __hasEditRights && !ganttViewModel.isMultiview();

      return __isVisible;
    },
    refreshMCHeaderVisiblity() {
      const isMassChangeColVisible = this.isMassChangeColVisible();
      const __mcColumn = gantt.config.columns.find(({ name }) => name === 'masschange');

      if (!__mcColumn) {
        return;
      }

      __mcColumn.hide = !isMassChangeColVisible;
    },
    validateConstantColumn(initGridColumns) {
      const constantSizeColumns = ['wbs', 'comments', 'attachments', 'buttons', 'overdue'];

      _.each(constantSizeColumns, column => {
        if (initGridColumns.sizes[column]) {
          if (+initGridColumns.sizes[column].width !== constants[column].width
            || +initGridColumns.sizes[column].min_width !== constants[column].min_width) {
            initGridColumns.sizes[column] = constants[column];
          }
        }
      });

      return initGridColumns;
    },
    validateColumnsSizes(initGridColumns) {
      const sizes = initGridColumns.sizes;
      let gridWidthChanged = false;

      initGridColumns = innerObject.helpers.validateConstantColumn(initGridColumns);

      _.each(sizes, (size, key) => {
        if (size.width < size.min_width) {
          size.width = size.min_width;
          initGridColumns[key] = size;
          const ganttColumn = _.find(gantt.config.columns, { name: key });

          if (ganttColumn) {
            ganttColumn.width = size.width;
          }

          gridWidthChanged = true;
        }
      });

      if (gridWidthChanged) {
        initGridColumns.grid_width = _.reduce(sizes, (resultWidth, column) => resultWidth + column.width, 0);
      }

      return initGridColumns;
    },
    timeToStringHelper: {},

    checkColumnsVisibilityAndOrder() {
      // visibility
      const columns = gantt.config.columns;
      const initGridColumns = innerObject.settings.initGridColumns;

      _.each(columns, column => {
        const getColName = () => {
          if (column.name === 'total_price') {
            return 'costs';
          }
          if (column.name === 'overload') {
            return 'workload';
          }

          return column.name;
        };

        if (!_.isUndefined(initGridColumns[column.name])) {
          if (column.name !== 'masschange') {
            column.hide = !initGridColumns[column.name];
            if (!pricingHelper.checkPricingAccess(getColName())) {
              column.hide = true;
            }
          }
        }
        if (initGridColumns.columnsOrder && initGridColumns.columnsOrder[0]) {
          if (initGridColumns.columnsOrder.indexOf(column.name) === -1) {
            initGridColumns.columnsOrder.push(column.name);
          }
        }
      });

      gantt.config.grid_width = innerObject.handlers.getGridWidthByColumns();

      if (initGridColumns.columnsOrder && initGridColumns.columnsOrder[0]) {
        initGridColumns.columnsOrder = innerObject.handlers.verifyColumnsOrder(initGridColumns.columnsOrder);
        columns.sort((a, b) => initGridColumns.columnsOrder.indexOf(a.name) - initGridColumns.columnsOrder.indexOf(b.name));
      }
    },

    getSumOfLogsForParent(taskId) {
      let total = 0;

      gantt.eachTask(childTask => {
        if (childTask.type !== gantt.config.types.button) {
          const logsData = timeTrackingModel.getLogsByParamsNotCopy(childTask.gantt_id, childTask.id);

          total += logsData ? logsData.sum : 0;
        }
      }, taskId);

      return total;
    },
    userInfoTooltip(column) {
      const projectIds = Store.getters['columns/getProjectsByColumnId'](column.id);
      const multiviewData = ganttViewModel.getActiveViewData();

      const projectItems = projectIds.map(projectId => {
        const multiviewProjectIds = multiviewData.ganttIDs;

        if (multiviewProjectIds.includes(+projectId)) {
          const project = projectsModel.getProjectDataById(+projectId);

          if (project) {
            return `<li class='tooltip-list-item'>${project.name}</li>`;
          }
        }

        return '';
      });

      const tooltip = `<div class='tooltip-gantt-html'>
          <div class='tooltip-gantt-html-description global_gantt_columns_tooltip ql-editor'>
            <div class='tooltip-header'>${__('guntt_custom_columns_multiview_tooltip_project')}</div>
            <div class='tooltip-divider'></div>
            <ul class='tooltip-list'>
              ${projectItems.join('')}
            </ul>
          </div>
        </div>`;

      return `<span class='columns_projects_info'>
        <span class='tooltip-gantt' data-html='true' data-x='11' data-padding='8px 12px' data-maxwidth='320' data-position='right'>${tooltip}</span>
      </span>`;
    },
    checkPermission(feature) {
      return Store.getters['paymentModel/getAccessToFeature'](user.paymentFeatures[feature].name);
    },
  },
};

// !FIXME: helpers
function composeMassChangeTaskMenu(idTask) {
  const __ck = gantt.getChildCount(idTask);
  const __taskEntry = Store.getters['tasksModel/getTask'](idTask);

  if (__ck === 0 || !__taskEntry) {
    return [];
  }

  const __selState = massChangeModel.getEntrySelectionState(idTask);
  const __isTaskSel = massChangeModel._isTaskSelected(idTask);

  if (__taskEntry.type === 'project' && !__taskEntry.parent) {
    return getMenu([
      { id: SELECT_ALL, disabled: __selState === 1 },
      { id: UNSELECT_ALL, disabled: __selState !== 1 },
    ]);
  }

  return getMenu([
    { id: SELECT_ALL, disabled: __selState === 1 },
    { id: SELECT_CURRENT, disabled: __isTaskSel },
    { id: UNSELECT_ALL, disabled: __selState !== 1 },
    { id: UNSELECT_CURRENT, disabled: !__isTaskSel },
  ]);
}

// !FIXME: handlers
app.on(ID_EVENT_MC_SELECT_ACTION, idAction => {
  const __menuEntry = getMenuItem(idAction);

  if (!__menuEntry) {
    console.warn('no menu entry for action', idAction);

    return;
  }

  return __menuEntry.handler();
});

gantt.attachEvent('onEmptyClick', e => {
  const target = e.target;

  if (target && target.closest('.gantt_grid_head_buttons')) {
    innerObject.handlers.initCustomGridPopup();

    userExtAnalytics.log('gantt_column_settings_open', {
      from: routerHelper.getCurrentRoute().path.startsWith('/projects/') ? 'portfolio' : 'project',
    });
  }
});

gantt.attachEvent('onGridHeaderClick', (itemId, e) => {
  const cell = e.target.closest('.gantt_grid_head_cell');
  const columnId = cell.getAttribute('data-column-id');

  if (cell.classList.contains('gantt_grid_head_buttons')) {
    return;
  }

  if ((e.target.parentNode.classList && e.target.parentNode.classList.contains('hide_column_button')) || e.target.closest('.hide_column_button')) {
    cell.style.opacity = '0.5';
    cell.style.pointerEvents = 'none';
    innerObject.handlers.changeMainCheckbox(columnId, 0);
  }
});

gantt.attachEvent('onAfterGanttRender', () => {
  if (app.config.mode.isBase) {
    innerObject.handlers.updateColumnsHeaders();
  }
});

gantt.attachEvent('afterTaskMoved', (sourceId, targetId) => {
  delete innerObject.settings.taskPathHas[sourceId];
  delete innerObject.settings.taskPathHas[targetId];
});

gantt.attachEvent('onGridResizeEnd', (oldWidth, newWidth) => {
  _.delay(() => {
    gantt.callEvent('onBeforeGanttRender');
    innerObject.handlers.helpers.onGridResizeEnd(oldWidth, newWidth);
    gantt.callEvent('onAfterGanttRender');
  });

  return true;
});

gantt.attachEvent('onGridResize', (oldWidth, newWidth) => {
  gantt.config.grid_width = newWidth;

  return true;
});

gantt.attachEvent('onAfterTaskAdd', (id, task) => {
  innerObject.handlers.updateColumnsHeaders();
});

gantt.attachEvent('updateColumnsHeaders', () => {
  innerObject.handlers.updateColumnsHeaders();
});

gantt.attachEvent('onContextMenu', () => {
  $$(ID_VIEW_POPUP_CUSTOMGRID).hide();
});

gantt.attachEvent('onGanttReady', () => {
  gantt.$ui.getView('grid').attachEvent('onBeforeColumnDragStart', obj => innerObject.handlers.helpers.onBeforeColumnDragStart(obj));

  gantt.$ui.getView('grid').attachEvent('onColumnDragMove', obj => innerObject.handlers.helpers.onColumnDragMove(obj));

  gantt.$ui.getView('grid').attachEvent('onBeforeColumnReorder', obj => innerObject.handlers.helpers.onBeforeColumnReorder(obj));
  gantt.$ui.getView('grid').attachEvent('onAfterColumnReorder', obj => innerObject.handlers.helpers.onAfterColumnReorder(obj));

  gantt.$ui.getView('grid').attachEvent('onColumnResizeStart', (columnIndex, column) => {
    innerObject.handlers.disableColumnsHeadersControlls();
  });

  gantt.$ui.getView('grid').attachEvent('onColumnResizeEnd', (columnIndex, column, newWidth) =>
    // innerObject.handlers.enableColumnsHeadersControlls();
    innerObject.handlers.helpers.onColumnResizeEnd(columnIndex, column, newWidth));

  gantt.$ui.getView('grid').attachEvent('onColumnResizeComplete', (columnIndex, column, newWidth) => {
    innerObject.handlers.enableColumnsHeadersControlls();

    return innerObject.handlers.helpers.onColumnResizeComplete(columnIndex, column, newWidth);
  });
});

app.on('workload:ready', () => {
  if (app.config.mode.isBase) {
    innerObject.handlers.updateColumnsHeaders();
  }
});

app.on('taskView:init', () => {
  $$(ID_VIEW_POPUP_CUSTOMGRID).hide();
});

app.on('resources:model:changeResourcesOnProjects', data => {
  data.forEach(item => {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](item.resourceId);

    if (resourceData && resourceData.userId === user.id) {
      item.projectIds.forEach(projectId => {
        innerObject.handlers.updateConfigAfterResourceModelChange(projectId);
      });
    }
  });
});

app.on('resources:model:changeResourceProjectRole', data => {
  const resourceData = globalStore.getters['resourcesModel/getResourceById'](data.resourceId);

  if (resourceData && resourceData.userId === user.id) {
    innerObject.handlers.updateConfigAfterResourceModelChange(data.ganttId);
  }
});
// duplication
webix.protoUI({
  name: 'activeList',
}, // give it some name you like
webix.ui.list,
webix.ActiveContent);

webix.ui({
  view: 'popupWithShadow',
  css: 'custom-grid-popup',
  id: ID_VIEW_POPUP_CUSTOMGRID,
  // scroll: false,
  height: document.documentElement.clientHeight,
  hidden: true,
  head: false,
  zIndex: 112,
  body: {
    type: 'clean',
    borderless: true,
    paddingX: 0,
    paddingY: 0,
    width: 382,
    // scroll: false,
    rows: [
      {
        css: 'customGridHeader',
        id: 'customGridHeader',
        height: 46,
        borderless: true,
        cols: [
          {
            width: 16,
          },
          {
            borderless: true,
            template() {
              return `<div class='customGridHeaderTitle'>${__('gantt_grid_custom_grid_header')}</div>`;
            },
          },
          {
            view: 'helpIcon',
            id: 'customGridHelpBlockView',
            dataId: 'customGridHelpBlock',
            css: 'help_icon_block custom_grid_popup',
            width: 36,
            height: 36,
            zIndex: 1,
          },
          {
            width: 8,
          },
          {
            width: 36,
            height: 36,
            view: 'button',
            template() {
              return `<div class='header_icon'>${icon_close}</div>`;
            },
            on: {
              onItemClick() {
                $$(ID_VIEW_POPUP_CUSTOMGRID).hide();
              },
            },
          },
          {
            width: 10,
          },
        ],
      },
      {
        view: 'form',
        padding: 0,
        scroll: 'y',
        css: 'customGridForm',
        id: 'customGridForm',
        // autoheight: true,
        borderless: true,
        paddingY: 0,
        paddingX: 0,
        rows: [
          {
            view: 'activeList',
            id: 'customGridList',
            width: 320,
            data: [],
            paddingY: 0,
            paddingX: 0,
            autoheight: true,
            navigation: false,
            borderless: true,
            type: {
              height: 36,
            },
            activeContent: {
              showGridCheckbox: {
                view: 'checkbox',
                width: 36,
                height: 30,
                on: {
                  onChange(newV, oldV) {
                    const item_id = this.config.$masterId;
                    const value = this.getValue();

                    innerSettings.clickMainCheckbox(item_id, value, this.config.id);

                    _.delay(() => {
                      $$('customGridList').updateItem(item_id, {
                        showGridCheckbox: value,
                        showGridCheckboxOptions: false,
                      });
                    });
                  },
                },
              },
              showGridCheckboxOptions: {
                view: 'checkbox',
                width: 36,
                height: 30,
                on: {
                  onChange() {
                    const item_id = this.config.$masterId;

                    if (!innerSettings.clickOptionalCheckbox(item_id, this.getValue(), this.config.id)) {
                      this.blockEvent();
                      this.setValue(0);
                      this.unblockEvent();
                    }

                    return false;
                  },
                },
              },
              showGridCheckboxAdditional: {
                view: 'helpIcon',
                id: 'taskCostHelpBlockView',
                dataId: 'taskCostHelpBlock',
                css: 'help_icon_block task_cost_popup',
                width: 36,
                height: 36,
                zIndex: 1,
              },
            },
            template(object, common) {
              let optionCheckBox = "<div class='customGridOptionList'>";
              const isDELocale = window.user.locale === 'de';
              let edit_button = '';

              if (object.isUserCustomColumn) {
                return '';
              }

              if (object.id === 'start_date' || object.id === 'end_date' || object.id === 'project_name') {
                optionCheckBox += `<div class='${isDELocale ? 'customGridOptionListItemDE' : 'customGridOptionListItem'}'>${
                  object.optionValue
                }</div>`
                  + `<div class='${!object.showGridCheckbox ? "disabled_checkbox'>" : "'>"}${common.showGridCheckboxOptions(object, common)}</div>`;
              }

              if (object.id === 'total_price') {
                optionCheckBox += `<div class='${isDELocale ? 'customGridOptionListItemDE' : 'customGridOptionListItem'}'>${
                  object.optionValue
                }</div>${common.showGridCheckboxAdditional(object, common)}`;
                edit_button = `<div class="edit js_edit" style="margin-left: 0px;">${icon_edit}<span>${__('edit_column')}</span></div>`;
              }

              optionCheckBox += '</div>';

              object.tooltip = `${object.id}_custom_grid_tooltip`;

              return `${"<div class='customGrid'><div class='customGridList'>"
                + "<div class='customGridListItem tooltip-gantt' data-key='"}${object.tooltip}' data-position='left'>${object.value}</div>`
                + `<div>${common.showGridCheckbox(object, common)}</div>`
                + `</div>${edit_button}${optionCheckBox}</div>`;
            },
            onClick: {
              js_edit(e, id) {
                if (id === 'total_price') {
                  app.trigger('openBudgetFieldPopup');
                }

                return false; // blocks the default click behavior
              },
            },
          },
          {
            id: 'createColumnArea',
            rows: [
              { height: 10 },
              {
                cols: [
                  {},
                  {
                    view: 'button',
                    id: 'createUserCustomColumn',
                    css: 'webix_button_default button_raised button_gray add_column',
                    value: __('create_new_column_button'),
                    width: innerSettings.addColumnWidth,
                    height: 36,
                    hidden: false,
                    attributes: { },
                    click() {
                      if (innerObject.helpers.checkPermission('custom_fields')) {
                        app.trigger('openUserCustomColumnsPopup');
                      }
                    },
                    updateCss() {
                      let thisNodeClassList = [];

                      if (!$$(this.id).isVisible()) {
                        return true;
                      }

                      const thisNode = $$(this.id).getNode();

                      if (!thisNode) {
                        return true;
                      }

                      thisNodeClassList = thisNode.classList;
                      if (!innerObject.helpers.checkPermission('custom_fields')) {
                        thisNodeClassList.add('pricing-tooltip');
                        thisNode.dataset.feature = 'custom_fields';
                        thisNode.dataset.position = 'right';

                        const pricingTitle = document.createElement('div');

                        pricingTitle.className = 'pricing-title-container';
                        const plan_title = `${pricingHelper.getPlans('custom_fields')}_plan_locale`;

                        pricingTitle.innerHTML = `${__(plan_title)}`;
                        thisNode.appendChild(pricingTitle);
                      }
                    },
                    on: {
                      onAfterRender() {
                        this.config.updateCss();
                      },
                    },

                  },
                  {},
                ],
              },
              { height: 10 },
            ],
          },
          {
            view: 'activeList',
            id: 'userCustomGridList',
            scroll: false,
            width: 320,
            data: [],
            autoheight: true,
            paddingY: 0,
            paddingX: 0,
            borderless: true,
            navigation: false,
            activeContent: {
              showGridCheckbox: {
                view: 'checkbox',
                width: 30,
                // height: 24,
                height: 30,
                on: {
                  onChange(newV, oldV) {
                    const item_id = this.config.$masterId;
                    const grid = $$('userCustomGridList');
                    const item = grid.getItem(item_id);
                    const value = !!this.getValue();

                    innerSettings.clickMainCheckbox(item.id, value, this.config.id);
                    _.delay(() => {
                      grid.updateItem(item_id, {
                        showGridCheckbox: value,
                      });
                    });
                  },
                },
              },
            },
            type: {
              // height: "auto",
              autoheight: true,
            },
            template(object, common) {
              const hideEditColumn = gantt.config.readonly || gantt.config.multiview || !rights?.account?.hasRight('custom_fields_edit');
              let margin = '';
              let tooltip = '';

              if (window.user.locale === 'de') {
                margin = "style='margin-left: 0px;'";
              }

              if (gantt.config.multiview && !object.projectName) {
                tooltip = innerObject.helpers.userInfoTooltip(object);
              }

              const edit_button = hideEditColumn ? '' : `<div class="edit js_edit" ${margin}>${icon_edit}<span>${__('edit_column')}</span></div>`;

              if (object.projectName) {
                return `${"<div class='customGrid'>"
                  + "<div class='customGridList customGridListProject'>"
                  + "<div class='customGridListItem customGridListItemProject' title='"}${object.projectName}` + '\'>' + `<span class=''>${__('guntt_custom_columns_multiview_title')}</span>` + '</div>'
                  + '</div>'
                  + '</div>';
              }

              return `${"<div class='customGrid'>"
                + "<div class='customGridList'>"
                  + "<div class='customGridListItem' title='"}${object.value}'>${object.value}</div>`
                  + `<div>${common.showGridCheckbox(object, common)}</div>`
                + `</div>${
                  edit_button
                }${tooltip
                }</div>`;
            },
            onClick: {
              js_edit(e, id) {
                const userCustomColumn = Store.getters['columns/getCustomColumnById'](id);

                app.trigger('openUserCustomColumnsPopup', userCustomColumn);

                return false; // blocks the default click behavior
              },
            },
          },
          { height: 6 },
        ],
      },
    ],
  },
  position(state) {
    if (innerSettings.getPosition) {
      innerSettings.getPosition(state);
    }
  },
  on: {
    onHide() {
      innerSettings.hidePopup();
    },
  },
});

// {Boolean} -> {String}
function getMCHeaderTemplate(isEnabled = false) {
  const __text = isEnabled ? __('app_masschange_header_disable') : __('app_masschange_header_enable');
  // const __ico = userProjCfg.getItem(projectsModel.getActiveGanttId()).userSkin === 'terrace' ? icMassChangeHeader : icMassChangeHeader2;
  // const __skin = userProjCfg.getItem(projectsModel.getActiveGanttId()).userSkin;

  const __projCfgItem = userProjCfg.getItem(projectsModel.getActiveGanttId());
  const __skin = __projCfgItem ? __projCfgItem.userSkin : gantt.config.userSkin;

  return `
    <div class="mc-header-container skin-${__skin}" data-pressed="${isEnabled}">
      <div class="mc-icon-container">${icMassChangeHeader}</div>
      <div class="mc-text-container">${__text}</div>
    </div>
  `;
}

function getMCItemTemplate(task) {
  if (!massChangeModel.isEnabled() || (task.type !== 'project' && task.type !== 'task' && task.type !== 'milestone')) {
    return '<span class="mc-item-icon-container"></span>';
  }

  const NO_SELECTED = -1;
  const PART_SELECTED = 0;
  const FULL_SELECTED = 1;

  const __selState = massChangeModel.getEntrySelectionState(task.id);

  let __chekIco = '';

  if (__selState === NO_SELECTED) {
    __chekIco = icNotSelected;
  } else if (__selState === PART_SELECTED) {
    __chekIco = icSelectedPart;
  } else if (__selState === FULL_SELECTED) {
    __chekIco = icSelected;
  }

  return `<div class="mc-item-icon-container">${__chekIco}${gantt.getTask(task.id).type === 'project' ? icArrow : ''}</div>`;
}

function reInitColumns(mode, deletedColumnId, isShow) {
  userCustomColumnModule.reInit();
  if (app.config.mode.isBase) {
    if (!routerHelper.isColumnsSettingsRoute() && deletedColumnId) {
      innerObject.handlers.updateInitGridSettings(mode, deletedColumnId, isShow);
    }

    innerObject.helpers.checkColumnsVisibilityAndOrder();

    if (!routerHelper.isColumnsSettingsRoute()) {
      innerObject.handlers.saveColumnsOrder();
    }

    if (mode !== 'collaboration') {
      gantt.callEvent('ganttRender');
    }
    if ($$(ID_VIEW_POPUP_CUSTOMGRID).isVisible()) {
      innerObject.handlers.initCustomGridPopup();
    }
  }
}

const outputObject = {
  init: {
    beforeInit: innerObject.init.run,
    run: innerObject.init.run,
    afterInit: innerObject.init.afterInit,
    reinit: innerObject.init.reinit,
  },
  helpers: {
    updateGanttGrid: innerObject.init.helpers.updateDurationGrid,
    advancedOptions() {
      return innerObject.settings.initGridColumns.advancedOptions;
    },
    updateInnerConfig(column, value) {
      innerObject.settings.initGridColumns[column] = value;
    },
  },
};

gantt.attachEvent('onContextMenu', innerObject.handlers.onMassChangeShowActionsMenu);
gantt.attachEvent('onMassChangeShowSelectMenu', innerObject.handlers.onMassChangeShowSelectMenu); // !FIXME: constant
gantt.attachEvent('onMassChangeCheckClick', innerObject.handlers.onMassChangeCheckClick); // !FIXME: constant

app.on('user:changedRole', ganttId => {
  if ($$(ID_VIEW_POPUP_CUSTOMGRID).isVisible()) {
    $$(ID_VIEW_POPUP_CUSTOMGRID).hide();
  }
});

app.on('app:route:after:changed', (to, from) => {
  if (to.params.mode === 'gantt') {
    innerObject.handlers.updateColumnsHeaders();
  }
});

app.on('userCustomColumnsModel:change', (ganttId, mode, deletedColumnId, isShow) => {
  gantt.unselectTask();
  gantt.unloadTaskEditors();
  reInitColumns(mode, deletedColumnId, isShow);
});

app.on('changeUserDateTimeFormat', () => {
  if (gantt.config.gantt_id || gantt.config.multiview) {
    innerObject.handlers.changeDateFormat();
  }
});

app.on('changeOptionCheckbox', (itemId, itemValue) => {
  if (innerObject.settings.initGridColumns[itemId]) {
    innerObject.settings.initGridColumns.advancedOptions[itemId].check = !!itemValue;
    innerObject.handlers.helpers.updateColumnTemplate(itemId);
  } else {
    innerObject.settings.initGridColumns.advancedOptions[itemId].check = false;
  }
});

app.on('showColumnsPopup', options => {
  innerObject.handlers.openColumnsPopup(options);
});

app.on('onAfterCollaboration', data => {
  if (data.event === 'TaskResourceUnassigned') {
    const gantt_id = projectsModel.getActiveGanttId();
    const canSeeAllTasks = rights.project.hasRight(gantt_id, 'all_tasks');
    const userResource = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
    const currentResource = data.unassignedResources.find(obj => obj.unassignedResourcesIds.find(id => id === userResource.id));

    if (canSeeAllTasks || !currentResource) return;

    const tasks = globalStore.getters['tasksModel/getTasksByGanttIds']([gantt_id]);
    const accessTasks = tasks.filter(task => task.type === gantt.config.types.task || task.type === gantt.config.types.milestone);

    if (!accessTasks.length) {
      $$(ID_VIEW_POPUP_CUSTOMGRID).hide();
    }
  }
})

app.on(ID_EVENT_APP_ROUTECHANGED, innerObject.handlers.onRouteChanged);
app.on(ID_EVENT_MASSCHANGE_UPDATESTATE, innerObject.handlers.onMCUpdateState);
app.on(ID_EVENT_MASSCHANGE_UPDATE_TASKS, innerObject.handlers.onMCUpdateTasks);

app.on('body:resize', () => {
  $$(ID_VIEW_POPUP_CUSTOMGRID).resize();
});

app.on('currencyUpdated', () => {
  gantt.refreshData();
});

export default outputObject;
