import { api } from '$$store/api';

export default {
  async loadProjectColumns(emptyValues, rootState) {
    let projectColumns = [];

    if (!rootState.appMode.isBase) {
      if (GT.ganttData.userCustomColumnsData) {
        projectColumns = [GT.ganttData.userCustomColumnsData];
      } else if (GT.ganttData.multiviewCustomColumns) {
        projectColumns = GT.ganttData.multiviewCustomColumns;
      } else {
        projectColumns = [{
          columns: [],
          id: GT.ganttData.project.gantt_id,
          values: [],
        }];
      }
    } else {
      let url = `/project_custom_columns/?${Date.now()}`;

      if (emptyValues) {
        url = `/project_custom_columns/empty_values/?${Date.now()}`;
      }

      const response = await api.get(url);

      projectColumns = response.data;
    }

    return projectColumns;
  },
  prepareOptionsLocale(columns) {
    columns.forEach(column => {
      if (column.options) {
        column.options.forEach(option => {
          option.value = __(option.locale);
        });
      }

      if (column.fields) {
        column.fields = this.prepareJiraOptions(column.fields);
      }
    });

    return columns;
  },
  prepareCustomColumnsData(columns) {
    const prepared = columns.map(item => {
      const column = { ...item };

      if (column.custom_column_types_id) {
        column.type = column.custom_column_types_id;
        column.sub_type = column.custom_column_sub_types_id;
        column.options = _.orderBy(column.options, ['sort_order'], ['asc']);
        column.last_update = column.last_update || column.create_date;
        column.last_update_by = column.last_update_by || column.user_id;

        delete column.custom_column_types_id;
        delete column.custom_column_sub_types_id;
      }

      return column;
    });

    return prepared;
  },
  prepareJiraOptions(jiraColumns) {
    const prepared = jiraColumns.map(col => {
      const column = { ...col };

      column.options = column.options.map(option => ({
        ...option,
        value: option.name,
      }));

      return column;
    });

    return prepared;
  },
};
