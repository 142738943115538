<template>
  <div :class="[$style.filter_saved_popup, isShow ? $style.active : '' ]">
    <div :class="[$style.filter_header, $style.jc_space_between]">
      <span :class="$style.filter_header_title">
        {{ locales.title }}
      </span>
      <div
        id="gp_autotest_global_filter_btn_save_popup_btn_close"
        @click="$emit('close')"
      >
        <webix-ui
          :class="$style.filter_header_icon"
          :config="icons.arrow"
        />
      </div>
    </div>

    <template v-if="list.length">
      <div
        class="gantt_scroll"
        :class="$style.saved_scroll"
      >
        <savedListItem
          v-for="(item, index) in list"
          :id="item.id"
          :key="index + '_' + item.id"
          :name="item.value"
          :active-item-id="activeItemId"
          :list="list"
          @delete="$emit('delete', item)"
          @edit="$emit('edit', { id: item.id, value: $event, old_value: item.value})"
          @select="$emit('select', {...item})"
        />
      </div>
    </template>

    <div
      v-else
      :class="$style.no_saved"
    >
      {{ locales.no_saved }}
    </div>
  </div>
</template>

<script>
import svgIconForVue from '../../common/svgIconForVue';
import icon_check from '../../../svg/task-settings/check.svg';
import icon_cancel from '../../../svg/task-settings/cancel.svg';
import edit_icon from '../../../svg/filter/edit.svg';
import trash_icon from '../../../svg/filter/trash.svg';
import right_arrow from '../../../svg/filter/right_arrow.svg';
import savedListItem from './savedListItem.vue';

export default {
  name: 'SavedFiltersPopup',
  components: { savedListItem },
  props: {
    isShow: { type: Boolean, required: true },
    list: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    activeItemId: { type: String, required: false, default: '' },
  },

  data() {
    return {
      locales: {
        title: __('popup_saved_filters'),
        no_saved: __('no_saved_filters'),
      },
      icons: {
        edit: svgIconForVue(edit_icon, 24, 24),
        trash: svgIconForVue(trash_icon, 24, 24),
        check: svgIconForVue(icon_check, 24, 24),
        cancel: svgIconForVue(icon_cancel, 24, 24),
        arrow: svgIconForVue(right_arrow, 16, 24),
      },
    };
  },

  methods: {

  },
};
</script>

<style module src="../filter.less" lang="less"></style>
