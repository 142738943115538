<template>
  <div :class="$.project_overview">
    <overview-toolbar
      ref="overviewToolbar"
      :overview-data="overviewData"
      :baselines="baselines"
      :gantt-id="ganttId"
      :is-initialized="isInitialized"
      @updateBaselines="updateBaselines"
      @updateOverview="updateOverview"
      @updateOverviewCharts="updateOverviewCharts"
    />
    <div
      v-if="isInitialized"
      :class="[$.overview_body, 'gantt_scroll']"
    >
      <overview-charts
        :overview-data="overviewData"
        :baseline-data="baselineData"
        :gantt-id="ganttId"
        @openManageModal="openManageModal"
      />
    </div>
    <div
      v-else
      :class="[$.overview_body_skeleton, 'gantt_scroll']"
    >
      <OverviewSkeleton />
    </div>
  </div>
</template>

<script>
import app from '../../app';
import constants from '../../helpers/constants';
import routerHelper from '../../helpers/router';
import { internalApi } from '$$store/api';
import baselinesModel from '../../models/baselines';
import OverviewToolbar from './components/OverviewToolbar.vue';
import OverviewCharts from './components/OverviewCharts.vue';
import OverviewSkeleton from './components/overviewSkeleton.vue';
import statusHelper from '../../helpers/status';

export default {
  components: {
    OverviewToolbar,
    OverviewCharts,
    OverviewSkeleton,
  },
  data() {
    return {
      isInitialized: false,
      ganttId: null,
      overviewData: {},
      baselines: [],
      baselineData: {},
      listeners: [],
    };
  },
  created() {
    this.init();

    this.listeners.push(app.on('app:route:after:changed', to => {
      if (to.params?.mode === 'overview') {
        this.init();
      }
    }));
  },
  beforeDestroy() {
    this.listeners.forEach(id => app.off(id));
  },
  methods: {
    async init() {
      const currentRoute = routerHelper.getCurrentRoute();

      this.ganttId = +currentRoute.params.projectId;

      await this.getOverviewData();
      await this.getBaselines();
      await this.getBaselineData();
      this.isInitialized = true;
    },
    async getOverviewData() {
      const { data } = await internalApi.get(`/overview/${this.ganttId}`);

      if (data) this.overviewData = data;
    },
    async getBaselines() {
      await baselinesModel.init();
      const baselines = baselinesModel.getByGanttId(this.ganttId)
        .map(item => ({ ...item, created_at: new Date(item.created_at) }))
        .sort((a, b) => a.created_at - b.created_at);

      this.baselines = baselines;
    },
    async getBaselineData() {
      const baseline = this.baselines.find(b => b.id === +this.overviewData.baseline_id);

      if (!baseline || this.overviewData.type_overview !== constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE) {
        this.baselineData = {};
        this.resetOverviewType();

        return;
      }

      if (baseline && this.overviewData.type_overview === constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE) {
        statusHelper.handlers.showMainSpinner();

        await baselinesModel.getByGanttIdAndEventId(baseline.gantt_id, baseline.event_id)
          .then(data => {
            if (data) {
              this.baselineData = data;
            } else {
              this.baselineData = {};
              this.resetOverviewType();
            }

            statusHelper.handlers.hideMainSpinner();
          });
      }
    },
    async updateBaselines() {
      await this.getBaselines();

      if (this.overviewData.type_overview === constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED) return;

      const baseline = this.baselines.find(b => b.id === +this.overviewData.baseline_id);

      if (!baseline) {
        this.baselineData = {};
        this.resetOverviewType();
      }
    },
    async updateOverview({ typeOverview, baselineId }) {
      const { data } = await internalApi.put(`/overview/${this.ganttId}`, {
        typeOverview,
        baselineId,
      });

      if (data.status === 'ok') {
        this.overviewData.type_overview = typeOverview;
        this.overviewData.baseline_id = baselineId;

        await this.getBaselineData();
      }
    },
    async updateOverviewCharts(charts) {
      const { data } = await internalApi.put(`/overview/charts/${this.ganttId}`, {
        charts,
      });

      if (data.status === 'ok') {
        this.overviewData.charts = this.overviewData.charts.map(chart => {
          const updatedChart = charts.find(c => c.id === chart.id);

          if (updatedChart) {
            return { ...chart, ...updatedChart };
          }

          return chart;
        });
      }
    },
    resetOverviewType() {
      const typeOverview = constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED;
      const baselineId = null;

      this.overviewData.type_overview = typeOverview;
      this.overviewData.baseline_id = baselineId;

      internalApi.put(`/overview/${this.ganttId}`, {
        typeOverview,
        baselineId,
      });
    },
    openManageModal() {
      this.$refs.overviewToolbar?.openManageModal();
    },
  },
};
</script>

<style module="$" src="./less/overview.less"></style>
