/* eslint-disable import/prefer-default-export */
import globalStore from '$$store/main';
import _ from '../../../libs/lodash';
import { getPreparedCustomColumnById } from './getPreparedCustomColumnById';

export function getCustomColumnsByProject(projectColumns) {
  const customTasks = {};
  const baseTask = {};

  projectColumns.columns.forEach(columnId => {
    setVal(baseTask, columnId, '');
  });

  const groupTasks = globalStore.getters['tasksModel/getItem'](projectColumns.id)?.tasks;

  groupTasks?.forEach(task => { customTasks[task.id] = { ...baseTask }; });

  const preparedCustomColumnMap = {};

  if (projectColumns.values) {
    Object.keys(projectColumns.values).forEach(task_id => {
      let taskCustomCols = customTasks[task_id];

      if (!taskCustomCols) { taskCustomCols = { ...baseTask }; }

      projectColumns.values[task_id].forEach(col => {
        if (!preparedCustomColumnMap[col.column_id]) {
          preparedCustomColumnMap[col.column_id] = getPreparedCustomColumnById(col.column_id);
        }

        const customCol = _.cloneDeep(preparedCustomColumnMap[col.column_id]);

        if (!customCol) return;

        if (customCol.isOptions) {
          const opt = customCol.data.find(i => col.task_value === i.id);

          setVal(taskCustomCols, col.column_id, opt, customCol.editor === 'multiselect');
        } else {
          taskCustomCols[col.column_id] = col.task_value ? col.task_value : '';
        }
      });

      customTasks[task_id] = taskCustomCols;
    });
  }

  return customTasks;

  function setVal(obj, property, val, isArrayValue) {
    if ((typeof val === 'undefined') || val === null) return;

    if (!isArrayValue) {
      obj[property] = val;

      return;
    }

    const prop = obj[property];

    if (!prop) {
      obj[property] = [val];

      return;
    }

    obj[property].push(val);
  }
}
