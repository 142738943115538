var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.webixView
    ? _c(
        "div",
        [
          _c("p", [_vm._v("Sample")]),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  _vm.count++
                },
              },
            },
            [_vm._v("Inc Vue button")]
          ),
          _vm._v(" "),
          _c("p", [_vm._v("Vue value: " + _vm._s(this.count))]),
          _vm._v(" "),
          _c("webix-ui", {
            key: _vm.count,
            attrs: { config: _vm.webixView(_vm.count) },
            on: {
              "inc-count": function ($event) {
                _vm.count++
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }