<template>
  <tooltip
    :content="`${taskName}` + `<div class='tooltip-separator'></div>` +`${parentPath.join(' > ')}`"
    :class="['tooltip-wrapper']"
    :position="'right'"
    :full-width="true"
    :delay="500"
    :interactive="true"
    :hide="hideTooltip"
  >
    <template
      #body
      :style="{width: '100%'}"
    >
      <div
        :class="[$style['task-item']]"
      >
        <div :class="[$style['task-item-name'], counter || mention ? $style['task-item-name_with_counter'] : '']">
          {{ taskName }}
        </div>

        <div :class="[$style['task-item-first-line'], 'gp_autotest_comment_hub_task_item_project_breadcrumbs']">
          <div :class="[$style['project-line']]">
            <div
              :class="[$style['breadcrumbs-icon']]"
            >
              <svg-sprite
                :name="'project'"
                :type="'regular'"
                :size="16"
              />
            </div>
            <div :class="[$style.breadcrumbs]">
              {{ shortenedBreadcrumbsIfDoesntFit.split(" > ")[0] }}
            </div>
          </div>
          <div :class="[$style['counter-block']]">
            <div
              v-if="counter && !mention"
              :class="[$style['task-item-counter'], 'gp_autotest_comment_hub_task_item_counter']"
            >
              {{ counter }}
            </div>
            <div
              v-if="mention"
              :class="[$style['task-item-counter'], 'gp_autotest_comment_hub_task_item_mention']"
            >
              <svg-sprite
                :style="{ color: '#333333'}"
                :name="'mentioned'"
                :type="'regular'"
                :size="16"
              />
            </div>
          </div>
        </div>
        <div :class="[$style['task-item-second-line'], $style.breadcrumbs, 'gp_autotest_comment_hub_task_item_path_breadcrumbs']">
          {{ (shortenedBreadcrumbsPartSecond.length ? '&nbsp;&nbsp;>&nbsp;&nbsp;' : '') + shortenedBreadcrumbsPartSecond.join('&nbsp;&nbsp;>&nbsp;&nbsp;') }}
        </div>
      </div>
    </template>
  </tooltip>
</template>

<script>

import { delay } from 'lodash';
import tooltip from '../../common/VueComponents/tooltips/tooltip.vue';
import { isBreadcrumbsFitInDiv, shortenBreadcrumbs } from '../../taskSettings/helpers/index';

export default {
  name: 'TasksItem',
  components: {
    tooltip,
  },
  props: {
    taskName: {
      type: String,
      required: true,
      default: '',
    },
    parentPath: {
      type: Array,
      required: true,
      default: [],
    },
    taskId: {
      type: String,
      required: true,
      default: '',
    },
    counter: {
      type: Number,
      required: false,
    },
    mention: {
      type: Boolean,
      required: false,
    },
    hideTooltip: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      locales: __,
      isBreadcrumbsFitInDiv: false,
    };
  },
  computed: {

    shortenedBreadcrumbsIfDoesntFit() {
      return shortenBreadcrumbs({
        breadcrumbsArray: this.parentPath,
        separator: ' > ',
        isFit: this.isBreadcrumbsFitInDiv,

      });
    },
    shortenedBreadcrumbsPartSecond() {
      const breads = this.shortenedBreadcrumbsIfDoesntFit.split('>');

      breads.shift();

      return breads;
    },
  },
  mounted() {
    delay(() => {
      this.setIsBreadcrumbsFitInDiv();
    }, 0);
  },
  methods: {
    setIsBreadcrumbsFitInDiv() {
      this.isBreadcrumbsFitInDiv = isBreadcrumbsFitInDiv({
        breadcrumbsArray: this.parentPath,
        separator: ' > ',
        classes: [this.$style.header__breadcrumbs],
        divWidth: 350,
      });
    },
  },
};
</script>
<style module src="../less/commentsHub.less" lang="less"></style>
<style lang="less" scoped>
.separator {
    width: 100%;
    margin: 8px 10px;
    height: 1px;
}
</style>
