<template>
  <div
    class="password-strength-indicator__wrapper"
    :style="{ color }"
  >
    <svg-sprite
      :name="icon"
      :color="color"
      type="bold"
      :size="16"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'PasswordStrengthIndicator',
  props: {
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="less">

.password-strength-indicator__wrapper {
  display: flex;
  gap: 4px;
  position: absolute;
  font-family: Lato-Regular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  padding-top: 4px;
}

</style>
