import _ from '../libs/lodash';

let plansList = {};

if (GT.appMode.isBase) {
  plansList = {
    pro: {
      id: 2,
      currencySymbol: '$',
      month: {
        name: 'Pro',
        fullName: 'Individual',
        key: 'pro',
        price: 15,
      },
      year: {
        name: 'ProA',
        fullName: 'Individual Annual',
        key: 'pro_annual',
        price: 15,
      },
    },
    team5: {
      id: 4,
      teamUsers: 5,
      currencySymbol: '$',
      month: {
        name: 'TeamPro5',
        fullName: 'Team 5',
        key: 'team_5',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro5A',
        fullName: 'Team 5 Annual',
        key: 'team_5_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team10: {
      id: 5,
      teamUsers: 10,
      currencySymbol: '$',
      month: {
        name: 'TeamPro10',
        fullName: 'Team 10',
        key: 'team_10',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro10A',
        fullName: 'Team 10 Annual',
        key: 'team_10_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team15: {
      id: 6,
      teamUsers: 15,
      currencySymbol: '$',
      month: {
        name: 'TeamPro15',
        fullName: 'Team 15',
        key: 'team_15',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro15A',
        fullName: 'Team 15 Annual',
        key: 'team_15_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team20: {
      id: 6,
      teamUsers: 20,
      currencySymbol: '$',
      month: {
        name: 'TeamPro20',
        fullName: 'Team 20',
        key: 'team_20',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro20A',
        fullName: 'Team 20 Annual',
        key: 'team_20_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team25: {
      id: 6,
      teamUsers: 25,
      currencySymbol: '$',
      month: {
        name: 'TeamPro25',
        fullName: 'Team 25',
        key: 'team_25',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro25A',
        fullName: 'Team 25 Annual',
        key: 'team_25_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team30: {
      id: 6,
      teamUsers: 30,
      currencySymbol: '$',
      month: {
        name: 'TeamPro30',
        fullName: 'Team 30',
        key: 'team_30',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro30A',
        fullName: 'Team 30 Annual',
        key: 'team_30_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team35: {
      id: 6,
      teamUsers: 35,
      currencySymbol: '$',
      month: {
        name: 'TeamPro35',
        fullName: 'Team 35',
        key: 'team_35',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro35A',
        fullName: 'Team 35 Annual',
        key: 'team_35_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team40: {
      id: 6,
      teamUsers: 40,
      currencySymbol: '$',
      month: {
        name: 'TeamPro40',
        fullName: 'Team 40',
        key: 'team_40',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro40A',
        fullName: 'Team 40 Annual',
        key: 'team_40_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team45: {
      id: 6,
      teamUsers: 45,
      currencySymbol: '$',
      month: {
        name: 'TeamPro45',
        fullName: 'Team 45',
        key: 'team_45',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro45A',
        fullName: 'Team 45 Annual',
        key: 'team_45_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team50: {
      id: 6,
      teamUsers: 50,
      currencySymbol: '$',
      month: {
        name: 'TeamPro50',
        fullName: 'Team 50',
        key: 'team_50',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro50A',
        fullName: 'Team 50 Annual',
        key: 'team_50_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team55: {
      id: 6,
      teamUsers: 55,
      currencySymbol: '$',
      month: {
        name: 'TeamPro55',
        fullName: 'Team 55',
        key: 'team_55',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro55A',
        fullName: 'Team 55 Annual',
        key: 'team_55_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team60: {
      id: 6,
      teamUsers: 60,
      currencySymbol: '$',
      month: {
        name: 'TeamPro60',
        fullName: 'Team 60',
        key: 'team_60',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro60A',
        fullName: 'Team 60 Annual',
        key: 'team_60_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team65: {
      id: 6,
      teamUsers: 65,
      currencySymbol: '$',
      month: {
        name: 'TeamPro65',
        fullName: 'Team 65',
        key: 'team_65',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro65A',
        fullName: 'Team 65 Annual',
        key: 'team_65_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team70: {
      id: 6,
      teamUsers: 70,
      currencySymbol: '$',
      month: {
        name: 'TeamPro70',
        fullName: 'Team 70',
        key: 'team_70',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro70A',
        fullName: 'Team 70 Annual',
        key: 'team_70_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team75: {
      id: 6,
      teamUsers: 75,
      currencySymbol: '$',
      month: {
        name: 'TeamPro75',
        fullName: 'Team 75',
        key: 'team_75',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro75A',
        fullName: 'Team 75 Annual',
        key: 'team_75_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team80: {
      id: 6,
      teamUsers: 80,
      currencySymbol: '$',
      month: {
        name: 'TeamPro80',
        fullName: 'Team 80',
        key: 'team_80',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro80A',
        fullName: 'Team 80 Annual',
        key: 'team_80_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team85: {
      id: 6,
      teamUsers: 85,
      currencySymbol: '$',
      month: {
        name: 'TeamPro85',
        fullName: 'Team 85',
        key: 'team_85',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro85A',
        fullName: 'Team 85 Annual',
        key: 'team_85_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team90: {
      id: 6,
      teamUsers: 90,
      currencySymbol: '$',
      month: {
        name: 'TeamPro90',
        fullName: 'Team 90',
        key: 'team_90',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro90A',
        fullName: 'Team 90 Annual',
        key: 'team_90_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team95: {
      id: 6,
      teamUsers: 95,
      currencySymbol: '$',
      month: {
        name: 'TeamPro95',
        fullName: 'Team 95',
        key: 'team_95',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro95A',
        fullName: 'Team 95 Annual',
        key: 'team_95_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
    team100: {
      id: 6,
      teamUsers: 100,
      currencySymbol: '$',
      month: {
        name: 'TeamPro100',
        fullName: 'Team 100',
        key: 'team_100',
        price: 11.9,
        priceTemplate: '11<small>.90</small>',
      },
      year: {
        name: 'TeamPro100A',
        fullName: 'Team 100 Annual',
        key: 'team_100_annual',
        price: 8.9,
        priceTemplate: '8<small>.90</small>',
      },
    },
  };
}

const innerObject = {
  getFileStorageByUserAndByTeamProject() {
    return user.accountPlanData.attachment_size || user.attachment.MAX_STORAGE_SPACE;
  },
  getFileStorageByUserAndByMainProject() {
    if (GT.appMode.isLink) {
      return 0;
    }

    return user.attachment.essential;
  },
  getPlanFullNameByTargetName(plan, period) { // for example TeamPro15A or Pro
    let fullName = '';

    // _.some(Object.keys(plansList), function (paymentPlan, index) {
    //   if (plansList[paymentPlan].monthName === paymentPlanName) {
    //     fullName = plansList[paymentPlan].fullMonthName;
    //     return true;
    //   }
    //
    //   if (plansList[paymentPlan].yearName === paymentPlanName) {
    //     fullName = plansList[paymentPlan].fullYearName;
    //     return true;
    //   }
    // });
    // console.log(plan, period);
    fullName = plansList[plan][period].fullName;

    return fullName;
  },
  getDataPlanPro() {
    return plansList.pro;
  },
  getDataPlanTeam(period, plan = 40) {
    const teamPlan = `team${plan}`;

    if (period) {
      return plansList[teamPlan][period];
    }

    return plansList[teamPlan];
  },
  getDataPlanTeam5Pro(period) {
    if (period) {
      return plansList.team5[period];
    }

    return plansList.team5;
  },
  getDataPlanTeam10Pro(period) {
    if (period) {
      return plansList.team10[period];
    }

    return plansList.team10;
  },
  getDataPlanTeam15Pro(period) {
    if (period) {
      return plansList.team15[period];
    }

    return plansList.team15;
  },
  getPaymentDataById(paymentId) {
    let planData = {};

    _.some(Object.keys(plansList), (paymentPlan, index) => {
      if (plansList[paymentPlan].id === paymentId) {
        planData = webix.copy(plansList[paymentPlan]);
      }
    });

    return planData;
  },
  getArrayOfPlanFullNames() {
    const planNames = [];

    _.each(Object.keys(plansList), paymentPlan => {
      // planNames.push(plansList[paymentPlan].fullYearName);
      // planNames.push(plansList[paymentPlan].fullMonthName);
      planNames.push(plansList[paymentPlan].month.fullName);
      planNames.push(plansList[paymentPlan].year.fullName);
    });

    return planNames;
  },
  settings: {
    canWork: GT.appMode.isBase,
  },
};

export default innerObject;
