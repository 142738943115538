<template>
  <div
    :key="componentKey"
    v-click-outside="{
      handler: hideCalendar,
      closeConditional: () => positionContext,
    }"
    :class="[
      $style['date-picker__container'],
      'vgp-interactive-element'
    ]"
  >
    <vgp-input
      ref="datePickerInput"
      :class="[
        !!positionContext ? $style.active : '',
        $style['custom-input-hover']
      ]"
      :value="formattedDate"
      :icons-left="iconsLeft"
      :border="false"
      :mask="dateMask"
      :readonly="isTimePicker"
      :is-disabled="disabled"
      :placeholder="placeholder"
      small
      @onFocus="showCalendar"
      @keydown.native.tab="hideCalendar"
      @onChange="handleChange"
    />
    <context-menu
      v-if="positionContext"
      :position="positionContext"
    >
      <template #body>
        <webix-ui
          :config="datepickerConfig"
        />
      </template>
    </context-menu>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import dateHelper from '$$helpers/dateFormats';
import moment from '../../../../../libs/moment';
import constants from '$$helpers/constants';

export default {
  name: 'DatePicker',
  props: {
    property: {
      type: String,
      default: 'start_date',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: 'day',
    },
    calendarIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    scrollingContainer: {
      type: String,
      default: null,
    },
    taskData: {
      type: Object,
      default: null,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollHandler: null,
      resizeHandler: null,
      componentKey: 0,
      positionContext: null,
    };
  },
  computed: {
    dateMask() {
      if (this.isTimePicker) {
        return '';
      }

      return user.dateFormat.replace(/[a-z]/gi, '#');
    },
    iconsLeft() {
      if (this.calendarIcon) {
        return [
          {
            name: 'calendar',
            handler: this.focusDatePickerInput,
          },
        ];
      }

      return [];
    },
    isTimePicker() {
      return this.type === 'time';
    },
    formattedDate() {
      if (!this.value) return '';
      const date = new Date(this.value);
      let format = dateHelper.getDateFormat();

      if (this.isTimePicker) {
        format = dateHelper.getDateFormatForGanttGrid(true);
      }

      return webix.Date.dateToStr(format)(date);
    },
    datePickerId() {
      return `datePicker-${new Date().getTime()}`;
    },
    datepickerConfig() {
      const component = this;
      const date = this.value || null;
      const config = {
        view: 'calendar',
        css: 'webix_calendar',
        id: component.datePickerId,
        monthSelect: true,
        navigation: true,
        weekHeader: true,
        borderless: true,
        fillspace: true,
        height: 260,
        type: this.type,
        calendar: {
          height: 300,
          cellHeight: 24,
        },
        date,
        icons: [{
          template() {
            const todayBtn = `<span role='button' tabindex='0' class='webix_cal_icon_today webix_cal_icon'>${webix.i18n.calendar.today}</span>`;

            return component.isShowTodayBtn() ? todayBtn : '';
          },
          on_click: {
            webix_cal_icon_today() {
              if (component.property === 'end_date') {
                this.setValue(gantt.getClosestWorkTime({
                  date: gantt.date.day_start(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
                  dir: 'past',
                  task: this.taskData,
                }));
              } else {
                this.setValue(gantt.getClosestWorkTime({
                  date: gantt.date.day_start(new Date()),
                  dir: 'future',
                  task: this.taskData,
                }));
              }
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
            webix_cal_icon_clear() {
              this.setValue('');
              this.callEvent('onDateClear', []);
            },
          },
        }],
        on: {
          onChange(val) {
            component.selectDate(val);
          },
        },
        weekNumber: false,
        blockDates: component.isBlockedDate,
        blockTime: component.isBlockedTime,
        minuteStep: 1,
      };

      return config;
    },

  },
  watch: {
    positionContext(val) {
      if (val) {
        this.$nextTick(this.setSelected);
      }
    },
    disabled(val) {
      if (val) {
        this.positionContext = null;
      }
    },
  },
  mounted() {
    this.addScrollHandler();
    this.addResizeHandler();
  },
  beforeDestroy() {
    this.removeScrollHandler();
    this.removeResizeHandler();
  },
  methods: {
    addScrollHandler() {
      this.scrollHandler = throttle(this.hideCalendar, 100);
      const scrollDivs = document.querySelectorAll('div.gantt_scroll');

      scrollDivs.forEach(div => div.addEventListener('scroll', this.scrollHandler));
    },
    addResizeHandler() {
      this.resizeHandler = throttle(this.adjustCalendarPopup, 100);
      window.addEventListener('resize', this.resizeHandler);
    },
    removeScrollHandler() {
      const scrollDivs = document.querySelectorAll('div.gantt_scroll');

      scrollDivs.forEach(div => div.removeEventListener('scroll', this.scrollHandler));
    },
    removeResizeHandler() {
      window.removeEventListener('resize', this.resizeHandler);
    },
    adjustCalendarPopup() {
      if (this.positionContext) {
        this.positionContext = this.$refs.datePickerInput.$el.getBoundingClientRect();
      }
    },
    focusDatePickerInput() {
      this.$refs.datePickerInput.$refs.input_field_icons.focus();
    },
    handleChange(event) {
      if (this.isTimePicker) {
        return;
      }

      const value = event.target.value;

      if (!value.length && this.allowEmpty) {
        this.selectDate([]);

        return;
      }

      if (!this.isValidManualDateInput(value)) {
        this.componentKey++;
        this.$nextTick(this.setSelected);
        this.$toast.warning(__('gantt_date_invalid'));

        return;
      }

      const { day, month, year } = this.parseDayMonthYearFromManualDateInput(value);
      const newDate = new Date(year, month - 1, day);

      if (this.isBlockedDate(newDate)) {
        this.componentKey++;
        this.$nextTick(this.setSelected);

        return;
      }

      this.selectDate([newDate]);
    },
    isValidManualDateInput(value) {
      if (value.length !== this.dateMask.length) return false;

      const { day, month, year } = this.parseDayMonthYearFromManualDateInput(value);

      if (month < 1 || month > 12) return false;
      const lastDayInMonth = (new Date(year, month, 0)).getDate();

      if (day > lastDayInMonth || day < 1) return false;

      return true;
    },
    parseDayMonthYearFromManualDateInput(value) {
      let day; let month; let
        year;
      const delimiter = dateHelper.getCurrentDateDelimiter();

      if (dateHelper.isCurrentDateFormatUSA()) {
        [month, day, year] = value.split(delimiter);
      } else {
        [day, month, year] = value.split(delimiter);
      }

      if (dateHelper.isCurrentDateFormatHas2DigitYear()) {
        year = `20${year}`;
      }

      return { day: Number(day), month: Number(month), year: Number(year) };
    },
    showCalendar() {
      if (this.disabled) return;

      this.positionContext = this.$refs.datePickerInput.$el.getBoundingClientRect();
    },
    hideCalendar() {
      this.positionContext = null;
      this.$refs.datePickerInput?.$refs.input_field_icons.blur();
    },
    selectDate(val) {
      const value = val ? val[0] : '';

      if ((new Date(value)).getTime() === (new Date(this.value)).getTime()) return;

      const emitValue = value ? this.toStringDate(value) : '';

      this.$emit('change', emitValue);
      this.hideCalendar();
    },

    toStringDate(date) {
      return moment(date, constants.TASK_DATES_FORMAT).toDate();
    },

    setSelected() {
      const $$ui = $$(this.datePickerId);
      const date = this.value;

      $$ui && date && $$ui.selectDate(date);
    },
    isShowTodayBtn() {
      const isWorkToday = !this.isBlockedDate(new Date());

      if (this.property === 'start_date') {
        return isWorkToday;
      }

      if (this.property === 'end_date') {
        const isTodayAfterStartDate = gantt.date.day_start(new Date(_.clone(this.taskData.start_date))).valueOf() < new Date().valueOf();

        return isTodayAfterStartDate && isWorkToday;
      }

      return true;
    },
    isBlockedDate(date) {
      if (this.property !== 'start_date' && this.property !== 'end_date') return false;

      const projectCalendar = gantt.getCalendar(this.taskData.gantt_id);

      if (this.property === 'end_date') {
        if (new Date(date).valueOf() < gantt.date.day_start(_.clone(this.taskData.start_date)).valueOf()) {
          return true;
        }
      }

      if (gantt.isWorkDay(date, projectCalendar)) return false;

      return true;
    },

    isBlockedTime(date) {
      if (this.property !== 'start_date' && this.property !== 'end_date') return false;

      const projectCalendar = gantt.getCalendar(this.taskData.gantt_id);

      if (this.property === 'end_date' && gantt.isWorkEndTime(date, projectCalendar)) {
        return false;
      }

      if (this.property === 'start_date' && gantt.isWorkStartTime(date, projectCalendar)) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style module lang="less">

@import '../../../../../less/_mixins.less';
@import '../../../../../less/_variables.less';

.date-picker__container {
  width: 100%;
}

.active {
  .border(2px, @blue-default) !important;
}

.custom-input-hover:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

</style>
