<template>
  <div :class="$.profile_settings">
    <div :class="$.title">
      <svg-sprite
        id="gp_autotest_sidebar_settings_close_button"
        :class="[$.icon]"
        name="arrow-left"
        type="bold"
        @click="sideBarBack"
      />
      {{ locales('settings_global_title') }}
    </div>
    <div :class="$.menu_list">
      <div
        v-for="(menuItem, index1) in menu"
        :key="index1"
      >
        <div
          v-for="(item, index) in menuItem"
          :key="index"
          :class="[$.menu_item, item.type === activeItem ? $.active : '']"
        >
          <div
            v-if="!item.hidden && item.type !== 'logout'"
            :id="`gp_autotest_sidebar_settings_menu_item_${item.type}`"
            :class="$.wrapper"
            @click="onChageRoute(item.type)"
          >
            <svg-sprite
              :name="item.icon"
              :size="20"
              :class="[$.icon, item.type === 'logout' ? $.logout : '']"
            />
            <div
              :class="$.name"
            >
              {{ item.title }}
              <span v-if="checkErrorsInSetting(item)">
                <svg-sprite
                  name="warning-1"
                  size="20"
                  color="#FF9A00"
                  type="bold"
                />
              </span>
            </div>
          </div>
          <div
            v-if="item.type === 'logout'"
            :class="$.logout_wrapper"
          >
            <vgp-button
              id="gp_autotest_sidebar_settings_logout_button"
              :type="'text'"
              :icon="{name: 'logout' }"
              :small="true"
              :icon-side="'left'"
              :label="item.title"
              @onClick="onChageRoute(item.type)"
            />
          </div>
        </div>
        <div
          v-if="menuItem.length > 1"
          :class="$.delimiter"
        />
      </div>
    </div>

    <core-confirm-popup
      :show="isShowLogoutPopup"
      close-icon
      :title="locales('settings_confirm_logout_title')"
      :text="locales('settings_confirm_logout')"
      :cancel-button="{ title: locales('common_no'), type: 'secondary', id: 'gp_autotest_sidebar_settings_logout_confirm_cancel_button' }"
      :ok-button="{ title: locales('common_yes'), type: 'destructive', id: 'gp_autotest_sidebar_settings_logout_confirm_ok_button' }"
      @onClickCancel="isShowLogoutPopup = false"
      @onClickOk="logout"
    />
  </div>
</template>

<script>
import routerHelper from '../../../helpers/router';
import settingsMenu from './profileSettingsMenu';
import app from '../../../app';
import plansModel from '../../../models/plans';
import coreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import rights from '../../../components/rights';
import store from '$$store/main';

export default {
  components: {
    coreConfirmPopup,
  },
  data() {
    return {
      locales: __,
      menu: [],
      activeItem: '',
      isShowLogoutPopup: false,
    };
  },
  computed: {
    accountRolesHasStatusSettings() {
      return rights.account.hasRight('project_statuses_settings');
    },
    accountRolesHasTeamSettingAccess() {
      return rights.account.hasRight('team_settings');
    },
    accountRolesHasTeamAndResourcesSettingAccess() {
      return rights.account.hasRight('team_and_resources_settings');
    },
    accountRolesHasCustomFieldsAccess() {
      return rights.account.hasRight('custom_fields_settings');
    },
    accountRolesHasAccountRolesAccess() {
      return rights.account.checkRightByArrayOfBits(['account_roles_create', 'account_roles_update', 'account_roles_delete']);
    },

    accountRolesHasProjectRolesAccess() {
      return rights.account.checkRightByArrayOfBits(['project_roles_create', 'project_roles_update', 'project_roles_delete']);
    },
    accountRolesHasBillingSettings() {
      return rights.account.isOwner();
    },

  },
  created() {
    const that = this;

    this.init();

    app.on('onAfterCollaboration', data => {
      if (data.event === 'ResourceRoleUpdated') {
        that.init();
      }
    });

    app.on('app:route:changed', data => {
      this.activeItem = data.name;
    });
  },
  methods: {
    init() {
      const isOverSubscription = plansModel.isOverSubscription() || plansModel.isExpiredTrial();
      const isTerminated = user.subscription.status_code !== 10 && user.subscription.status_code !== 2;
      const menu = [];

      settingsMenu.getMenuItems().map(menuItem => {
        menuItem.forEach(item => {
          if (item.type === 'billing' && !this.accountRolesHasBillingSettings) {
            item.hidden = true;
          }

          if (item.type === 'team' && (!this.accountRolesHasTeamSettingAccess || (isOverSubscription || !isTerminated))) {
            item.hidden = true;
          }

          if (item.type === 'security' && (isOverSubscription || !isTerminated)) {
            item.hidden = true;
          }

          if (item.type === 'integrations' && (isOverSubscription || !isTerminated)) {
            item.hidden = true;
          }

          if (item.type === 'notifications' && (isOverSubscription || !isTerminated)) {
            item.hidden = true;
          }

          if ((item.type === 'columns') && (!this.accountRolesHasCustomFieldsAccess || (isOverSubscription || !isTerminated))) {
            item.hidden = true;
          }

          if ((item.type === 'account_rights') && (!this.accountRolesHasAccountRolesAccess || (isOverSubscription || !isTerminated))) {
            item.hidden = true;
          }
          if ((item.type === 'project_rights') && (!this.accountRolesHasProjectRolesAccess || (isOverSubscription || !isTerminated))) {
            item.hidden = true;
          }

          if (item.type === 'statuses' && (!this.accountRolesHasStatusSettings || (isOverSubscription || !isTerminated))) {
            item.hidden = true;
          }

          if (item.type === 'resources' && !this.accountRolesHasTeamAndResourcesSettingAccess) {
            item.hidden = true;
          }
          if (item.type === 'admin' && user.userRole !== 'admin') {
            item.hidden = true;
          }

          return item;
        });

        if (menuItem.some(item => !item.hidden)) {
          menu.push(menuItem);
        }
      });

      this.menu = menu;
      this.activeItem = routerHelper.getCurrentRoute().name;
    },

    checkErrorsInSetting(item) {
      let hasErrors = false;

      const errors = store.getters['teamSetting/getSettingErrors'];

      Object.keys(errors).forEach(key => {
        if (key === item.type && errors[key].length !== 0) hasErrors = true;
      });

      return hasErrors;
    },

    onChageRoute(type) {
      if (type === 'logout') {
        this.isShowLogoutPopup = true;

        return;
      }
      if (type === 'admin') {
        this.openAdminPanel();

        return;
      }
      if (type === 'billing') {
        userExtAnalytics.log('billing_opened');
      }

      if (type === 'resources') {
        type += '/people';
      }
      routerHelper.changeRoute(`/settings/${type}`);
      this.activeItem = type.split('/')[0];
    },
    openAdminPanel() {
      _.delay(() => {
        window.open(`${GT.siteConfig}/admin/`);
      });
    },
    logout() {
      document.location.href = `/logout/${user.locale}`;
    },
    sideBarBack() {
      this.$emit('sideBarBack');
    },
  },
};
</script>

<style module='$' src='../less/profileMenu.less'></style>
