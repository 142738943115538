<template>
  <div class="integration_msteams_settings_window">
    <div class="integration_msteams_settings_window-header">
      <div :class="$style.integration_header_text">
        <div class="icon">
          <webix-ui :config="icons.msteamsIcon" />
        </div>
        Microsoft Teams&nbsp;
        <span>{{
          `${locales("integration_msteams_settings_page_header_text")}`
        }}</span>
      </div>
      <closer
        :on-close="closeWindow"
        :size="30"
      />
    </div>
    <div class="integration_msteams_settings_window-body">
      <div class="scroll_container">
        <div class="container_msteams">
          <description
            :title="locales('integration_msteams_settings_page_title')"
            :description="
              locales('integration_msteams_settings_page_description')
            "
            :remove-integration="removeIntegration"
            :table-preloder="tablePreloder"
            :disable-integration="disableIntegration"
            :remove-integration-button="removeIntegrationButton"
          />
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="!tablePreloder"
              key="table"
              class="connections_table"
            >
              <integration-header />
              <div
                v-if="filteredProjectChannelConnections.length"
                class="connections"
              >
                <div
                  v-for="(
                    projectChannelConnection, index
                  ) in filteredProjectChannelConnections"
                  :key="index"
                  class="connection"
                  :class="{ full_border: projectChannelConnection.fullBorder }"
                >
                  <connection-item
                    v-if="!projectChannelConnection.editMode"
                    :key="projectChannelConnection.id"
                    :project-channel-connection="projectChannelConnection"
                    :get-project-by-gantt-id="getProjectByGanttId"
                    :toggle-edit-view="toggleEditView"
                    :delete-item="deleteItem"
                    :get-settings-string="getSettingsString"
                  />

                  <connection-edit-item
                    v-else
                    :key="projectChannelConnection.id"
                    :project-channel-connection="projectChannelConnection"
                    :project-options="filteredProjectOptions"
                    :team-options="filteredTeamOptions"
                    :channel-options="filteredChannelOptions"
                    :settings-options="settingsOptions"
                    :selected-inputs="selectedInputs"
                    :on-change-project-id="onChangeProjectId"
                    :on-change-team-id="onChangeTeamId"
                    :on-change-channel-id="onChangeChannelId"
                    :on-change-settings="onChangeSettings"
                    :on-focus-select="onFocusSelect"
                    :on-changes-flag="onChangesFlag"
                    :on-ok="editItem"
                    :on-cancel="closeEditMode"
                    :channel-is-empty-error="channelIsEmptyError"
                    :team-is-empty-error="teamIsEmptyError"
                    :project-is-empty-error="projectIsEmptyError"
                    :is-channels-list-loading="isChannelsListLoading"
                  />
                </div>
              </div>
              <div
                v-else
                class="no_connections"
              >
                <div class="text">
                  {{
                    locales(
                      "integration_slack_settings_page_table_no_connections"
                    )
                  }}
                </div>
              </div>
              <div
                v-if="!addMode"
                class="add_item_container"
              >
                <div
                  v-if="!editMode"
                  class="add_button"
                  @click="onClickAdd"
                >
                  <div class="text">
                    {{ locales("integration_slack_settings_page_add_button") }}
                  </div>
                </div>
              </div>
              <connection-edit-item
                v-else
                :project-channel-connection="{}"
                :project-options="filteredProjectOptions"
                :team-options="filteredTeamOptions"
                :channel-options="filteredChannelOptions"
                :settings-options="settingsOptions"
                :selected-inputs="selectedInputs"
                :on-change-project-id="onChangeProjectId"
                :on-change-team-id="onChangeTeamId"
                :on-change-channel-id="onChangeChannelId"
                :on-change-settings="onChangeSettings"
                :on-focus-select="onFocusSelect"
                :on-changes-flag="onChangesFlag"
                :on-ok="addItem"
                :on-cancel="closeAddMode"
                :channel-is-empty-error="channelIsEmptyError"
                :team-is-empty-error="teamIsEmptyError"
                :project-is-empty-error="projectIsEmptyError"
                :add-mode="true"
                :is-channels-list-loading="isChannelsListLoading"
              />
            </div>
            <preloader v-else />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import app from '../../../app';

// Icons
import msteamsIcon from '../../../svg/integration/settings/msteams.svg';
import closeCross from '../../../svg/integration/settings/close.svg';
import tipImage from '../../../svg/integration/settings/image_tip.svg';
import yellowInfo from '../../../svg/integration/settings/yellow_info.svg';

// Models
import projectsModel from '../../../models/projects';
import integrationsModel from '../../../models/integrations/integrations';
import {
  integrationMSTeamsModel,
} from '../../../models/integrations/integrationsMSteams';

// Helpers
import vueSvgIcon from '../../common/svgIconForVue';
import routerHelper from '../../../helpers/router';
import multiselect from '../multiselect';
import rights from '../../../components/rights/index';

// Components
import description from './components/Description.vue';
import IntegrationHeader from './components/IntegrationTableHeader.vue';
import preloader from './components/Preloader.vue';
import ConnectionItem from './components/ConnectionItem.vue';
import ConnectionEditItem from './components/ConnectionEditItem.vue';
import closer from '../../common/VueComponents/closer/closer.vue';

function cloneAndfilterByProjects(items) {
  if (!items.length) return items;
  const projectIds = projectsModel.getAllProjects().map(el => el.gantt_id);

  return items.reduce((acc, el) => {
    if (projectIds.indexOf(el.gantt_id) !== -1) {
      acc.push({ ...el });
    }

    return acc;
  }, []);
}

class InputValues {
  constructor() {
    this.projectId = null;
    this.teamId = null;
    this.channelId = null;
    this.settings = null;
  }

  static clear(input) {
    input.projectId = null;
    input.teamId = null;
    input.channelId = null;
    input.settings = null;
  }
}

function createConnectionHash({ gantt_id, msteams_team_id, channel_id }) {
  return gantt_id + msteams_team_id + channel_id;
}

function composeConnectionObject(selectedInputs, teamsOptions, channelsOptions) {
  const { teamId, channelId, settings } = selectedInputs;
  const channelOption = channelsOptions.find(({ id }) => id === channelId);
  const teamOption = teamsOptions.find(({ id }) => id === teamId);

  return {
    teamId,
    teamName: teamOption.value,
    channelId,
    channelName: channelOption.value,
    settings,
  };
}

export default {
  components: {
    'integration-header': IntegrationHeader,
    'connection-item': ConnectionItem,
    'connection-edit-item': ConnectionEditItem,
    description,
    preloader,
    closer,
  },
  props: [],
  data() {
    return {
      icons: {
        msteamsIcon: vueSvgIcon(msteamsIcon, 18, 19),
        closeCross: vueSvgIcon(closeCross, 14, 14),
        yellowInfo: vueSvgIcon(yellowInfo, 24, 24),
      },
      webix: { multiselect },
      removeIntegrationButton: !!rights.account.isOwner(),
      locales: __,
      projects: projectsModel.getAllProjects(),
      teamsOptions: [],
      channelsOptions: [],
      projectsOptions: [],
      settingsOptions: [],
      projectChannelConnections: [],
      tips: [],
      tablePreloder: true,
      addMode: false,
      editMode: false,
      selectedSelect: null,
      onChangesFlag: 0,
      onSelectInputFlag: 0,
      projectIsEmptyError: false,
      teamIsEmptyError: false,
      channelIsEmptyError: false,
      selectedInputs: new InputValues(),
      currentConnectionHash: null,
      isChannelsListLoading: false,
      isDisableNewConnectionBtn: false,
    };
  },
  computed: {
    filteredProjectChannelConnections: {
      get() {
        const _data = _.map(this.projectChannelConnections, _.clone);

        _data.sort((a, b) => +a.gantt_id - +b.gantt_id);

        let _ganttId = _data && _data.length ? _data[0].gantt_id : null;

        for (let i = 0; i < _data.length; i++) {
          if (i === 0) {
            _data[i].showProject = true;
          }
          if (i === _data.length - 1) {
            _data[i].fullBorder = true;
          } else if (_data[i + 1].gantt_id === _ganttId) {
            _data[i].smallBorder = true;
          } else {
            _data[i + 1].showProject = true;
            _ganttId = _data[i + 1].gantt_id;
            _data[i].fullBorder = true;
          }
        }

        return _data;
      },
    },
    filteredProjectOptions: {
      get() {
        return this.projectsOptions;
      },
    },
    filteredChannelOptions: {
      get() {
        const { projectId, teamId } = this.selectedInputs;

        return this.channelsOptions.filter(({ id }) => {
          const hash = projectId + teamId + id;

          return this.currentConnectionHash === hash || !this.connectionsHashes.has(hash);
        });
      },
    },
    filteredTeamOptions: {
      get() {
        return this.teamsOptions;
      },
    },
    connectionsHashes() {
      return new Set(this.projectChannelConnections.map(createConnectionHash));
    },
  },
  created() {
    this.initSettingsOptions();
    this.loadData().then(() => {
      this.tablePreloder = false;
    });
  },
  mounted() {
    const service_url_updated_listener_id = app.on('integration:msteams:service_url_updated', () => {
      this.projectChannelConnections = cloneAndfilterByProjects(integrationMSTeamsModel.getDataFromCollection());
    });

    this.clearListeners = () => app.off(service_url_updated_listener_id);

    // hide selectPopup on scroll
    document.querySelector('.integration_msteams_settings_window-body').onscroll = () => {
      if (this.selectedSelect) {
        document.body.click();
        this.selectedSelect = null;
      }
    };
  },
  destroyed() {
    this.clearListeners && this.clearListeners();
  },
  methods: {
    initSettingsOptions() {
      this.settingsOptions = [
        { id: 'new_task', value: this.locales('integration_settings_page_settings_new_task') },
        { id: 'status', value: this.locales('integration_settings_page_settings_status') },
        { id: 'comment', value: this.locales('integration_settings_page_settings_comment') },
        { id: 'attachment', value: this.locales('integration_settings_page_settings_attachment') },
        { id: 'assignee', value: this.locales('integration_settings_page_settings_assignee') },
        { id: 'new_start_date', value: this.locales('integration_settings_page_settings_new_start_date') },
      ];
    },
    async loadData() {
      try {
        await integrationsModel.getDataFromBackend();

        const integration = integrationsModel.getByType('msteams');
        const currentUserConnected = !!integration.users.find(el => el.user_id === GT.user.id);

        if (!integration.isActive || !currentUserConnected) {
          routerHelper.changeRoute('/');

          return;
        }

        const [_teamsOptions, msteamsConnections] = await Promise.all([
          integrationMSTeamsModel.getTeams(),
          integrationMSTeamsModel.getData(),
        ]);

        this.teamsOptions = _teamsOptions.map(({ displayName: value, id }) => ({ id, value }));
        this.projectChannelConnections = cloneAndfilterByProjects(msteamsConnections);

        this.projectsOptions = projectsModel.getAllProjects().filter(item => !item.is_jira)
          .map(({ gantt_id, name }) => ({ id: gantt_id, value: name }));
      } catch (error) {
        console.error(error);
      }
    },
    onClickAdd() {
      this.closeEditMode();
      this.addMode = true;
      this.channelsOptions = [];
    },
    validateForm() {
      const { projectId, teamId, channelId } = this.selectedInputs;

      this.projectIsEmptyError = !projectId;
      this.teamIsEmptyError = !teamId;
      this.channelIsEmptyError = !channelId;

      return !(this.projectIsEmptyError || this.teamIsEmptyError || this.channelIsEmptyError);
    },
    async addItem() {
      if (this.isDisableNewConnectionBtn) {
        return;
      }

      if (this.validateForm()) {
        this.isDisableNewConnectionBtn = true;
        const { status, item } = await integrationMSTeamsModel.addConnection({
          type: 'msteams',
          ganttId: this.selectedInputs.projectId,
          ...composeConnectionObject(
            this.selectedInputs,
            this.teamsOptions,
            this.channelsOptions,
          ),
        });

        if (status === 'ok') {
          if (!item.service_url) item.waitingForActivation = true;
          this.projectChannelConnections.push(item);
          this.closeAddMode();
          this.$nextTick(() => {
            const screenBody = document.querySelector('.integration_msteams_settings_window-body');

            screenBody.scrollTo(0, screenBody.scrollHeight);
          });
        }
        this.isDisableNewConnectionBtn = await false;
      }
    },
    closeAddMode() {
      InputValues.clear(this.selectedInputs);
      this.addMode = false;
      this.projectIsEmptyError = false;
      this.channelIsEmptyError = false;
      this.teamIsEmptyError = false;
      this.isChannelsListLoading = false;
    },
    onChangeProjectId(value) {
      this.selectedInputs.projectId = +value;
      this.projectIsEmptyError = false;
      if (this.selectedInputs.channelId) {
        this.projectChannelConnections.forEach(_el => {
          if (+_el.gantt_id === +this.selectedInputs.projectId) {
            if (_el.channel_id === this.selectedInputs.channelId) {
              this.selectedInputs.channelId = null;
            }
          }
        });
      }

      this.onChangesFlag++;
    },
    onChangeChannelId(value) {
      this.selectedInputs.channelId = value;
      this.channelIsEmptyError = false;
      this.onChangesFlag++;
    },
    onChangeTeamId(value) {
      this.selectedInputs.teamId = value;
      this.teamIsEmptyError = false;
      this.channelIsEmptyError = false;
      this.onChangesFlag++;
      this.fetchChannels(value);
    },
    onChangeSettings(value) { this.selectedInputs.settings = value; },
    onFocusSelect(el) { this.selectedSelect = el; },
    getProjectByGanttId(ganttId) { return this.projects.find(el => el.gantt_id === +ganttId); },
    getSettingsString(item) {
      return this.settingsOptions.reduce((acc, { id, value }) => {
        if (item[`setting_${id}`]) {
          acc.settingsValue += acc.settingsValue ? `,${id}` : id;
          if (acc.settingsText === '-') acc.settingsText = value;
          else acc.settingsText += `, ${value}`;
        }

        return acc;
      }, { settingsText: '-', settingsValue: '' });
    },
    toggleEditView(channelProjectId) {
      this.closeAddMode();
      this.projectChannelConnections.forEach((el, index) => {
        Vue.set(this.projectChannelConnections[index], 'editMode', false);

        if (el.id === channelProjectId) {
          const projectChanel = this.projectChannelConnections[index];

          this.editMode = true;
          Vue.set(projectChanel, 'editMode', true);
          const {
            gantt_id, channel_id, msteams_team_id, channel_name,
          } = projectChanel;
          const settings = this.getSettingsString(projectChanel).settingsValue;

          this.selectedInputs.projectId = gantt_id;
          this.selectedInputs.teamId = msteams_team_id;
          this.selectedInputs.channelId = channel_id;
          this.selectedInputs.settings = settings;

          this.currentConnectionHash = createConnectionHash(projectChanel);

          this.fetchChannels(msteams_team_id);
          this.channelsOptions = [{ id: channel_id, value: channel_name }];
        }
      });
    },
    async editItem(id) {
      if (this.validateForm()) {
        const { status, item } = await integrationMSTeamsModel.editConnection({
          channelProjectId: id,
          ganttId: this.selectedInputs.projectId,
          ...composeConnectionObject(
            this.selectedInputs,
            this.teamsOptions,
            this.channelsOptions,
          ),
        });

        if (status === 'ok') {
          this.projectChannelConnections.push(item);
          this.deleteProjectChannelConnectionById(id);
          this.closeEditMode();
        }
      }
    },
    closeEditMode() {
      this.projectIsEmptyError = false;
      this.channelIsEmptyError = false;
      this.teamIsEmptyError = false;
      this.isChannelsListLoading = false;

      InputValues.clear(this.selectedInputs);
      this.currentConnectionHash = null;
      this.editMode = false;
      this.projectChannelConnections.forEach((el, index) => {
        Vue.set(this.projectChannelConnections[index], 'editMode', false);
      });
    },
    deleteItem(channelProjectId) {
      integrationMSTeamsModel.removeConnection({ id: channelProjectId })
        .then(result => {
          if (result.status === 'ok') {
            this.deleteProjectChannelConnectionById(channelProjectId);
          }
        });
    },
    deleteProjectChannelConnectionById(channelProjectId) {
      const _index = this.projectChannelConnections
        .findIndex(({ id }) => id === channelProjectId);

      if (_index !== -1) {
        this.projectChannelConnections.splice(_index, 1);
        this.onChangesFlag++;
      }
    },
    removeIntegration() {
      webix.confirm({
        text: __('integration_remove_integration_msteams'),
        ok: __('integration_remove_integration_ok'),
        cancel: __('integration_remove_integration_cancel'),
        callback: answer => {
          if (answer) {
            userExtAnalytics.log('integration_disconnect', { isOwner: true, type: 'msteams' });
            userExtAnalytics.log('intergraration_MSTeams_owner_disconnect');

            integrationsModel.removeIntegrationFromTeam('msteams')
              .then(() => this.closeWindow())
              .catch(err => console.error(err));
          }
        },
      });
    },
    disableIntegration() {
      webix.confirm({
        text: __('integration_disconnect_admin_msteams'),
        ok: __('integration_disconnect_admin_ok'),
        cancel: __('integration_disconnect_admin_cancel'),
        callback: answer => {
          if (answer) {
            userExtAnalytics.log('integration_disconnect', { isOwner: false, type: 'msteams' });
            const integration = integrationsModel.getByType('msteams');

            integrationsModel.disconnectIntegrationUser('msteams', +integration.id, +integration.settings.id)
              .then(() => this.closeWindow())
              .catch(err => console.error(err));
          }
        },
      });
    },
    closeWindow() {
      routerHelper.backRoute();
    },
    async fetchChannels(teamId) {
      this.channelsOptions = [];
      this.isChannelsListLoading = true;

      const channelsList = await integrationMSTeamsModel.getChannels(teamId);

      if (this.selectedInputs.teamId === teamId) {
        this.isChannelsListLoading = false;
        this.channelsOptions = channelsList.map(({ displayName: value, id }) => ({ id, value }));
      }
    },
  },
};
</script>
<style module src='./IntegrationMSTeamsSettings.less' ></style>
