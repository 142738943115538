import store from '../../store/main';

const assignedIconSvg = color => `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="${color}" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM9.5 5.5C9.5 6.88071 8.38071 8 7 8C5.61929 8 4.5 6.88071 4.5 5.5C4.5 4.11929 5.61929 3 7 3C8.38071 3 9.5 4.11929 9.5 5.5ZM10.5 11.874C10.4997 10.4827 9.54456 9.25014 8.16345 9.08223C7.75919 9.03308 7.35516 9 7 9C6.64484 9 6.24081 9.03308 5.83655 9.08223C4.45544 9.25014 3.50029 10.4827 3.5 11.874C4.48512 12.5826 5.69382 13 7 13C8.30618 13 9.51488 12.5826 10.5 11.874Z" fill="${color}"/>
  </svg>
  `;

const plusIconSvg = `
<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.55029 1.1001C5.55029 0.685884 5.21451 0.350098 4.80029 0.350098C4.38608 0.350098 4.05029 0.685884 4.05029 1.1001V3.8501H1.30029C0.886079 3.8501 0.550293 4.18588 0.550293 4.6001C0.550293 5.01431 0.886079 5.3501 1.30029 5.3501H4.05029V8.1001C4.05029 8.51431 4.38608 8.8501 4.80029 8.8501C5.21451 8.8501 5.55029 8.51431 5.55029 8.1001V5.3501H8.30029C8.71451 5.3501 9.05029 5.01431 9.05029 4.6001C9.05029 4.18588 8.71451 3.8501 8.30029 3.8501H5.55029V1.1001Z" fill="#1565C0"/>
</svg>
`;

const moreIconSvg = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.40311 6.67556C7.38435 6.07979 8.61565 6.07979 9.59689 6.67556L14.9662 9.93562C15.3609 10.1752 15.3609 10.748 14.9662 10.9877L9.59689 14.2477C8.61565 14.8435 7.38435 14.8435 6.40311 14.2477L1.03379 10.9877C0.639111 10.748 0.639113 10.1752 1.03379 9.93562L6.40311 6.67556Z" fill="#D5D5D5"/>
<path d="M6.40311 4.21399C7.38435 3.61822 8.61565 3.61822 9.59689 4.21399L14.9662 7.47405C15.3609 7.71368 15.3609 8.28645 14.9662 8.52608L9.59689 11.7861C8.61565 12.3819 7.38435 12.3819 6.40311 11.7861L1.03379 8.52608C0.639111 8.28645 0.639113 7.71368 1.03379 7.47405L6.40311 4.21399Z" fill="#8E8E8E"/>
<path d="M6.40311 1.58499C7.38435 0.989214 8.61565 0.989214 9.59689 1.58499L14.9662 4.84504C15.3609 5.08467 15.3609 5.65744 14.9662 5.89708L9.59689 9.15713C8.61565 9.7529 7.38435 9.7529 6.40311 9.15713L1.03379 5.89708C0.639111 5.65744 0.639113 5.08467 1.03379 4.84504L6.40311 1.58499Z" fill="#404040"/>
</svg>
`;

const defaultPhoto = `${GT.cdn}/imgs/default/default-ava.svg`;

export default {
  eventTemplate(taskEvent, staticFieldsRights) {
    if (!taskEvent || !taskEvent.ganttId) return;

    const resources = [];
    const resourcesData = store.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](taskEvent.ganttId, taskEvent.id);

    if (resourcesData.length) {
      for (let i = 0; i < resourcesData.length; i++) {
        if (i > 5) break;

        if (i === 4) {
          const hiddenResourcesCount = `<span class="hidden_resources_count">+${resourcesData.length - 4}</span>`;

          resources.push(hiddenResourcesCount);

          break;
        }

        const resourcePhoto = resourcesData[i]?.photo || defaultPhoto;
        const photo = `<img src="${resourcePhoto}" class="photo"></img>`;

        resources.push(photo);
      }
    } else {
      resources.push(assignedIconSvg(taskEvent.color.darkColor));
    }

    const resizer = `<div class="event_wrapper__resizer ${!staticFieldsRights ? 'hidden' : ''}">`
    + '<span></span>'
    + '<span></span>'
    + '</div>';

    const eventName = taskEvent.text.replace(/</ig, '&lt;').replace(/>/ig, '&gt;');

    const eventTemplate = `
      <div class="event_wrapper">
        <div class="event_wrapper__info">
          <div class="resources">${resources.join('')}</div>
          <div class="title">${eventName}</div>
        </div>
        ${resizer}
      </div>
    `;

    return eventTemplate;
  },
  newEventIcon() {
    return `${plusIconSvg}`;
  },
  moreButton(count) {
    return `
      ${moreIconSvg}
      <div class="show_text">${__('calendar_page_tasks_more', { tasksCount: count || '' })}</div>
      <div class="less_text">${__('calendar_page_show_less')}</div>
    `;
  },
};
