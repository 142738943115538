/* eslint-disable */
import app, { appIds } from '../../app';
import _ from '../../libs/lodash';
import moment from '../../libs/moment';
import filtersModel from '../../models/filters';
import ganttFilterHelper from '../../helpers/gantt_filter';
import dateHelper from '../../helpers/dateFormats';
import constants from '../../helpers/constants';
import customHelper from '../../helpers/custom';
import colorHelper from '../../helpers/color';
import folder_icon from '../../svg/folders.svg';
import iconSettings from '../../svg/header_buttons/ic_project_settings_small.svg';
import routerHelper from '../../helpers/router';
import pricingHelper from '../../helpers/pricingHelper';
import globalStore from '$$store/main';
import projectModel from '../../models/projects';
import rights from '../../components/rights';

const __ = window.__;
let outputObject;
const customColumnsFeatureKey = 'custom_fields';

const {
  events: { ID_EVENT_TASKS_MASSDELETE, ID_EVENT_TASKS_MASSUPDATE },
} = appIds;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

webix.type(webix.ui.kanbanlist, {
  name: 'cards',
  templateBody(obj) {
    const colorValue = parseInt(obj.color, 10) ? colorHelper.getColorStr(obj.color) : obj.color;
    const leftSideColor = colorValue || '#50C0D3';
    const breadcrumbsTemplate = innerObject.helpers.getBreadcrumbsTemplate(obj);
    const settingsBlock = `<span class="${innerObject.constants.BUTTONS_EDIT}">${iconSettings}</span>`;

    return `<div class='webix_kanban_body_side' style='background:${leftSideColor}'></div>${breadcrumbsTemplate
    }<div class='name'>${customHelper.formatTaskName(obj.text)}${settingsBlock}</div>`;
  },
  templateFooter(obj) {
    let cssClass = '';

    if (obj.resource && obj.resource.multi) {
      cssClass = obj.resource.multi.length <= 3 ? 'multi' : 'multi_count';
    }

    let tooltip = '';

    if (obj.data) {
      tooltip = `${"<div class='tooltip-gantt-html'>"
        + `<div class='tooltip-gantt-html-title'>${obj.data.text}</div>`}${
        obj.data.note ? `<div class='tooltip-gantt-html-description ql-snow ql-editor'>${obj.data.note}</div>` : ''
      }<div class='tooltip_gantt_show_more'><a>${__('gantt_tooltip_show_more')}</a></div>`
        + '</div>';
    }

    const dateBlock = `<span class='webix_kanban_footer_date ${cssClass}'>${obj.date}</span>`;
    const commentsBlock = `<span class='${innerObject.constants.BUTTONS_COMMENTS}'>${obj.comments}</span>`;
    const attachmentsBlock = `<span class='${innerObject.constants.BUTTONS_ATTACHMENTS}'>${obj.attachments}</span>`;
    const infoBlock = `<span class='kanban_task_info tooltip-gantt' data-html='true' data-align=${obj.status === 4 ? 'right' : 'center'} data-id='${obj.id}'>${tooltip}</span>`;

    return `${dateBlock}<span class='webix_kanban_footer_icons'>${commentsBlock}${attachmentsBlock}${infoBlock}</span>`;
  },
  templateAvatar(obj) {
    if (obj.resource) {
      const resources = obj.resource.multi;

      if (resources) {
        const count = resources.length;
        let html = `<div class="multi_avatar ${count > 3 ? 'border' : ''}">`;

        _.each(resources, (item, i) => {
          if (i < 3) {
            const resource = globalStore.getters['resourcesModel/getResourceById'](item.resource_id);

            html += innerObject.helpers.getAvatarTemplate(resource);
          }
        });

        if (count > 3) {
          html += `<span class="count">+${count - 3}</span>`;
        }

        html += '</div>';

        return html;
      }

      return innerObject.helpers.getAvatarTemplate(obj.resource);
    }

    return "<div class='avatar' style='background:#e2e2e2;'>&nbsp;</div>";
  },
});

var innerObject = {
  constants: {
    CARD_WIDTH: 255,
    CARD_PADDING: 10,
    DRAG_CLASS: 'webix_kanban_dragging',
    BUTTONS_EDIT: 'webix_kanban_footer_icons_edit',
    BUTTONS_COMMENTS: 'webix_kanban_footer_icons_comments',
    BUTTONS_ATTACHMENTS: 'webix_kanban_footer_icons_attachments',
  },
  views: {
    main: {
      id: 'kanbanWindow',
    },
  },
  init: {
    run(mode) {
      const tasks = innerObject.helpers.getTasks();
      const filterData = filtersModel.getFilterDataByActiveProject(gantt.config.gantt_id);
      const isFeatureAvailable = pricingHelper.checkPricingAccess(customColumnsFeatureKey);

      const optionsTypeColumns = [
        constants.CUSTOM_COLUMNS_TYPES.select.id,
        constants.CUSTOM_COLUMNS_TYPES.color.id,
        constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
        constants.CUSTOM_COLUMNS_TYPES.resources.id,
        constants.CUSTOM_COLUMNS_TYPES.tags.id,
      ];

      const columnIds = globalStore.getters['columns/getColumnIdsByProjectId'](gantt.config.gantt_id);
      const columns = columnIds.reduce((result, columnId) => {
        const column = globalStore.getters['columns/getCustomColumnById'](columnId);
        if (column) {
          result.push(column);
        }

        return result;
      }, []);

      let userCustomColumnsForKanban = [];

      if (isFeatureAvailable) {
        userCustomColumnsForKanban = _.filter(
          columns,
          column => optionsTypeColumns.includes(column.type),
        );
      }

      let optionsForDropdown = [
        { type: 'divider', value: __('kanban_default_columns') },
        { id: 'status', value: __('kanban_view_status') },
        { id: 'priority', value: __('kanban_view_priority') },
        { id: 'resource_id', value: __('kanban_view_resource') },
      ];

      if (userCustomColumnsForKanban.length) {
        innerObject.settings.userCustomColumns = userCustomColumnsForKanban;

        optionsForDropdown.push({ type: 'divider', value: __('kanban_custom_columns') });

        optionsForDropdown = _.concat(optionsForDropdown, _.map(userCustomColumnsForKanban, column => ({
          id: column.id,
          value: column.name,
          type: column.type,
        })));
      }

      if (!_.find(userCustomColumnsForKanban, col => col.id === +mode) && !_.includes(['priority', 'resource_id'], mode)) {
        mode = null;
      }

      globalStore.commit('headerView/setBoardTypes', optionsForDropdown);

      innerObject.handlers.onChangeViewMode(mode || 'status');
      innerObject.handlers.onChangeData(tasks);
      innerObject.handlers.onFilterData(filterData);
    },
    reInit(ganttId, data) {
      innerObject.handlers.onRefreshTask(ganttId, data);
    },
  },
  handlers: {
    /**
     *
     * @param {Number} ganttId - id of the gantt being refreshed
     * @param {Array <Object>} updTasksData - array of tasks to update
     */
    onRefreshTasks(ganttId, updTasksData) {
      if (gantt.config.gantt_id === ganttId && updTasksData.length > 0) {
        innerObject.helpers.getChangedTasks();
      }
    },
    /**
     * Fires on refreshing exact task or whole batch of tasks
     * @param {Number} ganttId - id of the gantt being refreshed
     * @param {Number|Object=} task - object of the task changed in the gantt
     */
    onRefreshTask(ganttId, task) {
      if ($$(innerObject.views.main.id) && ((gantt.config.gantt_id === ganttId) || !task || isFinite(task))) {
        return innerObject.helpers.getChangedTasks();
      }
    },
    /**
     * Fires when kanban columns should be rebuild and update
     */
    onRebuildLayout() {
      if (gantt.config.gantt_id && $$(innerObject.views.main.id)) {
        return innerObject.init.run(innerObject.settings.mode || 'status');
      }
    },
    /**
     * Fires on update whole exciting project
     * @param {String=} projectType - type of the project ("team" or anything else)
     * @param {Number=} projectId - id of the project being updated
     */
    onUpdateProject(projectType, projectId) {
      return innerObject.helpers.getChangedTasks();
    },
    /**
     * Fires after selecting new view mode in header toolbar.
     * Also firing after first displaying of the view.
     * @param {String} mode - one of "resource_id", "priority", "status" values - displaying mode
     * @returns {boolean} false if view mode is not available or valid
     */
    onChangeViewMode(mode) {
      const resourcesForRichSelect = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](gantt.config.gantt_id);

      const resources = [{
        id: constants.COMMON_EMPTY_RESOURCE,
        value: __(constants.COMMON_EMPTY_RESOURCE),
      }].concat(projectModel.getActiveProjectData().is_jira
        ? resourcesForRichSelect.filter(i => !i.userId)
        : resourcesForRichSelect);
      const $$container = $$('kanbanWindowBody');
      let $$view;

      if (innerObject.settings.kanbanId && $$(innerObject.settings.kanbanId)) {
        $$container.removeView(innerObject.settings.kanbanId);
      }

      innerObject.settings.mode = mode;

      if (mode === 'resource_id') {
        $$view = innerObject.helpers.getKanban(resources);
      } else if (mode === 'priority') {
        $$view = innerObject.helpers.getKanban(innerObject.helpers.getDropdownValues('priority'));
      } else if (mode === 'status') {
        $$view = innerObject.helpers.getKanban(innerObject.helpers.getDropdownValues('status'));
      } else if (innerObject.settings.userCustomColumns) {
        let options = _.find(innerObject.settings.userCustomColumns, o => o.id === +mode).options;

        options = options.filter(option => {
          if (option.resource_id) {
            const resource = resources.find(item => item.id === option.resource_id);

            if (resource) {
              option.value = resource.value;

              return true;
            }

            return false;
          }

          return true;
        });

        $$view = innerObject.helpers.getKanban(options);
      } else {
        return false;
      }

      innerObject.settings.kanbanId = $$container.addView($$view);
      globalStore.commit('headerView/setActiveBoardType', mode);
    },
    /**
     * Fires after changing batch of tasks was displayed. Also fires after creating new instance of kanban.
     * @param {Object} data - current state of the Gantt - links and tasks
     * @param {Array} data.data - array of tasks
     */
    onChangeData(data) {
      if (!data || !data.data) {
        return false;
      }

      const dataSortByDate = function (a, b) {
        const dateA = moment(a.date, dateHelper.getUserDateFormat()).valueOf();
        const dateB = moment(b.date, dateHelper.getUserDateFormat()).valueOf();

        if (dateA > dateB) {
          return 1;
        }

        return -1;
      };
      const gantt = window.gantt;
      const $$view = $$(innerObject.settings.kanbanId);
      const onlyTasks = _.filter(data.data, task => !task.type || task.type === gantt.config.types.task || task.type === gantt.config.types.milestone);
      let $data = _.map(onlyTasks, task => innerObject.helpers.prepareTaskData(task));

      $data = _.flatten($data);

      $data.sort(dataSortByDate);

      if ($$view) {
        innerObject.helpers.freezeView($$view);

        $$view.parse($data);

        $$view.refresh();

        $$view.attachEvent('onListAfterDrop', () => {
          $$view.$view.classList.remove(innerObject.constants.DRAG_CLASS);
        });

        $$view.attachEvent('onListBeforeDrag', () => {
          const mode = innerObject.settings.mode;
          const isCustomColumn = !!innerObject.settings.userCustomColumns?.find(o => o.id === innerObject.settings.mode);
          const ganttId = gantt.config.gantt_id;
          const hasEditRightBaseFields = rights.project.hasRightSomeOne(ganttId, ['static_fields', 'static_fields_4_only']);

          if (!rights.project.hasRight(ganttId, 'static_fields') && (mode === 'resource_id' || mode === 'priority')) {
            webix.message({ type: 'warning', text: __('msg_dont_have_right_to_edit_static_fields') });

            return false;
          }

          if (!hasEditRightBaseFields && mode === 'status') {
            webix.message({ type: 'warning', text: __('msg_dont_have_right_to_edit_status') });

            return false;
          }

          if (!rights.project.hasRight(ganttId, 'custom_field_edit') && isCustomColumn) {
            webix.message({ type: 'warning', text: __('msg_dont_have_right_to_edit_custom_column') });

            return false;
          }

          $$view.$view.classList.add(innerObject.constants.DRAG_CLASS);
        });

        $$view.attachEvent('onAfterStatusChange', id => {
          const kanbanTask = $$(innerObject.settings.kanbanId).getItem(id);

          return innerObject.handlers.onDragAndDrop(kanbanTask);
        });

        $$view.attachEvent("onBeforeStatusChange", function (itemId, newStatus) {
          const mode = innerObject.settings.mode;
          const kanbanTask = $$(innerObject.settings.kanbanId).getItem(itemId);
          const selectedUserCustomColumn = _.find(innerObject.settings.userCustomColumns, o => o.id === mode);

          if (mode === 'resource_id') {
            const ganttTask = gantt.getTask(kanbanTask.task_id);

            innerObject.helpers.updateResource(ganttTask, newStatus, kanbanTask.resource);

            let index = this.getIndexById(itemId);

            if (index !== -1) {
              let newkanbanTask = {
                ...kanbanTask,
                status: newStatus,
                resource: +newStatus ? globalStore.getters['resourcesModel/getResourceById'](newStatus) : null
              }
              this.updateItem(itemId, newkanbanTask);
            }
              return false;
            }

          if (selectedUserCustomColumn) {
            const task = gantt.getTask(kanbanTask.task_id);
            const prevValues = gantt.getUserCustomValue(task, selectedUserCustomColumn.id).value.split(',');
            const taskOwnerList = $$(innerObject.settings.kanbanId).getOwnerList(itemId);
            const newValues = prevValues.filter(prevValue => +prevValue !== taskOwnerList.config.status).concat(`${newStatus}`);

            globalStore.dispatch('columns/changeCustomValues',  { task, column: selectedUserCustomColumn, value: _.uniq(newValues).join(',') });
            return false;
          }

          return true;
        });

        $$view.attachEvent('onListItemDblClick', itemId => {
          const kanbanTask = $$(innerObject.settings.kanbanId).getItem(itemId);

          gantt.showLightbox(kanbanTask.task_id);

          userExtAnalytics.log('gantt_task_settings_open', {
            from: routerHelper.analyticsFromRoute(),
            route: `${routerHelper.analyticsRoute()}/DoubleClick Task`,
          });
        });

        $$view.attachEvent('onListItemClick', (itemId, e, target, list) => {
          const kanbanTask = $$(innerObject.settings.kanbanId).getItem(itemId);
          const taskId = kanbanTask.task_id;
          const taskData = gantt.getTask(taskId);
          const classList = e.target.classList;

          if (e.target.closest('.webix_kanban_footer_icons_edit')) {

            _.delay(() => {gantt.showLightbox(taskId)})

            userExtAnalytics.log('gantt_task_settings_open', {
              from: routerHelper.analyticsFromRoute(),
              route: `${routerHelper.analyticsRoute()}/ClickOnCard`,
            });
          } else if (classList.contains(innerObject.constants.BUTTONS_COMMENTS)) {
            _.delay(() => {app.trigger('taskSettings:init', webix.copy(taskData), taskData.gantt_id, { selectComment: true })})

            userExtAnalytics.log('gantt_task_settings_open', {
              from: routerHelper.analyticsFromRoute(),
              route: `${routerHelper.analyticsRoute()}/ClickOnCard`,
            });
          } else if (classList.contains(innerObject.constants.BUTTONS_ATTACHMENTS)) {
            _.delay(() => {  app.trigger('taskSettings:init', webix.copy(taskData), taskData.gantt_id, { selectAttachments: true })})

            userExtAnalytics.log('gantt_task_settings_open', {
              from: routerHelper.analyticsFromRoute(),
              route: `${routerHelper.analyticsRoute()}/ClickOnCard`,
            });
          } else if (classList.contains('breadcrumbs') || e.target.closest('.breadcrumbs')) {
            kanbanTask.breadcrumbsOpened = !kanbanTask.breadcrumbsOpened;
            $$(innerObject.settings.kanbanId).updateItem(itemId, kanbanTask);

            webix.resizeScroll(list.$view);
          }
        });

        innerObject.helpers.unfreezeView($$view);
      }
    },
    /**
     * Fires after applying new filter to the kanban view
     * @param {Object=} filterData - object used in filtering feature with batch of fields to sort and filter
     */
    onFilterData(filterData) {
      const filter = ganttFilterHelper.getFilter(window.gantt, filterData, null);
      const $$view = $$(innerObject.settings.kanbanId);

      $$view && $$view.filter(task => {
        if (!task) {
          return false;
        }

        return filter(task.data);
      });
    },
    /**
     * Fires after dropping card to the new column
     * @param {Number} taskId - id of the task being dragged to the new column
     */
    onDragAndDrop(kanbanTask) {
      const ganttTask = gantt.getTask(kanbanTask.task_id);
      const mode = innerObject.settings.mode;
      const oldValue = ganttTask[mode];

      ganttTask[mode] = kanbanTask.status;
      innerObject.helpers.updateCustomColumnsValue(kanbanTask.task_id, ganttTask, mode, oldValue);

      innerObject.handlers.onUpdateTask(kanbanTask.task_id, ganttTask);
    },
    /**
     * Fires after editing or inserting task to the gantt view
     * @param {Number} taskId - id of the updated task
     * @param {Object} taskData - data of the updated or inserted task in gantt view
     */
    onUpdateTask(taskId, taskData) {
      const $$view = $$(innerObject.settings.kanbanId);
      let isNewTask = false;
      let task = null;

      if ($$view) {
        task = $$view.getItem(taskId);

        if (!task) {
          const tasksInBoard = $$view.serialize().filter(o => o.task_id === taskId);

          if (tasksInBoard.length > 1) { // already exist task with multi assign
            tasksInBoard.forEach(t => {
              this.onUpdateTask(t.id, taskData);
            });

            return;
          }
          task = {};
          isNewTask = true;
        }

        let taskResources = null;
        let taskStatus = taskData[innerObject.settings.mode];
        const selectedUserCustomColumn = _.find(innerObject.settings.userCustomColumns, o => o.id === +innerObject.settings.mode);

        if (selectedUserCustomColumn) {
          taskStatus = gantt.getUserCustomValue(taskData, selectedUserCustomColumn.id).value;
        }

        if (taskData.resources && taskData.resources.length) {
          const resourceData = globalStore.getters['resourcesModel/getResourceById'](taskData.resources[0].resource_id);

          taskResources = taskData.resources.length > 1 ? { multi: taskData.resources } : resourceData;
        }

        task.id = taskId;
        task.task_id = taskData.id;
        task.text = taskData.text;
        task.color = taskData.color;
        task.data = taskData;
        task.date = moment(taskData.start_date).format(dateHelper.getUserDateFormat());
        task.resource = taskResources;
        task.status = taskStatus;
        task.comments = isFinite(taskData.hasComments) ? +taskData.hasComments : 0;
        task.attachments = isFinite(taskData.hasAttachments) ? +taskData.hasAttachments : 0;

        if (innerObject.settings.mode === 'resource_id') {
          task.status = taskData.resources && taskData.resources.length ? taskData.resources[0].resource_id : constants.COMMON_EMPTY_RESOURCE;

          if (taskData.resources && taskData.resources.length > 1) {
            const resourceId = (`${taskId}`).split('_')[1];

            task.status = +resourceId;
            task.resource = globalStore.getters['resourcesModel/getResourceById'](+resourceId);
          }
        }

        if (isNewTask) {
          $$view.add(task);
        } else {
          const index = $$view.getIndexById(taskId);

          if (index !== -1) {
            $$view.updateItem(taskId, task);
          }
          if (innerObject.settings.mode === 'resource_id' && index >= 1 && task.status === 'notAssigned') {
            innerObject.handlers.onDeleteTask(null, task);
          }
        }
      }
    },
    /**
     * Fires after editing or inserting custom column value
     */
    onCustomColumnsUpdate() {
      const $$view = $$(innerObject.settings.kanbanId);
      const tasks = innerObject.helpers.getTasks();
      const filterData = filtersModel.getFilterDataByActiveProject(gantt.config.gantt_id);

      let $ganttData = _.filter(tasks.data, task => !task.type || task.type === gantt.config.types.task || task.type === gantt.config.types.milestone).map(task => innerObject.helpers.prepareTaskData(task));

      $ganttData = _.flatten($ganttData);

      $$view.clearAll();
      $$view.parse($ganttData);
      $$view.refresh();

      innerObject.handlers.onFilterData(filterData);
    },
    /**
     * Fires after receiving or creating new comment
     * @param {Array} comments - array of comments being created recently
     * @param {Number} comments[].taskId - id of the task comment was attached
     */
    onAddComment(comments) {
      const $$view = $$(innerObject.settings.kanbanId);

      $$view && comments.forEach(comment => {
        const kanbanData = $$view.serialize();
        let commentsCount = 0;
        const taskId = comment.task_id;
        const allClones = _.filter(kanbanData, task => task.task_id === +taskId);

        innerObject.helpers.freezeView($$view);

        _.each(allClones, task => {
          commentsCount = task.comments;
          $$view.updateItem(task.id, task);
        });

        innerObject.helpers.unfreezeView($$view);
        innerObject.helpers.updateGanttTask(taskId, 'hasComments', commentsCount);
      });
    },
    /**
     * Fires when new file was uploaded to the team or was uploaded by the user
     * @param {Number} ganttId - id of the gantt task was uploaded to
     * @param {Number} taskId - id of the task new attachment was uploaded to
     * @param {Number=} countAdded - count of new attachments
     * @param {Number} countTotal - count of total attachments
     */
    onAddAttachment(ganttId, taskId, countAdded, countTotal) {
      const $$view = $$(innerObject.settings.kanbanId);

      if ($$view) {
        const kanbanData = $$view.serialize();
        let attachmentsCount = 0;
        const allClones = _.filter(kanbanData, task => task.task_id === +taskId);

        innerObject.helpers.freezeView($$view);

        _.each(allClones, task => {
          task.attachments += countAdded; // count || 1;
          attachmentsCount = task.attachments;
          $$view.updateItem(task.id, task);
        });

        innerObject.helpers.unfreezeView($$view);
        innerObject.helpers.updateGanttTask(taskId, 'hasAttachments', attachmentsCount);
      }
    },
    /**
     * Fires when task is inserted or updated to the Gantt
     * @param {Number} ganttId - id of the gantt task changed in
     * @param {Object} task - the inserted or updated task
     */
    onInsertTask(ganttId, task) {
      if (!task) {
        return;
      }

      if (task.type === gantt.config.types.task || task.type === gantt.config.types.milestone) {
        const $$view = $$(innerObject.settings.kanbanId);

        if ($$view) {
          innerObject.helpers.freezeView($$view);
          innerObject.handlers.onUpdateTask(task.id, task);
          innerObject.helpers.unfreezeView($$view);
        }
      } else {
        innerObject.handlers.onDeleteTask(ganttId, task);
      }
    },
    /**
     * Fires when task is deleted from Gantt
     * @param {Number} ganttId - id of the gantt task removed from
     * @param {Object} taskData - copy of the removed task
     */
    onDeleteTask(ganttId, taskData) {
      const $$view = $$(innerObject.settings.kanbanId);
      const gantt = window.gantt;
      let task;

      if ($$view) {
        task = $$view.getItem(taskData.id);

        if (task) {
          innerObject.helpers.freezeView($$view);
          $$view.remove(taskData.id);
          innerObject.helpers.unfreezeView($$view);
        } else if (!gantt.getParent(taskData.id) || taskData.type !== gantt.config.types.task || taskData.type !== gantt.config.types.milestone) {
          innerObject.helpers.getChangedTasks();
        }
      }
    },
    /**
     * Fires when the file was removed from the task
     * @param {Number} gantt_id - id of the gantt window attachment was deleted from
     * @param {Number} task_id - id of the task attachment was deleted from
     * @param {Number} countRemoved
     * @param {Number} countTotal
     * @param {Object} attach - data of removed attachment
     *
     */
    onRemoveAttachment(gantt_id, task_id, countRemoved, countTotal, attach) {
      const $$view = $$(innerObject.settings.kanbanId);

      if ($$view) {
        const taskId = attach.task_id;
        const kanbanData = $$view.serialize();
        let attachmentsCount = 0;
        const allClones = _.filter(kanbanData, task => task.task_id === +taskId);

        innerObject.helpers.freezeView($$view);

        _.each(allClones, task => {
          task.attachments -= countRemoved;
          attachmentsCount = task.attachments;
          $$view.updateItem(task.id, task);
        });

        innerObject.helpers.unfreezeView($$view);
        innerObject.helpers.updateGanttTask(taskId, 'hasAttachments', attachmentsCount);
      }
    },
    /**
     * Fires on redo moving card to different column, for example, when move is prohibited by JIRA workflow
     * @param {Number} taskId - id of the task in the gantt and kanban
     * @param {Number} value - old value used to restore
     */
    onRedoTask(taskId, value) {
      const $$view = $$(innerObject.settings.kanbanId);
      let task;

      if ($$view) {
        task = $$view.getItem(taskId);

        innerObject.helpers.freezeView($$view);
        task.status = value;
        $$view.updateItem(taskId, task);
        innerObject.helpers.unfreezeView($$view);
      }
    },
    /**
     * Fires when avatar of active user is updated and it necessary to reload cards with new avatar
     * @param {String} avatar - url of the new avatar of the current active user
     */
    onUpdateAvatar(avatar) {
      innerObject.helpers.getChangedTasks();
    },
  },
  helpers: {
    /**
     * Freezes view, hides it and blocks all events
     * @param {WebixView} $$view - view to freeze
     */
    freezeView($$view) {
      $$view.$view.style.display = 'none';
      $$view.blockEvent();
      webix.ui.$freeze = true;
    },
    /**
     * Unfreezes view, hides it and blocks all events
     * @param {WebixView} $$view - to unfreeze
     */
    unfreezeView($$view) {
      $$view.unblockEvent();
      $$view.$view.style.display = 'block';
      webix.ui.$freeze = false;
    },
    /**
     * Get UI object of Kanban for the list of predefined columns
     * @param {Array} columns - array of columns need to be displayed
     * @returns {Object} webix UI composition
     */
    getKanban(columns) {
      const colCount = columns.length;
      const cardWidth = innerObject.constants.CARD_WIDTH;
      const padding = innerObject.constants.CARD_PADDING;
      const kanbanSize = colCount * (cardWidth + padding) + padding;
      const selectedUserCustomColumn = _.find(innerObject.settings.userCustomColumns, o => o.id === +innerObject.settings.mode);

      return {
        view: 'kanban',
        type: 'space',
        css: 'kanbanBody',
        cols: _.map(columns, column => {
          let header = column.value;
          const headerTitle = _.escape(header).replace(/\s/g, '&nbsp;');

          if (selectedUserCustomColumn && selectedUserCustomColumn.type === constants.CUSTOM_COLUMNS_TYPES.color.id) {
            header = `<span class="kanban_color_header"">
              <span class="kanban_item_header_color" style="background: ${column.value}"></span>
            </span>`;
            if (column.value === '#FFFFFF') {
              header = '<span class="kanban_color_header none"></span>';
            }
          } else if (selectedUserCustomColumn && selectedUserCustomColumn.type === constants.CUSTOM_COLUMNS_TYPES.tags.id) {
            header = `<span class="kanban_color_header">
              <span class="kanban_item_header_color" style="background: ${column.color}"></span>
              <span class="kanban_tag_header_text">${column.value}</span>
            </span>`;
          } else {
            header = `<div title="${headerTitle}">${column.value}</div>`;
          }

          return {
            header,
            minWidth: cardWidth,
            body: {
              view: 'kanbanlist',
              type: 'cards',
              status: column.id,
            },
          };
        }),
        on: {
          onListBeforeDragIn(card, mouseEvent, list) {
            _.delay(() => {
              const listRect = list.$view?.getBoundingClientRectWrapper();
              const kanbanScroll = $$('kanbanScroll');
              const kanbanScrollWidth = kanbanScroll.$view.clientWidth;

              if(listRect) {
                if ((kanbanScrollWidth < listRect.x)) {
                  const kanbanScrollState = kanbanScroll.getScrollState();

                  kanbanScroll.scrollTo(kanbanScrollState.x + listRect.width, kanbanScrollState.y);
                } else if (listRect.width > (listRect.x + 5)) {
                  const kanbanScrollState = kanbanScroll.getScrollState();

                  kanbanScroll.scrollTo(kanbanScrollState.x - (listRect.width - listRect.x) - 10, kanbanScrollState.y);
                }
              }

            }, 250);
            // webix.message("Drag has been started");
          },
        },
      };
    },
    getTasks() {
      // const tasks = gantt.serialize();
      const tasks = globalStore.getters['tasksModel/getTasksForGantt'](gantt.config.gantt_id);

      if (!rights.project.hasRight(gantt.config.gantt_id, 'all_tasks')) {
        const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
        const projectData = globalStore.getters['tasksModel/getItem'](gantt.config.gantt_id);

        tasks.data = tasks.data.filter(task => projectData.resourcesToTasks[task.id]?.find(o => o.resource_id === resourceData.id));
      }

      return tasks;
    },
    getDropdownValues: function (columnName) {
      const columnOptions = globalStore.getters['columns/getColumnByName'](columnName)?.options;
      return _.map(columnOptions, function (opt) {
        return {
          id: opt.id,
          value: __(opt.locale) || opt.name || opt.value,
          picture: opt.icon,
          color: opt.color || "#55BED4"
        };
      });
    },
    /**
     * Update current resource of the current task
     * @param {Number} taskId - id of the updated task
     * @param {Object} taskData - object with new resource and other task fields
     * @param {Number} taskData.resource_id - id of the resource was attached to the task. If it's equal to COMMON_EMPTY, it
     * means to delete resource from the task
     * @param {Number} oldResourceId - previous resource was attached to the task
     */
    updateResource: async (taskData, newResourceId, oldResource) => {
      const newResource = globalStore.getters['resourcesModel/getResourceById'](newResourceId);
      const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](newResourceId, taskData.gantt_id);
      const isTimeTypeResource = resourceTypeOnProject === constants.RESOURCE_TIME_TYPE;
      const isAssigned = _.filter(taskData.resources, item => {
        item.resource_id === newResourceId;
      }).length;
      const taskDuration = taskData.duration / 60;
      let changedResources = [];

      if (oldResource) {
        changedResources.push({
          userId: oldResource.userId,
          id: oldResource.id,
          value: 0,
          action: 'delete',
        });
      }

      if (newResourceId !== constants.COMMON_EMPTY_RESOURCE && !isAssigned) {
        let value = taskData.estimation || taskDuration;

        if (oldResource) {
          let oldResourceData = taskData.resources.find(res => res.resource_id === oldResource.id)
          value = oldResourceData ? oldResourceData.value : 0;
        }

        changedResources.push({
          userId: newResource.userId,
          id: newResourceId,
          value: isTimeTypeResource ? value : 0,
          action: 'insert',
        });
      }

      if (newResourceId === constants.COMMON_EMPTY_RESOURCE && taskData.resources.length > 1) {
        changedResources = [];
        webix.confirm({
          ok: __('common_yes'),
          cancel: __('common_no'),
          text: __('resources_kanban_unassign_all_message'),
          type: 'confirm-error',
          width: 420,
        }).then(result => {
          if (result) {
            _.each(taskData.resources, resource => {
              const resourceData = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);

              changedResources.push({
                userId: resourceData.user_id,
                id: resourceData.id,
                value: 0,
                action: 'delete',
              });
            });

            globalStore.dispatch('tasksModel/changeAssign', {
              changedResources,
              taskId: taskData.id,
              ganttId: taskData.gantt_id,
            });
          }
        });

        return;
      }

      await globalStore.dispatch('tasksModel/changeAssign', {
        changedResources,
        taskId: taskData.id,
        ganttId: taskData.gantt_id,
      });

      innerObject.handlers.onRebuildLayout();
    },
    /**
     * Update custom columns such as status and priority of the current task
     * @param {Number} taskId - id of the updated task
     * @param {Object} taskData - changed values and columns of the task
     * @param {String} columnName - name of the custom column, one of "status" or "priority"
     * @param {Number=} oldValue - previous value of the task, used to redo action
     */
    updateCustomColumnsValue(taskId, taskData, columnName, oldValue) {
      if (columnName === 'status') {
        gantt.callEvent('changeStatus', [taskData, true]);
      } else if (columnName === 'priority') {
        globalStore.dispatch('tasksModel/changePriority', {
          priorityValue: taskData[columnName],
          taskData,
        });
      }
    },
    /**
     * Get tasks added or updated in Gantt view
     * @returns {Array} List of changed tasks or new tasks
     */
    getChangedTasks: _.debounce(() => {
      const $$view = $$(innerObject.settings.kanbanId);

      if (!$$view) return;

      const gantt = window.gantt;
      const tasks = innerObject.helpers.getTasks();
      let $ganttData = _.filter(tasks.data, task => !task.type || task.type === gantt.config.types.task || task.type === gantt.config.types.milestone).map(task => innerObject.helpers.prepareTaskData(task));

      $ganttData = _.flatten($ganttData);

      innerObject.helpers.freezeView($$view);

      const ganttTaskIds = $ganttData.map(i => i.id);

      $$view.serialize().forEach(t => {
        !ganttTaskIds.includes(t.id) && $$view.remove(t.id);
      });

      _.filter($ganttData, task => {
        const kanbanTask = $$view.getItem(task.id) || $$view.getItem(task.task_id);
        let multiResourceDiff = false;

        if (!kanbanTask) {
          return true;
        }

        if (kanbanTask.resource && task.resource && kanbanTask.resource.multi && task.resource.multi) {
          if (kanbanTask.resource.multi.length !== task.resource.multi.length) {
            multiResourceDiff = true;
          } else {
            multiResourceDiff = _.differenceBy(kanbanTask.resource.multi, task.resource.multi, 'resource_id').length;
          }
        }

        const flag = (
          kanbanTask.text !== task.text
            || kanbanTask.color !== task.color
            || kanbanTask.status !== task.status
            || kanbanTask.date !== task.date
            || kanbanTask.comments !== task.comments
            || kanbanTask.attachments !== task.attachments
            || (kanbanTask.resource && !task.resource)
            || (!kanbanTask.resource && task.resource)
            || ((kanbanTask.resource && task.resource)
              && kanbanTask.resource.id !== task.resource.id)
            || ((kanbanTask.resource && task.resource)
              && kanbanTask.resource.name !== task.resource.name)
            || ((kanbanTask.resource && task.resource)
              && kanbanTask.resource.username !== task.resource.username)
            || ((kanbanTask.resource && task.resource)
              && kanbanTask.resource.photo !== task.resource.photo)
            || multiResourceDiff
        );

        return flag;
      }).map(task => innerObject.handlers.onUpdateTask(task.id, task.data));

      innerObject.helpers.unfreezeView($$view);
    }, 250),
    /**
     * Refresh field in gantt when updating kanban card
     * @param {Number} taskId - id of the task being update
     * @param {String} field - name of the field to be updated
     * @param {*} value - new value to be set
     */
    updateGanttTask(taskId, field, value) {
      const gantt = window.gantt;
      const task = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

      if (!task) {
        return;
      }

      task[field] = value;
      gantt.refreshTask(taskId);
    },
    prepareTaskData(task) {
      const cloneTasks = [];
      let taskResources = null;
      let preparedTask;
      let taskStatus = task[innerObject.settings.mode];
      const selectedUserCustomColumn = _.find(innerObject.settings.userCustomColumns, o => o.id === +innerObject.settings.mode);
      // let colorValue = task.color && parseInt(task.color, 10) ? colorHelper.getColorStr(task.color) : task.color;

      if (task.resources && task.resources.length) {
        taskResources = task.resources.length > 1
          ? { multi: task.resources }
          : globalStore.getters['resourcesModel/getResourceById'](task.resources[0].resource_id);
      }

      preparedTask = {
        id: task.id,
        task_id: task.id,
        text: task.text,
        color: task.color,
        date: moment(task.start_date).format(dateHelper.getUserDateFormat()),
        data: task,
        comments: isFinite(task.hasComments) ? +task.hasComments : 0,
        attachments: isFinite(task.hasAttachments) ? +task.hasAttachments : 0,
        resource: taskResources,
        status: taskStatus,
      };

      if (selectedUserCustomColumn) {
        taskStatus = gantt.getUserCustomValue(task, selectedUserCustomColumn.id).value;
        const values = taskStatus ? taskStatus.split(',') : null;

        if (values && values.length) {
          _.each(values, value => {
            const cloneTask = _.cloneDeep(preparedTask);

            if (values.length > 1) {
              cloneTask.id += `_${value}`;
            }

            cloneTask.status = parseInt(value, 10);
            cloneTasks.push(cloneTask);
          });

          return cloneTasks;
        }
      }

      if (innerObject.settings.mode === 'resource_id') {
        if (task.resources && task.resources.length) {
          _.each(task.resources, resource => {
            const cloneTask = _.cloneDeep(preparedTask);

            if (task.resources.length > 1) {
              cloneTask.id += `_${resource.resource_id}`;
            }

            cloneTask.resource = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);
            cloneTask.status = resource.resource_id;
            cloneTasks.push(cloneTask);
          });

          return cloneTasks;
        }

        preparedTask.status = constants.COMMON_EMPTY_RESOURCE;
      }

      return preparedTask;
    },
    getAvatarTemplate(resource) {
      if (!resource) {
        return '';
      }

      const src = resource.photo || resource.resourcePhoto;
      const tooltip = resource.username || resource.name || __('common_empty');

      if (src) {
        return `<img class='avatar' src='${src}' title='${tooltip}'/>`;
      }

      return `<div class='avatar' style='background:#e2e2e2;' title='${tooltip}'>&nbsp;</div>`;
    },
    getDataForBreadcrumbs(taskId) {
      let parent = gantt.getParent(taskId);
      const result = [];

      if (!parent) {
        return result;
      }

      let level = gantt.getTask(parent)?.$level;

      while (level >= 1) {
        result.push(gantt.getTask(parent));
        parent = gantt.getParent(parent);
        level = gantt.getTask(parent).$level;
      }

      return result.reverse();
    },
    getBreadcrumbsTemplate(obj) {
      let breadcrumbs = '';
      let isOpen = obj.breadcrumbsOpened;
      const parents = innerObject.helpers.getDataForBreadcrumbs(obj.data.id);
      const count = parents.length;
      const tooltipHtml = __('tooltip_for_kanban_breadcrumbs', { count });

      if (!count) {
        return '';
      }

      isOpen = count === 1 ? true : isOpen;

      breadcrumbs += `<div class="breadcrumbs${isOpen ? ' open' : ''}${count === 1 ? ' empty' : ''}">`
        + `<span class="folders_icon${isOpen ? '' : ' tooltip-gantt'}" data-key="${tooltipHtml}">${folder_icon}</span>`;

      if (!isOpen) {
        breadcrumbs += `<span class="crumb">${customHelper.formatTaskName(parents[0].text)}</span>`;

        if (count > 2) {
          breadcrumbs += '<span class="dots">...</span>';
        }
        if (count > 1) {
          breadcrumbs += `<span class="crumb last">${customHelper.formatTaskName(parents[count - 1].text)}</span>`;
        }
      } else {
        _.each(parents, (parent, i) => {
          breadcrumbs += `<span class="crumb ${i === 0 ? 'first' : ''}">${customHelper.formatTaskName(parent.text)}</span>`;
        });
      }

      breadcrumbs += '</div>';

      return breadcrumbs;
    },
  },
  settings: {
    ganttId: null,
    kanbanId: null,
    mode: 'status',
    userCustomColumns: null,
  },
};

outputObject = {
  handlers: {
    /**
     * Show Kanban window if it is not visible already.
     * Also update visible buttons in the header toolbar.
     */
    show() {
      const $$main = $$(innerObject.views.main.id);
      const $$container = $$('kanbanScroll');

      if (!$$main.isVisible()) {
        $$main.show();
        $$container.resize();

        innerObject.init.run(innerObject.settings.mode || 'status');
      }
    },
    /**
     * Hide Kanban window and refresh visible buttons in the header toolbar.
     */
    hide() {
      if (!$$(innerObject.views.main.id)) {
        return;
      }
      $$(innerObject.views.main.id).hide();
    },
    /**
     * Apply new filter or clear filter to current Kanban view
     * @param {Object=} filter - object of applied filter or empty value if filter is not defined
     */
    filter(filter) {
      if (!$$(innerObject.views.main.id)) {
        return;
      }
      innerObject.handlers.onFilterData({
        value: filter && filter.text,
        options: filter,
      });
    },
    /**
     * Call resize and reflow of the main Kanban window.
     */
    resize() {
      $$(innerObject.views.main.id) && $$(innerObject.views.main.id).resize();
    },
  },
  webixId: innerObject.views.main.id,
};

app.on('changeUserDateTimeFormat', innerObject.handlers.onRefreshTask);
app.on('body:resize', outputObject.handlers.resize);
app.on('leftSideBar:changedMode', outputObject.handlers.resize);
app.on('gantt:area:click', outputObject.handlers.hide);
app.on('tab:projectClick', outputObject.handlers.hide);
// app.on("activeProject:afterSet", outputObject.handlers.hide);
app.on('filter:set', outputObject.handlers.filter);
app.on('filter:clear', outputObject.handlers.filter);
app.on('userAvatar:update', innerObject.handlers.onUpdateAvatar);
app.on('kanban:change:mode', innerObject.init.run);
app.on('userCustomValuesModel:change', () => {
  const $$view = $$(innerObject.settings.kanbanId);

  if ($$view) {
    innerObject.handlers.onCustomColumnsUpdate();
  }
});

app.on('userCustomColumnsModel:change', (ganttId, mode, deletedColumnId) => {
  if (mode === 'collaboration') {
    innerObject.handlers.onRebuildLayout();
  }
});

app.on('onAfterCollaboration', ({event, tasks, projects}) => {
  //if (['TaskCustomFieldUpdated', 'TaskCreated'].includes(event)) {
    innerObject.handlers.onRebuildLayout();
  //}
});

app.on('userProfile:update', () => {
  innerObject.handlers.onRebuildLayout();
})

app.on(ID_EVENT_TASKS_MASSUPDATE, innerObject.handlers.onRefreshTasks);
app.on('tasks:model:delete', (taskID, taskData) => {
  innerObject.handlers.onDeleteTask(taskData.gantt_id, taskData);
});
app.on('afterGanttWorkerFinish', data => {
  if (!data.tasks.length || data.triggerEvent?.eventType !== 'updateTasks') return;
  innerObject.handlers.onRefreshTasks(data.tasks[0].gantt_id, data.tasks);
});

app.on('tasks:model:updateTask', (taskId, taskData) => {
  if ($$(innerObject.views.main.id)) {
    innerObject.handlers.onUpdateTask(taskId, taskData);
  }
});

gantt.attachEvent('newTaskCommentNotification', innerObject.handlers.onAddComment, null, [], 500);
gantt.attachEvent('addTaskAttachmentNotification', innerObject.handlers.onAddAttachment, null, [], 500);
gantt.attachEvent('removeTaskAttachmentNotification', innerObject.handlers.onRemoveAttachment, null, [], 500);
gantt.attachEvent('onAfterTaskDelete', innerObject.handlers.onDeleteTask, null, [], 500);
gantt.attachEvent('onAfterTaskUpdate', innerObject.handlers.onInsertTask, null, [], 500);
gantt.attachEvent('onAfterTaskAdd', innerObject.handlers.onInsertTask, null, [], 500);
gantt.attachEvent('onAfterTaskMove', innerObject.handlers.onRefreshTask, null, [], 500);
gantt.attachEvent('onAfterBatchUpdate', innerObject.handlers.onRefreshTask, null, [], 500);
gantt.attachEvent('onAfterLightbox', innerObject.handlers.onInsertTask, null, [], 500);
gantt.attachEvent('onUpdateToChild', innerObject.handlers.onRefreshTask, null, [], 500);
gantt.attachEvent('onUpdateToParent', innerObject.handlers.onRefreshTask, null, [], 500);

export default outputObject;
