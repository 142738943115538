import app from "./../../app";

const __ = window.__;

import closer from "./../../svg/closer.svg";

var innerObject = {

  mainView: {
    id: 'studentPopupThank'
  },

  getStudentPopup: function () {
    return  "<div class='student-popup-content'>" +
      " <div class='student-popup'>" +
      "     <div class='popup-closer'>" + closer + "</div>" +
      "     <div class='popup-container'>" +
      "       <div class='popup-container-title'>" + __('student-popup-title') + "</div>" +
      "       <div class='popup-container-text'>" + __('student-popup-text') + "</div>" +
      "       <div class='popup-container-desc'>" +
      "         <a class='popup-container-desc-help'>" + __('student_popup_form_btn_desc_text') + "</a>" +
      "       </div>" +
      "     </div>" +
      " </div>" +
      "</div>";
    }

};

webix.ui({
  view: 'ganttproWindowPopup',
  id: 'studentPopupThank',
  css: 'student-popup-layout',
  body: {
    view: 'template',
    id: 'studentPopupThankBody',
    css: 'student-popup-wrapper',
    maxWidth: 635,
    maxHeight: 260,
    template: function () {
      return innerObject.getStudentPopup();
    },
    onClick: {
      'popup-closer': function () {
        $$('studentPopupThank').hide();
      },
    },
  },
  on: {
    'onHide': function () {
      app.trigger("popup:hide");
    }
  }
}).hide();

var outputObject = {
  init: function () {
    $$('studentPopupThank').show();
  }
};

export default outputObject;