<template functional>
  <div
    :key="data.key"
    :date-name="props.column.property"
    :class="['table-cell', data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
  >
    <div
      :key="`${props.rowData.id}-${props.column.property}`"
      class="table-cell--inner-wrap"
      :class="`gp_autotest_listview_item_cell_${props.column.property}`"
    >
      <div
        v-if="!$options.methods.isCellExist(props.rowData, props.column)"
        class="all-cell"
        @click="$options.methods.notExistCellWarning"
      />
      <template v-else>
        <component
          v-if="props.column.editor === 'checkbox-selectmode'"
          :is="injections.components.checkboxCell"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.isSelectedRow"
          :theme="'blue'"
          @change="listeners['select:row']"
        />

        <div
          v-if="props.column.editor === 'icon'"
          :class="['all-cell', 'flex-center']"
        >
          <component
            :is="injections.components.vueIcon"
            :class="{
              'show-only-row-hover': !props.rowData[props.column.property] && !props.selectMode,
              'blue-icon': props.rowData[props.column.property] ? !!props.rowData[props.column.property].unread : false,
            }"
            :name="props.column.iconName"
            :style="{opacity: props.discolor, display: props.rowData[props.column.property] ? 'flex' : 'none'}"
            @click.stop="() => listeners['show:itemSettings'](props.column.property)"
          />
        </div>

        <component
          v-if="props.column.editor === 'task'"
          :is="injections.components.taskCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :crossout="props.crossout"
          :overdue="props.overdue"
          :can-show-settings="props.canShowSettings && !props.selectMode"
          :discolor="props.discolor"
          @show:settings="listeners['show:itemSettings']"
          @show:task-name-editor="listeners['show:task-name-editor']"
          @change="listeners['change']"
          @delete="listeners['delete']"
        />

        <component
          v-if="props.column.editor === 'project'"
          :is="injections.components.projectCell"
          :item="props.rowData"
          :property="props.column.property"
          :property-extra="props.column.propertyExtra"
          :extra="$options.methods.isShowExtra(props.extra, props.column.extra.name)"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :crossout="props.crossout"
          :style="{opacity: props.discolor}"
        />

        <component
          v-if="props.column.editor === 'selectList'"
          :is="injections.components.defaultSelectListCell"
          :item="props.rowData"
          :column="props.column"
          :property="props.column.property"
          :list-type="props.column.listType"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :discolor="props.discolor"
          :crossout="props.crossout"
          @change="listeners['change']"
        />

        <component
          v-if="props.column.editor === 'datepicker'"
          :is="injections.components.calendarCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :extra="$options.methods.isShowExtra(props.extra, props.column.extra && props.column.extra.name)"
          :disabled="props.column.disabled || (props.column.property === 'end_date' && props.rowData.type === 'milestone')"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :crossout="props.crossout"
          :overdue="props.overdue"
          :discolor="props.discolor"
          @change="listeners['change']"
        />

        <component
          v-if="props.column.editor === 'timelog'"
          :is="injections.components.timeLogCell"
          :item="props.rowData"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :property="props.column.property"
          :discolor="props.discolor"
          :crossout="props.crossout"
        />

        <component
          v-if="props.column.editor === 'resources'"
          :is="injections.components.resourceCell"
          :item="props.rowData"
          :property="props.column.property"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :discolor="props.discolor"
          :crossout="props.crossout"
        />

        <component
          v-if="!props.column.editor"
          :is="injections.components.simpleCell"
          :item="props.rowData"
          :property="props.column.property"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :align="props.column.align"
          :style="{opacity: props.discolor}"
          :crossout="props.crossout"
        />

        <component
          v-if="props.column.editor === 'text'"
          :is="injections.components.textCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :type="props.column.type"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :style="{opacity: props.discolor}"
          :crossout="props.crossout"
          @change="listeners['change']"
        />

        <component
          v-if="props.column.editor === 'number'"
          :is="injections.components.numberCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :type="props.column.type"
          :disabled="props.column.property !=='progress' && (props.column.disabled)"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :discolor="props.discolor"
          :crossout="props.crossout"
          @change="listeners['change']"
        />

        <!-- custom columns -->
        <component
          v-if="props.column.editor === 'datepicker:custom'"
          :is="injections.components.calendarCell"
          :item="props.rowData"
          :for-custom="true"
          :property="props.column.property"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :value="props.rowData[props.column.property]"
          :discolor="props.discolor"
          :crossout="props.crossout"
          @change="listeners['change:custom']"
        />

        <component
          v-if="props.column.editor === 'text:custom'"
          :is="injections.components.textCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :type="props.column.type"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :style="{opacity: props.discolor}"
          :crossout="props.crossout"
          @change="listeners['change:custom']"
        />

        <component
          v-if="props.column.editor === 'number:custom'"
          :is="injections.components.numberCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :type="props.column.type"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :discolor="props.discolor"
          :crossout="props.crossout"
          @change="listeners['change:custom']"
        />

        <component
          v-if="props.column.editor === 'checkbox'"
          :is="injections.components.checkboxCell"
          :item="props.rowData"
          :property="props.column.property"
          :value="props.rowData[props.column.property]"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :style="{opacity: props.discolor}"
          :crossout="props.crossout"
          @change="listeners['change:custom']"
        />

        <component
          v-if="props.column.editor === 'select'"
          :is="injections.components.selectCell"
          :item="props.rowData"
          :property="props.column.property"
          :options="props.options"
          :template="props.column.template"
          :value="props.rowData[props.column.property]"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :crossout="props.crossout"
          :discolor="props.discolor"
          @change="listeners['change:custom']"
        />

        <component
          v-if="props.column.editor === 'multiselect'"
          :is="injections.components.multySelectCell"
          :item="props.rowData"
          :property="props.column.property"
          :options="props.options"
          :template="props.column.template"
          :value="props.rowData[props.column.property]"
          :disabled="props.column.disabled"
          :editable="$options.methods.isEditableCell(props.rowData.gantt_id, props.column)"
          :crossout="props.crossout"
          :discolor="props.discolor"
          @change="listeners['change:custom']"
        />
      </template>
    </div>
  </div>
</template>

<script>
import taskCell from './taskCell.vue';
import projectCell from './projectCell.vue';
import calendarCell from './calendarCell.vue';
import defaultSelectListCell from './selectCell/defaultSelectListCell.vue';
import textCell from './textCell.vue';
import numberCell from './numberCell.vue';
import selectCell from './selectCell/selectCell.vue';
import multySelectCell from './selectCell/multySelectCell.vue';
import checkboxCell from './checkboxCell.vue';
import timeLogCell from './timeLogCell.vue';
import resourceCell from './resourceCell.vue';
import simpleCell from './simpleCell.vue';
import vueIcon from '../icons/icon.vue';
import rightsHelper from '../../rightsHelper';

export default {
  name: 'Cell',
  inject: {
    components: {
      default: {
        taskCell,
        projectCell,
        defaultSelectListCell,
        calendarCell,
        textCell,
        numberCell,
        selectCell,
        multySelectCell,
        checkboxCell,
        timeLogCell,
        resourceCell,
        simpleCell,
        vueIcon,
      },
    },
  },
  props: {
    extra: { type: Array, required: true },
    rowData: { type: Object, required: true },
    column: { type: Object, required: true },
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
    overdue: { type: Boolean, required: false, default: false },
    canShowSettings: { type: Boolean, required: false, default: false },
    isSelectedRow: { type: Boolean, required: false, default: false },
    selectMode: { type: Boolean, required: false, default: false },
  },
  methods: {
    isCellExist(rowData, column) {
      if (!column.custom) return true;

      const isColumnInProject = column.ganttIds.find(id => id === rowData.gantt_id);

      return column.custom && isColumnInProject;
    },
    isShowExtra(extra, extra_name) {
      return extra_name && extra.includes(extra_name);
    },
    isEditableCell(ganttId, column) {
      return rightsHelper.isEditableCell(ganttId, column);
    },
    notExistCellWarning() {
      webix.message({ type: 'warning', text: __('msg_cant_edit_column') });
    },
    cellClick(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="less">
.all-cell{
  height: 100%;
  width: 100%;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-cell--inner-wrap{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
.blue-icon{
  color: #1565C0;
}
.discolor{
  opacity: 0.5;
}
</style>
