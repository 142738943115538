import globalStore from '$$store/main';
import customColumns from '../../gantt/modules/userCustomColumns';
import icon_resource from '../../../svg/default/default-ava.svg';
import listViewConstants from '../constants';

function getPrepareCustomColumnOptions(options, typeId) {
  if (!options && !options.length) return null;

  if (typeId === 8) {
    options = customColumns.filterPeopleOptions(options);
    options.sort((a, b) => {
      if (!a.userId) return 1;

      return -1;
    });
  }

  return options.map(item => {
    const opt = {
      id: item.id,
      color: item.color ? item.color : null,
      icon: item.picture || icon_resource,
      value: item.value,
    };

    if (typeId === 8) {
      opt.group = item.userId ? __('resources_layout_header_title_1') : __('resources_layout_header_title_2');
    }

    if (typeId === 6) {
      opt.color = item.value;
    }

    return opt;
  });
}

export function getPreparedCustomColumnById(columnId) {
  const column = globalStore.getters['columns/getCustomColumnById'](columnId);

  if (!column) return null;

  const ganttIds = globalStore.getters['columns/getProjectsByColumnId'](columnId);

  const preparedColumn = {
    typeId: column.type,
    id: columnId,
    property: column.id,
    text: column.name,
    group: __('settings_global_columns_title'),
    isOptions: !!column.options.length,
    data: getPrepareCustomColumnOptions(column.options, column.type),
    custom: true,
    ganttIds,
  };

  return { ...preparedColumn, ...listViewConstants.CUSTOM_COLUMN.CUSTOM_COLUMN_TEMPLATE[column.type] };
}
