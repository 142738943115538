var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "confirm-popup",
      style: {
        maxWidth: _vm.config.width ? _vm.config.width + "px" : 420 + "px",
      },
    },
    [
      _c("div", { staticClass: "body" }, [
        _c("div", {
          staticClass: "popup-text confirm-message",
          domProps: { innerHTML: _vm._s(_vm.config.message) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["footer", _vm.config.footerText ? "no-border" : ""] },
        [
          _c(
            "div",
            {
              class: [
                "footer__btns",
                (_vm.config.ok && !_vm.config.cancel) ||
                (!_vm.config.ok && _vm.config.cancel)
                  ? "alone-btn"
                  : "",
              ],
            },
            [
              _vm.config.ok
                ? _c(
                    "div",
                    {
                      staticClass: "popup-btn confirm blue-btn",
                      on: { click: _vm.confirmClick },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.config.ok) + "\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.config.cancel
                ? _c(
                    "div",
                    {
                      staticClass: "popup-btn confirm gray",
                      on: { click: _vm.closeClick },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.config.cancel) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.config.footerText
            ? _c("div", { staticClass: "footer__link" }, [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.config.footerText) },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }