var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: "preloder", staticClass: "connections_table" },
    [
      _c("IntegrationTableHeader"),
      _vm._v(" "),
      _vm._l([1, 2], function (item) {
        return _c(
          "div",
          { key: item, staticClass: "connections" },
          _vm._l([1, 2, 3], function (el) {
            return _c(
              "div",
              {
                key: el,
                staticClass: "connection",
                class: { full_border: el === 3 },
              },
              [
                _c("div", { staticClass: "view_container" }, [
                  _c(
                    "div",
                    { staticClass: "connection_project_title_msteams" },
                    [
                      el === 1
                        ? _c("div", { staticClass: "text_preloder" })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "channel_setting_container",
                      class: { small_border: el !== 3 },
                    },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _vm._m(1, true),
                      _vm._v(" "),
                      _vm._m(2, true),
                      _vm._v(" "),
                      _vm._m(3, true),
                    ]
                  ),
                ]),
              ]
            )
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "team_title_msteams" }, [
      _c("div", { staticClass: "text_preloder" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channel_title_msteams" }, [
      _c("div", { staticClass: "text_preloder" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "settings_title_msteams" }, [
      _c("div", { staticClass: "text_preloder" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buttons" }, [
      _c("div", { staticClass: "button_preloder" }),
      _vm._v(" "),
      _c("div", { staticClass: "button_preloder" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }