import { internalApi } from '$$store/api';
import app from '../../app';
import helpers from './helpers';
import rights from '../../components/rights';

export default {
  async loadData({ commit, rootState }) {
    let resourcesData = [];

    if (!rootState.appMode.isBase) {
      resourcesData = GT.ganttData.resources;
    } else {
      const response = await internalApi.get(`/resources/?${new Date().getTime()}`);

      resourcesData = response.data;
    }

    commit('setData', resourcesData);
    app.checkInit('resources');
  },
  async reloadData({ commit }) {
    const response = await internalApi.get(`/resources/?${new Date().getTime()}`);

    commit('setData', response.data);
  },
  replaceAllData({ commit }, payload) {
    commit('setData', payload);
  },
  async createResources({ commit, getters }, payload) {
    // payload = [
    //   {
    //     type: 'real',
    //     email: 'string',
    //     projectIds: [
    //       0,
    //     ],
    //     accountRoleId: 0,
    //     projectRoleId: 0,
    //     name: 'string',
    //     colorId: 0,
    //   },
    // ];

    const response = await internalApi.post('/resources/', { resourceItems: payload });

    commit('addResources', response.data);
    app.trigger('resources:model:create');
  },
  async updateResource({ commit, getters }, payload) {
    // payload = {
    //   resourceId: 0,
    //   update: {
    //     name: 'string',
    //     colorId: 0,
    //   },
    // };

    if (!payload.update.colorId) {
      delete payload.update.colorId;
    }

    const responseData = await internalApi.put('/resources/', payload);

    commit('updateResource', { resourceId: payload.resourceId, updated: responseData.data.update });
    app.trigger('resources:model:update', payload.id);
  },
  async removeResources({ commit, getters }, payload) {
    // payload = [1,2,3]
    let isRemovedOwner = false;

    payload.forEach(resourceId => {
      const resourceData = getters.getResourceById(resourceId);

      if (rights.account.isUserOwner(resourceData.userId)) {
        isRemovedOwner = true;
      }
    });

    app.trigger('resources:model:remove', payload);
    await internalApi.delete('/resources/', { data: { resourceIds: payload } });
    commit('removeResources', payload);

    if (isRemovedOwner) {
      document.location.href = `/logout/${user.locale}`;
    }
  },
  async changeWorkTime({ commit }, payload) {
    // payload = [
    //   {
    //     resourceId: payload.resourceId,
    //     updated: {
    //       workingDays: payload.workingDays,
    //       workingHours: payload.workingHours,
    //     },
    //   },
    // ];

    await internalApi.put('/resources/workTime/', { workTime: payload });
    payload.forEach(data => {
      commit('updateResource', data);
    });
    app.trigger('resources:model:changeWorkTime', payload.map(o => o.resourceId));
  },
  async createWorkCustomDays({ commit }, payload) {
    // payload = [{
    //   resourceId: 0,
    //   customDays: [
    //     {
    //       from: '2022-01-10',
    //       to: '2022-01-10',
    //       workingHours: 24,
    //       repeat: 'week',
    //       repeatFrom: '2022-01-10',
    //       repeatTo: '2022-01-10',
    //       title: '',
    //       type: '',
    //       customDayId: 0,
    //     },
    //   ],
    // }];
    payload.forEach(resourceData => {
      resourceData.customDays.map(customDay => helpers.formatCustomDayData(customDay));
    });

    const response = await internalApi.post('/resources/workCustomDays/', { customDays: payload });

    response.data.customDays.forEach(data => {
      const customDays = data.customDays.map(o => {
        o.to = o.to || null;

        return o;
      });

      commit('addWorkCustomDays', { resourceId: data.resourceId, customDays });
    });

    app.trigger('resources:model:changeWorkCustomDays', payload.map(o => o.resourceId));
  },
  async changeWorkCustomDays({ commit, getters }, payload) {
    // payload = [{
    //   resourceId: 0,
    //   customDays: [
    //     {
    //       from: '2022-01-10',
    //       to: '2022-01-10',
    //       workingHours: 24,
    //       repeat: 'week',
    //       repeatFrom: '2022-01-10',
    //       repeatTo: '2022-01-10',
    //       title: '',
    //       type: '',
    //       customDayId: 0,
    //     },
    //   ],
    // }];

    payload.forEach(resourceData => {
      resourceData.customDays.map(customDay => helpers.formatCustomDayData(customDay));
    });

    await internalApi.put('/resources/workCustomDays/', { customDays: payload });
    payload.forEach(data => {
      data.customDays.forEach(customDay => {
        commit('updateWorkCustomDay', { resourceId: data.resourceId, customDay });
      });
    });
    app.trigger('resources:model:changeWorkCustomDays', payload.map(o => o.resourceId));
  },
  async removeWorkCustomDays({ commit, getters }, payload) {
    // payload = {
    //   resourceId: 0,
    //   customDayIds: [0],
    // };

    await internalApi.delete('/resources/workCustomDays/', { data: { customDays: [payload] } });
    commit('removeWorkCustomDays', payload);
    app.trigger('resources:model:changeWorkCustomDays', payload.resourceId);
  },
  async addResourcesToProjects({ commit, getters }, payload) {
    // payload = [
    //   {
    //     projectRoleId: 0,
    //     resourceId: 0,
    //     projectIds: [
    //       0,
    //     ],
    //   },
    // ];
    const response = await internalApi.post('/resources/projectConfig/', { resourceToProject: payload });
    const grouped = _.groupBy(response.data, 'resourceId');
    const resourceIds = Object.keys(grouped);

    resourceIds.forEach(id => {
      commit('addResourceToProjects', {
        resourceId: id,
        resourceProjects: grouped[id].map(r => _.omit(r, ['resourceId'])),
      });
    });

    app.trigger('resources:model:addResourcesToProjects', payload);
  },
  async changeResourcesOnProjects({ commit, getters }, payload) {
    // payload = [
    //   {
    //     resourceId: 0,
    //     projectIds: [
    //       0,
    //     ],
    //     updated: {
    //       type: 'string',
    //       cost: 0,
    //     },
    //   },
    // ];

    await internalApi.put('/resources/projectConfig/', { resourceToProject: payload });

    payload.forEach(data => {
      commit('updateResourceOnProjects', data);
    });

    app.trigger('resources:model:changeResourcesOnProjects', payload);
  },
  async removeResourcesFromProjects({ commit, getters }, payload) {
    // payload = [
    //   {
    //     resourceId: 0,
    //     projectId: 0,
    //   },
    // ];

    const response = await internalApi.delete('/resources/projectConfig/', { data: { resourceToProject: payload } });
    const grouped = _.groupBy(payload, 'resourceId');
    const resourceIds = Object.keys(grouped);

    resourceIds.forEach(id => {
      commit('removeResourceFromProjects', {
        resourceId: id,
        projectIds: grouped[id].map(o => o.projectId),
      });
    });

    response.data.forEach(data => {
      commit('tasksModel/updateTaskResourcesByGanttId', {
        ganttId: data.projectId,
        resourcesToTasks: data.resourcesToTasks,
      }, { root: true });
    });

    app.trigger('resources:model:removeResourcesFromProjects', payload, resourceIds);
  },
  async changeResourceProjectRole({ commit }, payload) {
    await internalApi.put(`/resources/changeProjectRole?resourceId=${payload.resourceId}`, {
      roleId: payload.roleId,
      projectId: payload.ganttId,
    });

    commit('updateResourceOnProjects', {
      resourceId: payload.resourceId,
      projectIds: [payload.ganttId],
      updated: {
        projectRoleId: payload.roleId,
      },
    });

    app.trigger('resources:model:changeResourceProjectRole', payload);
  },
  async changeResourceAccountRole({ commit }, payload) {
    await internalApi.put(`/resources/changeAccountRole?resourceId=${payload.resourceId}`, { roleId: payload.roleId });
    commit('updateResource', { resourceId: payload.resourceId, updated: { accountRoleId: payload.roleId } });
    commit('teamSetting/updateUserAccountRole', { user_id: payload.userId, role_id: payload.roleId }, { root: true });
  },

  async convertResourcesOnProject({ commit, dispatch }, payload) {
    await internalApi.post('/resources/convertResourcesOnProject', payload);
  },
};
