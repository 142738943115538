<template>
  <vue-multiple-input
    v-model="value"
    class="gp_autotest_multiple_input"
    :class="['multiple_input', isError ? 'is-error' : '', classes]"
    :tags="inputs"
    :placeholder="placeholder"
    :validation="validation"
    :avoid-adding-duplicates="false"
    :allow-edit-tags="true"
    :separators="[',', ';', ' ']"
    @before-adding-tag="onBeforeAddingTag"
    @before-deleting-tag="onBeforeDeletingTag"
    @before-editing-tag="onBeforeEditingTag"
    @tags-changed="tagsChanged"
  >
    <template #tag-actions="props">
      <div
        class="closer_wrapper gp_autotest_multiple_input_tags"
        @click="props.performDelete(props.index)"
      >
        <svg-sprite
          class="chip_closer gp_autotest_multiple_input_tags_close_icon"
          name="close-2"
          :size="16"
          color="#191919"
        />
      </div>
    </template>
  </vue-multiple-input>
</template>

<script>
import VueMultipleInput from '@johmun/vue-tags-input';

export default {
  name: 'MultipleInput',
  components: { VueMultipleInput },
  props: {
    placeholder: { type: String, required: true, default: '' },
    validation: { type: Array, required: false, default: () => [] },
    items: { type: Array, required: false, default: () => [] },
    classes: { type: String, required: false, default: '' },
    isError: { type: Boolean, required: false, default: false },
  },
  emits: {
    setItems: Array,
    checkTag: Object,
    error: Boolean,
    isInput: Boolean,
  },
  data() {
    return {
      value: '',
      inputs: [],
    };
  },
  watch: {
    value(newVal) {
      if (newVal !== '') {
        this.$emit('isInput', true);
      } else {
        this.$emit('isInput', false);
      }
    },
    disabled(flag) {
      this.isDisabled(flag);
    },
  },
  created() {
    this.inputs = this.items;
  },
  mounted() {
    const el = this.$el;

    el.addEventListener('click', this.focus);
    document.documentElement.addEventListener('click', this.editTag);
  },
  destroyed() {
    const el = this.$el;

    el.removeEventListener('click', this.focus);
    document.documentElement.removeEventListener('click', this.editTag);
  },
  methods: {
    isDisabled(flag) {
      const el = this.$el;
      const input = el.querySelector('.ti-new-tag-input-wrapper');

      if (flag) {
        input.classList.add('disable');
      } else {
        input.classList.remove('disable');
      }
    },
    focus(e) {
      if (e.target.closest('.ti-tag')) return;
      const el = this.$el;
      const input = el.querySelector('.ti-new-tag-input');

      input.focus();
    },
    editTag(e) {
      if (e.target.closest('.ti-editing') || e.target.closest('.apply')) return false;
      if (document.querySelector('.ti-new-tag-input').value !== '') return false;

      this.tagsChanged(this.inputs);
    },
    onBeforeAddingTag(obj) {
      obj.addTag();
      this.$emit('checkTag', obj);
      this.$emit('isInput', false);
    },
    onBeforeDeletingTag(obj) {
      obj.deleteTag();
    },
    onBeforeEditingTag(obj) {
      this.index = obj.index;
      this.$emit('isInput', true);

      setTimeout(() => {
        const tagItem = this.$el.querySelectorAll('.ti-tag')[obj.index];
        const editInput = tagItem.querySelector('input');

        editInput.onchange = e => {
          if (e.target.value !== '') {
            this.inputs.forEach((itm, idx) => {
              if (idx === obj.index) {
                itm.text = e.target.value;
              }
            });
          } else {
            this.inputs.splice(obj.index, 1);
          }
        };
      }, 0);

      obj.editTag();
    },
    tagsChanged(tags) {
      this.inputs = tags;
      this.$emit('setItems', tags);
      this.$emit('isInput', false);
    },
  },
};
</script>

<style src='./vInput.less' lang='less' />
