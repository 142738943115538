const MAX_EVENTS_PER_CELL = 15;

const CALENDAR_CONFIG = {
  autoSave: true,
  dragResize: true,
  dragMove: true,
  dragCreate: false,
  // eventInfoOnClick: false,
  editorOnDblClick: false,
  createEventOnDblClick: false,
  eventVerticalSpace: 2,
  viewControl: 'none',
  views: [
    {
      id: 'month',
      label: 'Month',
      layout: 'month',
      config: {
        maxEventsPerCell: MAX_EVENTS_PER_CELL,
      },
    },
  ],
};
const MONTH_LOCALES = [
  __('calendar_page_jan'),
  __('calendar_page_feb'),
  __('calendar_page_march'),
  __('calendar_page_apr'),
  __('calendar_page_may'),
  __('calendar_page_jun'),
  __('calendar_page_jul'),
  __('calendar_page_aug'),
  __('calendar_page_sep'),
  __('calendar_page_oct'),
  __('calendar_page_nov'),
  __('calendar_page_dec'),
];
const WEEK_LOCALES = [
  __('calendar_page_sun'),
  __('calendar_page_mon'),
  __('calendar_page_tue'),
  __('calendar_page_wed'),
  __('calendar_page_thu'),
  __('calendar_page_fri'),
  __('calendar_page_sat'),
];

const PRIORITY = {
  5: {
    locale: __('filter_priority_highest'),
    color: '#E74C3C',
  },
  6: {
    locale: __('filter_priority_high'),
    color: '#FF9A02',
  },
  7: {
    locale: __('filter_priority_medium'),
    color: '#52884A',
  },
  8: {
    locale: __('filter_priority_low'),
    color: '#828282',
  },
  9: {
    locale: __('filter_priority_lowest'),
    color: '#AAAAAA',
  },
};

const STATUSES = {
  1: {
    locale: __('filter_status_open'),
    color: '#9b9b9b',
  },
  2: {
    locale: __('filter_status_in_progress'),
    color: '#ff9921',
  },
  3: {
    locale: __('filter_status_done'),
    color: '#55BED4',
  },
  4: {
    locale: __('filter_status_closed'),
    color: '#14bb4c',
  },
};

const DARKEST_COLORS = {
  '#FF6363': '#852020',
  '#217EE9': '#0D3D73',
  '#489F95': '#00342E',
  '#8BC34A': '#364F1A',
  '#D64AFF': '#722D7D',
  '#7A7A7A': '#0D0D0D',

  '#FF8E8E': '#BC2D00',
  '#4FC3F7': '#06567A',
  '#50C7D6': '#16555D',
  '#C0CA33': '#4B4F14',
  '#D860BB': '#761D60',
  '#757575': '#2E2E2E',

  '#FFAB91': '#EE7950',
  '#7986CB': '#2B356D',
  '#4DB6AC': '#1D4844',
  '#F9D06B': '#866106',
  '#9575CD': '#42286F',
  '#78909C': '#2E393F',

  '#EF9A9A': '#E45E5E',
  '#64B5F6': '#0D3D73',
  '#81C784': '#275929',
  '#F9D295': '#CC7B00',
  '#CE93D8': '#A051B7',
  '#A1887F': '#4E3F39',
};

export {
  MONTH_LOCALES,
  WEEK_LOCALES,
  CALENDAR_CONFIG,
  PRIORITY,
  STATUSES,
  DARKEST_COLORS,
  MAX_EVENTS_PER_CELL,
};
