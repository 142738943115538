import app from "./../app";
import notificationsModel from "./../models/notification";
import modalView from "./../views/notification/modal";
import messageView from "./../views/notification/message";

var notificationComponent = {
  waitingStack: [],
  //every view with blocker:true need to trigger notification:closed event on close
  typeMap: {
    "modal": {
      view: modalView,
      blocker: true
    },
    "message": {
      view: messageView
    },
    "default": {
      view: messageView
    }
  },

  getTypeView: function (type) {
    return this.typeMap[type] || this.typeMap['default'];
  },

  addToWaitingStack: function (data) {
    var existingNotifications = _.map(this.waitingStack, function (v) {
      return v.confirmId;
    });

    if (this.settings.activeConfirmId != data.confirmId && existingNotifications.indexOf(data.confirmId) == -1) {
      this.waitingStack.unshift(data);
    }
  },

  showWaitingNotifications: function () {
    if (this.waitingStack.length > 0) {
      var notification = this.waitingStack.pop();
      this.showNotification(notification);
      if (!this.isStandby()) {
        this.showWaitingNotifications();
      }
    }
  },

  showNotification: function (data) {
    if (this.settings.standby) {
      this.addToWaitingStack(data);
    } else {
      var type = this.getTypeView(data.type);
      if (type.blocker) {
        this.standbyOn(data.confirmId);
      }
      type.view.show(data.template, data.data, data.options, data.confirmId);
    }
  },

  isStandby: function () {
    return this.settings.standby;
  },

  standbyOn: function (confirmId) {
    this.settings.standby = true;
    this.settings.activeConfirmId = confirmId;
  },

  standbyOff: function () {
    this.settings.standby = false;
    this.settings.activeConfirmId = false;
  },

  restartInterval: function () {
    this.params.interval = setInterval(function () {
      notificationsModel.getWaitingNotifications();
    }, 600000);
  },

  params: {
    interval: false
  },
  settings: {
    standby: false,
    activeConfirmId: false
  }
};

// incomming data has fields:
// type - string - type of notification
// template - string - content that will be added to notification container of sufficient type
// data - object - data to fill template
// options - object - parameters for container like width/height of modal window or style of webix message
app.socket.on("notification:show", function (data) {
  notificationComponent.showNotification(data);
});

app.on('notifications:showWaiting', function (data) {
  _.each(data, function (ntf) {
    ntf && notificationComponent.showNotification(ntf);
  });
});

app.socket.on("notification:checkNew", function () {
  notificationComponent.restartInterval();
  notificationsModel.getWaitingNotifications();
});

app.on("notification:closed", function () {
  notificationComponent.standbyOff();
  notificationComponent.showWaitingNotifications();
});

app.on("notification:confirm", function (confirmId) {
  notificationsModel.confirmDelivery(confirmId);
});

app.on("gantt:init:before", function () {
  if (app.config.mode.isBase) {
    notificationsModel.getWaitingNotifications();
  }

  notificationComponent.restartInterval();
});

export default notificationComponent;



