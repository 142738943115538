
import app from '../../../app';
import globalStore from '$$store/main';

export function deleteResource(resourceIds) {
  const result = {
    resourceIds: [],
  };

  resourceIds.forEach(resourceId => {
    // const resource = resourcesModel.getResourceById(resourceId);
    // const data = { id: resourceId, user_id: resource?.user_id };
    //
    // resourcesModel.removeByCollaboration([data]);

    const resourceData = globalStore.getters['resourcesModel/getResourceById'](resourceId);

    // add new resource role to teamSetting store
    if (resourceData?.user_id) {
      globalStore.commit('teamSetting/deleteUserAccountRole', { user_id: resourceData.user_id });
    }

    result.resourceIds.push(+resourceId);
  });

  globalStore.commit('resourcesModel/removeResources', resourceIds);

  return result;
}

function onResourceDeleted(event) {
  const { resourceIds } = deleteResource(event.data.ids);

  app.trigger('onAfterCollaboration', {
    event: event.event,
    resources: [...new Set(resourceIds)],
  });
}

export default onResourceDeleted;
