<template>
  <dropCell
    :suggest-height="dropHeight"
    :drop-width="186"
    :disabled="blockShowDrop"
    :editable="editable"
  >
    <template #trigger="{isOpen}">
      <div
        v-if="selected"
        :style="{opacity: isOpen ? 1 : cellOpecity}"
        class="selected-item-cell"
        :class="{
          'disabled': blockShowDrop,
          'crossout': crossout && !isOpen,
          'not_allowed': !editable,
        }"
      >
        <div
          v-if="useCircleIcon"
          class="circle-8"
          :style="{'background-color': selected.color}"
        />
        <div
          v-else
          class="priority-icon"
          :style="{'background-image': `url(${selected.imgUrl})` }"
        />

        <span
          class="text_wrap"
          :class="{'g-pl-8': property === 'priority' && isJira}"
        >  {{ selected.value }} </span>
      </div>
    </template>

    <template #drop>
      <div
        v-for="(opt, index) in options"
        :key="'d-i' + index"
        class="drop-item-template"
        :class="{'active': isActive(opt.id)}"
        @click="selectOption(opt)"
      >
        <div
          v-if="useCircleIcon"
          class="circle-8"
          :style="{'background-color': opt.color}"
        />
        <div
          v-else
          class="priority-icon"
          :style="{'background-image': `url(${opt.imgUrl})` }"
        />

        <span :class="{'g-pl-8': property === 'priority' && isJira}">  {{ opt.value }} </span>
      </div>
    </template>
  </dropCell>
</template>

<script>
import dropCell from '../../dropPopup.vue';

export default {
  name: 'SelectListCell',
  components: { dropCell },
  props: {
    item: { type: Object, required: true },
    column: { type: Object, required: true },
    property: { type: String, required: true },
    listType: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      options: [],

      priorityDefault: [
        // id, value, img_url
        ['5', __('filter_priority_highest'), 'https://cdn.ganttpro.com/app/imgs/highest.svg'],
        ['6', __('filter_priority_high'), 'https://cdn.ganttpro.com/app/imgs/high.svg'],
        ['7', __('filter_priority_medium'), 'https://cdn.ganttpro.com/app/imgs/medium.svg'],
        ['8', __('filter_priority_low'), 'https://cdn.ganttpro.com/app/imgs/low.svg'],
        ['9', __('filter_priority_lowest'), 'https://cdn.ganttpro.com/app/imgs/lowest.svg'],
      ],

      statusesDefault: [
        // id, value, color
        ['1', __('filter_status_open'), '#9b9b9b'],
        ['2', __('filter_status_in_progress'), '#ff9921'],
        ['3', __('filter_status_done'), '#55BED4'],
        ['4', __('filter_status_closed'), '#14bb4c'],
      ],
    };
  },

  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },

    useCircleIcon() {
      return this.property === 'status';
    },

    dropHeight() {
      if (this.listType === 'status') { return 36 * 4; }
      if (this.listType === 'priority') { return 36 * 5; }

      return 200;
    },

    selected() {
      return this.options.find(s => s.id == this.item[this.property]);
    },

    blockShowDrop() {
      if (this.property === 'status') { return false; }

      return this.disabled;
    },

    isJira() {
      const ifJira = this.column.forJiraProject && this.column.forJiraProject[this.item.gantt_id];

      return !!ifJira;
    },
  },

  mounted() {
    this.createItemOptions();
  },

  methods: {
    createItemOptions() {
      if (this.isJira) {
        const forJira = this.column.forJiraProject[this.item.gantt_id];

        if (!forJira) {
          console.warn(`Did not find options for jira  ${this.property} item`);

          return;
        }

        forJira.options.forEach(i => {
          const vals = [i.id, i.value, this.property === 'status' ? i.color : i.icon, i.jira_field_id];
          const opt = this.property === 'status' ? this.createStatusOption(...vals) : this.createPriorityOption(...vals);

          this.options.push(opt);
        });

        return;
      }

      if (this.property === 'status') {
        this.statusesDefault.forEach(i => {
          this.options.push(this.createStatusOption(...i));
        });

        return;
      }

      if (this.property === 'priority') {
        this.priorityDefault.forEach(i => {
          this.options.push(this.createPriorityOption(...i));
        });
      }
    },

    createPriorityOption(id, value, imgUrl, jira_field_id = null) {
      return {
        id, value, imgUrl, jira_field_id,
      };
    },

    createStatusOption(id, value, color, jira_field_id = null) {
      return {
        id, value, color, jira_field_id,
      };
    },

    isActive(id) {
      return id == this.item[this.property];
    },

    selectOption(option) {
      this.$emit('change', +option.id);
    },
  },
};
</script>

<style scoped lang='less'>
.circle-8{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
}
.cell-trigger{
    height: 100%;
    display: flex;
    align-items: center;
}
.selected-item-cell{
    display: flex;
    padding:0 10px;
    align-items: center;
    height: 100%;
    overflow: hidden;
    width: 100%;

    &.disabled{
      color: #9e9e9e;
    }

    &.not_allowed{
      cursor: not-allowed;
    }
}
.drop-item-template{
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &:hover{
        background-color: #f3f3f3;
    }
    &.active{
        background-color: #fff1c6;
    }
}
.priority-icon{
    height: 17px;
    width: 17px;
    margin-right: 2px;
    background-size: 17px 17px;
    background-repeat: no-repeat;
    flex-shrink: 0;
}
.text_wrap{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.g-pl-8{
  padding-left: 8px;
}

.crossout{
  text-decoration: line-through;
}
</style>
