var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "skeletonHeader" } },
    [
      _c("skeleton-wrapper", {
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", [
                  _c("div", { class: [_vm.$.headerRow] }, [
                    _c(
                      "div",
                      { class: _vm.$.firstCell },
                      [
                        _c("skeleton-item", {
                          attrs: { type: "square", size: "lst" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { width: "430px", height: "24px" },
                          attrs: { type: "bar" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$.lastCell },
                      [
                        _c("skeleton-item", {
                          style: { width: "53px", height: "24px" },
                          attrs: { type: "bar" },
                        }),
                        _vm._v(" "),
                        _c("skeleton-item", {
                          style: { width: "53px", height: "24px" },
                          attrs: { type: "bar" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }