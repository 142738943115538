<template>
    <div>
        <webix-ui :config="webixUI"/>
    </div>
</template>

<script>
    import app from "../../app";
    import VuePage from "./VuePage.vue";
    import webixWindowForVueSample from "./webixWindowForVueSample";

    export default {
        name: 'VuePageSample',
        props: [],
        data() {
            return {
                webixUI: webixWindowForVueSample.window("vueSampleWindowId", VuePage, {
                  borderless: true
                })
            }
        },
        created: function() {
            let component = this;
        },
        mounted: function() {
            let component = this;
            component.$nextTick(function () {
                $$("vueSampleWindowId").show();
            })
        }
    }
</script>


<style scoped>

</style>