var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.cell_trigger,
        _vm.$style.select_none,
        _vm.align === "center" ? _vm.$style.text_center : "",
        _vm.disabled ? _vm.$style.text_disabled : "",
        _vm.crossout ? _vm.$style.crossout : "",
        _vm.discolor ? _vm.$style.discolor : "",
        !_vm.editable ? _vm.$style.not_allowed : "",
      ],
    },
    [
      _c("span", { class: [_vm.$style.text_wrap] }, [
        _vm._v(" " + _vm._s(_vm.item[_vm.property]) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }