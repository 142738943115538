<template>
  <div
    :class="[$style.info_popup, 'info_popup']"
    :style="{
      top: coordinates.top,
      bottom: coordinates.bottom,
      left: coordinates.left,
      right: coordinates.right,
    }"
  >
    <div :class="$style.container">
      <div
        :class="$style.body"
        :style="{width: `${width}px`}"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

export default {
  name: 'InfoPopup',
  props: {
    width: {
      type: Number,
      required: false,
      default: 484,
    },
    position: {
      type: Object,
      required: true,
    },
    side: { type: String, required: false, default: '' },
  },
  data() {
    return {
      coordinates: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      },
    };
  },
  computed: {
  },
  watch: {
    position() {
      this.getCoordinates();
    },
  },
  mounted() {
    this.getCoordinates();
    window.addEventListener('wheel', _.debounce(this.onScroll, 0));
  },
  beforeDestroy() {
    window.removeEventListener('wheel', _.debounce(this.onScroll, 0));
  },
  methods: {
    onScroll() {
      this.$emit('closePopup', true);
    },
    getCoordinates() {
      const coordinates = this.position;
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const tooltipHeight = document.getElementsByClassName('info_popup')[0].offsetHeight;
      const tooltipWidth = document.getElementsByClassName('info_popup')[0].offsetWidth;

      this.dropToBottom = (coordinates.bottom + tooltipHeight) < clientHeight;

      if (this.side && this.side === 'right') {
        if (this.dropToBottom) {
          this.coordinates = {
            top: `${coordinates.top}px`,
            left: `${coordinates.right}px`,
          };
        } else {
          this.coordinates = {
            top: `${coordinates.bottom - tooltipHeight}px`,
            left: `${coordinates.right}px`,
          };
        }
      } else if (this.dropToBottom) {
        this.coordinates = {
          top: `${coordinates.bottom}px`,
        };
      } else {
        this.coordinates = {
          top: `${coordinates.top - tooltipHeight}px`,
        };
      }
    },
  },
};
</script>

<style module src='./infoPopup.less'>

</style>
