<template>
  <div
    v-click-outside="close"
    :class="$style.select"
  >
    <tooltip
      :class="$style.tooltip_wrapper"
      :content="locales('header_toolbar_view_tooltip')"
      :position="'bottom-end'"
      :hide="showMenu"
    >
      <template #body>
        <vgp-icon-button
          ref="contextMenuButton"
          :icon="{ name: showMenu ? 'arrow-top' : 'arrow-down', size: 20 }"
          :label="locales('view_settings')"
          icon-side="right"
          :colors="buttonColors"
          :active="showMenu"
          @onClick="toggle"
        />
      </template>
    </tooltip>

    <context-menu
      v-if="showMenu"
      :position="positionContext1"
      style="min-width: 230px; overflow: visible"
    >
      <template #body>
        <template v-for="(item, index) in items">
          <div
            v-if="item.type === 'select' || item.type === 'multiselect'"
            :key="index"
            :class="[
              $style.menu_item,
              $style.item_level,
              showSelect === item.name ? $style.active : '',
            ]"
            @mouseenter="enterSelect(item.name, $event.target)"
            @mouseleave="leaveSelect"
          >
            <svg-sprite
              :class="$style.icon"
              name="drop-left"
              type="bold"
              size="24"
            />
            <div :class="$style.text">
              {{ item.text }}
            </div>

            <context-menu
              v-if="showSelect === item.name && item.list"
              :position="positionContext2"
              side="left"
              :offset="{ top: -8 }"
              :style="{
                'min-width': item.icons ? 'unset' : '',
                'border-top-right-radius': 0,
                'border-bottom-right-radius': 0,
              }"
            >
              <template #body>
                <template v-if="item.type === 'select'">
                  <div
                    v-if="item.icons"
                    :class="$style.row"
                  >
                    <div
                      v-for="(item2, index2) in item.list"
                      :key="index2"
                      :class="[
                        $style.row_item,
                        item.value === item2.value ? $style.active : '',
                      ]"
                      @click="onSelect(item, item2)"
                    >
                      <svg-sprite
                        :name="item2.config"
                      />
                    </div>
                  </div>
                  <template v-else>
                    <div
                      v-for="(item2, index2) in item.list"
                      :key="index2"
                      :class="[
                        $style.menu_item,
                        item.value === item2.value ? $style.active : '',
                      ]"
                      @click="onSelect(item, item2)"
                    >
                      <div :class="$style.text">
                        {{ item2.text }}
                      </div>
                    </div>
                  </template>
                </template>

                <template v-if="item.type === 'multiselect'">
                  <div
                    v-for="(item2, index2) in item.list"
                    :key="index2"
                    :class="[
                      $style.menu_item,
                      $style.item_check,
                      item.value.includes(item2.value) ? $style.active : '',
                    ]"
                    @click="onSelect(item, item2)"
                  >
                    <div :class="$style.checkbox">
                      <vgp-checkbox
                        size="16"
                        :checked="item.value.includes(item2.value)"
                      />
                    </div>
                    <div :class="$style.text">
                      {{ item2.text }}
                    </div>
                  </div>
                </template>
              </template>
            </context-menu>
          </div>

          <div
            v-if="item.type === 'checkbox'"
            :key="index"
            :class="[
              $style.menu_item,
              $style.item_check,
              item.value ? $style.active : '',
            ]"
            @click="onSelect(item)"
          >
            <div :class="$style.checkbox">
              <vgp-checkbox
                size="16"
                :checked="item.value"
              />
            </div>
            <div :class="$style.text">
              {{ item.text }}
            </div>
          </div>

          <div
            v-if="item.name === 'divider'"
            :key="index"
            :class="$style.divider"
          />
        </template>
      </template>
    </context-menu>
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import vgpCheckbox from '$$vueCmp/checkbox/vgpCheckbox.vue';
import routerHelper from '../../../helpers/router';
import Tooltip from '../../common/VueComponents/tooltips/tooltip.vue';

export default {
  components: {
    svgSprite,
    vgpIconButton,
    vgpCheckbox,
    Tooltip,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locales: __,
      showMenu: false,
      showSelect: '',
      positionContext1: null,
      positionContext2: null,
      itemLocales: {
        taskName: __('settings_task_name'),
        completedTask: __('settings_completed_task'),
        crossOutTasks: __('settings_cross_out_task'),
        discolorationTasks: __('settings_discoloration_task'),
        todayMarker: __('settings_current_day_marker'),
        rightSideText: __('settings_right_side_text'),
        insideText: __('settings_inside_text'),
        noTaskText: __('settings_no_task_text'),
        avatar: __('settings_show_resource_avatar'),
        skipOffTime: __('settings_skip_off_time'),
        buttons: __('settings_show_advanced_buttons'),
        skins: __('settings_skins'),
        viewMode: __('settings_view_mode'),
        auto: __('settings_view_modes_auto'),
        compact: __('settings_view_modes_compact'),
        touch: __('settings_view_modes_touch'),
      },
      icons: {
        1: 'skins-1', // 'skinOne',
        2: 'skins-2', // 'skinTwo',
        3: 'skins-3', // 'skinThree',
        4: 'skins-4', // 'skinFour',
      },
    };
  },
  computed: {
    items() {
      return this.config.map(obj => {
        const item = { ...obj };

        item.text = this.itemLocales[obj.name];
        item.value = this.$store.getters['headerView/viewSettings'][obj.name];

        if (obj.type === 'select' || obj.type === 'multiselect') {
          item.list = obj.list.map(str => {
            if (obj.icons) {
              return {
                value: str,
                config: this.icons[str],
              };
            }

            return {
              value: str,
              text: this.itemLocales[str],
            };
          });
        }

        return item;
      });
    },
    currentRouteName() {
      return this.$store.getters['headerView/currentRoute'].name;
    },
  },
  methods: {
    onSelect(item, option) {
      let value;

      if (item.type === 'multiselect') {
        if (item.value.includes(option.value)) {
          value = item.value.filter(name => name !== option.value);
        } else {
          value = [...item.value, option.value];
        }
      } else {
        value = !item.value;

        if (option) {
          //this.close();

          if (item.value === option.value) {
            return;
          }
          if (
            typeof parseInt(item.value) === 'number'
            && +item.value === +option.value
          ) {
            return;
          }

          value = option.value;
        }
      }
      this.$store.dispatch('headerView/setViewSettings', { name: item.name, value });
      this.analyticsHandler(item, option);
    },
    analyticsHandler(item, option) {
      const from = this.currentRouteName === 'project' ? 'project' : 'portfolio';
      let type = option ? option.value : '';

      if (!option) {
        if (item.name === 'todayMarker') type = 'today marker';
        if (item.name === 'avatar') type = 'avatar on chart';
        if (item.name === 'skipOffTime') type = 'days off on chart';
        if (item.name === 'buttons') type = 'show advaced buttons';
        userExtAnalytics.log('header_change_view_settings_toggle', {
          from,
          type,
        });

        return;
      }

      if (item.name === 'taskName') {
        if (option.value === 'insideText') type = 'inside';
        if (option.value === 'rightSideText') type = 'on the right';
        if (option.value === 'noTaskText') type = 'noTaskText';
        userExtAnalytics.log('header_change_view_settings_task_name', {
          from,
          type,
        });

        return;
      }

      if (item.name === 'skins') {
        if (option.value === 1) type = 'horizontal';
        if (option.value === 2) type = 'none';
        if (option.value === 3) type = 'vertical';
        if (option.value === 4) type = 'all';
        userExtAnalytics.log('header_change_view_settings_skins', {
          from,
          type,
        });
      }
    },
    toggle() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.positionContext1 = this.$refs.contextMenuButton.$el.getBoundingClientRect();

        userExtAnalytics.log('header_change_view_settings_open', {
          from: routerHelper.getCurrentRoute().path.startsWith('/project/')
            ? 'project'
            : 'portfolio',
        });
      }
    },
    close() {
      this.showMenu = false;
      this.showSelect = '';
    },
    enterSelect(selectName, el) {
      this.showSelect = this.showSelect === selectName ? '' : selectName;
      this.positionContext2 = el.getBoundingClientRect();
    },
    leaveSelect() {
      this.showSelect = '';
    },
  },
};
</script>

<style module src="../less/select.less"></style>
