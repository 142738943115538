<template>
  <right-popup
    :show="!!taskId && !ganttInProgress"
    :width="815"
    :border-top="false"
    exclude-footer
    exclude-header-toolbar
    portal-name="task-settings"
    :with-animation="false"
  >
    <template #body>
      <task-settings-main
        :task-id="taskId"
        @close="closeTask"
      />
    </template>
  </right-popup>
</template>

<script>
import { mapState } from 'vuex';
import RightPopup from '$$vueCmp/popups/rightPopup/rightPopup.vue';
import TaskSettingsMain from './TaskSettingsMain.vue';
import { closeTask } from './helpers';
import routerHelper from '$$helpers/router';

export default {
  name: 'TaskSettings',
  components: {
    TaskSettingsMain,
    RightPopup,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
    parent: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      ganttInProgress: state => state.ganttInProgress,
    }),
    showPopup() {
      return this.taskId && !this.ganttInProgress;
    },
    getParent() {
      if (this.$route.params.mode === 'list') {
        return 'list';
      }

      return this.parent;
    },
  },
  watch: {
    showPopup: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.selectActiveTask(this.taskId);
          }, 0);
        }
      },
      immediate: true,
    },
    taskId: {
      handler(val, oldVal) {
        if (oldVal) {
          setTimeout(() => {
            this.removeActiveTask(oldVal);
          }, 0);
        }
        if (val) {
          setTimeout(() => {
            this.selectActiveTask(val);
          }, 0);
        }
      },
    },
  },
  beforeDestroy() {
    this.removeActiveTask(this.taskId);
  },
  methods: {
    closeTask,
    removeActiveTask(taskId) {
      gantt.config._opened_task_id = null;

      if (this.getParent !== 'list') {
        const ganttTaskNode = gantt.getTaskRowNode(taskId);

        ganttTaskNode && ganttTaskNode.classList.remove('selected');
      }

      gantt.$grid.classList.remove('opened_task_settings');
    },
    selectActiveTask(taskId) {
      if (routerHelper.isWorkloadViewRoute() || !gantt.isTaskExists(taskId)) {
        gantt.$grid.classList.remove('opened_task_settings');

        return;
      }

      if (!routerHelper.isListViewRoute()) {
        gantt.unselectTask();
        gantt.showTask(taskId);// for horizontal scrolling
      }

      const ganttTaskNode = gantt.getTaskRowNode(taskId);

      if (!ganttTaskNode) {
        return;
      }

      if (this.getParent !== 'list') {
        ganttTaskNode.classList.add('selected');
        gantt.config._opened_task_id = taskId;// for inline editors
        gantt.$grid.classList.add('opened_task_settings');
      }
    },
  },

};
</script>

<style scoped lang="less">

@import '../../less/_fonts.less';

</style>
