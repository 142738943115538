/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import app from '../../../app';
import _ from '../../../libs/lodash';

import { assignResourceToProjects } from './onResourceToProjectsAssigned';
import { updateResourceOnProjects } from './onResourceOnProjectsUpdated';
import { unassignResourceFromProjects } from './onResourceFromProjectsUnassigned';
import { updateTasks } from '../tasks/onTaskUpdated';
import { resourceHelper } from '../tasks/helpers';

async function onResourcesOnProjectsConverted(event) {
  const projects = new Set();
  const tasks = new Set();

  await assignResourceToProjects(event.data.resourcesToProjects, event.innerSettings);
  updateResourceOnProjects(event.data.resourcesOnProjects);
  unassignResourceFromProjects(event.data.resourcesFromProjects);
  const { hasActiveProject } = updateTasks(event.data.tasksToUpdate);

  for (const { ganttId, resourcesToTasks } of event.data.resourcesToTasks) {
    const resources = [];

    projects.add(+ganttId);

    _.get(event.data.taskResourcesToAssign, ganttId, []).forEach(taskResources => {
      resources.push(taskResources);
      tasks.add(+taskResources.taskId);
    });

    _.get(event.data.resourcesToUnassign, ganttId, []).forEach(taskResources => {
      resources.push(taskResources);
      tasks.add(+taskResources.taskId);
    });

    _.get(event.data.taskResourcesToUpdate, ganttId, []).forEach(taskResources => {
      resources.push(taskResources);
      tasks.add(+taskResources.taskId);
    });

    await resourceHelper(ganttId, resourcesToTasks, null, resources);
  }

  if (hasActiveProject) {
    gantt.render();
  }

  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: [...projects],
    tasks: [...tasks],
  });

  app.trigger('refresh:filter');
}

export default onResourcesOnProjectsConverted;
