import durationConfig from './../newProject/include/durationConfig';
import importObject from './../newProject/import/main';

const __ = window.__;

var innerSettings = {};

var innerViews = {
  importProjectLayoutBody: {
    id: 'importProjectLayoutBody',
    css: 'new_project_layout_body'
  },
  importProjectLayoutForm: {
    id: 'importProjectLayoutForm',
    css: 'new_project_layout_form'
  },
  importProjectNameText: {
    view: 'text',
    id: 'importProjectNameText',
    label: __('new_name'),
    labelPosition: 'top',
    inputHeight: 48,
    height: 84,
    placeholder: __('new_project_placeholders_type_here'),
    on: {
      'onChange': function (newValue, oldValue) {
        importObject.setProjectName(newValue);
      }
    }
  },
  importProjectStartDaySelect: {
    view: 'datepicker',
    id: 'importProjectStartDaySelect',
    label: __('settings_start_date'),
    labelPosition: 'top',
    format: webix.i18n.longDateFormatStr,
    width: 280,
    inputHeight: 48,
    height: 84,
    on: {
      'onChange': function (newValue, oldValue) {
        //innerObject.handlers.changeStartDate(newValue);
      }
    }
  },
  importProjectDurationStepSelect: {
    view: 'richselectWithoutPoint',
    id: 'importProjectDurationStepSelect',
    options: durationConfig,
    value: 'hour',
    label: __('new_label_duration'),
    labelPosition: 'top',
    width: 280,
    inputHeight: 48,
    height: 84,
  },
  buttonOk: {
    id: 'importButtonOk',
    view: 'button',
    height: 60,
    value: __('import_project_btn'),
    css: 'green_btn',
    on: {
      onItemClick: function () {
        innerHandlers.createNewProject();
      }
    }
  }
};

var innerHandlers = {
  init: function () {
    setTimeout(() => {
      importObject.deleteImportFile();
      importObject.setDependency(
        innerHandlers.setProjectName,
        innerHandlers.disableEnableProjectOptions,
        innerHandlers.disableEnableButtonOk
      );
      importObject.onAfterTabClick('tabbarViewImport');
    }, 0);

  },
  createNewProject: function () {
    if (innerSettings.isDisableButton) {
      return false;
    }
    importObject.newProjectByImport('team');
  },
  setProjectName: function (projectName, forceClear) {
    if (!$$(innerViews.importProjectNameText.id).getValue().length || forceClear) {
      $$(innerViews.importProjectNameText.id).setValue(projectName);
    }

    return $$(innerViews.importProjectNameText.id).getValue();
  },
  disableEnableProjectOptions: function (state) {
    /*if (state) {
      $$(innerViews.importProjectStartDaySelect.id).enable();
      $$(innerViews.importProjectDurationStepSelect.id).enable();
    } else {
      $$(innerViews.importProjectStartDaySelect.id).disable();
      $$(innerViews.importProjectDurationStepSelect.id).disable();
    }*/
  },
  disableEnableButtonOk: function (state) {
    if (state) {
      $$(innerViews.buttonOk.id).enable();
      innerSettings.isDisableButton = !state;
    } else {
      $$(innerViews.buttonOk.id).disable();
      innerSettings.isDisableButton = !state;
    }
  }
};

var innerHelpers = {};

var outputObject = {
  view: {
    view: 'layout',
    id: innerViews.importProjectLayoutBody.id,
    css: innerViews.importProjectLayoutBody.css,
    borderless: true,
    cols: [
      {},
      {
        width: 570,
        rows: [
          {
            view: 'form',
            id: innerViews.importProjectLayoutForm.id,
            padding: 0,
            borderless: true,
            elements: [
              innerViews.importProjectNameText,
              /*{
                cols: [
                  innerViews.importProjectStartDaySelect,
                  { width: 10 },
                  innerViews.importProjectDurationStepSelect
                ]
              },
              calendarSettings.view,*/
              importObject.webixUI,
              innerViews.buttonOk
            ]
          }
        ]
      },
      {}
    ]
  },
  init: innerHandlers.init
}

export default outputObject;
