import constants from '../../helpers/constants';
import dateFormatHelper from '../../helpers/dateFormats';
import helpers from './helpers';
import rights from '../../components/rights';
import globalStore from '$$store/main';

export default {
  // contains tasks from inaccessible projects!
  getAllData: state => state.data,
  getData: state => state.data.filter(o => !o._onlyForWorkloadCalculation),
  getNotArchivedData: state => state.data.filter(o => o.is_archived !== 1 && !o._onlyForWorkloadCalculation),
  getItem: state => ganttId => state.data.find(o => o.id === +ganttId),
  tasksByIds: (state, getters) => {
    return getters.getData.reduce((tasksByIdsMap, dataItem) => {
      dataItem.tasks.forEach(task => {
        if (!tasksByIdsMap[task.id]) {
          tasksByIdsMap[task.id] = task;
        }
      });

      return tasksByIdsMap;
    }, {});
  },
  getTasksForGantt: (state, getters, rootState) => ganttId => {
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return {
        data: [],
        links: [],
      };
    }

    const tasksData = {
      data: projectData.tasks,
      links: projectData.links,
    };

    tasksData.data = _.orderBy(tasksData.data, ['parent', 'sortorder', 'id'], ['asc', 'asc', 'asc']);

    tasksData.data = tasksData.data.map(t => {
      if (t.parent === 1) {
        t.parent = 0;
      }
      t.needMultiviewBackground = false;
      t.calendar_id = t.gantt_id;

      return t;
    }); // needs after correct dates in multiview

    return tasksData;
  },
  // getTaskById
  getTask: state => taskId => {
    let task = null;

    _.some(state.data, projectData => _.some(projectData.tasks, t => {
      if (+t.id === +taskId) {
        task = t;

        return true;
      }

      return false;
    }));

    return task && helpers.smartClone(task);
  },

  // rights filtration
  getTasksByGanttIds: (state, getters, rootState, rootGetters) => ganttIds => {
    const tasks = [];
    const resourceId = rootGetters['resourcesModel/getResourceByUserId'](user.id)?.id;

    ganttIds.forEach(ganttId => {
      const accessToAllTask = rights.project.hasRight(ganttId, 'all_tasks');
      const projectData = getters.getItem(ganttId);
      let projectTasks = projectData?.tasks || [];

      if (!accessToAllTask) {
        projectTasks = projectTasks.filter(task => projectData.resourcesToTasks[task.id]?.find(o => o.resource_id === resourceId));
      }

      tasks.push(...projectTasks);
    });

    return tasks;
  },
  getTaskByGanttId: (state, getters) => (ganttId, taskId) => {
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return {};
    }

    return projectData.tasks.find(t => +t.id === +taskId) || {};
  },
  checkAccessToTask: (state, getters, rootState, rootGetters) => (taskId, projectId) => {
    const projectData = getters.getItem(projectId);
    const resourceId = rootGetters['resourcesModel/getResourceByUserId'](user.id)?.id;
    const accessToAllTask = rights.project.hasRight(projectId, 'all_tasks');

    if (!projectData) {
      return;
    }

    if (!accessToAllTask && !projectData.resourcesToTasks[taskId]?.find(o => o.resource_id === resourceId)) {
      return;
    }

    return true;
  },
  getLinkByGanttId: (state, getters) => (ganttId, linkId) => {
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return {};
    }

    return projectData.links.find(l => +l.id === +linkId) || {};
  },
  getSourceLinksByGanttIdAndTaskId: (state, getters) => (ganttId, taskId) => {
    const sourceLinks = [];
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return sourceLinks;
    }

    projectData.links.forEach(link => {
      if (+link.source === +taskId) {
        sourceLinks.push(link);
      }
    });

    return sourceLinks;
  },
  getTargetLinksByGanttIdAndTaskId: (state, getters) => (ganttId, taskId) => {
    const sourceLinks = [];
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return sourceLinks;
    }

    projectData.links.forEach(link => {
      if (+link.target === +taskId) {
        sourceLinks.push(link);
      }
    });

    return sourceLinks;
  },
  getMasterLinksByTaskId: (state, getters) => (ganttId, taskId) => {
    let taskLinks = [];
    let masterLinkData = {};
    const resultData = [];

    const taskData = getters.getTaskByGanttId(ganttId, taskId);

    if (taskData) {
      taskLinks = getters.getTargetLinksByGanttIdAndTaskId(ganttId, taskId);

      taskLinks.forEach(masterLink => {
        masterLinkData = getters.getLinkByGanttId(ganttId, masterLink.id);

        if (!masterLinkData || !masterLinkData.source || !getters.tasksByIds[masterLinkData.source]) {
          return;
        }

        resultData.push({
          linkId: masterLinkData.id,
          linkType: masterLinkData.type,
          dependencyTaskId: parseInt(masterLinkData.source, 10),
        });
      });
    }

    return resultData;
  },
  getSlaveLinksByTaskId: (state, getters) => (ganttId, taskId) => {
    let taskLinks = [];
    let slaveLinkData = {};
    const resultData = [];

    const taskData = getters.getTaskByGanttId(ganttId, taskId);

    if (taskData) {
      taskLinks = getters.getSourceLinksByGanttIdAndTaskId(ganttId, taskId);

      taskLinks.forEach(slaveLink => {
        slaveLinkData = getters.getLinkByGanttId(ganttId, slaveLink.id);

        if (!slaveLinkData || !slaveLinkData.source || !getters.tasksByIds[slaveLinkData.target]) {
          return;
        }

        resultData.push({
          linkId: slaveLinkData.id,
          linkType: slaveLinkData.type,
          dependencyTaskId: parseInt(slaveLinkData.target, 10),
        });
      });
    }

    return resultData;
  },

  getProjectsTasksMap(state) {
    const result = state.data.reduce((obj, item) => {
      obj[item.id] = item.tasks.reduce((map, item) => {
        map[item.id] = item;

        return map;
      }, {});

      return obj;
    }, {});

    return result;
  },
  getDataForBreadcrumbs: (state, getters) => (taskId, projectId, projectTasksMap) => {
    const keys = Object.keys(projectTasksMap);
    const preparedTasksMap = keys.length ? projectTasksMap : getters.getProjectsTasksMap[projectId];
    const task = preparedTasksMap[taskId];
    const result = [];

    if (!task) {
      return;
    }

    let parent = task.parent;

    while (parent) {
      const parentData = preparedTasksMap[parent];

      parent = parentData && parentData.parent;
      (parent && parent !== 1) && result.push(parentData);
    }

    return result.reverse();
  },

  // getDataForBreadcrumbsByParentId
  getDataForBreadcrumbsByParentId: (state, getters) => parentId => {
    if (!parentId) return;

    const result = [];

    while (parentId) {
      const parentData = getters.getTask(parentId);

      parentId = parentData && parentData.parent;
      parentId && (parentId !== 1) && result.push(parentData);
    }

    return result.reverse();
  },

  // getProjectsByActiveProject
  getParentTasksByGanttId: (state, getters) => ganttId => {
    const projectData = getters.getItem(ganttId);

    if (!projectData) {
      return [];
    }

    return projectData.tasks.filter(task => (task.type === constants.TASK_TYPES.project));
  },
  getTasksByResourceIdAndGanttIds: (state, getters) => (resourceId, ganttIds) => {
    const projectsData = getters.getNotArchivedData.filter(data => ganttIds.includes(+data.id));

    return _.flatten(
      projectsData.map(
        projectData => getters.getTasksByResourceIdAndGanttId(resourceId, projectData.id),
      ),
    );
  },
  getAllTasksByResourceId: (state, getters) => resourceId => {
    const projectsData = state.data.filter(o => o.is_archived !== 1);

    return _.flatten(
      projectsData.map(
        projectData => getters.getTasksByResourceIdAndGanttId(resourceId, projectData.id).map(o => {
          let accessToTasks = true;

          if (!rights.project.hasRight(projectData.id, 'all_tasks') || !rights.project.hasRight(projectData.id, 'static_fields')) {
            accessToTasks = false;
          }

          o._onlyForWorkloadCalculation = projectData._onlyForWorkloadCalculation || !accessToTasks;

          return o;
        }),
      ),
    );
  },
  getUnassignedTasksByGanttIds: (state, getters) => ganttIds => {
    const projectsData = getters.getNotArchivedData.filter(data => ganttIds.includes(+data.id));

    return projectsData.reduce((result, ganttData) => {
      let unassignedTasks = ganttData.tasks.filter(
        task => task.type !== constants.TASK_TYPES.project && !task.resources.length,
      );

      unassignedTasks = unassignedTasks.map(taskData => ({
        taskData,
        resource_id: null,
        task_id: taskData.id,
        gantt_id: taskData.gantt_id,
        value: 0,
        custom_days: [],
      }));

      return result.concat(unassignedTasks);
    }, []);
  },
  getTasksByResourceIdAndGanttId: (state, getters) => (resourceId, ganttId) => {
    const projectTasksData = getters.getItem(ganttId);

    if (!projectTasksData) {
      return [];
    }

    const tasksData = projectTasksData.tasks;
    const resourcesToTasks = projectTasksData.resourcesToTasks;
    const result = [];

    tasksData.forEach(task => {
      const assigned = _.find(resourcesToTasks[task.id], item => item.resource_id === +resourceId);

      if (assigned) {
        result.push({
          taskData: task,
          resource_id: resourceId,
          task_id: task.id,
          gantt_id: ganttId,
          value: assigned.value,
          custom_days: assigned.custom_days,
          id: assigned.id,
        });
      }
    });

    return result;
  },
  getDefaultCustomValues: (state, getters) => ganttId => {
    const projectData = getters.getItem(ganttId);

    return {
      status: projectData.defaultCustomValues.status,
      priority: projectData.defaultCustomValues.priority,
    };
  },
  getCustomValuesForTask: (state, getters) => (ganttId, taskId) => getters.getItem(ganttId)?.customValues[taskId],
  getResourcesForTask: (state, getters) => (ganttId, taskId) => {
    const projectData = getters.getItem(ganttId);

    return projectData && projectData.resourcesToTasks[taskId] || [];
  },
  getTotalEstimateDataForProject: (state, getters) => projectId => {
    const tasksData = getters.getTasksForGantt(projectId).data;
    let totalEstimate = {};

    _.some(tasksData, task => {
      if (task.type === 'project' && !task.parent) {
        totalEstimate = { ...task };

        return true;
      }
    });

    return totalEstimate;
  },
  getMaxAndMinDateForProject: (state, getters) => (projectId, periodFlexStep, setFirstDay) => {
    const tasksData = getters.getItem(projectId)?.tasks;
    const totalEstimateProjectId = getters.getTotalEstimateDataForProject(projectId).id;
    let startDate = {};
    let compareDate = {};
    let endDate = {};

    if (!tasksData || !tasksData.length) {
      return {
        maxDate: null,
        minDate: null,
      };
    }

    _.some(tasksData, task => {
      if (task.type === constants.TASK_TYPES.project && task.parent === totalEstimateProjectId) {
        startDate = dateFormatHelper.createNewDateByStringOrDateObject(task.start_date);
        endDate = dateFormatHelper.createNewDateByStringOrDateObject(task.end_date);

        return true;
      }
    });

    tasksData.forEach(taskData => {
      if (taskData.start_date) {
        compareDate = dateFormatHelper.createNewDateByStringOrDateObject(taskData.start_date);

        if (typeof startDate.valueOf() !== 'number' || startDate.valueOf() > compareDate.valueOf()) {
          startDate = compareDate;
        }
      }

      if (taskData.end_date) {
        compareDate = dateFormatHelper.createNewDateByStringOrDateObject(taskData.end_date);

        if (typeof endDate.valueOf() !== 'number' || endDate.valueOf() < compareDate.valueOf()) {
          endDate = compareDate;
        }
      }
    });

    startDate.setMonth(startDate.getMonth() - periodFlexStep);
    setFirstDay && startDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + periodFlexStep + 1);
    setFirstDay && endDate.setDate(0);

    return {
      maxDate: endDate,
      minDate: startDate,
    };
  },
  // getAllChildByTask
  getAllChildByTask: (state, getters) => (projectId, parentId) => {
    const tasksData = getters.getItem(projectId)?.tasks;
    let children = [];

    if (!parentId || !tasksData) {
      return [];
    }

    tasksData.forEach(task => {
      if (parseInt(task.parent, 10) === parentId) {
        children.push(task);

        if (task.type === constants.TASK_TYPES.project) {
          children = children.concat(getters.getAllChildByTask(projectId, task.id));
        }
      }
    });

    return children;
  },
  // getChildByTask
  getChildByTask: (state, getters) => (projectId, parentId) => {
    const tasksData = getters.getItem(projectId)?.tasks;
    const children = [];

    if (!parentId || !tasksData) {
      return [];
    }

    tasksData.forEach(task => {
      if (parseInt(task.parent, 10) === parentId) {
        children.push(task);
      }
    });

    return children;
  },
  // getAllTaskNamesAndIds
  getAllTaskNamesAndIds: (state, getters) => (projectId, parentData) => {
    if (!parentData) {
      parentData = getters.getTotalEstimateDataForProject(projectId);
    }

    return getters.getAllChildByTask(projectId, parentData.id).map(task => ({
      id: task.id,
      value: task.text,
    }));
  },
  // getAllTaskNamesAndIdsAndTypes: (state, getters) => (projectId, parentData) => {
  //   if (!parentData) {
  //     parentData = getters.getTotalEstimateDataForProject(projectId);
  //   }
  //
  //   return getters.getAllChildByTask(projectId, parentData.id).map(task => {
  //     return {
  //       id: task.id,
  //       name: task.text,
  //       type: task.type,
  //     };
  //   });
  // },
  // getAllTaskIds
  getAllTaskIds: (state, getters) => projectId => {
    const tasksData = getters.getItem(projectId)?.tasks;

    return tasksData?.map(task => task.id);
  },

  // filterTasksToUpdate()
  getFilteredTasksToUpdate: (state, getters) => (projectId, ganttData) => {
    const customizer = function (objValue, othValue) {
      const getDateUTCValue = function (dateString) {
        return gantt.date.convert_to_utc(new Date(dateString)).valueOf();
      };

      const isEqualDates = function (firstDate, secondDate) {
        return _.isEqual(getDateUTCValue(firstDate), getDateUTCValue(secondDate));
      };

      return isEqualDates(objValue.start_date, othValue.start_date) && isEqualDates(objValue.end_date, othValue.end_date) && _.isEqual(objValue.duration, othValue.duration);
    };

    const projectData = getters.getItem(projectId);
    const tasksModelDataMap = {};

    projectData?.tasks.forEach(task => {
      tasksModelDataMap[task.id] = task;
    });

    return ganttData.data.filter(task => {
      const taskInModel = tasksModelDataMap[task.id];

      if (task.type === constants.TASK_TYPES.task && task.duration === 0) {
        return false;
      }

      if (taskInModel) {
        const isEqualDates = _.isEqualWith(taskInModel, task, customizer);

        return !isEqualDates;
      }

      return false;
    });
  },

  // checkAccess()
  checkLinkAccess: (state, getters) => (projectId, source, target) => {
    let access = true;

    getters.getItem(projectId)?.links.forEach(item => {
      if (
        (+item.source === +source && +item.target === +target)
        || (+item.target === +source && +item.source === +target)) {
        access = false;
      }
    });

    return access;
  },

  getDefaultTaskValues: (state, getters) => taskData => {
    const projectData = getters.getItem(taskData.gantt_id);

    return helpers.prepareDefaultTaskValues(taskData, projectData.defaultCustomValues);
  },
  getDiff: (state, getters) => helpers.getDiff,
};
