var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { class: _vm.$style.container },
          [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.locales(
                      "integration_msteams_settings_page_table_input_loading"
                    )
                  )
              ),
            ]),
            _vm._v(" "),
            _c("loader"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }