<template>
  <div
    id="pricing-popup"
    class="tooltip-container pricing-mark"
    :class="customCssClass"
    :style="{
      top: coordinates.top,
      bottom: coordinates.bottom,
      left: coordinates.left,
      right: coordinates.right,
    }"
    @mouseover="addBorderAnchor"
    @mouseout="removeBorderAnchor"
  >
    <div
      class="tooltip-body"
    >
      <div class="pricing-popup-header pricing-mark">
        <div class="header-start">
          <div class="popup-header-name">
            {{ getTitleLocale(config.feature) }}
          </div>
          <div
            v-if="isTitle"
            class="pricing-title-container"
          >
            {{ locales(getPricingTitle(config.feature)) }}
          </div>
        </div>
        <div
          class="close_btn"
          @click="onClickClose"
        >
          <icon-button
            class="pricing-tooltip-closer"
            :icon="{ name:'close-1', size: 20 }"
            size="small"
          />
        </div>
      </div>
      <div
        v-if="isGif"
        class="pricing-popup-main pricing-mark"
      >
        <LottieAdapter
          v-if="path"
          :path="path"
        />
      </div>
      <div
        :class="[isGif ? '' : 'pricing-popup-text-margin', 'pricing-popup-text-part']"
      >
        <div class="pricing-popup-text">
          {{ getDescriptionTitleLocale(config.feature) }}
          <div class="learn_more">
            <a
              class="pricing-popup-link"
              :href="locales(getLink(config.feature))"
              target="_blank"
              @click="linkClick"
            >{{ locales('help_read_more') }}</a>
            <webix-ui
              class="arrow-left"
              :config="icons.arrowLeft"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isOwner"
        class="pricing-popup-button"
        :style="isDisableBtn ? {'pointer-events': 'none', 'opacity': '.5'} : {}"
      >
        <div
          class="popup-button-link"
          @click="upgradeClick"
        >
          {{ locales('upgrade_rate_btn') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LottieAdapter from './LottieAdapter.vue';
import iconClose from '../../svg/ic_close.svg';
import svgIconForVue from '../common/svgIconForVue';
import { getPlans, getPlansObj } from '../../helpers/pricingHelper';
import constants from '../../helpers/constants';
import plansModel from '../../models/plans';
import arrowLeft from '../../svg/pricing/ic_arrow_left.svg';
import paymentInternal from '../payment/paymentInternal';
import app from '../../app';
import IconButton from '../common/VueComponents/globalButton/withIcon/iconButton.vue';
import rights from "../../components/rights";

export default {
  name: 'PricingPopup',
  components: { LottieAdapter, IconButton },
  props: {
    config: { type: Object, required: false, default: null },
  },
  data() {
    return {
      icons: {
        close: svgIconForVue(iconClose, 24, 24),
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
      },
      locales: __,
      tariff: null,
      coordinates: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      },
      isDisableBtn: false,
    };
  },
  computed: {
    customCssClass() {
      return this.config.class ? { [this.config.class]: true } : '';
    },
    isGif() {
      return constants.PRICING_FEATURES[this.config.feature].gif;
    },
    isTitle() {
      return constants.PRICING_FEATURES[this.config.feature].title;
    },
    path() {
      return `/views/tooltip/lottie/${this.config.feature}.json`;
    },
    isOwner() {
      return rights.account.isOwner();
    },
  },
  watch: {
    config() {
      this.getCoordinates();
    },
  },
  beforeDestroy() {
    this.tariff = null;
  },
  mounted() {
    const feature = this.config.feature;

    userExtAnalytics.log('pricing_deadlock_reached', { type: feature });
    this.getCoordinates();

    if ([2, 10, 11].includes(user.subscription.status_code) || plansModel.isOverSubscription()) {
      this.isDisableBtn = true;
    }
  },
  methods: {
    getTitleLocale(feature) {
      return __(`${feature}_tooltip_title`);
    },
    getDescriptionTitleLocale(feature) {
      return __(`${feature}_tooltip_description`);
    },
    getPricingTitle(feature) {
      if (feature === 'time_log') {
        feature = 'time_tracking';
      }
      if (feature === 'estimation_assign') {
        feature = 'estimation';
      }
      if (feature === 'workload_estimation_duration' || feature === 'workload_estimation') {
        feature = 'workload';
      }

      return `${getPlans(feature)}_plan_locale`;
    },
    getLink(feature) {
      if (feature === 'estimation_assign') {
        feature = 'estimation';
      }

      return `${feature}_learn_more`;
    },
    onClickClose() {
      this.removeBorderAnchor();
      this.$store.commit('tooltip/showPricingPopup', false);
      if (this.config.feature !== 'custom_fields') {
        document.querySelector('.popup_shadow') && document.querySelector('.popup_shadow').classList.remove('show');
      }
    },
    getCoordinates() {
      const coordinates = this.config.target;
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const tooltipHeight = document.getElementsByClassName('tooltip-container')[0].offsetHeight;
      const tooltipWidth = 330;
      const position = this.config.position;
      const middlePx = 3;

      let left;
      let top;

      switch (position) {
      case 'top':
        if ((clientHeight - coordinates.bottom - middlePx - 10) < tooltipHeight) {
          top = `${coordinates.top - tooltipHeight - middlePx}px`;
        } else {
          top = `${coordinates.bottom + middlePx}px`;
        }
        if ((clientWidth - coordinates.left - 20) < tooltipWidth) {
          left = `${coordinates.left - tooltipWidth + coordinates.width}px`;
        } else {
          left = `${coordinates.left}px`;
        }

        this.coordinates = {
          top,
          left,
        };
        break;

      case 'left':
        if ((clientHeight - coordinates.bottom) < tooltipHeight) {
          this.coordinates = {
            top: `${coordinates.bottom - tooltipHeight}px`,
            left: `${coordinates.left - tooltipWidth - middlePx}px`,
          };
        } else {
          this.coordinates = {
            top: `${coordinates.top}px`,
            left: `${coordinates.left - tooltipWidth - middlePx}px`,
          };
        }
        break;

      case 'right':
        if ((clientHeight - coordinates.bottom) < tooltipHeight) {
          this.coordinates = {
            left: `${coordinates.right + middlePx}px`,
            top: `${coordinates.bottom - tooltipHeight}px`,
          };
        } else {
          this.coordinates = {
            left: `${coordinates.right + middlePx}px`,
            top: `${coordinates.top}px`,
          };
        }
        break;

      case 'bottom':
        if ((coordinates.top - middlePx - 10) < tooltipHeight) {
          top = `${coordinates.bottom + middlePx}px`;
        } else {
          top = `${coordinates.top - tooltipHeight - middlePx}px`;
        }
        if ((clientWidth - coordinates.left - 20) < tooltipWidth) {
          left = `${coordinates.left - tooltipWidth + coordinates.width}px`;
        } else {
          left = `${coordinates.left}px`;
        }

        this.coordinates = {
          top,
          left,
        };
        break;
      }
    },
    upgradeClick() {
      let feature = this.config.feature;

      userExtAnalytics.log('pricing_deadlock_upgrade_clicked', { type: feature });

      if (feature === 'time_log') {
        feature = 'time_tracking';
      }
      if (feature === 'estimation_assign') {
        feature = 'estimation';
      }
      if (feature === 'workload_estimation_duration' || feature === 'workload_estimation') {
        feature = 'workload';
      }
      const newPlanObj = getPlansObj(feature);
      const userPlanId = user.accountPlanData.payment_plans_id;
      const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

      if (this.config.feature !== 'custom_fields') {
        document.querySelector('.popup_shadow') && document.querySelector('.popup_shadow').classList.remove('show');
      }
      this.$store.commit('tooltip/showPricingPopup', false);
      this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'upgrade_plan_popup_title',
        currentPlan: userPlan,
        newPlan: newPlanObj,
        // userCount: user.subscription.user_count,
        isAnnual: user.subscription.is_annual,
      });
      this.removeBorderAnchor();
      if (feature === 'workload') {
        app.trigger('closeWindowWithAnimation');
      }
    },
    linkClick() {
      const feature = this.config.feature;

      userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: feature });

      this.$store.commit('tooltip/showPricingPopup', false);
      if (this.config.feature !== 'custom_fields') {
        document.querySelector('.popup_shadow') && document.querySelector('.popup_shadow').classList.remove('show');
      }
      this.removeBorderAnchor();
    },
    addBorderAnchor() {
      let anchorEl = '';

      this.config.additionalDif ? anchorEl = document.querySelector(`[data-feature='${this.config.feature}'][data-additional-dif='${this.config.additionalDif}']`)
        : anchorEl = document.querySelector(`[data-feature='${this.config.feature}']`);
      anchorEl?.classList.add('pricing-border');
    },
    removeBorderAnchor() {
      let anchorEl = '';

      this.config.additionalDif ? anchorEl = document.querySelector(`[data-feature='${this.config.feature}'][data-additional-dif='${this.config.additionalDif}']`)
        : anchorEl = document.querySelector(`[data-feature='${this.config.feature}']`);
      anchorEl?.classList.remove('pricing-border');
    },
  },
};
</script>
<style scoped src='./tooltips.less'></style>
<style lang="less">
.close_btn{
  &:hover{
    .webix_view{
      cursor: pointer;
    }
  }
}
</style>
