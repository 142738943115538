<template>
  <div :class="[$style.date_input]">
    <div :class="[$style.input_label]">
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="isHasSelected"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        @click="resetSelected"
      >
        {{ reset_locale }}
      </div>
    </div>

    <div
      :id="gpAutotestName"
      ref="inputField"
      :class="[
        $style.input_field,
        isHasSelected || isShowCalendar ? $style.active : '',
      ]"
      @click="inputClick"
    >
      <div v-if="isHasSelected">
        <span> {{ stringSelected }} </span>
      </div>
      <div v-else>
        {{ placeholder }}
      </div>

      <webix-ui
        :class="[$style.date_input_calendar_icon]"
        :config="calendar_icon"
      />
    </div>
  </div>
</template>

<script>
import svgIconForVue from '../../../common/svgIconForVue';
import calendar_icon from '../../../../svg/filter/calendar.svg';
import dateHelper from '../../../../helpers/dateFormats';
import app from '../../../../app';

export default {
  name: 'DataRangeInput',
  props: {
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
    placeholder: { type: String, required: false, default: 'placeholder' },
    label: { type: String, required: false, default: 'label' },
    value: { type: Object, required: false, default: null },
    rangeOnly: { type: Boolean, required: false, default: false },
    property: { type: String, required: false, default: '' },
  },

  data() {
    return {
      reset_locale: __('common_reset'),
      calendar_icon: svgIconForVue(calendar_icon, 24, 24),
      selectedDate: { start: null, end: null, period: null },
      isShowCalendar: false,
      calendarHeight: 414,
      blockEmit: false,
    };
  },

  computed: {
    componentKey() {
      const min = 1000;
      const max = 1000000;
      const random = Math.floor(Math.random() * (max - min)) + min;

      return `${new Date().getTime()}-${random}`;
    },
    isOpenedFilter() {
      return this.$store.getters['filter/isOpen'];
    },
    isHasSelected() {
      return this.selectedDate.start || this.selectedDate.period;
    },

    stringSelected() {
      if (this.selectedDate.period) {
        return __(`datepiker_${this.selectedDate.period}`);
      }

      if (this.selectedDate.start) {
        let text = this.format(this.selectedDate.start);

        if (this.selectedDate.end) text = `${text} - ${this.format(this.selectedDate.end)}`;

        return text;
      }

      return '';
    },

    allowRangeOnly() {
      const excludedProperty = ['endDate', 'startDate', 'creationDate'].includes(this.property);

      if (excludedProperty) return false;

      const mode = ['gantt', 'list', 'board'].includes(this.$route.params.mode);

      return mode;
    },

  },

  watch: {
    isShowCalendar(val) {
      if (val) {
        this.openDatePicker();
      } else if (this.rangeOnly && !this.selectedDate.end) {
        this.closeDatePicker();
      }
    },
    isOpenedFilter(val) {
      if (!val) {
         this.closeDatePicker();
      }
    },
    value(newVal, oldVal) {
      this.blockEmit = true;
      this.setSelectedDate('');
      this.setSelectedDate(newVal);
      this.blockEmit = false;
    },
  },

  mounted() {
    this.addListheners();
    const component = this;

    this.selectedDate = this.prepareValues(this.value);

    this.popupId = `popupDatePicker_${this.componentKey}`;
    this.calendarId = `datepickerRange_${this.componentKey}`;

    function on_click() {
      const value = component.stringValueToDates(this.period);

      value.period = this.period;
      $$(component.calendarId).setValue(value);
    }

    const datepickerConfig = webix.ui({
      view: 'popupWithoutPointCustom',
      id: component.popupId,
      css: 'customDateRangePicker suggest_popup customDateRangeSuggest filter-datepicker',
      hidden: true,
      body: {
        view: 'customDateRange',
        css: 'customDateRange',
        id: component.calendarId,
        calendarCount: 2,
        calendar: {
          height: 300,
          cellHeight: 24,
        },
        value: component.selectedDate,
        timepicker: false,
        icons: [
          {
            text: __('datepiker_today'),
            period: 'today',
            on_click() {
              on_click.bind(this)();
            },
          },
          {
            text: __('datepiker_yesterday'),
            period: 'yesterday',
            on_click() {
              on_click.bind(this)();
            },
          },
          {
            text: __('datepiker_this_week'),
            period: 'this_week',
            on_click() {
              on_click.bind(this)();
            },
          },
          {
            text: __('datepiker_last_week'),
            period: 'last_week',
            on_click() {
              on_click.bind(this)();
            },
          },
          {
            text: __('datepiker_this_month'),
            period: 'this_month',
            on_click() {
              on_click.bind(this)();
            },
          },
          {
            text: __('datepiker_last_month'),
            period: 'last_month',
            on_click() {
              on_click.bind(this)();
            },
          },
        ],
        on: {
          onChange(newValue) {
            if (_.isNull(newValue)) {
              return;
            }

            if (!newValue.end && newValue.period) {
              newValue.period = null;
              const icons_btn = this.getNode().querySelector(
                '.webix_cal_header_button.active',
              );

              icons_btn && icons_btn.classList.remove('active');
              this.setValue(newValue);
            }

            if (!newValue || !newValue.period) {
              const icons_btn = this.getNode().querySelector(
                '.webix_cal_header_button.active',
              );

              icons_btn && icons_btn.classList.remove('active');
            }

            component.setSelectedDate(newValue);
          },
        },
      },
      on: {
        onHide() {
          if (!component.selectedDate) {
            component.selectedDate = {};
          }

          const resetData = component.allowRangeOnly && (!component.selectedDate.start || !component.selectedDate.end);

          if (resetData) {
            component.selectedDate = {};
          }

          $$(component.calendarId).setValue(component.selectedDate);
          component.isShowCalendar = false;
        },
      },
    });

    datepickerConfig.$scope = this;
    webix.ui(datepickerConfig);
  },

  beforeDestroy() {
    this.removeListheners();
    webix.$$(this.popupId).destructor();
  },

  methods: {
    onScroll(e) {
      this.closeDatePicker();
    },

    addListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.onScroll);
      });
    },
    removeListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.removeEventListener('scroll', this.onScroll);
      });
    },

    setSelectedDate(values) {
      if (this.isSameRange(values)) return;

      this.selectedDate = this.prepareValues(values);

      if (!values || (values && !values.start && !values.end && !values.period)) {
        !this.blockEmit && this.$emit('reset', null);
        $$(this.calendarId).setValue('');

        return;
      }

      const canEmitValue = (this.allowRangeOnly && (this.selectedDate.start && this.selectedDate.end))
                      || !this.allowRangeOnly;

      if (canEmitValue) {
        !this.blockEmit && this.$emit('change', this.selectedDate);
      }

      $$(this.calendarId).setValue(this.selectedDate);
    },

    isSameRange(newRange) {
      let isSameStart = false;
      let isSameEnd = false;
      let isSamePeriod = false;

      if (newRange) {
        isSameStart = (!newRange.start && !this.selectedDate.start) || (newRange.start === this.selectedDate.start);
        isSameEnd = (!newRange.end && !this.selectedDate.end) || (newRange.end === this.selectedDate.end);
        isSamePeriod = newRange.period && newRange.period === this.selectedDate.period;
      } else {
        isSameStart = !this.selectedDate.start;
        isSameEnd = !this.selectedDate.end;
      }

      const isSameRange = isSameStart && isSameEnd;

      return isSamePeriod || isSameRange;
    },

    resetSelected() {
      this.setSelectedDate(null);
    },

    prepareValues(val) {
      let res = {};

      if (!val) return '';

      if (val.period) {
        res = this.stringValueToDates(val.period);
        res.period = val.period;

        return res;
      }

      res.start = ('start' in val) ? val.start : '';
      res.end = ('end' in val) ? val.end : '';

      return res;
    },

    openDatePicker() {
      const clientHeight = document.documentElement.clientHeight;
      const inputCoord = this.$el.getBoundingClientRect();
      const popup = $$(this.popupId);
      const isTop = inputCoord.top + 2 > this.calendarHeight;
      const isBottom = clientHeight - inputCoord.bottom + 2 > this.calendarHeight;
      const popupX = popup.$width - inputCoord.width;

      if (!isTop && isBottom) {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'bottom', x: app.config.mode.isExport ? -popupX : null });
      } else {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'top', x: app.config.mode.isExport ? -popupX : null });
      }

      this.blockEmit = true;
      this.setSelectedDate(this.selectedDate);
      this.blockEmit = false;
    },

    format(date) {
      const dateFormat = dateHelper.getDateFormat();

      return webix.Date.dateToStr(dateFormat)(date);
    },

    stringValueToDates(period_name) {
      const results = {};

      switch (period_name) {
      case 'today':
        results.start = webix.Date.datePart(new Date());
        results.end = new Date(
          webix.Date.datePart(webix.Date.add(new Date(), 1, 'day')) - 1,
        );
        break;
      case 'yesterday':
        results.start = webix.Date.datePart(
          webix.Date.add(new Date(), -1, 'day'),
        );
        results.end = new Date(webix.Date.datePart(new Date()) - 1);
        break;
      case 'this_week':
        results.start = webix.Date.weekStart(new Date());
        results.end = new Date(
          webix.Date.weekStart(webix.Date.add(new Date(), 1, 'week')) - 1,
        );
        break;
      case 'last_week':
        results.start = webix.Date.weekStart(
          webix.Date.add(new Date(), -1, 'week'),
        );
        results.end = new Date(
          webix.Date.add(webix.Date.copy(results.start), 1, 'week') - 1,
        );
        break;
      case 'this_month':
        results.start = webix.Date.monthStart(new Date());
        results.end = new Date(
          webix.Date.monthStart(webix.Date.add(new Date(results.start), 1, 'month')) - 1,
        );
        break;
      case 'last_month':
        results.start = webix.Date.monthStart(
          webix.Date.add(new Date(), -1, 'month'),
        );
        results.end = new Date(
          webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1,
        );
        break;
      case 'this_month_workload':
        results.start = webix.Date.monthStart(new Date());
        results.end = new Date(
          webix.Date.monthStart(webix.Date.add(new Date(esults.start), 1, 'month')) - 1,
        );
        break;
      case 'last_month_workload':
        results.start = webix.Date.monthStart(
          webix.Date.add(new Date(), -1, 'month'),
        );
        results.end = new Date(
          webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1,
        );
        break;
      case 'twelve_months_workload':
        results.start = webix.Date.add(new Date(), -4, 'month');
        results.end = webix.Date.add(new Date(), 8, 'month');
        break;
      case 'six_months_workload':
        results.start = webix.Date.add(new Date(), -2, 'month');
        results.end = webix.Date.add(new Date(), 4, 'month');
        break;
      case 'three_months_workload':
        results.start = webix.Date.add(new Date(), -1, 'month');
        results.end = webix.Date.add(new Date(), 2, 'month');
        break;
      default:
        console.error('can not find period alias');
      }

      return results;
    },
    svgForVue(icon) {
      return svgIconForVue(icon, 17, 17);
    },
    inputClick() {
      this.isShowCalendar = !this.isShowCalendar;
    },

    closeDatePicker() {
      $$(this.popupId).hide();
    },
  },
};
</script>

<style module src="../style.less" lang="less"></style>
