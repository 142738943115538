<template>
  <div
    :class="[
      'header_workload_range',
      isShowCalendar ? 'active' : '',
    ]"
  />
</template>

<script>
import app from '../../../app';
import dateHelper from '../../../helpers/dateFormats';
import _ from '../../../libs/lodash';

export default {
  data() {
    return {
      isShowCalendar: false,
      range: {},
    };
  },
  mounted() {
    const component = this;
    const config = webix.copy({
      view: 'customDateRangePicker',
      id: 'workloadRange',
      css: 'customDateRangePicker',
      width: 205,
      inputHeight: 30,
      label: __('workload_range'),
      icon: 'custom-icon',
      format(date) {
        return webix.Date.dateToStr(dateHelper.getDateFormat())(date);
      },
      value: {},
      suggest: {
        view: 'customDateRangeSuggest',
        css: 'customDateRangeSuggest workload_page',
        body: {
          calendarCount: 2,
          calendar: {
            height: 300,
            cellHeight: 24,
          },
          timepicker: false,
          icons: [
            {
              text: __('datepicker_last_month_workload'),
              period: 'last_month_workload',
              on_click() {
                $$('workloadRange').setValue({ period: this.period });
              },
            },
            {
              text: __('datepicker_this_month_workload'),
              period: 'this_month_workload',
              on_click() {
                $$('workloadRange').setValue({ period: this.period });
              },
            },
            {
              text: __('datepicker_three_months_workload'),
              period: 'three_months_workload',
              on_click() {
                $$('workloadRange').setValue({ period: this.period });
              },
            },
            {
              text: __('datepicker_six_months_workload'),
              period: 'six_months_workload',
              on_click() {
                $$('workloadRange').setValue({ period: this.period });
              },
            },
            {
              text: __('datepicker_twelve_months_workload'),
              period: 'twelve_months_workload',
              on_click() {
                $$('workloadRange').setValue({ period: this.period });
              },
            },
          ],
        },
        on: {
          onShow() {
            component.isShowCalendar = true;
          },
          onHide() {
            component.isShowCalendar = false;

            const picker = $$('workloadRange');
            const range = picker.getValue();

            if (range.start && !range.end) {
              let oldRange = component.range;

              if (component.range.period) {
                oldRange = { period: component.range.period };
              }

              picker.blockEvent();
              picker.setValue(oldRange);
              picker.unblockEvent();
            }
          },
        },
      },
      on: {
        onChange(newValue) {
          if (_.isNull(newValue) || (newValue.start && !newValue.end) || _.isEqual(newValue, component.range)) {
            return;
          }

          component.range = newValue;

          app.trigger('init:workload', newValue);
        },
        afterUpdateValue() {
          component.range = $$('workloadRange').getValue();
        },
      },
    });

    config.$scope = this;

    if ($$(config.id)) {
      webix.$$(config.id).destructor();
    }
    webix.ui(config, this.$el);
    $$(config.id).setValue(this.value);
    this.webixId = config.id;
  },
  destroyed() {
    $$(this.webixId) && webix.$$(this.webixId).destructor();
  },
};
</script>

<style src="../less/workload.less"></style>
