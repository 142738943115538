import constants from '../../../helpers/constants';

function validateProgress(value) {
  value = (Math.round(value * 100) || 0) / 100;
  value = value > 1 ? 1 : value;
  value = value < 0 ? 0 : value;

  return value;
}

function validateDuration(val, old_val) {
  let value = Math.round(val);
  const limit = constants.DURATION_LIMITS.minute;

  value = value < 1 ? old_val : value;
  value = value > limit ? limit : value;

  value = +value || old_val;

  return value;
}

function validateEndtDate(val, oldTask) {
  const end = new Date(val).getTime();
  const start = new Date(oldTask.start_date).getTime();

  return end < start ? oldTask.end_date : val;
}

export function validateTaskValue(value, field, oldTask) {
  const old_value = oldTask[field];

  if (field === 'progress') return validateProgress(value);
  if (field === 'duration') return validateDuration(value, old_value);
  if (field === 'end_date') return validateEndtDate(value, oldTask);

  return value;
}
