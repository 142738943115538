var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("context-menu", {
    attrs: { position: _vm.positionContext },
    on: {
      closePopup: function ($event) {
        return _vm.$emit("close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("ul", { class: _vm.$style.menu }, [
              _c("li", { class: _vm.$style.menu__item }, [
                _c(
                  "label",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("svg-sprite", {
                      attrs: { name: "project", type: "regular" },
                    }),
                    _vm._v(" "),
                    _c("span", { class: _vm.$style.menu__item_text }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locales("task_settings_upload_desctop")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "file", hidden: "", multiple: "" },
                      on: { change: _vm.handleFilesUpload },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: _vm.$style.menu__item,
                  on: {
                    mouseenter: _vm.showSubmenu,
                    mouseleave: function ($event) {
                      _vm.submenuPositionContext = null
                    },
                  },
                },
                [
                  _c("svg-sprite", {
                    attrs: { name: "google-drive", type: "color-regular" },
                  }),
                  _vm._v(" "),
                  _c("span", { class: _vm.$style.menu__item_text }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("task_settings_google_drive_text")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("svg-sprite", {
                    class: _vm.$style["arrow-right-icon"],
                    attrs: { name: "arrow-right", type: "regular" },
                  }),
                  _vm._v(" "),
                  _vm.submenuPositionContext
                    ? _c("context-menu", {
                        attrs: {
                          position: _vm.submenuPositionContext,
                          side: "right",
                          offset: { right: 1, top: -5 },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c(
                                    "ul",
                                    {
                                      class: [
                                        _vm.$style.menu,
                                        _vm.$style.submenu,
                                      ],
                                    },
                                    [
                                      _vm._l(
                                        _vm.googleDriveActions,
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              class: _vm.$style.menu__item,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSubmenuItemClick(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.$style.menu__item_text,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.label) +
                                                      "\n                  "
                                                  ),
                                                  item.labelBold
                                                    ? _c(
                                                        "b",
                                                        {
                                                          attrs: {
                                                            title:
                                                              item.labelBold,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                item.labelBold
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          class: _vm.$style.menu__item_divider,
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.locales(
                                                  "settings_google_drive_create_new"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.googleNewFileItems,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              class: _vm.$style.menu__item,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createNewGoogleFile(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-sprite", {
                                                attrs: {
                                                  name: item.icon,
                                                  type: "color-regular",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.$style.menu__item_text,
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.label) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2149068419
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: _vm.$style.menu__item,
                  on: {
                    mouseenter: _vm.showOneDriveSubmenu,
                    mouseleave: function ($event) {
                      _vm.submenuOneDrivePositionContext = null
                    },
                  },
                },
                [
                  _c("svg-sprite", {
                    attrs: { name: "one-drive", type: "color-regular" },
                  }),
                  _vm._v(" "),
                  _c("span", { class: _vm.$style.menu__item_text }, [
                    _vm._v(_vm._s(_vm.locales("task_settings_one_drive_text"))),
                  ]),
                  _vm._v(" "),
                  _c("svg-sprite", {
                    class: _vm.$style["arrow-right-icon"],
                    attrs: { name: "arrow-right", type: "regular" },
                  }),
                  _vm._v(" "),
                  _vm.submenuOneDrivePositionContext
                    ? _c("context-menu", {
                        attrs: {
                          position: _vm.submenuOneDrivePositionContext,
                          offset: { right: 1, top: -7 },
                          side: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c(
                                    "ul",
                                    {
                                      class: [
                                        _vm.$style.menu,
                                        _vm.$style.submenu,
                                      ],
                                    },
                                    _vm._l(
                                      _vm.oneDriveActions,
                                      function (item) {
                                        return _c(
                                          "li",
                                          {
                                            key: item.id,
                                            class: _vm.$style.menu__item,
                                            on: { click: item.handler },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.$style.menu__item_text,
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.label) +
                                                    "\n                  "
                                                ),
                                                item.labelBold
                                                  ? _c(
                                                      "b",
                                                      {
                                                        attrs: {
                                                          title: item.labelBold,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              item.labelBold
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3120815380
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }