import dateHelper from "./../../../helpers/dateFormats";
import ExcelJS from "../../exceljs/dist/exceljs";

const __ = window.__;

const innerSettings = {
  prepareWorkbook: []
};

webix.protoUI({
  name: "importselect",
  $cssName: "richselect",
  $setValue: function (value) {
    var popup = this.getPopup();

    this.getInputNode().innerHTML = popup ? popup.setValue(value) : "";
  }
}, webix.ui.richselect);

webix.protoUI({
  name: "importsuggest",
  defaults: {
    type: "menu",
    height: 442,
    body: {
      view: "menu",
      yCount: 15,
      layout: "y",
      subMenuPos: "right",
      select: false,
      scroll: true,
      template: function (obj) {
        if (obj.icon) {
          return "<span style='opacity:0.7;width:25px;' class='webix_icon fa-" + obj.icon + "'></span>" + obj.value;
        }

        return obj.value;
      }
    }
  },

  // init
  $init: function () {
    this.$ready.push(this._attachHandlers);
  },

  _attachHandlers: function () {
    var menu = this.getBody();

    menu.attachEvent("onMenuItemClick", webix.bind(function (id) {
      var item = menu.getMenuItem(id);

      if (!item.submenu) {
        this.setMasterValue(item, null, "menu");
      }
    }, this));
  },

  setMasterValue: function (data, refresh, mode) {
    if (!mode) {
      return;
    } //cancel default onItemClick behaviour

    if (this.config.master) {
      var master = webix.$$(this.config.master);

      if (master.options_setter) {
        master.setValue(data.id);
      } else {
        master.setValue(data.text || data.value);
      }
    }

    if (!refresh) {
      this.hide(true);
    }

    webix.delay(function () {
      webix.callEvent("onEditEnd", []);
    });
  },

  setValue: function (value) {
    if (!value) {
      return "";
    }

    var parent = this.getList().getMenuItem(value).parent,
      prefix = parent ? this.getList().getMenuItem(parent).value + ":" : "";

    return prefix + this.getList().getMenuItem(value).value;
  }
}, webix.ui.suggest);

/*control to suggest datatable column title*/
webix.ui.datafilter.importSelect = webix.extend({

  refresh: function (master, node, value) {
    var select = webix.$$(value.richselect);

    node.$webix = value.richselect;
    node.style.marginLeft = "-10px";

    master.registerFilter(node, value, this);

    //if (value.value) {
    //this.setValue(node, value.value);
    //}

    node.firstChild.appendChild(select.$view.parentNode);
    select.render();

    webix.delay(select.resize, select);
  },

  render: function (master, config) {
    if (!config.richselect) {
      var d = webix.html.create("div", {"class": "webix_richfilter"}),
        richConfig = {
          container: d,
          view: this.inputtype,
          css: "import_not_used",
          suggest: config.suggest
        },
        richSelect = webix.ui(richConfig);

      richSelect.attachEvent("onChange", function (newv, oldv) {
        var node = this.getInputNode();

        node.style.backgroundColor = newv === "not_used" ? "#ddd" : "#fff";
        master.getColumnConfig(config.columnId).import_id = newv;
        master.callEvent("onImportSelectChange", [config.columnId, newv, oldv]);
      });

      config.richselect = richSelect.config.id;

      if (!master.destroy_with_me) {
        master.destroy_with_me = [];
      }

      master.destroy_with_me.push(richSelect);
    } else {
      webix.delay(function () {
        master.blockEvent();
        $$(config.richselect).callEvent("onChange", [$$(config.richselect).getValue()]);
        master.unblockEvent();
      });
    }

    //$$(config.suggest.id).getNode().style.height = "auto";
    config.css = "webix_div_filter";
    return " ";
  },

  inputtype: "importselect"
}, webix.ui.datafilter.richSelectFilter);

/*control to suggest data format for a datatable column*/
webix.ui.datafilter.importTextFilter = webix.extend({
  refresh: function (master, node, value) {
    var field = webix.$$(value.input);

    node.$webix = value.input;
    node.style.marginLeft = "-10px";

    master.registerFilter(node, value, this);

    if (value.value) {
      this.setValue(node, value.value);
    }

    node.firstChild.appendChild(field.$view.parentNode);
    field.render();

    webix.delay(field.resize, field);
  },

  render: function (master, config) {
    if (!config.input) {
      var d = webix.html.create("div", {"class": "webix_textfilter"}),
        inputConfig = {
          container: d,
          view: this.inputtype,
          suggest: config.suggest,
          placeholder: config.placeholder
        },
        input = webix.ui(inputConfig);

      input.attachEvent("onChange", function (newv, oldv) {
        master.getColumnConfig(config.columnId).date_format = newv;
        master.callEvent("onImportTextChange", [config.columnId, newv, oldv]);
      });

      //not so good

      input.attachEvent("onItemClick", function () {
        var popup = $$(this.config.suggest);

        popup.config.master = input.config.id;

        if (popup.isVisible()) {
          popup.hide();
        } else {
          popup.show(this.$view);
        }
      });

      config.input = input.config.id;

      if (!master.destroy_with_me) {
        master.destroy_with_me = [];
      }

      master.destroy_with_me.push(input);
    }

    config.css = "webix_div_text_filter";
    $$(config.input).focus();

    return " ";
  },

  inputtype: "text"
}, webix.ui.datafilter.richSelectFilter);

/*control to suggest data format for a datatable column*/
webix.ui.datafilter.importSelectFilter = webix.extend({
  refresh: function (master, node, value) {
    var select = webix.$$(value.richselect),
      currentValue = value.suggest.getValue();

    node.$webix = value.richselect;
    node.style.marginLeft = "-10px";

    master.registerFilter(node, value, this);

    if (currentValue) {
      this.setValue(node, currentValue);
    } else {
      this.setValue(node, value.suggest.config.value);
    }

    node.firstChild.appendChild(select.$view.parentNode);
    select.render();

    webix.delay(select.resize, select);
  },

  render: function (master, config) {
    if (!config.input) {
      var d = webix.html.create("div", {"class": "webix_richfilter"}),
        inputConfig = {
          container: d,
          view: this.inputtype,
          suggest: config.suggest,
          placeholder: config.placeholder
        },
        input = webix.ui(inputConfig);

      input.attachEvent("onChange", function (newv, oldv) {
        master.getColumnConfig(config.columnId).duration = newv;
      });

      input.attachEvent("onAfterRender", webix.once(function () {
        this.getPopup().getList().data.each(function (obj) {
          if (obj.default) {
            config.default_value = obj.id;
          }
        });
      }));

      config.richselect = input.config.id;
    }

    config.css = "webix_div_duration_filter";

    return " ";
  }

}, webix.ui.datafilter.richSelectFilter);

webix.protoUI({
  name: "excelparser",
  defaults: {
    height: 38,
    borderless: true,
    padding: 5
  },

  $init: function (config) {
    var upload_config = {"type": "file", "class": "webix_hidden_upload", tabindex: -1},
      upload = webix.html.create("input", upload_config);

    this.$view.appendChild(upload);
    this.$view.firstChild.className = "webixbutton webix_button_raised";

    webix.event(this.$view, "click", webix.bind(function (e) {
      upload.value = "";
      this.callEvent("onFileDialog");
    }, this));

    webix.event(upload, 'change', webix.bind(function (e) {
      this.callEvent("onChange", [e.target.files]);
    }, this));

    this.attachEvent("onChange", webix.bind(function (files) {
      this.getExcelData(files);
    }, this));

    this.attachEvent("onFileDialog", webix.bind(function () {
      this.fileDialog();
    }, this));

    this.$ready.push(this._initPopup);

    this.attachEvent("onDestruct", function () {
      if (this._destroy_with_me) this._destroy_with_me.destructor();
    });
  },

  fileDialog: function () {
    var inputs = this.$view.getElementsByTagName("INPUT");

    inputs[inputs.length - 1].click();
  },

  getExcelData: function (files) {
    var i, f,
      parser = this;

    for (i = 0, f = files[i]; i != files.length; ++i) {
      let name = f.name;
      let ext = name.split(".")[name.split(".").length - 1];

      if (ext == "xlsx") {
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(f.file);

        reader.onload = () => {
          const buffer = reader.result;

          wb.xlsx.load(buffer).then(workbook => {
            if (workbook._worksheets.filter(sheet => sheet._rows.length).length > 1) {
              const prepareSheetNames = [];

              innerSettings.prepareWorkbook = workbook._worksheets.filter(sheet => sheet._rows.length);

              workbook.eachSheet((sheet, id) => {
                if (!sheet._rows.length) {
                  return;
                }

                prepareSheetNames.push(sheet.name)
              });
              parser._popup.getBody().getChildViews()[0].parse(prepareSheetNames);
              parser._popup.show();
            } else {
              workbook.eachSheet((sheet, id) => {
                parser._continue(sheet);
              })
            }
          })
        }
      } else {
        webix.alert({text: parser.config.formatWarning, type: "alert-error"});
        parser.callEvent("onAbort");
      }
    }
  },

  _continue: function (sheet) {
    let arr = [];
    let formatFunc = gantt.date.date_to_str(dateHelper.getDateFormat());

    sheet.eachRow((row, rowIndex) => {
      let rowArr = [];
      row.eachCell({includeEmpty: true}, function (cell, colNumber) {
        if(cell.type  === ExcelJS.ValueType.Null){
          cell.value = "";
          rowArr.push(cell.value);
          return;
        }
        if(cell.type === ExcelJS.ValueType.Merge){
          if (cell.value.text) {
            rowArr.push(cell.value.text);
          } else {
            rowArr.push(cell.value);
          }
          return;
        }
        if(cell.type === ExcelJS.ValueType.Number){
          rowArr.push(cell.value);
          return;
        }
        if(cell.type === ExcelJS.ValueType.String){
          rowArr.push(cell.value);
          return;
        }
        if(cell.type === ExcelJS.ValueType.Date){
          let date = new Date(cell.value);
          cell.value = isNaN(date.valueOf()) ? cell.value : formatFunc(date);
          rowArr.push(cell.value);
          return;
        }
        if(cell.type === ExcelJS.ValueType.Hyperlink){
          rowArr.push(cell.value.result);
          return;
        }
        if(cell.type === ExcelJS.ValueType.Formula){
          let valResult;
          if (cell.value.result) {
            valResult = cell.value.result;
          } else if (cell.value.formula) {
            valResult = cell.value.formula;
          } else {
            valResult = '';
          }

          rowArr.push(valResult);
          return;
        }
        if(cell.type === ExcelJS.ValueType.SharedString){
        }
        if(cell.type === ExcelJS.ValueType.RichText){
        }
        if(cell.type === ExcelJS.ValueType.Boolean){
        }
        if(cell.type === ExcelJS.ValueType.Error){
        }

        rowArr.push(cell.value);
      });

      arr.push(rowArr)
    });

    this.callEvent("onParse", [arr]);
  },

  _initPopup: function () {
    var parser = this,
      popup = this._popup = webix.ui({
        view: "ganttproWindowPopup",
        id: "speadsheetsId",
        css: 'speadsheets-list',
        header: {
          label: __('import_choose_spreadsheets'),
        },
        width: 488,
        autoheight: true,
        maxHeight: 700,
        body: {
          paddingX: 24,
          rows: [
            {
              view: "list",
              // yCount: 10,
              maxHeight: 592,
              autoheight: true,
              borderless: true,
              select: true,
              data: [],
              type: {
                height: 42
              },
              on: {
                "onAfterLoad": function () {
                  this.select(this.getFirstId());
                }
              }
            },
            {
              marginX: 12,
              paddingY: 12,
              cols: [
                {},
                {
                  view: "button",
                  css: "button_gray",
                  value: __("common_cancel"),
                  click: function () {
                    parser.callEvent("onAbort");
                    parser._popup.getTopParentView().hide();
                  }
                },
                {width: 12},
                {
                  view: "button",
                  css: "button_active",
                  value: __("import_continue"),
                  click: function () {
                    const list = parser._popup.getBody().getChildViews()[0];
                    let selectedSheet = innerSettings.prepareWorkbook.find(sheet => sheet.name === list.getSelectedId());

                    parser._popup.hide();
                    parser._continue(selectedSheet);
                    GT.importManual = true;
                  }
                }
              ]
            },
            {height:12}
          ]
        }
      });

    this._destroy_with_me = popup;
  }
}, webix.ui.template);
