var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "project-item",
        +_vm.projectOpen === _vm.project.id ? "active" : "",
        "gp_autotest_sidebar_projects_item",
      ],
      attrs: {
        "data-id": _vm.project.id,
        "data-gantt_id": _vm.project.gantt_id,
        "data-type": _vm.project.type,
        "data-archived": _vm.archived,
      },
      on: {
        "!click": function ($event) {
          return _vm.projectClick($event, _vm.project.id)
        },
        "!dblclick": function ($event) {
          return _vm.projectClick($event, _vm.project.id)
        },
      },
    },
    [
      _c(
        "div",
        {
          class: ["projects-popup-list-item"],
          attrs: {
            "data-id": _vm.project.id,
            "data-gantt_id": _vm.project.gantt_id,
            "data-type": _vm.project.type,
          },
        },
        [
          !_vm.multiSelectMode && !_vm.editMultiviewMode
            ? _c(
                "div",
                {
                  class: [
                    _vm.project.is_favorite ? "active" : "not_active",
                    "projects-popup-list-item-fav",
                  ],
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.favClick($event)
                    },
                  },
                },
                [
                  !_vm.archived
                    ? _c("svg-sprite", {
                        class: "project_item_icon",
                        attrs: {
                          name: "star",
                          type: _vm.project.is_favorite ? "bold" : "regular",
                          size: 20,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.multiSelectMode || _vm.editMultiviewMode
            ? _c(
                "div",
                { staticClass: "projects-popup-list-item-multiselect" },
                [
                  _c("vgp-checkbox", {
                    attrs: {
                      color: "#1565C0",
                      size: 16,
                      checked: _vm.multiSelectModeIds.includes(_vm.project.id),
                    },
                    on: {
                      onClick: function () {
                        return _vm.checkboxHandler(_vm.project.id)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "projects-popup-list-item-title" },
            [
              _c(
                "div",
                { staticClass: "projects-popup-list-item-title-icon" },
                [
                  _c("svg-sprite", {
                    attrs: { name: _vm.getProjectIcon(_vm.project), size: 20 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "projects-popup-list-item-title-container" },
                [
                  _c(
                    "div",
                    { class: ["projects-popup-list-item-title-name"] },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.project.name) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "projects-popup-list-item-title-last-update",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getLastUpdateDate(_vm.project)) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.statusData
                ? _c("status-select", {
                    class: [
                      _vm.archived ? "status-archived" : "",
                      "status-view",
                      "gp_autotest_sidebar_projects_item_status_select",
                    ],
                    attrs: {
                      "status-data": _vm.statusData,
                      "project-config": _vm.project,
                      "block-select":
                        _vm.multiSelectMode || _vm.editMultiviewMode,
                      "has-right-to-change-status": _vm.hasRightToChangeStatus,
                      "has-right-to-status-settings":
                        _vm.hasRightToStatusSettings,
                      "open-status": _vm.openStatus,
                    },
                    on: {
                      open: function ($event) {
                        return _vm.$emit("openStatus", $event)
                      },
                      currentProjectForStatus: function ($event) {
                        return _vm.$emit("currentProjectForStatus", $event)
                      },
                      positionSelect: function ($event) {
                        return _vm.$emit("positionSelect", $event)
                      },
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.blockStatusClick($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.archived
                ? _c("div", [
                    _c(
                      "div",
                      {
                        ref: "infoPopup",
                        staticClass: "projects-popup-list-item-info",
                        attrs: { "data-id": _vm.project.id },
                        on: {
                          mouseover: function ($event) {
                            return _vm.hoverItem(_vm.project)
                          },
                          mouseleave: function ($event) {
                            return _vm.$emit("currentProjectInfo", null)
                          },
                        },
                      },
                      [_c("svg-sprite", { attrs: { name: "info", size: 20 } })],
                      1
                    ),
                  ])
                : _c("div", [
                    _c("div", {
                      staticClass: "projects-popup-list-item-info disabled",
                    }),
                  ]),
              _vm._v(" "),
              _c("div", [
                _vm.itemsForContextMenu.length
                  ? _c(
                      "div",
                      {
                        ref: "contextMenu",
                        staticClass: "projects-popup-list-item-menu",
                        class: [
                          {
                            disabled:
                              _vm.multiSelectMode || _vm.editMultiviewMode,
                          },
                          "gp_autotest_sidebar_projects_item_menu_button",
                        ],
                        attrs: { "data-id": _vm.project.id },
                        on: {
                          click: function ($event) {
                            return _vm.openMenu($event, _vm.project)
                          },
                        },
                      },
                      [
                        _c("svg-sprite", {
                          attrs: { name: "more-1", size: 20 },
                        }),
                      ],
                      1
                    )
                  : _c("div", {
                      staticClass: "projects-popup-list-item-empty-container",
                    }),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }