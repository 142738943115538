import Quill from "quill";

Quill.prototype.quillGetHTML = function(inputDelta) {
  var tempCont = document.createElement("div");

  (new Quill(tempCont)).setContents(inputDelta);

  return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
};

Quill.prototype.getHtml = function() {
  return this.container.querySelector('.ql-editor').innerHTML;
};

Quill.prototype.getInnerText = function() {
  return this.container.querySelector('.ql-editor').innerText;
};

Quill.prototype.getContainer = function() {
  return this.container.querySelector('.ql-editor');
};

export default Quill;