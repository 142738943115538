import { render, staticRenderFns } from "./spaceSize.vue?vue&type=template&id=524e70b2&scoped=true&"
import script from "./spaceSize.vue?vue&type=script&lang=js&"
export * from "./spaceSize.vue?vue&type=script&lang=js&"
import style0 from "../../less/profileSettings.less?vue&type=style&index=0&id=524e70b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524e70b2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ganttpro/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('524e70b2')) {
      api.createRecord('524e70b2', component.options)
    } else {
      api.reload('524e70b2', component.options)
    }
    module.hot.accept("./spaceSize.vue?vue&type=template&id=524e70b2&scoped=true&", function () {
      api.rerender('524e70b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/views/profileSettings/components/team/spaceSize.vue"
export default component.exports