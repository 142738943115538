import app from '../../../app';
import globalStore from '$$store/main';
import rights from '../../rights/index';

export function updateResourceOnProjects(resourcesOnProjects) {
  const result = {
    projectIds: [],
    resourceIds: [],
    isUpdatedRole: false,
  };

  resourcesOnProjects.forEach(resourceData => {
    if (resourceData?.updatedFields?.projectRoleId) {
      result.isUpdatedRole = true;
      rights.project.clearCacheGetUserRole();
    }

    globalStore.commit('resourcesModel/updateResourceOnProjects', {
      resourceId: resourceData.resourceId,
      projectIds: [resourceData.projectId],
      updated: resourceData.updatedFields,
    });

    result.projectIds.push(+resourceData.projectId);
    result.resourceIds.push(+resourceData.resourceId);
  });

  return result;
}

function onResourceOnProjectsUpdated(event) {
  const { data } = event;
  const { projectIds, resourceIds, isUpdatedRole } = updateResourceOnProjects(data.updated);

  event.data.updated.forEach(resourceData => {
    if (resourceData?.user_id === user.id && isUpdatedRole) {
      resourceData.projectId && app.trigger('user:changedRole', +resourceData.projectId);
    }
  });

  gantt.callEvent('ganttClearAndParse', []);

  app.trigger('onAfterCollaboration', {
    event: event.event,
    data: event.data,
    projects: [...new Set(projectIds)],
    resourcesIds: [...new Set(resourceIds)],
    isUpdatedRole,
  });
}

export default onResourceOnProjectsUpdated;
