<template>
  <div class="integrationsettings_class">
    <router-view/>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
};
</script>

<style scoped>

</style>
