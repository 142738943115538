import app from '../app';
import projects from './projects';
import _ from '../libs/lodash';
import timeIntervals from '../helpers/timeIntervals';
import helperDate from '../helpers/dateFormats';

let multiViewsProjects = {};
const paramsToUpdate = new Map();

if (app.config.mode.isBase) {
  multiViewsProjects = new webix.DataCollection({
    url: `rest->/api/multiview/?${new Date().getTime()}`,
    save: {
      url: 'rest->/api/multiview/',
      updateFromResponse: true,
    },
  });
} else {
  multiViewsProjects = new webix.DataCollection();
  multiViewsProjects.parse(GT.ganttData.multiviews || []);
  app.checkInit('multiViewsProjects');
}

let activeMultiView = null;

app.on('app:start', () => {
  webix.promise.all([multiViewsProjects.waitData])
    .then(() => {
      app.checkInit('multiViewsProjects');
      app.trigger('multiViewsProjects:init');
    });
});

app.on('app:initModels:complete', () => {
  if ((app.config.mode.isExport || app.config.mode.isLink) && GT.ganttData.multiviews) {
    multiViewsProjects.setActiveMultiView(GT.ganttData.project.id);
    // _.delay(() => {
    app.trigger('activeMultiProjects:set', activeMultiView.id, activeMultiView.ganttIDs);
    // });
  }
});

multiViewsProjects.addParamsToUpdate = function (params) {
  paramsToUpdate.set(params.key, params.value);
};

multiViewsProjects.updateGanttSettings = function () {
  if (!paramsToUpdate.size) {
    return true;
  }

  const currentMultiview = multiViewsProjects.getActiveViewData();

  for (const [key, value] of paramsToUpdate) {
    const isSkinChanged = key === 'skin';
    const isNameChanged = key === 'name';

    if (isNameChanged) {
      currentMultiview[key] = value;
    } else if (isSkinChanged) {
      currentMultiview.config.userSkin = value;
    } else {
      currentMultiview.config[key] = value;
    }
  }

  paramsToUpdate.clear();

  multiViewsProjects.updateItem(currentMultiview.id, currentMultiview);
};

multiViewsProjects.getActiveGanttId = function () {
  return activeMultiView;
};

multiViewsProjects.getActiveViewData = function () {
  return activeMultiView;
};

multiViewsProjects.getTasksValuesFormMultiview = function () {
  if (activeMultiView && activeMultiView.taskValuesMultiview) {
    return activeMultiView.taskValuesMultiview;
  }

  return [];
};

multiViewsProjects.isMultiview = function () {
  return true;
};

multiViewsProjects.getAllMultiviews = function () {
  return multiViewsProjects.serialize();
};

multiViewsProjects.getAllMultiviewsWithJira = function () {
  const jiraProjectsIds = projects.getAllJiraProjects().map(i => i.id);
  const multiviewAll = multiViewsProjects.getAllMultiviews();

  const jiraMultiview = multiviewAll.filter(i => i.ganttIDs.find(id => jiraProjectsIds.includes(id)));

  return jiraMultiview;
};

multiViewsProjects.getProjectDataById = function (id) {
  return multiViewsProjects.getItem(id);
};

multiViewsProjects.clearActiveMultiView = function () {
  activeMultiView = null;
};

multiViewsProjects.setActiveMultiView = function (id) {
  const multiView = multiViewsProjects.getItem(id);

  activeMultiView = multiView;
  activeMultiView.config = multiViewsProjects.getProjectConfig();
  projects.activeGanttId = null;

  if (activeMultiView.config.order) {
    activeMultiView.ganttIDs.sort((a, b) => activeMultiView.config.order.indexOf(a) - activeMultiView.config.order.indexOf(b));
  }

  return activeMultiView;
};

multiViewsProjects.getActiveProjectData = function () {
  return activeMultiView;
};

multiViewsProjects.getProjectConfig = function () {
  const projectConfigs = _.map(activeMultiView.ganttIDs, ganttID => projects.getProjectConfig(ganttID));

  const workHours = _.reduce(projectConfigs, (res, config) => {
    res = _.union(res, timeIntervals.getHoursArrayByIntervalArray(_.clone(config.durationData.showTime), helperDate.getHoursArrayByUserTimeFormat()));

    return res;
  }, []);

  activeMultiView.config.durationData.showTime = timeIntervals.prepareSelectedHours(workHours, helperDate.getHoursArrayByUserTimeFormat());
  // console.log('workHours', activeMultiView.config.durationData.showTime)

  return {
    ...activeMultiView.config,
    multiview: true,
  };
};

multiViewsProjects.createMultiview = function (ganttIDs, customName) {
  const totalEstimates = ganttIDs.map(ganttID => app.callGlobalStore('getter', 'tasksModel/getTotalEstimateDataForProject', [ganttID]).id);

  ganttIDs.sort((ganttIdA, ganttIdB) => {
    const totalTaskA = app.callGlobalStore('getter', 'tasksModel/getTotalEstimateDataForProject', [ganttIdA]);
    const totalTaskB = app.callGlobalStore('getter', 'tasksModel/getTotalEstimateDataForProject', [ganttIdB]);

    return totalTaskA.start_date - totalTaskB.start_date;
  });

  const config = multiViewsProjects.getDefaultConfig();

  config.order = ganttIDs;

  const name = customName || ganttIDs
    .map(ganttID => projects.getProjectDataById(ganttID).name)
    .join(', ');

  const multivie_count = multiViewsProjects.getAllMultiviews().length;
  const projects_count = projects.getAllProjects().length;

  webix.ajax().post('/api/pipedrive', { projects_created: multivie_count + projects_count + 1 });

  return multiViewsProjects.waitSave(() => {
    multiViewsProjects.add({
      name,
      ganttIDs,
      totalEstimates,
      config,
    });
  });
};

multiViewsProjects.updateMultiview = function (multiviewID, ganttIDs) {
  const multiview = multiViewsProjects.getItem(multiviewID);

  const oldGanttIDs = multiview.ganttIDs;
  const oldName = multiview.name;
  const name = oldGanttIDs
    .map(ganttID => projects.getProjectDataById(ganttID).name)
    .join(', ');

  const newGanttIDs = _.difference(ganttIDs, oldGanttIDs);

  const totalEstimates = newGanttIDs.map(ganttID => app.callGlobalStore('getter', 'tasksModel/getTotalEstimateDataForProject', [ganttID]).id);

  const canUpdateName = oldName.trim() === name.trim();

  if (canUpdateName) {
    multiview.name = ganttIDs
      .map(ganttID => projects.getProjectDataById(ganttID).name)
      .join(', ');
  }

  multiview.config.order = ganttIDs;
  multiview.ganttIDs = ganttIDs;
  multiview.totalEstimates = totalEstimates;
  multiview.updateGanttIDs = true;

  for (const task_id of totalEstimates) {
    multiview.taskValuesMultiview.push({
      multiview_id: multiview.id,
      open: 0,
      task_id,
      user_id: multiview.user_id,
    });
  }

  multiViewsProjects.updateItem(multiviewID, multiview);

  delete multiview.updateGanttIDs;
};

multiViewsProjects.getDefaultConfig = function () {
  return {
    ...projects.getDefaultConfig(),
    auto_scheduling: true,
    show_advanced_buttons: true,
    discoloration_tasks: true,
    cross_out_tasks: true,
    right_side_text: 'insideText',
  };
};

multiViewsProjects.removeMultiview = function (id) {
  multiViewsProjects.remove(id);
  multiViewsProjects.data.callEvent('removeMultiview', [id]);
};

multiViewsProjects.manualUpdate = function (multiview) {
  multiViewsProjects.data.pull[multiview.id] = multiview;
};

multiViewsProjects.getMultiviewsByGanttId = function (ganttID) {
  const multiviews = multiViewsProjects.serialize();
  const multiviewWithProject = [];

  if (multiviews) {
    _.each(multiviews, multiview => {
      const hasMultiviewProject = _.find(multiview.ganttIDs, gantt_id => +gantt_id === +ganttID);

      if (hasMultiviewProject) {
        multiviewWithProject.push(multiview);
      }
    });
  }

  return multiviewWithProject;
};

multiViewsProjects.removeAllMultiview = function () {
  const multiviews = multiViewsProjects.serialize();

  _.each(multiviews, multiview => {
    multiViewsProjects.removeMultiview(multiview.id);
  });
};

multiViewsProjects.removeProjectFromAllMultiviews = function (ganttID) {
  const multiviewIDs = _.map(multiViewsProjects.serialize(), item => item.id);

  multiViewsProjects.removeProjectFromMultiviews(ganttID, multiviewIDs);
};

multiViewsProjects.removeProjectFromMultiviews = function (ganttID, multiviewIDs) {
  const multiviews = _.map(multiviewIDs, id => multiViewsProjects.getItem(id)).filter(Boolean);
  const activeGanttIDs = activeMultiView ? [...activeMultiView.ganttIDs] : [];

  if (multiviews) {
    _.each(multiviews, multiview => {
      _.remove(multiview.ganttIDs, gantt_id => gantt_id === ganttID);
      _.remove(multiview.config.order, gantt_id => gantt_id === ganttID);

      if (multiview.ganttIDs.length === 0) {
        multiViewsProjects.removeMultiview(multiview.id);
      }
    });
  }

  app.trigger('portfolio:ganttIDs:remove', multiviewIDs, ganttID);

  if (activeMultiView && _.includes(multiviewIDs, activeMultiView.id)) {
    const active = multiViewsProjects.getItem(activeMultiView.id);

    if (active) {
      if (active.ganttIDs.length === 0) {
        _.delay(() => {
          projects.openProjectAfterDelete();
        }, 200);
      } else if (activeGanttIDs.includes(ganttID)) {
        app.trigger('activeMultiProjects:set', active.id, active.ganttIDs);
      }
    } else {
      _.delay(() => {
        projects.openProjectAfterDelete();
      }, 200);
    }
  }
};

multiViewsProjects.removeMultiviews = function (multiviewIDs) {
  const multiviews = _.map(multiviewIDs, id => multiViewsProjects.getItem(id)).filter(Boolean);

  if (multiviews) {
    _.each(multiviews, multiview => {
      multiViewsProjects.removeMultiview(multiview.id);
    });
  }

  if (activeMultiView) {
    projects.openProjectAfterDelete();
  }
};

multiViewsProjects.getAllFavorite = function () {
  return _.filter(multiViewsProjects.serialize(), multiView => multiView.is_favorite);
};

multiViewsProjects.checkParamsToUpdate = function (checkParam, id) {
  if (!id) {
    return false;
  }

  const multiviewConfig = this.getProjectConfig();

  if (!_.isEqual(checkParam.value, multiviewConfig[checkParam.key])) {
    this.addParamsToUpdate(checkParam);

    return true;
  }

  return false;
};

multiViewsProjects.checkParamsToUpdateAndUpdate = function (checkParam) {
  if (activeMultiView) {
    const id = activeMultiView.id;

    if (this.checkParamsToUpdate(checkParam, id)) {
      this.updateGanttSettings();

      return true;
    }
  }

  return false;
};

multiViewsProjects.updateOrderActiveMultiview = function (newOrder) {
  if (activeMultiView) {
    const id = activeMultiView.id;

    if (this.checkParamsToUpdate({ key: 'order', value: newOrder }, id)) {
      this.updateGanttSettings();
      app.trigger('updateGanttIDs', newOrder);

      return true;
    }
  }

  return false;
};

multiViewsProjects.updateProjectListSortingActiveMultiview = function (sortingState) {
  if (activeMultiView) {
    const id = activeMultiView.id;

    if (this.checkParamsToUpdate({ key: 'project_list_sorting', value: sortingState }, id)) {
      this.updateGanttSettings();

      return true;
    }
  }

  return false;
};

multiViewsProjects.manualUpdateFavorite = function (id, isFavorite) {
  return webix.ajax()
    .put('/api/multiview/favorite', {
      id,
      isFavorite: isFavorite ? 1 : 0,
    })
    .then(res => {
      const data = res.json();

      if (!(data.status && data.status === 'error') && data.updated) {
        const multiview = multiViewsProjects.getItem(id);

        multiViewsProjects.data.pull[multiview.id].is_favorite = isFavorite;
        multiViewsProjects.data.blockEvent();
        multiViewsProjects.updateItem(multiview.id, { is_favorite: isFavorite });
        multiViewsProjects.data.unblockEvent();
      }
      app.trigger('sideBar:update:favorite');

      return data.updated;
    });
};

app.socket.on('removeProjectFromMultiview', data => {
  multiViewsProjects.removeProjectFromMultiviews(+data.gantt_id, data.multiviewsIDs);
});

app.socket.on('removeMultiviews', data => {
  multiViewsProjects.removeMultiviews(data.multiviewsIDs);
});

export default multiViewsProjects;
