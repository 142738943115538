var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.filter_body, "gantt_scroll"] },
    [
      _vm._l(_vm.fields, function (item) {
        return _c(
          "div",
          { key: item.id + "_filter_" + item.property + _vm.componentKey },
          [
            item.type === "search-input"
              ? _c("text-input", {
                  attrs: {
                    "gp-autotest-name":
                      "gp_autotest_global_filter_option_" + item.property,
                    label: item.label,
                    placeholder: item.placeholder,
                    value: _vm.values[item.property],
                    "with-search-icon": true,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "multiselect"
              ? _c("multiselect", {
                  attrs: {
                    "gp-autotest-name":
                      "gp_autotest_global_filter_option_" + item.property,
                    label: item.label,
                    "drop-items": item.data,
                    "drop-template": item.itemTemplate,
                    placeholder: item.placeholder,
                    selected: _vm.values[item.property],
                  },
                  on: {
                    check: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "search-multiselect"
              ? _c("search-multiselect", {
                  attrs: {
                    "gp-autotest-name":
                      "gp_autotest_global_filter_option_" + item.property,
                    label: item.label,
                    "drop-items": item.data,
                    "drop-template": item.itemTemplate,
                    placeholder: item.placeholder,
                    "group-by": "group",
                    selected: _vm.values[item.property],
                  },
                  on: {
                    check: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "datepicker"
              ? _c("date-range-input", {
                  attrs: {
                    "gp-autotest-name":
                      "gp_autotest_global_filter_option_" + item.property,
                    label: item.label,
                    placeholder: item.placeholder,
                    "range-only": item.rangeOnly,
                    value: _vm.values[item.property],
                    property: item.property,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "toggle" && _vm.checkPricingAccess("overdue_tasks")
              ? _c("toggle-field", {
                  attrs: {
                    label: item.label,
                    tooltip: item.tooltip,
                    "is-toggle": !!_vm.values[item.property],
                  },
                  on: {
                    toggle: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.type === "from-to"
              ? _c("from-to-time", {
                  attrs: {
                    "gp-autotest-name":
                      "gp_autotest_global_filter_option_" + item.property,
                    label: item.label,
                    value: _vm.values[item.property],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                    reset: function ($event) {
                      return _vm.changeProperty(item.property, $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.custom && _vm.custom.length && _vm.accessToColumns
        ? [
            _c("div", { class: [_vm.$style.delimeter] }),
            _vm._v(" "),
            _vm._l(_vm.custom, function (item, index) {
              return _c(
                "div",
                { key: "filter_custom_" + index + _vm.componentKey },
                [
                  item.type === "from-to-date"
                    ? _c("from-to-input", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          type: "date",
                          value: _vm.getCustomValues(item.id),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeCustomProperty(
                              item.id,
                              $event,
                              "from-to-date"
                            )
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "from-to-number"
                    ? _c("from-to-input", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          type: "number",
                          value: _vm.getCustomValues(item.id),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeCustomProperty(
                              item.id,
                              $event,
                              "from-to-number"
                            )
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "text-input"
                    ? _c("text-input", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          placeholder: _vm.locales.search_placeholder,
                          value: _vm.getCustomValues(item.id),
                          "with-search-icon": false,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeCustomProperty(item.id, $event)
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "multiselect"
                    ? _c("multiselect", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          "drop-items": item.data,
                          "drop-template": item.itemTemplate,
                          placeholder: _vm.locales.all_placeholder,
                          selected: _vm.getCustomValues(item.id),
                        },
                        on: {
                          check: function ($event) {
                            return _vm.changeCustomProperty(item.id, $event)
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "search-multiselect"
                    ? _c("search-multiselect", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          "drop-items": item.data,
                          "drop-template": item.itemTemplate,
                          placeholder: _vm.locales.all_placeholder,
                          "group-by": "group",
                          selected: _vm.getCustomValues(item.id),
                        },
                        on: {
                          check: function ($event) {
                            return _vm.changeCustomProperty(item.id, $event)
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "single-select"
                    ? _c("single-select", {
                        attrs: {
                          "gp-autotest-name":
                            "gp_autotest_global_filter_custom_option_" +
                            item.id,
                          label: item.label,
                          "drop-items": item.data,
                          "drop-template": item.itemTemplate,
                          placeholder: _vm.locales.all_placeholder,
                          "group-by": "group",
                          selected: _vm.getCustomValues(item.id),
                        },
                        on: {
                          check: function ($event) {
                            return _vm.changeCustomProperty(item.id, $event)
                          },
                          reset: function ($event) {
                            return _vm.resetCustomCols(item.id)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }