var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gp_autotest_sso_toast",
      class: [_vm.$.toast_cmp, _vm.$.sso_toast_cmp],
    },
    [
      _c("div", {
        class: _vm.$.message,
        domProps: { innerHTML: _vm._s(_vm.locales("warning_team_only_sso")) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }