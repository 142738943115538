var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.type === "tab" ? _vm.$.tabs : _vm.$.button,
        _vm.small ? _vm.$.small : "",
      ],
      attrs: { id: _vm.gpAutotestName },
    },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c(
        "div",
        {
          key: index,
          class: [
            _vm.type === "tab" ? _vm.$.tabs_item : _vm.$.button_item,
            tab[_vm.activeBy] === _vm.activeItem[_vm.activeBy]
              ? _vm.$.active
              : "",
            _vm.gpAutotestName ? _vm.gpAutotestName + "_" + tab.type : "",
          ],
          style: {
            borderColor:
              tab[_vm.activeBy] === _vm.activeItem[_vm.activeBy]
                ? _vm.color
                : "transparent",
            width: _vm.tabs.length === 2 && _vm.type !== "tab" ? "174px" : "",
          },
          on: {
            click: function ($event) {
              return _vm.selectTab(tab)
            },
          },
        },
        [
          tab.icon
            ? _c("sprite", {
                class: _vm.$.tabs_item__icon,
                attrs: {
                  name: tab.icon.name,
                  size: tab.icon.size || 24,
                  type: tab.icon.type || "regular",
                  color:
                    tab[_vm.activeBy] === _vm.activeItem[_vm.activeBy]
                      ? _vm.color
                      : "#b2b2b2",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              class: [
                _vm.type === "tab"
                  ? _vm.$.tabs_item__title
                  : _vm.$.button_item__title,
              ],
              style: {
                marginLeft: tab.icon ? "6px" : "",
                color:
                  _vm.type === "tab" &&
                  tab[_vm.activeBy] === _vm.activeItem[_vm.activeBy]
                    ? _vm.color
                    : _vm.type === "button" &&
                      tab[_vm.activeBy] === _vm.activeItem[_vm.activeBy]
                    ? "#191919"
                    : "#b2b2b2",
              },
            },
            [
              _vm._v(
                _vm._s(tab.title) +
                  " " +
                  _vm._s(
                    _vm.amounts[tab.type] >= 100 ? "99+" : _vm.amounts[tab.type]
                  )
              ),
            ]
          ),
          _vm._v(" "),
          _vm.tabs.length > 2 && _vm.type === "button"
            ? _c("div", { class: [_vm.$.button_item__separator] })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }