var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-section" },
    [
      _c("div", { staticClass: "main-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.locales("settings_profile_password_title")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.locales("settings_profile_password_text")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      !_vm.isEditPasswordAreaShown
        ? _c("vgp-button", {
            staticClass: "password-section__button",
            attrs: {
              type: "primary-border",
              small: "",
              label: _vm.toggleSectionButtonLabel,
            },
            on: {
              onClick: function ($event) {
                _vm.isEditPasswordAreaShown = true
              },
            },
          })
        : _c("div", { staticClass: "edit-area" }, [
            _c(
              "div",
              { staticClass: "password-editer" },
              [
                _c("input", {
                  attrs: {
                    id: "gp_autotest_change_password_email_input",
                    type: "text",
                    autocomplete: "username",
                    hidden: "",
                  },
                  domProps: { value: _vm.form.email },
                }),
                _vm._v(" "),
                !_vm.isFirstTimePasswordSet
                  ? _c("vgp-label-slot", {
                      staticClass: "input__wrap",
                      attrs: { label: _vm.locales("profile_current_password") },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("ValidationProvider", {
                                  ref: "currentPasswordValidationProvider",
                                  attrs: {
                                    rules: "required",
                                    "custom-messages": {
                                      required: _vm.locales(
                                        "validation_error_currentPassword_required"
                                      ),
                                    },
                                    vid: "currentPassword",
                                    mode: _vm.customValidationProviderMode,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("vgp-input", {
                                              ref: "currentPassword",
                                              attrs: {
                                                id: "gp_autotest_change_password_currentPassword_input",
                                                maxlength: "250",
                                                small: "",
                                                "is-error": !!errors[0],
                                                placeholder: _vm.locales(
                                                  "profile_current_password_placeholder"
                                                ),
                                                "input-type":
                                                  _vm.currentPasswordInputType,
                                                "icons-right": [
                                                  _vm.getIconConfig(
                                                    "currentPasswordInputType"
                                                  ),
                                                ],
                                              },
                                              on: {
                                                onFocus:
                                                  _vm.handleCurrentPasswordFocus,
                                              },
                                              model: {
                                                value: _vm.form.currentPassword,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "currentPassword",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.currentPassword",
                                              },
                                            }),
                                            _vm._v(" "),
                                            errors[0]
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "input__error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n              " +
                                                        _vm._s(errors[0]) +
                                                        "\n            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    559200909
                                  ),
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        435712050
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("vgp-label-slot", {
                  staticClass: "input__wrap",
                  attrs: { label: _vm.locales("profile_new_password") },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("tooltip", {
                            attrs: {
                              position: "left",
                              content: _vm.locales("pass_strength_descr"),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("ValidationProvider", {
                                      ref: "newPasswordValidationProvider",
                                      attrs: {
                                        rules: "required|min:8",
                                        "custom-messages": {
                                          min: _vm.locales(
                                            "validation_error_password_min_length"
                                          ),
                                          required: _vm.locales(
                                            "validation_error_password_required"
                                          ),
                                        },
                                        vid: "newPassword",
                                        mode: _vm.customValidationProviderMode,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("vgp-input", {
                                                ref: "newPassword",
                                                attrs: {
                                                  id: "gp_autotest_change_password_newPassword_input",
                                                  autocomplete: "new-password",
                                                  value: _vm.form.newPassword,
                                                  maxlength: "250",
                                                  small: "",
                                                  "is-error":
                                                    !!errors[0] ||
                                                    _vm.isPasswordCommonError,
                                                  placeholder: _vm.locales(
                                                    "profile_new_password_placeholder"
                                                  ),
                                                  "input-type":
                                                    _vm.newPasswordInputType,
                                                  "icons-right": [
                                                    _vm.getIconConfig(
                                                      "newPasswordInputType"
                                                    ),
                                                  ],
                                                },
                                                on: {
                                                  onFocus:
                                                    _vm.handleNewPasswordFocus,
                                                  input:
                                                    _vm.handleNewPasswordInput,
                                                  onBlur: function ($event) {
                                                    _vm.passwordStrengthState.show = false
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              errors[0] ||
                                              _vm.isPasswordCommonError
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "input__error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            errors[0] ||
                                                              _vm.locales(
                                                                "validation_error_common_password"
                                                              )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _vm.passwordStrengthState.show
                            ? _c(
                                "password-strength-indicator",
                                _vm._b(
                                  {},
                                  "password-strength-indicator",
                                  _vm.passwordStrengthState,
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("vgp-label-slot", {
                  staticClass: "input__wrap",
                  attrs: { label: _vm.locales("profile_retype_password") },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("ValidationProvider", {
                            ref: "passwordConfirmationValidationProvider",
                            attrs: {
                              rules: "required|confirmed:newPassword",
                              "custom-messages": {
                                required: _vm.locales(
                                  "validation_error_newPasswordConfirmation_required"
                                ),
                                confirmed: _vm.locales(
                                  "validation_error_password_does_not_match_confirm_password"
                                ),
                              },
                              vid: "passwordConfirmation",
                              mode: _vm.customValidationProviderMode,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("vgp-input", {
                                      ref: "passwordConfirmation",
                                      attrs: {
                                        id: "gp_autotest_change_password_passwordConfirmation_input",
                                        maxlength: "250",
                                        small: "",
                                        "is-error": !!errors[0],
                                        placeholder: _vm.locales(
                                          "profile_retype_password"
                                        ),
                                        "input-type":
                                          _vm.passwordConfirmationInputType,
                                        "icons-right": [
                                          _vm.getIconConfig(
                                            "passwordConfirmationInputType"
                                          ),
                                        ],
                                      },
                                      on: {
                                        onFocus:
                                          _vm.handlePasswordConfirmationFocus,
                                      },
                                      model: {
                                        value: _vm.form.passwordConfirmation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "passwordConfirmation",
                                            $$v
                                          )
                                        },
                                        expression: "form.passwordConfirmation",
                                      },
                                    }),
                                    _vm._v(" "),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          { staticClass: "input__error" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "password-section__buttons-wrapper" },
              [
                _c("vgp-button", {
                  staticClass: "password-section__button",
                  attrs: {
                    type: "secondary",
                    small: "",
                    label: _vm.locales("common_cancel"),
                  },
                  on: { onClick: _vm.handleCancelButtonClick },
                }),
                _vm._v(" "),
                _c("vgp-button", {
                  staticClass: "password-section__button",
                  attrs: {
                    type: "primary",
                    small: "",
                    disabled: _vm.isSavePasswordButtonDisabled,
                    "is-loading": _vm.isFormSubmitting,
                    label: _vm.locales("common_save"),
                  },
                  on: { onClick: _vm.submitChanges },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }