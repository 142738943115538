import app from '../../../app';
import projectComponent from '../../Project';
import globalStore from "../../../store/main";

function onTaskLinkDeleted(event) {
  event.innerSettings.collaborationItems.forEach(linkToAction => {
    if (projectComponent.userHasProject(linkToAction.gantt_id)) {
      globalStore.commit('tasksModel/deleteLink', linkToAction);
      app.trigger('tasks:model:deleteLink', linkToAction.id, linkToAction);
    }
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    ids: event.data.id,
  });
}

export default onTaskLinkDeleted;
