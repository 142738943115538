var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vgpInput", {
    key: "bulc-input-" + _vm.item.id,
    attrs: {
      placeholder: _vm.placeholder,
      "is-disabled": _vm.isDisabled,
      "icons-right": _vm.iconsConfig,
      small: true,
      "input-type": _vm.item.inputType,
      value: _vm.inputValue,
    },
    on: { onKeyup: _vm.onKeyup },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }