<template>
  <div
    :id="gpAutotestName"
    ref="inputField"
    :class="[
      $style.input_field,
      isHasSelected || isShowCalendar ? $style.active : '',
    ]"
    @click="inputClick"
  >
    <div v-if="isHasSelected">
      <span> {{ stringSelected }} </span>
    </div>
    <div v-else>
      {{ placeholder }}
    </div>

    <webix-ui
      :class="[$style.date_input_calendar_icon]"
      :config="calendar_icon"
    />
  </div>
</template>

<script>
import svgIconForVue from '../../../common/svgIconForVue';
import calendar_icon from '../../../../svg/filter/calendar.svg';
import dateHelper from '../../../../helpers/dateFormats';

export default {
  name: 'FromToDateInput',
  props: {
    placeholder: { type: String, required: false, default: 'placeholder' },
    value: { type: Object, required: false, default: null },
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
  },

  data() {
    return {
      calendar_icon: svgIconForVue(calendar_icon, 24, 24),
      selectedDate: '',
      isShowCalendar: false,
      calendarHeight: 414,
      redraw: 0,
    };
  },

  computed: {
    componentKey() {
      const min = 10;
      const max = 100000;
      const key = Math.floor(Math.random() * (max - min)) + min;

      return `drop-select-date-input-${new Date().getTime()}-${key}`;
    },

    isHasSelected() {
      return this.selectedDate;
    },

    stringSelected() {
      return (this.selectedDate) ? this.format(this.selectedDate) : '';
    },
  },

  watch: {
    isShowCalendar(val) {
      if (val) {
        this.openDatePicker();
      } else {
        this.closeDatePicker();
      }
    },
    value(val) {
      this.selectedDate = val;
    },
  },

  mounted() {
    this.addListheners();
    const component = this;

    this.setIncomingValue();

    this.popupId = `popupDatePicker_${this.componentKey}`;
    this.calendarId = `datepickerRange_${this.componentKey}`;

    const datepickerConfig = webix.ui({
      view: 'popupWithoutPointCustom',
      id: component.popupId,
      css: 'customDateRangePicker suggest_popup customDateRangeSuggest filter-datepicker',
      hidden: true,
      body: {
        view: 'calendar',
        css: 'calendar webix_calendar',
        id: component.calendarId,
        monthSelect: true,
        navigation: true,
        cacheData: {},
        weekHeader: true,
        borderless: true,
        calendar: {
          height: 300,
          cellHeight: 24,
        },
        value: component.selectedDate,
        timepicker: false,
        icons: [{
          template() {
            const today = webix.i18n.calendar.today;

            return `<span role='button' tabindex='0' class='webix_cal_icon_today webix_cal_icon' >${today}</span>`;
          },
          on_click: {
            webix_cal_icon_today() {
              this.setValue(new Date());
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
          },
        }],
        on: {
          onChange(newValue) {
            component.setSelectedDate(newValue);
          },
        },
      },
      on: {
        onHide() {
          component.isShowCalendar = false;
        },
      },
    });

    datepickerConfig.$scope = this;
    webix.ui(datepickerConfig);
  },

  beforeDestroy() {
    this.removeListheners();
    webix.$$(this.popupId).destructor();
  },

  methods: {
    onScroll(e) {
      this.closeDatePicker();
    },

    addListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.onScroll);
      });
    },
    removeListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.removeEventListener('scroll', this.onScroll);
      });
    },
    setSelectedDate(values) {
      if (!values) {
        this.selectedDate = '';

        return;
      }

      if ((new Date(values[0])).getTime() === (new Date(this.selectedDate)).getTime()) { return; }

      this.selectedDate = values[0];
      this.$emit('change', this.selectedDate);
      this.isShowCalendar = false;
    },

    setIncomingValue() {
      if (!this.value) return;
      this.selectedDate = this.value;
    },

    format(date) {
      const dateFormat = dateHelper.getDateFormat();

      return webix.Date.dateToStr(dateFormat)(date);
    },
    svgForVue(icon) {
      return svgIconForVue(icon, 17, 17);
    },
    inputClick() {
      this.isShowCalendar = !this.isShowCalendar;
    },
    openDatePicker() {
      this.showCalendar();
      $$(this.calendarId).setValue(this.selectedDate);
    },
    showCalendar() {
      const clientHeight = document.documentElement.clientHeight;
      const inputCoord = this.$el.getBoundingClientRect();

      const isTop = inputCoord.top + 2 > this.calendarHeight;
      const isBottom = clientHeight - inputCoord.bottom + 2 > this.calendarHeight;

      if (!isTop && isBottom) {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'bottom' });
      } else {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'top' });
      }
    },
    closeDatePicker() {
      $$(this.popupId).hide();
    },
  },
};
</script>

<style module src="../style.less" lang="less"></style>
