<template>
  <div
    :class="[
      $style.box_wrapper,
      'vgp-interactive-element'
    ]"
    :style="{
      height: `${size}px`,
    }"
  >
    <label :class="$style.radioButton">
      <span
        v-if="label && labelPosition==='left'"
        :class="$style.label_text"
        :style="{marginRight: `8px`}"
      >
        {{ label }}
      </span>

      <input
        :class="$style.check_input"
        type="radio"
        :checked="checked"
        @click="$emit('onChange', $event.target.checked)"
      >
      <span
        :class="$style.radio"
      >
        <div
          v-if="checked"
          :style="{
            width: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
          }"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="9"
              fill="white"
              stroke="#1565C0"
              stroke-width="2"
            />
            <circle
              cx="12"
              cy="12"
              r="5"
              fill="#1565C0"
            />
          </svg>
        </div>
        <div
          v-else
          :class="[$style.radio, $style.radio_uncheked]"
          :style="{
            width: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
          }"
        />
      </span>

      <span
        v-if="label && labelPosition==='right'"
        :class="$style.label_text"
        :style="{marginLeft: `8px`}"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VgpRadioButton',
  model: {
    prop: 'checked',
    event: 'onChange',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 24,
    },
    label: {
      type: String,
      default: null,
    },
    labelPosition: {
      type: String, // left or right
      default: 'right',
    },
    color: {
      type: String,
      default: '#1565C0',
    },
  },
  emits: {
    onClick: null,
    onChange: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style module src="./radioButton.less"></style>
