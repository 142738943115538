<template>
  <div :style="{height: '100%'}">
    <stub />
    <portfolio-projects v-if="mode==='projects'" />
    <list-view v-if="mode==='list'" />
    <kanban v-if="mode==='board'" />
    <workload v-if="mode==='workload'" />
    <export v-if="mode==='export'" />
    <resources v-if="mode==='resources'" />
    <portfolio-dashboard v-if="mode==='dashboard'" />

    <confirm-popup
      :show="portfolioDeleteObj"
      :popupWidth="450"
      :closeIcon="true"
      :title="locale('project_list_delete_multiview')"
      :text="deleteConfirmMessage"
      :confirmMessageClass="'multiline'"
      :cancelButton="{
        title: locale('common_cancel'),
        type: 'secondary'
      }"
      :okButton="{
        title: locale('common_delete'),
        type: 'destructive'
      }"
      @onClickCancel="closeModal"
      @onClickOk="deletePortfolio"
    />

    <router-view />
  </div>
</template>

<script>
import routerGuard from '../../router/guards';
import routerHelper from '../../helpers/router';
import projectsModel from '../../models/projects';
import multiViewsProjects from '../../models/multiViewsProjects';
import Kanban from '../kanban/Kanban.vue';
import Export from '../export/Export.vue';
import Workload from './workloadPage/workloadPage.vue';
import ProjectResources from '../resources/main.vue';
import listView from '../listView/listView.vue';
import portfolioProjects from '../portfolioProjects/portfolioProjects.vue';
import PortfolioDashboard from '../portfolioDashboard/PortfolioDashboard.vue';
import stub from '../stubs/stubPage.vue';
import app from '../../app';
import ConfirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';

export default {
  name: 'MultiviewPage',
  components: {
    kanban: Kanban,
    export: Export,
    resources: ProjectResources,
    workload: Workload,
    'list-view': listView,
    portfolioProjects,
    PortfolioDashboard,
    ConfirmPopup,
    stub,
  },
  beforeRouteUpdate: routerGuard.multiViewGuard,
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'project') {
      projectsModel.activeGanttId = projectsModel.getFirstId();
    }

    const isEditingTitle = this.$store.getters['headerView/isEditingTitle'];
    isEditingTitle && this.$store.dispatch('headerView/saveHeadTitle');

    multiViewsProjects.clearActiveMultiView();

    next();
  },
  props: {
    mode: {
      type: String,
      default: 'gantt',
    },
  },
  data() {
    return {
      locale: __,
      portfolioDeleteObj: null,
      deleteConfirmMessage: __('settings_msg_delete_multiview_confirm_text')
    }
  },
  watch: {
    mode(mode) {
      projectsModel.setActiveProjectMode(mode);
    },
  },
  mounted() {
    projectsModel.setActiveProjectMode(this.mode); // init render
    app.on('portfolio:deleteConfirm', ({id, name}) => {
      this.portfolioDeleteObj = { id, name };
    });
  },
  beforeDestroy() {
    app.off('portfolio:deleteConfirm');
  },
  methods: {
    closeModal() {
      this.portfolioDeleteObj = null;
    },
    deletePortfolio() {
      let showDeleteToast = false;

      if (multiViewsProjects.getAllMultiviews().length === 1) {
        showDeleteToast = true;
      }

      multiViewsProjects.removeMultiview(this.portfolioDeleteObj.id);
      const name = this.portfolioDeleteObj.name;
      this.closeModal();
      routerHelper.pushRoute({name: 'portfolio'});

      if (showDeleteToast) {
        this.$toast.success(this.locale('portfolio_page_portfolio_deleted', { name }));
      }
    }
  }
};
</script>

<style scoped>

</style>
