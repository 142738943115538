import Vue from 'vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import Toast from 'vue-toastification';
import VueLazyload from 'vue-lazyload';
import VueMask from 'v-mask';
import PortalVue from 'portal-vue';
import VCalendar from 'v-calendar';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueEllipseProgress from 'vue-ellipse-progress';
// own components;
import SvgSprite from './views/common/VueComponents/VueIcon/svgSprite.vue';
import VgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import VgpInput from '$$vueCmp/vInput/vgpInput.vue';
import VgpCheckbox from '$$vueCmp/checkbox/vgpCheckbox.vue';
import corePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import colorPalette from '$$vueCmp/colorPalette/colorPalette.vue';
import statusSelect from '$$vueCmp/select/statusSelect/statusSelect.vue';
import VgpTabs from './views/common/VueComponents/tabs/vgpTabs.vue';
import Header from './views/header/header.vue';
import Footer from './views/footer/footer.vue';
import LeftSideBar from './views/leftSideBar/leftSideBar.vue';
import GlobalPopup from '$$vueCmp/popups/globalPopup/globalPopup.vue';
import PassStrengthPopup from '$$vueCmp/popups/passStrengthPopup/passStrengthPopup.vue';
import tooltip from './views/tooltip/tooltip.vue';
import PaymentPlans from './views/paymentPlans/paymentPlans.vue';
import StudentsPage from './views/paymentPlans/studentsPage/studentsPage.vue';
import VueGallery from './views/vueGallery/VueGallery.vue';
import infoPopup from '$$vueCmp/popups/infoPopup/infoPopup.vue';
import MaintenanceNotification from '$$vueCmp/maintenanceNotification/maintenanceNotification.vue';
import contextMenuPopup from '$$vueCmp/popups/contextMenuPopup/contextMenuPopup.vue';
import VgpAvatar from '$$vueCmp/avatar/vgpAvatar.vue';
import VgpRowInput from '$$vueCmp/vInput/vgpRowInput.vue';
import WebixUIComponent from './views/common/WebixUIComponent.vue';
import constants from '$$helpers/constants';
import videoComponent from '$$vueCmp/video/videoComponent.vue';

Vue.component('SvgSprite', SvgSprite);
Vue.component('VgpButton', VgpButton);
Vue.component('VgpInput', VgpInput);
Vue.component('VgpCheckbox', VgpCheckbox);
Vue.component('VgpCorePopup', corePopup);
Vue.component('VgpColorPalette', colorPalette);
Vue.component('HeaderVue', Header);
Vue.component('FooterVue', Footer);
Vue.component('MaintenanceNotification', MaintenanceNotification);
Vue.component('LeftSidebarVue', LeftSideBar);
Vue.component('GlobalPopup', GlobalPopup);
Vue.component('PassStrengthPopup', PassStrengthPopup);
Vue.component('TooltipPopup', tooltip);
Vue.component('PaymentPlans', PaymentPlans);
Vue.component('StudentsPage', StudentsPage);
Vue.component('VueGallery', VueGallery);
Vue.component('VgpTabs', VgpTabs);
Vue.component('StatusSelect', statusSelect);
Vue.component('InfoPopup', infoPopup);
Vue.component('ContextMenu', contextMenuPopup);
Vue.component('VgpAvatar', VgpAvatar);
Vue.component('VgpRowInput', VgpRowInput);
Vue.component('WebixUi', WebixUIComponent);
Vue.component('WebixUi', WebixUIComponent);
Vue.component('VideoComponent', videoComponent);

// use dependencies
Vue.use(VueMask);
Vue.use(VueLazyload);
Vue.use(PortalVue);
Vue.use(VueTippy, {
  directive: 'tippy',
  arrow: true,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        boundariesElement: 'window',
      },
    },
  },
  defaultProps: {
    placement: 'auto-end',
    allowHTML: true,

    popperOptions: {
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window',
        },
      },
    },
  },
});
Vue.use(Toast, constants.TOAST_CONFIG);
Vue.use(VueEllipseProgress);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.component('Tippy', TippyComponent);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
