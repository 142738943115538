import app, { appIds } from '../../app';
import zoomObject from './include/settingsZoom';
import durationObject from './include/settingsDuration';
import templates from '../../templates/labels';
import helper from './helpers';
import _ from '../../libs/lodash';
import icon_close from '../../svg/ic_close.svg';
import icon_question from '../../svg/question.svg';
import logInternalHelper from '../../helpers/logInternal';
import routerHelper from '../../helpers/router';
import ganttViewModel from '../../models/ganttViewModel';
import globalStore from '../../store/main';
import { addPricingTooltipToWebixElement, checkPricingAccess } from '../../helpers/pricingHelper';
import rights from '../../components/rights';
import projectsModel from '../../models/projects';

const __ = window.__;

const {
  events: { ID_EVENT_WINDOW_STATE_CHANGED },
  popupViews: { ID_VIEW_POPUP_PROJ_SETTINGS },
} = appIds;

webix.protoUI({
  name: 'ltext',
  defaults: { attributes: {} },
  $cssName: 'text',
  maxLength_setter(value) {
    this.config.attributes.maxLength = value;
  },
}, webix.ui.text);

if (!webix.env.touch && webix.env.scrollSize) {
  webix.CustomScroll.init();
}

const innerSettings = {
  isOpened: {},
  needRecalculateWorkload: false,
};

const innerViews = {
  main: {
    id: ID_VIEW_POPUP_PROJ_SETTINGS,
  },
  helpPopupView: {
    view: 'helpIcon',
    id: 'settingsHelpBlockView',
    dataId: 'settingsHelpBlock',
    css: 'help_icon_block settings_help_block settingsHelpBlock',
    width: 30,
    height: 30,
    zIndex: 1,
  },
  settingsForm: {
    id: 'settings_form',
  },
  autoSchedulingLabel: {
    view: 'label',
    id: 'autoSchedulingToggleLabel',
    css: 'label-with-spacer auto-scheduling-group',
    label: __('settings_auto_scheduling_mode'),
    width: 215,
    toolTip: {
      y: 4,
      langKey: 'settings_tooltips_autoScheduling',
    },
    template: templates.getTemplateForLabelWithTooltip,
  },
  autoSchedulingToggle: {
    view: 'toggle',
    type: 'icon',
    icon: '',
    id: 'autoScheduling',
    // width: 54,
    // borderless: true,
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.autoSchedulingChange(newValue, oldValue);
      },
    },
  },
  autoBudgetLabel: {
    view: 'label',
    id: 'autoBudgetToggleLabel',
    css: 'label-with-spacer auto-budget-group',
    label: __('settings_auto_budget_mode'),
    width: 215,
    toolTip: {
      y: 4,
      langKey: 'settings_tooltips_autoBudget',
    },
    template: templates.getTemplateForLabelWithTooltip,
  },
  autoBudgetToggle: {
    view: 'toggle',
    type: 'icon',
    icon: '',
    id: 'autoBudget',
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.autoBudgetChange(newValue);
      },
    },
  },
  progressCalcSegmentedTooltip: {
    view: 'label',
    width: 30,
    template: `<span class='b-tooltip icon-question tooltip-gantt' data-y='-4' data-key='settings_tooltips_progress'>${icon_question}</span>`,
  },
  progressCalcSegmented: {
    view: 'richselectWithoutPoint',
    label: __('settings_progress'),
    id: 'progressCalc',
    suggestCss: 'project-settings-dropdown',
    labelPosition: 'top',
    paddingY: 17,
    toolTip: {
      y: 4,
      x: 0,
      langKey: 'settings_tooltips_progress',
    },
    options: [
      { id: 'duration', value: __('settings_progress_duration') },
      { id: 'average', value: __('settings_progress_average') },
    ],
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.progressTypeChange(newValue, oldValue);
      },
      onAfterRender: helper.toolTipInitForTopLabels,
    },
  },
  estimationModes: {
    view: 'richselectWithoutPoint',
    label: __('settings_estimation_modes'),
    id: 'estimationModesSelect',
    suggestCss: 'project-settings-dropdown',
    labelPosition: 'top',
    toolTip: {
      y: 4,
      x: 0,
      langKey: 'settings_tooltips_estimation_modes',
    },
    suggest: {
      body: {
        data: [
          { id: 2, value: __('settings_fix_duration') },
          { id: 1, value: __('settings_fix_estimation') },
          { id: 3, value: __('settings_fix_duration_estimation') },
        ],
        template(obj) {
          return `<div class="pricing"><div class="pricing-text">${obj.value}</div></div>`;
        },
      },
      on: {
        onBeforeShow() {
          if (!checkPricingAccess('workload')) {
            innerHandlers.estimationModeChange('2');
            setTimeout(() => {
              const parent = document.querySelector('.project-settings-dropdown.suggest_popup_top');

              parent.removeAttribute('title');
              Object.entries(this.getList()._htmlmap)
                .map(([key, value]) => {
                  if (key === '2') return;
                  if (key === '1') {
                    addPricingTooltipToWebixElement(value, {
                      feature: 'workload_estimation',
                      position: 'left',
                      cssClass: 'pricing_project_settings',
                      selectorToAppendLabel: 'div.pricing',
                      tooltipCssClass: 'project_settings_tooltip',
                      disableClick: true,
                      additionalDif: 'first',
                    });
                  }
                  if (key === '3') {
                    addPricingTooltipToWebixElement(value, {
                      feature: 'workload_estimation_duration',
                      position: 'left',
                      cssClass: 'pricing_project_settings',
                      selectorToAppendLabel: 'div.pricing',
                      tooltipCssClass: 'project_settings_tooltip',
                      disableClick: true,
                      additionalDif: 'third',
                    });
                  }
                });
            });
          }
        },
      },
    },
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.estimationModeChange(newValue, oldValue);
      },
      onAfterRender: helper.toolTipInitForTopLabels,
    },
  },
  applyResourceCalendar: {
    id: 'applyResourceCalendarLayout',
    height: 26,
    cols: [
      {
        view: 'label',
        id: 'applyResourceCalendarLabel',
        css: 'label-with-spacer auto-scheduling-group',
        width: 215,
        toolTip: {
          y: 4,
          langKey: 'settings_tooltips_apply_resource_calendar',
        },
        template: templates.getTemplateForLabelWithTooltip,
        label: __('settings_apply_resource_calendar'),
      },
      {
        view: 'toggle',
        type: 'icon',
        icon: '',
        id: 'applyResourceCalendarToggle',
        css: 'webix_custom_toggle',
        labelPosition: 'top',
        on: {
          onChange(newValue, oldValue) {
            innerHandlers.toggleApplyResourceCalendar(newValue, oldValue);
          },
        },
      },
    ],
  },
  bottomFiller: {
    template: ' ',
    css: 'settings_popup_bottom_filler',
    borderless: true,
    height: 20,
  },
};

const innerHandlers = {
  scrollShadow() {
    const elementSettingsBody = $$('project_popup-body').getNode().classList;

    if ($$('projectSettingsMainScrollView').getScrollState().y > 0) {
      elementSettingsBody.add('js-active-shadow-top');
    } else {
      elementSettingsBody.remove('js-active-shadow-top');
    }
  },
  // @private (не вызвать если не показан)
  // смысл функции???
  initView() {
    innerHandlers.hidePopup();

    const block = $$('mainApplication');

    if (!block) return;

    const blockRect = block.$view.getBoundingClientRectWrapper();

    if (innerObject.settings.isOpened) {
      $$(innerViews.main.id).$view.style.transform = 'translateX(-100%)';

      return true;
    }
    $$(innerViews.main.id).$view.style.transform = 'translateX(0)';

    $$(innerViews.main.id).$view.style.left = `${blockRect.width}px`;
  },
  autoSchedulingChange(autoSchedulingState) {
    app.trigger('settings:autoScheduling:change', autoSchedulingState);
  },
  autoBudgetChange(autoBudgetState) {
    $$(innerViews.autoBudgetToggle.id).config.value = !autoBudgetState;
    $$(innerViews.autoBudgetToggle.id).refresh();
    app.trigger('setting:autoBudget:showConfirm', !!autoBudgetState);
  },
  getCurrentValueAutoScheduling() {
    return !!$$(innerViews.autoSchedulingToggle.id).getValue();
  },
  getCurrentValueAutoBudget() {
    return !!$$(innerViews.autoBudgetToggle.id).getValue();
  },
  getEstimationModesToggle() {
    return $$('estimationModesSelect').getValue();
  },
  getApplyResourceCalendarToggle() {
    return !!$$('applyResourceCalendarToggle').getValue();
  },
  getCurrentProgressMethod() {
    return $$(innerViews.progressCalcSegmented.id).getValue();
  },
  saveSettings() {
    durationObject.handlers.saveSettings();

    this.addCurrentSettingsParamToUpdate();

    innerHandlers.updateGanttViewParams();

    if (innerSettings.needRecalculateWorkload) {
      app.trigger('project:settings:workload:recalculate');
      innerSettings.needRecalculateWorkload = false;
    }
  },
  addCurrentSettingsParamToUpdate() {
    const autoScheduling = this.getCurrentValueAutoScheduling();
    const progressType = this.getCurrentProgressMethod();
    const estimationModes = this.getEstimationModesToggle();
    const applyResourceCalendar = this.getApplyResourceCalendarToggle();
    const ganttConfig = ganttViewModel.getProjectConfig();

    this.checkToUpdate('auto_scheduling', autoScheduling, ganttConfig);
    this.checkToUpdate('progress', progressType, ganttConfig);
    this.checkToUpdate('estimation_mode', estimationModes, ganttConfig);

    if (this.checkToUpdate('apply_resource_calendar', applyResourceCalendar, ganttConfig)) {
      innerSettings.needRecalculateWorkload = true;
    }
  },
  checkToUpdate(checkParam, newValueParam, objectData) {
    if (objectData[checkParam] !== newValueParam) {
      ganttViewModel.addParamsToUpdate({ key: checkParam, value: newValueParam });

      return true;
    }
  },
  progressTypeChange(newType) {
    // TODO: too much triggers :(
    app.trigger('settings:progressType:change', newType); // change consig
    innerHandlers.saveSettings(); // save config to project model (required for progress recalculation)
    app.trigger('progress:progressType:changed'); // trigger progress recaltulation
    app.trigger('popup:closeOther');
  },
  estimationModeChange(newValue) {
    if (+newValue !== gantt.estimationCalculator.MODES.fixEstimation) {
      $$('applyResourceCalendarToggle').setValue(false);
    }
    $$('applyResourceCalendarToggle')[+newValue === gantt.estimationCalculator.MODES.fixEstimation ? 'enable' : 'disable']();

    app.trigger('settings:estimationModes:change', newValue);
  },
  toggleApplyResourceCalendar(newValue) {
    app.trigger('settings:applyResourceCalendar:change', newValue);
  },
  updateGanttViewParams(ignoreFail) {
    if (app.config.mode.isBase) {
      ganttViewModel.updateGanttSettings();
    }
  },

  // @private
  hidePopup: () => {
    const popupID = innerViews.main.id;

    if (innerSettings.isOpened[popupID]) {
      innerHandlers.togglePopup(popupID);
    }
  },
  // @private
  showPopup() {
    const popupID = innerViews.main.id;

    innerHandlers.togglePopup(popupID);
  },

  // TEST TOGGLE POPUP
  togglePopup() {
    const popupID = innerViews.main.id;

    if (innerSettings.isOpened[popupID]) {
      $$(innerViews.main.id).$view.style.transform = 'translateX(0)';
      innerHandlers.saveSettings();
      // _.delay(function () { $$(popupID).hide(); }, 500);
      userExtAnalytics.log('project_settings_close');
      gantt.setBlockingCollaborationByPopup(popupID, false);
    } else {
      $$(popupID).show();
      _.delay(() => {
        $$(innerViews.main.id).$view.style.transform = 'translateX(-100%)';
      });

      userExtAnalytics.log('project_settings_open', { type: innerSettings.type });
      gantt.setBlockingCollaborationByPopup(popupID, true);
    }

    innerSettings.isOpened[popupID] = !innerSettings.isOpened[popupID];
    globalStore.commit('headerView/toggleSettingsPopup', innerSettings.isOpened[popupID]);

    return innerSettings.isOpened[popupID];
  },
};

var innerObject = {
  init: {
    run(ganttId) {
      const viewData = ganttViewModel.getActiveViewData();
      const projectConfig = ganttViewModel.getProjectConfig();
      const canEdit = ganttId ? rights.project.hasRight(ganttId, 'project_settings') : ganttViewModel.isMultiview();

      innerObject.settings.updatedConfigParams = [];

      this.autoScheduling(projectConfig);
      this.autoBudget(projectConfig);
      this.progressMethod(projectConfig);
      this.estimationModes(projectConfig);
      this.applyResourceCalendar(projectConfig);

      this.updateProjectWithNewParams(projectConfig);
      this.initChildObjects();
      innerHandlers.updateGanttViewParams();

      if (app.config.mode.isBase) {
        // this.initFileStore(viewData);
      }

      if (!canEdit || app.config.mode.isLink) {
        this.initLinkMode();
      } else {
        this.initBaseMode();
      }

      // if (!user.subscription.activites) {
      //   $$('fileStoreValue').hide();
      // }

      $$(innerViews.main.id).$view.style.transform = 'translateX(0)';

      innerObject.settings.isOpened = false;
      globalStore.commit('headerView/toggleSettingsPopup', false);
      app.trigger('settings:init', innerObject.settings.optionsInit);
    },
    reInitByRights(ganttId) {
      if (ganttId && ganttId !== gantt.config.gantt_id) {
        return;
      }

      const canEdit = rights.project.hasRight(ganttId, 'project_settings');

      if (!canEdit || app.config.mode.isLink) {
        this.initLinkMode();
      } else {
        this.initBaseMode();
      }

      $$(innerViews.main.id).$view.style.transform = 'translateX(0)';

      innerObject.settings.isOpened = false;
    },
    autoScheduling(projectConfig) {
      const autoSchedulingToggle = $$(innerViews.autoSchedulingToggle.id);

      projectConfig = this.checkParamAndUpdateConfig('auto_scheduling', projectConfig);

      autoSchedulingToggle.config.value = projectConfig.auto_scheduling;
      autoSchedulingToggle.refresh();
    },
    autoBudget(projectConfig) {
      const autoBudgetToggle = $$(innerViews.autoBudgetToggle.id);

      projectConfig = this.checkParamAndUpdateConfig('auto_budget', projectConfig);

      autoBudgetToggle.config.value = projectConfig.auto_budget;
      autoBudgetToggle.refresh();
    },
    progressMethod(projectConfig) {
      projectConfig = this.checkParamAndUpdateConfig('progress', projectConfig);

      $$(innerViews.progressCalcSegmented.id).config.value = projectConfig.progress || innerObject.defaultSettings.progress;
      $$(innerViews.progressCalcSegmented.id).refresh();
    },
    estimationModes(projectConfig) {
      projectConfig.estimation_mode = +projectConfig.estimation_mode || gantt.estimationCalculator.MODES.fixDuration;
      projectConfig = this.checkParamAndUpdateConfig('estimation_mode', projectConfig);

      if (!checkPricingAccess('workload')) {
        $$('estimationModesSelect').setValue('2');

        return;
      }
      $$('estimationModesSelect').setValue(projectConfig.estimation_mode);
    },
    applyResourceCalendar(projectConfig) {
      projectConfig.apply_resource_calendar = !!projectConfig.apply_resource_calendar;
      projectConfig = this.checkParamAndUpdateConfig('apply_resource_calendar', projectConfig);

      if (!checkPricingAccess('workload')) {
        $$('applyResourceCalendarToggle').config.value = false;
        $$('applyResourceCalendarToggle').refresh();

        return;
      }

      $$('applyResourceCalendarToggle').config.value = projectConfig.apply_resource_calendar;
      $$('applyResourceCalendarToggle').refresh();
    },
    checkParamAndUpdateConfig(checkParam, projectConfig) {
      if (typeof checkParam === 'string' && projectConfig[checkParam] === undefined) {
        projectConfig[checkParam] = innerObject.defaultSettings[checkParam];
        innerObject.settings.updatedConfigParams.push({ key: checkParam, value: projectConfig[checkParam] });
        innerObject.settings.needUpdateConfig = true;
      }

      return projectConfig;
    },
    updateProjectWithNewParams(projectConfig) {
      if (innerObject.settings.needUpdateConfig && app.config.mode.isBase) {
        _.each(innerObject.settings.updatedConfigParams, configParam => {
          ganttViewModel.addParamsToUpdate(configParam);
        });

        innerObject.settings.needUpdateConfig = false;
        innerObject.settings.updatedConfigParams = [];
      }
    },
    initChildObjects() {
      const initChild = child => child.handlers.init(gantt.config.gantt_id);

      _.each([
        zoomObject,
        durationObject,
      ], initChild);
    },
    showSettings() {
      const projectConfig = ganttViewModel.getProjectConfig();
      const isMultiview = routerHelper.isMultiProjectsRoute();
      const ganttId = projectsModel.getActiveGanttId();

      if (isMultiview) {
        this.initMultiviewMode();
      } else {
        const canEdit = rights.project.hasRight(ganttId, 'project_settings');

        if (!canEdit || app.config.mode.isLink) {
          this.initLinkMode();
        } else {
          this.initBaseMode();
        }
      }

      this.autoScheduling(projectConfig);
      this.autoBudget(projectConfig);
      this.progressMethod(projectConfig);

      durationObject.handlers.showPopup();

      !isMultiview && zoomObject.handlers.updateLabels();

      if (!checkPricingAccess('workload')) $$(innerViews.applyResourceCalendar.id).hide();
      if (!checkPricingAccess('costs') || !rights.project.hasCostRight(ganttId)) $$(innerObject.views.autoBudgetLayout.id).hide();
    },
    initLinkMode() {
      $$(innerObject.views.customDaysLayout.id).hide();
      $$(innerObject.views.autoSchedulingLayout.id).hide();
      $$(innerObject.views.autoBudgetLayout.id).hide();

      zoomObject.handlers.initLinkMode();
      durationObject.handlers.initLinkMode();

      $$(innerViews.progressCalcSegmented.id).hide();
      $$(innerViews.estimationModes.id).hide();
      $$(innerViews.applyResourceCalendar.id).hide();
    },
    initBaseMode() {
      $$(innerObject.views.customDaysLayout.id).show();
      $$(innerObject.views.autoSchedulingLayout.id).show();
      $$(innerObject.views.autoBudgetLayout.id).show();

      zoomObject.handlers.initBaseMode();
      durationObject.handlers.initBaseMode();

      $$(innerViews.progressCalcSegmented.id).show();
      $$(innerViews.estimationModes.id).show();
      $$(innerViews.applyResourceCalendar.id).show();
    },
    initMultiviewMode() {
      $$(innerObject.views.customDaysLayout.id).hide();
      $$(innerObject.views.autoSchedulingLayout.id).hide();
      $$(innerObject.views.autoBudgetLayout.id).hide();

      $$(innerViews.progressCalcSegmented.id).hide();
      $$(innerViews.estimationModes.id).hide();
      $$(innerViews.applyResourceCalendar.id).hide();
    },
  },
  views: {
    customDaysLayout: {
      id: 'projectSettingsMainUpPart',
      css: 'projectSettingsMainPart',
      // height: 'auto',
      borderless: false,
      rows: [
        zoomObject.webixUI,
      ],
    },
    autoSchedulingLayout: {
      id: 'autoSchedulingGroup',
      height: 26,
      cols: [
        innerViews.autoSchedulingLabel,
        innerViews.autoSchedulingToggle,
      ],
    },
    autoBudgetLayout: {
      id: 'autoBudgetGroup',
      css: 'setting-auto-budget',
      cols: [
        innerViews.autoBudgetLabel,
        innerViews.autoBudgetToggle,
      ],
    },
  },
  settings: {
    isOpened: false,
    projectId: 0,
    needUpdateConfig: false,
    updatedConfigParams: [],
    optionsInit: {},
  },
  defaultSettings: {
    auto_scheduling: true,
    progress: 'duration',
    highlight_critical_path: false,
  },
};

webix.ui({
  view: 'window',
  width: 300,
  height: 600,
  id: 'settings_popup',
  css: 'settings_popup clear-window-popup settings_popup-view settings-project_popup',
  modal: false,
  hidden: true,
  zIndex: 100,
  borderless: true,
  move: false,
  // unClosable: true,
  isShown() {
    return innerObject.settings.isOpened;
  },
  head: {
    css: 'settings_popup_header',
    height: 46,
    paddingY: 8,
    cols: [
      { width: 18 },
      {
        view: 'label',
        label: __('settings_project_settings'),
      },
      { width: 8 },
      innerViews.helpPopupView,
      // {width: 8},
      {
        view: 'icon',
        width: 30,
        template: `<div class='window_header_icon'>${icon_close}</div>`,
        on: {
          onItemClick() {
            innerHandlers.hidePopup(true);
            app.trigger('helpPopup:close');
            app.trigger('popup:hide');
          },
        },
      },
      { width: 8 },
    ],
  },
  body: {
    id: 'project_popup-body',
    css: 'project_popup-body',
    rows: [
      {
        view: 'scrollview',
        // scroll: "y",
        id: 'projectSettingsMainScrollView',
        css: 'projectSettingsMainView',
        // autoheight: true,
        // paddingX: 0,
        // paddingY: 0,
        body: {
          rows: [
            {
              view: 'form',
              id: innerViews.settingsForm.id,
              // width: 310,
              margin: 12,
              paddingX: 16,
              // height: 'auto',
              borderless: false,
              elements: [
                innerObject.views.customDaysLayout,
                innerObject.views.autoSchedulingLayout,
                innerObject.views.autoBudgetLayout,
                {
                  // paddingX: 16,
                  rows: [
                    { height: 10 },
                    durationObject.webixUIDurationView,
                    // { height: 17 },
                    durationObject.webixUIResourceLoadingType,
                    // { height: 17 },
                    // skinObject.webixUI_skinSegmented,
                    // { height: 17 },
                    // skinObject.webixUI_skinViewModeSegmented,
                    // { height: 17 },
                    innerViews.progressCalcSegmented,
                    { height: 17 },
                    innerViews.estimationModes,
                    { height: 17 },
                    innerViews.applyResourceCalendar,
                  ],
                },
                {},
                innerViews.bottomFiller,
              ],
            },
          ],
        },
        on: {
          onAfterScroll() {
            webix.callEvent('onClick', []);
          },
        },
      },
      {
        css: 'settings_popup-bottom-scroll-layout', height: '0',
      },
    ],
  },
  position(state) {
    const headerHeight = globalStore.getters['headerView/UIConfig'] && globalStore.getters['headerView/UIConfig'].sumHeight || 0;

    state.height = document.body.clientHeight - headerHeight - 29 + 42;
    state.width = this.config.width;
    state.top = headerHeight < 100 ? headerHeight : headerHeight - 41;
    state.left = state.maxWidth;

    if (innerObject.settings.isOpened) {
      return true;
    }
  },
  on: {
    onBeforeShow() {
      app.trigger('popup:closeOther', 'settings_popup');
      innerObject.init.showSettings();
    },
    onHide() {
      app.trigger('popup:hide');
    },
    onShow() {
      logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
    },
  },
});

// ???
const outputObject = {
  handlers: {
    // @public
    togglePopup() {
      // todo this
      if (!innerObject.settings.isOpened) {
        innerHandlers.showPopup();
      } else {
        innerHandlers.hidePopup();
      }
    },
  },
};

app.on('body:resize', () => {
  innerHandlers.initView();
});

app.on('gantt:area:click', () => {
  innerHandlers.hidePopup(true);
});

app.on('popup:show', () => {
  innerHandlers.hidePopup(true);
});

app.on('settings:startOnMonday:change', () => {
  zoomObject.handlers.updateLabels();
});

app.on('popup:closeOther', id => {
  if (id !== 'settings_popup') {
    innerHandlers.hidePopup(true);
  }
});

app.on('closeWindowWithAnimation', () => {
  innerHandlers.hidePopup(true);
});

app.on('activeProject:afterSet', (ganttId, projectData) => {
  _.delay(() => {
    innerObject.init.run(ganttId);
  });
});

app.on('activeMultiProjects:set', () => {
  _.delay(() => {
    innerObject.init.run();
  });
});

app.on('user:changedRole', ganttId => {
  _.delay(() => {
    innerObject.init.reInitByRights(ganttId);
  });
});

app.on('project:archive', ganttId => {
  const activeProjectId = projectsModel.getActiveGanttId();

  if (activeProjectId === ganttId) {
    innerHandlers.hidePopup(true);
  }
});

app.on('settings:autoBudget:change', ({ autoBudgetState }) => {
  $$(innerViews.autoBudgetToggle.id).config.value = autoBudgetState;
  $$(innerViews.autoBudgetToggle.id).refresh();
});

window.onbeforeunload = function (event) {
  $$(innerViews.main.id).isVisible() && innerHandlers.saveSettings();
};

webix.event(window, 'resize', () => {
  innerHandlers.initView();
});

export default outputObject;
