import app from "./../app";
import popupModel from "./../models/popups/main";
import update_icon from "./../svg/release/update.svg";

const __ = window.__;

var innerObject = {
  views: {
    main: {
      id: "releasePopup"
    },
    titleLabel: {
      view: "label",
      css: "titleLabel",
      align: "center",
      template: update_icon + "<div class='releaseLabel'>" + __("release_popup_default_label") + "</div>"
    },
    featuresList: {
      view: "datatable",
      id: "featuresListDataTable",
      css: "featuresList",
      header: false,
      autoheight: true,
      minHeight: 200,
      maxHeight: 800,
      borderless: true,
      fixedRowHeight: false,
      rowLineHeight: 25,
      isWork: false,
      navigation: false,
      columns: [
        {
          id: "featureIcon", width: 60,
          template: function (object) {
            return "<img src='" + object.featureIcon + "'>";
          }
        },
        {
          id: "featureName", fillspace: true, css: "featureText",
          template: function (object) {
            return "<div><span class='featureName'>" + object.featureName + "</span>" +
              "<span class='featureDesc'>" + object.featureDesc + "</span>" +
              "<a href='" + object.faqLink + "' target='_blank'>" + __("common_read_more") + "</a>" +
              "<span class='advancedPadding'></span></div>";
          }
        }
      ],
      data: [],
      on: {
        "onAfterLoad": function () {
          if (this.config.isWork) {
            this.adjustRowHeight("featureName", false);
            this.refresh();
          }
        }
      }
    },
    featuresTeamList: {
      view: "datatable",
      id: "featuresTeamListDataTable",
      css: "featuresTeamList",
      header: false,
      autoheight: true,
      minHeight: 40,
      maxHeight: 80,
      borderless: true,
      hidden: true,
      fixedRowHeight: false,
      rowLineHeight: 25,
      isWork: false,
      navigation: false,
      columns: [
        {
          id: "featureIcon", width: 60,
          template: function (object) {
            return "<img src='" + object.featureIcon + "'>";
          }
        },
        {
          id: "featureName", width: 440, css: "featureText",
          template: function (object) {
            return "<div class='featureName'>" + object.featureDesc + "</div>";
          }
        }
      ],
      data: [],
      on: {
        "onAfterLoad": function () {
          if (this.config.isWork) {
            this.adjustRowHeight("featureName", false);
          }
        }
      }
    },
    featureSpacer: {
      id: "featureSpacer",
      height: 2,
      css: "spacer",
      borderless: false,
      hidden: true,
      template: ""
    },
    featuresButton: {
      view: "button",
      type: "success",
      align: "center",
      width: 250,
      css: "webix_button_default button_raised",
      value: __("release_popup_default_button"),
      click: function () {
        innerObject.handlers.buttonOkClick();
      }
    }
  },
  handlers: {
    popupWithUpdateData: function () {
      $$(innerObject.views.main.id).show();
    },
    buttonOkClick: function () {
      $$(innerObject.views.main.id).hide();
    }
  },
  helpers: {

  },
  dataStoreEvents: {
    popupModel: {
      storeUpdateInit: function () {
        popupModel.data.attachEvent("showPopup", function (popupMode) {
          popupMode === "updateRelease" && innerObject.handlers.popupWithUpdateData();
        });
      }
    }
  },
  settings: {
    popupId: 0
  }
};

app.on("gantt:init:before", function () {
  if (app.config.mode.isBase) {
    innerObject.dataStoreEvents.popupModel.storeUpdateInit();
    popupModel.checkReleasePopup();
  }
});

var outputObject = {
  event: {}
};


webix.ui({
  view: "popup",
  id: "releasePopup",
  css: "release_update",
  borderless: true,
  head: false,
  modal: true,
  hidden: true,
  autoheight: true,
  position: "center",
  body: {
    type: "wide",
    paddingX: 24,
    paddingY: 14,
    borderless: true,
    width: 550,
    rows: [
      innerObject.views.titleLabel,
      {height: 20},
      innerObject.views.featuresList,
      {height: 15},
      innerObject.views.featureSpacer,
      innerObject.views.featuresTeamList,
      {height: 10},
      innerObject.views.featuresButton
    ]
  }
});

export default outputObject;
