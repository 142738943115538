var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "inputField",
      class: {
        colorPallete_disabled: _vm.disabled,
        "vgp-interactive-element": true,
      },
      style: { position: "relative" },
    },
    [
      _vm.selectField === "select"
        ? _c(
            "div",
            {
              staticClass: "input_field_wrap",
              style: {
                width: _vm.selectWidth,
                height: _vm.selectHeight + "px",
              },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "input_field",
                    _vm.illuminateOnHover ? "input_hover" : "",
                    !_vm.border ? "input_borderless" : "",
                    _vm.isHightlightBorder ? "active" : "",
                    _vm.paletteOpen ? "open_palette" : "",
                  ],
                  attrs: { id: _vm.gpAutotestName },
                  on: { click: _vm.showPalette },
                },
                [
                  _vm.checked.length
                    ? _c(
                        "div",
                        {
                          class: "input_field_selected ai-center",
                          style: { width: "100%" },
                        },
                        [
                          _vm.multiselect
                            ? _c(
                                "div",
                                [
                                  _c("vgpRollItems", {
                                    attrs: {
                                      items: _vm.checked || [],
                                      offset: 4,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("div", {
                                                class: ["color-palette_item"],
                                                style: {
                                                  "background-color":
                                                    _vm.colors.find(function (
                                                      color
                                                    ) {
                                                      return color.id === item
                                                    }).hex,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3554108941
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                _vm._l(_vm.checked, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index + item.id },
                                    [
                                      [
                                        _c("div", {
                                          class: ["color-palette_item"],
                                          style: {
                                            "background-color": _vm.colors.find(
                                              function (color) {
                                                return color.id === item.id
                                              }
                                            ).hex,
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  )
                                }),
                                0
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.checked.length
                    ? _c("div", { class: "input_placeholder" }, [
                        _vm.placeholder
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("sprite", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowResetBtn,
                            expression: "isShowResetBtn",
                          },
                        ],
                        staticClass: "vgp_colorPallete_reset_icon",
                        attrs: { name: "close-2", type: "bold", size: 16 },
                        on: { click: _vm.onClickReset },
                      }),
                      _vm._v(" "),
                      _c("sprite", {
                        staticClass: "input_field_icon",
                        attrs: { name: "drop-down", type: "bold" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectField === "square"
        ? _c(
            "div",
            _vm._l(_vm.checked, function (item, index) {
              return _c(
                "div",
                { key: index + item.id, on: { click: _vm.showPalette } },
                [
                  [
                    _c("div", {
                      class: ["color-palette_item", "item_offset"],
                      style: { "background-color": item.hex },
                    }),
                  ],
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.paletteOpen,
              expression: "paletteOpen",
            },
          ],
          ref: "colorPalette",
          class: [
            "gantt_scroll",
            "color-palette",
            _vm.dropToBottom ? "bottom_drop" : "top_drop",
          ],
        },
        [
          _vm._l(_vm.colors, function (option, index) {
            return _c(
              "div",
              {
                key: index + option.id,
                class: ["color-palette_item", "item_offset"],
                style: {
                  "background-color":
                    _vm.hoverColor === option.id ? option.hex2 : option.hex,
                },
                on: {
                  mouseover: function ($event) {
                    _vm.hoverColor = option.id
                  },
                  mouseleave: function ($event) {
                    _vm.hoverColor = null
                  },
                  click: function ($event) {
                    return _vm.selectItem(option)
                  },
                },
              },
              [
                _vm.checked.length
                  ? _c("sprite", {
                      staticClass: "color-palette_item_icon",
                      style: {
                        display: _vm.isSelectedColor(option) ? "block" : "none",
                      },
                      attrs: { color: "white", name: "check" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.multiselect && _vm.isHasSelectedColor
            ? _c("div", { class: "reset", on: { click: _vm.onClickReset } }, [
                _vm._v("\n      " + _vm._s("Reset") + "\n    "),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }