<template>
  <div :class="$style.add_project_wrapper">
    <vgp-button :class="$style.new_button" @onClick="togglePopup" :label="locale('add_project')"
      :icon="{ name: 'add-project', size: 20, type: regular }" iconSide="left"
      type="primary" xSmall />

    <core-popup
      :show="showPopup"
      :title="locale('add_project_to_portfolio')"
      :closeIcon="true"
      :buttonsSwitch="true"
      :okButtonTitle="locale('common_add')"
      :cancelButtonTitle="locale('common_cancel')"
      :disabledCancel="!selectedProjects.length"
      :bodyStyle="'addProjectToPortfolio'"
      :footerStyle="overflowedList && filteredProjects.length ? 'flex-end-with-shadow' : 'flex-end'"
      @onClickOk="addProjectsToPortfolio"
      @onClickCancel="togglePopup"
    >
      <template #body>
        <div :class="$style.popup_content">
          <div :class="$style.search_wrapper">
            <vgp-input v-model="searchStr" :style="{ outline: '#fff' }" :class="'search-input'" :type="'text'"
              :placeholder="locale('search_projects_placeholder')" :icons-left="[{ name: 'search' }]" :small="true"
              :select-by-focus="false" />
          </div>

          <div :class="$style.projects" ref="projectsWrapper">
            <!-- commented until further performance improvements (big load time on portfolio creation) -->
            <!-- <div :class="[$style.item, $style.select_all]" v-if="filteredProjects.length">
              <global-button
                :config="{
                  isGlobal: false,
                  className: 'text-btn',
                  locale: locale(isAnySelected ? 'multicombo_unselect_all_items' : 'multicombo_select_all_items'),
                  handler: toggleSelectAll
                }"
              />
            </div> -->
            <div :class="$style.item" v-for="project in filteredProjects" @click="project.selected = !project.selected">
              <vgp-checkbox
                :size="16"
                :label="project.name"
                label-position="right"
                :checked="project.selected"
                @onClick="project.selected = !project.selected"
              />
            </div>
            <div
              :class="[$style.item, $style.no_items]"
              v-if="!filteredProjects.length && availableProjects.length"
            >
              <svg-image v-if="overflowedList" :name="'comment-hub-no-search'" />
              <div :class="overflowedList ? $style.small_text : $style.medium_text">
                {{ locale('no_matches_message_text') }}
              </div>
            </div>
            <div
              :class="[$style.item, $style.no_items, $style.all_added]"
              v-if="!availableProjects.length"
            >
              <svg-image
                :name="'comment-hub-no-tasks'"
                :style="{ width: '130px', height: '76px' }"
              />
              <div :class="overflowedList ? $style.small_text : $style.medium_text">
                {{ locale('portfolio_all_projects_added') }}
              </div>
            </div>
          </div>

        </div>
      </template>
    </core-popup>
  </div>
</template>

<script>
import corePopup from "../../common/VueComponents/popups/corePopup/corePopup.vue";
import globalButton from "../../common/VueComponents/globalButton/globalButton.vue";
import svgImage from '../../common/VueComponents/VueIcon/vgpSvgImage.vue';
import projects from "../../../models/projects";
import multiViewsProjects from "../../../models/multiViewsProjects";
import app from '../../../app';

export default {
  name: "AddProjectButton",
  components: { corePopup, globalButton, svgImage },
  created() {
    this.portfolio = multiViewsProjects.getActiveViewData();
    this.initAllProjects();

    const multiviewListener_1 = multiViewsProjects.data.attachEvent('onDataUpdate', (id, data, old) => {
      if (this.portfolio.id === id) {
        this.portfolio = multiViewsProjects.getActiveViewData();
      }
    });

    const projectsListener_1 = projects.data.attachEvent('projectListRefreshed', () => {
      this.initAllProjects();
    });

    const appListener_1 = app.on('project:archive', () => {
      this.initAllProjects();
    });

    const appListener_2 = app.on('activeMultiProjects:set', () => {
      this.initAllProjects();
    });

    this.multiviewListeners = [
      multiviewListener_1
    ];

    this.projectsListeners = [
      projectsListener_1
    ];

    this.appListeners = [
      appListener_1,
      appListener_2
    ];
  },
  beforeDestroy() {
    this.multiviewListeners.forEach(id => multiViewsProjects.data.detachEvent(`${id}`));
    this.projectsListeners.forEach(id => projects.data.detachEvent(`${id}`));
    this.appListeners.forEach(id => app.off(id));
  },
  data() {
    return {
      locale: __,
      showPopup: false,
      allProjectsList: [],
      portfolio: null,
      searchStr: '',
      multiviewListeners: [],
      projectsListeners: [],
      appListeners: [],
      overflowedList: false
    };
  },
  methods: {
    initAllProjects() {
      this.allProjectsList = projects.getAllProjects().filter(project => !project.is_jira).map((item) => ({
        ...item,
        selected: false,
      }));
    },
    togglePopup() {
      if (this.showPopup) {
        this.allProjectsList = this.allProjectsList.map((item) => ({
          ...item,
          selected: false,
        }));
        this.searchStr = '';
      }

      this.showPopup = !this.showPopup;

      this.$nextTick(() => {
        if (this.$refs.projectsWrapper && this.availableProjects.length) {
          const clientHeight = this.$refs.projectsWrapper.clientHeight;
          this.$refs.projectsWrapper.style.minHeight = `${clientHeight}px`;
          if (this.$refs.projectsWrapper.scrollHeight > clientHeight) {
            this.overflowedList = true;
          } else {
            this.overflowedList = false;
          }
        }
      })
    },
    toggleSelectAll() {
      const isAnySelected = this.isAnySelected;
      this.filteredProjects.forEach((item) => {
        item.selected = !isAnySelected;
      });
    },
    getSortedGanttIDs(portfolio) {
      const ganttIDs = [ ...portfolio.ganttIDs ];
      const order = [ ...portfolio.config.order ];
      if (!order || !order.length) return ganttIDs;

      const orderMap = order.reduce((acc, curr, currIndex) => {
        acc[curr] = currIndex + 1;

        return acc;
      }, {})
      
      ganttIDs.sort((prev, next) => orderMap[prev] - orderMap[next]);

      return ganttIDs;
    },
    addProjectsToPortfolio() {
      const selectedIDs = this.selectedProjects.map(item => item.id);
      multiViewsProjects.updateMultiview(this.portfolio.id, this.getSortedGanttIDs(this.portfolio).concat(selectedIDs));

      this.togglePopup();
    }
  },
  computed: {
    availableProjects() {
      return this.allProjectsList.filter((item) => !this.portfolio.ganttIDs.includes(item.id));
    },
    filteredProjects() {
      return this.availableProjects
        .filter((item) => item.name.toLowerCase().includes(this.searchStr.toLowerCase()));
    },
    selectedProjects() {
      return this.filteredProjects.filter((item) => item.selected);
    },
    isAnySelected() {
      return this.selectedProjects.length;
    }
  }
};
</script>

<style module src="../less/addProjectButton.less"></style>
