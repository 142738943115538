/* eslint-disable no-undef, no-param-reassign, no-unused-vars */
import app from '../../../app';

import _ from '../../../libs/lodash';

import projectsModel from '../../../models/projects';
import customDayTemplatesModel from '../../../models/customDayTemplates';

import constants from '../../../helpers/constants';
import resourcesModule from './resources';
import moment from '../../../libs/moment';
import globalStore from '$$store/main';

let _worker = null;
let _filterGanttIds = [];

function _createGanttWorker(workerPath) {
  const worker = new Worker(workerPath);

  return function (workerData) {
    return new Promise((resolve, reject) => {
      worker.onmessage = function (e) {
        worker.onmessage = null;
        worker.onerror = null;
        return resolve(e.data);
      };

      worker.onerror = function (e) {
        worker.onmessage = null;
        worker.onerror = null;
        return reject(e);
      };

      worker.postMessage(workerData);
    });
  };
}

const _init = function () {
  let version = Date.now();

  if (GT.productVersion && GT.productVersion.buildVersion) {
    version = GT.productVersion.buildVersion;
  }

  _worker = _createGanttWorker(`/workers/gantt_worker.js?${version}`);
};

const _dataComposers = {
  changeTasks(data) {
    const ganttIdsMap = {};
    const preparedData = {};

    data.forEach(taskChangeData => {
      const ganttId = taskChangeData.gantt_id;
      ganttIdsMap[ganttId] = true;

      if (!preparedData[ganttId]) preparedData[ganttId] = [];

      preparedData[ganttId].push(taskChangeData);
    });

    _filterGanttIds = Object.keys(ganttIdsMap);

    return preparedData;
  },
  updateLinks(data) {
    const ganttIdsMap = {};
    const preparedData = {};

    data.forEach(linkChangeData => {
      const ganttId = linkChangeData.gantt_id;

      ganttIdsMap[ganttId] = true;

      if (!preparedData[ganttId]) preparedData[ganttId] = [];

      preparedData[ganttId].push(linkChangeData);
    });

    _filterGanttIds = Object.keys(ganttIdsMap);

    return preparedData;
  },
  afterMassChangeAssign(massChangeData) {
    const ganttIdsMap = {};
    const preparedData = {};

    massChangeData.forEach(data => {
      const ganttId = data.taskData.gantt_id;

      data.resourcesToAdd.forEach(r => {
        r.resource_id = r.idResource;
      });

      const timeTypeResources = resourcesModule.getTimeTypeTaskResources(
        {...data.taskData, ...{resources: data.resourcesToAdd}}
      );

      data.taskData.estimation = data.taskChanges.estimation;// mass change recalculated estimation

      if (!preparedData[ganttId]) preparedData[ganttId] = [];

      preparedData[ganttId].push({
        data: data.taskData,
        timeTypeResources,
      });
      ganttIdsMap[ganttId] = true;
    });

    _filterGanttIds = Object.keys(ganttIdsMap);

    return preparedData;
  },
  afterChangeResourceCalendar(resourceId) {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](resourceId);
    let ganttIds = resourceData.resourceProjects.map(settings => settings.type === constants.RESOURCE_TIME_TYPE && settings.projectId);

    ganttIds = ganttIds.filter(ganttId => {
      const projectConfig = projectsModel.getProjectConfig(ganttId);

      if (!projectConfig) {
        return false;
      }

      const isFixedEstimation = +projectConfig.estimation_mode === gantt.estimationCalculator.MODES.fixEstimation;

      return isFixedEstimation && projectConfig.apply_resource_calendar;
    });

    const tasks = globalStore.getters['tasksModel/getTasksByResourceIdAndGanttIds'](resourceId, ganttIds).map(t => t.taskData);

    if (!tasks.length) {
      return;
    }

    _filterGanttIds = ganttIds;

    const preparedData = {};
    const groupedData = _.groupBy(tasks, 'gantt_id');

    Object.keys(groupedData).forEach(ganttId => {
      const dataByGanttId = groupedData[ganttId];

      preparedData[ganttId] = dataByGanttId.map(task => ({
        data: task,
        timeTypeResources: resourcesModule.getTimeTypeTaskResources(task),
      }));
    });

    return preparedData;
  },
};

const _innerHelpers = {
  prepareModelsData() {
    const ganttIds = (_filterGanttIds.length && _filterGanttIds) || projectsModel.serialize().map(o => o.gantt_id);
    const projectsCustomDays = customDayTemplatesModel.serialize();
    const resultData = {
      projectConfigs: {},
      tasksData: {},
      commonGanttConfig: {
        start_on_monday: gantt.config.start_on_monday,
      },
    };

    ganttIds.forEach(ganttId => {
      const totalData = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](ganttId);
      const calendar = gantt.getCalendar(ganttId);
      const projectConfig = projectsModel.getProjectConfig(ganttId);
      const customDaysData = projectsCustomDays.find(data => +data.ganttId === +ganttId);

      if (!calendar) {
        return;
      }

      resultData.projectConfigs[ganttId] = {
        calendar: {
          id: ganttId,
          worktime: {
            hours: calendar.getConfig().hours,
            days: _.values(calendar.getConfig().dates),
          },
        },
        ganttConfig: {
          auto_scheduling: projectConfig.auto_scheduling,
          estimation_mode: projectConfig.estimation_mode,
          apply_resource_calendar: projectConfig.apply_resource_calendar,
          progress: projectConfig.progress,
        },

        customDays: customDaysData ? customDaysData.customDays : [],
        totalDates: {
          start_date: totalData.start_date,
          end_date: totalData.end_date,
        },
      };

      resultData.tasksData[ganttId] = globalStore.getters['tasksModel/getTasksForGantt'](ganttId);
    });

    return resultData;
  },
  formatTaskData(task) {
    if (task.constraint_date) {
      task.constraint_date = moment(task.constraint_date).toDate();
    }
    if (task.deadline) {
      task.deadline = moment(task.deadline).toDate();
    }
  },
  calculate: async (data, composer, action) => {
    _filterGanttIds = [];

    const updatedData = _dataComposers[composer](data);

    if (!updatedData) {
      return Promise.resolve(false);
    }

    //app.trigger('gantt:progress:show');//

    const innerData = _innerHelpers.prepareModelsData();
    const calculatedGanttData = await _worker({ innerData, updatedData, action: action || composer });

    if (!calculatedGanttData.tasks.length && !calculatedGanttData.links.length) {
      app.trigger('gantt:progress:hide');
      return Promise.resolve(false);
    }

    let needRender = false;

    gantt.silent(() => {
      calculatedGanttData.tasks.forEach(changedTask => {
        if (gantt.isTaskExists(changedTask.id)) {
          const currentTask = gantt.getTask(changedTask.id);

          if (currentTask.parent === 1) { // for multiViews
            changedTask.parent = 1;
          }

          _innerHelpers.formatTaskData(changedTask);

          gantt.updateTask(changedTask.id, {
            ...currentTask,
            ...changedTask,
          });
          needRender = true;

          //jopa hack
          if (calculatedGanttData.diff.tasks[changedTask.id].duration && changedTask.type !== constants.TASK_TYPES.project) {
            app.trigger('changedTaskDuration', changedTask, currentTask);
          }
        }
      });
    });

    // calculatedGanttData.tasks.forEach(changedTask => {
    //   gantt.isTaskExists(changedTask.id) && gantt.refreshTask(changedTask.id);
    // });

    needRender && gantt.render();

    if (action === 'updateTasks') {
      calculatedGanttData.triggerEvent = {
        eventType: action,
        taskIds: data.map(t => t.id)
      };
    }

    await globalStore.dispatch('tasksModel/updateTasksAfterGanttWorker', calculatedGanttData);

    if (composer === 'afterChangeResourceCalendar') {
      webix.message({type: 'info', text: __('after_change_resource_calendar_info'), expire: 10000});
    }

    app.trigger('afterGanttWorkerFinish', calculatedGanttData);
    return Promise.resolve(true);
  },
};

app.on('abc', () => {
  _innerHelpers.some();
});

const outputObject = {
  init: _init,
  calculate: _innerHelpers.calculate,
};

export default outputObject;
// gantt.ganttWorker.calculate([{id: taskId, gantt_id: ganttId, start_date: newStartDate, ...}], 'changeTasks' ,'updateTasks');
// gantt.ganttWorker.calculate([{id: taskId, gantt_id: ganttId}], 'changeTasks' ,'removeTasks');
