<template>
  <div :class="$.calendar_step">
    <ic-button
      :class="$.button"
      :icon="{ name: 'arrow-left' }"
      size="small"
      @onClick="onClickStep(-1)"
    />
    <div :class="$.date">
      <span>{{ currentDate }}</span>
    </div>
    <ic-button
      :class="$.button"
      :icon="{ name: 'arrow-right' }"
      size="small"
      @onClick="onClickStep(1)"
    />
  </div>
</template>

<script>
import icButton from '../../common/VueComponents/globalButton/withIcon/iconButton.vue';

export default {
  components: { icButton },
  data() {
    return {

    };
  },
  computed: {
    calendarViewDate() {
      return this.$store.getters['headerView/calendarViewDate'];
    },
    currentDate() {
      const date = new Date();
      const currenDate = this.calendarViewDate || date;

      const monthNames = [
        __('header_calendar_step_jan'),
        __('header_calendar_step_feb'),
        __('header_calendar_step_march'),
        __('header_calendar_step_apr'),
        __('header_calendar_step_may'),
        __('header_calendar_step_jun'),
        __('header_calendar_step_jul'),
        __('header_calendar_step_aug'),
        __('header_calendar_step_sep'),
        __('header_calendar_step_oct'),
        __('header_calendar_step_nov'),
        __('header_calendar_step_dec'),
      ];

      const month = monthNames[currenDate.getMonth()];
      const year = currenDate.getFullYear();

      return `${month} ${year}`;
    },
  },
  methods: {
    onClickStep(step) {
      this.$store.commit('headerView/setCalendarStep', step);
    },
  },
};
</script>

<style module='$' lang='less'>
  @import "../../../less/_fonts.less";
  @import "../../../less/_mixins.less";
  .calendar_step {
    display: flex;
    align-items: center;

    .button {
      color: #191919;
    }

    .date {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 115px;
      padding: 0 4px;

      span {
        .FontLatoRegular;
        color: #191919;
        .fs(14px, 20px);
      }
    }
  }
</style>
