<template>
  <div
    :class="[
      'vgp-interactive-element',
      $style.vgp_input_box,
      isError ? $style.error : null,
      isDisabled ? $style.disabled : null,
      small ? $style.small : $style.big,
      !border ? $style.borderless : null,
      readonly ? $style.borderless_readonly : null,
      postfix ? $style.with_postfix : '',
      prefix ? $style.with_prefix : ''
    ]"
    @click="focusElement"
  >
    <div
      v-if="imagesLeft.length"
      :class="[$style.images_left]"
    >
      <div
        v-for="(image, index) in imagesLeft"
        :key="index"
        :class="[$style.image_wrap]"
      >
        <img
          :src="image"
          alt="image"
        >
      </div>
    </div>
    <div
      v-if="iconsLeft.length"
      :class="[$style.icons_left]"
    >
      <sprite
        v-for="(item,i) in iconsLeft"
        :key="i"
        :class="$style.svg"
        :name="item.name || 'check'"
        :size="item.size || 24"
        :type="item.type || 'regular'"
        color="#999999"
        @click.stop="iconHandler(item.handler)"
      />
    </div>
    <span
      v-if="prefix"
      :class="[$style.prefix, 'input-prefix']"
      @click.stop="handlePrefixClick"
    >
      {{ prefix }}
    </span>
    <input
      :id="getGpAutotestName"
      ref="input_field_icons"
      v-model="componentValue"
      v-mask="mask"
      :readonly="readonly"
      :type="setInputType"
      :class="[$style.vgp_input]"
      :style="size"
      :disabled="isDisabled"
      :placeholder="placeholder ? placeholder : ''"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @change="onChange"
      @focus="onFocus"
      @input="onInput"
      @keydown="onKeydownChange"
      @keyup="onKeyupChange"
      @blur="onBlur"
    >
    <span
      v-if="postfix"
      :class="$style.postfix"
      @click.stop="handlePostfixClick"
    >
      {{ postfix }}
    </span>
    <div
      v-if="iconsRight.length"
      :class="$style.icons_right"
    >
      <sprite
        v-for="(item,i) in iconsRight"
        :key="i + item.name"
        :name="item.name || 'check'"
        :size="item.size || 24"
        :type="item.type || 'regular'"
        :class="$style.svg"
        color="#999999"
        @click.stop="iconHandler(item.handler)"
      />
    </div>
  </div>
</template>

<script>
import sprite from '$$vueCmp/VueIcon/svgSprite.vue';

export default {
  name: 'VgpInput',
  components: {
    sprite,
  },
  props: {
    gpAutotestName: { type: String, required: false, default: '' },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    iconsLeft: {
      type: [Array],
      required: false,
      default: () => [],
    },
    iconsRight: {
      type: [Array],
      required: false,
      default: () => [],
    },
    imagesLeft: {
      type: [Array],
      required: false,
      default: () => [],
    },
    postfix: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    side: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectByFocus: {
      type: Boolean,
      required: false,
    },
    blurOnEnter: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxlength: {
      type: [String, Number],
      required: false,
      default: null,
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
    mask: {
      type: String,
      default: '',
    },
    changeByEnter: {
      type: Boolean,
      default: false,
    },
    initialFocus: {
      type: Boolean,
      default: false
    }
  },

  emits: {
    input: 'string',
    onChange: 'string',
    onBlur: 'string',
    onKeydown: 'string',
    onKeyup: 'string',
    onFocus: 'string',
  },
  mounted() {
    if (this.initialFocus) {
      this.focusElement();
    }
  },
  data() {
    return {
      isFocus: false,
      componentValue: this.value,
    };
  },

  computed: {
    getGpAutotestName(){
      if(this.gpAutotestName)
        return this.gpAutotestName;

      return `gp_autotest_input_ ${new Date().getTime()}`;
    },

    size() {
      if (this.small) {
        return {
          height: '32px',
        };
      }

      return {
        height: '40px',
      };
    },

    setInputType() {
      return this.inputType === 'number' ? 'text' : this.inputType;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.componentValue = v;
      },
      immediate: true,
    },
  },

  methods: {
    focusElement() {
      this.$refs.input_field_icons.focus();
      this.$emit('onClick');
    },

    onChange(e) {
      this.$emit('onChange', e, this.componentValue);
    },

    onInput(e) {
      let value = e.target.value;

      if (this.inputType === 'number') {
        value = value.replace(/[^\d.,\s]/g, '');
        if (this.max !== null && Number(value) > this.max) {
          value = this.max;
        }

        if (this.min !== null && Number(value) < this.min) {
          value = this.min;
        }
        e.target.value = value;
      }
      this.componentValue = value;
      this.$emit('input', value);
    },

    onKeydownChange(e) {
      this.$emit('onKeydown', e, this.componentValue);
    },

    onKeyupChange(e) {
      if (e.key === 'Enter' && this.blurOnEnter) {
        this.$refs.input_field_icons.blur();
      }
      if (e.key !== 'Enter' && this.changeByEnter) return;

      this.$emit('onKeyup', e, this.componentValue);
    },

    onBlur(e) {
      this.$emit('onBlur', e, this.componentValue);
      const input = this.$refs.input_field_icons;

      input.scrollLeft = 0;
    },

    onFocus(e) {
      this.$emit('onFocus', e, this.componentValue);

      if (this.selectByFocus) {
        return e.target.select();
      }
    },

    iconHandler(handler) {
      handler ? handler() : this.$refs.input_field_icons.focus();
    },

    handlePostfixClick() {
      this.$refs.input_field_icons.focus();
    },
    handlePrefixClick() {
      this.$refs.input_field_icons.focus();
    }
  },
};
</script>

<style module src='./vInput.less'></style>
