<template>
  <div
    class="payment-plans gantt-scroll"
    :style="{width: width, height: '100vh'}"
  >
    <div class="content">
      <div
        ref="head"
        class="head"
      >
        <div class="main-title">
          {{ locales('plans_popup_title') }}
        </div>
        <div :class="['period-tabs', isAnnual ? 'annual' : 'month']">
          <div
            class="tab annually"
            @click="toggleAnnual(true)"
          >
            <div :class="['save-item', isAnnual && nextCount === 1 ? 'dnone' : '']">
              {{ locales('save_up_locale') }}
            </div>
            <div>{{ locales('plans_annually_locale') }}</div>
          </div>
          <div
            class="tab monthly"
            @click="toggleAnnual(false)"
          >
            {{ locales('plans_monthly_locale') }}
          </div>
        </div>
        <closer
          :size="30"
          :on-close="close"
        />
      </div>
      <div class="body">
        <div
          ref="plansSection"
          class="plans"
        >
          <plan-item
            v-for="(plan, index) in plans"
            :key="index"
            :plan="plan"
            :is-annual="isAnnual"
            :is-popup="false"
            :number-of-plan="numberOfPlanItems"
          />
        </div>
        <div
          ref="studentsSection"
          :style="{'padding-top': '6px', 'padding-bottom': '36px'}"
        >
          <div class="students border-wrapper">
            <div class="content d-flex align-center">
              <p class="title-h2">
                {{ locales('plans_students_title') }}
              </p>
              <p
                class="content-desc"
                v-html="locales('plans_students_description')"
              />
              <button
                class="btn"
                @click="studentsClick"
              >
                {{ locales('learn_more') }}
              </button>
            </div>
          </div>
        </div>
        <div
          ref="features"
          :class="['features border-wrapper', isShowTable ? 'show-table' : '']"
          @click="toggleFeaturesTable"
        >
          <div
            ref="highElem"
            class="high-elem"
          >
            <div class="features-title title-h2">
              {{ locales('pricing_plans_features_table_head') }}
            </div>
            <div
              :class="['check-icon', isActive ? 'active' : '']"
            >
              <webix-ui
                :config="icons.checkIcon"
              />
            </div>
          </div>
          <div
            ref="hiddenTopTable"
            class="hidden-top-table"
          >
            <div class="white-top-bg" />
            <div class="top-header" />
          </div>
          <div
            ref="table"
            class="table"
          >
            <div
              ref="tableHead"
              class="table-head"
            >
              <div
                ref="tableHeadTitle"
                class="table-head-title"
              />
              <div class="head-plans-wrapper">
                <div
                  v-for="(plan, index) in plans"
                  :key="index"
                  :class="['table-plan-cell head-cell', plan.name]"
                >
                  <div
                    v-if="plan.name !== 'enterprise'"
                    class="inner-info"
                  >
                    <div class="plan-name">
                      {{ locales(`${plan.name}_plan_locale`) }}
                    </div>
                    <div class="price-desc">
                      {{ `$ ${plan.annual_price_usd} ${locales('per_user_locale')}` }}
                    </div>
                    <div class="price-desc">
                      {{ locales('per_month_key') }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="enterprise-info"
                  >
                    <div class="plan-name">
                      {{ locales(`${plan.name}_plan_locale`) }}
                    </div>
                    <div class="demo-action-desc enterprise">
                      <webix-ui
                        class="buble-icon"
                        :config="icons.bubleIcon"
                      />
                      <div class="price-desc">
                        {{ locales('lets_talk_locale') }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="plan.plan_name ==='pro'"
                    ref="orangeBg"
                    class="orange-bg"
                  />
                  <div
                    v-if="plan.plan_name ==='business'"
                    ref="grayDelimiter"
                    class="gray-delimiter"
                  />
                </div>
              </div>
            </div>
            <div
              ref="tableBody"
              class="table-body"
            >
              <table-group
                v-for="(group, index) in featuresTable"
                ref="tableGroup"
                :key="index"
                :plans="plans"
                :group="group"
              />
            </div>
          </div>
        </div>
        <div
          ref="faq"
          class="faq"
        >
          <div class="faq-title">
            {{ locales('faq_plans_locales') }}
          </div>
          <div class="faq-list">
            <div
              v-for="(item, index) in faqConfig"
              :key="index"
              class="faq-item"
              :data-key="item.key"
              @click="showAnswer"
            >
              <div
                class="question"
              >
                {{ item.question }}
              </div>
              <div
                class="check-icon"
              >
                <webix-ui
                  :config="icons.checkIcon"
                />
              </div>
              <div
                class="answer"
                v-html="item.answer"
              />
            </div>
          </div>
        </div>
        <div
          ref="footer"
          class="contact-footer"
        >
          <div class="footer-title">
            {{ locales('plans_popup_footer_title') }}
          </div>
          <div
            class="contact-us"
            @click="chatClick"
            v-html="locales('footer_contact_link_locale')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import svgIconForVue from '../common/svgIconForVue';
import plansModel from '../../models/plans';
import planItem from './components/planItem.vue';
import closer from '../common/VueComponents/closer/closer.vue';
import tableGroup from './components/tableGroup.vue';

import checkIcon from '../../svg/pricing/plus_ic.svg';
import bubleSmallIcon from '../../svg/pricing/bubble_ic_small.svg';
import helpers from './helpers';

export default {
  components: {
    planItem,
    tableGroup,
    closer,
  },
  data() {
    return {
      locales: __,
      isAnnual: true,
      plans: [],
      realUsers: '',
      featuresTable: [],
      numberOfPlan: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        // 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
      ],
      numberOfPlanItems: [],
      faqConfig: [
        {
          question: __('question_locale_1'),
          answer: __('answer_locale_1'),
          key: 'free',
        },
        {
          question: __('question_locale_2'),
          answer: __('answer_locale_2'),
          key: 'payment options',
        },
        {
          question: __('question_locale_3'),
          answer: __('answer_locale_3'),
          key: 'discounts',
        },
        {
          question: __('question_locale_4'),
          answer: __('answer_locale_4'),
          key: 'data hosted',
        },
        {
          question: __('question_locale_5'),
          answer: __('answer_locale_5'),
          key: 'gdpr',
        },
        // {
        //   question: __('question_locale_6'),
        //   answer: __('answer_locale_6'),
        // },
        // {
        //   question: __('question_locale_7'),
        //   answer: __('answer_locale_7'),
        // },
      ],
      isShowTable: false,
      isActive: false,
      icons: {
        checkIcon: svgIconForVue(checkIcon, 24, 24),
        bubleIcon: svgIconForVue(bubleSmallIcon, 21, 21),
      },
    };
  },
  computed: {
    isShow() {
      return this.$store.getters['plansPopup/isShowPlans'];
    },
    width() {
      const sidebarWidth = this.$store.getters.leftSidebarWidth;
      const width = `calc(100% - ${sidebarWidth + 1}px)`;

      return width;
    },
    nextCount() {
      return this.$store.getters['paymentPlans/nextCount'];
    },
  },
  watch: {
    $route(to, from) {
      this.$store.commit('paymentPlans/close');
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.mountTableLayout();
  },
  methods: {
    init() {
      this.plans = user.paymentPlans.filter(value => {
        if (value.id !== 17) {
          return value;
        }
      });
      this.realUsers = this.$store.getters['resourcesModel/getAllRealResources'].length;
      let nextCount;

      if (plansModel.isTrial() || user.subscription.status_code === 2 || user.subscription.status_code === 10) {
        if (this.realUsers === 2) {
          nextCount = this.numberOfPlan[1];
        } else {
          const next = this.numberOfPlan.find(num => num >= this.realUsers);

          nextCount = next || this.realUsers;
        }
      } else {
        nextCount = this.realUsers > 1 ? this.numberOfPlan.find(num => num >= this.realUsers) : this.numberOfPlan[1];
      }

      this.featuresTable = helpers.getTableFeatures();
      this.$store.commit('paymentPlans/setNextCount', nextCount);
      this.setSelectItems(this.numberOfPlan);
      userExtAnalytics.log('pricing_upgrade_account_clicked');
    },
    mountTableLayout() {
      const mainNode = this.$el;
      const head = this.$refs.head;
      const plans = this.$refs.plansSection;
      const students = this.$refs.studentsSection;
      const features = this.$refs.features;
      const highElem = this.$refs.highElem;
      const table = this.$refs.table;
      const tableHead = this.$refs.tableHead;
      const tableHeadTitle = this.$refs.tableHeadTitle;
      const tableBody = this.$refs.tableBody;
      const tableGroups = this.$refs.tableGroup;
      const hiddenTopTable = this.$refs.hiddenTopTable;
      const grayDelimiter = this.$refs.grayDelimiter;

      const browserHeight = document.documentElement.clientHeight;
      const contentWidth = plans.scrollWidth;
      const scrollHeight = head.clientHeight + plans.clientHeight + students.clientHeight + 22;
      const bigScroll = scrollHeight + highElem.scrollHeight + table.scrollHeight - browserHeight;

      features.style.width = `${contentWidth}px`;
      hiddenTopTable.style.width = `${contentWidth}px`;
      tableHead.style.width = `${contentWidth - 25}px`;
      hiddenTopTable.style.height = `${highElem.scrollHeight + tableHead.scrollHeight + 30}px`;
      grayDelimiter[0].style.top = `${tableHead.scrollHeight + 6}px`;

      tableHeadTitle.innerText = tableGroups[0].$el.querySelector('.group-title').innerText;

      mainNode.addEventListener('scroll', () => {
        if (mainNode.scrollTop > scrollHeight && this.isShowTable) {
          hiddenTopTable.style.top = 0;
          hiddenTopTable.style.position = 'fixed';
          highElem.style.position = 'fixed';
          highElem.style.top = `${30}px`;
          tableHead.style.position = 'fixed';
          tableHead.style.top = '60px';
          tableBody.style.paddingTop = '100px';
        } else if (mainNode.scrollTop < scrollHeight || mainNode.scrollTop > scrollHeight + features.scrollHeight) {
          hiddenTopTable.style.top = `${-1000}px`;
          highElem.style.position = 'static';
          tableHead.style.position = 'absolute';
          tableHead.style.top = 0;
          tableBody.style.paddingTop = '70px';
        }

        if (mainNode.scrollTop > bigScroll) {
          hiddenTopTable.style.position = 'absolute';
          hiddenTopTable.style.top = 0;
          highElem.style.position = 'absolute';
          highElem.style.top = 0;
          tableHead.style.position = 'absolute';
          tableHead.style.top = 0;
        }

        tableGroups.forEach(node => {
          const scrollPosition = hiddenTopTable.scrollHeight - 30;
          const title = node.$el.querySelector('.group-title').innerText;

          if (node.$el.getBoundingClientRect().top <= scrollPosition) {
            tableHeadTitle.innerText = title;
          }
        });
      });
    },
    setSelectItems(numbers) {
      const preparedCountItems = numbers.map(num => {
        const countConfig = {};

        countConfig.value = num;
        if (num < this.realUsers) {
          countConfig.disabled = true;
        } else {
          countConfig.disabled = false;
        }

        return countConfig;
      });

      this.numberOfPlanItems = preparedCountItems;
    },
    close() {
      this.$store.commit('paymentPlans/close');
    },
    removeHeight() {
      this.$el.querySelectorAll('.faq-item').forEach(item => {
        item.classList.remove('active');
        item.querySelector('.answer').style.maxHeight = 0;
        item.querySelector('.check-icon').classList.remove('active');
      });
    },
    showAnswer(e) {
      const faqItem = e.target.closest('.faq-item');
      const answerNode = faqItem.querySelector('.answer');
      const checkIcon = faqItem.querySelector('.check-icon');
      const contentHeight = answerNode.scrollHeight;
      const dataKey = e.target.dataset.key;

      userExtAnalytics.log('pricing_faq_opened', dataKey);

      if (faqItem.classList.contains('active')) {
        checkIcon.classList.remove('active');
        this.removeHeight();

        return;
      }
      this.removeHeight();
      faqItem.classList.add('active');
      checkIcon.classList.add('active');
      answerNode.style.maxHeight = `${contentHeight}px`;
    },
    toggleFeaturesTable() {
      const table = this.$refs.table;
      const tableHead = this.$refs.tableHead;
      const orangeBg = this.$refs.orangeBg;
      const grayDelimiter = this.$refs.grayDelimiter;
      const height = table.scrollHeight;
      const highElem = this.$refs.highElem;
      const hiddenTopTable = this.$refs.hiddenTopTable;
      const tableBody = this.$refs.tableBody;

      if (!this.isShowTable) {
        hiddenTopTable.style.top = `${-1000}px`;
        highElem.style.position = 'static';
        tableHead.style.position = 'absolute';
        tableHead.style.top = 0;
        tableBody.style.paddingTop = '70px';
        table.style.maxHeight = `${height + 54}px`;
        orangeBg[0].style.height = `${height + 18}px`;
        grayDelimiter[0].style.height = `${height - tableHead.scrollHeight + 18}px`;
        this.isShowTable = true;
        this.isActive = true;
        userExtAnalytics.log('pricing_compare_all_features_opened');
      } else {
        table.style.maxHeight = 0;
        orangeBg[0].style.height = 0;
        grayDelimiter[0].style.height = 0;
        this.isShowTable = false;
        this.isActive = false;
      }
    },
    toggleAnnual(flag) {
      this.isAnnual = flag;
      const selectItems = this.numberOfPlanItems;
      const nextCount = this.$store.getters['paymentPlans/nextCount'];
      const startCountMonth = selectItems.find(item => item.value === 5);
      const firstItem = this.numberOfPlanItems.find(item => item.value === 1);

      firstItem.disabled = false;

      if (!flag && nextCount === 1) {
        this.$store.commit('paymentPlans/setNextCount', startCountMonth.value);
      }

      if (!flag && this.realUsers >= 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      } else if (flag && this.realUsers > 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      }
      userExtAnalytics.log('pricing_period_changed', { type: this.isAnnual ? 'annually' : 'monthly' });
      this.numberOfPlanItems = selectItems;
    },
    chatClick(e) {
      const target = e.target.closest('.contact-us');
      const contactLink = target.querySelectorAll('span');

      contactLink && this.$store.dispatch('footer/chatClickHandler');
    },
    studentsClick() {
      this.$store.commit('paymentPlans/showStudentsPage', true);
    },
  },
};
</script>

<style scoped src="./less/paymentPlans.less"></style>
<style lang="less">
.check-icon {
  .webix_template {
    cursor: pointer;
  }

  &.active {
    div {
      .webix_view {
        .webix_template {
          svg {
            rect {
              &:last-child {
                height: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

  .features {
    &:hover {
      .check-icon {
        .webix_template {
          svg{
            rect{
              fill: #424242;
            }
          }
        }
      }
    }
  }

  .faq-item:hover{
    .check-icon {
      .webix_template {
        svg{
          rect{
            fill: #424242;
          }
        }
      }
    }
  }

.contact-us {
  span {
    border-bottom: 1px solid #424242;
    cursor: pointer;
  }
}
</style>
