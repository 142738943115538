<template>
  <div
    ref="wrap"
    :key="'group-chips-'+ componentKey + '-' + items.length"
    v-resize="onResize"
    :class="[$style.wrap]"
    :style="{height: wrapHeight + 'px'}"
  >
    <div
      v-for="n in numberToShow"
      :id="componentKey + '-group-itemm-'+ (n-1)"
      :key="componentKey + '-gr-ch-' + n "
      :class="$style.group_chip_wrap"
      data-chip="chip"
    >
      <slot :item="items[n-1]" />
    </div>

    <div
      v-if="restCount"
      data-chip="rest"
      :class="$style.rest_count_chip"
      :style="{left: commonItemsWidth + 'px', height: wrapHeight + 'px' }"
    >
      +{{ restCount }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'RollingItems',
  props: {
    items: { type: Array, required: true },
    offset: { type: Number, required: false, default: 10 },
    maxItemsShow: { type: Number, required: false, default: 0 },
    containerWidth: { type: Number, default: null },
  },

  data() {
    return {
      numberToShow: 0,
      restCount: 0,
      itemsChanged: 0,
      wrapHeight: 0,
      commonItemsWidth: 0,
    };
  },

  computed: {
    componentKey() {
      const min = 1000;
      const max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
  },

  watch: {
    items: {
      handler(vals) {
        this.recalcShowed();
      },
      deep: false,
    },
    maxItemsShow: {
      handler(vals) {
        this.recalcShowed();
      },
    },
    offset: {
      handler(vals) {
        this.recalcShowed();
      },
    },
  },

  mounted() {
    this.recalcShowed();
  },

  updated() {
    if (this.itemsChanged) {
      this.calcShowedItems();
    }
  },

  methods: {
    onResize() {
      this.recalcShowed();
    },

    recalcShowed() {
      this.itemsChanged = 1;
      this.restCount = 0;
      this.numberToShow = this.items.length;
    },

    calcShowedItems() {
      this.itemsChanged++;
      if (this.itemsChanged > 3) this.itemsChanged = 0;

      const toShow = this.calcNumberToShow();

      if (this.items.length === 1 && toShow === 0) {
        const children = this.$refs.wrap.children;
        const first = Array.from(children)[0];

        first.style.width = `${(this.containerWidth || this.$refs.wrap.clientWidth) - 15}px`;

        return;
      }

      this.numberToShow = toShow;
      const rest = this.items.length - this.numberToShow;

      this.restCount = rest > 0 ? rest : 0;
    },

    calcNumberToShow() {
      const wrapWidth = (this.containerWidth || this.$refs.wrap.clientWidth) - 15;
      const restChipWidth = 35 + this.offset;
      let max = this.calcMaxNumberByWidth(wrapWidth);

      if (max < this.items.length) {
        max = this.calcMaxNumberByWidth(wrapWidth - restChipWidth);
      }

      const suggestMax = this.maxItemsShow > 0 ? this.maxItemsShow : this.items.length;

      return suggestMax > max ? max : suggestMax;
    },

    calcMaxNumberByWidth(width) {
      let tempWidth = 0;
      let countToShow = 0;
      const children = this.$refs.wrap.children;
      const arr = Array.from(children);

      this.commonItemsWidth = 0;

      arr.forEach((el, index) => {
        if (el.getAttribute('data-chip') === 'rest') {
          return;
        }

        const chipWidth = index ? el.offsetWidth + this.offset : el.offsetWidth;

        tempWidth += chipWidth;
        this.wrapHeight = el.offsetHeight > this.wrapHeight ? el.offsetHeight : this.wrapHeight;

        if (tempWidth <= width) {
          countToShow = index + 1;
          el.style.left = `${this.commonItemsWidth}px`;
          this.commonItemsWidth += (el.offsetWidth + this.offset);
        }
      });

      return countToShow;
    },
  },
};
</script>

<style module src="./groups.less" lang="less"></style>
