var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-dependency-form" }, [
    _c("div", { staticClass: "new-dependency-form__headers" }, [
      _c("div", { staticClass: "header-label" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.locales("settings_dependency_task_name")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-label" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.locales("settings_dependency_link_type")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-label" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.locales("settings_dependency_lag")) + "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "new-dependency-form__body" },
      [
        _c("autocomplete-select", {
          ref: "autocompleteSelect",
          attrs: {
            "filter-by": "text",
            "additionally-filter-by": "wbs",
            "track-by": "id",
            "get-items-callback": _vm.getAvailableTasksForDependencyCreation,
          },
          model: {
            value: _vm.form.targetTask,
            callback: function ($$v) {
              _vm.$set(_vm.form, "targetTask", $$v)
            },
            expression: "form.targetTask",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "new-dependency__types" },
          [
            _vm._l(_vm.availableDependencyTypes, function (item, index) {
              return _c("div", {
                key: index,
                ref: "dependencyTypes",
                refInFor: true,
                class: [
                  "new-dependency__type",
                  _vm.form.dependencyType === item.value
                    ? "new-dependency__type_selected"
                    : "",
                ],
                domProps: { innerHTML: _vm._s(item.icon) },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectDependency(item.value)
                  },
                  mouseenter: function ($event) {
                    return _vm.showDependencyPreview(index)
                  },
                  mouseleave: function ($event) {
                    _vm.dependencyPreviewProps.position = null
                  },
                },
              })
            }),
            _vm._v(" "),
            _vm.dependencyPreviewProps.position
              ? _c(
                  "dependency-preview",
                  _vm._b(
                    {},
                    "dependency-preview",
                    _vm.dependencyPreviewProps,
                    false
                  )
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("vgp-input", {
          key: _vm.inputLagKey,
          attrs: { value: _vm.formattedLag, placeholder: "Lag", small: "" },
          on: { onChange: _vm.handleChangeLag },
        }),
        _vm._v(" "),
        _c("vgp-button", {
          attrs: {
            type: "primary",
            small: "",
            label: _vm.locales("common_save"),
            width: _vm.saveButtonWidth,
          },
          on: { onClick: _vm.addNewDependency },
        }),
        _vm._v(" "),
        _c("vgp-icon-button", {
          attrs: { icon: { name: "close-1" } },
          on: {
            onClick: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }