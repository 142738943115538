var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.sideBar, _vm.blockSideBar ? _vm.$style.blocked : ""],
      on: {
        "!click": function ($event) {
          return _vm.clickAllSideBar($event)
        },
      },
    },
    [
      !_vm.isOpenProfileSettings
        ? _c(
            "div",
            {
              class: [
                _vm.$style.leftSideBar,
                _vm.isOpenSideBar ? _vm.$style.open : "",
                !_vm.isOpenSideBar && _vm.showIcon ? _vm.$style.clickable : "",
              ],
              attrs: { id: "leftSideBar" },
              on: {
                mouseover: _vm.handleMouseOver,
                mouseleave: function ($event) {
                  _vm.showIcon = false
                },
                click: _vm.handleClickSidebar,
              },
            },
            [
              _c(
                "div",
                { ref: "header" },
                [
                  _c("sidebar-header", {
                    attrs: {
                      "is-open-side-bar": _vm.isOpenSideBar,
                      "show-icon": _vm.showIcon,
                      "team-label": _vm.teamLabel,
                      "user-rights": _vm.userRights,
                      "add-project-click": _vm.addProjectClick,
                      "position-context": _vm.positionContext,
                      menu: _vm.menu,
                      "active-item": _vm.activeItem,
                      "block-header-click": _vm.blockHeaderClick,
                      "items-for-context": _vm.itemsForContext,
                      "team-settings-access":
                        _vm.accountRolesHasTeamSettingAccess,
                      "unread-count": _vm.unreadCommentsCount,
                      mention: _vm.hasMentionComments,
                    },
                    on: {
                      clickSideBar: _vm.clickSideBar,
                      addProjectClickBtn: _vm.addProjectClickBtn,
                      onSelectItemMenu: _vm.onSelectItemMenu,
                      onClickOutside: _vm.onClickOutside,
                      clickMenuItem: _vm.clickMenuItem,
                      teamSettings: _vm.teamSettings,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("favorite-projects", {
                attrs: {
                  "is-open-side-bar": _vm.isOpenSideBar,
                  "fav-projects": _vm.favProjects,
                  "open-fav-projects": _vm.openFavProjects,
                  "fav-height": _vm.favProjectsHeight,
                  "active-item": _vm.activeItem,
                },
                on: {
                  hideShowFavProjects: _vm.hideShowFavProjects,
                  projectClick: _vm.projectClick,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { ref: "footer", class: [_vm.$style.footer_items] },
                [
                  _c("upgrade-button", {
                    attrs: {
                      "is-open-side-bar": _vm.isOpenSideBar,
                      "user-rights": _vm.userRights,
                      "is-show-upgrade-popup": _vm.isShowUpgradePopup,
                    },
                    on: { upgradeClick: _vm.upgradeClick },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.$style.menu,
                      style: {
                        marginTop: _vm.isShowUpgradePopup ? "" : "24px",
                      },
                    },
                    _vm._l(_vm.getFooterItems, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("menu-item", {
                            attrs: {
                              config: item,
                              "is-open-side-bar": _vm.isOpenSideBar,
                            },
                            on: { clickMenuItem: _vm.clickMenuItem },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenAllProjects
        ? _c("project-list-popup", {
            attrs: {
              "user-rights": _vm.userRights,
              "multiselect-mode-from-sidebar": _vm.multiselectMode,
              open: _vm.isOpenAllProjects,
              "multiselect-project": _vm.multiselectProject,
              "items-for-context": _vm.itemsForContext,
              "active-item": _vm.getActiveProject(),
            },
            on: {
              sideBarBack: _vm.sideBarBackProjects,
              clickFavProject: _vm.clickFavProject,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpenProfileSettings
        ? _c("profile-menu", { on: { sideBarBack: _vm.sideBarBack } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }