import store from '../../../store/main';

function onProjectStatusOptionUpdated({ data }) {
  const updateData = {};

  data.updated.forEach(update => {
    updateData.statusData = update;
    updateData.action = 'update';
    updateData.id = data.projectStatusId;
    store.commit('teamSetting/updateProjectStatusesOptions', updateData);
  });
}

export default onProjectStatusOptionUpdated;
