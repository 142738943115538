import globalStore from '$$store/main';
import _ from '../../../libs/lodash';

export function getMyTasksFilterStatus(filterState) {
  if (!filterState || !filterState.assignee || !filterState.status) return false;
  if (filterState.assignee.length !== 1 || filterState.status.length !== 2) return false;

  const statuses = filterState.status ? filterState.status.join() : '';
  const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);

  if (resourceData.id === filterState.assignee[0] && (statuses === '1,2' || statuses === '2,1')) {
    if (gantt.isDeepEmpty(_.omit(filterState, ['assignee', 'status']))) return true;
  }

  return false;
}
