import _ from '../../../libs/lodash';
import estimationModule from '../../gantt/modules/estimation';
import timeTrackingModel from '../../../models/timeTracking';
import customHelper from '../../../helpers/custom';
import rights from '../../../components/rights';
import projectsModel from '../../../models/projects';

export function prepareTasks(tasks, customColumns) {
  const taskNameMap = {};
  const projectNameMap = {};
  const hasCostRightMap = {};

  tasks.forEach(task => {
    taskNameMap[task.id] = {
      text: task.text,
      parent: task.parent,
    };

    if (!projectNameMap[task.gantt_id]) {
      const projectName = projectsModel.getItem(task.gantt_id)?.name;
      projectNameMap[task.gantt_id] = customHelper.formatTaskName(projectName);
    }

    if (!hasCostRightMap[task.gantt_id]) {
      hasCostRightMap[task.gantt_id] = rights.project.hasCostRight(task.gantt_id);
    }
  });

  const getPath = (parentId) => {
    if (!taskNameMap[parentId] || !taskNameMap[parentId].parent) {
      return [];
    }

    return [
      ...getPath(taskNameMap[parentId].parent),
      taskNameMap[parentId].text,
    ];
  }

  return tasks.reduce((result, t) => {
    let task = _.clone(t);

    if (task.type !== 'milestone' && task.type !== 'task') {
      return result;
    }

    task.time_tracking = timeTrackingModel.getLogsByParams(task.gantt_id, task.id)?.sum || 0;
    task.overdue = new Date(task.end_date) < new Date() && task.progress < 1 && task.type === 'task';
    task.closed = task.status == 4;
    task.creator = _.find(gantt.config.columns, { name: 'creator' }).template(task);
    task.create_date = task.created_at;
    task.actual_cost = hasCostRightMap[task.gantt_id] ? estimationModule.helpers.getActualCostValueForTask(task, true) : '';
    task.total_price = hasCostRightMap[task.gantt_id] ? estimationModule.helpers.getPriceForTask(task, false, null, true) : '';
    task.comments = task.hasComments ? { count: task.hasComments, unread: task.newComments } : null;
    task.attachments = task.hasAttachments ? { count: task.hasAttachments, unread: false } : null;
    task.project_name = projectNameMap[task.gantt_id];
    task.project_extra = [projectNameMap[task.gantt_id], ...getPath(task.parent)].join(' / ');;

    if (customColumns[task.id]) {
      task = Object.assign(task, customColumns[task.id]);
      task.customColumns = customColumns[task.id];
    }

    result.push(task);

    return result;
  }, []);
}
