var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.public_url_wrap },
    [
      _c("div", { class: _vm.$style.public_url__subtitle }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.locales("share_popup_public_url_filter_sub_header")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.public_url__form },
        [
          _c("public-urls-form", {
            attrs: { "is-loading": _vm.isLoading },
            on: { onAddItem: _vm.addItem },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-simple-table", {
        ref: "table",
        staticClass: "table",
        attrs: { "fixed-header": true, height: "300px" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.locales(
                              "share_popup_public_url_list_header_description"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locales("common_filter")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locales("header_password")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.locales(
                              "share_popup_public_url_list_header_snapshot"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.locales(
                              "share_popup_public_url_list_header_url"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.locales(
                              "share_popup_public_url_list_header_status"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { class: _vm.$style.last_th }),
                  ]),
                ]),
                _vm._v(" "),
                _vm.data.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.data, function (item) {
                        return _c("tr", { key: item.id }, [
                          _c(
                            "td",
                            { class: _vm.$style.field_title },
                            [
                              _c("tooltip", {
                                attrs: {
                                  content: _vm.format(
                                    item.created_at,
                                    item.firstName,
                                    item.lastName
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "body",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.title) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { class: _vm.$style.field_filter }, [
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getCurrentTitle(item))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.togglePasswordId !== item.id
                            ? _c(
                                "td",
                                {
                                  class: _vm.$style.field_password,
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.hoverItem(item.id, true)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.hoverItem(item.id, false)
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        class: item.password
                                          ? _vm.$style.password
                                          : _vm.$style.empty_password,
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.password ? "••••" : "—")
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.hover
                                      ? _c(
                                          "div",
                                          {
                                            class: _vm.$style.public_url__icons,
                                          },
                                          [
                                            _c("svg-sprite", {
                                              class: _vm.$style.active_link,
                                              attrs: { name: "pensil" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.togglePassword(
                                                    item.id
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            item.password
                                              ? _c("svg-sprite", {
                                                  class: _vm.$style.active_link,
                                                  attrs: { name: "copy" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyPassword(
                                                        item.password
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.password
                                              ? _c("svg-sprite", {
                                                  class: _vm.$style.active_link,
                                                  attrs: { name: "delete" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removePassword(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            : _c(
                                "td",
                                { class: _vm.$style.password_change },
                                [
                                  _c("vgp-input-confirm", {
                                    ref: "password",
                                    refInFor: true,
                                    class: _vm.$style.passwordInput,
                                    attrs: {
                                      type: "text",
                                      "is-disabled": false,
                                    },
                                    on: {
                                      onKeydown: _vm.editKeyPassword,
                                      onOk: _vm.editPassword,
                                      onCancel: _vm.togglePassword,
                                    },
                                    model: {
                                      value: _vm.newPassword,
                                      callback: function ($$v) {
                                        _vm.newPassword = $$v
                                      },
                                      expression: "newPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              [
                                _c("svg-sprite", {
                                  attrs: {
                                    color: "#424242",
                                    name: item.is_snapshot
                                      ? "check"
                                      : "minus-1",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              [
                                _c("svg-sprite", {
                                  class: _vm.$style.active_link,
                                  attrs: { color: "#247ECC", name: "link-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createToken(
                                        item.token,
                                        item.user_id
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("square-toggle", {
                                attrs: { "is-toggle-on": item.status },
                                on: {
                                  onChange: function ($event) {
                                    return _vm.swithStatus(item.id, item.status)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              [
                                _c("svg-sprite", {
                                  class: [
                                    _vm.$style.active_link,
                                    _vm.$style.active_link_password,
                                  ],
                                  attrs: { name: "delete", color: "#A8A8A8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleConfirmDelete(item.id)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            class: _vm.$style.public_url__empty,
                            attrs: { colspan: "7" },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.locales(
                                      "share_popup_public_url_empty_list"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.isConfirmPopup
        ? _c("core-confirm-popup", {
            attrs: {
              show: _vm.isConfirmPopup,
              "close-icon": false,
              text: _vm.locales("public_link_window_delete_message"),
              "ok-button-title": _vm.locales("common_yes"),
              "cancel-button-title": _vm.locales("common_no"),
              "ok-button": {
                title: _vm.locales("common_yes"),
                type: "destructive",
              },
              "cancel-button": {
                title: _vm.locales("common_no"),
                type: "secondary",
              },
            },
            on: {
              onClickOk: _vm.deleteItem,
              onClickCancel: function ($event) {
                return _vm.toggleConfirmDelete(null)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }