import app from '../../app';
import teamModel from '../../models/team';
import plansModel from '../../models/plans';

const teamComponent = {};

teamComponent.acceptInviteToTeam = function (token, needToSuspend) {
  if (needToSuspend) {
    plansModel
      .suspend(data => {
        teamModel.acceptInviteToTeam({ userId: user.id, token });
      });
  } else {
    teamModel.acceptInviteToTeam({ userId: user.id, token, needToSuspend });
  }
};

teamComponent.userIsOwner = function (userData) {
  userData = userData || user.team;

  return parseInt(userData.role_id, 10) === 1;
};

teamComponent.userIsAdmin = function (userData) {
  userData = userData || user.team;

  return parseInt(userData.role_id, 10) === 4;
};

teamComponent.usersIsMember = function (userData) {
  userData = userData || user.team;

  return parseInt(userData.role_id, 10) === 3;
};

teamComponent.canManageTeam = function () {
  return teamComponent.userIsAdmin(user.team) || teamComponent.userIsOwner(user.team);
};

app.socket.on('team:user:changeTeamMember', changeData => {
  teamModel.changeTeamMemberState(changeData);
});

app.socket.on('team:user:add', teamData => {
  teamModel.addToTeam(teamData);
});

export default teamComponent;
