<template>
  <div class="settings-content settings-content-team">
    <div class="settings-section">
      <div class="main-title">
        {{ locales('team_screen_caption') }}
      </div>
      <div class="text">
        {{ locales('team_screen_description') }}
      </div>
      <div class="team-editor">
        <div class="team-info">
          <div
            class="team-img"
            @click="showLogoUploader"
          >
            <img
              class="logo"
              :src="teamLogo"
              alt=""
            >
            <div
              v-if="!isShowResetBtn"
              class="overlay"
            >
              <img
                :src="overlayIcon"
                alt=""
              >
            </div>
          </div>
          <div :class="['team-name', 'wide']">
            <v-input
              :css="'profile-settings-input'"
              :label="locales('team_name_label')"
              :value="teamName"
              :handle-change="changeTeamName"
            />
            <div
              v-if="faviconImg && (teamLogo === defaultLogo)"
              class="favicon-layout"
            >
              <vgp-checkbox
                :checked="!!isShowFavicon"
                @onClick="toggleFavicon"
              />
              <p class="text favicon-checkbox-label">
                {{ locales('show_team_logo_favicon') }}
              </p>
              <img
                class="favicon-img"
                :src="faviconImg"
              >
            </div>
          </div>
        </div>
        <div
          v-if="isShowResetBtn"
          class="reset-logo-btn"
          @click="resetLogo"
        >
          {{ locales('team_screen_reset_settings') }}
        </div>
      </div>
    </div>
    <delimiter />
    <div
      v-if="isShowStoreValue"
      class="settings-section"
    >
      <div class="main-title">
        {{ locales('settings_storage_title') }}
      </div>
      <div class="file-store-row">
        <div class="label">
          {{ locales('settings_fileStoreUsed') }}
        </div>
        <div class="line">
          <span />
        </div>
        <spaceSize></spaceSize>
      </div>
    </div>

    <image-uploader
      :show="isShowLogoUploader"
      :title="locales('upload_team_logo_title')"
      :type="'teamLogo'"
      @onClose="isShowLogoUploader = false"
      @onApply="onUploadLogo"
    />
  </div>
</template>

<script>
import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import projectsModel from '../../../models/projects';
import teamModel from '../../../models/team';

import uploadLogo from '../../../helpers/uploadLogo';
import resetLogo from '../../../helpers/resetLogo';
import plansHelper from '../../../helpers/plans';
import humanFileSize from '../../../helpers/fileSize';
import _ from '../../../libs/lodash';

import vInput from '../../common/VueComponents/vInput/vInput.vue';
import Delimiter from '../include/delimiter.vue';
import imageUploader from '../../imageUploader/imageUploader.vue';
import Compressor from 'compressorjs';
import spaceSize from '../components/team/spaceSize.vue';

export default {
  components: {
    vInput,
    Delimiter,
    imageUploader,
    spaceSize,
  },
  data() {
    return {
      locales: __,
      overlayIcon: `${GT.cdn}/imgs/settings/add_logo.svg`,
      defaultLogo: `${GT.cdn}/imgs/settings/team.svg`,
      teamLogo: '',
      teamName: '',
      faviconImg: '',
      isShowFavicon: '',
      isShowStoreValue: true,
      // usedSize: '',
      // maxSize: '',
      isShowResetBtn: '',
      isShowLogoUploader: false,
    };
  },
  created() {
    this.init();
  },
  beforeCreate() {
    const component = this;

    if (component.$route.path === '/settings/team') {
      app.on('user:changedRole', ganttId => {
        this.init();
      });
    }
  },
  methods: {
    init() {
      this.teamName = user.team.name;
      this.faviconImg = user.team.favicon;
      this.isShowFavicon = user.team.show_favicon;

      if (!user.subscription.activites || app.config.mode.isLink) {
        this.isShowStoreValue = false;
      }

      this.setTeamLogo();
    },
    setTeamLogo() {
      if (!user.team.logo) {
        this.teamLogo = this.defaultLogo;
        this.isShowResetBtn = false;
      } else {
        this.teamLogo = user.team.logo;
        this.isShowResetBtn = true;
      }
    },
    changeTeamName(newName) {
      const name = newName.trim();

      if (this.teamName !== name) {
        this.teamName = name;
        teamModel.changeName(name);
      }
    },
    async resetLogo() {
      const res = await resetLogo(null);

      if (res.status === 'ok') {
        user.team.logo = null;
        this.setTeamLogo();
        app.trigger('refreshLogo');
      }
    },
    showLogoUploader() {
      this.isShowLogoUploader = true;
    },
    onUploadLogo(file) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 500,
        maxHeight: 500,
        success: async resFile => {
          const result = await uploadLogo(resFile);

          user.team.logo = result.photo;
          this.init();
          app.trigger('refreshLogo');
        },
      });
    },
    toggleFavicon() {
      const flag = this.isShowFavicon === 1 ? 0 : 1;

      teamModel.showFavicon(flag);
      this.isShowFavicon = flag;
    },
  },
};
</script>

<style scoped src="../less/profileSettings.less"></style>
