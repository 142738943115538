<template>
  <div class="password-section">
    <div class="main-title">
      {{ locales('settings_profile_password_title') }}
    </div>
    <p class="text">
      {{ locales('settings_profile_password_text') }}
    </p>
    <vgp-button
      v-if="!isEditPasswordAreaShown"
      class="password-section__button"
      type="primary-border"
      small
      :label="toggleSectionButtonLabel"
      @onClick="isEditPasswordAreaShown = true"
    />
    <div
      v-else
      class="edit-area"
    >
      <div class="password-editer">
        <input
          id="gp_autotest_change_password_email_input"
          type="text"
          autocomplete="username"
          :value="form.email"
          hidden
        >
        <vgp-label-slot
          v-if="!isFirstTimePasswordSet"
          :label="locales('profile_current_password')"
          class="input__wrap"
        >
          <template #body>
            <ValidationProvider
              v-slot="{ errors }"
              ref="currentPasswordValidationProvider"
              rules="required"
              :custom-messages="{ required: locales('validation_error_currentPassword_required') }"
              vid="currentPassword"
              :mode="customValidationProviderMode"
            >
              <vgp-input
                id="gp_autotest_change_password_currentPassword_input"
                ref="currentPassword"
                v-model="form.currentPassword"
                maxlength="250"
                small
                :is-error="!!errors[0]"
                :placeholder="locales('profile_current_password_placeholder')"
                :input-type="currentPasswordInputType"
                :icons-right="[getIconConfig('currentPasswordInputType')]"
                @onFocus="handleCurrentPasswordFocus"
              />
              <span
                v-if="errors[0]"
                class="input__error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </template>
        </vgp-label-slot>
        <vgp-label-slot
          :label="locales('profile_new_password')"
          class="input__wrap"
        >
          <template #body>
            <tooltip
              position="left"
              :content="locales('pass_strength_descr')"
            >
              <template #body>
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="newPasswordValidationProvider"
                  rules="required|min:8"
                  :custom-messages="{
                    min: locales('validation_error_password_min_length'),
                    required: locales('validation_error_password_required'),
                  }"
                  vid="newPassword"
                  :mode="customValidationProviderMode"
                >
                  <vgp-input
                    id="gp_autotest_change_password_newPassword_input"
                    ref="newPassword"
                    autocomplete="new-password"
                    :value="form.newPassword"
                    maxlength="250"
                    small
                    :is-error="!!errors[0] || isPasswordCommonError"
                    :placeholder="locales('profile_new_password_placeholder')"
                    :input-type="newPasswordInputType"
                    :icons-right="[getIconConfig('newPasswordInputType')]"
                    @onFocus="handleNewPasswordFocus"
                    @input="handleNewPasswordInput"
                    @onBlur="passwordStrengthState.show = false"
                  />
                  <span
                    v-if="errors[0] || isPasswordCommonError"
                    class="input__error"
                  >
                    {{ errors[0] || locales('validation_error_common_password') }}
                  </span>
                </ValidationProvider>
              </template>
            </tooltip>
            <password-strength-indicator
              v-if="passwordStrengthState.show"
              v-bind="passwordStrengthState"
            />
          </template>
        </vgp-label-slot>
        <vgp-label-slot
          :label="locales('profile_retype_password')"
          class="input__wrap"
        >
          <template #body>
            <ValidationProvider
              v-slot="{ errors }"
              ref="passwordConfirmationValidationProvider"
              rules="required|confirmed:newPassword"
              :custom-messages="{
                required: locales('validation_error_newPasswordConfirmation_required'),
                confirmed: locales('validation_error_password_does_not_match_confirm_password')
              }"
              vid="passwordConfirmation"
              :mode="customValidationProviderMode"
            >
              <vgp-input
                id="gp_autotest_change_password_passwordConfirmation_input"
                ref="passwordConfirmation"
                v-model="form.passwordConfirmation"
                maxlength="250"
                small
                :is-error="!!errors[0]"
                :placeholder="locales('profile_retype_password')"
                :input-type="passwordConfirmationInputType"
                :icons-right="[getIconConfig('passwordConfirmationInputType')]"
                @onFocus="handlePasswordConfirmationFocus"
              />
              <span
                v-if="errors[0]"
                class="input__error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </template>
        </vgp-label-slot>
      </div>
      <div class="password-section__buttons-wrapper">
        <vgp-button
          class="password-section__button"
          type="secondary"
          small
          :label="locales('common_cancel')"
          @onClick="handleCancelButtonClick"
        />
        <vgp-button
          class="password-section__button"
          type="primary"
          small
          :disabled="isSavePasswordButtonDisabled"
          :is-loading="isFormSubmitting"
          :label="locales('common_save')"
          @onClick="submitChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VgpLabelSlot from '../../../common/VueComponents/label/vgpLabelSlot.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import constants from '../../../../helpers/constants';
import passwordMixin from '../../../../mixins/password';
import PasswordStrengthIndicator from '../../../common/VueComponents/password/PasswordStrengthIndicator.vue';
import Tooltip from '../../../common/VueComponents/tooltips/tooltip.vue';
import profileModel from '../../../../models/profile';

export default {
  name: 'PasswordChangeSection',
  components: {
    PasswordStrengthIndicator,
    VgpLabelSlot,
    VgpInput,
    Tooltip,
  },
  mixins: [passwordMixin],
  data() {
    return {
      locales: __,
      form: {
        currentPassword: '',
        newPassword: '',
        passwordConfirmation: '',
        email: user.email,
      },
      currentPasswordInputType: 'password',
      newPasswordInputType: 'password',
      passwordConfirmationInputType: 'password',
      isFormSubmitting: false,
      isEditPasswordAreaShown: false,
      isFirstTimePasswordSet: window.user.isUserRegisteredViaSocialOrSSO,
    };
  },
  computed: {
    toggleSectionButtonLabel() {
      return this.isFirstTimePasswordSet
        ? __('settings_profile_set_password_btn')
        : __('settings_profile_change_password_btn');
    },
    isSavePasswordButtonDisabled() {
      const passwordConfirmationInvalid = this.form.passwordConfirmation !== this.form.newPassword;
      const newPasswordLengthInvalid = this.form.newPassword.length < constants.MIN_PASSWORD_LENGTH;
      const weakPasswordStrength = this.passwordStrengthState.strength <= 1;
      const currentPasswordEmpty = this.isFirstTimePasswordSet ? false : !this.form.currentPassword;

      return [
        currentPasswordEmpty,
        passwordConfirmationInvalid,
        newPasswordLengthInvalid,
        weakPasswordStrength,
        this.isPasswordCommonError,
      ].filter(Boolean).length;
    },
  },
  watch: {
    'form.currentPassword': function (value) {
      if (value === '') {
        this.disableGoogleSuggestions('currentPassword');
      }
    },
    'form.newPassword': function (value) {
      if (value === '') {
        this.disableGoogleSuggestions('newPassword');
      }
      this.setPasswordStrengthState(value);
    },
    'form.passwordConfirmation': function (value) {
      if (value === '') {
        this.disableGoogleSuggestions('passwordConfirmation');
      }
    },
  },
  methods: {
    cleanupData() {
      this.form.currentPassword = '';
      this.form.newPassword = '';
      this.form.passwordConfirmation = '';
      this.$refs.currentPasswordValidationProvider && this.$refs.currentPasswordValidationProvider.reset();
      this.$refs.newPasswordValidationProvider.reset();
      this.$refs.passwordConfirmationValidationProvider.reset();
      this.currentPasswordInputType = 'password';
      this.newPasswordInputType = 'password';
      this.passwordConfirmationInputType = 'password';
    },
    handleCancelButtonClick() {
      this.isEditPasswordAreaShown = false;
      this.cleanupData();
    },
    handleNewPasswordInput(value) {
      this.form.newPassword = value;
      this.debounceCheckPasswordOnCommon(value, 500);
    },
    handleNewPasswordFocus() {
      this.disableGoogleSuggestions('newPassword');
      this.$refs.newPasswordValidationProvider.reset();
      if (!this.isPasswordCommonError && this.form.newPassword) {
        this.passwordStrengthState.show = true;
      }
    },
    handleCurrentPasswordFocus() {
      this.disableGoogleSuggestions('currentPassword');
      this.$refs.currentPasswordValidationProvider.reset();
    },
    handlePasswordConfirmationFocus() {
      this.disableGoogleSuggestions('passwordConfirmation');
      this.$refs.passwordConfirmationValidationProvider.reset();
    },
    async submitChanges() {
      if (this.isSavePasswordButtonDisabled || this.isFormSubmitting) {
        return;
      }

      try {
        this.isFormSubmitting = true;
        await profileModel.updateProfile({
          password: this.form.newPassword,
          oldPassword: this.isFirstTimePasswordSet ? null : this.form.currentPassword,
          token: true,
        });
        this.isFirstTimePasswordSet && (this.isFirstTimePasswordSet = false);
        this.cleanupData();
        this.$toast.success(__('profile_saved'));
      } catch (error) {
        if (error === 'INVALID_OLD_PASSWORD') {
          this.$refs.currentPasswordValidationProvider.setErrors([
            __('validation_error_currentPassword_incorrect'),
          ]);
        }

        if (error === 'COMMON_PASSWORD') {
          this.isPasswordCommonError = true;
        }
      } finally {
        this.isFormSubmitting = false;
      }
    },
    customValidationProviderMode() {
      return {
        on: ['onBlur'],
      };
    },
    getIconConfig(fieldInputTypeProperty) {
      if (this[fieldInputTypeProperty] === 'password') {
        return {
          name: 'visible',
          handler: () => this.$set(this, fieldInputTypeProperty, 'text'),
        };
      }

      return {
        name: 'hiden',
        handler: () => this.$set(this, fieldInputTypeProperty, 'password'),
      };
    },
    disableGoogleSuggestions(fieldRefName) {
      const elementRef = this.$refs[fieldRefName];

      elementRef.$refs.input_field_icons.setAttribute('readonly', '');
      setTimeout(() => {
        elementRef.$refs.input_field_icons && elementRef.$refs.input_field_icons.removeAttribute('readonly');
      }, 0);
    },
  },
};
</script>

<style scoped lang="less">

.password-section {

  .main-title {
    font-family: Lato-Bold, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #191919;
    margin-bottom: 8px;
  }

  .text {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #191919;
  }

  .input {
    &__wrap {
      position: relative;
      ::v-deep .tooltip_label {
        width: 100%;
      }
    }

    &__error {
      position: absolute;
      font-family: Lato-Regular, sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: #DD3636;
      margin-top: 4px;
    }
  }

  &__buttons-wrapper {
    max-width: 292px;
    gap: 16px;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: fit-content !important;
  }

  .edit-area {
    .password-editer {
      display: flex;
      flex-direction: column;
      gap: 22px;
      margin-bottom: 34px;
      max-width: 292px;
    }
  }
}
</style>
