import app from "./../app";

const globalGanttId = 666;

var activeTab = {};

activeTab.getActiveGanttId = function () {
  return webix.storage.local.get("activeGanttId");
};

activeTab.saveActiveGanttId = function (ganttId) {
  if (app.config.mode.isBase && ganttId && ganttId !== globalGanttId) {
    webix.storage.local.put("activeGanttId", ganttId);
  }
};

app.on('activeProject:afterSet', function (ganttId) {
  activeTab.saveActiveGanttId(ganttId);
});

export default activeTab;
