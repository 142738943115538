/* eslint-disable no-restricted-syntax */
import projectComponent from '../../Project';
import app from '../../../app';
import accountRights from '../../rights/account';
import globalStore from '$$store/main';

export function unassignResourceFromProjects(resourcesFromProjects) {
  const result = {
    projectIds: [],
  };

  const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

  for (const resourceFromProject of resourcesFromProjects) {
    if (resourceFromProject.resourceId === resourceId && !accountRights.isOwner() && !accountRights.isKingMode()) {
      projectComponent.deleteSharedProject(resourceFromProject.projectId);
    }

    result.projectIds.push(+resourceFromProject.projectId);
  }

  resourcesFromProjects.forEach(resourceData => {
    globalStore.commit('resourcesModel/removeResourceFromProject', resourceData);
  });

  return result;
}

function onResourceFromProjectsUnassigned(event) {
  const { data } = event;
  const { projectIds } = unassignResourceFromProjects(data.resourcesFromProjects);

  if (data.isUnblockCollab) {
    data.resourcesFromProjects.forEach(it => {
      const projectTaskData = globalStore.getters['tasksModel/getItem'](it.projectId);

      if (projectTaskData) {
        for (const key in projectTaskData.resourcesToTasks) {
          if (Array.isArray(projectTaskData.resourcesToTasks[key])) {
            projectTaskData.resourcesToTasks[key] = projectTaskData.resourcesToTasks[key].filter(item => item.resource_id !== it.resourceId);
          }
        }
        globalStore.commit('tasksModel/updateTaskResourcesByGanttId', {
          ganttId: it.projectId,
          resourcesToTasks: projectTaskData.resourcesToTasks,
        });
      }
    });
  } else {
    data.resourcesToTasks.forEach(data => {
      globalStore.commit('tasksModel/updateTaskResourcesByGanttId', {
        ganttId: data.projectId,
        resourcesToTasks: data.resourcesToTasks,
      });
    });
  }
  const getCommentsByProjectId = globalStore.getters['comments/getCommentsByGanttIds'](projectIds);
  const commentsActions = globalStore.getters['comments/getCommentsActions'];
  const commentIds = [];

  for (const key in getCommentsByProjectId) {
    getCommentsByProjectId[key].forEach(comment => {
      if (commentsActions.find(com => com.commentId === comment.id && com.read === 0)) commentIds.push(comment.id);
    });
  }
  globalStore.commit('comments/markAsReadCommentsActions', commentIds);

  gantt.render(); // need to refresh assign column

  app.trigger('resources:model:removeResourcesFromProjects', event.data.resourcesFromProjects);
  app.trigger('onAfterCollaboration', {
    event: event.event,
    data,
    projects: [...new Set(projectIds)],
  });
}

export default onResourceFromProjectsUnassigned;
