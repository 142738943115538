import core from './libs/core';
import locale from './plugins/locale';
import checkRoute from './plugins/checkRoute';
import _ from './libs/lodash';
import moment from './libs/moment';
import userExtAnalytics from './helpers/userExtAnalytics';
import demoSheduler from './helpers/demoSheduler';
import './kanban/codebase/kanban';
import { initVueApp } from './index';
import { io } from 'socket.io-client';
import cookie from '$$helpers/cookie';

// window.JSZip = jszip;
// window.jszip = jszip;
window.moment = moment;
window.userExtAnalytics = userExtAnalytics;
window.demoSheduler = demoSheduler;

const appIds = {
  // web-sockets events
  wsEvents: {
    ID_EVENT_WS_ADD_COMMENT: 'newComment',
    ID_EVENT_WS_EDIT_COMMENT: 'editComment',
    ID_EVENT_WS_RM_COMMENT: 'rmComment', // !FIXME
    // teamshare
    ID_EVENT_WS_TEAM_DEL_PROJECT: 'teamShare:deleteProject',
    ID_EVENT_WS_NEWS_ENTRY_ADD: 'add:news:notify',
    ID_EVENT_WS_MAINTENANCE_ENTRY_ADD: 'add:maintenance:notify',
  },
  events: {
    ID_EVENT_APP_START: 'app:start',
    ID_EVENT_APP_ROUTECHANGED: 'app:route:changed',
    ID_EVENT_APP_PROJ_CREATE: 'tab:projectCreate',
    ID_EVENT_APP_ONBOARD_NEXT: 'app::onboarding::next',
    ID_EVENT_APP_ONBOARD_FINISH: 'app::onboarding::finish',
    ID_EVENT_POPUPS_CHANGED: 'popups:state:changed', // is generated by the popupsManager
    ID_EVENT_POPUPS_CLOSEOTHER: 'popup:closeOther',
    ID_EVENT_APP_INIT_MODELS: 'app:initModels',
    ID_EVENT_WINDOW_STATE_CHANGED: 'app:window:state:changed',
    ID_APP_EVENT_SUPPORTCHAT_HIDE: 'app:livechat:hide',
    ID_APP_EVENT_SUPPORTCHAT_SHOW: 'app:livechat:show',
    ID_APP_EVENT_LIVECHAT_CLICK: 'app:footer:livechat:click',
    ID_APP_EVENT_SEARCHFILTER_CHANGE: 'app:searchfilter:change',
    ID_EVENT_DISABLEGANTT_TOGGLE_BTNS: 'disablegantt:toggletoolbarbuttons',
    ID_EVENT_COMMENTS_COUNT_CHANGED: 'changedCommentsCount',
    ID_EVENT_COMMENTS_COUNT_RESET: 'resetCommentsCount',
    ID_EVENT_PROJECT_ACTIVE_AFTERSET: 'activeProject:afterSet',
    ID_EVENT_POPUP_CONFIRMATON_SHOW: 'app:confirmation:show',
    ID_EVENT_GANTT_AFTER_INIT: 'gantt:init:after',
    // router events
    ID_EVENT_ROUTER_SHOW_PREVIEW: 'app:router:show:preview',
    //
    ID_EVENT_MC_SELECT_ACTION: 'mc:menu:action',
    ID_EVENT_MC_ACTIONS_CLICK: 'massChange:actions:click',
    // MassChange model events
    ID_EVENT_MASSCHANGE_UPDATE_TASKS: 'masschange:tasks:update',
    ID_EVENT_MASSCHANGE_UPDATESTATE: 'masschange:enable',
    ID_EVENT_MASSCHANGE_SET_ACTIVE_TASK: 'masschange:active:set',
    ID_EVENT_MASSCHANGE_UNSET_ACTIVE_TASK: 'masschange:active:unset',
    // comments
    ID_EVENT_COMMENTS_ADD_COMMENT: 'comments2:add',
    ID_EVENT_COMMENTS_ADD_COMMENTS: 'comments2:adds',
    ID_EVENT_COMMENTS_EDIT_COMMENT: 'comments2:edit',
    ID_EVENT_COMMENTS_REMOVE_COMMENT: 'comments2:remove',
    ID_EVENT_COMMENTS_MARK_AS_READ: 'comments2:read',
    ID_EVENT_COMMENTS_DELETE_COMMENTS: 'comments2:delete',
    ID_EVENT_COMMENTS_INIT_COMPLETE: 'comments:init:complete',
    // collaboration
    ID_EVENT_COLLABORATION_UPDATEDMODELS: 'collaboration:updatedModels',
    // tasks
    ID_EVENT_TASKS_MASSDELETE: 'tasks:model:massdelete',
    ID_EVENT_TASKS_MASSUPDATE: 'tasks:model:massupdate',
  },
  popupViews: {
    ID_VIEW_POPUP_CUSTOMGRID: 'customGridPopup',
    ID_VIEW_POPUP_GLOBALSEARCH: 'globalSearchWindowId',
    ID_VIEW_POPUP_ADVANCED_HEADER: 'advancedHeaderPopup',
    ID_VIEW_POPUP_PROJ_SETTINGS: 'settings_popup',
    ID_VIEW_POPUP_COMMENTS: 'commentsListPopup',
    ID_VIEW_POPUP_MC_SELECT_MENU: 'idViewPopupMcSelectMenu',
    ID_VIEW_POPUP_MC_ACTIONS_MENU: 'idViewPopupMcActionsMenu',
    ID_VIEW_POPUP_MASSCHANGE: 'idViewPopupMasschange',
    ID_VIEW_POPUP_INTEGRATION_SLACK_SETTINGS: 'integration_slack_settings',
    ID_VIEW_POPUP_INTEGRATION_MSTEAMS_SETTINGS: 'integration_msteams_settings',
  },
  controlViews: {
    ID_VIEW_LEFT_SIDEBAR_FAVORITE_LIST: 'favoriteProjectsList',
    //
    ID_VIEW_TOOLBAR_BTN_DEMO: 'footerBtnDemoId',
    ID_VIEW_TOOLBAR_BTN_LEARNING: 'footerBtnLearningCenterId',
    ID_VIEW_TOOLBAR_BTN_CHAT: 'footerBtnChatId',

    ID_VIEW_BUTTON_ADVANCED_HEADER: 'advancedHeaderButton',
    ID_VIEW_SEARCH_FILTERGROUP: 'searchFilterGroupId',
    ID_VIEW_SEARCH_INPUT_TEXT: 'searchTextViewId',
    ID_VIEW_SEARCH_RESULTSTABLE: 'idSearchResultsTableView',
  },
};

// configuration
const app = core.create({
  id: 'GanttPRO',
  name: 'GanttPRO',
  version: GT.productVersion,
  debug: false,
  mode: GT.appMode,
});

webix.Date.startOnMonday = true;

const createSocketConnection = function (path) {
  const socketOptions = {
    transports: ['websocket'],
    secure: true,
    query: {
      ganttproSocketVersion: 2,
      user_id: user.id || 7,
      team_id: (user.team && user.team.id) ? user.team.id : null,
    },
  };

  // console.log('exportlog - createSocketConnection', { path, socketOptions });
  const socket = io(GT.siteConfig + path, socketOptions);

  // todo exportlog - remove dev condition
  if (window.GT && (window.GT.env === 'local' || window.GT.env === 'dev')) {
    const on = socket.on;

    socket.on = (event, cb) => on.call(socket, event, (...args) => {
      console.debug(event, args);
      // console.log('exportlog - socket.on');
      // if (socket.id && gantt._dp && gantt._dp?._headers?.socketid !== socket.id) {
      //   gantt._dp._headers.socketid = socket.id;
      // }

      return cb(...args);
    });
  }

  window[`socket${path}`] = socket;

  return socket;
};

if (GT.productVersion) {
  // webix.storage.cookie.put('clientGanttProVersion', GT.productVersion.version); // так было раньше и это вызывало выставление кук с path (если юзер зайдет например по роуту app.ganttpro.com/form/sale/)
  webix.storage.cookie.put('clientGanttProVersion', GT.productVersion.version, `${window.location.hostname};path=/`); // так кука выставляется для рутового пути на домене
}

app.socket = createSocketConnection(`/api/socket/${window.GT.env !== 'production' ? `-${window.GT.env}` : ''}`);
app.socketCommon = createSocketConnection(`/api/notify/${window.GT.env !== 'production' ? `-${window.GT.env}` : ''}`);
app.socketNotify = createSocketConnection(`/payment/notify/${window.GT.env !== 'production' ? `-${window.GT.env}` : ''}`);

app.modelscheckInitRequired = [
  'columns',
  'customDayTemplates',
  'projects',
  'filters',
  'tasks',
  'resources',
  'templates',
  'timeTracking',
  'multiViewsProjects',
  // 'comments',
  'apiKeys',
  'team',
  'userActivities',
  'roles',
];

try {
  window.userExtAnalytics.init();
  window.userExtAnalytics.log('load');
  if (!user.subscription.is_trial) {
    window.userExtAnalytics.log('load_by_paid_user'); // required for google analytics
  }
  // window.userExtAnalytics.log('focus');
  // window.addEventListener('focus', () => {
  //   window.userExtAnalytics.log('focus');
  // });
} catch (e) {
  // console.error('External services init fail', e);
}

app.checkInit = function (modelName) {
  const index = app.modelscheckInitRequired.indexOf(modelName);

  if (index !== -1) {
    app.modelscheckInitRequired.splice(index, 1);
  }
  // -- use this only for debugging
  // console.info(app.modelscheckInitRequired);
  if (!app.modelscheckInitRequired[0]) {
    if (!app.config.mode.isHistory) {
      app.trigger('app:initModels:complete');
      initVueApp(app).then(() => {
        app.trigger(appIds.events.ID_EVENT_APP_INIT_MODELS);
      });
    } else {
      app.trigger(appIds.events.ID_EVENT_APP_INIT_MODELS);
    }
    // app.trigger(appIds.events.ID_EVENT_APP_INIT_MODELS);
    // // (app.config.mode.isBase || app.config.mode.isExport ) && initVueApp(app);
    // !app.config.mode.isHistory && initVueApp(app);
  }
};

app.use(locale);
app.use(checkRoute);

webix.skin.set('material');

export { appIds };

export default app;
