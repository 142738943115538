var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: ["header_workload_range", _vm.isShowCalendar ? "active" : ""],
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }