import app from '../../../app';
import _ from '../../../libs/lodash';
import helperDate from '../../../helpers/dateFormats';
import timeIntervalHelper from '../../../helpers/timeIntervals';
import templates from '../../../templates/labels';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from '../../../store/main';
import helperTimeIntervals from '../../../helpers/timeIntervals';

const __ = window.__;

const innerObject = {
  views: {
    main: {
      id: 'zoomHoursPopup',
    },
    hoursRangeLabel: {
      view: 'template',
      id: 'displayOptionRangeHoursLabel',
      height: 38,
      css: 'hours-template',
      borderless: false,
      template(obj) {
        let innerHtml = '';

        this.intervals.forEach(intervalString => {
          innerHtml += `<div class='dataview-hours-inline-item'>${intervalString}</div>`;
        });

        return `<div class='dataview-hours-inline'>${innerHtml}</div>`;
      },
      onClick: {
        'hours-template': function () {
          outputObject.handlers.show(this.$view);
        },
      },
    },
    optionsHoursLabel: {
      view: 'label',
      id: 'optionsHoursLabel',
      align: 'center',
      height: 33,
      label: __('hours_label_desc_in_data_view'),
    },
    hoursRangeTitleLabel: {
      view: 'label',
      id: 'displayOptionRangeHoursTitleLabel',
      // width: 160,
      label: __('project_settings_hours'),
      css: 'label-with-spacer',
      toolTip: {
        y: 4,
        langKey: 'project_settings_range_hours_tooltip',
      },
      number: 0,
      template: templates.getTemplateForLabelWithSpacerAndNumber,
    },
    displayOptionsHoursDataView: {
      view: 'dataview',
      id: 'displayOptionsHoursDataView',
      select: true,
      multiselect: 'touch',
      xCount: 6,
      yCount: 8,
      scroll: false,
      align: 'center',
      css: 'dataview-hours displayOptionsHoursDataView',
      height: 320,
      type: {
        width: 72,
        height: 37,
        css: 'displayOptionsHoursDataView-row',
        template(obj) {
          return `<div class='dataview-hours-block'><div class='dataview-hours-item'>${helperDate.getUserTimeFormat() ? obj.id : obj.value}</div></div>`;
        },
      },
      data: [],
      on: {
        onSelectChange() {
        },
      },

    },
    buttonOk: {
      view: 'button',
      type: 'success',
      align: 'right',
      id: 'zoomHoursButtonOk',
      width: 100,
      css: 'webix_button_default button_raised',
      value: __('common_ok'),
      on: {
        onItemClick() {
          innerObject.handlers.buttonOkClick();
        },
      },
    },
  },
  init: {
    initHours() {
      const $$displayOptionsHoursDataView = $$(innerObject.views.displayOptionsHoursDataView.id);

      innerObject.helpers.hours = helperDate.getHoursArrayByUserTimeFormat();
      $$displayOptionsHoursDataView.parse(innerObject.helpers.hours.filter(i => i.id !== '24:00'));
    },
    run(currentSettings, changeProject) {
      const $$displayOptionsHoursDataView = $$(innerObject.views.displayOptionsHoursDataView.id);

      this.initHours();
      $$displayOptionsHoursDataView.blockEvent();

      if (!currentSettings.showTime.length) {
        currentSettings.showTime = [currentSettings.startTime, currentSettings.endTime];
      }

      currentSettings.startTime = 0;
      currentSettings.endTime = 0;

      const selectedHoursByIntervals = timeIntervalHelper.getHoursArrayByIntervalArray(currentSettings.showTime, innerObject.helpers.hours);

      innerObject.handlers.updateHoursNumberLabel($$(innerObject.views.hoursRangeTitleLabel.id), currentSettings.showTime);

      $$displayOptionsHoursDataView.select(selectedHoursByIntervals);
      $$displayOptionsHoursDataView.unblockEvent();

      innerObject.currentSettings = currentSettings;
      innerObject.handlers.changeHours(true, changeProject);
    },
  },
  handlers: {
    updateTasksEndDate(changeProject) {
      const ganttIDs = ganttViewModel.getGanttIDs();
      const memorizedCalculateEndDate = _.memoize((date, duration, task) => gantt.calculateEndDate({
        start_date: new Date(date),
        duration,
        task,
      }));

      _.each(ganttIDs, gantt_id => {
        const tasks = globalStore.getters['tasksModel/getItem'](gantt_id)?.tasks;

        if (tasks) {
          tasks.forEach(task => {
            task.end_date = memorizedCalculateEndDate(task.start_date, task.duration, task);

            return task;
          });
        }
      });

      !changeProject && gantt.callEvent('ganttClearAndParse', []);
    },
    updateHoursNumberLabel(webixUI, number) {
      if (number % 1 !== 0) {
        webixUI.config.number = `${Math.floor(number)} ${__('h')} 30${__('m')}`;
      } else if (number === 30) {
        webixUI.config.number = `30 ${__('m')}`;
      } else {
        webixUI.config.number = `${number} ${__('h')}`;
      }

      webixUI.refresh();
    },
    houseRangeSetLabel() {
      const delimiter = ',';
      const stringIntervals = innerObject.handlers.getLabelForIntervals(null, delimiter);
      // const hoursIntervals = stringIntervals.split(delimiter);
      const $$hoursRangeLabel = $$(innerObject.views.hoursRangeLabel.id);

      $$hoursRangeLabel.config.intervals = stringIntervals;
      $$hoursRangeLabel.config.height = 38 * Math.ceil(stringIntervals.length / 4);
      $$hoursRangeLabel.resize();
      $$hoursRangeLabel.refresh();
    },
    showPopup() {
      const $$displayOptionsHoursDataView = $$(innerObject.views.displayOptionsHoursDataView.id);
      const oldSelect = $$displayOptionsHoursDataView.getSelectedId();

      $$displayOptionsHoursDataView.blockEvent();
      $$displayOptionsHoursDataView.clearAll();
      $$displayOptionsHoursDataView.parse(innerObject.helpers.hours.filter(i => i.id !== '24:00'));
      $$displayOptionsHoursDataView.select(oldSelect);
      $$displayOptionsHoursDataView.config.number = `${oldSelect.length} ${__('h')}`;
      $$displayOptionsHoursDataView.unblockEvent();

      innerObject.handlers.helpers.removeTriangleDOM();
    },
    getFormattedHoursInterval(hours) {
      const hoursArray = helperDate.getHoursArrayByUserTimeFormat();
      const enableAM = !helperDate.getUserTimeFormat();

      const formattedIntervals = hours.map(interval => {
        const [start, end] = interval.trim().split('-');
        const startTime = hoursArray.find(item => item.id === start).value.replace(/AM|PM/g, '');
        let endTime = '';

        if (end === '24:00') {
          endTime = enableAM ? '12AM' : '24:00';
        } else {
          endTime = hoursArray.find(item => item.id === end).value.replace(/AM|PM/g, '');
        }

        return `${startTime}-${endTime}`;
      });

      return formattedIntervals;
    },
    getLabelForIntervals(currentHoursArray, delimiter) {
      const labelWithIntervals = '';
      const enableAM = !helperDate.getUserTimeFormat();

      if (app.config.mode.isLink) {
        return labelWithIntervals;
      }

      if (!innerObject.currentSettings.showTime) {
        innerObject.init.initHours();
        innerObject.handlers.changeHours();
      }
      if (currentHoursArray && typeof currentHoursArray[0] === 'number') {
        const currentHoursArray1 = helperTimeIntervals.getHoursArrayByIntervalArray(currentHoursArray, helperDate.getHoursArrayByUserTimeFormat());

        currentHoursArray = helperTimeIntervals.prepareSelectedHours(currentHoursArray1, helperDate.getHoursArrayByUserTimeFormat());
      }
      if (currentHoursArray && !enableAM) {
        return currentHoursArray;
      } if (currentHoursArray && enableAM) {
        return innerObject.handlers.getFormattedHoursInterval(currentHoursArray);
      }

      if (!enableAM) {
        return innerObject.currentSettings.showTime;
      }

      return innerObject.handlers.getFormattedHoursInterval(innerObject.currentSettings.showTime);
    },
    changeHours(blockSend, changeProject) {
      const $$displayOptionsHoursDataView = $$(innerObject.views.displayOptionsHoursDataView.id);
      const selectedHours = $$displayOptionsHoursDataView.getSelectedId();
      let countHours = 0;
      let showTimesIntervals = '';

      if (selectedHours.length) {
        showTimesIntervals = timeIntervalHelper.prepareSelectedHours(selectedHours, innerObject.helpers.hours);
      }

      if (!showTimesIntervals.length) {
        showTimesIntervals = ['9:00-13:00', '14:00-18:00'];
        $$displayOptionsHoursDataView.blockEvent();
        $$displayOptionsHoursDataView.select(timeIntervalHelper.getHoursArrayByIntervalArray(['9:00-13:00', '14:00-18:00'], innerObject.helpers.hours));
        $$displayOptionsHoursDataView.unblockEvent();
        $$displayOptionsHoursDataView.callEvent('onSelectChange', []);
        countHours = 8;
      }

      innerObject.currentSettings.showTime = webix.copy(showTimesIntervals);
      app.trigger('settings:zoom:update:label', innerObject.currentSettings);

      if (!countHours) {
        if (selectedHours && typeof selectedHours !== 'string' && selectedHours.length) {
          countHours = selectedHours.length / 2;
        } else if (selectedHours.length) {
          countHours = 30;
        }
      }

      innerObject.handlers.updateHoursNumberLabel($$(innerObject.views.hoursRangeTitleLabel.id), countHours);
      changeProject && innerObject.handlers.updateTasksEndDate(changeProject);

      if (blockSend) {
        innerObject.handlers.debounceChangeHours(blockSend);
      }
    },
    debounceChangeHours() {
    },
    buttonOkClick() {
      $$(innerObject.views.main.id).hide();
    },
    addCurrentSettingsParamToUpdate() {
      const projectConfig = ganttViewModel.getProjectConfig();
      const durationData = projectConfig.durationData;

      this.helpers.checkNewDurationData(durationData);
    },
    updateZoomSettings(duratioData) {
      const tempData = webix.copy(duratioData);
      const oldShowTime = innerObject.currentSettings.showTime;

      innerObject.currentSettings = tempData;
      innerObject.currentSettings.showTime = oldShowTime;
    },
    helpers: {
      hour24toAMPM(digitHour) {
        let timeCorrector = 0;
        let AMPM = 'AM';

        if (digitHour === 0) {
          timeCorrector = -12;
        }

        if (digitHour === 12) {
          timeCorrector = 0;
          AMPM = 'PM';
        }
        if (digitHour > 12 && digitHour !== 12.5) {
          timeCorrector = 12;
          AMPM = 'PM';
        }
        if (digitHour === 12.5) {
          return '12:30';
        }
        if (digitHour === 0.5) {
          return '00:30';
        }
        if (digitHour === 24) {
          return '12 AM';
        }

        const calcHour = (digitHour - timeCorrector);

        if (calcHour % 1 !== 0) return `${Math.floor(calcHour)}:30`;

        return calcHour;
      },
      removeTriangleDOM() {
        let triangleDOM = document.getElementsByClassName('webix_point_top')[0];

        if (!triangleDOM) {
          triangleDOM = document.getElementsByClassName('webix_point_bottom')[0];
        }
        if (!triangleDOM) {
          return false;
        }
        triangleDOM.style.display = 'none';
      },
    },
  },
  helpers: {
  },
  settings: {},
  currentSettings: {},
  defaultSettings: {
    hours: ['0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  },
};

webix.ui({
  view: 'popupWith6Padding',
  id: innerObject.views.main.id,
  padding: 12,
  body: {
    width: 472,
    height: 353,
    // type: 'clean',
    paddingX: 0,
    paddingY: 0,
    borderless: true,
    rows: [
      {
        height: 320,
        css: 'displayOptionsHoursDataView',
        body:
        innerObject.views.displayOptionsHoursDataView,

      },

      // { width: 428 },
      innerObject.views.optionsHoursLabel,
    ],
  },
  on: {
    onShow() {
      innerObject.handlers.showPopup();
    },
    onHide() {
      innerObject.handlers.changeHours();
    },
  },
}).hide();

const outputObject = {
  handlers: {
    show(node) {
      $$(innerObject.views.main.id).show(node);
    },
    init(currentSettings, changeProject) {
      innerObject.init.run(currentSettings, changeProject);
    },
    getCurrentHoursTime() {
      return [innerObject.settings.startTime, innerObject.settings.endTime];
    },
    checkNewData(oldValue) {
      return !_.isEqual(oldValue, innerObject.currentSettings.showTime);
    },
    getNewHoursRange() {
      return webix.copy(innerObject.currentSettings.showTime);
    },
    getLabelForIntervals(hoursRange, delimiter) {
      return innerObject.handlers.getLabelForIntervals(hoursRange, delimiter);
    },
    saveSettings() {},
    getIntervals(hoursRange) {},
    hide() {},
    houseRangeSetLabel: innerObject.handlers.houseRangeSetLabel,
    updateHoursNumberLabel: innerObject.handlers.updateHoursNumberLabel,
  },
  views: {
    optionsHoursLabel: innerObject.views.optionsHoursLabel,
    displayOptionsHoursDataView: innerObject.views.displayOptionsHoursDataView,
    hoursRangeTitleLabel: innerObject.views.hoursRangeTitleLabel,
    hoursRangeLabel: innerObject.views.hoursRangeLabel,
  },
};

// app.on("gantt:init:before", innerObject.init.initDebounce);

export default outputObject;
