<template>
  <div>
    <webix-ui :config="window"/>
  </div>
</template>

<script>
  import app from './../../app';
  import innerImport from './importpreviewXLSX';

  export default {
    props: {
    },
    name: "importPreview",
    data() {
      return {
        window: innerImport.$ui
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        innerImport.$oninit(webix)
      })
    }
  }
</script>

<style scoped>

</style>