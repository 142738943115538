<template>
  <div :style="{'padding-top': '16px'}">
    <resources-switch
      :active="activeLayout"
      @onToggleSwitch="onToggleLayout"
    />
    <div :class="$.description">
      {{ activeLayout === 'people'
        ? locales('project_resources_manage_layout_header_txt_1')
        : locales('project_resources_manage_layout_header_txt_2') }}
    </div>
    <div
      ref="tablePanel"
      :class="$.project_table_panel"
    >
      <div :class="$.search">
        <search-input
          :placeholder="
            activeLayout === 'people'
              ? locales('people_resources_search_placeholder')
              : locales('material_resources_search_placeholder')
          "
          :value="searchValue"
          @onInput="onSearch"
        />
      </div>
      <div :class="$.panel_buttons">
        <vgp-button
          v-if="activeLayout === 'material' && selectedResources.length > 0"
          :class="$.delete_btn"
          type="destructive-border"
          :label="locales('resources_delete_btn')"
          :small="true"
          @onClick="onClickDeleteResources"
        />
        <vgp-button
          v-if="!(activeLayout === 'material' && isJiraProject)"
          type="primary"
          :disabled="activeLayout === 'material' && selectedResources.length > 0"
          :label="activeLayout === 'people'
            ? locales('project_resources_invite_btn')
            : locales('project_resources_create_btn')"
          :icon="{
            name: activeLayout === 'people' ? 'add-user' : 'plus-1',
            size: activeLayout === 'people' ? '24' : '20',
            type: activeLayout === 'people' ? 'regular' : 'bold'
          }"
          icon-side="left"
          :small="true"
          @onClick="openInvitePopup"
        />
      </div>
    </div>
    <resources-table
      :cost-component-key="costComponentKey"
      :headers="tableHeaders"
      :resources="tableResources"
      :roles="projectRoles"
      :resources-type="activeLayout"
      :me-is-king="meIsKing"
      :me-is-project-owner="meIsProjectOwner"
      @onDeleteResource="onClickDeleteResources"
      @onChangeType="onChangeType"
      @onChangeCost="onChangeCost"
      @onChangeRole="onChangeRole"
      @onChangeOwner="onChangeOwner"
      @onSelectResources="setSelectedResources"
      @onConvertResources="onClickConvertResources"
    />
    <invite-to-project-popup
      :is-show="isShowInvitePopup"
      :resources-from-other-projects="resourcesFromOtherProjects"
      :project-roles="projectRoles"
      :account-roles="accountRoles"
      :layout="activeLayout"
      :create-and-invite-resources-access="createAndInviteResourcesAccess"
      :account-role-change-access="accountRoleChangeAccess"
      :project-role-change-access="projectRoleChangeAccess"
      @onClose="isShowInvitePopup = false"
      @addResourcesToProject="addResourcesToProject"
      @onSendInvites="onSendInvites"
      @onCreateResource="onCreateResource"
    />
    <confirm-popup
      :is-show="isShowConfirmPopup"
      :title="locales('change_resource_type_title')"
      :message="locales('change_resource_type_warning')"
      @onApply="onApplyChangeType"
      @onCancel="onCancelChangeType"
    />
    <div
      v-if="!tableResources.length"
      :class="$.empty_area"
      :style="{ height: stubHeight }"
    >
      <div
        v-if="searchValue !== ''"
        :class="$.wrap"
        v-html="searchStub"
      />
      <div
        v-else
        :class="$.wrap"
        v-html="stub"
      />
      <div :class="$.desc">
        {{ searchValue !== '' ? searchMessage[activeLayout] : locales("resources_no_resources_description") }}
      </div>
    </div>
  </div>
</template>

<script>
import tableHeaders from './resourcesTable/tableHeaders';
import searchStub from '../../../svg/search_no_result_pic.svg';
import stub from '../../../svg/resources/empty_resources.svg';
import resourcesTable from './resourcesTable/resourcesTable.vue';
import confirmPopup from './popups/confirmPopup.vue';
import searchInput from './inputSearch/inputSearch.vue';
import vgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import resourcesSwitch from './resourcesSwitch/switch.vue';
import inviteToProjectPopup from './popups/inviteToProjectPopup.vue';
import projectsModel from '../../../models/projects';

export default {
  components: {
    resourcesTable,
    confirmPopup,
    searchInput,
    vgpButton,
    resourcesSwitch,
    inviteToProjectPopup,
  },
  provide() {
    return {
      checkExistResourceName: this.checkExistResourceName,
    };
  },
  props: {
    projectResources: { type: Object, required: true, default: { people: [], material: [] } },
    allResources: { type: Array, required: true, default: () => [] },
    projectRoles: { type: Array, required: true, default: () => [] },
    accountRoles: { type: Array, required: true, default: () => [] },
    projects: { type: Array, required: true, default: () => [] },

    meIsKing: { type: Boolean, required: false, default: false },
    meIsProjectOwner: { type: Boolean, required: false, default: false },
    accountRoleChangeAccess: { type: Boolean, required: false, default: false },
    projectRoleChangeAccess: { type: Boolean, required: false, default: false },

    hasRightToCostSettings: { type: Boolean, required: false, default: false },
    pricingAccessToCostSettings: { type: Boolean, required: false, default: false },
    createAndInviteResourcesAccess: { type: Boolean, required: false, default: false },
  },
  emits: {
    onSendInvite: Object,
    onCreateResource: Object,
    removeResourcesFromProject: Array,
    onChangeCostType: Object,
    onChangeCost: Object,
    onChangeProjectRole: Object,
    onChangeOwner: Object,
    showRemovePopup: Object,
    showConvertPopup: Object,
  },
  data() {
    return {
      locales: __,
      costComponentKey: 0,
      searchValue: '',
      activeLayout: 'people',
      selectedResources: [],
      newCostType: null,
      isShowInvitePopup: false,
      isShowConfirmPopup: false,
      searchStub,
      stub,
      stubHeight: null,
      searchMessage: {
        people: __('resources_no_search_result'),
        material: __('material_resources_no_search_result'),
      },
    };
  },
  computed: {
    tableHeaders() {
      let peopleTable = tableHeaders.people;
      let materialTable = tableHeaders.material;

      if (!this.pricingAccessToCostSettings || !this.hasRightToCostSettings) {
        peopleTable = peopleTable.filter(obj => !['cost', 'type'].includes(obj.value));
        materialTable = materialTable.filter(obj => !['cost', 'type'].includes(obj.value));
      }

      if (!projectsModel.getActiveProjectData().config.auto_budget) {
        peopleTable = peopleTable.filter(obj => !['cost'].includes(obj.value));
        materialTable = materialTable.filter(obj => !['cost'].includes(obj.value));
      }

      peopleTable = peopleTable.filter(obj => obj.value !== 'status');
      peopleTable.forEach(obj => {
        if (obj.value === 'roleTitle') {
          obj.text = __('project_role_title');
          obj.sortable = false;
        }
      });

      const headers = {
        people: peopleTable,
        material: materialTable,
      };

      return headers[this.activeLayout];
    },
    tableResources() {
      const resources = this.projectResources[this.activeLayout];

      resources.sort((a, b) => a.name.localeCompare(b.name));

      const ownerIdx = resources.findIndex(resource => resource.isOwner === true);

      if (ownerIdx !== -1) {
        const owner = resources.splice(ownerIdx, 1)[0];

        resources.unshift(owner);
      }

      return resources.filter(resource => {
        const email = resource.email || '';

        return resource?.name.toLowerCase().startsWith(this.searchValue.toLowerCase())
              || email.toLowerCase().startsWith(this.searchValue.toLowerCase());
      });
    },
    resourcesFromOtherProjects() {
      return this.allResources[this.activeLayout].filter(resource => !resource.resourceProjects.find(pr => pr.projectId === gantt.config.gantt_id));
    },
    isJiraProject() {
      return projectsModel.getProjectDataById(gantt.config.gantt_id)?.is_jira;
    },
  },
  mounted() {
    this.$nextTick(() => this.calcStubHeight());
  },
  methods: {
    onSearch(value) {
      this.searchValue = value;
    },
    setSelectedResources(selected) {
      this.selectedResources = selected;
    },
    calcStubHeight() {
      this.stubHeight = `calc(100vh - ${this.$refs.tablePanel.getBoundingClientRect().bottom + 78}px)`;
    },
    onToggleLayout(obj) {
      this.activeLayout = obj.type;
      this.searchValue = '';
    },
    openInvitePopup() {
      this.isShowInvitePopup = true;
    },
    checkExistResourceName(name) {
      const str = name.toLowerCase();
      const exist = this.allResources[this.activeLayout].find(resource => resource.name.toLowerCase().startsWith(str));

      return exist && exist.name.length === name.length;
    },
    onSendInvites(data) {
      this.$emit('onSendInvites', data);
      this.searchValue = '';
      this.isShowInvitePopup = false;
      this.$toast.info(__('common_info_resource_ivited'));
    },
    onCreateResource(data) {
      this.$emit('onCreateResource', data);
      this.searchValue = '';
      this.isShowCreateResourcePopup = false;
      this.$toast.info(__('common_info_resource_created'));
    },
    addResourcesToProject(data) {
      data.resourcesType = this.activeLayout;
      this.$emit('addResourcesToProject', data);
      this.searchValue = '';
      this.isShowCreateResourcePopup = false;
    },
    onClickDeleteResources(resourceData) {
      const resources = resourceData ? [resourceData] : this.selectedResources;
      let hasOwner = false;

      resources.forEach(resource => {
        if (resource.isOwner) {
          this.$emit('onChangeOwner', {
            type: 'project',
            resource_id: resourceData.id,
            gantt_id: gantt.config.gantt_id,
          });

          hasOwner = true;
        }
      });

      if (!hasOwner) {
        this.$emit('showRemovePopup', {
          type: 'project',
          resources,
          projectId: gantt.config.gantt_id,
        });
      }
    },
    onChangeType(data) {
      if (data.oldTypeId === data.newTypeId) return;

      this.isShowConfirmPopup = true;
      this.newCostType = data;
    },
    onChangeCost(data) {
      this.$emit('onChangeCost', data);
      if (data.oldCost === 0 && data.newCost === '') { this.costComponentKey++; }
    },
    onChangeRole(data) {
      this.$emit('onChangeProjectRole', data);
    },
    onApplyChangeType() {
      this.$emit('onChangeCostType', {
        resourceId: this.newCostType.resourceId,
        projectIds: this.newCostType.projectIds,
        newTypeId: this.newCostType.newTypeId,
      });
      this.newCostType = null;
      this.isShowConfirmPopup = false;
    },
    onCancelChangeType() {
      this.costComponentKey++;
      this.newCostType = null;
      this.isShowConfirmPopup = false;
    },
    onChangeOwner(resourceData) {
      this.$emit('onChangeOwner', {
        type: 'change',
        resource_id: resourceData.id,
      });
    },
    onClickConvertResources(sourceResourceId) {
      this.$emit('showConvertPopup', {
        type: 'project',
        sourceResourceId,
        projectId: gantt.config.gantt_id,
      });
    },
  },
};
</script>

<style module="$" src="../less/resources.less" />
