import { getParentsIDs } from '../tasks/helpers';
import globalStore from '$$store/main';

const eventsToCollectForQueue = [
  'TaskDeleted',
  'TaskCreated',
  'TaskCommentCreated',
  'TaskCommentDeleted',
  'TaskAttachementCreated',
  'TaskAttachementDeleted',
  'TaskLinkDeleted',
  'ResourceDeleted',
  'ResourceCreated',
  'ResourceUpdated',
  'ResourceToProjectsAssigned',
  'ResourceFromProjectsUnassigned',
  'ResourceOnProjectsUpdated',
  'ResourcesOnProjectsConverted',
  'ResourceRoleUpdated',
  'ProjectCalendarUpdated',
  'ProjectConfigUpdated',
  'ProjectArchived',
  'ProjectDeleted',
];

const eventsToCollect = [
  'CustomFieldFromProjectUnassigned',
  'CustomFieldToProjectAssigned',
  'CustomFieldDeleted',
  'ResourceRoleUpdated',
  'ResourceOnProjectsUpdated',
  'TaskUpdated',
  'TaskLinkCreated',
  'TaskResourceUnassigned',
  'TaskResourceAssigned',
];

class CollectData {
  _emptyData() {
    return {
      resourcesToTask: [], // tasks ids
      tasks: [], // tasks ids
      changedSortorder: [], // tasks ids
      customValuesTask: [], // tasks ids
      customFields: [], // custom columns ids
      customFieldsToProject: [], // projects ids
      teamColumns: false,
      timelogs: [], // tasks ids
      links: [], // target tasks ids
    };
  }

  constructor() {
    this._eventsQueue = [];
    this._toUpdate = this._emptyData();
    this._editingTasks = [];
    this._collectedEvents = [];
    this._allCollectedEventsCount = 0;
  }

  clear() {
    this._toUpdate = this._emptyData();
    this._editingTasks = [];
    this._collectedEvents = [];
    this._allCollectedEventsCount = 0;
  }

  get allCollectedEventsCount() {
    return this._allCollectedEventsCount;
  }

  get changedTaskIds() {
    const ids = [
      ...this._toUpdate.resourcesToTask,
      ...this._toUpdate.customValuesTask,
      ...this._toUpdate.timelogs,
      ...this._toUpdate.tasks,
      ...this._toUpdate.changedSortorder,
      ...this._toUpdate.links,
    ];

    return [...new Set(ids)];
  }

  get toUpdate() {
    if (this._toUpdate.tasks.length) {
      const tids = [];

      this._editingTasks.forEach(task_id => {
        tids.push(...getParentsIDs(task_id));
        this._toUpdate.tasks.push(task_id, ...tids);
      });
    }

    return this._prepareToVals(this._toUpdate);
  }

  get events() {
    return this._eventsQueue;
  }

  shiftEvent() {
    return this._eventsQueue.shift();
  }

  hasEventProjectCalendarUpdated() {
    return this.events.find(e => e.event === 'ProjectCalendarUpdated');
  }

  _prepareToVals(val) {
    let res = null;

    Object.entries(val).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length) {
        res = res || {};
        res[key] = [...new Set(value)];
      }

      if (typeof value === 'boolean' && value) {
        res = res || {};
        res[key] = value;
      }
    });

    return res;
  }

  addTaskId(task_id) {
    this._editingTasks.push(task_id);
  }

  collect(e) {
    this._allCollectedEventsCount++;

    if (eventsToCollect.includes(e.event)) {
      this._collectedEvents.push(e);
    }

    if (eventsToCollectForQueue.includes(e.event)) {
      this._eventsQueue.push(e);

      if (e.event === 'TaskCreated') {
        this._collectTasks(e);
      }

      return;
    }

    this._collectResourceToTask(e);
    this._collectTasks(e);
    this._collectCustomValues(e);
    this._collectCustomFields(e);
    this._collectTimelog(e);
    this._collectLinks(e);
  }

  get userRoleInCollectedEvents() {
    let newUserRole = null;

    this._collectedEvents.forEach(e => {
      if (e.event !== 'ResourceRoleUpdated ') { return false; }

      const role = e.data.updated.find(i => i.resourceId === globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id);

      role && (newUserRole = role);
    });

    return newUserRole ? newUserRole.roleId : null;
  }

  get deletedTasksInCollectedEvents() {
    const taskIds = [];

    this.events.forEach(e => {
      if (e.event !== 'TaskDeleted') { return; }

      taskIds.push(...e.data.ids);
    });

    return taskIds;
  }

  get createdTasksInCollectedEvents() {
    const collectedTasks = {};

    this.events.forEach(e => {
      if (e.event !== 'TaskCreated') { return; }

      e.data.tasks.forEach(task => {
        let projectTasks = collectedTasks[task.projectId];

        if (!projectTasks) {
          projectTasks = [];
          collectedTasks[task.projectId] = projectTasks;
        }

        projectTasks.push(task.id);
      });
    });

    return collectedTasks;
  }

  get unassignedCustomColumnsInCollectedEvents() {
    const deleted = [];
    const unassigned = {};

    this._collectedEvents.forEach(e => {
      if (e.event === 'CustomFieldDeleted') {
        deleted.push(...e.data.ids);
      }

      if (e.event === 'CustomFieldToProjectAssigned') {
        e.data.assignedCustomFields.forEach(i => {
          i.projectIds.forEach(gantt_id => {
            const unassignedFields = getItem(unassigned, gantt_id);
            const index = unassignedFields.indexOf(i.customFieldId);

            index >= 0 && unassignedFields.splice(index, 1);
          });
        });
      }

      if (e.event === 'CustomFieldFromProjectUnassigned') {
        e.data.unassignedCustomFields.forEach(i => {
          i.projectIds.forEach(gantt_id => {
            const unassignedFields = getItem(unassigned, gantt_id);

            unassignedFields.push(i.customFieldId);
          });
        });
      }
    });

    Object.values(unassigned).forEach(value => {
      value.push(...deleted);
    });

    return unassigned;

    function getItem(obj, id) {
      let item = obj[id];

      if (!item) {
        item = [];
        obj[id] = item;
      }

      return item;
    }
  }

  get tasksWithCreatedLinksInCollectedEvents() {
    const tasksIds = [];

    this._collectedEvents.forEach(e => {
      if (e.event !== 'TaskLinkCreated') { return; }
      e.data.links.forEach(item => {
        tasksIds.push(item.target, item.source);
      });
    });

    return [...new Set(tasksIds)];
  }

  get resourceOnProjectsUpdatedFromCollectedEvents() {
    return this._collectedEvents.filter(e => e.event === 'ResourceOnProjectsUpdated');
  }

  getUnassignedResourcesByTaskFromCollectedEvents(task_id) {
    let unassigned = [];
    let assigned = [];

    this._collectedEvents.forEach(e => {
      if (e.event === 'TaskResourceUnassigned') {
        const resources = e.data.unassignedResources.find(i => i.taskId === task_id);

        if (!resources) { return; }

        unassigned.push(...resources.unassignedResourcesIds);
        assigned = assigned.filter(i => !unassigned.includes(i));
      }

      if (e.event === 'TaskResourceAssigned') {
        const assigned = e.data.assignedResources.find(i => i.taskId === task_id);

        if (!assigned) { return; }

        const assignedIds = assigned.resources.map(i => i.resourceId);

        unassigned = unassigned.filter(i => !assignedIds.includes(i));
      }
    });

    return { unassigned, assigned };
  }

  getProjectRightsInCollectedEvents(project_id) {
    let newRights = null;

    this._collectedEvents.forEach(e => {
      if (e.event !== 'ResourceOnProjectsUpdated ') { return false; }
      const rights = e.project.updated.find(i => i.projectId === project_id && i.resourceId === globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id);

      rights && (newRights = rights);
    });

    return newRights ? newRights.rights : null;
  }

  getChangedFieldsInTaskFromCollectedEvents(tasks_id) {
    const taskUpdates = {};

    this._collectedEvents.forEach(e => {
      if (e.event !== 'TaskUpdated') { return; }

      const item = e.data.updated.find(i => i.taskId === tasks_id);

      item && Object.assign(taskUpdates, item.updatedFields);
    });

    return taskUpdates;
  }

  _collectTasks(e) {
    let tids = [];
    const sortorder = [];

    if (e.event === 'TaskCreated') {
      tids = e.data.tasks.map(t => t.id);
    }

    if (e.event === 'TaskUpdated') {
      tids = e.data.updated.map(i => {
        i.updatedFields.sortorder && sortorder.push(i.taskId);

        return i.taskId;
      });
    }

    this._toUpdate.tasks.push(...tids);
    this._toUpdate.changedSortorder.push(...sortorder);
  }

  _collectResourceToTask(e) {
    const taskIds = [];

    if (e.event === 'TaskResourceUnassigned') {
      e.data.unassignedResources.forEach(item => {
        taskIds.push(item.taskId);
      });
    }

    if (e.event === 'TaskResourceAssigned') {
      e.data.assignedResources.forEach(item => {
        taskIds.push(item.taskId);
      });
    }

    if (e.event === 'TaskResourceUpdated') {
      e.data.updated.forEach(item => {
        taskIds.push(item.taskId);
      });
    }

    this._toUpdate.resourcesToTask.push(...taskIds);
  }

  _collectCustomValues(e) {
    if (e.event === 'TaskCustomFieldUpdated') {
      const taskIds = e.data.customFields.map(i => i.taskId);

      this._toUpdate.customValuesTask.push(...taskIds);
    }
  }

  _collectCustomFields(e) {
    if (e.event === 'CustomFieldUpdated') {
      const ids = e.data.updated.map(i => i.customFieldId);

      this._toUpdate.customFields.push(...ids);
    }

    if (e.event === 'CustomFieldToProjectAssigned') {
      const ids = [];

      e.data.assignedCustomFields.forEach(i => ids.push(...i.projectIds));
      this._toUpdate.customFieldsToProject.push(...ids);
    }

    if (e.event === 'CustomFieldFromProjectUnassigned') {
      const ids = [];

      e.data.unassignedCustomFields.forEach(i => ids.push(...i.projectIds));
      this._toUpdate.customFieldsToProject.push(...ids);
    }

    if (e.event === 'CustomFieldCreated') {
      this._toUpdate.teamColumns = true;
    }

    if (e.event === 'CustomFieldDeleted') {
      this._toUpdate.teamColumns = true;
    }
  }

  _collectTimelog(e) {
    if (e.event === 'TaskTimeLogUpdated') {
      this._toUpdate.timelogs.push(e.innerSettings.task_id);
    }

    if (e.event === 'TaskTimeLogsCreated') {
      const ids = e.data.timeLogs.map(i => i.taskId);

      this._toUpdate.timelogs.push(...ids);
    }

    if (e.event === 'TaskTimeLogDeleted') {
      const tid = e.innerSettings.task_id;

      this._toUpdate.timelogs.push(tid);
    }
  }

  _collectLinks(e) {
    if (e.event === 'TaskLinkCreated') {
      const ids = e.data.links.map(i => i.target);

      this._toUpdate.links.push(...ids);
    }
  }
}

export default new CollectData();
