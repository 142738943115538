import app from '../../app';
import routerHelper from '$$helpers/router';
import helpPopup from '../help/helpPopup';
import manager from './filters/Manager';

const filterStore = {
  namespaced: true,
  state: {
    isOpen: false,
    isActive: false,
    currentFilter: null,
    filterState: null,
    disable: false,
  },

  getters: {
    isOpen: state => state.isOpen,
    isActive: state => state.isActive,
    currentFilter: state => state.currentFilter,
    filterState: state => state.filterState,
    isDisable: state => state.disable,
  },

  mutations: {
    setFilterState(state, val) {
      state.filterState = val;
    },
    setIsOpenFilter(state, flag) {
      state.isOpen = flag;
      this.commit('headerView/toggleFilterPopup', flag);
    },
    setIsActiveFilter(state, flag) {
      state.isActive = flag;
      this.commit('headerView/setActiveFilter', flag);

      if (state.currentFilter && routerHelper.isGanttGridRoute()) {
        app.trigger('filterWorkloadAfterGanttFilter', state.filterState);
      }

      if (app.config.mode.isExport) {
        app.trigger('filterActive', flag);
      }
    },
    setCurrentFilter(state, current) {
      state.currentFilter = current;
    },
    setIsDisable(state, val) {
      state.disable = val;
    },
  },

  actions: {
    init({ commit }, route_name) {
      const filter = manager.initFilter(route_name);

      if (!filter) {
        commit('setCurrentFilter', null);

        return;
      }

      commit('setCurrentFilter', filter);
      commit('setFilterState', filter.filterState);

      if (app.config.mode.isExport) {
        app.trigger('filterActive', filter.isActiveFilter);
      }
    },

    setIsOpenFilter({ commit, getters, dispatch }, flag) {
      flag && app.trigger('popup:show');

      commit('setIsOpenFilter', flag);
      if (app.config.mode.isExport) {
        app.trigger('toggleFilter', getters.isOpen);
      }

      if (app.config.mode.isLink) {

      }
    },
    toggleIsOpenFilter({ getters, dispatch }) {
      const flag = !getters.isOpen;

      dispatch('setIsOpenFilter', flag);
    },
  },

};

export default filterStore;
