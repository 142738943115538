import app from "./../app";


var innerObject = {
  handlers: {
    helpers: {
      showMessage: function (text, type, expire) {
        webix.message({
          type: (type || "info"),
          text: text,
          expire: expire || 8000
        });
      }
    }
  },
  socketData: {
    count: 0
  }
};


var outputObject = {
  show: innerObject.handlers.helpers.showMessage
};

export default outputObject;
