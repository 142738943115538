<template>
  <div :class="$.overview_charts">
    <div
      v-if="isShowStub"
      :class="$.stub"
    >
      <svg
        width="273"
        height="257"
        viewBox="0 0 273 257"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="273"
          height="257"
          fill="white"
        />
        <path
          d="M45.0132 151.341C44.2713 149.368 43.5921 147.374 42.9761 145.364M38.7642 122.683C38.3972 117.436 38.4372 112.154 38.8907 106.884C40.1892 91.7942 44.8453 77.1854 52.5182 64.1268C60.1911 51.0683 70.6866 39.8906 83.2368 31.4117C95.7869 22.9327 110.074 17.3671 125.053 15.1222C140.031 12.8773 155.322 14.0098 169.807 18.4368L155.492 65.2729C147.99 62.9799 140.07 62.3934 132.312 63.5561C124.554 64.7189 117.154 67.6015 110.654 71.993C104.154 76.3846 98.7175 82.174 94.7434 88.9375C90.7694 95.701 88.3578 103.267 87.6852 111.083C87.0127 118.899 88.0962 126.766 90.8563 134.109C93.6165 141.452 97.9836 148.085 103.638 153.523C109.292 158.96 116.091 163.065 123.536 165.536C130.982 168.007 138.885 168.782 146.669 167.804L152.772 216.397C137.744 218.285 122.484 216.788 108.11 212.017C93.7347 207.246 80.6084 199.322 69.6913 188.824C60.669 180.147 53.3444 169.895 48.0654 158.595"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M238.867 139.441C244.491 116.149 241.702 91.6201 230.993 70.1861C220.284 48.7521 202.343 31.793 180.342 22.3049L160.948 67.2763C172.344 72.1905 181.636 80.9743 187.182 92.0758C192.729 103.177 194.174 115.882 191.261 127.945L238.867 139.441Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M163.463 214.469C179.919 210.582 195.145 202.65 207.76 191.391C220.375 180.132 229.98 165.902 235.705 149.992L189.623 133.41C186.658 141.651 181.683 149.021 175.149 154.852C168.615 160.684 160.729 164.792 152.206 166.805L163.463 214.469Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M193.035 31.9563L174.134 42.8246L204.376 39.5168L164.211 57.4731L218.552 46.6048L174.134 73.5392L228.948 57.4731C212.724 66.1362 180.655 83.4624 182.167 83.4624C183.679 83.4624 224.065 78.107 244.069 75.4293L187.838 96.2207L234.146 90.5503L187.838 111.342L234.146 100.001C219.655 106.144 191.145 118.335 193.035 117.957C194.926 117.579 238.556 110.239 260.135 106.616L206.266 129.298L234.146 125.518"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M47.4952 134.968C47.4952 141.754 43.0524 147.254 37.572 147.254C32.0916 147.254 25.2861 141.754 25.2861 134.968C25.2861 128.183 32.0916 122.683 37.572 122.683C43.0524 122.683 47.4952 128.183 47.4952 134.968Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M43 135C43 138.866 40.3994 142 37.1915 142C33.9835 142 30 138.866 30 135C30 131.134 33.9835 128 37.1915 128C40.3994 128 43 131.134 43 135Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M57.8917 198.288C57.8917 205.073 53.4489 210.574 47.9685 210.574C42.488 210.574 35.6826 205.073 35.6826 198.288C35.6826 191.503 42.488 186.002 47.9685 186.002C53.4489 186.002 57.8917 191.503 57.8917 198.288Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M52 198.5C52 202.09 49.7995 205 47.0851 205C44.3707 205 41 202.09 41 198.5C41 194.91 44.3707 192 47.0851 192C49.7995 192 52 194.91 52 198.5Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M13 212.936L57.4181 126.463L63.0885 125.045L23.8683 212.936H13Z"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
        <path
          d="M200.143 237.697L89.5498 234.2L175.813 242H112.996"
          stroke="#5C5C5C"
          stroke-width="1.23"
        />
      </svg>
      <div :class="$.stub_text">
        {{ locales('overview_stub_text') }}
      </div>
      <vgp-button
        :class="$.stub_button"
        type="primary"
        :label="locales('overview_manage_widgets_button')"
        @onClick="$emit('openManageModal')"
      />
    </div>

    <div
      v-else
      :class="$.wrap"
    >
      <template v-for="chart in charts">
        <div
          v-if="getChartComponent(chart.key)"
          :key="chart.id"
          :class="[
            $.item,
            chart.x_size === 2 ? $.large : '',
          ]"
        >
          <component
            :is="getChartComponent(chart.key)"
            :chart="chart"
            :data="getChartData(chart.key)"
            @onOpenDescription="openDescriptionModal"
          />
        </div>
      </template>
    </div>

    <description-modal
      v-if="isShowModal"
      :show="isShowModal"
      :description="projectData.description"
      @onCancel="isShowModal = false"
      @onSave="saveDescription"
    />
  </div>
</template>

<script>
import constants from '../../../helpers/constants';
import moment from '../../../libs/moment';
import app from '../../../app';
import { internalApi } from '$$store/api';
import rights from '../../../components/rights';
import dateHelper from '../../../helpers/dateFormats';
import timeTrackingModel from '../../../models/timeTracking';
import estimationModule from '../../gantt/modules/estimation';
import projectsModel from '../../../models/projects';
import moduleGanttDateWorker from '../../gantt/modules/ganttDateWorker';
import { checkPricingAccess } from '../../../helpers/pricingHelper';
import VgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import ChartProjectInfo from './ChartProjectInfo.vue';
import ChartByStatus from './ChartByStatus.vue';
import ChartMilestones from './ChartMilestones.vue';
import ChartSchedule from './ChartSchedule.vue';
import ChartBarVertical from './ChartBarVertical.vue';
import DescriptionModal from './DescriptionModal.vue';
import timeParser from '../../../helpers/timeParser';

export default {
  components: {
    VgpButton,
    ChartProjectInfo,
    ChartByStatus,
    ChartMilestones,
    ChartSchedule,
    ChartBarVertical,
    DescriptionModal,
  },
  props: {
    overviewData: {
      type: Object,
      required: true,
    },
    baselineData: {
      type: Object,
      required: true,
    },
    ganttId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locales: __,
      projectData: {},
      chartsMap: {
        project_info: { component: 'ChartProjectInfo', data: 'dataChartProjectInfo' },
        tasks_by_statuses: { component: 'ChartByStatus', data: 'dataChartStatusTasks' },
        time_on_tasks_h: { component: 'ChartBarVertical', data: 'dataChartTimeTasks' },
        milestones: { component: 'ChartMilestones', data: 'dataChartMilestones' },
        schedule: { component: 'ChartSchedule', data: 'dataChartSchedule' },
        cost: {
          component: 'ChartBarVertical', data: 'dataChartCost', rights: 'cost_field', features: 'costs',
        },
      },
      isShowModal: false,
      updateTimeLog: 1,
      waitTodayTasksDuration: false,
      todayTasksDuration: {},
      listeners: [],
    };
  },
  computed: {
    resources() {
      return this.$store.getters['resourcesModel/getResourcesByProjectId'](this.ganttId);
    },
    charts() {
      if (!this.overviewData.charts) return [];

      const charts = this.overviewData.charts.filter(chart => chart.status);

      charts.sort((a, b) => {
        if (a.y > b.y) return 1;
        if (a.y < b.y) return -1;
        if (a.x > b.x) return 1;
        if (a.x < b.x) return -1;

        return 0;
      });

      return charts;
    },
    isShowStub() {
      return !this.charts.filter(chart => this.getChartComponent(chart.key)).length;
    },
    taskStatuses() {
      const activeProjectData = projectsModel.getActiveProjectData();
      let statuses = [];

      const colors = {
        1: '#999999',
        2: '#FF9A00',
        3: '#4484CD',
        4: '#8BC34A',
      };

      if (activeProjectData.is_jira) {
        const jiraStatus = this.$store.getters['columns/getJiraColumnByNameAndProjectId']('status', this.ganttId);

        statuses = jiraStatus.options.map(item => ({
          id: item.id,
          color: item.color,
          name: item.name || item.value,
        }));
      } else {
        statuses = Object.entries(constants.TASK_STATUSES).map(([key, value]) => ({
          id: +key,
          color: colors[key],
          name: __(value.locale),
        }));
      }

      return statuses;
    },
    allTasks() {
      return this.$store.getters['tasksModel/getTasksForGantt'](this.ganttId).data;
    },
    tasks() {
      return this.allTasks.filter(task => task.type === constants.TASK_TYPES.task);
    },
    milestones() {
      return this.allTasks.filter(task => task.type === constants.TASK_TYPES.milestone);
    },
    tasksAndMilestones() {
      return this.allTasks.filter(task => task.type === constants.TASK_TYPES.task || task.type === constants.TASK_TYPES.milestone);
    },
    totalEstimate() {
      return this.allTasks.find(task => task.type === constants.TASK_TYPES.project && !task.parent);
    },
    baselineAllTasks() {
      return this.baselineData?.tasks || [];
    },
    baselineTasksAndMilestones() {
      return this.baselineAllTasks.filter(task => task.type === constants.TASK_TYPES.task || task.type === constants.TASK_TYPES.milestone);
    },
    baselineTotalEstimate() {
      return this.baselineAllTasks.find(task => task.type === constants.TASK_TYPES.project && !task.parent);
    },
    currentTasksAndMilestones() {
      const tasks = this.overviewData.type_overview === constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE
        ? this.baselineTasksAndMilestones
        : this.tasksAndMilestones;
      const todayTasks = tasks.filter(task => !moment(task.end_date).isBefore() && !moment(task.start_date).isAfter());
      const prepareTodayTasks = todayTasks.map(task => ({
        id: task.id,
        start_date: new Date(task.start_date),
        end_date: new Date(),
      }));

      this.getTodayTasksDuration(prepareTodayTasks);

      return tasks;
    },
    dataChartProjectInfo() {
      const data = {
        description: '',
        owner: {},
        team: [],
        startDate: '',
        endDate: '',
        lastUpdate: '',
        progress: 0,
      };
      const projectOwnerRole = this.$store.getters['roles/getProjectRoles'].find(role => role.defaultType === 'OWNER');

      data.owner = this.resources.find(resource => resource.resourceProjects.find(settings => settings.projectId === this.ganttId && settings.projectRoleId === projectOwnerRole.id)) || {};
      data.team = this.resources;
      data.description = this.projectData.description ? this.projectData.description.trim() : '';
      data.lastUpdate = this.projectData.last_update ? dateHelper.formatDateNoTime(this.projectData.last_update) : '';

      if (this.totalEstimate) {
        data.startDate = this.totalEstimate.start_date ? dateHelper.formatDateNoTime(this.totalEstimate.start_date) : '';
        data.endDate = this.totalEstimate.end_date ? dateHelper.formatDateNoTime(this.totalEstimate.end_date) : '';
        data.progress = this.totalEstimate.progress ? Math.round(this.totalEstimate.progress * 100) : 0;
      }

      return data;
    },
    dataChartStatusTasks() {
      const data = {
        items: [],
        summary: {
          total: {
            text: this.locales('overview_total_tasks'),
            value: 0,
          },
          unassigned: {
            text: this.locales('overview_unassigned_tasks'),
            value: 0,
          },
          overdue: {
            text: this.locales('overview_overdue_tasks'),
            value: 0,
          },
        },
      };

      if (!this.tasks.length) return data;

      data.items = this.taskStatuses.map(status => {
        const statusTasks = this.tasks.filter(task => +task.status === status.id);
        const qty = statusTasks.length;
        const value = Math.round((qty / this.tasks.length) * 100);

        return { ...status, qty, value };
      });

      data.summary.total.value = this.tasks.length;
      data.summary.unassigned.value = this.tasks.filter(task => !task.resources.length).length;
      data.summary.overdue.value = this.tasks.filter(task => task.progress < 1 && moment(task.end_date).isBefore()).length;

      return data;
    },
    dataChartTimeTasks() {
      const data = {
        mode: '',
        labels: [
          __('overview_total_logged'),
          __('overview_total_estimated'),
          __('overview_variance'),
        ],
        items: [0, 0, 0],
      };

      data.mode = this.overviewData.type_overview;

      if (data.mode !== constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED && !this.baselineData?.tasks) {
        return data;
      }

      const totalTask = this.allTasks.find(task => task.type === 'project' && task.parent === 0);

      const totalLogged = this.updateTimeLog && this.tasksAndMilestones.reduce((acc, task) => {
        const logsData = timeTrackingModel.getLogsByParams(task.gantt_id, task.id);
        const time = logsData ? +(logsData.sum / 60).toFixed(2) : 0;

        return acc + time;
      }, 0);

      const totalEstimated = data.mode === constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE
        ? estimationModule.helpers.getEstimationForTask(totalTask, true)
        : estimationModule.helpers.getEstimationForTask(totalTask, false);

      const text = timeParser.output(totalEstimated, {
        durationData: gantt.config.durationData,
        prop: 'estimation',
      });

      const values = [];

      text.split(' ').forEach(i => {
        const num = parseFloat(i);

        values.push(num);
      });

      const totalEstimateValue = values.join('.');
      const variance = totalEstimateValue - totalLogged;

      data.items = [totalLogged, totalEstimateValue, variance];
      data.chartType = 'time';

      return data;
    },
    dataChartMilestones() {
      const data = {
        items: [],
        total: 0,
        overdue: 0,
        milestones: [],
      };

      if (!this.milestones.length) return data;

      data.items = this.taskStatuses.map(status => {
        const statusMilestones = this.milestones.filter(milestone => +milestone.status === status.id);
        const qty = statusMilestones.length;
        const value = Math.round((qty / this.milestones.length) * 100);

        return { ...status, qty, value };
      });

      data.total = this.milestones.length;
      data.overdue = this.milestones.filter(milestone => milestone.progress < 1 && moment(milestone.start_date).isBefore()).length;
      data.milestones = this.milestones.map(milestone => {
        const status = this.taskStatuses.find(s => s.id === +milestone.status);
        const statusColor = status?.color;
        const statusName = status?.name;
        const formatDate = dateHelper.formatDateNoTime(milestone.start_date);

        return {
          ...milestone,
          statusColor,
          statusName,
          formatDate,
        };
      }).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

      return data;
    },
    dataChartSchedule() {
      const data = {
        mode: '',
        items: [0, 0, 0],
      };

      data.mode = this.overviewData.type_overview;

      if (this.waitTodayTasksDuration) {
        return data;
      }

      if (data.mode !== constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED && !this.baselineData?.tasks) {
        return data;
      }

      const projectConfig = projectsModel.getProjectConfig(this.ganttId);
      const isProgressTypeDuration = projectConfig.progress === 'duration';
      const plannedTotalDuration = this.currentTasksAndMilestones.reduce((sum, task) => sum + task.duration, 0) || 1;
      const plannedTasksCount = this.currentTasksAndMilestones.length || 1;
      const plannedDuration = this.currentTasksAndMilestones.reduce((sum, task) => {
        let progress;

        if (moment(task.end_date).isBefore()) {
          progress = 1;
        } else if (moment(task.start_date).isAfter()) {
          progress = 0;
        } else {
          const duration = this.todayTasksDuration[task.id] || 0;

          progress = +(duration / task.duration).toFixed(2);
        }

        if (isProgressTypeDuration) {
          return sum + progress * task.duration;
        }

        return sum + progress;
      }, 0);
      const _plannedProgress = isProgressTypeDuration
        ? plannedDuration / plannedTotalDuration
        : plannedDuration / plannedTasksCount;
      const plannedProgress = Math.round(_plannedProgress * 100);

      const actualProgress = this.totalEstimate ? Math.round(this.totalEstimate.progress * 100) : 0;

      const variance = actualProgress - plannedProgress;

      data.items = [plannedProgress, actualProgress, variance];

      return data;
    },
    dataChartCost() {
      const data = {
        mode: '',
        labels: [
          __('overview_total_actual'),
          __('overview_total_planned'),
          __('overview_variance'),
        ],
        items: [0, 0, 0],
      };

      data.mode = this.overviewData.type_overview;

      if (data.mode !== constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED && !this.baselineData?.tasks) {
        return data;
      }

      const totalActual = this.updateTimeLog && +estimationModule.helpers.getActualCostValueForTask(this.totalEstimate, true);

      const totalPlanned = data.mode === constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE
        ? +estimationModule.helpers.getPriceForTask(this.baselineTotalEstimate, true, this.baselineData, true)
        : +estimationModule.helpers.getPriceForTask(this.totalEstimate, false, null, true);

      const variance = totalPlanned - totalActual;

      data.items = [totalActual, totalPlanned, variance];
      data.chartType = 'cost';

      return data;
    },
  },
  watch: {
    ganttId() {
      this.getProjectData();
    },
  },
  created() {
    moduleGanttDateWorker.init();

    this.listeners.push(app.on('projectModel:updateProject', ganttId => {
      if (ganttId === this.ganttId) {
        this.getProjectData();
      }
    }));

    this.listeners.push(app.on('timeTrackingModel:change', id => {
      if (this.tasks.some(t => t.id === id)) this.updateTimeLog++;
    }));
  },
  beforeDestroy() {
    this.listeners.forEach(id => app.off(id));
  },
  mounted() {
    this.getProjectData();
  },
  methods: {
    getProjectData() {
      const { description, last_update } = projectsModel.getProjectDataById(this.ganttId);

      this.projectData = {
        description,
        last_update,
      };
    },
    getChartComponent(key) {
      if (this.chartsMap[key]?.rights && !rights.project.hasRight(this.ganttId, this.chartsMap[key].rights)) {
        return null;
      }
      if (this.chartsMap[key]?.features && !checkPricingAccess(this.chartsMap[key].features)) {
        return null;
      }

      return this.chartsMap[key]?.component || null;
    },
    getChartData(key) {
      const name = this.chartsMap[key]?.data;

      return name ? this[name] : null;
    },
    getTodayTasksDuration(tasks) {
      this.waitTodayTasksDuration = true;
      moduleGanttDateWorker.calculateDuration(this.ganttId, tasks).then(data => {
        this.todayTasksDuration = data;
        this.waitTodayTasksDuration = false;
      });
    },
    openDescriptionModal(e) {
      if (e.target?.tagName !== 'A') {
        this.isShowModal = true;
      }
    },
    async saveDescription(description) {
      this.isShowModal = false;

      const { data } = await internalApi.put(`/projects/${this.ganttId}`, {
        description,
      });

      if (data.status === 'ok') {
        this.projectData.description = description;

        const projectData = projectsModel.getProjectDataById(this.ganttId);

        projectData.description = description;

        projectsModel.manualUpdate(projectData);
      }
    },
  },
};
</script>

<style module="$" src="../less/overview.less"></style>
