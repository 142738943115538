import MaskParser from '../libs/bitwiseMask';
import plansModel from '../models/plans';

const Mask = new MaskParser();

const _getAccessToFeature = featureName => (plansModel.isTrial()
  ? true
  : Mask.checkPermission(user.accountPlanData.mask, user.paymentFeatures[featureName].id));

export function getPlans(feature) {
  const plan = user.paymentPlans && user.paymentPlans.find(item => {
    if (Mask.checkPermission(item.mask, user.paymentFeatures[feature].id)) {
      return true;
    }
  });

  return plan ? plan.name : '';
}

export function getPlansObj(feature) {
  const plan = user.paymentPlans && user.paymentPlans.find(item => {
    if (Mask.checkPermission(item.mask, user.paymentFeatures[feature].id)) {
      return true;
    }
  });

  return plan && plan;
}

export function checkPricingAccess(n) {
  return user.paymentFeatures[n]
    ? _getAccessToFeature(user.paymentFeatures[n].name)
    : true;
}

function _disableClick(e) {
  e.stopImmediatePropagation();
}

export function addPricingTooltipToWebixElement(...args) {
  // just to implement function overload START
  let element;
  let feature;
  let position;
  let cssClass;
  let selectorToAppendLabel;
  let tooltipCssClass;
  let disableClick;

  if (args.length > 2) {
    [element, feature, position, cssClass, selectorToAppendLabel = 'button', tooltipCssClass, disableClick] = args;
  } else if (typeof args[1] === 'string') {
    [element, feature] = args;
  } else {
    [element, {
      feature, position, cssClass, selectorToAppendLabel = 'button', tooltipCssClass, disableClick,
    }] = args;
  }
  // just to implement function overload END

  let elementNode;

  if (typeof element === 'string') {
    const wbxElement = $$(element);

    if (!wbxElement.isVisible()) return true;
    elementNode = wbxElement.getNode();
  }

  if (element instanceof HTMLDivElement) elementNode = element;

  if (!elementNode) return true;

  if (disableClick) elementNode.addEventListener('click', _disableClick);

  const classList = elementNode.classList;

  classList.add('pricing-tooltip');
  elementNode.removeAttribute('title');

  if (cssClass) classList.add(cssClass);

  elementNode.dataset.feature = feature;
  elementNode.dataset.position = position;

  if (tooltipCssClass) elementNode.dataset.class = tooltipCssClass;
  const button = elementNode.querySelector(selectorToAppendLabel);

  if (button.querySelector('.pricing-title-container')) return;

  const pricingTitle = document.createElement('div');

  pricingTitle.className = 'pricing-title-container';

  if (feature === 'workload_estimation_duration' || feature === 'workload_estimation') feature = 'workload';
  const plan_title = `${getPlans(feature)}_plan_locale`;

  pricingTitle.innerHTML = `${__(plan_title)}`;

  button.appendChild(pricingTitle);
}

export function getAccessToDefaultColumns(colArray) {
  const arr = [];

  colArray.forEach(column => {
    if (checkPricingAccess(column.property)) {
      return arr.push(column.property);
    }
  });

  return arr;
}
export default {
  getPlans,
  checkPricingAccess,
  addPricingTooltipToWebixElement,
  getAccessToDefaultColumns,
};
