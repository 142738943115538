import { assign, isEqual } from 'lodash';
import Vue from 'vue';
import globalStore from '$$store/main';
import app from '../../../app';
import constants from '$$helpers/constants';
import { closeTask } from '../helpers';
import bindStatusAndProgressHelper from '$$helpers/bindStatusAndProgress';
import pricingHelper from '../../../helpers/pricingHelper';
import rights from '../../../components/rights';
import moduleWbsWorker from '../../gantt/modules/wbsWorker';
import linksModule from '../../gantt/modules/link';
import routerHelper from '$$helpers/router';

// Task properties need additional update requests
const SPECIAL_TASK_PROPERTIES = ['status', 'progress', 'priority', 'duration', 'estimation'];

const PROPERTIES_TO_UPDATE_BY_WORKER = ['start_date', 'end_date', 'duration', 'progress', 'status'];

const TaskSettingsController = {

  appEventsListeners: [],

  getUpdateMethodByProperty(property) {
    const UPDATE_PROPERTY_METHODS_MAP = {
      type: this._updateType.bind(this),
      start_date: this._updateStartDate.bind(this),
      end_date: this._updateEndDate.bind(this),
      duration: this._updateDuration.bind(this),
      estimation: this._updateEstimation.bind(this),
      deadline: this._updateDeadline.bind(this),
      note: this._updateDescription.bind(this),
      priority: this._updatePriority.bind(this),
      status: this._updateStatus.bind(this),
      progress: this._updateProgress.bind(this),
      text: this._updateTaskName.bind(this),
      color: this._updateColor.bind(this),
    };

    if (!UPDATE_PROPERTY_METHODS_MAP[property]) {
      throw Error(`Update method does not exist for property ${property}`);
    }

    return UPDATE_PROPERTY_METHODS_MAP[property];
  },

  initWbsWorker() {
    moduleWbsWorker.init();
  },

  getTasksWbsCodes(ganttIds) {
    return moduleWbsWorker.calculate(ganttIds);
  },

  setTaskData(taskData) {
    this.taskData = { ...taskData };
  },

  setCustomFields(columns, values) {
    this.customFieldsColumns = columns;
    this.customFieldsValues = values;
  },

  async isValidPropertyData({ property, payload }) {
    if (property === 'start_date') {
      return this._isValidStartDate(payload);
    }

    return true;
  },

  async _isValidStartDate(value) {
    if (gantt.isTaskExists(this.taskData.id)) {
      return gantt.canChangeStartDate(value, gantt.getTask(this.taskData.id));
    }

    return true;
  },

  async updateTaskDataPartially({ property, payload }) {
    const isValidData = await this.isValidPropertyData({ property, payload });

    if (!isValidData) {
      return;
    }

    let needFullTaskUpdate = true;
    const needUpdateByWorker = PROPERTIES_TO_UPDATE_BY_WORKER.some(item => item === property);
    const oldTaskData = globalStore.getters['tasksModel/tasksByIds'][this.taskData.id];

    if (this.getUpdateMethodByProperty(property)) {
      await this.getUpdateMethodByProperty(property)(payload);
    }

    // task data at the time of update operation
    // this.taskData can be different if switching fast between tasks when update in progress
    const taskDataToUpdate = { ...this.taskData };

    if (property === 'priority') needFullTaskUpdate = false;

    if (needUpdateByWorker) {
      await gantt.ganttWorker.calculate([taskDataToUpdate], 'changeTasks', 'updateTasks');
    } else {
      await this.refreshTask(taskDataToUpdate);
      needFullTaskUpdate && await this.updateTask(taskDataToUpdate);
      gantt.callEvent('taskEditor:task:changed', [taskDataToUpdate]);
    }

    if (SPECIAL_TASK_PROPERTIES.includes(property)) {
      switch (property) {
      case 'status':
        await globalStore.dispatch('tasksModel/changeStatus', {
          statusValue: taskDataToUpdate.status,
          taskData: taskDataToUpdate,
        });
        break;
      case 'progress':
        await globalStore.dispatch('tasksModel/changeStatus', {
          statusValue: taskDataToUpdate.status,
          taskData: taskDataToUpdate,
        });
        break;
      case 'priority':
        await globalStore.dispatch('tasksModel/changePriority', {
          priorityValue: taskDataToUpdate.priority,
          taskData: taskDataToUpdate,
        });
        break;
      case 'duration':
        app.trigger('changedTaskDuration', taskDataToUpdate, oldTaskData);
        break;
      case 'estimation':
        app.trigger('changedTaskEstimation', taskDataToUpdate, true);
        break;
      }

      gantt.callEvent('taskEditor:task:changed', [taskDataToUpdate]);
    }
  },

  updateCustomField(column, value) {
    const columnInModel = globalStore.getters['columns/getCustomColumnById'](column.id);

    if (!columnInModel) {
      webix.message({ type: 'warning', text: __('task_settings_custom_column_deleted') });

      return;
    }
    
    //Vue.set(this.customFieldsValues, column.id, value);
    const type = Object.entries(constants.CUSTOM_COLUMNS_TYPES).find(([_, val]) => val.id === column.type)?.[0];

    userExtAnalytics.log('task_settings_action', { action: 'custom_field', type });
    gantt.callEvent('onChangeUserCustomValue', [this.taskData, column.id, value]);
  },

  async updateDependencyLag(dependency, value) {
    const linkData = globalStore.getters['tasksModel/getLinkByGanttId'](this.taskData.gantt_id, dependency.linkId);
    const startDate = linksModule.helpers.getStartAccordingToType(linkData);
    const calcDate = gantt.calculateToDuration(startDate, value, gantt.config.duration_unit);

    linkData.lag = linksModule.helpers.customCalculateDuration(startDate, calcDate.endDate);
    const data = await globalStore.dispatch('tasksModel/backgroundUpdateLink', linkData);

    if (!routerHelper.isListViewRoute()) {
      gantt.callEvent('onAfterLinkUpdate', [data.id, data]);
    }
  },

  _onAfterCollaborationHandler(collaborationData) {

    if (collaborationData.event === 'TaskDeleted') {
      return this._onTaskDeleted(collaborationData);
    }

    if (collaborationData.event === 'TaskUpdated') {
      // sync controller taskData with store
      if (collaborationData.tasks?.includes(this.taskData.id)) {
        this.taskData = globalStore.getters['tasksModel/getTask'](this.taskData.id);
      }
      this._onTaskUpdated(collaborationData);
    }

    if (['ProjectDeleted', 'ProjectArchived'].includes(collaborationData.event)) {
      this._onProjectDeleted(collaborationData.projects);
    }

    if (collaborationData.event === 'TaskCustomFieldUpdated') {
      this._onTaskCustomFieldUpdated(collaborationData);
    }

    if (collaborationData.event === 'CustomFieldDeleted') {
      this._onTaskCustomFieldDeleted(collaborationData.customFields);
    }

    if (collaborationData.event === 'CustomFieldFromProjectUnassigned') {
      this._onCustomFieldFromProjectUnassigned(collaborationData.unassignedCustomFields);
    }

    if (collaborationData.event === 'CustomFieldToProjectAssigned') {
      this._onCustomFieldToProjectAssigned(collaborationData.assignedCustomFields);
    }

    if (collaborationData.event === 'TaskResourceUnassigned') {
      this._onTaskResourceUnassigned(collaborationData.unassignedResources);
    }
  },

  addAppEventsListeners() {
    this.removeAppEventsListeners();
    const closeTaskEvents = ['reloadModal:show', 'closeWindowOnEscKeyPressed', 'taskView:close'];

    closeTaskEvents.forEach(eventName => addAppEvent(eventName, closeTask, this));
    addAppEvent('project:archive', this._onAppProjectArchive, this);
    addAppEvent('onAfterCollaboration', this._onAfterCollaborationHandler, this);

    function addAppEvent(eventName, eventHandler, context) {
      const e_id = app.on(eventName, eventHandler.bind(context));

      context.appEventsListeners.push(e_id);
    }
  },

  removeAppEventsListeners() {
    this.appEventsListeners.forEach(id => app.off(id));
    this.appEventsListeners = [];
  },

  _onTaskResourceUnassigned(unassignedResources) {
    if (!rights.project.hasRight(this.taskData.gantt_id, 'all_tasks')) {
      const realResource = globalStore.getters['resourcesModel/getResourceByUserId'](window.user.id);
      const currentTaskUnassignedResources = unassignedResources.find(item => item.taskId === this.taskData.id);
      const isUnassignedFromCurrentTask = currentTaskUnassignedResources?.unassignedResourcesIds.includes(realResource.id);
      let isCurrentTaskIsParentOfUnassignedTask = false;
      let isAnyChildrenTaskWithCurrentUserResourceLeft = false;

      if (this.taskData.type === constants.TASK_TYPES.project) {
        isCurrentTaskIsParentOfUnassignedTask = unassignedResources.some(unassignedResource => (
          gantt.isChildOf(unassignedResource.taskId, this.taskData.id)
          && unassignedResource.unassignedResourcesIds.includes(realResource.id)
        ));
        const taskChildren = globalStore.getters['tasksModel/getAllChildByTask'](this.taskData.gantt_id, this.taskData.id);
        // Checking if the current parent task still has children where the current resource is assigned
        isAnyChildrenTaskWithCurrentUserResourceLeft = taskChildren.some(task => (
          task.resources.find(resource => resource.resource_id === realResource.id)
        ));
      }

      if (isUnassignedFromCurrentTask || (isCurrentTaskIsParentOfUnassignedTask && !isAnyChildrenTaskWithCurrentUserResourceLeft)) {
        closeTask();
      }
    }
  },

  _onTaskUpdated(collaborationData) {
    if (!collaborationData.tasks.includes(this.taskData.id)) return;

    if (!this._isUserHasRightsAccessToCurrentTask()) {
      return closeTask();
    }
  },

  _isUserHasRightsAccessToCurrentTask() {
    if (rights.project.hasRight(this.taskData.gantt_id, 'all_tasks')) return true;

    return globalStore.getters['tasksModel/checkAccessToTask'](this.taskData.id, this.taskData.gantt_id);
  },

  _onTaskDeleted(collaborationData) {
    const isCurrentProject = collaborationData.projects?.includes(this.taskData.gantt_id);
    const isCurrentTask = collaborationData.tasks?.includes(this.taskData.id);

    if (isCurrentProject && isCurrentTask) {
      closeTask();
    }
  },

  _onProjectDeleted(projects) {
    if (projects.includes(this.taskData.gantt_id)) {
      return closeTask();
    }
  },

  _onTaskCustomFieldUpdated(collaborationData) {
    // if (!collaborationData.tasks.includes(this.taskData.id)) return;
    // collaborationData.customFields.forEach(field => {
    //   this.customFieldsValues[field.customFieldId] = Array.isArray(field.value) ? field.value.join(',') : field.value;
    // });
  },

  _onTaskCustomFieldDeleted(customFieldsIds) {
    // customFieldsIds.forEach(id => {
    //   const columnIndex = this.customFieldsColumns.findIndex(column => column.id === id);

    //   delete this.customFieldsValues[id];
    //   this.customFieldsColumns.splice(columnIndex, 1);
    // });
  },

  _onCustomFieldFromProjectUnassigned(unassignedCustomFields) {
    const customFieldsIds = unassignedCustomFields.map(field => field.customFieldId);

    this._onTaskCustomFieldDeleted(customFieldsIds);
  },

  _onCustomFieldToProjectAssigned(assignedCustomFields) {
    // if (!pricingHelper.checkPricingAccess('custom_fields')) {
    //   return;
    // }

    // assignedCustomFields.forEach(field => {
    //   const assignedColumn = globalStore.getters['columns/getCustomColumnById'](field.customFieldId);

    //   if (assignedColumn) {
    //     const fieldValue = gantt.getUserCustomValue(this.taskData, assignedColumn.id).value;

    //     this.customFieldsColumns.push(assignedColumn);
    //     Vue.set(this.customFieldsValues, assignedColumn.id, fieldValue);
    //   }
    // });
  },

  _onAppProjectArchive(projectData) {
    if (projectData.gantt_id === this.taskData.gantt_id) {
      closeTask();
    }
  },

  _updateTaskName(value) {
    userExtAnalytics.log('task_settings_action', { action: 'name' });
    this.taskData.text = value.slice(0, 254);
  },

  _updateColor(value) {
    this.taskData.color = value;
    userExtAnalytics.log('task_settings_action', { action: 'color' });
  },

  _updatePriority(value) {
    this.taskData.priority = value;
    userExtAnalytics.log('task_settings_action', { action: 'priority' });
  },

  _updateStatus(value) {
    this.taskData.status = value;
    this.taskData.progress = bindStatusAndProgressHelper.calc(value, null).progress;
    userExtAnalytics.log('task_settings_action', { action: 'status' });
  },

  _updateProgress(value) {
    let newValue = value;

    if (value > 1) {
      newValue = 1;
    }

    this.taskData.progress = newValue;
    this.taskData.status = bindStatusAndProgressHelper.calc(null, newValue * 100).status;

    let type = '';

    switch (newValue) {
    case 0: type = 'open'; break;
    case 1: type = 'done'; break;
    default: type = 'in progress';
    }
    userExtAnalytics.log('task_status_changed', { type });
    userExtAnalytics.log('task_settings_action', { action: 'progress' });
  },

  _updateType(value) {
    this.taskData.type = value;

    if (this.taskData.type === gantt.config.types.milestone) {
      this.taskData.duration = 0;
      this.taskData.color = constants.DEFAULT_TYPE_COLORS.MILESTONE;
    } else {
      this.taskData.duration = 60;
      this.taskData.color = constants.DEFAULT_TYPE_COLORS.TASK;
    }

    this.taskData.estimation = 0;
    this.taskData.end_date = gantt.calculateEndDate({
      start_date: this.taskData.start_date,
      duration: this.taskData.duration,
      task: this.taskData,
    });

    userExtAnalytics.log('task_settings_action', { action: 'type' });
  },

  async _updateStartDate(value) {
    const oldValue = this.taskData.start_date;

    this.taskData.start_date = value;
    this.taskData.end_date = gantt.calculateEndDate({
      start_date: this.taskData.start_date,
      duration: this.taskData.duration,
      task: this.taskData,
    });

    this.taskData.constraint_type = gantt.config.constraint_types.ASAP;
    this.taskData.constraint_date = null;

    if (gantt.isTaskExists(this.taskData.id)) {
      if (this.taskData.type === constants.TASK_TYPES.project) {
        await gantt.moveProjectHandler(this.taskData.id, gantt.getTask(this.taskData.id), this.taskData.start_date);
      }
      gantt.callEvent('onInlineEdit', [this.taskData.id, 'start_date', value, oldValue]);
    }

    userExtAnalytics.log('task_settings_action', { action: 'start_date' });
  },

  _updateEndDate(value) {
    const prevDuration = this.taskData.duration;

    this.taskData.end_date = value;
    this.taskData.duration = gantt.calculateDuration(this.taskData);

    if (this.taskData.duration < 1) {
      this.taskData.duration = prevDuration;
      this.taskData.end_date = gantt.calculateEndDate({
        start_date: this.taskData.start_date,
        duration: this.taskData.duration,
        task: this.taskData,
      });
    }

    userExtAnalytics.log('task_settings_action', { action: 'end_date' });

    app.trigger(
      'changedTaskDuration',
      this.taskData,
      globalStore.getters['tasksModel/getTaskByGanttId'](this.taskData.gantt_id, this.taskData.id),
    );
  },

  _updateDuration(value) {
    if (+this.taskData.duration === +value) {
      return;
    }

    this.taskData.duration = +value;

    if (!this.taskData.duration || this.taskData.duration < 1) {
      this.taskData.duration = 1;
    }

    this.taskData.end_date = gantt.calculateEndDate({
      start_date: this.taskData.start_date,
      duration: this.taskData.duration,
      task: this.taskData,
    });

    userExtAnalytics.log('task_settings_action', { action: 'duration' });

    gantt.config.auto_scheduling && gantt.autoSchedule();
  },

  _updateEstimation(value) {
    if (+this.taskData.estimation === +value) {
      return;
    }

    this.taskData.estimation = +value;

    userExtAnalytics.log('task_settings_action', { action: 'estimation' });
  },

  _updateDeadline({ value, updateType }) {
    this.taskData.deadline = value;
    userExtAnalytics.log('task_settings_action', { action: 'deadline', type: updateType });
  },

  _updateDescription({ newValue }) {
    this.taskData.note = newValue;
  },

  async updateTask(taskData) {
    await globalStore.dispatch('tasksModel/backgroundUpdateTask', { taskData });
    this.refreshParents(taskData);
  },

  refreshParents(task) {
    let parent = gantt.isTaskExists(task.parent) && gantt.getTask(task.parent);

    while (parent) {
      gantt.refreshTask(parent.id);
      parent = gantt.isTaskExists(parent.parent) && gantt.getTask(parent.parent);
    }
  },

  async refreshTask(taskData) {
    if (!gantt.isTaskExists(taskData.id)) return;

    const taskInGantt = gantt.getTask(taskData.id);

    if (taskInGantt.type === gantt.config.types.project && !isEqual(taskData.start_date, taskInGantt.start_date)) {
      await gantt.moveProjectHandler(taskInGantt.id, taskInGantt, taskData.start_date);
    }

    assign(taskInGantt, taskData);
    gantt.refreshTask(taskData.id);
  },

};

export default TaskSettingsController;
