import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';
import projectsModel from '../../../models/projects';
import customDaysTemplatesModel from '../../../models/customDayTemplates';
import userProjectConfigModel from '../../../models/userProjectConfigs';
import timeTracking from '../../../models/timeTracking';
import helpers from '../helpers';
import globalStore from "$$store/main";

const getProjectDataFromServer = function (ganttId) {
  return webix.ajax()
    .get(`/api/collaboration/${ganttId}?${Date.now()}`);
};

const showCollaborationMessage = function (user) {
  helpers.notify({
    userPic: user.photo,
    locale: 'history_event_restored',
    data: {},
  });
};

const freezeOpenCloseState = function (ganttId, taskData) {
  const currentTasks = globalStore.getters['tasksModel/getItem'](ganttId)?.tasks;
  const data = taskData.tasks.data;

  _.each(data, nTask => {
    let compareTask = null;

    if (nTask.type === gantt.config.types.project) {
      compareTask = _.find(currentTasks, t => +t.id === +nTask.id);
    }

    if (compareTask && nTask.type === compareTask.type) {
      nTask.open = compareTask.open;
    }
  });

  return taskData;
};

const manualUpdateModels = function (projectData, responseData, ignoreUpdate, runAutoScheduling) {
  if (responseData.taskData) {
    globalStore.dispatch('tasksModel/updateProjectTasksData', freezeOpenCloseState(projectData.gantt_id, responseData.taskData));
  }

  if (responseData.customDays) {
    customDaysTemplatesModel.manualAddTemplateToShareProject(responseData.customDays, projectData.gantt_id, 'collaboration');
  }

  if (responseData.resources) {
    globalStore.dispatch('resourcesModel/replaceAllData', responseData.resources);
  }

  if (responseData.projectData) {
    projectData.config = responseData.projectData.config;
    projectData.name = responseData.projectData.name;
    projectData.last_update = responseData.projectData.last_update;
    projectData.config = userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(projectData.gantt_id, projectData.config);
  }

  if (responseData.globalCustomColumnsData) {
    globalStore.commit('columns/setCustomColumns', responseData.globalCustomColumnsData);
  }

  if (responseData.userCustomColumnsData) {
    globalStore.commit('columns/updateProject', {
      projectId: projectData.gantt_id,
      updated: { ...responseData.userCustomColumnsData },
    });
    app.trigger('userCustomColumnsModel:change', projectData.gantt_id, 'update');
  }

  if (responseData.timeTrackingData) {
    timeTracking.manualUpdateItem(responseData.timeTrackingData, projectData.gantt_id, 'collaboration');
  }

  projectsModel.manualLiveUpdate(projectData, ignoreUpdate, runAutoScheduling);
};

const hardRefreshGantt = function () {
  gantt.blockEvents();
  app.trigger('gantt:reinit');
  gantt.unblockEvents();
};

const hardRefresh = (event, ganttId) => getProjectDataFromServer(ganttId).then(rawResponseData => {
  const projectData = projectsModel.getItem(ganttId);

  if (!projectData) {
    return;
  }

  const ganttIDs = ganttViewModel.getGanttIDs();
  const responseData = rawResponseData.json();
  const isSameProjectOpen = !gantt.is_workload_mode && _.includes(ganttIDs, +ganttId);

  gantt.config.updateWithoutChangeFocus = false;

  manualUpdateModels(projectData, responseData, false, false);

  gantt.is_workload_mode && app.trigger('change:work:time', true, +ganttId);

  app.trigger('collaboration:updatedModels');

  if (isSameProjectOpen) {
    showCollaborationMessage(event.innerSettings.user);

    hardRefreshGantt();
    app.trigger('change:work:time', true, +ganttId);
    app.trigger('settings:days:collaboration', +ganttId);
  }
});

async function onHistoryRestored(event) {
  await hardRefresh(event, +event.data.data.projectId);

  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: [+event.data.data.projectId], 
  });
}

export default onHistoryRestored;
