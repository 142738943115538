<template>
  <div
    :class="[
      $.chart,
      $.chart_bar_vertical,
    ]"
  >
    <div :class="$.header">
      <div :class="$.title">
        {{ locales(`chart_${chart.key}_title`) }}
      </div>
      <div
        v-if="data.mode && !statusSwitch"
        :class="$.mode"
      >
        {{ locales(`overview_type_${data.mode}`) }}
      </div>
      <vgp-switch
        v-if="statusSwitch"
        :is-toggle-on="chart.status"
        @onChange="$emit('onToggleStatus')"
      />
    </div>
    <div
      v-if="description"
      :class="$.description"
    >
      {{ locales(`chart_${chart.key}_description`) }}
    </div>
    <div :class="$.wrapper">
      <div
        ref="chart"
        :class="$.body"
      />
      <div
        v-if="isShowStub"
        :class="$.stub"
      >
        <div :class="$.stub_item" />
        <div :class="$.stub_item" />
        <div :class="$.stub_item" />
        <div :class="$.stub_text">
          {{ locales(`chart_${chart.key}_stub_text`) }}
        </div>
      </div>
      <loader :show="isShowLoader" />
    </div>
  </div>
</template>

<script>
import * as echarts from '../../../libs/echarts';
import Loader from './Loader.vue';
import VgpSwitch from '../../common/VueComponents/checkbox/squareToggle.vue';

export default {
  components: {
    Loader,
    VgpSwitch,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    statusSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      instance: null,
      id: 'chart',
      timeout: null,
      isShowLoader: false,
      tooltipTitle: __(`chart_${this.chart.key}_tooltip_title`),
    };
  },
  computed: {
    isShowStub() {
      let isShowStub = true;

      if (this.data.chartType === 'cost' && this.data.items.some(value => value !== 0)) isShowStub = false;
      if (this.data.chartType === 'time') {
        this.data.items.forEach(i => {
          if (i !== '0.00') isShowStub = false;
        });
      }

      return !this.static && isShowStub;
    },
    items() {
      return this.data.items.map(item => {
        const value = item && typeof item === 'number' ? item.toFixed(2) : item;

        return {
          value,
          itemStyle: {
            borderRadius: item < 0 ? [0, 0, 4, 4] : [4, 4, 0, 0],
          },
          label: {
            position: item < 0 ? 'insideBottom' : 'insideTop',
          },
        };
      });
    },
    options() {
      return {
        grid: {
          left: '17%',
          right: '5%',
          top: '10%',
          bottom: '19%',
          containLabel: false,
        },
        tooltip: (this.static ? false : {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            z: 0,
            lineStyle: {
              type: 'solid',
              width: 58,
              color: '#D0E0F2',
            },
          },
          formatter: data => {
            const params = data.find(obj => obj.seriesName === this.id);
            const value = params?.value && typeof params.value === 'number' ? params.value.toFixed(2) : params.value;

            return `<div class="chart_tooltip">
              <div class="tooltip_title">${this.tooltipTitle}</div>
              <div class="tooltip_info">
                <div class="tooltip_name">${params.name}</div>
                <div class="tooltip_value">${value}</div>
              </div>
            </div>`;
          },
          borderColor: '#808080',
          borderWidth: 1,
          borderRadius: 4,
          padding: 8,
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);',
        }),
        xAxis: {
          type: 'category',
          data: this.data.labels,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            width: 110,
            overflow: 'break',
            color: '#191919',
            fontSize: 12,
            lineHeight: 14,
            fontFamily: 'Lato-Regular, sans-serif',
            margin: 24,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => this.format(value),
            color: '#191919',
            fontSize: 10,
            lineHeight: 12,
            fontFamily: 'Lato-Regular, sans-serif',
          },
        },
        series: [{
          name: this.id,
          type: 'bar',
          barWidth: 58,
          itemStyle: {
            color: '#1565C0',
            borderRadius: 4,
          },
          emphasis: {
            itemStyle: {
              color: '#4484CD',
            },
          },
          label: {
            show: true,
            position: 'inside',
            formatter: params => {
              const value = params?.value && typeof params.value === 'number' ? params.value.toFixed(2) : params.value;

              this.format(value);
            },
            color: '#FFFFFF',
            fontSize: 12,
            lineHeight: 14,
            width: 56,
            fontFamily: 'Lato-Bold, sans-serif',
            overflow: 'truncate',
          },
          labelLayout: params => ({
            fontSize: params.rect.height < 19 ? 0 : 12,
          }),
          data: this.items,
          animation: !this.static,
          silent: this.static,
        }],
      };
    },
  },
  watch: {
    data() {
      clearTimeout(this.timeout);
      this.isShowLoader = true;

      this.timeout = setTimeout(() => {
        this.isShowLoader = false;
        this.instance.setOption({
          series: [{
            name: this.id,
            data: this.items,
          }],
        });
      }, 1000);
    },
  },
  mounted() {
    this.instance = echarts.init(this.$refs.chart);
    this.instance.setOption(this.options);
  },
  beforeDestroy() {
    echarts.dispose(this.instance);
  },
  methods: {
    format(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style module="$" src="../less/chart.less"></style>
