var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dropCell", {
    attrs: {
      "suggest-height": _vm.dropHeight,
      "drop-width": 186,
      disabled: _vm.blockShowDrop,
      editable: _vm.editable,
    },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function (ref) {
          var isOpen = ref.isOpen
          return [
            _vm.selected
              ? _c(
                  "div",
                  {
                    staticClass: "selected-item-cell",
                    class: {
                      disabled: _vm.blockShowDrop,
                      crossout: _vm.crossout && !isOpen,
                      not_allowed: !_vm.editable,
                    },
                    style: { opacity: isOpen ? 1 : _vm.cellOpecity },
                  },
                  [
                    _vm.useCircleIcon
                      ? _c("div", {
                          staticClass: "circle-8",
                          style: { "background-color": _vm.selected.color },
                        })
                      : _c("div", {
                          staticClass: "priority-icon",
                          style: {
                            "background-image":
                              "url(" + _vm.selected.imgUrl + ")",
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "text_wrap",
                        class: {
                          "g-pl-8": _vm.property === "priority" && _vm.isJira,
                        },
                      },
                      [_vm._v("  " + _vm._s(_vm.selected.value) + " ")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "drop",
        fn: function () {
          return _vm._l(_vm.options, function (opt, index) {
            return _c(
              "div",
              {
                key: "d-i" + index,
                staticClass: "drop-item-template",
                class: { active: _vm.isActive(opt.id) },
                on: {
                  click: function ($event) {
                    return _vm.selectOption(opt)
                  },
                },
              },
              [
                _vm.useCircleIcon
                  ? _c("div", {
                      staticClass: "circle-8",
                      style: { "background-color": opt.color },
                    })
                  : _c("div", {
                      staticClass: "priority-icon",
                      style: { "background-image": "url(" + opt.imgUrl + ")" },
                    }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: {
                      "g-pl-8": _vm.property === "priority" && _vm.isJira,
                    },
                  },
                  [_vm._v("  " + _vm._s(opt.value) + " ")]
                ),
              ]
            )
          })
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }