import routerHelper from '../../helpers/router';
import customColumnOptions from './customColumnOptions';
import globalStore from '../../store/main';
import { actionTypes } from './dataProvider';
import rights from '../../components/rights';

export default class BulkPopup {
  static getConfig(project_id) {
    const bulk = new BulkPopup(project_id);

    return bulk.createConfig();
  }

  get isGanttMode() {
    const currentRoute = routerHelper.getCurrentRoute();
    const isProject = currentRoute.path.includes('project/');

    return isProject && currentRoute.params.mode === 'gantt';
  }

  get statuses() {
    return {
      property: 'status',
      label: __('filter_option_status_label'),
      placeholder: __('filter_task_status_any'),
      type: 'select',
      useSearch: false,
      multiselect: false,
      itemTemplate: 'status',
      actionType: actionTypes.STATUS,
      isOptions: true,
      options: [
        {
          id: '1',
          value: __('filter_status_open'),
          color: '#9b9b9b',
        },
        {
          id: '2',
          value: __('filter_status_in_progress'),
          color: '#ff9921',
        },
        {
          id: '3',
          value: __('filter_status_done'),
          color: '#55BED4',
        },
        {
          id: '4',
          value: __('filter_status_closed'),
          color: '#14bb4c',
        },
      ],
    };
  }

  get priority() {
    return {
      property: 'priority',
      label: __('filter_option_priority_label'),
      placeholder: __('filter_task_priority_any'),
      type: 'select',
      useSearch: false,
      multiselect: false,
      itemTemplate: 'withImg',
      actionType: actionTypes.PRIORITY,
      isOptions: true,
      options: [
        {
          id: '5',
          value: __('filter_priority_highest'),
          icon: 'https://cdn.ganttpro.com/app/imgs/highest.svg',
        },
        {
          id: '6',
          value: __('filter_priority_high'),
          icon: 'https://cdn.ganttpro.com/app/imgs/high.svg',
        },
        {
          id: '7',
          value: __('filter_priority_medium'),
          icon: 'https://cdn.ganttpro.com/app/imgs/medium.svg',
        },
        {
          id: '8',
          value: __('filter_priority_low'),
          icon: 'https://cdn.ganttpro.com/app/imgs/low.svg',
        },
        {
          id: '9',
          value: __('filter_priority_lowest'),
          icon: 'https://cdn.ganttpro.com/app/imgs/lowest.svg',
        },
      ],
    };
  }

  get resources() {
    const locale = {
      realResource: __('resources_layout_header_title_1'),
      virtualResource: __('resources_layout_header_title_2'),
    };
    const resources = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](this.project_id);
    const virtual = [];
    const real = [];

    resources.forEach(i => {
      const item = { ...i };

      item.icon = i.picture || icon_resource;
      item.value = i.name || i.value;
      item.group = i.userId ? locale.realResource : locale.virtualResource;

      if (i.userId) real.push(item);
      else virtual.push(item);
    });

    const notAssigned = {
      id: '-1',
      value: __('notAssigned'),
      icon: 'https://cdn.ganttpro.com/app/imgs/default/unassigned.svg',
    };

    return {
      property: 'assignee',
      label: __('filter_option_assignee_label'),
      placeholder: __('filter_task_assignee_any'),
      type: 'select',
      useSearch: true,
      multiselect: true,
      itemTemplate: 'user',
      actionType: actionTypes.ASSIGN,
      isOptions: true,
      onlyOneCanSelected: [notAssigned],
      options: [notAssigned, ...real, ...virtual],
    };
  }

  get color() {
    const options = globalStore.getters['teamSetting/colors'].data;

    return {
      property: 'color',
      label: __('app_masschange_window_input_customcolor'),
      placeholder: __('filter_task_color_any'),
      type: 'select-color',
      actionType: actionTypes.COLOR,
      isOptions: true,
      options,
    };
  }

  constructor(project_id) {
    this.project_id = project_id;
    this.config = [];
  }

  createConfig() {
    this.config.splice(0);
    this._createForDefaultCoFig();
    this._createForCustomConfig();
    this._filterConfigByRights();

    return this.config;
  }

  _createForDefaultCoFig() {
    this.config = [this.resources, this.statuses, this.priority];
    this.isGanttMode && this.config.push(this.color);
  }

  _createForCustomConfig() {
    if (!rights.project.hasRight(this.project_id, 'custom_field_edit')) {
      return;
    }

    const customColumns = customColumnOptions.getOptionsByGanttIds([this.project_id]);

    this.config.push(...customColumns);
  }

  _filterConfigByRights() {
    const blockItems = [];
    const staticFieldRight = rights.project.hasRight(this.project_id, 'static_fields');
    const staticField4Right = rights.project.hasRight(this.project_id, 'static_fields_4_only');

    if (!staticFieldRight) {
      blockItems.push(...[
        'assignee',
        'color',
        'priority',
      ]);
    }

    if (!staticField4Right && !staticFieldRight) {
      blockItems.push(...[
        'assignee',
        'color',
        'priority',
        'status',
      ]);
    }

    if (blockItems.length) {
      this.config = this.config.filter(o => !blockItems.includes(o.property));
    }
  }
}
