<template>
  <dropCell
    v-model="drop"
    :suggest-height="dropHeight"
    :drop-width="dropWidth"
    :disabled="disabled"
    :editable="editable"
    :manual-open="true"
  >
    <template #trigger="{isOpen}">
      <div
        ref="trigger"
        :class="[$style.cell_trigger,
                 drop && !disabled && editable ? $style.edit: '',
                 disabled ? $style.disabled_cell : '',
                 crossout && !isOpen ? $style.crossout :'',
                 !editable ? $style.not_allowed : '',
        ]"
        :style="{opacity: isOpen ? 1 : cellOpecity}"
        @click="triggerClick"
      >
        <template v-if="template === 'text' && selected">
          <span :class="[$style.text_wrap]">{{ selected.value }}</span>
        </template>
        <template v-if="template === 'color' && selected">
          <div
            :class="[$style.template__color_item, $style.auto]"
            :style="{'background-color': selected.color }"
          />
        </template>
      </div>
    </template>

    <template #drop>
      <div ref="drop">
        <div
          v-if="withSearch"
          :class="$style.search_input"
        >
          <div :class="$style.search" />
          <input
            ref="seach_input"
            v-model="txt_search"
            :placeholder="locale.search"
          >
        </div>
        <div
          ref="scrolled"
          v-scroll.self="onScroll"
          class="gantt_scroll"
          :class="[withSearch ? $style.scrolable_list :'', $style.scrollable ]"
        >
          <template v-if="template === 'color'">
            <div :class="[$style.template__color]">
              <div
                v-for="(option, index) in showItems"
                :key="componentKey +'-' +index + option.id"
                :class="[$style.template__color_item]"
                :style="{'background-color': option.color }"
                @click="selectOption(option)"
              />
            </div>
          </template>
          <template v-if="template !== 'color' && showItems.length">
            <div
              v-for="(option, index) in showItems"
              :key="componentKey +'-' +index + option.id"
              :class="[$style.item_template, isActive(option.id)? $style.active : '']"
              @click="selectOption(option)"
            >
              <template v-if="template === 'text'">
                <span :class="[$style.text_wrap]">{{ option.value }}</span>
              </template>
            </div>
          </template>
          <template v-if="template !== 'color' && !showItems.length">
            <div :class="[$style.no_data]">
              <span> {{ locale.empty_filter }} </span>
            </div>
          </template>
        </div>

        <div
          v-if="selected"
          :class="[$style.drop_footer, (!scrollBottom && withSearch) ? $style.border_top :'']"
          @click="clearSelected"
        >
          <span :class="[$style.text_action]">
            {{ locale.clear }}
          </span>
        </div>
      </div>
    </template>
  </dropCell>
</template>

<script>
import dropCell from '../../dropPopup.vue';

export default {
  name: 'SelectListCell',
  components: { dropCell },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    options: { type: Array, required: true },
    value: { type: [String, Number], required: true },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    template: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return ['text', 'color'].indexOf(value) !== -1;
      },
    },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      locale: {
        search: __('app_footer_btn_search_text'),
        clear: __('common_clear'),
        empty_filter: __('no_matches_message_text'),
      },
      txt_search: '',
      scrollBottom: false,
      drop: false,
      selected: '',
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },

    dropWidth() {
      return this.template === 'color' ? 194 : 223;
    },
    dropHeight() {
      const cleareHeight = 30;
      const itemHeight = 36;

      if (this.template === 'color') {
        return cleareHeight + 30 * Math.ceil(this.options.length / 6);
      }
      if (this.options.length < 6) {
        return cleareHeight + itemHeight * this.options.length;
      }

      return 250;
    },
    withSearch() {
      return this.options.length > 5 && this.template !== 'color';
    },
    componentKey() {
      const min = 1000; const
        max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
    showItems() {
      return this.options.filter(item => {
        const value = item.value.toLowerCase();

        if (value.includes(this.txt_search.toLowerCase())) {
          return item;
        }

        return false;
      });
    },
  },

  watch: {
    value(val) {
      this.selected = val || '';
    },
  },

  mounted() {
    this.selected = this.item[this.property] || '';

    document.addEventListener('click', this.documentClick);
    document.addEventListener('contextmenu', this.closeDrop);
  },

  methods: {
    selectOption(option) {
      if (this.selected.id === option.id) {
        this.closeDrop();

        return;
      }

      this.selected = option;
      this.closeDrop();
      this.$emit('change', option);
    },
    clearSelected() {
      this.selectOption('');
    },

    isActive(id) {
      return this.selected && this.selected.id === id;
    },

    onScroll() {
      const scrollHeight = this.$refs.scrolled.scrollHeight;
      const scrollTop = this.$refs.scrolled.scrollTop;
      const viewHeight = 36 * 5;

      if (scrollHeight - 2 <= (scrollTop + viewHeight)) {
        this.scrollBottom = true;
      } else {
        this.scrollBottom = false;
      }
    },
    triggerClick() {
      if (this.drop) this.closeDrop();
      else {
        this.drop = true;
        this.$nextTick(() => {
          this.$refs.seach_input && this.$refs.seach_input.focus();
        });
      }
    },
    closeDrop() {
      this.drop = false;
      this.txt_search = '';
    },
    documentClick(e) {
      const isTrigger = this.$refs.trigger ? this.$refs.trigger.contains(e.target) : false;
      const isDrop = this.$refs.drop ? this.$refs.drop.contains(e.target) : false;

      if (!isDrop && !isTrigger) {
        this.closeDrop();
      }
    },

  },
};
</script>

<style module src="../cell.less" lang="less">

</style>
