import app from "./../../app";
import teamModel from "./../../models/team";
import closeIcon from "./../../svg/close.svg";
import icon_trash from "./../../svg/webix_material/trash.svg";

const __ = window.__;
const blankLogoImg = GT.cdn + "/imgs/blank_logo.png";

var innerSettings = {
  logoClass: "upload-team-logo-popup-preview-logo-image",
  file: false
};

var innerViews = {
  main: {
    id: "uploadTeamLogoPopup"
  },
  controlLabel: {
    view: "label",
    css: 'upload-team-logo-popup-control-label',
    label: __('upload_team_logo_popup_header_filename_label'),
    on: {
      "onItemClick": function () {
        $$(innerViews.teamLogoUploader.id).fileDialog();
      }
    }
  },
  recomendationList: {
    borderless: true,
    css: "upload-team-logo-popup-body-template",
    template: "" +
    "<div class=\"upload-team-logo-popup-recommendation\">" +
    "<a class=\"upload-team-logo-popup-recommendation-list-head\">" +
    __('upload_team_logo_popup_header_recomendation_head') +
    "</a>" +
    "<ul class=\"upload-team-logo-popup-recommendation-list\">" +
    "<li class=\"upload-team-logo-popup-recommendation-list-item\">" +
    __('upload_team_logo_popup_header_recommendation_1') +
    "</li>" +
    "<li class=\"upload-team-logo-popup-recommendation-list-item\">" +
    __('upload_team_logo_popup_header_recommendation_2') +
    "</li>" +
    "<li class=\"upload-team-logo-popup-recommendation-list-item\">" +
    __('upload_team_logo_popup_header_recommendation_3') +
    "</li>" +
    "</ul>" +
    "</div>"
  },
  teamLogoUploader: {
    view: "uploader",
    id: "teamLogoUploader",
    width: 120,
    value: __("upload_team_logo_popup_uploader_button"),
    autosend: false,
    on: {
      "onBeforeFileAdd": function (file) {
        innerHandlers.previewImage(file.file);
        innerSettings.file = file;
      }
    }
  },
  infoLabel: {
    autoheight: true,
    borderless: true,
    css: "upload-team-logo-popup-info",
    template: __("upload_team_logo_popup_info")
  },
  previewLabel: {
    view: "label",
    label: __("upload_team_logo_popup_preview_label")
  },
  preview: {
    height: 120,
    id: "uploadTeamLogoPopupPreview",
    borderless: true,
    css: "upload-team-logo-popup-preview",
    template: "<div class=\"upload-team-logo-popup-preview-container\"> " +
    "<img src=\""+blankLogoImg+"\">" +
    "<div class=\"upload-team-logo-popup-preview-logo-block\">" +
    "<img class=\"upload-team-logo-popup-preview-logo-image\">" +
    "</div>" +
    "</div>"
  },
  taskSettingsDeleteButton: {
    view: "button",
    type: "error",
    id: "teamLogoDelete",
    align: "right",
    width: 90,
    template: "<div class='upload-team-logo-popup-delete'>" +
    "<div class='upload-team-logo-popup-delete-icon'>" + icon_trash + "</div>" +
    "<div class='upload-team-logo-popup-delete-text'>" + __("common_delete") + "</div>" +
    "</div>",
    click: function () {
      if (innerSettings.file) {
        innerSettings.file = false;
        innerHandlers.setCustomLogo(user.team.logo);
      } else {
        if (user.team.logo) {
          webix.confirm(__("team_logo_delete_confirm_msg"), function (answer) {
            if (answer) {
              teamModel.deleteLogo(user.team.id);
              app.trigger("refreshLogo");
            }
          });
        }
      }

    }
  },
  saveButton: {
    view: "button",
    type: "success",
    align: "center",
    height: 36,
    width: 120,
    css: "button_green",
    value: __("common_save"),
    click: function () {
      if (!innerSettings.file) {
        return false;
      }

      teamModel.uploadLogo(innerSettings.file, user.team.id);
      innerHandlers.hidePopup();
    }
  }
};

var innerHandlers = {
  setCustomLogo: function (imageUrl) {
    if (!imageUrl) imageUrl = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

    var preview = $$(innerViews.preview.id);
    var logoContainer = preview.$view.getElementsByClassName("upload-team-logo-popup-preview-logo-image");
    logoContainer[0].setAttribute("src", imageUrl);
  },
  showPopup: function () {
    $$(innerViews.main.id).show();
    innerHandlers.setCustomLogo(user.team.logo);
  },
  hidePopup: function () {
    innerSettings.file = false;
    $$(innerViews.main.id).hide();
  },
  previewImage: function (file) {
    if (file) {
      var preview = $$(innerViews.preview.id);
      var logoContainer = preview.$view.getElementsByClassName("upload-team-logo-popup-preview-logo-image")[0];
      var reader = new FileReader();

      reader.onload = function (e) {
        logoContainer.setAttribute('src', e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }
};

webix.ui({
  view: "window",
  width: 636,
  height: 470,
  id: innerViews.main.id,
  modal: true,
  borderless: true,
  position: "center",
  css: "clear-window-popup",
  move: false,
  head: {
    height: 48,
    cols: [
      {
        view: "icon",
        width: 50,
        css: "_noAction",
        template: "<div class='window_header_icon'></div>"
      },
      {
        view: "label",
        label: __("upload_team_logo_popup_header")
      },
      {
        view: "icon",
        width: 50,
        template: "<div class='window_header_icon'>" + closeIcon + "</div>",
        on: {
          onItemClick: function () {
            $$(innerViews.main.id).hide();
          }
        }
      }
    ]
  },
  body: {
    rows: [
      {
        paddingX: 36,
        paddingY: 24,
        borderless: true,
        css: "upload-team-logo-popup-body",
        rows: [
          {
            height: 140,
            cols: [
              innerViews.recomendationList,
              {width: 10},
              {
                rows: [
                  innerViews.teamLogoUploader,
                  {}
                ]
              }
            ]
          },
          innerViews.previewLabel,
          innerViews.preview
        ]
      },
      {
        css: "upload-team-logo-popup-bottom-block layout-top-border",
        paddingX: 36,
        paddingY: 12,
        height: 60,
        cols: [
          innerViews.taskSettingsDeleteButton,
          {},
          innerViews.saveButton
        ]
      }
    ]
  }
}).hide();

app.on("uploadTeamLogoPopup:show", function () {
  innerSettings.file = false;
  innerHandlers.showPopup();
});

app.on("teamLogo:deleted", function () {
  innerSettings.file = false;
  innerHandlers.showPopup();
});

export default {};
