import Vue from 'vue';
import leftSideBar from "../../views/leftSideBar/leftSideBar.vue";
import header from "../../views/header/header.vue";
import footer from "../../views/footer/footer.vue";

webix.protoUI({
  name: 'vue',
  $init(config) {
    const vtm = config.template;
    const id = `vue_${webix.uid()}`;

    this.$vueData = config.data;

    delete config.data;
    config.template = `<div id='${id}'></div>`;

    this.attachEvent('onAfterRender', function () {
      this.$vue = new Vue({
        el: `#${id}`,
        render: function(createElement) {
          if (config.id === 'leftSideBar') {
            return createElement(leftSideBar);
          }
          if (config.id === 'header') {
            return createElement(header);
          }
          if (config.id === 'footer') {
            return createElement(footer);
          }

          return Vue.compile(vtm).render.call(this, createElement);
        },
        data: this.$vueData,
        watch: this.config.watch,
      });
    });
  },
  getVue() {
    return this.$vue;
  },
  setValues(value) {
    if (this.$vue) {
      for (const key in value) {
        if (typeof this.$vueData[key] !== 'undefined') this.$vue.$set(this.$vue.$data, key, value[key]);
      }
    } else this.$vueData = value;
  },
}, webix.ui.template);
