<template>
  <div :class="$style['skeleton_row_wrapper']">
    <div :class="$style['skeleton_row_name']">
      {{ columnName }}
    </div>
    <skeleton-wrapper
      :style="{maxWidth: '260px'}"
    >
      <template #body>
        <div
          v-if="selectedRow === 0"
          :class="[$style['skeleton_row']]"
        >
          <skeleton-item
            :width="'big'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
        </div>
        <div
          v-else-if="selectedRow === 1"
          :class="[$style['skeleton_row']]"
        >
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
        </div>
        <div
          v-else-if="selectedRow === 2"
          :class="[$style['skeleton_row']]"
        >
          <skeleton-item
            :style="{height: '22px', width: '22px', marginRight: '8px'}"
            :type="'square'"
          />
          <skeleton-item
            :type="'square'"
            :style="{height: '22px', width: '22px'}"
          />
        </div>
        <div
          v-else-if="selectedRow === 3"
          :class="[$style['skeleton_row']]"
        >
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
        </div>
        <div
          v-else-if="selectedRow === 4"
          :class="[$style['skeleton_row']]"
        >
          <skeleton-item
            :width="'wide'"
            :type="'bar'"
            :style="{height: '22px'}"
          />
        </div>
      </template>
    </skeleton-wrapper>
  </div>
</template>

<script>

import skeletonItem from '../../../../common/VueComponents/skeletons/skeletonItem.vue';
import SkeletonWrapper from '../../../../common/VueComponents/skeletons/skeletonWrapper.vue';

export default {
  name: 'CustomFieldSkeletonRow',
  components: {
    SkeletonWrapper,
    skeletonItem,
  },
  props: {
    columnName: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      selectedRow: 0,
    };
  },
  created() {
    this.selectedRow = Math.floor(Math.random() * 4);
  },
};
</script>

<style module lang="less">

.skeleton_row_wrapper{
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  height: 32px;
}

.skeleton_row_name {
  font-family: Lato-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #9E9E9E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skeleton_row {
  width: 100%;
  max-width: 260px;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

</style>
