var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M12.4732 2.88901C11.3023 1.7168 9.4038 1.7168 8.23286 2.88901L3.00016 8.12737L2.29343 7.41988L7.52613 2.18152C9.08739 0.618574 11.6187 0.618578 13.1799 2.18152C14.7412 3.74446 14.7412 6.27849 13.1799 7.84143L6.81941 14.2088C5.64847 15.381 3.75 15.381 2.57906 14.2088C1.40812 13.0366 1.40812 11.1361 2.57906 9.9639L7.71033 4.82708C8.49096 4.0456 9.75661 4.0456 10.5372 4.82708C11.3179 5.60855 11.3179 6.87558 10.5372 7.65705L6.60964 11.5889L5.90292 10.8814L9.83052 6.94956C10.2208 6.55882 10.2208 5.92531 9.83052 5.53457C9.4402 5.14383 8.80737 5.14383 8.41705 5.53457L3.28579 10.6714C2.50516 11.4529 2.50516 12.7199 3.28579 13.5013C4.06641 14.2828 5.33206 14.2828 6.11268 13.5013L12.4732 7.13394C13.6441 5.96174 13.6441 4.06121 12.4732 2.88901Z",
            fill: "currentColor",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }