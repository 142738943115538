var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$.row_input_wrapper }, [
    !_vm.editing
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.formattedContent },
                expression: "{ content: formattedContent }",
              },
            ],
            class: [_vm.$.vgp_displayed_value, _vm.readonly && _vm.$.readonly],
            style: {
              maxWidth: _vm.maxWidth ? _vm.maxWidth + "px" : "100%",
              fontSize: _vm.styles.fontSize,
            },
            on: { click: _vm.showInput },
          },
          [_vm._v("\n    " + _vm._s(_vm.formattedContent) + "\n  ")]
        )
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          ref: "input",
          class: [_vm.$.vgp_row_input, _vm.bold ? _vm.$.bold : ""],
          style: Object.assign({}, { width: _vm.width }, _vm.styles),
          attrs: {
            gpAutotestName: _vm.gpAutotestName,
            readonly: _vm.readonly,
            disabled: _vm.readonly,
          },
          domProps: { value: _vm.content },
          on: {
            focus: _vm.onFocus,
            change: function ($event) {
              return _vm.onChange($event)
            },
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.content = $event.target.value
              },
              function ($event) {
                return _vm.onInput($event)
              },
            ],
            keydown: function ($event) {
              return _vm.onKeydown($event)
            },
            blur: _vm.onBlur,
          },
        }),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "hidden",
        class: [_vm.$.vgp_row_input__hidden, _vm.bold ? _vm.$.bold : ""],
        style: { fontSize: _vm.styles.fontSize },
      },
      [_vm._v("\n    " + _vm._s(_vm.content) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }