import app from '../../../app';
import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';

import projectsModel from '../../../models/projects';
import ganttViewModel from '../../../models/ganttViewModel';

import constants from '../../../helpers/constants';
import helpPopup from '../../help/helpPopup';
import routerHelper from '../../../helpers/router';
import logInternalHelper from '../../../helpers/logInternal';

import disableGanttModule from './disableGantt';
import customGridsModule from './customGrids';
import workloadCore from './workloadCore';
import resourcesOverloadModule from './resourcesOverload';
import globalStore from '../../../store/main';

import pricingHelper, { getPlans, getPlansObj } from '../../../helpers/pricingHelper';
import workload_block_icon from '../../../svg/workload_block.svg';
import arrowLeft from '../../../svg/pricing/ic_arrow_left.svg';
import rights from '../../../components/rights';
import constantsCommon from '../../../helpers/constants';

const __ = window.__;

const RESOURCE_TASK_HEIGHT = 22;
const ROW_HEIGHT = 30;
const GLOBAL_WORKLOAD_GRID_WIDTH = 300;
const PARTIAL_ROWS_COUNT = 20;

const _state = {
  ganttIds: [],
  workloadByProjectsMode: 2,
  scrollX: 0,
  range: null,
  ganttGridColumns: [],
};

const _handlers = {
  refreshWorkloadModeRadioView() {
    const button = document.body.querySelector('.workload-header-mode');
    const radioHours = document.getElementById('workload_group_by_hours');
    const radioTasks = document.getElementById('workload_group_by_tasks');

    if (button) {
      button.innerHTML = _state.workloadByProjectsMode === 2 ? __('workload_mode_all_projects_title') : __('workload_mode_this_project_title');

      button.setAttribute('data-mode', _state.workloadByProjectsMode);
    }

    if (radioHours && radioTasks) {
      radioHours.classList.remove('checked');
      radioTasks.classList.remove('checked');
    }

    if (workloadCore.getState('viewMode') !== 'tasks' && radioHours) {
      radioHours.classList.add('checked');
      radioHours.nextSibling.innerHTML = `<span><span></span></span> ${gantt.config.resource_loading_type === 'hours' ? __('workload_header_hours_per_day') : __('workload_header_percents_per_day')}`;
    } else {
      radioTasks && radioTasks.classList.add('checked');
    }
  },
  hideSingleWorkload() {
    if (!gantt.config.hide_workload) {
      gantt.callEvent('workloadExpandButtonClick', [{ value: 0 }]);
      gantt.callEvent('ganttRender');
    }
  },
  initFakeLayout(isShow) {
    const timelineView = gantt.$ui.getView('workload');

    if (!_state.fakeLayout && isShow) {
      _state.fakeLayout = document.createElement('div');

      _state.fakeLayout.className = 'workload-fake';
      _state.fakeLayout.innerHTML = `${"<div class='workload-fake-layout'>"
        + "<div class='workload-fake-layout-top'>"
        + "<div class='workload-fake-layout-photo'></div>"
        + "<div class='workload-fake-layout-text'>"}${__('edit_is_not_possible')}</div>`
        + '</div>'
        + `<div class='workload-fake-layout-button'>${__('button_display_in_days')}</div>`
        + '</div>';

      _state.fakeLayout.addEventListener('click', e => {
        if (e && e.target && e.target.closest('.workload-fake-layout-button')) {
          if (!app.config.mode.isExport) {
            globalStore.dispatch('headerView/changeZoom', 1);
          } else {
            const postMessage = {
              message: 'changeZoom',
              value: 1,
            };

            parent.postMessage(postMessage, GT.siteConfig);
            // app.trigger('zoom:change', 1);
          }
        }
      });

      timelineView.$view.appendChild(_state.fakeLayout);
    }

    if (_state.fakeLayout) {
      const timelineSizes = timelineView.$view.getBoundingClientRectWrapper();
      const workloadGrid = gantt.$ui.getView('workloadGrid').$grid.getBoundingClientRectWrapper();

      _state.fakeLayout.style.cssText = [
        'top: 0',
        `left:${workloadGrid.width + 1}px`,
        `width: ${timelineSizes.width - workloadGrid.width}px`,
      ].join(';');

      _state.fakeLayout.style.display = isShow ? 'flex' : 'none';
    }
  },
  setStorage({ panelOpenState, panelHeight }) {
    let workloadActivity = globalStore.getters['user/getActivitySettings'](constants.USER_ACTIVITIES.WORKLOAD);
    let isNeedSave = false;

    if (!workloadActivity) {
      workloadActivity = {};
    }

    if (panelOpenState !== undefined) {
      if (!workloadActivity.panelOpenState) {
        workloadActivity.panelOpenState = {
          [gantt.config.gantt_id || gantt.config.multiview_id]: !panelOpenState,
        };
      } else {
        workloadActivity.panelOpenState[gantt.config.gantt_id || gantt.config.multiview_id] = !panelOpenState;
      }
      isNeedSave = true;
    }

    if (panelHeight !== undefined) {
      if (!workloadActivity.workloadHeight) {
        workloadActivity.workloadHeight = {
          [gantt.config.gantt_id || gantt.config.multiview_id]: panelHeight,
        };
        isNeedSave = true;
      } else {
        const oldHeight = workloadActivity.workloadHeight[gantt.config.gantt_id || gantt.config.multiview_id];

        if (oldHeight !== panelHeight) {
          workloadActivity.workloadHeight[gantt.config.gantt_id || gantt.config.multiview_id] = panelHeight;
          isNeedSave = true;
        }
      }
    }

    isNeedSave && globalStore.dispatch('user/updateActivitySettings', {
      activityName: constants.USER_ACTIVITIES.WORKLOAD,
      settings: workloadActivity,
    });
  },
  changeViewMode(mode, isSilent) {
    if (!mode) {
      return;
    }

    const viewMode = mode !== 'tasks' ? gantt.config.resource_loading_type : mode;

    workloadCore.updateState({ viewMode: mode });// for zoom in hours
    _handlers.refreshWorkloadModeRadioView();
    !isSilent && workloadCore.run({ viewMode });
  },
  showSelectOptions() {
    if ($$('workloadSelectModePopupId').isVisible()) {
      $$('workloadSelectModePopupId').hide();

      return;
    }

    const button = document.body.querySelector('.workload-header-mode');

    const getTemplate = function () {
      return `<div data-id='1' class='workload-header-mode-select-item ${_state.workloadByProjectsMode === 1 ? ' selected' : ''}'>${__('workload_mode_this_project_title')}</div><div data-id='2' class='workload-header-mode-select-item ${_state.workloadByProjectsMode === 2 ? ' selected' : ''}'>${__('workload_mode_all_projects_title')}</div>`;
    };

    $$('workloadSelectModePopupTemplateId').define('template', getTemplate());
    $$('workloadSelectModePopupTemplateId').refresh();
    $$('workloadSelectModePopupId').show(button, { y: -3 });
  },
  initWorkloadNoAccessPlaceHolder() {
    const timelineView = gantt.$ui.getView('workload');

    // timelineView.$config.height = 165;
    const hasAccessToWorkLoad = pricingHelper.checkPricingAccess('workload');
    const isExport = app.config.mode.isExport;
    const upgradeBtn = (!rights.account.isOwner() || isExport) ? '' : `<div class="upgrade_button">${__('upgrade_rate_btn')}</div>`;

    if (!_state.workloadNoAccessPlaceHolderNode && !hasAccessToWorkLoad) {
      _state.workloadNoAccessPlaceHolderNode = document.createElement('div');

      _state.workloadNoAccessPlaceHolderNode.className = 'gantt_workload_placeholder';
      const plan_title = `${getPlans('workload')}_plan_locale`;

      _state.workloadNoAccessPlaceHolderNode.innerHTML = `<div class="placeholder_image">
          <img src="${GT.cdn}/imgs/workload/ganttchart_blocked_workload_left.png">
        </div>
        <div class="center_placeholder_part">
          ${workload_block_icon}
          <div class="description_block">
            <div class="text_wrapper">
              <span class="title">
                ${__('workload_access_title')}
                <span class="plan_label">${__(plan_title)}</span>
              </span>          
              <div class="description">
                <div class="description-text">
                ${__('workload_access_desc')}
                 <div class="learn_more">
                    <a class="pricing-popup-link" href="${__('workload_learn_more')}" target="_blank">${__('help_read_more')}</a>             
                    <div class="arrow-left">${arrowLeft}</div>
                </div>  
                </div>            
              </div>
            ${upgradeBtn}
          </div>
        </div>
        <div class="placeholder_image">
          <img src="${GT.cdn}/imgs/workload/ganttchart_blocked_workload_right.png">
        </div>`;

      const btnToPlan = _state.workloadNoAccessPlaceHolderNode.querySelector('.upgrade_button');
      const linkClick = _state.workloadNoAccessPlaceHolderNode.querySelector('.pricing-popup-link');
      const newPlanObj = getPlansObj('workload');
      const userPlanId = user.accountPlanData.payment_plans_id;
      const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

      btnToPlan && btnToPlan.addEventListener('click', () => {
        userExtAnalytics.log('pricing_deadlock_upgrade_clicked', { type: 'workload' });
        globalStore.dispatch('globalPopup/show', {
          type: 'upgradePlan',
          title: 'upgrade_plan_popup_title',
          currentPlan: userPlan,
          newPlan: newPlanObj,
          // userCount: user.subscription.user_count,
          isAnnual: user.subscription.is_annual,
        });
      });

      linkClick && linkClick.addEventListener('click', () => {
        userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'workload' });
      });

      timelineView.$view.appendChild(_state.workloadNoAccessPlaceHolderNode);
    }

    if (_state.workloadNoAccessPlaceHolderNode) {
      _state.workloadNoAccessPlaceHolderNode.style.display = hasAccessToWorkLoad ? 'none' : 'flex';
    }
  },
};

const _helpers = {
  isWorkloadHidden() {
    const workloadActivity = globalStore.getters['user/getActivitySettings'](constants.USER_ACTIVITIES.WORKLOAD);
    const isOpen = workloadActivity?.panelOpenState
      ? workloadActivity.panelOpenState[(!gantt.config.multiview ? gantt.config.gantt_id : gantt.config.multiview_id)]
      : false;

    const viewData = ganttViewModel.getActiveViewData();
    const multiviewGanttIds = app.config.mode.isExport ? gantt.config.order : viewData?.ganttIDs;
    const ganttId = app.config.mode.isExport ? gantt.config.gantt_id : viewData?.gantt_id;

    const hasRightWorkload = (multiviewGanttIds && multiviewGanttIds.some(id => rights.project.hasRight(id, 'workload'))
        || rights.project.hasRight(ganttId, 'workload'));

    if (!hasRightWorkload) return true;

    return isOpen === undefined ? true : !isOpen;
  },
  initWorkloadLayout(changeFlag) {
    const isGlobal = routerHelper.isWorkloadViewRoute();
    const mainGanttLayout = gantt.$ui.getView('ganttChart');
    const workloadLayout = gantt.$ui.getView('workload');
    const workloadHeader = gantt.$ui.getView('workloadHeader');
    const workloadGrid = gantt.$ui.getView('workloadGrid');
    const workloadTimeline = gantt.$ui.getView('workloadTimeline');
    const isHidden = _helpers.isWorkloadHidden();
    const workloadActivity = globalStore.getters['user/getActivitySettings'](constants.USER_ACTIVITIES.WORKLOAD);

    workloadTimeline.getSize = workloadCore.timelineGetSize;

    if (!workloadHeader || !workloadTimeline) {
      return;
    }

    gantt.config.hide_workload = _.isUndefined(changeFlag) ? isHidden : !gantt.config.hide_workload;
    workloadLayout.$config.config.scale_height = isGlobal ? 38 : '0';
    workloadLayout.$view.classList[isGlobal ? 'remove' : 'add']('local');
    workloadLayout.$view.classList[app.config.mode.isExport ? 'add' : 'remove']('export');

    if (app.config.mode.isBase) {
      gantt._workload_expand_button._html.classList[isGlobal ? 'add' : 'remove']('hide');

      const viewData = ganttViewModel.getActiveViewData();
      const hasRightWorkload = ((viewData?.ganttIDs && viewData.ganttIDs.some(id => rights.project.hasRight(id, 'workload')))
        || rights.project.hasRight(viewData?.gantt_id, 'workload'));

      if (!hasRightWorkload) {
        gantt.config.hide_workload = true;
        gantt._workload_expand_button._html.classList.add('hide');
      }
    }

    if (isGlobal) {
      workloadLayout.$config.height = undefined;
      !workloadHeader.getNode().offsetHeight && workloadHeader.show();
      !workloadLayout.getNode().offsetHeight && workloadLayout.show();
      workloadHeader.hide();
      mainGanttLayout.hide();
      workloadGrid.$parent.$config.hidden = false;
    } else {
      !mainGanttLayout.getNode().offsetHeight && mainGanttLayout.show();
      workloadHeader[gantt.config.hide_workload ? 'hide' : 'show']();
      workloadLayout[gantt.config.hide_workload ? 'hide' : 'show']();
      workloadGrid.$parent.$config.hidden = app.config.mode.isExport ? gantt.config.grid_width === 0 : !gantt.config.show_grid;

      if (!gantt.config.hide_workload) {
        workloadLayout.$config.height = workloadActivity?.workloadHeight
          ? (workloadActivity.workloadHeight[gantt.config.gantt_id || gantt.config.multiview_id] || constants.WORKLOAD_PANEL_DEFAULT_HEIGHT)
          : constants.WORKLOAD_PANEL_DEFAULT_HEIGHT;
        workloadLayout.resize();
      }
    }

    const workloadResizer = document.querySelector('.workload-resizer');

    if (workloadResizer) {
      workloadResizer.parentNode.classList[gantt.config.hide_workload || isGlobal ? 'add' : 'remove']('hide');
    }
  },
  initGanttForGlobalMode() {
    let ganttIds = ganttViewModel.getActiveViewData().ganttIDs || [ganttViewModel.getActiveViewData().gantt_id];

    if (routerHelper.isCommonWorkloadViewRoute()) {
      ganttIds = projectsModel.getAllProjects().map(o => o.gantt_id);
    }
    const scrollViewX = gantt.$ui.getView('scrollHor');

    gantt.config.skip_off_time = false;

    gantt.silent(() => {
      gantt.blockEvents();
      gantt.clearAll();
      gantt.parse(ganttViewModel.getTasksForMultiView(ganttIds));
      gantt.unblockEvents();
    });

    scrollViewX && scrollViewX.scrollTo(_state.scrollX, null);

    gantt.callEvent('onParse');
    app.trigger('settings:skin:change', '4');
    app.trigger('settings:viewMode:change', 'auto');
  },
  initZoomForGlobalMode() {
    const userActivity = user.activity.find(o => o.name === 'workload');
    let zoom = 1;

    if (userActivity) {
      const workloadActivity = JSON.parse(userActivity.settings);

      zoom = workloadActivity.zoom || zoom;
    }

    globalStore.dispatch('headerView/changeZoom', zoom);
  },
  clearBaselines() {
    const element = document.querySelector('.dhx_gantt_container');

    element && element.classList.remove('baseline-container');
  },
  initGridForGlobalMode() {
    if (!_state.ganttGridColumns.length) {
      _state.ganttGridColumns = gantt.config.columns;

      gantt.config.columns = [{
        width: GLOBAL_WORKLOAD_GRID_WIDTH,
      }];
    }
    gantt.config.grid_width = GLOBAL_WORKLOAD_GRID_WIDTH;
  },
  initTimelineRangeForGlobalMode(newRange) {
    const rangePicker = $$('workloadRange');

    if (!rangePicker) {
      return;
    }

    const userActivity = user.activity.find(o => o.name === 'workload');

    if (!rangePicker.isVisible()) {
      return;
    }

    rangePicker.blockEvent();

    if (newRange) {
      rangePicker.setValue(newRange);
      _state.range = rangePicker.getValue();
      _state.range.start = moment(rangePicker.getPopup().getValue().start).toDate();
      _state.range.end = moment(rangePicker.getPopup().getValue().end).toDate();
      globalStore.dispatch('user/updateActivitySettings', {
        activityName: constants.USER_ACTIVITIES.WORKLOAD,
        settings: {
          ..._state.range,
          zoom: globalStore.getters['headerView/zoom'],
        },
      });
    } else if (userActivity) {
      _state.range = JSON.parse(userActivity.settings);

      _state.range.start = moment(_state.range.start).toDate();
      _state.range.end = moment(_state.range.end).toDate();

      if (_state.range.period) {
        rangePicker.setValue({ period: _state.range.period });
        _state.range.start = moment(rangePicker.getPopup().getValue().start).toDate();
        _state.range.end = moment(rangePicker.getPopup().getValue().end).toDate();
      } else {
        rangePicker.setValue(_state.range);
      }
    } else {
      rangePicker.setValue({ period: 'three_months_workload' }); // default
      _state.range = rangePicker.getValue();
      _state.range.start = moment(rangePicker.getPopup().getValue().start).toDate();
      _state.range.end = moment(rangePicker.getPopup().getValue().end).toDate();
    }

    rangePicker.unblockEvent();
    rangePicker.callEvent('afterUpdateValue');
  },
  onUserActivitiesLoadedHandler() {
    const headerHelpIcon = _helpers.getHelIconNode();

    if (headerHelpIcon) {
      const activityStatus = globalStore.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.HELP_WORKLOAD);

      _helpers.updateHelpIcon(headerHelpIcon, activityStatus);
    }
  },
  getHelIconNode() {
    const headerView = gantt.$ui.getView('workloadHeader');

    return headerView && headerView.$view.querySelector('.help_icon_block .help-icon');
  },
  updateHelpIcon(node, status) {
    if (status === 1) {
      node.classList.add('active-help');
    } else {
      node.classList.remove('active-help');
    }
  },
  applyGanttChartFilter(filterOptions) {
    if (!filterOptions) {
      return;
    }

    let needUpdate = false;
    let fromCache = true;
    const newFilterState = {
      resources: [],
      projects: [],
    };
    const workloadFilterState = workloadCore.getState('filter');

    const newFilterByResources = filterOptions.assignee.length
      && filterOptions.assignee.reduce((sum, id) => sum += +id, 0);

    const oldFilterByResources = workloadFilterState.resources.length
      && workloadFilterState.resources.reduce((sum, id) => sum += +id, 0);

    if (newFilterByResources !== oldFilterByResources) {
      newFilterState.resources = filterOptions.assignee;
      needUpdate = true;
    }

    if (filterOptions.ganttId) {
      const newFilterByProjects = filterOptions.ganttId.length
        && filterOptions.ganttId.reduce((sum, id) => sum += +id, 0);

      const oldFilterByProjects = workloadFilterState.projects.length
        && workloadFilterState.projects.reduce((sum, id) => sum += +id, 0);

      if (newFilterByProjects !== oldFilterByProjects) {
        newFilterState.projects = filterOptions.ganttId;
        needUpdate = true;
        fromCache = false;
      }
    }

    needUpdate && workloadCore.run({ fromCache, filter: newFilterState });
  },
  onBeforeColumnDragStart(obj) {
    if (!obj.draggedColumn || constantsCommon.COLUMN_ORDER.NOT_DRAGABLE.indexOf(obj.draggedColumn.name) !== -1) {
      return false;
    }
    return true;
  },
};

const _initWorkload = function (config = {}) {
  if (!app.config.mode.isBase && !app.config.mode.isExport) {
    gantt.init_workload = false;

    return;
  }

  const isGlobal = routerHelper.isWorkloadViewRoute();

  gantt.init_workload = true;

  if (isGlobal) {
    const ganttIds = routerHelper.isCommonWorkloadViewRoute()
      ? [] : ganttViewModel.getActiveViewData().ganttIDs || [ganttViewModel.getActiveViewData().gantt_id];

    _helpers.initGridForGlobalMode();
    gantt._forceInitMultiview = true;
    disableGanttModule.disableGanttChart(false, false);
    !config.dateRange && _helpers.initGanttForGlobalMode();
    _helpers.initTimelineRangeForGlobalMode(config.dateRange);
    _helpers.initZoomForGlobalMode();
    _helpers.clearBaselines();
    gantt.render();// needs for recalculate timeline widths and recalculate grid width
    globalStore.commit('headerView/setActiveWorkloadType', workloadCore.getState('viewMode'));
    workloadCore.run({ ganttIds });
    projectsModel.forceSetActive = true;
    // workloadFilter.init(workloadCore.afterFilter, ganttIds);
    app.trigger('workload:filter:filtration');
  }

  _helpers.initWorkloadLayout();

  !isGlobal && customGridsModule.init.reinit();

  gantt.$ui.getView('workload').resize();

  config.closeResources && workloadCore.updateState({ openedResources: {} });

  if (gantt.config.hide_workload) app.trigger('gantt:progress:hide');
  logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
};
const handleResizeForWorkloadPanel = _.debounce(() => {
  const workloadHeight = gantt.$ui.getView('workload').getNode().offsetHeight;
  const chartHeight = gantt.$ui.getView('ganttChart').getNode().offsetHeight;

  if (!chartHeight) {
    return;
  }

  if (workloadHeight < constants.WORKLOAD_PANEL_MIN_HEIGHT && !gantt.config.hide_workload) {
    gantt.callEvent('workloadExpandButtonClick', [{ value: 0 }]);
    gantt.callEvent('ganttRender');
  }
  if (workloadHeight >= constants.WORKLOAD_PANEL_MIN_HEIGHT) {
    _handlers.setStorage({ panelHeight: workloadHeight });
  }
}, 200);

const _initEvents = function () {
  window.addEventListener('resize', _.debounce(() => {
    // if (!app.config.mode.isExport) {
    workloadCore.run({ needResize: true });
    // }
  }, 100));

  app.on('gantt:init', () => {
    if (!pricingHelper.checkPricingAccess('workload')) _handlers.initWorkloadNoAccessPlaceHolder();
    else _handlers.initFakeLayout(gantt.config.durationData.mode === 'hour');
  });

  app.on('workload:ready', () => {
    _handlers.refreshWorkloadModeRadioView();
    if (gantt.config.isMpp && !gantt.serialize().data.length) return; // we don't have to hide loader in case of no tasks added (case of mpp import)
    if (routerHelper.isWorkloadViewRoute() || routerHelper.isGanttRoute()) app.trigger('gantt:progress:hide');
    gantt.$ui.getView('workloadGrid').attachEvent('onBeforeColumnDragStart', obj => _helpers.onBeforeColumnDragStart(obj));
  });

  app.on('workload:skipped', () => {
    if (gantt.config.isMpp && !gantt.serialize().data.length) return; // we don't have to hide loader in case of no tasks added (case of mpp import)
    if (routerHelper.isWorkloadViewRoute()) app.trigger('gantt:progress:hide');
  });

  app.on('collaboration:updatedModels', _.debounce(() => {
    if (routerHelper.isWorkloadViewRoute()) {
      _helpers.initGanttForGlobalMode();
      workloadCore.run({ needResize: true });
      // _initWorkload();
    }
  }));

  app.on('collaboration:liteRefresh', _.debounce(() => {
    if (routerHelper.isWorkloadViewRoute()) {
      workloadCore.run();
    }
  }));

  app.on('settings:resourceLoadingType:change', value => {
    _handlers.refreshWorkloadModeRadioView();
    workloadCore.run({ viewMode: value, fromCache: true });
  });

  app.on('tasks:model:addLink', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _helpers.initGanttForGlobalMode();
      workloadCore.run();
    }
  });

  app.on('tasks:model:updateLink', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _helpers.initGanttForGlobalMode();
      workloadCore.run();
    }
  });

  app.on('tasks:model:massChangeLink', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _helpers.initGanttForGlobalMode();
      workloadCore.run();
    }
  });

  app.on('tasks:model:deleteLink', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _helpers.initGanttForGlobalMode();
      workloadCore.run();
    }
  });

  app.on('tasks:model:massdelete', () => {
    workloadCore.run();
  });

  app.on('tasks:model:updateTask', (id, item) => {
    workloadCore.run();
  });

  app.on('tasks:model:delete', (id, item) => {
    workloadCore.run();
  });

  app.on('resizeWorkload', () => {
    _.delay(() => {
      workloadCore.run();
    });
  });

  app.on('activeProject:afterSet', ganttId => {
    if (!routerHelper.isWorkloadViewRoute()) {
      workloadCore.updateState({ filter: { projects: [], resources: [] } });
      _.delay(() => {
        _initWorkload({ closeResources: _state.activeSingleProjectGanttId !== ganttId });
        _state.activeSingleProjectGanttId = ganttId;
      });
    }
  });

  app.on('init:multiview', () => { // fires on start app from multiview route
    _state.activeSingleProjectGanttId = null;
    _initWorkload({ closeResources: true });
  });

  app.on('activeMultiProjects:afterSet', () => {
    _state.activeSingleProjectGanttId = null;
    _initWorkload({ closeResources: true });
  });

  app.on('init:workload', range => { // fires after change route to global workload or multiview
    if (!pricingHelper.checkPricingAccess('workload')) {
      app.trigger('gantt:progress:hide', true);
    }
    _state.activeSingleProjectGanttId = null;
    _.delay(() => {
      _state.scrollX = 0;
      workloadCore.updateState({ filter: { projects: [], resources: [] }, visibleUnassignedTasksRows: PARTIAL_ROWS_COUNT });
      _initWorkload({ dateRange: range, closeResources: true });
    });
  });

  app.on('gantt:reinitConfig', () => {
    workloadCore.updateState({ selectedTask: {}, openedResources: {} });
  });

  app.on('project:settings:workload:recalculate', () => {
    workloadCore.run();
  });

  app.on('settings:skipOffTime:change', () => {
    workloadCore.run();
  });

  // gantt chart filter changes
  app.on('filterWorkloadAfterGanttFilter', filterOptions => {
    _.delay(() => {
      _helpers.applyGanttChartFilter(filterOptions);
    });
  });

  app.on('filter:clear', filterOptions => {
    _.delay(() => {
      workloadCore.run({ filter: { projects: [], resources: [] } });
    });
  });

  app.on('project:component:add', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _initWorkload();
    }
  });

  app.on('project:component:remove', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      _initWorkload();
    }
  });

  app.on('userProfile:update', () => {
    workloadCore.run();
  });

  app.on('before:change:globalWorkload:route', () => {
    if (_state.ganttGridColumns.length) {
      gantt.config.columns = _state.ganttGridColumns;
      _state.ganttGridColumns = [];
    }
  });

  // app.on('user:changedRole', () => {
  //   _.delay(() => {
  //     const viewData = ganttViewModel.getActiveViewData();
  //     const hasRightWorkload = ((viewData?.ganttIDs && viewData.ganttIDs.some(id => rights.project.hasRight(id, 'workload')))
  //       || rights.project.hasRight(viewData?.gantt_id, 'workload'));
  //
  //     if (!hasRightWorkload && !gantt.config.multiview) {
  //       routerHelper.changeRoute('/');
  //
  //       return;
  //     }
  //     _initWorkload();
  //   });
  // });

  app.on('init:newProject', () => {
    _initWorkload();
  });

  app.on('workload:change:type', type => {
    workloadCore.run({ viewMode: type });
  });

  app.on('afterGanttWorkerFinish', () => {
    workloadCore.run();
  });

  app.on('tasksModel:onStoreUpdate', ({ ganttId, taskId, autoschedule }) => {
    if (_state.ganttIds.includes(+ganttId) || (routerHelper.isWorkloadViewRoute() && taskId)) {
      if (autoschedule || !taskId) {
        workloadCore.run();
      } else {
        workloadCore.run({ fromCache: true });
      }
    }
  });

  gantt.attachEvent('onBeforeGanttRender', () => {
    if (routerHelper.isWorkloadViewRoute() && _state.range) {
      const endDate = _state.range.period ? _state.range.end : moment(_state.range.end).add(1, 'day').toDate();

      gantt.config.start_date = moment(_state.range.start).startOf('day').toDate();
      gantt.config.end_date = endDate;
    }
  });

  gantt.attachEvent('toggleWorkloadGrid', isShow => {
    const workloadGrid = gantt.$ui.getView('workloadGrid');

    if (!workloadGrid) {
      return;
    }

    workloadGrid.$parent.$config.hidden = isShow;

    workloadCore.run({ needResize: true });
  });

  gantt.attachEvent('onGridColumnsToggle', () => {
    workloadCore.run();
  });

  gantt.attachEvent('onGridResizeEnd', () => {
    workloadCore.run();
  });

  gantt.attachEvent('workload:change:zoom', () => {
    _helpers.initGridForGlobalMode();
    workloadCore.updateState({ selectedTask: {}, openedResources: {} });
    workloadCore.run({ needResize: true });

    if (_state.range) {
      globalStore.dispatch('user/updateActivitySettings', {
        activityName: constants.USER_ACTIVITIES.WORKLOAD,
        settings: {
          ..._state.range,
          zoom: globalStore.getters['headerView/zoom'],
        },
      });
    }
  });

  gantt.attachEvent('workloadExpandButtonClick', ({
    value, viewMode, isShow, isExport,
  }) => {
    if (!_.isUndefined(value)) {
      gantt.config.hide_workload = value;
    }

    _helpers.initWorkloadLayout(true);

    !isExport && _handlers.setStorage({ panelOpenState: gantt.config.hide_workload });
    _handlers.changeViewMode(viewMode, true);

    if (gantt.config.hide_workload) {
      app.trigger('workload:ready');
    } else {
      userExtAnalytics.log('workload_open', { from: 'gantt view' });
    }

    if (!pricingHelper.checkPricingAccess('workload')) {
      _handlers.initWorkloadNoAccessPlaceHolder();

      return;
    }
    if (gantt.config.durationData.mode === 'hour') {
      _handlers.initFakeLayout(gantt.config.durationData.mode === 'hour');

      return;
    }

    workloadCore.run({ needResize: !gantt.config.hide_workload, ganttIds: _state.ganttIds });

    const isActiveFilter = globalStore.getters['filter/isActive'];
    const currentFilter = globalStore.getters['filter/currentFilter'];
    const filterState = globalStore.getters['filter/filterState'];

    if (isActiveFilter && ['project', 'multiview'].includes(currentFilter.type)) {
      _helpers.applyGanttChartFilter(filterState);
    }
  });

  gantt.attachEvent('showResourceWorkloadTasks', resourceId => {
    workloadCore.showResourceTasks(resourceId);
  });

  gantt.attachEvent('changeWorkloadMode', () => {
    const workloadSelectModePopupId = $$('workloadSelectModePopupId');
    const workloadHeaderMode = document.querySelector('.workload-header-mode');

    if (workloadSelectModePopupId && workloadSelectModePopupId.isVisible()) {
      workloadHeaderMode.classList.remove('active');
    } else {
      workloadHeaderMode.classList.add('active');
    }

    _handlers.showSelectOptions();
  });

  gantt.attachEvent('onParse', () => {
    if (routerHelper.isWorkloadViewRoute()) {
      return;
    }

    gantt.clearCash('isWorkDays');

    // _.delay(() => {
    if (!app.config.mode.isExport || !app.config.mode.ignoreFilters) {
      const projectData = ganttViewModel.getActiveViewData();
      let ganttIds = [];

      if (projectData) {
        if (!projectData.isSingleProject) {
          ganttIds = projectData.ganttIDs;
        } else {
          ganttIds.push(projectData.gantt_id);
        }

        _state.ganttIds = ganttIds;

        if (!routerHelper.isWorkloadViewRoute() && _state.workloadByProjectsMode === 2) {
          ganttIds = [];
        }

        workloadCore.run({ needResize: true, ganttIds });
      }
    }
    // });
  });

  gantt.attachEvent('recalculateWorkload', () => {
    workloadCore.run();
  });

  app.on('resources:model:update', resourceId => {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](resourceId);
    const isActiveResource = !!resourceData?.resourceProjects.find(o => _state.ganttIds.includes(o.projectId));

    isActiveResource && workloadCore.run();
  });

  app.on('resources:model:addResourcesToProjects', resourceIds => {
    workloadCore.run();
  });

  app.on('resources:model:changeResourcesOnProjects', resourcesMap => {
    workloadCore.run();
  });

  app.on('resources:model:removeResourcesFromProjects', resourceIds => {
    workloadCore.run();
  });

  app.on('resources:model:changeWorkCustomDays', resourceIds => {
    workloadCore.run();
  });

  app.on('resources:model:changeWorkTime', resourceIds => {
    workloadCore.run();
  });

  const headerView = gantt.$ui.getView('workloadHeader');
  const timelineView = gantt.$ui.getView('workloadTimeline');
  const mainView = gantt.$ui.getView('main');
  const scrollYView = gantt.$ui.getView('workloadVScroll');
  const headerHelpIcon = headerView.$view.querySelector('.help_icon_block .help-icon');
  const lastScrollX = 0;

  gantt.eventRemove(headerView.$view, 'change');

  mainView.attachEvent('onResize', () => {
    if (!routerHelper.isWorkloadViewRoute() && !app.config.mode.isExport) {
      handleResizeForWorkloadPanel();
    }

    if (!pricingHelper.checkPricingAccess('workload')) _handlers.initWorkloadNoAccessPlaceHolder();
    else _handlers.initFakeLayout(gantt.config.durationData.mode === 'hour');
    gantt.updateExpandButtonsPosition();
    app.trigger('workload:resize');
    gantt.callEvent('updateColumnsHeaders');
    workloadCore.refreshWorkload();
  });

  timelineView.$task_data.ondragover = _.debounce(function (e) {
    const parentCoords = this.getBoundingClientRect();

    const scrollBar = gantt.$ui.getView('workloadVScroll');
    const scrollState = scrollBar.getScrollState();

    if (e.clientY - parentCoords.top - ROW_HEIGHT <= 0) {
      scrollBar.scroll(scrollState.position - ROW_HEIGHT);

      return;
    }

    if (e.clientY - parentCoords.height - parentCoords.top + ROW_HEIGHT >= 0) {
      scrollBar.scroll(scrollState.position + ROW_HEIGHT);
    }
  }, 10);

  // scrollYView.attachEvent("onScroll", _.debounce(function () {
  //   workloadCore.run({fromCache: true});
  // }, 50));

  if (headerHelpIcon) {
    gantt.event(headerHelpIcon, 'click', async () => {
      helpPopup.showHelpBlock('', 'workloadHelpBlock');

      const { activity_status } = await globalStore.dispatch('user/updateActivityStatus', {
        activityName: constants.USER_ACTIVITIES.HELP_WORKLOAD,
        status: 1,
      });

      const headerHelpIconNode = _helpers.getHelIconNode();

      if (headerHelpIconNode) {
        _helpers.updateHelpIcon(headerHelpIconNode, activity_status);
      }
    });
  }

  gantt.event(headerView.$view, 'click', event => {
    if (event.target.closest('.workload-header-controls-item')) {
      _.delay(() => {
        _handlers.changeViewMode(event.target.value);
      });
    }
  });

  gantt.event(timelineView.$task, 'scroll', e => {
    if ((e.target.scrollLeft || _state.scrollX) && gantt.init_workload) {
      _.delay(() => {
        if (!e.target) {
          return;
        }

        _state.scrollX = e.target.scrollLeft;
        workloadCore.run({ useHardCache: true, fromCache: true });
      });
    }
  });

  // gantt.event(scrollYView, 'scroll', function (e) {
  //   const scrollState = workloadCore.getState('scroll');
  //
  //   scrollState.y = e.target.scrollTop;
  //   workloadCore.updateState({scroll: scrollState});
  // });
};

const _setGanttLayout = function () {
  gantt.config.layout = {
    css: 'gantt_container',
    rows: [
      {
        id: 'ganttChart',
        gravity: 4,
        cols: [
          { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
          { resizer: true, width: 1 },
          { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
        ],
      },
      {
        resizer: true, id: 'workloadResizer', width: 1, next: 'workload', css: 'workload-resizer',
      },
      {
        css: 'workload-header',
        id: 'workloadHeader',
        height: 48,
        min_height: 48,
        max_height: 48,
        cols: pricingHelper.checkPricingAccess('workload')
          ? [
            {
              html: `<div>${__('workload_header_title')}</div>${
                app.config.mode.isExport ? ''
                  : `<div 
                       class='workload-header-mode' 
                       data-mode="${_state.workloadByProjectsMode}"
                       onclick='gantt.callEvent(\`changeWorkloadMode\`)'
                       >
                        ${__('workload_mode_all_projects_title')}
                  </div>`}`,
              css: 'workload-header-title',
              group: 'grids',
            },
            { resizer: true, width: 1 },
            {
              view: 'template',
              css: 'workload-header-controls',
              borderless: true,
              html:
              // (app.config.mode.isExport ? '' :
                `${'<div class="workload-header-controls-item">'
                + '<input id="workload_group_by_hours" type="radio" name="workload_group_by" value="hours" checked><label for="workload_group_by_hours"><span><span></span></span>'}${gantt.config.resource_loading_type === 'hours' ? __('workload_header_hours_per_day') : __('workload_header_percents_per_day')}</label>`
                + '</div>'
                + '<div class="workload-header-controls-item">'
                + `<input id="workload_group_by_tasks" type="radio" name="workload_group_by" value="tasks" ><label for="workload_group_by_tasks"><span><span></span></span>${__('workload_header_tasks_per_day')}</label>`
                + '</div>',
            },
            {
              view: 'template',
              id: 'workloadHelpBlock',
              css: 'help_icon_block',
              borderless: true,
              hidden: !app.config.mode.isBase,
              width: 50,
              html:
                "<div class=\"help-icon\" id='workloadHelpBlock'><div class=\"help-icon-inner\"><svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n"
                + '<path d="M12 22V18.9684C7 18.9684 4 15.2695 4 11C4 6.58737 7.5 3 12 3C16.4126 3 20 6 20 11C20 16 16 20 12 22ZM12 4C8 4 5 7 5 11C5 15 8.12458 18 12 18H13V20C16.0653 18.0632 19.0048 15 19 11C18.9952 7 16 4 12 4ZM11 14H13.0054L13 16H12.5H11V14ZM13 13H11C11 10.5 13 10.6842 13 9C13 8.44394 12.6568 8 12 8C11.5 8 11 8.5 11 9H9C9 7.13895 10.1389 6 12 6C14 6 15 7.5663 15 9C15 10.5 13 11.5 13 13Z" fill-rule="evenodd"/>\n'
                + '</svg>\n</div></div>',
            },
          ] : [{
            html: `<div>${__('workload_header_title')}</div>`,
            css: 'workload-header-title',
          }],
      },
      {
        gravity: 1,
        id: 'workload',
        css: 'workload-body local',
        config: {
          columns: [
            {


              name: 'workload_resource_name',
              label: __('workload_resource_name'),
            },
          ],
          scale_height: '0',
          row_height: ROW_HEIGHT,
        },
        cols: [
          {
            id: 'workloadGrid',
            view: 'grid',
            group: 'grids',
            bind: 'workloadStore',
            scrollY: 'workloadVScroll',
            gravity: 1,
          },
          // { resizer: true, width: 1 , id: 'workloadGridResizer'},
          {
            id: 'workloadTimeline',
            view: 'timeline',
            gravity: 5,
            scrollX: 'scrollHor',
            scrollY: 'workloadVScroll',
            bind: 'workloadStore',
            layers: workloadCore.renderLayers,
          },
          {
            view: 'scrollbar', scroll: 'y', id: 'workloadVScroll', group: 'vertical',
          },
        ],
      },
      { view: 'scrollbar', id: 'scrollHor' },
    ],
  };
};

webix.ui({
  id: 'workloadSelectModePopupId',
  view: 'popup',
  css: 'workload-header-mode-select',
  borderless: true,
  // header: false,
  head: false,
  hidden: true,
  // headHeight: 0,
  type: 'clean',
  height: 72,
  width: 144,
  padding: '0',
  // position: function (state) {

  // },
  relative: 'bottom',
  modal: false,
  move: false,
  margin: '0',
  body: {
    type: 'clean',
    borderless: true,
    margin: '0',
    padding: '0',
    height: 72,
    width: 144,
    id: 'workloadSelectModePopupTemplateId',
    view: 'template',
    template: '',
    onClick: {
      'workload-header-mode-select-item': function (e, t, node) {
        $$('workloadSelectModePopupId').hide();

        if (_state.workloadByProjectsMode !== +node.dataset.id) {
          _state.workloadByProjectsMode = +node.dataset.id;

          _handlers.refreshWorkloadModeRadioView();
          workloadCore.run({ ganttIds: _state.workloadByProjectsMode === 1 ? _state.ganttIds : [] });
        }
      },
    },
  },
}).hide();

const outputObject = {
  init: {
    beforeInit() {
      _initEvents();
      _helpers.onUserActivitiesLoadedHandler();
      workloadCore.initWorker();
      resourcesOverloadModule.init();
    },
    initLayoutConfig(force) {
      if (app.config.mode.isBase || app.config.mode.isExport) {
        _setGanttLayout();
      }
    },
  },
  handlers: {
    changeWorkloadHeightByCount(resourcesCount) {
      const view = gantt.$ui.getView('workload');
      const workloadRows = document.querySelectorAll('.gantt_grid_data .workload_row');
      const height = _.reduce(workloadRows, (sum, item) => sum + item.offsetHeight, 0);

      this.changeWorkloadHeight(height + view.$config.headerHeight);
    },
    changeWorkloadHeight(height) {
      const view = gantt.$ui.getView('workload');

      view.$config.height = height;
      view.resize();
    },
    getWorkloadHeight() {
      const view = gantt.$ui.getView('workload');

      return view.$config.headerHeight + view.$view.offsetHeight;
    },
  },
  helpers: {
    isWorkloadHidden: _helpers.isWorkloadHidden,
  },
  _state,
};

export default outputObject;
