<template>
  <div :class="['table-plan-cell', plan.name]">
    <div v-if="accessToFeature">
      <webix-ui
        v-if="feature.name !== 'virtual_resources'"
        :class="plan.name"
        :config="checkIcon"
      />
      <div
        v-else
        class="unlimited-item"
      >
        {{ locales('unlimited_locale') }}
      </div>
    </div>
    <div
      v-else
      class="disable-item"
    />
  </div>
</template>

<script>
import MaskParser from '../../../libs/bitwiseMask';

import svgIconForVue from '../../common/svgIconForVue';
import checkIcon from '../../../svg/pricing/check_ic.svg';

const Mask = new MaskParser();

export default {
  components: {

  },
  props: ['plan', 'feature'],
  data() {
    return {
      locales: __,
      checkIcon: svgIconForVue(checkIcon, 24, 24),
    };
  },
  computed: {
    accessToFeature() {
      if (this.feature?.plans) {
        return this.feature.plans[this.plan.name];
      }

      return Mask.checkPermission(this.plan.mask, this.feature.id);
    },
  },

};
</script>

<style scoped src="../less/paymentPlans.less"></style>
<style lang="less">
.table-plan-cell {
  .webix_view {
    cursor: pointer;
  }

  &.business {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #1976D2;
          }
        }
      }
    }
  }

  &.pro {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #9F6000;
          }
        }
      }
    }
  }

  &.enterprise {
    .webix_view {
      .webix_template {
        svg {
          path {
            fill: #00564C;
          }
        }
      }
    }
  }
}
</style>
