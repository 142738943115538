var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.$style["task-settings-buttons"] },
    [
      !_vm.isFieldDisabled("resources_visibility")
        ? [
            _vm.formattedAssignedResources.length
              ? _c("assigned-resources-chips", {
                  staticClass: "vgp-interactive-element",
                  attrs: {
                    resources: _vm.formattedAssignedResources,
                    "task-data": _vm.taskData,
                    "is-active": _vm.isAssignResourcesPopupShown,
                  },
                  on: { click: _vm.showAssignResourcesPopup },
                })
              : _c("vgp-icon-button", {
                  class: _vm.$style["task-settings-buttons__item"],
                  attrs: {
                    icon: { name: "assigned" },
                    size: "middle",
                    bold: "",
                    disabled: _vm.isFieldDisabled("resources"),
                    label: _vm.locales("settings_task_resources_add_new"),
                    colors: _vm.buttonColors,
                    sizes: _vm.buttonLabelSizes,
                    active: _vm.isAssignResourcesPopupShown,
                  },
                  on: { onClick: _vm.showAssignResourcesPopup },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("vgp-icon-button", {
        ref: "newAttachmentButton",
        class: _vm.$style["task-settings-buttons__item"],
        attrs: {
          icon: { name: "attach" },
          size: "middle",
          bold: "",
          label: _vm.locales("settings_task_attachment_add_new"),
          colors: _vm.buttonColors,
          sizes: _vm.buttonLabelSizes,
          active: _vm.isNewAttachmentMenuShown,
          disabled: _vm.isFieldDisabled("attachments"),
        },
        on: { onClick: _vm.showNewAttachmentMenu },
      }),
      _vm._v(" "),
      _vm.isNewAttachmentMenuShown
        ? _c("new-attachment-context-menu", {
            attrs: {
              "position-context": _vm.newAttachmentMenuPosition,
              "task-data": _vm.taskData,
            },
            on: {
              "attachment-created": function ($event) {
                return _vm.$emit("attachment-created")
              },
              close: _vm.hideNewAttachmentMenu,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vgp-icon-button", {
        class: _vm.$style["task-settings-buttons__item"],
        attrs: {
          icon: { name: "project_name" },
          size: "middle",
          bold: "",
          label: _vm.locales("settings_task_dependency_add_new"),
          colors: _vm.buttonColors,
          sizes: _vm.buttonLabelSizes,
          disabled: _vm.isFieldDisabled("dependencies"),
        },
        on: { onClick: _vm.showNewDependencyForm },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style["time-log-button-wrapper"],
            _vm.isFieldDisabled("time_log_pricing") ? "pricing-tooltip" : "",
          ],
          attrs: { "data-feature": "time_log", "data-position": "bottom" },
        },
        [
          _c("vgp-icon-button", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: {
                  handler: _vm.onTimeLogButtonOutsideClick,
                  closeConditional: function () {
                    return _vm.isTimeLogPopupShown
                  },
                },
                expression:
                  "{\n        handler: onTimeLogButtonOutsideClick,\n        closeConditional: () => isTimeLogPopupShown\n      }",
              },
            ],
            ref: "timeLogButton",
            class: [
              _vm.$style["task-settings-buttons__item"],
              _vm.$style["time-log-button"],
            ],
            attrs: {
              icon: { name: "time" },
              size: "middle",
              bold: "",
              disabled: _vm.isFieldDisabled("time_log"),
              label: _vm.locales("settings_task_time_add_new"),
              colors: _vm.buttonColors,
              sizes: _vm.buttonLabelSizes,
              active: _vm.isTimeLogPopupShown,
            },
            on: { onClick: _vm.showTimeLogPopup },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }