var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.select,
    },
    [
      _c("select-button", {
        ref: "contextMenuButton",
        attrs: {
          label: _vm.locales("workload_view_mode"),
          value: _vm.getActive ? _vm.getActive.value : "",
          active: _vm.showMenu,
        },
        on: { onClick: _vm.toggleShow },
      }),
      _vm._v(" "),
      _vm.showMenu
        ? _c("context-menu", {
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return _vm._l(_vm.types, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: [
                            _vm.$style.menu_item,
                            item.id === _vm.getActive.id
                              ? _vm.$style.active
                              : "",
                            item.type === "divider" ? _vm.$style.title : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.itemClicked(item)
                            },
                          },
                        },
                        [
                          item.type === "divider"
                            ? _c("div", { class: _vm.$style.title_text }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.value) +
                                    "\n        "
                                ),
                              ])
                            : _c("div", { class: _vm.$style.text }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.value) +
                                    "\n        "
                                ),
                              ]),
                        ]
                      )
                    })
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1773679605
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }