import Vue from 'vue';

export default {
  toggleSpecialPastePopup(state, flag) {
    state.isOpenPastePopup = flag;
  },
  hasCopiedTask(state, flag) {
    state.hasCopiedTask = flag;
  },
  setCopiedTask(state, data) {
    Vue.set(state, 'copiedTask', data);
  },
  assignCopyData(state, payload) {
    const data = state.copiedTask ? { ...state.copiedTask, ...payload } : { ...payload };

    Vue.set(state, 'copiedTask', { ...data });
  },
  setSpecialPasteConfig(state, config) {
    Vue.set(state, 'specialPasteConfig', { ...config });
  },
  updateConfig(state, updated) {
    Vue.set(state, 'specialPasteConfig', {
      ...state.specialPasteConfig,
      ...updated,
    });
  },
  setTaskMap(state, data) {
    Vue.set(state, 'taskMap', data);
  },
  setSpecialPasteIconId(state, taskId) {
    state.specialPasteIconId = taskId;
  },
  setPopupCoordinates(state, coordinates) {
    Vue.set(state, 'popupCoordinates', coordinates);
  },
  resetStore(state) {
    state.hasCopiedTask = false;
    Vue.set(state, 'copiedTask', null);
    Vue.set(state, 'specialPasteConfig', null);
  },
  insertingProcess(state, flag) {
    state.isInsertingProcess = flag;
  } 
};
