const DEFAULT_LOADER_CONFIG = {
  size: 20,
  line: 'round',
  loading: true,
  thickness: '10%',
  emptyThickness: '10%',
  color: '#FFFFFF',
  emptyColor: '#D0E0F280',
};

export const BUTTON_TYPE_TO_LOADER_CONFIG_MAP = {
  primary: DEFAULT_LOADER_CONFIG,
  'primary-border': {
    ...DEFAULT_LOADER_CONFIG,
    color: '#1565C0',
    emptyColor: '#73A3D94D',
  },
  secondary: {
    ...DEFAULT_LOADER_CONFIG,
    color: '#808080',
    emptyColor: '#9999994D',
  },
  destructive: {
    ...DEFAULT_LOADER_CONFIG,
    emptyColor: '#F8D7D74D',
  },
  'destructive-border': {
    ...DEFAULT_LOADER_CONFIG,
    color: '#DD3636',
    emptyColor: '#EB86864D',
  },
  text: {
    ...DEFAULT_LOADER_CONFIG,
    color: '#1565C0',
    emptyColor: '#73A3D94D',
  },
  'text-grey': {
    ...DEFAULT_LOADER_CONFIG,
    color: '#808080',
    emptyColor: '#9999994D',
  },
  green: {
    ...DEFAULT_LOADER_CONFIG,
    emptyColor: '#D0E0F280',
  },
  'text-green': {
    ...DEFAULT_LOADER_CONFIG,
    color: '#00564C',
    emptyColor: '#CCDDDB',
  },
};
