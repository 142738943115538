var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "button",
      class: [
        "vgp-interactive-element",
        "button",
        _vm.typeClasses,
        _vm.disabled ? "disabled" : "",
        _vm.isLoading ? "loading" : "",
      ],
      style: _vm.size,
      on: { click: _vm.onClick },
    },
    [
      _c("div", { class: "wrapper", style: _vm.margins }, [
        _c(
          "div",
          { class: [_vm.sideClasses, "text-area"] },
          [
            !_vm.isLoading
              ? [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("\n          " + _vm._s(_vm.label) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _vm.icon
                    ? _c(
                        "div",
                        { class: [_vm.iconClass] },
                        [
                          _c("sprite", {
                            attrs: {
                              name: _vm.icon.name,
                              type: _vm.icon.type,
                              size: _vm.icon.size,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _c(
                  "vue-ellipse-progress",
                  _vm._b({}, "vue-ellipse-progress", _vm.loaderConfig, false)
                ),
          ],
          2
        ),
        _vm._v(" "),
        _vm.pricingLabel
          ? _c("div", { staticClass: "pricing-title-container" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v("\n        " + _vm._s(_vm.pricingLabel) + "\n      "),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }