import userProjectConfigModel from '../../../models/userProjectConfigs';
import app from '../../../app';
import helpers from '../helpers';
import projectsModel from '../../../models/projects';
import projectComponent from '../../Project';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from '../../../store/main';
import _ from '../../../libs/lodash';

export function onProjectConfigUpdated(projectId, config) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const isSameProjectOpen = _.includes(ganttIDs, +projectId);

  const projectData = projectsModel.getItem(projectId);
  if (projectData) {
    projectData.config = config || projectData.config;
    projectData.config = userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(projectId, projectData.config);
    projectComponent.onChangeProjectData(projectData);
    app.trigger('collaboration:updatedModels', {});
    if (isSameProjectOpen) {
      app.trigger('change:work:time', true, +projectId);
      app.trigger('settings:days:collaboration', +projectId);
      gantt.blockEvents();
      app.trigger('gantt:reinit');
      gantt.unblockEvents();
    }
  } else {
    const projectTasksData = globalStore.getters['tasksModel/getItem'](projectId);

    projectTasksData.workloadData.durationData = config.durationData;
    globalStore.commit('tasksModel/updateProjectTasksData', projectTasksData);
    gantt.addProjectCalendar(config.durationData, projectId);
  }

}

export function hardRefresh() {
  gantt.blockEvents();
  app.trigger('gantt:reinit');
  gantt.unblockEvents();
}
