var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: { show: _vm.show },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.image_uploader }, [
              _c("div", { class: _vm.$.head }, [
                _c("div", { class: _vm.$.popup_title }, [
                  _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.upload_body }, [
                _vm.croppedSrc
                  ? _c(
                      "div",
                      { class: [_vm.$.inner_box, _vm.$.inner_box__crop_area] },
                      [
                        _c(
                          "div",
                          { ref: "croppWrapper", class: _vm.$.inner_wrapper },
                          [
                            _c("img", {
                              ref: "croppedImg",
                              class: _vm.$.crop_img,
                              attrs: { src: _vm.croppedSrc, alt: "" },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        ref: "dropArea",
                        class: [
                          _vm.$.inner_box,
                          _vm.$.inner_box__upload_area,
                          _vm.$.drop_area,
                        ],
                      },
                      [
                        _c("div", { class: _vm.$.inner_wrapper }, [
                          _c("div", { class: _vm.$.upload_icon }, [
                            _c("img", {
                              attrs: { src: _vm.uploadIcon, alt: "" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.popup_text }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.locales("drop_area_description")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.upload_input }, [
                            _c("input", {
                              ref: "userInput",
                              class: _vm.$.input,
                              attrs: {
                                id: "userInput",
                                name: "file",
                                type: "file",
                                accept: "image/png, image/jpg, image/jpeg",
                              },
                              on: { change: _vm.handleChange },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                class: [_vm.$.popup_btn, _vm.$.upload],
                                attrs: { for: "userInput" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.locales("avatar_upload_button"))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.popup_text }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.locales("drop_area_formats_label") +
                                    ": " +
                                    _vm.formats
                                      .map(function (e) {
                                        return "." + e
                                      })
                                      .join(", ")
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.footer },
                [
                  _c("vgp-button", {
                    attrs: {
                      type: "secondary",
                      small: true,
                      label: _vm.locales("common_cancel"),
                    },
                    on: { onClick: _vm.closeUploader },
                  }),
                  _vm._v(" "),
                  _c("vgp-button", {
                    style: { marginLeft: "12px" },
                    attrs: {
                      small: true,
                      type: "primary",
                      label: _vm.locales("common_save"),
                      disabled: !_vm.file,
                    },
                    on: { onClick: _vm.saveUploadImg },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }