import axios from 'axios';
import app from '../../../app';
import routerHelper from '../../../helpers/router';
import globalStore from '../../../store/main';
import commentsModel from '../../../models/comments2';
import moment from '../../../libs/moment';
import _ from '../../../libs/lodash';
import statusHelper from '../../../helpers/status';
import projectsModel from '../../../models/projects';
import multiViewsModel from '../../../models/multiViewsProjects';

const TIMER_FOR_SPECIAL_PASTE_ICON = 7000;
let timerId = null;

export default {
  initSocketListeners() {
    app.socket.on('copy:tasks', this.afterCopyTasks.bind(this));
    app.socket.on('copy:specialPaste', this.afterSpecialPaste.bind(this));
  },

  onSpecialPasteIconClick(e) {
    const specialPasteIcon = e.target.closest('.special-paste-options');

    if (specialPasteIcon) {
      const taskRow = specialPasteIcon.closest('.gantt_row');
      const taskId = taskRow.getAttribute('task_id');

      this.showSpecialPastePopup(+taskId);
    }
  },

  isAvailablePastePopup() {
    const currentRoute = routerHelper.getCurrentRoute();

    return (currentRoute.name === 'project' || currentRoute.name === 'projectsMultiView') && currentRoute.params.mode === 'gantt';
  },

  setPopupCoordinates(taskId) {
    if (taskId && gantt.isTaskExists(taskId)) {
      const targetNode = document.querySelector(`[task_id="${taskId}"] .column-options-cog.icon-dots`);

      if (targetNode) {
        const coordinates = targetNode.getBoundingClientRect();
        const popupWidth = 292;
        const popupHeight = 450;
        const clientWidth = document.documentElement.clientWidth;
        const popupCoordinates = {
          bottom: `${0}px`,
          left: `${0}px`,
        };

        if (clientWidth - coordinates.right < popupWidth) {
          popupCoordinates.left = `${coordinates.left - popupWidth}px`;
        } else {
          popupCoordinates.left = `${coordinates.right}px`;
        }

        if (coordinates.bottom <= popupHeight + 115) {
          popupCoordinates.top = '116px';
          popupCoordinates.bottom = 'auto';
        } else {
          popupCoordinates.top = 'auto';
          popupCoordinates.bottom = `${-coordinates.bottom - 50}px`;
        }

        globalStore.commit('copyPaste/setPopupCoordinates', popupCoordinates);
      }
    }
  },

  showSpecialPastePopup(taskId = null) {
    const copiedTask = globalStore.getters['copyPaste/copiedTask'];
    const taskID = taskId || copiedTask?.copyRootTaskId;

    globalStore.commit('copyPaste/toggleSpecialPastePopup', true);

    this.hideSpecialPasteIcon(taskID);
    if (timerId) {
      clearTimeout(timerId);
    }
  },

  showSpecialPasteIcon(taskId) {
    const isOpenPopup = globalStore.getters['copyPaste/isOpenPastePopup'];

    if (taskId && !isOpenPopup) {
      globalStore.commit('copyPaste/setSpecialPasteIconId', taskId);
      gantt.refreshTask(taskId);

      timerId = setTimeout(() => {
        this.hideSpecialPasteIcon(taskId);
      }, TIMER_FOR_SPECIAL_PASTE_ICON);
    }
  },

  hideSpecialPasteIcon(taskId) {
    if (taskId) {
      globalStore.commit('copyPaste/setSpecialPasteIconId', null);
      gantt.refreshTask(taskId);
    }
  },

  async afterCopyTasks(data) {
    // const copiedTask = globalStore.getters['copyPaste/copiedTask'];
    const taskMap = JSON.parse(data.taskMap);
    const res = await axios.get('/api/task/copy-settings', { params: { user_id: user.id, project_id: data.newGanttId } });
    const specialPasteConfig = res.data;

    delete data.taskMap;
    delete data.copiedValues;

    globalStore.commit('copyPaste/setTaskMap', taskMap);
    globalStore.commit('copyPaste/setSpecialPasteConfig', { ...specialPasteConfig });
    globalStore.commit('copyPaste/assignCopyData', {
      ...data,
      copyRootTaskId: data.copyRootTaskId,
    });

    await this.afterCopyTasksHandler(data, false);
    this.setPopupCoordinates(data.copyRootTaskId);

    if (specialPasteConfig.show_always_popup) {
      this.showSpecialPastePopup(data.copyRootTaskId);
    } else {
      this.showSpecialPasteIcon(data.copyRootTaskId);
    }
  },

  async afterSpecialPaste(data) {
    this.afterCopyTasksHandler(data, true);
  },

  async afterCopyTasksHandler(data) {
    const rootTaskID = data.copyRootTaskId;
    const multiviewProject = multiViewsModel.getActiveViewData();
    const route = routerHelper.getCurrentRoute();

    // if ((route.name === 'project' && +route.params?.projectId === +data.newGanttId) || multiviewProject?.ganttIDs.includes(data.newGanttId)) {
    //   statusHelper.handlers.showMainSpinner();
    // }

    globalStore.commit('copyPaste/insertingProcess', true);

    const cloneArray = [];

    let parsedResponse = await axios.get('/api/task/after-copy-tasks', { params: { ganttId: data.newGanttId } });

    parsedResponse = parsedResponse.data;

    _.each(parsedResponse.tasksModel.tasks.data, task => {
      task.calendar_id = task.gantt_id;

      const endDate = gantt.calculateEndDate({
        start_date: moment(task.start_date).toDate(),
        duration: task.duration,
        task,
      });

      if (endDate.valueOf() !== moment(task.end_date).valueOf()) {
        task.end_date = endDate;
        cloneArray.push(task);
      }
    });

    let promise = Promise.resolve();

    if (!_.isEmpty(cloneArray)) {
      promise = globalStore.dispatch('tasksModel/updateTasksByEvent', {
        updatedTasks: cloneArray,
        masterType: 'autoscheduling',
      });
    }

    return promise
      .then(async () => {
        globalStore.dispatch('tasksModel/updateProjectTasksData', parsedResponse.tasksModel);
        if (parsedResponse.userCustomColumns) {
          globalStore.commit('columns/updateProject', { projectId: parsedResponse.userCustomColumns.id, updated: { ...parsedResponse.userCustomColumns } });
        }

        globalStore.dispatch('tasksModel/recalculateStartDatesForTasksByGanttId', { ganttId: parsedResponse.tasksModel.id, isChangedCalendar: true });

        rootTaskID && gantt.isTaskExists(rootTaskID) && gantt.showTask(rootTaskID);

        globalStore.dispatch('comments/fetchCommentsByProjectIds', {
          projectIds: data.newGanttId,
          refetch: true,
        });
        globalStore.dispatch('comments/fetchCommentsActions');
        await commentsModel.refreshData();

        globalStore.dispatch('attachments/fetchAttachmentsByProjectIds', {
          projectIds: data.newGanttId,
          refetch: true,
        });
      })
      .then(() => {
        gantt.callEvent('ganttClearAndParse', []);
        gantt.callEvent('tasks:correctDates', [true]);
        gantt.callEvent('setSpecialPasteID', [rootTaskID]);
        // statusHelper.handlers.hideMainSpinner();
        globalStore.commit('copyPaste/insertingProcess', false);
      });
  },
};
