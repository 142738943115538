import app from '../../app';
import icon_close from '../../svg/close.svg';
import moment from '../../libs/moment';
import store from '../../store/main';

import '../common/ganttproWindowPopup';

const __ = window.__;

const innerObject = {

  mainView: {
    id: 'userAccountSubscribe',
  },

  getUserAccountPopup() {
    const plansPeriod = store.getters['profileSettings/plansPeriod'];
    const profileSubscriptionDate = moment(plansPeriod.dateTo).format('DD MMM, YYYY');
    const profileSubscriptionDateTemplate = `<span class='date_label'>${profileSubscriptionDate}</span>`;

    return `
      <div class="user-popup-content">
        <div class="popup-container">
          <!--    HEADER    -->
          <div class="popup-container-header">
            <div class="popup-container-header__title">
                ${__('subscribe_popup_header_title')}
            </div>
            <div class="popup-container-header__icon">
                ${icon_close}
            </div>
          </div>
          <!--    BODY    -->
          <div class="popup-container-body popup-subscribe">
            <div class="popup-container-body__text">
                ${__('subscribe_popup_body_text')}

                <ul class="text_list">
                    <li class="list_item">
                        ${__('subscribe_popup_body_list_item_01')}
                    </li>
                    <li class="list_item">
                        ${__('subscribe_popup_body_list_item_02')}
                        <div class="item_date">(${profileSubscriptionDateTemplate})</div>
                    </li>

                </ul>
            </div>
            <div class="popup-container-body__desc">
                ${__('subscribe_popup_body_desc')}
                <a href="mailto:${__('support_mail')}">${__('support_mail')}</a>
            </div>
          </div>
          <!--    FOOTER   -->
          <div class="popup-container-footer">
            <div class="popup-container-footer__button send">
                ${__('subscribe_popup_footer_button_text_01')}
            </div>
            <div class="popup-container-footer__button cancel">
                ${__('subscribe_popup_footer_button_text_02')}
            </div>
          </div>
        </div>
      </div>
    `;
  },

};

webix.ui({
  view: 'ganttproWindowPopup',
  id: 'userAccountSubscribe',
  css: 'user-account-popup user-subscribe-popup',
  maxWidth: 636,
  maxHeight: 320,
  zIndex: 111,
  head: false,

  body: {
    view: 'template',
    id: 'userAccountSubscribeBody',
    css: 'user-popup-wrapper',
    template() {
      return innerObject.getUserAccountPopup();
    },
    onClick: {
      'popup-container-header__icon': function (e) {
        $$('userAccountSubscribe').hide();
        // $$('userProfilePopup').show();
      },
      cancel(e) {
        $$('userAccountSubscribe').hide();
        // $$('userProfilePopup').show();
      },
      send(e) {
        $$('userAccountSubscribe').hide();
        $$('userAccountSubscribeForm').show();
      },
    },
  },
  on: {
    onHide() {
      app.trigger('popup:hide');
    },
  },
}).hide();

const outputObject = {
  init() {
    $$('userAccountSubscribe').show();
  },
};

app.on('app:start', () => {
  // $$('userAccountPopup').show();
});

export default outputObject;
