var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.role_create], attrs: { id: "role_create_item" } },
    [
      _c(
        "div",
        { class: [_vm.$style.role_create_select] },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.locales("set_default_role_" + _vm.type + "_description")
              )
            ),
          ]),
          _vm._v(" "),
          _c("vgp-dropdown-select", {
            staticClass: "dropdown",
            class: [_vm.$style.vgp_select],
            attrs: {
              "gp-autotest-name":
                "gp_autotest_create_role_dropdown_select_default_role",
              "highlight-active-state": false,
              "show-selected-first": true,
              "show-reset-btn": false,
              selected: _vm.setDefault,
              "drop-list": _vm.data,
              "item-template": "text",
              multiselect: false,
              "fixed-width": 320,
              "need-description": true,
              size: "autoWidth",
              "track-by-text": "title",
              "use-system-tooltip": "",
            },
            on: { selected: _vm.changeSelect },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAccessSubscribe
        ? _c(
            "div",
            { class: [_vm.$style.role_create_button] },
            [
              !_vm.isEditMode && _vm.accessToCreate
                ? _c("vgp-button", {
                    attrs: {
                      id: "gp_autotest_create_role_btn_create_new_role",
                      type: "primary",
                      label: _vm.locales("create_new_role"),
                      icon: { name: "plus-1", size: 20, type: "bold" },
                      "icon-side": "left",
                      small: true,
                    },
                    on: { onClick: _vm.togglePopup },
                  })
                : _vm.isEditMode
                ? _c(
                    "div",
                    { class: [_vm.$style.buttons_group] },
                    [
                      _c("vgp-button", {
                        attrs: {
                          id: "gp_autotest_create_role_btn_cancel_changes_in_role",
                          type: "secondary",
                          label: _vm.locales("cancel_role_change"),
                          "icon-side": "left",
                          small: true,
                        },
                        on: { onClick: _vm.cancelRoleEdit },
                      }),
                      _vm._v(" "),
                      _c("vgp-button", {
                        attrs: {
                          id: "gp_autotest_create_role_btn_save_changes_in_role",
                          type: "primary-border",
                          label: _vm.locales("confirm_role_change"),
                          "icon-side": "left",
                          small: true,
                          disabled: !_vm.hasChanges,
                        },
                        on: { onClick: _vm.confirmRoleEdit },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowForm
        ? _c("core-popup", {
            attrs: {
              id: "gp_autotest_popup_create_new_role",
              "close-icon": true,
              show: _vm.isShowForm,
              title: _vm.locales("create_new_role_" + _vm.type + "_title"),
            },
            on: { onClickCancel: _vm.togglePopup },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("ValidationObserver", {
                        ref: "form",
                        attrs: { tag: "div" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var handleSubmit = ref.handleSubmit
                                return [
                                  _c(
                                    "div",
                                    {
                                      class: [_vm.$style.add_form],
                                      style: { width: "392px" },
                                    },
                                    [
                                      _c("vgp-label-slot", {
                                        class: _vm.$style.form_line,
                                        attrs: {
                                          label: _vm.locales(
                                            "create_new_role_" +
                                              _vm.type +
                                              "_form_title"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _c("ValidationProvider", {
                                                    ref: "titleValidationProvider",
                                                    attrs: {
                                                      rules: "required|min:1",
                                                      vid: "title",
                                                      "custom-messages": {
                                                        required: _vm.locales(
                                                          "error_required_field"
                                                        ),
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("vgp-input", {
                                                                ref: "title",
                                                                class: [
                                                                  "public-url-form-input",
                                                                ],
                                                                attrs: {
                                                                  "gp-autotest-name":
                                                                    "gp_autotest_popup_create_new_role_input_name_role",
                                                                  "is-error":
                                                                    !!errors[0],
                                                                  placeholder:
                                                                    _vm.locales(
                                                                      "create_new_role_" +
                                                                        _vm.type +
                                                                        "_placeholder"
                                                                    ),
                                                                  small: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.title,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.title =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "title",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              errors[0]
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      class: [
                                                                        _vm
                                                                          .$style
                                                                          .error,
                                                                      ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                  " +
                                                                          _vm._s(
                                                                            errors[0]
                                                                          ) +
                                                                          "\n                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("vgp-label-slot", {
                                        attrs: {
                                          label: _vm.locales(
                                            "create_new_role_" +
                                              _vm.type +
                                              "_form_list_inherit"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _c("ValidationProvider", {
                                                    ref: "roleIdValidationProvider",
                                                    attrs: {
                                                      rules: "required",
                                                      vid: "roleId",
                                                      "custom-messages": {
                                                        required: _vm.locales(
                                                          "error_required_field"
                                                        ),
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "vgp-dropdown-select",
                                                                {
                                                                  ref: "roleId",
                                                                  staticClass:
                                                                    "dropdown",
                                                                  class: [
                                                                    _vm.$style
                                                                      .vgp_select,
                                                                  ],
                                                                  attrs: {
                                                                    "gp-autotest-name":
                                                                      "gp_autotest_popup_create_new_role_dropdown_select_base_role",
                                                                    "highlight-active-state": false,
                                                                    "show-selected-first": true,
                                                                    "show-reset-btn": false,
                                                                    selected:
                                                                      _vm.setDefaultForCreate,
                                                                    "drop-list":
                                                                      _vm.selectList,
                                                                    "item-template":
                                                                      "text",
                                                                    multiselect: false,
                                                                    "track-by-text":
                                                                      "title",
                                                                    "fixed-width": 320,
                                                                    size: "autoWidth",
                                                                    "need-description": true,
                                                                    "is-error":
                                                                      !!errors[0],
                                                                  },
                                                                  on: {
                                                                    selected:
                                                                      _vm.changeSelectRole,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.roleId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.roleId =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "roleId",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              errors[0]
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      class: [
                                                                        _vm
                                                                          .$style
                                                                          .error,
                                                                      ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                  " +
                                                                          _vm._s(
                                                                            errors[0]
                                                                          ) +
                                                                          "\n                "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: [_vm.$style.form_line_buttons],
                                        },
                                        [
                                          _c("vgp-button", {
                                            attrs: {
                                              id: "gp_autotest_popup_create_new_role_btn_cancel",
                                              type: "secondary",
                                              small: true,
                                              label: _vm.locales(
                                                "role_create_cancel_btn"
                                              ),
                                            },
                                            on: { onClick: _vm.togglePopup },
                                          }),
                                          _vm._v(" "),
                                          _c("vgp-button", {
                                            attrs: {
                                              id: "gp_autotest_popup_create_new_role_btn_ok",
                                              type: "primary",
                                              small: true,
                                              label:
                                                _vm.locales(
                                                  "role_create_ok_btn"
                                                ),
                                            },
                                            on: {
                                              onClick: function ($event) {
                                                return handleSubmit(
                                                  _vm.submitForm
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          961930741
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2755831969
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }