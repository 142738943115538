<template>
  <div
    class="container-description"
  >
    <div class="main-title">
      <div class="text">
        {{ title }}
      </div>
      <div
        v-if="removeIntegrationButton && !tablePreloder"
        class="button"
        @click="removeIntegration"
      >
        <div class="button-text">
          {{ locales("integration_msteams_settings_page_remove") }}
        </div>
      </div>
      <div
        v-if="!removeIntegrationButton && !tablePreloder"
        class="button"
        @click="disableIntegration"
      >
        <div class="button-text">
          {{ locales("integration_msteams_settings_page_disable") }}
        </div>
      </div>
    </div>
    <div class="description">
      {{ description }}
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'title',
    'description',
    'removeIntegration',
    'removeIntegrationButton',
    'tablePreloder',
    'disableIntegration',
  ],
  data() {
    return {
      locales: __,
    };
  }
};
</script>
