<template>
  <div>
    <right-popup
      :show="isShow"
      :border-left="false"
      :border-top="false"
      :width="600"
      :exclude-footer="true"
    >
      <template #body>
        <div
          v-click-outside="onClickOutside"
          :class="$.resource_card"
        >
          <div
            :class="[
              $.resource_card_head,
              isMaterial ? $.resource_card_head__material : '',
              resourceData.description || descriptionIsEditable || descriptionIsLoading ? $.has_description : '',
            ]"
          >
            <div :class="$.profile">
              <div
                ref="avatar"
                v-click-outside="onClickAvatarOutside"
                :class="$.avatar"
              >
                <vgp-avatar
                  :src="resourceData.photo"
                  :custom-size="100"
                />
                <div
                  v-if="isMaterial || (!isMaterial && meIsAccountOwner && !isPending)"
                  :class="[$.avatar_edit, !isShowColorsDropdown ? $.with_hover : '']"
                  @click="onClickAvatar"
                >
                  <sprite
                    :class="$.avatar_edit_icon"
                    name="pensil"
                  />
                  <div :class="$.avatar_edit_text">
                    {{ locales('common_avatar_edit') }}
                  </div>
                </div>
                <context-menu
                  v-if="isShowColorsDropdown"
                  :position="positionColorsDropdown"
                >
                  <template #body>
                    <vgp-colors-list
                      :colors="colors"
                      :selected="resourceData.colorId ? [resourceData.colorId] : []"
                      @selectColors="onChangeResourceColor"
                    />
                  </template>
                </context-menu>
              </div>
              <div :class="$.info">
                <div :class="$.info_name">
                  <vgp-row-input
                    :key="nameGeneratedKey"
                    :value="resourceData.name"
                    :readonly="resourceData.email ? true : false"
                    :bold="true"
                    :fontSize="16"
                    gp-autotest-name="gp_autotest_resource_card_input_resource_name"
                    @onChange="onChangeResourceName"
                  />
                </div>
                <div
                  id="gp_autotest_resource_card_copy_email"
                  :class="[$.info_email, resourceData.email && !descriptionIsEditable ? $.with_hover : '']"
                  @click="onCopyEmail"
                >
                  <input
                    v-if="!isMaterial"
                    ref="email"
                    :class="$.hidden_email_input"
                    :value="resourceData.email"
                  >
                  <div :class="$.text">
                    {{ resourceData.email ? resourceData.email : locales('common_virtual_resources') }}
                  </div>
                  <div
                    v-if="!isMaterial"
                    :class="$.copy_icon"
                  >
                    <sprite
                      name="copy"
                      color="#808080"
                    />
                  </div>
                </div>
                <div :class="$.info_description">
                  <vgp-button
                    v-if="!resourceData.description && !descriptionIsEditable && !descriptionIsLoading"
                    :class="$.add_description_btn"
                    :label="locales('common_add_description')"
                    type="text"
                    small
                    @onClick="descriptionIsEditable = true"
                  />
                  <vgp-responsive-textarea
                    v-if="resourceData.description || descriptionIsEditable || descriptionIsLoading"
                    ref="descriptionTextArea"
                    :key="descriptionGeneratedKey"
                    :class="$.description_text"
                    :value="resourceData.description || ''"
                    :start-focus="!resourceData.description"
                    :height="descriptionIsEditable ? 62 : 44"
                    :is-error="isDescriptionError"
                    @onFocus="descriptionIsEditable = true"
                    @onBlur="onBlurResourceDescription"
                    @onChange="onChangeResourceDescription"
                    @onResize="onResizeResourceDescription"
                    @onKeyup="onKeyupDescription"
                  />
                  <div
                    v-if="descriptionIsEditable"
                    :class="[
                      $.description_counter,
                      isDescriptionError ? $.description_counter__error : '',
                      descriptionIsEditable && descriptionCount > 0 ? $.description_counter__active : '',
                    ]"
                  >
                    <span>{{ descriptionCount }}</span>/{{ maxDescriptionCount }}
                  </div>
                </div>
              </div>
            </div>
            <div :class="$.panel">
              <div
                v-if="!isMaterial"
                :class="$.panel_role_dropdown"
              >
                <vgp-label-slot :label="locales('common_account_role')">
                  <template #body>
                    <dropdown-select
                      v-if="resourceData.accessToChangeRole && !resourceData.isOwner && !actionsDisabled"
                      :drop-list="accountRoles"
                      :multiselect="false"
                      :show-selected-first="false"
                      :show-reset-btn="false"
                      :highlight-active-state="false"
                      item-template="text"
                      track-by-text="title"
                      outline="always"
                      :fixed-width="320"
                      :need-description="true"
                      :size="'autoWidth'"
                      gp-autotest-name="gp_autotest_resource_card_dropdown_select_common_account_role"
                      :selected="accountRoles.find(role => role.id === resourceData.accountRoleId)"
                      @selected="$event => onChangeAccountRole($event)"
                    />
                    <vgp-input
                      v-else
                      :value="resourceData.role.title"
                      :small="true"
                      :is-disabled="true"
                      :class="$.role_description"
                      gp-autotest-name="gp_autotest_resource_card_input_role_title"
                    />
                  </template>
                </vgp-label-slot>
              </div>
              <div
                v-if="resourceCalendarAccess"
                :class="[$.panel_calendar, !pricingAccessToResourceCalendar ? 'pricing-tooltip' : '']"
                data-y="-4"
                data-position="top"
                data-feature="personal_calendar"
              >
                <vgp-button
                  id="gp_autotest_resource_btn_calendar"
                  :class="$.panel_calendar_btn"
                  :disabled="!pricingAccessToResourceCalendar"
                  :label="locales('common_view_calenar')"
                  type="text"
                  :icon="{ name:'calendar', size: 24, type: 'regular' }"
                  icon-side="left"
                  :small="true"
                  @onClick="openCalendar"
                />
              </div>
            </div>
            <div :class="$.icon_buttons">
              <icon-button
                v-if="resourceData.acсessToDelete && !resourceData.isJira"
                id="gp_autotest_resource_btn_delete_resource"
                :icon="{ name:'delete' }"
                size="large"
                @onClick="$emit('onDeleteResource', resourceData)"
              />
              <icon-button
                id="gp_autotest_resource_btn_close"
                :style="{marginLeft: 6 + 'px'}"
                :icon="{ name:'close-1', type:'bold' }"
                size="large"
                @onClick="onClose"
              />
            </div>
          </div>
          <div :class="$.resource_card_body">
            <vgp-tabs
              :class="$.tabs"
              :tabs="tabs"
              active-by="type"
              :active="tabs[0]"
              :small="true"
              gp-autotest-name="gp_autotest_resource_card_tabs"
            />
            <div
              ref="panel"
              :class="$.panel"
            >
              <input-search
                :style="{width: '290px'}"
                :placeholder="locales('common_search_by_name')"
                :value="searchValue"
                gp-autotest-name="gp_autotest_resource_card_input_search_by_name"
                @onInput="onSearch"
              />
              <div :class="$.panel_btn">
                <vgp-button
                  type="primary"
                  :label="locales('add_to_projects')"
                  :icon="{
                    name: 'add-user',
                    size: '24',
                    type: 'regular'
                  }"
                  icon-side="left"
                  :small="true"
                  @onClick="isShowNewProjectsPopup = true"
                />
              </div>
            </div>
            <div :style="{padding: '16px'}">
              <resource-projects
                ref="projectTable"
                :is-material="isMaterial"
                :projects="resourceProjects"
                :project-roles="projectRoles"
                :pricing-access-to-cost-settings="pricingAccessToCostSettings"
                @onChangeCostType="onChangeCostType"
                @onChangeCost="onChangeCost"
                @onChangeRight="onChangeRight"
                @onDeleteFromProject="onDeleteFromProject"
                @onChangeProjectRole="onChangeProjectRole"
              />
            </div>
            <div
              v-if="!resourceProjects.length"
              :class="$.stub_block"
              :style="{height: stubHeight, }"
            >
              <div :class="$.wrapper">
                <div
                  ref="picture"
                  :class="$.img"
                  v-html="stubImage"
                />
                <div :class="$.description">
                  {{ searchValue !== '' ? locales('no_search_result') : locales('no_project_description') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </right-popup>
    <add-to-projects-popup
      :is-show="isShowNewProjectsPopup"
      :project-roles="projectRoles"
      :projects="anotherProjects"
      :resource-data="resourceData"
      @onApply="assignToProjects"
      @onClose="isShowNewProjectsPopup = false"
    />
  </div>
</template>

<script>
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import VgpButton from '../../../common/VueComponents/globalButton/vgpButton.vue';
import VgpLabelSlot from '../../../common/VueComponents/label/vgpLabelSlot.vue';
import RightPopup from '../../../common/VueComponents/popups/rightPopup/rightPopup.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import sprite from '../../../common/VueComponents/VueIcon/svgSprite.vue';
import resourceCalendar from '../../../calendar/resourceCalendar';
import inputSearch from '../inputSearch/inputSearch.vue';
import stubImage from '../../../../svg/search_no_result_pic.svg';
import IconButton from '../../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import VgpAvatar from '../../../common/VueComponents/avatar/vgpAvatar.vue';
import VgpRowInput from '../../../common/VueComponents/vInput/vgpRowInput.vue';
import ResourceProjects from '../resourceCardProjects/resourceProjects.vue';
import VgpColorsList from '../../../common/VueComponents/colorPalette/colorsList/colorsList.vue';
import VgpResponsiveTextarea from '../../../common/VueComponents/vInput/vgpResponsiveTextarea.vue';
import addToProjectsPopup from '../popups/addToProjectsPopup.vue';

const OUTSIDE_SELECTORS = [
  '.checkbox-overlay',
  '.core_popup',
  '.core_popup_block',
  '.js-drop-item',
  '.text-start',
  '.calendar-layout',
  '.pricing-mark',
  '.pricing-tooltip-closer',
  '.global-popup',
  '.Vue-Toastification__container',
  '.vgp-interactive-element',
];

export default {
  name: 'ResourceCard',
  components: {
    sprite,
    VgpInput,
    VgpButton,
    RightPopup,
    VgpLabelSlot,
    DropdownSelect,
    inputSearch,
    IconButton,
    VgpAvatar,
    VgpRowInput,
    ResourceProjects,
    VgpColorsList,
    VgpResponsiveTextarea,
    addToProjectsPopup,
  },
  inject: ['allProjects', 'checkOpenedPopups'],
  props: {
    resourceData: {
      type: Object,
      required: false,
      default() { return {}; },
    },
    isShow: { type: Boolean, required: true, default: false },
    accountRoles: { type: Array, required: true, default: () => [] },
    projectRoles: { type: Array, required: true, default: () => [] },
    meIsAccountOwner: { type: Boolean, required: true, default: false },
    actionsDisabled: { type: Boolean, required: false, default: false },
    // removeResourcesAccess: { type: Boolean, required: true, default: false },
    resourceCalendarAccess: { type: Boolean, required: true, default: false },
    pricingAccessToResourceCalendar: { type: Boolean, required: true, default: false },
    pricingAccessToCostSettings: { type: Boolean, required: true, default: false },
  },
  emits: {
    onClose: Boolean,
    onChangeDescription: String,
    onChangeRole: Object,
    onChangeCost: Object,
    onChangeRight: Object,
    onDeleteResource: Object,
    onDeleteFromProject: Object,
    onChangeCostType: Object,
    updateResource: Object,
    openProfileCard: Number,
    onSelectProjectRole: Object,
    onChangeAccountRole: Object,
    onChangeProjectRole: Object,
    showAvatarUploader: null,
    assignToProjects: Array,
  },
  data() {
    return {
      nameGeneratedKey: null,
      descriptionGeneratedKey: null,
      locales: __,
      tabs: [
        {
          type: 'project',
          title: __('common_project_tab'),
        },
      ],
      searchValue: '',
      resourceName: null,
      stubHeight: null,
      stubImage,
      isShowColorsDropdown: false,
      positionColorsDropdown: null,
      descriptionIsEditable: false,
      descriptionIsLoading: false,
      isDescriptionError: false,
      descriptionCount: 0,
      isShowNewProjectsPopup: false,
      maxDescriptionCount: 200,
    };
  },
  computed: {
    colors() {
      return this.$store.getters['teamSetting/colors'].data;
    },
    isMaterial() {
      return !this.resourceData.userId;
    },
    isPending() {
      return this.resourceData.invite?.status || this.resourceData.invite?.hasOldTeam;
    },
    resourceProjects() {
      return this.resourceData?.resourceProjects.filter(pr => {
        const projectName = pr.name || '';

        return projectName.toLowerCase().startsWith(this.searchValue.toLowerCase()) && !pr.isArchived;
      });
    },
    isCurrentUser() {
      return +this.resourceData.userId === +user.id;
    },
    resourceId() {
      return this.resourceData.id;
    },
    anotherProjects() {
      const filteredAnotherProjects = this.allProjects().filter(pr => !this.resourceData.resourceProjects.find(project => project.projectId === pr.gantt_id) && !pr.is_archived);

      if (this.isMaterial) {
        return filteredAnotherProjects.filter(pr => !pr.is_jira);
      }

      return filteredAnotherProjects;
    },
  },
  watch: {
    resourceId() {
      this.searchValue = '';
      this.resourceName = this.resourceData.name;
      this.descriptionCount = this.resourceData.description ? this.resourceData.description.length : 0;
      this.isDescriptionError = this.descriptionCount > this.maxDescriptionCount;
      this.descriptionIsEditable = false;
      if (this.$refs.descriptionTextArea) {
        this.$refs.descriptionTextArea.isExpanded = false;
        this.$refs.descriptionTextArea.isFocus = false;
      }
    },
    'resourceData.resourceProjects': function () {
      this.searchValue = '';
    },
  },
  created() {
    this.init();
  },
  mounted() {
    const textarea = this.$refs.textarea;

    if (textarea) {
      if (textarea.scrollHeight > 54) {
        this.isShowExpandButton = true;
      }
    }

    window.addEventListener('resize', this.onResize);
    setTimeout(() => this.calcPicHeight(), 0);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.resourceName = this.resourceData.name;
      this.descriptionCount = this.resourceData.description ? this.resourceData.description.length : 0;
      this.isDescriptionError = this.descriptionCount > this.maxDescriptionCount;
    },
    onResize() {
      this.calcPicHeight();
    },
    showEditIcon() {
      this.showAvatarEdit = true;
    },
    calcPicHeight() {
      const pic = this.$refs.picture;
      const searchBlock = this.$refs.panel;
      const coords = searchBlock.getBoundingClientRect();

      if (pic) {
        if (document.documentElement.clientHeight < 620) {
          pic.style.display = 'none';
        } else {
          pic.style.display = 'block';
        }
      }

      this.stubHeight = `${document.documentElement.clientHeight - coords.bottom - 30 - 64}px`;
    },
    onClickAvatarOutside() {
      this.isShowColorsDropdown = false;
    },
    onClickAvatar() {
      if (this.isMaterial) {
        this.isShowColorsDropdown = !this.isShowColorsDropdown;
        if (this.isShowColorsDropdown) {
          this.positionColorsDropdown = this.$refs.avatar.getBoundingClientRect();
        }
      } else {
        this.$emit('showAvatarUploader');
      }
    },
    onChangeResourceColor(data) {
      this.$store.dispatch('resourcesModel/updateResource', {
        resourceId: this.resourceData.id,
        update: {
          name: this.resourceData.name,
          colorId: data[0].id,
        },
      });
    },
    onBlurResourceDescription() {
      if (this.isDescriptionError) return;
      setTimeout(() => {
        this.descriptionIsEditable = false;
      });
    },
    async onChangeResourceDescription(value) {
      if (this.isDescriptionError) return;
      this.descriptionIsLoading = true;

      const description = value.trim();

      if (this.resourceData.description === description || (!this.resourceData.description && !description)) {
        this.descriptionIsLoading = false;

        return;
      }

      await this.$store.dispatch('resourcesModel/updateResource', {
        resourceId: this.resourceData.id,
        update: {
          description,
        },
      });

      this.descriptionIsLoading = false;
    },
    onKeyupDescription(value) {
      this.isDescriptionError = false;
      this.descriptionCount = value.length;
      this.isDescriptionError = this.descriptionCount > this.maxDescriptionCount;
    },
    onResizeResourceDescription() {
      setTimeout(() => {
        this.$refs.projectTable?.calcHeight();
      });
    },
    async onCopyEmail() {
      if (!this.resourceData.email) {
        return;
      }

      const email = this.$refs.email;

      await email.select();
      await document.execCommand('copy');
      this.$toast.info(__('public_lick_copied'));
    },
    onClose() {
      this.searchValue = '';
      this.$emit('onClose');
    },
    openCalendar() {
      resourceCalendar.init(this.resourceData.id);
    },
    onSearch(value) {
      this.searchValue = value;
    },
    onClickOutside(e) {
      const tableCell = e.target.closest('.table-cell');
      let resourceId = null;

      if (tableCell && tableCell?.dataset?.id) {
        resourceId = tableCell.dataset.id;
        this.$emit('openProfileCard', +resourceId);

        return;
      }

      if (OUTSIDE_SELECTORS.some(selector => e.target.closest(selector)) || this.checkOpenedPopups()) return;

      this.onClose();
    },
    onChangeResourceName(name) {
      const isMach = name.trim() === this.resourceData.name;
      const isEmpty = name.trim() === '';
      const isLong = name.length >= 255;

      if (isMach || isEmpty) {
        this.nameGeneratedKey = (Math.random() + 1).toString(36).substring(2);

        return;
      }

      if (isLong) {
        this.$toast.warning(__('change_resource_name_is_long_name'));
        this.nameGeneratedKey = (Math.random() + 1).toString(36).substring(2);

        return;
      }
      this.$emit('updateResource', {
        resourceId: this.resourceData.id,
        name,
        colorId: this.resourceData.colorId,
      });
    },
    onChangeAccountRole(data) {
      this.$emit('onChangeAccountRole', {
        roleId: data[0].id,
        resource: this.resourceData,
      });
    },
    onChangeCostType(data) {
      data.resourceId = this.resourceData.id;
      this.$emit('onChangeCostType', data);
    },
    onChangeCost(data) {
      data.resourceId = this.resourceData.id;
      this.$emit('onChangeCost', data);
    },
    onChangeRight(data, type) {
      this.$emit('onChangeRight', { userId: this.resourceData.user_id, project: data }, type);
    },
    onDeleteFromProject(projectData) {
      this.$emit('onDeleteFromProject', {
        resource: this.resourceData,
        project: projectData,
      });
    },
    onChangeProjectRole(data) {
      data.resource = this.resourceData;

      this.$emit('onChangeProjectRole', data);
    },
    async assignToProjects(data) {
      await this.$emit('assignToProjects', data);

      if (this.isMaterial) {
        this.$toast.success(__('material_assign_to_projects_done'));
      } else {
        this.$toast.success(__('assign_to_projects_done'));
      }
    },
  },
};
</script>

<style module='$' src='./resourceCard.less'></style>
