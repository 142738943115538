import app from "./../app";
import _ from "./../libs/lodash";

import projectsModel from "./projects";
import multiviewsModel from "./multiViewsProjects";
import routerHelper from "../helpers/router";
import ganttViewModel from "./ganttViewModel";
import constants from '../helpers/constants'

const __ = window.__;

const filters = new webix.DataCollection();

const empty = {
  taskType: "",
  status: "",
  assignee: "",
  priority: "",
  reporter: "",
  startDate: {
    start: "",
    end: ""
  },
  rangeDate: {
    start: "",
    end: ""
  },
  endDate: {
    start: "",
    end: ""
  },
  creationDate: {
    start: "",
    end: ""
  },
  color: "",
  text: "",
  overdue: ""
};

let filtersData = [];
let activeFilters = {};

filters.isSetFilter = function (filterData) {
  return filterData && filterData.options && (
    filterData.options.text ||
    filterData.options.taskType || filterData.options.type ||
    filterData.options.status ||
    filterData.options.assignee ||
    filterData.options.priority ||
    filterData.options.reporter ||
    (filterData.options.startDate && (filterData.options.startDate.start || filterData.options.startDate.end)) ||
    (filterData.options.rangeDate && (filterData.options.rangeDate.start || filterData.options.rangeDate.end)) ||
    (filterData.options.endDate && (filterData.options.endDate.start || filterData.options.endDate.end)) ||
    (filterData.options.creationDate && (filterData.options.creationDate.start || filterData.options.creationDate.end)) ||
    filterData.options.color ||
    filterData.options.customColumns ||
    filterData.options.overdue ||
    ( Array.isArray(filterData.options.ganttId) && filterData.options.ganttId.length)

  );
};

filters.addFilterByActiveProject = function (viewDataID, filterOptions) {
  this.add({
    id: viewDataID,
    options: filterOptions,
    ganttData: []
  });
};

filters.updateFilterByActiveProject = function (viewDataID, filterOptions) {
  if (!viewDataID) return;

  if ( this.getItem(viewDataID)) this.updateItem(viewDataID, { options: filterOptions });    
  else this.addFilterByActiveProject(viewDataID, filterOptions);
  
  activeFilters[viewDataID] = filterOptions;
  
};

filters.updateFoundDataByFilter = function (projectId, ganttData) {
  if (projectId && this.getItem(projectId)) {
    this.updateItem(projectId, {
      ganttData: ganttData
    });
  }

  filters.manualUpdateActiveFiltersGanttData(projectId, ganttData);
};

// filters.updateTaskInFilterMode = function (projectId, taskData) {
//   var filterData = this.getItem(projectId);
//
//   if (projectId && filterData) {
//     filterData.ganttData.data.map(function (task) {
//       if (task.id === taskData.id) {
//         task = taskData;
//       }
//     });
//
//     this.updateFoundDataByFilter(projectId, filterData.ganttData);
//   }
// };

filters.clearFilterByActiveProject = function (projectId) {
  if (projectId) {
    this.getItem(projectId) && this.remove(projectId);
    activeFilters[projectId] = empty;
  }   
};

filters.getFilterDataByActiveProject = function (projectId) {
  let projectActiveFilter = activeFilters[projectId];
  let item = this.getItem(projectId);

  if (item) {
    return item;
  }

  if (projectActiveFilter) {
    return {
      ganttData: projectActiveFilter.ganttData || [],
      id: projectId,
      options: projectActiveFilter,
    };
  }

  if (app.config.mode.isLink) {
    const options = !_.isEmpty(GT.ganttData.filter) ? GT.ganttData.filter.filter : empty;

    return {
      ganttData: [],
      id: GT.ganttData.project.id,
      options,
    };
  }

  return null;
};

filters.getAllFilters = function () {
  return filtersData;
};

filters.getActiveFilter = function (type, viewID) {
  let obj = {};

  if (type === 'project' || type === 'multiview') {
    obj = activeFilters[viewID];
    // obj = this.getItem(viewID);
  }
  else if (type === 'workload' || type === 'list' || type === 'calendarview') { 
    obj = viewID ? activeFilters[type][viewID] : activeFilters[type].common;
  }
  else {
    obj = activeFilters[type];
  }

  // old strange code
  // if (obj) {
  //   obj.filter = _.assign({}, empty, obj.filter);
  // }

  return obj;
};

filters.getFilterById = function (filterId) {
  var obj = _.find(filtersData, function (filter) {
    return filter.id === +filterId;
  });

  if (obj) {
    obj.filter = _.assign({}, empty, obj.filter);
  }

  return _.cloneDeep(obj);
};

filters.getFilterByName = function (name, projectId) {
  return _.find(filtersData, function (filter) {
    return filter.project_id === projectId && filter.name === name;
  });
};

filters.getFiltersForRichSelect = function () {
  return [{
    id: '0',
    value: __("filter_current_filter")
  }].concat(_.map(filtersData, function (item) {
    return {
      id: item.id,
      value: item.name
    };
  }));
};

filters.getFiltersByActiveProjectForRichSelect = function (projectId) {
  return [{
    id: '0',
    value: __("filter_current_filter")
  }].concat(_.filter(filtersData, function (item) {
    return item.project_id === projectId;
  }).map(function (item) {
    return {
      id: item.id,
      value: item.name
    };
  }));
};

filters.getSavedFilters = function (type, viewID) {
  const savedFilters = _.filter(filtersData, filterData => {
    if (type === 'project') {
      return +filterData.project_id === +viewID && filterData.type === type;
    }
    else if (type === 'multiview') {
      return +filterData.multiview_id === +viewID && filterData.type === type;
    }
    else if (type === 'workload' || type === 'list' || type === 'calendarview') {
      const checkID = +filterData.project_id || +filterData.multiview_id;
      return checkID ? checkID === +viewID && filterData.type === type : !viewID && filterData.type === type;
    }
    else {
      return filterData.type === type;
    }
  });

  return savedFilters.map((item) => _.assignIn({}, {id: item.id, value: item.name }));
};

filters.updateFilterName = function (id, name) {
  return webix.ajax().put("/api/filters/" + id, { name: name })
    .then(function (res) {
      const data = res.json();
      let filter = _.find(filtersData, {id: id});
      filter.name = name;

      app.trigger('update:saved:filter:name', id, name);
    })
    .fail(function (err) {
      console.error(err);
    })
};

filters.fetchData = function () {
  return webix.ajax().get("/api/filters/")
    .then(function (res) {
      var data = res.json();

      filtersData = _.map((data || []).slice(), function (item) {
        item.filter = _.assign({}, empty, item.filter);
        return item;
      });

      app.trigger("filter:model:change", filtersData);
    });
};

filters.fetchActiveFilters = function () {
  return webix.ajax()
    .get("/api/filters/active")
    .then(function (res) {
      activeFilters = res.json();

      if (activeFilters.workload === undefined) {
        activeFilters.workload = {};
      }
      if (activeFilters.list === undefined) {
        activeFilters.list = {};
      }
      if (activeFilters.calendarview === undefined) {
        activeFilters.calendarview = {};
      }
    });
};

filters.deleteActiveFilter = function (projectID) {
  delete activeFilters[projectID];
};

filters.manualUpdateActiveFilters = function (data) {
  if (!data) {
    return true;
  } 

  activeFilters = data; 
  
  if (activeFilters.workload === undefined) {
    activeFilters.workload = {};
  }

  if (activeFilters.list === undefined) {
    activeFilters.list = {};
  }

  app.trigger("filter:model:change", data);
};

filters.manualUpdateActiveFiltersGanttData = function (ganttID, ganttData) {
  if (!ganttData) {
    return true;
  }

  if (!activeFilters[ganttID]) {
    activeFilters[ganttID] = {};
  }

  activeFilters[ganttID].ganttData = ganttData;
};

filters.saveFilter = function (filterData) {
  return webix.ajax()
    .post("/api/filters/add", filterData)
    .then(function (res) {
      var data = res.json();

      data.filter = _.assign({}, empty, data.filter);

      filtersData.push(data);

      app.trigger("filter:model:change", filtersData);
      app.trigger("filter:change:current", data.id);  

      return data;
    });
};

filters.removeFilter = function (filterId) {
  return webix.ajax()
    .del("/api/filters/" + filterId)
    .then(function (res) {
      filtersData = _.filter(filtersData, function (filter) {
        return filter.id !== filterId;
      });

      app.trigger("filter:model:change", filtersData);
    });
};

filters.updateActiveFilter = function (type, filterData, viewData) {
  if (app.config.mode.isLink || app.config.mode.isExport) {
    return;
  }
 
  let data = { filter: filterData, type, project_id: null,  multiview_id: null };

  if (viewData) {
    if (viewData.project_id) data.project_id = viewData.project_id;
    if ( viewData.multiview_id) data.multiview_id = viewData.multiview_id; 
  }
   
  return webix.ajax()
    .post("/api/filters/active", data)
    .then(function (res) {
      if (type === 'project') {
        activeFilters[data.project_id] = filterData; 
      }
      else if (type === 'multiview') {
        activeFilters[data.multiview_id] = filterData;
      }
      else if (type === 'workload' || type === 'list' || type === 'calendarview') {
        let projectId = viewData.project_id || viewData.multiview_id || 'common'
        activeFilters[type][projectId] = filterData;
      } 
      else {
        activeFilters[type] = filterData;
      }
    });
};

filters.removeJiraOptFromFilters = function(){
  let defaultIds = [ ...constants.STATUSES.map(i=> i.id), ...constants.PRIORITIES.map(i=> i.id) ];

  let jiraProjectsIds = projectsModel.getAllJiraProjects().map( i => i.id);
  let jiraMultiviewIds = multiviewsModel.getAllMultiviewsWithJira().map( i => i.id); 
  
  let parapms = { delete: [], update: [] };
  
  if(!jiraProjectsIds.length)
    return;
     
  parapms.delete = getfilterIdsWithJiraOpt(); 

  parapms.update.push( 
    ...getUpdatedActiveFilters( {filters: activeFilters, type: 'project', projectIds: jiraProjectsIds, project: true} ),
    ...getUpdatedActiveFilters( {filters: activeFilters, type: 'multiview', projectIds: jiraMultiviewIds, multiview: true} ),

    ...getUpdatedActiveFilters( {filters: activeFilters.list, type: 'list', projectIds: jiraProjectsIds, project: true} ),
    ...getUpdatedActiveFilters( {filters: activeFilters.list, type: 'list', projectIds: jiraMultiviewIds, multiview: true} ),

    ...getUpdatedActiveFilters( {filters: activeFilters.workload, type: 'workload', projectIds: jiraProjectsIds, project: true} ),
    ...getUpdatedActiveFilters( {filters: activeFilters.workload, type: 'workload', projectIds: jiraMultiviewIds, multiview: true} ), 
    
    ...getUpdatedActiveFilters( {filters: activeFilters.list, type: 'calendarview', projectIds: jiraProjectsIds, project: true} ),
    ...getUpdatedActiveFilters( {filters: activeFilters.list, type: 'calendarview', projectIds: jiraMultiviewIds, multiview: true} ),
  );

  if(activeFilters.list && activeFilters.list.common){
    parapms.update.push(
      ...getPreparedActiveFilter( { filters: activeFilters.list.common, type: 'list'  }  )
    )
  }

  if(activeFilters.workload && activeFilters.workload.common){
    parapms.update.push(
      ...getPreparedActiveFilter( { filters: activeFilters.workload.common, type: 'workload'  }  )
    )
  }

  if(activeFilters.calendarview && activeFilters.calendarview.common){
    parapms.update.push(
      ...getPreparedActiveFilter( { filters: activeFilters.calendarview.common, type: 'calendarview'  }  )
    )
  }

  if(!parapms.delete.length && !parapms.update.length)
    return;

  return  webix.ajax()
    .post("/api/filters/updates", {parapms})
    .then(function (res){ 
      filtersData = filtersData.filter( i => !parapms.delete.includes(i.id));
    })

  function hasJiraOpt( filter ){ 
    return [filter.status, filter.priority].find( opts => {

      if( !Array.isArray(opts) || !opts.length)
        return false;

      return opts.find( id => !defaultIds.includes(id) )
    })        
  }

  function deleteJiraOpt(filter){ 
    if(Array.isArray(filter.status)){
      filter.status = filter.status.filter(id => defaultIds.includes(id));
      filter.status = filter.status.length ? filter.status : '';
    }

    if(Array.isArray(filter.priority)){
      filter.priority = filter.priority.filter(id => defaultIds.includes(id));
      filter.priority = filter.priority.length ? filter.priority : '';
    }

    let res = {...filter};
    if(res.filter)
      delete res.filter;

    return res;
  }

  function getfilterIdsWithJiraOpt(){
    let toDelete = filtersData.filter( item => {
      if(!jiraProjectsIds.includes(item.project_id) )
        return false; 
      
      return !!hasJiraOpt(item.filter);
    }); 

    return toDelete.map( i => i.id);
  }
 
  function getUpdatedActiveFilters( {filters, type, projectIds, multiview = false, project = false} ){
    if(!filters) return [];
    let upd = [];
    projectIds.map( id => { 
      let active_filter = filters[id];    
      upd.push(...getPreparedActiveFilter({filter: active_filter, id, type, multiview, project}));
    });
    return upd;
  }

  function getPreparedActiveFilter( {filter, type, id = null, multiview = false, project = false} ){
    if(!filter || !hasJiraOpt(filter) )
      return [];

    let prepared_filter = deleteJiraOpt(filter);
    return [{ 
      filter: prepared_filter, 
      type: type, 
      project_id: project ? id : null,  
      multiview_id: multiview ? id : null,  
    }];
  }
}

if (!app.config.mode.isBase && (app.config.mode.isLink || (app.config.mode.isExport && app.config.mode.ignoreFilters))) {
  app.checkInit('filters');
} else {
  webix.promise.all([
    projectsModel.waitData,
    filters.fetchData(),
    filters.fetchActiveFilters()
  ])
    .then(function () {
      app.checkInit('filters');
      app.trigger("filters:init");
    });
}

// "filter:addAndApply" need for link view
app.on("filter:addAndApply", function (filter, projectId) {
  filtersData = _.unionBy([filter], filtersData, 'id');

  activeFilters[projectId] = filter.filter;
  filters.updateFilterByActiveProject(projectId, filter.filter);
  app.trigger('filter:change:current', filter.id);
});

export default filters;

