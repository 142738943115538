var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { class: [_vm.$style.animated_popup, "animated_popup_wrap"] }, [
      _c("div", { class: _vm.$style.container }, [
        _c(
          "div",
          {
            class: [_vm.$style.body, _vm.$style.wrap],
            style: { width: _vm.width + "px" },
          },
          [_vm._t("body")],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "background_container" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }