<template>
  <div
    :class="$style.select"
    v-click-outside="close"
  >
    <select-button
      ref="contextMenuButton"
      :label="locales('board_type')"
      :value="getActive ? getActive.value : ''"
      :active="showMenu"
      @onClick="toggleShow"
    />

    <context-menu
      v-if="showMenu"
      :position="positionContext"
    >
      <template #body>
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="[
            $style.menu_item,
            item.id === getActive.id ? $style.active : '',
            item.type === 'divider' ? $style.title : '',
          ]"
          @click="itemClicked(item)"
        >
          <div
            v-if="item.type === 'divider'"
            :class="$style.title_text"
          >
            {{ item.value }}
          </div>
          <div
            v-else
            :class="$style.text"
          >
            {{ item.value }}
          </div>
        </div>
      </template>
    </context-menu>
  </div>
</template>

<script>
import selectButton from './selectButton.vue';

export default {
  components: {
    selectButton,
  },
  data() {
    return {
      locales: __,
      showMenu: false,
      positionContext: null,
    };
  },
  computed: {
    items() {
      return this.$store.getters['headerView/boardTypes'];
    },
    getActive() {
      return this.items.find(obj => obj.id === this.$store.getters['headerView/activeBoardType']);
    },
  },
  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();
      }
    },
    itemClicked(item) {
      if (item.type !== 'divider') {
        this.toggleShow();
        userExtAnalytics.log('header_select_board_type', {
          type: item.id === 'resource_id' ? 'people' : item.id,
        });
        this.$store.dispatch('headerView/selectBoardType', item.id);
      }
    },
    close() {
      this.showMenu = false;
    },
  },
};
</script>

<style module src="../less/select.less"></style>
