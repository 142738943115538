var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "globalPopup", staticClass: "global-popup" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        ![
          "confirm",
          "thankUpgrade",
          "permissionPopup",
          "paymentConfirm",
        ].includes(_vm.config.type)
          ? _c("closer", {
              staticClass: "global-popup-closer",
              attrs: { "on-close": _vm.closeClick },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("core-confirm-popup", {
          attrs: {
            show: _vm.config.type === "confirm",
            text: _vm.locale(_vm.config.message),
            "ok-button": {
              type:
                _vm.config.popupType === "confirm" ? "primary" : "destructive",
              title: _vm.locale(_vm.config.ok),
            },
            "cancel-button": {
              type: "secondary",
              title: _vm.locale(_vm.config.cancel),
            },
            "close-icon": _vm.config.closeIcon,
            "popup-width": _vm.config.popupWidth,
            "additional-text": _vm.config.additionalText,
            title: _vm.config.title,
            "button-width": _vm.config.buttonWidth,
          },
          on: {
            onClickOk: function ($event) {
              return _vm.config.confirm(_vm.config.data)
            },
            onClickCancel: _vm.closeClick,
          },
        }),
        _vm._v(" "),
        _vm.config.type === "paymentConfirm"
          ? _c("confirm-popup", { attrs: { config: _vm.config } })
          : _vm._e(),
        _vm._v(" "),
        _vm.config.type === "twoFaPopup" ? _c("two-fa-popup") : _vm._e(),
        _vm._v(" "),
        _vm.config.type === "permissionPopup"
          ? _c("permission-popup")
          : _vm._e(),
        _vm._v(" "),
        _vm.config.type === "paymentPopup" ? _c("payment-popup") : _vm._e(),
        _vm._v(" "),
        _vm.config.type === "upgradePlan"
          ? _c("upgrade-plan-popup", { attrs: { config: _vm.config } })
          : _vm._e(),
        _vm._v(" "),
        _vm.config.type === "thankUpgrade"
          ? _c("thank-upgrade-popup")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }