import projectComponent from '../../Project';
//import commentsModel from '../../../models/comments2';
import app from '../../../app';

async function onProjectArchived(event) {
  await projectComponent.onArchivedProject({
    ganttId: event.data.project.projectId,
    isArchived: 1,
  });
  //commentsModel.onArchivedProject(event.data.project.projectId, 1);

  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: [event.data.project.projectId],
  });
}

export default onProjectArchived;
