<template>
  <div :class="[$style.multiselect, $style.field_wrap]">
    <div :class="[$style.input_label]">
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="hasChacked"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        @click="resetChacked()"
      >
        {{ locale.reset }}
      </div>
    </div>
    <div
      ref="inputField"
      :class="[$style.input_field_wrap]"
    >
      <div
        :class="[
          $style.d_flex,
          $style.input_field,
          $style.multiselect_with_search_input,
          (hasChacked || focusInput) ? $style.active : ''
        ]"
      >
        <div
          v-if="hasChacked"
          :id="gpAutotestName"
          :class="[
            $style.selected,
            dropList.isOpen ? $style.active : '',
            dropTemplate === 'label' ? $style.whiteBg : '',
            dropTemplate === 'icon+text' || dropTemplate === 'text' ? $style.add_pad : '',
          ]"
          @click="toggleDropList"
        >
          <template v-if="dropTemplate === 'icon+text'">
            <template v-if="chacked.length == 1">
              <div
                v-for="(item, index) in getFirstItems()"
                :key="index + componentKey + 1"
                :class="[$style.nowrap, $style.d_flex]"
              >
                <div
                  v-if="isSrcToImg(item[iconField])"
                  :class="[$style.item_img_wrap]"
                  :style="{'backgroundImage': `url(${item[iconField]})`, 'backgroundSize': 'cover'}"
                >
                  <!-- <img :src="item[iconField]"> -->
                </div>

                <webix-ui
                  v-else
                  :class="[$style.item_icon]"
                  :config="svgForVue(item[iconField])"
                />
                <span> {{ item[valueField] }} </span>
              </div>
            </template>

            <template v-if="chacked.length >= 2">
              <div
                v-for="(item, index) in getFirstItems(2)"
                :key="index + componentKey + 2"
                :class="[$style.item_icon_wrap, $style.item_img_wrap]"
              >
                <template
                  v-if="isSrcToImg(item[iconField])"
                >
                  <div
                    :class="[$style.item_img_wrap]"
                    :style="{'backgroundImage': `url(${item[iconField]})`, 'backgroundSize': 'cover'}"
                  />
                  <!-- <img :src="item[iconField]"> -->
                </template>

                <webix-ui
                  v-else
                  :class="[$style.item_icon]"
                  :config="svgForVue(item[iconField])"
                />
              </div>
            </template>

            <div
              v-if="chacked.length > 2"
              :class="[$style.selected_count]"
            >
              +{{ chacked.length - 2 }}
            </div>
          </template>

          <template v-if="dropTemplate === 'text'">
            <template v-if="chacked.length === 1">
              <div
                v-for="(item, index) in getFirstItems()"
                :key="index + componentKey + 1"
                :class="[$style.nowrap, $style.d_flex]"
              >
                <span :class="[$style.nowrap]"> {{ item[valueField] }} </span>
              </div>
            </template>

            <div
              v-else
              :class="[$style.nowrap]"
            >
              {{ chacked.length }} {{ locale.items }}
            </div>
          </template>

          <template v-if="dropTemplate === 'label'">
            <template v-if="chacked.length == 1">
              <div
                v-for="(item, index) in getFirstItems()"
                :key="index + componentKey + 1"
                :class="[$style.nowrap, $style.d_flex]"
              >
                <div
                  :class="[$style.selected_label_drop]"
                  :style="{'background-color': item.color}"
                >
                  <span>{{ item[valueField] }} </span>
                </div>
              </div>
            </template>

            <template v-if="chacked.length >= 2">
              <div
                v-for="(item, index) in getFirstItems(2)"
                :key="index + componentKey + 2"
                :class="[$style.selected_label_drop, $style.label_min]"
                :style="{'background-color': item.color}"
              >
                <span>{{ getFirstLetter(item[valueField]) }} </span>
              </div>
            </template>

            <div
              v-if="chacked.length > 2"
              :class="[$style.selected_count]"
            >
              +{{ chacked.length - 2 }}
            </div>
          </template>
        </div>
        <input
          v-model="searchStr"
          :placeholder="dropList.isOpen ? locale.search : hasChacked ? '' : placeholder"
          type="text"
          :class="[dropList.isOpen ? $style.cursor_text : '' ]"
          :disabled="filterProvide.disableInput"
          @input="updateShowedItems"
          @focus="focusInput=true"
          @blur="focusInput=false"
          @click="openDropList"
        >
        <webix-ui
          :class="$style.drop_arrow"
          :config="icon_arrow"
          @click="toggleDropList"
        />
      </div>

      <div
        ref="dropList"
        :class="[
          $style.drop_list,
          dropList.isOpen ? $style.show_list : '',
          isBottom ? $style.bottom_drop : $style.top_drop
        ]"
        :style="{
          top: dropList.coordinates.top,
          bottom: dropList.coordinates.bottom,
          left: dropList.coordinates.left,
          right: dropList.coordinates.right,
          width: dropList.width
        }"
      >
        <div v-show="showItems.length">
          <div
            :class="[
              $style.select_all,
              dropList.isScrollOnTop ? '' : $style.scrolled
            ]"
            @click="selectUnselectAll"
          >
            <span v-if="!isAllShowedSelected">
              {{ locale.selectAll }}
            </span>
            <span v-else>{{ locale.unselectAll }}</span>
          </div>

          <div
            :key="dropItems.length"
            ref="dropListScrolled"
            class="gantt_scroll"
            :class="$style.drop_scroll"
          >
            <div
              v-for="groupname in groupSet"
              :key="componentKey + groupname"
            >
              <div
                v-if="groupname"
                :class="$style.drop_group"
              >
                <span :class="$style.drop_group_name"> {{ groupname }} </span>
                <div :class="$style.drop_group_line" />
              </div>
              <div
                v-for="item in getGroupItems(groupname)"
                :key="dropTemplate + item.id"
                :class="$style.list_item"
              >
                <input
                  :id="item[trackBy] + componentKey"
                  v-model="chacked"
                  type="checkbox"
                  :value="item"
                >
                <label :for="item[trackBy] + componentKey">

                  <template v-if="dropTemplate === 'text'">
                    <span :class="[$style.nowrap]">{{ item[valueField] }}</span>
                  </template>

                  <template v-if="dropTemplate === 'icon+text'">
                    <div
                      v-if="isSrcToImg(item[iconField])"
                      :class="[$style.item_img_wrap]"
                      :style="{'backgroundImage': `url(${item[iconField]})`, 'backgroundSize': 'cover'}"
                    >
                    <!-- <img :src="item[iconField]"> -->
                    </div>
                    <webix-ui
                      v-else
                      :class="[$style.item_icon]"
                      :config="svgForVue(item[iconField])"
                    />
                    <span :class="[$style.nowrap]"> {{ item[valueField] }} </span>
                  </template>
                  <template v-if="dropTemplate === 'label'">
                    <div
                      :style="{'background-color': item.color}"
                      :class="[$style.label_drop_template, $style.nowrap]"
                    >
                      {{ item[valueField] }}
                    </div>
                  </template>

                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="!showItems.length"
          :class="[$style.no_items]"
        >
          <span> {{ locale.noSearchedItems }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_downArrow from '../../../svg/filter/arrow_dropdawn.svg';
import svgIconForVue from '../../common/svgIconForVue';

export default {
  name: 'FilterVue',

  inject: ['filterProvide'],

  props: {
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
    placeholder: { type: String, required: false, default: 'placeholder' },
    label: { type: String, required: false, default: 'label' },
    dropTemplate: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return (
          ['text', 'color', 'icon+text', 'color+text', 'label'].indexOf(value) !== -1
        );
      },
    },
    dropItems: { type: Array, required: true },
    trackBy: { type: String, required: false, default: 'id' },
    valueField: { type: String, required: false, default: 'value' },
    iconField: { type: String, required: false, default: 'icon' },
    groupBy: { type: String, required: false, default: 'group' },
    selected: { type: Array, required: false, default: null },
  },

  data() {
    return {
      locale: {
        reset: __('common_reset'),
        search: __('filter_search_placeholder'),
        selectAll: __('multicombo_select_all_items'),
        unselectAll: __('multicombo_unselect_all_items'),
        items: __('tag_items'),
        noSearchedItems: __('no_matches_message_text'),
      },
      icon_arrow: svgIconForVue(icon_downArrow, 15, 24),
      chacked: [],
      isBottom: true,

      dropList: {
        width: '300px',
        isOpen: false,
        coordinates: {
          left: 'auto',
          right: 'auto',
          top: 'auto',
          bottom: 'auto',
        },
        isScrollOnTop: true,
      },
      focusInput: false,
      searchStr: '',
      showItems: [],
      mountedMode: false,
      updateChacked: false,
      blockEmit: false,
    };
  },

  computed: {
    isAllShowedSelected() {
      if (this.hasChacked < this.showItems.length) return false;

      const isAllSelected = this.showItems.every(item => this.chacked.find(i => i.id === item.id));

      return isAllSelected;
    },
    hasChacked() {
      return this.chacked.length || 0;
    },
    componentKey() {
      const min = 1000; const
        max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },

    groupSet() {
      const set = [];

      this.showItems.forEach(item => {
        const groupName = (this.groupBy in item) ? item[this.groupBy] : null;

        if (!set.includes(groupName)) {
          set.push(groupName);
        }
      });

      return set;
    },
  },

  watch: {
    'dropList.isOpen': function (val) {
      if (!val) {
        this.searchStr = '';
        this.showItems = this.dropItems || [];
      } else {
        this.$nextTick(() => {
          this.calcPopupPosition();
        });
      }
    },
    selected(val, oldval) {
      this.blockEmit = true;
      this.updateChackedValue();
    },
    chacked() {
      const ids = this.chacked.map(i => i[this.trackBy]);
      const value = ids.length ? ids : '';

      if (!value) !this.blockEmit && this.$emit('reset', value);
      else !this.blockEmit && this.$emit('check', value);

      this.blockEmit = false;
    },
    dropItems(val) {
      !this.dropList.isOpen && this.updateShowedItems();
      this.checkSelectedItems();
    },
    $route(to, from) {
      this.dropList.isOpen = false;
    },
  },
  mounted() {
    this.blockEmit = true;
    this.showItems = this.dropItems || [];
    const input_coords = this.$refs.inputField.getBoundingClientRect();

    this.dropList.width = `${input_coords.width}px`;

    this.updateChackedValue();
    this.addListeners();
  },

  beforeDestroy() {
    this.removeListeners();
  },

  methods: {
    getFirstLetter(txt) {
      return txt.charAt(0);
    },
    updateChackedValue() {
      this.updateChacked = true;
      if (!this.selected || !this.selected.length) {
        this.chacked = [];

        return;
      }

      this.chacked = this.dropItems.filter(item => this.selected.find(id => item[this.trackBy] == id));
    },

    checkSelectedItems() {
      const mustDelChecked = [];

      this.chacked.forEach((item, index) => {
        const isItem = this.dropItems.find(d => d.id === item.id);

        if (!isItem) {
          mustDelChecked.push(index);
        }

        if (isItem) {
          const checkedIndex = this.chacked.indexOf(this.chacked.find(c => c.id === isItem.id));

          this.chacked[checkedIndex] = {
            ...item,
            ...isItem,
          };
        }
      });
      mustDelChecked.reverse().forEach(index => {
        this.chacked.splice(index, 1);
      });
    },

    updateShowedItems() {
      if (this.searchStr.trim() === '') {
        this.showItems = [...this.dropItems];

        return;
      }

      this.showItems = this.dropItems.filter(item => {
        const value = item[this.valueField].toLowerCase();

        if (value.includes(this.searchStr.toLowerCase())) {
          return item;
        }

        return false;
      });
    },
    sorteShowedItems() {
      const res = [];

      this.groupSet.forEach(name => {
        const first = []; const
          second = [];
        const group = this.getGroupItems(name);

        group.forEach(item => {
          if (this.chacked.find(i => i[this.trackBy] === item[this.trackBy])) {
            first.push(item);
          } else second.push(item);
        });
        res.push(...first, ...second);
      });
      this.showItems = res;
    },
    getFirstItems(n = 1) {
      return this.chacked.filter((item, index) => index < n);
    },
    getGroupItems(groupName) {
      if (groupName === null) {
        return this.showItems.filter(item => !(this.groupBy in item));
      }

      return this.showItems.filter(item => item[this.groupBy] === groupName);
    },
    selectUnselectAll() {
      if (this.isAllShowedSelected) {
        this.chacked = this.chacked.filter(item => {
          const is = !this.showItems.find(s => s.id === item.id);

          return is;
        });
      } else {
        const selectedIds = this.chacked.map(i => i[this.trackBy]);
        const toAdd = this.showItems.filter(i => !selectedIds.includes(i[this.trackBy]));

        this.chacked.push(...toAdd);
      }
    },
    svgForVue(icon) {
      return svgIconForVue(icon, 17, 17);
    },
    isSrcToImg(icon) {
      return !icon.includes('<svg');
    },
    resetChacked() {
      this.chacked = [];
      this.showItems = [...this.dropItems];
    },

    toggleDropList() {
      if (this.dropList.isOpen) {
        this.dropList.isOpen = false;

        return;
      }

      this.openDropList();
    },

    openDropList() {
      this.calcPopupPosition();
      this.sorteShowedItems();
      this.dropList.isOpen = true;
    },

    calcPopupPosition() {
      const drop = this.$refs.dropList;
      let dropHeight = 0;

      const input_coordinate = this.$refs.inputField.getBoundingClientRect();
      const clientHeight = document.documentElement.clientHeight;

      if (drop) {
        dropHeight = drop.getBoundingClientRect().height;
      } else {
        const selectAllHeight = 34;
        const groupNameHeight = this.groupSet.length ? 36 * 2 : 0;
        const supposeHeight = selectAllHeight + groupNameHeight + (37 * this.dropItems.length);

        dropHeight = supposeHeight < 216 ? (37 * this.dropItems.length) : 216;
      }

      this.isBottom = (input_coordinate.bottom + dropHeight + 35) < clientHeight;

      if (this.isBottom) {
        this.dropList.coordinates = {
          left: `${input_coordinate.left}px`,
          right: 'auto',
          top: `${input_coordinate.bottom}px`,
          bottom: 'auto',
        };
      } else {
        this.dropList.coordinates = {
          left: `${input_coordinate.left}px`,
          right: 'auto',
          top: 'auto',
          bottom: `${clientHeight - input_coordinate.top}px`,
        };
      }
    },

    dropListScroll() {
      if (this.$refs.dropListScrolled.scrollTop === 0) {
        this.dropList.isScrollOnTop = true;
      } else {
        this.dropList.isScrollOnTop = false;
      }
    },

    scrollHandler() {
      this.dropList.isOpen = false;
    },

    documentClickHandler(e) {
      const node = this.$refs.inputField;

      if (!node.contains(e.target)) {
        this.dropList.isOpen = false;
      }
    },

    documentResizeHandler() {
      this.dropList.isOpen = false;
    },

    addListeners() {
      window.addEventListener('resize', this.documentResizeHandler);
      document.addEventListener('click', this.documentClickHandler);
      this.$refs.dropListScrolled.addEventListener('scroll', this.dropListScroll);

      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        if (!this.$refs.inputField.contains(div)) div.addEventListener('scroll', this.scrollHandler);
      });
    },
    removeListeners() {
      window.removeEventListener('resize', this.documentResizeHandler);
      document.removeEventListener('click', this.documentClickHandler);
      this.$refs.dropListScrolled.removeEventListener('scroll', this.dropListScroll);

      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.scrollHandler);
      });
    },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
