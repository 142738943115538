<template>
  <div
    v-scroll:#virtual-scroll-table="scrollAndResizeHandler"
    v-scroll:#horizontal-scroll-table="scrollAndResizeHandler"
    class="drop-cell no-select"
    :class="{'highlight': isOpenPopup && highlight}"
  >
    <div class="trigger-wrap">
      <slot
        name="trigger"
        :isOpen="isOpen"
      />
    </div>

    <div
      v-if="isOpenPopup"
      ref="drop"
      class="drop-cell--drop js-drop-cell-popup"
      :style="{
        'width':dropWidth + 'px',
        top: dropCoords.top,
        bottom: dropCoords.bottom,
        right:dropCoords.right,
        left:dropCoords.left,
      }"
    >
      <slot name="drop" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DropPopup',
  components: { },
  props: {
    manualOpen: { type: Boolean, required: false, default: false },
    dropShow: { type: Boolean, required: false, default: false },
    suggestHeight: { type: Number, required: false, default: 200 },
    dropWidth: { type: Number, required: false, default: 200 },
    closeOnContentClick: { type: Boolean, required: false, default: true },
    value: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    highlight: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      isOpen: false,
      triggerCoords: {},
      dropCoords: {
        bottom: 'auto', top: 'auto', right: 'auto', left: 'auto',
      },
    };
  },
  computed: {
    isOpenPopup() {
      const ifOpen = this.manualOpen ? this.value : this.isOpen;

      return ifOpen && !this.disabled && this.editable;
    },
  },
  watch: {
    value(val) {
      this.togglePopup(val);
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClick);
    document.addEventListener('contextmenu', this.contextMenuEvent);
  },
  beforeDestroy() {
    this.closeDrop();
    document.removeEventListener('click', this.documentClick);
    document.removeEventListener('contextmenu', this.contextMenuEvent);
  },
  methods: {
    documentClick(e) {
      if (this.manualOpen) return;

      const isElem = this.$el.contains(e.target);
      const isDrop = this.$refs.drop ? this.$refs.drop.contains(e.target) : false;

      if (!isElem) {
        this.closeDrop();
        this.$emit('input', this.isOpen);
      }

      if (isElem) {
        if (isDrop && !this.closeOnContentClick) return;
        this.togglePopup(!this.isOpen);
        this.$emit('input', this.isOpen);
      }
    },
    contextMenuEvent() {
      this.closeDrop();
    },
    scrollAndResizeHandler() {
      this.closeDrop();
      this.$emit('input', this.isOpen);
    },
    togglePopup(flag) {
      flag ? this.openDrop() : this.closeDrop();
    },

    closeDrop() {
      if (!this.isOpen) return;
      this.isOpen = false;
    },

    openDrop() {
      this.isOpen = true;
      this.calcDropCoords();
      this.checkPosition();
    },

    calcDropCoords() {
      const coords = this.$el.getBoundingClientRect();

      this.triggerCoords = {
        bottom: coords.bottom,
        top: coords.top,
        left: coords.left,
        right: coords.right,
      };
    },
    checkPosition() {
      const clientHeight = document.body.clientHeight;
      const clientWidth = document.body.clientWidth;

      const isTop = (this.triggerCoords.bottom + this.suggestHeight) > (clientHeight - 30);
      const isOverRight = (this.triggerCoords.left + this.dropWidth + 0) > clientWidth;

      this.dropCoords = {
        bottom: isTop ? `${clientHeight - this.triggerCoords.top}px` : 'auto',
        top: !isTop ? `${this.triggerCoords.bottom}px` : 'auto',
        right: isOverRight ? '0px' : 'auto',
        left: !isOverRight ? `${this.triggerCoords.left}px` : 'auto',
      };
    },
  },
};
</script>

<style scoped lang="less">
.trigger-wrap{
  height: 100%;
  width: 100%;
}
.drop-cell{
  // position: relative;
  width: 100%;
  height: 100%;
  border-width: 0px;

  &--drop{
    position: fixed;
    background-color: #fff;
    z-index:  6;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 10%);
    border: 1px solid #efefef;
  }

  &.highlight{
    box-shadow: inset 0 0 0 1px #247ecc;
    background-color: #fff;
  }
}
.no-select{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
