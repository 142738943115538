<template>
  <div
    :class="[$style.vgp_input_confirm, isDisabled ? $style.disabled : '', isError ? $style.error: null, isFocus ? $style.focus : null]"
    @click="focusElement(true)"
  >
    <template v-if="iconPosition === 'left'"> 
      <sprite
        :class="$style.vgpIcon"
        color="#909090"
        name="check"
        :size="20"
        @click="ok"
      /> 
      <sprite
        :class="$style.vgpIcon"
        color="#909090"
        name="close-1"
        :size="20"
        @click="cancel"
      /> 
    </template>

    <vgp-input
      ref="input_confirm"
      v-model="data"
      :small="isSmall"
      :class="$style.input"
      :input-type="type"
      :placeholder="placeholder"
      @onKeydown="onKeydown"
      @input="onInput"
      @change="onChange"
      @onBlur="onBlur"
    />

    <template v-if="iconPosition === 'right'"> 
      <sprite
        :class="$style.vgpIcon"
        color="#909090"
        name="check"
        :size="20"
        @click="ok"
      /> 
      <sprite
        :class="$style.vgpIcon"
        color="#909090"
        name="close-1"
        :size="20"
        @click="cancel"
      /> 
    </template>
  </div>
</template>

<script>

import vgpInput from './vgpInput.vue';
import sprite from '$$vueCmp/VueIcon/svgSprite.vue';

export default {
  name: 'VgpInputConfirm',
  components: { vgpInput, sprite },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    isDisabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: true,
    },
    iconPosition: {
      type: String,
      required: false,
      default: 'right',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
  },
  emits: {
    onChange: null,
    click: null,
    input: 'string',
  },
  data() {
    return {
      data: this.value ? this.value : '',
      currentValue: '',
      isFocus: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    focusElement(value) {
      this.isFocus = value;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.focusElement(false);
      }
    },

    onKeydown(e) {
      this.$emit('onKeydown', this.data, e);
    },

    onChange(e) {
      this.$emit('onChange', this.data, e);
    },

    onInput(e) {
      this.$emit('input', this.data, e);
    },

    onBlur(e) {
      this.$emit('onBlur', this.data, e);
    },

    ok(e) {
      this.$emit('onOk', this.data, e);
    },
    cancel(e) {
      this.$emit('onCancel', undefined, e);
    },
  },
};
</script>

<style module src='./vInput.less'></style>
