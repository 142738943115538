var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "view_container",
        ((_obj = {}), (_obj[_vm.$style.inactive] = _vm.inactive), _obj),
      ],
    },
    [
      _vm.projectChannelConnection.showProject
        ? _c("div", { staticClass: "connection_project_title_msteams" }, [
            _c(
              "div",
              {
                staticClass: "text",
                attrs: {
                  title: _vm.getProjectByGanttId(
                    _vm.projectChannelConnection.gantt_id
                  ).name,
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.getProjectByGanttId(
                        _vm.projectChannelConnection.gantt_id
                      ).name
                    ) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "connection_project_title_msteams" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "channel_setting_container",
          class: { small_border: _vm.projectChannelConnection.smallBorder },
        },
        [
          _c(
            "div",
            {
              staticClass: "team_title_msteams",
              attrs: { title: _vm.projectChannelConnection.msteams_team_name },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.projectChannelConnection.msteams_team_name) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "channel_title_msteams" }, [
            _c(
              "div",
              {
                staticClass: "text",
                attrs: { title: _vm.projectChannelConnection.channel_name },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.projectChannelConnection.channel_name) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "settings_title_msteams" }, [
            _c(
              "div",
              {
                staticClass: "text",
                attrs: {
                  title: _vm.getSettingsString(_vm.projectChannelConnection)
                    .settingsText,
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.getSettingsString(_vm.projectChannelConnection)
                        .settingsText
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "div",
              {
                staticClass: "edit",
                on: {
                  click: function ($event) {
                    !_vm.inactive &&
                      _vm.toggleEditView(_vm.projectChannelConnection.id)
                  },
                },
              },
              [
                _vm.waitingForActivation
                  ? _c("loader")
                  : _vm.inactive
                  ? _c(
                      "div",
                      {
                        staticClass: "tooltip-gantt",
                        attrs: { "data-html": "true" },
                      },
                      [
                        _c("div", { staticClass: "tooltip-gantt-html" }, [
                          _c(
                            "div",
                            { staticClass: "tooltip-gantt-html-title" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.locales(
                                      "integration_msteams_add_app_to_team_tooltip"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("webix-ui", {
                          class: _vm.$style.tip,
                          attrs: { config: _vm.icons.tip },
                        }),
                      ],
                      1
                    )
                  : _c("webix-ui", { attrs: { config: _vm.icons.editIcon } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "delete",
                on: {
                  click: function ($event) {
                    return _vm.deleteItem(_vm.projectChannelConnection.id)
                  },
                },
              },
              [_c("webix-ui", { attrs: { config: _vm.icons.deleteIcon } })],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }