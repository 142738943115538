var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container_export" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        {
          staticClass: "header-close header-button",
          on: { click: _vm.closeExport },
        },
        [_c("div", { staticClass: "header-close-icon" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "header-right" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "header-hide-bar header-button",
            on: {
              mousedown: function () {
                return false
              },
              click: _vm.rightBarToggle,
              dblclick: _vm.rightBarToggle,
            },
          },
          [
            _c("div", {
              staticClass: "header-hide-bar-icon",
              domProps: { innerHTML: _vm._s(_vm.minimizeIcon) },
            }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "gantt" },
        [
          _c(_vm.iframe, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFrame,
                expression: "showFrame",
              },
            ],
            tag: "component",
          }),
          _vm._v(" "),
          _c(_vm.paperWithSvg, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showFrame,
                expression: "!showFrame",
              },
            ],
            tag: "component",
            attrs: { "export-type": _vm.exportType },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-bar" },
        [
          _c(_vm.rightBar, {
            tag: "component",
            attrs: { "show-frame": _vm.showFrame },
            on: { "show-frame-toggle": _vm.frameToggle },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {
      staticClass: "header-title",
      domProps: { innerHTML: _vm._s(_vm.title) },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }