<template>
  <div :class="$style.table_wrapper">
    <div v-if="tableInstance" :class="$style.table_grid">
      <div class="header-row" :class="$style.header_row" :style="{ gridTemplateColumns: gridTemplateColumns }">
        <div :class="[$style.cell, $style.header]" v-for="header in getAllHeaders()" :key="header.id"
          :id="header.id"
          :style="cellStyle(header.id)"
          @click="(e) => toggleSorting(e, header)">
          <div>{{ header.column.columnDef.header }}</div>
          <div v-if="header.column.columnDef.header">
            <svg-sprite
              v-if="header.column.getIsSorted()"
              type="regular"
              :size="16"
              :name="{asc: 'priority', desc: 'priority-2'}[header.column.getIsSorted()]"
              color="#333333"
            />
            <svg-sprite
              v-if="!header.column.getIsSorted()"
              :class="$style.only_hover"
              type="regular"
              :size="16"
              name="priority"
              color="#B2B2B2"
            />
          </div>
          <div v-if="header.column.getCanResize()" class="resizer" :data-id="`${header.id}`" :class="[$style.resizer, header.column.getIsResizing() && $style.is_resizing]"
            @mousedown.stop="e => header.getResizeHandler()(e)"
          ></div>
        </div>
      </div> 

      <div
        :class="$style.content_row"
        v-for="row in tableInstance.getRowModel().rows"
        :key="row.id"
        :style="{ gridTemplateColumns: gridTemplateColumns }"
        @contextmenu.prevent="(e) => contextMenuHandler(e, row)"
        @click="clickHandler(row)"
      >
        <tooltip
          v-for="cell in getAllCells(row)"
          :key="cell.id"
          :class="[$style.cell, $style.content]"
          :style="cellStyle(cell.getContext().column.id)"
          :content="getTooltipContent(row, cell)"
          :position="cell.getContext().row.index ? 'top' : 'bottom'"
          :fullWidth="true"
          :delay="250"
          :hide="!cell.getContext().column.columnDef.showTooltip"
        >
          <template #body>
            <div :class="$style.td_cell">
              <component
                :is="cell.column.columnDef.cell"
                v-bind="{ context: getCellContext(cell) }"
                @contextMenu="contextMenuHandler"  
              />
            </div>
          </template>
        </tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel
} from "@tanstack/table-core";
import cellComponent from "./helpers/cellComponent";
import Tooltip from '../tooltips/tooltip.vue';

export default {
  name: "TanstackTable",
  components: { Tooltip },
  props: {
    config: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      tableInstance: null,
      expanded: {},
      sorting: [],
      columnSizingInfo: {
        startOffset: null,
        startSize: null,
        deltaOffset: null,
        deltaPercentage: null,
        isResizingColumn: false,
        columnSizingStart: []
      },
      columnSizing: {},
      currentWidthInfo: {}
    };
  },
  created() {
    this.initializeTable();
  },
  mounted() {
    this.setCurrentColumnWidth();
  },
  computed: {
    getColumns() {
      return this.config.columns.map(col => ({
        ...col,
        accessorKey: col.id,
        cell: cellComponent(col.type)
      }));
    },
    tableOptions() {
      return {
        ...this.config,
        columns: this.getColumns
      };
    },
    gridTemplateColumns() {
      return this._gridTemplateColumns([
        ...this.tableInstance.getLeftFlatHeaders(),
        ...this.tableInstance.getCenterFlatHeaders(),
        ...this.tableInstance.getRightFlatHeaders()
      ], this.columnSizing);
    }
  },
  methods: {
    isPinned(context) {
      return context.column.getIsPinned();
    },
    initializeTable() {
      this.tableInstance = createTable({
        onExpandedChange: (updater) => this.setExpanded(updater),
        onSortingChange: (updater) => this.setSorting(updater),
        onColumnSizingInfoChange: (updater) => this.setColumnSizingInfo(updater),
        onColumnSizingChange: (updater) => this.setColumnSizing(updater),
        columnResizeMode: "onChange",
        getSubRows: row => row.subRows,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        sortDescFirst: false,
        renderFallbackValue: '',
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        ...this.tableOptions,
        state: {
          columnPinning: {},
          columnSizing: this.columnSizing,
          columnSizingInfo: this.columnSizingInfo,
          ...this.tableOptions.state
        }
      });

      this.sorting = this.tableOptions.state.sorting;
    },
    setData(data) {
      this.tableInstance.setOptions((old) => ({ ...old, data }));
    },
    setExpanded(state) {
      this.expanded = typeof state === 'function' ? state(this.expanded) : state;
      this.tableInstance.setOptions((old) => ({ ...old, state: { ...old.state, expanded: this.expanded } }));
    },
    setSorting(state) {
      const newSortingState = typeof state === 'function' ? state(this.sorting) : state;
      this.sorting = newSortingState;
      this.tableInstance.setOptions((old) => ({ ...old, state: { ...old.state, sorting: this.sorting } }));

      this.$emit('sortingChanged', newSortingState);
    },
    setColumnSizingInfo(state) {
      const updatedInfo = typeof state === 'function' ? state(this.columnSizingInfo) : state;
      this.columnSizingInfo = updatedInfo;
    },
    setColumnSizing(state) {
      this.columnSizing = typeof state === 'function' ? state(this.columnSizing) : state;
      this.tableInstance.setOptions((old) => ({ ...old, state: { ...old.state, columnSizing: this.columnSizing } }));
      this.setCurrentColumnWidth();
    },
    setCurrentColumnWidth() {
      const headerRow = document.querySelector('.header-row');
      if (headerRow) {
        this.currentWidthInfo = [...headerRow.children].reduce((acc, headerElem) => {
          acc[headerElem.id] =

            this.config.columns.find(col => col.id === headerElem.id)?.size ||
            headerElem.getBoundingClientRect().width;

          return acc;
        }, {});
      }
    },
    getAllHeaders() {
      return [
        ...this.tableInstance.getLeftFlatHeaders(),
        ...this.tableInstance.getCenterFlatHeaders(),
        ...this.tableInstance.getRightFlatHeaders()
      ];
    },
    getAllCells(row) {
      return [
        ...row.getLeftVisibleCells(),
        ...row.getCenterVisibleCells(),
        ...row.getRightVisibleCells()
      ];
    },
    cellStyle(id) {
      const context = this.getAllHeaders().find(header => header.id === id);
      const index = this.getAllHeaders().findIndex(header => header.id === id);
      const isPinned = this.isPinned(context);
      let style = {};

      if (isPinned) {
        style.position = 'sticky';
        style.zIndex = 4;
        if (isPinned === 'left') {
          style.left = index === 0 ? 0 : `${this.calculateLeftOffset(context.id)}px`;
        } else if (isPinned === 'right') {
          style.right = index === this.getAllHeaders().length - 1 ? 0 : `${this.calculateRightOffset(context.id)}px`;
        }
      }
      return style;
    },
    calculateLeftOffset(id) {
      const leftHeaders = this.tableInstance.getLeftFlatHeaders().map(header => header.id);
      const index = leftHeaders.findIndex(ID => ID === id);
      const offset = leftHeaders.slice(0, index).reduce((acc, curr) => {
        return acc + this.currentWidthInfo[curr];
      }, 0);

      return offset;
    },
    calculateRightOffset(id) {
      const rightHeaders = this.tableInstance.getRightFlatHeaders().map(header => header.id);
      const index = rightHeaders.findIndex(ID => ID === id);
      const offset = rightHeaders.slice(index + 1).reduce((acc, curr) => {
        return acc + this.currentWidthInfo[curr];
      }, 0);

      return offset;
    },
    getCellContext(cell) {
      let cellContext = { ...cell.getContext() };
      const columnDefContext = cell.column.columnDef.context;
      if (columnDefContext) {
        cellContext = { ...cellContext, ...columnDefContext }; 
      }

      return cellContext;
    },
    toggleSorting(e, header) {
      if (e.target.classList.contains('resizer')) {
        return;
      }

      header.column.toggleSorting();
    },
    getTooltipContent(row, cell) {
      const tooltipContentFn = cell.getContext().column.columnDef.tooltipContent;
      return tooltipContentFn && tooltipContentFn(row);
    },
    clickHandler(row) {
      this.$emit('rowClick', row);
    },
    contextMenuHandler(event, row) {
      this.$emit('contextMenu', event, row);
    },
    _gridTemplateColumns(headers, columnSizing) {
      return headers.map((header) => {
        const width = columnSizing[header.id] || this.config.columns.find(col => col.id === header.id)?.size;
        return width ? `${width + (header.column.getIsResizing() ? this.columnSizingInfo.deltaOffset : 0)}px` : '1fr';
      }).join(' ');
    }
  },
  watch: {
    tableOptions(newVal, oldVal) {
      this.setData(newVal.data);
    }
  }
};
</script>

<style module src="./less/table.less"></style>