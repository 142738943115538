/* eslint-disable */
import moment from "../../../../libs/moment";
import projectsModel from "../../../../models/projects";
import estimationModule from "../../../../views/gantt/modules/estimation";
import userCustomColumnsModule from "../../../../views/gantt/modules/userCustomColumns";
import constants from '../../../../helpers/constants';
import routerHelper from "../../../../helpers/router";
import globalStore from '../../../../store/main';
import _ from "../../../../libs/lodash";
import pricingHelper from '../../../../helpers/pricingHelper';

const __ = window.__;


var multDecimal = function (wbs) {
  var wbsArr = wbs.split('.');
  var sum = 0;
  wbsArr.map(function (v, i) {
    sum += v * 1 / (Math.pow(100, i + 1));
  });
  return sum;
};

var innerObject = {
  init: {
    run: function () { },
    afterInit: function () { }
  },

  getTaskColumns: function (exportOptions, workSheetOptions) {
    let cols = [];
    let outCols = [];
    let splitLevelsToColumns = workSheetOptions.splitLevelsToColumns || false;
    let compareWithBaselineOptions = workSheetOptions.compareWithBaselineOptions || false;
    let userCustomColumns = [];

    if (pricingHelper.checkPricingAccess('custom_fields')) {
      userCustomColumns = userCustomColumnsModule.getColumnsData();
    }

    workSheetOptions.excludedColumns = workSheetOptions.excludedColumns || [];

    cols = cols.concat([
      { label: __("export_excel_color"), key: "color", colWidth: 3, type: 'color' },
      { label: __("export_excel_wbs_number"), key: "wbs", type: 'text' }
    ]);

    if (splitLevelsToColumns && splitLevelsToColumns.depth) {
      for (let i = 0; i < splitLevelsToColumns.depth; i++) {
        let index = (i + 1);
        let label = (splitLevelsToColumns.depth === index) ? splitLevelsToColumns.label : '';
        cols.push({
          label: label,
          key: splitLevelsToColumns.indentColumn,
          colWidth: (splitLevelsToColumns.depth !== index) ? 3 : 30,
          preprocessor: function (row, column) {
            let out = { value: '' };
            if (row[splitLevelsToColumns.levelColumn] === index) {
              out.value = row[splitLevelsToColumns.indentColumn];
              out.merge = { horizontal: splitLevelsToColumns.depth - index };
            }
            return out;
          }
        });
      }
    } else {
      cols.push({ label: __("export_excel_task_name"), key: "text",  preprocessor: (row, params) => {
        return { value: row[params.key] }
      }})
    }

    cols = cols.concat([
      { label: __("export_excel_assigned"), key: "resources", type: 'text' },
      { label: __("export_excel_start_date"), key: "start_date", type: (exportOptions.projectStartDate ? 'dateRelatedToNow' : 'date') },
      { label: __("export_excel_end_date"), key: "end_date", type: (exportOptions.projectStartDate ? 'dateRelatedToNow' : 'date') },
      { label: __("export_excel_deadline"), key: "deadline", type: (exportOptions.projectStartDate ? 'dateRelatedToNow' : 'date') },
      { label: __("export_excel_progress") + ' (%)', key: "progress", type: 'number' },
      { label: __("export_excel_duration") + ' ' + __("export_excel_duration_view_hour"), key: "duration", type: 'number' },
      { label: __("export_excel_estimation"), key: "estimation", type: 'number' },
      { label: __("export_excel_time_tracking") + ' (' + __("common_minutes").toLowerCase() + ')', key: "time_tracking", type: 'number' },
      { label: __("export_excel_status"), key: "status", type: 'text' },
      { label: __("export_excel_priority"), key: "priority", type: 'text' },
      { label: __("export_excel_task_description"), key: "note", type: 'text' },
      { label: __("export_excel_predecessors"), key: "predecessors", type: 'text' }
    ]);

    if (exportOptions.enablePrice) {
      cols.push({ label: __("export_excel_cost"), key: "price", type: 'number' });
      cols.push({ label: __("export_excel_actual_cost"), key: "actual_cost", type: 'number' });
    }

    if (gantt.config.isJira) {
      cols.push({ label: __("export_excel_jira_key"), key: "jira_key", type: 'jira_key' });
    }

    if (userCustomColumns && userCustomColumns.length) {
      _.each(userCustomColumns, customColumn => {
        let fieldType = 'text';
        switch (customColumn.type) {
          case constants.CUSTOM_COLUMNS_TYPES.text.id: fieldType = 'text'; break;
          case constants.CUSTOM_COLUMNS_TYPES.number.id: fieldType = 'number'; break;
          case constants.CUSTOM_COLUMNS_TYPES.date.id: fieldType = 'date'; break;
          case constants.CUSTOM_COLUMNS_TYPES.select.id: fieldType = 'text'; break;
          case constants.CUSTOM_COLUMNS_TYPES.checkbox.id: fieldType = 'number'; break;
          case constants.CUSTOM_COLUMNS_TYPES.color.id: fieldType = 'hex'; break;
          case constants.CUSTOM_COLUMNS_TYPES.tags.id: fieldType = 'tag'; break;
        }
        cols.push({
          label: customColumn.name,
          key: customColumn.id,
          type: fieldType
        });
      });
    }

    cols = cols.concat([

      { label: __("export_excel_created_at"), key: "created", type: 'date' },
      { label: __("export_excel_creator"), key: "creator", type: 'text' },
      { label: __("export_excel_task_type"), key: "type", type: 'text' },
      { label: __("export_excel_project"), key: "project", type: 'longText', preprocessor: (row) => {
        return { value: row.project }
      }},
      { label: __("export_excel_level"), key: "level", type: 'number' }
    ]);

    if (compareWithBaselineOptions) {
      cols.map((val) => {
        if (compareWithBaselineOptions.excludeBaselineColumns.indexOf(val.key) === -1) {
          val.subColumns = {
            'baseline': { attributes: { font: { color: { argb: 'FFCE2525' } } }, subLabel: __('baseline'), key: val.key + compareWithBaselineOptions.postfix, originalKey: val.key, isBaseLine: true, type: val.type, preprocessor: val.preprocessor },
            'current': { subLabel: __('current'), key: val.key, type: val.type, preprocessor: val.preprocessor }
          }
        }
      });
    }

    cols.map((col) => {
      if (workSheetOptions.excludedColumns.indexOf(col.key) == -1) {
        outCols.push(col);
      }
    });

    return outCols;
  },

  taskDataToRow: function (task, compareWithBaselineOptions, calculateOptions, baselineModel) {
    if (!task) return {};

    let baselinePostfix = '';
    let isBaseLine = false;

    if (!calculateOptions) {
      calculateOptions = {};
    }

    if (compareWithBaselineOptions) {
      baselinePostfix = compareWithBaselineOptions.postfix;
      isBaseLine = true;
    }

    var row = {};
    var scaleUnit = gantt.config.durationData.mode;
    let renderedType = task.$rendered_type || task.type;
    const timeTrackingColumn = _.find(gantt.config.columns, column => column.name === 'time_tracking');
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](task.owner_id);
    const projectData = projectsModel.getItem(task.gantt_id);

    if (!compareWithBaselineOptions) {
      row.level = task.$level;
      row.color = task.color;
      row.projectStartDate = calculateOptions.projectStartDate;
      row.time_tracking = timeTrackingColumn ? timeTrackingColumn.template(task, true) : '0';
      row.project = (projectData && projectData.name) ? innerObject.getTaskParentsPath(task, projectData.gantt_id) : "";
      row.created = task.created_at;
      row.creator = (resourceData && resourceData.name) ? resourceData.name : '';

      if (renderedType === 'task') row.type = __('gantt_task').toLowerCase();
      if (renderedType === 'milestone') row.type = __('gantt_milestone').toLowerCase();
      if (renderedType === 'project' && row.level === 0) row.type = __('gantt_project_group').toLowerCase();
      if (renderedType === 'project' && row.level === 1) row.type = __('gantt_project').toLowerCase();
      if (renderedType === 'project' && row.level > 1) row.type = __('gantt_task_group').toLowerCase();

      if (renderedType !== 'task' && renderedType !== 'milestone') {
        row.highlighted = true;
      }

      if (!calculateOptions.multiProjectsList) {
        //  we do not calculate wbs for list view export
        let taskObj = gantt.getTask(task.id);
        row.wbs = gantt.getWBSCode(taskObj);
        row.wbsFull = gantt.getWBSCodeOriginal(taskObj);
        row.wbsDecimal = multDecimal(row.wbsFull);
      }
    }

    if (!task.end_date) {
      task.end_date = gantt.calculateEndDate(_.isString(task.start_date) ? moment(task.start_date).toDate() : task.start_date, task.duration);
    }

    row['estimation' + baselinePostfix] = estimationModule.helpers.getEstimationForTask(task, isBaseLine, baselineModel) * 1 || 0;
    row['price' + baselinePostfix] = estimationModule.helpers.getPriceForTask(task, isBaseLine, baselineModel, true) * 1 || 0;
    row['actual_cost' + baselinePostfix] = estimationModule.helpers.getActualCostValueForTask(task, true) * 1 || 0;
    row['start_date' + baselinePostfix] = moment(task.start_date).toDate();
    row['end_date' + baselinePostfix] = task.end_date ? moment(task.end_date).toDate() : gantt.calculateEndDate(task.start_date, task.duration, scaleUnit);

    if (!calculateOptions.multiProjectsList) {
      row['duration' + baselinePostfix] = gantt.calculateDurationTo(
        moment(task.start_date).toDate(),
        moment(task.end_date).toDate(),
        'hour',
        task.gantt_id
      ).duration;
    } else if (task.duration) {
      row['duration' + baselinePostfix] = task.duration;
    }

    row['progress' + baselinePostfix] = task.progress ? Math.floor(task.progress * 100) : 0;
    row['deadline' + baselinePostfix] = task.deadline ? moment(task.deadline).toDate() : '';
    row['text' + baselinePostfix] = (row.level === 1 && gantt.config.multiview) ? row.project : task.text;
    row['note' + baselinePostfix] = task.note;
    row['jira_key' + baselinePostfix] = task.jira_key;

    row['resources' + baselinePostfix] = '';

    _.each(task.resources, function (resource, i) {
      var divider = row['resources' + baselinePostfix] ? ', ' : '';
      const res = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);

      if (!res) return;

      row['resources' + baselinePostfix] += divider + res.name;
    });

    if (task.$rendered_type === 'task' || task.$rendered_type === 'milestone' || task.type === 'task' || task.type === 'milestone') {
      var statusOptions = globalStore.getters['columns/getColumnByName']('status')?.options;
      if(routerHelper.isCommonRoute()){
        const jiraColumns = globalStore.getters['columns/getJiraColumns'];

        if (jiraColumns && jiraColumns.length) {
          jiraColumns.forEach(item => {
            let column = item.fields.find( field => field.name === 'status');

            if (!column) return;

            statusOptions.push(...column.options);
          })
        }
      }

      for (let i = 0; i < statusOptions.length; i++) {
        if (statusOptions[i].id === task.status) row['status' + baselinePostfix] = statusOptions[i].value;
      }

      var priorityOptions = globalStore.getters['columns/getColumnByName']('priority')?.options;
      if(routerHelper.isCommonRoute()){
        const jiraColumns = globalStore.getters['columns/getJiraColumns'];

        if (jiraColumns && jiraColumns.length) {
          jiraColumns.forEach(item => {
            let column = item.fields.find( field => field.name === 'priority');

            if (!column) return;

            priorityOptions.push(...column.options)
          })
        }
      }

      for (let i = 0; i < priorityOptions.length; i++) {
        if (priorityOptions[i].id === task.priority) row['priority' + baselinePostfix] = priorityOptions[i].value;
      }
    } else {
      row['status' + baselinePostfix] = '';
      row['priority' + baselinePostfix] = '';
    }


    const masterLinks = globalStore.getters['tasksModel/getMasterLinksByTaskId'](task.gantt_id, task.id);
    const valuesLinksWbs = masterLinks.map(link => {
      const taskMaster = gantt.getTask(link.dependencyTaskId);
      return gantt.getWBSCode(taskMaster);
    })

    row['predecessors' + baselinePostfix] = valuesLinksWbs.length ? valuesLinksWbs.join(', ') : '';

    // custom columns excel
    let userCustomColumns = userCustomColumnsModule.getColumnsData();

    if (userCustomColumns && userCustomColumns.length) {
      _.each(userCustomColumns, customColumn => {
        let result;
        let data;
        if (isBaseLine) {
          data = gantt.getBaselineUserCustomValue(task, { name: customColumn.id });
        } else {
          data = gantt.getUserCustomValue(task, customColumn.id);
        }
        result = data.toExcel;
        if (customColumn.type === constants.CUSTOM_COLUMNS_TYPES.color.id && result && result.split) {
          row[customColumn.id + baselinePostfix] = {
            value: result,
            attributes: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FF' + result.split('#')[1]
                }
              }
            }
          };
        }
        row[customColumn.id + baselinePostfix] = result;
      });
    }
    return row;
  },

  getTaskParentsPath (task, ganttId) {
    const breadcrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](task.id, ganttId, {});
    const projectData = projectsModel.getProjectDataById(ganttId);
    const breadcrumbsText = breadcrumbsTasks.reduce(function (sum, elem) {
      return sum + " / " + elem.text;
    }, "");
    const result = projectData.name + breadcrumbsText;
    return result;
  },

  baselineDataToRow: function (task, compareWithBaselineOptions, calculateOptions, baselineModel) {
    let baselineFields = innerObject.taskDataToRow(task, compareWithBaselineOptions, calculateOptions, baselineModel);
    return baselineFields;
  },

  taskToRow: function (task, compareWithBaselineOptions, calculateOptions, baselineModel) {
    let rowBaselineFields = {};
    let row = { data: {}, attributes: {} };
    let rowFields = innerObject.taskDataToRow(task, false, calculateOptions);

    if (baselineModel) {
      rowBaselineFields = innerObject.baselineDataToRow(task.baselineData, compareWithBaselineOptions, calculateOptions, baselineModel);
      _.each(rowBaselineFields, (value, key) => {
        let originalKey = key.split(compareWithBaselineOptions.postfix)[0];
        if (JSON.stringify(rowFields[originalKey]) + '' === JSON.stringify(value) + '') {
          rowBaselineFields[key] = '';
        }
      });
    }

    row.data = Object.assign(rowFields, rowBaselineFields);

    if (row.data.highlighted) {
      row.attributes.font = { bold: true };
    }

    return row;
  }
};

var outputObject = {
  taskToRow: innerObject.taskToRow,
  getTaskColumns: innerObject.getTaskColumns
};
export default outputObject;

