<template>
  <onboarding-section :animate-leave="animateLeave">
    <template #right>
      <div :class="$style.right__wrapper">
        <div
          :class="[
            $style.select__wrapper,
            animateEnter && !animateLeave ? $style.animateEnter : '',
            animateLeave && !animateEnter ? $style.animateLeave : '',
          ]"
        >
          <div
            v-for="item in familiarityItems"
            :id="`gp_autotest_registration_4_step_selectItem_${item.value}`"
            :key="item.value"
            :class="$style.item__wrapper"
            @click="handleItemClick(item.value)"
          >
            <div :class="$style.item__title">
              {{ item.title }}
            </div>
            <div :class="$style.item__description">
              {{ item.description }}
            </div>
            <div :class="$style.icons__wrapper">
              <div
                v-for="(iconConfig, index) in item.icons"
                :key="index"
                :class="$style.item__icon"
              >
                <vgp-svg-image
                  v-bind="iconConfig"
                />
              </div>
            </div>
          </div>
        </div>
        <portal
          to="onboarding-footer"
        >
          <vgp-button
            id="gp_autotest_registration_4_step_submit_button"
            :class="[
              $style.submit_button,
              animateEnter && !animateLeave ? $style.animateEnter : '',
              animateLeave && !animateEnter ? $style.animateLeave : '',
            ]"
            type="text-green"
            :label="locales('onboarding_skip_step_btn_label')"
            small
            @onClick="nextStep(true)"
          />
        </portal>
      </div>
    </template>
  </onboarding-section>
</template>

<script>
import { mapState } from 'vuex';
import OnboardingSection from '../OnboardingSection.vue';
import vgpSvgImage from '../../../common/VueComponents/VueIcon/vgpSvgImage.vue';
import profile from '../../../../models/profile';
import rights from '../../../../components/rights/index';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';

export default {
  name: 'MembersInfo',
  components: {
    OnboardingSection,
    vgpSvgImage,
  },
  data() {
    return {
      locales: __,
      animateEnter: false,
      animateLeave: false,
      familiarityItems: [
        {
          title: __('onboarding_familiarity_step_first_time_title'),
          description: __('onboarding_familiarity_step_first_time_descr'),
          value: 'no_tool',
          icons: [
            {
              name: 'onboarding-hat',
            },
          ],
        },
        {
          title: __('onboarding_familiarity_step_limited_title'),
          description: __('onboarding_familiarity_step_limited_descr'),
          value: 'excel_or_google_sheet',
          icons: [
            {
              name: 'excel',
            },
            {
              name: 'googleSheets',
            },
          ],
        },
        {
          title: __('onboarding_familiarity_step_advanced_title'),
          description: __('onboarding_familiarity_step_advanced_descr'),
          value: 'ms_project',
          icons: [
            {
              name: 'msProject',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
  },
  mounted() {
    setTimeout(() => {
      this.animateEnter = true;
    }, 0);
  },
  methods: {
    handleItemClick(value) {
      this.nextStep();
      profile.saveSettings({ user_tool_used_before: value });
    },
    nextStep(isSkip = false) {
      if (this.animateLeave) {
        return;
      }

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
        if (isSkip) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
        }
      }

      this.animateEnter = false;
      this.animateLeave = true;
      setTimeout(() => {
        this.$store.dispatch('onboarding/nextStep');
      }, 750);
    },
  },
};
</script>

<style lang="less" module>
@import "../../../../less/_variables.less";

.right__wrapper {
  margin-right: 18px;
}

.select__wrapper {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);
  display: flex;
  flex-direction: column;

  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }

}

.item__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #E3E8EC;
  border-radius: 5px;
  padding: 8px 0 10px 21px;
  width: 361px;
  min-height: 72px;
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:hover {
    cursor: pointer;
    background: #337870;
    box-shadow: 0px 6px 20px rgba(51, 120, 112, 0.35);
    .item__title, .item__description {
      color: @white;
    }
  }

  &:active {
    box-shadow: none;
  }
}

.item__title {
  font-family: Lato-Bold, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #586972;
}

.item__description {
  font-family: Lato-Regular, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #586972;
  max-width: 262px;
}

.icons__wrapper {
  position: absolute;
  right: -19px;
  display: flex;
  gap: 4px;
}

.item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  background: #FFFFFF;
  box-shadow: 0px 6px 13px rgba(0, 86, 76, 0.26);
  border-radius: 5px;
}

.submit_button {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  &.animateLeave {
    transition: opacity .2s, transform .2s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .3s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
