// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_mwxjX2xa {\n  height: 100%;\n  overflow: auto;\n}\n.wrapper_mwxjX2xa .dashboard_wrapper_mRhi0vQV {\n  display: grid;\n  width: 1036px;\n  margin: 0 auto;\n  gap: 24px;\n  grid-template-columns: 1fr 1fr;\n  padding: 24px 16px;\n}\n.wrapper_mwxjX2xa .dashboard_wrapper_mRhi0vQV .item_Bq_D5NOS.large_iE0nStX0 {\n  grid-column: 1/3;\n}\n", "",{"version":3,"sources":["webpack://./public/views/portfolioDashboard/less/portfolioDashboard.less","webpack://./portfolioDashboard.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;ACCF;ADHA;EAKI,aAAA;EACA,aAAA;EACA,cAAA;EACA,SAAA;EACA,8BAAA;EACA,kBAAA;ACCJ;ADEM;EACE,gBAAA;ACAR","sourcesContent":[".wrapper {\n  height: 100%;\n  overflow: auto;\n\n  .dashboard_wrapper {\n    display: grid;\n    width: 1036px;\n    margin: 0 auto;\n    gap: 24px;\n    grid-template-columns: 1fr 1fr;\n    padding: 24px 16px;\n  \n    .item {\n      &.large {\n        grid-column: ~\"1/3\"; // workaround for less file (compiles as fraction) \n      }\n    }\n  }\n}\n\n\n",".wrapper {\n  height: 100%;\n  overflow: auto;\n}\n.wrapper .dashboard_wrapper {\n  display: grid;\n  width: 1036px;\n  margin: 0 auto;\n  gap: 24px;\n  grid-template-columns: 1fr 1fr;\n  padding: 24px 16px;\n}\n.wrapper .dashboard_wrapper .item.large {\n  grid-column: 1/3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_mwxjX2xa",
	"dashboard_wrapper": "dashboard_wrapper_mRhi0vQV",
	"item": "item_Bq_D5NOS",
	"large": "large_iE0nStX0"
};
export default ___CSS_LOADER_EXPORT___;
