export default function(count) {
    return {
        id: "vue_test_layout",
        rows: [
            {
                cols: [
                    {
                        view: "template",
                        borderless: true,
                        template: `<p>Webix count: </p>`,
                        height: 20,
                        width: 100
                    },
                    {
                        view: "template",
                        template: count,
                        borderless: true,
                        height: 20
                    }
                ]
            },
            {
                view: "button",
                borderless: true,
                value: `Inc Webix button`,
                on: {
                    onItemClick: function() {
                        this.$scope.$emit("inc-count")
                    }
                }
            }
        ]
    }
}