var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$.details_tooltip, "calendar-page-event-details-tooltip"],
      style: _vm.styles,
      attrs: { "data-id": _vm.config.taskData.id },
    },
    [
      _c("div", { class: _vm.$.header }, [
        _c("div", { class: _vm.$.breadcrumbs }, [
          _vm._v(
            "\n      " + _vm._s(_vm.shortenedBreadcrumbsIfDoesntFit) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$.title }, [
          _vm._v("\n      " + _vm._s(_vm.config.taskData.text) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$.body }, [
        _c("div", { class: _vm.$.task_details }, [
          _c(
            "div",
            { class: _vm.$.task_details__icon },
            [_c("svg-sprite", { attrs: { name: "calendar", size: 20 } })],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$.task_details__rows }, [
            _c("div", { class: _vm.$.row }, [
              _c("div", { class: _vm.$.row__title }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.locales("gantt_grid_start_date") + ":") +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row__value }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formattedDate.startDate) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$.row }, [
              _c("div", { class: _vm.$.row__title }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.locales("gantt_grid_end_date") + ":") +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row__value }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formattedDate.endDate) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$.task_details }, [
          _c(
            "div",
            { class: _vm.$.task_details__icon },
            [_c("svg-sprite", { attrs: { name: "time", size: 20 } })],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$.task_details__rows }, [
            _c("div", { class: _vm.$.row }, [
              _c("div", { class: _vm.$.row__title }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.locales("gantt_grid_duration") + ":") +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row__value }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formattedDuration) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$.row }, [
              _c("div", { class: _vm.$.row__title }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.locales("gantt_grid_estimation") + ":") +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row__value }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formattedEstimation) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$.task_details }, [
          _c(
            "div",
            { class: _vm.$.task_details__icon },
            [_c("svg-sprite", { attrs: { name: "settings", size: 20 } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$.task_details__rows, style: { paddingTop: 0 } },
            [
              _c("div", { class: _vm.$.row }, [
                _c("div", { class: _vm.$.row__title }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.locales("filter_option_assignee_label") + ":"
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm.config.taskData.resources.length
                  ? _c(
                      "div",
                      { class: _vm.$.resources },
                      _vm._l(_vm.resourcesChips, function (item, index) {
                        return _c(
                          "div",
                          { key: index, class: _vm.$.chip },
                          [
                            item.counter
                              ? _c("div", { class: _vm.$.chip__count }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s("+" + item.count) +
                                      "\n              "
                                  ),
                                ])
                              : _c("vgp-avatar", {
                                  attrs: { src: item.photo, "custom-size": 22 },
                                }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("div", { class: _vm.$.resources }, [
                      _c(
                        "div",
                        { class: _vm.$.unassigned_icon },
                        [
                          _c("svg-sprite", {
                            attrs: { name: "assigned", size: 20, type: "bold" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { class: _vm.$.unassigned_text }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("calendar_page_unassigned")) +
                            "\n            "
                        ),
                      ]),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row }, [
                _c("div", { class: _vm.$.row__title }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.locales("filter_option_status_label") + ":") +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: _vm.$.row__status_ic,
                    style: { marginLeft: "-6px" },
                  },
                  [_c("span", { style: { background: _vm.status.color } })]
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$.row__value }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.status.locale) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.row }, [
                _c("div", { class: _vm.$.row__title }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.locales("overview_progress") + ":") +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.row__value }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.config.taskData.progress === 0
                          ? _vm.config.taskData.progress + "%"
                          : _vm.config.taskData.progress * 100 + "%"
                      ) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.row },
                [
                  _c("div", { class: _vm.$.row__title }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.locales("filter_option_priority_label") + ":"
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("svg-sprite", {
                    style: { color: _vm.priority.color, marginLeft: "-6px" },
                    attrs: { name: "priority", size: 20 },
                  }),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.row__value }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.priority.locale) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }