var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: "",
      "body-style": "noPaddings",
      "background-image":
        "https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: [_vm.$style.body__wrapper] }, [
              _c("div", { class: [_vm.$style.body__header] }, [
                _c(
                  "div",
                  { class: _vm.$style.logo },
                  [
                    _c("vgp-svg-image", {
                      attrs: { name: "logo", color: "#003D36" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.body__content },
                [
                  _c("div", { class: _vm.$style.body__content_left }, [
                    _c("h1", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locales("password_restore_title")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ValidationObserver", {
                    ref: "form",
                    class: _vm.$style.body__content_right,
                    attrs: { tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          return [
                            _c(
                              "div",
                              { class: _vm.$style.inputs__wrapper },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    autocomplete: "username",
                                    hidden: "",
                                  },
                                  domProps: { value: _vm.form.email },
                                }),
                                _vm._v(" "),
                                _c("vgp-label-slot", {
                                  attrs: {
                                    label: _vm.locales(
                                      "onboarding_password_label"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { class: _vm.$style.input__wrap },
                                              [
                                                _c("tooltip", {
                                                  attrs: {
                                                    position: "left",
                                                    content: _vm.locales(
                                                      "pass_strength_descr"
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "body",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "ValidationProvider",
                                                              {
                                                                ref: "passwordValidationProvider",
                                                                attrs: {
                                                                  rules:
                                                                    "min:8",
                                                                  "custom-messages":
                                                                    {
                                                                      min: _vm.locales(
                                                                        "validation_error_password_min_length"
                                                                      ),
                                                                    },
                                                                  vid: "password",
                                                                  mode: _vm.customValidationProviderMode,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var errors =
                                                                            ref.errors
                                                                          return [
                                                                            _c(
                                                                              "vgp-input",
                                                                              {
                                                                                ref: "password",
                                                                                attrs:
                                                                                  {
                                                                                    autocomplete:
                                                                                      "new-password",
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .password,
                                                                                    "is-error":
                                                                                      !!errors[0] ||
                                                                                      _vm.isPasswordCommonError,
                                                                                    placeholder:
                                                                                      _vm.locales(
                                                                                        "onboarding_password_placeholder"
                                                                                      ),
                                                                                    "input-type":
                                                                                      _vm.passwordInputType,
                                                                                    "icons-right":
                                                                                      [
                                                                                        _vm.getIconConfig(
                                                                                          "passwordInputType"
                                                                                        ),
                                                                                      ],
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    _vm.handlePasswordInput,
                                                                                  onFocus:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleOnFocus(
                                                                                        "password"
                                                                                      )
                                                                                    },
                                                                                  onBlur:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.passwordStrengthState.show = false
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            errors[0] ||
                                                                            _vm.isPasswordCommonError
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      _vm
                                                                                        .$style
                                                                                        .input__error,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                          " +
                                                                                        _vm._s(
                                                                                          errors[0] ||
                                                                                            _vm.locales(
                                                                                              "validation_error_common_password"
                                                                                            )
                                                                                        ) +
                                                                                        "\n                        "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                                _vm._v(" "),
                                                _vm.passwordStrengthState.show
                                                  ? _c(
                                                      "password-strength-indicator",
                                                      _vm._b(
                                                        {},
                                                        "password-strength-indicator",
                                                        _vm.passwordStrengthState,
                                                        false
                                                      )
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("vgp-label-slot", {
                                  attrs: {
                                    label: _vm.locales(
                                      "password_restore_password_confirmation_label"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { class: _vm.$style.input__wrap },
                                              [
                                                _c("ValidationProvider", {
                                                  ref: "passwordConfirmationValidationProvider",
                                                  attrs: {
                                                    rules: "confirmed:password",
                                                    vid: "passwordConfirmation",
                                                    "custom-messages": {
                                                      confirmed: _vm.locales(
                                                        "validation_error_password_does_not_match_confirm_password"
                                                      ),
                                                    },
                                                    mode: _vm.customValidationProviderMode,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("vgp-input", {
                                                              ref: "passwordConfirmation",
                                                              attrs: {
                                                                "is-error":
                                                                  errors[0],
                                                                placeholder:
                                                                  _vm.locales(
                                                                    "password_restore_password_confirmation_placeholder"
                                                                  ),
                                                                "input-type":
                                                                  _vm.passwordConfirmationInputType,
                                                                "icons-right": [
                                                                  _vm.getIconConfig(
                                                                    "passwordConfirmationInputType"
                                                                  ),
                                                                ],
                                                              },
                                                              on: {
                                                                onFocus:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleOnFocus(
                                                                      "passwordConfirmation"
                                                                    )
                                                                  },
                                                                onBlur:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.passwordStrengthState.show = false
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .passwordConfirmation,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "passwordConfirmation",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.passwordConfirmation",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            errors[0]
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      _vm.$style
                                                                        .input__error,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          errors[0]
                                                                        ) +
                                                                        "\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("vgp-button", {
                              class: [_vm.$style.submit__button],
                              attrs: {
                                disabled: _vm.isSubmitButtonDisabled,
                                "is-loading": _vm.isFormSubmitting,
                                type: "green",
                                label: _vm.locales(
                                  "password_restore_save_password_btn_label"
                                ),
                              },
                              on: {
                                onClick: function ($event) {
                                  return handleSubmit(_vm.submit)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }