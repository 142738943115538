import app from '../../app';
import _ from '../../libs/lodash';

let apiKeys = {};

if (app.config.mode.isBase) {
  apiKeys = new webix.DataCollection({
    url: `rest->/api/team/apiKeys?${new Date().getTime()}`,
  });

  apiKeys.getData = function () {
    const data = apiKeys.serialize(true);
    data.sort((a, b) => {
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });
    return data;
  };

  apiKeys.create = function () {
    return webix.ajax().post('/api/team/apiKeys', {}, response => {
      const data = JSON.parse(response);
      if (data && data.apiKey) {
        apiKeys.add(data.apiKey);
      }
    });
  };

  apiKeys.delete = function (id) {
    return webix.ajax().del('/api/team/apiKeys', { id }, response => {
      const data = JSON.parse(response);
      if (data && data.status) {
        apiKeys.remove(id);
      }
    });
  };

  apiKeys.attachEvent('onAfterDelete', id => {
    app.trigger('apiKeys:updated');
  });

  apiKeys.attachEvent('onAfterAdd', id => {
    app.trigger('apiKeys:updated');
  });

  app.on('app:start', () => {
    webix.promise.all([apiKeys.waitData]).then(() => {
      app.checkInit('apiKeys');
    });
  });
} else {
  app.checkInit('apiKeys');
}

export default apiKeys;
