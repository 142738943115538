<template>
  <skeleton-wrapper>
    <template #body>
      <div :class="$.skeleton_wrapper">
        <div :class="[$.skeleton_box, $.first]">
          <div :class="$.skeleton_row_main">
            <div :class="$.skeleton_mb30">
              <skeleton-item
                :style="{width: `124px`, height: '12px'}"
                :type="'bar'"
              />
            </div>
            <skeleton-item
              :style="{width: `496px`, height: '12px'}"
              :type="'bar'"
            />
            <skeleton-item
              :style="{width: `496px`, height: '12px'}"
              :type="'bar'"
            />
            <skeleton-item
              :style="{width: `344px`, height: '12px'}"
              :type="'bar'"
            />
          </div>
          <div :class="$.skeleton_row_main">
            <div :class="[$.skeleton_line, $.skeleton_mb22]">
              <div
                style="margin-right: 105px"
              >
                <div
                  :class="[$.skeleton_line, $.skeleton_mb8]"
                >
                  <div
                    :class="[$.skeleton_mr8]"
                  >
                    <skeleton-item
                      :style="{width: `52px`, height: '12px'}"
                      :type="'bar'"
                    />
                  </div>
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="$.skeleton_line">
                  <div
                    :class="[$.skeleton_mr8]"
                  >
                    <skeleton-item
                      :style="{width: `52px`, height: '12px'}"
                      :type="'bar'"
                    />
                  </div>
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
              <div>
                <div :class="[$.skeleton_mb8]">
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div>
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
            </div>
            <div :class="[$.skeleton_line]">
              <div :class="[$.skeleton_mr24]">
                <div :class="[$.skeleton_mb16]">
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div>
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
              <div :class="[$.skeleton_mr16]">
                <div :class="[$.skeleton_mb16]">
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div>
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
              <div :class="[$.skeleton_bl, $.skeleton_pl24 ]">
                <div :class="[$.skeleton_mb16]">
                  <skeleton-item
                    :style="{width: `100px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div>
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="$.skeleton_flex_row">
          <div :class="[$.skeleton_box, $.direction_column]">
            <div :class="[$.skeleton_mb26]">
              <skeleton-item
                :style="{width: `124px`, height: '12px'}"
                :type="'bar'"
              />
            </div>
            <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_row_center, $.skeleton_flex_column_center]">
              <div :class="[$.skeleton_circle]">
                <skeleton-item
                  :style="{width: `241px`, height: '241px', 'border-width': '50px'}"
                  :type="'donut'"
                />
              </div>
              <div :class="[$.skeleton_list]">
                <div :class="[$.skeleton_mb12, $.skeleton_flex]">
                  <skeleton-item
                    :style="{width: `24px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_mb12, $.skeleton_flex]">
                  <skeleton-item
                    :style="{width: `24px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_mb12, $.skeleton_flex]">
                  <skeleton-item
                    :style="{width: `24px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_flex]">
                  <skeleton-item
                    :style="{width: `24px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
            </div>
            <div :class="[ $.skeleton_flex, $.skeleton_flex_row_center, $.skeleton_flex_column_center]">
              <div :class="[$.skeleton_flex, $.skeleton_flex_row_center, $.skeleton_flex_column_center, $.skeleton_line_padding]">
                <div :class="[$.skeleton_flex, $.skeleton_mr16]">
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `24px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_flex, $.skeleton_bl, $.skeleton_mr16, $.skeleton_pl24 ]">
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <div>
                    <skeleton-item
                      :style="{width: `24px`, height: '12px'}"
                      :type="'bar'"
                    />
                  </div>
                </div>
                <div :class="[$.skeleton_flex, $.skeleton_bl, $.skeleton_pl24 ]">
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                  <div>
                    <skeleton-item
                      :style="{width: `24px`, height: '12px'}"
                      :type="'bar'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[$.skeleton_box, $.direction_column]">
            <div :class="[$.skeleton_mb26]">
              <skeleton-item
                :style="{width: `124px`, height: '12px'}"
                :type="'bar'"
              />
            </div>
            <div :class="[$.skeleton_chart, $.skeleton_flex, $.skeleton_flex_row_center, $.skeleton_flex_column_center]">
              <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_mb26, $.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_flex, $.skeleton_flex_column_center]">
                <skeleton-item
                  :style="{width: `24px`, height: '12px'}"
                  :type="'bar'"
                />
                <skeleton-item
                  :style="{width: `349px`, height: '1px'}"
                  :type="'bar'"
                />
              </div>
              <div :class="[$.skeleton_chart_columns]">
                <div :class="[$.skeleton_chart_columns_item]">
                  <skeleton-item
                    :style="{width: `57px`, height: '77px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_chart_columns_item]">
                  <skeleton-item
                    :style="{width: `57px`, height: '191px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
                <div :class="[$.skeleton_chart_columns_item]">
                  <skeleton-item
                    :style="{width: `57px`, height: '130px'}"
                    :type="'bar'"
                  />
                  <skeleton-item
                    :style="{width: `52px`, height: '12px'}"
                    :type="'bar'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </skeleton-wrapper>
</template>

<script>

import SkeletonItem from '$$vueCmp/skeletons/skeletonItem.vue';
import SkeletonWrapper from '$$vueCmp/skeletons/skeletonWrapper.vue';

export default {
  name: 'SkeletonMain',
  components: { SkeletonWrapper, SkeletonItem },

};
</script>

<style module="$" lang="less" src="./../less/skeleton.less"></style>
