var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "add-columns-popup",
      style: { height: _vm.height + "px" },
      attrs: { id: "gp_autotest_listview_add_columns_popup" },
    },
    [
      _c("div", { staticClass: "add-columns-popup--header" }, [
        _c("span", [_vm._v(_vm._s(_vm.header_locale))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("webix-ui", {
              staticClass: "icon_helper icon_helper_hover",
              attrs: {
                id: "gp_autotest_listview_add_columns_popup_btn_helper",
                config: _vm.helper.columns,
              },
            }),
            _vm._v(" "),
            _c("webix-ui", {
              staticClass: "close-icon cursor-pointer",
              attrs: {
                id: "gp_autotest_listview_add_columns_popup_btn_close",
                config: _vm.closeIcon,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          key: _vm.items.length,
          staticClass: "gantt_scroll",
          style: { height: +_vm.height - 44 + "px", "padding-top": "10px" },
        },
        _vm._l(_vm.groupSet, function (groupname) {
          return _c(
            "div",
            { key: _vm.componentKey + groupname },
            [
              groupname
                ? _c("div", { staticClass: "group" }, [
                    _c("span", { staticClass: "group-name" }, [
                      _vm._v(" " + _vm._s(groupname) + " "),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "group-line" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.getGroupItems(groupname), function (item, index) {
                return _c(
                  "div",
                  {
                    key: "add-column-" + index + groupname,
                    staticClass: "add-columns-popup--row align-center d-flex",
                    attrs: {
                      id:
                        "gp_autotest_listview_add_columns_popup_item_" +
                        item.id,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "align-center d-flex fix-width" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tooltip-gantt add-columns--text ",
                            attrs: {
                              "data-position": "left",
                              "data-key": item.tooltip,
                              "data-y": "10",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.text) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "add-columns-popup--checkbox" },
                          [
                            _c("customCheckBox", {
                              attrs: {
                                id:
                                  "gp_autotest_listview_add_columns_popup_item_checkbox_" +
                                  item.id,
                                "v-model": _vm.chacked,
                                value: item,
                              },
                              on: { change: _vm.changeSelected },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    item.extra
                      ? _c(
                          "div",
                          { staticClass: "add-columns-popup--extra" },
                          [
                            item.extra.name
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "add-columns--text" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.extra.text) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "add-columns-popup--checkbox",
                                    },
                                    [
                                      _c("customCheckBox", {
                                        attrs: {
                                          "v-model": _vm.extra,
                                          value: item.extra.name,
                                          block: _vm.isBlockExtraBox(
                                            item.extra.name
                                          ),
                                        },
                                        on: { change: _vm.changeExtra },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            item.extra.helper
                              ? _c("webix-ui", {
                                  staticClass: "icon_helper row-icon-extra",
                                  attrs: { config: _vm.helper.cost },
                                })
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.custom && item.tooltipExtra && _vm.showCustomColumnInfo
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex  align-center flex-grow-1 columns_projects_info",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gantt_notification_tooltip tooltip-gantt row-icon-extra",
                                attrs: {
                                  "data-html": "true",
                                  "data-padding": "8px 12px",
                                  "data-maxwidth": "320",
                                  "data-position": "left",
                                  "data-y": "8",
                                  "data-x": "15",
                                  "data-id": item.id + "-col-add",
                                },
                              },
                              [
                                _c("sprite", { attrs: { name: "info" } }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tooltip-gantt-html" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tooltip-gantt-html-description global_gantt_columns_tooltip ql-editor",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tooltip-header" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.tooltipExtra.header
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "tooltip-divider",
                                        }),
                                        _vm._v(" "),
                                        _c("ul", {
                                          staticClass: "tooltip-list",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.tooltipExtra.descriptionHtml
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }