import _ from "../../../libs/lodash";

const innerHandlers = {
  run: function () {
    const overlay = document.createElement('div');
    overlay.setAttribute('class', 'export-overlay');

    document.body.insertBefore(overlay, document.body.firstChild);

    gantt.attachEvent('gantt:export:settings:overlay', function (isTrial) {
      if (isTrial) {
        overlay.classList.add('show');
      } else {
        overlay.classList.remove('show');
      }
    })
  }
};

const outputObject = {
  init: {
    run: _.once(innerHandlers.run),
  }
};

export default outputObject;