c<template>
  <div class="upgrade-plan-popup">
    <div class="head">
      <p class="main-title">
        {{ locales(`${config.title}`) }}
      </p>
      <div
        v-if="config.subtitle"
        class="desc"
        v-html="config.subtitle"
      />
    </div>
    <div
      v-if="loader"
      class="loader-body"
    >
      <loader />
    </div>
    <div
      v-else
      class="body"
    >
      <div class="edit-panel">
        <div class="edit-panel__controls">
          <user-count-select
            :label="locales('upgrade_select_label')"
            :class-name="'borderless'"
            :is-annual="isAnnual"
            :is-current-plan="isCurrentPlan"
            :number-of-plan="numberOfPlanItems"
            :is-popup="true"
            :type="config.mode"
            :plan="config.newPlan"
          />
          <div
            v-if="!config.isAnnual && (config.mode === 'upgrade-plan' || config.mode === 'change-team-size')"
            :class="['toggle-widget', isAnnual ? 'annual' : 'month']"
          >
            <p
              class="toggle-widget__text toggle-widget__text--month"
              @click="toggleAnnual(false)"
            >
              {{ locales('plans_monthly_locale') }}
            </p>
            <div
              class="toggle-rounded"
              @click="toggleAnnual(!isAnnual)"
            >
              <div class="toggle-rounded__target" />
            </div>
            <p
              class="toggle-widget__text toggle-widget__text--annual"
              @click="toggleAnnual(true)"
            >
              {{ locales('plans_annually_locale') }}
            </p>
            <div :class="['toggle-widget__sticky-item', isAnnual && nextUserCount === 1 ? 'dnone' : '']">
              <p class="toggle-widget__text light">
                {{ locales('save_up_locale') }}
              </p>
            </div>
          </div>
        </div>
        <div class="edit-panel__plans">
          <div class="plan-info">
            <div class="plan-info__about">
              <p class="inner-text label">
                {{ locales('upgrade_popup_current_label') }}
              </p>
              <p class="inner-text name">
                {{ userCount + ' ' + `${+userCount === 1 ? locales('one_user_locale') : locales('users_locale')}` }} <br><span>{{ `${locales(config.currentPlan.name + '_plan_locale')}` }}</span>
              </p>
              <p class="inner-text sum">
                {{ `$${planDetails.subscriptionPrice} ${subscriptionPeriod ? locales('upgrade_period_annual') : locales('upgrade_period_month')}` }}
              </p>
            </div>
            <div
              v-if="isShowTotalPrice"
              class="plan-info__total"
            >
              <p class="inner-text name">
                {{ locales('upgrade_balance_key') }}
              </p>
              <p class="inner-text label">
                <span>{{ `$${+planDetails.unusedBudget.toFixed(2)}` }}</span> ({{ `${planDetails.unusedDays} ${locales('days_locale')}` }})
              </p>
            </div>
          </div>
          <div class="arrow-delimiter">
            <div class="arrow" />
          </div>
          <div
            :class="['plan-info', isCurrentUserCount
              && isCurrentPlan
              && isCurrentPeriod
              && (config.mode === 'change-team-size' || config.mode === 'upgrade-plan')
              ? 'is-disable'
              : '']"
          >
            <div class="plan-info__about">
              <p class="inner-text label">
                {{ locales('upgrade_popup_updated_label') }}
              </p>
              <p class="inner-text name">
                {{ nextUserCount + ' ' + `${+nextUserCount === 1 ? locales('one_user_locale') : locales('users_locale')}` }} <br><span :class="config.newPlan.name">{{ `${locales(config.newPlan.name + '_plan_locale')}` }}</span>
              </p>
              <p class="inner-text sum">
                {{ `$${newSubcsriptionPrice} ${isAnnual ? locales('upgrade_period_annual') : locales('upgrade_period_month')}` }}
              </p>
            </div>
            <div
              v-if="isShowTotalPrice"
              class="plan-info__total"
            >
              <p class="inner-text name">
                {{ locales('upgrade_pay_extra_key') }}
              </p>
              <p class="inner-text label">
                {{ `$${payExtra}` }}
              </p>
            </div>
          </div>
        </div>
        <div class="edit-panel__period">
          <p class="inner-text label">
            {{ `${locales('next_payment_locale')}: ${isCurrentUserCount
              && isCurrentPlan
              && isCurrentPeriod
              && (config.mode === 'change-team-size' || config.mode === 'upgrade-plan' )
              ? currentNextPaymentDay
              : newPaymentDay}` }}
          </p>
          <p
            class="inner-text small payment-footer-info"
            @click="liveChatClick"
            v-html="locales('next_payment_description_locale') "
          />
        </div>
      </div>
      <div class="form-panel">
        <div class="form">
          <p class="form-title">
            {{ locales('upgrade_form_title') }}
          </p>
          <div class="input-box">
            <div
              class="input-box__label"
            >
              {{ locales('upgrade_form_label_plan') }}
            </div>
            <div
              :class="['input-box__input input-box__plan-name', config.newPlan.name]"
            >
              {{ `${locales(config.newPlan.name + '_plan_locale')}` }}
            </div>
          </div>
          <div class="input-box">
            <div
              class="input-box__label"
            >
              {{ locales('upgrade_form_label_team') }}
            </div>
            <div
              class="input-box__input"
            >
              {{ `${nextUserCount} ${locales('input_value_editors')}` }}
            </div>
          </div>
          <div class="input-box">
            <div
              class="input-box__label"
            >
              {{ locales('upgrade_form_label_period') }}
            </div>
            <div
              class="input-box__input"
            >
              {{ newPaymentPeriod }}
            </div>
          </div>
          <div class="form-total">
            <p class="form-total__text">
              {{ locales('upgrade_tottal_label') }}
            </p>
            <span class="form-total__text">
              {{ `$${payExtra}` }}
            </span>
          </div>
          <div>
            <span class="payment-remark-upgrade-info">
              {{ locales('upgrade_remark_about_payment_and_tax') }}
            </span>
          </div>
          <global-btn
            :config="{
              isGlobal: true,
              size: 'normal',
              color: 'blue',
              width: '100%',
              locale: locales('apply_locale'),
              isDisable: (isCurrentUserCount
                && isCurrentPlan
                && isCurrentPeriod
                && (config.mode === 'change-team-size' || config.mode === 'upgrade-plan'))
                || isDisabledApply,
              handler: onApplyNewPlan,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from '../../../app';
import plansModel from '../../../models/plans';
import dateHelper from '../../../helpers/dateFormats';
import svgIconForVue from '../../common/svgIconForVue';
import userCountSelect from '../components/select.vue';

import globalBtn from '../../common/VueComponents/globalButton/globalButton.vue';
import loader from '../../common/VueComponents/loader/loader.vue';

import infoIcon from '../../../svg/ic_info.svg';

export default {
  components: {
    globalBtn,
    loader,
    userCountSelect,
  },
  props: ['config'],
  data() {
    return {
      loader: true,
      locales: __,
      isAnnual: '',
      numberOfPlan: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        // 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
      ],
      numberOfPlanItems: [],
      planDetails: {},
      currentUserCount: '',
      newSubcsriptionPrice: '',
      currentDay: '',
      currentNextPaymentDay: '',
      newPaymentDay: '',
      newPaymentPeriod: '',
      payExtra: '',
      isShowSelectList: false,
      isShowTotalPrice: true,
      isCurrentUserCount: '',
      isCurrentPlan: '',
      isCurrentPeriod: '',
      isDisabledApply: false,
      icons: {
        infoIcon: svgIconForVue(infoIcon, 24, 24),
      },
    };
  },
  computed: {
    nextUserCount() {
      return this.$store.getters['paymentPlans/nextCount'];
    },
    userCount() {
      return user.subscription.user_count || 1;
    },
    subscriptionPeriod() {
      return user.accountPlanData.is_annual;
    },
  },
  watch: {
    async nextUserCount(val) {
      const userCount = user.subscription.user_count || 1;

      if (+val !== +userCount) {
        this.isCurrentUserCount = false;
      } else {
        this.isCurrentUserCount = true;
      }

      if ((!this.isAnnual && val === 1) || (val < this.currentUserCount)) {
        return;
      }
      this.checkPlan(this.isAnnual, val);
      this.isDisabledApply = true;
      await this.loadPlanDetails();
      await this.calcPrice(val);
      await this.setDatePeriod();
      this.isDisabledApply = false;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener('click', this.checkSelectNode);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.checkSelectNode);
  },
  methods: {
    async init() {
      this.isAnnual = this.config.isAnnual;
      this.currentNextPaymentDay = dateHelper.formatDateNoTime(user.subscription.date_to);
      const userCount = user.subscription.user_count || 1;
      let nextCount;

      // if (this.config.newPlan.id === this.config.currentPlan.id) {
      //   this.isCurrentPlan = true;
      // } else {
      //   this.isCurrentPlan = false;
      // }

      if (this.config.mode === 'change-team-size') {
        const idx = this.numberOfPlan.indexOf(userCount);

        if (userCount % 5 !== 0) {
          nextCount = this.numberOfPlan.find(num => num > userCount);
        } else {
          nextCount = this.numberOfPlan[idx + 1];
        }
      } else if (this.config.countForBuy) {
        nextCount = this.config.countForBuy;
      } else {
        nextCount = userCount;
      }
      app.trigger('sideBar:closeProjects');
      userExtAnalytics.log('payment_page_opened', { type: this.config.isAnnual ? 'annually' : 'monthly', count: nextCount });
      this.checkPlan(this.config.isAnnual, nextCount);
      this.$store.commit('paymentPlans/setNextCount', nextCount);
      this.setSelectItems();
      await this.loadPlanDetails();
      await this.calcPrice(nextCount);
      await this.setDatePeriod();
      this.loader = false;
    },
    checkPlan(annual, count) {
      const period = annual ? 1 : 0;

      this.isCurrentPlan = this.config.newPlan.id === user.accountPlanData.payment_plans_id;
      this.isCurrentUserCount = count === user.subscription.user_count;
      this.isCurrentPeriod = period === user.accountPlanData.is_annual;

      if (this.config.mode === 'change-team-size' || this.config.mode === 'upgrade-plan') {
        if (this.isCurrentPlan && this.isCurrentUserCount && this.isCurrentPeriod) {
          this.isShowTotalPrice = false;
        } else {
          this.isShowTotalPrice = true;
        }
      }
    },
    setSelectItems() {
      const userCount = this.$store.getters['paymentPlans/nextCount'];
      const currentUserCount = user.subscription.user_count;
      const preparedCountItems = this.numberOfPlan.map(num => {
        const countConfig = {};

        countConfig.value = num;
        if (!this.isAnnual && num === 1) {
          countConfig.disabled = true;
        }

        if (this.config.countForBuy) {
          if (num < currentUserCount) {
            countConfig.disabled = true;
          }
        } else if (num < currentUserCount) {
          countConfig.disabled = true;
        } else if (this.config.mode === 'change-team-size' && num <= currentUserCount) {
          countConfig.disabled = true;
        } else {
          countConfig.disabled = false;
        }

        return countConfig;
      });

      this.numberOfPlanItems = preparedCountItems;
    },
    async loadPlanDetails() {
      const data = await plansModel.getSubscriptionStatus(user.subscription.provider);

      this.planDetails = data;
    },
    toggleSelect() {
      this.isShowSelectList = !this.isShowSelectList;
    },
    showSelectList() {
      this.isShowSelectList = true;
    },
    checkSelectNode(e) {
      if (e.target.closest('.popup_proto_shadow') || e.target.closest('.message-us-form')) {
        return;
      }
      if (!e.target.closest('.select')) {
        this.hideSelectList();
      }
    },
    hideSelectList() {
      this.isShowSelectList = false;
    },
    async toggleAnnual(flag) {
      this.isAnnual = flag;
      // this.isCurrentPeriod = !this.isCurrentPeriod;
      const selectItems = this.numberOfPlanItems;
      const nextCount = this.$store.getters['paymentPlans/nextCount'];
      const userCount = user.subscription.user_count;
      const firstItem = this.numberOfPlanItems.find(item => item.value === 1);
      let count;

      firstItem.disabled = false;

      if (!flag && nextCount === 1) {
        this.$store.commit('paymentPlans/setNextCount', this.numberOfPlanItems[1].value);
        count = this.numberOfPlanItems[1].value;
      } else {
        count = nextCount;
      }

      if (!flag && userCount >= 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      } else if (flag && userCount > 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      }

      this.numberOfPlanItems = selectItems;
      this.checkPlan(flag, count);
      this.isDisabledApply = true;
      await this.loadPlanDetails();
      await this.calcPrice(count);
      await this.setDatePeriod();
      this.isDisabledApply = false;
    },
    setDatePeriod() {
      const date = new Date();

      const currentDay = dateHelper.formatDateNoTime(date);
      let newPaymentDay;
      const planDurationDays = this.isAnnual ? 365 : 30;
      const periodName = this.isAnnual ? __('annual_locale') : __('month_locale');

      if (this.planDetails.unusedBudget < this.newSubcsriptionPrice) {
        date.setDate(date.getDate() + planDurationDays);
        newPaymentDay = dateHelper.formatDateNoTime(date);
        // console.log('1 unusedBudget < newSubcsriptionPrice', newPaymentDay);
      } else {
        const oneDayCost = this.newSubcsriptionPrice / planDurationDays;
        const remainder = this.planDetails.unusedBudget - this.newSubcsriptionPrice;
        const remainderDays = remainder / oneDayCost;

        date.setDate(date.getDate() + planDurationDays + remainderDays);
        newPaymentDay = dateHelper.formatDateNoTime(date);
        // console.log('2 unusedBudget > newSubcsriptionPrice', this.planDetails.unusedBudget, newPaymentDay, planDurationDays, remainderDays);
      }

      this.currentDay = currentDay;
      this.newPaymentDay = newPaymentDay;
      this.newPaymentPeriod = `${periodName} (${__('from_key')} ${currentDay} ${__('to_key')} ${newPaymentDay.trim()})`;
    },
    calcPrice(userCount) {
      let annualPrice;
      let monthPrice;

      if (this.config.currentPlan.id === this.config.newPlan.id) {
        annualPrice = user.accountPlanData.annual_price_usd;
        monthPrice = user.accountPlanData.month_price_usd;
      } else {
        annualPrice = this.config.newPlan.annual_price_usd;
        monthPrice = this.config.newPlan.month_price_usd;
      }

      // experiment for single annual subs
      // if (this.isAnnual && userCount === 1) {
      //   this.newSubcsriptionPrice = +(userCount * monthPrice * 12).toFixed(2);
      // } else

      if (this.isAnnual) {
        this.newSubcsriptionPrice = +(userCount * annualPrice * 12).toFixed(2);
      } else {
        this.newSubcsriptionPrice = +(userCount * monthPrice).toFixed(2);
      }

      const payExtra = this.newSubcsriptionPrice - this.planDetails.unusedBudget;
      const payExtraValue = +payExtra.toFixed(2);

      this.payExtra = payExtraValue <= 0 ? 0 : payExtraValue;
    },
    onApplyNewPlan() {
      const userCount = this.$store.getters['paymentPlans/nextCount'];
      const nextPlan = {
        user_count: userCount,
        billing_period: 1,
        is_annual: this.isAnnual,
        planId: this.config.newPlan.id,
      };

      userExtAnalytics.log('payment_page_apply_clicked', { type: this.isAnnual ? 'annually' : 'monthly', count: userCount });
      this.$store.dispatch('profileSettings/onApplyNewPlan', nextPlan);
      this.isDisabledApply = true;
    },
    liveChatClick(e) {
      if (e.target.closest('.contact-us')) {
        this.$store.dispatch('paymentPlans/liveChatClick');
      }
    },
  },
};
</script>

<style scoped src="../less/upgradePlanPopup.less"></style>
<style lang='less'>
  p {
    margin-bottom: 0;
  }

  .payment-footer-info {
    a {
      color: #757575 !important;
      text-decoration: underline !important;
      cursor: pointer;

      &:hover {
        color: #424242 !important;
      }
    }
  }

  .payment-remark-upgrade-info {
    color: #757575 !important;
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
    line-height: 18px;
    display: inline-block;
    padding-bottom: 10px;
  }

</style>
