var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.closeLightBox($event)
        },
      },
    },
    [
      _vm.images && _vm.images.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.lightBoxOn,
                  expression: "lightBoxOn",
                },
              ],
              ref: "container",
              staticClass: "vue-lb-container",
            },
            [
              _c(
                "button",
                {
                  staticClass: "vue-lb-button-close",
                  attrs: { type: "button", title: "Close (Esc)" },
                },
                [
                  _c("span", [
                    _c(
                      "svg",
                      {
                        staticStyle: { "enable-background": "new 0 0 512 512" },
                        attrs: {
                          fill: "white",
                          x: "0px",
                          y: "0px",
                          width: "100%",
                          height: "100%",
                          viewBox: "0 0 512 512",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vue-lb-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "vue-lb-figure",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { mode: "out-in", name: "fade" } },
                      [
                        _c("img", {
                          key: _vm.images[_vm.select].src,
                          staticClass: "vue-lb-modal-image",
                          attrs: {
                            src: _vm.images[_vm.select].src,
                            srcset: _vm.images[_vm.select].srcset || "",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCaption,
                          expression: "showCaption",
                        },
                      ],
                      staticClass: "vue-lb-info",
                      domProps: {
                        innerHTML: _vm._s(_vm.images[_vm.select].caption),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "vue-lb-footer" }, [
                      _c("div", { staticClass: "vue-lb-footer-info" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-lb-footer-count" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.select + 1) +
                            "/" +
                            _vm._s(_vm.images.length) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vue-lb-thumbnail-wrapper" }, [
                _vm.showThumbs
                  ? _c(
                      "div",
                      { staticClass: "vue-lb-thumbnail" },
                      [
                        _vm.images.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "vue-lb-thumbnail-arrow vue-lb-thumbnail-left",
                                attrs: { type: "button", title: "Previous" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.previousImage()
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        fill: "white",
                                        x: "0px",
                                        y: "0px",
                                        width: "100%",
                                        height: "100%",
                                        viewBox: "0 0 512 512",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.imagesThumb, function (image, index) {
                          return _c("div", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy:background-image",
                                value: _vm.images[index].src,
                                expression: "images[index].src",
                                arg: "background-image",
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  index >= _vm.thumbIndex.begin &&
                                  index <= _vm.thumbIndex.end,
                                expression:
                                  "index >= thumbIndex.begin && index <= thumbIndex.end",
                              },
                            ],
                            key: index,
                            class:
                              "vue-lb-modal-thumbnail" +
                              (_vm.select === index ? "-active" : ""),
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.showImage(index)
                              },
                            },
                          })
                        }),
                        _vm._v(" "),
                        _vm.images.length > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "vue-lb-thumbnail-arrow vue-lb-thumbnail-right",
                                attrs: { type: "button", title: "Next" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.nextImage()
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        fill: "white",
                                        x: "0px",
                                        y: "0px",
                                        width: "100%",
                                        height: "100%",
                                        viewBox: "0 0 512 512",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.images.length > 1
                ? _c(
                    "button",
                    {
                      staticClass: "vue-lb-arrow vue-lb-left",
                      attrs: { type: "button", title: "Previous" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.previousImage()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              fill: "white",
                              x: "0px",
                              y: "0px",
                              width: "100%",
                              height: "100%",
                              viewBox: "0 0 512 512",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.images.length > 1
                ? _c(
                    "button",
                    {
                      staticClass: "vue-lb-arrow vue-lb-right",
                      attrs: { type: "button", title: "Next" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.nextImage()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              fill: "white",
                              x: "0px",
                              y: "0px",
                              width: "100%",
                              height: "100%",
                              viewBox: "0 0 512 512",
                              "xml:space": "preserve",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }