<template>
  <section :class="$style.section">
    <div
      ref="sectionWrapper"
      :class="[
        $style.section__wrapper,
      ]"
    >
      <wizard-left-section
        :title="title"
        :description="description"
        animate-enter
      />
      <div :class="$style.section__right">
        <div :class="$style['photo-container']">
          <div :class="$style['photo-badge']">
            <svg-sprite
              name="timer"
              :size="20"
            />
            <span :class="$style['photo-badge__text']">{{ locales('onboarding_book_demo_duration') }}</span>
          </div>
        </div>
        <div :class="$style['quote-container']">
          <div :class="[$style.quote__img, $style.quote__img_top]" />
          <div :class="$style.quote__text">
            {{ locales('onboarding_book_demo_quote') }}
          </div>
          <div :class="[$style.quote__img, $style.quote__img_bottom]" />
        </div>
        <vgp-button
          type="green"
          :label="locales('onboarding_book_demo_btn')"
          small
          @onClick="openDemoScheduler"
        />
        <vgp-button
          :class="$style['skip-button']"
          type="text-green"
          :label="locales('onboarding_skip_step_btn_label')"
          small
          @onClick="skipBooking"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import WizardLeftSection from '../wizard/WizardLeftSection.vue';
import constants from '$$helpers/constants';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';

export default {
  name: 'BookDemo',
  components: { WizardLeftSection },
  data() {
    return {
      locales: __,
    };
  },
  computed: {
    ...mapGetters({
      currentStepConfig: 'onboarding/getCurrentStepConfig',
    }),
    ...mapState('onboarding', ['activeStepId', 'analyticType']),
    title() {
      return this.currentStepConfig.title;
    },
    description() {
      return this.currentStepConfig.description;
    },
  },
  methods: {
    skipBooking() {
      userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
      userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });

      this.$store.dispatch('user/updateActivityStatus', {
        activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
        status: 1,
      });
      this.$router.push({ name: 'app' });
    },
    handleDemoSchedulerClose(e) {
      e.stopPropagation();
      this.skipBooking();
    },
    openDemoScheduler() {
      window.demoSheduler.open({ campaign: 'appOnboardingWizardHQL' });
      document.querySelector('.calendly-popup-close').addEventListener('click', this.handleDemoSchedulerClose);
      document.querySelector('.calendly-close-overlay').addEventListener('click', this.handleDemoSchedulerClose);
      window.addEventListener('message', event => {
        if (window.demoSheduler.isCalendlyEvent(event) && event.data.event === 'calendly.event_scheduled') {
          const demoPopupActivityStatus = this.$store.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.DEMO_POPUP);

          if (!demoPopupActivityStatus) {
            this.$store.dispatch(
              'user/updateActivityStatus',
              { activityName: constants.USER_ACTIVITIES.DEMO_POPUP, status: 1 },
            );
          }
        }
      });
      this.$emit('demo-scheduler-opened');
    },
  },
};
</script>

<style module lang="less">
.section {
  height: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    opacity: 1;
  }
}

.section__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 652px;
  background: #EFF4F4;
  overflow: hidden;
}

.photo-container {
  position: relative;
  background: url('https://cdn.ganttpro.com/app/imgs/onboarding/tanya.png') no-repeat center;
  width: 196px;
  height: 196px;
  border-radius: 32px;
  margin-bottom: 24px;
  background-size: cover;

  .photo-badge {
    position: absolute;
    bottom: 27px;
    left: 100%;
    width: fit-content;
    transform: translateX(-50%);
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    padding: 0 8px;
    color: #337870;
    background: white;
    box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);
    border-radius: 200px;
    &__text {
      white-space: nowrap;
      font-family: Lato-Bold, sans-serif;
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.quote-container {
  position: relative;
  max-width: 308px;
  margin-bottom: 24px;

  .quote__img {
    background: url('https://cdn.ganttpro.com/app/imgs/onboarding/quotes.svg') no-repeat center;
    width: 36px;
    height: 34px;
    position: absolute;
    &_top {
      left: -1px;
      top: -14px;
    }

    &_bottom {
      right: 0;
      bottom: 0;
    }
  }

  .quote__text {
    padding: 10px 4px 3px 0;
    font-family: Lato-Regular, sans-serif;
    font-style: italic;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    opacity: 0.8;
  }
}

.skip-button {
  position: absolute;
  right: 58px;
  bottom: 72px;
}

</style>
