/* eslint-disable */
import app from "./../app";
import projectsModel from "./../models/projects";
import globalStore from "./../store/main";
import customDaysTemplatesModel from "./../models/customDayTemplates";
import baselineModel from "./../models/baselines";
import plansModel from '../models/plans';

import customGridsModule from "./../views/gantt/modules/customGrids";
import workloadModules from "./../views/gantt/modules/workload";
import historyModel from "../models/history";
import filtersModel from "../models/filters";
import statusHelper from "../helpers/status";
import dateFormatsHelper from "../helpers/dateFormats";
import baselinePopup from "../views/header/include/baselinePopup";
import baselineModule from "../views/gantt/modules/baseline";
import timeTrackingModel from "../models/timeTracking";
import ganttViewModel from "../models/ganttViewModel";
import ganttToXlsx from "../views/export/xlsxExportHandlers/ganttToXlsx";

let exportComponent = {};
let BASELINE;
let isWorkloadReady = false;

const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

const innerHandlers = {
  changeIframeSize: function (iframe, width = '100%', height = '100%') {
    iframe.style.width = width;
    iframe.style.height = height;
  }
};

window.addEventListener(messageEvent, function (e) {
  const exportFrameMessageHandlers = {
    'workloadReadyChange': function () {
      isWorkloadReady = true;
    },
    'ganttData': function (data) {
      statusHelper.handlers.showMainSpinner(false, 5000);
      parent.postMessage({message: 'disableForm'}, GT.siteConfig);

      const preparedData = prepareDataForExport(data);
      const iframe = document.querySelector('.history-window-frame iframe');

      isWorkloadReady = false;
      let oneChange = false;
      const frameWidth = preparedData.exportProject.config.ganttDataAreaWidth + 'px';
      const frameHeight = preparedData.exportProject.config.ganttDataAreaHeight + 'px';

      innerHandlers.changeIframeSize(iframe, frameWidth, frameHeight);

      let interValID = setInterval(function () {
        if (isWorkloadReady) {
          let offset = 0;
          const items = document.querySelector('.history-window-frame iframe').contentWindow.document.body.querySelectorAll('.gantt_side_content.gantt_right');
          const maxX = _.reduce(items, (max, item)=> {
            const textClientRect = item.getBoundingClientRectWrapper();

            if (max < parseInt(textClientRect.x + textClientRect.width) + 10) {
              max = parseInt(textClientRect.x + textClientRect.width) + 10;
              offset = textClientRect.width;
            }

            return max;
          }, 0);


          if (maxX > preparedData.exportProject.config.ganttDataAreaWidth && !oneChange) {
            oneChange = true;
            isWorkloadReady = false;
            const oneScaleWidth = document.querySelector('.history-window-frame iframe').contentWindow.document.body.querySelector(".gantt_task_scale .gantt_scale_line:last-child .gantt_scale_cell").offsetWidth;
            gantt.config.additionalScale = parseInt(offset / oneScaleWidth) + 1;
            preparedData.exportProject.config.ganttDataAreaWidth = maxX;
            const frameWidth = preparedData.exportProject.config.ganttDataAreaWidth + 'px';
            const frameHeight = preparedData.exportProject.config.ganttDataAreaHeight + 'px';
            innerHandlers.changeIframeSize(iframe, frameWidth, frameHeight);
            document.querySelector('.history-window-frame iframe').contentWindow.eval('gantt.config.additionalScale = ' + gantt.config.additionalScale);
            return;
          }
          clearInterval(interValID);

          const html = iframe.contentDocument.querySelector('[view_id="dhxView-gantt"]');
          const svgIcons = document.querySelector('#svg-sprite-wrap');
          const styles = iframe.contentDocument.querySelectorAll('style[id^="gantt-static-bg-styles"]');
          const stylesHTML = _.reduce(styles, (res, item) => {
            return res + item.outerHTML;
          }, '');
          html.querySelector('.dhx_gantt_container').classList.add('gantt_skin_terrace');
          preparedData.html = html.innerHTML + svgIcons.outerHTML + stylesHTML;
          // console.timeEnd('exportlog front');
          // console.log(`exportlog html size: ${preparedData.html.length / 1000} Kb`);
          sendToExport(preparedData, 'png');

          innerHandlers.changeIframeSize(iframe, '100%', '100%');
          exportComponent.sendMessageToExportFrame({oldWorkloadHeight: data.values.oldWorkloadHeight, message: "returnDefaultWorkloadHeight"});
        }
      }, 300);
    },
    'filterActive': function (data) {
      const btn = document.querySelector(".export-filter");

      if (btn) {
        webix.html[data.filter ? 'addCss' : 'removeCss'](btn, "active");
      }
    },
    'toggleFilterPopup': function (data) {
      const btn = document.querySelector(".export-filter");

      if (btn) {
        webix.html[data.toggle ? 'addCss' : 'removeCss'](btn, "show_filter");
      }
    },
    'changeZoom': function (data) {
      const $$exportZoomSlider = $$("exportZoomSlider");

      if ($$exportZoomSlider) {
        $$exportZoomSlider.setValue(data.value);
      }
    },
    'enableForm': function () {
      $$('exportForm') && $$('exportForm').enable();
    },
    'disableForm': function () {
      $$('exportForm') && $$('exportForm').disable();
    },
    'baselineEnable': function () {
      var baselinesCookie = webix.storage.cookie.get('baselines') || {};
      let value;
      if (_.keys(baselinesCookie[gantt.config.gantt_id]).length > 0) {
        value = _.keys(baselinesCookie[gantt.config.gantt_id])[0];
      }

      if (value) {
        const exportBaseline = $$('export_baseline');
        const staticInp = exportBaseline.getNode().querySelector('.webix_inp_static');

        exportBaseline.setValue(value);

        exportComponent.sendMessageToExportFrame({newValue: value}, "changeBaseline");
        staticInp && staticInp.setAttribute('title', staticInp.innerText);
      }
    }
  };

  if (e.data && e.data.message) {
    exportFrameMessageHandlers[e.data.message] && exportFrameMessageHandlers[e.data.message](e.data);
  }
}, false);

function prepareDataForExport(data) {
  let result = {};
  const viewData = ganttViewModel.getActiveViewData();

  result.exportProject = {
    ganttId: gantt.config.gantt_id,
    multiviewId: gantt.config.multiview_id,
    viewName: viewData.name,
    skin: viewData.config.userSkin,
    teamName: user.team.name,
    userId: user.id,
    lang: window.lang.lang,
    config: {
      ...data.values,
      isTrial: plansModel.isTrial(),
      teamLogo: user.team.logo,
      favicon: user.team.favicon,
      teamName: user.team.name,
      show_favicon: user.team.show_favicon
    }
  };

  return result;
}

gantt._create_hidden_form = function () {
  if (!this._hidden_export_form) {
    var t = this._hidden_export_form = document.createElement("div");

    t.style.display = "none";
    t.innerHTML = "<form method='POST'><textarea name='data'></textarea><input type='hidden' name='type' value=''></form>";
    document.body.appendChild(t);
  }

  return this._hidden_export_form;
};

function getFile(data) {
  //  console.log('exportlog - getFile');
  const link = document.createElement('a');
  link.href = `/api/export/${data.fileToken}`;
  link.download = data.name;
  link.click();
}

function sendToExport(data, type) {
  data.socketId = app.socket.id;
  // console.time('exportlog back');
  // console.log('exportlog - Export back');
  fetch('/api/export', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then(response => {
    // console.timeEnd('exportlog back')
    return null;
  });
}

app.socket.on("export:enable:form", function (responseData) {
  statusHelper.handlers.hideMainSpinner();
  parent.postMessage({message: 'enableForm'}, GT.siteConfig);
});

(function () {

  exportComponent.sendMessageToExportFrame = function (data) {
    const frame = $$("exportFrame").getWindow();
    console.log(`Gantt size: ${frame.gantt.getScale().count} x ${frame.gantt.getVisibleTaskCount()}`);

    historyModel.sendPostMessageToFrame(frame, {...data, master: "export"});
  };

  exportComponent.run = function (values) {
    // console.time('exportlog front');
    // console.log('exportlog - Export front');
    this.sendMessageToExportFrame({...values, message: "collectExportData"});
  };

  exportComponent.runXlsx = function (values) {
    this.sendMessageToExportFrame({...values, message: "collectExportDataXlsx"});
  };

  app.on('workload:resize', _.debounce(function () {
    if (app.config.mode.isExport) {
      isWorkloadReady = true;

      const postMessage = {
        message: 'workloadReadyChange',
      };


      parent.postMessage(postMessage, GT.siteConfig);
    }
  }, 4000));


  if (!app.config.mode.isExport) {
    return;
  }

  const stringToDateInObject = (key, object) => {
    if (object[key]) {
      if (object[key].start) {
        object[key].start = moment(object[key].start).toDate();
      }
      if (object[key].end) {
        object[key].end = moment(object[key].end).toDate();
      }
    }
  };

  app.on("gantt:init:after", function () {
    parent.postMessage({message: 'enableForm'}, GT.siteConfig);
    parent.postMessage({message: 'baselineEnable'}, GT.siteConfig);
  });

  app.on('activeProject:afterSet', _.debounce(function () {
    if (BASELINE) {
      baselinePopup.handlers.baselines.addBaselineTaskLayer(BASELINE)
        .then(() => {
          gantt.callEvent('ganttRender');
          app.trigger('baseline:shown');
        });
    } else {
      baselineModule.handlers.clearBaselines();
      gantt.config.row_height = gantt.config.original_row_height;
      baselinePopup.handlers.baselines.build();
      gantt.callEvent('ganttRender');
      app.trigger('baseline:shown');
    }
  }, 500));

  app.on("filterActive", function (bool) {
    parent.postMessage( { message: 'filterActive', filter: bool } );
  });

  app.on("toggleFilter", function (bool) {
    parent.postMessage( { message: 'toggleFilterPopup', toggle: bool } );
  });

  app.on('zoom:change', newValue => {
    parent.postMessage( { message: 'changeZoom', value: newValue } );
  })

  exportComponent.updateModels = function (responseData, config) {
    const ganttId = responseData.project.gantt_id;
    const projectData = responseData.project;

    if (!ganttId) {
      console.error('Export component can not update models. Empty ganttId', responseData.project);
      return;
    }
    gantt.config.gantt_id = ganttId;

    if (responseData.customColumns) {
      responseData.project.is_jira === 1 
        ? globalStore.commit('columns/setJiraColumns', responseData.customColumns) 
        : globalStore.commit('columns/setStaticColumns', responseData.customColumns)
    }

    if (responseData.tasks) {
      globalStore.commit('tasksModel/setData', responseData.tasks);
    }

    baselineModel.clearAll();
    if (responseData.baselines && config.baseline) {
      baselineModel.parse(responseData.baselines);
      BASELINE = baselineModel.getItem(config.baseline);
    } else {
      BASELINE = null;
    }

    if (responseData.customDays) {
      customDaysTemplatesModel.manualAddTemplateToShareProject(responseData.customDays, ganttId);
    }

    // customDaysResourcesModel.clearAll();
    // if (responseData.customDaysResources) {
    //   customDaysResourcesModel.parse(responseData.customDaysResources);
    // } else {
    //   customDaysResourcesModel.parse([]);
    // }

    if (responseData.resources) {
      // resourcesModel.manualUpdateOrAddResources(responseData, ganttId);
      globalStore.dispatch('resourcesModel/replaceAllData', responseData.resources);
    }

    if (responseData.globalCustomColumnsData) {
      globalStore.commit('columns/setCustomColumns', responseData.globalCustomColumnsData);
    }

    if (responseData.userCustomColumnsData) {
      globalStore.commit('columns/addProject', {
        projectId: ganttId,
        data: responseData.userCustomColumnsData,
      })
    }

    timeTrackingModel.clearAll();
    if (responseData.timeTrackingData) {
      timeTrackingModel.manualUpdateItem(responseData.timeTrackingData, ganttId, 'export');
    }

    projectsModel.clearAll();
    projectsModel.parse([projectData]);
    projectsModel.activeGanttId = 0;
    projectsModel.setActiveProject(ganttId);

    if (projectData.userSkin) {
      projectData.skin = projectData.userSkin;
      webix.callEvent('onReconstruct', []);
      app.trigger('settings:skin:change', projectData.userSkin);
    }
  };

  exportComponent.initWindowListener = function () {

    exportComponent.initFrameListener = true;

    window.addEventListener("message", exportComponent.listenMainWindow);
  };

  exportComponent.showColumn = function (gantt, id, value) {
    const column = gantt.getGridColumn(id);

    if (!column) {
      console.warn('can not find column', id);
      if (_.includes(id, 'start_date')) {
        app.trigger("changeOptionCheckbox", 'start_date', !value)
      }
      if (_.includes(id, 'end_date')) {
        app.trigger("changeOptionCheckbox", 'end_date', !value)
      }
      return;
    }

    column.hide = value;

    customGridsModule.helpers.updateInnerConfig(id, !value);

    if (column.hide) {
      gantt.config.grid_width -= gantt.getGridColumn(id).width;
    } else {
      gantt.config.grid_width += gantt.getGridColumn(id).width;
    }
  };

  exportComponent.listenMainWindow = function (event) {
    const eventHandlers = {
      "closeFilterPopup": function (data) {
        const notificationMessage = document.querySelector('.webix_global-notification');

        if (data.isOpen) {
          notificationMessage && webix.html.addCss(notificationMessage, 'export_notification');
          
        } else {
          notificationMessage && webix.html.removeCss(notificationMessage, 'export_notification');
          app.trigger('filter:close:export');
        }
      },
      "showFilterPopup": function (data) {
        app.trigger('filter:toggle:export');
        // filterObject.handlers.toggleFilterPopupExport();
      },
      "changeZoom": function (data) {
        app.trigger('zoom:change', data.newValue);
      },
      "changeAutoScheduling": function (data) {
        app.trigger("settings:autoScheduling:change", data.newValue);
      },
      "changeCriticalWay": function (data) {
        app.trigger("settings:criticalPath:change", data.newValue);
      },
      "changeCurrentDayMarker": function (data) {
        app.trigger("settings:currentDayMarker:change", data.newValue);
      },
      "highlightOverdue": function (data) {
        app.trigger("settings:highlightOverdue:change", data.newValue);
      },
      "discolorationTasks": function (data) {
        app.trigger("settings:discolorationTasks:change", data.newValue);
      },
      "crossOutTasks": function (data) {
        app.trigger("settings:crossOutTasks:change", data.newValue);
      },
      "changeTaskName": function (data) {
        app.trigger("settings:rightSideText:change", data.newValue);
      },
      "changeWorkload": function (data) {
        gantt.callEvent('workloadExpandButtonClick', [{value: data.newValue, viewMode: 'hours', isExport: true}]);
        gantt.callEvent('ganttRender');
      },
      "changeBaseline": function (data) {
        baselineModule.handlers.clearBaselines(true);

        const baseline = baselineModel.getItem(data.newValue);

        if (baseline) {
          baselinePopup.handlers.baselines.addBaselineTaskLayer(baseline)
            .then(() => {
              gantt.callEvent('ganttRender');
            });
        } else {
          baselinePopup.handlers.baselines.build();
          gantt.callEvent('ganttRender');
        }
      },
      "changeColumns": function (data) {
        exportComponent.showColumn(gantt, data.newValue.itemId, !data.newValue.newValue);

        gantt.callEvent('ganttRender');
        gantt.callEvent('onGridColumnsToggle');
      },
      "returnDefaultWorkloadHeight": function (data) {
        const oldWorkloadHeight = data.oldWorkloadHeight;
        oldWorkloadHeight && workloadModules.handlers.changeWorkloadHeight(oldWorkloadHeight);
      },
      'collectExportDataXlsx': function(values) {
        //const filterData = filtersModel.getFilterDataByActiveProject(gantt.config.gantt_id || gantt.config.multiview_id);
        const baseline = {
          tasksState: gantt.serialize(),
          baselineModelData: baselineModel.getBaselineData(gantt.config.gantt_id)
        };
        ganttToXlsx.export(values, baseline);
      },
      "collectExportData": function (values) {
        const tasks = filtersModel.getFilterDataByActiveProject(gantt.config.gantt_id);
        const ganttData = gantt.serialize();
        const ganttDataArea = document.querySelector('.gantt_data_area');
        const ganttGrid = document.querySelector('.gantt_grid');
        const workloadHeader = document.querySelector('.workload-header');
        const workloadBody = document.querySelector('.workload-body');
        const ganttTasks = ganttData.data;

        values.dateFormat = dateFormatsHelper.getDateFormat();
        values.timeFormat = dateFormatsHelper.getUserTimeFormat();

        if (tasks && tasks.options) {
          values.filterOptions = tasks.options;
        }

        if (ganttDataArea) {
          const closedGanttTasks = _.filter(ganttTasks, (task) => {
            return task.open === 0 && task.type === gantt.config.types.project;
          });

          const openedGanttTasks = _.filter(ganttTasks, (task) => {
            return task.open === 1 && task.type === gantt.config.types.project;
          });

          const visibleTasks = _.filter(ganttTasks, (task) => {
            return gantt.isTaskVisible(task.id);
          });

          values.ganttDataAreaWidth = +ganttDataArea.style.width.replace('px', '') + ganttGrid.offsetWidth;
          values.ganttDataAreaHeight = visibleTasks.length * gantt.config.row_height + 200;


          values.closedGanttTasks = closedGanttTasks.map(item => item.id);
          values.openedGanttTasks = openedGanttTasks.map(item => item.id);
        }

        if (workloadHeader && workloadBody) {
          const resourcesCount = document.querySelectorAll('.workload_row').length;

          if (resourcesCount) {
            values.oldWorkloadHeight = workloadModules.handlers.getWorkloadHeight();
            workloadModules.handlers.changeWorkloadHeightByCount(resourcesCount > 1 ? resourcesCount : 2);
          }

          values.groupByWorkload = "by_hours";
          values.ganttDataAreaHeight += workloadHeader.offsetHeight + workloadBody.offsetHeight;
          values.workloadHeight = workloadHeader.offsetHeight + workloadBody.offsetHeight;
        }

        _.each(values.enabledColumns, (column) => {
          const foundColumn = _.find(gantt.config.columns, {'name': column.name});

          if (foundColumn) {
            column.width = foundColumn.width;
          }
        });

        const postMessage = {
          message: 'ganttData',
          ganttData,
          values
        };


        parent.postMessage(postMessage, GT.siteConfig);
      }
    };

    if (event.data.master && event.data.master === "export" && event.data.message) {
      return eventHandlers[event.data.message] && eventHandlers[event.data.message](event.data);
    }
  };

  exportComponent.initWindowListener();
})();

app.socket.on("export:link", getFile);
export default exportComponent;
