import app from '../../../app';
import globalStore from '$$store/main';

function onResourceUpdated(event) {
  event.data.updated.forEach(el => {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](el.resourceId);

    if (el.updatedFields) {
      if (resourceData.userId === user.id && el.updatedFields.photo) {
        user.photo = el.updatedFields.photo;
      }

      const updatedFields = {
        ...el.updatedFields,
        invite: {
          status: 0,
        },
      };

      globalStore.commit('resourcesModel/updateResource', {
        resourceId: el.resourceId,
        updated: { ...updatedFields },
      });

      app.trigger('userCustomColumnsModel:change');
      app.trigger('userProfile:update');
    }
    if (el.updatedFields.accountRoleId) {
      app.trigger('onAfterCollaboration', {
        event: event.event,
        userId: resourceData.userId,
        isUpdatedRole: true,
        resourceIds: event.data.updated.map(item => item.resourceId),
      });
      globalStore.commit('teamSetting/updateUserAccountRole', { user_id: resourceData.userId, role_id: el.updatedFields.accountRoleId });
    }
  });
}

export default onResourceUpdated;
