import BaseFilter from './BaseFilter';
import BaseProperties from './BaseProperties';
import routerHelper from '../../../helpers/router';
import globalStore from '$$store/main';

const _self = null;

class ListviewFilter extends BaseFilter {
  static filterByType() {
    if (!_self) {
      this._self = new ListviewFilter('list');
      this.filteringOnInit = false;
    }

    return this._self;
  }

  get _emptyState() {
    return {
      type: '',
      status: '',
      assignee: '',
      priority: '',
      reporter: '',
      rangeDate: {
        start: '',
        end: '',
      },
      startDate: {
        start: '',
        end: '',
      },
      endDate: {
        start: '',
        end: '',
      },
      creationDate: {
        start: '',
        end: '',
      },
      text: '',
    };
  }

  get myIncompleteTasksState() {
    const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);

    return {
      ...this._emptyState,
      status: ['1', '2'],
      assignee: [resourceData.id],
    };
  }

  config() {
    const conf = [
      BaseProperties.taskName('text'),
      BaseProperties.projects('ganttId'),
      BaseProperties.taskTypes(),
      BaseProperties.assigneeByGanttIDs('assignee', this.ganttIDs),
      BaseProperties.statuses(),
      BaseProperties.priority(),
      BaseProperties.reporter('reporter'),
      BaseProperties.dateRange(),
      BaseProperties.startDate(),
      BaseProperties.endDate(),
      BaseProperties.creationDate(),
      BaseProperties.overdue(),
    ];

    if (routerHelper.isSingleProjectRoute()) {
      conf.splice(1, 1);
    }

    return conf;
  }

  init() {
    super.init();
    this.resetIfnotFoundTasks = false;

    if (!this.isActiveFilter && routerHelper.isListViewRoute() ) {
      const state = _.cloneDeep(this.myIncompleteTasksState);

      this.setFilterState(state, true);
    } else if (!this.isActiveFilter && (routerHelper.isProjectList() || routerHelper.isMultiviewList())) {
      this.setFilterState(this._emptyState);
    }
  }

  filterData() {
  }

  myIncompleteTasks() {
    this.changeFilterState(this.myIncompleteTasksState);
  }

  changeFilterState(filterState) {
    this.setFilterState(filterState);
    this.filterData();
  }

  refreshFilter() {
    this.changeFilterState(this.filterState);
  }

  selectSavedFilter(filter) {
    this.changeFilter(filter.id, filter.value);
    this.filterData();
  }

  resetFilter() {
    this.setFilterState(this._emptyState);
    this.filterData();
  }
}

export default ListviewFilter;
