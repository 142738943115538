var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    class: _vm.popup.remove_popup,
    attrs: {
      id: "gp_autotest_remove_resource_popup",
      show: _vm.isShow,
      "close-icon": false,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.popup.head }, [
              _c("div", { class: _vm.popup.title }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", {
                class: _vm.popup.message,
                domProps: { innerHTML: _vm._s(_vm.message) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.popup.buttons },
              [
                _c("vgp-button", {
                  attrs: {
                    id: "gp_autotest_remove_resource_popup_btn_cancel",
                    type: "secondary",
                    label: _vm.locales("common_cancel"),
                    small: true,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.$emit("onClose")
                    },
                  },
                }),
                _vm._v(" "),
                _c("vgp-button", {
                  style: { marginLeft: 16 + "px" },
                  attrs: {
                    id: "gp_autotest_remove_resource_popup_btn_delete",
                    type: "destructive",
                    label: _vm.locales("common_delete"),
                    small: true,
                  },
                  on: { onClick: _vm.onDelete },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }