<template>
  <div
    :class="[
      $style.cell_trigger,
      $style.select_none,
      align ==='center' ? $style.text_center : '',
      disabled ? $style.text_disabled : '',
      crossout ? $style.crossout :'',
      discolor ? $style.discolor :'',
      !editable ? $style.not_allowed : '',
    ]"
  >
    <span :class="[$style.text_wrap]"> {{ item[property] }} </span>
  </div>
</template>

<script>

export default {
  name: 'SimpleCell',
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: true },
    editable: { type: Boolean, required: false, default: true },
    align: {
      type: String,
      required: false,
      default: 'center',
      validator(value) {
        return (['center', 'left'].indexOf(value) !== -1);
      },
    },
    discolor: { type: Boolean, required: false, default: false },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
    };
  },

};
</script>

<style module src="./cell.less" lang="less" />
