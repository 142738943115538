const rolesHelpers = {

  getBitIds(state, payload, rightId) {
    const arrayBitIds = [];

    state[payload.type].roles.forEach(item => {
      if (item.id === payload.roleId) {
        item.rights.forEach(bit => {
          // search current bit
          if (bit.rightId === rightId) {
            arrayBitIds.push(bit.id);
          }
        });
      }
    });

    return arrayBitIds;
  },

  getChildRightIds(state, payload, rightId) {
    const childrenRightIds = [];

    state[payload.type].rights.forEach(item => {
      if (item.parentRight === rightId) {
        childrenRightIds.push(item.id);
      }
    });

    return childrenRightIds;
  },

  getChildReverseRightIds(state, payload) {
    const { parentRightId, rightId } = payload;
    const ids = [];

    if (parentRightId !== 0) {
      // get children if this parent
      state[payload.type].rights.forEach(item => {
        // get all children  + parent
        if (item.parentRight === parentRightId || item.id === parentRightId) {
          ids.push(item.id);
        }
      });
    } else {
      // it was parent. Get all children and add parent Id
      state[payload.type].rights.forEach(item => {
        if (item.parentRight === rightId) {
          ids.push(item.id);
        }
      });
      ids.push(rightId);
    }

    return ids;
  },

};

export default rolesHelpers;
