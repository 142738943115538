<template>
  <div
    id="inputRangeSlider"
    class="vgp-interactive-element"
    :style="{ width: `${width}px` }"
  />
</template>

<script>

import noUiSlider from 'nouislider';

export default {
  name: 'InputRangeSlider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sliderInstance: null,
      sliding: false,
    };
  },
  watch: {
    value(val) {
      const parsedIntValue = parseInt(val, 10);
      const noUiConnect = document.querySelector('.noUi-connect');

      if (!noUiConnect) return;

      if (parsedIntValue === 0) {
        noUiConnect.classList.add('empty');
      } else {
        noUiConnect.classList.remove('empty');
      }
      if (!this.sliding) {
        this.sliderInstance.set(parsedIntValue, true, true);
      }
    },
    disabled(val) {
      val ? this.sliderInstance.disable() : this.sliderInstance.enable();
    },
  },
  mounted() {
    this.$nextTick(this.initSlider);
  },
  methods: {
    initSlider() {
      this.sliderInstance = noUiSlider.create(this.$el, {
        start: 0,
        step: this.step,
        connect: 'lower',
        range: {
          min: this.min,
          max: this.max,
        },
        format: {
          from(value) {
            return parseInt(value, 10);
          },
          to(value) {
            return parseInt(value, 10);
          },
        },
      });

      this.sliderInstance.set(this.value, true, true);

      if (this.value === 0) {
        const noUiConnect = document.querySelector('.noUi-connect');
        noUiConnect.classList.add('empty');
      }

      if (this.disabled) {
        this.sliderInstance.disable();
      }

      this.sliderInstance.on('update', ([newValue]) => {
        !this.disabled && (this.value !== newValue) && this.$emit('update', newValue);
      });
      this.sliderInstance.on('slide', () => {
        this.sliding = true;
      });
      this.sliderInstance.on('change', ([value]) => {
        !this.disabled && this.$emit('change', value);
        this.sliding = false;
      });
      this.sliderInstance.on('end', ([newValue]) => {
        !this.disabled && this.$emit('slide-end', newValue);
      });
    },
  },
};
</script>

<style lang="less">

</style>
