import app from "./../../app";
import globalStore from '../../store/main';

import "./init";
import "./../webix/gantt";

app.on("gantt:progress:show", function (mode) {
  var mainNode = $$('ganttMainNode');

  if (mode === 'import') {
    mainNode = $$('import:table');
  }

  if (mainNode) {
    !mainNode.showProgress && webix.extend(mainNode, webix.ProgressBar);

    mainNode.disable();
    mainNode.showProgress({
      type: "icon_cover"
    });
  }
  globalStore.commit('setGanttInProgress', true);
});

app.on("gantt:progress:hide", function (now) {
  var mainNode = $$('ganttMainNode');
  mainNode && mainNode.enable();
  mainNode && mainNode.hideProgress && mainNode.hideProgress(now);
  globalStore.commit('setGanttInProgress', false);
});

export default {
  view: "dhx-gantt",
  id: "dhxView-gantt",
  borderless: true,
  initMode: true
};
