var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.shared_wraper },
    [
      _c("core-popup", {
        attrs: { show: _vm.isConfirmPopup },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { class: _vm.$style.shared_content }, [
                  _c("div", {
                    class: _vm.$style.shared_logo,
                    domProps: { innerHTML: _vm._s(_vm.logo) },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        _vm.$style.shared_message,
                        _vm.error ? _vm.$style.shared_message_error : "",
                      ],
                    },
                    [
                      _vm.error
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.locales("share_popup_message_error"))
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.locales("share_popup_message_info"))
                            ),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.$style.shared_form] },
                    [
                      _c("vgp-label-slot", {
                        attrs: {
                          label: _vm.locales("share_popup_password_title"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("vgp-input", {
                                  attrs: {
                                    "input-type": "password",
                                    type: "status",
                                    "is-error": _vm.error,
                                    placeholder: _vm.locales(
                                      "share_popup_enter_password_placeholder"
                                    ),
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function ($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password",
                                  },
                                }),
                                _vm._v(" "),
                                _c("vgp-button", {
                                  attrs: {
                                    type: "status",
                                    label: _vm.locales(
                                      "share_popup_password_submit_placeholder"
                                    ),
                                  },
                                  on: { onClick: _vm.submit },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.shared_help }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.locales("share_popup_support_message") + " "
                        ) +
                        "\n          "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:" + _vm.locales("share_popup_mailto"),
                        },
                      },
                      [_vm._v(_vm._s(_vm.locales("share_popup_mailto")))]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "popup" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }