// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progress_wrapper_lhSsEHB4 {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./public/views/common/VueComponents/table/cells/less/progressCell.less","webpack://./progressCell.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;ACCF","sourcesContent":[".progress_wrapper {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}",".progress_wrapper {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress_wrapper": "progress_wrapper_lhSsEHB4"
};
export default ___CSS_LOADER_EXPORT___;
