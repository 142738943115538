<template>
  <div
    v-if="taskData"
    :data-drag-over-text="locales('task_view_upload_text')"
    :class="[
      ((longComments && commentHub) || !hideForHub) ? $style['task-settings__wrapper'] : $style['task-settings__short'] ,
      commentHub ? $style['task-settings__wrapper_hub'] : '',
      isDraggingFile ? $style['task-settings__drag-overlay'] : ''
    ]"

    @dragenter.prevent="showDragOverlay"
    @dragover.prevent="showDragOverlay"
    @dragleave.prevent="hideDragOverlay"
    @drop.prevent="handleDropFile"
  >
    <task-settings-header
      v-if="!commentHub"
      ref="taskSettingsHeader"
      :task-data="taskData"
      :comment-hub="commentHub"
      @change="handleChangeData"
      @close="$emit('close')"
      @dragleave.native.stop
    />
    <div
      id="taskSettingsWrapper"
      ref="taskSettingsInnerWrapper"
      :class="[
        $style[!hideForHub && commentHub ? 'settings-main' : ''],
        $style[hideForHub && !comments.length ? 'setting-test' : ''],
        !commentHub ? 'gantt_scroll' : '',
        $style['task-settings__inner-wrapper']
      ]"
      @scroll="handleTaskSettingsBodyScroll"
    >
      <task-settings-header
        v-if="commentHub"
        ref="taskSettingsHeader"
        :task-data="taskData"
        :comment-hub="commentHub"
        @change="handleChangeData"
        @close="$emit('close')"
        @dragleave.native.stop
      />
      <div
        ref="taskSettingsBody"
        :class="['task-settings-body-scroll-container', $style['task-settings__body']]"
        @scroll="handleTaskSettingsBodyScroll"
        @dragleave.stop
      >
        <button
          v-show="showScrollToTopButton && !hideForHub"
          :class="[$style['scroll-to-top-btn'], 'vgp-interactive-element']"
          @click.prevent="scrollToTop"
        >
          {{ locales('settings_jump_to_top_btn') }}
        </button>
        <div :class="[$style['task-settings__body-inner']]">
          <task-settings-primary-info
            :task-data="taskData"
            @change="handleChangeData"
            @dragstart.native.prevent
          />
          <task-settings-buttons
            :task-data="taskData"
            :disabled="disabledFieldsWithReasons"
            @attachment-created="scrollToAttachmentsIfNotVisible"
            @show-new-dependency-form="showNewDependencyForm"
            @dragstart.native.prevent
          />
          <task-settings-dates
            :task-data="taskData"
            @change="handleChangeData"
            @dragstart.native.prevent
          />
          <task-settings-description
            v-if="showDescriptionSection"
            :task-data="taskData"
            @change="handleChangeData"
            @dragstart.native.prevent
          />
          <task-settings-custom-fields
            :task-data="taskData"
            @change="handleChangeCustomField"
            @dragstart.native.prevent
          />
          <task-settings-dependencies
            v-if="slaveLinks.length || masterLinks.length || newDependencyFormShown"
            ref="taskSettingsDependenciesSection"
            :master-links="masterLinks"
            :slave-links="slaveLinks"
            :task-data="taskData"
            :disabled="disabledFieldsWithReasons"
            @new-dependency-form-close="newDependencyFormShown = false"
            @dragstart.native.prevent
          />
          <task-settings-attachments
            v-if="attachments.length"
            ref="taskSettingsAttachmentsSection"
            :attachments="attachments"
            :task-data="taskData"
            @attachment-created="scrollToAttachmentsIfNotVisible"
            @dragstart.native.prevent
          />
        </div>
      </div>

      <task-settings-comments
        v-if="!commentHub && comments.length"
        ref="taskSettingsCommentsSection"
        :class="[$style['task-settings__body'], $style['task-settings__comments']]"
        :comments="comments"
        :hide-for-hub="hideForHub"
        :comment-hub="commentHub"
        :task-data="taskData"
        :comment-editor="commentEditorCoordinates"
        @scroll="handleTaskSettingsBodyScroll"
        @delete-comment="handleDeleteComment"
        @edit-comment="handleClickEditComment"
        @new-comment="handleTaskSettingsBodyScroll"
        @dragstart.native.prevent
      />
    </div>

    <task-settings-footer
      v-if="!commentHub"
      ref="taskSettingsFooter"
      :current-edit-comment="currentEditComment"
      :show-scroll-to-bottom-button="showScrollToBottomButton"
      :task-data="taskData"
      :task-id="taskId"
      :comment-hub="commentHub"
      :unread-comment-ids="unreadCommentIds"
      @submit="handleSubmitCommentEditor"
      @scrollToBottom="scrollToBottom"
      @openEditor="handleOpenComment"
      @comment="$emit('comment', $event)"
      @cancel="currentEditComment = null"
      @dragleave.native.stop
      @dragstart.native.prevent
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import TaskSettingsHeader from './header/TaskSettingsHeader.vue';
import TaskSettingsDependencies from './sections/dependencies/TaskSettingsDependencies.vue';
import TaskSettingsComments from './sections/comments/TaskSettingsComments.vue';
import TaskSettingsAttachments from './sections/attachments/TaskSettingsAttachments.vue';
import TaskSettingsDescription from './sections/description/TaskSettingsDescription.vue';
import TaskSettingsDates from './sections/dates/TaskSettingsDates.vue';
import TaskSettingsPrimaryInfo from './sections/primaryInfo/TaskSettingsPrimaryInfo.vue';
import TaskSettingsCustomFields from './sections/customFields/TaskSettingsCustomFields.vue';
import TaskSettingsButtons from './sections/buttons/TaskSettingsButtons.vue';
import TaskSettingsFooter from './footer/TaskSettingsFooter.vue';

import TaskSettingsController from './controllers';
import GoogleDriveAttachmentsController from './controllers/googleDrive';
import rights from '../../components/rights';
import { checkPricingAccess } from '$$helpers/pricingHelper';
import elementIsVisibleInViewport from '$$helpers/elementIsVisibleInViewport';
import OneDriveAttachmentsController from './controllers/oneDrive';
import app from '../../app';
import constants from '$$helpers/constants';
import svgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';
import { closeTask } from './helpers';

const MILESTONE_DISABLED_FIELDS = ['duration', 'estimation', 'end_date'];
const PROJECT_DISABLED_FIELDS = [
  'duration', 'estimation', 'end_date', 'progress',
  'resources_visibility', 'status_visibility', 'priority_visibility', 'type',
];

let COMPONENT_APP_EVENT_LISTENERS = [];

export default {
  name: 'TaskSettingsMain',
  components: {
    svgImage,
    TaskSettingsHeader,
    TaskSettingsCustomFields,
    TaskSettingsButtons,
    TaskSettingsPrimaryInfo,
    TaskSettingsDates,
    TaskSettingsDescription,
    TaskSettingsDependencies,
    TaskSettingsComments,
    TaskSettingsAttachments,
    TaskSettingsFooter,
  },
  provide() {
    return {
      isFieldDisabled: this.isFieldDisabled,
      getDisabledFieldReason: this.getDisabledFieldReason,
    };
  },
  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
    hideForHub: {
      type: Boolean,
      required: false,
      default: false,
    },

    commentHub: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
    },

  },
  data() {
    return {
      locales: __,
      currentEditComment: null,
      isDraggingFile: false,
      showScrollToTopButton: false,
      showScrollToBottomButton: false,
      // customFieldsColumns: [],
      // customFieldsValues: {},
      newDependencyFormShown: false,
      disabledFieldsWithReasons: {},
      commentEditorCoordinates: null,
      bottomForCounter: '',
      unreadCommentIds: [],
      longComments: false,
    };
  },
  computed: {
    taskData() {
      return this.$store.getters['tasksModel/tasksByIds'][this.taskId];
    },
    showDescriptionSection() {
      if (!this.taskData.note && this.isFieldDisabled('note')) return false;

      return true;
    },
    isTaskType() {
      return this.taskData.type === constants.TASK_TYPES.task;
    },
    isMilestoneType() {
      return this.taskData.type === constants.TASK_TYPES.milestone;
    },
    isProjectType() {
      return this.taskData.type === constants.TASK_TYPES.project;
    },
    comments() {
      return this.$store.getters['comments/getCommentsByProjectAndTaskId'](this.taskData.gantt_id, this.taskData.id);
    },
    attachments() {
      return this.$store.getters['attachments/getAttachmentsByProjectAndTaskId'](this.taskData.gantt_id, this.taskData.id);
    },
    masterLinks() {
      return this.$store.getters['tasksModel/getMasterLinksByTaskId'](this.taskData.gantt_id, this.taskData.id);
    },
    dependenciesLength() {
      return this.masterLinks.length + this.slaveLinks.length;
    },
    slaveLinks() {
      return this.$store.getters['tasksModel/getSlaveLinksByTaskId'](this.taskData.gantt_id, this.taskData.id);
    },
  },
  watch: {
    taskData(val) {
      if (!val) closeTask();
    },
    'taskData.type': function () {
      this.setDisabledFieldsWithReasons();
    },

    taskId: {
      handler(val) {
        val && this.initializeData();
        this.$nextTick(() => {
          // this.resetTaskSettingsBodyScroll();
          if (this.$refs.taskSettingsCommentsSection?.$el.getBoundingClientRect().height > this.height) {
            this.longComments = true;
          } else {
            this.longComments = false;
          }
        });
      },
      immediate: true,
    },
    hideForHub: {
      handler(val) {
        const stub = this.$refs.taskSettingsStubSection;

        if (!val) {
          this.$nextTick(() => {
            // this.$refs.taskSettingsBody && this.$refs.taskSettingsBody.scrollTo({ top: 0, behavior: 'smooth' });
            // this.$refs.taskSettingsHeader.$el && this.$refs.taskSettingsHeader.$el.scrollTo({ top: 0 });

            setTimeout(() => {
              const el = document.getElementById('taskSettingsWrapper');

              el && el.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }, 100);
            stub && setTimeout(() => {
              stub.style.display = 'none';
            }, 500);
          });
        } else {
          stub && setTimeout(() => {
            stub.style.display = 'flex';
          }, 0);
        }
      },
    },
    dependenciesLength() {
      this.setDisabledFieldsWithReasons();
    },
  },
  created() {
    TaskSettingsController.initWbsWorker();
  },
  mounted() {
    if (window.taskSettingsData?.initOptions?.selectComment) {
      this.scrollToCommentsAndOpenCommentEditor();
    }
    if (window.taskSettingsData?.initOptions?.selectAttachments && this.attachments.length) {
      setTimeout(() => {
        this.scrollToAttachmentsIfNotVisible({
          block: 'center',
          behavior: 'smooth',
        });
        window.taskSettingsData.initOptions.selectAttachments = false;
      }, 200);
    }
  },
  beforeDestroy() {
    this.forceHidePricingTooltip();
    TaskSettingsController.removeAppEventsListeners();
    this.removeComponentAppEventsListeners();
    this.longComments = false;
  },
  methods: {

    handleOpenComment(val) {
      this.$emit('comment', val);
    },
    resetTaskSettingsBodyScroll() {
      this.$refs.taskSettingsBody.scrollTop = 0;
    },
    setDisabledFieldsWithReasons() {
      const disabledFields = [];
      const disabledFieldsReasons = {};

      if (this.isMilestoneType) {
        disabledFields.push(...MILESTONE_DISABLED_FIELDS);
      }

      if (this.isProjectType) {
        disabledFields.push(...PROJECT_DISABLED_FIELDS);
        disabledFieldsReasons.end_date = 'why_can_not_set_it_case1';
      }

      if (
        gantt.isTaskExists(this.taskData.id)
          && gantt._inline_edit.checkByLinkDependency(gantt.getTask(this.taskData.id), 'start_date')
      ) {
        disabledFields.push('start_date');
        if (rights.project.hasRight(this.taskData.gantt_id, 'static_fields')) {
          if (this.isProjectType) {
            disabledFieldsReasons.end_date = 'why_can_not_set_it_case2_end';
            disabledFieldsReasons.start_date = 'why_can_not_set_it_case2_start';
          } else {
            disabledFieldsReasons.start_date = 'why_can_not_set_it_case4';
          }
        }
      }

      if (
        gantt.isTaskExists(this.taskData.id)
          && gantt._inline_edit.checkByLinkDependency(gantt.getTask(this.taskData.id), 'end_date')
      ) {
        disabledFields.push('end_date');
        if (this.isTaskType && rights.project.hasRight(this.taskData.gantt_id, 'static_fields')) {
          disabledFieldsReasons.end_date = 'why_can_not_set_it_case5';
        }
      }

      if (!rights.project.hasRight(this.taskData.gantt_id, 'static_fields')) {
        disabledFields.push(
          ...[
            'priority', 'estimation',
            'duration', 'start_date', 'end_date',
            'deadline', 'resources', 'text', 'note',
            'color', 'dependencies',
          ],
        );
      }

      if (
        !rights.project.hasRight(this.taskData.gantt_id, 'static_fields_4_only')
          && !rights.project.hasRight(this.taskData.gantt_id, 'static_fields')
      ) {
        disabledFields.push(
          ...['progress', 'time_log', 'status', 'attachments'],
        );
      }

      if (!rights.project.hasRight(this.taskData.gantt_id, 'delete_task')) {
        disabledFields.push('delete_task');
      }

      if (!rights.project.hasRight(this.taskData.gantt_id, 'comments')) {
        disabledFields.push('comments');
      }

      if (!rights.project.hasRight(this.taskData.gantt_id, 'custom_field_edit')) {
        disabledFields.push('custom_fields');
      }

      if (!rights.project.hasRight(this.taskData.gantt_id, 'create_task')) {
        disabledFields.push('type');
      }

      if (!checkPricingAccess('estimation')) {
        disabledFields.push('estimation');
        disabledFields.push('estimation_pricing');
      }

      if (!checkPricingAccess('time_tracking')) {
        disabledFields.push('time_log');
        disabledFields.push('time_log_pricing');
      }

      const uniqueDisabledFields = [...new Set(disabledFields)];
      const disabledFieldsWithReasons = uniqueDisabledFields.reduce((result, fieldName) => {
        if (disabledFieldsReasons[fieldName]) {
          result[fieldName] = disabledFieldsReasons[fieldName];
        } else {
          result[fieldName] = null;
        }

        return result;
      }, {});

      this.disabledFieldsWithReasons = disabledFieldsWithReasons;
    },
    initializeData() {
      // const {
      //   columns,
      //   values,
      // } = TaskSettingsController.getCustomFieldsColumnsAndValues(this.taskData);
      // this.customFieldsColumns = columns;
      // this.customFieldsValues = values;
      // TaskSettingsController.setCustomFields(this.customFieldsColumns, this.customFieldsValues);

      TaskSettingsController.setTaskData(this.taskData);

      TaskSettingsController.addAppEventsListeners();
      this.addComponentAppEventsListeners();

      this.forceHidePricingTooltip();

      if (!this.$store.getters['attachments/getGoogleUser']) {
        GoogleDriveAttachmentsController.loadGoogleApiAndSetGoogleUser();
      }
      if (!this.$store.getters['attachments/getMicrosoftUser']) {
        OneDriveAttachmentsController.loadMicrosoftApiAndSetMicrosoftUser();
      }

      this.fetchCommentsIfNeeded();
      this.fetchAttachmentsIfNeeded();
      this.showScrollToTopButton = false;
      this.setDisabledFieldsWithReasons();
    },
    addComponentAppEventsListeners() {
      this.removeComponentAppEventsListeners();

      const eventId = app.on('onAfterCollaboration', collaborationData => {
        if (collaborationData.event === 'ResourceOnProjectsUpdated' && collaborationData.isUpdatedRole) {
          this.setDisabledFieldsWithReasons();
        }
      });

      COMPONENT_APP_EVENT_LISTENERS.push(eventId);
    },
    removeComponentAppEventsListeners() {
      COMPONENT_APP_EVENT_LISTENERS.forEach(id => app.off(id));
      COMPONENT_APP_EVENT_LISTENERS = [];
    },
    forceHidePricingTooltip() {
      this.$store.commit('tooltip/showPricingPopup', false);
    },
    scrollToBottom() {
      const taskSettingsBodyRef = this.$refs.taskSettingsBody;
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = taskSettingsBodyRef;

      if (this.unreadCommentIds.length) {
        this.$store.dispatch('comments/markAsRead', {
          taskIds: [this.taskData.id],
          commentIds: this.unreadCommentIds,
        });
      }
      // taskSettingsBodyRef.scrollTo({ top: scrollHeight, behavior: 'smooth' });
      document.getElementById('taskSettingsWrapper').scrollTo({ top: scrollHeight });
    },
    scrollToAttachmentsIfNotVisible(scrollOptions = { block: 'nearest' }) {
      const attachmentsSection = this.$refs.taskSettingsAttachmentsSection.$el;

      if (!elementIsVisibleInViewport(attachmentsSection, this.$style['task-settings__body'])) {
        attachmentsSection.scrollIntoView(scrollOptions);
      }
    },
    showDragOverlay() {
      if (this.isFieldDisabled('attachments')) return;
      this.isDraggingFile = true;
    },
    scrollToCommentsAndOpenCommentEditor() {
      this.$nextTick(() => {
        this.$refs.taskSettingsFooter.activateEditor();
        setTimeout(() => {
          this.$refs.taskSettingsInnerWrapper.scrollTo({
            top: this.$refs.taskSettingsInnerWrapper.scrollHeight,
            behavior: 'smooth',
          });
          window.taskSettingsData.initOptions.selectComment = false;
        }, 0);
      });
    },
    fetchCommentsIfNeeded() {
      if (!this.$store.getters['comments/isCommentsAlreadyFetchedForProject'](this.taskData.gantt_id) && !this.commentHub) {
        this.$store.dispatch('comments/fetchCommentsByProjectIds', { projectIds: this.taskData.gantt_id });
      }
    },
    fetchAttachmentsIfNeeded() {
      if (!this.$store.getters['attachments/isAttachmentsAlreadyFetchedForProject'](this.taskData.gantt_id)) {
        this.$store.dispatch('attachments/fetchAttachmentsByProjectIds', { projectIds: this.taskData.gantt_id });
      }
    },
    isFieldDisabled(fieldName) {
      return fieldName in this.disabledFieldsWithReasons;
    },
    getDisabledFieldReason(fieldName) {
      return this.disabledFieldsWithReasons[fieldName];
    },
    async handleSubmitCommentEditor(submitActionType) {
      this.currentEditComment = null;
      if (submitActionType === 'create') {
        this.$refs.taskSettingsCommentsSection.$el.scrollIntoView({ block: 'end' });
      }
    },
    handleChangeData({
      property,
      payload,
    }) {
      if (this.isFieldDisabled(property)) return;
      // taskIdForUpdate passed in TaskSettingsDescription component
      if (payload.taskIdForUpdate && payload.taskIdForUpdate !== this.taskData.id) return;
      // Before making request for updating task we need to set actual task data from store to the controller because
      // it can be changed by autoscheduling or collaboration and it will not be changed in controller cause nonreactive
      TaskSettingsController.setTaskData(this.taskData);
      TaskSettingsController.updateTaskDataPartially({
        property,
        payload,
      });
    },
    handleChangeCustomField({
      column,
      value,
    }) {
      if (this.isFieldDisabled('custom_fields')) return;
      TaskSettingsController.updateCustomField(column, value);
    },
    handleTaskSettingsBodyScroll: debounce(function () {
      const taskSettingsBodyRef = document.getElementById('taskSettingsWrapper');
      const {
        scrollTop,
        clientHeight,
      } = taskSettingsBodyRef;

      if (this.commentHub) {
        const taskSettingsCommentsRef = this.$refs.taskSettingsCommentsSection.$el;
        const { scrollHeight } = taskSettingsCommentsRef;

        this.showScrollToBottomButton = scrollTop < scrollHeight - clientHeight;
        this.bottomForCounter = `${this.commentEditorCoordinates?.top - 108}px`;
      }

      this.showScrollToTopButton = (clientHeight / 2) + scrollTop > clientHeight;
    }, 100),
    scrollToTop() {
      this.$refs.taskSettingsInnerWrapper.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    showNewDependencyForm() {
      this.newDependencyFormShown = true;
      this.$nextTick(() => {
        this.$refs.taskSettingsDependenciesSection.showNewDependencyForm = true;
      });
    },
    hideDragOverlay() {
      this.isDraggingFile = false;
    },
    async handleDropFile(event) {
      if (this.isFieldDisabled('attachments') || this.hideForHub) return;

      this.isDraggingFile = false;
      const { files } = event.dataTransfer;

      if (!files.length) return;

      await this.$store.dispatch('attachments/createAttachment', {
        projectId: this.taskData.gantt_id,
        taskId: this.taskData.id,
        fileList: files,
      });

      this.scrollToAttachmentsIfNotVisible();
    },
    handleClickEditComment(comment) {
      this.currentEditComment = comment;
    },
    handleDeleteComment(commentId) {
      this.$store.dispatch('comments/deleteComment', {
        projectId: this.taskData.gantt_id,
        taskId: this.taskData.id,
        commentId,
      }).then(() => {
        this.currentEditComment = null;
      });
    },

  },

};
</script>

<style module lang="less">

.task-settings {
  &__drag-overlay {
    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 10000;
      -webkit-user-drag: none;
      background-image: url('https://cdn.ganttpro.com/app/imgs/task-settings/cloud-upload.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:before {
      display: none;
      z-index: 10001;
      text-align: center;
      content: attr(data-drag-over-text);
      position: absolute;
      max-width: fit-content;
      transform: translate(-50%, -50% + 66px);
      top: ~"calc(50% + 40px)";
      left: 50%;
      font-family: Lato-Regular, sans-serif;
      font-size: 24px;
      line-height: 26px;
      color: #FFFFFF;
    }

    &:after, &:before {
      display: block;
    }
  }

  &__inner-wrapper {
    height: 100%;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;

  }

  &__short {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;

  }

  &__body {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-x: hidden;
  }

  &__body-inner {
    section {
      padding: 0 32px;

      &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        margin-bottom: 30px;
      }
    }

    section:last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__comments {
    padding: 0 32px;
  }
}

.scroll-to-top-btn {
  z-index: 3;
  position: fixed;
  margin-left: 365px;
  margin-top: 5px;
  padding: 4px 10px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 29px;
  font-family: Lato-Bold, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
}

.no-selected-task-stub {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-search {
  display: flex;
  align-items: center;
  flex-direction: column;

  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
  }
}

.hide-scroll {
  overflow: hidden;
}

</style>
