import app from '../../app';
import _ from '../../libs/lodash';
import moment from '../../libs/moment';

import timeTrackingModel from '../../models/timeTracking';
import projectsModel from '../../models/projects';

import groupByTable from '../reports/include/groupByTable';
import exportModule from '../reports/include/export';

import timeParser from '../../helpers/timeParser';
import logsHeaderHelper from '../../helpers/logsHeaderHelper';
import calculateMainWindowSize from '../../helpers/calculateMainWindowSize';
import dateHelper from '../../helpers/dateFormats';
import globalStore from '$$store/main';
import rights from '../../components/rights';

const __ = window.__;

const TYPE = 'user-logs';

const filterConfig = [
  {
    filterType: 'text',
    filteredProperty: 'task_name',
  },
  {
    filterType: 'multicombo',
    filteredProperty: 'gantt_id',
    getValues() {
      return _.map(projectsModel.getAllProjects(), proj => ({
        id: proj.id,
        value: proj.name,
      }));
    },
  },
  {
    filterType: 'datepicker',
    filteredProperty: 'date',
  },
  {
    filterType: 'number_time',
    filteredProperty: 'time',
  },
  {
    filterType: 'text',
    filteredProperty: 'comment',
  },
];

const columnConfigs = [
  {
    id: 'task_name',
    groupId: 'task_id',
    css: 'task_name',
    fillspace: 3,
    header: __('group_by_table_task'),
    sort: 'task_name',
    template: 'groupColumnWithInfo',
    excelFormat: 'middleText',
  },
  {
    id: 'project_name',
    groupId: 'gantt_id',
    css: 'project_name',
    fillspace: 2,
    header: __('group_by_table_project'),
    sort: 'string',
    template: 'projectNameText',
    excelFormat: 'longText',
  },
  {
    id: 'date',
    groupId: 'string_date',
    groupFormat: 'date',
    groupSorting: 'task_name',
    css: 'date align_center editable',
    fillspace: 1,
    header: __('group_by_table_date'),
    sort: 'date',
    editor: 'date',
    format: 'date',
    excelFormat: 'date',
    suggest: {
      type: 'calendar',
      padding: 0,
      body: {
        icons: [{
          template() {
            return `<span class='webix_cal_icon_today webix_cal_icon'>${webix.i18n.calendar.today}</span>`;
          },
          on_click: {
            webix_cal_icon_today() {
              this.setValue(new Date());
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
          },
        }],
      },
    },
  },
  {
    id: 'time',
    groupId: 'time',
    groupFormat: 'time',
    css: 'time align_right editable',
    fillspace: 1,
    header: __('group_by_table_time'),
    sort: 'int',
    groupFunctor: 'sum',
    editor: 'text',
    editFormat: 'time',
    parseType: 'time',
    template: 'time',
    excelFormat: 'number',
  },
  {
    id: 'comment',
    css: 'comment editable',
    fillspace: 5,
    header: __('group_by_table_comment'),
    sort: 'string',
    editor: 'commentPopup',
    template: 'text',
    excelFormat: 'longText',
  },
];

const groupByConfig = [
  {
    id: 'task_id',
    text: __('group_by_task'),
  },
  {
    id: 'gantt_id',
    text: __('group_by_project'),
  },
  {
    id: 'string_date',
    text: __('group_by_date'),
  },
  {
    id: 'time',
    text: __('group_by_time'),
  },
];

const innerSettings = {
  resourceId: null,
};

const webixUI = {
  view: 'window',
  id: 'userLogsLayout',
  css: 'my-logs-layout tasks-view-list',
  borderless: true,
  // header: false,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  zIndex: 9,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state]);
  },
  body: {
    rows: [
      {
        view: 'layout',
        id: 'groupByTableContainer',
        rows: [],
      },
    ],
  },
  on: {
    onHide() {

    },
    onShow() {

    },
  },
};

const innerHandlers = {
  validateOnBeforeEditStop(log) {
    const logData = timeTrackingModel.getLogsByParams(log.gantt_id, log.task_id, log.id);
    const isArchiveProject = !!projectsModel.getArchivedProject(log.gantt_id);
    const taskData = globalStore.getters['tasksModel/getTask'](log.task_id);

    if (!taskData) {
      webix.message({ type: 'warning', text: __('task_was_removed_for_log_edit') });

      return false;
    }

    if (!logData) {
      webix.message({ type: 'warning', text: __('log_was_removed') });

      return false;
    }

    if (isArchiveProject) {
      webix.message({ type: 'warning', text: __('project_was_archived_for_log_edit') });

      return false;
    }

    return true;
  },
  onAfterLoadTable(tableData) {
    innerHandlers.updateHeaderLayout(tableData);
  },
  onAfterEditStopTable(newData, prop, valueState) {
    const logData = timeTrackingModel.getLogsByParams(newData.gantt_id, newData.task_id, newData.id);

    logData[prop] = newData[prop];
    logData.gantt_id = newData.gantt_id;

    logData.date = moment(logData.date).toDate();

    userExtAnalytics.log('user_time_log_list_log_change', {
      'comment length': logData.comment.length,
      'logged time': logData.time,
    });

    return timeTrackingModel.updateLog(logData, true)
      .then(() => innerHelpers.prepareTableData(timeTrackingModel.getLogsByResourceId(innerSettings.resourceId)));
  },
  exportToExcel() {
    const data = $$('groupByTableTree').serialize(); // already filtered data

    if (!data.length) {
      webix.message({ type: 'warning', text: __('no_data_for_export_my_logs') });

      return;
    }

    const isTree = !!data[0].data;

    const options = {
      title: __('logs_left_side_label'),
      isTree,
      columns: columnConfigs,
      data,
    };

    exportModule.exportToExcel(options);
    userExtAnalytics.log('user_time_log_list_export_done');
  },
  onGroupByChange(value) {
    app.trigger('filter:data:user-logs');

    if (value) {
      userExtAnalytics.log('user_time_log_list_group_by_change', {
        by: replaceAnalytics(value),
      });
    }

    function replaceAnalytics(val) {
      return val.map(item => {
        switch (item) {
        case 'task_id': return 'task name';
        case 'gantt_id': return 'project';
        case 'string_date': return 'date';
        case 'time': return 'time';
        default: return item;
        }
      });
    }
  },
  onAfterSortTable(state) {
    userExtAnalytics.log('user_time_log_list_sort_by_change', {
      by: analyticsReplace(state.id),
    });

    function analyticsReplace(txt) {
      switch (txt) {
      case 'task_name': return 'task name';
      case 'project_name': return 'project';
      default: return txt;
      }
    }
  },
  updateHeaderLayout(tableData, isSetFilter, filterRange) {
    const data = tableData || $$('groupByTableTree').serialize();
    const headerData = logsHeaderHelper(data, filterRange);

    globalStore.commit('headerView/setReportToolbarInfo', {
      total: timeParser.output(headerData.sum, {}),
      range: headerData.range,
      title_locale: 'common_my_logs_title',
      total_locale: 'total_time_spent',
    });
  },
  onAfterFilterTable(isFilterApplied) {
    // innerHandlers.updateHeaderLayout(false, isFilterApplied, logsFilter.handlers.getDateRange());
  },
};

const innerHelpers = {
  prepareTableData(userLogs) {
    const resultData = [];

    _.each(userLogs, logData => {
      const taskData = globalStore.getters['tasksModel/getTask'](logData.task_id);

      if (!taskData) {
        return;
      }

      const isArchiveProject = !!projectsModel.getArchivedProject(taskData.gantt_id);

      if (isArchiveProject) {
        return;
      }

      const projectData = projectsModel.getProjectDataById(taskData.gantt_id);

      if (!projectData) return;

      const date = moment(logData.date).startOf('day').toDate();

      resultData.push({
        id: logData.id,
        gantt_id: taskData.gantt_id,
        project_name: projectData.name,
        task_id: logData.task_id,
        task_name: taskData.text,
        info_text: taskData.note,
        date,
        string_date: webix.Date.dateToStr(dateHelper.getDateFormat())(date),
        time: logData.time,
        comment: logData.comment,
      });
    });

    return resultData;
  },
};

const init = function () {
  userExtAnalytics.log('my_logs_open');
  const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;
  const tableData = innerHelpers.prepareTableData(timeTrackingModel.getLogsByResourceId(resourceId));
  const sortConfig = {
    as: 'date',
    dir: 'desc',
    by: 'date',
  };

  innerSettings.resourceId = resourceId;

  const tableSettings = {
    sort: {
      as: 'date',
      dir: 'desc',
      by: 'date',
    },
    groupBy: [],
    type: TYPE,
  };// default settings

  $$('userLogsLayout').show();

  const container = $$('groupByTableContainer');
  const childViews = container.getChildViews();
  const data = {
    tableData,
    tableSettings,
    groupBySelectData: groupByConfig,
    columnsData: _.cloneDeep(columnConfigs),
    emptyDataLocales: {
      data: 'empty_data_logs_group_by_table',
      filter: 'empty_filter_logs_group_by_table',
      prefix: 'logs',
    },
    callbacks: {
      onAfterLoad: innerHandlers.onAfterLoadTable,
      onAfterEditStop: innerHandlers.onAfterEditStopTable,
      validateOnBeforeEditStop: innerHandlers.validateOnBeforeEditStop,
      onGroupByChange: innerHandlers.onGroupByChange,
      onAfterSort: innerHandlers.onAfterSortTable,
      onAfterFilter: innerHandlers.onAfterFilterTable,
    },
  };

  if (childViews.length) {
    container.reconstruct();
  }

  container.addView(groupByTable.init(data));

  globalStore.commit('headerView/setGroupByData', groupByConfig);
  app.trigger('filter:data:user-logs');
};

app.on('leftSideBar:changedMode', () => {
  $$('userLogsLayout') && $$('userLogsLayout').resize();
});
app.on('header:export:report', type => {
  if (type === TYPE) {
    innerHandlers.exportToExcel();
  }
});

webix.attachEvent('onClick', e => {
  if (e && e.target.closest('.my-logs-layout') && !e.target.closest('.filter-set')) {
    app.trigger('table:area:click');
  }
});

app.on('onAfterCollaboration', e => {
  if (e.event === 'TaskDeleted') {
    if ($$('userLogsLayout')) init();
  }
});

export {
  init,
  webixUI,
};
