<template>
  <div :class="[$style.input_search, 'input-search']">
    <sprite
      :class="$style.icon"
      name="search"
      color="#B2B2B2"
    />
    <vgp-input
      v-model="content"
      :value="content"
      :class="$style.input"
      :small="true"
      :placeholder="placeholder"
      :border="false"
      :gp-autotest-name="gpAutotestName"
    />
  </div>
</template>

<script>

import vgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import sprite from '../../../common/VueComponents/VueIcon/svgSprite.vue';

export default {
  name: 'InputSearch',
  components: { vgpInput, sprite },
  props: {
    placeholder: {
      type: String,
      required: true,
      default: '—',
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    gpAutotestName: {
      type: String,
      required: true,
      default: '',
    },

  },
  emits: {
    onInput: String,
  },
  data() {
    return {
      content: '',
    };
  },
  watch: {
    content() {
      this.$emit('onInput', this.content);
    },
    value(newValue) {
      if (newValue === '') this.content = '';
    },
  },
  created() {
    this.content = this.value ? this.value : '';
  },
};
</script>

<style module src='./inputSearch.less'></style>
