import { render, staticRenderFns } from "./sidebarMenuItem.vue?vue&type=template&id=3413c42d&scoped=true&"
import script from "./sidebarMenuItem.vue?vue&type=script&lang=js&"
export * from "./sidebarMenuItem.vue?vue&type=script&lang=js&"
import style0 from "../style.less?vue&type=style&index=0&module=true&lang=less&"
import style1 from "./sidebarMenuItem.vue?vue&type=style&index=1&id=3413c42d&scoped=true&lang=less&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["../style.less?vue&type=style&index=0&module=true&lang=less&"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("../style.less?vue&type=style&index=0&module=true&lang=less&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/home/ganttpro/app/node_modules/vue-hot-reload-api/dist/index.js").rerender("3413c42d")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3413c42d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ganttpro/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3413c42d')) {
      api.createRecord('3413c42d', component.options)
    } else {
      api.reload('3413c42d', component.options)
    }
    module.hot.accept("./sidebarMenuItem.vue?vue&type=template&id=3413c42d&scoped=true&", function () {
      api.rerender('3413c42d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/views/leftSideBar/includes/sidebarMenuItem.vue"
export default component.exports