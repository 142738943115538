<template>
  <div @click="$emit('click')"></div>
</template>

<script>
function data_handler(value) {
  const view = $$(this.webixId);

  if (typeof value === 'object') {
    if (this.copyData) { value = webix.copy(value); }
    if (view.setValues) { view.setValues(value); } else if (view.parse) {
      view.clearAll();
      view.parse(value);
    }
  } else if (view.setValue) { view.setValue(value); }

  webix.ui.each(view, sub => {
    if (sub.hasEvent && sub.hasEvent('onValue')) { sub.callEvent('onValue', [value]); }
  }, this, true);
}

export default {
  name: "WebixUi",
  props: ['config', 'value', 'copyData'],
  watch: {
    value: {
      handler: data_handler,
    },
    config(newConfig) {
      if (newConfig && newConfig.isReactive) {
        webix.$$(this.webixId).destructor();
        const config = webix.copy(newConfig);

        config.$scope = this;
        this.webixId = webix.ui(config, this.$el);
      }
    },
  },

  mounted() {
    const config = webix.copy(this.config);

    config.$scope = this;

    if ($$(config.id)) {
      return;
    }

    this.webixId = webix.ui(config, this.$el);
    if (this.value) data_handler.call(this, this.value);
  },
  destroyed() {
    if ($$(this.webixId)) {
      webix.$$(this.webixId).destructor();
    }
  }
}
</script>

<style scoped>

</style>