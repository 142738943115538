var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.toggle_field },
    [
      _c(
        "span",
        {
          class: ["tooltip-gantt"],
          attrs: {
            "data-position": _vm.tooltipPosition,
            "data-key": _vm.tooltip,
            "data-y": "12",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.toggle_field_line }),
      _vm._v(" "),
      _c("toggle", {
        attrs: { "is-toggle-on": _vm.value },
        on: { onChange: _vm.toggle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }