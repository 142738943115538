const innerObject = {
  COMMON_USER_PHOTO: 'https://ganttprowe.blob.core.windows.net/app/imgs/common_user_avatar.svg',
  DEFAULT_PROJECT_TYPE: 'team',
  STATUS_DEFAULT: 1,
  PRIORITY_DEFAULT: 7,
  TASK_DATES_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  MIN_PASSWORD_LENGTH: 8,
  MIN_USER_SCORING_PERCENT_FOR_DEMO_SCHEDULE: 64,

  JOB_TITLES: [
    { c_level: 'job_option_partner' },
    { group_manager: 'job_option_head_of' },
    { manager: 'job_option_manager' },
    { project_manager: 'job_option_pm' },
    { student: 'job_option_student' },
    { educator: 'job_option_educator' },
    { other: 'job_option_other' },
  ],

  JOB_FIELDS: [
    { marketing: 'app_welcome_form_job_field_option_marketing' },
    { engineering: 'app_welcome_form_job_field_option_engineering' },
    { development: 'app_welcome_form_job_field_option_development' },
    { accounting: 'app_welcome_form_job_field_option_accounting' },
    { hr: 'app_welcome_form_job_field_option_hr' },
    { other: 'app_welcome_form_job_field_option_other' },
  ],
  TOOL_USED_BEFORE: [

    { excel_or_google_sheet: 'app_welcome_form_tool_used_before_excel_or_google_sheet' },
    { ms_project: 'app_welcome_form_tool_used_before_ms_project' },
    { jira_or_analogs: 'app_welcome_form_tool_used_before_jira_or_analogs' },
    { other_gantt_software: 'app_welcome_form_tool_used_before_other_gantt_software' },
    { other_pm_software: 'app_welcome_form_tool_used_before_other_pm_software' },
    { no_tool: 'app_welcome_form_tool_used_before_no_tool' },
    { student: 'app_welcome_form_tool_used_before_student' },
    { other: 'app_welcome_form_tool_used_before_other' },
  ],
  INDUSTRIES: [
    { marketing: 'app_welcome_industry_btn_marketing' },
    { construction: 'app_welcome_industry_btn_construction' },
    { legal: 'app_welcome_industry_btn_legal' },
    { tax: 'app_welcome_industry_btn_tax' },
    { architecture: 'app_welcome_industry_btn_architecture' },
    { non_profit: 'app_welcome_industry_btn_non_profit' },
    { soft: 'app_welcome_industry_btn_soft' },
    { healthcare: 'app_welcome_industry_btn_healthcare' },
    { financial: 'app_welcome_industry_btn_financial' },
    { staffing: 'app_welcome_industry_btn_staffing' },
    { education: 'app_welcome_industry_btn_education' },
    { manufacturing: 'app_welcome_industry_btn_manufacturing' },
    { technology: 'app_welcome_industry_btn_technology' },
    { estate: 'app_welcome_industry_btn_estate' },
  ],
  TEAM_SIZES: [
    { 1: 'app_welcome_industry_btn_one' },
    { 4: '2-4' },
    { 20: '5-20' },
    { 50: '21-50' },
    { 200: '51-200' },
    { 500: '201+' },
  ],
  NOTIFICATION_ACTIONS: {
    MENTION: 1,
    ASSIGN: 2,
    COMMENT: 3,
    ATTACHMENT: 4,
    FEATURES: 5,
    PROMO: 6,
    WELCOME: 7,
    START_TASK: 8,
    DEADLINE: 9,
    EMAIL: 10,
    INVITE_TO_TEAM: 11,
    END_TASK: 12,
  },
  TASK_TYPES: {
    project: 'project',
    task: 'task',
    milestone: 'milestone',
    button: 'button',
  },
  TASK_STATUSES: {
    1: {
      color: '#9b9b9b',
      locale: 'filter_status_open',
    },
    2: {
      color: '#ff9921',
      locale: 'filter_status_in_progress',
    },
    3: {
      color: '#55BED4',
      locale: 'filter_status_done',
    },
    4: {
      color: '#14bb4c',
      locale: 'filter_status_closed',
    },
  },
  STATUSES: [
    {
      id: '1',
      color: '#9b9b9b',
      locale: 'filter_status_open',
    },
    {
      id: '2',
      color: '#ff9921',
      locale: 'filter_status_in_progress',
    },
    {
      id: '3',
      color: '#55BED4',
      locale: 'filter_status_done',
    },
    {
      id: '4',
      color: '#14bb4c',
      locale: 'filter_status_closed',
    },
  ],
  PRIORITIES: [
    {
      id: '5',
      iconUrl: 'https://cdn.ganttpro.com/app/imgs/highest.svg',
      locale: 'filter_priority_highest',
    },
    {
      id: '6',
      iconUrl: 'https://cdn.ganttpro.com/app/imgs/high.svg',
      locale: 'filter_priority_high',
    },
    {
      id: '7',
      iconUrl: 'https://cdn.ganttpro.com/app/imgs/medium.svg',
      locale: 'filter_priority_medium',
    },
    {
      id: '8',
      iconUrl: 'https://cdn.ganttpro.com/app/imgs/low.svg',
      locale: 'filter_priority_low',
    },
    {
      id: '9',
      iconUrl: 'https://cdn.ganttpro.com/app/imgs/lowest.svg',
      locale: 'filter_priority_lowest',
    },
  ],
  ROLES: {
    OWNER: 1,
    ADMIN: 4,
    MEMBER: 3,
  },

  MAINTENANCE_NOTIFY: [
    { id: 'new_release_planned', value: 'New release planned' },
    { id: 'api_changes_are_coming', value: 'API changes are coming' },
    { id: 'temporary_performance_issues', value: 'Temporary performance issues' },
    { id: 'technical_issues', value: 'Technical issues' },
    { id: 'currently_experiencing_technical_difficulties', value: 'Currently experiencing technical difficulties' },
  ],

  RESOURCE_TIME_TYPE: 1,
  RESOURCE_ITEM_TYPE: 2,
  RESOURCE_COST_TYPE: 3,
  RESOURCE_TYPE_LOCALE: {
    1: 'resources_default_price_label_per_hour',
    2: 'resource_item_type_select',
    3: 'resource_cost_type_select',
  },
  COMMON_EMPTY_RESOURCE: 'notAssigned',
  COMMON_EMPTY_RESOURCE_ACTION: 'unassigned_action',
  ADMIN_PANEL: {
    DATE_FORMAT: 'YYYY-MM-DD HH:mm',
  },
  CALENDARS: {
    DATE_FORMAT: 'YYYY/MM/DD',
    LAYOUT_TYPES: {
      RESOURCE_CALENDAR: 'Resource',
      PROJECT_CALENDAR: 'Main',
      TEMPLATE_CALENDAR: 'Templates',
    },
    DAY_TYPES: {
      WEEKEND: 'weekend',
      CUSTOM: 'custom',
      WORKDAY: 'normal',
    },
  },
  PLANS: {
    FREE: 0,
    FOREVER_FREE: 1,
    ESSENTIAL: 2,
    TEAM_5: 4,
    TEAM_10: 5,
    TEAM_15: 6,
    TEAM_25: 6,
    TEAM_TRIAL: 12,
    GANTT_PRO: 13,
    BASE: 14,
    PRO: 15,
    BUSINESS: 16,
    ENTERPRISE_TEAM: 17,
    ENTERPRISE: 18,
  },
  ZOOM: {
    modeToZoomValue: {
      hour: 0, day: 1, week: 2, month: 3, quarter: 4, year: 5,
    },
    zoomValueToMode: ['hour', 'day', 'week', 'month', 'quarter', 'year'],
  },
  TEMPLATES_CATEGORIES: {
    construction: 'construction_templates_category',
    software_development: 'software_development_templates_category',
    services: 'services_templates_category',
    marketing: 'marketing_templates_category',
    consulting: 'consulting_templates_category',
    event_planning: 'event_planning_templates_category',
    web_design: 'web_design_templates_category',
    product_launch: 'product_launch_templates_category',
    retail: 'retail_templates_category',
    learning: 'learning_templates_category',
    methodology: 'methodology_templates_category',
    roadmap: 'roadmap_templates_category',
    planner: 'planner_templates_category',
    manufacturing: 'manufacturing_templates_category',
  },
  DURATION_LIMITS: {
    minute: 540000,
    hour: 9000,
    day: 3000,
    week: 600,
    month: 150,
  },
  CUSTOM_COLUMNS_TYPES: {
    select: {
      id: 1,
      locale: 'custom_columns_option_type_select',
    },
    text: {
      id: 2,
      locale: 'custom_columns_option_type_text',
    },
    number: {
      id: 3,
      locale: 'custom_columns_option_type_number',
    },
    date: {
      id: 4,
      locale: 'custom_columns_option_type_date',
    },
    checkbox: {
      id: 5,
      locale: 'custom_columns_option_type_checkbox',
    },
    color: {
      id: 6,
      locale: 'custom_columns_option_type_color',
    },
    multiselect: {
      id: 7,
      locale: 'custom_columns_option_type_multiselect',
    },
    resources: {
      id: 8,
      locale: 'custom_columns_option_type_resources',
    },
    tags: {
      id: 9,
      locale: 'custom_columns_option_type_tags',
    },
  },
  CUSTOM_COLUMNS_SUBTYPES: {
    sum: {
      id: 1,
      locale: 'custom_columns_option_aggregation_sum',
    },
    average: {
      id: 2,
      locale: 'custom_columns_option_aggregation_average',
    },
  },
  PALETTE_CONFIG: {},
  COLORS: {},
  DARKEN_COLORS: {},
  LIGHT_COLORS: {},
  TITLE_COLORS: {},
  SKINS: {
    1: {
      class: 'first-skin',
      locale: 'skins_horizontal_borders',
    },
    2: {
      class: 'second-skin',
      locale: 'skins_no_borders',
    },
    3: {
      class: 'third-skin',
      locale: 'skins_vertical_borders',
    },
    4: {
      class: 'fourth-skin',
      locale: 'skins_all_borders',
    },
  },
  // getColorsById,
  DEFAULT_TYPE_COLORS: {
    TASK: 1,
    GROUP: 10,
    PROJECT: 12,
    MILESTONE: 16,
    ESTIMATION: 18,
  },
  COLUMN_ORDER: {
    NOT_DRAGABLE: ['masschange', 'overdue', 'overload', 'comments', 'attachments', 'wbs', 'text', 'buttons', 'workload_resource_name'],
    NOT_MOVABLE_AFTER: ['masschange', 'overdue', 'overload', 'comments', 'attachments', 'wbs', 'text', 'buttons'],
    NOT_DROPABLE_BEFORE: ['masschange', 'overdue', 'overload', 'comments', 'attachments', 'wbs', 'text', 'buttons'],
    ALWAYS_FIXED_START: ['masschange', 'overdue', 'overload', 'comments', 'attachments', 'wbs', 'text'],
    ALWAYS_FIXED_END: ['buttons'],
    NOT_HIDABLE: ['text', 'overdue', 'overload', 'wbs', 'comments', 'attachments', 'masschange', 'workload_resource_name', 'buttons'],
  },
  FILTER_TYPES: {
    PROJECT: 'project',
    MULTIVIEW: 'multiview',
    WORKLOAD: 'workload',
    TASKSLIST: 'listView',
    TIMELOGS: 'timeloglist',
    COSTSFILTER: 'costslist',
  },
  GANTT_BASE_COLUMNS: {
    TASK_NAME: 'text',
    //
  },
  PRICING_FEATURES: {
    portfolio_view: {
      title: false,
      gif: true,
    },
    bulk_change: {
      title: true,
      gif: true,
    },
    overdue_tasks: {
      title: true,
      gif: true,
    },
    custom_fields: {
      title: false,
      gif: false,
    },
    cascade_sorting: {
      title: true,
      gif: true,
    },
    saving_project_templates: {
      title: false,
      gif: true,
    },
    saving_filters: {
      title: true,
      gif: true,
    },
    costs: {
      title: true,
      gif: false,
    },
    estimation: {
      title: false,
      gif: false,
    },
    estimation_assign: {
      title: true,
      gif: false,
    },
    time_tracking: {
      title: true,
      gif: true,
    },
    time_log: {
      title: false,
      gif: true,
    },
    workload: {
      title: false,
      gif: false,
    },
    workload_estimation: {
      title: false,
      gif: false,
    },
    workload_estimation_duration: {
      title: false,
      gif: false,
    },
    jira_cloud: {
      title: false,
      gif: false,
    },
    personal_calendar: {
      title: true,
      gif: false,
    },
  },
  USER_ACTIVITIES: {
    HELP_ONBOARDING: 'onboardingHelpBlock', // "онбординг"
    HELP_CREATE_ACOUNT: 'createAccountHelpBlock', // "создание аккаунта"
    HELP_CUSTOM_GRID: 'customGridHelpBlock', // "колонки"
    HELP_FILTER_VIEW: 'filterViewHelpPopup', // "фильтр"
    HELP_PROJ_SETTINGS: 'settingsHelpBlock', // "настройки проекта"
    HELP_HISTORY: 'historyHelpPopup', // "история"
    HELP_BASELINE: 'baselineHeaderHelpBlock', // "базовый план"
    HELP_PUBLIC_LINK: 'publicLinkHelpBlock', // "публичная ссылка"
    HELP_USER_TEMPLATES: 'saveUserTemplateHelpBlock', // "шаблоны"
    HELP_MANAGE_TEAM: 'manageTeamLayoutHelpPopup', // ? "команда" в команда и ресурсы
    HELP_MANAGE_RES: 'resourcesLayoutHelpPopup', // ? "ресурсы" в команда и ресурсы
    HELP_TASK_COST: 'taskCostHelpBlock', // "стоимость задач"
    HELP_NOTIFICATIONS: 'userNotificationHelpBlock', // "уведомления"
    HELP_WORKLOAD: 'workloadHelpBlock', // "загрузка ресурсов"
    HELP_PROJ_IMPORT: 'importProjectBlock', // "импорт проектов"
    HELP_PROJ_CREATE: 'newProjectBlock', // "создание проекта"
    HELP_GOOGLE_DRIVE: 'googleDriveCreateDontShow', // "чекбокс гугл драйв создаие документа"
    HELP_POPUP_ESP_LOCALE: 'espPopupCreateDontShow', // "попап для испанской локали"
    HELP_NPS_POPUP: 'npsPopup', // "попап NPS"
    HELP_EXPORT: 'toolBarExportHelpBlock', // "экспорт"
    DEMO_POPUP: 'demoModalPopup', // "демо попап"
    LAST_NEWS_ID: 'lastViewedNewsEntryId', // id последней прочитанной новости
    PAYMENT: 'paymentWindow', // "прайсинг виндоу"
    ONBOARDING: 'onboarding', // "онбоардинг"
    WORKLOAD: 'workload',
    MSTRECONNECT: 'msTeamsReconnect',
    CONVERSION_FEATURE_NOTIFICATION: 'conversionFeatureNotification',
    COMMENTS_FILTERED_TAB: 'commentsFilteredTab',
    COMMENTS_SELECTED_PROJECTS: 'commentsSelectedProjects',
  },
  WORKLOAD_PANEL_MIN_HEIGHT: 60,
  WORKLOAD_PANEL_DEFAULT_HEIGHT: 200,
  ATTACHMENT_TYPE_KEYS: {
    FILE: 1,
    GOOGLE_DRIVE: 2,
    ONE_DRIVE: 3,
  },
  PROJECT_ROLES_DEFAULT: {
    owner: 'OWNER',
    admin: 'ADMIN',
  },
  OVERVIEW_TYPES: {
    ACTUAL_TO_PLANNED: 'actual_to_planned',
    ACTUAL_TO_BASELINE: 'actual_to_baseline',
  },
  TOAST_CONFIG: {
    position: 'top-right',
    timeout: 2500,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    draggable: false,
    closeOnClick: true,
  },
  PROGRESS_BAR: {
    STATUS: {
      STARTED: 1,
      UPDATE: 2,
      DONE: 3,
      FAIL: 4,
    },
    TIMEOUT: 4000,
  },

  PAYMENT_STATUSES: {
    ORDER_CHARGED: {
      CODE: 1,
      TEXT: 'OrderCharged',
    },
    ORDER_REFUNDED: {
      CODE: 2,
      TEXT: 'OrderRefunded',
    },
    ORDER_CHARGED_BACK: {
      CODE: 3,
      TEXT: 'OrderChargedBack',
    },
    ORDER_DECLINED: {
      CODE: 4,
      TEXT: 'OrderDeclined',
    },
    ORDER_PARTIALLY_REFUNDED: {
      CODE: 5,
      TEXT: 'OrderPartiallyRefunded',
    },
    SUBSCRIPTION_CHARGE_SUCCEED: {
      CODE: 6,
      TEXT: 'SubscriptionChargeSucceed',
    },
    SUBSCRIPTION_CHARGE_FAILED: {
      CODE: 7,
      TEXT: 'SubscriptionChargeFailed',
    },
    SUBSCRIPTION_SUSPENDED: {
      CODE: 8,
      TEXT: 'SubscriptionSuspended',
    },
    SUBSCRIPTION_RENEWED: {
      CODE: 9,
      TEXT: 'SubscriptionRenewed',
    },
    SUBSCRIPTION_TERMINATED: {
      CODE: 10,
      TEXT: 'SubscriptionTerminated',
    },
    SUBSCRIPTION_FINISHED: {
      CODE: 11,
      TEXT: 'SubscriptionFinished',
    },
    ORDER_PROCESSING: {
      CODE: 110,
      TEXT: 'OrderProcessing',
    },
    PARTNER_ACTIVATE: {
      CODE: 8,
      TEXT: 'PartnerActivate',
    },
    PARTNER_DEACTIVATE: {
      CODE: 8,
      TEXT: 'PartnerDeactivate',
    },
    TRIAL_ACTIVE: {
      CODE: 8,
      TEXT: 'TrialActive',
    },
  },
};

export default innerObject;
