<template>
  <onboarding-section :animate-leave="animateLeave">
    <template #right>
      <div :class="$style.right__wrapper">
        <div
          :class="[
            $style.select__wrapper,
            animateEnter && !animateLeave ? $style.animateEnter : '',
            animateLeave && !animateEnter ? $style.animateLeave : '',
          ]"
        >
          <div
            v-for="item in plansItems"
            :id="`gp_autotest_registration_3_step_selectItem_${item.value}`"
            :key="item.value"
            :class="$style.item__wrapper"
            @click="handleClick(item.value)"
          >
            <span :class="$style.item__text">
              {{ item.label }}
            </span>
            <div :class="$style.icon__wrap">
              <vgpSvgImage
                :class="$style.item__icon"
                v-bind="item.iconConfig"
              />
            </div>
          </div>
        </div>
        <portal
          to="onboarding-footer"
        >
          <vgp-button
            id="gp_autotest_registration_3_step_submit_button"
            :class="[
              $style.submit_button,
              animateEnter && !animateLeave ? $style.animateEnter : '',
              animateLeave && !animateEnter ? $style.animateLeave : '',
            ]"
            type="text-green"
            :label="locales('onboarding_skip_step_btn_label')"
            small
            @onClick="nextStep(true)"
          />
        </portal>
      </div>
    </template>
  </onboarding-section>
</template>

<script>
import { mapState } from 'vuex';
import OnboardingSection from '../OnboardingSection.vue';
import vgpSvgImage from '../../../common/VueComponents/VueIcon/vgpSvgImage.vue';
import profile from '../../../../models/profile';
import rights from '../../../../components/rights/index';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';

export default {
  name: 'MembersInfo',
  components: {
    OnboardingSection,
    vgpSvgImage,
  },
  data() {
    return {
      locales: __,
      animateEnter: false,
      animateLeave: false,
      plansItems: [
        {
          label: __('onboarding_planning_step_for_work_label'),
          value: 'work',
          iconConfig: {
            name: 'onboarding-bag',
            height: 26,
            width: 26,
          },
        },
        {
          label: __('onboarding_planning_step_for_personal_label'),
          value: 'personal_projects',
          iconConfig: {
            name: 'onboarding-person',
            height: 30,
            width: 30,
          },
        },
        {
          label: __('onboarding_planning_step_for_study_label'),
          value: 'study',
          iconConfig: {
            name: 'onboarding-books',
            height: 30,
            width: 30,
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
  },
  mounted() {
    setTimeout(() => {
      this.animateEnter = true;
    }, 0);
  },
  methods: {
    handleClick(value) {
      this.nextStep();
      profile.saveSettings({ user_planning_use_for: value });
    },
    nextStep(isSkip = false) {
      if (this.animateLeave) {
        return;
      }

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
        if (isSkip) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
        }
      }

      this.animateEnter = false;
      this.animateLeave = true;
      setTimeout(() => {
        this.$store.dispatch('onboarding/nextStep');
      }, 750);
    },
  },
};
</script>

<style lang="less" module>
@import "../../../../less/_variables.less";

.right__wrapper {
  margin-right: 48px;
}

.select__wrapper {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  display: flex;
  flex-direction: column;

  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

.item__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #E3E8EC;
  border-radius: 5px;
  padding: 22px 0 22px 16px;
  width: 229px;

  &:nth-child(2) .icon__wrap {
    align-items: flex-end;
  }

  &:nth-child(1) .icon__wrap {
    svg {
      rotate: -8.9deg;
    }
    path {
      stroke: #ffffff;
    }
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:hover {
    cursor: pointer;
    background: #337870;
    box-shadow: 0px 6px 20px rgba(51, 120, 112, 0.35);
    .item__text {
      color: @white;
    }
  }

  &:active {
    box-shadow: none;
  }
}

.item__text {
  font-family: Lato-Bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #586972;
}

.item__icon {

}

.icon__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -20px;
  background: #FFFFFF;
  box-shadow: 0px 6px 13px rgba(0, 86, 76, 0.26);
  border-radius: 5px;
  width: 40px;
  height: 40px;
}

.submit_button {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  &.animateLeave {
    transition: opacity .2s, transform .2s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .3s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
