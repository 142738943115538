import Vue from 'vue';
import helpers from './helpers';
import rights from '../../components/rights/index';

export default {
  setData(state, payload) {
    state.data = payload.map(helpers.prepareResourceData);
  },
  addResources(state, payload) {
    state.data.push(...payload.map(helpers.prepareResourceData));
  },
  updateResource(state, payload) {
    const index = state.data.map(r => r.id).indexOf(payload.resourceId);

    if (index !== -1) {
      Vue.set(state.data, index, {
        ...state.data[index],
        ...payload.updated,
      });
    }
  },
  removeResources(state, payload) {
    state.data = state.data.filter(r => !payload.includes(r.id));
  },
  addResourceToProjects(state, payload) {
    const resourceData = state.data.find(res => res.id === +payload.resourceId);

    if (!resourceData) {
      return;
    }

    resourceData.resourceProjects = [..._.concat(resourceData.resourceProjects, payload.resourceProjects)];
  },
  addResourceToProject(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    if (!resourceData) {
      return;
    }

    delete payload.resourceId;
    
    // onResourceToProjectsAssigned webhook workaround

    // if (payload.role_id) {
    //   payload.projectRoleId = payload.role_id;
    //   delete payload.role_id;
    // }

    resourceData.resourceProjects.push(payload);
  },
  removeResourceFromProject(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    if (!resourceData) {
      return;
    }

    resourceData.resourceProjects = resourceData.resourceProjects.filter(o => o.projectId !== payload.projectId);
  },
  removeAllResourcesFromProject(state, payload) {
    state.data.forEach(resourceData => {
      resourceData.resourceProjects = resourceData.resourceProjects.filter(o => o.projectId !== payload.projectId);
    });
  },
  updateResourceOnProjects(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    if (!resourceData) {
      return;
    }

    resourceData.resourceProjects.forEach((config, i) => {
      if (payload.projectIds.includes(config.projectId)) {
        Vue.set(resourceData.resourceProjects, i, {
          ...resourceData.resourceProjects[i],
          ...payload.updated,
        });
      }
    });

    if (payload?.updated?.projectRoleId) rights.project.clearCacheGetUserRole();
  },
  removeResourceFromProjects(state, payload) {
    const resourceData = state.data.find(res => +res.id === +payload.resourceId);

    if (!resourceData) {
      return;
    }

    resourceData.resourceProjects = resourceData.resourceProjects.filter(config => !payload.projectIds.includes(config.projectId));
  },
  addWorkCustomDays(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    resourceData.customDays.push(...payload.customDays);
  },
  updateWorkCustomDay(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    if (!resourceData) {
      return;
    }

    const index = resourceData.customDays.map(o => o.id).indexOf(payload.customDay.id);

    Vue.set(resourceData.customDays, index, {
      ...resourceData.customDays[index],
      ...payload.customDay,
    });
  },
  removeWorkCustomDays(state, payload) {
    const resourceData = state.data.find(res => res.id === payload.resourceId);

    if (!resourceData) {
      return;
    }

    resourceData.customDays = resourceData.customDays.filter(customDay => !payload.customDayIds.includes(customDay.id));
  },
};
