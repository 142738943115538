var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.search_input] }, [
    _c("div", { class: [_vm.$style.input_label] }, [
      _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.outerValue.from || _vm.outerValue.to,
              expression: "outerValue.from || outerValue.to",
            },
          ],
          class: _vm.$style.reset,
          attrs: { id: _vm.gpAutotestName + "_reset_btn" },
          on: {
            click: function ($event) {
              return _vm.resetSelected()
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.locale.reset) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.$style.input_field,
          _vm.outerValue.from || _vm.focusFrom ? _vm.$style.active : "",
        ],
      },
      [
        _c("input", {
          attrs: {
            id: _vm.gpAutotestName + "_from",
            placeholder: _vm.locale.from,
          },
          domProps: { value: _vm.showValue.from },
          on: {
            change: function ($event) {
              return _vm.onChange("from", $event.target.value)
            },
            focus: function ($event) {
              _vm.focusFrom = true
            },
            blur: function ($event) {
              _vm.focusFrom = false
            },
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.$style.input_field,
          _vm.outerValue.to || _vm.focusTo ? _vm.$style.active : "",
        ],
      },
      [
        _c("input", {
          attrs: { id: _vm.gpAutotestName + "_to", placeholder: _vm.locale.to },
          domProps: { value: _vm.showValue.to },
          on: {
            change: function ($event) {
              return _vm.onChange("to", $event.target.value)
            },
            focus: function ($event) {
              _vm.focusTo = true
            },
            blur: function ($event) {
              _vm.focusTo = false
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }