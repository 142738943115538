<template>
  <div :class="$style.reports">
    <div
      v-if="accessToReports"
      :class="$style.reportsCardList"
    >
      <div
        v-for="card in reportCards"
        :key="card.id"
        :class="$style.cardItem"
        @click="selectReport(card.type)"
      >
        <div
          :class="$style.icon"
          :style="{ backgroundImage: `url(${card.icon})` }"
        />
        <div :class="$style.title">
          {{ card.title }}
        </div>
        <div :class="$style.description">
          {{ card.description }}
        </div>
        <div
          v-if="card.linkName"
          :class="$style.link"
        >
          {{ card.linkName }}
        </div>
      </div>
    </div>
    <div
      v-else
      :class="$style.reports_not_access"
    >
      <div :class="$style.upgrade_info">
        <div :class="$style.title_wrapper">
          <div :class="$style.title">
            {{ locales(`reports_access_title`) }}
          </div>
          <div class="pricing-title-container">
            {{ locales(planTitle) }}
          </div>
        </div>
        <div :class="$style.text">
          <div class="desc-text">
            {{ locales('reports_access_text') }}
            <div class="learn_more">
              <a
                class="pricing-popup-link"
                :href="locales('reports_learn_more')"
                target="_blank"
                @click="linkClick"
              >{{ locales('help_read_more') }}</a>
              <webix-ui
                class="arrow-left"
                :config="icons.arrowLeft"
              />
            </div>
          </div>
        </div>
        <upgrade-btn
          v-if="isOwner"
          :feature="'reports'"
        />
      </div>
      <div :class="$style.pic_wrapper">
        <img
          :src="accessPic"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import app from '../../app';
import router from '../../helpers/router';
import pricingHelper from '../../helpers/pricingHelper';

import upgradeBtn from '../common/VueComponents/ugradeButton/upgradeButton.vue';
import svgIconForVue from '../common/svgIconForVue';
import arrowLeft from '../../svg/pricing/ic_arrow_left.svg';
import rights from '../../components/rights/index';

export default {
  components: {
    upgradeBtn,
  },
  data() {
    return {
      locales: __,
      reportCards: [
        {
          id: 1,
          type: 'timeloglist',
          icon: `${GT.cdn}/imgs/report_logs.svg`,
          title: __('title_report_time_tracking'),
          description: __('description_report_time_tracking'),
          linkName: __('report_create'),
        },
        {
          id: 2,
          type: 'costslist',
          icon: `${GT.cdn}/imgs/report_costs.svg`,
          title: __('title_report_costs'),
          description: __('description_report_costs'),
          linkName: __('report_create'),
        },
        {
          id: 3,
          type: 'feedback',
          icon: `${GT.cdn}/imgs/report_feedback_form.svg`,
          title: __('title_report_feedback_form'),
          description: __('description_report_feedback_form'),
        },
      ],
      accessPic: `${GT.cdn}/imgs/reports/blocked_im_report.png`,
      icons: {
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
      },
    };
  },
  computed: {
    accessToReports() {
      return pricingHelper.checkPricingAccess('reports');
    },
    planTitle() {
      const planTitle = `${pricingHelper.getPlans('reports')}_plan_locale`;

      return planTitle;
    },
    isOwner() {
      return rights.account.isOwner()
    },
  },
  mounted() {
    this.$nextTick(() => {
      userExtAnalytics.log('reports_all_open');
      // app.trigger('popup:closeOther', 'reportsLayout');
    });
  },
  methods: {
    selectReport(type) {
      if (type === 'feedback') {
        app.trigger('messageUsForm:show', 'reports-feedback');

        return;
      }

      router.changeRoute(`/reports/${type}`);
    },
    linkClick() {
      userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'reports' });
    },
  },
};
</script>

<style module src='./less/reportsMain.less'></style>
<style src='./less/pricingTitle.less'></style>
<style lang="less">
.desc-text{
    .learn_more{
        .pricing-popup-link{
            font-size: 14px !important;
        }
    }
}

</style>
