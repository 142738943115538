var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "80",
        height: "8",
        viewBox: "0 0 80 8",
        fill: "none",
      },
    },
    [
      _c("defs", [
        _c("mask", { attrs: { id: "progressMask" } }, [
          _c("rect", {
            attrs: { width: "100%", height: "8", rx: "4", fill: "#ffffff" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("rect", {
        attrs: { width: "100%", height: "8", rx: "4", fill: "#E5E5E5" },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          width: _vm.value + "%",
          height: "8",
          fill: "#4484CD",
          mask: "url(#progressMask)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }