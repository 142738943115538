var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status-row" },
    [
      _c("vgp-color-palette", {
        staticClass: "color-area",
        attrs: {
          "selected-values": _vm.color,
          "select-field": "select",
          "select-type": "single",
          colors: _vm.colors,
          "select-width": "72px",
        },
        on: { selectColors: _vm.onChangeColor },
      }),
      _vm._v(" "),
      _c("vgp-input", {
        staticClass: "input-area",
        attrs: {
          type: "status",
          placeholder: _vm.locale("enter_name_locale"),
          value: _vm.localeArray.includes(_vm.value)
            ? _vm.locale(_vm.value)
            : _vm.value,
          "select-by-focus": true,
        },
        on: { onChange: _vm.onChangeName, onKeydown: _vm.onKeyDown },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "trash-area", class: { hoverable: !_vm.isDefault } },
        [
          !_vm.isDefault
            ? _c("svg-sprite", {
                staticClass: "delete",
                attrs: { name: "delete" },
                on: { click: _vm.onShowDeletePopup },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDefault
            ? _c("svg-sprite", {
                staticClass: "tooltip-gantt warning-icon",
                attrs: {
                  name: "warning-2",
                  "data-key": "project_statuses_default_status",
                  "data-position": "top",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: !!_vm.deleteInfoData,
          "popup-width": 440,
          text: _vm.locale("project_status_delete_description"),
          "ok-button": {
            title: _vm.locale("common_delete"),
            type: "destructive",
          },
          "cancel-button": {
            title: _vm.locale("common_cancel"),
            type: "secondary",
          },
        },
        on: {
          onClickOk: function ($event) {
            return _vm.onCloseDeletePopup("ok")
          },
          onClickCancel: _vm.onCloseDeletePopup,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }