var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.$style["task-settings-dependencies"] },
    [
      _c("div", { class: _vm.$style["header"] }, [
        _c(
          "div",
          { class: _vm.$style["title-wrap"] },
          [
            _c("h2", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.locales("settings_taskDependency")) +
                  "\n        "
              ),
              _c("span", [_vm._v(_vm._s(_vm.dependencies.length))]),
            ]),
            _vm._v(" "),
            !_vm.isFieldDisabled("dependencies")
              ? _c("vgp-icon-button", {
                  attrs: { icon: { name: "plus-1" } },
                  on: {
                    onClick: function ($event) {
                      _vm.showNewDependencyForm = true
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showNewDependencyForm
        ? _c("new-dependency-form", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: {
                  handler: _vm.newDependencyFormClickOutsideHandler,
                  closeConditional: function () {
                    return _vm.showNewDependencyForm
                  },
                },
                expression:
                  "{\n      handler: newDependencyFormClickOutsideHandler,\n      closeConditional: () => showNewDependencyForm\n    }",
              },
            ],
            ref: "newDependencyForm",
            attrs: {
              "task-data": _vm.taskData,
              "existing-dependencies": _vm.dependencies,
            },
            on: {
              "dependency-created": _vm.handleDependencyCreated,
              close: function ($event) {
                _vm.showNewDependencyForm = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style["dependencies__list"] },
        _vm._l(_vm.dependencies, function (dependency) {
          return _c("dependency-row", {
            key: dependency.linkId,
            ref: "dependencies",
            refInFor: true,
            attrs: { dependency: dependency },
            on: {
              "change-lag": function ($event) {
                return _vm.handleChangeDependencyLag(dependency, $event)
              },
              delete: function ($event) {
                return _vm.showDeleteConfirmPopup("single_delete", dependency)
              },
              "show-preview": function ($event) {
                return _vm.showDependencyPreview(dependency, $event)
              },
              "hide-preview": function ($event) {
                _vm.dependencyPreviewProps = null
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.dependencyPreviewProps
        ? _c(
            "dependency-preview",
            _vm._b({}, "dependency-preview", _vm.dependencyPreviewProps, false)
          )
        : _vm._e(),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          "popup-width": 440,
          show: _vm.confirmDeletionData.showPopup,
          text: _vm.confirmDeletionData.text,
          "cancel-button": {
            title: _vm.locales("common_cancel"),
            type: "secondary",
          },
          "ok-button": {
            title: _vm.locales("common_delete"),
            type: "destructive",
          },
        },
        on: {
          onClickOk: _vm.handleConfirmDeletion,
          onClickCancel: _vm.cleanUpConfirmDeletionData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }