import projectComponent from '../../Project';
import projectsModel from '../../../models/projects';
import { onProjectConfigUpdated } from './helpers';
import helpers from '../helpers';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';

function onProjectConfigUpdatedFunc(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  event.data.updated.forEach(item => {
    const isSameProjectOpen = _.includes(ganttIDs, +item.projectId);
    onProjectConfigUpdated(item.projectId, item.config);

    if (isSameProjectOpen) {
      helpers.notify({
        userPic: event.innerSettings.user.photo,
        locale: 'history_event_project_updated',
        data: {},
      });
    }
  });
}

export default onProjectConfigUpdatedFunc;
