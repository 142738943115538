import app from '../app';
import _ from '../libs/lodash';
import constants from '../helpers/constants';
import projectsModel from '../models/projects';
import customDaysTemplatesModel from '../models/customDayTemplates';
import timeTrackingModel from '../models/timeTracking';
import filtersModel from '../models/filters';
import historyModel from '../models/history';
import notifyComponent from './notifications';
import collaborationComponent from './Collaboration';
import userProjectConfigModel from '../models/userProjectConfigs';
import moment from '../libs/moment';
import multiViewsProjects from '../models/multiViewsProjects';
import globalStore from '../store/main';
import routerHelper from '../helpers/router';
import activeTab from '../models/activeTabs';

let projectSharedFrom = null;
const projectComponent = {};

projectComponent.addNewProject = function ({
  projectName, sourceGanttId, projectSettings, applyResources,
}, headers) {
  const projectData = {
    userId: user.id,
    skin: '4',
    name: _.escape(projectName),
    sourceGanttId,
    gantt_id: Date.now(),
    settings: projectSettings,
    status: constants.DEFAULT_PROJECT_TYPE,
    applyResources,
    isFromTemplate: projectSettings.isFromTemplate,
    onboardingProjectData: projectSettings.onboardingProjectData,
  };

  globalStore.dispatch('teamSetting/addProjectStatus', projectData.gantt_id);

  return projectComponent.addNewProjectRequest(projectData, headers);
  // return projectComponent.responseHandler(projectComponent.addNewProjectRequest(projectData), 'newMainProject');
};

projectComponent.addNewImportProject = function (projectName, projectConfig, importProjectType, importProjectData, mppToken) {
  const projectData = {
    user_id: user.id,
    skin: '4',
    name: _.escape(projectName),
    gantt_id: Date.now(),
    status: 'import',
    projectStatus: 'team',
    config: projectConfig,
    importType: importProjectType,
    importProjectData,
    mppToken,
  };

  globalStore.dispatch('teamSetting/addProjectStatus', projectData.gantt_id);

  return projectComponent.addNewProjectRequest(projectData);
  // projectComponent.responseHandler(projectComponent.addNewProjectRequest(projectData), 'newMainProject');
};

projectComponent.importFinishExcel = function (data) {
  if (data) {
    activeTab.saveActiveGanttId(data?.projectData?.gantt_id);
  }
  // webix.ajax().post("/api/pipedrive", {projects_created: '+1'});
  const projects = projectsModel.getAllProjects().length;
  const multiviews = multiViewsProjects.getAllMultiviews().length;

  webix.ajax().post('/api/pipedrive', {
    projects_created: multiviews + projects,
    is_first_task_created: 1,
  });

  window.location.assign('/');
};

projectComponent.importFinish = function (ganttId) {
  projectComponent.getLastActionHash(ganttId)
    .then(actionHash => {
      const projectData = {
        user_id: user.id,
        gantt_id: ganttId,
        status: 'import',
        actionHash,
        importFinish: true,
      };

      return webix.ajax()
        .post('/api/projects', projectData);
    });
};

projectComponent.addNewJiraProject = function (ganttId) {
  projectComponent.responseHandler(projectComponent.getNewJiraProject(ganttId), 'newJiraProject');
};

projectComponent.addNewTeamSharedProject = function (ganttId) {
  projectComponent.responseHandler(projectComponent.getTeamSharedProjectData(ganttId), 'newTeamSharedProject');
};

projectComponent.addNewTeamProjects = function (ganttIds) {
  projectComponent.responseHandler(projectComponent.getTeamProjects(ganttIds), 'newTeamProjects');
};

projectComponent.prepareNewProjectData = function (data) {
  const responseRawData = data.response;
  const actionHash = data.actionHash;
  const responseData = responseRawData.json();

  // responseData.projectData.access = 100;
  // gantt.config.needUpdateTasksDatesAfterCreateProject = true;

  return projectComponent.manualAddDataToModels(responseData, null, actionHash);
};

projectComponent.prepareNewProjectJiraData = function (responseRawData) {
  const responseData = responseRawData.json();

  // responseData.projectData.access = 100;
  projectComponent.manualAddDataToModels(responseData, 'jira');
  projectsModel.setActiveProject(responseData.projectData.gantt_id, null, null, null);
};

projectComponent.prepareNewSharedProjectData = function (responseRawData) {
  const responseData = responseRawData.json();

  projectSharedFrom = responseData?.ownerData || {};
  projectComponent.manualAddDataToModels(responseData, 'shared');
  notifyComponent.showNotifyForSharedProject(responseData.projectData, projectSharedFrom, responseData.comments.count, responseData.projectData.projectType);
  projectSharedFrom = null;
};

projectComponent.prepareNewTeamProjectsData = function (responseRawData) {
  const responseData = responseRawData.json();

  _.each(responseData, data => {
    projectComponent.manualAddDataToModels(data, 'silence');
  });
};

projectComponent.updateShareProjectData = function (responseRawData) {
  const responseData = responseRawData.json();

  responseData.access = responseData.rights;
  projectsModel.manualUpdate(responseData);
};

// projectComponent.changeAdvancedRightsTeamShareProject = function (responseRawData) {
//   const responseData = responseRawData.json();
//
//   projectsModel.manualUpdateRights(responseData);
// };

projectComponent.prepareDataToArchiveProject = function (responseRawData) {
  const responseData = responseRawData.json();

  projectsModel.manualUpdateArchive(responseData);
  projectsModel.addArchivedProjects(responseData.ganttId);
  globalStore.dispatch('tasksModel/archiveTasks', { ganttId: responseData.ganttId, value: 1 });

  app.trigger('project:archive', responseData.ganttId, 1);
  if (+gantt.config.gantt_id === +responseData.ganttId) {
    app.trigger('gantt:reinitConfig');
  }
};

projectComponent.onChangeProjectData = function (projectData) {
  projectsModel.manualUpdate(projectData);
};

projectComponent.userHasProject = function (projectId) {
  const projects = projectsModel.getAllProjects();

  return projects.some(el => +el.gantt_id === +projectId);
};

projectComponent.prepareDataToUnArchiveProject = function (responseRawData, isCollaboration) {
  const responseData = responseRawData.json();
  let promise = Promise.resolve();
  const existArchivedProject = projectsModel.manualUpdateArchive(responseData);

  if (!existArchivedProject) {
    promise = projectComponent.loadArchiveProject(responseData.ganttId, 0);
  }

  promise
    .then(() => {
      projectsModel.removeArchivedProjects(responseData.ganttId);
      globalStore.dispatch('tasksModel/archiveTasks', { ganttId: responseData.ganttId, value: 0 });

      projectsModel.initProjectCalendar(projectsModel.getItem(responseData.ganttId));

      app.trigger('project:archive', responseData.ganttId, 0, isCollaboration);

      if (+gantt.config.gantt_id === +responseData.ganttId) {
        app.trigger('gantt:reinitConfig');
      }
    });
};

projectComponent.prepareDataToRemoveProject = function (responseRawData) {
  const responseData = responseRawData.json();

  projectComponent.manualRemoveProject(parseInt(responseData.ganttId, 10));

  globalStore.commit('resourcesModel/setData', responseData.resourcesData);
};

projectComponent.prepareDataToRemoveArchivedProject = function (responseRawData) {
  const responseData = responseRawData.json();

  projectsModel.removeArchivedProjects(parseInt(responseData.ganttId, 10));
  projectComponent.manualRemoveProject(parseInt(responseData.ganttId, 10));
  // app.trigger('project:component:remove', parseInt(responseData.ganttId, 10));
};

projectComponent.manualRemoveProject = function (ganttId) {
  if (ganttId) {
    // globalStore.commit('tasksModel/deleteTasksByGanttId', ganttId); // keep data for workload calculation
    projectsModel.manualRemoveProject(ganttId);
    multiViewsProjects.removeProjectFromAllMultiviews(ganttId);
  }

  // app.socket.emit("checkTotalComments", {});
  app.trigger('project:component:remove', ganttId);
};

projectComponent.manualRemoveTeamProject = function (ganttId) {
  const projectData = projectsModel.getProjectDataById(ganttId);

  if (!ganttId || !projectData) {
    return;
  }

  projectComponent.manualRemoveProject(ganttId);
  notifyComponent.removeTeamProjectMessage();
};

projectComponent.manualRemoveSharedProject = function (projectData) {
  projectComponent.manualRemoveProject(parseInt(projectData.gantt_id, 10));
  notifyComponent.removeSharedProjectMessage(projectData, projectData.ownerUser);
};

projectComponent.manualRemoveSharedProjectAfterDelete = function (projectData) {
  projectComponent.manualRemoveProject(parseInt(projectData.gantt_id, 10));
  notifyComponent.deleteSharedProjectMessage(projectData, projectData.ownerUser);
};

projectComponent.manualRemoveMainProject = function (ganttId) {
  projectComponent.manualRemoveProject(ganttId);
};
projectComponent.updateLastUpdate = _.debounce(ganttId => webix.ajax().get(`/api/projects/lastUpdate/${ganttId}`)
  .then(rawResponseData => {
    const project = projectsModel.getItem(ganttId);

    if (!project) {
      return;
    }
    const projectData = rawResponseData.json();
    const multiviews = multiViewsProjects.getMultiviewsByGanttId(ganttId);

    if (project) {
      project.last_update = projectData.last_update;
      projectsModel.manualUpdate(project);
    }

    if (multiviews) {
      _.each(multiviews, multiview => {
        multiview.last_update = projectData.last_update;
        multiViewsProjects.manualUpdate(multiview);
      });
    }
  }), 5000);

projectComponent.loadArchiveProject = function (ganttID, unarchive = 1) {
  const projectData = projectsModel.getArchivedProject(ganttID);

  return collaborationComponent.getProjectDataFromServer(ganttID)
    .then(rawResponseData => {
      const responseData = rawResponseData.json();

      projectData.is_archived = unarchive;

      if (responseData.userCustomColumnsData) {
        globalStore.commit('columns/addProject', {
          projectId: ganttID,
          data: responseData.userCustomColumnsData,
        });
      }

      if (responseData.timeTrackingData) {
        timeTrackingModel.manualUpdateItem(responseData.timeTrackingData, ganttID, 'unarchive');
      }

      if (responseData.taskData) {
        responseData.taskData.is_archived = unarchive;
        responseData.taskData.id = +responseData.taskData.id;
        responseData.taskData.links = responseData.taskData.tasks.links;
        responseData.taskData.tasks = responseData.taskData.tasks.data;
        globalStore.commit('tasksModel/addProjectTasksData', responseData.taskData);
      }

      if (responseData.resources) {
        globalStore.commit('resourcesModel/setData', responseData.resources);
      }

      if (responseData.projectData) {
        projectData.config = responseData.projectData.config;
        projectData.config = userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(
          projectData.gantt_id,
          projectData.config,
        );
      }

      if (projectsModel.getItem(projectData.id)) {
        projectsModel.manualLiveUpdate(projectData);
      } else {
        projectsModel.blockEvent();
        projectsModel.data.blockEvent();
        projectsModel.manualAddProject(projectData, projectData.projectType, 'silence');
        projectsModel.unblockEvent();
        projectsModel.data.unblockEvent();
      }

      return Promise.resolve();
    });
};

projectComponent.archiveProject = function (ganttID, value, sharedUsers, isCollaboration) {
  projectsModel.manualUpdateFavorite(ganttID, 0)
    .then(() => {
    });

  if (isCollaboration) {
    return {
      json: () => ({
        ganttId: ganttID,
        isArchived: value ? 1 : 0,
        updated: 1,
        date: new Date(),
      }),
    };
  }

  return webix.ajax()
    .put(`/api/projects/${ganttID}/archive`, {
      isArchived: value ? 1 : 0,
      sharedUsers,
    })
    .then(data => data);
};

projectComponent.deleteProject = function (projectData) {
  return webix.ajax()
    .del('/api/projects', projectData)
    .then(data => {
      gantt._forceInitMultiview = true;
      app.trigger('project:del', projectData);
      globalStore.dispatch('integrationGoogle/removeAfterCollabaration', { id: projectData.gantt_id });
      globalStore.dispatch('integrationOutlook/removeAfterCollabaration', { id: projectData.gantt_id });

      return data;
    });
};

projectComponent.archiveProjectByGanttId = async function (ganttId, isCollaboration) {
  const projectData = projectsModel.getProjectDataById(ganttId);

  if (!projectData) {
    // TODO: make collaboration for archive projects
    return;
  }
  let getUsersByGanttId = await projectComponent.getUsersByGanttId(ganttId);

  if (isCollaboration) {
    getUsersByGanttId = [];
  }

  const archiveProject = await projectComponent.archiveProject(projectData.gantt_id, true, getUsersByGanttId, isCollaboration);

  await projectComponent.prepareDataToArchiveProject(archiveProject);

  multiViewsProjects.removeProjectFromAllMultiviews(ganttId);

  const allProjects = projectsModel.getAllProjects();

  if (allProjects.length <= 0) {
    routerHelper.changeRoute('/newproject');
    app.trigger('sideBar:closeProjects');
  }
  app.trigger('project:archive', projectData);

  globalStore.dispatch('integrationGoogle/removeAfterCollabaration', { id: projectData.gantt_id });
  globalStore.dispatch('integrationOutlook/removeAfterCollabaration', { id: projectData.gantt_id });
};

projectComponent.unArchiveProjectByGanttId = async function (ganttId, isCollaboration) {
  let getUsersByGanttId = await projectComponent.getUsersByGanttId(ganttId);

  if (isCollaboration) {
    getUsersByGanttId = [];
  }

  const archiveProject = await projectComponent.archiveProject(ganttId, false, getUsersByGanttId, isCollaboration);

  await projectComponent.prepareDataToUnArchiveProject(archiveProject, isCollaboration);
};

projectComponent.deleteProjectByGanttId = async function (ganttId) {
  let projectData = projectsModel.getProjectDataById(ganttId);
  const usersData = [];

  if (projectsModel.isArchived(ganttId)) {
    projectData = projectsModel.getArchivedProject(ganttId);
  }
  const getUsersByGanttId = await projectComponent.getUsersByGanttId(ganttId);
  const deleteProject = await projectComponent.deleteProject({
    id: projectData.project_id,
    usersData,
    gantt_id: projectData.gantt_id,
    teamSharedUsers: getUsersByGanttId,
    team_id: user.team && user.team.id,
    user_id: user.id,
    is_jira: projectData.is_jira,
  });

  if (projectsModel.isArchived(ganttId)) {
    await projectComponent.prepareDataToRemoveArchivedProject(deleteProject);
  } else {
    await projectComponent.prepareDataToRemoveProject(deleteProject);
  }

  globalStore.commit('tasksModel/deleteTasksByGanttId', ganttId);
};

projectComponent.getUsersByGanttId = function (ganttId) {
  return webix.ajax().get(`/api/teamShare/${ganttId}?${Date.now()}`)
    .then(responseRawData => responseRawData.json());
};

projectComponent.manualChangeRightsInTeamProject = function (ganttId, teamUserId) {
  if (teamUserId === user.id) {
    projectComponent.responseHandler(
      projectComponent.getTeamProjectData(ganttId),
      'updateTeamSharedProject',
    );
  }
};

// projectComponent.manualChangeAdvancedRightsInTeamProject = function (ganttId) {
//   projectComponent.responseHandler(
//     projectComponent.getTeamProjectData(ganttId),
//     'changeRightsTeamSharedProject',
//   );
// };

projectComponent.manualAddDataToModels = async function (responseData, manualType, actionHash) {
  const ganttId = responseData.projectData.gantt_id;

  if (responseData.projectData.is_jira) {
    manualType = 'jira';
  }

  if (responseData.userCustomColumnsData && responseData.userCustomColumnsData.id) {
    responseData.userCustomColumnsData.id = +responseData.userCustomColumnsData.id;
  }

  globalStore.dispatch('tasksModel/addTasksAfterCreateNewProject', { ganttId, projectTasksData: responseData.taskData });
  customDaysTemplatesModel.manualAddTemplateToShareProject(responseData.customDays, ganttId);
  globalStore.commit('resourcesModel/setData', responseData.resources);
  projectsModel.manualAddProject(responseData.projectData, responseData.projectData.projectType, manualType);

  if (responseData.customColumns) {
    manualType === 'jira'
      ? globalStore.commit('columns/setJiraColumns', responseData.customColumns)
      : globalStore.commit('columns/setStaticColumns', responseData.customColumns);
  }

  globalStore.getters['columns/getProjectById'](+ganttId)
    ? globalStore.commit('columns/updateProject', { projectId: +ganttId, updated: { ...responseData.userCustomColumnsData } })
    : globalStore.commit('columns/addProject', { projectId: +ganttId, data: responseData.userCustomColumnsData });

  if (responseData.globalCustomColumnsData) globalStore.commit('columns/setCustomColumns', responseData.globalCustomColumnsData);
  timeTrackingModel.manualUpdateItem(responseData.timeTrackingData, ganttId, manualType);
  responseData.filters && filtersModel.manualUpdateActiveFilters(responseData.filters);

  // const isMppImport = responseData.projectData.status === 'import' && responseData.projectData.importType === 'mpp';

  if (manualType !== 'shared' && manualType !== 'jira') {
    const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
    const projectRoles = globalStore.getters['roles/getProjectRoles'];

    if (resourceData.resourceProjects.find(settings => settings.projectId === +ganttId)) {
      globalStore.commit('resourcesModel/removeResourceFromProject', {
        resourceId: resourceData.id,
        projectId: +ganttId,
      });
    }
    await globalStore.dispatch('resourcesModel/addResourcesToProjects', [{
      resourceId: resourceData.id,
      projectIds: [ganttId],
      projectRoleId: projectRoles.find(role => role?.defaultType === constants.PROJECT_ROLES_DEFAULT.owner)?.id,
    }]);
  }

  const project = projectsModel.getProjectDataById(ganttId);

  projectsModel.initProjectCalendar(project);

  app.trigger('project:component:add', project);

  if (projectsModel.count() > 1
   && (manualType === 'shared' || manualType === 'jira' || manualType === 'silence')) {
    return;
  }

  projectsModel.setActiveProject(ganttId, null, null, actionHash);

  return Promise.resolve(project);
};

projectComponent.addNewProjectForOwner = async function (ganttId) {
  const responseRawData = await projectComponent.getTeamSharedProjectData(ganttId);
  const responseData = responseRawData.json();

  projectComponent.manualAddDataToModels(responseData, 'shared');
};

projectComponent.addNewProjectByCollaboration = async function (responseData, projectSharedFrom, showNotifyAboutShareProject, config) {
  // const responseRawData = await projectComponent.getProjectDataFromServerForCollaboration(ganttId);
  // const responseRawData = await projectComponent.getTeamSharedProjectData(ganttId);
  // const responseData = responseRawData.json();
  const {
    isCreator,
    isAPI,
    isImport,
  } = config;

  const userSkin = responseData?.userProjectConfig?.skin;

  if (userSkin) {
    responseData.projectData.userSkin = userSkin;
  }

  await projectComponent.manualAddDataToModels(responseData, 'shared');
  // await projectComponent.manualAddDataToModels(responseData, isImport && isCreator ? null : 'shared');

  if (isCreator && !isAPI) {
    const ganttId = responseData.projectData.gantt_id;

    projectsModel.setActiveProject(ganttId, null, null, null);
  }

  if (isImport === 'mpp' && isCreator) {
    app.trigger('import:progress:mpp', responseData.projectData.gantt_id);
  }

  if (showNotifyAboutShareProject) {
    notifyComponent.showNotifyForSharedProject(responseData.projectData, projectSharedFrom, 0, 'team');
  }
};

projectComponent.responseHandler = function (responsePromise, strategyMethod) {
  const strategyHandler = {
    newMainProject: projectComponent.prepareNewProjectData,
    newSharedProject: projectComponent.prepareNewSharedProjectData,
    newJiraProject: projectComponent.prepareNewProjectJiraData,
    newTeamSharedProject: projectComponent.prepareNewSharedProjectData,
    newTeamProjects: projectComponent.prepareNewTeamProjectsData,
    updateTeamSharedProject: projectComponent.updateShareProjectData,
    // changeRightsTeamSharedProject: projectComponent.changeAdvancedRightsTeamShareProject,
    deleteProject: projectComponent.prepareDataToRemoveProject,
    deleteArchivedProject: projectComponent.prepareDataToRemoveArchivedProject,
    archiveProject: projectComponent.prepareDataToArchiveProject,
    unArchiveProject: projectComponent.prepareDataToUnArchiveProject,
  };

  if (strategyHandler[strategyMethod]) {
    return responsePromise
      .then(strategyHandler[strategyMethod])
      .fail(projectComponent.responseErrorHandler);
  }
  responsePromise
    .then(data => {
      webix.message(`CAN'T FOUND STRATEGY FOR RESPONSE:${strategyMethod}`);
      console.error("CAN'T FOUND STRATEGY FOR RESPONSE:", strategyMethod, data.json());
    })
    .fail(projectComponent.responseErrorHandler);
};

projectComponent.responseErrorHandler = function (errorData) {
  console.warn(errorData);
  webix.message({ type: 'error', text: 'Something is broken...' });
};

projectComponent.addNewProjectRequest = function (projectData, headers) {
  const actionHash = historyModel.generateActionHash();

  projectData.actionHash = actionHash;
  gantt._correctDatesActionHash = projectData.actionHash;

  // webix.ajax().post("/api/pipedrive", { projects_created: '+1' });

  const projects = projectsModel.getAllProjects().length;
  const multiviews = multiViewsProjects.getAllMultiviews().length;

  webix.ajax().post('/api/pipedrive', { projects_created: multiviews + projects + 1 });

  if (headers && Object.keys(headers).length) {
    return webix.ajax().headers(headers)
      .post('/api/projects', projectData)
      .then(response => ({ response, actionHash }));
  }

  return webix.ajax()
    .post('/api/projects', projectData)
    .then(response => ({ response, actionHash }));
};

projectComponent.getProjectsList = function () {
  const projects = projectsModel.getAllProjects();
  const multiviews = multiViewsProjects.getAllMultiviews();

  const projectsTasks = _.reduce(projects, (projectTasks, project) => {
    const projectData = globalStore.getters['tasksModel/getItem'](project.gantt_id);

    projectTasks[project.gantt_id] = projectData ? projectData.tasks : [];

    return projectTasks;
  }, {});

  _.each(projects, project => {
    const totalEstimateTask = _.find(projectsTasks[project.gantt_id], task => _.isNull(task.parent) || task.parent === 0);

    if (totalEstimateTask && totalEstimateTask.id) {
      project.progress = totalEstimateTask.progress * 100;
    } else {
      project.progress = 0;
    }

    project.progress = isNaN(project.progress) ? 0 : Math.floor(project.progress);

    project.countOfOverdue = 0;
    project.countOfSlightlyOverdue = 0;
    project.countOfPerSchedule = 0;
    project.type = 'project';

    let unassignedTasksLength = 0;

    _.each(projectsTasks[project.gantt_id], task => {
      if (task.type !== gantt.config.types.task) {
        return;
      }

      if (task.type === gantt.config.types.task && task.resources.length === 0) {
        unassignedTasksLength++;
      }

      const endDate = task.end_date || gantt.calculateEndDate({
        start_date: new Date(task.start_date),
        duration: task.duration,
        task,
      });
      const date1 = new Date();

      if (date1 > new Date(endDate) && task.progress < 1 && task.type === 'task') {
        project.countOfOverdue++;
      } else if (date1 > new Date(task.start_date) && date1 < new Date(endDate) && task.progress === 0) {
        project.countOfSlightlyOverdue++;
      } else if (task.progress === 1) {
        project.countOfPerSchedule++;
      }
    });
    project.unassignedTasks = unassignedTasksLength;

    project.last_update = new Date(project.last_update).valueOf();
  });

  _.each(multiviews, multiview => {
    let addToProjects = true;
    const result = {
      ...multiview,
      type: 'multiview',
      progress: 0,
      countOfOverdue: 0,
      countOfSlightlyOverdue: 0,
      countOfPerSchedule: 0,
      unassignedTasks: 0,
      last_update: new Date(multiview.last_update).valueOf(),
    };

    const ganttIDs = multiview.ganttIDs || [];

    if (ganttIDs.length) {
      _.each(ganttIDs, (ganttID, index) => {
        const project = _.find(projects, { gantt_id: ganttID });

        if (!project) {
          addToProjects = false;

          return;
        }

        result.countOfOverdue += project.countOfOverdue;
        result.countOfSlightlyOverdue += project.countOfSlightlyOverdue;
        result.countOfPerSchedule += project.countOfPerSchedule;
        result.unassignedTasks += project.unassignedTasks;

        result.progress += project.progress;
      });

      result.progress = Math.floor(result.progress / ganttIDs.length);

      addToProjects && projects.push(result);
    }
  });

  return projects;
};

projectComponent.getTeamSharedProjectData = function (ganttId) {
  return webix.ajax()
    .get(`/api/projects/teamShare/${ganttId}`);
};

projectComponent.getTeamProjects = function (ganttIds) {
  return webix.ajax()
    .get('/api/projects/newTeamProjects', {
      ganttIds,
    });
};

projectComponent.getTeamProjectData = function (ganttId) {
  return webix.ajax()
    .get(`/api/projects/teamProject/${ganttId}`);
};

projectComponent.getSharedProjectData = function (ganttId) {
  return webix.ajax()
    .get(`/api/projects/share/${ganttId}`);
};

projectComponent.getNewJiraProject = function (ganttId) {
  return webix.ajax()
    .get(`/api/projects/jira/${ganttId}`);
};

projectComponent.getProjectDataFromServerForCollaboration = function (ganttId) {
  return webix.ajax()
    .get(`/api/collaboration/${ganttId}?${Date.now()}`);
};

// projectComponent.getTeamAdvancedAccess = function (ganttId) {
//   const projectData = projectsModel.getProjectDataById(ganttId);
//
//   if (projectData.status === 'team') {
//     return projectData.advanced_rights;
//   }
//
//   return 0;
// };

projectComponent.changeRightsInShareProject = function (projectData) {
  projectsModel.manualUpdateAccess(projectData);
  notifyComponent.changeRightsShareProjectMessage(projectData, projectData.ownerUser);
};

projectComponent.updateProjectSortType = function (type, desc) {
  return webix.ajax()
    .put('/api/userSettings/projects_sort_type', { type, desc })
    .then(data => {

    })
    .fail(err => {
      console.error('updateProjectSortType', err);
    });
};

projectComponent.updateProjectStatus = function (newId, ganttId, statusId) {
  return webix.ajax()
    .put('/api/projects/status', { newId, ganttId, statusId })
    .fail(err => {
      console.error('statusFailed', err);
    });
};

projectComponent.getLastActionHash = function (ganttId) {
  if (!ganttId) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    webix.ajax()
      .get(`/api/history/actionHash/${ganttId}`)
      .then(data => {
        let actionHash = null;

        try {
          actionHash = data.json();
        } catch (e) {
          return reject(null);
        }

        return resolve(actionHash);
      });
  });
};

projectComponent.addNewSharedProjectCollaboration = async function addNewSharedProjectCollaboration(data) {
  const ganttId = parseInt(data.gantt_id, 10);

  projectSharedFrom = data.ownerUser;

  return projectComponent.getTeamSharedProjectData(ganttId)
    .then(projectComponent.prepareNewSharedProjectData)
    .fail(projectComponent.responseErrorHandler);
};

projectComponent.addNewSharedProject = function (data) {
  const ganttId = parseInt(data.gantt_id, 10);

  projectSharedFrom = data.ownerUser;
  projectComponent.addNewTeamSharedProject(ganttId);
};

projectComponent.deleteSharedProject = function (ganttId) {
  projectComponent.manualRemoveTeamProject(parseInt(ganttId, 10));
};

projectComponent.onArchivedProject = async function (data) {
  const ganttId = data.ganttId;
  const isArchived = data.isArchived;

  if (isArchived) {
    !projectsModel.isArchived(ganttId) && await projectComponent.archiveProjectByGanttId(ganttId, true);
  } else {
    projectsModel.isArchived(ganttId) && await projectComponent.unArchiveProjectByGanttId(ganttId, true);
  }
};

app.on('openProject', ganttID => {
  routerHelper.pushRoute(`/project/${ganttID}/gantt`);
});

// app.on('history:openEvent', (ganttID, actionHash) => {
//   if (!actionHash) {
//     const actionHashPromise = projectComponent.getLastActionHash(ganttID);

//     return actionHashPromise
//       .then((actionHashFromServer) => {
//         if (!actionHashFromServer) {
//           return Promise.resolve();
//         }
//         return webix.ajax().post('/api/projects/', {
//           gantt_id: ganttID,
//           openEvent: true,
//           actionHash: actionHashFromServer
//         });
//       });
//   } else {
//     return Promise.resolve()
//       .then(() => {
//         return webix.ajax().post('/api/projects/', {
//           gantt_id: ganttID,
//           openEvent: true,
//           actionHash: actionHash
//         });
//       })
//   }

// });

// app.socket.on("teamShare:addProject", function (data) {
//   var ganttId = parseInt(data.gantt_id, 10);

//   projectSharedFrom = data.ownerUser;
//   projectComponent.addNewTeamSharedProject(ganttId);
// });

// app.socket.on("teamShare:deleteProject", function (ganttId) {
//   projectComponent.manualRemoveTeamProject(parseInt(ganttId, 10));
// });

// app.socket.on("teamShare:archiveProject", function (data) {
//   projectComponent.onArchivedProject(data);
// });

// app.socket.on('team:newProjects', ganttIds => {
//   projectComponent.addNewTeamProjects(ganttIds);
// });

// app.socket.on('removeProject', projectData => {
//   projectComponent.manualRemoveSharedProject(projectData);
// });

// app.socket.on('teamShare:change', teamData => {
//   projectComponent.manualChangeRightsInTeamProject(parseInt(teamData.gantt_id, 10), parseInt(teamData.teamUserId, 10));
// });

// app.socket.on('teamShare:advancedRights:change', ganttId => {
//   projectComponent.manualChangeAdvancedRightsInTeamProject(parseInt(ganttId, 10));
// });

// app.socket.on('deleteProject', projectData => {
//   projectComponent.manualRemoveSharedProjectAfterDelete(projectData);
// });

// app.socket.on('changeRights', projectData => {
//   projectComponent.changeRightsInShareProject(projectData);
// });

app.socket.on('update:project:lastUpdate', data => {
  projectComponent.updateLastUpdate(data.gantt_id);
});

app.socket.on('task:update:estimation', data => {
  projectComponent.updateLastUpdate(data.ganttId);
});

export default projectComponent;
