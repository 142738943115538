var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", { attrs: { d: "M4 13V12H21V13H4Z" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M4 13V12H21V13H4Z" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M12 4H13V21H12V4Z" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M12 4H13V21H12V4Z" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }