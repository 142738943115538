<template>
  <div :class="$.expansion_panel_wrapper">
    <div
      :class="$.expansion_panel_task_title"
      @click.stop="toggle"
    >
      <div :class="$.wrapper">
        <icon-button
          :class="[$.expand_button, show ? $.open : '']"
          :icon="{ name:'drop-down', type: 'bold' }"
          size="small"
        />
        <div :class="$.name">
          {{ taskNameString }}
        </div>
        <vgp-button
          :class="$.all_attachments_button"
          type="text-grey"
          :label="locales('all_task_attacments') + ' ' + attachments.length"
          :icon="{name: 'link-2', size: 20}"
          icon-side="right"
          :small="true"
          @click.stop
          @click.prevent
          @onClick="$emit('open-task', taskId)"
        />
      </div>
    </div>
    <transition name="accordion">
      <div
        v-show="show"
        :class="[
          $.expansion_panel_task_content_outer_wrap,
        ]"
      >
        <div :class="[$.expansion_panel_task_content]">
          <div :class="$.expansion_panel_task_content_inner_wrap">
            <div :class="$.expansion_panel_attachments_list">
              <div
                v-for="item in attachments"
                :key="item.id"
                :class="$.expansion_panel_attachments_list__item"
                @click="$emit('click-file', item)"
              >
                <div :class="$.item_img">
                  <img
                    v-if="!item.isStub"
                    :src="item.thumbnailLink"
                    loading="lazy"
                    @error="$event.target.src = defaultImagePlaceholder"
                  >
                  <div
                    v-else
                    v-html="imgStub"
                  />
                </div>
                <div :class="$.item_info">
                  <span :class="$.name">{{ item.name }}</span>
                  <div :class="$.info">
                    <span
                      v-if="checkResourceExist(item.userId)"
                      :class="$.info_author"
                    >
                      {{ item.user.firstName + ' ' + item.user.lastName }} &nbsp;
                    </span>
                    <span
                      v-else
                      :class="$.info_author"
                    >
                      {{ `${item.user.firstName} ${item.user.lastName} (${locales('task_settings_author_deleted_label')})` }} &nbsp;
                    </span>
                    <span>{{ `${item.uploadDate}` }} &nbsp;</span>
                    <span>{{ item.size }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="hasRights"
              :class="$.new_attachment_button"
            >
              <label>
                <vgp-button
                  type="text"
                  :label="locales('attacments_add_button')"
                  :icon="{name: 'plus-1', size: 20, type: 'bold'}"
                  icon-side="left"
                  :small="true"
                />
                <input
                  type="file"
                  hidden
                  multiple
                  @change="$emit('files-upload', $event, taskId)"
                >
              </label>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import IconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import imgStub from './svg/img-stub.svg';

export default {
  name: 'AttachmentsAccordion',
  components: {
    IconButton,
  },
  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    taskName: {
      type: String,
      default: '',
    },
    hasRights: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.defaultImagePlaceholder = `${GT.cdn}/imgs/fileTypes/file.svg`;
    this.imgStub = imgStub;
    this.locales = __;

    return {
      show: false,
      taskNameString: null,
    };
  },
  watch: {
    taskName: {
      handler(newValue, oldValue) {
        if (newValue === undefined) {
          this.taskNameString = oldValue;
        } else {
          this.taskNameString = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapGetters({
      checkResourceExist: 'resourcesModel/getResourceByUserId',
    }),
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped lang="less">

.accordion-enter-active, .accordion-leave-active {
  transition: grid-template-rows .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.accordion-enter, .accordion-leave-to {
  grid-template-rows: 0fr;
}

.accordion-enter-to, .accordion-leave {
  grid-template-rows: 1fr;
}

</style>
<style module='$' src='./less/attachments.less' />
