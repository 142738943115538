<template>
  <div class="settings-content settings-content-integrations">
    <div class="settings-section">
      <div class="main-title">
        {{ locales('integrations_section_title') }}
      </div>
      <div class="text">
        {{ locales('integrations_section_feedback') }}
        <span
          class="link"
          @click="createFeedback"
        >
          {{ locales('integrations_create') }}
        </span>
      </div>
      <inline-notification
        v-if="errorConnectMsTeams"
        class="notify-block"
        type="warning"
        :text="errorConnectMsTeams"
      />
      <div :class="['integration-list', errorConnectMsTeams ? 'integration-list-width-box' : '']">
        <div
          v-for="(config, index) in integrationItems"
          :key="index"
          :class="['integration-item', errorConnectMsTeams && config.type==='msteams' ? 'error-integration' : '', errorConnectMsTeams ? 'integration-item-width-box' : '']"
        >
          <div class="item-head">
            <div class="wrapper">
              <webix-ui :config="icons[config.type]" />
              <div
                v-if="config.type === 'jira' && !pricingAccessToJira"
                class="pricing-title-container"
              >
                {{ locales(planTitle) }}
              </div>
            </div>
          </div>
          <div
            :class="['text']"
            v-html="config.text"
          />
          <div class="item-footer">
            <div
              v-if="config.type === 'jira' && !pricingAccessToJira && isOwner"
              class="main-btn upgrade-btn"
              @click="upgradePlanClick()"
            >
              {{ locales('upgrade_button_label') }}
            </div>
            <div
              v-else-if="config.showBtn"
              :class="['main-btn', config.cssActive ? 'main-btn--active' : '']"
              @click="config.handler(config)"
            >
              {{ config.btnText }}
            </div>
            <div
              v-if="config.handlerMsTeams"
              :class="['main-btn', config.cssMsTeamsActive ? 'main-btn gray help' : '']"
              @click="config.handlerMsTeams(config)"
            >
              {{ config.btnMsTeamsText }}
            </div>

            <div
              v-if="config.buttonHelp"
              class="main-btn gray help"
              @click="integrationHelpClick(config.type)"
            >
              {{ locales('integrations_help_btn') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="accessToApiAndWebhooks">
      <delimiter />
      <div class="settings-section">
        <div class="main-title">
          {{ locales('settings_api_keys_title') }}
        </div>
        <div
          class="text"
          v-html="locales('settings_api_keys_text')"
        />
        <button
          v-if="accessToApiAndWebhooks"
          id="gp_autotest_api_key_create_button"
          class="main-btn"
          @click="apiCreate"
        >
          {{ locales('settings_add_api_key_button') }}
        </button>
        <div
          v-if="apiKeys.length"
          class="api-list list-container"
        >
          <div class="label">
            {{ locales('settings_api_keys_list_title') }}
          </div>
          <div class="list">
            <div
              v-for="item in apiKeys"
              :key="item.id"
              :class="[`list-item api-item-${item.id}`, 'gp_autotest_api_keys_list_item']"
            >
              <div :class="['text', isCopiedApi === item.id ? 'copied' : '']">
                {{ item.XAPIKey }}
              </div>
              <div class="btns">
                <div
                  class="copy-btn"
                  @click="copyApiKey(item.XAPIKey, item.id)"
                >
                  {{ locales('settings_integrations_item_copy') }}
                </div>
                <div
                  class="delete-btn"
                  @click="deleteApiKey(item.id)"
                >
                  <webix-ui
                    :config="icons.delete"
                    class="delete-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!accessToApiAndWebhooks"
          class="not-allowed-description"
          v-html="notAllowedDescription"
        />
      </div>
      <delimiter />
      <div class="settings-section">
        <div class="main-title">
          {{ locales('settings_webhooks_title') }}
        </div>
        <div
          class="text"
          v-html="locales('settings_webhooks_text')"
        />
        <input-with-button
          v-if="accessToApiAndWebhooks"
          :input-placeholder="locales('settings_webhook_form_input_placeholder')"
          :button-text="locales('settings_webhook_form_input_btn')"
          :get-value="sendToWebhooksModel"
          :is-error="isWebhookError"
          :on-input="onWebhookInput"
          :error-text="locales('settings_webhook_form_input_error')"
        />
        <div
          v-if="webhooks.length"
          class="list-container"
        >
          <div class="label">
            {{ locales('settings_webhooks_list_title') }}
          </div>
          <div class="list">
            <div
              v-for="item in webhooks"
              :key="item.id"
              class="list-item"
            >
              <div class="text">
                {{ item.url }}
              </div>
              <div class="btns">
                <div
                  class="delete-btn"
                  @click="deleteWebhookUrl(item.id)"
                >
                  <webix-ui
                    :config="icons.delete"
                    class="delete-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!accessToApiAndWebhooks"
          class="not-allowed-description"
          v-html="notAllowedDescription"
        />
      </div>
    </div>

    <core-confirm-popup
      v-if="isConfirmPopup"
      :show="isConfirmPopup"
      :close-icon="false"
      :text="confirmPopupMessage"
      :ok-button-title="locales('common_ok')"
      :cancel-button="false"
      :popup-width="440"
      :ok-button="{
        title : locales('common_ok'),
        type : 'primary'
      }"
      @onClickOk="toggleConfirmDelete(null)"
    />
    <google-integration-popup
      :show="googlePopup"
      @linkCreated="redirectToGooglePage"
      @closePopup="googlePopup = false"
    />
    <outlook-integration-popup
      :show="outlookPopup"
      @linkCreated="redirectToOutlookPage"
      @closePopup="outlookPopup = false"
    />
  </div>
</template>

<script>
import app from '../../../app';
import integrationModel from '../../../models/integrations/integrations';
import apiKeysModel from '../../../models/settings/apiKeys';
import webhooksModel from '../../../models/settings/webhooks';
import jiraModel from '../../../models/jira';
import projectsModel from '../../../models/projects';
import plansModel from '../../../models/plans';
import jiraIntegration from '../../integration/jira/index';
import pricingHelper, { getPlansObj } from '../../../helpers/pricingHelper';
import routerHelper from '../../../helpers/router';

// webix
import upgradePopup from '../../payment/upgradePopup';
import showAuthWindow from '../include/integrations/showAuthWindow';
import failedPopups from '../include/integrations/popups';
// components
import inputWithButton from '../../common/VueComponents/inputWithButton/inputWithButton.vue';
import InlineNotification from '$$vueCmp/nofitication/inline/InlineNotification.vue';

// icons
import jiraIcon from '../../../svg/integration/settings/jira.svg';
import slackIcon from '../../../svg/integration/settings/slack_big.svg';
import zapierIcon from '../../../svg/integration/settings/zapier.svg';
import msteamsIcon from '../../../svg/integration/settings/msteams_big.svg';
import googleIcon from '../../../svg/integration/settings/googleCalendar.svg';
import outlookIcon from '../../../svg/integration/settings/outlookCalendar.svg';
import icDelete from '../../../svg/ic_basket_normal.svg';

import svgIconForVue from '../../common/svgIconForVue';
import Delimiter from '../include/delimiter.vue';
import rights from '../../../components/rights';
import integrationsModel from '../../../models/integrations/integrations';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import GoogleIntegrationPopup from '../../integration/googleIntegration/googleIntegrationPopup.vue';
import OutlookIntegrationPopup from '../../integration/outlookIntegration/outlookIntegrationPopup.vue';

function validateWebhooksUrl(value) {
  const patternDomain = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'); // domain
  const pattern = new RegExp('^https?://((?:\\d{1,3}\\.){3}\\d{1,3}|([^:/\\s]+))(?::(\\d+))?(?:/[^?#]*)?(?:\\?([^#]*))?(?:#(.*))?$'); // regexp domain port query hash ip4

  const isDomain = !!patternDomain.test(value);
  const result = !!pattern.test(value);

  return { isDomain, result };
}

export default {
  components: {
    OutlookIntegrationPopup,
    GoogleIntegrationPopup,
    CoreConfirmPopup,
    inputWithButton,
    Delimiter,
    InlineNotification,
  },
  data() {
    return {
      isConfirmPopup: false,
      confirmPopupMessage: '',
      locales: __,
      icons: {
        jira: svgIconForVue(jiraIcon, 93, 33),
        slack: svgIconForVue(slackIcon, 95, 24),
        zapier: svgIconForVue(zapierIcon, 98, 45),
        msteams: svgIconForVue(msteamsIcon, 153, 30),
        delete: svgIconForVue(icDelete, 36, 36),
        google: svgIconForVue(googleIcon, 162, 25),
        outlook: svgIconForVue(outlookIcon, 119, 24),
      },
      integrationItems: [],
      apiKeys: [],
      webhooks: [],
      isCopiedApi: null,
      isWebhookError: false,
      errorConnectMsTeams: false,
      googlePopup: false,
      outlookPopup: false,
      appOnIds: [],
    };
  },
  computed: {
    pricingAccessToJira() {
      return pricingHelper.checkPricingAccess('jira_cloud');
    },

    roleAccessToJira() {
      return rights.account.hasRight('integration_settings');
    },
    planTitle() {
      return `${pricingHelper.getPlans('jira_cloud')}_plan_locale`;
    },
    isOwner() {
      return rights.account.isOwner();
    },
    accessToApiAndWebhooks() {
      return rights.account.hasRight('api_settings');
    },
    notAllowedDescription() {
      const text = __('members_not_allowed_template', {
        ownerName: `${user.team.owner_firstName} ${user.team.owner_lastName}`,
        ownerEmail: user.team.owner_email,
      });

      return text;
    },
  },
  created() {
    this.init();
    this.initEvents();
  },

  beforeDestroy() {
    this.appOnIds.forEach(id => {
      app.off(id);
    });
  },

  methods: {
    initEvents() {
      const component = this;

      this.appOnIds.push(app.on('onAfterCollaboration', data => {
        if (data.event === 'ProjectDeleted' || data.event === 'ProjectArchived' || data.event === 'ResourceFromProjectsUnassigned') {
          const id = data.projects[0];

          Promise.all([
            this.$store.dispatch('integrationGoogle/removeAfterCollabaration', { id }),
            this.$store.dispatch('integrationOutlook/removeAfterCollabaration', { id }),
          ]).then(() => {
            component.init();
          });
        }
      }));
      if (component.$route.path === '/settings/integrations') {
        this.appOnIds.push(app.on('integration:updated', () => {
          component.init();
        }));

        this.appOnIds.push(app.on('apiKeys:updated', () => {
          component.init();
        }));

        this.appOnIds.push(app.on('webhooks:updated', () => {
          component.init();
        }));

        this.appOnIds.push(app.on('user:changedRole', () => {
          component.init();
        }));

        app.socket.on('integration:connected', data => {
          if (data.status === 'ok') {
            integrationModel.activateIntegrationById(data.integration_id, data.newUser);
            this.$store.commit('teamSetting/deleteSettingErrorsByCategoryAndName', {
              name: 'integrations',
              key: 'msTeamsReconnect',
            });

            component.init();
          } else if (data.type === 'msteams' && data.code === 'workspace_already_connected_to_other_team') {
            this.confirmPopupMessage = __('integration_msteams_workspace_already_used_text');
            this.isConfirmPopup = true;
          } else if (data.type === 'msteams' && data.code === 'workspace_use_current_account_connection') {
            this.confirmPopupMessage = __('integration_msteams_workspace_use_your_current_account');
            this.isConfirmPopup = true;
          } else {
            failedPopups.show(data);
          }
        });
      }
    },
    init() {
      userExtAnalytics.log('general_integrations_settings_open');
      this.apiKeys = apiKeysModel.getData();
      this.webhooks = webhooksModel.getData();

      const integrationsData = integrationModel.getData();

      const jira = {
        type: 'jira',
        btnText: __('integrations_connect_btn'),
        buttonConnect: () => !rights.account.hasRight('integration_settings'),
      };
      const google = {
        type: 'google',
        btnText: __('integrations_connect_btn'),
        // buttonConnect: () => !rights.account.hasRight('integration_settings'),
      };
      const outlook = {
        type: 'outlook',
        btnText: __('integrations_connect_btn'),
        // buttonConnect: () => !rights.account.hasRight('integration_settings'),
      };

      integrationsData.unshift(jira);
      integrationsData.push(google);
      integrationsData.push(outlook);

      const prepareIntergrationsItems = integrationsData.map(item => {
        let config = {
          id: item.id,
          type: item.type,
          beta: item.beta,
          isActive: item.isActive,
          soon: item.soon,
          settings: item.settings,
          showBtn: true,
          handler: this.openSettings,
          buttonHelp: true,
          text: __(`integrations_${item.type}_text`),
          btnText: item.btnText || __('integrations_settings_btn'),
          cssActive: true,
        };

        // --- Common logics
        if (item.type === 'jira' && rights.account.hasRight('integration_settings')) {
          config.handler = this.connectJira;
        }
        if (item.type === 'google') {
          config.handler = this.connectGoogle;
        }
        if (item.type === 'outlook') {
          config.handler = this.connectOutlook;
        }

        if (item.type === 'zapier') {
          config.showBtn = false;
        }

        if (!item.isActive && !item.soon && (item.type !== 'jira' && item.type !== 'google' && item.type !== 'outlook')) {
          if (!rights.account.hasRight('integration_settings')) {
            config.showBtn = false;
            config.text = __(`integrations_${item.type}_text_not_owner_connected`);
          } else {
            config.btnText = __('integrations_connect_btn');
            config.handler = this.connect;
          }
        }

        if (!item.isActive) {
          config.cssActive = false;
        }

        // --- Only for Slack and MSTeams
        if (item.type === 'slack' || item.type === 'msteams') {
          if (!item.isActive) {
            if (!rights.account.hasRight('integration_settings')) {
              config.showBtn = false;
              config.text = __(`integrations_${item.type}_text_not_owner_connected`);
            } else {
              config.handler = this.connect;
              config.btnText = __('integrations_connect_btn');
            }
          } else {
            config.showBtn = true;

            if (!rights.account.hasRight('integration_settings')) {
              if (!item.users.find(user => +user.user_id === +GT.user.id)) {
                config.handler = this.connectUserToTeam;
                config.text = __(`integrations_${item.type}_user_connect`);
                config.btnText = __('integrations_connect_btn');
                config.cssActive = false;
              } else {
                config.handler = this.disconnectUserFromTeam;
                config.text = __(`integrations_${item.type}_member_disconnect`);
                config.btnText = __('integrations_disconnect_btn');
                config.cssActive = true;
              }
            } else if (!item.users.find(user => +user.user_id === +GT.user.id)) {
              config.handler = this.connectUserToTeam;
              config.text = __(`integrations_${item.type}_user_connect`);
              config.btnText = __('integrations_connect_btn');
              config.cssActive = false;
            } else {
              config.handler = this.openSettings;
              config.text = __(`integrations_${item.type}_member_disconnect`);
              config.btnText = __('integrations_settings_btn');
              config.cssActive = true;
            }
          }

          if (item.type === 'msteams') {
            config = { ...config, ...this.checkMsTeamsPolandSubscription(item) };
          }
        }

        // --- Only for Jira
        if (item.type === 'jira') {
          config.showBtn = true;

          if (plansModel.isActiveTeamAccount() && rights.account.hasRight('integration_settings') && pricingHelper.checkPricingAccess('jira_cloud')) {
            if (user.team.is_jira) {
              config.handler = this.reconnectJira;
              config.btnText = __('integration_reconnect');
              config.cssActive = true;
            }
          }
        }

        if (item.type === 'jira' && (!rights.account.hasRight('integration_settings') || !pricingHelper.checkPricingAccess('jira_cloud'))) {
          config.buttonHelp = true;
          config.showBtn = false;
        }
        if (item.type === 'google') { // && rights.account.hasRight('integration_settings')
          const googleLinks = this.$store.getters['integrationGoogle/googleLinks'];

          if (googleLinks.length) {
            config.handler = this.openSettings;
            config.btnText = __('integrations_settings_btn');
            config.cssActive = true;
          } else {

          }
        }
        if (item.type === 'outlook') { //  && rights.account.hasRight('integration_settings')
          const outlookLinks = this.$store.getters['integrationOutlook/outlookLinks'];

          if (outlookLinks.length) {
            config.handler = this.openSettings;
            config.btnText = __('integrations_settings_btn');
            config.cssActive = true;
          } else {

          }
        }

        return config;
      });

      this.integrationItems = prepareIntergrationsItems;
    },
    toggleConfirmDelete(id) {
      this.isConfirmPopup = !this.isConfirmPopup;
    },
    checkMsTeamsPolandSubscription(item) {
      const userId = +GT.user.id;
      const teamIsReconnected = !item.needReconnect;
      let config = { }; // handler: false, showBtn: false

      if (!item.isActive) {
        // its new team. We don't need any check
        return {};
      }

      item.users.forEach(user => {
        if (teamIsReconnected) {
          if (+user.user_id === userId && !user.reconnected) { //  && rights.account.hasRight('integration_settings')
            // ОТЛЮЧИТЬ себя
            // it was reconnect of the team. This user haven't been reconnect yet
            config.handlerMsTeams = this.disconnectUserFromTeam;
            config.handler = this.reconnectMsTeams;
            config.btnMsTeamsText = __('integrations_disconnect_btn');
            config.cssMsTeamsActive = true;
            config.btnText = __('reconnect');
            config.cssActive = false;
            config.showBtn = true;
            config.buttonHelp = false;
            this.errorConnectMsTeams = __('integrations_reconnect_message_only_connection');
          } else if (+user.user_id === userId && user.reconnected) {
            config = { };
            this.errorConnectMsTeams = false;
          }
        } else if (!teamIsReconnected) {
          if (rights.account.hasRight('integration_settings')) {
            // it wasn't reconnect of the team. This user haven't been reconnect yet
            config.handlerMsTeams = this.removeMsTeamIntegration;
            config.handler = this.reconnectMsTeams;
            config.btnMsTeamsText = __('integrations_disconnect_btn');
            config.cssMsTeamsActive = true;
            config.btnText = __('reconnect');
            config.cssActive = false;
            config.showBtn = true;
            config.buttonHelp = false;
            this.errorConnectMsTeams = __('integrations_reconnect_message_team_connection');
          } else if (!rights.account.hasRight('integration_settings')) {
            // it wasn't reconnect of the team. This user doesn't have permission
            // config.handlerMsTeams = this.removeMsTeamIntegration;
            // config.handler = this.reconnectMsTeams;
            // config.btnMsTeamsText = __('integrations_disconnect_btn');
            // config.cssMsTeamsActive = true;
            // config.btnText = __('reconnect');
            config.cssActive = false;
            config.showBtn = false;
            config.buttonHelp = false;
            this.errorConnectMsTeams = __('integrations_reconnect_message_disabled');
          }
        }
      });

      return config;
    },

    reconnectMsTeams(data) {
      showAuthWindow({ isOwner: true, type: data.type, reconnect_msteams: true });
    },
    createFeedback() {
      app.trigger('messageUsForm:show', 'integrations-feedback');
    },
    openSettings(data) {
      const type = data.type;

      routerHelper.changeRoute(`/integration/${type}`);
    },
    connect(data) {
      showAuthWindow({ isOwner: true, type: data.type });
    },
    connectUserToTeam(data) {
      showAuthWindow({ isOwner: false, type: data.type }, data.settings.integration_account_id);
    },
    async disconnectUserFromTeam(data) {
      userExtAnalytics.log('integration_disconnect', { isOwner: false, type: data.type });
      const integrationTeamId = data.settings && data.settings.id ? data.settings.id : '';
      const integrationId = data.id;

      const callback = () => integrationModel.disconnectIntegrationUser(data.type, +integrationId, +integrationTeamId)
        .then(() => {
          this.errorConnectMsTeams = false;
          this.$store.commit('teamSetting/deleteSettingErrorsByCategoryAndName', {
            name: 'integrations',
            key: 'msTeamsReconnect',
          });
          this.init();
        });

      this.$store.dispatch('globalPopup/show', {
        message: __(`integration_disconnect_member_${data.type}`),
        ok: __('integration_disconnect_member_ok'),
        cancel: __('integration_disconnect_member_cancel'),
        type: 'confirm',
        buttonWidth: '90px',
        popupType: 'confirm',
        confirm: callback,
      });
    },
    connectJira() {
      jiraIntegration.init();
    },
    connectGoogle() {
      // jiraIntegration.init();
      this.googlePopup = true;
    },
    connectOutlook() {
      // jiraIntegration.init();
      this.outlookPopup = true;
    },
    reconnectJira() {
      const callback = () => jiraModel.jiraReconnect()
        .then(() => {
          user.team.is_jira = null;
          projectsModel.removeLinkWithJira();
          jiraIntegration.init();
          app.trigger('integration:updated');
          app.trigger('sideBar:update:favorite');
        });

      this.$store.dispatch('globalPopup/show', {
        message: __('jira_recconect_popup_text'),
        ok: __('jira_recconect_popup_ok'),
        cancel: __('jira_recconect_popup_cancel'),
        type: 'confirm',
        buttonWidth: '90px',
        popupType: 'confirm',
        confirm: callback,
      });
    },
    upgradeJira() {
      if (plansModel.isTrial()) {
        this.$store.commit('paymentPlans/show');
      } else {
        upgradePopup.handlers.showUpgradePopup();
      }
    },
    upgradePlanClick() {
      if (!pricingHelper.checkPricingAccess('jira_cloud') && !plansModel.isOverSubscription()) {
        const newPlanObj = getPlansObj('jira_cloud');
        const userPlanId = user.accountPlanData.payment_plans_id;
        const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

        this.$store.dispatch('globalPopup/show', {
          type: 'upgradePlan',
          title: 'upgrade_plan_popup_title',
          currentPlan: userPlan,
          newPlan: newPlanObj,
          isAnnual: user.subscription.is_annual,
        });
      } else if (plansModel.isOverSubscription()) {
        this.$store.dispatch('globalPopup/show', {
          type: 'paymentPopup',
        });
      }
    },
    integrationHelpClick(type) {
      if (type === 'zapier') {
        app.trigger('messageUsForm:show', 'zapier-feedack');
      } else {
        window.open(__(`${type}_integration_blog_link`), '_blank');
      }
    },
    apiCreate() {
      apiKeysModel.create().then(() => {
        this.init();
      });
    },
    removeMsTeamIntegration() {
      webix.confirm({
        text: __('integration_remove_integration_msteams'),
        ok: __('integration_remove_integration_ok'),
        cancel: __('integration_remove_integration_cancel'),
        callback: answer => {
          if (answer) {
            userExtAnalytics.log('integration_disconnect', { isOwner: true, type: 'msteams' });
            userExtAnalytics.log('intergraration_MSTeams_owner_disconnect');

            this.errorConnectMsTeams = false;
            this.$store.commit('teamSetting/deleteSettingErrorsByCategoryAndName', {
              name: 'integrations',
              key: 'msTeamsReconnect',
            });

            integrationsModel.removeIntegrationFromTeam('msteams')
              .catch(err => console.error(err));
          }
        },
      });
    },
    copyApiKey(key, id) {
      this.isCopiedApi = id;
      this.init();
      const el = document.createElement('textarea');

      el.value = key;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setTimeout(() => {
        this.isCopiedApi = null;
        this.init();
      }, 2000);
      webix.message({ type: 'info', text: __('settings_integrations_item_copied'), expire: 10000 });
    },
    deleteApiKey(id) {
      const callback = id => {
        apiKeysModel.delete(id).then(() => {
          this.init();
        }).then(() => this.$store.dispatch('globalPopup/close'));
      };

      this.$store.dispatch('globalPopup/show', {
        type: 'confirm',
        ok: __('common_yes'),
        cancel: __('common_no'),
        message: __('settings_integrations_api_keys_delete'),
        confirm: callback,
        popupWidth: 420,
        buttonWidth: '90px',
        popupType: 'confirm',
        data: id,
      });
    },
    sendToWebhooksModel(val) {
      if (val === '') {
        this.isWebhookError = true;
      }

      setTimeout(() => {
        const res = validateWebhooksUrl(val);

        if (res.isDomain) {
          this.isWebhookError = !res.result;
        } else {
          this.isWebhookError = true;

          return false;
        }
        if (res.result) {
          this.$store.dispatch('profileSettings/sendToWebhooksModel', val);
        }
      }, 1);

      // validateWebhooksUrl(val).then(res => {
      //   if (res.isDomain) {
      //     this.isWebhookError = !res.result;
      //   } else {
      //     this.isWebhookError = true;
      //
      //     return false;
      //   }
      //   if (res.result) {
      //     this.$store.dispatch('profileSettings/sendToWebhooksModel', val);
      //   }
      // });
      // const res = ;
    },
    deleteWebhookUrl(id) {
      const callback = id => {
        webhooksModel.delete(id).then(() => {
          this.init();
        }).then(() => this.$store.dispatch('globalPopup/close'));
      };

      this.$store.dispatch('globalPopup/show', {
        type: 'confirm',
        ok: __('common_yes'),
        cancel: __('common_no'),
        message: __('settings_integrations_webhook_delete'),
        data: id,
        buttonWidth: '90px',
        popupType: 'confirm',
        confirm: callback,
      });
    },
    onWebhookInput() {
      this.isWebhookError = false;
    },
    redirectToGooglePage() {
      this.$store.commit('integrationGoogle/setFirstLink', true);
      routerHelper.changeRoute('/integration/google');
    },
    redirectToOutlookPage() {
      this.$store.commit('integrationOutlook/setFirstLink', true);
      routerHelper.changeRoute('/integration/outlook');
    },
  },
};
</script>

<style scoped src="../less/profileSettings.less"></style>
<style lang='less'>
	.icon {
		//margin-bottom: 10px;
		.webix_view {
			.webix_template {
				display: flex;
				align-items: center;
		  }
		}
	}

  .error-ms-teams-block {
    border-radius: 4px;
    background: #F8D7D7;
    padding: 8px 13px;
  }

  .notify-block {
    margin-bottom: 12px;
  }

  .not-allowed-description {
    font-size: 12px;
    color: #424242;

    a {
      color: #257ECC;
      font-weight: lighter;
    }
  }
</style>
