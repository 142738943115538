import { actionTypes } from './dataProvider';
import massChangeModel from '../gantt/modules/massChange';
import projectsModel from '../../models/projects';
import store from '../../store/main';

export default class BulkActions {
  constructor() {
    this._payload = [];
  }

  hasChanges() {
    return !!this._payload.length;
  }

  cleare() {
    this._payload.splice(0);
  }

  async applyChanges() {
    const tasksId = massChangeModel.getSelectedList();
    const storeTasksIds = tasksId.filter(id => store.getters['tasksModel/tasksByIds'][id]);

    if (!storeTasksIds.length || !this._payload.length) { return; }

    this._addChangesToTasks(storeTasksIds);
    const ganttData = projectsModel.getActiveGanttId();

    await massChangeModel.executeActions(this._payload, ganttData);

    this.cleare();
  }

  _addChangesToTasks(tasksId) {
    const projectId = projectsModel.getActiveGanttId();

    this._payload.forEach(opt => {
      if (opt.actionType !== actionTypes.CUSTOM_COL) {
        opt.actionPayload.tasks = [...tasksId];
        opt.actionPayload.projectId = projectId;

        return;
      }

      opt.actionPayload.forEach(cust => {
        cust.actionPayload.tasks = [...tasksId];
        cust.actionPayload.projectId = projectId;
      });
    });
  }

  onChange(opt, selected) {
    if (opt.actionType !== actionTypes.CUSTOM_COL) {
      this._onChangeDefault(opt, selected);

      return;
    }

    this._onChangeCustom(opt, selected);
  }

  _isEmpty(val) {
    return !val || (Array.isArray(val) && !val.length);
  }

  _onChangeDefault(opt, selected) {
    if (this._isEmpty(selected)) {
      this._deleteByAction(opt.actionType);

      return;
    }

    const action = this._getAction(opt.actionType);
    let val = selected.map(i => i.id).join(',');

    if (opt.actionType === actionTypes.COLOR) {
      val = selected[0].hex;
    }

    action.actionPayload = {
      tasks: [],
      value: val,
    };
  }

  _onChangeCustom(opt, selected) {
    if (this._isEmpty(selected)) {
      this._deleteByActionCustom(opt.id);

      return;
    }

    const customAction = this._getActionCustom(opt.id);

    customAction.actionPayload.value = this._createtPayloadCustomValue(selected, opt.typeId, opt.isOptions);
    customAction.actionPayload.isOptions = opt.isOptions;
  }

  _createtPayloadCustomValue(val, typeId, isOption) {
    if (typeId === 4) { // for date
      return webix.Date.dateToStr('%Y-%m-%d %H:%i:%s')(val);
    }

    if (Array.isArray(val)) {
      const res = val.map(i => ((i && typeof i === 'object') ? i.id : i));

      return isOption ? res : res[0];
    }

    return (val && typeof i === 'object') ? val.value : val;
  }

  _getAction(actionType) {
    let act = this._payload.find(i => i.actionType === actionType);

    if (!act) {
      act = {
        actionType,
        actionPayload: [],
      };
      this._payload.push(act);
    }

    return act;
  }

  _getActionCustom(actionId) {
    const custom = this._getAction(actionTypes.CUSTOM_COL);
    let act = custom.actionPayload.find(i => i.actionId === actionId);

    if (!act) {
      act = {
        actionId,
        actionPayload: {},
      };
      custom.actionPayload.push(act);
    }

    return act;
  }

  _deleteByAction(actionType) {
    const index = this._payload.findIndex(i => i.actionType === actionType);

    index >= 0 && this._payload.splice(index, 1);
  }

  _deleteByActionCustom(actionId) {
    const custom = this._payload.find(i => i.actionType === actionTypes.CUSTOM_COL);

    if (!custom) return;

    const index = custom.actionPayload.findIndex(i => i.actionId === actionId);

    index >= 0 && custom.actionPayload.splice(index, 1);

    if (!custom.actionPayload.length) {
      const index = this._payload.findIndex(i => i.actionType === actionTypes.CUSTOM_COL);

      this._payload.splice(index, 1);
    }
  }
}
