/* eslint-disable */

import app from "./../../../app";
import _ from "./../../../libs/lodash";
import Vue from "vue";

import ganttViewModel from "./../../../models/ganttViewModel";
import historyModel from "./../../../models/history";
import gridCustomButtons from "./gridCustomButtons";
import globalStore from '../../../store/main';
import projectsModel from '../../../models/projects';

import constants from "./../../../helpers/constants";
import colorHelper from './../../../helpers/color';
import statusHelper from "./../../../helpers/status";
import contextMenu from './contextMenu';

import svgPaste from "./../../../svg/webix_material/paste.svg";
import svgPasteBelow from "./../../../svg/webix_material/paste_below.svg";
import svgPasteAbove from "./../../../svg/webix_material/paste_above.svg";
import svgSpecialPasteInfo from "./../../../svg/special-paste/info.svg";
import svgLockIcon from "./../../../svg/lock.svg";
import ic_dots from "./../../../svg/ic_settings_dots_hor.svg";
import massChangeModel from "./massChange";
import { innerHandlers as taskSettingsHandlers } from "../../taskSettings/main";
import pricingHelper from "../../../helpers/pricingHelper";
import commentsModel from '../../../models/comments2';
import toastMessage from '../toastMessage/message.vue';
import customHelper from '../../../helpers/custom';

const __ = window.__;
const customColumnsFeatureKey = 'custom_fields';

var errorCopyPasteHandler = function (id, text) {
  let ids =  Array.isArray(id) ? [...id] : [id];
  const idsToNumber = ids.map(id => +id);
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];

  var copyTasks = _.map(
    globalStore.getters['tasksModel/getAllChildByTask'](
      +copiedTask.ganttId,
      +copiedTask.taskId
    ), 'id');

  copyTasks.push(+copiedTask.taskId);

  if (copyTasks.find(tid => idsToNumber.includes(tid))) {
    innerObject.settings.copyPastError =  text
  }
};

// gantt.attachEvent("onAfterTaskDelete", function (id, taskData) {
//   if (taskData.hasComments) {
//     commentsModel.getNewCommentsForProjects()
//       .then(function (data) {
//         var unreadComments = _.reduce(data, function (unreadCommentsCount, comment) {
//           return +comment.read === 1 ? unreadCommentsCount : ++unreadCommentsCount;
//         }, 0);

//         app.trigger('resetCommentsCount', {
//           count: unreadComments
//         });
//       });
//   }
// });

gantt.attachEvent("onBeforeRowDragEnd", function (sid, parent, tindex) {
  _.delay(function () {
    innerObject.handlers.addPopup.helpers.updateTasksAfterIndentOutdent(sid);
  });

  return true;
});

gantt.attachEvent("onAfterTaskRowHover", _.debounce(function (task_id) {
  var element = document.querySelector(".column-custom-options:not([task_id='" + task_id + "'])");

  if (!element) {
    return;
  }

  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }

  element.parentNode.removeChild(element);
}, 50));

gantt.attachEvent("onBeforeTaskMove", function (id, parent, tindex) {
  if (gantt.isTaskExists(parent) && gantt.getTask(parent).type === gantt.config.types.button) {
    return false;
  }

  return true;
});

gantt.attachEvent("onAfterTaskUpdate", function (id, taskData) {
  if (taskData.muteNotify) {
    return true;
  }
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  copiedTask && errorCopyPasteHandler(id, __('can_not_copy_changes'));
});

gantt.attachEvent("taskEditor:task:changed", function (taskData) {
  if (taskData?.muteNotify) {
    return true;
  }
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  copiedTask && errorCopyPasteHandler(taskData.id, __('can_not_copy_changes'));
});

gantt.attachEvent("showSpecialPasteOptions", function (specialPasteOptions) {
  specialPasteOptions.parentNode.parentNode.style.display = 'inline-block';
});

app.on('taskSettings:delete', function (id) {
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  copiedTask && errorCopyPasteHandler(id, __('can_not_copy_delete'));
});

// app.on('tasks:model:updateTask', function (taskId, taskData) {  // trigger when opening or closing a task in the grid
//   const copiedTask = globalStore.getters['copyPaste/copiedTask'];
//   copiedTask && errorCopyPasteHandler(taskId, __('can_not_copy_changes'));
// })

app.on('tasks:model:updateTasksAfterMove', function (taskIds) {
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  copiedTask && errorCopyPasteHandler(taskIds, __('can_not_copy_changes'));
})

app.on('onAfterCollaboration', function (e) {
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  if(
  copiedTask
  && ['TaskUpdated', 'TaskCustomFieldUpdated', 'TaskResourceUnassigned', 'TaskResourceAssigned', 'ProjectDeleted', 'ResourcesOnProjectsConverted'].includes(e.event)
  ) {
  if(!e.projects.includes(copiedTask.ganttId)) return;

  errorCopyPasteHandler(e.tasks, __('can_not_copy_changes'));
  }
});

app.on('project:del', function (projectData) {
  const copiedTask = globalStore.getters['copyPaste/copiedTask'];
  if (copiedTask && copiedTask.ganttId === projectData.gantt_id) {
    globalStore.commit('copyPaste/resetStore');
  }
});

var innerObject = {
  views: {
    addMenuGanttPopup: {
      id: "addMenuGantt"
    },
    addMenuList: {
      id: "addMenuList"
    },
    pasteTaskPopup: {
      id: "pasteTaskPopup"
    },
    specialPasteTaskPopup: {
      id: "specialPasteTaskPopup"
    },
    pasteTaskMenuList: {
      id: "pasteTaskMenuList"
    },
    specialPasteTaskMenuList: {
      id: "specialPasteTaskMenuList"
    },
    specialPasteDataTable: {
      id: "specialPasteDataTable"
    },
    taskColorPopup: {
      id: "gridColorboardPopup",
    },
    taskColorBoard: {
      view: "colorboardCst",
      id: "gridColorboard",
      css: "task-settings-color-board",
      width: 180,
      height: 120,
      palette: constants.PALETTE_CONFIG.palette.map(item => {
        return item.map(cell => cell.color)
      }),
      template: function (obj) {
        return '<div class="color-bar" style="background: ' + obj.val + ';"><div class="color-bar-inside-border"></div></div>';
      },
    }
  },
  init: {
    beforeInit: function (tariffTemplate, checkOutdent) {
    },
    run: function (enableModule) {
      innerObject.settings.moduleEnable = enableModule;
      innerObject.settings.columnNumber = 0;
      innerObject.settings.isShow = false;
      innerObject.init.initActionGrid();
    },
    afterInit: function (deleteResourcesFromTask) {
      innerObject.handlersFromDependencyModule.deleteResourcesFromTask = deleteResourcesFromTask;
    },
    initActionGrid: function () {
      var columnsLength = innerObject.settings.columnNumber || gantt.config.columns.length;
      var ganttIDs = ganttViewModel.getGanttIDs();
      //var userResource = resourceModel.getResourceOnProjectByUserId(user.id, ganttIDs[0]);

      gantt.config.columns.push({
        name: "buttons",
        align: "left",
        label: `<div class='gantt_header_custom_grid tooltip-gantt ' data-key=${_("gantt_grid_burger_tooltip_text")}>
                <span class='gantt_header_custom_grid_icon color="#B2B2B2"'>${gantt._getSvgIcon('plus-1', 'regular', 24)}</span></div>`, //__("gantt_actions")
        task_types: 36,//["project", "task", "milestone"],
        width: 50,
        min_width: 50,
        max_width: 50,
        hide: false,
        resize: false,
        module: "actionButtons",
        template: function (item) {
          const isMultiview = gantt.config.multiview;
          const totalEstimateChilds = item.$level === 0 && gantt.getChildren(item.id).length <= 1

          if(item.$level === 0 && !gantt.config.multiview){
            optionsTemplate = "<div class='column-options'>"
              + "<div class='column-options-cog icon-dots' onclick='gantt.showContextMenu(this)' data-task-id='" + item.id + "'>"
              + gantt._getSvgIcon('more-1', 'regular', 24)
              + "</div>"
              + "</div>";
          }

          if ( (item.$level === 0 &&  ( gantt.config.multiview || globalStore.getters['headerView/isActiveFilter'] ) )
                || item.$virtual
                || item.type === gantt.config.types.button
              ) {
            return "";
          }
          const specialPasteIconId = globalStore.getters['copyPaste/specialPasteIconId'];
          var height = (gantt.config.original_row_height - 2 - parseInt(gantt.config.original_row_height / 10, 10)) + 'px';
          var heightContainer = parseInt(gantt.config.original_row_height, 10) - 3 + "px";
          var specialPasteTemplate = "";
          var optionsTemplate = "";

          if (!massChangeModel.isEnabled()) {
            specialPasteTemplate = "<div style='height:  " + heightContainer + ";' class='column-options special-paste-options " + (item.id === specialPasteIconId ? '' : 'hidden') +
              " tooltip-gantt'  data-key='task_special_paste_tooltip' data-position='top'>" +
              "<div class='column-options-cog special-paste-icon' style='height: " + height + ";'>" + gantt._getSvgIcon('paste', 'regular', 24) + "</div>" +
              "</div>";
              
            optionsTemplate = "<div class='column-options " + (item.id !== specialPasteIconId ? '' : 'hidden') + "'>" +
              "<div class='column-options-cog icon-dots' onclick='gantt.showContextMenu(this)' data-task-id='" + item.id + "'>" + gantt._getSvgIcon('more-1', 'regular', 24) + "</div>" +
              // "<div class='column-options-cog' onmouseenter='gantt.showTaskOptions(this)'>" + svgTaskSettings + "</div>" +
              "</div>";
          } /*else if (userResource) {
            var resourcesData = resourceModel.getResourcesByTaskIdAndGanttId(item.id, item.gantt_id);
            var isAssignedUser = !!_.find(resourcesData, function (resource) {
              return resource.id === +userResource.id;
            });

            if (resourcesData && isAssignedUser && !massChangeModel.isEnabled()) {
              optionsTemplate = "<div class='column-options'>" +
                "<div class='column-options-cog icon-dots' data-task-id='" + item.id + "'>" +
                gantt._getSvgIcon('more-1', 'regular', 24) +
                "</div>" +
                "</div>";
            } else if (!massChangeModel.isEnabled()) {
              optionsTemplate = "" +
                "<div class='column-options'>" +
                "<div class='column-options-lock tooltip-gantt' data-key='" + _("column-options-lock") + "'>" + svgLockIcon + "</div>" +
                "</div>";
            }
          }*/

          if (totalEstimateChilds) {
            optionsTemplate = "";
          }

          return specialPasteTemplate + optionsTemplate;
        },
        onClick: {
          details_button: function (id, data) {
            innerObject.handlers.duplicateRows(data);
          },
        },
      });

      gantt.clickGridButton = innerObject.handlers.customButtonClick;
      innerObject.settings.columnNumber = columnsLength;
      innerObject.settings.isShow = true;

      innerObject.settings.columnNumber = columnsLength;
    }
  },
  handlersFromDependencyModule: {
    //this all method from dependency modules - add when init module
  },
  handlers: {
    customButtonClick: function (node) {
      var taskId = node.closest(".column-custom-options").getAttribute('task_id');
      var action = node.getAttribute('action');

      switch (action) {
        case "copy_task":
          innerObject.handlers.copyPaste.fullCopyAction(taskId);
          break;
        case "paste_task":
          innerObject.handlers.copyPaste.fullPasteAction(taskId, node);
          break;
        case "edit_task":
          this.callEvent("onBeforeLightbox", [taskId]);
          break;
        case "add_task":
          _.delay(function () {
            innerObject.handlers.show(gantt.getTask(taskId), node);
          });

          break;
        case "change_color":
          innerObject.settings.colorPickerTaskId = taskId;
          innerObject.handlers.showColorboard(node);
          break;
        case "delete_task":
          innerObject.handlers.removeTaskById(taskId);
          break;
      }
    },
    hidePopup: function (actionId) {
      $$(innerObject.views.addMenuGanttPopup.id).hide();
    },
    clickItem: function (id) {
      innerObject.handlers.hidePopup(id);

      switch (id) {
        case 'add_subtask':
          innerObject.handlers.addPopup.subTask(innerObject.settings.currTaskId);
          break;
        case 'add_sibling_task':
          innerObject.handlers.addPopup.sublingTask(innerObject.settings.currTaskId);
          break;
        case 'add_child_milestone':
          innerObject.handlers.addPopup.subMilestone(innerObject.settings.currTaskId);
          break;
        case 'add_sibling_milestone':
          innerObject.handlers.addPopup.sublingMilestone(innerObject.settings.currTaskId);
          break;
        case 'move_indent':
          innerObject.handlers.addPopup.indentTask(innerObject.settings.currTaskId);
          break;
        case 'move_outdent':
          innerObject.handlers.addPopup.outdentTask(innerObject.settings.currTaskId);
          break;
      }
    },
    filterMenuItems: function ($$addMenuList, task) {
      const minLevelForTask = gantt.config.multiview ? 2 : 1;
      if (gantt.config.multiview && task.$level === 1) {
        const ids = new Set(($$addMenuList.serialize()).map(item => item.id));
        for (let item of ids) {
          if (!(item === 'go_to_project' || item === 'choose_project_color')) {
            $$addMenuList.hideItem(item);
          }
        }
      } else {
        if (globalStore.getters['headerView/isActiveFilter']) {
          $$addMenuList.hideItem('paste_task');
          $$addMenuList.hideItem('copy_task');
          $$addMenuList.hideItem('paste_task_disabled');

          $$addMenuList.hideItem('go_to_project');
          $$addMenuList.hideItem('choose_project_color');

          if (task.type === gantt.config.types.task) {
            $$addMenuList.hideItem('convert_in_task');
          }

          if (task.type === gantt.config.types.project) {
            $$addMenuList.hideItem('convert_in_task');
            $$addMenuList.hideItem('convert_in_milestone');

            if (task.$level < minLevelForTask) {
              $$addMenuList.define('yCount', 3);
              $$addMenuList.hideItem('add_sibling_task');
              $$addMenuList.hideItem('add_sibling_milestone');
              $$addMenuList.hideItem('move_outdent');
            }
          } else if (task.$level < minLevelForTask) {
            $$addMenuList.hideItem('move_outdent');
          }

          if (task.type === gantt.config.types.milestone) {
            $$addMenuList.hideItem('convert_in_milestone');
          }

          if (!gantt.getPrevSibling(task.id)) {
            $$addMenuList.hideItem('move_indent');
            $$addMenuList.define('yCount', $$addMenuList.config.yCount - 1);
          }

          $$addMenuList.resize();

          return;
        }

        $$addMenuList.showItem('add_sibling_task');
        $$addMenuList.showItem('copy_task');
        $$addMenuList.showItem('add_subtask');
        $$addMenuList.showItem('add_sibling_milestone');
        $$addMenuList.showItem('add_child_milestone');
        $$addMenuList.showItem('move_indent');
        $$addMenuList.showItem('move_outdent');

        $$addMenuList.hideItem('go_to_project');
        $$addMenuList.hideItem('choose_project_color');

        $$addMenuList.define('yCount', 6);

        if (gantt.config.multiview && task.$level === minLevelForTask) {
          $$addMenuList.hideItem('move_outdent');
        }

        if (gantt.config.multiview ) {
          $$addMenuList.hideItem('select_task_for_masschange');
        }

        if (!gantt.config.multiview && task.$level <= minLevelForTask) {
          $$addMenuList.hideItem('move_outdent');
        }

        if (task.$level === minLevelForTask) {
          const viewData = ganttViewModel.getActiveViewData();

          if (viewData.is_jira) {
            $$addMenuList.hideItem('move_outdent');
          }
        }

        if (task.$level > 12) {
          $$addMenuList.hideItem('move_indent');
          $$addMenuList.define('yCount', 5);
          $$addMenuList.hideItem('add_subtask');
          $$addMenuList.hideItem('add_child_milestone');
        }

        if (!gantt.getPrevSibling(task.id)) {
          $$addMenuList.hideItem('move_indent');
          $$addMenuList.define('yCount', $$addMenuList.config.yCount - 1);
        }

        if (task.type === gantt.config.types.task) {
          $$addMenuList.hideItem('convert_in_task');
        }

        if (task.type === gantt.config.types.project) {
          $$addMenuList.hideItem('convert_in_task');
          $$addMenuList.hideItem('convert_in_milestone');

          if (task.$level < minLevelForTask) {
            $$addMenuList.define('yCount', 3);
            $$addMenuList.hideItem('add_sibling_task');
            $$addMenuList.hideItem('add_sibling_milestone');
            $$addMenuList.hideItem('move_outdent');
          }
        } else if (task.$level < minLevelForTask) {
          $$addMenuList.hideItem('move_outdent');
        }

        if (task.type === gantt.config.types.milestone) {
          $$addMenuList.hideItem('add_sibling_task');
          $$addMenuList.hideItem('add_subtask');
          $$addMenuList.hideItem('add_child_milestone');
          $$addMenuList.hideItem('convert_in_milestone');
          $$addMenuList.define('yCount', 3);

          if (task.$level === 0 || task.$level === 1) {
            $$addMenuList.define('yCount', 2);
            $$addMenuList.hideItem('move_outdent');
          }
        }

        if ($$addMenuList.getItem('paste_task') && $$addMenuList.getItem('paste_task_disabled')) {
          if (globalStore.getters['copyPaste/hasCopiedTask']) {
            $$addMenuList.hideItem('paste_task_disabled');
            $$addMenuList.showItem('paste_task');
          } else {
            $$addMenuList.showItem('paste_task_disabled');
            $$addMenuList.disableItem('paste_task_disabled');
            $$addMenuList.hideItem('paste_task');
          }
        }

        if (gantt.config.isJira) {
          $$addMenuList.hideItem('copy_task');
          $$addMenuList.hideItem('paste_task');
          $$addMenuList.hideItem('paste_task_disabled');
          $$addMenuList.hideItem('select_task_for_masschange');
          if (task.$level === 1 && (gantt.config.gantt_id <= GT.lastProjectIdBeforeRootTask)) $$addMenuList.hideItem('delete_task');
        }

        $$addMenuList.resize();
      }

      if(task.$level === 0){
        $$addMenuList.showItem('copy_task');

        let hidenItems = ['edit_task', 'add_subtask', 'add_child_milestone', 'select_task_for_masschange', 'paste_task',
            'choose_task_color', 'delete_task', 'paste_task_disabled', 'separatorBefore', 'separatorAfter' ];

        gantt.config.isJira && hidenItems.push('copy_task');

        hidenItems.forEach( name => $$addMenuList.hideItem(name));
      }
    },
    show: function (task, node) {
      innerObject.settings.currTaskId = task.id;

      var $$addMenuList = $$(innerObject.views.addMenuList.id);

      innerObject.handlers.filterMenuItems($$addMenuList, task);

      $$(innerObject.views.addMenuGanttPopup.id).show(node, { y: 5 });
    },
    addPopup: {
      subTask: function (currentTaskId) {
        const taskData = gantt.getTask(currentTaskId);
        let taskId = 0;
        const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        if (gantt.calculateTaskLevel(currentTaskId) > 12) {
          //MYSQL: Cascading operations may not be nested more than 15 levels deep.
          return false;
        }

        gantt.callEvent('onChangeTaskToParent', [taskData]);

        if (!gantt.hasChild(currentTaskId)) {

          taskId = gantt.createTask({
            text: __("gantt_new_subtask"),
            type: gantt.config.types.task,
            duration: taskData.duration,
            estimation: 0,
            ignoreProgress: true,
            gantt_id: taskData.gantt_id,
            calendar_id: taskData.gantt_id,
            status: 1,
            created_at: new Date(),
            sortorder: gantt.getChildCount(currentTaskId),
            owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
            openEditAfterCreate: true
          },
            currentTaskId
          );

          taskData.type = "project";
          taskData.open = 1;
          taskData.progress = 0;

          if (!projectsModel.getProjectConfig(taskData.gantt_id)?.auto_budget) {
            taskData.total_price = 0;
            taskData.actual_cost = 0;
          }
          
          taskData.color = constants.DEFAULT_TYPE_COLORS.GROUP;
          if (taskId) {
            taskData.actionHash = gantt.getTask(taskId).actionHash;
            taskData.actionHashManual = true;
          }

          innerObject.handlersFromDependencyModule.deleteResourcesFromTask(taskData);
          gantt.callEvent("setIgnoreMove", [taskData.id]);

          gantt.callEvent("customButtons:add:to", [taskData]);
          gantt.updateTask(currentTaskId, taskData);

          if (taskData.status > constants.STATUS_DEFAULT) {
            taskData.status = constants.STATUS_DEFAULT;
            globalStore.dispatch('tasksModel/changeStatus', {
              statusValue: taskData.status,
              taskData,
              silenceMode: true
            });
          }

          delete taskData.actionHash;
          delete taskData.actionHashManual;

          return true;
        }

        var calcStartData = new Date();
        var projectStartData = taskData.start_date;
        var startDate;
        const projectCalendar = gantt.getCalendar(taskData.gantt_id);

        if (projectStartData.valueOf() > calcStartData.valueOf()) {
          startDate = projectStartData;
        } else {
          startDate = calcStartData;
        }

        if (!gantt.isWorkDay(startDate, projectCalendar)) {
          startDate = gantt.getClosestWorkTime({ date: startDate, dir: "future", unit: gantt.config.duration_unit, task: taskData });
        }

        var workRange = gantt.getDayHours(startDate);
        var dayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), workRange.firstHour);

        startDate = gantt.getClosestWorkTime({ date: dayStart, dir: "future" });

        if (projectStartData.valueOf() > startDate.valueOf()) {
          startDate = gantt.getClosestWorkTime({ date: gantt.date.add(startDate, 1, "day"), dir: "future" });
        }

        var oldDurationView = gantt.config.duration_view;
        var projectConfig = ganttViewModel.getProjectConfig();

        if (gantt.config.duration_view === "hour") {
          gantt.config.duration_view = projectConfig.durationData.mode;
        }

        var endDateData = gantt.calculateToDuration(startDate, 1);
        var duration = gantt.calculateDuration(startDate, endDateData.endDate, taskData);
        gantt.config.duration_view = oldDurationView;
        const sortOrderAndIndex = gridCustomButtons.helpers.calculateSortOrderForTask(taskData.id);

        const newTask = {
          text: __("gantt_new_subtask"),
          start_date: startDate,
          duration: duration,
          estimation: 0,
          type: gantt.config.types.task,
          created_at: new Date(),
          sortorder: sortOrderAndIndex.sortorder,
          gantt_id: taskData.gantt_id,
          owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
          calendar_id: taskData.gantt_id,
          openEditAfterCreate: true
        };

        taskId = gantt.createTask(newTask, currentTaskId, sortOrderAndIndex.index);

        taskData.open = 1;
        // taskData.sortorder = newTask.sortorder;

        gantt.updateTask(currentTaskId, taskData);
      },
      sublingTask: function (currentTaskId) {
        var task = gantt.getTask(currentTaskId);
        var calcStartData = gantt.date.hour_start(new Date());
        var projectStartData = gantt.getTask(task.parent).start_date;
        var projectConfig = ganttViewModel.getProjectConfig();
        var oldDurationView = gantt.config.duration_view;
        var calcEndDate = {};
        var newStarDate = {};
        var duration = {};
        var startDate;
        const projectCalendar = gantt.getCalendar(task.gantt_id);

        if (projectStartData.valueOf() > calcStartData.valueOf()) {
          startDate = projectStartData;
        } else {
          startDate = calcStartData;
        }

        if (!gantt.isWorkDay(startDate, projectCalendar)) {
          startDate = gantt.getClosestWorkTime({ date: startDate, task: task, dir: "future", unit: gantt.config.duration_unit });
        }

        var workRange = gantt.getDayHours(startDate);
        var dayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), workRange.firstHour);

        startDate = gantt.getClosestWorkTime({ date: dayStart, task: task, dir: "future" });

        if (projectStartData.valueOf() > startDate.valueOf()) {
          startDate = gantt.getClosestWorkTime({ date: gantt.date.add(startDate, 1, "day"), task: task, dir: "future" });
        }

        if (gantt.config.duration_view === "hour") {
          gantt.config.duration_view = projectConfig.durationData.mode;
        }

        var endDateData = gantt.calculateToDuration(startDate, 1);
        duration = gantt.calculateDuration(startDate, endDateData.endDate, task);
        gantt.config.duration_view = oldDurationView;

        var children = gantt.getChildren(task.parent);
        var childrenIndex = 0;

        _.some(children, function (child) {
          ++childrenIndex;
          if (+child === task.id) {
            return true;
          }
        });

        var checkAfterCurrentTask = false;

        _.each(children, function (child) {
          if (checkAfterCurrentTask) {
            var childTask = gantt.getTask(child);
            childTask.sortorder = +childTask.sortorder + 1;
          } else if (+child === task.id) {
            checkAfterCurrentTask = true;
          }
        });

        const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        gantt.createTask(
          {
            text: __("gantt_new_sibling_task"),
            duration: duration,
            estimation: 0,
            insertAfter: JSON.stringify([task.id, task.parent]),
            type: gantt.config.types.task,
            start_date: startDate,
            sortorder: +task.sortorder + 1,
            gantt_id: task.gantt_id,
            calendar_id: task.gantt_id,
            created_at: new Date(),
            owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
            openEditAfterCreate: true
          },
          task.parent,
          childrenIndex
        );
      },
      subMilestone: function (currentTaskId) {
        const taskData = gantt.getTask(currentTaskId);
        let taskId = 0;
        const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        gantt.callEvent('onChangeTaskToParent', [taskData]);

        if (!gantt.hasChild(currentTaskId)) {
          taskId = gantt.createTask(
            {
              text: __("gantt_new_child_milestone"),
              type: gantt.config.types.milestone,
              gantt_id: taskData.gantt_id,
              calendar_id: taskData.gantt_id,
              color: 16,
              ignoreProgress: true,
              created_at: new Date(),
              sortorder: 1,
              owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
              openEditAfterCreate: true
            },
            currentTaskId
          );

          gantt.callEvent("customButtons:add:to", [taskData]);
          taskData.type = "project";
          taskData.open = 1;
          taskData.progress = 0;
          taskData.actionHash = gantt.getTask(taskId).actionHash;
          taskData.actionHashManual = true;

          innerObject.handlersFromDependencyModule.deleteResourcesFromTask(taskData);

          gantt.callEvent("setIgnoreMove", [taskData.id]);

          gantt.callEvent("customButtons:add:to", [taskData]);
          gantt.updateTask(currentTaskId, taskData);

          delete taskData.actionHash;
          delete taskData.actionHashManual;

          return true;
        }

        var children = gantt.getChildren(currentTaskId);
        var sortorder = 1;

        _.each(children, function (child) {
          var childTask = gantt.getTask(child);

          if (childTask.type !== gantt.config.types.button && +childTask.sortorder >= sortorder) {
            sortorder = +childTask.sortorder;
          }
        });

        taskId = gantt.createTask({
          text: __("gantt_new_child_milestone"),
          type: gantt.config.types.milestone,
          created_at: new Date(),
          gantt_id: taskData.gantt_id,
          calendar_id: taskData.gantt_id,
          sortorder: sortorder + 1,
          owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
          openEditAfterCreate: true
        },
          currentTaskId,
          gantt.getChildCount(currentTaskId) - 1
        );

        taskData.open = 1;

        gantt.updateTask(currentTaskId, taskData);
      },
      sublingMilestone: function (currentTaskId) {
        var task = gantt.getTask(currentTaskId);
        var children = gantt.getChildren(task.parent);
        var childrenIndex = 0;
        const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        _.some(children, function (child) {
          ++childrenIndex;
          if (+child === task.id) {
            return true;
          }
        });

        var checkAfterCurrentTask = false;

        _.each(children, function (child) {
          if (checkAfterCurrentTask) {
            var childTask = gantt.getTask(child);
            childTask.sortorder = +childTask.sortorder + 1;
          } else if (+child === task.id) {
            checkAfterCurrentTask = true;
          }
        });

        gantt.createTask(
          {
            text: __("gantt_new_sibling_milestone"),
            type: gantt.config.types.milestone,
            gantt_id: task.gantt_id,
            calendar_id: task.gantt_id,
            sortorder: +task.sortorder + 1,
            insertAfter: JSON.stringify([task.id, task.parent]),
            created_at: new Date(),
            owner_id: resourceId, // resourceModel.getResourceByUserId(user.id),
            openEditAfterCreate: true
          },
          task.parent,
          childrenIndex
        );
      },
      indentTask: function (currentTaskId) {
        var newParentId = gantt.getPrevSibling(currentTaskId),
          oldParentId = gantt.getParent(currentTaskId),
          newParent = {},
          currentTask,
          parentChanged = false,
          maxCurrentLevelDepth = 0,
          maxSiblingLevelDepth = 0;

        if (!newParentId) {
          return false;
        }

        maxSiblingLevelDepth = gantt.calculateTaskLevel(newParentId);
        maxCurrentLevelDepth = gantt.calculateTaskLevel(currentTaskId);

        if (gantt.hasChild(currentTaskId)) {
          gantt.eachTask(function (task) {
            if (gantt.calculateTaskLevel(task.id) > maxCurrentLevelDepth) {
              maxCurrentLevelDepth = gantt.calculateTaskLevel(task.id);
            }
          }, currentTaskId);
        }

        if ((maxCurrentLevelDepth + maxSiblingLevelDepth) > 15) {
          webix.message({ type: "info", text: __("block_indent_parent_task_message") });
          return false;
        }

        newParent = gantt.getTask(newParentId);

        while (newParent.type === gantt.config.types.button || !gantt.calculateTaskLevel(newParentId)) {
          newParentId = gantt.getPrevSibling(newParentId);
          newParent = gantt.getTask(newParentId);
        }

        gantt.moveTask(currentTaskId, gantt.getChildren(newParentId).length - 1, newParentId);

        gantt.callEvent("manualMoveTask", [currentTaskId, newParentId, oldParentId]);

      },
      outdentTask: function (currentTaskId) {
        var currentTask = gantt.getTask(currentTaskId);
        var oldParentId = currentTask.parent;
        var newParentId = 0;
        var newParentData = gantt.getTask(gantt.getParent(currentTask.parent));
        const viewData = ganttViewModel.getActiveViewData();

        if (!newParentData || (viewData.is_jira && newParentData.$level === 0)) {
          return false;
        }

        gantt.saveTaskEditors(true);

        if (gantt.isTaskExists(oldParentId) && gantt.calculateTaskLevel(gantt.getTask(oldParentId)) !== gantt.config.root_id) {
          newParentId = gantt.getParent(currentTask.parent);

          gantt.moveTask(currentTaskId, gantt.getTaskIndex(oldParentId), newParentId);
          gantt.callEvent("manualMoveTask", [currentTaskId, newParentId, oldParentId]);
          // if (currentTask.type === gantt.config.types.milestone && newParentData.$level === 0) {
          //   currentTask.type = gantt.config.types.project;
          //   gantt.updateTask(currentTaskId, currentTask);
          // }
        }
      },
      helpers: {
        updateTasksAfterIndentOutdent: function (currentId) {
          gantt.batchUpdate(function () {
            gantt.eachTask(function (task) {
              task.$level = gantt.calculateTaskLevel(task.id);
              gantt.refreshTask(task.id);
            }, currentId);
          });
        },
        foundAndDeleteLink: function (linkId, task, method) {
          var ganttLinks = gantt.serialize().links;
          var checkTask = {};
          var checkParentTaskId = 0;

          return ganttLinks.some(function (link) {
            if (parseInt(link.id, 10) === parseInt(linkId, 10)) { //нашли связь - дальше нужно узнать что за связь)
              if ((method === "source" && parseInt(link.target, 10) !== parseInt(task.id, 10)) ||
                (method === "target" && parseInt(link.source, 10) !== parseInt(task.id, 10))) {
                if (method === "source") {
                  checkTask = gantt.getTask(link.target);
                } else {
                  checkTask = gantt.getTask(link.source);
                }

                if (gantt.calculateTaskLevel(checkTask.id) >= gantt.calculateTaskLevel(task.id)) {
                  return true;
                } else { // таск с коротым связ - находится выше - теперь надо узнать является ли он отцом)
                  checkParentTaskId = task.id;
                  for (var taskLevel = gantt.calculateTaskLevel(checkTask.id); taskLevel <= gantt.calculateTaskLevel(task.id); taskLevel++) {
                    if (checkParentTaskId && checkParentTaskId === checkTask.id) {
                      gantt.deleteLink(link.id);
                    }

                    checkParentTaskId = gantt.getTask(checkParentTaskId).parent;
                  }
                }
              }
            }
          });
        }
      }
    },
    copyPaste: {
      clickItem: function (taskId, id) {
        var strategy = {
          'insert_above': innerObject.handlers.copyPaste.paste.bind(this, true),
          'insert_below': innerObject.handlers.copyPaste.paste.bind(this, false)
        };

        this.hidePastePopup();

        strategy[id] && strategy[id](taskId);
      },
      hidePastePopup: function () {
        $$(innerObject.views.pasteTaskPopup.id).hide();
      },
      showPastePopup: function (taskId, node) {
        innerObject.settings.currTaskId = +taskId;
        $$(innerObject.views.pasteTaskPopup.id).show(node, { y: 5 });
      },
      hideSpecialPopup: function () {
        // $$(innerObject.views.specialPasteTaskPopup.id).hide();
      },
      fullCopyAction: function (taskId) {
        innerObject.handlers.copyPaste.copy(taskId);
        innerObject.helpers.initPasteIcon();
        innerObject.settings.copyPastError = null;
      },

      copy: function (taskId) {
        globalStore.dispatch('copyPaste/copy', taskId)
      },
      insertTask: function (taskId, id) {
        innerObject.handlers.copyPaste.clickItem(taskId, id);
      },

      pasteValidation: function () {
        const copiedTask = globalStore.getters['copyPaste/copiedTask'];
        const sameProject = copiedTask.ganttId === gantt.config.gantt_id;
        const taskNotExist = !gantt.isTaskExists(copiedTask.taskId);

        if ((sameProject && taskNotExist) && copiedTask) {
          errorCopyPasteHandler(copiedTask.taskId, __('can_not_copy_delete'));
        }

        if (innerObject.settings.copyPastError) {
          Vue.$toast.error({
            component: toastMessage,
            props: { message: innerObject.settings.copyPastError },
          }, { timeout: 6000 });
          // globalStore.commit('copyPaste/resetStore');
          innerObject.helpers.initPasteIcon();
          return false;
        } else {
          return true;
        }
      },
      fullPasteAction: function (taskId, node) {
        if (innerObject.handlers.copyPaste.pasteValidation()) {
          innerObject.handlers.copyPaste.showPastePopup(taskId, node);
        }
      },
      paste: async function (before, taskId) {
        await globalStore.dispatch('copyPaste/paste', {before, taskId })
        const copiedTask = globalStore.getters['copyPaste/copiedTask'];
        const totalTask = gantt.getTaskByIndex(0);
        errorCopyPasteHandler([copiedTask.parentId, totalTask.id], __('can_not_copy_changes'));
      }
    },
    selectColorOnColorboard: function () {
      var task = gantt.getTask(innerObject.settings.colorPickerTaskId);
      var $$taskColorBoard = $$(innerObject.views.taskColorBoard.id);
      let colorValue;

      $$taskColorBoard.blockEvent();

      if (task.color) {
        colorValue = parseInt(task.color, 10) ? colorHelper.getColorStr(parseInt(task.color, 10)) : task.color;

        $$taskColorBoard.setValue(colorValue);
      }

      $$taskColorBoard.unblockEvent();
    },
    showColorboard: function (node, position) {

      if (!position || _.isEmpty(position)) {
        position = {
          pos: "bottom", x: -6, y: 0
        }
      }

      innerObject.handlers.selectColorOnColorboard();
      $$(innerObject.views.taskColorPopup.id).show(node, position);
    },
    hideColorboard: function () {
      $$(innerObject.views.taskColorPopup.id).hide();
      innerObject.settings.colorPickerTaskId = false;
    },
    changeTaskColorToId: (color) => {
      let taskId = innerObject.settings.colorPickerTaskId;

      if (!taskId) {
        return;
      }

      let colorID = colorHelper.getColorId(color);

      innerObject.handlers.changeCurrentTaskColor(colorID);
    },
    changeCurrentTaskColor: function (color) {
      let taskId = innerObject.settings.colorPickerTaskId;

      app.trigger("taskSettings:save", _.assign(gantt.getTask(taskId), { color: +color }));
      innerObject.handlers.hideColorboard();

      userExtAnalytics.log('task_grid_action', { action: 'color' });
    },
    generateActionHash: function () {
      return historyModel.generateActionHash();
    },
    removeTaskById: function (taskId, callback) {
      if (!gantt.isTaskExists(taskId))
        return;

      var taskData = gantt.getTask(taskId);

      if (taskData.type !== gantt.config.types.button) {
        app.trigger("popup:show", true);

        app.trigger('gantt:keyboard:disable');

        webix.message.keyboard = true;
        const confirmCallback = data => {
          if (taskData.$new) {
            gantt._deleteTask(taskId, true); //gantt5
            gantt.refreshData();
          } else {
            globalStore.commit('copyPaste/toggleSpecialPastePopup', false);
            app.trigger("taskSettings:delete", taskId);
          }
          globalStore.dispatch('globalPopup/close');
          app.trigger('gantt:keyboard:enable');
          callback && callback();
          userExtAnalytics.log('gantt_task_delete_confirmed');
        };
        const cancelCallback = () => {
          userExtAnalytics.log('gantt_task_delete_cancelled');
          app.trigger('gantt:keyboard:enable');
        };
        const isJira = !!taskData.jira_key;
        const localeKey = "settings_msg_delete_task" + (isJira ? "_jira" : "");
        const taskName = __(localeKey, { taskName: `<b>${customHelper.formatTaskName(taskData.text)}</b>` });

        globalStore.dispatch('globalPopup/show', {
          type: 'confirm',
          ok: ('common_yes'),
          cancel: ('common_cancel'),
          message: `<div>${taskName}</div>`,
          footerText: true,
          popupWidth: 440,
          buttonWidth: '90px',
          confirm: confirmCallback,
          cancelCallback: cancelCallback,
        });


        // taskSettingsHandlers.deleteTaskConfirmation(taskData, function (result) {
        //   if (result) {
        //     if (taskData.$new) {
        //       gantt._deleteTask(taskId, true); //gantt5
        //       gantt.refreshData();
        //     } else {
        //       globalStore.commit('copyPaste/toggleSpecialPastePopup', false);
        //       app.trigger("taskSettings:delete", taskId);
        //     }
        //     callback && callback();
        //     userExtAnalytics.log('gantt_task_delete_confirmed');
        //   } else {
        //     userExtAnalytics.log('gantt_task_delete_cancelled');
        //   }
        //
        //   app.trigger('gantt:keyboard:enable');
        // });
      }
    },
    convertTask: function (taskId) {
      var taskData = gantt.getTask(taskId);

      if (taskData.type === gantt.config.types.milestone) {
        taskData.type = gantt.config.types.task;
        taskData.color = constants.DEFAULT_TYPE_COLORS.TASK;
        taskData.duration = 60;
      } else {
        taskData.type = gantt.config.types.milestone;
        taskData.color = constants.DEFAULT_TYPE_COLORS.MILESTONE;
        taskData.duration = 0;
      }

      taskData.estimation = 0;
      taskData.end_date = gantt.calculateEndDate(taskData);
      gantt.updateTask(taskId, taskData);
    },
    // removePairFromResourceModel: function (resourceID, taskID) {
    //   resourceModel.data.pull[resourceID] && _.remove(resourceModel.data.pull[resourceID].tasks, function (data) {
    //     return data.task_id === parseInt(taskID, 10);
    //   });
    // },
    // addNewPairToResourceModel: function (resourceID, taskID) {
    //   resourceModel.data.pull[resourceID] && resourceModel.data.pull[resourceID].tasks
    //     .push({
    //       resource_id: parseInt(resourceID, 10),
    //       task_id: parseInt(taskID, 10)
    //     });
    // },
    // isResourceModelContainPair: function (resourceID, taskID) {
    //   return resourceModel.data.pull[resourceID] && _.find(resourceModel.data.pull[resourceID].tasks, {
    //     resource_id: parseInt(resourceID, 10),
    //     task_id: parseInt(taskID, 10)
    //   });
    // },
    // updateResourceModel: function (tasks, tasksMap) {
    //   var newTaskIDs = _.values(tasksMap),
    //     originalTaskIDs = _.keys(tasksMap),
    //     newTasks = _.filter(tasks, function (task) {
    //       return _.includes(newTaskIDs, task.id);
    //     }),
    //     originalTasks = _.filter(tasks, function (task) {
    //       return _.includes(originalTaskIDs, '' + task.id);
    //     });
    //
    //   _.each(newTasks, function (task) {
    //     if (task.resource_id) {
    //       !innerObject.handlers.isResourceModelContainPair(task.resource_id, task.id) &&
    //         innerObject.handlers.addNewPairToResourceModel(task.resource_id, task.id);
    //     } else {
    //       var originalTaskID = Object.keys(tasksMap).filter(function (x) {
    //         return +tasksMap[x] === +task.id;
    //       })[0],
    //         originalTask = _.find(originalTasks, { id: +originalTaskID });
    //
    //       originalTask && innerObject.handlers.removePairFromResourceModel(originalTask.resource_id, task.id);
    //     }
    //   });
    // },
  },
  helpers: {
    moduleIsActive: function () {
      return innerObject.settings.moduleEnable;
    },
    activateModule: function (active) {
      if (innerObject.settings.moduleEnable) {
        if (active === innerObject.settings.isShow) {
          innerObject.settings.columnNumber = 0;
          innerObject.init.initActionGrid();
        }
      }
    },
    initPasteIcon: function () {
      const viewData = ganttViewModel.getActiveViewData();
      const copiedTask = globalStore.getters['copyPaste/copiedTask'];
      var disabledPasteNode = document.getElementById('disabled-paste');
      var enabledPasteNode = document.getElementById('enabled-paste');

      if (!viewData.is_jira) {
        if (copiedTask) {
          disabledPasteNode && disabledPasteNode.classList.add('hidden');
          enabledPasteNode && enabledPasteNode.classList.remove('hidden');
        } else {
          disabledPasteNode && disabledPasteNode.classList.remove('hidden');
          enabledPasteNode && enabledPasteNode.classList.add('hidden');
        }
      }
    },
  },
  settings: {
    progressType: "",
    ganttId: 0,
    taskDataBeforeUpdate: {},
    colorPickerTaskId: false,
    moduleEnable: false,
    isShow: false,
    dependency: {
      gridCustomButtons: true
    }
  }
}

webix.ui({
  view: "popup",
  id: innerObject.views.addMenuGanttPopup.id,
  borderless: false,
  css: "addMenuGanttPopup",
  width: 250,
  padding: 0,
  body: {
    id: innerObject.views.addMenuList.id,
    view: 'menu',
    layout: "y",
    autoheight: true,
    type: {
      width: 250,
      height: 36
    },
    padding: 0,
    yCount: 6,
    borderless: false,
    template: "<div><span class='add_task_icon #icon#'></span></div><div class='add_task_icon_text'>#value#</div>",
    data: [
      { id: 'add_subtask', icon: 'add_subtask_icon', value: __('gantt_add_subtask') },
      { id: 'add_sibling_task', icon: 'add_sibling_task_icon', value: __('gantt_add_sibling_task') },
      { id: 'add_child_milestone', icon: 'add_child_milestone_icon', value: __('gantt_add_child_milestone') },
      { id: 'add_sibling_milestone', icon: 'add_sibling_milestone_icon', value: __('gantt_add_sibling_milestone') },
      { id: 'move_indent', icon: 'move_indent_icon', value: __('gantt_move_indent') },
      { id: 'move_outdent', icon: 'move_outdent_icon', value: __('gantt_move_outdent') }
    ],
    on: {
      onItemClick: function (id, e) {
        innerObject.handlers.clickItem(id, e);
      }
    }
  },
  on: {
    "onHide": function () {
      app.trigger("keyboard:enable");
    }
  }
}).hide();

webix.ui({
  view: "popup",
  id: innerObject.views.pasteTaskPopup.id,
  borderless: false,
  css: "addMenuGanttPopup",
  width: 250,
  padding: 0,
  hidden: true,
  body: {
    id: innerObject.views.pasteTaskMenuList.id,
    view: 'menu',
    layout: "y",
    autoheight: true,
    type: {
      width: 250,
      height: 36
    },
    padding: 0,
    yCount: 6,
    borderless: false,
    template: "<div><span class='add_task_icon'>#icon#</span></div><div class='add_task_icon_text'>#value#</div>",
    data: [
      { id: 'insert_above', icon: svgPasteAbove, value: __('gantt_insert_above') },
      { id: 'insert_below', icon: svgPasteBelow, value: __('gantt_insert_below') }
    ],
    on: {
      onItemClick: function (id, e) {
        innerObject.handlers.copyPaste.insertTask(innerObject.settings.currTaskId, id);
      }
    }
  },
  on: {
    "onHide": function () {
      app.trigger("keyboard:enable");
    }
  }
})

var outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit
  },
  helpers: {
    activateModule: innerObject.helpers.activateModule
  },
  handlers: {
    changeTaskColor: function (config) {
      innerObject.settings.colorPickerTaskId = config.taskId;
      innerObject.handlers.showColorboard(config.node, config.position);
    },
    hideColorboard: innerObject.handlers.hideColorboard,
    removeTaskById: innerObject.handlers.removeTaskById,
    filterMenuItems: innerObject.handlers.filterMenuItems,
    fullCopyAction: innerObject.handlers.copyPaste.fullCopyAction,
    pasteValidation: innerObject.handlers.copyPaste.pasteValidation,
    insertTask: innerObject.handlers.copyPaste.insertTask,
    convertTask: innerObject.handlers.convertTask,
    addTaskClick: function (id, taskId) {
      innerObject.settings.currTaskId = +taskId;
      innerObject.handlers.clickItem(id);
    }
  }
};

webix.ui({
  view: "popupWithoutPoint",
  css: "grid-colorboard-popup",
  id: innerObject.views.taskColorPopup.id,
  padding: 8,
  hidden: true,
  body: innerObject.views.taskColorBoard
}).hide();

app.on('context_menu:mouse:move', function () {
  innerObject.handlers.hideColorboard();
});

$$(innerObject.views.taskColorBoard.id).attachEvent("onSelect", innerObject.handlers.changeTaskColorToId);


export default outputObject;
