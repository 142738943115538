<template>
  <div class="settings-content settings-content-statuses">
    <div class="settings-section statuses-section">
      <div class="main-title">
        <div class="main-title-text">
          {{ locale('project_status_locale') }}
        </div>
        <vgp-button
          :class="{'disabled': buttonDisable }"
          :label="locale('project_status_create_new')"
          :type="'primary'"
          :icon="{name: 'plus-1', type:'bold', size: 20}"
          :icon-side="'left'"
          :small="true"
          :icon-color="'#fff'"
          @onClick="createNewStatus"
        />
      </div>
      <div class="text">
        {{ locale('project_status_description_locale') }}
      </div>
      <div class="project-list-label">
        <div>{{ locale('project_status_color_locale') }}</div>
        <div class="project-list-label_name">
          {{ locale('project_status_name_locale') }}
        </div>
      </div>
      <div
        v-for="status in statuses"
        :key="status.id"
      >
        <div class="default_status">
          <div
            style="width: 46px; padding: 0 10px"
          />
          <status-row
            :colors="colors.data"
            :color-value="[status.options[0].color]"
            :input-value="status.options[0].value"
            :row-id="status.options[0].id"
            :is-default="status.options[0].is_default"
            @change="updateStatus(status.id, status.options[0].id, $event)"
          />
        </div>
        <Container
          group-name="status.id"
          drag-handle-selector=".column-drag-handle"
          drag-class="opacity-ghost"
          drop-class="opacity-ghost-drop"
          :lock-axis="'y'"
          :auto-scroll-enabled="true"
          @drop="onDrop(status.id, status.options, $event)"
        >
          <Draggable
            v-for="option in status.options"
            :key="option.id"
            class="status-list"
          >
            <div class="draggable-item">
              <div
                v-if="!option.is_default"
                class="drag-area column-drag-handle"
                style="float:left; padding:0 10px;"
              >
                <svg-sprite
                  :name="'dragdrop'"
                />
              </div>
              <status-row
                v-if="!option.is_default"
                :colors="colors.data"
                :color-value="[option.color]"
                :input-value="option.value"
                :row-id="option.id"
                :is-default="option.is_default"
                @change="updateStatus(status.id, option.id, $event)"
                @delete="onDeleteStatus(status.id, option.id, $event)"
              />
            </div>
          </Draggable>
        </Container>
      </div>
    </div>
  </div>
</template>

<script>

import { Container, Draggable } from 'vue-smooth-dnd';
import StatusRow from '../include/statusRow.vue';

export default {
  components: {
    StatusRow,
    Container,
    Draggable,
  },
  data() {
    return {
      locale: __,
      buttonDisable: false,
      statusesData: null,
      firstStatus: null,
    };
  },
  computed: {
    statuses() {
      const statusesData = this.$store.getters['teamSetting/projectStatuses'];

      statusesData?.forEach(status => status.options.sort((a, b) => a.order - b.order));

      return statusesData;
    },
    colors() {
      return this.$store.getters['teamSetting/colors'];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
    async createNewStatus() {
      if (!this.buttonDisable) {
        this.buttonDisable = true;
        await this.$store.dispatch('teamSetting/createNewStatus');
      }
      this.buttonDisable = false;
    },
    async updateStatus(statusId, optionId, data) {
      const statusData = {
        [data.property]: data.value,
      };

      await this.$store.dispatch('teamSetting/updateStatus', { statusId, optionId, statusData });
    },
    async onDeleteStatus(statusId, optionId) {
      await this.$store.dispatch('teamSetting/deleteStatus', { statusId, optionId });
    },
    async onDrop(statusId, collection, dropResult) {
      const orders = [];

      if (dropResult.removedIndex === null && dropResult.addedIndex === null) return collection;
      if (dropResult.removedIndex === dropResult.addedIndex) return collection;

      const result = [...collection];
      let itemToAdd = null;

      if (dropResult.removedIndex !== null) {
        itemToAdd = result.splice(dropResult.removedIndex, 1)[0];
      }

      if (dropResult.addedIndex !== null) {
        result.splice(dropResult.addedIndex, 0, itemToAdd);
      }

      result.forEach((status, idx) => {
        orders.push({ id: status.id, order: idx });
      });

      await this.$store.dispatch('teamSetting/updateOrders', { statusId, orders });
    },
  },
};
</script>

<style scoped src="../less/profileSettings.less"></style>
