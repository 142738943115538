var helpers = {
  get: function (name) {
    var escape = function (s) {
      return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1');
    };
    if(!window.document.cookie) return;
    var match = window.document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
  },
  set: function (name, value, days, domain) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    window.document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; domain=" + domain + "; path=/";
  },
  delete: function (name, value, days, domain) {
    window.document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
};

export default helpers;
