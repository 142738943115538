import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import Store from '../../../store/main';

function onCustomFieldDeleted(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const id = event.data.ids[0];

  Store.commit('columns/removeCustomColumn', id);

  if (ganttIDs && ganttIDs.length) {
    app.trigger('userCustomColumnsModel:change', ganttIDs[0]);
  }

  app.trigger('userCustomColumnsModel:change');

  app.trigger('onAfterCollaboration', {
    event: event.event,
    customFields: event.data.ids,
  });
}

export default onCustomFieldDeleted;
