// views/newProject/_noProject.js
// !TODO: description

import app from '../../app';
import common from './common';
import Store from '../../store/main'
import rights from "../../components/rights";

const {templates: {noProject}} = common;

const ID_VIEW_NO_PROJ_WINDOW = 'noProjectLayout';

const webixUI = {
  id: ID_VIEW_NO_PROJ_WINDOW,
  view: 'window',
  css: 'no-project-info',
  header: false,
  hidden: true,
  headHeight: 0,
  unClosable: true,
  position: function (state) {
    let leftMargin = Store.getters.leftSidebarWidth;

    // if user has not projects, and not fully registered
    if (!user.firstName) {
      leftMargin = 0;
    }

    state.height = document.body.clientHeight + 2;
    state.width = document.body.clientWidth - leftMargin;
    state.left = leftMargin;
    state.top = 0;
  },
  modal: false,
  move: false,
  body: {
    view: "scrollview",
    scroll: "y",
    autoheight: true,
    borderless: true,
    body: {
      rows: [
        {
          view: 'template',
          id: 'noProjectInfoPopupBody',
          css: 'no-project-info-template',
          width: 564,
          autoheight: true,
          data: {
            caption: __('no_project_info_title'),
            icon: GT.cdn + "/imgs/over_gantt_imgs/im_welcome.png",
            content: __('no_project_info_txt', {
              ownerName: user.team.owner_firstName + ' ' + user.team.owner_lastName
            }),
            description: __('no_project_info_desc', {
              ownerEmail: user.team.owner_email
            }),
            btn: {
              text: __('no_project_info_btn_text')
            },
            footer: __('no_project_info_footer')
          },
          template: noProject,
          onClick: {
            'js-init-lc': function () {
              app.trigger('app:footer:livechat:click')
            },
            'no-project-info-btn': function () {
              if (!rights.account.hasRight('project_create')) {
                userExtAnalytics.log('member_invited_no_projects_contact_popup_owner_click');
              }
            },
          }
        }
      ]
    }
  },
  on: {
    'onHide': onPopupHide,
    'onShow': onPopupShow
  }
};

function onPopupHide() {
  Object.keys(_events).forEach((event) => {
    app.off(_events[event].id);
  });

  app.trigger('user:noProject', false);
  app.trigger('user:overTrialOrSubscription', true);
  webix.callEvent("onResize", []);
};

function onPopupShow() {
  // Object.keys(_events).forEach((event) => {
  //   _events[event].id = app.on(event, _events[event].fn);
  // });
  //
  // if (userRoleHelper.isExpiredNotOwner()) {
  //   notificationModal.init({
  //     title: __('you_can_not_buy_title'),
  //     width: 420,
  //     unClosable: true,
  //     body: {
  //       css: "",
  //       template: __('you_can_not_buy_body')
  //     },
  //     buttons: [{
  //       width: 180,
  //       text: __('you_can_not_buy_button'),
  //       css: "",
  //       callback: function () {
  //         app.trigger('popup:show');
  //       }
  //     }]
  //   });
  // }
  app.trigger('user:overTrialOrSubscription', true);
  app.trigger('user:noProject', true);
  webix.callEvent("onResize", []);

  $$(ID_VIEW_NO_PROJ_WINDOW) && $$(ID_VIEW_NO_PROJ_WINDOW).resize();

  if(!rights.account.hasRight('project_create')){
    userExtAnalytics.log('member_invited_no_projects_popup_show');
  }
}

const _events = {
  ['body:resize']: {
    fn: (data) => {
      $$(ID_VIEW_NO_PROJ_WINDOW) && $$(ID_VIEW_NO_PROJ_WINDOW).resize();
    }
  },
  ['leftSideBar:changedMode']: {
    fn: () => {
      $$(ID_VIEW_NO_PROJ_WINDOW) && $$(ID_VIEW_NO_PROJ_WINDOW).resize();
    }
  },
  ['tab:projectCreate']: {
    fn: () => {
      //$$(ID_VIEW_NO_PROJ_WINDOW) && routerHelper.pushRoute({name: 'app'});
    }
  }
};

app.on("body:resize", function (data) {
  $$(ID_VIEW_NO_PROJ_WINDOW).resize();
});

function init() {
  $$(ID_VIEW_NO_PROJ_WINDOW).show();
}

function cleanup() {
  $$(ID_VIEW_NO_PROJ_WINDOW).hide();
}

export {webixUI, init, cleanup};
