var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("onboarding-section", {
    attrs: { "animate-leave": _vm.animateLeave },
    scopedSlots: _vm._u([
      {
        key: "right",
        fn: function () {
          return [
            _c("div", { class: _vm.$style.right__wrapper }, [
              _c(
                "div",
                {
                  class: [
                    _vm.$style.fields__wrapper,
                    _vm.animateEnter && !_vm.animateLeave
                      ? _vm.$style.animateEnter
                      : "",
                    _vm.animateLeave && !_vm.animateEnter
                      ? _vm.$style.animateLeave
                      : "",
                  ],
                },
                [
                  _c("input", {
                    attrs: {
                      id: "gp_autotest_registration_email_input",
                      type: "text",
                      autocomplete: "username",
                      hidden: "",
                    },
                    domProps: { value: _vm.form.email },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.fullName__wrapper },
                    [
                      _c("vgp-label-slot", {
                        class: _vm.$style.input__wrap_narrow,
                        attrs: {
                          label: _vm.locales("app_welcome_form_first_name"),
                          "label-asterix": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("ValidationProvider", {
                                  ref: "firstNameValidationProvider",
                                  attrs: {
                                    rules: { required: true },
                                    vid: "firstName",
                                    mode: _vm.customValidationProviderMode,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("vgp-input", {
                                            ref: "firstName",
                                            attrs: {
                                              id: "gp_autotest_registration_firstName_input",
                                              maxlength: "250",
                                              "is-error": !!errors[0],
                                              placeholder: _vm.locales(
                                                "app_welcome_form_first_name_placeholder"
                                              ),
                                            },
                                            on: {
                                              onFocus: function ($event) {
                                                return _vm.$refs.firstNameValidationProvider.reset()
                                              },
                                            },
                                            model: {
                                              value: _vm.form.firstName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "firstName",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "form.firstName",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("vgp-label-slot", {
                        class: _vm.$style.input__wrap_narrow,
                        attrs: {
                          label: _vm.locales("app_welcome_form_last_name"),
                          "label-asterix": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("ValidationProvider", {
                                      ref: "lastNameValidationProvider",
                                      attrs: {
                                        rules: { required: true },
                                        vid: "lastName",
                                        mode: _vm.customValidationProviderMode,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("vgp-input", {
                                                ref: "lastName",
                                                attrs: {
                                                  id: "gp_autotest_registration_lastName_input",
                                                  maxlength: "250",
                                                  "is-error": !!errors[0],
                                                  placeholder: _vm.locales(
                                                    "app_welcome_form_last_name_placeholder"
                                                  ),
                                                },
                                                on: {
                                                  onFocus: function ($event) {
                                                    return _vm.$refs.lastNameValidationProvider.reset()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form.lastName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "lastName",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "form.lastName",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isFieldShown("user_company")
                    ? _c("vgp-label-slot", {
                        attrs: {
                          label: _vm.locales("onboarding_business_label"),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c("vgp-input", {
                                    attrs: {
                                      id: "gp_autotest_registration_userCompany_input",
                                      maxlength: "250",
                                      "images-left": _vm.teamLabel.favicon
                                        ? [_vm.teamLabel.favicon]
                                        : [],
                                      placeholder: _vm.locales(
                                        "onboarding_business_placeholder"
                                      ),
                                    },
                                    model: {
                                      value: _vm.form.user_company,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "user_company", $$v)
                                      },
                                      expression: "form.user_company",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          735831868
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFieldShown("password")
                    ? _c("vgp-label-slot", {
                        class: _vm.$style.input__wrap,
                        attrs: {
                          label: _vm.locales("onboarding_password_label"),
                          "label-asterix": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c("tooltip", {
                                    attrs: {
                                      position: "left",
                                      content: _vm.locales(
                                        "pass_strength_descr"
                                      ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "body",
                                          fn: function () {
                                            return [
                                              _c("ValidationProvider", {
                                                ref: "passwordValidationProvider",
                                                attrs: {
                                                  rules: "required|min:8",
                                                  vid: "password",
                                                  "custom-messages": {
                                                    min: _vm.locales(
                                                      "validation_error_password_min_length"
                                                    ),
                                                    required: _vm.locales(
                                                      "validation_error_password_required"
                                                    ),
                                                  },
                                                  mode: _vm.customValidationProviderMode,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("vgp-input", {
                                                            ref: "password",
                                                            attrs: {
                                                              id: "gp_autotest_registration_password_input",
                                                              value:
                                                                _vm.form
                                                                  .password,
                                                              "is-error":
                                                                !!errors[0] ||
                                                                _vm.isPasswordCommonError,
                                                              placeholder:
                                                                _vm.locales(
                                                                  "onboarding_password_placeholder"
                                                                ),
                                                              "input-type":
                                                                _vm.passwordInputType,
                                                              "icons-right": [
                                                                _vm.passwordIconConfig,
                                                              ],
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handlePasswordInput,
                                                              onFocus:
                                                                _vm.handleOnFocusPassword,
                                                              onBlur:
                                                                _vm.handleOnBlurPassword,
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          errors[0] ||
                                                          _vm.isPasswordCommonError
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  class:
                                                                    _vm.$style
                                                                      .input__error,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        errors[0] ||
                                                                          _vm.locales(
                                                                            "validation_error_common_password"
                                                                          )
                                                                      ) +
                                                                      "\n                  "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1320515872
                                                ),
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      678616719
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.passwordStrengthState.show
                                    ? _c(
                                        "password-strength-indicator",
                                        _vm._b(
                                          {},
                                          "password-strength-indicator",
                                          _vm.passwordStrengthState,
                                          false
                                        )
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1056118276
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentOnboardingFlow.displayedFields.length <= 2
                    ? _c(
                        "div",
                        {
                          class: _vm.$style.container_checkbox,
                          on: { click: _vm.handleCheckbox },
                        },
                        [
                          _c("vgp-checkbox", {
                            attrs: {
                              size: 16,
                              color: "#007063",
                              checked: _vm.checkPolicyAndTerms,
                              "is-error": _vm.isCheckboxError,
                            },
                            on: { onClick: _vm.handleCheckbox },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.$style.container_checkbox_label,
                                _vm.isCheckboxError
                                  ? _vm.$style.container_checkbox_label_error
                                  : "",
                              ],
                            },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.locales(
                                      "onboarding_checkbox_policy_and_terms"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("vgp-button", {
              class: [
                _vm.$style.form__button,
                _vm.animateEnter && !_vm.animateLeave
                  ? _vm.$style.animateEnter
                  : "",
                _vm.animateLeave && !_vm.animateEnter
                  ? _vm.$style.animateLeave
                  : "",
              ],
              attrs: {
                id: "gp_autotest_registration_submit_button",
                disabled: _vm.isSubmitButtonDisabled,
                "is-loading": _vm.isFormSubmitting,
                type: "green",
                label: _vm.locales("onboarding_create_account_btn_label"),
              },
              on: { onClick: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }