var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.resource }, [
    _vm.person.photo
      ? _c("div", {
          class: _vm.$style.photo_wrap,
          style: { "background-image": "url(" + _vm.person.photo + ")" },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.$style.name }, [_vm._v(_vm._s(_vm.person.name))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }