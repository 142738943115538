var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.UIConfig
    ? _c(
        "div",
        { staticClass: "header", attrs: { id: "header" } },
        [
          _vm.UIConfig.head
            ? _c("head-view", {
                ref: "headView",
                attrs: {
                  config: _vm.UIConfig.head,
                  "button-colors": _vm.buttonColors,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.UIConfig.tabs && _vm.UIConfig.tabs.length
            ? _c("tabs-view", { attrs: { config: _vm.UIConfig.tabs } })
            : _vm._e(),
          _vm._v(" "),
          _vm.UIConfig.toolbar
            ? _c("toolbar-view", {
                attrs: {
                  config: _vm.UIConfig.toolbar,
                  "button-colors": _vm.buttonColors,
                  "data-loaded": _vm.dataLoaded,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }