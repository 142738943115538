<template>
  <div
    ref="container"
    :style="style"
  />
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    path: { type: String },
    animationData: { type: Object },
    autoplay: { type: Boolean, default: true },
    loop: { type: Boolean, default: true },
    height: Number,
    width: Number,
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
      config: {
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
      },
    };
  },
  watch: {
    animationData() {
      this.loadAnimation();
    },
    path() {
      this.loadAnimation();
    },
  },
  mounted() {
    this.loadAnimation();
  },
  methods: {
    loadAnimation() {
      if (this.animationData) this.config.animationData = this.animationData;
      else if (this.path) this.config.path = this.path;
      else throw new Error('Ether "path" or "animationData" has to be provided');
      this.config.container = this.$refs.container;

      if (this.anim) this.anim.destroy();
      this.anim = lottie.loadAnimation(this.config);
      this.$emit('animCreated', this.anim);
    },
  },
};
</script>
