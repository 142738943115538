var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.select,
    },
    [
      _c("vgp-icon-button", {
        ref: "contextMenuButton",
        attrs: {
          icon: { name: "more-1" },
          colors: _vm.buttonColors,
          active: _vm.showMenu,
        },
        on: { onClick: _vm.toggleShow },
      }),
      _vm._v(" "),
      _vm.showMenu
        ? _c("context-menu", {
            staticStyle: { "min-width": "212px" },
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._l(_vm.items, function (item, index) {
                        return [
                          item.name === "delimiter"
                            ? _c("div", {
                                key: index,
                                class: _vm.$style.divider,
                              })
                            : _c(
                                "div",
                                {
                                  key: index,
                                  class: [
                                    _vm.$style.menu_item,
                                    item.cssClass || "",
                                    item.restrictAccess
                                      ? "pricing-tooltip"
                                      : "",
                                  ],
                                  attrs: {
                                    "data-feature": item.feature,
                                    "data-position": item.pricingTooltipPos,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.itemClicked(
                                        item,
                                        item.restrictAccess
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-sprite", {
                                    class: _vm.$style.icon,
                                    style: {
                                      color: item.iconColor
                                        ? item.iconColor
                                        : "",
                                    },
                                    attrs: {
                                      name: item.icon,
                                      type: "regular",
                                      size: "24",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.$style.text,
                                      style: {
                                        color: item.labelColor
                                          ? item.labelColor
                                          : "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(item.text) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  item.helpIcon && !item.restrictAccess
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.$style.help_icon,
                                            !item.activityStatus
                                              ? _vm.$style.not_shown
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleHelp(
                                                $event,
                                                item.helpIcon
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-sprite", {
                                            attrs: {
                                              name: "help",
                                              type: "regular",
                                              size: "24",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.restrictAccess
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.$style.plan_label,
                                            "pricing-title-container",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.locales(item.pricingPlan)
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2615535409
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }