<template>
  <div :class="$style['attachments__list']">
    <div
      v-for="attachment in items"
      :key="`attachments-list-view-${attachment.id}`"
      :class="[$style['attachment__row'], 'vgp-interactive-element']"
      @click="$emit('view', attachment)"
    >
      <vgp-checkbox
        :checked="selectedItems.includes(attachment.id)"
        @onChange="$emit('select', attachment.id)"
        @click.native.stop
      />
      <div :class="$style['attachment__image-wrapper']">
        <img
          ref="attachmentsImages"
          :src="attachment.thumbnailLink"
          loading="lazy"
          alt="Attachment image"
          :class="$style['attachment__image']"
          @error="$event.target.src = defaultImagePlaceholder"
        >
      </div>
      <div :class="$style['attachment__data']">
        <div :class="$style['attachment__title']">
          {{ attachment.name }}
        </div>
        <div :class="$style['attachment__info']">
          <span :class="$style['attachment__author']">
            {{ attachment.author }} &nbsp;
          </span>
          <span :class="$style['attachment__date']">
            {{ attachment.uploadDate }}
          </span>
          <span :class="$style['attachment__size']">
            {{ attachment.size }}
          </span>
        </div>
      </div>
      <div :class="$style['attachment__actions']">
        <vgp-icon-button
          v-if="!attachment.downloadUrl"
          :icon="{ name: 'link-2' }"
          @onClick.stop="$emit('view', attachment)"
        />
        <vgp-icon-button
          v-if="attachment.downloadUrl"
          :icon="{ name: 'download' }"
          @onClick.stop="$emit('download', attachment)"
        />
        <vgp-icon-button
          v-if="!disabledDeletion"
          :icon="{ name: 'delete' }"
          @onClick.stop="$emit('delete', attachment)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';

export default {
  name: 'ListView',
  components: { VgpIconButton },
  props: {
    disabledDeletion: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    defaultImagePlaceholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" module>

.attachments__list {
  display: flex;
  flex-direction: column;
}

.attachment {
  &__row {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 0;
    position: relative;
    &:hover {
      cursor: pointer;
      &::before {
        display: block;
      }
      .attachment__actions {
        opacity: 1;
        pointer-events: unset;
        visibility: unset;
      }
    }
    &::before {
      z-index: -1;
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 64px);
      height: 100%;
      background: #F2F2F2;
    }
    // full width border bottom
    &::after {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 64px);
      height: 1px;
      background: #E3E3E3;
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    height: 32px;
    width: 50px;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__data {
    display: flex;
    flex-direction: column;
    width: 490px;
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #191919;
    margin-bottom: 5px;
  }

  &__info {
    display: flex;
  }

  &__author, &__date, &__size  {
    font-family: Lato-Regular, sans-serif;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }

  &__author {
    max-width: 370px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__size {
    margin-left: 9px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    align-items: center;
    gap: 8px;
    color: #999999;
    svg {
      cursor: pointer;
    }
  }

}
</style>
