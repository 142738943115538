import app, { appIds } from '../../app';
import routerHelper from '../../helpers/router';
// import { lcPopupShow } from '../popups/learningCenter';
import UIConfigsByRoute from './UIConfigsByRoute';
import plansModel from '../../models/plans';
// import NotifyManager from '../notifyPopup/index';
import constants from "../../helpers/constants";

const footerStore = {
  namespaced: true,
  state: {
    UIConfig: null,
    timerValue: null,
    unreadComments: null,
    height: 30,
  },

  getters: {
    height: state => state.height,
    UIConfig: state => state.UIConfig,
    timerValue: state => state.timerValue,
    unreadComments: state => state.unreadComments,
    isActiveUser: () => plansModel.isActive(),
  },

  mutations: {
    changeRoute(state, data) {
      routerHelper.pushByNameAndParams(data.name, data.params);
    },

    seTimerValue(state, value) {
      state.timerValue = value;
    },

    stopTimer(state) {
      state.timerValue = null;
    },

    setUIConfig(state) {
      state.UIConfig = UIConfigsByRoute.getUI(state.currentRoute);
      state.height = state.UIConfig.existFooter ? 30 : 0;
    },

    setUnreadComments(state, value) {
      state.unreadComments = value;
    },
    setHeight(state, height) {
      state.height = height;
    },
  },

  actions: {
    timerClickHandler({}, pos) {
      gantt.callEvent('showTimeTrackingPopup', ['global', pos]);
    },
    chatClickHandler({}) {
      app.trigger(appIds.events.ID_APP_EVENT_LIVECHAT_CLICK);
    },
    demoClickHandler({ dispatch, rootGetters }, { campaign }) {
      const demoPopupActivityStatus = rootGetters['user/getActivityStatus'](constants.USER_ACTIVITIES.DEMO_POPUP);
      if (!demoPopupActivityStatus) {
        dispatch(
          'user/updateActivityStatus',
          { activityName: constants.USER_ACTIVITIES.DEMO_POPUP, status: 1 },
          { root: true },
        );
      }
      demoSheduler.open({ campaign });
    },
  },
};

export default footerStore;
