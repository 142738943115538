import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import multiviewModel from '../../../models/multiViewsProjects';
import Store from '../../../store/main';
import { refreshParents } from '../tasks/helpers';

function onCustomFieldFromProjectUnassigned(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const columnsFromProjects = event.data.unassignedCustomFields;
  const projectIds = [];

  columnsFromProjects.forEach(el => {
    Store.commit('columns/removeColumnFromProjects', { columnId: el.customFieldId, projectIds: el.projectIds });
    projectIds.push(...el.projectIds);
  });

  if (ganttIDs && ganttIDs.length) {
    app.trigger('userCustomColumnsModel:change', ganttIDs[0]);
  }

  app.trigger('userCustomColumnsModel:change');
  app.trigger('filter:refresh:gantt');

  if (multiviewModel.getActiveGanttId()) {
    projectIds.forEach(prId => {
      const isMultivewProject = multiviewModel.getActiveGanttId().ganttIDs.find(ganttId => ganttId === prId);

      if (isMultivewProject) {
        const tasks = Store.getters['tasksModel/getTasksForGantt'](prId);

        tasks.data.forEach(task => {
          refreshParents(task.id);
        });
      }
    });
  }

  app.trigger('onAfterCollaboration', {
    event: event.event,
    unassignedCustomFields: columnsFromProjects,
    projects: [...new Set(projectIds)],
  });
}

export default onCustomFieldFromProjectUnassigned;
