import Vue from 'vue';
import helpers from './helpers';

export default {
  setData(state, payload) {
    state.data = payload;
  },
  updateProjectTasksData(state, payload) {
    const index = state.data.map(o => o.id).indexOf(payload.id);

    Vue.set(state.data, index, payload);
  },
  addProjectTasksData(state, payload) {
    const projectData = state.data.find(o => o.id === payload.id);

    if (!projectData) {
      state.data.push(helpers.prepareProjectTasksData(payload));
      state.data = [...state.data];
    } else {
      const index = state.data.map(o => o.id).indexOf(payload.id);

      Vue.set(state.data, index, helpers.prepareProjectTasksData(payload));
    }
  },
  deleteTasksByGanttId(state, ganttId) {
    state.data = state.data.filter(o => o.id !== Number(ganttId));
  },
  updateTask(state, payload) {
    const projectData = state.data.find(o => o.id === payload.gantt_id);
    const index = projectData.tasks.map(t => t.id).indexOf(payload.id);
    const newData = helpers.smartClone(payload);

    if (index !== -1) {
      Vue.set(projectData.tasks, index, {
        ...projectData.tasks[index],
        ...newData,
      });
    }
  },
  updateTasks(state, payload) {
    const groupByGanttId = _.groupBy(payload, 'gantt_id');

    Object.keys(groupByGanttId).forEach(ganttId => {
      const updatedTasksMap = _.keyBy(groupByGanttId[ganttId], 'id');
      const projectData = state.data.find(o => +o.id === +ganttId);

      if (!projectData) {
        return;
      }

      projectData.tasks.forEach((task, index) => {
        const updatedTaskData = updatedTasksMap[task.id];

        if (updatedTaskData) {
          Vue.set(projectData.tasks, index, {
            ...projectData.tasks[index],
            ...helpers.smartClone(updatedTaskData),
          });
        }
      });
    });
  },
  updateSortorderTasksAfterAddTask(state, payload) {
    const { ganttId, newTaskData } = payload;
    const projectData = state.data.find(o => o.id === ganttId);
    let checkUpdate = false;

    if (!projectData) return;

    projectData.tasks.forEach(task => {
      if (task.parent === +newTaskData.parent && task.sortorder === newTaskData.sortorder) {
        checkUpdate = true;
      }
    });

    if (!checkUpdate) {
      return;
    }

    projectData.tasks.forEach(task => {
      if (task.parent === +newTaskData.parent && task.sortorder >= newTaskData.sortorder) {
        task.sortorder += 1;
      }
    });

    projectData.tasks = [...projectData.tasks];
  },
  updateCustomValues(state, payload) {
    const {
      mapData, dataByGanttId, ganttId, taskId, newValue, propertyName,
    } = payload;

    if (mapData) {
      Object.keys(mapData).forEach(id => {
        const projectData = state.data.find(o => o.id === +id);

        if (!projectData) {
          return;
        }

        mapData[id].items.forEach(item => {
          const taskData = projectData.customValues[item.taskId];

          if (taskData) {
            projectData.customValues[item.taskId] = { ...taskData, ...item.updates };
          } else {
            projectData.customValues[item.taskId] = item.updates;
          }
        });
      });

      return;
    }

    if (dataByGanttId) {
      const projectData = state.data.find(o => o.id === +ganttId);

      projectData.customValues = dataByGanttId;

      return;
    }

    const projectData = state.data.find(o => o.id === +ganttId);

    if (!projectData) {
      return;
    }

    if (propertyName && projectData.customValues && projectData.customValues[taskId]) {
      projectData.customValues[taskId][propertyName] = newValue;
    } else if (projectData.customValues && projectData.customValues[taskId]) {
      projectData.customValues[taskId] = newValue;
    }
  },
  deleteTask(state, payload) {
    const projectData = state.data.find(o => o.id === payload.gantt_id);
    const removedIds = [];

    const sliceFunction = function (taskId) {
      projectData.tasks.forEach(currentTask => {
        if (currentTask) {
          if (+currentTask.id === +taskId) {
            removedIds.push(taskId);
          }

          if (+currentTask.parent === +taskId) {
            sliceFunction(currentTask.id);
          }
        }
      });
    };

    sliceFunction(payload.id);

    projectData.tasks = projectData.tasks.filter(t => !removedIds.includes(t.id));
  },
  deleteTasks(state, payload) {
    const { projectId, taskIds } = payload;
    const projectData = state.data.find(o => o.id === projectId);

    projectData.tasks = [...projectData.tasks.filter(t => !taskIds.includes(t.id))];
  },
  addLink(state, payload) {
    const projectData = state.data.find(o => o.id === payload.gantt_id);

    payload.lag = payload.lag || 0;
    payload.source = +payload.source;
    payload.target = +payload.target;

    projectData.links.push(payload);
    projectData.links = [...projectData.links];
  },
  updateLink(state, payload) {
    const projectData = state.data.find(o => o.id === payload.gantt_id);
    const index = projectData.links.map(t => t.id).indexOf(payload.id);

    if (index !== -1) {
      Vue.set(projectData.links, index, {
        ...projectData.links[index],
        ...payload,
      });

      return true;
    }

    return false;
  },
  deleteLink(state, payload) {
    const projectData = state.data.find(o => o.id === payload.gantt_id);

    projectData.links = projectData.links.filter(l => l.id !== payload.id);
  },
  updateTaskResourcesByGanttId(state, payload) {
    const { resourcesToTasks, ganttId } = payload;
    const projectData = state.data.find(o => o.id === ganttId);

    if (!projectData) {
      return;
    }

    projectData.resourcesToTasks = resourcesToTasks;
  },

  updateTaskResourcesByGanttIdsAndTaskIds(state, payload) {
    if (!Array.isArray(payload) || !payload.length) return;

    payload.forEach(data => {
      const { resourcesToTasks, ganttId } = data;
      const projectData = state.data.find(o => o.id === ganttId);

      if (!projectData) {
        return;
      }

      const modelTaskResources = projectData.resourcesToTasks;

      Object.keys(resourcesToTasks).forEach(taskId => {
        Vue.set(modelTaskResources, taskId, resourcesToTasks[taskId]);
      });
    });
  },

  // addTask()
  addTask(state, taskData) {
    const projectTasksData = state.data.find(o => o.id === taskData.gantt_id);

    if (!projectTasksData) return;

    taskData.$level = taskData.$level || 1;
    taskData.type = taskData.$rendered_type || taskData.type;
    taskData.note = taskData.note || '';

    projectTasksData.tasks.push(
      helpers.smartClone(helpers.prepareDefaultTaskValues(taskData, projectTasksData.defaultCustomValues)),
    );

    projectTasksData.tasks = [...projectTasksData.tasks];
  },

  archiveTasks(state, payload) {
    const { ganttId, value } = payload;
    const projectTasksData = state.data.find(o => o.id === ganttId);

    if (!projectTasksData) return;

    projectTasksData.is_archived = value;
  },

  updateLinks(state, payload) {
    const { ganttId, links } = payload;
    const projectTasksData = state.data.find(o => o.id === ganttId);

    if (!projectTasksData) return;

    projectTasksData.links.forEach(storeLink => {
      const newLinkData = links.find(link => link.id === storeLink.id);

      if (newLinkData) {
        storeLink.lag = newLinkData.lag;
      }
    });

    projectTasksData.links = [...projectTasksData.links];
  },

};
