import {
  cloneDeep, differenceWith, includes, isEqual, map,
} from 'lodash';
import { checkPricingAccess } from '../../../helpers/pricingHelper';
import projectsModel from '../../../models/projects';
import moment from '../../../libs/moment';
import durationConfig from '../../newProject/include/durationConfig';
import projectComponent from '../../../components/Project';
import globalStore from '$$store/main';

const getDefaultProjectStartDate = () => {
  const startDate = new Date();

  const updateStartDateAccordingToWorkDays = () => {
    const defaultWorkDays = projectsModel.getDefaultWorkDays();
    let blockDays = differenceWith([1, 2, 3, 4, 5, 6, 7], defaultWorkDays, isEqual);

    blockDays = map(blockDays, day => (day === 7 ? 0 : day));

    if (includes(blockDays, startDate.getDay())) {
      while (includes(blockDays, startDate.getDay())) {
        startDate.setDate(startDate.getDate() + 1);
      }
    }
  };

  updateStartDateAccordingToWorkDays();

  return startDate;
};

const composeProjectSettingsForRequest = () => {
  const startDate = getDefaultProjectStartDate();
  const workHours = projectsModel.getDefaultworkHours();
  const roundedStartDate = moment(startDate).hours(workHours[0]).startOf('hour');
  const templateName = 'none';

  return {
    startProject: roundedStartDate.valueOf() - (new Date().getTimezoneOffset() * 60000), // get time by UTC
    durationConfig: durationConfig.find(config => config.id === 'hour').config,
    showTime: workHours,
    showDay: projectsModel.getDefaultWorkDays(),
    estimation_mode: checkPricingAccess('workload') ? gantt.estimationCalculator.MODES.fixEstimation : gantt.estimationCalculator.MODES.fixDuration,
    apply_resource_calendar: true,
    isFromTemplate: templateName,
  };
};

const createProject = async projectData => {
  const projectSettings = composeProjectSettingsForRequest();
  const projectDataCopy = cloneDeep(projectData);
  const isUserSkippedStepWithTasks = projectDataCopy.tasks.every(task => !task.name);

  if (isUserSkippedStepWithTasks) {
    projectDataCopy.tasks = projectDataCopy.tasks.slice(0, 2);
  } else {
    projectDataCopy.tasks = projectDataCopy.tasks.filter(task => task.name || task.uniqueTemplateText === 'group1');
  }

  Object.assign(projectSettings, { onboardingProjectData: projectDataCopy });

  const data = {
    projectName: projectData.name || __('onboarding_project_name_default_name'),
    projectSettings,
    sourceGanttId: null,
    applyResources: 0,
  };

  const { response } = await projectComponent.addNewProject(data, {
    sync: true,
  });
  const responseData = await response.json();

  return responseData.projectData;
};

const addTeammatesAsResources = async (teammates, ganttId) => {
  // const resourcesData = {
  //   emails: teammates.filter(teammate => teammate.email).map(teammate => teammate.email),
  //   teamId: user.team.id,
  //   ganttIds: [ganttId],
  //   roleId: 3,
  // };

  const emails = teammates.filter(teammate => teammate.email).map(teammate => teammate.email);

  if (!emails.length) return;

  await globalStore.dispatch('resourcesModel/createResources', emails.map(email => ({
    type: 'real',
    email,
    projectIds: [ganttId],
    accountRoleId: null,
    projectRoleId: null,
  })));
};

const addTeammateResourcesToProject = async (resources, ganttId) => {
  await globalStore.dispatch('resourcesModel/addResourcesToProjects', resources.map(item => ({
    projectRoleId: null,
    resourceId: item.id,
    projectIds: [ganttId],
  })));
};

export const createProjectWithTasksAndTeammates = async projectData => {
  const createdProjectData = await createProject(projectData);

  await addTeammatesAsResources(projectData.teammates, createdProjectData.gantt_id);

  const createdTeammatesResources = globalStore.getters['resourcesModel/getAllRealResources'].filter(resource => resource.userId !== user.id);

  await addTeammateResourcesToProject(createdTeammatesResources, createdProjectData.gantt_id);

  return Promise.resolve();
};
