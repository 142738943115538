import app from "./../app";

import projectsModel from "./projects";
import multiViewsProjectsModel from "./multiViewsProjects";
import globalStore from "../store/main";
import _ from "../libs/lodash";
import routerHelper from "../helpers/router";

let activeGanttModel = null;
let activeInstanceID = null;
let activeInstanceIDGanttIds = null;
let isMultiProjectsView = false;

let ganttViewModel = new Proxy({}, {
  get: function (target, prop, value) {
    isMultiProjectsView = routerHelper.isMultiProjectsRoute() ||
      ((app.config.mode.isExport || app.config.mode.isLink) && GT.ganttData.multiviews);

    if (!activeGanttModel || activeGanttModel.isMultiview !== isMultiProjectsView) {
      activeGanttModel = isMultiProjectsView ? multiViewsProjectsModel : projectsModel;
    }

    if (prop in target) {
      return target[prop];
    }
    return activeGanttModel[prop];
  }
});

ganttViewModel.PROJECT = "project";
ganttViewModel.MULTIVIEW = "multiview";

ganttViewModel.reInit = function (id, ganttIds) {
  isMultiProjectsView = !!ganttIds;

  gantt.config.multiview = isMultiProjectsView;

  gantt.config.multiview_id = isMultiProjectsView ? +id : null;
  gantt.config.gantt_id = isMultiProjectsView ? null : +id;

  activeGanttModel = !!ganttIds ? multiViewsProjectsModel : projectsModel;
  activeInstanceID = id;
  activeInstanceIDGanttIds = ganttIds;

  //app.trigger('ganttViewModel:reinit', id, ganttIds);
};

ganttViewModel.getGanttIDs = function () {
  let ganttIDs = [];

  if (isMultiProjectsView) {
    ganttIDs = activeInstanceIDGanttIds;
  } else {
    const ganttID = projectsModel.getActiveGanttId();
    if (ganttID && !isNaN(ganttID)) {
      ganttIDs = [projectsModel.getActiveGanttId()];
    }
  }

  return _.compact(ganttIDs);
};

ganttViewModel.updateGanttIDs = function (ganttIDs) {
  if (isMultiProjectsView) {
    activeInstanceIDGanttIds = ganttIDs;
  }
};

ganttViewModel.updateMultiviewValuesByTaskID = function (taskData) {
  const tasksValuesFormMultiview = multiViewsProjectsModel.getTasksValuesFormMultiview();

  // console.log('updateMultiviewValuesByTaskID', taskData.id, taskData.open);
  if (tasksValuesFormMultiview && taskData) {
    const task = _.find(tasksValuesFormMultiview, {task_id: taskData.id});
    if (task) {
      task.open = taskData.open;
    } else {
      tasksValuesFormMultiview.push({
        multiview_id: activeInstanceID,
        task_id: taskData.id,
        user_id: user.id,
        open: taskData.open,
      });
    }
  }
  // console.log('tasksValuesFormMultiview', tasksValuesFormMultiview);
};

ganttViewModel.getTaskValuesMultiview = function (taskFromModel) {
  const tasksValuesFormMultiview = multiViewsProjectsModel.getTasksValuesFormMultiview();
  const values = _.find(tasksValuesFormMultiview, {task_id: taskFromModel.id});
  const result = _.clone(taskFromModel);

  result.open = 1;//default value for multiview task

  if (values) {
    result.open = values.open;
  }

  return result;
};

ganttViewModel.getTasksForMultiView = function (ganttIDs, id) {
  let needMultiviewBackground = false;

  const multiViewTasks = _.map(ganttIDs, (ganttID) => {
    const tasksFromModel = _.cloneDeep(globalStore.getters['tasksModel/getTasksForGantt'](ganttID));

    tasksFromModel.data = _.map(tasksFromModel.data, (task) => {
      return ganttViewModel.getTaskValuesMultiview(task);
    });

    return tasksFromModel;
  });

  const preparedTasks = {
    data: [{
      open: 1,
      id: 1,
      parent: 0,
      type: gantt.config.types.project,
      text: "main parent",
      unscheduled: true,
      auto_scheduling: false,
      sortorder: 1,
    }],
    links: [],
  };

  gantt.config.inherit_calendar = true;

  _.each(multiViewTasks, tasksAndLinks => {
    needMultiviewBackground = !needMultiviewBackground;
    if (tasksAndLinks.data.length === 0) {
      return;
    }

    tasksAndLinks.data = _.map(tasksAndLinks.data, task => {
      task.needMultiviewBackground = needMultiviewBackground;
      return task;
    });

    const totalEstimate = _.find(tasksAndLinks.data, task => {
      return !task.parent || task.parent === 1;
    });
    const projectConfig = projectsModel.getProjectConfig(totalEstimate.gantt_id);

    totalEstimate.parent = 1;
    totalEstimate.calendar_id = totalEstimate.gantt_id;
    totalEstimate.auto_scheduling = projectConfig.auto_scheduling;

    preparedTasks.data = _.concat(preparedTasks.data, tasksAndLinks.data);

    _.each(preparedTasks.data, (task) => {
      if (task.gantt_id === totalEstimate.gantt_id) {
        task.auto_scheduling = totalEstimate.auto_scheduling;
        task.calendar_id = totalEstimate.gantt_id;
      }

      return task;
    });

    preparedTasks.links = _.concat(preparedTasks.links, tasksAndLinks.links);
  });

  return preparedTasks;
};

ganttViewModel.getTasks = function () {
  if (isMultiProjectsView) {
    return ganttViewModel.getTasksForMultiView(activeInstanceIDGanttIds, activeInstanceID);
  } else {
    return globalStore.getters['tasksModel/getTasksForGantt'](activeInstanceID);
  }
};

ganttViewModel.getFavorite = function () {
  return projectsModel.getAllFavorite().concat(multiViewsProjectsModel.getAllFavorite());
};

app.on('activeMultiProjects:set', function (id, ganttIDs) {
  ganttViewModel.reInit(id, ganttIDs);
});

app.on('updateGanttIDs', function (ganttIDs) {
  ganttViewModel.updateGanttIDs(ganttIDs);
});

export default ganttViewModel;