<template>
  <div :class="$style['custom-field__row']">
    <div
      :class="$style['custom-field__name']"
      :title="fieldColumn.name"
    >
      {{ fieldColumn.name }}
    </div>
    <div
      :class="$style['custom-field__component-wrapper']"
      @mouseenter="showTooltipIfNeeded"
      @mouseleave="hideTooltip"
    >
      <component
        :is="customFieldComponentName"
        :key="componentKey"
        :style="customFieldComponentStyle"
        v-bind="customFieldComponentProps"
        v-on="customFieldComponentListeners"
      />
    </div>
  </div>
</template>

<script>
import constants from '$$helpers/constants';
import vgpCheckbox from '$$vueCmp/checkbox/vgpCheckbox.vue';
import dropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import vgpInput from '$$vueCmp/vInput/vgpInput.vue';
import DatePicker from '../../dates/includes/DatePicker.vue';
import ColorPalette from '$$vueCmp/colorPalette/colorPalette.vue';
import customColumns from '../../../../gantt/modules/userCustomColumns';

export default {
  name: 'CustomFieldRow',
  components: {
    'vgp-colorPicker': ColorPalette,
    'vgp-checkbox': vgpCheckbox,
    'vgp-select': dropdownSelect,
    'vgp-input': vgpInput,
    'vgp-datepicker': DatePicker,
  },
  props: {
    fieldColumn: {
      type: Object,
      default: () => {},
    },
    fieldValue: {
      type: [String, Number, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      componentKey: 0,
      isActive: false,
    };
  },
  computed: {
    customFieldComponentName() {
      return this.customFieldComponentConfig.name;
    },
    customFieldComponentListeners() {
      return this.customFieldComponentConfig.listeners;
    },
    customFieldComponentProps() {
      return this.customFieldComponentConfig.props;
    },
    customFieldComponentStyle() {
      return this.customFieldComponentConfig.style;
    },
    customFieldComponentConfig() {
      const COMPONENTS_CONFIGS = {
        [constants.CUSTOM_COLUMNS_TYPES.color.id]: this.getColorComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.text.id]: this.getTextComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.multiselect.id]: this.getMultiSelectComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.checkbox.id]: this.getCheckboxComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.select.id]: this.getSelectComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.tags.id]: this.getTagsComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.resources.id]: this.getResourcesComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.number.id]: this.getNumberComponentConfig,
        [constants.CUSTOM_COLUMNS_TYPES.date.id]: this.getDateComponentConfig,
      };

      if (COMPONENTS_CONFIGS[this.fieldColumn.type]) {
        return COMPONENTS_CONFIGS[this.fieldColumn.type]();
      }

      return {};
    },
  },
  methods: {
    showTooltipIfNeeded(event) {
      const isCustomFieldWithTooltip = [
        constants.CUSTOM_COLUMNS_TYPES.text.id,
        constants.CUSTOM_COLUMNS_TYPES.resources.id,
        constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
        constants.CUSTOM_COLUMNS_TYPES.tags.id,
      ].includes(this.fieldColumn.type);

      if (isCustomFieldWithTooltip && this.fieldValue && !this.isActive) {
        const pos = event.target.getBoundingClientRectWrapper();

        if (pos.x || pos.y) {
          gantt.callEvent('showCustomColumnPreviewList', [pos, this.taskData.id, this.fieldColumn.id]);
        }
      }
    },
    hideTooltip() {
      gantt.callEvent('hideCustomColumnPreviewList');
    },
    getDateComponentConfig() {
      return {
        name: 'vgp-datepicker',
        props: {
          value: this.fieldValue,
          disabled: this.disabled,
          taskData: this.taskData,
          placeholder: __('task_settings_date_type_custom_column'),
          property: '',
          allowEmpty: true,
        },
        listeners: {
          change: this.emitChange,
        },
      };
    },
    getNumberComponentConfig() {
      return {
        name: 'vgp-input',
        props: {
          inputType: 'number',
          value: this.fieldValue || 0,
          small: true,
          border: false,
          isDisabled: this.disabled || this.taskData.type === constants.TASK_TYPES.project,
        },
        listeners: {
          onChange: event => {
            let newValue = event.target.value.replace(',', '.').trim();
            const regex = /^(\d+)?(?:\.\d*)?$/; // Regular expression to match a valid decimal number (or int)

            if (!regex.test(newValue)) {
              this.$toast.warning(__('filter_number_custom_column_not_valid'));
              this.componentKey++;

              return;
            }

            const oldValue = String(this.fieldValue);

            if (newValue === '') {
              oldValue !== '0' && this.emitChange('0');
              this.componentKey++;

              return;
            }

            newValue = newValue.replace(/^(\.)/, '0$1'); // replace .2 with 0.2

            if (newValue.includes('.')) {
              newValue = parseFloat(newValue).toFixed(2);
            } else {
              newValue = parseInt(newValue, 10);
            }

            newValue = String(newValue);

            if (newValue === oldValue) {
              this.componentKey++; // rerender to remove spaces in the end

              return;
            }

            this.emitChange(newValue);
          },
        },
      };
    },
    getResourcesComponentConfig() {
      return {
        name: 'vgp-select',
        props: {
          placeholder: __('task_settings_resources_type_custom_column'),
          dropList: this.getOptionsForResourcesMultiselect(),
          selected: this.getSelectedOptionsForResourcesMultiselect(),
          useSearch: true,
          useSelectAll: true,
          outline: 'none',
          itemTemplate: 'user',
          groupBy: 'group',
          dropPosition: 'fixed',
          parentSelectorForCalcPositionDrop: '.drop-select',
          disabled: this.disabled,
        },
        listeners: {
          selected: this.handleSelectedDropdownSelect,
          open: () => this.isActive = true,
          close: () => this.isActive = false,
        },
      };
    },
    getTagsComponentConfig() {
      return {
        name: 'vgp-select',
        props: {
          placeholder: __('task_settings_tags_type_custom_column'),
          dropList: this.fieldColumn.options,
          selected: this.getSelectedOptionsForMultiSelect(),
          useSearch: true,
          useSelectAll: true,
          trackByText: 'value',
          outline: 'none',
          itemTemplate: 'label',
          dropPosition: 'fixed',
          parentSelectorForCalcPositionDrop: '.drop-select',
          disabled: this.disabled,
        },
        listeners: {
          selected: this.handleSelectedDropdownSelect,
          open: () => this.isActive = true,
          close: () => this.isActive = false,
        },
      };
    },
    getSelectComponentConfig() {
      return {
        name: 'vgp-select',
        props: {
          placeholder: __('task_settings_select_type_custom_column'),
          dropList: this.fieldColumn.options,
          selected: this.fieldColumn.options.find(option => Number(option.id) === Number(this.fieldValue)),
          trackByText: 'value',
          outline: 'none',
          multiselect: false,
          dropPosition: 'fixed',
          parentSelectorForCalcPositionDrop: '.drop-select',
          disabled: this.disabled,
        },
        listeners: {
          selected: this.handleSelectedDropdownSelect,
        },
      };
    },
    getCheckboxComponentConfig() {
      return {
        name: 'vgp-checkbox',
        props: {
          checked: typeof this.fieldValue === 'string' ? +this.fieldValue : this.fieldValue,
          isDisable: this.disabled,
        },
        listeners: {
          onChange: value => this.emitChange(!!value),
        },
        style: {
          paddingLeft: '8px',
        },
      };
    },
    getMultiSelectComponentConfig() {
      return {
        name: 'vgp-select',
        props: {
          placeholder: __('task_settings_select_type_custom_column'),
          dropList: this.fieldColumn.options,
          selected: this.getSelectedOptionsForMultiSelect(),
          useSearch: true,
          useSelectAll: true,
          trackByText: 'value',
          outline: 'none',
          dropPosition: 'fixed',
          parentSelectorForCalcPositionDrop: '.drop-select',
          disabled: this.disabled,
        },
        listeners: {
          selected: this.handleSelectedDropdownSelect,
          open: () => this.isActive = true,
          close: () => this.isActive = false,
        },
      };
    },
    getColorComponentConfig() {
      return {
        name: 'vgp-colorPicker',
        props: {
          colors: this.getOptionsForColorPicker(),
          selectedValues: this.getSelectedOptionForColorPicker(),
          selectType: 'single',
          selectField: 'select',
          selectHeight: '32',
          selectWidth: '260px',
          showResetBtn: true,
          small: true,
          illuminateOnHover: true,
          dropPosition: 'fixed',
          border: false,
          disabled: this.disabled,
          placeholder: __('task_settings_color_type_custom_column'),
        },
        listeners: {
          selectColors: this.handleSelectColors,
        },
      };
    },
    getTextComponentConfig() {
      return {
        name: 'vgp-input',
        props: {
          placeholder: __('task_settings_text_type_custom_column'),
          small: true,
          value: this.fieldValue,
          border: false,
          isDisabled: this.disabled,
        },
        listeners: {
          onChange: event => { this.emitChange(event.target.value); },
          onFocus: () => this.isActive = true,
          onBlur: () => this.isActive = false,
        },
      };
    },
    emitChange(value) {
      this.$emit('change', { column: this.fieldColumn, value });
    },
    handleSelectColors(colors) {
      colors.length ? this.emitChange(String(colors[0].id)) : this.emitChange('');
    },
    handleSelectedDropdownSelect(items) {
      if (!items.length) {
        this.emitChange('');

        return;
      }
      const selectedIds = items.map(item => String(item.id)).join(',');

      this.emitChange(selectedIds);
    },
    getSelectedOptionsForMultiSelect() {
      if (!this.fieldValue) return;
      const selectedIds = this.fieldValue;

      return selectedIds.reduce((result, id) => {
        const foundOption = this.fieldColumn.options.find(option => option.id === id);

        if (foundOption) {
          result.push(foundOption);
        }

        return result;
      }, []);
    },
    getOptionsForColorPicker() {
      return this.fieldColumn.options.map(option => ({
        id: option.id,
        hex: option.value,
        hex2: `${option.value}CC`,
      }));
    },
    getSelectedOptionForColorPicker() {
      if (!this.fieldValue) return null;

      return this.getOptionsForColorPicker().find(option => Number(option.id) === Number(this.fieldValue));
    },
    getOptionsForResourcesMultiselect() {
      const filteredPeopleOptions = customColumns.filterPeopleOptions(this.fieldColumn.options, this.taskData.gantt_id);

      return filteredPeopleOptions.map(item => ({
        id: item.id,
        name: item.name,
        img: item.picture,
        group: item.userId ? __('resources_layout_header_title_1') : __('resources_layout_header_title_2'),
      }));
    },
    getSelectedOptionsForResourcesMultiselect() {
      if (!this.fieldValue) return;

      const selectedIds = this.fieldValue;

      return this.getOptionsForResourcesMultiselect().filter(option => selectedIds.includes(option.id));
    },
  },
};
</script>

<style module lang="less">

.custom-field__row {
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  height: 32px;
}

.custom-field__name {
  font-family: Lato-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #9E9E9E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-field__component-wrapper {
  width: 100%;
  max-width: 260px;
  display: flex;
  align-items: center;
}

</style>
