<template>
  <div>
    <webix-ui :config="kanban" />
  </div>
</template>

<script>
  import app from './../../app';
  import kanbanWebix from './main';
import store from '../../store/main'

  export default {
    name: "Kanban",
    data() {
      return {
        kanban: {
          id: kanbanWebix.webixId,
          view: "window",
          css: "kanbanWindow",
          zIndex: 19,
          unClosable: true,
          body: {
            view: "scrollview",
            scroll: "x",
            id: "kanbanScroll",
            body: {
              id: "kanbanWindowBody",
              view: "layout",
              rows: []
            }
          },
          head: false,
          position: function (state) {
            var block = $$("leftSideBar");
            var toolbar = $$("header");

            const sidebarWidth = store.getters['leftSidebarWidth'];
            const footerHeight = store.getters['footer/height']; 
            
            if (!block || !toolbar ) {
              return true;
            }
            
            var offsetTop = parseInt(toolbar.$view.style.height);
            var offsetHeight = parseInt(toolbar.$view.style.height) + footerHeight + 1;

            state.height = document.body.clientHeight - offsetHeight;
            state.width = document.body.clientWidth - sidebarWidth;
            this.config.height = state.height;
            state.left = sidebarWidth;
            state.top = offsetTop;
          },
          borderless: true,
          // header: false,
          modal: false,
          move: false,
          on: {
            "onBeforeShow": function () {
              app.trigger("popup:show", true);
            },
            "onHide": function () {
              app.trigger("popup:hide");
              app.trigger("kanban:toolbar:update", false);
              webix.callEvent("onResize", []);
            },
            "onShow": function () {
              app.trigger("kanban:toolbar:update", true);
              webix.callEvent("onResize", []);
            }
          }
        }
      }
    },
    mounted () {
      this.$nextTick(function () {
        kanbanWebix.handlers.show();
      });
    },
    beforeDestroy () {
      kanbanWebix.handlers.hide();
    }
  }
</script>
