var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$.skeleton }, [
    _c(
      "div",
      { class: _vm.$.head },
      _vm._l(_vm.table.head, function (i) {
        return _c("div", { key: i, class: _vm.$.week_day }, [
          _vm._v("\n      " + _vm._s(_vm.weekDaysLocales[i - 1]) + "\n    "),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.grid },
      _vm._l(_vm.table.grid.weekDays, function (row, rowIndex) {
        return _c(
          "div",
          { key: rowIndex, class: _vm.$.row },
          [
            _vm._l(row, function (cell, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: [
                    _vm.$.cell,
                    _vm.weekends.includes(index) ? _vm.$.weekend : "",
                  ],
                  style: { minHeight: _vm.minHeight },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.$.date,
                        cell.month !== "current" ? _vm.$.gray : "",
                      ],
                    },
                    [_vm._v("\n          " + _vm._s(cell.day) + "\n        ")]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(
              _vm.table.grid.weekEvents[rowIndex],
              function (event, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: _vm.$.event,
                    style: {
                      top: event.top + "px",
                      gridColumnStart: _vm.startOnMonday
                        ? event.start
                        : event.start + 1,
                      gridColumnEnd: _vm.startOnMonday
                        ? event.end
                        : event.end + 1,
                    },
                  },
                  [_c("div", { class: _vm.$.element })]
                )
              }
            ),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }