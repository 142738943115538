import app from '../../../app';
import _ from '../../../libs/lodash';
import templateUpload from '../../../templates/upload';
import projectComponent from '../../../components/Project';
import collaborationComponent from '../../../components/Collaboration';

import templatesModel from '../../../models/templates';
import profileModel from '../../../models/profile';
import projects from '../../../models/projects';
import customHelpers from '../../../helpers/custom';
import humanFileSize from '../../../helpers/fileSize';
import statusHelper from '../../../helpers/status';
import routerHelper from '../../../helpers/router';
import helperBrowser from '../../../helpers/browsers';
import rights from '../../../components/rights/index';

import excel_icon from '../../../svg/projects_icons/export_xlsx.svg';
import ms_proj_icon from '../../../svg/projects_icons/export_msp.svg';
import icon_delete from '../../../svg/close_delete.svg';

import importpreview from '../../import/importpreviewXLSX';

import '../../../libs/webix/custom/importXLSX';
import moment from '../../../libs/moment';
import globalStore from '../../../store/main';
import { ANALYTIC_ACTIONS } from '../../onboarding/onboarding.config';
import helperDate from '../../../helpers/dateFormats';

const __ = window.__;

// app.on('activeProject:afterSet', (ganttId, ganttData) => {
//   if (innerObject.settings.importProgress) {
//     _.delay(() => {
//       innerObject.handlers.afterCreateProject(ganttId, ganttData);
//     }, 50);
//   }
// });

var innerObject = {
  views: {
    importListDataView: {
      view: 'dataview',
      id: 'importProjectDataView',
      css: 'importProjectDataView',
      yCount: 1,
      height: 240,
      borderless: true,
      select: false,
      template(obj) {
        if (obj.empty) {
          return '';
        }

        return `${"<div class='dataview_elem'>"
          + "<div class='dataview_icon'>"}${obj.icon}</div>`
          + `<div class='dataview_label'>${obj.value}</div>`
          + '</div>';
      },
      type: {
        width: 105,
        height: 120,
      },
      data: [
        { empty: true },
        { empty: true },
        { id: 'msExcel', value: 'MS Excel', icon: excel_icon },
        { id: 'msProject', value: 'MS Project', icon: ms_proj_icon },
        { empty: true },
      ],
    },
    importUploadFileList: {
      id: 'importUploadFileList',
      view: 'list',
      css: 'new-project-tab-body-import-file-list',
      hidden: true,
      borderless: true,
      height: 240,
      data: [],
      type: {
        height: 100,
      },
      template(obj) {
        return `${"<div class='new-project-tab-body-import-file-list-item'>"
          + "<div class='new-project-tab-body-import-file-list-item-icon'>"}${ms_proj_icon}</div>`
          + `<div class='new-project-tab-body-import-file-list-item-name'>${obj.name}</div>`
          + `<div class='new-project-tab-body-import-file-list-item-size'>${humanFileSize(obj.size)}</div>`
          + `<div class='new-project-tab-body-import-file-list-item-delete'>${icon_delete}</div>`
          + '</div>';
      },
      onClick: {
        'new-project-tab-body-import-file-list-item-delete': function (event, id, node) {
          innerObject.handlers.deleteImportFile();
        },
      },
    },
    importFileUploader: {
      id: 'importFileUploader',
    },
    uploadArea: {
      id: 'importFileUploaderArea',
      view: 'template',
      height: 240,
      borderless: true,
      css: 'new-project-tab-body-import-drag-zone',
      template: templateUpload('importDragZone'),
      onClick: {
        'js-uploadLink': function (e) {
          $$('importFileUploader').fileDialog();
        },
      },
    },
    formatSupportLabel: {
      id: 'formatSupportLabel',
      view: 'label',
      css: 'new-project-tab-body-import-label',
      align: 'center',
      height: 1,
      label: __('import_supported_format'),
    },
  },
  helpers: {
    // calculateLag: function (firstDate, secondDate) {
    //   let calculatedLag = 0;
    //   let direction = 1;
    //
    //   if (moment(firstDate).isBefore(secondDate)) {
    //     direction = -1;
    //     calculatedLag = gantt.calculateDuration(firstDate, secondDate) * direction;
    //   } else {
    //     calculatedLag = gantt.calculateDuration(secondDate, firstDate) * direction;
    //   }
    //
    //   return calculatedLag;
    // }

    checkFilesAfterDrop(e) {
      e.preventDefault();
      webix.message({ type: 'warning', text: __('import_one_file') });

      return false;
    },
  },
  init: {
    run() {
      if (!innerObject.settings.initDragZone) {
        $$(innerObject.views.importFileUploader.id).addDropZone(document.getElementById(innerObject.settings.dragZoneContainerId));
        innerObject.settings.initDragZone = true;
      }
    },
    setParentMethods(setProjectNameMethod, disableEnableOptions, disableEnableButtonOk) {
      innerObject.settings.parentMethods.setProjectName = setProjectNameMethod;
      innerObject.settings.parentMethods.disableEnableOptions = disableEnableOptions;
      innerObject.settings.parentMethods.disableEnableButtonOk = disableEnableButtonOk;
    },
  },
  handlers: {
    importDataViewClick(id) {
      webix.message({ type: 'info', text: __('import_coming_soon') });
    },
    onAfterTabClick(id, e) {
      if (id === 'tabbarViewImport') {
        innerObject.init.run();

        if ($$(innerObject.views.importFileUploader.id).files.count()) {
          innerObject.settings.parentMethods.disableEnableOptions(false);
        }
      } else {
        innerObject.settings.parentMethods.disableEnableOptions(true);
      }
    },
    importProjectFromOnboardingWizardHandler(file) {
      const type = file.name.split('.').pop();
      const fileObjectForWebix = {
        file,
        name: file.name,
        type,
      };

      innerObject.settings.isImportFromOnboarding = true;
      innerObject.settings.importFile = fileObjectForWebix;

      innerObject.settings.importFileName = customHelpers.getFileNameWithoutExt(innerObject.settings.importFile.name);

      innerObject.handlers.importProjectHandler('team');
    },
    importProjectHandler(projectMode, settings) {
      innerObject.settings.mode = projectMode;
      if (innerObject.settings.importFile.type) {
        if (
          innerObject.settings.importFile.type === 'xlsx'
          || innerObject.settings.importFile.type === 'csv'
        ) {
          importpreview.handlers.setProjectType(projectMode);
          routerHelper.changeRoute('/importpreview');
        } else {
          innerObject.handlers.sendFileToWebixLibrary();
          if (!innerObject.settings.isImportFromOnboarding) {
            webix.message({
              type: 'info', text: __('import_label_notification'),
            });
          }
        }
        userExtAnalytics.log('project_import_add_file', { format: innerObject.settings.importFile.type });
      } else {
        webix.message({ type: 'warning', text: __('import_check_the_file') });
      }
    },
    createNewProjectBasedOnMppJson(data, fileName) {
      const importData = data.data;
      const mppToken = data.token;
      const importMPPGanttCalendar = {
        id: "importMPPGanttCalendar", 
        worktime: {
            hours: [8, 17],
            days: [0, 0, 0, 0, 0, 0, 0]
        }
      }

      innerObject.settings.defaultConfig = projects.getDefaultConfig();
      innerObject.settings.defaultConfig.isMpp = true;
      innerObject.settings.defaultConfig.mppToken = mppToken; // to link created project with db mpp token
      innerObject.settings.defaultConfig.auto_scheduling = false; // off auto_scheduling
      innerObject.settings.defaultConfig.show_resource_avatar = false; // off avatar
      innerObject.settings.defaultConfig.durationData.mode = 'day';
      innerObject.settings.defaultConfig.durationData.showDay = [];

      let mppDefaultcalendar = importData.calendars.find(calendar => calendar.id === importData.defaultCalendarId);


      _.each(mppDefaultcalendar.dates, (workTime, dayIndex) => {
        dayIndex = +dayIndex;

        if (dayIndex < 7 && _.isArray(workTime)) {
          // be careful with dayIndex
          // for gantt calendar dayIndex 0 - Sunday, 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday
          importMPPGanttCalendar.worktime.days[dayIndex] = 1;

          if (dayIndex === 0) {
            dayIndex = 7;
          }

          // for save it to DB we need to change dayIndex to 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday, 7 - Sunday
          innerObject.settings.defaultConfig.durationData.showDay.push(dayIndex);
        }
      });


      innerObject.settings.defaultConfig.durationData.showTime = mppDefaultcalendar.hours;
      importMPPGanttCalendar.worktime.hours = mppDefaultcalendar.hours;

      gantt.addCalendar(importMPPGanttCalendar);

      innerObject.settings.defaultConfig.duration_view = importData.config.duration_unit;
      innerObject.settings.defaultConfig.duration_unit = importData.config.duration_unit;

      innerObject.settings.importData = webix.copy(innerObject.handlers.removeRepeatingIdsFromTasksData(importData));
      innerObject.handlers.prepareTaskData();
      innerObject.settings.importProgress = true;
      innerObject.settings.ignoreCreateProject = false;

      projectComponent.addNewImportProject(
        innerObject.settings.importFileName || fileName,
        innerObject.settings.defaultConfig,
        innerObject.settings.importFile.type || fileName.split('.').at(-1),
        innerObject.settings.importData,
        mppToken,
      ).then(() => {
        if (innerObject.settings.parentMethods && innerObject.settings.parentMethods.disableEnableButtonOk) {
          innerObject.settings.parentMethods.disableEnableButtonOk(true);
        }
      });
    },
    removeRepeatingIdsFromTasksData(importData) {
      const tasksData = importData.data.data;

      if (tasksData.length === 0) {
        return importData;
      }

      let maxId = tasksData[tasksData.length - 1].id;
      const ids = [];

      _.each(tasksData, task => {
        if (_.includes(ids, task.id)) {
          task.id = ++maxId;
        }
        ids.push(task.id);
      });

      return importData;
    },

    sendFileToWebixLibrary() {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      let responseData = {};
      // msProjectServer = GT.MSProjectServer || "/ganttpro-services-dev.azurewebsites.net",

      formData.append('enctype', 'multipart/form-data');
      formData.append('file', innerObject.settings.importFile.file);

      if (!innerObject.settings.isImportFromOnboarding) {
        innerObject.settings.parentMethods.disableEnableButtonOk(false);
      }

      xhr.onload = function (data, k, n) {
        if (data.target.status === 200) {
          responseData = JSON.parse(data.target.response);

          if (!innerObject.settings.isImportFromOnboarding) {
            innerObject.handlers.showHideFileList(false);
            $$(innerObject.views.importUploadFileList.id).clearAll();
            $$(innerObject.views.importFileUploader.id).files.clearAll();
          }

          if (responseData.error) {
            webix.message({ type: 'error', text: responseData.error });
            if (!innerObject.settings.isImportFromOnboarding) {
              innerObject.settings.parentMethods.disableEnableButtonOk(true);
            }
            innerObject.handlers.clearData();

            return;
          }

          const webixMessageNode = document.querySelector('.global-notification_info');

          if (webixMessageNode) {
            webixMessageNode.style.display = 'none';
          }

          // app.trigger('gantt:progress:show');
          innerObject.handlers.createNewProjectBasedOnMppJson(responseData);
          if (innerObject.settings.isImportFromOnboarding) {
            if (rights.account.isOwner()) {
              userExtAnalytics.log('onboarding', {
                type: globalStore.state.onboarding.analyticType,
                step: globalStore.state.onboarding.activeStepId,
                action: ANALYTIC_ACTIONS.IMPORT_FINISHED,
              });
            }
          }

          userExtAnalytics.log('project_create_done', {
            type: 'import',
            'import format': innerObject.settings.importFile.type,
          });
        }
      };

      xhr.onerror = function (data, k, n) {
        webix.message({ type: 'warning', text: __('import_error_parse') });
        innerObject.handlers.clearData();
      };

      xhr.open('POST', '/api/mpp/');
      xhr.send(formData);
    },
    afterCreateProject(ganttId, ganttData) {
      innerObject.settings.insertIndex = -1;
      innerObject.settings.lastInsertId = '0';

      innerObject.handlers.prepareTaskData();

      if (innerObject.settings.stopImport) {
        webix.message({ type: 'error', text: __('invalid_dates_format_import_file') });
        statusHelper.handlers.hideMainSpinner();
        innerObject.settings.importProgress = false;

        return;
      }

      if (!innerObject.settings.importProgress) {
        return;
      }

      innerObject.settings.importProgress = false;
      webix.ajax()
        .post('/api/import/mpp/', {
          data: JSON.stringify(innerObject.settings.importData),
          ganttId,
          name: innerObject.settings.importFileName,
        })
        .then(response => {
          innerObject.settings.afterImport = true;
          innerObject.settings.importProgress = false;
        })
        .fail(err => {
          webix.message({ type: 'error', text: err });
          statusHelper.handlers.hideMainSpinner();
        });
    },
    prepareTaskData() {
      const preparedTasks = [];
      const tasksImportData = innerObject.settings.importData.data.data;

      tasksImportData.forEach(importTask => {
        // if(additionalSubprojectRequired && importTask.parent === '0' && importTask.id !== additionalSubprojectTempId){
        //   importTask.parent = additionalSubprojectTempId;
        // }
        
        let startDate = moment(importTask.start_date).toDate();

        if (_.isNaN(+startDate) || +startDate < +moment(0).toDate() || innerObject.settings.stopImport) {
          innerObject.settings.stopImport = true;

          return;
        }
        if (innerObject.settings.importData.config.duration_unit === 'hour') {
          importTask.duration = +importTask.duration * 60;
        }

        if (innerObject.settings.importData.config.duration_unit === 'day') {
          const workingHours = gantt.getDefaultWorkHoursPerDay(innerObject.settings.defaultConfig.durationData.showTime, helperDate.getHoursArrayByUserTimeFormat());

          importTask.duration = +importTask.duration * 60 * workingHours;
        }

        let endDate = gantt.getCalendar('importMPPGanttCalendar').calculateEndDate({
          start_date: startDate,
          duration: importTask.duration,
          unit: 'minute',
        });

        endDate = gantt.getCalendar('importMPPGanttCalendar').getClosestWorkTime({
          date: endDate,
          dir: "past",
          unit: 'minute',
        });

        // if (innerObject.settings.importData.config.duration_unit === 'day' && importTask.duration < 1) {
        //   endDate = gantt.getCalendar('importMPPGanttCalendar').calculateEndDate({
        //     start_date: startDate,
        //     duration: gantt.getDefaultWorkHoursPerDay(innerObject.settings.defaultConfig.durationData.showTime) * importTask.duration,
        //     unit: 'minute'
        //   });
        // }

        // if (innerObject.settings.importData.config.duration_unit !== 'hour') {
        //   const newEnd = gantt.getCalendar('importMPPGanttCalendar').getClosestWorkTime({
        //     date: new Date(endDate - 1),
        //     dir: 'past',
        //     unit: 'minute',
        //   });

        //   if (gantt.getCalendar('importMPPGanttCalendar').calculateDuration(newEnd, endDate) === 0 && endDate - newEnd > 2) {
        //     endDate = newEnd;
        //   }
        // }

        const isParent = _.find(tasksImportData, task => task.parent === importTask.id);

        importTask.end_date = moment(endDate).format('YYYY-MM-DD HH:mm');

        if (importTask.$custom_data) {
          const customDataToTaskProperty = {
            Work: 'estimation',
          };

          _.each(customDataToTaskProperty, (taskProperty, importProperty) => {
            if (importTask.$custom_data[importProperty]) {
              if (importProperty === 'Work') {
                if (innerObject.settings.importData.config.duration_unit === 'day' && innerObject.settings.importFile.type === 'xml') {
                  const workHours = gantt.getDefaultWorkHoursPerDay(innerObject.settings.defaultConfig.durationData.showTime, helperDate.getHoursArrayByUserTimeFormat());

                  importTask[taskProperty] = workHours * importTask.$custom_data[importProperty];
                } else {
                  importTask[taskProperty] = +importTask.$custom_data[importProperty];
                }
              }
            }
          });
        }

        if (importTask.duration == '0' && !isParent) {
          importTask.type = 'milestone';
        }
      });

      tasksImportData.forEach(task => {
        task.text = task.text || __('gantt_grid_text');
        task.text = task.text.replace(/(<|>)+/ig, '');
        task.note = _.escape(task.note);
      });

      const buildTree = function (task) {
        const parent = tasksImportData.find(t => t.id === task.parent);

        if (!parent) {
          preparedTasks.push(task);
        }

        if (task.children === undefined) {
          const children = tasksImportData.filter(t => t.parent === task.id);

          children.forEach(buildTree);

          task.children = children;
        }
      };

      tasksImportData.forEach(task => {
        buildTree(task);
      });

      tasksImportData.forEach(task => {
        task.parent = null;
      });

      innerObject.settings.importData.data.data = preparedTasks;
    },
    setProjectName(projectName) {
      innerObject.settings.importFileName = projectName;
      importpreview.handlers.setFileName(projectName);
    },
    deleteImportFile() {
      $$(innerObject.views.importUploadFileList.id).clearAll();
      $$(innerObject.views.importFileUploader.id).files.clearAll();
      innerObject.handlers.showHideFileList(false);
      // $$(innerObject.views.importListDataView.id).unselectAll();
      if (innerObject.settings.importFile.name
        === (`${innerObject.settings.importFileName}.${innerObject.settings.importFile.type}`)) {
        innerObject.settings.parentMethods.setProjectName('', true);
      }
      innerObject.settings.importFile = {};
    },
    showHideFileList(status) {
      if (status) {
        $$(innerObject.views.importUploadFileList.id).show();
        $$(innerObject.views.uploadArea.id).hide();
      } else {
        $$(innerObject.views.importUploadFileList.id).hide();
        $$(innerObject.views.uploadArea.id).show();
        $$(innerObject.views.importFileUploader.id).addDropZone(
          document.getElementById(innerObject.settings.dragZoneContainerId),
        );
        const fileListNode = $$('importUploadFileList').$view;

        fileListNode.removeEventListener('drop', innerObject.helpers.checkFilesAfterDrop);
      }
    },
    sendFileToAzure(file, type) {
      const xhr = new XMLHttpRequest();
      const formData = new FormData();

      formData.append('enctype', 'multipart/form-data');
      formData.append(`${type}File[]`, file);
      xhr.open('POST', '/api/mpp');
      xhr.send(formData);
    },

  },
  //
  settings: {
    dragZoneContainerId: 'importDragZone',
    initDragZone: false,
    parentMethods: {},
    importProgress: false,
    importFileName: '',
    projectId: 0,
    tabId: 0,
    importData: {},
    importFile: {},
    mode: '',
    onAfterTaskAddEventID: '',
    isImportFromOnboarding: false,
  },
};

webix.ui({
  view: 'uploader',
  id: 'importFileUploader',
  apiOnly: true,
  autosend: false,
  multiple: false,
  on: {
    onBeforeFileAdd(file) {
      if (!$$(innerObject.views.importFileUploader.id).files.count()) {
        innerObject.settings.importFile = {};

        if (file.type === 'mpp' || file.type === 'xml') {
          innerObject.settings.importFile = file;
        } else if (file.type === 'mpx') {
          webix.message({ type: 'warning', text: __('import_msg_msProject2016') });

          return false;
        } else if (file.type === 'xlsx') {
          if (helperBrowser.isIE11()) {
            webix.message({ type: 'info', text: __('import_xlsx_ie11_potracheno') });

            return false;
          }
          excelParser.callEvent('onChange', [[file]]);
          innerObject.settings.importFile = file;
        } else if (file.type === 'csv') {
          const reader = new FileReader();

          reader.onload = function (e) {
            importpreview.handlers.setFileData(e.target.result,
              innerObject.settings.importFile.type,
              innerObject.settings.importFileName);
          };

          reader.readAsText(file.file, 'CP1251');
          innerObject.settings.importFile = file;
        } else if (file.type === 'ical') {
          webix.message({ type: 'info', text: __('import_coming_soon') });

          return false;
        } else {
          webix.message({ type: 'warning', text: __('import_check_the_file') });

          return false;
        }
      } else {
        webix.message({ type: 'warning', text: __('import_one_file') });

        return false;
      }

      innerObject.settings.parentMethods.disableEnableButtonOk(false);
    },
    onAfterFileAdd(file) {
      innerObject.settings.importFileName = customHelpers.getFileNameWithoutExt(innerObject.settings.importFile.name);

      innerObject.settings.importFileName = innerObject.settings.parentMethods.setProjectName(innerObject.settings.importFileName);

      innerObject.settings.parentMethods.disableEnableOptions(false);

      $$(innerObject.views.importUploadFileList.id).add(innerObject.settings.importFile);
      innerObject.handlers.showHideFileList(true);

      if (file.size > 100000) {
        setTimeout(() => {
          innerObject.settings.parentMethods.disableEnableButtonOk(true);
        }, 1000);
      } else {
        innerObject.settings.parentMethods.disableEnableButtonOk(true);
      }

      if ($$(innerObject.views.importFileUploader.id).files.count()) {
        const fileListNode = $$('importUploadFileList').$view;

        fileListNode.addEventListener('drop', innerObject.helpers.checkFilesAfterDrop);
      }
    },
  },
});

var excelParser = webix.ui({
  view: 'excelparser',
  hidden: true,
  on: {
    onParse(data) {
      if (data === '') {
        webix.alert(__('import_file_empty'));
        innerObject.handlers.deleteImportFile();
      } else {
        importpreview.handlers.setFileData(
          data,
          innerObject.settings.importFile.type,
          innerObject.settings.importFileName,
        );
      }
    },
    onAbort() {
      innerObject.handlers.deleteImportFile();
    },
  },
});

gantt.attachEvent('onParse', () => {
  if (innerObject.settings.afterImport) {
    innerObject.settings.afterImport = false;

    app.trigger('import:updateLinkLag');
  }
});

const outputObject = {
  webixUI: {
    id: 'tabbarViewImport',
    css: 'uploadFileArea',
    rows: [
      { height: 24 },
      innerObject.views.uploadArea,
      innerObject.views.importUploadFileList,
      innerObject.views.formatSupportLabel,
      { height: 30 }, // label had 1px height
      // innerObject.views.importListDataView,
      // { height: 10 }
    ],
  },
  onAfterTabClick: innerObject.handlers.onAfterTabClick,
  newProjectByImport: innerObject.handlers.importProjectHandler,
  setDependency: innerObject.init.setParentMethods,
  setProjectName: innerObject.handlers.setProjectName,
  deleteImportFile: innerObject.handlers.deleteImportFile,
  createNewProjectBasedOnMppJson: innerObject.handlers.createNewProjectBasedOnMppJson,
  newProjectByImportFromOnboardingWizard: innerObject.handlers.importProjectFromOnboardingWizardHandler,
};

app.on('import:progress:mpp', ganttId => {
  collaborationComponent.updateProjectDataAfterImport(ganttId).then(() => {
    statusHelper.handlers.hideMainSpinner();
    app.trigger('gantt:progress:hide', true);
    innerObject.settings.importProgress = false;
  });
});

export default outputObject;
