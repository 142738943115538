import app from '../../../app';
import routerHelper from '../../../helpers/router';

import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import ganttViewModel from '../../../models/ganttViewModel';
import projectsModel from '../../../models/projects';
import customColumns from '../../gantt/modules/userCustomColumns';
import Store from '../../../store/main';

import icon_resource from '../../../svg/default/default-ava.svg';

const locale = {
  realResource: __('resources_layout_header_title_1'),
  virtualResource: __('resources_layout_header_title_2'),
};

class CustomColumnsFilter {
  constructor() {
    this.ganttIDs = null;
    this.columns = null;
  }

  init() {
    this.initGanttIds();
    this.loadColumnsByGanttIDs(); 
  }

  update() {
    this.initGanttIds();
    this.loadColumnsByGanttIDs();
  }
 
  initGanttIds() {
    let ganttIDs = [];
    if (routerHelper.getCurrentRoute().params.projectId) {
      ganttIDs = [routerHelper.getCurrentRoute().params.projectId];
    }

    if (routerHelper.isMultiProjectsRoute()) {
      ganttIDs = multiViewsProjectsModel.getActiveViewData().ganttIDs;
    }

    if (routerHelper.isWorkloadViewRoute()) {
      if (!ganttIDs.length) {
        ganttIDs = projectsModel.getAllProjects().map(o => o.gantt_id);
      }
    }

    if (routerHelper.isListViewRoute()) {
      ganttIDs = projectsModel.getAllProjects().map(o => o.gantt_id);
    }

    if (!app.config.mode.isBase) {
      const projectData = ganttViewModel.getActiveViewData();
      ganttIDs = projectData.ganttIDs || [projectData.gantt_id];
    }
    this.ganttIDs = ganttIDs;
  }

  loadColumnsByGanttIDs() {
    const columns = [];

    this.ganttIDs.forEach(ganttID => {
      const projectColumnIds = Store.getters['columns/getColumnIdsByProjectId'](ganttID);

      projectColumnIds.forEach(columnId => {
        const isAdded = columns.find(item => item.id === columnId);

        if (isAdded) return;

        const column = Store.getters['columns/getCustomColumnById'](columnId);

        if (column) columns.push(column);
      });
    });

    this.columns = this.prepareCollumns(columns);
  }

  prepareCollumns(columns) {
    return columns.map(col => {
      const item = {
        typeId: col.type,
        id: col.id,
        label: col.name,
        data: this.prepareOptions(col.options, col.type),
      };
      return { ...item, ...this.getTemplate[col.type] };
    });
  }

  get getTemplate() {
    return {
      1: { itemTemplate: 'text', type: 'search-multiselect' },
      2: { itemTemplate: '', type: 'text-input' },
      3: { itemTemplate: '', type: 'from-to-number' },
      4: { itemTemplate: '', type: 'from-to-date' },
      5: { itemTemplate: 'text', type: 'single-select' }, // checkbox
      6: { itemTemplate: 'color', type: 'multiselect' },
      7: { itemTemplate: 'text', type: 'search-multiselect' },
      8: { itemTemplate: 'icon+text', type: 'search-multiselect' },
      9: { itemTemplate: 'label', type: 'search-multiselect' },
    };
  }

  prepareOptions(options, typeId) {
    if (typeId === 5) {
      return [
        {
          id: '1',
          value: __('filter_checkbox_type_custom_column_off'),
        },
        {
          id: '2',
          value: __('filter_checkbox_type_custom_column_on'),
        },
      ];
    }
    if (!options && !options.length) return;

    if (typeId === 8) {
      options = customColumns.filterPeopleOptions(options);
      options.sort((a, b) => {
        if (!a.userId) return 1;
        else return -1;
      })
    }

    return options.map(item => {
      const opt = {
        id: item.id,
        color: item.color ? item.color : null,
        icon: item.picture || icon_resource,
        value: item.value,
      };
      if (typeId === 8) {
        opt.group = item.userId ? locale.realResource : locale.virtualResource;
      }
      if (typeId === 6) {
        opt.color = item.value;
      }
      return opt;
    });
  }
}

export default CustomColumnsFilter;
