<template>
  <div
    ref="taskNameContainer"
    class="cell-task-name select_none"
    :class="{'crossout': crossout && !editMode }"
    @click.self="emitShowSettings"
  >
    <span
      :class="{
        edit: editMode,
        'not_allowed': !editable,
        ['tooltip-gantt']: isEllipsied,
        'editable': canEdit && editable,
      }"

      data-y="-5"
      :data-html="(isEllipsied && !editMode) ? true : ''"
      @click="clickToEditTaskName"
    >
      <div
        :class="{'overdue': overdue}"
        :style="{opacity: cellOpecity}"
      >
        {{ taskName }}
      </div>

      <div class="input-container">
        <input
          ref="taskNameEditInput"
          v-model="inputValue"
          :maxlength="250"
          @blur="inputBlur"
          @keyup.esc="closeEdit"
          @keyup.enter="saveTaskName"
          @focus="$event.target.select()"
        >
        <sprite
          name="enter-2"
          class="enter-arrow-icon"
          @click.stop=""
          @mousedown.stop="submitButtonMouseDown"
          @mouseup.stop="submitButtonMouseUp"
        />
      </div>

      <div
        v-if="isEllipsied"
        class="tooltip-gantt-html"
      >
        <div
          class="tooltip-gantt-html-title"
        >
          {{ taskName }}
        </div>
      </div>
    </span>
    <div
      v-if="canShowSettings && !editMode"
      class="task-name-cell-icon-container  show-only-row-hover ai-center"
      :class="{ edit: editMode }"
    >
      <div
        class="gantt_notification_tooltip tooltip-gantt ai-center"
        data-html="true"
        :data-id="item.id"
      >
        <sprite
          name="info"
          :size="20"
          class="add-info-icons"
          @click="emitShowSettings"
        />
        <div
          class="tooltip-gantt-html"
          @click="$emit('show:settings')"
        >
          <div class="tooltip-gantt-html-title">
            {{ taskName }}
          </div>
          <div
            v-if="item.note"
            class="tooltip-gantt-html-description ql-snow ql-editor"
            v-html="item.note"
          />
          <div :class="'tooltip_gantt_show_more'">
            <a>{{ locale.more }}</a>
          </div>
        </div>
      </div>

      <sprite
        name="open"
        :size="20"
        class="add-info-icons"
        @click="emitShowSettings"
      />
      <sprite
        v-if="canDeleteTask"
        name="delete"
        :size="20"
        class="add-info-icons"
        @click.stop="$emit('delete', $event)"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import rights from '../../../../components/rights';
import sprite from '../icons/sprite.vue';

function isTaskNameEllipsied(taskNameContainer) {
  const actualContentSize = taskNameContainer.clientWidth - 20 - 80; // 20px - horizontal padding, 80px - icon block size
  const fullContentSize = taskNameContainer.childNodes[0].scrollWidth;

  return actualContentSize < fullContentSize;
}

const observer = new ResizeObserver(debounce(entries => {
  entries.forEach(({ target }) => {
    if (target && target.__vue__) {
      target.__vue__.isEllipsied = isTaskNameEllipsied(target);
    }
  });
}, 200));

export default {
  name: 'TaskCell',
  components: { sprite },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    value: { type: [String, Number], required: true },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
    overdue: { type: Boolean, required: false, default: false },
    canShowSettings: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      locale: {
        more: __('gantt_tooltip_show_more'),
      },
      editMode: false,
      isEllipsied: false,
      inputValue: '',
      ignoreInputBlur: false,
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      return this.editMode ? 1 : val;
    },
    canDeleteTask() {
      return rights.project.hasRight(this.item.gantt_id, 'delete_task');
    },

    canEdit() {
      return this.canShowSettings;
    },
    taskName() {
      const name = this.value || '';
      const valid_name = name.replace('<br/>', ' ');

      return valid_name;
    },
  },
  mounted() {
    observer.observe(this.$refs.taskNameContainer);
  },
  methods: {
    emitShowSettings(e) {
      this.$emit('show:settings');
    },
    clickToEditTaskName() {
      if (this.editable) {
        if (!this.canEdit) {
          this.emitShowSettings();

          return;
        }

        this.editMode = true;
        this.inputValue = this.taskName;
        this.$nextTick(() => this.$refs.taskNameEditInput.focus());
        this.$emit('show:task-name-editor', this.item.id);
      }
    },
    saveTaskName() {
      if (!!this.inputValue.trim() && this.inputValue && this.inputValue !== this.taskName) { this.$emit('change', this.inputValue.trim()); }
      this.closeEdit();
    },
    closeEdit() {
      this.inputValue = this.taskName;
      this.$emit('show:task-name-editor', null);
      this.editMode = false;
    },
    inputBlur() {
      if (this.ignoreInputBlur) {
        this.ignoreInputBlur = false;
      } else {
        this.saveTaskName();
      }
    },
    submitButtonMouseDown() {
      this.ignoreInputBlur = true;
    },
    submitButtonMouseUp() {
      this.ignoreInputBlur = false;
      this.saveTaskName();
    },
  },
};
</script>

<style scoped lang="less">
  .cell-task-name{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 10px;
    height: 100%;

    &.not_allowed {
      cursor: not-allowed;
    }

    span{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border: 1px solid transparent;
      border-radius: 2px;
      height: 21px;
      padding: 2px 8px 2px 4px;
      line-height: 16px;
      margin-left: -5px;
      position: relative;
      cursor: pointer;

      &.not_allowed {
        cursor: not-allowed;
      }

      &:hover.editable{
        cursor: text;
        border-color: #C4C4C4;
        background-color: white;
      }

      &.edit {
        .input-container {
          display: flex;
          align-items: center;
          padding-right: 4px;
        }
        display: flex;
        flex-grow: 1;
        border-color: #66A5DC;
      }

      .input-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        display: none;

        input {
          width: 100%;
          height: 100%;
          line-height: 16px;
          padding: 2px 20px 2px 4px;
        }
      }

    }

    .add-info-icons{
      color: #B2B2B2;
      transition: color 0.3;
      &:hover{
        color: #808080;
      }
    }
  }

  .ai-center{
    display: flex;
    align-items: center;
  }

  .settings-opened .cell-task-name>span:hover {
    cursor: pointer;
    border-color: transparent;
    background-color: unset;
  }

  .task-name-cell-icon-container {
    height: 100%;
    .vue-icon {
      width: 24px;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 0.73;
      &:not(:last-child) {
        margin-right: 4px;
      }
      &:hover {
        opacity: 1;
      }
    }
    &.edit {
      display: none !important;
    }
  }

  .enter-arrow-icon {
    cursor: pointer;
    color: #b2b2b2;
    &:hover{
      color: #808080;
    }
  }

  .select_none{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .discolor{
    opacity: 0.5;
  }

.crossout{
  text-decoration: line-through;
	// text-decoration-color: red;
}

.overdue{
  color: #B51A0A;
}
</style>
