export default function(svg, width, height, styles) {
  return {
    view: "template",
    isReactive: true,
    css: {"background-color":"transparent !important"},
    template: svg,
    width,
    height,
    borderless: true
  };
}