function getByPeriodName(period){
    const results = {};

    switch (period) {
    case 'today':
    results.start = webix.Date.datePart(new Date());
    results.end = new Date(
        webix.Date.datePart(webix.Date.add(new Date(), 1, 'day')) - 1,
    );
    break;
    case 'yesterday':
    results.start = webix.Date.datePart(
        webix.Date.add(new Date(), -1, 'day'),
    );
    results.end = new Date(webix.Date.datePart(new Date()) - 1);
    break;
    case 'this_week':
    results.start = webix.Date.weekStart(new Date());
    results.end = new Date(
        webix.Date.weekStart(webix.Date.add(new Date(), 1, 'week')) - 1,
    );
    break;
    case 'last_week':
    results.start = webix.Date.weekStart(
        webix.Date.add(new Date(), -1, 'week'),
    );
    results.end = new Date(
        webix.Date.add(webix.Date.copy(results.start), 1, 'week') - 1,
    );
    break;
    case 'this_month':
    results.start = webix.Date.monthStart(new Date());
    results.end = new Date(
        webix.Date.monthStart(webix.Date.add(new Date(results.start), 1, 'month')) - 1,
    );
    break;
    case 'last_month':
    results.start = webix.Date.monthStart(
        webix.Date.add(new Date(), -1, 'month'),
    );
    results.end = new Date(
        webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1,
    );
    break;
    case 'this_month_workload':
    results.start = webix.Date.monthStart(new Date());
    results.end = new Date(
        webix.Date.monthStart(webix.Date.add(new Date(results.start), 1, 'month')) - 1,
    );
    break;
    case 'last_month_workload':
    results.start = webix.Date.monthStart(
        webix.Date.add(new Date(), -1, 'month'),
    );
    results.end = new Date(
        webix.Date.add(webix.Date.copy(results.start), 1, 'month') - 1,
    );
    break;
    case 'twelve_months_workload':
    results.start = webix.Date.add(new Date(), -4, 'month');
    results.end = webix.Date.add(new Date(), 8, 'month');
    break;
    case 'six_months_workload':
    results.start = webix.Date.add(new Date(), -2, 'month');
    results.end = webix.Date.add(new Date(), 4, 'month');
    break;
    case 'three_months_workload':
    results.start = webix.Date.add(new Date(), -1, 'month');
    results.end = webix.Date.add(new Date(), 2, 'month');
    break;
    default:
    console.error('can not find period alias');
    }
    return results;
}

export default {
    getByPeriodName
  };