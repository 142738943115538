<template>
  <div class="sso-form">
    <input-with-button
      :input-placeholder="locales('sso_placeholder_domain')"
      :button-text="locales('sso_btn_domain')"
      :get-value="addSsoDomain"
      :styles="'margin-bottom: 18px'"
      :is-error="ssoConfig.isError"
      :error-text="locales('sso_error_input_validation_domain')"
      :on-input="onInputDomain"
    />
    <div
      v-if="ssoConfig.domains && ssoConfig.domains.length"
      class="list-container margin-bottom"
    >
      <div class="label">
        {{ locales('sso_list_title_domains') }}
      </div>
      <div class="list">
        <div
          v-for="item in ssoConfig.domains"
          :key="item.id"
          class="list-item"
        >
          <div :class="['text', isCopied ? 'copied' : '']">
            {{ item.domain }}
          </div>
          <div class="btns">
            <div
              class="delete-btn"
              @click="deleteDomainClick(item.id)"
            >
              <webix-ui
                :config="icDelete"
                class="delete-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-input
      :css="'sso-form-input'"
      :label="locales('sso_label_identity_provider_single_signOn_url')"
      :placeholder="locales('sso_placeholder_identity_provider')"
      :handle-change="checkSignOnUrl"
      :value="ssoConfig.ssoUrl"
    />
    <v-input
      :css="'sso-form-input'"
      :label="locales('sso_label_issuer')"
      :placeholder="locales('sso_placeholder_issuer')"
      :handle-change="checkIssuerHandle"
      :value="ssoConfig.issuer"
    />
    <v-text-area
      :label="locales('sso_label_sertificate')"
      :placeholder="locales('sso_placeholder_certificate')"
      :styles="'height: 110px; resize: none'"
      :handle-change="checkCertificateHandle"
      :value="ssoConfig.certificate"
      :is-check="isForceAllSsoCheckbox"
    />
    <div>
      <div
        ref="ssoCheckbox"
        :class="['checkbox-row', ssoConfig.disableAllSsoCheckbox ? 'tooltip-gantt disable' : '']"
        :data-key="locales('sso_tooltip_force_all')"
        :data-position="'top'"
        :data-x="tooltipPosition"
        :data-y="-3"
        @click="changeAllSso(ssoConfig.checkboxValue, ssoConfig.disableAllSsoCheckbox)"
      >
        <vgp-checkbox
          :checked="ssoConfig.checkboxValue"
          :is-disable="ssoConfig.disableAllSsoCheckbox"
          @onChange="changeAllSso(ssoConfig.checkboxValue, ssoConfig.disableAllSsoCheckbox)"
        />
        <div class="label">
          {{ locales('sso_label_force_all_team_members') }}
        </div>
      </div>
    </div>
    <div
      v-if="ssoConfig.checkboxValue"
      class="sso-excluded-emails"
    >
      <div
        class="label"
        :style="{marginBottom: 12 + 'px'}"
        v-html="locales('sso_description_force_all_team_members')"
      />
      <input-with-button
        :input-placeholder="locales('sso_placeholder_excluded')"
        :button-text="locales('sso_btn_excluded')"
        :get-value="addSsoExcludedEmailClick"
        :styles="'margin-bottom: 18px'"
        :is-error="ssoConfig.isExcludedEmailError"
        :error-text="locales('sso_error_input_validation_excluded_emails')"
        :tooltip-key="locales('sso_tooltip_excluded_email_btn')"
        :on-input="onInputExclude"
      />
      <div
        v-if="ssoConfig.excludedEmails && ssoConfig.excludedEmails.length"
        class="list-container margin-bottom"
      >
        <div class="label">
          {{ locales('sso_list_title_excluded') }}
        </div>
        <div class="list">
          <div
            v-for="item in ssoConfig.excludedEmails"
            :key="item.id"
            class="list-item"
          >
            <div class="text">
              {{ item.email }}
            </div>
            <div class="btns">
              <div
                class="delete-btn"
                @click="deleteExcludedEmailClick(item.id)"
              >
                <webix-ui
                  :config="icDelete"
                  class="delete-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="main-btn gray"
      @click="hideSSOForm"
    >
      {{ locales('sso_btn_deactivate') }}
    </button>
  </div>
</template>

<script>
import inputWithButton from '../../common/VueComponents/inputWithButton/inputWithButton.vue';
import vTextArea from '../../common/VueComponents/vInput/vTextArea.vue';
import vInput from '../../common/VueComponents/vInput/vInput.vue';
import icDelete from '../../../svg/ic_basket_normal.svg';

import svgIconForVue from '../../common/svgIconForVue';

export default {
  components: {
    inputWithButton,
    vInput,
    vTextArea,
  },
  props: [
    'hideSSOForm',
    'addSsoDomain',
    'deleteDomain',
    'ssoConfig',
    'checkSsoUrl',
    'checkIssuer',
    'checkCertificate',
    'changeAllSso',
    'addSsoExcludedEmail',
    'deleteExcludedEmail',
  ],
  data() {
    return {
      locales: __,
      icDelete: svgIconForVue(icDelete, 36, 36),
      tooltipPosition: '',
    };
  },
  mounted() {
    const ssoChekbox = this.$refs.ssoCheckbox;

    this.tooltipPosition = ((ssoChekbox.clientWidth / 2) - 11) * -1;
  },
  methods: {
    deleteDomainClick(id) {
      this.deleteDomain(id);
    },
    checkSignOnUrl(val) {
      this.checkSsoUrl(val);
    },
    checkIssuerHandle(val) {
      this.checkIssuer(val);
    },
    checkCertificateHandle(val) {
      this.checkCertificate(val);
    },
    addSsoExcludedEmailClick(val) {
      this.addSsoExcludedEmail(val);
    },
    deleteExcludedEmailClick(id) {
      this.deleteExcludedEmail(id);
    },
    onInputDomain() {
      this.ssoConfig.isError = false;
    },
    onInputExclude() {
      this.ssoConfig.isExcludedEmailError = false;
    },
  },
};
</script>

<style scoped src="../less/profileSettings.less"></style>
