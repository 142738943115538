<template>
  <div class="linear-progress">
    <div
      class="indicator"
      :style="indicatorWidth"
    />
  </div>
</template>

<script>
export default {
  name: 'LinearIndicator',
  props: {
    value: { type: Number, required: true, defaul: 0 },
  },
  data() {
    return {

    };
  },
  computed: {
    indicatorWidth() {
      return {
        width: `${this.value}%`,
      };
    },
  },
};
</script>

<style scoped lang='less'>
  .linear-progress {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(242, 242, 242, 1);

    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(21, 101, 192, 1);
    }
  }
</style>
