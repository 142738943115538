<template>
  <div
    class="spinner-container"
    :style="spinnerSize"
  >
    <div class="circle loader" />
    <div class="circle circle1" />
    <div class="circle circle2" />
  </div>
</template>

<script>
export default {
  name: 'CircleIndicator',
  props: {
    size: { type: Number, default: 24 },
  },
  data() {
    return {};
  },
  computed: {
    spinnerSize() {
      return {
        width: `${this.size * 2}px`,
        height: `${this.size * 2}px`,
      };
    },
  },
};
</script>

<style scoped lang='less'>
.spinner-container {
  position: relative;

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    &.circle1 {
      border: 4px solid #E5E5E5;
      animation-name: pulse1;
    }

    &.circle2 {
      border: 4px solid #E5E5E5;
      animation-name: pulse2;
    }

    &.loader {
      border: 2px solid;
      border-color: transparent transparent #1565C0 transparent;
      z-index: 2;
      animation: rotate .5s linear infinite;
    }
  }
}

@keyframes pulse1 {
  0%, 100% {
      transform: scale(1);
      background: transparent;
  }
  50% {
      transform: scale(0.1);
      background: #E5E5E5;
  }
}

@keyframes pulse2 {
  0%, 100% {
      transform: scale(0.1);
      background: #E5E5E5;
  }
  50% {
      transform: scale(1);
      background: transparent;
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
</style>
