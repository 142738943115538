var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c("core-popup", {
            attrs: {
              title: !!_vm.settingsSync
                ? _vm.locale(
                    _vm.integrationName + "_integration_settings_up_header"
                  )
                : _vm.locale(_vm.integrationName + "_integration_popup_header"),
              show: _vm.show,
              width: 382,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.syncAlreadyCreated
                            ? _c(
                                "div",
                                { class: _vm.$.integration_warning_message },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.$.integration_warning_message_icon,
                                    },
                                    [
                                      _c("svg-sprite", {
                                        attrs: {
                                          name: "warning-1",
                                          size: 20,
                                          type: "bold",
                                          color: "#FF9A00",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class:
                                        _vm.$.integration_warning_message_text,
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.locale(
                                              "integration_warning_message"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.options, function (option) {
                            return _c("div", { class: _vm.$.options }, [
                              _c(
                                "div",
                                { class: _vm.$.radioOption },
                                [
                                  _c("vgp-radio-button", {
                                    attrs: {
                                      checked: option.selected,
                                      label: _vm.locale(
                                        "integration_option_" + option.id
                                      ),
                                    },
                                    on: {
                                      onChange: function ($event) {
                                        return _vm.selectOption($event, option)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  option.info
                                    ? _c(
                                        "div",
                                        { class: _vm.$.labelWrapper },
                                        [
                                          _c("tooltip", {
                                            attrs: {
                                              content: _vm.locale(
                                                "integration_option_" +
                                                  option.info +
                                                  "_desc"
                                              ),
                                              position: "right",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "body",
                                                  fn: function () {
                                                    return [
                                                      _c("svg-sprite", {
                                                        attrs: {
                                                          color: "#B2B2B2",
                                                          name: "info",
                                                          size: 20,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: _vm.$.sync_dropdowns_container },
                            [
                              _c("vgp-label-slot", {
                                class: _vm.$.sync_dropdown,
                                attrs: {
                                  label: _vm.locale(
                                    "integration_project_to_sync_label"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "body",
                                      fn: function () {
                                        return [
                                          _c("dropdown-select", {
                                            attrs: {
                                              "drop-list": _vm.projects,
                                              placeholder: _vm.locale(
                                                "integration_project_dropdown_placeholder"
                                              ),
                                              "no-matches-message": _vm.locale(
                                                "integration_project_dropdown_no_matches"
                                              ),
                                              selected: _vm.selectedProject,
                                              multiselect: false,
                                              "use-search": true,
                                              "track-by": "id",
                                              "item-template": "text",
                                              "track-by-text": "name",
                                              "show-selected-first": true,
                                              "show-reset-btn": false,
                                              disabled: _vm.disableProjectDrop,
                                            },
                                            on: {
                                              change:
                                                _vm.onChangeProjectSelected,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2297313827
                                ),
                              }),
                              _vm._v(" "),
                              _c("vgp-label-slot", {
                                class: _vm.$.sync_dropdown,
                                attrs: {
                                  label: _vm.locale(
                                    "integration_sync_options_label"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "body",
                                      fn: function () {
                                        return [
                                          _c("dropdown-select", {
                                            attrs: {
                                              "drop-list": _vm.syncOptions,
                                              placeholder: _vm.locale(
                                                "integration_sync_options_dropdown_placeholder"
                                              ),
                                              selected: _vm.selectedSyncOption,
                                              "track-by-img-url": "icon",
                                              multiselect: false,
                                              "use-search": false,
                                              "track-by": "id",
                                              "track-by-text": "name",
                                              "show-selected-first": false,
                                              "show-reset-btn": false,
                                              "item-template": "withIcon",
                                              disabled: _vm.disableSyncDrop,
                                              "track-by-additional-for-item":
                                                "advanced",
                                            },
                                            on: {
                                              change: _vm.onChangeSyncSelected,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  536339433
                                ),
                              }),
                              _vm._v(" "),
                              _vm.selectedSyncOption &&
                              (_vm.selectedSyncOption.id === "assigned_to_me" ||
                                _vm.selectedSyncOption.id ===
                                  _vm.assignedAndMilestones)
                                ? _c(
                                    "div",
                                    { class: _vm.$.switch_item },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: _vm.$.integration_switch_label,
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.locale(
                                                  "integration_switch_to_milestones"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("square-toggle", {
                                        attrs: {
                                          "is-disabled": _vm.disableToggle,
                                          "is-toggle-on": _vm.toggleOn,
                                        },
                                        on: { onChange: _vm.activateElement },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { class: _vm.$.action_buttons },
                                [
                                  _c("vgp-button", {
                                    attrs: {
                                      type: "secondary",
                                      label: _vm.locale("common_cancel"),
                                      width: "90px",
                                      small: "",
                                    },
                                    on: { onClick: _vm.handleCancel },
                                  }),
                                  _vm._v(" "),
                                  _c("vgp-button", {
                                    class: _vm.$.action_btn,
                                    attrs: {
                                      type: "primary",
                                      label: _vm.settingsSync
                                        ? _vm.locale("common_save")
                                        : _vm.locale("integration_create"),
                                      disabled: _vm.disableButton,
                                      width: "90px",
                                      small: "",
                                    },
                                    on: { onClick: _vm.handleCreateOrUpdate },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3663011666
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }