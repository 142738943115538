var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dropCell", {
    attrs: {
      "suggest-height": _vm.dropHeight,
      "drop-width": _vm.dropWidth,
      disabled: _vm.disabled,
      editable: _vm.editable,
      "manual-open": true,
    },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function (ref) {
          var isOpen = ref.isOpen
          return [
            _c(
              "div",
              {
                ref: "trigger",
                class: [
                  _vm.$style.cell_trigger,
                  _vm.drop && !_vm.disabled && _vm.editable
                    ? _vm.$style.edit
                    : "",
                  _vm.disabled ? _vm.$style.disabled_cell : "",
                  _vm.crossout && !isOpen ? _vm.$style.crossout : "",
                  !_vm.editable ? _vm.$style.not_allowed : "",
                ],
                style: { opacity: isOpen ? 1 : _vm.cellOpecity },
                on: { click: _vm.triggerClick },
              },
              [
                _vm.template === "text" && _vm.selected
                  ? [
                      _c("span", { class: [_vm.$style.text_wrap] }, [
                        _vm._v(_vm._s(_vm.selected.value)),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.template === "color" && _vm.selected
                  ? [
                      _c("div", {
                        class: [
                          _vm.$style.template__color_item,
                          _vm.$style.auto,
                        ],
                        style: { "background-color": _vm.selected.color },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
      {
        key: "drop",
        fn: function () {
          return [
            _c("div", { ref: "drop" }, [
              _vm.withSearch
                ? _c("div", { class: _vm.$style.search_input }, [
                    _c("div", { class: _vm.$style.search }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.txt_search,
                          expression: "txt_search",
                        },
                      ],
                      ref: "seach_input",
                      attrs: { placeholder: _vm.locale.search },
                      domProps: { value: _vm.txt_search },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.txt_search = $event.target.value
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "scroll",
                      rawName: "v-scroll.self",
                      value: _vm.onScroll,
                      expression: "onScroll",
                      modifiers: { self: true },
                    },
                  ],
                  ref: "scrolled",
                  staticClass: "gantt_scroll",
                  class: [
                    _vm.withSearch ? _vm.$style.scrolable_list : "",
                    _vm.$style.scrollable,
                  ],
                },
                [
                  _vm.template === "color"
                    ? [
                        _c(
                          "div",
                          { class: [_vm.$style.template__color] },
                          _vm._l(_vm.showItems, function (option, index) {
                            return _c("div", {
                              key: _vm.componentKey + "-" + index + option.id,
                              class: [_vm.$style.template__color_item],
                              style: { "background-color": option.color },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOption(option)
                                },
                              },
                            })
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.template !== "color" && _vm.showItems.length
                    ? _vm._l(_vm.showItems, function (option, index) {
                        return _c(
                          "div",
                          {
                            key: _vm.componentKey + "-" + index + option.id,
                            class: [
                              _vm.$style.item_template,
                              _vm.isActive(option.id) ? _vm.$style.active : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.selectOption(option)
                              },
                            },
                          },
                          [
                            _vm.template === "text"
                              ? [
                                  _c(
                                    "span",
                                    { class: [_vm.$style.text_wrap] },
                                    [_vm._v(_vm._s(option.value))]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.template !== "color" && !_vm.showItems.length
                    ? [
                        _c("div", { class: [_vm.$style.no_data] }, [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.locale.empty_filter) + " "),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.selected
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.$style.drop_footer,
                        !_vm.scrollBottom && _vm.withSearch
                          ? _vm.$style.border_top
                          : "",
                      ],
                      on: { click: _vm.clearSelected },
                    },
                    [
                      _c("span", { class: [_vm.$style.text_action] }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.locale.clear) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.drop,
      callback: function ($$v) {
        _vm.drop = $$v
      },
      expression: "drop",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }