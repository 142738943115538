import app from "./../../../app";
import icon_close from "./../../../svg/close.svg";

const __ = window.__;

var outputObject = {
  view: "window",
  id: 'thank-you-popup',
  css: "thank-you-popup",
  width: 564,
  height: 240,
  hidden: true,
  modal: true,
  head: false,
  borderless: true,
  position: "center",
  body: {
    paddingX: 0,
    paddingY: 0,
    borderless: true,
    rows: [
      {
        view: 'template',
        borderless: true,
        template: function () {
          return "<div class='thank-you-popup-container'>" +
            "<div class='thank-you-popup-close'>" + icon_close + "</div>" +
            "<div class='thank-you-popup-column thank-you-popup-image'>" +
            // "<img src='//ganttpro.com/media/images/our-team/pasha.png'>" +
            "</div>" +
            "<div class='thank-you-popup-column thank-you-popup-text'>" +
            "<h4>" + __('thank_you_for_submitting') + "</h4>" +
            "<p>" + __('thank_you_for_submitting_info') + "</p>" +
            "</div>" +
            "</div>" +
            "<div class='thank-you-popup-footer'>" +
            "<div class='thank-you-popup-footer-button'>" + __("have_a_great_day") + "</div>" +
            "</div>";
        },
        onClick: {
          'thank-you-popup-footer-button': function () {
            $$(outputObject.id).hide();
          },
          'thank-you-popup-close': function () {
            $$(outputObject.id).hide();
          }
        }
      }
    ]
  }
};

export default outputObject;
