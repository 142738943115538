import onCustomFieldCreated from './onCustomFieldCreated';
import onCustomFieldDeleted from './onCustomFieldDeleted';
import onCustomFieldToProjectAssigned from './onCustomFieldToProjectAssigned';
import onCustomFieldFromProjectUnassigned from './onCustomFieldFromProjectUnassigned';
import onCustomFieldUpdated from './onCustomFieldUpdated';

export default {
  onCustomFieldCreated,
  onCustomFieldDeleted,
  onCustomFieldToProjectAssigned,
  onCustomFieldFromProjectUnassigned,
  onCustomFieldUpdated,
};
