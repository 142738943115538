<template>
  <div>
    <kanban v-if="mode==='board'"></kanban>
    <list v-if="mode==='list'"></list>
  </div>
</template>

<script>
import Kanban from "../kanban/Kanban.vue";
import listView from "../listView/listView.vue";

export default {
  name: 'ListViewPage',
  props: {
    mode: {
      type: String,
      default: 'list'
    }
  },
  components: {
    'kanban': Kanban,
    'list': listView
  }
}
</script>

<style scoped>

</style>