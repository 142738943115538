var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { "padding-top": "16px" } },
    [
      _c("resources-switch", {
        attrs: { active: _vm.activeLayout },
        on: { onToggleSwitch: _vm.onToggleLayout },
      }),
      _vm._v(" "),
      _c("div", { class: _vm.$.description }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.activeLayout === "people"
                ? _vm.locales("project_resources_manage_layout_header_txt_1")
                : _vm.locales("project_resources_manage_layout_header_txt_2")
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "tablePanel", class: _vm.$.project_table_panel }, [
        _c(
          "div",
          { class: _vm.$.search },
          [
            _c("search-input", {
              attrs: {
                placeholder:
                  _vm.activeLayout === "people"
                    ? _vm.locales("people_resources_search_placeholder")
                    : _vm.locales("material_resources_search_placeholder"),
                value: _vm.searchValue,
              },
              on: { onInput: _vm.onSearch },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$.panel_buttons },
          [
            _vm.activeLayout === "material" && _vm.selectedResources.length > 0
              ? _c("vgp-button", {
                  class: _vm.$.delete_btn,
                  attrs: {
                    type: "destructive-border",
                    label: _vm.locales("resources_delete_btn"),
                    small: true,
                  },
                  on: { onClick: _vm.onClickDeleteResources },
                })
              : _vm._e(),
            _vm._v(" "),
            !(_vm.activeLayout === "material" && _vm.isJiraProject)
              ? _c("vgp-button", {
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.activeLayout === "material" &&
                      _vm.selectedResources.length > 0,
                    label:
                      _vm.activeLayout === "people"
                        ? _vm.locales("project_resources_invite_btn")
                        : _vm.locales("project_resources_create_btn"),
                    icon: {
                      name:
                        _vm.activeLayout === "people" ? "add-user" : "plus-1",
                      size: _vm.activeLayout === "people" ? "24" : "20",
                      type: _vm.activeLayout === "people" ? "regular" : "bold",
                    },
                    "icon-side": "left",
                    small: true,
                  },
                  on: { onClick: _vm.openInvitePopup },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("resources-table", {
        attrs: {
          "cost-component-key": _vm.costComponentKey,
          headers: _vm.tableHeaders,
          resources: _vm.tableResources,
          roles: _vm.projectRoles,
          "resources-type": _vm.activeLayout,
          "me-is-king": _vm.meIsKing,
          "me-is-project-owner": _vm.meIsProjectOwner,
        },
        on: {
          onDeleteResource: _vm.onClickDeleteResources,
          onChangeType: _vm.onChangeType,
          onChangeCost: _vm.onChangeCost,
          onChangeRole: _vm.onChangeRole,
          onChangeOwner: _vm.onChangeOwner,
          onSelectResources: _vm.setSelectedResources,
          onConvertResources: _vm.onClickConvertResources,
        },
      }),
      _vm._v(" "),
      _c("invite-to-project-popup", {
        attrs: {
          "is-show": _vm.isShowInvitePopup,
          "resources-from-other-projects": _vm.resourcesFromOtherProjects,
          "project-roles": _vm.projectRoles,
          "account-roles": _vm.accountRoles,
          layout: _vm.activeLayout,
          "create-and-invite-resources-access":
            _vm.createAndInviteResourcesAccess,
          "account-role-change-access": _vm.accountRoleChangeAccess,
          "project-role-change-access": _vm.projectRoleChangeAccess,
        },
        on: {
          onClose: function ($event) {
            _vm.isShowInvitePopup = false
          },
          addResourcesToProject: _vm.addResourcesToProject,
          onSendInvites: _vm.onSendInvites,
          onCreateResource: _vm.onCreateResource,
        },
      }),
      _vm._v(" "),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.isShowConfirmPopup,
          title: _vm.locales("change_resource_type_title"),
          message: _vm.locales("change_resource_type_warning"),
        },
        on: {
          onApply: _vm.onApplyChangeType,
          onCancel: _vm.onCancelChangeType,
        },
      }),
      _vm._v(" "),
      !_vm.tableResources.length
        ? _c(
            "div",
            { class: _vm.$.empty_area, style: { height: _vm.stubHeight } },
            [
              _vm.searchValue !== ""
                ? _c("div", {
                    class: _vm.$.wrap,
                    domProps: { innerHTML: _vm._s(_vm.searchStub) },
                  })
                : _c("div", {
                    class: _vm.$.wrap,
                    domProps: { innerHTML: _vm._s(_vm.stub) },
                  }),
              _vm._v(" "),
              _c("div", { class: _vm.$.desc }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.searchValue !== ""
                        ? _vm.searchMessage[_vm.activeLayout]
                        : _vm.locales("resources_no_resources_description")
                    ) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }