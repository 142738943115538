import _ from "../libs/lodash";

var helpers = {
  compareArraysByFiled: function (firstArray, secondArray, field) {
    if (firstArray.length === 0 || firstArray.length !== secondArray.length) {
      return false;
    }

    const comparator = (item, index) => {
      return item[field] !== secondArray[index][field];
    };

    return _.filter(firstArray, comparator).length === 0;
  },
  validateName: function (userName) {
    var ruleExp = new RegExp("[<\>]+");
    return userName.trim().length && !ruleExp.test(userName.trim());
  },
  validateColumnName: function (name) {
    var ruleExp = new RegExp("[<\>]+");
    return !ruleExp.test(name.trim());
  },
  validateTaskName: function (userName) {
    return userName.trim().length;
  },
  validateNameInGrid: function (name) {
    var ruleExp = new RegExp("[<\>]+");
    return !ruleExp.test(name.trim());
  },
  validateEmail: function (email) {
    var ruleExp = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
    return ruleExp.test(email.trim());
  },
  getFileNameWithoutExt: function (fileName) {
    var ruleExp = new RegExp("(.+?)(\.[^.]*$|$)");
    return ruleExp.exec(fileName)[1];
  },
  isMySelf: function (targetUserId) {
    return parseInt(targetUserId, 10) === parseInt(user.id, 10);
  },
  isNumeric: function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  },
  testNumber: function(value) {
    return /^(\d+\.?\d{0,9}|\.\d{1,9})$/.test(value);
  },
  sanitizeText: function(value) {
    return value.replace(/ +(?= )/g,'').replace(/>/g, "&gt;").replace(/</g, "&lt;");
  },
  removeSpaces: function(value) {
    return value.replace(/ +(?= )/g,'').trim();
  },
  formatTaskName: function(value) {
    if (!value) {
      return value;
    }
    
    return value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  },
  formatLocaleText: function(value) {
    if (!value) {
      return value;
    }

    return value.replace(/<b>/g, '#b#')
      .replace(/<\/b>/g, '#/b#')
      .replace(/<strong>/g, '#strong#')
      .replace(/<\/strong>/g, '#/strong#')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replaceAll('#b#', '<b>')
      .replaceAll('#/b#', '</b>')
      .replaceAll('#strong#', '<strong>')
      .replaceAll('#/strong#', '</strong>');
  },
};

export default helpers;
