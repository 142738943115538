var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      class: [_vm.$style.context_popup, "context_popup"],
      style: {
        top: _vm.coordinates.top,
        bottom: _vm.coordinates.bottom,
        left: _vm.coordinates.left,
        right: _vm.coordinates.right,
        width: _vm.items.length ? _vm.width : "auto",
        height: _vm.coordinates.height,
      },
      on: {
        wheel: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.items.length
        ? _c(
            "div",
            { class: [_vm.$style.context_popup_items] },
            _vm._l(_vm.items, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    class: [_vm.$style.context_popup_item_wrap],
                    attrs: { id: item.gpTestId },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectItem(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$style.context_popup_item,
                          _vm.$style[_vm.size],
                          item.disable ? _vm.$style.disable : "",
                          item.feature
                            ? item.pricingAccess
                              ? "tooltip-gantt"
                              : "pricing-tooltip"
                            : "",
                        ],
                        attrs: {
                          "data-feature": item.feature,
                          "data-key":
                            item.id === "select_multiple" && item.disable
                              ? _vm.locale("select_multiple_tooltip")
                              : "",
                          "data-x": "10",
                          "data-y": "-6",
                          "data-position": "right",
                        },
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.context_menu_list_item },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.$style.context_menu_list_item_left_part,
                              },
                              [
                                item.icon
                                  ? _c(
                                      "div",
                                      {
                                        class:
                                          _vm.$style
                                            .context_menu_list_item_icon,
                                      },
                                      [
                                        _c("svg-sprite", {
                                          style: {
                                            color: item.iconColor
                                              ? item.iconColor
                                              : "",
                                          },
                                          attrs: {
                                            name: item.icon,
                                            type: item.iconType,
                                            size: item.iconSize,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.$style.context_menu_list_item_text,
                                    style: {
                                      color: item.labelColor
                                        ? item.labelColor
                                        : "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.locale(item.name)) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                item.planName && !item.pricingAccess
                                  ? _c(
                                      "div",
                                      { class: _vm.$style.plan_title },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.locale(item.planName)) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            item.iconRight
                              ? _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.$style.context_menu_list_item_icon,
                                      _vm.$style.right,
                                    ],
                                  },
                                  [
                                    _c("svg-sprite", {
                                      style: {
                                        color: item.iconRightColor
                                          ? item.iconRightColor
                                          : "",
                                      },
                                      attrs: {
                                        name: item.iconRight,
                                        type: item.iconRightType,
                                        size: item.iconRightSize,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("body"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }