<template>
  <div></div>
</template>

<script>
import routerGuard from "../../router/guards";

export default {
  name: 'HomePage',
  beforeEnter: routerGuard.guard,
}
</script>

<style scoped>

</style>