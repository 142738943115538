export default {
  PROJECT: {
    all_tasks: 'visible',
    create_tasks: 'plus-2',
    assugne_unassign_custom_fields: 'custom-field-edit',
    static_fields: 'standart-field',
    static_fields_4_items: 'user-fields',
    custom_fields: 'custom-field-create',
    comments: 'comments',
    cost_field: 'cost-2',
    gantt_chart: 'gantt-view',
    board: 'board-view',
    list: 'list-view',
    workload: 'workload-view',
    history: 'history',
    project_settings: 'settings',
    people: 'team',
    people_rights: 'edit-rights',
    project_statuses: 'status-1',
    overview: 'dashboard-view',
    calendar: 'calendar',
  },

  ACCOUNT: {
    project_statuses_settings: 'status-1',
    sso_settings: 'security',
    team_settings: 'palette',
    billing: 'billing',
    king_mode: 'owner',
    integration_settings: 'integration-1',
    integration_settings_additional: 'integration-2',
    api_and_webhooks: 'api',
    project: 'add-project',
    multiview: 'portfolio',
    reports: 'report',
    export_and_public_url: 'export',
    custom_fields: 'custom-field',
    team_and_resources: 'team',
    team_and_resources_create_and_invite_resources: 'add-user',
    team_and_resources_account_role: 'user-edit',
    resources_groups: 'gruop',
    account_roles: 'assigned',
    project_roles: 'rights',
    undefined: 'billing',
  },
};
