export default {
  setUserSettingsDataNotification(state, payload) {
    state.settings = payload.settings;
  },

  setBaseData(state, payload) {
    state.baseData = payload;
  },

  setNotificationCount(state, payload) {
    state.newNotificationCount = payload.count;
  },

  updateUserSettings(state, payload) {
    state.settings = { ...state.settings, ...payload };
  },

  setSoundConfig(state, payload) {
    state.sound = payload.sound;
  },

  readAll(state) {
    state.notifications = state.notifications.map(item => {
      item.read = true;

      return item;
    });
  },

  setLoaded(state) {
    state.loaded = true;
  },

  addNewNotification(state, payload) {
    state.notifications.push(payload);
  },

  loadNotifications(state, payload) {
    state.notifications = [...state.notifications, ...payload.data];
  },
};
