<template>
  <div
    class="students-page gantt_scroll"
    :style="{width: width, height: '100vh'}"
  >
    <div
      class="students-page-head"
      :style="{width: width}"
      v-html="headTemplate"
    />
    <div
      class="students-page-body"
      v-html="bodyTemplate"
    />
  </div>
</template>

<script>
import { templates, eventHandlers } from './students';

export default {
  data() {
    return {
      headTemplate: '',
      bodyTemplate: '',
    };
  },
  computed: {
    width() {
      const sidebarWidth = this.$store.getters.leftSidebarWidth;
      const width = `calc(100% - ${sidebarWidth + 1}px)`;

      return width;
    },
  },
  mounted() {
    document.addEventListener('click', eventHandlers);
  },
  beforeDestroy() {
    document.removeEventListener('click', eventHandlers);
  },
  created() {
    this.headTemplate = templates.getMainPaymentTemplateStudentHeader();
    this.bodyTemplate = templates.getMainPaymentTemplateStudent();
  },
};
</script>

<style scoped lang='less'>
  .students-page {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 35;
  }
</style>
