var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-task-project" }, [
    _c("span", [
      _vm._v(
        " " +
          _vm._s(_vm.item[_vm.extra ? _vm.propertyExtra : _vm.property]) +
          " "
      ),
    ]),
    _vm._v(" "),
    _vm.extra
      ? _c("div", {
          staticClass: "tooltip-gantt icon-projects",
          attrs: {
            "data-key": _vm.item[_vm.extra ? _vm.propertyExtra : _vm.property],
            "data-y": -5,
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }