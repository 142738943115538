var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("integration-page", {
        attrs: {
          "integration-name": "google",
          svg: _vm.googleIcon,
          links: _vm.links,
          "is-first-link": _vm.isFirstLink,
        },
        on: {
          handleDelete: _vm.onOkDeletePopup,
          firstLinkWasOpen: _vm.firstLinkWasOpen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }