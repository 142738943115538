import app from '../../../app';
import _ from '../../../libs/lodash';

import projectsModel from '../../../models/projects';
import ganttViewModel from '../../../models/ganttViewModel';
import multiviewModel from '../../../models/multiViewsProjects';
import baselineModel from '../../../models/baselines';
import timeTrackingModel from '../../../models/timeTracking';
import constants from '../../../helpers/constants';
import timeParser from '../../../helpers/timeParser';
import rights from '../../../components/rights';
import numbersHelper from '../../../helpers/numbers';
import customHelper from '../../../helpers/custom';
import globalStore from "$$store/main";

const __ = window.__;

gantt.attachEvent("onAfterSmartTaskUpdate", function (taskId, taskData, properties) {
  if (_.includes(properties, 'estimation')) {
    innerObject.handlers.updateEstimationForParents(taskId, taskData);
  }
});

const innerObject = {
  init: {
    beforeInit() {

    },
    run() {
      if (gantt.config.multiview) {
        innerObject.settings.ganttIds = multiviewModel.getActiveGanttId().ganttIDs;
        innerObject.settings.isMultiview = true;
      } else {
        innerObject.settings.ganttId = projectsModel.getActiveGanttId();
      }
      innerObject.init.initEstimationGrid(true);
    },
    afterInit() {
    },
    initEstimationGrid(initProject) {
      const projectData = projectsModel.getActiveProjectData();
      const projectConfig = ganttViewModel.getProjectConfig();

      gantt.config.columns.push({
        align: 'right',
        name: 'estimation',
        label: `<span class='grid_head_cel_label'>${__('gantt_grid_estimation')}</span>`,
        resize: true,
        min_width: projectConfig.initGridColumns.sizes.estimation.min_width,
        width: projectConfig.initGridColumns.sizes.estimation.width,
        hide: false,
        isShowCheckbox: true,
        order: 5,
        control_type: 'text',
        task_types: ['task'],
        template(task, isBaseline) {
          if (task.type === gantt.config.types.button || task.type === gantt.config.types.milestone) {
            return '';
          }

          const value = innerObject.init.getEstimationValueForTask(task, isBaseline);

          const text = timeParser.output(value, {
            durationData: gantt.config.durationData,
            prop: 'estimation',
          });

          return text;
        },
        inline_validate(value) {
          let durationData = gantt.config.durationData;
          const task = gantt.getTask(gantt._inline_edit._currentTask);

          if (task) {
            const projectCalendar = gantt.getCalendar(task.gantt_id);

            durationData = {
              showDay: Object.keys(projectCalendar.getConfig().dates).filter(key => +projectCalendar.getConfig().dates[key]),
              showTime: _.clone(projectCalendar.getConfig().hours),
            };
          }
          const result = timeParser.input(value, {
            durationData,
            prop: 'estimation',
          });

          if (result.value >= 0) {
            return result;
          }

          return false;
        },
      });

      gantt.config.columns = _.orderBy(gantt.config.columns, 'order');

      innerObject.init.initPriceGrid();
    },
    initPriceGrid() {
      const projectConfig = ganttViewModel.getProjectConfig();
      let hasCostRight;

      if (innerObject.settings.isMultiview) {
        hasCostRight = innerObject.settings.ganttIds.some(ganttId => rights.project.hasCostRight(ganttId));
      } else {
        hasCostRight = rights.project.hasCostRight(innerObject.settings.ganttId);
      }

      if ((hasCostRight && app.config.mode.isBase) || app.config.mode.isExport || app.config.mode.isLink) {
        gantt.config.columns.push({
          align: 'center',
          name: 'total_price',
          label: __('gantt_grid_total_price'),
          resize: true,
          min_width: projectConfig.initGridColumns.sizes.total_price.min_width,
          width: projectConfig.initGridColumns.sizes.total_price.width,
          hide: false,
          isShowCheckbox: true,
          order: 8,
          control_type: "custom_counter",
          task_types: ['task', 'milestone'],
          template: this.getPriceValueForTask,
          inline_validate: innerObject.helpers.validatePrice
        });

        gantt.config.columns.push({
          align: 'center',
          name: 'actual_cost',
          label: __('gantt_grid_actual_cost'),
          resize: true,
          min_width: projectConfig.initGridColumns.sizes.actual_cost.min_width,
          width: projectConfig.initGridColumns.sizes.actual_cost.width,
          hide: false,
          isShowCheckbox: true,
          order: 8,
          control_type: "custom_counter",
          task_types: ['task', 'milestone'],
          template: this.getActualCostValueForTask,
          inline_validate: innerObject.helpers.validatePrice
        });

        gantt.config.columns = _.orderBy(gantt.config.columns, 'order');
      }
    },
    checkAccessForPrice() {
      if (!innerObject.settings.ganttId || !projectsModel.count()) {
        return false;
      }

      let hasCostRightOnProject;

      if (innerObject.settings.isMultiview) {
        hasCostRightOnProject = innerObject.settings.ganttIds.some(ganttId => rights.project.hasCostRight(ganttId));
      } else {
        hasCostRightOnProject = rights.project.hasCostRight(innerObject.settings.ganttId);
      }

      if (!hasCostRightOnProject) {
        _.remove(gantt.config.columns, o => _.includes(['total_price', 'actual_cost'], o.name));

        // gantt.callEvent("ganttRender");
        return true;
      }

      if (hasCostRightOnProject && (!gantt.getGridColumn('total_price') || !gantt.getGridColumn('actual_cost'))) {
        innerObject.init.initPriceGrid();
        // gantt.callEvent("ganttRender");

        return true;
      }
    },

    getEstimationValueForTask(task, isBaseline, baselineModelState) {
      if (task.type === gantt.config.types.button || task.type === gantt.config.types.milestone) {
        return '';
      }

      if (task.type === gantt.config.types.project) {
        // var tasks = innerObject.init.getAllChildByTask(task.id);
        let total_estimate = 0;

        if (isBaseline) {
          const baselineData = baselineModelState || baselineModel.getBaselineData(task.gantt_id);
          const originalChildren = innerObject.init.getAllChildByTask(task.id);

          _.each(originalChildren, child => {
            const baselineTask = _.find(baselineData.tasks, bt => bt.id === child.id);

            if (baselineTask && baselineTask.type === gantt.config.types.task) {
              total_estimate += parseFloat(baselineTask.estimation);
            }
          });
        } else {
          gantt.eachTask(childTask => {
            if (childTask.type === gantt.config.types.task) {
              total_estimate += parseFloat(childTask.estimation);
            }
          }, task.id);
        }

        return `${total_estimate}`;
      }

      return `${task.estimation}`;
    },

    getAllChildByTask(taskId) {
      let children = [];
      let temp = [];
      const tasksIds = gantt.getChildren(taskId);
      let taskItem;

      _.each(tasksIds, id => {
        taskItem = gantt.getTask(id);

        children.push(taskItem);

        if (taskItem.type === 'project') {
          temp = innerObject.init.getAllChildByTask(taskItem.id);

          children = children.concat(temp);
        }
      });

      return children;
    },

    getPriceForProjectTask(task) {
      let totalPrice = 0;

      gantt.eachTask(childTask => {
        if (childTask.type !== gantt.config.types.button) {
          const projectConfig = projectsModel.getProjectConfig(task.gantt_id);
          if (childTask.type !== gantt.config.types.project && ((childTask.resources && childTask.resources.length) || !projectConfig.auto_budget)) {
            totalPrice += innerObject.helpers.calculateCostChildTask(childTask);
          }
        }
      }, task.id);

      return totalPrice;
    },
    getPriceValueForTask(task, isBaseline, baselineModelState, notFormatted) {
      let totalPrice = 0;

      if (task.type === gantt.config.types.button) {
        return '';
      }

      if (!task.gantt_id) {
        if (!gantt.config.multiview) {
          return '';
        }

        if (task.type === gantt.config.types.project) {
          totalPrice = innerObject.init.getPriceForProjectTask(task);
        }
      } else if (task.type === gantt.config.types.project) {
        if (isBaseline) {
          const baselineData = baselineModelState || baselineModel.getBaselineData(task.gantt_id);
          const originalChildren = innerObject.init.getAllChildByTask(task.id);

          _.each(originalChildren, child => {
            const baselineTask = _.find(baselineData.tasks, bt => bt.id === child.id);

            if (baselineTask && baselineTask.type !== gantt.config.types.button) {
              if (baselineTask.type !== gantt.config.types.project && baselineTask.resources && baselineTask.resources.length) {
                totalPrice += innerObject.helpers.calculateCostChildTask(baselineTask);
              }
            }
          });
        } else {
          totalPrice = innerObject.init.getPriceForProjectTask(task);
        }
      } else {
        const projectConfig = projectsModel.getProjectConfig(task.gantt_id);
        if ((task.resources && task.resources.length) || !projectConfig.auto_budget) {
          totalPrice = innerObject.helpers.calculateCostChildTask(task);
        }
      }

      return notFormatted ? totalPrice : innerObject.helpers.formatWithCurrency(totalPrice);
    },
    getActualCostValueForTask(task, notFormatted) {
      if (task.type === gantt.config.types.button) {
        return '';
      }

      if (!task.gantt_id && !gantt.config.multiview) {
        return '';
      }

      let resultCost = '';

      if (task.type === gantt.config.types.project) {
        let totalCost = innerObject.helpers.calculateActualCostChildTask(task);
        
        gantt.eachTask(childTask => {
          if (childTask.type !== gantt.config.types.button) {
            totalCost += innerObject.helpers.calculateActualCostChildTask(childTask);
          }
        }, task.id);

        resultCost = totalCost;
      } else {
        resultCost = innerObject.helpers.calculateActualCostChildTask(task);
      }

      return notFormatted ? resultCost : innerObject.helpers.formatWithCurrency(resultCost);
    }
  },
  handlers: {
    updateEstimationForParents(taskId, taskData) {
      if (!taskData.parent) {
        return;
      }

      let parentData = gantt.getTask(taskData.parent);

      gantt.refreshTask(parentData.id);

      while (parentData.parent) {
        parentData = gantt.getTask(parentData.parent);

        gantt.refreshTask(parentData.id);
      }
    },
    changeProjectAdvancedRights(ganttId) {
      if (!ganttId || ganttId === innerObject.settings.ganttId) {
        innerObject.init.checkAccessForPrice();
      }
    },
  },
  helpers: {
    calculateCostChildTask: function (task) {
      let total = 0;
      const projectConfig = projectsModel.getProjectConfig(task.gantt_id);

      if (projectConfig.auto_budget) {
        _.each(task.resources, function (resource) {
          const resourceSettingsOnProject = globalStore.getters['resourcesModel/getResourceProjectSettings'](resource.resource_id, task.gantt_id);
  
          if (!resourceSettingsOnProject) {
            return;
          }
  
          if (resourceSettingsOnProject.type === constants.RESOURCE_COST_TYPE) {
            total += resource.value;
            return;
          }
  
          total += resource.value * resourceSettingsOnProject.cost;
  
        });
      } else {
        total += task.total_price || 0;
      }

      total = Math.round(total * 100) / 100;

      return total;
    },
    calculateActualCostChildTask(task) {
      const taskLogsData = timeTrackingModel.getLogsByParamsNotCopy(task.gantt_id, task.id);
      const taskResources = globalStore.getters['tasksModel/getResourcesForTask'](task.gantt_id, task.id);
      let result = 0;
      const projectConfig = projectsModel.getProjectConfig(task.gantt_id);

      if (projectConfig.auto_budget) {
        if (taskLogsData) {
          _.each(taskLogsData.logs, log => {
            const resourceSettingsOnProject = globalStore.getters['resourcesModel/getResourceProjectSettings'](log.resource_id, task.gantt_id);
  
            if (!resourceSettingsOnProject) {
              return;
            }
  
            if (resourceSettingsOnProject.type === constants.RESOURCE_TIME_TYPE) {
              result += (log.time / 60) * resourceSettingsOnProject.cost;
            }
          });
        }
  
        if (!task.resources || !task.resources.length || !task.progress) {
          return result;
        }
  
        task.resources.forEach(resource => {
          const resourceSettingsOnProject = globalStore.getters['resourcesModel/getResourceProjectSettings'](resource.resource_id, task.gantt_id);
  
          if (!resourceSettingsOnProject) {
            return;
          }
  
          if (resourceSettingsOnProject.type === constants.RESOURCE_TIME_TYPE) {
            const resourceData = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);
  
            if (!resourceData.userId) {
              result += resource.value * resourceSettingsOnProject.cost * task.progress;
            }
          }
  
          if (resourceSettingsOnProject.type === constants.RESOURCE_ITEM_TYPE) {
            result += resource.value * resourceSettingsOnProject.cost * task.progress;
          }
  
          if (resourceSettingsOnProject.type === constants.RESOURCE_COST_TYPE) {
            result += resource.value * task.progress;
          }
        });
      } else {
        result += task.actual_cost || 0;
      }

      result = Math.round(result * 100) / 100;

      return result;
    },
    formatWithCurrency(number) {
      const currencySign = globalStore.getters['teamSetting/enableCurrency']
        ? GT.currency.find((item) => item.ID === globalStore.getters['teamSetting/currency'])?.VALUE || ''
        : '';

      return currencySign +
        numbersHelper.getFormatted(number);
    },
    validatePrice(value) {
      if (!customHelper.testNumber(value)) {
        webix.message({ type: 'warning', text: __('grid_number_custom_column_not_valid') });

        return false;
      }

      return true;
    },
  },

  settings: {
    isMultiview: false,
    ganttId: 0,
    ganttIds: [],
    taskDataBeforeUpdate: {},
  },
};

const outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit,
  },

  helpers: {
    getEstimationForTask: innerObject.init.getEstimationValueForTask,
    getPriceForTask: innerObject.init.getPriceValueForTask,
    getActualCostValueForTask: innerObject.init.getActualCostValueForTask,
    calculateCostChildTask: innerObject.helpers.calculateCostChildTask,
    calculateActualCostChildTask: innerObject.helpers.calculateActualCostChildTask,
    formatWithCurrency: innerObject.helpers.formatWithCurrency
  }
};

app.on('activeProject:afterSet', (ganttId, ganttData) => {
  if (app.config.mode.isBase) {
    innerObject.settings.isMultiview = false;
    innerObject.settings.ganttId = +ganttId;
    innerObject.init.checkAccessForPrice();
  }
});

app.on('activeMultiProjects:afterSet', ganttIds => {
  if (app.config.mode.isBase) {
    innerObject.settings.isMultiview = true;
    innerObject.settings.ganttIds = ganttIds;
    innerObject.init.checkAccessForPrice();
  }
});

export default outputObject;
