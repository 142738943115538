import app from '../../app';

const popups = new webix.DataCollection();

popups.checkReleasePopup = function () {
  webix.ajax()
    .get(`/api/popups?${Date.now()}`)
    .then(responseRawData => {
      const responseData = responseRawData.json();

      if (responseData.needShow) {
        popups.data.callEvent('showPopup', ['updateRelease']);
      }
    })
    .fail();
};

// popups.submitShowPopup = function (popupId) {
//   webix.ajax()
//     .post('/api/popups', { popupId })
//     .then()
//     .fail();
// };

export default popups;
