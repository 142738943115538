var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cell-text",
      class: {
        edit: _vm.editMode && !_vm.disabled,
        disabled: _vm.disabled,
        select_none: !_vm.editMode,
        right: _vm.type === "time",
        not_allowed: !_vm.editable,
      },
      on: { click: _vm.clickOnCell },
    },
    [
      _vm.editMode && !_vm.disabled && _vm.editable
        ? _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputTxt,
                  expression: "inputTxt",
                },
              ],
              ref: "input_field",
              attrs: { type: "text", autofocus: _vm.editMode },
              domProps: { value: _vm.inputTxt },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.editedValue($event)
                },
                focus: function ($event) {
                  return $event.target.select()
                },
                blur: function ($event) {
                  _vm.editMode = false
                },
                change: _vm.editedValue,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputTxt = $event.target.value
                },
              },
            }),
          ])
        : _c(
            "span",
            {
              staticClass: "one-wrap",
              class: { crossout: _vm.crossout },
              style: { opacity: _vm.cellOpecity },
            },
            [_vm._v(" " + _vm._s(_vm.formattedVal) + " ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }