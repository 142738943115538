import onRoleAccountCreated from './onRoleAccountCreated';
import onRoleProjectCreated from './onRoleProjectCreated';
import onRoleProjectDeleted from './onRoleProjectDeleted';
import onRoleAccountDeleted from './onRoleAccountDeleted';
import onRoleProjectUpdated from './onRoleProjectUpdated';
import onRoleAccountUpdated from './onRoleAccountUpdated';

export default {
  onRoleAccountCreated,
  onRoleProjectCreated,
  onRoleProjectDeleted,
  onRoleAccountDeleted,
  onRoleProjectUpdated,
  onRoleAccountUpdated,
};
