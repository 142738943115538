<template>
  <div :class="$style.wrapper">
    <div :class="$style.dashboard_wrapper">
      <div :class="[$style.item, chart.large && $style.large]" v-for="chart in charts">
        <component
          :key="chart.key"
          :is="chart.component"
          :chart="chart"
          :data="chart.data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import multiViewsProjects from '../../models/multiViewsProjects';
import projects from '../../models/projects';
import constants from '../../helpers/constants';
import dateHelper from '../../helpers/dateFormats';
import moment from '../../libs/moment';
import ChartByStatus from '../overview/components/ChartByStatus.vue';
import ChartMilestones from '../overview/components/ChartMilestones.vue';
import MilestonesWidget from '../common/VueComponents/charts/MilestonesWidget.vue';

export default {
  name: 'PortfolioDashboard',
  components: {
    ChartByStatus,
    ChartMilestones,
    MilestonesWidget
  },
  data() {
    return {
      projects: [],
      locale: __,
      summary: {
        totalTasks: {
          text: __('overview_total_tasks'),
          value: 0
        },
        unassignedTasks: {
          text: __('overview_unassigned_tasks'),
          value: 0
        },
        overdueTasks: {
          text: __('overview_overdue_tasks'),
          value: 0
        },
        totalProjects: {
          text: __('dashboard_total_projects'),
          value: 0
        }
      }
    };
  },
  computed: {
    charts() {
      return [
        {
          key: 'portfolio_tasks_by_statuses',
          component: 'ChartByStatus',
          data: this.tasksByStatusesData
        },
        {
          key: 'projects_by_statuses',
          component: 'ChartByStatus',
          data: this.projectsByStatusesData
        },
        {
          key: 'projects_milestones',
          component: 'ChartMilestones',
          data: this.projectsMilestones,
          large: true
        },
        {
          key: 'portfolio_milestones_date',
          component: 'MilestonesWidget',
          data: this.projectsMilestones,
          large: true
        }
      ];
    },
    tasks() {
      return this.$store.getters['tasksModel/getTasksByGanttIds'](this.portfolio.ganttIDs)
        .filter(task => task.type === constants.TASK_TYPES.task || task.type === constants.TASK_TYPES.milestone);
    },
    milestones() {
      return this.tasks.filter(task => task.type === constants.TASK_TYPES.milestone);
    },
    taskStatusesColors() {
      return {
        1: '#999999',
        2: '#FF9A00',
        3: '#4484CD',
        4: '#8BC34A',
      };
    },
    taskStatuses() {
      return Object.entries(constants.TASK_STATUSES).map(([key, value]) => ({
        id: +key,
        color: this.taskStatusesColors[key],
        name: this.locale(value.locale),
      }));
    },
    tasksGroupedByStatus() {
      return this.tasks.reduce((acc, curr) => {
        (acc[+curr.status] = acc[+curr.status] || []).push(curr);

        return acc;
      }, {});
    },
    milestonesGroupedByStatus() {
      return this.milestones.reduce((acc, curr) => {
        (acc[+curr.status] = acc[+curr.status] || []).push(curr);

        return acc;
      }, {});
    },
    tasksByStatusesData() {
      const data = {
        items: [],
        summary: {
          total: this.summary.totalTasks,
          unassigned: this.summary.unassignedTasks,
          overdue: this.summary.overdueTasks,
        }
      };

      if (!this.tasks.length) {
        return data;
      }

      data.items = this.taskStatuses.map(status => {
        const statusTasks = this.tasksGroupedByStatus[status.id];

        if (!statusTasks || !statusTasks.length) {
          return null;
        }

        const qty = statusTasks.length;
        const value = Math.round((qty / this.tasks.length) * 100);
        return { ...status, qty, value };
      }).filter(item => item);

      data.summary.total.value = this.tasks.length;
      data.summary.unassigned.value = this.tasks.filter(task => !task.resources.length).length;
      data.summary.overdue.value = this.tasks.filter(task => task.progress < 1 && moment(task.end_date).isBefore()).length;

      return data;
    },
    projectsByStatusesData() {
      const data = {
        items: [],
        summary: {
          total: this.summary.totalProjects
        }
      };
      const projectIDs = this.portfolio.ganttIDs;

      if (!projectIDs.length) {
        return data;
      }

      const colors = this.$store.getters['teamSetting/colors'].data;
      const projectsStatuses = projectIDs.map((ganttID) => {
        const statusValue = this.$store.getters['teamSetting/statusDataForProject'](ganttID).selectOption.value;

        return [ganttID, statusValue];
      });

      data.items = this.$store.getters['teamSetting/projectStatuses']
        .find((item) => item.name === 'status')
        ?.options.map((status) => {
          const qty = projectsStatuses.filter(([id, statusValue]) => statusValue === status.value).length;
          const value = Math.round((qty / projectsStatuses.length) * 100);
          return {
            id: status.id,
            color: colors.find((color) => color.id === status.color).hex2,
            name: this.locale(status.value),
            qty,
            value
          }
        });

      data.summary.total.value = projectsStatuses.length;

      return data;
    },
    projectsMilestones() {
      const data = {
        items: [],
        total: 0,
        overdue: 0,
        milestones: [],
      };

      if (!this.milestones.length) return data;

      data.items = this.taskStatuses.map(status => {
        const statusMilestones = this.milestonesGroupedByStatus[status.id];

        if (!statusMilestones || !statusMilestones.length) {
          return null;
        }

        const qty = statusMilestones.length;
        const value = Math.round((qty / this.milestones.length) * 100);

        return { ...status, qty, value };
      }).filter(item => item);

      data.total = this.milestones.length;
      data.overdue = this.milestones.filter(milestone => milestone.progress < 1 && moment(milestone.start_date).isBefore()).length;
      data.milestones = this.milestones.map(milestone => {
        const status = constants.TASK_STATUSES[+milestone.status];
        const statusColor = this.taskStatusesColors[+milestone.status];
        const statusName = this.locale(status?.locale);
        const formatDate = dateHelper.formatDateNoTime(milestone.start_date);
        const projectName = projects.getProjectDataById(milestone.gantt_id).name;

        return {
          ...milestone,
          statusColor,
          statusName,
          formatDate,
          projectName
        };
      }).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

      return data;
    },
    portfolio() {
      return multiViewsProjects.getActiveViewData();
    }
  },
}
</script>

<style module src="./less/portfolioDashboard.less"></style>
