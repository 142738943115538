import app from "./../../../app";

import _ from "./../../../libs/lodash";
import routerHelper from "../../../helpers/router";
import webixMouseUpOutsideInput from "../../../helpers/webixMouseUpOutsideInput";

import '../../common/ganttproWindowPopup';
import timeParser from "../../../helpers/timeParser";
import globalStore from "../../../store/main";

import rights from '../../../components/rights';

const __ = window.__;

const _state = {
  savingTask: null
};

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

gantt.attachEvent("onLinkClick", function (id, e) {
  innerObject.handlers.linkClick(id, e);
});

gantt.attachEvent("onLinkIdChange", function (oldId, newId) {
  _.delay(function () {
    gantt.refreshLink(newId);
  });
});

gantt.attachEvent("showLinksSuggestOnSearch", function (pos, selectedTask, newValue, selectionPos, master) {
  innerObject.handlers.openSuggestSearchPopup(pos, selectedTask, newValue, selectionPos, master);
});

gantt.attachEvent("hideLinksSuggestOnSearch", function () {
  innerObject.handlers.closeSuggestSearchPopup();
});

gantt.attachEvent("saveLinkColumn", function (value, task) {
  if (_state.savingTask !== task.id) {
    _state.savingTask = task.id;
    innerObject.handlers.predecessorColumnChange(value, task);
  }
});

gantt.attachEvent("suggestSearchPopupFocus", function () {
  const popup = $$(innerObject.views.suggestSearchLinksPopup.id);
  if (popup.isVisible()) {
    const list = $$(innerObject.views.suggestSearchLinksPopupList.id);
    list.select(list.getFirstId());
    webix.UIManager.setFocus(list);
  }
});

var innerObject = {
  views: {
    settingsLinkPopup: {
      id: "settingsLinkPopupGantt"
    },
    settingsLinkForm: {
      id: "settingsLinkFormGantt"
    },
    suggestSearchLinksPopup: {
      id: "suggestSearchLinksPopupGantt"
    },
    suggestSearchLinksPopupList: {
      id: "suggestSearchLinksPopupListGantt"
    },
    settingsLinkCounter: {
      view: "lagCounter",
      css: 'settingsLinkCounterGantt',
      id: "settingsLinkCounterGantt",
      label: ' ',
      name: 'lag',
      labelWidth: 260,
      min: -Infinity,
      on: {
        "onChange": function (newValue, oldValue) {
          const $$settingsLinkCounterGantt = $$('settingsLinkCounterGantt');
          let linkData = gantt.getLink(innerObject.settings.linkId);

          if (!linkData) return;

          innerObject.settings.inputParser = timeParser.input(newValue, {
            durationData: gantt.config.durationData,
            durationStep: gantt.config.duration_view,
            prop: 'lag'
          });

          if (!innerObject.settings.inputParser) {
            let linkDataValue = linkData.lag + timeParser.locale[gantt.config.duration_unit];

            innerObject.settings.inputParser = timeParser.input(linkDataValue, {
              durationData: gantt.config.durationData,
              durationStep: gantt.config.duration_view,
              prop: 'lag'
            });
          }

          _.delay(() => {
            if (innerObject.settings.inputParser && innerObject.settings.inputParser.text) {
              $$settingsLinkCounterGantt.setValue(innerObject.settings.inputParser.text);
              $$settingsLinkCounterGantt.refresh();
            }
            $$settingsLinkCounterGantt.getInputNode().focus();
          });
        },
        "onFocus": function () {
          var field = this.getNode().querySelector('input');

          _.delay(function () { // for IE, Edge
            field.select();
          });
        },
        "onKeyPress": _.debounce(function (code, e) {
          if (code === 13) {// Enter key
            e.preventDefault();
            innerObject.handlers.updateLink(innerObject.settings.linkId, innerObject.settings.inputParser.value);
          }
        }, 100)
      }
    },
    closePopupButton: {
      view: "button",
      id: "closeLinkSettingsPopup",
      type: "icon",
      icon: "plus-times",
      width: 32,
      // height: "100%",
      template: "<div style='float:right; line-height: 38px; margin-right: 10px'><span class='fa-times webix_close_icon webix_icon'></span></div>",
      on: {
        onItemClick: function () {
          innerObject.handlers.hide();
        }
      }
    },
    deleteLinkButton: {
      view: 'template',
      borderless: true,
      css: 'popup_button',
      width: 210,
      maxWidth: 250,
      minWidth: 131,
      template: function () {
        return `<div class="button_inactive">${__('settings_delete_link')}</div>`
      },
      onClick: {
        'button_inactive': function () {
          innerObject.handlers.deleteLink(innerObject.settings.linkId);
        }
      }
    },
    updateLinkDataButton: {
      view: 'template',
      borderless: true,
      css: 'popup_button',
      template: function () {
        return `<div class="button_active">Ok</div>`;
      },
      onClick: {
        'button_active': function () {
          innerObject.handlers.updateLink(innerObject.settings.linkId, innerObject.settings.inputParser.value);
        }
      }
    }
  },
  init: {
    run: function (enablePlugin, ganttId) {
      innerObject.settings.enablePlugin = true;

      gantt.templates.link_class = function (link) {
        var types = gantt.config.links;

        // if(gantt.isSummaryTask(gantt.getTask(link.source))){
        //   return "summary-link";
        // }
        // if(gantt.isSummaryTask(gantt.getTask(link.target))){
        //   return "summary-link";
        // }

        //return "";
        if (link.lag && gantt.config.auto_scheduling) {
          return "finish_to_start";
        }
      };

      innerObject.init.initPredecessorsGrid();
    },
    initPredecessorsGrid: function () {
      const linksFormatter = gantt.ext.formatters.linkFormatter();

      gantt.config.columns.push({
        align: "left",
        name: "predecessors",
        label: `<span class='grid_head_cel_label'>${__("gantt_grid_predecessors")}</span>`,
        resize: true,
        hide: false,
        isShowCheckbox: true,
        order: 99,
        control_type: "predecessor",
        task_types: ['task', 'milestone', 'project'],
        editor: { type: "predecessor", map_to: "auto", formatter: linksFormatter },
        template: function (task) {
          if (task.hasOwnProperty('linkValues')) {
            return task.linkValues;
          }

          const links = task.$target || [];
          const labels = [];
          for (var i = 0; i < links.length; i++) {
            const link = {
              ...gantt.getLink(links[i]),
              type: '0',
              lag: 0
            };

            labels.push(linksFormatter.format(link));
          }
          return labels.join(", ")
        }
      });

      gantt.config.columns = _.orderBy(gantt.config.columns, "order");
    },
  },
  handlers: {
    linkClick: function (id, e) {
      if (gantt.config.readonly) {
        return false;
      }

      var link = gantt.getLink(id);

      if (!rights.project.hasRight(link.gantt_id, 'task_links')) {
        return false;
      }

      if (!innerObject.settings.enablePlugin || !gantt.config.auto_scheduling) {
        innerObject.helpers.customDeleteLink(id);
        return false;
      }

      const lagWithDurationBetweenTasks = innerObject.helpers.getDurationViewValue(link, "hour") * 60;

      $$(innerObject.views.settingsLinkForm.id).setValues({
        lag: timeParser.output(lagWithDurationBetweenTasks, {
          durationData: gantt.config.durationData,
          durationStep: gantt.config.duration_view,
          prop: 'lag'
        })
      });

      innerObject.settings.linkId = id;

      innerObject.handlers.show();

      userExtAnalytics.log('gantt_link_click');
    },
    show: function (method) {
      const $$settingsLinkCounterGantt = $$('settingsLinkCounterGantt');
      const $$popup = $$(innerObject.views.settingsLinkPopup.id);
      innerObject.helpers.setupCounterLabel(innerObject.views.settingsLinkCounter.id);
      $$popup.show();
      if ($$settingsLinkCounterGantt && $$settingsLinkCounterGantt.isVisible()) {
        $$settingsLinkCounterGantt.getInputNode().focus();
      }
      webixMouseUpOutsideInput.fix($$popup);

    },
    hide: function () {
      $$(innerObject.views.settingsLinkPopup.id).hide();
    },
    updateLinksLag: function () {
      const links = gantt.getLinks();

      if (links.length === 0) {
        return;
      }

      const updatedLinks = _.map(links, (link) => {
        link.lag = innerObject.helpers.getDurationViewValue(link, 'minute');

        return link;
      });

      globalStore.dispatch('tasksModel/updateLinks',{ganttId: gantt.config.gantt_id, links})
    },
    updateLink: function (linkId, val) {
      if (!innerObject.settings.enablePlugin) return;

      let oldLink = webix.copy(gantt.getLink(linkId));
      // let vals = val == undefined ? $$(innerObject.views.settingsLinkForm.id).getValues() : {lag: val};
      let oldLag = oldLink.lag;
      let vals = val == undefined ? {lag: oldLag} : {lag: val};
      let link = gantt.getLink(linkId);
      let startDate = innerObject.helpers.getStartAccordingToType(link);
      let calcDate = gantt.calculateToDuration(startDate, vals.lag, gantt.config.duration_unit);
      let trueLag = innerObject.helpers.customCalculateDuration(startDate, calcDate.endDate);

      link.lag = trueLag;
      link.gantt_id = link.gantt_id ? link.gantt_id : gantt.config.gantt_id;

      if (oldLink.lag !== link.lag) {
        gantt.updateLink(linkId, link);
        userExtAnalytics.log('gantt_link_add_lag_done', {from: 'chart', value: link.lag});
      }

      innerObject.handlers.hide();
    },
    deleteLink: async function (linkId) {
      if (!innerObject.settings.enablePlugin) {
        return;
      }

      const link = gantt.getLink(linkId);
      if(!link) return;

      const ganttID = link.gantt_id;
      const linkData = globalStore.getters['tasksModel/getLinkByGanttId'](ganttID, linkId);
      await globalStore.dispatch('tasksModel/backgroundDeleteLink', linkData);

      if (!routerHelper.isListViewRoute()) {
        gantt.callEvent('recalculateCriticalPath');
      }

      innerObject.handlers.hide();
    },
    openSuggestSearchPopup: function (pos, selectedTask, newValue, selectionPos, master) {
      const popup = $$(innerObject.views.suggestSearchLinksPopup.id);
      const list = $$(innerObject.views.suggestSearchLinksPopupList.id);

      innerObject.handlers.closeSuggestSearchPopup();

      const minSearchLength = 2;

      // find editing text element by carret possition
      const stringBeforeSelection = newValue.slice(0, selectionPos);
      let editElemBegin = stringBeforeSelection.lastIndexOf(',');
      editElemBegin = editElemBegin !== -1 ? editElemBegin + 1 : 0;
      let editElemEnd = newValue.indexOf(',', selectionPos);
      editElemEnd = editElemEnd !== -1 ? editElemEnd : newValue.length;
      let value = newValue.slice(editElemBegin, editElemEnd).trim();

      // check if value is start from digit or letter
      const isDigitFirst = value.search(/^\d/) === 0;
      const isSearchSuggestNeeded = value.length < minSearchLength ? isDigitFirst : true;

      if (!isSearchSuggestNeeded) {
        return;
      }

      // get all the rest items from input text
      const selectedWbs = innerObject.helpers.getSelectedWbsOptions(newValue, editElemBegin, editElemEnd);

      const allTaskIds = globalStore.getters['tasksModel/getAllTaskIds'](selectedTask.gantt_id);

      const tasksForSearch = allTaskIds.reduce((result, taskId) => {
        const data = gantt.getTask(taskId);
        const wbs =  gantt.getWBSCode(data);
        if ((data.parent && !(gantt.config.multiview && data.parent === 1))
        && !selectedWbs.includes(wbs)
        && taskId !== selectedTask.id) {
          result.push({
            id: taskId,
            value: data.text,
            wbs: wbs
          })
        }
        return result;
      }, []);

      // find tasks include search string either in name or wbs
      const filteredTasks = tasksForSearch.filter(task => {
        return task.value.toLowerCase().includes(value.toLowerCase()) || task.wbs.includes(value);
      }).sort((task1, task2) => {
        if(task2.wbs.startsWith(value)) {
          return 1
        } else if (task1.wbs.startsWith(value)) {
          return -1;
        }
        return 0;
      });

      innerObject.settings.gridLinkColumn = {
        searchValue: value,
        valueStartPos: editElemBegin,
        valueEndPos: editElemEnd,
        gridLinkInputCell: master
      };

      if (filteredTasks.length) {
        list.clearAll();
        popup.show();
        list.parse(filteredTasks);
        innerObject.helpers.defineSearchPopupPosition(pos, filteredTasks.length);
      }
    },
    closeSuggestSearchPopup: function () {
      $$(innerObject.views.suggestSearchLinksPopup.id).hide();
    },
    predecessorColumnChange: async function (value, task) {
      const values = value ? value.split(',').map(wbs => wbs.trim()) : [];
      const wbsItems =  _.uniq(values.filter(value => value.length));
      const prevLinks = task.$target || [];
      let linksForUpdate = [];

      const selectedTasks = innerObject.helpers.getTasksByWBS(task.gantt_id, wbsItems);

      innerObject.handlers.closeSuggestSearchPopup();

      if (selectedTasks.length !== wbsItems.length) {
        webix.message({ type: "info", text: __("gantt_msg_dependency_not_exist_error") });
        _state.savingTask = null;
        return false;
      }

      let deleteLinkIds = prevLinks.filter(prevLinkId => {
        return !selectedTasks.find(item => {
          return item.$source.includes(prevLinkId)
        })
      });

      linksForUpdate = deleteLinkIds.map(id => {
        const link = gantt.getLink(id)
        link.action ='delete';
        return link;
      });

      const insertLinks = selectedTasks.reduce((result, taskItem) => {
        const isExist = _.intersection(taskItem.$source, prevLinks);
        if (!isExist.length) {
          result.push({
            source: taskItem.id,
            target: task.id,
            gantt_id: task.gantt_id,
            type: 0,
            lag: 0,
            action: 'insert'
          });
        }
        return result;
      }, [])

      const isWrongLink = insertLinks.find(insLink => {
        const source = gantt.getTask(insLink.source)
        const sourceTargets = source.$target.map(id => {
          const link = gantt.getLink(id);
          return link.source;
        });

        const isExist = sourceTargets.includes(task.id);
        return insLink.source === insLink.target || gantt.isCircularLink(insLink) || isExist;
      })

      if (isWrongLink) {
        webix.message({ type: "info", text: __("gantt_msg_dependency_limit_error") });
        _state.savingTask = null;
        return false;
      }

      linksForUpdate.push(...insertLinks);

      if (!linksForUpdate.length) {
        _state.savingTask = null;
        return;
      }

      task.linkValues = innerObject.helpers.getValidWbs(selectedTasks, wbsItems);
      const data = await globalStore.dispatch('tasksModel/backgroundMassLinksChange', linksForUpdate);

      _state.savingTask = null;
      delete task.linkValues;
      if (!routerHelper.isListViewRoute()) {
        if (data.insert.length) {
          userExtAnalytics.log('gantt_link_add_done', { from: 'chart' });
          gantt.callEvent("onLinkIdChange");

          data.insert.forEach(link => {
            const type = Object.entries(gantt.config.links).find(([key, val]) => val === link.type)?.[0];

            userExtAnalytics.log('task_grid_action', { action: 'link_add', type });
          });
        }

        if (data.delete.length) {
          gantt.callEvent('recalculateCriticalPath');

          data.delete.forEach(link => {
            const type = Object.entries(gantt.config.links).find(([key, val]) => val === link.type)?.[0];

            userExtAnalytics.log('task_grid_action', { action: 'link_delete', type });
          });
        }

        const taskIdsToRefresh = linksForUpdate.filter(link => {
          if (link.action === "insert") {
            return !data.insert.find(data => {
              return data.source === link.source && data.target === link.target
            })
          }
          return false;
        }).map(link => link.target);

        if (taskIdsToRefresh.length !== data.insert.length) {
          gantt.refreshTask(task.id);
        }
      }
    },
  },
  helpers: {
    customCalculateDuration: function (start, end) {
      if (start.valueOf() > end.valueOf()) {
        return -gantt.calculateDuration(end, start);
      } else {
        return gantt.calculateDuration(start, end);
      }
    },
    setupCounterLabel: function (id) {
      let counter = $$(id);

      counter.define('label', `${__('settings_lag')} (<strong>${__('settings_' + gantt.config.duration_view)}</strong>)`);
      counter.refresh();
    },
    getStartAccordingToType: function (link, allowedTypes) {
      var source = {};

      if (link) {
        source = globalStore.getters['tasksModel/getTaskByGanttId'](link.gantt_id, link.source);

        for (var key in gantt.config.links) {
          if (gantt.config.links[key] === link.type) {
            if (allowedTypes && allowedTypes.indexOf(key) == -1) {
              return false;
            }

            switch (key) {
              case 'finish_to_start':
              case 'finish_to_finish':
                return source.end_date || gantt.calculateEndDate({
                  start_date: source.start_date,
                  duration: source.duration,
                  task: source
                });
              case 'start_to_start':
              case 'start_to_finish':
                return (new Date(source.start_date));
              default:
                return false;
            }
          }
        }
      }
    },
    getEndAccordingToType: function (link) {
      var target = globalStore.getters['tasksModel/getTaskByGanttId'](link.gantt_id, link.target);

      for (var key in gantt.config.links) {
        if (gantt.config.links[key] == link.type) {
          switch (key) {
            case 'finish_to_start':
            case 'start_to_start':
              return target.start_date;
            case 'finish_to_finish':
            case 'start_to_finish':
              return target.end_date || gantt.calculateEndDate({
                start_date: target.start_date,
                duration: target.duration,
                task: target
              });
            default:
              return false;
          }
        }
      }
    },
    getDurationViewValue: function (link, durationUnit = gantt.config.duration_view) {
      const start = innerObject.helpers.getStartAccordingToType(link);
      const end = innerObject.helpers.getEndAccordingToType(link);

      const dur = gantt.calculateDurationTo(start, end, durationUnit, link.gantt_id); //durationUnit

      return dur.duration;
    },
    customDeleteLink: function (linkId) {
      if (linkId) {
        webix.confirm({
          ok: __("common_yes"),
          cancel: __("common_no"),
          type: "confirm-error",
          text: __("gantt_msg_link_delete_confirm"),
          callback: function (result) {
            if (result) {
              innerObject.handlers.deleteLink(linkId);
            }
          }
        });
      }
    },
    getSelectedWbsOptions: function (inputValue, editElemBegin, editElemEnd) {
      const valuesBefore = inputValue.slice(0, editElemBegin);
      const valuesAfter = inputValue.slice(editElemEnd);
      return [
        ...valuesBefore ? valuesBefore.split(',').map(wbs => wbs.trim()) : [],
        ...valuesAfter ? valuesAfter.split(',').map(wbs => wbs.trim()) : []
      ]
    },
    matchedSearchStringTemplate: function (link) {
      const searchValue = innerObject.settings.gridLinkColumn.searchValue;
      const regEx = new RegExp(searchValue, "ig");
      let template = `${link.wbs} ${link.value}`;

      return template.replace(regEx, matchedText => {
        return `<span class="search-match">${matchedText}</span>`;
      });
    },
    getTasksByWBS: function(ganttId, wbsItems) {
      const tasksData = {};
      const allTaskIds = globalStore.getters['tasksModel/getAllTaskIds'](ganttId);

      allTaskIds.forEach(taskId => {
        const data = gantt.getTask(taskId);
        const wbs =  gantt.getWBSCode(data);

        tasksData[wbs] = data;
      });

      return wbsItems.reduce((result, wbsItem) => {
        const task = tasksData[wbsItem];

        if (task) {
          result.push(task)
        }

        return result;
      }, [])
    },
    getValidWbs: function (selectedTasks, wbsItems) {
      const wbsList = selectedTasks.map(task => {
        return gantt.getWBSCode(task);
      });

      return wbsItems.filter(item => {
        return wbsList.includes(item)
      }).join(', ')
    },
    addSelectedTaskWBSToValues: function(list, id) {
      const gridLinkColumn = innerObject.settings.gridLinkColumn;
      const wbs = gantt.getWBSCode(gantt.getTask(id));
      const prevValue = gridLinkColumn.gridLinkInputCell.getValue();
      const newValue = `${prevValue.slice(0, gridLinkColumn.valueStartPos)}${wbs}${prevValue.slice(gridLinkColumn.valueEndPos)}`;
      let resultValue = newValue.split(',').map(item => item.trim()).join(', ');

      if (gridLinkColumn.valueEndPos === prevValue.length) {
        resultValue = `${resultValue},`
      }

      gridLinkColumn.gridLinkInputCell.setValue(resultValue);

      list.getParentView().hide();
    },
    defineSearchPopupPosition: function (pos, itemsNumber) {
      const maxElems = 5;
      const position = {
        x: pos.x - 6
      };
      const popup = $$(innerObject.views.suggestSearchLinksPopup.id);
      const list = $$(innerObject.views.suggestSearchLinksPopupList.id);
      const popupHeight = list.config.type.height * (itemsNumber < maxElems ? itemsNumber : maxElems);
      const docHeight = document.body.clientHeight;
      const bottomHeight = docHeight - pos.bottom;

      popup.$view.style.left = `${pos.x - 6}px`;
      if (bottomHeight > popupHeight) {
        position.y = pos.bottom + 1;
        popup.getNode().classList.remove('suggest_popup_top');
        popup.$view.style.top = `${pos.bottom + 1}px`;
      } else {
        position.y = pos.top - popupHeight - 5;
        popup.getNode().classList.add('suggest_popup_top');
        popup.$view.style.top = `${pos.top - popupHeight - 5}px`;
      }

      return position;
    }
  },
  settings: {
    linkId: false,
    enablePlugin: false,
    inputParser: {},
    gridLinkColumn: {},
    afterCollabEventId: null,
    parserLocale: {
      minute: __('minute_in_time_string'),
      hour: __('hour_in_time_string'),
      day: __('day_in_time_string'),
      week: __('week_in_time_string'),
      month: __('month_in_time_string')
    },
  }
};

webix.ui({
  view: "ganttproWindowPopup",
  id: innerObject.views.settingsLinkPopup.id,
  css: "linkPopupWithSettings",
  width: 438,
  height: 240,
  zIndex: 113,
  header: {
    label: __('settings_link_settings'),
  },
  body: {
    rows: [
      {
        view: "form",
        borderless: true,
        paddingX: 24,
        paddingY: 0,
        height: 84,
        width: 438,
        css: 'settingsLinkForm',
        id: innerObject.views.settingsLinkForm.id,
        elements: [
          innerObject.views.settingsLinkCounter
        ]
      },
      {
        css: "layout-top-border",
        height: 84,
        paddingX: 24,
        paddingY: 24,
        cols: [
          innerObject.views.deleteLinkButton,
          {},
          innerObject.views.updateLinkDataButton
        ]
      }
    ]
  },
  on: {
    "onShow": function (){
      app.trigger('opened:popup:settingsLink', +innerObject.settings.linkId);
    },
    "onBeforeShow": function () {
      app.trigger("popup:show");
    },
    "onHide": function () {
      _.delay(function () {
        app.trigger('closed:popup:settingsLink')
        app.trigger("popup:hide");
      });
    }
  }
}).hide();

webix.ui({
  view: 'popupWithoutPointCustom',
  id: innerObject.views.suggestSearchLinksPopup.id,
  css: 'linkSearchSuggestPopup',
  width: 224,
  autofocus: false,
  body: {
    view: 'list',
    id: innerObject.views.suggestSearchLinksPopupList.id,
    navigation:true,
    borderless: true,
    select: true,
    type: {
      height: 36
    },
    template: innerObject.helpers.matchedSearchStringTemplate,
    on: {
      onBeforeRender: function (data) {
        const length = data.serialize().length;
        const maxItems = 5;
        let yCount = length;

        if (length > maxItems) {
          yCount = maxItems;
        }

        this.define('yCount', yCount);
        this.resize();
      },
      onItemClick: function (id) {
        const gridLinkColumn = innerObject.settings.gridLinkColumn;
        gridLinkColumn.gridLinkInputCell.focus();
        innerObject.helpers.addSelectedTaskWBSToValues(this, id);
      },
      onKeyPress: function(key) {
        if (key === 13) {
          const id = this.getSelectedId();
          innerObject.helpers.addSelectedTaskWBSToValues(this, id);
        }
      }
    },
  },
});

var outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit
  },
  helpers: innerObject.helpers,
  handlers: innerObject.handlers
};

app.on("popup:show", function () {
  $$(innerObject.views.settingsLinkPopup.id).hide();
});

app.on("import:updateLinkLag", function () {
  if (gantt.config.durationData.durationUnit !== 'hour') {
    innerObject.handlers.updateLinksLag();
  }
});

app.on("tasks:model:deleteLink", function (link_id, action) {
   if(+innerObject.settings.linkId === +link_id){
    innerObject.handlers.hide();
   }
});

export default outputObject;


