/* eslint-disable import/no-cycle */
import Vue from 'vue';
import app from '../../app';
import profileModel from '../../models/profile';
import plansModel from '../../models/plans';
import ssoModel from '../../models/sso';
import webhooksModel from '../../models/settings/webhooks';

import dateHelper from '../../helpers/dateFormats';

import TwoFaPopup from './twoFaStore';

import _ from '../../libs/lodash';

const profileSettingsStore = {
  namespaced: true,
  modules: {
    twoFaPopup: TwoFaPopup,
  },
  state: {
    planPeriod: '',
    paymentHistory: [],
  },
  getters: {
    plansPeriod: state => state.planPeriod,
    paymentHistory: state => state.paymentHistory,
  },
  mutations: {
    setPlanPeriod(state, period) {
      state.planPeriod = period;
    },
    setPaymentHistory(state, history) {
      state.paymentHistory = [...history];
    },
  },
  actions: {
    onSettingsScroll({}) {
      app.trigger('onSettingsScroll');
    },
    async sendUpdateOnServer({ dispatch }, data) {
      try {
        await profileModel.updateProfile(data);
        await dispatch('resourcesModel/reloadData', null, { root: true });
        await app.trigger('userProfile:update');
        if (data.firstName || data.lastName || data.photo) {
          app.trigger('userCustomColumnsModel:change'); // update name in dropdown select for resource custom column
        }
        gantt.render();// update name in assign for grid
        Vue.$toast.success(__('profile_saved'));

        if (data.locale) {
          location.reload();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    restoreSubscription({ }) {
      plansModel.renew(data => {
        if (data.isSuccess) {
          webix.message({ type: 'success', text: __('profile_successRenew'), expire: 10000 });
          user.team.status_code = 1;
          user.subscription.status_code = 1;
        } else {
          webix.message({ type: 'warning', text: __('profile_errorRenew'), expire: 10000 });
        }
      });
    },
    addDomainSso({ }, value) {
      const domain = ssoModel.addDomain(value);

      if (domain === 0) {
        webix.message({
          type: 'error',
          text: __('sso_error_text_when_domain_repeated'),
          expire: 10000,
        });

        return;
      }
      if (!domain) {
        return false;
      }
    },
    sendToServerToUpdateSSO({ }, data) {
      ssoModel.update(data);
    },
    sendToWebhooksModel({ }, value) {
      return webhooksModel.create(value);
    },
    getPlanPeriod({ commit }) {
      const getPaymentPlan = webix.ajax().get(`${'/payment/plan' + '?'}${Date.now()}`);

      getPaymentPlan.then(result => {
        const plan = result.json();
        // const dateTo = plan.date_to ? dateHelper.formatDateNoTime(plan.date_to) : '';
        // const dateFrom = plan.date_from ? dateHelper.formatDateNoTime(plan.date_from) : '';

        const planPeriod = {
          dateFrom: plan.date_from,
          dateTo: plan.date_to,
        };

        commit('setPlanPeriod', planPeriod);
      });
    },
    async loadPaymentHistory({ commit }) {
      const getPaymentHistory = await webix.ajax().get(`${'/payment/history/' + '?'}${Date.now()}`);
      const history = await getPaymentHistory.json();
      const checkoutToPayproActions = {
        PAYMENT_AUTHORIZED: 'OrderCharged',
        COMPLETE: 'OrderCharged',
        REFUND: 'OrderRefunded',
      };

      const filteredHistory = [];

      history.map(item => {

        const details = JSON.parse(item.details);
        const gpn = JSON.parse(item.gpn);

        if (!gpn) {
          return;
        }

        if (gpn?.isSupendedAsTeamAlreadyHasNewSubscription) {
          return;
        }

        if (checkoutToPayproActions[item.action]) {
          item.action = checkoutToPayproActions[item.action];
        }
        const actionName = __(`common_type_action_${item.action}`);

        item.date = dateHelper.formatDateNoTime(item.date);
        item.invoiceLink = details.INVOICE_LINK;
        item.actionName = actionName;
        item.chargeFailed = false;
        item.order_total = gpn?.orderFullPriceUSD || 0;

        if (item.action === 'SubscriptionSuspended'
        || item.action === 'SubscriptionRenewed'
        || item.action === 'SubscriptionTerminated') {
          item.invoiceLink = null;
        }

        if (item.action === 'OrderRefunded') {
          item.order_total = Math.abs(gpn.orderRefundPriceUSD);
        }

        if (item.action === 'SubscriptionChargeFailed') {
          item.chargeFailed = true;
          item.invoiceLink = null;
        }

        filteredHistory.push(item);
      });
      const reversedHistory = filteredHistory.reverse();

      commit('setPaymentHistory', reversedHistory);
    },
    async onApplyNewPlan({ dispatch, commit }, nextPlan) {
      await dispatch('globalPopup/close', null, { root: true });

      // if (updateResult.isUpgradeChargeWasSuccessfull) {
      $$('mainApplication').showProgress({
        type: 'icon',
      });
      // }
      const updateResult = await plansModel.upgradePlan({
        nextPlan,
        provider: user.subscription.provider,
      });

      if (!updateResult.isUpgradeChargeRequired) {
        await dispatch('globalPopup/show', {
          type: 'thankUpgrade',
        }, { root: true });
        $$('mainApplication').hideProgress();
      } else if (updateResult.isUpgradeChargeWasSuccessfull) {
        $$('mainApplication').showProgress({
          type: 'icon',
        });
      } else {
        webix.message({ type: 'error', text: __('error_while_payment_update') });
        $$('mainApplication').hideProgress();
      }
      await dispatch('loadPaymentHistory');
    },
  },
};

export default profileSettingsStore;
