// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resource_TovsMwaE {\n  display: flex;\n  gap: 4px;\n  width: 100%;\n}\n.resource_TovsMwaE .photo_wrap_yJhJCiK9 {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-size: 100%;\n  flex-shrink: 0;\n  padding: 1.667px 2.333px 2.333px 1.667px;\n}\n.resource_TovsMwaE .name_yH4DSEFl {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-wrap: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./public/views/common/VueComponents/table/cells/less/personCell.less","webpack://./personCell.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,WAAA;ACCF;ADJA;EAMI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,cAAA;EACA,wCAAA;ACCJ;ADZA;EAeI,gBAAA;EACA,uBAAA;EACA,iBAAA;ACAJ","sourcesContent":[".resource {\n  display: flex;\n  gap: 4px;\n  width: 100%;\n\n  .photo_wrap {\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    background-size: 100%;\n    flex-shrink: 0;\n    padding: 1.667px 2.333px 2.333px 1.667px;\n  }\n\n  .name {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    text-wrap: nowrap;\n  }\n}",".resource {\n  display: flex;\n  gap: 4px;\n  width: 100%;\n}\n.resource .photo_wrap {\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-size: 100%;\n  flex-shrink: 0;\n  padding: 1.667px 2.333px 2.333px 1.667px;\n}\n.resource .name {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-wrap: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resource": "resource_TovsMwaE",
	"photo_wrap": "photo_wrap_yJhJCiK9",
	"name": "name_yH4DSEFl"
};
export default ___CSS_LOADER_EXPORT___;
