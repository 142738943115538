<template>
  <div
    :class="[$style[itemClass],$style[itemSizeClass]]"
    :style="{
      maxWidth:`${randomValues[width]}px` || `100%`,
      width: `${randomValues[width]}px` || `100%`,
    }"
  />
</template>

<script>

export default {
  name: 'SkeletonItem',
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },

  computed: {
    itemClass() {
      return `skeleton_${this.type}`;
    },
    itemSizeClass() {
      return `skeleton_${this.type}_${this.size}`;
    },

    randomValues() {
      const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

      const big = getRandomNumber(80, 105);
      const wide = getRandomNumber(50, 99);
      const tiny = getRandomNumber(40, 105);
      const middle = getRandomNumber(44, 249);
      const small = getRandomNumber(45, 70);

      return {
        big, wide, tiny, middle, small,
      };
    },

  },
};
</script>

<style module lang="less">

.skeleton_square {
  border-radius: 4px;
  background-color: #F2F2F2;

  &_bg {
    width: 20px;
    height: 20px;
  }

  &_md {
    width: 19px;
    height: 19px;
  }

  &_sm {
    width: 16px;
    height: 16px;
  }

  &_lst {
    margin-right: 12px;
    width: 17px;
    height: 17px;
  }
}

.skeleton_bar {
  border-radius: 4px;
  background-color: #F2F2F2;
  margin-right: 8px;
}

.skeleton_donut {
  border-radius: 50%;
  background-color: #FFF;
  border-color: #F2F2F2;
  border-style: solid;
}

.skeleton_circle {
  border-radius: 50%;
  margin-right: 2px;
  background-color: #F2F2F2;

  &_bg {
    width: 23px;
    height: 23px;
  }

  &_sm {
    width: 20px;
    height: 20px;
  }
}

</style>
