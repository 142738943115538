var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("onboarding-section", {
    attrs: { "animate-leave": _vm.animateLeave },
    scopedSlots: _vm._u([
      {
        key: "right",
        fn: function () {
          return [
            _c(
              "div",
              { class: _vm.$style.right__wrapper },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.select__wrapper,
                      _vm.animateEnter && !_vm.animateLeave
                        ? _vm.$style.animateEnter
                        : "",
                      _vm.animateLeave && !_vm.animateEnter
                        ? _vm.$style.animateLeave
                        : "",
                    ],
                  },
                  _vm._l(_vm.featuresItems, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        class: [
                          _vm.$style.item__wrapper,
                          _vm.selectedItems.includes(item.value)
                            ? _vm.$style.item__wrapper_selected
                            : "",
                        ],
                        attrs: {
                          id:
                            "gp_autotest_registration_5_step_selectItem_" +
                            item.value,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleClickItem(item.value)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style["item__inner-wrapper"] },
                          [
                            _vm.selectedItems.includes(item.value)
                              ? _c("svg-sprite", {
                                  class: _vm.$style.selected_icon,
                                  attrs: {
                                    name: "check",
                                    size: 20,
                                    color: "#ffffff",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.label))]),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "portal",
                  { attrs: { to: "onboarding-footer" } },
                  [
                    _c("vgp-button", {
                      class: [
                        _vm.$style.submit_button,
                        _vm.animateEnter && !_vm.animateLeave
                          ? _vm.$style.animateEnter
                          : "",
                        _vm.animateLeave && !_vm.animateEnter
                          ? _vm.$style.animateLeave
                          : "",
                      ],
                      attrs: {
                        id: "gp_autotest_registration_5_step_submit_button",
                        type: !_vm.isAnyItemSelected ? "text-green" : "green",
                        label: !_vm.isAnyItemSelected
                          ? _vm.locales("onboarding_skip_step_btn_label")
                          : _vm.locales("onboarding_continue_btn_label"),
                        small: "",
                      },
                      on: { onClick: _vm.handleButtonClick },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }