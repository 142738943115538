<template>
  <onboarding-section :animate-leave="animateLeave">
    <template #right>
      <div :class="$style.right__wrapper">
        <div
          :class="[
            $style.fields__wrapper,
            animateEnter && !animateLeave ? $style.animateEnter : '',
            animateLeave && !animateEnter ? $style.animateLeave : '',
          ]"
        >
          <input
            id="gp_autotest_registration_email_input"
            type="text"
            autocomplete="username"
            :value="form.email"
            hidden
          >
          <div :class="$style.fullName__wrapper">
            <vgp-label-slot
              :label="locales('app_welcome_form_first_name')"
              label-asterix
              :class="$style.input__wrap_narrow"
            >
              <template #body>
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="firstNameValidationProvider"
                  :rules="{ required: true }"
                  vid="firstName"
                  :mode="customValidationProviderMode"
                >
                  <vgp-input
                    id="gp_autotest_registration_firstName_input"
                    ref="firstName"
                    v-model.trim="form.firstName"
                    maxlength="250"
                    :is-error="!!errors[0]"
                    :placeholder="locales('app_welcome_form_first_name_placeholder')"
                    @onFocus="$refs.firstNameValidationProvider.reset()"
                  />
                </ValidationProvider>
              </template>
            </vgp-label-slot>
            <vgp-label-slot
              :label="locales('app_welcome_form_last_name')"
              label-asterix
              :class="$style.input__wrap_narrow"
            >
              <template #body>
                <div>
                  <ValidationProvider
                    v-slot="{ errors }"
                    ref="lastNameValidationProvider"
                    :rules="{ required: true }"
                    vid="lastName"
                    :mode="customValidationProviderMode"
                  >
                    <vgp-input
                      id="gp_autotest_registration_lastName_input"
                      ref="lastName"
                      v-model.trim="form.lastName"
                      maxlength="250"
                      :is-error="!!errors[0]"
                      :placeholder="locales('app_welcome_form_last_name_placeholder')"
                      @onFocus="$refs.lastNameValidationProvider.reset()"
                    />
                  </ValidationProvider>
                </div>
              </template>
            </vgp-label-slot>
          </div>

          <vgp-label-slot
            v-if="isFieldShown('user_company')"
            :label="locales('onboarding_business_label')"
          >
            <template #body>
              <vgp-input
                id="gp_autotest_registration_userCompany_input"
                v-model="form.user_company"
                maxlength="250"
                :images-left="teamLabel.favicon ? [teamLabel.favicon] : []"
                :placeholder="locales('onboarding_business_placeholder')"
              />
            </template>
          </vgp-label-slot>

          <vgp-label-slot
            v-if="isFieldShown('password')"
            :label="locales('onboarding_password_label')"
            label-asterix
            :class="$style.input__wrap"
          >
            <template #body>
              <tooltip
                position="left"
                :content="locales('pass_strength_descr')"
              >
                <template #body>
                  <ValidationProvider
                    v-slot="{ errors }"
                    ref="passwordValidationProvider"
                    rules="required|min:8"
                    vid="password"
                    :custom-messages="{
                      min: locales('validation_error_password_min_length'),
                      required: locales('validation_error_password_required'),
                    }"
                    :mode="customValidationProviderMode"
                  >
                    <vgp-input
                      id="gp_autotest_registration_password_input"
                      ref="password"
                      :value="form.password"
                      :is-error="!!errors[0] || isPasswordCommonError"
                      :placeholder="locales('onboarding_password_placeholder')"
                      :input-type="passwordInputType"
                      :icons-right="[passwordIconConfig]"
                      @input="handlePasswordInput"
                      @onFocus="handleOnFocusPassword"
                      @onBlur="handleOnBlurPassword"
                    />
                    <span
                      v-if="errors[0] || isPasswordCommonError"
                      :class="$style.input__error"
                    >
                      {{ errors[0] || locales('validation_error_common_password') }}
                    </span>
                  </ValidationProvider>
                </template>
              </tooltip>
              <password-strength-indicator
                v-if="passwordStrengthState.show"
                v-bind="passwordStrengthState"
              />
            </template>
          </vgp-label-slot>

          <div
            v-if="currentOnboardingFlow.displayedFields.length <= 2"
            :class="$style.container_checkbox"
            @click="handleCheckbox"
          >
            <vgp-checkbox
              :size="16"
              color="#007063"
              :checked="checkPolicyAndTerms"
              :is-error="isCheckboxError"
              @onClick="handleCheckbox"
            />
            <div
              :class="[$style.container_checkbox_label, isCheckboxError ? $style.container_checkbox_label_error : '']"
            >
              <p
                v-html="locales('onboarding_checkbox_policy_and_terms')"
              />
            </div>
          </div>
        </div>
      </div>

      <vgp-button
        id="gp_autotest_registration_submit_button"
        :class="[
          $style.form__button,
          animateEnter && !animateLeave ? $style.animateEnter : '',
          animateLeave && !animateEnter ? $style.animateLeave : ''

        ]"
        :disabled="isSubmitButtonDisabled"
        :is-loading="isFormSubmitting"
        type="green"
        :label="locales('onboarding_create_account_btn_label')"
        @onClick="submit"
      />
    </template>
  </onboarding-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { extend } from 'vee-validate';
import { regex, required } from 'vee-validate/dist/rules';
import OnboardingSection from '../OnboardingSection.vue';
import VgpLabelSlot from '../../../common/VueComponents/label/vgpLabelSlot.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import Tooltip from '../../../common/VueComponents/tooltips/tooltip.vue';
import profileModel from '../../../../models/profile';
import app from '../../../../app';
import profile from '../../../../models/profile';
import newProjectOutputObject from '../../../newProject/newProject';
import projectsModel from '../../../../models/projects';
import constants from '../../../../helpers/constants';
import rights from '../../../../components/rights/index';
import teamModel from '../../../../models/team';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';
import passwordMixin from '../../../../mixins/password';
import PasswordStrengthIndicator from '../../../common/VueComponents/password/PasswordStrengthIndicator.vue';

extend('required', required);
extend('regex', regex);

export default {
  name: 'PersonalInfo',
  components: {
    PasswordStrengthIndicator,
    OnboardingSection,
    VgpLabelSlot,
    VgpInput,
    Tooltip,
  },
  mixins: [passwordMixin],
  props: {
    teamLabel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      locales: __,
      form: {
        firstName: window.user.firstName || null,
        lastName: window.user.lastName || null,
        user_company: this.teamLabel.name || null,
        password: null,
        email: window.user.email,
      },
      animateLeave: false,
      animateEnter: false,
      passwordInputType: 'password',
      isFormSubmitting: false,
      checkPolicyAndTerms: false,
      isCheckboxError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentOnboardingFlow: 'onboarding/getCurrentOnboardingFlow',
      isRegistrationViaSocials: 'onboarding/isRegistrationViaSocials',
    }),
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
    passwordIconConfig() {
      if (this.passwordInputType === 'password') {
        return {
          name: 'visible',
          handler: () => this.passwordInputType = 'text',
        };
      }

      return {
        name: 'hiden',
        handler: () => this.passwordInputType = 'password',
      };
    },
    isSubmitButtonDisabled() {
      const isSomeRequiredFieldEmpty = this.currentOnboardingFlow.requiredRegistrationFields.some(field => !this.form[field]);

      let invalidPasswordLength = false;
      let weakPasswordStrength = false;

      if (this.isFieldShown('password') && this.form.password) {
        invalidPasswordLength = this.form.password.length < constants.MIN_PASSWORD_LENGTH;
        weakPasswordStrength = this.passwordStrengthState.strength <= 1;
      }

      const invalidFullName = !this.form.firstName || !this.form.lastName;

      return [
        isSomeRequiredFieldEmpty,
        invalidPasswordLength,
        invalidFullName,
        weakPasswordStrength,
        this.isPasswordCommonError,
      ].filter(Boolean).length;
    },
    amountOfDisplayedFields() {
      return this.currentOnboardingFlow.displayedFields.length;
    },
  },
  watch: {
    teamLabel: {
      handler(val) {
        if (val.name) {
          this.form.user_company = val.name;
        }
      },
    },
    'form.password': function (value) {
      if (value === '') {
        this.disableGoogleSuggestionsForPassword();
      }
      this.setPasswordStrengthState(value);
    },
  },
  mounted() {
    if (rights.account.isOwner()) {
      userExtAnalytics.log('welcome_open');
      userExtAnalytics.log('account_goal');
      userExtAnalytics.log('onboarding', { type: this.analyticType, step: 'start', action: ANALYTIC_ACTIONS.DONE });
    }

    this.$nextTick(() => {
      this.animateEnter = true;
      this.$refs.firstName.$refs.input_field_icons.focus();
    });
  },
  methods: {
    isFieldShown(fieldName) {
      return this.currentOnboardingFlow.displayedFields.includes(fieldName);
    },
    handlePasswordInput(value) {
      this.form.password = value;
      this.debounceCheckPasswordOnCommon(value, 500);
    },
    getSettingsData() {
      const settingsData = {};

      if (this.isFieldShown('user_company')) {
        settingsData.user_company = this.form.user_company;
      }

      return settingsData;
    },
    getProfileData() {
      const profileData = {};

      if (this.isFieldShown('password')) {
        profileData.password = this.form.password;
      } else {
        profileData.password = 'NOT_REQUIRED';
      }

      profileData.firstName = this.form.firstName;
      profileData.lastName = this.form.lastName;

      return profileData;
    },
    updateInvitedTeamMemberResource(userData) {
      const resource = this.$store.getters['resourcesModel/getResourceByUserId'](userData.id);

      if (resource) {
        const updatedResourceData = {
          resourceId: resource.id,
          update: {
            name: `${userData.firstName} ${userData.lastName}`,
            photo: userData.photo,
          },
        };

        this.$store.dispatch('resourcesModel/updateResource', updatedResourceData);
      }
    },
    async submit() {
      if (this.isSubmitButtonDisabled || this.isFormSubmitting) {
        return;
      }
      if (!this.checkPolicyAndTerms && this.currentOnboardingFlow.displayedFields.length <= 2) {
        this.isCheckboxError = true;

        return;
      }

      const settingsData = this.getSettingsData();

      if (Object.keys(settingsData).length) {
        profile.saveSettings(settingsData);
        settingsData.user_company && teamModel.changeName(settingsData.user_company);
      }

      const profileData = this.getProfileData();

      this.isFormSubmitting = true;
      profileModel.updateProfile(profileData).then(async updatedUserData => {
        if (this.isRegistrationViaSocials) {
          window.user.isUserRegisteredViaSocialOrSSO = true;
        }

        app.trigger('userProfile:update');

        const hasUserAnyProject = !!projectsModel.getAllProjects().length;

        if (hasUserAnyProject || (!hasUserAnyProject && !rights.account.isOwner())) {
          if (rights.account.isOwner()) {
            userExtAnalytics.log('onboarding', { type: this.analyticType, step: 'finish', action: ANALYTIC_ACTIONS.DONE });
            userExtAnalytics.log('project_goal');
          }
          this.updateInvitedTeamMemberResource(updatedUserData);
          await this.$store.dispatch(
            'user/updateActivitySettings',
            { activityName: constants.USER_ACTIVITIES.ONBOARDING, settings: { isFinished: true } },
          );
          this.$router.push({ name: 'app' });

          return;
        }

        if (newProjectOutputObject.createNewProjectWithoutDialogIfUserCameFromTemplate()) {
          userExtAnalytics.log('new_first_project_automatically_created_from_landing_template');

          return;
        }

        if (newProjectOutputObject.createNewProjectWithoutDialogIfUserCameFromMpp()) {
          app.trigger('gantt:progress:show');
          userExtAnalytics.log('new_first_project_automatically_created_from_landing_mpp');

          return;
        }

        this.animateLeave = true;
        this.animateEnter = false;
        setTimeout(() => {
          this.$store.dispatch('onboarding/nextStep');
          this.isFormSubmitting = false;
        }, 750);

        if (rights.account.isOwner()) {
          userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
        }

        if (rights.account.isOwner() && window.GT && GT.env === 'production') {
          window.userExtAnalytics.initHotJarAndStartRecording();
        }
      })
        .catch(error => {
          if (error === 'COMMON_PASSWORD') {
            this.isPasswordCommonError = true;
          }
          this.isFormSubmitting = false;
        });
    },
    handleOnFocusPassword() {
      this.disableGoogleSuggestionsForPassword();
      if (!this.isPasswordCommonError && this.form.password) {
        this.passwordStrengthState.show = true;
      }
      this.$refs.passwordValidationProvider.reset();
    },
    handleOnBlurPassword() {
      this.passwordStrengthState.show = false;
    },
    disableGoogleSuggestionsForPassword() {
      this.$refs.password.$refs.input_field_icons.setAttribute('readonly', '');
      setTimeout(() => {
        this.$refs.password.$refs.input_field_icons.removeAttribute('readonly');
      }, 0);
    },
    customValidationProviderMode() {
      return {
        on: ['onBlur'],
      };
    },
    handleCheckbox() {
      this.checkPolicyAndTerms = !this.checkPolicyAndTerms;
      !this.checkPolicyAndTerms ? this.isCheckboxError = true : this.isCheckboxError = false;
    },
  },
};
</script>

<style lang="less" module>
@import "../../../../less/_variables.less";

.right__wrapper {
  width: 280px;
  margin-right: 40px;
}

.fullName__wrapper {
  display: flex;
  gap: 12px;
}

.input {
  &__wrap {
    position: relative;
    :global(.tooltip_label) {
      width: 100%;
    }
    &_narrow {
      width: 134px;
    }
  }

  &__error {
    position: absolute;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #DD3636;
    margin-top: 4px;
  }
}

.password__hint {
  display: flex;
  gap: 6px;
  position: absolute;
  font-family: Lato-Regular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin-top: 7px;
}

.form__button {
    margin-top: 47px;
    opacity: 0;
    transform: translateY(40px);
    transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);
    bottom: 50px;
    position: fixed;
    width: 280px;
    margin-left: -2px;

  &_mt140 {
    //margin-top: 140px;
  }
    &_mt160 {
    margin-top: 160px;
  }

  &_mt230 {
    margin-top: 230px;
  }

  &.animateLeave {
    transition: opacity .2s, transform .2s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .3s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

.fields__wrapper {
  opacity: 0;
  transform: translateY(40px);
  display: flex;
  flex-direction: column;
  gap: 22px;
  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }

    .container_checkbox {
        display: flex;
        align-items: start;
        cursor: pointer;
        &_label{
            color: #666666;
            font-family: Lato-Regular, sans-serif;
            font-size: 12px !important;
            line-height: 18px;
            margin-left: 10px;
            margin-bottom: 0;
            width: 240px;
            top: -2px;
            position: relative;
            cursor: pointer;
            &_error {
                color:  #e53935;
            }
        }
        a {
            color: #666666;
            outline: none;
            cursor: pointer;
            -webkit-transition: color 120ms;
            transition: color 120ms;
            text-decoration: underline;
        }
        a:hover {
            color: #008a7a;
        }
        a:active {
            color: #00564c;
        }
    }
}

</style>
