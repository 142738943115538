<template>
  <div :class="$.widget_wrapper">
    <div :class="$.header">
      <div :class="$.title">{{ locale(`chart_${chart.key}_title`) }}</div>
      <div :class="$.chart_toolbox">
      <div :class="$.year_switch">
        <div :class="$.previous" @click="setCurrent(-1)">
          <svg-sprite :type="'bold'" :name="'arrow-left'" :size="24" :color="minYear === currentYear ? '#CCCCCC' : '#808080'" />
        </div>
        <div :class="$.current">{{ currentYear }} {{ locale('year') }}</div>
        <div :class="$.next" @click="setCurrent(1)">
          <svg-sprite :type="'bold'" :name="'arrow-right'" :size="24" :color="maxYear === currentYear ? '#CCCCCC' : '#808080'"  />
        </div>
      </div>
    </div>
    </div>
    <milestones-chart
      :data="data"
      :currentYear="currentYear"
      :small="small"
      @setYearRange="setYearRange"
    />
  </div>
</template>

<script>
import MilestonesChart from './MilestonesChart.vue';

export default {
  name: 'MilestonesWidget',
  components: { MilestonesChart },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: {}
    }
  },
  mounted() {
    this.small = window.screen.height <= 768;
  },
  data() {
    return {
      locale: __,
      currentYear: new Date().getFullYear(),
      minYear: new Date().getFullYear(),
      maxYear: new Date().getFullYear(),
      small: false
    }
  },
  methods: {
    setCurrent(delta) {
      const result = this.currentYear + delta;

      if (result < this.minYear || result > this.maxYear) {
        return;
      }

      this.currentYear = result;
    },
    setYearRange({minYear, maxYear}) {
      this.minYear = minYear;
      this.maxYear = maxYear;

      if (minYear > this.currentYear) {
        this.currentYear = minYear;
      } else if (maxYear < this.currentYear) {
        this.currentYear = maxYear;
      }
    }
  }
}
</script>

<style module="$" src="./less/milestonesWidget.less"></style>