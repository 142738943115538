<template>
  <div>
    <webix-ui :config="webixUI"/>
    <router-view></router-view>
  </div>
</template>

<script>
  //import { webixUI, init } from './reportPage';
  import timeLogListView from './include/timeLogList';
  import costsListView from './include/costsList';

  const reportsMap = {
    'timeloglist': timeLogListView,
    'costslist': costsListView
    //
  };

  export default {
    props: {
      type: {
        type: String,
        default: null
      },
    },
    name: 'reportPage',
    data() {
      let component = this;
      return {
        webixUI: reportsMap[component.type].webixUI
      }
    },
    mounted: function () {
      let component = this;

      this.$nextTick(function () {
        reportsMap[component.type].init()
      });
    }
  }
</script>

<style scoped>

</style>
