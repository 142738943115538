<template>
  <div
    ref="globalPopup"
    class="global-popup"
  >
    <div class="container">
      <closer
        v-if="!['confirm', 'thankUpgrade', 'permissionPopup', 'paymentConfirm'].includes(config.type)"
        class="global-popup-closer"
        :on-close="closeClick"
      />

      <core-confirm-popup
        :show="config.type === 'confirm'"
        :text="locale(config.message)"
        :ok-button="{ type: config.popupType === 'confirm' ? 'primary' : 'destructive', title: locale(config.ok) }"
        :cancel-button="{ type: 'secondary', title: locale(config.cancel) }"
        :close-icon="config.closeIcon"
        :popup-width="config.popupWidth"
        :additional-text="config.additionalText"
        :title="config.title"
        :button-width="config.buttonWidth"
        @onClickOk="config.confirm(config.data)"
        @onClickCancel="closeClick"
      />

      <confirm-popup
        v-if="config.type === 'paymentConfirm'"
        :config="config"
      />
      <two-fa-popup
        v-if="config.type === 'twoFaPopup'"
      />
      <permission-popup
        v-if="config.type === 'permissionPopup'"
      />
      <payment-popup
        v-if="config.type === 'paymentPopup'"
      />
      <upgrade-plan-popup
        v-if="config.type === 'upgradePlan'"
        :config="config"
      />
      <thank-upgrade-popup
        v-if="config.type === 'thankUpgrade'"
      />
    </div>
  </div>
</template>

<script>
import closer from '../../closer/closer.vue';
import confirmPopup from '../confirmPopup/confirmPopup.vue';
import twoFaPopup from '../../../../profileSettings/include/twoFaActivationPopup.vue';
import permissionPopup from '../../../../profileSettings/include/permissionPopup.vue';
import paymentPopup from '../../../../paymentPlans/paymentPopup/paymentPopup.vue';
import upgradePlanPopup from '../../../../paymentPlans/upgradePlanPopup/upgradePlanPopup.vue';
import thankUpgradePopup from '../../../../paymentPlans/thankUpgradePopup/thankUpgradePopup.vue';
import coreConfirmPopup from '../coreConfirmPopup/coreConfirmPopup.vue';

export default {
  components: {
    closer,
    twoFaPopup,
    permissionPopup,
    confirmPopup,
    paymentPopup,
    upgradePlanPopup,
    thankUpgradePopup,
    coreConfirmPopup,
  },
  data() {
    return {
      locale: __,
    };
  },
  computed: {
    config() {
      return this.$store.getters['globalPopup/config'];
    },
  },
  mounted() {
    const globalPopup = this.$refs.globalPopup;

    setTimeout(() => {
      globalPopup.classList.add('show');
    }, 0);
  },
  beforeDestroy() {
    const globalPopup = this.$refs.globalPopup;

    globalPopup.classList.remove('show');
  },
  methods: {
    closeClick() {
      this.config.cancelCallback && this.config.cancelCallback();
      this.$store.dispatch('globalPopup/close');
    },
  },
};
</script>

<style src='./less/globalPopup.less'></style>
