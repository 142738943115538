var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        {
          class: [
            _vm.$style.header_toolbar,
            "header_toolbar_common",
            _vm.config.disable ? _vm.$style.disable : "",
          ],
        },
        [
          _c("div", { class: _vm.$style.left_side }, [
            _vm.config.ui.includes("boardType")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [_c("board-types")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("workloadType")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [_c("workload-types")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("rangeCalendar")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [_c("workload-range")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("calendarStep")
              ? _c("div", [_c("calendar-step")], 1)
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("calendarToday")
              ? _c(
                  "div",
                  { class: [_vm.$style.btn_group, _vm.$style.delimiter_left] },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.$style.text_button,
                        on: { click: _vm.onClickToday },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.locales("calendar_today_btn")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("tasksCount")
              ? _c("div", { class: _vm.$style.btn_group }, [
                  _c("div", { class: _vm.$style.text_label }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locales("tasks_label")) +
                        "\n        "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.listTasksCount))]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("myTasks")
              ? _c("div", { class: _vm.$style.btn_group }, [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.$style.text_button,
                        _vm.$style.my_tasks_btn,
                        _vm.isMyTasksFilter ? _vm.$style.active : "",
                      ],
                      on: { click: _vm.onClickMyTasks },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("common_my_incompleted_tasks")) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("missedFeature")
              ? _c("div", { class: _vm.$style.btn_group }, [
                  _c(
                    "div",
                    {
                      class: _vm.$style.text_button,
                      on: { click: _vm.onClickMissedFeature },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("header_contact_btn")) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("reportInfo") &&
            (_vm.reportInfo.total_locale || _vm.reportInfo.range)
              ? _c("div", { class: _vm.$style.btn_group }, [
                  _c(
                    "div",
                    { class: [_vm.$style.text_label, _vm.$style.row] },
                    [
                      _vm.reportInfo.total_locale
                        ? _c("div", { class: _vm.$style.group }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.locales(_vm.reportInfo.total_locale)
                                ) +
                                ":\n          "
                            ),
                            _c("span", [_vm._v(_vm._s(_vm.reportInfo.total))]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.reportInfo.range
                        ? _c("div", { class: _vm.$style.group }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.reportInfo.range) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("groupBy")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _c("group-by-combo", {
                      attrs: {
                        "on-select": _vm.onChangeGroupBy,
                        "button-colors": _vm.buttonColors,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("bulkChange") ||
            _vm.config.ui.includes("overdue") ||
            _vm.config.ui.includes("criticalPath") ||
            _vm.config.ui.includes("baseline")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _vm.config.ui.includes("bulkChange")
                      ? _c("tooltip", {
                          class: [
                            _vm.$style.tooltip_wrapper,
                            _vm.$style.inner_btn,
                          ],
                          attrs: {
                            content: _vm.locales("header_toolbar_bulk_tooltip"),
                            position: "bottom-start",
                            interactive: true,
                            hide: !_vm.checkPricingAccess("bulk_change"),
                            "with-animation": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("vgp-icon-button", {
                                      class: [
                                        _vm.checkPricingAccess("bulk_change")
                                          ? ""
                                          : "pricing-tooltip",
                                      ],
                                      attrs: {
                                        icon: {
                                          name: "checkbox-filled",
                                          size: 20,
                                        },
                                        colors: _vm.buttonColors,
                                        active: _vm.isBulkChange,
                                        "data-feature": "bulk_change",
                                        "data-position": "bottom",
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.onClickBulkChange(
                                            "bulk_change"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            312302387
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.ui.includes("overdue")
                      ? _c("tooltip", {
                          class: [
                            _vm.$style.tooltip_wrapper,
                            _vm.$style.inner_btn,
                          ],
                          attrs: {
                            content: _vm.locales(
                              "header_toolbar_overdue_tooltip"
                            ),
                            position: "bottom-start",
                            interactive: true,
                            hide: !_vm.checkPricingAccess("overdue_tasks"),
                            "with-animation": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("vgp-icon-button", {
                                      class: [
                                        _vm.checkPricingAccess("overdue_tasks")
                                          ? ""
                                          : "pricing-tooltip",
                                      ],
                                      attrs: {
                                        icon: { name: "overdue", size: 20 },
                                        colors: _vm.buttonColors,
                                        active:
                                          _vm.isOverdueTasks &&
                                          _vm.checkPricingAccess(
                                            "overdue_tasks"
                                          ),
                                        "data-feature": "overdue_tasks",
                                        "data-position": "bottom",
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.onClickOverdue(
                                            "overdue_tasks"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2271237893
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.ui.includes("criticalPath")
                      ? _c("tooltip", {
                          class: [
                            _vm.$style.tooltip_wrapper,
                            _vm.$style.inner_btn,
                          ],
                          attrs: {
                            content: _vm.locales(
                              "header_toolbar_critical_path_tooltip"
                            ),
                            position: "bottom-start",
                            interactive: true,
                            hide: !_vm.checkPricingAccess("overdue_tasks"),
                            "with-animation": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("vgp-icon-button", {
                                      attrs: {
                                        icon: {
                                          name: "critical-path",
                                          size: 20,
                                        },
                                        colors: _vm.buttonColors,
                                        active: _vm.isCriticalPath,
                                      },
                                      on: { onClick: _vm.onClickCriticalPath },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2106129289
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.ui.includes("baseline")
                      ? _c("tooltip", {
                          class: [
                            _vm.$style.tooltip_wrapper,
                            _vm.$style.inner_btn,
                          ],
                          attrs: {
                            content: _vm.locales(
                              "header_toolbar_baseline_tooltip"
                            ),
                            position: "bottom-start",
                            interactive: true,
                            "with-animation": false,
                            hide: _vm.isShowBaselinePopup,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("baseline", {
                                      attrs: {
                                        "button-colors": _vm.buttonColors,
                                      },
                                      on: {
                                        isShowBaselinePopup: function ($event) {
                                          return _vm.changeShowBaselinePopup(
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1451580123
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("expand") ||
            _vm.config.ui.includes("collapse")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _vm.config.ui.includes("expand")
                      ? _c("tooltip", {
                          class: _vm.$style.tooltip_wrapper,
                          attrs: {
                            content: _vm.locales(
                              "header_toolbar_expand_tooltip"
                            ),
                            position: "bottom",
                            interactive: true,
                            "with-animation": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("vgp-icon-button", {
                                      attrs: {
                                        icon: { name: "expand-1", size: 20 },
                                        label: _vm.locales("expand_all"),
                                        colors: _vm.buttonColors,
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.onClickExpandCollapse(
                                            "expand"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1957472327
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.ui.includes("collapse")
                      ? _c("tooltip", {
                          class: _vm.$style.tooltip_wrapper,
                          attrs: {
                            content: _vm.locales(
                              "header_toolbar_collapse_tooltip"
                            ),
                            position: "bottom",
                            interactive: true,
                            "with-animation": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("vgp-icon-button", {
                                      attrs: {
                                        icon: { name: "collaps-1", size: 20 },
                                        label: _vm.locales("collapse_all"),
                                        colors: _vm.buttonColors,
                                      },
                                      on: {
                                        onClick: function ($event) {
                                          return _vm.onClickExpandCollapse(
                                            "collapse"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3088680271
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("sort")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _c("tooltip", {
                      class: _vm.$style.tooltip_wrapper,
                      attrs: {
                        content: _vm.locales(
                          "header_toolbar_cascade_sorting_tooltip"
                        ),
                        position: "bottom",
                        interactive: true,
                        "with-animation": false,
                        hide: !_vm.checkPricingAccess("cascade_sorting"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("vgp-icon-button", {
                                  class: [
                                    _vm.checkPricingAccess("cascade_sorting")
                                      ? ""
                                      : "pricing-tooltip",
                                  ],
                                  attrs: {
                                    icon: { name: "cascade-sorting", size: 20 },
                                    label: _vm.locales("cascade_sorting"),
                                    colors: _vm.buttonColors,
                                    "data-position": "bottom",
                                    "data-feature": "cascade_sorting",
                                  },
                                  on: {
                                    onClick: function ($event) {
                                      return _vm.onClickSorting(
                                        "cascade_sorting"
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        863469919
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("addTask") && _vm.accessToCreateTask
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [_c("add-task-btn")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("addProject")
              ? _c("div", [_c("add-project-button")], 1)
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.right_side }, [
            _vm.config.ui.includes("filter")
              ? _c("div", { class: _vm.$style.btn_group }, [
                  _c(
                    "div",
                    { class: _vm.$style.filter },
                    [
                      _vm.isActiveFilter &&
                      _vm.config.ui.includes("filterRefresh")
                        ? _c("vgp-icon-button", {
                            class: _vm.$style.filter_refresh,
                            attrs: {
                              icon: { name: "update", size: 20 },
                              colors: _vm.buttonColors,
                              active: _vm.isActiveFilter,
                            },
                            on: { onClick: _vm.onClickRefreshFilter },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("vgp-icon-button", {
                        class: [
                          _vm.$style.filter_button,
                          "header-filter-btn",
                          "js_filter_btn",
                        ],
                        attrs: {
                          icon: { name: "filter", size: 20 },
                          label: _vm.locales("common_filter"),
                          colors: _vm.buttonColors,
                          active: _vm.isActiveFilter || _vm.isShowFilter,
                        },
                        on: { onClick: _vm.onClickFilter },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("zoom")
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _c("zoom-slider", {
                      attrs: { value: _vm.zoom },
                      on: { change: _vm.onChangeZoom },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.ui.includes("export") && _vm.accessToExport
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _c("tooltip", {
                      class: _vm.$style.tooltip_wrapper,
                      attrs: {
                        content: _vm.locales(_vm.getExportTooltipKey),
                        position: "bottom-end",
                        interactive: true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("vgp-icon-button", {
                                  class: ["js_export_button"],
                                  attrs: {
                                    icon: { name: "link-2", size: 20 },
                                    label: _vm.locales("common_export"),
                                    colors: _vm.buttonColors,
                                  },
                                  on: { onClick: _vm.onClickExport },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1478314236
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.viewSettings
              ? _c(
                  "div",
                  { class: _vm.$style.btn_group },
                  [
                    _c("view-settings", {
                      attrs: {
                        config: _vm.config.viewSettings,
                        "button-colors": _vm.buttonColors,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.config.ui.includes("sort")
            ? _c("core-confirm-popup", {
                attrs: {
                  show: _vm.isShowSortingPopup,
                  "popup-width": "440",
                  "close-icon": "",
                  title: _vm.locales("cascade_sorting"),
                  text: _vm.locales("cascade_popup_text"),
                  "cancel-button": {
                    title: _vm.locales("common_cancel"),
                    type: "secondary",
                  },
                  "ok-button": {
                    title: _vm.locales("common_yes"),
                    type: "primary",
                  },
                },
                on: {
                  onClickCancel: _vm.onSortingConfirmCancel,
                  onClickOk: _vm.onSortingConfirmOk,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _c("div", [_c("skeleton")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }