var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$.card_block, _vm.$[_vm.type]],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.itemClick($event)
        },
      },
    },
    [
      _vm.type === "portfolio"
        ? [
            _c(
              "div",
              { class: _vm.$.portfolio_icon },
              [
                _c("svg-sprite", {
                  attrs: { type: "regular", name: "portfolio", size: 72 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.$.card_details }, [
              _c(
                "div",
                { class: _vm.$.name_wrapper },
                [
                  _c(
                    "div",
                    {
                      class: [_vm.$.star, _vm.favorite && _vm.$.active],
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.favClick($event)
                        },
                      },
                    },
                    [
                      _c("svg-sprite", {
                        attrs: {
                          type: _vm.favorite ? "bold" : "regular",
                          name: "star",
                          size: 20,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isEditing
                    ? _c("div", { class: _vm.$.item_name }, [
                        _vm._v(_vm._s(_vm.item.name)),
                      ])
                    : _c("div", { class: _vm.$.item_input }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name",
                            },
                          ],
                          ref: "input",
                          attrs: { type: "text" },
                          domProps: { value: _vm.name },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                            blur: _vm.updateName,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              return _vm.updateName($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { class: _vm.$.input_actions }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("icon-button", {
                                attrs: {
                                  id: "confirm_edit",
                                  type: "colored",
                                  icon: { name: "check" },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("icon-button", {
                                attrs: { icon: { name: "close-1" } },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  !_vm.isEditing
                    ? _c("icon-button", {
                        class: _vm.$.edit,
                        attrs: { icon: { name: "more-2" }, size: "small" },
                        on: { onClick: _vm.toggleContextMenu },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.metadata },
                _vm._l(_vm.metadata, function (data) {
                  return _c("div", { key: data }, [
                    _vm._v("\n          " + _vm._s(data) + "\n        "),
                  ])
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "button"
        ? [
            _c("svg-sprite", {
              attrs: {
                type: "regular",
                name: "plus-1",
                color: "#1565C0",
                size: 20,
              },
            }),
            _vm._v(" "),
            _c("div", { class: _vm.$.button_label }, [
              _vm._v(_vm._s(_vm.item.name)),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showContextMenu
        ? _c("context-menu", {
            attrs: {
              items: _vm.contextMenuItems,
              position: _vm.contextMenuPosition,
            },
            on: {
              selectItemMenu: function ($event) {
                return _vm.onSelectContextItem($event)
              },
              onClickOutside: _vm.onClickOutside,
              closePopup: _vm.onClickOutside,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }