<template>
  <section v-if="customFieldsColumns.length">
    <div :class="$style['section-name']">
      {{ locales('settings_global_columns_title') }}
    </div>
    <div
      v-if="customFieldsValuesLoaded"
      :class="$style['custom-field-list']"
    >
      <custom-field-row
        v-for="column in customFieldsColumns"
        :key="column.id"
        :field-column="column"
        :field-value="customFieldsValues[column.id]"
        :disabled="isCustomFieldsDisabled"
        :task-data="taskData"
        @change="$emit('change', $event)"
      />
    </div>
    <div
      v-else
      :class="$style['custom-field-list']"
    >
      <div v-for="column in customFieldsColumns">
        <custom-field-skeleton-row
          :key="column.id"
          :column-name="column.name"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CustomFieldRow from './includes/CustomFieldRow.vue';
import pricingHelper from '../../../../helpers/pricingHelper';
import customFieldSkeletonRow from './includes/customFieldSkeletonRow.vue';

export default {
  name: 'TaskSettingsCustomFields',
  components: { CustomFieldRow, customFieldSkeletonRow },
  inject: ['isFieldDisabled'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
    };
  },
  computed: {
    isCustomFieldsDisabled() {
      return this.isFieldDisabled('custom_fields');
    },
    customFieldsValuesLoaded() {
      let loaded = false;
      const customColumnsValuesLoaded = this.$store.getters['columns/valuesLoaded'];
      const customFieldsValuesOfProject = this.$store.getters['columns/valuesLoadedByProjectId'](this.taskData.gantt_id);

      loaded = customColumnsValuesLoaded || customFieldsValuesOfProject;

      return loaded;
    },
    customFieldsColumns() {
      const columnIds = this.$store.getters['columns/getColumnIdsByProjectId'](this.taskData.gantt_id);
      const isFeatureAvailable = pricingHelper.checkPricingAccess('custom_fields');
      let columns = [];

      if (isFeatureAvailable) {
        columns = columnIds.reduce((result, id) => {
          const column = this.$store.getters['columns/getCustomColumnById'](id);

          if (column) result.push(column);

          return result;
        }, []);
      }

      return columns;
    },
    customFieldsValues() {
      const values = this.$store.getters['columns/getValuesMapForTask'](this.taskData.gantt_id, this.taskData.id);

      return values;
    },
  },
  created() {
    if (!this.customFieldsValuesLoaded) {
      this.$store.dispatch('columns/dynamicLoadValues', {ganttIds: [this.taskData.gantt_id]});
    }
  },
};
</script>

<style module lang="less">

.section-name {
  font-family: Lato-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #191919;
  margin-bottom: 16px;
}

.custom-field-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

</style>
