<template>
  <div
    v-click-outside="close"
    :class="$style.role_popup"
  >
    <div
      v-if="currentRoleTitle"
      ref="button"
      :class="$style.button"
      @click="open"
      @mouseenter="open"
      @mouseleave="close"
    >
      {{ currentRoleTitle }}
    </div>

    <context-menu
      v-if="showPopup"
      :position="positionContext"
      :class="$style.context_popup"
    >
      <template #body>
        <div :class="$style.popup">
          <div :class="$style.popup_head">
            <div :class="$style.popup_title">
              {{ currentRoleTitle }}
            </div>
          </div>
          <div :class="$style.popup_body">
            <template v-if="isMultiview">
              <p :class="$style.popup_text">
                {{ locales('role_popup_text_multiview') }}:
              </p>
              <ul :class="$style.projects">
                <li
                  v-for="item in listProjects"
                  :key="item.id"
                >
                  <div :class="$style.project_name">
                    {{ item.name }}
                  </div>
                  <div :class="$style.project_role">
                    {{ item.role }}
                  </div>
                </li>
              </ul>
            </template>
            <template v-else>
              <p :class="$style.popup_text">
                {{ locales('role_popup_text_project') }}:
              </p>
              <ul>
                <li
                  v-for="item in listRights"
                  :key="item.id"
                >
                  <div>{{ item.title }}</div>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </template>
    </context-menu>
  </div>
</template>

<script>
import app from '../../../app';
import rights from '../../../components/rights';
import routerHelper from '../../../helpers/router';
import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import projectsModel from '../../../models/projects';

import ganttViewModel from '../../../models/ganttViewModel';

const RIGHT_ALL_TASKS_ID = 1;

export default {
  data() {
    return {
      locales: __,
      showPopup: false,
      positionContext: null,
      isMultiview: false,
      currentRole: null,
      projectsIds: null,
      listeners: [],
    };
  },
  computed: {
    projectRoles() {
      return this.$store.getters['roles/getProjectRoles'];
    },
    projectRights() {
      return this.$store.getters['roles/getProjectRights'];
    },
    adminRole() {
      return this.projectRoles.find(role => role.defaultType === 'ADMIN');
    },
    isOwner() {
      return rights.account.isOwner() || rights.account.isKingMode();
    },
    currentRoleTitle() {
      if (this.isMultiview) {
        return __('role_popup_multiview_title');
      }

      if (!this.currentRole) return '';

      return this.currentRole.defaultType ? __(`project_${this.currentRole.title.toLowerCase()}`) : this.currentRole.title;
    },
    currentRoleRightsIds() {
      if (!this.currentRole) return [];

      return this.currentRole.rights.reduce((accumulator, value) => {
        if (value.status && !accumulator.includes(value.rightId)) {
          accumulator.push(value.rightId);
        }

        return accumulator;
      }, []);
    },
    listRights() {
      let arr = this.currentRoleRightsIds.map(id => {
        const right = this.projectRights.find(item => item.id === id);

        return {
          id,
          title: __(`proj_rs_${right.title}_title`),
        };
      });

      if (!this.currentRoleRightsIds.includes(RIGHT_ALL_TASKS_ID)) {
        const rightAllTasks = this.projectRights.find(item => item.id === RIGHT_ALL_TASKS_ID);
        const disabledRight = {
          id: rightAllTasks.id,
          title: __(`proj_rs_${rightAllTasks.title}_off_title`),
        };

        arr = [disabledRight, ...arr];
      }

      return arr;
    },
    listProjects() {
      if (!this.projectsIds) return [];

      return this.projectsIds.map(gantt_id => {
        const name = projectsModel.getProjectDataById(gantt_id)?.name;
        const currentRole = rights.project.getCurrentUserRole(gantt_id);
        const resources = this.$store.getters['resourcesModel/getResourcesByProjectId'](gantt_id);
        const isUserAddedOnProject = resources.find(resource => resource.userId === user.id);
        let role;

        if (this.isOwner && !isUserAddedOnProject) {
          role = this.adminRole;
        } else if (this.isOwner && currentRole.defaultType !== 'OWNER') {
          role = this.adminRole;
        } else {
          role = currentRole;
        }

        return {
          id: gantt_id,
          name: name || '',
          role: role.defaultType ? __(`project_${role.title.toLowerCase()}`) : role.title,
        };
      });
    },
  },
  created() {
    this.listeners.push(app.on('gantt:init:after', () => {
      this.getCurrentRole();
    }));

    this.listeners.push(app.on('activeProject:afterSet', () => {
      this.getCurrentRole();
    }));

    this.listeners.push(app.on('activeMultiProjects:afterSet', () => {
      this.getCurrentRole();
    }));

    this.listeners.push(app.on('app:route:after:changed', to => {
      if (
        to.name === 'project'
        || to.name === 'taskRoute'
        || to.name === 'projectsMultiView'
        || to.name === 'multiviewTaskRoute'
      ) {
        this.getCurrentRole();
      }
    }));

    this.listeners.push(app.on('onAfterCollaboration', data => {
      if (data.event === 'RoleProjectUpdated') {
        this.getCurrentRole();
      }
    }));

    this.listeners.push(app.on('resources:model:afterMutation', () => {
      this.getCurrentRole();
    }));

    multiViewsProjectsModel.data.attachEvent('onDataUpdate', () => {
      this.getCurrentRole();
    });
  },
  beforeDestroy() {
    this.listeners.map(listener => app.off(listener));
  },
  mounted() {
    this.getCurrentRole();
  },
  methods: {
    open() {
      this.showPopup = true;
      this.positionContext = this.$refs.button.getBoundingClientRect();
    },
    close() {
      this.showPopup = false;
    },
    getCurrentRole() {
      this.currentRole = null;
      this.projectsIds = null;
      this.isMultiview = false;

      const currentRoute = routerHelper.getCurrentRoute();
      const routeProjectId = +currentRoute.params.projectId || +currentRoute.params.multiviewID;
      const projectData = ganttViewModel.getActiveProjectData();
      const activeProjectId = projectData?.id;

      if (routeProjectId !== activeProjectId) {
        return;
      }

      this.isMultiview = !!projectData.ganttIDs;

      if (this.isMultiview) {
        this.projectsIds = projectData.ganttIDs;
      } else {
        const currentRole = rights.project.getCurrentUserRole(activeProjectId);
        const resources = this.$store.getters['resourcesModel/getResourcesByProjectId'](activeProjectId);

        if (resources.length) {
          const isUserAddedOnProject = resources.find(resource => resource.userId === user.id);

          if (this.isOwner && !isUserAddedOnProject) {
            this.currentRole = this.adminRole;
          } else if (this.isOwner && currentRole.defaultType !== 'OWNER') {
            this.currentRole = this.adminRole;
          } else {
            this.currentRole = currentRole;
          }
        }
      }
    },
  },
};
</script>

<style module src="../less/rolePopup.less"></style>
