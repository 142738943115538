var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.componentKey,
      ref: "statusSelect",
      class: ["status-select", _vm.archived ? "archived" : ""],
    },
    [
      _c("tooltip", {
        class: "tooltip-wrapper",
        attrs: {
          content: _vm.locale("project_status_select_tooltip"),
          hide: _vm.isOpen || !_vm.hasRightToChangeStatus || _vm.archived,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    class: [
                      "select__view",
                      _vm.isOpen &&
                      _vm.hasRightToChangeStatus &&
                      (_vm.statusData.options.length > 1 ||
                        _vm.hasRightToStatusSettings)
                        ? "open"
                        : "",
                      _vm.hasRightToChangeStatus && !_vm.archived
                        ? "cursor_pointer"
                        : "",
                    ],
                    style: {
                      backgroundColor: _vm.statusData.selectOption.background,
                    },
                    attrs: {
                      "data-key":
                        !_vm.hasRightToStatusSettings &&
                        _vm.statusData.options.length === 1
                          ? "project_status_select_no_access_to_create_tooltip"
                          : "project_status_select_tooltip",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleSelect($event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class: ["selected-value"],
                        style: {
                          color: _vm.statusData.selectOption.labelColor,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.localeArray.includes(
                              _vm.statusData.selectOption.value
                            )
                              ? _vm.locale(_vm.statusData.selectOption.value)
                              : _vm.statusData.selectOption.value
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    ((_vm.hasRightToChangeStatus &&
                      _vm.statusData.options.length > 1) ||
                      (_vm.hasRightToStatusSettings &&
                        _vm.hasRightToChangeStatus &&
                        _vm.statusData.options.length <= 1)) &&
                    !_vm.archived
                      ? _c("svg-sprite", {
                          class: ["select-arrow", "select-icon"],
                          attrs: {
                            name: "arrow-down",
                            type: "regular",
                            size: 20,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }