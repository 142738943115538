<template>
  <div class="twoFa-popup">
    <div
      class="head"
    >
      <div class="wrapper">
        <div class="popup-title main-title">
          {{ locales('twofa_active_header_title') }}
        </div>
        <div class="popup-text main-title">
          {{ locales('twofa_active_view_title') }}
        </div>
      </div>
    </div>
    <div class="body">
      <div class="section">
        <div class="pic">
          <webix-ui :config="icons.icSafe" />
        </div>
        <div class="info-wrapper">
          <div class="main-title">
            {{ locales('twofa_active_view_section_title_01') }}
          </div>
          <div
            class="popup-text"
            v-html="locales('twofa_active_view_section_text_01')"
          />
        </div>
      </div>
      <div class="section">
        <div class="pic">
          <img
            :src="qrPic"
            alt=""
          >
        </div>
        <div class="info-wrapper">
          <div class="main-title">
            {{ locales('twofa_active_view_section_title_02') }}
          </div>
          <div
            class="popup-text"
            v-html="locales('twofa_active_view_section_text_02')"
          />
        </div>
      </div>
      <div class="section">
        <div class="pic">
          <webix-ui :config="icons.icAuth" />
        </div>
        <div class="info-wrapper">
          <div class="main-title">
            {{ locales('twofa_active_view_section_title_03') }}
          </div>
          <div
            class="popup-text"
            :style="{marginBottom: 12 + 'px'}"
            v-html="locales('twofa_active_view_section_text_03')"
          />
          <v-input
            :placeholder="locales('twofa_activate_section_3_placeholder')"
            :handle-input="onInput"
            :is-error="isError"
            :value="value"
            :error-text="locales('twofa_active_view_section_input_error')"
          />
          <div
            class="popup-btn blue-btn"
            @click="activateTwoFa"
          >
            {{ locales('twofa_activate_section_3_button') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import twoFaModel from '../../../models/twoFa';

import vInput from '../../common/VueComponents/vInput/vInput.vue';

import icSafe from '../../../svg/profile/security/ic_authenticator_app.svg';
import icAuth from '../../../svg/profile/security/ic_authentication.svg';
import svgIconForVue from '../../common/svgIconForVue';

export default {
  components: {
    vInput,
  },
  data() {
    return {
      locales: __,
      icons: {
        icSafe: svgIconForVue(icSafe, 140, 140),
        icAuth: svgIconForVue(icAuth, 140, 140),
      },
      value: '',
      isError: false,
    };
  },
  computed: {
    qrPic() {
      return this.$store.getters['profileSettings/twoFaPopup/qrPic'];
    },
  },
  methods: {
    activateTwoFa() {
      const otp = this.value;

      twoFaModel.verify({
        otp,
      }).then(data => {
        if (data && data.verified) {
          this.$store.dispatch('globalPopup/close');
          this.isError = false;
          user.twoFa = {
            active: 1,
          };

          webix.message({
            type: 'success',
            text: __('settings_twofa_message_enabled'),
            expire: 10000,
          });
          userExtAnalytics.log('twofa_enabled');
          this.$store.commit('profileSettings/twoFaPopup/enableTwoFa', true);
        } else {
          this.value = '';
          this.isError = true;
        }
      });
    },
    // onChangeInput(val) {
    //   this.value = val;
    // },
    onInput(val) {
      this.isError = false;
      this.value = val;
    },
  },
};
</script>

<style scoped src="../less/twoFaPopup.less"></style>
<style lang="less">
  .twoFa-popup {
    a {
      color: #257ECC;
      border-bottom: 1px solid transparent;
      cursor: pointer;

      &:hover {
        // border-bottom: 1px solid #257ECC;
        text-decoration: underline !important;
      }
    }
  }

  .pic {
    .webix_view {
      .webix_template {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .accent_text {
	  font-weight: bold;
	}
</style>
