import app from '../app';
import { internalApi } from '$$store/api';

const innerObject = {
  settings: {
    numberFormat: GT.user.userSettings?.numberFormat || 'comma-period'
  },
  numberFormats: [
    { id: 'period-comma', value: '1.234,56' },
    { id: 'comma-period', value: '1,234.56'},
    { id: 'space-comma', value: '1 234,56' },
    { id: 'space-period', value: '1 234.56' },
    { id: 'plain-comma', value: '1234,56' },
    { id: 'plain-period', value: '1234.56' },
  ],
  getFormatted: (
    number,
    formatType = innerObject.getUserNumberFormat()?.id,
    options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ) => {
    let result;
  
    switch (formatType) {
      case 'period-comma':
        result = number.toLocaleString('de-DE', options);
        break;
      case 'comma-period':
        result = number.toLocaleString('en-US', options);
        break;
      case 'space-comma':
        result = number.toLocaleString('fr-FR', options);
        break;
      case 'space-period':
        result = number.toLocaleString('fr-FR', options).replace(',', '.');
        break;
      case 'plain-comma':
        result = number.toLocaleString('de-DE', options).replace(/\./g, '');
        break;
      case 'plain-period':
        result = number.toLocaleString('en-US', options).replace(/,/g, '');
        break;
      default:
        result = number.toLocaleString(undefined, options);
    }
  
    return result;
  },
  setUserNumberFormat: async (format) => {
    innerObject.settings.numberFormat = format;
    await internalApi.put(`/users/${GT.user.id}`, {
      settings: {
        numberFormat: format
      }
    });
  },
  getUserNumberFormat: () => {
    return innerObject.numberFormats.find((item) => item.id === innerObject.settings.numberFormat);
  }
};

app.on('app:start', function () {
  if (!user.numberFormat) return;

  innerObject.setUserNumberFormat(user.numberFormat);
});

export default innerObject;
