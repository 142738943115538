var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-multiple-input", {
    staticClass: "gp_autotest_multiple_input",
    class: ["multiple_input", _vm.isError ? "is-error" : "", _vm.classes],
    attrs: {
      tags: _vm.inputs,
      placeholder: _vm.placeholder,
      validation: _vm.validation,
      "avoid-adding-duplicates": false,
      "allow-edit-tags": true,
      separators: [",", ";", " "],
    },
    on: {
      "before-adding-tag": _vm.onBeforeAddingTag,
      "before-deleting-tag": _vm.onBeforeDeletingTag,
      "before-editing-tag": _vm.onBeforeEditingTag,
      "tags-changed": _vm.tagsChanged,
    },
    scopedSlots: _vm._u([
      {
        key: "tag-actions",
        fn: function (props) {
          return [
            _c(
              "div",
              {
                staticClass: "closer_wrapper gp_autotest_multiple_input_tags",
                on: {
                  click: function ($event) {
                    return props.performDelete(props.index)
                  },
                },
              },
              [
                _c("svg-sprite", {
                  staticClass:
                    "chip_closer gp_autotest_multiple_input_tags_close_icon",
                  attrs: { name: "close-2", size: 16, color: "#191919" },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }