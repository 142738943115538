import Resizer from './Resizer';
import Columns from './Columns';

class TableResize {
  constructor(table) {
    this.table = table;
    this.resizers = [];
    this.columns = new Columns(table);
  }

  get isActiveResizer() {
    return this.resizers.find(i => i.active);
  }

  init() {
    const headerCells = this.table.querySelectorAll('.table-header-cell');

    if (!headerCells) return;

    const tableHeight = this.table.getBoundingClientRect().height - 17;

    for (let i = 0; i < headerCells.length; i++) {
      if (i === headerCells.length - 1) return;

      const cell = headerCells[i];

      this.resizers.push(new Resizer(i, cell, this.columns, tableHeight));
    }
  }

  update() {
    this.destroy();
    this.init();
    this.columns.updateColumns();
  }

  destroy() {
    this.resizers.forEach(resizer => resizer.destroy());
    this.resizers = [];
  }
}

export default TableResize;
