
import globalStore from "$$store/main";
import app from '../../../app';

function onResourceCreated(event) {
  // event.innerSettings.resourcesForCollaboration.forEach(el => {
  //   if (el.user_id && resourcesModel.getRealResourceByUserIdFast(el.user_id)) return false;
  // });

  // resourcesModel.addResourceByCollaboration({ resourcesData: event.innerSettings.resourcesForCollaboration });
  globalStore.commit('resourcesModel/addResources', event.data.resources);
  event.data.resources.forEach(res => {
    app.trigger('resources:model:update', res.id);
  });
}

export default onResourceCreated;
