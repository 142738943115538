<template>
  <core-popup
    id="gp_autotest_invite_people_popup"
    :show="isShow"
  >
    <template #body>
      <div :class="popup.invite_popup">
        <div :class="popup.title">
          {{ locales('invite_popup_title') }}
        </div>
        <div :class="popup.invite_popup_input_area">
          <vgp-label-slot
            :label="locales('common_emails')"
            :action="invitationsCount >= 0 
              ? locales('invite_popup_count_of_invitations', { count: invitationsCount > 0 ? invitationsCount : 0 }) 
              : locales('invite_popup_count_of_invitations_overage', { count: invitationsCount * -1 })"
            :actionStyle="invitationsCount < 0 ? { color: '#DD3636' } : {}"
          >
            <template #body>
              <multiple-input
                :placeholder="locales('common_enter_emails')"
                :validation="validationRules"
                :items="emails"
                :classes="[invitationsCount < 0 ? 'is-error' : '', 'invite-input']"
                :is-error="inputError"
                @setItems="setEmails"
                @checkTag="onCheckValue"
                @isInput="flag => isInput = flag"
              />
            </template>
          </vgp-label-slot>
          <div
            v-if="inputError && isEmailError && invitationsCount > 0"
            id="gp_autotest_invite_people_popupe_message_error_email"
            :class="popup.alert_message"
          >
            {{ locales('invite_popup_alert_message_email') }}
          </div>
          <div
            v-if="inputError && isAlreadyInTeam && invitationsCount > 0"
            id="gp_autotest_invite_people_popupe_message_error_alreadyInTeam"
            :class="popup.alert_message"
          >
            {{ locales('permission_error_alreadyIsTeam') }}
          </div>
          <div
            v-if="inputError && isAlreadyInEmails && invitationsCount > 0"
            id="gp_autotest_invite_people_popupe_message_error_alreadyInvited"
            :class="popup.alert_message"
          >
            {{ locales('permission_error_alreadyIsInvites') }}
          </div>
          <div
            v-if="invitationsCount < 0"
            id="gp_autotest_invite_people_popupe_message_error_outOfInvitations"
            :class="popup.alert_message"
          >
            {{ locales('invite_popup_alert_message') }}
          </div>
        </div>
        <div :class="popup.invite_popup_settings">
          <div :class="popup.settings__role_dropdown">
            <vgp-label-slot :label="locales('common_account_role')">
              <template #body>
                <dropdown-select
                  :disabled="!accessToChangeAccountRole"
                  gp-autotest-name="gp_autotest_invite_people_popup_dropdown_select_account_role"
                  :drop-list="dropdownRoles"
                  :multiselect="false"
                  :show-selected-first="false"
                  :show-reset-btn="false"
                  :highlight-active-state="false"
                  item-template="text"
                  track-by-text="title"
                  track-by-item-tooltip="tooltip"
                  outline="always"
                  :fixed-width="320"
                  :size="'autoWidth'"
                  :need-description="true"
                  :selected="inviteRole"
                  @selected="selectRole"
                />
              </template>
            </vgp-label-slot>
          </div>
          <div :class="popup.settings__project_dropdown">
            <vgp-label-slot :label="locales('common_invite_projects_dropdown')">
              <template #body>
                <dropdown-select
                  :drop-list="projects"
                  gp-autotest-name="gp_autotest_invite_people_popup_dropdown_select_projects"
                  :multiselect="true"
                  :use-select-all="true"
                  :use-search="true"
                  :show-selected-first="false"
                  :show-reset-btn="false"
                  :highlight-active-state="false"
                  item-template="text"
                  outline="always"
                  :popup-max-height="180"
                  :placeholder="locales('create_resource_projects_drop_placeholder')"
                  @selected="selectProjects"
                  @change="onChangeSelected"
                />
              </template>
            </vgp-label-slot>
          </div>
        </div>
        <div :class="popup.invite_popup_footer">
          <div
            v-if="isOwner"
            id="gp_autotest_invite_people_popupe_message_warning_upgrade_invite"
            :class="[popup.invite_upgrade_message, (invitationsCount < 0 && !isSuspended) ? popup.invite_upgrade_message__show : '']"
          >
            <div
              :class="popup.invite_upgrade_message__wrapper"
            >
              <div :class="popup.description">
                <div>
                  <svg-sprite
                    :name="'warning-1'"
                    color="#FF9A00"
                    type="bold"
                  />
                </div>
                <div :class="popup.text">
                  {{ locales('invite_popup_out_licences') }}
                </div>
              </div>
              <vgp-button
                id="gp_autotest_invite_people_popup_btn_upgrade_invites"
                :class="popup.btn"
                type="text"
                :small="true"
                :label="locales('invites_add_more')"
                @onClick="$emit('upgradePlanShowFromResourcesForce')"
              />
            </div>
          </div>
          <div :class="popup.invite_buttons">
            <vgp-button
              id="gp_autotest_invite_people_popup_btn_cancel"
              :class="popup.invite_buttons__cancel"
              :label="locales('common_cancel')"
              type="secondary"
              :small="true"
              @onClick="onClickCancel"
            />
            <vgp-button
              id="gp_autotest_invite_people_popup_btn_invite"
              class="apply"
              :label="locales('send_invite_btn')"
              type="primary"
              :small="true"
              :disabled="!emails.length || inputError || (invitationsCount < 0) || isInput"
              @onClick="onSendInvites"
            />
          </div>
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import validationRules from './inviteValidations';
import plansModel from '../../../../models/plans';

import corePopup from '../../../common/VueComponents/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import multipleInput from '$$vueCmp/vInput/multipleInput.vue';
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import VgpButton from '../../../common/VueComponents/globalButton/vgpButton.vue';
import ssoToastCmp from '../toastComponents/ssoToast.vue';

export default {
  name: 'InvitePopup',
  components: {
    corePopup, VgpLabelSlot, DropdownSelect, multipleInput, VgpButton,
  },
  props: {
    isShow: { type: Boolean, required: true, default: false },
    accountRoles: { type: Array, required: true, default: () => [] },
    projects: { type: Array, required: true, default: () => [] },
    accessToChangeAccountRole: { type: Boolean, required: false, default: false },
    emits: {
      onClose: Boolean,
      onSendInvites: Object,
      upgradePlanShowFromResourcesForce: Object,
    },
  },
  data() {
    return {
      locales: __,
      emails: [],
      roleId: null,
      defaultRoleId: 0,
      projectsToInvite: [],
      inviteConfig: {},
      inputError: false,
      isEmailError: false,
      isAlreadyInTeam: false,
      isAlreadyInEmails: false,
      isInput: false,
      validationRules: [],
    };
  },
  computed: {
    invitationsCount() {
      const realResourcesLength = this.$store.getters['resourcesModel/getAllRealResources'].length;

      return user.team.invitations - realResourcesLength - this.emails.length;
    },
    isOwner() {
      return +user.team.owner_id === +user.id;
    },
    isTrial() {
      return plansModel.isTrial();
    },
    isSuspended() {
      return user.subscription.status_code === 8 && !this.isTrial;
    },
    inviteRole() {
      const memberRole = this.dropdownRoles.find(role => role.defaultType === 'MEMBER');
      const defaultRole = this.dropdownRoles.find(role => role.isDefault);

      const defaultRoleId = defaultRole?.id || memberRole.id;

      // if collab came we should change role
      if (this.defaultRoleId !== defaultRoleId) {
        this.changeDefaultRoleWhenCollabCame(defaultRoleId);
      }

      return defaultRole || memberRole;
    },
    dropdownRoles() {
      const roles = this.accountRoles.map(item => {
        const role = { ...item };

        role.tooltip = { content: item?.title };

        return role;
      });

      return roles;
    },
  },
  watch: {
    isShow(flag) {
      if (flag) {
        this.getEmailsFromStorage();
      }
    },
  },
  created() {
    this.roleId = this.inviteRole.id;
    this.defaultRoleId = this.inviteRole.id;
    this.getEmailsFromStorage();
    this.validationRules = validationRules;
  },
  methods: {
    async getEmailsFromStorage() {
      const emailsFromStorage = webix.storage.local.get('inviteEmails');

      if (emailsFromStorage && emailsFromStorage?.emails && emailsFromStorage.emails.length && emailsFromStorage.userId === user.id) {
        this.emails = emailsFromStorage.emails;
      } else {
        this.emails = [];
      }
    },

    changeDefaultRoleWhenCollabCame(defaultRoleId) {
      this.roleId = defaultRoleId;
      this.defaultRoleId = defaultRoleId;
    },

    setEmails(emails) {
      webix.storage.local.remove('inviteEmails');

      if (emails.length) {
        webix.storage.local.put('inviteEmails', { emails, userId: user.id });
        this.emails = emails;
      } else {
        this.emails = [];
      }

      setTimeout(() => {
        const invalidEmails = document.querySelectorAll('.ti-tag.ti-invalid');

        if (!invalidEmails.length) {
          this.inputError = false;
          this.isEmailError = false;
          this.isAlreadyInEmails = false;
          this.isAlreadyInTeam = false;

          return;
        }

        if (invalidEmails.length) {
          const classList = invalidEmails[invalidEmails.length - 1].classList.value;

          this.inputError = true;
          if (classList.includes('invalid-email')) { this.isEmailError = true; } else { this.isEmailError = false; }
          if (classList.includes('ti-duplicate')) { this.isAlreadyInEmails = true; } else { this.isAlreadyInEmails = false; }
          if (classList.includes('has-in-team')) { this.isAlreadyInTeam = true; } else { this.isAlreadyInTeam = false; }
        }
      }, 0);
    },
    selectRole(role) {
      this.roleId = role[0].id;
    },
    selectProjects(projects) {
      this.projectsToInvite = projects;
    },
    onChangeSelected(projects) {
      this.projectsToInvite = projects;
    },
    onSendInvites() {
      if (this.inputError || !this.emails.length) {
        return;
      }

      const inviteData = {
        emails: this.emails.map(email => email.text),
        projectIds: this.projectsToInvite.map(project => project.gantt_id),
        accountRoleId: this.roleId,
      };

      this.$emit('onSendInvites', inviteData);
      webix.storage.local.remove('inviteEmails');
      this.$emit('onClose');

      this.inviteConfig = {};
      this.emails = [];
    },
    onCheckValue(obj) {
      if (obj.tag.tiClasses.includes('sso-email')) {
        this.$toast.warning(ssoToastCmp);

        return;
      }

      if (obj.tag.tiClasses.includes('invalid-email')) {
        this.$toast.warning(__('permission_error_invalidEmail'));

        return;
      }

      if (obj.tag.tiClasses.includes('ti-duplicate')) {
        this.$toast.warning(__('permission_error_alreadyIsInvites'));

        return;
      }

      if (obj.tag.tiClasses.includes('has-in-team')) {
        this.$toast.warning(__('permission_error_alreadyIsTeam'));
      }
    },
    onClickCancel() {
      webix.storage.local.remove('inviteEmails');
      this.$emit('onClose');
    },
  },
};
</script>

<style module='popup' src='./popups.less'></style>
