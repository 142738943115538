import globalStore from '$$store/main';
import projectsModel from '../../../models/projects';

export function createTooltipInfoForCustomColumn(columnId, activeGanttIds) {
  const projectIds = globalStore.getters['columns/getProjectsByColumnId'](columnId);

  const projectsNameHtml = activeGanttIds.map(ganttId => {
    if (projectIds.includes(+ganttId)) {
      const project = projectsModel.getProjectDataById(+ganttId);

      if (project) {
        return `<li class='tooltip-list-item'>${project.name}</li>`;
      }
    }

    return '';
  });

  return {
    header: __('guntt_custom_columns_multiview_tooltip_project'),
    descriptionHtml: projectsNameHtml.join(''),
  };
}
