<template>
  <div
    v-if="isShowStub"
    :key="'stub-page-' + stubType"
    :style="styleParams"
    class="stub-page"
  >
    <stub :type="stubType" />
  </div>
</template>

<script>
import stub from './stub.vue';
import projectsModels from '../../models/projects';
import multiViewsModels from '../../models/multiViewsProjects';
import rights from '../../components/rights';
import routerHelper from '../../helpers/router';
import app from '../../app';

const VIEWS = [
  'gantt_chart',
  'board_view',
  'list_view',
  'workload',
  'resources_page',
  'overview',
  'calendar',
];

export default {
  name: 'StubPage',
  components: { stub },
  props: { },

  data() {
    return {
      isShowStub: false,
      stubType: 'no-views',
      ganttIds: [],
      eventsIds: [],
      styleParams: {},
    };
  },

  computed: {
    headerConfig() {
      return this.$store.getters['headerView/UIConfig'];
    },
    leftSidebarWidth() {
      return this.$store.getters.leftSidebarWidth;
    },
  },

  watch: {
    headerConfig() {
      this.update();
    },
    leftSidebarWidth() {
      this.update();
    },
  },

  created() {
    const e_id_1 = app.on('onAfterCollaboration', ({ event, projects }) => {
      const events = [
        'TaskCreated',
        'TaskUpdated',
        'TaskDeleted',
      ];

      if (events.includes(event) && projects.find(ganttId => this.ganttIds.includes(ganttId))) {
        this.update();
      }
    });

    const e_id_2 = app.on('project:archive', projectData => {
      this.update();
    });

    this.eventsIds.push(
      e_id_1,
      e_id_2,
    );
  },

  mounted() {
    this.update();
  },

  beforeDestroy() {
    this.eventsIds.forEach(id => app.off(id));
  },

  methods: {
    setStyleParams() {
      if (routerHelper.isExportRoute()) {
        this.styleParams = {
          top: '60px',
          left: 0,
          bottom: 0,
          right: 0,
        };
      } else {
        this.styleParams = {
          top: `${this.$store.getters['headerView/height']}px`,
          left: `${this.$store.getters.leftSidebarWidth}px`,
          bottom: '20px',
          right: 0,
        };
      }
    },
    update() {
      this.$store.commit('filter/setIsDisable', false);
      this.stubType = 'no-tasks';
      this.isShowStub = false;

      if (!this.$store.state.appMode.isBase) {
        return;
      }

      this._checkStubForSingleProject();
      this._checkStubForMultiProject();

      if (this.isShowStub) {
        this.$store.commit('headerView/removeToolbar');
        this.$store.commit('filter/setIsDisable', true);
      }

      this.setStyleParams();
    },

    _checkStubForSingleProject() {
      if (!routerHelper.isSingleProjectRoute()) { return; }

      const gantt_id = projectsModels.getActiveGanttId();
      const mode = routerHelper.getCurrentRoute().params.mode;
      const canSeeAllTasks = rights.project.hasRight(gantt_id, 'all_tasks');

      this.ganttIds = [gantt_id];

      const seeOnlyBoardView = this.seeOnlyBoardView(gantt_id);
      const isArchivedProject = projectsModels.getArchivedProject(gantt_id);
      const isAchivedWithBoardOnly = seeOnlyBoardView && isArchivedProject?.is_archived;

      if (this.forbiddenToSeeAllViews(gantt_id) || isAchivedWithBoardOnly) {
        this.isShowStub = true;
        this.stubType = 'no-views';

        return;
      }

      if (mode === 'resources' || canSeeAllTasks) {
        this.isShowStub = false;

        return;
      }

      if (mode === 'overview') {
        this.isShowStub = false;

        return;
      }

      const tasks = this.$store.getters['tasksModel/getTasksByGanttIds']([gantt_id]);
      const accessTasks = tasks.filter(task => task.type === gantt.config.types.task || task.type === gantt.config.types.milestone);

      this.isShowStub = !accessTasks.length;
    },

    _checkStubForMultiProject() {
      if (!routerHelper.isMultiProjectsRoute()) return;

      const mode = routerHelper.getCurrentRoute().params.mode;
      const gantt_ids = multiViewsModels.getActiveViewData().ganttIDs;
      const canSeeTasks = gantt_ids.some(gantt_id => rights.project.hasRight(gantt_id, 'all_tasks'));
      const forbiddenToSeeAllViews = gantt_ids.every(gantt_id => this.forbiddenToSeeAllViews(gantt_id));

      this.ganttIds = gantt_ids;

      if (forbiddenToSeeAllViews) {
        this.isShowStub = true;
        this.stubType = 'no-views';

        return;
      }

      if (mode === 'resources' || canSeeTasks) {
        this.isShowStub = false;

        return;
      }

      const tasks = this.$store.getters['tasksModel/getTasksByGanttIds'](gantt_ids);
      const accessTasks = tasks.filter(task => task.type === gantt.config.types.task || task.type === gantt.config.types.milestone);

      this.isShowStub = !accessTasks.length;
    },

    forbiddenToSeeAllViews(gantt_id) {
      return !rights.project.hasRightSomeOne(gantt_id, VIEWS);
    },

    seeOnlyBoardView(gantt_id) {
      const filtered = VIEWS.filter(view => view !== 'board_view');
      const seeSomeView = rights.project.hasRightSomeOne(gantt_id, filtered);
      const seeBoardView = rights.project.hasRight(gantt_id, 'board_view');

      return !seeSomeView && seeBoardView;
    },

  },
};
</script>

 <style lang="less" scoped>
  .stub-page{
    position: fixed;
    background-color: #fff;
    z-index: 11;
  }
 </style>
