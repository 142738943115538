var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      key: _vm.data.key,
      class: ["table-cell", _vm.data.staticClass, _vm.data.class],
      style: [_vm.data.staticStyle, _vm.data.style],
      attrs: { "date-name": _vm.props.column.property },
    },
    [
      _c(
        "div",
        {
          key: _vm.props.rowData.id + "-" + _vm.props.column.property,
          staticClass: "table-cell--inner-wrap",
          class: "gp_autotest_listview_item_cell_" + _vm.props.column.property,
        },
        [
          !_vm.$options.methods.isCellExist(_vm.props.rowData, _vm.props.column)
            ? _c("div", {
                staticClass: "all-cell",
                on: { click: _vm.$options.methods.notExistCellWarning },
              })
            : [
                _vm.props.column.editor === "checkbox-selectmode"
                  ? _c(_vm.injections.components.checkboxCell, {
                      tag: "component",
                      attrs: {
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.isSelectedRow,
                        theme: "blue",
                      },
                      on: { change: _vm.listeners["select:row"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "icon"
                  ? _c(
                      "div",
                      { class: ["all-cell", "flex-center"] },
                      [
                        _c(_vm.injections.components.vueIcon, {
                          tag: "component",
                          class: {
                            "show-only-row-hover":
                              !_vm.props.rowData[_vm.props.column.property] &&
                              !_vm.props.selectMode,
                            "blue-icon": _vm.props.rowData[
                              _vm.props.column.property
                            ]
                              ? !!_vm.props.rowData[_vm.props.column.property]
                                  .unread
                              : false,
                          },
                          style: {
                            opacity: _vm.props.discolor,
                            display: _vm.props.rowData[
                              _vm.props.column.property
                            ]
                              ? "flex"
                              : "none",
                          },
                          attrs: { name: _vm.props.column.iconName },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return (function () {
                                return _vm.listeners["show:itemSettings"](
                                  _vm.props.column.property
                                )
                              })($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "task"
                  ? _c(_vm.injections.components.taskCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                        overdue: _vm.props.overdue,
                        "can-show-settings":
                          _vm.props.canShowSettings && !_vm.props.selectMode,
                        discolor: _vm.props.discolor,
                      },
                      on: {
                        "show:settings": _vm.listeners["show:itemSettings"],
                        "show:task-name-editor":
                          _vm.listeners["show:task-name-editor"],
                        change: _vm.listeners["change"],
                        delete: _vm.listeners["delete"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "project"
                  ? _c(_vm.injections.components.projectCell, {
                      tag: "component",
                      style: { opacity: _vm.props.discolor },
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        "property-extra": _vm.props.column.propertyExtra,
                        extra: _vm.$options.methods.isShowExtra(
                          _vm.props.extra,
                          _vm.props.column.extra.name
                        ),
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "selectList"
                  ? _c(_vm.injections.components.defaultSelectListCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        column: _vm.props.column,
                        property: _vm.props.column.property,
                        "list-type": _vm.props.column.listType,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "datepicker"
                  ? _c(_vm.injections.components.calendarCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        extra: _vm.$options.methods.isShowExtra(
                          _vm.props.extra,
                          _vm.props.column.extra && _vm.props.column.extra.name
                        ),
                        disabled:
                          _vm.props.column.disabled ||
                          (_vm.props.column.property === "end_date" &&
                            _vm.props.rowData.type === "milestone"),
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                        overdue: _vm.props.overdue,
                        discolor: _vm.props.discolor,
                      },
                      on: { change: _vm.listeners["change"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "timelog"
                  ? _c(_vm.injections.components.timeLogCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        property: _vm.props.column.property,
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "resources"
                  ? _c(_vm.injections.components.resourceCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.props.column.editor
                  ? _c(_vm.injections.components.simpleCell, {
                      tag: "component",
                      style: { opacity: _vm.props.discolor },
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        align: _vm.props.column.align,
                        crossout: _vm.props.crossout,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "text"
                  ? _c(_vm.injections.components.textCell, {
                      tag: "component",
                      style: { opacity: _vm.props.discolor },
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        type: _vm.props.column.type,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "number"
                  ? _c(_vm.injections.components.numberCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        type: _vm.props.column.type,
                        disabled:
                          _vm.props.column.property !== "progress" &&
                          _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "datepicker:custom"
                  ? _c(_vm.injections.components.calendarCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        "for-custom": true,
                        property: _vm.props.column.property,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        value: _vm.props.rowData[_vm.props.column.property],
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "text:custom"
                  ? _c(_vm.injections.components.textCell, {
                      tag: "component",
                      style: { opacity: _vm.props.discolor },
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        type: _vm.props.column.type,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "number:custom"
                  ? _c(_vm.injections.components.numberCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        type: _vm.props.column.type,
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        discolor: _vm.props.discolor,
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "checkbox"
                  ? _c(_vm.injections.components.checkboxCell, {
                      tag: "component",
                      style: { opacity: _vm.props.discolor },
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        value: _vm.props.rowData[_vm.props.column.property],
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "select"
                  ? _c(_vm.injections.components.selectCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        options: _vm.props.options,
                        template: _vm.props.column.template,
                        value: _vm.props.rowData[_vm.props.column.property],
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                        discolor: _vm.props.discolor,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.props.column.editor === "multiselect"
                  ? _c(_vm.injections.components.multySelectCell, {
                      tag: "component",
                      attrs: {
                        item: _vm.props.rowData,
                        property: _vm.props.column.property,
                        options: _vm.props.options,
                        template: _vm.props.column.template,
                        value: _vm.props.rowData[_vm.props.column.property],
                        disabled: _vm.props.column.disabled,
                        editable: _vm.$options.methods.isEditableCell(
                          _vm.props.rowData.gantt_id,
                          _vm.props.column
                        ),
                        crossout: _vm.props.crossout,
                        discolor: _vm.props.discolor,
                      },
                      on: { change: _vm.listeners["change:custom"] },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }