var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["table-plan-cell", _vm.plan.name] }, [
    _vm.accessToFeature
      ? _c(
          "div",
          [
            _vm.feature.name !== "virtual_resources"
              ? _c("webix-ui", {
                  class: _vm.plan.name,
                  attrs: { config: _vm.checkIcon },
                })
              : _c("div", { staticClass: "unlimited-item" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.locales("unlimited_locale")) +
                      "\n    "
                  ),
                ]),
          ],
          1
        )
      : _c("div", { staticClass: "disable-item" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }