<template>
  <div
    :class="$style['news-notify-popup-slot-wrap']"
    v-on="$listeners"
  >
    <svg-sprite
      :class="$style['demo_modal__closer']"
      name="close-1"
      :size="20"
      @click="$emit('close')"
    />
    <div
      :class="$style['demo_modal__container']"
    >
      <div :class="$style['container_view']">
        <div
          :class="$style['view_img_container']"
          :style="{ backgroundColor: imgContainerBg }"
        >
          <div :class="$style['view_img']">
            <img
              :class="{ rounded: isRoundedImg }"
              :src="imageSrc"
            >
          </div>
        </div>
        <div
          :class="$style['view_content']"
        >
          <div :class="$style['content_title']">
            {{ title }}
          </div>
          <div :class="$style['content_text']">
            {{ content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotifyPopupSlot',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    content: {
      type: String,
      default: '',
      required: true,
    },
    imageSrc: {
      type: String,
      default: '',
      required: true,
    },
    isRoundedImg: {
      type: Boolean,
      default: false,
    },
    imgContainerBg: {
      type: String,
      default: '#ffffff',
    },
  },
  emits: {
    close: null,
  },
};
</script>

<style lang="less" module src="./less/notifyPopupSlot.less">
</style>
