export default {
  //tooltip-gantt' data-y='-4' data-key='settings_tooltips_autoScheduling'
  getTemplateForLabelWithTooltip: function () {
    return "<div class='" + this.css + "-block'>" +
      "<div class='" + this.css + "-block-text tooltip-gantt' data-position='left' data-y='" + this.toolTip.y + "' data-key= '" + this.toolTip.langKey + "'> " + this.label + "</div>" +
      "<div class='" + this.css + "-block-spacer'></div>" +
      "</div>";
  },

  getTemplateForLabelWithSpacer: function () {
    return "<div class='" + this.css + "-block'>" +
      "<div class='" + this.css + "-block-text'> " + this.label + "</div>" +
      "<div class='" + this.css + "-block-spacer'></div>" +
      "</div>";
  },

  getTemplateForSimpleLabelWithTooltip: function () {
    return "<span class='tooltip-gantt' data-y='" + this.toolTip.y + "' data-key= '" + this.toolTip.langKey + "'>" + this.label + "</span>";
  },

  getTemplateForLabelWithSpacerAndNumber: function () {
    return "<div class='" + this.css + "-block'>" +
      "<div class='" + this.css + "-block-text'> " + this.label + "</div>" +
      "<div class='" + this.css + "-block-spacer'></div>" +
      "<div class='" + this.css + "-block-text'> " + this.number + "</div>" +
      "</div>";
  },

  getTemplateForFileStorageLabel: function () {
    return "<div class='" + this.css + "-block'>" +
      "<div class='" + this.css + "-block-text'> " + this.label + "</div>" +
      "<div class='" + this.css + "-block-right'>" +
      "<div class='" + this.css + "-block-right-spacer'></div>" +
      "<div class='" + this.css + "-right-value'>" + this.usedFileStore + " / " + this.maxFileStore + "</div>" +
      "</div>" +
      "</div>";
  }
};
