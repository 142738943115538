var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requiredDataLoaded
    ? _c(
        "div",
        { staticClass: "height100", attrs: { id: "listview-table" } },
        [
          _vm.config && _vm.preparedTasks && _vm.preparedHeaders
            ? _c("resizable-table", {
                ref: "table",
                attrs: {
                  "headers-show": _vm.config.columns,
                  headers: _vm.preparedHeaders,
                  "static-headers": _vm.staticHeaders,
                  "prepend-headers": _vm.prependHeaders,
                  "data-items": _vm.preparedTasks,
                  "table-height": _vm.height,
                  "active-task-id": _vm.activeTaskId,
                  "closed-task-discolor":
                    _vm.config.viewSettings.completedTask.includes(
                      "discolorationTasks"
                    ),
                  "closed-task-crossout":
                    _vm.config.viewSettings.completedTask.includes(
                      "crossOutTasks"
                    ),
                  "highlight-overdue-task": _vm.config.overdue,
                  skins: _vm.skins,
                  "show-custom-column-info": !_vm.viewData.projectId,
                  "disable-create-items": !!_vm.activeTaskId,
                  "select-mode": _vm.isBulkChange,
                  "type-no-tasks-img": _vm.preparedTasks.length
                    ? "noFiltredTasks"
                    : "noTasks",
                },
                on: {
                  select: _vm.onSelectRow,
                  change: _vm.changeTask,
                  "change:custom": _vm.changeTaskCustom,
                  "change:show:columns": _vm.changeShowColumnsDebounced,
                  "show:itemSettings": _vm.showTaskSettings,
                  "delete:item": _vm.showDeleteConfirm,
                  sorted: _vm.sortedColumn,
                  "create:item": _vm.onCreateItem,
                  "before:create": _vm.onBeforeCreate,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("confirmPopup", {
            attrs: {
              show: _vm.deleteConfirm.show,
              text: _vm.deleteConfirm.body,
              title: _vm.deleteConfirm.title,
              "ok-button": _vm.deleteConfirm.okBtn,
              "cancel-button": _vm.deleteConfirm.cancelBtn,
              "popup-width": 440,
            },
            on: {
              onClickOk: _vm.deleteTask,
              onClickCancel: _vm.closeDeleteConfirm,
            },
          }),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "height100" },
          [
            _c("skeleton", { attrs: { height: _vm.height } }),
            _vm._v(" "),
            _c("skeleton-popup", {
              attrs: {
                show: _vm.needTimeForLoading && !_vm.requiredDataLoaded,
                text: _vm.skeletonText,
              },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }