<template>
  <div class="wrapper">
    <div
      :class="[$style.animated_popup, 'animated_popup_wrap']"
    >
      <div :class="$style.container">
        <div
          :class="[$style.body, $style.wrap]"
          :style="{width: `${width}px`}"
        >
          <slot name="body" />
        </div>
      </div>
    </div>
    <div class="background_container"></div>
  </div>
</template>

<script>

export default {
  name: 'AnimatedPopup',
  props: {
    width: {
      type: Number,
      required: false,
      default: 558,
    },
  },
  computed: {},
  mounted() {
  },
  methods: {
  },
};
</script>

<style module src='./less/animatedPopup.less'></style>

<style scoped lang="less">
.background_container {
  width: 100%;
  height: 100%;

  &.show {
    position: fixed;
    display: flex;
    align-items: start;
    justify-content: start;
    top:0;
    z-index: 30;
    background-color: fade(#000, 10%);
  }
}

.animated_popup {
  @width: 558px;

  &_wrap{
    left: -@width - 1;
    width: @width;
    transition: left 0.38s ease-in-out;

    &.show{
      left: 0;
    }
  }
}

</style>
