// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".outer__wrapper_aeH18d_s {\n  width: 100%;\n}\n.inner_wrapper__ooPbVoo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 45px;\n}\n.inner_wrapper__ooPbVoo span {\n  font-family: Lato-Regular, sans-serif;\n  font-size: 29px;\n  line-height: 35px;\n  color: #000000;\n}\n.inner_wrapper__ooPbVoo div[role='progressbar'] {\n  width: 160px;\n}\n", "",{"version":3,"sources":["webpack://./public/views/onboarding/components/ProjectPreparationLoader.vue","webpack://./ProjectPreparationLoader.vue"],"names":[],"mappings":"AA6BA;EACE,WAAA;AC5BF;AD+BA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AC7BF;ADwBA;EAOI,qCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AC5BJ;ADkBA;EAcI,YAAA;AC7BJ","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.outer__wrapper {\n  width: 100%;\n}\n\n.inner_wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 45px;\n  span {\n    font-family: Lato-Regular, sans-serif;\n    font-size: 29px;\n    line-height: 35px;\n    color: #000000;\n  }\n\n  div[role='progressbar'] {\n    width: 160px;\n  }\n}\n\n",".outer__wrapper {\n  width: 100%;\n}\n.inner_wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 45px;\n}\n.inner_wrapper span {\n  font-family: Lato-Regular, sans-serif;\n  font-size: 29px;\n  line-height: 35px;\n  color: #000000;\n}\n.inner_wrapper div[role='progressbar'] {\n  width: 160px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer__wrapper": "outer__wrapper_aeH18d_s",
	"inner_wrapper": "inner_wrapper__ooPbVoo"
};
export default ___CSS_LOADER_EXPORT___;
