var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.uiConfig && _vm.uiConfig.existFooter
      ? _c(
          "div",
          { class: _vm.$style.footer, attrs: { id: "footer" } },
          [
            _vm.isActiveUser && !_vm.isResourcesMoreThanPaid
              ? _c("div", [
                  _vm.uiConfig.attachment
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.$style.footer_item,
                            _vm.$style.attachment_item,
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.attachmentClick()
                            },
                          },
                        },
                        [
                          _c("svg-sprite", {
                            class: _vm.$style.footer_icons,
                            attrs: { name: "attach", size: 20 },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.itemLocales.attachment) + " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uiConfig.timer
                    ? _c(
                        "div",
                        {
                          ref: "timer_btn",
                          class: [
                            _vm.$style.footer_item,
                            { "pricing-tooltip": _vm.restrictAccessToTimeLog },
                          ],
                          attrs: {
                            "data-feature": "time_tracking",
                            "data-position": "bottom",
                          },
                          on: {
                            click: function ($event) {
                              !_vm.restrictAccessToTimeLog && _vm.timerClick()
                            },
                          },
                        },
                        [
                          _c("svg-sprite", {
                            class: _vm.$style.footer_icons,
                            attrs: { name: "timer", size: 20 },
                          }),
                          _vm._v(" "),
                          _vm.timerValue
                            ? _c("span", { class: [_vm.$style.timer_value] }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.timerValue) +
                                    "\n        "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.itemLocales.timer) +
                                    "\n        "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  class: [_vm.$style.footer_item, _vm.$style.footer_item_store],
                },
                [
                  _c("span", { class: _vm.$style.footer_item_download }, [
                    _vm._v(" " + _vm._s(_vm.download.txt) + " "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: _vm.$style.store_icon,
                      attrs: {
                        target: "_blank",
                        href: _vm.download.google.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.sendAnalytic("android")
                        },
                      },
                    },
                    [
                      _c("svg-sprite", {
                        class: _vm.$style.footer_icons,
                        attrs: { name: "google-play", size: 20 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: _vm.$style.store_icon,
                      attrs: { target: "_blank", href: _vm.download.apple.url },
                      on: {
                        click: function ($event) {
                          return _vm.sendAnalytic("ios")
                        },
                      },
                    },
                    [
                      _c("svg-sprite", {
                        class: _vm.$style.footer_icons,
                        attrs: { name: "app-store", size: 20 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.uiConfig.demo && !_vm.isDemoButtonHidden
                ? _c(
                    "div",
                    {
                      ref: "demo",
                      class: [
                        _vm.$style.footer_item,
                        ((_obj = {}),
                        (_obj[_vm.$style["footer_item--active"]] =
                          _vm.isDemoNotificationPopupShown),
                        (_obj[_vm.$style["footer_item--hovered"]] =
                          _vm.isDemoNotificationPopupShown &&
                          _vm.notificationPopupState.isHovered),
                        _obj),
                      ],
                      attrs: { id: "footerBtnDemoId" },
                      on: {
                        click: function ($event) {
                          return _vm.demoClick("appBottomToolbarButton")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.itemLocales.demo) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uiConfig.learningCenter
                ? _c(
                    "div",
                    {
                      ref: "learningCenter",
                      class: [
                        _vm.$style.footer_item,
                        ((_obj$1 = {}),
                        (_obj$1[_vm.$style["footer_item--active"]] =
                          _vm.isNewsNotificationPopupShown),
                        (_obj$1[_vm.$style["footer_item--hovered"]] =
                          _vm.isNewsNotificationPopupShown &&
                          _vm.notificationPopupState.isHovered),
                        _obj$1),
                      ],
                      attrs: { id: "footerBtnLearningCenterId" },
                      on: { click: _vm.learningCenterClick },
                    },
                    [
                      _c("svg-sprite", {
                        class: _vm.$style.footer_icons,
                        attrs: { name: "help", type: "bold", size: 20 },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.itemLocales.learningCenter) + " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uiConfig.chat
                ? _c(
                    "div",
                    {
                      class: _vm.$style.footer_item,
                      attrs: { id: "footerBtnChatId" },
                      on: {
                        click: function ($event) {
                          return _vm.chatClick()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.itemLocales.chat) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.learningCenterPopupState.isShown
              ? _c("learning-center-popup", {
                  attrs: {
                    "last-clicked-tab":
                      _vm.learningCenterPopupState.lastClickedTab,
                  },
                  on: {
                    rememberLastClickedTab: function ($event) {
                      _vm.learningCenterPopupState.lastClickedTab = $event
                    },
                    close: function ($event) {
                      _vm.learningCenterPopupState.isShown = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "slide-up" } },
              [
                _vm.notificationPopupState.isShown
                  ? _c("notify-popup", {
                      attrs: { type: _vm.notificationPopupState.type },
                      on: {
                        close: function ($event) {
                          _vm.notificationPopupState.isShown = false
                        },
                        click: _vm.handleNotificationPopupClick,
                        highlight: function ($event) {
                          _vm.notificationPopupState.isHovered = $event
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("attachments-popup", {
              attrs: { "is-show": _vm.isShowAttacments },
              on: {
                onClose: function ($event) {
                  _vm.isShowAttacments = false
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }