<template>
  <div style="width: 100%;">
    <!--
    // old code...we must remove it after 28.06.2023
    <tippy
      v-if="!hide"
      :content="content"
      :interactive="interactive"
      :placement="position"
      :to="uiId ? uiId : id"
      :duration="[withAnimation ? 275 : 0 , withAnimation ? 250 : 0 ]"
      trigger="click"
    />
    <div
      :name="uiId ? uiId : id"
      class="tooltip_label"
    >
      <slot name="body" />
    </div> -->

    <div
      v-if="!hide"
      v-tippy="{
        'placement' : position,
        'interactive' : interactive,
        'duration': [withAnimation ? 275 : 0 , withAnimation ? 250 : 0 ],
        'delay' : delay,
        'a11y': false,
      }"
      :content="content"
      class="tooltip_label"
      :style="fullWidth ? 'width: 100%' : ''"
    >
      <slot name="body" />
    </div>
    <div
      v-if="hide"
      :content="content"
      class="tooltip_label"
      :style="fullWidth ? 'width: 100%' : ''"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
    uiId: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      required: false,
      default: 'top',
    },
    interactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    withAnimation: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    delay: {
      type: Number,
      required: false,
      default: 0,
    },
    triggerTarget: {
      type: Object,
      required: false,
      default: null
    }
  },
};
</script>

<style lang='less' src="./less/tooltip.less"></style>
