/* eslint-disable */
import app, { appIds } from '../../../app';
import _ from '../../../libs/lodash';
import historyObject from '../../history/main';
import Mousetrap from '../../../libs/mousetrap';
import routerHelper from '../../../helpers/router';
import plansModel from '../../../models/plans';
import projectsModel from '../../../models/projects';
import actionButtonsModule from './gridActionButtons';
import add_task_button from '../../../svg/task_save.svg';
import cancel_task_button from '../../../svg/task_cancel.svg';
import rights from '../../../components/rights';
import { driver } from 'driver.js';

const __ = window.__;

const {
  popupViews: { ID_VIEW_POPUP_GLOBALSEARCH, ID_VIEW_POPUP_CUSTOMGRID },
} = appIds;

let newTaskInputData;

function showCreateTaskInput(task) {
  const level = task.$level;
  const row = gantt.$grid.querySelector(`.gantt_row[${gantt.config.task_attribute}='${task.id}']`);

  if (!row) return;

  const container = row.querySelector('.text_cell .text_value');

  if (!container) return;

  const coords = keyboardNavigation.calculateAddTaskInputCoords(container);

  if (!coords) {
    keyboardNavigation.updateFocusAddTaskInput(row, false);

    return;
  }

  if (!newTaskInputData) {
    const inputContainer = document.createElement('div');
    const input = document.createElement('input');
    const buttonContainer = document.createElement('div');
    const submitButton = document.createElement('button');
    const cancelButton = document.createElement('button');

    inputContainer.className = 'gantt_create_task_field_container';
    input.className = 'gantt_create_task_field mousetrap';
    input.maxLength = 250;
    submitButton.className = 'add_task_button';
    cancelButton.className = 'cancel_task_button';
    buttonContainer.className = 'button_container';
    cancelButton.innerHTML = cancel_task_button;
    submitButton.innerHTML = add_task_button;

    input.onblur = e => {
      if (!e.target.value) {
        keyboardNavigation.updateFocusAddTaskInput(newTaskInputData.row, false);
        e.target.classList.remove('active');
        inputContainer.classList.remove('active');
        gantt.unloadTaskEditors(); // for unfreeze collaboration
        newTaskInputData.active = false;
      }
    };

    submitButton.onclick = () => {
      gantt.callEvent('addTaskByButton', [
        newTaskInputData.task.parent,
        newTaskInputData.input.value,
      ]);
    };

    cancelButton.onclick = () => {
      keyboardNavigation.updateFocusAddTaskInput(newTaskInputData.row, false);
      input.classList.remove('active');
      inputContainer.classList.remove('active');
      gantt.unloadTaskEditors(); // for unfreeze collaboration
      newTaskInputData.active = false;
    };

    buttonContainer.appendChild(submitButton);
    buttonContainer.appendChild(cancelButton);
    inputContainer.appendChild(input);
    inputContainer.appendChild(buttonContainer);
    document.body.appendChild(inputContainer);

    const buffer = document.createElement('div');
    buffer.className = "buffer";
    input.parentNode.insertBefore(buffer, input.nextSibling);

    input.oninput = function() {
      this.nextElementSibling.innerHTML = this.value;
      this.style.width = this.nextElementSibling.clientWidth + 'px';
    };
    newTaskInputData = {
      inputContainer,
      input,
      resetInput: () => {
        if (!newTaskInputData.active) return;
        inputContainer.classList.remove('active');
        input.value = '';
        newTaskInputData.active = false;
      },
    };
  }

  const { inputContainer, input } = newTaskInputData;

  const resizer = document.querySelector('.gantt_resizer');
  const coordsResizer = resizer.getBoundingClientRect();

  newTaskInputData.row = row;
  newTaskInputData.active = true;
  newTaskInputData.task = task;

  inputContainer.style.cssText = `
        left: ${coords.left - 4}px;
        top: ${coords.top + (coords.height - 20) / 2}px;
        height: 20px;`;
  input.style.cssText = `
        max-width: ${coordsResizer.left - coords.left - 6}px;
        min-width: 178px;
        width: 178px;
     `;

  input.classList.add('active');
  inputContainer.classList.add('active');

  input.value = '';

  input.focus();
  app.trigger('create:ganttTask:focus', task);
}

var keyboardNavigation = {
  rowIndex: 0, // 1
  startIndexColumn: 0,
  columnIndex: 0,
  hiddenEditor: null,
  cssClass: 'gantt_focused',
  blockEnter: false,
  creatingTask: false,
  // needUpdateState: false,
  init() {
    const columns = gantt.getGridColumns();

    if (!gantt.config.multiview) {
      _.each(columns, (column, i) => {
        if (column.name === 'text') {
          keyboardNavigation.startIndexColumn = i;
        }
      });
    }

    keyboardNavigation.columnIndex = keyboardNavigation.startIndexColumn;

    this.initHandlers();
    this.initEvents();
  },
  // moveToHandler: _.debounce(function () {
  //   if (keyboardNavigation.disabled) {
  //     return;
  //   }
  //   keyboardNavigation.moveTo(keyboardNavigation.rowIndex, keyboardNavigation.columnIndex);
  // }, 100),
  initEvents() {
    gantt.attachEvent('setFocusToCreateTaskField', taskIndex => {
      keyboardNavigation.moveTo(taskIndex, keyboardNavigation.columnIndex);
    });

    gantt.attachEvent('onAfterTaskInsert', task => {
      if (!keyboardNavigation.creatingTask) {
        return;
      }

      keyboardNavigation.rowIndex++;
    });
    gantt.attachEvent("onBeforeGanttRender", () => {
      // if(newTaskInputData && newTaskInputData.input.value) {
      //   gantt.callEvent('addTaskByButton', [
      //     newTaskInputData.task.parent,
      //     newTaskInputData.input.value,
      //   ]);
      // }
    });

    gantt.attachEvent('onAfterGanttRender', () => {
      if (!(routerHelper.isGanttRoute() || routerHelper.isMultiProjectsRoute()) || keyboardNavigation.disabled) {
        return;
      }

      const columns = gantt.getGridColumns();

      _.each(columns, (column, i) => {
        if (column.name === 'text') {
          keyboardNavigation.startIndexColumn = i;
        }
      });

      if (columns[keyboardNavigation.columnIndex] && columns[keyboardNavigation.columnIndex].name === 'buttons') {
        keyboardNavigation.columnIndex--;
      }

      if (keyboardNavigation.columnIndex < keyboardNavigation.startIndexColumn) {
        keyboardNavigation.columnIndex = keyboardNavigation.startIndexColumn;
      }

      // keyboardNavigation.moveToHandler();
    });

    gantt.attachEvent('onTaskIdChange', (id, newId) => {
      const task = gantt.getTask(newId);

      if (keyboardNavigation.toTaskDetail && task) {
        _.delay(() => {
          app.trigger('taskSettings:init', task, task.gantt_id);
        });
        keyboardNavigation.toTaskDetail = false;
      }
      _.delay(() => {
        keyboardNavigation.creatingTask = false;
      });
    });

    gantt.attachEvent('onTaskClick', (taskId, e) => {
      const tgt = e.target;
      const cell = tgt.closest('.gantt_cell');

      keyboardNavigation.rowIndex = gantt.getGlobalTaskIndex(taskId);

      if (tgt.classList.contains('gantt_create_task_field')) {
        gantt.saveTaskEditors(true);

        const task = gantt.getTask(taskId);
        const doNotCreateTaskFlag = keyboardNavigation.rowIndex === gantt.getGlobalTaskIndex(taskId);

        cell.querySelector('.line').style.height = `${keyboardNavigation.getLineHeight(gantt.getChildren(task.parent))}px`;

        keyboardNavigation.rowIndex = gantt.getGlobalTaskIndex(taskId);
        keyboardNavigation.moveTo(keyboardNavigation.rowIndex, keyboardNavigation.columnIndex, { doNotCreateTaskFlag });
      }
    });

    gantt.attachEvent('openedInlineEditor', (taskId, columnIndex) => {
      keyboardNavigation.columnIndex = columnIndex;
      keyboardNavigation.rowIndex = gantt.getGlobalTaskIndex(taskId);
      keyboardNavigation.moveTo(keyboardNavigation.rowIndex, keyboardNavigation.columnIndex, { noHiddenEditor: true });
    });

    gantt.attachEvent('addTaskByButton', (parent, value) => {
      const type_task = (gantt.config.creatingMilestoneFlag) ? 'milestone' : 'task';

      keyboardNavigation.creatingTask = true;
      app.trigger('createNewTask', parent, value);
      userExtAnalytics.log('gantt_add_task_grid_done', {
        way: 'button',
        type: type_task,
      });
    });

    gantt.attachEvent('counterKeyNavigation', e => {
      let num = 0;

      if (e.keyCode === 38) {
        num = 1;
        if (e.altKey) {
          num = 0.1;
        }
        if (e.shiftKey) {
          num = 10;
        }
      }
      if (e.keyCode === 40) {
        num = -1;
        if (e.altKey) {
          num = -0.1;
        }
        if (e.shiftKey) {
          num = -10;
        }
      }

      !!num && keyboardNavigation.counterHandler(e, num);
    });

    app.on('leftSideBar:changedMode', () => {
      keyboardNavigation.renderAddTaskInput(true);
    });

    app.on('baseline:changedMode', () => {
      keyboardNavigation.renderAddTaskInput(true);
    });

    app.on('gantt:keyboard:enable', () => {
      keyboardNavigation.toggleState(true);
    });

    app.on('gantt:keyboard:disable', () => {
      keyboardNavigation.toggleState(false);
    });

    app.on('refresh:keyboardNavigation', () => {
      keyboardNavigation.creatingTask = false;
    });

    // $$('welcomeLayout').attachEvent('onHide', () => {
    //   if (userRoleHelper.isActiveTrial() || userRoleHelper.isActive()) {
    //     keyboardNavigation.toggleState(true);
    //   }
    // });
    //
    // $$('welcomeLayout').attachEvent('onBeforeShow', () => {
    //   keyboardNavigation.toggleState(false);
    // });

    $$('globalSearchWindowId').attachEvent('onShow', () => {
      keyboardNavigation.toggleState(false);
    });

    $$('globalSearchWindowId').attachEvent('onAfterAnimationHide', () => {
      keyboardNavigation.toggleState(true);
      // userExtAnalytics.log('global_search_window_hide');
    });

    app.on('refreshKeyboardCursor', taskId => {
      keyboardNavigation.moveTo(keyboardNavigation.rowIndex, keyboardNavigation.columnIndex);
    });

    app.on('createNewTask', () => {
      const input = document.querySelector('.gantt_create_task_field');

      if (input) {
        input.value = '';
      }
    });

    gantt.attachEvent('onGanttScroll', (left, top) => {
      keyboardNavigation.renderAddTaskInput(!keyboardNavigation.creatingTask);
    });

    gantt.attachEvent('onAfterAutoSchedule', () => {
      keyboardNavigation.renderAddTaskInput(true);
    });

    gantt.attachEvent('addTaskByButton', (left, top) => {
      keyboardNavigation.creatingTask = true;
    });

    gantt.attachEvent('onTaskExpandClick', (task, isExpanded) => {
      const taskInputContainer = document.documentElement.querySelector('.gantt_create_task_field_container');

      if (taskInputContainer && taskInputContainer.classList.contains('active')) {
        const input = taskInputContainer.querySelector('input');
        keyboardNavigation.updateFocusAddTaskInput(newTaskInputData.row, false);
        input.classList.remove('active');
        taskInputContainer.classList.remove('active');
        gantt.unloadTaskEditors(); // for unfreeze collaboration
        newTaskInputData.active = false;
      }
    });
  },
  toggleState (enable = true) {
    if (enable && !(routerHelper.isGanttRoute() || routerHelper.isMultiProjectsRoute())) {
      return;
    }

    keyboardNavigation.disabled = !enable;
    Mousetrap[enable ? 'unpause' : 'pause']();
  },
  initHandlers() {
    Mousetrap.prototype.stopCallback = function (e, element, combo) { // called before will triggered any callback
      if (keyboardNavigation.disabled) {
        return true;
      }

      if (document.getElementsByClassName('webix_modal_box')[0]) {
        return true;
      }

      const windowsList = [
        'kanbanWindow',
        'manageTeamLayout',
        'attachmentsListPopup',
        'commentsListPopup',
        'taskSettingsPopup',
        'resourcesLayout',
        'calendarExceptionsPopup',
        'resourceCalendarExceptionsPopup',
        'templateEditorExceptionsPopup',
        'integrationsPopup',
        'createProjectLayout',
        'welcomeLayout',
        'paymentPopup',
        'jiraIntegrationPopup',
        'paymentPlanInfoPopup',
        ID_VIEW_POPUP_CUSTOMGRID,
        'timeLogListLayout',
        'userLogsLayout',
      ];

      const popupsWithAnimationList = [
        'projectsPopupId',
        'notificationsTimeLineModal',
        'saveAsUserTemplate',
        'publicLinkWindow',
        'reportsFeedbackPopup',
        // 'userProfilePopup'
      ];

      if (
        windowsList.some(id => $$(id) && $$(id).isVisible())
        || popupsWithAnimationList.some(id => $$(id) && $$(id).isShown())
      ) {
        if (e.keyCode === 9) { // tab key
          e.preventDefault();
        }

        return true;
      }

      if ((` ${element.className} `).indexOf('mousetrap') > -1) {
        return false;
      }

      return element.tagName == 'INPUT' || element.tagName == 'SELECT' || element.tagName == 'TEXTAREA' || (element.contentEditable && element.contentEditable == 'true');
    };

    Mousetrap.bind({
      up(e) {
        if (gantt.isInlineEditorOpened()) {
          keyboardNavigation.multiAssignHandler(false, -1);

          return;
        }

        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);
        const prevTaskId = gantt.getPrev(task && task.id);

        if (prevTaskId) {
          keyboardNavigation.moveTo(keyboardNavigation.rowIndex - 1, keyboardNavigation.columnIndex, { needUpdateScrollPosition: true });
        }
      },
      down(e) {
        if (gantt.isInlineEditorOpened()) {
          keyboardNavigation.multiAssignHandler(false, 1);

          return;
        }

        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);
        const nextTaskId = gantt.getNext(task && task.id);

        if (!nextTaskId) {
          return;
        }

        const nextTask = gantt.getTask(nextTaskId);

        if (nextTask) {
          keyboardNavigation.moveTo(keyboardNavigation.rowIndex + 1, keyboardNavigation.columnIndex, { needUpdateScrollPosition: true });
        }
      },
      left(e) {
        if (gantt.isInlineEditorOpened()) {
          return;
        }

        const columnIndex = keyboardNavigation.columnIndex;
        const rowIndex = keyboardNavigation.rowIndex;

        if (columnIndex > keyboardNavigation.startIndexColumn && gantt.getTaskByIndex(rowIndex).type !== gantt.config.types.button) {
          keyboardNavigation.moveTo(rowIndex, columnIndex - 1, { needUpdateScrollPosition: true });
          keyboardNavigation.columnIndex--;
        }
      },
      right(e) {
        if (gantt.isInlineEditorOpened()) {
          return;
        }

        const columnIndex = keyboardNavigation.columnIndex;
        const rowIndex = keyboardNavigation.rowIndex;
        const columns = gantt.getGridColumns();

        if (columnIndex < columns.length - 2 && gantt.getTaskByIndex(rowIndex).type !== gantt.config.types.button) { // 2 - because ignore last column
          keyboardNavigation.moveTo(rowIndex, columnIndex + 1, { needUpdateScrollPosition: true });
          keyboardNavigation.columnIndex++;
        }
      },
      'shift+left': function (e) {
        if (gantt.isInlineEditorOpened()) {
          return;
        }

        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (!rights.project.hasRight(task.gantt_id, 'create_task')) {
          return;
        }

        const oldParentId = gantt.getParent(task.id);
        const newParentId = gantt.getParent(oldParentId);

        if (gantt.isTaskExists(oldParentId) && gantt.calculateTaskLevel(gantt.getTask(oldParentId)) !== gantt.config.root_id && task.type !== gantt.config.types.button) {
          gantt.moveTask(task.id, gantt.getGlobalTaskIndex(oldParentId) + 1, newParentId);
          gantt.callEvent('manualMoveTask', [task.id, newParentId, oldParentId, true]);
        }
      },
      'shift+right': function (e) {
        if (gantt.isInlineEditorOpened()) {
          return;
        }

        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (!rights.project.hasRight(task.gantt_id, 'create_task')) {
          return;
        }

        const oldParentId = gantt.getParent(task.id);
        const newParentId = gantt.getPrevSibling(task.id);

        if (gantt.isTaskExists(newParentId) && !gantt.isChildOf(task.id, newParentId) && task.type !== gantt.config.types.button) {
          const parent = gantt.getTask(newParentId);

          parent.$open = 1;
          gantt.moveTask(task.id, -1, newParentId);
          gantt.callEvent('manualMoveTask', [task.id, newParentId, oldParentId, true]);
        }
      },
      tab(e) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        const columnIndex = keyboardNavigation.columnIndex;
        const columns = gantt.getGridColumns();
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (gantt.isInlineEditorOpened()) {
          gantt.saveTaskEditors(true);
        }

        if (task.type === gantt.config.types.button || columnIndex >= columns.length - 2) {
          keyboardNavigation.columnIndex = keyboardNavigation.startIndexColumn;
          Mousetrap.trigger('down');
        } else {
          Mousetrap.trigger('right');
        }
      },
      'shift+tab': function (e) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        const columnIndex = keyboardNavigation.columnIndex;
        const columns = gantt.getGridColumns();
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (gantt.isInlineEditorOpened()) {
          gantt.saveTaskEditors(true);
        }

        if (task.type === gantt.config.types.button || columnIndex <= keyboardNavigation.startIndexColumn) {
          keyboardNavigation.columnIndex = columns.length - 2;
          Mousetrap.trigger('up');
        } else {
          Mousetrap.trigger('left');
        }
      },
      'shift+enter': function (e) {
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (task.type === gantt.config.types.button) {
          const type_task = (gantt.config.creatingMilestoneFlag) ? 'milestone' : 'task';

          keyboardNavigation.creatingTask = true;
          keyboardNavigation.toTaskDetail = true;

          app.trigger('createNewTask', task.parent, e.target.value);
          userExtAnalytics.log('gantt_add_task_grid_done', {
            way: 'shift + enter',
            type: type_task,
          });
        } else if (gantt.isInlineEditorOpened()) {
          gantt.saveTaskEditors(true);
          keyboardNavigation.toTaskDetail = true;
        } else {
          app.trigger('taskSettings:init', task, task.gantt_id);
        }
      },
      enter(e) {
        const columns = gantt.getGridColumns();
        const columnIndex = keyboardNavigation.columnIndex;
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (keyboardNavigation.blockEnter || keyboardNavigation.creatingTask) {
          return;
        }

        if (!keyboardNavigation.blockEnter) {
          keyboardNavigation.blockEnter = true;
        }

        if (e.target.classList.contains('gantt_create_task_field')) {
          const type_task = (gantt.config.creatingMilestoneFlag) ? 'milestone' : 'task';

          keyboardNavigation.creatingTask = true;
          app.trigger('createNewTask', task.parent, e.target.value);
          userExtAnalytics.log('gantt_add_task_grid_done', {
            way: 'enter',
            type: type_task,
          });

          return;
        }

        if (!gantt.isInlineEditorOpened()) {
          if (gantt._inline_edit.hasEditor(columns[columnIndex], task)) {
            gantt.showTaskEditors(task, columnIndex);
          } else {
            Mousetrap.trigger('down');
          }
        } else {
          _.delay(() => {
            gantt.saveTaskEditors(true);
            Mousetrap.trigger('down');
          }, 100);
        }
      },
      space(e) {
        const columns = gantt.getGridColumns();
        const columnIndex = keyboardNavigation.columnIndex;
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        if (!gantt.isInlineEditorOpened()) {
          if (gantt._inline_edit.hasEditor(columns[columnIndex], task)) {
            gantt.showTaskEditors(task, columnIndex);
          } else if (task.type !== gantt.config.types.button) {
            Mousetrap.trigger('down');
          }
        } else {
          keyboardNavigation.multiAssignHandler(true);
        }
      },
    });

    Mousetrap.bind(['shift+down', 'shift+up'], e => {
      if (gantt.isInlineEditorOpened()) {
        return;
      }

      const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

      if (gantt.hasChild(task.id) && task.$level) {
        keyboardNavigation.toggleTask(task);
      }
    });

    Mousetrap.bind(['enter'], e => {
      keyboardNavigation.blockEnter = false;
    }, 'keyup');

    Mousetrap.bind(['esc', 'mod+z'], (e, combo) => {
      if (gantt.isInlineEditorOpened()) {
        gantt.saveTaskEditors(true, true);
      } else if (!$$('historyId').isVisible() && combo === 'mod+z' && window.localStorage.getItem('introjsCtrlZ')) {
        historyObject.handlers.showPopup();
      }
    });

    Mousetrap.bind(['del', 'command+backspace'], e => {
      const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);
      const isHistory = $$('historyId').isVisible();
      const isExport = routerHelper.isExportRoute();

      if (!gantt.isInlineEditorOpened() && !isHistory && !isExport) {
        if (task && task.type !== gantt.config.types.button && task.$level > 0) {
          actionButtonsModule.handlers.removeTaskById(task.id);
        }
      }
    });

    document.addEventListener('click', e => {
      if (e.target.closest('.js_header') || e.target.closest('.left-sidebar')) {
        keyboardNavigation.hideCursor();
      }
      if (e.target.closest('.gantt_grid_head_buttons') || e.target.classList.contains('gantt_grid_head_buttons')) {
        keyboardNavigation.renderAddTaskInput(true);
      }
      if (e.target.closest('.js_add_task') || e.target.closest('.js_add_milestone') ) {
        //keyboardNavigation.creatingTask = true;
        gantt.config.creatingMilestoneFlag = e.target.closest('.js_add_milestone');
        keyboardNavigation.renderAddTaskInput();
      }

      if(e.target.closest('.js_filter_btn')) keyboardNavigation.renderAddTaskInput(true);
      if (
        (e.target.closest('.js_header')
          || e.target.closest('.left-sidebar')
          || e.target.closest('.kanbanWindow'))
        && !e.target.closest('.js_filter_btn')
        && !e.target.closest('.js_settings_btn')
        && !e.target.closest('.js_slack_btn')
      ) {
        app.trigger('closeWindowWithAnimation');
      }
    });

    document.addEventListener('mousedown', e => {

      if (e.target.closest('.gantt_resizer_x')) {
        //app.trigger('closeWindowWithAnimation');
        if(newTaskInputData && newTaskInputData.input.value)  {
          gantt.callEvent('addTaskByButton', [
            newTaskInputData.task.parent,
            newTaskInputData.input.value,
          ]);
        }
      }

    });
  },

  counterHandler(e, num) {
    e.preventDefault(e);

    const webixId = e.target.closest('.webix_view').getAttribute('view_id');
    const counter = $$(webixId);
    const oldValue = counter.getValue();
    let newValue = +oldValue + num;

    if (newValue >= 0) {
      newValue = Math.round(newValue * 100) / 100;
      counter.setValue(newValue);
    }
  },
  multiAssignHandler(isSelect, num) {
    if ($$('resourcesSelectListPopup').isVisible()) {
      const list = $$('resourcesSelectList');
      const data = list.serialize();
      const selectedId = list.getSelectedId();
      let pos = 0;

      if (isSelect) {
        _.delay(() => { // to next step rendering
          selectedId && app.trigger('selectInResourceList', selectedId);
        });

        return;
      }

      selectedId && list.updateItem(selectedId);

      _.each(data, (o, i) => {
        if (o.id === selectedId) {
          pos = i;
        }
      });

      pos += num;

      if (pos < 0) {
        pos = 0;
      }
      if (pos > data.length - 1) {
        pos = data.length - 1;
      }
      if (data[pos].id === 'divider') {
        pos += num;
      }

      list.select(data[pos].id);
    }
  },
  moveTo(rowIndex, columnIndex, params) {
    if (!(routerHelper.isGanttRoute() || routerHelper.isMultiProjectsRoute()) || keyboardNavigation.disabled) {
      return;
    }

    // if (rowIndex === 0) {
    //   rowIndex = 1;
    // }

    let task = gantt.getTaskByIndex(rowIndex);

    params = params || {};

    while (!task && rowIndex > 0) {
      const tempTask = gantt.getTaskByIndex(--rowIndex);

      if (tempTask && tempTask.type !== gantt.config.types.button) {
        task = tempTask;
      }
    }

    if (!task) {
      return;
    }

    const visibleColumns = gantt.getGridColumns();
    const isAddTaskRow = task.type === gantt.config.types.button && task.$level > 0;

    if (!visibleColumns[columnIndex]) {
      columnIndex = 1;// temp
      keyboardNavigation.columnIndex = columnIndex;
    }

    _.each(gantt.$container.querySelectorAll('.row_hover'), el => {
      el.classList && el.classList.remove('row_hover');
    });

    if (params.needUpdateScrollPosition) {
      keyboardNavigation.checkScrollPosition(task);
    }
    keyboardNavigation.rowIndex = rowIndex;

    keyboardNavigation.renderAddTaskInput();

    const node = keyboardNavigation.getNode(task.id, columnIndex);

    keyboardNavigation.removeHiddenEditor();

    keyboardNavigation.selectNode(node);

    if (node && node.classList && node.classList.contains('tooltip-gantt')) {
      node.classList.remove('tooltip-gantt');
    };

    if (visibleColumns[columnIndex] && visibleColumns[columnIndex].name === 'text' && !isAddTaskRow && !params.noHiddenEditor) {
      keyboardNavigation.createHiddenEditor();
    }
  },
  checkScrollPosition(task) {
    const pos = gantt.getTaskPosition(task);
    const height = gantt.config.row_height;
    const scroll = gantt.getScrollState();
    let viewHeight;

    if (gantt.$grid_data || gantt.$task_data) {
      viewHeight = (gantt.$grid_data || gantt.$task_data).offsetHeight;
    } else {
      viewHeight = scroll.inner_height;
    }

    if (pos.top < scroll.y || pos.top + height > (scroll.y + viewHeight)) {
      gantt.scrollTo(null, pos.top - height * 5);
    }
  },
  hideCursor() {
    const cssClass = keyboardNavigation.cssClass;

    _.each(document.getElementsByClassName(cssClass), el => {
      el.classList.remove(cssClass);
    });
  },
  getNode(taskId, columnIndex) {
    if (gantt.isTaskExists(taskId) && gantt.isTaskVisible(taskId)) {
      if (gantt.config.show_grid) {
        const row = gantt.$grid.querySelector(`.gantt_row[${gantt.config.task_attribute}='${taskId}']`);

        if (row) {
          return row.childNodes[columnIndex];
        }
      } else {
        return gantt.getTaskNode(taskId);
      }
    }
  },
  selectNode(node) {
    const cssClass = keyboardNavigation.cssClass;
    const ganttRowSelectClass = 'row_focused';

    _.each(document.getElementsByClassName(cssClass), el => {
      el && el.classList.remove(cssClass);
    });

    _.each(document.getElementsByClassName('gantt_row'), row => {
      row && row.classList.remove(ganttRowSelectClass);
    });

    if (node && node.closest('.gantt_row')) {
      node.closest('.gantt_row').classList.add(ganttRowSelectClass);
    }

    node && node.classList.add(cssClass);
  },
  createHiddenEditor() {
    const input = document.createElement('input');
    let listenKeyUpEvent = true;

    input.type = 'text';
    input.className = 'mousetrap hidden_editor';
    document.body.appendChild(input);
    keyboardNavigation.hiddenEditor = input;

    input.onkeyup = function (e) {
      if (listenKeyUpEvent && e.target.value.trim()) {
        const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

        listenKeyUpEvent = false;
        gantt.showTaskEditors(task, keyboardNavigation.columnIndex, e.target.value);
      }
    };

    input.focus();
  },
  renderAddTaskInput(forceHide) {
    const taskLevelLimit = routerHelper.isMultiProjectsRoute() ? 1 : 0;

    newTaskInputData?.resetInput();
    if (!(routerHelper.isGanttRoute() || routerHelper.isMultiProjectsRoute()) || keyboardNavigation.disabled || !gantt.$grid) {
      return;
    }

    const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);

    if (!task) return;

    const row = gantt.$grid.querySelector(`.gantt_row[${gantt.config.task_attribute}='${task.id}']`);

    if (!row) return;

    const isShow = task.type === gantt.config.types.button && task.$level > taskLevelLimit;

    if (forceHide) {
      keyboardNavigation.updateFocusAddTaskInput(row, false);

      return;
    }

    keyboardNavigation.updateFocusAddTaskInput(row, isShow);

    if (!isShow) return;

    showCreateTaskInput(task);
  },
  updateFocusAddTaskInput(row, isShow) {
    const task = gantt.getTaskByIndex(keyboardNavigation.rowIndex);
    const line = row.querySelector('.line');

    if (!gantt.$grid) {
      return;
    }

    gantt.$grid.querySelectorAll('.gantt_row').forEach(el => {
      el.classList.remove('active');
    });

    if (!task) {
      return;
    }

    if (line) {
      line.style.height = `${keyboardNavigation.getLineHeight(gantt.getChildren(task.parent))}px`;
    }

    if (isShow) {
      row.classList.add('active');
    }

    isShow && row.classList.add('active');
  },
  calculateAddTaskInputCoords(sourceEl, isTopLevelTask) {
    const viewportCoords = gantt.$grid_data.getBoundingClientRect();
    const coords = sourceEl.getBoundingClientRect();
    const topCheck = coords.top + coords.height < viewportCoords.top;
    const bottomCheck = coords.top > viewportCoords.top + viewportCoords.height;

    if (topCheck || bottomCheck) {
      return false;
    }

    const width = document.body.offsetWidth <= coords.left + coords.width ? document.body.offsetWidth - coords.left : coords.width - 30;

    return {
      top: coords.top,
      left: coords.left,
      height: coords.height,
      width,
    };
  },
  removeHiddenEditor() {
    if (keyboardNavigation.hiddenEditor) {
      keyboardNavigation.hiddenEditor.remove();
      keyboardNavigation.hiddenEditor = null;
    }
  },
  getLineHeight(allChildren) {
    const rows = [];
    const filterState = gantt.getTasksFilterState();

    function getChildren(children) {
      if (!children.length) {
        return;
      }

      _.each(children, child => {
        if (filterState.active && filterState.foundTasks.length) {
          const foundTask = _.indexOf(filterState.foundTasks, child);

          foundTask !== -1 && rows.push(child);
        } else {
          rows.push(child);
        }

        if (gantt.getTask(child).$open) {
          getChildren(gantt.getChildren(child));
        }
      });
    }

    getChildren(allChildren);

    if (gantt.config.baseline === 1) {
      return rows.length * (gantt.config.row_height + 1) - 10;
    }

    return rows.length * (gantt.config.row_height) - gantt.config.row_height / 2 + 5;
  },
  toggleTask(task) {
    if (!app.config.mode.isBase) {
      if (!task.$open) {
        gantt.open(task.id);
      } else {
        gantt.close(task.id);
      }

      return;
    }

    gantt.blockEvents();
    if (!task.$open) {
      gantt.open(task.id);
      task.open = 1;
    } else {
      gantt.close(task.id);
      task.open = 0;
    }

    gantt.unblockEvents();

    _.delay(() => {
      gantt.updateTask(task.id);
      gantt.$ui.getView('scrollVer').resize();
    });
  },

};

var innerObject = {

  init: {
    run() {
      innerObject.settings.enablePlugin = true;
      innerObject.settings.canWork = true;

      keyboardNavigation.init();
    },
    afterInit() {

    },
  },
  settings: {
    linkId: false,
    enablePlugin: false,
    canWork: false,
    ganttId: 0,
  },
  driverJsPlugin: {
    startIntro() {
      const driverObj = driver({
        popoverClass: 'gp-driver-popover',
        animate: true,
        stagePadding: 5,
        showProgress: false,
        showButtons: ['next'],
        doneBtnText: __('header-toolbar-button-introjs-btn'),
        steps: [
          {
            element: '#js_header_history_button',
            popover: {
              description: __('header-toolbar-button-introjs'),
              side: 'bottom',
              align: 'end'
            }
          },
        ],
        onNextClick() {
          window.localStorage.setItem('introjsCtrlZ', 1);
          driverObj.destroy();
        }
      });

      driverObj.drive();
    },
    KeyPressCancel(e) {
      const evtobj = window.event ? event : e;

      if ((evtobj.ctrlKey || evtobj.code === 'KeyZ') && !(routerHelper.isGanttRoute() || routerHelper.isMultiProjectsRoute())) {
        app.trigger('gantt:keyboard:disable');

        return;
      }

      if (((evtobj.ctrlKey
            || evtobj.keyCode === 224
            || evtobj.keyCode === 17
            || evtobj.keyCode === 91)
          || evtobj.metaKey)
        && evtobj.which === 90) {
        if (document.getElementsByClassName('driver-overlay').length > 0) {
          e.preventDefault();

          return;
        }

        if (!window.localStorage.getItem('introjsCtrlZ')) {
          innerObject.driverJsPlugin.startIntro();
        }
      }
    },

  },
  toggleGlobalSearchIfNeeded: e => {
    if (
      (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70))
      && (plansModel.isActiveTrial() || plansModel.isActive())
      && projectsModel.getAllProjects().length > 0
    ) {
      e.preventDefault();

      const __$$globalSearchWndView = $$(ID_VIEW_POPUP_GLOBALSEARCH);

      if (__$$globalSearchWndView.isShown()) {
        __$$globalSearchWndView.hide();
      } else {
        __$$globalSearchWndView.show();
      }
    }
  },
  fireEventOnEscKeyPressed: e => {
    if (e.keyCode === 27) {
      app.trigger('closeWindowOnEscKeyPressed');
    }
  },
};

document.addEventListener('keydown', e => {
  innerObject.toggleGlobalSearchIfNeeded(e);
  innerObject.driverJsPlugin.KeyPressCancel(e); // only for keydown
  innerObject.fireEventOnEscKeyPressed(e);
});

webix.UIManager.addHotKey('esc', view => {
  for (const i in webix.ui.views) {
    const obj = webix.ui.views[i];

    if ((obj.name === 'window' || obj.name === 'image-uploader') && obj.isVisible() && !obj.config.unClosable) {
      obj.hide();
    }
  }

  app.trigger('closeWindowWithAnimation');

  if (view) {
    const top = view.getTopParentView();

    if (top.name === 'popupWithAnimation' && top.isShown()) {
      top.hide();
    }

    if (_.includes(['window', 'popupWithoutPoint', 'popupWithShadow'], top.name) && top.isVisible() && !top.config.unClosable) {
      top.hide();
    } else {
      return false;
    }
  }
});

const outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit,
  },
  helpers: innerObject.helpers,
  handlers: innerObject.handlers,
};

app.on('close:createTaskInput:withoutSave', () => {
  let cancelButton = document.querySelector('button.cancel_task_button');
  cancelButton && cancelButton.onclick();
})

app.on('popup:show', () => {
  gantt.unselectTask();
});

app.on('popup:hide', () => {
  innerObject.settings.canWork = true;
});

app.on('hideCursor', () => {
  keyboardNavigation.hideCursor();
});

export default outputObject;
