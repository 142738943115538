// dataProvider.js
// Provides data for the dynamic building of the mass change window

// import customColModel from '../../models/customColumns';

// import icon_unassigned from '../../svg/default/unassigned.svg';

// const _statusColData = customColModel.getColumnByName('status');
// const _priorityColData = customColModel.getColumnByName('priority');

const _actionTypes = {
  ASSIGN: 0,
  STATUS: 1,
  PRIORITY: 2,
  COLOR: 3,
  CUSTOM_COL: 4,
};

const DEFAULT_RES_IMG = '/imgs/default/default-ava.svg';
const UNASSINED_RES_IMG = '/imgs/default/unassigned.svg';

export const actionTypes = _actionTypes;
