/* eslint-disable */
import app from '../../../app';
import moment from '../../../libs/moment';
import _ from '../../../libs/lodash';

import ganttViewModel from '../../../models/ganttViewModel';
import projectsModel from '../../../models/projects';
import constants from '../../../helpers/constants';
import customHelper from '../../../helpers/custom';
import dateHelper from '../../../helpers/dateFormats';
import globalColumns from '../../profileSettings/include/columns';
import globalStore from '../../../store/main';

import icon_edit from '../../../svg/webix_material/edit_icon.svg';
import icon_delete from '../../../svg/webix_material/delete_icon.svg';

import icon_multiselect from '../../../svg/webix_material/custom_columns/multiselect.svg';
import icon_checkbox from '../../../svg/webix_material/custom_columns/checkbox.svg';
import icon_number from '../../../svg/webix_material/custom_columns/number.svg';
import icon_resources from '../../../svg/webix_material/custom_columns/resources.svg';
import icon_color from '../../../svg/webix_material/custom_columns/color.svg';
import icon_date from '../../../svg/webix_material/custom_columns/date.svg';
import icon_list from '../../../svg/webix_material/custom_columns/list.svg';
import icon_tags from '../../../svg/webix_material/custom_columns/tags.svg';
import icon_text from '../../../svg/webix_material/custom_columns/text.svg';
import icon_save from '../../../svg/webix_material/save_edit.svg';
import icon_cancel from '../../../svg/webix_material/cancel_edit.svg';
import icon_info from '../../../svg/integration/settings/yellow_info.svg';
import icon_close from '../../../svg/ic_close.svg';

import routerHelper from '../../../helpers/router';
import baselineModel from '../../../models/baselines';
import rights from '../../../components/rights';
import pricingHelper from '../../../helpers/pricingHelper';
import numbersHelper from '../../../helpers/numbers';

const __ = window.__;

gantt.attachEvent('beforeShowCustomColumnSuggestList', () => {
  innerObject.settings.doNotShowPreview = true;
});

gantt.attachEvent('showCustomColumnPreviewList', (target, taskId, columnId) => {
  innerObject.settings.doNotShowPreview = false;
  innerObject.handlers.showCustomColumnPreviewPopup(target, taskId, columnId);
});

gantt.attachEvent('hideCustomColumnPreviewList', () => {
  $$(innerObject.views.customColumnPreviewPopup).hide();
  $$(innerObject.views.customColumnPreviewPopupText).hide();
  innerObject.settings.doNotShowPreview = true;
});

if (!webix.env.touch && webix.env.scrollSize) {
  webix.CustomScroll.init();
}

const optionsTypeSelect = [
  {
    id: constants.CUSTOM_COLUMNS_TYPES.text.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.text.locale),
    icon: icon_text,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.number.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.number.locale),
    icon: icon_number,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.date.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.date.locale),
    icon: icon_date,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.select.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.select.locale),
    icon: icon_list,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.checkbox.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.checkbox.locale),
    icon: icon_checkbox,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.color.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.color.locale),
    icon: icon_color,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.multiselect.locale),
    icon: icon_multiselect,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.resources.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.resources.locale),
    icon: icon_resources,
  },
  {
    id: constants.CUSTOM_COLUMNS_TYPES.tags.id,
    value: __(constants.CUSTOM_COLUMNS_TYPES.tags.locale),
    icon: icon_tags,
  },
];

const optionsAggregationSelect = [
  {
    id: constants.CUSTOM_COLUMNS_SUBTYPES.sum.id,
    value: __(constants.CUSTOM_COLUMNS_SUBTYPES.sum.locale),
  },
  {
    id: constants.CUSTOM_COLUMNS_SUBTYPES.average.id,
    value: __(constants.CUSTOM_COLUMNS_SUBTYPES.average.locale),
  },
];

const defaultValuesForColumnItem = {
  [constants.CUSTOM_COLUMNS_TYPES.select.id]: __('custom_column_select_default_name'),
  [constants.CUSTOM_COLUMNS_TYPES.multiselect.id]: __('custom_column_select_default_name'),
  [constants.CUSTOM_COLUMNS_TYPES.tags.id]: __('custom_column_default_tag_name'),
};

const defaultColor = '#4FC3F7';
let tagColorsUsed = [];
let colorsUsed = [];
const customColumnsFeatureKey = 'custom_fields';

const editorsForColumns = {
  [constants.CUSTOM_COLUMNS_TYPES.select.id]: 'searchselect',
  [constants.CUSTOM_COLUMNS_TYPES.text.id]: 'text',
  [constants.CUSTOM_COLUMNS_TYPES.number.id]: 'custom_counter',
  [constants.CUSTOM_COLUMNS_TYPES.date.id]: 'custom_datepicker',
  [constants.CUSTOM_COLUMNS_TYPES.checkbox.id]: 'checkbox',
  [constants.CUSTOM_COLUMNS_TYPES.color.id]: 'select_color',
  [constants.CUSTOM_COLUMNS_TYPES.multiselect.id]: 'searchmultiselect',
  [constants.CUSTOM_COLUMNS_TYPES.resources.id]: 'resources',
  [constants.CUSTOM_COLUMNS_TYPES.tags.id]: 'tags',
};

const alignForColumns = {
  [constants.CUSTOM_COLUMNS_TYPES.select.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.text.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.number.id]: 'right',
  [constants.CUSTOM_COLUMNS_TYPES.date.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.checkbox.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.color.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.multiselect.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.resources.id]: 'left',
  [constants.CUSTOM_COLUMNS_TYPES.tags.id]: 'left',
};

const dataTypesForColumns = {
  [constants.CUSTOM_COLUMNS_TYPES.select.id]: 'custom_select',
  [constants.CUSTOM_COLUMNS_TYPES.text.id]: 'custom_text',
  [constants.CUSTOM_COLUMNS_TYPES.number.id]: 'custom_number',
  [constants.CUSTOM_COLUMNS_TYPES.date.id]: 'custom_date',
  [constants.CUSTOM_COLUMNS_TYPES.checkbox.id]: 'custom_checkbox',
  [constants.CUSTOM_COLUMNS_TYPES.color.id]: 'custom_color',
  [constants.CUSTOM_COLUMNS_TYPES.multiselect.id]: 'custom_multiselect',
  [constants.CUSTOM_COLUMNS_TYPES.resources.id]: 'custom_resources',
  [constants.CUSTOM_COLUMNS_TYPES.tags.id]: 'custom_tags',
};

const defaultValuesByColumnType = {
  [constants.CUSTOM_COLUMNS_TYPES.text.id]: '',
  [constants.CUSTOM_COLUMNS_TYPES.number.id]: 0,
  [constants.CUSTOM_COLUMNS_TYPES.checkbox.id]: 0,
  [constants.CUSTOM_COLUMNS_TYPES.date.id]: '',
  [constants.CUSTOM_COLUMNS_TYPES.resources.id]: '',
};

// {Array} -> {} !TODO: event is not needed
gantt.attachEvent('onMassChangeCustomValues', colActionsByTaskIds => {
  colActionsByTaskIds.forEach(entry => {
    const __idTask = entry.idTask;
    const __colActions = entry.actions;
    const __taskEntry = gantt.getTask(__idTask);

    __colActions.forEach(colActionEntry => {
      let value = colActionEntry.value;
      const columnId = colActionEntry.actionId;
      const __column = globalStore.getters['columns/getCustomColumnById'](columnId);

      if (__column.type === constants.CUSTOM_COLUMNS_TYPES.date.id) {
        value = moment(value).format('YYYY-MM-DD HH:mm:ss');
      }

      if (__column.type === constants.CUSTOM_COLUMNS_TYPES.text.id) {
        value = customHelper.removeSpaces(value);

        if (value.length > 2048) {
          value = value.substr(0, 2047);
        }
      }

      globalStore.commit('columns/changeCustomValues', {
        task: __taskEntry,
        columnId: __column.id,
        value,
      })
    });

    // app.trigger('userCustomValuesModel:change', task);
    gantt.silentUpdateTask(__idTask, __taskEntry);
  });
});

gantt.attachEvent('canEditColumn', (task, columnId) => {
  const currentProject = globalStore.getters['columns/getProjectById'](task.gantt_id);
  const isColumn = currentProject?.columns.includes(+columnId);

  if (!isColumn && task.parent !== 0) {
    webix.message({ type: 'warning', text: __('msg_cant_edit_column') });

    return false;
  }

  return true;
});

gantt.attachEvent('onChangeUserCustomValue', (task, columnId, value) => {
  const column = globalStore.getters['columns/getCustomColumnById'](columnId);

  if (!column) {
    return false;
  }

  if (column.type === constants.CUSTOM_COLUMNS_TYPES.date.id) {
    const isEmpty = value === '';
    const isValid = value instanceof Date && !isNaN(value);

    if (!isValid && !isEmpty) {
      webix.message({ type: 'warning', text: __('gantt_date_invalid') });

      return false;
    }

    value = dateHelper.checkIfDateInLimit(value, true);

    value = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;
  }

  if (column.type === constants.CUSTOM_COLUMNS_TYPES.text.id) {
    value = customHelper.removeSpaces(value);
    if (value.length > 2048) {
      value = value.substr(0, 2047);
      webix.message({ type: 'warning', text: __('msg_custom_column_long_text') });
    }
  }

  if (column.type === constants.CUSTOM_COLUMNS_TYPES.checkbox.id) {
    const oldValue = +innerObject.helpers.getValueForUserColumn(task, column).value;

    value = +!oldValue;
  }

  globalStore.dispatch('columns/changeCustomValues', { task, column, value })
  gantt.callEvent('taskEditor:task:changed', [task]);

  if (column.type === constants.CUSTOM_COLUMNS_TYPES.number.id && routerHelper.isGanttRoute()) {
    let pid = gantt.getParent(task);

    while (pid) {
      gantt.refreshTask(pid);
      const parent = gantt.getTask(pid);

      pid = gantt.getParent(parent);
    }
  }
});

gantt.attachEvent('onChangeTaskToParent', task => {
  const columnTypesForReset = [constants.CUSTOM_COLUMNS_TYPES.number.id];

  if (!task) {
    return;
  }

  _.each(globalStore.getters['columns/getColumnIdsByProjectId'](task.gantt_id), columnId => {
    const column = globalStore.getters['columns/getCustomColumnById'](columnId);

    if (_.includes(columnTypesForReset, column.type)) {
      const value = globalStore.getters['columns/getValueByParams']( {
        projectId: task.gantt_id,
        taskId: task.id,
        columnId: column.id
      });

      if (!_.isUndefined(value)) {
        globalStore.dispatch('columns/removeCustomValue', { task, columnId: column.id })
      }
    }
  });
});

var innerObject = {
  settings: {
    clickedEditButtonId: null,
    currentColumn: null,
    currentProjets: null,
    defaultColumnType: constants.CUSTOM_COLUMNS_TYPES.text.id,
    dateFormat: null,
    defaultDataSet: null,
    isGlobal: null,
  },
  views: {
    popupId: 'customColumnPopup',
    colorPopupId: 'customColumnColorPopupId',
    colorBoard: 'customColumnColorboard',
    customColumnPreviewPopup: 'customColumnPreviewPopup',
    customColumnPreviewPopupText: 'customColumnPreviewPopupText',
    customColumnPreviewText: 'customColumnPreviewText',
    customColumnPreviewList: 'customColumnPreviewList',
    selectCustomColumnEditorList: 'selectCustomColumnEditorList',
    popupTabWrapper: 'popupTabWrapper',
    customColumsMatchesPopup: 'customColumsMatchesPopup',
    customColumsMatchesList: 'customColumsMatchesList',
    closeHeaderButton: {
      view: 'template',
      borderless: true,
      width: 30,
      height: 30,
      template() {
        return `<div class="close_control_container">${icon_close}</div>`;
      },
      onClick: {
        close_control_container() {
          $$(innerObject.views.popupId).hide();
        },
      },
    },
    headerCreateLabel: {
      view: 'label',
      id: 'customColumnPopupCreateTitle',
      height: 24,
      css: 'custom_column_popup_title',
      borderless: true,
      label: __('create_column_popup_title'),
    },
    headerEditLabel: {
      view: 'label',
      id: 'customColumnPopupEditTitle',
      height: 30,
      css: 'custom_column_popup_title',
      borderless: true,
      label: __('edit_column_popup_title'),
    },
    messageBox: {
      id: 'customColumnPopupMessageBox',
      rows: [
        {
          view: 'template',
          borderless: true,
          id: 'customColumnPopupMessage',
          css: 'custom_column_popup_message',
          textValue: '',
          messageType: 'info',
          autoheight: true,
          template(obj, elem) {
            const textObj = elem.config.textValue;

            return `<div class='custom_column_message ${elem.config.messageType}'>
              <div class='message_icon'>${icon_info}</div>
              <div class='message_text_box'>
                ${textObj.visible ? `<div class='message_text'>${__('columns_projects_number', { number: textObj.visible })}</div>` : ''}
                ${textObj.archive ? `<div class='message_text'>${__('columns_archive_projects_number', { number: textObj.archive })}</div>` : ''}
              </div>
            </div>`;
          },
        },
        { height: 10 },
      ],
    },
    nameFieldLabel: {
      view: 'label',
      height: 36,
      label: __('custom_column_name_label'),
      css: 'custom_column_popup_name',
    },
    nameField: {
      height: 36,
      view: 'text',
      id: 'customColumnPopupName',
      inputHeight: 36,
      keyPressTimeout: 0,
      placeholder: __('custom_column_name_placeholder'),
      attributes: {
        maxlength: 250,
      },
      suggest: {
        data: [],
        template(obj) {
          return `<div>${obj.name}</div>`;
        },
      },
      on: {
        onChange(newVal, oldVal) {
          if (!customHelper.validateColumnName(newVal)) {
            webix.message({ type: 'warning', text: __('msg_custom_column_name_invalid') });
            this.blockEvent();
            this.setValue(oldVal);
            this.unblockEvent();
          }
        },
        onTimedKeyPress() {
          const value = this.getValue();
          const isAddFromProject = !innerObject.settings.currentColumn && !innerObject.settings.isGlobal;
          const isSettings = routerHelper.isColumnsSettingsRoute();

          if (isAddFromProject && !isSettings) {
            innerObject.helpers.findColumnsMatches(value);
          }
        },
        onItemClick() {
          const matchesPopup = $$(innerObject.views.customColumsMatchesPopup);

          if (matchesPopup.isVisible()) {
            matchesPopup.show();
          }
        },
      },
    },
    columnsMatches: {
      view: 'template',
      id: 'customColumnMatches',
      css: 'custom_column_matches_link',
      height: 30,
      borderless: true,
      autowidth: true,
      textValue: __('custom_column_matches_default'),
      template(obj, elem) {
        return `<span class="custom_column_matches_link_text">${elem.config.textValue}</span>`;
      },
      onClick: {
        custom_column_matches_link_text() {
          const matchesPopup = $$(innerObject.views.customColumsMatchesPopup);
          const columnNameField = $$(innerObject.views.nameField.id);
          const value = columnNameField.getValue();

          if (!value.trim()) {
            innerObject.helpers.addMatchedColumnsToSuggestList('');
          }

          matchesPopup.show(columnNameField.getNode());
        },
      },
    },
    typeSelect: {
      height: 72,
      view: 'richselectWithoutPoint',
      id: 'customColumnTypeSelect',
      css: 'custom_column_type_select',
      suggestCss: 'custom_column_type_suggest_popup',
      value: 1,
      label: __('custom_column_type_label'),
      labelPosition: 'top',
      inputHeight: 36,
      suggest: {
        body: {
          data: optionsTypeSelect,
          template(obj) {
            return `<div class="custom_columns_type_container"><span class="custom_columns_type_icon">${obj.icon}</span>${obj.value}</div>`;
          },
        },
        on: {
          onShow() {
            const navlink = $$(innerObject.views.settingsLink.id);

            if (navlink) {
              navlink.disable();
            }
          },
          onHide() {
            const navlink = $$(innerObject.views.settingsLink.id);

            if (navlink) {
              _.delay(() => {
                navlink.enable();
              }, 500);
            }
          },
        },
      },
      on: {
        onChange(newVal, oldVal) {
          const optionsTypeEditor = $$('optionsCustomColumnEditor');
          const customColumnResourcesMultiSelect = $$('customColumnResourcesMultiSelect');
          const aggregationSelect = $$(innerObject.views.aggregationSelect.id);
          const optionsTypeEditorList = $$(innerObject.views.optionsTypeEditorList.id);
          const resourcesMultiSelectBox = $$(innerObject.views.resourcesMultiSelect.id);
          const customColumnResourcesInfo = $$('customColumnResourcesInfo');

          optionsTypeEditorList.clearAll();
          optionsTypeEditor.hide();
          aggregationSelect.hide();
          resourcesMultiSelectBox.hide();
          customColumnResourcesInfo.hide();
          $$('customColumnPopupSaveButton').enable();
          innerObject.settings.clickedEditButtonId = null;
          tagColorsUsed = [];
          colorsUsed = [];

          switch (+newVal) {
          case constants.CUSTOM_COLUMNS_TYPES.select.id:
          case constants.CUSTOM_COLUMNS_TYPES.multiselect.id: {
            optionsTypeEditorList.add({
              value: '',
              is_default: 0,
            });
            optionsTypeEditor.show();
            break;
          }
          case constants.CUSTOM_COLUMNS_TYPES.color.id: {
            colorsUsed.push(defaultColor)
            optionsTypeEditorList.parse([{
              value: defaultColor,
              is_default: 0,
            }]);
            optionsTypeEditor.show();
            break;
          }
          case constants.CUSTOM_COLUMNS_TYPES.tags.id: {
            tagColorsUsed.push(defaultColor);
            optionsTypeEditorList.add({
              value: '',
              color: defaultColor,
              is_default: 0,
            });
            optionsTypeEditor.show();
            break;
          }
          case constants.CUSTOM_COLUMNS_TYPES.number.id: {
            aggregationSelect.show();
            break;
          }
          case constants.CUSTOM_COLUMNS_TYPES.resources.id: {
            if (!innerObject.settings.isGlobal) {
              const list = customColumnResourcesMultiSelect.getList();
              const resources = innerObject.helpers.getOptionsForResourcesMultiselect();

              customColumnResourcesMultiSelect.setValue('');
              list.clearAll();
              list.parse(resources);
            } else {
              customColumnResourcesMultiSelect.disable();
              customColumnResourcesInfo.show();
            }

            resourcesMultiSelectBox.show();
            break;
          }
          }
        },
      },
    },
    aggregationSelect: {
      id: 'customColumnAggregationSelectContainer',
      rows: [
        {
          height: 72,
          view: 'richselectWithoutPoint',
          id: 'customColumnAggregationSelect',
          suggestCss: 'custom_column_select_suggest_popup',
          value: 1,
          label: __('custom_column_aggregation_label'),
          labelPosition: 'top',
          inputHeight: 36,
          labelHeight: 32,
          suggest: {
            body: {
              data: optionsAggregationSelect,
            },
            on: {
              onShow() {
                const navlink = $$(innerObject.views.settingsLink.id);

                if (navlink) {
                  navlink.disable();
                }
              },
              onHide() {
                const navlink = $$(innerObject.views.settingsLink.id);

                if (navlink) {
                  _.delay(() => {
                    navlink.enable();
                  }, 500);
                }
              },
            },
          },
        },
        { height: 10 },
      ],
    },
    resourcesMultiSelect: {
      id: 'customColumnResourcesMultiSelectBox',
      rows: [
        {
          cols: [
            {
              view: 'label',
              label: __('resources_column_aggregation_label'),
              css: 'resources_multi_select_label',
            },
            {
              view: 'template',
              id: 'customColumnResourcesInfo',
              borderless: true,
              hidden: true,
              width: 30,
              template() {
                return `<span class='columns_user_info'>
                <span class='tooltip-gantt' data-html='true'>
                  <div class='tooltip-gantt-html'>
                    <div class='tooltip-gantt-html-description global_columns_tooltip ql-snow ql-editor'>
                      <div class='text_message_box'>${__('custom_column_resources_info')}</div>
                    </div>
                  </div>
                </span>
              </span>`;
              },
            },
          ],
        },
        {
          id: 'customColumnResourcesMultiSelect',
          view: 'multicombosearch',
          placeholder: __('resources_column_aggregation_placeholder'),
          width: 276,
          suggest: {
            view: 'checksuggestseparatorsoverlay',
            selectAll: true,
            body: {
              template(item) {
                if (innerObject.helpers.isDividerItem(item.id)) {
                  return `
                    <div class="filter-group-item">
                      <span class="group-header-caption">
                          ${item.value}
                      </span>
                      <span class="group-header-underline"></span>
                    </div>`;
                }

                const picture = item.picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${item.picture})"></span>` : '';

                return `<span class="filter-option-item custom-resource">${picture}<span class="custom-resource-text">${item.value}</span></span>`;
              },
            },
          },
          on: {
            onChange(values) {
              const selectedIds = (_.uniq(values) || []).filter(itemId => !innerObject.helpers.isDividerItem(itemId));

              this.setValue(selectedIds);
            },
          },
        },
      ],
    },
    selectTypeLabel: {
      view: 'label',
      height: 36, // 19
      label: __('custom_column_select_list_label'),
    },
    selectTypeAddButton: {
      view: 'template',
      height: 46,
      css: 'add_button',
      template(obj) {
        return `<span class="button">+ ${__('add_button_custom_column_select')}</span>`;
      },
      onClick: {
        button(e) {
          const id = innerObject.settings.clickedEditButtonId;
          const selectedTypeId = innerObject.helpers.getCurrentCollumnType();

          if (id && constants.CUSTOM_COLUMNS_TYPES.color.id !== selectedTypeId) {
            const list = $$(innerObject.views.selectCustomColumnEditorList);

            innerObject.helpers.saveCustomColumnItemText(list, id);
          }
          innerObject.handlers.addSelectItem();
        },
      },
    },
    optionsTypeEditorList: {
      view: 'dataview',
      id: 'selectCustomColumnEditorList',
      css: 'custom_columns_select_list',
      borderless: true,
      select: false,
      drag: true,
      type: {
        height: 36,
        width: 276,
      },
      template(obj) {
        let text = _.escape(obj.value);
        const selectedTypeId = innerObject.helpers.getCurrentCollumnType();
        const isLocked = $$(innerObject.views.optionsTypeEditorList.id).serialize().length === 1;
        const isColor = selectedTypeId === constants.CUSTOM_COLUMNS_TYPES.color.id;
        const isTags = selectedTypeId === constants.CUSTOM_COLUMNS_TYPES.tags.id;
        const isEdit = obj.id === innerObject.settings.clickedEditButtonId;

        if (isTags && isEdit) {
          const list = $$(innerObject.views.selectCustomColumnEditorList);
          const inputText = innerObject.helpers.getInputNodeValue(list).trim();

          text = inputText || text;
        }

        const editButton = `<span class="icon icon_edit">${icon_edit}</span>`;
        const deleteButton = `<span class='icon icon_delete'>${icon_delete}</span>`;
        const saveButton = `<span class="icon icon_save save_text">${icon_save}</span>`;
        const cancelButton = obj.value ? `<span class="icon icon_cancel cancel_text">${icon_cancel}</span>` : '';
        const tagColorElem = `<span class='color_tag edit_tag_color' style='background:${obj.color};'></span>`;
        const colorElem = `<div class='color_container edit_color'><span class='color' style='background:${obj.value}'></span>${editButton}</div>`;

        const buttonsBox = `<div class='buttons_container'>${isEdit ? saveButton + cancelButton : editButton}</div>`;
        const textField = !isColor ? `<div class='custom_column_item_text edit_text'><span class='text' title='${text}'>${text}</span>${buttonsBox}</div>` : '';
        const editTextField = `<div class='custom_column_item_text'>
          <div class='edit_text_input'><input id='editTextInput' autocomplete="off" maxlength="250" type='text' placeholder='${__('custom_column_item_placeholder')}' value='${text}' /></div>
          ${buttonsBox}
        </div>`;

        return `<div class='custom_columns_select_list_item'>
          ${isTags ? tagColorElem : ''}
          ${isEdit ? editTextField : textField}
          ${isColor ? colorElem : ''}
          ${!isLocked && !isEdit ? deleteButton : ''}
        </div>`;
      },
      onClick: {
        edit_text(e, id) {
          const currentItemId = innerObject.settings.clickedEditButtonId;

          if (currentItemId) {
            innerObject.helpers.saveCustomColumnItemText(this, currentItemId);
          }
          innerObject.helpers.resetInputNode(this);
          innerObject.settings.clickedEditButtonId = parseInt(id);
          this.render();
        },
        edit_color(e, id) {
          const value = this.getItem(id).value;

          innerObject.helpers.setColorPanelValue(id, e.target, value);
        },
        edit_tag_color(e, id) {
          const value = this.getItem(id).color;

          innerObject.helpers.setColorPanelValue(id, e.target, value);
        },
        save_text(e, id) {
          innerObject.helpers.saveCustomColumnItemText(this, id);
          this.render();
        },
        cancel_text(e, id) {
          innerObject.helpers.resetCustomColumnItemText(this, id);
        },
      },
      on: {
        onBeforeRender(data) {
          let yCount = data.serialize().length;
          const visibleCount = 5;
          const dataView = this;

          if (yCount > visibleCount) {
            yCount = visibleCount;
          }

          this.define('yCount', yCount);
          this.resize();

          _.each(dataView.serialize(), obj => {
            if (_.isUndefined(obj.sort_order)) {
              obj.sort_order = dataView.getIndexById(obj.id);
              dataView.updateItem(obj.id, obj);
            }
          });
        },
        onAfterRender() {
          const currentItemId = innerObject.settings.clickedEditButtonId;

          if (currentItemId) {
            const elementNode = this.getItemNode(currentItemId);

            elementNode && elementNode.parentNode.classList.add('editMode');
          }

          innerObject.helpers.setFocusForElemInput(this);
        },
        onEnter() {
          if (innerObject.settings.clickedEditButtonId) {
            const id = innerObject.settings.clickedEditButtonId;

            innerObject.helpers.saveCustomColumnItemText(this, id);
            this.render();
          }
        },
        onItemClick(id, e, node) {
          const item = this.getItem(id);
          const dataView = this;

          if (e.target.closest('.icon_delete')) {
            webix.confirm({
              text: __('confirm_delete_custom_column_select_item'),
              ok: __('common_ok'),
              cancel: __('common_cancel'),
              type: 'confirm-error',
            }).then(res => {
              if (res) {
                dataView.remove(item.id);
                dataView.refresh();
              }
            });
          }
        },
        onBeforeDrag() {
          return !innerObject.settings.clickedEditButtonId;
        },
        onAfterSelect(id) {
          const dataView = this;
          const item = dataView.getItem(id);

          _.each(dataView.serialize(), obj => {
            if (obj.is_default) {
              obj.is_default = 0;
              dataView.updateItem(obj.id, obj);
            }
          });

          item.is_default = 1;
          dataView.updateItem(item.id, item);
        },
        onAfterDrop(itemId, index, e) {
          const dataView = this;

          _.each(dataView.serialize(), obj => {
            obj.sort_order = dataView.getIndexById(obj.id);
            dataView.updateItem(obj.id, obj);
          });
        },
        onAfterAdd(id, index) {
          const columnSelectedType = innerObject.helpers.getCurrentCollumnType();

          if (columnSelectedType !== constants.CUSTOM_COLUMNS_TYPES.color.id) {
            innerObject.settings.clickedEditButtonId = parseInt(id);
          }
          this.refresh();
          this.showItem(id);
        },
      },
    },
    projectsSelectLabel: {
      view: 'label',
      height: 36,
      label: __('projects_columns_label'),
      css: 'projects_select_label',
    },
    projectsMultiSelect: {
      id: 'customColumnProjectsSelect',
      view: 'multicombosearch',
      width: 276,
      placeholder: __('projects_columns_placeholder'),
      suggest: {
        view: 'checksuggestoverlay',
        body: {
          data: [],
          template(item) {
            return `<span class="filter-option-item">${item.value}</span>`;
          },
        },
      },
      on: {
        onChange(values) {
          this.setValue(_.uniq(values));
        },
      },
    },
    settingsLink: {
      view: 'template',
      id: 'customColumnSettingslink',
      css: 'custom_column_settings_link',
      height: 24,
      borderless: true,
      autowidth: true,
      template() {
        return `<span class="custom_column_settings_link_text">${__('custom_column_nav_link')}</span>`;
      },
      onClick: {
        custom_column_settings_link_text() {
          $$(innerObject.views.popupId).hide();
          routerHelper.changeRoute('/settings/columns');
        },
      },
    },
    saveButton: {
      view: 'button',
      id: 'customColumnPopupSaveButton',
      value: __('common_save'),
      minWidth: 100,
      autowidth: true,
      css: 'ok_button',
      click() {
        const currentItemId = innerObject.settings.clickedEditButtonId;

        if (currentItemId) {
          const list = $$(innerObject.views.selectCustomColumnEditorList);

          innerObject.helpers.saveCustomColumnItemText(list, currentItemId);
        }

        innerObject.handlers.saveCustomColumn();
      },
    },
    selectedGlobalColumn: {
      view: 'template',
      borderless: true,
      width: 276,
      autoheight: true,
      columnData: {},
      template(obj, elem) {
        const column = elem.config.columnData;
        const content = innerObject.helpers.getTemplateForColumnDataContainer(column);

        return `<div class='global_column_selected_item'>
            <div class="global_column_deselect">${icon_close}</div>
            ${content}
          </div>
          <div class='spacer_block'></div>`;
      },
      onClick: {
        global_column_deselect(event) {
          const columnNameField = $$(innerObject.views.nameField.id);

          columnNameField.setValue('');
          innerObject.helpers.setMatchesButtonText('');
          innerObject.helpers.setVisibilityAfterColumnDeselection();
          columnNameField.focus();

          return webix.html.stopEvent(event);
        },
        global_column_selected_item(event) {
          const matchesPopup = $$(innerObject.views.customColumsMatchesPopup);
          const columnNameField = $$(innerObject.views.nameField.id);

          innerObject.helpers.setVisibilityAfterColumnDeselection();
          matchesPopup.show(columnNameField.getNode());
          const inutNode = columnNameField.getInputNode();

          if (inutNode) {
            inutNode.selectionStart = inutNode.selectionEnd = inutNode.value.length;
          }
        },
      },
    },
    deleteGlobalButton: {
      view: 'button',
      id: 'customColumnGlobalDeleteButton',
      label: __('common_delete'),
      width: 100,
      css: 'delete_button',
      click() {
        const currentColumn = innerObject.settings.currentColumn;

        app.trigger('openColumnGlobalDeletePopup', currentColumn.id);
      },
    },
    deleteButton: {
      view: 'button',
      id: 'customColumnDeleteButton',
      label: __('common_delete'),
      width: 100,
      css: 'delete_button',
      click() {
        webix.confirm({
          text: __('confirm_delete_custom_column'),
          ok: __('common_ok'),
          cancel: __('common_cancel'),
          type: 'confirm-error',
          id: 'confirm_delete_custom_column'
        }).then(res => {
          if (res) {
            innerObject.handlers.removeCustomColumn();
          }
        });
      },
    },
  },
  getColumnsData() {
    const viewData = ganttViewModel.getActiveViewData();

    if (!viewData) {
      return [];
    }

    if (routerHelper.isSingleProjectRoute() && viewData.gantt_id) {
      const columnIds = globalStore.getters['columns/getColumnIdsByProjectId'](viewData.gantt_id);

      return columnIds.reduce((result, columnId) => {
        const column = globalStore.getters['columns/getCustomColumnById'](columnId);

        if (column) {
          result.push(column);
        }

        return result;
      }, []);
    }

    if (routerHelper.isMultiProjectsRoute() || routerHelper.isCommonRoute()) {
      const ganttIDs = routerHelper.isCommonRoute()
        ? projectsModel.getAllProjects().map(p => p.gantt_id)
        : (viewData.ganttIDs || []);

      return _.reduce(ganttIDs, (columns, ganttID) => {
        if (projectsModel.isArchived(+ganttID)) {
          return columns;
        }

        const columnIds = globalStore.getters['columns/getColumnIdsByProjectId'](ganttID);

        columnIds.forEach(columnId => {
          const column = globalStore.getters['columns/getCustomColumnById'](columnId);

          if (column) {
            const addedColumn = columns.find(item => item.id === column.id);

            if (!addedColumn) {
              // column.ganttIds = [ganttID];
              columns.push({ ...column, ganttIds: [ganttID] });
            } else {
              addedColumn.ganttIds.push(ganttID);
            }
          }
        });

        return columns;
      }, []);
    }
  },
  initColumns() {
    const isFeatureAvailable = pricingHelper.checkPricingAccess(customColumnsFeatureKey);

    if (routerHelper.isListViewRoute() || routerHelper.isWorkloadViewRoute() || !isFeatureAvailable) {
      return;
    }

    const columnsData = innerObject.getColumnsData();
    const config = ganttViewModel.getProjectConfig();

    _.each(columnsData, (column, i) => {
      const initCustomSizes = config.initGridColumns || { sizes: {} };

      if (!initCustomSizes.sizes) {
        initCustomSizes.sizes = {};
      }

      const taskTypesForColumn = ['task', 'milestone'];

      if (column.type !== constants.CUSTOM_COLUMNS_TYPES.number.id) {
        taskTypesForColumn.push('project');
      }

      let minWidth = initCustomSizes.sizes[column.name] ? initCustomSizes.sizes[column.name].min_width : 50;
      let width = initCustomSizes.sizes[column.name] ? initCustomSizes.sizes[column.name].width : 100;

      if (column.name === 'masschange') {
        minWidth = 1;
        width = 1;
      }

      gantt.config.columns.push({
        align: alignForColumns[column.type],
        label: column.name,
        name: `${column.id}`, // must be string (for columns reorder)
        ganttIds: column.ganttIds || null,
        min_width: minWidth,
        width,
        resize: true,
        hide: true,
        isShowCheckbox: true,
        order: 100 + i,
        isUserCustomColumn: true,
        columnType: column.type,
        control_type: editorsForColumns[column.type],
        control_options: innerObject.helpers.getOptionsForSelectInlineEditor(column),
        control_id: column.id,
        task_types: taskTypesForColumn,
        data_type: dataTypesForColumns[column.type],
        template(task) {
          const res = innerObject.helpers.getValueForUserColumn(task, column);

          return res.text;
        },
        inline_validate: innerObject.helpers.inlineValidate,
      });
    });

    gantt.config.columns = _.orderBy(gantt.config.columns, 'order');
  },
  reInitColumns() {
    gantt.config.columns = _.filter(gantt.config.columns, column => !column.isUserCustomColumn);
    innerObject.initColumns();
    if($$(innerObject.views.popupId)) {
      innerObject.handlers.closeCustomColumnPopup();
      if(document.querySelector(".webix_confirm-error") && document.querySelector(".webix_confirm-error .webix_popup_button:last-child")) document.querySelector(".webix_confirm-error .webix_popup_button:last-child").click();
    }
  },
  filterPeopleOptions(columns, ganttId) {
    return innerObject.helpers.filterResourceOptions(columns, ganttId);
  },

  // ------------------------------- handlers --------------------------------- //
  handlers: {
    openCustomColumnPopup(column, isGlobal) {
      innerObject.helpers.generateGlobalColumnPopupContent(column, isGlobal, gantt.config.gantt_id);

      const nameField = $$(innerObject.views.nameField.id);
      const typeSelect = $$(innerObject.views.typeSelect.id);
      const aggregationSelect = $$(innerObject.views.aggregationSelect.id);
      const aggregationSelectElem = $$('customColumnAggregationSelect');
      const optionsTypeEditor = $$('optionsCustomColumnEditor');
      const resourcesMultiSelect = $$('customColumnResourcesMultiSelect');
      const customColumnResourcesInfo = $$('customColumnResourcesInfo');
      const optionsTypeEditorList = $$(innerObject.views.optionsTypeEditorList.id);
      const resourcesMultiSelectBox = $$(innerObject.views.resourcesMultiSelect.id);
      const projectsMultiSelect = $$(innerObject.views.projectsMultiSelect.id);

      aggregationSelect.hide();
      resourcesMultiSelectBox.hide();
      optionsTypeEditor.hide();

      nameField.blockEvent();
      nameField.setValue();

      typeSelect.blockEvent();
      typeSelect.setValue(innerObject.settings.defaultColumnType);
      typeSelect.enable();

      innerObject.helpers.defineColumnMessage(column, 'warning');

      innerObject.settings.isGlobal = !!isGlobal;

      if (column) {
        nameField.setValue(column.name.trim());
        typeSelect.setValue(column.type);
        typeSelect.disable();

        const listTypeColumns = [
          constants.CUSTOM_COLUMNS_TYPES.select.id,
          constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
          constants.CUSTOM_COLUMNS_TYPES.color.id,
          constants.CUSTOM_COLUMNS_TYPES.tags.id,
        ];

        innerObject.settings.currentColumn = _.cloneDeep(column);

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
          aggregationSelectElem.setValue(column.sub_type);
          aggregationSelect.show();
        }

        if (listTypeColumns.includes(column.type)) {
          optionsTypeEditorList.clearAll();
          tagColorsUsed = [];
          colorsUsed = [];

          column.options.sort((a, b) => {
            if (a.sort_order > b.sort_order) return 1;
            if (a.sort_order < b.sort_order) return -1;

            return 0;
          });
          const columnOptions = _.cloneDeep(column.options)
          optionsTypeEditorList.parse(columnOptions);
          tagColorsUsed = columnOptions.map(option => option?.color);
          colorsUsed = columnOptions.map(option => option?.value)
          optionsTypeEditor.show();
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          if (!innerObject.settings.isGlobal) {
            const values = column.options.filter(option => option.resource_id).map(option => option.resource_id);
            const resources = innerObject.helpers.getOptionsForResourcesMultiselect();

            resourcesMultiSelect.getList().clearAll();
            resourcesMultiSelect.getList().parse(resources);

            resourcesMultiSelect.setValue(values);
          } else {
            resourcesMultiSelect.disable();
            customColumnResourcesInfo.show();
          }

          resourcesMultiSelectBox.show();
        }
      }

      if (isGlobal) {
        const projects = innerObject.helpers.getOptionsForProjectsMultiSelect();

        projectsMultiSelect.getList().clearAll();
        projectsMultiSelect.getList().parse(projects);

        if (column) {
          const columnInProjects = innerObject.helpers.getProjectsForColumn(column.id);

          innerObject.settings.currentProjets = columnInProjects;
          projectsMultiSelect.setValue(columnInProjects);
        }
      }

      nameField.unblockEvent();
      typeSelect.unblockEvent();
    },
    closeCustomColumnPopup() {
      $$(innerObject.views.popupId).hide();
    },
    addSelectItem() {
      const selectList = $$(innerObject.views.optionsTypeEditorList.id);
      const columnSelectedType = innerObject.helpers.getCurrentCollumnType();

      switch (columnSelectedType) {
      case constants.CUSTOM_COLUMNS_TYPES.select.id:
      case constants.CUSTOM_COLUMNS_TYPES.multiselect.id: {
        selectList.add({
          value: '',
          is_default: 0,
        });
        break;
      }
      case constants.CUSTOM_COLUMNS_TYPES.color.id: {
        const newColor = innerObject.helpers.getRandomColor(colorsUsed);
        colorsUsed.push(newColor)
        selectList.add({
          value: newColor,
          is_default: 0,
        });
        break;
      }
      case constants.CUSTOM_COLUMNS_TYPES.tags.id: {
        const newTagColor = innerObject.helpers.getRandomColor(tagColorsUsed);
        tagColorsUsed.push(newTagColor);
        selectList.add({
          value: '',
          color: newTagColor,
          is_default: 0,
        });
        break;
      }
      }
    },
    saveCustomColumn() {
      const resourcesMultiSelect = $$('customColumnResourcesMultiSelect');
      const name = innerObject.helpers.getCollumnDefaultName();
      const type = innerObject.helpers.getCurrentCollumnType();
      const aggregationType = $$('customColumnAggregationSelect').getValue();
      const projectsMultiSelect = $$(innerObject.views.projectsMultiSelect.id);
      const selectListData = $$(innerObject.views.optionsTypeEditorList.id).serialize();
      const isResourcesType = type === constants.CUSTOM_COLUMNS_TYPES.resources.id;
      const currentColumn = innerObject.settings.currentColumn;
      const columnToProjectIds = innerObject.helpers.getColumnProjects();
      const selectedGlobalColumn = $$('columnDataContainer');

      if (selectedGlobalColumn.isVisible()) {
        const columnData = innerObject.views.selectedGlobalColumn.columnData;

        const column = {
          id: columnData.id,
          ganttIds: columnToProjectIds.map(ganttId => ({ id: ganttId, status: 'add' })),
        };

        if (type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
          column.custom_column_sub_types_id = columnData.sub_type;
        }

        globalStore.dispatch('columns/updateColumn', { column: column, isShow: true })
        $$(innerObject.views.popupId).hide();

        return;
      }

      const newColumn = {
        name,
        type,
        team_id: user.team.id,
        ganttIds: columnToProjectIds,
      };

      const listTypeColumns = [
        constants.CUSTOM_COLUMNS_TYPES.select.id,
        constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
        constants.CUSTOM_COLUMNS_TYPES.color.id,
        constants.CUSTOM_COLUMNS_TYPES.tags.id,
      ];

      if (listTypeColumns.includes(type)) {
        newColumn.options = selectListData;
      }

      if (isResourcesType) {
        const resourcesValue = resourcesMultiSelect.getValue();
        const resourcesListIds = resourcesValue ? resourcesValue.split(resourcesMultiSelect.config.separator) : [];

        newColumn.options = resourcesListIds.filter(id => !innerObject.helpers.isDividerItem(id)).map((id, index) => ({
          resource_id: +id,
          is_default: 0,
          sort_order: index,
        }));
      }

      if (type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
        newColumn.custom_column_sub_types_id = +aggregationType || null;
      }

      if (currentColumn) {
        let changedOptions = null;
        let changedProjects = null;

        newColumn.id = currentColumn.id;

        if (newColumn.options) {
          const propName = isResourcesType ? 'resource_id' : 'id';

          if (isResourcesType && innerObject.settings.isGlobal) {
            changedOptions = null;
          } else {
            changedOptions = innerObject.helpers.prepareOptionsForUpdate(currentColumn, newColumn, propName);
          }
        }

        if (projectsMultiSelect) {
          const prevProjectIds = innerObject.settings.currentProjets;
          const newProjectIds = [];

          prevProjectIds.forEach(ganttId => {
            if (!columnToProjectIds.includes(ganttId)) {
              newProjectIds.push({ id: ganttId, status: 'delete' });
            }
          });

          columnToProjectIds.forEach(ganttId => {
            if (!prevProjectIds.includes(ganttId)) {
              newProjectIds.push({ id: ganttId, status: 'add' });
            }
          });

          if (newProjectIds.length) {
            changedProjects = newProjectIds;
          }
        }

        if (innerObject.helpers.checkForUpdate(currentColumn, newColumn) || changedOptions || changedProjects) {
          newColumn.options = changedOptions;
          newColumn.ganttIds = changedProjects;

          if (innerObject.helpers.checkForUpdate(currentColumn, newColumn) || changedOptions) {
            newColumn.last_update = new Date();
            newColumn.last_update_by = user.id;
            newColumn.isChanged = true;
          }

          if (newColumn.name === currentColumn.name) {
            delete newColumn.name;
          }

          webix.confirm({
            text: __('custom_column_changes_confirm_message'),
            ok: __('common_ok'),
            cancel: __('common_cancel'),
            type: 'confirm-error',
          }).then(res => {
            if (res) {
              globalStore.dispatch('columns/updateColumn', { column: newColumn, isShow: false })
              $$(innerObject.views.popupId).hide();
            }
          });

          return;

          // if (!innerObject.settings.isGlobal) {
          //   webix.confirm({
          //     text: __('custom_column_changes_confirm_message'),
          //     ok: __('common_ok'),
          //     cancel: __('common_cancel'),
          //     type: 'confirm-error',
          //   }).then(res => {
          //     if (res) {
          //       userCustomColumnsModel.updateColumn(newColumn);
          //       $$(innerObject.views.popupId).hide();
          //     }
          //   });

          //   return;
          // }

          // userCustomColumnsModel.updateColumn(newColumn);
        }

        $$(innerObject.views.popupId).hide();

        return;
      }

      globalStore.dispatch('columns/createColumn', {
        column: newColumn,
        isGlobal: innerObject.settings.isGlobal,
      });

      $$(innerObject.views.popupId).hide();

      _.each(constants.CUSTOM_COLUMNS_TYPES, v => {
        if (v.id === type) {
          analyticsCustomColumn(+v.id);
        }
      });

      function analyticsCustomColumn(id) {
        let type = ''; let
          from = '';
        const path = routerHelper.getCurrentRoute().path;

        from = path.startsWith('/settings') ? 'settings' : 'project';

        switch (id) {
        case 1: type = 'list'; break;
        case 2: type = 'text'; break;
        case 3: type = 'number'; break;
        case 4: type = 'date'; break;
        case 5: type = 'checkbox'; break;
        case 6: type = 'color'; break;
        case 7: type = 'multiselect'; break;
        case 8: type = 'people'; break;
        case 9: type = 'lables'; break;
        default: type = id;
        }

        userExtAnalytics.log('gantt_custom_column_add', { type, from, 'projects number': columnToProjectIds.length });
      }
    },
    removeCustomColumn() {
      const column = innerObject.settings.currentColumn;

      const columnToDelete = {
        id: column.id,
        type: column.type,
        team_id: column.team_id,
        options: null,
        ganttIds: [
          { id: gantt.config.gantt_id, status: 'delete' },
        ],
      };

      globalStore.dispatch('columns/updateColumn', { column: columnToDelete, isShow: false });
      $$(innerObject.views.popupId).hide();
    },
    changeColor(color) {
      const colorPopup = $$(innerObject.views.colorPopupId);
      const selectedTypeId = innerObject.helpers.getCurrentCollumnType();
      const isColor = selectedTypeId === constants.CUSTOM_COLUMNS_TYPES.color.id;
      const list = $$(innerObject.views.optionsTypeEditorList.id);
      const item = list.getItem(colorPopup.config.parentId);

      if (isColor) {
        colorsUsed.push(color);
        item.value = color;
      } else {
        tagColorsUsed.push(color);
        item.color = color;
      }

      list.updateItem(item.id, item);
      colorPopup.hide();
    },
    changeDateFormat() {
      innerObject.settings.dateFormat = gantt.date.date_to_str(dateHelper.getDateFormat());
    },
    showCustomColumnPreviewPopup: _.debounce((pos, taskId, columnId, taskSettings = false) => {
      if (innerObject.settings.doNotShowPreview && !taskSettings) {
        return;
      }

      const previewPopup = $$(innerObject.views.customColumnPreviewPopup);
      const textPopup = $$(innerObject.views.customColumnPreviewPopupText);
      const list = $$(innerObject.views.customColumnPreviewList);
      const text = $$(innerObject.views.customColumnPreviewText);
      const task = globalStore.getters['tasksModel/getTask'](taskId);
      const column = globalStore.getters['columns/getCustomColumnById'](columnId);
      const resourcesData = gantt.getUserCustomValue(task, columnId);

      if (!resourcesData || !resourcesData.value) {
        return;
      }

      list.clearAll();
      text.clearAll();

      if (resourcesData.preview?.length) {
        if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          resourcesData.preview = innerObject.helpers.createSortedResourcesSet(resourcesData.preview);
        }

        list.parse(resourcesData.preview);

        previewPopup.show();
        previewPopup.hide();
        pos.x -= 5;
        previewPopup.show(pos, { pos: 'top' });
      } else {
        text.parse(resourcesData);

        textPopup.show();
        textPopup.hide();
        pos.x -= 5;
        textPopup.show(pos, { pos: 'top' });
      }
    }, 1000),
  },

  // ------------------------------- helpers --------------------------------- //
  helpers: {
    prepareColumnValue(result, task, column, taskCustomValue, isBaseline) {
      if (column.options && column.options.length) {
        const taskCustomValueIds = (taskCustomValue || []).map(value => value.task_value);
        const defaultOption = _.find(column.options, opt => opt.is_default);
        const findOption = _.filter(column.options, opt => taskCustomValueIds.includes(opt.id));

        if (findOption.length) {
          result.text = findOption.map(option => option.value).join('');
          result.value = findOption.map(option => option.id).join(',');
        } else if (defaultOption) {
          result.text = defaultOption.value;
          result.value = `${defaultOption.id}`;
        }

        result.toExcel = result.text;

        result.text = _.escape(result.text);

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.color.id) {
          result.text = innerObject.helpers.getTemplateForColorSelect(result.text);
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.select.id) {
          result.text = innerObject.helpers.getTemplateForListSelect(result.text);
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.multiselect.id) {
          const multiSelectText = findOption.map(option => option.value).join(', ');

          result.text = innerObject.helpers.getTemplateForListMultiSelect(multiSelectText);
          result.toExcel = multiSelectText;
          result.preview = findOption;
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.tags.id) {
          result.text = innerObject.helpers.getTemplateForTags(findOption);
          result.toExcel = findOption;
          result.preview = findOption;
        }
        if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          const resources = innerObject.helpers.prepareOptionsForResources(findOption, task.gantt_id);

          result.text = innerObject.helpers.getTemplateForListResources(resources);
          result.toExcel = resources.map(option => option.value).join(', ');
          result.preview = resources;
        }
      } else {
        let value = taskCustomValue.length ? taskCustomValue.map(value => value.task_value).join('') : defaultValuesByColumnType[column.type];

        if (task.type === gantt.config.types.project && column.type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
          if (column.sub_type === constants.CUSTOM_COLUMNS_SUBTYPES.sum.id) {
            value = innerObject.helpers.getCalcForParent(task, column, isBaseline, true, null);
          }

          if (column.sub_type === constants.CUSTOM_COLUMNS_SUBTYPES.average.id) {
            value = innerObject.helpers.getCalcForParent(task, column, isBaseline, false, null);
          }
        }

        result.value = value;
        result.text = value;
        result.toExcel = value;

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.checkbox.id) {
          result.text = innerObject.helpers.getTemplateForCheckbox(result.text);
          result.value = +value;
          result.toExcel = result.value ? __('common_on') : __('common_off');
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.date.id) {
          const date = value && value !== '0' ? moment(value).toDate() : defaultValuesByColumnType[column.type];
          const dateString = date ? gantt.date.date_to_str(dateHelper.getDateFormat())(date) : defaultValuesByColumnType[column.type];

          result.value = date;
          result.text = dateString;
          result.toExcel = date;
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
          result.toExcel = (result.text || 0) * 1;
          result.text = numbersHelper.getFormatted(+result.text);
        }

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.text.id) {
          result.text = innerObject.helpers.getTemplateForText(result.text);
        }
      }

      return result;
    },
    getValueForUserColumn(task, column) {
      const taskTypesForColumn = ['project', 'task', 'milestone'];

      const result = {
        text: '',
        value: null,
        toExcel: '',
      };

      if (!column) {
        return result;
      }

      const totalEstimateTaskLevel = gantt.config.multiview ? 2 : 1;

      if (!_.includes(taskTypesForColumn, task.type) || (task.$level < totalEstimateTaskLevel && column.type !== constants.CUSTOM_COLUMNS_TYPES.number.id)) { // task.$level === 0
        return result;
      }

      const taskCustomValue = globalStore.getters['columns/getValueByParams']( {
        projectId: task.gantt_id,
        taskId: task.id,
        columnId: column.id
      });

      return innerObject.helpers.prepareColumnValue(result, task, column, taskCustomValue);
    },
    setColorPanelValue(id, target, value) {
      const popup = $$(innerObject.views.colorPopupId);
      const board = $$(innerObject.views.colorBoard);

      board.setValue(value);
      board.refresh();
      popup.define('parentId', id);
      popup.show(target.parentNode.parentNode, { pos: 'bottom', x: -1 });
    },
    getCalcForParent(task, column, isBaseline, sumMode, valuesMap) {
      const children = gantt.getChildren(task.id);
      let parentSum = 0;
      let count = 0;
      let parentAverage = 0;
      let result = 0;
      let taskCustomValuesMap = valuesMap;

      if (!valuesMap) {
        if (isBaseline) {
          taskCustomValuesMap = baselineModel.getUserCustomValuesMap(task.gantt_id);
        } else {
          taskCustomValuesMap = globalStore.getters['columns/getValuesMapByProjectId'](task.gantt_id);
        }
      }

      _.each(children, child => {
        const task = gantt.getTask(child);

        if (task.type === gantt.config.types.task || task.type === gantt.config.types.milestone) {
          let taskCustomValue = 0;

          // if (isBaseline) {
          //   taskCustomValue = baselineModel.getBaselineUserCustomValuesByParams(task.gantt_id, column.id, task.id);
          //   parentSum += (taskCustomValue.length ? taskCustomValue.reduce((sum, value) => sum + parseFloat(value.task_value, 10), 0) : 0);
          // } else {
          //   // taskCustomValue = globalStore.getters['columns/getValueByParams']({
          //   //   projectId: task.gantt_id,
          //   //   taskId: task.id,
          //   //   columnId: column.id
          //   // });

          // }

          taskCustomValue = taskCustomValuesMap[`${task.id}-${column.id}`] ? parseFloat(taskCustomValuesMap[`${task.id}-${column.id}`], 10) : 0;
          parentSum += taskCustomValue;

          // parentSum += (taskCustomValue.length ? taskCustomValue.reduce((sum, value) => sum + parseFloat(value.task_value, 10), 0) : 0);
          count++;
        }
        if (task.type === gantt.config.types.project) {
          parentSum += innerObject.helpers.getCalcForParent(task, column, isBaseline, sumMode, taskCustomValuesMap);
          count++;
        }
      });

      parentAverage = parentSum / count;

      result = sumMode ? parentSum : !isNaN(parentAverage) ? parentAverage : 0;

      return Math.round(result * 100) / 100;
    },
    checkForUpdate(oldData, newData, checkList) {
      let flag = false;
      var checkList = checkList || ['name', 'type', 'custom_column_sub_types_id'];

      _.each(checkList, prop => {
        if (!_.isUndefined(newData[prop]) && newData[prop] !== oldData[prop]) {
          flag = true;
        }
      });

      return flag;
    },
    prepareOptionsForUpdate(oldData, newData, propName) {
      const optionCheckList = ['value', 'is_default', 'sort_order', 'color', 'resource_id'];

      const diff = [];

      oldData.options.forEach(oldOption => {
        const addOption = newData.options.find(newOption => newOption[propName] === oldOption[propName]);

        if (!addOption) {
          oldOption.status = 'delete';
          diff.push(oldOption);
        } else if (propName !== 'resource_id' && innerObject.helpers.checkForUpdate(oldOption, addOption, optionCheckList)) {
          addOption.status = 'update';
          diff.push(addOption);
        }
      });

      newData.options.forEach(newOption => {
        if (!oldData.options.find(oldOption => oldOption[propName] === newOption[propName])) {
          diff.push({
            is_default: newOption.is_default,
            sort_order: newOption.sort_order,
            status: 'add',
            value: newOption.value,
            resource_id: newOption.resource_id,
            color: newOption.color,
          });
        }
      });

      return diff.length ? diff : null;
    },
    inlineValidate(value, columnType) {
      if (columnType === constants.CUSTOM_COLUMNS_TYPES.number.id) {
        if (!customHelper.testNumber(value)) {
          webix.message({ type: 'warning', text: __('grid_number_custom_column_not_valid') });

          return false;
        }

        return true;
      }

      return true;
    },
    getTemplateForColorSelect(color) {
      return `<span class='custom_column_option_color' style='background: ${color || 'transparent'}'></span>`;
    },
    getTemplateForListSelect(text) {
      return `<span class='custom_column_option_list'>${text}</span>`;
    },
    getTemplateForListMultiSelect(text) {
      return `<span class='custom_column_option_list'>${text}</span>`;
    },
    getTemplateForCheckbox(val) {
      return `<span class='custom_column_checkbox ${+val ? ' active' : ''}'></span>`;
    },
    getTemplateForText(val) {
      return `<div class='custom_column_text' title='${_.escape(val)}'>${_.escape(val)}</div>`;
    },
    getTemplateForTags(items) {
      let resultTag = '';
      const maxElemsToShow = 2;

      if (items.length && items.length <= maxElemsToShow) {
        resultTag = items.map(elem => {
          const tag = `<span class="tag-text">${elem.value}</span>`;

          return `<span class="tag-item" style="background: ${elem.color}">${tag}</span>`;
        }).join('');
      }

      if (items.length && items.length > maxElemsToShow) {
        const tags = items.slice(0, maxElemsToShow).map(elem => {
          const tag = `<span class="tag-text">${elem.value[0]}</span>`;

          return `<span class="tag-item" style="width: 18px; background: ${elem.color}">${tag}</span>`;
        }).join('');

        resultTag = `${tags}${items.length > maxElemsToShow ? `<span>+${items.length - maxElemsToShow}</span>` : ''}`;
      }

      return resultTag;
    },
    getTemplateForListResources(resources) {
      let resultTag = '';
      const maxElemsToShow = 2;

      if (resources.length && resources.length < maxElemsToShow) {
        const picture = `<span class="resources-icon" style="background-image: url(${resources[0].picture})"></span>`;
        const text = `<span class="resources-text">${resources[0].value}</span>`;

        resultTag = `<span class="resources-item">${picture}${text}</span>`;
      }

      if (resources.length && resources.length >= maxElemsToShow) {
        const maxElemsToShow = 2;
        const iconTags = resources.slice(0, maxElemsToShow).map(elem => {
          const picture = elem.picture ? `<span class="resources-icon" style="background-image: url(${elem.picture})"></span>` : '';

          return `<span class="resources-item">${picture}</span>`;
        }).join('');

        resultTag = `${iconTags}${resources.length > maxElemsToShow ? `+${resources.length - maxElemsToShow}` : ''}`;
      }

      return resultTag;
    },
    getOptionsForSelectInlineEditor(column) {
      if (!column || _.isEmpty(column)) {
        return [];
      }

      let config = {
        body: {
          view: 'list',
          css: 'custom_column_select tag_multiselect',
          borderless: true,
          autoheight: true,
          yCount: 5,
          select: true,
          template(item) {
            // escape value
            let value = item.value;
            if (value) {
              value = _.escape(value);
            }
            return `<span class="custom_column_option">${value}</span>`;
          },
        },
        data: column.options,
      };

      if (column.type === constants.CUSTOM_COLUMNS_TYPES.color.id) {
        config = {
          body: {
            view: 'list',
            css: 'custom_column_select_color',
            borderless: true,
            select: true,
            type: {
              height: 30,
            },
            template(item) {
              const innerHtml = '<span class="border"></span>';
              const colorValue = item.value === '#FFFFFF' ? '#50C7D6' : item.value;

              if (gantt.config.baseline === 1) {
                return `<span class="custom_column_option_color baseline_active" style="background: ${colorValue}">${innerHtml}</span>`;
              }

              return `<span class="custom_column_option_color" style="background: ${colorValue}">${innerHtml}</span>`;
            },
          },
          data: column.options,
        };
      }

      if (column.type === constants.CUSTOM_COLUMNS_TYPES.tags.id) {
        config = {
          body: {
            view: 'list',
            template(item) {
              return `<span class="filter-option-item option-item-tag" style="background: ${item.color}">${item.value}</span>`;
            },
          },
          data: column.options,
        };
      }

      if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
        config = {
          body: {
            template(item) {
              if (innerObject.helpers.isDividerItem(item.id)) {
                return `
                  <div class="filter-group-item">
                    <span class="group-header-caption">
                        ${item.value}
                    </span>
                    <span class="group-header-underline"></span>
                  </div>`;
              }

              const picture = item.picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${item.picture})"></span>` : '';

              return `<span class="filter-option-item custom-resource">${picture}<span class="custom-resource-text">${item.value}</span></span>`;
            },
          },
          data: innerObject.helpers.filterResourceOptions(column.options),
          multiviewData: innerObject.helpers.getResourceOptionsForSelectInlineEditor(column.options),

        };
      }

      return config;
    },
    getResourceOptionsForSelectInlineEditor(options) {
      const activeViewData = ganttViewModel.getActiveViewData();
      const viewGanttIDs = activeViewData ? (activeViewData.ganttIDs || [activeViewData.gantt_id]) : [];
      const resources = {};

      for (const ganttID of viewGanttIDs) {
        const resourcesByGanttID = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttID);

        resources[ganttID] = options.reduce((result, option) => {
          const resource = resourcesByGanttID.find(item => item.id === option.resource_id);

          if (resource) {
            result.push({
              ...resource,
              id: option.id,
              sort_order: option.sort_order,
            });
          }

          return result;
        }, []);
      }

      return resources;
    },
    getOptionsForResourcesMultiselect(ganttId) {
      const activeViewData = ganttViewModel.getActiveViewData();
      let viewGanttIDs = activeViewData ? (activeViewData.ganttIDs || [activeViewData.gantt_id]) : [];
      let resources = [];

      if (ganttId) {
        viewGanttIDs = [ganttId];
      }

      for (const ganttID of viewGanttIDs) {
        const resourcesByGanttID = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttID);

        resources = _.unionBy(resources, resourcesByGanttID, 'id');
      }

      return resources;
    },
    filterResourceOptions(options, ganttId) {
      const resources = innerObject.helpers.getOptionsForResourcesMultiselect(ganttId);

      return options.reduce((result, option) => {
        const resource = resources.find(item => item.id === option.resource_id);

        if (resource) {
          result.push({
            ...resource,
            id: option.id,
            sort_order: option.sort_order,
          });
        }

        return result;
      }, []);
    },
    getOptionsForProjectsMultiSelect() {
      return projectsModel.getAllProjects()
        .filter(p => rights.project.hasRight(+p.gantt_id, 'custom_fields_assing_to_project'))
        .map(p => ({
          id: p.gantt_id,
          value: p.name,
        }));
    },
    getProjectsForColumn(columnId) {
      const projects = projectsModel.getAllProjects();

      const projectIds = projects.reduce((result, project) => {
        const columnIds = globalStore.getters['columns/getColumnIdsByProjectId'](project.gantt_id);
        const column = columnIds.find(id => id === columnId);

        if (column) {
          result.push(project.gantt_id);
        }

        return result;
      }, []);

      return projectIds;
    },
    prepareOptionsForResources(options, ganttId) {
      const resources = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttId);

      return options.reduce((result, option) => {
        const resource = resources.find(item => item.id === option.resource_id);

        if (resource) {
          result.push({
            ...resource,
            id: option.id,
            sort_order: option.sort_order,
            picture: resource.picture || svg(icon_resource),
            value: resource.name,
          });
        }

        return result;
      }, []);
    },
    isDividerItem(id) {
      return _.isString(id) && id.includes('divider');
    },
    getInputNode(list) {
      return list.getNode().querySelector('#editTextInput');
    },
    getInputNodeValue(list) {
      const input = innerObject.helpers.getInputNode(list);

      return input ? input.value : '';
    },
    resetInputNode(list) {
      const input = innerObject.helpers.getInputNode(list);

      if (input) {
        input.value = '';
      }
    },
    getCurrentCollumnType() {
      return parseInt($$(innerObject.views.typeSelect.id).getValue(), 10);
    },
    getCollumnDefaultName() {
      const name = $$(innerObject.views.nameField.id).getValue();
      const activeType = innerObject.helpers.getCurrentCollumnType();
      const activeColumn = _.find(constants.CUSTOM_COLUMNS_TYPES, type => type.id === activeType);

      return name.trim() || __(activeColumn.locale);
    },
    getColumnProjects() {
      const projects = [];
      const projectsSelect = $$(innerObject.views.projectsMultiSelect.id);

      if (projectsSelect) {
        const values = projectsSelect.getValue();

        if (values) {
          projects.push(...values.split(',').map(value => +value));
        }
      } else {
        projects.push(gantt.config.gantt_id);
      }

      return projects;
    },
    createSortedResourcesSet(reducedResources) {
      const groupedObj = _.groupBy(reducedResources, item => (_.isNull(item.userId) ? 'material' : 'people'));

      const peopleHeader = groupedObj.people ? [
        {
          id: 'divider__people',
          label: __('resources_layout_header_title_1'),
        },
      ] : [];

      const materialHeader = groupedObj.material ? [
        {
          id: 'divider_material',
          label: __('resources_layout_header_title_2'),
        },
      ] : [];

      return [
        ...peopleHeader,
        ...groupedObj.people || [],
        ...materialHeader,
        ...groupedObj.material || [],
      ];
    },
    setFocusForElemInput(list) {
      const input = innerObject.helpers.getInputNode(list);

      if (input) {
        _.delay(() => {
          input.select();
          input.focus();
        });
      }
    },
    checkColumnsForUpdate(columnIds, optionId) {
      const col = columnIds.reduce((result, id) => {
        const column = globalStore.getters['columns/getCustomColumnById'](id);

        if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          result.push(...column.options);
        }

        return result;
      }, []);

      const editcols = col.filter(item => item.resource_id === +optionId);

      return editcols;
    },
    saveCustomColumnItemText(list, itemId) {
      const value = innerObject.helpers.getInputNodeValue(list).trim();
      const collumnType = innerObject.helpers.getCurrentCollumnType();

      if (value) {
        list.getItem(itemId).value = value;
      } else {
        const defaultValue = defaultValuesForColumnItem[collumnType];

        list.getItem(itemId).value = defaultValue;
      }
      innerObject.helpers.resetInputNode(list);
      innerObject.settings.clickedEditButtonId = null;
    },
    getLocateTextFromConstants(constantType, id) {
      const types = Object.values(constants[constantType]);
      const columnType = types.find(type => type.id === id);

      return columnType ? columnType.locale : null;
    },
    resetCustomColumnItemText(list, itemId) {
      const item = list.getItem(itemId);
      const collumnType = innerObject.helpers.getCurrentCollumnType();

      if (!item.value) {
        const defaultValue = defaultValuesForColumnItem[collumnType];

        item.value = defaultValue;
      }
      innerObject.helpers.resetInputNode(list);
      innerObject.settings.clickedEditButtonId = null;
      list.render();
    },
    setMatchesButtonText(value, matchedColumns) {
      let message = __('custom_column_matches_default');
      const matchesMessage = $$(innerObject.views.columnsMatches.id);

      matchesMessage.enable();

      if (value.length) {
        message = matchedColumns.length ? __('custom_column_matches_match', { number: matchedColumns.length }) : __('custom_column_matches_nothing');

        if (!matchedColumns.length) {
          matchesMessage.disable();
        }
      }

      matchesMessage.config.textValue = message;
      matchesMessage.refresh();
    },
    findColumnsMatches(value) {
      const matchedColumns = innerObject.helpers.addMatchedColumnsToSuggestList(value.trim());

      innerObject.helpers.setMatchesButtonText(value, matchedColumns);
    },
    addMatchedColumnsToSuggestList(value) {
      const matchesList = $$(innerObject.views.customColumsMatchesList);
      const allColumns = globalColumns.getAllColumns();
      const columnsIds = globalStore.getters['columns/getColumnIdsByProjectId'](gantt.config.gantt_id);

      matchesList.config.matchText = value;

      const unusedColumns = allColumns.filter(column => !columnsIds.find(id => id === column.id));

      const matchedColumns = unusedColumns.filter(column => column.name.toLowerCase().includes(value.toLowerCase()));

      innerObject.helpers.defineOverlayDisplay(!matchedColumns.length);

      matchesList.clearAll();
      matchesList.parse(matchedColumns);

      return matchedColumns;
    },
    generateGlobalColumnPopupContent(column, isGlobal, ganttId) {
      const tabWrap = $$(innerObject.views.popupTabWrapper);
      const childViews = tabWrap.getChildViews();

      if (childViews.length) {
        tabWrap.removeView(childViews[0]);
      }

      const popupContent = {
        view: 'layout',
        rows: [
          {
            paddingX: 24,
            rows: [
              {
                cols: [
                  column ? innerObject.views.headerEditLabel : innerObject.views.headerCreateLabel,
                  innerObject.views.closeHeaderButton,
                ],
              },
              { height: 18 },
              innerObject.views.messageBox,
              innerObject.views.nameFieldLabel,
              innerObject.views.nameField,
              !isGlobal && !column ? innerObject.views.columnsMatches : {},
              {
                view: 'layout',
                width: 276,
                autoheight: true,
                hidden: true,
                id: 'columnDataContainer',
                rows: [

                ],
              },
              {
                id: 'columnDataEditContainer',
                rows: [
                  { height: 10 },
                  innerObject.views.typeSelect,
                  { height: 10 },
                  innerObject.views.aggregationSelect,
                  innerObject.views.resourcesMultiSelect,
                  {
                    id: 'optionsCustomColumnEditor',
                    hidden: true,
                    rows: [
                      innerObject.views.selectTypeLabel,
                      innerObject.views.optionsTypeEditorList,
                      innerObject.views.selectTypeAddButton,
                    ],
                  },
                ],
              },
              isGlobal ? {
                rows: [
                  innerObject.views.projectsSelectLabel,
                  innerObject.views.projectsMultiSelect,
                ],
              } : (
                rights.account.hasRight('custom_fields_settings') ? innerObject.views.settingsLink : {}
              ),
            ],
          },
          { height: 6 },
          {
            id: 'footerCustomColumnPopup',
            css: 'footer_custom_column_popup',
            rows: [
              { height: 12 },
              {
                paddingX: 24,
                cols: [
                  column ? (
                    isGlobal ? !rights?.account?.hasRight('custom_fields_delete') ? {} : innerObject.views.deleteGlobalButton
                      : rights.project.hasRight(ganttId, 'custom_fields_unassing_from_project') ? innerObject.views.deleteButton : {}) : {},
                  {},
                  innerObject.views.saveButton,
                ],
              },
            ],
          },
        ],
      };

      tabWrap.addView(popupContent);

      $$(innerObject.views.popupId).show();
    },
    matchedSearchStringTemplate(value, searchValue) {
      const regEx = new RegExp(searchValue, 'ig');

      if (!searchValue) {
        return value;
      }

      return value.replace(regEx, matchedText => `<span class="search-match">${matchedText}</span>`);
    },
    setSelectedColumnItem(columnId) {
      const columnDataContainer = $$('columnDataContainer');

      const childViews = columnDataContainer.getChildViews();

      if (childViews.length) {
        columnDataContainer.removeView(childViews[0]);
      }

      const data = globalStore.getters['columns/getCustomColumnById'](columnId);

      innerObject.views.selectedGlobalColumn.columnData = data;

      columnDataContainer.addView(innerObject.views.selectedGlobalColumn);

      innerObject.helpers.defineColumnMessage(data, 'info');
      innerObject.helpers.setVisibilityAfterColumnSelection();
    },
    getTemplateForColumnOptions(columnType, options) {
      let resultTag = '';
      const maxElemsToShow = 2;

      if (options.length === 1) {
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.select.id
          || columnType === constants.CUSTOM_COLUMNS_TYPES.multiselect.id) {
          resultTag = `<span class="column-option-text">${options[0].value}</span>`;
        }
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.color.id) {
          resultTag = `<span class="column-option-color" style="background: ${options[0].value}"></span>`;
        }
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.tags.id) {
          resultTag = `<span class="column-option-tag" style="background: ${options[0].color}"><span>${options[0].value}</span></span>`;
        }
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          const picture = options[0].picture ? `<span class="webix_icon icon_list_item" style="background-image: url(${options[0].picture})"></span>` : '';
          const textTag = `<span class="column-options-text">${options[0].value}</span>`;

          resultTag = `<span class="column-option-people">${picture}${textTag}</span>`;
        }
      } else {
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.select.id
          || columnType === constants.CUSTOM_COLUMNS_TYPES.multiselect.id) {
          resultTag = `<span class="column-option-text">${options.length} ${__('select_items')}</span>`;
        }
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.tags.id) {
          if (options.length <= maxElemsToShow) {
            resultTag = options.map(elem => {
              const tag = `<span class="column-option-tag-text">${elem.value}</span>`;

              return `<span class="column-option-tag" style="background: ${elem.color}">${tag}</span>`;
            }).join('');
          }

          if (options.length > maxElemsToShow) {
            const tags = options.slice(0, maxElemsToShow).map(elem => {
              const tag = `<span class="column-option-tag-text">${elem.value[0]}</span>`;

              return `<span class="column-option-tag" style="width: 18px; background: ${elem.color}">${tag}</span>`;
            }).join('');

            resultTag = `${tags}<span class="column-option-number">+${options.length - maxElemsToShow}</span>`;
          }
        }
        if (columnType === constants.CUSTOM_COLUMNS_TYPES.color.id) {
          const colors = options.slice(0, maxElemsToShow).map(elem => `<span class="column-option-color" style="width: 18px; background: ${elem.value}"></span>`).join('');

          const elemsNumber = options.length > maxElemsToShow ? `<span class="column-option-number">+${options.length - maxElemsToShow}</span>` : '';

          resultTag = `<div style="display: flex;">${colors}${elemsNumber}</div>`;
        }

        if (columnType === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          const iconTags = options.slice(0, maxElemsToShow).map(elem => {
            const picture = `<span class="webix_icon icon_list_item" style="background-image: url(${elem.picture})"></span>`;

            return `<span class="column-option-people">${picture}</span>`;
          }).join('');

          const textTag = options.length > maxElemsToShow ? `<span class="column-option-number">+${options.length - maxElemsToShow}</span>` : '';

          resultTag = `${iconTags}${textTag}`;
        }
      }

      return resultTag;
    },
    getTemplateForColumnDataContainer(column) {
      const name = column.name;
      const type = innerObject.helpers.getLocateTextFromConstants('CUSTOM_COLUMNS_TYPES', column.type);
      const subType = innerObject.helpers.getLocateTextFromConstants('CUSTOM_COLUMNS_SUBTYPES', column.sub_type);
      let options = column.options;

      let template = '';

      if (options.length) {
        if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
          options = innerObject.helpers.filterResourceOptions(options);
        }

        template = innerObject.helpers.getTemplateForColumnOptions(column.type, options);
      }

      return `
        <div class='column_selected_item_name'>${name}</div>

        <div class='column_selected_item_layout'>
          <span class='column_selected_item_label'>${__('custom_column_type_label')}</span>
          <span class='column_selected_item_value'>${__(type)}</span>
          ${subType ? `
            <span class='column_selected_item_label'>${__('custom_column_aggregation_label')}:</span>
            <span class='column_selected_item_value'>${__(subType)}</span>
          ` : ''}
          ${options.length ? `
            <span class='column_selected_item_label'>${__('custom_column_select_list_label')}:</span>
            <span class='column_selected_item_value'>${template}</span>
          ` : ''}
        </div>
      `;
    },
    setVisibilityAfterColumnSelection() {
      $$(innerObject.views.nameField.id).hide();
      $$(innerObject.views.columnsMatches.id).hide();
      $$('columnDataContainer').show();
      $$('columnDataEditContainer').hide();
      $$(innerObject.views.saveButton.id).setValue(__('common_add'));
    },
    setVisibilityAfterColumnDeselection() {
      $$(innerObject.views.nameField.id).show();
      $$(innerObject.views.columnsMatches.id).show();
      $$('columnDataContainer').hide();
      $$('columnDataEditContainer').show();
      innerObject.helpers.defineColumnMessage();
      $$(innerObject.views.saveButton.id).setValue(__('common_save'));
      innerObject.views.selectedGlobalColumn.columnData = {};
    },
    defineOverlayDisplay(isShow) {
      const suggestColumnsList = $$(innerObject.views.customColumsMatchesList);

      if (isShow) {
        suggestColumnsList.showOverlay(`<div>${__('custom_column_matches_nothing')}</div>`);
      } else {
        suggestColumnsList.hideOverlay();
      }
    },
    defineColumnMessage(column, messageType) {
      const messageBox = $$(innerObject.views.messageBox.id);

      if (column) {
        const message = $$('customColumnPopupMessage');
        const columnProjects = globalStore.getters['columns/getProjectsNumberByColumnId'](column.id);

        if (columnProjects.archive || columnProjects.visible) {
          message.config.textValue = columnProjects;
          message.config.messageType = messageType;
          message.refresh();
          messageBox.show();

          return;
        }
      }

      messageBox.hide();
    },
    updateCustomColumnPopupProjects() {
      const popup = $$(innerObject.views.popupId);

      if (popup) {
        const projectsMultiSelect = $$(innerObject.views.projectsMultiSelect.id);

        if (innerObject.settings.isGlobal) {
          const projects = innerObject.helpers.getOptionsForProjectsMultiSelect();
          const oldProjects = projectsMultiSelect.getList().serialize();

          if (projects.length !== oldProjects.length) {
            projectsMultiSelect.getList().clearAll();
            projectsMultiSelect.getList().parse(projects);
            projectsMultiSelect.getPopup().hide();
            projectsMultiSelect.refresh();
          }
        }
      }
    },
    getRandomColor(colorsUsed) {
      const palette = constants.PALETTE_CONFIG.palette.map(item => item.map(cell => cell.color));
      const colors = palette[0].concat(palette[1], palette[2], palette[3]);
      let tagColor = colors.find(color => !colorsUsed.includes(color));

      if (!tagColor) {
        let randomIndex = Math.floor(Math.random() * colors.length);

        if (colorsUsed[colorsUsed.length - 1] === colors[randomIndex] && randomIndex > 0) {
          tagColor = colors[randomIndex - 1];
        } else {
          tagColor = colors[randomIndex];
        }
      }

      return tagColor;
    }
  },
};

app.on('resources:model:remove', resourceIds => {
  resourceIds.forEach(resourceId => {
    globalStore.commit('columns/removeResourceOptionById', resourceId);
    app.trigger('userCustomColumnsModel:change');
  });
});

app.on('resources:model:addResourcesToProjects', data => {
  _.delay(() => {
    app.trigger('userCustomColumnsModel:change');
    innerObject.helpers.updateCustomColumnPopupProjects();
  }, 1000);
});

app.on('resources:model:removeResourcesFromProjects', (data, resourceIds) => {
  data.forEach(obj => {
    globalStore.commit('columns/removeResourceOptionFromProject', {
      resourceId: obj.resourceId,
      projectId: obj.projectId,
    })
    app.trigger('userCustomColumnsModel:change', obj.projectId);
  });
});

webix.ui({
  view: 'ganttproWindowPopup',
  width: 324,
  id: innerObject.views.popupId,
  css: 'custom_columns_popup',
  zIndex: 113,
  body: {
    rows: [
      { height: 24 },
      {
        view: 'layout',
        width: 324,
        autoheight: true,
        css: '',
        id: innerObject.views.popupTabWrapper,
        rows: [

        ],
      },
      { height: 12 },
    ],
  },
  on: {
    onShow() {
      const nameField = $$(innerObject.views.nameField.id);
      const input = nameField.getInputNode();

      input.focus();
      input.select();
    },
    onHide() {
      innerObject.helpers.resetInputNode($$(innerObject.views.selectCustomColumnEditorList));
      innerObject.settings.currentColumn = null;
      innerObject.settings.clickedEditButtonId = null;
      innerObject.settings.isGlobal = null;
      $$(innerObject.views.saveButton.id).enable();
    },
  },
}).hide();

webix.ui({
  view: 'popup',
  padding: 8,
  id: innerObject.views.colorPopupId,
  hidden: true,
  body: {
    view: 'colorboardCst',
    id: innerObject.views.colorBoard,
    css: 'task-settings-color-board',
    width: 180,
    height: 120,
    palette: constants.PALETTE_CONFIG.palette.map(item => item.map(cell => cell.color)),
    template(obj) {
      return `<div class="color-bar" style="background: ${obj.val};"><div class="color-bar-inside-border"></div></div>`;
    },
    on: {
      onSelect: innerObject.handlers.changeColor,
    },
    left: 100,
    top: 100,
  },
});

webix.ui({
  view: 'popupWithoutPointCustom',
  id: innerObject.views.customColumnPreviewPopup,
  css: 'custom_column_preview_popup',
  padding: 8,
  body: {
    view: 'list',
    id: innerObject.views.customColumnPreviewList,
    borderless: true,
    width: 200,
    type: {
      height: 30,
    },
    template(item) {
      if (innerObject.helpers.isDividerItem(item.id)) {
        return `<div class="list-group-item">
          <div class="group-item-container">
            <span class="group-header-caption">
              ${item.label}
            </span>
            <span class="group-header-underline"></span>
          </div>
        </div>`;
      }

      if (item.color) {
        return `<div class="list_item">
          <span class="list-item-tag" style="background: ${item.color}"></span>
          <span class="list_item_value">${item.value}</span>
        </div>`;
      }

      if (item.picture) {
        return `<div class="list_item">
          <span class="list_item_icon" style="background-image: url(${item.picture})"></span>
          <span class="list_item_value list_item_name">${item.value}</span>
        </div>`;
      }

      return `<span class="list_item"><span class="list_item_value">${item.value}</span></span>`;
    },
    on: {
      onBeforeRender(data) {
        const length = data.serialize().length;

        this.define('yCount', length);
        this.resize();
      },
    },
  },
});

webix.ui({
  view: 'popupWithoutPointCustom',
  id: innerObject.views.customColumnPreviewPopupText,
  css: 'custom_column_preview_popup_text',
  padding: 8,
  body: {
    view: 'list',
    id: innerObject.views.customColumnPreviewText,
    css: 'custom_column_preview_text',
    borderless: true,
    height: 30,
    template(item) {
      return `<span class="list_item"><span class="list_item_value">${item.value}</span></span>`;
    },
  },
});

webix.ui({
  view: 'popup',
  id: innerObject.views.customColumsMatchesPopup,
  width: 276,
  css: 'custom_colums_matches_popup',
  autoheight: true,
  body: {
    view: 'list',
    minHeight: 32,
    id: innerObject.views.customColumsMatchesList,
    borderless: true,
    autoheight: true,
    yCount: 5,
    matchText: '',
    type: {
      height: 52,
    },
    data: [],
    template(obj) {
      const text = $$(innerObject.views.customColumsMatchesList).config.matchText;
      const name = innerObject.helpers.matchedSearchStringTemplate(obj.name, text);
      const typeValue = innerObject.helpers.getLocateTextFromConstants('CUSTOM_COLUMNS_TYPES', obj.type);

      return `<div class='colums_matches_item'>
        <div class='colums_matches_item_name'>${name}</div>
        <div class='colums_matches_item_type'>${__(typeValue)}</div>
      </div>`;
    },
    on: {
      onItemClick(id, e, node) {
        innerObject.helpers.setSelectedColumnItem(id);
        this.hide();
      },
    },
  },
  on: {
    onShow() {
      const nameField = $$(innerObject.views.nameField.id);
      const input = nameField.getInputNode();

      input.focus();
    },
  },
});

app.on('openUserCustomColumnsPopup', innerObject.handlers.openCustomColumnPopup);
app.on('closeUserCustomColumnsPopup', innerObject.handlers.closeCustomColumnPopup);

app.on('userCustomValuesModel:change', task => {
  gantt.silentUpdateTask(task.id, task);
});

app.on('openProject', () => {
  const popup = $$(innerObject.views.popupId);

  if (popup) {
    popup.hide();
  }
});

app.on('user:changedRole', ganttId => {
  const popup = $$(innerObject.views.popupId);

  if (popup) {
    popup.hide();
  }
});

Gantt.plugin(gantt => {
  gantt.getBaselineUserCustomValue = function (task, column, a) {
    const ganttId = gantt.config.gantt_id || task.gantt_id;
    const taskTypesForColumn = ['project', 'task', 'milestone'];
    let result = {
      text: '',
      value: null,
      toExcel: '',
    };

    if (!_.includes(taskTypesForColumn, task.type) || task.$level === 0) {
      return result;
    }

    const baselineUserCustomValue = baselineModel.getBaselineUserCustomValuesByParams(ganttId, column.name, task.id);
    const baselineData = baselineModel.getBaselineData(ganttId);

    if (baselineData && !gantt.config.multiview) {
      let columnData = _.find(baselineData.userCustomColumnsData.columns, { id: column.name });

      if (!columnData) {
        columnData = globalStore.getters['columns/getCustomColumnById'](column.name);
      } else {
        columnData.type = columnData.custom_column_types_id;
        columnData.sub_type = columnData.custom_column_sub_types_id;
      }

      result = innerObject.helpers.prepareColumnValue(result, task, columnData, baselineUserCustomValue, true);
    }

    return result;
  };

  gantt.getUserCustomValue = function (task, columnId) {
    const ganttID = task.gantt_id || gantt.config.gantt_id;

    if (!ganttID) {
      return '';
    }
    const column = globalStore.getters['columns/getCustomColumnById'](columnId);

    return innerObject.helpers.getValueForUserColumn(task, column);
  };

  gantt.config.changed_value_locale = __('changed_custom_value');
});

const outputObject = {
  init: innerObject.initColumns,
  reInit: innerObject.reInitColumns,
  getColumnsData: innerObject.getColumnsData,
  filterPeopleOptions: innerObject.filterPeopleOptions,
};

export default outputObject;
