<template>
  <div
    :class="[
      $.progress_toast,
      currentProgress.status === status.done ? $.progress_toast__success : '',
      currentProgress.status === status.fail ? $.progress_toast__error : '',
      config.spinnerType === 'progress' ? $.progress_toast__progress_line : '',
    ]"
  >
    <!-- Progress body -->
    <div
      v-if="currentProgress.status === status.started || currentProgress.status === status.update"
      :class="$.progress_toast__info"
    >
      <div
        v-if="config.spinnerType === 'spinner'"
        :class="$.progress_toast__circle_indicator"
      >
        <spinner />
      </div>
      <div
        v-else
        :class="$.progress_toast__value"
      >
        {{ `${currentProgress.progress}%` }}
      </div>
      <div
        :class="$.progress_toast__message"
        :style="config.spinnerType === 'spinner' ? {paddingLeft: '40px'} : ''"
      >
        {{ locales(currentProgress.title) }}
      </div>
      <div
        v-if="currentProgress.blockType !== 'full' && currentProgress.spinnerType !== 'spinner'"
        :class="$.progress_toast__hide"
      >
        <vgp-button
          type="text"
          :label="locales('progress_hide_btn')"
          :small="true"
          @onClick="onClickHide(currentProgress.token)"
        />
      </div>
      <div
        v-if="config.spinnerType === 'progress'"
        :class="$.progress_toast__linear_indicator"
      >
        <linear :value="currentProgress.progress" />
      </div>
    </div>

    <!-- Success body -->
    <div
      v-if="currentProgress.status === status.done"
      :class="[$.progress_toast__info, isMultilineMessage ? $.progress_toast_one_line : '']"
    >
      <div :class="[$.progress_toast__icon, isMultilineMessage ? $.progress_toast_one_line : '']">
        <sprite
          :name="'success'"
          :type="'bold'"
          :size="24"
        />
      </div>
      <div
        ref="message"
        :class="$.progress_toast__message"
        v-html="locales(currentProgress.title, currentProgress.meta)"
      />
      <div :class="$.progress_toast__close_btn">
        <icon-button
          :icon="{ name:'close-1' }"
          size="small"
          @onClick="onClose"
        />
      </div>
    </div>

    <!-- Error body -->
    <div
      v-if="currentProgress.status === status.fail"
      :class="$.progress_toast__info"
    >
      <div :class="$.progress_toast__icon">
        <sprite
          :name="'close-2'"
          :type="'bold'"
          :size="24"
        />
      </div>
      <div
        :class="$.progress_toast__message"
      >
        {{ locales(currentProgress.title) }}
      </div>
      <div :class="$.progress_toast__close_btn">
        <icon-button
          :icon="{ name:'close-1' }"
          size="small"
          @onClick="onClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import linear from '../indicators/linear.vue';
import spinner from '../indicators/circle.vue';
import vgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import store from '../../../store/main';
import sprite from '../../common/VueComponents/VueIcon/svgSprite.vue';
import constants from '../../../helpers/constants';
import iconButton from '../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import customHelper from '../../../helpers/custom';

export default {
  name: 'ProgressLinear',
  components: {
    linear, spinner, vgpButton, sprite, iconButton,
  },
  props: {
    config: { type: Object, required: true },
  },
  data() {
    return {
      locales: (...args) => customHelper.formatLocaleText(__(...args)),
      status: {
        started: constants.PROGRESS_BAR.STATUS.STARTED,
        update: constants.PROGRESS_BAR.STATUS.UPDATE,
        done: constants.PROGRESS_BAR.STATUS.DONE,
        fail: constants.PROGRESS_BAR.STATUS.FAIL,
      },
      message: null,
      isMultilineMessage: false,
    };
  },
  computed: {
    progressEvents() {
      return store.getters['progress/progressEvents'];
    },
    currentProgress() {
      return this.progressEvents.find(itm => itm.token === this.config.token);
    },
  },
  watch: {
    currentProgress(obj) {
      if (!obj) {
        this.$emit('close-toast');
      }
    },
    'currentProgress.title': {
      handler() {
        this.$nextTick(() => {
          this.checkMessageLines();
        });
      },
      deep: true,
    },
  },
  created() {
    this.checkMessageLines();
  },
  methods: {
    onClickHide(id) {
      this.$emit('close-toast');
      this.$emit('onHide', id);
    },
    onClose() {
      this.$emit('close-toast');
    },
    checkMessageLines() {
      const message = this.$refs.message;

      if (message) {
        const lineHeight = parseFloat(window.getComputedStyle(message).lineHeight);
        const numLines = Math.round(message.clientHeight / lineHeight);

        this.isMultilineMessage = numLines > 1;
      }
    },
  },
};
</script>

<style module='$' src='./progressToast.less'></style>
