var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.multiselect, _vm.$style.field_wrap] }, [
    _c("div", { class: [_vm.$style.input_label] }, [
      _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChacked,
              expression: "hasChacked",
            },
          ],
          class: _vm.$style.reset,
          attrs: { id: _vm.gpAutotestName + "_reset_btn" },
          on: {
            click: function ($event) {
              return _vm.resetChacked()
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.reset_locale) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { ref: "inputField", class: [_vm.$style.input_field_wrap] }, [
      _c(
        "div",
        {
          class: [
            _vm.$style.input_field,
            _vm.hasChacked || _vm.isShowDropList ? _vm.$style.active : "",
          ],
          attrs: { id: _vm.gpAutotestName },
          on: { click: _vm.toggleDropList },
        },
        [
          _vm.hasChacked
            ? _c(
                "div",
                { class: _vm.$style.input_field_selected },
                _vm._l(_vm.chacked, function (item, index) {
                  return _c(
                    "div",
                    { key: _vm.dropTemplate + item[_vm.trackBy] },
                    [
                      _vm.dropTemplate === "text"
                        ? [_c("span", [_vm._v(_vm._s(item[_vm.valueField]))])]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dropTemplate === "icon+text"
                        ? [
                            _c("div", {
                              class: _vm.$style.item_icon,
                              style: {
                                "background-image":
                                  "url(" + item[_vm.iconField] + ")",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item[_vm.valueField]))]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dropTemplate === "color+text"
                        ? [
                            _c("div", {
                              class: _vm.$style.item_circle_color,
                              style: { background: item.color },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item[_vm.valueField]))]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dropTemplate === "color"
                        ? [
                            _c("div", {
                              class: [
                                _vm.$style.item_color,
                                _vm.$style.selected_item_color,
                              ],
                              style: { background: item.color },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasChacked != index + 1
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              )
            : _c("div", { class: _vm.$style.input_placeholder }, [
                _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      "),
              ]),
          _vm._v(" "),
          _c("webix-ui", {
            class: _vm.$style.drop_arrow,
            attrs: { config: _vm.icon_arrow },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowDropList,
              expression: "isShowDropList",
            },
          ],
          ref: "dropList",
          class: [
            _vm.$style.drop_list,
            _vm.$style.drop_scroll,
            "gantt_scroll",
            _vm.$style.show_list,
            _vm.isBottom ? _vm.$style.bottom_drop : _vm.$style.top_drop,
          ],
          style: {
            top: _vm.coordinates.top,
            bottom: _vm.coordinates.bottom,
            left: _vm.coordinates.left,
            right: _vm.coordinates.right,
            width: _vm.dropListWidth,
          },
        },
        _vm._l(_vm.dropData, function (item) {
          return _c(
            "div",
            { key: _vm.dropTemplate + item.id, class: _vm.$style.list_item },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chacked,
                    expression: "chacked",
                  },
                ],
                attrs: {
                  id: item[_vm.trackBy] + _vm.componentKey,
                  type: "checkbox",
                },
                domProps: {
                  value: item,
                  checked: Array.isArray(_vm.chacked)
                    ? _vm._i(_vm.chacked, item) > -1
                    : _vm.chacked,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.chacked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = item,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.chacked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.chacked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.chacked = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { attrs: { for: item[_vm.trackBy] + _vm.componentKey } },
                [
                  _vm.dropTemplate === "text"
                    ? [_c("span", [_vm._v(_vm._s(item[_vm.valueField]))])]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dropTemplate === "icon+text"
                    ? [
                        _c("div", {
                          class: _vm.$style.item_icon,
                          style: {
                            "background-image":
                              "url(" + item[_vm.iconField] + ")",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(" " + _vm._s(item[_vm.valueField]) + " "),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dropTemplate === "color+text"
                    ? [
                        _c("div", {
                          class: _vm.$style.item_circle_color,
                          style: { background: item.color },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(" " + _vm._s(item[_vm.valueField]) + " "),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dropTemplate === "color"
                    ? [
                        _c("div", {
                          class: _vm.$style.item_color,
                          style: { background: item.color },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }