<template>
  <core-popup
    :show="isShow"
    :title="config.title || null"
    width="440px"
  >
    <template #body>
      <div
        class="content"
        :style="{'padding': '24px 0 28px 0', 'font-size': '16px', 'line-height': '24px'}"
      >
        <div class="message">
          {{ config.message }}
        </div>
      </div>
      <div
        class="footer"
        :style="{'display': 'flex', 'justify-content': 'flex-end'}"
      >
        <vgp-button
          :label="locales('common_reload_btn')"
          type="primary"
          :small="true"
          @onClick="onReload"
        />
      </div>
    </template>
  </core-popup>
</template>

<script>
import app from '../../../app';
import VgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import CorePopup from '../../common/VueComponents/popups/corePopup/corePopup.vue';
import multiviewModel from '../../../models/multiViewsProjects';
import routerHelper from '../../../helpers/router';

import rights from '../../../components/rights/index';

export default {
  name: 'ReloadModal',
  components: { CorePopup, VgpButton },
  data() {
    return {
      locales: __,
      isShow: false,
      config: {},
    };
  },
  watch: {
    isShow(value) {
      if (value) {
        webix.modalbox.hideAll();
        app.trigger('reloadModal:show');
      }
    },
  },
  beforeCreate() {
    app.on('onAfterCollaboration', data => {
      const currentRoute = routerHelper.getCurrentRoute();

      if (currentRoute.fullPath.includes('/onboarding') || currentRoute.fullPath.includes('/comments')) {
        return;
      }

      if (data.event === 'ResourceUpdated' && data?.isUpdatedRole && user.id === data.userId) {
        this.config = {
          message: __('changed_account_role_modal_message'),
        };
        this.isShow = true;

        return;
      }

      if (data.event === 'ResourceOnProjectsUpdated') {
        const resource = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);
        const isCurrentResource = data.resourcesIds.find(id => resource.id === id);
        const isCurrentProject = data.projects.find(id => id === gantt.config.gantt_id);
        const activeMultiviewData = multiviewModel.getActiveViewData();
        const isMultiview = gantt.config.multiview && activeMultiviewData && activeMultiviewData.ganttIDs.find(ganttId => ganttId === data.projects[0]);

        if (isCurrentResource && data.isUpdatedRole && (isCurrentProject || isMultiview)) {
          this.config = {
            message: __('changed_project_role_modal_message'),
          };
          this.isShow = true;
        }
      }

      if (data.event === 'RoleAccountUpdated') {
        const resourceData = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);

        if (data.data.reload && data.data.updated.find(obj => obj.id === resourceData.accountRoleId)) {
          this.config = {
            message: __('changed_account_role_modal_message'),
          };
          this.isShow = true;
        }
      }

      if (data.event === 'RoleProjectUpdated') {
        const userProjectRole = rights.project.getUserRole(user.id, gantt.config.gantt_id);
        const resourceData = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);
        const activeMultiviewData = multiviewModel.getActiveViewData();
        let isProjectRoleInMultivew = false;

        activeMultiviewData && activeMultiviewData.ganttIDs.forEach(ganttId => {
          const isProject = resourceData.resourceProjects.find(pr => pr.projectId === ganttId);

          if (isProject && data.data.updated.find(i => i.id === isProject.projectRoleId)) {
            isProjectRoleInMultivew = true;
          }
        });

        if (data.data.reload && (data.data.updated.find(role => role.id === userProjectRole?.id) || isProjectRoleInMultivew)) {
          this.config = {
            message: __('changed_project_role_modal_message'),
          };
          this.isShow = true;
        }
      }

      if (data.event === 'ResourceFromProjectsUnassigned') {
        const resourceId = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);

        const isCurrentResource = data.data.resourcesFromProjects.find(item => item.resourceId === resourceId);
        const isCurrentProject = data.projects.find(id => id === gantt.config.gantt_id);

        if (isCurrentResource && !isCurrentProject) {
          this.isShow = false;
          this.config = {};
        }
      }
    });

    app.on('reloadModal:show:selfRoleUpdate', type => {
      if (type === 'account') {
        this.config = {
          message: __('changed_account_role_modal_message'),
        };
        this.isShow = true;
      }
    });
  },
  methods: {
    onReload() {
      window.location.reload();
    },
  },
};
</script>
