// fully written by us

class MaskParser {
  constructor() {
    const maxBitSize = 10;// 1024 dec
    this.chunkSize = String(2 ** maxBitSize).length;
    this.maxBitSize = maxBitSize;
    this.defaultSubMask = ''.padStart(this.chunkSize, '0');
  }

  _getSubMasks(mask) {
    return mask.match(new RegExp(`.{1,${this.chunkSize}}`, 'g')) || [];
  }

  _decodePermission(permission) {
    return 2 ** ((permission - 1) % this.maxBitSize);
  }

  _getSubMaskPosition(permission) {
    return permission === this.maxBitSize ? 0 : Math.floor(permission / this.maxBitSize);
  }

  /**
   * @param {string} mask
   * @param {number} permission - only integers > 0
   * @return {boolean}
   */
  checkPermission(mask, permission) {
    const subMask = this._getSubMasks(mask)[this._getSubMaskPosition(permission)];

    if (!subMask) {
      return false;
    }

    return !!(+subMask & this._decodePermission(permission));
  }

  /**
   * @param {string} mask
   * @param {array} permissions - only integers > 0
   * @return {string} modified mask
   */
  addPermissions(mask, permissions) {
    const subMasks = this._getSubMasks(mask);

    permissions.forEach(permission => {
      const decodedSubPerm = this._decodePermission(permission);
      const partPosition = this._getSubMaskPosition(permission);

      subMasks[partPosition] = String(+subMasks[partPosition] | decodedSubPerm).padStart(this.chunkSize, '0');
    });

    return subMasks.join('');
  }

  /**
   * @param {string} mask
   * @param {array} permissions - only integers > 0
   * @return {string} modified mask
   */
  removePermissions(mask, permissions) {
    const subMasks = this._getSubMasks(mask);

    permissions.forEach(permission => {
      if (!this.checkPermission(mask, permission)) {
        return mask;
      }
      const decodedSubPerm = this._decodePermission(permission);
      const partPosition = this._getSubMaskPosition(permission);

      subMasks[partPosition] = String(+subMasks[partPosition] ^ decodedSubPerm).padStart(this.chunkSize, '0');

      if (!+subMasks[subMasks.length - 1]) {
        subMasks.pop();
      }
    });

    return subMasks.join('');
  }

  /**
   * returns new mask based on permissions
   *
   * @param {array} permissions - only integers > 0
   * @return {string}
   */
  createMask(permissions) {
    const mask = [];

    permissions.forEach(permission => {
      const decodedSubPerm = this._decodePermission(permission);
      let partPosition = this._getSubMaskPosition(permission);

      mask[partPosition] = String(+mask[partPosition] | decodedSubPerm).padStart(this.chunkSize, '0');

      while (partPosition > 0) {
        partPosition--;
        if (mask[partPosition] === undefined) {
          mask[partPosition] = this.defaultSubMask;
        }
      }
    });

    return mask.join('');
  }

  /**
   * @param {array} masks - array of strings
   * @return {string}
   */
  mergeMasks(masks) {
    return masks.reduce((prevMergedMasks, mask) => {
      const resultMask = [];
      const subMasksPrev = this._getSubMasks(prevMergedMasks);
      const subMasksNext = this._getSubMasks(mask);

      for (let i = 0; i < Math.max(subMasksPrev.length, subMasksNext.length); i++) {
        resultMask[i] = String(+subMasksPrev[i] | +subMasksNext[i]).padStart(this.chunkSize, '0');
      }

      return resultMask.join('');
    }, masks[0]);
  }

  /**
   * @param {string} mask
   * @param {string} exceptions - mask of exceptions
   * @return {string} modified mask
   */
  mergeExceptions(mask, exceptions) {
    const resultMask = [];
    const subMasks = this._getSubMasks(mask);
    const exceptionsSubMasks = this._getSubMasks(exceptions);

    for (let i = 0; i < Math.max(subMasks.length, exceptionsSubMasks.length); i++) {
      resultMask[i] = String(+subMasks[i] ^ +exceptionsSubMasks[i]).padStart(this.chunkSize, '0');
    }

    return resultMask.join('');
  }
}

if (typeof module === 'object' && module.exports) module.exports = MaskParser;
else if (typeof exports === 'object') exports = MaskParser;

// export default MaskParser;

// tests

 // const Mask = new MaskParser();
 // let mask = '10230511102310230639';
//  mask = Mask.addPermissions(mask, [48]);
//  console.log('mask ------------------------------ ',mask);

//
// let mask = Mask.createMask([1, 2, 4]);
//
// const mask2 = Mask.createMask([8, 55]);
// const mask3 = Mask.createMask([13, 32]);
// const mergedMasks = Mask.mergeMasks([mask, mask2, mask3]);
//
// console.log(mask);
//
// mask = Mask.addPermissions(mask, [7]);
// mask = Mask.removePermissions(mask, [12]);
// mask = Mask.removePermissions(mask, [13]);
//
// console.log(Mask.checkPermission(mergedMasks, 32));
// console.log(Mask.checkPermission(mergedMasks, 8));
// console.log(Mask.checkPermission(mask, 13));
// console.log(Mask.checkPermission(mask, 43));
// console.log(Mask.checkPermission(mask, 4));
// console.log(Mask.checkPermission(mask, 7));
