import commentsModel from '../../../models/comments2';
import app from '../../../app';
import globalStore from '../../../store/main';

function onTaskCommentDeleted(event) {
  let ids = [];

  if (event.data?.ids.length) {
    ids = event.data.ids;
  } else if (event.data?.taskIds.length) {
    event.data.taskIds.forEach(tid => {
      const taskData = globalStore.getters['tasksModel/getTask'](tid);

      if (taskData && taskData.comments?.length) {
        ids.push(...taskData.comments?.map(comment => comment.id));
      }
    });
  }

  ids.forEach(commentId => {
    // commentsModel.onWSRemoveComment({
    //   count: 1,
    //   data: commentId,
    // });

    globalStore.dispatch('comments/onWSCommentDeleted', commentId);
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: [...event.data.ids],
  });
}

export default onTaskCommentDeleted;
