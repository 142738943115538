import templatesLabels from '../../templates/labels';
import customGrids from '../gantt/modules/customGrids';
import icon_filter from '../../svg/header_buttons/ic_filter.svg';
import settingsZoom from '../settings/include/settingsZoom';
import exportComponent from '../../components/Export';
import constants from '../../helpers/constants';
import baselinesModel from '../../models/baselines';
import './xmlExport';
import './xlsxExport';
import _ from '../../libs/lodash';
import { checkPricingAccess } from '../../helpers/pricingHelper';
import rights from '../../components/rights';
import app from '../../app';

const __ = window.__;

const EVENTS = {
  SHOW_FILTER: 'showFilterPopup',
  CHANGE_ZOOM: 'changeZoom',
  AUTO_SCHEDULING: 'changeAutoScheduling',
  CRITICAL_PATH: 'changeCriticalWay',
  CURRENT_DAY_MARKER: 'changeCurrentDayMarker',
  WORKLOAD: 'changeWorkload',
  BASELINE: 'changeBaseline',
  START_PROJECT: 'changeStartProject',
  ROW_SETTINGS: 'changeRowSettings',
  CHANGE_COLUMNS: 'changeColumns',
  OVERDUE: 'highlightOverdue',
  CROSS_OUT_TASKS: 'crossOutTasks',
  DISCOLORATION_TASKS: 'discolorationTasks',
  TASK_NAME: 'changeTaskName',
};

const rowSettingsLayoutID = 'rowSettingsLayoutID';
const exportFormBottomID = 'exportFormBottom';

const sendMessageToFrom = (newValue, event) => {
  exportComponent.sendMessageToExportFrame({
    newValue,
    message: event,
  });
};

const getToggleValue = event => {
  const eventValueMap = {
    changeAutoScheduling() {
      return gantt.config.auto_scheduling;
    },
    changeCriticalWay() {
      return gantt.config.highlight_critical_path;
    },
    changeCurrentDayMarker() {
      return gantt.config.current_day_marker;
    },
    changeWorkload() {
      return !gantt.config.hide_workload;
    },
    changeStartProject() {
      return false;
    },
    changeRowSettings() {
      return false;
    },
    highlightOverdue() {
      return gantt.config.highlight_overdue;
    },
    crossOutTasks() {
      return gantt.config.cross_out_tasks;
    },
    discolorationTasks() {
      return gantt.config.discoloration_tasks;
    },
  };

  return eventValueMap[event] ? eventValueMap[event]() : false;
};

const getStatusBaselineDropdown = function () {
  const baselines = baselinesModel.getByGanttId(gantt.config.gantt_id);

  if (baselines.length) {
    return createBaselineSelect();
  }
};

const createBaselineSelect = function () {
  const richSelectID = 'export_baseline';
  let baselines = baselinesModel.getByGanttId(gantt.config.gantt_id);
  let baselineOptions = [];
  const value = '0';

  baselineOptions.push({ id: '0', value: __('export_without_baseline') });
  baselineOptions = _.union(baselineOptions, _.map(baselines, item => ({ id: item.id, value: item.name })));

  gantt.attachEvent('baseline:updated', () => {
    if ($$(richSelectID)) {
      baselines = baselinesModel.getByGanttId(gantt.config.gantt_id);

      baselineOptions = _.union(baselineOptions, _.map(baselines, item => ({ id: item.id, value: item.name })));

      $$(richSelectID).getPopup().getList().parse(baselineOptions);
      $$(richSelectID).refresh();
    }
  });

  return {
    view: 'richselectWithoutPoint',
    label: __('export_baseline'),
    name: 'export_baseline',
    css: 'export_baseline_dropdown',
    id: richSelectID,
    labelPosition: 'top',
    value,
    options: baselineOptions,
    on: {
      onChange(newValue) {
        const exportBaseline = $$('export_baseline');
        const staticInp = exportBaseline.getNode().querySelector('.webix_inp_static');

        staticInp && staticInp.setAttribute('title', staticInp.innerText);
        sendMessageToFrom(newValue, EVENTS.BASELINE);
      },
    },
  };
};

const createToggleLayout = function (label, event, toolTip) {
  return {
    paddingY: 6,
    id: event,
    cols: [
      {
        view: 'label',
        css: 'label-with-spacer',
        label,
        template: templatesLabels.getTemplateForLabelWithSpacer,
        width: 205,
      },
      {
        view: 'toggle',
        type: 'icon',
        icon: '',
        // width: 54,
        // borderless: true,
        name: event,
        value: getToggleValue(event),
        on: {
          onChange(newValue) {
            sendMessageToFrom(newValue, event);
          },
        },
      },
    ],
  };
};

const createButton = function (label, handler, css, template) {
  const buttonUI = {
    view: 'button',
    width: 144,
    height: 36,
    css,
    name: label,
    label,
    borderless: true,
    click() {
      handler && handler();
    },
  };

  if (template) {
    buttonUI.template = template;
  }

  return buttonUI;
};

const createCheckbox = function (item, handler) {
  const cols = [
    {
      view: 'checkbox',
      id: item.id,
      width: 36,
      value: item.optionValue,
      on: {
        onChange(newValue) {
          if (_.includes(['start_date', 'end_date'], item.id)) {
            const $optionCheckbox = $$(`${item.id}_time`);

            if (newValue) {
              $optionCheckbox.enable();
              $$(`${item.id}_time` + '_label').enable();
            } else {
              // $optionCheckbox.blockEvent();
              $optionCheckbox.setValue(newValue);
              // $optionCheckbox.unblockEvent();
              $optionCheckbox.disable();
              $$(`${item.id}_time` + '_label').disable();
            }
          }
          sendMessageToFrom({ newValue, itemId: item.id }, EVENTS.CHANGE_COLUMNS);
        },
      },
    },
    {
      view: 'label',
      checkBox: item.id,
      label: item.label,
      css: 'export-checkbox-label',
      labelWidth: 230,
      on: {
        onItemClick(id) {
          $$(this.config.checkBox).toggle();
        },
      },
    },
  ];

  if (_.includes(['start_date', 'end_date'], item.id)) {
    const adv = customGrids.helpers.advancedOptions();

    cols.push({
      view: 'checkbox',
      id: `${item.id}_time`,
      width: 36,
      value: adv[item.id].check,
      disabled: !item.optionValue,
      on: {
        onChange(newValue) {
          sendMessageToFrom({ newValue, itemId: `${item.id}_time` }, EVENTS.CHANGE_COLUMNS);
        },
      },
    });

    cols.push({
      view: 'label',
      checkBox: `${item.id}_time`,
      id: `${item.id}_time` + '_label',
      label: __(`export_${`${item.id}_time`}_label`),
      css: 'export-checkbox-label',
      disabled: !item.optionValue,
      labelWidth: 230,
      on: {
        onItemClick(id) {
          $$(this.config.checkBox).toggle();
        },
      },
    });
  }

  return {
    cols,
  };
};

const createCheckboxLayout = function () {
  const options = [];

  _.each(gantt.config.columns, column => {
    const getColName = () => {
      if (column.name === 'total_price') {
        return 'costs';
      }
      if (column.name === 'overload') {
        return 'workload';
      }

      return column.name;
    };

    if ((column.isShowCheckbox || column.name === 'text') && !_.includes(['comments', 'attachments'], column.name) && checkPricingAccess(getColName())) {
      options.push({
        id: column.name,
        label: column.isUserCustomColumn ? column.label : __(`gantt_grid_${column.name}`),
        optionValue: !column.hide,
      });
    }
  });

  return {
    id: rowSettingsLayoutID,
    // hidden: true,
    rows: [
      {
        css: 'rowSettingsLayout',
        view: 'label',
        label: __('export_row_settings'),
      },
      // createCheckbox({
      //   id: 'select_all_columns',
      //   label: __("gantt_grid_" + 'select_all_columns'),
      // }),
      // {height: 10},
      // {
      //   css: "export-divide-line-checkbox",
      //   height: 1
      // },
      { height: 10 },
      {
        css: 'export-row-settings',
        rows: _.map(options, createCheckbox),
      },
    ],
  };
};

const createZoomDots = function () {
  return `<div class='dots-wrapper'>
            <div class='dot-item'></div>
            <div class='dot-item'></div>
            <div class='dot-item'></div>
            <div class='dot-item'></div>
            <div class='dot-item'></div>
            <div class='dot-item'></div>
          </div>`;
};

const getTopPartFrom = function (handler) {
  const innerViews = {
    exportSettingsSize: 'export_setting_size',
    exportSettingsOrient: 'export_setting_orient',
  };

  const showFormElements = function (exportType) {
    if (exportType === 'png') {
      $$(innerViews.exportSettingsSize).hide();
      $$(innerViews.exportSettingsOrient).hide();

      if (!checkPricingAccess('overdue_tasks')) {
        $$('highlightOverdue').hide();
      }
      $$(exportFormBottomID).show();
      $$('exportFilter').show();
      $$('baselineSelectSeparator').show();
      $$('exportZoomSlider').show();
      $$('exportToggleLayout').show();
      $$('exportCheckboxLayout').show();
      $$('export_setting_task_name').show();

      handler(true, exportType);
    }

    if (exportType === 'pdf') {
      $$(innerViews.exportSettingsSize).show();
      $$(innerViews.exportSettingsOrient).show();

      if (!checkPricingAccess('overdue_tasks')) {
        $$('highlightOverdue').hide();
      }
      $$(exportFormBottomID).show();
      $$('exportFilter').show();
      $$('baselineSelectSeparator').show();
      $$('exportZoomSlider').show();
      $$('exportToggleLayout').show();
      $$('exportCheckboxLayout').show();
      $$('export_setting_task_name').show();

      handler(true, exportType);
    }

    if (exportType === 'xml') {
      $$(innerViews.exportSettingsSize).hide();
      $$(innerViews.exportSettingsOrient).hide();

      $$(exportFormBottomID).hide();
      handler(false, exportType);
    }

    if (exportType === 'xlsx') {
      $$(innerViews.exportSettingsSize).hide();
      $$(innerViews.exportSettingsOrient).hide();

      $$(exportFormBottomID).show();
      $$('exportFilter').show();

      $$('exportZoomSlider').hide();
      $$('exportToggleLayout').hide();
      $$('exportCheckboxLayout').hide();
      $$('export_setting_task_name').hide();

      handler(true, exportType);
    }
  };

  const richSelectItems = [
    { id: 'pdf', value: __('export_setting_format_pdf') },
    { id: 'png', value: __('export_setting_format_png') },
  ];

  if (!gantt.config.multiview) {
    richSelectItems.push({ id: 'xml', value: __('export_setting_format_xml') });
  }

  richSelectItems.push({ id: 'xlsx', value: __('export_setting_format_xlsx') });

  return {
    paddingX: 18,
    css: 'export_top_form',
    autoheight: true,
    rows: [
      {
        view: 'richselectWithoutPoint',
        label: __('export_setting_format'),
        name: 'export_setting_format',
        id: 'export_setting_format',
        labelPosition: 'top',
        value: 'pdf',
        options: richSelectItems,
        on: {
          onBeforeRender() {
            if (!checkPricingAccess('overdue_tasks')) {
              $$('highlightOverdue').hide();
            }

            const hasRightWorkload = ((gantt.config.order && gantt.config.order.some(id => rights.project.hasRight(id, 'workload')))
              || rights.project.hasRight(gantt.config.gantt_id, 'workload'));

            if (!hasRightWorkload) {
              $$('changeWorkload').hide();
            }
          },

          onChange(newValue) {
            showFormElements(newValue);
          },
        },
      },
      { height: 12 },
      {
        view: 'richselectWithoutPoint',
        label: __(innerViews.exportSettingsSize),
        name: innerViews.exportSettingsSize,
        id: innerViews.exportSettingsSize,
        labelPosition: 'top',
        value: 'full',
        options: [
          { id: 'full', value: __('export_setting_size_full') },
          { id: 'Letter', value: __('export_setting_size_letter') },
          { id: 'Legal', value: __('export_setting_size_legal') },
          { id: 'Tabloid', value: __('export_setting_size_tabloid') },
          { id: 'Ledger', value: __('export_setting_size_ledger') },
          { id: 'A4', value: __('export_setting_size_a4') },
          { id: 'A3', value: __('export_setting_size_a3') },
          { id: 'A2', value: __('export_setting_size_a2') },
          { id: 'A1', value: __('export_setting_size_a1') },
          { id: 'A0', value: __('export_setting_size_a0') },
        ],
      },
      { height: 12 },
      {
        view: 'radio',
        vertical: false,
        optionHeight: 36,
        label: __(innerViews.exportSettingsOrient),
        name: innerViews.exportSettingsOrient,
        id: innerViews.exportSettingsOrient,
        labelPosition: 'top',
        value: 'landscape',
        css: 'export-setting-orient',
        options: [
          { id: 'landscape', value: __('export_setting_orient_landscape') },
          { id: 'portrait', value: __('export_setting_orient_portrait') },
        ],
      },
    ],
  };
};

const getBottomPartFrom = function () {
  return {
    paddingX: 18,
    id: exportFormBottomID,
    css: 'export_bottom_form',
    rows: [
      {
        id: 'exportFilter',
        rows: [
          {
            cols: [
              {},
              createButton(__('common_filter'), () => {
                exportComponent.sendMessageToExportFrame({ message: EVENTS.SHOW_FILTER });
              }, 'export-filter', function () {
                let labelHtml = `<div class='header-toolbar-button-label'>${this.label}</div>`;

                if (!this.label.length) {
                  labelHtml = '<div></div>';
                }

                return `<div class='header-toolbar-button ${'export-filter' ? 'export-filter' : ''}'>`
                  + `<div class='header-toolbar-button-icon'>${icon_filter}</div>${
                    labelHtml
                  }</div>`;
              }),
              {},
            ],
          },
        ],
      },
      {
        id: 'baselineSelectSeparator',
        height: 30,
      },
      {
        id: 'exportZoomSliderWithDots',
        css: 'zoom-slider-with-dots',
        rows: [
          {
            view: 'ganttSlider',
            css: 'header-toolbar-slider header-toolbar-slider-export',
            min: 0,
            max: 5,
            step: 1,
            title(obj) {
              return `${__('settings_zoom')}<span>${settingsZoom.zoomSliderOptions.modeToZoomTitle[obj.value]}</span>`;
            },
            id: 'exportZoomSlider',
            width: 266,
            height: 40,
            labelAlign: 'left',
            name: 'export_zoom',
            value: constants.ZOOM.modeToZoomValue[gantt.config.durationData.mode],
            on: {
              onChange: _.debounce((newValue, oldValue) => {
                exportComponent.sendMessageToExportFrame({ newValue, message: EVENTS.CHANGE_ZOOM });
              }, 10),
            },
          },
          {
            id: 'zoomSliderDots',
            height: 2,
            view: 'template',
            css: 'zoom-slider-dots',
            template: createZoomDots,
          },
        ],
      },
      {
        ...getStatusBaselineDropdown(),
      },
      { height: 18 },
      {
        view: 'richselectWithoutPoint',
        label: __('settings_task_name'),
        name: 'export_setting_task_name',
        id: 'export_setting_task_name',
        labelPosition: 'top',
        value: gantt.config.right_side_text,
        options: [
          { id: 'insideText', value: __('export_setting_inside_text') },
          { id: 'rightSideText', value: __('export_setting_right_side_text') },
          { id: 'noTaskText', value: __('export_setting_no_task_text') },
        ],
        on: {
          onChange(newValue) {
            sendMessageToFrom(newValue, EVENTS.TASK_NAME);
          },
        },
      },
      {
        id: 'exportToggleLayout',
        rows: [
          createToggleLayout(__('export_cross_out_tasks'), EVENTS.CROSS_OUT_TASKS),
          createToggleLayout(__('export_discoloration_tasks'), EVENTS.DISCOLORATION_TASKS),
          createToggleLayout(__('export_critical_way'), EVENTS.CRITICAL_PATH),
          createToggleLayout(__('export_current_day_marker'), EVENTS.CURRENT_DAY_MARKER),
          createToggleLayout(__('export_workload'), EVENTS.WORKLOAD),
          createToggleLayout(__('export_highlight_overdue'), EVENTS.OVERDUE),
        ],
        on: {
          onBeforeShow() {
            if (!checkPricingAccess('overdue_tasks')) {
              $$('highlightOverdue').hide();
            }
          },
          onBeforeRender() {
            if (!checkPricingAccess('overdue_tasks')) {
              $$('highlightOverdue').hide();
            }
          },
        },
      },
      {
        id: 'exportCheckboxLayout',
        rows: [
          createCheckboxLayout(),
        ],
      },
    ],
  };
};

export {
  createButton,
  getTopPartFrom,
  getBottomPartFrom,
};
