/* eslint-disable */
import app from '../../../app';
import _ from '../../../libs/lodash';

import projectsModel from '../../../models/projects';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from '../../../store/main';

import constants from '../../../helpers/constants';
import customHelper from '../../../helpers/custom';
import routerHelper from '../../../helpers/router';

import overload_icon from '../../../svg/overload_icon.svg';
import search_icon from '../../../svg/globalSearch/outline-search-24px.svg';
import massChangeModule from './massChange';
import { checkPricingAccess } from '../../../helpers/pricingHelper';
import rights from '../../../components/rights';
import helperDate from '../../../helpers/dateFormats';

const __ = window.__;

gantt.attachEvent('showResourcesListForTask', (pos, taskId, ganttId, taskSettingsOpened, css, callback) => {
  innerObject.settings.taskSettingsOpened = taskSettingsOpened;
  $$(innerObject.views.resourcesPreviewListPopup).hide();
  innerObject.settings.doNotShowPreview = true;
  innerObject.settings.resourcePopupTaskId = taskId;
  innerObject.settings.callback = callback;
  innerObject.handlers.showResourcesPopup(pos, taskId, ganttId, css);
});

gantt.attachEvent('hideResourcesListForTask', () => {
  $$(innerObject.views.resourcesListPopup).hide();
  $$(innerObject.views.resourcesPreviewListPopup).hide();
});

gantt.attachEvent('showResourcesPreviewListForTask', (target, taskId, ganttId) => {
  innerObject.settings.doNotShowPreview = false;
  $$(innerObject.views.resourcesPreviewListPopup).hide();
  innerObject.handlers.showResourcesPreviewPopup(target, taskId, ganttId);
});

gantt.attachEvent('hideResourcesPreviewListForTask', () => {
  $$(innerObject.views.resourcesPreviewListPopup).hide();
  innerObject.settings.doNotShowPreview = true;
});

const constantValues = {
  maxResourceVisibleItems: 8,
  minResourceVisibleItems: 2,
  resourceVisibleItems: 0,
  resourcePopupTopPossition: false,
};

var innerObject = {
  views: {
    resourcesListPopup: 'resourcesSelectListPopup',
    resourcesList: 'resourcesSelectList',
    resourcesPreviewListPopup: 'resourcesPreviewListPopup',
    resourcesPreviewList: 'resourcesPreviewList',
    resourceSearchInput: 'resourceSearchInput',
    noMatchesPopupMessage: 'noMatchesPopupMessage',
    resourcePopupButtons: 'resourcePopupButtons',
    resourcePopupButtonOk: 'resourcePopupButtonOk',
    resourcePopupButtonSpacer: 'resourcePopupButtonSpacer',
  },
  init: {
    run() {
      // const projectData = projectsModel.getActiveProjectData();
      // let resources = [];
      //
      // if (gantt.config.isJira) {
      //   resources = _.orderBy(resourcesModel.getMaterialResourcesForRichSelect(gantt.config.gantt_id), 'name');
      // } else {
      //   resources = _.orderBy(resourcesModel.getResourcesForMultiSelect(gantt.config.gantt_id), 'name');
      // }

      innerObject.init.initResourcesGrid();
      // gantt.config.isJira && innerObject.init.initOwnersGrid(_.cloneDeep(resources));
    },
    afterInit() {
      if (!innerObject.settings.modelEventListenerInit) {
        innerObject.modelEvents.resources.init();
        innerObject.modelEvents.tasks.init();
      }
    },
    initResourcesGrid() {
      const projectConfig = ganttViewModel.getProjectConfig();
      const initCustomSizes = projectConfig.initGridColumns;

      gantt.config.columns.push({
        align: 'center',
        label: __('gantt_grid_assigned'),
        name: 'resource_id',
        min_width: initCustomSizes.sizes.resource_id.min_width,
        width: initCustomSizes.sizes.resource_id.width,
        resize: true,
        hide: false,
        isShowCheckbox: true,
        order: 2,
        control_type: 'multiselect',
        task_types: ['task', 'milestone'],
        template: innerObject.init.showUsersInGrid,
      });

      gantt.config.columns = _.orderBy(gantt.config.columns, 'order');

      // innerObject.helpers.setResourcesOptionsInSettings();
    },
    initOwnersGrid(resources) { // for jira
      const projectConfig = ganttViewModel.getProjectConfig();
      const initCustomSizes = projectConfig.initGridColumns;

      gantt.config.columns.push({
        align: 'center',
        label: __('gantt_grid_owner_id'),
        name: 'owner_id',
        min_width: initCustomSizes.sizes.owner_id.min_width,
        width: initCustomSizes.sizes.owner_id.width,
        resize: true,
        hide: true,
        isShowCheckbox: true,
        order: 10,
        control_options: resources,
        task_types: ['task', 'milestone'],
        template: innerObject.init.showOwnersInGrid,
      });

      gantt.config.columns = _.orderBy(gantt.config.columns, 'order');

      // innerObject.helpers.setResourcesOptionsInSettings();
    },
    showUsersInGrid(item, isBaseline) {
      if (!innerObject.init.helpers.isTask(item.type)) {
        return '';
      }

      let resourceData = [];

      if (isBaseline && item) {
        resourceData = item.resources.map(resource => globalStore.getters['resourcesModel/getResourceById'](resource.resource_id));
      } else {
        resourceData = globalStore.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](item.gantt_id, item.id);
      }

      resourceData = _.compact(resourceData);// clean undefined in array, case when resource was removed but assign is present

      const count = resourceData.length;
      let html = '<div class="resource_list_in_grid resource-in-grid">';

      if (count) {
        let more = 0;

        if (count === 1) {
          html += `${innerObject.helpers.getTemplateForResource(resourceData[0])
          }<span class="resource_list_item">${resourceData[0].name}</span>`;
        } else {
          _.each(resourceData, (resource, i) => {
            if (count < 4) {
              html += innerObject.helpers.getTemplateForResource(resource);
            } else if (i < 2) {
              html += innerObject.helpers.getTemplateForResource(resource);
            } else if (!more) {
              more = count - 2;
              html += `<span class="resource_list_item_more">+${more}</span>`;
            }
          });
        }

        html += '</div>';

        return html;
      }

      return `${'<div class="resource-in-grid resource_list_in_grid unassigned">'
          + '<div class="resource_list_item_icon "></div>'
          + '<div class="resource_list_item">'}${__(constants.COMMON_EMPTY_RESOURCE)}</div>`
          + '</div>';
    },
    showOwnersInGrid(item) { // for jira
      if (!innerObject.init.helpers.isTask(item.type)) {
        return '';
      }

      const resourceData = globalStore.getters['resourcesModel/getResourceById'](item.owner_id);

      if (resourceData) {
        // live update
        item.owner_id = resourceData.id;

        return resourceData.name;
      }

      const resourcesData = globalStore.getters['resourcesModel/getResourcesByProjectId'](gantt.config.gantt_id);

      return innerObject.init.helpers.findOwner(item, resourcesData).name || __(constants.COMMON_EMPTY_RESOURCE);
    },
    helpers: {
      findOwner(item, resources) { // for jira
        return _.find(resources, (resourceItem => {
          if (parseInt(resourceItem.id, 10) === parseInt(item.owner_id, 10)) {
            return true;
          }
        })) || {};
      },
      isTask(itemType) {
        return (gantt.config.types.project !== itemType && gantt.config.types.button !== itemType);
      },
    },
  },
  handlers: {
    showResourcesPopup(pos, taskId, ganttId, css) {
      if (!pos || !taskId || routerHelper.isProfileSettings()) {
        return;
      }

      const resourcesPopup = $$(innerObject.views.resourcesListPopup);
      const resourcesList = $$(innerObject.views.resourcesList);

      innerObject.settings.doNotShowPreview = true;

      if (resourcesPopup.isVisible()) {
        resourcesPopup.hide();

        return;
      }

      if (!ganttId) {
        const task = gantt.getTask(taskId);

        ganttId = task.gantt_id;
      }

      const resourcesData = innerObject.helpers.prepareResourcesForRender(taskId, ganttId, false);

      if (!resourcesData) {
        return;
      }

      const isAssignedTimeType = !!_.filter(resourcesData, item => item.selected && item.type === constants.RESOURCE_TIME_TYPE).length;

      innerObject.settings.currentTaskId = taskId;
      innerObject.settings.currentGanttId = ganttId;
      innerObject.settings.currentTaskData = globalStore.getters['tasksModel/getTaskByGanttId'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);

      resourcesList.clearAll();
      resourcesList.parse(resourcesData);

      innerObject.helpers.prepareIfEmptyData(resourcesData.length);

      resourcesPopup.getNode().setAttribute('data-predessor', css || '');
      const resourcesPopupGetData = resourcesPopup.getNode().getAttribute('data-predessor');
      const isTaskView = resourcesPopupGetData === 'taskView';
      const isGridView = resourcesPopupGetData === 'gridView';
      const isListView = resourcesPopupGetData === 'listView';
      const showPopupPosition = { x: pos.x - 9, y: pos.y + pos.height * 2 };

      if (isListView) {
        showPopupPosition.y += -12;
      }

      innerObject.helpers.defineResourceListMaxElements(pos, resourcesList, isTaskView);
      resourcesPopup.show(showPopupPosition);

      if (isTaskView) {
        userExtAnalytics.log('task_settings_action', { action: 'assigned', type: 'focus' });
      }
      if (isGridView) {
        userExtAnalytics.log('task_grid_action', { action: 'assigned', type: 'focus' });
      }

      const docHeight = document.body.clientHeight;
      const bottomHeight = docHeight - pos.bottom;
      const popupHeight = resourcesPopup.$height;

      if (!isTaskView) {
        if (bottomHeight < popupHeight) {
          resourcesPopup.config.top = pos.y - popupHeight - 2;
          innerObject.helpers.resourcePopupTopPos(true);
          constantValues.resourcePopupTopPossition = true;
        } else {
          resourcesPopup.config.top = pos.bottom;
          innerObject.helpers.resourcePopupTopPos(false);
          constantValues.resourcePopupTopPossition = false;
        }
      } else {
        resourcesPopup.config.top = pos.y + pos.height;
        innerObject.helpers.resourcePopupTopPos(false);
        constantValues.resourcePopupTopPossition = false;
      }

      resourcesPopup.resize();

      innerObject.helpers.showAddMoreButton();
      innerObject.handlers.showUnassignedButton(resourcesData);
      innerObject.helpers.resetResourceSearchState();
    },
    showResourcesPreviewPopup: _.debounce((pos, taskId, ganttId) => {
      if (innerObject.settings.doNotShowPreview) {
        return;
      }

      const previewPopup = $$(innerObject.views.resourcesPreviewListPopup);
      const list = $$(innerObject.views.resourcesPreviewList);
      const task = globalStore.getters['tasksModel/getTask'](taskId);

      ganttId = ganttId || task.gantt_id;
      const resourcesData = innerObject.helpers.prepareResourcesForRender(taskId, ganttId || gantt.config.gantt_id, true);

      if (!resourcesData || innerObject.settings.resourcePopupTaskId === taskId) {
        return;
      }

      list.clearAll();

      if (resourcesData.length) {
        innerObject.settings.currentTaskId = taskId;
        innerObject.settings.currentGanttId = ganttId || gantt.config.gantt_id;
        list.parse(resourcesData);

        previewPopup.show();// needs for recalculate top after resizing
        previewPopup.hide();
        pos.x -= 8;
        previewPopup.show(pos, { pos: 'top' });
      }
    }, 1000),
    updateTaskResources(saveMode) {
      const resourcesListData = _.filter(innerObject.settings.shownResources, item => !_.isUndefined(item.userId));

      const taskData = globalStore.getters['tasksModel/getTaskByGanttId'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);

      resourcesListData.forEach(newResource => {
        const currentResource = innerObject.settings.currentTaskResources.find(item => item.id === newResource.id);

        if (!newResource.value) {
          newResource.value = 0;
        }

        if (currentResource.selected !== newResource.selected) {
          newResource.action = newResource.selected ? 'insert' : 'delete';
        } else if (+currentResource.value !== +newResource.value && currentResource.selected) {
          newResource.action = 'update';

          if (newResource.type === constants.RESOURCE_TIME_TYPE) {
            const taskResourcesData = globalStore.getters['tasksModel/getResourcesForTask'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);
            const taskResourceData = taskResourcesData.find(taskResource => newResource.id === taskResource.resource_id);


            newResource.customEstimation = [{
              resource_task_id: taskResourceData.resource_tasks_id,
              action: 'delete',
            }];
          }
        }
      });

      const changedResources = resourcesListData.filter(item => !_.isUndefined(item.action));

      if (changedResources.length /* || !saveMode */) {
        resourcesListData.forEach( item => {
          item.action = item.selected ? 'insert' : 'delete';
        })

        globalStore.dispatch('tasksModel/changeAssign', {
          changedResources: resourcesListData,
          taskId: innerObject.settings.currentTaskId,
          ganttId: innerObject.settings.currentGanttId
        }).then(() => {
          if (innerObject.settings.taskSettingsOpened) {
            app.trigger('taskSettings:updateAssignedView');
          } else {
            gantt.callEvent('refreshTaskEditors');
            // gantt.refreshTask(taskData.id);
          }

          const resourcesPopup = $$(innerObject.views.resourcesListPopup);
          const resourcesPopupGetData = resourcesPopup.getNode().getAttribute('data-predessor');
          const isTaskView = resourcesPopupGetData === 'taskView';
          const isGridView = resourcesPopupGetData === 'gridView';

          if (isTaskView) {
            userExtAnalytics.log('task_settings_action', { action: 'assigned', type: 'change' });
          }
          if (isGridView) {
            userExtAnalytics.log('task_grid_action', { action: 'assigned', type: 'change' });
          }
        });
      }
    },
    updateResourcesAfterChangeEstimation(task, sendTaskUpdate) {
      const newTaskEstimation = task.estimation;
      let oldTaskEstimation = 0;
      const taskResources = innerObject.helpers.getTimeTypeTaskResources(task);
      const promise = Promise.resolve();

      if (!taskResources.length) {
        return promise;
      }

      taskResources.forEach(resource => {
        oldTaskEstimation += resource.value;
      });

      if (newTaskEstimation === oldTaskEstimation) {
        return promise;
      }

      const calculatedData = gantt.estimationCalculator.calculateTaskResourcesAfterChangeEstimation(task, taskResources);

      return globalStore.dispatch('tasksModel/changeAssign', {
        changedResources: calculatedData.taskResources,
        taskId: task.id,
        ganttId: task.gantt_id,
        taskChanges: calculatedData.taskChanges
      }).then(() => {
          gantt.callEvent('refreshTaskEditors');
          // gantt.refreshTask(task.id);
        });
    },
    updateResourcesAfterChangeTaskToMilestone(task) {
      const changedResources = [];
      const taskResources = innerObject.helpers.getTimeTypeTaskResources(task);

      if (!taskResources.length) {
        return;
      }

      _.each(taskResources, resource => {
        changedResources.push({
          userId: resource.user_id,
          action: 'update',
          id: resource.resource_id,
          value: 0,
          customEstimation: [{
            resource_task_id: resource.id,
            action: 'delete',
          }],
        });
      });

      globalStore.dispatch('tasksModel/changeAssign', {
        changedResources,
        taskId: task.id,
        ganttId: task.gantt_id
      });
    },
    updateResourcesAfterChangeDuration(task, oldTaskData) {
      const taskResources = innerObject.helpers.getTimeTypeTaskResources(task);

      if (!taskResources.length) {
        return;
      }

      const calculatedData = gantt.estimationCalculator.calculateTaskResourcesAfterChangeDuration(task, oldTaskData.duration, taskResources);

      if (calculatedData.taskResources.length) {
        let taskChanges = gantt.estimationCalculator.calculateTaskChangesAfterAssign(task, taskResources);

        // duration already changed
        if (taskChanges) {
          delete taskChanges.duration;
          delete taskChanges.end_date;
        }

        globalStore.dispatch('tasksModel/changeAssign', {
          changedResources: calculatedData.taskResources,
          taskId: task.id,
          ganttId: task.gantt_id,
          taskChanges,
        });
      }
    },
    setFocusInValueInput(item, id) {
      const list = $$(innerObject.views.resourcesList);
      const input = list.getItemNode(id).querySelector('input[type="text"]');
      const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](id, innerObject.settings.currentGanttId);
      const projectConfig = projectsModel.getProjectConfig(innerObject.settings.currentGanttId);
      const isResourceTime = resourceTypeOnProject === constants.RESOURCE_TIME_TYPE;

      if (!input) {
        return;
      }

      input.classList.add('mousetrap');

      if (item.selected && !(+projectConfig.estimation_mode === gantt.estimationCalculator.MODES.fixEstimation && isResourceTime) && checkPricingAccess('estimation')) {
        input.closest('.input_box').classList.remove('disabled');
        input && input.select();
      } else {
        input.closest('.input_box').classList.add('disabled');
        if (!checkPricingAccess('estimation')) {
          const fields = list.getItemNode(id).querySelectorAll('input[type="text"]');

          fields.forEach(field => {
            field.disabled = true;
          });
        }
      }
    },
    changeResources(node, value) {
      if (!projectsModel.getActiveProjectData()) {
        return;
      }

      // innerObject.helpers.setResourcesOptionsInSettings();

      // !gantt.isEditingState() && gantt.callEvent('ganttRender'); ???
    },
    selectInResourceList(id) {
      const list = $$(innerObject.views.resourcesList);

      if (!innerObject.helpers.isDividerItem(id)) {
        if (gantt.config.isJira) {
          innerObject.helpers.unselectForJiraProjects(list, id);
        }

        const item = list.getItem(id);

        item.selected = +!item.selected;
        list.updateItem(id, item);

        innerObject.helpers.calculateEstimationForResource(item);
        innerObject.handlers.setFocusInValueInput(item, id);

        innerObject.handlers.showUnassignedButton(list.serialize());
      }
    },
    deleteResourceFromTask(taskData) {
      //
    },
    unselectAllResources() {
      const resourcesList = $$(innerObject.views.resourcesList);
      const listData = innerObject.settings.shownResources;

      _.each(listData, item => {
        if (item.selected) {
          item.selected = 0;
        }
      });

      const sortedResourcesData = innerObject.helpers.createSortedResourcesSet(listData);

      resourcesList.clearAll();
      resourcesList.parse(sortedResourcesData);
      innerObject.helpers.resetResourceSearchState();
    },
    showUnassignedButton(resources) {
      if (!_.find(resources, i => i.selected)) {
        $$('unassignedButton').hide();
      } else {
        $$('unassignedButton').show();
      }
    },
  },
  helpers: {
    resourcePopupTopPos(topPosition) {
      const resourcesPopup = $$(innerObject.views.resourcesListPopup);

      if (topPosition) {
        resourcesPopup.getNode().classList.add('suggest_popup');
        resourcesPopup.getNode().classList.add('suggest_popup_top');
      } else {
        resourcesPopup.getNode().classList.remove('suggest_popup');
        resourcesPopup.getNode().classList.remove('suggest_popup_top');
      }
    },
    prepareResourcesForRender(taskId, ganttId, isForPreview) {
      const task = globalStore.getters['tasksModel/getTaskByGanttId'](ganttId, taskId);

      if (!task) {
        return false;
      }

      const taskResources = globalStore.getters['tasksModel/getResourcesForTask'](task.gantt_id, taskId);
      let resources = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](task.gantt_id)

      if (gantt.config.isJira) {
        resources = resources.filter(o => !o.userId);
      }

      let reducedResources = _.reduce(resources, (arr, resource) => {
        const taskResource = _.filter(taskResources, res => res.resource_id === resource.id)[0];

        resource.resource_id = resource.id;
        resource.selected = taskResource ? 1 : 0;
        resource.value = String((taskResource && taskResource.value) ? taskResource.value : 0);
        resource.type_text = __(innerObject.helpers.getLocaleForResourceType(resource.type));

        if (!isForPreview || (isForPreview && taskResource)) {
          arr.push(resource);
        }

        return arr;
      }, []);

      reducedResources = _.orderBy(reducedResources, ['selected', 'name'], ['desc', 'asc']);

      if (!isForPreview) {
        innerObject.settings.currentTaskResources = _.cloneDeep(reducedResources);
        innerObject.settings.shownResources = reducedResources;
      }

      return innerObject.helpers.createSortedResourcesSet(reducedResources);
    },
    createSortedResourcesSet(reducedResources) {
      const groupedObj = _.groupBy(reducedResources, item => (_.isNull(item.userId) ? 'material' : 'people'));

      const peopleHeader = groupedObj.people ? [
        {
          id: 'divider__people',
          label: __('resources_layout_header_title_1'),
        },
      ] : [];

      const materialHeader = groupedObj.material ? [
        {
          id: 'divider_material',
          label: __('resources_layout_header_title_2'),
        },
      ] : [];

      return [
        ...peopleHeader,
        ...groupedObj.people || [],
        ...materialHeader,
        ...groupedObj.material || [],
      ];
    },
    calculateEstimationForResource(lastItem) {
      const task = globalStore.getters['tasksModel/getTaskByGanttId'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);
      const list = $$(innerObject.views.resourcesList);
      const taskDurationInHours = task.duration / 60;
      const itemsForUpdate = _.filter(list.serialize(), item => item.selected && item.type === constants.RESOURCE_TIME_TYPE);

      if (task.estimation) {
        _.each(itemsForUpdate, (item, i) => {
          item.value = gantt.estimationCalculator.preCalculateEstimationForResource(task, itemsForUpdate.length, itemsForUpdate.length === i + 1);
          list.updateItem(item.id, item);
        });
      } else if (lastItem.selected && lastItem.type === constants.RESOURCE_TIME_TYPE) {
        lastItem.value = gantt.estimationCalculator.preCalculateEstimationForResource(task, itemsForUpdate.length);
        list.updateItem(lastItem.id, lastItem);
      }
    },
    async updateTasksAfterChangeResource(resourceId, ganttId, action) {
      const tasks = globalStore.getters['tasksModel/getTasksByResourceIdAndGanttId'](resourceId, ganttId).map(t => t.taskData);
      const tasksResourcesMap = {};
      const isRemoveResource = ['removeResourceFromProject', 'removeResource'].includes(action);
      const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](resourceId, ganttId);
      const isNotTimeTypeResource = resourceTypeOnProject !== constants.RESOURCE_TIME_TYPE;

      action = isRemoveResource ? 'remove' : 'update';

      if (isRemoveResource && isNotTimeTypeResource) {
        return;
      }

      tasks.forEach(task => {
        tasksResourcesMap[task.id] = innerObject.helpers.getTimeTypeTaskResources(task);

        if (isNotTimeTypeResource && tasksResourcesMap[task.id].length) {
          tasksResourcesMap[task.id].push({
            isNotTimeType: true,
            ...task.resources.find(res => res.resource_id === resourceId),
          });
        }
      });

      const calculatedDataForMassChange = gantt.estimationCalculator.calculateAfterChangeResourceOnProject(tasks, tasksResourcesMap, resourceId, action);

      if (calculatedDataForMassChange) {
        const queryResult = await massChangeModule._api.execMassChange(calculatedDataForMassChange, ganttId, true);
        const { resources, actions: { succeeded } } = queryResult;

        await massChangeModule._processQueryActionsRes(queryResult.ganttId, succeeded, resources);
      }
    },
    getLocaleForResourceType(type) {
      switch (type) {
      case constants.RESOURCE_TIME_TYPE:
        return 'resource_time_type';
      case constants.RESOURCE_ITEM_TYPE:
        return 'resource_item_type';
      case constants.RESOURCE_COST_TYPE:
        return 'resource_cost_type';
      }
    },
    getTemplateForResource(resource) {
      return `<span class="resource_list_item_icon" style="background-image: url(${
        resource.photo || resource.resourcePhoto || (`${GT.cdn}/imgs/default/default-ava.svg`)})"></span>`;
    },
    hideValuesInTemplates(resource) {
      const task = globalStore.getters['tasksModel/getTaskByGanttId'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);

      return (resource.type === constants.RESOURCE_COST_TYPE
        && !rights.project.hasCostRight(gantt.config.gantt_id))
        || (task.type === gantt.config.types.milestone
          && resource.type === constants.RESOURCE_TIME_TYPE);
    },
    showAddMoreButton() {
      const button = $$('addMorePeopleButtonInAssign');
      const buttonEmpty = $$('addMorePeopleButtonEmpty');
      const hasAccessToResources = rights.project.hasRight(gantt.config.gantt_id, 'resources_page');

      if (gantt.config.multiview || gantt.config.isJira || !hasAccessToResources || routerHelper.isListViewRoute() || routerHelper.isWorkloadViewRoute() ||
        routerHelper.getCurrentRoute().path.includes('/comments')) {
        button.hide();
        buttonEmpty.show();
      } else {
        button.show();
        buttonEmpty.hide();
      }
    },
    prepareIfEmptyData(itemsNumber) {
      const emptyAssignPopupText = $$('emptyAssignPopupText');
      const resourcesList = $$(innerObject.views.resourcesList);
      const resourcePopupButtonOk = $$(innerObject.views.resourcePopupButtonOk);
      const resourcePopupButtonSpacer = $$(innerObject.views.resourcePopupButtonSpacer);

      if (!itemsNumber) {
        resourcesList.hide();
        emptyAssignPopupText.show();
        resourcePopupButtonOk.hide();
        resourcePopupButtonSpacer.show();
      } else {
        resourcesList.show();
        emptyAssignPopupText.hide();
        resourcePopupButtonOk.show();
        resourcePopupButtonSpacer.hide();
      }
    },
    defineSearchVisibilite(itemsNumber) {
      const resourceSearchContainer = $$('resourceSearchContainer');

      if (itemsNumber > constantValues.resourceVisibleItems) {
        resourceSearchContainer.show();
      } else {
        resourceSearchContainer.hide();
      }
    },
    prepareIfNoMatches(state) {
      const resourcesList = $$(innerObject.views.resourcesList);
      const resourcePopupButtonOk = $$(innerObject.views.resourcePopupButtonOk);
      const resourcePopupButtonSpacer = $$(innerObject.views.resourcePopupButtonSpacer);
      const noMatchesPopupMessage = $$(innerObject.views.noMatchesPopupMessage);
      const resourcePopupButtons = $$(innerObject.views.resourcePopupButtons);
      const resourcesListPopup = $$(innerObject.views.resourcesListPopup);
      const currentPopupView = resourcesListPopup.getNode().getAttribute('data-predessor');

      if (!state) {
        resourcesList.hide();
        resourcePopupButtonOk.hide();
        noMatchesPopupMessage.show();
        resourcePopupButtonSpacer.show();
        if (currentPopupView) {
          resourcePopupButtons.hide();
        }
      } else {
        resourcesList.show();
        noMatchesPopupMessage.hide();
        resourcePopupButtonOk.show();
        resourcePopupButtonSpacer.hide();
        if (currentPopupView) {
          resourcePopupButtons.show();
        }
      }
      innerObject.handlers.showUnassignedButton(resourcesList.serialize());
    },
    getTimeTypeTaskResources(task) {
      if (!task.resources) {
        return [];
      }

      return task.resources.filter(resource => {
        const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](resource.resource_id, task.gantt_id);

        return resourceTypeOnProject === constants.RESOURCE_TIME_TYPE;
      }).map(resource => {
        const resourceData = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);

        return {
          ...resource,
          customDays: resourceData.customDays,
          workingDays: resourceData.workingDays,
          workingHours: resourceData.workingHours,
          // resourceCalendar: resourcesWorkTimeModel.getWorkTimeByResourceId(resource.resource_id) || resourcesWorkTimeModel.getDefaultWorkTime(),
          // resourceCustomDays: customDaysResourcesModel.getCustomDaysByResourceId(resource.resource_id),
        }
      });
    },
    unselectForJiraProjects(list, current_id) {
      _.each(list.serialize(), i => {
        if (i.id !== constants.COMMON_EMPTY_RESOURCE && !innerObject.helpers.isDividerItem(i.id) && i.id !== +current_id) {
          const item = list.getItem(i.id);

          item.selected = 0;

          list.updateItem(i.id, item);
        }
      });
    },
    // setResourcesOptionsInSettings() {
    //   innerObject.settings.resources = globalStore.getters['resourcesModel/getResourcesByProjectId'](gantt.config.gantt_id);
    // },
    isDividerItem(id) {
      return _.isString(id) && id.includes('divider');
    },
    resetResourceSearchState() {
      const resourceSearchInput = $$(innerObject.views.resourceSearchInput);

      $$(innerObject.views.resourcePopupButtons).show();
      $$(innerObject.views.noMatchesPopupMessage).hide();
      resourceSearchInput.setValue('');
      resourceSearchInput.focus();
    },
    defineResourceListMaxElements(pos, resourcesList, isTaskView = false) {
      const topBottomPadding = 10;
      const constantHeight = 120;
      const elemsNumber = resourcesList.count();
      const listItemHeight = resourcesList.config.type.height;
      const docHeight = document.body.clientHeight;

      const topHeight = pos.y;
      const bottomHeight = docHeight - pos.bottom;

      const topFinalHeight = topHeight - constantHeight - topBottomPadding;
      const bottomFinalHeight = bottomHeight - constantHeight - topBottomPadding;

      const topElems = Math.floor(topFinalHeight / listItemHeight);
      const bottomElems = Math.floor(bottomFinalHeight / listItemHeight);
      const resultElements = isTaskView
        ? Math.min(bottomElems, constantValues.maxResourceVisibleItems)
        : Math.min(constantValues.maxResourceVisibleItems, Math.max(topElems, bottomElems));

      constantValues.resourceVisibleItems = resultElements > 0 ? resultElements : constantValues.minResourceVisibleItems;
      innerObject.helpers.defineSearchVisibilite(elemsNumber);
    },
  },
  settings: {
    currentTaskData: null,
    currentTaskResources: [],
    currentTaskId: 0,
    modelEventListenerInit: false,
    updateResource: false,
    taskSettingsOpened: false,
    isHandledEstimation: false,
    callback: null,
    closeAndSave: true,
  },
  modelEvents: {
    resources: {
      init() {
        innerObject.settings.modelEventListenerInit = true;

        // resourcesModel.data.attachEvent('onStoreUpdated', (id, resourceData, mode, ganttId) => {
        //   if (ganttId === gantt.config.gantt_id) {
        //     innerObject.handlers.changeResources();
        //   }
        // });
      },
    },
    tasks: {
      init() {
      },
    },
  },
};

const outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit,
  },
  handlers: {
    deleteResourceFromTask: innerObject.handlers.deleteResourceFromTask,
  },
  helpers: {
    getTemplateForResource: innerObject.helpers.getTemplateForResource,
  },
  getTimeTypeTaskResources: innerObject.helpers.getTimeTypeTaskResources,
};

webix.ui({
  view: 'popupWithoutPointCustom',
  id: innerObject.views.resourcesListPopup,
  css: 'resource_multiselect_popup',
  padding: 0,
  body: {
    rows: [
      {
        id: 'resourceSearchContainer',
        height: 48,
        rows: [
          { height: 12 },
          {
            cols: [
              { width: 8 },
              {
                borderless: true,
                view: 'template',
                width: 36,
                height: 36,
                paddingX: 6,
                paddingY: 6,
                template: () => `<div style="width: 100%; height: 100%; padding: 6px 0;">${search_icon}</div>`,
              },
              {
                view: 'text',
                css: 'resource-search-input',
                id: 'resourceSearchInput',
                placeholder: __('resource_search_input_placeholder'),
                on: {
                  onKeyPress(key, e) {
                    _.delay(() => {
                      const text = e.target.value;
                      const resourcesList = $$(innerObject.views.resourcesList);
                      const resourceListPopup = $$(innerObject.views.resourcesListPopup);
                      const resourcePopupHeight = resourceListPopup.$height;

                      const resourcesData = innerObject.settings.shownResources.filter(elem => elem.name.toLowerCase().includes(text.toLowerCase()));

                      const sortedResourcesData = innerObject.helpers.createSortedResourcesSet(resourcesData);

                      resourcesList.clearAll();
                      resourcesList.parse(sortedResourcesData);
                      resourceListPopup.resize();
                      innerObject.helpers.prepareIfNoMatches(sortedResourcesData.length);

                      if (constantValues.resourcePopupTopPossition) {
                        const resourcePopupPositionDiff = resourceListPopup.$height - resourcePopupHeight;

                        resourceListPopup.config.top = resourceListPopup.config.top - resourcePopupPositionDiff;
                        resourceListPopup.resize();
                      }
                    }, 1);
                  },
                },
              },
              { width: 8 },
            ],
          },
        ],
      },
      { height: 12 },
      {
        view: 'activeList',
        id: innerObject.views.resourcesList,
        borderless: true,
        css: 'resource_multiselect_list',
        navigation: false,
        type: {
          height: 36,
        },
        template(obj, common) {
          let result = '';

          if (innerObject.helpers.isDividerItem(obj.id)) {
            result += `
              <div class="list-group-item">
                  <div class="group-item-container">
                      <span class="group-header-caption">
                          ${obj.label}
                      </span>
                      <span class="group-header-underline"></span>
                  </div>
              </div>;`;

            return result;
          }

          result += '<div class="list_item">';

          if (!obj.selected) {
            obj.value = '0';
          }

          const isOverloaded = !!gantt._getOverloadedResources({ taskId: innerObject.settings.currentTaskData.id, resourceId: obj.id });
          const taskDurationInHours = innerObject.settings.currentTaskData.duration / 60;
          // const settings = resourcesModel.getSettingsForResource(obj.id, innerObject.settings.currentGanttId);
          const projectConfig = projectsModel.getProjectConfig(innerObject.settings.currentGanttId);
          const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](obj.id, innerObject.settings.currentGanttId);
          const isResourceTime = resourceTypeOnProject === constants.RESOURCE_TIME_TYPE;

          let isTooltip = false;
          const blockInputPerDay = +projectConfig.estimation_mode === gantt.estimationCalculator.MODES.fixEstimation && isResourceTime;

          const defaultHours = gantt.getDefaultWorkHoursPerDay(null, helperDate.getHoursArrayByUserTimeFormat());
          const workDaysCount = _.floor(taskDurationInHours / defaultHours, 2);

          if (isResourceTime) {
            // const resourceData = resourcesModel.getDataByTaskIdAndResourceId(innerObject.settings.currentTaskId, obj.id, innerObject.settings.currentGanttId);
            const taskResourcesData = globalStore.getters['tasksModel/getResourcesForTask'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);
            const taskResourceData = taskResourcesData.find(taskResource => obj.id === taskResource.resource_id);

            if (taskResourceData && taskResourceData.custom_days.length) {
              isTooltip = true;
            }

            obj.valuePerDay = gantt.estimationCalculator.calculateEstimationPerDay(innerObject.settings.currentTaskData, taskResourceData, obj.value);
          }

          const tooltipRow = isTooltip ? '<span class="tooltip_box tooltip-gantt" data-key="resource_has_customs"></span>' : '';
          const overloadBox = isOverloaded ? `<span class="overload_box tooltip-gantt" data-key="overload_tooltip_assign">${overload_icon}</span>` : '';
          const checkBox = common.selected(obj, common);
          const inputBox = common.value(obj, common);
          const inputBoxPerDay = isResourceTime ? common.valuePerDay(obj, common) : '';
          const typeBox = obj.type_text;
          const typeBoxType = obj.type;
          const resourcesName = obj.name.replace(/>/g, '&gt;').replace(/</g, '&lt;');
          const typeBoxTypeCost = typeBoxType === 3 ? 'cost' : '';
          const typeBoxTypePerItem = typeBoxType === 2 ? 'per-item' : '';
          const labelDaysBox = isResourceTime ? `${workDaysCount} ${__('resource_all_days')}` : '';
          const labelTypeBox = isResourceTime ? (gantt.config.resource_loading_type === 'percents' ? __('resource_percents_per_day') : __('resource_per_day')) : '';
          const infoBox = `<span class="resource_list_item_icon" style="background-image: url(${obj.picture})"></span>`
            + `<span class="resource_list_item" title="${resourcesName}">${resourcesName}</span>`;

          result += `<span class="select_box">${checkBox}</span>`
            + `<span class="info_box">${infoBox}</span>`;

          if (!innerObject.helpers.hideValuesInTemplates(obj)) {
            if (isResourceTime) {
              if (checkPricingAccess('estimation')) {
                result += `<span class="input_box${obj.selected && !blockInputPerDay ? '' : ' disabled'}">${inputBoxPerDay}</span>`
                  + `<span class="label_box per-day">${labelTypeBox}</span>`;
                result += `<span class="input_box${obj.selected ? '' : ' disabled'}">${inputBox}</span>`
                  + `<span class="type_box">${typeBox}</span>` + `<span class="label_box all-day">${labelDaysBox}</span>${tooltipRow}${overloadBox}`;
              } else {
                result += `<div class="pricing-tooltip disable-block" data-position="top" data-additional-dif="${obj.id}" data-feature="estimation_assign" >` + `<span class="input_box disabled">${inputBoxPerDay}</span>`
                  + `<span class="label_box per-day">${labelTypeBox}</span>`;
                result += `<span class="input_box disabled">${inputBox}</span>`
                  + `<span class="type_box">${typeBox}</span>` + `<span class="label_box all-day">${labelDaysBox}</span>${tooltipRow}${overloadBox}</div>`;
              }
            } else {
              if (projectConfig.auto_budget || typeBoxTypePerItem) {
                result += `<span class="input_box${obj.selected ? '' : ' disabled'}">${inputBox}</span>`
                + `<span class="type_box ${typeBoxTypeCost}${typeBoxTypePerItem}">${typeBox}</span>` + `<span class="label_box all-day">${labelDaysBox}</span>`;
              }
            }
          }

          result += '</div>';

          return result;
        },
        on: {
          onBeforeRender(data) {
            const length = data.serialize().length;
            const visibleCount = constantValues.resourceVisibleItems;
            let yCount = length;
            const buttons = $$(innerObject.views.resourcePopupButtons).getNode().classList;

            if (length > visibleCount) {
              yCount = visibleCount;
              buttons.add('scroll');
            } else {
              buttons.remove('scroll');
            }

            this.define('yCount', yCount);

            this.resize();
          },
          onAfterScroll() {
            const pos = this.getScrollState();
            const length = this.serialize().length;
            const visibleCount = 8;
            const buttons = $$(innerObject.views.resourcePopupButtons).getNode().classList;

            if (pos.y > 0) {
              buttons.add('in_scroll');
            } else {
              buttons.remove('in_scroll');
            }

            if (pos.y === (length - visibleCount) * this.config.type.height) {
              buttons.add('in_bottom');
            } else {
              buttons.remove('in_bottom');
            }
          },
          onBeforeSelect(id) {
            if (id === 'add' || innerObject.helpers.isDividerItem(id)) {
              return false;
            }
          },
          onAfterSelect(id) {
            const list = $$(innerObject.views.resourcesList);
            const item = list.getItem(id);

            this.showItem(id);

            _.delay(() => {
              item.selected && innerObject.handlers.setFocusInValueInput(item, id);
            });
          },
          onItemClick(id, e, node) {
            if (innerObject.settings.blockItemClick) {
              innerObject.settings.blockItemClick = false;

              return false;
            }
            innerObject.handlers.selectInResourceList(id);
          },
          onItemRender() {
            _.delay(() => {
              const parent = $$(innerObject.views.resourcesList).getNode();
              const fields = parent.querySelectorAll('input[type="text"]');

              fields.forEach(field => {
                field.onmousedown = function (e) {
                  innerObject.settings.blockItemClick = true;
                };

                field.onclick = function (e) {
                  innerObject.settings.blockItemClick = false;
                };
              });
            });
          },
        },
        activeContent: {
          selected: {
            view: 'checkbox',
            width: 18,
            on: {
              onChange() {
                const current_id = this.config.$masterId;
                const list = $$(innerObject.views.resourcesList);

                _.delay(() => {
                  if (gantt.config.isJira) {
                    innerObject.helpers.unselectForJiraProjects(list, current_id);
                  }

                  const item = list.getItem(current_id);

                  list.updateItem(current_id, item);

                  innerObject.helpers.calculateEstimationForResource(item);

                  innerObject.handlers.setFocusInValueInput(item, current_id);
                  innerObject.handlers.showUnassignedButton(list.serialize());
                });
              },
            },
          },
          value: {
            view: 'text',
            width: 48,
            on: {
              onKeyPress(code, e) {
                const oldVal = e.target.value;

                _.delay(() => {
                  const newVal = e.target.value;

                  if (customHelper.testNumber(newVal)) {
                    e.target.value = `${newVal}`;
                  } else if (newVal && newVal.length) {
                    e.target.value = oldVal;
                  }
                  if (code === 13) {
                    e.target.blur();
                  }
                });
              },
              onChange(newVal, oldVal) {
                //const settings = resourcesModel.getSettingsForResource(this.data.$masterId, innerObject.settings.currentGanttId);
                const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](this.data.$masterId, innerObject.settings.currentGanttId);

                if (resourceTypeOnProject === constants.RESOURCE_TIME_TYPE) {
                  const current_id = this.config.$masterId;
                  const list = $$(innerObject.views.resourcesList);
                  // const resourceData = resourcesModel.getDataByTaskIdAndResourceId(innerObject.settings.currentTaskId, +current_id, innerObject.settings.currentGanttId);
                  const taskResourcesData = globalStore.getters['tasksModel/getResourcesForTask'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);
                  const taskResourceData = taskResourcesData.find(taskResource => +current_id === taskResource.resource_id);

                  _.delay(() => {
                    const item = list.getItem(current_id);

                    item.valuePerDay = gantt.estimationCalculator.calculateEstimationPerDay(innerObject.settings.currentTaskData, taskResourceData, item.value, null);
                    list.updateItem(current_id, item);
                  });
                }
              },
              onItemClick(id, e) {
                const field = e.target;
                const fields = field.closest('.resource_multiselect_list').querySelectorAll('input[type="text"]');

                field.classList.add('mousetrap');

                _.each(fields, el => {
                  if (el.id !== field.id) {
                    el.classList.remove('selected');
                  }
                });

                if (!field.classList.contains('selected')) {
                  if (field.select) {
                    field.select();
                  } else {
                    field.querySelector('input').select();
                  }

                  field.classList.add('selected');
                }
              },
            },
          },
          valuePerDay: {
            view: 'text',
            width: 48,
            on: {
              onKeyPress(code, e) {
                const oldVal = e.target.value;

                _.delay(() => {
                  const newVal = e.target.value;

                  if (/^(\d+\.?\d{0,9}|\.\d{1,9})$/.test(newVal)) {
                    e.target.value = `${newVal}`;
                  } else if (newVal && newVal.length) {
                    e.target.value = oldVal;
                  }

                  if (code === 13) {
                    e.target.blur();
                  }
                });
              },
              onChange(newVal, oldVal) {
                const current_id = this.config.$masterId;
                const list = $$(innerObject.views.resourcesList);
                // const resourceData = resourcesModel.getDataByTaskIdAndResourceId(innerObject.settings.currentTaskId, +current_id, innerObject.settings.currentGanttId);
                const taskResourcesData = globalStore.getters['tasksModel/getResourcesForTask'](innerObject.settings.currentGanttId, innerObject.settings.currentTaskId);
                const taskResourceData = taskResourcesData.find(taskResource => +current_id === taskResource.resource_id);

                _.delay(() => {
                  const item = list.getItem(current_id);

                  item.value = gantt.estimationCalculator.calculateEstimationPerDay(innerObject.settings.currentTaskData, taskResourceData, null, item.valuePerDay);
                  list.updateItem(current_id, item);
                });
              },
              onItemClick(id, e) {
                const field = e.target;
                const fields = field.closest('.resource_multiselect_list').querySelectorAll('input[type="text"]');

                field.classList.add('mousetrap');

                _.each(fields, el => {
                  if (el.id !== field.id) {
                    el.classList.remove('selected');
                  }
                });

                if (!field.classList.contains('selected')) {
                  if (field.select) {
                    field.select();
                  } else {
                    field.querySelector('input').select();
                  }

                  field.classList.add('selected');
                }
              },
            },
          },
        },
      },
      {
        view: 'label',
        id: 'emptyAssignPopupText',
        css: 'emptyAssignPopupText',
        label: __('empty_assign_popup_text'),
        height: 40,
        hidden: true,
        align: 'center',
      },
      {
        view: 'template',
        id: 'noMatchesPopupMessage',
        css: 'nomatches_popup_message',
        hidden: true,
        height: 50,
        borderless: true,
        template: () => `<p class="nomatches_message_text">${__('no_matches_message_text')}</p>`,
      },
      { height: 12 },
      {
        css: 'resource_popup_buttons',
        id: 'resourcePopupButtons',
        cols: [
          {
            width: 12,
          },
          {
            id: 'unassignedButton',
            view: 'label',
            css: 'unassignedButton',
            template: () => `<div >${__(constants.COMMON_EMPTY_RESOURCE_ACTION)}</div>`,
            click() {
              innerObject.handlers.unselectAllResources();
              this.hide();
            },
          },
          {},
          {
            id: 'addMorePeopleButtonInAssign',
            css: 'add_more_button',
            cols: [
              {
                view: 'button',
                css: 'webix_button_default button_raised button_gray',
                width: 184, // 164
                value: __('add_people_from_grid'),
                click() {
                  if (routerHelper.isProjectList() || routerHelper.isTaskSettingsRoute()) {
                    $$(innerObject.views.resourcesListPopup).hide();
                  }

                  routerHelper.pushByNameAndParams('project', { mode: 'resources' });
                  gantt.saveTaskEditors(true);
                },
              },
              {
                width: 12,
              },
            ],
          },
          {
            width: 164,
            id: 'addMorePeopleButtonEmpty',
          },
          {
            view: 'button',
            label: __('common_ok'),
            width: 80,
            id: 'resourcePopupButtonOk',
            css: 'ok_button',
            click() {
              if (innerObject.settings.taskSettingsOpened) {
                $$(innerObject.views.resourcesListPopup).hide();
              } else {
                gantt.saveTaskEditors(true);
              }
            },
          },
          {
            id: 'resourcePopupButtonSpacer',
          },
          {
            width: 12,
          },
        ],
      },
      { height: 12 },
    ],
  },
  on: {
    onBeforeShow() {
      if (this.getNode().dataset.predessor) {
        this.config.width = 485;
      } else {
        this.config.width = 470;
      }
    },
    onHide() {
      // const callbackWithNotAppliedChanges = () => {
      //   innerObject.helpers.prepareResourcesForRender(innerObject.settings.currentTaskData.id, innerObject.settings.currentTaskData.gantt_id, false);
      //   innerObject.handlers.updateTaskResources(true);
      // };
      //
      // const callbackWithoutNotAppliedChanges = () => {
      //   innerObject.handlers.updateTaskResources(true);
      // };

      //gantt.afterCollaborationChanges(callbackWithNotAppliedChanges, callbackWithoutNotAppliedChanges);
      innerObject.settings.closeAndSave && innerObject.handlers.updateTaskResources(true);
      innerObject.settings.resourcePopupTaskId = null;
      innerObject.settings.callback && innerObject.settings.callback();
      this.hide();
    },
    onShow() {
      $$(innerObject.views.resourcesList).scrollTo(0, 0);
    },
  },
});

webix.ui({
  view: 'popupWithoutPointCustom',
  id: innerObject.views.resourcesPreviewListPopup,
  css: 'resources_preview_popup',
  padding: 8,
  body: {
    view: 'list',
    id: innerObject.views.resourcesPreviewList,
    borderless: true,
    css: 'resource_multiselect_list preview_list',
    width: 260,
    type: {
      height: 30,
    },
    template(obj) {
      let result = '';

      if (innerObject.helpers.isDividerItem(obj.id)) {
        result += `
          <div class="list-group-item">
            <div class="group-item-container">
                <span class="group-header-caption">
                    ${obj.label}
                </span>
                <span class="group-header-underline"></span>
            </div>
          </div>`;

        return result;
      }

      result += '<div class="list_item">';
      result += `${'<span class="info_box">'
        + '<span class="resource_list_item_icon" style="background-image: url('}${obj.picture})"></span>`
        + `<span class="resource_list_item">${obj.name.replace(/>/g, '&gt;').replace(/</g, '&lt;')}</span>`
        + '</span>';

      if (!innerObject.helpers.hideValuesInTemplates(obj)) {
        result += `<span class="input_box">${obj.value}</span>`
          + `<span class="type_box">${obj.type_text}</span>`;
      }

      result += '</div>';

      return result;
    },
    on: {
      onBeforeRender(data) {
        const length = data.serialize().length;

        this.define('yCount', length);
        this.resize();
      },
    },
  },
});

app.on('popup:show', () => {
  gantt.callEvent('hideResourcesPreviewListForTask');
});

app.on('popup:resourceList:closeWithoutSave', () => {
  innerObject.settings.closeAndSave = false;
  gantt.callEvent('hideResourcesListForTask');
  gantt.callEvent('hideResourcesPreviewListForTask');
  innerObject.settings.closeAndSave = true;
})

app.on('changedTaskEstimation', (task, sendTaskUpdate) => {
  innerObject.handlers.updateResourcesAfterChangeEstimation(task, sendTaskUpdate);
});

app.on('resources:model:remove', (resourceIds) => {
  resourceIds.forEach(resourceId => {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](resourceId);

    resourceData.resourceProjects.forEach(settings => {
      innerObject.helpers.updateTasksAfterChangeResource(resourceId, settings.projectId, 'removeResource');
    });
  })
});

app.on('resources:model:changeResourcesOnProjects', (resourcesData) => {
  resourcesData.forEach(data => {
    data.projectIds.forEach(ganttId => {
      innerObject.helpers.updateTasksAfterChangeResource(data.resourceId, ganttId, 'changeResourcesOnProjects');
    });
  });
});
app.on('resources:model:removeResourcesFromProjects', resourcesData => {
  resourcesData.forEach(data => {
    innerObject.helpers.updateTasksAfterChangeResource(data.resourceId, data.projectId, 'removeResourceFromProject');
  });
});
app.on('changedTaskDuration', (task, oldTaskData) => {
  innerObject.handlers.updateResourcesAfterChangeDuration(task, oldTaskData);
});

app.on('changedTaskToMilestone', task => {
  innerObject.handlers.updateResourcesAfterChangeTaskToMilestone(task);
});

app.on('selectInResourceList', id => {
  innerObject.handlers.selectInResourceList(id);
});

export default outputObject;
