// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_juFtrFaz {\n  display: flex;\n  align-items: center;\n}\n.btn_juFtrFaz.disabled_L9eLF2tT {\n  opacity: 0.5;\n  pointer-events: none;\n}\n.btn_juFtrFaz .btn_part_nGh9nMbm + .btn_part_nGh9nMbm {\n  margin-left: 1px;\n}\n.btn_juFtrFaz .left_btn_ZaNtZh1Q {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.btn_juFtrFaz .right_btn_i6erjdvh {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n", "",{"version":3,"sources":["webpack://./public/views/header/less/addTaskBtn.less","webpack://./addTaskBtn.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;ACCF;ADCE;EACE,YAAA;EACA,oBAAA;ACCJ;ADPA;EAUM,gBAAA;ACAN;ADVA;EAcI,0BAAA;EACA,6BAAA;ACDJ;ADdA;EAmBI,yBAAA;EACA,4BAAA;ACFJ","sourcesContent":[".btn{\n  display: flex;\n  align-items: center;\n\n  &.disabled {\n    opacity: 0.5;\n    pointer-events: none;\n  }\n\n  .btn_part + .btn_part {\n      margin-left: 1px;\n  }\n\n  .left_btn {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n\n  .right_btn {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n  }\n}\n",".btn {\n  display: flex;\n  align-items: center;\n}\n.btn.disabled {\n  opacity: 0.5;\n  pointer-events: none;\n}\n.btn .btn_part + .btn_part {\n  margin-left: 1px;\n}\n.btn .left_btn {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.btn .right_btn {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "btn_juFtrFaz",
	"disabled": "disabled_L9eLF2tT",
	"btn_part": "btn_part_nGh9nMbm",
	"left_btn": "left_btn_ZaNtZh1Q",
	"right_btn": "right_btn_i6erjdvh"
};
export default ___CSS_LOADER_EXPORT___;
