class Resizer {
  constructor(index, parent_node, columns, resizer_height = 100) {
    this.index = index;
    this.parent = parent_node;
    this.next = parent_node.nextElementSibling || null;
    this.curCol = {
      node: parent_node,
      name: parent_node.getAttribute('date-name'),
    };
    this.nextCol = !this.next ? null : {
      node: this.next,
      name: this.next.getAttribute('date-name'),
    };
    this.resizer = null;
    this.active = false;
    this.pageX = null;
    this.columns = columns;
    this.resizerHeight = resizer_height;
    this.create();
  }

  create() {
    if (this.curCol.node.getAttribute('data-resizable')) {
      this.createResizer();
      this.setListheners();
    }

    this.updateCoords();
  }

  destroy() {
    this.removeLestheners();
  }

  createResizer() {
    const div = document.createElement('div');

    div.setAttribute('data-column', this.index);
    div.className = 'resizer';
    div.style.top = 0;
    div.style.right = '-1px';
    div.style.width = '4px';
    div.style.position = 'absolute';
    div.style.zIndex = 1;
    div.style.cursor = 'col-resize';
    div.style.userSelect = 'none';
    div.style.height = `${this.resizerHeight}px`;

    this.parent.appendChild(div);
    this.parent.style.position = 'sticky';
    this.resizer = div;
  }

  updateCoords() {
    this._setCoords(this.curCol);
    this.nextCol && this._setCoords(this.nextCol);
  }

  _setCoords(elem) {
    const coords = elem.node.getBoundingClientRect();

    elem.width = elem.node.offsetWidth;
    elem.left = coords.left;
    elem.right = coords.right;
  }

  setListheners() {
    if (!this.resizer) return;

    this.resizer.addEventListener('mouseover', this._mouseover.bind(this));
    this.resizer.addEventListener('mouseout', this._mouseout.bind(this));
    this.resizer.addEventListener('mousedown', this._mousedown.bind(this));
    document.addEventListener('mousemove', this._mousemove.bind(this));
    document.addEventListener('mouseup', this._mouseup.bind(this));
  }

  removeLestheners() {
    if (!this.resizer) return;

    this.resizer.removeEventListener('mouseover', this._mouseover);
    this.resizer.removeEventListener('mouseout', this._mouseout);
    this.resizer.removeEventListener('mousedown', this._mousedown);
    document.removeEventListener('mousemove', this._mousemove);
    document.removeEventListener('mouseup', this._mouseup);
  }

  _mouseover(e) {
    // e.target.style.borderRight = '1px solid #0000ff';
  }

  _mouseout(e) {
    // e.target.style.borderRight = '';
  }

  _mousedown(e) {
    this.pageX = e.pageX;
    this.active = true;
    e.target.style.borderRight = '1px solid #23b2b2';
  }

  _mousemove(e) {
    if (!this.active) { return; }

    const diffX = e.pageX - this.pageX;

    const cursorLessCurrent = e.pageX <= this.curCol.left + 40;
    const cursorMoreNext = e.pageX >= this.nextCol.right - 40;

    if (cursorLessCurrent || cursorMoreNext) {
      return;
    }

    const curColWidth = `${this.curCol.width + diffX}px`;

    this.columns.resize(this.curCol.name, curColWidth);

    const nextColWidth = `${this.nextCol.width - diffX}px`;

    this.columns.resize(this.nextCol.name, nextColWidth);
  }

  _mouseup(e) {
    this.active = false;
    this.updateCoords();
    this.resizer.style.borderRight = '';
  }
}

export default Resizer;
