<template>
  <div
    v-if="notify.isShow"
    id="MaintenanceNotification"
    ref="MaintenanceNotification"
    :class="[$style.box]"
    :style="{width: `calc(100% - ${(calcWidth + 16)}px)`, left: `${calcWidth}px`}"
  >
    <div :class="[$style.text_box]">
      <vgp-icon-button
        size="small"
        :colors="buttonColors"
        :icon="{ name:'warning-1', size: 20, type : 'bold' }"
      />

      <div><span v-html="translation(notify.key)" /> {{ '&nbsp;' }}<span v-html="notify.text " /></div>
    </div>
    <vgp-icon-button
      :class="[$style.header__close_icon]"
      size="small"
      :colors="buttonColors"
      :icon="{ name:'close-1', size: 20 }"
      @onClick="onClickCancel"
    />
  </div>
</template>

<script>
import app, { appIds } from '../../../../app';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import Store from '$$store/main';
import constants from '../../../../helpers/constants';

export default {
  name: 'MaintenanceNotification',
  components: {
    vgpIconButton,
  },
  data() {
    return {
      appOnIds: [],
      top: 66, // top for postition
      notify: {
        isShow: false,
        key: null,
        text: null,
      },
      locale: __,
      buttonColors: {
        color: '#FFF',
      },
    };
  },
  computed: {
    calcWidth() {
      const leftSidebarWidth = Store.getters.leftSidebarWidth;

      return leftSidebarWidth + 16; // 16px margin-left
    },

  },

  watch: {
    notify() {
      this.$nextTick(() => this.calcHeight());
    },
  },

  mounted() {
    setTimeout(() => {
      this.calcHeight();
    }, 0);
  },
  created() {
    const {
      wsEvents: {
        ID_EVENT_WS_MAINTENANCE_ENTRY_ADD,
      },
    } = appIds;
    const maintenanceData = user.maintenanceNotification;
    const dataStorage = localStorage.getItem('maintenanceNotification');

    if (dataStorage && maintenanceData) {
      const data = JSON.parse(dataStorage);

      if (data.hash !== maintenanceData.hash) {
        localStorage.setItem('maintenanceNotification', JSON.stringify({
          hash: maintenanceData.hash,
          isShow: true,
          key: maintenanceData.main_key,
          text: maintenanceData.custom_text,
        }));
        this.notify = {
          ...JSON.parse(localStorage.getItem('maintenanceNotification')),
        };
      } else {
        localStorage.setItem('maintenanceNotification', JSON.stringify({
          ...data,
          show: data.show,
        }));

        this.notify = {
          ...data,
        };
      }
    } else {
      localStorage.setItem('maintenanceNotification', JSON.stringify({
        hash: maintenanceData?.hash || null,
        isShow: !!maintenanceData?.is_enabled,
        key: maintenanceData?.main_key || null,
        text: maintenanceData?.custom_text || null,
      }));

      this.notify = {
        ...JSON.parse(localStorage.getItem('maintenanceNotification')),
      };
    }

    app.socket.on(ID_EVENT_WS_MAINTENANCE_ENTRY_ADD, this.handleSocketData);
  },

  methods: {
    handleSocketData(socketData) {
      localStorage.setItem('maintenanceNotification', JSON.stringify({
        hash: socketData.hash,
        key: socketData.main_key,
        text: socketData.custom_text,
        isShow: !!parseInt(socketData.is_enabled, 10),
      }));

      this.notify = {
        ...JSON.parse(localStorage.getItem('maintenanceNotification')),
      };
    },

    calcHeight() {
      const box = this.$refs.MaintenanceNotification || document.querySelector('#MaintenanceNotification');

      if (box && box.offsetHeight > 40) {
        box.style.top = 'calc(100vh - 77px)';
      } else if (box) {
        box.style.top = 'calc(100vh - 66px)';
      }
    },

    translation(key) {
      let data;

      constants.MAINTENANCE_NOTIFY.forEach(item => {
        if (item.id === key) data = __(`maintenance_notify_${item.id}`);
      });

      return data;
    },

    onClickCancel() {
      this.isShow = false;
      const dataStorage = localStorage.getItem('maintenanceNotification');

      if (dataStorage) {
        const data = JSON.parse(dataStorage);

        localStorage.setItem('maintenanceNotification', JSON.stringify({
          ...data,
          isShow: false,
        }));

        this.notify = {
          ...JSON.parse(localStorage.getItem('maintenanceNotification')),
        };
      }
    },
  },
};

</script>
<style module src='./less/style.less'></style>
