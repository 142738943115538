<template>
  <div class="up">
    <div :class="$style.header">
      <div
        v-if="!isOpenSideBar"
        :class="$style.header_icons"
      >
        <svg-sprite
          v-if="!isOpenSideBar && (!showIcon || blockClick)"
          :class="[$style.short]"
          name="gantt"
        />
        <svg-sprite
          v-if="!isOpenSideBar && showIcon && !blockClick"
          id="gp_autotest_sidebar_open_button"
          :class="[$style.reverse, $style.short, $style.arrow_collapse]"
          name="collaps-2"
          type="bold"
          :size="20"
          @click.capture="clickSideBar($event)"
        />
      </div>

      <div :class="$style.header_logo">
        <!-- 102 14 -->
        <vgp-svg-image
          v-if="isOpenSideBar"
          :class="$style.logo_icon"
          name="logo"
        />

        <div :class="[$style.arrow_back]">
          <svg-sprite
            v-if="isOpenSideBar"
            id="gp_autotest_sidebar_close_button"
            :class="[$style.icon]"
            name="collaps-2"
            type="bold"
            :size="20"
            @click.capture="clickSideBar($event)"
          />
        </div>
      </div>

      <div
        v-if="isOpenSideBar"
        :class="[$style.team_logo, teamSettingsAccess ? $style.owner : '']"
        @mouseover="showSettingsIcon=true"
        @mouseleave="showSettingsIcon=false"
      >
        <div
          v-if="teamLabel.logo"
          :class="$style.team_logo_img"
        >
          <img :src="teamLabel.logo">
        </div>

        <template v-else>
          <div
            v-if="teamLabel.showFavicon && teamLabel.favicon"
            :class="$style.team_logo_img"
          >
            <img :src="teamLabel.favicon">
          </div>
        </template>
        <div
          :class="[$style.team_logo_teamName, showSettingsIcon && teamSettingsAccess ? team_logo_teamName_hover : '']"
          @click="editHandler()"
        >
          {{ teamLabel.name }}
        </div>
        <div
          v-if="teamSettingsAccess && showSettingsIcon"
          :class="$style.team_logo_rightIcons"
        >
          <div
            id="gp_autotest_sidebar_settings_open_button"
            :class="[$style.team_logo_icons, $style.edit_icon]"
            @click="editHandler()"
          >
            <svg-sprite
              name="settings"
            />
          </div>
        </div>
      </div>

      <div
        v-if="itemsForContext.length"
        ref="addProjectBtn"
      >
        <div
          v-if="isOpenSideBar && itemsForContext.length"
          :class="[$style.button, $style.add]"
        >
          <vgp-button
            id="gp_autotest_sidebar_add_project_button"
            :label="locale('create_new_project')"
            :type="'primary'"
            :icon="{name: 'plus-1', type:'bold', size: 20}"
            :icon-side="'left'"
            :small="true"
            :width="'236px'"
            @onClick="addProjectClickBtn"
          />
        </div>
        <svg-sprite
          v-if="!isOpenSideBar && itemsForContext.length && !blockClick"
          id="gp_autotest_sidebar_add_project_button_short"
          :class="[$style.icon, $style.addButton]"
          name="plus-2"
          type="bold"
          size="24"
          @click="addProjectClickBtn()"
        />
      </div>
      <context-menu
        v-if="addProjectClick"
        :class="$style.context_menu"
        :items="itemsForContext"
        :position="positionContext"
        :side="isOpenSideBar ? '' : 'right'"
        :style="{marginLeft: isOpenSideBar ? '12px' : ''}"
        @selectItemMenu="onSelectItemMenu($event)"
        @onClickOutside="onClickOutside"
        @closePopup="onClickOutside"
      />
    </div>
    <!--HEADER-->
    <div :class="$style.menu">
      <div
        v-for="(item, index) in menu"
        :key="index"
      >
        <sidebar-menu-item
          :is-open-side-bar="isOpenSideBar"
          :config="item"
          :is-active="activeItem"
          :unread-count="unreadCount"
          :mention="mention"
          @clickMenuItem="clickMenuItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenuItem from './sidebarMenuItem.vue';
import vgpSvgImage from '../../common/VueComponents/VueIcon/vgpSvgImage.vue';

export default {
  name: 'SidebarHeader',
  components: { SidebarMenuItem, vgpSvgImage },
  props: {
    isOpenSideBar: { type: Boolean, required: true },
    showIcon: { type: Boolean, required: true },
    userRights: { type: Object, required: true },
    blockHeaderClick: { type: Boolean, required: false },
    teamLabel: { type: Object, required: true },
    addProjectClick: { type: Boolean, required: true },
    teamSettingsAccess: { type: Boolean, required: true },
    menu: { type: Array, required: true },
    activeItem: { type: String, required: false, default: '' },
    itemsForContext: { type: Array, required: true },
    unreadCount: { type: Number, required: false, default: 0 },
    mention: { type: Boolean, required: false },
  },
  data() {
    return {
      locale: __,
      showSettingsIcon: false,
      positionContext: null,
    };
  },

  computed: {
    blockClick() {
      return this.blockHeaderClick;
    },
  },

  mounted() {
  },
  methods: {
    clickSideBar() {
      if (this.blockHeaderClick) return;
      this.$emit('clickSideBar');
    },
    addProjectClickBtn() {
      this.positionContext = this.$refs.addProjectBtn.getBoundingClientRect();
      this.$emit('addProjectClickBtn');
    },
    onSelectItemMenu(e) {
      this.$emit('onSelectItemMenu', e);
    },
    onClickOutside() {
      this.positionContext = null;
      this.$emit('onClickOutside');
    },
    clickMenuItem(path, id) {
      this.$emit('clickMenuItem', path, id);
    },
    editHandler() {
      if (!this.teamSettingsAccess) return;
      this.$emit('teamSettings');
    },
  },
};

</script>

<style module src="../style.less" lang="less"></style>
