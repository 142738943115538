var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "role-table-" + _vm.data.length,
      class: [_vm.$style.core],
      attrs: { id: "role_wrapper" },
    },
    [
      _c(
        "div",
        {
          class: [_vm.$style.role_description],
          attrs: { id: "role_description" },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.locale("acc_rs_head_description")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("role-create", {
        attrs: {
          type: "account",
          data: _vm.defaultRoles,
          "select-list": _vm.defaultRoles,
          "access-to-create": _vm.checkRoleCreateAccess,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style.view,
            _vm.sizeForShadow ? null : _vm.$style.without_scroll,
          ],
        },
        [
          _c(
            "div",
            {
              ref: "scrollable",
              class: [_vm.$style.wrapper, "gantt_scroll"],
              style: _vm.setHeightOfTable,
            },
            [
              _c(
                "table",
                {
                  class: [_vm.$style.table, _vm.$style.account_rights_gradient],
                },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          {
                            class: [
                              _vm.$style.sticky_col,
                              _vm.$style.first_col_main,
                              _vm.sizeForShadow ? _vm.$style.shadow : "",
                            ],
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.locale("acc_rs_title_permission")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.data, function (role) {
                          return _c(
                            "th",
                            {
                              class: [
                                _vm.$style.sticky_col,
                                _vm.$style.first_top,
                              ],
                            },
                            [
                              _c(
                                "div",
                                { class: [] },
                                [
                                  _c("role-title", {
                                    attrs: {
                                      "item-id": role.id,
                                      title: role.title,
                                      "default-type": role.defaultType,
                                      "access-to-edit": _vm.checkRoleEditAccess,
                                      "access-to-delete":
                                        _vm.checkRoleDeleteAccess,
                                      "access-to-edit-and-delete":
                                        _vm.checkRoleEditDeleteAccess,
                                      "is-edit-mode": _vm.isEditMode,
                                      type: "account",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.rights, function (item) {
                    return _c(
                      "tbody",
                      { key: item.title },
                      [
                        _c("tr", { class: _vm.$style.test }, [
                          _c(
                            "td",
                            {
                              class: [
                                _vm.$style.sticky_col,
                                _vm.$style.first_col,
                              ],
                            },
                            [
                              _c("div", { class: _vm.$style.title_group }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.locale("acc_" + item.title + "_group")
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", { attrs: { colspan: _vm.data.length } }),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.children, function (child) {
                          return _c(
                            "tr",
                            { key: child.title },
                            [
                              _c(
                                "td",
                                {
                                  class: [
                                    _vm.$style.sticky_col,
                                    _vm.$style.first_col,
                                    _vm.$style.border,
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$style.parent_info,
                                        "box_" + child.title,
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { class: _vm.$style.icon },
                                        [
                                          _c("svg-sprite", {
                                            attrs: {
                                              name: _vm.ICONS.ACCOUNT[
                                                child.title
                                              ],
                                              size: "24",
                                              type: "regular",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.$style.parent_description,
                                        },
                                        [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.locale(
                                                  "acc_rs_" +
                                                    child.title +
                                                    "_title"
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.locale(
                                                  "acc_rs_" +
                                                    child.title +
                                                    "_description"
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  child.children.length
                                    ? _c(
                                        "div",
                                        {
                                          class: _vm.$style.children_wrap,
                                          staticStyle: {
                                            "margin-left": "12px",
                                          },
                                        },
                                        _vm._l(
                                          child.children,
                                          function (subcat) {
                                            return _c(
                                              "div",
                                              {
                                                class: _vm.$style.children_info,
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { class: _vm.$style.icon },
                                                  [
                                                    _c("svg-sprite", {
                                                      attrs: {
                                                        name: _vm.ICONS.ACCOUNT[
                                                          subcat.title
                                                        ],
                                                        size: "24",
                                                        type: "regular",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      _vm.$style
                                                        .children_description,
                                                      "box_" + subcat.title,
                                                    ],
                                                  },
                                                  [
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.locale(
                                                            "acc_rs_" +
                                                              subcat.title +
                                                              "_title"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.locale(
                                                            "acc_rs_" +
                                                              subcat.title +
                                                              "_description"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.data, function (role) {
                                return _c(
                                  "td",
                                  {
                                    class: [
                                      child.children.length
                                        ? _vm.$style.border_left2
                                        : _vm.$style.border_left,
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.$style.roles_parent_info,
                                        style: _vm.setHeight(child.title),
                                      },
                                      [
                                        _c("role-item", {
                                          attrs: {
                                            rights: role.rights,
                                            "role-id": role.id,
                                            category: child,
                                            reverse: _vm.checkReverse(
                                              child.title
                                            ),
                                            type: "account",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    child.children.length
                                      ? _c(
                                          "div",
                                          {
                                            class:
                                              _vm.$style.children_role_wrap,
                                          },
                                          _vm._l(
                                            child.children,
                                            function (subcat) {
                                              return _c(
                                                "div",
                                                {
                                                  class:
                                                    _vm.$style.children_info,
                                                  style: _vm.setHeight(
                                                    subcat.title
                                                  ),
                                                },
                                                [
                                                  _c("role-item", {
                                                    attrs: {
                                                      rights: role.rights,
                                                      "role-id": role.id,
                                                      category: subcat,
                                                      reverse: _vm.checkReverse(
                                                        subcat.title
                                                      ),
                                                      type: "account",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isShowForm
        ? _c("core-popup", {
            attrs: {
              id: "gp_autotest_account_role_popup_save_before_leave",
              "close-icon": true,
              show: _vm.isShowForm,
              title: _vm.locale("acc_rs_save_edit_role_before_leave_title"),
            },
            on: { onClickCancel: _vm.togglePopup },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { class: [_vm.$style.add_form] }, [
                        _c("div", { class: [_vm.$style.form_line] }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.locale("acc_rs_leave_description"))
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: [_vm.$style.form_line_buttons] },
                          [
                            _c("vgp-button", {
                              attrs: {
                                id: "gp_autotest_account_role_popup_save_before_leave_btn_cancel",
                                type: "secondary",
                                small: true,
                                label: _vm.locale("acc_rs_create_cancel_btn"),
                              },
                              on: { onClick: _vm.cancelRoleEdit },
                            }),
                            _vm._v(" "),
                            _c("vgp-button", {
                              attrs: {
                                id: "gp_autotest_account_role_popup_save_before_leave_btn_ok",
                                type: "primary",
                                small: true,
                                label: _vm.locale("acc_rs_create_ok_btn"),
                              },
                              on: { onClick: _vm.confirmRoleEdit },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2427857384
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }