var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEditMode || (_vm.isEditMode && _vm.getEditItemId !== _vm.roleId)
    ? _c(
        "div",
        { class: _vm.$style.role_icon },
        [
          _c("svg-sprite", {
            attrs: {
              name: _vm.getIcon.name,
              size: "24",
              color: _vm.getIcon.color,
              type: "bold",
            },
          }),
        ],
        1
      )
    : _vm.isEditMode &&
      _vm.getEditItemId === _vm.roleId &&
      _vm.checkRoleBillingAccess
    ? _c(
        "div",
        [
          _c(
            "square-toggle",
            _vm._g(
              {
                attrs: {
                  id:
                    "gp_autotest_" +
                    _vm.type +
                    "_role_table_item_toggle_" +
                    _vm.category.title,
                  "is-toggle-on": _vm.getSwitchStatus.status,
                  "is-disabled": _vm.getSwitchStatus.disabled,
                },
              },
              _vm.getSwitchStatus.disabled
                ? {}
                : {
                    onChange: function () {
                      return _vm.changeSwitch(!_vm.getSwitchStatus.status)
                    },
                  }
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }