import app from "../../app";
import dateFormatHelper from "../../helpers/dateFormats";
import projectsModel from "./../../models/projects";
import status from './../../helpers/status';

const __ = window.__;


const innerObject = {
  init: {
    run: function (values) {
      innerObject.settings.exportData = {};
      this.helpers.findEstimateProject();

      innerObject.settings.exportData.baselineStatus = values.export_baseline;
      innerObject.settings.exportData.startDate = this.helpers.detectStartTime();
      innerObject.settings.exportData.endDate = this.helpers.detectEndTime();

      innerObject.settings.firstInit = false;
    },

    helpers: {
      findEstimateProject: function () {
        innerObject.settings.exportData.mainProjectTask = gantt.getTask(gantt.getChildren(0)[0]);
      },
      detectStartTime: function () {
        var startDate = dateFormatHelper.createNewDateByStringOrDateObject(innerObject.settings.exportData.mainProjectTask.start_date);

        return new Date(startDate.setDate(startDate.getDate() - 1));
      },
      detectEndTime: function () {
        var endDate = dateFormatHelper.createNewDateByStringOrDateObject(
          innerObject.settings.exportData.mainProjectTask.end_date
        );

        return new Date(endDate.setDate(endDate.getDate() + 1));
      },
      detectBasalineSelect: () => {

      }
    }
  },
  handlers: {},
  settings: {
    projectId: null,
    firstInit: true,
    defaultMethod: "pdf",
    parentId: "exportBtn",
    exportData: {}
  }
};

var outputObject = {
  handlers: {
    run: function (values) {
      innerObject.init.run(values);
      status.handlers.showMainSpinner(true);
      let projectData = projectsModel.getActiveProjectData();
      var exportOptions = {
        name: encodeURIComponent(projectData.name) + ".xml",
        title: encodeURIComponent(projectData.name),
        time: gantt.config.worktimes.global,
        tasks: {
          "Work": function (task) {
            return task.estimation;
          },
        }

      };
      gantt.exportToMSProject(exportOptions);
      status.handlers.hideMainSpinner();
    }
  }
};

app.on("gantt:export:xml", function (values) {
  outputObject.handlers.run(values);
});

export default outputObject;
