var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    !_vm.hide
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: {
                  placement: _vm.position,
                  interactive: _vm.interactive,
                  duration: [
                    _vm.withAnimation ? 275 : 0,
                    _vm.withAnimation ? 250 : 0,
                  ],
                  delay: _vm.delay,
                  a11y: false,
                },
                expression:
                  "{\n      'placement' : position,\n      'interactive' : interactive,\n      'duration': [withAnimation ? 275 : 0 , withAnimation ? 250 : 0 ],\n      'delay' : delay,\n      'a11y': false,\n    }",
              },
            ],
            staticClass: "tooltip_label",
            style: _vm.fullWidth ? "width: 100%" : "",
            attrs: { content: _vm.content },
          },
          [_vm._t("body")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hide
      ? _c(
          "div",
          {
            staticClass: "tooltip_label",
            style: _vm.fullWidth ? "width: 100%" : "",
            attrs: { content: _vm.content },
          },
          [_vm._t("body")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }