var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.$style["task-settings-dates"] }, [
    _c(
      "div",
      {
        class: [
          _vm.$style["task-settings-dates__column"],
          _vm.$style["date-with-time-column"],
        ],
      },
      [
        _c(
          "div",
          {
            class: [
              _vm.$style["column__header"],
              _vm.isFieldDisabled("start_date")
                ? _vm.$style["column__header_disabled"]
                : "",
              "ml-3",
            ],
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locales("gantt_grid_start_date")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.$style["date-with-time-column__inputs"],
              _vm.isFieldDisabled("start_date")
                ? _vm.$style["date-with-time-column__inputs_disabled"]
                : "",
              "tooltip-gantt",
            ],
            attrs: {
              "data-align": "center",
              "data-key": _vm.getDisabledFieldReason("start_date"),
              "data-position": "bottom",
            },
          },
          [
            _c("date-picker", {
              attrs: {
                value: _vm.taskData.start_date,
                disabled: _vm.isFieldDisabled("start_date"),
                "task-data": _vm.taskData,
                property: "start_date",
              },
              on: { change: _vm.handleChangeStartDate },
            }),
            _vm._v(" "),
            _c("date-picker", {
              attrs: {
                value: _vm.taskData.start_date,
                "calendar-icon": false,
                disabled: _vm.isFieldDisabled("start_date"),
                "task-data": _vm.taskData,
                property: "start_date",
                type: "time",
              },
              on: { change: _vm.handleChangeStartTime },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style["task-settings-dates__column"] }, [
      _c("span", { class: _vm.$style["dates-divider"] }, [_vm._v("—")]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          _vm.$style["task-settings-dates__column"],
          _vm.$style["date-with-time-column"],
        ],
      },
      [
        _c(
          "div",
          {
            class: [
              _vm.$style["column__header"],
              _vm.isFieldDisabled("end_date")
                ? _vm.$style["column__header_disabled"]
                : "",
              "ml-3",
            ],
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.locales("gantt_grid_end_date")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.$style["date-with-time-column__inputs"],
              _vm.isFieldDisabled("end_date")
                ? _vm.$style["date-with-time-column__inputs_disabled"]
                : "",
              "tooltip-gantt",
            ],
            attrs: {
              "data-align": "center",
              "data-key": _vm.getDisabledFieldReason("end_date"),
              "data-position": "bottom",
            },
          },
          [
            _c("date-picker", {
              attrs: {
                value: _vm.taskData.end_date,
                disabled: _vm.isFieldDisabled("end_date"),
                "task-data": _vm.taskData,
                property: "end_date",
              },
              on: { change: _vm.handleChangeEndDate },
            }),
            _vm._v(" "),
            _c("date-picker", {
              attrs: {
                value: _vm.taskData.end_date,
                "calendar-icon": false,
                disabled: _vm.isFieldDisabled("end_date"),
                "task-data": _vm.taskData,
                property: "end_date",
                type: "time",
              },
              on: { change: _vm.handleChangeEndTime },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style["column__divider"] }),
    _vm._v(" "),
    _c("div", { class: _vm.$style["task-settings-dates__column"] }, [
      _c(
        "span",
        {
          staticClass: "text-center",
          class: [
            _vm.$style["column__header"],
            _vm.isFieldDisabled("estimation")
              ? _vm.$style["column__header_disabled"]
              : "",
          ],
          attrs: { title: _vm.locales("gantt_grid_estimation") },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.locales("gantt_grid_estimation")) + "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.isFieldDisabled("estimation_pricing") ? "pricing-tooltip" : "",
          ],
          attrs: { "data-feature": "estimation", "data-position": "top" },
        },
        [
          _c("vgp-input", {
            key: _vm.estimationKey,
            class: [
              _vm.$style["text-align-right"],
              _vm.$style["custom-input-hover"],
            ],
            attrs: {
              value: _vm.formattedEstimation,
              border: false,
              "is-disabled": _vm.isFieldDisabled("estimation"),
              "select-by-focus": "",
              small: "",
            },
            on: { onChange: _vm.handleChangeEstimation },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style["column__divider"] }),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style["task-settings-dates__column"] },
      [
        _c(
          "span",
          {
            staticClass: "text-center",
            class: [
              _vm.$style["column__header"],
              _vm.isFieldDisabled("duration")
                ? _vm.$style["column__header_disabled"]
                : "",
            ],
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.locales("gantt_grid_duration")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c("vgp-input", {
          key: _vm.durationKey,
          class: [
            _vm.$style["custom-input-hover"],
            _vm.$style["text-align-right"],
          ],
          attrs: {
            "is-disabled": _vm.isFieldDisabled("duration"),
            value: _vm.formattedDuration,
            border: false,
            "select-by-focus": "",
            small: "",
          },
          on: { onChange: _vm.handleChangeDuration },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style["column__divider"] }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ml-1", class: _vm.$style["task-settings-dates__column"] },
      [
        _c(
          "span",
          {
            class: [
              _vm.$style["column__header"],
              _vm.isFieldDisabled("deadline")
                ? _vm.$style["column__header_disabled"]
                : "",
            ],
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.locales("task_settings_deadline_date")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex align-center",
            class: _vm.$style["deadline-column__input-wrap"],
          },
          [
            _c("square-toggle", {
              attrs: {
                "is-toggle-on": !!_vm.taskData.deadline,
                "is-disabled": _vm.isFieldDisabled("deadline"),
              },
              on: { onChange: _vm.handleToggleDeadline },
            }),
            _vm._v(" "),
            !!_vm.taskData.deadline
              ? _c("date-picker", {
                  attrs: {
                    value: _vm.taskData.deadline,
                    "task-data": _vm.taskData,
                    disabled: _vm.isFieldDisabled("deadline"),
                    "calendar-icon": false,
                  },
                  on: { change: _vm.handleChangeDeadline },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }