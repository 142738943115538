var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "8", sm: "8" } },
        [
          _c(
            "v-row",
            { attrs: { "align-content-md": "flex-start" } },
            [
              _c(
                "v-col",
                { attrs: { md: "4", sm: "4" } },
                [
                  _c("vgp-label-slot", {
                    attrs: {
                      label: _vm.locales(
                        "share_popup_public_url_list_header_description"
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-input", {
                              class: ["public-url-form-input"],
                              attrs: {
                                placeholder: _vm.getDateNow,
                                small: true,
                              },
                              model: {
                                value: _vm.title,
                                callback: function ($$v) {
                                  _vm.title = $$v
                                },
                                expression: "title",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "4", sm: "4" } },
                [
                  _c("vgp-label-slot", {
                    attrs: { label: _vm.locales("common_filter") },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-dropdown-select", {
                              key: _vm.filterDropdownKey,
                              class: [
                                "public-url-form-input-select",
                                "dropdown",
                              ],
                              attrs: {
                                "highlight-active-state": false,
                                "show-selected-first": true,
                                "show-reset-btn": false,
                                selected: _vm.options.find(function (opt) {
                                  return +opt.value === +_vm.filter
                                }),
                                "drop-list": _vm.options,
                                "item-template": "text",
                                multiselect: false,
                                "track-by-text": "title",
                                "track-by": "value",
                              },
                              on: { selected: _vm.changeSelect },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "4", sm: "4" } },
                [
                  _c("vgp-label-slot", {
                    attrs: { label: _vm.locales("field_password") },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-input-switch", {
                              staticClass: "public-url-form-input-password",
                              attrs: { type: "text", placeholder: "—" },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { md: "4", sm: "4" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "5", sm: "5" } }, [
                _c(
                  "div",
                  { staticClass: "public-url-form__actions" },
                  [
                    _c("tooltip", {
                      attrs: {
                        content: _vm.locales(
                          "share_popup_public_url_filter_checkbox_tooltip"
                        ),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("vgp-checkbox", {
                                staticStyle: {
                                  position: "relative",
                                  top: "8px",
                                  width: "100%",
                                },
                                attrs: {
                                  value: _vm.snapshot,
                                  label: _vm.locales(
                                    "share_popup_public_url_list_header_snapshot"
                                  ),
                                  "label-position": "right",
                                },
                                model: {
                                  value: _vm.snapshot,
                                  callback: function ($$v) {
                                    _vm.snapshot = $$v
                                  },
                                  expression: "snapshot",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { md: "7", sm: "7" } }, [
                _c(
                  "div",
                  { staticClass: "public-url-form__actions" },
                  [
                    _c("vgp-button", {
                      attrs: {
                        type: "primary",
                        disabled: _vm.isLoading,
                        small: true,
                        width: "140px",
                        label: _vm.locales("share_popup_public_url_filter_btn"),
                      },
                      on: { onClick: _vm.submitForm },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }