var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.filter_saved_popup,
        _vm.isShow ? _vm.$style.active : "",
      ],
    },
    [
      _c(
        "div",
        { class: [_vm.$style.filter_header, _vm.$style.jc_space_between] },
        [
          _c("span", { class: _vm.$style.filter_header_title }, [
            _vm._v("\n      " + _vm._s(_vm.locales.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: {
                id: "gp_autotest_global_filter_btn_save_popup_btn_close",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c("webix-ui", {
                class: _vm.$style.filter_header_icon,
                attrs: { config: _vm.icons.arrow },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.list.length
        ? [
            _c(
              "div",
              { staticClass: "gantt_scroll", class: _vm.$style.saved_scroll },
              _vm._l(_vm.list, function (item, index) {
                return _c("savedListItem", {
                  key: index + "_" + item.id,
                  attrs: {
                    id: item.id,
                    name: item.value,
                    "active-item-id": _vm.activeItemId,
                    list: _vm.list,
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.$emit("delete", item)
                    },
                    edit: function ($event) {
                      return _vm.$emit("edit", {
                        id: item.id,
                        value: $event,
                        old_value: item.value,
                      })
                    },
                    select: function ($event) {
                      return _vm.$emit("select", Object.assign({}, item))
                    },
                  },
                })
              }),
              1
            ),
          ]
        : _c("div", { class: _vm.$style.no_saved }, [
            _vm._v("\n    " + _vm._s(_vm.locales.no_saved) + "\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }