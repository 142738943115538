import customColumns from '../gantt/modules/userCustomColumns';
import icon_resource from '../../svg/default/default-ava.svg';
import globalStore from '../../store/main';
import { actionTypes } from './dataProvider';

const locale = {
  realResource: __('resources_layout_header_title_1'),
  virtualResource: __('resources_layout_header_title_2'),
};

export default class CustomColumns {
  static getOptionsByGanttIds(ganttIds) {
    const creator = new CustomColumns(ganttIds);

    return creator.columns;
  }

  get commonColors() {
    return globalStore.getters['teamSetting/colors'].data;
  }

  constructor(ganttIds = []) {
    this.ganttIDs = ganttIds;
    this.columns = null;
    this.init();
  }

  init() {
    this.loadColumnsByGanttIDs();
  }

  loadColumnsByGanttIDs() {
    const columns = [];

    this.ganttIDs.forEach(ganttID => {
      const projectColumnIds = globalStore.getters['columns/getColumnIdsByProjectId'](ganttID);

      projectColumnIds.forEach(columnId => {
        const isAdded = columns.find(item => item.id === columnId);

        if (isAdded) return;

        const column = globalStore.getters['columns/getCustomColumnById'](columnId);

        if (column) columns.push(column);
      });
    });

    this.columns = this.prepareCollumns(columns);
  }

  prepareCollumns(columns) {
    return columns.map(col => {
      const item = {
        custom: true,
        typeId: col.type,
        id: col.id,
        property: col.id,
        label: col.name,
        options: this.prepareOptions(col.options, col.type),
        actionType: actionTypes.CUSTOM_COL,
      };

      return { ...item, ...this.getTemplate[col.type] };
    });
  }

  get getTemplate() {
    return {
      1: {
        itemTemplate: 'text', type: 'select', useSearch: true, multiselect: false, isOptions: true,
      },
      2: {
        itemTemplate: '', type: 'input', inputType: 'text', isOptions: false,
      },
      3: {
        itemTemplate: '', type: 'input', inputType: 'number', isOptions: false,
      },
      4: { itemTemplate: '', type: 'select-date', isOptions: false },
      5: {
        itemTemplate: 'text', type: 'select', multiselect: false, isOptions: false,
      }, // checkbox
      6: {
        itemTemplate: '', type: 'select-color', multiselect: false, isOptions: true,
      },
      7: {
        itemTemplate: 'text', type: 'select', useSearch: true, multiselect: true, isOptions: true,
      }, //
      8: {
        itemTemplate: 'user', type: 'select', useSearch: true, multiselect: true, isOptions: true,
      }, //
      9: {
        itemTemplate: 'label', type: 'select', useSearch: true, multiselect: true, isOptions: true,
      }, //
    };
  }

  prepareOptions(options, typeId) {
    if (typeId === 5) {
      return [
        {
          id: '0',
          value: __('filter_checkbox_type_custom_column_off'),
        },
        {
          id: '1',
          value: __('filter_checkbox_type_custom_column_on'),
        },
      ];
    }

    if (!options && !options.length) return;

    if (typeId === 8) {
      options = customColumns.filterPeopleOptions(options);
      options.sort((a, b) => {
        if (!a.userId) return 1;

        return -1;
      });
    }

    return options.map(item => {
      const opt = {
        id: item.id,
        color: item.color ? item.color : null,
        icon: item.picture || icon_resource,
        value: item.value,
      };

      if (typeId === 8) {
        opt.group = item.userId ? locale.realResource : locale.virtualResource;
      }

      if (typeId === 6) {
        opt.color = item.value;
        const color = this.commonColors.find(c => c.hex === item.value);

        if (!color) {
          console.warn('Did not find color in dafault colors');

          return;
        }

        const {
          hex, hex2, hex3, hex4, locale,
        } = color;

        Object.assign(opt, {
          hex, hex2, hex3, hex4, locale,
        });
      }

      return opt;
    });
  }
}
