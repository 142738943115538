import { api } from '$$store/api';
import constants from '../helpers/constants';
import {
  getPasswordStrengthColorByCode, getPasswordStrengthNumberByCode,
  getPasswordStrengthStatistics,
  getPasswordStrengthTextByCode,
} from '../helpers/passHelper';

const passwordMixin = {
  data() {
    return {
      isPasswordCommonError: false,
      debouncePasswordTimeout: null,
      passwordStrengthState: {
        strength: 0,
        show: false,
        color: null,
        text: '',
        icon: null,
      },
    };
  },
  methods: {
    setPasswordStrengthState(value) {
      const { strengthCode } = getPasswordStrengthStatistics(value);

      if (!strengthCode) {
        this.passwordStrengthState.show = false;

        return;
      }

      this.passwordStrengthState.strength = getPasswordStrengthNumberByCode(strengthCode);
      this.passwordStrengthState.color = getPasswordStrengthColorByCode(strengthCode);
      this.passwordStrengthState.text = getPasswordStrengthTextByCode(strengthCode);
      this.passwordStrengthState.icon = strengthCode === 'VERY_STRONG' ? 'success' : 'warning-1';
      this.passwordStrengthState.show = true;
    },
    debounceCheckPasswordOnCommon(value, wait) {
      this.isPasswordCommonError = false;

      if (this.debouncePasswordTimeout) {
        clearTimeout(this.debouncePasswordTimeout);
      }

      this.debouncePasswordTimeout = setTimeout(async () => {
        if (value.length >= constants.MIN_PASSWORD_LENGTH) {
          const { data: { isCommon } } = await api.post(
            '/password-strength/check-on-common-passwords',
            { password: value },
          );

          if (isCommon) {
            this.passwordStrengthState.show = false;
            this.isPasswordCommonError = true;
          }
        }
      }, wait);
    },
  },
};

export default passwordMixin;