var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["team-size-select", _vm.className ? _vm.className : ""] },
    [
      _c("div", { staticClass: "description" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "select" }, [
        _c(
          "div",
          {
            class: ["dropdown", _vm.isShowSelectMenu ? "active" : ""],
            on: { click: _vm.showSelect },
          },
          [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.nextCount +
                      " " +
                      (+_vm.nextCount === 1
                        ? _vm.locales("one_user_locale")
                        : _vm.locales("users_locale"))
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("webix-ui", {
              staticClass: "arrow-icon",
              attrs: { config: _vm.icons.arrowMore },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isShowSelectMenu
          ? _c(
              "div",
              { staticClass: "list gantt_scroll" },
              [
                _vm._l(_vm.numberOfPlan, function (count, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "select-item",
                        count.value === _vm.nextCount ? "active" : "",
                        count.disabled ? "disabled" : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.onSelectItem(count)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "select-item__text" }, [
                        _c("span", [_vm._v(_vm._s(count.value))]),
                        _vm._v(
                          " " +
                            _vm._s(
                              count.value === 1
                                ? _vm.locales("one_user_locale")
                                : _vm.locales("users_locale")
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      !_vm.isAnnual && count.value === 1
                        ? _c(
                            "div",
                            {
                              staticClass: "select-item__icon tooltip-gantt",
                              attrs: {
                                "data-key": _vm.locales(
                                  "base_plan_info_tooltip"
                                ),
                                "data-position": "bottom",
                                "data-y": 0,
                                "data-x": 0,
                              },
                            },
                            [
                              _c("webix-ui", {
                                staticClass: "info-icon",
                                attrs: { config: _vm.icons.infoIcon },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (!_vm.isPopup &&
                        _vm.type === "buy-new-plan" &&
                        _vm.isAnnual &&
                        count.disabled) ||
                      (!_vm.isPopup &&
                        _vm.type === "buy-new-plan" &&
                        !_vm.isAnnual &&
                        count.disabled &&
                        count.value !== 1)
                        ? _c(
                            "div",
                            {
                              staticClass: "select-item__icon tooltip-gantt",
                              attrs: {
                                "data-key": _vm.locales("can_not_use_plan"),
                                "data-position": "bottom",
                                "data-y": 0,
                                "data-x": 0,
                              },
                            },
                            [
                              _c("webix-ui", {
                                staticClass: "info-icon",
                                attrs: { config: _vm.icons.infoIcon },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "select-item contact-item",
                    on: { click: _vm.contactClick },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locales("contact_item")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }