var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tooltip-container pricing-mark",
      class: _vm.customCssClass,
      style: {
        top: _vm.coordinates.top,
        bottom: _vm.coordinates.bottom,
        left: _vm.coordinates.left,
        right: _vm.coordinates.right,
      },
      attrs: { id: "pricing-popup" },
      on: { mouseover: _vm.addBorderAnchor, mouseout: _vm.removeBorderAnchor },
    },
    [
      _c("div", { staticClass: "tooltip-body" }, [
        _c("div", { staticClass: "pricing-popup-header pricing-mark" }, [
          _c("div", { staticClass: "header-start" }, [
            _c("div", { staticClass: "popup-header-name" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.getTitleLocale(_vm.config.feature)) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _vm.isTitle
              ? _c("div", { staticClass: "pricing-title-container" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.locales(_vm.getPricingTitle(_vm.config.feature))
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "close_btn", on: { click: _vm.onClickClose } },
            [
              _c("icon-button", {
                staticClass: "pricing-tooltip-closer",
                attrs: { icon: { name: "close-1", size: 20 }, size: "small" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.isGif
          ? _c(
              "div",
              { staticClass: "pricing-popup-main pricing-mark" },
              [
                _vm.path
                  ? _c("LottieAdapter", { attrs: { path: _vm.path } })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.isGif ? "" : "pricing-popup-text-margin",
              "pricing-popup-text-part",
            ],
          },
          [
            _c("div", { staticClass: "pricing-popup-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getDescriptionTitleLocale(_vm.config.feature)) +
                  "\n        "
              ),
              _c(
                "div",
                { staticClass: "learn_more" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pricing-popup-link",
                      attrs: {
                        href: _vm.locales(_vm.getLink(_vm.config.feature)),
                        target: "_blank",
                      },
                      on: { click: _vm.linkClick },
                    },
                    [_vm._v(_vm._s(_vm.locales("help_read_more")))]
                  ),
                  _vm._v(" "),
                  _c("webix-ui", {
                    staticClass: "arrow-left",
                    attrs: { config: _vm.icons.arrowLeft },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.isOwner
          ? _c(
              "div",
              {
                staticClass: "pricing-popup-button",
                style: _vm.isDisableBtn
                  ? { "pointer-events": "none", opacity: ".5" }
                  : {},
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popup-button-link",
                    on: { click: _vm.upgradeClick },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locales("upgrade_rate_btn")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }