import app from "../../app";

var collection = {};
collection = new webix.DataCollection();

collection.getData = function () {
  collection.clearAll();

  return webix.ajax()
    .get("/api/integrations/slack/getConversions")
    .then(function(result) {
      let data = result.json();
      collection.parse(data);
      return collection.serialize();
    })
}

export default collection;
