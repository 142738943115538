<template>
  <section :class="$style['task-settings-dates']">
    <div :class="[$style['task-settings-dates__column'], $style['date-with-time-column']]">
      <div
        :class="[
          $style['column__header'],
          isFieldDisabled('start_date') ? $style['column__header_disabled'] : '',
          'ml-3',
        ]"
      >
        {{ locales('gantt_grid_start_date') }}
      </div>
      <div
        :class="[
          $style['date-with-time-column__inputs'],
          isFieldDisabled('start_date') ? $style['date-with-time-column__inputs_disabled'] : '',
          'tooltip-gantt'
        ]"
        data-align="center"
        :data-key="getDisabledFieldReason('start_date')"
        data-position="bottom"
      >
        <date-picker
          :value="taskData.start_date"
          :disabled="isFieldDisabled('start_date')"
          :task-data="taskData"
          property="start_date"
          @change="handleChangeStartDate"
        />
        <date-picker
          :value="taskData.start_date"
          :calendar-icon="false"
          :disabled="isFieldDisabled('start_date')"
          :task-data="taskData"
          property="start_date"
          type="time"
          @change="handleChangeStartTime"
        />
      </div>
    </div>
    <div :class="$style['task-settings-dates__column']">
      <span :class="$style['dates-divider']">—</span>
    </div>
    <div :class="[$style['task-settings-dates__column'], $style['date-with-time-column']]">
      <div
        :class="[
          $style['column__header'],
          isFieldDisabled('end_date') ? $style['column__header_disabled'] : '',
          'ml-3',
        ]"
      >
        {{ locales('gantt_grid_end_date') }}
      </div>
      <div
        :class="[
          $style['date-with-time-column__inputs'],
          isFieldDisabled('end_date') ? $style['date-with-time-column__inputs_disabled'] : '',
          'tooltip-gantt'
        ]"
        data-align="center"
        :data-key="getDisabledFieldReason('end_date')"
        data-position="bottom"
      >
        <date-picker
          :value="taskData.end_date"
          :disabled="isFieldDisabled('end_date')"
          :task-data="taskData"
          property="end_date"
          @change="handleChangeEndDate"
        />
        <date-picker
          :value="taskData.end_date"
          :calendar-icon="false"
          :disabled="isFieldDisabled('end_date')"
          :task-data="taskData"
          property="end_date"
          type="time"
          @change="handleChangeEndTime"
        />
      </div>
    </div>
    <div :class="$style['column__divider']" />
    <div :class="$style['task-settings-dates__column']">
      <span
        :class="[
          $style['column__header'],
          isFieldDisabled('estimation') ? $style['column__header_disabled'] : ''
        ]"
        :title="locales('gantt_grid_estimation')"
        class="text-center"
      >
        {{ locales('gantt_grid_estimation') }}
      </span>
      <div
        data-feature="estimation"
        data-position="top"
        :class="[isFieldDisabled('estimation_pricing') ? 'pricing-tooltip' : '']"
      >
        <vgp-input
          :key="estimationKey"
          :value="formattedEstimation"
          :border="false"
          :is-disabled="isFieldDisabled('estimation')"
          :class="[
            $style['text-align-right'],
            $style['custom-input-hover']
          ]"
          select-by-focus
          small
          @onChange="handleChangeEstimation"
        />
      </div>
    </div>
    <div :class="$style['column__divider']" />
    <div :class="$style['task-settings-dates__column']">
      <span
        :class="[
          $style['column__header'],
          isFieldDisabled('duration') ? $style['column__header_disabled'] : '',
        ]"
        class="text-center"
      >
        {{ locales('gantt_grid_duration') }}
      </span>
      <vgp-input
        :key="durationKey"
        :is-disabled="isFieldDisabled('duration')"
        :value="formattedDuration"
        :border="false"
        :class="[$style['custom-input-hover'], $style['text-align-right']]"
        select-by-focus
        small
        @onChange="handleChangeDuration"
      />
    </div>
    <div :class="$style['column__divider']" />
    <div
      :class="$style['task-settings-dates__column']"
      class="ml-1"
    >
      <span
        :class="[
          $style['column__header'],
          isFieldDisabled('deadline') ? $style['column__header_disabled'] : '',
        ]"
      >
        {{ locales('task_settings_deadline_date') }}
      </span>
      <div
        :class="$style['deadline-column__input-wrap']"
        class="d-flex align-center"
      >
        <square-toggle
          :is-toggle-on="!!taskData.deadline"
          :is-disabled="isFieldDisabled('deadline')"
          @onChange="handleToggleDeadline"
        />
        <date-picker
          v-if="!!taskData.deadline"
          :value="taskData.deadline"
          :task-data="taskData"
          :disabled="isFieldDisabled('deadline')"
          :calendar-icon="false"
          @change="handleChangeDeadline"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { isDate } from 'lodash';
import SquareToggle from '$$vueCmp/checkbox/squareToggle.vue';
import DatePicker from './includes/DatePicker.vue';
import moment from '../../../../libs/moment';
import timeParser from '$$helpers/timeParser';
import projectsModel from '../../../../models/projects';
import constants from '$$helpers/constants';
import routerHelper from '$$helpers/router';
import estimationModule from '../../../gantt/modules/estimation';
import dateHelper from '$$helpers/dateFormats';

export default {
  name: 'TaskSettingsDates',
  components: { DatePicker, SquareToggle },
  inject: ['isFieldDisabled', 'getDisabledFieldReason'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      estimation: this.taskData.estimation,
      duration: this.taskData.duration,
      durationKey: 0,
      estimationKey: 0,
    };
  },
  computed: {
    projectId() {
      return this.taskData.gantt_id;
    },
    formattedDuration() {
      const durationData = projectsModel.getProjectConfig(this.projectId).durationData;

      return timeParser.output(this.duration, {
        durationData,
        durationStep: gantt.config.duration_view,
        prop: 'duration',
      });
    },
    formattedEstimation() {
      return timeParser.output(
        estimationModule.helpers.getEstimationForTask(this.taskData),
        { prop: 'estimation' },
      );
    },
  },
  watch: {
    'taskData.duration': function (val) {
      this.duration = val;
    },
    'taskData.estimation': function (val) {
      this.estimation = val;
    },
  },
  methods: {
    handleChangeEstimation(event) {
      const { value } = event.target;
      const durationData = projectsModel.getProjectConfig(this.projectId).durationData;
      const parsedResult = timeParser.input(value, {
        durationData,
        prop: 'estimation',
      });

      if (!parsedResult || parsedResult.text === this.formattedEstimation) {
        this.estimation = this.taskData.estimation;
        this.estimationKey++;

        return;
      }

      this.$emit('change', { property: 'estimation', payload: parsedResult.value });
    },
    handleChangeDuration(event) {
      const { value } = event.target;
      const durationData = projectsModel.getProjectConfig(this.projectId).durationData;
      const parsedResult = timeParser.input(value, {
        durationData,
        durationStep: gantt.config.duration_view,
        prop: 'duration',
      });

      if (
        !parsedResult
        || parsedResult.text === this.formattedDuration
        || parsedResult.value > constants.DURATION_LIMITS.minute
      ) {
        this.duration = this.taskData.duration;
        this.durationKey++;

        return;
      }

      this.$emit('change', { property: 'duration', payload: parsedResult.value });
    },
    handleChange(property, value) {
      this.$emit('change', {
        property,
        payload: value,
      });
    },
    handleChangeStartTime(newValue) {
      const calendar = gantt.getCalendar(this.taskData.gantt_id);
      let time = null;

      if (!gantt.isWorkDay(newValue, calendar) || !gantt.isWorkTime(newValue, 'minute', calendar)) {
        time = gantt.getClosestWorkTime({
          date: newValue, dir: 'future', unit: gantt.config.duration_unit, task: this.taskData,
        });
      } else time = newValue;

      this.handleChange('start_date', time);
    },
    handleChangeEndTime(newValue) {
      const calendar = gantt.getCalendar(this.taskData.gantt_id);
      let time = null;

      if (!gantt.isWorkDay(newValue, calendar) || !gantt.isWorkTime(newValue, 'minute', calendar)) {
        time = gantt.getClosestWorkTime({
          date: newValue, unit: gantt.config.duration_unit, task: this.taskData,
        });
      } else time = newValue;

      this.handleChange('end_date', time);
    },
    handleChangeStartDate(newValue) {
      if (isDate(newValue) && isDate(this.taskData.start_date)) {
        const oldValue = this.taskData.start_date;
        const newValueWithLimitCheck = dateHelper.checkIfDateInLimit(newValue, true);

        const newDateClone = new Date(newValue.getTime());

        newDateClone.setHours(0, 0, 0, 0);

        const oldDateClone = new Date(oldValue.getTime());

        oldDateClone.setHours(0, 0, 0, 0);

        if (newDateClone.getTime() === oldDateClone.getTime()) return;

        if (newValueWithLimitCheck.getTime() !== newDateClone.getTime()) {
          newValue = newValueWithLimitCheck;
        }
      }

      const currentTaskStartDate = new Date(this.taskData.start_date);
      const [currentHours, currentMinutes] = [currentTaskStartDate.getHours(), currentTaskStartDate.getMinutes()];

      const newStartDate = this.getClosestDate(newValue, 'future');

      newStartDate.setHours(currentHours);
      newStartDate.setMinutes(currentMinutes);

      this.handleChange('start_date', newStartDate);
    },
    handleChangeEndDate(newValue) {
      if (isDate(newValue) && isDate(this.taskData.end_date)) {
        const newValueWithLimitCheck = dateHelper.checkIfDateInLimit(newValue, true);

        const newDateClone = new Date(newValue.getTime());

        newDateClone.setHours(0, 0, 0, 0);

        const oldDateClone = new Date(this.taskData.end_date.getTime());

        oldDateClone.setHours(0, 0, 0, 0);

        if (newDateClone.getTime() === oldDateClone.getTime()) return;

        if (newValueWithLimitCheck.getTime() !== newDateClone.getTime()) {
          newValue = newValueWithLimitCheck;
        }
      }
      const currentTaskEndDate = new Date(this.taskData.end_date);
      const [currentHours, currentMinutes] = [currentTaskEndDate.getHours(), currentTaskEndDate.getMinutes()];

      const newEndDate = this.getClosestDate(newValue, 'past');

      newEndDate.setHours(currentHours);
      newEndDate.setMinutes(currentMinutes);

      this.handleChange('end_date', newEndDate);
    },
    handleToggleDeadline() {
      const deadlineEnabled = !this.taskData.deadline;
      const newDeadline = deadlineEnabled ? this.endOfDay(new Date(this.taskData.end_date)) : null;

      this.handleChange('deadline', {
        value: newDeadline,
        updateType: 'toggle',
      });
    },
    handleChangeDeadline(value) {
      const newDeadline = this.endOfDay(value);

      this.handleChange('deadline', {
        value: newDeadline,
        updateType: 'change',
      });
    },
    getClosestDate(date, direction) {
      let resDate = date;
      const taskData = routerHelper.isListViewRoute() ? this.taskData : gantt.getTask(this.taskData.id);
      const projectCalendar = gantt.getCalendar(this.projectId);

      if (!gantt.isWorkDay(resDate, projectCalendar)) {
        resDate = gantt.getClosestWorkTime({
          date: resDate, dir: direction, unit: gantt.config.duration_unit, task: taskData,
        });
      }

      return resDate;
    },
    endOfDay(date) {
      // should be 23:59:00
      return moment(date).endOf('day').subtract('minutes', 1).toDate();
    },
  },
};
</script>

<style module lang="less">

.task-settings-dates {
  display: grid;
  align-items: center;
  grid-template-columns: 200px 12px 200px 1px 80px 1px 86px 1px 144px;
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  margin-top: 20px;
  margin-bottom: 32px;
}

.custom-input-hover:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.task-settings-dates__column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  :global(.pricing-tooltip) {
    background-color: unset !important;
  }
}

.column__header {
  font-family: Lato-Bold, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &_disabled {
    color: #CCCCCC;
  }
}

.column__divider {
  height: 44px;
  background: #E5E5E5;
}

.date-with-time-column {
  display: flex;
  flex-direction: column;
}

.date-with-time-column__inputs {
  display: grid;
  grid-template-columns: 123px auto;
  grid-column-gap: 2px;
  grid-template-rows: 1fr;
  align-items: center;
  &_disabled {
    cursor: default !important;
  }
}

.deadline-column__input-wrap {
  height: 100%;
  max-height: 32px;
  gap: 4px;
}

.start-end-dates-column__headers-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .column__header {
    margin-left: 12px;
  }
}

.text-align-right {
  input {
    text-align: right;
  }
}

.dates-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato-Regular, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #191919;
  height: 32px;
  margin-top: 22px;
}

</style>
