import app from '../../app';
import _ from '../../libs/lodash';

import constants from '../../helpers/constants';
import composedPath from '../../helpers/composedPath';

import calendarTemplates from './commonTemplates';

import customDaysModel from '../../models/customDays';
import customDayTemplatesModel from '../../models/customDayTemplates';

import settingsCustomDays from '../settings/include/settingsCustomDays';

import {
  closeCross, tableIcon, calendarIcon, arrowDown, icon_left_arrow, icon_right_arrow, trashIcon,
} from './icons';
import routerHelper from '../../helpers/router';
import calculateMainWindowSize from '../../helpers/calculateMainWindowSize';

const __ = window.__;

const innerSettings = {
  isOpened: false,
  postfixForAddLayout: constants.CALENDARS.LAYOUT_TYPES.TEMPLATE_CALENDAR,
  currentSettings: {},
};

const innerTemplates = {
  getLayoutHeader() {
    return `${''
      + "<p class='header_title manage'>"
      + '<span>'}${__('template_editor_layout_title')}</span>`
      + '</p>'
      + '<div class=\'header_button white\'>'
      + `<div class='arrow'>${
        closeCross
      }</div>`
      + '</div>';
  },
  getBodyHeader(config) {
    return `${''
      + "<div class='body_text'>"
      + "<p class='title'>"}${config.title}</p>`
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
  getBodyToggleLayout(config) {
    const activeTemplateName = innerSettings.activeTemplate ? innerSettings.activeTemplate.name : __('templates_choose');

    return `${''
      + "<div class='body_text custom-days-toggle'>"
      + "<div class='body_text_row'>"
      + "<div class='custom-days-button'>"
      + "<div class='webix_template'>"
      + "<div class='custom-days-button-inner'>"
      + "<div class='custom-days-button-inner-text'>"}${activeTemplateName}</div>`
      + `<div class='custom-days-button-inner-icon'>${arrowDown}</div>`
      + '</div>'
      + '</div>'
      + '</div>'
      + '<div class=\'btn_group\'>'
      + `<div class='custom-days-year ${config.tableView ? 'hidden' : ''}'>`
      + `<div class='custom-days-arrowLeft'>${icon_left_arrow}</div>`
      + `<div class='custom-days-yearText'>${config.currentYear}</div>`
      + `<div class='custom-days-arrowRight'>${icon_right_arrow}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? 'active' : ''} btn-table'>`
      + `<div class='btn-icon'>${tableIcon}</div>`
      + `<div class='btn-text'>${__('table')}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? '' : 'active'} btn-calendar'>`
      + `<div class='btn-icon'>${calendarIcon}</div>`
      + `<div class='btn-text'>${__('calendar')}</div>`
      + '</div>'
      + '</div>'
      + '</div>'
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
  getLayoutFooter() {
    const hasActiveTemplate = !!innerSettings.activeTemplate;

    return `<div class='calendar-layout-footer-row ${hasActiveTemplate ? 'hasActiveTemplate' : ''}'>`
      + '<div class=\'calendar-layout-footer-container-delete\'>'
      + `<div class='calendar-layout-footer-container-delete-icon'>${trashIcon}</div>`
      + `<div class='calendar-layout-footer-container-delete-text'>${__('delete_template')}</div>`
      + '</div>'
      + '<div class=\'calendar-layout-footer-container-buttons\'>'
      + `<div class='calendar-layout-footer-button calendar-layout-footer-button-copy'>${__('common_copy')}</div>`
      + `<div class='calendar-layout-footer-button calendar-layout-footer-button-rename'>${__('common_rename')}</div>`
      + `<div class='calendar-layout-footer-button calendar-layout-footer-button-new'>${__('common_new')}</div>`
      + '</div>'
      + '</div>';
  },
};

var innerHandlers = {
  cancelAddingCustomDay() {
    innerHandlers.showAddingButtonAndHideForm();
  },
  updateCustomDaysList(customDays) {
    const $$templateCustomDaysList = $$(innerViews.templateCustomDaysList.id);
    const $$templateCustomDaysListTableLayout = $$(innerViews.templateCustomDaysListTableLayout.id);

    $$templateCustomDaysList.clearAll();
    $$templateCustomDaysList.parse(customDays);
    if (customDays.length) {
      !$$templateCustomDaysListTableLayout.isVisible() && $$templateCustomDaysListTableLayout.show();
    } else {
      $$templateCustomDaysListTableLayout.isVisible() && $$templateCustomDaysListTableLayout.hide();
    }

    innerHandlers.noExtensionLayout($$templateCustomDaysList);

    webix.delay(() => {
      $$templateCustomDaysList.refresh();
    }, null, [], 100);
  },
  saveNewCustomDay(customDay) {
    customDaysModel.addNewItem(customDay);
    innerHandlers.showAddingButtonAndHideForm();
    innerHandlers.initLayoutByTemplateId(innerSettings.activeTemplate.id);
  },
  showCustomDaysInCalendarView(customDays) {
    settingsCustomDays.helpers.updateCustomDays(customDays);
  },
  showCustomDaysInTableView(customDays) {
    innerHandlers.updateCustomDaysList(customDays);
  },
  noExtensionLayout(webixUI) {
    const $$customDaysList = webixUI || $$(innerViews.templateCustomDaysList.id);

    if ($$customDaysList.count() > 0) {
      settingsCustomDays.handlers.hideNoExceptions(innerSettings.postfixForAddLayout);
    } else {
      settingsCustomDays.handlers.showNoExceptions(innerSettings.postfixForAddLayout);
    }
  },
  initLayoutWithoutTemplate() {
    settingsCustomDays.init.initCustomDayTemplates(null);
    settingsCustomDays.init.changeUserTemplate(null);
    innerHandlers.showCustomDaysInTableView([]);
    innerHandlers.showCustomDaysInCalendarView([]);
    innerHandlers.hideAddButton();
    $$(innerViews.footerTemplateLayout.id).refresh();
    $$(innerViews.templateEditorLayoutBodyToggle.id).refresh();
    $$(innerViews.addCustomDayButton.id).refresh();
  },
  initLayoutByTemplateId(templateID) {
    const template = customDayTemplatesModel.getTemplateDataByTemplateId(templateID);

    innerSettings.activeTemplate = template;
    settingsCustomDays.init.initCustomDayTemplates(templateID);
    settingsCustomDays.init.changeUserTemplate(templateID);
    innerHandlers.showCustomDaysInTableView(template.customDays);
    innerHandlers.showCustomDaysInCalendarView(template.customDays);
    $$(innerViews.templateCustomDaysListLayout.id).resize();
    $$(innerViews.footerTemplateLayout.id).refresh();
    innerHandlers.showAddingButtonAndHideForm();
    $$(innerViews.addCustomDayButton.id).refresh();
  },
  refreshChoosePopup() {
    const templates = customDayTemplatesModel.getUserTemplateList();
    const $$templateList = $$(innerViews.templateChoosePopup.body.id);

    $$templateList.clearAll();
    $$templateList.parse(templates);
  },
  initChoosePopup() {
    const templates = customDayTemplatesModel.getUserTemplateList();
    const $$templateList = $$(innerViews.templateChoosePopup.body.id);

    $$templateList.clearAll();
    $$templateList.parse(templates);

    if (innerSettings.activeTemplate) {
      innerSettings.activeTemplate = _.find(templates, { id: innerSettings.activeTemplate.id }) || {};
      innerSettings.activeTemplate.customDays = innerSettings.activeTemplate.customDays || [];
      customDaysModel.initData(innerSettings.activeTemplate.customDays, innerSettings.activeTemplate.id);
      innerHandlers.showAddingButtonAndHideForm();
    } else {
      customDaysModel.initData([]);
      innerHandlers.hideAddButton();
    }
  },
  initCalendarView() {
    settingsCustomDays.helpers.changeMainId('templateCustomDaysMainScrollView');
    $$(innerViews.main.id).show();

    innerHandlers.initChoosePopup();
  },
  showAddButton() {
    if (innerSettings.activeTemplate) {
      $$('wrapperDayButtonAddForm').show();
      $$(innerViews.addCustomDayButton.id).show();
    }
  },
  hideAddButton() {
    $$('wrapperDayButtonAddForm').hide();
    $$(innerViews.addCustomDayButton.id).hide();
  },
  showAddingCustomDayForm() {
    settingsCustomDays.handlers.showAddingForm(innerSettings.postfixForAddLayout);
  },
  hideAddingCustomDayForm() {
    settingsCustomDays.handlers.hideAddingForm(innerSettings.postfixForAddLayout);
  },
  showAddingButtonAndHideForm() {
    innerHandlers.hideAddingCustomDayForm();
    innerHandlers.showAddButton();
  },
};

var innerViews = {
  main: {
    id: 'templateEditorExceptionsPopup',
    css: 'ganttpro-layout calendar-layout',
  },
  addCustomDayButton: {
    view: 'button',
    id: 'addCustomDayForTemplatеButton',
    label: __('custom_day_add_button'),
    width: 300,
    height: 48,
    css: 'custom-days-add-button',
    hidden: !!innerSettings.activeTemplate,
    on: {
      onItemClick() {
        innerHandlers.showAddingCustomDayForm();
        this.hide();
      },
    },
  },
  templateEditorLayoutBody: {
    id: 'templateEditorLayoutBody',
    css: 'body',
  },
  templateEditorLayoutBodyHeader: {
    id: 'templateEditorLayoutBodyHeader',
    view: 'template',
    borderless: true,
    template: innerTemplates.getBodyHeader({
      title: __('calendar_layout_body_title'),
      txt: __('calendar_layout_body_txt'),
    }),
  },
  templateEditorLayoutBodyToggle: {
    id: 'templateEditorLayoutBodyToggle',
    view: 'template',
    css: 'templateEditorLayoutBodyToggle',
    height: 125,
    borderless: true,
    currentYear: null,
    tableView: false,
    template(item, common) {
      return innerTemplates.getBodyToggleLayout({
        title: __('templates_toggle_layout_body_title'),
        txt: __('templates_toggle_layout_body_txt'),
        currentYear: this.currentYear,
        tableView: this.tableView,
      });
    },
    onClick: {
      'btn-table': function () {
        this.config.tableView = true;
        if (this.config.tableView) {
          $$(innerViews.customDaysCalendarLayout.id).hide();
          $$(innerViews.templateCustomDaysListLayout.id).show();

          if (_.keys($$(innerViews.templateCustomDaysList.id).data.pull).length) {
            $$(innerViews.templateCustomDaysListTableLayout.id).show();
          } else {
            $$(innerViews.templateCustomDaysListTableLayout.id).hide();
          }
        }
        $$(innerViews.templateEditorLayoutBodyToggle.id).refresh();
      },
      'custom-days-button': function () {
        $$(innerViews.templateChoosePopup.id).show(
          this.getNode().querySelector('.custom-days-button'),
          { x: 0, y: 5 },
        );
      },
      'btn-calendar': function () {
        innerHandlers.cancelAddingCustomDay();
        // settingsCustomDays.handlers.reconstructAddForm(innerSettings.postfixForAddLayout);
        this.config.tableView = false;
        if (!this.config.tableView) {
          $$(innerViews.templateCustomDaysListLayout.id).hide();
          $$(innerViews.customDaysCalendarLayout.id).show();
        }
        $$(innerViews.templateEditorLayoutBodyToggle.id).refresh();
      },
      'custom-days-arrowLeft': function () {

        settingsCustomDays.helpers.changeYear('left',
          innerViews.templateEditorLayoutBodyToggle.id, innerViews.templateCustomDaysMainScrollView.id);
      },
      'custom-days-arrowRight': function () {
        settingsCustomDays.helpers.changeYear('right',
          innerViews.templateEditorLayoutBodyToggle.id, innerViews.templateCustomDaysMainScrollView.id);
      },
    },
    on: {
      onBeforeRender() {
        innerHandlers.refreshChoosePopup();
      },
      onAfterRender: () => {
      },
    },
  },
  templateCustomDaysMainScrollView: {
    id: 'templateCustomDaysMainScrollView',
  },
  customDaysCalendarLayout: {
    id: 'templateCustomDaysCalendarLayout',
  },
  templateCustomDaysListLayout: {
    id: 'templateCustomDaysListLayout',
  },
  templateCustomDaysListTableLayout: {
    id: 'templateCustomDaysListTableLayout',
  },
  templateCustomDaysList: {
    view: 'activeDataTable',
    id: 'templateCustomDaysList',
    css: 'custom-days-list',
    width: 889,
    autoheight: true,
    header: false,
    scroll: true,
    borderless: true,
    editable: true,
    scrollY: true,
    scrollX: false,
    prerender: false,
    rowHeight: 66,
    data: [],
    columns: [
      {
        id: 'id',
        fillspace: true,
        header: false,
        template(object) {
          const name = calendarTemplates.getCustomDayName(object);
          const info = calendarTemplates.getCustomDayInfo(object);
          const type = calendarTemplates.getCustomDayType(object);

          return calendarTemplates.customDaysListItem(name, info, type);
        },
      },
    ],
    onClick: {
      'custom-days-list-item': function (event, data, node) {
        const customDayData = this.getItem(data.row);
        const path = event.path || (event.composedPath && event.composedPath()) || composedPath(event.target);

        if (_.some(path, item => _.includes(item.className, 'custom-days-list-item-remove'))) {
          // webix.alert({
          //   ok: __("common_yes"),
          //   cancel: __("common_no"),
          //   width: 420,
          //   text: __("custom_day_remove_message"),
          //   type: "confirm-error",
          //   callback: function (result) {
          //     if (result) {
          //       customDaysModel.deleteCustomDay(data.row);
          //     }
          //   }
          // });
          webix.confirm({
            ok: __('common_yes'),
            cancel: __('common_no'),
            width: 420,
            text: __('custom_day_remove_message'),
            type: 'confirm-error',
          }).then(result => {
            if (result) {
              customDaysModel.deleteCustomDay(data.row);
            }
          });
        } else {
          settingsCustomDays.helpers.showCustomDayEditPopup({
            node: node.querySelector('.custom-days-list-item-edit'),
            config: {},
            data: customDayData,
            workMode: innerSettings.postfixForAddLayout,
          });
        }
      },
    },
    on: {
      onBeforeRender() {
        if (this.count() > 0) {
          this.config.rowHeight = 66;
          this.config.height = this.count() * this.config.rowHeight;
        } else {
          this.config.rowHeight = 0;
          this.config.height = 'auto';
        }
      },
    },
  },
  templateChoosePopup: {
    id: 'templateChoosePopup',
    css: 'template-manage-popup',
    view: 'popupWithoutPadding',
    width: 288,
    body: {
      view: 'list',
      css: 'custom-days-templates-menu',
      id: 'templateChooseList',
      data: [],
      template: '#name#',
      //autoheight: true,
      height: 244,
      select: true,
      borderless: true,
      on: {
        onItemClick(id, e, node) {
          const item = this.getItem(id);

          innerHandlers.initLayoutByTemplateId(item.id);

          const templateNameElement = document.querySelector('.custom-days-button-inner-text');

          if (templateNameElement) {
            templateNameElement.textContent = item.name;
          }

          this.hide();
        },
      },
    },
  },
  footerTemplateLayout: {
    id: 'footer-template-layout',
  },
};

webix.ui({
  view: 'window',
  id: innerViews.main.id,
  css: innerViews.main.css,
  borderless: true,
  hidden: true,
  zIndex: 105,
  // header: false,
  head: false,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state, true]);
  },
  modal: false,
  move: false,
  body: {
    rows: [
      {
        css: 'header topHeaderShadow',
        view: 'template',
        height: 60,
        borderless: true,
        template: innerTemplates.getLayoutHeader,
        onClick: {
          header_button() {
            app.trigger('templateEditor:close');
            $$(innerViews.main.id).hide();
          },
        },
      },
      {
        view: 'scrollview',
        borderless: true,
        id: innerViews.templateEditorLayoutBody.id,
        css: innerViews.templateEditorLayoutBody.css,
        scroll: 'y',
        autoheight: true,
        body: {
          rows: [
            { height: 48 },
            innerViews.templateEditorLayoutBodyToggle,
            {
              id: innerViews.templateCustomDaysListLayout.id,
              autoheight: true,
              hidden: true,
              rows: [
                {
                  id: innerViews.templateCustomDaysListTableLayout.id,
                  hidden: true,
                  cols: [
                    {},
                    innerViews.templateCustomDaysList,
                    {},
                  ],
                },
                {
                  height: 24,
                },
                {
                  id: 'wrapperDayButtonAddForm',
                  hidden: true,
                  cols: [
                    {},
                    innerViews.addCustomDayButton,
                    settingsCustomDays.init.createAddForm(innerSettings.postfixForAddLayout),
                    {},
                  ],
                },
                settingsCustomDays.init.createNoExceptions(innerSettings.postfixForAddLayout),
              ],
            },
            {
              id: innerViews.customDaysCalendarLayout.id,
              rows: [
                {
                  cols: [
                    {},
                    settingsCustomDays.helpers.createCalendarLayout({
                      mainId: innerViews.templateCustomDaysMainScrollView.id,
                    }),
                    {},
                  ],
                },
                { height: 24 },
              ],
            },
            { height: 48 },
          ],
        },
        on: {
          onAfterScroll: () => {
            webix.callEvent('onClick', []);
          },
        },
      },
      {
        view: 'template',
        height: 60,
        id: 'footer-template-layout',
        css: 'calendar-layout-footer',
        borderless: false,
        template: innerTemplates.getLayoutFooter,
        onClick: {
          'calendar-layout-footer-button-new': function () {
            settingsCustomDays.handlers.buttonNewUserTemplateClick();
          },
          'calendar-layout-footer-button-rename': function () {
            settingsCustomDays.handlers.buttonRenameUserTemplateClick();
          },
          'calendar-layout-footer-button-copy': function () {
            settingsCustomDays.handlers.buttonCopyUserTemplateClick();
          },
          'calendar-layout-footer-container-delete': function () {
            settingsCustomDays.handlers.buttonDeleteUserTemplateClick();
          },
        },
      },
    ],
  },
  on: {
    onHide() {
      innerSettings.isOpened = false;
      app.trigger('popup:hide');
      settingsCustomDays.helpers.resetSelectedAndHideTemplatePopup();
      webix.callEvent('onResize', []);
      app.trigger('init:customDay:project');
    },
    onShow() {
      innerSettings.isOpened = true;
      webix.callEvent('onResize', []);
      $$(innerViews.templateEditorLayoutBody.id).resize();
    },
  },
});

webix.ui(innerViews.templateChoosePopup);

app.on('popup:show', () => {
  $$(innerViews.main.id).hide();
});

app.on('app:route:changed', () => {
  $$(innerViews.main.id).hide();
});

app.on('popup:closeOther', id => {
  if (id !== innerViews.main.id) {
    $$(innerViews.main.id).hide();
  }
});

app.on('body:resize', data => {
  $$(innerViews.main.id).resize();
});

app.on('leftSideBar:changedMode', data => {
  $$(innerViews.main.id).resize();
});

app.on('customDays:insert', () => {
  if (innerSettings.isOpened && innerSettings.activeTemplate && innerSettings.activeTemplate.id) {
    innerHandlers.initLayoutByTemplateId(innerSettings.activeTemplate.id);
  }
});

app.on('customDays:live:update', () => {
  if (innerSettings.isOpened && innerSettings.activeTemplate && innerSettings.activeTemplate.id) {
    innerHandlers.initLayoutByTemplateId(innerSettings.activeTemplate.id);
  }
});

app.on('customDays:update', () => {
  if (innerSettings.isOpened && innerSettings.activeTemplate && innerSettings.activeTemplate.id) {
    innerHandlers.initLayoutByTemplateId(innerSettings.activeTemplate.id);
  }
});

app.on('customDays:delete', () => {
  if (innerSettings.isOpened && innerSettings.activeTemplate && innerSettings.activeTemplate.id) {
    innerHandlers.initLayoutByTemplateId(innerSettings.activeTemplate.id);
  }
});

app.on('customDays:projectSettings:init', templateID => {
  if (!templateID) {
    innerSettings.activeTemplate = null;
    innerHandlers.initCalendarView();
    innerHandlers.initLayoutWithoutTemplate();

    return;
  }

  innerSettings.activeTemplate = customDayTemplatesModel.getTemplateDataByTemplateId(templateID);

  innerHandlers.initLayoutByTemplateId(templateID);
  $$(innerViews.templateEditorLayoutBodyToggle.id).refresh();
});

app.on('save:customDay:template', customDay => {
  innerHandlers.saveNewCustomDay(customDay);
});

app.on('cancel:customDay:template', () => {
  innerHandlers.showAddingButtonAndHideForm();
  innerHandlers.noExtensionLayout($$(innerViews.templateCustomDaysList.id));
});

app.on('gantt:init:before', () => {
  !routerHelper.isListViewRoute() && settingsCustomDays.init.addViewToForm(innerSettings.postfixForAddLayout);
});

const outputObject = {
  init(ganttId) {
    if (!innerSettings.isOpened) {
      innerSettings.activeTemplate = null;
      innerSettings.isOpened = true;
      innerHandlers.initCalendarView();
      innerHandlers.initLayoutWithoutTemplate();
      $$(innerViews.customDaysCalendarLayout.id).reconstruct();
    } else {
      innerSettings.isOpened = false;
      $$(innerViews.main.id).hide();
    }
  },
};

export default outputObject;
