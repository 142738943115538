var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: { show: _vm.showPopup, width: "440" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { ref: "popup", class: [_vm.$style.template_popup] }, [
              _c(
                "div",
                {
                  ref: "popup_container",
                  class: [_vm.$style.template_popup_container],
                },
                [
                  _c("div", { class: [_vm.$style.header] }, [
                    _c("div", { class: [_vm.$style.header__title] }, [
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("new_project_advanced_title")) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("vgp-icon-button", {
                          class: [_vm.$style.header__close_icon],
                          attrs: {
                            size: "small",
                            colors: _vm.buttonColors,
                            icon: { name: "close-1", type: "bold", size: 24 },
                          },
                          on: { onClick: _vm.onClickCancel },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$style.body] }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.locales("new_project_advanced_description")
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.separator }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.$style.settings_list] },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "add-column-" + index,
                          class: [
                            _vm.$style.settings,
                            item.tooltipKey &&
                            !_vm.datePickerHovered &&
                            !_vm.datePickerOpened
                              ? "tooltip-gantt"
                              : "",
                            item.suggest ? _vm.$style.settings_suggest : "",
                          ],
                          attrs: {
                            "data-key": item.tooltipKey ? item.tooltipKey : "",
                            "data-position": "left",
                            "data-y": 8,
                            "data-x": 10,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.$style.settings,
                                item.disabled
                                  ? _vm.$style.settings_disabled
                                  : "",
                              ],
                            },
                            [
                              _c(
                                "div",
                                { class: [_vm.$style.setting_checkbox] },
                                [
                                  _c("vgp-checkbox", {
                                    attrs: {
                                      size: "16",
                                      checked: item.selected,
                                    },
                                    on: {
                                      onClick: function ($event) {
                                        return _vm.handleCheckbox(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: [_vm.$style.setting_label],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckbox(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.locales(item.label)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.hasCalendar
                            ? _c(
                                "div",
                                {
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.datePickerHovered = true
                                    },
                                    mouseleave: function ($event) {
                                      _vm.datePickerHovered = false
                                    },
                                  },
                                },
                                [
                                  _c("vgp-date-picker", {
                                    class: [_vm.$style.date_picker],
                                    attrs: {
                                      "is-show-today-btn": true,
                                      value: _vm.projectStartDate,
                                      "work-days": _vm.items.find(function (
                                        item
                                      ) {
                                        return (
                                          item.id === "copyCalendar" &&
                                          item.selected
                                        )
                                      })
                                        ? _vm.templateWorkDays
                                        : _vm.defaultWorkDays,
                                    },
                                    on: {
                                      onChange: _vm.handleChangeStartDate,
                                      onShow: function ($event) {
                                        _vm.datePickerOpened = true
                                      },
                                      onClose: function ($event) {
                                        _vm.datePickerOpened = false
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var inputValue = ref.inputValue
                                            var togglePopover =
                                              ref.togglePopover
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  class: [
                                                    "vgp_date_picker_input",
                                                    _vm.isFieldDisabled(
                                                      "startDate"
                                                    )
                                                      ? _vm.$style
                                                          .date_input_disabled
                                                      : null,
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return (function () {
                                                        return togglePopover()
                                                      })($event)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-sprite", {
                                                    class: [
                                                      "vgp_date_picker_input_icon",
                                                    ],
                                                    attrs: {
                                                      name: "calendar",
                                                      type: "regular",
                                                      size: 24,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        "vgp_date_picker_input_text",
                                                      ],
                                                    },
                                                    [_vm._v(_vm._s(inputValue))]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "footer",
                                          fn: function (ref) {
                                            var setValue = ref.setValue
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  class: [
                                                    "vgp_date_picker_footer_button",
                                                    _vm.$style.set_today_button,
                                                  ],
                                                  style: !_vm.templateStartDate
                                                    ? {
                                                        opacity: 0.4,
                                                        textDecoration: "none",
                                                      }
                                                    : {},
                                                  attrs: {
                                                    disabled:
                                                      !_vm.templateStartDate,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return (function () {
                                                        return (
                                                          !_vm.isEqualDates(
                                                            _vm.projectStartDate,
                                                            _vm.templateStartDate
                                                          ) &&
                                                          setValue(
                                                            _vm.templateStartDate
                                                          )
                                                        )
                                                      })($event)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.locales(
                                                          "new_project_advanced_set_from_template_label"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  class:
                                                    "vgp_date_picker_footer_button",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return (function () {
                                                        return (
                                                          !_vm.isEqualDates(
                                                            _vm.projectStartDate,
                                                            new Date()
                                                          ) &&
                                                          setValue(new Date())
                                                        )
                                                      })($event)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.locales(
                                                          "datepiker_today"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$style.footer_button] }, [
                    _c(
                      "div",
                      { class: [_vm.$style.apply] },
                      [
                        _c("vgp-button", {
                          attrs: {
                            type: "primary",
                            small: true,
                            label: _vm.locales("common_apply"),
                          },
                          on: { onClick: _vm.onClickOk },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }