import _ from "./../../../libs/lodash";
import xlsxExport from "./../../../views/export/xlsxExport";

// it's hacky to have 2 models here, but we to it to d not calculate path
// for the whole collection without export to excel
// because it's better to cache  it
import projectsModel from "./../../../models/projects";
import globalStore from '../../../store/main';
// ----------------------------------------------------------------------


const __ = window.__;
const levelColumn = '$level';
const taskPathColumn = 'project_name';
const indentColumn = 'project';


const exportToExcel = function (options) {

  const exportOptions = {
    title: options.title,
    worksheets: []
  };

  const splitLevelsToColumns = {
    depth: innerHelpers.getMaxDepthBylevelColumn(options.data, levelColumn) - 1,
    indentColumn,
    levelColumn,
    label: __("export_excel_task_name")
  };

  const columns = innerHelpers.prepareColumns(options.columns, { splitLevelsToColumns: options.isTree?splitLevelsToColumns:false, isTree: options.isTree });
  const rows = innerHelpers.prepareData(options.data, columns);

  exportOptions.worksheets.push({
    title: options.title,
    rows,
    columns
  });

  xlsxExport.export(exportOptions);
};

const innerHelpers = {

  prepareColumns(columns, options) {
    let splitLevelsToColumns = options.splitLevelsToColumns;
    let cols = [];
    if (splitLevelsToColumns && splitLevelsToColumns.depth) {
      for (let i = 0; i < splitLevelsToColumns.depth; i++) {
        let index = (i + 1);
        let label = '';//(splitLevelsToColumns.depth === index) ? splitLevelsToColumns.label : '';
        cols.push({
          label: label,
          key: splitLevelsToColumns.indentColumn,
          colWidth: 7,
          preprocessor: function (row, column) {
            let out = { value: '' };
            if (row[splitLevelsToColumns.levelColumn] === index) {
              out.value = row[splitLevelsToColumns.indentColumn];
              out.merge = { horizontal: splitLevelsToColumns.depth - index + 1 };
            }
            return out;
          }
        });
      }
    }

    _.each(columns, column => {
      let header = column.header;
      if(column.id === 'time' || column.id === 'time_logs'){
        header = `${column.header} (${__('common_minutes').toLowerCase()})`
      }

      const col = {
        label: header,
        key: column.id,
        type: column.excelFormat
      }

      if (column.id === "task_name") {
        col.preprocessor = (row) => ({ value: row.task_name});
      }

      if (column.id === "project_name") {
        col.preprocessor = (row) => ({ value: row.project_name});
      }

      cols.push(col);
    });

    if (options.isTree) {
      cols.push({
        label: __('export_excel_level'),
        key: '$level',
        type: 'number'
      });
    }

    return cols;
  },

  getMaxDepthBylevelColumn: function (rows, levelColumn) {
    let depth = 0;
    rows.map(function (row, j) {
      if (row.data) {
        depth = innerHelpers.getMaxDepthBylevelColumn(row.data, levelColumn)
      } else if (row[levelColumn] > depth) {
        depth = row[levelColumn];
      }
    });
    return depth;
  },

  getTaskParentsPath (taskId, ganttId) {
    const breadcrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](taskId, ganttId, {});
    const projectData = projectsModel.getProjectDataById(ganttId);
    const breadcrumbsText = breadcrumbsTasks.reduce(function (sum, elem) {
      return sum + " / " + elem.text;
    }, "");
    const result = projectData.name + breadcrumbsText;

    return result;
  },

  prepareData(data, columns, parentLevel = 0) {
    let level = parentLevel + 1;
    let out = [];

    _.each(data, (obj, i) => {
      let row = {};
      let isGroupedRow = !_.isUndefined(obj.groupValue);
      _.each(columns, (column, j) => {
        let formattedValue = '';
        if (!_.isUndefined(obj[column.key])) {
          if (column.key === levelColumn) {
            formattedValue = level;
          } else if (column.key === taskPathColumn && obj.task_id && obj.gantt_id) {
            formattedValue = innerHelpers.getTaskParentsPath(obj.task_id, obj.gantt_id);
          } else {
            formattedValue = obj[column.key];
          }
        }

        if (isGroupedRow && column.key === indentColumn) {
          formattedValue = obj.groupValue;
        }
        row[column.key] = formattedValue;
      });

      let attributes = isGroupedRow ? {
        font: { bold: true },
        border: {
          top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
          bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
          right: { style: 'thin', color: { argb: 'FFBFBFBF' } }
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFEEEEEE'
          }
        }
      } : {};
      out.push({ data: row, attributes });

      if (obj.data) {
        out = out.concat(innerHelpers.prepareData(obj.data, columns, level));
      }
    });

    return out;
  }
}

const outerObject = {
  exportToExcel
};

export default outerObject;
