var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$.chart, _vm.$.chart_project_info] }, [
    _c(
      "div",
      { class: _vm.$.header },
      [
        _c("div", { class: _vm.$.title }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("chart_" + _vm.chart.key + "_title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.statusSwitch
          ? _c("vgp-switch", {
              attrs: { "is-toggle-on": _vm.chart.status },
              on: {
                onChange: function ($event) {
                  return _vm.$emit("onToggleStatus")
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$.wrapper }, [
      _c("div", { class: _vm.$.row }, [
        _c("div", { class: _vm.$.col }, [
          _vm.description
            ? _c("div", { class: _vm.$.description }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.locales("chart_" + _vm.chart.key + "_description")
                    ) +
                    "\n        "
                ),
              ])
            : _c(
                "div",
                {
                  class: [
                    _vm.$.textarea,
                    !_vm.data.description ? _vm.$.placeholder : "",
                    "ql-snow",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onOpenDescription", $event)
                    },
                  },
                },
                [
                  !_vm.data.description
                    ? [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.locales(
                                "overview_project_description_placeholder"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    : _c("div", {
                        staticClass: "ql-editor",
                        domProps: { innerHTML: _vm._s(_vm.data.description) },
                      }),
                ],
                2
              ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$.col }, [
          _c("div", { class: [_vm.$.row, _vm.$.info] }, [
            _c("div", { class: _vm.$.col }, [
              _c("div", { class: _vm.$.team }, [
                _c("div", { class: _vm.$.line }, [
                  _c("div", { class: _vm.$.label }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.locales("overview_owner")) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    class: _vm.$.avatar,
                    style:
                      "background-image: url('" + _vm.data.owner.photo + "');",
                  }),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.name }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.data.owner.name) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$.line },
                  [
                    _c("div", { class: _vm.$.label }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.locales("overview_team")) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.teamPreview, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          style: { display: "flex", marginLeft: "-8px" },
                          on: {
                            mouseenter: _vm.onMouseEnterAvatar,
                            mouseleave: _vm.onMouseLeaveAvatar,
                          },
                        },
                        [
                          _c("vgp-avatar", {
                            attrs: { "custom-size": 24, src: item.photo },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.data.team.length > _vm.teamCount
                      ? _c(
                          "div",
                          {
                            ref: "avatarEtc",
                            class: [
                              _vm.$.avatar_etc,
                              _vm.isShowTeamDropdown ? _vm.$.active : "",
                            ],
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleTeamDropdown($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                  +" +
                                _vm._s(_vm.data.team.length - _vm.teamCount) +
                                "\n                  "
                            ),
                            _c("div", { class: _vm.$.triangle }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowTeamDropdown
                      ? _c("context-menu", {
                          staticStyle: {
                            "min-width": "226px",
                            "max-width": "300px",
                          },
                          attrs: {
                            position: _vm.positionTeamDropdown,
                            offset: { top: 4 },
                          },
                          on: {
                            closePopup: function ($event) {
                              _vm.isShowTeamDropdown = false
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("div", { class: _vm.$.list_title }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.locales("common_people"))
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.data.team,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            class: _vm.$.list_item,
                                          },
                                          [
                                            _c("vgp-avatar", {
                                              attrs: {
                                                "custom-size": 24,
                                                src: item.photo,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", { class: _vm.$.name }, [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(item.name) +
                                                  "\n                      "
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2342176871
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$.col }, [
              _c("div", { class: _vm.$.progress }, [
                _c("div", { class: _vm.$.label }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.locales("overview_progress")) +
                      ": " +
                      _vm._s(_vm.data.progress) +
                      "%\n              "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.bar }, [
                  _c("span", { style: "width: " + _vm.data.progress + "%" }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: [_vm.$.row, _vm.$.dates] }, [
            _c("div", { class: _vm.$.col }, [
              _c("div", { class: _vm.$.item }, [
                _c("div", { class: _vm.$.label }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.locales("overview_start_date")) +
                      "\n              "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.value }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.startDate) +
                      "\n              "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.item }, [
                _c("div", { class: _vm.$.value }, [
                  _vm._v("\n                —\n              "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.item }, [
                _c("div", { class: _vm.$.label }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.locales("overview_end_date")) +
                      "\n              "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.value }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.endDate) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$.col }, [
              _c("div", { class: _vm.$.item }, [
                _c("div", { class: _vm.$.label }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.locales("overview_last_change")) +
                      "\n              "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.value }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.lastUpdate) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }