<template>
  <div>
    <div
      v-if="uiConfig && uiConfig.existFooter"
      id="footer"
      :class="$style.footer"
    >
      <div v-if="isActiveUser && !isResourcesMoreThanPaid">
        <div
          v-if="uiConfig.attachment"
          :class="[$style.footer_item, $style.attachment_item]"
          @click="attachmentClick()"
        >
          <svg-sprite
            :class="$style.footer_icons"
            name="attach"
            :size="20"
          />
          <span> {{ itemLocales.attachment }} </span>
        </div>

        <div
          v-if="uiConfig.timer"
          ref="timer_btn"
          :class="[
            $style.footer_item,
            { 'pricing-tooltip': restrictAccessToTimeLog }
          ]"
          data-feature="time_tracking"
          data-position="bottom"
          @click="!restrictAccessToTimeLog && timerClick()"
        >
          <svg-sprite
            :class="$style.footer_icons"
            name="timer"
            :size="20"
          />
          <span
            v-if="timerValue"
            :class="[$style.timer_value]"
          >
            {{ timerValue }}
          </span>
          <span v-else>
            {{ itemLocales.timer }}
          </span>
        </div>
      </div>

      <div />

      <div>
        <div :class="[$style.footer_item, $style.footer_item_store]">
          <span :class="$style.footer_item_download"> {{ download.txt }} </span>
          <a
            target="_blank"
            :class="$style.store_icon"
            :href="download.google.url"
            @click="sendAnalytic('android')"
          >
            <svg-sprite
              :class="$style.footer_icons"
              name="google-play"
              :size="20"
            />
          </a>
          <a
            target="_blank"
            :class="$style.store_icon"
            :href="download.apple.url"
            @click="sendAnalytic('ios')"
          >
            <svg-sprite
              :class="$style.footer_icons"
              name="app-store"
              :size="20"
            />
          </a>
        </div>

        <div
          v-if="uiConfig.demo && !isDemoButtonHidden"
          id="footerBtnDemoId"
          ref="demo"
          :class="[
            $style.footer_item,
            {
              [$style['footer_item--active']]: isDemoNotificationPopupShown,
              [$style['footer_item--hovered']]: isDemoNotificationPopupShown && notificationPopupState.isHovered
            }
          ]"
          @click="demoClick('appBottomToolbarButton')"
        >
          <span> {{ itemLocales.demo }} </span>
        </div>

        <!-- <div
        v-if="uiConfig.video"
        :class="$style.footer_item"
        @click="videoClick()"
      >
        <webix-ui :config="icons.video" />
        <span> {{ itemLocales.video }} </span>
      </div> -->

        <div
          v-if="uiConfig.learningCenter"
          id="footerBtnLearningCenterId"
          ref="learningCenter"
          :class="[
            $style.footer_item,
            {
              [$style['footer_item--active']]: isNewsNotificationPopupShown,
              [$style['footer_item--hovered']]: isNewsNotificationPopupShown && notificationPopupState.isHovered
            }
          ]"
          @click="learningCenterClick"
        >
          <svg-sprite
            :class="$style.footer_icons"
            name="help"
            type="bold"
            :size="20"
          />
          <span> {{ itemLocales.learningCenter }} </span>
        </div>
        <div
          v-if="uiConfig.chat"
          id="footerBtnChatId"
          :class="$style.footer_item"
          @click="chatClick()"
        >
          <span> {{ itemLocales.chat }} </span>
        </div>
      </div>
      <learning-center-popup
        v-if="learningCenterPopupState.isShown"
        :last-clicked-tab="learningCenterPopupState.lastClickedTab"
        @rememberLastClickedTab="learningCenterPopupState.lastClickedTab = $event"
        @close="learningCenterPopupState.isShown = false"
      />

      <transition name="slide-up">
        <notify-popup
          v-if="notificationPopupState.isShown"
          :type="notificationPopupState.type"
          @close="notificationPopupState.isShown = false"
          @click="handleNotificationPopupClick"
          @highlight="notificationPopupState.isHovered = $event"
        />
      </transition>

      <attachments-popup
        :is-show="isShowAttacments"
        @onClose="isShowAttacments = false"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import app, { appIds } from '../../app';
import svgIconForVue from '../common/svgIconForVue';
import icoAttachment from '../../svg/footerToolbar/attachments_24.svg';
import icoTimer from '../../svg/footerToolbar/timer.svg';
import icoLearningCenter from '../../svg/footerToolbar/learning_center.svg';
import videoIco from '../../svg/footerToolbar/footer_video.svg';
import icGooglePlay from '../../svg/store_icons/ic_google-play.svg';
import icAppStore from '../../svg/store_icons/ic_app-store.svg';
import store from '../../store/main';
import { checkPricingAccess } from '../../helpers/pricingHelper';
import routerHelper from '../../helpers/router';
import NotifyPopup from '../notifyPopup/notifyPopup.vue';
import constants from '../../helpers/constants';
import { NOTIFICATION_POPUP_TYPE } from '../notifyPopup/notifyPopup.config';
import LearningCenterPopup from '../learningCenter/learningCenterPopup.vue';
import SvgSprite from '../common/VueComponents/VueIcon/svgSprite.vue';
import plansModel from '../../models/plans';
import attachmentsPopup from '../attachments/main.vue';

export default {
  name: 'FooterVue',
  components: {
    LearningCenterPopup, NotifyPopup, SvgSprite, attachmentsPopup,
  },
  store,
  data() {
    return {
      locales: __,
      refsMounted: false,
      refDemoMounted: false,
      refLearningCenterMounted: false,
      download: {
        txt: __('store_title'),
        google: {
          url: 'https://play.google.com/store/apps/details?id=com.ganttpro.app',
          icon: svgIconForVue(icGooglePlay, 24, 24),
        },
        apple: {
          url: 'https://apps.apple.com/us/app/ganttpro/id1579665713',
          icon: svgIconForVue(icAppStore, 24, 24),
        },
      },
      icons: {
        attachment: svgIconForVue(icoAttachment, 22, 24),
        timer: svgIconForVue(icoTimer, 17, 16),
        learningCenter: svgIconForVue(icoLearningCenter, 24, 24),
        video: svgIconForVue(videoIco, 24, 24),
      },
      itemLocales: {
        attachment: __('app_footer_attachments'),
        timer: __('app_footer_btn_tracker_text'),
        learningCenter: __('app_footer_btn_learning_text'),
        video: __('app_footer_btn_videos_text'),
        chat: __('app_footer_btn_chat_text'),
        demo: __('app_footer_btn_demo_text'),
      },
      notificationPopupState: {
        type: '',
        isHovered: false,
        isShown: false,
      },
      learningCenterPopupState: {
        isShown: false,
        lastClickedTab: '',
      },
      isResourcesMoreThanPaid: false,
      isDemoButtonHidden: !!plansModel.isPaidTeam() || user.settings.user_scoring_percent === 0,
      isShowAttacments: false,
    };
  },
  computed: {
    ...mapGetters({
      getActivityStatus: 'user/getActivityStatus',
    }),
    isNewsNotificationPopupShown() {
      return this.notificationPopupState.isShown && this.notificationPopupState.type === NOTIFICATION_POPUP_TYPE.NEWS;
    },
    isDemoNotificationPopupShown() {
      return this.notificationPopupState.isShown && this.notificationPopupState.type === NOTIFICATION_POPUP_TYPE.DEMO;
    },
    restrictAccessToTimeLog() {
      return !checkPricingAccess('time_tracking');
    },
    isActiveUser() {
      return this.$store.getters['footer/isActiveUser'];
    },
    currentRoute() {
      return this.$store.getters['headerView/currentRoute'];
    },
    timerValue() {
      return this.$store.getters['footer/timerValue'];
    },
    uiConfig() {
      return this.$store.getters['footer/UIConfig'];
    },
    readyToShowNewsPopup() {
      return this.refLearningCenterMounted && this.$store.state.user.isActivitiesLoaded;
    },
    readyToShowDemoPopup() {
      return this.refDemoMounted && this.$store.state.user.isActivitiesLoaded;
    },
    readyToShowNotificationPopup() {
      return this.readyToShowNewsPopup || this.readyToShowDemoPopup;
    },
  },

  watch: {
    readyToShowNotificationPopup: {
      handler(val) {
        if (val) {
          this.showNotifyPopupIfNeeded(true);
        }
      },
      immediate: true,
    },
    currentRoute(newVal, oldVal) {
      if (this.readyToShowNotificationPopup || oldVal.name === 'onboarding') {
        this.setIsDemoButtonHidden();
        this.showNotifyPopupIfNeeded();
      }
      this.$store.commit('footer/setUIConfig');
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.learningCenter) {
        this.refLearningCenterMounted = true;
      }

      if (this.$refs.demo) {
        this.refDemoMounted = true;
      }

      if (this.isDemoButtonHidden && this.refLearningCenterMounted) {
        clearInterval(interval);
      } else if (!this.isDemoButtonHidden && this.refLearningCenterMounted && this.refDemoMounted) {
        clearInterval(interval);
      }
    }, 50);

    this.isResourcesMoreThanPaid = plansModel.isResourcesMoreThanPaid();
  },
  created() {
    const component = this;
    const {
      wsEvents: {
        ID_EVENT_WS_NEWS_ENTRY_ADD,
      },
    } = appIds;

    app.on('app:initModels', () => {
      component.$store.commit('footer/setUIConfig');
      component.isResourcesMoreThanPaid = plansModel.isResourcesMoreThanPaid();
    });

    app.on('updateFooterGlobalTimer', timer_value => {
      component.$store.commit('footer/seTimerValue', timer_value);
    });

    app.on('app:route:changed', value => {
      component.isResourcesMoreThanPaid = plansModel.isResourcesMoreThanPaid();
      const footer = $$('footer');

      if (value?.params?.mode === 'export') {
        footer.$view.classList.add('footer_down_index');
      } else {
        footer.$view.classList.remove('footer_down_index');
      }
    });
    app.socket.on(ID_EVENT_WS_NEWS_ENTRY_ADD, this.handleOnAddNewsEntry);
  },

  methods: {
    setIsDemoButtonHidden() {
      this.isDemoButtonHidden = !!plansModel.isPaidTeam() || user.settings.user_scoring_percent === 0;
    },
    async showNotifyPopupIfNeeded(sendAnalytic) {
      if (!this.isPopupAboutToShow()) {
        return;
      }

      if (this.needToShowNewsPopupNotification()) {
        sendAnalytic && userExtAnalytics.log('app_notification_popup_shown', { type: 'news' });
        this.notificationPopupState.type = NOTIFICATION_POPUP_TYPE.NEWS;
        this.notificationPopupState.isShown = true;

        return;
      }

      if (this.needToShowDemoPopupNotification()) {
        sendAnalytic && userExtAnalytics.log('app_notification_popup_shown', { type: 'demo' });
        sendAnalytic && userExtAnalytics.log('demo_suggestion_shown', { type: 'footer_bubble' });
        this.notificationPopupState.type = NOTIFICATION_POPUP_TYPE.DEMO;
        this.notificationPopupState.isShown = true;
      }
    },
    isPopupAboutToShow() {
      return (
        this.$store.state.appMode.isBase
        && routerHelper.getCurrentRoute().params.mode !== 'export'
        && !this.learningCenterPopupState.isShown
      );
    },
    needToShowNewsPopupNotification() {
      const lastDisplayedActivityId = this.getActivityStatus(constants.USER_ACTIVITIES.LAST_NEWS_ID);
      const alreadyDisplayed = lastDisplayedActivityId && lastDisplayedActivityId === window.user.lastNewsEntryId;

      return (
        window.user.lastNewsEntryId
              && moment(window.user.registrationTime) < moment(window.user.lastNewsEntryDate)
              && !alreadyDisplayed
      );
    },
    needToShowDemoPopupNotification() {
      return (
        this.getActivityStatus(constants.USER_ACTIVITIES.DEMO_POPUP) !== 1
              && this.getActivityStatus(constants.USER_ACTIVITIES.HELP_ONBOARDING) === 0
              && !app.config.mode.isLink
              && !app.config.mode.isExport
              // && (user.settings.user_scoring_percent && user.settings.user_scoring_percent >= constants.MIN_USER_SCORING_PERCENT_FOR_DEMO_SCHEDULE)
              && ((user.isDemoTarget && user.settings.user_team && user.settings.user_team >= 4 && user.settings.user_planning_use_for !== 'study') || (user.settings.gp_utm && user.settings.gp_utm.includes('utm_medium=cpc')))
      );
    },
    handleOnAddNewsEntry(result) {
      if (Number(result.is_published) === 1 && Number(result.is_deleted) === 0) {
        const { id, date } = result;

        window.user.lastNewsEntryId = id;
        window.user.lastNewsEntryDate = date;
        this.showNotifyPopupIfNeeded(true);
      }
    },
    handleNotificationPopupClick(refName) {
      if (refName === NOTIFICATION_POPUP_TYPE.DEMO) {
        this.demoClick('appBottomToolbarBubble');
      } else {
        this.$refs[refName].click();
      }

      this.notificationPopupState.isShown = false;
    },
    sendAnalytic(type) {
      userExtAnalytics.log('mobile_gettapps_button_clicked', { type, from: 'footer' });
    },
    attachmentClick() {
      this.isShowAttacments = true;
    },

    demoClick(campaignName) {
      this.$store.dispatch('footer/demoClickHandler', { campaign: campaignName });
    },

    timerClick() {
      const pos = this.$refs.timer_btn.getBoundingClientRect();

      this.$store.dispatch('footer/timerClickHandler', pos);
    },

    videoClick() {
      userExtAnalytics.log('general_videos_clicked');
      window.open(__('app_links_gantt_videos'), '_blank');
    },
    learningCenterClick() {
      this.learningCenterPopupState.isShown = true;
      this.notificationPopupState.isShown = false;
      const lastDisplayedActivityId = this.getActivityStatus(constants.USER_ACTIVITIES.LAST_NEWS_ID);

      if (lastDisplayedActivityId !== window.user.lastNewsEntryId) {
        this.$store.dispatch(
          'user/updateActivityStatus',
          { activityName: constants.USER_ACTIVITIES.LAST_NEWS_ID, status: window.user.lastNewsEntryId },
        );
      }
    },
    chatClick() {
      this.$store.dispatch('footer/chatClickHandler');
    },
    checkPricingAccess,
  },
};

</script>

<style module src="./style.less" lang="less"></style>

<style scoped lang="less">
.slide-up-enter-active {
  transition: all .6s ease;
}
.slide-up-leave-active {
  transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(20vh);
  opacity: 0;
}
</style>
