<template>
  <div
    v-click-outside="close"
    :class="$style.select"
  >
    <vgp-icon-button
      ref="contextMenuButton"
      :icon="{ name: 'more-1', }"
      :colors="buttonColors"
      :active="showMenu"
      @onClick="toggleShow"
    />

    <context-menu
      v-if="showMenu"
      :position="positionContext"
      style="min-width: 212px;"
    >
      <template #body>
        <template v-for="(item, index) in items">
          <div
            v-if="item.name === 'delimiter'"
            :key="index"
            :class="$style.divider"
          />
          <div
            v-else
            :key="index"
            :class="[
              $style.menu_item,
              item.cssClass || '',
              item.restrictAccess ? 'pricing-tooltip' : '',
            ]"
            :data-feature="item.feature"
            :data-position="item.pricingTooltipPos"
            @click="itemClicked(item, item.restrictAccess)"
          >
            <svg-sprite
              :style="{color: item.iconColor ? item.iconColor : ''}"
              :class="$style.icon"
              :name="item.icon"
              type="regular"
              size="24"
            />
            <div
              :class="$style.text"
              :style="{color: item.labelColor ? item.labelColor : ''}"
            >
              {{ item.text }}
            </div>
            <div
              v-if="item.helpIcon && !item.restrictAccess"
              :class="[
                $style.help_icon,
                !item.activityStatus ? $style.not_shown : '',
              ]"
              @click.stop="handleHelp($event, item.helpIcon)"
            >
              <svg-sprite
                name="help"
                type="regular"
                size="24"
              />
            </div>
            <div
              v-if="item.restrictAccess"
              :class="[
                $style.plan_label,
                'pricing-title-container',
              ]"
            >
              {{ locales(item.pricingPlan) }}
            </div>
          </div>
        </template>
      </template>
    </context-menu>
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import helpPopup from '../../help/helpPopup';
import { checkPricingAccess, getPlans } from '../../../helpers/pricingHelper';
import app from '../../../app';

export default {
  components: {
    svgSprite,
    vgpIconButton,
  },
  props: {
    onClick: {
      type: Function,
      default: null,
    },
    config: {
      type: Array,
      default: () => ([]),
    },
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      positionContext: null,
      moreItems: {
        delimiter: {
          name: 'delimiter',
        },
        publicUrl: {
          icon: 'link-1',
          text: __('settings_publicLinkButton'),
          helpIcon: 'publicLinkHelpBlock',
          activityStatus: 0,
          cssClass: 'js_public_url_button',
        },
        template: {
          icon: 'template',
          text: __('settings_saveAsTemplate'),
          helpIcon: 'saveUserTemplateHelpBlock',
          activityStatus: 0,
          cssClass: 'js_template_button',
          feature: 'saving_project_templates',
          restrictAccess: !checkPricingAccess('saving_project_templates'),
          pricingPlan: `${getPlans('saving_project_templates')}_plan_locale`,
          pricingTooltipPos: 'left',
        },
        archive: {
          icon: 'archive',
          text: __('settings_archive'),
        },
        delete: {
          icon: 'delete',
          text: __('settings_delete'),
          labelColor: '#DD3636',
          iconColor: '#DD3636',
        },
        deletePortfolio: {
          icon: 'delete',
          text: __('settings_multiview_delete'),
          labelColor: '#DD3636',
          iconColor: '#DD3636',
        },
      },
      locales: __,
    };
  },
  computed: {
    items() {
      return this.config && this.config.map(item => {
        this.moreItems[item].name = item;

        return this.moreItems[item];
      });
    },
    showMenu() {
      return this.$store.getters['headerView/isShowMorePopup'];
    },
  },
  methods: {
    toggleShow() {
      this.$store.commit('headerView/toggleMorePopup', !this.showMenu);
      this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();

      if (this.showMenu) {
        this.items.filter(item => item.helpIcon).forEach(item => {
          const activityStatus = app.callGlobalStore('getter', 'user/getActivityStatus', [item.helpIcon]);

          this.$set(this.moreItems, item.name, { ...item, activityStatus });
        });
      }
    },
    itemClicked(item, restrictAccess) {
      if (restrictAccess) return;
      this.toggleShow();
      if (this.onClick) this.onClick(item);
    },
    handleHelp(e, id) {
      helpPopup.showHelpBlock(e, id);
      app.callGlobalStore('action', 'user/updateActivityStatus', [{
        activityName: id,
        status: 1,
      }]);
      this.close();
    },
    close() {
      this.$store.commit('headerView/toggleMorePopup', false);
    },
  },
};
</script>

<style module src="../less/select.less"></style>
