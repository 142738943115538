import { onProjectConfigUpdated, hardRefresh } from './helpers';
import helpers from '../helpers';
import customDaysModel from '../../../models/customDays';
import customDaysTemplatesModel from '../../../models/customDayTemplates';
import app from '../../../app';
import projectComponent from '../../Project';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';

function prepareData(data) {
  if (data) {
    data.hours = typeof data.hours === 'string' ? JSON.parse(data.hours) : data.hours;
    if (!data.hours.length) {
      data.hours = false;
    }
    data.template_id = +data.template_id;
  }
  return data;
}

function onProjectCalendarUpdated(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const isMultiview = gantt.config.multiview;

  event.data.updated.forEach(item => {
    const isSameProjectOpen = _.includes(ganttIDs, +item.projectId);

    if (item.worktime && item.worktime.days) {
      onProjectConfigUpdated(item.projectId, event.innerSettings.projectConfigs[item.projectId]);

      if (isSameProjectOpen) {
        helpers.notify({
          userPic: event.innerSettings.user.photo,
          locale: 'history_event_project_updated',
          data: {},
        });
      }
    }

    if (item.customDays && item.customDays.length) {
      event.innerSettings.dataForCollaboration.forEach(el => {
        el.customDays.forEach(customDayAction => {
          if (customDayAction.action === 'add') {
            customDayAction.data.forEach(cd => {
              if (!isMultiview && isSameProjectOpen) {
                customDaysModel.addNewItemCollaboration(prepareData(cd));
              }
              customDaysTemplatesModel.addCustomDayByCollaboration(cd, +item.projectId);
            });
          }

          if (customDayAction.action === 'update') {
            customDayAction.data.forEach(cd => {
              if (!isMultiview && isSameProjectOpen) {
                customDaysModel.deleteCustomDayCollaboration(+cd.id);
                customDaysModel.addNewItemCollaboration(prepareData(cd));
              }
              customDaysTemplatesModel.updateCustomDayByCollaboration(cd, +item.projectId);
            });
          }

          if (customDayAction.action === 'delete') {
            customDayAction.ids.forEach(id => {
              if (!isMultiview && isSameProjectOpen) {
                customDaysModel.deleteCustomDayCollaboration(+id);
              }
              customDaysTemplatesModel.deleteCustomDayByCollaboration(id, +item.projectId);
            });
          }
        });

        app.trigger('collaboration:updatedModels', {});
        if (isSameProjectOpen) {
          app.trigger('change:work:time', true, +el.projectId);
          app.trigger('settings:days:collaboration', +el.projectId);
          gantt.blockEvents();
          app.trigger('gantt:reinit');
          gantt.unblockEvents();
        }
      });
    }
  });

  gantt.is_workload_mode && app.trigger('project:settings:workload:recalculate');
}

export default onProjectCalendarUpdated;
