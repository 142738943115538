import app from "./../../app";
import collaborationComponent from "./../../components/Collaboration";

import _ from "./../../libs/lodash";

const __ = window.__;

export default (() => {
  if (!app.config.mode.isBase) {
    return true;
  }

  var innerHandlers = {
    showNotify: _.debounce(function (eventData) {
      // console.time('showNotify');
      var collaborationTemplateUI = innerViews.collaborationEventTemplate,
        mainWindowRowUI = $$("mainWindowRow"),
        childrenViews = [];

      collaborationTemplateUI.eventData = eventData;

      mainWindowRowUI.addView(_.cloneDeep(collaborationTemplateUI));

      childrenViews = mainWindowRowUI.getChildViews();

      if (childrenViews.length > 1) {

        childrenViews[0].getNode().classList.add("remove");

        childrenViews[childrenViews.length - 1].getNode().classList.add("addView");

        innerHandlers.clearMessages();
      }

      $$(innerViews.mainPopupId).show();
      innerHandlers.hideNotify();
    }, 1500),
    hideNotify: _.debounce(function () {
      $$(innerViews.mainPopupId).getNode().classList.add("popup-remove");
      $$(innerViews.mainPopupId).hide();
    }, 5000),
    clearMessages: _.debounce(function () {
      var mainWindowRowUI = $$("mainWindowRow"),
        childrenViews = mainWindowRowUI.getChildViews(),
        childNode = {},
        childNodeClassList = [];

      for (var viewIndex = 0; viewIndex < childrenViews.length; viewIndex++) {
        childNode = childrenViews[viewIndex].getNode();
        childNodeClassList = childNode.classList;

        if (childNodeClassList.contains("addView")) {
          childNodeClassList.remove("addView");
          if (childNodeClassList.contains("remove")) {
            mainWindowRowUI.removeView(childrenViews[viewIndex]);
            viewIndex--;
          }
          return true;
        }
        mainWindowRowUI.removeView(childrenViews[viewIndex]);
        viewIndex--;
      }

    }, 1000),

    modelHandlers: {
      collaboration: {
        init: function () {
          collaborationComponent.data.attachEvent("collaboration:event", function (eventData) {
            if (eventData.isHidden) {
              return;
            }

            innerHandlers.showNotify(eventData);
          });
        }
      }
    }
  };

  var innerViews = {
    mainPopupId: "collaborationMessageWindow",
    collaborationEventTemplate: {
      //id: "collaborationEventTemplate",
      view: "template",
      borderless: true,
      eventData: {},
      height: 32,
      template: function () {
        const { taskNames } = this.eventData.data;
        const name = taskNames ? { taskNames: taskNames.text } : this.eventData.data;
        return "<div class='collaboration-popup-message'>" +
          "<div class='collaboration-popup-message-user' style='background-image: url(" + this.eventData.userPic + ");'></div>" +
          "<div class='collaboration-popup-message-desc'>" + __(this.eventData.locale, name) + "</div>" +
          "</div>";
      },
      on: {
        "onAfterRender": function () {
          $$(innerViews.mainPopupId).resize();
        }
      }
    }
  };


  webix.ui({
    view: "windowWithoutPadding",
    id: innerViews.mainPopupId,
    css: "collaboration-popup",
    borderless: true,
    head: false,
    modal: false,
    hidden: true,
    autoheight: true,
    autofocus: false,
    height: 32,
    body: {
      height: 64,
      borderless: true,
      id: "mainWindowRow",
      rows: []
    },
    position: function (state) {
      var collaborationMessage = document.querySelectorAll(".collaboration-popup-message-desc"),
        maxWidth = 0,
        currentWidth = 0;


      if (collaborationMessage) {
        _.each(collaborationMessage, function (item) {
          currentWidth = item.offsetWidth;
          if (currentWidth > maxWidth) {
            maxWidth = currentWidth;
          }
        });
        state.width = maxWidth + 32 + 8;
      }
      if (state.width < 300) {
        state.width = 300;
      }
      state.left = document.body.clientWidth - state.width - 10;
      state.top = 10; // 60 + 10
    },
    on: {
      "onHide": function () {
        var childViews = $$("mainWindowRow").getChildViews();

        for (var indexChild = 0; indexChild < childViews.length; indexChild++) {
          $$("mainWindowRow").removeView(childViews[indexChild]);
          indexChild--;
        }

        $$(innerViews.mainPopupId).getNode().classList.remove("popup-init");
        $$(innerViews.mainPopupId).getNode().classList.remove("popup-remove");
      },
      "onShow": function () {
        setTimeout(function () {
          $$(innerViews.mainPopupId).getNode().classList.add("popup-init");
        }, 1200);
      }
    }
  });

  app.on("app:start", function () {
    innerHandlers.modelHandlers.collaboration.init();
  });

  app.on("collaboration:notify", function(eventData) {
    innerHandlers.showNotify(eventData);
  })

})();


