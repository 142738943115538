import app from '../../../app';
import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';

import projectsModel from '../../../models/projects';
import customDayTemplatesModel from '../../../models/customDayTemplates';
import constants from '../../../helpers/constants';
import routerHelper from '../../../helpers/router';
import ganttViewModel from '../../../models/ganttViewModel';
import svg from '../../../helpers/svgToURI';
import drag_icon from '../../../svg/drag_task_bar.svg';
import icon_unassigned from '../../../svg/default/unassigned.svg';
import workloadTooltipsHelper from '../../../helpers/workloadTooltips';
import globalStore from '../../../store/main';
import rights from '../../../components/rights';

const __ = window.__;

const RESOURCE_TASK_ID_PREFIX = '-tasks';
const RESOURCE_TASK_HEIGHT = 22;
const RESOURCE_TASK_SMALL_HEIGHT = 4;
const TASK_BAR_TOP_POSITION = 4;
const ROW_HEIGHT = 30;
const PARTIAL_ROWS_COUNT = 20;

const _workloadStore = gantt.createDatastore({
  name: 'workloadStore',
  _skip_refresh: true,
});

let _state = {
  cache: {},
  hardCache: [],
  worker: null,
  filter: { projects: [], resources: [] },
  selectedTask: {},
  openedResources: {},
  resourcesLayout: {},
  visibleUnassignedTasksRows: 0,
  viewMode: 'hours',
  editTaskCellMode: false,
  tooltipNode: null,
  draggedTask: null,
  droppedDate: null,
};

function getAnalyticType() {
  if (routerHelper.isGanttGridRoute()) {
    return 'gantt view';
  }

  if (routerHelper.isCommonWorkloadViewRoute()) {
    return 'general';
  }

  if (routerHelper.isWorkloadViewRoute()) {
    return 'workload tab ';
  }
}

const _debouncedResourceRowDragEnter = _.debounce((e, resource) => {
  if (!_state.openedResources[resource.id]) {
    _handlers.changeCssClassForGridRow(resource.id, null, ['drag_over']);
    _state.openedResources[resource.id] = true;
    _run();
  }

  e.preventDefault();

  return false;
}, 1000);

const _renderer = {
  main(resource, timeline) {
    if (gantt.config.durationData.mode === 'hour') {
      return;
    }

    const row = document.createElement('div');
    const scale = timeline.getScale();

    row.className = 'gantt_resource_row';
    row.setAttribute('resource_id', resource.id);

    if (resource.separator) {
      row.className += ' separator';
    }

    if (resource.firstChild) {
      row.className += ' first';
    }

    if (resource.lastChild) {
      row.className += ' last';
    }

    if (resource.tasksLayout) {
      row.className += ' tasks';
    }

    if (resource.opened) {
      row.className += ' open';
    }

    if (resource.unassigned) {
      row.className += ' unassigned';
    }

    if (resource.isPartial) {
      row.className += ' partial';
    }

    if (resource.prevLastChild) {
      row.className += ' prev-last';
    }

    row.style.cssText = `
      height: ${_renderer.getWorkloadItemHeight(resource.id)}px;
      position: absolute;
      width: 100%;
      top: ${_renderer.getWorkloadItemTop(resource.id)}px`;

    if (resource.tasksLayout) {
      return _renderer.prepareResourceTasksRow(resource, row);
    }

    row.ondragenter = function (e) {
      if (!_state.draggedTask) {
        return false;
      }

      _handlers.changeCssClassForGridRow(resource.id, 'drag_over', _state.openedResources[resource.id] ? ['drag_over'] : null);
      _debouncedResourceRowDragEnter.cancel();
      _debouncedResourceRowDragEnter(e, resource);
    };

    row.onmouseover = e => {
      _handlers.changeCssClassForGridRow(resource.id, 'hover');
    };

    row.onmouseleave = e => {
      _handlers.changeCssClassForGridRow(resource.id, null, ['hover', 'drag_over']);
    };

    row.addEventListener('click', _.debounce(event => {
      let scrollXratio = event.offsetX / timeline.$task.offsetWidth;

      if (gantt.getScrollState().width) {
        scrollXratio = event.offsetX / gantt.getScrollState().width;
      }

      _handlers.showResourceTasks(resource.id, scrollXratio);
    }, 50));

    for (let i = 0; i < scale.count; i++) {
      const delta = scale.left[i] - gantt.getScrollState().x;

      if (delta < -timeline.$task.offsetWidth || delta > (2 * timeline.$task.offsetWidth)) {
        continue;
      }

      const preparedCellData = resource.cellData[i];

      let el = null;

      if (!preparedCellData) {
        continue;
      }

      preparedCellData.sizes = {
        left: scale.left[i],
        width: scale.width[i],
        top: 0,
      };

      // if (preparedCellData.totalTasksLength || preparedCellData.isWeekend) {
      el = _renderer.renderResourceCell(resource, preparedCellData);
      // }

      el && row.appendChild(el);
    }

    return row;
  },
  renderTaskBar(task, isMirror) {
    let taskBar = gantt.$ui.layers.taskBar();
    const taskData = _.isObject(task) ? gantt.__scaleTask(task) : gantt.__scaleTask(gantt.getTask(task));
    const dragArea = document.createElement('div');

    dragArea.innerHTML = `<div style="background-color: ${`${constants.COLORS[taskData.color]}dd`}">${drag_icon}</div>`;
    dragArea.classList.add('drag_area');

    taskData.workloadMode = true;

    taskBar = taskBar.render(taskData, gantt.$ui.getView('workloadTimeline'));

    if (!taskBar) {
      return;
    }

    taskBar.style.height = `${RESOURCE_TASK_HEIGHT}px`;
    taskBar.style.lineHeight = `${RESOURCE_TASK_HEIGHT}px`;

    if (rights.project.hasRight(taskData.gantt_id, 'static_fields')) {
      taskBar.appendChild(dragArea);
    }

    if (gantt.config.start_date && gantt.config.end_date && !isMirror) {
      const leftCondition = taskData.start_date.valueOf() < gantt.config.start_date.valueOf() && taskData.end_date.valueOf() > gantt.config.start_date.valueOf();
      const rightCondition = taskData.start_date.valueOf() < gantt.config.end_date.valueOf() && taskData.end_date.valueOf() > gantt.config.end_date.valueOf();

      leftCondition && _renderer.renderTaskBarCutArea(taskBar, 'left', taskData.color);
      rightCondition && _renderer.renderTaskBarCutArea(taskBar, 'right', taskData.color);
    }

    return taskBar;
  },
  renderTaskBarCutArea(taskBar, sideClass, taskColor) {
    const cutArea = document.createElement('div');

    cutArea.innerHTML = `<div style="background-color: ${`${constants.COLORS[taskColor]}dd`}"></div>`;
    cutArea.classList.add('cut_area');
    cutArea.classList.add(sideClass);

    taskBar.appendChild(cutArea);
  },
  renderResourceCell(resource, {
    sizes, totalTasksLength, resourceDayInfo, totalGanttIds, currentTracexValue,
  }) {
    const totalHours = resourceDayInfo.totalHours;
    const workHours = resourceDayInfo.workHours;
    const cssPostfix = _helpers.getCSSByWorkHours(totalHours, workHours, totalTasksLength);

    if (workHours === 0 && !resourceDayInfo.isWeekend && !app.config.mode.isExport) {
      return;
    }

    let css = `gantt_resource_marker gantt_resource_marker_${cssPostfix}`;
    const el = document.createElement('div');
    const loadInPercents = _.floor(totalHours / workHours * 100);

    el.style.cssText = `left: ${sizes.left}px;
                        width: ${sizes.width}px;
                        position: absolute;
                        height: ${resource.unassigned ? ROW_HEIGHT - 2 : ROW_HEIGHT}px;
                        line-height: ${ROW_HEIGHT}px;
                        top: ${sizes.top}px;`;

    if (resource.unassigned) {
      css += ' unassigned';

      if (totalTasksLength) {
        css += ' not_empty';
      }

      el.className = css;

      return el;
    }

    if (!resourceDayInfo.isWeekend || totalHours) {
      if (_state.viewMode === 'hours' || _state.viewMode === 'percents') {
        el.innerHTML = `${_state.viewMode === 'hours' ? _helpers.formatNumber(totalHours) : (loadInPercents === Infinity ? 100 : (loadInPercents || ''))}`;
      } else {
        el.innerHTML = totalTasksLength;
      }

      if (workHours) {
        const loadLayer = document.createElement('div');

        loadLayer.className = 'load_layer';
        loadLayer.style.cssText = `height: ${loadInPercents > 100 ? 100 : loadInPercents}%`;
        el.appendChild(loadLayer);
      }
    }

    el.setAttribute('trace_x', currentTracexValue);

    const debounced = _.debounce(e => {
      _handlers.showResourceCellTooltip(e, totalTasksLength, totalHours, workHours, totalGanttIds);
    }, 300);

    el.addEventListener('mouseover', debounced);

    el.addEventListener('mouseleave', () => {
      debounced.cancel();
      _handlers.hideTooltip();
    });

    if (resourceDayInfo.isWeekend) {
      css += ' weekend';
    }

    el.className = css;

    return el;
  },
  renderTaskCell(taskResourceData, sizes, totalHours, currentTraceXValue, dayPart, isCustom) {
    const timeline = gantt.$ui.getView('workloadTimeline');

    if (timeline.getScale().trace_indexes[currentTraceXValue] === undefined) {
      return;
    }

    const el = document.createElement('input');
    const css = `gantt_resource_marker gantt_resource_marker_task${isCustom ? ' custom' : ''}`;

    const cellValue = _helpers.formatNumber(totalHours);

    el.setAttribute('type', 'text');
    el.setAttribute('value', cellValue);
    el.setAttribute('old_estimation', totalHours);
    el.setAttribute('resource_task_id', taskResourceData.resource_task_id);

    el.addEventListener('focus', function () {
      this.select();
      _state.editTaskCellMode = true;
      _afterTaskCellEditDebounced.cancel();
    });

    const hasRightWorkload = ((gantt.config.order && gantt.config.order.some(id => rights.project.hasRight(id, 'static_fields')))
      || rights.project.hasRight(gantt.config.gantt_id, 'static_fields'));

    if (!!app.config.mode.isExport || !hasRightWorkload) {
      el.setAttribute('readonly', '');
    }

    el.addEventListener('blur', function () {
      const old_estimation = this.getAttribute('old_estimation');
      const newValue = parseFloat((`${this.value}`).replace(',', '.').replace('-', '')) || 0;

      this.value = newValue;

      _afterTaskCellEditDebounced();

      if (newValue === +old_estimation) {
        return;
      }

      const delta_estimation = (newValue - +old_estimation);

      globalStore.dispatch('tasksModel/changeEstimationOnTaskDay', {
        gantt_id: taskResourceData.gantt_id,
        task_id: taskResourceData.task_id,
        resource_task_id: taskResourceData.resource_task_id,
        day_part: dayPart,
        day_time: currentTraceXValue,
        estimation: newValue,
        delta_estimation,
      });

      _state.taskCellEstimateChanged = true;
    });

    el.addEventListener('keyup', function (e) {
      if (_.includes([13, 27], e.keyCode)) {
        this.blur();
      }
    });

    el.addEventListener('mouseover', _.debounce(_handlers.hideTooltip, 280));
    el.addEventListener('mouseleave', _.debounce(_handlers.hideTooltip, 280));

    el.className = css;

    el.style.cssText = [
      `left: ${sizes.left}px`,
      `width: ${sizes.width - 1}px`,
      'position: absolute',
      `height: ${ROW_HEIGHT - 1}px`,
      `line-height: ${ROW_HEIGHT - 1}px`,
      `top: ${sizes.top - TASK_BAR_TOP_POSITION}px`,
    ].join(';');

    return el;
  },
  prepareOpenedTaskBar(task, resource, scale, topIndex) {
    const bar = document.createElement('div');
    const resourceTask = _.find(task.resources, res => res.resource_id === resource.resource_id);
    const resourceTaskData = {
      gantt_id: task.gantt_id,
      task_id: task.id,
      resource_task_id: resourceTask.id,
    };

    resource.selectedTaskTimeTable.forEach((obj, index) => {
      const currentTraceXValue = obj.start_date.valueOf();
      const dayPart = index + 1;
      const traceIndex = scale.trace_indexes[currentTraceXValue];

      const sizes = {
        left: scale.left[traceIndex],
        top: topIndex * ROW_HEIGHT,
        width: scale.width[traceIndex],
      };

      const cell = _renderer.renderTaskCell(resourceTaskData, sizes, obj.hours, currentTraceXValue, dayPart, obj.isCustom);

      cell && bar.appendChild(cell);
    });

    return bar;
  },
  prepareResourceTasksRow(resource, row) {
    const resourceTasksLayout = resource.tasksLayout;
    const scale = gantt.$ui.getView('workloadTimeline').getScale();
    const timelineContainer = gantt.$ui.getView('workloadTimeline').$task_data;
    let isDblClicked = false;
    let visibleCount = 0;

    row.ondragenter = function (e) {
      _debouncedResourceRowDragEnter.cancel();
      _handlers.changeCssClassForGridRow(resource.id, null, ['drag_over']);

      if (e.target && e.target.closest('.gantt_resource_row')) {
        const dropMirror = document.getElementById('dropMirror');

        if (dropMirror) {
          row.appendChild(dropMirror);
        }
      }
      e.preventDefault();

      return false;
    };

    row.ondragover = _handlers.onDragOverResourceTasksLayout;

    row.ondragleave = function (e) {
      if (e.toElement && e.toElement.closest('.gantt_resource_row')) {
        const dropMirror = document.getElementById('dropMirror');

        if (dropMirror) {
          dropMirror.style.display = 'none';
        }
      }

      return false;
    };

    row.ondrop = _handlers.onDropResourceTasksLayout;

    resource.tasksByGanttId.forEach(task => {
      if (!gantt.isTaskExists(task.taskData.id) || task._onlyForWorkloadCalculation) { //
        return;
      }
      if (!_helpers.checkVisibilityForTaskBar(task, resource)) {
        return;
      }

      const index = resourceTasksLayout.taskIndexes[task.taskData.id] || 0;

      if (resource.isPartial && index >= _state.visibleUnassignedTasksRows) {
        return;
      }
      visibleCount++;

      const bar = _renderer.renderTaskBar(task.taskData.id);

      if (!bar) {
        return;
      }

      bar.style.top = `${index * ROW_HEIGHT + TASK_BAR_TOP_POSITION}px`;

      if (rights.project.hasRight(task.gantt_id, 'static_fields') && !app.config.mode.isExport) {
        bar.setAttribute('draggable', true);
      }

      bar.onclick = _.debounce(e => {
        if (isDblClicked || resource.unassigned) {
          isDblClicked = false;

          return;
        }

        if (_state.viewMode !== 'hours' || task.taskData.type === gantt.config.types.milestone) {
          return;
        }

        const isSelected = _helpers.checkIsSelectedTaskBar(task.taskData.id, resource.resource_id);

        _state.selectedTask = {
          id: isSelected ? null : task.taskData.id,
          resourceId: resource.resource_id,
        };

        _state.cache[resource.id] = null;
        _state.editTaskCellMode = false;
        _run({ fromCache: !_state.taskCellEstimateChanged });
        _afterTaskCellEditDebounced.cancel();
        if (_state.taskCellEstimateChanged) {
          _state.taskCellEstimateChanged = false;
        }
        _handlers.hideTooltip();
      }, 200);

      bar.addEventListener('dblclick', e => {
        if (app.config.mode.isExport) {
          return;
        }

        isDblClicked = true;

        const currentRoute = routerHelper.getCurrentRoute();
        let newRoutePathArray;

        if (currentRoute.name.includes('workloadTaskRoute')) {
          newRoutePathArray = currentRoute.path.split('/').slice(0, 2);
        } else {
          newRoutePathArray = currentRoute.path.split('/').slice(0, 4);
        }

        newRoutePathArray.push('task', task.taskData.id);
        routerHelper.changeRoute(newRoutePathArray.join('/'));

        analyticTaskClick();

        function analyticTaskClick() {
          const type = routerHelper.analyticsRoute().includes('gantt') ? 'Workload' : 'Task';

          userExtAnalytics.log('gantt_task_settings_open', {
            from: routerHelper.analyticsFromRoute(),
            route: `${routerHelper.analyticsRoute()}/DoubleClick ${type}`,
          });
        }
      });

      const debouncedMouseEnter = _.debounce(e => {
        _handlers.showTaskBarTooltip(task.taskData, bar, e);
      }, 300);

      bar.onmouseenter = debouncedMouseEnter;

      bar.onmouseleave = function (e) {
        debouncedMouseEnter.cancel();
        _handlers.hideTooltip();
      };

      bar.ondragstart = function (e) {
        const dropMirror = _renderer.createDropMirror(task.taskData.id);

        _handlers.hideTooltip();

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('taskId', task.taskData.id);
        e.dataTransfer.setData('resourceId', resource.id);
        e.target.classList.add('dragged');
        timelineContainer.classList.add('on_drag');
        _state.draggedTask = gantt.getTask(task.taskData.id);// needs prepared task data
        _state.draggedTask.dragOffset = e.offsetX;

        row.appendChild(dropMirror);

        userExtAnalytics.log('workload_task_drag_start', {
          from: getAnalyticType(),
        });

        return true;
      };

      bar.ondragend = function (e) {
        const dropMirror = document.getElementById('dropMirror');

        e.target.classList.remove('dragged');
        timelineContainer.classList.remove('on_drag');
        _state.draggedTask = null;
        dropMirror && dropMirror.remove();

        return true;
      };

      row.appendChild(bar);

      if (_helpers.checkIsSelectedTaskBar(task.taskData.id, resource.resource_id) && _state.viewMode === 'hours') {
        const topIndex = (resourceTasksLayout.taskIndexes[task.taskData.id] + 1) || 0;
        const openedTaskBar = _renderer.prepareOpenedTaskBar(task.taskData, resource, scale, topIndex);

        row.appendChild(openedTaskBar);
      }
    });

    if (resource.isPartial && resource.tasksByGanttId.length > visibleCount) {
      row.appendChild(_renderer.createMoreButtonForResource(resource.tasksByGanttId.length, visibleCount));
    }

    return row;
  },
  createMoreButtonForResource(allTasksCount, visibleCount) {
    const addButton = document.createElement('div');

    addButton.className = 'show_more';
    addButton.style.height = `${ROW_HEIGHT}px`;
    addButton.innerHTML = `<span class="text">${visibleCount} ${__('key_of')} ${allTasksCount} ${__('show_more_unassigned')}</span>`;

    addButton.querySelector('.text').onclick = function (e) {
      _state.visibleUnassignedTasksRows += PARTIAL_ROWS_COUNT;
      _run({ fromCache: true });
    };

    return addButton;
  },
  createDropMirror(taskId) {
    const dropMirror = _renderer.renderTaskBar(taskId, true);

    dropMirror.setAttribute('id', 'dropMirror');
    dropMirror.style.top = `${TASK_BAR_TOP_POSITION}px`;

    return dropMirror;
  },
  addFakeRowWithBackground() {
    const row = document.createElement('div');
    const timeline = gantt.$ui.getView('workloadTimeline');
    const scale = timeline.getScale();

    row.className = 'gantt_resource_row fake';

    for (let i = 0; i < scale.count; i++) {
      const delta = scale.left[i] - gantt.getScrollState().x;

      if (delta < -timeline.$task.offsetWidth || delta > (2 * timeline.$task.offsetWidth)) {
        continue;
      }

      const el = document.createElement('div');

      el.className = 'gantt_resource_marker';

      el.style.cssText = `
        left: ${scale.left[i]}px;
        width: ${scale.width[i]}px;
        position: absolute`;

      el && row.appendChild(el);
    }

    timeline.$task_data.querySelector('[data-layer]').prepend(row);
  },
  renderEmptyLayout(isHide) {
    const workloadNode = gantt.$ui.getView('workload').getNode();

    if (workloadNode.querySelector('.workload_empty_layout')) {
      if (isHide) {
        workloadNode.querySelector('.workload_empty_layout').remove();
      }

      return;
    } if (isHide) {
      return;
    }

    if (!_state.isWorkloadPage) {
      return;
    }

    const layout = document.createElement('div');

    layout.className = 'workload_empty_layout';

    layout.innerHTML = `<div class="wrap">
        <div class="img"></div>
        <span>${__('empty_filter_logs_group_by_table')}</span>
      </div>`;
    workloadNode.append(layout);
  },
  renderTodayMarker() {
    const timeline = gantt.$ui.getView('workloadTimeline');
    const scale = timeline.getScale();
    let marker = timeline.$task.querySelector('.workload_marker');

    if (!_state.isWorkloadPage) {
      marker && marker.remove();

      return;
    }

    const now = moment().valueOf();

    const startCheck = scale.trace_x[0].valueOf();
    const endCheck = moment(scale.trace_x[scale.trace_x.length - 1]).add(1, scale.unit).valueOf();

    if (now < startCheck || now > endCheck) {
      marker && marker.remove();

      return;
    }

    const findClosestTime = function (arr) {
      const middleIndex = parseInt(arr.length / 2);
      const rest = now < arr[middleIndex].valueOf() ? arr.slice(0, middleIndex) : arr.slice(middleIndex);

      return rest.length === 1 ? rest[0] : findClosestTime(rest);
    };

    const closestTime = findClosestTime(scale.trace_x).valueOf();
    const index = scale.trace_indexes[closestTime];
    const coefficient = (now - closestTime) / (moment(closestTime).add(1, scale.unit) - closestTime);
    const delta = scale.unit === 'day' ? -1 : parseInt(scale.width[index] * coefficient);

    if (!marker) {
      marker = document.createElement('div');
      marker.className = 'workload_marker gantt_marker';
      marker.innerHTML = `<div class="gantt_marker_content">${__('gantt_today')}</div>`;

      timeline.$task.appendChild(marker);
    }

    marker.style.cssText = `left: ${scale.left[index] + delta}px;`;
  },
  getWorkloadItemTop(itemId) {
    if (_state.resourcesLayout[itemId]) {
      return _state.resourcesLayout[itemId].top;
    }

    return 0;
  },
  getWorkloadItemHeight(itemId) {
    if (_state.resourcesLayout[itemId]) {
      return _state.resourcesLayout[itemId].visibleHeight || _state.resourcesLayout[itemId].height;
    }

    return 0;
  },
};

const _handlers = {
  showResourceCellTooltip(event, totalTasksLength, totalHours, workHours, totalGanttIds) {
    if (!totalHours || !event.target.offsetHeight) {
      return;
    }

    let dataFromInaccessibleProjects = null;

    if (!_state.tooltipNode) {
      _state.tooltipNode = document.createElement('div');

      _state.tooltipNode.classList.add('workload-tooltip');

      document.body.appendChild(_state.tooltipNode);
    }

    _state.tooltipNode.innerHTML = `
      <div class='workload-tooltip-head'>
        <div class='workload-tooltip-head-load'>
          ${__('workload_loaded_label')}
          <span>${`${totalHours}/${workHours}`}</span>
          ${__('workload_avaliable_label')}
        </div>
        <div class='workload-tooltip-head-load'>${__('workload_task_assigned_label')}: <span>${totalTasksLength}</span></div>
      </div>
      <div class='workload-tooltip-projects'>`;

    _.each(totalGanttIds, (value, index) => {
      const project = projectsModel.getProjectDataById(+index);

      if (!project) {
        if (!dataFromInaccessibleProjects) {
          dataFromInaccessibleProjects = {
            hours: 0,
            tasksCount: 0,
          };
        }
        dataFromInaccessibleProjects.hours += _.floor(value.hours, 2);
        dataFromInaccessibleProjects.tasksCount += value.tasks.length;

        return;
      }

      _state.tooltipNode.innerHTML += `
        <div class='workload-tooltip-projects-item'>
          <div class='workload-tooltip-projects-item-name'>${project.name}</div>
          <div class='workload-tooltip-projects-item-info'>
            ${__('tasks')}: ${value.tasks.length} / ${__('hours')}: ${_.floor(value.hours, 2)}
          </div>
        </div>`;
    });

    if (dataFromInaccessibleProjects) {
      _state.tooltipNode.innerHTML += `
        <div class='workload-tooltip-projects-item'>
          <div class='workload-tooltip-projects-item-name'>${__('workload_from_inaccessible_projects')}</div>
          <div class='workload-tooltip-projects-item-info'>
            ${__('tasks')}: ${dataFromInaccessibleProjects.tasksCount} / ${__('hours')}: ${_.floor(dataFromInaccessibleProjects.hours, 2)}
          </div>
        </div>`;
    }

    _state.tooltipNode.innerHTML += '</div>';

    const pos = workloadTooltipsHelper.calculatePosition(_state.tooltipNode, event, ROW_HEIGHT);

    _state.tooltipNode.style.top = `${pos.top}px`;
    _state.tooltipNode.style.left = `${pos.left}px`;
  },
  showTaskBarTooltip(taskData, targetNode, event) {
    if (!event.target.offsetHeight) {
      return;
    }

    if (!_state.tooltipNode) {
      _state.tooltipNode = document.createElement('div');

      _state.tooltipNode.classList.add('workload-tooltip-task');

      document.body.appendChild(_state.tooltipNode);
    }

    _state.tooltipNode.innerHTML = workloadTooltipsHelper.getTaskTooltipTemplate(taskData);
    _state.tooltipNode.innerHTML = _state.tooltipNode.innerHTML.replace(/&nbsp;/gi, ' ');

    const pos = workloadTooltipsHelper.calculatePosition(_state.tooltipNode, event, RESOURCE_TASK_HEIGHT);

    _state.tooltipNode.style.top = `${pos.top}px`;
    _state.tooltipNode.style.left = `${pos.left}px`;
  },
  hideTooltip() {
    if (_state.tooltipNode) {
      document.body.removeChild(_state.tooltipNode);
      _state.tooltipNode = null;
    }
  },
  showResourceTasks(resourceId, scrollXratio) {
    const prevsModes = {
      year: 4,
      quarter: 3,
      month: 2,
      week: 1,
    };

    if (gantt.config.durationData.mode !== 'day' && prevsModes[gantt.config.durationData.mode]) {
      app.trigger('zoom:change', prevsModes[gantt.config.durationData.mode]);

      gantt.$ui.getView('scrollHor').scrollTo(
        (gantt.getScrollState().width - gantt.$ui.getView('workloadTimeline').$task.offsetWidth) * scrollXratio,
        null,
      );

      return;
    }

    // if (gantt.config.durationData.mode === 'day') {
    _state.openedResources[resourceId] = !_state.openedResources[resourceId];
    // }

    _run({ fromCache: true });
    _state.openedResources[resourceId] && analyticsWorkload();

    function analyticsWorkload() {
      let from = 'general';

      if (routerHelper.isGanttGridRoute()) from = 'gantt view';

      if (routerHelper.isMultiProjectsRoute() || routerHelper.isSingleProjectRoute()) {
        if (routerHelper.isWorkloadViewRoute()) { from = 'workload tab'; }
      }

      userExtAnalytics.log('workload_resource_open', { from });
    }
  },
  showSpinner: _.debounce(() => {
    gantt.$ui.getView('workloadTimeline').$task_data.style.opacity = 0.3;
    gantt.$ui.getView('workloadTimeline').$task_data.style.pointerEvents = 'none';
  }, 300),
  hideSpinner() {
    gantt.$ui.getView('workloadTimeline').$task_data.style.opacity = 1;
    gantt.$ui.getView('workloadTimeline').$task_data.style.pointerEvents = 'all';
    app.trigger('workload:ready');
  },
  onDropResourceTasksLayout(e) {
    const taskId = +e.dataTransfer.getData('taskId');

    if (!taskId) {
      return;
    }

    const oldRowData = _workloadStore.getItem(e.dataTransfer.getData('resourceId'));
    const newRowData = _workloadStore.getItem(this.getAttribute('resource_id'));
    const task = gantt.getTask(taskId);
    const changedResources = [];
    const resourceSettings = _.find(newRowData.resourceProjects, settings => settings.projectId === task.gantt_id);
    const isBlockedStartDate = _helpers.isTaskStartDateBlockedByLinkDependencies(task);
    const isChangedStartDate = task.start_date.valueOf() !== _state.droppedDate.valueOf();
    let promise = Promise.resolve();
    let changeFlag = false;

    _handlers.changeCssClassForGridRow(this.getAttribute('resource_id'), null, ['drag_over']);

    const exceptionText = !newRowData.unassigned ? !resourceSettings
      ? 'workload_resource_not_exist_on_project' : resourceSettings.type !== constants.RESOURCE_TIME_TYPE
        ? 'workload_resource_not_valid_type_on_project' : '' : '';

    userExtAnalytics.log('workload_task_drop', {
      from: getAnalyticType(),
    });

    if (!newRowData.unassigned) {
      const messageData = {
        resourceName: newRowData.name,
        projectName: projectsModel.getProjectDataById(task.gantt_id).name,
      };

      if (!resourceSettings) {
        webix.message({ type: '', text: __('workload_resource_not_exist_on_project', messageData) });

        return false;
      }

      if (resourceSettings.type !== constants.RESOURCE_TIME_TYPE) {
        webix.message({ type: '', text: __('workload_resource_not_valid_type_on_project', messageData) });

        return false;
      }
    }

    if (isChangedStartDate && !isBlockedStartDate) {
      gantt.callEvent('onBeforeWorkloadTaskUpdate');

      task.start_date = _state.droppedDate;
      task.end_date = gantt.calculateEndDate({
        start_date: _state.droppedDate,
        duration: task.duration,
        task,
        unit: gantt.config.duration_unit,
      });

      task.start_date_string = moment(task.start_date).format('YYYY-MM-DD HH:mm:ss');
      task.end_date_string = moment(task.end_date).format('YYYY-MM-DD HH:mm:ss');
      if (_state.isWorkloadPage) {
        if (!task.auto_scheduling) {
          delete task.auto_scheduling;
        }
        gantt.silentUpdateTask(taskId, task);
        globalStore.dispatch('tasksModel/backgroundUpdateTask', { taskData: task });
      } else {
        gantt.updateTask(taskId, task);
      }

      if (task.auto_scheduling) {
        gantt.autoSchedule();
      }

      changeFlag = true;
    }

    if (oldRowData.resource_id !== newRowData.resource_id) {
      const isTaskAssigned = _.find(task.resources, res => res.resource_id === newRowData.resource_id);

      if (!oldRowData.unassigned) {
        changedResources.push({
          id: oldRowData.resource_id,
          userId: oldRowData.userId,
          action: 'delete',
        });
      }

      let value = task.estimation || task.duration / 60;

      if (!oldRowData.unassigned) {
        value = task.resources.find(res => res.resource_id === oldRowData.resource_id).value;
      }

      if (!isTaskAssigned && !newRowData.unassigned) {
        changedResources.push({
          id: newRowData.resource_id,
          userId: newRowData.userId,
          action: 'insert',
          value,
        });
      }

      promise = globalStore.dispatch('tasksModel/changeAssign', {
        changedResources,
        taskId,
        ganttId: task.gantt_id,
      });

      changeFlag = true;
    }

    if (isChangedStartDate && isBlockedStartDate) {
      webix.message({ type: '', text: __('autoscheduling_block_start_date') });
    }

    if (!changeFlag) {
      return false;
    }

    promise.then(() => {
      gantt.isTaskExists(task.id) && gantt.refreshTask(task.id);// just for refresh task row in grid
      _run();
    });

    e.stopPropagation();

    return false;
  },
  onDragOverResourceTasksLayout(e) {
    if (!_state.draggedTask) {
      return false;
    }

    const taskBarHovered = e.target.closest('.gantt_task_line');
    let offsetX = e.offsetX;

    _handlers.hideTooltip();

    if (taskBarHovered) {
      offsetX += taskBarHovered.offsetLeft;
    }

    offsetX -= _state.draggedTask.dragOffset;
    const scale = gantt.$ui.getView('workloadTimeline').getScale();
    let index = ((offsetX - (offsetX % scale.col_width)) / scale.col_width);

    // need calculate index for skip_off_time == true
    if (gantt.config.skip_off_time) {
      let i = 0;
      const left = (offsetX - (offsetX % scale.col_width));

      while (scale.left[i] <= left) {
        if (scale.width[i]) {
          index = i;
        }
        i++;
      }
    }

    const rowData = _workloadStore.getItem(this.getAttribute('resource_id'));
    let dropMirror = document.getElementById('dropMirror');

    if (!scale.trace_x[index]) {
      return false;
    }

    _state.droppedDate = gantt.getClosestWorkTime({
      date: scale.trace_x[index], dir: 'future', unit: gantt.config.duration_unit, task: _state.draggedTask,
    });

    const tempTaskObj = _.assignIn({}, _state.draggedTask, {
      start_date: _state.droppedDate,
      end_date: gantt.calculateEndDate({
        start_date: scale.trace_x[index],
        duration: _state.draggedTask.duration,
        task: _state.draggedTask,
        unit: gantt.config.duration_unit,
      }),
    });

    let isHoverAnotherTask = _.some(rowData.tasksByGanttId, o => {
      if (rowData.tasksLayout.taskIndexes[o.task_id] > 0) {
        return false;
      }

      const startCond = o.taskData.start_date.valueOf() < tempTaskObj.end_date.valueOf();
      const endCond = o.taskData.end_date.valueOf() > scale.trace_x[index].valueOf();

      return startCond && endCond;
    });

    const tempBar = _renderer.renderTaskBar(tempTaskObj);

    if (!dropMirror) {
      dropMirror = _renderer.createDropMirror(_state.draggedTask.id);
      this.appendChild(dropMirror);
    }

    if (_state.draggedTask.type === gantt.config.types.milestone) {
      isHoverAnotherTask = false;
    }

    if (dropMirror) {
      dropMirror.style.left = tempBar.style.left;
      dropMirror.style.top = isHoverAnotherTask ? `${RESOURCE_TASK_HEIGHT + TASK_BAR_TOP_POSITION}px` : `${TASK_BAR_TOP_POSITION}px`;
      dropMirror.style.display = 'block';
      dropMirror.style.width = tempBar.style.width;
      dropMirror.classList[isHoverAnotherTask ? 'add' : 'remove']('under');
      dropMirror.style.height = isHoverAnotherTask ? `${RESOURCE_TASK_SMALL_HEIGHT}px` : `${RESOURCE_TASK_HEIGHT}px`;
    }

    e.preventDefault();

    return false;
  },
  changeCssClassForGridRow(rowId, cssClass, removeClassList) {
    const grid = gantt.$ui.getView('workloadGrid').$grid;
    const allRows = grid.querySelectorAll('.workload_row');

    allRows.forEach(el => {
      if (removeClassList) {
        removeClassList.forEach(cl => {
          el.classList.remove(cl);
        });

        return;
      }

      const id = el.getAttribute('data-resource-grid-id');

      el.classList[String(rowId) === id ? 'add' : 'remove'](cssClass);
    });
  },
};

const _helpers = {
  prepareProjectConfigs() {
    const projectConfigs = [];

    const ganttIds = globalStore.getters['tasksModel/getAllData'].map(o => o.id);

    ganttIds.forEach(ganttId => {
      const calendar = gantt.getCalendar(ganttId);
      let projectConfig = projectsModel.getProjectConfig(ganttId);
      const customDaysData = customDayTemplatesModel.serialize().find(data => +data.ganttId === +ganttId);

      if (!calendar) {
        return;
      }

      if (!projectsModel.getItem(ganttId)) {
        projectConfig = globalStore.getters['tasksModel/getItem'](ganttId).workloadData;
      }

      projectConfigs.push({
        calendar: {
          id: ganttId,
          worktime: {
            hours: calendar.getConfig().hours,
            days: _.values(calendar.getConfig().dates),
          },
        },
        ganttId,
        ganttConfig: {
          estimation_mode: projectConfig.estimation_mode,
          apply_resource_calendar: projectConfig.apply_resource_calendar,
        },
        customDays: customDaysData ? customDaysData.customDays : [],
        totalEstimate: globalStore.getters['tasksModel/getTotalEstimateDataForProject'](ganttId),
      });
    });

    return projectConfigs;
  }, //
  workerCalculate(innerData = [], ganttIds) {
    const totalEstimate = gantt.getTaskByIndex(0) || { start_date: gantt.config.start_date, end_date: gantt.config.end_date };
    const timelineScale = gantt.$ui.getView('workloadTimeline').getScale();

    if (!_state.worker || !totalEstimate) {
      webix.message({ type: 'error', text: __('workload_worker_shutdown_or_no_tasks'), expire: 10000 });

      return;
    }

    _state.worker.postMessage({
      innerData,
      projectConfigs: _helpers.prepareProjectConfigs(ganttIds),
      scale: timelineScale.unit,
      ganttConfig: {
        work_time: gantt.config.work_time,
        duration_unit: gantt.config.duration_unit,
        start_on_monday: gantt.config.start_on_monday,
      },
      rowHeight: ROW_HEIGHT,
      timelineData: {
        count: timelineScale.count,
        width: timelineScale.width,
        left: timelineScale.left,
        trace_x_ascending: timelineScale.trace_x_ascending,
        totalStart: gantt.date[`${gantt.config.durationData.mode}_start`](new Date(totalEstimate.start_date)),
        totalEnd: gantt.date.add(gantt.date[`${gantt.config.durationData.mode}_start`](new Date(totalEstimate.end_date)), 1, gantt.config.durationData.mode),
        rangeStart: moment(gantt.config.start_date).toDate(),
        rangeEnd: moment(gantt.config.end_date).toDate(),
      },
    });
  },
  prepareResourceData(resource, i, resources, ganttIds) {
    const item = _.assign(_.clone(resource), {
      id: resource.id,
      resource_id: resource.id,
      name: resource.name,
      photo: resource.photo || resource.resourcePhoto,
      render_type: 'workload',
      selectedTaskId: null,
    });

    if (rights.account.hasRight('team_and_resources_settings')) {
      item.accessToPersonalCalendar = true;
    }

    if (!resource.userId && resources[i - 1] && resources[i - 1].userId) {
      item.separator = true;
    }

    let tasksByGanttId = [];

    if (resource.unassigned) {
      tasksByGanttId = globalStore.getters['tasksModel/getUnassignedTasksByGanttIds'](ganttIds);
    } else if (_state.workloadCalculationMode) {
      tasksByGanttId = globalStore.getters['tasksModel/getAllTasksByResourceId'](item.resource_id);
    } else {
      tasksByGanttId = globalStore.getters['tasksModel/getTasksByResourceIdAndGanttIds'](item.resource_id, ganttIds);
    }

    tasksByGanttId = tasksByGanttId.filter(t => {
      let startCond = false;
      let endCond = false;

      if (gantt.config.start_date && gantt.config.end_date) {
        startCond = t.taskData.start_date.valueOf() > gantt.config.end_date.valueOf();
        endCond = t.taskData.end_date.valueOf() < gantt.config.start_date.valueOf();
      }

      return t.taskData.type !== gantt.config.types.project && !(startCond || endCond);
    });

    if (_state.isWorkloadPage && _state.filter.isApplied) {
      let filteredTasks = tasksByGanttId.map(t => t.taskData);

      if (_state.filter.workloadFilter) {
        filteredTasks = _state.filter.workloadFilter.filterData(filteredTasks, ['projects', 'resources']);
      }
      tasksByGanttId = tasksByGanttId.filter(t => filteredTasks.find(o => o.id === t.task_id));
    }

    if (item.resourceProjects) {
      tasksByGanttId = _.filter(tasksByGanttId, task => {
        const settings = _.find(item.resourceProjects, o => +o.projectId === +task.gantt_id);

        return settings && settings.type === constants.RESOURCE_TIME_TYPE;
      });
    }

    item.tasksByGanttId = tasksByGanttId.map(task => {
      task.taskData.calendar_id = task.gantt_id;

      return task;
    });

    return item;
  },
  prepareData() {
    const projectData = ganttViewModel.getActiveViewData();
    const filterByProjects = _state.filter.projects || [];
    //

    if (!projectData) {
      return;
    }

    let ganttIdsRorResources = _state.isWorkloadPage ? _state.ganttIds : (projectData && (projectData.ganttIDs || [projectData.gantt_id]));

    if (filterByProjects.length) {
      ganttIdsRorResources = filterByProjects;
    }

    const allProjectsResources = globalStore.getters['resourcesModel/getResourcesByProjectIdsAndType'](ganttIdsRorResources, constants.RESOURCE_TIME_TYPE);
    const preparedData = [];
    let isShowUnassigned = true;
    let resources = _.sortBy(allProjectsResources,
      [function (o) {
        return !o.userId;
      }, 'name']);

    // filter right and roles

    resources = resources.filter(resource => resource.resourceProjects.some(project => rights.project.hasRight(project.projectId, 'workload')));

    if (_state.filter.resources.length) {
      _state.filter.resources = _state.filter.resources.map(resourceId => (resourceId === '-1' ? 'unassigned' : +resourceId || 'unassigned'));
      isShowUnassigned = _state.filter.resources.includes('unassigned');
    }

    if (_state.isWorkloadPage && isShowUnassigned) {
      resources.push({
        id: 'unassigned',
        name: __('notAssigned'),
        unassigned: true,
        photo: svg(icon_unassigned),
        customDays: [],
        workingDays: [1, 2, 3, 4, 5, 6, 7],
        workingHours: 0,
      });
    }

    resources.forEach((res, i, array) => {
      const item = _helpers.prepareResourceData(res, i, array,
        filterByProjects.length ? _.intersection(_state.ganttIds, filterByProjects) : _state.ganttIds);

      preparedData.push(item);

      if (_state.openedResources[item?.id]) {
        const resourceTasksItem = _.assign(_.clone(item), {
          id: item.id + RESOURCE_TASK_ID_PREFIX,
          isTasksItem: true,
        });

        if (!_state.isWorkloadPage) {
          // clear invisible tasks from taskslayout in current scope of projects
          resourceTasksItem.tasksByGanttId = resourceTasksItem.tasksByGanttId.filter(o => gantt.isTaskExists(o.task_id));
        } else {
          const tasksToParse = [];

          resourceTasksItem.tasksByGanttId.forEach(o => {
            if (!gantt.isTaskExists(o.task_id)) {
              tasksToParse.push(o.taskData);
            }
          });

          tasksToParse.length && gantt.silent(() => {
            gantt.blockEvents();
            gantt.parse({ data: tasksToParse });
            gantt.unblockEvents();
          });
        }

        preparedData.push(resourceTasksItem);
      }
    });

    if (!_.isEmpty(preparedData)) {
      preparedData[preparedData.length - 1].isLast = true;
    }

    const resultData = [];
    let needReCalculate = false;

    preparedData.forEach(item => {
      if (item) {
        if (_state.cache[item?.id]) {
          item = {
            ..._state.cache[item.id],
            ...{ tasksByGanttId: item.tasksByGanttId },
          };
          item.selectedTaskId = null;
          item._fromCache = true;
        } else {
          needReCalculate = true;
        }

        if (gantt.config.durationData.mode === 'day'
          && _state.selectedTask.id
          && item.id === _state.selectedTask.resourceId + RESOURCE_TASK_ID_PREFIX) {
          const task = gantt.isTaskExists(_state.selectedTask.id) && gantt.getTask(_state.selectedTask.id);

          if (task) {
            item.selectedTaskId = task.id;
            needReCalculate = true;
          }
        }

        item.opened = _state.openedResources[item.id];
        resultData.push(item);
      }
    });

    if (!needReCalculate) {
      return resultData;
    }

    _handlers.showSpinner();

    _helpers.workerCalculate(resultData, _state.ganttIds);
  },
  getCSSByWorkHours(hours, workHours, workTasks) {
    if (!workTasks) {
      return 'none';
    } if (hours === workHours) {
      return 'ok';
    } if (hours < workHours) {
      return 'underloaded';
    }

    return 'overloaded';
  },
  formatNumber(val) {
    let res = 0;

    if (val >= 100) {
      res = Math.round(val);
    }

    if (val >= 10 && val < 100) {
      res = Math.round(val * 10) / 10;
    }

    if (val < 10) {
      res = Math.round(val * 100) / 100;
    }

    return res;
  },
  checkIsSelectedTaskBar(taskId, resourceId) {
    return taskId === _state.selectedTask.id && resourceId === _state.selectedTask.resourceId;
  },
  isTaskStartDateBlockedByLinkDependencies(taskData) {
    if (!gantt.config.auto_scheduling && ganttViewModel.getActiveViewData().isSingleProject && !_state.isWorkloadPage) {
      return false;
    }

    if (!taskData.auto_scheduling && (!ganttViewModel.getActiveViewData().isSingleProject || _state.isWorkloadPage)) {
      return false;
    }

    const children = gantt.getChildren(taskData.parent)/* .filter(childID => gantt.getTask(childID).type !== gantt.config.types.button) */;
    const predecessors = gantt._getPredecessors(taskData, children.length > 1);

    if (predecessors.length === 1) {
      return true;
    }

    const mastersData = gantt.getMasterLinksByTaskId(taskData.id);
    let shouldBlock = false;

    mastersData.forEach(masterData => {
      switch (masterData.linkType) {
      case gantt.config.links.finish_to_start:
      case gantt.config.links.start_to_start:
        shouldBlock = true;
        break;
      }
    });

    return shouldBlock;
  },
  checkVisibilityForTaskBar(task, resource) {
    const timeline = gantt.$ui.getView('workloadTimeline');
    const scale = timeline.getScale();
    const startTimestamp = gantt.date.day_start(new Date(task.taskData.start_date)).valueOf();
    const endTimestamp = gantt.date.add(gantt.date.day_start(new Date(task.taskData.end_date)), 1, 'day').valueOf();
    const startIndex = scale.trace_indexes[startTimestamp];
    const endIndex = scale.trace_indexes[endTimestamp];
    let isVisible = true;

    const startCheck = scale.left[startIndex] > (2 * timeline.$task.offsetWidth + gantt.getScrollState().x);
    const endCheck = scale.left[endIndex] < (gantt.getScrollState().x - timeline.$task.offsetWidth);

    if (startCheck || endCheck) {
      isVisible = false;
    }

    if (!rights.project.hasRight(task.gantt_id, 'all_tasks') && resource.userId !== user.id) {
      isVisible = false;
    }

    return isVisible;
  },
  parseAndRender(data) {
    if (_state.filter.resources && _state.filter.resources.length) {
      data = _.filter(data, resource => _state.filter.resources.find(id => id === resource.id || String(resource.id).includes(id)));
    }

    const scrollViewY = gantt.$ui.getView('workloadVScroll');
    const scrollViewX = gantt.$ui.getView('scrollHor');
    const gridView = gantt.$ui.getView('workloadGrid');
    const timelineView = gantt.$ui.getView('workloadTimeline');

    let lastScrollY = scrollViewY.getScrollState().position;
    const lastScrollX = scrollViewX.getScrollState().position;

    _workloadStore._skip_refresh = false;

    scrollViewY.scrollTo(null, 0);

    data = _helpers.prepareForSmartScroll(data);

    _state.hardCache = data;
    _workloadStore.clearAll();
    _workloadStore.parse(data);

    if (_state.needResize || (_state.resourcesLayout.totalHeight > scrollViewY.getSize().height && !scrollViewY.getScrollState().scrollSize)) {
      scrollViewY.resize();
      _state.needResize = false;
    }

    if (_state.resourcesLayout.totalHeight <= scrollViewY.getSize().height) {
      lastScrollY = 0;
    }

    scrollViewY.setSize(scrollViewY.getSize().width, scrollViewY.getSize().height);// adjust scroll bar size for new content
    scrollViewY.scrollTo(null, lastScrollY);
    gridView && gridView.scrollTo(null, lastScrollY);
    timelineView && timelineView.scrollTo(lastScrollX, lastScrollY);
    _workloadStore._skip_refresh = true;

    _renderer.renderTodayMarker();
    if (data.length) {
      _renderer.addFakeRowWithBackground();
    }

    _helpers.blockFilterPopup(false);
    _handlers.showSpinner.cancel();
    _handlers.hideSpinner();

    _renderer.renderEmptyLayout(data.length);
  },
  prepareForSmartScroll(data) {
    // calculates positions for rendering workload rows
    let top = 0;
    let result = [];

    result = data.map((item, i) => {
      if (item.id === 'unassigned-tasks') {
        const maxHeight = _state.visibleUnassignedTasksRows * ROW_HEIGHT;

        _state.resourcesLayout[item.id].visibleHeight = _state.resourcesLayout[item.id].height;

        if (_state.resourcesLayout[item.id].height > maxHeight) {
          _state.resourcesLayout[item.id].visibleHeight = maxHeight + ROW_HEIGHT;
          item.isPartial = true;
        }
      }

      _state.resourcesLayout[item.id].top = top;
      top += _state.resourcesLayout[item.id].visibleHeight || _state.resourcesLayout[item.id].height;

      item.lastChild = false;
      item.firstChild = false;

      if (data.length - 1 === i) {
        item.lastChild = true;
      }

      if (data.length - 2 === i) {
        item.prevLastChild = true;
      }

      if (i === 0) {
        item.firstChild = true;
      }

      return item;
    });

    _state.resourcesLayout.totalHeight = top;

    return result;
  },
  afterFilter: _.debounce((filterState, isFilterApplied, workloadFilter) => {
    isFilterApplied && _helpers.blockFilterPopup(true);
    _state.filter = { ...filterState };
    _state.filter.isApplied = isFilterApplied;
    _state.filter.workloadFilter = workloadFilter;
    _run();
  }, 50),
  blockFilterPopup(isBlock) {
    // const popup = $$('workloadFilterPopup');
    const popup = document.getElementById('app-filter');

    if (popup) {
      let overlay = document.querySelector('.workload_filter_overlay');

      if (!isBlock) {
        overlay && overlay.remove();

        return;
      }

      if (!overlay) {
        overlay = document.createElement('div');
        overlay.classList.add('workload_filter_overlay');
        document.body.appendChild(overlay);
      }

      overlay.style.width = `${popup.offsetWidth}px`;
      overlay.style.height = `${popup.offsetHeight}px`;
      overlay.style.top = `${popup.offsetTop}px`;
      overlay.style.left = `${popup.offsetLeft}px`;
    }
  },
  refreshWorkload() {
    const workloadStore = gantt.getDatastore('workloadStore');
    const scrollViewY = gantt.$ui.getView('workloadVScroll');

    workloadStore._skip_refresh = false;
    workloadStore.refresh();
    workloadStore._skip_refresh = true;
    scrollViewY.scrollTo(null, scrollViewY.getScrollState().position);
    _renderer.renderTodayMarker();
    _renderer.addFakeRowWithBackground();
  },
  validateRun() {
    const viewData = ganttViewModel.getActiveViewData();

    const hasRightWorkload = ((viewData?.ganttIDs && viewData.ganttIDs.some(id => rights.project.hasRight(id, 'workload')))
      || rights.project.hasRight(viewData?.gantt_id, 'workload'));

    if (!app.config.mode.isBase && !app.config.mode.isExport) {
      return;
    }

    if (gantt.config.hide_workload && !routerHelper.isWorkloadViewRoute()) {
      return;
    }

    if (routerHelper.isListViewRoute()) {
      return;
    }

    if (!projectsModel.count()) {
      return;
    }

    if (!hasRightWorkload && !app.config.mode.isExport && !routerHelper.isWorkloadViewRoute()) {
      return;
    }

    if (gantt.config.durationData.mode === 'hour' && !routerHelper.isWorkloadViewRoute()) {
      return;
    }

    if (_state.editTaskCellMode) {
      return;
    }

    if (!globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures.workload.name)) {
      return;
    }

    return true;
  },
  getWorkloadCalculationMode() {
    const button = document.body.querySelector('.workload-header-mode');
    let mode = false;

    if (button) {
      mode = +button.getAttribute('data-mode') === 2;
    }

    if (_state.isCommonWorkloadPage) {
      mode = true;
    }

    if (_state.filter.projects && _state.filter.projects.length) {
      mode = false;
    }

    return mode;
  },
};

const _afterTaskCellEditDebounced = _.debounce(() => {
  _state.editTaskCellMode = false;

  if (_state.taskCellEstimateChanged) {
    _run();
    _state.taskCellEstimateChanged = false;
  }
}, 250);

const _mainParseDebounced = _.debounce(isClearCache => {
  if (isClearCache) {
    _state.cache = {};
  }

  const prepareData = _helpers.prepareData();

  if (prepareData) {
    _helpers.parseAndRender(prepareData);
  }
}, 150);

const _hardCachedParseDebounced = _.debounce(() => {
  if (_state.hardCache) {
    _helpers.parseAndRender(_state.hardCache);
  }
}, 50);

const _run = function (config = {}) {
  _state.isWorkloadPage = routerHelper.isWorkloadViewRoute();
  _state.isCommonWorkloadPage = routerHelper.isCommonWorkloadViewRoute();

  app.trigger('workloadCore:start:run', config.fromCache || config.useHardCache || _state.editTaskCellMode);

  if (!_helpers.validateRun()) {
    app.trigger('workload:skipped');

    return;
  }

  let ganttIds = _state.ganttIds || [];

  if (_state.isCommonWorkloadPage) {
    ganttIds = [];
  }

  if (!_state.isWorkloadPage && _state.viewMode !== 'tasks') {
    _state.viewMode = gantt.config.resource_loading_type;
  }

  if (config.ganttIds) {
    ganttIds = config.ganttIds;
  }

  if (config.viewMode) {
    _state.viewMode = config.viewMode;
    if (config.viewMode !== 'hours') {
      _state.selectedTask = {};
    }
  }

  if (!ganttIds.length) {
    ganttIds = projectsModel.getAllProjects().map(o => o.gantt_id);
  }

  if (app.config.mode.isExport) {
    const projectData = ganttViewModel.getActiveViewData();

    ganttIds = projectData.ganttIDs || [projectData.gantt_id];
  }

  _state.ganttIds = ganttIds;

  if (config.filter) {
    _state.filter = config.filter;
  }

  if (_state.filter.projects && _state.filter.projects.length) {
    _state.filter.projects = _state.filter.projects.map(ganttId => +ganttId);
  }

  _state.workloadCalculationMode = _helpers.getWorkloadCalculationMode();

  config.needResize && gantt.$ui.getView('workload').resize();

  if (config.needResize) {
    _state.needResize = config.needResize;
  }

  if (config.useHardCache) {
    _hardCachedParseDebounced();

    return;
  }

  _mainParseDebounced(!config.fromCache);
};

const _initWorker = function () {
  if (window.Worker) {
    let version = Date.now();

    if (GT.productVersion && GT.productVersion.buildVersion) {
      version = GT.productVersion.buildVersion;
    }

    _state.worker = new Worker('/workers/workload_worker.js' + `?${version}`);

    _state.worker.onmessage = function (e) {
      _state.resourcesLayout = { ..._state.resourcesLayout, ...e.data.resourcesLayout };
      e.data.resourcesData.forEach(obj => {
        _state.cache[obj.id] = obj;
      });
      _helpers.parseAndRender(e.data.resourcesData);
    };

    _state.worker.onerror = function (e) {
      webix.message({ type: 'error', text: __('workload_calculating_error'), expire: 10000 });
    };
  }
};

const _updateState = function (changedState) {
  _state = { ..._state, ...changedState };
};

const _getState = function (property) {
  if (property) {
    return _state[property];
  }

  return { ..._state };
};

const _timelineGetSize = function () {
  const config = this.$getConfig();

  const contentHeight = _state.resourcesLayout ? _state.resourcesLayout.totalHeight : 0;
  const contentWidth = this.getScale().full_width;

  return {
    x: this.$config.width,
    y: this.$config.height,
    contentX: this.isVisible() ? contentWidth : 0,
    contentY: this.isVisible() ? (config.scale_height + contentHeight) : 0,
    scrollHeight: this.isVisible() ? contentHeight : 0,
    scrollWidth: this.isVisible() ? contentWidth : 0,
  };
};

gantt._getWorkloadItemTop = _renderer.getWorkloadItemTop;
gantt._getWorkloadItemHeight = _renderer.getWorkloadItemHeight;

export default {
  run: _run,
  updateState: _updateState,
  getState: _getState,
  showResourceTasks: _handlers.showResourceTasks,
  afterFilter: _helpers.afterFilter,
  formatNumber: _helpers.formatNumber,
  prepareProjectConfigsForWorker: _helpers.prepareProjectConfigs,
  refreshWorkload: _helpers.refreshWorkload,
  initWorker: _initWorker,
  timelineGetSize: _timelineGetSize,
  renderLayers: [
    // _renderer.main,
    {
      renderer: {
        render(item, timeline, viewport) {
          return _renderer.main(item, timeline);
        },
        update: null,
        getRectangle(item, view) {
          return {
            top: app.config.mode.isExport ? 0 : _renderer.getWorkloadItemTop(item.id),
            height: app.config.mode.isExport ? Infinity : _renderer.getWorkloadItemHeight(item.id),
            left: 0,
            right: Infinity,
          };
        },
      },
    },
  ],
  isTaskStartDateBlockedByLinkDependencies: _helpers.isTaskStartDateBlockedByLinkDependencies,
};
