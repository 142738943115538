<template>
  <div
    class="cell-text"
    :class="{
      'edit': editMode && !disabled,
      'disabled': disabled,
      'select_none': !editMode,
      'right':type==='time',
      'not_allowed': !editable
    }"
    @click="clickOnCell"
  >
    <div v-if="editMode && !disabled && editable">
      <input
        ref="input_field"
        v-model="inputTxt"
        type="text"
        :autofocus="editMode"
        @keyup.enter="editedValue"
        @focus="$event.target.select()"
        @blur="editMode = false"
        @change="editedValue"
      >
    </div>
    <span
      v-else
      class="one-wrap"
      :style="{opacity: cellOpecity}"
      :class="{'crossout': crossout}"
    > {{ formattedVal }} </span>
  </div>
</template>

<script>
import timeParser from '../../../../helpers/timeParser';

export default {
  name: 'TextCell',
  components: { },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    value: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return (['text', 'time'].indexOf(value) !== -1);
      },
    },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      editMode: false,
      inputTxt: '',
      oldTxt: '',
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },

    formattedVal() {
      if (this.emptyForMilestone) return '';

      let value = this.value;

      if (this.type === 'time') {
        value = this.outputTime(value);
      }

      return value;
    },
    emptyForMilestone() {
      return this.item.type === 'milestone' && (this.property === 'estimation' || this.property === 'duration');
    },
  },

  watch: {
    editMode(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.input_field.focus();
        });
      } else {
        this.changeValue();
      }
    },
    value(val) {
      this.inputTxt = this.formattedVal;
    },
  },

  mounted() {
    this.inputTxt = this.formattedVal;
    this.oldTxt = this.formattedVal;
  },

  methods: {
    clickOnCell(e) {
      if (this.disabled || this.emptyForMilestone || !this.editable) {
        e.preventDefault();

        return;
      }

      this.inputTxt = this.formattedVal;
      this.oldTxt = this.formattedVal;
      this.editMode = true;
    },
    editedValue() {
      this.editMode = false;
    },

    changeValue() {
      const val = this.inputTime(this.inputTxt);

      if (this.inputTxt === this.oldTxt || val === this.oldTxt) return;
      this.inputTxt = val;
      this.oldTxt = val;
      this.$emit('change', val);
    },

    outputTime(val) {
      if (this.type === 'text') return val;

      const parsed = timeParser.output(val, {
        durationData: gantt.config.durationData,
        prop: this.property,
      });

      return parsed;
    },
    inputTime(val) {
      if (this.type === 'text') return val;

      const parsed = timeParser.input(val, {
        durationData: gantt.config.durationData,
        prop: this.property,
      });

      return parsed ? parsed.value : this.item[this.property];
    },
  },
};
</script>

<style scoped lang='less'>
.cell-text{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &.edit{
      box-shadow: inset 0 0 0 1px #247ecc;
      background-color: #fff;
    }

    &.disabled{
      color: #9e9e9e;
    }

    input{
      width: 100%;
    }
    &.right{
      justify-content: flex-end;
      input{
        text-align: right;
      }
    }

    &.not_allowed{
      cursor: not-allowed;
    }
}

.one-wrap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.select_none{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.discolor{
    opacity: 0.5;
}

.crossout{
    text-decoration: line-through;
}
</style>
