var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.overview_toolbar },
    [
      _vm.isInitialized
        ? _c("div", { class: _vm.$.wrapper }, [
            _c(
              "div",
              { class: _vm.$.controls },
              [
                _c("compare-dropdown", {
                  attrs: {
                    "overview-type": _vm.overviewData.type_overview,
                    "baseline-id": _vm.overviewData.baseline_id,
                    baselines: _vm.baselines,
                  },
                  on: { onOpen: _vm.openCompare, onSelect: _vm.changeCompare },
                }),
                _vm._v(" "),
                _vm.baselineName
                  ? _c("div", { class: _vm.$.text_label }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("overview_comparison_text")) +
                          ": "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.baselineName))]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$.right_side },
              [
                _c("div", { class: _vm.$.btn_group }, [
                  _c(
                    "div",
                    {
                      class: _vm.$.text_button,
                      on: { click: _vm.onClickMissedFeature },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locales("header_contact_btn")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.charts.length
                  ? _c("vgp-button", {
                      class: _vm.$.manage_button,
                      attrs: {
                        type: "primary",
                        label: _vm.locales("overview_manage_widgets_button"),
                        "x-small": "",
                      },
                      on: { onClick: _vm.openManageModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c("overview-toolbar-skeleton"),
      _vm._v(" "),
      _vm.isShowModal
        ? _c("manage-modal", {
            attrs: {
              show: _vm.isShowModal,
              charts: _vm.charts,
              "gantt-id": _vm.ganttId,
            },
            on: { onCancel: _vm.closeManageModal, onSave: _vm.saveManage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }