var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.skeleton_container, attrs: { id: "skeletonListView" } },
    [
      _c("skeleton-wrapper", {
        style: { width: "100%" },
        attrs: { id: "skeletonWrapper" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", [
                  _c(
                    "div",
                    { class: _vm.$.headerRow },
                    _vm._l(4, function (column) {
                      return _c(
                        "div",
                        [
                          _c("skeleton-item", {
                            style: { width: "107px", height: "17px" },
                            attrs: { type: "bar" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("table", { class: _vm.$.skeleton_table }, [
                  _c(
                    "tbody",
                    _vm._l(10, function (row) {
                      return _c(
                        "tr",
                        [
                          _c(
                            "td",
                            { class: _vm.$.firstCell },
                            [
                              _c(
                                "div",
                                { class: _vm.$.resourcePart },
                                [
                                  _c("skeleton-item", {
                                    style: { marginRight: "8px" },
                                    attrs: { type: "circle", size: "bg" },
                                  }),
                                  _vm._v(" "),
                                  _c("skeleton-item", {
                                    style: { height: "14px" },
                                    attrs: { type: "bar", width: "wide" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                attrs: { type: "square", size: "bg" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(80, function (col) {
                            return _c(
                              "td",
                              { class: _vm.$.skeleton_col },
                              [
                                _vm.checkboxes[row][col]
                                  ? _c("skeleton-item", {
                                      attrs: { type: "square", size: "md" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }