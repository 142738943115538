<template>
  <div
    ref="passStrengthPopup"
    class="pass-strength-popup"
    :style="{'left': left, 'top': top}"
  >
    <div class="container">
      <div class="pass-strength-desc">
        <b>{{ locales('pass_strength_caption') }}</b>: {{ strengthStatus }}
      </div>
      <div
        class="strength-tmp"
        v-html="strengthTemplate"
      />
      <div class="text">
        {{ locales('pass_strength_descr') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPasswordStrengthStatistics,
  getPasswordStrengthTextByCode,
  renderPwdStrengthTpl,
} from '../../../../../helpers/passHelper';

export default {
  data() {
    return {
      locales: __,
      popupHeight: '',
      popupWidth: ''
    };
  },
  computed: {
    strengthStatus() {
      const value = this.$store.getters['passStrengthPopup/value'];
      const { strengthCode } = getPasswordStrengthStatistics(value);

      return getPasswordStrengthTextByCode(strengthCode);
    },
    strengthTemplate() {
      const value = this.$store.getters['passStrengthPopup/value'];
      const { strengthCode } = getPasswordStrengthStatistics(value);

      return renderPwdStrengthTpl(strengthCode);
    },
    coords() {
      return this.$store.getters['passStrengthPopup/position'];
    },
    top() {
      return `${this.coords.top - this.popupHeight / 2 + this.coords.height / 2}px`;
    },
    left() {
      return `${this.coords.left - this.popupWidth}px`;
    },
  },
  mounted() {
    this.popupHeight = this.$refs.passStrengthPopup.clientHeight;
    this.popupWidth = this.$refs.passStrengthPopup.clientWidth;
    document.addEventListener('click', this.hidePopup);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hidePopup);
  },
  methods: {
    hidePopup(e) {
      if (!this.$el.contains(e.target)) {
        this.$store.dispatch('passStrengthPopup/close');
      }
    },
  },
};
</script>

<style scoped src='./less/passStrengthPopup.less'></style>
