<template>
  <div
    v-click-outside="close"
    :class="$style.baseline"
  >
    <vgp-icon-button
      ref="contextMenuButton"
      :icon="{ name:'baseline' }"
      :colors="buttonColors"
      :active="isShowPopup || isEnabledBaselines"
      :disabled="isDisabled"
      @onClick="toggle"
    />

    <context-menu
      v-if="isShowPopup"
      :position="positionContext"
      style="width: 240px"
    >
      <template #body>
        <div
          v-if="isEmpty"
          :class="$style.empty"
        >
          <div
            :class="$style.icon"
            v-html="baselineIcon"
          />
          <div :class="$style.text">
            {{ locales('there_are_not_baselines') }}
          </div>
        </div>

        <div
          v-if="isAccess"
          :class="$style.button"
        >
          <vgp-button
            type="primary-border"
            small
            :label="locales('create_baseline')"
            @onClick="createBaseline"
          />
        </div>

        <div
          v-if="!isEmpty"
          :class="[
            $style.menu,
            'gantt_scroll',
          ]"
        >
          <div
            v-for="item in items"
            :key="item.id"
            :class="[
              $style.menu_item,
            ]"
          >
            <div :class="$style.checkbox">
              <vgp-radio-button
                :checked="enabledBaselines[item.id]"
                @onChange="handleCheckbox($event, item)"
              />
            </div>
            <div :class="[$style.title, !isAccess || item.id === 'default' ? $style.title_no_access : '']">
              <input
                ref="baseLineTitleInput"
                :data-baseline-item-input="item.id"
                type="text"
                :class="$style.input"
                :value="item.name"
                :readonly="!isAccess || item.id === 'default'"
                @keydown.enter="handleTitleKeydown(item)"
                @blur="editName($event, item)"
              >
            </div>
            <div
              v-if="isAccess && item.id !== 'default'"
              :class="$style.icon"
              @click="handleDelete(item)"
            >
              <svg-sprite
                name="delete"
                type="regular"
                size="24"
              />
            </div>
          </div>
        </div>
      </template>
    </context-menu>

    <core-confirm-popup
      :show="isShowDeletePopup"
      popup-width="440"
      close-icon
      :title="locales('settings_msg_delete_baseline_confirm_title')"
      :text="locales('settings_msg_delete_baseline_confirm_text')"
      :cancel-button="{ title: locales('common_cancel'), type: 'secondary' }"
      :ok-button="{ title: locales('common_delete'), type: 'destructive' }"
      @onClickCancel="deleteConfirmCancel"
      @onClickOk="deleteConfirmOk"
    />
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import baselineIcon from '../../../svg/baselines/baseline.svg';
import coreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';

import _ from '../../../libs/lodash';
import app from '../../../app';
import projectsComponent from '../../../components/Project';
import baselineModel from '../../../models/baselines';
import baselineObject from '../../gantt/modules/baseline';
import correctDates from '../../../helpers/correctDates';
import rights from '../../../components/rights/index';
import historyModel from '../../../models/history';
import VgpRadioButton from '../../common/VueComponents/radioButton/vgpRadioButton.vue';

export default {
  components: {
    VgpRadioButton,
    svgSprite,
    vgpButton,
    vgpIconButton,
    coreConfirmPopup,
  },
  props: {
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locales: __,
      isDisabled: false,
      isShowPopup: false,
      positionContext: null,
      baselineIcon,
      enabledBaselines: {},
      isAccess: false,
      items: [],
      isDisabledItems: false,
      isShowDeletePopup: false,
      deleteItem: null,
      // copyPasteTaskID: null,
      events: [],
      noneBaseline: { id: 'default', name: __('none_baseline') },
      selectedItem: null,
    };
  },
  computed: {
    isEmpty() {
      return !this.items.length;
    },
    isEnabledBaselines() {
      return Object.keys(this.enabledBaselines).length && !this.enabledBaselines[this.noneBaseline.id];
    },
    copyPasteTaskID() {
      const copiedTask = this.$store.getters['copyPaste/copiedTask'];
      const taskMap = this.$store.getters['copyPaste/taskMap'];
      const taskId = taskMap[copiedTask.taskId];

      if (taskId) {
        return taskId;
      }

      return null;
    },
  },
  created() {
    if (gantt.config.gantt_id) {
      baselineModel.init().then(() => {
        this.buildBaselines();
      });
    }

    // gantt.attachEvent('setSpecialPasteID', taskID => {
    //   this.copyPasteTaskID = taskID;
    // });

    const eventCollaboration = app.on('onAfterCollaboration', ({ event }) => {
      if (event === 'ResourceRoleUpdated' || event === 'ResourceOnProjectsUpdated') {
        this.isShowPopup = false;
        this.buildBaselines();
      }
    });

    const eventGanttInit = app.on('gantt:init:after', () => {
      baselineModel.init().then(() => {
        this.buildBaselines();
      });
    });

    const eventBuild = app.on('baselineHeader:build', () => {
      baselineModel.init().then(() => {
        this.buildBaselines();
      });
    });

    const eventProgress = app.on('gantt:progress:show', () => {
      this.enabledBaselines = {};
      this.isDisabled = true;
    });

    this.events.push(eventCollaboration);
    this.events.push(eventGanttInit);
    this.events.push(eventBuild);
    this.events.push(eventProgress);
  },
  beforeDestroy() {
    this.events.map(event => app.off(event));
  },
  methods: {
    toggle() {
      if (this.isDisabled) return;
      userExtAnalytics.log('header_click_baseline_icon');
      this.isShowPopup = !this.isShowPopup;
      this.$emit('isShowBaselinePopup', this.isShowPopup);
      if (this.isShowPopup) {
        this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();
        this.update();
        baselineModel.init().then(() => {
          this.update();
        });
      }
    },
    close() {
      if (!this.isShowDeletePopup) {
        this.isShowPopup = false;

        this.$emit('isShowBaselinePopup', this.isShowPopup);
      }
    },
    update() {
      this.isAccess = this.checkAccessForActiveProject();
      this.items = baselineModel.getByGanttId(gantt.config.gantt_id).sort((a, b) => a.id - b.id);
      if (this.items.length) this.items.unshift(this.noneBaseline);
    },
    checkAccessForActiveProject() {
      return rights.project.hasRight(gantt.config.gantt_id, 'gantt_chart');
    },
    putInCookie(item) {
      if (app.config.mode.isExport) {
        return;
      }
      const baselines = webix.storage.cookie.get('baselines') || {};
      const newBaseline = {};

      newBaseline[item.id] = item;
      baselines[item.gantt_id] = _.assign(baselines[item.gantt_id], newBaseline);

      webix.storage.cookie.put('baselines', baselines);
    },
    removeFromCookie(gantt_id, id) {
      const baselines = webix.storage.cookie.get('baselines');

      if (baselines && baselines[gantt_id]) {
        delete baselines[gantt_id][id];

        if (_.keys(baselines[gantt_id]).length === 0) {
          delete baselines[gantt_id];
        }
      }

      webix.storage.cookie.put('baselines', baselines);
    },
    createBaseline() {
      const gantt_id = gantt.config.gantt_id;
      let actionHashPromise;

      if (gantt._correctDatesActionHash) {
        actionHashPromise = Promise.resolve(gantt._correctDatesActionHash);
      } else {
        actionHashPromise = projectsComponent.getLastActionHash(gantt_id);
      }

      actionHashPromise
        .then(actionHash => {
          // historyModel.generateActionHash(); needed for craetion baseline if project was imported through the excel
          gantt._correctDatesActionHash = actionHash || historyModel.generateActionHash();

          return correctDates.run();
        })
        .then(() => {
          baselineModel.create(gantt_id);
          this.update();

          userExtAnalytics.log('create_baseline_done', { from: 'header' });
        });
    },
    handleTitleKeydown(item) {
      document.querySelector(`[data-baseline-item-input="${item.id}"]`)?.blur();
    },
    editName(e, item) {
      let value = e.target.value;

      if (value.length > 255) {
        value = value.substring(0, 255);
      }

      let newValue = value.trim();

      if (newValue === item.name) return false;
      if (!newValue) newValue = item.name;

      baselineModel.updateName({ ...item, ...{ name: newValue } });
      gantt.callEvent('ganttRender');
      // fix for FF only
      if (e.target) {
        e.target.setSelectionRange(0, 0);
      }
      this.update();
    },
    handleDelete(item) {
      this.deleteItem = item;
      this.isShowDeletePopup = true;
    },
    deleteConfirmCancel() {
      this.deleteItem = null;
      setTimeout(() => {
        this.isShowDeletePopup = false;
      });
    },
    deleteConfirmOk() {
      if (this.enabledBaselines[this.deleteItem.id]) {
        this.hideBaseline(this.deleteItem);
      }
      baselineModel.delete(this.deleteItem.id);
      this.update();
      this.deleteItem = null;
      setTimeout(() => {
        this.isShowDeletePopup = false;
        this.$set(this.enabledBaselines, this.noneBaseline.id, 1);
        const promise = this.hideBaseline();

        promise.finally(() => {
          gantt.callEvent('ganttRender');
          app.trigger('baseline:changedMode');
          this.isDisabledItems = false;
        });
      });
    },
    hideBaseline(item) {
      if (!item) {
        item = this.selectedItem;
      }
      if (item) {
        this.$delete(this.enabledBaselines, item.id);
        this.removeFromCookie(item.gantt_id, item.id);
        baselineObject.handlers.removeBaseline(item.id);
      }

      const promise = webix.promise.defer();

      promise.resolve();

      return promise;
    },
    getDataForBaseline(item) {
      if (app.config.mode.isExport && app.config.mode.ignoreFilters) {
        return Promise.resolve(GT.ganttData.baselineTasks);
      }

      return baselineModel.getByGanttIdAndEventId(item.gantt_id, item.event_id)
        .then(data => Promise.resolve(data));
    },
    addBaselineTaskLayer(item) {
      this.selectedItem = item;

      return this.getDataForBaseline(item)
        .then(data => {
          if (data && data.tasks && item.id) {
            this.putInCookie(item);
            this.enabledBaselines = {};
            this.$set(this.enabledBaselines, item.id, 1);
            baselineObject.handlers.buildBaseline(data.tasks, item.id);
          }
        });
    },
    handleCheckbox(checked, item) {
      this.isDisabledItems = true;

      let promise;

      if (item.id === 'default') {
        const emptyCookie = _.reduce(webix.storage.cookie.get('baselines')[gantt.config.gantt_id], (promises, baseline) => {
          this.removeFromCookie(gantt.config.gantt_id, baseline.id);
          this.$delete(this.enabledBaselines, baseline.id);
          this.removeFromCookie(baseline.gantt_id, baseline.id);
          baselineObject.handlers.removeBaseline(baseline.id);

          return promises;
        }, []);

        webix.promise.all(emptyCookie);
        this.$set(this.enabledBaselines, this.noneBaseline.id, 1);
        gantt.callEvent('ganttRender');

        return;
      }
      this.selectedItem = item;
      const options = {
        id: item.id,
        gantt_id: item.gantt_id,
        event_id: item.event_id,
      };

      if (checked) {
        if (webix.storage.cookie.get('baselines')) {
          const emptyCookie = _.reduce(webix.storage.cookie.get('baselines')[gantt.config.gantt_id], (promises, baseline) => {
            this.removeFromCookie(gantt.config.gantt_id, baseline.id);
            this.$delete(this.enabledBaselines, baseline.id);
            this.removeFromCookie(baseline.gantt_id, baseline.id);
            baselineObject.handlers.removeBaseline(baseline.id);

            return promises;
          }, []);

          webix.promise.all(emptyCookie);
        }

        promise = this.addBaselineTaskLayer(options);
        userExtAnalytics.log('project_compare_baseline_done');
      }

      promise.finally(() => {
        gantt.callEvent('ganttRender');
        app.trigger('baseline:changedMode');
        this.isDisabledItems = false;
      });
    },
    buildBaselines(baselines = webix.storage.cookie.get('baselines')) {
      if (!baselines) {
        this.isDisabled = false;
        this.$set(this.enabledBaselines, this.noneBaseline.id, 1);

        return;
      }

      const gantt_id = gantt.config.gantt_id;

      if (!rights.project.hasRight(gantt_id, 'gantt_chart')) {
        const baselinesActive = baselines[gantt_id];

        if (baselinesActive) {
          Object.keys(baselinesActive).map(key => this.hideBaseline(baselinesActive[key]));
          gantt.callEvent('ganttRender');
        }

        this.isDisabled = false;

        return;
      }

      if (baselines && baselines[gantt_id] && app.config.mode.isBase) {
        baselineModel.init()
          .then(() => {
            const promises = _.reduce(baselines[gantt_id], (promises, baseline) => {
              if (baseline && baselineModel.getItem(baseline.id) && promises.length < 1) {
                promises.push(this.addBaselineTaskLayer(baseline));
              } else {
                this.removeFromCookie(gantt_id, baseline.id);
              }

              return promises;
            }, []);

            webix.promise.all(promises)
              .then(() => {
                gantt.callEvent('ganttRender');
                if (this.copyPasteTaskID) {
                  app.trigger('paste:special', this.copyPasteTaskID);
                  this.copyPasteTaskID = null;
                }
              }).finally(() => {
                this.isDisabled = false;
              });
          });
      } else {
        this.isDisabled = false;
        this.$set(this.enabledBaselines, this.noneBaseline.id, 1);
      }
    },
  },
};
</script>

<style module src="../less/baseline.less"></style>
