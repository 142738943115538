const CUSTOM_COLUMN = {
  CUSTOM_COLUMN_TEMPLATE: {
    1: { editor: 'select', template: 'text', sortType: 'one' },
    2: { editor: 'text:custom' },
    3: { editor: 'number:custom', type: 'number' },
    4: { editor: 'datepicker:custom' },
    5: { editor: 'checkbox' },
    6: { editor: 'select', template: 'color', sortType: 'one' },
    7: { editor: 'multiselect', template: 'text', sortType: 'multi' },
    8: { editor: 'multiselect', template: 'icon+text', sortType: 'multi' }, // people
    9: { editor: 'multiselect', template: 'label', sortType: 'multi' },
  },
};

export { CUSTOM_COLUMN };
