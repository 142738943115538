var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentFilter
    ? _c(
        "div",
        {
          ref: "filter",
          class: [
            _vm.$style.filter_wrap,
            _vm.isOpenFilter ? _vm.$style.show : "",
            _vm.currentFilter.exportMode ? _vm.$style.export : "",
          ],
          style: Object.assign({}, _vm.style, { right: _vm.right }),
          attrs: { id: _vm.componentId },
        },
        [
          _c("saved-filters-popup", {
            key: _vm.redrawSevedFiltersPopup,
            attrs: {
              "is-show": _vm.isShowSavedFilters && _vm.isOpenFilter,
              list: _vm.currentFilter.getSavedFilters,
              "active-item-id": _vm.currentFilter.id,
            },
            on: {
              close: function ($event) {
                _vm.isShowSavedFilters = false
              },
              delete: _vm.deleteSavedFilter,
              edit: _vm.editSavedFilter,
              select: _vm.selectSavedFilter,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: [_vm.$style.filter] },
            [
              _vm.showOverlay
                ? _c("div", { class: [_vm.$style.filter_overlay] })
                : _vm._e(),
              _vm._v(" "),
              _c("filter-header", {
                attrs: {
                  "show-current": _vm.currentFilter.showSavedFilters,
                  "show-cleare-btn": _vm.isActiveFilter,
                  "show-helper": _vm.currentFilter.showHelpIcon,
                  name: _vm.filterName,
                },
                on: {
                  toggleSavedPopup: function ($event) {
                    _vm.isShowSavedFilters = !_vm.isShowSavedFilters
                  },
                  reset: _vm.resetFilterState,
                  close: _vm.closeFilter,
                },
              }),
              _vm._v(" "),
              _c("filter-body", {
                key:
                  "filter-" +
                  _vm.currentFilter.type +
                  _vm.currentFilter.id +
                  "-" +
                  _vm.filterKey,
                attrs: {
                  fields: _vm.currentFilter.config(),
                  values: _vm.currentFilter.filterState,
                  custom: _vm.currentFilter.custom.columns,
                },
                on: { change: _vm.changeFilterState },
              }),
              _vm._v(" "),
              _vm.currentFilter.showSaveBtn
                ? _c("filter-footer", {
                    attrs: { "save-handler": _vm.saveFilter },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }