var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$.chart_wrapper }, [
    _c("div", {
      ref: "chart",
      class: [_vm.$.chart_body, _vm.flexGrow && _vm.$.flex_grow],
    }),
    _vm._v(" "),
    !_vm.dataExists
      ? _c("div", { class: _vm.$.no_data_message }, [
          _vm._v(
            _vm._s(_vm.locale("chart_portfolio_milestones_date_no_items"))
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: [_vm.$.chart_legend, _vm.small && _vm.$.no_padding] },
      _vm._l(_vm.legend, function (item, i) {
        return _c(
          "div",
          {
            key: item.label,
            class: [_vm.$.legend_item, !item.shouldFilter && _vm.$.disabled],
            on: {
              click: function ($event) {
                return _vm.toggleLegendItem(i)
              },
            },
          },
          [
            _c("div", {
              class: _vm.$.color_box,
              style: { backgroundColor: item.color },
            }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(item.label))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }