<template>
  <div class="export-paper-with-svg">
    <div class="export-paper-with-svg-container" v-if="options[exportType]">
      <div class="export-paper-with-svg-icon" v-html="options[exportType].icon"></div>
      <div v-bind:class="[options[exportType].css ? options[exportType].css : '']"
           class="export-paper-with-svg-text">
        {{ options[exportType].text }}
      </div>
    </div>
  </div>
</template>

<script>
  import export_xml_icon from './../../svg/export/export_xml.svg';
  import export_xlsx_icon from './../../svg/export/export_xlsx.svg';

  const __ = window.__;

  export default {
    name: "PaperWithSvg",
    props: ['exportType'],
    data() {
      return {
        options: {
          xml: {
            icon: export_xml_icon,
            text: __('export_xml_text'),
            css: "xml"
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>