<template>
  <div
    ref="statusSelect"
    :key="componentKey"
    :class="['status-select', archived? 'archived' : '',]"
  >
    <tooltip
      :class="'tooltip-wrapper'"
      :content="locale('project_status_select_tooltip')"
      :hide="(isOpen || !hasRightToChangeStatus || archived)"
    >
      <template #body>
        <div
          :class="['select__view', isOpen && hasRightToChangeStatus && (statusData.options.length > 1 || hasRightToStatusSettings) ? 'open' : '',
                   hasRightToChangeStatus && !archived ? 'cursor_pointer' : '']"
          :data-key="!hasRightToStatusSettings && statusData.options.length === 1 ? 'project_status_select_no_access_to_create_tooltip' : 'project_status_select_tooltip'"
          :style="{backgroundColor: statusData.selectOption.background}"
          @click.prevent="toggleSelect($event)"
        >
          <span
            :style="{ color: statusData.selectOption.labelColor}"
            :class="['selected-value']"
          >{{ localeArray.includes(statusData.selectOption.value) ? locale(statusData.selectOption.value) : statusData.selectOption.value }}</span>
          <svg-sprite
            v-if="((hasRightToChangeStatus && statusData.options.length > 1)
              || (hasRightToStatusSettings && hasRightToChangeStatus && statusData.options.length <= 1)) && !archived"
            :class="['select-arrow', 'select-icon']"
            :name="'arrow-down'"
            :type="'regular'"
            :size="20"
          />
        </div>
      </template>
    </tooltip>
  </div>
</template>

<script>
import routerHelper from '../../../../../helpers/router';
import app from '../../../../../app';
import globalStore from '../../../../../store/main';
import tooltip from '../../tooltips/tooltip.vue';
import projectsModel from '../../../../../models/projects';

export default {
  name: 'StatusSelect',
  components: { tooltip },
  props: {
    projectConfig: {
      type: Object,
      required: true,
    },
    statusData: {
      type: Object,
      required: true,
    },
    blockSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasRightToChangeStatus: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasRightToStatusSettings: {
      type: Boolean,
      required: false,
      default: true,
    },
    openStatus: {
      type: Boolean,
      required: false,
      default: true,
    },

  },
  emits: {
    input: 'string',
  },
  data() {
    return {
      isOpen: false,
      defaultColor: 'fff',
      locale: __,
      localeArray: ['no_status_locale', 'on_track_locale', 'at_risk_locale', 'off_track_locale'],
      isFirefox: false,
    };
  },

  computed: {
    colors() {
      return globalStore.getters['teamSetting/colors'].data;
    },
    archived() {
      return this.projectConfig.ui?.includes('unArchive') || this.projectConfig.is_archived;
    },
    componentKey() {
      const min = 1000;
      const max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  watch: {
    openStatus: {
      handler(val) {
        this.isOpen = val;
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener('click', this.close);
    this.isFirefox = typeof InstallTrigger !== 'undefined';
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  beforeCreate() {
    const component = this;

    app.on('onAfterCollaboration', data => {
      if (data.event === 'ResourceRoleUpdated'
        || data.event === 'ResourceOnProjectsUpdated'
        || (data.event === 'ResourceUpdated' && data?.isUpdatedRole && user.id === data.userId)
      ) {
        component.init();
      }

      if (data.event === 'RoleProjectUpdated') {
        if (this.isOpen) {
          const activeProjectId = projectsModel.getActiveGanttId();
          const updated = data.data.updated;
          const resource = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);
          const currentProject = resource.resourceProjects.find(pr => pr.projectId === activeProjectId);

          if (currentProject) {
            const isProjectRole = updated.find(obj => obj.id === currentProject.projectRoleId);

            if (isProjectRole) {
              this.isOpen = false;
              this.$emit('open', false);
            }
          }
        }
      }
    });

    app.on('project:archive', ganttId => {
      const activeProjectId = projectsModel.getActiveGanttId();

      if (+activeProjectId === +ganttId) {
        this.isOpen = false;
        this.$emit('open', false);
      }
    });
  },
  methods: {
    init() {
      this.isOpen = false;
    },
    getColorBack(id) {
      const itemColor = this.findColorItem(id);

      return itemColor ? itemColor.hex3 : this.defaultColor;
    },
    colorLabel(id) {
      const itemColor = this.findColorItem(id);

      return itemColor ? itemColor.hex4 : this.defaultColor;
    },
    findColorItem(id) {
      return this.colors?.find(color => color.id === id);
    },
    toggleSelect(e) {
      if (!this.hasRightToChangeStatus || this.archived || this.blockSelect) return false;

      this.isOpen = !this.isOpen;
      this.$emit('open', this.isOpen);
      this.$emit('currentProjectForStatus', this.projectConfig);
      this.$emit('positionSelect', this.$refs.statusSelect?.getBoundingClientRect());
    },

    close(e) {
      if (!this.isOpen) return;
      if (e.target.closest('.status-dropdown') && !e.target.closest('.select__options-item')) {
        this.isOpen = true;
      } else if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    },
    redirectToStatuses() {
      this.$emit('closePopup');
      routerHelper.changeRoute('/settings/statuses');
    },

  },
};
</script>

<style scoped src="../less/select.less"></style>
