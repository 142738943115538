import actions from './actions';
import mutations from './mutations';
import getters from './getters';


const UserStore = {
  namespaced: true,
  state: {
    activities: [],
    isActivitiesLoaded: false,
  },
  getters,
  mutations,
  actions
};

export default UserStore;