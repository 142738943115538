<template>
  <div
    class="add-columns-popup"
    :id="'gp_autotest_listview_add_columns_popup'"
    :style="{'height': height + 'px' }"
  >
    <div class="add-columns-popup--header">
      <span>{{ header_locale }}</span>
      <div class="d-flex">
        <webix-ui
          class="icon_helper icon_helper_hover"
          :id="'gp_autotest_listview_add_columns_popup_btn_helper'"
          :config="helper.columns"
        />
        <webix-ui
          class="close-icon cursor-pointer"
          :id="'gp_autotest_listview_add_columns_popup_btn_close'"
          :config="closeIcon"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div
      :key="items.length"
      class="gantt_scroll"
      :style="{'height': +height-44 + 'px', 'padding-top':'10px' }"
    >
      <div
        v-for="groupname in groupSet"
        :key="componentKey + groupname"
      >
        <div
          v-if="groupname"
          class="group"
        >
          <span class="group-name"> {{ groupname }} </span>
          <div class="group-line" />
        </div>

        <div
          v-for="(item, index) in getGroupItems(groupname)"
          :key="'add-column-' + index + groupname"
          class="add-columns-popup--row align-center d-flex"
          :id="'gp_autotest_listview_add_columns_popup_item_'+item.id"
        >
          <div class="align-center d-flex fix-width">
            <div
              class="tooltip-gantt add-columns--text "
              data-position="left"
              :data-key="item.tooltip"
              data-y="10"
            >
              {{ item.text }}
            </div>
            <div class="add-columns-popup--checkbox">
              <customCheckBox
                :id="'gp_autotest_listview_add_columns_popup_item_checkbox_'+item.id"
                :v-model="chacked"
                :value="item"
                @change="changeSelected"
              />
            </div>
          </div>

          <div
            v-if="item.extra"
            class="add-columns-popup--extra"
          >
            <template v-if="item.extra.name">
              <div class="add-columns--text">
                {{ item.extra.text }}
              </div>
              <div class="add-columns-popup--checkbox">
                <customCheckBox
                  :v-model="extra"
                  :value="item.extra.name"
                  :block="isBlockExtraBox(item.extra.name)"
                  @change="changeExtra"
                />
              </div>
            </template>
            <webix-ui
              v-if="item.extra.helper"
              class="icon_helper row-icon-extra"
              :config="helper.cost"
            />
          </div>
          <div
            v-if="item.custom && item.tooltipExtra && showCustomColumnInfo"
            class="d-flex  align-center flex-grow-1 columns_projects_info"
          >
            <div
              class="gantt_notification_tooltip tooltip-gantt row-icon-extra"
              data-html="true"
              data-padding="8px 12px"
              data-maxwidth="320"
              data-position="left"
              data-y="8"
              data-x="15"
              :data-id="item.id+'-col-add'"
            >
              <sprite
                :name="'info'"
              />
              <div class="tooltip-gantt-html">
                <div class="tooltip-gantt-html-description global_gantt_columns_tooltip ql-editor">
                  <div class="tooltip-header">
                    <span>{{ item.tooltipExtra.header }} </span>
                  </div>
                  <div class="tooltip-divider" />
                  <ul
                    class="tooltip-list"
                    v-html="item.tooltipExtra.descriptionHtml"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_close from '../../../svg/ic_close.svg';
import svgIconForVue from '../../common/svgIconForVue';
import customCheckBox from './customCheckBox.vue';
import helpIconForVue from '../../common/helpIconForVue';
import sprite from './icons/sprite.vue';

export default {
  name: 'AddColumnPopup',
  components: { customCheckBox, sprite },
  props: {
    items: { type: Array, required: true },
    selected: { type: Array, required: true, default() { return []; } },
    groupBy: { type: String, required: false, default: 'group' },
    height: { type: Number, required: true },
    extraInfo: { type: Array, required: false, default() { return []; } },
    showCustomColumnInfo: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      closeIcon: svgIconForVue(icon_close, 24, 24),
      header_locale: __('gantt_grid_custom_grid_header'),
      chacked: [],
      extra: [],
      helper: {
        columns: helpIconForVue('customGridHelpBlockView_g', 'customGridHelpBlock', 24, 24),
        cost: helpIconForVue('taskCostHelpBlockView_g', 'taskCostHelpBlock', 24, 24),
      },
      locale: {
        more: __('gantt_tooltip_show_more'),
      },
    };
  },
  computed: {
    groupSet() {
      const set = [];

      this.items.forEach(item => {
        const groupName = (this.groupBy in item) ? item[this.groupBy] : null;

        if (!set.includes(groupName)) {
          set.push(groupName);
        }
      });

      return set;
    },

    popupMustClose() {
      const openSettings = this.$store.getters['headerView/isShowSettingsPopup'];
      const openSlask = this.$store.getters['headerView/isShowSlackPopup'];
      const openMSTeams = this.$store.getters['headerView/isShowMSTeamsPopup'];
      const openMassChange = this.$store.getters['headerView/isBulkChange'];

      return openSettings || openSlask || openMSTeams || openMassChange;
    },

    isFilterOpen() {
      return this.$store.getters['filter/isOpen'];
    },
  },

  watch: {
    selected(val) {
      this.chacked = val;
    },
    extraInfo(val) {
      this.extra = val;
    },
    popupMustClose(val) {
      val && this.$emit('close');
    },
    isFilterOpen(val) {
      val && this.$emit('close');
    },
    $route(to, from) {
      this.$emit('close');
    },
  },

  mounted() {
    this.extra = this.extraInfo;
    this.chacked = this.selected;
  },

  methods: {
    changeSelected(val) {
      this.chacked = val;
      this.$emit('update:selected', [...this.chacked]);
    },
    changeExtra(val) {
      this.extra = val;
      this.$emit('update:extra', [...this.extra]);
    },
    getGroupItems(groupName) {
      if (groupName === null) {
        return this.items.filter(item => !(this.groupBy in item));
      }

      return this.items.filter(item => item[this.groupBy] === groupName);
    },
    isBlockExtraBox(extra_name) {
      const property = extra_name.replace('_extra', '');

      return !this.chacked.find(item => item.property === property);
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../less/_fonts.less';
  .add-columns-popup{
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 380px;
    height: 100%;
    border: 1px solid #D9D9D9;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 10%);
    z-index: 10;
    font-family: inherit;
    font-size: 14px;
    color: #424242;
    border-top: 1px solid transparent;
    .gantt_scroll{
      overflow-y: auto;
    }
    &--header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 16px;
      border-bottom: 1px solid #BDBDBD;
      span{
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #212121;
        .FontLatoBold();
      }
    }
    &--extra{
      display: flex;
      align-items: center;
      padding-left: 22px;
      flex-grow: 1;
    }
    &--row{
      display: flex;
      align-items: center;
      padding-left: 16px;
      height: 31px;
    }
    .add-columns--text{
      .FontLatoRegular();
      padding-right: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      flex-grow: 1;
    }
    .fix-width{
      width: 228px;
      cursor: pointer;
    }

    &--checkbox{
        display: flex;
        align-items: center;
        input[type=checkbox]{
            position: absolute;
            z-index: -1;
            opacity: 0;
            cursor: pointer;

            &:checked + label{
                border-color: #247ECC;
                background: #247ECC url(https://cdn.ganttpro.com/app/imgs/webix_material/check_white.svg) center no-repeat;
                background-size: 12px;
            }
        }
        label{
            display: inline-block;
            width: 17px;
            height: 17px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #9e9e9e;
            border-radius: 2px;
            box-sizing: border-box;
            cursor: pointer;
        }
    }
    .row-icon-extra{
      margin-left: auto;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    .oferflow{
      overflow: auto;
    }
    .group{
      padding: 12px 16px;
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      color: #9E9E9E;
      cursor: default;
      pointer-events: none;

      span{
        white-space: nowrap;
        margin-right: 20px;
      }

      &-line{
        flex-grow: 5;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #cfcfcf;
        margin-bottom: 9px;
      }
    }
    .icon_helper_hover{
      width: 30px;
      padding-top: 1px;
      padding-left: 2px;
      &:hover{
        background-color: #f3f3f3;
      }
    }
  }
  .icon-info{
    width: 24px;
    height: 24px;
    opacity: 0.75;
    transition: opasity 0.3s, backgroundColor 0.3s;

    &:hover{
      background-color: #f3f3f3;
      opacity: 1;
    }
  }
  .close-icon{
    transition: 0.3s;
    cursor: pointer;
    padding: 0 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;

    svg{
      fill: #808080;
      cursor: pointer;
    }
    &:hover {
      background-color: #f3f3f3;
      svg{
        fill: #333333;
      }
    }
  }

</style>
