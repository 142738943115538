import _ from "./../libs/lodash";
import constants from "./constants";

const __ = window.__;

const template = function (resources) {
  let html = `<div class="resource_list_in_grid">`;
  const count = resources.length;

  if (count) {
    let more = 0;

    if (count === 1) {
      html += `${_getTemplateForResourceIcon(resources[0])}
          <span title="${resources[0].name}" class="resource_list_item">${resources[0].name}</span>`;
    } else {
      _.each(resources, (resource, i) => {
        if (count < 4) {
          html += _getTemplateForResourceIcon(resource);
        } else {
          if (i < 2) {
            html += _getTemplateForResourceIcon(resource);
          } else if (!more) {
            more = count - 2;
            html += `<span class="resource_list_item_more">+ ${more}</span>`;
          }
        }
      });
    }

    html += `</div>`;

    return html;
  } else {
    return `<div class="resource_list_in_grid unassigned">
        <div class="resource_list_item_icon"></div>
        <div title="${__(constants.COMMON_EMPTY_RESOURCE)}" class="resource_list_item">${__(constants.COMMON_EMPTY_RESOURCE)}</div>
        </div>`;
  }
};

const _getTemplateForResourceIcon = function (resource) {
  return `<span class="resource_list_item_icon" style="background-image: url(
      ${resource.photo || resource.resourcePhoto || (GT.cdn + '/imgs/default/default-ava.svg')})"></span>`;
}

export default template;
