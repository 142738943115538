var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-billing" },
    [
      _c("div", { ref: "settings_section", staticClass: "settings-section" }, [
        _c("div", { staticClass: "main-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("settings_billing_title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row-info current-plan" }, [
          _c("div", { staticClass: "info-label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.locales("billing_current_plan_label")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description name" }, [
            _c("p", { staticClass: "details details" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.currentPlanName) + "\n        "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row-info plan" }, [
          _c("div", { staticClass: "info-label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.locales("billing_upgrade_label")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "description" },
            [
              _c("p", {
                staticClass: "details details-text",
                domProps: { innerHTML: _vm._s(_vm.planLabel) },
              }),
              _vm._v(" "),
              !_vm.isOverSubscription
                ? _c("p", { staticClass: "details details-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.planPeriod.dateTo) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("global-button", {
                attrs: {
                  config: {
                    isGlobal: true,
                    locale: _vm.locales(
                      "" +
                        (_vm.isTrial || _vm.isOverSubscription
                          ? "upgrade_plan_btn"
                          : "billing_upgrade_btn_text")
                    ),
                    handler: _vm.upgradePlanClick,
                    size: "small",
                    color: "light-orange",
                    isDisable: false,
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        !_vm.isTrial
          ? _c("div", { staticClass: "row-info editors" }, [
              _c("div", { staticClass: "info-label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("billing_editors_label")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("p", { staticClass: "details details-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("billing_editors_text")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "details details-bold" }, [
                  _vm._v("\n          " + _vm._s(_vm.userCount) + "\n        "),
                ]),
                _vm._v(" "),
                _vm.isSubscriptionActive
                  ? _c(
                      "div",
                      {
                        class: ["string-btn", ""],
                        on: { click: _vm.onChangeSize },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.locales("billing_change_team_size_btn")
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isTrial
          ? _c("div", { staticClass: "row-info payment-method" }, [
              _c("div", { staticClass: "info-label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("billing_payment_method_label")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("p", { staticClass: "details details-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("billing_payment_method_text")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: ["string-btn", ""],
                    on: { click: _vm.changePaymentMethod },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("billing_payment_method_btn")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.paymentHistory.length ? _c("delimiter") : _vm._e(),
      _vm._v(" "),
      _vm.paymentHistory.length
        ? _c("div", { staticClass: "settings-section invoices" }, [
            _c("div", { staticClass: "main-title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("settings_billing_invoices_title")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: ["invoices-table", "show-all"] },
              [
                _c("div", { staticClass: "table-row table-row_head" }, [
                  _c("div", { staticClass: "table-cell table-cell_date" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("date_key")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell table-cell_desc" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("desc_key")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell table-cell_editors" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("editors_key")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell table-cell_total" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("total_key")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-cell table-cell_status" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("status_key")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.paymentHistory, function (item, index) {
                  return _c("div", { key: index, staticClass: "table-row" }, [
                    _c("div", { staticClass: "table-cell table-cell_date" }, [
                      _vm._v("\n          " + _vm._s(item.date) + "\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "table-cell table-cell_desc",
                        attrs: { title: item.name },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-cell table-cell_editors" },
                      [
                        item.users
                          ? _c("span", { staticClass: "editors-count" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.users) +
                                  "\n          "
                              ),
                            ])
                          : _c("div", { staticClass: "editors-no-data" }, [
                              _c("span", { staticClass: "line" }),
                            ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-cell table-cell_total" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s("$ " + item.order_total) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: ["table-cell table-cell_status"],
                        style: { color: _vm.getStatusColor(item.action) },
                        attrs: { title: item.actionName },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.actionName) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    item.invoiceLink
                      ? _c(
                          "div",
                          { staticClass: "table-cell table-cell_invoice-link" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: item.invoiceLink,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.locales("view_invoice_link")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isSubscriptionActive
        ? _c("div", { ref: "billing_footer", staticClass: "billing-footer" }, [
            _c(
              "div",
              {
                staticClass: "footer-btn footer-btn--cancel",
                on: { click: _vm.unsubscribeClick },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.locales("cancel_btn")) + "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }