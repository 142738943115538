<template>
  <div :class="$style['learning-center-news-tab-wrapper']">
    <div
      v-if="newsItems.length"
      :class="$style['news-list']"
      class="gantt_scroll"
    >
      <div
        v-for="newsItem in newsItems"
        :class="$style['news-item']"
      >
        <div :class="$style['content__wrap']">
          <div :class="$style.content">
            <div :class="$style['content__date']">
              {{ formatDate(newsItem.date) }}
            </div>
            <h2 :class="$style['content__title']">
              {{ newsItem.header }}
            </h2>
            <p
              :class="$style['content__description']"
              v-html="newsItem.content"
            />
          </div>
          <div
            :class="[$style['image-wrap'], newsItem.videoData ? $style['image-wrap_play-icon'] : '']"
            v-bind="newsItem.videoData"
            @click="handleImageClick(newsItem, $event)"
          >
            <img
              v-if="newsItem.imageSrc"
              :src="newsItem.imageSrc"
              @click.stop
              alt="News image"
            >
          </div>
        </div>
        <div :class="$style['content__delimiter']" />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '$$store/api';
import moment from 'moment';
import constants from '../../../helpers/constants';
import videoLayout from '../../videoLayout/main';

export default {
  name: 'LearningCenterNewsTab',
  data() {
    return {
      newsItems: [],
    };
  },
  created() {
    api.get('/newshistory', {
      params: {
        start: 0,
        count: 1000,
      },
    })
      .then(({ data }) => {
        const { data: newsItems } = data;
        const sortedItems = newsItems.sort((a, b) => b.idTemplate - a.idTemplate);

        this.newsItems = sortedItems.map(this.extractAndSetImageUrl);
      })
      .catch(console.error);
  },
  mounted() {
    this.$store.dispatch(
      'user/updateActivityStatus',
      { activityName: constants.USER_ACTIVITIES.LAST_NEWS_ID, status: window.user.lastNewsEntryId },
    );
  },
  methods: {
    formatDate(date) {
      return moment(date).locale(user.locale).format('D MMM YYYY');
    },
    extractAndSetImageUrl(newsItem) {
      const imageSrcMatch = newsItem.content.match(/<news-image>.+<\/news-image>/);

      if (imageSrcMatch?.length) {
        const [imageSrcString] = imageSrcMatch;

        newsItem.content = newsItem.content.replace(imageSrcString, '');
        newsItem.imageSrc = imageSrcString
          .replace('<news-image>', '')
          .replace('</news-image>', '');
      }

      const newsVideoTagMatch = newsItem.content.match(/<news-video.+\/>/);

      if (newsVideoTagMatch?.length) {
        const [newsVideoTagString] = newsVideoTagMatch;

        newsItem.content = newsItem.content.replace(newsVideoTagString, '');

        const requiredDataAttributes = ['data-video-src', 'data-video-id', 'data-video-class'];
        const videoDataAttributes = requiredDataAttributes.reduce((dataAttributesResult, dataAttributeName) => {
          const regExpToFindDataAttributeValue = new RegExp(`${dataAttributeName}="(.*?)"`, 'i');
          const findResult = regExpToFindDataAttributeValue.exec(newsVideoTagString);

          if (findResult?.length) {
            const [_, dataAttributeValue] = findResult;

            return { ...dataAttributesResult, ...{ [dataAttributeName]: dataAttributeValue } };
          }

          return dataAttributesResult;
        }, {});

        const isEveryRequiredDataAttributesParsed = Object.values(videoDataAttributes).filter(Boolean).length === requiredDataAttributes.length;

        if (!isEveryRequiredDataAttributesParsed) {
          console.warn('Missing required video data attribute');

          return newsItem;
        }

        newsItem.videoData = videoDataAttributes;
      }

      return newsItem;
    },
    handleImageClick(item, event) {
      if (!item.videoData) {
        return;
      }
      videoLayout.init(event);
    },
  },
};
</script>

<style module src="../less/learningCenterNewsTab.less" lang="less">
</style>
