// views/comments/commentsList.js

import app, { appIds } from '../../app';

import commentsModel from '../../models/comments2';
import projectsModel from '../../models/projects';
import teamModel from '../../models/team';

import routerHelper from '../../helpers/router';
import customHelper from '../../helpers/custom';

import icon_jira from '../../svg/leftSideBar/jira_file_ico.svg';
import icon_my_project from '../../svg/leftSideBar/gantt_file_ico.svg';
import icon_trash from '../../svg/default/trash.svg';

import './addCommentProto';
import globalStore from '../../store/main';
import rightsComponent from '../../components/rights';
import rights from '../../components/rights';
import constants from '$$helpers/constants';

const __ = window.__;
const _ = window._;

const {
  events: {
    ID_EVENT_COMMENTS_ADD_COMMENT, ID_EVENT_COMMENTS_REMOVE_COMMENT,
    ID_EVENT_COMMENTS_MARK_AS_READ, ID_EVENT_COMMENTS_DELETE_COMMENTS,
    ID_EVENT_COMMENTS_ADD_COMMENTS, ID_EVENT_COLLABORATION_UPDATEDMODELS,
    ID_EVENT_COMMENTS_INIT_COMPLETE, ID_EVENT_COMMENTS_EDIT_COMMENT,
  },
  popupViews: {
    ID_VIEW_POPUP_COMMENTS,
  },
} = appIds;

// identifiers:
const ID_VIEW_COMMENTS_LIST_EMPTY_AREA = 'commentsTemplateIfNoComments';
const ID_VIEW_COMMENTS_LIST_COMMENTS_AREA = 'scrollViewWithCommentsList';
const ID_VIEW_COMMENTS_PROJ_LIST = 'workProjectsList';
const ID_VIEW_COMMENTS_ACCORDION = 'accordionCommentsLayout';

// the global state
const _state = {
  commentsById: {},
  projectsById: {},
  tasksById: {},
};

let _teamMembers = [];
let _innerViews = null;
let _helpers = null;
let _idHelper = null;

// wtf???
let _stopBackRouting = false;
let _activeProjId = null;
let _init = false;
let _updatedCurrentUserAvatar = null;

_helpers = {
  //
  replaceEmailToID(comment) {
    const replaceUserEmailPattern = /\[\~([^\]]*)\]/gim;

    return comment.replace(replaceUserEmailPattern, (needle, email) => {
      const resource = globalStore.getters['resourcesModel/getResourceByEmail'](email);

      app.trigger('notify:comment:mention', resource.userId);

      return `[~${resource.userId}]`;
    });
  },
  // if comment with a new task was added
  initStateWithComment: srcCommentEntry => {
    // const srcCommentEntry = __allComments[i];
    const {
      task_id, gantt_id, task_name, name, date, text,
      comment, user_id, lastName, firstName, username, read, photo
    } = srcCommentEntry;

    const __projData = projectsModel.getProjectDataById(srcCommentEntry.gantt_id);

    // if a comment was added to a newly created project (projects model doesn't sync now)
    if (!__projData) {
      return;
    }

    const isOwnComment = user_id === user.id;
    const userCommentPhoto = isOwnComment && _updatedCurrentUserAvatar ? _updatedCurrentUserAvatar : photo;
    _state.commentsById[srcCommentEntry.id] = {
      ...srcCommentEntry,
      photo: userCommentPhoto,
      isOwn: isOwnComment,
      date_fmt: webix.i18n.fullDateFormatStr(new Date(date)),
      comment_fmt: _helpers.replaceUsername(comment.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />')),
      username_fmt: (lastName || firstName) ? `${firstName} ${lastName}` : username,
    };

    if (!_state.tasksById[task_id]) {
      _state.tasksById[task_id] = {
        commentIds: [],
        id: task_id,
        name: text || task_name,
        task_name: text || task_name, // for compatibility
        gantt_id,
      };
    }

    if (_state.tasksById[task_id].commentIds.indexOf(srcCommentEntry.id) === -1) {
      _state.tasksById[task_id].commentIds.push(srcCommentEntry.id);
    }

    if (!_state.projectsById[gantt_id]) {
      _state.projectsById[gantt_id] = {
        taskIds: [],
        id: gantt_id,
        name: name || __projData.name,
        unreadCount: 0,
        isJira: _helpers.checkIfJiraProject(gantt_id),
      };
    }

    if (_state.projectsById[gantt_id].taskIds.indexOf(task_id) === -1) {
      const accessToAllTasks = rightsComponent.project.hasRight(gantt_id, 'all_tasks');
      const tasksToCurrentResource = globalStore.getters['tasksModel/getResourcesForTask'](gantt_id, task_id);

      if (!accessToAllTasks) {
        const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        tasksToCurrentResource.forEach(resource => {
          if (resource.resource_id === resourceId) {
            _state.projectsById[gantt_id].taskIds.push(task_id);
          }
        });

        return;
      }

      _state.projectsById[gantt_id].taskIds.push(task_id);
    }

    if (read === 0) {
      _state.projectsById[gantt_id].unreadCount++;
    }
  },
  // composes an accordion item for selected task using the shared common template
  composeAccordionTaskItem: idTask => {
    const { tasksById, commentsById } = _state;
    const { task_name, commentIds, gantt_id } = tasksById[idTask];

    const __tplInstance = _.cloneDeep(_innerViews.accordionItemTemplateCfg(idTask, gantt_id));

    __tplInstance.header = _templates.getHeaderTemplateForAccordion({
      captionValue: __('comment_list_collapse_task_comments'),
      taskName: task_name,
      commentsCount: commentIds.length,
      unreadCount: commentIds.filter(id => commentsById[id].read === 0).length,
    });// !IMPLEMENT

    __tplInstance.headerAlt = _templates.getHeaderTemplateForAccordion({
      captionValue: __('comment_list_expand_task_comments'),
      taskName: task_name,
      commentsCount: commentIds.length,
      unreadCount: commentIds.filter(id => commentsById[id].read === 0).length,
    }); // !IMPLEMENT

    __tplInstance.id = _idHelper.getAccordionItemViewId(idTask);
    __tplInstance.idTask = idTask; // ?WTF

    const __$$commentsList = __tplInstance.body.rows[0];
    const __$$addComent = __tplInstance.body.rows[2];

    __$$commentsList.data.comments = commentIds
      .map(id => commentsById[id])
      .sort((e1, e2) => new Date(e1.date) - new Date(e2.date));
    __$$commentsList.id = _idHelper.getBodyCommentsListId(idTask);

    __$$addComent.teamMembers = _helpers.getResoursesCanCreateComments(idTask, gantt_id);
    __$$addComent.id = `${idTask}___`; // !FIXME
    __$$addComent.idtask = idTask;
    __$$addComent.gantt_id = gantt_id;

    return __tplInstance;
  },

  getResoursesCanCreateComments(taskId, ganttId) {
    const projectData = globalStore.getters['tasksModel/getItem'](+ganttId);
    const resourcesOnTask = projectData.resourcesToTasks[taskId] || [];

    const showMembers = _teamMembers.filter(member => {
      const memberHasRight = rights.project.userHasRight(member.id, ganttId, 'all_tasks');

      if (memberHasRight || resourcesOnTask.find(i => i.resource_id === member.id)) {
        return true;
      }
    });

    return showMembers;
  },
  hasAccess: gantt_id => rightsComponent.project.hasRight(gantt_id, 'comments'),
  // !DESCR
  composeAccordionItemBody: ({ comments }) => comments.reduce((result, entry) => {
    if (entry.is_removed) {
      return result + _templates.accordionBodyRemComment({
        photo: entry.photo,
        comment_id: entry.id,
        isOwn: entry.isOwn,
      });
    }

    return result + _templates.accordionBodyExistComment({
      isOwn: entry.isOwn,
      photo: entry.is_removed_user ? constants.COMMON_USER_PHOTO : entry.photo,
      comment_fmt: entry.comment_fmt,
      date_fmt: entry.date_fmt,
      userName: entry.is_removed_user ? `${entry.username_fmt} (${__('task_settings_author_deleted_label')})` : entry.username_fmt, // !TODO: implement
      date_edited: entry.date_edited,
    });
  }, ''),
  // {Number} -> {Boolean}
  checkIfJiraProject: idProject => {
    const __projData = projectsModel.getProjectDataById(idProject);

    return __projData ? __projData.is_jira : false;
  },
  // {String} -> {String}
  replaceUsername: text => {
    const __replaceUserEmailPattern = /\[\~([^\]]*)\]/gim;

    return text.replace(__replaceUserEmailPattern, (needle, id) => {
      const member = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);

      if (member) {
        const __name = member.firstName && member.lastName ? (`${member.firstName} ${member.lastName}`) : member.name;

        return `<a class="task-comments-list-item-block-body-text-username" data-id="${id}">${__name.trim()}</a>`;
      }

      return `(${__('user_deleted')})`;
    });
  },
  /**
     * Returns an identifier of the selected in list project.
     * If no project is selected returns -1
     *
     * @return {Number} - gannt_id of the selected project.
     */
  getSelecterProjId: () => {
    // ! "getSelectedId()" returns <String>
    const $$__projListView = $$(ID_VIEW_COMMENTS_PROJ_LIST);

    if (!$$__projListView) {
      return -1;
    }

    const __isSelListItem = $$__projListView.getSelectedId();
    const __isSelProj = parseInt(__isSelListItem, 10);

    return Number.isInteger(__isSelProj) ? __isSelProj : -1;
  },
  /**
     * Updates counters: "totalCount"/"unreadCount" for the given accordion item
     *
     * @param {Object} $$accItem - accordion item view
     * @param {String} taskName - the name of the task
     * @param {Number} commentsCount - total task comments
     * @param {Number} unreadCount - unread task comments
     */
  updateAccordionHeaderData: ($$accItem, taskName, commentsCount, unreadCount) => {
    const __hdrTemplate = _templates.getHeaderTemplateForAccordion({
      captionValue: __('comment_list_collapse_task_comments'),
      taskName,
      commentsCount,
      unreadCount,
    });

    const __hdrTemplateAlt = _templates.getHeaderTemplateForAccordion({
      captionValue: __('comment_list_expand_task_comments'),
      taskName,
      commentsCount,
      unreadCount,
    });

    $$accItem.define('header', __hdrTemplate);
    $$accItem.define('headerAlt', __hdrTemplateAlt);
    $$accItem.refresh();
  },
};

_idHelper = {
  // {Number} -> {String}
  getBodyCommentsListId: idTask => `${idTask}__`,
  // {Number} -> {String}
  getAccordionItemViewId: idTask => `${idTask}_`,
};

const _handlers = {
  /**
     * Reset project selection if the rights of the task
     * for the selected project were updated (assigned/unassigned).
     *
     * @param {Object} payload - collaboration action data-object
     */
  onCollaborationUpdateModels: (payload = {}) => {
    const { actionType, data } = payload;
    const UPDATE = 61;

    if (actionType === UPDATE) {
      const __idTask = parseInt(data.taskId, 10);
      const __taskEntry = _state.tasksById[__idTask];
      const __idSelectedProj = _helpers.getSelecterProjId();

      if (!__taskEntry || __taskEntry.gantt_id !== __idSelectedProj) {
        return;
      }

      if ($$(ID_VIEW_COMMENTS_ACCORDION)) {
        webix.ui([], $$(ID_VIEW_COMMENTS_ACCORDION));
      }

      if ($$(ID_VIEW_COMMENTS_PROJ_LIST)) {
        $$(ID_VIEW_COMMENTS_PROJ_LIST).unselectAll();
      }
    }
  },
  onInitComments: () => {
    $$(ID_VIEW_COMMENTS_PROJ_LIST) && _handlers.onPopupBeforeShow();
  },
  /**
     * @public model events handler
     */
  onAddComment: comment => {
    if (!$$(ID_VIEW_POPUP_COMMENTS) || !comment) {
      return;
    }

    const { gantt_id, task_id } = comment;

    // update the entire state
    _helpers.initStateWithComment(comment);

    const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
    const tasksToCurrentResource = globalStore.getters['tasksModel/getResourcesForTask'](comment.gantt_id, comment.task_id);

    if (!$$(ID_VIEW_COMMENTS_PROJ_LIST).getItem(gantt_id)) {
      const resourceId = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

      const tasksToCurrentResource = globalStore.getters['tasksModel/getResourcesForTask'](comment.gantt_id, comment.task_id);

      if (!rights.project.hasRight(gantt_id, 'all_tasks')) {
        tasksToCurrentResource.forEach(resource => {
          if (resource.resource_id === resourceId) {
            $$(ID_VIEW_COMMENTS_PROJ_LIST).add(_state.projectsById[gantt_id]);
          }
        });
      } else {
        $$(ID_VIEW_COMMENTS_PROJ_LIST).add(_state.projectsById[gantt_id]);
      }
    }

    // update a sidebar
    if (comment.read === 0) {
      $$(ID_VIEW_COMMENTS_PROJ_LIST).refresh(); // .updateItem(gantt_id);
    }
    // const __projects = Object.keys(_state.projectsById).map(id => _state.projectsById[id]); // !TODO: sort

    // $$(ID_VIEW_COMMENTS_PROJ_LIST).clearAll();
    // $$(ID_VIEW_COMMENTS_PROJ_LIST).parse(__projects);

    // if
    let __$$accordionTaskItem = $$(_idHelper.getAccordionItemViewId(task_id));
    let __$$bodyCommentsList = $$(_idHelper.getBodyCommentsListId(task_id));

    // We need to update an accordion if the received comment belongs to the selected project
    if (_helpers.getSelecterProjId() === gantt_id) {
      // we need to  create a new acordion item if it doesnt exist
      if (!__$$accordionTaskItem && !__$$bodyCommentsList) {
        if (!rights.project.hasRight(gantt_id, 'all_tasks')) {
          tasksToCurrentResource.forEach(resource => {
            if (resource.resource_id === resourceId) {
              const __$$itemView = _helpers.composeAccordionTaskItem(task_id);

              $$(ID_VIEW_COMMENTS_ACCORDION).addView(__$$itemView);

              __$$accordionTaskItem = $$(_idHelper.getAccordionItemViewId(task_id));
              __$$bodyCommentsList = $$(_idHelper.getBodyCommentsListId(task_id));
            }
          });
        } else {
          const __$$itemView = _helpers.composeAccordionTaskItem(task_id);

          $$(ID_VIEW_COMMENTS_ACCORDION).addView(__$$itemView);

          __$$accordionTaskItem = $$(_idHelper.getAccordionItemViewId(task_id));
          __$$bodyCommentsList = $$(_idHelper.getBodyCommentsListId(task_id));
        }
      }

      // const __taskEntry = _state.tasksById[task_id];
      const { commentIds, task_name } = _state.tasksById[task_id];
      const __comments = commentIds.map(idComment => _state.commentsById[idComment]);
      const __unread = __comments.filter(entry => entry.read === 0);

      // 1. update header information (total count and unread count)
      _helpers.updateAccordionHeaderData(
        __$$accordionTaskItem,
        task_name,
        __comments.length,
        __unread.length,
      );

      // 2. update commnts list
      __$$bodyCommentsList.setValues({ comments: __comments }, true); // refresh??

      // 3. mark as read if the appropriate task is expanded
      if (!__$$accordionTaskItem.config.collapsed && !comment.read) {
        commentsModel.markAsRead([comment.id]);
      }
    }
    userExtAnalytics.log('task_comment_added', { from: 'global' });
  },
  /**
     * @public model events handler
     */
  onRemoveComment: idComment => {
    if (!$$(ID_VIEW_POPUP_COMMENTS)) {
      return; // !FIXME
    }

    const __comment = _state.commentsById[idComment];
    const { gantt_id, task_id } = __comment;

    __comment.is_removed = 1;

    const __$$bodyCommentsList = $$(_idHelper.getBodyCommentsListId(task_id));

    // We need to update an accordion if the received comment belongs to the selected project
    if (
      _helpers.getSelecterProjId() === gantt_id
            && __$$bodyCommentsList
    ) {
      const __taskEntry = _state.tasksById[task_id];
      const __comments = __taskEntry.commentIds.map(id => _state.commentsById[id]);

      __$$bodyCommentsList.setValues({ comments: __comments }, true);
    }
  },
  onEditComment: (idComment, commentData) => {
    if (!$$(ID_VIEW_POPUP_COMMENTS)) {
      return; // !FIXME
    }

    const __comment = _state.commentsById[idComment];
    const { gantt_id, task_id } = __comment;

    __comment.comment = commentData.comment;
    __comment.comment_fmt = _helpers.replaceUsername(commentData.comment.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />'));
    __comment.date_edited = commentData.date_edited;

    const __$$bodyCommentsList = $$(_idHelper.getBodyCommentsListId(task_id));

    // We need to update an accordion if the received comment belongs to the selected project
    if (
      _helpers.getSelecterProjId() === gantt_id
      && __$$bodyCommentsList
    ) {
      const __taskEntry = _state.tasksById[task_id];
      const __comments = __taskEntry.commentIds.map(id => _state.commentsById[id]);

      __$$bodyCommentsList.setValues({ comments: __comments }, true);
    }
  },
  /**
     * @public model events handler
     */
  onMarkAsRead: commentIds => {
    if (
      !$$(ID_VIEW_POPUP_COMMENTS)
            || !Array.isArray(commentIds)
    ) {
      return; // !FIXME
    }

    // update the entire state
    commentIds.forEach(idComment => {
      const __commentEntry = _state.commentsById[idComment];
      const __projEntry = _state.projectsById[__commentEntry.gantt_id];

      __commentEntry.read = 1;
      __projEntry.unreadCount -= 1;
    });

    // update view
    $$(ID_VIEW_COMMENTS_PROJ_LIST).refresh();

    //
    const __idSelProj = _helpers.getSelecterProjId();
    const __selProjEntry = _state.projectsById[__idSelProj];

    if (!__selProjEntry) {
      return;
    }

    __selProjEntry.taskIds.forEach(idTask => {
      const __$$accordionTaskItem = $$(_idHelper.getAccordionItemViewId(idTask));

      const { task_name, commentIds } = _state.tasksById[idTask];
      const __comments = commentIds.map(idComment => _state.commentsById[idComment]);
      const __unread = __comments.filter(entry => entry.read === 0);

      // 1. update header information (total count and unread count)
      _helpers.updateAccordionHeaderData(
        __$$accordionTaskItem,
        task_name,
        __comments.length,
        __unread.length,
      );
    });
  },
  // open the "task settings" popup window by "display all comments" click
  onDisplayAllCommentsClick: (e, viewId /* , node */) => {
    const __idTask = $$(viewId).config.idTask;
    const __taskEntry = _state.tasksById[__idTask];

    if (!__taskEntry) {
      return false;
    }

    _stopBackRouting = true; // ?WTF
    $$(ID_VIEW_POPUP_COMMENTS).hide();
    _stopBackRouting = false; // ?WTF

    projectsModel
      .setActiveProject(__taskEntry.gantt_id)
      .then(() => {
        window.taskSettingsData = {
          taskData: __taskEntry,
          ganttId: __taskEntry.gantt_id,
          initOptions: { selectComment: true },
        };

        routerHelper.pushRoute({
          name: 'taskRoute',
          params: {
            taskId: __idTask,
            projectId: __taskEntry.gantt_id,
            mode: 'gantt',
          },
        });
      });

    userExtAnalytics.log('gantt_task_settings_open', {
      from: routerHelper.analyticsFromRoute(),
      route: 'Global comments',
    });

    return false;
  },
  //
  onAfterItemCollapse: () => {
    userExtAnalytics.log('general_comments_project_task_close');
  },
  //
  onAfterItemExpand: id => {
    const __$$accordionItem = $$(id);
    const { idTask } = __$$accordionItem.config;
    const { tasksById, commentsById } = _state;
    const __unread = tasksById[idTask].commentIds.filter(id => commentsById[id].read === 0);

    __unread.length && commentsModel.markAsRead(__unread);
    userExtAnalytics.log('general_comments_project_task_open');
  },
  //
  onProjectsListClick: id => {
    const __projEntry = _state.projectsById[id];
    const __accordionItems = __projEntry.taskIds.map(_helpers.composeAccordionTaskItem);

    $$(ID_VIEW_COMMENTS_ACCORDION).blockEvent();
    webix.ui(__accordionItems, $$(ID_VIEW_COMMENTS_ACCORDION));
    $$(ID_VIEW_COMMENTS_ACCORDION).unblockEvent();
  },
  //
  onPopupBeforeShow: () => {
    _state.commentsById = {};
    _state.projectsById = {};
    _state.tasksById = {};

    teamModel
      .getTeamList()
      .then(teamMembers => {
        _teamMembers = teamMembers;
      });

    const __allComments = commentsModel.getAllComments();

    __allComments.forEach(_helpers.initStateWithComment);

    const __projects = Object.keys(_state.projectsById).map(id => _state.projectsById[id]); // !TODO: sort

    $$(ID_VIEW_COMMENTS_PROJ_LIST).clearAll();
    $$(ID_VIEW_COMMENTS_PROJ_LIST).parse(__projects.filter(project => project.taskIds.length));
  },
  //
  onPopupHide: () => {
    $$(ID_VIEW_COMMENTS_PROJ_LIST).unselectAll();

    if (!_stopBackRouting) {
      routerHelper.backRoute();
    }

    userExtAnalytics.log('general_comments_close');
    _init = false;
  },
  //
  onPopupShow: () => {
    // if ($$(ID_VIEW_POPUP_COMMENTS).isVisible() === true) {
    //     return;
    // }
    _activeProjId = projectsModel.getActiveGanttId();

    // const __allComments = commentsModel.getAllComments();

    // __allComments.forEach(_helpers.initStateWithComment);

    // const __projects = Object.keys(_state.projectsById).map(id => _state.projectsById[id]); // !TODO: sort

    // $$(ID_VIEW_COMMENTS_PROJ_LIST).clearAll();
    // $$(ID_VIEW_COMMENTS_PROJ_LIST).parse(__projects);
  },
  onSubmitCreateComment: ({ idtask, content }) => {
    if (!content) {
      return webix.message({
        type: 'warning',
        text: __('comments_msg_error'),
      });
    }

    const __taskEntry = _state.tasksById[idtask];
    // const __projEntry = _state.projectsById[__taskEntry.gantt_id];

    globalStore.dispatch('comments/createComment', {
      projectId: __taskEntry.gantt_id,
      taskId: idtask,
      taskName: __taskEntry.name,
      content: _helpers.replaceEmailToID(content),
      needReplaceMentions: false,
    });

    // commentsModel.addNewComment({
    //   task_id: idtask,
    //   task_name: __taskEntry.name,
    //   comment: _helpers.replaceEmailToID(content),
    //   date: (new Date()).toUTCString(),
    //   user_id: user.id,
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   username: user.username,
    //   photo: user.photo,
    //   team_id: user.team.id,
    //   project_name: __projEntry.name,
    //   gantt_id: __taskEntry.gantt_id,
    //   read: 0,
    // });
  },
};

const _templates = {
  /**
     * A template for the accordeon item header
     *
     * @param {String} taskName
     * @param {Number} commentsCount
     * @param {String} captionValue
     *
     * @return {String}
     */
  getHeaderTemplateForAccordion: ({
    taskName, commentsCount, captionValue, unreadCount,
  }) => `
        <div class="accordion-header">
            <div class="accordion-header-task">
                <div class="accordion-header-task-label">
                    ${__('gantt_task')}
                </div>
                <div class="accordion-header-task-name" style="flex-basis: 24px;">
                    ${customHelper.formatTaskName(taskName)}
                </div>
                <div class="accordion-header-all-comments">
                ${__('comment_list_all_task_comments', { count: commentsCount })}
                </div>
                <div style="visibility: ${unreadCount > 0 ? 'visible' : 'hidden'};" class="accordion-header-unread-count">
                    ${unreadCount}
                </div>
            </div>
            <div class="accordion-header-center-block">
                ${captionValue}
            </div>
        </div>`,
  //
  projListEntry: ({
    isJira, name, unreadCount, taskIds,
  }) => {
    if (taskIds && taskIds.length) {
      return `
            <div class="space-item-icon">
                ${isJira ? icon_jira : icon_my_project}
            </div>
            <div class='space-item-name'>
                ${name}
            </div>
            <div style="display: ${unreadCount > 0 ? 'block' : 'none'}" class="space-item-count">
                ${unreadCount}
            </div>
        `;
    }
  },
  /**
     * !description
     *
     * @param {String}
     * @param {String}
     *
     * @return {String}
     */
  emptyAreaTemplate: ({ imgSrc, text }) => `
        <div class="all-comments-popup-body-right-panel-no-comments-body">
            <div class="all-comments-popup-body-right-panel-no-comments-body-icon">
                <img src="${imgSrc}" />
            </div>
            <div class="all-comments-popup-body-right-panel-no-comments-body-text">
                ${text}
            </div>
        </div>`,
  /**
     * A template for the regular comment in the accordion item body
     *
     * @param {String} userName
     * @param {String} photo
     * @param {String} comment_fmt
     * @param {String} date_fmt
     * @param {Boolean} isOwn
     *
     * @return {String} - a composed-item template
     */
  accordionBodyExistComment: ({
    isOwn, photo, comment_fmt, date_fmt, userName, date_edited,
  }) => `
        <div class="task-comments-list-item ${isOwn ? 'my-comment' : ''}">
            <div class="task-comments-list-item-avatar">
                <img class="task-comments-list-item-avatar-photo" src="${photo}">
            </div>
            <div class="task-comments-list-item-block">
                <div class="task-comments-list-item-block-body ql-snow">
                    <div class="task-comments-list-item-block-body-text ql-editor">${comment_fmt}</div>
                </div>
                <div class="task-comments-list-item-block-footer">
                    <div class="task-comments-list-item-block-footer-user-name">
                        ${userName}
                    </div>
                    <div class="task-comments-list-item-block-footer-date">
                        ${date_fmt}
                    </div>
                    ${date_edited ? `<div class="task-comments-list-item-block-footer-edit">${__('task_view_comment_area_edited')}</div>` : ''}
                </div>
            </div>
        </div>`,
  /**
     * A template for the removed comment in the accordion item body
     *
     * @param {String}
     * @param {String}
     * @param {Boolean}
     *
     * @return {String} - a composed-item template
     */
  accordionBodyRemComment: ({ photo, comment_id, isOwn }) => `
        <div class="task-comments-list-item all-comments comment-delete ${isOwn ? 'my-comment' : ''}" data-id="${comment_id}">
            <div class="task-comments-list-item-avatar">
                <img class="task-comments-list-item-avatar-photo" src="${photo}">
            </div>
            <div class="task-comments-list-item-block">
                <div class="task-comments-list-item-block-body ql-snow">
                    <div class="task-comments-list-item-block-body-text ql-editor">
                        <div>${__('task_comments_delete_msg')}</div>
                        <div class="task-comments-list-item-block-body-text-basket">
                            ${icon_trash}
                        </div>
                    </div>
                </div>
            </div>
        </div>`,
};

_innerViews = {
  // a common template configuration (some fielad will be calculated dynamically)
  accordionItemTemplateCfg: (task_id, gantt_id) => ({
    view: 'accordionitem',
    headerHeight: 48,
    headerAltHeight: 48,
    type: 'space',
    id: null,
    header: null,
    headerAlt: null,
    collapsed: true,
    body: {
      css: 'accordion-body',
      autoheight: true,
      rows: [{
        data: { comments: [] },
        css: 'body-comments-list',
        template: _helpers.composeAccordionItemBody,
        id: null,
        autoheight: true,
        borderless: true,
      },
      {
        height: 20,
      },
      {
        view: 'add_comment',
        css: 'template_body_footer',
        idtask: task_id,
        idgantt: gantt_id,
        id: `${task_id}_addBtnView`,
        hidden: !_helpers.hasAccess(gantt_id),
        on: { submitCreateComment: _handlers.onSubmitCreateComment },
      },
      {
        height: 20,
      }],
    },
    onClick: {
      'accordion-header-all-comments': _handlers.onDisplayAllCommentsClick,
    },
  }),
  //
  workProjectsList: {
    view: 'list',
    id: ID_VIEW_COMMENTS_PROJ_LIST,
    css: 'workProjectsList',
    borderless: false,
    width: 250,
    select: true,
    template: _templates.projListEntry,
    type: {
      height: 48,
    },
    data: [],
    on: {
      onItemClick: _handlers.onProjectsListClick,
    },
  },
  //
  scrollWithCommentsView: {
    id: ID_VIEW_COMMENTS_LIST_COMMENTS_AREA,
    view: 'scrollview',
    paddingX: 0,
    paddingY: 0,
    hidden: false,
    scroll: 'y',
    margin: 0,
    css: 'all-comments-popup-body-right-panel',
    body: {
      cols: [
        { width: 12 },
        {
          view: 'accordion',
          id: ID_VIEW_COMMENTS_ACCORDION,
          css: 'accordionCommentsLayout',
          type: 'line',
          width: 624,
          multi: true,
          scroll: 'y',
          borderless: true,
          on: {
            onAfterCollapse: _handlers.onAfterItemCollapse,
            onAfterExpand: _handlers.onAfterItemExpand,
          },
          rows: [],
        },
        { width: 24 },
      ],
    },
  },
  //
  emptyAreaView: {
    id: ID_VIEW_COMMENTS_LIST_EMPTY_AREA,
    view: 'template',
    hidden: true,
    borderless: false,
    css: 'all-comments-popup-body-right-panel-no-comments',
    data: {
      imgSrc: `${GT.cdn}/imgs/no_comments.png`,
      text: __('comments_list_no_comments'),
    },
    template: _templates.emptyAreaTemplate,
  },
};

const webixUI = {
  view: 'ganttproWindowPopup',
  width: 960,
  height: 640,
  zIndex: 200,
  id: ID_VIEW_POPUP_COMMENTS,
  css: 'all-comments-popup clear-window-popup',
  header: {
    label: __('new_all_comments'),
  },
  body: {
    paddingX: 24,
    paddingY: 0,
    type: 'space',
    margin: 1,
    css: 'all-comments-popup-body',
    rows: [{
      cols: [
        _innerViews.workProjectsList,
        _innerViews.emptyAreaView,
        _innerViews.scrollWithCommentsView,
      ],
    }, {
      height: 24,
    }],
  },
  on: {
    onBeforeShow: _handlers.onPopupBeforeShow,
    onHide: _handlers.onPopupHide,
    onShow: _handlers.onPopupShow,
  },
};

// !TODO: dynamic events (after component initialization)
app.on(ID_EVENT_COMMENTS_ADD_COMMENTS, comments => comments.forEach(_handlers.onAddComment));
app.on(ID_EVENT_COMMENTS_ADD_COMMENT, _handlers.onAddComment);
app.on(ID_EVENT_COMMENTS_REMOVE_COMMENT, _handlers.onRemoveComment);
app.on(ID_EVENT_COMMENTS_EDIT_COMMENT, _handlers.onEditComment);
app.on(ID_EVENT_COMMENTS_MARK_AS_READ, _handlers.onMarkAsRead);
app.on(ID_EVENT_COLLABORATION_UPDATEDMODELS, _handlers.onCollaborationUpdateModels);
app.on(ID_EVENT_COMMENTS_INIT_COMPLETE, _handlers.onInitComments);

// fixme: make separate functions
app.on(ID_EVENT_COMMENTS_DELETE_COMMENTS, commentIds => {
  const { commentsById, tasksById, projectsById } = _state;

  // getTasks to remove
  const __taskIdsToRemove = commentIds.reduce((result, idComment) => {
    const __commentEntry = commentsById[idComment];

    return (!__commentEntry || result.indexOf(__commentEntry.task_id) !== -1)
      ? result
      : result.concat(__commentEntry.task_id);
  }, []);

  const __idSelectedProj = _helpers.getSelecterProjId();
  const __pp = []; // hotpatch
  // const

  __taskIdsToRemove.forEach(idTask => {
    if (tasksById[idTask]) {
      const __idProj = tasksById[idTask].gantt_id;

      _.unset(tasksById, idTask);

      const __projEntry = projectsById[__idProj];

      if (__projEntry) {
        __projEntry.taskIds.splice(__projEntry.taskIds.indexOf(idTask), 1);

        if (__idSelectedProj === __idProj) {
          __pp.push(idTask);
        }

        if (__projEntry.taskIds.length === 0) {
          if (__idSelectedProj === __idProj && $$(ID_VIEW_COMMENTS_ACCORDION)) {
            webix.ui([], $$(ID_VIEW_COMMENTS_ACCORDION));
          }

          if ($$(ID_VIEW_COMMENTS_PROJ_LIST)) {
            $$(ID_VIEW_COMMENTS_PROJ_LIST).remove(__idProj);
          }
        }
      }
    }
  });

  if (projectsById[__idSelectedProj]) {
    if (projectsById[__idSelectedProj].taskIds.length === 0) {
      if ($$(ID_VIEW_COMMENTS_ACCORDION)) {
        webix.ui([], $$(ID_VIEW_COMMENTS_ACCORDION));
      }
    } else if ($$(ID_VIEW_COMMENTS_ACCORDION)) {
      __pp.forEach(idTask => {
        $$(ID_VIEW_COMMENTS_ACCORDION).removeView(_idHelper.getAccordionItemViewId(idTask));
      });
    }
  }
});

app.socket.on('teamShare:deleteProject', ganttId => {
  if (parseInt(ganttId, 10) === _activeProjId && $$(ID_VIEW_POPUP_COMMENTS)) {
    $$(ID_VIEW_POPUP_COMMENTS).hide();
  }
});

app.on('project:archive', (ganttId, isArchived) => {
  if (parseInt(ganttId, 10) === _activeProjId && isArchived && $$(ID_VIEW_POPUP_COMMENTS)) {
    $$(ID_VIEW_POPUP_COMMENTS).hide();
  }
});
app.on('onAfterCollaboration', ({ event, projects }) => {
  if ((event === 'ResourceOnProjectsUpdated' || event === 'RoleAccountUpdated' || event === 'RoleProjectUpdated') && $$(ID_VIEW_POPUP_COMMENTS)) {
    if (!rightsComponent.project.hasRight(projects[0], 'comments')) {
      _state.projectsById[projects[0]].taskIds.forEach(taskId => $$(`${taskId}___`) && $$(`${taskId}___`).hide());
    } else {
      _state.projectsById[projects[0]].taskIds.forEach(taskId => $$(`${taskId}___`) && $$(`${taskId}___`).show());
    }
  }
});

app.on('userAvatar:update', newPhoto => {
  _updatedCurrentUserAvatar = newPhoto;
});

const init = function () {
  if (!_init) {
    $$(ID_VIEW_POPUP_COMMENTS).show();
    _init = true;
  }
};

export {
  init,
  webixUI,
};
