/* eslint-disable */
import app from "../../app";
import _ from "../../libs/lodash";

// should be fixed far future!
const ID_EVENT_APP_MODAL_SHOW = 'app:modal:show';
const ID_EVENT_APP_MODAL_HIDE = 'app:modal:hide';

var innerViews = {
  notificationModal: {
    id: "notificationModal"
  }
};

var innerHelpers = {
  generateWebixConfig: function (options) {
    var buttons = _.reduce(options.buttons, function (res, item, index) {
      var button = {
        view: "button",
        width: item.width,
        value: item.text,
        css: item.css ? item.css : "",
        on: {
          "onItemClick": function () {
            item.callback();
          }
        }
      };

      res.push(button);
      if (options.buttons.length - 1 !== index) {
        res.push({});
      }
      return res;

    }, []);

    if (buttons.length === 1) {
      buttons = _.concat({}, buttons, {});
    }

    return {
      view: "ganttproWindowPopup",
      id: innerViews.notificationModal.id,
      css: "notificationModal clear-window-popup",
      width: options.width,
      hidden: false,
      unClosable: options.unClosable,
      zIndex: 200,
      header: {
        label: options.title
      },
      on: {
        'onShow': () => {
          app.trigger(ID_EVENT_APP_MODAL_SHOW, {
            idModal: 'notificationModal'
          });
        },
        'onHide': () => {
          app.trigger(ID_EVENT_APP_MODAL_HIDE, {
            idModal: 'notificationModal'
          });
        }
      },
      body: {
        css: 'notificationModalBody',
        width: options.width,
        autoheight: true,
        rows: [
          // {
          //   height: 12,
          //   paddingY: 0,
          // },
          {
            paddingX: 24,
            // paddingY: 24,
            css: options.body.css ? options.body.css : "",
            cols: [
              {
                view: "template",
                borderless: true,
                autoheight: true,
                css: options.body.templateCss ? options.body.templateCss : "",
                template: options.body.template
              }
            ]
          },
          {
            height: 24,
            paddingY: 0,
          },
          {
            css: "layout-top-border notificationModalButtons",
            height: 60,
            paddingX: 24,
            paddingY: 12,
            borderless: false,
            cols: buttons
          }
        ]
      }
    };
  },
  renderWindow: function (config) {
    $$(innerViews.notificationModal.id) && $$(innerViews.notificationModal.id).close();
    webix.ui(config).show();
  }
};

app.on("popup:show", function () {
  $$(innerViews.notificationModal.id) && $$(innerViews.notificationModal.id).hide();
});

export default {
  init: function (options) {
    innerHelpers.renderWindow(innerHelpers.generateWebixConfig(options));
  }
};
