var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "15",
        height: "15",
        viewBox: "0 0 15 15",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.0029 0.999672H1.01365L0.997115 10.9981L3.17571 10.9997V13.3619L6.73489 10.9981H13L13.0029 0.999672ZM0.0136461 1.17859C0.0136461 0.527491 0.545367 -0.000328064 1.1832 -0.000328064H12.8441C13.49 -0.000328064 14.0136 0.527131 14.0136 1.17859L14 10.8125C14 11.4636 13.4739 11.9914 12.8441 11.9914H7L2.33333 14.9997V11.9914H1.16667C0.522334 11.9914 0 11.4639 0 10.8125L0.0136461 1.17859Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M11 3.99967H3V4.42193V4.99967H11V3.99967Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M11 6.99967H3V7.99967H11V6.99967Z", fill: "currentColor" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }