var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.multiselect, _vm.$style.field_wrap] }, [
    _c("div", { class: [_vm.$style.input_label] }, [
      _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasChacked,
              expression: "hasChacked",
            },
          ],
          class: _vm.$style.reset,
          attrs: { id: _vm.gpAutotestName + "_reset_btn" },
          on: {
            click: function ($event) {
              return _vm.resetChacked()
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.locale.reset) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { ref: "inputField", class: [_vm.$style.input_field_wrap] }, [
      _c(
        "div",
        {
          class: [
            _vm.$style.d_flex,
            _vm.$style.input_field,
            _vm.$style.multiselect_with_search_input,
            _vm.hasChacked || _vm.focusInput ? _vm.$style.active : "",
          ],
        },
        [
          _vm.hasChacked
            ? _c(
                "div",
                {
                  class: [
                    _vm.$style.selected,
                    _vm.dropList.isOpen ? _vm.$style.active : "",
                    _vm.dropTemplate === "label" ? _vm.$style.whiteBg : "",
                    _vm.dropTemplate === "icon+text" ||
                    _vm.dropTemplate === "text"
                      ? _vm.$style.add_pad
                      : "",
                  ],
                  attrs: { id: _vm.gpAutotestName },
                  on: { click: _vm.toggleDropList },
                },
                [
                  _vm.dropTemplate === "icon+text"
                    ? [
                        _vm.chacked.length == 1
                          ? _vm._l(_vm.getFirstItems(), function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + _vm.componentKey + 1,
                                  class: [_vm.$style.nowrap, _vm.$style.d_flex],
                                },
                                [
                                  _vm.isSrcToImg(item[_vm.iconField])
                                    ? _c("div", {
                                        class: [_vm.$style.item_img_wrap],
                                        style: {
                                          backgroundImage:
                                            "url(" + item[_vm.iconField] + ")",
                                          backgroundSize: "cover",
                                        },
                                      })
                                    : _c("webix-ui", {
                                        class: [_vm.$style.item_icon],
                                        attrs: {
                                          config: _vm.svgForVue(
                                            item[_vm.iconField]
                                          ),
                                        },
                                      }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(item[_vm.valueField]) + " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chacked.length >= 2
                          ? _vm._l(
                              _vm.getFirstItems(2),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index + _vm.componentKey + 2,
                                    class: [
                                      _vm.$style.item_icon_wrap,
                                      _vm.$style.item_img_wrap,
                                    ],
                                  },
                                  [
                                    _vm.isSrcToImg(item[_vm.iconField])
                                      ? [
                                          _c("div", {
                                            class: [_vm.$style.item_img_wrap],
                                            style: {
                                              backgroundImage:
                                                "url(" +
                                                item[_vm.iconField] +
                                                ")",
                                              backgroundSize: "cover",
                                            },
                                          }),
                                        ]
                                      : _c("webix-ui", {
                                          class: [_vm.$style.item_icon],
                                          attrs: {
                                            config: _vm.svgForVue(
                                              item[_vm.iconField]
                                            ),
                                          },
                                        }),
                                  ],
                                  2
                                )
                              }
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chacked.length > 2
                          ? _c("div", { class: [_vm.$style.selected_count] }, [
                              _vm._v(
                                "\n            +" +
                                  _vm._s(_vm.chacked.length - 2) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dropTemplate === "text"
                    ? [
                        _vm.chacked.length === 1
                          ? _vm._l(_vm.getFirstItems(), function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + _vm.componentKey + 1,
                                  class: [_vm.$style.nowrap, _vm.$style.d_flex],
                                },
                                [
                                  _c("span", { class: [_vm.$style.nowrap] }, [
                                    _vm._v(
                                      " " + _vm._s(item[_vm.valueField]) + " "
                                    ),
                                  ]),
                                ]
                              )
                            })
                          : _c("div", { class: [_vm.$style.nowrap] }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.chacked.length) +
                                  " " +
                                  _vm._s(_vm.locale.items) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dropTemplate === "label"
                    ? [
                        _vm.chacked.length == 1
                          ? _vm._l(_vm.getFirstItems(), function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + _vm.componentKey + 1,
                                  class: [_vm.$style.nowrap, _vm.$style.d_flex],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [_vm.$style.selected_label_drop],
                                      style: { "background-color": item.color },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item[_vm.valueField]) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chacked.length >= 2
                          ? _vm._l(
                              _vm.getFirstItems(2),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index + _vm.componentKey + 2,
                                    class: [
                                      _vm.$style.selected_label_drop,
                                      _vm.$style.label_min,
                                    ],
                                    style: { "background-color": item.color },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFirstLetter(
                                            item[_vm.valueField]
                                          )
                                        ) + " "
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chacked.length > 2
                          ? _c("div", { class: [_vm.$style.selected_count] }, [
                              _vm._v(
                                "\n            +" +
                                  _vm._s(_vm.chacked.length - 2) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchStr,
                expression: "searchStr",
              },
            ],
            class: [_vm.dropList.isOpen ? _vm.$style.cursor_text : ""],
            attrs: {
              placeholder: _vm.dropList.isOpen
                ? _vm.locale.search
                : _vm.hasChacked
                ? ""
                : _vm.placeholder,
              type: "text",
              disabled: _vm.filterProvide.disableInput,
            },
            domProps: { value: _vm.searchStr },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchStr = $event.target.value
                },
                _vm.updateShowedItems,
              ],
              focus: function ($event) {
                _vm.focusInput = true
              },
              blur: function ($event) {
                _vm.focusInput = false
              },
              click: _vm.openDropList,
            },
          }),
          _vm._v(" "),
          _c("webix-ui", {
            class: _vm.$style.drop_arrow,
            attrs: { config: _vm.icon_arrow },
            on: { click: _vm.toggleDropList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "dropList",
          class: [
            _vm.$style.drop_list,
            _vm.dropList.isOpen ? _vm.$style.show_list : "",
            _vm.isBottom ? _vm.$style.bottom_drop : _vm.$style.top_drop,
          ],
          style: {
            top: _vm.dropList.coordinates.top,
            bottom: _vm.dropList.coordinates.bottom,
            left: _vm.dropList.coordinates.left,
            right: _vm.dropList.coordinates.right,
            width: _vm.dropList.width,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showItems.length,
                  expression: "showItems.length",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$style.select_all,
                    _vm.dropList.isScrollOnTop ? "" : _vm.$style.scrolled,
                  ],
                  on: { click: _vm.selectUnselectAll },
                },
                [
                  !_vm.isAllShowedSelected
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.locale.selectAll) +
                            "\n          "
                        ),
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.locale.unselectAll))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  key: _vm.dropItems.length,
                  ref: "dropListScrolled",
                  staticClass: "gantt_scroll",
                  class: _vm.$style.drop_scroll,
                },
                _vm._l(_vm.groupSet, function (groupname) {
                  return _c(
                    "div",
                    { key: _vm.componentKey + groupname },
                    [
                      groupname
                        ? _c("div", { class: _vm.$style.drop_group }, [
                            _c("span", { class: _vm.$style.drop_group_name }, [
                              _vm._v(" " + _vm._s(groupname) + " "),
                            ]),
                            _vm._v(" "),
                            _c("div", { class: _vm.$style.drop_group_line }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.getGroupItems(groupname), function (item) {
                        return _c(
                          "div",
                          {
                            key: _vm.dropTemplate + item.id,
                            class: _vm.$style.list_item,
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.chacked,
                                  expression: "chacked",
                                },
                              ],
                              attrs: {
                                id: item[_vm.trackBy] + _vm.componentKey,
                                type: "checkbox",
                              },
                              domProps: {
                                value: item,
                                checked: Array.isArray(_vm.chacked)
                                  ? _vm._i(_vm.chacked, item) > -1
                                  : _vm.chacked,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.chacked,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.chacked = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.chacked = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.chacked = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: item[_vm.trackBy] + _vm.componentKey,
                                },
                              },
                              [
                                _vm.dropTemplate === "text"
                                  ? [
                                      _c(
                                        "span",
                                        { class: [_vm.$style.nowrap] },
                                        [_vm._v(_vm._s(item[_vm.valueField]))]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dropTemplate === "icon+text"
                                  ? [
                                      _vm.isSrcToImg(item[_vm.iconField])
                                        ? _c("div", {
                                            class: [_vm.$style.item_img_wrap],
                                            style: {
                                              backgroundImage:
                                                "url(" +
                                                item[_vm.iconField] +
                                                ")",
                                              backgroundSize: "cover",
                                            },
                                          })
                                        : _c("webix-ui", {
                                            class: [_vm.$style.item_icon],
                                            attrs: {
                                              config: _vm.svgForVue(
                                                item[_vm.iconField]
                                              ),
                                            },
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { class: [_vm.$style.nowrap] },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item[_vm.valueField]) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dropTemplate === "label"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.$style.label_drop_template,
                                            _vm.$style.nowrap,
                                          ],
                                          style: {
                                            "background-color": item.color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item[_vm.valueField]) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showItems.length,
                  expression: "!showItems.length",
                },
              ],
              class: [_vm.$style.no_items],
            },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.locale.noSearchedItems) + " "),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }