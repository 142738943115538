import app from '../../../app';
import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';

import baselineModel from '../../../models/baselines';
import routerHelper from '../../../helpers/router';
import globalStore from '../../../store/main';

const __ = window.__;

const PLANNED_START_FIELD = 'planned_start';
const PLANNED_END_FIELD = 'planned_end';

let layer_ids = {};

const innerObject = {
  init: {
    addEventListeners() {
      gantt.attachEvent('onBeforeGanttRender', () => {
        const baselines = webix.storage.cookie.get('baselines');

        if (baselines && !baselines[gantt.config.gantt_id]) {
          gantt.config.baseline = 0;
        }

        if (gantt.config.baseline === 0) {
          gantt.config.row_height = gantt.config.original_row_height;
          innerObject.helpers.removeClass('.dhx_gantt_container', 'baseline-container');
        }
      });

      gantt.attachEvent('onParse', _.debounce(() => {
        if (routerHelper.isWorkloadViewRoute()) {
          return;
        }
        innerObject.handlers.clearBaselines();
        app.trigger('baselineHeader:build');
      }, 700));
    },
    run() {
      innerObject.init.addEventListeners();
      innerObject.handlers.clearBaselines(true);
    },
  },
  handlers: {
    updateTask(baselineID, baselineTask) {
      const task = gantt.isTaskExists(baselineTask.id) && gantt.getTask(baselineTask.id);

      if (task && task.start_date && task.end_date && task.parent !== 0) {
        baselineTask.start_date = innerObject.helpers.parseDate(baselineTask.start_date);
        baselineTask.end_date = gantt.calculateEndDate({
          start_date: baselineTask.start_date,
          duration: baselineTask.duration,
          baselineTask,
        });

        const copy = gantt.__scaleTask(baselineTask);

        task.baselineData = baselineTask;

        task[PLANNED_START_FIELD + baselineID] = copy.start_date;

        if (new Date(baselineTask.end_date).valueOf() === new Date(baselineTask.start_date).valueOf()
          && baselineTask.duration > 0) {
          task[PLANNED_END_FIELD + baselineID] = gantt.calculateEndDate(copy.start_date, copy.duration);
        } else {
          task[PLANNED_END_FIELD + baselineID] = copy.end_date;
        }
      }
    },
    addTaskLayer(baselineID, baselineNumber, task) {
      const k = Object.keys(layer_ids).indexOf(baselineID.toString());

      if (task[PLANNED_START_FIELD + baselineID] && task[PLANNED_END_FIELD + baselineID]) {
        const sizes = gantt.getTaskPosition(task,
          innerObject.helpers.parseDate(task[PLANNED_START_FIELD + baselineID]),
          innerObject.helpers.parseDate(task[PLANNED_END_FIELD + baselineID]));

        const right = gantt.templates.rightside_text(
          task[PLANNED_START_FIELD + baselineID],
          task[PLANNED_END_FIELD + baselineID], {
            text: baselineModel.getName(baselineID),
            start_date: task[PLANNED_START_FIELD + baselineID],
            end_date: task[PLANNED_END_FIELD + baselineID],
          },
        );

        const element = document.createElement('div');
        const baselineNameNode = document.createElement('div');

        baselineNameNode.className = `baseline_name ${right ? 'gantt_side_content gantt_right' : 'gantt_task_content'}`;
        baselineNameNode.innerHTML = '';// baselineModel.getName(baselineID);

        if (gantt.config.baseline === 2) {
          baselineNameNode.style['font-size'] = `${gantt.config.task_height / (gantt.config.baseline) - 2}px`;
          if (!right) {
            baselineNameNode.style['letter-spacing'] = `${2}px`;
          }
        }

        element.className = `baseline baseline-${baselineID}`;
        element.style.width = `${sizes.width}px`;
        element.style.left = `${sizes.left}px`;
        element.style.height = '6px'; // gantt.config.task_height / (gantt.config.baseline) + 'px';
        element.style['line-height'] = '10px';// gantt.config.task_height / (gantt.config.baseline) - 2 + 'px';
        if (task.type === 'project') {
          element.className = 'baseline project-baseline';
        }

        let topPosition = `${sizes.top + (gantt.config.task_height + 5)}px`;

        if (task.type === gantt.config.types.project) topPosition = `${sizes.top + (gantt.config.task_height + 19)}px`;

        // element.style.top = sizes.top + (task.type !== gantt.config.types.milestone ? gantt.config.task_height : (gantt.config.task_height + 5))+ 'px';
        element.style.top = topPosition;

        element.appendChild(baselineNameNode);

        const baselineContainer = document.querySelector('.baseline-container');

        if (baselineContainer && baselineContainer.classList.contains('touch')) {
          element.style.top = `${sizes.top + (task.type !== gantt.config.types.milestone ? (gantt.config.task_height + 4) : (gantt.config.task_height + parseInt(element.style.height, 10) + 4))}px`;
        }

        if (baselineContainer && baselineContainer.classList.contains('compact')) {
          element.style.top = `${sizes.top + (task.type !== gantt.config.types.milestone ? (gantt.config.task_height + 2) : (gantt.config.task_height + 2))}px`;
        }

        return element;
      }

      return false;
    },

    buildBaseline(tasks, baselineID) {
      if (gantt.config.gantt_id !== tasks[0].gantt_id) {
        return;
      }

      gantt.refreshData();

      const renderBaseline = innerObject.handlers.addTaskLayer.bind(null, baselineID, Object.keys(layer_ids).length);

      gantt._inline_edit._currentTask = null;

      _.each(tasks, task => innerObject.handlers.updateTask(baselineID, task));

      layer_ids[baselineID] && gantt.removeTaskLayer(layer_ids[baselineID]);

      gantt.config.row_height = gantt.config.original_row_height * 2;
      innerObject.helpers.addClass('.dhx_gantt_container', 'baseline-container');

      layer_ids[baselineID] = gantt.addTaskLayer({
        renderer: {
          render: renderBaseline,
          getRectangle(item, view) {
            return {
              top: view.getItemTop(item.id),
              height: view.getItemHeight(item.id),
              left: 0,
              right: Infinity,
            };
          },
        },
      });

      if (Object.keys(layer_ids).length === 1) {
        gantt.config.baseline = 0;
      }

      gantt.config.baseline = _.keys(layer_ids).length;

      globalStore.commit('headerView/toggleBaselinePopup', gantt.config.baseline);
    },
    removeBaseline(baselineID) {
      const layerID = layer_ids[baselineID];

      if (layerID) {
        gantt.removeTaskLayer(layerID);

        gantt.config.baseline--;

        if (gantt.config.baseline < 1) {
          gantt.config.row_height = gantt.config.original_row_height;
          innerObject.helpers.removeClass('.dhx_gantt_container', 'baseline-container');
        }

        delete layer_ids[baselineID];

        gantt.callEvent('ganttRender');
      }
    },
    clearBaselines(removeClass) {
      gantt.config.baseline = 0;
      globalStore.commit('headerView/toggleBaselinePopup', gantt.config.baseline);

      _.each(layer_ids, layerID => {
        layerID && !_.isUndefined(layerID) && gantt.removeTaskLayer(layerID);
      });

      layer_ids = {};

      if (removeClass) {
        innerObject.helpers.removeClass('.dhx_gantt_container', 'baseline-container');
      }
    },
  },
  settings: {
    ganttId: 0,
  },
  helpers: {
    parseDate(date) {
      return gantt.date.parseDate(moment(date).format('YYYY-MM-DD H:mm:ss'), '%Y/%m/%d %H:%i:%s');
    },
    addClass(selector, cssClass) {
      const element = document.querySelector(selector);
      element && element.classList.add(cssClass);
    },
    removeClass(selector, cssClass) {
      const element = document.querySelector(selector);
      element && element.classList.remove(cssClass);
    },
  },
};

const outputObject = {
  init: {
    run: innerObject.init.run,
  },
  handlers: innerObject.handlers,
};

export default outputObject;
