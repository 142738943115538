import app from "../../app";

var collection = {};
collection = new webix.DataCollection();

if (app.config.mode.isBase) {
  collection = new webix.DataCollection({
    url: "rest->/api/integrations/slack"
  });
} else {
  collection = new webix.DataCollection();
}

collection.getData = function () {
  return new Promise((resolve, reject) => {
    webix.ajax()
      .get("/api/integrations/slack")
      .then(function(result) {
        let data = result.json();
        collection.parse(data);
        let _data = collection.serialize();
        _data.forEach(element => {
          element.editMode = false;
        });
      
        resolve(_data);
      }).catch(err => {
        new Error(err)
      })
  })
}

collection.getDataFromCollection = function() {
  let _data = collection.serialize();
  _data.forEach(element => {
    element.editMode = false;
  });
  return _data;
}

collection.addConnection = function(data) {
  return new Promise(function(resolve, reject) {
    webix.ajax()
      .post("/api/integrations/slack/addConnection", data)
      .then(function(data) {
        let result = data.json();
        if (result.status === 'ok') {
          collection.add(result.item);
        }
        resolve(result)
      }).catch(err => {
        new Error(err)
      })
  })
};

collection.editConnection = function(data) {
  return new Promise(function(resolve, reject) {
    let itemId = data.channelProjectId;
    webix.ajax()
      .post("/api/integrations/slack/editConnection", data)
      .then(function(data) {
        let result = data.json();
        if (result.status === 'ok') {
          let cItem = collection.getItem(itemId);
          cItem = result.item;
          collection.updateItem(itemId, cItem);
        }
        resolve(result)
      }).catch(err => {
        new Error(err)
      })
  })
}

collection.removeConnection = function(data) {
  return new Promise(function(resolve, reject) {
    webix.ajax()
      .del("/api/integrations/slack", data)
      .then(function(data) {
        let result = data.json();
        if (result.status === 'ok') {
          collection.remove(result.id);
        }
        resolve(result)
      })
      .catch(err => {
        new Error(err)
      })
  })
};

collection.removeAllConnections = () => collection.clearAll();

collection.saveSetting = function(data) {
  return new Promise(function(resolve, reject) {
    webix.ajax()
      .post("/api/integrations/slack/saveSetting", data)
      .then(function(response) {
        let result = response.json();
        if (result.status === 'ok') {
          let cItem = collection.getItem(data.id);
          cItem[data.settingType] = data.value;
          collection.updateItem(data.id, cItem);
        }
        resolve(result)
      }).catch(err => {
        new Error(err)
      })
  })
};

collection.getChannels = function() {
  return new Promise(function(resolve, reject) {
    webix.ajax()
      .post("/api/integrations/slack/getConversions", {})
      .then(function(data) {
        let result = data.json();
        resolve(result.channels)
      }).catch(err => {
        new Error(err)
      })
  })
}

collection.attachEvent("onAfterAdd", function (id) {
  app.trigger("integration:slack:updated");
  app.trigger("integration:slack:addNewItem");
});

collection.attachEvent("onDataUpdate", function (id) {
  app.trigger("integration:slack:updated");

});

collection.attachEvent("onAfterDelete", function (id) {
  app.trigger("integration:slack:updated");
});

export default collection;
