var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.baseline,
    },
    [
      _c("vgp-icon-button", {
        ref: "contextMenuButton",
        attrs: {
          icon: { name: "baseline" },
          colors: _vm.buttonColors,
          active: _vm.isShowPopup || _vm.isEnabledBaselines,
          disabled: _vm.isDisabled,
        },
        on: { onClick: _vm.toggle },
      }),
      _vm._v(" "),
      _vm.isShowPopup
        ? _c("context-menu", {
            staticStyle: { width: "240px" },
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm.isEmpty
                        ? _c("div", { class: _vm.$style.empty }, [
                            _c("div", {
                              class: _vm.$style.icon,
                              domProps: { innerHTML: _vm._s(_vm.baselineIcon) },
                            }),
                            _vm._v(" "),
                            _c("div", { class: _vm.$style.text }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.locales("there_are_not_baselines")
                                  ) +
                                  "\n        "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAccess
                        ? _c(
                            "div",
                            { class: _vm.$style.button },
                            [
                              _c("vgp-button", {
                                attrs: {
                                  type: "primary-border",
                                  small: "",
                                  label: _vm.locales("create_baseline"),
                                },
                                on: { onClick: _vm.createBaseline },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isEmpty
                        ? _c(
                            "div",
                            { class: [_vm.$style.menu, "gantt_scroll"] },
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "div",
                                { key: item.id, class: [_vm.$style.menu_item] },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.$style.checkbox },
                                    [
                                      _c("vgp-radio-button", {
                                        attrs: {
                                          checked:
                                            _vm.enabledBaselines[item.id],
                                        },
                                        on: {
                                          onChange: function ($event) {
                                            return _vm.handleCheckbox(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$style.title,
                                        !_vm.isAccess || item.id === "default"
                                          ? _vm.$style.title_no_access
                                          : "",
                                      ],
                                    },
                                    [
                                      _c("input", {
                                        ref: "baseLineTitleInput",
                                        refInFor: true,
                                        class: _vm.$style.input,
                                        attrs: {
                                          "data-baseline-item-input": item.id,
                                          type: "text",
                                          readonly:
                                            !_vm.isAccess ||
                                            item.id === "default",
                                        },
                                        domProps: { value: item.name },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleTitleKeydown(item)
                                          },
                                          blur: function ($event) {
                                            return _vm.editName($event, item)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isAccess && item.id !== "default"
                                    ? _c(
                                        "div",
                                        {
                                          class: _vm.$style.icon,
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-sprite", {
                                            attrs: {
                                              name: "delete",
                                              type: "regular",
                                              size: "24",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              191149518
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: _vm.isShowDeletePopup,
          "popup-width": "440",
          "close-icon": "",
          title: _vm.locales("settings_msg_delete_baseline_confirm_title"),
          text: _vm.locales("settings_msg_delete_baseline_confirm_text"),
          "cancel-button": {
            title: _vm.locales("common_cancel"),
            type: "secondary",
          },
          "ok-button": {
            title: _vm.locales("common_delete"),
            type: "destructive",
          },
        },
        on: {
          onClickCancel: _vm.deleteConfirmCancel,
          onClickOk: _vm.deleteConfirmOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }