var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-group" },
    [
      _c("div", { staticClass: "group-title" }, [
        _vm._v("\n    " + _vm._s(_vm.locales("" + _vm.group.title)) + "\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.group.features, function (feature, index) {
        return _c("table-row", {
          key: index,
          attrs: { feature: feature, plans: _vm.plans },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }