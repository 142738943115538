import routerHelper from '../../helpers/router';
import projectsModel from '../../models/projects';
import historyModel from '../../models/history';

const CopyPasteHelpers = {
  isAvailablePastePopup() {
    const currentRoute = routerHelper.getCurrentRoute();

    return (currentRoute.name === 'project' || currentRoute.name === 'projectsMultiView') && currentRoute.params.mode === 'gantt';
  },

  prepareDataForCopy(taskId) {
    const task = gantt.getTask(taskId);
    const copyData = {
      taskId: +taskId,
      ganttId: task.gantt_id,
      text: task.text,
      siblingId: +taskId,
    };

    if (task.$level === 0) {
      const projectData = projectsModel.getProjectDataById(task.gantt_id);

      copyData.text = projectData.name;
    }

    return copyData;
  },

  prepareDataForPaste(data, config) {
    // statusHelper.handlers.showMainSpinner();

    const sibling = gantt.getTask(data.taskId);
    const actionHash = historyModel.generateActionHash();
    const pasteData = {
      ...config,
      newGanttId: sibling.gantt_id,
      siblingId: sibling.id,
      parentId: sibling.parent,
      before: data.before,
      convertToParent: false,
      actionHash,
    };

    return pasteData;
  },
};

export default CopyPasteHelpers;
