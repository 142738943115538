import app from "./../../../app";
import _ from "./../../../libs/lodash";

import projectsModel from "./../../../models/projects";
import ganttViewModel from "./../../../models/ganttViewModel";
import historyModel from "./../../../models/history";

import projectComponent from "./../../../components/Project";
import bindStatusAndProgressHelper from "../../../helpers/bindStatusAndProgress";
import routerHelper from "../../../helpers/router";
import globalStore from "../../../store/main";

const __ = window.__;

function createProgressCalculator(workerPath) {
  const _worker = new Worker(workerPath);

  return function (workerData) {
    return new Promise(function (resolve, reject) {
      _worker.onmessage = function (e) {
        _worker.onmessage = null;
        _worker.onerror = null;
        return resolve(e.data);
      };

      _worker.onerror = function (e) {
        _worker.onmessage = null;
        _worker.onerror = null;
        return reject(e);
      };

      _worker.postMessage(workerData);
    });
  }
}

gantt.attachEvent("onAfterTaskAdd", function (id, task) {
  if (task.ignoreProgress) {
    task = _.omit(task, 'ignoreProgress');
    return true;
  }

  if (task.callaborationAdd) {
    return;
  }

  if (task.type !== gantt.config.types.button && task.type !== "import" && task.type !== gantt.config.types.milestone) {
    innerObject.settings.actionHash = task.actionHash;
    _.delay(function () {
      innerObject.handlers.checkProgressAfterParse(task);
    }, 300);
  }
});

gantt.attachEvent("onAfterTaskDelete", function (id, taskData) {
  if (taskData.type !== gantt.config.types.button && taskData.type !== gantt.config.types.milestone) {
    innerObject.settings.actionHash = taskData.actionHash;
    _.delay(function () {
      innerObject.handlers.checkProgressAfterParse(taskData);
    }, 300);
  }
});

gantt.attachEvent("afterTaskMoved", function (sourceId, targetId, actionHash) {
  const source = gantt.getTask(sourceId);
  innerObject.settings.actionHash = actionHash;
  _.delay(function () {
    innerObject.handlers.checkProgressAfterParse(source);
  }, 300);
});

gantt.attachEvent("copyPaste", function (actionHash, task) {
  innerObject.settings.actionHash = actionHash;
  _.delay(function () {
    innerObject.handlers.checkProgressAfterParse(task);
  }, 300);
});

gantt.attachEvent("onAfterSmartTaskUpdate", function (id, taskData, props) {
  if (_.includes(props, "progress") || _.includes(props, "type") ) {
    if (isNaN(taskData.progress)) {
      taskData.progress = 0;
    }

    if (!gantt.config.isJira && taskData.type !== gantt.config.types.project) {
      gantt.config.bindStatusAndProgress.updateStatus(taskData);
    }
  }
});

gantt.attachEvent("onBeforeTaskUpdate", function (id, taskData, props) {
  innerObject.settings.actionHash = taskData.actionHash;
  innerObject.settings.updatedTask = taskData;
  _.delay(function () {
    !innerObject.settings.ignoreUpdate && innerObject.handlers.checkProgressAfterParse(taskData);
  }, 300);
  return true;
});


var innerObject = {
  init: {
    run: function () {
      let version = Date.now();
      if (GT.productVersion && GT.productVersion.buildVersion) {
        version = GT.productVersion.buildVersion;
      }

      innerObject.settings.progressCalc = createProgressCalculator('/workers/progress_worker.js' + `?${version}`);

      innerObject.init.setProgress(true);
      innerObject.settings.projectConfig = ganttViewModel.getProjectConfig();
    },
    setProgress: function (enableProgress) {
      gantt.config.drag_progress = enableProgress;
    },
  },
  helpers: {
    // {Number} -> {Object}
    getTasksByLevels: (ganttId) => {
      const taskLevels = {};
      const tasksList = globalStore.getters['tasksModel/getParentTasksByGanttId'](ganttId); // only project tasks

      if (!tasksList.length) {
        return {};
      }

      tasksList.forEach((task) => {
        const level = gantt.calculateTaskLevel(task.id);

        if (!taskLevels[level]) {
          taskLevels[level] = [];
        }

        taskLevels[level].push(task);
      });

      return taskLevels;
    },
    // {Object} -> {Boolean}
    isProgressEntry: entry => !!entry.parent && entry.type !== gantt.config.types.button,
    // {Number, Array, Array} -> {Promise}
    calcFullProgressUpdate: (ganttId, tasksProgress, taskIdsToRemove = []) => {
      const itemsCopy = _.cloneDeep(gantt.serialize().data.filter(innerObject.helpers.isProgressEntry));

      tasksProgress.forEach(({idTask, progress}) => {
        const taskEntry = itemsCopy.find(entry => entry.id === idTask);

        if (taskEntry) {
          taskEntry.progress = progress;
        }
      });

      const tasksProgressMap = tasksProgress.reduce((result, value) => ({
        ...result,
        [value.idTask]: value.progress
      }), {});

      const itemsCopyFiltered = taskIdsToRemove.length > 0
        ? itemsCopy.filter(entry => taskIdsToRemove.indexOf(entry.id) === -1)
        : itemsCopy;

      // itemsCopy
      return innerObject.settings.progressCalc({
        taskLevels: innerObject.helpers.getTasksByLevels(ganttId),
        progressType: gantt.config.progress,
        ganttTasksData: itemsCopyFiltered
      }).then(({tasksToUpdate}) => {
        const resultProgressMap = Object.keys(tasksToUpdate).reduce((result, keyName) => {
          return {
            ...result,
            [keyName]: parseFloat(tasksToUpdate[keyName].progress)
          };
        }, tasksProgressMap);

        const resultProgressArr = Object.keys(resultProgressMap).map(keyName => ({
          idTask: parseInt(keyName),
          progress: parseFloat(resultProgressMap[keyName])
        }));

        return resultProgressArr;
      });
    }
  },
  handlers: {
    leftOnlyProgress: function (ganttData) {
      return ganttData.filter(task => task && task.id)
        .map(task => {
          return {
            id: task.id,
            gantt_id: task.gantt_id,
            progress: task.progress,
            status: task.status,
            text: task.text,
          };
        })
        .filter(task => {
          return _.isNumber(+task.progress) && !isNaN(+task.progress);
        });
    },
    onProgressCalcFail: (e) => {
      console.error(e);
      webix.message({type: 'error', text: __('progress_calculating_error'), expire: 100000});
    },
    packageTasksUpdate: function (taskToUpdateMap, actionHash, ganttId) {
      const tasksToUpdate = Object.keys(taskToUpdateMap)
        .reduce((result, key) => {
          const taskEntry = _.clone(taskToUpdateMap[key]);
          let oldTaskEntry = gantt.isTaskExists(taskEntry.id) && gantt.getTask(taskEntry.id);

          if (!oldTaskEntry && innerObject.settings.oldTaskData) {
            oldTaskEntry = innerObject.settings.oldTaskData;
          }

          if (oldTaskEntry) {
            if (innerObject.settings.oldTaskData && innerObject.settings.oldTaskData.id === oldTaskEntry.id) {
              oldTaskEntry.progress = innerObject.settings.oldTaskData.progress;
            }

            const oldProgress = Math.round(oldTaskEntry.progress * 100) / 100;
            const newProgress = Math.round(taskEntry.progress * 100) / 100;

            if (newProgress !== oldProgress) {
              oldTaskEntry.progress = taskEntry.progress;
              return result.concat(taskEntry);
            }
          }

          return result;
        }, []);

      if (tasksToUpdate.length === 0) {
        return true;
      }

      const tasksWithProgress = this.leftOnlyProgress(tasksToUpdate);


      if (tasksWithProgress.length === 0) {
        // console.log('tasksToUpdate', tasksToUpdate)
        return true;
      }

      return actionHash
        ? gantt.updateTasks(tasksWithProgress, 'progress', actionHash)
        : projectComponent.getLastActionHash(ganttId)
          .then(resultHash => gantt.updateTasks(tasksWithProgress, 'progress', resultHash || historyModel.generateActionHash()));
    },
    calculateProgressByGanttID: function (ganttId, actionHash, taskToUpdate) {
      const projectConfig = projectsModel.getProjectConfig(ganttId);

      return Promise.resolve(innerObject.helpers.getTasksByLevels(ganttId))
        .then((tasksByLevels) => {
          let tasksData = gantt.serialize().data;

          if (routerHelper.isListViewRoute()) {
            tasksData = globalStore.getters['tasksModel/getTasksForGantt'](ganttId).data;
          }

          const ganttTasksData = _.filter(tasksData, t => t.id !== 1 && t.gantt_id === ganttId && t.type !== gantt.config.types.button);

          return innerObject.settings.progressCalc({
            taskLevels: tasksByLevels,
            progressType: projectConfig.progress,
            ganttTasksData
          });
        })
        .then(({tasksToUpdate}) => {
          return {...tasksToUpdate, ...taskToUpdate};
        })
        .then(resultMap => {
          return innerObject.handlers.packageTasksUpdate(resultMap, actionHash, ganttId);
        })
        .catch(innerObject.handlers.onProgressCalcFail);
    },
    // {Object} -> {Promise}
    checkProgressAfterParse: async function (taskToUpdate) {
      if (!app.config.mode.isBase || (taskToUpdate && taskToUpdate.id === 1)) {
        return Promise.resolve();
      }

      let actionHash = taskToUpdate ? taskToUpdate.actionHash : historyModel.generateActionHash();

      if (innerObject.settings.actionHash) {
        actionHash = innerObject.settings.actionHash;
        innerObject.settings.actionHash = null;
      }

      if (!taskToUpdate) {
        const ganttIDs = ganttViewModel.getGanttIDs();

        _.each(ganttIDs, async function (ganttID) {
          await innerObject.handlers.calculateProgressByGanttID(ganttID, actionHash, {});
        });
      } else {
        const taskToUpdateMap = {[taskToUpdate.id]: taskToUpdate};
        return await innerObject.handlers.calculateProgressByGanttID(taskToUpdate.gantt_id, actionHash, taskToUpdateMap);
      }
    },
  },
  settings: {
    progressType: "",
    taskDataBeforeUpdate: {},
    ignoreUpdate: false,
    tasksToUpdate: {}
  }
};

var outputObject = {
  calcFullProgressUpdate: innerObject.helpers.calcFullProgressUpdate,
  init: {
    run: innerObject.init.run,
  },
  handlers: {
    checkProgressAfterParse: innerObject.handlers.checkProgressAfterParse,
    reCalcProgressDuration: innerObject.handlers.reCalcProgressDuration,
  }
};

gantt.attachEvent("onParse", function () {
  innerObject.handlers.checkProgressAfterParse();
});

gantt.attachEvent("changeProgress", async function (updatedTask, needRefresh, oldTaskData) {
  const status = bindStatusAndProgressHelper.calc(null, updatedTask.progress * 100).status;

  updatedTask.progress = Math.round(updatedTask.progress * 100) / 100;

  if (gantt.isTaskExists(updatedTask.id)) {
    const ganttTask = gantt.getTask(updatedTask.id);
    innerObject.settings.oldTaskData = _.clone(ganttTask);
    if (ganttTask) {
      ganttTask.progress = updatedTask.progress;
    }
  } else if (oldTaskData) {
    innerObject.settings.oldTaskData = oldTaskData;
  }

  if (!needRefresh) {
    innerObject.settings.oldTaskData = globalStore.getters['tasksModel/getTaskByGanttId'](updatedTask.gantt_id, updatedTask.id);
  }

  globalStore.dispatch('tasksModel/updateTask', {
    taskChanges: updatedTask,
    taskId: updatedTask.id,
    ganttId: updatedTask.gantt_id
  });
  innerObject.settings.actionHash = historyModel.generateActionHash();

 if (updatedTask) {
   updatedTask.actionHash = innerObject.settings.actionHash;
 }

  if (updatedTask) {
    innerObject.settings.tasksToUpdate[updatedTask.id] = updatedTask;
    innerObject.settings.ganttId = updatedTask.gantt_id;

  }
  await innerObject.handlers.checkProgressAfterParse(updatedTask);
  innerObject.settings.oldTaskData = null;
  bindStatusAndProgressHelper.updateStatus(updatedTask, status);

  if (needRefresh && gantt.isTaskExists(updatedTask.id)) {
    gantt.refreshTask(updatedTask.id);
  }
});

gantt.attachEvent("changeStatus", async function (updatedTask, needRefresh, oldTaskData) {
  let ganttTask = oldTaskData;
  const status = updatedTask.status;

  if (gantt.isTaskExists(updatedTask.id)) {
    ganttTask = gantt.getTask(updatedTask.id);
  }

  innerObject.settings.oldTaskData = _.clone(ganttTask);

  updatedTask.progress = bindStatusAndProgressHelper.calc(status, null).progress;

  globalStore.dispatch('tasksModel/updateTask', {
    taskChanges: updatedTask,
    taskId: updatedTask.id,
    ganttId: updatedTask.gantt_id
  });

  if (needRefresh && gantt.isTaskExists(updatedTask.id)) {
    ganttTask.status = status;
    ganttTask.progress = updatedTask.progress;
    gantt.refreshTask(ganttTask.id);
  }

  innerObject.settings.ignoreUpdate = true;

  await globalStore.dispatch('tasksModel/changeStatus', {
    statusValue: status,
    taskData: updatedTask
  });

  if (updatedTask) {
    innerObject.settings.actionHash = historyModel.generateActionHash();
    innerObject.settings.tasksToUpdate[updatedTask.id] = updatedTask;
    innerObject.settings.ganttId = updatedTask.gantt_id;
  }

  innerObject.handlers.checkProgressAfterParse(updatedTask)
    .then(() => {
      innerObject.settings.oldTaskData = null;
      innerObject.settings.ignoreUpdate = false;
    });
});

app.on("progress:progressType:changed", function () {
  innerObject.handlers.checkProgressAfterParse();
});

export default outputObject;

