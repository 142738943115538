var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rightPopup", {
    staticClass: "bulk-changes-popup",
    attrs: {
      show: _vm.massChange && _vm.massChange.canUse && _vm.massChange.isOpen,
      "exclude-header": true,
      "exclude-footer": true,
      width: 300,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.massChange && _vm.massChange.canUse
              ? [
                  _c(
                    "div",
                    { ref: "header", attrs: { id: "massChangeHeader" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center",
                          class: [_vm.$style.p_lr_wrap],
                        },
                        [
                          _c("div", [
                            _c("div", { class: [_vm.$style.title] }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.locale.bulk_title) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { class: [_vm.$style.subtitle] }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.upperFirstLetter(_vm.locale.selected)
                                  ) +
                                  ": " +
                                  _vm._s(_vm.massChange.countSelected || 0) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [_vm.$style.close_btn],
                              on: { click: _vm.onClosePopup },
                            },
                            [
                              _c("sprite", {
                                attrs: { name: "close-1", type: "bold" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { class: _vm.$style.devider }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.massChange.showStub
                    ? _c("stub", { attrs: { type: "no-fields" } })
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "scroll",
                              rawName: "v-scroll.self",
                              value: _vm.onScroll,
                              expression: "onScroll",
                              modifiers: { self: true },
                            },
                          ],
                          key:
                            "masschange-" +
                            _vm.massChange.project_id +
                            _vm.massChange.isOpen +
                            _vm.resetSelected,
                          ref: "scrolled",
                          staticClass: "gantt_scroll",
                          class: [
                            _vm.$style.p_lr_wrap,
                            _vm.$style.scrolled_area,
                          ],
                          style: { "flex-grow": 2 },
                        },
                        _vm._l(_vm.massChange.getConfig(), function (opt) {
                          return _c("vgpLabelSlot", {
                            key: "bulk-" + opt.id,
                            attrs: { label: opt.label },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { class: [_vm.$style.item_p_wrap] },
                                        [
                                          opt.type === "select"
                                            ? _c("dropdownSelect", {
                                                key: "mass-select" + opt.id,
                                                attrs: {
                                                  "drop-list": opt.options,
                                                  multiselect: opt.multiselect,
                                                  "use-search": opt.useSearch,
                                                  "use-select-all": opt.custom,
                                                  "item-template":
                                                    opt.itemTemplate,
                                                  "track-by": "id",
                                                  "track-by-text": "value",
                                                  "track-by-img-url": "icon",
                                                  "group-by": "group",
                                                  placeholder:
                                                    _vm.locale.placeholder,
                                                  disabled:
                                                    _vm.isDisabledField(opt),
                                                  "track-by-status-color":
                                                    "color",
                                                  "show-selected-first":
                                                    opt.property &&
                                                    ![
                                                      "priority",
                                                      "status",
                                                    ].includes(opt.property),
                                                  "parent-selector-for-calc-position-drop":
                                                    ".drop-select",
                                                  "only-one-can-selected":
                                                    opt.onlyOneCanSelected ||
                                                    [],
                                                  "drop-position": "fixed",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onChange(
                                                      opt,
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          opt.type === "select-date"
                                            ? _c("dropdownSelectDate", {
                                                key: "mass-select" + opt.id,
                                                attrs: {
                                                  placeholder:
                                                    _vm.locale.placeholder,
                                                  disable:
                                                    _vm.isDisabledField(opt),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onChange(
                                                      opt,
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          opt.type === "select-color"
                                            ? _c("colorPalette", {
                                                key: "mass-select" + opt.id,
                                                attrs: {
                                                  colors: opt.options,
                                                  "select-field": "select",
                                                  "select-type": "single",
                                                  placeholder:
                                                    _vm.locale.placeholder,
                                                  "show-reset-btn": true,
                                                  "select-width": "100%",
                                                  "select-height": "32",
                                                  disabled:
                                                    _vm.isDisabledField(opt),
                                                  "outline-if-has-selected": false,
                                                  "drop-position": "fixed",
                                                  "illuminate-on-hover": true,
                                                },
                                                on: {
                                                  selectColors: function (
                                                    $event
                                                  ) {
                                                    return _vm.onChange(
                                                      opt,
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          opt.type === "input"
                                            ? _c("bulkInput", {
                                                key: "mass-select" + opt.id,
                                                attrs: {
                                                  item: opt,
                                                  placeholder:
                                                    _vm.locale.placeholder,
                                                  "is-disabled":
                                                    _vm.isDisabledField(opt),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.onChange(
                                                      opt,
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "footer",
                      class: [_vm.$style.p_b_wrap, _vm.$style.footer],
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isEndScrolledContainer,
                            expression: "!isEndScrolledContainer",
                          },
                        ],
                        class: _vm.$style.haze,
                      }),
                      _vm._v(" "),
                      _c("div", {
                        class: [_vm.$style.devider, _vm.$style.m_b_wrap],
                      }),
                      _vm._v(" "),
                      !_vm.massChange.showStub
                        ? _c("vgpButton", {
                            class: [_vm.$style.m_lr_wrap, _vm.$style.m_b_12],
                            attrs: {
                              type: "primary",
                              label: _vm.locale.apply_changes_btn,
                              disabled:
                                !_vm.massChange.hasChanges ||
                                _vm.disabledApllyButton,
                              width: 254,
                            },
                            on: { onClick: _vm.onApplyChanges },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.accessToDeleteButton
                        ? _c("vgpButton", {
                            class: [_vm.$style.m_lr_wrap],
                            attrs: {
                              disabled: !_vm.massChange.countSelected,
                              label: _vm.locale.delete_tasks_btn,
                              type: "destructive-border",
                              width: 254,
                            },
                            on: {
                              onClick: function ($event) {
                                _vm.showConfirmDelete = true
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("confirmPopup", {
                    attrs: {
                      show: _vm.showConfirmDelete,
                      text: _vm.locale.confirmDelete_body,
                      title: _vm.locale.confirmDelete_title,
                      "ok-button": _vm.confirm_okBtn,
                      "cancel-button": _vm.confirm_cancelBtn,
                    },
                    on: {
                      onClickOk: _vm.onDeleteSelectedTasks,
                      onClickCancel: function ($event) {
                        _vm.showConfirmDelete = false
                      },
                    },
                  }),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }