<template>
  <img
    :style="styles"
    class="avatar-item"
    :src="src ? src : defaultSrc"
    alt=""
  >
</template>

<script>
const defaultAva = `${GT.cdn}/imgs/default/default-ava.svg`;

export default {
  name: 'VgpAvatar',
  props: {
    src: {
      type: String,
      required: true,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
    border: {
      type: Boolean,
      required: false,
      default: false,
    },
    customSize: {
      type: Number || String,
      required: false,
    },
  },
  data() {
    return {
      defaultSrc: defaultAva,
    };
  },
  computed: {
    styles() {
      let height;
      let width;
      let minWidth;
      let border;
      let borderColor = 'transparent';

      if (this.size === 'small') {
        height = `${24}px`;
        width = `${24}px`;
        minWidth = `${24}px`;
        border = '1px solid';
      }

      if (this.size === 'medium') {
        height = `${32}px`;
        width = `${32}px`;
        minWidth = `${32}px`;
        border = '2px solid';
      }

      if (this.size === 'large') {
        height = `${40}px`;
        width = `${40}px`;
        minWidth = `${40}px`;
        border = '2px solid';
      }

      if (this.border) {
        borderColor = '#FFFFFF';
      }

      if (this.customSize) {
        height = `${this.customSize}px`;
        width = `${this.customSize}px`;
        minWidth = `${this.customSize}px`;
      }

      return {
        height,
        width,
        minWidth,
        border,
        borderColor,
      };
    },
  },
};
</script>

<style scoped lang='less'>
.avatar-item {
  border-radius: 50%;
  object-fit: cover;
}
</style>
