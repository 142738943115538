import BaseFilter from './BaseFilter';
import BaseProperties from './BaseProperties';
import ganttViewModel from '../../../models/ganttViewModel';
import _ from '../../../libs/lodash';
import app from '../../../app';
import routerHelper from '../../../helpers/router';
import globalStore from '$$store/main';

const _self = null;

class ProjectFilter extends BaseFilter {
  static filterByType(type, isExport) {
    if (!_self) {
      this._self = new ProjectFilter(type, isExport);
    }

    return this._self;
  }

  constructor(type, isExport) {
    super(type);
    this.viewId = null;
    this.isExport = isExport;
  }

  get _emptyState() {
    return {
      assignee: '',
      color: '',
      creationDate: null,
      endDate: null,
      id: 0,
      overdue: 0,
      priority: '',
      rangeDate: null,
      reporter: '',
      startDate: null,
      status: '',
      text: '',
      type: '',
    };
  }

  assignee() {
    const activeViewData = ganttViewModel.getActiveViewData();
    const viewGanttIDs = activeViewData ? (activeViewData.ganttIDs || [activeViewData.gantt_id]) : [];
    let resources = [];

    viewGanttIDs.forEach(ganttID => {
      const resourcesByGanttID = globalStore.getters['resourcesModel/getResourcesByProjectIdForUI'](ganttID);

      resources = _.unionBy(resources, resourcesByGanttID, 'id');
    });

    const virtual = [];
    const real = [];

    resources.forEach(i => {
      const item = { ...i };

      item.icon = i.picture || this.icons.resource;
      item.value = i.name;
      item.group = i.userId ? this.locales.realResource : this.locales.virtualResource;

      if (i.userId) real.push(item);
      else virtual.push(item);
    });

    return {
      property: 'assignee',
      label: __('filter_option_assignee_label'),
      placeholder: __('filter_task_assignee_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: [{
        id: '-1',
        value: this.locales.unassigned,
        icon: this.icons.unassigned,
      }, ...real, ...virtual],
    };
  }

  reporter() {
    const activeViewData = ganttViewModel.getActiveViewData();
    const viewGanttIDs = activeViewData ? (activeViewData.ganttIDs || [activeViewData.gantt_id]) : [];
    let realResources = [];

    viewGanttIDs.forEach(ganttID => {
      const resourcesByGanttID = globalStore.getters['resourcesModel/getRealResourcesByGanttId'](ganttID);

      realResources = _.unionBy(realResources, resourcesByGanttID, 'id');
    });

    const data = realResources.map(i => {
      const item = { ...i };

      item.icon = i.resourcePhoto || i.photo || this.icons.resource;
      item.value = i.name;

      return item;
    });

    return {
      property: 'reporter',
      label: __('filter_option_reporter_label'),
      placeholder: __('filter_task_reporter_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data,
    };
  }

  config() {
    const conf = [
      BaseProperties.taskName(),
      BaseProperties.projects('ganttId'),
      BaseProperties.taskTypes(),
      this.assignee(),
      BaseProperties.statuses(),
      BaseProperties.priority(),
      this.reporter(),
      BaseProperties.dateRange(),
      BaseProperties.startDate(),
      BaseProperties.endDate(),
      BaseProperties.creationDate(),
      BaseProperties.color(),
      BaseProperties.overdue(),
    ];

    if (this.project_id) {
      conf.splice(1, 1);
    }

    return conf;
  }

  init() {
    super.init();
    if (!this.isActiveFilter) {
      this.setFilterState(this._emptyState, true);
    }
    this.filterData();
  }

  filterData() {
    if (globalStore.getters['filter/isDisable']) return;
    if (!ganttViewModel.getActiveViewData()) return;

    const viewData = ganttViewModel.getActiveViewData();
    const clonedFilter = _.cloneDeep(this.filterState);

    if (this.isActiveFilter || this.filterState.customColumns) {
      if (!viewData.isGlobal) {
        this.model.updateFilterByActiveProject(viewData.id, clonedFilter);
      }
      setTimeout(() => {
        app.trigger('filter:set', clonedFilter);
      }, 0); // because in gantt not actual tasks after change route
    } else {
      this.model.clearFilterByActiveProject(viewData.id, clonedFilter);
      app.trigger('filter:clear');
    }
  }

  changeFilterState(filterState) {
    this.setFilterState(filterState);
    this.filterData();
  }

  refreshFilter() {
    this.changeFilterState(this.filterState);
  }

  selectSavedFilter(filter) {
    this.changeFilter(filter.id, filter.value);
    this.filterData();
  }

  resetFilter() {
    super.resetFilter(this._emptyState);
    this.filterData();
  }
}

export default ProjectFilter;
