var data = [
  {id: "YY-M-D", value: "YY-M-D"},
  {id: "YYYY-M-D", value: "YYYY-M-D"},
  {id: "YYYY-M-D H:m", value: "YYYY-M-D H:m"},
  {id: "YYYY-M-D H:m:s", value: "YYYY-M-D H:m:s"},

  {id: "M-D-YY", value: "M-D-YY"},
  {id: "M-D-YYYY", value: "M-D-YYYY"},
  {id: "M-D-YYYY H:m", value: "M-D-YYYY H:m"},
  {id: "M-D-YYYY H:m:s", value: "M-D-YYYY H:m:s"},

  {id: "D-M-YY", value: "D-M-YY"},
  {id: "D-M-YYYY", value: "D-M-YYYY"},
  {id: "D-M-YYYY H:m", value: "D-M-YYYY H:m"},
  {id: "D-M-YYYY H:m:s", value: "D-M-YYYY H:m:s"},

  {id: "YY/M/D", value: "YY/M/D"},
  {id: "YYYY/M/D", value: "YYYY/M/D"},
  {id: "YYYY/M/D H:m", value: "YYYY-M-D H:m"},
  {id: "YYYY/M/D H:m:s", value: "YYYY/M/D H:m:s"},

  {id: "M/D/YY", value: "M/D/YY"},
  {id: "M/D/YYYY", value: "M/D/YYYY"},
  {id: "M/D/YYYY H:m", value: "M/D/YYYY H:m"},
  {id: "M/D/YYYY H:m:s", value: "M/D/YYYY H:m:s"},

  {id: "D/M/YY", value: "D/M/YY"},
  {id: "D/M/YYYY", value: "D/M/YYYY"},
  {id: "D/M/YYYY H:m", value: "D/M/YYYY H:m"},
  {id: "D/M/YYYY H:m:s", value: "D/M/YYYY H:m:s"},


  {id: "YY.M.DD", value: "YY.M.D"},
  {id: "YYYY.M.D", value: "YYYY.M.D"},
  {id: "YYYY.M.D H:m", value: "YYYY.M.D H:m"},
  {id: "YYYY.M.D H:m:s", value: "YYYY.M.D H:m:s"},

  {id: "M.D.YY", value: "M.D.YY"},
  {id: "M.D.YYYY", value: "M.D.YYYY"},
  {id: "M.D.YYYY H:m", value: "M.D.YYYY H:m"},
  {id: "M.D.YYYY H:m:s", value: "M.D.YYYY H:m:s"},

  {id: "D.M.YY", value: "D.M.YY"},
  {id: "D.M.YYYY", value: "D.M.YYYY"},
  {id: "D.M.YYYY H:m", value: "D.M.YYYY H:m"},
  {id: "D.M.YYYY H:m:s", value: "D.M.YYYY H:m:s"}
];

export default {
  data: data
};


