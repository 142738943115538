import tasksMethods from './tasks';
import projectMethods from './projects';
import resourcesMethods from './resources';
import customFieldsMethods from './customFields';
import teamMethods from './team';
import historyMethods from './history';
import projectStatusesMethods from './projectStatuses';
import rolesMethods from './roles';
import progressMethods from './progressEvents';

export default {
  ...tasksMethods,
  ...projectMethods,
  ...customFieldsMethods,
  ...resourcesMethods,
  ...teamMethods,
  ...historyMethods,
  ...projectStatusesMethods,
  ...rolesMethods,
  ...progressMethods,
};
