<template>
  <portal
    :to="portalName"
  >
    <div
      ref="parent"
      :class="[
        $style.right_popup,
        $style.bottom_border,
        borderTop ? $style.top_border : '',
        show && withAnimation ? $style.show_right_popup_animated : '',
        show && !withAnimation ? $style.show_right_popup : '',
      ] "
      :style="{...styles, width: width+'px'}"
    >
      <slot name="body" />
    </div>
  </portal>
</template>

<script>

export default {
  name: 'RightPopup',
  components: { },
  props: {
    show: { type: Boolean, required: false, default: false },
    width: { type: Number, required: false, default: 300 },
    excludeFooter: { type: Boolean, required: false, default: false },
    excludeHeader: { type: Boolean, required: false, default: false },
    excludeHeaderToolbar: { type: Boolean, required: false, default: false },
    borderTop: { type: Boolean, required: false, default: true },
    borderLeft: { type: Boolean, required: false, default: true },
    customHeaderHeigth: { type: Number, required: false, default: null },
    portalName: { type: String, default: 'right-popup' },
    withAnimation: { type: Boolean, default: true },
  },

  data() {
    return {
      popupHeight: 0,
      styles: {},
    };
  },

  computed: {
    headerHeight() {
      if (this.customHeaderHeigth) {
        return this.customHeaderHeigth;
      }

      return this.$store ? this.$store.getters['headerView/height'] : 0;
    },

    headerToolbarHeigh() {
      return 40;
    },

    footerHeight() {
      return this.$store ? this.$store.getters['footer/height'] : 0;
    },

  },

  watch: {
    show() {
      this.calcStyles();
    },
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(this.calcStyles);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.calcStyles();
    },

    calcStyles() {
      let top = 0;
      let bottom = 0;
      const clientHeight = document.documentElement.clientHeight;
      let borderLeft = '1px solid  #D9D9D9';

      if (this.excludeHeaderToolbar) {
        const borderTopHeight = this.borderTop ? 1 : 0;

        top = this.headerHeight - this.headerToolbarHeigh - borderTopHeight;
      }

      if (this.excludeHeader) {
        top = this.headerHeight;
      }

      if (this.excludeFooter) {
        bottom = this.footerHeight - 1;
      }

      if (!this.borderLeft) {
        borderLeft = '1px solid transparent';
      }

      this.styles = {
        top: `${top}px`,
        bottom: `${bottom}px`,
        borderLeft,
        height: `${clientHeight - bottom - top}px`,
      };
    },
  },
};
</script>

 <style module lang="less">
 @import '../../../../../less/_fonts.less';

.right_popup{
  position: fixed;
  right: -100%;
  // z-index: 50;
  z-index: 31;
  min-width: 200px;
  background-color: #ffffff;
  box-shadow: -20px 0px 22px -22px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  font-family: inherit;
  .FontLatoRegular();
  overflow: hidden;

  &.show_right_popup{
    right: 0;
  }

  &.show_right_popup_animated {
    right: 0;
    transition: right .6s ease-out;
  }

  &.bottom_border:after,
  &.top_border:before {
    display: block;
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
  }

  &.bottom_border:after{
    bottom: 0;
    border-bottom: 1px solid #D9D9D9;
  }

  &.top_border:before{
    top: 0;
    border-top: 1px solid #D9D9D9;
  }
}
 </style>
