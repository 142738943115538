var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["learning-center-news-tab-wrapper"] }, [
    _vm.newsItems.length
      ? _c(
          "div",
          { staticClass: "gantt_scroll", class: _vm.$style["news-list"] },
          _vm._l(_vm.newsItems, function (newsItem) {
            return _c("div", { class: _vm.$style["news-item"] }, [
              _c("div", { class: _vm.$style["content__wrap"] }, [
                _c("div", { class: _vm.$style.content }, [
                  _c("div", { class: _vm.$style["content__date"] }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatDate(newsItem.date)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h2", { class: _vm.$style["content__title"] }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(newsItem.header) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    class: _vm.$style["content__description"],
                    domProps: { innerHTML: _vm._s(newsItem.content) },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._b(
                    {
                      class: [
                        _vm.$style["image-wrap"],
                        newsItem.videoData
                          ? _vm.$style["image-wrap_play-icon"]
                          : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.handleImageClick(newsItem, $event)
                        },
                      },
                    },
                    "div",
                    newsItem.videoData,
                    false
                  ),
                  [
                    newsItem.imageSrc
                      ? _c("img", {
                          attrs: { src: newsItem.imageSrc, alt: "News image" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style["content__delimiter"] }),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }