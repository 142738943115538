/* eslint-disable no-undef, no-param-reassign, no-unused-vars */
// A mass change data-model massChange.js
// todo: getRealActionIds

import app, { appIds } from '../../../app';

import filterModel from '../../../models/filters';
import projectsModel from '../../../models/projects';
import progressModule from './progress';

import routerHelper from '../../../helpers/router';
import constants from '../../../helpers/constants';
import colorHelper from '../../../helpers/color';
import statusHelper from '../../../helpers/status';

import bindStatusAndProgressHelper from '../../../helpers/bindStatusAndProgress';
import { isAnyPopupOpened } from '../../../models/onBoarding/popups'; // !FIXME
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from "../../../store/main";
import moment from "../../../libs/moment";

const {
  events: {
    ID_EVENT_MASSCHANGE_UPDATE_TASKS, ID_EVENT_MASSCHANGE_SET_ACTIVE_TASK,
    ID_EVENT_MASSCHANGE_UNSET_ACTIVE_TASK, ID_EVENT_MASSCHANGE_UPDATESTATE,
    ID_EVENT_APP_ROUTECHANGED, ID_EVENT_POPUPS_CHANGED, ID_EVENT_TASKS_MASSUPDATE,
    ID_EVENT_TASKS_MASSDELETE
  },
  popupViews: {
    ID_VIEW_POPUP_CUSTOMGRID,
  },
} = appIds;

const _actionTypes = {
  ASSIGN: 0,
  STATUS: 1,
  PRIORITY: 2,
  COLOR: 3,
  CUSTOM_COL: 4,
  REMOVE: 5,
  EXPAND_COLLAPSE: 6,
  EXPAND_COLLAPSE_MULTIVIEW: 7,
  CASCADE_SORT: 8,
};

let _helpers = null;

_helpers = {
  /**
   * @recursive Creates the full children hash starting at selected lvl with its content.
   *
   * @param {Number} lvl - the starting level value
   * @param {Array} taskIds - the starting level content
   * @return {Object} - the composed by levels result-hash.
   */
  getFullChildrenTreeAtLvl: (lvl, taskIds) => {
    const nextLvlIds = taskIds.reduce((result, idTask) => {
      const childIds = gantt.getChildren(idTask).filter(idChildTask => {
        const taskEntry = gantt.getTask(idChildTask);

        return taskEntry && ['task', 'project'].indexOf(taskEntry.type) !== -1;
      }).map(id => +id);

      return result.concat(childIds);
    }, []);

    const nextLvlData = nextLvlIds.length > 0
      ? _helpers.getFullChildrenTreeAtLvl(lvl + 1, nextLvlIds) : {};

    return {
      [lvl]: taskIds,
      ...nextLvlData,
    };
  },
  /**
   * @private Composes a hash-by-levels for given array of task id's
   * @param {Array} taskIds - an array of task id's
   * @return {Object} - the result hash of task-level arrays
   */
  getTasksByLvls: taskIds => taskIds.reduce((result, idTask) => {
    const lvl = gantt.calculateTaskLevel(idTask);
    const byLvl = result[lvl];

    return {
      ...result,
      [lvl]: byLvl ? byLvl.concat(idTask) : [idTask],
    };
  }, {}),
  // !TODO
  composeRmPatch: taskIds => {
    const taskIdsByLvls = _.omit(_helpers.getTasksByLvls(taskIds), 0);
    // const this = this;
    const rmTreesBylvlKeysSorted = Object.keys(taskIdsByLvls).map(k => parseInt(k, 10)).sort((e1, e2) => e1 - e2);
    const idsByLvlCopy = _.cloneDeep(taskIdsByLvls);
    const fullRmPathByLvls = {};

    const rmTreesBylvl = rmTreesBylvlKeysSorted.reduce((result, lvl) => {
      result[lvl] = _helpers.getFullChildrenTreeAtLvl(lvl, taskIdsByLvls[lvl]);

      return result;
    }, {});

    if (rmTreesBylvlKeysSorted.length === 1) {
      return {
        fullData: rmTreesBylvl[rmTreesBylvlKeysSorted[0]],
        meta: idsByLvlCopy,
      };
    }

    rmTreesBylvlKeysSorted.forEach((rmTreeKeyVal, rmTreeKeyInd) => {
      const currentRmTree = rmTreesBylvl[rmTreeKeyVal];
      const curTreeKeysSorted = Object.keys(currentRmTree).map(k => parseInt(k, 10)).sort((e1, e2) => e1 - e2);

      curTreeKeysSorted.forEach(treeLvlVal => {
        const idsToRemove = currentRmTree[treeLvlVal];

        if (!fullRmPathByLvls[treeLvlVal]) {
          fullRmPathByLvls[treeLvlVal] = [];
        }

        idsToRemove.forEach(rmTaskId => {
          const ind1 = fullRmPathByLvls[treeLvlVal].indexOf(rmTaskId);

          if (ind1 === -1) {
            fullRmPathByLvls[treeLvlVal].push(rmTaskId);
          } else if (treeLvlVal === rmTreeKeyVal) {
            const ind2 = idsByLvlCopy[rmTreeKeyVal].indexOf(rmTaskId);

            if (ind2 !== -1) {
              idsByLvlCopy[rmTreeKeyVal].splice(ind2, 1);
            }
          }
        });
        // fullRmPathByLvls[treeLvlVal] = idsToRemove
      });
    });

    return {
      fullData: fullRmPathByLvls,
      meta: idsByLvlCopy,
    };
  },
  /**
   * @private !TODO: description
   * @param {Array} parentIds - parent tasks identifiers list
   * @return {Array} - list of buttons (task) identifiers
   */
  getParentTasksButtonIds(parentIds) {
    return parentIds.reduce((result, idTask) => {
      const childIds = gantt.getChildren(idTask);

      const parentBtnIds = childIds.filter(idChildTask => {
        const childTaskEntry = gantt.getTask(idChildTask);

        return childTaskEntry && childTaskEntry.type === gantt.config.types.button;
      });

      return result.concat(parentBtnIds);
    }, []);
  },
  /**
   * @private Composes an update (if required) of affected parent-tasks for the provied children list
   * @param {Array <Number>} taskIdsToRemove - an array of child identifiers to remove
   * @return {Array <Object>} - the result parents update
   */
  composeParentsUpdate(taskIdsToRemove) {
    const updateData = {};
    const idGantt = projectsModel.getActiveGanttId();
    const projEntry = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](idGantt);

    taskIdsToRemove.forEach(idTask => {
      const { parent, duration, start_date } = gantt.getTask(idTask);
      const { id, /* start_date, */ $level, type /* , duration */ } = gantt.getTask(parent);

      if (!updateData[parent]) {
        updateData[parent] = {
          update: {
            idTask: id,
            type: gantt.config.types.task,
            duration,
            progress: 0,
            end_date: gantt.calculateEndDate(start_date, duration),
            start_date,
          },
          rmChildIds: [],
        };
      }

      updateData[parent].rmChildIds.push(idTask);
    });

    const parentsToUpdate = [];
    const parentsToRemove = [];
    Object.keys(updateData).forEach(id => {
      const idParent = parseInt(id, 10);
      const allParentChildren = globalStore.getters['tasksModel/getChildByTask'](idGantt, idParent);
      const isParentProject = projEntry.id === idParent;

      if (allParentChildren.length === updateData[idParent].rmChildIds.length && !isParentProject) {
          parentsToUpdate.push(updateData[idParent].update);
      }
    });

    return { parentsToUpdate, parentsToRemove };
  },
};

// actions: [{"actionType":1,"actionPayload":{"tasks":[{"idTask":20621190,"progress":0.5,"status":2},{"idTask":20621191,"progress":0.35,"status":2},{"idTask":20621195,"progress":0.5,"status":2},{"idTask":20621196,"progress":0.5,"status":"2"},{"idTask":20621197,"progress":0.5,"status":"2"}]}}]
// actions: [{"actionType":6,"actionPayload":{"tasks":[{"idTask":20621195,"open":1}]}}]

// dataComposer -> how to compose data before sending request
// queryHandler -> how to update UI after success request
const _actionsConfig = {
  [_actionTypes.EXPAND_COLLAPSE]: {
    async dataComposer(ganttId, payload) {
      const open = payload.open ? 1 : 0;
      const user_id = payload.user_id;

      return {
        tasks: payload.gantt_tasks_id.map(gantt_tasks_id => ({ gantt_tasks_id, user_id, open })),
      };
    },
    async queryHandler(ganttId, payload) {
      const { tasks } = payload;
      tasks.forEach(task => {
        const ganttTask = gantt.getTask(task.gantt_tasks_id);

        if (ganttTask) {
          ganttTask.$open = task.open;
          ganttTask.open = task.open;
        }
        globalStore.dispatch('tasksModel/updateTask', {
          taskChanges: ganttTask,
          taskId: ganttTask.id,
          ganttId
        });
      });
    },
  },
  [_actionTypes.EXPAND_COLLAPSE_MULTIVIEW]: {
    async dataComposer(ganttId, payload) {
      const open = payload.open ? 1 : 0;
      const user_id = payload.user_id;
      const multiview_id = payload.multiview_id;

      return {
        tasks: payload.gantt_tasks_id.map(task_id => ({
          multiview_id, task_id, user_id, open,
        })),
      };
    },
    async queryHandler(ganttId, payload) {
      const { tasks } = payload;

      tasks.forEach(task => {
        const ganttTask = gantt.getTask(task.task_id);

        if (ganttTask) {
          ganttTask.$open = task.open;
          ganttTask.open = task.open;
        }

        ganttViewModel.updateMultiviewValuesByTaskID(ganttTask);
      });
    },
  },
  [_actionTypes.CASCADE_SORT]: {
    async dataComposer(ganttId, payload) {
      return {
        tasks: payload,
      };
    },
    async queryHandler(ganttId, payload) {
      const { tasks } = payload;
      let needClearAndParse = false;

      tasks.forEach(task => {
        const ganttTask = gantt.getTask(task.id);

        if (ganttTask && ganttTask.sortorder !== task.sortorder) {
          ganttTask.sortorder = task.sortorder;
          globalStore.dispatch('tasksModel/updateTask', {
            taskChanges: ganttTask,
            taskId: ganttTask.id,
            ganttId: ganttTask.gantt_id
          });
          needClearAndParse = true;
        }
      });
      needClearAndParse && gantt.callEvent('ganttClearAndParse', []);
    },
  },
  [_actionTypes.ASSIGN]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      const resToAddIds = payload.value === '-1' ? [] : payload.value.trim().split(',').map(e => parseInt(e, 10));
      const timeTypeResourcesToAddCount = resToAddIds.filter(id => {
        const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](id, ganttId);

        return resourceTypeOnProject === constants.RESOURCE_TIME_TYPE;
      }).length;
      const tasksResources = [];
      const projectTasksMap = globalStore.getters['tasksModel/getProjectsTasksMap'][ganttId];

      payload.tasks.forEach(idTask => {
        const taskData = projectTasksMap[idTask];

        if (!taskData.id || taskData.type === constants.TASK_TYPES.project) {
          return;
        }

        const resourceIdsToRm = globalStore.getters['tasksModel/getResourcesForTask'](ganttId, idTask).map(entry => entry.resource_id);
        const resourcesToAdd = [];
        let taskEstimation = 0;

        resToAddIds.forEach((idResource, i) => {
          const resourceTypeOnProject = globalStore.getters['resourcesModel/getResourceTypeOnProject'](idResource, ganttId);
          let resourceValue = 0;

          if (resourceTypeOnProject === constants.RESOURCE_TIME_TYPE) {
            resourceValue = gantt.estimationCalculator.preCalculateEstimationForResource(taskData, timeTypeResourcesToAddCount, timeTypeResourcesToAddCount === i + 1);
            taskEstimation += resourceValue;
          }

          resourcesToAdd.push({
            idResource,
            value: resourceValue,
          });
        });

        let taskChanges = {
          estimation: taskData.estimation || taskEstimation || Math.round((taskData.duration / 60) * 100) / 100,
        };

        if (!resourcesToAdd.length) {
          taskChanges = gantt.estimationCalculator.calculateTaskChangesAfterAssign(taskData, [])
            || { estimation: taskData.estimation };
        }

        tasksResources.push({
          idTask,
          resourceIdsToRm,
          resourcesToAdd,
          taskData,
          taskChanges,
        });
      });

      return {
        tasksResources,
      };
    },
    // {Number, Object} -> {Promise} !FIXME
    // TODO probably unused after new collaboration
    async queryHandler(ganttId, payload, resources) {
      const cItem = globalStore.getters['tasksModel/getItem'](ganttId);
      const projectConfig = projectsModel.getProjectConfig(ganttId);

      if (cItem) {
        cItem.resourcesToTasks = resources;
        globalStore.dispatch('tasksModel/updateProjectTasksData', cItem);
      }

      let isUpdatedTasksByWorker = false;

      if (+projectConfig.estimation_mode === gantt.estimationCalculator.MODES.fixEstimation) {
        isUpdatedTasksByWorker = await gantt.ganttWorker.calculate(payload.tasksResources, 'afterMassChangeAssign', 'massCalculateTasksDuration');
      }

      // if (isUpdatedTasksByWorker) {
      //   return;
      // }

      const taskResources = payload.tasksResources;

      taskResources.forEach(taskResEntry => {
        const taskEntry = globalStore.getters['tasksModel/getTaskByGanttId'](ganttId, taskResEntry.idTask);
        const ganttTaskEntry = gantt.getTask(taskResEntry.idTask);

        if (!taskEntry) {
          console.warn('invalid taskEntry', taskResEntry.idTask); // !WARN
        }

        Object.assign(taskEntry, taskResEntry.taskChanges);

        if (ganttTaskEntry) {
          Object.assign(ganttTaskEntry, taskResEntry.taskChanges);
          ganttTaskEntry.resources = taskEntry.resources;
        }
      });
      gantt.callEvent('onParse');
    },
  },
  [_actionTypes.REMOVE]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      const { fullData, meta } = _helpers.composeRmPatch(payload.tasks);

      const idsForUpdateArr = Object.keys(meta).reduce((result, lvl) => result.concat(meta[lvl]), []);
      const idsToRemoveArr = Object.keys(fullData).reduce((result, lvl) => result.concat(fullData[lvl]), []);

      const parentsData = _helpers.composeParentsUpdate(idsForUpdateArr);
      const updParentsData = parentsData.parentsToUpdate;
      const delParentsData = parentsData.parentsToRemove;
      const rmButtonsData = _helpers.getParentTasksButtonIds(updParentsData.map(({ idTask }) => idTask));

      const fullProgressUpdate = await progressModule.calcFullProgressUpdate(
        ganttId,
        updParentsData.map(({ idTask, progress }) => ({ idTask, progress })),
        idsToRemoveArr,
      );

      return {
        taskIdsToRemove: [...idsToRemoveArr, ...delParentsData],
        rmButtonsData,
        parentsData: updParentsData,
        delParentsData,
        progressData: fullProgressUpdate.map(entry => ({
          ...entry,
          status: bindStatusAndProgressHelper.calc(null, entry.progress * 100).status,
        })),
      };
    },
    // {Number, Object} -> {Promise}
    async queryHandler(ganttId, payload) {
      const {
        taskIdsToRemove, parentsData, rmButtonsData, progressData,
      } = payload;
      const idsToRemove = [...taskIdsToRemove, ...rmButtonsData];

      parentsData.forEach(t => {
        t.id = t.idTask;
        t.gantt_id = ganttId;
        t.start_date = moment(t.start_date, constants.TASK_DATES_FORMAT).toDate();
        t.end_date = moment(t.end_date, constants.TASK_DATES_FORMAT).toDate();
        delete t.idTask;
      })

      globalStore.commit('tasksModel/deleteTasks', {projectId: ganttId, taskIds: idsToRemove})
      app.trigger(ID_EVENT_TASKS_MASSDELETE, ganttId, idsToRemove);
      globalStore.commit('tasksModel/updateTasks', parentsData);
      app.trigger(ID_EVENT_TASKS_MASSUPDATE, ganttId, parentsData);
      app.trigger(ID_EVENT_TASKS_MASSUPDATE, ganttId, progressData.map(({ idTask, progress }) => ({ id: idTask, progress })));
    },
  },
  [_actionTypes.STATUS]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      const { tasks, value } = payload;
      const progressVal = bindStatusAndProgressHelper.calc(value, null).progress;

      const relevantTasks = tasks.filter(idTask => {
        const taskEntry = gantt.getTask(idTask);

        return taskEntry && ['task', 'milestone'].indexOf(taskEntry.type) !== -1;
      });

      const progressBatch = relevantTasks.map(idTask => ({ idTask, progress: progressVal }));
      const tasksProgress = await progressModule.calcFullProgressUpdate(ganttId, progressBatch);

      return {
        tasks: tasksProgress.map(entry => ({
          ...entry,
          status: tasks.find(idTask => idTask === entry.idTask)
            ? value : bindStatusAndProgressHelper.calc(null, entry.progress * 100).status,
        })),
      };
    },
    // {Number, Object} -> {Promise}
    async queryHandler(ganttId, payload) {
      const { tasks } = payload;
      const cItem = globalStore.getters['tasksModel/getItem'](ganttId);

      if (cItem) {
        tasks.forEach(({ idTask, progress, status }) => {
          if (cItem.customValues[idTask]) {
            cItem.customValues[idTask].status = status;
          } else {
            cItem.customValues[idTask] = { status };
          }

          const task = gantt.getTask(idTask);

          if (task) {
            task.progress = +progress;
            task.status = +status;
          }

          globalStore.dispatch('tasksModel/updateTask', {
            taskChanges: task,
            taskId: task.id,
            ganttId
          });
        });
        globalStore.dispatch('tasksModel/updateProjectTasksData', cItem);
        gantt.callEvent('onParse');
      }
    },
  },
  [_actionTypes.PRIORITY]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      return payload;
    },
    // {Number, Object} -> {Promise}
    async queryHandler(ganttId, payload) {
      const cItem = globalStore.getters['tasksModel/getItem'](ganttId);
      const { tasks, value } = payload;

      if (cItem) {
        tasks.forEach(idTask => {
          const ganttTask = gantt.isTaskExists(idTask) && gantt.getTask(idTask);

          if (ganttTask) {
            ganttTask.priority = +value;
          }

          if (cItem.customValues[idTask]) {
            cItem.customValues[idTask].priority = value;
          } else {
            cItem.customValues[idTask] = {
              priority: value,
            };
          }
        });
        globalStore.dispatch('tasksModel/updateProjectTasksData', cItem);
        gantt.callEvent('onParse');
      }
    },
  },
  [_actionTypes.CUSTOM_COL]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      const value = payload[0].actionPayload.value;
      if (value.length > 2048) {
        payload[0].actionPayload.value = value.substr(0, 2048);
      }
      return payload.map(({ actionId, actionPayload }) => ({
        actionType: _actionTypes.CUSTOM_COL,
        actionPayload: { actionId, ...actionPayload },
      }));
    },
    // {Number, Array} -> {Promise}
    async queryHandler(ganttId, payload) {
      const actsByTasks = {};

      payload.forEach(action => {
        const { actionId, value, tasks } = action;

        tasks.forEach(idTask => {
          if (!actsByTasks[idTask]) {
            actsByTasks[idTask] = [];
          }

          actsByTasks[idTask].push({ actionId, value });
        });
      });

      const resultArr = Object.keys(actsByTasks).map(idTask => ({
        idTask,
        actions: actsByTasks[idTask],
      }));

      gantt.callEvent('onMassChangeCustomValues', [resultArr]); // !FIXME: ?event is not needed?
      app.trigger('massChange:update:customValues', {tasksId: [...payload[0].tasks], projectId: payload[0].projectId } );
    },
  },
  [_actionTypes.COLOR]: {
    // {Number, Object} -> {Promise}
    async dataComposer(ganttId, payload) {
      return {
        ...payload,
        value: colorHelper.getColorId(payload.value),
      };
    },
    // {Number, Object} -> {Promise}
    async queryHandler(ganttId, payload) {
      const { tasks, value } = payload;

      tasks.forEach(idTask => {
        const taskEntry = globalStore.getters['tasksModel/getTaskByGanttId'](ganttId, idTask);
        taskEntry.color = value;
        globalStore.dispatch('tasksModel/updateTask', {
          taskChanges: taskEntry,
          taskId: taskEntry.id,
          ganttId
        });

        gantt.getTask(idTask).color = value;
      });
      gantt.render();
    },
  },
};

// !FIXME
const _api = {
  execMassChange: (actions, ganttId, noHistoryFlag) => webix.ajax()
    .post('/api/masschange/actions', {
      actions,
      gantt_id: ganttId,
      no_history_flag: noHistoryFlag,
    })
    .then(response => response.json()),
};

const MassChangeModel = function (actionsCfg, api) {
  this._selectedTaskIds = {};
  this._activeFilterData = null;
  this._isEnabled = false;
  this._idProject = null;
  this._actionsCfg = actionsCfg;
  this._api = api;
  this._idActiveGridTask = null;
};

MassChangeModel.prototype.setActive = function (idTask) {
  this._idActiveGridTask = idTask;

  app.trigger(ID_EVENT_MASSCHANGE_SET_ACTIVE_TASK, idTask);
};

MassChangeModel.prototype.unsetActive = function () {
  this._idActiveGridTask = null;
  this._idProject = null;

  app.trigger(ID_EVENT_MASSCHANGE_UNSET_ACTIVE_TASK);
};

MassChangeModel.prototype.getActive = function () {
  return this._idActiveGridTask;
};

/**
 * @private
 * @param {Array} succeedActions
 * @param {Object} resources
 * @return {Promise}
 */
MassChangeModel.prototype._processQueryActionsRes = async function (ganttId, succeedActions, resources) {
  const { _actionsCfg } = this;
  const customColResults = [];

  for (let i = 0, len = succeedActions.length; i < len; i++) {
    const { actionType, actionPayload } = succeedActions[i].action; // !FIXME { action, result }

    if (actionType === _actionTypes.CUSTOM_COL) {
      customColResults.push(actionPayload);
      continue;
    }

    const handler = _actionsCfg[actionType] && _actionsCfg[actionType].queryHandler;

    if (!handler) {
      console.warn('!handler'); // !WARN
      continue;
    }

    // const handlerResult =
    await handler(ganttId, actionPayload, resources);
  }

  if (customColResults.length > 0 && _actionsCfg[_actionTypes.CUSTOM_COL] && _actionsCfg[_actionTypes.CUSTOM_COL].queryHandler) {
    _actionsCfg[_actionTypes.CUSTOM_COL].queryHandler(ganttId, customColResults);
  }
};

/**
 * @public Executes provided actions one after another
 * @param {Array <Object>} actionsData - array of actions to execute
 * @param {Numbrer} ganttId - active gantt project identifier
 * @return {Promise}
 */
MassChangeModel.prototype.executeActions = async function (actionsData, ganttId) {
  let composedActions = [];
  const { _actionsCfg } = this;

  if (!Array.isArray(actionsData)) {
    throw new Error('invalid actionsData');
  }

  userExtAnalytics.log('masschange_execute_actions_start');

  statusHelper.handlers.showMainSpinner(); // !FIXME

  for (let i = 0, len = actionsData.length; i < len; i++) {
    const { actionType, actionPayload } = actionsData[i];
    const composer = _actionsCfg[actionType] && _actionsCfg[actionType].dataComposer;

    if (!composer) {
      console.warn('no composer for action ', actionType); // !WARN
      continue;
    }

    // const composedPayload = await composer(ganttId, this.getSelectedList(), actionPayload);

    const composedPayload = await composer(ganttId, actionPayload);
    const result = Array.isArray(composedPayload) ? composedPayload : { actionType, actionPayload: composedPayload };

    composedActions = composedActions.concat(result);
  }

  const queryResult = await _api.execMassChange(composedActions, ganttId);
  const { resources, actions: { succeeded } } = queryResult;

  // ------ only for !TEST
  // console.warn('composedActions', composedActions); // !DEBUG
  // const queryResult = {
  //   actions: { succeeded: composedActions.map(action => ({ action })) },
  //   ganttId: ganttId
  // };
  // const resources = {};
  // const { actions: { succeeded } } = queryResult;
  // // ------ only for !TEST

  await this._processQueryActionsRes(queryResult.ganttId, succeeded, resources);

  statusHelper.handlers.hideMainSpinner(); // !FIXME

  this.disable();

  userExtAnalytics.log('masschange_execute_actions_finish');
};

/**
 * @public !TODO: description
 */
MassChangeModel.prototype.enable = function () {
  const currentRoute = routerHelper.getCurrentRoute(); // !FIXME: helper
  const activeGanttId = projectsModel.getActiveGanttId(); // !FIXME: model

  // validations
  if (gantt.isEditingState()) {
    console.warn('MassChangeModel.prototype.enable -> isEditingState'); // !WARN
    return;
  }

  if (currentRoute.name !== 'project' || !['gantt', 'list'].includes(currentRoute.params.mode)  ) {
    console.warn('[MassChangeModel.prototype.enable] -> invalid route'); // !WARN
    return;
  }

  if (projectsModel.isArchived(activeGanttId)) {
    console.warn('MassChangeModel.prototype.enable -> is archived project'); // !WARN
    return;
  }

  userExtAnalytics.log('masschange_enable');

  // "isMassChangeSelected" can be executed inside gantt plugins (required models are unavailable)
  gantt.isMassChangeSelected = this._isTaskSelected.bind(this);

  this._activeFilterData = {};

  gantt.$data.tasksStore.visibleOrder.forEach(taskId => {
    const task = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

    if (task) {
      this._activeFilterData[taskId] = task;
    }
  });

  this._idProject = currentRoute.projectId || +currentRoute.params.projectId;
  this._isEnabled = true;

  gantt.config.readonly = true; // !FIXME: location + use otside this class
  gantt.config.masschange = true; // !FIXME: location + use otside this class

  app.trigger('gantt:keyboard:disable'); // !FIXME: use otside this class
  app.trigger(ID_EVENT_MASSCHANGE_UPDATESTATE, true);
};

MassChangeModel.prototype.updateFiltredTasksForGantt = function() {
  this._activeFilterData = {};

  gantt.$data.tasksStore.visibleOrder.forEach(taskId => {
    const task = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

    if (task) {
      this._activeFilterData[taskId] = task;
    }
  });
}

/**
 * @public !TODO: description
 */
MassChangeModel.prototype.disable = function () {
  this._activeFilterData = null;
  this._selectedTaskIds = {};
  this._isEnabled = false;
  this._idProject = null;

  // cleanup binded func for outside execution
  gantt.isMassChangeSelected = null;

  gantt.config.readonly = false; // !FIXME: location + use otside this class
  gantt.config.masschange = false; // !FIXME: location + use otside this class

  app.trigger('gantt:keyboard:enable'); // !FIXME: use otside this class
  app.trigger(ID_EVENT_MASSCHANGE_UPDATESTATE, false);

  userExtAnalytics.log('masschange_disable');
};

// {} -> {Boolean}
MassChangeModel.prototype.isEnabled = function () {
  return this._isEnabled;
};

/**
 * @private
 * @param {Number}
 * @return {Boolean}
 */
MassChangeModel.prototype._isTaskSelected = function (idTask) {
  return this._selectedTaskIds[idTask] && this._selectedTaskIds[idTask].isSelected;
};

/**
 * @private !TODO: description
 * @param {Number} idTask - gantt task identifier
 * @return {Boolean} - true is the task is valid for selection/unselection
 */
MassChangeModel.prototype._isValidSelectionTask = function (idTask, parentId) {
  const taskInGantt = parentId ? gantt.getTask(parentId) : null;
  const isOpenedParent = parentId && taskInGantt && taskInGantt.type === 'project' && taskInGantt.$open;
  const taskEntry = gantt.getTask(idTask);// globalStore.getters['tasksModel/getTask'](idTask);

  if(!taskEntry)
    return false;

  const currentRoute = routerHelper.getCurrentRoute();

  if(currentRoute.name === 'project' && currentRoute.params.mode === 'list' ){
    let isCurrentProject = taskEntry.gantt_id === this._idProject;
    let isRightEntity = ['task', 'project', 'milestone'].indexOf(taskEntry.type) !== -1
    return isCurrentProject && isRightEntity;
  }

  const inFlt = this._activeFilterData && isOpenedParent ? !!this._activeFilterData[idTask] : true;

  return ['task', 'project', 'milestone'].indexOf(taskEntry.type) !== -1 && inFlt;
};

/**
 * @private !TODO: description
 * @param {Number}
 * @param {Number}
 * @return {Array}
 */
MassChangeModel.prototype._unselectTasksRec = function (idTask, deep = 1) {
  const { _selectedTaskIds } = this;

  let res = [];

  if (!this._isValidSelectionTask(idTask) || deep === 0) {
    return [];
  }

  if (_selectedTaskIds[idTask] && deep === 1 && _selectedTaskIds[idTask].nChildren > 0) {
    _selectedTaskIds[idTask].isSelected = false;
    _selectedTaskIds[idTask].allSelected = false;

    res = [idTask];
  } else if (_selectedTaskIds[idTask]) {
    delete _selectedTaskIds[idTask];
    res = [idTask];
  }

  return gantt.getChildren(idTask).reduce((result, idChild) => result.concat(this._unselectTasksRec(idChild, deep - 1)), res);
};

/**
 * @private Selects the specified task and its children (if needed)
 * @param {Number} idTask - a task identifier
 * @param {Number} deep - if 0 selects only the specified task (without children)
 * @return {Array} - newly selected tasks identifiers list
 */
MassChangeModel.prototype._selectTasksRec = function (idTask, deep = 0) {
  const { _selectedTaskIds } = this;

  let res = [];

  if (!this._isValidSelectionTask(idTask)) {
    return [];
  }

  if (!_selectedTaskIds[idTask]) {
    _selectedTaskIds[idTask] = {};
  }

  const taskEntry = _selectedTaskIds[idTask];

  taskEntry.isSelected = true;

  const childIds = [];

  // after d&d in gantt, the getChildren() can returnt string types
  gantt.getChildren(idTask).forEach(id => {
    const idChild = typeof id === 'number' ? id : parseInt(id, 10);

    if (this._isValidSelectionTask(idChild, idTask)) {
      childIds.push(idChild);
    }
  });

  // const childIds = gantt.getChildren(idTask).filter(idChild => this._isValidSelectionTask(idChild)); // it works

  if (typeof taskEntry.nChildren === 'undefined') {
    taskEntry.nChildren = childIds.length;
    // taskEntry.nSelected = (deep === 0)
    //   ? childIds.filter(idChild => !!_selectedTaskIds[idChild]).length : childIds.length;
  }

  taskEntry.allSelected = (deep === 0) ? taskEntry.nChildren === taskEntry.nSelected : true;
  taskEntry.nSelected = (deep === 0) ? childIds.filter(idChild => !!_selectedTaskIds[idChild]).length : childIds.length;

  res = [idTask];

  if (deep === 0) {
    return res;
  }

  return childIds.reduce((result, idChild) => result.concat(this._selectTasksRec(idChild, deep - 1)), res);
};

/**
 * @private !TODO: description
 * @param {Number} idTask - the base task identifier
 */
MassChangeModel.prototype._updateSelParents = function (idTask) {
  const { parent } = gantt.getTask(idTask);// globalStore.getters['tasksModel/getTask'](idTask);
  const res = [];

  if (!parent) {
    return [];
  }

  if (!this._selectedTaskIds[idTask].allSelected) {
    console.warn('return []'); // !DEBUG
    return [];
  }

  let parentSelEntry = this._selectedTaskIds[parent];

  if (!parentSelEntry) {
    parentSelEntry = this._selectedTaskIds[parent] = {
      isSelected: false,
      allSelected: false,
      // !FIXME: performance
      nChildren: gantt.getChildren(parent).filter(entry => this._isValidSelectionTask(entry)).length,
      nSelected: 1,
    };

    return [parent];
  }

  if (parentSelEntry.nSelected < parentSelEntry.nChildren) {
    parentSelEntry.nSelected += 1;
  }

  if (
    parentSelEntry.nChildren === parentSelEntry.nSelected
    && parentSelEntry.isSelected === true
  ) {
    parentSelEntry.allSelected = true;

    if (parentSelEntry.nSelected > 0) {
      res.push(parent);
    } else {
      this._unselectTasksRec(parent);
    }

    // traverse and update parents in cycle
    let parentOfParentEntry = gantt.getTask(parent); // globalStore.getters['tasksModel/getTask'](parent);
    let parentOfParentSelEntry = parentOfParentEntry && this._selectedTaskIds[parentOfParentEntry.parent];

    while (
      parentOfParentSelEntry && parentOfParentSelEntry.isSelected
      && parentOfParentSelEntry.nChildren === parentOfParentSelEntry.nSelected
    ) {
      parentOfParentSelEntry.allSelected = true;
      res.push(parentOfParentEntry.parent);

      parentOfParentEntry = gantt.getTask(parentOfParentEntry.parent); // globalStore.getters['tasksModel/getTask'](parentOfParentEntry.parent);
      parentOfParentSelEntry = parentOfParentEntry && this._selectedTaskIds[parentOfParentEntry.parent];
    }
  }

  return res;
};

/**
 * @private !TODO: description
 * @param {Number} idTask - the base task identifier
 */
MassChangeModel.prototype._updateUnselParents = function (idTask) {
  const { parent } = gantt.getTask(idTask); // globalStore.getters['tasksModel/getTask'](idTask);

  const res = [];

  const parentSelEntry = this._selectedTaskIds[parent];

  if (!parentSelEntry) {
    return [];
  }

  parentSelEntry.nSelected -= 1;

  if (
    parentSelEntry.isSelected === true
    && parentSelEntry.allSelected === true
  ) {
    parentSelEntry.allSelected = false;
    res.push(parent);
    // if (parentSelEntry.nSelected > 0) {
    //   res.push(parent);
    // } else {
    //   this._unselectTasksRec(parent);
    // }

    // traverse and update parents in cycle
    let parentOfParentEntry = gantt.getTask(parent); // globalStore.getters['tasksModel/getTask'](parent);
    let parentOfParentSelEntry = parentOfParentEntry && this._selectedTaskIds[parentOfParentEntry.parent];

    while (parentOfParentSelEntry && parentOfParentSelEntry.allSelected /* && parentOfParentSelEntry.isSelected &&  */) {
      parentOfParentSelEntry.allSelected = false;
      res.push(parentOfParentEntry.parent);

      parentOfParentEntry = gantt.getTask(parentOfParentEntry.parent); // globalStore.getters['tasksModel/getTask'](parentOfParentEntry.parent);
      parentOfParentSelEntry = parentOfParentEntry && this._selectedTaskIds[parentOfParentEntry.parent];
    }
  }

  // if (parentSelEntry.isSelected === true
  //   && parentSelEntry.allSelected === false) {
  //     this._unselectTasksRec(parent);
  // }

  return res;
};

/**
 * @public Marks as "selected" the provided task and its children (if required). Updates
 * the chain of task's parents (if needed). Send an event with arrays of selected & updated tasks ids.
 * @param {Number} idTask - the task identifier
 * @param {Boolean} includeChildren - if set to "true" selects children for all levels
 */
MassChangeModel.prototype.selectTask = function (idTask, includeChildren = false) {
  if (typeof idTask !== 'number') {
    throw new TypeError('Invalid arguiments!');
  }

  if (this._isTaskSelected(idTask) && includeChildren === false) {
    console.warn('[MassChangeModel.prototype.selectTask] -> already selected', idTask); // !WARN
    return;
  }

  // console.info('selectTask >>>> before before: ', JSON.stringify(this._selectedTaskIds)); // !DEBUG
  const depth = includeChildren ? -1 : 0;
  const tasksToSelect = this._selectTasksRec(idTask, depth);

  // console.info('selectTask >>>> before: ', JSON.stringify(this._selectedTaskIds)); // !DEBUG
  const parentsToUpdate = tasksToSelect.length > 0 ? this._updateSelParents(idTask) : [];
  // console.info('selectTask >>>> after: ', JSON.stringify(this._selectedTaskIds)); // !DEBUG

  // console.info('selectTask >>>>> ', idTask, tasksToSelect, parentsToUpdate, this._selectedTaskIds); // !DEBUG

  if (tasksToSelect.length > 0) {
    app.trigger(ID_EVENT_MASSCHANGE_UPDATE_TASKS, idTask, tasksToSelect, parentsToUpdate, true);
  }
};

/**
 * @public Marks as "unselected" the provided task and its children (if required). Updates
 * the chain of task's parents (if needed). Send an event with arrays of unselected & updated tasks ids.
 * @param {Number} idTask - the task identifier
 * @param {Boolean} includeChildren - if set to "true" unselects children for all levels
 */
MassChangeModel.prototype.unselectTask = function (idTask, includeChildren = false) {
  if (typeof idTask !== 'number') {
    throw new TypeError('Invalid arguments');
  }

  if (!this._isTaskSelected(idTask) && includeChildren === false) {
    console.warn('[MassChangeModel.prototype.unselectTask] -> already unselectTask', idTask); // !WARN
    return;
  }

  const depth = includeChildren ? -1 : 1;
  const tasksToUnselect = this._unselectTasksRec(idTask, depth);
  const parentsToUpdate = tasksToUnselect.length > 0 ? this._updateUnselParents(idTask) : [];

  // console.info('selectTask >>>> after: ', JSON.stringify(this._selectedTaskIds)); // !DEBUG
  // console.info('unselectTask -> parentsToUpdate', parentsToUpdate); // !DEBUG

  if (tasksToUnselect.length > 0) {
    app.trigger(ID_EVENT_MASSCHANGE_UPDATE_TASKS, idTask, tasksToUnselect, parentsToUpdate, false);
  }
};

/**
 * @public Returns the total count of selected tasks
 * @return {Number}
 */
MassChangeModel.prototype.getSelectedCount = function () {
  return this.getSelectedList().length;
};

/**
 * @public Returns an array of selected tasks identifiers
 * @return {Array <Number>}
 */
MassChangeModel.prototype.getSelectedList = function () {
  const { _selectedTaskIds } = this;

  return _selectedTaskIds ? Object.keys(_selectedTaskIds)
    .filter(key => _selectedTaskIds[key].isSelected)
    .map(key => (typeof key === 'number' ? key : parseInt(key, 10))) : [];
};

//  0 - partially selected
// @public {Number} -> {Number}
MassChangeModel.prototype.getEntrySelectionState = function (idTask) {
  if (!this._selectedTaskIds[idTask] || !this._selectedTaskIds[idTask].isSelected) {
    return -1;
  }

  if (this._selectedTaskIds[idTask].isSelected && this._selectedTaskIds[idTask].allSelected) {
    return 1;
  }

  return 0;
};

const _massChangeModel = new MassChangeModel(_actionsConfig, _api);

export default _massChangeModel;

// const externalHandlers = {
//   onPopupsStateChanged: () => {},
//   onProjArchiveStateChanged: () => {}
// };

app.on('toggle:filter:popup', () => {
  if (_massChangeModel.isEnabled()) {
    _massChangeModel.disable();
  }
});

app.on(ID_EVENT_POPUPS_CHANGED, (state, idWnd) => {
  if (isAnyPopupOpened() && idWnd !== ID_VIEW_POPUP_CUSTOMGRID) {
    if (_massChangeModel.isEnabled()) {
      _massChangeModel.disable();
    }
  }
});

//
app.on('project:archive', (ganttId, archive) => {
  const activeGanttId = projectsModel.getActiveGanttId();

  if (activeGanttId === ganttId && !!archive) {
    if (_massChangeModel.isEnabled()) {
      _massChangeModel.disable();
    }
  }
});

// !FIXME
app.on(ID_EVENT_APP_ROUTECHANGED, routeEntry => {
  if (
    routeEntry.name !== 'project'
    || routeEntry.params.mode !== 'gantt'
    || routeEntry.params.projectId !== _massChangeModel._idProject
  ) {
    if (_massChangeModel.isEnabled()) {
      _massChangeModel.disable();
    }
  }
});
