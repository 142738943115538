var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "button",
      class: [
        "vgp-interactive-element",
        _vm.$style.button,
        _vm.active ? _vm.$style.active : "",
        _vm.$style[_vm.size],
        _vm.$style[_vm.currentType],
        _vm.isLoading ? _vm.$style.loading : "",
        _vm.label ? _vm.$style.with_label : "",
        _vm.label ? _vm.$style["icon_" + _vm.iconSide] : "",
        _vm.bold ? _vm.$style.bold : "",
      ],
      style: _vm.customStyle,
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      !_vm.isLoading && _vm.icon
        ? [
            _c("sprite", {
              key: _vm.icon.name + _vm.icon.type,
              class: _vm.$style.icon,
              attrs: {
                name: _vm.icon.name || "check",
                type: _vm.icon.type || "regular",
                size: _vm.icon.size || 24,
              },
            }),
            _vm._v(" "),
            _vm.label
              ? _c("div", { class: _vm.$style.label }, [
                  _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
                ])
              : _vm._e(),
          ]
        : _c(
            "vue-ellipse-progress",
            _vm._b({}, "vue-ellipse-progress", _vm.loaderConfig, false)
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }