import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import Store from '../../../store/main';

function onCustomFieldCreated(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const addedItem = event.innerSettings.addedItem;

  addedItem.type = addedItem.custom_column_types_id;
  addedItem.sub_type = addedItem.custom_column_sub_types_id;
  delete addedItem.custom_column_types_id;
  delete addedItem.custom_column_sub_types_id;

  Store.commit('columns/addCustomColumn', addedItem);

  if (ganttIDs && ganttIDs.length) {
    app.trigger('userCustomColumnsModel:change', ganttIDs[0]);
  }
  app.trigger('userCustomColumnsModel:change');
}

export default onCustomFieldCreated;
