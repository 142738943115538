var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.widget_wrapper },
    [
      _c("div", { class: _vm.$.header }, [
        _c("div", { class: _vm.$.title }, [
          _vm._v(_vm._s(_vm.locale("chart_" + _vm.chart.key + "_title"))),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$.chart_toolbox }, [
          _c("div", { class: _vm.$.year_switch }, [
            _c(
              "div",
              {
                class: _vm.$.previous,
                on: {
                  click: function ($event) {
                    return _vm.setCurrent(-1)
                  },
                },
              },
              [
                _c("svg-sprite", {
                  attrs: {
                    type: "bold",
                    name: "arrow-left",
                    size: 24,
                    color:
                      _vm.minYear === _vm.currentYear ? "#CCCCCC" : "#808080",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.$.current }, [
              _vm._v(
                _vm._s(_vm.currentYear) + " " + _vm._s(_vm.locale("year"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.$.next,
                on: {
                  click: function ($event) {
                    return _vm.setCurrent(1)
                  },
                },
              },
              [
                _c("svg-sprite", {
                  attrs: {
                    type: "bold",
                    name: "arrow-right",
                    size: 24,
                    color:
                      _vm.maxYear === _vm.currentYear ? "#CCCCCC" : "#808080",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("milestones-chart", {
        attrs: {
          data: _vm.data,
          currentYear: _vm.currentYear,
          small: _vm.small,
        },
        on: { setYearRange: _vm.setYearRange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }