var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permission-popup" }, [
    _c("div", { staticClass: "head-wrapper " }, [
      _c("div", { staticClass: "popup-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.locales("push_permission_title")) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "closer-icon" },
        [
          _c("svg-sprite", {
            attrs: {
              name: "close-1",
              type: "bold",
              size: 24,
              color: "#999999",
            },
            on: { click: _vm.closeClick },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "pic" }, [
        _c("img", { attrs: { src: _vm.img, alt: "" } }),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "popup-text text",
        domProps: { innerHTML: _vm._s(_vm.text) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "permission-btn" },
        [
          _c("vgp-button", {
            staticClass: "gp_autotest_confirm_popap_btn_ok",
            attrs: {
              type: "primary",
              small: true,
              width: "90px",
              label: _vm.locales("request_permission"),
            },
            on: { onClick: _vm.clickPermission },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }