/* eslint-disable */
import _ from "./../libs/lodash";
import globalStore from "../store/main";

var obj = {
  calc: function (newStatus, newProgress) {
    var result = {};
    var obj;
    var mapStatuses = globalStore.getters['columns/getColumnByName']('status')?.options;

    if (_.isNull(newProgress)) {
      obj = _.find(mapStatuses, function (s) {
        return (+s.id === +newStatus);
      });

      if (obj) {
        result.progress = +obj.default_progress;
      }
    }

    if (_.isNull(newStatus)) {
      var default_progress;

      newProgress /= 100;

      if (+newProgress === 0) {
        default_progress = 0;
      }

      if (+newProgress > 0) {
        default_progress = 0.5;
      }

      if (+newProgress === 1) {
        default_progress = 1;
      }

      obj = _.find(mapStatuses, function (s) {
        return (+s.default_progress === +default_progress);
      }) || {};

      result.status = obj.id;
    }

    return result;
  },
  updateStatus: function (taskData, newStatus) {
    newStatus = newStatus || this.calc(null, taskData.progress * 100).status;

    if ( (taskData.status === newStatus && newStatus !== 2  ) || ( _.includes([3, 4], taskData.status) && _.includes([3, 4], newStatus) )) {
      return;
    }

    taskData.status = newStatus;
    globalStore.dispatch('tasksModel/changeStatus', {
      statusValue: newStatus,
      taskData: taskData
    });
  }
};

export default obj;

