var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: { show: _vm.show },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                ref: "popup",
                class: [_vm.$style.template_popup],
                style: { width: "440px" },
              },
              [
                _c(
                  "div",
                  {
                    ref: "popup_container",
                    class: [_vm.$style.template_popup_container],
                  },
                  [
                    _c("div", { class: [_vm.$style.header] }, [
                      _c("div", { class: [_vm.$style.header__title] }, [
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.locales("new_template_title")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("vgp-icon-button", {
                            class: [
                              _vm.$style.header__info_icon,
                              !_vm.helpActivityStatus
                                ? _vm.$style.not_shown
                                : "",
                            ],
                            attrs: {
                              size: "small",
                              icon: { name: "help", type: "regular", size: 24 },
                              colors: _vm.buttonColors,
                            },
                            on: {
                              onClick: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickInfo($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("vgp-icon-button", {
                            attrs: {
                              size: "small",
                              colors: _vm.buttonColors,
                              icon: { name: "close-1", type: "bold", size: 24 },
                            },
                            on: {
                              onClick: function ($event) {
                                return _vm.$emit("onClickCancel")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$style.body] }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locales("new_template_description")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: [_vm.$style.input] },
                      [
                        _c("div", { class: [_vm.$style.input_title] }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.locales("new_template_input_label")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("vgp-input", {
                          class: [_vm.$style.input_field],
                          attrs: {
                            border: true,
                            small: "",
                            "is-error": _vm.templateName.length >= 255,
                            maxlength: 255,
                            placeholder: _vm.locales(
                              "new_template_input_placeholder"
                            ),
                          },
                          model: {
                            value: _vm.templateName,
                            callback: function ($$v) {
                              _vm.templateName = $$v
                            },
                            expression: "templateName",
                          },
                        }),
                        _vm._v(" "),
                        _vm.templateName.length >= 255
                          ? _c(
                              "span",
                              { class: _vm.$style.input_error_message },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.locales(
                                        "new_template_input_limit_exceeded"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$style.footer] }, [
                      _c(
                        "div",
                        {
                          class: [_vm.$style.share_checkbox],
                          on: { click: _vm.handleCheckbox },
                        },
                        [
                          _c("vgp-checkbox", {
                            class: [_vm.$style.checkbox],
                            attrs: { size: "16", checked: _vm.shareTemplate },
                            on: { onChange: _vm.handleCheckbox },
                          }),
                          _vm._v(" "),
                          _c("div", { class: [_vm.$style.label] }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.locales("new_template_share_label")
                                ) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("vgp-button", {
                            attrs: {
                              type: "primary",
                              small: true,
                              label: _vm.locales("common_save"),
                              disabled: _vm.disableButton,
                            },
                            on: { onClick: _vm.onClickOk },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }