const headerConfig = {
  profile: {
    height: 62,
    type: 'profile',
    icon: 'role',
    title: __('settings_profile'),
    tabs: null,
    activeTab: null,
  },
  billing: {
    height: 62,
    type: 'billing',
    icon: 'billing',
    title: __('settings_billing'),
    tabs: null,
    activeTab: null,
  },
  team: {
    height: 62,
    type: 'team',
    icon: 'palette',
    title: __('settings_team'),
    tabs: null,
    activeTab: null,
  },
  columns: {
    height: 62,
    type: 'columns',
    icon: 'custom-field',
    title: __('settings_columns'),
    tabs: null,
    activeTab: null,
  },
  statuses: {
    height: 62,
    type: 'statuses',
    icon: 'status-1',
    title: __('settings_statuses'),
    tabs: null,
    activeTab: null,
  },
  account_rights: {
    height: 62,
    type: 'account_rights',
    icon: 'assigned',
    title: __('settings_account_right'),
    tabs: null,
    activeTab: null,
  },
  project_rights: {
    height: 62,
    type: 'project_rights',
    icon: 'rights',
    title: __('settings_project_right'),
    tabs: null,
    activeTab: null,
  },
  resources: {
    height: 82,
    type: 'resources',
    icon: 'team',
    title: __('settings_resources'),
    tabs: [
      {
        title: __('resources_manage_layout_header_title_1'),
        type: 'people',
      },
      {
        title: __('resources_manage_layout_header_title_2'),
        type: 'material',
      },
    ],
    activeTab:       {
      title: __('resources_manage_layout_header_title_1'),
      type: 'people',
    },
  },
  security: {
    height: 62,
    type: 'security',
    icon: 'security',
    title: __('settings_security'),
    tabs: null,
    activeTab: null,
  },
  integrations: {
    height: 62,
    type: 'integrations',
    icon: 'integration-1',
    title: __('settings_integration'),
    tabs: null,
    activeTab: null,
  },
  notifications: {
    height: 62,
    type: 'notifications',
    icon: 'notifications',
    title: __('settings_notification'),
    tabs: null,
    activeTab: null,
  },
};

export default headerConfig;
