<template functional>
  <svg
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    :viewBox="$options.methods.viewBox(props.size)"
    :width="props.size"
    :height="props.size"
    fill="none"
    role="presentation"
    class="svg-icon"
    v-html="$options.methods.icon(props.size, props.name, props.type)"
    v-on="listeners"
    v-bind="data.attrs"
  />
</template>
<script>

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String, // regular, bold, color-bold, color-regular
      required: false,
      default: 'regular',
    },
    size: {
      type: Number, // 16, 20, 24
      required: false,
      default: 24,
    },

  },
  methods: {
    viewBox(size) {
      return `0 0 ${size} ${size}`;
    },
    icon(size, name, type) {
      const hrefForSvg = `${type}/${size}/${name}`;

      return `<use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#icons/${hrefForSvg}"
        href="#icons/${hrefForSvg}"
      ></use>`;
    },
  },
};
</script>
