<template>
  <section
    :class="$style['task-settings-dependencies']"
  >
    <div :class="$style['header']">
      <div :class="$style['title-wrap']">
        <h2>
          {{ locales('settings_taskDependency') }}
          <span>{{ dependencies.length }}</span>
        </h2>
        <vgp-icon-button
          v-if="!isFieldDisabled('dependencies')"
          :icon="{ name: 'plus-1' }"
          @onClick="showNewDependencyForm = true"
        />
      </div>
    </div>
    <new-dependency-form
      v-if="showNewDependencyForm"
      ref="newDependencyForm"
      v-click-outside="{
        handler: newDependencyFormClickOutsideHandler,
        closeConditional: () => showNewDependencyForm
      }"
      :task-data="taskData"
      :existing-dependencies="dependencies"
      @dependency-created="handleDependencyCreated"
      @close="showNewDependencyForm = false"
    />
    <div
      :class="$style['dependencies__list']"
    >
      <dependency-row
        v-for="dependency in dependencies"
        ref="dependencies"
        :key="dependency.linkId"
        :dependency="dependency"
        @change-lag="handleChangeDependencyLag(dependency, $event)"
        @delete="showDeleteConfirmPopup('single_delete', dependency)"
        @show-preview="showDependencyPreview(dependency, $event)"
        @hide-preview="dependencyPreviewProps = null"
      />
    </div>
    <dependency-preview
      v-if="dependencyPreviewProps"
      v-bind="dependencyPreviewProps"
    />
    <core-confirm-popup
      :popup-width="440"
      :show="confirmDeletionData.showPopup"
      :text="confirmDeletionData.text"
      :cancel-button="{ title: locales('common_cancel'), type: 'secondary' }"
      :ok-button="{ title: locales('common_delete'), type: 'destructive' }"
      @onClickOk="handleConfirmDeletion"
      @onClickCancel="cleanUpConfirmDeletionData"
    />
  </section>
</template>

<script>
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import NewDependencyForm from './includes/NewDependencyForm.vue';
import timeParser from '$$helpers/timeParser';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import routerHelper from '$$helpers/router';
import DependencyPreview from './includes/DependencyPreview.vue';
import elementIsVisibleInViewport from '$$helpers/elementIsVisibleInViewport';
import app from '../../../../app';
import composedPath from '../../../../helpers/composedPath';
import DependencyRow from './includes/DependencyRow.vue';
import TaskSettingsController from '../../controllers';

const LINK_TYPE_TO_VALUE = {
  0: 'finish_to_start',
  1: 'start_to_start',
  2: 'finish_to_finish',
  3: 'start_to_finish',
};

const LINK_TYPE_TO_SHORT_NAME = {
  0: __('settings_dependency_FS'),
  1: __('settings_dependency_SS'),
  2: __('settings_dependency_FF'),
  3: __('settings_dependency_SF'),
};

const LINK_TYPE_TO_FULL_NAME = {
  0: __('settings_dependency_FS_description'),
  1: __('settings_dependency_SS_description'),
  2: __('settings_dependency_FF_description'),
  3: __('settings_dependency_SF_description'),
};

let COMPONENT_EVENT_LISTENERS = [];

export default {
  name: 'TaskSettingsDependencies',
  components: {
    DependencyRow,
    DependencyPreview,
    CoreConfirmPopup,
    NewDependencyForm,
    VgpIconButton,
  },
  inject: ['isFieldDisabled'],
  props: {
    masterLinks: {
      type: Array,
      default: () => [],
    },
    slaveLinks: {
      type: Array,
      default: () => [],
    },
    taskData: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      showNewDependencyForm: false,
      confirmDeletionData: {
        showPopup: false,
        text: '',
        type: null, // single_delete || multiple_delete || delete_all
        eventPayload: null,
      },
      dependencyPreviewProps: null,
      isDeleting: false,
    };
  },
  computed: {
    dependencies() {
      return [
        ...this.getDependenciesByLinksData(this.masterLinks, true),
        ...this.getDependenciesByLinksData(this.slaveLinks, false),
      ];
    },
  },
  watch: {
    dependencies(newVal, oldVal) {
      // close new dependency form after new dependency created to not allow create 2 same dependency by collaboration
      if (newVal.length > oldVal.length && this.showNewDependencyForm) {
        this.showNewDependencyForm = false;
      }
    },
    showNewDependencyForm(val) {
      if (!val) {
        this.$emit('new-dependency-form-close');
      }
    },
    disabled() {
      if (this.disabled.dependencies === null) {
        this.$emit('new-dependency-form-close');
      }
    },
  },
  created() {
    const onAfterCollaborationListenerId = app.on('onAfterCollaboration', data => {
      if (data.event === 'TaskLinkDeleted') {
        this.onTaskLinkDeleted(data.ids);
      }
    });

    COMPONENT_EVENT_LISTENERS.push(onAfterCollaborationListenerId);
  },
  beforeDestroy() {
    COMPONENT_EVENT_LISTENERS.forEach(id => app.off(id));
    COMPONENT_EVENT_LISTENERS = [];
  },
  methods: {
    onTaskLinkDeleted(deletedLinkIds) {
      if (!this.confirmDeletionData.showPopup) return;

      if (deletedLinkIds.includes(this.confirmDeletionData.eventPayload.linkId)) {
        this.cleanUpConfirmDeletionData();
      }
    },
    newDependencyFormClickOutsideHandler(event) {
      let path = event.path || (event.composedPath && event.composedPath()) || [];

      if (!path.length) {
        path = composedPath(event.target);
      }

      const isInteractiveElementClicked = path.some(element => element?.classList?.contains('vgp-interactive-element'));

      if (isInteractiveElementClicked) {
        this.showNewDependencyForm = false;
      }
    },
    handleDependencyCreated() {
      this.showNewDependencyForm = false;
      this.$nextTick(this.scrollToLastDependencyIfNotVisible);
    },
    cleanUpConfirmDeletionData() {
      this.confirmDeletionData.showPopup = false;
      this.confirmDeletionData.type = null;
      this.confirmDeletionData.eventPayload = null;
      this.confirmDeletionData.text = '';
    },
    handleConfirmDeletion() {
      switch (this.confirmDeletionData.type) {
      case 'single_delete':
        this.deleteDependency(this.confirmDeletionData.eventPayload.linkId);
        break;
      }
      this.cleanUpConfirmDeletionData();
    },
    showDeleteConfirmPopup(deletionType, eventData = null) {
      this.confirmDeletionData.type = deletionType;
      this.confirmDeletionData.eventPayload = eventData;
      switch (deletionType) {
      case 'single_delete':
        const linkData = this.$store.getters['tasksModel/getLinkByGanttId'](this.taskData.gantt_id, eventData.linkId);
        const masterTask = this.$store.getters['tasksModel/getTaskByGanttId'](this.taskData.gantt_id, linkData.source);
        const slaveTask = this.$store.getters['tasksModel/getTaskByGanttId'](this.taskData.gantt_id, linkData.target);

        const text = __('settings_msg_delete_link', { taskMaster: `<b>${masterTask.text}</b>`, taskSlave: `<b>${slaveTask.text}</b>` });

        this.confirmDeletionData.text = `<dv>${text}</div>`;
        break;
      }
      this.confirmDeletionData.showPopup = true;
    },
    async deleteDependencies(ids) {
      if (this.isDeleting) return;

      this.isDeleting = true;
      const promises = ids.map(id => {
        const linkData = this.$store.getters['tasksModel/getLinkByGanttId'](this.taskData.gantt_id, id);

        const type = Object.entries(gantt.config.links).find(([_, val]) => val === linkData.type)?.[0];

        userExtAnalytics.log('task_settings_action', { action: 'link_delete', type });

        return this.$store.dispatch('tasksModel/backgroundDeleteLink', linkData);
      });

      await Promise.all(promises);

      if (!routerHelper.isListViewRoute()) {
        gantt.callEvent('recalculateCriticalPath');
      }
      this.isDeleting = false;
    },
    scrollToLastDependencyIfNotVisible() {
      const lastDependency = this.$refs.dependencies.at(-1);

      if (!elementIsVisibleInViewport(lastDependency.$el)) {
        lastDependency.$el.scrollIntoView({ block: 'center' });
      }
    },
    showDependencyPreview(dependency, position) {
      const predecessorName = dependency.taskMaster ? dependency.taskName : this.taskData.text;
      const successorName = dependency.taskMaster ? this.taskData.text : dependency.taskName;

      this.dependencyPreviewProps = {
        type: dependency.linkType,
        position,
        name: dependency.linkShortName,
        description: dependency.linkFullName,
        predecessorName,
        successorName,
        alignToCenter: false,
      };
    },
    async handleChangeDependencyLag(dependency, value) {
      await TaskSettingsController.updateDependencyLag(dependency, value);
    },
    getConvertedLag(lag) {
      return timeParser.output(lag, {
        durationData: gantt.config.durationData,
        durationStep: gantt.config.duration_view,
        prop: 'lag',
      });
    },
    getDependenciesByLinksData(linksData, isMaster) {
      const dependencyData = [];

      linksData.forEach(linkData => {
        const linkFullData = this.$store.getters['tasksModel/getLinkByGanttId'](this.taskData.gantt_id, linkData.linkId);
        const tempTask = this.$store.getters['tasksModel/getTaskByGanttId'](this.taskData.gantt_id, linkData.dependencyTaskId);

        if (tempTask?.id) {
          dependencyData.push({
            taskName: tempTask.text,
            taskId: tempTask.id,
            taskMaster: isMaster,
            linkLag: linkFullData.lag,
            linkType: LINK_TYPE_TO_VALUE[linkData.linkType],
            linkShortName: LINK_TYPE_TO_SHORT_NAME[linkData.linkType],
            linkFullName: LINK_TYPE_TO_FULL_NAME[linkData.linkType],
            linkId: linkData.linkId,
          });
        }
      });

      return dependencyData;
    },
    async deleteDependency(linkId) {
      await this.deleteDependencies([linkId]);
    },

  },
};
</script>

<style module lang="less">

.task-settings-dependencies {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    h2 {
      font-family: Lato-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #191919;
    }
  }

  .buttons-wrap {
    display: flex;
    align-items: center;
    .header-actions-btn {
      margin-left: 12px;
    }

    .delete-selected-btn {
      margin-right: 4px;
    }
  }

  .title-wrap {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 8px;
      font-family: Lato-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #191919;
      span {
        color: #B2B2B2;
      }
    }

  }

  .dependencies__list {
    display: flex;
    flex-direction: column;
  }

}

</style>
