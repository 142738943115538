var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "commentHistory", class: [_vm.$style["task-settings-comments"]] },
    [
      _c("div", { class: _vm.$style.header }, [
        _c("h2", [
          _vm._v(
            "\n      " + _vm._s(_vm.locales("settings_taskComment")) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style["comments__list"] },
        _vm._l(_vm.groupedCommentsByDate, function (groupComments, groupName) {
          return _c(
            "div",
            [
              _vm.commentHistoryLabelsShown
                ? _c(
                    "div",
                    { class: _vm.$style["comments__group-history-date"] },
                    [_vm._v("\n        " + _vm._s(groupName) + "\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(groupComments, function (comment, index) {
                return _c(
                  "div",
                  {
                    key: comment.id,
                    ref: "groupName",
                    refInFor: true,
                    attrs: { id: comment.id },
                  },
                  [
                    _c("comment-item", {
                      attrs: { comment: comment },
                      on: {
                        "comment-delete": _vm.handleClickDeleteComment,
                        "edit-comment": function ($event) {
                          return _vm.$emit("edit-comment", comment)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: _vm.currentCommentIdForDelete,
          text: _vm.locales("settings_msg_delete_comment"),
          "ok-button": {
            type: "destructive",
            title: _vm.locales("common_yes"),
          },
          "cancel-button": {
            type: "secondary",
            title: _vm.locales("common_cancel"),
          },
        },
        on: {
          onClickOk: _vm.handleConfirmDeleteComment,
          onClickCancel: function ($event) {
            _vm.currentCommentIdForDelete = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }