var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.toggle_box,
        _vm.isToggleOn ? _vm.$style.on : null,
        _vm.isDisabled ? _vm.$style.disabled : null,
      ],
      on: { click: _vm.toggle },
    },
    [_c("div", { class: _vm.$style.switch })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }