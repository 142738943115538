var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style["header"]] }, [
    _c("div", { class: [_vm.$style["header-line-first"]] }, [
      _c(
        "div",
        { class: [_vm.$style["header-dropdown"]] },
        [
          _c("dropdownSelect", {
            staticClass: "gp_autotest_comment_hub_projects_dropdown",
            attrs: {
              "drop-list": _vm.projects,
              selected: _vm.filteredProjects,
              multiselect: true,
              "use-search": true,
              "use-select-all": true,
              "track-by": "id",
              "item-template": "text",
              "track-by-text": "name",
              placeholder: "search",
              "show-selected-first": true,
              "show-reset-btn": true,
              "stub-text-if-all-selected": _vm.locale(
                "comment_hub_all_projects"
              ),
              "stub-text-if-all-unselected": _vm.locale(
                "comment_hub_all_projects"
              ),
            },
            on: { change: _vm.onChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { style: { width: "332px" } },
        [
          _c("vgp-input", {
            staticClass: "gp_autotest_comment_hub_search_input",
            attrs: {
              type: "text",
              placeholder: _vm.locale("comment_hub_search_tasks_and_comments"),
              "icons-left": [{ name: "search" }],
              "icons-right": _vm.iconsRightConfig,
              small: true,
              "select-by-focus": false,
            },
            on: { onKeydown: _vm.search },
            model: {
              value: _vm.searchStr,
              callback: function ($$v) {
                _vm.searchStr = $$v
              },
              expression: "searchStr",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: [_vm.$style["header-line-second"]] },
      [
        _c("vgp-tabs", {
          attrs: {
            tabs: _vm.tabsItems,
            active: _vm.filterButton,
            type: "button",
            amounts: _vm.tabsAmounts,
            "gp-autotest-name": "gp_autotest_comment_hub_filter_buttons",
          },
          on: {
            onToggle: function (tab) {
              return _vm.$emit("toggle", tab)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }