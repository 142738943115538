var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$style.select,
      style: Object.assign({}, { width: _vm.width + "px" }, _vm.styles),
    },
    [
      _vm.label
        ? _c("div", { class: _vm.$style.label }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.select_box, style: { width: _vm.width + "px" } },
        [
          _c(
            "div",
            {
              class: [
                _vm.$style.target,
                _vm.isShowSelectItems ? _vm.$style.active : "",
              ],
              style: {
                height: (_vm.height ? _vm.height : 36) + "px",
                lineHeight: (_vm.height ? _vm.height : 36) + "px",
              },
              on: { click: _vm.toggleSelectItems },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.currentItem[_vm.targetValue]) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.isShowSelectItems
            ? _c(
                "div",
                {
                  ref: "list",
                  class: [_vm.$style.select_list, "gantt_scroll"],
                  style: _vm.position + ": 100%",
                },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "selectItem",
                      refInFor: true,
                      class: [
                        _vm.$style.select_item,
                        item[_vm.targetValue] ===
                        _vm.currentItem[_vm.targetValue]
                          ? _vm.$style.active
                          : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.onSelectItem(item, _vm.selectType)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            item[_vm.targetValue] ||
                              _vm.locales("" + item[_vm.targetValue])
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.inputDescription
        ? _c("div", { class: _vm.$style.input_description }, [
            _vm._v("\n    " + _vm._s(_vm.inputDescription) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }