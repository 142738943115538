var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.box_wrapper,
        _vm.isDisable ? _vm.$style.disable : null,
        "vgp-interactive-element",
      ],
    },
    [
      _c("label", { class: _vm.$style.checkbox }, [
        _vm.label && _vm.labelPosition === "left"
          ? _c(
              "span",
              { class: _vm.$style.label_text, style: { marginRight: "8px" } },
              [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          class: _vm.$style.check_input,
          attrs: { type: "checkbox", disabled: _vm.isDisable },
          domProps: { checked: _vm.checked },
          on: {
            change: function ($event) {
              return _vm.$emit("onChange", $event.target.checked)
            },
            click: _vm.onClick,
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            class: [
              _vm.$style.check_box,
              _vm.isError ? _vm.$style.check_box_error : "",
            ],
            style: {
              width: _vm.size + "px",
              height: _vm.size + "px",
              minWidth: _vm.size + "px",
              backgroundColor: _vm.checked ? _vm.color : "",
              borderColor: _vm.checked ? _vm.color : "",
            },
          },
          [
            _vm.checked
              ? _c(
                  "svg",
                  {
                    class: _vm.$style.check_inner,
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "11",
                      height: "8",
                      viewBox: "0 0 11 8",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M11 0.841163L3.45714 8L0 4.71887L0.886286 3.8777L3.45714 6.31171L10.1137 0L11 0.841163Z",
                        fill: "#FFFFFF",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.label && _vm.labelPosition === "right"
          ? _c(
              "span",
              { class: _vm.$style.label_text, style: { marginLeft: "8px" } },
              [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }