import app from '../../app';
import integrationsSlack from './integrationsSlack';
import { integrationMSTeamsModel } from './integrationsMSteams';

const integrationsModules = {
  slack: integrationsSlack,
  msteams: integrationMSTeamsModel,
};

const integrations = app.config.mode.isBase
  ? new webix.DataCollection({ url: 'rest->/api/integrations/' })
  : new webix.DataCollection();

integrations.getData = function () {
  const data = integrations.serialize();

  return data;
};

integrations.getDataFromBackend = function () {
  if (!app.config.mode.isBase) return Promise.resolve([]);

  return new Promise((resolve, reject) => {
    webix.ajax()
      .get('/api/integrations')
      .then(result => {
        const data = result.json();

        integrations.parse(data);
        const _data = integrations.serialize();

        resolve(_data);
      }).catch(err => {
        new Error(err);
      });
  });
};

integrations.getByType = function (type) {
  const data = integrations.serialize();
  const integration = data.find(el => el.type === type);

  return integration;
};

integrations.activateIntegrationById = function (id, slackUser) {
  const cItem = integrations.getItem(id);

  cItem.isActive = true;
  cItem.needReconnect = false;
  cItem.users.push(slackUser);
  integrations.updateItem(id, cItem);
};

integrations.removeIntegrationFromTeam = type => webix.ajax()
  .post(`/api/integrations/${type}/removeIntegration`)
  .then(data => {
    const { status, id } = data.json();

    if (status === 'ok') {
      integrationsModules[type].removeAllConnections();
      const cItem = integrations.getItem(id);

      cItem.isActive = false;
      integrations.updateItem(id, cItem);
    }
  }).catch(err => { new Error(err); });

integrations.removeIntegrationByType = type => {
  const cItem = integrations.getByType(type);

  integrationsModules[type].removeAllConnections();
  cItem.isActive = false;
  integrations.updateItem(cItem.id, cItem);
};

integrations.disconnectIntegrationUser = (type, integrationId, integrationTeamId) => webix.ajax()
  .post(`/api/integrations/${type}/disconnectUser`, { integrationTeamId })
  .then(data => {
    const result = data.json();

    if (result.status === 'ok') {
      const cItem = integrations.getItem(integrationId);
      let userIndex = -1;

      cItem.users.forEach((slackUser, index) => {
        if (slackUser.user_id === GT.user.id && slackUser.integration_team_id === integrationTeamId) {
          userIndex = index;
        }
      });
      if (userIndex >= 0) {
        cItem.users.splice(userIndex, 1);
      }
      integrations.updateItem(integrationId, cItem);
    }
  })
  .catch(err => console.error(err));

const onAfterLoadEventHandlerId = integrations.attachEvent('onAfterLoad', () => {
  integrations.detachEvent(onAfterLoadEventHandlerId);
  app.trigger('integration:updated');
});

integrations.attachEvent('onDataUpdate', id => {
  app.trigger('integration:updated');
});

integrations.attachEvent('onAfterAdd', id => {
  app.trigger('integration:updated');
});

integrations.attachEvent('onAfterDelete', id => {
  app.trigger('integration:updated');
});

app.socket.on('integration:socket:integration_removed', data => {
  integrations.removeIntegrationByType(data.type);
});

export default integrations;
