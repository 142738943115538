import BaseFilter from './BaseFilter';
import BaseProperties from './BaseProperties';
import routerHelper from '../../../helpers/router';
import _ from '../../../libs/lodash';
import constants from '../../../helpers/constants';
import ganttViewModel from '../../../models/ganttViewModel';
import workloadCore from '../../gantt/modules/workloadCore';
import globalStore from '../../../store/main';

const _self = null;

class WorkloadFilter extends BaseFilter {
  static filterByType(type, withProjects) {
    if (!_self) {
      this._self = new WorkloadFilter(type, withProjects);
    }

    return this._self;
  }

  constructor(type, withProjects) {
    super(type);
    this.withProjects = withProjects;
  }

  get _emptyState() {
    return {
      created_at: { period: null },
      end_date: { period: null },
      overdue: 0,
      owner_id: '',
      priority: '',
      projects: '',
      resources: '',
      start_date: { period: null },
      status: '',
      text: '',
    };
  }

  get ganttIds() {
    const withProjects = routerHelper.isWorkloadViewRoute();
    let ids = [];

    if (withProjects) {
      const viewData = ganttViewModel.getActiveViewData();

      ids = routerHelper.isCommonWorkloadViewRoute() ? [] : viewData.ganttIDs || [viewData.gantt_id];
    }

    return ids;
  }

  get resources() {
    let storeResources = globalStore.getters['resourcesModel/getResourcesByTypeOnProject'](constants.RESOURCE_TIME_TYPE);

    if (this.ganttIds.length) {
      storeResources = storeResources.filter(res => _.some(res.resourceProjects, ps => this.ganttIds.includes(ps.projectId)));
    }

    const resourcesData = storeResources.map(i => {
      const item = { ...i };

      item.icon = i.resourcePhoto || i.photo || this.icons.resource;
      item.value = i.name;
      item.userId = i.userId;
      item.group = i.userId ? this.locales.realResource : this.locales.virtualResource;

      return item;
    });

    return {
      property: 'resources',
      label: __('filter_option_resource_id_label'),
      placeholder: __('filter_task_resource_id_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: [{
        id: '-1',
        value: this.locales.unassigned,
        icon: this.icons.unassigned,
      }].concat(resourcesData),
    };
  }

  get creator() {
    let resources = globalStore.getters['resourcesModel/getResourcesByTypeOnProject'](constants.RESOURCE_TIME_TYPE);

    if (this.ganttIds.length) {
      resources = resources.filter(res => _.some(res.resourceProjects, ps => this.ganttIds.includes(ps.projectId)));
    }

    const data = resources.map(i => {
      const item = { ...i };

      item.icon = i.resourcePhoto || i.photo || this.icons.resource;
      item.value = i.name;

      return item;
    });

    return {
      property: 'owner_id',
      label: __('filter_option_owner_id_label'),
      placeholder: __('filter_task_owner_id_any'),
      type: 'search-multiselect',
      itemTemplate: 'icon+text',
      data: data.filter(i => i.userId),
    };
  }

  config() {
    const base = [
      this.resources,
      BaseProperties.statuses(),
      BaseProperties.priority(),
      this.creator,
      BaseProperties.startDate('start_date'),
      BaseProperties.endDate('end_date'),
      BaseProperties.creationDate('created_at'),
      BaseProperties.color(),
      BaseProperties.overdue(),
    ];

    const withProjectsConf = [
      BaseProperties.taskName('text'),
      BaseProperties.projects('projects'),
      ...base,
    ];

    const conf = [BaseProperties.taskName('text'), ...base];

    return this.withProjects ? withProjectsConf : conf;
  }

  init() {
    super.init();
    if (!this.isActiveFilter) {
      this.setFilterState(this._emptyState, true);
    }
    gantt.init_workload && this.runWorkloadFiltration();
  }

  runWorkloadFiltration() {
    if (globalStore.getters['filter/isDisable']) return;

    // this.hasFilterOptions(this.filterState);
    workloadCore.afterFilter(this.filterState, this.isActiveFilter, this);
    this.appliedFirstFiltration = true;
  }

  changeFilterState(filterState) {
    this.setFilterState(filterState);
    this.runWorkloadFiltration();
  }

  refreshFilter() {
    this.changeFilterState(this.filterState);
  }

  selectSavedFilter(filter) {
    this.changeFilter(filter.id, filter.value);
    this.runWorkloadFiltration();
  }

  resetFilter() {
    super.resetFilter(this._emptyState);
    this.runWorkloadFiltration();
  }
}

export default WorkloadFilter;
