import { internalApi } from '$$store/api';
import constants from '../../helpers/constants';
import app from '../../app';

function prepareLoadedProgress(data) {
  const preparedProgress = data.map(item => {
    const progress = { ...item };

    progress.status = constants.PROGRESS_BAR.STATUS.UPDATE;
    progress.progress = item.data.progress;
    progress.title = item.data.title;
    progress.spinnerType = item.data?.loaderType || 'spinner';

    delete progress.data;

    return progress;
  });

  return preparedProgress;
}

const progressBar = {
  namespaced: true,
  state: {
    progressEvents: [],
    localHistoryEventsMap: {},
  },
  getters: {
    progressEvents: state => state.progressEvents,
    eventsMap: state => state.localHistoryEventsMap,
  },
  mutations: {
    start(state, payload) {
      const event = { ...payload };
      const target = payload.target.split(':');

      event.target = target[0];
      event.targetEvent = target[1];

      state.progressEvents.push(event);
    },
    update(state, payload) {
      const item = state.progressEvents.find(i => i.token === payload.token);
      const newEvent = { ...payload };

      if (payload.target) {
        const target = payload.target.split(':');

        newEvent.target = target[0];
        newEvent.targetEvent = target[1];
      }

      if (item) {
        const index = state.progressEvents.findIndex(i => i.token === item.token);
        const updated = {
          ...item,
          ...newEvent,
        };

        state.progressEvents.splice(index, 1, updated);
      } else {
        state.progressEvents.push(newEvent);
      }
    },
    finish(state, key) {
      const index = state.progressEvents.findIndex(i => i.token === key);

      if (index < 0) return;

      state.progressEvents.splice(index, 1);
    },
    setEventToMap(state, event) {
      state.localHistoryEventsMap[event.token] = `${event.event}_${event.progress}`;
    },
  },
  actions: {
    async loadData({ commit }) {
      if (!app.config.mode.isLink) {
        const response = await internalApi.get('/progress');

        const progressData = prepareLoadedProgress(response.data);

        progressData.length && progressData.forEach(progress => {
          commit('update', progress);
        });
      }
    },
  },
};

export default progressBar;
