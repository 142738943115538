import app from "./../../app";
import icon_close from "../../svg/close.svg";
import _ from "../../libs/lodash";
import rights from '../../components/rights/index';
import helperFeedback from "../../helpers/feedback";
import plansModel from "../../models/plans";
import globalStore from "$$store/main";

const __ = window.__;

var innerHandlers = {
  formRatingConfig: function () {
    var rating = document.querySelector('.rating_list');
    var ratingTitle = document.querySelector('.rating_title');
    var ratingItem = document.querySelectorAll('.rating_list_item');

    rating.onclick = function (e) {
      var target = e.target;

      rating.classList.add("checked");
      ratingTitle.classList.remove("error");

      if (target.classList.contains('rating_list_item')) {
        removeClass(ratingItem, 'current-active');
        target.classList.add('active', 'current-active');
      }

      var ratingItemCheck = document.querySelector(".current-active");
      getCurrentAllNextSiblings(ratingItemCheck);
    };
    rating.onmouseover = function (e) {
      var target = e.target;
      var ratingItemCheck = document.querySelector(".current-active");

      if (target.classList.contains('rating_list_item')) {
        removeClass(ratingItem, 'active')
        target.classList.add('active');
        mouseOverActiveClass(ratingItem);
      }

      if (rating.classList.contains('checked')) {
        getCurrentAllPrevSiblings(ratingItemCheck);
      }
    };
    rating.onmouseout = function () {
      mouseOutActiveClass(ratingItem);
    };

    function removeClass(arr) {
      for (var i = 0, iLen = arr.length; i < iLen; i++) {
        for (var j = 1; j < arguments.length; j++) {
          ratingItem[i].classList.remove(arguments[j]);
        }
      }
    }

    function addClass(arr) {
      for (var i = 0, iLen = arr.length; i < iLen; i++) {
        for (var j = 1; j < arguments.length; j++) {
          ratingItem[i].classList.add(arguments[j]);
        }
      }
    }

    function mouseOverActiveClass(arr) {
      for (var i = 0, iLen = arr.length; i < iLen; i++) {
        if (arr[i].classList.contains('active')) {
          break;
        } else {
          arr[i].classList.add('active');
        }
      }
    }

    function mouseOutActiveClass(arr) {
      if (!document.querySelector('.rating_list').classList.contains('checked')) {
        arr.forEach(function (item) {
          item.classList.remove('active');
        })
      }
    }

    function getCurrentAllNextSiblings(ratingItemCheck) {
      var out = [];
      while (ratingItemCheck.nextElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.nextElementSibling);
      }

      out.forEach(function (item, index, arr) {
        item.classList.remove("active");
        item.classList.add("uncheck");
      });

      return out;
    }

    function getCurrentAllPrevSiblings(ratingItemCheck) {
      var out = [];
      while (ratingItemCheck.previousElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.previousElementSibling);
      }

      out.forEach(function (item, index, arr) {
        item.classList.add("active");
        item.classList.remove("uncheck");
      });

      return out;
    }
  },
  getRating: function () {
    let ratingCurrentText = document.querySelector(".rating_list .current-active");
    let ratingCurrentDefaultText = document.querySelector(".rating_list .active");

    if (ratingCurrentText === null) {
      ratingCurrentText = ratingCurrentDefaultText.getAttribute("date-rate");
    } else {
      ratingCurrentText = ratingCurrentText.getAttribute("date-rate");
    }

    let resultRat = ratingCurrentText;

    return resultRat;
  },
  getTextArea: function () {
    let textareaCurrentText = document.querySelector(".main_textarea").value;

    return textareaCurrentText;
  },
  getValidateForm: function () {
    let ratingList = document.querySelector('.rating_list');
    let ratingTitle = document.querySelector('.rating_title');

    if (!ratingList.classList.contains('checked')) {
      ratingTitle.classList.add('error');
      return false;
    } else {
      ratingTitle.classList.remove('error');
    }

    if (!innerHandlers.getTextArea()) {
      document.querySelector(".main_textarea").classList.add('empty_error');
      document.querySelector(".textarea_title").classList.add('empty_error');
      return false;
    } else {
      document.querySelector(".main_textarea").classList.remove('empty_error');
      document.querySelector(".textarea_title").classList.remove('empty_error');
    }

    return true;
  }
};

var innerObject = {

  mainView: {
    id: 'userAccountFormPopup'
  },

  getUserAccountPopup: function () {

    return `
      <div class="user-popup-content">
        <div class="popup-container">
          <!--    HEADER    -->
          <div class="popup-container-header">
            <div class="popup-container-header__title">
                ${__("delete_form_popup_header_title")}
            </div>
            <div class="popup-container-header__icon">
                ${icon_close}
            </div>
          </div>
          <!--    BODY    -->
          <div class="popup-container-body">
            <div class="popup-container-body__text">
                ${__("delete_form_popup_header_text")}
            </div>
            <!--     RATING     -->
            <div class="popup-container-body__rating">
                <div class="rating_title"> ${__("delete_form_popup_body_rat_text")} </div>
                <div class="rating_list">
                    <div class="rating_list_item" date-rate="1"> ${__("delete_form_popup_body_rat_01")} </div>
                    <div class="rating_list_item" date-rate="2"> ${__("delete_form_popup_body_rat_02")} </div>
                    <div class="rating_list_item" date-rate="3"> ${__("delete_form_popup_body_rat_03")} </div>
                    <div class="rating_list_item" date-rate="4"> ${__("delete_form_popup_body_rat_04")} </div>
                    <div class="rating_list_item" date-rate="5"> ${__("delete_form_popup_body_rat_05")} </div>
                </div>
                <div class="rating_desc">
                    <div class="desc_left"> ${__("delete_form_popup_rating_footer_item_01")} </div>
                    <div class="desc_right"> ${__("delete_form_popup_rating_footer_item_02")} </div>
                </div>
            </div>
            <!--     TEXTAREA     -->
            <div class="popup-container-body__textarea">
                <div class="textarea_title"> ${__("delete_popup_form_textarea_head_title")} </div>
                <div class="textarea_desc"> ${__("delete_popup_form_textarea_head_desc")} </div>
                <div class="textarea_main">
                    <textarea class="main_textarea" form="text" name="feedback__text" placeholder="${__("delete_form_popup_text_area")}"></textarea>
                </div>
            </div>
          </div>
          <!--    FOOTER   -->
          <div class="popup-container-footer">
            <div class="popup-container-footer__button send" id="gp_autotest_delete_feedback_confirm">
                ${__("delete_popup_form_footer_button_text_01")}
            </div>
            <div class="popup-container-footer__button cancel" id="gp_autotest_delete_feedback_cancel">
                ${__("delete_popup_form_footer_button_text_02")}
            </div>
          </div>
        </div>
      </div>
    `;
  },

};

webix.ui({
  view: 'ganttproWindowPopup',
  id: "userAccountFormPopup",
  css: "user-form-popup",
  width: 636,
  height: 562,
  head: false,
  zIndex: 111,
  body: {
    view: 'template',
    id: 'userAccountBodyFormPopup',
    css: 'user-popup-wrapper',
    template: function () {
      return innerObject.getUserAccountPopup();
    },
    onClick: {
      'popup-container-header__icon': function (e) {
        $$("userAccountFormPopup").hide();
      },
      'cancel': function (e) {
        $$("userAccountFormPopup").hide();
      },
      'send': function (e) {
        if (innerHandlers.getValidateForm() === false) {
          return false;
        }

        var resource = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

        if (rights.account.isOwner() && plansModel.isActiveSubscription() && !plansModel.isTrial()) {
          webix.message({type: "info", text: __("profile_errors_deleteAccountSuspend"), expire: 10000});
        } else {

          helperFeedback.send({
            type: 'delete-account-form',
            message: innerHandlers.getTextArea(),
            rating: innerHandlers.getRating(),
          })
          webix.ajax().del("/api/team/teamMember", {
            resourceId: resource,
            userId: user.id,
            email: user.email,
            username: user.firstName || user.username,
            teamName: user.team.name || '',
            teamId: user.team.id,
            newOwnerID: user.team.owner_id !== user.id ? user.team.owner_id : null,
          }).then(() => {
            window.location = '/deletedProfile/' + user.locale;
          });
        }
      }
    },
    on: {
      'onAfterRender': function () {
        document.querySelector(".main_textarea").onfocus = function() {
          document.querySelector(".main_textarea").classList.remove('empty_error');
          document.querySelector(".textarea_title").classList.remove('empty_error');
        };
      },
    }
  },
  on: {
    'onHide': function () {
      app.trigger("popup:hide");
    },
    'onShow': function () {
      innerHandlers.formRatingConfig();
    }
  }
}).hide();

var outputObject = {
  init: function () {
    $$('userAccountFormPopup').show();

    _.delay(function () {
      webix.callEvent("onClick", []);
    }, 50);
  }
};

app.on("app:start", function () {
  // $$('userAccountPopup').show();
});

export default outputObject;
