var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "passStrengthPopup",
      staticClass: "pass-strength-popup",
      style: { left: _vm.left, top: _vm.top },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "pass-strength-desc" }, [
          _c("b", [_vm._v(_vm._s(_vm.locales("pass_strength_caption")))]),
          _vm._v(": " + _vm._s(_vm.strengthStatus) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "strength-tmp",
          domProps: { innerHTML: _vm._s(_vm.strengthTemplate) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.locales("pass_strength_descr")) + "\n    "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }