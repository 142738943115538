var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.label ? _vm.$style.field_wrap : _vm.$style.input_field_wrap],
    },
    [
      _vm.label
        ? _c("div", { class: [_vm.$style.input_label] }, [
            _c("span", { class: _vm.$style.txt_wrap }, [
              _vm._v(_vm._s(_vm.label)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.input_txt,
                    expression: "input_txt",
                  },
                ],
                class: _vm.$style.reset,
                attrs: { id: _vm.gpAutotestName + "_reset_btn" },
                on: {
                  click: function ($event) {
                    return _vm.resetSelected()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.reset_locale) + "\n    ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style.input_field,
            _vm.input_txt || _vm.focusInput ? _vm.$style.active : "",
          ],
        },
        [
          _c(
            "div",
            { class: [_vm.$style.text_input_inner] },
            [
              _vm.withSearchIcon
                ? _c("webix-ui", {
                    class: [_vm.$style.search_icon],
                    attrs: { config: _vm.searchIcon },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input_txt,
                    expression: "input_txt",
                  },
                ],
                attrs: {
                  id: _vm.gpAutotestName,
                  placeholder: _vm.placeholder,
                  disabled: _vm.filterProvide.disableInput,
                },
                domProps: { value: _vm.input_txt },
                on: {
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      ) {
                        return null
                      }
                      return _vm.deleteHandler($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onEnter($event)
                    },
                  ],
                  change: _vm.onChange,
                  focus: _vm.onFocus,
                  blur: function ($event) {
                    _vm.focusInput = false
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.input_txt = $event.target.value
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }