<template>
  <div>
    <div>
      <skeleton-wrapper
        id="skeletonWrapper"
        :style="{width: '100%'}"
        :is-calendar="true"
      >
        <template #body>
          <skeleton v-if="isShowSkeleton" />
        </template>
      </skeleton-wrapper>
      <pricing-stub
        v-if="!pricingAccessToCalendar"
        :is-owner="isOwner"
        :plan-title="planTitle"
      />
      <div
        v-else
        id="calendar_view_id"
        ref="calendarView"
        :class="['calendar_view', isTaskSettingsOpened ? 'disabled-layout' : '']"
        :style="styles"
      />
      <new-event-popup
        :show="isShowNewEventPopup"
        :date="newEventDate"
        :project-id="projectId"
        @close="isShowNewEventPopup = false"
        @createTask="createNewTask"
      />
      <details-tooltip
        v-if="tooltipConfig"
        :config="tooltipConfig"
      />
    </div>
  </div>
</template>

<script>
import app from '../../app';
import Calendar from './calendar';
import calendarHelpers from './helpers';
import projectsModel from '../../models/projects';
import newEventPopup from './components/newEventPopup/newEventPopup.vue';
import detailsTooltip from './components/detailsTooltip/detailsTooltip.vue';
import _ from '../../libs/lodash';
import pricingHelper from '../../helpers/pricingHelper';
import rights from '../../components/rights/index';
import pricingStub from './components/pricingStub/stub.vue';
import skeleton from './components/skeleton/skeleton.vue';
import skeletonWrapper from '../common/VueComponents/skeletons/skeletonWrapper.vue';

export default {
  name: 'CalendarView',
  components: {
    newEventPopup, detailsTooltip, pricingStub, skeleton, skeletonWrapper,
  },
  data() {
    return {
      newEventDate: null,
      tooltipConfig: null,
      isShowNewEventPopup: false,
      isTaskSettingsOpened: false,
      isMounted: false,
      isShowSkeleton: false,
      appEvents: [],
    };
  },
  computed: {
    projectId() {
      return projectsModel.getActiveGanttId();
    },
    route() {
      return this.$route;
    },
    styles() {
      const opacity = this.isMounted ? 1 : 0;

      return { width: '100%', opacity };
    },
    pricingAccessToCalendar() {
      return pricingHelper.checkPricingAccess('calendar_view');
    },
    planTitle() {
      return `${pricingHelper.getPlans('calendar_view')}_plan_locale`;
    },
    isOwner() {
      return rights.account.isOwner();
    },
  },
  watch: {
    route() {
      if (this.route.params?.taskId) {
        this.isTaskSettingsOpened = true;
      } else {
        this.isTaskSettingsOpened = false;
      }
    },
  },
  mounted() {
    if (!this.pricingAccessToCalendar) {
      return;
    }

    if (calendarHelpers.checkAccessToTasks(this.projectId)) {
      this.isShowSkeleton = true;
    }

    if (this.$route.params?.taskId) {
      this.isTaskSettingsOpened = true;
    }

    Calendar.init(gantt);

    this.initInstanceListeners();
    this.initAppListeners();
    this.initDOMListeners();

    setTimeout(() => {
      this.isMounted = true;
      this.updateCalendar();
      this.isShowSkeleton = false;
    }, 2000);
  },
  beforeDestroy() {
    if (!this.pricingAccessToCalendar) {
      return;
    }

    this.removeDOMListeners();
    this.removeAppListeners();
    Calendar.destroy();
    this.$refs.calendarView = null;
    this.isMounted = false;
  },
  methods: {
    checkCalendarRoute() {
      return this.$route.name === 'project' && this.$route.params.mode === 'calendar';
    },
    initDOMListeners() {
      window.addEventListener('click', this.handleClick);
      window.addEventListener('mousedown', this.handleMouseDown);
      window.addEventListener('mouseup', this.handleMouseUp);
      window.addEventListener('wheel', _.throttle(this.hideByScroll, 50));
      window.addEventListener('mousemove', this.handleMouseMove);
      window.addEventListener('resize', this.onResize);
    },
    removeDOMListeners() {
      window.removeEventListener('click', this.handleClick);
      window.removeEventListener('mousedown', this.handleMouseDown);
      window.removeEventListener('mouseup', this.handleMouseUp);
      window.removeEventListener('wheel', this.hideByScroll);
      window.removeEventListener('mousemove', this.handleMouseMove);
      window.removeEventListener('resize', this.onResize);
    },
    initInstanceListeners() {
      const instance = Calendar.calendarInstance;
      const debouncedMount = _.debounce(Calendar.mount, 0).bind(Calendar);
      const reactiveState = instance.api.getReactiveState();

      instance.api.on('set-bound', obj => {
        debouncedMount();
      });

      instance.api.on('set-date', obj => {
        debouncedMount();
      });

      instance.api.on('add-event', obj => {
        Calendar.tasksMap.set(obj.taskData.id, obj.taskData);
        Calendar.refresh();
      });

      instance.api.on('delete-event', obj => {
        Calendar.tasksMap.delete(obj.id);
        Calendar.closeTaskSettings();
        Calendar.resetFilterIfNeeded(obj.id);
        Calendar.refresh();
      });

      instance.api.intercept('update-event', obj => Calendar.checkEventBeforeUpdate(obj));

      instance.api.on('show-new-event-popup', obj => {
        this.newEventDate = obj.date;
        this.isShowNewEventPopup = true;
      });

      instance.api.on('show-details', obj => {
        if (obj) {
          this.tooltipConfig = {
            taskData: obj.task,
            coords: obj.tooltipCoords,
          };
        } else {
          this.tooltipConfig = null;
        }
      });

      instance.api.intercept('set-mode', obj => {
        if (obj) return false;
      });

      instance.api.on('update-events', obj => {
        if (obj.projectId === this.projectId) {
          Calendar.parse(obj.tasks);
          this.updateCalendar();
        }
      });

      reactiveState.bounds.subscribe(bounds => {
        Calendar.recalculateCalendarHeight(bounds);
      });
    },
    initAppListeners() {
      const customDaysInsert = app.on('customDays:insert', () => {
        if (this.checkCalendarRoute()) {
          this.updateCalendar();
        }
      });

      const customDaysDelete = app.on('customDays:delete', () => {
        if (this.checkCalendarRoute()) {
          this.updateCalendar();
        }
      });

      const filterSet = app.on('filter:calendar:set', data => {
        const { projectId, type, filter } = data;
        const activeProjectId = this.route?.params?.projectId;
        const firstInit = !this.isMounted;

        if (+activeProjectId === +projectId && type === 'calendarview') {
          const tasks = this.$store.getters['tasksModel/getTasksForGantt'](projectId).data;

          if (!calendarHelpers.hasAccessToAllTasks(projectId)) {
            const accessTasks = calendarHelpers.tasksWithAccess(tasks);

            Calendar.parseFilteredEvents(filter, accessTasks, true, firstInit);

            return;
          }

          Calendar.parseFilteredEvents(filter, tasks, true, firstInit);
        }
      });

      const filterClear = app.on('filter:calendar:clear', () => {
        if (this.checkCalendarRoute()) {
          const projectId = this.route?.params?.projectId;

          if (projectId) {
            const tasks = this.$store.getters['tasksModel/getTasksForGantt'](projectId).data;

            if (!calendarHelpers.hasAccessToAllTasks(projectId)) {
              const accessTasks = calendarHelpers.tasksWithAccess(tasks);

              Calendar.parseEvents(accessTasks, true);

              return;
            }

            Calendar.parseEvents(tasks, true);
          }
        }
      });

      const updateModels = app.on('collaboration:updatedModels', () => {
        if (this.checkCalendarRoute()) {
          this.updateCalendar();
        }
      });

      const changeWorkTime = app.on('change:work:time', () => {
        if (this.checkCalendarRoute()) {
          this.updateCalendar();
        }
      });

      const initModels = app.on('app:initModels', () => {
        if (this.checkCalendarRoute()) {
          this.updateCalendar();
        }
      });

      this.appEvents.push(
        customDaysInsert,
        customDaysDelete,
        filterSet,
        filterClear,
        initModels,
        updateModels,
        changeWorkTime,
      );
    },
    removeAppListeners() {
      this.appEvents.forEach(event => app.off(event));
      this.appEvents = [];
    },
    handleClick(e) {
      if (!this.isTaskSettingsOpened) {
        Calendar.handleClick(e);
      }
    },
    handleMouseDown(e) {
      if (!this.isTaskSettingsOpened) {
        Calendar.handleMouseDown(e);
      }
    },
    handleMouseUp(e) {
      Calendar.handleMouseUp(e);
    },
    handleMouseMove(e) {
      if (!this.isTaskSettingsOpened) {
        Calendar.handleMouseMove(e);
      }
    },
    createNewTask(newTask, date) {
      Calendar.createTask(newTask, date);
    },
    onResize() {
      Calendar.recalculateCalendarHeight();
      Calendar.calcMaxVisibleEvents();
      Calendar.refresh();
    },
    hideByScroll() {
      Calendar.hideDetailsTooltip();
      Calendar.hideHightlights();
    },
    updateCalendar() {
      // hack to artificially trigger an update from the calendar system (needed for render)
      const state = Calendar.calendarInstance.api.getState();
      const calendar = state.calendars[0];

      Calendar.calendarInstance.api.exec('update-calendar', {
        id: calendar.id,
        calendar: {
          ...calendar,
          key: new Date().getTime(),
        },
      });
    },
  },
};
</script>

<style src='./less/main.less' lang='less'></style>
