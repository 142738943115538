var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "up" }, [
    _c(
      "div",
      { class: _vm.$style.header },
      [
        !_vm.isOpenSideBar
          ? _c(
              "div",
              { class: _vm.$style.header_icons },
              [
                !_vm.isOpenSideBar && (!_vm.showIcon || _vm.blockClick)
                  ? _c("svg-sprite", {
                      class: [_vm.$style.short],
                      attrs: { name: "gantt" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isOpenSideBar && _vm.showIcon && !_vm.blockClick
                  ? _c("svg-sprite", {
                      class: [
                        _vm.$style.reverse,
                        _vm.$style.short,
                        _vm.$style.arrow_collapse,
                      ],
                      attrs: {
                        id: "gp_autotest_sidebar_open_button",
                        name: "collaps-2",
                        type: "bold",
                        size: 20,
                      },
                      on: {
                        "!click": function ($event) {
                          return _vm.clickSideBar($event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.header_logo },
          [
            _vm.isOpenSideBar
              ? _c("vgp-svg-image", {
                  class: _vm.$style.logo_icon,
                  attrs: { name: "logo" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { class: [_vm.$style.arrow_back] },
              [
                _vm.isOpenSideBar
                  ? _c("svg-sprite", {
                      class: [_vm.$style.icon],
                      attrs: {
                        id: "gp_autotest_sidebar_close_button",
                        name: "collaps-2",
                        type: "bold",
                        size: 20,
                      },
                      on: {
                        "!click": function ($event) {
                          return _vm.clickSideBar($event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isOpenSideBar
          ? _c(
              "div",
              {
                class: [
                  _vm.$style.team_logo,
                  _vm.teamSettingsAccess ? _vm.$style.owner : "",
                ],
                on: {
                  mouseover: function ($event) {
                    _vm.showSettingsIcon = true
                  },
                  mouseleave: function ($event) {
                    _vm.showSettingsIcon = false
                  },
                },
              },
              [
                _vm.teamLabel.logo
                  ? _c("div", { class: _vm.$style.team_logo_img }, [
                      _c("img", { attrs: { src: _vm.teamLabel.logo } }),
                    ])
                  : [
                      _vm.teamLabel.showFavicon && _vm.teamLabel.favicon
                        ? _c("div", { class: _vm.$style.team_logo_img }, [
                            _c("img", {
                              attrs: { src: _vm.teamLabel.favicon },
                            }),
                          ])
                        : _vm._e(),
                    ],
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.team_logo_teamName,
                      _vm.showSettingsIcon && _vm.teamSettingsAccess
                        ? _vm.team_logo_teamName_hover
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.editHandler()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.teamLabel.name) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.teamSettingsAccess && _vm.showSettingsIcon
                  ? _c("div", { class: _vm.$style.team_logo_rightIcons }, [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$style.team_logo_icons,
                            _vm.$style.edit_icon,
                          ],
                          attrs: {
                            id: "gp_autotest_sidebar_settings_open_button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editHandler()
                            },
                          },
                        },
                        [_c("svg-sprite", { attrs: { name: "settings" } })],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.itemsForContext.length
          ? _c(
              "div",
              { ref: "addProjectBtn" },
              [
                _vm.isOpenSideBar && _vm.itemsForContext.length
                  ? _c(
                      "div",
                      { class: [_vm.$style.button, _vm.$style.add] },
                      [
                        _c("vgp-button", {
                          attrs: {
                            id: "gp_autotest_sidebar_add_project_button",
                            label: _vm.locale("create_new_project"),
                            type: "primary",
                            icon: { name: "plus-1", type: "bold", size: 20 },
                            "icon-side": "left",
                            small: true,
                            width: "236px",
                          },
                          on: { onClick: _vm.addProjectClickBtn },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isOpenSideBar &&
                _vm.itemsForContext.length &&
                !_vm.blockClick
                  ? _c("svg-sprite", {
                      class: [_vm.$style.icon, _vm.$style.addButton],
                      attrs: {
                        id: "gp_autotest_sidebar_add_project_button_short",
                        name: "plus-2",
                        type: "bold",
                        size: "24",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addProjectClickBtn()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.addProjectClick
          ? _c("context-menu", {
              class: _vm.$style.context_menu,
              style: { marginLeft: _vm.isOpenSideBar ? "12px" : "" },
              attrs: {
                items: _vm.itemsForContext,
                position: _vm.positionContext,
                side: _vm.isOpenSideBar ? "" : "right",
              },
              on: {
                selectItemMenu: function ($event) {
                  return _vm.onSelectItemMenu($event)
                },
                onClickOutside: _vm.onClickOutside,
                closePopup: _vm.onClickOutside,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.menu },
      _vm._l(_vm.menu, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("sidebar-menu-item", {
              attrs: {
                "is-open-side-bar": _vm.isOpenSideBar,
                config: item,
                "is-active": _vm.activeItem,
                "unread-count": _vm.unreadCount,
                mention: _vm.mention,
              },
              on: { clickMenuItem: _vm.clickMenuItem },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }