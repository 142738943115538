<template>
  <div
    :class="$style['comment__row']"
  >
    <img
      :src="comment.authorPhoto"
      alt="Comment's author photo"
      :class="$style['comment__author-image']"
    >
    <div :class="$style['comment__info-wrap']">
      <div :class="$style['comment__header']">
        <span :class="$style['comment__author']">
          {{ comment.authorFullName }}
        </span>
        <span :class="$style['comment__date']">
          {{ getCommentDisplayedDateString(comment) }}
        </span>
        <div
          v-if="showCommentActions(comment)"
          :class="$style['comment__actions']"
        >
          <vgp-icon-button
            :icon="{ name: 'delete', size: 16 }"
            @onClick="handleClickDeleteComment(comment.id)"
          />
          <vgp-icon-button
            class="edit-comment-icon-btn"
            :icon="{ name: 'pensil', size: 16 }"
            @onClick="$emit('edit-comment', comment)"
          />
        </div>
      </div>
      <div class="ql-snow">
        <div
          class="ql-editor"
          :class="$style['comment__content']"
          v-html="comment.content"
        />
      </div>
    </div>
  </div>
</template>

<script>

import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';

export default {
  name: 'CommentsItem',
  components: { VgpIconButton },
  inject: ['isFieldDisabled'],
  props: {
    comment: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      locales: __,
      currentCommentIdForDelete: null,
    };
  },
  methods: {
    getCommentDisplayedDateString(comment) {
      if (comment.updatedAt) {
        return `${this.locales('comment_edited_label')} ${comment.updatedAt}`;
      }

      return comment.createdAt;
    },
    showCommentActions(comment) {
      if (this.isFieldDisabled('comments')) return false;

      return comment.authorId === window.user.id;
    },
    handleClickDeleteComment(commentId) {
      this.$emit('comment-delete', commentId);
    },

  },
};
</script>

<style module lang="less">

.comment {
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  &__author-image {
    width: 32px;
    height: 32px;
    border-radius: 33px;
  }

  &__info-wrap {
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 0 4px 4px 4px;
    max-width: 480px;
    padding: 4px 8px;

    &:hover {
      border: 1px solid #F2F2F2;
      background: #FCFCFC;

      .comment__actions {
        opacity: 1;
        transition: 200ms;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  &__author {
    max-width: 280px;
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__date {
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: #999999;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0;
  }

  &__content {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
    max-width: 100%;
    padding: 4px 0 8px 0;
  }

}

</style>
