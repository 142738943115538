var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          class: [
            _vm.props.name + "-icon",
            "vue-icon",
            _vm.data.staticClass,
            _vm.data.class,
          ],
          style: [_vm.data.staticStyle, _vm.data.style],
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [_c(_vm.injections.components[_vm.props.name], { tag: "component" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }