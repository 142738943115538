var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "twoFa-popup" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "popup-title main-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales("twofa_active_header_title")) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "popup-text main-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.locales("twofa_active_view_title")) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "pic" },
          [_c("webix-ui", { attrs: { config: _vm.icons.icSafe } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info-wrapper" }, [
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.locales("twofa_active_view_section_title_01")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "popup-text",
            domProps: {
              innerHTML: _vm._s(
                _vm.locales("twofa_active_view_section_text_01")
              ),
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section" }, [
        _c("div", { staticClass: "pic" }, [
          _c("img", { attrs: { src: _vm.qrPic, alt: "" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-wrapper" }, [
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.locales("twofa_active_view_section_title_02")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "popup-text",
            domProps: {
              innerHTML: _vm._s(
                _vm.locales("twofa_active_view_section_text_02")
              ),
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "pic" },
          [_c("webix-ui", { attrs: { config: _vm.icons.icAuth } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "info-wrapper" },
          [
            _c("div", { staticClass: "main-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.locales("twofa_active_view_section_title_03")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "popup-text",
              style: { marginBottom: 12 + "px" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.locales("twofa_active_view_section_text_03")
                ),
              },
            }),
            _vm._v(" "),
            _c("v-input", {
              attrs: {
                placeholder: _vm.locales(
                  "twofa_activate_section_3_placeholder"
                ),
                "handle-input": _vm.onInput,
                "is-error": _vm.isError,
                value: _vm.value,
                "error-text": _vm.locales(
                  "twofa_active_view_section_input_error"
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "popup-btn blue-btn",
                on: { click: _vm.activateTwoFa },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("twofa_activate_section_3_button")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }