<template>
  <div
    class="closer"
    :style="{width: `${size ? size : 24}px`, height: `${size ? size : 24}px`}"
    @click="closeClick"
  >
    <icon-button
      :icon="{ name:'close-1', type:'bold', size: 24 }"
    />
  </div>
</template>

<script>
import ic_close from '../../../../svg/ic_close.svg';
import svgIconForVue from '../../svgIconForVue';
import iconButton from '../globalButton/withIcon/iconButton.vue';

export default {
  components: { iconButton },
  props: ['onClose', 'size'],
  data() {
    return {
      iconClose: svgIconForVue(ic_close, 24, 24),
    };
  },
  methods: {
    closeClick() {
      this.onClose();
    },
  },
};
</script>

<style lang="less">
	.closer {
		position: absolute;
		right: 15px;
		top: 15px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;

		&:hover {
      //background-color: #eee;
      //border-radius: 2px;

			.webix_template {
				background-color: #eee;
        cursor: pointer;
			}
		}
	}
</style>
