<template>
  <div id="vue-container">
    <stub />
    <v-app>
      <div
        class="page-wrapper"
        :style="windowParams"
      >
        <router-view />
      </div>
      <appFilter />
      <massChangePopup />
      <reload-modal />
      <global-popup v-if="isShowGlobalPopup" />
      <pass-strength-popup v-if="isShowPassStrengthPopup" />
      <payment-plans v-if="isShowPaymentPage" />
      <students-page v-if="isShowStudentsPage" />
      <progress-bar />
      <app-gallery />

      <tooltip-popup
        v-if="isShowPricingPopup"
        :config="getConfig"
      />
      <copyPastePopup />

      <newProjectFromTemplatePopup />
      <currency-popup />

      <portal-target name="popup" />
      <portal-target name="confirm-popup" />
      <portal-target name="right-popup" />
      <portal-target name="task-settings" />
      <vue-gallery />
      <maintenance-notification />
    </v-app>
  </div>
</template>

<script>

import appFilter from './views/filter/filter.vue';
import massChangePopup from './views/massChangeWindow/massChangePopup.vue';
import CurrencyPopup from './views/common/VueComponents/popups/currencyPopup/currencyPopup.vue';
import reloadModal from './views/modals/reloadModal/reloadModal.vue';
import stub from './views/stubs/stubPage.vue';
import projectsModel from './models/projects';
import copyPastePopup from './views/gantt/copyPaste/main.vue';
import progressBar from './views/progressBar/main.vue';
import newProjectFromTemplatePopup from './views/templatePopups/newProjectFromTemplatePopup.vue';
import store from '$$store/main';
import settingErrors from './helpers/settingErrors';

export default {
  name: 'App',
  components: {
    appFilter,
    massChangePopup,
    CurrencyPopup,
    reloadModal,
    stub,
    copyPastePopup,
    progressBar,
    newProjectFromTemplatePopup,
  },
  computed: {
    windowParams() {
      const params = this.$store.getters.getWindowParams;

      return params;
    },
    isShowPermissionPopup() {
      return this.$store.getters['profileSettings/isShowPermissionPopup'];
    },
    isShowGlobalPopup() {
      return this.$store.getters['globalPopup/isShowGlobalPopup'];
    },
    isShowPassStrengthPopup() {
      return this.$store.getters['passStrengthPopup/isShowPassStrengthPopup'];
    },
    isShowPricingPopup() {
      return this.$store.getters['tooltip/isShowPopup'];
    },
    isShowPaymentPage() {
      return this.$store.getters['paymentPlans/isShowPaymentPage'];
    },
    isShowStudentsPage() {
      return this.$store.getters['paymentPlans/isShowStudentsPage'];
    },
    getConfig() {
      return this.$store.getters['tooltip/getConfig'];
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => this.updateWindowParams());
    },
    '$route.params.projectId': {
      handler(val) {
        if (val) {
          const projectData = projectsModel.getProjectDataById(+val);

          if (!projectData.is_archived) {
            _.delay(() => {
              this.$store.dispatch('attachments/fetchAttachmentsByProjectIds', { projectIds: val });
              this.$store.dispatch('comments/fetchCommentsByProjectIds', { projectIds: val });
            }, 500);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', () => {
      this.updateWindowParams();
    });
    gantt.attachEvent('onGanttRender', () => {
      this.$nextTick(() => this.updateWindowParams());
    });
    this.$store.dispatch('paymentModel/loadData');
    this.$store.state.appMode.isBase && this.$store.dispatch('comments/fetchCommentsActions');
    // need fill teamsStore -> settingErrors. Global errors
    settingErrors();
  },
  mounted() {
    this.$nextTick(() => {
      this.updateWindowParams();
      this.loadProgress();
    });
  },
  methods: {
    updateWindowParams() {
      this.$store.dispatch('updateWindowParams', {});
    },
    loadProgress() {
      this.$store.dispatch('progress/loadData');
    },
  },
};
</script>

<style lang="less" scoped>
  .page-wrapper {
    position: absolute;
    z-index: 9;
    background: #fff;
  }

  .gantt-scroll {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 4px;
      background: fade(#000, 40%);
      cursor: pointer;
    }

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
</style>
