var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$.chart, _vm.$.chart_bar_vertical] }, [
    _c(
      "div",
      { class: _vm.$.header },
      [
        _c("div", { class: _vm.$.title }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("chart_" + _vm.chart.key + "_title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.data.mode && !_vm.statusSwitch
          ? _c("div", { class: _vm.$.mode }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("overview_type_" + _vm.data.mode)) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.statusSwitch
          ? _c("vgp-switch", {
              attrs: { "is-toggle-on": _vm.chart.status },
              on: {
                onChange: function ($event) {
                  return _vm.$emit("onToggleStatus")
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.description
      ? _c("div", { class: _vm.$.description }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.locales("chart_" + _vm.chart.key + "_description")) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wrapper },
      [
        _c("div", { ref: "chart", class: _vm.$.body }),
        _vm._v(" "),
        _vm.isShowStub
          ? _c("div", { class: _vm.$.stub }, [
              _c("div", { class: _vm.$.stub_item }),
              _vm._v(" "),
              _c("div", { class: _vm.$.stub_item }),
              _vm._v(" "),
              _c("div", { class: _vm.$.stub_item }),
              _vm._v(" "),
              _c("div", { class: _vm.$.stub_text }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.locales("chart_" + _vm.chart.key + "_stub_text")
                    ) +
                    "\n      "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("loader", { attrs: { show: _vm.isShowLoader } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }