var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.calendar_step },
    [
      _c("ic-button", {
        class: _vm.$.button,
        attrs: { icon: { name: "arrow-left" }, size: "small" },
        on: {
          onClick: function ($event) {
            return _vm.onClickStep(-1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { class: _vm.$.date }, [
        _c("span", [_vm._v(_vm._s(_vm.currentDate))]),
      ]),
      _vm._v(" "),
      _c("ic-button", {
        class: _vm.$.button,
        attrs: { icon: { name: "arrow-right" }, size: "small" },
        on: {
          onClick: function ($event) {
            return _vm.onClickStep(1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }