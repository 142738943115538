var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c("integration-popup", {
            attrs: {
              show: _vm.show,
              "integration-name": "outlook",
              "settings-sync": _vm.settingsSync,
              "existed-links": _vm.existedLinks,
            },
            on: {
              closePopup: _vm.handleCancel,
              createOrUpdateLink: _vm.handleCreateOrUpdate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }