<template>
  <div :class="$.overview_toolbar">
    <div
      v-if="isInitialized"
      :class="$.wrapper"
    >
      <div :class="$.controls">
        <compare-dropdown
          :overview-type="overviewData.type_overview"
          :baseline-id="overviewData.baseline_id"
          :baselines="baselines"
          @onOpen="openCompare"
          @onSelect="changeCompare"
        />
        <div
          v-if="baselineName"
          :class="$.text_label"
        >
          {{ locales('overview_comparison_text') }}: <span>{{ baselineName }}</span>
        </div>
      </div>

      <div :class="$.right_side">
        <div :class="$.btn_group">
          <div
            :class="$.text_button"
            @click="onClickMissedFeature"
          >
            {{ locales("header_contact_btn") }}
          </div>
        </div>

        <vgp-button
          v-if="charts.length"
          :class="$.manage_button"
          type="primary"
          :label="locales('overview_manage_widgets_button')"
          x-small
          @onClick="openManageModal"
        />
      </div>
    </div>
    <overview-toolbar-skeleton v-else />

    <manage-modal
      v-if="isShowModal"
      :show="isShowModal"
      :charts="charts"
      :gantt-id="ganttId"
      @onCancel="closeManageModal"
      @onSave="saveManage"
    />
  </div>
</template>

<script>
import VgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import CompareDropdown from './CompareDropdown.vue';
import ManageModal from './ManageModal.vue';
import OverviewToolbarSkeleton from './overviewToolbarSkeleton.vue';
import app from '../../../app';

export default {
  components: {
    VgpButton,
    CompareDropdown,
    ManageModal,
    OverviewToolbarSkeleton,
  },
  props: {
    overviewData: {
      type: Object,
      required: true,
    },
    baselines: {
      type: Array,
      default: () => ([]),
    },
    ganttId: {
      type: Number,
      required: true,
    },
    isInitialized: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    updateBaselines: null,
    updateOverview: Object,
    updateOverviewCharts: Array,
  },
  data() {
    return {
      locales: __,
      isShowModal: false,
    };
  },
  computed: {
    baselineName() {
      const baseline = this.baselines.find(b => b.id === +this.overviewData.baseline_id);

      return baseline?.name || '';
    },
    charts() {
      if (!this.overviewData.charts) return [];

      return this.overviewData.charts.toSorted((a, b) => {
        if (a.y > b.y) return 1;
        if (a.y < b.y) return -1;
        if (a.x > b.x) return 1;
        if (a.x < b.x) return -1;

        return 0;
      });
    },
  },
  methods: {
    openCompare() {
      this.$emit('updateBaselines');
    },
    changeCompare(data) {
      this.$emit('updateOverview', data);
    },
    saveManage(charts) {
      this.$emit('updateOverviewCharts', charts);
    },
    openManageModal() {
      this.isShowModal = true;
    },
    closeManageModal() {
      this.isShowModal = false;
    },
    onClickMissedFeature() {
      app.trigger('messageUsForm:show', 'overview-feedback', null, 'workload-feedback');
    },
  },
};
</script>

<style module="$" src="../less/overview.less"></style>
