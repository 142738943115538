var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: [_vm.$style[_vm.itemClass], _vm.$style[_vm.itemSizeClass]],
    style: {
      maxWidth: _vm.randomValues[_vm.width] + "px" || "100%",
      width: _vm.randomValues[_vm.width] + "px" || "100%",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }