<template>
  <div
    key="preloder"
    class="connections_table"
  >
    <IntegrationTableHeader />
    <div
      v-for="item in [1,2]"
      :key="item"
      class="connections"
    >
      <div
        v-for="el in [1,2,3]"
        :key="el"
        class="connection"
        :class="{full_border: el === 3}"
      >
        <div class="view_container">
          <div class="connection_project_title_msteams">
            <div
              v-if="el === 1"
              class="text_preloder"
            />
          </div>
          <div
            class="channel_setting_container"
            :class="{small_border: el !== 3}"
          >
            <div class="team_title_msteams">
              <div class="text_preloder" />
            </div>
            <div class="channel_title_msteams">
              <div class="text_preloder" />
            </div>
            <div class="settings_title_msteams">
              <div class="text_preloder" />
            </div>
            <div class="buttons">
              <div class="button_preloder" />
              <div class="button_preloder" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IntegrationTableHeader from './IntegrationTableHeader.vue';

export default {
  components: { IntegrationTableHeader }
};
</script>
