var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowStub
    ? _c(
        "div",
        {
          key: "stub-page-" + _vm.stubType,
          staticClass: "stub-page",
          style: _vm.styleParams,
        },
        [_c("stub", { attrs: { type: _vm.stubType } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }