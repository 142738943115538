<template>
  <div>
    <webix-ui :config="form" /> 
  </div>
</template>

<script>
import app from "./../../app";
import ExportComponent from "./../../components/Export";
import constants from "./../../helpers/constants";
import status from "./../../helpers/status";
import ganttToXlsx from "./xlsxExportHandlers/ganttToXlsx.js"
import ganttViewModel from "../../models/ganttViewModel";
import routerHelper from '../../helpers/router' 

import {
  createButton,
  getTopPartFrom,
  getBottomPartFrom
} from "./webixHelpers";

const Vue = window.Vue;
const __ = window.__;

const formViewID = "exportForm";

const innerHandlers = {
  prepareFormValues: function(values) {
    return {
      autoScheduling: gantt.config.auto_scheduling,
      startOnMonday: gantt.config.start_on_monday,
      baseline: +values.export_baseline,
      criticalWay: values.changeCriticalWay,
      currentDayMarker: values.changeCurrentDayMarker,
      endDateTime: values.endDateTime,
      durationView: gantt.config.duration_view,
      filterOptions: values.filterOptions,
      format: values.export_setting_format,
      ganttDataAreaHeight: values.ganttDataAreaHeight,
      ganttDataAreaWidth: values.ganttDataAreaWidth,
      isLandscape: values.export_setting_orient === "landscape",
      rightSideText: gantt.config.right_side_text,
      skipOffTime: gantt.config.skip_off_time,
      showResourceAvatar: gantt.config.show_resource_avatar,
      resource_loading_type: gantt.config.resource_loading_type,
      startProject: values.changeStartProject,
      start_date: values.start_date,
      startDateTime: values.startDateTime,
      enabledColumns: _.concat(values.enabledColumns),
      view_mode: gantt.config.view_mode,
      viewSize: values.export_setting_size,
      workload: values.changeWorkload,
      zoom: values.export_zoom,
      highlight_overdue: values.highlightOverdue
    };
  },
  exportButtonClick: function() {
    const values = $$(formViewID).getValues();
    const currentRoute = routerHelper.getCurrentRoute();

    userExtAnalytics.log("gantt_export_done", {
      format: values.export_setting_format,
      from: currentRoute.path.startsWith('/project/') ? 'project' : 'portfolio',
    });

    if (values.export_setting_format === "xml") {
      app.trigger("gantt:export:xml", values); //values.export_setting_format
      return;
    }

    if (values.export_setting_format === "xlsx") {
      const projectData = ganttViewModel.getActiveViewData();
      values.title = projectData.name;
      ExportComponent.runXlsx(values);
      return;
    }

    let enabled = [];

    _.each(gantt.config.columns, column => {
      const checkBox = $$(column.name);

      if (checkBox && checkBox.getValue()) {
        enabled.push({ name: column.name, width: column.width });
      }
    });

    values.startDateTime = $$("start_date_time").getValue();
    values.endDateTime = $$("end_date_time").getValue();

    values.enabledColumns = enabled;

    const scaleUnit = constants.ZOOM.zoomValueToMode[values.export_zoom];

    if (values.changeStartProject) {
      values.start_date = gantt.calculateEndDate(
        gantt.config.start_date,
        3,
        scaleUnit
      );
      values.end_date = gantt.config.end_date;
    } else {
      values.start_date = gantt.config.start_date;
      values.end_date = gantt.config.end_date;
    }

    const preparedValues = innerHandlers.prepareFormValues(values);

    ExportComponent.run(preparedValues);
  }
};

gantt.attachEvent("onBeforeGanttRender", function() {
  const $$form = $$(formViewID);

  if ($$form) {
    $$form.config.height =
      document.querySelector("#vue-container .right-bar").offsetHeight - 60;
    $$form.resize();
  }

  const workloadResizer = document.querySelector(".workload-resizer");
  if (workloadResizer) {
    if (gantt.config.hide_workload) {
      workloadResizer.parentNode.style.display = "none";
    } else {
      workloadResizer.parentNode.style.display = "block";
    }
  }

  return true;
});

export default {
  name: "RightBar",
  props: ["showFrame"], 
  data() {
    const component = this;
    return {
      form: {
        view: "form",
        id: formViewID,
        width: 300,
        autoheight: true,
        borderless: true,
        padding: 0,
        margin: 0,
        elements: [
          {
            view: "scrollview",
            id: "scrollview",
            scroll: "y",
            autoheight: true,
            borderless: true,
            width: 300,
            body: {
              paddingY: 18,
              rows: [
                getTopPartFrom(component.toggleFrame),
                { height: 12 },
                {
                  css: "export-divide-line-checkbox",
                  height: 1
                },
                { height: 30 },
                getBottomPartFrom()
              ]
            }
          },
          {
            height: 60,
            css: "export_button_layout",
            rows: [
              {
                paddingY: 12,
                cols: [
                  {},
                  createButton(
                    __("common_export"),
                    innerHandlers.exportButtonClick,
                    "button_blue"
                  ),
                  {}
                ]
              }
            ]
          }
        ]
      },
      localShowFrame: this.showFrame
    };
  },
  methods: {
    toggleFrame(flag, type) {
      this.localShowFrame = flag;

      this.$emit("show-frame-toggle", {
        showFrame: this.localShowFrame,
        exportType: type
      });
    }
  },
  mounted: function() { 
    this.$nextTick(function() {
      const $$form = $$(formViewID);

      $$form.disable();
      $$form.config.height =
        document.querySelector("#vue-container .right-bar").offsetHeight - 60;
      $$form.resize();
    });
  },
  components: {}
};
</script>

<style scoped>
</style>
