var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.vgp_label }, [
    _c(
      "div",
      { class: _vm.$style.vgp_label_header },
      [
        _vm.icon
          ? _c("sprite", {
              class: _vm.$style.icon,
              attrs: {
                color: "#999999",
                name: _vm.icon.name || "check",
                type: _vm.icon.type || "regular",
                size: 24,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.label
          ? _c(
              "span",
              { class: [_vm.$style.vgp_label_text, _vm.$style.elipsis_text] },
              [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                _vm.labelAsterix ? _c("sup", [_vm._v("*")]) : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.action
          ? _c(
              "span",
              {
                class: _vm.$style.vgp_label_action,
                style: _vm.actionStyle ? _vm.actionStyle : {},
                on: { click: _vm.onClick },
              },
              [_vm._v("\n      " + _vm._s(_vm.action) + "\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style.vgp_label_slot }, [_vm._t("body")], 2),
    _vm._v(" "),
    _vm.description
      ? _c("div", { class: _vm.$style.vgp_label_description }, [
          _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }