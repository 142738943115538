import axios from 'axios';
import globalStore from '$$store/main';
import _ from '../../../libs/lodash';
import app from '../../../app';
import ganttFilterHelper from '../../../helpers/gantt_filter';
import listViewHelpers from '../helpers';
import rights from '../../../components/rights';
import pricingHelper from '../../../helpers/pricingHelper';
import bindStatusAndProgressHelper from '../../../helpers/bindStatusAndProgress';
import listViewConstants from '../constants';

const ListViewController = {
  getPreparedHeadersByParams(projectsColumns, activeGanttIds, isProjectType) {
    const availableDefaultColumns = pricingHelper.getAccessToDefaultColumns([
      ...listViewConstants.COLUMNS.PREPEND_COLUMNS,
      ...listViewConstants.COLUMNS.DEFAULT_COLUMNS,
    ]);

    const columns = listViewConstants.COLUMNS.DEFAULT_COLUMNS.filter(col => {
      if (isProjectType && col.property === 'project_name') return false;

      if (availableDefaultColumns.includes(col.property)) return col;

      return false;
    });

    if (pricingHelper.checkPricingAccess('custom_fields')) {
      projectsColumns.forEach(projectColumns => {
        projectColumns?.columns.forEach(columnId => {
          const isAddedColumn = columns.find(col => col.id === columnId);

          if (isAddedColumn) {
            return;
          }

          const column = listViewHelpers.getPreparedCustomColumnById(columnId);

          if (column) {
            column.ganttIds = column.ganttIds.filter(id => activeGanttIds.includes(id));
            column.tooltipExtra = listViewHelpers.createTooltipInfoForCustomColumn(columnId, activeGanttIds);
            column.resizable = true;

            columns.push(column);
          }
        });
      });
    }

    const addCostCol = activeGanttIds.some(gantt_id => rights.project.hasCostRight(gantt_id));

    if (addCostCol && pricingHelper.checkPricingAccess('costs')) {
      columns.splice(6, 0, ...listViewConstants.COLUMNS.COST_COLUMNS);
    }

    columns.map(item => {
      if (item.id === 'status') {
        activeGanttIds.map(ganttId => item.forJiraProject[ganttId] = globalStore.getters['columns/getJiraColumnByNameAndProjectId']('status', ganttId));
      }

      if (item.id === 'priority') {
        activeGanttIds.map(ganttId => item.forJiraProject[ganttId] = globalStore.getters['columns/getJiraColumnByNameAndProjectId']('priority', ganttId));
      }
    });

    return columns;
  },

  getPreparedTasksByParams(tasks, projectsColumns, filterState) {
    const customColumns = pricingHelper.checkPricingAccess('custom_fields')
      ? projectsColumns.reduce((columns, project) => Object.assign(columns, listViewHelpers.getCustomColumnsByProject(project)), {})
      : {};

    const preparedTasks = listViewHelpers.prepareTasks(tasks, customColumns);

    let filtredTasks = [];

    if (filterState) {
      const filter = ganttFilterHelper.getFilter(window.gantt, {
        options: filterState,
        value: filterState.text || '',
      }, null);

      filtredTasks = preparedTasks.filter(task => filter(task));

      if (!filtredTasks.length) {
        app.trigger('filter:tasks:not:found');
      }
    } else {
      filtredTasks = preparedTasks;
    }

    globalStore.commit('headerView/setListTasksCount', filtredTasks.length);
    globalStore.commit('headerView/setMyTasksFilter', listViewHelpers.getMyTasksFilterStatus(filterState));

    return filtredTasks;
  },

  async getConfig(project_id, multiview_id) {
    const res = await axios.get('api/list', { user_id: user.id });

    let config = _.find(res.data, { project_id, multiview_id });

    if (!config) {
      const defaultConf = {
        multiview_id,
        project_id,
        columns: JSON.stringify(listViewConstants.CONFIG.DEFAULT_COLUMNS),
        settings: JSON.stringify(listViewConstants.CONFIG.DEFAULT_SETTINGS),
      };

      const res = await axios.post('api/list', defaultConf);

      config = _.find(res.data, { project_id, multiview_id });
    }

    const result = listViewHelpers.getSettingsFromConfig(config);

    globalStore.commit('headerView/assignViewSettings', result.viewSettings);
    globalStore.commit('headerView/setOverdueTasks', result.overdue);

    return result;
  },

  async updateConfig(currentConfig, update) {
    if (!update.columns && !update.settings) {
      return currentConfig;
    }

    const body = {
      project_id: currentConfig.project_id,
      multiview_id: currentConfig.multiview_id,
    };

    if (update.columns) {
      body.columns = JSON.stringify(update.columns);
    }

    if (update.settings) {
      body.settings = JSON.stringify(update.settings);
    }

    const response = await axios.put(`api/list/${currentConfig.id}`, body);
    const newConfig = _.find(response.data, { project_id: currentConfig.project_id, multiview_id: currentConfig.multiview_id });

    if (!newConfig) {
      return currentConfig;
    }

    return listViewHelpers.getSettingsFromConfig(newConfig);
  },

  createItem(item, projectId) {
    gantt.config.creatingMilestoneFlag = item.type === 'milestone';

    const parentId = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](projectId).id;

    app.trigger('createNewTask', parentId, item.name);
  },

  async changeTask(edit) {
    let updateByWorker = ['start_date', 'end_date', 'duration', 'progress'].find(i => i === edit.property);
    const originalTaskData = globalStore.getters['tasksModel/getTaskByGanttId'](edit.task.gantt_id, edit.task.id);
    const task = _.cloneDeep(originalTaskData);
    const value = listViewHelpers.validateTaskValue(edit.value, edit.property, originalTaskData);

    task[edit.property] = value;

    if (edit.property === 'status') {
      task.progress = bindStatusAndProgressHelper.calc(value, null).progress;
      if (task.progress !== originalTaskData.progress) updateByWorker = true;
    }

    if (updateByWorker) {
      await gantt.ganttWorker.calculate([task], 'changeTasks', 'updateTasks');
    } else {
      await globalStore.dispatch('tasksModel/backgroundUpdateTask', { taskData: task });
    }

    switch (edit.property) {
    case 'progress':
      task.status = bindStatusAndProgressHelper.calc(null, value * 100).status;
      await globalStore.dispatch('tasksModel/changeStatus', {
        statusValue: task.status,
        taskData: task,
      });
      break;
    case 'status':
      await globalStore.dispatch('tasksModel/changeStatus', {
        statusValue: value,
        taskData: task,
      });
      break;
    case 'priority':
      await globalStore.dispatch('tasksModel/changePriority', {
        priorityValue: value,
        taskData: task,
      });
      break;
    case 'duration':
      app.trigger('changedTaskDuration', task, originalTaskData);
      break;
    case 'estimation':
      app.trigger('changedTaskEstimation', task, true);
      break;
    }

    Object.assign(edit.task, task);
  },

  changeTaskCustom(edit) {
    const originalTaskData = globalStore.getters['tasksModel/getTaskByGanttId'](edit.task.gantt_id, edit.task.id);
    // edit.task[edit.column.property] = edit.value;

    let value = typeof edit.value === 'undefined' || edit.value == null ? '' : edit.value;

    if (edit.column.isOptions) {
      if (Array.isArray(value)) {
        value = value.map(i => i.id).join(',');
      }
      if (_.isObject(value)) {
        value = value.id.toString();
      }
    }

    gantt.callEvent('onChangeUserCustomValue', [originalTaskData, edit.column.id, value]);
  },

  async deleteTask(task) {
    if (gantt.isTaskExists(task.id)) {
      gantt.deleteTask(task.id);
    } else {
      await gantt.ganttWorker.calculate([task], 'changeTasks', 'removeTasks');
      globalStore.dispatch('tasksModel/backgroundDeleteTask', task);
    }

    return true;
  },
};

export default ListViewController;
