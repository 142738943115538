<template>
  <section :class="$style['task-settings-buttons']">
    <template v-if="!isFieldDisabled('resources_visibility')">
      <assigned-resources-chips
        v-if="formattedAssignedResources.length"
        :resources="formattedAssignedResources"
        :task-data="taskData"
        :is-active="isAssignResourcesPopupShown"
        class="vgp-interactive-element"
        @click="showAssignResourcesPopup"
      />
      <vgp-icon-button
        v-else
        :class="$style['task-settings-buttons__item']"
        :icon="{ name: 'assigned' }"
        size="middle"
        bold
        :disabled="isFieldDisabled('resources')"
        :label="locales('settings_task_resources_add_new')"
        :colors="buttonColors"
        :sizes="buttonLabelSizes"
        :active="isAssignResourcesPopupShown"
        @onClick="showAssignResourcesPopup"
      />
    </template>
    <vgp-icon-button
      ref="newAttachmentButton"
      :class="$style['task-settings-buttons__item']"
      :icon="{ name: 'attach' }"
      size="middle"
      bold
      :label="locales('settings_task_attachment_add_new')"
      :colors="buttonColors"
      :sizes="buttonLabelSizes"
      :active="isNewAttachmentMenuShown"
      :disabled="isFieldDisabled('attachments')"
      @onClick="showNewAttachmentMenu"
    />
    <new-attachment-context-menu
      v-if="isNewAttachmentMenuShown"
      :position-context="newAttachmentMenuPosition"
      :task-data="taskData"
      @attachment-created="$emit('attachment-created')"
      @close="hideNewAttachmentMenu"
    />
    <vgp-icon-button
      :class="$style['task-settings-buttons__item']"
      :icon="{ name: 'project_name' }"
      size="middle"
      bold
      :label="locales('settings_task_dependency_add_new')"
      :colors="buttonColors"
      :sizes="buttonLabelSizes"
      :disabled="isFieldDisabled('dependencies')"
      @onClick="showNewDependencyForm"
    />
    <div
      :class="[
        $style['time-log-button-wrapper'],
        isFieldDisabled('time_log_pricing') ? 'pricing-tooltip' : ''
      ]"
      data-feature="time_log"
      data-position="bottom"
    >
      <vgp-icon-button
        ref="timeLogButton"
        v-click-outside="{
          handler: onTimeLogButtonOutsideClick,
          closeConditional: () => isTimeLogPopupShown
        }"
        :class="[
          $style['task-settings-buttons__item'],
          $style['time-log-button'],
        ]"
        :icon="{ name: 'time' }"
        size="middle"
        bold
        :disabled="isFieldDisabled('time_log')"
        :label="locales('settings_task_time_add_new')"
        :colors="buttonColors"
        :sizes="buttonLabelSizes"
        :active="isTimeLogPopupShown"
        @onClick="showTimeLogPopup"
      />
    </div>
  </section>
</template>

<script>
import { throttle } from 'lodash';
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import NewAttachmentContextMenu from '../attachments/includes/NewAttachmentContextMenu.vue';
import AssignedResourcesChips from './includes/AssignedResourcesChips.vue';

export default {
  name: 'TaskSettingsButtons',
  components: { AssignedResourcesChips, NewAttachmentContextMenu, VgpIconButton },
  inject: ['isFieldDisabled'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      isTimeLogPopupShown: false,
      isNewAttachmentMenuShown: false,
      isAssignResourcesPopupShown: false,
      newAttachmentMenuPosition: null,
      buttonColors: {
        color: '#1565C0',
        labelColor: '#1565C0',
        activeColor: '#11519A',
        hoverBg: '#D0E0F2',
        activeBg: '#A1C1E6',
        activeHoverBg: '#D0E0F2',
      },
      buttonLabelSizes: {
        labelFontSize: '14px',
        labelLineHeight: '24px',
      },
    };
  },
  computed: {
    formattedAssignedResources() {
      const assignedResources = this.$store.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](this.taskData.gantt_id, this.taskData.id);

      return assignedResources.map(resource => ({
        ...resource,
        avatar: resource.photo || resource.resourcePhoto || `${GT.cdn}/imgs/default/default-ava.svg`,
      }));
    },
  },
  watch: {
    disabled() {
      if (this.disabled.resources === null) {
        this.hideAssignResourcesPopup();
      }
      if (this.disabled.time_log === null) {
        gantt.callEvent('hideTimeTrackingPopup');
        this.onHideTimeTrackingPopup();
      }
      if (this.disabled.attachments === null) this.hideNewAttachmentMenu();
    },
    'taskData.type': function (newVal) {
      if (newVal === gantt.config.types.project && this.isAssignResourcesPopupShown) {
        this.hideAssignResourcesPopup();
      }
    },
  },
  mounted() {
    this.addScrollHandler();
  },
  beforeDestroy() {
    gantt.callEvent('hideResourcesListForTask');
    this.removeScrollHandler();
  },
  methods: {
    onTimeLogButtonOutsideClick(event) {
      // if click to footer time log button
      if (event.target.closest('[data-feature="time_tracking"]')) {
        this.onHideTimeTrackingPopup();
      }
    },
    addScrollHandler() {
      const scrollDivs = document.querySelectorAll('div.gantt_scroll');

      scrollDivs.forEach(div => div.addEventListener('scroll', this.onScroll));
    },
    removeScrollHandler() {
      const scrollDivs = document.querySelectorAll('div.gantt_scroll');

      scrollDivs.forEach(div => div.removeEventListener('scroll', this.onScroll));
    },
    onScroll: throttle(function () {
      if (this.isAssignResourcesPopupShown) {
        this.hideAssignResourcesPopup();
      }

      if (this.isTimeLogPopupShown) {
        gantt.callEvent('hideTimeTrackingPopup');
        this.onHideTimeTrackingPopup();
      }
    }, 100),
    hideAssignResourcesPopup() {
      gantt.callEvent('hideResourcesListForTask');
      this.onAssignResourcesPopupHide();
    },
    onAssignResourcesPopupHide() {
      this.isAssignResourcesPopupShown = false;
    },
    onHideTimeTrackingPopup() {
      this.isTimeLogPopupShown = false;
    },
    showNewDependencyForm() {
      if (this.isFieldDisabled('dependencies')) return;
      this.$emit('show-new-dependency-form');
    },
    showAssignResourcesPopup(event) {
      if (this.isFieldDisabled('resources') || this.isAssignResourcesPopupShown) return;

      const pos = event.currentTarget.getBoundingClientRectWrapper();

      pos.y += 4;
      pos.x += 8;

      gantt.callEvent('showResourcesListForTask', [pos, this.taskData.id, this.taskData.gantt_id, true, 'taskView', this.onAssignResourcesPopupHide]);
      this.isAssignResourcesPopupShown = true;
    },
    showTimeLogPopup() {
      if (this.isFieldDisabled('time_log') || this.isTimeLogPopupShown) return;

      const pos = this.$refs.timeLogButton.$el.getBoundingClientRectWrapper();

      pos.y += 4;

      gantt.callEvent('showTimeTrackingPopup', [
        'task',
        pos,
        this.taskData.id,
        this.onHideTimeTrackingPopup,
      ]);

      this.isTimeLogPopupShown = true;
    },
    showNewAttachmentMenu() {
      if (this.isFieldDisabled('attachments')) return;

      setTimeout(() => {
        this.newAttachmentMenuPosition = this.$refs.newAttachmentButton.$el.getBoundingClientRect();
        this.isNewAttachmentMenuShown = true;
      }, 0);
    },
    hideNewAttachmentMenu() {
      this.newAttachmentMenuPosition = null;
      this.isNewAttachmentMenuShown = false;
    },
  },
};
</script>

<style module lang="less">

.task-settings-buttons {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 20px !important;

  &__item {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }

  .time-log-button-wrapper {
    margin-left: auto;
  }

  :global(.pricing-tooltip) {
    background-color: unset !important;
  }
}

</style>
