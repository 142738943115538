var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("skeleton-wrapper", {
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.skeleton_wrapper }, [
              _c("div", { class: [_vm.$.skeleton_box, _vm.$.first] }, [
                _c(
                  "div",
                  { class: _vm.$.skeleton_row_main },
                  [
                    _c(
                      "div",
                      { class: _vm.$.skeleton_mb30 },
                      [
                        _c("skeleton-item", {
                          style: { width: "124px", height: "12px" },
                          attrs: { type: "bar" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("skeleton-item", {
                      style: { width: "496px", height: "12px" },
                      attrs: { type: "bar" },
                    }),
                    _vm._v(" "),
                    _c("skeleton-item", {
                      style: { width: "496px", height: "12px" },
                      attrs: { type: "bar" },
                    }),
                    _vm._v(" "),
                    _c("skeleton-item", {
                      style: { width: "344px", height: "12px" },
                      attrs: { type: "bar" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$.skeleton_row_main }, [
                  _c(
                    "div",
                    { class: [_vm.$.skeleton_line, _vm.$.skeleton_mb22] },
                    [
                      _c("div", { staticStyle: { "margin-right": "105px" } }, [
                        _c(
                          "div",
                          { class: [_vm.$.skeleton_line, _vm.$.skeleton_mb8] },
                          [
                            _c(
                              "div",
                              { class: [_vm.$.skeleton_mr8] },
                              [
                                _c("skeleton-item", {
                                  style: { width: "52px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "100px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.skeleton_line },
                          [
                            _c(
                              "div",
                              { class: [_vm.$.skeleton_mr8] },
                              [
                                _c("skeleton-item", {
                                  style: { width: "52px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "100px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { class: [_vm.$.skeleton_mb8] },
                          [
                            _c("skeleton-item", {
                              style: { width: "52px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("skeleton-item", {
                              style: { width: "100px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$.skeleton_line] }, [
                    _c("div", { class: [_vm.$.skeleton_mr24] }, [
                      _c(
                        "div",
                        { class: [_vm.$.skeleton_mb16] },
                        [
                          _c("skeleton-item", {
                            style: { width: "100px", height: "12px" },
                            attrs: { type: "bar" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("skeleton-item", {
                            style: { width: "52px", height: "12px" },
                            attrs: { type: "bar" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$.skeleton_mr16] }, [
                      _c(
                        "div",
                        { class: [_vm.$.skeleton_mb16] },
                        [
                          _c("skeleton-item", {
                            style: { width: "100px", height: "12px" },
                            attrs: { type: "bar" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("skeleton-item", {
                            style: { width: "52px", height: "12px" },
                            attrs: { type: "bar" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: [_vm.$.skeleton_bl, _vm.$.skeleton_pl24] },
                      [
                        _c(
                          "div",
                          { class: [_vm.$.skeleton_mb16] },
                          [
                            _c("skeleton-item", {
                              style: { width: "100px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("skeleton-item", {
                              style: { width: "52px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$.skeleton_flex_row }, [
                _c(
                  "div",
                  { class: [_vm.$.skeleton_box, _vm.$.direction_column] },
                  [
                    _c(
                      "div",
                      { class: [_vm.$.skeleton_mb26] },
                      [
                        _c("skeleton-item", {
                          style: { width: "124px", height: "12px" },
                          attrs: { type: "bar" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.skeleton_mb26,
                          _vm.$.skeleton_flex,
                          _vm.$.skeleton_flex_row_center,
                          _vm.$.skeleton_flex_column_center,
                        ],
                      },
                      [
                        _c(
                          "div",
                          { class: [_vm.$.skeleton_circle] },
                          [
                            _c("skeleton-item", {
                              style: {
                                width: "241px",
                                height: "241px",
                                "border-width": "50px",
                              },
                              attrs: { type: "donut" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { class: [_vm.$.skeleton_list] }, [
                          _c(
                            "div",
                            {
                              class: [_vm.$.skeleton_mb12, _vm.$.skeleton_flex],
                            },
                            [
                              _c("skeleton-item", {
                                style: { width: "24px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [_vm.$.skeleton_mb12, _vm.$.skeleton_flex],
                            },
                            [
                              _c("skeleton-item", {
                                style: { width: "24px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [_vm.$.skeleton_mb12, _vm.$.skeleton_flex],
                            },
                            [
                              _c("skeleton-item", {
                                style: { width: "24px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: [_vm.$.skeleton_flex] },
                            [
                              _c("skeleton-item", {
                                style: { width: "24px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.skeleton_flex,
                          _vm.$.skeleton_flex_row_center,
                          _vm.$.skeleton_flex_column_center,
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_row_center,
                              _vm.$.skeleton_flex_column_center,
                              _vm.$.skeleton_line_padding,
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.skeleton_flex,
                                  _vm.$.skeleton_mr16,
                                ],
                              },
                              [
                                _c("skeleton-item", {
                                  style: { width: "52px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                                _vm._v(" "),
                                _c("skeleton-item", {
                                  style: { width: "24px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.skeleton_flex,
                                  _vm.$.skeleton_bl,
                                  _vm.$.skeleton_mr16,
                                  _vm.$.skeleton_pl24,
                                ],
                              },
                              [
                                _c("skeleton-item", {
                                  style: { width: "52px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("skeleton-item", {
                                      style: { width: "24px", height: "12px" },
                                      attrs: { type: "bar" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.skeleton_flex,
                                  _vm.$.skeleton_bl,
                                  _vm.$.skeleton_pl24,
                                ],
                              },
                              [
                                _c("skeleton-item", {
                                  style: { width: "52px", height: "12px" },
                                  attrs: { type: "bar" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("skeleton-item", {
                                      style: { width: "24px", height: "12px" },
                                      attrs: { type: "bar" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: [_vm.$.skeleton_box, _vm.$.direction_column] },
                  [
                    _c(
                      "div",
                      { class: [_vm.$.skeleton_mb26] },
                      [
                        _c("skeleton-item", {
                          style: { width: "124px", height: "12px" },
                          attrs: { type: "bar" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$.skeleton_chart,
                          _vm.$.skeleton_flex,
                          _vm.$.skeleton_flex_row_center,
                          _vm.$.skeleton_flex_column_center,
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_mb26,
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_mb26,
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_mb26,
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_mb26,
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_mb26,
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.$.skeleton_flex,
                              _vm.$.skeleton_flex_column_center,
                            ],
                          },
                          [
                            _c("skeleton-item", {
                              style: { width: "24px", height: "12px" },
                              attrs: { type: "bar" },
                            }),
                            _vm._v(" "),
                            _c("skeleton-item", {
                              style: { width: "349px", height: "1px" },
                              attrs: { type: "bar" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { class: [_vm.$.skeleton_chart_columns] }, [
                          _c(
                            "div",
                            { class: [_vm.$.skeleton_chart_columns_item] },
                            [
                              _c("skeleton-item", {
                                style: { width: "57px", height: "77px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: [_vm.$.skeleton_chart_columns_item] },
                            [
                              _c("skeleton-item", {
                                style: { width: "57px", height: "191px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { class: [_vm.$.skeleton_chart_columns_item] },
                            [
                              _c("skeleton-item", {
                                style: { width: "57px", height: "130px" },
                                attrs: { type: "bar" },
                              }),
                              _vm._v(" "),
                              _c("skeleton-item", {
                                style: { width: "52px", height: "12px" },
                                attrs: { type: "bar" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }