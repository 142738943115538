<template>
  <core-popup
    id="gp_autotes_transfer_owner_popup"
    :show="show"
    :title="title"
    width="420"
  >
    <template #body>
      <div class="transfer-ownership">
        <div class="subtitle">
          {{ subtitle }}
        </div>
        <div
          v-if="config && config.type === 'resource'"
          class="projects"
        >
          <div class="projects-toggle">
            <button
              id="gp_autotes_transfer_owner_popup_toggle_projects"
              type="button"
              :class="{'open': isShowProjects}"
              @click="handleToggleProjects"
            >
              {{ locales('transfer_ownership_toggle_projects') }}
              <svg-sprite
                name="drop-down"
                type="bold"
                class="button-icon"
              />
            </button>
          </div>
          <div
            v-show="isShowProjects"
            class="projects-wrap"
            :class="{'haze': !isEndScrolledProjects}"
          >
            <div
              ref="projectsScroll"
              v-scroll.self="handleScrollProjects"
              class="projects-scroll gantt_scroll"
            >
              <div class="projects-list">
                <div
                  v-for="item in projectsList"
                  :key="item.id"
                  class="projects-item"
                >
                  <span class="projects-item__label">{{ item.label }}</span>
                  <span class="projects-item__name">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form">
          <vgp-label-slot
            :label="locales('transfer_ownership_select_label')"
            :description="description"
          >
            <template #body>
              <vgp-dropdown-select
                gp-autotest-name="gp_autotes_transfer_owner_popup_dropdown_select_resources"
                :placeholder="locales('transfer_ownership_select_placeholder')"
                :drop-list="filteredResources"
                :use-search="true"
                :multiselect="false"
                :show-reset-btn="false"
                item-template="user"
                track-by-img-url="photo"
                track-by-additional-for-item="aditionalElement"
                :selected="selectedResource"
                @selected="handleSelect"
              />
            </template>
          </vgp-label-slot>
        </div>
        <div class="actions">
          <vgp-button
            id="gp_autotes_transfer_owner_popup_btn_cancel"
            class="action-button"
            type="secondary"
            :small="true"
            :width="'100px'"
            :label="locales('common_cancel')"
            @onClick="onClose"
          />
          <vgp-button
            id="gp_autotes_transfer_owner_popup_btn_accept"
            class="action-button"
            :small="true"
            :width="'100px'"
            :type="config.type !== 'change' ? 'destructive' : 'primary'"
            :label="config.type !== 'change' ? locales('common_delete') : locales('common_ok')"
            :disabled="!selectedResource"
            @onClick="transferRole"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import { internalApi, api } from '$$store/api';
import CorePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import VgpDropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import rights from '../../components/rights';
import projectsModel from '../../models/projects';
import routerHelper from '../../helpers/router';
import app from '../../app';

export default {
  name: 'TransferOwnershipModal',
  components: {
    CorePopup,
    VgpLabelSlot,
    VgpDropdownSelect,
  },
  props: {
    show: { type: Boolean, default: false },
    config: { type: Object, default: null },
  },
  emits: {
    onClose: null,
    onDelete: String,
  },
  data() {
    return {
      locales: __,
      selectedResource: null,
      isShowProjects: false,
      isEndScrolledProjects: false,
    };
  },
  computed: {
    resources() {
      return this.$store.getters['resourcesModel/getAllRealResources'];
    },
    accountRolesHasProjectCreate() {
      return rights.account.getRolesByBits(['project_create']);
    },
    projectRoles() {
      return this.$store.getters['roles/getProjectRoles'];
    },
    defaultProjectRole() {
      const adminRole = this.projectRoles.find(role => role.defaultType === 'ADMIN');
      const memberRole = this.projectRoles.find(role => role.defaultType === 'MEMBER');
      const defaultRole = this.projectRoles.find(role => role.isDefault);
      const resourceRole = rights.account.getUserRole(this.currentProjectOwnerResource?.user_id);
      const isAccountOwner = resourceRole?.defaultType === 'OWNER';
      const isKing = resourceRole?.rights.find(bitObj => bitObj.bit === 2)?.status;

      return isAccountOwner || isKing ? adminRole : defaultRole || memberRole;
    },
    ownerProjectRole() {
      return this.projectRoles.find(role => role?.defaultType === 'OWNER');
    },
    currentProject() {
      return projectsModel.getActiveProjectData();
    },
    currentProjectOwnerResource() {
      return this.resources.find(resource => resource.resourceProjects.find(settings => settings.projectId === this.currentProject?.gantt_id && settings.projectRoleId === this.ownerProjectRole.id));
    },
    removeResource() {
      if (!this.config || this.config?.type === 'change') return null;

      return this.resources.find(resource => resource.id === this.config.resource_id);
    },
    removeResourceOwnerProjects() {
      if (!this.removeResource) return [];

      return this.removeResource.resourceProjects.filter(project => project.projectRoleId === this.ownerProjectRole.id);
    },
    projectsList() {
      return this.removeResourceOwnerProjects.map(project => {
        let projectData = projectsModel.getProjectDataById(project.projectId);

        if (!projectData) {
          projectData = projectsModel.getArchivedProject(project.projectId);
        }

        const projectName = projectData?.name;

        return {
          id: project.projectId,
          label: projectName.split(' ').slice(0, 2).map(name => name[0]).join(''),
          name: projectName,
        };
      });
    },
    ganttIds() {
      let arr = [];

      if (this.config?.type === 'project') {
        arr = [this.config.gantt_id];
      } else if (this.config?.type === 'resource') {
        arr = this.removeResourceOwnerProjects.map(project => project.projectId);
      } else {
        arr = [this.currentProject.gantt_id];
      }

      return arr;
    },
    filteredResources() {
      const defaultAvatar = `${GT.cdn}/imgs/default/default-ava.svg`;
      const filteredResources = [];
      const resourcesWithCreateRights = this.resources.filter(resource => {
        if (
          (this.config.type === 'change' && resource.id === this.currentProjectOwnerResource.id)
          || resource.id === this.config?.resource_id
        ) return false;

        return this.accountRolesHasProjectCreate.find(role => role?.id === resource?.accountRoleId);
      });

      resourcesWithCreateRights.forEach(item => {
        const resource = { ...item };

        resource.photo = item.photo ? item.photo : defaultAvatar;
        resource.aditionalElement = {
          text: item.email,
          bgColor: '#F8F8F8',
          round: 4,
          paddingLeft: 8,
          paddingRight: 8,
          preset: 'custom',
          txtColor: '#333333',
          useInSearch: true,
        };

        filteredResources.push(resource);
      });

      return filteredResources;
    },
    title() {
      let string = '';

      if (this.config?.type === 'project') {
        string = this.locales('transfer_ownership_title_remove_from_project');
      } else if (this.config?.type === 'resource') {
        string = this.locales('transfer_ownership_title_remove_from_account');
      } else {
        string = this.locales('transfer_ownership_title_transfer_owner_project');
      }

      return string;
    },
    subtitle() {
      let string = '';

      if (this.config?.type === 'project') {
        // string = `${this.locales('transfer_ownership_subtitle_remove_from_project')} ${this.removeResource.name}.`;
        string = `${this.locales('transfer_ownership_subtitle_remove_from_project')}`;
      } else if (this.config?.type === 'resource') {
        // string = `${this.locales('transfer_ownership_subtitle_remove_from_account_part_1')} ${this.removeResource.name}. ${this.locales('transfer_ownership_subtitle_remove_from_account_part_2')} ${this.removeResourceOwnerProjects.length} ${this.locales('transfer_ownership_subtitle_remove_from_account_part_3')}.`;
        string = `${this.locales('transfer_ownership_subtitle_remove_from_account')}`;
      } else {
        // string = `${this.locales('transfer_ownership_subtitle_transfer_owner_project_part_1')} ${this.currentProject.name} ${this.locales('transfer_ownership_subtitle_transfer_owner_project_part_2')} ${this.currentProjectOwnerResource.name}.`;
        string = `${this.locales('transfer_ownership_subtitle_transfer_owner_project')}`;
      }

      return string;
    },
    description() {
      let string = '';

      // string = this.config ? '' : `${this.locales('transfer_ownership_select_description_part_1')} ${this.defaultProjectRole.title} ${this.locales('transfer_ownership_select_description_part_2')}.`;
      string = this.config ? '' : `${this.locales('transfer_ownership_select_description')}`;

      if (string.length !== 0) {
        string.replace('%{default_role}', this.defaultProjectRole.title);
      }

      return string;
    },
  },
  watch: {
    resources(newResources) {
      const hasSelected = newResources.find(resource => resource.id === this.selectedResource?.id);

      if (!hasSelected) {
        this.selectedResource = null;
      }
    },
  },
  beforeCreate() {
    app.on('onAfterCollaboration', data => {
      if (this.show) {
        if (data.event === 'ProjectDeleted') {
          const hasProject = data.projects.some(id => this.projectsList.some(project => project.id === id));

          if (hasProject) {
            this.$store.dispatch('resourcesModel/reloadData');
          }
        }

        if (data.event === 'ResourceUpdated' && this.show && this.selectedResource) {
          const resourceId = data.resourceIds.find(id => id === this.selectedResource.id);

          if (resourceId) {
            const resourceData = this.$store.getters['resourcesModel/getResourceById'](resourceId);
            const hasRightToProjectCreate = this.accountRolesHasProjectCreate.find(role => role?.id === resourceData?.accountRoleId);

            if (!hasRightToProjectCreate) {
              this.selectedResource = null;
            }
          }
        }
      }
    });
  },
  methods: {
    handleSelect(data) {
      this.selectedResource = data.length ? data[0] : null;
    },
    async transferRole() {
      const projectIds = [];

      this.ganttIds.forEach(projectId => {
        const projectData = projectsModel.getProjectDataById(projectId);
        const archiveProjectData = projectsModel.getArchivedProject(projectId);

        if (projectData || archiveProjectData) {
          projectIds.push(projectId);
        }
      });

      const newProjectOwner = this.$store.getters['resourcesModel/getResourceById'](this.selectedResource.id);
      const oldProjectOwner = this.$store.getters['resourcesModel/getResourceById'](this.config.resource_id);
      const newOwnerProjectsIds = newProjectOwner.resourceProjects.map(pr => pr.projectId);
      const projectsWithoutResource = projectIds.filter(id => !newOwnerProjectsIds.includes(id));
      const projectsWhereHasResource = projectIds.filter(id => newOwnerProjectsIds.includes(id));

      await internalApi.put(`/resources/changeProjectOwner?resourceId=${this.selectedResource.id}`, {
        projectId: projectIds,
        roleId: this.defaultProjectRole.id,
        isRemove: this.config.type === 'resource' || this.config.type === 'project',
        isArchived: true,
      });

      if (this.config.type === 'change') {
        this.updateResourceOnProjects(oldProjectOwner.id, projectIds, this.defaultProjectRole.id);

        if (projectsWithoutResource.length) {
          this.addResourceToProjects(this.selectedResource.id, projectsWithoutResource, this.ownerProjectRole.id);
        } else {
          this.updateResourceOnProjects(this.selectedResource.id, projectIds, this.ownerProjectRole.id);
        }

        this.$toast.success(__('succsess_transfer_ownership_message'));
      }

      if (this.config.type === 'project') {
        this.removeResourceFromProjects(oldProjectOwner.id, projectIds);

        if (projectsWithoutResource.length) {
          this.addResourceToProjects(this.selectedResource.id, projectsWithoutResource, this.ownerProjectRole.id);
        } else {
          this.updateResourceOnProjects(this.selectedResource.id, projectIds, this.ownerProjectRole.id);
        }
      }

      if (this.config.type === 'resource') {
        this.removeResourceFromAccount(oldProjectOwner.id);

        if (projectsWithoutResource.length) {
          this.addResourceToProjects(this.selectedResource.id, projectsWithoutResource, this.ownerProjectRole.id);
        }

        if (projectsWhereHasResource.length) {
          this.updateResourceOnProjects(this.selectedResource.id, projectsWhereHasResource, this.ownerProjectRole.id);
        }
      }

      if (routerHelper.getCurrentRoute().params.mode === 'resources' && !rights.account.isOwner() && !rights.account.isKingMode()) {
        location.reload();
      }

      this.onClose();
    },
    updateResourceOnProjects(resourceId, projectIds, roleId) {
      this.$store.commit('resourcesModel/updateResourceOnProjects', {
        resourceId,
        projectIds,
        updated: {
          projectRoleId: roleId,
        },
      });
    },
    addResourceToProjects(resourceId, projectsIds, roleId) {
      const resourceProjects = projectsIds.map(projectId => ({
        cost: 0,
        type: 1,
        projectId,
        projectRoleId: roleId,
      }));

      this.$store.commit('resourcesModel/addResourceToProjects', {
        resourceId,
        resourceProjects,
      });
    },
    removeResourceFromProjects(resourceId, projectIds) {
      const payload = projectIds.map(projectId => ({
        resourceId,
        projectId,
      }));

      this.$store.dispatch('resourcesModel/removeResourcesFromProjects', payload);
    },
    removeResourceFromAccount(resourceId) {
      this.$store.dispatch('resourcesModel/removeResources', [resourceId]);
    },
    handleToggleProjects() {
      this.isShowProjects = !this.isShowProjects;
      if (this.isShowProjects) {
        setTimeout(this.handleScrollProjects);
      }
    },
    handleScrollProjects() {
      const el = this.$refs.projectsScroll;
      const restDistance = el.scrollHeight - (el.scrollTop + el.offsetHeight);

      this.isEndScrolledProjects = restDistance < 5;
    },
    onClose() {
      this.selectedResource = null;
      this.$emit('onClose');
    },
  },
};
</script>

<style src="./less/transferOwnership.less" scoped></style>
