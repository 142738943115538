const headers = {
  people: [
    {
      text: __('resources_table_head_users'), value: 'name', sortable: true, width: 305, class: 'sticky-header',
    },
    {
      text: null, value: 'roleTitle', sortable: true, width: 200, class: 'sticky-header',
    },
    {
      text: __('table_cost_type'), value: 'type', sortable: false, width: 150, class: 'sticky-header',
    },
    {
      text: __('table_resource_cost'), value: 'cost', sortable: false, width: 120, class: 'sticky-header',
    },
    {
      text: __('resources_table_head_activity'), value: 'status', sortable: false, width: 200, class: 'sticky-header',
    },
    {
      text: null, value: 'changeOwner', sortable: false, width: 200, class: 'sticky-header',
    },
    {
      text: null, value: 'buttons', sortable: false, class: 'sticky-header',
    },
  ],
  material: [
    {
      text: __('resources_table_head_names'), value: 'name', sortable: true, width: 305, class: 'sticky-header material-name-head',
    },
    {
      text: __('resources_table_head_type'), value: 'type', sortable: false, width: 180, class: 'sticky-header',
    },
    {
      text: __('table_resource_cost'), value: 'cost', sortable: false, width: 180, class: 'sticky-header',
    },
    {
      text: null, value: 'buttons', sortable: false, class: 'sticky-header',
    },
  ],
};

export default headers;
