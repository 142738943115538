var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        {
          ref: "contextmenu",
          staticClass: "context-settings",
          style: _vm.style,
          attrs: { id: "gp_autotest_listview_context_menu" },
        },
        [
          _c(
            "div",
            {
              staticClass: "settings-item",
              attrs: { id: "gp_autotest_listview_context_menu_item_settings" },
              on: { click: _vm.settingsClick },
            },
            [
              _c("icon", { attrs: { name: "settings" } }),
              _vm._v(" "),
              _c("span", { staticClass: "settings-item--text" }, [
                _vm._v(" " + _vm._s(_vm.locale.setting) + " "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.canDeleteTask
            ? _c(
                "div",
                {
                  staticClass: "settings-item text-red",
                  attrs: {
                    id: "gp_autotest_listview_context_menu_item_delete",
                  },
                  on: { click: _vm.deleteClick },
                },
                [
                  _c("icon", { attrs: { name: "dustbin" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "settings-item--text" }, [
                    _vm._v(" " + _vm._s(_vm.locale.delete) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }