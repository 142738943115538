import app from "./../app";

var templates = {};

var templatesLocale = (!GT.locales.supported[user.locale] || GT.locales.supported[user.locale].fallbackTemplates) ? GT.locales.fallback : user.locale;

if (!app.config.mode.isBase) {
  templates = new webix.DataCollection();
  app.checkInit('templates');
} else {
  templates = new webix.DataCollection({
    url: "rest->/api/templates/" + templatesLocale + "?" + new Date().getTime(),
    save: {
      url: "rest->/api/templates/",
      updateFromResponse: true
    }
  });
}

app.on("app:start", function () {
  webix.promise.all([templates.waitData]).then(function () {
    app.trigger("templates:init");
    app.checkInit('templates');
  });
});

templates.getAllTemplates = function () {
  return this.serialize();
};

templates.getFirstTemplateIndex = function () {
  return this.baseTemplate[0].id;
};

templates.getBaseTemplates = function () {
  var baseTemplate = [];

  _.each(this.serialize(), function (template) {
    if (template.isPredefined) {
      let labelKey = template.nameKey;
      if(labelKey === 'default_template'){
        labelKey = 'default_template_recommended';
      }
      template.name = __(labelKey);
      baseTemplate.push(template);
    }
  });

  this.baseTemplate = webix.copy(baseTemplate);

  return baseTemplate;
};

templates.getUserTemplates = function () {
  if (this.userTemplate) {
    return this.userTemplate;
  }

  var userTemplate = [];

  _.each(this.serialize(), function (template) {
    if (template.owner_id) {
      userTemplate.push(template);
    }
  });

  this.baseTemplate = userTemplate;

  return userTemplate;
};

templates.addUserTemplate = function (gantt_id, icon, name, templateId, hasResources, settings = {}) {
  this.data.pull[templateId] = {
    gantt_id: gantt_id,
    name: name,
    icon: icon,
    owner_id: GT.user.id,
    id: templateId,
    has_resources: hasResources
  };

  if (settings.startProject) {
    this.data.pull[templateId].startProject = settings.startProject;
  }

  if (settings.workDays) {
    this.data.pull[templateId].workDays = settings.workDays;
  }

  this.data.order.push(templateId);
};

templates.deleteUsersTemplate = function (id) {
  templates.remove(id);
};

templates.updateUsersTemplate = function (id, value) {
  templates.updateItem(id, {name: value});
};

app.on("getFirstTemplateId", function (callback) {
  callback(templates.getFirstId());
});

webix.dp(templates).attachEvent("onAfterSync", function (obj, dataRaw, data) {
  if (obj.status === "delete" && data) {
    app.trigger('template:delete');
  }
});

export default templates;
