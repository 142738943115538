import app from '../../app';
import _ from '../../libs/lodash';
import constants from '../../helpers/constants';
import composedPath from '../../helpers/composedPath';
import templates from '../../templates/labels';
import calendarTemplates from './commonTemplates';
import zoomDays from '../settings/include/zoomDays';
import settingsCustomDays from '../settings/include/settingsCustomDays';

import {
  closeCross, tableIcon, calendarIcon, icon_left_arrow, icon_right_arrow, defaultAva,
} from './icons';
import routerHelper from '../../helpers/router';
import calculateMainWindowSize from '../../helpers/calculateMainWindowSize';
import globalStore from '$$store/main';
import helperDate from '../../helpers/dateFormats';
import projectsModel from '../../models/projects';

const __ = window.__;

const innerSettings = {
  isOpened: false,
  postfixForAddLayout: constants.CALENDARS.LAYOUT_TYPES.RESOURCE_CALENDAR,
  currentSettings: {},
};
const hoursArray = helperDate.getHoursArrayByUserTimeFormat(false, true);
const innerTemplates = {
  getLayoutHeader() {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](innerSettings.resourceId);
    // const resource = resourcesModel.getResourceById(innerSettings.resourceId);
    let innerPhoto = defaultAva;

    // if (resource.resourcePhoto) {
    //   innerPhoto = `<img src="${resource.resourcePhoto}">`;
    // }

    if (resourceData.photo) {
      innerPhoto = `<img src="${resourceData.photo}">`;
    }

    return `${''
      + "<div class='header_title manage'>"
      + "<div class='header_title_icon'>"}${innerPhoto}</div>`
      + `<div class='header_title_text'>${resourceData.name}</div>`
      + '</div>'
      + '<div class=\'header_button white\'>'
      + `<div class='arrow'>${
        closeCross
      }</div>`
      + '</div>';
  },
  getBodyHeader(config) {
    return `${''
      + "<div class='body_text'>"
      + "<p class='title'>"}${config.title}</p>`
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
  getBodyToggleLayout(config) {
    return `${''
      + "<div class='body_text custom-days-toggle'>"
      + "<div class='body_text_row'>"
      + "<p class='title'>"}${config.title}</p>`
      + '<div class=\'btn_group\'>'
      + `<div class='custom-days-year ${config.tableView ? 'hidden' : ''}'>`
      + `<div class='custom-days-arrowLeft'>${icon_left_arrow}</div>`
      + `<div class='custom-days-yearText'>${config.currentYear}</div>`
      + `<div class='custom-days-arrowRight'>${icon_right_arrow}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? 'active' : ''} btn-table'>`
      + `<div class='btn-icon'>${tableIcon}</div>`
      + `<div class='btn-text'>${__('table')}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? '' : 'active'} btn-calendar'>`
      + `<div class='btn-icon'>${calendarIcon}</div>`
      + `<div class='btn-text'>${__('calendar')}</div>`
      + '</div>'
      + '</div>'
      + '</div>'
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
};

var innerHandlers = {
  changeDays() {
    const webixDayUI = $$(innerViews.displayOptionsListDaysResource.id);
    let selectedDays = webixDayUI.getSelectedId();

    const hours = parseFloat($$(innerViews.hoursRangeTitleLabel.id).config.value);

    if (typeof selectedDays === 'number' || _.toSafeInteger(selectedDays)) {
      selectedDays = [selectedDays];
    }

    if (typeof selectedDays === 'string') {
      selectedDays = [];
    }

    selectedDays = selectedDays
      .sort((a, b) => a - b)
      .map(dayNumber => parseInt(dayNumber, 10));

    if (!selectedDays.length) {
      webixDayUI.blockEvent();
      webixDayUI.selectAll();
      selectedDays = webixDayUI.getSelectedId();
      webixDayUI.unblockEvent();
    }

    innerSettings.currentSettings.startDay = selectedDays[0];
    innerSettings.currentSettings.endDay = selectedDays[selectedDays.length - 1];
    innerSettings.currentSettings.showDay = selectedDays;
    // innerHandlers.debounceChangeDays(innerSettings.resourceId, selectedDays);
    // resourcesWorkTimeModel.updateWorkingTime(innerSettings.resourceId, selectedDays, hours);
    app.trigger('updateCalendarMonths');
    globalStore.dispatch('resourcesModel/changeWorkTime', [{
      resourceId: innerSettings.resourceId,
      updated: {
        workingDays: selectedDays,
        workingHours: hours,
      },
    }]);
    zoomDays.handlers.updateNumberInLabel($$(innerViews.daysRangeLabelResource.id), selectedDays.length);
    innerHandlers.runGanttWorker();
  },
  noExtensionLayout(webixUI) {
    const $$customDaysList = webixUI || $$(innerViews.resourceCustomDaysList.id);

    if ($$customDaysList.count() > 0) {
      settingsCustomDays.handlers.hideNoExceptions(innerSettings.postfixForAddLayout);
    } else {
      settingsCustomDays.handlers.showNoExceptions(innerSettings.postfixForAddLayout);
    }
  },
  updateCustomDaysList() {
    const customDays = innerHandlers.prepareCustomDaysData();
    const $$resourceCustomDaysList = $$(innerViews.resourceCustomDaysList.id);
    const $$resourceCustomDaysListTableLayout = $$(innerViews.resourceCustomDaysListTableLayout.id);

    $$resourceCustomDaysList.clearAll();
    $$resourceCustomDaysList.parse(customDays);

    if (customDays.length) {
      !$$resourceCustomDaysListTableLayout.isVisible() && $$resourceCustomDaysListTableLayout.show();
    } else {
      $$resourceCustomDaysListTableLayout.isVisible() && $$resourceCustomDaysListTableLayout.hide();
    }

    innerHandlers.noExtensionLayout($$resourceCustomDaysList);

    webix.delay(() => {
      $$resourceCustomDaysList.refresh();
      innerHandlers.showCustomDaysInCalendarView(customDays);
      $$(innerViews.resourceCustomDaysListLayout.id).resize();
    }, null, [], 100);
  },
  showCustomDaysInTableView() {
    innerHandlers.updateCustomDaysList();
  },
  showPopup() {
    const $$displayOptionsHoursDataView = $$(innerViews.displayResourceOptionsHoursDataView.id);
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](innerSettings.resourceId);

    $$displayOptionsHoursDataView.blockEvent();
    $$displayOptionsHoursDataView.clearAll();
    $$displayOptionsHoursDataView.parse(hoursArray);
    $$displayOptionsHoursDataView.select(resourceData.workingHours);
    $$displayOptionsHoursDataView.unblockEvent();
  },
  showWorkingHours() {
    // let workTime = resourcesWorkTimeModel.getWorkTimeByResourceId(innerSettings.resourceId);
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](innerSettings.resourceId);
    const $$displayOptionsListDaysResource = $$(innerViews.displayOptionsListDaysResource.id);
    const $$hoursRangeTitleLabel = $$(innerViews.hoursRangeTitleLabel.id);

    // if (!workTime) {
    //   workTime = resourcesWorkTimeModel.getDefaultWorkTime();
    // }

    $$hoursRangeTitleLabel.blockEvent();
    $$hoursRangeTitleLabel.config.data = resourceData.workingHours;
    $$hoursRangeTitleLabel.unblockEvent();

    if (resourceData.workingDays.length) {
      $$displayOptionsListDaysResource.blockEvent();
      $$displayOptionsListDaysResource.select(resourceData.workingDays);
      zoomDays.handlers.updateNumberInLabel($$(innerViews.daysRangeLabelResource.id), resourceData.workingDays.length);
      $$displayOptionsListDaysResource.unblockEvent();
    }
  },
  initCalendarView() {
    innerHandlers.showCustomDaysInCalendarView();
    settingsCustomDays.helpers.changeMainId('resCustomDaysMainScrollView', innerSettings.resourceId);
    $$(innerViews.main.id).show();
    // $$('resourceCalendarLayoutBodyToggle').config.tableView = false;
    // $$(innerViews.resourceCustomDaysListLayout.id).hide();
    // $$(innerViews.customDaysCalendarLayout.id).show();
    innerHandlers.showCustomDaysInTableView();
    innerHandlers.showWorkingHours();
  },
  showCustomDaysInCalendarView(customDays) {
    customDays = customDays || innerHandlers.prepareCustomDaysData();
    settingsCustomDays.handlers.clearAllCache();
    settingsCustomDays.helpers.updateCustomDays(customDays);
  },
  showAddButton() {
    $$(innerViews.addCustomDayButton.id).show();
  },
  hideAddButton() {
    $$(innerViews.addCustomDayButton.id).hide();
  },
  showAddingCustomDayForm() {
    settingsCustomDays.handlers.showAddingForm(innerSettings.postfixForAddLayout);
  },
  hideAddingCustomDayForm() {
    settingsCustomDays.handlers.hideAddingForm(innerSettings.postfixForAddLayout);
  },
  showAddingButtonAndHideForm() {
    innerHandlers.hideAddingCustomDayForm();
    innerHandlers.showAddButton();
  },
  runGanttWorker() {
    _.debounce(() => {
      gantt.ganttWorker.calculate(innerSettings.resourceId, 'afterChangeResourceCalendar', 'massCalculateTasksDuration');
    }, 1000)();
  },
  prepareCustomDaysData() {
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](innerSettings.resourceId);

    return resourceData.customDays.map(dayData => ({
      id: dayData.id,
      resource_id: innerSettings.resourceId,
      from: dayData.from,
      to: dayData.to,
      hours_count: dayData.workingHours,
      repeat: dayData.repeat,
      repeat_from: dayData.repeatFrom,
      repeat_to: dayData.repeatTo,
      title: dayData.title,
      type: dayData.type,
    }));
  },
  showHoursPopup(node) {
    $$(innerViews.resourceHoursPopup.id).show(node);
  },
};

var innerViews = {
  main: {
    id: 'resourceCalendarExceptionsPopup',
    css: 'ganttpro-layout calendar-layout',
  },
  addCustomDayButton: {
    view: 'button',
    id: 'addCustomDayForResourceButton',
    label: __('custom_day_add_button'),
    width: 300,
    height: 48,
    css: 'custom-days-add-button',
    on: {
      onItemClick() {
        innerHandlers.showAddingCustomDayForm();
        this.hide();
      },
    },
  },
  resourceCalendarLayoutBody: {
    id: 'resourceCalendarLayoutBody',
    css: 'body',
  },
  resourceCalendarLayoutBodyHeader: {
    id: 'resourceCalendarLayoutBodyHeader',
    view: 'template',
    height: 80,
    borderless: true,
    template: innerTemplates.getBodyHeader({
      title: __('personal_calendar_layout_body_title'),
      txt: __('personal_calendar_layout_body_txt'),
    }),
    onClick: {},
    on: {},
  },
  resourceCalendarLayoutBodyToggle: {
    id: 'resourceCalendarLayoutBodyToggle',
    view: 'template',
    css: 'resourceCalendarLayoutBodyToggle',
    height: 125,
    borderless: true,
    currentYear: (new Date()).getFullYear(),
    tableView: false,
    template(item, common) {
      return innerTemplates.getBodyToggleLayout({
        title: __('personal_calendar_toggle_layout_body_title'),
        txt: __('personal_calendar_toggle_layout_body_txt'),
        currentYear: this.currentYear,
        tableView: this.tableView,
      });
    },
    onClick: {
      'btn-table': function () {
        this.config.tableView = true;
        if (this.config.tableView) {
          $$(innerViews.customDaysCalendarLayout.id).hide();
          $$(innerViews.resourceCustomDaysListLayout.id).show();
          if (_.keys($$(innerViews.resourceCustomDaysList.id).data.pull).length) {
            $$(innerViews.resourceCustomDaysListTableLayout.id).show();
          } else {
            $$(innerViews.resourceCustomDaysListTableLayout.id).hide();
          }
        }
        $$(innerViews.resourceCustomDaysListLayout.id).resize();
        $$(innerViews.resourceCalendarLayoutBodyToggle.id).refresh();
      },
      'btn-calendar': function () {
        this.config.tableView = false;
        if (!this.config.tableView) {
          $$(innerViews.resourceCustomDaysListLayout.id).hide();
          $$(innerViews.customDaysCalendarLayout.id).show();
        }
        $$(innerViews.resourceCalendarLayoutBodyToggle.id).refresh();
      },
      'custom-days-arrowLeft': function () {
        settingsCustomDays.helpers.changeYear('left',
          innerViews.resourceCalendarLayoutBodyToggle.id, innerViews.resCustomDaysMainScrollView.id);
      },
      'custom-days-arrowRight': function () {
        settingsCustomDays.helpers.changeYear('right',
          innerViews.resourceCalendarLayoutBodyToggle.id, innerViews.resCustomDaysMainScrollView.id);
      },
    },
    on: {},
  },
  resCustomDaysMainScrollView: {
    id: 'resCustomDaysMainScrollView',
  },
  customDaysCalendarLayout: {
    id: 'resourceCustomDaysCalendarLayout',
  },
  resourceCustomDaysListLayout: {
    id: 'resourceCustomDaysListLayout',
  },
  resourceCustomDaysListTableLayout: {
    id: 'resourceCustomDaysListTableLayout',
  },
  resourceCustomDaysList: {
    view: 'activeDataTable',
    id: 'resourceCustomDaysList',
    css: 'custom-days-list',
    width: 889,
    autoheight: true,
    header: false,
    scroll: true,
    borderless: true,
    editable: true,
    scrollY: true,
    scrollX: false,
    prerender: false,
    rowHeight: 66,
    data: [],
    columns: [
      {
        id: 'id',
        fillspace: true,
        header: false,
        template(object) {
          const name = calendarTemplates.getCustomDayName(object);
          const info = calendarTemplates.getCustomDayInfo(object);
          const type = object.type;

          return calendarTemplates.customDaysListItem(name, info, type);
        },
      },
    ],
    onClick: {
      'custom-days-list-item': function (event, data, node) {
        const customDayData = this.getItem(data.row);
        const path = event.path || (event.composedPath && event.composedPath()) || composedPath(event.target);

        if (_.some(path, item => _.includes(item.className, 'custom-days-list-item-remove'))) {
          webix.confirm({
            ok: __('common_yes'),
            cancel: __('common_no'),
            width: 420,
            text: __('custom_day_remove_message'),
            type: 'confirm-error',
            callback(result) {
              if (result) {
                globalStore.dispatch('resourcesModel/removeWorkCustomDays', {
                  resourceId: innerSettings.resourceId,
                  customDayIds: [data.row],
                });
                // customDaysResourcesModel.deleteCustomDay(data.row);
              }
            },
          });
        } else {
          settingsCustomDays.helpers.showCustomDayEditPopup({
            node: node.querySelector('.custom-days-list-item-edit'),
            config: {},
            data: customDayData,
            workMode: innerSettings.postfixForAddLayout,
          });
        }
      },
    },
    on: {
      onBeforeRender() {
        if (this.count() > 0) {
          this.config.rowHeight = 66;
          this.config.height = this.count() * this.config.rowHeight;
        } else {
          this.config.rowHeight = 0;
          this.config.height = 'auto';
        }
      },
    },
  },
  daysRangeLabelResource: _.assign({}, zoomDays.views.daysRangeLabel, { id: 'daysRangeLabelResource' }),
  displayOptionsListDaysResource: _.assign({}, zoomDays.views.displayOptionsListDays, {
    id: 'displayOptionsListDaysResource',
    on: {
      onSelectChange() {
        innerHandlers.changeDays();
      },
    },
  }),
  hoursRangeLabel: {
    view: 'label',
    label: __('resource_calendar_hours'),
    css: 'label-with-spacer',
    toolTip: {
      y: 4,
      langKey: 'resource_range_hours_tooltip',
    },
    template: templates.getTemplateForSimpleLabelWithTooltip,
    id: 'resourceHoursRangeLabel',
  },
  hoursRangeTitleLabel: {
    id: 'resourceHoursRangeTitleLabel',
    view: 'template',
    css: 'resource-calendar-hours-title gp_autotest_webix_workload_perconal_calendar_input_hours',
    inputAlign: 'center',
    height: 38,
    template(obj) {
      if (this.value) {
        const hourItem = this.value && hoursArray.find(item => item.id === this.value);

        return `<div class='dataview-hours-inline'>${hourItem.value}</div>`;
      }
    },

    onClick: {
      'resource-calendar-hours-title': function () {
        innerHandlers.showHoursPopup(this.$view);
      },
    },
  },
  resourceDaysAndHoursSettings: {
    id: 'resourceDaysAndHoursSettings',
  },
  resourceHoursPopup: {
    id: 'zoomResourceHoursPopup',
  },
  displayResourceOptionsHoursDataView: {
    view: 'dataview',
    id: 'displayResourceOptionsHoursDataView',
    select: true,
    xCount: 6,
    yCount: 8,
    scroll: false,
    align: 'center',
    css: 'dataview-hours displayOptionsHoursDataView',
    height: 320,
    type: {
      width: 72,
      height: 37,
      template(obj) {
        return `<div class='dataview-hours-block'><div class='dataview-hours-item'>${obj.value}</div></div>`;
      },
    },
    data: [],
    on: {
      onItemClick(newV) {
        const hoursRangeTitleLabel = $$(innerViews.hoursRangeTitleLabel.id);

        if (newV === '0') {

          hoursRangeTitleLabel.config.value = 1;
        } else {
          hoursRangeTitleLabel.config.value = newV;
        }

        innerHandlers.changeDays();
        $$(innerViews.resourceHoursPopup.id).hide();
      },
    },

  },
};

const _init = function (resourceId) {
  if (!innerSettings.isOpened) {
    innerSettings.resourceId = resourceId;
    $$(innerViews.resourceCalendarLayoutBodyToggle.id).config.currentYear = (new Date()).getFullYear();
    innerHandlers.initCalendarView();
    $$(innerViews.resourceCalendarLayoutBodyToggle.id).refresh();
    $$(innerViews.customDaysCalendarLayout.id).reconstruct();
    $$(`${innerViews.main.id}header`).refresh();

    const hoursRangeTitleLabel = $$(innerViews.hoursRangeTitleLabel.id);
    const resourceData = globalStore.getters['resourcesModel/getResourceById'](innerSettings.resourceId);

    hoursRangeTitleLabel.config.value = resourceData.workingHours;
    hoursRangeTitleLabel.refresh();
  } else {
    innerSettings.resourceId = null;
    $$(innerViews.main.id).hide();
  }
};

const _hide = function () {
  innerSettings.resourceId = null;
  $$(innerViews.main.id).hide();
};

webix.ui({
  view: 'window',
  id: innerViews.main.id,
  css: innerViews.main.css,
  borderless: true,
  hidden: true,
  // header: false,
  head: false,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state, true]);
  },
  modal: false,
  move: false,
  zIndex: 100,
  body: {
    rows: [
      {
        css: 'header topHeaderShadow',
        view: 'template',
        id: `${innerViews.main.id}header`,
        height: 60,
        paddingY: 15,
        paddingX: 15,
        borderless: true,
        template: innerTemplates.getLayoutHeader,
        onClick: {
          header_button() {
            $$(innerViews.main.id).hide();
          },
        },
      },
      {
        view: 'scrollview',
        borderless: true,
        id: innerViews.resourceCalendarLayoutBody.id,
        css: innerViews.resourceCalendarLayoutBody.css,
        scroll: 'y',
        autoheight: true,
        body: {
          rows: [
            { height: 48 },
            innerViews.resourceCalendarLayoutBodyHeader,
            { height: 6 },
            {
              cols: [
                {},
                {
                  id: innerViews.resourceDaysAndHoursSettings.id,
                  width: 889,
                  cols: [
                    {
                      height: 76,
                      // width: "100%",
                      width: 432,
                      rows: [
                        innerViews.daysRangeLabelResource,
                        innerViews.displayOptionsListDaysResource,
                      ],
                    },
                    { width: 24 },
                    {
                      height: 76,
                      // width: "100%",
                      rows: [
                        innerViews.hoursRangeLabel,
                        innerViews.hoursRangeTitleLabel,
                      ],
                    },
                  ],
                },
                {},
              ],
            },
            { height: 30 },
            innerViews.resourceCalendarLayoutBodyToggle,
            { height: 12 },
            {
              id: innerViews.resourceCustomDaysListLayout.id,
              // minHeight: 338,
              hidden: true,
              autoheight: true,
              rows: [
                {
                  id: innerViews.resourceCustomDaysListTableLayout.id,
                  hidden: true,
                  cols: [
                    {},
                    innerViews.resourceCustomDaysList,
                    {},
                  ],
                },
                { height: 24 },
                {
                  cols: [
                    {},
                    innerViews.addCustomDayButton,
                    settingsCustomDays.init.createAddForm(innerSettings.postfixForAddLayout),
                    {},
                  ],
                },
                settingsCustomDays.init.createNoExceptions(innerSettings.postfixForAddLayout),
              ],
            },
            {
              id: innerViews.customDaysCalendarLayout.id,

              autoheight: true,
              rows: [
                {
                  cols: [
                    {},
                    settingsCustomDays.helpers.createCalendarLayout({
                      mainId: innerViews.resCustomDaysMainScrollView.id,
                    }),
                    {},
                  ],
                },
                { height: 24 },
              ],
            },
            { height: 48 },
          ],
        },
        on: {
          onAfterScroll: () => {
            webix.callEvent('onClick', []);
          },
        },
      },
    ],
  },
  on: {
    onHide() {
      innerSettings.isOpened = false;
      app.trigger('popup:hide');
      webix.callEvent('onResize', []);
    },
    onShow() {
      innerSettings.isOpened = true;
      webix.callEvent('onResize', []);
      $$(innerViews.resourceCalendarLayoutBody.id).resize();

      zoomDays.handlers.startOnMondayChange($$(innerViews.displayOptionsListDaysResource.id), +user.settings.start_monday);
    },
  },
});
webix.ui({
  view: 'popupWith6Padding',
  id: innerViews.resourceHoursPopup.id,
  padding: 12,
  body: {
    width: 472,
    // type: 'clean',
    paddingX: 0,
    paddingY: 0,
    borderless: true,
    rows: [
      {
        height: 320,
        css: 'displayOptionsHoursDataView',
        body:
        innerViews.displayResourceOptionsHoursDataView,
      },
    ],
  },
  on: {
    onShow() {
      innerHandlers.showPopup();
    },
    onHide() {
      $$(innerViews.resourceHoursPopup.id).hide();
      // innerHandlers.changeDays();
    },
  },
}).hide();

app.on('popup:show', () => {
  $$(innerViews.main.id).hide();
});

app.on('app:route:changed', () => {
  $$(innerViews.main.id).hide();
});

app.on('popup:closeOther', id => {
  if (id !== innerViews.main.id && id !== 'notificationsTimeLineModal') {
    $$(innerViews.main.id).hide();
  }
});

app.on('body:resize', () => {
  $$(innerViews.main.id).resize();
});

app.on('leftSideBar:changedMode', () => {
  $$(innerViews.main.id).resize();
});

app.on('customDaysResources:insert', () => {
  innerHandlers.updateCustomDaysList();
});

app.on('customDaysResources:live:update', () => {
  innerHandlers.updateCustomDaysList();
  innerHandlers.runGanttWorker();
});

app.on('customDaysResources:delete', () => {
  innerHandlers.updateCustomDaysList();
  innerHandlers.runGanttWorker();
});

app.on('save:customDay:resource', customDay => {
  globalStore.dispatch('resourcesModel/createWorkCustomDays', [{
    resourceId: innerSettings.resourceId,
    customDays: [
      {
        from: customDay.from,
        to: customDay.to || undefined,
        workingHours: customDay.hours_count,
        repeat: customDay.repeat,
        repeatFrom: customDay.repeat_from,
        repeatTo: customDay.repeat_to,
        title: customDay.title,
        type: customDay.type,
      },
    ],
  }]);
  // customDay.resource_id = innerSettings.resourceId;
  // customDaysResourcesModel.addResourceCustomDay(customDay);
  innerHandlers.showAddingButtonAndHideForm();
  innerHandlers.updateCustomDaysList();
  innerHandlers.runGanttWorker();
});

app.on('cancel:customDay:resource', () => {
  innerHandlers.hideAddingCustomDayForm();
  innerHandlers.showAddButton();
  innerHandlers.noExtensionLayout($$(innerViews.resourceCustomDaysList.id));
});

app.on('gantt:init:before', () => {
  !routerHelper.isListViewRoute() && settingsCustomDays.init.addViewToForm(innerSettings.postfixForAddLayout);
});

app.on('settings:startOnMonday:change', startOnMonday => {
  zoomDays.handlers.startOnMondayChange($$(innerViews.displayOptionsListDaysResource.id), startOnMonday);
});

app.on('resources:model:changeWorkTime', resourceIds => {
  if (innerSettings.isOpened) {
    innerSettings.isOpened = false;
    _init(innerSettings.resourceId);
  }
});

app.on('resources:model:changeWorkCustomDays', resourceIds => {
  if (innerSettings.isOpened) {
    innerSettings.isOpened = false;
    _init(innerSettings.resourceId);
  }
});

app.on('project:archive', ganttId => {
  const activeProjectId = projectsModel.getActiveGanttId();

  if (activeProjectId === ganttId) {
    $$(innerViews.main.id).hide();
  }
});

// customDaysResourcesModel.data.attachEvent('onStoreUpdated', () => {
//   if (innerSettings.isOpened) {
//     innerSettings.isOpened = false;
//     _init(innerSettings.resourceId);
//   }
// });
//
// resourcesWorkTimeModel.data.attachEvent('onStoreUpdated', () => {
//   if (innerSettings.isOpened) {
//     innerSettings.isOpened = false;
//     _init(innerSettings.resourceId);
//   }
// });

const outputObject = {
  init: _init,
  hide: _hide,
};

gantt.attachEvent('showPersonalCalendar', resource_id => {
  outputObject.init(resource_id);
});

export default outputObject;
