const __ = window.__;

var durationOptions = [
  {
    id: "hour",
    value: __("new_durationOptionHours"),
    config: {
      duration_view: "hour"
    }
  }, //hour
  {
    id: "day",
    value: __("new_durationOptionDays"),
    config: {
      duration_view: "day"
    }
  }, // day
  {
    id: "week",
    value: __("new_durationOptionWeeks"),
    config: {
      duration_view: "week"
    }
  }, // week
  {
    id: "month",
    value: __("new_durationOptionMonth"),
    config: {
      duration_view: "month"
    }
  } //hour
];


export default durationOptions;
