import globalStore from '$$store/main';

export default {
  assignedColumnSort(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      if (a.resources.length && b.resources.length && a.resources.length === b.resources.length) {
        const nameA = globalStore.getters['resourcesModel/getResourceById'](a.resources[0].resource_id).name;
        const nameB = globalStore.getters['resourcesModel/getResourceById'](b.resources[0].resource_id).name;

        if (!isDescColumns[0]) {
          return nameA < nameB ? -1 : 1;
        }

        return nameA > nameB ? -1 : 1;
      }

      if (!isDescColumns[0]) {
        return a.resources.length < b.resources.length ? -1 : 1;
      }

      return a.resources.length > b.resources.length ? -1 : 1;
    });

    return items;
  },

  objectColumnSort(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      if ((typeof a[sortByColumns] === 'undefined')) return 1;
      if (a[sortByColumns] && (typeof b[sortByColumns] === 'undefined')) return -1;

      if (a[sortByColumns] && b[sortByColumns]) {
        const nameA = a[sortByColumns].value;
        const nameB = b[sortByColumns].value;

        if (!isDescColumns[0]) {
          return nameA < nameB ? -1 : 1;
        }

        return nameA > nameB ? -1 : 1;
      }

      if (!isDescColumns[0]) {
        return a[sortByColumns] < b[sortByColumns] ? -1 : 1;
      }

      return a[sortByColumns] > b[sortByColumns] ? -1 : 1;
    });

    return items;
  },

  multiDataColumnSort(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      if ((typeof a[sortByColumns] === 'undefined')) return 1;
      if (a[sortByColumns] && (typeof b[sortByColumns] === 'undefined')) return -1;

      if (a[sortByColumns].length && b[sortByColumns].length && a[sortByColumns].length === b[sortByColumns].length) {
        const nameA = a[sortByColumns][0].value;
        const nameB = b[sortByColumns][0].value;

        if (!isDescColumns[0]) {
          return nameA < nameB ? -1 : 1;
        }

        return nameA > nameB ? -1 : 1;
      }

      const aLength = a[sortByColumns] ? a[sortByColumns].length : 0;
      const bLength = b[sortByColumns] ? b[sortByColumns].length : 0;

      if (!isDescColumns[0]) {
        return aLength < bLength ? -1 : 1;
      }

      return aLength > bLength ? -1 : 1;
    });

    return items;
  },

  textColumnSort(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      let aVal = a[sortByColumns];
      let bVal = b[sortByColumns];

      typeof aVal === 'string' && (aVal = aVal.toLowerCase());
      typeof bVal === 'string' && (bVal = bVal.toLowerCase());

      if (typeof aVal === 'undefined') return 1;
      if (aVal && (typeof bVal === 'undefined')) return -1;

      if (isDescColumns[0]) {
        return bVal < aVal ? -1 : 1;
      }

      return aVal < bVal ? -1 : 1;
    });
  },

  sortForDate(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      const aDate = new Date(a[sortByColumns]);
      const bDate = new Date(b[sortByColumns]);

      if (isDescColumns[0]) {
        return (aDate - bDate) > 0 ? -1 : 1;
      }

      return (aDate - bDate) > 0 ? 1 : -1;
    });
  },

  projectNameSort(items, sortByColumns, isDescColumns) {
    items.sort((a, b) => {
      let aVal = a[sortByColumns];
      let bVal = b[sortByColumns];

      typeof aVal === 'string' && (aVal = aVal.toLowerCase());
      typeof bVal === 'string' && (bVal = bVal.toLowerCase());

      if (typeof aVal === 'undefined') return 1;
      if (aVal && (typeof bVal === 'undefined')) return -1;

      if (isDescColumns[0]) {
        return bVal < aVal ? -1 : 1;
      }

      return aVal < bVal ? -1 : 1;
    });
  },

  sort(items, columns, isDescColumns, showColumns = []) {
    const colName = columns[0].property;

    if (colName === 'assigned') {
      this.assignedColumnSort(items, colName, isDescColumns);
    } else if (colName === 'project_name') {
      const sortByColumns = showColumns.includes('project_name_extra') ? 'project_extra' : 'project_name';

      this.projectNameSort(items, sortByColumns, isDescColumns);
    } else if (colName === 'start_date' || colName === 'end_date' || colName === 'created_at') {
      this.sortForDate(items, colName, isDescColumns);
    } else if (columns[0].sortType === 'multi') {
      this.multiDataColumnSort(items, colName, isDescColumns);
    } else if (columns[0].sortType === 'one') {
      this.objectColumnSort(items, colName, isDescColumns);
    } else {
      this.textColumnSort(items, colName, isDescColumns);
    }

    return items;
  },
};
