import moduleGridCustomButtons from "../modules/gridCustomButtons";
import moduleHistory from "../modules/history";
import moduleResources from "../modules/resources";
import moduleCustomColumns from "../modules/customColumns";
import moduleEstimation from "../modules/estimation";
import moduleGridActionButtons from "../modules/gridActionButtons";
import moduleWorkDays from "../modules/workDays";
import moduleLink from "../modules/link";
import moduleTextAlign from "../modules/textAlign";
import moduleCustomGrids from "../modules/customGrids";
import moduleWorkload from "../modules/workload";
import moduleExportOverlay from "../modules/exportOverlay";

export default {
  beforeInit: [
    moduleWorkload,
    moduleCustomGrids,
    moduleGridCustomButtons,
    moduleHistory,
  ],
  init: [
    moduleResources,
    moduleCustomColumns,
    moduleEstimation,
    moduleGridCustomButtons,
    // moduleGridActionButtons,
    moduleWorkDays,
    moduleLink,
    moduleTextAlign,
    moduleExportOverlay,
  ],
  dependencyInit: [
    {
      module: moduleCustomGrids,
      callBack: () => {}
    },
    {
      module: moduleResources,
      callBack: () => {}
    }
  ],
}