const DEFAULT_OPTIONS = [
  {
    label: __('gantt_task_resource'),
    id: 'resources',
  },
  {
    label: __('gantt_task_links'),
    id: 'links',
  },
  {
    label: __('gantt_task_color'),
    id: 'colors',
  },
  {
    label: __('gantt_task_description'),
    id: 'description',
  },
  {
    label: __('gantt_task_progress'),
    id: 'progress',
  },
  {
    label: __('gantt_task_date'),
    id: 'dates',
  },
  {
    label: __('gantt_task_files'),
    id: 'attachments',
  },
  {
    label: __('gantt_task_comments'),
    id: 'comments',
  },
  {
    label: __('gantt_task_priority'),
    id: 'priority',
  },
  {
    label: __('gantt_task_status'),
    id: 'status',
  },
];

export {
  DEFAULT_OPTIONS,
};
