import ganttToXlsx from "../views/export/xlsxExportHandlers/ganttToXlsx";
import constants from "./constants";

export default function (name, gantt_id) {
  setTimeout(function () {
    let templatesCategoryKeys = Object.keys(constants.TEMPLATES_CATEGORIES).concat(['default']);
    let [beforeFirstSlash, templatesWord, locale, category, key, afterLastSlash] = name.split('/');
    if (templatesWord !== 'templates') return;
    if (beforeFirstSlash !== '' || !locale || !category || !key || afterLastSlash !== undefined) { return webix.message({ type: "warning", text: 'Wrong template name. <br>Excel for template is not saved. <br>Should be something like "/templates/en/event_planning/temlpate-key"' }); }
    if (user.locale !== locale) { return webix.message({ type: "warning", text: `Wrong language. <br>Excel for template is not saved. <br>Swith to "${locale}" locale in profile settings.` }); }
    if (templatesCategoryKeys.indexOf(category) === -1) { return webix.message({ type: "warning", text: `Wrong template category. <br>Excel for template is not saved. <br>Use one of category: ${templatesCategoryKeys.join(', ')}.` }); }
    webix.message({ type: "info", text: "Позабыты хлопоты, остановлен бег.<br> Вкалывают роботы, а не человек.<br> Надо подождать пару секунд..." });
    ganttToXlsx.export({
      title: __(key),
      projectStartDate: gantt.config.start_date,
      server: {
        locale,
        category,
        key,
        gantt_id,
        name: __(key)
      }
    });
  }, 100);
}