function centerPopup(w, h) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height = window.innerHeight
  || document.documentElement.clientHeight
  || window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  return { top, left };
}

function getWindowConfig() {
  const w = 600;
  const h = 800;
  const coordinates = centerPopup(w, h);

  return `toolbar=no,
  location=no,
  status=no,
  menubar=no,
  scrollbars=yes,
  resizable=yes,
  width=${w},
  height=${h},
  top=${coordinates.top},
  left=${coordinates.left}`;
}

export default function showAuthWindow({
  type, isOwner, update_user_token, reconnect_msteams,
}, accountID) {
  !update_user_token && userExtAnalytics.log('integration_connect', { isOwner, type });

  if (!update_user_token) {
    if (type === 'slack') userExtAnalytics.log('intergraration_slack_connect');
    if (type === 'msteams') userExtAnalytics.log('intergraration_MSTeams_connect');
  }

  const integration_config = GT.integrations[type];
  let url = `${integration_config.basicURL
  }?client_id=${integration_config.clientID
  }&state={"teamId": ${GT.user.team.id}, "userId": ${GT.user.id}${update_user_token ? ', "update_user_token": true' : ''
  }${reconnect_msteams ? ', "reconnect_msteams": true' : ''}}&scope=${integration_config.scopeBasic
  }&redirect_uri=${integration_config.callbackBasicURL}`;

  if (type === 'slack' && !isOwner) url += `&team=${accountID}`;
  if (type === 'msteams') url += '&response_type=code';
  window.open(url, 'targetWindow', getWindowConfig());
}
