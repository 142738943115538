import app from "./../../app";
import icon_close from "../../svg/close.svg";

import '../common/ganttproWindowPopup';

const __ = window.__;

var innerObject = {

  mainView: {
    id: 'userAccountPopup'
  },

  getUserAccountPopup: function () {

    return `
      <div class="user-popup-content">
        <div class="popup-container">
          <!--    HEADER    -->
          <div class="popup-container-header">
            <div class="popup-container-header__title">
                ${__("delete_popup_header_title")}
            </div>
            <div class="popup-container-header__icon">
                ${icon_close}
            </div>
          </div>
          <!--    BODY    -->
          <div class="popup-container-body">
            <div class="popup-container-body__text">
                ${__("delete_popup_body_text")}
            </div>
            <div class="popup-container-body__desc">
                ${__("delete_popup_body_desc")}
                <a href="mailto:${__('support_mail')}">${__('support_mail')}</a>
            </div>
          </div>
          <!--    FOOTER   -->
          <div class="popup-container-footer">
            <div id="gp_autotest_delete_account_confirm_ok_button" class="popup-container-footer__button send">
                ${__("delete_popup_footer_button_text_01")}
            </div>
            <div id="gp_autotest_delete_account_confirm_cancel_button" class="popup-container-footer__button cancel">
                ${__("delete_popup_footer_button_text_02")}
            </div>
          </div>
        </div>
      </div>
    `;
  },

};

webix.ui({
  view: 'ganttproWindowPopup',
  id: "userAccountPopup",
  zIndex: 111,
  css: "user-account-popup",
  // header: false,
  head: false,
  body: {
    view: 'template',
    id: 'userAccountPopupBody',
    css: 'user-popup-wrapper',
    maxWidth: 636,
    maxHeight: 320,
    template: function () {
      return innerObject.getUserAccountPopup();
    },
    onClick: {
      'popup-container-header__icon': function (e) {
        $$("userAccountPopup").hide();
      },
      'cancel': function (e) {
        $$("userAccountPopup").hide();
      },
      'send': function (e) {
        $$("userAccountPopup").hide();
        $$("userAccountFormPopup").show();
      }
    }
  },
  on: {
    'onHide': function () {
      app.trigger("popup:hide");
    },
  }
});


var outputObject = {
  init: function () {
    $$("userAccountPopup").show();
  }
};

export default outputObject;
