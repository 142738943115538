// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inactive_qlsdYB2c .connection_project_title_msteams,\n.inactive_qlsdYB2c .channel_setting_container > div:not(.buttons) {\n  opacity: 0.5;\n}\n.tip_NAV7_X_r svg path {\n  fill: #FF9A00;\n}\n.tip_NAV7_X_r svg:hover path {\n  fill: #FF9A00 !important;\n}\n", "",{"version":3,"sources":["webpack://./public/views/integration/msteams/components/integrationMSTeamsCommonStyles.less","webpack://./integrationMSTeamsCommonStyles.less"],"names":[],"mappings":"AAAA;;EAGM,YAAA;ACAN;ADGE;EAEM,aAAA;ACFR;ADAE;EAMM,wBAAA;ACHR","sourcesContent":[".inactive {\n    :global(.connection_project_title_msteams),\n    :global(.channel_setting_container) > div:not(:global(.buttons)) {\n      opacity: 0.5;\n    }\n  }\n  .tip {\n    svg  path{\n        fill: #FF9A00\n    }\n\n    svg:hover path {\n        fill: #FF9A00 !important\n    }\n  }",".inactive :global(.connection_project_title_msteams),\n.inactive :global(.channel_setting_container) > div:not(:global(.buttons)) {\n  opacity: 0.5;\n}\n.tip svg path {\n  fill: #FF9A00;\n}\n.tip svg:hover path {\n  fill: #FF9A00 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inactive": "inactive_qlsdYB2c",
	"tip": "tip_NAV7_X_r"
};
export default ___CSS_LOADER_EXPORT___;
