import Vue from 'vue';
import {
  en, de, ru, EventCalendar,
} from '../../libs/eventCalendar/dist/event-calendar.es';
import Store from '../../store/main';
import templateHelper from './templateHelper';
import routerHelper from '../../helpers/router';
import projectsModel from '../../models/projects';
import gridCustomButtons from '../gantt/modules/gridCustomButtons';
import { initAdapter } from '../../store/adapters/calendar/main';
import {
  CALENDAR_CONFIG, MONTH_LOCALES, WEEK_LOCALES, MAX_EVENTS_PER_CELL,
} from './constants';
import moment from '../../libs/moment';
import ganttFilterHelper from '../../helpers/gantt_filter';
import calendarHelpers from './helpers';
import workloadCore from '../gantt/modules/workloadCore';
import rights from '../../components/rights/index';
import app from '../../app';

let tooltipTimer;

class Calendar {
  constructor() {
    this.calendarInstance = null;
    this.ganttInstance = null;
    this.tasksMap = {};
    this.accessToStaticFields = true;
    this.projectId = null;

    this.dndState = {
      eventId: null,
      isDragStart: false,
      isDragMove: false,
      targetWeek: null,
      eventCoords: null,
      resizer: false,
      mouseOverCalendar: false,
    };

    this.viewSettings = {
      minCellHeight: 0,
      maxVisibleEvents: 0,
    };
  }

  init(ganttInstance) {
    const locale = en;
    const weekStart = +user.settings.start_monday === 1 ? 1 : 7;
    const projectId = projectsModel.getActiveGanttId();
    const accessToStaticFields = rights.project.hasRight(projectId, 'static_fields');

    locale.calendar.weekStart = weekStart;

    if (!accessToStaticFields) {
      CALENDAR_CONFIG.dragMove = false;
      CALENDAR_CONFIG.dragResize = false;
    }

    this.ganttInstance = ganttInstance;
    this.accessToStaticFields = accessToStaticFields;
    this.projectId = projectId;

    const calendarInstance = new EventCalendar('#calendar_view_id', {
      date: new Date(),
      config: {
        ...CALENDAR_CONFIG,
        views: [
          {
            id: 'month',
            label: 'Month',
            layout: 'month',
            config: {
              maxEventsPerCell: MAX_EVENTS_PER_CELL,
              cellCss: date => calendarHelpers.getWeekendCssSelector(date, ganttInstance),
            },
          },
        ],
      },
      mode: 'month',
      sidebar: { show: false },
      locale,
      templates: {
        monthEvent: ({ event, calendar }) => templateHelper.eventTemplate(event, accessToStaticFields),
        multievent: ({ event, calendar }) => templateHelper.eventTemplate(event, accessToStaticFields),
      },
    });

    initAdapter(calendarInstance, ganttInstance);

    this.calendarInstance = calendarInstance;

    const tasks = Store.getters['tasksModel/getTasksForGantt'](projectId).data;

    this.parse(tasks, false, true);
    this.recalculateCalendarHeight();
    setTimeout(this.mount.bind(this), 0);
  }

  parse(tasks, needRefresh = true, init = false) {
    const currentFilter = Store.getters['filter/currentFilter'];
    const projectId = projectsModel.getActiveGanttId();

    if (currentFilter && currentFilter.type === 'calendarview' && projectId === currentFilter.project_id) {
      this.parseFilteredEvents(currentFilter.filterState, tasks, needRefresh, init);
    } else {
      this.parseEvents(tasks, needRefresh);
    }
  }

  parseEvents(tasks, needRefresh) {
    const preparedEvents = calendarHelpers.prepareCalendarEvents(tasks);

    this.calendarInstance.parse(preparedEvents);
    this.tasksMap = calendarHelpers.getTasksMap(tasks);
    needRefresh && this.refresh();
  }

  parseFilteredEvents(filterData, tasks, needRefresh, firstInit = false) {
    if (this.calendarInstance) {
      const checkByFilter = ganttFilterHelper.getFilter(window.gantt, {
        value: filterData && filterData.text,
        options: filterData,
      }, null);

      const filteredTasks = tasks.filter(t => checkByFilter(t));
      const preparedFilteredEvents = calendarHelpers.prepareCalendarEvents(filteredTasks);

      if (!preparedFilteredEvents.length) {
        if (firstInit) {
          setTimeout(() => {
            app.trigger('filter:reset');
          }, 0);

          return;
        }
        app.trigger('filter:tasks:not:found');
      }

      this.calendarInstance.parse(preparedFilteredEvents);
      this.tasksMap = calendarHelpers.getTasksMap(tasks);
      needRefresh && this.refresh(200);
    }
  }

  resetFilterIfNeeded(taskId) {
    const state = this.calendarInstance.getState();
    const currentFilter = Store.getters['filter/currentFilter'];
    // const needReset = !state.events.length || (state.events.length === 1 && state.events[0].id === taskId);
    const needReset = !state.events.length;

    if (currentFilter && currentFilter.type === 'calendarview' && this.projectId === currentFilter.project_id && needReset) {
      app.trigger('filter:reset');
    }
  }

  mount() {
    this.calcMaxVisibleEvents();
    this.setCustomLocales();
    this._mountHightlights();
    this._mountCustomShowMoreButtons();
    this._mountCustomNewEventButtons();
    this._customEventsStylingByTimeline();
    this._setMonthDescriptions();

    document.querySelectorAll('.wx-event-calendar-calendar-week').forEach((el, index) => {
      el.classList.add(`calendar-week-custom-selector-by-index-${index}`);
      el.setAttribute('data-index', index);
    });
  }

  recalculateCalendarHeight(bounds) {
    let weeksLength = null;

    const state = this.calendarInstance.api.getState();
    const weeks = state.viewModel.monthTable;
    const calendarGridLayout = document.querySelector('.wx-event-calendar-layout.wx-event-calendar');

    if (bounds) {
      const dates = bounds.map(date => new Date(date));
      const differenceMs = Math.abs(dates[1] - dates[0]);

      weeksLength = Math.ceil(differenceMs / (1000 * 60 * 60 * 24 * 7));
    } else {
      weeksLength = weeks.length;
    }

    const cellHeight = (document.documentElement.clientHeight - 177) / weeksLength;

    calendarGridLayout.style.setProperty('--wx-event-calendar_month-cell-min-height', `${cellHeight}px`, 'important');
    this.viewSettings.minCellHeight = cellHeight;
  }

  calcMaxVisibleEvents() {
    if (!this.calendarInstance) {
      return;
    }
    const state = this.calendarInstance.getState();
    const config = state.viewModel.config;

    const maxVisibleEvents = Math.floor((this.viewSettings.minCellHeight - config.dateHeight) / (config.eventHeight + config.eventVerticalSpace));

    this.viewSettings.maxVisibleEvents = maxVisibleEvents + 1;
  }

  setCustomLocales() {
    const calendar = document.querySelector('#calendar_view_id');
    const weekdaysHeader = calendar.querySelectorAll('.wx-event-calendar-weekday');

    weekdaysHeader.forEach((weekDay, index) => {
      const date = weekDay.querySelector('.wx-event-calendar-date');

      if (!this.ganttInstance.config.start_on_monday) {
        date.innerText = __(`${WEEK_LOCALES[index]}`);
      } else {
        const weekMonday = WEEK_LOCALES.slice(1).concat(WEEK_LOCALES[0]);

        date.innerText = __(`${weekMonday[index]}`);
      }
    });
  }

  refresh(delay = 10) {
    const debouncedRefresh = _.debounce(this._refreshCustomCalendarLayout, delay).bind(this);

    debouncedRefresh();
  }

  _refreshCustomCalendarLayout() {
    this.hideDetailsTooltip();
    this._customEventsStylingByTimeline();
    this._mountCustomShowMoreButtons();

    document.querySelectorAll('.wx-event-calendar-calendar-week').forEach(el => {
      this._recalculateWeekHeightWithEvents(el);
    });
  }

  _mountCustomNewEventButtons() {
    const projectId = projectsModel.getActiveGanttId();

    if (!calendarHelpers.hasAccessToCreateTask(projectId)) {
      return;
    }

    const calendar = document.querySelector('#calendar_view_id');
    const cells = calendar.querySelectorAll('.wx-event-calendar-day');

    cells.forEach(cell => {
      const customNewEventButton = cell.querySelector('.calendar_custom_new_event_button');

      customNewEventButton && customNewEventButton.remove();

      const newEventButton = document.createElement('div');

      newEventButton.className = 'calendar_custom_new_event_button';
      newEventButton.innerHTML = templateHelper.newEventIcon();
      cell.appendChild(newEventButton);
    });
  }

  _mountHightlights() {
    const calendar = document.querySelector('#calendar_view_id');
    const weeks = calendar.querySelectorAll('.wx-event-calendar-calendar-week');

    weeks.forEach(week => {
      const hightlight = week.querySelector('.event-hightlight');

      if (!hightlight) {
        const hiddenEventHightlight = document.createElement('div');

        hiddenEventHightlight.className = 'event-hightlight';

        week.appendChild(hiddenEventHightlight);
      }
    });
  }

  _mountCustomShowMoreButtons() {
    if (!this.calendarInstance) {
      return;
    }

    const state = this.calendarInstance.getState();
    const config = state.viewModel.config;
    const monthTable = state.viewModel.monthTable;
    const viewData = state.viewData;

    const calendar = document.querySelector('#calendar_view_id');
    const weeks = calendar.querySelectorAll('.wx-event-calendar-calendar-week');

    weeks.forEach((week, weekIndex) => {
      const stateWeek = monthTable[weekIndex];
      const days = week.querySelectorAll('.wx-event-calendar-day');
      const eventsOnWeek = Array.from(week.querySelectorAll('.wx-event-calendar-event'));
      const eventsData = viewData.filter(data => eventsOnWeek.find(el => +el.dataset.id === data.id));

      eventsOnWeek.forEach(el => el.classList.remove('hidden-if-collapsed'));

      days.forEach((day, indexDay) => {
        day.setAttribute('data-index', indexDay);
        const stateDay = stateWeek.days[indexDay];
        const allEvents = stateDay.events;
        const dayTime = new Date(stateDay.value).getTime();

        const customShowMoreButton = day.querySelector('.calendar_custom_more_button');
        const customDescription = day.querySelector('.calendar_custom_events_count');

        customShowMoreButton && customShowMoreButton.remove();
        customDescription && customDescription.remove();

        const dayEventsIds = eventsData.filter(event => {
          const startDay = moment(event.start_date);
          const endDate = moment(event.end_date);

          startDay.startOf('day');
          endDate.startOf('day');

          const startDateTime = new Date(startDay).getTime();
          const endDateTime = new Date(endDate).getTime();

          if ((startDateTime < dayTime && dayTime < endDateTime) || (startDateTime === dayTime || dayTime === endDateTime)) {
            return true;
          }

          return false;
        }).map(event => event.id);

        const dayHiddenEvents = eventsOnWeek.filter(el => {
          const topValue = parseFloat(el.style.top);
          const bottomValue = topValue + config.eventHeight;

          if (topValue > this.viewSettings.minCellHeight) {
            el.classList.add('hidden-if-collapsed');
          }

          if (bottomValue >= this.viewSettings.minCellHeight) {
            return true;
          }

          return false;
        }).filter(el => dayEventsIds.find(id => id === +el.dataset.id));

        if (dayHiddenEvents.length && !day.classList.contains('custom-weekend')) {
          const showMoreButton = document.createElement('div');

          showMoreButton.className = 'calendar_custom_more_button';
          showMoreButton.innerHTML = templateHelper.moreButton(dayHiddenEvents.length || allEvents - this.viewSettings.maxVisibleEvents);
          day.appendChild(showMoreButton);

          if (+allEvents > MAX_EVENTS_PER_CELL) {
            const hiddenEventsCount = +allEvents - MAX_EVENTS_PER_CELL;
            const hiddenEventsText = document.createElement('div');

            hiddenEventsText.className = 'calendar_custom_events_count';
            hiddenEventsText.innerHTML = `${__('calendar_page_more_event_count', { hiddenTasksCount: hiddenEventsCount })}`;
            day.appendChild(hiddenEventsText);
          }
        }
      });
    });
  }

  _customEventsStylingByTimeline() {
    if (!this.calendarInstance) {
      return;
    }
    const state = this.calendarInstance.getState();
    const monthTable = state.viewModel.monthTable;
    const weeks = document.querySelectorAll('.wx-event-calendar-calendar-week');

    weeks.forEach((week, weekIndex) => {
      const events = week.querySelectorAll('.wx-event-calendar-event');

      events.forEach(e => {
        const eventData = state.viewModel.getEventById(+e.dataset.id);
        const eventStartDateTime = new Date(eventData.start_date).getTime();
        const eventEndDateTime = new Date(eventData.end_date).getTime();
        const weekStartTime = new Date(monthTable[weekIndex].start_date).getTime();
        const weekEndTime = new Date(monthTable[weekIndex].end_date).getTime();

        e.style.setProperty('--event-dark-color', `${eventData.color.darkColor}`);

        e.classList.remove('custom-past');
        e.classList.remove('custom-future');

        if (weekStartTime > eventStartDateTime) {
          e.classList.add('custom-past');
        }

        if (eventEndDateTime > weekEndTime) {
          e.classList.add('custom-future');
        }
      });
    });
  }

  _setMonthDescriptions() {
    const state = this.calendarInstance.api.getState();
    const currentDate = new Date(state.date);
    const currentMonth = currentDate.getMonth();

    const calendar = document.querySelector('#calendar_view_id');
    const cells = calendar.querySelectorAll('.wx-event-calendar-day');

    cells.forEach(cell => {
      const dateNode = cell.querySelector('.wx-event-calendar-date');
      const monthDescNode = cell.querySelector('.month-description');
      const date = new Date(+cell?.dataset?.cell);
      const dateMonth = date.getMonth();
      const dateDay = date.getDate();

      let month = null;

      monthDescNode && monthDescNode.remove();

      if (date && (currentMonth !== dateMonth || dateDay === 1)) {
        month = MONTH_LOCALES[dateMonth];
      }

      if (month) {
        const monthDesc = document.createElement('div');

        monthDesc.className = 'month-description';
        monthDesc.innerText = month;
        dateNode.appendChild(monthDesc);
      }
    });
  }

  handleClick(e) {
    const showMoreBtn = e.target.closest('.calendar_custom_more_button');
    const newEventBtn = e.target.closest('.calendar_custom_new_event_button');

    if (showMoreBtn) {
      this._toggleExpandCollpase(showMoreBtn);
    }

    if (newEventBtn) {
      const parentCell = newEventBtn.closest('.wx-event-calendar-day');
      const ms = parentCell.dataset.cell;
      const newDate = this._getDate(+ms);

      this.calendarInstance.api.exec('show-new-event-popup', { date: newDate });
    }
  }

  handleMouseDown(e) {
    const calendar = document.querySelector('#calendar_view_id');
    const resizer = e.target.closest('.wx-event-calendar-resizer');
    const event = e.target.closest('.wx-event-calendar-event');
    const detailsTooltip = e.target.closest('.calendar-page-event-details-tooltip');

    if (detailsTooltip) {
      return;
    }

    this.hideDetailsTooltip();

    if (event && this.accessToStaticFields) {
      this.dndState.eventId = event.dataset.id;
      this.dndState.isDragStart = true;
      this.dndState.eventCoords = event.getBoundingClientRect();
      this.dndState.resizer = !!resizer;
      calendar.classList.add('disabled-layout-by-drag');
    }
  }

  handleMouseUp(e) {
    const calendar = document.querySelector('#calendar_view_id');
    const taskEvent = e.target.closest('.event_wrapper');

    if (taskEvent && !this.dndState.isDragMove) {
      const wxEvent = taskEvent.closest('.wx-event-calendar-event');
      const taskId = wxEvent?.dataset?.id;

      taskId && this.openTaskSettings(taskId);
    }

    if (this.dndState.isDragMove) {
      const events = calendar.querySelectorAll(`.wx-event-calendar-event[data-id="${this.dndState.eventId}"]`);

      if (events && events.length) {
        const targetWeek = this.dndState.targetWeek;
        const coords = events[events.length - 1].getBoundingClientRect();

        const debouncedRecalculateWeekHeights = _.debounce(() => {
          Array.from(document.querySelectorAll('.wx-event-calendar-calendar-week')).filter(el => el !== targetWeek)
            .forEach(el => {
              this._recalculateWeekHeightWithEvents(el);
            });

          this.dndState.targetWeek = null;
        }, 100).bind(this);

        if (coords.left === this.dndState.eventCoords?.left && coords.width === this.dndState.eventCoords?.width) {
          debouncedRecalculateWeekHeights();
        }
      }
    } else {
      this.dndState.targetWeek = null;
    }

    this.dndState.eventId = null;
    this.dndState.isDragStart = false;
    this.dndState.isDragMove = false;
    this.dndState.eventCoords = null;
    this.dndState.resizer = false;

    calendar.classList.remove('disabled-layout-by-drag');
    document.querySelectorAll('.wx-event-calendar-day').forEach(el => el.classList.remove('hover-dy-drag'));
  }

  handleMouseMove(e) {
    const pointElement = document.elementFromPoint(e.clientX, e.clientY);

    const calendarView = pointElement?.closest('#calendar_view_id');

    if (calendarView) {
      this.dndState.mouseOverCalendar = true;
    } else {
      this.dndState.mouseOverCalendar = false;
    }

    if (pointElement && this.dndState.isDragStart) {
      const targetWeek = pointElement?.closest('.wx-event-calendar-calendar-week');

      this.dndState.isDragMove = true;
      this.dndState.targetWeek = targetWeek;

      const debuncedMove = _.debounce(this._moveOrResize, 200).bind(this);

      debuncedMove(e);

      return;
    }

    this._checkEventByMouseMove(e);
  }

  _moveOrResize(e) {
    if (!this.dndState.isDragStart) {
      return;
    }

    const state = this.calendarInstance.getState();
    const config = state.viewModel.config;

    const weeks = Array.from(document.querySelectorAll('.wx-event-calendar-calendar-week'));

    if (this.dndState.targetWeek) {
      const targetWeek = this.dndState.targetWeek;
      const eventsInTargetWeek = Array.from(this.dndState.targetWeek.querySelectorAll('.wx-event-calendar-event'));
      const hasHiddenEvents = eventsInTargetWeek.some(el => {
        const topValue = parseFloat(el.style.top);
        const bottomValue = topValue + config.eventHeight;

        if (bottomValue >= this.viewSettings.minCellHeight) {
          return true;
        }

        return false;
      });

      weeks.forEach(el => this._recalculateWeekHeightWithEvents(el));
      this._customEventsStylingByTimeline();
      this._hoverEvent(this.dndState.eventId);

      const needExpandByResize = this._hoverAndExpandDays();
      const needExpand = this.dndState.resizer ? needExpandByResize : hasHiddenEvents;

      if (!targetWeek.classList.contains('calendar-week-expanded') && needExpand) {
        targetWeek.classList.add('calendar-week-expanded');
      }
    }
  }

  checkExistEventElement(eventId) {
    const event = document.querySelector(`.wx-event-calendar-event[data-id="${eventId}"]`);

    return !!event;
  }

  _checkEventByMouseMove(e) {
    const event = e.target.closest('.wx-event-calendar-event');
    const detailsTooltip = document.querySelector('.calendar-page-event-details-tooltip');

    if (e.target.closest('.calendar-page-event-details-tooltip')) {
      return;
    }

    if (!event) {
      this.dndState.eventId = null;
      this._removeEventHover();
      this.hideDetailsTooltip();
      this.hideHightlights();

      return;
    }

    if (event?.dataset?.id === this.dndState.eventId) {
      return;
    }

    if (event && detailsTooltip && event?.dataset?.id !== detailsTooltip?.dataset?.id) {
      this.hideDetailsTooltip();
    }

    if (event && event?.dataset?.id !== this.dndState.eventId) {
      this.dndState.eventId = event?.dataset?.id;
      this._removeEventHover();
    }

    this.hideHightlights();

    if (event && this.tasksMap instanceof Map && this.tasksMap.size > 0) {
      const eventId = +event?.dataset?.id;

      if (!detailsTooltip) {
        clearTimeout(tooltipTimer);
        this._showDetailsTooltip(e, eventId, event);
      }

      this._showEventHightlight(event);
      this._hoverEvent(eventId);
    }
  }

  _showDetailsTooltip(e, eventId, eventNode) {
    const leftSidebarWidth = Store.getters.leftSidebarWidth;
    const eventCoords = eventNode.getBoundingClientRect();
    const mouseX = e.clientX;
    const task = this.tasksMap.get(eventId);

    const headerHeight = 115;
    const tooltipHeight = 335;
    const tooltipWidth = 283;
    const viewportHeight = document.documentElement.clientHeight;

    const isTopAligned = eventCoords.top <= tooltipHeight;
    const top = isTopAligned ? eventCoords.top - headerHeight + 23 : 'auto';
    const bottom = isTopAligned ? 'auto' : viewportHeight - eventCoords.top - 29;

    let left;
    const fitsLeft = mouseX - tooltipWidth < eventCoords.left;
    const fitsWithinEventWidth = eventCoords.width <= tooltipWidth;
    const fitsRight = eventCoords.left + eventCoords.width <= mouseX + tooltipWidth;

    if (fitsLeft || fitsWithinEventWidth) {
      left = eventCoords.left - leftSidebarWidth;
    } else if (fitsRight) {
      left = eventCoords.left + eventCoords.width - tooltipWidth - leftSidebarWidth;
    } else {
      left = mouseX - leftSidebarWidth - 20;
    }

    tooltipTimer = setTimeout(() => {
      this.calendarInstance.api.exec('show-details', {
        task,
        tooltipCoords: {
          left: `${left}px`,
          top: top !== 'auto' ? `${top}px` : top,
          bottom: bottom !== 'auto' ? `${bottom}px` : bottom,
        },
      });
    }, 1000);
  }

  _showEventHightlight(event) {
    if (!this.calendarInstance) {
      return;
    }

    const state = this.calendarInstance.getState();
    const eventData = state.viewModel.getEventById(+event?.dataset?.id);
    const monthTable = state.viewModel.monthTable;
    const leftSidebarWidth = Store.getters.leftSidebarWidth;
    const calendarGridLayout = document.querySelector('.wx-event-calendar-layout.wx-event-calendar');

    if (eventData) {
      calendarGridLayout.style.setProperty('--hightlight-border-color', `${eventData.color?.border}`);

      const startDay = moment(eventData.start_date).startOf('day').toDate().getTime();
      const endDate = moment(eventData.end_date).startOf('day').toDate().getTime();

      monthTable.forEach(weekConfig => {
        const weekStartTime = new Date(weekConfig.start_date).getTime();
        const weekEndTime = new Date(weekConfig.end_date).getTime();
        const weekElement = document.querySelectorAll('.wx-event-calendar-calendar-week')[weekConfig.index];
        const hightlightElement = weekElement.querySelector('.event-hightlight');
        const eventElement = weekElement.querySelector(`.wx-event-calendar-event[data-id="${eventData.id}"]`);

        if (!eventElement) return;

        const opacity = window.getComputedStyle(eventElement).opacity;

        if (opacity === '1') return;

        let left = null;
        let width = null;

        if (startDay <= weekStartTime && endDate >= weekEndTime) {
          left = '2px';
          width = `${weekElement.clientWidth - 4}px`;
        } else if (startDay > weekStartTime && endDate > weekEndTime) {
          const eventStartDayCell = weekElement.querySelector(`.wx-event-calendar-day[data-cell="${startDay.toString()}"]`);

          if (eventStartDayCell) {
            const coords = eventStartDayCell.getBoundingClientRect();

            left = `${coords.left - leftSidebarWidth + 2}px`;
            width = `${document.documentElement.clientWidth - coords.left - 4}px`;
          }
        } else if (startDay < weekStartTime && endDate < weekEndTime) {
          const eventEndDayCell = weekElement.querySelector(`.wx-event-calendar-day[data-cell="${endDate.toString()}"]`);

          if (eventEndDayCell) {
            const coords = eventEndDayCell.getBoundingClientRect();

            left = '2px';
            width = `${coords.left + coords.width - leftSidebarWidth - 4}px`;
          }
        }

        if (left && width) {
          hightlightElement.style.left = left;
          hightlightElement.style.width = width;
          hightlightElement.classList.add('visible');
        }
      });
    }
  }

  _hoverEvent(eventId) {
    const events = document.documentElement.querySelectorAll(`.wx-event-calendar-event[data-id="${eventId}"`);

    events.forEach(el => el.classList.add('hover-event'));
  }

  _removeEventHover() {
    document.documentElement.querySelectorAll('.hover-event').forEach(el => el.classList.remove('hover-event'));
  }

  _hoverAndExpandDays() {
    if (!this.calendarInstance) {
      return;
    }
    const state = this.calendarInstance.getState();
    const eventData = state.viewModel.getEventById(+this.dndState.eventId);
    const monthTable = state.viewModel.monthTable;

    let needExpand = false;

    if (eventData) {
      const startDay = moment(eventData.start_date);
      const endDate = moment(eventData.end_date);

      startDay.startOf('day');
      endDate.startOf('day');

      const eventStartDateTime = new Date(startDay.format()).getTime();
      const eventEndDateTime = new Date(endDate.format()).getTime();

      const days = document.querySelectorAll('.wx-event-calendar-day');
      const hoverDays = [];

      days.forEach(el => {
        el.classList.remove('hover-dy-drag');
        if (+el.dataset.cell >= eventStartDateTime && +el.dataset.cell <= eventEndDateTime) {
          el.classList.add('hover-dy-drag');
          hoverDays.push(el);
        }
      });

      const targetDay = hoverDays[hoverDays.length - 1];
      const parentWeek = targetDay.closest('.wx-event-calendar-calendar-week');
      const dayIndex = targetDay.dataset.index;
      const weekIndex = parentWeek.dataset.index;
      const showMoreBtn = targetDay.querySelector('.calendar_custom_more_button');

      const stateWeek = monthTable[weekIndex];
      const stateDay = stateWeek.days[dayIndex];
      const allEvents = stateDay.events;

      if (allEvents > this.viewSettings.maxVisibleEvents || showMoreBtn) {
        needExpand = true;
      }

      return needExpand;
    }
  }

  hideDetailsTooltip() {
    clearTimeout(tooltipTimer);
    this.calendarInstance?.api?.exec('show-details', null);
  }

  hideHightlights() {
    const calendarGridLayout = document.querySelector('.wx-event-calendar-layout.wx-event-calendar');

    calendarGridLayout && calendarGridLayout.style.setProperty('--hightlight-border-color', 'transparent');

    document.querySelectorAll('.event-hightlight').forEach(el => el.classList.remove('visible'));
  }

  _getDate(ms) {
    const date = new Date(ms);
    const month = date.getMonth();
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay();

    return {
      fullDate: date,
      month: MONTH_LOCALES[month],
      dayMonth: dayOfMonth,
      dayWeek: WEEK_LOCALES[dayOfWeek],
    };
  }

  _toggleExpandCollpase(moreButton) {
    if (moreButton) {
      const parentWeek = moreButton.closest('.wx-event-calendar-calendar-week');
      const parentCell = moreButton.closest('.wx-event-calendar-day');

      if (parentWeek.classList.contains('calendar-week-expanded')) {
        this._collapse(parentWeek);

        return;
      }

      parentCell.classList.add('calendar-day-expanded');
      this._expand(parentWeek);
    }
  }

  _expand(week) {
    const calendarTable = document.querySelector('.wx-event-calendar-table');
    const allWeeks = document.querySelectorAll('.wx-event-calendar-calendar-week');
    const weekIndex = week.dataset.index;

    this._recalculateWeekHeightWithEvents(week);

    week.classList.add('calendar-week-expanded');

    setTimeout(() => {
      const weeks = document.querySelectorAll('.wx-event-calendar-calendar-week');
      const coords = weeks[weekIndex].getBoundingClientRect();

      if (weekIndex === allWeeks.length - 1) {
        calendarTable.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
      }

      if (coords.bottom > document.documentElement.clientHeight - 30) {
        calendarTable.scrollBy({
          top: +(coords.bottom - document.documentElement.clientHeight + 60),
          behavior: 'smooth',
        });
      }
    }, 0);
  }

  _collapse(week) {
    week.classList.remove('calendar-week-expanded');
    week.querySelectorAll('.wx-event-calendar-day').forEach(cell => {
      cell.classList.remove('calendar-day-expanded');
    });
  }

  _recalculateWeekHeightWithEvents(week) {
    if (!week || !this.calendarInstance) return;

    week.querySelectorAll('.hidden-event').forEach(el => el.classList.remove('hidden-event'));

    const { viewModel: { config, monthTable } } = this.calendarInstance.getState();
    const calendarGridLayout = document.querySelector('.wx-event-calendar-layout.wx-event-calendar');
    const allEventsOfWeek = Array.from(week.querySelectorAll('.wx-event-calendar-event'));
    const maxValue = ((config.eventHeight + config.eventVerticalSpace) * MAX_EVENTS_PER_CELL) + config.dateHeight;
    const weekIndex = week.dataset.index;

    let maxHeight;

    week.classList.remove('calendar-week-custom-max-height');
    week.classList.remove('calendar-week-custom-max-height-with-count-desc');

    if (!allEventsOfWeek.length) {
      // maxHeight = monthTable[weekIndex].height + config.eventHeight;
      week.style.setProperty(`--calendar-week-custom-max-height-by-index-${weekIndex}`, `${this.viewSettings.minCellHeight}px`);

      return;
    }

    const hasMaxVisibleEvents = allEventsOfWeek.length && allEventsOfWeek.some(el => parseFloat(el.style.top) + config.eventHeight + config.eventVerticalSpace >= maxValue);

    if (hasMaxVisibleEvents) {
      const hiddenEventsDescription = week.querySelector('.calendar_custom_events_count');

      if (hiddenEventsDescription) {
        week.classList.add('calendar-week-custom-max-height-with-count-desc');
        maxHeight = maxValue + 48;
        calendarGridLayout.style.setProperty('--calendar-week-custom-max-height-with-count-desc', `${maxHeight}px`);
      } else {
        week.classList.add('calendar-week-custom-max-height');
        maxHeight = maxValue + config.eventHeight + config.eventVerticalSpace;
        calendarGridLayout.style.setProperty('--calendar-week-custom-max-height', `${maxHeight}px`);
      }
    } else {
      const bottomEventElement = allEventsOfWeek.reduce((maxElement, currentElement) => (parseFloat(currentElement.style.top) > parseFloat(maxElement.style.top) ? currentElement : maxElement));
      const bottomEventTop = parseFloat(bottomEventElement.style.top);

      if (bottomEventTop + config.eventHeight <= this.viewSettings.minCellHeight) {
        week.style.setProperty(`--calendar-week-custom-max-height-by-index-${weekIndex}`, `${this.viewSettings.minCellHeight}px`);

        return;
      }

      maxHeight = bottomEventTop + (config.eventHeight * 2) + config.eventVerticalSpace;
      week.style.setProperty(`--calendar-week-custom-max-height-by-index-${weekIndex}`, `${maxHeight}px`);
    }
  }

  openTaskSettings(taskId) {
    this.hideDetailsTooltip();
    this.hideHightlights();

    const taskViewPath = `${routerHelper.getCurrentRoute().path}/task/${taskId}`;
    const taskSettingsRoute = routerHelper.isTaskSettingsRoute();

    if (taskSettingsRoute) {
      // const ganttTasks = Store.getters['tasksModel/getTasksForGantt'](projectsModel.getActiveGanttId()).data;
      // const task = ganttTasks.find(t => +t.id === +taskId);

      // if (!task || task?.type === this.ganttInstance.config.types.button || !task.parent || task.parent === 1) {
      //   return;
      // }

      routerHelper.pushRoute({
        name: taskSettingsRoute,
        params: {
          taskId: String(taskId),
        },
      });
    } else {
      routerHelper.changeRoute(taskViewPath);
    }
  }

  closeTaskSettings() {
    const currentRoute = routerHelper.getCurrentRoute();
    const parent = currentRoute.path.split('/task/')[0];

    routerHelper.changeRoute(parent);
  }

  _getStartDate(task) {
    task.start_date = this.ganttInstance.getClosestWorkTime({
      date: task.start_date,
      dir: 'future',
      task,
    });
  }

  _getEndDate(task) {
    task.end_date = this.ganttInstance.getClosestWorkTime({
      date: task.end_date,
      dir: 'past',
      task,
    });
  }

  calcNewWorkDays(task, updates) {
    let isNewWorkDays = true;

    const prevStartDateTime = new Date(task.start_date).getTime();
    const prevEndDateTime = new Date(task.end_date).getTime();
    const enyityStartDateTime = new Date(updates.start_date).getTime();
    const startDay = moment(updates.start_date);
    const endDate = moment(updates.end_date);

    startDay.startOf('day');
    endDate.add(1, 'days');
    endDate.startOf('day');

    const updatedTask = {
      ...task,
      start_date: new Date(startDay.format()),
      end_date: new Date(endDate.format()),
    };

    this._getStartDate(updatedTask);
    const updatedStartDateTime = new Date(updatedTask.start_date).getTime();

    if (updatedStartDateTime > enyityStartDateTime) {
      const updatedEndDate = moment(updatedTask.end_date);

      updatedEndDate.add(1, 'days');
      updatedEndDate.startOf('day');
      updatedTask.end_date = new Date(updatedEndDate.format());

      isNewWorkDays = false;
    }

    this._getEndDate(updatedTask);
    const updatedEndDateTime = new Date(updatedTask.end_date).getTime();

    if (updatedStartDateTime === prevStartDateTime && updatedEndDateTime === prevEndDateTime) {
      isNewWorkDays = false;
    }

    if (!isNewWorkDays) {
      return false;
    }

    return updatedTask;
  }

  createTask(task, dateFromCalendar) {
    const parentData = this.ganttInstance.getTaskByIndex(0);

    task.start_date = gridCustomButtons.helpers.calculateStarDateForTask(task, parentData.id, dateFromCalendar);
    task.estimation = 0;
    task.owner_id = Store.getters['resourcesModel/getResourceIdByUserId'](user.id);
    task.calendar_id = parentData.calendar_id;

    const endDate = this.ganttInstance.calculateEndDate({
      start_date: task.start_date,
      duration: 1,
      unit: this.ganttInstance.config.durationData.mode,
      task: parentData,
    });

    const calculatedDuration = this.ganttInstance.calculateDuration(task.start_date, endDate, parentData);
    const sortOrderAndIndex = gridCustomButtons.helpers.calculateSortOrderForTask(parentData.id);

    task.end_date = this.ganttInstance.calculateEndDate({
      start_date: task.start_date,
      duration: calculatedDuration,
      unit: this.ganttInstance.config.duration_unit,
      task: parentData,
    });

    task.duration = calculatedDuration;
    task.sortorder = sortOrderAndIndex.sortorder;

    this.calendarInstance.resourcesToNewTask = task.resources;
    this.calendarInstance.api.exec('create-task-from-calendar', {
      task,
      parentId: parentData.id,
      sortOrderAndIndex: sortOrderAndIndex.index,
    });
  }

  checkEventBeforeUpdate(obj) {
    if (obj) {
      this.hideDetailsTooltip();
      const task = this.tasksMap.get(+obj.id);

      if (task) {
        const isEventExist = this.checkExistEventElement(obj.id);
        const ganttTask = this.ganttInstance.getTask(task.id);
        const isBlockedStartDate = workloadCore.isTaskStartDateBlockedByLinkDependencies(ganttTask);
        const isChangedStartDate = obj.event.start_date.valueOf() !== ganttTask.start_date.valueOf();

        if (isChangedStartDate && isBlockedStartDate) {
          Vue.$toast.info(__('autoscheduling_block_start_date'));

          obj.event.start_date = task.start_date;
          obj.event.end_date = task.end_date;

          this.refresh(250);

          return obj;
        }

        if (!isEventExist && !this.dndState.mouseOverCalendar) {
          obj.event.start_date = task.start_date;
          obj.event.end_date = task.end_date;

          this.refresh(250);

          return obj;
        }

        const taskWithNewWorkDays = this.calcNewWorkDays(task, obj.event);

        if (!taskWithNewWorkDays) {
          obj.event.start_date = task.start_date;
          obj.event.end_date = task.end_date;

          this.refresh(250);

          return obj;
        }

        this.calendarInstance.api.exec('update-task-by-worker', taskWithNewWorkDays);

        return taskWithNewWorkDays;
      }
    }
  }

  destroy() {
    this.calendarInstance.destructor();
    this.calendarInstance = null;
    this.ganttInstance = null;
  }
}
const calendar = new Calendar();

export default calendar;
