import app, { appIds } from '../../../app';

import './colorPickerPopup';
import rights from '../../../components/rights'; // !FIXME

let _innerHandlers = null;
let _innerViews = null;
let _innerHelpers = null;

const {
  popupViews: { ID_VIEW_POPUP_MC_ACTIONS_MENU },
  events: {
    ID_EVENT_MASSCHANGE_UPDATESTATE,
    ID_EVENT_MC_ACTIONS_CLICK,
    ID_EVENT_MASSCHANGE_UPDATE_TASKS,
  },
} = appIds;

const ID_VIEW_MENU_MC_ACTIONS = 'idViewMenuMcActions';

_innerHelpers = {
  // {Array} -> {Array}
  composeMenuData(config) {
    return config.map(cfgEntry => ({
      id: cfgEntry.id,
      value: cfgEntry.value || 'default',
      icon: cfgEntry.icon || '',
      disabled: cfgEntry.disabled,
      $css: `${cfgEntry.css} ${cfgEntry.disabled ? 'menu-list-disabled' : ''}`,
      onClick: cfgEntry.onClick || _innerHandlers.onMenuItemClick,
      onHover: cfgEntry.onHover || _innerHandlers.onMenuItemHover,
      hideByClick: cfgEntry.hideByClick,
    }));
  },
  hideMenuPopup() {
    const __$$popupView = $$(ID_VIEW_POPUP_MC_ACTIONS_MENU);

    if (__$$popupView) {
      __$$popupView.hide();
    }
  },
};

_innerHandlers = {
  // default handler
  onMenuItemHover() {
    console.info('onMenuItemHover -> default'); // !DEBUG
  },
  //
  onMcUpdateTasks() {
    _innerHelpers.hideMenuPopup();
  },
  onMcUpdateState() {
    _innerHelpers.hideMenuPopup();
  },
  //
  onGlobalPopupShow() {
    _innerHelpers.hideMenuPopup();
  },
  //
  onMenuItemClick(id, e, node) {
    const __dataItem = this.getItem(id);

    if (!__dataItem || __dataItem.disabled === true) {
      return;
    }

    setTimeout(() => {
      __dataItem.onClick(id, node);
    }, 0);

    if (__dataItem.hideByClick) {
      $$(ID_VIEW_POPUP_MC_ACTIONS_MENU).hide();
    }
  },
  //
  onMouseMove(id, e, node) {
    const __dataItem = this.getItem(id);

    if (!__dataItem || __dataItem.disabled === true) {
      return;
    }

    setTimeout(() => {
      __dataItem.onHover(id, e, node);
    }, 0);
  },
  //
  onBeforePopupShow() {
    let { menuConfig } = this;

    menuConfig = menuConfig.filter(menu => {
      if (!rights.project.hasRight(gantt.config.gantt_id, 'delete_task') && menu.id === 'idRemoveSelected') {
        return;
      }
      if (!rights.project.hasRight(gantt.config.gantt_id, 'static_fields') && menu.id === 'idSetColorSelected') {
        return;
      }

      return menu;
    });

    if (!Array.isArray(menuConfig) || menuConfig.length === 0) {
      return false;
    }

    const __menuData = _innerHelpers.composeMenuData(menuConfig);

    $$(ID_VIEW_MENU_MC_ACTIONS).parse(__menuData);
  },
  //
  onPopupHide() {
    $$(ID_VIEW_MENU_MC_ACTIONS).clearAll();
  },
};

const innerTemplates = {
  menuItem: item => `<div class='context_menu_list_item_icon'>${item.icon}</div>`
            + `<div class='context_menu_list_item_text'>${item.value}</div>`,
};

_innerViews = {
  contextMenuList: {
    id: ID_VIEW_MENU_MC_ACTIONS,
    view: 'menu',
    css: 'context_menu_list',
    borderless: true,
    autoheight: true,
    layout: 'y',
    template: innerTemplates.menuItem,
    data: [],
    type: {
      height: 32,
    },
    on: {
      onItemClick: _innerHandlers.onMenuItemClick,
      onMouseMove: _innerHandlers.onMouseMove,
    },
  },
};

const _menuPopupView = {
  id: ID_VIEW_POPUP_MC_ACTIONS_MENU,
  view: 'popupWithoutPoint',
  css: 'context_menu_popup',
  hidden: true,
  borderless: true,
  autoheight: true,
  width: 237,
  body: {
    rows: [_innerViews.contextMenuList],
  },
  on: {
    onBeforeShow: _innerHandlers.onBeforePopupShow,
    onHide: _innerHandlers.onPopupHide,
  },
};

webix.ui(_menuPopupView);

app.on('popup:show', _innerHandlers.onGlobalPopupShow);
app.on(ID_EVENT_MASSCHANGE_UPDATE_TASKS, _innerHandlers.onMcUpdateTasks);
app.on(ID_EVENT_MASSCHANGE_UPDATESTATE, _innerHandlers.onMcUpdateState);
