var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-0 personal-projects-table gantt_scroll",
        attrs: {
          height: _vm.tableHeight,
          headers: _vm.tableHeaders,
          "fixed-header": "",
          items: _vm.projects,
          "item-key": _vm.id,
          "hide-default-footer": "",
          "disable-pagination": "",
          "show-select": false,
          "calculate-widths": true,
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    staticClass: "name-cell",
                    style: { maxWidth: _vm.projectNameWidth + "px" },
                  },
                  [
                    _c("tooltip", {
                      class: ["tooltip-wrapper"],
                      attrs: { content: item.name },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.type",
            fn: function (ref) {
              var item = ref.item
              return [
                item.costAccess
                  ? _c(
                      "div",
                      { key: _vm.costComponentKey, staticClass: "type-cell" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cost-dropdown",
                            style: _vm.isFreezeCost
                              ? { "pointer-events": "none" }
                              : {},
                          },
                          [
                            _c("dropdown-select", {
                              staticClass:
                                "gp_autotest_projects_table_dropdown_select_cost",
                              attrs: {
                                "drop-list": _vm.costDropdown,
                                multiselect: false,
                                "show-selected-first": false,
                                "show-reset-btn": false,
                                "highlight-active-state": false,
                                "item-template": "text",
                                "track-by-text": "text",
                                outline: "hover",
                                "track-by-item-tooltip": "tooltip",
                                selected: _vm.costDropdown.find(function (i) {
                                  return i.id === item.type
                                }),
                              },
                              on: {
                                selected: function ($event) {
                                  return _vm.onSelectCostType($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "no-rights-item" },
                      [
                        _c("div", { staticClass: "no-rights-item__text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.locales("no_rights_key")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("svg-sprite", {
                          staticClass: "icon",
                          attrs: { name: "hiden", color: "#B2B2B2" },
                        }),
                      ],
                      1
                    ),
              ]
            },
          },
          {
            key: "item.cost",
            fn: function (ref) {
              var item = ref.item
              return [
                item.costAccess
                  ? _c(
                      "div",
                      { key: _vm.costComponentKey, staticClass: "cost-cell" },
                      [
                        item.type === 3
                          ? _c("div", { staticClass: "cost-disable" }, [
                              _c("div"),
                            ])
                          : !_vm.autoBudget(item.projectId)
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      content: _vm.locales(
                                        "budget_manual_mode_tooltip_notify"
                                      ),
                                    },
                                    expression:
                                      "{ content: locales('budget_manual_mode_tooltip_notify') }",
                                  },
                                ],
                                staticClass: "cost-disable",
                              },
                              [_c("div")]
                            )
                          : _c(
                              "div",
                              {
                                style: _vm.isFreezeCost
                                  ? { "pointer-events": "none" }
                                  : {},
                              },
                              [
                                _c("vgp-row-input", {
                                  staticClass:
                                    "cost-input gp_autotest_projects_table_cost_input",
                                  attrs: {
                                    value: item.cost,
                                    "font-size": 12,
                                    "max-width": 80,
                                    "input-type": "number",
                                    format: "currency",
                                    bold: true,
                                  },
                                  on: {
                                    onChange: function (value) {
                                      return _vm.onChangeCost(value, item)
                                    },
                                    onKeydown: function (value) {
                                      return _vm.onChangeCost(value, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.rights",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "rights-cell" },
                  [
                    item.roleAccess
                      ? _c(
                          "div",
                          { staticClass: "rights-cell__dropdown" },
                          [
                            _c("dropdown-select", {
                              staticClass:
                                "gp_autotest_projects_table_dropdown_select_rights",
                              attrs: {
                                "drop-list": _vm.dropdownRoles,
                                multiselect: false,
                                "show-selected-first": false,
                                "show-reset-btn": false,
                                "highlight-active-state": false,
                                "item-template": "text",
                                "track-by-text": "title",
                                outline: "hover",
                                "drop-position": "fixed",
                                "track-by-item-tooltip": "tooltip",
                                "fixed-width": 320,
                                "need-description": true,
                                size: "autoWidth",
                                "parent-selector-for-calc-position-drop":
                                  "personal-projects-table",
                                selected: _vm.dropdownRoles.find(function (
                                  role
                                ) {
                                  return role.id === item.projectRoleId
                                }),
                              },
                              on: {
                                selected: function ($event) {
                                  return _vm.onChangeProjectRole($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _c("vgp-input", {
                          staticClass:
                            "rights-cell__description gp_autotest_projects_table_input_rights",
                          attrs: {
                            value: item.roleOnProject.title,
                            small: true,
                            "is-disabled": true,
                          },
                        }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.deleteButton",
            fn: function (ref) {
              var item = ref.item
              return [
                item.removeAccess
                  ? _c(
                      "div",
                      { staticClass: "del-cell" },
                      [
                        _c("icon-button", {
                          staticClass: "gp_autotest_projects_table_btn_delete",
                          attrs: {
                            icon: { name: "delete", size: 20 },
                            size: "small",
                          },
                          on: {
                            onClick: function ($event) {
                              return _vm.onDeleteFromProject(item)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm._v(" "),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.isShowConfirmPopup,
          title: _vm.locales("change_resource_type_title"),
          message: _vm.locales("change_resource_type_warning"),
        },
        on: {
          onApply: _vm.onApplyChangeType,
          onCancel: _vm.onCancelChangeType,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }