<template>
  <div
    :class="['view_container', { [$style.inactive]: inactive }]"
  >
    <div
      v-if="projectChannelConnection.showProject"
      class="connection_project_title_msteams"
    >
      <div
        class="text"
        :title="getProjectByGanttId(projectChannelConnection.gantt_id).name"
      >
        {{ getProjectByGanttId(projectChannelConnection.gantt_id).name }}
      </div>
    </div>
    <div
      v-else
      class="connection_project_title_msteams"
    />
    <div
      class="channel_setting_container"
      :class="{small_border: projectChannelConnection.smallBorder}"
    >
      <div
        class="team_title_msteams"
        :title="projectChannelConnection.msteams_team_name"
      >
        <div class="text">
          {{ projectChannelConnection.msteams_team_name }}
        </div>
      </div>
      <div class="channel_title_msteams">
        <div
          class="text"
          :title="projectChannelConnection.channel_name"
        >
          {{ projectChannelConnection.channel_name }}
        </div>
      </div>
      <div class="settings_title_msteams">
        <div
          class="text"
          :title="getSettingsString(projectChannelConnection).settingsText"
        >
          {{ getSettingsString(projectChannelConnection).settingsText }}
        </div>
      </div>
      <div class="buttons">
        <div
          class="edit"
          @click="!inactive && toggleEditView(projectChannelConnection.id)"
        >
          <loader v-if="waitingForActivation" />
          <div
            v-else-if="inactive"
            class="tooltip-gantt"
            data-html="true"
          >
            <div class="tooltip-gantt-html">
              <div class="tooltip-gantt-html-title">
                {{ locales('integration_msteams_add_app_to_team_tooltip') }}
              </div>
            </div>
            <webix-ui
              :class="$style.tip"
              :config="icons.tip"
            />
          </div>
          <webix-ui
            v-else
            :config="icons.editIcon"
          />
        </div>
        <div
          class="delete"
          @click="deleteItem(projectChannelConnection.id)"
        >
          <webix-ui :config="icons.deleteIcon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import editIcon from '../../../../svg/integration/settings/edit.svg';
import deleteIcon from '../../../../svg/integration/settings/delete.svg';
import tipImage from '../../../../svg/integration/settings/tip.svg';

// Helpers
import vueSvgIcon from '../../../common/svgIconForVue';
import loader from '../../Loader.vue';

export default {
  components: { loader },
  props: [
    'projectChannelConnection',
    'getSettingsString',
    'getProjectByGanttId',
    'toggleEditView',
    'deleteItem'
  ],
  data() {
    return {
      locales: __,
      icons: {
        editIcon: vueSvgIcon(editIcon, 24, 24),
        deleteIcon: vueSvgIcon(deleteIcon, 24, 24),
        tip: vueSvgIcon(tipImage, 24, 24),
      }
    };
  },
  computed: {
    inactive() {
      return !this.projectChannelConnection.service_url;
    },
    waitingForActivation() {
      return this.projectChannelConnection.waitingForActivation;
    },
  }
};
</script>
<style module src='./integrationMSTeamsCommonStyles.less' ></style>
