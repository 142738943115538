var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      class: _vm.$style["autocomplete-select-wrapper"],
    },
    [
      _c("vgp-input", {
        ref: "searchInput",
        attrs: {
          small: "",
          placeholder: !_vm.search
            ? _vm.locales("task_settings_new_dependency_search_select")
            : "",
          "icons-left": [
            { name: "search", handler: _vm.handleSearchIconClick },
          ],
          "icons-right": _vm.iconsRightConfig,
          title:
            _vm.selectedItem &&
            _vm.getFullItemOptionForFilter(_vm.selectedItem),
        },
        on: { onFocus: _vm.showDropdown },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm._v(" "),
      _vm.dropdownPosition
        ? _c("context-menu", {
            ref: "dropdownContextMenu",
            attrs: {
              position: _vm.dropdownPosition,
              height: _vm.dropdownHeight,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      !_vm.isLoading
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.filteredHighlightedItems.length,
                                    expression:
                                      "!filteredHighlightedItems.length",
                                  },
                                ],
                                class: _vm.$style["suggestions-empty"],
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.locales("no_matches_message_text")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("recycle-scroller", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.filteredHighlightedItems.length,
                                  expression: "filteredHighlightedItems.length",
                                },
                              ],
                              class: [
                                _vm.$style["suggestions-list"],
                                "gantt_scroll",
                              ],
                              attrs: {
                                items: _vm.filteredHighlightedItems,
                                "item-size": 46,
                                "key-field": "id",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              _vm.$style["suggestions-item"],
                                              _vm.selectedItem &&
                                              item.id === _vm.selectedItem.id
                                                ? _vm.$style[
                                                    "suggestions-item_selected"
                                                  ]
                                                : "",
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-sprite", {
                                              class:
                                                _vm.$style[
                                                  "suggestions-item__icon"
                                                ],
                                              attrs: {
                                                name: item.type,
                                                size: 20,
                                                type: "regular",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class:
                                                  _vm.$style[
                                                    "suggestions-item__content"
                                                  ],
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.$style[
                                                        "suggestions-item__name-wrap"
                                                      ],
                                                  },
                                                  [
                                                    _vm.additionallyFilterBy &&
                                                    !item.highlightedText
                                                      ? _c(
                                                          "span",
                                                          {
                                                            class:
                                                              _vm.$style[
                                                                "suggestions-item__name-additional"
                                                              ],
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  item[
                                                                    _vm
                                                                      .additionallyFilterBy
                                                                  ]
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      class:
                                                        _vm.$style[
                                                          "suggestions-item__name"
                                                        ],
                                                      attrs: {
                                                        title:
                                                          item[_vm.filterBy],
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          "" +
                                                            (item.highlightedText ||
                                                              item[
                                                                _vm.filterBy
                                                              ])
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.$style[
                                                        "suggestions-item__breadcrumbs"
                                                      ],
                                                    attrs: {
                                                      title: item.parentPath,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          item.parentPath
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                724548643
                              ),
                            }),
                          ]
                        : _c(
                            "div",
                            {
                              class: _vm.$style["suggestions-loader-container"],
                            },
                            [
                              _c(
                                "vue-ellipse-progress",
                                _vm._b(
                                  {},
                                  "vue-ellipse-progress",
                                  _vm.$options.loaderConfig,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4214679433
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }