<template>
  <div
    :class="$style.select"
    :style="{width: `${width}px`, ...styles}"
  >
    <div
      v-if="label"
      :class="$style.label"
    >
      {{ label }}
    </div>
    <div
      :class="$style.select_box"
      :style="{width: `${width}px`}"
    >
      <div
        :class="[$style.target, isShowSelectItems ? $style.active : '']"
        :style="{height: `${height ? height : 36}px`, lineHeight:`${height ? height : 36}px`}"
        @click="toggleSelectItems"
      >
        {{ currentItem[targetValue] }}
      </div>
      <div
        v-if="isShowSelectItems"
        ref="list"
        :class="[$style.select_list, 'gantt_scroll']"
        :style="`${position}: 100%`"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          ref="selectItem"
          :class="[$style.select_item, item[targetValue] === currentItem[targetValue] ? $style.active : '']"
          @click="onSelectItem(item, selectType)"
        >
          {{ item[targetValue] || locales(`${item[targetValue]}`) }}
        </div>
      </div>
    </div>
    <div
      v-if="inputDescription"
      :class="$style.input_description"
    >
      {{ inputDescription }}
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'label',
    'items',
    'styles',
    'currentItem',
    'targetValue',
    'selectHandler',
    'width',
    'height',
    'selectType',
    'position',
    'inputDescription'
  ],
  data() {
    return {
      locales: __,
      isShowSelectItems: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.hideSelectItems);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideSelectItems);
  },
  methods: {
    toggleSelectItems() {
      this.isShowSelectItems = !this.isShowSelectItems;
    },
    hideSelectItems(e) {
      if (!this.$el.contains(e.target)) {
        this.isShowSelectItems = false;
      }
    },
    onSelectItem(item, selectType) {
      this.isShowSelectItems = false;
      if (this.selectHandler) {
        this.selectHandler(item, selectType);
      }
    },
  },
};
</script>

<style module src='../less/select.less'></style>
