var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.button, _vm.active ? _vm.$style.active : ""],
      on: {
        click: function ($event) {
          return _vm.$emit("onClick", $event)
        },
      },
    },
    [
      _vm.checkLabel
        ? _c("div", { class: _vm.$style.label }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.checkValue
        ? _c("div", { class: _vm.$style.value }, [
            _vm._v("\n    " + _vm._s(_vm.value) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("svg-sprite", {
        class: _vm.$style.icon,
        attrs: {
          name: _vm.active ? "arrow-top" : "arrow-down",
          size: 20,
          height: 24,
          width: 24,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }