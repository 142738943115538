import CollectCollabData from './CollectCollabData';
import UpToDate from './UpToDate';
import collaborationMethods from '../methods';
import Superviser from './Superviser';
import app from '../../../app';
import globalStore from '$$store/main';
import constants from '../../../helpers/constants';

import ganttParams from './componentsParams/ganttgrid';
import ganttContextMenuParams from './componentsParams/ganttContextMenu';
import settingsLinkPopupParams from './componentsParams/settingsLinkPopup';
import timer from '../../../helpers/Timer';
import rights from '../../rights/index';

class Blocker {
  constructor() {
    this.active = false;
    this._addListtheners();
    this._observedEvents = [];
    this._updateInProgress = false;
    this.superviser = null;
    this._countOfBlockedEvents = 0;
  }

  _addListtheners() {
    ganttParams.activateBlockListheners(this._activate.bind(this));
    ganttParams.deactivateBlockListheners(this._deactivate.bind(this));

    ganttContextMenuParams.activateBlockListheners(this._activate.bind(this));
    ganttContextMenuParams.deactivateBlockListheners(this._deactivate.bind(this));

    settingsLinkPopupParams.activateBlockListheners(this._activate.bind(this));
    settingsLinkPopupParams.deactivateBlockListheners(this._deactivate.bind(this));
  }

  _activate(params) {
    !timer.getTimer('fullBlockingTime') && timer.start('fullBlockingTime');

    this.active = true;

    if (this._canNotEditingItem(params)) {
      this.unblock();

      return;
    }

    this.superviser = new Superviser(params);
    params.task_id && CollectCollabData.addTaskId(params.task_id);
    this._countOfBlockedEvents = CollectCollabData.allCollectedEventsCount;
  }

  _canNotEditingItem(params) {
    if (!CollectCollabData.events.length && !CollectCollabData.toUpdate) { return false; }

    const taskFromModel = params.task_id ? globalStore.getters['tasksModel/getTask'](params.task_id) : null;
    let taskChangesCollab = null;
    let taskResourcesCollab = null;

    if (params.task_id && params.column_id && Number.isNaN(+params.column_id)) {
      taskChangesCollab = CollectCollabData.getChangedFieldsInTaskFromCollectedEvents(params.task_id);
      taskResourcesCollab = CollectCollabData.getUnassignedResourcesByTaskFromCollectedEvents(params.task_id);
    }

    return selectTaskWithChangedLinks()
          || selectDeletedTask()
          || selectTaskBecameProject()
          || selectTaskBecameMilestone()
          || selectDatesAndHasChangedWorkCalendars()
          || selectDeletedCustomFields()
          || selectTaskWithoutEditRights()
          || selectTaskWithChangedFields()
          || selectAssignCellWithUpdatedResources();

    function selectTaskWithChangedLinks() {
      return params.task_id
        && params.column_id === 'predecessors'
        && CollectCollabData.tasksWithCreatedLinksInCollectedEvents.includes(params.task_id);
    }

    function selectDeletedTask() {
      return params.task_id
          && CollectCollabData.deletedTasksInCollectedEvents.includes(params.task_id);
    }

    function selectTaskBecameProject() {
      if (!params.task_id || !params.column_id) { return false; }

      const editableFieldsInProject = ['text', 'start_date', 'predecessors', 'deadline'];

      if (editableFieldsInProject.includes(params.column_id)) { return false; }

      if (!Number.isNaN(+params.column_id)) {
        // const column = userCustomColumns.getColumnById(params.column_id);
        const column = globalStore.getters['columns/getCustomColumnById'](params.column_id);

        return column.type === constants.CUSTOM_COLUMNS_TYPES.number.id;
      }

      return false;
    }

    function selectTaskBecameMilestone() {
      if (!params.task_id || !params.column_id || !taskChangesCollab) { return false; }

      if (taskChangesCollab.type !== 'milestone' || taskFromModel.type === taskChangesCollab.type) { return false; }

      const nonEditableFieldsForMilestone = ['duration', 'endDate', 'end_date', 'estimation'];

      return nonEditableFieldsForMilestone.includes(params.column_id);
    }

    function selectDeletedCustomFields() {
      if (!taskFromModel || !params.column_id) { return false; }

      const unassignedFields = CollectCollabData.unassignedCustomColumnsInCollectedEvents[taskFromModel.gantt_id];

      return unassignedFields && unassignedFields.includes(params.column_id);
    }

    function selectDatesAndHasChangedWorkCalendars() {
      return params.column_id
      && ['start_date', 'end_date'].includes(params.column_id)
      && CollectCollabData.hasEventProjectCalendarUpdated();
    }

    function selectTaskWithoutEditRights() {
      const currentRole = user.team.role_id;
      const changedRole = CollectCollabData.userRoleInCollectedEvents;
      const role = changedRole || currentRole;
      const staticFields = ['attachments', 'text', 'resource_id', 'start_date', 'end_date', 'duration', 'estimation', 'time_tracking', 'progress', 'status', 'priority'];
      const staticFields4Only = ['attachments', 'time_tracking', 'progress', 'status'];

      if (!params.task_id || !taskFromModel) { return false; }

      if (params && params.column_id) {
        const ganttId = taskFromModel?.gantt_id;
        const canEditStaticFields = rights.project.hasRight(ganttId, 'static_fields') && staticFields.includes(params.column_id);
        const canEditStaticFields4Only = rights.project.hasRight(ganttId, 'static_fields_4_only') && staticFields4Only.includes(params.column_id);

        if (params?.userCustomColumn && !Number.isNaN(+params.column_id) && rights.project.hasRight(ganttId, 'custom_field_edit')) { return false; }
        if (canEditStaticFields || canEditStaticFields4Only) { return false; }
      }

      const userResourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

      return taskResourcesCollab.unassigned.includes(userResourceId);
    }

    function selectTaskWithChangedFields() {
      if (!taskChangesCollab) { return false; }

      const assignedResourcesChanged = taskResourcesCollab.unassigned.length || taskResourcesCollab.assigned.length;
      const updResources = ['estimation', 'duration'].includes(params.column_id) && assignedResourcesChanged;
      const updEstimationOrDuration = params.column_id === 'resource_id'
                && (taskChangesCollab.estimation || taskChangesCollab.duration);

      const updStart = params.column_id === 'start_date' && (taskChangesCollab.duration || taskChangesCollab.endDate);
      const updEnd = params.column_id === 'end_date' && (taskChangesCollab.duration || taskChangesCollab.startDate);
      const updDuration = params.column_id === 'duration' && taskChangesCollab.startDate;

      return updResources || updEstimationOrDuration || updStart || updEnd || updDuration;
    }

    function selectAssignCellWithUpdatedResources() {
      if (params.column_id !== 'resource_id') { return false; }

      const events = CollectCollabData.resourceOnProjectsUpdatedFromCollectedEvents;

      if (!events.length) { return false; }

      return events.find(e => e.data.updated.find(i => {
        // const settings = resourcesModel.getSettingsForResource(i.resourceId, i.projectId);
        const settings = globalStore.getters['resourcesModel/getResourceProjectSettings'](i.resourceId, i.projectId);

        if (!settings) return false;

        return i.type !== settings.type || i.cost !== settings.cost;
      }));
    }
  }

  _deactivate() {
    this.active = false;
    const cnt = this;

    setTimeout(async () => {
      if (cnt.active) return;
      await cnt._refreshClientData();
    }, 400);
  }

  async _refreshClientData() {
    let sendEndAnalitic = false;

    if (CollectCollabData.events.length || CollectCollabData.toUpdate) {
      timer.end('fullBlockingTime');
      sendEndAnalitic = true;
    }

    while (CollectCollabData.events.length) {
      const e = CollectCollabData.shiftEvent();

      e.data.isUnblockCollab = true;
      collaborationMethods[`on${e.event}`](e);
    }

    if (CollectCollabData.toUpdate) {
      if (this._updateInProgress) return;

      const params = { ...CollectCollabData.toUpdate };
      const changedTasks = [...CollectCollabData.changedTaskIds];

      this._updateInProgress = true;

      await UpToDate.updateClientDataByParams(params, changedTasks);
      CollectCollabData.clear();

      this._updateInProgress = false;
    }

    sendEndAnalitic && this._sendAnalyticEnd();
    sendEndAnalitic = false;
    this._cleareTimers();
    this._countOfBlockedEvents = CollectCollabData.allCollectedEventsCount;
  }

  isNeedBlockEvent(e) {
    if (!this.active) { return false; }

    const collectedTask = {
      created: CollectCollabData.createdTasksInCollectedEvents,
      deleted: CollectCollabData.deletedTasksInCollectedEvents,
    };

    if (!this.superviser.isneedBlockEvent(e, collectedTask)) {
      return false;
    }

    return true;
  }

  checkEventIsNeedEmergencyUnblockCollab(e) {
    return this.superviser.needEmergencyUnblock(e);
  }

  unblock() {
    this._closeAllEditors();
    this._deactivate();
  }

  _closeAllEditors() {
    app.trigger('gantt:close:contextMenu');
    app.trigger('popup:resourceList:closeWithoutSave');
    gantt.callEvent('hideTimeTrackingPopup');

    app.trigger('popup:show');
    app.trigger('close:createTaskInput:withoutSave');

    gantt.unloadTaskEditors();
    gantt.unselectTask();
  }

  collectEventData(e) {
    CollectCollabData.collect(e);

    if (CollectCollabData.allCollectedEventsCount === 1) {
      this._sendAnalyticStart();
    }

    this._countOfBlockedEvents = CollectCollabData.allCollectedEventsCount;
  }

  _sendAnalyticStart() {
    userExtAnalytics.log('collaboration_blocking_start', {
      initiator: this.superviser.type,
      initiatorEvent: this.superviser.mode,
      projectId: this.superviser.projectId,
      multiviewId: this.superviser.multiviewId,
    });
  }

  _sendAnalyticEnd() {
    const analiticData = {
      initiator: this.superviser.type,
      initiatorEvent: this.superviser.mode,
      projectId: this.superviser.projectId,
      multiviewId: this.superviser.multiviewId,
      countOfEvents: this._countOfBlockedEvents,
      fullBlockingTime: timer.getTime('fullBlockingTime'),
      ganttRerenderTime: timer.getTime('ganttRerenderTime'),
      timeToGetExternalData: timer.getTime('timeToGetExternalData'),
    };

    userExtAnalytics.log('collaboration_blocking_end', analiticData);
  }

  _cleareTimers() {
    ['fullBlockingTime', 'ganttRerenderTime', 'timeToGetExternalData'].forEach(name => {
      timer.getTimer(name) && timer.clear(name);
    });
  }
}

export default new Blocker();
