import CustomCells from "../cells";

const cellTypeMap = {
  text: CustomCells.Text,
  expand: CustomCells.Expand,
  status: CustomCells.Status,
  progress: CustomCells.Progress,
  person: CustomCells.Person,
  contextMenu: CustomCells.ContextMenu
};

const cellComponent = (cellType) => {
  return cellTypeMap[cellType];
};

export default cellComponent;