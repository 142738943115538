import { api } from '../api';
import helpers from './helpers';
import app from '../../app';
import projectsModel from '../../models/projects';
import estimationModule from '../../views/gantt/modules/estimation';
import { internalApi } from '$$store/api';

export default {
  async loadData({ commit, rootState }) {
    let tasksData = [];

    if (!rootState.appMode.isBase) {
      tasksData = GT.ganttData.tasks;
    } else {
      const response = await api.get(`/tasks/?${new Date().getTime()}`);

      tasksData = response.data;
    }

    commit('setData', tasksData.map(helpers.prepareProjectTasksData));
    app.checkInit('tasks');
  },

  // updateItem()
  updateProjectTasksData({ commit, getters }, payload) {
    if (!payload) return false;
    commit('updateProjectTasksData', helpers.prepareProjectTasksData(payload));
    app.trigger('tasksModel:onStoreUpdate', { ganttId: payload.id });
  },

  // correctDates, autoScheduling, projectMove (after dnd projects on chart), progress
  async updateTasksByEvent({ commit, getters, rootState }, payload) {
    const {
      updatedTasks, masterType, actionHash, taskName,
    } = payload;

    if (!rootState.appMode.isBase) {
      return Promise.resolve();
    }

    const ganttIds = _.keys(_.groupBy(updatedTasks, 'gantt_id'));

    await Promise.all(
      ganttIds.map(ganttId => {
        const tasksData = getters.getItem(ganttId).tasks;

        tasksData.forEach(taskInModel => {
          const updatedTaskData = updatedTasks.find(t => t.id === taskInModel.id);

          if (updatedTaskData) {
            if (gantt.config.multiview) {
              updatedTaskData.open = taskInModel.open;
            }

            const oldTask = { ...taskInModel };

            commit('updateTask', { ...taskInModel, ...updatedTaskData });

            const newTask = getters.getTaskByGanttId(taskInModel.gantt_id, taskInModel.id);

            updatedTaskData.diff = helpers.getDiff(newTask, oldTask);
            Object.assign(updatedTaskData, newTask);
          }
        });

        const params = {
          tasks: updatedTasks.map(helpers.prepareTaskDataForServer),
          ganttId: +ganttId,
          actionHash,
          timezoneOffset: (new Date()).getTimezoneOffset(),
        };

        if (taskName) {
          params.taskName = taskName;
        }

        return api.post(`/tasks/${masterType}`, params);
      }),
    );
  },

  // dnd grid
  async updateTasksAfterMove({ commit, getters, rootState }, payload) {
    let {
      ganttId, ganttData, actionHash, taskName, sourceId, targetId,
    } = payload;

    ganttData = _.uniqBy(ganttData, 'id');// duplicates in dnd grid module

    const taskIds = ganttData.map(data => data.id);

    app.trigger('tasks:model:updateTasksAfterMove', taskIds);
    ganttData = ganttData.map(updatedTaskData => {
      commit('updateTask', updatedTaskData);

      return helpers.prepareTaskDataForServer(updatedTaskData);
    });

    await api.put('/tasks/moveTasks', {
      ganttId,
      taskName,
      ganttData,
      actionHash,
      sourceId,
      targetId,
    });
  },

  // dnd projects on chart
  async updateTasksAfterDragProjectDates({ commit, getters, rootState }, payload) {
    let {
      ganttId, ganttData, actionHash, taskName, updatedTask,
    } = payload;

    ganttData = ganttData.map(updatedTaskData => {
      commit('updateTask', updatedTaskData);

      return helpers.prepareTaskDataForServer(updatedTaskData);
    });

    updatedTask.start_date_for_integrations = new Date(updatedTask.start_date);
    updatedTask.end_date_for_integrations = new Date(updatedTask.end_date);

    // old was dragProjectDates
    await api.put('/tasks/dragProjectDates', {
      ganttId,
      updatedTask,
      taskName,
      ganttData,
      actionHash,
    });

    gantt.callEvent('onAfterProjectMove', [ganttData[0].id, actionHash]);
  },

  // after gantt worker
  async updateTasksAfterGanttWorker({ commit, getters, rootState }, payload) {
    Object.keys(payload.byGanttId).forEach(ganttId => {
      const changedGanttData = payload.byGanttId[ganttId];
      const projectData = getters.getItem(ganttId);
      const tasksProjectData = projectData && projectData.tasks;

      changedGanttData.tasks.forEach(changedTask => {
        const oldModelTaskData = tasksProjectData.find(t => t.id === changedTask.id);

        if (oldModelTaskData) {
          commit('updateTask', changedTask);
        }
      });
    });

    if (payload.tasks.length) {
      payload.tasks = payload.tasks.map(t => helpers.prepareTaskDataForServer(t));
      await api.post('/tasks/update', payload);
    }

    if (payload.links.length) {
      //
    }
  },

  // recalcStartDatesForTasks() after change work time, copy paste, flag needUpdateTasksDatesAfterCreateProject
  async recalculateStartDatesForTasksByGanttId({ commit, getters, dispatch }, payload) {
    const { ganttId, isChangedCalendar } = payload;
    const tasksData = getters.getItem(ganttId).tasks;
    const updatedTasks = [];

    tasksData.forEach(task => {
      let changed = false;

      if (!task.calendar_id) {
        task.calendar_id = task.gantt_id;
      }

      if (task.duration === 0) {
        task.duration = gantt.calculateDuration({
          start_date: task.start_date,
          end_date: task.end_date,
          task,
        }) || 1;
        changed = true;
      }
      const calendar = gantt.getCalendar(task.gantt_id);

      if (!gantt.isWorkDay(task.start_date, calendar) || !gantt.isWorkTime(task.start_date, 'hour', task)) {
        task.start_date = gantt.getClosestWorkTime({ date: new Date(task.start_date), dir: 'future', task });
        changed = true;
      }

      if (changed || isChangedCalendar
        || (!gantt.isWorkDay(task.end_date, calendar) || !gantt.isWorkTime(task.end_date, 'hour', task))
      ) {
        const endDate = gantt.calculateEndDate({
          start_date: task.start_date, duration: task.duration, task, unit: gantt.config.duration_unit,
        });

        if (endDate.valueOf() !== task.end_date.valueOf()) {
          task.end_date = endDate;
          changed = true;
        }
      }

      if (changed) {
        updatedTasks.push(task);
      }
    });

    if (updatedTasks.length) {
      dispatch('updateTasksByEvent', { updatedTasks, masterType: 'autoscheduling' });
    }
  },

  // backgroundUpdateTask()
  async backgroundUpdateTask({ commit, state, getters }, payload) {
    const { taskData, silent } = payload;
    const oldTaskData = getters.getTaskByGanttId(taskData.gantt_id, taskData.id);

    commit('updateTask', taskData);

    const newTaskData = getters.getTaskByGanttId(taskData.gantt_id, taskData.id);
    const newTaskDataForServer = { ...newTaskData };

    newTaskDataForServer.diff = helpers.getDiff(newTaskData, oldTaskData);

    // if (silent) {
    //   newTaskDataForServer.silent = silent;
    // }

    await api.put(`/task/${taskData.id}`, helpers.prepareTaskDataForServer(newTaskDataForServer));

    app.trigger('tasks:model:updateTask', newTaskData.id, newTaskData);
  },

  // backgroundDeleteTask()
  async backgroundDeleteTask({ commit, state, dispatch }, payload) {
    await api.delete(`/task/${payload.id}?ganttId=${payload.gantt_id}`);
    dispatch('deleteTask', payload);
    app.trigger('tasks:model:deleteTask', payload.id, payload);
  },

  // deleteTask()
  deleteTask({ commit, state, dispatch }, payload) {
    commit('deleteTask', payload);

    dispatch('comments/deleteCommentsByTaskId', payload, { root: true });

    app.trigger('tasks:model:delete', payload.id, payload);
  },

  // backgroundAddLink()
  async backgroundAddLink({ commit, state }, payload) {
    const response = await api.post('/link/', payload);

    payload.id = response.data.tid;
    commit('addLink', payload);
    app.trigger('tasks:model:addLink', payload.id, payload);

    return payload;
  },

  // backgroundUpdateLink()
  async backgroundUpdateLink({ commit, state }, payload) {
    commit('updateLink', payload);
    await api.put(`/link/${payload.id}`, payload);
    app.trigger('tasks:model:updateLink', payload.id, payload);

    return payload;
  },

  // backgroundDeleteLink()
  async backgroundDeleteLink({ commit, state }, payload) {
    await api.delete(`/link/${payload.id}?ganttId=${payload.gantt_id}`);
    commit('deleteLink', payload);
    app.trigger('tasks:model:deleteLink', payload.id, payload);
  },

  // backgroundMassLinksChange()
  async backgroundMassLinksChange({ commit, state }, payload) {
    const response = await api.put('/link/links', { links: payload });

    response.data.insert.forEach(link => {
      commit('addLink', link);
    });

    response.data.delete.forEach(link => {
      commit('deleteLink', link);
    });

    app.trigger('tasks:model:massChangeLink', response.data);

    return response.data;
  },

  // manualUpdateSortorderTasksAfterAddTask()
  updateSortorderTasksAfterAddTask({
    commit, state, dispatch, getters,
  }, payload) {
    commit('updateSortorderTasksAfterAddTask', payload);
    dispatch('updateProjectTasksData', getters.getItem(payload.ganttId));
  },

  // manualUpdateCustomValueForGanttId()  manualUpdateCustomValues() manualUpdateAllCustomValuesForGanttId()
  updateCustomValues({
    commit, state, dispatch, getters,
  }, payload) {
    commit('updateCustomValues', payload);

    const { mapData, ganttId } = payload;

    if (mapData) {
      Object.keys(mapData).forEach(id => {
        dispatch('updateProjectTasksData', getters.getItem(id));
      });

      return;
    }

    dispatch('updateProjectTasksData', getters.getItem(ganttId));
  },

  // manualUpdateResourcesForGanttId()
  updateTasksResourcesByGanttId({
    commit, state, dispatch, getters,
  }, payload) {
    commit('updateTaskResourcesByGanttId', payload);
    dispatch('updateProjectTasksData', getters.getItem(payload.ganttId));
  },

  updateTaskResourcesByGanttIdsAndTaskIds({
    commit, state, dispatch, getters,
  }, payload) {
    commit('updateTaskResourcesByGanttIdsAndTaskIds', payload);
    dispatch('updateProjectTasksData', getters.getItem(payload[0].ganttId));
  },

  // updateTask()
  updateTask({ commit, getters, state }, payload) {
    const {
      taskChanges, taskId, ganttId, silent,
    } = payload;

    if (taskChanges) {
      const taskDataOld = getters.getTaskByGanttId(ganttId, taskId);

      taskChanges && commit('updateTask', { ...taskDataOld, ...taskChanges });
    }
    const taskData = getters.getTaskByGanttId(ganttId, taskId);

    !silent && app.trigger('tasks:model:updateTask', taskData.id, taskData);
  },

  // changeResources
  async changeAssign({
    commit, getters, state, dispatch,
  }, payload) {
    let {
      changedResources, taskId, ganttId, taskChanges,
    } = payload;
    const task = getters.getTaskByGanttId(ganttId, taskId);

    if (!taskChanges) {
      const newTimeTypeTaskResources = helpers.prepareNewTimeTypeTaskResources(task, changedResources);

      taskChanges = gantt.estimationCalculator.calculateTaskChangesAfterAssign(task, newTimeTypeTaskResources);
    }

    let noHistoryFlag = 1;
    const mappedData = _.map(changedResources, item => {
      const obj = {
        user_id: item.userId,
        resource_id: item.resource_id || item.id,
        action: item.action,
        customEstimation: item.customEstimation,
      };

      if (item.value !== undefined) {
        obj.value = parseFloat(item.value);
      }

      if (item.action !== 'update') {
        noHistoryFlag = 0;
      }

      return obj;
    });

    const data = {
      taskResources: mappedData,
      task_id: taskId,
      gantt_id: ganttId,
      task_name: task.text,
      no_history_flag: noHistoryFlag,
    };

    if (taskChanges) {
      data.taskChanges = taskChanges;
    }

    const response = await api.put('/task/resources', data);

    gantt.callEvent('taskEditor:task:changed', [task]);

    if (response.data.error) {
      webix.message({ type: 'error', text: __(response.data.error.locale) });

      return;
    }

    dispatch('updateTasksResourcesByGanttId', { resourcesToTasks: response.data.resourcesToTasks, ganttId });
    dispatch('updateTask', { taskChanges, taskId, ganttId });

    if (taskChanges && taskChanges.duration) {
      app.trigger('tasksModel:onStoreUpdate', { ganttId, taskId, autoschedule: true });
    }
  },

  // archiveTasks()
  archiveTasks({
    commit, state, dispatch, getters,
  }, payload) {
    commit('archiveTasks', payload);
    dispatch('updateProjectTasksData', getters.getItem(payload.ganttId));
  },

  addTask({
    commit, state, dispatch, getters,
  }, payload) {
    commit('addTask', payload);
    app.trigger('tasksModel:onStoreUpdate', { ganttId: payload.gantt_id, newTaskId: payload.id });
  },

  // addTasksAfterCreateNewProject()
  addTasksAfterCreateNewProject({
    commit, state, dispatch, getters,
  }, payload) {
    const { ganttId, projectTasksData } = payload;
    const oldProjectTasksData = getters.getItem(ganttId);

    if (oldProjectTasksData) {
      dispatch('updateProjectTasksData', projectTasksData);
    } else {
      commit('addProjectTasksData', helpers.prepareProjectTasksData(projectTasksData));
    }
  },

  // updateLinks()
  async updateLinks({
    commit, state, dispatch, getters,
  }, payload) {
    commit('updateLinks', payload);
    await api.put('/task/resources', { links });
  },

  // changeStatus()
  async changeStatus({ commit, getters, dispatch }, payload) {
    const { statusValue, taskData, silenceMode } = payload;
    const ganttId = taskData.gantt_id;

    const response = await api.put('/task/status', {
      text: taskData.text,
      task_id: taskData.id,
      option_id: +statusValue,
      gantt_id: ganttId,
    });
    const data = response.data;

    if (data.error) {
      webix.message({ type: 'warning', text: __(data.error.locale) });

      return;
    }

    if (!silenceMode) {
      if (projectsModel.getProjectDataById(ganttId).is_jira) {
        dispatch('updateCustomValues', {
          ganttId,
          taskId: taskData.id,
          newValue: statusValue,
          propertyName: 'status',
        });
      } else {
        dispatch('updateCustomValues', { dataByGanttId: data.customValues, ganttId });
      }

      app.trigger('tasksModel:onStoreUpdate', { ganttId, taskId: taskData.id });
    }
  },

  // changePriority()
  async changePriority({ commit, getters, dispatch }, payload) {
    const { priorityValue, taskData } = payload;
    const ganttId = taskData.gantt_id;

    const response = await api.put('/task/priority', {
      text: taskData.text,
      task_id: taskData.id,
      option_id: +priorityValue,
      gantt_id: ganttId,
    });
    const data = response.data;

    if (data.error) {
      webix.message({ type: 'warning', text: __(data.error.locale) });

      return;
    }

    if (projectsModel.getProjectDataById(ganttId).is_jira) {
      dispatch('updateCustomValues', {
        ganttId,
        taskId: taskData.id,
        newValue: priorityValue,
        propertyName: 'priority',
      });
    } else {
      dispatch('updateCustomValues', { dataByGanttId: data.customValues, ganttId });
    }

    app.trigger('tasksModel:onStoreUpdate', { ganttId, taskId: taskData.id });
  },
  async changeEstimationOnTaskDay({ commit, getters, dispatch }, payload) {
    const response = await api.put('/task/estimationWorkload', payload);
    const data = response.data;

    if (data.error) {
      webix.message({ type: 'warning', text: __(data.error.locale) });

      return;
    }

    dispatch('updateTasksResourcesByGanttId', { resourcesToTasks: data.resourcesToTasks, ganttId: data.ganttId });
    dispatch('updateTask', data);
  },
  async updateTasksBudgetAndCost({ dispatch, commit }, { status, isDeleteTask, projectId, projectTasks = [] }) {
    const tasks = projectTasks
      .filter(item => item.type === 'task' || item.type === 'milestone')
      .map((task) => ({
        id: task.id,
        total_price: status || isDeleteTask ? 0 : estimationModule.helpers.calculateCostChildTask(task),
        actual_cost: status || isDeleteTask ? 0 : estimationModule.helpers.calculateActualCostChildTask(task)
      }));
    
    await internalApi.put(`projects/${projectId}/budget`, {
      status,
      isDeleteTask,
      tasks: status || isDeleteTask ? [] : tasks
    });

    const tasksToUpdate = tasks.map((task) => ({ ...task, gantt_id: +projectId }));

    commit('updateTasks', tasksToUpdate);
    projectsModel.manualUpdateAutoBudget(projectId, status);
    app.trigger('settings:autoBudget:change', {
      autoBudgetState: status,
      tasks: tasksToUpdate
    });
  }
};
