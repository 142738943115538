import _ from "../../../libs/lodash";

const innerSettings = {
  successorsConstraintDate: {},
  predecessorsConstraintDate: {},
  projectDates: {},
};

const innerHelpers = {
  hasDirectLink: function (task, id) {
    return task.$target.length > 0 && _.includes(task.$target, id);
  },
  isMilestone: function (task) {
    return task.type === gantt.config.types.milestone;
  },
  isButton: function (taskID) {
    return gantt.getTask(taskID).type === gantt.config.types.button;
  },
  filterTasks: function (tasks) {
    return tasks.filter(i => {
      return !(innerHelpers.isButton(i.target) || innerHelpers.isButton(i.source));
    });
  },
  calculateConstraintDate: function (date, lag) {
    if (Math.sign(lag) > 0) {
      const firstDate = gantt.calculateToDuration(date, lag + 1, gantt.config.duration_unit).endDate;
      return gantt.calculateToDuration(firstDate, lag - 1, gantt.config.duration_unit).endDate;
    } else {
      return gantt.calculateToDuration(date, lag, gantt.config.duration_unit).endDate;
    }
  },
  clearInnerSettings: function () {
    innerSettings.successorsConstraintDate = {};
    innerSettings.predecessorsConstraintDate = {};
  },
  hasChanges: function () {
    return _.keys(innerSettings.successorsConstraintDate).length > 0 || _.keys(innerSettings.predecessorsConstraintDate).length > 0;
  },
  hasSameParent: function (link) {
    return link.targetParent === link.sourceParent;
  }
};

const innerHandlers = {
  checkLinks: function (id, item) {
    const parent = gantt.getTask(item.parent);

    if (gantt.isSummaryTask(parent) && gantt.calculateTaskLevel(item.parent) <= 1) {
      const children = gantt.getChildren(parent.id).filter(taskID => !innerHelpers.isButton(taskID));

      if (children.length === 0) {
        _.each(parent.$target, function (id) {
          gantt.isLinkExists(id) && gantt.deleteLink(id);
        });
        _.each(parent.$source, function (id) {
          gantt.isLinkExists(id) && gantt.deleteLink(id);
        });
      }
    }
  },
  preventLinkToEmptyProject: function (id, link) {
    const target = gantt.getTask(link.target);

    if (gantt.isSummaryTask(target)) {
      const children = gantt.getChildren(link.target).filter(taskID => !innerHelpers.isButton(taskID));

      if (children.length === 0) {
        return false;
      }
    }

    return true;
  },
  preventLinkFromEmptyProject: function (id, link) {
    const source = gantt.getTask(link.source);

    if (gantt.isSummaryTask(source)) {
      const children = gantt.getChildren(link.source).filter(taskID => !innerHelpers.isButton(taskID));

      if (children.length === 0) {
        return false;
      }
    }

    return true;
  },
  updateSuccessors: function (successor) {
    const target = gantt.getTask(successor.target);
    const source = gantt.getTask(successor.source);
    const link = gantt.getLink(successor.id);
    const originalSource = gantt.getTask(link.source);
    const originalTarget = gantt.getTask(link.target);

    if (innerHelpers.isMilestone(target) || innerHelpers.hasSameParent(successor)) {
      target.constraint_type = gantt.config.constraint_types.SNLT;
      let tmp;

      if (innerHelpers.hasDirectLink(target, source.id)) {
        tmp = source.end_date;
      } else {
        successor.lag += gantt.calculateDuration(source.end_date, originalSource.end_date, source);
        tmp = innerHelpers.calculateConstraintDate(source.end_date, successor.lag);
      }

      if (!innerSettings.successorsConstraintDate[successor.target]) {
        target.constraint_date = gantt.calculateToDuration(source.end_date, successor.lag - successor.additionalLag , gantt.config.duration_unit).endDate;
      } else {
        const tmp = gantt.calculateToDuration(source.end_date, successor.lag - successor.additionalLag, gantt.config.duration_unit).endDate;

        if (moment(innerSettings.successorsConstraintDate[successor.target]).isBefore(tmp)) {
          target.constraint_date = tmp;
        }
      }

      target.constraint_date = gantt.getClosestWorkTime({date: target.constraint_date, dir: 'future', task: target});
      innerSettings.successorsConstraintDate[successor.target] = target.constraint_date;
    } else {
      target.constraint_type = gantt.config.constraint_types.SNLT;
      if (!innerHelpers.hasDirectLink(target, source.id)) {
        successor.lag += gantt.calculateDuration(source.end_date, originalSource.end_date, source);
      }
      if (!innerSettings.successorsConstraintDate[successor.target]) {
        target.constraint_date = gantt.calculateToDuration(source.end_date, successor.lag - successor.additionalLag , gantt.config.duration_unit).endDate;
      } else {
        const tmp = gantt.calculateToDuration(source.end_date, successor.lag - successor.additionalLag, gantt.config.duration_unit).endDate;

        if (moment(innerSettings.successorsConstraintDate[successor.target]).isBefore(tmp)) {
          target.constraint_date = tmp;
        }
      }

      target.constraint_date = gantt.getClosestWorkTime({date: target.constraint_date, dir: 'future', task: target});
      innerSettings.successorsConstraintDate[successor.target] = target.constraint_date;

    }
  },
  updatePredecessors: function (parent) {
    return function (predecessor) {
      const target = gantt.getTask(predecessor.target);
      const source = gantt.getTask(predecessor.source);
      const link = gantt.getLink(predecessor.id);
      const originalSource = gantt.getTask(link.source);
      const originalTarget = gantt.getTask(link.target);


      if (innerHelpers.isMilestone(target) || innerHelpers.hasSameParent(predecessor)) {
        target.constraint_type = gantt.config.constraint_types.SNLT;
        let tmp;

        if (innerHelpers.hasDirectLink(target, source.id)) {
          tmp = source.end_date;
        } else {
          predecessor.lag += gantt.calculateDuration(source.end_date, originalSource.end_date, source);
          tmp = innerHelpers.calculateConstraintDate(source.end_date, predecessor.lag);
        }

        if (!innerSettings.predecessorsConstraintDate[predecessor.target]) {
          target.constraint_date = gantt.calculateToDuration(source.end_date, predecessor.lag - predecessor.additionalLag, gantt.config.duration_unit).endDate;
        } else {
          const tmpDate = gantt.calculateToDuration(source.end_date, predecessor.lag - predecessor.additionalLag, gantt.config.duration_unit).endDate;

          if (moment(innerSettings.predecessorsConstraintDate[predecessor.target]).isAfter(tmpDate)) {
            target.constraint_date = tmpDate;
          }
        }

        target.constraint_date = gantt.getClosestWorkTime({date: target.constraint_date, dir: 'future', task: target});
        innerSettings.predecessorsConstraintDate[predecessor.target] = target.constraint_date;
      } else {
        target.constraint_type = gantt.config.constraint_types.SNLT;
        if (!innerHelpers.hasDirectLink(target, source.id)) {
          predecessor.lag += gantt.calculateDuration(source.end_date, originalSource.end_date, source);
        }
        if (!innerSettings.predecessorsConstraintDate[predecessor.target]) {
          target.constraint_date = gantt.calculateToDuration(source.end_date, predecessor.lag - predecessor.additionalLag, gantt.config.duration_unit).endDate;
        } else {
          const tmpDate = gantt.calculateToDuration(source.end_date, predecessor.lag - predecessor.additionalLag, gantt.config.duration_unit).endDate;

          if (moment(innerSettings.predecessorsConstraintDate[predecessor.target]).isAfter(tmpDate)) {
            target.constraint_date = tmpDate;
          }
        }

        target.constraint_date = gantt.getClosestWorkTime({date: target.constraint_date, dir: 'future', task: target});
        innerSettings.predecessorsConstraintDate[predecessor.target] = target.constraint_date;
      }
    };
  },
  beforeAutoSchedule: function (task) {
    innerHelpers.clearInnerSettings();
    const isTask = task.$source.length && gantt.getTask(gantt.getLink(task.$source[task.$source.length - 1]).target);
    const hasLinkToProject = task.$source.length && isTask && gantt.getTask(gantt.getLink(task.$source[task.$source.length - 1]).target).type === gantt.config.types.project;

    if (task.$target.length && task.type !== gantt.config.types.project && !hasLinkToProject) {
      task.constraint_type = gantt.config.constraint_types.ASAP;
      task.constraint_date = null;
    } else {
      const parent = task.parent ? gantt.getTask(task.parent) : null;
      const predecessors = innerHelpers.filterTasks(gantt._getPredecessors(task, false));
      const successors = innerHelpers.filterTasks(gantt._getSuccessors(task, false));

      if (parent) {
        _.some(predecessors, innerHandlers.updatePredecessors(parent));
      }

      _.some(successors, innerHandlers.updateSuccessors);

      if (!innerHelpers.hasChanges()) {
        return false;
      }

       // console.log('predecessorsConstraintDate', _.clone(innerSettings.predecessorsConstraintDate));
       // console.log('successorsConstraintDate', _.clone(innerSettings.successorsConstraintDate));

      innerHelpers.clearInnerSettings();
    }


    return true;
  },
  saveDatesOfProjectTasks: function(){
    innerSettings.projectDates = {};
    gantt.eachTask(function(task){
      if(task.type === "project"){
        innerSettings.projectDates[task.id] = {start_date: new Date(task.start_date), end_date: new Date(task.end_date)};
      }
    });
  },
  sendTasksUpdate: function(id, updatedTasksIDs){
    gantt.eachTask(function(task){
      if(task.type === "project" && innerSettings.projectDates[task.id]){
        const oldValue = innerSettings.projectDates[task.id];
        if(oldValue.start_date.valueOf() !== task.start_date.valueOf() || oldValue.end_date.valueOf() !== task.end_date.valueOf()){
          // нашли проект который поменялся
          updatedTasksIDs.push(task.id);
        }
      }
    });


    if (!_.isEmpty(updatedTasksIDs)) {
      const actionHash = gantt._autoscheduling_actionHash || null;

      let updatedTasks = _.map(updatedTasksIDs, function (tsId) {
        return gantt.getTask(tsId);
      });


      _.some(updatedTasks, (task) => {
        return gantt.callEvent('checkTimelineDates', [task]);
      });

      // _.map(updatedTasksIDs, function (tsId) {
      //   let a = gantt.refreshTask(tsId);
      //   return a;
      // });

      gantt.updateTasks(
        updatedTasks,
        "autoscheduling",
        actionHash
      );
    }
  },
};

gantt.attachEvent("onBeforeLinkAdd", innerHandlers.preventLinkToEmptyProject);
gantt.attachEvent("onBeforeLinkUpdate", innerHandlers.preventLinkToEmptyProject);
gantt.attachEvent("onBeforeLinkAdd", innerHandlers.preventLinkFromEmptyProject);
gantt.attachEvent("onBeforeLinkUpdate", innerHandlers.preventLinkFromEmptyProject);
gantt.attachEvent("onAfterTaskDelete", innerHandlers.checkLinks);

gantt.attachEvent("onBeforeAutoSchedule", innerHandlers.saveDatesOfProjectTasks);
gantt.attachEvent("onAfterAutoSchedule", innerHandlers.sendTasksUpdate);

const outerObject = {
  handlers: {
    onBeforeAutoSchedule: innerHandlers.beforeAutoSchedule
  },
};

export default outerObject;