import app from '../app';
import _ from '../libs/lodash';
import filterModel from './filters'

const jira = new webix.DataCollection();

jira.siteOrigin = GT.siteConfig;

jira.commonFields = {
  apiUrl: 'https://stage-jiraapi.ganttpro.com',
  appName: 'GanttPRO',
};

jira.getJiraProjects = function () {
  return webix.ajax()
    .get(`/api/jira/projects?team_id=${user.team.id}`)
    .then(responseRawData => {
      jira.data.callEvent('getJiraProjects', [responseRawData.json()]);
    });
};

jira.getJiraDataForFiltersByProjectKey = function (projectKey) {
  return webix.ajax()
    .get(`/api/jira/import/filters/${projectKey}?team_id=${user.team.id}`);
};

jira.importProjectJira = function (projectData) {
  return webix.ajax()
    .post(`/api/jira/import/${projectData.id}?team_id=${user.team.id}`, projectData);
};

jira.authorizeStepOne = function (autorizeData) {
  webix.ajax()
    .post(`/api/jira/authorize?team_id=${user.team.id}`, autorizeData)
    .then(responseRawData => {
      jira.data.callEvent('getAuthorizeUrl', [responseRawData.json()]);
    })
    .fail(error => {
      var error = JSON.parse(error.responseText);

      webix.message({ type: 'warning', text: error.msg });

      jira.data.callEvent('errorAuthorizeUrl', [error]);
    });
};

jira.getJiraClientData = function () {
  return webix.ajax()
    .get(`/api/jira/client?team_id=${user.team.id}`)
    .then(responseRawData => {
      const parsedData = responseRawData.json();
      jira.commonFields = _.assign(jira.commonFields, parsedData);

      jira.data.callEvent('jiraClientData', [parsedData]);

      return parsedData;
    });
};

jira.jiraReconnect = async function () {
  await filterModel.removeJiraOptFromFilters(); 

  return webix.ajax()
    .del('/api/jira/users/', { teamId: user.team.id}) 
    .then(responseRawData => {
      app.trigger('filter:refresh:gantt');
    });
};

app.socket.on('jiraIntegration:success', () => {
  user.team.is_jira = 1;
  jira.data.callEvent('jiraIntegration:success');
});

app.socket.on('jiraIntegration:denied', () => {
  jira.data.callEvent('jiraIntegration:denied');
});

export default jira;
