import plansModel from '../../../../../models/plans';
import app from '../../../../../app';

const globalPopupStore = {
  namespaced: true,
  state: {
    isShowGlobalPopup: false,
    config: {},
  },
  getters: {
    isShowGlobalPopup: state => state.isShowGlobalPopup,
    config: state => state.config,
  },
  mutations: {
    isShowGlobalPopup(state, flag) {
      state.isShowGlobalPopup = flag;
    },
    setConfig(state, config) {
      state.config = config;
    },
  },
  actions: {
    show({ commit }, config) {
      //[upgrage] - upgradePlan
      // debugger;
      if ((config.type === 'upgradePlan' || config.type === 'paymentPopup')) {
        if (plansModel.isCanNotBeUpdatedWithoutSales()) {
          app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

          return;
        }
      }

      commit('isShowGlobalPopup', true);
      commit('setConfig', config);
    },
    async close({ commit }) {
      commit('isShowGlobalPopup', false);
      commit('setConfig', {});
    },
    confirm({ dispatch, state }) {
      if (state.config.confirm) {
        state.config.confirm(state.config.data);
        dispatch('close');

        return;
      }
      dispatch('close');
    },
  },
};

export default globalPopupStore;
