import globalStore from "$$store/main";

const MENTION_CLASS = 'mention';

const replaceMentionsWithIds = content => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const mentions = doc.querySelectorAll(`span.${MENTION_CLASS}`);

  mentions.forEach(mention => {
    const id = mention.dataset.id;

    mention.outerHTML = `[~${id}]`;
  });

  return doc.body.innerHTML;
};

const replaceIdsWithMentionsHtml = content => {
  const replaceUserIDPattern = /\[\~([^\]]*)\]/gim;

  return content.replace(replaceUserIDPattern, (needle, id) => {
    const member = globalStore.getters['resourcesModel/getResourceByUserId'](+id);

    if (!member) return id;

    const mentionSpan = document.createElement('span');

    mentionSpan.classList.add(MENTION_CLASS);
    mentionSpan.dataset.id = member.userId;
    mentionSpan.dataset.value = member.name;
    mentionSpan.dataset.denotationChar = '@';
    mentionSpan.textContent = `@${member.name}`;

    const mentionSpanHtml = mentionSpan.outerHTML;

    mentionSpan.remove();

    return mentionSpanHtml;
  });
};

export {
  replaceMentionsWithIds,
  replaceIdsWithMentionsHtml,
};
