import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const instance = new Vuex.Store({
  state: {
    isShow: false,
    selected: 1,
    images: [
    ],
  },
  getters: {
    isShow: state => state.isShow,
    images: state => state.images,
    selected: state => {
      let selectedIndex = 0;

      _.some(state.images, (img, index) => {
        if (img.selected) {
          selectedIndex = index;

          return true;
        }

        return false;
      });

      return selectedIndex;
    },
  },
  mutations: {
    setImages(state, images) {
      Vue.set(state, 'images', images);
    },
    showGallery(state) {
      Vue.set(state, 'isShow', true);
    },
    hideGallery(state) {
      Vue.set(state, 'isShow', false);
    },
  },
});

export default instance;
