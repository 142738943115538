<template>
  <div
    :id="componentKey"
    ref="inputField"
    :key="componentKey"
    :class="{
      [$style.active]: isHighlight,
      [$style.wrap]: true,
      [$style.open]: isOpen && !disable,
      [$style.disable]: disable,
      [$style.outline_none]: outline === 'none',
      [$style.outline_hover]: outline === 'hover',
      [$style.outline_always]: outline === 'always',
      [$style.pointer]: true,
    }"
    :style="{
      'font-size': view.fontSize + 'px',
      'height': view.itemHeight + 'px'
    }"
    @click="inputClick"
  >
    <div
      :class="{ [$style.select_inner]: true }"
    >
      <span
        v-if="selectedDate"
        :class="$style.main_txt"
      > {{ stringSelected }} </span>
      <span
        v-else
        :class="$style.placeholder_txt"
      > {{ placeholder }} </span>

      <div
        :class="[$style.d_flex, $style.ai_center]"
      >
        <sprite
          v-show="isShowResetBtn"
          :name="'close-2'"
          :type="'bold'"
          :size="16"
          :class="[ $style.mr_8, 'vgp_select_reset_btn']"
          @click="onClickResetBtn"
        />

        <sprite
          :name="'calendar'"
          :type="'regular'"
          :size="24"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dateHelper from '../../../../helpers/dateFormats';
import sprite from '../VueIcon/svgSprite.vue';

export default {
  name: 'FromToDateInput',
  components: { sprite },
  props: {
    placeholder: { type: String, required: false, default: 'placeholder' },
    value: { type: Object, required: false, default: null },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator(value) {
        return ['sm', 'lg'].includes(value);
      },
    },
    showResetBtn: { type: Boolean, required: false, default: true },
    disable: { type: Boolean, required: false, default: false },
    highlightActiveState: { type: Boolean, required: false, default: false },
    outline: {
      type: String,
      required: false,
      default: 'always',
      validator(value) {
        return ['none', 'always', 'hover'].includes(value);
      },
    },
  },

  data() {
    return {
      calendarIconUrl: 'https://cdn.ganttpro.com/app/imgs/webix_material/calendar_filter_new.svg',
      selectedDate: '',
      isShowCalendar: false,
      calendarHeight: 250,
      redraw: 0,
    };
  },

  computed: {
    isShowResetBtn() {
      return this.showResetBtn && this.selectedDate;
    },

    componentKey() {
      const min = 10;
      const max = 100000;
      const key = Math.floor(Math.random() * (max - min)) + min;

      return `drop-select-date-${new Date().getTime()}-${key}`;
    },

    view() {
      switch (this.size) {
      case 'sm': return { fontSize: 14, itemHeight: 32 };
      case 'lg': return { fontSize: 16, itemHeight: 40 };
      default: return { fontSize: 14, itemHeight: 32 };
      }
    },

    isHighlight() {
      const highlightActive = this.highlightActiveState && this.selectedDate;

      return !this.disable && (this.isShowCalendar || highlightActive);
    },

    stringSelected() {
      return (this.selectedDate) ? this.format(this.selectedDate) : '';
    },
  },

  watch: {
    isShowCalendar(val) {
      if (val) {
        this.openDatePicker();
      } else {
        this.closeDatePicker();
      }
    },
    value(val) {
      if (!val && !this.selectedDate) { return; }

      const newDate = new Date(val || 0).getTime();
      const oldDate = new Date(this.selectedDate || 0).getTime();

      if (newDate === oldDate) { return; }

      this.selectedDate = val;
    },

    disable(val) {
      val && this.closeDatePicker();
    },

  },

  mounted() {
    this.addListheners();
    const component = this;

    this.setIncomingValue();

    this.popupId = `popupDatePicker_${this.componentKey}`;
    this.calendarId = `datepickerRange_${this.componentKey}`;

    const datepickerConfig = webix.ui({
      view: 'popupWithoutPointCustom',
      id: component.popupId,
      css: 'customDateRangePicker suggest_popup customDateRangeSuggest filter-datepicker',
      hidden: true,
      body: {
        view: 'calendar',
        css: 'calendar webix_calendar',
        id: component.calendarId,
        monthSelect: true,
        navigation: true,
        cacheData: {},
        weekHeader: true,
        borderless: true,
        calendar: {
          height: 300,
          cellHeight: 24,
        },
        value: component.selectedDate,
        timepicker: false,
        icons: [{
          template() {
            const today = webix.i18n.calendar.today;

            return `<span role='button' tabindex='0' class='webix_cal_icon_today webix_cal_icon' >${today}</span>`;
          },
          on_click: {
            webix_cal_icon_today() {
              this.setValue(new Date());
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
          },
        }],
        on: {
          onChange(newValue) {
            component.setSelectedDate(newValue);
          },
        },
      },
      on: {
        onHide() {
          component.isShowCalendar = false;
        },
      },
    });

    datepickerConfig.$scope = this;
    webix.ui(datepickerConfig);
  },

  beforeDestroy() {
    this.removeListheners();
    webix.$$(this.popupId).destructor();
  },

  methods: {
    onScroll() {
      this.closeDatePicker();
    },

    addListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.onScroll);
      });
    },

    removeListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.removeEventListener('scroll', this.onScroll);
      });
    },

    onClickResetBtn() {
      this.selectedDate = '';
      $$(this.calendarId).setValue(this.selectedDate);
      this.emitSelectedValue();
    },

    setSelectedDate(values) {
      if (!values) {
        this.selectedDate = '';

        return;
      }

      if ((new Date(values[0])).getTime() === (new Date(this.selectedDate)).getTime()) { return; }

      this.selectedDate = values[0];
      this.isShowCalendar = false;
      this.emitSelectedValue();
    },

    emitSelectedValue() {
      this.$emit('change', this.selectedDate);
    },

    setIncomingValue() {
      if (!this.value) return;
      this.selectedDate = this.value;
    },

    format(date) {
      const dateFormat = dateHelper.getDateFormat();

      return webix.Date.dateToStr(dateFormat)(date);
    },

    inputClick(e) {
      const path = e.path ? e.path : e.composedPath(e.target);

      const isResetBtn = path.find(elem => elem.classList && elem.classList.contains('vgp_select_reset_btn'));

      if (this.disable || isResetBtn) { return; }

      this.isShowCalendar = !this.isShowCalendar;
    },

    openDatePicker() {
      if (this.disable) return;

      this.calcCalendarPosition();
      $$(this.calendarId).setValue(this.selectedDate);
    },

    calcCalendarPosition() {
      const clientHeight = document.documentElement.clientHeight;
      const inputCoord = this.$el.getBoundingClientRect();

      const isTop = inputCoord.top + 2 > this.calendarHeight;
      const isBottom = clientHeight - inputCoord.bottom + 2 > this.calendarHeight;

      if (isBottom) {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'bottom' });

        return;
      }

      if (isTop) {
        $$(this.popupId).show(this.$refs.inputField, { pos: 'top' });

        return;
      }

      $$(this.popupId).show(this.$refs.inputField, { pos: 'bottom' });
    },

    closeDatePicker() {
      $$(this.popupId).hide();
    },
  },
};
</script>

<style module src="./dropSelect.less" lang="less"></style>
