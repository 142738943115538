<template>
  <div
    class="cell-checkbox"
    :class="{'not_allowed': !editable}"
  >
    <button
      class="btn-checkbox"
      :class="{'chacked': isChacked, 'discolor': discolor, ['box-' + theme]: true, 'not_allowed': !editable}"
      @click="clickOnBtn"
    />
  </div>
</template>

<script>

export default {
  name: 'CheckBoxCell',
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    value: { type: [String, Boolean], required: true },
    editable: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    discolor: { type: Boolean, required: false, default: false },
    crossout: { type: Boolean, required: false, default: false },
    theme: {
      type: String,
      required: false,
      default: 'red',
      validator(value) {
        return ['red', 'green', 'blue'].includes(value);
      },
    },
  },
  data() {
    return {
      isChacked: false,
    };
  },

  watch: {
    value(val) {
      this.updateCurrentValue();
    },
  },
  mounted() {
    this.updateCurrentValue();
  },

  methods: {
    clickOnBtn(e) {
      if (this.disabled || !this.editable) {
        e.preventDefault();

        return;
      }
      this.$emit('change', !this.isChacked);
    },
    updateCurrentValue() {
      let val = this.value;

      if (typeof val === 'boolean') {
        this.isChacked = val;

        return;
      }

      const isNumber = !Number.isNaN(Number(val));

      val = isNumber ? +val : val;
      this.isChacked = !!val;
    },
  },
};
</script>

<style scoped lang='less'>
.cell-checkbox{
  width: 100%;
  height: 100%;
	display: flex;
	align-items: center;

  &.not_allowed{
    cursor: not-allowed !important;
  }
}
.btn-checkbox{
	position: relative;
	display: block;
	width: 17px;
	height: 17px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #9e9e9e;
	border-radius: 2px;
	box-sizing: border-box;
	margin: 0 auto;
	cursor: pointer;

  &.not_allowed{
    cursor: not-allowed !important;
  }

	&:before {
		content: '';
		width: 12px;
		height: 9px;
		background: url(https://cdn.ganttpro.com/app/imgs/white_check.svg) center no-repeat;
		position: absolute;
		top: 3px;
		left: 2px;
		display: none;
	}

	&.chacked {
    &.box-red{
      background: #CE2525;
		  border-color: #CE2525;
    }

    &.box-green{
      background: #00564c;
		  border-color: #00564c;
    }

    &.box-blue{
      background: #1565C0;
		  border-color: #1565C0;
    }

		&:before{
			display: block;
		}
	}
}
.discolor{
  opacity: 0.5;
}

.crossout{
  text-decoration: line-through;
}
</style>
