<template>
  <div>
    <video
      :autoplay="autoplay"
      :loop="loop"
      muted
      :poster="posterUrl"
      @contextmenu.prevent
    >
      <source
        :src="videoUrl"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script>

export default {
  name: 'VideoComponent',
  props: {
    posterUrl: { type: String, required: true, default: '' },
    videoUrl: { type: String, required: true, default: '' },
    autoplay: { type: Boolean, required: false, default: true },
    loop: { type: Boolean, required: false, default: true },
  }
};
</script>
