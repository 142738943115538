var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "descriptionContainer",
      class: _vm.$style["task-settings-description"],
    },
    [
      _c("vgp-quill-editor", {
        ref: "descriptionEditor",
        class: [
          _vm.isFieldDisabled("note")
            ? _vm.$style["task-settings-description-quill_disabled"]
            : "",
          !_vm.quillEditorActive
            ? _vm.$style["task-settings-description-quill_inactive"]
            : "",
          _vm.showExpandButton && !_vm.descriptionExpanded
            ? _vm.$style["task-settings-description-quill_collapsed"]
            : "",
        ],
        attrs: {
          value: _vm.taskData.note,
          "upload-image-callback": _vm.addImageToTask,
          disabled: _vm.isFieldDisabled("note"),
          placeholder: _vm.locales("settings_taskDescription"),
          "font-size": 16,
          "focus-on-init": false,
          "with-buttons": false,
          active: _vm.quillEditorActive,
          "scrolling-container": ".task-settings-body-scroll-container",
        },
        on: {
          blur: _vm.saveDescription,
          focus: _vm.activateEditor,
          "text-change": _vm.handleOnTextChange,
        },
      }),
      _vm._v(" "),
      _c("vgp-icon-button", {
        class: [
          _vm.showExpandButton && !_vm.quillEditorActive
            ? ""
            : _vm.$style["collapse-button_hidden"],
        ],
        attrs: {
          size: "middle",
          bold: "",
          "icon-side": "right",
          icon: _vm.descriptionExpanded
            ? { name: "arrow-top" }
            : { name: "arrow-down" },
          label: _vm.descriptionExpanded
            ? _vm.locales("comment_list_collapse_task_comments")
            : _vm.locales("comment_list_expand_task_comments"),
          colors: _vm.expandBtnColors,
          sizes: { labelFontSize: "14px", lineHeight: "24px" },
        },
        on: { onClick: _vm.toggleDescriptionVisibility },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }