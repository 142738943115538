var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-checkbox", on: { click: _vm.checkedHandler } },
    [
      _c("button", {
        staticClass: "btn-checkbox",
        class: {
          chacked: _vm.shouldBeChecked && !_vm.block,
          disabled: _vm.block,
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }