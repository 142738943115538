import rights from '../../components/rights';
import globalStore from '$$store/main';
import projects from '../../models/projects';

const static4 = [
  'time_tracking',
  'status',
  'progress',
  'attachments',
];

const costFields = [
  'total_price',
  'actual_cost'
];

export default {
  isEditableCell(ganttId, column) {
    if (globalStore.getters['headerView/isBulkChange']) return true;
    if (column.custom) {
      return rights.project.hasRight(ganttId, 'custom_field_edit');
    }

    if (static4.includes(column.property)) {
      return rights.project.hasRight(ganttId, 'static_fields_4_only') || rights.project.hasRight(ganttId, 'static_fields');
    }

    if (costFields.includes(column.property) && rights.project.hasRight(ganttId, 'static_fields')) {
      const projectConfig = projects.getProjectConfig(ganttId);

      if (projectConfig) return !projectConfig.auto_budget;
    }

    return rights.project.hasRight(ganttId, 'static_fields');
  },
};
