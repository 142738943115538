<template>
  <div :class="$.skeleton">
    <div :class="$.head">
      <div
        v-for="i in table.head"
        :key="i"
        :class="$.week_day"
      >
        {{ weekDaysLocales[i - 1] }}
      </div>
    </div>
    <div :class="$.grid">
      <div
        v-for="(row, rowIndex) in table.grid.weekDays"
        :key="rowIndex"
        :class="$.row"
      >
        <div
          v-for="(cell, index) in row"
          :key="index"
          :class="[$.cell, weekends.includes(index) ? $.weekend : '']"
          :style="{minHeight: minHeight}"
        >
          <div :class="[$.date, cell.month !== 'current' ? $.gray : '']">
            {{ cell.day }}
          </div>
        </div>
        <div
          v-for="(event, index) in table.grid.weekEvents[rowIndex]"
          :key="index"
          :class="$.event"
          :style="{
            top: `${event.top}px`,
            gridColumnStart: startOnMonday ? event.start : event.start + 1,
            gridColumnEnd: startOnMonday ? event.end : event.end + 1
          }"
        >
          <div
            :class="$.element"
          />
          <!-- <div :class="$.blink" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WEEK_LOCALES } from '../../constants';

export default {
  data() {
    return {
      table: {
        head: 7,
        grid: {
          weekDays: [
            [
              { day: 30, month: 'prev' },
              { day: 31, month: 'prev' },
              { day: 1, month: 'current' },
              { day: 2, month: 'current' },
              { day: 3, month: 'current' },
              { day: 4, month: 'current' },
              { day: 5, month: 'current' },
            ],
            [
              { day: 6, month: 'current' },
              { day: 7, month: 'current' },
              { day: 8, month: 'current' },
              { day: 9, month: 'current' },
              { day: 10, month: 'current' },
              { day: 11, month: 'current' },
              { day: 12, month: 'current' },
            ],
            [
              { day: 13, month: 'current' },
              { day: 14, month: 'current' },
              { day: 15, month: 'current' },
              { day: 16, month: 'current' },
              { day: 17, month: 'current' },
              { day: 18, month: 'current' },
              { day: 19, month: 'current' },
            ],
            [
              { day: 20, month: 'current' },
              { day: 21, month: 'current' },
              { day: 22, month: 'current' },
              { day: 23, month: 'current' },
              { day: 24, month: 'current' },
              { day: 25, month: 'current' },
              { day: 26, month: 'current' },
            ],
            [
              { day: 27, month: 'current' },
              { day: 28, month: 'current' },
              { day: 29, month: 'current' },
              { day: 30, month: 'current' },
              { day: 31, month: 'current' },
              { day: 1, month: 'next' },
              { day: 2, month: 'next' },
            ],
          ],
          weekEvents: [
            [{ top: 30, start: 3, end: 3 }],
            [{ top: 30, start: 1, end: 1 }, { top: 30, start: 4, end: 6 }, { top: 56, start: 4, end: 6 }, { top: 82, start: 4, end: 4 }],
            [{ top: 30, start: 1, end: 5 }, { top: 56, start: 3, end: 6 }],
            [{ top: 30, start: 1, end: 5 }, { top: 30, start: 5, end: 5 }, { top: 56, start: 4, end: 4 }],
            [{ top: 30, start: 1, end: 1 }],
          ],
        },
      },
    };
  },
  computed: {
    startOnMonday() {
      return +user.settings.start_monday;
    },
    weekDaysLocales() {
      let locales;

      if (!this.startOnMonday) {
        locales = WEEK_LOCALES;
      } else {
        locales = WEEK_LOCALES.slice(1).concat(WEEK_LOCALES[0]);
      }

      return locales;
    },
    minHeight() {
      return `${((document.documentElement.clientHeight - 177) / 5) - 1}px`;
    },
    weekends() {
      let weekends = [];

      if (!this.startOnMonday) {
        weekends = [0, 6];
      } else {
        weekends = [5, 6];
      }

      return weekends;
    },
  },
};
</script>

<style module='$' src='./style.less'></style>
