<template>
  <div :class="$style.context_menu" @click.stop="emitContextMenu">
    <svg-sprite type="regular" name="more-1" :size="24" color="#B2B2B2" />
  </div>
</template>

<script>
export default {
  name: 'ContextMenuCell',
  props: ['context'],
  computed: {
    row() {
      return this.context.row;
    }
  },
  methods: {
    emitContextMenu(e) {
      this.$emit('contextMenu', e, this.row);
    }
  }
}
</script>

<style module src="./less/contextMenuCell.less"></style>