import app from '../../../app';
import timeTracking from '../../../models/timeTracking';
import { refreshParents } from './helpers';

function onTaskTimeLogDeleted(event) {
  const items = event.innerSettings.collaborationItems;
  const gantt_id = event.innerSettings.gantt_id;
  const task_id = event.innerSettings.task_id;

  timeTracking.manualUpdateItemForTask({
    task_id,
    gantt_id,
  }, items);
  app.trigger('timeTrackingModel:change', task_id);

  refreshParents(task_id);
}

export default onTaskTimeLogDeleted;
