<template>
  <core-popup
    show
    body-style="noPaddings"
    background-image="https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg"
  >
    <template #body>
      <div :class="[$style.body__wrapper]">
        <div :class="[$style.body__header]">
          <div :class="$style.logo">
            <vgp-svg-image
              :name="'logo'"
              color="#003D36"
            />
          </div>
        </div>
        <div :class="$style.body__content">
          <div :class="$style.body__content_left">
            <h1>
              {{ locales('password_restore_title') }}
            </h1>
          </div>
          <ValidationObserver
            ref="form"
            v-slot="{ handleSubmit }"
            tag="div"
            :class="$style.body__content_right"
          >
            <div :class="$style.inputs__wrapper">
              <input
                type="text"
                autocomplete="username"
                :value="form.email"
                hidden
              >
              <vgp-label-slot
                :label="locales('onboarding_password_label')"
              >
                <template #body>
                  <div :class="$style.input__wrap">
                    <tooltip
                      position="left"
                      :content="locales('pass_strength_descr')"
                    >
                      <template #body>
                        <ValidationProvider
                          v-slot="{ errors }"
                          ref="passwordValidationProvider"
                          rules="min:8"
                          :custom-messages="{ min: locales('validation_error_password_min_length') }"
                          vid="password"
                          :mode="customValidationProviderMode"
                        >
                          <vgp-input
                            ref="password"
                            autocomplete="new-password"
                            :value="form.password"
                            :is-error="!!errors[0] || isPasswordCommonError"
                            :placeholder="locales('onboarding_password_placeholder')"
                            :input-type="passwordInputType"
                            :icons-right="[getIconConfig('passwordInputType')]"
                            @input="handlePasswordInput"
                            @onFocus="handleOnFocus('password')"
                            @onBlur="passwordStrengthState.show = false"
                          />
                          <span
                            v-if="errors[0] || isPasswordCommonError"
                            :class="$style.input__error"
                          >
                            {{ errors[0] || locales('validation_error_common_password') }}
                          </span>
                        </ValidationProvider>
                      </template>
                    </tooltip>
                    <password-strength-indicator
                      v-if="passwordStrengthState.show"
                      v-bind="passwordStrengthState"
                    />
                  </div>
                </template>
              </vgp-label-slot>

              <vgp-label-slot
                :label="locales('password_restore_password_confirmation_label')"
              >
                <template #body>
                  <div :class="$style.input__wrap">
                    <ValidationProvider
                      v-slot="{ errors }"
                      ref="passwordConfirmationValidationProvider"
                      rules="confirmed:password"
                      vid="passwordConfirmation"
                      :custom-messages="{ confirmed: locales('validation_error_password_does_not_match_confirm_password') }"
                      :mode="customValidationProviderMode"
                    >
                      <vgp-input
                        ref="passwordConfirmation"
                        v-model="form.passwordConfirmation"
                        :is-error="errors[0]"
                        :placeholder="locales('password_restore_password_confirmation_placeholder')"
                        :input-type="passwordConfirmationInputType"
                        :icons-right="[getIconConfig('passwordConfirmationInputType')]"
                        @onFocus="handleOnFocus('passwordConfirmation')"
                        @onBlur="passwordStrengthState.show = false"
                      />
                      <span
                        v-if="errors[0]"
                        :class="$style.input__error"
                      >
                        {{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </div>
                </template>
              </vgp-label-slot>
            </div>
            <vgp-button
              :class="[$style.submit__button]"
              :disabled="isSubmitButtonDisabled"
              :is-loading="isFormSubmitting"
              type="green"
              :label="locales('password_restore_save_password_btn_label')"
              @onClick="handleSubmit(submit)"
            />
          </ValidationObserver>
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import { extend } from 'vee-validate';
import { min, confirmed } from 'vee-validate/dist/rules';
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '../common/VueComponents/label/vgpLabelSlot.vue';
import VgpInput from '../common/VueComponents/vInput/vgpInput.vue';
import Tooltip from '../common/VueComponents/tooltips/tooltip.vue';
import profileModel from '../../models/profile';
import constants from '../../helpers/constants';
import passwordMixin from '../../mixins/password';
import PasswordStrengthIndicator from '../common/VueComponents/password/PasswordStrengthIndicator.vue';
import vgpSvgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';

extend('min', min);
extend('confirmed', confirmed);

export default {
  name: 'PasswordRestorePage',
  components: {
    PasswordStrengthIndicator,
    CorePopup,
    VgpLabelSlot,
    VgpInput,
    Tooltip,
    vgpSvgImage,
  },
  mixins: [passwordMixin],
  data() {
    return {
      locales: __,
      form: {
        password: '',
        passwordConfirmation: '',
        email: window.user.email,
      },
      isFormSubmitting: false,
      passwordInputType: 'password',
      passwordConfirmationInputType: 'password',
    };
  },
  computed: {
    isSubmitButtonDisabled() {
      const invalidPasswordLength = this.form.password.length < constants.MIN_PASSWORD_LENGTH;
      const confirmationPasswordDoesNotMatch = this.form.password !== this.form.passwordConfirmation;
      const weakPasswordStrength = this.passwordStrengthState.strength <= 1;

      return [
        invalidPasswordLength,
        confirmationPasswordDoesNotMatch,
        weakPasswordStrength,
        this.isPasswordCommonError,
      ].filter(Boolean).length;
    },
  },
  watch: {
    'form.password': function (value) {
      if (value === '') {
        this.disableGoogleSuggestions('password');
      }
      this.setPasswordStrengthState(value);
    },
    'form.passwordConfirmation': function (value) {
      if (value === '') {
        this.disableGoogleSuggestions('passwordConfirmation');
      }
    },
  },
  methods: {
    handlePasswordInput(value) {
      this.form.password = value;
      this.debounceCheckPasswordOnCommon(value, 500);
    },
    customValidationProviderMode() {
      return {
        on: ['onBlur'],
      };
    },
    submit() {
      if (this.isSubmitButtonDisabled || this.isFormSubmitting) {
        return;
      }

      this.isFormSubmitting = true;
      profileModel.updateProfile({
        password: this.form.password,
        token: true,
      })
        .then(async () => {
          user.isPasswordRestore = false;
          const onboardingActivitySettings = this.$store.getters['user/getActivitySettings']('onboarding');

          if (onboardingActivitySettings && !onboardingActivitySettings.isFinished) {
            await this.$store.dispatch(
              'user/updateActivitySettings',
              { activityName: constants.USER_ACTIVITIES.ONBOARDING, settings: { isFinished: true } },
            );
          }
          this.$router.push({ name: 'app' });
        })
        .catch(error => {
          if (error === 'COMMON_PASSWORD') {
            this.isPasswordCommonError = true;
          }
        })
        .finally(() => {
          this.isFormSubmitting = false;
        });
    },
    handleOnFocus(fieldName) {
      this.disableGoogleSuggestions(fieldName);
      if (fieldName === 'password' && this.form.password && !this.isPasswordCommonError) {
        this.passwordStrengthState.show = true;
      }
      const validationProviderRefName = `${fieldName}ValidationProvider`;

      this.$refs[validationProviderRefName].reset();
    },
    disableGoogleSuggestions(fieldName) {
      this.$refs[fieldName].$refs.input_field_icons.setAttribute('readonly', '');
      setTimeout(() => {
        this.$refs[fieldName].$refs.input_field_icons.removeAttribute('readonly');
      }, 0);
    },
    getIconConfig(fieldInputTypeProperty) {
      if (this[fieldInputTypeProperty] === 'password') {
        return {
          name: 'visible',
          handler: () => this.$set(this, fieldInputTypeProperty, 'text'),
        };
      }

      return {
        name: 'hiden',
        handler: () => this.$set(this, fieldInputTypeProperty, 'password'),
      };
    },
  },
};
</script>

<style lang="less" module>

.logo {
  width: 120px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body__header {
  display: flex;
}

.body__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 33px 82px 56px 40px;
  width: 840px;
  height: 530px;
}

.body__header {
  margin-bottom: 67px;
}

.body__content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  &_left, &_right {
    max-width: 364px;
  }

  &_left h1 {
    font-family: Lato-Regular, sans-serif;
    font-size: 30px;
    line-height: 36px;
    color: #191919;
  }

  &_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.inputs__wrapper {
  display: flex;
  flex-direction: column;
  width: 280px;
  gap: 24px;
}

.submit__button {
  width: 280px;
}

.password__hint {
  display: flex;
  gap: 6px;
  position: absolute;
  font-family: Lato-Regular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin-top: 7px;
}

.input {
  &__wrap {
    position: relative;
    :global(.tooltip_label) {
      width: 100%;
    }
  }

  &__error {
    position: absolute;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #DD3636;
    margin-top: 4px;
  }
}

</style>
