var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.zoom_slider }, [
    _c("div", { class: _vm.$style.wrap }, [
      _c("label", { class: _vm.$style.label }, [
        _c(
          "div",
          { class: _vm.$style.dots },
          _vm._l(_vm.dots, function (dot) {
            return _c("div", { key: dot, class: _vm.$style.dot })
          }),
          0
        ),
        _vm._v(" "),
        _c("input", {
          class: _vm.$style.slider,
          attrs: { type: "range", step: "1", min: _vm.min, max: "5" },
          domProps: { value: _vm.value },
          on: { change: _vm.onChange },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.title }, [_vm._v(_vm._s(_vm.title))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }