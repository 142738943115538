var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: _vm.$.integration_settings_window }, [
        _c(
          "div",
          { class: _vm.$.settings_header },
          [
            _c("div", { class: _vm.$.left_side }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("webix-ui", { attrs: { config: _vm.icon } })],
                1
              ),
              _vm._v(" "),
              _c("div", {
                class: _vm.$.settings_header_logo,
                domProps: {
                  innerHTML: _vm._s(
                    _vm.locale(
                      _vm.integrationName + "_calendar_integration_logo"
                    )
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("closer", { attrs: { "on-close": _vm.closeWindow, size: 30 } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { class: _vm.$.settings_body }, [
          _c("div", { class: _vm.$.container_description }, [
            _c("div", { class: _vm.$.main_title }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.locale(
                      _vm.integrationName + "_integration_settings_page_title"
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$.description },
              [
                _c("div", { class: _vm.$.text }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.locale(
                          _vm.integrationName +
                            "_integration_settings_page_description"
                        )
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("vgp-button", {
                  attrs: {
                    label: _vm.locale("integrations_connect_btn"),
                    "icon-side": "left",
                    type: "primary",
                    width: "101px",
                    small: "",
                  },
                  on: { onClick: _vm.createNewLink },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$.settings_table }, [
            _c("div", { class: _vm.$.settings_table_header }, [
              _c(
                "div",
                { class: [_vm.$.project_title, _vm.$.project_title_text] },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.locale("integration_page_table_header_project")
                      ) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.$.sync_option_title,
                    _vm.$.sync_option_title_text,
                  ],
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.locale("integration_page_table_header_sync_option")
                      ) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { class: [_vm.$.link_title_header] }, [
                _c("div", { class: _vm.$.link_title_block }, [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.locale("integration_page_table_header_link")
                        ) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.$.link_title_header_icon] },
                    [
                      _c(
                        "tippy",
                        {
                          attrs: {
                            interactive: true,
                            "animate-fill": false,
                            placement: "top",
                            trigger: "mouseenter",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "trigger",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { class: _vm.$.link_title_icon },
                                    [
                                      _c("svg-sprite", {
                                        attrs: {
                                          color: "#B2B2B2",
                                          name: "info",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.locale(
                                    _vm.integrationName + "_instructions_info"
                                  )
                                ) +
                                "\n                    "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.locale(
                                    _vm.integrationName +
                                      "_integration_learn_more"
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.locale(
                                      _vm.integrationName +
                                        "_integration_learn_more_info"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.links.length
              ? _c(
                  "div",
                  {
                    class: [_vm.$.connections, "gantt_scroll"],
                    style: { height: _vm.calculatedHeight + "px" },
                  },
                  _vm._l(_vm.links, function (link, index) {
                    return _c(
                      "div",
                      { key: index, class: _vm.$.connection },
                      [
                        _c("tooltip", {
                          class: _vm.$.project_title,
                          attrs: {
                            content: _vm.projectName(link),
                            hide: _vm.setProjectFitInDiv(_vm.projectName(link)),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { class: _vm.$.project_title_text },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              link.connectionType ===
                                                "all_tasks"
                                                ? _vm.locale("my_tasks_label")
                                                : link.projects[0].name
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("tooltip", {
                          class: _vm.$.sync_option_title,
                          attrs: {
                            content: _vm.locale(
                              "option_with_milestone_tooltip"
                            ),
                            hide:
                              link.connectionType !==
                              "assigned_to_me_and_milestones",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    link.connectionType !== "all_tasks"
                                      ? _c(
                                          "div",
                                          {
                                            class: _vm.$.sync_option_title_text,
                                          },
                                          [
                                            _vm.syncOptions[link.connectionType]
                                              .icon
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.$
                                                        .sync_option_title_icon,
                                                  },
                                                  [
                                                    _c("svg-sprite", {
                                                      attrs: {
                                                        color: "#B2B2B2",
                                                        name: _vm.syncOptions[
                                                          link.connectionType
                                                        ].icon.name,
                                                        size: _vm.syncOptions[
                                                          link.connectionType
                                                        ].icon.size,
                                                        type: _vm.syncOptions[
                                                          link.connectionType
                                                        ].icon.type,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n\n                  " +
                                                _vm._s(
                                                  _vm.syncOptions[
                                                    link.connectionType
                                                  ].name
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.link_title_input },
                          [
                            _c("vgp-input", {
                              staticClass: "input-area",
                              class: _vm.$.link_title_input_text,
                              attrs: {
                                value: "" + _vm.generatedLink + link.hash,
                                "select-by-focus": true,
                                readonly: true,
                                border: false,
                              },
                              on: {
                                onClick: function ($event) {
                                  return _vm.copyGeneratedLink(link)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.$.integration_icon_item,
                                  _vm.$.integration_icon_item_link,
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.copyGeneratedLink(link)
                                  },
                                },
                              },
                              [
                                _c("svg-sprite", {
                                  attrs: { name: "link-1", color: "#1565C0" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { class: _vm.$.setting_title }, [
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.$.integration_icon_item,
                                    _vm.$.setting_title_icon,
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSettings(link)
                                    },
                                  },
                                },
                                [
                                  _c("svg-sprite", {
                                    attrs: {
                                      name: "settings",
                                      color: "#999999",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.$.integration_icon_item,
                                    _vm.$.setting_title_icon,
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteLink(link)
                                    },
                                  },
                                },
                                [
                                  _c("svg-sprite", {
                                    attrs: { name: "delete", color: "#999999" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("outlook-integration-popup", {
        attrs: {
          show: _vm.outlookShowPopup,
          "settings-sync": _vm.settingsSync,
          "integration-name": _vm.integrationName,
        },
        on: {
          linkCreated: _vm.openLinkPopup,
          closePopup: function ($event) {
            _vm.outlookShowPopup = false
          },
        },
      }),
      _vm._v(" "),
      _c("google-integration-popup", {
        attrs: {
          show: _vm.googleShowPopup,
          "settings-sync": _vm.settingsSync,
          "integration-name": _vm.integrationName,
        },
        on: {
          linkCreated: _vm.openLinkPopup,
          closePopup: function ($event) {
            _vm.googleShowPopup = false
          },
        },
      }),
      _vm._v(" "),
      _c("integration-generated-link", {
        attrs: {
          show: _vm.isLinkGenerated,
          link: _vm.linkForInstructions,
          "integration-name": _vm.integrationName,
        },
        on: { closePopup: _vm.closeGeneratedLinkPopup },
      }),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: !!_vm.deleteInfoData,
          "popup-width": 440,
          title: _vm.locale("integration_delete_popup_title"),
          text: _vm.locale(
            _vm.integrationName + "_integration_delete_popup_text"
          ),
          "ok-button": {
            title: _vm.locale("common_remove"),
            type: "destructive",
          },
          "cancel-button": {
            title: _vm.locale("common_cancel"),
            type: "secondary",
          },
        },
        on: {
          onClickOk: _vm.onOkDeletePopup,
          onClickCancel: function ($event) {
            _vm.deleteInfoData = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }