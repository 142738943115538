var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.resources },
    [
      _vm.isAccount
        ? _c("account-resources", {
            attrs: {
              resources: _vm.accountResources,
              "account-roles": _vm.accountRoles,
              "project-roles": _vm.projectRoles,
              projects: _vm.projects,
              "account-role-change-access": _vm.accountRoleChangeAccess,
              "remove-resources-access": _vm.removeResourcesAccess,
              "resource-calendar-access": _vm.resourceCalendarAccess,
              "pricing-access-to-resource-calendar":
                _vm.pricingAccessToResourceCalendar,
              "create-and-invite-resources-access":
                _vm.createAndInviteResourcesAccess,
              "pricing-access-to-cost-settings":
                _vm.pricingAccessToCostSettings,
              "is-over-subscription": _vm.isOverSubscription,
              "me-is-account-owner": _vm.meIsAccountOwner,
            },
            on: {
              onSendInvites: _vm.onSendInvites,
              onCreateResource: _vm.onCreateResource,
              onChangeCostType: _vm.onChangeCostType,
              onChangeCost: _vm.onChangeCost,
              onChangeProjectRole: _vm.onChangeProjectRole,
              updateResource: _vm.updateResource,
              upgradePlanShowFromResourcesForce:
                _vm.upgradePlanShowFromResourcesForce,
              showRemovePopup: _vm.showRemovePopup,
              onChangeOwner: _vm.onChangeOwner,
              onChangeAccountRole: _vm.onChangeAccountRole,
              onChangeUserAvatar: _vm.onChangeUserAvatar,
              showConvertPopup: _vm.showConvertPopup,
              assignToProjects: _vm.multiAssignToProjects,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isProject
        ? _c("project-resources", {
            attrs: {
              "project-resources": _vm.projectResources,
              "all-resources": _vm.accountResources,
              "account-roles": _vm.accountRoles,
              "project-roles": _vm.projectRoles,
              "has-right-to-cost-settings": _vm.hasRightToCostSettings,
              "pricing-access-to-cost-settings":
                _vm.pricingAccessToCostSettings,
              "create-and-invite-resources-access":
                _vm.createAndInviteResourcesAccess,
              "me-is-king": _vm.meIsKing,
              "me-is-project-owner": _vm.meIsProjectOwner,
              "account-role-change-access": _vm.accountRoleChangeAccess,
              "project-role-change-access": _vm.projectRoleChangeAccess,
            },
            on: {
              onSendInvites: _vm.onSendInvites,
              onCreateResource: _vm.onCreateResource,
              addResourcesToProject: _vm.addResourcesToProject,
              onChangeCostType: _vm.onChangeCostType,
              onChangeCost: _vm.onChangeCost,
              onChangeProjectRole: _vm.onChangeProjectRole,
              onChangeOwner: _vm.onChangeOwner,
              showRemovePopup: _vm.showRemovePopup,
              showConvertPopup: _vm.showConvertPopup,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transfer-ownership-modal-vue", {
        attrs: {
          show: _vm.isShowChangeOwnerPopup,
          config: _vm.transferPopupConfig,
        },
        on: {
          onClose: function ($event) {
            ;(_vm.isShowChangeOwnerPopup = false),
              (_vm.transferPopupConfig = null)
          },
        },
      }),
      _vm._v(" "),
      _c("remove-resource-popup-vue", {
        attrs: {
          "is-show": _vm.isShowRemovePopup,
          config: _vm.removePopupConfig,
        },
        on: {
          onClose: _vm.closeRemovePopup,
          removeResourcesFromAccount: _vm.removeResourcesFromAccount,
          removeResourcesFromProject: _vm.removeResourcesFromProject,
        },
      }),
      _vm._v(" "),
      _vm.isShowConvertPopup
        ? _c("convert-resources-popup-vue", {
            attrs: { config: _vm.convertPopupConfig },
            on: {
              onClose: _vm.closeConvertPopup,
              convertResourcesOnProject: _vm.convertResourcesOnProject,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }