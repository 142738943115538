var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.progress_wrapper },
    [
      _c("progress-bar", { attrs: { value: _vm.originalValue } }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.renderValue))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }