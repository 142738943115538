import app from '../../../app';
import globalStore from '../../../store/main';
import { resourceHelper } from './helpers';
// import projectsModel from "../../../models/projects";

async function onTaskResourceUpdated(event) {
  // const allGanttIds = projectsModel.getAllProjects().map(o => o.id);
  //
  // if (!allGanttIds.includes(event.innerSettings.projectId)) {
  //   return;
  // }
  if (event.innerSettings.projectId && event.innerSettings.taskId) {
    globalStore.dispatch('tasksModel/updateTasksResourcesByGanttId', {
      resourcesToTasks: event.innerSettings.resourcesToTasks,
      ganttId: event.innerSettings.projectId,
    });

    const taskData = globalStore.getters['tasksModel/getTaskByGanttId'](
      event.innerSettings.projectId,
      event.innerSettings.taskId,
    );

    app.trigger('tasks:model:updateTask', taskData.id, taskData);

    app.trigger('onAfterCollaboration', {
      event: event.event,
      projects: [event.innerSettings.projectId],
      tasks: [event.innerSettings.taskId],
    });
  }
}

export default onTaskResourceUpdated;
