var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.outer__wrapper }, [
    _c(
      "div",
      { class: _vm.$style.inner_wrapper },
      [
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("onboarding_preparing_project_progress")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("v-progress-linear", {
          attrs: {
            indeterminate: "",
            "background-color": "#E5E5E5",
            color: "#337870",
            rounded: "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }