// public/helpers/resetLogo.js

// {} -> {Promise}
export default () => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  xhr.onerror = function() {
    console.info('xhr error!'); // !DEBUG
    const err = new Error('network error!'); // !FIXME: const
    return reject(err);
  };

  xhr.onload = function (data, k, n) {
    // !DEBUG
    // var response = JSON.parse(data.currentTarget.response);
    const { currentTarget: { status } } = data;

    if (status >= 300 || status < 200) {
      const err = new Error('Faild to remove logo!'); // !FIXME: const
      return reject(err);
    }

    return resolve({ status: 'ok' });
  };

  xhr.open('DELETE', '/api/upload/teamlogo/');
  xhr.send();
});
