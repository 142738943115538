<template>
  <div
    class="upgrade_btn"
    @click="upgradeClick"
  >
    {{ locales('upgrade_rate_btn') }}
  </div>
</template>

<script>
import { getPlansObj } from '../../../../helpers/pricingHelper';

export default {
  props: [
    'feature',
  ],
  data() {
    return {
      locales: __,
    };
  },
  methods: {
    upgradeClick() {
      const newPlanObj = getPlansObj(this.feature);
      const userPlanId = user.accountPlanData.payment_plans_id;
      const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

      userExtAnalytics.log('pricing_deadlock_reached', { type: this.feature });
      this.$store.dispatch('globalPopup/show', {
        type: 'upgradePlan',
        title: 'upgrade_plan_popup_title',
        currentPlan: userPlan,
        newPlan: newPlanObj,
        // userCount: user.subscription.user_count,
        isAnnual: user.subscription.is_annual,
      });
    },
  },
};
</script>

<style scoped src='./less/upgradeButton.less'></style>
