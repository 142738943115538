var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style["assigned-resources-chips-wrapper"],
        _vm.isActive
          ? _vm.$style["assigned-resources-chips-wrapper_active"]
          : "",
        _vm.isFieldDisabled("resources") && _vm.resourcesLength <= 7
          ? _vm.$style["cursor-default"]
          : "",
      ],
      style: { width: _vm.containerWidth + "px" },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
        mouseenter: _vm.showAssignedResourcesPopup,
        mouseleave: _vm.hideAssignedResourcesPopup,
      },
    },
    [
      _c("rolling-items", {
        key: "task-settings-assigned" + _vm.rollingItemsKey,
        attrs: {
          items: _vm.resources,
          "container-width": _vm.containerWidth,
          "max-items-show": 7,
          offset: _vm.singleResourceAssigned ? 4 : -4,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("vgp-chip", {
                  key: item.id,
                  attrs: {
                    "img-url": item.avatar,
                    preset: _vm.singleResourceAssigned ? "user" : "avatar",
                    text: _vm.singleResourceAssigned ? item.name : "",
                    "is-avatar": "",
                    "txt-ellipsis": _vm.resources.length === 1,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }