import app from "./../app";

var notification = {};

if (app.debug) {
  GT.models.notification = notification;
}

notification.confirmDelivery = function (id) {
  webix.ajax().put("/api/notification/setShown/" + id, function (response) {
  });
};

notification.getWaitingNotifications = function () {
  var date = new Date();

  // webix.ajax().get("/api/notification/waiting", {v: date.valueOf()}, function (response) {
  //   app.trigger('notifications:showWaiting', JSON.parse(response));
  // });
};

export default notification;

