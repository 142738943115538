var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "vgp-interactive-element",
        _vm.$style.vgp_input_box,
        _vm.isError ? _vm.$style.error : null,
        _vm.isDisabled ? _vm.$style.disabled : null,
        _vm.small ? _vm.$style.small : _vm.$style.big,
        !_vm.border ? _vm.$style.borderless : null,
        _vm.readonly ? _vm.$style.borderless_readonly : null,
        _vm.postfix ? _vm.$style.with_postfix : "",
        _vm.prefix ? _vm.$style.with_prefix : "",
      ],
      on: { click: _vm.focusElement },
    },
    [
      _vm.imagesLeft.length
        ? _c(
            "div",
            { class: [_vm.$style.images_left] },
            _vm._l(_vm.imagesLeft, function (image, index) {
              return _c("div", { key: index, class: [_vm.$style.image_wrap] }, [
                _c("img", { attrs: { src: image, alt: "image" } }),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.iconsLeft.length
        ? _c(
            "div",
            { class: [_vm.$style.icons_left] },
            _vm._l(_vm.iconsLeft, function (item, i) {
              return _c("sprite", {
                key: i,
                class: _vm.$style.svg,
                attrs: {
                  name: item.name || "check",
                  size: item.size || 24,
                  type: item.type || "regular",
                  color: "#999999",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.iconHandler(item.handler)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.prefix
        ? _c(
            "span",
            {
              class: [_vm.$style.prefix, "input-prefix"],
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handlePrefixClick($event)
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.prefix) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.setInputType === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.componentValue,
                expression: "componentValue",
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            ref: "input_field_icons",
            class: [_vm.$style.vgp_input],
            style: _vm.size,
            attrs: {
              id: _vm.getGpAutotestName,
              readonly: _vm.readonly,
              disabled: _vm.isDisabled,
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              maxlength: _vm.maxlength,
              autocomplete: _vm.autocomplete,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.componentValue)
                ? _vm._i(_vm.componentValue, null) > -1
                : _vm.componentValue,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.componentValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.componentValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.componentValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.componentValue = $$c
                  }
                },
                _vm.onChange,
              ],
              focus: _vm.onFocus,
              input: _vm.onInput,
              keydown: _vm.onKeydownChange,
              keyup: _vm.onKeyupChange,
              blur: _vm.onBlur,
            },
          })
        : _vm.setInputType === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.componentValue,
                expression: "componentValue",
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            ref: "input_field_icons",
            class: [_vm.$style.vgp_input],
            style: _vm.size,
            attrs: {
              id: _vm.getGpAutotestName,
              readonly: _vm.readonly,
              disabled: _vm.isDisabled,
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              maxlength: _vm.maxlength,
              autocomplete: _vm.autocomplete,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.componentValue, null) },
            on: {
              change: [
                function ($event) {
                  _vm.componentValue = null
                },
                _vm.onChange,
              ],
              focus: _vm.onFocus,
              input: _vm.onInput,
              keydown: _vm.onKeydownChange,
              keyup: _vm.onKeyupChange,
              blur: _vm.onBlur,
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.componentValue,
                expression: "componentValue",
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            ref: "input_field_icons",
            class: [_vm.$style.vgp_input],
            style: _vm.size,
            attrs: {
              id: _vm.getGpAutotestName,
              readonly: _vm.readonly,
              disabled: _vm.isDisabled,
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              maxlength: _vm.maxlength,
              autocomplete: _vm.autocomplete,
              type: _vm.setInputType,
            },
            domProps: { value: _vm.componentValue },
            on: {
              change: _vm.onChange,
              focus: _vm.onFocus,
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.componentValue = $event.target.value
                },
                _vm.onInput,
              ],
              keydown: _vm.onKeydownChange,
              keyup: _vm.onKeyupChange,
              blur: _vm.onBlur,
            },
          }),
      _vm._v(" "),
      _vm.postfix
        ? _c(
            "span",
            {
              class: _vm.$style.postfix,
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handlePostfixClick($event)
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.postfix) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.iconsRight.length
        ? _c(
            "div",
            { class: _vm.$style.icons_right },
            _vm._l(_vm.iconsRight, function (item, i) {
              return _c("sprite", {
                key: i + item.name,
                class: _vm.$style.svg,
                attrs: {
                  name: item.name || "check",
                  size: item.size || 24,
                  type: item.type || "regular",
                  color: "#999999",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.iconHandler(item.handler)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }