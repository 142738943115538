var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "profile_settings",
        !["resources", "project_rights", "account_rights"].includes(_vm.type)
          ? "gantt-scroll"
          : "",
      ],
      style: { paddingTop: _vm.header[_vm.type].height + "px" },
    },
    [
      _c("profile-settings-header", {
        attrs: {
          title: _vm.header[_vm.type].title,
          icon: _vm.header[_vm.type].icon,
          tabs: _vm.header[_vm.type].tabs,
          "active-by": "type",
          "active-tab": _vm.header[_vm.type].activeTab,
        },
        on: { toggle: _vm.onToggle },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main",
          style: {
            height: _vm.clientHeight - _vm.header[_vm.type].height - 30 + "px",
          },
        },
        [
          _c(
            "div",
            { ref: "settings", staticClass: "settings" },
            [_c("router-view")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }