import store from '../../store/main';
import constants from '../../helpers/constants';

export const NOTIFICATION_POPUP_TYPE = {
  NEWS: 'news',
  DEMO: 'demo',
};

export const NOTIFICATION_POPUP_CONFIG = {
  [NOTIFICATION_POPUP_TYPE.NEWS]: {
    content: null,
    title: __('news_notify_popup_title'),
    imageSrc: 'https://cdn.ganttpro.com/app/imgs/notifications/newsNotifyIcon.svg',
    imgContainerBg: '#FF9A00',
    positionContext: null,
    controlNodeId: 'footerBtnLearningCenterId',
    attachedToRefName: 'learningCenter',
    onCloseClickHandler: () => {
      const { lastNewsEntryId } = window.user;

      if (lastNewsEntryId) {
        store.dispatch(
          'user/updateActivityStatus',
          { activityName: constants.USER_ACTIVITIES.LAST_NEWS_ID, status: lastNewsEntryId },
        );
      }
    },
  },
  [NOTIFICATION_POPUP_TYPE.DEMO]: {
    content: __('demo_modal_popup_text'),
    title: __('demo_modal_popup_title'),
    imageSrc: 'https://cdn.ganttpro.com/statics/media/images/email/tania.png',
    isRoundedImg: true,
    positionContext: null,
    controlNodeId: 'footerBtnDemoId',
    attachedToRefName: 'demo',
    onCloseClickHandler: () => {
      store.dispatch(
        'user/updateActivityStatus',
        { activityName: constants.USER_ACTIVITIES.DEMO_POPUP, status: 1 },
      );
    },
  },
};
