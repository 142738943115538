// public/models/onBoarding/onBoardModal.js
// !TODO: DESCRIPTION

import icon_close from '../../svg/close.svg';
import app from '../../app';

import './onBoardModal.less';

const __ = window.__;

// !TODO: import from the app
const ID_EVENT_APP_ONBOARD_NEXT = 'app::onboarding::next';
const ID_EVENT_APP_ONBOARD_FINISH = 'app::onboarding::finish';

// onboard_tour_popup_close_click
const onPopupCloseClick = () => {
  app.trigger(ID_EVENT_APP_ONBOARD_FINISH);
};

// onboard_tour_popup_next_click
const onPopupNextClick = () => {
  userExtAnalytics.log('onboarding_tour_popup_next_click');
  app.trigger(ID_EVENT_APP_ONBOARD_NEXT);
};

// onboard_tour_popup_finish_click
const onPopupFinishClick = () => {
  userExtAnalytics.log('onboarding_tour_popup_finish_click');
  app.trigger(ID_EVENT_APP_ONBOARD_FINISH);
};

// an intrernal-handlers object
const _handlers = {};

// an internal-templates object
const _templates = {
  // {Object} -> {String}
  closeIcon: ({ ico }) => `
        <div class="window_header_icon">
            ${ico}
        </div>`,
  // {Object} -> {String}
  contentGifTemplate: ({ src }) => `
        <div style="width:100%; height: 100%; border-top: 1px solid #d9d9d9; border-bottom: 1px solid #d9d9d9;">
            <img
                style="width:100%; height: 100%;"
                src="${src}"
            />
        </div>
    `,
  // {Object} -> {String}
  currentStep: ({ curStep, totalSteps }) => `
        <div style="width:100%; height: 100%; display: flex; align-items: center;">
            <b>${curStep}</b>&nbsp;|&nbsp;${totalSteps}
        </div>
    `,
};

// // step_1
// const onBoardModalNewProject = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 115,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalNewProject',
//   css: 'onboard-modal',
//   borderless: true,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [{ height: 1 }, {
//         width: 295,
//         css: 'modal-header-label',
//         view: 'label',
//         label: __('app_onboard_newproj_caption'),
//       }],
//     }, {
//       view: 'template',
//       borderless: true,
//       css: 'modal-header-closeicon',
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '1) create a project' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_newproj_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       height: 24,
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         view: 'template',
//         css: 'modal-body-important',
//         template: __('app_onboard_newproj_content_misc'),
//         borderless: true,
//       }, {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       height: 24,
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         borderless: true,
//         view: 'template',
//         data: { curStep: 1, totalSteps: 15 },
//         template: _templates.currentStep,
//       }, {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       // css: 'test-borderless',
//       height: 24,
//       borderless: true,
//     }],
//   },
// };

// step_1
const onBoardModalAddTask = {
  hidden: true,
  autofocus: false,
  view: 'window',
  zIndex: 15,
  modal: false,
  unClosable: true,
  id: 'onBoardModalAddTask',
  css: 'onboard-modal',
  borderless: false,
  move: false,
  width: 360,
  head: {
    height: 60,
    paddingY: 15,
    cols: [
      {
        width: 24,
      },
      {
        rows: [
          {
            height: 1,
          },
          {
            width: 295,
            css: 'modal-header-label',
            view: 'label',
            label: __('app_onboard_newtask_caption'),
          },
        ],
      },
      {
        view: 'template',
        css: 'modal-header-closeicon gp_autotest_onboard_modal_close_btn',
        width: 30,
        height: 30,
        data: { ico: icon_close },
        borderless: true,
        template: _templates.closeIcon,
        onClick: {
          'modal-header-closeicon': () => {
            userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '1) create a task' });
            onPopupCloseClick();
          },
        },
      },
    ],
  },
  body: {
    rows: [
      {
        height: 180,
        cols: [{
          view: 'template',
          css: '',
          borderless: true,
          data: { src: GT.appMode.isBase ? (`${GT.cdn}/imgs/onboarding/step_02.gif`) : '' },
          template: _templates.contentGifTemplate,
        }],
      },
      {
        height: 18,
        borderless: true,
      },
      {
        cols: [{
          width: 24,
          borderless: true,
        }, {
          autoheight: true,
          borderless: true,
          template: __('app_onboard_newtask_content'),
        }, {
          borderless: true,
          width: 24,
        }],
      },
      {
        height: 24,
        borderless: true,
      }, {
        height: 24,
        cols: [{
          width: 24,
          borderless: true,
        }, {
          borderless: true,
          view: 'template',
          data: { curStep: 1, totalSteps: 4 },
          template: _templates.currentStep,
        },
        {
          width: 24,
          borderless: true,
        }],
      }, {
        height: 24,
        borderless: true,
      }],
  },
};

// step_2
const onBoardModalConnection = {
  hidden: true,
  autofocus: false,
  view: 'window',
  zIndex: 9,
  modal: false,
  unClosable: true,
  id: 'onBoardModalConnection',
  css: 'onboard-modal',
  borderless: false,
  move: false,
  width: 360,
  head: {
    height: 60,
    paddingY: 15,
    cols: [{
      width: 24,
    }, {
      rows: [
        { height: 1 },
        {
          width: 295,
          css: 'modal-header-label',
          view: 'label',
          label: __('app_onboard_link_caption'),
        },
      ],
    }, {
      view: 'template',
      borderless: true,
      css: 'modal-header-closeicon',
      width: 30,
      height: 30,
      data: { ico: icon_close },
      template: _templates.closeIcon,
      onClick: {
        'modal-header-closeicon': () => {
          userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '2) create dependency' });
          onPopupCloseClick();
        },
      },
    }],
  },
  body: {
    rows: [{
      height: 180,
      cols: [{
        view: 'template',
        css: '',
        borderless: true,
        data: { src: GT.appMode.isBase ? (`${GT.cdn}/imgs/onboarding/step_03.gif`) : '' },
        template: _templates.contentGifTemplate,
      }],
    },
    {
      height: 18,
      borderless: true,
    },
    {
      cols: [{
        width: 24,
        borderless: true,
      }, {
        autoheight: true,
        borderless: true,
        css: 'modal-body-content',
        template: __('app_onboard_link_content'),
      }, {
        borderless: true,
        width: 24,
      }],
    },
    {
      height: 24,
      borderless: true,
    }, {
      height: 24,
      cols: [{
        width: 24,
        borderless: true,
      }, {
        borderless: true,
        view: 'template',
        data: { curStep: 2, totalSteps: 4 },
        template: _templates.currentStep,
      },
      {
        width: 24,
        borderless: true,
      }],
    }, {
      height: 24,
      borderless: true,
    }],
  },
};

// step_3
const onBoardModalAssign = {
  hidden: true,
  autofocus: false,
  view: 'window',
  zIndex: 9,
  modal: false,
  unClosable: true,
  id: 'onBoardModalAssign',
  css: 'onboard-modal',
  borderless: false,
  move: false,
  width: 360,
  head: {
    height: 60,
    paddingY: 15,
    cols: [{
      width: 24,
    }, {
      rows: [
        { height: 1 },
        {
          width: 295,
          css: 'modal-header-label',
          view: 'label',
          label: __('app_onboard_assign_caption'),
        },
      ],
    }, {
      view: 'template',
      borderless: true,
      css: 'modal-header-closeicon',
      width: 30,
      height: 30,
      data: { ico: icon_close },
      template: _templates.closeIcon,
      onClick: {
        'modal-header-closeicon': () => {
          userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '3) assign a task' });
          onPopupCloseClick();
        },
      },
    }],
  },
  body: {
    rows: [{
      cols: [{
        width: 24,
        borderless: true,
      }, {
        autoheight: true,
        borderless: true,
        template: __('app_onboard_assign_content'),
      }, {
        borderless: true,
        width: 24,
      }],
    }, {
      height: 24,
      borderless: true,
    },
    {
      css: 'modal-footer',
      cols: [
        {
          borderless: true,
          view: 'template',
          data: { curStep: 3, totalSteps: 4 },
          template: _templates.currentStep,
        },
        {
          view: 'button',
          id: 'idBtnAssignNext',
          value: __('app_onboard_btn_next'),
          css: '',
          height: 32,
          click: onPopupNextClick,
        },
      ],
    }, {
      height: 24,
      borderless: true,
    }],
  },
};

// step_4
const onBoardModalColumns = {
  hidden: true,
  autofocus: false,
  view: 'window',
  zIndex: 9,
  modal: false,
  unClosable: true,
  id: 'onBoardModalColumns',
  css: 'onboard-modal',
  borderless: false,
  move: false,
  width: 360,
  head: {
    height: 60,
    paddingY: 15,
    cols: [{
      width: 24,
    }, {
      rows: [
        { height: 1 },
        {
          width: 295,
          css: 'modal-header-label',
          view: 'label',
          label: __('app_onboard_columns_caption'),
        },
      ],
    }, {
      view: 'template',
      borderless: true,
      css: 'modal-header-closeicon',
      width: 30,
      height: 30,
      data: { ico: icon_close },
      template: _templates.closeIcon,
      onClick: {
        'modal-header-closeicon': () => {
          userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '4) add a column' });
          onPopupCloseClick();
        },
      },
    }],
  },
  body: {
    rows: [{
      cols: [{
        width: 24,
        borderless: true,
      }, {
        autoheight: true,
        borderless: true,
        template: __('app_onboard_columns_content'),
      }, {
        borderless: true,
        width: 24,
      }],
    }, {
      height: 24,
      borderless: true,
    }, {
      css: 'modal-footer',
      cols: [
        {
          borderless: true,
          view: 'template',
          data: { curStep: 4, totalSteps: 4 },
          template: _templates.currentStep,
        },
        {
          view: 'button',
          id: 'idBtnColumnsNext',
          value: __('app_onboard_btn_finish'),
          css: '',
          height: 32,
          click: onPopupFinishClick,
        },
      ],
    },
    {
      height: 24,
      borderless: true,
    }],
  },
};

// step_6
// const onBoardModalTeamAndResource = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 33,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalTeamAndResource',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_teamres_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       borderless: true,
//       css: 'modal-header-closeicon',
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '6) team & resources' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_teamres_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 6, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idBtnTeamAndResNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_7
// const onBoardModalFilter = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalFilter',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_filter_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '7) filter' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_filter_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 7, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idBtnFilterNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_8
// const onBoardModalPeople = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalPeople',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_people_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '8) people tab' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_people_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 8, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idBtnPeopleNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_9
// const onBoardModalZoom = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalZoom',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_zoom_caption'),
//         }],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '9) zoom' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_zoom_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 9, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idZoomNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_10
// const onBoardModalHistory = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalHistory',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_history_caption'),
//         }],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '10) history' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_history_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 10, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idHistoryNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_11
// const onBoardModalProjSettings = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalProjSettings',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_projsettings_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '11) project settings' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_projsettings_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 11, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idProjSettingsNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_12
// const onBoardModalBaseline = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalBaseline',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_baseline_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '12) baseline' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_baseline_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 12, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idBaselineNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_13
// const onBoardModalProjExport = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalProjExport',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_projexport_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '13) export' });
//           onPopupCloseClick();
//         },
//       },
//     }, {
//       width: 18,
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_projexport_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 13, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idProjExportNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_14
// const onBoardModalPublicUrl = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalPublicUrl',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_projlink_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '14) sharing' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_projlink_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 14, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idPublicUrlNext',
//         value: __('app_onboard_btn_next'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupNextClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };
//
// // step_15
// const onBoardModalSaveTemplate = {
//   hidden: true,
//   autofocus: false,
//   view: 'window',
//   zIndex: 9,
//   modal: false,
//   unClosable: true,
//   id: 'onBoardModalSaveTemplate',
//   css: 'onboard-modal',
//   borderless: false,
//   move: false,
//   width: 360,
//   head: {
//     height: 60,
//     paddingY: 15,
//     cols: [{
//       width: 24,
//     }, {
//       rows: [
//         { height: 1 },
//         {
//           width: 295,
//           css: 'modal-header-label',
//           view: 'label',
//           label: __('app_onboard_templates_caption'),
//         },
//       ],
//     }, {
//       view: 'template',
//       css: 'modal-header-closeicon',
//       borderless: true,
//       width: 30,
//       height: 30,
//       data: { ico: icon_close },
//       template: _templates.closeIcon,
//       onClick: {
//         'modal-header-closeicon': () => {
//           userExtAnalytics.log('onboarding_tour_popup_close_click', { step: '15) project templates' });
//           onPopupCloseClick();
//         },
//       },
//     }],
//   },
//   body: {
//     rows: [{
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         autoheight: true,
//         borderless: true,
//         template: __('app_onboard_templates_content'),
//       }, {
//         borderless: true,
//         width: 24,
//       }],
//     }, {
//       height: 18,
//       borderless: true,
//     }, {
//       cols: [{
//         width: 24,
//         borderless: true,
//       }, {
//         width: 192,
//         borderless: true,
//         view: 'template',
//         data: { curStep: 15, totalSteps: 15 },
//         template: _templates.currentStep,
//       },
//       {
//         view: 'button',
//         id: 'idSaveTemplateNext',
//         value: __('app_onboard_btn_finish'),
//         css: '',
//         width: 120,
//         inputWidth: 120,
//         inputHeight: 36,
//         click: onPopupFinishClick,
//       },
//       {
//         width: 24,
//         borderless: true,
//       }],
//     }, {
//       height: 24,
//       borderless: true,
//     }],
//   },
// };

const PopupManager = {
  // @private
  _views: {
    // newProjStep: webix.ui(onBoardModalNewProject),
    addTaskStep: webix.ui(onBoardModalAddTask),
    connectionStep: webix.ui(onBoardModalConnection),
    assignStep: webix.ui(onBoardModalAssign),
    columnStep: webix.ui(onBoardModalColumns),
    // teamAndResourceStep: webix.ui(onBoardModalTeamAndResource),
    // filterStep: webix.ui(onBoardModalFilter),
    // peopleStep: webix.ui(onBoardModalPeople),
    // zoomStep: webix.ui(onBoardModalZoom),
    // historyStep: webix.ui(onBoardModalHistory),
    // projSettingsStep: webix.ui(onBoardModalProjSettings),
    // baselineStep: webix.ui(onBoardModalBaseline),
    // projExportStep: webix.ui(onBoardModalProjExport),
    // publicUrlStep: webix.ui(onBoardModalPublicUrl),
    // saveTemplateStep: webix.ui(onBoardModalSaveTemplate),
  },
  // @public ????
  init(config) {
  },
  // @public {String, Object} -> {}
  // show: function(idStep, elemNode, pos) {

  //     const _stepViewCfg = this._views[idStep];

  //     if (!_stepViewCfg) {
  //         console.warn('unknown step'); // !DEBUG
  //         return;
  //     }

  //     Object.keys(this._views).forEach((key) => {
  //         $$(this._views[key].id) && $$(this._views[key].id).close();
  //     });

  //     let _view = $$(_stepViewCfg.id)

  //     if (!_view) {
  //         _view = webix.ui(_stepViewCfg);
  //     }
  //         _view.show(elemNode, pos);

  //         // _view.hide();
  //         // setTimeout(() => {
  //         //     _view.show(elemNode, pos);
  //         // }, 100);

  //     // _stepView.show(elemNode, pos);
  // },
  //
  show(idStep, elemNode, pos) {
    const _view = this._views[idStep];

    if (!_view) {
      // console.warn('Unknow view', idStep, this._views); // !DEBUG
      return;
    }

    Object.keys(this._views).forEach(view => {
      this._views[view].getNode().style.display = 'none';
    });

    _view.show(elemNode, pos);
  },
  //
  hide(idStep) {
    const _view = this._views[idStep];

    if (!_view) {
      // console.warn('Unknow view', idStep);
      return;
    }

    _view.getNode().style.display = 'none';
  },
  // // {String} -> {}
  // //!TODO: description
  // hide: function(idStep) {
  //     const isDev = !!webix.debug_load_event;

  //     if (idStep) {
  //         const _stepViewCfg = this._views[idStep];

  //         let _tmpPP = [];

  //         // webix.ui.et (dist) vs webix.ui.popups
  //         if ($$(_stepViewCfg.id)) {
  //             const pp = isDev
  //                 ? webix.ui._popups
  //                 : webix.ui.et;

  //             _tmpPP = pp;

  //             while(pp.length > 0) {
  //                 pp.removeAt(0);
  //             }

  //             $$(_stepViewCfg.id).close();

  //             // "onClose event"
  //             setTimeout(() => {
  //                 _tmpPP.forEach((entry,ind) => pp.insertAt(entry));
  //                 _tmpPP = [];
  //             }, 300);
  //             return;
  //         }
  //     }

  //     // if (!_stepViewCfg) {
  //     //     console.warn('unknown step'); // !DEBUG
  //     //     return;
  //     // }
  //         // this._views[idStep] && $$(this._views[idStep].config.id).close();
  //         // this._views[idStep] && $$(this._views[idStep].config.id).hide();
  //         // return;

  //     Object.keys(this._views).forEach((key) => {
  //         const _cfg = this._views[key];
  //         if ($$(_cfg.id)) {
  //             // $$(_cfg.id).blockEvent();
  //             $$(_cfg.id).close();
  //             // $$(_cfg.id).unblockEvent();
  //         }
  //         // this._views[key].hide(s);
  //     });
  // },
};

export default PopupManager;
