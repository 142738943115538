<template xmlns:integration-popup="http://www.w3.org/1999/html">
  <div>
    <integration-popup
      v-if="show"
      :show="show"
      :integration-name="'outlook'"
      :settings-sync="settingsSync"
      :existed-links="existedLinks"
      @closePopup="handleCancel"
      @createOrUpdateLink="handleCreateOrUpdate"
    />
  </div>
</template>

<script>

import IntegrationPopup from '../googleOutlookIntegrations/integrationPopup.vue';

export default {
  name: 'OutlookIntegrationPopup',
  components: {
    IntegrationPopup,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    settingsSync: {
      type: Object,
      required: false,
      default: null,
    },

  },
  data() {
    return {
      locale: __,

    };
  },
  computed: {
    existedLinks() {
      return this.$store.getters['integrationOutlook/outlookLinks'] || [];
    },
  },
  methods: {
    handleCreateOrUpdate(data) {
      if (this.settingsSync) {
        this.$store.dispatch('integrationOutlook/updateLink', { id: this.settingsSync.id, data })
          .then(() => {
            this.$toast.success(this.locale('integration_link_updated'));
            this.$emit('closePopup');
          });
      } else {
        this.$store.dispatch('integrationOutlook/createLink', { data })
          .then(response => {
            userExtAnalytics.log('integration_outlook_calendar_connected', { type: data.connectionType });

            this.$emit('linkCreated', response);

            this.$emit('closePopup');
          });
      }
    },
    handleCancel() {
      this.$emit('closePopup');
    },
  },
};
</script>

<style module="$" src="../googleOutlookIntegrations/googleOutlookIntegration.less"></style>
