const __ = window.__;

export default function (containerId) {
  return '<div id="' + containerId + '" class="b-upload">' +
    '<div class="upload__desc">' +
    '<div class="upload__text">' + __('task_settings_upload_text') + '</div>' +
    '<div class="upload__link js-uploadLink">' + __('task_settings_upload_button') + '</div>' +
    '</div>' +
    '</div>';
};
  