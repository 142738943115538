import app from '../app';
import notifyHelpers from '../helpers/notifications';

const __ = window.__;

const notificationComponent = {};

notificationComponent.showNotifyForSharedProject = function (projectData, ownerData, commentsCount, projectType) {
  let text = "<span class='txt_blue'>";

  if (ownerData && (ownerData.firstName || ownerData.lastName)) {
    text += `${ownerData.firstName} ${ownerData.lastName}`;
  } else {
    text += ownerData.username;
  }

  text += '</span>';
  text += ` ${__('notifications_shared')}`;
  text += ` <span class='txt_orange'>«${projectData.name}»</span>.`;
  text += ` ${__('notifications_share_access')}`;
  text += ` ${__(`notifications_shared_access_${projectData.rights > 50 ? 'editor' : 'viewer'}`)}`;
  text += ` ${__('notifications_currently_messages')}`;
  text += ` ${__('notifications_shared_unread_comments')}: `;
  text += commentsCount;

  notifyHelpers.show(text, projectType);
  // $$('noProjectLayout').isVisible() && $$('noProjectLayout').hide();

  app.trigger('changedCommentsCount', { count: commentsCount });
};

notificationComponent.changeRightsShareProjectMessage = function (projectData, ownerData) {
  let text = "<span class='txt_blue'>";

  if (ownerData.firstName || ownerData.lastName) {
    text += `${ownerData.firstName} ${ownerData.lastName}`;
  } else {
    text += ownerData.userName;
  }
  text += '</span>';

  text += ` ${__('notifications_change_rights')}`;
  text += ` <span class='txt_orange'>«${projectData.name}»</span>.`;
  text += ` ${__('notifications_access_available')}`;
  text += ` ${__(`notifications_shared_access_${projectData.access > 50 ? 'editor' : 'viewer'}`)}`;
  notifyHelpers.show(text, 'warning');
};

notificationComponent.deleteSharedProjectMessage = function (data) {
  let text = "<span class='txt_blue'>";

  if (ownerData.firstName || ownerData.lastName) {
    text += `${ownerData.firstName} ${ownerData.lastName}`;
  } else {
    text += ownerData.userName;
  }
  text += '</span>';

  text += ` ${__('notifications_delete_project')}`;
  text += ` <span class='txt_orange'>«${projectData.name}»</span>.`;
  text += ` ${__('notifications_remove_from_tab')}`;
  notifyHelpers.show(text, 'warning');
};

notificationComponent.removeSharedProjectMessage = function (projectData, ownerData) {
  let text = "<span class='txt_blue'>";

  if (ownerData.firstName || ownerData.lastName) {
    text += `${ownerData.firstName} ${ownerData.lastName}`;
  } else {
    text += ownerData.userName;
  }
  text += '</span>';

  text += ` ${__('notifications_remove_access')}`;
  text += ` <span class='txt_orange'>«${projectData.name}»</span>.`;
  text += ` ${__('notifications_remove_from_tab')}`;
  notifyHelpers.show(text, 'warning');
};

notificationComponent.removeTeamProjectMessage = function () {
  notifyHelpers.show(
    __('notifications_you_deleted_project'),
    'warning',
  );
};

notificationComponent.showCollaborationEventData = function (historyEventData, projectType) {
  let text = "<span class='txt_blue'>TestUser </span>";

  text += ` ${__(historyEventData.locale, historyEventData.data)}`;

  notifyHelpers.show(text, projectType);
};

export default notificationComponent;
