import projectModel from '../../models/projects';
import helper from './helpers';

export default {
  valuesLoaded: state => state.valuesLoaded,
  valuesLoadedByProjectId: (state, getters) => projectId => {
    const project = getters.getProjectById(projectId);
    let valuesLoaded = false

    if (!project) return valuesLoaded;
    if (project.values) {
      valuesLoaded = true;
    }

    return valuesLoaded;
  },
  getAllCustomColumns: state => state?.columns,
  getStaticColumns: state => state.staticColumns,
  getProjectColumns: state => state?.projectColumns,
  getJiraColumns: state => state?.jiraColumns,
  getCustomColumnById: state => id => state.columns.find(col => col.id === +id),
  getColumnIdsByProjectId: state => projectId => state.projectColumns.find(project => project.id === +projectId)?.columns || [],
  getColumnByName: (state, getters) => (columnName, ganttId = null) => {
    const activeProjectData = ganttId ? projectModel.getItem(ganttId) : projectModel.getActiveProjectData();
    
    let columns = [];

    if (activeProjectData && activeProjectData.is_jira) {
      columns = getters.getJiraColumnsByProjectId(activeProjectData.gantt_id);
    } else {
      columns = state.staticColumns;
    }

    return columns.find(col => col.name === columnName) || null;
  },
  getJiraColumnsByProjectId: (state, getters) => projectId => {
    const jiraColumns = getters.getJiraColumns.find(col => +col.id === +projectId)?.fields || [];

    return helper.prepareJiraOptions(jiraColumns);
  },
  getJiraColumnByNameAndProjectId: (state, getters) => (name, projectId) => {
    const jiraColumns = getters.getJiraColumnsByProjectId(projectId);
    const column = jiraColumns.find(col => col.name === name);

    return column || null;
  },
  getProjectById: state => projectId => state.projectColumns.find(project => +project.id === projectId),
  getProjectColumnsByIds: state => projectIds => state.projectColumns.filter(project => projectIds.includes(+project.id)),
  getProjectsByColumnId: state => columnId => {
    const projectsIds = [];

    state.projectColumns.forEach(project => {
      const hasColumn = project?.columns.includes(+columnId);

      if (!hasColumn || project.is_archived) return;

      projectsIds.push(project.id);
    });

    return projectsIds;
  },

  // return object of "[taskId-columnId]: value" pairs
  getValuesMapByProjectId: (state, getters) => projectId => {
    const project = getters.getProjectById(projectId);
    const columnsIds = getters.getColumnIdsByProjectId(projectId);
    const columnsMap = {};

    columnsIds.forEach(columnId => {
      columnsMap[columnId] = getters.getCustomColumnById(columnId)
    });

    if (!project) return {};

    const valuesMap = {};

    if (!project.values) return valuesMap;
    
    Object.keys(project.values).forEach(taskId => {
      const customColumnValues = project.values[taskId];

      customColumnValues.forEach(customColumnValueObject => {
        let isOptionsTypeField = getters.isOptionsTypeField(customColumnValueObject.column_id);

        if (isOptionsTypeField) {
          if (valuesMap[`${taskId}-${customColumnValueObject.column_id}`]) {
            valuesMap[`${taskId}-${customColumnValueObject.column_id}`].push(customColumnValueObject.task_value);
          } else {
            valuesMap[`${taskId}-${customColumnValueObject.column_id}`] = [customColumnValueObject.task_value];
          }
        } else {
          valuesMap[`${taskId}-${customColumnValueObject.column_id}`] = customColumnValueObject.task_value;
        }
      });
    });

    return valuesMap;
  },
  getValuesMapForTask: (state, getters) => (projectId, taskId) => {
    const project = getters.getProjectById(projectId);
    const columnsIds = getters.getColumnIdsByProjectId(projectId);
    const columnsMap = {};

    columnsIds.forEach(columnId => {
      columnsMap[columnId] = getters.getCustomColumnById(columnId)
    });

    if (!project) return {};
    const valuesMap = {};

    const customColumnValues = project.values[taskId];
    if (customColumnValues) {
      customColumnValues.forEach(customColumnValueObject => {
        let isOptionsTypeField = getters.isOptionsTypeField(customColumnValueObject.column_id);

        if (isOptionsTypeField) {
          if (valuesMap[customColumnValueObject.column_id]) {
            valuesMap[customColumnValueObject.column_id].push(customColumnValueObject.task_value);
          } else {
            valuesMap[customColumnValueObject.column_id] = [customColumnValueObject.task_value];
          }
        } else {
          valuesMap[customColumnValueObject.column_id] = customColumnValueObject.task_value;
        }
      });
    }

    return valuesMap;
  },
  getValueByParams: (state, getters) => params => {
    const { columnId, taskId, projectId } = params;
    let values = {};
    const project = getters.getProjectById(projectId);

    if (!project?.columns.find(id => id === columnId)) return [];

    if (project && project.values) values = project.values;
    if (taskId && values[taskId]) values = values[taskId];
    if (columnId) values = _.filter(values, o => o.column_id === +columnId);

    return values;
  },
  getProjectsNumberByColumnId: state => columnId => {
    const projectNum = {
      visible: 0,
      archive: 0,
    };

    state.projectColumns.forEach(project => {
      const isColumn = project.columns.includes(columnId);

      if (isColumn) {
        project.is_archived ? ++projectNum.archive : ++projectNum.visible;
      }
    });

    return projectNum;
  },
  isOptionsTypeField: (state, getters) => columnId => {
    const column = getters.getCustomColumnById(columnId);

    return column.type === 7 || column.type === 8 || column.type === 9;
  }
};
