import moment from 'moment';

export default {
  prepareResourceData(data) {
    return data;
  },
  formatCustomDayData(data) {
    if (data.to) {
      data.to = moment(data.to).format('YYYY-MM-DD');
    }
    if (data.from) {
      data.from = moment(data.from).format('YYYY-MM-DD');
    }
    if (data.repeatTo) {
      data.repeatTo = moment(data.repeatTo).format('YYYY-MM-DD');
    }
    if (data.repeatFrom) {
      data.repeatFrom = moment(data.repeatFrom).format('YYYY-MM-DD');
    }

    data.isDayOff = data.type === 'weekend';

    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        data[key] = undefined;// for internal api validation
      }
    });

    return data;
  },
};
