import store from '../../../store/main';

function onProjectStatusOptionDeleted({ data }) {
  data.items.forEach(item => {
    const updateData = {};
    updateData.optionId = item.id;
    updateData.action = 'delete';
    updateData.id = item.projectStatusId;
    store.commit('teamSetting/updateProjectStatusesOptions', updateData);
    store.commit('teamSetting/updateProjectsWithStatus', updateData);
  });
}

export default onProjectStatusOptionDeleted;
