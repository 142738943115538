var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-row" },
    [
      _c("div", {
        staticClass: "label",
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.isSelect
        ? _c("v-select", {
            attrs: {
              items: _vm.selectItems,
              "current-item": _vm.selected,
              "target-value": "value",
              width: _vm.selectWidth,
              height: _vm.selectHeight,
              styles: _vm.selectStyles,
              "select-type": _vm.config.id,
              "select-handler": _vm.selectHandler,
              position: _vm.position,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mode && _vm.mode === "checkbox"
        ? _c(
            "div",
            { staticClass: "checkbox-wrapper" },
            [
              _c("vgp-checkbox", {
                style: { marginRight: 16 + "px" },
                attrs: { checked: _vm.config.email },
                on: {
                  onClick: function () {
                    return _vm.checkboxHandler(
                      _vm.config.id,
                      "email",
                      _vm.config.email
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("vgp-checkbox", {
                style: _vm.defaultPermission ? { opacity: "0.5" } : {},
                attrs: {
                  checked: _vm.config.desktop,
                  "is-disable": _vm.disableCheckbox,
                },
                on: {
                  onClick: function () {
                    return _vm.checkboxHandler(
                      _vm.config.id,
                      "desktop",
                      _vm.config.desktop
                    )
                  },
                },
              }),
            ],
            1
          )
        : _c("toggle", {
            attrs: { "is-toggle-on": _vm.isToggleOn },
            on: {
              onChange: function ($event) {
                return _vm.onToggle(_vm.isToggleOn)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [_c("div")])
  },
]
render._withStripped = true

export { render, staticRenderFns }