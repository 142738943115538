import { internalApi } from '$$store/api';
import store from '../main';
import app from '../../app';

export default {
  async loadData({ commit, rootState, dispatch }) {
    let response;

    // used for publicUrl/history/export
    if (!rootState.appMode.isBase) {
      response = { data: GT.ganttData.roles };
    } else {
      response = await internalApi.get(`/roles/?${new Date().getTime()}`);
    }

    commit('setData', { account: response.data?.account, project: response.data?.project });
    store.dispatch('teamSetting/setUsersAccountRole', response.data?.usersAccountRole);
    app.checkInit('roles');
    app.trigger('rolesModel:init');
  },

  // add new account or project roles
  async addNewRole({ commit }, payload) {
    return internalApi.post(`/roles/${payload.type}`, {
      roleId: payload.id,
      title: payload.title,
    }).then(item => {
      if (payload.type === 'account') {
        commit('addAccountRole', item.data);
      } else {
        commit('addProjectRole', item.data);
      }
    });
  },

  async updateRole({ commit, state }) {
    let requestRights = [];
    let title;

    state[state.editMode.type].roles.forEach(role => {
      if (role.id === state.editMode.roleId) {
        requestRights = [...role.rights];
        title = role.title;
      }
    });

    requestRights = requestRights.map(item => ({
      bit: item.bit,
      status: item.status,
    }));

    return internalApi.put(`/roles/${state.editMode.type}/${state.editMode.roleId}`, {
      rights: requestRights,
      title,
    }).then(() => {
      commit('deleteDataFromEditMode');
    });
  },

  setDefaultRole({ commit }, payload) {
    return internalApi.put(`/roles/${payload.type}/default/${payload.id}`)
      .then(() => {
        commit('setDefaultAccountRole', payload);
      });
  },

  setRoleTitle({ commit }, payload) {
    return internalApi.put(`/roles/${payload.type}/${payload.id}`, {
      title: payload.title,
    })
      .then(() => {
        commit('setRoleTitle', payload);
      });
  },

  setEditMode({ commit }, payload) {
    commit('setEditMode', payload);
  },

  setBitStatus({ commit }, payload) {
    commit('setBitStatus', payload);
  },

  resetEdit({ commit }, payload) {
    commit('resetEdit', payload);
  },

  deleteRole({ commit }, payload) {
    return internalApi.delete(`/roles/${payload.type}/${payload.id}`, {
      data: {
        roleId: payload.roleId,
      },
    })
      .then(() => {
        commit('deleteRole', payload);
      });
  },
};
