var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-notifications" },
    [
      _c("div", { staticClass: "settings-section" }, [
        _c("div", { staticClass: "main-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("settings_notifications_title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("settings_notifications_text")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "settings-table" }, [
          _c("div", { staticClass: "checkbox-labels" }, [
            _c(
              "div",
              {
                staticClass: "label email tooltip-gantt",
                attrs: {
                  "data-y": -4,
                  "data-key": _vm.locales("email_notification_tooltip"),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("notification_email_label")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "label desktop tooltip-gantt",
                attrs: {
                  "data-y": -4,
                  "data-key": _vm.locales("desktop_notification_tooltip"),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("notification_desktop_label")) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "checkbox-rows" },
            _vm._l(_vm.notificationSettings, function (item, index) {
              return _c("setting-row", {
                key: item.key,
                attrs: {
                  mode: "checkbox",
                  label: _vm.locales("" + item.locale),
                  config: item,
                  "select-items": _vm.delayOptions,
                  "select-width": 84,
                  "select-height": 30,
                  "select-styles": { marginRight: 18 + "px" },
                  position: "top",
                  "select-handler": _vm.changeDelay,
                  "checkbox-handler": _vm.changeCheckbox,
                  "disable-checkbox": _vm.isShowPermissionFaq,
                  "notification-permission": _vm.notificationPermission,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "toggle-rows" },
            [
              _c("setting-row", {
                attrs: {
                  label: _vm.locales("sound_preferences"),
                  "toggle-handler": _vm.toggleSound,
                  "is-toggle-on": _vm.notificationSound,
                },
              }),
              _vm._v(" "),
              _c("setting-row", {
                attrs: {
                  label: _vm.locales("user_notification_dispatch_label"),
                  "toggle-handler": _vm.toggleEmailOffers,
                  "is-toggle-on": _vm.emailOffers,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "default-settings",
              on: { click: _vm.setToDefaultSettings },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.locales("recommended_settings_for_notifications")
                    ) +
                    "\n        "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.isShowPermissionFaq
            ? _c("div", { staticClass: "permission-faq" }, [
                _c("div", { staticClass: "message" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.locales("request_notification_permission_text_faq")
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "faq-btn",
                  domProps: { innerHTML: _vm._s(_vm.faqBtn) },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }