var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style["task-list-main"]] },
    [
      _vm.tasks.length || _vm.taskId
        ? _c(
            "div",
            {
              class: [_vm.$style["task-list-header"]],
              style: { minWidth: "275px" },
            },
            [
              _c("div", { class: [_vm.$style["task-list-header_label"]] }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales("comment_hub_tasks_label")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _vm.newComments
                ? _c("tooltip", {
                    class: [
                      _vm.$style["task-list-header_icon"],
                      "tooltip-wrapper",
                      "gp_autotest_comment_hub_read_all_tooltip",
                    ],
                    attrs: { content: _vm.locales("comment-hub-read-all") },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: [_vm.$style["icon-hover"]],
                                  on: { click: _vm.readAllComments },
                                },
                                [
                                  _c("svg-sprite", {
                                    attrs: { name: "email", size: 24 },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3332165382
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("recycle-scroller", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tasks.length,
            expression: "tasks.length",
          },
        ],
        ref: "tasksList",
        class: [
          _vm.$style["suggestions-list"],
          "gantt_scroll",
          _vm.needScroll ? "" : _vm.$style["suggestions-list-noScroll"],
          "gp_autotest_comment_hub_tasks_list",
        ],
        style: { height: _vm.height + "px" },
        attrs: { items: _vm.tasks, "min-item-size": 84, "key-field": "id" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("selectItem", item)
                      },
                    },
                  },
                  [
                    _c("task-item", {
                      class: [
                        _vm.taskId === item.id
                          ? _vm.$style["task-item-selected"]
                          : "",
                      ],
                      attrs: {
                        id: "gp_autotest_comment_hub_task_item_" + item.id,
                        "task-name": item.text,
                        "task-id": item.id,
                        "parent-path": _vm.parentPaths[item.id],
                        counter: _vm.unreadCommentsCount(item.id),
                        mention: _vm.mention(item.id),
                        "hide-tooltip": _vm.hideTooltip,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$style["delimiter"]] }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      !_vm.tasks.length && _vm.taskId
        ? _c(
            "div",
            {
              class: [
                _vm.$style["no-filtered-task-stub"],
                "gp_autotest_comment_hub_no_filter_task_stub",
              ],
              style: { height: _vm.height + "px" },
            },
            [
              _c("svgImage", {
                class: [_vm.$style["current-stub"]],
                attrs: { name: _vm.currentStub.stubSvg },
              }),
              _vm._v(" "),
              _c("div", { class: [_vm.$style["no-search-title"]] }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales(_vm.currentStub.stubTitle)) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: [_vm.$style["no-search-text"]] }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales(_vm.currentStub.stubText)) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }