const __ = window.__;

export default {
  getTemplate: function () {
    return "<div class='jira-view'>" +
      "<div class='jira-view-desc'>" +
      "<div class='jira-view-desc-item'>" + __('jira_integration_fourth_1') + "</div>" +
      "</div>" +
      "<div class='jira-view-paste-url'>" +
      "<div class='jira-view-paste-url-body'>" +
      "<div class='jira-view-paste-url-body-label'>" + __('jira_integration_fourth_2') + "</div><input type='text' class='input-jira-host' /> <button class='verify'>" + __('jira_integration_fourth_3') + "</button>" +
      "</div>" +
      "</div>" +
      "<div class='jira-view-screen fourth'>" + '' + "</div>" +
      "</div>";
  }
}
