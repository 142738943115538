import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import Store from '../../../store/main';

function onCustomFieldUpdated(event) {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const updates = event.data.updated;
  const projectIDs = [];

  updates.forEach(_update => {
    projectIDs.push(...Store.getters['columns/getProjectsByColumnId'](_update.customFieldId));
    const column = Store.getters['columns/getCustomColumnById'](_update.customFieldId);

    if (column) {
      if (_update.updatedFields.name) {
        column.name = _update.updatedFields.name;
      }

      if (_update.updatedFields.custom_column_sub_types_id) {
        column.sub_type = _update.updatedFields.custom_column_sub_types_id;
      }

      if (_update.updatedFields.options) {
        _update.updatedFields.options.forEach(element => {
          const optionIndex = column.options.findIndex(item => item.id === element.id);

          const newOptionData = {
            id: element.id,
            locale: null,
            resource_id: element.resourceId,
            icon: element.icon,
            color: element.color,
            custom_columns_id: column.id,
            sort_order: element.sortOrder,
            value: element.value,
            is_default: element.isDefault,
          };

          if (element.event === 'add') {
            column.options.push(newOptionData);
          }

          if (element.event === 'update' && optionIndex >= 0) {
            column.options[optionIndex] = newOptionData;
          }

          if (element.event === 'delete' && optionIndex >= 0) {
            column.options.splice(optionIndex, 1);
          }
        });
      }

      Store.commit('columns/updateCustomColumn', { columnId: column.id, updated: { ...column } });
      app.trigger('userCustomColumnsModel:change', ganttIDs[0], 'update');
    }
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    customFields: updates,
    projects: [...new Set(projectIDs)],
  });
}

export default onCustomFieldUpdated;
