<template>
  <vgpInput
    :key="'bulc-input-'+ item.id"
    :placeholder="placeholder"
    :is-disabled="isDisabled"
    :icons-right="iconsConfig"
    :small="true"
    :input-type="item.inputType"
    :value="inputValue"
    @onKeyup="onKeyup"
  />
</template>

<script>
import vgpInput from '../common/VueComponents/vInput/vgpInput.vue';

export default {
  name: 'BulkInput',
  components: { vgpInput },

  props: {
    item: { type: Object, required: true },
    placeholder: { type: String, required: true },
    isDisabled: { type: String, required: true },
  },

  data() {
    return {
      inputValue: '',
      validValue: '',
      iconsConfig: [],
      crossIcon: {
        name: 'circledCrossFill',
        handler: () => { this.onInput(''); },
      },
    };
  },

  watch: {
    inputValue() {
      this.updateIconsConfig();
    },
  },

  mounted() {
    this.updateIconsConfig();
  },

  methods: {
    onResetInput() {
      this.onInput('');
    },

    onInput(e) {
      this.inputValue = e;
      this.$emit('input', this.inputValue);
    },

    onKeyup(e, val) {
      if (this.item.inputType === 'number') {
        if (!Number.isNaN(val)) {
          this.validValue = val;
          this.onInput(val);
        } else {
          this.validValue = val;
        }

        return;
      }

      this.onInput(val);
      this.validValue = val;
    },

    updateIconsConfig() {
      !this.inputValue && this.iconsConfig.splice(0);

      if (this.inputValue && !this.iconsConfig.length) {
        this.iconsConfig.push(this.crossIcon);
      }
    },
  },

};
</script>
