var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.filter_footer }, [
    _c(
      "div",
      {
        class: [
          _vm.$style.filter_footer_btn,
          _vm.checkPricingAccess("saving_filters") ? "" : "pricing-tooltip",
        ],
        staticStyle: { "{color": "red, background: green}" },
        attrs: {
          id: "gp_autotest_global_filter_btn_save",
          "data-position": "left",
          "data-feature": "saving_filters",
        },
        on: { click: _vm.clickSaveBtn },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.locales.save) + "\n    "),
        !_vm.checkPricingAccess("saving_filters")
          ? _c("div", { staticClass: "pricing-title-container" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.locales.all(_vm.pricingPlan)) + "\n    "
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }