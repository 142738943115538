export default {
  toolTipInitForTopLabels: function () {
    var thisNode = this.getNode(),
      thisLabel = thisNode.getElementsByTagName("label"),
      labelClassList = [];
    if (!thisLabel.length) {
      return true;
    }
    labelClassList = thisLabel[0].classList;
    if (labelClassList.contains("tooltip-gantt")) {
      return true;
    }
    labelClassList.add("tooltip-gantt");
    thisLabel[0].setAttribute('data-position', 'left');
    thisLabel[0].setAttribute('data-key', this.config.toolTip.langKey);
    thisLabel[0].setAttribute('data-y', this.config.toolTip.y);
    thisLabel[0].setAttribute('data-x', this.config.toolTip.x);
  }
};
