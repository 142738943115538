<template>
  <button
    :class="[
      config.isGlobal ? $style.global_btn : $style.button,
      config.color ? $style[config.color] : '',
      config.size ? $style[config.size] : '',
      config.className ? $style[config.className] : '',
      config.isDisable ? $style.disabled : ''
    ]"
    :style="{
      width: config.width ? config.width : 'auto',
    }"
    @click="onClick"
  >
    {{ config.locale }}
  </button>
</template>

<script>
export default {
  props: ['config'],
  data() {
    return {

    };
  },
  methods: {
    onClick() {
      if (this.config.isDisable) {
        return;
      }
      this.config.handler();
    },
  },
};
</script>

<style module src='./globalButton.less'></style>
