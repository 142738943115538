// a "global search" window view implementation: app/public/views/gloabalSearch/main.js

import app, {
  appIds,
} from '../../app';

// import attachmentsModel from '../../models/attachment';
import filterGroupView from './filterGroup';

import projectsModel from '../../models/projects';
import routerHelper from '../../helpers/router';
import customHelper from '../../helpers/custom';
import icoAttach from '../../svg/globalSearch/attachments-24px.svg';
import icoComment from '../../svg/globalSearch/comments-24px.svg';
import icoDescription from '../../svg/globalSearch/deskription-24px.svg';
import icoTask from '../../svg/globalSearch/task-24px.svg';
import icoSearch from '../../svg/globalSearch/outline-search-24px.svg';

import icoListTask from '../../svg/globalSearch/ic_task.svg';
import icoListTaskGroup from '../../svg/globalSearch/ic_group_tasks.svg';
import icoListMilestone from '../../svg/globalSearch/ic_milestone.svg';

import './globalSearch.less';
import globalStore from '../../store/main';
import arrow_back from '../../svg/arrowBack.svg';
import rights from '../../components/rights';

const {
  popupViews: {
    ID_VIEW_POPUP_GLOBALSEARCH,
  },
  events: {
    ID_APP_EVENT_SEARCHFILTER_CHANGE,
    ID_EVENT_APP_ROUTECHANGED,
    ID_EVENT_POPUPS_CLOSEOTHER,
  },
  controlViews: {
    ID_VIEW_SEARCH_FILTERGROUP,
    ID_VIEW_SEARCH_INPUT_TEXT,
    ID_VIEW_SEARCH_RESULTSTABLE,
  },
} = appIds;

const IMG_SRC_NOENTRIES = 'https://cdn.ganttpro.com/app/imgs/globalSearch/noEntries.png';
const IMG_SRC_NOREQUEST = 'https://cdn.ganttpro.com/app/imgs/globalSearch/noRequest.png';

// variables
const __ = window.__;
const _ = window._;

const _autoCleanTimeout = 600000;
const _headerRowHeight = 48;
const _entryRowHeight = 56;

let _state = null;
let _templates = null;
let _helpers = null;
let _handlers = null;

let _cleanupTimer = null;
let _pathStringMaxCount = null;

// a component state object
_state = {
  searchText: null,
  searchFilter: 0,
  tasksById: {},
  groupsById: {},
  commentsById: {},
  projectsById: {},
  attachmentsById: {},
  filesById: {},
  tasksIdsByName: [],
  tasksIdsByDescr: [],
  tasksIdsByComments: [],
  tasksIdsByFiles: [],
};

const _filterConf = {
  TYPE_0: {
    id: 'TYPE_0',
    ord: 1,
    datasets: ['tasksIdsByName'],
    name: 'T_N', // !TODO: loc
  },
  TYPE_1: {
    id: 'TYPE_1',
    ord: 2,
    datasets: ['tasksIdsByDescr'],
    name: 'T_D', // !TODO: loc
  },
  TYPE_3: {
    id: 'TYPE_3',
    ord: 3,
    datasets: ['tasksIdsByComments'],
    name: 'T_C', // !TODO: loc
  },
  TYPE_4: {
    id: 'TYPE_4',
    ord: 4,
    datasets: ['zzz'],
    name: 'T_F', // !TODO: loc
  },
  TYPE_ALL: {
    id: 'TYPE_ALL',
    ord: 0,
    datasets: ['tasksIdsByName', 'tasksIdsByDescr'],
    name: 'T_A', // !TODO: loc
  },
};

function replaceAnalyticFilter(id) {
  switch (id) {
  case 0:
    return 'all';
  case 1:
    return 'task';
  case 2:
    return 'description';
  case 3:
    return 'comments';
  case 4:
    return 'files';
  default:
    return id;
  }
}

_helpers = {
  // !FIXME !TODO: move into the ProtoUI!
  selectFilterView: idSelected => {
    $$(ID_VIEW_SEARCH_FILTERGROUP).setValues({
      idSelected,
    }, true);
  },
  // !FIXME !TODO: move into the ProtoUI!
  updateFilterView: result => {
    const __cfg = {
      0: __('app_searchfilter_btn_all'),
      1: __('app_searchfilter_btn_name'),
      2: __('app_searchfilter_btn_descr'),
      3: __('app_searchfilter_btn_comments'),
      4: __('app_searchfilter_btn_files'),
    };

    const __result = result.map(({
      id,
      count,
    }) => ({
      id,
      count: count < 100 ? count : '99+',
      name: __cfg[id],
    }));

    $$(ID_VIEW_SEARCH_FILTERGROUP).setValues({
      entries: __result,
    }, true);
  },
  //
  selectInputViewText: ($$inputView, startPos, endPos) => {
    const __viewNode = $$inputView.getNode();
    const __inputNode = __viewNode.querySelector('input');

    if (startPos === undefined) {
      startPos = __inputNode.value.length;
    }

    if (endPos === undefined) {
      endPos = __inputNode.value.length;
    }

    __inputNode.selectionStart = startPos;
    __inputNode.selectionEnd = endPos;
    __inputNode.focus();
  },
  //
  adjustWindowPosition(config) {
    config.height = document.body.clientHeight;
    config.width = 542;

    config.left = -config.width;
    config.top = 0;
  },
  // {Object} -> {String
  getEntryIcon: ({
    entryPayload: {
      taskType,
    },
  }) => {
    switch (taskType) {
    case 'task':
      return icoListTask;
    case 'project':
      return icoListTaskGroup;
    case 'milestone':
      return icoListMilestone;
    default:
      return '';
    }
  },
  //
  getHeaderIcon: ({
    entryType,
    entryPayload,
  }) => {
    const {
      dataType,
    } = entryPayload;

    switch (dataType) {
    case 1:
      return icoTask;
    case 2:
      return icoDescription;
    case 3:
      return icoComment;
    case 4:
      return icoAttach;
    default:
      return 'null';
    }
  },
  // {Number} -> {Array}
  getTaskPath: (idTask, excludeTask = false) => {
    const __taskEntry = _state.tasksById[idTask];

    if (!__taskEntry) {
      return [];
    }

    const __projEntry = projectsModel.getProjectDataById(__taskEntry.gantt_id);
    const __path = [];
    let __ptr = __taskEntry;

    do {
      __path.unshift(__ptr.text);
      __ptr = _state.tasksById[__ptr.parent];
      /* groupsById[__ptr.parent]; */
    } while (__ptr);

    __path[0] = __projEntry.name;

    if (excludeTask) {
      __path.pop();
    }

    return __path;
    // return __path.join(' / ');
  },
  // !FIXME
  getTableItemTemplate: (dataObj /* , common, val */) => {
    _handlers.cbRenderTableItem(dataObj);

    return dataObj.entryType === 0
      ? _templates.itemGroupHeader(dataObj)
      : _templates.commonItem(dataObj);
  },
  getSearchValue: () => $$(ID_VIEW_SEARCH_INPUT_TEXT).getValue() || '',
  // {array} -> {array}
  applyFilter: data => {
    const __filter = _filterConf[_state.searchFilter];

    if (!__filter) {
      return data;
    }

    return __filter.handler(data);
  },
  // {}
  updateResultsView: (result = [] /* null */) => {
    $$(ID_VIEW_SEARCH_RESULTSTABLE).clearAll();
    $$(ID_VIEW_SEARCH_RESULTSTABLE).parse(result);
  },
  // !FIXME
  saveSearchResults: data => {
    _state.searchResults = data;

    return data;
    // return JSON.parse(JSON.stringify(data));
  },
  getHtmlTextContent: htmlString => {
    const div = document.createElement('div');

    div.innerHTML = htmlString;

    return div.textContent || div.innerText;
  },
  // {Number} -> {String}
  getTaskName: idTask => {
    const __taskEntry = _state.tasksById[idTask];

    return __taskEntry && __taskEntry.text;
  },
  //
  getTaskDescription: idTask => {
    const __taskEntry = _state.tasksById[idTask];

    return __taskEntry && _helpers.getHtmlTextContent(__taskEntry.note);
  },
  // {Number} -> {String}
  getCommentDescription: idComment => {
    const __commentEntry = _state.commentsById[idComment];

    return __commentEntry && _helpers.getHtmlTextContent(__commentEntry.comment);
  },
  //
  getTaskIdByCommentId: idComment => {
    const __commentEntry = _state.commentsById[idComment];

    return __commentEntry && __commentEntry.taskId;
  },
  // {Number} -> {Number}
  getTaskIdByAttachId: idAttach => {
    const __attachEntry = _state.attachmentsById[idAttach];

    return __attachEntry && __attachEntry.taskId;
  },
  // {Numbr} -> {String}
  getAttachDescription: idAttach => {
    const __attachEntry = _state.attachmentsById[idAttach];

    return __attachEntry && __attachEntry.name;
  },
  // !TODO: REFACTOR !TODO2: more immutability?
  applyGlobalFilter: () => {
    const {
      tasksIdsByName,
      tasksIdsByDescr,
      tasksIdsByComments,
      tasksIdsByFiles,
      searchFilter,
    } = _state;

    let __listItems = [];

    const __tasksIdsByName = tasksIdsByName.map(dataId => ({
      entryType: 1,
      entryPayload: {
        idTask: dataId,
        dataId,
        searchText: _helpers.getTaskName(dataId),
        searchPath: _helpers.getTaskPath(dataId, true),
        taskType: _state.tasksById[dataId].type,
      },
    }));

    const __tasksIdsByDescr = tasksIdsByDescr.map(dataId => ({
      entryType: 2,
      entryPayload: {
        idTask: dataId,
        dataId,
        searchText: _helpers.getTaskDescription(dataId),
        searchPath: _helpers.getTaskPath(dataId),
        taskType: _state.tasksById[dataId].type,
      },
    }));

    const __tasksIdsByComments = tasksIdsByComments.map(dataId => ({
      entryType: 3,
      entryPayload: {
        idTask: _helpers.getTaskIdByCommentId(dataId),
        dataId,
        searchText: _helpers.getCommentDescription(dataId),
        searchPath: _helpers.getTaskPath(_helpers.getTaskIdByCommentId(dataId)),
        taskType: _state.tasksById[_helpers.getTaskIdByCommentId(dataId)].type,
      },
    }));

    const __tasksIdsByFiles = tasksIdsByFiles.map(dataId => ({
      entryType: 4,
      entryPayload: {
        idTask: _helpers.getTaskIdByAttachId(dataId),
        dataId,
        searchText: _helpers.getAttachDescription(dataId),
        searchPath: _helpers.getTaskPath(_helpers.getTaskIdByAttachId(dataId)),
        taskType: _state.tasksById[_helpers.getTaskIdByAttachId(dataId)].type,
      },
    }));

    if (searchFilter === 1) {
      __listItems = __tasksIdsByName;
    } else if (searchFilter === 2) {
      __listItems = __tasksIdsByDescr;
    } else if (searchFilter === 3) {
      __listItems = __tasksIdsByComments;
    } else if (searchFilter === 4) {
      __listItems = __tasksIdsByFiles;
    } else if (searchFilter === 0) {
      if (__tasksIdsByName.length > 0) {
        __tasksIdsByName.unshift({
          entryType: 0,
          entryPayload: {
            name: __('app_search_results_header_task'),
            dataType: 1,
          },
        });
      }

      if (__tasksIdsByDescr.length > 0) {
        __tasksIdsByDescr.unshift({
          entryType: 0,
          entryPayload: {
            name: __('app_search_results_header_description'),
            dataType: 2,
          },
        });
      }

      if (__tasksIdsByComments.length > 0) {
        __tasksIdsByComments.unshift({
          entryType: 0,
          entryPayload: {
            name: __('app_search_results_header_comments'),
            dataType: 3,
          },
        });
      }

      if (__tasksIdsByFiles.length > 0) {
        __tasksIdsByFiles.unshift({
          entryType: 0,
          entryPayload: {
            name: __('app_search_results_header_attachments'),
            dataType: 4,
          },
        });
      }

      __listItems = [
        ...__tasksIdsByName,
        ...__tasksIdsByDescr,
        ...__tasksIdsByComments,
        ...__tasksIdsByFiles,
      ];
    }

    _helpers.updateResultsView(__listItems);
  },
  //
  getSearchPathNodeId: (typeId, dataId) => `idSearchPath_${typeId}_${dataId}`,
  //
  // {String, Number, String}
  normalizeSearchPath: (pathString, reqLength, filler = ' ... ') => {
    // console.info('normalizeSearchPath', pathString, reqLength); // !DEBUG
    const __pathLength = pathString.length;
    const __fillerLength = filler.length;

    if (__pathLength <= reqLength) {
      return pathString;
    }

    if (reqLength <= __fillerLength) {
      return filler;
    }

    const __tmp = __pathLength - reqLength;
    const __tmp2 = __tmp + __fillerLength; // 9

    const __remain = __pathLength - __tmp2;
    const __remainStart = parseInt(__remain / 2, 10);
    const __remainEnd = __remain - __remainStart;

    const __substr = pathString.substring(__remainStart, __pathLength - __remainEnd);

    return pathString.replace(__substr, filler);
  },
  // !description
  getStringLenPx: (srcString, fontName, fontSize) => {
    const __id = 'idGetLengthNode';
    let __node = document.getElementById(__id);

    if (!__node) {
      __node = document.createElement('span');
      __node.style.display = 'inline-block';
      __node.style.visiblity = 'hidden';
      __node.style.width = 'auto';
      __node.id = __id;

      document.body.appendChild(__node);
    }

    __node.style.fontSize = `${fontSize}px`;
    __node.style.fontFamily = fontName;
    __node.textContent = srcString;

    return __node.offsetWidth;
  },
  // !description
  // {Number, String, NUmber}
  getStringMaxSymCount: (maxLenPx, fontName, fontSize, fillerSym = 'M') => {
    const __id = 'id_test_node';
    let __node = document.getElementById(__id);
    let __maxLen = 0;
    const i = 0;

    if (!__node) {
      __node = document.createElement('span');
      __node.style.display = 'inline-block';
      __node.style.visiblity = 'hidden';
      __node.style.width = 'auto';
      __node.id = __id;

      document.body.appendChild(__node);
    }

    __node.style.fontSize = `${fontSize}px`;
    __node.style.fontFamily = fontName;
    __node.textContent = '';

    while (__node.offsetWidth < maxLenPx) {
      __node.textContent += fillerSym;
      __maxLen = __node.textContent.length;
    }

    return __maxLen;
  },
  calculateResultCount: () => {
    const __tasksById = _state.tasksById;
    const __commentsById = _state.commentsById;
    const __attachmentsById = _state.attachmentsById;

    const __allTasksIds = Object.keys(__tasksById);
    const __allCommentsIds = Object.keys(__commentsById);
    const __allAttachmentsIds = Object.keys(__attachmentsById);

    const __searchText = _state.searchText.toLowerCase();

    // !TODO: make optimization
    _state.tasksIdsByName = __allTasksIds.filter(idTask => __tasksById[idTask]
      && __tasksById[idTask].text
      && __tasksById[idTask].parent
      && __tasksById[idTask].search
      && __tasksById[idTask].text.toLowerCase().indexOf(__searchText) !== -1);

    _state.tasksIdsByDescr = __allTasksIds.filter(idTask => __tasksById[idTask]
      && __tasksById[idTask].note
      && __tasksById[idTask].parent
      && __tasksById[idTask].search
      && __tasksById[idTask].note.toLowerCase().indexOf(__searchText) !== -1);

    _state.tasksIdsByComments = __allCommentsIds.filter(idComment => __commentsById[idComment]
      && __commentsById[idComment].comment
      && __commentsById[idComment].comment.toLowerCase().indexOf(__searchText) !== -1);

    _state.tasksIdsByFiles = __allAttachmentsIds.filter(idAttachment => __attachmentsById[idAttachment]
      && __attachmentsById[idAttachment].name
      && __attachmentsById[idAttachment].name.toLowerCase().indexOf(__searchText) !== -1);

    const __cntByName = _state.tasksIdsByName.length;
    const __cntByDescr = _state.tasksIdsByDescr.length;
    const __cntByComments = _state.tasksIdsByComments.length;
    const __cntByFiles = _state.tasksIdsByFiles.length;
    const __total_cnt = __cntByName + __cntByDescr + __cntByComments + __cntByFiles;

    _helpers.updateFilterView([
      {
        id: 0,
        count: __total_cnt,
      },
      {
        id: 1,
        count: __cntByName,
      },
      {
        id: 2,
        count: __cntByDescr,
      },
      {
        id: 3,
        count: __cntByComments,
      },
      {
        id: 4,
        count: __cntByFiles,
      },
    ]);
  },
};

_handlers = {
  onBeforeAnimationHide: () => {
    // userExtAnalytics.log('global_search_window_hide');
  },
  onAfterPopupAnimatedHide: thisArg => {
    $$(ID_VIEW_SEARCH_INPUT_TEXT).blur();

    // document.activeElement.blur(); // breaks logic of the dhtmlx why?

    if (_cleanupTimer) {
      clearTimeout(_cleanupTimer);
    }

    _cleanupTimer = setTimeout(() => {
      if ($$(ID_VIEW_POPUP_GLOBALSEARCH).isShown() === false) {
        $$(ID_VIEW_SEARCH_INPUT_TEXT).setValue('');
      }

      _cleanupTimer = null;
    }, _autoCleanTimeout);

    gantt.setBlockingCollaborationByPopup(ID_VIEW_POPUP_GLOBALSEARCH, false);
  },
  //
  onAfterTableDataLoad: () => {
    const __$$tableView = $$(ID_VIEW_SEARCH_RESULTSTABLE);
    const __isSearchPresent = _state.searchText && _state.searchText.length > 1;

    if (__$$tableView.count() === 0) {
      const __template = _templates.getTableOverlay({
        imgSrc: __isSearchPresent ? IMG_SRC_NOENTRIES : IMG_SRC_NOREQUEST,
        text: __isSearchPresent ? __('app_search_banner_noresult') : __('app_search_banner_norequest'),
      });

      __$$tableView.showOverlay(__template);
    } else {
      __$$tableView.hideOverlay();
    }
  },
  //
  renderEntryPath: (entryType, entryPayload) => {
    if (entryType !== 0) {
      const __idPathNode = _helpers.getSearchPathNodeId(entryType, entryPayload.dataId);
      const __pathNode = document.getElementById(__idPathNode);

      if (!__pathNode) {
        return;
      }
      const __innerHtml = __pathNode.innerHTML;

      const __fontName = 'Lato-Regular';
      const __fontSizePx = 12;
      const __filler = 'M';

      if (!_pathStringMaxCount) {
        _pathStringMaxCount = _helpers.getStringMaxSymCount(
          __pathNode.offsetWidth - 36,
          __fontName,
          __fontSizePx,
          __filler,
        );
      }

      if (!__innerHtml || __innerHtml.trim().length === 0) {
        const __searchPathString = entryPayload.searchPath.join(' / ');

        const __cnt = (__searchPathString.match(new RegExp(__filler, 'g')) || []).length;

        const __k = Math.floor((__cnt / _pathStringMaxCount) * 10) / 10;
        let __ik = 1 - __k;

        if (__ik > 0) {
          __ik -= 0.1;
        }

        let __normalized = _helpers.normalizeSearchPath(
          __searchPathString,
          _pathStringMaxCount + parseInt(_pathStringMaxCount * __ik, 10),
        );

        while (_helpers.getStringLenPx(__normalized, __fontName, __fontSizePx) > __pathNode.offsetWidth
        - 36 /** padding */ && __ik > 0) {
          __ik -= 0.1;
          __normalized = _helpers.normalizeSearchPath(
            __searchPathString,
            _pathStringMaxCount + parseInt(_pathStringMaxCount * __ik, 10),
          );
        }

        __pathNode.innerHTML = customHelper.formatTaskName(__normalized);

        // __pathNode.innerHTML = _helpers.normalizeSearchPath(
        //     __searchPathString,
        //     _pathStringMaxCount + parseInt(_pathStringMaxCount * __ik, 10)
        // );
      }
    }
  },
  // !TODO: !DESCRIPTION
  cbRenderTableItem: ({
    id,
    entryType,
    entryPayload,
  }) => {
    setTimeout(() => {
      _handlers.renderEntryPath(entryType, entryPayload);
    }, 0);

    $$(ID_VIEW_SEARCH_RESULTSTABLE).setRowHeight(id,
      entryType === 0
        ? _headerRowHeight
        : _entryRowHeight);
  },
  // !FIXME: handle in the ProtoUI
  onSearchFilterChange: ({
    idControl,
    idFilter,
  }) => {
    userExtAnalytics.log('global_search_filter_change', { 'filter type': replaceAnalyticFilter(idFilter) }); // !FIXME: name of the filter

    _helpers.selectFilterView(idFilter);

    if (idControl !== ID_VIEW_SEARCH_FILTERGROUP) {
      console.warn('[_handlers::onSearchFilterChange] -> unknown control!'); // !DEBUG

      return;
    }

    const __idFilter = _state.searchFilter = idFilter;

    _helpers.applyGlobalFilter();
  },
  onBeforePopupShow: () => {
    // console.info('_handlers.onBeforePopupShow'); // !DEBUG
  },
  //
  onPopupShow: () => {
    _state.tasksIdsByComments = _state.tasksIdsByComments.filter(id => globalStore.getters['tasksModel/tasksByIds'][+id]);
    _state.tasksIdsByDescr = _state.tasksIdsByDescr.filter(id => globalStore.getters['tasksModel/tasksByIds'][+id]);
    _state.tasksIdsByFiles = _state.tasksIdsByFiles.filter(id => globalStore.getters['tasksModel/tasksByIds'][+id]);
    _state.tasksIdsByName = _state.tasksIdsByName.filter(id => globalStore.getters['tasksModel/tasksByIds'][+id]);

    const __tasksModelSerialized = globalStore.getters['tasksModel/getData'];
    const __userId = window.user.id;
    const userResourceData = globalStore.getters['resourcesModel/getResourceByUserId'](__userId);

    if ($$(ID_VIEW_POPUP_GLOBALSEARCH).isShown()) {
      return;
    }

    gantt.setBlockingCollaborationByPopup(ID_VIEW_POPUP_GLOBALSEARCH, true);

    // console.warn('__tasksModelSerialized', __tasksModelSerialized); // !DEBUG

    // app.trigger(ID_EVENT_POPUPS_CLOSEOTHER, ID_VIEW_POPUP_GLOBALSEARCH);
    if (_cleanupTimer) {
      clearTimeout(_cleanupTimer);
      _cleanupTimer = null;
    }

    _state.groupsById = {};
    _state.tasksById = {};
    _state.commentsById = {};
    _state.attachmentsById = {};

    for (let i = 0, len = __tasksModelSerialized.length; i < len; i++) {
      const {
        tasks,
        id,
        resourcesToTasks,
      } = __tasksModelSerialized[i];

      const __hasEditRight = rights.project.hasRight(id, 'all_tasks');

      if (projectsModel.isArchived(id)) {
        continue;
      }

      for (let j = 0, _len = tasks.length; j < _len; j++) {
        const {
          id,
          gantt_id,
          note,
          text,
          owner_id,
          parent,
          type,
        } = tasks[j];

        const __stateEntry = {
          [id]: {
            gantt_id,
            note,
            text,
            owner_id,
            parent,
            id,
            type,
            search: __hasEditRight || (resourcesToTasks && resourcesToTasks[id] && resourcesToTasks[id].find(
              entry => entry.resource_id === userResourceData.id,
            )),
          },
        };

        Object.assign(_state.tasksById, __stateEntry);
      }
    }

    // commentsModel.getNewCommentsForProjects()
    const comments = globalStore.getters['comments/getAllCommentsFull'];

    // console.info('getNewCommentsForProjects', comments); // !DEBUG
    for (let i = 0, len = comments.length; i < len; i++) {
      const {
        id,
        projectId,
        taskId,
        comment,
        is_removed,
      } = comments[i];

      if ((!_state.tasksById[taskId] || !_state.tasksById[taskId]
        .search /* && !_state.groupsById[task_id] */) || is_removed) {
        // console.warn('unavailable comment', id); // !DEBUG
        continue;
      }

      let commentString;
      const mention = comment.match(/\[~(\d+)\]/);

      if (mention) {
        commentString = comment.replace(/\[~\d+\]/, '');
      } else {
        commentString = comment;
      }

      const __stateEntry = {
        [id]: {
          id,
          projectId,
          taskId,
          comment: commentString,
        },
      };

      Object.assign(_state.commentsById, __stateEntry);
    }

    const attachments = globalStore.getters['attachments/getAllAttachments'];

    for (let i = 0, len = attachments.length; i < len; i++) {
      const {
        id,
        projectId,
        taskId,
        name,
      } = attachments[i];

      if (!_state.tasksById[taskId] || !_state.tasksById[taskId]
        .search /* && !_state.groupsById[task_id] */) {
        console.warn('unavailable atach', id);

        continue;
      }

      const __attachEntry = {
        [id]: {
          id,
          projectId,
          taskId,
          name: name.split('/').pop(),
        },
      };

      Object.assign(_state.attachmentsById, __attachEntry);
    }

    if (_state.searchText && _state.searchText.length) {
      _helpers.calculateResultCount();
      _helpers.applyGlobalFilter();
    }

    setTimeout(() => {
      _helpers.selectInputViewText($$(ID_VIEW_SEARCH_INPUT_TEXT), 0);
    }, 200 /** !FIXME */);
  },
  onSearchDataChange: _.debounce((d1, d2) => {
    // console.info('onSearchDataChange', _state.searchText, _helpers.getSearchValue()); // !DEBUG

    if (_state.searchText === _helpers.getSearchValue()) {
      return;
    }

    _state.searchText = _helpers.getSearchValue();

    userExtAnalytics.log('global_search_request_change', {
      'search text': _state.searchText,
    });

    if (_state.searchText.length <= 1) {
      _state.tasksIdsByName = [];
      _state.tasksIdsByDescr = [];
      _state.tasksIdsByComments = [];
      _state.tasksIdsByFiles = [];

      _helpers.updateResultsView();

      // TODO: make separate helper
      _helpers.updateFilterView([{
        id: 0,
        count: -1,
      },
      {
        id: 1,
        count: -1,
      },
      {
        id: 2,
        count: -1,
      },
      {
        id: 3,
        count: -1,
      },
      {
        id: 4,
        count: -1,
      },
      ]);

      return;
    }

    _helpers.calculateResultCount();
    _helpers.applyGlobalFilter();
  }, 300),
  // !FIXME
  onTableItemClick: tableItem => {
    const {
      entryType,
      entryPayload: {
        dataId,
      },
    } = $$(ID_VIEW_SEARCH_RESULTSTABLE).getItem(tableItem.row);

    if (entryType === 0) {
      return;
    }

    let idTask = null;

    if (entryType === 1) {
      idTask = dataId;
      // !FIXME
      window.taskSettingsData = {};
    }

    if (entryType === 2) {
      idTask = dataId;
      // !FIXME
      window.taskSettingsData = {};
    }

    if (entryType === 3) {
      idTask = _helpers.getTaskIdByCommentId(dataId);
      // !FIXME
      window.taskSettingsData = {
        initOptions: {
          selectComment: true,
        },
      };
    }

    if (entryType === 4) {
      idTask = _helpers.getTaskIdByAttachId(dataId);
      // !FIXME
      window.taskSettingsData = {
        initOptions: {
          selectAttachments: true,
        },
      };
    }

    const __stateTaskEntry = _state.tasksById[idTask];
    const project = __stateTaskEntry && projectsModel.getProjectDataById(__stateTaskEntry.gantt_id);

    if (!__stateTaskEntry || project.is_archived) {
      webix.message({
        type: 'warning',
        text: __('app_search_message_notask'),
      });

      return;
    }

    userExtAnalytics.log('global_search_result_entry_click', { 'entry type': replaceAnalyticFilter(entryType) });

    if (!rights.project.hasRight(__stateTaskEntry.gantt_id, 'all_tasks')) {
      const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;
      const projectTasksData = globalStore.getters['tasksModel/getItem'](__stateTaskEntry.gantt_id);

      if (!projectTasksData.resourcesToTasks[idTask]?.find(o => o.resource_id === resourceId)) {
        webix.message({
          type: 'warning',
          text: __('app_search_message_notask'),
        });
      } else {
        routerHelper.pushRoute({
          name: 'taskRoute',
          params: {
            taskId: idTask,
            projectId: __stateTaskEntry.gantt_id,
            mode: 'gantt',
          },
        });
      }
    } else {
      routerHelper.pushRoute({
        name: 'taskRoute',
        params: {
          taskId: idTask,
          projectId: __stateTaskEntry.gantt_id,
          mode: 'gantt',
        },
      });
    }
  },
};

//
const _delimiter_sm_view = {
  borderless: true,
  height: 12,
};

const _searchInputView = {
  view: 'text',
  css: 'global-search-input',
  paddingX: 24,
  id: ID_VIEW_SEARCH_INPUT_TEXT,
  placeholder: __('app_search_input_placeholder'),
  on: {
    onafterrender: () => {
      _helpers.selectInputViewText($$(ID_VIEW_SEARCH_INPUT_TEXT));
    },
    onChange: _handlers.onSearchDataChange,
    onKeyPress: _handlers.onSearchDataChange,
  },
};

_templates = {
  //
  getTableOverlay: ({
    imgSrc,
    text,
  }) => `
        <div class="table-overlay">
            <img class="overlay-image" src="${imgSrc}"/>
            <div class="overlay-content">
                ${text}
            </span>
        <div>`,
  //
  matchedSearchString: (srcString, searchText, className) => {
    const __foundInd = srcString.toLowerCase().indexOf(searchText.toLowerCase());

    if (__foundInd === -1) {
      return `<span>${srcString}</span>`;
    }

    const __preMatch = customHelper.formatTaskName(srcString.substring(0, __foundInd));
    const __match = customHelper.formatTaskName(srcString.substring(__foundInd, __foundInd + searchText.length));
    const __postMatch = customHelper.formatTaskName(srcString.substring(__foundInd + searchText.length, srcString
      .length));

    return `<span>${__preMatch}</span><span class="${className}">${__match}</span><span>${__postMatch}</span>`;
  },
  // table group-item template
  itemGroupHeader: dataItem => `
        <div class="table-group-item">
            <div class="group-item-container">
                <span class="table-header-icon">
                    ${_helpers.getHeaderIcon(dataItem)}
                </span>
                <span class="table-header-caption">
                    ${dataItem.entryPayload.name}
                </span>
                <span class="table-header-underline"></span>
            </div>
        </div>
    `,
  // table common-item template
  commonItem: dataItem => `
        <div class="table-common-item sidebar-close-popup">
            <div class="common-item-container">
            <div id="idItemSearchText_${dataItem.entryPayload.dataId}" class="item-searchtext-container">
                <div class="searchtext-icon">
                    ${_helpers.getEntryIcon(dataItem)}
                </div>
                <div class="searchtext-content">
                    ${
  _templates.matchedSearchString(
    dataItem.entryPayload.searchText,
    _state.searchText,
    'search-match',
  )
}
                </div>
                </div>
                <div id="${_helpers.getSearchPathNodeId(dataItem.entryType, dataItem.entryPayload.dataId)}" class="item-searchpath"></div>
            </div>
        </div>
    `,
};

const _border_delimiter_view = {
  view: 'template',
  css: 'border-delimiter',
  height: 1,
  // width: 'auto',
  borderless: true,
};

const _tableView = {
  borderless: true,
  id: ID_VIEW_SEARCH_RESULTSTABLE,
  view: 'datatable',
  css: 'search-results-table',
  width: 320,
  header: false,
  footer: false,
  rowHeight: 44,
  columns: [{
    id: 'entryName',
    header: 'entry_name',
    css: 'custom-dt-column',
    fillspace: true,
    template: _helpers.getTableItemTemplate,
  }],
  item: {
    borderless: true,
  },
  scheme: {
    $init(obj) {
      // cpecifying static index column in DataTable
      obj.index = this.count();
    },
  },
  data: [],
  on: {
    onAfterLoad: _handlers.onAfterTableDataLoad,
    onItemClick: _handlers.onTableItemClick,
    // 'onAfterAdd': function(d1, d2, d3) {},
    // 'onAfterRender': function(d1, d2, d3) {}
  },
  onClick: {
    customclass() {
      console.info('customclass click!'); /** debug */
    },
  },

};

const _globalSearchWndView = {
  hidden: true,
  view: 'popupWithAnimation',
  modal: false,
  head: false,
  borderless: true,
  move: false,
  width: 542,
  zIndex: 112,
  css: 'search-results-window',
  // height: 'auto',
  position: _helpers.adjustWindowPosition,
  id: ID_VIEW_POPUP_GLOBALSEARCH,
  body: {
    width: 542,
    css: '',
    marginX: 0,
    marginY: 0,
    rows: [{
      height: 94,
      cols: [{
        borderless: true,
        width: 12,
      },
      {
        rows: [
          {
            css: 'head_search',
            height: 32,
            cols: [
              {
                view: 'icon',
                width: 32,
                height: 32,
                template: `<span class="icon arrow-back sidebar-close-popup">${arrow_back}</span>`,
                on: {
                  onItemClick() {
                    // $$(ID_VIEW_POPUP_GLOBALSEARCH).hide();
                  },
                },
              },
              {
                height: 32,
                borderless: true,
                template: `<div class="title_search main">${__('title_search_popup')}</div>`,
              },
            ],
          },
          {
            height: 36,
            cols: [{
              borderless: true,
              view: 'template',
              width: 36,
              height: 36,
              paddingX: 6,
              paddingY: 6,
              template: () => `<div style="width: 100%; height: 100%; padding: 6px 0;">${icoSearch}</div>`,
            },
            _searchInputView,
            ],
          }],
      },
      ],
    },
    {
      ..._delimiter_sm_view,
    },
    {
      ...filterGroupView,
      id: ID_VIEW_SEARCH_FILTERGROUP,
    },
    {
      ..._border_delimiter_view,
    },
    {
      ..._delimiter_sm_view,
    },
    _tableView,
    ],
  },
  on: {
    onBeforeAnimationHide: _handlers.onBeforeAnimationHide,
    onAfterAnimationHide: _handlers.onAfterPopupAnimatedHide,
    onBeforeShow: _handlers.onBeforePopupShow,
    onShow: _handlers.onPopupShow,
  },
};

app.on(ID_APP_EVENT_SEARCHFILTER_CHANGE, _handlers.onSearchFilterChange);

app.on(ID_EVENT_APP_ROUTECHANGED, () => {
  if ($$(ID_VIEW_POPUP_GLOBALSEARCH).isShown()) {
    $$(ID_VIEW_POPUP_GLOBALSEARCH).hide();
  }
});

app.on(ID_EVENT_POPUPS_CLOSEOTHER, data => {
  if (
    data !== ID_VIEW_POPUP_GLOBALSEARCH
    && $$(ID_VIEW_POPUP_GLOBALSEARCH).isShown()
  ) {
    $$(ID_VIEW_POPUP_GLOBALSEARCH).hide();
  }
});

webix.ui(_globalSearchWndView).hide();
