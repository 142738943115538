/* eslint-disable */
import globalStore from '../../../store/main';
import _ from '../../../libs/lodash';
import customHelper from '../../../helpers/custom';
import projectsModel from '../../../models/projects';
import resourcesModule from './resources';
import moment from '../../../libs/moment';

const __ = window.__;
const formatDate = function (date) {
  return webix.i18n.dateFormatStr(moment(date).toDate());
};
var innerObject = {
  init: {
    run() {
      innerObject.settings.exportMode = false;
    },
    leftSideText(start, end, task) {
      return '';
    },
    rightSideText(start, end, task) {
      let text = '';
      const state = gantt.getState();
      let borderElement = '';

      if (gantt.config.right_side_text === 'rightSideText' && gantt.config.show_resource_avatar
        && task.resources && task.resources.length > 0 || task.type !== 'project') {
        if (state.drag_mode !== 'move') {
          borderElement = '<div class="resource_list_in_grid_border"></div>';
        }
      }
      if (task.type === 'project' && gantt.config.right_side_text === 'rightSideText') {
        borderElement = '<div class="project-name-and-dates-separator"></div>';
        const start = formatDate(task.start_date);
        const end = formatDate(task.end_date);
        let projectName = null;
        if (gantt.config.multiview && task.$level === 1) {
          const project = projectsModel.getProjectDataById(task.gantt_id);
          if (project) {
            projectName = customHelper.formatTaskName(project.name).trim();
          }
        } else {
          projectName = customHelper.formatTaskName(task.text).trim();
        }
        text = `<div class='custom-project-name'>${projectName}</div>${borderElement
        }<div class='custom-project-dates'>` + ` ${start} - ${end}</div>`;
      } else if (state.drag_mode !== 'move' && state.drag_mode !== 'resize') {
        const taskName = customHelper.formatTaskName(task.text).trim();
        text = `<div class='custom-task-name'>${taskName}</div>`;
      }

      if (gantt.config.right_side_text !== 'rightSideText' || task.workloadMode) {
        text = '';
      }

      if (gantt.config.show_resource_avatar && task.resources && task.resources.length > 0
        && !task.workloadMode && state.drag_mode !== 'move' && state.drag_mode !== 'resize' && task.type !== 'project') {

        const resourceData = globalStore.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](task.gantt_id, task.id);

        if (resourceData.length) {
          const count = task.resources.length;
          let html = `<div class="resource_list_in_grid resource-in-grid" data-task-id="${task.id}">`;
          let more = 0;

          _.each(resourceData, (resource, i) => {
            if (count < 4) {
              html += resourcesModule.helpers.getTemplateForResource(resource);
            } else if (i < 2) {
              html += resourcesModule.helpers.getTemplateForResource(resource);
            } else if (!more) {
              more = count - 2;
              html += `<span class="resource_list_item_more">+${more}</span>`;
            }
          });
          if (gantt.config.right_side_text !== 'rightSideText') {
            html += `</div>${text}`;

            if ((task.deadline && (end.valueOf() < task.deadline.valueOf()))) {
              html += `</div>${text}`;
            }
            if ((task.deadline && (end.valueOf() > task.deadline.valueOf()))) {
              html += `</div>${borderElement}${text}`;
            }
          } else {
            html += `</div>${borderElement}${text}`;
          }

          text = html;
        }
      }

      if (task.deadline && !task.workloadMode && state.drag_mode !== 'move' && state.drag_mode !== 'resize') {
        if (gantt.config.right_side_text !== 'rightSideText') {
          borderElement = '';
        }
        if (gantt.config.right_side_text !== 'rightSideText' && task.type === 'project') {
          return text;
        }
        if (end.valueOf() > task.deadline.valueOf()) {
          const overdue = Math.ceil(Math.abs((end.getTime() - task.deadline.getTime()) / (24 * 60 * 60 * 1000)));
          text += `${borderElement}<div class="custom-overdue">${__('overdue_deadline_task_text', {
            days: overdue,
          })}</div>`;
        }
      }

      return text;
    },
    taskTextTemplateContent(start, end, task) {
      const isMultiview = gantt.config.multiview;
      const borderElement = '<div class="project-name-and-dates-separator"></div>';
      let text = '';
      let projectName = null;
      if (gantt.config.right_side_text === 'rightSideText' && !task.workloadMode) {
        text = '';
      }
      if (gantt.config.right_side_text !== 'rightSideText' && task.type === 'project') {
        const start = formatDate(task.start_date);
        const end = formatDate(task.end_date);
        if (isMultiview && task.$level === 1 && gantt.config.right_side_text !== 'rightSideText') {
          const project = projectsModel.getProjectDataById(task.gantt_id);
          if (project) {
            projectName = customHelper.formatTaskName(project.name).trim();
          }
        } else {
          projectName = customHelper.formatTaskName(task.text).trim();
        }
        text = `<div class='custom-project-name'>${projectName}</div>${borderElement
        }<div class='custom-project-dates'>` + ` ${start} - ${end}</div>`;
      }
      if (gantt.config.right_side_text === 'insideText' && (task.type !== 'project' || task.workloadMode)) {
        text = customHelper.formatTaskName(task.text).trim();
      }
      if (task.deadline && !task.workloadMode && gantt.config.right_side_text !== 'rightSideText' && task.type === 'project') {
        if (end.valueOf() > task.deadline.valueOf()) {
          const overdue = Math.ceil(Math.abs((end.getTime() - task.deadline.getTime()) / (24 * 60 * 60 * 1000)));
          text += `${borderElement}<div class="custom-overdue">${__('overdue_deadline_task_text', {
            days: overdue,
          })}</div>`;
        }
      }
      return text;
    },
    helpers: {
      getTaskFitValue(task) {
        return 'right';
      },
    },
  },
  handlers: {
    setExportMode(exportMode) {
      innerObject.settings.exportMode = exportMode;
      innerObject.settings.totalEstimateId = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](gantt.config.gantt_id).id;
    },
  },
  settings: {
    ganttId: 0,
    exportMode: false,
    totalEstimate: {},
  },
};

const outputObject = {
  init: {
    run: innerObject.init.run,
    leftSideText: innerObject.init.leftSideText,
    rightSideText: innerObject.init.rightSideText,
    taskTextTemplateContent: innerObject.init.taskTextTemplateContent,
  },
  handlers: {
    setExportMode: innerObject.handlers.setExportMode,
  },
};

export default outputObject;
