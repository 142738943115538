var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    ref: "corePopup",
    attrs: {
      id: "gp_autotest_invite_to_project_popup",
      show: _vm.isShow,
      title: _vm.locales(
        "" +
          (_vm.layout === "people"
            ? "invite_to_project_title"
            : "create_for_project_title")
      ),
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { class: _vm.$.invite_to_project_popup },
              [
                _c("vgp-tabs", {
                  class: _vm.$.tabs,
                  attrs: {
                    "gp-autotest-name": "gp_autotest_invite_to_project_tabs",
                    tabs: _vm.tabs,
                    "active-by": "type",
                    active: _vm.tabsActive,
                  },
                  on: { onToggle: _vm.onToggleTab },
                }),
                _vm._v(" "),
                _c("div", { class: _vm.$.content }, [
                  _vm.activeTab === "team"
                    ? _c("div", { class: _vm.$.items_with_checkbox }, [
                        _c(
                          "div",
                          { class: _vm.$.items_with_checkbox__search },
                          [
                            _c("search", {
                              attrs: {
                                "gp-autotest-name":
                                  "gp_autotest_invite_to_project_popup_input_search_by_name",
                                placeholder: _vm.locales("search_by_name_only"),
                              },
                              on: { onInput: _vm.onSearch },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.resources.length
                          ? _c(
                              "div",
                              { class: _vm.$.items_with_checkbox__table },
                              [
                                _c(
                                  "div",
                                  { class: _vm.$.select_btn },
                                  [
                                    _c("vgp-button", {
                                      attrs: {
                                        id: "gp_autotest_invite_to_project_popup_btn_select_all",
                                        label:
                                          _vm.visibleSelectedLength ===
                                          _vm.resources.length
                                            ? _vm.locales(
                                                "multicombo_unselect_all_items"
                                              )
                                            : _vm.locales(
                                                "multicombo_select_all_items"
                                              ),
                                        type: "text",
                                        small: true,
                                      },
                                      on: { onClick: _vm.onToggleSelectAll },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { class: _vm.$.labels }, [
                                  _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.locales(
                                            "" +
                                              (_vm.layout === "people"
                                                ? "people_popup_names_label"
                                                : "people_popup_material_names_label")
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.layout === "people"
                                    ? _c(
                                        "div",
                                        { class: _vm.$.labels__roles },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.locales(
                                                  "people_popup_roles_label"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { class: [_vm.$.list, "gantt_scroll"] },
                                  _vm._l(_vm.resources, function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, class: _vm.$.list__row },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: _vm.$.info,
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickRow(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("vgp-checkbox", {
                                              key: item.key,
                                              staticClass:
                                                "gp_autotest_invite_to_project_popup_table_row_checkboxes",
                                              class: _vm.$.checkbox,
                                              style: {
                                                "pointer-events": "none",
                                              },
                                              attrs: {
                                                checked: item.isChecked,
                                                size: 16,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("vgp-avatar", {
                                              class: _vm.$.avatar,
                                              attrs: {
                                                size: "small",
                                                src: item.photo,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", { class: _vm.$.name }, [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.name) +
                                                  "\n                  "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        item.userId
                                          ? _c(
                                              "div",
                                              {
                                                class: [
                                                  _vm.$.dropdown,
                                                  item.isOwner ||
                                                  item.isKing ||
                                                  !_vm.projectRoleChangeAccess
                                                    ? _vm.$.disabled
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c("dropdown-select", {
                                                  staticClass:
                                                    "gp_autotest_invite_to_project_dropdown_select_rights_in_project",
                                                  attrs: {
                                                    "gp-autotest-name":
                                                      "gp_autotest_invite_to_project_dropdown_select_rights_in_project_" +
                                                      item.id,
                                                    "drop-list":
                                                      _vm.dropdownRoles,
                                                    multiselect: false,
                                                    "show-selected-first": false,
                                                    "show-reset-btn": false,
                                                    "highlight-active-state": false,
                                                    "item-template": "text",
                                                    "track-by-text": "title",
                                                    outline:
                                                      item.isOwner ||
                                                      item.isKing
                                                        ? "none"
                                                        : "hover",
                                                    disabled:
                                                      item.isOwner ||
                                                      item.isKing ||
                                                      !_vm.projectRoleChangeAccess,
                                                    "drop-position": "fixed",
                                                    "fixed-width": 320,
                                                    size: "autoWidth",
                                                    "need-description": true,
                                                    "parent-selector-for-calc-position-drop":
                                                      ".core_popup_block",
                                                    "track-by-item-tooltip":
                                                      "tooltip",
                                                    selected:
                                                      _vm.getProjectRole(item),
                                                  },
                                                  on: {
                                                    selected: function (
                                                      $event
                                                    ) {
                                                      return _vm.onSelectResourceProjectRole(
                                                        $event,
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { class: _vm.$.items_with_checkbox__stub },
                              [
                                _c("div", { class: _vm.$.wrap }, [
                                  _c("div", {
                                    class: _vm.$.img,
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.searchValue !== ""
                                          ? _vm.searchStub
                                          : _vm.stub
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { class: _vm.$.desc }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.searchValue !== ""
                                            ? _vm.locales(
                                                "people_project_popup_no_search_result"
                                              )
                                            : _vm.locales(
                                                "project_popup_no_resources_description"
                                              )
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeTab === "invite"
                    ? _c("div", { class: _vm.$.resources_invite }, [
                        _c(
                          "div",
                          { class: _vm.$.resources_invite__input_slot },
                          [
                            _c("vgp-label-slot", {
                              attrs: {
                                label: _vm.locales("common_emails"),
                                action: _vm.locales(
                                  "invite_popup_count_of_invitations",
                                  {
                                    count:
                                      _vm.invitationsCount > 0
                                        ? _vm.invitationsCount
                                        : 0,
                                  }
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _c("multiple-input", {
                                          attrs: {
                                            placeholder: _vm.locales(
                                              "common_enter_emails"
                                            ),
                                            validation: _vm.validationRules,
                                            classes: [
                                              _vm.invitationsCount <= 0
                                                ? "alert-border"
                                                : "",
                                            ],
                                            "is-error": _vm.inputError,
                                          },
                                          on: {
                                            setItems: _vm.setEmails,
                                            checkTag: _vm.onCheckValue,
                                            isInput: function (flag) {
                                              return (_vm.isInput = flag)
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2327124230
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.resources_invite__error_message },
                          [
                            _vm.inputError &&
                            _vm.isEmailError &&
                            _vm.invitationsCount > 0
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      id: "gp_autotest_invite_to_project_popup_message_error_email",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales(
                                            "invite_popup_alert_message_email"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inputError &&
                            _vm.isAlreadyInTeam &&
                            _vm.invitationsCount > 0
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      id: "gp_autotest_invite_to_project_popup_message_error_alreadyInTeam",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales(
                                            "permission_error_alreadyIsTeam"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inputError &&
                            _vm.isAlreadyInEmails &&
                            _vm.invitationsCount > 0
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      id: "gp_autotest_invite_to_project_popup_message_error_emailTaken",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales(
                                            "permission_error_alreadyIsInvites"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.invitationsCount <= 0
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      id: "gp_autotest_invite_to_project_popup_message_error_outOfInvitations",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locales(
                                            "invite_popup_alert_message"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.invite_area__dropdown_slot },
                          [
                            _c("vgp-label-slot", {
                              attrs: {
                                label: _vm.locales(
                                  "invite_to_project_role_dropdown_label"
                                ),
                                description: _vm.locales(
                                  "invite_to_project_role_dropdown_description",
                                  {
                                    default_role:
                                      _vm.getDefaultAccountRoleTitle,
                                  }
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _c("dropdown-select", {
                                          attrs: {
                                            "gp-autotest-name":
                                              "gp_autotest_invite_to_project_dropdown_select_rights_invited_people",
                                            "drop-list": _vm.dropdownRoles,
                                            multiselect: false,
                                            "show-selected-first": false,
                                            "show-reset-btn": false,
                                            "highlight-active-state": false,
                                            disabled:
                                              !_vm.projectRoleChangeAccess ||
                                              _vm.defaultAccountRoleIsKing,
                                            "fixed-width": 320,
                                            "need-description": true,
                                            size: "autoWidth",
                                            "item-template": "text",
                                            "track-by-text": "title",
                                            outline: "always",
                                            selected: _vm.dropdownRoles.find(
                                              function (i) {
                                                return i.id === _vm.inviteRoleId
                                              }
                                            ),
                                          },
                                          on: {
                                            selected: _vm.onSelectInviteRole,
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1424499648
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeTab === "create"
                    ? _c("div", { class: _vm.$.resources_create }, [
                        _c(
                          "div",
                          { class: _vm.$.resources_create__name_slot },
                          [
                            _c("vgp-label-slot", {
                              attrs: {
                                label: _vm.locales("add_resource_name_label"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _c("vgp-input", {
                                          attrs: {
                                            placeholder: _vm.locales(
                                              "common_placeholder_name"
                                            ),
                                            type: "text",
                                            small: true,
                                          },
                                          on: {
                                            onChange: _vm.inputChange,
                                            input: _vm.debouncedValidateName,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.isExistResourceName
                                          ? _c(
                                              "div",
                                              {
                                                class: _vm.$.warning_message,
                                                attrs: {
                                                  id: "gp_autotest_invite_to_project_popup_message_warning_alreadyExist",
                                                },
                                              },
                                              [
                                                _c("sprite", {
                                                  attrs: {
                                                    name: "warning-1",
                                                    type: "bold",
                                                    size: 20,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    class:
                                                      _vm.$
                                                        .warning_message_text,
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.locales(
                                                            "create_popup_exist_warning"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2903274145
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.$.resources_create__color_slot },
                          [
                            _c("vgp-label-slot", {
                              attrs: { label: _vm.locales("common_color") },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _c("color-palette", {
                                          attrs: {
                                            "select-type": "single",
                                            "select-field": "select",
                                            "selected-values": [17],
                                            colors: _vm.colors,
                                            "select-width": 72,
                                            "select-height": 32,
                                          },
                                          on: { selectColors: _vm.getColor },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                536110592
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.$.footer,
                      _vm.activeTab === "team" ? _vm.$.with_shadow : "",
                    ],
                  },
                  [
                    _c("vgp-button", {
                      style: { marginRight: "16px" },
                      attrs: {
                        id: "gp_autotest_invite_to_project_popup_btn_cancel",
                        label: _vm.locales("common_cancel"),
                        type: "secondary",
                        small: true,
                      },
                      on: { onClick: _vm.onClose },
                    }),
                    _vm._v(" "),
                    _c("vgp-button", {
                      attrs: {
                        id: "gp_autotest_invite_to_project_popup_btn_add",
                        label:
                          _vm.activeTab === "invite"
                            ? _vm.locales("send_invite_btn")
                            : _vm.locales("common_add"),
                        type: "primary",
                        small: true,
                        disabled: _vm.disableButton,
                      },
                      on: { onClick: _vm.onApply },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }