var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: _vm.show,
      title: _vm.locales("overview_manage_widgets_title"),
      "close-icon": "",
      "class-list": _vm.$.manage_modal,
    },
    on: {
      onClickCancel: function ($event) {
        return _vm.$emit("onCancel")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.description }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("overview_manage_widgets_description")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { class: [_vm.$.content, "gantt_scroll"] }, [
              _c(
                "div",
                { class: _vm.$.charts },
                [
                  _vm._l(_vm.chartsData, function (chart) {
                    return [
                      _vm.getChartComponent(chart.key)
                        ? _c(
                            "div",
                            {
                              key: chart.id,
                              class: [
                                _vm.$.item,
                                chart.x_size === 2 ? _vm.$.large : "",
                              ],
                            },
                            [
                              _c(_vm.getChartComponent(chart.key), {
                                tag: "component",
                                attrs: {
                                  chart: chart,
                                  data: _vm.getChartData(chart.key),
                                  description: "",
                                  static: "",
                                  "status-switch": "",
                                },
                                on: {
                                  onToggleStatus: function ($event) {
                                    return _vm.toggleChart(chart)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }