import app from "./../../../app";
import _ from "./../../../libs/lodash";
import massChangeModel from "./massChange";
import ganttViewModel from "../../../models/ganttViewModel";
import globalStore from "$$store/main";

import rights from '../../../components/rights';

const __ = window.__;


gantt.attachEvent("customButtons:delete:from", function(parentTask, indexButton) {
  innerObject.handlers.deleteCustomButtonFromParent(parentTask, indexButton);
});

gantt.attachEvent("customButtons:add:to", function(parentTask, indexButton) {
  innerObject.handlers.addCustomButtonInParent(parentTask, indexButton);
});

gantt.attachEvent("customButtons:task:add", function(buttonId, milestone) {
  var buttonClickData = gantt.getTask(buttonId);

  if (gantt.calculateTaskLevel(buttonId) === 1) { //createProject
    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.project, buttonClickData.parent);
    return true;
  }

  if (milestone) {
    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.milestone, buttonClickData.parent);
  } else {
    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.task, buttonClickData.parent);
  }
});

gantt.attachEvent("onBeforeRowDragEnd", function(id, parent, tindex) {
  innerObject.settings.oldParentId = parent;

  return true;
});

gantt.attachEvent("onBeforeTaskDelete", function(id, task) {
  if (task.type === gantt.config.types.button) {
    innerObject.settings.buttonIdToDelete = id;
  }

  return true;
});

gantt.attachEvent("onTaskClick", function(id, e) {
  const isExpandButton = e.target.closest('.gantt_tree_icon_item');
  var elClass = e.target.className;
  var taskClickData = gantt.getTask(id);

  var cell = e.target || e.srcElement;

  if(isExpandButton && massChangeModel.isEnabled()){
    massChangeModel.updateFiltredTasksForGantt();

    if (taskClickData.open && massChangeModel._isTaskSelected(taskClickData.id) && gantt.getChildren(taskClickData.id).length > 0) {
      massChangeModel.unselectTask(taskClickData.id, true);
      massChangeModel.selectTask(taskClickData.id, true);
    }

    return;
  }

  if (
    massChangeModel.isEnabled() &&
    !innerObject.handlers.helpers.isTaskCellMcSelMenuIcon(cell) &&
    !innerObject.handlers.helpers.isTaskExpandCollapseCell(cell) &&
    innerObject.handlers.helpers.isTaskAvailForMassChange(taskClickData)
  ) {
    // const { id } = taskClickData;

    // massChangeModel.selUnselTask(id, !massChangeModel._isTaskSelected(id), true);
      if(massChangeModel._isTaskSelected(taskClickData.id)) {
        massChangeModel.unselectTask(taskClickData.id, true);
        userExtAnalytics.log('masschange_unselect_task', {from: 'grid'});  
      } else {
        massChangeModel.selectTask(taskClickData.id, true);
        userExtAnalytics.log('masschange_select_task', {from: 'grid'}); 
      }

    return;
  }

  if (taskClickData.type !== gantt.config.types.button) {
    return;
  }

  if (elClass === "fa fa-plus") {
    if (!gantt.getParent(taskClickData.parent)) {
      innerObject.handlers.addNewEntityPrepareData(gantt.config.types.project, taskClickData.parent);
      return false;
    }

    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.task, taskClickData.parent);
    return false;
  }

  if (elClass === "project-control__add-task") {
    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.task, taskClickData.parent);
    return false;
  }

  if (elClass === "project-control__add_milestone") {
    innerObject.handlers.addNewEntityPrepareData(gantt.config.types.milestone, taskClickData.parent);
    return false;
  }

  return true;
});


var innerObject = {
  init: {
    run: function() {

    },
    beforeInit: function(dataProcessor) {
      innerObject.init.initOnceGanttDP(dataProcessor);
    },
    initOnceGanttDP: function(dataProcessor) {
      if (this.dp) {
        return this.dp;
      }

      this.dp = dataProcessor;

      this.dp.attachEvent("onBeforeDataSending", function(id, state, data) {
        if (state === 'deleted') {
          if (innerObject.settings.buttonIdToDelete === parseInt(id, 10)) { //after delete we not have some information about task
            return false;
          }
        }

        return true;
      });

      this.dp.attachEvent("onAfterUpdate", function(id, action, tid, response) {
        var taskData = {};
        var linkData = {};

        if (action === "inserted") {
          taskData = gantt.isTaskExists(tid) && gantt.getTask(tid);
          linkData = gantt.isLinkExists(tid) && gantt.getLink(tid);

          if (taskData) {
            if (taskData.type === gantt.config.types.project) {
              innerObject.handlers.helpers.actionsAfterCreateNewProject(tid, taskData);
            } else {
              innerObject.handlers.helpers.actionsAfterCreateNewTask(taskData);
            }
          }

          if (linkData) {
            globalStore.dispatch('tasksModel/updateTask', {
              taskChanges: gantt.getTask(linkData.target),
              taskId: gantt.getTask(linkData.target).id,
              ganttId: gantt.config.gantt_id
            });
            globalStore.dispatch('tasksModel/updateTask', {
              taskChanges: gantt.getTask(linkData.source),
              taskId: gantt.getTask(linkData.source).id,
              ganttId: gantt.config.gantt_id
            });
          }
        }
      });

      return this.dp;
    }
  },
  handlers: {
    deleteCustomButtonFromParent: function(parentTask, indexWhenPlaceButton) {
      var buttonTaskId = _.find(gantt.getChildren(parentTask.id), tsk => gantt.getTask(tsk).type === gantt.config.types.button);
      var buttonTask = buttonTaskId && gantt.isTaskExists(buttonTaskId) && gantt.getTask(buttonTaskId);

      if (buttonTask && buttonTask.type === gantt.config.types.button) {
        innerObject.settings.buttonIdToDelete = buttonTaskId;
        gantt.deleteTask(buttonTaskId);
      }
    },
    addCustomButtonInParent: function(ParentTask, indexButton) {
      if (gantt.config.show_advanced_buttons) {
        if (gantt.getChildCount(ParentTask.id) === 1 || !gantt.hasChild(ParentTask.id)) {
          gantt.addTask({
            unscheduled: true,
            auto_scheduling: false,
            type: gantt.config.types.button,
            gantt_id: ParentTask.gantt_id,
            sortorder: 10000
          }, ParentTask.id, indexButton);
        }
      }
    },
    addNewEntityPrepareData: function(typeEntity, parentId, taskName) {
      var task = {};
      var taskId;

      const parentTask = gantt.getTask(parentId);
      const ganttID = parentTask.gantt_id;

      task.type = typeEntity;
      task.text = taskName && taskName.trim() || __("gantt_new_sibling_task");
      task.created_at = new Date();

      if (task.type === gantt.config.types.project) {
        task.text = __("gantt_new_project");
        task.open = 1;
      } else {
        _.assign(task, globalStore.getters['tasksModel/getDefaultCustomValues'](ganttID));
      }

      if (task.type === gantt.config.types.milestone) {
        task.text = taskName && taskName.trim() || __("gantt_new_sibling_milestone");
      }

      taskId = innerObject.handlers.helpers.addNewEntity(task, parentId);

      app.trigger("keyboard:enable");
    },
    addCustomButtonToProject: function(innerGanttTasks) {
      const ganttTasks = innerGanttTasks || ganttViewModel.getTasks();
      const ganttTasksWithButtons = webix.copy(ganttTasks);

      if (gantt.config.show_advanced_buttons && app.config.mode.isBase) {
        const button = {
          unscheduled: true,
          auto_scheduling: false,
          text: '',
          sortorder: 10000,
          type: gantt.config.types.button,
        };

        _.each(ganttTasks.data, task => {
          if (
            (!task.parent || (task.parent && task.type === gantt.config.types.project))
          ) {
            const hasCreateTasksRight = rights.project.hasRight(task.gantt_id, 'create_task');

            !(gantt.config.multiview && task.id === 1) && hasCreateTasksRight && ganttTasksWithButtons.data.push({
              ...button,
              parent: task.id,
              calendar_id: task.gantt_id,
            });
          }
        });
      }

      return ganttTasksWithButtons;
    },
    ganttSerializeWithoutCustomButtons: function() {
      var ganttData = {
        data: [],
        links: []
      };

      gantt.eachTask(function(task) {
        if ((task.type !== gantt.config.types.button && !task.resource) || task.taskType === "import") {
          ganttData.data.push(task);
        }
      });

      ganttData.links = gantt.getLinks();

      return webix.copy(ganttData);
    },
    helpers: {
      // {Object} -> {Boolean}
      isTaskAvailForMassChange: ({ type }) => (['task', 'milestone', 'project'].indexOf(type) !== -1),
      // {Object} -> {Boolean}
      isTaskCellMcSelMenuIcon: (cell) => (cell.nodeName === 'svg' && cell.classList.contains('mc-item-arrow')) ||
        (cell.nodeName === 'path' && cell.parentNode.nodeName === 'svg' && cell.parentNode.classList.contains('mc-item-arrow')),
      // {Object} -> {Boolean}
      isTaskExpandCollapseCell: (cell) =>
        (
          cell.classList.contains('gantt_tree_icon') 
          || ( cell.classList.contains('gantt_tree_icon_item')
              && (cell.closest('.gantt_tree_icon') || cell.closest('.gantt_tree_icon'))
              ) 
          || ( cell.nodeName === 'svg' && cell.parentNode.classList.contains("gantt_tree_icon_item") ) 
          || (cell.nodeName === 'path' 
              && cell.parentNode.nodeName === 'svg' 
              && cell.parentNode.parentNode.classList.contains("gantt_tree_icon_item"))
        ) ? true : false,
      calculateStarDateForTask: function(taskData, parentId, startDateFromCalendar) {
        var startDate = {};
        var calcStartData = startDateFromCalendar || new Date();
        const parentTask = gantt.getTask(parentId);
        const projectStartData = parentTask.start_date;
        const projectCalendar = gantt.getCalendar(parentTask.gantt_id);

        if (projectStartData.valueOf() > calcStartData.valueOf()) {
          startDate = projectStartData;
        } else {
          startDate = calcStartData;
        }

        if (startDateFromCalendar) {
          startDate = startDateFromCalendar;
        }

        if (!gantt.isWorkDay(startDate, projectCalendar)) {
          startDate = gantt.getClosestWorkTime({date: startDate, task: parentTask, dir: "future", unit: gantt.config.duration_unit});
        }

        if (taskData.type === gantt.config.types.task || taskData.type === gantt.config.types.milestone) {
          var workRange = gantt.getDayHours(startDate);
          var dayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), workRange.firstHour);

          startDate = gantt.getClosestWorkTime({date: dayStart, task: parentTask, dir: "future"});

          if (projectStartData.valueOf() > startDate.valueOf() && !startDateFromCalendar) {
            startDate = gantt.getClosestWorkTime({
              date: gantt.date.add(startDate, 1, "day"),
              task: parentTask,
              dir: "future"
            });
          }
        }

        return startDate;
      },
      calculateSortOrderForTask: function(parentId) {
        var childrenLength = 0;
        var sortOrder = 0;

        var children = _.filter(_.map(gantt.getChildren(parentId), (it) => gantt.getTask(it)), fit => (fit.type !== "button"));

        childrenLength = (children && children.length) || 0;
        sortOrder = (children && children.length) ? (+children[children.length - 1].sortorder + 1) : 1;

        return {
          index: childrenLength,
          sortorder: sortOrder
        };
      },
      addNewEntity: function(item, parentId) {
        const parentData = gantt.getTask(parentId);

        item.start_date = this.calculateStarDateForTask(item, parentId);
        item.estimation = 0;
        item.owner_id = globalStore.getters['resourcesModel/getResourceIdByUserId'](user.id);

        if (item.type === gantt.config.types.task) {
          const endDate =  gantt.calculateEndDate({
            start_date: item.start_date,
            duration: 1,
            unit: gantt.config.durationData.mode,
            task: parentData
          });

          const calculatedDuration = gantt.calculateDuration(item.start_date, endDate, parentData);

          item.end_date = gantt.calculateEndDate({
            start_date: item.start_date,
            duration: calculatedDuration,
            unit: gantt.config.duration_unit,
            task: parentData
          });

          item.duration = calculatedDuration;
          item.calendar_id = parentData.calendar_id;
        }

        const sortOrderAndIndex = this.calculateSortOrderForTask(parentId);

        item.sortorder = sortOrderAndIndex.sortorder;

        const id = gantt.createTask(webix.copy(item), parentId, sortOrderAndIndex.index);

        globalStore.dispatch('tasksModel/backgroundUpdateTask', {taskData: parentData});

        if (!parentData.open) {
          gantt.blockEvents();
          gantt.open(parentId);
          gantt.unblockEvents();

          parentData.open = 1;
        }

        return id;
      },
      actionsAfterCreateNewProject: function(taskId, taskData) {
        if (!taskData.import) {
          innerObject.handlers.addNewEntityPrepareData(gantt.config.types.task, taskId);
        }
        innerObject.handlers.addCustomButtonInParent({ id: taskId }, 2);
        gantt.callEvent('tasks:correctDates', [true]);

        userExtAnalytics.log('gantt_add_task_grid_done', {
          type: 'subproject',
          way: 'button'
        });
      },
      actionsAfterCreateNewTask: function(taskData) {
        // if (gantt.config.isJira) {
          // status.handlers.showMainSpinner();
        // }

        gantt.callEvent("onAfterTaskAddAndOtherActions", [taskData.id, taskData]);
      }
    }
  },
  settings: {
    addNewProject: false,
    progressType: "",
    ganttId: 0,
    buttonIdToDelete: 0,
    oldParentId: 0
  }
};

app.on('createNewTask', function(parent, newName) {
  innerObject.handlers.addNewEntityPrepareData(gantt.config.creatingMilestoneFlag ? gantt.config.types.milestone : gantt.config.types.task, parent, newName);
  gantt.config.creatingMilestoneFlag = false;
});

var outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit
  },
  handlers: {
    ganttSerializeWithoutCustomButtons: innerObject.handlers.ganttSerializeWithoutCustomButtons,
    addCustomButtonToProject: innerObject.handlers.addCustomButtonToProject
  },
  helpers: {
    calculateSortOrderForTask: function (parentID) {
      return innerObject.handlers.helpers.calculateSortOrderForTask(parentID);
    },
    calculateStarDateForTask: function(taskData, parentID, startDateFromCalendar = null) {
      return innerObject.handlers.helpers.calculateStarDateForTask(taskData, parentID, startDateFromCalendar);
    }
  }
};

export default outputObject;

