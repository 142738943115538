import app from '../../../app';
import projectComponent from '../../Project';
import globalStore from "../../../store/main";

function onTaskLinkDeleted(event) {
  event.innerSettings.collaborationItems.forEach(linkToAction => {
    if (projectComponent.userHasProject(linkToAction.gantt_id)) {
      globalStore.commit('tasksModel/addLink', linkToAction);
      app.trigger('tasks:model:addLink', linkToAction.id, linkToAction);
    }
  });
}

export default onTaskLinkDeleted;
