import moment from '../../../libs/moment';
import BaseFilter from './BaseFilter';
import BaseProperties from './BaseProperties';
import globalStore from '../../../store/main';
import app from '../../../app';


const _self = null;

class CostsFilter extends BaseFilter {
  static filterByType(type) {
    if (!_self) {
      this._self = new CostsFilter(type);
    }
    return this._self;
  }

  config() {
    return [
      BaseProperties.taskName('task_name'),
      BaseProperties.projects('gantt_id'),
      BaseProperties.resources('resource_id'),
      BaseProperties.dateRange('cost_range'),
    ];
  }

  init() {
    super.init();
    this.resetIfnotFoundTasks = false;
    if (!this.isActiveFilter) {
      this.setFilterState({ cost_range: { period: 'this_month' }}, true);
    }
    this.addEventsHandler();
  }

  filterData() {
    const dataTable = $$('groupByTableTree');
    dataTable && super.filterData(dataTable);
  }

  changeFilterState(filterState) {
    this.setFilterState(filterState);
    this.filterData();
    this.updateDataOnHeader();
  }

  selectSavedFilter(filter) {
    this.changeFilter(filter.id, filter.value);
    this.filterData();
  }

  resetFilter() {
    super.resetFilter();
    this.filterData();
    this.updateDataOnHeader();
  }

  refreshFilter() {
    this.changeFilterState(this.filterState);
  }

  updateDataOnHeader() {
    let range = null;
    if ('cost_range' in this.filterState) {
      const filterRange = this.filterState.cost_range;

      if (filterRange) {
        range = (filterRange.end ? '' : (__('from_date') + ' ')) +
          moment(filterRange.start).format('D MMM, YYYY') +
          (filterRange.end ? (' - ' + moment(filterRange.end).format('D MMM, YYYY')) : '');
      }
    }
    globalStore.commit('headerView/setReportToolbarInfo', {
      range,
      total: null,
      total_locale: null,
      title_locale: 'reports_costs_list_title',
    });
  }

  addEventsHandler(){
    let event_name = 'filter:data:costslist';
    app.on(event_name, () => {
      this.filterData();
      this.updateDataOnHeader();
    })
  }
}

export default CostsFilter;
