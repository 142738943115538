<template>
  <div
    v-if="!isEditMode || (isEditMode && getEditItemId !== roleId)"
    :class="$style.role_icon"
  >
    <svg-sprite
      :name="getIcon.name"
      size="24"
      :color="getIcon.color"
      type="bold"
    />
  </div>
  <div v-else-if="isEditMode && getEditItemId === roleId && checkRoleBillingAccess">
    <square-toggle
      :id="'gp_autotest_' + type + '_role_table_item_toggle_' + category.title "
      :is-toggle-on="getSwitchStatus.status"
      :is-disabled="getSwitchStatus.disabled"
      v-on="getSwitchStatus.disabled ? {} : { onChange: () => changeSwitch(!getSwitchStatus.status) }"
    />
  </div>
</template>

<script>

import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import SquareToggle from '$$vueCmp/checkbox/squareToggle.vue';
import rightsComponent from '../../../../components/rights';

export default {
  name: 'RoleItem',
  components: {
    svgSprite,
    SquareToggle,
  },
  props: {
    rights: {
      type: [],
      required: false,
      default: () => {},
    },

    category: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    reverse: {
      type: Boolean,
      required: true,
      default: false,
    },

    roleId: {
      type: Number,
      required: true,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: 'account',
    },
  },
  data() {
    return {
      itemId: 0,
      status: false, // need for change data in store
    };
  },

  computed: {
    isEditMode() {
      return this.$store.getters['roles/getEditModeStatus'];
    },
    getEditItemId() {
      return this.$store.getters['roles/getEditModeData']?.roleId;
    },

    checkRoleBillingAccess() {
      return !['billing'].includes(this.category.title);
    },

    getIcon() {
      let icon = {
        name: 'close-4',
        color: '#B2B2B2',
      };
      let arrayOfBits = [];

      // get all bit current rightId
      this.rights.forEach(item => {
        if (item.rightId === this.category?.id) {
          arrayOfBits = [...arrayOfBits, item];
        }
      });

      if (arrayOfBits.every(e => e.status)) {
        icon = {
          name: 'check',
          color: '#1565C0',
        };
      }

      return icon;
    },

    accessToAccountSettings() {
      return rightsComponent.account.checkRightByArrayOfBits(['account_roles_create', 'account_roles_update', 'account_roles_delete']);
    },

    getSwitchStatus() {
      let status = false;
      let disabled = false;
      let arrayOfBits = [];

      // get all bit current rightId
      this.rights.forEach(item => {
        if (item.rightId === this.category?.id) {
          arrayOfBits = [...arrayOfBits, item];
        }
      });

      // if current right doesn't have child (parent_right === 0)
      if (this.reverse && arrayOfBits.every(e => e.status)) {
        status = true;
      } else if (this.reverse) {
        status = false;
      } else if (this.category.parentRight === 0 && arrayOfBits.every(e => e.status)) {
        status = true;
      } else if (this.category.parentRight !== 0 && this.checkParentRight(this.category.parentRight)) {
        status = arrayOfBits.every(e => e.status);
      } else if (this.category.parentRight !== 0 && !this.checkParentRight(this.category.parentRight)) {
        status = false;
        disabled = true;
      }

      if (this.category.id === 11) {
        if (this.getEditItemId === rightsComponent.account.getCurrentUserRole().id) {
          status = this.accessToAccountSettings;
          disabled = true;
        }
      }

      return { status, disabled };
    },
  },

  methods: {
    changeSwitch(status) {
      this.$store.dispatch('roles/setBitStatus', {
        type: this.type,
        roleId: this.roleId,
        rightId: this.category.id,
        parentRightId: this.category.parentRight,
        reverse: this.reverse,
        status,
      });
    },

    checkParentRight(parentId) {
      let arrayOfBits = [];

      // check if the parent bits off or on
      this.rights.forEach(item => {
        if (item.rightId === parentId) {
          arrayOfBits = [...arrayOfBits, item];
        }
      });

      return arrayOfBits.every(e => e.status);
    },
  },

};
</script>

<style module src="../../less/role.less"></style>
