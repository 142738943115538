var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style["skeleton_loading"],
        _vm.isCalendar ? _vm.$style["calendar_loading"] : "",
      ],
    },
    [_vm._t("body")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }