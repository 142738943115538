var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upgrade-thank-popup" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("webix-ui", { staticClass: "pic", attrs: { config: _vm.pic } }),
        _vm._v(" "),
        _c("p", { staticClass: "main-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.locales("upgrade_thank_title")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("upgrade_thank_subtitle")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("global-btn", {
          attrs: {
            config: {
              isGlobal: true,
              width: "180px",
              size: "normal",
              color: "blue",
              locale: _vm.locales("common_ok"),
              handler: _vm.onClickOk,
            },
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "support-link",
          domProps: { innerHTML: _vm._s(_vm.locales("contact_support_link")) },
          on: { click: _vm.chatClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }