<template>
  <div
    id="role_wrapper"
    :key="'role-project-table-' + allroles.length"
    :class="[$style.core]"
  >
    <div
      id="role_description"
      :class="[$style.role_description]"
    >
      {{ locale(`proj_rs_head_description`) }}
    </div>
    <role-create
      type="project"
      :data="defaultRoles"
      :select-list="defaultRoles"
      :access-to-create="checkRoleCreateAccess"
    />
    <div :class="[$style.view, sizeForShadow ? null : $style.without_scroll]">
      <div
        ref="scrollable"
        :class="[$style.wrapper, 'gantt_scroll']"
        :style="setHeightOfTable"
      >
        <table :class="[$style.table, $style.projects_rights_gradient]">
          <thead>
            <!-- title -->
            <tr>
              <th :class="[$style.sticky_col, $style.first_col_main , sizeForShadow ? $style.shadow : '']">
                {{ locale(`proj_rs_title_permission`) }}
              </th>
              <th
                v-for="role in allroles"
                :class="[$style.sticky_col, $style.first_top]"
              >
                <div :class="[]">
                  <role-title
                    :item-id="role.id"
                    :title="role.title"
                    :default-type="role.defaultType"
                    :access-to-edit="checkRoleEditAccess"
                    :access-to-delete="checkRoleDeleteAccess"
                    :access-to-edit-and-delete="checkRoleEditDeleteAccess"
                    type="project"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            v-for="item in rights"
            :key="item.title"
          >
            <tr :class="$style.test">
              <td :class="[$style.sticky_col, $style.first_col]">
                <div :class="$style.title_group">
                  <span>{{ locale(`proj_${item.title}_group`) }}</span>
                </div>
              </td>
              <td :colspan="$store.getters['roles/getProjectRoles'].length" />
            </tr>
            <tr
              v-for="child in item.children"
              :key="child.title"
            >
              <td :class="[$style.sticky_col, $style.first_col, $style.border]">
                <div :class="[$style.parent_info, `box_${child.title}`]">
                  <div :class="$style.icon">
                    <svg-sprite
                      :name="ICONS.PROJECT[child.title]"
                      size="24"
                      type="regular"
                    />
                  </div>
                  <div :class="$style.parent_description">
                    <div v-html="locale(`proj_rs_${child.title}_title`)" />
                    <div v-html="locale(`proj_rs_${child.title}_description`)" />
                  </div>
                </div>
                <div
                  v-if="child.children.length"
                  style="margin-left: 12px;"
                  :class="$style.children_wrap"
                >
                  <div
                    v-for="subcat in child.children"
                    :class="$style.children_info"
                  >
                    <div :class="$style.icon">
                      <svg-sprite
                        :name="ICONS.PROJECT[subcat.title]"
                        size="24"
                        type="regular"
                      />
                    </div>
                    <div :class="[$style.children_description, `box_${subcat.title}`]">
                      <div v-html="locale(`proj_rs_${subcat.title}_title`)" />
                      <div v-html="locale(`proj_rs_${subcat.title}_description`)" />
                    </div>
                  </div>
                </div>
              </td>
              <td
                v-for="role in $store.getters['roles/getProjectRoles']"
                :class="[child.children.length ? $style.border_left2 : $style.border_left]"
              >
                <div
                  :class="$style.roles_parent_info"
                  :style="setHeight(child.title)"
                >
                  <role-item
                    :rights="role.rights"
                    :role-id="role.id"
                    :category="child"
                    :reverse="checkReverse(child.title)"
                    type="project"
                  />
                </div>
                <div
                  v-if="child.children.length"
                  :class="$style.children_role_wrap"
                >
                  <div
                    v-for="subcat in child.children"
                    :class="$style.children_info"
                    :style="setHeight(subcat.title)"
                  >
                    <role-item
                      :rights="role.rights"
                      :role-id="role.id"
                      :category="subcat"
                      :reverse="checkReverse(subcat.title)"
                      type="project"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <core-popup
      v-if="isShowForm"
      id="gp_autotest_project_role_popup_save_before_leave"
      :close-icon="true"
      :show="isShowForm"
      :title="locale('proj_rs_save_edit_role_before_leave_title')"
      @onClickCancel="togglePopup"
    >
      <template
        #body
      >
        <div :class="[$style.add_form]">
          <div :class="[$style.form_line]">
            <p>{{ locale('proj_rs_leave_description') }}</p>
          </div>
          <div :class="[$style.form_line_buttons]">
            <vgp-button
              id="gp_autotest_project_role_popup_save_before_leave_btn_cancel"
              type="secondary"
              :small="true"
              :label="locale('proj_rs_create_cancel_btn')"
              @onClick="cancelRoleEdit"
            />
            <vgp-button
              id="gp_autotest_project_role_popup_save_before_leave_btn_ok"
              type="primary"
              :small="true"
              :label="locale('proj_rs_create_ok_btn')"
              @onClick="confirmRoleEdit"
            />
          </div>
        </div>
      </template>
    </core-popup>
  </div>
</template>

<script>

import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import roleTitle from '../include/roles/roleTitle.vue';
import roleCreate from '../include/roles/roleCreate.vue';
import roleItem from '../include/roles/roleItem.vue';
import corePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import { internalApi } from '$$store/api';
import ICONS from '../include/roles/iconLibrary';
import rightsComponent from '../../../components/rights';

export default {
  components: {
    roleTitle,
    svgSprite,
    corePopup,
    roleCreate,
    roleItem,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditMode) {
      this.isShowForm = true;
      this.leaveRoute = true;
    } else {
      next();
    }
  },
  data() {
    return {
      locale: __,
      rights: [],
      roles: [],
      parentWidth: 1000,
      tableHeight: 52,
      isShowForm: false,
      statusesData: null,
      firstStatus: null,
      reverse: ['static_fields', 'static_fields_4_items'], // which fields are reverse
      ICONS,
    };
  },

  computed: {
    setHeightOfTable() {
      const data = {
        maxHeight: `calc(100vh - ${200 + this.tableHeight}px)`,
      };

      return data;
    },

    allroles() {
      const roles = this.$store.getters['roles/getProjectRoles'];
      // it's crutch. We should fix this on the future
      const member_edit = roles[3].defaultType === 'MEMBER_EDIT' ? roles[3] : roles[2];
      const member = roles[2].defaultType === 'MEMBER' ? roles[2] : roles[3];

      roles.splice(2, 2, { ...member_edit }, { ...member });

      return roles;
    },
    sizeForShadow() {
      const countStore = this.$store.getters['roles/getProjectRoles'].length;

      return this.parentWidth - 40 < 515 + 146 * countStore;
    },

    defaultRoles() {
      let roles = [
        ...this.$store.getters['roles/getProjectRoles'].filter(item => !['OWNER'].includes(item.defaultType)),
      ];

      roles = roles.map(item => {
        const copy = { ...item };

        if (copy.defaultType) {
          copy.description = this.locale(`project_${copy.title.toLowerCase()}_description`);
          copy.title = this.locale(`project_${copy.title.toLowerCase()}`);
        } else {
          copy.description = this.locale('project_custom_role_description');
        }

        return copy;
      });

      return roles;
    },

    isEditMode() {
      return this.$store.getters['roles/getEditModeStatus'];
    },
    defaultRolesSelect() {
      return [
        ...this.$store.getters['roles/getProjectRoles'].filter(item => !['OWNER'].includes(item.defaultType)),
      ];
    },
    checkRoleCreateAccess() {
      return rightsComponent.account.hasRight('project_roles_create');
    },
    checkRoleEditAccess() {
      return rightsComponent.account.hasRight('project_roles_update');
    },
    checkRoleDeleteAccess() {
      return rightsComponent.account.hasRight('project_roles_delete');
    },
    checkRoleEditDeleteAccess() {
      return rightsComponent.account.checkRightByArrayOfBits(['project_roles_update', 'project_roles_delete']);
    },
  },

  mounted() {
    internalApi.get('/roles/project/rights', {
      params: {
        order: true,
      },
    }).then(response => {
      this.rights = response.data;
    }).then(() => {
      const roleDiv = document.querySelector('#role_wrapper');

      this.parentWidth = roleDiv ? roleDiv.offsetWidth : 0;
    });

    const elemBlockCreation = document.querySelector('#role_create_item');
    const elemBlockDescription = document.querySelector('#role_description');

    if (elemBlockCreation && elemBlockDescription) {
      this.tableHeight = elemBlockCreation.getBoundingClientRect().height = elemBlockDescription.getBoundingClientRect().height;
    }
  },
  methods: {
    togglePopup() {
      if (this.leaveRoute) {
        this.isShowForm = true;
        this.$store.dispatch('roles/resetEdit');
        this.$router.replace(this.$router.history.pending.fullPath);
      }
      this.isShowForm = !this.isShowForm;
    },

    setHeight(selector) {
      const elem = document.querySelector(`.box_${selector}`);

      return {
        height: elem ? `${elem.getBoundingClientRect().height}px` : '44px',
      };
    },

    cancelRoleEdit() {
      this.togglePopup();
      this.$store.dispatch('roles/resetEdit');
      if (this.$router.history.pending) this.$router.replace(this.$router.history.pending.fullPath);
    },

    confirmRoleEdit() {
      this.$store.dispatch('roles/updateRole').then(() => {
        this.$toast.success(this.locale('proj_rs_success_update'));
        if (this.$router.history.pending) this.$router.replace(this.$router.history.pending.fullPath);
      }).catch(e => {
        console.error(e);
        this.$toast.error(this.locale('proj_rs_error_update'));
      });
    },
    checkReverse(title) {
      return this.reverse.includes(title);
    },
  },

};
</script>

<style module src="../less/role.less"></style>
