var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: { id: "gp_autotest_invite_people_popup", show: _vm.isShow },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.popup.invite_popup }, [
              _c("div", { class: _vm.popup.title }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("invite_popup_title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.popup.invite_popup_input_area },
                [
                  _c("vgp-label-slot", {
                    attrs: {
                      label: _vm.locales("common_emails"),
                      action:
                        _vm.invitationsCount >= 0
                          ? _vm.locales("invite_popup_count_of_invitations", {
                              count:
                                _vm.invitationsCount > 0
                                  ? _vm.invitationsCount
                                  : 0,
                            })
                          : _vm.locales(
                              "invite_popup_count_of_invitations_overage",
                              { count: _vm.invitationsCount * -1 }
                            ),
                      actionStyle:
                        _vm.invitationsCount < 0 ? { color: "#DD3636" } : {},
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("multiple-input", {
                              attrs: {
                                placeholder: _vm.locales("common_enter_emails"),
                                validation: _vm.validationRules,
                                items: _vm.emails,
                                classes: [
                                  _vm.invitationsCount < 0 ? "is-error" : "",
                                  "invite-input",
                                ],
                                "is-error": _vm.inputError,
                              },
                              on: {
                                setItems: _vm.setEmails,
                                checkTag: _vm.onCheckValue,
                                isInput: function (flag) {
                                  return (_vm.isInput = flag)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.inputError && _vm.isEmailError && _vm.invitationsCount > 0
                    ? _c(
                        "div",
                        {
                          class: _vm.popup.alert_message,
                          attrs: {
                            id: "gp_autotest_invite_people_popupe_message_error_email",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("invite_popup_alert_message_email")
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inputError &&
                  _vm.isAlreadyInTeam &&
                  _vm.invitationsCount > 0
                    ? _c(
                        "div",
                        {
                          class: _vm.popup.alert_message,
                          attrs: {
                            id: "gp_autotest_invite_people_popupe_message_error_alreadyInTeam",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("permission_error_alreadyIsTeam")
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inputError &&
                  _vm.isAlreadyInEmails &&
                  _vm.invitationsCount > 0
                    ? _c(
                        "div",
                        {
                          class: _vm.popup.alert_message,
                          attrs: {
                            id: "gp_autotest_invite_people_popupe_message_error_alreadyInvited",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("permission_error_alreadyIsInvites")
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.invitationsCount < 0
                    ? _c(
                        "div",
                        {
                          class: _vm.popup.alert_message,
                          attrs: {
                            id: "gp_autotest_invite_people_popupe_message_error_outOfInvitations",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.locales("invite_popup_alert_message")
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.popup.invite_popup_settings }, [
                _c(
                  "div",
                  { class: _vm.popup.settings__role_dropdown },
                  [
                    _c("vgp-label-slot", {
                      attrs: { label: _vm.locales("common_account_role") },
                      scopedSlots: _vm._u([
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("dropdown-select", {
                                attrs: {
                                  disabled: !_vm.accessToChangeAccountRole,
                                  "gp-autotest-name":
                                    "gp_autotest_invite_people_popup_dropdown_select_account_role",
                                  "drop-list": _vm.dropdownRoles,
                                  multiselect: false,
                                  "show-selected-first": false,
                                  "show-reset-btn": false,
                                  "highlight-active-state": false,
                                  "item-template": "text",
                                  "track-by-text": "title",
                                  "track-by-item-tooltip": "tooltip",
                                  outline: "always",
                                  "fixed-width": 320,
                                  size: "autoWidth",
                                  "need-description": true,
                                  selected: _vm.inviteRole,
                                },
                                on: { selected: _vm.selectRole },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.popup.settings__project_dropdown },
                  [
                    _c("vgp-label-slot", {
                      attrs: {
                        label: _vm.locales("common_invite_projects_dropdown"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("dropdown-select", {
                                attrs: {
                                  "drop-list": _vm.projects,
                                  "gp-autotest-name":
                                    "gp_autotest_invite_people_popup_dropdown_select_projects",
                                  multiselect: true,
                                  "use-select-all": true,
                                  "use-search": true,
                                  "show-selected-first": false,
                                  "show-reset-btn": false,
                                  "highlight-active-state": false,
                                  "item-template": "text",
                                  outline: "always",
                                  "popup-max-height": 180,
                                  placeholder: _vm.locales(
                                    "create_resource_projects_drop_placeholder"
                                  ),
                                },
                                on: {
                                  selected: _vm.selectProjects,
                                  change: _vm.onChangeSelected,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.popup.invite_popup_footer }, [
                _vm.isOwner
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.popup.invite_upgrade_message,
                          _vm.invitationsCount < 0 && !_vm.isSuspended
                            ? _vm.popup.invite_upgrade_message__show
                            : "",
                        ],
                        attrs: {
                          id: "gp_autotest_invite_people_popupe_message_warning_upgrade_invite",
                        },
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.popup.invite_upgrade_message__wrapper },
                          [
                            _c("div", { class: _vm.popup.description }, [
                              _c(
                                "div",
                                [
                                  _c("svg-sprite", {
                                    attrs: {
                                      name: "warning-1",
                                      color: "#FF9A00",
                                      type: "bold",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { class: _vm.popup.text }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.locales("invite_popup_out_licences")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("vgp-button", {
                              class: _vm.popup.btn,
                              attrs: {
                                id: "gp_autotest_invite_people_popup_btn_upgrade_invites",
                                type: "text",
                                small: true,
                                label: _vm.locales("invites_add_more"),
                              },
                              on: {
                                onClick: function ($event) {
                                  return _vm.$emit(
                                    "upgradePlanShowFromResourcesForce"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.popup.invite_buttons },
                  [
                    _c("vgp-button", {
                      class: _vm.popup.invite_buttons__cancel,
                      attrs: {
                        id: "gp_autotest_invite_people_popup_btn_cancel",
                        label: _vm.locales("common_cancel"),
                        type: "secondary",
                        small: true,
                      },
                      on: { onClick: _vm.onClickCancel },
                    }),
                    _vm._v(" "),
                    _c("vgp-button", {
                      staticClass: "apply",
                      attrs: {
                        id: "gp_autotest_invite_people_popup_btn_invite",
                        label: _vm.locales("send_invite_btn"),
                        type: "primary",
                        small: true,
                        disabled:
                          !_vm.emails.length ||
                          _vm.inputError ||
                          _vm.invitationsCount < 0 ||
                          _vm.isInput,
                      },
                      on: { onClick: _vm.onSendInvites },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }