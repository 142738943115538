var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.select,
    },
    [
      _c("tooltip", {
        class: _vm.$style.tooltip_wrapper,
        attrs: {
          content: _vm.locales("header_toolbar_view_tooltip"),
          position: "bottom-end",
          hide: _vm.showMenu,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("vgp-icon-button", {
                  ref: "contextMenuButton",
                  attrs: {
                    icon: {
                      name: _vm.showMenu ? "arrow-top" : "arrow-down",
                      size: 20,
                    },
                    label: _vm.locales("view_settings"),
                    "icon-side": "right",
                    colors: _vm.buttonColors,
                    active: _vm.showMenu,
                  },
                  on: { onClick: _vm.toggle },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.showMenu
        ? _c("context-menu", {
            staticStyle: { "min-width": "230px", overflow: "visible" },
            attrs: { position: _vm.positionContext1 },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._l(_vm.items, function (item, index) {
                        return [
                          item.type === "select" || item.type === "multiselect"
                            ? _c(
                                "div",
                                {
                                  key: index,
                                  class: [
                                    _vm.$style.menu_item,
                                    _vm.$style.item_level,
                                    _vm.showSelect === item.name
                                      ? _vm.$style.active
                                      : "",
                                  ],
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.enterSelect(
                                        item.name,
                                        $event.target
                                      )
                                    },
                                    mouseleave: _vm.leaveSelect,
                                  },
                                },
                                [
                                  _c("svg-sprite", {
                                    class: _vm.$style.icon,
                                    attrs: {
                                      name: "drop-left",
                                      type: "bold",
                                      size: "24",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { class: _vm.$style.text }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.text) +
                                        "\n          "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.showSelect === item.name && item.list
                                    ? _c("context-menu", {
                                        style: {
                                          "min-width": item.icons
                                            ? "unset"
                                            : "",
                                          "border-top-right-radius": 0,
                                          "border-bottom-right-radius": 0,
                                        },
                                        attrs: {
                                          position: _vm.positionContext2,
                                          side: "left",
                                          offset: { top: -8 },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  item.type === "select"
                                                    ? [
                                                        item.icons
                                                          ? _c(
                                                              "div",
                                                              {
                                                                class:
                                                                  _vm.$style
                                                                    .row,
                                                              },
                                                              _vm._l(
                                                                item.list,
                                                                function (
                                                                  item2,
                                                                  index2
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: index2,
                                                                      class: [
                                                                        _vm
                                                                          .$style
                                                                          .row_item,
                                                                        item.value ===
                                                                        item2.value
                                                                          ? _vm
                                                                              .$style
                                                                              .active
                                                                          : "",
                                                                      ],
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onSelect(
                                                                              item,
                                                                              item2
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg-sprite",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: item2.config,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._l(
                                                              item.list,
                                                              function (
                                                                item2,
                                                                index2
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index2,
                                                                    class: [
                                                                      _vm.$style
                                                                        .menu_item,
                                                                      item.value ===
                                                                      item2.value
                                                                        ? _vm
                                                                            .$style
                                                                            .active
                                                                        : "",
                                                                    ],
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.onSelect(
                                                                            item,
                                                                            item2
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .$style
                                                                            .text,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      " +
                                                                            _vm._s(
                                                                              item2.text
                                                                            ) +
                                                                            "\n                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.type === "multiselect"
                                                    ? _vm._l(
                                                        item.list,
                                                        function (
                                                          item2,
                                                          index2
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index2,
                                                              class: [
                                                                _vm.$style
                                                                  .menu_item,
                                                                _vm.$style
                                                                  .item_check,
                                                                item.value.includes(
                                                                  item2.value
                                                                )
                                                                  ? _vm.$style
                                                                      .active
                                                                  : "",
                                                              ],
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onSelect(
                                                                      item,
                                                                      item2
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class:
                                                                    _vm.$style
                                                                      .checkbox,
                                                                },
                                                                [
                                                                  _c(
                                                                    "vgp-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        size: "16",
                                                                        checked:
                                                                          item.value.includes(
                                                                            item2.value
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  class:
                                                                    _vm.$style
                                                                      .text,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        item2.text
                                                                      ) +
                                                                      "\n                  "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "checkbox"
                            ? _c(
                                "div",
                                {
                                  key: index,
                                  class: [
                                    _vm.$style.menu_item,
                                    _vm.$style.item_check,
                                    item.value ? _vm.$style.active : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelect(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.$style.checkbox },
                                    [
                                      _c("vgp-checkbox", {
                                        attrs: {
                                          size: "16",
                                          checked: item.value,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { class: _vm.$style.text }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.text) +
                                        "\n          "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.name === "divider"
                            ? _c("div", {
                                key: index,
                                class: _vm.$style.divider,
                              })
                            : _vm._e(),
                        ]
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              952808244
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }