var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vue-container" } },
    [
      _c("stub"),
      _vm._v(" "),
      _c(
        "v-app",
        [
          _c(
            "div",
            { staticClass: "page-wrapper", style: _vm.windowParams },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _c("appFilter"),
          _vm._v(" "),
          _c("massChangePopup"),
          _vm._v(" "),
          _c("reload-modal"),
          _vm._v(" "),
          _vm.isShowGlobalPopup ? _c("global-popup") : _vm._e(),
          _vm._v(" "),
          _vm.isShowPassStrengthPopup ? _c("pass-strength-popup") : _vm._e(),
          _vm._v(" "),
          _vm.isShowPaymentPage ? _c("payment-plans") : _vm._e(),
          _vm._v(" "),
          _vm.isShowStudentsPage ? _c("students-page") : _vm._e(),
          _vm._v(" "),
          _c("progress-bar"),
          _vm._v(" "),
          _c("app-gallery"),
          _vm._v(" "),
          _vm.isShowPricingPopup
            ? _c("tooltip-popup", { attrs: { config: _vm.getConfig } })
            : _vm._e(),
          _vm._v(" "),
          _c("copyPastePopup"),
          _vm._v(" "),
          _c("newProjectFromTemplatePopup"),
          _vm._v(" "),
          _c("currency-popup"),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "popup" } }),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "confirm-popup" } }),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "right-popup" } }),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "task-settings" } }),
          _vm._v(" "),
          _c("vue-gallery"),
          _vm._v(" "),
          _c("maintenance-notification"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }