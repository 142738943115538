<template>
  <div
    class="confirm-popup"
    :style="{maxWidth: config.width ? config.width + 'px' : 420 + 'px'}"
  >
    <div class="body">
      <div
        class="popup-text confirm-message"
        v-html="config.message"
      />
    </div>
    <div
      :class="['footer', config.footerText ? 'no-border' : '']"
    >
      <div :class="['footer__btns', (config.ok && !config.cancel) || (!config.ok && config.cancel) ? 'alone-btn' : '']">
        <div
          v-if="config.ok"
          class="popup-btn confirm blue-btn"
          @click="confirmClick"
        >
          {{ config.ok }}
        </div>
        <div
          v-if="config.cancel"
          class="popup-btn confirm gray"
          @click="closeClick"
        >
          {{ config.cancel }}
        </div>
      </div>
      <div
        v-if="config.footerText"
        class="footer__link"
      >
        <p
          v-html="config.footerText"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['config'],
  data() {
    return {};
  },
  methods: {
    closeClick() {
      this.$store.dispatch('globalPopup/close');
    },
    confirmClick() {
      this.$store.dispatch('globalPopup/confirm');
    },
  },
};
</script>

<style scoped></style>
<style lang='less'>
.confirm-message,
.footer__link {
  a {
    color: #257ECC;

    &:hover {
      text-decoration: underline !important;
    }
  }
}
</style>
