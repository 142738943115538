var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "background-color": "transparent" },
      attrs: { id: "vue-gallery" },
    },
    [
      _c("LightBox", {
        ref: "lightbox",
        attrs: { "show-caption": true, "show-light-box": _vm.isShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }