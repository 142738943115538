<template>
  <div class="container_export">
    <div class="header">
      <div
        class="header-close header-button"
        @click="closeExport"
      >
        <div class="header-close-icon" />
      </div>
      <div class="header-right">
        <div
          v-once
          class="header-title"
          v-html="title"
        />
        <div
          class="header-hide-bar header-button"
          @mousedown="() => { return false }"
          @click="rightBarToggle"
          @dblclick="rightBarToggle"
        >
          <div
            class="header-hide-bar-icon"
            v-html="minimizeIcon"
          />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="gantt">
        <component
          :is="iframe"
          v-show="showFrame"
        />
        <component
          :is="paperWithSvg"
          v-show="!showFrame"
          :export-type="exportType"
        />
      </div>
      <div class="right-bar">
        <component
          :is="rightBar"
          :show-frame="showFrame"
          @show-frame-toggle="frameToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import minimizeIcon from '../../../svg/history/historylist_minimize.svg';
import _ from '../../../libs/lodash';
import routerHelper from '../../../helpers/router';
import exportComponent from '../../../components/Export';

export default {
  name: 'LayoutHeaderRightBar',
  props: ['title', 'rightBar', 'iframe', 'paperWithSvg'],
  data() {
    return {
      minimizeIcon: `<div class="header-hide-bar-icon">${minimizeIcon}</div>`,
      showFrame: true,
      rightBarShow: true,
      exportType: 'pdf',
    };
  },
  created() {
    const leftSideBar = $$('leftSideBar');
    const footer = $$('footer');

    leftSideBar.$view.classList.add('left_sidebar_down_index');
    footer.$view.classList.add('footer_down_index');
  },
  methods: {
    frameToggle(options) {
      this.exportType = options.exportType;
      this.showFrame = options.showFrame;
    },
    closeExport() {
      const routerName = gantt.config.multiview
        ? 'projectsMultiView'
        : 'project';

      this.$router.push({
        name: routerName,
        params: _.assign(this.$route.params, { mode: 'gantt' }),
      });
    },
    rightBarToggle() {
      const component = this;
      const rightBarNode = document.querySelector('.right-bar');
      const hideButton = document.querySelector('.header-hide-bar');
      
      // const exportFrame = $$('exportFrame');
      // const exportFrameWidth = exportFrame.$width;
      // const exportFrameNode = exportFrame.getNode();
      // const newExportFrameWidth = component.rightBarShow
      //   ? exportFrameWidth + 300
      //   : exportFrameWidth;

      // if (exportFrameNode) {
      //   exportFrameNode.style.width = `${newExportFrameWidth}px`;
      // }

      if (!component.rightBarShow) {
        webix.html.removeCss(hideButton, 'rotate');
        webix.html.removeCss(rightBarNode, 'collapsed');

        exportComponent.sendMessageToExportFrame({ message: 'closeFilterPopup', isOpen: true })
        component.rightBarShow = !component.rightBarShow;
        this.userAnalyticsOpenCloseFilter('filter_close');
      } else {
        // _.delay(() => {
        //   webix.html.addCss(hideButton, 'rotate');
        //   webix.html.addCss(rightBarNode, 'collapsed');

        //   component.rightBarShow = !component.rightBarShow;
        // }, 400);
        webix.html.addCss(hideButton, 'rotate');
        webix.html.addCss(rightBarNode, 'collapsed');

        exportComponent.sendMessageToExportFrame({ message: 'closeFilterPopup', isOpen: false })
        component.rightBarShow = !component.rightBarShow;
        
        this.userAnalyticsOpenCloseFilter('filter_open');
      }
    },
    userAnalyticsOpenCloseFilter(event_name) {
      let from = '';
      let route = '';

      const url = routerHelper.getCurrentRoute().path;
      const urlArr = url.slice(1).split('/');
      const urlItems = urlArr.filter(item => !Number.parseInt(item));

      switch (urlItems[0]) {
      case 'project':
        from = 'project & portfolio';
        route = 'Project/export';
        break;
      case 'projects':
        from = 'project & portfolio';
        route = 'Portfolio/export';
        break;
      default:
        from = urlItems[0];
        route = url;
      }

      userExtAnalytics.log(event_name, { from, route });
    },
  },
};
</script>

<style src="./style.less" scoped></style>
