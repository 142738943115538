import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const TasksStore = {
  namespaced: true,
  state: {
    data: [
      {
        id: 0,
        customValues: {},
        resourcesToTasks: {},
        tasks: [],
        links: [],
        is_archived: false,
        workloadData: {
          durationData: {},
          estimation_mode: 1,
          apply_resource_calendar: true,
        },
      },
    ],
  },
  getters,
  mutations,
  actions,
};

export default TasksStore;
