var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: _vm.hideEditor,
            include: _vm.includeClickOutside,
            closeConditional: function () {
              return _vm.editorShown && !_vm.isCommentEditing
            },
          },
          expression:
            "{\n    handler: hideEditor,\n    include: includeClickOutside,\n    closeConditional: () => editorShown && !isCommentEditing\n  }",
        },
      ],
      class: [
        _vm.$style["task-settings-comment-editor"],
        _vm.$style[_vm.commentHub ? "fixed-footer" : "footer-section"],
      ],
      on: { click: _vm.clickOnEditor },
    },
    [
      _vm.showScrollToBottomButton && _vm.commentHub
        ? _c(
            "div",
            {
              class: _vm.$style["counter-section"],
              on: {
                click: function ($event) {
                  return _vm.$emit("scrollToBottom")
                },
              },
            },
            [
              _c("div", { class: _vm.$style["counter-item"] }, [
                _vm.unreadCommentIds.length && !_vm.mention
                  ? _c("div", { class: _vm.$style["counter-number"] }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.unreadCommentIds.length) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.mention && _vm.unreadCommentIds.length
                  ? _c("div", { class: _vm.$style["counter-number"] }, [
                      _vm._v("\n        @\n      "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.$style["counter-item-icon"] },
                  [
                    _c("svg-sprite", {
                      attrs: { name: "arrow-down", type: "bold", size: 24 },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.editorShown
        ? _c(
            "div",
            {
              class: [
                "vgp-interactive-element",
                _vm.$style["placeholder-container"],
                _vm.isFieldDisabled("comments")
                  ? _vm.$style["placeholder-container_disabled"]
                  : "",
              ],
            },
            [
              !_vm.isFieldDisabled("comments")
                ? [
                    _c("img", {
                      class: _vm.$style["author-avatar"],
                      attrs: { src: _vm.userPhoto, alt: "Author photo" },
                    }),
                    _vm._v(" "),
                    _c("span", { on: { click: _vm.activateEditor } }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.locales("settings_new_comment_placeholder")
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]
                : [
                    _c("vgp-svg-image", {
                      style: { alignItems: "unset" },
                      attrs: { name: "not-allowed" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.locales(
                              "settings_new_comment_placeholder_disabled"
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                  ],
            ],
            2
          )
        : _c("vgp-quill-editor", {
            ref: "commentsEditor",
            class: [_vm.$style["quill-container"], "footer-quill-editor"],
            attrs: {
              value: _vm.currentValue,
              active: _vm.quillEditorActive,
              "submit-button-label": _vm.currentEditComment
                ? _vm.locales("common_save")
                : _vm.locales("common_send"),
              "mention-items-callback": _vm.getMentionItems,
              "upload-image-callback": _vm.addImageToTask,
              "font-size": 16,
              "max-height": 212,
              "focus-on-init": _vm.focusOnInit,
              "toolbar-list-options": ["ordered", "bullet"],
              "draft-blur": _vm.draftBlur,
            },
            on: {
              cancel: _vm.handleCancelButtonClick,
              submit: _vm.handleSaveButtonClick,
              focus: _vm.handleFocus,
              blur: _vm.handleBlur,
              imageUpload: function ($event) {
                _vm.isCommentEditing = $event
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }