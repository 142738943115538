var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.overview_charts },
    [
      _vm.isShowStub
        ? _c(
            "div",
            { class: _vm.$.stub },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "273",
                    height: "257",
                    viewBox: "0 0 273 257",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("rect", {
                    attrs: { width: "273", height: "257", fill: "white" },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M45.0132 151.341C44.2713 149.368 43.5921 147.374 42.9761 145.364M38.7642 122.683C38.3972 117.436 38.4372 112.154 38.8907 106.884C40.1892 91.7942 44.8453 77.1854 52.5182 64.1268C60.1911 51.0683 70.6866 39.8906 83.2368 31.4117C95.7869 22.9327 110.074 17.3671 125.053 15.1222C140.031 12.8773 155.322 14.0098 169.807 18.4368L155.492 65.2729C147.99 62.9799 140.07 62.3934 132.312 63.5561C124.554 64.7189 117.154 67.6015 110.654 71.993C104.154 76.3846 98.7175 82.174 94.7434 88.9375C90.7694 95.701 88.3578 103.267 87.6852 111.083C87.0127 118.899 88.0962 126.766 90.8563 134.109C93.6165 141.452 97.9836 148.085 103.638 153.523C109.292 158.96 116.091 163.065 123.536 165.536C130.982 168.007 138.885 168.782 146.669 167.804L152.772 216.397C137.744 218.285 122.484 216.788 108.11 212.017C93.7347 207.246 80.6084 199.322 69.6913 188.824C60.669 180.147 53.3444 169.895 48.0654 158.595",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M238.867 139.441C244.491 116.149 241.702 91.6201 230.993 70.1861C220.284 48.7521 202.343 31.793 180.342 22.3049L160.948 67.2763C172.344 72.1905 181.636 80.9743 187.182 92.0758C192.729 103.177 194.174 115.882 191.261 127.945L238.867 139.441Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M163.463 214.469C179.919 210.582 195.145 202.65 207.76 191.391C220.375 180.132 229.98 165.902 235.705 149.992L189.623 133.41C186.658 141.651 181.683 149.021 175.149 154.852C168.615 160.684 160.729 164.792 152.206 166.805L163.463 214.469Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M193.035 31.9563L174.134 42.8246L204.376 39.5168L164.211 57.4731L218.552 46.6048L174.134 73.5392L228.948 57.4731C212.724 66.1362 180.655 83.4624 182.167 83.4624C183.679 83.4624 224.065 78.107 244.069 75.4293L187.838 96.2207L234.146 90.5503L187.838 111.342L234.146 100.001C219.655 106.144 191.145 118.335 193.035 117.957C194.926 117.579 238.556 110.239 260.135 106.616L206.266 129.298L234.146 125.518",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M47.4952 134.968C47.4952 141.754 43.0524 147.254 37.572 147.254C32.0916 147.254 25.2861 141.754 25.2861 134.968C25.2861 128.183 32.0916 122.683 37.572 122.683C43.0524 122.683 47.4952 128.183 47.4952 134.968Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M43 135C43 138.866 40.3994 142 37.1915 142C33.9835 142 30 138.866 30 135C30 131.134 33.9835 128 37.1915 128C40.3994 128 43 131.134 43 135Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M57.8917 198.288C57.8917 205.073 53.4489 210.574 47.9685 210.574C42.488 210.574 35.6826 205.073 35.6826 198.288C35.6826 191.503 42.488 186.002 47.9685 186.002C53.4489 186.002 57.8917 191.503 57.8917 198.288Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M52 198.5C52 202.09 49.7995 205 47.0851 205C44.3707 205 41 202.09 41 198.5C41 194.91 44.3707 192 47.0851 192C49.7995 192 52 194.91 52 198.5Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M13 212.936L57.4181 126.463L63.0885 125.045L23.8683 212.936H13Z",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M200.143 237.697L89.5498 234.2L175.813 242H112.996",
                      stroke: "#5C5C5C",
                      "stroke-width": "1.23",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$.stub_text }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales("overview_stub_text")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("vgp-button", {
                class: _vm.$.stub_button,
                attrs: {
                  type: "primary",
                  label: _vm.locales("overview_manage_widgets_button"),
                },
                on: {
                  onClick: function ($event) {
                    return _vm.$emit("openManageModal")
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { class: _vm.$.wrap },
            [
              _vm._l(_vm.charts, function (chart) {
                return [
                  _vm.getChartComponent(chart.key)
                    ? _c(
                        "div",
                        {
                          key: chart.id,
                          class: [
                            _vm.$.item,
                            chart.x_size === 2 ? _vm.$.large : "",
                          ],
                        },
                        [
                          _c(_vm.getChartComponent(chart.key), {
                            tag: "component",
                            attrs: {
                              chart: chart,
                              data: _vm.getChartData(chart.key),
                            },
                            on: { onOpenDescription: _vm.openDescriptionModal },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _vm.isShowModal
        ? _c("description-modal", {
            attrs: {
              show: _vm.isShowModal,
              description: _vm.projectData.description,
            },
            on: {
              onCancel: function ($event) {
                _vm.isShowModal = false
              },
              onSave: _vm.saveDescription,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }