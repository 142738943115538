<template>
  <core-popup
    id="gp_autotest_invite_to_project_popup"
    ref="corePopup"
    :show="isShow"
    :title="locales(`${layout === 'people' ? 'invite_to_project_title' : 'create_for_project_title'}`)"
  >
    <template #body>
      <div :class="$.invite_to_project_popup">
        <vgp-tabs
          gp-autotest-name="gp_autotest_invite_to_project_tabs"
          :class="$.tabs"
          :tabs="tabs"
          active-by="type"
          :active="tabsActive"
          @onToggle="onToggleTab"
        />
        <div :class="$.content">
          <div
            v-if="activeTab === 'team'"
            :class="$.items_with_checkbox"
          >
            <div :class="$.items_with_checkbox__search">
              <search
                gp-autotest-name="gp_autotest_invite_to_project_popup_input_search_by_name"
                :placeholder="locales('search_by_name_only')"
                @onInput="onSearch"
              />
            </div>
            <div
              v-if="resources.length"
              :class="$.items_with_checkbox__table"
            >
              <div :class="$.select_btn">
                <vgp-button
                  id="gp_autotest_invite_to_project_popup_btn_select_all"
                  :label="visibleSelectedLength === resources.length
                    ? locales('multicombo_unselect_all_items')
                    : locales('multicombo_select_all_items')"
                  type="text"
                  :small="true"
                  @onClick="onToggleSelectAll"
                />
              </div>
              <div :class="$.labels">
                <div>
                  {{ locales(`${layout === 'people'
                    ? 'people_popup_names_label'
                    : 'people_popup_material_names_label'}`) }}
                </div>
                <div
                  v-if="layout === 'people'"
                  :class="$.labels__roles"
                >
                  {{ locales('people_popup_roles_label') }}
                </div>
              </div>
              <div :class="[$.list, 'gantt_scroll']">
                <div
                  v-for="(item, index) in resources"
                  :key="index"
                  :class="$.list__row"
                >
                  <div
                    :class="$.info"
                    @click="onClickRow(item)"
                  >
                    <vgp-checkbox
                      :key="item.key"
                      :class="$.checkbox"
                      class="gp_autotest_invite_to_project_popup_table_row_checkboxes"
                      :style="{'pointer-events': 'none'}"
                      :checked="item.isChecked"
                      :size="16"
                    />
                    <vgp-avatar
                      :class="$.avatar"
                      size="small"
                      :src="item.photo"
                    />
                    <div :class="$.name">
                      {{ item.name }}
                    </div>
                  </div>
                  <div
                    v-if="item.userId"
                    :class="[$.dropdown, item.isOwner || item.isKing || !projectRoleChangeAccess ? $.disabled : '']"
                  >
                    <dropdown-select
                      class="gp_autotest_invite_to_project_dropdown_select_rights_in_project"
                      :gp-autotest-name="'gp_autotest_invite_to_project_dropdown_select_rights_in_project_' + item.id"
                      :drop-list="dropdownRoles"
                      :multiselect="false"
                      :show-selected-first="false"
                      :show-reset-btn="false"
                      :highlight-active-state="false"
                      item-template="text"
                      track-by-text="title"
                      :outline="item.isOwner || item.isKing ? 'none' : 'hover'"
                      :disabled="item.isOwner || item.isKing || !projectRoleChangeAccess"
                      drop-position="fixed"
                      :fixed-width="320"
                      :size="'autoWidth'"
                      :need-description="true"
                      parent-selector-for-calc-position-drop=".core_popup_block"
                      track-by-item-tooltip="tooltip"
                      :selected="getProjectRole(item)"
                      @selected="$event => onSelectResourceProjectRole($event, item, index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              :class="$.items_with_checkbox__stub"
            >
              <div :class="$.wrap">
                <div
                  :class="$.img"
                  v-html="searchValue !== '' ? searchStub : stub"
                />
                <div :class="$.desc">
                  {{ searchValue !== ''
                    ? locales('people_project_popup_no_search_result')
                    : locales('project_popup_no_resources_description') }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activeTab === 'invite'"
            :class="$.resources_invite"
          >
            <div :class="$.resources_invite__input_slot">
              <vgp-label-slot
                :label="locales('common_emails')"
                :action="locales('invite_popup_count_of_invitations', { count: invitationsCount > 0 ? invitationsCount : 0 })"
              >
                <template #body>
                  <multiple-input
                    :placeholder="locales('common_enter_emails')"
                    :validation="validationRules"
                    :classes="[invitationsCount <= 0 ? 'alert-border' : '']"
                    :is-error="inputError"
                    @setItems="setEmails"
                    @checkTag="onCheckValue"
                    @isInput="flag => isInput = flag"
                  />
                </template>
              </vgp-label-slot>
            </div>
            <div
              :class="$.resources_invite__error_message"
            >
              <!--              :style="{height: inputError && invitationsCount > 0 ? '18px' : invitationsCount <= 0 ? '36px' : 0}"-->
              <div
                v-if="inputError && isEmailError && invitationsCount > 0"
                id="gp_autotest_invite_to_project_popup_message_error_email"
              >
                {{ locales('invite_popup_alert_message_email') }}
              </div>
              <div
                v-if="inputError && isAlreadyInTeam && invitationsCount > 0"
                id="gp_autotest_invite_to_project_popup_message_error_alreadyInTeam"
              >
                {{ locales('permission_error_alreadyIsTeam') }}
              </div>
              <div
                v-if="inputError && isAlreadyInEmails && invitationsCount > 0"
                id="gp_autotest_invite_to_project_popup_message_error_emailTaken"
              >
                {{ locales('permission_error_alreadyIsInvites') }}
              </div>
              <div
                v-if="invitationsCount <= 0"
                id="gp_autotest_invite_to_project_popup_message_error_outOfInvitations"
              >
                {{ locales('invite_popup_alert_message') }}
              </div>
            </div>
            <div :class="$.invite_area__dropdown_slot">
              <vgp-label-slot
                :label="locales('invite_to_project_role_dropdown_label')"
                :description="locales('invite_to_project_role_dropdown_description', { default_role: getDefaultAccountRoleTitle })"
              >
                <template #body>
                  <dropdown-select
                    gp-autotest-name="gp_autotest_invite_to_project_dropdown_select_rights_invited_people"
                    :drop-list="dropdownRoles"
                    :multiselect="false"
                    :show-selected-first="false"
                    :show-reset-btn="false"
                    :highlight-active-state="false"
                    :disabled="!projectRoleChangeAccess || defaultAccountRoleIsKing"
                    :fixed-width="320"
                    :need-description="true"
                    :size="'autoWidth'"
                    item-template="text"
                    track-by-text="title"
                    outline="always"
                    :selected="dropdownRoles.find(i => i.id === inviteRoleId)"
                    @selected="onSelectInviteRole"
                  />
                </template>
              </vgp-label-slot>
            </div>
          </div>
          <div
            v-if="activeTab === 'create'"
            :class="$.resources_create"
          >
            <div :class="$.resources_create__name_slot">
              <vgp-label-slot :label="locales('add_resource_name_label')">
                <template #body>
                  <vgp-input
                    :placeholder="locales('common_placeholder_name')"
                    type="text"
                    :small="true"
                    @onChange="inputChange"
                    @input="debouncedValidateName"
                  />
                  <div
                    v-if="isExistResourceName"
                    id="gp_autotest_invite_to_project_popup_message_warning_alreadyExist"
                    :class="$.warning_message"
                  >
                    <sprite
                      :name="'warning-1'"
                      :type="'bold'"
                      :size="20"
                    />
                    <p :class="$.warning_message_text">
                      {{ locales('create_popup_exist_warning') }}
                    </p>
                  </div>
                </template>
              </vgp-label-slot>
            </div>
            <div :class="$.resources_create__color_slot">
              <vgp-label-slot :label="locales('common_color')">
                <template #body>
                  <color-palette
                    select-type="single"
                    select-field="select"
                    :selected-values="[17]"
                    :colors="colors"
                    :select-width="72"
                    :select-height="32"
                    @selectColors="getColor"
                  />
                </template>
              </vgp-label-slot>
            </div>
          </div>
        </div>
        <div :class="[$.footer, activeTab === 'team' ? $.with_shadow : '']">
          <vgp-button
            id="gp_autotest_invite_to_project_popup_btn_cancel"
            :label="locales('common_cancel')"
            type="secondary"
            :small="true"
            :style="{marginRight: '16px'}"
            @onClick="onClose"
          />
          <vgp-button
            id="gp_autotest_invite_to_project_popup_btn_add"
            :label="activeTab === 'invite' ? locales('send_invite_btn') : locales('common_add')"
            type="primary"
            :small="true"
            :disabled="disableButton"
            @onClick="onApply"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import validationRules from './inviteValidations';
import VgpAvatar from '../../../common/VueComponents/avatar/vgpAvatar.vue';
import corePopup from '../../../common/VueComponents/popups/corePopup/corePopup.vue';
import VgpTabs from '../../../common/VueComponents/tabs/vgpTabs.vue';
import search from '../inputSearch/inputSearch.vue';
import VgpButton from '../../../common/VueComponents/globalButton/vgpButton.vue';
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import VgpLabelSlot from '../../../common/VueComponents/label/vgpLabelSlot.vue';
import MultipleInput from '../../../common/VueComponents/vInput/multipleInput.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import ColorPalette from '$$vueCmp/colorPalette/colorPalette.vue';
import searchStub from '../../../../svg/search_no_result_pic.svg';
import stub from '../../../../svg/resources/empty_resources.svg';
import sprite from '$$vueCmp/VueIcon/svgSprite.vue';
import ssoToastVue from '../toastComponents/ssoToast.vue';
import app from '../../../../app';

export default {
  name: 'InviteToProjectPopup',
  components: {
    corePopup,
    VgpTabs,
    search,
    VgpAvatar,
    VgpButton,
    DropdownSelect,
    VgpLabelSlot,
    MultipleInput,
    VgpInput,
    ColorPalette,
    sprite,
  },
  inject: ['checkExistResourceName'],
  props: {
    isShow: { type: Boolean, required: false, default: false },
    resourcesFromOtherProjects: { type: Array, required: true, default: () => [] },
    allResources: { type: Array, required: true, default: () => [] },
    layout: { type: String, required: true, default: 'people' },
    projectRoles: { type: Array, required: true, default: () => [] },
    accountRoles: { type: Array, required: true, default: () => [] },
    createAndInviteResourcesAccess: { type: Boolean, required: false, default: false },
    projectRoleChangeAccess: { type: Boolean, required: false, default: false },
  },
  emits: {
    onClose: Boolean,
    addResourcesToProject: Object,
    onSendInvites: Object,
    onCreateResource: Object,
  },
  data() {
    return {
      locales: __,
      activeTab: 'team',
      emails: [],
      resources: [],
      selected: [],
      validationRules: [],
      inviteRoleId: '',
      searchValue: '',
      colorId: 17,
      newResourceName: '',
      isNameError: true,
      isInput: false,
      isExistResourceName: false,
      inputError: false,
      isEmailError: false,
      isAlreadyInTeam: false,
      isAlreadyInEmails: false,
      debouncedValidateName: null,
      searchStub,
      stub,
    };
  },
  computed: {
    invitationsCount() {
      const realResourcesLength = this.$store.getters['resourcesModel/getAllRealResources'].length;

      return user.team.invitations - realResourcesLength - this.emails.length;
    },
    colors() {
      return this.$store.getters['teamSetting/colors'].data;
    },
    disableButton() {
      let flag = true;

      if (this.activeTab === 'invite') {
        if (this.emails.length && !this.inputError && !this.isInput && this.invitationsCount > -1) {
          flag = false;
        }
      }

      if (this.activeTab === 'create') {
        flag = this.isNameError;
      }

      if (this.activeTab === 'team') {
        if (this.selected.length) {
          flag = false;
        }
      }

      return flag;
    },
    tabs() {
      const tabs = [
        {
          title: __('choose_member_tab'),
          type: 'team',
        },
      ];

      if (this.layout === 'people') {
        if (this.createAndInviteResourcesAccess) {
          tabs.push({
            title: __('invite_member_tab'),
            type: 'invite',
          });
        }
      }

      if (this.layout === 'material') {
        if (this.createAndInviteResourcesAccess) {
          tabs.push({
            title: __('create_resource_tab'),
            type: 'create',
          });
        }
      }

      return tabs;
    },
    tabsActive() {
      return this.tabs.find(t => t.type === this.activeTab) || this.tabs[0];
    },
    accountMaterialResources() {
      return this.allResources.filter(resource => !resource.user_id);
    },
    dropdownRoles() {
      const roles = this.projectRoles.map(item => {
        const role = { ...item };

        role.tooltip = { content: item?.title };

        return role;
      });

      return roles;
    },
    getDefaultAccountRole() {
      return this.accountRoles.find(role => role.isDefault);
    },
    getDefaultAccountRoleTitle() {
      return this.getDefaultAccountRole.title;
    },
    defaultAccountRoleIsKing() {
      return this.getDefaultAccountRole.rights.find(bitObj => bitObj.bit === 2)?.status;
    },
    visibleSelectedLength() {
      const visibleSelected = this.selected.filter(sel => this.resources.find(r => r.id === sel.id));

      return visibleSelected.length;
    },
  },
  watch: {
    resourcesFromOtherProjects(newItems) {
      this.prepareItemsWithSearch(newItems, true);
    },
    projectRoles(newRoles) {
      this.resources.forEach(res => {
        const hasRole = newRoles.find(role => role.id === res.roleId);
        const defaultRoleId = this.getDefaultProjectRole().id;

        if (!hasRole) {
          res.key = (Math.random() + 1).toString(36).substring(2);
          res.roleId = defaultRoleId;
        }
      });

      this.inviteRoleId = this.getDefaultProjectRole().id;
    },
    selected() {
      this.prepareItemsWithSearch(this.resources, false);
    },
    defaultAccountRoleIsKing() {
      this.inviteRoleId = this.getDefaultProjectRole().id;
    },
  },
  created() {
    this.prepareItemsWithSearch(this.resourcesFromOtherProjects, true);
    this.debouncedValidateName = _.debounce(this.onCheckName, 200).bind(this);
    this.inviteRoleId = this.getDefaultProjectRole().id;
    this.validationRules = validationRules;
  },
  beforeCreate() {
    app.on('onAfterCollaboration', event => {
      if (this.isShow && event.event === 'ResourceDeleted') {
        event.resources.forEach(resourceId => {
          const isSelectedIndex = this.selected.indexOf(this.selected.find(s => s.id === resourceId));

          if (isSelectedIndex !== -1) {
            this.selected.splice(isSelectedIndex, 1);
          }
        });
      }
    });
  },
  methods: {
    getProjectRole(resource) {
      if (resource.isOwner || resource.isKing) {
        return this.dropdownRoles.find(role => role.defaultType === 'ADMIN');
      }

      return this.dropdownRoles.find(role => role.id === resource.projectRoleId);
    },
    getDefaultProjectRole() {
      const memberRole = this.projectRoles.find(role => role.defaultType === 'MEMBER');
      const defaultProjectRole = this.projectRoles.find(role => role.isDefault);

      if (this.defaultAccountRoleIsKing) {
        return this.projectRoles.find(role => role.defaultType === 'ADMIN');
      }

      return defaultProjectRole || memberRole;
    },
    onSearch(value) {
      this.searchValue = value;
      this.prepareItemsWithSearch(this.resourcesFromOtherProjects, false);
    },
    prepareItemsWithSearch(resources, setDefaultRoles = false) {
      this.resources = resources.map(resource => {
        const isSelected = this.selected.find(selectedResource => selectedResource.id === resource.id);

        if (setDefaultRoles) resource.projectRoleId = this.getDefaultProjectRole().id;

        resource.key = (Math.random() + 1).toString(36).substring(2);
        resource.isChecked = !!isSelected;

        return resource;
      });

      const result = this.resources.filter(item => (item.name.toLowerCase().startsWith(this.searchValue.toLowerCase())));

      this.resources = result.sort((a, b) => a.name.localeCompare(b.name));
    },
    onClickRow(resource) {
      resource.isChecked = !resource.isChecked;

      if (resource.isChecked) {
        this.selected.push(resource);
      } else {
        const idx = this.selected.indexOf(this.selected.find(res => res.id === resource.id));

        if (idx !== -1) {
          this.selected.splice(idx, 1);
        }
      }
    },
    onToggleSelectAll() {
      if (this.visibleSelectedLength < this.resources.length) {
        this.resources.forEach(item => {
          const isSelected = this.selected.find(s => s.id === item.id);

          if (!isSelected) {
            this.selected.push(item);
          }
        });
      } else if (this.visibleSelectedLength = this.resources.length) {
        this.resources.forEach(item => {
          const idx = this.selected.indexOf(this.selected.find(res => res.id === item.id));

          if (idx !== -1) {
            this.selected.splice(idx, 1);
          }
        });
      }
    },
    onClose() {
      this.$emit('onClose');
      this.emails = [];
      this.activeTab = 'team';
      this.inviteRoleId = this.getDefaultProjectRole().id;
      this.colorId = 17;
      this.isExistResourceName = false;
      this.selected = [];
      this.searchValue = '';
      this.prepareItemsWithSearch(this.resourcesFromOtherProjects, true);
      this.inputError = false;
    },
    onToggleTab(obj) {
      if (this.activeTab === obj.type) return;

      this.activeTab = obj.type;
      this.inviteRoleId = this.getDefaultProjectRole().id;
      this.emails = [];
      this.isExistResourceName = false;
      this.selected = [];
      this.searchValue = '';
      this.prepareItemsWithSearch(this.resourcesFromOtherProjects, true);
    },
    setEmails(emails) {
      this.emails = emails.map(email => email.text);

      setTimeout(() => {
        const invalidEmails = document.querySelectorAll('.ti-tag.ti-invalid');

        if (!invalidEmails.length) {
          this.inputError = false;
          this.isEmailError = false;
          this.isAlreadyInEmails = false;
          this.isAlreadyInTeam = false;

          return;
        }

        if (invalidEmails.length) {
          const classList = invalidEmails[invalidEmails.length - 1].classList.value;

          this.inputError = true;
          if (classList.includes('invalid-email')) { this.isEmailError = true; } else { this.isEmailError = false; }
          if (classList.includes('ti-duplicate')) { this.isAlreadyInEmails = true; } else { this.isAlreadyInEmails = false; }
          if (classList.includes('has-in-team')) { this.isAlreadyInTeam = true; } else { this.isAlreadyInTeam = false; }
        }
      }, 0);
    },
    onSelectInviteRole(data) {
      this.inviteRoleId = data[0].id;
    },
    onSelectResourceProjectRole(data, resource, index) {
      const selectedResource = this.resources.find(res => res.id === resource.id);

      selectedResource.projectRoleId = data[0].id;
      this.resources.splice(index, 1, selectedResource);
    },
    inputChange(e, val) {
      this.newResourceName = val;
    },
    onCheckName(name) {
      this.isNameError = false;
      this.isExistResourceName = this.checkExistResourceName(name);

      if (name.trim().length > 255) {
        this.isNameError = true;
        this.$toast.warning(__('change_resource_name_is_long_name'));
      }

      if (name.trim() === '') {
        this.isNameError = true;
      }
    },
    getColor(data) {
      this.colorId = data[0].id;
    },
    onApply() {
      if (this.activeTab === 'team') {
        this.$emit('addResourcesToProject', {
          resources: this.selected,
          projectIds: [gantt.config.gantt_id],
        });
      }
      if (this.activeTab === 'invite') {
        this.$emit('onSendInvites', {
          emails: this.emails,
          projectRoleId: this.inviteRoleId,
          projectIds: [gantt.config.gantt_id],
        });
      }
      if (this.activeTab === 'create') {
        this.$emit('onCreateResource', {
          name: this.newResourceName,
          colorId: this.colorId,
          projectIds: [gantt.config.gantt_id],
        });
      }

      this.onClose();
    },
    onCheckValue(obj) {
      if (obj.tag.tiClasses.includes('sso-email')) {
        this.$toast.warning(ssoToastVue);

        return;
      }

      if (obj.tag.tiClasses.includes('invalid-email')) {
        this.$toast.warning(__('permission_error_invalidEmail'));

        return;
      }

      if (obj.tag.tiClasses.includes('ti-duplicate')) {
        this.$toast.warning(__('permission_error_alreadyIsInvites'));

        return;
      }

      if (obj.tag.tiClasses.includes('has-in-team')) {
        this.$toast.warning(__('permission_error_alreadyIsTeam'));
      }
    },
  },
};
</script>

<style module="$" src="./popups.less"></style>
