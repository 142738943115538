var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("context-menu", {
    style: { padding: 0 },
    attrs: {
      position: _vm.position,
      "horizontally-align-center": _vm.alignToCenter,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "dependency-preview__wrapper" }, [
              _c("div", { staticClass: "dependency-preview__header" }, [
                _c("span", { staticClass: "dependency-preview__header_name" }, [
                  _vm._v(_vm._s(_vm.name) + " "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "dependency-preview__header_description" },
                  [_vm._v(_vm._s(_vm.description))]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dependency-preview__body" }, [
                _c(
                  "div",
                  {
                    staticClass: "dependency-preview__predecessor",
                    style: _vm.predecessorStyles,
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "dependency-preview__item-name" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.predecessorName) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "dependency-preview__predecessor_filled",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "dependency-preview__arrow",
                  style: _vm.arrowStyles,
                  domProps: { innerHTML: _vm._s(_vm.arrowIcon) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dependency-preview__successor",
                    style: _vm.successorStyles,
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "dependency-preview__item-name" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.successorName) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "dependency-preview__successor_filled",
                    }),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }