let _getDomainFromEmail = (email)=>{
  return email.split('@')[1];
};

let _isEmailMatchesDomainOrEmail = (email, mask)=>{
  if(mask === '*') {return true;}
  if(email.toLowerCase() === mask.toLowerCase()) {return true;}
  if(_getDomainFromEmail(email) === mask) {return true;}
  return false;
};

function checkSSOForced(sso) {
  return sso && sso.enabled && sso.force_team_login_only_sso;
}

function checkSSOEmailWhitelisted(email, sso) {
  if(!checkSSOForced(sso)) return true;
  let isWhitelisted = false;

  let whitelist = (sso.sso_excluded_emails || '').split(',');
  let blacklis = (sso.sso_domains || '').split(',');

  // blacklis.map((mask)=>{
  //   if(_isEmailMatchesDomainOrEmail(email, mask)){
  //     isWhitelisted = false;
  //   }
  // });
  
  // whitelist.map((mask)=>{
  //   if(_isEmailMatchesDomainOrEmail(email, mask)){
  //     isWhitelisted = true;
  //   }
  // });

  isWhitelisted = whitelist.find( mask => (_isEmailMatchesDomainOrEmail(email, mask)));
 
  return !!isWhitelisted;
}


export default {checkSSOForced, checkSSOEmailWhitelisted};

