import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const RolesStore = {
  namespaced: true,
  state: {
    editMode: {
      status: false,
      roleId: null,
      type: null,
      rights: [],
    },
    account: {
      rights: [],
      roles: [],
    },
    project: {
      rights: [],
      roles: [],
    },
  },
  getters,
  mutations,
  actions,
};

export default RolesStore;
