import app from '../../../app';
import _ from '../../../libs/lodash';

import projectsModel from '../../../models/projects';
import constants from '../../../helpers/constants';
import routerHelper from '../../../helpers/router';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from '../../../store/main';

import workloadCore from './workloadCore';

const __ = window.__;

const _state = {
  overloadedResources: {},
  overloadedTasks: [],
};

const _helpers = {
  prepareProjectConfigs(ganttIds) {
    return workloadCore.prepareProjectConfigsForWorker(ganttIds);
  },
  workerRun(innerData = [], ganttIds) {
    const totalEstimate = gantt.getTaskByIndex(0) || { start_date: gantt.config.start_date, end_date: gantt.config.end_date };
    const totalStartUnit = gantt.config.durationData.mode === 'hour' ? 'day_start' : `${gantt.config.durationData.mode}_start`;

    if (!_state.worker || !totalEstimate) {
      webix.message({ type: 'error', text: __('workload_worker_shutdown_or_no_tasks'), expire: 10000 });

      return;
    }

    _state.worker.postMessage({
      innerData,
      projectConfigs: _helpers.prepareProjectConfigs(ganttIds),
      ganttConfig: {
        work_time: gantt.config.work_time,
        duration_unit: gantt.config.duration_unit,
        start_on_monday: gantt.config.start_on_monday,
      },
      totalStart: gantt.date[totalStartUnit](new Date(totalEstimate.start_date)),
      totalEnd: gantt.date.add(gantt.date[`${gantt.config.durationData.mode}_start`](new Date(totalEstimate.end_date)), 1, gantt.config.durationData.mode),
    });
  },
  prepareData(ganttIds) {
    const allProjectsResources = globalStore.getters['resourcesModel/getResourcesByProjectIdsAndType'](ganttIds, constants.RESOURCE_TIME_TYPE);
    let range = { start_date: gantt.config.start_date, end_date: gantt.config.end_date };
    const preparedData = [];

    if (!routerHelper.isWorkloadViewRoute()) {
      range = gantt.getTaskByIndex(0) || range;
    }

    allProjectsResources.forEach((res, i, array) => {
      let tasksByGanttId = globalStore.getters['tasksModel/getAllTasksByResourceId'](res.id);

      tasksByGanttId = tasksByGanttId.filter(t => {
        let startCond = false;
        let endCond = false;
        const startDate = gantt.date[`${gantt.config.durationData.mode}_start`](new Date(range.start_date));
        const endDate = gantt.date.add(gantt.date[`${gantt.config.durationData.mode}_start`](new Date(range.end_date)), 1, gantt.config.durationData.mode);

        startCond = t.taskData.start_date.valueOf() > endDate.valueOf();
        endCond = t.taskData.end_date.valueOf() < startDate.valueOf();

        return t.taskData.type !== gantt.config.types.project && !(startCond || endCond);
      });

      if (res.resourceProjects) {
        tasksByGanttId = _.filter(tasksByGanttId, task => {
          const settings = _.find(res.resourceProjects, o => +o.projectId === +task.gantt_id);

          return settings && settings.type === constants.RESOURCE_TIME_TYPE;
        });
      }

      res.tasksByGanttId = tasksByGanttId.map(task => {
        task.taskData.calendar_id = task.gantt_id;

        return task;
      });

      preparedData.push(res);
    });

    return preparedData;
  },
};

const _calculate = _.debounce(() => {
  const ganttIds = projectsModel.getAllProjects().map(o => o.gantt_id);
  const projectData = ganttViewModel.getActiveViewData();

  if (!projectData) {
    return;
  }

  _state.ganttIds = routerHelper.isWorkloadViewRoute() ? ganttIds : (projectData.ganttIDs || [projectData.gantt_id]);

  _helpers.workerRun(_helpers.prepareData(_state.ganttIds), _state.ganttIds);
}, 200);

const _initEvents = function () {
  app.on('workloadCore:start:run', fromCache => {
    const overloadColumn = gantt.config.columns.find(o => o.name === 'overload');

    if (overloadColumn && overloadColumn.hide) {
      return;
    }

    !fromCache && _calculate();
  });
};

gantt._getOverloadedResources = function ({ taskData, taskId, resourceId }) {
  const overloadColumn = gantt.config.columns.find(o => o.name === 'overload');

  if (overloadColumn && overloadColumn.hide) {
    return;
  }
  if (resourceId && taskId) {
    return _state.overloadedResources[resourceId] && _state.overloadedResources[resourceId].taskIds.find(id => id === taskId);
  }
  if (resourceId) {
    return _state.overloadedResources[resourceId];
  }
  if (taskData) {
    const overloadedResource = taskData.resources.filter(resource => _state.overloadedResources[resource.resource_id]
        && _state.overloadedResources[resource.resource_id].taskIds.find(id => id === taskData.id));

    return overloadedResource.length && overloadedResource;
  }
};

const _init = function () {
  const ganttRefreshDataDebounced = _.debounce(() => {
    let tasksIds = [];
    let beforeRefreshTaskIds = [];

    _.values(_state.overloadedResources).forEach(o => {
      tasksIds = tasksIds.concat(o.taskIds);
    });

    beforeRefreshTaskIds = _.uniq(tasksIds);

    tasksIds = _.uniq(tasksIds.concat(_state.overloadedTasks));

    if (!routerHelper.isWorkloadViewRoute()) {
      tasksIds.forEach(taskId => {
        gantt.getTaskNode(taskId)
        && gantt.getTaskNode(taskId).offsetHeight
        && gantt.isTaskExists(taskId)
        && gantt.refreshTask(taskId);
      });
    }

    _state.overloadedTasks = beforeRefreshTaskIds;

    workloadCore.refreshWorkload();
  }, 250);

  if (window.Worker) {
    let version = Date.now();

    if (GT.productVersion && GT.productVersion.buildVersion) {
      version = GT.productVersion.buildVersion;
    }

    _state.worker = new Worker('/workers/overload_worker.js' + `?${version}`);

    _state.worker.onmessage = function (e) {
      _state.overloadedResources = e.data;
      ganttRefreshDataDebounced();
    };

    _state.worker.onerror = function (e) {
      webix.message({ type: 'error', text: __('overload_calculating_error'), expire: 100000 });
    };
  }
  _initEvents();
};

export default {
  init: _init,
  calculate: _calculate,
};
