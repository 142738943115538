<template>
  <vgp-core-popup
    :show="showBudgetSettings"
    :title="locale('budget_field_editor_title')"
    :closeIcon="true"
    :buttonsSwitch="true"
    :okButtonTitle="locale('common_apply')"
    :footerStyle="'flex-end'"
    @onClickCancel="toggleBudgetPopup"
    @onClickOk="applyBudgetSettings"
  >
    <template #body>
      <div :class="$.currency_popup_wrapper">
        <inline-notification type="warning" :text="locale('warning_all_projects_setting')" />
        <div :class="$.field">
          <div :class="$.label">{{ locale('budget_currency') }}</div>
          <toggle :isToggleOn="enableCurrency" @onChange="currencySwitch" />
        </div>
        <div :class="[$.field, $.select]">
          <div :class="$.label">{{ locale('budget_currency_sign') }}</div>
          <dropdown-select :multiselect="false" :showResetBtn="false" :disabled="!enableCurrency" :dropList="currencies"
            :selected="[currentCurrency]" @selected="setSelected" />
        </div>
      </div>
    </template>
  </vgp-core-popup>
</template>

<script>
import app from '../../../../../app';
import DropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import Toggle from '$$vueCmp/checkbox/squareToggle.vue';
import InlineNotification from '$$vueCmp/nofitication/inline/InlineNotification.vue';

export default {
  name: 'CurrencyPopup',
  components: {
    DropdownSelect,
    Toggle,
    InlineNotification
  },
  beforeCreate() {
    app.on('openBudgetFieldPopup', () => {
      this.toggleBudgetPopup();
    });
  },
  beforeDestroy() {
    app.off('openBudgetFieldPopup');
  },
  data() {
    return {
      locale: __,
      showBudgetSettings: false,
      enableCurrency: false,
      currentCurrency: null,
    }
  },
  computed: {
    currencies() {
      return GT.currency.map(({ ID, VALUE}) => ({
        id: ID,
        name: `${VALUE} ${ID}`
      }));
    }
  },
  methods: {
    toggleBudgetPopup() {
      if (!this.showBudgetSettings) {
        this.enableCurrency = this.$store.getters['teamSetting/enableCurrency'];
        this.currentCurrency = this.currencies.find((item) => item.id === this.$store.getters['teamSetting/currency']) || this.currencies[0];
      }

      this.showBudgetSettings = !this.showBudgetSettings;
    },
    applyBudgetSettings() {
      userExtAnalytics.log('budget_currency_change', {
        enabled: this.enableCurrency,
        currency: this.currentCurrency.id
      });
      this.$store.dispatch('teamSetting/setCurrency', {
        currency: this.currentCurrency.id,
        isActiveCurrency: this.enableCurrency
      });

      this.toggleBudgetPopup();
    },
    currencySwitch(e) {
      this.enableCurrency = !this.enableCurrency;
    },
    setSelected(selected) {
      this.currentCurrency = selected[0];
    },
  }
}
</script>

<style module="$" src="./less/currencyPopup.less"></style>