var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("right-popup", {
    attrs: {
      show: !!_vm.taskId && !_vm.ganttInProgress,
      width: 815,
      "border-top": false,
      "exclude-footer": "",
      "exclude-header-toolbar": "",
      "portal-name": "task-settings",
      "with-animation": false,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("task-settings-main", {
              attrs: { "task-id": _vm.taskId },
              on: { close: _vm.closeTask },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }