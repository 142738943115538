<template>
  <div :class="$.switch">
    <div
      v-for="(item, index) in views"
      :key="index"
      :class="[$.switch_item, item.type === active ? $.active : '']"
      class="gp_autotes_resource_switch"
      @click="$emit('onToggleSwitch', item)"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      required: false,
    },
    emits: {
      onToggleSwitch: Object,
    },
  },
  data() {
    return {
      views: [
        {
          type: 'people',
          text: __('resources_manage_layout_header_title_1'),
        },
        {
          type: 'material',
          text: __('resources_manage_layout_header_title_2'),
        },
      ],
    };
  },
};
</script>

<style module='$' src='./switch.less' />
