<template>
  <div :class="['v-global-input', css ? css : '']">
    <label
      v-if="label"
      class="label"
    >
      {{ label }}
    </label>
    <textarea
      v-model="inputValue"
      class="text-area"
      :style="styles"
      :class="disable ? 'not_editable' : ''"
      :type="type ? type : null"
      :placeholder="placeholder"
      :readonly="disable"
      @change="onHandleChange"
      @input="onHandleInput"
    />
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'placeholder',
    'value',
    'type',
    'css',
    'handleChange',
    'handleInput',
    'isDisable',
    'styles',
  ],
  data() {
    return {

    };
  },
  computed: {
    inputValue() {
      return this.value && this.value;
    },
    disable() {
      return this.isDisable && this.isDisable;
    },
  },
  methods: {
    onHandleChange(e) {
      if (this.handleChange) {
        this.handleChange(e.target.value);
      }
    },
    onHandleInput(e) {
      if (this.handleInput) {
        this.handleInput(e.target.value);
      }
    },
  },
};
</script>

<style scoped src='./vInput.less'></style>
