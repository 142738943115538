var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "payment-plans gantt-scroll",
      style: { width: _vm.width, height: "100vh" },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { ref: "head", staticClass: "head" },
          [
            _c("div", { staticClass: "main-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.locales("plans_popup_title")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: ["period-tabs", _vm.isAnnual ? "annual" : "month"] },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab annually",
                    on: {
                      click: function ($event) {
                        return _vm.toggleAnnual(true)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "save-item",
                          _vm.isAnnual && _vm.nextCount === 1 ? "dnone" : "",
                        ],
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.locales("save_up_locale")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.locales("plans_annually_locale"))),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab monthly",
                    on: {
                      click: function ($event) {
                        return _vm.toggleAnnual(false)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("plans_monthly_locale")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("closer", { attrs: { size: 30, "on-close": _vm.close } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "body" }, [
          _c(
            "div",
            { ref: "plansSection", staticClass: "plans" },
            _vm._l(_vm.plans, function (plan, index) {
              return _c("plan-item", {
                key: index,
                attrs: {
                  plan: plan,
                  "is-annual": _vm.isAnnual,
                  "is-popup": false,
                  "number-of-plan": _vm.numberOfPlanItems,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "studentsSection",
              style: { "padding-top": "6px", "padding-bottom": "36px" },
            },
            [
              _c("div", { staticClass: "students border-wrapper" }, [
                _c("div", { staticClass: "content d-flex align-center" }, [
                  _c("p", { staticClass: "title-h2" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.locales("plans_students_title")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "content-desc",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.locales("plans_students_description")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn", on: { click: _vm.studentsClick } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.locales("learn_more")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "features",
              class: [
                "features border-wrapper",
                _vm.isShowTable ? "show-table" : "",
              ],
              on: { click: _vm.toggleFeaturesTable },
            },
            [
              _c("div", { ref: "highElem", staticClass: "high-elem" }, [
                _c("div", { staticClass: "features-title title-h2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.locales("pricing_plans_features_table_head")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: ["check-icon", _vm.isActive ? "active" : ""] },
                  [_c("webix-ui", { attrs: { config: _vm.icons.checkIcon } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "hiddenTopTable", staticClass: "hidden-top-table" },
                [
                  _c("div", { staticClass: "white-top-bg" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "top-header" }),
                ]
              ),
              _vm._v(" "),
              _c("div", { ref: "table", staticClass: "table" }, [
                _c("div", { ref: "tableHead", staticClass: "table-head" }, [
                  _c("div", {
                    ref: "tableHeadTitle",
                    staticClass: "table-head-title",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-plans-wrapper" },
                    _vm._l(_vm.plans, function (plan, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: ["table-plan-cell head-cell", plan.name],
                        },
                        [
                          plan.name !== "enterprise"
                            ? _c("div", { staticClass: "inner-info" }, [
                                _c("div", { staticClass: "plan-name" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.locales(plan.name + "_plan_locale")
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "price-desc" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        "$ " +
                                          plan.annual_price_usd +
                                          " " +
                                          _vm.locales("per_user_locale")
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "price-desc" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.locales("per_month_key")) +
                                      "\n                  "
                                  ),
                                ]),
                              ])
                            : _c("div", { staticClass: "enterprise-info" }, [
                                _c("div", { staticClass: "plan-name" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.locales(plan.name + "_plan_locale")
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "demo-action-desc enterprise",
                                  },
                                  [
                                    _c("webix-ui", {
                                      staticClass: "buble-icon",
                                      attrs: { config: _vm.icons.bubleIcon },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "price-desc" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.locales("lets_talk_locale")
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                          _vm._v(" "),
                          plan.plan_name === "pro"
                            ? _c("div", {
                                ref: "orangeBg",
                                refInFor: true,
                                staticClass: "orange-bg",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          plan.plan_name === "business"
                            ? _c("div", {
                                ref: "grayDelimiter",
                                refInFor: true,
                                staticClass: "gray-delimiter",
                              })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { ref: "tableBody", staticClass: "table-body" },
                  _vm._l(_vm.featuresTable, function (group, index) {
                    return _c("table-group", {
                      key: index,
                      ref: "tableGroup",
                      refInFor: true,
                      attrs: { plans: _vm.plans, group: group },
                    })
                  }),
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { ref: "faq", staticClass: "faq" }, [
            _c("div", { staticClass: "faq-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.locales("faq_plans_locales")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "faq-list" },
              _vm._l(_vm.faqConfig, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "faq-item",
                    attrs: { "data-key": item.key },
                    on: { click: _vm.showAnswer },
                  },
                  [
                    _c("div", { staticClass: "question" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.question) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "check-icon" },
                      [
                        _c("webix-ui", {
                          attrs: { config: _vm.icons.checkIcon },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "answer",
                      domProps: { innerHTML: _vm._s(item.answer) },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { ref: "footer", staticClass: "contact-footer" }, [
            _c("div", { staticClass: "footer-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.locales("plans_popup_footer_title")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "contact-us",
              domProps: {
                innerHTML: _vm._s(_vm.locales("footer_contact_link_locale")),
              },
              on: { click: _vm.chatClick },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }