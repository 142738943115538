var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.saved_item_wrap,
        _vm.isActive && !_vm.editMode ? _vm.$style.active : "",
      ],
      on: {
        mouseover: function ($event) {
          _vm.showIcons = true
        },
        mouseleave: function ($event) {
          _vm.showIcons = false
        },
        click: _vm.clickItemHandler,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editMode,
              expression: "!editMode",
            },
          ],
          class: _vm.$style.saved_item,
        },
        [
          _c(
            "span",
            {
              class: _vm.$style.text_elipsis,
              on: {
                click: function ($event) {
                  return _vm.$emit("select")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.input_txt) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showIcons || _vm.isActive,
                  expression: "showIcons || isActive",
                },
              ],
              class: _vm.$style.flex,
            },
            [
              _c("webix-ui", {
                ref: "edit",
                staticClass:
                  "gp_autotest_global_filter_btn_save_popup_item_btn_edit",
                class: _vm.$style.saved_item_icon,
                attrs: {
                  id: "gp_autotest_global_filter_btn_save_popup_btn_close",
                  config: _vm.icons.edit,
                },
                on: {
                  click: function ($event) {
                    _vm.editMode = !_vm.editMode
                  },
                },
              }),
              _vm._v(" "),
              _c("webix-ui", {
                ref: "trash",
                staticClass:
                  "gp_autotest_global_filter_btn_save_popup_item_btn_delete",
                class: _vm.$style.saved_item_icon,
                attrs: { config: _vm.icons.trash },
                on: {
                  click: function ($event) {
                    return _vm.$emit("delete")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editMode,
              expression: "editMode",
            },
          ],
          class: [_vm.$style.saved_item, _vm.$style.edit_mode],
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input_txt,
                expression: "input_txt",
              },
            ],
            ref: "input_field",
            staticClass:
              "gp_autotest_global_filter_btn_save_popup_item_input_change_namme",
            attrs: { type: "text", autofocus: _vm.editMode },
            domProps: { value: _vm.input_txt },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.editedValue($event)
              },
              focus: function ($event) {
                return $event.target.select()
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input_txt = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { ref: "icons", class: _vm.$style.flex },
            [
              _c("webix-ui", {
                ref: "check",
                staticClass:
                  "gp_autotest_global_filter_btn_save_popup_item_input_btn_check",
                class: [_vm.$style.icon_edit_mode, _vm.$style.check],
                attrs: { config: _vm.icons.check },
                on: { click: _vm.editedValue },
              }),
              _vm._v(" "),
              _c("webix-ui", {
                ref: "cancel",
                staticClass:
                  "gp_autotest_global_filter_btn_save_popup_item_input_btn_cancel",
                class: [
                  _vm.$style.icon_edit_mode,
                  _vm.$style.cancel,
                  "icon_cancel",
                ],
                attrs: { config: _vm.icons.cancel },
                on: { click: _vm.cancelEdit },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }