import app from "../../../app";
import _ from "../../../libs/lodash";

const innerSettings = {
  callBack: null,
};

webix.ui({
  view: "ganttproWindowPopup",
  id: 'tokenRejectPopup',
  css: "linkPopupWithSettings",
  width: 438,
  height: 240,
  header: {
    label: __('jira_tokenReject_header'),
  },
  body: {
    paddingX: 12,
    rows: [
      {
        css: "layout-top-border",
        view: "label",
        borderless: true,
        fillspace: true,
        align: "center",
        height: 64,
        inputHeight: 24,
        paddingX: 12,
        label: __("jira_tokenReject_body")
      },
      {
        height: 84,
        paddingX: 24,
        paddingY: 24,
        cols: [
          {},
          {
            view: 'template',
            borderless: true,
            css: 'popup_button',
            template: function () {
              return `<div class="button_active">${__('jira_tokenReject_button')}</div>`
            },
            onClick: {
              'button_active': function () {
                innerSettings.callBack && innerSettings.callBack(3, true);
              }
            }
          },
          {}
        ]
      }
    ]
  },
  on: {
    "onBeforeShow": function () {
      app.trigger("popup:show");
    },
    "onHide": function () {
      _.delay(function () {
        app.trigger("popup:hide");
      });
    }
  }
}).hide();

export default {
  show: function (callback) {
    innerSettings.callBack = callback;
    $$('tokenRejectPopup').show();
  },
  close: function () {
    $$('tokenRejectPopup').hide();
  }
}