var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      id: "gp_autotes_transfer_owner_popup",
      show: _vm.show,
      title: _vm.title,
      width: "420",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "transfer-ownership" }, [
              _c("div", { staticClass: "subtitle" }, [
                _vm._v("\n        " + _vm._s(_vm.subtitle) + "\n      "),
              ]),
              _vm._v(" "),
              _vm.config && _vm.config.type === "resource"
                ? _c("div", { staticClass: "projects" }, [
                    _c("div", { staticClass: "projects-toggle" }, [
                      _c(
                        "button",
                        {
                          class: { open: _vm.isShowProjects },
                          attrs: {
                            id: "gp_autotes_transfer_owner_popup_toggle_projects",
                            type: "button",
                          },
                          on: { click: _vm.handleToggleProjects },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.locales(
                                  "transfer_ownership_toggle_projects"
                                )
                              ) +
                              "\n            "
                          ),
                          _c("svg-sprite", {
                            staticClass: "button-icon",
                            attrs: { name: "drop-down", type: "bold" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowProjects,
                            expression: "isShowProjects",
                          },
                        ],
                        staticClass: "projects-wrap",
                        class: { haze: !_vm.isEndScrolledProjects },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "scroll",
                                rawName: "v-scroll.self",
                                value: _vm.handleScrollProjects,
                                expression: "handleScrollProjects",
                                modifiers: { self: true },
                              },
                            ],
                            ref: "projectsScroll",
                            staticClass: "projects-scroll gantt_scroll",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "projects-list" },
                              _vm._l(_vm.projectsList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "projects-item",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "projects-item__label" },
                                      [_vm._v(_vm._s(item.label))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "projects-item__name" },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c("vgp-label-slot", {
                    attrs: {
                      label: _vm.locales("transfer_ownership_select_label"),
                      description: _vm.description,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-dropdown-select", {
                              attrs: {
                                "gp-autotest-name":
                                  "gp_autotes_transfer_owner_popup_dropdown_select_resources",
                                placeholder: _vm.locales(
                                  "transfer_ownership_select_placeholder"
                                ),
                                "drop-list": _vm.filteredResources,
                                "use-search": true,
                                multiselect: false,
                                "show-reset-btn": false,
                                "item-template": "user",
                                "track-by-img-url": "photo",
                                "track-by-additional-for-item":
                                  "aditionalElement",
                                selected: _vm.selectedResource,
                              },
                              on: { selected: _vm.handleSelect },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("vgp-button", {
                    staticClass: "action-button",
                    attrs: {
                      id: "gp_autotes_transfer_owner_popup_btn_cancel",
                      type: "secondary",
                      small: true,
                      width: "100px",
                      label: _vm.locales("common_cancel"),
                    },
                    on: { onClick: _vm.onClose },
                  }),
                  _vm._v(" "),
                  _c("vgp-button", {
                    staticClass: "action-button",
                    attrs: {
                      id: "gp_autotes_transfer_owner_popup_btn_accept",
                      small: true,
                      width: "100px",
                      type:
                        _vm.config.type !== "change"
                          ? "destructive"
                          : "primary",
                      label:
                        _vm.config.type !== "change"
                          ? _vm.locales("common_delete")
                          : _vm.locales("common_ok"),
                      disabled: !_vm.selectedResource,
                    },
                    on: { onClick: _vm.transferRole },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }