// !TODO: description
import app, { appIds } from '../../../app';
import massChangeModel from './massChange.js';

const {
    popupViews: { ID_VIEW_POPUP_MC_SELECT_MENU },
    events: { ID_EVENT_MC_SELECT_ACTION }
} = appIds;

const ID_VIEW_MENU_MC_SELECT = 'idViewMenuMcSelect';

var _innerHandlers = null;
var _outerHandlers = null;
var _innerHelpers = null;
var _innerViews = null;
var _templates = null;

_innerHelpers = {
  // composeMenuItems: () => {
  //     return _menuItems;
  // }
};

_templates = {
    menuItem: item =>
        `<div class='context_menu_list_item_icon'>${item.icon}</div>
        <div class='context_menu_list_item_text'>${item.value}</div>`
};

_outerHandlers = {

};

_innerHandlers = {
  //
  onMenuItemClick(id, e) {
    const __dataEntry = this.getItem(id);

    if (__dataEntry.disabled) {
      return;
    }

        app.trigger(ID_EVENT_MC_SELECT_ACTION, id)
        $$(ID_VIEW_POPUP_MC_SELECT_MENU).hide();
    },
    //
    onBeforePopupShow: function() {
        const { menuData, activeItem } = this;

        if (!activeItem || !menuData || menuData.length === 0) {
            return false;
        }

    massChangeModel.setActive(activeItem);

        $$(ID_VIEW_MENU_MC_SELECT).clearAll();
        $$(ID_VIEW_MENU_MC_SELECT).parse(menuData);
    },
    //
    onPopupHide: function() {
        massChangeModel.unsetActive();
        $$(ID_VIEW_MENU_MC_SELECT).clearAll();
    }
};

_innerViews = {
    contextMenuList: {
        id: ID_VIEW_MENU_MC_SELECT,
        view: 'menu',
        css: 'context_menu_list select-menu',
        borderless: true,
        autoheight: true,
        layout: 'y',
        template: (obj) => obj.disabled ? `<span class="list-item-disabled">${obj.value}</span>` : obj.value,
        data: [],
        type: {
            height: 36,
        },
        on: {
            'onItemClick': _innerHandlers.onMenuItemClick
        }
    }
};

const _menuPopupView = {
    id: ID_VIEW_POPUP_MC_SELECT_MENU,
    view: 'popupWithoutPoint',
    css: 'context_menu_popup',
    hidden: true,
    borderless: true,
    autoheight: true,
    width: 146,
    body: {
        rows: [ _innerViews.contextMenuList, ]
    },
    on: {
        'onBeforeShow': _innerHandlers.onBeforePopupShow,
        'onHide': _innerHandlers.onPopupHide
    },
};

webix.ui(_menuPopupView);

// !FIXME: constant
app.on('popup:show', function() {
    $$(ID_VIEW_POPUP_MC_SELECT_MENU).hide();
});
