var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["attachments__list"] },
    _vm._l(_vm.items, function (attachment) {
      return _c(
        "div",
        {
          key: "attachments-list-view-" + attachment.id,
          class: [_vm.$style["attachment__row"], "vgp-interactive-element"],
          on: {
            click: function ($event) {
              return _vm.$emit("view", attachment)
            },
          },
        },
        [
          _c("vgp-checkbox", {
            attrs: { checked: _vm.selectedItems.includes(attachment.id) },
            on: {
              onChange: function ($event) {
                return _vm.$emit("select", attachment.id)
              },
            },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          }),
          _vm._v(" "),
          _c("div", { class: _vm.$style["attachment__image-wrapper"] }, [
            _c("img", {
              ref: "attachmentsImages",
              refInFor: true,
              class: _vm.$style["attachment__image"],
              attrs: {
                src: attachment.thumbnailLink,
                loading: "lazy",
                alt: "Attachment image",
              },
              on: {
                error: function ($event) {
                  $event.target.src = _vm.defaultImagePlaceholder
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style["attachment__data"] }, [
            _c("div", { class: _vm.$style["attachment__title"] }, [
              _vm._v("\n        " + _vm._s(attachment.name) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style["attachment__info"] }, [
              _c("span", { class: _vm.$style["attachment__author"] }, [
                _vm._v(
                  "\n          " + _vm._s(attachment.author) + "  \n        "
                ),
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.$style["attachment__date"] }, [
                _vm._v(
                  "\n          " + _vm._s(attachment.uploadDate) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.$style["attachment__size"] }, [
                _vm._v("\n          " + _vm._s(attachment.size) + "\n        "),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style["attachment__actions"] },
            [
              !attachment.downloadUrl
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "link-2" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("view", attachment)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              attachment.downloadUrl
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "download" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("download", attachment)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.disabledDeletion
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "delete" } },
                    on: {
                      onClick: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("delete", attachment)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }