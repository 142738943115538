/* eslint-disable */
import _ from "../../../libs/lodash";
import taskToXlsxExportHelpers from './helpers/taskToXlsxExportHelpers'
import xlsxExport from "../xlsxExport";
import rights from '../../../components/rights';


const __ = window.__;

var innerObject = {
  init: {
    run: function () { },
    afterInit: function () { }
  },

  handlers: {
    export: function (options) {
      let serialisedListData = options.data;
      let rows = innerObject.handlers.prepareTasks(serialisedListData);
      var exportOptions = {
        title: options.title,
        enablePrice: !!rights.project.hasCostRight(gantt.config.gantt_id),
        worksheets: []
      };

      let columns = taskToXlsxExportHelpers.getTaskColumns(exportOptions, {
        excludedColumns: ['wbs','level'],
      });

      exportOptions.worksheets.push({
        title: exportOptions.title,
        rows,
        columns
      });

      xlsxExport.export(exportOptions);
    },

    prepareTasks: function (data) {
      var ganttData = [];
      _.each(data, function (task, index) {
        let preparedTask = taskToXlsxExportHelpers.taskToRow(task, false, { multiProjectsList: true }); 

        let dur = preparedTask.data.duration / 60;
        if(dur && dur/Math.floor(dur) !==1 ){
          dur = dur.toFixed(2);
        }
        preparedTask.data.duration = dur;

        ganttData.push(preparedTask);
      });
      return ganttData;
    },
   }
};

var outputObject = {
  init: {
    run: innerObject.init.run,
    afterInit: innerObject.init.afterInit,
  },
  export: innerObject.handlers.export
};

export default outputObject;

