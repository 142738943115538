var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("info-popup", {
    attrs: { position: _vm.position, side: _vm.side },
    on: { closePopup: _vm.closePopup },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "projects-popup-list-item-adv",
                attrs: {
                  "data-id": _vm.project.id,
                  "data-gantt_id": _vm.project.gantt_id,
                },
              },
              [
                _c("div", { staticClass: "projects-popup-list-item-row" }, [
                  _c(
                    "div",
                    { staticClass: "projects-popup-list-item-column" },
                    [
                      _c(
                        "div",
                        { staticClass: "projects-popup-list-item-adv-users" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "projects-popup-list-item-adv-users-icons",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "projects-popup-list-item-adv-users-icons-item",
                                },
                                [
                                  _c("svg-sprite", {
                                    attrs: { name: "assigned" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "projects-popup-list-item-adv-users-count",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.project.gantt_id
                                      ? _vm.locale("gantt_grid_owner")
                                      : _vm.locale("gantt_grid_creator")
                                  ) +
                                  ": "
                              ),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.getCreatorName(_vm.project))),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "projects-popup-list-item-row" }, [
                  _c(
                    "div",
                    { staticClass: "projects-popup-list-item-column" },
                    [
                      _c(
                        "div",
                        { staticClass: "projects-popup-list-item-adv-users" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "projects-popup-list-item-adv-users-icons",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "projects-popup-list-item-adv-users-icons-item",
                                },
                                [_c("svg-sprite", { attrs: { name: "team" } })],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "projects-popup-list-item-adv-users-count",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.locale("team_name")) +
                                  ":\n              "
                              ),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.getUsersCount(_vm.project)) +
                                    " " +
                                    _vm._s(
                                      _vm.getUsersCount(_vm.project) > 1
                                        ? _vm.locale("locale_users")
                                        : _vm.locale("locale_user")
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "projects-popup-list-item-column" },
                    [
                      _c("div", {
                        staticClass: "projects-popup-list-item-adv-unassigned",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.locale("project_unassignedTasks", {
                              unassignedTasks: _vm.project.unassignedTasks,
                            })
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "projects-popup-list-item-row" }, [
                  _c(
                    "div",
                    { staticClass: "projects-popup-list-item-column" },
                    [
                      _c(
                        "div",
                        { staticClass: "projects-popup-list-item-adv-icon" },
                        [_c("svg-sprite", { attrs: { name: "pensil" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "projects-popup-list-item-adv-last" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.locale("project_last_change")) +
                              ":\n            "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.getLastUpdateDate(_vm.project))),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "projects-popup-list-item-column" },
                    [
                      _c("div", {
                        staticClass: "projects-popup-list-item-adv-progress",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.locale("project_progress", {
                              progress: _vm.project.progress,
                            })
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "projects-popup-list-item-line" }),
                _vm._v(" "),
                _c("div", { staticClass: "projects-popup-list-item-row" }, [
                  _c("div", {
                    staticClass:
                      "projects-popup-list-item-column-3 green-color",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.locale("project_countOfPerSchedule", {
                          count: _vm.project.countOfPerSchedule,
                        })
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "projects-popup-list-item-column-3 orange-color",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.locale("project_countOfSlightlyOverdue", {
                          count: _vm.project.countOfSlightlyOverdue,
                        })
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "projects-popup-list-item-column-3 red-color",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.locale("project_countOfOverdue", {
                          count: _vm.project.countOfOverdue,
                        })
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "projects-popup-list-item-progress-line" },
                  [
                    _c("div", {
                      staticClass:
                        "projects-popup-list-item-progress-line-green",
                      style: {
                        width:
                          (_vm.getSum(_vm.project) === 0
                            ? 1
                            : _vm.project.countOfPerSchedule /
                              _vm.getSum(_vm.project)) *
                            100 +
                          "%",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "projects-popup-list-item-progress-line-orange",
                      style: {
                        width:
                          (_vm.project.countOfSlightlyOverdue /
                            _vm.getSum(_vm.project)) *
                            100 +
                          "%",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "projects-popup-list-item-progress-line-red",
                      style: {
                        width:
                          (_vm.project.countOfOverdue /
                            _vm.getSum(_vm.project)) *
                            100 +
                          "%",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }