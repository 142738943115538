<template>
  <core-popup
    :show="isShow"
    :title="locales('new_all_attachments')"
    :close-icon="true"
    @onClickCancel="onClose"
  >
    <template #body>
      <div
        :class="$.attachments_popup"
        :style="!preparedAttachments ? {'height': 'auto'} : {}"
      >
        <div :class="$.left_side">
          <div :class="$.title">
            {{ locales('attachments_projects_title') }}
          </div>
          <div
            v-if="preparedAttachments"
            :class="$.body"
          >
            <vgp-input
              v-model="searchValue"
              :value="searchValue"
              :small="true"
              :placeholder="locales('attachments_search_placeholder')"
              :border="true"
              :icons-left="[{ name: 'search' }]"
            />
            <ul :class="[$.project_list, 'gantt_scroll']">
              <li
                v-for="(project, index) in projects"
                :key="index"
                :class="[$.list_item, project.projectId === activeProjectId ? $.active : '']"
                @click="setActiveProject(project.projectId)"
              >
                <div :class="$.project_title">
                  <svg-sprite
                    :key="index"
                    :name="project.isJira ? 'jira' : 'project'"
                    :size="24"
                    :color="project.projectId === activeProjectId ? '#ffffff' : '#B2B2B2'"
                    :type="'regular'"
                  />
                  <span :class="$.name">
                    {{ project.name }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div :class="$.right_side">
          <div :class="$.title">
            {{ locales('attachments_tasks_title') }}
          </div>
          <div
            v-if="preparedAttachments"
            :class="[$.body, 'gantt_scroll']"
          >
            <div
              v-if="searchValue !== '' && !projects.length"
              :class="$.stub_body"
            >
              <div
                :class="$.stub_img"
                v-html="searchStub"
              />
              <div :class="$.stub_desc">
                {{ locales('attachments_stub_message_if_no_search_result') }}
              </div>
            </div>
            <div
              v-else-if="!activeProjectId"
              :class="$.stub_body"
            >
              <div
                :class="$.stub_img"
                v-html="attachmentsStub"
              />
              <div :class="$.stub_desc">
                {{ locales('attachments_stub_message_if_project_dont_open') }}
              </div>
            </div>
            <template v-else>
              <attachments-accordion
                v-for="(attachments, taskId) in projectTasks"
                :key="taskId"
                :attachments="attachments"
                :task-name="tasksByIds[taskId] ? tasksByIds[taskId].text : ''"
                :task-id="taskId"
                :has-rights="hasRightToAttachments"
                @open-task="openTask"
                @click-file="onClickFile"
                @files-upload="handleFilesUpload"
              />
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="!preparedAttachments"
        :class="$.popup_stub"
      >
        <div
          :class="$.stub_img"
          v-html="attachmentsStub"
        />
        <div :class="$.stub_desc">
          {{ locales('attachments_stub_message_if_have_no_attachments') }}
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import corePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import projectsModel from '../../models/projects';
import humanFileSize from '../../helpers/fileSize';
import moment from '../../libs/moment';
import rights from '../../components/rights/index';
import VgpInput from '../common/VueComponents/vInput/vgpInput.vue';
import SvgSprite from '../common/VueComponents/VueIcon/svgSprite.vue';
import routerHelper from '../../helpers/router';
import attachmentsStub from './svg/attachments-stub.svg';
import searchStub from './svg/attachments-search-stub.svg';
import app from '../../app';
import constants from '$$helpers/constants';
import storeGallery from '../vueGallery/storeGallery';
import { downloadSingle } from '$$helpers/attachments';
import GoogleDriveAttachmentsController from '../taskSettings/controllers/googleDrive/index';
import { previewAvailabilityByType } from '../taskSettings/helpers/googleDrive/index';
import AttachmentsAccordion from './AttachmentsAccordion.vue';

const FETCH_EVENTS = ['ProjectUpdated', 'ResourceDeleted', 'ProjectRestored', 'TaskDeleted'];
const IMAGE_EXTENSIONS = ['jpeg', 'png', 'svg', 'jpg', 'gif', 'bmp', 'tiff', 'exif'];

export default {
  name: 'AttachmentsPopup',
  components: {
    AttachmentsAccordion,
    corePopup,
    VgpInput,
    SvgSprite,
  },
  props: {
    isShow: { type: Boolean, required: true, default: false },
  },
  emits: {
    onClose: Boolean,
  },
  data() {
    return {
      locales: __,
      projects: [],
      filteredByTaskAccess: [],
      preparedAttachments: null,
      activeProjectId: null,
      lastActiveProjectId: null,
      searchValue: '',
      attachmentMenuPosition: null,
      attachmentsStub,
      searchStub,
    };
  },
  computed: {
    ...mapGetters({
      tasksByIds: 'tasksModel/tasksByIds',
      attachmentsData: 'attachments/getAllAttachments',
      resourceTasks: 'tasksModel/getAllTasksByResourceId',
      resourceByUserId: 'resourcesModel/getResourceByUserId',
    }),
    formattedAttachments() {
      return this.preparedAttachments[this.activeProjectId]?.map(attachment => {
        const attachmentExtension = attachment.link.split('.').at(-1).toLowerCase();
        let thumbnailLink = attachment.link;
        let downloadUrl = null;
        let isStub = !IMAGE_EXTENSIONS.includes(attachmentExtension);

        if (attachment.attachmentType === constants.ATTACHMENT_TYPE_KEYS.FILE && !IMAGE_EXTENSIONS.includes(attachmentExtension)) {
          thumbnailLink = `${GT.cdn}/imgs/fileTypes/${attachmentExtension}.svg`;
          isStub = false;
        }

        switch (attachment.attachmentType) {
        case constants.ATTACHMENT_TYPE_KEYS.FILE:
          downloadUrl = attachment.link;
          break;
        case constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE:
          thumbnailLink = `https://drive.google.com/thumbnail?authuser=0&sz=w166&h=130&id=${attachment.attacherExternalAccessToken}`;
          break;
        }

        return {
          ...attachment,
          size: humanFileSize(attachment.size),
          uploadDate: moment(attachment.uploadDate).format(user.dateFormat),
          thumbnailLink,
          downloadUrl,
          isStub,
        };
      });
    },
    projectTasks() {
      return _.groupBy(this.formattedAttachments, 'taskId');
    },
    hasRightToAttachments() {
      return rights.project.hasRight(this.activeProjectId, 'static_fields_4_only')
      || rights.project.hasRight(this.activeProjectId, 'static_fields');
    },
  },
  watch: {
    attachmentsData(newData) {
      if (newData && this.isShow) {
        this.init();

        if (this.preparedAttachments && !this.preparedAttachments[this.activeProjectId]) {
          this.activeProjectId = null;
        }
      }
    },
    isShow: {
      async handler(flag) {
        if (flag) {
          await this.fetchAttachments();
          this.init();
        }
      },
      immediate: true,
    },
    searchValue(val) {
      if (this.filteredByTaskAccess.length) {
        const filteredBySearch = this.filteredByTaskAccess.filter(item => {
          if (item.name.toLowerCase().includes(val.toLowerCase())) {
            return item;
          }
        });

        this.preparedAttachments = _.groupBy(filteredBySearch, 'projectId');
        const projectsMap = this.prepareProjects();

        if (!projectsMap[this.activeProjectId]) {
          this.setActiveProject(null);
        }

        if (!val && this.lastActiveProjectId) {
          this.setActiveProject(this.lastActiveProjectId);
        }
      }
    },
  },
  beforeCreate() {
    app.on('onAfterCollaboration', async eventData => {
      const { event } = eventData;

      if (FETCH_EVENTS.includes(event)) {
        await this.fetchAttachments(true);
        this.init();
      }

      if (event === 'ProjectArchived' || event === 'ResourceFromProjectsUnassigned') {
        this.projects = this.projects.filter(pr => !eventData.projects.includes(pr.projectId));

        if (eventData.projects.includes(this.activeProjectId)) {
          this.activeProjectId = null;
        }
      }

      if (event === 'ResourceOnProjectsUpdated' && eventData.isUpdatedRole) {
        this.onResourceRoleOnProjectsUpdated(eventData);
      }

      if (!this.projects.length) {
        this.preparedAttachments = null;
        this.searchValue = '';
      }
    });
    app.on('userProfile:update', async () => {
      await this.fetchAttachments(true);
      this.init();
    });
    app.on('project:archive', async () => {
      await this.fetchAttachments(true);
      this.init();
    });
  },
  beforeDestroy() {
    app.off('onAfterCollaboration');
    app.off('userProfile:update');
    app.off('project:archive');
  },
  methods: {
    init() {
      const resourceId = this.resourceByUserId(user.id)?.id;
      const resourceTasks = this.resourceTasks(resourceId);

      this.filteredByTaskAccess = this.attachmentsData.filter(item => {
        const projectData = projectsModel.getProjectDataById(item.projectId);

        if (!projectData || projectData.is_archived) return;

        const isResourcesTask = resourceTasks.find(data => data.task_id === item.taskId);

        if (!this.tasksByIds[item.taskId]) return;
        if (rights.account.isKingMode() || rights.project.hasRight(item.projectId, 'all_tasks') || isResourcesTask) return item;
      });

      this.prepareAttachments();
      this.prepareProjects();
    },
    prepareAttachments() {
      if (!this.filteredByTaskAccess.length) {
        this.preparedAttachments = null;

        return;
      }

      const filteredBySearch = this.filteredByTaskAccess.filter(item => {
        if (item.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return item;
        }
      });

      this.preparedAttachments = _.groupBy(filteredBySearch, 'projectId');
    },
    prepareProjects() {
      this.projects = [];
      const activeProjectsMap = {};

      if (this.preparedAttachments) {
        const projectIds = Object.keys(this.preparedAttachments);

        projectIds
          .map(id => projectsModel.getProjectDataById(id))
          .filter(projectData => projectData && !projectData.is_archived)
          .forEach(projectData => {
            activeProjectsMap[projectData.gantt_id] = true;
            this.projects.push({
              projectId: projectData.gantt_id,
              name: projectData.name,
              isJira: projectData.is_jira,
            });
          });
      }

      return activeProjectsMap;
    },
    async fetchAttachments(refetch = false) {
      const allProjectIds = projectsModel.getAllProjects().filter(pr => !pr.is_archived).map(pr => pr.gantt_id);

      await this.$store.dispatch('attachments/fetchAttachmentsByProjectIds', { projectIds: allProjectIds, refetch });
    },
    setActiveProject(projectId) {
      this.activeProjectId = projectId;
      if (projectId) {
        this.lastActiveProjectId = projectId;
      }
    },
    onClickFile(attachment) {
      const attachmentType = attachment.name.split('.').pop().toLowerCase();

      if (IMAGE_EXTENSIONS.includes(attachmentType)) {
        const imagesAttachments = this.formattedAttachments.filter(entry => {
          const entryType = entry.name.split('.').pop() || '';

          return IMAGE_EXTENSIONS.includes(entryType);
        });

        storeGallery.commit('setImages', _.map(_.cloneDeep(imagesAttachments), item => {
          item.src = item.link;

          if (item.attachmentType === constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE) {
            item.src = `https://drive.google.com/uc?id=${item.attacherExternalAccessToken}`;
          }

          if (item.id === attachment.id) {
            item.selected = true;
          }

          return item;
        }));

        return storeGallery.commit('showGallery');
      }

      if (attachment.attachmentType === constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE && previewAvailabilityByType(attachment.mimeType)) {
        return GoogleDriveAttachmentsController.showAndLoadPreviewPopup(attachment);
      }

      if ([constants.ATTACHMENT_TYPE_KEYS.ONE_DRIVE, constants.ATTACHMENT_TYPE_KEYS.GOOGLE_DRIVE].includes(attachment.attachmentType)) {
        window.open(attachment.link, '_blank');

        return;
      }

      downloadSingle(attachment);
    },
    openTask(taskId) {
      routerHelper.pushRoute({
        name: 'taskRoute',
        params: {
          taskId,
          projectId: this.activeProjectId,
          mode: 'gantt',
        },
      });
      this.$nextTick(() => this.onClose());
    },
    onClose() {
      this.$emit('onClose');
      this.activeProjectId = null;
      this.searchValue = '';
      this.preparedAttachments = null;
      this.projects = [];
    },
    async handleFilesUpload(e, taskId) {
      const { files } = e.target;

      if (!files.length) return;

      await this.$store.dispatch('attachments/createAttachment', {
        projectId: this.activeProjectId,
        taskId,
        fileList: files,
      });
    },
    checkResourceExist(userId) {
      return !!this.resourceByUserId(userId);
    },
    onResourceRoleOnProjectsUpdated(eventData) {
      const projectIdsToRemove = eventData.projects.reduce((result, projectId) => {
        if (!rights.project.hasRight(projectId, 'all_tasks')) {
          result.push(projectId);
        }

        return result;
      }, []);

      this.projects = this.projects.filter(project => !projectIdsToRemove.includes(project.projectId));
      if (projectIdsToRemove.includes(this.activeProjectId)) {
        this.activeProjectId = null;
      }
    },
  },
};
</script>

<style lang='less'>
  .v-expansion-panels {
    border-radius: 0 !important;

    .v-expansion-panel {
      margin-top: 0 !important;

      &:not(:last-child):before {
        display: none;
      }

      &:last-child::before {
        width: calc(100% - 18px);
        left: 10px;
        top: 3px;
        bottom: 1px;
        box-shadow: 0 0px 0px 2px #e5e5e5;
        border-radius: 0;
      }

      &::after {
        opacity: 1 !important;
        left: 8px;
        width: calc(100% - 16px);
      }

      &:not(:first-child)::after {
        opacity: 1 !important;
        left: 8px;
        width: calc(100% - 16px);
        border-color: #e5e5e5;
      }

      &--active {
        min-height: auto;
        border-radius: 0;

        .v-expansion-panel-header {
          min-height: auto;
        }
      }

      &-header {
        min-height: auto;
        padding: 0;
      }

      &-content {
        padding: 0 4px;

        &__wrap {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 12px;
        }
      }
    }
  }
</style>
<style module='$' src='./less/attachments.less' />
