var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["vgp-svg-image", _vm.name + "-icon"],
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("svg", {
        style: {
          width: _vm.iconWidth + "px",
          height: _vm.iconHeight + "px",
          color: _vm.iconColor,
        },
        attrs: {
          viewBox: _vm.viewBox,
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
        domProps: { innerHTML: _vm._s(_vm.iconHTML) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }