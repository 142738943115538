<template>
  <core-popup
    :show="isShow"
    :title="locales('add_to_projects_popup_title')"
  >
    <template #body>
      <div :class="$.add_to_projects_popup">
        <div :class="$.content">
          <div :class="$.items_with_checkbox">
            <div :class="$.items_with_checkbox__search">
              <search
                :placeholder="locales('search_by_name_only')"
                @onInput="onSearch"
              />
            </div>
            <div
              v-if="preparedProjects.length"
              :class="$.items_with_checkbox__table"
            >
              <div :class="$.select_btn">
                <vgp-button
                  :label="visibleSelectedLength === preparedProjects.length
                    ? locales('multicombo_unselect_all_items')
                    : locales('multicombo_select_all_items')"
                  type="text"
                  :small="true"
                  @onClick="onToggleSelectAll"
                />
              </div>
              <div :class="$.labels">
                <div>
                  {{ locales('attachments_projects_title') }}
                </div>
                <div
                  v-if="resourceData.userId"
                  :class="$.labels__roles"
                >
                  {{ locales('people_popup_roles_label') }}
                </div>
              </div>
              <div :class="[$.list, 'gantt_scroll']">
                <div
                  v-for="(item, index) in preparedProjects"
                  :key="index"
                  :class="$.list__row"
                >
                  <div
                    :class="$.info"
                    @click="onClickRow(item)"
                  >
                    <vgp-checkbox
                      :class="$.checkbox"
                      :style="{'pointer-events': 'none'}"
                      :checked="item.isChecked"
                      :size="16"
                    />
                    <div :class="$.name">
                      <tooltip
                        :class="$.tooltip_wrapper"
                        :content="item.name"
                      >
                        <template #body>
                          {{ item.name }}
                        </template>
                      </tooltip>
                    </div>
                  </div>
                  <div
                    v-if="resourceData.userId"
                    :class="[$.dropdown, !item.isAccessToChangeRole || resourceData.isOwner || resourceData.isKing ? $.disabled : '']"
                  >
                    <dropdown-select
                      :key="item.key"
                      :drop-list="dropdownRoles"
                      :multiselect="false"
                      :show-selected-first="false"
                      :show-reset-btn="false"
                      :highlight-active-state="false"
                      item-template="text"
                      track-by-text="title"
                      :outline="!item.isAccessToChangeRole ? 'none' : 'hover'"
                      :disabled="!item.isAccessToChangeRole || resourceData.isOwner || resourceData.isKing"
                      drop-position="fixed"
                      parent-selector-for-calc-position-drop=".core_popup_block"
                      track-by-item-tooltip="tooltip"
                      :fixed-width="320"
                      :size="'autoWidth'"
                      :need-description="true"
                      :selected="dropdownRoles.find(role => role.id === item.roleId)"
                      @selected="$event => onChangeRole($event, item)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              :class="$.items_with_checkbox__stub"
            >
              <div :class="$.wrap">
                <div
                  :class="$.img"
                  v-html="stub"
                />
                <div :class="$.desc">
                  {{ searchValue !== ''
                    ? locales('add_projects_popup_no_search_result')
                    : locales('add_projects_popup_no_projects') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="[$.footer, $.with_shadow]">
          <vgp-button
            :label="locales('common_cancel')"
            type="secondary"
            :small="true"
            :style="{marginRight: '16px'}"
            @onClick="onClose"
          />
          <vgp-button
            :label="locales('common_add')"
            type="primary"
            :small="true"
            :disabled="!selected.length"
            @onClick="onApply"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import corePopup from '../../../common/VueComponents/popups/corePopup/corePopup.vue';
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import search from '../inputSearch/inputSearch.vue';
import stub from '../../../../svg/search_no_result_pic.svg';
import app from '../../../../app';
import tooltip from '../../../common/VueComponents/tooltips/tooltip.vue';

export default {
  name: 'InviteToProjectPopup',
  components: {
    corePopup, DropdownSelect, search, tooltip,
  },
  inject: ['checkProjectRightToFeature'],
  props: {
    isShow: { type: Boolean, default: false },
    projects: { type: Array, required: true, default: [] },
    projectRoles: { type: Array, required: true, default: [] },
    resourceData: { type: Object, required: true, default: {} },
  },
  emits: {
    onClose: null,
    assignToProjects: Array,
  },
  data() {
    return {
      locales: __,
      preparedProjects: [],
      selected: [],
      updatedRoles: [],
      searchValue: '',
      stub,
    };
  },
  computed: {
    dropdownRoles() {
      return this.projectRoles.map(role => {
        const copy = { ...role };

        copy.tooltip = { content: role.title };
        copy.tooltipPosition = 'left';

        return copy;
      });
    },
    visibleSelectedLength() {
      const visibleSelected = this.selected.filter(sel => this.preparedProjects.find(pr => pr.gantt_id === sel.projectId));

      return visibleSelected.length;
    },
  },
  watch: {
    isShow(flag) {
      if (flag) {
        this.init();
      }
    },
    projects(newData) {
      if (newData && this.isShow) {
        this.selected = this.selected.filter(sel => newData.find(pr => pr.gantt_id === sel.projectId));
        this.init();
      }
    },
    updatedRoles(newData) {
      if (newData && this.isShow) {
        this.init();
      }
    },
    searchValue(val) {
      this.init();
    },
  },
  beforeCreate() {
    // app.on('project:archive', data => {
    //   const index = this.selected.indexOf(this.selected.find(i => i.projectId === data.gantt_id));

    //   if (index !== -1) {
    //     this.selected.splice(index, 1);
    //   }
    // });

    app.on('onAfterCollaboration', event => {
      if (event.event === 'ProjectDeleted') {
        const isSelected = this.selected.find(i => event.projects.includes(i.projectId));

        const selectedIndex = this.selected.indexOf(isSelected);

        if (selectedIndex !== -1) {
          this.selected.splice(selectedIndex, 1);
        }
      }

      if (event.event === 'ProjectArchived') {
        const isSelected = this.selected.find(i => event.projects.includes(i.projectId));
        const isProject = this.preparedProjects.find(pr => event.projects.includes(pr.gantt_id));
        const selectedIndex = this.selected.indexOf(isSelected);
        const projectIndex = this.preparedProjects.indexOf(isProject);

        if (selectedIndex !== -1) {
          this.selected.splice(selectedIndex, 1);
        }

        if (projectIndex !== -1) {
          this.preparedProjects.splice(projectIndex, 1);
        }
      }

      if (event.event === 'RoleProjectDeleted') {
        const selectedRole = this.updatedRoles.find(i => event.ids.includes(i.roleId));

        this.selected = this.selected.map(item => {
          const copy = { ...item };

          if (event.ids.includes(item.roleId)) {
            copy.roleId = this.projectRoles.find(role => role.isDefault).id;
          }

          return copy;
        });

        if (selectedRole) {
          selectedRole.roleId = this.projectRoles.find(role => role.isDefault).id;

          const index = this.updatedRoles.indexOf(selectedRole);

          this.updatedRoles.splice(index, 1, selectedRole);
        }
      }

      if (event.event === 'RoleProjectUpdated') {
        const oldDefaultRoleId = event.data.updated.find(role => !role.isDefault).id;
        const newDefaultRoleId = event.data.updated.find(role => role.isDefault).id;

        const selectedRole = this.updatedRoles.find(i => i.roleId === oldDefaultRoleId);

        this.selected = this.selected.map(item => {
          const copy = { ...item };

          if (item.roleId === oldDefaultRoleId) {
            copy.roleId = newDefaultRoleId;
          }

          return copy;
        });

        if (selectedRole) {
          selectedRole.roleId = newDefaultRoleId;

          const index = this.updatedRoles.indexOf(selectedRole);

          this.updatedRoles.splice(index, 1, selectedRole);
        }
      }

      if (event.event === 'ResourceFromProjectsUnassigned') {
        const userResourceId = this.$store.getters['resourcesModel/getResourceIdByUserId'](user.id);
        const isCurrentResource = event.data.resourcesFromProjects.find(res => res.resourceId === userResourceId);

        if (isCurrentResource) {
          event.projects.forEach(projectId => {
            const isSelectedIndex = this.selected.indexOf(this.selected.find(s => s.projectId === projectId));

            if (isSelectedIndex !== -1) {
              this.selected.splice(isSelectedIndex, 1);
            }
          });
        }
      }
    });
  },
  beforeDestroy() {
    app.off('project:archive');
    app.off('onAfterCollaboration');
  },
  methods: {
    init() {
      const preparedProjects = this.projects.map(project => {
        const newRole = this.updatedRoles.find(obj => obj.projectId === project.gantt_id);
        const isSelected = this.selected.find(obj => obj.projectId === project.gantt_id);

        if (newRole && newRole.roleId !== this.getDefaultRoleId().id) {
          project.roleId = newRole.roleId;
        } else {
          project.roleId = this.getDefaultRoleId().id;
        }

        project.key = (Math.random() + 1).toString(36).substring(2);
        project.isChecked = !!isSelected;
        project.isAccessToChangeRole = this.checkProjectRightToFeature(project.gantt_id, 'resources_rights_edit');

        return project;
      });

      this.preparedProjects = preparedProjects.filter(item => (item.name.toLowerCase().startsWith(this.searchValue.toLowerCase())));
    },
    getDefaultRoleId() {
      if (this.resourceData.isOwner || this.resourceData.isKing) {
        return this.projectRoles.find(role => role.defaultType === 'ADMIN');
      }

      return this.projectRoles.find(role => role.isDefault);
    },
    onSearch(value) {
      this.searchValue = value;
    },
    onClickRow(project) {
      const isSelected = this.selected.find(obj => obj.projectId === project.gantt_id);
      const index = this.preparedProjects.indexOf(project);
      const updatedProject = { ...project };

      if (!isSelected && index !== -1) {
        updatedProject.key = (Math.random() + 1).toString(36).substring(2);
        updatedProject.isChecked = true;

        this.preparedProjects.splice(index, 1, updatedProject);
        this.selected.push({
          resourceId: this.resourceData.id,
          projectId: project.gantt_id,
          roleId: project.roleId,
        });
      } else {
        const idx = this.selected.indexOf(this.selected.find(obj => obj.projectId === project.gantt_id));

        updatedProject.key = (Math.random() + 1).toString(36).substring(2);
        updatedProject.isChecked = false;

        this.preparedProjects.splice(index, 1, updatedProject);

        if (idx !== -1) {
          this.selected.splice(idx, 1);
        }
      }
    },
    onToggleSelectAll() {
      if (this.visibleSelectedLength < this.preparedProjects.length) {
        this.preparedProjects = this.preparedProjects.map(pr => {
          const updated = { ...pr };
          const isSelected = this.selected.find(sel => sel.projectId === pr.gantt_id);

          if (!isSelected) {
            this.selected.push({
              resourceId: this.resourceData.id,
              projectId: pr.gantt_id,
              roleId: pr.roleId,
            });

            updated.key = (Math.random() + 1).toString(36).substring(2);
            updated.isChecked = true;
          }

          return updated;
        });
      } else if (this.visibleSelectedLength = this.preparedProjects.length) {
        this.preparedProjects = this.preparedProjects.map(pr => {
          const selectedIndex = this.selected.indexOf(this.selected.find(sel => sel.projectId === pr.gantt_id));
          const updated = { ...pr };

          this.selected.splice(selectedIndex, 1);
          updated.key = (Math.random() + 1).toString(36).substring(2);
          updated.isChecked = false;

          return updated;
        });
      }
    },
    onChangeRole(data, project) {
      if (project.roleId === data[0].id) return;

      const selectedItem = this.selected.find(obj => obj.projectId === project.gantt_id);
      const projectItemWithNewRole = this.updatedRoles.find(obj => obj.projectId === project.gantt_id);

      if (selectedItem) {
        const idx = this.selected.indexOf(selectedItem);

        selectedItem.roleId = data[0].id;
        this.selected.splice(idx, 1, selectedItem);
      }

      if (projectItemWithNewRole) {
        const idx = this.updatedRoles.indexOf(projectItemWithNewRole);

        projectItemWithNewRole.roleId = data[0].id;
        this.updatedRoles.splice(idx, 1, projectItemWithNewRole);
      } else {
        this.updatedRoles.push({
          projectId: project.gantt_id,
          roleId: data[0].id,
        });
      }
    },
    onClose() {
      this.$emit('onClose');
      this.preparedProjects = [];
      this.selected = [];
      this.updatedRoles = [];
      this.searchValue = '';
    },
    onApply() {
      this.$emit('onApply', this.selected);
      this.onClose();
    },
  },
};

</script>

<style module='$' src='./popups.less'></style>
