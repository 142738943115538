import _ from '../libs/lodash';

const innerObject = {
  send(data) {
    const out = _.assignIn({
      email: user.email,
      user_name: user.username,
      last_name: user.firstName,
      first_name: user.lastName,
      company: user.settings.user_company,
      company_size: user.settings.user_team,
      industry: user.settings.user_industry,
      phone: user.settings.user_phone,
      user_payment_plan: user.subscription.name,
      user_payment_provider: user.subscription.provider,
      lang: 'en', // user.locale,
      user_id: user.id,
    }, data);

    return webix.ajax().post('/api/feedback/', out);
  },
};

export default innerObject;
