<template>
  <div
    :class="[$.status, !label && $.no_label]"
    :style="{
      backgroundColor,
      color
    }"
  >
    {{ label }}
  </div>
</template>

<script>
import statusSelect from "../../select/statusSelect/statusSelect.vue";

export default {
  name: 'StatusCell',
  components: { statusSelect },
  props: ['context'],
  data() {
    return {
      locale: __
    }
  },
  computed: {
    originalValue() {
      return this.context.row.original.status;
    },
    backgroundColor() {
      return this.originalValue.selectOption.background;
    },
    color() {
      return this.originalValue.selectOption.labelColor;
    },
    label() {
      return this.locale(this.originalValue.selectOption.value);
    }
  }
}
</script>

<style module="$" src="./less/statusCell.less"></style>
