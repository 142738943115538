import app from "./../app";

import projectsModel from "./../models/projects";
import customDaysTemplatesModel from "./../models/customDayTemplates";
import historyModel from "./../models/history";
import helperStatus from "./../helpers/status";
import timeTrackingModel from "./../models/timeTracking";
import globalStore from "../store/main";

var historyComponent = {};

historyModel.data.attachEvent("need:project:update", function (historyData) {
  helperStatus.handlers.showMainSpinner();

  historyModel.getGanttDataByHistoryEvent(historyData)
    .then(function (responseProjectData) {
      const data = responseProjectData.json().ganttData;
      const durationDataMap = ['hour', 'day', 'week', 'month', 'quarter', 'year'];

      historyComponent.updateModels(data);
      app.trigger('gantt:reinit');

      if (data.project.config.durationData.mode !== durationDataMap[$$('frameZoomSlider').getValue()]) {
        data.project.config.durationData.mode = durationDataMap[$$('frameZoomSlider').getValue()];
        projectsModel.updateUserConfigInHistoryMode(data.project.gantt_id, data.project.config.durationData);
        app.trigger('gantt:reinitConfig');
      }

      helperStatus.handlers.hideMainSpinner();
    });
});

historyComponent.updateModels = function (responseData) {
  var ganttId = responseData.project.gantt_id;
  var projectData = responseData.project;

  if (!ganttId) {
    console.error('History component can not update models. Empty ganttId', responseData.project);
    return;
  }

  if (responseData.tasks) {
    const data = responseData.tasks[0];
    globalStore.dispatch('tasksModel/updateProjectTasksData', data);
  }

  if (responseData.customDays) {
    customDaysTemplatesModel.manualAddTemplateToShareProject(responseData.customDays, ganttId);
  }

  if (responseData.resources) {
    globalStore.dispatch('resourcesModel/replaceAllData', responseData.resources);
  }

  globalStore.commit('columns/setCustomColumns', responseData.globalCustomColumnsData);
  globalStore.commit('columns/updateProject', {
    projectId: ganttId,
    updated: { ...responseData.userCustomColumnsData },
  });
  timeTrackingModel.manualUpdateItem(responseData.timeTrackingData, ganttId, 'history');
  app.trigger('userCustomColumnsModel:change', ganttId);
  delete projectData.userConfig;

  projectsModel.manualLiveUpdate(projectData);
};


export default historyComponent;
