import app, { appIds } from '../../app';

const paymentPopupStore = {
  namespaced: true,
  state: {
    isShowPaymentPage: false,
    isShowStudentsPage: false,
    isUpdatedFromApp: false,
    nextCount: '',
    descriptionHeight: [],
    featuresHeight: [],
    itemHeigth: {},
  },
  getters: {
    isShowPaymentPage: state => state.isShowPaymentPage,
    isShowStudentsPage: state => state.isShowStudentsPage,
    nextCount: state => state.nextCount,
    itemHeigth: state => state.itemHeigth,
    isUpdatedFromApp: state => state.isUpdatedFromApp,
  },
  mutations: {
    show(state) {
      state.isShowPaymentPage = true;
    },
    showStudentsPage(state, flag) {
      state.isShowStudentsPage = flag;
    },
    close(state) {
      state.isShowPaymentPage = false;
    },
    setNextCount(state, count) {
      state.nextCount = count;
    },
    setItemHeigth(state, params) {
      state.itemHeigth = params;
    },
    setUpdatingFlag(state, flag) {
      state.isUpdatedFromApp = flag;
    },
  },
  actions: {
    liveChatClick() {
      app.trigger(appIds.events.ID_APP_EVENT_LIVECHAT_CLICK);
    },
    setItemHeigth({ commit, state }, params) {
      state.descriptionHeight.push(params.description);
      state.featuresHeight.push(params.features);
      const maxDesc = state.descriptionHeight.reduce((a, b) => (a < b ? a = b : a));
      const maxFeatures = state.featuresHeight.reduce((a, b) => (a < b ? a = b : a));

      commit('setItemHeigth', {
        maxDesc,
        maxFeatures,
      });
    },
  },
};

export default paymentPopupStore;
