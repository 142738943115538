import onResourceCreated from './onResourceCreated';
import onResourceDeleted from './onResourceDeleted';
import onResourceToProjectsAssigned from './onResourceToProjectsAssigned';
import onResourceFromProjectsUnassigned from './onResourceFromProjectsUnassigned';
import onResourceUpdated from './onResourceUpdated';
import onResourceRoleUpdated from './onResourceRoleUpdated';
import onResourceCalendarUpdated from './onResourceCalendarUpdated';
import onResourceOnProjectsUpdated from './onResourceOnProjectsUpdated';
import onResourcesOnProjectsConverted from './onResourcesOnProjectsConverted';

export default {
  onResourceCreated,
  onResourceDeleted,
  onResourceToProjectsAssigned,
  onResourceFromProjectsUnassigned,
  onResourceUpdated,
  onResourceRoleUpdated,
  onResourceCalendarUpdated,
  onResourceOnProjectsUpdated,
  onResourcesOnProjectsConverted,
};
