var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notify.isShow
    ? _c(
        "div",
        {
          ref: "MaintenanceNotification",
          class: [_vm.$style.box],
          style: {
            width: "calc(100% - " + (_vm.calcWidth + 16) + "px)",
            left: _vm.calcWidth + "px",
          },
          attrs: { id: "MaintenanceNotification" },
        },
        [
          _c(
            "div",
            { class: [_vm.$style.text_box] },
            [
              _c("vgp-icon-button", {
                attrs: {
                  size: "small",
                  colors: _vm.buttonColors,
                  icon: { name: "warning-1", size: 20, type: "bold" },
                },
              }),
              _vm._v(" "),
              _c("div", [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.translation(_vm.notify.key)),
                  },
                }),
                _vm._v(" " + _vm._s(" ")),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.notify.text) },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("vgp-icon-button", {
            class: [_vm.$style.header__close_icon],
            attrs: {
              size: "small",
              colors: _vm.buttonColors,
              icon: { name: "close-1", size: 20 },
            },
            on: { onClick: _vm.onClickCancel },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }