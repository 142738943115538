<template>
  <div
    :class="[label ? $style.field_wrap : $style.input_field_wrap]"
  >
    <div
      v-if="label"
      :class="[$style.input_label]"
    >
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="input_txt"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        @click="resetSelected()"
      >
        {{ reset_locale }}
      </div>
    </div>
    <div :class="[$style.input_field, input_txt || focusInput ? $style.active : '']">
      <div :class="[$style.text_input_inner]">
        <webix-ui
          v-if="withSearchIcon"
          :config="searchIcon"
          :class="[$style.search_icon]"
        />
        <input
          :id="gpAutotestName"
          v-model="input_txt"
          :placeholder="placeholder"
          :disabled="filterProvide.disableInput"
          @keyup.delete="deleteHandler"
          @keyup.enter="onEnter"
          @change="onChange"
          @focus="onFocus"
          @blur="focusInput=false"
        >
      </div>
    </div>
  </div>
</template>

<script>
import ic_search from '../../../svg/project_popup/icon_search.svg';
import svgIconForVue from '../../common/svgIconForVue';

export default {

  inject: ['filterProvide'],
  props: {
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
    placeholder: { type: String, required: false, default: 'placeholder' },
    label: { type: String, required: false, default: '' },
    value: { type: String, required: false, default: '' },
    withSearchIcon: { type: Boolean, required: false, default: false },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return (
          ['text', 'number'].indexOf(value) !== -1
        );
      },
    },
  },

  data() {
    return {
      reset_locale: __('common_reset'),
      searchIcon: svgIconForVue(ic_search, 16, 16),
      input_txt: null,
      valid_txt: '',
      focusInput: false,
      updateMode: false,
    };
  },

  computed: {

  },

  watch: {
    value(val) {
      this.setValue(val);
    },
    input_txt(newVal, oldVal) {
      if (this.updateMode) {
        this.updateMode = false;

        return;
      }

      const oldValue = (!oldVal && oldVal !== 0) ? '' : oldVal;
      const validVal = (!newVal && newVal !== 0) ? '' : newVal;

      if (validVal !== oldValue || validVal !== this.valid_txt) {
        this.validateInputTxt();
      }
    },
  },

  mounted() {
    this.value && (this.updateMode = true);

    this.input_txt = this.value;
    this.valid_txt = this.value;
  },

  methods: {
    onFocus(e) {
      this.focusInput = true;
    },
    deleteHandler(e) {
      this.valid_txt = this.input_txt;
      this.$emit('input', this.input_txt);
      this.$emit('change', this.input_txt);
    },

    validateInputTxt() {
      if (this.type === 'text') {
        this.$emit('input', this.input_txt);
        this.$emit('change', this.input_txt);

        return;
      }

      if (this.type === 'number') {
        if (Number(this.input_txt) || !this.input_txt) {
          this.valid_txt = this.input_txt;
          this.$emit('input', this.valid_txt);
        } else {
          this.input_txt = this.valid_txt;
          webix.message({ type: 'warning', text: __('filter_number_custom_column_not_valid') });
        }
      }
    },

    setValue(newValue) {
      let validNewVal = (!newValue && newValue !== 0) ? '' : newValue.toString();

      validNewVal = validNewVal.trim();

      if (this.input_txt === validNewVal) return;
      this.input_txt = validNewVal;
      this.valid_txt = validNewVal;
    },

    onEnter() {
      this.valid_txt = this.input_txt;
      this.$emit('input', this.input_txt);
      this.$emit('change', this.input_txt);
    },

    onChange() {
      if (this.input_txt) this.$emit('change', this.input_txt);
      else this.$emit('reset', this.input_txt);
    },

    resetSelected() {
      this.input_txt = '';
      this.$emit('reset');
    },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
