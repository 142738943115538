var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowSpecialPastePopup
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.onClickOutside,
              expression: "onClickOutside",
            },
          ],
          class: _vm.$style.special_paste_popup,
          style: {
            top: _vm.popupCoordinates.top,
            bottom: _vm.popupCoordinates.bottom,
            left: _vm.popupCoordinates.left,
          },
        },
        [
          _c("div", { class: _vm.$style.special_paste_header }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.locale.title) + " ")]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.header_icons }, [
              _c(
                "span",
                { class: _vm.$style.header_icons_info },
                [
                  _c("Tooltip", {
                    class: _vm.$style.tooltip,
                    attrs: {
                      content: _vm.locale.info,
                      position: _vm.tooltipPosition,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("SvgSprite", { attrs: { name: "info" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1527107158
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.header_icons_close },
                [
                  _c("icon-button", {
                    class: _vm.$style.icon,
                    attrs: { icon: { name: "close-1" }, size: "small" },
                    on: { onClick: _vm.onClosePopup },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.special_paste_selectAll },
            [
              _c("VgpButton", {
                attrs: {
                  type: "text",
                  label: _vm.isAllSelected
                    ? _vm.locale.unselectAll
                    : _vm.locale.selectAll,
                  small: "",
                },
                on: { onClick: _vm.onClickAll },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gantt_scroll",
              class: _vm.$style.special_paste_body,
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "div",
                {
                  key: "special-option" + item.id,
                  class: _vm.$style.special_paste_option,
                },
                [
                  _c("VgpCheckbox", {
                    attrs: {
                      label: item.label,
                      "label-position": "right",
                      size: 16,
                      checked: _vm.isSelected(item.id),
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.onSelectOption(item.id)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.special_paste_footer },
            [
              _c("VgpButton", {
                class: _vm.$style.special_paste_footer__button,
                attrs: { type: "primary", label: _vm.locale.okBtn, small: "" },
                on: { onClick: _vm.onClickOklBtn },
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.special_paste_footer__checkbox },
                [
                  _c("VgpCheckbox", {
                    key: "show_always_" + _vm.options.length,
                    attrs: {
                      label: _vm.locales("sp_popup_dont_show_checkbox"),
                      "label-position": "right",
                      size: 16,
                      checked: !_vm.showAlwaysPopup,
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.onClickShowAlwaysPopup()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }