var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-description" }, [
    _c("div", { staticClass: "main-title" }, [
      _c("div", { staticClass: "text" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.removeIntegrationButton && !_vm.tablePreloder
        ? _c(
            "div",
            { staticClass: "button", on: { click: _vm.removeIntegration } },
            [
              _c("div", { staticClass: "button-text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.locales("integration_msteams_settings_page_remove")
                    ) +
                    "\n      "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.removeIntegrationButton && !_vm.tablePreloder
        ? _c(
            "div",
            { staticClass: "button", on: { click: _vm.disableIntegration } },
            [
              _c("div", { staticClass: "button-text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.locales("integration_msteams_settings_page_disable")
                    ) +
                    "\n      "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "description" }, [
      _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }