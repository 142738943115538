<template>
  <context-menu
    :position="positionContext"
    @closePopup="$emit('close')"
  >
    <template #body>
      <ul :class="$style.menu">
        <li :class="$style.menu__item">
          <label class="d-flex align-center">
            <svg-sprite
              name="project"
              type="regular"
            />
            <span :class="$style.menu__item_text">
              {{ locales('task_settings_upload_desctop') }}
            </span>
            <input
              type="file"
              hidden
              multiple
              @change="handleFilesUpload"
            >
          </label>
        </li>
        <li
          :class="$style.menu__item"
          @mouseenter="showSubmenu"
          @mouseleave="submenuPositionContext = null"
        >
          <svg-sprite
            name="google-drive"
            type="color-regular"
          />
          <span :class="$style.menu__item_text">
            {{ locales('task_settings_google_drive_text') }}
          </span>
          <svg-sprite
            name="arrow-right"
            type="regular"
            :class="$style['arrow-right-icon']"
          />
          <context-menu
            v-if="submenuPositionContext"
            :position="submenuPositionContext"
            side="right"
            :offset="{right: 1, top: -5}"
          >
            <template #body>
              <ul :class="[$style.menu, $style.submenu]">
                <li
                  v-for="item in googleDriveActions"
                  :key="item.id"
                  :class="$style.menu__item"
                  @click="handleSubmenuItemClick(item)"
                >
                  <span :class="$style.menu__item_text">
                    {{ item.label }}
                    <b
                      v-if="item.labelBold"
                      :title="item.labelBold"
                    >
                      {{ item.labelBold }}
                    </b>
                  </span>
                </li>
                <li :class="$style.menu__item_divider">
                  <span>{{ locales('settings_google_drive_create_new') }}</span>
                </li>
                <li
                  v-for="(item, index) in googleNewFileItems"
                  :key="index"
                  :class="$style.menu__item"
                  @click="createNewGoogleFile(item.id)"
                >
                  <svg-sprite
                    :name="item.icon"
                    type="color-regular"
                  />
                  <span :class="$style.menu__item_text">
                    {{ item.label }}
                  </span>
                </li>
              </ul>
            </template>
          </context-menu>
        </li>
        <li
          :class="$style.menu__item"
          @mouseenter="showOneDriveSubmenu"
          @mouseleave="submenuOneDrivePositionContext = null"
        >
          <svg-sprite
            name="one-drive"
            type="color-regular"
          />
          <span :class="$style.menu__item_text">{{ locales('task_settings_one_drive_text') }}</span>
          <svg-sprite
            name="arrow-right"
            type="regular"
            :class="$style['arrow-right-icon']"
          />
          <context-menu
            v-if="submenuOneDrivePositionContext"
            :position="submenuOneDrivePositionContext"
            :offset="{right: 1, top: -7}"
            side="right"
          >
            <template #body>
              <ul :class="[$style.menu, $style.submenu]">
                <li
                  v-for="item in oneDriveActions"
                  :key="item.id"
                  :class="$style.menu__item"
                  @click="item.handler"
                >
                  <span :class="$style.menu__item_text">
                    {{ item.label }}
                    <b
                      v-if="item.labelBold"
                      :title="item.labelBold"
                    >
                      {{ item.labelBold }}
                    </b>
                  </span>
                </li>
              </ul>
            </template>
          </context-menu>
        </li>
      </ul>
    </template>
  </context-menu>
</template>

<script>

import { mapGetters } from 'vuex';
import GoogleDriveAttachmentsController from '../../../controllers/googleDrive';
import humanFileSize from '$$helpers/fileSize';

// OneDrive v8
// import OneDriveAttachmentsController from '../../../controllers/oneDrive';
// OneDrive v7.2
import OneDriveAttachmentsController from '../../../controllers/oneDrive/onedrive_v7_2';

export default {
  name: 'NewAttachmentContextMenu',
  inject: ['isFieldDisabled'],
  props: {
    positionContext: {
      type: Object,
      required: true,
    },
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      submenuPositionContext: null,
      submenuOneDrivePositionContext: null,
      googleNewFileItems: [
        {
          id: 'application/vnd.google-apps.document',
          icon: 'google-docs',
          label: __('settings_google_drive_create_word'),
        },
        {
          id: 'application/vnd.google-apps.spreadsheet',
          icon: 'google-sheet',
          label: __('settings_google_drive_create_sheets'),
        },
        {
          id: 'application/vnd.google-apps.drawing',
          icon: 'google-draw',
          label: __('settings_google_drive_create_drawings'),
        },
        {
          id: 'application/vnd.google-apps.presentation',
          icon: 'google-pres',
          label: __('settings_google_drive_create_slides'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      googleDriveUser: 'attachments/getGoogleUser',
      oneDriveUser: 'attachments/getMicrosoftUser',
    }),
    googleDriveActions() {
      const items = [{
        id: 'add',
        label: __('settings_google_drive_add'),
        handler: () => GoogleDriveAttachmentsController.pickFiles(this.taskData),
      }];

      if (this.googleDriveUser) {
        items.push({
          id: 'changeAccount',
          label: `${__('settings_google_drive_another_account')}`,
          labelBold: this.googleDriveUser.email,
          handler: () => GoogleDriveAttachmentsController.changeAccount(),
        });
        items.push({
          id: 'changeFolder',
          label: `${__('settings_google_drive_another_folder')}`,
          labelBold: this.googleDriveUser.folder_name || __('settings_google_drive_root'),
          handler: () => GoogleDriveAttachmentsController.changeFolder(),
        });
      }

      return items;
    },
    oneDriveActions() {
      const items = [];

      // OneDrive v8
      // if (this.oneDriveUser) {
      //   items.push({
      //     id: 'add',
      //     label: __('settings_microsoft_one_drive_add'),
      //     handler: this.handlePickOneDriveFile,
      //   });
      //   items.push({
      //     id: 'changeAccount',
      //     label: `${__('settings_microsoft_one_drive_another_account')}`,
      //     labelBold: this.oneDriveUser.email,
      //     handler: () => OneDriveAttachmentsController.changeAccount(),
      //   });
      // } else {
      //   items.push({
      //     id: 'addAccount',
      //     label: `${__('settings_microsoft_one_drive_add_account')}`,
      //     handler: () => OneDriveAttachmentsController.addAccount(),
      //   });
      // }

      // OneDrive v7.2
      items.push({
          id: 'add',
          label: __('settings_microsoft_one_drive_add'),
          handler: this.handlePickOneDriveFile,
        });
      // items.push({
      //   id: 'changeAccount',
      //   label: `${__('settings_microsoft_one_drive_another_account')}`,
      //   handler: () => OneDriveAttachmentsController.changeAccount(this.taskData),
      // });

      return items;
    },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.oneDriveFileUploaded);
  },
  methods: {
    oneDriveFileUploaded(event) {
      if (event.data.type === 'onedriveFileUploaded') {
        window.removeEventListener('message', this.oneDriveFileUploaded);
        this.$emit('attachment-created');
        this.$emit('close');
      }
    },
    handlePickOneDriveFile() {
      window.addEventListener('message', this.oneDriveFileUploaded);
      OneDriveAttachmentsController.pickFiles(this.taskData);
    },
    async handleFilesUpload(event) {
      if (this.isFieldDisabled && this.isFieldDisabled('attachments')) return;

      const { files } = event.target;

      if (!files.length) return;

      await this.$store.dispatch('attachments/createAttachment', {
        projectId: this.taskData.gantt_id,
        taskId: this.taskData.id,
        fileList: files,
      }).catch(error => {
        if (error?.response?.data?.code === 4005) {
          this.$toast.error(this.locales('fileUpload_limitStore', { limit: '' }));
        }

        return Promise.reject(error);
      });

      this.$emit('attachment-created');
      this.$emit('close');
    },
    async handleSubmenuItemClick(item) {
      await item.handler();
      if (item.id === 'add') {
        this.$emit('attachment-created');
      }
      this.$emit('close');
    },
    showSubmenu(event) {
      this.submenuPositionContext = event.currentTarget.getBoundingClientRect();
    },
    showOneDriveSubmenu(event) {
      this.submenuOneDrivePositionContext = event.currentTarget.getBoundingClientRect();
    },

    async createNewGoogleFile(id) {
      await GoogleDriveAttachmentsController.createDocument(id, this.taskData);
      this.$emit('attachment-created');
      this.$emit('close');
    },
  },
};
</script>

<style module lang="less">

.menu {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  background: #FFFFFF;
  border-radius: 4px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 4px 12px;

    label {
      cursor: pointer;
    }

    &_text {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #191919;
      margin-left: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .icon ~ & {
        margin-left: 0;
      }
    }

    &_divider {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 4px 12px;

      span {
        font-family: Lato-Regular, sans-serif;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #999999;
        width: fit-content;
      }

      &::after {
        content: '';
        height: 1px;
        flex-grow: 1;
        background: #E5E5E5;
      }
    }

    &:hover {
      background: #F2F2F2;
    }
  }

  .arrow-right-icon {
    color: #B2B2B2;
    margin-left: auto;
  }
}

.submenu {
  width: 287px;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}

</style>
