var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "project_title_msteams" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.locales("integration_settings_page_table_header_project")
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "team_title_msteams" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.locales("integration_msteams_settings_page_table_header_team")
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "channel_title_msteams" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.locales(
              "integration_msteams_settings_page_table_header_channel"
            )
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "settings_title_msteams" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.locales("integration_settings_page_table_header_settings")
          ) +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }