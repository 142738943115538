const elementIsVisibleInViewport = (el, elementContainer = null, partiallyVisible = false) => {
  const {
    top, left, bottom, right,
  } = el.getBoundingClientRect();

  let height, width;

  if (elementContainer) {
    const elementContainerNode = document.querySelector(`.${elementContainer}`);
    height = elementContainerNode.offsetHeight;
    width = elementContainerNode.offsetWidth;
  } else {
    height = window.innerHeight;
    width = window.innerWidth;
  }

  return partiallyVisible
    ? ((top > 0 && top < height)
      || (bottom > 0 && bottom < height))
    && ((left > 0 && left < width) || (right > 0 && right < width))
    : top >= 0 && left >= 0 && bottom <= height && right <= width;
};

export default elementIsVisibleInViewport;
