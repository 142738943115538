<template>
  <core-popup
    id="gp_autotest_create_resource_popup"
    :show="isShow"
    :class="popup.create_resource_popup"
  >
    <template #body>
      <div :class="popup.create_resource_popup_body">
        <div :class="popup.title">
          {{ locales('resources_create_resource_title') }}
        </div>
        <div :class="popup.info_panel">
          <vgp-label-slot :label="locales('add_resource_name_label')">
            <template #body>
              <vgp-input
                :placeholder="locales('common_placeholder_name')"
                type="text"
                :small="true"
                gp-autotest-name="gp_autotest_create_resources_popup_input_name"
                @onChange="inputChange"
                @input="debouncedValidateName"
              />
              <div
                v-if="isExistResourceName"
                id="gp_autotest_create_resources_popup_input_warning_message"
                :class="popup.warning_message"
              >
                <sprite
                  :name="'warning-1'"
                  :type="'bold'"
                  :size="20"
                />
                <p :class="popup.warning_message_text">
                  {{ locales('create_popup_exist_warning') }}
                </p>
              </div>
            </template>
          </vgp-label-slot>
          <div :class="popup.colors_wrapper">
            <vgp-label-slot :label="locales('common_color')">
              <template #body>
                <color-palette
                  gp-autotest-name="gp_autotest_create_resources_popup_color_palette_color"
                  select-type="single"
                  select-field="select"
                  :selected-values="[17]"
                  :colors="colors"
                  :select-width="72"
                  :select-height="32"
                  @selectColors="getColor"
                />
              </template>
            </vgp-label-slot>
          </div>
        </div>
        <div :class="popup.project_dropdown">
          <vgp-label-slot :label="locales('add_resource_projects_label')">
            <template #body>
              <dropdown-select
                gp-autotest-name="gp_autotest_create_resources_popup_dropdown_select_projects"
                :drop-list="dropdownProjects"
                :multiselect="true"
                :use-select-all="true"
                :use-search="true"
                :popup-max-height="180"
                :show-reset-btn="false"
                :highlight-active-state="false"
                :selected="selectedProjects"
                :placeholder="locales('create_resource_projects_drop_placeholder')"
                @selected="onSelect"
              />
            </template>
          </vgp-label-slot>
        </div>
        <div :class="popup.buttons">
          <vgp-button
            id="gp_autotest_create_resources_popup_btn_cancel"
            type="secondary"
            :label="locales('common_cancel')"
            :small="true"
            @onClick="onClose"
          />
          <vgp-button
            id="gp_autotest_create_resources_popup_btn_create"
            :style="{marginLeft: 16 + 'px'}"
            type="primary"
            :label="locales('common_create')"
            :small="true"
            :disabled="isDisableBtn"
            @onClick="onCreateResource"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import CorePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import VgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import ColorPalette from '$$vueCmp/colorPalette/colorPalette.vue';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import VgpInput from '$$vueCmp/vInput/vgpInput.vue';
import DropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import sprite from '../../../common/VueComponents/VueIcon/svgSprite.vue';

export default {
  components: {
    CorePopup,
    VgpButton,
    ColorPalette,
    VgpLabelSlot,
    VgpInput,
    DropdownSelect,
    sprite,
  },
  inject: ['checkExistResourceName'],
  props: {
    isShow: { type: Boolean, required: true, default: false },
    projects: { type: Array, required: true, default() { return []; } },
  },
  emits: {
    onClose: Boolean,
    onCreate: Object,
  },
  data() {
    return {
      locales: __,
      selectedProjects: [],
      resourceName: '',
      colorId: 17,
      isDisableBtn: true,
      debouncedValidateName: null,
      isExistResourceName: false,
    };
  },
  computed: {
    colors() {
      return this.$store.getters['teamSetting/colors'].data;
    },
    dropdownProjects() {
      return this.projects.filter(pr => !pr.is_jira);
    },
  },
  watch: {
    projects: {
      handler(newProjects) {
        const selectedProjects = this.selectedProjects.filter(project => newProjects.some(pr => pr.id === project.id));

        this.selectedProjects = [...selectedProjects];
      },
    },
    immediate: true,
  },
  created() {
    this.debouncedValidateName = _.debounce(this.onCheckName, 200).bind(this);
  },
  methods: {
    onSelect(projects) {
      this.selectedProjects = projects;
    },
    inputChange(e, val) {
      this.resourceName = val;
    },
    getColor(data) {
      const id = data[0].id;

      this.colorId = id;
    },
    onCreateResource() {
      this.$emit('onCreate', {
        name: this.resourceName,
        projectIds: this.selectedProjects.map(pr => pr.gantt_id),
        colorId: this.colorId,
      });
      this.onClose();
    },
    onClose() {
      this.isDisableBtn = true;
      this.selectedProjects = [];
      this.resourceName = '';
      this.colorId = 17;
      this.$emit('onClose');
    },
    onCheckName(name) {
      this.isExistResourceName = this.checkExistResourceName(name);
      this.isDisableBtn = false;

      if (name.trim().length > 255) {
        this.isDisableBtn = true;
        this.$toast.warning(__('change_resource_name_is_long_name'));
      }

      if (name.trim() === '') {
        this.isDisableBtn = true;
      }
    },
  },
};
</script>

<style module='popup' src='./popups.less'></style>
