import globalStore from '../../../store/main';
import getCurrentUserId from '../helpers/getCurrentUserId';
import constants from '../../../../protected/extensions/constants';
import projectRoles from '../project/index';

export default {
  isOwner() {
    const role = this.getCurrentUserRole();

    return role?.defaultType === constants.ROLES.DEFAULT_TYPE.OWNER;
  },
  isKingMode() {
    const role = this.getCurrentUserRole();

    return role?.rights.find(bitObj => bitObj.bit === 2)?.status;
  },
  isUserOwner(user_id) {
    const role = this.getUserRole(user_id);

    return role?.defaultType === constants.ROLES.DEFAULT_TYPE.OWNER;
  },
  isUserKingMode(user_id) {
    const role = this.getUserRole(user_id);

    return role?.rights.find(bitObj => bitObj.bit === 2)?.status;
  },
  getUserRole(userId) {
    const roleId = globalStore.getters['teamSetting/getUserAccountRoleIdByUserId'](userId);
    const role = roleId && globalStore.getters['roles/getAccountRoleById'](roleId);

    return role;
  },
  getCurrentUserRole() {
    const userId = getCurrentUserId();

    return this.getUserRole(userId);
  },
  hasRight(bitKey) {
    const role = this.getCurrentUserRole();

    if (this.isOwner()) {
      return true;
    }

    return role?.rights.find(bitObj => bitObj.bitKey === bitKey)?.status;
  },
  getRolesByBits(bitKeys) {
    const roles = globalStore.getters['roles/getAccountRoles'];

    return roles.filter(role => {
      const match = bitKeys.filter(bitKey => role.rights.find(bitObj => bitObj.bitKey === bitKey)?.status);

      return match.length === bitKeys.length;
    });
  },
  checkRightByArrayOfBits(array) {
    const role = this.getCurrentUserRole();

    return array.some(bitKey => role?.rights.find(bitObj => bitObj.bitKey === bitKey)?.status);
  },

  hasRightToActionsWithProjects(ganttId) {
    const bitKey = 'project_create';
    const role = this.getCurrentUserRole();

    if (projectRoles.isOwner(+ganttId) || this.isOwner() || this.isKingMode()) {
      return true;
    }
    if (!role) {
      return false;
    }

    return role?.rights.find(bitObj => bitObj.bitKey === bitKey)?.status;
  },

};
