<template>
  <div :class="['v-global-input', css ? css : '', isError ? 'is-error' : '']">
    <label
      v-if="label"
      class="label"
    >
      {{ label }}
    </label>
    <span
      v-if="errorText"
      class="error-text"
    >
      {{ errorText }}
    </span>
    <input
      :id="id ? id : ''"
      v-model="inputValue"
      :class="disable ? 'not_editable' : ''"
      :type="type ? type : null"
      :placeholder="placeholder ? placeholder : ''"
      :readonly="disable"
      @change="onHandleChange"
      @input="onHandleInput"
    >
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'label',
    'placeholder',
    'value',
    'type',
    'css',
    'handleChange',
    'handleInput',
    'isDisable',
    'isError',
    'errorText',
  ],
  data() {
    return {

    };
  },
  computed: {
    inputValue() {
      return this.value;
    },
    disable() {
      return this.isDisable;
    },
  },
  methods: {
    onHandleChange(e) {
      if (this.handleChange) {
        e.target.blur();

        if (!e.target.value.trim()) {
          const oldValue = this.value;
          this.value = '';
          this.value = oldValue;

          return;
        }

        this.handleChange(e.target.value);
      }
    },
    onHandleInput(e) {
      if (this.handleInput) {
        this.handleInput(e.target.value);
      }
    },
  },
};
</script>

<style scoped src='./vInput.less'></style>
