<template>
  <div>
    <webix-ui :config="webixUI" />
  </div>
</template>

<script>
import app from '../../app';
import globalStore from '../../store/main';
import rights from '../../components/rights';
import { webixUI, init, cleanup } from './_noProject';

export default {
  name: 'NoProject',
  data() {
    return {
      webixUI,
      listeners: [],
    };
  },
  created() {
    this.listeners.push(app.on('onAfterCollaboration', ({ event, projects }) => {
      if (event === 'ProjectRestored') {
        const isKingMode = rights.account.isKingMode();
        const resources = globalStore.getters['resourcesModel/getResourcesByProjectId'](projects[0]);

        if (isKingMode || resources.some(resource => resource.userId === user.id)) {
          window.location.reload();
        }
      }
    }));
  },
  mounted() {
    this.$nextTick(() => {
      init();
    });
  },
  beforeDestroy() {
    cleanup();
    this.listeners.forEach(id => app.off(id));
  },
};
</script>
