<template>
  <context-menu
    v-if="currentConfig"
    :key="currentConfig.controlNodeId"
    :position="currentConfig.positionContext"
    :style="{ padding: 0, overflowY: 'unset' }"
    horizontally-align-center
    :offset="{ top: -6 }"
  >
    <template #body>
      <notify-popup-slot
        v-bind="currentConfig"
        @close="handleOnCloseNotificationPopup"
        @click="handleOnClickNotificationPopup"
        @mouseover="toggleControlHighlight(true, $event)"
        @mouseleave="toggleControlHighlight(false, $event)"
      />
    </template>
  </context-menu>
</template>

<script>

import { api } from '$$store/api';
import { cloneDeep, throttle } from 'lodash';
import NotifyPopupSlot from './notifyPopupSlot.vue';
import { NOTIFICATION_POPUP_CONFIG, NOTIFICATION_POPUP_TYPE } from './notifyPopup.config';

export default {
  name: 'NotifyPopup',
  components: {
    NotifyPopupSlot,
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(NOTIFICATION_POPUP_TYPE).includes(value);
      },
    },
  },
  emits: {
    close: null,
    click: String,
    highlight: Boolean,
  },
  data() {
    return {
      defaultConfigMap: NOTIFICATION_POPUP_CONFIG,
      currentConfig: null,
    };
  },
  watch: {
    type: {
      handler(val) {
        if (val) {
          this.setNotificationConfigByType(val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.getNewPositionContext);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getNewPositionContext);
  },
  methods: {
    async setNotificationConfigByType(type) {
      const config = cloneDeep(this.defaultConfigMap[type]);
      const $controlElement = document.getElementById(config.controlNodeId);

      if (!$controlElement) {
        return;
      }

      if (type === NOTIFICATION_POPUP_TYPE.NEWS) {
        config.content = await this.getLastNewsContent();
      }

      config.positionContext = $controlElement.getBoundingClientRect();

      this.currentConfig = config;
    },
    handleOnCloseNotificationPopup() {
      this.$emit('close');
      this.currentConfig.onCloseClickHandler();
      userExtAnalytics.log('app_notification_popup_closed');
    },
    handleOnClickNotificationPopup({ target }) {
      if (target.closest('.closer-icon')) {
        return;
      }

      this.$emit('click', this.currentConfig.attachedToRefName);
    },
    getLastNewsContent() {
      return api.get('/newshistory', {
        params: {
          start: 0,
          count: 1000,
        },
      })
        .then(({ data }) => {
          const { data: newsItems } = data;
          const sortedItems = newsItems.sort((a, b) => b.idTemplate - a.idTemplate);

          if (!sortedItems.length) {
            return '';
          }

          return sortedItems[0].header;
        })
        .catch(console.error);
    },
    toggleControlHighlight(highlightEnabled, event) {
      if (!this.currentConfig) {
        return;
      }

      if (event.target.closest('.closer-icon')) {
        this.$emit('highlight', false);

        return;
      }
      this.$emit('highlight', highlightEnabled);
    },
    getNewPositionContext: throttle(function () {

      const $controlElement = document.getElementById(this.currentConfig.controlNodeId);

      $controlElement && (this.$set(this.currentConfig, 'positionContext', $controlElement.getBoundingClientRect()));
    }, 50),
  },
};
</script>

<style scoped>

</style>
