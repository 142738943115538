var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: "",
      "body-style": "noPaddings",
      "background-image":
        "https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          var _obj
          return [
            !_vm.demoSchedulerShown
              ? [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.$style.body__wrapper,
                        ((_obj = {}),
                        (_obj[_vm.$style.body__wrapper_wide] =
                          _vm.isBodyWrapperWide),
                        _obj),
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$style.body__header,
                            _vm.isBodyWrapperWide
                              ? _vm.$style.body__header_mb33
                              : "",
                          ],
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.$style.logo },
                            [
                              _c("vgp-svg-image", {
                                attrs: { name: "logo", color: "#003D36" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.body__content },
                        [
                          _c(_vm.currentComponent, {
                            tag: "component",
                            attrs: { "team-label": _vm.teamLabel },
                            on: {
                              "demo-scheduler-opened": function ($event) {
                                _vm.demoSchedulerShown = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isOnboardingWizard && !_vm.isBookDemo
                        ? _c(
                            "div",
                            { class: _vm.$style.body__footer },
                            [
                              _c("div", { class: _vm.$style.progress }, [
                                _c("div", {
                                  class: _vm.$style.progress__bar,
                                  style: { width: _vm.progressBarWidth + "%" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("portal-target", {
                                key: _vm.currentComponent,
                                attrs: { slim: "", name: "onboarding-footer" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }