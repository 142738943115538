export default {
  getAllData: state => state,
  getAccountRoles: state => state?.account?.roles,
  getAccountRights: state => state?.account?.rights,
  getProjectRoles: state => state?.project?.roles,
  getProjectRights: state => state?.project?.rights,
  getEditModeStatus: state => state?.editMode?.status,
  getEditModeData: state => state?.editMode,

  getAccountRoleById: state => id => state?.account?.roles.find(role => role.id === id) || null,
  getProjectRoleById: state => id => state?.project?.roles.find(role => role.id === id) || null,
};
