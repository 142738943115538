import constants from '../../helpers/constants';

export default {
  ROLES: {
    [constants.ROLES.OWNER]: 'Owner',
    [constants.ROLES.ADMIN]: 'Admin',
    [constants.ROLES.MEMBER]: 'Member',
  },
  PLANS: {
    TEAM_TRIAL: constants.PLANS.TEAM_TRIAL,
    BASE: constants.PLANS.BASE,
    PRO: constants.PLANS.PRO,
    BUSINESS: constants.PLANS.BUSINESS,
    ENTERPRISE_TEAM: constants.PLANS.ENTERPRISE_TEAM,
    ENTERPRISE: constants.PLANS.ENTERPRISE,
  },
  PLAN_NAMES: {
    [constants.PLANS.TEAM_TRIAL]: 'Trial',
    [constants.PLANS.BASE]: 'Basic',
    [constants.PLANS.PRO]: 'Pro',
    [constants.PLANS.BUSINESS]: 'Business',
    [constants.PLANS.ENTERPRISE_TEAM]: 'Enterprise-Team',
    [constants.PLANS.ENTERPRISE]: 'Enterprise',
  },
  EVENT_TYPES: {
    SUBSCRIPTION_CHANGED: 'SUBSCRIPTION_CHANGED',
    SUBSCRIPTION_RENEWED: 'SUBSCRIPTION_RENEWED',
    SUBSCRIPTION_SUSPENDED: 'SUBSCRIPTION_SUSPENDED',
    OWNER_CHANGED: 'OWNER_CHANGED',
    USERS_NUMBER_CHANGED: 'USERS_NUMBER_CHANGED',
    COMMENT_ADDED: 'COMMENT_ADDED',
    EMAIL_CHANGED: 'EMAIL_CHANGED',
    EXPIRATION_DATE_CHANGED: 'EXPIRATION_DATE_CHANGED',
    FREE_SUBSCRIPTION: 'FREE_SUBSCRIPTION',
    PRICE_PER_USER_CHANGED: 'PRICE_PER_USER_CHANGED',
  },

  PAYMENT_TYPE_COLORS: {
    OrderCharged: '#28a745', // Green for successful payment
    OrderRefunded: '#007bff', // Blue for refunded payment
    OrderChargedBack: '#dc3545', // Red for chargeback
    OrderDeclined: '#ffc107', // Yellow for declined order
    OrderPartiallyRefunded: '#17a2b8', // Teal for partial refund
    SubscriptionChargeSucceed: '#28a745', // Green for successful subscription charge
    SubscriptionChargeFailed: '#dc3545', // Red for failed subscription charge
    SubscriptionSuspended: '#ffc107', // Yellow for suspended subscription
    SubscriptionRenewed: '#28a745', // Green for renewed subscription
    SubscriptionTerminated: '#dc3545', // Red for terminated subscription
    SubscriptionFinished: '#6c757d', // Grey for finished subscription
    OrderProcessing: '#17a2b8', // Teal for order processing
    PartnerActivate: '#28a745', // Green for partner activation
    PartnerDeactivate: '#dc3545', // Red for partner deactivation
    TrialActive: '#17a2b8', // Teal for active trial period
  },

  PERIOD: {
    0: 'Month',
    1: 'Annual',
  },
  PERIOD_PAYPRO: {
    0: 3,
    1: 4,
  },
  ADMIN_PANEL: {
    DATE_FORMAT: 'YYYY-MM-DD HH:mm',
  },
  UPDATE_PANEL: {
    DATE_FORMAT: 'YYYY-MM-DD',
    WEBIX_DATE_FORMAT: '%Y-%m-%d',
  },
  PROVIDER: {
    paypro: 'PayPro',
    '2checkout': '2Checkout',
    ganttpro: 'GanttPro',
  },
  SUBSCRIPTION_STATUS: {
    ACTIVE: 'active',
    SUSPENDED: 'suspended',
  },
};
