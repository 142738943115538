/* eslint-disable */
import app from '../../app';
import moment from '../../libs/moment';
import _ from '../../libs/lodash';

import projectsModel from '../../models/projects';
import ganttViewModel from '../../models/ganttViewModel';
import baselineModel from '../../models/baselines';
import historyModel from '../../models/history';

import teamComponent from '../../components/team/main';

import plansModel from '../../models/plans';
import dateFormatHelper from '../../helpers/dateFormats';
import customHelper from '../../helpers/custom';
import rights from '../../components/rights';
import pricingHelper from '../../helpers/pricingHelper';

import settingsZoom from '../settings/include/settingsZoom';

import icon_filter from '../../svg/header_buttons/filter.svg';
import icon_settings from '../../svg/webix_material/icon-settings.svg';
import icon_dependencies from '../../svg/webix_material/icon-dependencies.svg';
import icon_resource from '../../svg/default/default-ava.svg';
import icon_magic from '../../svg/history/magic.svg';
import icon_task from '../../svg/history/task.svg';
import icon_masschange from '../../svg/history/ic_mass_change_history.svg';
import icon_masschange_f from '../../svg/history/ic_mass_change_history_filter.svg';
import icon_backup from '../../svg/history/history.svg';
import icon_right from '../../svg/history/left_arrow.svg';
import icon_left from '../../svg/history/right_arrow.svg';
import historylist_minimize from '../../svg/history/historylist_minimize.svg';
import icon_calendar from '../../svg/history/calendar.svg';
import icon_folder from '../../svg/history/folder+.svg';
import icon_column from '../../svg/history/column.svg';
import icon_team from '../../svg/webix_material/team_icon.svg';
import icon_custom_day from '../../svg/history/custom-calendar.svg';
import icon_move from '../../svg/history/move.svg';
import icon_copy from '../../svg/history/cc-new.svg';
import icon_status from '../../svg/history/status.svg';
import icon_priority from '../../svg/history/priority.svg';
import baseline_icon from '../../svg/baselines/baseline.svg';
import logInternalHelper from '../../helpers/logInternal';
import icon_cascade from '../../svg/history/cascade.svg';
import icon_convertation from '../../svg/history/convertation.svg';

const __ = window.__;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

var innerHelpers = {
  getTemplateForHeader(event) {
    if (!event) {
      return this.getTemplateForBackButton();
    }

    const currentProject = projectsModel.getProjectDataById(event.gantt_id);

    const accessToRestoreHistory = rights.project.hasRight(event.gantt_id, 'history_restore');

    return this.getTemplateForBackButton()
      + this.getTemplateHeaderForEventInfo(event)
      + (currentProject.is_archived === 1 ? '' : this.getTemplateForCreateBaselineButton())
      + this.getTemplateHeaderForSlideButtons()
      + ((currentProject.is_jira === 1 || currentProject.is_archived === 1 || !accessToRestoreHistory) ? '' : this.getTemplateForRestoreButton());
  },
  getTemplateFilter(value, key, id) {
    const keyValue = (id || id === 0) ? id : '';

    const formatedValue = id === 0 ? __('jira_history_event_label') : customHelper.formatTaskName(value);

    return `${"<div class='history-window-filter-selected-item'>"
      + "<div class='history-window-filter-selected-item-value'>"}${formatedValue}</div>`
      + `<div class='history-window-filter-selected-item-close' data-key='${key}' data-value='${keyValue}'></div>`
      + '</div>';
  },
  getTemplateForBackButton() {
    return "<div class='history-window-header-close'>"
      + "<div class='history-window-header-close-bar'>"
      + "<div class='history-window-header-close-bar-icon'>"
      + '</div>'
      + '</div>'
      + '</div>';
  },
  getTemplateHeaderForSlideButtons() {
    return `${"<div class='history-window-header-slide-buttons'>"
      + "<div class='history-window-header-slide-buttons-left'>"}${icon_left}</div>`
      + `<div class='history-window-header-slide-buttons-right'>${icon_right}</div>`
      + '</div>';
  },
  getTemplateForRestoreButton() {
    return `<div class='history-window-header-button history-window-header-restore'>${__('history_restore_version')}</div>`;
  },
  getTemplateForCreateBaselineButton() {
    const hasBaselineAccess = rights.project.hasRight(gantt.config.gantt_id, 'gantt_chart');

    return hasBaselineAccess
      ? `${"<div class='history-window-header-baseline tooltip-gantt' "
      + "data-key='history_window_header_baseline' data-position='bottom'>"}${baseline_icon}</div>` : '';
  },
  getTemplateHeaderForEventInfo(event) {
    if (!event) {
      return "<div class='history-window-header-event'></div>";
    }

    return `${"<div class='history-window-header-event'>"
      + "<div class='history-window-header-event-icon'>"}${this.getIconAndClassByEvent(event).icon}</div>`
      + `<div class='history-window-header-event-desc tooltip-gantt' data-key="${innerHelpers.getEventName(event)}">${innerHelpers.getEventName(event)}</div>`
      + `<div class='history-window-header-event-date'>${moment(event.created_at).calendar()}</div>`
      + `<div class='history-window-header-event-user' style='background: url(${event.user_pic}); background-size: contain;'></div>`
      + '</div>';
  },
  getTemplateListItem(obj) {
    if (obj.customTemplate) {
      return this.getTemplateListItemDate(obj);
    }

    return this.getTemplateListItemEvent(obj);
  },
  getTemplateListItemDate(obj) {
    return `<div class='history-event-item date'>${moment(obj.value).format('Do MMMM YYYY')}</div>`;
  },
  getTemplateListItemEvent(obj) {
    const data = innerHelpers.getIconAndClassByEvent(obj);
    const isJira = obj.action_description.data && obj.action_description.data.isJira;

    let icon = '';

    if (isJira) {
      icon = "<div class='history-event-item-jira-icon'></div>";
    } else if (obj.withHash) {
      icon = "<div class='history-event-item-g-icon'></div>";
    }

    return `<div class='history-event-item ${obj.azure_key ? '' : 'not-ready-yet'} ${data.css} ${obj.action_hash ? 'group' : ''}'>`
      + `<div class='history-event-item-icon ${data.css}'>${data.icon}</div>${
        icon
      }<div class='history-event-item-desc'><div>${innerHelpers.getEventName(obj)}</div></div>`
      + `<div class='history-event-item-user' style='background: url(${obj.user_pic}); background-size: contain;'></div>`
      + `<div class='history-event-item-date'>${moment(obj.created_at).calendar(null, {
        sameElse: dateFormatHelper.getUserDateFormat(),
      })}</div>`
      + '</div>';
  },
  getTemplateForGroupButtons() {
    let template = '';

    for (let i = 0; i < innerSettings.modeToZoomTitle.length; i++) {
      template += `<div class='history-window-filter-main-slider-item ${innerSettings.currentZoom === i ? 'active' : ''}' data-value='${i}'>${innerSettings.modeToZoomTitle[i]}</div>`;
    }

    return template;
  },
  getTemplateGroupListItem(obj) {
    if (obj.customTemplate) {
      return `<div class='history-event-item date'>${moment(obj.value).format(innerSettings.zoomFormats[innerSettings.currentZoom])}</div>`;
    }

    return `${"<div class='history-event-item open-events'>"
      + "<div class='history-event-item-icon'>"}${icon_folder}</div>`
      + `<div class='history-event-item-desc'><div>${moment(obj.date).format(innerSettings.zoomFormats[innerSettings.currentZoom - 1])}</div></div>`
      + '<div class=\'history-event-item-user\'></div>'
      + `<div class='history-event-item-date'>${obj.times.length} changes</div>`
      + '</div>';
  },
  getEventName(obj) {
    return obj.text.replace(/<b>/g, '#b#')
      .replace(/<\/b>/g, '#/b#')
      .replace(/<strong>/g, '#strong#')
      .replace(/<\/strong>/g, '#/strong#')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replaceAll('#b#', '<b>')
      .replaceAll('#/b#', '</b>')
      .replaceAll('#strong#', '<strong>')
      .replaceAll('#/strong#', '</strong>');
  },
  getIconAndClassByEvent(item) {
    let iconType = '';
    let iconCSS = '';

    if (item.action_id === 71) {
      iconCSS = 'tasks';
      iconType = icon_task;
    } else {
      switch (~~(item.action_id / 10)) {
      case 17:
        iconCSS = '',
        iconType = icon_convertation;
        break;
      case 15:
        iconCSS = '',
        iconType = icon_masschange;
        break;
      case 16:
        iconCSS = '',
        iconType = icon_cascade;
        break;
      case 12:
        iconCSS = 'column';
        iconType = icon_column;
        break;
      case 11:
        iconCSS = 'copy';
        iconType = icon_copy;
        break;
      case 10:
        if (item.action_description.data && item.action_description.data.eventName === 'status') {
          iconCSS = item.action_description.data.eventName;
          iconType = icon_status;
        } else if (item.action_description.data && item.action_description.data.eventName === 'priority') {
          iconCSS = item.action_description.data.eventName;
          iconType = icon_priority;
        }
        break;
      case 9:
        iconCSS = 'backup';
        iconType = icon_backup;
        break;
      case 7:
        iconCSS = 'magic';
        iconType = icon_magic;
        break;
      case 6:
        iconCSS = 'tasks';
        iconType = icon_task;
        break;
      case 8:
        iconCSS = 'tasks';
        iconType = icon_move;
        break;
      case 5:
        iconCSS = 'assign';
        iconType = icon_resource;
        break;
      case 4:
        iconCSS = 'settings';
        iconType = icon_settings;
        break;
      case 3:
        iconCSS = 'links';
        iconType = icon_dependencies;
        break;
      case 1:
      case 2:
        iconCSS = 'custom-days';
        iconType = icon_custom_day;
        break;
      default:
        break;
      }
    }

    return {
      icon: iconType,
      css: iconCSS,
    };
  },
  showHistoryIframe() {
    const iframe = $$(innerViews.iframeHistory.id);

    iframe.config.gravity = 1;
    iframe.resize();
  },
  hideHistoryIframe() {
    const iframe = $$(innerViews.iframeHistory.id);

    iframe.config.src = null;
    iframe.load('about:blank');
    iframe.config.gravity = 0;
    iframe.resize();
  },
  hideNoDataTemplate() {
    $$(innerViews.historyNoDataTemplate.id).hide();
  },
  showNoDataTemplate() {
    $$(innerViews.historyNoDataTemplate.id).show();
  },
  getDefaultSettings() {
    return {
      ganttId: 0,
      selectedEvent: null,
      iFramseIsLoad: false,
      iFramseIsReady: false,
      scrollTimer: null,
      lastItemsCount: 0,
      limit: 3000,
      offset: 0,
      modeToZoomTitle: [
        __('history_All'),
        __('history_Hours'),
        __('history_Days'),
        __('history_Month'),
      ],
      zoomFormats: [
        'HH:mm',
        'Do MMMM',
        'MMMM YYYY',
        'YYYY',
      ],
      currentZoom: 0,
      filters: {
        byText: '',
        byUser: [],
        byTask: false,
        byColumn: false,
        byTaskPriority: false,
        byTaskStatus: false,
        byTaskDnD: false,
        bySettings: false,
        byLink: false,
        byCustomDay: false,
        byResource: false,
        byCopy: false,
        byTaskProgress: false,
        byDate: {
          from: null,
          to: null,
        },
        byResourceConvert: false,
        byMassChange: false,
        byCascadeSort: false,
      },
      userIsPaid: true,
    };
  },
  getZoomDots() {
    return `<div class='dots-wrapper'>
              <div class='dot-item'></div>
              <div class='dot-item'></div>
              <div class='dot-item'></div>
              <div class='dot-item'></div>
              <div class='dot-item'></div>
              <div class='dot-item'></div>
            </div>`;
  },
};

var innerSettings = innerHelpers.getDefaultSettings();

var innerHandlers = {
  applyFilters() {
    let hasFilters = false;
    const list = $$(innerViews.listHistory.id);

    list.clearAll();
    list.blockEvent();
    list.define('data', innerHandlers.getDataForList());
    list.unblockEvent();

    if (
      innerSettings.filters.byLink
      || innerSettings.filters.byCopy
      || innerSettings.filters.byTask
      || innerSettings.filters.byColumn
      || innerSettings.filters.byTaskPriority
      || innerSettings.filters.byTaskStatus
      || innerSettings.filters.byTaskDnD
      || innerSettings.filters.byResource
      || innerSettings.filters.byCustomDay
      || innerSettings.filters.bySettings
      || innerSettings.filters.byUser.length
      || innerSettings.filters.byDate.from
      || innerSettings.filters.byDate.to
      || innerSettings.filters.byText
      || innerSettings.filters.byResourceConvert
      || innerSettings.filters.byMassChange
      || innerSettings.filters.byCascadeSort
      || innerSettings.filters.byTaskProgress
    ) {
      hasFilters = true;
    }

    innerHandlers.selectListEvent(innerSettings.selectedEvent);
    innerHandlers.checkFilterIsActive(hasFilters);
    innerHandlers.refreshSelectedFilters();
    list.refresh();
  },
  checkFilterIsActive(hasFilters) {
    const buttonNode = $$('mainFilterButton').getNode();

    if (hasFilters) {
      buttonNode.classList.add('selected');
    } else {
      buttonNode.classList.remove('selected');
    }
  },
  getDataForList() {
    if (innerSettings.currentZoom) {
      return historyModel.groupBy(innerSettings.currentZoom, innerSettings.filters);
    }

    return historyModel.getHistoryDataForList(innerSettings.filters);
  },
  refreshSelectedFilters() {
    let template = "<div class='history-window-filter-selected-hidden'></div>";
    let dateValue = '';

    if (innerSettings.filters.byTask) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_tasks'), 'byTask');
    }

    if (innerSettings.filters.byColumn) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_column'), 'byColumn');
    }

    if (innerSettings.filters.byTaskPriority) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_tasks_priority'), 'byTaskPriority');
    }

    if (innerSettings.filters.byTaskProgress) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_tasks_progress'), 'byTaskProgress');
    }

    if (innerSettings.filters.byTaskStatus) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_tasks_status'), 'byTaskStatus');
    }

    if (innerSettings.filters.byTaskDnD) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_tasks_dnd'), 'byTaskDnD');
    }

    if (innerSettings.filters.bySettings) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_settings'), 'bySettings');
    }

    if (innerSettings.filters.byLink) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_links'), 'byLink');
    }

    if (innerSettings.filters.byCopy) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_copy'), 'byCopy');
    }

    if (innerSettings.filters.byResource) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_resources'), 'byResource');
    }

    if (innerSettings.filters.byCustomDay) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_custom_days'), 'byCustomDay');
    }

    if (innerSettings.filters.byUser.length) {
      innerSettings.filters.byUser.forEach(id => {
        const userData = historyModel.getUserDataById(id);

        template += innerHelpers.getTemplateFilter(`${__('history_filter_by_user')} ${userData.username}`, 'byUser', id);
      });
    }

    if (innerSettings.filters.byDate.from || innerSettings.filters.byDate.to) {
      if (innerSettings.filters.byDate.from) {
        dateValue += __('history_filter_by_date_from') + moment(innerSettings.filters.byDate.from).format('DD-MM-YYYY HH:mm');
      }

      if (innerSettings.filters.byDate.to) {
        dateValue += __('history_filter_by_date_to') + moment(innerSettings.filters.byDate.to).format('DD-MM-YYYY HH:mm');
      }

      template += innerHelpers.getTemplateFilter(dateValue, 'byDate');
    }

    if (innerSettings.filters.byText) {
      template += innerHelpers.getTemplateFilter(innerSettings.filters.byText, 'byText');
    } else {
      $$('historyFilterSearch').setValue(null);
    }
    if (innerSettings.filters.byCascadeSort) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_cascade_sort'), 'byCascadeSort');
    }

    if (innerSettings.filters.byResourceConvert) {
      template += innerHelpers.getTemplateFilter(__('history_filter_by_resource_convert'), 'byResourceConvert');
    }

    $$('selectedHistoryFiltersId').define('template', template);
    $$('selectedHistoryFiltersId').refresh();
  },
  hideShowHistoryList() {
    const list = $$('historyPanelId');
    const header = $$('historyHeaderId');
    const filter = $$(innerViews.mainHistoryFilter.id);
    const headerNode = header.getNode();
    const filterNode = filter.getNode();
    const addClass = function () {
      filterNode.classList.add('collapsed');
      headerNode.classList.add('collapsed');
    };
    const removeClass = function () {
      filterNode.classList.remove('collapsed');
      headerNode.classList.remove('collapsed');
    };

    if (list.config.width > 1) {
      list.define('width', 1);
      filter.define('width', 60);
      setTimeout(addClass, 500);
    } else {
      list.define('width', 444);

      setTimeout(() => {
        filter.define('width', 444);
        filter.resize();

        setTimeout(removeClass, 432);
      }, 68);
    }

    list.resize();
  },
  hideShowAdvancedFilter() {
    const advancedFilter = $$(innerViews.advancedHistoryFilter.id);
    const advancedFilterNode = advancedFilter.getNode();

    if (!advancedFilter.isVisible()) {
      advancedFilter.show();
      $$('advancedHistoryFilterId').resize();
      advancedFilter.resize();
    } else {
      advancedFilter.hide();
      $$('advancedHistoryFilterId').resize();
      advancedFilter.resize();
    }
  },
  sortList(a, b) {
    if (a.id > b.id) {
      return 1;
    }

    return -1;
  },
  loadSelectedVersion(event) {
    if (!event || event.customTemplate || GT.preventLoadHistory) {
      return false;
    }

    const frame = $$(innerViews.iframeHistory.id);

    let eventId = event.id;
    let azureKey = event.azure_key;

    if (innerSettings.currentZoom && event.times) {
      eventId = event.times[0].id;
      azureKey = event.times[0].azure_key;
    }

    const src = `/view/history/${azureKey}/event_id/${eventId}/gantt_id/${innerSettings.ganttId}`;

    if (!innerSettings.iFramseIsLoad) {
      innerSettings.iFramseIsLoad = true;
      frame.config.src = src;
      frame.load(src);
      innerSettings.waitEventToShow = webix.copy(event);

      return true;
    }

    if (!innerSettings.iFramseIsReady) {
      innerSettings.waitEventToShow = webix.copy(event);

      return true;
    }

    innerSettings.waitEventToShow = null;

    const projectData = projectsModel.getProjectDataById(innerSettings.ganttId);

    historyModel.sendPostMessageToFrame(frame.getWindow(), {
      master: 'history',
      message: 'showHistoryEvent',
      eventId,
      azureKey,
      ganttId: innerSettings.ganttId,
      projectType: projectData.status,
    });
  },
  selectListEvent(event) {
    if (!event || event.customTemplate) {
      return false;
    }

    if (innerSettings.selectedEvent) {
      $$(innerViews.listHistory.id).removeCss(innerSettings.selectedEvent.id, 'selected');
    }

    innerSettings.selectedEvent = event;
    event && $$(innerViews.listHistory.id).addCss(innerSettings.selectedEvent.id, 'selected');

    innerHandlers.resetHeader();
  },
  showUserFilter() {
    const usersData = historyModel.getUsersByHistoryData();

    innerHandlers.updateFilterUserList(usersData);
  },
  zoomSliderChange(newV, oldV) {
    const list = $$(innerViews.listHistory.id);

    innerSettings.currentZoom = newV;

    $$('historyZoomSlider').define('template', innerHelpers.getTemplateForGroupButtons());
    $$('historyZoomSlider').refresh();

    list.clearAll();
    list.parse(innerHandlers.getDataForList());
  },
  showDateFilter() {
    const datesRange = historyModel.getStartAndFinishDate();

    if (innerSettings.filters.byDate.from) {
      datesRange.startDate = innerSettings.filters.byDate.from;
    }

    $$(innerViews.historyDateStart.id).config.minDate = datesRange.startDate;
    $$(innerViews.historyDateStart.id).config.maxDate = datesRange.endDate;
    $$(innerViews.historyDateStart.id).blockEvent();
    $$(innerViews.historyDateStart.id).setValue(datesRange.startDate);
    $$(innerViews.historyDateStart.id).unblockEvent();

    $$(innerViews.historyTimeStart.id).setValue(datesRange.startDate);

    if (innerSettings.filters.byDate.to) {
      datesRange.endDate = innerSettings.filters.byDate.to;
    }

    $$(innerViews.historyDateEnd.id).config.minDate = datesRange.startDate;
    $$(innerViews.historyDateEnd.id).config.maxDate = datesRange.endDate;
    $$(innerViews.historyDateEnd.id).blockEvent();
    $$(innerViews.historyDateEnd.id).setValue(datesRange.endDate);
    $$(innerViews.historyDateEnd.id).unblockEvent();

    $$(innerViews.historyTimeEnd.id).setValue(datesRange.endDate);

    $$(innerViews.historyDatesPopupId).show();
  },
  startDateInFilterChanged(newDate, oldDate) {
    const datePicker = $$(innerViews.historyDateStart.id);
    const isEmpty = (newDate === '');
    const isValid = newDate instanceof Date && !isNaN(newDate);

    const minDate = datePicker.config.minDate;
    const maxDate = datePicker.config.maxDate;
    let minAndMaxTime = {};

    if (!isValid || isEmpty) {
      datePicker.blockEvent('onChange');
      datePicker.setValue(minDate);
      datePicker.unblockEvent('onChange');

      webix.message({ type: 'warning', text: __('gantt_date_invalid') });

      return;
    }

    if (newDate.valueOf() < minDate.valueOf()) {
      webix.message({ type: 'info', text: __('history_filter_out_of_date_range') });
      datePicker.blockEvent();
      datePicker.setValue(minDate);
      datePicker.unblockEvent();
      datePicker.setValue(minDate);

      return true;
    }

    if (newDate.valueOf() > maxDate.valueOf()) {
      webix.message({ type: 'info', text: __('history_filter_out_of_date_range') });
      datePicker.blockEvent();
      datePicker.setValue(maxDate);
      datePicker.unblockEvent();

      minAndMaxTime = historyModel.getMinTimeAndMaxTimeByHistoryDay(maxDate);
      $$(innerViews.historyTimeStart.id).setValue(minAndMaxTime.minTime);

      return true;
    }

    minAndMaxTime = historyModel.getMinTimeAndMaxTimeByHistoryDay(newDate);
    $$(innerViews.historyTimeStart.id).setValue(minAndMaxTime.minTime);
  },
  endDateInFilterChanged(newDate, oldDate) {
    const datePicker = $$(innerViews.historyDateEnd.id);
    const isEmpty = (newDate === '');
    const isValid = newDate instanceof Date && !isNaN(newDate);

    const minDate = datePicker.config.minDate;
    const maxDate = datePicker.config.maxDate;
    let minAndMaxTime = {};

    if (!isValid || isEmpty) {
      datePicker.blockEvent('onChange');
      datePicker.setValue(maxDate);
      datePicker.unblockEvent('onChange');

      webix.message({ type: 'warning', text: __('gantt_date_invalid') });

      return;
    }

    if (newDate.valueOf() > maxDate.valueOf()) {
      webix.message({ type: 'info', text: __('history_filter_out_of_date_range') });
      datePicker.blockEvent();
      datePicker.setValue(maxDate);
      datePicker.unblockEvent();
      datePicker.setValue(maxDate);

      return true;
    }

    if (newDate.valueOf() < minDate.valueOf()) {
      webix.message({ type: 'info', text: __('history_filter_out_of_date_range') });
      datePicker.blockEvent();
      datePicker.setValue(minDate);
      datePicker.unblockEvent();
      minAndMaxTime = historyModel.getMinTimeAndMaxTimeByHistoryDay(minDate);
      $$(innerViews.historyTimeEnd.id).setValue(minAndMaxTime.maxTime);

      return true;
    }

    minAndMaxTime = historyModel.getMinTimeAndMaxTimeByHistoryDay(newDate);
    $$(innerViews.historyTimeEnd.id).setValue(minAndMaxTime.maxTime);
  },
  applyFilterForDatesRange() {
    const dateStart = $$(innerViews.historyDateStart.id).getValue();
    const timeStart = $$(innerViews.historyTimeStart.id).getValue();
    const dateEnd = $$(innerViews.historyDateEnd.id).getValue();
    const timeEnd = $$(innerViews.historyTimeEnd.id).getValue();
    const datesRange = historyModel.getStartAndFinishDate();

    dateStart.setHours(timeStart.getHours());
    dateStart.setMinutes(timeStart.getMinutes());
    dateEnd.setHours(timeEnd.getHours());
    dateEnd.setMinutes(timeEnd.getMinutes());

    if (datesRange.startDate.valueOf() === dateStart.valueOf() && datesRange.endDate.valueOf() === dateEnd.valueOf()) {
      $$(innerViews.historyDatesPopupId).hide();

      return true;
    }

    innerSettings.filters.byDate.from = dateStart;
    innerSettings.filters.byDate.to = dateEnd;

    $$(innerViews.historyDatesPopupId).hide();

    innerHandlers.applyFilters();
  },
  updateFilterUserList(teamMembers) {
    let finalUserMembersPackage = [];
    const userHistoryFilterUI = $$(innerViews.usersHistoryFilterDataTable.id);

    finalUserMembersPackage = _.map(teamMembers, teammate => {
      const filterActive = _.includes(innerSettings.filters.byUser, parseInt(teammate.id, 10)) ? 1 : 0;

      // FOR JIRA EVENTS ONLY
      if (teammate.id === '0' && !teammate.username && !teammate.photo) {
        return {
          filterActive,
          userPhoto: 'https://cdn.ganttpro.com/app/imgs/history/J_label.svg',
          userName: __('jira_history_event_label'),
          userId: teammate.id,
        };
      }

      return {
        filterActive,
        userPhoto: teammate.photo,
        userName: teammate.username,
        userId: teammate.id,
      };
    });

    userHistoryFilterUI.clearAll();
    userHistoryFilterUI.parse(finalUserMembersPackage);

    if (finalUserMembersPackage.length) {
      $$(innerViews.historyUsersPopupId).show($$(innerViews.historyAdvancedFilterUsersId).getNode());
    }
  },
  loadHistoryData() {
    const isFeatureAvailable = pricingHelper.checkPricingAccess('custom_fields');
    let historyData = historyModel.getHistoryDataForList(innerSettings.filters);

    if (!isFeatureAvailable) {
      const customColumnsActionIds = [64, 121, 122, 123];

      historyData = historyData.filter(item => !customColumnsActionIds.includes(item.action_id));
    }

    $$(innerViews.listHistory.id).parse(historyData);

    if (historyData.length) {
      innerHelpers.hideNoDataTemplate();
      const lastObjectWithAzureKey = historyData.find(el => el.azure_key);

      innerHandlers.showHistoryGanttDataAndSelectItem(lastObjectWithAzureKey);
    } else {
      innerHelpers.showNoDataTemplate();
    }
  },
  showHistoryGanttDataAndSelectItem(historyObject) {
    innerHelpers.showHistoryIframe();
    // innerHelpers.hideNoDataTemplate();

    if (historyObject) {
      innerHandlers.loadSelectedVersion(historyObject);
      innerHandlers.selectListEvent(historyObject);
    }
  },
  resetHeader() {
    const header = $$(innerViews.headerHistory.id);

    if (innerSettings.currentZoom && innerSettings.selectedEvent.times && innerSettings.selectedEvent.times[0]) {
      header.define('template', innerHelpers.getTemplateForHeader(innerSettings.selectedEvent.times[0]));
      header.refresh();

      return true;
    }

    header.define('template', innerHelpers.getTemplateForHeader(innerSettings.selectedEvent));
    header.refresh();
  },
  resetSettings() {
    const ganttId = innerSettings.ganttId;

    innerSettings = innerHelpers.getDefaultSettings();

    innerSettings.ganttId = ganttId;
    innerSettings.iFramseIsLoad = false;
    innerSettings.iFramseIsReady = false;

    innerHandlers.showHistoryGanttDataAndSelectItem();
    innerHandlers.applyFilters();
    innerHandlers.zoomSliderChange(innerSettings.currentZoom);
    innerHelpers.hideHistoryIframe();
    innerHandlers.resetHeader();
  },

  initPopupWithEventTypeFilters() {
    const isFeatureUnalable = {
      column: !pricingHelper.checkPricingAccess('custom_fields'),
    };

    const historyEventDataTypes = $$('historyEventsTypeDataTable');
    let currentSettings = historyEventDataTypes.serialize();

    currentSettings = currentSettings.filter(filterItem => {
      if (isFeatureUnalable[filterItem.eventType]) {
        return false;
      }

      filterItem.filterActive = innerSettings.filters[filterItem.id];

      return true;
    });

    historyEventDataTypes.blockEvent();
    historyEventDataTypes.clearAll();
    historyEventDataTypes.parse(currentSettings);
    historyEventDataTypes.unblockEvent();
  },
  checkOnAllSelectedFilters(newFilterItem) {
    const historyEventDataTypes = $$('historyEventsTypeDataTable');
    const currentSettings = historyEventDataTypes.serialize();
    const allActive = currentSettings.every(filterItem => {
      if (newFilterItem.id === filterItem.id) {
        return newFilterItem.filterActive;
      }

      return filterItem.filterActive;
    });

    if (allActive) {
      currentSettings.forEach(filterItem => {
        innerSettings.filters[filterItem.id] = false;
      });
    }
  },

  fillFiltersByEventType(newFilterItem) {
    const historyEventDataTypes = $$('historyEventsTypeDataTable');
    const currentSettings = historyEventDataTypes.serialize();

    currentSettings.forEach(filterItem => {
      if (newFilterItem.id === filterItem.id) {
        innerSettings.filters[filterItem.id] = newFilterItem.filterActive;

        return true;
      }

      innerSettings.filters[filterItem.id] = filterItem.filterActive;
    });
  },
  userClickOnRow(historyObject) {
    if (!historyObject || historyObject.customTemplate) {
      return false;
    }

    if (innerSettings.userIsPaid) {
      innerHandlers.showHistoryGanttDataAndSelectItem(historyObject);

      return false;
    }

    innerHandlers.showHistoryGanttDataAndSelectItem(historyObject);
  },

  getLimitedData() {
    const hisoryList = $$(innerViews.listHistory.id);

    historyModel.getHistoryDataByGanttId(innerSettings.ganttId, innerSettings.offset, innerSettings.limit);
    innerSettings.offset += innerSettings.limit;
  },

  modelHandlers: {
    history: {
      init() {
        historyModel.data.attachEvent('onStoreLoaded', data => {
          innerSettings.lastItemsCount = data.length;
          const list = $$(innerViews.listHistory.id);
          const pos = list.getScrollState();

          list.clearAll();
          innerHandlers.loadHistoryData();
          list.scrollTo(pos.x, pos.y);
        });

        historyModel.data.attachEvent('initHistoryFrame', () => {
          innerSettings.iFramseIsReady = true;

          if (innerSettings.waitEventToShow) {
            innerHandlers.loadSelectedVersion(innerSettings.waitEventToShow);
          }
        });
      },
    },
  },
};

var innerViews = {
  historyMainId: 'historyId',
  helpHistory: {
    view: 'helpIcon',
    id: 'historyHelpPopupView',
    dataId: 'historyHelpPopup',
    css: 'help_icon_block help_history',
    zIndex: 1,
  },
  headerHistory: {
    // zIndex: 200,
    height: 59,
    borderless: true,
    view: 'template',
    id: 'historyHeaderId',
    css: 'history-window-header',
    template: innerHelpers.getTemplateForHeader(),
    onClick: {
      'history-window-header-close': function () {
        $$(innerViews.iframeHistory.id).config.src = null;
        $$(innerViews.iframeHistory.id).load('about:blank');
        $$(innerViews.historyMainId).hide();
      },
      'history-window-header-restore': function (event, id, target) {
        let eventId = 0;

        eventId = innerSettings.currentZoom ? innerSettings.selectedEvent.times[0].id : innerSettings.selectedEvent.id;

        webix.ajax().post(`/api/history/event/${eventId}`, { gantt_id: innerSettings.ganttId }, () => {
          window.location.reload(false);
        });

        userExtAnalytics.log('project_history_restore');

        return false;
      },
      'history-window-header-baseline': function (event, id, target) {
        let eventId = 0;

        eventId = innerSettings.currentZoom ? innerSettings.selectedEvent.times[0].id : innerSettings.selectedEvent.id;

        baselineModel.create(innerSettings.ganttId, eventId);
        userExtAnalytics.log('create_baseline_done', { from: 'history' });
        webix.message({ type: 'success', text: __('baseline_was_saved') });

        return false;
      },
      'history-window-header-slide-buttons-right': function () {
        const list = $$(innerViews.listHistory.id);
        const event = _.last(_.filter(list.serialize(), obj => (!obj.customTemplate && parseInt(obj.id, 10) > innerSettings.selectedEvent.id)));

        if (event.azure_key) {
          event && innerHandlers.userClickOnRow(event);
        }
      },
      'history-window-header-slide-buttons-left': function () {
        const list = $$(innerViews.listHistory.id);
        const event = _.first(_.filter(list.serialize(), obj => (!obj.customTemplate && parseInt(obj.id, 10) < innerSettings.selectedEvent.id)));

        if (event.azure_key) {
          event && innerHandlers.userClickOnRow(event);
        }
      },
    },
  },
  iframeHistory: {
    view: 'iframe',
    id: 'historyFrameId',
    css: 'history-window-frame',
    gravity: 0,
    borderless: true,
    hidden: false,
  },
  historyAdvancedFilterUsersId: 'historyAdvancedFilterUsers',
  historyAdvancedFilterDatesId: 'historyAdvancedFilterDatesId',
  mainHistoryFilter: {
    height: 59,
    width: 444,
    paddingY: 12,
    paddingX: 12,
    borderless: false,
    id: 'historyMainFilterId',
    css: 'history-window-filter-main',
    cols: [
      {
        view: 'template',
        width: 36,
        borderless: true,
        css: 'history-window-filter-main-minimize',
        template: historylist_minimize,
        onClick: {
          'history-window-filter-main-minimize': innerHandlers.hideShowHistoryList,
        },
      },
      {
        width: 12,
      },
      {},
      {
        view: 'template',
        id: 'historyZoomSlider',
        css: 'history-window-filter-main-slider',
        borderless: true,
        height: 36,
        width: 300,
        template: innerHelpers.getTemplateForGroupButtons(),
        onClick: {
          'history-window-filter-main-slider-item': function (mE, v, node) {
            const value = +node.dataset.value;
            const count = $$(innerViews.listHistory.id).count();

            count && innerHandlers.zoomSliderChange(value, null);
          },
        },
      },
      {},
      {
        width: 12,
      },
      {
        view: 'template',
        width: 36,
        borderless: true,
        id: 'mainFilterButton',
        css: 'history-window-filter-main-filter',
        template: icon_filter,
        onClick: {
          'history-window-filter-main-filter': innerHandlers.hideShowAdvancedFilter,
        },
      },
    ],
  },
  advancedHistoryFilter: {
    hidden: true,
    autoheight: true,
    paddingX: 12,
    id: 'advancedHistoryFilterId',
    css: 'history-window-filter-advanced',
    rows: [
      { height: 12 },
      {
        cols: [
          {
            view: 'text',
            width: 132,
            id: 'historyFilterByTypeInput',
            height: 36,
            borderless: true,
            css: 'history-window-filter-advanced-event-type',
            value: __('history_filter_event_type_text'),
            readonly: true,
            on: {
              onItemClick() {
                innerHandlers.initPopupWithEventTypeFilters();
                if ($$(innerViews.historyEventsPopupId).isVisible()) {
                  $$(innerViews.historyEventsPopupId).hide();

                  return true;
                }
                $$(innerViews.historyEventsPopupId).show(this.getNode());
              },
            },
          },
          {
            width: 24,
          },
          {
            view: 'template',
            id: 'historyAdvancedFilterDatesId',
            width: 36,
            borderless: true,
            css: 'history-window-filter-main-calendar',
            template: icon_calendar,
            onClick: {
              'history-window-filter-main-calendar': innerHandlers.showDateFilter,
            },
          },
          {
            width: 12,
          },
          {
            view: 'template',
            id: 'historyAdvancedFilterUsers',
            width: 36,
            height: 36,
            borderless: true,
            css: 'history-window-filter-advanced-user',
            template: icon_team,
            onClick: {
              'history-window-filter-advanced-user': innerHandlers.showUserFilter,
            },
          },
          {
            width: 24,
          },
          {
            view: 'search',
            width: 156,
            id: 'historyFilterSearch',
            height: 36,
            placeholder: __('history_filter_text_search_placeholder'),
            borderless: true,
            keyPressTimeout: 100,
            css: 'history-window-filter-advanced-search',
            on: {
              onTimedKeyPress(newv, oldv) {
                innerSettings.filters.byText = this.getValue() || false;

                innerHandlers.applyFilters();
              },
            },
          },
        ],
      },
      { height: 12, css: 'spacer-last' },
      {
        autoheight: true,
        id: 'selectedHistoryFiltersId',
        view: 'template',
        css: 'history-window-filter-selected',
        template: "<div class='history-window-filter-selected-hidden'></div>",
        onClick: {
          'history-window-filter-selected-item-close': function (mouse, vd, node) {
            const dataKey = node.dataset.key;

            if (dataKey === 'byUser') {
              _.remove(innerSettings.filters.byUser, userId => (`${userId}`) === (`${node.dataset.value}`));
            } else if (dataKey === 'byDate') {
              innerSettings.filters.byDate.from = false;
              innerSettings.filters.byDate.to = false;
            } else {
              innerSettings.filters[dataKey] = false;
            }
            innerHandlers.applyFilters();
          },
        },
      },
    ],
  },
  listHistory: {
    view: 'datatable',
    width: 420,
    css: 'history-window-list',
    id: 'historyList',
    header: false,
    borderless: true,
    select: false,
    rowHeight: 48,
    navigation: false,
    type: {
      height: 48,
      width: 420,
    },
    columns: [
      {
        id: 'data',
        fillspace: true,
        template(obj) {
          if (innerSettings.currentZoom) {
            return innerHelpers.getTemplateGroupListItem(obj);
          }

          return innerHelpers.getTemplateListItem(obj);
        },
      },
    ],
    onClick: {
      'open-events': function (e, v, n) {
        const list = $$(innerViews.listHistory.id);
        const eventItem = list.getItem(list.locate(e)).times[0];

        innerHandlers.zoomSliderChange(0);
        list.callEvent('onItemClick', [eventItem.id]);
        list.showItem(eventItem.id);
      },
    },
    on: {
      onAfterScroll() {
        const list = $$(innerViews.listHistory.id);
        const pos = list.getScrollState();

        if (innerSettings.scrollTimer) {
          clearTimeout(innerSettings.scrollTimer);
        }

        if (list._dtable_height - list.$height - 50 < pos.y) {
          innerSettings.scrollTimer = setTimeout(() => {
            if (innerSettings.lastItemsCount >= innerSettings.limit) {
              innerHandlers.getLimitedData();
            }
          }, 150);
        }
      },
      onAfterLoad() {
        $$(innerViews.historyMainId).show();
        logInternalHelper.addInactiveClassToAllAlreadyUsedHelpers();
      },
      onItemClick(id) {
        const historyObject = this.getItem(+id);

        if (historyObject && historyObject.azure_key) {
          innerHandlers.userClickOnRow(historyObject);
        }

        return false;
      },
    },
  },
  historyUsersPopupId: 'historyUsersPopupId',
  historyDatesPopupId: 'historyDatesPopupId',
  historyEventsPopupId: 'historyEventsPopupId',
  historyDateStart: {
    view: 'datepickeredit',
    id: 'historyDateStartDate',
    css: 'history-popup-dates-calendar',
    label: __('common_from'),
    labelWidth: 48,
    width: 216,
    value: new Date('10-09-2017'),
    icon: 'fa-calendar',
    on: {
      onChange(newDate, oldDate) {
        if (_.isDate(newDate) && _.isDate(oldDate)) {
          const newDateClone = new Date(newDate.getTime());

          newDateClone.setHours(0, 0, 0, 0);
          const oldDateClone = new Date(oldDate.getTime());

          oldDateClone.setHours(0, 0, 0, 0);

          if (newDateClone.getTime() === oldDateClone.getTime()) {
            return false;
          }
        }

        innerHandlers.startDateInFilterChanged(newDate, oldDate);
      },
      onEnter() {
        const popup = this.getPopup();

        this.callEvent('onBlur');

        if (popup.isVisible()) {
          popup.hide();
        }

        webix.UIManager.setFocus($$(innerViews.historyDatesPopupId));
      },
    },
  },
  historyDateEnd: {
    view: 'datepickeredit',
    id: 'historyDateEndDate',
    css: 'history-popup-dates-calendar',
    label: __('common_to'),
    width: 216,
    labelWidth: 48,
    value: new Date(),
    icon: 'fa-calendar',
    on: {
      onChange(newDate, oldDate) {
        if (_.isDate(newDate) && _.isDate(oldDate)) {
          const newDateClone = new Date(newDate.getTime());

          newDateClone.setHours(0, 0, 0, 0);
          const oldDateClone = new Date(oldDate.getTime());

          oldDateClone.setHours(0, 0, 0, 0);

          if (newDateClone.getTime() === oldDateClone.getTime()) {
            return false;
          }
        }

        innerHandlers.endDateInFilterChanged(newDate, oldDate);
      },
      onEnter() {
        const popup = this.getPopup();

        this.callEvent('onBlur');

        if (popup.isVisible()) {
          popup.hide();
        }

        webix.UIManager.setFocus($$(innerViews.historyDatesPopupId));
      },
    },
  },
  historyTimeStart: {
    id: 'historyTimeStart',
    view: 'datepicker',
    type: 'time',
    width: 120,
  },
  historyTimeEnd: {
    id: 'historyTimeEnd',
    view: 'datepicker',
    type: 'time',
    width: 120,
  },
  historyDatesFilterButtonCancel: {
    view: 'button',
    css: 'button_default',
    align: 'center',
    id: 'historyDatesFilterButtonCancel',
    width: 120,
    value: __('common_cancel'),
    click() {
      $$(innerViews.historyDatesPopupId).hide();
    },
  },
  historyDatesFilterButtonOk: {
    view: 'button',
    css: 'webix_button_default button_raised',
    align: 'center',
    id: 'historyDatesFilterButtonOk',
    width: 120,
    value: __('common_apply'),
    click: innerHandlers.applyFilterForDatesRange,
  },
  usersHistoryFilterDataTable: {
    view: 'activeDataTable',
    width: 252,
    rowHeight: 48,
    css: 'history-window-event-type-popup-data-table',
    id: 'usersHistoryFilterDataTable',
    borderless: false,
    header: false,
    select: false,
    editable: true,
    autoheight: true,
    activeContent: {
      filterActive: {
        view: 'checkbox',
        // type: "icon",
        // icon: '',
        width: 54,
        borderless: true,
        on: {
          onChange(newValue, oldValue) {
            const filterObject = $$('usersHistoryFilterDataTable').getItem(this.config.$masterId);

            if (!filterObject) {
              return true;
            }

            filterObject.filterActive = newValue;

            if (filterObject.filterActive === 1) {
              innerSettings.filters.byUser = _.union(innerSettings.filters.byUser, [parseInt(filterObject.userId, 10)]);
            } else {
              _.remove(innerSettings.filters.byUser, userId => parseInt(userId, 10) === parseInt(filterObject.userId, 10));
            }

            innerHandlers.applyFilters();
          },
        },
      },
    },
    scheme: {
      $init(obj) {
        obj.$css = obj.eventType;
      },
    },
    data: [],
    columns: [
      {
        id: 'userPhoto',
        width: 48,
        css: '',
        template(item) {
          return `<div class='history-popup-users-list-item-photo' style='background: url(${item.userPhoto}); background-size: contain;'></div>`;
        },
      },
      {
        id: 'userName',
        css: 'history-popup-users-list-item-name',
        fillspace: true,
      },
      {
        id: 'filterActive',
        template: '{common.filterActive()}',
        width: 48,
        css: '',
      },
    ],
  },
  historyNoDataTemplate: {
    view: 'template',
    id: 'historyNoDataTemplate',
    gravity: 1,
    css: 'history-no-data-template',
    hidden: true,
    template() {
      return `${"<div class='history-no-data-template-body'>"
        + "<div class='history-no-data-template-body-image'>"}${__('history_no_select_message')}</div>`
        + '</div>';
    },
  },

  historyEventsTypeDataTable: {
    id: 'historyEventsTypeDataTable',
    view: 'activeDataTable',
    header: false,
    select: false,
    editable: true,
    borderless: false,
    css: 'history-window-event-type-popup-data-table',
    width: 322,
    rowHeight: 48,
    height: 480,
    activeContent: {
      filterActive: {
        view: 'checkbox',
        // type: "icon",
        // icon: '',
        width: 54,
        borderless: true,
        on: {
          onChange(newValue, oldValue) {
            const filterObject = $$('historyEventsTypeDataTable').getItem(this.config.$masterId);

            if (!filterObject) {
              return true;
            }
            filterObject.filterActive = newValue;
            innerHandlers.fillFiltersByEventType(filterObject);
            innerHandlers.checkOnAllSelectedFilters(filterObject);
            innerHandlers.applyFilters();
          },
        },
      },
    },
    columns: [
      { id: 'eventIcon', width: 48, css: '' },
      { id: 'eventLabel', css: '', fillspace: true },
      {
        id: 'filterActive', template: '{common.filterActive()}', width: 48, css: '',
      },
    ],
    onClick: {},
    data: [
      {
        filterActive: 0,
        eventIcon: icon_task,
        eventType: 'task',
        id: 'byTask',
        eventLabel: __('history_filter_event_type_task'),
      },
      {
        filterActive: 0,
        eventIcon: icon_column,
        eventType: 'column',
        id: 'byColumn',
        eventLabel: __('history_filter_event_type_column'),
      },
      {
        filterActive: 0,
        eventIcon: icon_priority,
        eventType: 'task-priority',
        id: 'byTaskPriority',
        eventLabel: __('history_filter_event_type_task_priority'),
      },
      // {
      //   filterActive: 0,
      //   eventIcon: icon_task,
      //   eventType: 'task-progress',
      //   id: 'byTaskProgress',
      //   eventLabel: __('history_filter_event_type_task_progress'),
      // },
      {
        filterActive: 0,
        eventIcon: icon_status,
        eventType: 'task-status',
        id: 'byTaskStatus',
        eventLabel: __('history_filter_event_type_task_status'),
      },
      {
        filterActive: 0,
        eventIcon: icon_move,
        eventType: 'task-dnd',
        id: 'byTaskDnD',
        eventLabel: __('history_filter_event_type_task_dnd'),
      },
      {
        filterActive: 0,
        eventIcon: icon_resource,
        eventType: 'resource',
        id: 'byResource',
        eventLabel: __('history_filter_event_type_resource'),
      },
      {
        filterActive: 0,
        eventIcon: icon_settings,
        eventType: 'project-settings',
        id: 'bySettings',
        eventLabel: __('history_filter_event_type_project'),
      },
      {
        filterActive: 0,
        eventIcon: icon_custom_day,
        eventType: 'custom-day',
        id: 'byCustomDay',
        eventLabel: __('history_filter_event_type_custom_day'),
      },
      {
        filterActive: 0,
        eventIcon: icon_dependencies,
        eventType: 'dependency',
        id: 'byLink',
        eventLabel: __('history_filter_event_type_dependency'),
      },
      {
        filterActive: 0,
        eventIcon: icon_copy,
        eventType: 'copy',
        id: 'byCopy',
        eventLabel: __('history_filter_event_type_copy'),
      },
      {
        filterActive: 0,
        eventIcon: icon_masschange,
        eventType: 'masschange',
        id: 'byMassChange',
        eventLabel: __('history_filter_event_type_masschange'),
      },
      {
        filterActive: 0,
        eventIcon: icon_cascade,
        eventType: 'cascadesort',
        id: 'byCascadeSort',
        eventLabel: __('history_filter_event_type_cascadesort'),
      },
      {
        filterActive: 0,
        eventIcon: icon_convertation,
        eventType: 'resource-convert',
        id: 'byResourceConvert',
        eventLabel: __('history_filter_event_type_resource_convert'),
      },
    ],
    scheme: {
      $init(obj) {
        obj.$css = obj.eventType;
      },
    },
  },
};

webix.ui({
  view: 'window',
  css: 'history-window',
  id: innerViews.historyMainId,
  unClosable: true,
  head: false,
  modal: true,
  zIndex: 112,
  position(state) {
    state.height = document.body.clientHeight;
    state.width = document.body.clientWidth;
    state.left = 0;
    state.top = 0;
  },
  borderless: true,
  body: {
    rows: [
      {
        cols: [
          innerViews.headerHistory,
          innerViews.helpHistory,
          innerViews.mainHistoryFilter,
        ],
      },
      {
        cols: [
          {
            css: 'history-window-area',
            rows: [
              innerViews.historyNoDataTemplate,
              innerViews.iframeHistory,
            ],
          },
          {
            id: 'historyPanelId',
            css: 'history-window-panel',
            width: 444,
            rows: [
              innerViews.advancedHistoryFilter,
              innerViews.listHistory,
              {
                height: 12,
                css: 'history-window-panel-spacer',
                width: 420,
                view: 'template',
                borderless: false,
                template() {
                  return "<div class='history-window-panel-spacer-body top'></div>";
                },
              },
            ],
          },
        ],
      },
    ],
  },
  on: {
    onBeforeShow() {
      app.trigger('popup:show');
    },
    onHide() {
      app.trigger('popup:hide');
    },
  },
}).hide();

webix.ui({
  view: 'popupWithoutPadding',
  id: innerViews.historyUsersPopupId,
  css: 'history-popup-users',
  body: {
    paddingX: 0,
    paddingY: 0,
    rows: [
      innerViews.usersHistoryFilterDataTable,
    ],
  },
}).hide();

webix.ui({
  view: 'popupWithoutPadding',
  id: innerViews.historyEventsPopupId,
  css: 'history-popup-users',
  body: {
    paddingX: 0,
    paddingY: 0,
    rows: [
      innerViews.historyEventsTypeDataTable,
    ],
  },
}).hide();

app.config.mode.isHistory && webix.ui({
  view: 'window',
  id: 'frameZoom',
  css: 'history-window-frame-zoom',
  head: false,
  unClosable: true,
  body: {
    paddingX: 0,
    paddingY: 0,
    height: 40,
    width: 176,
    rows: [
      {},
      {
        cols: [
          {},
          {
            css: 'history-zoom-slider',
            rows: [
              {
                view: 'ganttSlider',
                id: 'frameZoomSlider',
                css: 'history-window-frame-zoom-slider',
                min: 0,
                max: 5,
                step: 1,
                value: settingsZoom.zoomSliderOptions.modeToZoomValue[gantt.config.duration_unit],
                height: 36,
                width: 120,
                title(obj) {
                  return __('settings_zoom') + settingsZoom.zoomSliderOptions.modeToZoomTitle[obj.value];
                },
                on: {
                  onChange(newValue, oldValue) {
                    const projectData = projectsModel.getActiveProjectData();
                    const projectConfig = ganttViewModel.getProjectConfig();
                    const durationDataMap = ['hour', 'day', 'week', 'month', 'quarter', 'year'];

                    projectConfig.durationData.mode = durationDataMap[newValue];
                    projectsModel.updateUserConfigInHistoryMode(innerSettings.ganttId, projectConfig.durationData);

                    app.trigger('gantt:reinitConfig');

                    // app.trigger('settingZoom:change', newValue, oldValue);
                  },
                },
              },
              {
                id: 'zoomSliderDots',
                height: 2,
                view: 'template',
                css: 'zoom-slider-dots',
                borderless: false,
                template: innerHelpers.getZoomDots,
              },
            ],
          },
          {},
        ],
      },
      {},
    ],
  },
  position(state) {
    state.left = document.body.clientWidth - 198;
    state.top = document.body.clientHeight - 81;
  },
}).show();

webix.ui({
  view: 'popupWithoutPadding',
  id: innerViews.historyDatesPopupId,
  css: 'history-popup-dates',
  body: {
    paddingX: 0,
    paddingY: 0,
    width: 444,
    borderless: true,
    rows: [
      {
        view: 'form',
        css: 'history-popup-dates-form',
        autoheight: true,
        borderless: false,
        width: 444,
        paddingX: 36,
        paddingY: 24,
        elements: [
          {
            rows: [
              {
                cols: [
                  innerViews.historyDateStart,
                  { view: 'label', label: '', align: 'center' },
                  innerViews.historyTimeStart,
                ],
              },
              {
                height: 24,
              },
              {
                cols: [
                  innerViews.historyDateEnd,
                  { view: 'label', label: '', align: 'center' },
                  innerViews.historyTimeEnd,
                ],
              },
            ],
          },
        ],
      },
      {
        height: 60,
        paddingX: 36,
        paddingY: 12,
        borderless: false,
        cols: [
          innerViews.historyDatesFilterButtonCancel,
          {},
          innerViews.historyDatesFilterButtonOk,
        ],
      },
    ],
  },
  position(state) {
    state.left = state.maxWidth - state.width;
    state.top = innerViews.mainHistoryFilter.height + 2; // 12 is margin;
  },
}).hide();

const outputObject = {
  handlers: {
    showPopup() {
      userExtAnalytics.log('project_history_open');
      if (!rights.project.hasRight(innerSettings.ganttId, 'history')) {
        return;
      }

      const hisoryList = $$(innerViews.listHistory.id);

      innerHandlers.resetSettings();
      innerSettings.userIsPaid = plansModel.getStatus() > 0;

      historyModel.clearAll();
      innerHandlers.getLimitedData();
    },
  },
};

app.on('ganttHistory:initZoom', () => {
  $$('frameZoomSlider').setValue(settingsZoom.zoomSliderOptions.modeToZoomValue[gantt.config.durationData.mode]);
});

app.on('app:start', () => {
  innerHandlers.modelHandlers.history.init();
});

app.on('activeProject:afterSet', (ganttId, projectData) => {
  innerSettings.ganttId = ganttId;

  if (innerSettings.iFramseIsLoad) {
    innerSettings.iFramseIsLoad = false;
    innerSettings.iFramseIsReady = false;
    $$(innerViews.iframeHistory.id).config.src = null;
    $$(innerViews.iframeHistory.id).load('about:blank');
  }

  $$(innerViews.iframeHistory.id).config.gravity = 0;
  $$(innerViews.iframeHistory.id).resize();

  // $$(innerViews.historyNoDataTemplate.id).show();
});

app.on('onAfterCollaboration', (data) => {
  if (data.event === 'ResourcesOnProjectsConverted' && data.projects?.includes(+innerSettings.ganttId)) {
    if ($$(innerViews.historyMainId).isVisible()) {
      $$(innerViews.iframeHistory.id).config.src = null;
      $$(innerViews.iframeHistory.id).load('about:blank');
      $$(innerViews.historyMainId).hide();
    }
  }
});

export default outputObject;
