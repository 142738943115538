import jiraModel from "./../../../../models/jira";

const __ = window.__;

export default {
  getTemplate: function () {
    return "<div class='jira-view'>" +
      "<div class='jira-view-desc'>" +
      "<div class='jira-view-desc-item'><div>" + __('jira_integration_second_step_1') + "</div><input readonly onClick='this.select();' class='input-second' type='text' value='" + jiraModel.commonFields.appName + "'/><div>" + __('jira_integration_second_step_2') + "</div></div>" +
      "</div>" +
      "<div class='jira-view-screen second'></div>" +
      "</div>";
  }
}
