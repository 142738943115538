var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.info_popup, "info_popup"],
      style: {
        top: _vm.coordinates.top,
        bottom: _vm.coordinates.bottom,
        left: _vm.coordinates.left,
        right: _vm.coordinates.right,
      },
    },
    [
      _c("div", { class: _vm.$style.container }, [
        _c(
          "div",
          { class: _vm.$style.body, style: { width: _vm.width + "px" } },
          [_vm._t("body")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }