import _ from '../../libs/lodash';
import app from '../../app';
import close_icon from '../../svg/close.svg';
import Store from '../../store/main';

const __ = window.__;

export default (function () {
  if (!app.config.mode.isBase) {
    return {
      handlers: {
        showPopup() {
        },
        hidePopup() {
        },
        togglePopup() {
        },
      },
    };
  }

  if (!webix.env.touch && webix.ui.scrollSize) {
    webix.CustomScroll.init();
  }

  let currentProduct = false;
  let currentProductName = '';
  let resizedOnceTY = false;
  let billingIsDone = false;

  const calculatePaymentHash = function (data) {
    return webix.ajax().get('/payment/plan/hash', data);
  };

  const getFrameParamenters = function (product) {
    return calculatePaymentHash(product).then(dataRaw => {
      const data = dataRaw.json();

      const paymentConfig = window.user.paymentProducts.ganttPro;

      const hash = data.hash;
      const out = {
        name: data.name,
        plan: data.plan,
      };

      // https://developers.payproglobal.com/docs/checkout-pages/url-parameters/


      let url = `${
        paymentConfig.baseUrl
      }?products[1][id]=${paymentConfig.id
      }${paymentConfig.addUrl
      }&products[1][data]=${hash
      }&language=${user.locale || 'en'}`;

      if (paymentConfig.isTest) {
        url += '&use-test-mode=true';
      }

      // Это при генерации ссылки сейлом не передаем. Но оставил тут, вдруг понадобится
      url += `&billing-email=${user.email}`;
      url += `&billing-first-name=${user.firstName}`;
      url += `&billing-last-name=${user.lastName}`;

      if (user.affiliate && user.affiliate.percent && user.affiliate.coupon && user.affiliate.isStillValid) {
        url += `&coupon-code-to-add=${user.affiliate.coupon}`;
      }

      if (user.affiliate && user.affiliate.paymentProviderAccountId && user.subscription?.is_trial === true) {
        // we add affiliate id only if user is in trial, for 2nd charge we do not add it
        url += `&products[1][agreement-id]=${user.affiliate.paymentProviderAccountId}`;
      }

      // от PayPro. Этот параметр нужен, чтоб показывать страницу чекаута в iframe
      // При этом у них возникает ошибка Refused to display 'https://store.payproglobal.com/' in a frame because it set 'X-Frame-Options' to 'sameorigin' при показе в iframe. Скорее всего из ха того, что есть какие-то исключения для этого на вашей стороне. Не могли бы вы помочь 
      // Убедитесь что вы добавили в URL параметр &exfo=742, ошибка может возникать из-за отсутствия этого параметра
      url += '&exfo=742';

      //  это надо чтобы показывать все доступные методы оплаты. Без этого параметра показывается только кредитная карта и пейпал
      url += '&all-payment-methods=true';

      // products[1][agreement-id]=AGREEMENTID

      // url += '&' + linkerParam; // linker is required to pass google analytics tracking inside the iframe

      // DO NOT CHANGE THE ORDER OF PARAMETERS
      // THIS WILL DRAMMETICALLY CHANGE THE FORM
      // ORDER:
      // products[1][id]=ХХХХХХХ
      // &page-template=ХХХХХХ
      // &payment-method=ХХ
      // &all-payment-methods=true/false
      // &use-company-purchase=true/false
      // &display-invoice=true/false
      // &display-quote=true/false
      // &quote-mode=editable
      // AND THEN ALL OTHER PARAMETERS
      out.url = url;

      console.log(url);

      userExtAnalytics.log('payment_iframe_open', { out, affiliate: user.affiliate, subscription: user.subscription });

      return out;
    });
  };

  const loadFrameForProduct = function (data) {
    const product = {
      planId: data.newPlan.planId,
      userCount: data.newPlan.userCount,
      isAnnual: +(data.newPeriod !== 'month'),
    };

    getFrameParamenters(product).then(data => {
      currentProductName = data.name;
      currentProduct = data.plan;
      $$('inapp_payment_title_zone_id').refresh();
      $$('paymentFrame').load(data.url);
    });
  };

  const innerObject = {
    views: {
      main: {
        id: 'paymentPopup',
        borderless: true,
      },
      frame: {
        view: 'iframe',
        id: 'paymentFrame',
      },
    },
    init: {
      run() {
      },
    },
    handlers: {},
    settings: {
      ganttId: null,
      popupType: '',
    },
  };

  webix.ui({
    view: 'window',
    // width: 'auto',
    id: innerObject.views.main.id,
    css: 'paymentPopup',
    modal: false,
    borderless: true,
    move: false,
    head: false,
    unClosable: true,
    zIndex: 100,
    body: {
      borderless: true,
      rows: [
        {
          height: 60,
          borderless: true,
          id: 'inapp_payment_top',
          css: 'inapp_payment_top topHeaderShadow',
          cols: [
            {},
            {
              rows: [
                {
                  view: 'template',
                  id: 'inapp_payment_title_zone_id',
                  borderless: true,
                  width: 710,
                  height: 60,
                  css: 'inapp_payment_title_zone',
                  template() {
                    return `<h2 class='inapp_payment_header'>${currentProductName}</h2>`;
                  },
                },
              ],
            },
            {},
            {
              view: 'template',
              id: 'innapPaymentPopupCloseButton',
              // hidden: true,
              borderless: true,
              width: 48,
              padding: 0,
              template: `<div class='inapp_payment_header_close'>${close_icon}</div>`,
              onClick: {
                inapp_payment_header_close() {
                  if (Store.getters.blockSideBar) return false;
                  $$(innerObject.views.main.id).hide();
                },
              },
            },
          ],
        },
        {
          view: 'scrollview',
          id: 'iframe_scrollview',
          width: 300,
          borderless: true,
          scroll: 'y',
          autoheight: true,
          body: {
            rows: [
              innerObject.views.frame,
            ],
          },
        },
        {
          height: 100,
          borderless: true,
          hidden: true,
          autoheight: true,
          id: 'inapp_payment_bottom',
          cols: [
            {},
            {
              rows: [
                {
                  view: 'template',
                  id: 'inapp_payment_continue_zone_id',
                  borderless: true,
                  width: 500,
                  height: 100,
                  css: 'inapp_payment_continue_zone',
                  template() {
                    return `${''
                      + "<a class='inapp_payment_continue_button'>"}${
                      __('plan_continue')
                    }</a>`;
                  },
                  onClick: {
                    inapp_payment_continue_zone(e) {
                      e.preventDefault();
                      window.location.reload();
                    },
                  },
                },
              ],
            },
            {},
          ],
        },
        {
          id: 'inapp_payment_bottom_spacer',
          hidden: true,
        },
      ],
    },
    position(state) {
      const leftSidebarWidth = Store.getters.leftSidebarWidth;

      const offsetLeft = leftSidebarWidth;
      const offsetWidth = leftSidebarWidth;

      state.height = document.body.clientHeight;
      state.top = 0;

      if (_.includes(innerObject.settings.popupType, 'history')) {
        this.config.height = state.height;
        state.width = document.body.clientWidth;
        state.left = 0;
      } else {
        state.width = document.body.clientWidth - offsetWidth;
        state.left = offsetLeft;
      }
    },
    on: {
      onBeforeShow() {
        app.trigger('popup:show', true);
        webix.ajax().post('/api/pipedrive', { payment_open_times: '+1' });
      },
      onHide() {
        app.trigger('popup:hide');
      },
    },
  }).hide();

  webix.extend($$('paymentFrame'), webix.ProgressBar);

  $$('paymentFrame').showProgress({
    type: 'icon',
  });

  const outputObject = {
    handlers: {
      showPopup(webixView, data) {
        const key = data.newPlan.key;

        webix.ajax()
          .headers({ 'Content-type': 'application/json' })
          .get('/payment/plan/nextProvider')
          .then(res => {
            loadFrameForProduct(data);
            innerObject.settings.popupType = data.subjectView || '';
            innerObject.settings.fullScreen = innerObject.settings.popupType.indexOf('historyRestore') !== -1;
            $$(innerObject.views.main.id).show();
            webix.callEvent('onResize', []);
          });
      },
      hidePopup() {
        $$(innerObject.views.main.id).hide();
        webix.callEvent('onResize', []);
      },
      togglePopup(webixView, data) {
        if ($$(innerObject.views.frame.id).isVisible()) {
          this.hidePopup();
        } else {
          this.showPopup(webixView, data);
        }
      },
    },
  };

  const setBlockedSidebar = function (flag) {
    Store.commit('setBlockedSideBar', flag);
  };

  const doOnPaymentInProcess = function (data) {

    if (billingIsDone) return false;
    billingIsDone = true;

    webix.ajax().headers({
      'Content-type': 'application/json',
    }).post('/payment/plan/processing', {
      orderId: data.orderId,
      currentProduct,
    })
      .then(() => {
        $$('inapp_payment_top').hide();
        $$('inapp_payment_bottom').show();
        $$('inapp_payment_bottom_spacer').show();
        $$('projectsPopupId')?.getNode().classList.add('hidden');
      })
      .catch(() => {
        console.error('something went wrong: please contact support@ganttro.com');
      });
  };

  const payproWindowHandlers = {
    isFullfield(data) {
      doOnPaymentInProcess(data);
    },

    isWaiting(data) {
      doOnPaymentInProcess(data);
    },

    isResized(data) {
    },

    isResizedTY(data) { // deprecated, remove after new page template from paypro
      if (!resizedOnceTY) {
        const iframeScrollView = $$('iframe_scrollview');

        iframeScrollView.config.height = (data.height ? data.height + 50 : 650);
        iframeScrollView.resize();
        resizedOnceTY = true;
      }
    },

    isDecline(data) {
    },

    isReview(data) {
      doOnPaymentInProcess(data);
    },

    onCheckoutPageLoaded(data) {
    },

    onSubmitStart(data) {
      setBlockedSidebar(true);
    },

    onSubmitFinish(data) {
      if (!billingIsDone) setBlockedSidebar(false);
    },
  };

  if (!app.config.mode.isBase) {
    return outputObject;
  }

  window.addEventListener('message', e => {
    if (typeof e.data === 'string' || e.data.master) {
      return true;
    }

    const type = e.data.type;
    const data = e.data.data;

    if (!payproWindowHandlers[type]) {
      return false;
    }
    payproWindowHandlers[type](data);
  });

  app.on('popup:show', () => {
    $$(innerObject.views.main.id).hide();
  });

  app.on('popup:closeOther', id => {
    if (id !== innerObject.views.main.id) {
      $$(innerObject.views.main.id).hide();
    }
  });

  app.on('gantt:area:click', () => {
    $$(innerObject.views.main.id).hide();
  });

  app.on('tab:projectClick', data => {
    $$(innerObject.views.main.id).hide();
  });

  app.on('leftSideBar:changedMode', data => {
    $$(innerObject.views.main.id).resize();
  });

  return outputObject;
}());
