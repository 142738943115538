
import app from "./../../../app";
import _ from "./../../../libs/lodash";

import routerHelper from "./../../../helpers/router";
import settingsZoom from "./../../settings/include/settingsZoom";
import workloadCore from "./../../gantt/modules/workloadCore";
import workloadToXlsx from "../../../views/export/xlsxExportHandlers/workloadToXlsx";
import globalStore from '../../../store/main';
import Store from "../../../store/main";
import projectsModel from "../../../models/projects";
import rights from "../../../components/rights";
import plansModel from '../../../models/plans';

const __ = window.__;

const WORKLOAD_ZOOM_ID = 'workloadZoomSlider';

const WORKLOAD_ZOOM_VALUES = [
  __("zoom_scale_days"),
  __("zoom_scale_weeks"),
  __("zoom_scale_months"),
  __("zoom_scale_quarters"),
  __("zoom_scale_years")
];

const _state = {
  range: null
};

const webixUI = {
  view: 'window',
  id: 'workloadLayout',
  css: 'workload_layout',
  borderless: true,
  // header: false,
  // headHeight: 0,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  zIndex: 9,
  // unClosable: true,
  height: 60,
  top: 0,
  position: function (state) {
    const leftSideBarWidth = Store.getters.leftSidebarWidth;;

    state.width = document.body.clientWidth - leftSideBarWidth;
    state.left = leftSideBarWidth;
    state.top = 0;
  },
  body: {
    rows: [

    ]
  }
};

const init = function () {
  const accessToWorkload = projectsModel.getAllProjects().some(o => rights.project.hasRight(o.gantt_id, 'workload'));

  if (!plansModel.isActiveTrial() && !plansModel.isActive() || !accessToWorkload) {
    routerHelper.changeRoute('/');
    return;
  }

  _.delay(() => {
    let value = settingsZoom.zoomSliderOptions.modeToZoomValue[gantt.config.durationData.mode] || 1;
    let workloadLayout = $$('workloadLayout');
    if (workloadLayout) {
      workloadLayout.show();
      globalStore.dispatch('headerView/changeZoom', value);
    }
    app.trigger('init:workload');
  });
};

app.on('gantt:init:after', () => {
  init();
});

app.on('zoom:change', function (newValue) {
  //$$('workloadZoomSlider') && $$('workloadZoomSlider').setValue(newValue);
});

app.on("leftSideBar:changedMode", function () {
  $$("workloadLayout") && $$("workloadLayout").resize();
});

app.on('header:export:workload', function () {
  workloadToXlsx.export({
    title: __('workload_title'),
    data: {
      workload: _.cloneDeep(gantt.getDatastore('workloadStore').pull),
      order: _.cloneDeep(gantt.getDatastore('workloadStore').fullOrder),
      scale: _.cloneDeep(gantt.$ui.getView('workloadTimeline').getScale()),
      viewMode: workloadCore.getState('viewMode')
    }
  });
});

export {
  init,
  webixUI
};
