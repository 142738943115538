<template>
  <div
    :class="[$style.saved_item_wrap, (isActive && !editMode) ? $style.active : '']"
    @mouseover="showIcons=true"
    @mouseleave="showIcons=false"
    @click="clickItemHandler"
  >
    <div
      v-show="!editMode"
      :class="$style.saved_item"
    >
      <span
        :class="$style.text_elipsis"
        @click="$emit('select')"
      >
        {{ input_txt }}
      </span>
      <div
        v-show="showIcons || isActive"
        :class="$style.flex"
      >
        <webix-ui
          id="gp_autotest_global_filter_btn_save_popup_btn_close"
          ref="edit"
          class="gp_autotest_global_filter_btn_save_popup_item_btn_edit"
          :class="$style.saved_item_icon"
          :config="icons.edit"
          @click="editMode=!editMode"
        />
        <webix-ui
          ref="trash"
          class="gp_autotest_global_filter_btn_save_popup_item_btn_delete"
          :class="$style.saved_item_icon"
          :config="icons.trash"
          @click="$emit('delete')"
        />
      </div>
    </div>

    <div
      v-show="editMode"
      :class="[$style.saved_item, $style.edit_mode]"
    >
      <input
        ref="input_field"
        v-model="input_txt"
        type="text"
        :autofocus="editMode"
        class="gp_autotest_global_filter_btn_save_popup_item_input_change_namme"
        @keyup.enter="editedValue"
        @focus="$event.target.select()"
      >
      <div
        ref="icons"
        :class="$style.flex"
      >
        <webix-ui
          ref="check"
          :class="[$style.icon_edit_mode,$style.check ]"
          :config="icons.check"
          class="gp_autotest_global_filter_btn_save_popup_item_input_btn_check"
          @click="editedValue"
        />
        <webix-ui
          ref="cancel"
          :class="[$style.icon_edit_mode,$style.cancel, 'icon_cancel' ]"
          :config="icons.cancel"
          class="gp_autotest_global_filter_btn_save_popup_item_input_btn_cancel"
          @click="cancelEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import svgIconForVue from '../../common/svgIconForVue';
import icon_check from '../../../svg/filter/check.svg';
import icon_cancel from '../../../svg/filter/cancel.svg';
import edit_icon from '../../../svg/filter/edit.svg';
import trash_icon from '../../../svg/filter/trash.svg';

export default {
  name: 'SavedFiltersPopup',
  props: {
    activeItemId: { type: String, required: false, default: '' },
    name: { type: String, required: true },
    id: { type: String || Number, required: true },
    list: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      editMode: false,
      input_txt: '',
      old_value: '',
      showIcons: false,
      icons: {
        edit: svgIconForVue(edit_icon, 16, 16),
        trash: svgIconForVue(trash_icon, 12, 16),
        check: svgIconForVue(icon_check, 14, 20),
        cancel: svgIconForVue(icon_cancel, 12, 24),
      },
    };
  },

  computed: {
    isActive() {
      return this.activeItemId === this.id;
    },
  },
  watch: {
    editMode(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.input_field.focus();
        });
      }
    },
  },
  mounted() {
    this.input_txt = this.name;
    this.old_value = this.name;
    document.addEventListener('click', this.documentClickHandler);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClickHandler);
  },

  methods: {
    editedValue() {
      const value = this.input_txt.trim();

      if (!value) {
        this.editMode = false;
        this.input_txt = this.old_value;

        return;
      }

      if (value === this.name) {
        this.editMode = false;

        return;
      }
      const isRepeatName = this.list.find(i => value === i.value.trim());

      if (isRepeatName) {
        webix.message({ type: 'error', text: __('filter_save_error_duplicate_name'), expire: 4000 });

        return false;
      }

      this.old_value = value;
      this.editMode = false;
      this.$emit('edit', value);
    },

    cancelEdit() {
      this.editMode = false;
      this.input_txt = this.old_value;
    },

    documentClickHandler(e) {
      if (!this.$el.contains(e.target)) {
        this.editMode = false;
        this.cancelEdit();
      }
    },

    clickItemHandler(e) {
      const isEdit = this.$refs.edit.$el.contains(e.target);
      const isTrash = this.$refs.trash.$el.contains(e.target);

      const isCancel = this.$refs.cancel.$el.contains(e.target);
      const isCheck = this.$refs.check.$el.contains(e.target);

      if (!isEdit && !isTrash && !isCancel && !isCheck) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style module src="../filter.less" lang="less"></style>
<style lang="less">
  .icon_cancel {
    .webix_view {
      .webix_template {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
