<template>
  <div class="settings-content settings-content-notifications">
    <div class="settings-section">
      <div class="main-title">
        {{ locales('settings_notifications_title') }}
      </div>
      <div class="text">
        {{ locales('settings_notifications_text') }}
      </div>
      <div class="settings-table">
        <div class="checkbox-labels">
          <div
            class="label email tooltip-gantt"
            :data-y="-4"
            :data-key="locales('email_notification_tooltip')"
          >
            {{ locales('notification_email_label') }}
          </div>
          <div
            class="label desktop tooltip-gantt"
            :data-y="-4"
            :data-key="locales('desktop_notification_tooltip')"
          >
            {{ locales('notification_desktop_label') }}
          </div>
        </div>
        <div class="checkbox-rows">
          <setting-row
            v-for="(item, index) in notificationSettings"
            :key="item.key"
            :mode="'checkbox'"
            :label="locales(`${item.locale}`)"
            :config="item"
            :select-items="delayOptions"
            :select-width="84"
            :select-height="30"
            :select-styles="{marginRight: 18 + 'px'}"
            :position="'top'"
            :select-handler="changeDelay"
            :checkbox-handler="changeCheckbox"
            :disable-checkbox="isShowPermissionFaq"
            :notification-permission="notificationPermission"
          />
        </div>
        <div class="toggle-rows">
          <setting-row
            :label="locales('sound_preferences')"
            :toggle-handler="toggleSound"
            :is-toggle-on="notificationSound"
          />
          <setting-row
            :label="locales('user_notification_dispatch_label')"
            :toggle-handler="toggleEmailOffers"
            :is-toggle-on="emailOffers"
          />
        </div>
        <div
          class="default-settings"
          @click="setToDefaultSettings"
        >
          <div class="text">
            {{ locales('recommended_settings_for_notifications') }}
          </div>
        </div>
        <div
          v-if="isShowPermissionFaq"
          class="permission-faq"
        >
          <div class="message">
            {{ locales('request_notification_permission_text_faq') }}
          </div>
          <div
            class="faq-btn"
            v-html="faqBtn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from '../../../app';
// import userNotificationsModel from '../../../models/userNotifications';
import routerHelper from '../../../helpers/router';
import constants from '../../../helpers/constants';
import _ from '../../../libs/lodash';

import settingRow from '../include/settingRow.vue';
import globalStore from '$$store/main';

function prepareNotificationSettings(settings) {
  const userNotificationsStore = globalStore.getters['userNotification/getNotificationStore'];

  const startTask = userNotificationsStore.settings.task_start_notify_delay;
  const endTask = userNotificationsStore.settings.task_end_notify_delay;
  const deadLine = userNotificationsStore.settings.deadline_notify_delay;

  settings.map((item, index) => {
    if (index <= 2) {
      item.select = true;
    }
    if (index === 0) {
      item.delay = endTask;
    }
    if (index === 1) {
      item.delay = deadLine;
    }
    if (index === 2) {
      item.delay = startTask;
    }

    item.key = new Date().getTime();

    return item;
  });

  return settings;
}

export default {
  components: {
    settingRow,
  },
  data() {
    return {
      locales: __,
      delayOptions: [
        { id: 1, value: __('notify_delay_day_1') },
        { id: 2, value: __('notify_delay_day_2') },
        { id: 3, value: __('notify_delay_day_3') },
        { id: 4, value: __('notify_delay_day_4') },
        { id: 5, value: __('notify_delay_day_5') },
        { id: 7, value: __('notify_delay_day_7') },
      ],
      notificationSettings: [],
      faqBtn: __('request_permission_faq'),
      isShowPermissionFaq: false,
      notificationSound: '',
      emailOffers: '',
      notificationPermission: '',
    };
  },
  created() {
    this.init();
  },
  beforeCreate() {
    const component = this;

    if (component.$route.path === '/settings/notifications') {
      app.on('activeProject:set', () => {
        _.delay(() => {
          const chat = document.getElementById('chat-widget-container');

          if (chat) {
            if (routerHelper.isListViewRoute()) {
              !chat.classList.contains('chat-widget-list-view') && chat.classList.add('chat-widget-list-view');
            } else {
              chat.classList.contains('chat-widget-list-view') && chat.classList.remove('chat-widget-list-view');
            }
          }
        }, 1000);
      });

      app.on('userProfile:showNotifications', () => {
        component.init();
      });
    }
  },
  methods: {
    init() {
      this.notificationPermission = Notification.permission;

      if (!GT.models.notifications.settings) {
        _.delay(() => {
          this.initNotificationsConfig();
        });
      } else {
        this.initNotificationsConfig();
      }
      if (Notification.permission === 'denied') {
        this.isShowPermissionFaq = true;
      }
    },
    initNotificationsConfig() {
      const userNotificationsStore = globalStore.getters['userNotification/getNotificationStore'];

      const settings = userNotificationsStore.settings;
      const settingsSetup = settings.setup;
      const settingConfig = prepareNotificationSettings(settingsSetup);

      this.notificationSettings = [...settingConfig];
      this.notificationSound = userNotificationsStore.settings.notification_sound;
      this.emailOffers = user.settings.email_offers;
    },
    changeDelay(item, type) {
      let update;

      if (type === constants.NOTIFICATION_ACTIONS.START_TASK) {
        update = {
          task_start_notify_delay: item.id,
        };
      }

      if (type === constants.NOTIFICATION_ACTIONS.END_TASK) {
        update = {
          task_end_notify_delay: item.id,
        };
      }

      if (type === constants.NOTIFICATION_ACTIONS.DEADLINE) {
        update = {
          deadline_notify_delay: item.id,
        };
      }

      // userNotificationsModel.updateUserSettings(update)
      globalStore.dispatch('userNotification/updateUserSettings', { data: { ...update } }).then(() => {
        this.initNotificationsConfig();
      });
    },
    changeCheckbox(id, type, val) {
      if (type === 'desktop') {
        if (Notification.permission !== 'granted') {
          if (Notification.permission === 'default') {
            this.$store.dispatch('globalPopup/show', {
              type: 'permissionPopup',
            });
          }

          return;
        }
      }
      const value = val ? 0 : 1;

      _.delay(() => {
        const userNotificationStore = globalStore.getters['userNotification/getNotificationStore'];

        const typeID = userNotificationStore.baseData.types.filter(item => item.code === type)[0].id;

        globalStore.dispatch('userNotification/updateSetup', {
          type_code: type,
          type_id: typeID,
          action_id: id,
          active: value,
        }).then(() => {
          this.updateSettings({
            id,
            type,
          });
        });
      });
    },
    updateSettings(data) {
      const settings = this.notificationSettings.map(item => {
        if (item.id === data.id) {
          item[data.type] = !item[data.type];
        }

        return item;
      });

      this.notificationSettings = settings;
    },
    toggleSound(val) {
      const value = val === 1 ? 0 : 1;

      globalStore.dispatch('userNotification/updateUserSettings', { data: { notification_sound: value } })
        .then(() => app.trigger('userNotifications:toggleSound', value));
      // userNotificationsModel.updateUserSettings({
      //   notification_sound: value
      // }).then(() => {
      //   app.trigger('userNotifications:toggleSound', value);
      // });

      this.notificationSound = value;
    },
    toggleEmailOffers(val) {
      const value = val === 1 ? 0 : 1;

      // userNotificationsModel.updateUserSettings({
      //   email_offers: value,
      // })

      globalStore.dispatch('userNotification/updateUserSettings', { data: { email_offers: value } }).then(() => {
        user.settings.email_offers = value;
        this.emailOffers = value;
      });
    },
    setToDefaultSettings() {
      // userNotificationsModel.updateSetupToDefault()

      globalStore.dispatch('userNotification/updateSetupToDefault').then(() => {
        // const settings = userNotificationsModel.settings;
        const notificationSetting = globalStore.getters['userNotification/getNotificationsSettings'];
        const settingsSetup = notificationSetting.setup;
        const settingsConfig = prepareNotificationSettings(settingsSetup);

        this.notificationSettings = settingsConfig;
      });
    },
  },
};
</script>

<style scoped src='../less/profileSettings.less'></style>
<style lang='less'>
	strong {
    font-family: "Lato-Bold", sans-serif;
    font-weight: normal !important;
    font-style: normal;
    color: #424242;
	}

	.js_permission {
		color: #fff !important;
	}

	.faq-btn {
		text-align: center;

		a:hover {
			color: #fff !important;
		}
	}
</style>
