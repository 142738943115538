import rights from '../../../components/rights';
import projectsModel from '../../../models/projects';
import globalStore from '../../../store/main';

const checkAccessToHeaderItems = (config, ganttId) => {
  const isArchivedProject = projectsModel.isArchived(ganttId);

  const { is_jira: isJira } = projectsModel.getProjectDataById(ganttId) || {};
  const accessToBoard = rights.project.hasRight(ganttId, 'board_view');
  const accessToGanttChart = rights.project.hasRight(ganttId, 'gantt_chart');
  const accessToListView = rights.project.hasRight(ganttId, 'list_view');
  const accessToWorkload = rights.project.hasRight(ganttId, 'workload');
  const accessToHistory = rights.project.hasRight(ganttId, 'history');
  const accessToOverview = rights.project.hasRight(ganttId, 'overview');
  const accessToProjectSettings = rights.project.hasRight(ganttId, 'project_settings');
  const accessToResourcePage = rights.project.hasRight(ganttId, 'resources_page');
  const accessToMasschange = rights.project.hasRightSomeOne(ganttId, [
    'static_fields',
    'delete_task',
    'static_fields_4_only',
    'custom_field_edit',
  ]);
  const accessToCascadeSorting = rights.project.hasRight(ganttId, 'cascade_sorting');
  const accessToCreateTask = rights.project.hasRight(ganttId, 'create_task');
  const accessToPublicUrl = rights.account.hasRight('public_url');
  const accessToExport = rights.account.hasRight('export');
  const accessToCalendar = rights.project.hasRight(ganttId, 'calendar');

  if (!accessToGanttChart) _.remove(config.tabs, el => ['timeline'].includes(el));
  if (!accessToBoard) _.remove(config.tabs, el => ['board'].includes(el));
  if (!accessToListView) _.remove(config.tabs, el => ['list'].includes(el));
  if (!accessToWorkload) _.remove(config.tabs, el => ['workload'].includes(el));
  if (!accessToResourcePage) _.remove(config.tabs, el => ['resources'].includes(el));
  if (!accessToOverview) _.remove(config.tabs, el => ['overview'].includes(el));
  if (!accessToHistory) _.remove(config.head.ui, el => ['history'].includes(el));
  if (!accessToProjectSettings) _.remove(config.head.ui, el => ['editTitle', 'settings'].includes(el));
  if (!accessToCalendar) _.remove(config.tabs, el => ['calendar'].includes(el));

  if (config.toolbar) {
    if (!accessToMasschange) {
      _.remove(config.toolbar.gantt.ui, el => ['bulkChange'].includes(el));
      _.remove(config.toolbar.list?.ui, el => ['bulkChange'].includes(el));
    }
    if (!accessToCascadeSorting) _.remove(config.toolbar.gantt.ui, el => ['sort'].includes(el));
    if (!accessToExport) _.remove(config.toolbar.gantt.ui, el => ['export'].includes(el));
    if (!accessToCreateTask) {
      _.remove(config.toolbar.gantt.viewSettings, el => ['buttons'].includes(el.name));
      _.remove(config.toolbar.list.ui, el => ['addTask'].includes(el));
    }
  }
  if (!rights.account.hasRightToActionsWithProjects(ganttId)) _.remove(config.head.moreItems, el => ['template'].includes(el));
  if (!accessToPublicUrl) {
    _.remove(config.headForTabs.resources.moreItems, el => ['publicUrl'].includes(el));
    _.remove(config.head.moreItems, el => ['publicUrl'].includes(el));
  }

  if (!rights.account.hasRightToActionsWithProjects(ganttId)) {
    _.remove(config.head.moreItems, el => ['delete'].includes(el));
    _.remove(config.headForTabs.resources.moreItems, el => ['delete'].includes(el));
    _.remove(config.head.moreItems, el => ['archive'].includes(el));
    _.remove(config.headForTabs.resources.moreItems, el => ['archive'].includes(el));
  }

  if (rights.account.hasRightToActionsWithProjects(ganttId) && isArchivedProject) {
    config.head.ui.push('unArchive');
  }

  if (!accessToPublicUrl && isJira) {
    _.remove(config.headForTabs.resources.moreItems, el => ['publicUrl'].includes(el));
    _.remove(config.head.moreItems, el => ['publicUrl'].includes(el));
  }

  if (config.headForTabs.resources.moreItems.length === 1) _.remove(config.head.ui, el => ['more'].includes(el));
  if (config.head.moreItems === 1) _.remove(config.head.ui, el => ['more'].includes(el));
  if (config.head.ui === 1) _.remove(config.head.ui, el => ['more'].includes(el));

  return config;
};

export default checkAccessToHeaderItems;
