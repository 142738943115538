var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "vgp_date_picker" },
    [
      _c("vc-date-picker", {
        ref: "calendar",
        attrs: {
          mode: "date",
          "is-required": true,
          attributes: _vm.calendarAttributes,
          locale: _vm.calendarLocale,
          popover: {
            placement: "top-end",
          },
        },
        on: {
          input: _vm.onInput,
          popoverDidShow: _vm.onShow,
          popoverDidHide: _vm.onHide,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var inputValue = ref.inputValue
                var togglePopover = ref.togglePopover
                return [
                  _vm.$scopedSlots.default
                    ? _vm._t("default", null, {
                        inputValue: inputValue,
                        togglePopover: togglePopover,
                      })
                    : _c(
                        "div",
                        { class: "vgp_date_picker_input" },
                        [
                          _c("svg-sprite", {
                            class: "vgp_date_picker_icon",
                            attrs: {
                              name: "calendar",
                              type: "regular",
                              size: 24,
                            },
                            on: {
                              click: function () {
                                return togglePopover()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { class: "vgp_date_picker_footer" },
                    [
                      _vm.$scopedSlots.footer
                        ? _vm._t("footer", null, { setValue: _vm.setValue })
                        : _c(
                            "button",
                            {
                              class: "vgp_date_picker_footer_button",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (function () {
                                    return _vm.setValue(new Date())
                                  })($event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.locales("datepiker_today")) +
                                  "\n        "
                              ),
                            ]
                          ),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.calendarValue,
          callback: function ($$v) {
            _vm.calendarValue = $$v
          },
          expression: "calendarValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }