<template>
  <div
    v-click-outside="hideDropdown"
    :class="$style['autocomplete-select-wrapper']"
  >
    <vgp-input
      ref="searchInput"
      v-model="search"
      small
      :placeholder="!search ? locales('task_settings_new_dependency_search_select') : ''"
      :icons-left="[{ name: 'search', handler: handleSearchIconClick }]"
      :icons-right="iconsRightConfig"
      :title="selectedItem && getFullItemOptionForFilter(selectedItem)"
      @onFocus="showDropdown"
    />
    <context-menu
      v-if="dropdownPosition"
      ref="dropdownContextMenu"
      :position="dropdownPosition"
      :height="dropdownHeight"
    >
      <template #body>
        <template v-if="!isLoading">
          <div
            v-show="!filteredHighlightedItems.length"
            :class="$style['suggestions-empty']"
          >
            {{ locales('no_matches_message_text') }}
          </div>
          <recycle-scroller
            v-show="filteredHighlightedItems.length"
            :class="[$style['suggestions-list'], 'gantt_scroll']"
            :items="filteredHighlightedItems"
            :item-size="46"
            key-field="id"
          >
            <template #default="{ item }">
              <div
                :class="[
                  $style['suggestions-item'],
                  selectedItem && item.id === selectedItem.id ? $style['suggestions-item_selected'] : ''
                ]"
                @click="selectItem(item)"
              >
                <svg-sprite
                  :class="$style['suggestions-item__icon']"
                  :name="item.type"
                  :size="20"
                  type="regular"
                />
                <div :class="$style['suggestions-item__content']">
                  <div :class="$style['suggestions-item__name-wrap']">
                    <span
                      v-if="additionallyFilterBy && !item.highlightedText"
                      :class="$style['suggestions-item__name-additional']"
                    >
                      {{ item[additionallyFilterBy] }}
                    </span>
                    <span
                      :class="$style['suggestions-item__name']"
                      :title="item[filterBy]"
                      v-html="`${item.highlightedText || item[filterBy]}`"
                    />
                  </div>
                  <div
                    :class="$style['suggestions-item__breadcrumbs']"
                    :title="item.parentPath"
                  >
                    {{ item.parentPath }}
                  </div>
                </div>
              </div>
            </template>
          </recycle-scroller>
        </template>
        <div
          v-else
          :class="$style['suggestions-loader-container']"
        >
          <vue-ellipse-progress v-bind="$options.loaderConfig" />
        </div>
      </template>
    </context-menu>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller';
import { debounce } from 'lodash';
import { VueEllipseProgress } from 'vue-ellipse-progress';

const DROPDOWN_LIST_PADDINGS = 16; // top + bottom
const DROPDOWN_LIST_DEFAULT_HEIGHT = 262;
const DROPDOWN_LIST_LOADER_CONTAINER_HEIGHT = 96;

export default {
  name: 'AutocompleteSelect',
  loaderConfig: {
    size: 30,
    line: 'round',
    loading: true,
    thickness: '10%',
    emptyThickness: '10%',
    color: '#1565C0',
    emptyColor: '#73A3D94D',
  },
  components: {
    RecycleScroller,
    VueEllipseProgress,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    getItemsCallback: {
      type: Function,
      default: () => [],
      required: false,
    },
    value: {
      type: Object,
      default: null,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    filterBy: {
      type: String,
      default: 'name',
    },
    additionallyFilterBy: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      dropdownShown: false,
      selectedItem: null,
      search: '',
      dropdownPosition: null,
      dropdownItemSize: 46,
      debouncedScrollHandler: null,
      debouncedResizeHandler: null,
      items: [],
      isLoading: false,
    };
  },
  computed: {
    dropdownHeight() {
      if (this.isLoading) return DROPDOWN_LIST_LOADER_CONTAINER_HEIGHT;
      if (this.filteredHighlightedItems.length > 5) return DROPDOWN_LIST_DEFAULT_HEIGHT;

      return this.filteredHighlightedItems.length * this.dropdownItemSize + DROPDOWN_LIST_PADDINGS;
    },
    filteredHighlightedItems() {
      const searchString = this.search.trim();

      if (!searchString) {
        return this.items;
      }

      const regex = new RegExp(`(${searchString})`, 'i');

      return this.items
        .filter(item => this.getFullItemOptionForFilter(item).match(regex))
        .map(item => {
          let highlightedText = '';

          if (this.selectedItem && this.selectedItem[this.trackBy] === item[this.trackBy]) {
            if (!this.additionallyFilterBy) {
              highlightedText = `<span class="autocomplete-select-search-match selected">${item[this.filterBy]}</span>`;
            } else {
              highlightedText = '<span class="autocomplete-select-search-match-full">';
              highlightedText += '<span class="autocomplete-select-search-match-full_additional-filter">';
              highlightedText += `${item[this.additionallyFilterBy]}`;
              highlightedText += '</span>';
              highlightedText += '<span class="autocomplete-select-search-match-full_main-filter">';
              highlightedText += `${item[this.filterBy]}`;
              highlightedText += '</span>';
              highlightedText += '</span>';
            }

            return {
              ...item,
              highlightedText,
            };
          }
          const matchString = '<span class="autocomplete-select-search-match">$1</span>';

          highlightedText = this.getFullItemOptionForFilter(item).replace(regex, matchString);

          return { ...item, highlightedText };
        });
    },
    iconsRightConfig() {
      if (this.search) {
        return [{
          name: 'close-2',
          type: 'bold',
          size: '16',
          handler: this.resetSelection,
        }];
      }

      return [];
    },
  },
  watch: {
    dropdownShown(isShown) {
      if (isShown && !this.selectedItem) {
        this.getItems();
      } else {
        this.$nextTick(this.restoreSelectedItem);
      }
    },
    value(val) {
      this.selectedItem = val;
      if (!val) {
        this.search = '';
      }
    },
  },
  mounted() {
    this.debouncedScrollHandler = debounce(this.handleScroll, 100);
    this.debouncedResizeHandler = debounce(this.showDropdown, 100);
    window.addEventListener('wheel', this.debouncedScrollHandler);
    window.addEventListener('resize', this.debouncedResizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.debouncedScrollHandler);
    window.removeEventListener('resize', this.debouncedResizeHandler);
  },
  methods: {
    async getItems() {
      this.isLoading = true;
      this.items = await this.getItemsCallback();
      this.isLoading = false;
    },
    getFullItemOptionForFilter(item) {
      if (!this.additionallyFilterBy) {
        return item[this.filterBy];
      }

      return `${item[this.additionallyFilterBy]} ${item[this.filterBy]}`;
    },
    handleSearchIconClick() {
      this.$refs.searchInput.$refs.input_field_icons.focus();
    },
    handleScroll(event) {
      const target = event.target;

      if (!this.$refs.dropdownContextMenu) {
        return;
      }

      if (!this.$refs.dropdownContextMenu.$el.contains(target)) {
        this.hideDropdown();
        this.$refs.searchInput.$refs.input_field_icons.blur();
      }
    },
    showDropdown() {
      this.$nextTick(() => {
        this.dropdownPosition = this.$refs.searchInput.$el.getBoundingClientRect();
        this.dropdownShown = true;
      });
    },
    hideDropdown() {
      this.dropdownPosition = null;
      this.dropdownShown = false;
    },
    selectItem(item) {
      this.hideDropdown();
      this.search = this.getFullItemOptionForFilter(item);
      this.$emit('select', item);
    },
    resetSelection() {
      this.search = '';
      this.$emit('select', null);
    },
    restoreSelectedItem() {
      if (!this.selectedItem) {
        return;
      }

      const fullItemOption = this.getFullItemOptionForFilter(this.selectedItem);

      if (this.search !== fullItemOption) {
        this.search = fullItemOption;
      }
    },
  },
};
</script>

<style module lang="less">

.autocomplete-select-wrapper {
  :global {
    .autocomplete-select-search-match {
      color: #1565C0;
      font-family: Lato-Bold, sans-serif;
      &.selected {
        color: #ffffff;
      }
    }

    .autocomplete-select-search-match-full {
      display: flex;
      align-items: center;
      font-family: Lato-Regular, sans-serif;

      &_additional-filter {
        margin-right: 4px;
        color: #D0E0F2;
      }
      &_main-filter {
        color: #ffffff;
      }
    }
  }

  position: relative;
  .suggestions-list {
    max-height: 246px;
    width: 300px;
  }

  .suggestions-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    width: 300px;
    height: 96px;
  }

  .suggestions-loader-container {
    height: 96px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .suggestions-item {
    height: 46px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;

    &:hover:not(.suggestions-item_selected) {
      background: #F2F2F2;
    }

    &_selected {
      background: #4484CD;
      .suggestions-item__name, .suggestions-item__icon {
        color: #FFFFFF;
      }

      .suggestions-item__name-additional {
        color: #D0E0F2;
      }

      .suggestions-item__breadcrumbs {
        color: #D0E0F2;
      }
    }

    &__icon {
      margin-left: 12px;
      margin-right: 8px;
    }
    &__content {
      max-width: 252px;
    }

    &__name-wrap {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #191919;
      font-family: Lato-Regular, sans-serif;
      line-height: 20px;
    }

    &__name {
      &-additional {
        margin-right: 4px;
        color: #808080;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__breadcrumbs {
      font-size: 14px;
      font-family: Lato-Regular, sans-serif;
      line-height: 18px;
      color: #808080;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

</style>
