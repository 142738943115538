<template>
  <div
    :class="[$style['skeleton_loading'], isCalendar ? $style['calendar_loading'] : '']"
  >
    <slot name="body" />
  </div>
</template>

<script>

export default {
  name: 'SkeletonWrapper',
  props: {
    isCalendar: { type: Boolean, required: false, default: false },
  },
};
</script>

<style module lang="less">

.skeleton_loading {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, rgba(204, 204, 204, 0) 0%, rgba(255, 255, 255, 0.6) 49.94%, rgba(204, 204, 204, 0) 100%);
    animation: loading 4s infinite;
    mask-image: radial-gradient(ellipse, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 80%);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
    mask-clip: content-box;
  }

  &.calendar_loading {
    &:after {
      top: 30px;
      z-index: 2;
    }
  }
}
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}
</style>
