import { api } from '../api';

export class UserApi {
  static async getActivities() {
    if (window.user.activity) {
      return window.user.activity;
    }

    const { data: activities } = await api.get('/user-activities').catch(error => Promise.reject(error));

    return activities;
  }

  static async updateActivityStatus(activityName, status) {
    const { data: activity } = await api.put(
      `/user-activities/${activityName}/status`,
      { value: status },
    ).catch(error => Promise.reject(error));

    const curFeature = window.user.activity.find(entry => entry.name === activity.name);

    if (curFeature) {
      curFeature.activity_status = activity.activity_status;
    } else {
      window.user.activity.push(activity);
    }

    return activity;
  }

  static async updateActivitySettings(activityName, settings) {
    const { data: activity } = await api.put(
      `/user-activities/${activityName}/settings`,
      { settings },
    ).catch(error => Promise.reject(error));
    let activityIndex = null;

    const curFeature = window.user.activity.find((entry, index) => {
      activityIndex = index;

      return entry.name === activity.name;
    });

    if (curFeature) {
      curFeature.settings = activity.settings;
      window.user.activity.splice(activityIndex, 1, curFeature);
    } else {
      window.user.activity.push(activity);
    }

    if (activity.scoring) {
      window.user.settings = Object.assign(window.user.settings, { ...activity.scoring });
    }

    return activity;
  }
}
