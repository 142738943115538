var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "js_add_task_entity",
        _vm.$style.btn,
        _vm.isDisabled ? _vm.$style.disabled : "",
      ],
    },
    [
      _c(
        "div",
        { class: _vm.$style.btn_part },
        [
          _c("vgp-icon-button", {
            class: ["js_add_task", _vm.$style.left_btn],
            attrs: {
              icon: { name: "plus-1", type: "bold", size: 20 },
              label: _vm.locale.add_new,
              colors: _vm.buttonColors,
              bold: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style.btn_part, _vm.select.select] },
        [
          _c("vgp-icon-button", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.closePopup,
                expression: "closePopup",
              },
            ],
            ref: "contextMenuButton",
            class: [_vm.$style.right_btn],
            attrs: {
              icon: {
                name: _vm.isShowDrop ? "arrow-top" : "arrow-down",
                size: 20,
              },
              colors: _vm.buttonColors,
              active: _vm.isShowDrop,
            },
            on: { onClick: _vm.togglePopup },
          }),
          _vm._v(" "),
          _c("context-menu", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowDrop,
                expression: "isShowDrop",
              },
            ],
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        class: ["js_add_task", _vm.select.menu_item],
                        on: { click: _vm.closePopup },
                      },
                      [
                        _c("svg-sprite", {
                          class: _vm.select.icon,
                          attrs: { name: "task", type: "regular", size: "24" },
                        }),
                        _vm._v(" "),
                        _c("div", { class: _vm.select.text }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.locale.add_task) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: ["js_add_milestone", _vm.select.menu_item],
                        on: { click: _vm.closePopup },
                      },
                      [
                        _c("svg-sprite", {
                          class: _vm.select.icon,
                          attrs: {
                            name: "milestone",
                            type: "regular",
                            size: "24",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { class: _vm.select.text }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.locale.add_milestone) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }