// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-name_fslxjBAv {\n  font-family: Lato-Bold, sans-serif;\n  font-size: 14px;\n  line-height: 24px;\n  color: #191919;\n  margin-bottom: 16px;\n}\n.custom-field-list_xxnkdqFs {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./public/views/taskSettings/sections/customFields/TaskSettingsCustomFields.vue","webpack://./TaskSettingsCustomFields.vue"],"names":[],"mappings":"AAmGA;EACE,kCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AClGF;ADqGA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;ACnGF","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.section-name {\n  font-family: Lato-Bold, sans-serif;\n  font-size: 14px;\n  line-height: 24px;\n  color: #191919;\n  margin-bottom: 16px;\n}\n\n.custom-field-list {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n",".section-name {\n  font-family: Lato-Bold, sans-serif;\n  font-size: 14px;\n  line-height: 24px;\n  color: #191919;\n  margin-bottom: 16px;\n}\n.custom-field-list {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-name": "section-name_fslxjBAv",
	"custom-field-list": "custom-field-list_xxnkdqFs"
};
export default ___CSS_LOADER_EXPORT___;
