var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShowResourceCard
        ? _c("div", { class: _vm.$.header_overlay })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.$.description }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.activeLayout === "people"
                ? _vm.locales("resources_manage_layout_header_txt_1")
                : _vm.locales("resources_manage_layout_header_txt_2")
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "tablePanel", class: _vm.$.account_table_panel }, [
        _c("div", { class: _vm.$.panel_left }, [
          _c(
            "div",
            {
              class: _vm.$.search,
              style: _vm.isShowResourceCard ? { "pointer-events": "none" } : {},
            },
            [
              _c("search-input", {
                attrs: {
                  placeholder:
                    _vm.activeLayout === "people"
                      ? _vm.locales("people_resources_search_placeholder")
                      : _vm.locales("material_resources_search_placeholder"),
                  value: _vm.searchValue,
                },
                on: { onInput: _vm.onSearch },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$.count_label }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.activeLayout === "people"
                    ? _vm.locales("common_resources_count", {
                        resourcesCount: _vm.tableResources.length,
                      })
                    : _vm.locales("common_material_resources_count", {
                        resourcesCount: _vm.tableResources.length,
                      })
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$.panel_buttons },
          [
            _vm.activeLayout === "material" &&
            _vm.selectedResources.length > 0 &&
            _vm.removeResourcesAccess
              ? _c("vgp-button", {
                  class: _vm.$.delete_btn,
                  attrs: {
                    type: "destructive-border",
                    label: _vm.locales("resources_delete_btn"),
                    small: true,
                  },
                  on: { onClick: _vm.onClickDeleteResources },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.createAndInviteResourcesAccess
              ? _c("vgp-button", {
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.activeLayout === "material" &&
                      _vm.selectedResources.length > 0,
                    label:
                      _vm.activeLayout === "people"
                        ? _vm.locales("resources_layout_header_invite_1")
                        : _vm.locales("resources_layout_header_invite_2"),
                    icon: {
                      name:
                        _vm.activeLayout === "people" ? "add-user" : "plus-1",
                      size: _vm.activeLayout === "people" ? "24" : "20",
                      type: _vm.activeLayout === "people" ? "regular" : "bold",
                    },
                    "icon-side": "left",
                    small: true,
                  },
                  on: { onClick: _vm.addResourceClick },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("resources-table", {
        attrs: {
          headers: _vm.tableHeaders,
          resources: _vm.tableResources,
          roles: _vm.accountRoles,
          "resources-type": _vm.activeLayout,
          "actions-disabled": _vm.isShowResourceCard || _vm.isOverSubscription,
        },
        on: {
          openProfileCard: _vm.openProfileCard,
          onDeleteResource: _vm.onClickDeleteResources,
          onChangeRole: _vm.onChangeAccountRole,
          onSelectResources: _vm.setSelectedResources,
        },
      }),
      _vm._v(" "),
      _vm.isShowResourceCard
        ? _c("resource-card", {
            attrs: {
              "is-show": _vm.isShowResourceCard,
              "resource-data": _vm.resourceCardData,
              "account-roles": _vm.accountRoles,
              "project-roles": _vm.projectRoles,
              "me-is-account-owner": _vm.meIsAccountOwner,
              "remove-resources-access": _vm.removeResourcesAccess,
              "resource-calendar-access": _vm.resourceCalendarAccess,
              "pricing-access-to-resource-calendar":
                _vm.pricingAccessToResourceCalendar,
              "pricing-access-to-cost-settings":
                _vm.pricingAccessToCostSettings,
              "actions-disabled": _vm.isOverSubscription,
            },
            on: {
              openProfileCard: _vm.openProfileCard,
              onClose: _vm.onCloseResourceCard,
              onChangeAccountRole: _vm.onChangeAccountRole,
              onChangeCost: _vm.onChangeCost,
              onChangeRight: _vm.onChangeRight,
              onChangeCostType: _vm.onChangeCostType,
              onDeleteResource: _vm.onClickDeleteResources,
              onDeleteFromProject: _vm.onClickDeleteFromProject,
              updateResource: _vm.updateResource,
              onChangeProjectRole: _vm.onChangeProjectRole,
              showAvatarUploader: function ($event) {
                _vm.isShowAvatarUploader = true
              },
              assignToProjects: function (data) {
                return _vm.$emit("assignToProjects", data)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowInvitePopup
        ? _c("invite-to-account-popup", {
            attrs: {
              "is-show": _vm.isShowInvitePopup,
              projects: _vm.projects,
              "account-roles": _vm.accountRoles.filter(function (role) {
                return role.defaultType !== "OWNER"
              }),
              "access-to-change-account-role": _vm.accountRoleChangeAccess,
            },
            on: {
              onClose: function ($event) {
                _vm.isShowInvitePopup = false
              },
              onSendInvites: _vm.onSendInvites,
              upgradePlanShowFromResourcesForce:
                _vm.upgradePlanShowFromResourcesForce,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowCreateResourcePopup
        ? _c("create-resource-popup", {
            attrs: {
              "is-show": _vm.isShowCreateResourcePopup,
              projects: _vm.projects,
            },
            on: {
              onClose: function ($event) {
                _vm.isShowCreateResourcePopup = false
                _vm.isExistResourceName = false
              },
              onCreate: _vm.onCreateResource,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("avatar-uploder-popup", {
        attrs: {
          show: _vm.isShowAvatarUploader,
          title: _vm.locales("update_profile_photo_title"),
          type: "avatar",
        },
        on: {
          onClose: function ($event) {
            _vm.isShowAvatarUploader = false
          },
          onApply: _vm.onChangeUserAvatar,
        },
      }),
      _vm._v(" "),
      !_vm.tableResources.length
        ? _c(
            "div",
            { class: _vm.$.empty_area, style: { height: _vm.stubHeight } },
            [
              _vm.searchValue !== ""
                ? _c("div", {
                    class: _vm.$.wrap,
                    domProps: { innerHTML: _vm._s(_vm.stubSearch) },
                  })
                : _c("div", {
                    class: _vm.$.wrap,
                    domProps: { innerHTML: _vm._s(_vm.stub) },
                  }),
              _vm._v(" "),
              _c("div", { class: _vm.$.desc }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.searchValue !== ""
                        ? _vm.searchMessage[_vm.activeLayout]
                        : _vm.locales("resources_no_resources_description")
                    ) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }