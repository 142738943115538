<template>
  <div
    ref="table"
    v-resize="onResize"
    class="app-table"
  >
    <addColumnsPopup
      v-show="showColumnsPopup"
      :items="allDinamicHeaders"
      :selected="allSelectedHeaders"
      :height="tableHeight"
      :extra-info="extra"
      :show-custom-column-info="showCustomColumnInfo"
      @close="closeAddColsPopup"
      @update:selected="updateShowColumns"
      @update:extra="changeExtraShow"
    />
    <v-data-table
      id="virtual-scroll-table"
      ref="scrollable"
      v-scroll:#virtual-scroll-table="onScroll"
      v-scroll.self="closeAllPopupInApp"
      class="elevation-0 v_table table-body virtual-scroll"
      :class="{ 'settings-opened': isSettingsOpened }"
      :style="{'height': currentTableHeight +'px'}"
      :headers="columnsToShow"
      :items="dataItems"
      :custom-sort="customSort"
      hide-default-footer
      hide-default-header
      disable-pagination
      dense
      @update:sort-by="sortByEvent"
      @update:sort-desc="sortDescEvent"
    >
      <template #header="{ on, props }">
        <div class="header-row table-row sticky-top">
          <div
            v-for="(header, index) in props.headers"
            :id="`gp_autotest_listview_header_cell_${header.property}`"
            :key="'header-' + header.property"
            :date-name="header.property"
            :data-resizable="header.resizable"
            :fix-width=" header.width || 0"
            class="table-header-cell table-cell"
            :class="{
              'hardwidth': !!header.hardWidth,
              'prepend': !!header.emptyHeader,
              'sticky-left': !!stickyLeft[header.property],
              'relative' : !stickyLeft[header.property],
              'last-in-row': props.headers.length === index + 1,
            }"
            :style="{
              height: headerHeight+'px',
              left: stickyLeft[header.property] ? stickyLeft[header.property] : 'auto',
            }"
          >
            <div v-if="header.property === 'select_col'">
              <checkboxCell
                :value="isSelectedAll"
                :theme="'blue'"
                @change="onSelectAll"
              />
            </div>

            <div
              v-if="!header.emptyHeader"
              class="text-wrap"
              @click="onSort($event, on, header)"
            >
              <span
                class="cell-title"
                :class="{'bold':sortBy && sortBy.coll.property === header.property }"
              >
                {{ header.text }}
              </span>
            </div>

            <div
              v-if="sortBy && sortBy.coll.property == header.property && header.property!='select_col'"
              class="sort-arrow"
              :class="{'up': sortBy.desc, 'down': !sortBy.desc}"
              @click="onSort($event, on, header)"
            />
          </div>

          <div
            class="header-cell-add table-cell sticky-right"
            :style="{width: '50px', 'flex-grow':0}"
            @click="showColumnsPopup=true"
          >
            <icon :name="'add'" />
          </div>
        </div>
      </template>

      <template #body="{ items, headers }">
        <template v-if="dataItems.length">
          <div
            v-if="start > 0"
            class=""
            :style="{'padding-top': +startHeight+'px'}"
          />
          <tableRow
            v-for="row in perPage"
            :id="(row + start - 1) + '-row'"
            :key="items[row + start -1].id + '-' + headersLength + '-' + redrawRow"
            :item="items[row + start -1]"
            :columns="headers"
            :height="rowHeight"
            :active-task-id="activeTaskId"
            :editing-task-id="editingTaskId"
            :highlight-overdue-task="highlightOverdueTask"
            :closed-task-discolor="closedTaskDiscolor"
            :closed-task-crossout="closedTaskCrossout"
            :sticky-left="stickyLeft"
            :skins="skins"
            :select-mode="selectMode"
            :selected="selectedItemIds.includes(items[row + start -1].id)"
            :extra="extra"
            @select="onSelectRow"
            @show:itemSettings="$emit('show:itemSettings', $event)"
            @change="$emit('change', $event)"
            @change:custom="$emit('change:custom', $event)"
            @show:task-name-editor="setEditTaskId"
            @delete="$emit('delete:item', $event)"
            @contextmenu:morecell="callContextMenuFromMorecell"
            @contextmenu="callContextMenu($event, items[row + start -1])"
          />

          <div
            v-if="start + perPage < itemsLength"
            :style="{'padding-top': +endHeight+'px'}"
            class="table-row"
          />

          <contextMenu
            :key="redrawRow"
            v-model="contextMenuData"
            :info="contextMenuData"
            @menu:delete="deleteItem"
            @menu:settings="showItemSettings"
          />
        </template>

        <template v-else>
          <div
            v-show="!createItemMode"
            :id="'gp_autotest_listview_no_data'"
            class="no-data--wrap"
            :style="{'height': (currentTableHeight - headerHeight - 18) +'px'}"
          >
            <template v-if="typeNoTasksImg === 'noFiltredTasks'">
              <div class="no-data noFiltredTasks" />
              <span> {{ locale.noFiltredTasks }} </span>
            </template>

            <template v-else>
              <div class="no-data noTasks" />
              <span> {{ locale.noTasks }} </span>
            </template>
          </div>
        </template>

        <creation-item
          v-if="canCreateTasks"
          v-show="showCreateItem"
          :disable="disableCreateItems"
          :is-create-mode.sync="createItemMode"
          :select-mode="selectMode"
          :skins="skins"
          @create="onCreateItem"
          @beforeCreateItem="onBeforeCreateItem"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TableResizer from './tableResize/main';
import addColumnsPopup from './addColumnsPopup.vue';
import icon from './icons/icon.vue';
import contextMenu from './contextMenu.vue';
import customSorts from './sorts';
import creationItem from './creationItem.vue';
import tableRow from './tableRow.vue';
import checkboxCell from './cells/checkboxCell.vue';
import rights from '../../../components/rights';

export default {
  name: 'SizableTable',
  components: {
    addColumnsPopup, icon, contextMenu, creationItem, tableRow, checkboxCell,
  },
  props: {
    rowHeight: { type: Number, required: false, default: 29 },
    tableHeight: { type: Number, required: false, default: 300 },
    dataItems: { type: Array, required: true },
    headersShow: { type: Array, required: true, default() { return []; } },
    headers: { type: Array, required: true, default() { return []; } },
    prependHeaders: { type: Array, required: true, default() { return []; } },
    staticHeaders: { type: Array, required: true, default() { return []; } },
    activeTaskId: { type: Number, required: false, default: -10 },
    closedTaskDiscolor: { type: Boolean, required: false, default: false },
    closedTaskCrossout: { type: Boolean, required: false, default: false },
    highlightOverdueTask: { type: Boolean, required: false, default: false },
    skins: {
      type: String,
      required: false,
      default: 'skins-all',
      validator(value) {
        return ['skins-horiz', 'skins-none', 'skins-vert', 'skins-all'].includes(value);
      },
    },
    typeNoTasksImg: {
      type: String,
      required: false,
      default: 'noTasks',
      validator(value) {
        return ['noTasks', 'noFiltredTasks'].includes(value);
      },
    },
    showCustomColumnInfo: { type: Boolean, required: false, default: false },
    disableCreateItems: { type: Boolean, required: false, default: false },
    selectMode: { type: Boolean, required: false, default: false },
  },

  emits: {
    'create:item': { name: String, type: String },
    'delete:item': {}, // task
    'show:itemSettings': {}, // task
    'change:custom': { task: {}, column: {}, value: String },
    change: { task: {}, column: {}, value: String },
    sorted: { col: {}, desc: Boolean },
    'close:column:popup': null,
    'change:show:columns': { columns: Array, extra: Array },
    select: { select: Number | null, unselect: Number | null, all: [] },
  },

  data() {
    return {
      resizer: '',
      start: 0,
      timeout: null,
      sortBy: null,
      scrollTop: 0,
      headerHeight: 38,
      showColumnsPopup: false,
      showDynamicColumns: [],
      showPrependColumns: [],
      extra: [],
      locale: {
        noFiltredTasks: __('empty_filter_logs_group_by_table'),
        noTasks: __('user_has_no_assigned_tasks_table'),
      },
      contextMenuData: null,
      emitVirtualScroll: true,
      horizontalOverflow: false,
      editingTaskId: null,
      stickyLeft: {},
      createItemMode: false,
      selectedItemIds: [],
      selectCol: {
        id: 'select_col',
        property: 'select_col',
        width: 40,
        emptyHeader: false,
        resizable: false,
        hardWidth: true,
        editor: 'checkbox-selectmode',
        stickyLeft: -1,
      },
      redrawRow: 0,
    };
  },
  computed: {
    canCreateTasks() {
      if (this.$route?.params?.projectId) {
        return rights.project.hasRight(+this.$route.params.projectId, 'create_task');
      }

      return false;
    },
    showCreateItem() {
      const createFirstTask = !this.dataItems.length && this.createItemMode;
      const showCreateItem = this.dataItems.length;

      return showCreateItem || createFirstTask;
    },
    isSelectedAll() {
      const all = this.dataItems.map(i => i.id);

      return all.every(i => this.selectedItemIds.includes(i));
    },
    allDinamicHeaders() {
      return [...this.prependHeaders, ...this.headers];
    },
    allSelectedHeaders() {
      return [...this.showPrependColumns, ...this.showDynamicColumns];
    },
    isSettingsOpened() {
      return !!this.activeTaskId;
    },
    columnsToShow() {
      const cols = [...this.showPrependColumns, ...this.staticHeaders, ...this.showDynamicColumns];

      return this.selectMode ? [this.selectCol, ...cols] : cols;
    },
    itemsLength() {
      return this.dataItems.length || 0;
    },
    headersLength() {
      return this.headers.length || 0;
    },
    tableBodyHeight() {
      return this.itemsLength * this.rowHeight;
    },
    currentTableHeight() {
      return this.tableHeight;
    },
    perPage() {
      const perPage = Math.ceil(this.tableHeight / this.rowHeight) * 3;

      return perPage >= this.itemsLength ? this.itemsLength : perPage;
    },
    scrollHeight() {
      return this.rowHeight * this.itemsLength - 30;
    },
    startHeight() {
      if ((this.start + this.perPage) > this.itemsLength) { this.start = this.itemsLength - this.perPage; }

      return this.start * this.rowHeight - 30;
    },
    endHeight() {
      return this.rowHeight * (this.itemsLength - this.start);
    },
    selectedHeaders() {
      return this.allDinamicHeaders.filter(h => this.headersShow.includes(h.property)) || [];
    },
  },
  watch: {
    selectedHeaders(cols) {
      this.setDinamicColumnsToShow(cols);
      this.updateExtraShow();
    },
    showDynamicColumns(val) {
      this.$nextTick(this.updateResizer);
    },
    showPrependColumns(val) {
      this.$nextTick(() => {
        this.calkStickyLeft();
        this.updateResizer();
      });
    },
    selectMode(val) {
      if (!val) {
        this.selectedItemIds = [];
      }

      this.$nextTick(() => {
        this.calkStickyLeft();
        this.updateResizer();
      });
    },
    dataItems: {
      async handler(val) {
        this.redrawRow++;

        await this.$nextTick(() => {
          this.updateColumnCells();
          this.createItemMode && this.goToBottomOfList();
        });
        this.createItemMode && this.goToBottomOfList();
      },
      deep: false,
    },
  },

  mounted() {
    const component = this;

    this.setDinamicColumnsToShow(this.selectedHeaders);
    this.updateExtraShow();

    this.$nextTick(() => {
      component.resizer = new TableResizer(component.$refs.table);
      component.resizer.init();
    });

    this.calkStickyLeft();
  },

  beforeDestroy() {
    this.resizer.destroy();
  },

  methods: {
    onSelectAll() {
      const res = { unselect: null, select: null };
      const common = this.dataItems.map(i => i.id);

      if (this.isSelectedAll) {
        res.unselect = [...common];
        this.selectedItemIds.splice(0);
      } else {
        res.select = common.filter(i => !this.selectedItemIds.includes(i));
        this.selectedItemIds = [...common];
      }

      this.$emit('select', { ...res, all: [...this.selectedItemIds] });
    },
    onSelectRow(id) {
      const index = this.selectedItemIds.indexOf(id);
      const res = { select: null, unselect: null, all: [] };

      if (index < 0) {
        this.selectedItemIds.push(id);
        res.select = [id];
      } else {
        this.selectedItemIds.splice(index, 1);
        res.unselect = [id];
      }

      this.$emit('select', { ...res, all: [...this.selectedItemIds] });
    },

    async onCreateItem(e) {
      this.$emit('create:item', e);
      this.goToBottomOfList();
    },

    async onBeforeCreateItem() {
      await this.$nextTick(() => {
        this.$emit('before:create');
      });

      this.goToBottomOfList();
    },

    goToBottomOfList() {
      this.$refs.scrollable.$el.scrollIntoView(false);
    },

    calkStickyLeft() {
      const showColsName = this.columnsToShow.map(i => i.property);
      const showedPrepCols = this.prependHeaders.filter(p => showColsName.includes(p.property));

      this.selectMode && showedPrepCols.unshift(this.selectCol);

      showedPrepCols.forEach(col => {
        const prepends = showedPrepCols.filter(c => c.stickyLeft < col.stickyLeft);
        const left = prepends.reduce((res, val) => res + val.width, 0);
        const value = prepends.length ? `${left}px` : '0px';

        this.$set(this.stickyLeft, col.property, value);
      });

      const staticLeft = `${showedPrepCols.reduce((res, val) => res + val.width, 0)}px`;

      this.$set(this.stickyLeft, this.staticHeaders[0].property, staticLeft);
    },

    setDinamicColumnsToShow(cols) {
      this.showPrependColumns = this.prependHeaders.reduce((result, header) => {
        const prependColumn = cols.find(column => column.property === header.property);

        if (prependColumn) {
          result.push(prependColumn);
        }

        return result;
      }, []);

      this.showDynamicColumns = this.headers.reduce((result, header) => {
        const dynamicColumn = cols.find(column => column.property === header.property);

        if (dynamicColumn) {
          result.push(dynamicColumn);
        }

        return result;
      }, []);
    },

    deleteItem(task) {
      this.$emit('delete:item', task);
    },
    showItemSettings(item) {
      this.$emit('show:itemSettings', { item });
    },

    callContextMenu(e, item) {
      if (!this.isShowCusttomMenu(e)) return;
      e.preventDefault();

      this.contextMenuData = {
        top: e.clientY,
        left: e.clientX,
        width: 236,
        item,
      };
    },
    callContextMenuFromMorecell({ coords, item }) {
      this.contextMenuData = {
        top: coords.bottom,
        left: coords.left,
        width: 236,
        item,
      };
    },
    isShowCusttomMenu(e) {
      let blockMenu = false; let showCustomMenu = false; let
        drop = false;

      let path = e.path;

      if (!path && e.composedPath) path = e.composedPath(e.target);

      Array.prototype.forEach.call(path,
        elem => {
          if (!elem.classList) return false;
          if (elem.classList.contains('block-menu')) blockMenu = true;
          if (elem.classList.contains('with-custom-menu')) showCustomMenu = true;
          if (elem.classList.contains('js-drop-cell-popup')) drop = true;
        });

      return showCustomMenu && !blockMenu && !drop;
    },

    closeAddColsPopup() {
      this.showColumnsPopup = false;
      this.$emit('close:column:popup');
    },
    itemNum(row) {
      return this.start + row - 1;
    },
    updateShowColumns(columns) {
      this.setDinamicColumnsToShow(columns);
      this.$emit('change:show:columns', { columns: this.allSelectedHeaders, extra: this.extra });
    },
    changeExtraShow(val) {
      this.extra.splice(0);
      this.extra.push(...val);
      this.$emit('change:show:columns', { columns: this.allSelectedHeaders, extra: this.extra });
    },
    updateExtraShow() {
      const extra = this.headersShow.filter(name => name.toString().includes('_extra'));

      this.extra.splice(0);
      this.extra.push(...extra);
    },
    sortByEvent(e) {
      if (!e.length) {
        this.sortBy = null;

        return;
      }

      const col = e[0];

      this.sortBy = !col.emptyHeader ? { coll: e[0] } : null;
    },

    sortDescEvent(e) {
      if (this.sortBy) {
        this.sortBy.desc = e[0];
      }
      this.$emit('sorted', this.sortBy);
    },
    onResize() {
      if (!this.resizer) return;
      this.$nextTick(this.updateColumnCells);
    },
    onSort(e, on, header) {
      if (e.target.classList.contains('resizer') || header.id === 'select_col') { return; }

      on.sort(header);
      this.$nextTick(this.updateColumnCells);
    },
    customSort(items, sortByColumns, isDescColumns) {
      if (!items.length || !sortByColumns.length) return items;
      customSorts.sort(items, sortByColumns, isDescColumns, this.headersShow);

      return items;
    },
    updateColumnCells() {
      this.resizer.columns.updateColumnCells();
      this.horizontalOverflow = this.resizer.columns.horizontalOverflow;
    },
    updateResizer() {
      this.resizer.update();
      this.horizontalOverflow = this.resizer.columns.horizontalOverflow;
    },
    onScroll(e) {
      this.contextMenuData = null;
      if ((this.tableHeight - this.headerHeight) >= this.scrollHeight) return;

      this.timeout && clearTimeout(this.timeout);
      const component = this;
      const rows = Math.ceil(e.target.scrollTop / component.rowHeight);
      const minDelta = component.perPage / 3;
      const delta = Math.ceil(component.perPage / 2);
      const bottomCondition = (rows - delta - 1) >= component.start;
      const topCondition = rows - minDelta < component.start;

      this.scrollTop = e.target.scrollTop;
      if (!(bottomCondition || topCondition)) return;

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / component.rowHeight);

        component.start = rows + component.perPage > component.itemsLength
          ? component.itemsLength - (component.perPage / 3)
          : (topCondition ? rows - delta : rows - minDelta);

        if (component.start < 0) {
          component.start = 0;
        }

        component.$nextTick(() => {
          component.updateColumnCells();
        });
      }, 0);

      this.closeAllPopupInApp();
    },

    closeAllPopupInApp() {
      if (this.emitVirtualScroll) {
        this.emitVirtualScroll = false;
        webix.callEvent('onClick', []);
        setTimeout(() => { this.emitVirtualScroll = true; }, 2000);
      }
    },

    setEditTaskId(taskId) {
      this.editingTaskId = taskId;
    },

  },
};
</script>

<style lang="less">
@import "../../../less/_fonts.less";
.bold{
  .FontLatoBold();
}

.block{
  width: 20px;
  height: 20px;
  background-color: rgb(251, 21, 101);
  position: fixed;
  right: 100px;
  top: 150px;
}

.v_table {
  position: relative;
  color: #000 ;
  overflow-x: auto;
  overflow-y: hidden;

  table{
    width: 100%;
  }

  .v-data-table__wrapper{
    overflow-x: visible;
    overflow-y: visible;
    th {
      font-family: Lato-Bold !important;
      font-weight: normal !important;
    }
  }

  .header-row{
    background-color: #fafafa;
    &:hover{
      background-color: #fafafa;
    }
  }

  .sticky-top{
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .sticky-right{
    position: sticky;
    right: 0;
    z-index: 5;
  }

  .sticky-left{
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .table-cell{
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    color: #454545;
    font-family: Arial;
    font-size: 12px;
    border-width: 0;

    @skinColor: #E0E0E0;

    .more-cell{
      position: relative;

      &::before{
        position: absolute;
        display: block;
        content: '';
        height: 100%;
        left: -1px;
        width: 1px;
        border-left: 1px solid transparent;
      }
    }

    &.skins-horiz .more-cell::before{
      border-left: 1px solid transparent;
    }
    &.skins-horiz .table-cell--inner-wrap {
      border-right: 1px solid transparent;
    }

    &.skins-vert .more-cell::before{
      border-left: 1px solid @skinColor;
    }
    &.skins-vert .table-cell--inner-wrap {
      border-right: 1px solid @skinColor;
    }

    &.skins-none .more-cell::before{
      border-left: 1px solid transparent;
    }
    &.skins-none .table-cell--inner-wrap {
      border-right: 1px solid transparent;
    }

    &.skins-all .more-cell::before{
      border-left: 1px solid @skinColor;
    }
    &.skins-all .table-cell--inner-wrap {
      border-right: 1px solid @skinColor;
    }

    &.skins-none .more-cell,
    &.skins-vert .more-cell,
    &.skins-vert .table-cell--inner-wrap,
    &.skins-none .table-cell--inner-wrap {
      border-bottom: 0px solid transparent;
    }

    &.skins-horiz .more-cell,
    &.skins-all .more-cell,
    &.skins-horiz .table-cell--inner-wrap ,
    &.skins-all .table-cell--inner-wrap {
      border-bottom: 1px solid @skinColor;
    }

    &.skins-border-right-0 .table-cell--inner-wrap{
      border-right: 0px solid @skinColor;
    }

    &.sticky-right,
    &.sticky-left{
      z-index: 1;
      background-color: inherit;
    }

    &.last-in-row .table-cell--inner-wrap{
      border-right: 1px solid transparent !important;
    }
  }

  .header-cell-add,
  .table-header-cell.table-cell{
    padding: 0px 10px;
    text-align: center;
    color: #616161;
    background-color: #fafafa !important;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    transition: color .3s;
    cursor: pointer;
    justify-content: center;
    font-size: 14px !important;
    .FontLatoRegular;

    &.last-in-row{
      border-right: 1px solid transparent !important;
    }

    &:hover{
      color: #212121;
      background-color: #fafafa;
    }

    &.sticky-right,
    &.sticky-left{
      z-index: 6;
    }

    .sort-arrow{
      position: absolute;
      right: 7px;
      top: 50%;
      width: 14px;
      height: 24px;
      &.up{
        transform: translateY(-50%) rotate( 0deg);
        background: url(https://cdn.ganttpro.com/app/imgs/outline-arrow_upward.svg) no-repeat center;
      }
      &.down{
        background: url(https://cdn.ganttpro.com/app/imgs/outline-arrow_upward.svg) no-repeat center;
        transform: translateY(-50%) rotate( 180deg);
      }
    }

    &.prepend{
      border-right: 1px solid transparent;
    }
  }

  .header-cell-add::after{
    display: block;
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 1px solid #cfcfcf;
    left: -1px;
  }

  .text-wrap{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .cell-title{
    line-height: 37px;
    white-space: nowrap;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #virtual-scroll-table,
  &#virtual-scroll-table {
    overflow-y: auto;
  }

  .relative{
    position: relative;
  }

  .hardwidth{
    flex-grow: 0;
  }

  .virtual-scroll{
    position: relative;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .relative{
    position: relative;
  }
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-data{
    margin-bottom: 30px;

    &--wrap{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: "Lato-Light", sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 26px;
    }

    &.noFiltredTasks{
      width: 149px;
      height: 149px;
      background: url(https://cdn.ganttpro.com/app/imgs/empty_filter_tasks.svg) center no-repeat;
    }

    &.noTasks{
      width: 360px;
      height: 130px;
      background: url(https://cdn.ganttpro.com/app/imgs/empty_tasks.svg) center no-repeat;
    }
  }
}
</style>
