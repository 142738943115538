<template>
  <div :class="$style['learning-center-videos-tab-wrapper']">
    <div :class="$style['video-container']">
      <div id="video-player" />
    </div>
    <div :class="$style['timeline-list']">
      <ul
        :class="$style['list-items']"
        class="gantt_scroll"
        @scroll="handleListScroll"
      >
        <li
          v-for="timeCode in timeCodes"
          ref="timeCodes"
          :key="timeCode.id"
          :class="[$style['list-item'], { [$style['list-item--selected']]: isTimeCodeActive(timeCode.id) } ]"
          @click="handleTimeCodeClick(timeCode)"
        >
          <div :class="[$style['title-container'], { [$style['selected']]: isTimeCodeActive(timeCode.id) } ]">
            <svg-sprite
              v-if="isTimeCodeActive(timeCode.id)"
              name="play"
              :type="'bold'"
              :size="20"
              :class="$style['play-icon']"
            />
            <div :class="$style['title-step']">
              {{ `${locales('lc_video_step')} ${timeCode.id}` }}
            </div>
          </div>
          <div :class="[$style['item-data'], { [$style['selected']]: isTimeCodeActive(timeCode.id) }]">
            <div :class="$style.title">
              {{ timeCode.title }}
            </div>
            <div :class="$style.time">
              {{ getTimeStringFromSeconds(timeCode.second) }}
            </div>
          </div>
        </li>
      </ul>
      <div :class="[$style.blur, { [$style['blur--active']]: needToBlurListBottom }]" />
    </div>
  </div>
</template>

<script>

import { throttle } from 'lodash';
import { VIDEO_GUIDE_TIME_CODES, VIDEO_GUIDE_YOUTUBE_ID } from '../learningCenter.config';

export default {
  name: 'LearningCenterGuideTab',
  data() {
    return {
      locales: __,
      currentTimeCodeId: 1,
      player: null,
      needToBlurListBottom: true,
      timeCodes: VIDEO_GUIDE_TIME_CODES,
    };
  },
  beforeDestroy() {
    if (this.player !== null && this.player.destroy) {
      this.player.destroy();
      delete this.player;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.player = new YT.Player('video-player', {
        height: '430',
        width: '590',
        videoId: VIDEO_GUIDE_YOUTUBE_ID,
        playerVars: {
          rel: 0,
          hl: ['ru', 'es'].indexOf(user.locale) !== -1 ? user.locale : 'en',
          cc_load_policy: user.locale === 'en' ? 0 : 1,
          cc_lang_pref: ['ru', 'es'].indexOf(user.locale) !== -1 ? user.locale : 'en',
        },
        events: {
          onError(err) {
            console.error(err);
          },
          onReady: () => {
            if (this.currentTimeCodeId) {
              const currentTimeCode = this.timeCodes.find(timeCode => timeCode.id === this.currentTimeCodeId);

              if (currentTimeCode.id > 1) {
                this.player.seekTo(currentTimeCode.second);
              }
            }
          },
          onStateChange: this.findAndSetActiveTimeCodeInList,
        },
      });
    });
  },
  methods: {
    handleListScroll: throttle(function ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.needToBlurListBottom = scrollTop + clientHeight < scrollHeight;
    }, 100),
    handleTimeCodeClick({ id, key_analytics, second }) {
      this.currentTimeCodeId = id;
      userExtAnalytics.log('learning_center_setup_guide_step', { type: key_analytics });
      this.player.seekTo(second);
      this.player.playVideo();
    },
    getTimeStringFromSeconds(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 3600 % 60);
      const padTo2Digits = num => num.toString().padStart(2, '0');

      return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    },
    findAndSetActiveTimeCodeInList() {
      const videoCurrentTime = Math.round(this.player.getCurrentTime());

      const foundTimeCodeIndex = this.timeCodes.findIndex((timeCode, index, array) => {
        const nextTimeCode = array[index + 1];

        if (timeCode.second <= videoCurrentTime && nextTimeCode?.second > videoCurrentTime) {
          return true;
        }

        return timeCode.second <= videoCurrentTime && !nextTimeCode;
      });

      if (foundTimeCodeIndex !== -1) {
        this.currentTimeCodeId = this.timeCodes[foundTimeCodeIndex].id;
        this.$refs.timeCodes[foundTimeCodeIndex].scrollIntoView({ behavior: 'smooth' });
      }
    },
    isTimeCodeActive(id) {
      return this.currentTimeCodeId === id;
    },
  },
};
</script>

<style module src="../less/learningCenterGuideTab.less" lang="less">
</style>
