export default function(id, dataId, size) {
  return {
    view: 'helpIcon',
    id,
    dataId,
    css: 'help_icon_block advanced_help',
    width: size,
    height: size,
    zIndex: 1,
  };
}