import icon_close from '../../svg/close.svg';
import app from '../../app';
import planModel from '../../models/plans';
import moment from '../../libs/moment';
import _ from '../../libs/lodash';
import templates from '../../templates/labels';
import lock_blocked from '../../svg/pricing/lock-blocked.svg';
import info_icon from '../../svg/pricing/info.svg';
import smile_icon from '../../svg/pricing/smile.svg';
import status from '../../helpers/status';

import '../common/ganttproWindowPopup';

const __ = window.__;

const innerViews = {
  upgradePopup: {
    id: 'upgrade-popup',
  },
  upgradePopupToggleLayout: {
    id: 'upgrade_popup_toggle_layout',
  },
  upgradePopupToggleLabel: {
    view: 'label',
    id: 'upgrade_popup_toggle_label',
    css: 'label-with-spacer',
    template: templates.getTemplateForLabelWithSpacer,
    label: __('need_upgrade_popup_toggle_label'),
  },
  upgradePopupToggle: {
    view: 'template',
    icon: '',
    id: 'upgrade_popup_toggle',
    borderless: true,
    height: 36,
    css: 'upgrade-popup-toggle',
    isAnnual: true,
    template() {
      return innerTemplates.upgradePeriodToggle(this);
    },
    onClick: {
      'upgrade-popup-toggle-select': function (e) {
        const selected = e.target.classList.contains('selected');

        if (!selected) {
          const isAnnual = +e.target.dataset.annual;

          innerHandlers.changeUpgrade(isAnnual);
        }
      },
    },
  },
  calcPopup: {
    id: 'calc-popup',
    textID: 'calc-popup-text',
  },
  upgradePopupHeaderText: {
    id: 'upgrade-popup-header-text',
  },
  thankForUpgradePopup: {
    id: 'thank-upgrade-popup',
    textID: 'thank-upgrade-popup-text',
  },
};

const innerTemplates = {
  warningMessage() {
    return `${"<div class='payment-body-container-block-warning'>"
      + '<div class="payment-body-container-block-warning-icon">'}${lock_blocked}</div>`
      + `<div class="payment-body-container-block-warning-text">${__('can_not_use_plan')}</div>`
      + '</div>';
  },
  getPlanButton(plan, index) {
    return `<div class='payment-body-container-block-button'  data-index='${index}'>`
      + `<div class="payment_btn">${__('pay_and_upgrade')}</div>`
      + '</div>';
  },
  getRequestPlanBlock() {
    return `${''
      + "<div class='payment-body-container-block request-upgrade-block'>"
      + "<div class='payment-body-container-block-fields'>"
      + "<div class='payment-body-container-block-name'>"}${__('upgrade_to_team_15_plus')}</div>`
      + '<div class=\'payment-body-container-block-contact payment-body-container-block-price\'>'
      + `<div class='payment-body-container-block-contact-header'>${
        __('contact_us')
      }</div>`
      + `<div class='payment-body-container-block-contact-text'>${__('upgrade_contact_us_text')}</div>`
      + '</div>'
      + '</div>'

      + `<div class='payment-body-container-block-button request-upgrade-button'>${__('request_button')}</div>`
      + '</div>';
  },
  getPaymentPlanBlock(data, upgradeData, index, css) {
    return `${''
      + "<div class='payment-body-container-block "}${data.disabled ? ' disabled' : ''}' data-index='${index}'>`
      + '<div class=\'payment-body-container-block-fields\'>'
      + `<div class='payment-body-container-block-name'>${__(`upgrade_to_team_${data.user_count}`)}</div>`
      + `<div class='payment-body-container-block-price' data-is-annual='${data.is_annual}'>`
      + `<div class='payment-body-container-block-price-desc'>${
        __('your_additional_payment')
      }</div>`
      + `<div class='payment-body-container-block-price-value'> $${Math.round((data.price - upgradeData.saleValue) * 10) / 10}</div>`
      + '</div>'
      + `<div class='payment-body-container-block-price-calc' data-index='${index}'>`
      + `<div class='payment-body-container-block-price-calc-text'>${__('see_price_calc')}</div>`
      + `<div class='payment-body-container-block-price-calc-svg'>${info_icon}</div>`
      + '</div>'
      + `</div>${

        data.disabled ? innerTemplates.warningMessage() : innerTemplates.getPlanButton(data.plan, index)
      }</div>`;
  },
  getCustomPaymentPlanBlock(data, upgradeData, index, css) {
    return `${''
      + "<div class='payment-body-container-block "}${data.disabled ? ' disabled' : ''}' data-index='${index}'>`
      + '<div class=\'payment-body-container-block-fields\'>'
      + `<div class='payment-body-container-block-name'>${__('upgrade_to_team_X', { user_count: data.user_count })}</div>`
      + `<div class='payment-body-container-block-price' data-is-annual='${data.is_annual}'>`
      + `<div class='payment-body-container-block-price-desc'>${
        __('your_additional_payment')
      }</div>`
      + `<div class='payment-body-container-block-price-value'> $${Math.round((data.full_price - data.discount) * 100) / 100}</div>`
      + '</div>'
      + `<div class='payment-body-container-block-price-calc' data-index='${index}'>`
      + `<div class='payment-body-container-block-price-calc-text'>${__('see_price_calc')}</div>`
      + `<div class='payment-body-container-block-price-calc-svg'>${info_icon}</div>`
      + '</div>'
      + `</div>${

        data.disabled ? innerTemplates.warningMessage() : innerTemplates.getPlanButton(data.plan, index)
      }</div>`;
  },
  upgradePeriodToggle($toggle) {
    const isAnnual = $toggle.isAnnual;

    return `${''
      + '<div class="upgrade-popup-toggle-container">'
      + '<div class="upgrade-popup-toggle-select upgrade-popup-toggle-month '}${isAnnual ? '' : 'selected'}" data-annual="0">${__('monthly')}</div>`
      + `<div class="upgrade-popup-toggle-select upgrade-popup-toggle-year  ${isAnnual ? 'selected' : ''}" data-annual="1">${__('yearly')}</div>`
      + '</div>';
  },
  getPopupBodyHTML($$body) {
    const isUpgradeToAnnual = $$body.isAnnual;
    const $$upgradePopup = $$(innerViews.upgradePopup.id);

    const upgradeData = $$upgradePopup.config.upgradeData;
    // const needRefresh = $$upgradePopup.config.needRefresh;

    // if (needRefresh) {
    //   return __("notifications_order_OrderCharged");
    // }

    if (upgradeData) {
      const isAnnual = upgradeData.currentPlan.subscriptionPeriod === 'Years' || upgradeData.currentPlan.isAnnual;
      const isActive = upgradeData.currentPlan.status === 'Active';
      const customPlans = upgradeData.customPlans.filter(plan => !!plan.is_annual === !!isUpgradeToAnnual);

      let plans = _.filter(upgradeData.nextPlans, plan => {
        const isAnnualPlan = !!plan.is_annual === !!isUpgradeToAnnual;
        const isCurrentPlanCheaper = upgradeData.currentPlan.subscriptionPrice < plan.price;

        if (isAnnualPlan && isCurrentPlanCheaper && plan.user_count <= 100) {
          return plan;
        }
      });

      plans = plans.splice(0, 3);

      if (!isActive) {
        $$upgradePopup.config.upgradeData.needRenew = true;
      }

      if (!isAnnual) {
        $$(innerViews.upgradePopupToggleLayout.id).show();
      } else {
        $$(innerViews.upgradePopupToggleLayout.id).hide();
      }

      if (customPlans.length > 0) {
        plans = _.filter(plans, plan => plan.user_count >= customPlans[0].user_count);
      }

      $$upgradePopup.config.allPlans = _.concat(customPlans, plans);

      const showRequestPlanBlock = customPlans.length === 0;
      const blockLength = plans.length + customPlans.length + (showRequestPlanBlock ? 1 : 0);

      if ((blockLength) > 3) {
        $$(`${innerViews.upgradePopup.id}body`).config.width = 672 + 220;
      } else {
        $$(`${innerViews.upgradePopup.id}body`).config.width = 672;
      }

      return `${''
        + '<div class="payment-body-container-blocks">'}${
        _.reduce(customPlans, (result, plan, index) => result += innerTemplates.getCustomPaymentPlanBlock(plan, upgradeData, index), '')
      }${_.reduce(plans, (result, plan, index) => result += innerTemplates.getPaymentPlanBlock(plan, upgradeData, customPlans.length + index), '')
        // (showRequestPlanBlock ? innerTemplates.getRequestPlanBlock() : '') +
      }</div>`;

      // var resultPrice = upgradeData.nextPlan.price - upgradeData.saleValue;
      //
      // const config = {
      //   currentPlanName: upgradeData.currentPlan.subscriptionName,
      //   nextPayment: upgradeData.currentPlan.nextPayment,
      //   subscriptionPrice: upgradeData.currentPlan.subscriptionPrice,
      //   nextPlan: upgradeData.nextPlan.name[user.locale],
      //   nextPlanPrice: upgradeData.nextPlan.price,
      //   resultPrice: resultPrice + " " + upgradeData.currentPlan.subscriptionCurrency,
      //   saleValue: upgradeData.saleValue + " " + upgradeData.currentPlan.subscriptionCurrency,
      //   totalTime: upgradeData.totalTime,
      //   usedTime: upgradeData.usedTime,
      // };
      //
      // return "<div class='upgrade_body'>" +
      //   "<p>" + __("need_upgrade_popup_body", config) + "</p>" +
      //   needRenewBlock +
      //   "</div>";
    }

    return ''
        + '<div class="payment-body-container-blocks">'
        // innerTemplates.getRequestPlanBlock() +
        + '</div>';
  },
};

const innerHelpers = {
  showCalculatePopup(event) {
    const target = event.target;

    const debounced = _.debounce(() => {
      innerHelpers.initCalculatePopup(target);
    }, 300);

    if (!innerHelpers.hasFocusOnPopup(target)) {
      debounced.cancel();
      $$(innerViews.calcPopup.id)
      && $$(innerViews.calcPopup.id).isVisible()
      && $$(innerViews.calcPopup.id).hide();
    }

    debounced();
  },
  initCalculatePopup(target) {
    const $$upgradePopup = $$(innerViews.upgradePopup.id);
    const $$calcPopupText = $$(innerViews.calcPopup.textID);
    const $$thankForUpgradeText = $$(innerViews.thankForUpgradePopup.textID);
    let isSvg = false;

    while (target) {
      if (target.classList && target.classList.contains('payment-body-container-block-price-calc')) {
        isSvg = true;
        break;
      }

      target = target.parentNode;
    }

    if (!isSvg || !target.parentElement.classList.contains('payment-body-container-block-fields')) {
      return;
    }

    const plan = $$upgradePopup.config.allPlans[target.dataset.index];

    $$calcPopupText.config.upgradeData = $$upgradePopup.config.upgradeData;
    $$calcPopupText.config.plan = plan;
    $$calcPopupText.refresh();

    $$thankForUpgradeText.config.plan = plan;
    $$thankForUpgradeText.refresh();

    $$(innerViews.calcPopup.id).show(target);
    $$(innerViews.calcPopup.id).hide();
    $$(innerViews.calcPopup.id).show(target, { pos: 'top', x: -104 });
  },
  hasFocusOnPopup(element) {
    while (element) {
      if (_.includes(element.classList, 'calc-popup')) {
        return true;
      }

      element = element.parentNode;
    }

    return false;
  },
  getCalcucationTemplate() {
    return {
      rows: [
        {
          view: 'label',
          align: 'left',
          height: 24,
          label: __('calc_popup_title'),
          css: 'calc-popup-title',
        },
        {
          view: 'template',
          borderless: true,
          // height: 160,
          autoheight: true,
          id: innerViews.calcPopup.textID,
          css: 'calc-popup-text',
          template() {
            const config = $$(innerViews.calcPopup.textID).config;

            if (!config.plan || !(config.plan.price || config.plan.full_price)) {
              // console.error('TODO');
              return '';
            }

            const payNow = config.plan.price ? (config.plan.price - config.upgradeData.saleValue) : (config.plan.full_price - config.plan.discount);

            const data = {
              unusedDays: config.upgradeData.totalTime - config.upgradeData.usedTime,
              currentPlanName: config.upgradeData.currentPlan.subscriptionName,
              newPlanName: config.plan.name ? config.plan.name[user.locale] : config.plan.new_subscription_name,
              newPlanPrice: config.plan.price || config.plan.full_price,
              newPlanPeriod: config.plan.is_annual ? __('yearly') : __('monthly'),
              payNow: (Math.round((payNow) * 10) / 10),
            };

            return __('calc_popup_text', data);
          },
        },
      ],
    };
  },
  getThankUTemplate() {
    return {
      css: 'thankUpgradePopupBody',
      width: 475,
      padding: 24,
      rows: [
        {
          view: 'template',
          height: 30,
          borderless: true,
          // width: 475,
          css: 'thank-upgrade-popup-title',
          template() {
            return `<span>${__('thank_upgrade_popup_title')}</span>${smile_icon}`;
          },
        },
        { height: 12 },
        {
          view: 'template',
          borderless: true,
          id: innerViews.thankForUpgradePopup.textID,
          autoheight: true,
          css: 'thank-upgrade-popup-text',
          suggest: 'upgrade',
          plan: { name: { en: '', ru: '' } },
          template() {
            const self = this;
            const data = {
              newPlanName: self.plan.name ? self.plan.name[user.locale] : self.plan.new_subscription_name,
            };

            if (self.suggest === 'unsubscribe') {
              return __('thank_unsubscribe_popup_text', data);
            }

            return __('thank_upgrade_popup_text', data);
          },
        },
        { height: 36 },
        {
          view: 'button',
          id: 'thank-upgrade-popup-button',
          css: 'custom-days-add-button',
          align: 'center',
          height: 36,
          width: 180,
          borderless: true,
          value: __('common_ok'),
          on: {
            onItemClick() {
              innerHelpers.hideThankPopup();
            },
          },
        },
        { height: 18 },
        {
          view: 'label',
          align: 'center',
          css: 'upgrade-popup-footer',
          autoheight: true,
          label: __('upgrade_popup_footer_text'),
        },
      ],
    };
  },
  getPopupHeader() {
    return {
      height: 102,
      css: 'upgrade-popup-header',
      rows: [
        { height: 14 },
        {
          height: 24,
          cols: [
            { width: 24 },
            {
              view: 'label',
              align: 'left',
              label: __('upgrade_popup_title'),
              css: 'upgrade-popup-header-title',
            },
            {
              view: 'icon',
              width: 24,
              template: `<div class='upgrade-popup-header-icon'>${icon_close}</div>`,
              on: {
                onItemClick: innerHandlers.hideUpgradePopup,
              },
            },
            { width: 12 },
          ],
        },
        { height: 12 },
        {
          cols: [
            { width: 24 },
            {
              view: 'template',
              borderless: true,
              height: 48,
              id: innerViews.upgradePopupHeaderText.id,
              css: 'upgrade-popup-header-text',
              template() {
                return __('upgrade_popup_header_text', { subscriptionName: user.subscription.name });
              },
            },
          ],
        },
      ],
    };
  },
  getPopupBody() {
    return {
      rows: [
        {
          paddingX: 24,
          // width: 1000,
          css: 'upgrade-popup',
          rows: [
            {
              id: innerViews.upgradePopupToggleLayout.id,
              hidden: true,
              rows: [
                { height: 24 },
                innerViews.upgradePopupToggle,
              ],
            },
            { height: 24 },
            {
              view: 'template',
              borderless: true,
              autoheight: true,
              css: 'upgrade-popup-template payment-body-container upgrade',
              id: `${innerViews.upgradePopup.id}body`,
              width: 672,
              isAnnual: true,
              template() {
                return innerTemplates.getPopupBodyHTML(this);
              },
              on: {
                onAfterRender() {
                  const blocks = this.getNode().querySelectorAll('.payment-body-container-block');

                  blocks.length > 0 && _.first(blocks).classList.add('selected');
                },
              },
              onClick: {
                'payment-body-container-block': function (event, el, node) {
                  const block = node;

                  if (block) {
                    const selected = block.parentElement.querySelector('.payment-body-container-block.selected');

                    selected.classList.remove('selected');
                    block.classList.add('selected');
                  }
                },
                'payment-body-container-block-button': function (e, el, node) {
                  const $$upgradePopup = $$(innerViews.upgradePopup.id);
                  const index = e.target.parentElement.dataset.index;
                  const $$thankForUpgradeText = $$(innerViews.thankForUpgradePopup.textID);

                  $$(`${innerViews.upgradePopup.id}body`).disable();

                  if (index) {
                    status.handlers.showMainSpinner(true);
                    $$upgradePopup.config.upgradeData.nextPlan = $$upgradePopup.config.allPlans[index];
                    $$thankForUpgradeText.config.plan = $$upgradePopup.config.upgradeData.nextPlan;
                    innerHandlers.upgradeButtonClick()
                      .then(() => {
                        $$(`${innerViews.upgradePopup.id}body`).enable();
                      });
                  }
                },
                'request-upgrade-button': function (e) {
                  app.trigger('contactUsForm:show', 'upgrade');
                },
              },
            },
          ],
        },
        { height: 12 },
        {
          view: 'label',
          align: 'center',
          css: 'upgrade-popup-footer',
          height: 18,
          label: __('upgrade_popup_footer_text'),
        },
        { height: 24 },
      ],
    };
  },
  payproUpgradeHandler($$popup, data) {

    if (data && data.currentPlan/* && data.nextPlans.length > 0 */) {
      const lastPaymentDate = moment(data.currentPlan.lastPayment, ['M/D/YYYY']);
      const nextPaymentDate = moment(data.currentPlan.nextPayment, ['M/D/YYYY']);

      const totalTime = nextPaymentDate.diff(lastPaymentDate, 'days');
      const usedTime = moment().diff(lastPaymentDate, 'days');
      const saleValue = Math.round((data.currentPlan.subscriptionPrice * ((totalTime - usedTime) / totalTime)) * 10) / 10;

      data.currentPlan.subscriptionName = user.subscription.name;

      $$popup.config.upgradeData = _.assign(data, {
        totalTime,
        usedTime,
        saleValue,
      });

      $$(`${innerViews.upgradePopup.id}body`).refresh();
    } else {
      app.trigger('paymentPlanInfo:show', { type: 'default' });
    }
  },
  checkoutUpgradeHandler($$popup, data) {
    if (data && data.currentPlan/* && data.nextPlans.length > 0 */) {
      const lastPaymentDate = moment(data.currentPlan.lastPayment);
      const nextPaymentDate = moment(data.currentPlan.nextPayment);

      const totalTime = nextPaymentDate.diff(lastPaymentDate, 'days');
      const usedTime = moment().diff(lastPaymentDate, 'days');
      const saleValue = Math.round((data.currentPlan.subscriptionPrice * ((totalTime - usedTime) / totalTime)) * 10) / 10;

      data.currentPlan.subscriptionName = user.subscription.name;

      $$popup.config.upgradeData = _.assign(data, {
        totalTime,
        usedTime,
        saleValue,
      });

      $$(`${innerViews.upgradePopup.id}body`).refresh();
    } else {
      app.trigger('paymentPlanInfo:show', { type: 'default' });
    }
  },
  // showUpgradeData: function ($$popup) {
  //   $$popup.config.upgradeData = null;

  //   status.handlers.showMainSpinner(true);

  //   planModel.getSubscriptionStatus()
  //     .then((data) => {
  //       if (data.provider) {
  //         data.provider === "paypro" && innerHelpers.payproUpgradeHandler($$popup, data);
  //         data.provider === "2checkout" && innerHelpers.checkoutUpgradeHandler($$popup, data);
  //       }

  //       status.handlers.hideMainSpinner();
  //     });
  // },
  showThankPopup() {
    $$(innerViews.thankForUpgradePopup.id).show();

    _.delay(() => {
      const webWinBody = document.querySelector('.thankUpgradePopupBody').closest('.webix_win_body');

      webWinBody.classList.add('active');
    }, 10);
  },
  hideThankPopup() {
    const webWinBody = document.querySelector('.thankUpgradePopupBody').closest('.webix_win_body');

    webWinBody.classList.remove('active');

    _.delay(() => {
      $$(innerViews.thankForUpgradePopup.id).hide();
      $$('userProfilePopup') && $$('userProfilePopup').hide();
    });
  },
  changeLocaleThankPopup(date) {
    const $$thankForUpgradeText = $$(innerViews.thankForUpgradePopup.textID);

    if (date === 'unsubscribe') {
      $$thankForUpgradeText.config.suggest = 'unsubscribe';
    }

    $$thankForUpgradeText.refresh();
    innerHelpers.showThankPopup();
  },
};

const innerHandlers = {
  init: {
    render: _.once(() => {
      webix.ui({
        id: innerViews.calcPopup.id,
        view: 'popupWithoutPadding',
        css: 'calc-popup',
        hidden: true,
        borderless: true,
        padding: 12,
        width: 381,
        body: innerHelpers.getCalcucationTemplate(),
      });

      webix.ui({
        id: innerViews.thankForUpgradePopup.id,
        view: 'ganttproWindowPopup',
        css: 'clear-window-popup white-header-window thank-upgrade-popup',
        // width: 475,
        // height: 260,
        // padding: 24,
        hidden: true,
        autoheight: true,
        head: false,
        body: innerHelpers.getThankUTemplate(),
      });

      webix.ui({
        id: innerViews.upgradePopup.id,
        view: 'window',
        css: 'clear-window-popup white-header-window',
        position: 'center',
        modal: true,
        move: false,
        borderless: true,
        hidden: true,
        head: innerHelpers.getPopupHeader(),
        body: innerHelpers.getPopupBody(),
        on: {
          onShow() {
            innerHelpers.showUpgradeData(this);
          },
        },
      });
    }),
    eventHandlers: _.once(() => {
      app.on('popup:show', innerHandlers.hideUpgradePopup);

      app.on('notify:payment:orderFilled', innerHandlers.refreshForUpgrade);
      app.on('notify:payment:orderFailed', innerHandlers.refreshForUpgrade);

      document.querySelector('body')
        .addEventListener('mouseover', _.debounce(innerHelpers.showCalculatePopup, 150), false);
    }),
  },
  changeUpgrade(isAnnual) {
    const select = document.querySelectorAll('.upgrade-popup-toggle-select');

    select.forEach(item => {
      if (+item.dataset.annual === isAnnual) {
        item.classList.add('selected');
        $$(`${innerViews.upgradePopup.id}body`).config.isAnnual = true;
      } else {
        item.classList.remove('selected');
        $$(`${innerViews.upgradePopup.id}body`).config.isAnnual = false;
      }
    });

    $$(`${innerViews.upgradePopup.id}body`).refresh();
  },
  showUpgradePopup() {
    $$(innerViews.upgradePopup.id).show();
  },
  hideUpgradePopup() {
    $$(innerViews.upgradePopup.id).hide();
  },
  refreshForUpgrade() {
    const $$upgradePopupHeaderText = $$(innerViews.upgradePopupHeaderText.id);

    $$upgradePopupHeaderText.refresh();
  },
  upgradeButtonClick() {
    const $$upgradePopup = $$(innerViews.upgradePopup.id);
    const upgradeData = $$upgradePopup.config.upgradeData;

    if (!upgradeData) {
      app.trigger('paymentPlanInfo:show', { type: 'default' });
      status.handlers.hideMainSpinner();

      return Promise.resolve();
    }

    return planModel.upgradePlan(upgradeData)
      .then(() => {
        $$upgradePopup.hide();
        innerHelpers.showThankPopup();
        status.handlers.hideMainSpinner();
      });
  },
};

const outerObject = {
  init: {
    run() {
      innerHandlers.init.render();
      innerHandlers.init.eventHandlers();
    },
  },
  handlers: {
    showUpgradePopup: innerHandlers.showUpgradePopup,
    hideUpgradePopup: innerHandlers.hideUpgradePopup,
  },
  innerHelpers,
};

outerObject.init.run();

app.on('upgradePopup:show', data => {
  innerHandlers.showUpgradePopup();
});

export default outerObject;
