import helperFeedback from '../../../helpers/feedback';
import studentPopup from '../../studentPopup/main';
import videoLayout from '../../videoLayout/main';

import backIconPrice from '../../../svg/pricing/back_price.svg';
import closeCross from '../../../svg/ic_close.svg';
import iconNumberOne from '../../../svg/pricing/number_one.svg';
import iconNumberTwo from '../../../svg/pricing/number_two.svg';
import iconNumberThree from '../../../svg/pricing/number_three.svg';
import iconPlayHover from '../../../svg/pricing/icon_play-hover.svg';

import store from '../../../store/main';

function ValidURL(str) {
  const pattern = new RegExp(/\(?(?:(http|https|ftp):\/\/)?(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/gi);

  return pattern.test(str);
}

const templates = {
  getMainPaymentTemplateStudentHeader: () => `${"<div class='payment-body-header'>"
      + "<span class='payment-body-header-text back_btn'>"}${backIconPrice}${__('back_btn')}</span>`
      + `<div class='payment-body-header-text manage'>${__('student_page_title')}</div>`
      + '<div class=\'payment-body-header-button white\'>'
      + `<div class='arrow'>${
        closeCross
      }</div>`
      + '</div>'
      + '</div>',
  paymentPlanStudentHero: () => `${"<div class='payment-body-student-page-hero'>"

      + "<div class='student-page-hero-container'>"
      + "<div class='student-page-hero-text'>"
      + "<div class='student-page-hero-title'>"}${__('student_page_hero_title')}</div>`
      + `<div class='student-page-hero-desc'>${__('student_page_hero_desc')}</div>`
      + '</div>'
      + '<div class=\'student-page-hero-pic\'>'
      + `<img src='${GT.cdn}/imgs/pricing/student-hero-pic.png'>`
      + '</div>'
      + '</div>'
      // "<div class='student-page-hero-addition'>" + __('student_page_hero_addition') + "</div>" +
      + '</div>',
  paymentPlanStudentMain: () => {
    const srcVideoStudentImage = __('student_video_bg_img');
    const srcVideoStudentSrc = __('student_video_src');
    const studentReviewLinkLocale = `student_review_link_${(Math.ceil(window.user.id) % 20) + 1}`;

    return `${"<div class='payment-body-student-page-main'>"
      + "<div class='student-main-container student-main-container-left'>"

      // header
      + "<div class='student-main-head'>"
      + "  <div class='student-main-head-title'>"}${__('student_main_head_title')}</div>`
      + '    <div class=\'student-main-head-tabs\'>'
      + '      <div class=\'tab-button js-tabs-left active-left\'>'
      + `        <div class='tab-button-title'>${__('student_main_head_tabs_left_title')}</div>`
      + `        <div class='tab-button-link'>${__('student_main_head_tabs_left_link')}</div>`
      + '      </div>'
      + '      <div class=\'tab-button js-tabs-right\'>'
      + `        <div class='tab-button-title'>${__('student_main_head_tabs_right_title')}</div>`
      + `        <div class='tab-button-link'>${__('student_main_head_tabs_right_link')}</div>`
      + '      </div>'
      + '      <div class=\'after-line\'>' + '</div>'
      + '    </div>'
      + '</div>'

      + '<div id=\'left\' class=\'student-main-layout student-main-layout-left js-show-student-main-content-left\'>'
      + '  <div class=\'student-main-content\'>'
      + '    <div class=\'content-text-one-capterra\'>'
      // "      <div class='capterra-text'>" + __('content_box_video_text_right') +
      // "        <a href=" + __('student_review_link') + " target='_blank' class='capterra-text-link'>" + __('student_link_review') + "</a>" +
      // "      </div>" +
      + `         <div class='capterra-text'>${__('content_box_video_text_right_link_before')} ${__(studentReviewLinkLocale)}<br>${__('content_box_video_text_right_link_after')}</div>`
      + `      <div class='content-text-one-capterra-number'>${iconNumberOne}</div>`
      + '    </div>'
      + '    <div class=\'content-text-two-capterra\'>'
      + `      <div class='capterra-text'>${__('content_box_video_text_right_02')}</div>`
      + `      <div class='content-text-two-capterra-number'>${iconNumberTwo}</div>`
      + '    </div>'
      + '    <div class=\'content-text-three-capterra\'>'
      + `    <div class='capterra-text'>${__('form_btn_desc_text_year')}</div>`
      + `    <div class='content-text-three-capterra-number'>${iconNumberThree}</div>`
      + '      <form class=\'contact_form\' enctype=\'multipart/form-data\'>'
      + '        <div class=\'form_cell\'>'
      + `          <input id='js-form-validation-year' class='form_input-year' type='url' placeholder='${__('student_form_input_placeholder')}' name='url'>`
      + `        <div class='capterra-text'>${__('form_btn_desc_text_review_year')}</div>`
      + '        <div class=\'form_cell-review\'>'
      + `          <p class='form_error-year-review isVisible'>${__('from_error_text')}</p>`
      + `          <input id='js-form-validation-review-year' class='form_input-review-year' type='url' placeholder='${__('student_form_input_placeholder')}' name='url'>` + '</div>'
      + '      <div class=\'form_btn\'>'
      + `        <a class='form-btn-link-year'>${__('common_done')}</a>`
      + '      </div>'
      + `          <p class='form_error-year isVisible'>${__('from_error_text')}</p>`
      + '        </div>'
      + '      </form>'
      + `      <div class='form-btn-desc-link'>${__('form_btn_desc_text')}</div>`
      + '    </div>'
      + '  </div>'
      + '</div>'

      + '<div id=\'right\' class=\'student-main-layout student-main-layout-right\'>'

      + '  <div class=\'student-main-content\'>'
      + '    <div class=\'content-box-video\'>'
      + `      <div class='video-text'>${__('content_box_video_text')}</div>`
      + `      <div class='video-desc'>${__('content_box_video_desc')}</div>`
      + `      <div class='video-number'>${iconNumberOne}</div>`
      + '      <div class=\'video-box\'>'
      + `        <div class='video-box-play hover'>${iconPlayHover}</div>`
      + `        <img class='promo-video-image js-show-global-video-fullscreen' data-video-src='${srcVideoStudentSrc}' src='${srcVideoStudentImage}' data-video-class='ratio_16-8-3725'>`
      + '      </div>'
      + '    </div>'
      + '    <div class=\'content-text-two\'>'
      + `      <div class='video-text'>${__('content_box_video_text_02')}</div>`
      + `      <div class='video-number-two'>${iconNumberTwo}</div>`
      + '    </div>'
      + '    <div class=\'content-text-three\'>'
      + `    <div class='video-text'>${__('content_box_video_text_03')}</div>`
      + `    <div class='video-number-three'>${iconNumberThree}</div>`
      + '      <form class=\'contact_form\'>'
      + '        <div id=\'form_cell_student\' class=\'form_cell\'>'
      + `          <input id='js-form-validation' class='form_input' type='url' placeholder='${__('student_form_input_placeholder')}' name='url'>`
      + `        <div class='video-text'>${__('content_box_video_text_03_student_id')}</div>`
      + '        <div class=\'form_cell-student\'>'
      + `          <p class='form_error-student isVisible'>${__('from_error_text')}</p>`
      + `          <input id='js-student-id-form-validation' class='form_input-student' type='url' placeholder='${__('student_form_input_placeholder')}' name='url'>` + '</div>'
      + '          <div class=\'form-btn\'>'
      + `            <a class='form-btn-link'>${__('common_done')}</a>`
      + '          </div>'
      + `          <p class='form_error isVisible'>${__('from_error_text')}</p>`
      + '        </div>'
      + '      </form>'
      + `      <div class='form-btn-desc-link'>${__('form_btn_desc_text')}</div>`
      + '    </div>'
      + '  </div>'

      + '</div>'

      + '</div>'
      + '</div>';
  },
  paymentPlanStudentBottom: () => `${"<div class='payment-body-student-page-bottom'>"

      + "<div class='student-page-hero-container'>"
      + "<div class='student-page-hero-bottom-title'>"}${__('student_page_hero_title_bottom')}</div>`
      + '<div class=\'student-page-hero-bottom-pic\'>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/dpm.png'>` + '</div>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/univer_michigan.png'>` + '</div>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/stanford.png'>` + '</div>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/vashington.png'>` + '</div>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/poerto_rico.png'>` + '</div>'
      + '<div class=\'hero-bottom-pic one\'>' + `<img src='${GT.cdn}/imgs/pricing/student-ochif/unver_cornell.png'>` + '</div>'
      + '</div>'
      + '</div>'

      + '</div>',
  getMainPaymentTemplateStudent: () => templates.paymentPlanStudentHero() + templates.paymentPlanStudentMain() + templates.paymentPlanStudentBottom(),
};

const handlers = {
  sendEmail(message) {
    helperFeedback.send({
      message,
      type: 'student-app-pricing-form',
      send_followup_email: true,
    });
  },
  submitFormStudent() {
    const urlValidate = document.getElementById('js-form-validation').value;
    const urlStudentIdValidate = document.getElementById('js-student-id-form-validation').value;

    const validURL = ValidURL(urlValidate);
    const validStudentURL = ValidURL(urlStudentIdValidate);

    if (validURL && validStudentURL) {
      const message = `${urlValidate}\n${urlStudentIdValidate}`;

      handlers.sendEmail(message);
      studentPopup.init();
      document.getElementById('js-form-validation').value = '';
      document.getElementById('js-student-id-form-validation').value = '';
    } else if (validURL && !validStudentURL) {
      document.querySelector('.form_error-student').classList.remove('isVisible');
      document.querySelector('.form_input-student').classList.add('form-error-border');

      return false;
    } else if (!validURL && validStudentURL) {
      document.querySelector('.form_error').classList.remove('isVisible');
      document.querySelector('.form_input').classList.add('form-error-border');

      return false;
    } else {
      document.querySelector('.form_error').classList.remove('isVisible');
      document.querySelector('.form_input').classList.add('form-error-border');
      document.querySelector('.form_error-student').classList.remove('isVisible');
      document.querySelector('.form_input-student').classList.add('form-error-border');

      return false;
    }
  },
  submitFormStudentYear() {
    const urlValidateYear = document.getElementById('js-form-validation-year').value;
    const urlReviewValidateYear = document.getElementById('js-form-validation-review-year').value;

    const validURL = ValidURL(urlValidateYear);
    const validReviewURL = ValidURL(urlReviewValidateYear);

    if (validURL && validReviewURL) {
      const message = `${urlValidateYear}\n${urlReviewValidateYear}`;

      handlers.sendEmail(message);
      studentPopup.init();
      document.getElementById('js-form-validation-year').value = '';
      document.getElementById('js-form-validation-review-year').value = '';
    } else if (validURL && !validReviewURL) {
      document.querySelector('.form_error-year-review').classList.remove('isVisible');
      document.querySelector('.form_input-review-year').classList.add('form-error-border');

      return false;
    } else if (!validURL && validReviewURL) {
      document.querySelector('.form_error-year').classList.remove('isVisible');
      document.querySelector('.form_input-year').classList.add('form-error-border');
    } else {
      document.querySelector('.form_error-year').classList.remove('isVisible');
      document.querySelector('.form_input-year').classList.add('form-error-border');
      document.querySelector('.form_error-year-review').classList.remove('isVisible');
      document.querySelector('.form_input-review-year').classList.add('form-error-border');
    }
  },
};

const eventHandlers = e => {
  const formContainer = document.querySelector('.student-main-container');
  const left = document.querySelector('#left');
  const right = document.querySelector('#right');

  const leftTab = document.querySelector('.js-tabs-left');
  const rightTab = document.querySelector('.js-tabs-right');

  const afterLine = document.querySelector('.after-line');
  const tabsHeader = document.querySelector('.student-main-head');

  const inputs = document.querySelectorAll('input');
  const errorForm = document.querySelector('.form_error-year');
  const errorFormYear = document.querySelector('.form_error-year');
  const errorFormStudent = document.querySelector('.form_error-student');
  const errorFormReview = document.querySelector('.form_error-year-review');
  const errorMessages = [errorForm, errorFormReview, errorFormStudent, errorFormYear];

  if (e.target.closest('.tab-button')) {
    inputs.forEach(input => input.classList.remove('form-error-border'));
    errorMessages.forEach(errorMessage => errorMessage.classList.add('isVisible'));
  }

  if (e.target.closest('.payment-body-header-text.back_btn') || e.target.closest('.payment-body-header-button.white')) {
    store.commit('paymentPlans/showStudentsPage', false);

    return;
  }

  if (e.target.closest('.js-tabs-left')) {
    left.classList.add('js-show-student-main-content-left');
    leftTab.classList.add('active-left');

    right.classList.remove('js-show-student-main-content-right');
    rightTab.classList.remove('active-right');

    afterLine.classList.remove('js-after-line-right');

    formContainer.style.height = `${tabsHeader.clientHeight + left.clientHeight}px`;

    return;
  }

  if (e.target.closest('.js-tabs-right')) {
    left.classList.remove('js-show-student-main-content-left');
    leftTab.classList.remove('active-left');

    right.classList.add('js-show-student-main-content-right');
    rightTab.classList.add('active-right');

    afterLine.classList.add('js-after-line-right');

    formContainer.style.height = `${tabsHeader.clientHeight + right.clientHeight}px`;
  }

  if (e.target.closest('.form-btn-link-year')) {
    handlers.submitFormStudentYear();

    return;
  }

  if (e.target.closest('.form-btn-link')) {
    handlers.submitFormStudent();

    return;
  }

  if (e.target.closest('.js-show-global-video-fullscreen')) {
    videoLayout.init(e);
  }
};

export {
  templates,
  handlers,
  eventHandlers,
};
