import moment from 'moment';
import _ from 'lodash';
import app from '../../../app';
import globalStore from '$$store/main';
import projectsModel from '../../../models/projects';
import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import rights from '../../rights';
import massChangeModel from '../../../views/gantt/modules/massChange';

function _mappingApiTaskFieldsAndWebTaskFields(_task) {
  if (_task.name) _task.text = _task.name;

  if (_task.description !== undefined) _task.note = _task.description;

  if (_task.startDate) _task.start_date = _task.startDate;

  if (_task.endDate) _task.end_date = _task.endDate;

  if (_task.projectId) _task.gantt_id = _task.projectId;

  if (_task.ownerId) _task.owner_id = _task.ownerId;

  if (_task.createdAt) _task.created_at = _task.createdAt;

  if (_task.resources?.length) {
    _task.resources = _task.resources.map(r => {
      if (r.resourceId) {
        return {
          resource_id: r.resourceId,
          ...r,
        };
      }

      return r;
    });
  }

  /**
  * priority options stored in DB with ids from 5 to 9
  * such operation maps it with api notation (from 1 to 5)
  * jira priority options id is far above 9 and have to be passed without mapping
  */
  if (_task.priority) _task.priority = _task.priority > 9 ? _task.priority : 6 - _task.priority + 4;

  return _task;
}
export function getParentsIDs(taskID) {
  const task = gantt.isTaskExists(taskID) && gantt.getTask(taskID);
  const parentsIDs = [];

  if (task) {
    let pid = gantt.getParent(task);

    while (pid) {
      parentsIDs.push(pid);
      const parent = gantt.isTaskExists(pid) && gantt.getTask(pid);

      pid = gantt.getParent(parent);
    }
  }

  return parentsIDs;
}

export function refreshTasksByIDs(taskIDs) {
  _.each(taskIDs, id => {
    gantt.isTaskExists(id) && gantt.refreshTask(id);
  });
}

export function refreshParents(taskID) {
  if (gantt.isTaskExists(taskID)) {
    const task = gantt.getTask(taskID);

    gantt.refreshTask(task.id);
    const parentsIDs = getParentsIDs(taskID);

    refreshTasksByIDs(parentsIDs);
  }
}

export function refreshTasksWithParents(taskIds) {
  const parentIds = [];

  taskIds.forEach(task_id => {
    if (!gantt.isTaskExists(task_id)) { return; }

    gantt.refreshTask(task_id);
    parentIds.push(...getParentsIDs(task_id));
  });
  refreshTasksByIDs(parentIds);
}

export function prepareTaskToRefresh(taskToUpdate) {
  let _task = _mappingApiTaskFieldsAndWebTaskFields({ ...taskToUpdate });

  const fieldsToUpdate = [
    'id', 'gantt_id', 'start_date', 'end_date', 'text', 'note', 'type', 'progress', 'duration', 'estimation', 'parent', 'sortorder', 'created_at',
    'owner_id', 'last_update', 'color', 'deadline', 'resource_id', 'status', 'priority', 'resources', 'constraint_type', 'constraint_date', 'jira_key',
    'comments', 'attachments', 'actual_cost', 'total_price',
  ];
  const numberFields = ['id', 'gantt_id', 'progress', 'duration', 'estimation', 'parent', 'sortorder', 'color', 'status', 'owner_id', 'priority', 'actual_cost', 'total_price'];
  const dateFields = ['start_date', 'end_date', 'last_update', 'deadline', 'constraint_date'];

  _task = _.pick(_task, fieldsToUpdate);

  _.each(numberFields, key => {
    if (key === 'estimation' && _task[key] > 0) {
      _task[key] = Math.round((+_task[key] / 60) * 100) / 100;
    }

    if (_task[key]) {
      _task[key] = +_task[key];
    }
  });

  _.each(dateFields, key => {
    if (_task[key]) {
      _task[key] = moment(_task[key]).toDate();
    }
  });

  return _task;
}

export function updateButtonInTask(taskID, ganttID) {
  if (taskID) {
    const children = gantt.getChildren(taskID);

    children.forEach(id => {
      const task = gantt.isTaskExists(id) && gantt.getTask(id);
      const isButton = task && task.type === gantt.config.types.button;

      if (isButton) {
        gantt._dp.setUpdateMode('off');
        gantt.deleteTask(id);
        gantt._dp.setUpdateMode('cell');
      }
    });

    const parentTask = gantt.getTask(taskID);

    if (parentTask && gantt.getChildCount(taskID) > 0 && parentTask.type !== gantt.config.types.project) {
      parentTask.type = gantt.config.types.project;
      gantt.refreshTask(taskID);
    }

    if (parentTask && parentTask.type !== gantt.config.types.project) {
      // gantt.callEvent('ganttClearAndParse');
      gantt.refreshTask(taskID);

      return;
    }

    const button = {
      unscheduled: true,
      auto_scheduling: false,
      text: '',
      sortorder: 10000,
      type: 'button',
      skipShowing: true,
      callaborationAdd: true,
    };

    if (rights.project.hasRight(ganttID, 'create_task')) {
      gantt.createTask({
        ...button,
        parent: taskID,
        calendar_id: ganttID,
      });
    }
  }
}

export async function resourceHelper(ganttId, resourcesToTasks, massAssignData, resources) {
  // const allGanttIds = projectsModel.getAllProjects().map(o => o.id);
  //
  // if (!allGanttIds.includes(ganttId)) {
  //   return Promise.resolve();
  // }

  globalStore.dispatch('tasksModel/updateTasksResourcesByGanttId', { resourcesToTasks, ganttId });
  globalStore.dispatch('headerView/setUIConfig');

  const ids = [];
  const multiviewData = multiViewsProjectsModel.getActiveViewData();
  const activeProjectId = projectsModel.getActiveGanttId();

  multiviewData && ids.push(...multiviewData.ganttIDs);
  activeProjectId && ids.push(activeProjectId);

  if (!ids.includes(ganttId)) return;

  gantt.batchUpdate(() => {
    resources.forEach(({ taskId }) => {
      const taskData = globalStore.getters['tasksModel/getTaskByGanttId'](ganttId, taskId);

      const task = gantt.getTask(taskId);
      // app.trigger('tasks:model:updateTask', taskData.id, taskData);

      task && (task.resources = taskData.resources);
    });
  });

  if (!rights.project.hasRight(ganttId, 'all_tasks') && massChangeModel.isEnabled()) {
    const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

    resources.forEach(item => {
      if (item.unassignedResourcesIds?.includes(resourceId)) {
        massChangeModel.unselectTask(item.taskId, true);
      }
    });
  }
}
