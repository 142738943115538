<template>
  <div :class="$.row_input_wrapper">
    <div
      v-if="!editing"
      v-tippy="{ content: formattedContent }"
      :class="[$.vgp_displayed_value, readonly && $.readonly]"
      :style="{ maxWidth: maxWidth ? `${maxWidth}px` : '100%', fontSize: styles.fontSize }"
      @click="showInput"
    >
      {{ formattedContent }}
    </div>
    <input
      v-else
      ref="input"
      v-model="content"
      :gpAutotestName="gpAutotestName"
      :class="[$.vgp_row_input, bold ? $.bold : '']"
      :style="{width: width, ...styles}"
      :readonly="readonly"
      :disabled="readonly"
      @focus="onFocus"
      @change="onChange($event)"
      @input="onInput($event)"
      @keydown="onKeydown($event)"
      @blur="onBlur"
    >
    <div
      ref="hidden"
      :class="[$.vgp_row_input__hidden, bold ? $.bold : '']"
      :style="{fontSize: styles.fontSize}"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
import numbersHelper from '../../../../helpers/numbers';
import { mapState } from 'vuex';

export default {
  name: 'VgpRowInput',
  props: {
    value: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: '#191919' },
    bold: { type: Boolean, required: false, default: true },
    readonly: { type: Boolean, required: false, default: false },
    fontSize: { type: Number, required: false, default: 12 },
    inputType: { type: String, required: false, default: 'text' },
    maxWidth: { type: Number, required: false },
    gpAutotestName: { type: String, required: false, default: '' },
    format: { type: String, required: false, default: ''}
  },
  emits: {
    onChange: String,
    onInput: String,
    onKeydown: Object,
  },
  data() {
    return {
      editing: false,
      content: '',
      width: '',
    };
  },
  computed: {
    styles() {
      return {
        maxWidth: this.maxWidth ? `${this.maxWidth}px` : '100%',
        color: this.color,
        fontSize: `${this.fontSize}px`,
      };
    },
    formattedContent() {
      let val = '';

      switch (this.format) {
        case 'currency':
          const num = +this.content;
          val = this.currencySign + (isNaN(num) ? this.value : numbersHelper.getFormatted(num));
          break;
        default:
          val = this.content;
          break;
      }

      return val;
    },
    ...mapState('teamSetting', {
      currencySign: state => {
        if (!state.enableCurrency) {
          return '';
        }

        return GT.currency.find((item) => item.ID === state.currency)?.VALUE || '$'
      }
    })
  },
  watch: {
    value: {
      handler(newValue) {
        this.valueHandler(newValue);
      },
      immediate: true,
    },
  },
  mounted() {
    this.calcWidth();
  },
  created() {
    this.content = this.value;
  },
  methods: {
    calcWidth() {
      this.$nextTick(() => {
        const hidden = this.$refs.hidden;

        this.width = `${hidden.offsetWidth + 16}px`;
      });
    },
    onInput(e) {
      let value = e.target.value;

      if (this.inputType === 'number') {
        value = value.replace(/[^\d.,]/g, '');
        e.target.value = value;
      }
      this.content = value;
      this.$emit('onInput', value);
      this.calcWidth();
    },
    onChange(e) {
      let val = e.target.value;
      if (this.format === 'currency') {
        val = e.target.value.replace(/,/g, '.');

        if (isNaN(+val)) {
          this.valueHandler(this.value);
          this.editing = false;

          return;
        }

        this.valueHandler(val);
      }
      this.$emit('onChange', val);
      this.editing = false;
    },
    onKeydown(e) {
      if (e.keyCode === 13) {
        let val = e.target.value;
        if (this.format === 'currency') {
          val = e.target.value.replace(/,/g, '.');

          if (isNaN(+val)) {
            this.valueHandler(this.value);
            this.$refs.input.blur();
            this.editing = false;

            return;
          }

          this.valueHandler(val);
        }

        this.$emit('onKeydown', val);
        this.$refs.input.blur();
        this.editing = false;
      }
    },
    onFocus() {
      this.$el.querySelector('input').select();
    },
    onBlur() {
      this.editing = false;
    },
    showInput() {
      if (this.readonly) return;

      this.editing = true;
      this.$nextTick(() => {
        this.onFocus();
      });
    },
    valueHandler(newValue) {
      const stringValue = typeof newValue === 'number' ? newValue.toString() : newValue;

      this.content = stringValue.trim();
      this.calcWidth();
    }
  },
};
</script>

<style module='$' src='./vInput.less' />
