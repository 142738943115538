var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.section__left },
    [
      _c(
        "h1",
        {
          ref: "title",
          class: [
            _vm.$style.section__title,
            _vm.animateLeave ? _vm.$style.animateLeave : "",
            _vm.animateEnter ? _vm.$style.animateEnter : "",
          ],
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c("h2", {
        ref: "description",
        class: [
          _vm.$style.section__description,
          _vm.animateLeave ? _vm.$style.animateLeave : "",
          _vm.animateEnter ? _vm.$style.animateEnter : "",
        ],
        domProps: { innerHTML: _vm._s(_vm.description) },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }