var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "size-value" }, [
        _vm._v(
          "\n  " + _vm._s(_vm.usedSpace) + " / " + _vm._s(_vm.spaceLimit) + "\n"
        ),
      ])
    : _c("div", { staticClass: "size-value" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }