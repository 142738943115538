import app from "./../../app";
import videoLayout from "../videoLayout/main";

const __ = window.__;

var innerObject = {
  views: {
    main: {
      id: "notificationContainerModal",
    },
    body: {
      id: "notificationContainerModalBody",
      type: "template",
      // paddingX: 24,
      // paddingY: 14,
      borderless: true,
      template: "test",
      onClick: {
        "js-show-global-video-fullscreen": function (e) {
          videoLayout.init(e);
        }
      }
    },
    closeBtn: {
      view: 'button',
      id: "notificationContainerModalCloseBtn",
      value: __('common_close'),
      on: {
        onItemClick: function () {
          innerObject.handlers.hide();
        }
      }
    }
  },
  handlers: {
    show: function (template, data, options, confirmId) {
      $$("notificationContainerModal").config._is_popup_shown = true;
      innerObject.settings.confirmId = confirmId;
      var body = $$(innerObject.views.body.id);
      body.define('template', template);
      body.setValues(data);
      innerObject.helpers.defineSettings(options);
      $$(innerObject.views.main.id).show();
      this._toggle_transform_popup(true);
    },
    hide: function (force) {
      const that = $$("notificationContainerModal");
      var popupHide = _.bind(webix.ui.window.prototype.hide, that);

      if (that.config._is_popup_shown) {
        that.callEvent("onBeforeAnimationHide", []);
      }
      innerObject.handlers._toggle_transform_popup(false);
      that.config._is_popup_shown = false;
      that.callEvent("onAfterAnimationHide", []);
      that.hide();
      if (innerObject.settings.confirmId) {
        app.trigger("notification:confirm", innerObject.settings.confirmId);
      }
      app.trigger("notification:closed");
      popupHide(force);
    },

    _toggle_transform_popup: function (isShow) {
      const that = $$("notificationContainerModal");

      that.$view.style.transition = 'transform 240ms, opacity 240ms';
      that.$view.style.opacity = '0';
      that.$view.style.transform = 'translateY(25px)';
      that.$view.style.pointerEvents = 'none';
      that.$view.style.display = 'none';

      var callMethod = function () {
        if (!that.$view) {
          return false;
        }
        if (isShow) {
          that.$view.style.opacity = '1';
          that.$view.style.pointerEvents = 'auto';
          that.$view.style.transform = 'translateY(0)';
        } else {
          that.$view.style.opacity = '0';
          that.$view.style.pointerEvents = 'none';
          that.$view.style.transform = 'translateY(25px)';
        }
      };

      isShow ? that.$view.style.display = 'block' : that.$view.style.display = 'none';
      setTimeout(callMethod, 50);
    },
  },
  helpers: {

    defineSettings: function (options) {
      if (typeof(options) != 'object') return;

      var map = {};
      map[innerObject.views.body.id] = [
        'width',
        'height'
      ];
      map[innerObject.views.closeBtn.id] = [
        {key: 'buttonLabel', parameter: 'value'},
        {key: 'buttonWidth', parameter: 'width'}
      ];

      _.each(map, function (list, id) {
        var body = $$(id);

        _.each(list, function (key) {
          if (typeof key == "object") {
            if (options[key.key]) {
              body.define(key.parameter, options[key.key]);
            }
          } else {
            if (options[key]) {
              body.define(key, options[key]);
            }
          }
        });
      });

      $$(innerObject.views.main.id).resize();
    }
  },
  settings: {
    popupId: 0,
    confirmId: false
  }
};

var outputObject = {
  show: function (template, data, options, confirmId) {
    innerObject.handlers.show(template, data, options, confirmId);
  }
};


webix.ui({
  view: "popup",
  id: innerObject.views.main.id,
  css: "release_update",
  width: 600,
  borderless: true,
  head: false,
  modal: true,
  hidden: true,
  autoheight: true,
  position: "center",
  _is_popup_shown: false,
  padding: 0,
  margin: 0,
  body: {
    padding: 0,
    margin: 0,
    rows: [
      innerObject.views.body,
      {
        cols: [
          {},
          innerObject.views.closeBtn,
          {}
        ]
      }
    ]
  }
});

export default outputObject;
