// Configuration data for the mass-change actions menu
// import { actionsMenuIds } from './mcActionsMenu';

import app from '../../app';

import massChangeModel from '../gantt/modules/massChange.js';
import projectsModel from '../../models/projects';

import icon_task_color from '../../svg/webix_material/outline_format_color_normal.svg';
import icon_trash from '../../svg/webix_material/trash.svg';

const menuItemsIds = {
  REMOVE: 'idRemoveSelected',
  SET_COLOR: 'idSetColorSelected',
};

const ID_MENU_COLOR_POPUP = 'gridColorboardPopup2';

const ID_ACTION_REMOVE = 5;

const _config = {
  [menuItemsIds.SET_COLOR]: {
    // id: actionsMenuIds.SET_COLOR,
    // disabled: __selectedCount === 0,
    value: __('app_masschange_menu_choose_color'),
    icon: gantt._getSvgIcon('color-backgroud', 'bold', 24),
    hideByClick: false,
    height: 32,
    css: 'context_menu_list_border_bottom context_menu_list_right_arrow',
    onClick(itemId, event, domNode) {
      // console.info('SET_COLOR::onClick', itemId); // !DEBUG
    },
    onHover(itemId, event, domNode) {
      $$(ID_MENU_COLOR_POPUP).show(domNode, {
        pos: 'right',
        x: 0,
        y: -1,
      });
    },
  },
  [menuItemsIds.REMOVE]: {
    // id: REMOVE,
    // disabled: __selectedCount === 0,
    value: __('app_masschange_menu_remove'),
    icon: gantt._getSvgIcon('delete', 'regular', 24),
    height: 32,
    hideByClick: true,
    css: 'context_menu_list_border_bottom',
    onClick(itemId, event, domNode) {
      app.trigger('gantt:keyboard:disable');
      //
      // webix.alert({
      //   ok: __('common_yes'),
      //   cancel: __('common_cancel'),
      //   text: __('app_masschange_confirm_rm_text'),
      //   type: 'confirm-error',
      //   width: 420,
      //   callback: function (result) {
      //     if (result) {
      //       const actions = [{
      //           actionType: ID_ACTION_REMOVE,
      //           actionPayload: {
      //             tasks: massChangeModel.getSelectedList()
      //           }
      //       }];
      //       const __activeGanttId = projectsModel.getActiveGanttId();
      //       massChangeModel.executeActions(actions, __activeGanttId);
      //     }
      //     app.trigger('gantt:keyboard:enable');
      //   }
      // });
      webix.confirm({
        ok: __('common_yes'),
        cancel: __('common_cancel'),
        text: __('app_masschange_confirm_rm_text'),
        type: 'confirm-error',
        width: 420,
      }).then(result => {
        if (result) {
          const actions = [{
            actionType: ID_ACTION_REMOVE,
            actionPayload: {
              tasks: massChangeModel.getSelectedList(),
            },
          }];
          const __activeGanttId = projectsModel.getActiveGanttId();

          massChangeModel.executeActions(actions, __activeGanttId);
        }
        app.trigger('gantt:keyboard:enable');
      });
    },
    onHover(itemId, event, domNode) {
      $$(ID_MENU_COLOR_POPUP).hide();
    },
  },
};

// {Array <Object>} -> {Array <Object>}
const getMenuConfig = items => items.filter(({ id }) => !!_config[id]).map(({ id, disabled }) => ({ ..._config[id], disabled, id }));

export {
  menuItemsIds,
  getMenuConfig,
};
