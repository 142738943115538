import app from '../../app';
import plansModel from '../../models/plans';

const uiItemsAll = {
  comment: true,
  attachment: true,
  timer: true,
  video: true,
  learningCenter: true,
  chat: true,
  demo: !plansModel.isPaidTeam(),
};

const withoutLC = {
  comment: true,
  attachment: true,
  timer: true,
  video: true,
  learningCenter: false,
  chat: true,
  demo: !plansModel.isPaidTeam(),
};

const UIConfigsByRoute = new Map([
  [
    ['project', 'projectsMultiView'],
    uiItemsAll,
  ],
  [
    ['listView', 'userLogs', 'teamAndResources', 'reports', 'workload'],
    withoutLC,
  ],
]);

export default {
  getUI(route) {
    const config = {};

    config.existFooter = app.config.mode.isBase;

    if (!config.existFooter) return config;

    // UIConfigsByRoute.forEach((cfg, routes) => {
    //     if(routes.includes(route.name)) {
    //         Object.assign(config, cfg);
    //     }
    // })

    Object.assign(config, uiItemsAll);

    return config;
  },

  hasRouteConfig(routeName) {
    for (const arr of UIConfigsByRoute.keys()) {
      if (arr.includes(routeName)) {
        return true;
      }
    }

    return false;
  },
};
