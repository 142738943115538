import app from '../app';
import dateFormatsHelper from '../helpers/dateFormats';

let customDays = {};

if (!app.config.mode.isBase) {
  customDays = new webix.DataCollection();

  customDays.parse(GT.ganttData.customDays);
} else {
  customDays = new webix.DataCollection({ // inner dataCollection for manipulation with data
    save: {
      url: 'rest->/api/customday',
      updateFromResponse: true,
    },
  });
}

if (app.debug) {
  GT.models.customDays = customDays;
}

customDays.cacheData = {};
customDays.dataCached = false;
customDays.activeTemplateId = 0;

customDays.initData = function (data, templateId) {
  data = data || [];
  customDays.clearAll();

  if (templateId) {
    data.map(dayDataObject => {
      if (typeof dayDataObject.hours === 'string') {
        dayDataObject.hours = JSON.parse(dayDataObject.hours);
      }
    });

    customDays.parse(data);
    customDays.activeTemplateId = templateId;
  }

  customDays.dataCached = false;
  app.trigger('customDays:init');
};

customDays.getDaysByActiveProject = function () {
  return webix.copy(customDays.serialize());
};

customDays.getSortingDaysByActiveProject = function (ganttId, customDaysArray) {
  const sortingResult = {
    exception: [],
    repeat: [],
  };

  _.each((customDaysArray || customDays.getDaysByActiveProject()), dayData => {
    if (dayData.repeat) {
      sortingResult.repeat.push(dayData);
    } else {
      sortingResult.exception.push(dayData);
    }
  });

  sortingResult.exception.sort((a, b) => {
    if (a.to && !b.to) {
      return -1;
    }

    if (a.to && b.to) {
      if (new Date(a.to).valueOf() - new Date(a.from).valueOf() <= new Date(b.to).valueOf() - new Date(b.from).valueOf()) {
        return 1;
      }

      return 0;
    }

    return 1;
  });

  sortingResult.repeat.sort((a, b) => {
    if (a.repeat !== 'week' && b.reapeat === 'week') {
      return -1;
    }

    if (a.repeat === 'month' && b.repeat === 'year') {
      return -1;
    }

    if (a.repeat === 'week' && b.repeat !== 'week') {
      return 0;
    }

    if (a.repeat === b.repeat) {
      return 0;
    }

    return 1;
  });

  customDays.dataCached = true;
  customDays.cacheData = webix.copy(sortingResult);

  return sortingResult;
};

customDays.getSortingDaysByGanttIdFromCache = function (ganttId) {
  if (customDays.dataCached) {
    return customDays.cacheData;
  }

  return customDays.getSortingDaysByActiveProject(ganttId);
};

customDays.getCustomTypeByDate = function (controlDate, ganttId, monthNumber) {
  const getBasicArray = customDays.getSortingDaysByGanttIdFromCache(ganttId);
  let customDayTo = {};
  let customDayFrom = {};
  let customDayRepeatTo = {};
  const resultData = {};
  let type = '';

  if (controlDate.getMonth() !== monthNumber) {
    resultData.eventType = '';

    return resultData;
  }

  _.some(getBasicArray.repeat, customDayData => {
    customDayFrom = dateFormatsHelper.createDayStartedAt00(customDayData.from);
    customDayRepeatTo = dateFormatsHelper.createDayStartedAt00(customDayData.repeat_to);

    switch (customDayData.repeat) {
    case 'week':
      if (
        (customDayData.repeat_to && customDayRepeatTo.valueOf() >= controlDate.valueOf()
            || !customDayData.repeat_to)
          && controlDate.valueOf() >= customDayFrom.valueOf()
          && customDayFrom.getDay() === controlDate.getDay()) {
        resultData.from = customDayFrom;
        resultData.repeat = true;
        resultData.repeatType = customDayData.repeat;
        resultData.repeat_to = customDayData.repeat_to;
        resultData.id = customDayData.id;
        resultData.title = customDayData.title;
        resultData.hours = customDayData.hours;

        if (customDayData.hours.length) {
          type = 'repeat custom';
          resultData.type = 'custom';
        }

        if (!customDayData.hours.length) {
          type = 'repeat weekend';
          resultData.type = 'weekend';
        }
      }
      break;

    case 'month':
      if ((customDayData.repeat_to && customDayRepeatTo.valueOf() >= controlDate.valueOf()
          || !customDayData.repeat_to)
          && controlDate.valueOf() >= customDayFrom.valueOf()
          && customDayFrom.getDate() === controlDate.getDate()) {
        resultData.from = customDayFrom;
        resultData.repeat = true;
        resultData.repeat_to = customDayData.repeat_to;
        resultData.repeatType = customDayData.repeat;
        resultData.id = customDayData.id;
        resultData.title = customDayData.title;
        resultData.hours = customDayData.hours;
        if (customDayData.hours.length) {
          type = 'repeat custom';
          resultData.type = 'custom';
        }
        if (!customDayData.hours.length) {
          type = 'repeat weekend';
          resultData.type = 'weekend';
        }
      }
      break;

    case 'year':
      if ((customDayData.repeat_to
          && customDayRepeatTo.valueOf() >= controlDate.valueOf()
          || !customDayData.repeat_to)
          && controlDate.valueOf() >= customDayFrom.valueOf()
          && customDayFrom.getMonth() === controlDate.getMonth()
          && customDayFrom.getDate() === controlDate.getDate()) {
        resultData.from = customDayFrom;
        resultData.repeat = true;
        resultData.repeat_to = customDayData.repeat_to;
        resultData.repeatType = customDayData.repeat;
        resultData.id = customDayData.id;
        resultData.title = customDayData.title;
        resultData.hours = customDayData.hours;

        if (customDayData.hours.length) {
          type = 'repeat custom';
          resultData.type = 'custom';
        }
        if (!customDayData.hours.length) {
          type = 'repeat weekend';
          resultData.type = 'weekend';
        }
      }
      break;
    }
  });

  getBasicArray.exception.forEach(customDayData => {
    customDayFrom = dateFormatsHelper.createDayStartedAt00(customDayData.from);
    customDayTo = customDayData.to ? dateFormatsHelper.createDayStartedAt00(customDayData.to) : null;

    if (
      (
        customDayTo
        && controlDate.valueOf() >= customDayFrom.valueOf()
        && controlDate.valueOf() <= customDayTo.valueOf()
      )
      || (
        !customDayTo
        && controlDate.valueOf() === customDayFrom.valueOf()
      )) {
      resultData.repeat = false;
      resultData.from = customDayFrom;
      resultData.to = customDayTo || null;
      resultData.id = customDayData.id;
      resultData.hours = customDayData.hours;
      resultData.title = customDayData.title;
      if (customDayData.hours.length) {
        type = 'exception custom';
        resultData.type = 'custom';
      }
      if (!customDayData.hours.length) {
        type = 'exception weekend';
        resultData.type = 'weekend';
      }
      if (customDayTo) {
        type += ' interval';
        if (controlDate.valueOf() === customDayFrom.valueOf()) {
          type += ' start';
        }
      }

      return true;
    }
  });

  resultData.eventType = type;

  return resultData;
};

customDays.updateCustomDayById = function (customDayId, propertyObject) {
  customDays.updateItem(customDayId, propertyObject);
  customDays.dataCached = false;
};

customDays.addNewItem = function (customDayData, ganttId) {
  customDayData.template_id = customDays.activeTemplateId;
  customDays.add(customDayData);
  customDays.dataCached = false;
  app.trigger('customDays:insert');
};

customDays.addNewItemCollaboration = function (customDayData, ganttId) {
  customDayData.template_id = customDays.activeTemplateId;
  customDays.data.blockEvent();
  if (!customDays.data.pull[customDayData.id]) {
    customDays.add(customDayData);
  }

  customDays.data.unblockEvent();
  customDays.dataCached = false;
  app.trigger('customDays:insert');
};

customDays.prepareDataAfterSync = function (customDayData) {
  customDays.data.pull[customDayData.id].hours = JSON.parse(customDayData.hours);
  customDays.data.pull[customDayData.id].template_id = parseInt(customDayData.template_id, 10);
  customDays.cacheData = {};
  customDays.getSortingDaysByActiveProject();
};

customDays.getNexRepeatableDay = function (controlDate, typeRepeat) {
  const foundDay = new Date(controlDate);

  switch (typeRepeat) {
  case 'week':
    foundDay.setDate(controlDate.getDate() + 7);
    break;
  case 'month':
    foundDay.setMonth(controlDate.getMonth() + 1);
    break;
  case 'year':
    foundDay.setFullYear(controlDate.getFullYear() + 1);
    break;
  }

  return foundDay;
};

customDays.getAllDaysForRepeatOnInterval = function (intervalFrom, intervalTo, controlDate, typeRepeat) {
  const stepDay = new Date(controlDate);
  const meetDays = [];

  switch (typeRepeat) {
  case 'week':
    while (stepDay.valueOf() <= intervalTo.valueOf()) {
      if (stepDay.getDay() === controlDate.getDay()) {
        meetDays.push(new Date(stepDay));
      }
      stepDay.setDate(stepDay.getDate() + 1);
    }

    break;

  case 'month':
    stepDay.setDate(controlDate.getDate());

    while (stepDay.valueOf() <= intervalTo.valueOf()) {
      if (stepDay.getDate() === controlDate.getDate()) {
        meetDays.push(new Date(stepDay));
      }
      stepDay.setMonth(stepDay.getMonth() + 1);
    }

    break;

  case 'year':
    stepDay.setDate(controlDate.getDate());
    stepDay.setMonth(controlDate.getMonth());

    while (stepDay.valueOf() <= intervalTo.valueOf()) {
      if (stepDay.getDate() === controlDate.getDate()) {
        meetDays.push(new Date(stepDay));
      }
      stepDay.setFullYear(stepDay.getFullYear() + 1);
    }

    break;
  }

  return meetDays;
};

customDays.deleteCustomDay = function (customDayId, ganttId) {
  customDays.remove(customDayId);
  customDays.cacheData = {};
  customDays.dataCached = false;
  app.trigger('updateCalendarMonths')
};

customDays.deleteCustomDayCollaboration = function (customDayId, ganttId) {
  customDays.data.blockEvent();

  if (customDays.exists(customDayId)) {
    customDays.remove(customDayId);
  }
  customDays.data.unblockEvent();
  customDays.cacheData = {};
  customDays.dataCached = false;
  app.trigger('customDays:delete');
};

webix.dp(customDays).attachEvent('onAfterSync', (obj, dataRaw, data) => {
  const customDay = {};

  customDays.dataCached = false;

  if (obj.status === 'insert' && data) {
    customDays.prepareDataAfterSync(data);
    app.trigger('customDays:insert');
  }

  if (obj.status === 'update' && data) {
    customDays.prepareDataAfterSync(data);
    app.trigger('customDays:update');
  }

  if (obj.status === 'delete' && data) {
    app.trigger('customDays:delete');
  }
});

export default customDays;
