import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const AttachmentsStore = {
  namespaced: true,
  state: {
    projectAttachmentsMap: {},
    googleDriveData: {
      user: null,
      googlePickerApiLoaded: false,
      googleAuthApiLoaded: false,
    },
    oneDriveData: {
      user: null,
      microsoftPickerApiLoaded: false,
      microsoftAuthApiLoaded: false,
    },
    isFetching: false,
    isUploading: false,
  },
  getters,
  mutations,
  actions,
};

export default AttachmentsStore;
