/* eslint-disable no-var */
/* eslint-disable import/no-mutable-exports */
import app from '../app';
import moment from '../libs/moment';

const dateFormats = [
  { id: 'DD.MM.YYYY', value: '26.01.1993 | DD.MM.YYYY' },
  { id: 'DD.MM.YY', value: '26.01.93 | DD.MM.YY' },
  { id: 'DD-MM-YYYY', value: '26-01-1993 | DD-MM-YYYY' },
  { id: 'DD-MM-YY', value: '26-01-93 | DD-MM-YY' },
  { id: 'DD/MM/YYYY', value: '26/01/1993 | DD/MM/YYYY' },
  { id: 'DD/MM/YY', value: '26/01/93 | DD/MM/YY' },

  { id: 'MM.DD.YYYY', value: '01.26.1993 | MM.DD.YYYY' },
  { id: 'MM.DD.YY', value: '01.26.93 | MM.DD.YY' },
  { id: 'MM-DD-YYYY', value: '01-26-1993 | MM-DD-YYYY' },
  { id: 'MM-DD-YY', value: '01-26-93 | MM-DD-YY' },
  { id: 'MM/DD/YYYY', value: '01/26/1993 | MM/DD/YYYY' },
  { id: 'MM/DD/YY', value: '01/26/93 | MM/DD/YY' },

  { id: 'YYYY.DD.MM', value: '1993.26.01 | YYYY.DD.MM' },
  { id: 'YY.DD.MM', value: '93.26.01| YY.DD.MM' },
  { id: 'YYYY-DD-MM', value: '1993-26-01 | YYYY-DD-MM' },
  { id: 'YY-DD-MM', value: '93-26-01 | YY-DD-MM' },
  { id: 'YYYY/DD/MM', value: '1993/26/01 | YYYY/DD/MM' },
  { id: 'YY/DD/MM', value: '93/26/01 | YY/DD/MM' },

  { id: 'YYYY.MM.DD', value: '1993.01.26 | YYYY.MM.DD' },
  { id: 'YY.MM.DD', value: '93.01.26 | YY.MM.DD' },
  { id: 'YYYY-MM-DD', value: '1993-01-26 | YYYY-MM-DD' },
  { id: 'YY-MM-DD', value: '93-01-26 | YY-MM-DD' },
  { id: 'YYYY/MM/DD', value: '1993/01/26 | YYYY/MM/DD' },
  { id: 'YY/MM/DD', value: '93/01/26 | YY/MM/DD' },
];

// const hours24 = [
//   { id: '0', value: '0:00' },
//   { id: 1, value: '1:00' },
//   { id: 2, value: '2:00' },
//   { id: 3, value: '3:00' },
//   { id: 4, value: '4:00' },
//   { id: 5, value: '5:00' },
//   { id: 6, value: '6:00' },
//   { id: 7, value: '7:00' },
//   { id: 8, value: '8:00' },
//   { id: 9, value: '9:00' },
//   { id: 10, value: '10:00' },
//   { id: 11, value: '11:00' },
//   { id: 12, value: '12:00' },
//   { id: 13, value: '13:00' },
//   { id: 14, value: '14:00' },
//   { id: 15, value: '15:00' },
//   { id: 16, value: '16:00' },
//   { id: 17, value: '17:00' },
//   { id: 18, value: '18:00' },
//   { id: 19, value: '19:00' },
//   { id: 20, value: '20:00' },
//   { id: 21, value: '21:00' },
//   { id: 22, value: '22:00' },
//   { id: 23, value: '23:00' },
// ];

// const hoursAM = [
//   { id: '0', value: '12 AM' },
//   { id: 1, value: '1 AM' },
//   { id: 2, value: '2 AM' },
//   { id: 3, value: '3 AM' },
//   { id: 4, value: '4 AM' },
//   { id: 5, value: '5 AM' },
//   { id: 6, value: '6 AM' },
//   { id: 7, value: '7 AM' },
//   { id: 8, value: '8 AM' },
//   { id: 9, value: '9 AM' },
//   { id: 10, value: '10 AM' },
//   { id: 11, value: '11 AM' },
//   { id: 12, value: '12 PM' },
//   { id: 13, value: '1 PM' },
//   { id: 14, value: '2 PM' },
//   { id: 15, value: '3 PM' },
//   { id: 16, value: '4 PM' },
//   { id: 17, value: '5 PM' },
//   { id: 18, value: '6 PM' },
//   { id: 19, value: '7 PM' },
//   { id: 20, value: '8 PM' },
//   { id: 21, value: '9 PM' },
//   { id: 22, value: '10 PM' },
//   { id: 23, value: '11 PM' },
// ];

const locale = {
  minute: __('minute_in_time_string'),
  hour: __('hour_in_time_string'),
};

const AVAILABLE_DELIMITERS = ['.', '/', '-'];

var innerObject = {
  dateFormatVariants: dateFormats,
  getUserDateFormat() {
    return innerObject.settings.userFormat;
  },
  getCurrentDateDelimiter() {
    let currentDelimiter = '';

    AVAILABLE_DELIMITERS.some(delimiter => {
      const splittedUserFormat = this.getUserDateFormat().split(delimiter);

      if (splittedUserFormat.length > 1) {
        currentDelimiter = delimiter;

        return true;
      }

      return false;
    });

    return currentDelimiter;
  },
  isCurrentDateFormatUSA() {
    return this.getUserDateFormat().indexOf('MM') === 0;
  },
  isCurrentDateFormatHas2DigitYear() {
    return !this.getUserDateFormat().includes('YYYY') && this.getUserDateFormat().includes('YY');
  },
  getDateFormat() {
    let currentDelimiter = '';
    let formats = [];
    let dateFormat = '';
    const dateFormatConvector = {
      DD: '%d',
      MM: '%m',
      YY: '%y',
      YYYY: '%Y',
    };

    _.some(AVAILABLE_DELIMITERS, delimiter => {
      formats = innerObject.settings.userFormat.split(delimiter);

      if (formats.length > 1) {
        currentDelimiter = delimiter;

        return true;
      }
    });

    _.each(formats, (formatPart, index) => {
      if (dateFormatConvector[formatPart]) {
        dateFormat += dateFormatConvector[formatPart];
      } else {
        dateFormat += formatPart;
      }

      if (index < 2) {
        dateFormat += currentDelimiter;
      } else {
        dateFormat += ' ';
      }
    });

    return dateFormat;
  },
  checkIfDateInLimit(date, noTime, customLimits) {
    if (_.isDate(date)) {
      let value = new Date(date);

      let minDate = new Date();
      let maxDate = new Date();

      const dateFrom = minDate.getFullYear() - 50;

      minDate.setFullYear(dateFrom);
      minDate.setHours(date.getHours(), date.getMinutes(), 0, 0);

      const dateTo = maxDate.getFullYear() + 50;

      maxDate.setFullYear(dateTo);
      maxDate.setHours(date.getHours(), date.getMinutes(), 0, 0);

      if (customLimits) {
        if (customLimits.from) {
          minDate = new Date(customLimits.from, 0, 1);
        }
        if (customLimits.to) {
          maxDate = new Date(customLimits.to, 0, 1);
        }
      }

      if (date.getTime() < minDate.getTime()) {
        value = minDate;
      }

      if (date.getTime() > maxDate.getTime()) {
        value = maxDate;
      }

      if (noTime) {
        value.setHours(0, 0, 0, 0);
      }

      return value;
    }

    return date;
  },
  convertGanttDateFormat(isTime) {
    let dateFormat = this.settings.userFormat;
    const isEnable24 = this.settings.enable24;

    if (isTime) {
      if (isEnable24) {
        dateFormat += ' HH:mm';
      } else {
        dateFormat += ' hh:mm A';
      }
    }

    return dateFormat;
  },
  getDateFormatForGanttGrid(onlyTime) {
    let dateFormat = onlyTime ? '' : innerObject.getDateFormat();
    const hasTimeInDate = dateFormat.indexOf('%H:%i') !== -1
      || dateFormat.indexOf('%h:%i%A') !== -1;

    if (!hasTimeInDate) {
      if (!innerObject.settings.enable24) {
        dateFormat += '%h:%i%A';
      } else {
        dateFormat += '%H:%i';
      }
    }

    return dateFormat;
  },
  setUserDateFormat(newFormat) {
    user.dateFormat = newFormat;
    innerObject.settings.userFormat = newFormat;
    innerObject.updateWebixDateFormat();
  },
  setUserTimeFormat(enable24) {
    user.timeFormat = enable24;
    innerObject.settings.enable24 = enable24;
    innerObject.updateWebixTimeFormat();
  },
  setMomentLocale() {
    moment.locale(user.locale);
  },
  getUserTimeFormat() {
    return innerObject.settings.enable24;
  },
  setCustomLocale() {
    webix.i18n.calendar.hours = __('history_Hours');
    webix.i18n.calendar.minutes = __('common_minutes');
    webix.i18n.calendar.done = __('calendar_done');
    webix.i18n.calendar.today = __('datepiker_today');
    webix.i18n.calendar.clear = __('common_clear');
    webix.i18n.calendar.monthFull = __('calendar_months_full').split(',').map(string => string.trim());
    webix.i18n.calendar.monthShort = __('calendar_months_short').split(',').map(string => string.trim());
    webix.i18n.calendar.dayFull = __('calendar_days_full').split(',').map(string => string.trim());
    webix.i18n.calendar.dayShort = __('calendar_days_short').split(',').map(string => string.trim());
  },
  updateWebixDateFormat() {
    webix.i18n.locales = {};
    webix.i18n.locales.GT = {};
    webix.i18n.locales.GT.fullDateFormat = innerObject.getDateFormat() + (innerObject.settings.enable24 ? '%H:%i' : '%h:%i %A');
    webix.i18n.locales.GT.dateFormat = innerObject.getDateFormat();
    webix.i18n.setLocale('GT');

    innerObject.setCustomLocale();
  },
  updateWebixTimeFormat() {
    // const GT = GT.locales.supported[user.locale].code;

    webix.i18n.locales.GT.timeFormat = '%H:%i';// %A";
    webix.i18n.locales.GT.timeFormat = innerObject.settings.enable24 ? '%H:%i' : '%h:%i %A';
    innerObject.updateWebixDateFormat();
  },
  getHoursArray(interval = true, resource = false) {
    const hoursArray = [];

    if (interval) {
      const startMinute = 0;
      const endMinute = 24.5 * 60;

      for (let currentMinute = startMinute; currentMinute < endMinute; currentMinute += 30) {
        const currentHour = Math.floor(currentMinute / 60);
        const minutePart = currentMinute % 60;
        const period = currentHour >= 12 ? 'PM' : 'AM';

        const id = `${currentHour}:${minutePart.toString().padStart(2, '0')}`;
        const value = `${currentHour === 0 ? 12 : currentHour > 12 ? currentHour - 12 : currentHour}:${minutePart.toString().padStart(2, '0')}${period}`;

        hoursArray.push({ id, value });
      }
    } else if (resource) {
      for (let i = 1; i < 49; i++) {
        const hours = Math.floor(i / 2);
        const minutes = i % 2 === 0 ? '00' : '30';
        let time = '';

        time = `${hours.toString()}${locale.hour} ${minutes === '30' ? minutes : ''}${minutes === '30' ? locale.minute : ''}`;

        const id = i / 2 === 0 ? '0' : i / 2;

        hoursArray.push({ id, value: time.trim() });
      }
    } else {
      for (let i = 0; i < 49; i++) {
        const hours = Math.floor(i / 2);
        const minutes = i % 2 === 0 ? '00' : '30';
        let time = '';

        time = `${hours.toString()}${locale.hour} ${minutes === '30' ? minutes : ''}${minutes === '30' ? locale.minute : ''}`;

        const id = i / 2 === 0 ? '0' : i / 2;

        hoursArray.push({ id, value: time.trim() });
      }
    }

    return hoursArray;
  },
  getHoursArrayByUserTimeFormat(interval, resource) {
    return innerObject.getHoursArray(interval, resource);
  },
  createDayStartedAt00(date) {
    const newDate = innerObject.createNewDateByStringOrDateObject(date);

    return gantt.date.day_start(newDate);
  },
  createNewDateByStringOrDateObject(date) {
    let newDate = {};

    if (typeof date === 'string') {
      newDate = date ? innerObject.settings.strToDate(date) : new Date();
    } else {
      newDate = moment(date).toDate();
    }

    return newDate;
  },
  setStringTimeToDateObject(date, stringTime) {
    if (!stringTime.trim() || !this.isValidDate(date)) {
      return false;
    }

    const timeFormatter = gantt.date.str_to_date('%H:%i:$s');
    const timeValidated = timeFormatter(stringTime);

    if (this.isValidDate(timeValidated)) {
      date.setHours(timeValidated.getHours());
      date.setMinutes(timeValidated.getMinutes());
      date.setSeconds(timeValidated.getSeconds());

      return date;
    }

    return false;
  },
  isValidStringTime(stringTime) {
    if (!stringTime) {
      return false;
    }

    const timeFormatter = gantt.date.str_to_date('%H:%i:$s');
    const timeValidated = timeFormatter(stringTime);

    return this.isValidDate(timeValidated);
  },
  isValidDate(date) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      return !isNaN(date.getTime());
    }

    return false;
  },

  formatDate(date) {
    return webix.i18n.fullDateFormatStr(moment(date).toDate());
  },

  formatDateNoTime(date) {
    return webix.i18n.dateFormatStr(moment(date).toDate());
  },
  settings: {
    userFormat: '',
    strToDate: gantt.date.str_to_date('%Y-%m-%d %H:%i:$s'),
    enable24: false,
  },
};

app.on('app:start', () => {
  innerObject.setUserDateFormat(user.dateFormat);
  innerObject.setUserTimeFormat(user.timeFormat);
  innerObject.setMomentLocale();
});

app.on('setUserDateFormat', (dateFormat, timeFormat) => {
  if (dateFormat) {
    user.dateFormat = dateFormat;
    innerObject.settings.userFormat = dateFormat;
  }

  if (timeFormat) {
    user.timeFormat = timeFormat;
    innerObject.settings.enable24 = timeFormat;
  }
});

export default innerObject;
