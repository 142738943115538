var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "password-strength-indicator__wrapper",
      style: { color: _vm.color },
    },
    [
      _c("svg-sprite", {
        attrs: { name: _vm.icon, color: _vm.color, type: "bold", size: 16 },
      }),
      _vm._v("\n  " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }