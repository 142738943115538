<template>
  <div :class="$style['gallery__wrapper']">
    <div
      v-for="item in items"
      :key="item.id"
      :class="[$style['card'], 'vgp-interactive-element']"
      @click.stop="$emit('view', item)"
    >
      <svg-sprite
        v-if="item.attachmentType === attachmentTypeKeys.GOOGLE_DRIVE"
        :class="$style['card__google-drive-icon']"
        name="google-drive"
        type="color-regular"
      />
      <svg-sprite
        v-if="item.attachmentType === attachmentTypeKeys.ONE_DRIVE"
        :class="$style['card__one-drive-icon']"
        name="one-drive"
        type="color-regular"
      />
      <div :class="$style['card__buttons']">
        <vgp-icon-button
          v-if="!disabledDeletion"
          :icon="{ name: 'delete' }"
          @onClick.stop="$emit('delete', item)"
        />
        <vgp-icon-button
          v-if="!item.downloadUrl"
          :icon="{ name: 'link-2' }"
          @onClick.stop="$emit('view', item)"
        />
        <vgp-icon-button
          v-else
          :icon="{ name: 'download' }"
          @onClick.stop="$emit('download', item)"
        />
      </div>
      <div :class="$style['card__image-wrapper']">
        <img
          :src="item.thumbnailLink"
          alt="Attachment image"
          :class="$style['card__image']"
          @error="$event.target.src = defaultImagePlaceholder"
        >
      </div>
      <div :class="$style['card__info-wrapper']">
        <div :class="$style['card__name']">
          {{ item.name }}
        </div>
        <div :class="$style['card__inner-wrapper']">
          <span :class="$style['card__size']">{{ item.size }}</span>
          <span :class="$style['card__date']">{{ item.uploadDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import constants from '$$helpers/constants';

export default {
  name: 'GalleryView',
  components: { VgpIconButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    disabledDeletion: {
      type: Boolean,
      default: false,
    },
    defaultImagePlaceholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachmentTypeKeys: constants.ATTACHMENT_TYPE_KEYS,
    };
  },
};
</script>

<style module lang="less">

.gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1.26724px solid #E5E5E5;
  border-radius: 5.06897px;
  min-height: 146px;
  width: 112px;

  &:hover {

    cursor: pointer;

  .card__one-drive-icon,
  .card__google-drive-icon {
      opacity: 0;
    }

    .card__buttons {
      opacity: 1;
    }

    .card__image {
      visibility: hidden;
      opacity: 0;
    }

  }

    &__one-drive-icon,
    &__google-drive-icon {
    z-index: 2;
    background: #ffffff;
    position: absolute;
    left: 4px;
    top: 4px;
  }

  &__buttons {
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 2;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 74px;
  }

  &__image {
    transition: all .2s ease-in-out;
    cursor: pointer;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: auto;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4px 8px 8px 8px;
    gap: 16px;
  }

  &__name {
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #191919;
    height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__inner-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__size, &__date {
    font-family: Lato-Regular, sans-serif;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
  }
}

</style>
