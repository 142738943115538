import app from '../../../../app';

const blockedEvents = [
  'TaskResourceUnassigned',
  'TaskResourceAssigned',
  'TaskResourceUpdated',
  'TaskDeleted',
  'TaskCreated',
  'TaskUpdated',
  'TaskCustomFieldUpdated',
  'TaskTimeLogsCreated',
  'TaskTimeLogDeleted',
  'TaskTimeLogUpdated',
  'TaskCommentCreated',
  'TaskCommentDeleted',
  'TaskAttachementCreated',
  'TaskAttachementDeleted',
  'TaskLinkDeleted',
  'TaskLinkCreated',

  'CustomFieldToProjectAssigned',
  'CustomFieldFromProjectUnassigned',
  'CustomFieldUpdated',
  'CustomFieldCreated',
  'CustomFieldDeleted',

  'ResourceToProjectsAssigned',
  'ResourceFromProjectsUnassigned',
  'ResourceDeleted',
  'ResourceCreated',
  'ResourceUpdated',
  'ResourceOnProjectsUpdated',
  'ResourceRoleUpdated',
  'ResourcesOnProjectsConverted',

  'ProjectCalendarUpdated',
  'ProjectArchived',
  'ProjectConfigUpdated'
];

function activateBlockListheners(activateCollback) {
  gantt.attachEvent('openedInlineEditor', (task_id, column_index) => activateHandler('edit', task_id, column_index));
  // gantt.attachEvent('setFocusToCreateTaskField', () => { activateHandler('create'); });
  app.on('create:ganttTask:focus', () => { activateHandler('create'); });

  function activateHandler(mode, task_id, column_index) {
    const collbackParams = { mode, type: 'gantt', events: blockedEvents };

    if (mode === 'edit') {
      collbackParams.task_id = task_id;
      const ganttColumn = gantt.getGridColumns()[column_index];

      collbackParams.column_id = ganttColumn.name;

      if (ganttColumn.isCustomColumn) {
        collbackParams.customColumn = true;
      }

      if (ganttColumn.isUserCustomColumn) {
        collbackParams.userCustomColumn = true;
        collbackParams.column_id = +ganttColumn.name;
      }
    }

    activateCollback(collbackParams);
  }
}

function deactivateBlockListheners(deactivateCollback) {
  gantt.attachEvent('closedInlineEditor', deactivateCollback);
}

export default {
  activateBlockListheners,
  deactivateBlockListheners,
};
