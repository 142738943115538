/* eslint-disable */
import app, { appIds } from '../../app';
import _ from '../../libs/lodash';

import ganttViewModel from '../../models/ganttViewModel';
import projectsModel from '../../models/projects';
import filtersModel from '../../models/filters';
import userProjectConfigModel from '../../models/userProjectConfigs';
import mppModel from '../../models/mpp';

import helperDate from '../../helpers/dateFormats';
import bindStatusAndProgressHelper from '../../helpers/bindStatusAndProgress';
import ganttFilterHelper from '../../helpers/gantt_filter';
import routerHelper from '../../helpers/router';
import timeParser from '../../helpers/timeParser';
import customHelper from '../../helpers/custom';

import modulesConfig from './modeConfigs/index';

import moduleGridCustomButtons from './modules/gridCustomButtons';
import moduleWorkDays from './modules/workDays';
import moduleCustomGrids from './modules/customGrids';
import moduleTextAlign from './modules/textAlign';
import moduleDisableGantt from './modules/disableGantt';
import actionButtonsModule from './modules/gridActionButtons';
import autoSchedulingPatchesModule from './modules/autoSchedulingPatches';
import moduleWorkload from './modules/workload';
import moduleGanttWorker from './modules/ganttWorker';
import moduleWbsWorker from './modules/wbsWorker';
import moduleDnD from './modules/dragndrop';

import deadline_icon from '../../svg/deadline/deadline_flame.svg';
// import userNotificationsModel from '../../models/userNotifications';

import collaborationComponent from '../../components/Collaboration';
import historyModel from '../../models/history';
import massChangeModel from './modules/massChange';
import constants from '../../helpers/constants';
import moment from '../../libs/moment';
import globalStore from '../../store/main';
import customDayTemplatesModel from '../../models/customDayTemplates';
import {checkPricingAccess} from "../../helpers/pricingHelper";

import rights from '../../components/rights';
import { set } from 'lodash';

const __ = window.__;

let lastUpdatedTasks = [];

const {
  events: { ID_EVENT_TASKS_MASSDELETE, ID_EVENT_TASKS_MASSUPDATE },
} = appIds;

const expanded = gantt._getSvgIcon('minus-2', 'regular', 16);
const collapsed = gantt._getSvgIcon('plus-3', 'regular', 16);

gantt.attachEvent('onGanttRender', _.debounce(() => {
  gantt.callEvent('onAfterGanttRender');
}, 100));

gantt.attachEvent('onAfterGanttRender', (task, colName, isEditorOpened) => ganttHandlers.onAfterGanttRender());

gantt.attachEvent('onBeforeAutoSchedule', taskID => ganttHandlers.onBeforeAutoSchedule(taskID));

gantt.attachEvent('onAutoScheduleCircularLink', (cycles, tasks) => ganttHandlers.onAutoScheduleCircularLink(cycles, tasks));

gantt.attachEvent('onBeforeTaskRowClick', (task, colName, isEditorOpened) => ganttHandlers.onBeforeTaskRowClick(task, colName, isEditorOpened));

gantt.attachEvent('onBeforeTaskClick', taskId => ganttHandlers.onBeforeTaskClick(taskId));

gantt.attachEvent('onBeforeTaskAdd', (id, task) => ganttHandlers.onBeforeTaskAdd(id, task));

gantt.attachEvent('onTaskCommentsClick', id => ganttHandlers.onTaskCommentsClick(id));

gantt.attachEvent('onTaskAttachmentsClick', id => ganttHandlers.onTaskAttachmentsClick(id));

gantt.attachEvent('onTaskEditClick', id => {
  userExtAnalytics.log('gantt_task_settings_open', {
    from: routerHelper.analyticsFromRoute(),
    route: `${routerHelper.analyticsRoute()}/FastButton`,
  });


  // if(localStorage.getItem('GanttProHotjarTaskSettingOpenRecorded') !== "1") {
  //   window.userExtAnalytics.initHotJarAndStartRecording();
  //   localStorage.setItem('GanttProHotjarTaskSettingOpenRecorded', '1');
  // }

  return ganttHandlers.onTaskEditClick(id);
});

gantt.attachEvent('onTaskDblClick', id => {
  userExtAnalytics.log('gantt_task_settings_open', {
    from: routerHelper.analyticsFromRoute(),
    route: `${routerHelper.analyticsRoute()}/DoubleClick Chart`,
  });

  return ganttHandlers.onTaskEditClick(id);
});

gantt.attachEvent('onShowMoreTooltipClick', id => ganttHandlers.onShowMoreTooltipClick(id));

gantt.attachEvent('onTaskDeleteClick', id => {
  // return ganttHandlers.deleteTask(id);
  actionButtonsModule.handlers.removeTaskById(id);
});

gantt.attachEvent('onAfterTaskAdd', (id, task) => ganttHandlers.onAfterTaskAdd(task.gantt_id, webix.copy(task)));

gantt.attachEvent('onBeforeTaskDelete', (id, task) => ganttHandlers.onBeforeTaskDelete(id, task));

gantt.attachEvent('onAfterTaskDelete', (id, task) => ganttHandlers.onAfterTaskDelete(id, task));

gantt.attachEvent('onBeforeLinkAdd', (id, link) => ganttHandlers.onBeforeLinkAdd(id, link));

gantt.attachEvent('onTaskDrag', (id, mode, task, original, e) => ganttHandlers.onTaskDrag(id, mode, task, original, e));

gantt.attachEvent('onAfterLinkDelete', (id, link) => ganttHandlers.onAfterLinkDelete(link));

gantt.attachEvent('onTaskOpened', id => ganttHandlers.onTaskOpened(id));

gantt.attachEvent('onTaskClosed', id => ganttHandlers.onTaskClosed(id));

gantt.attachEvent('onLinkDblClick', id => ganttHandlers.onLinkDblClick(id));

gantt.attachEvent('silentUpdate', (taskId, task) => ganttHandlers.silentUpdate(taskId, task));

gantt.attachEvent('onBeforeTaskUpdate', (taskId, task) => ganttHandlers.onBeforeTaskUpdate(taskId, task));

gantt.attachEvent('changeStatus', taskData => {
  let type = '';
  const id = +taskData.status;

  switch (id) {
  case 1: type = 'open'; break;
  case 2: type = 'in progress'; break;
  case 3: type = 'done'; break;
  case 4: type = 'closed'; break;
  default: type = taskData.status;
  }
  userExtAnalytics.log('task_status_changed', { type });
});

gantt.attachEvent('onAfterTaskUpdate', (id, item) => {
  // const callbackWithNotAppliedChanges = () => {
  //   ganttHandlers.onAfterTaskUpdate(id, item);
  // };
  //
  // const callbackWithoutNotAppliedChanges = () => {
  //   if (gantt.config.needUpdateResourcesFromModel) {
  //     const taskFromModel = globalStore.getters['tasksModel/getTaskByGanttId'](item.gantt_id, item.id);
  //     gantt.config.needUpdateResourcesFromModel = false;
  //     item.resources = taskFromModel.resources;
  //   }
  //
  //   ganttHandlers.onAfterTaskUpdate(id, item);
  // };

  // gantt.afterCollaborationChanges(callbackWithNotAppliedChanges, callbackWithoutNotAppliedChanges);
  if (gantt.config.needUpdateResourcesFromModel) {
    const taskFromModel = globalStore.getters['tasksModel/getTaskByGanttId'](item.gantt_id, item.id);

    gantt.config.needUpdateResourcesFromModel = false;
    item.resources = taskFromModel.resources;
  }

  ganttHandlers.onAfterTaskUpdate(id, item);

  return true;
});

gantt.attachEvent('onAfterAutoSchedule', t => ganttHandlers.onAfterAutoSchedule(t));

gantt.attachEvent('onAfterProjectMove', (id, actionHash) => ganttHandlers.onAfterProjectMove(id, actionHash));

gantt.attachEvent('onAfterLinkUpdate', () => ganttHandlers.onAfterLinkUpdate());
gantt.attachEvent('onAfterLinkAdd', () => ganttHandlers.onAfterLinkAdd());

gantt.attachEvent('onLinkIdChange', () => ganttHandlers.onLinkIdChange());

gantt.attachEvent('onTaskIdChange', (id, newId) => {
  if (ganttSettings.filterData.active && ganttSettings.filterData.foundTasks.length) {
    ganttSettings.filterData.foundTasks = _.filter(ganttSettings.filterData.foundTasks, taskId => taskId !== id);
    ganttSettings.filterData.foundTasks.push(newId);

    if (gantt.config.highlight_critical_path) {
      gantt.render();
    }
  }

  gantt.showTask(newId);// for horizontal scrolling
  _.delay(() => {
    gantt.getTask(newId)?.openEditAfterCreate && gantt.showTaskEditors(
      newId,
      _.findIndex(gantt.getGridColumns(), c => c.name === 'text'),
    );
  });
});

gantt.attachEvent('ganttRender', callback => ganttHandlers.ganttRender(callback));

gantt.attachEvent('afterTasksUpdated', (masterType, cloneArray) => ganttHandlers.afterTasksUpdated(masterType, cloneArray));

gantt.attachEvent('onBeforeWorkloadTaskUpdate', () => {
  lastUpdatedTasks = [];
});

gantt.attachEvent('updateGanttEstimationConfig', ganttId => {
  const projectConfig = projectsModel.getProjectConfig(ganttId);

  if (!checkPricingAccess('workload')) {
    gantt.config.estimation_mode = '2';
    gantt.config.apply_resource_calendar = false;

    return;
  }
  if (projectConfig) {
    gantt.config.estimation_mode = projectConfig.estimation_mode;
    gantt.config.apply_resource_calendar = projectConfig.apply_resource_calendar;
  }
});

gantt.addProjectCalendar = (durationData, ganttId) => {
  if (_.isEmpty(durationData.showDay)) {
    durationData.showDay = [1, 2, 3, 4, 5];
  }
  if (_.isEmpty(durationData.showTime)) {
    durationData.showTime = ['9:00-13:00', '14:00-18:00'];
  }

  const days = _.map(new Array(7), (value, key) => {
    const map = {
      0: 7,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
    };

    return +_.includes(durationData.showDay, map[key]);
  });

  gantt.addCalendar({
    id: ganttId,
    worktime: {
      hours: durationData.showTime,
      days,
    },
  });

  const customDaysData = customDayTemplatesModel.serialize().find(data => +data.ganttId === ganttId);
  let customDays = customDaysData ? customDaysData.customDays : [];
  const projectTotalEstimate = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](ganttId);
  const projectCalendar = gantt.getCalendar(ganttId);


  customDays = Array.isArray(customDays) ? customDays : [customDays];

  gantt._initCustomWorkTime(customDays, projectTotalEstimate, projectCalendar);
};

var ganttSettings = {
  ganttId: null,
  dateFormat: '%Y-%m-%d %H:%i:%s',
  grid_width: 600,
  touchEnabel: true,
  stableGridWidth: false,
  enableMarkers: true,
  roundDndDates: true,
  detailsOnCreate: false,
  addNewProject: false,
  taskDataBeforeUpdate: {},
  progressCalc: 0,
  criticalPath: false,
  autoScheduling: false,
  overdueHighlight: false,
  durationView: '',
  viewMode: '',
  ganttSkin: '',

  filterData: {
    foundTasks: [],
    directEnrty: 0,
    active: false,
  },
  initOptions: {},
  currentIdTasksLayout: [],
};

const ganttHelpers = {
  setAutoZoom: _.once(() => {
    const projectLength = moment(gantt.config.end_date).diff(gantt.config.start_date, 'days');
    let scaleUnit;

    if (projectLength > 23 * 7 * 4 * 12) {
      scaleUnit = 'year';
    } else if (projectLength > 23 * 7 * 4) {
      scaleUnit = 'quarter';
    } else if (projectLength > 23 * 7) {
      scaleUnit = 'month';
    } else if (projectLength > 23) {
      scaleUnit = 'week';
    }

    if (scaleUnit) {
      gantt.config.durationData.mode = scaleUnit;
      moduleWorkDays.init.reinit(gantt.config.durationData);
    }
  }),
  coloredClass(start, end, task) {
    const isWorkload = routerHelper.isWorkloadViewRoute();
    const resClassFunc = function (start, end, task) {
      const isMultiview = gantt.config.multiview;
      const addProjectTaskLevel = isMultiview ? 2 : 1;

      if (isMultiview) {
        if (task.parent === 1 && task.type === gantt.config.types.project) {
          if (!task.color) {
            task.color = constants.DEFAULT_TYPE_COLORS.ESTIMATION;
          }

          return 'task_level_project';
        }
      }

      if (!task.parent && task.type === gantt.config.types.project && gantt.calculateTaskLevel(task.id) === 0) {
        if (!task.color) {
          task.color = constants.DEFAULT_TYPE_COLORS.ESTIMATION;
          // gantt.updateTask(task.id, task);
        }

        return 'project_estimateTotal';
      }

      if (task.$virtual && task.type === gantt.config.types.project) {
        return 'top_level_project task_grouping';
      }

      if (task.type === gantt.config.types.project && gantt.calculateTaskLevel(task.id) === addProjectTaskLevel) {
        if (!task.color) {
          task.color = constants.DEFAULT_TYPE_COLORS.PROJECT;
        }

        return 'top_level_project';
      }

      if (task.type === gantt.config.types.button) {
        return 'gantt_btn_hide';
      }

      const taskNode = gantt.getTaskNode(task.id);

      if (taskNode && parseInt(taskNode.style.width, 10) < 40) {
        if (gantt.hasChild(task.id)) {
          return 'gantt_progress_drag_hide task_level_project';
        }

        return 'gantt_progress_drag_hide';
      }

      if (gantt.isSelectedTask(task.id)) {
        return 'gantt_selected';
      }

      if (gantt.hasChild(task.id)) {
        if (!task.color) {
          task.color = constants.DEFAULT_TYPE_COLORS.GROUP;
          // gantt.updateTask(task.id, task);
        }

        return 'task_level_project';
      }

      if (!task.color && task.type === gantt.config.types.milestone) {
        task.color = constants.DEFAULT_TYPE_COLORS.MILESTONE;
        // gantt.updateTask(task.id, task);
      }

      if (!task.color && task.type === gantt.config.types.task && !gantt.hasChild(task.id)) {
        task.color = constants.DEFAULT_TYPE_COLORS.TASK;
        // gantt.updateTask(task.id, task);
      }

      return '';
    };

    let resClass = resClassFunc(start, end, task);

    if (task.color) {
      const colorValue = task.color;

      if (gantt.config.discoloration_tasks && +task.status === 4 && task.type !== 'project' && !isWorkload) {
        resClass += ` task_discolor discoloration-tasks-${colorValue}`;
      } else {
        resClass += ` task-box-shadow-${colorValue}`;
      }
    }

    let rightsClass = '';
    const canResizeTask = rights.project.hasRight(task.gantt_id, 'static_fields');
    const canChangeProgressTask = rights.project.hasRightSomeOne(task.gantt_id, ['static_fields', 'static_fields_4_only']);
    const canCreateLinkTask = rights.project.hasRight(task.gantt_id, 'task_links');

    if (!canResizeTask) {
      rightsClass += ' gantt_resize_hide';
    }
    if (!canChangeProgressTask) {
      rightsClass += ' gantt_progress_drag_hide';
    }
    if (!canCreateLinkTask) {
      rightsClass += ' gantt_link_controls_hide';
    }

    return resClass + rightsClass;
  },
  addViewModeClass(oldClass, viewMode) {
    const ganttContainer = document.querySelector('.dhx_gantt_container');

    if (ganttContainer) {
      _.each(oldClass, item => {
        ganttContainer.classList.remove(item);
      });

      ganttContainer.classList.add(viewMode);
    }
  },
  adjustGridResizer() {
    gantt.$layout._syncCellSizes('grids', gantt.config.grid_width);
    gantt.$ui.getView('main').resize();
  },
  setEventListenerOnResourcesAvatarHover() {
    const resources = document.querySelectorAll('.gantt_side_content.gantt_right .resource_list_in_grid');

    _.each(resources, resource => {
      resource.addEventListener('mouseenter', _.debounce(event => {
        const pos = event.target.getBoundingClientRectWrapper();

        if (pos.x || pos.y) {
          gantt.callEvent('showResourcesPreviewListForTask', [pos, event.target.dataset.taskId]);
        }
      }));

      resource.addEventListener('mouseleave', _.debounce(event => {
        gantt.callEvent('hideResourcesPreviewListForTask');
      }));
    });
  },
  // detectDevice: function () {
  //   var userAgent = GT.agent;
  //
  //   if (userAgent.isDesktop) {
  //     return 30;
  //   }
  //
  //   return 40;
  // },
  customButtonTemplate(start, end, task) {
    let css = '';

    if (task.type === gantt.config.types.button) {
      css += ' gantt_button';
    }

    if (task.$level === 0) {
      css += ' gantt_estimate_total_row';
    }

    if (task.$level === 1 && task.type !== 'task') {
      css += ' gantt_top_project_row';
    }

    if (gantt.isSelectedTask(task.id) || gantt.getState().selected_task === task.id) {
      css += ' gantt_selected';
    }

    return css;
  },
  gridTasksTemplate(start, end, task) {
    if (task.type === gantt.config.types.button) {
      if (gantt.config.creatingTaskButtonId && task.id === gantt.config.creatingTaskButtonId) {
        return 'gantt_btn_add disable';
      }

      let className = 'gantt_btn_add';

      if (gantt.config.multiview) {
        const parent = gantt.getTask(task.parent);

        if (parent.needMultiviewBackground) {
          className += ' multiviewBackground';
        }
      }
      if (!gantt.config.show_advanced_buttons) {
        const ganttTasks = moduleGridCustomButtons.handlers.addCustomButtonToProject();

        reinitGantt.updateGanttTasks(ganttTasks, true);
        ganttHandlers.addingClassForAdvancedButtons();
      }

      else return className;
    }

    if (task.highlight) {
      return 'gantt_line__highlight';
    }

    if (task.$level > 6) {
      return 'leaf-task';
    }

    if (+task.id === +gantt.$grid_dnd) {
      return 'dnd-task';
    }

    if (gantt.isSelectedTask(task.id) || gantt.getState().selected_task === task.id) {
      return 'gantt_selected';
    }

    if (task.id === gantt._currentHoverRowId) {
      return 'row_hover';
    }

    return '';
  },

  addFoundTask(task, tasks) {
    ganttSettings.filterData.foundTasks.push(task.id);
    ganttSettings.filterData.directEnrty++;
    if (task.type !== gantt.config.types.button) {
      ganttSettings.filterData.foundTasks = _.concat(ganttSettings.filterData.foundTasks,
        _.map(_.filter(tasks, tsk => (tsk.left < task.left && tsk.right > task.right)), tsk2 => tsk2.id));
    }

    ganttSettings.filterData.active = true;
  },
  addElement(config) {
    const div = document.createElement('div');

    div.style.position = 'absolute';
    div.className = config.css || '';
    div.style.left = config.left;
    div.style.width = config.width;
    div.style.height = config.height;
    div.style.lineHeight = config.height;
    div.style.top = config.top;

    if (config.html) {
      div.innerHTML = config.html;
    }

    if (config.wrapper) {
      config.wrapper.appendChild(div);
    }

    return div;
  },
  removeSkinClass() {
    const classList = gantt.$container.classList;
    const values = Object.values(constants.SKINS);

    values.forEach(value => {
      if (classList.contains(value.class)) {
        gantt.$container.classList.remove(value.class);
      }
    });
  },
  renderCutArea(task, side) {
    if (task.$level !== 0) {
      const sizes = gantt.getTaskPosition(task);
      const route = routerHelper.getCurrentRoute();
      const filterData = filtersModel.getFilterDataByActiveProject(route.params.projectId || route.params.multiviewID);
      const criticalWay = gantt.config.highlight_critical_path && gantt.isCriticalTask(task);
      const colorCutArea = criticalWay && task.type !== 'project' ? '#b51a0a' : constants.COLORS[task.color];

      if (filterData && filterData.options.rangeDate) {
        if (gantt.config.start_date && gantt.config.end_date) {
          const cutArea = document.createElement('div');

          cutArea.innerHTML = `<div style="background-color: ${`${colorCutArea}dd`}"></div>`;
          cutArea.classList.add('cut_areaChart');
          if (task.type === 'project') {
            cutArea.classList.add('cut_areaProject');
          }
          cutArea.classList.add(side);
          cutArea.style.top = `${sizes.top + ((gantt.config.row_height - 26) / 2)}px`;

          return cutArea;
        }
      }
    }

    return null;
  },
  hideShowAvatarAndTaskName(filterData) {
    const projectConfig = ganttViewModel.getProjectConfig();
    const currentConfig = app.config.mode.isExport ? gantt.config : projectConfig;

    if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start && filterData.options.rangeDate.end) {
      if (['insideText', 'rightSideText'].includes(currentConfig.right_side_text)) {
        gantt.config.right_side_text = currentConfig.right_side_text;
      } else {
        gantt.config.right_side_text = 'noTaskText';
      }
      gantt.config.show_resource_avatar = false;
    } else {
      if (currentConfig.right_side_text === true) {
        gantt.config.right_side_text = 'rightSideText';
      } else if (currentConfig.right_side_text === false || currentConfig.right_side_text === undefined) {
        gantt.config.right_side_text = 'insideText';
      } else {
        gantt.config.right_side_text = projectConfig.right_side_text;
      }
      gantt.config.show_resource_avatar = projectConfig.show_resource_avatar;
    }
  },
  checkPermission(feature) {
    return globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures[feature].name);
  },
  calcGridWidth() {
    const gridArea = gantt.$container.querySelector('.gantt_grid_data');

    if ((gridArea && gridArea.clientWidth + globalStore.getters['leftSidebarWidth']) >= document.documentElement.clientWidth) {
      gantt.config.grid_width = gantt.$container.clientWidth - 18;
    };
  },
  async checkAndRequireProjectData(ganttIds) {
    await globalStore.dispatch('columns/dynamicLoadValues', { ganttIds: ganttIds });
  },
};

var ganttHandlers = {
  onGanttRender() {
    // ganttHandlers.afterGanttShowing();

    ganttHelpers.adjustGridResizer();
    ganttHelpers.setEventListenerOnResourcesAvatarHover();
  },
  updateParentTaskFromQuery(actionTaskId, parentId) {
    let parentTaskData = {};

    if (!gantt.isTaskExists(parentId)) {
      return true;
    }

    parentTaskData = webix.copy(gantt.getTask(parentId));

    const children = _.filter(gantt.getChildren(parentTaskData.id), childID => {
      const childData = gantt.getTask(childID) || {};

      return childData.type !== gantt.config.types.button;
    });

    const PROJECT_LEVEL = gantt.config.multiview ? 1 : 0;

    if (
      children.length < 1
      && parentTaskData.type === gantt.config.types.project
      && gantt.calculateTaskLevel(parentTaskData.id) > PROJECT_LEVEL
    ) {
      parentTaskData.type = gantt.config.types.task;
      parentTaskData.progress = 0;
      parentTaskData.color = constants.DEFAULT_TYPE_COLORS.TASK;

      if (parentTaskData.end_date.valueOf() <= parentTaskData.start_date.valueOf()) {
        parentTaskData.end_date = gantt.calculateEndDate({
          start_date: parentTaskData.start_date,
          duration: 1,
          task: parentTaskData,
        });
      }

      gantt.callEvent('customButtons:delete:from', [parentTaskData]);
      gantt.updateTask(parentTaskData.id, parentTaskData);
    }
  },
  onBeforeLightbox(id, selectComment, selectAttachments) {
    let taskData = {};
    let linkData = {};
    const settingsInitOptions = {};

    if (!app.config.mode.isBase) {
      return false;
    }

    taskData = gantt.getTask(id);

    if (!taskData) {
      return false;
    }

    if (taskData.type === gantt.config.types.button) {
      return false;
    }

    if (taskData.$target.length) {
      linkData = gantt.getLink(taskData.$target[0]);

      settingsInitOptions.disableStartDate = (gantt.config.auto_scheduling && linkData.type == gantt.config.links.finish_to_start);
    }

    if (!taskData.type) {
      taskData.type = taskData.$rendered_type;
    }

    settingsInitOptions.selectComment = selectComment || null;
    settingsInitOptions.selectAttachments = selectAttachments || null;

    if (taskData.$level !== 0) {
      app.trigger('taskSettings:init', webix.copy(taskData), taskData.gantt_id, settingsInitOptions);
    }

    return false;
  },
  onBeforeTaskDisplay(id, task) {
    return ganttInitFilter.checkFoundTask(id, task); // "ololo"
  },
  onTaskLoading(task) {
    return true;
  },
  onBeforeGanttRender() {
    if (routerHelper.isWorkloadViewRoute()) {
      return;
    }

    ganttHandlers.refreshGanttStartEndDate();

    if (app.config.mode.isLink && !app.config.mode.isTemplate) {
      // ganttHelpers.setAutoZoom();// temporary disabled
    }
  },
  onBeforeDataRender() {
    ganttHandlers.changeCurrentDayMarker(gantt.config.current_day_marker);
  },
  onBeforeTaskDrag(taskId, mode, e) {
    const currentSelectedTask = gantt.selectTask();

    if (currentSelectedTask != taskId) {
      // gantt.unselectTask(currentSelectedTask);
      // gantt.selectTask(taskId);
    }

    mode === gantt.config.drag_mode.move && userExtAnalytics.log('gantt_task_timeline_drag_start');

    return true;
  },
  onEmptyClick(e) {
    app.trigger('gantt:area:click');

    if (gantt.config.readonly) {
      return false;
    }

    const target = e.target || e.srcElement;

    if (typeof target.className === 'object') { // svg
      return false;
    }

    if (target.className.indexOf('add_task_button') > -1 || (target.parentNode && target.parentNode.className.indexOf('add_task_button') > -1)) {
      if (ganttSettings.filterData.foundTasks.length) {
        webix.message({
          type: 'info',
          text: __('gantt_msg_add_button_filter'),
        });

        return false;
      }
    }
  },
  onAfterGanttRender() {
    const resizer = document.querySelector('.gantt_resizer_x');

    if (!resizer) {
      return;
    }

    if (gantt.config.show_grid) {
      resizer.style.display = 'block';

      return;
    }

    resizer.style.display = 'none';
  },
  onAutoScheduleCircularLink(cycles, tasks = []) {
    const ganttData = gantt.serialize();
    const linksToRemove = _.map(cycles, cycle => Math.max(...cycle.links));

    _.each(linksToRemove, linkID => gantt.deleteLink(linkID));

    return webix.ajax().headers({ 'Content-type': 'application/json' })
      .post('/api/tasks/onAutoScheduleCircularLink', {
        gantt_id: gantt.config.gantt_id,
        cycles,
        tasks: tasks.map(task => ({ text: task.text, id: task.id })),
        linksToRemove,
        ganttData,
      })
      .then(() => {
        if (linksToRemove.length > 0) {
          gantt.autoSchedule();
        }
      });
  },
  onBeforeTaskClick(taskId) {
    const item = gantt.getTask(taskId);
    const taskSettingsRoute = routerHelper.isTaskSettingsRoute();

    if (taskSettingsRoute) {
      if (item.type === gantt.config.types.button || !item.parent || item.parent === 1) {
        return;
      }

      _.delay(() => {
        routerHelper.pushRoute({
          name: taskSettingsRoute,
          params: {
            taskId: String(taskId),
          },
        });
      });

      return false;
    }

    return true;
  },
  onBeforeAutoSchedule(taskID) {
    if (gantt.isTaskExists(taskID)) {
      const task = gantt.getTask(taskID);

      return autoSchedulingPatchesModule.handlers.onBeforeAutoSchedule(task);
    }

    return true;
  },
  onBeforeTaskRowClick(task, colName, isEditorOpened) {
    if (isEditorOpened && colName === 'end_date' && task.type === gantt.config.types.project) {
      webix.message({
        type: 'warning',
        text: __('message_user_click_end_date_on_parent'),
      });
    }

    app.trigger('gantt:area:click');
  },
  onBeforeTaskAdd(taskId, taskData) {
    const parent = gantt.isTaskExists(taskData.parent) ? gantt.getTask(taskData.parent) : {};

    const viewData = ganttViewModel.getActiveViewData();
    const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);

    if (!taskData.parent && taskData.taskType !== 'import') {
      taskData.parent = gantt.serialize().data[0].id;
    }

    if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start && filterData.options.rangeDate.end) {
      const rangeStart = filterData.options.rangeDate.start;
      const rangeEnd = filterData.options.rangeDate.end;
      const projectCalendar = gantt.getCalendar(taskData.gantt_id);
      const diffRangeStart = moment(rangeStart).diff(taskData.start_date, 'day');
      const parentStartDate = gantt.getTask(taskData.parent).start_date;
      // let currentDayInRange = new Date() >= rangeStart &&  new Date() <= rangeEnd;

      if (parentStartDate >= rangeStart && parentStartDate <= rangeEnd) {
        taskData.start_date = parentStartDate;
      } else if (parentStartDate < rangeStart) {
        if (rangeStart > taskData.start_date) {
          taskData.start_date = moment(taskData.start_date).add(Math.abs(diffRangeStart), 'day').toDate();
        } else {
          taskData.start_date = moment(taskData.start_date).subtract(Math.abs(diffRangeStart), 'day').toDate();
        }
      }

      if (!gantt.isWorkDay(taskData.start_date, projectCalendar)) {
        taskData.start_date = gantt.getClosestWorkTime({
          date: taskData.start_date, dir: 'future', unit: gantt.config.duration_unit, task: taskData,
        });
      }

      const workRange = gantt.getDayHours(taskData.start_date);
      const dayStart = new Date(taskData.start_date.getFullYear(), taskData.start_date.getMonth(), taskData.start_date.getDate(), workRange.firstHour);

      taskData.start_date = gantt.getClosestWorkTime({ date: dayStart, dir: 'future' });

      if (rangeStart.valueOf() > taskData.start_date.valueOf()) {
        taskData.start_date = gantt.getClosestWorkTime({ date: gantt.date.add(taskData.start_date, 1, 'day'), dir: 'future' });
      }

      if (gantt.config.durationData.mode === 'day') {
        taskData.end_date = gantt.calculateToDuration(taskData.start_date, 1, 'day').endDate;
      } else if (gantt.config.durationData.mode === 'hour') {
        taskData.end_date = gantt.calculateToDuration(taskData.start_date, 60, 'minute').endDate;
      } else {
        taskData.end_date = moment(taskData.end_date).add(diffRangeStart, 'day').toDate();
      }
    }
    if (!taskData.progress) {
      if (taskData.taskType !== 'import') {
        taskData.progress = 0;
      }
    }

    const childrenList = gantt.getChildren(taskData.parent);

    if (!taskData.sortorder) {
      taskData.sortorder = gantt.taskDataStoreIndex(childrenList[childrenList.length - 1]) + 1;

      if (taskData.sortorder < 0) {
        taskData.sortorder = childrenList.length + 1;
      }
    }

    if (gantt.config.multiview) {
      const parent = gantt.getTask(taskData.parent);

      if (parent.needMultiviewBackground) {
        taskData.needMultiviewBackground = true;
      }
    }

    if (!taskData.gantt_id) {
      taskData.gantt_id = parent.gantt_id;
    }

    if (parent.resources && parent.resources.length) {
      globalStore.dispatch('tasksModel/changeAssign', {
        changedResources: [],
        taskId: parent.id,
        ganttId: parent.gantt_id,
      });
    }

    taskData = globalStore.getters['tasksModel/getDefaultTaskValues'](taskData);

    taskData.timezoneOffset = taskData.end_date.getTimezoneOffset();

    return true;
  },
  onBeforeTaskDelete(id, task) {
    if (!task || !task.parent) {
      return false;
    }

    return true;
  },
  onAfterTaskDelete(id, task) {
    const viewData = ganttViewModel.getActiveViewData();
    const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);

    if (task.type !== gantt.config.types.button) {
      globalStore.dispatch('tasksModel/deleteTask', task);
      ganttHandlers.updateParentTaskFromQuery(id, task.parent);

      if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start) {
        app.trigger('filter:set', filterData.options);
      }
      gantt.render();
      gantt.callEvent('tasks:correctDates');
    }
  },
  onBeforeLinkAdd(id, link) {
    if (routerHelper.isListViewRoute()) {
      return true;
    }
    if (
      gantt.getTask(link.target).type === gantt.config.types.button
      || !gantt.getTask(link.source).parent
      || !gantt.getTask(link.target).parent
    ) {
      return false;
    }

    if (gantt.getTask(link.target).gantt_id !== gantt.getTask(link.source).gantt_id) {
      webix.message({
        type: 'info',
        text: __('gantt_msg_dependency_error'),
      });
      return false;
    }

    if (!globalStore.getters['tasksModel/checkLinkAccess'](
      gantt.getTask(link.target).gantt_id,
      link.source,
      link.target,
    )) {
      webix.message({
        type: 'info',
        text: __('gantt_msg_dependency_limit_error'),
      });

      return false;
    }

    link.gantt_id = link.gantt_id || gantt.getTask(link.target).gantt_id;

    return true;
  },
  onTaskDrag(id, mode, task, original, e) {
    task.duration = original.duration;

    if (gantt.config.auto_scheduling && mode === 'move') {
      const predecessors = gantt._getPredecessors(task);

      const configTask = {
        date: task.start_date,
        dir: 'future',
      };

      const configPreferredEnd = {
        date: predecessors[0] ? predecessors[0].preferredEnd : null,
        dir: 'future',
      };

      if (!_.isEmpty(predecessors) && configPreferredEnd.date && moment(gantt.getClosestWorkTime(configTask)).isBefore(gantt.getClosestWorkTime(configPreferredEnd))) {
        task.$preferred = {
          start_date: gantt.getClosestWorkTime({ date: predecessors[0].preferredEnd, dir: 'future' }),
          duration: original.duration,
        };

        if (moment(task.$preferred.start_date).isSame(original.start_date)) {
          task.$ignore_update = true;
          task.$preferred = null;
          task.duration = original.duration;
        }
      } else {
        task.$preferred = null;
      }

      if (predecessors.length === 1) {
        // task.$ignore_update = true;
      }
    }

    return true;
  },
  onAfterLinkDelete(link) {
    const ganttID = link.gantt_id;

    globalStore.commit('tasksModel/deleteLink', link);
    globalStore.dispatch('tasksModel/updateTask', {
      taskChanges: gantt.getTask(link.source),
      taskId: gantt.getTask(link.source).id,
      ganttId: ganttID,
    });
    globalStore.dispatch('tasksModel/updateTask', {
      taskChanges: gantt.getTask(link.target),
      taskId: gantt.getTask(link.target).id,
      ganttId: ganttID,
    });
  },
  silentUpdate(taskId, task) {
    if (gantt.config.multiview) {
      const taskInMultiview = ganttViewModel.getTaskValuesMultiview(task);

      if (taskInMultiview.open !== task.open) {
        ganttViewModel.updateMultiviewValuesByTaskID(task);
      }
    }

    setTimeout(() => {
      globalStore.dispatch('tasksModel/updateTask', {
        taskChanges: task,
        taskId: task.id,
        ganttId: task.gantt_id,
      });
    }, 100);

    return true;
  },
  onBeforeTaskUpdate(taskId, task) {
    if (taskId === 1) {
      return false;
    }

    if (task.$preferred) {
      task.start_date = task.$preferred.start_date;
      task.duration = task.$preferred.duration;
      task.end_date = gantt.calculateEndDate(task);

      task.$preferred = null;
    }

    if (task.type === gantt.config.types.task && (moment(task.start_date).isSame(task.end_date) || task.duration === 0)) {
      task.duration = 1;
      task.end_date = gantt.calculateEndDate(task.start_date, task.duration);
    }

    task.progress = _.floor(task.progress, 2);
    task.timezoneOffset = task.end_date.getTimezoneOffset();

    ganttSettings.taskDataBeforeUpdate = globalStore.getters['tasksModel/getTaskByGanttId'](task.gantt_id, taskId);

    if (!_.isUndefined(ganttSettings.taskDataBeforeUpdate.open) && ganttSettings.taskDataBeforeUpdate.open !== task.open) {
      task.silent = 1;
      task.muteNotify = 1;
      task.isHidden = 1;
    }

    if (gantt.config.multiview) {
      ganttSettings.taskDataBeforeUpdateMultiview = ganttViewModel.getTaskValuesMultiview(ganttSettings.taskDataBeforeUpdate);
      task.multiview_id = gantt.config.multiview_id;

      if (ganttSettings.taskDataBeforeUpdateMultiview.open !== task.open) {
        task.silent = 1;
        task.muteNotify = 1;
        task.isHidden = 1;
      }
    } else {
      delete task.multiview_id;
    }

    // only for webhooks
    if (!ganttSettings.taskDataBeforeUpdate.deadline) {
      ganttSettings.taskDataBeforeUpdate.deadline = null;
    }
    ganttSettings.taskDataBeforeUpdate.start_date_string = moment(ganttSettings.taskDataBeforeUpdate.start_date).format('YYYY-MM-DD HH:mm:ss');
    ganttSettings.taskDataBeforeUpdate.end_date_string = moment(ganttSettings.taskDataBeforeUpdate.end_date).format('YYYY-MM-DD HH:mm:ss');
    task.start_date_string = moment(task.start_date).format('YYYY-MM-DD HH:mm:ss');
    task.end_date_string = moment(task.end_date).format('YYYY-MM-DD HH:mm:ss');

    task.diff = globalStore.getters['tasksModel/getDiff'](task, ganttSettings.taskDataBeforeUpdate);

    return true;
  },
  onAfterTaskUpdate(id, item) {
    let taskBeforeUpdate = ganttSettings.taskDataBeforeUpdate;
    const viewData = ganttViewModel.getActiveViewData();
    const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);

    if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start) {
      app.trigger('filter:set', filterData.options);
    }

    if (gantt.config.multiview) {
      taskBeforeUpdate = ganttSettings.taskDataBeforeUpdateMultiview;
    }

    const diff = _.reduce(taskBeforeUpdate, (result, value, key) => {
      if (_.includes(['actionHash', 'end_date'], key)) {
        return result;
      }

      if (key === 'start_date') {
        return _.isEqual(new Date(value).valueOf(), new Date(item[key]).valueOf()) ? result : result.concat(key);
      }

      if (key === 'progress') {
        value = Math.round(value * 100) / 100;

        return _.isEqual(value, Math.round(item[key] * 100) / 100) ? result : result.concat(key);
      }

      return _.isEqual(value, item[key]) ? result : result.concat(key);
    }, []);

    gantt.callEvent('onAfterSmartTaskUpdate', [id, item, diff, ganttSettings.taskDataBeforeUpdate]);

    const oldTaskData = ganttSettings.taskDataBeforeUpdate;

    if (!_.includes(diff, 'open') && taskBeforeUpdate.open !== item.open) {
      diff.push('open');
    }

    setTimeout(() => {
      if (gantt.config.multiview) {
        if (_.includes(diff, 'open')) {
          ganttViewModel.updateMultiviewValuesByTaskID(item);
        }
        item = _.cloneDeep(item);
        item.open = oldTaskData.open;
      }

      delete item.diff;
      delete item.start_date_string;
      delete item.end_date_string;
      globalStore.dispatch('tasksModel/updateTask', {
        taskChanges: item,
        taskId: item.id,
        ganttId: item.gantt_id,
      });

      if (_.includes(diff, 'open')) {
        gantt.$ui.getView('scrollVer').resize();
      }

      if (_.includes(diff, 'start_date') || _.includes(diff, 'end_date') || _.includes(diff, 'duration')) {
        gantt._correctDatesSourceGanttId = item.gantt_id;
        gantt.callEvent('tasks:correctDates', [true]);
      }

      if (_.isEmpty(oldTaskData)) {
        return;
      }

      if (item.estimation !== oldTaskData.estimation) {
        app.trigger('changedTaskEstimation', item);
      }

      if (item.type !== oldTaskData.type && item.type === gantt.config.types.milestone) {
        app.trigger('changedTaskToMilestone', item);
      } else if (item.duration !== oldTaskData.duration) {
        app.trigger('changedTaskDuration', item, oldTaskData);
      }
    }, 100);

    ganttSettings.taskDataBeforeUpdate = {};
    gantt.updateTaskEditorsCurrentTask(id);

    gantt.callEvent('checkTimelineDates', [item]);
  },
  onAfterLinkUpdate() {
    lastUpdatedTasks = [];
    gantt.callEvent('tasks:correctDates', [true]);
  },
  onAfterLinkAdd() {
    lastUpdatedTasks = [];
    gantt.callEvent('tasks:correctDates', [true]);
    userExtAnalytics.log('gantt_link_add_done', { from: 'chart' });
  },
  onLinkIdChange() {
    gantt.config.auto_scheduling && gantt.autoSchedule();
  },
  onAfterAutoSchedule(t) {
    // gantt.callEvent('tasks:correctDates', [true]);
    if (gantt.config.highlight_critical_path) {
      gantt.callEvent('recalculateCriticalPath');
    }
  },
  onAfterProjectMove(id, actionHash) {
    gantt._correctDatesActionHash = actionHash || gantt._correctDatesActionHash;
    if (gantt.config.auto_scheduling && !gantt._autoscheduling_in_progress) {
      gantt.autoSchedule(id);
    }
    gantt.callEvent('tasks:correctDates');
    collaborationComponent.refreshParents(id);
    if (ganttHandlers.refreshGanttStartEndDate()) {
      gantt.render();
    }
  },
  onTaskOpened(id) {

  },
  onTaskClosed(id) {

  },
  onLinkDblClick(id) {
    return false;
  },
  onTaskCommentsClick(taskId) {
    const ganttTask = gantt.getTask(taskId);

    app.trigger('taskSettings:init', webix.copy(ganttTask), ganttTask.gantt_id, {
      selectComment: true,
    });
    userExtAnalytics.log('gantt_task_settings_open', {
      from: routerHelper.analyticsFromRoute(),
      route: `${routerHelper.analyticsRoute()}/ClickOnComment`,
    });
  },
  onTaskAttachmentsClick(taskId) {
    const ganttTask = gantt.getTask(taskId);

    app.trigger('taskSettings:init', webix.copy(ganttTask), ganttTask.gantt_id, {
      selectAttachments: true,
    });
    userExtAnalytics.log('gantt_task_settings_open', {
      from: routerHelper.analyticsFromRoute(),
      route: `${routerHelper.analyticsRoute()}/ClickOnAttachment`,
    });
  },
  onTaskEditClick(taskId) {
    if (projectsModel.isArchived(gantt.config.gantt_id)) return;
    const ganttTask = gantt.getTask(taskId);

    if (ganttTask.type !== 'button') {
      app.trigger('taskSettings:init', webix.copy(ganttTask), ganttTask.gantt_id, {
        // selectAttachments: true
      });
    } else {
      return false;
    }

    // userExtAnalytics.log('gantt_task_settings_open', {
    //   from: routerHelper.analyticsFromRoute(),
    //   route: routerHelper.analyticsRoute() + '/FastButton',
    // });
  },
  onShowMoreTooltipClick(taskId) {
    const ganttTask = gantt.getTask(taskId) || globalStore.getters['tasksModel/getTask'](taskId);

    app.trigger('taskSettings:init', webix.copy(ganttTask), ganttTask.gantt_id, {
      selectDescription: true,
    });
    userExtAnalytics.log('gantt_task_settings_open', {
      from: routerHelper.analyticsFromRoute(),
      route: `${routerHelper.analyticsRoute()}/ClickOnTooltip`,
    });
  },
  onAfterTaskAdd(ganttId, taskData) {
    if (taskData.type !== 'import') {
      if (ganttSettings.filterData.active && ganttSettings.filterData.foundTasks.length) {
        ganttSettings.filterData.foundTasks.push(taskData.id);
      }
      _.delay(() => {
        globalStore.dispatch('tasksModel/updateSortorderTasksAfterAddTask', { ganttId, newTaskData: taskData });
      });

      if (taskData.start_date > gantt.config.end_date) {
        gantt.callEvent('ganttRender', [() => {
          gantt.showDate(taskData.start_date);
        }]);
      }
    }
  },
  advancedButtons(value) {
    ganttHandlers.changeAdvancedButtons(value);
    gantt.callEvent('ganttRender');
  },
  criticalPathChange(critcalPathState) {
    ganttSettings.criticalPath = !!(critcalPathState);
    ganttHandlers.changeCriticalPath();
  },
  viewModeChange(viewModeState) {
    ganttSettings.viewMode = viewModeState;
    ganttHandlers.changeViewMode(viewModeState);
  },
  resourceLoadingTypeChange(resourceLoadingType) {
    ganttSettings.resourceLoadingType = resourceLoadingType;
    ganttHandlers.changeResourceLoadingType();
  },
  startOnMondayChange(startOnMondayState) {
    if (gantt.config.gantt_id) {
      ganttSettings.startOnMonday = startOnMondayState;
      user.settings.start_monday = ganttSettings.startOnMonday;
      gantt.config.start_on_monday = user.settings.start_monday;
      ganttHandlers.changeStartOnMonday();
    }
  },
  durationViewChange(durationViewState) {
    ganttSettings.durationView = durationViewState;
    ganttHandlers.changeDurationView(durationViewState);
  },
  saveTask(taskData) {
    gantt.updateTask(taskData.id, taskData);
  },
  deleteTask(taskId) {
    const viewData = ganttViewModel.getActiveViewData();
    const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);

    if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start) {
      app.trigger('filter:set', filterData.options);
    }
    gantt.deleteTask(taskId);
  },
  checkOnSelectedTask() {
    if (gantt.isInlineEditorOpened()) {
      gantt.unloadTaskEditors();
    } else if (gantt.getSelectedId()) {
      gantt.unselectTask();
    }
  },
  ganttClearAndParse(innerGanttTasks, restoreScrollState) {
    const tasks = innerGanttTasks || ganttViewModel.getTasks();
    const tasksWithButtons = moduleGridCustomButtons.handlers.addCustomButtonToProject(tasks);
    const oldScrollState = gantt.getScrollState();

    gantt.unloadTaskEditors();

    gantt.silent(() => {
      gantt.blockEvents();
      gantt.getDatastore('task').clearAll();
      gantt.getDatastore('link').clearAll();
      gantt.parse(tasksWithButtons);
      gantt.unblockEvents();
    });

    const needRender = ganttHandlers.refreshGanttStartEndDate();

    gantt.$layout.resize();
    gantt.callEvent('onParse');

    if (needRender) {
      gantt.render();
    }

    if (restoreScrollState) {
      gantt.scrollTo(oldScrollState.x, oldScrollState.y);
    }
  },
  showDeadlineMessage(tasksArray, masterType) {
    if (masterType === 'autoscheduling') {
      tasksArray.forEach(task => {
        if (task.deadline && task.end_date > task.deadline) {
          webix.message({
            type: 'warning',
            text: `${task.text} - ${__('warning_the_task_is_overdue')}`,
          });
        }
      });
    }
  },
  changeZoom(durationData, workDaysNotChange) {
    ganttInit.configurations.highlightArea(durationData);

    if (!workDaysNotChange) {
      this.ganttRender();
    }
  },
  changeCriticalPath() {
    ganttInit.configurations.criticalPath(ganttSettings.criticalPath);
    this.ganttRender();
  },
  changeAdvancedButtons(value) {
    gantt.config.show_advanced_buttons = value;
    const ganttTasks = moduleGridCustomButtons.handlers.addCustomButtonToProject();

    reinitGantt.updateGanttTasks(ganttTasks, true);
    ganttHandlers.addingClassForAdvancedButtons();
  },
  changeAutoScheduling(autoSchedulingState) {
    ganttInit.configurations.autoScheduling(autoSchedulingState);
    gantt.autoSchedule();
  },
  changeAutoBudget({ autoBudgetState, tasks }) {
    ganttInit.configurations.autoBudget(autoBudgetState);
    tasks.forEach(({ id, total_price, actual_cost }) => {
      const ganttTask = gantt.getTask(id);
      ganttTask.total_price = total_price;
      ganttTask.actual_cost = actual_cost;
    });
    gantt.refreshData();
  },
  changeStartOnMonday() {
    ganttInit.configurations.startOnMonday(+user.settings.start_monday);
    this.ganttRender();
  },
  changeResourceLoadingType() {
    ganttInit.configurations.initResourceLoadingType(ganttSettings.resourceLoadingType);
    this.ganttRender();
  },
  changeCurrentDayMarker(currentDayMarker) {
    ganttInit.configurations.currentDayMarker(currentDayMarker);
  },
  changeShowResourceAvatar(showResourceAvatar) {
    ganttInit.configurations.showResourceAvatar(showResourceAvatar);
    ganttHandlers.ganttRender();
  },
  changeRightSideText(rightSideText) {
    ganttInit.configurations.rightSideText(rightSideText);
    ganttHandlers.ganttRender();
  },
  changeEstimationMode(mode) {
    ganttInit.configurations.estimationModes(mode);
  },
  changeApplyResourceCalendar(mode) {
    ganttInit.configurations.applyResourceCalendar(mode);
  },
  changeSkipOffTime(skipOffTime) {
    if (gantt.config.durationData.mode !== 'hour') {
      ganttHandlers.refreshGanttStartEndDate();
      ganttInit.configurations.skipOffTime(!skipOffTime);
      ganttHandlers.ganttRender();
    }
  },
  changeOverdueHighlight(overdue) {
    ganttSettings.overdueHighlight = !!(overdue);
    ganttInit.configurations.overdueHighlight(overdue);
    ganttHandlers.ganttRender();
  },
  changeViewMode(viewModeState) {
    ganttInit.configurations.durationViewMode(viewModeState);
    this.ganttRender();
  },
  changeDiscolorationTasks(discolorationTasks) {
    ganttInit.configurations.discolorationTasks(discolorationTasks);
    ganttHandlers.ganttRender();
  },
  changeCrossOutTasks(crossOutTasks) {
    ganttInit.configurations.crossOutTasks(crossOutTasks);
    ganttHandlers.ganttRender();
  },
  changeDurationView(durationViewState) {
    ganttInit.configurations.durationView(durationViewState);

    _.delay(() => {
      ganttHandlers.ganttRender();
    });

    const durationColumn = _.filter(gantt.config.columns);

    if (durationColumn) {
      durationColumn.label = `<span class='grid_head_cel_label'>${__('gantt_grid_duration')}</span>`;
    }
  },
  changeSkin(ganttSkin) {
    let skin = '';

    if (!constants.SKINS[ganttSkin]) {
      skin = 4;
    } else {
      skin = ganttSkin;
    }
    ganttSettings.ganttSkin = skin;
    const dhxView = $$('dhxView-gantt');

    dhxView.$view.className = 'webix_view gantt_skin_terrace';
    ganttHelpers.removeSkinClass();
    skin && gantt.$container.classList.add(constants.SKINS[skin].class);
    if (gantt.config.multiview) {
      gantt.$container.classList.add('isMultiview');
    } else if (!gantt.config.multiview && gantt.$container.classList.contains('isMultiview')) {
      gantt.$container.classList.remove('isMultiview');
    }
    ganttHandlers.addingClassForAdvancedButtons();
  },
  addingClassForAdvancedButtons() {
    if (!gantt.config.show_advanced_buttons
      || app.config.mode.isExport || app.config.mode.isLink || !rights.project.hasRight(gantt.config.gantt_id, 'create_task')) {
      gantt.$container.classList.add('no-buttons');
    } else if ((gantt.config.show_advanced_buttons || rights.project.hasRight(gantt.config.gantt_id, 'create_task')) && gantt.$container.classList.contains('no-buttons')) {
      gantt.$container.classList.remove('no-buttons');
    }
    gantt.callEvent('ganttRender');
  },
  refreshGanttStartEndDate() {
    const range = gantt.getSubtaskDates();
    const viewData = ganttViewModel.getActiveViewData();
    const filterData = viewData && filtersModel.getFilterDataByActiveProject(viewData.id);
    const scaleUnit = gantt.config.durationData.mode;
    let scaleAsideMultiplier = 1;
    const spaceBefore = -1;
    const colWidth = {
      hour: 20,
      day: 26,
      week: 70,
      month: 65,
      quarter: 135,
      year: 45,
    };
    const spaceAfter = Math.ceil(800 / colWidth[scaleUnit]);

    if (range.start_date && range.end_date) {
      if (app.config.mode.isExport) {
        gantt.$container && gantt.$container.classList.add('gantt_layout_export');

        if (ganttSettings.filterData.foundTasks.length > 1) {
          const tasks = _.map(ganttSettings.filterData.foundTasks, id => gantt.getTask(id)).filter(task => task.parent);

          range.start_date = new Date(Math.min(...tasks.map(task => task.start_date)));
          range.end_date = new Date(Math.max(...tasks.map(task => task.end_date)));
        }
      } else if (gantt.config.scale_unit === 'month') {
        scaleAsideMultiplier = 5;
      }

      let endDateDuration = spaceAfter * scaleAsideMultiplier;

      if (gantt.config.additionalScale) {
        endDateDuration = gantt.config.additionalScale;
        gantt.config.additionalScale = null;
      }

      const newStartDate = gantt.calculateEndDate(range.start_date, spaceBefore * scaleAsideMultiplier, scaleUnit);
      const newEndDate = gantt.calculateEndDate(range.end_date, endDateDuration, scaleUnit);
      const needRender = !(moment(newStartDate).isSame(gantt.config.start_date, 'hour') && moment(newEndDate).isSame(gantt.config.end_date, 'hour'));

      if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start && filterData.options.rangeDate.end) {
        const rangeStart = moment(filterData.options.rangeDate.start, constants.TASK_DATES_FORMAT).toDate();
        const rangeEnd = filterData.options.rangeDate.period ? filterData.options.rangeDate.end : moment(filterData.options.rangeDate.end, constants.TASK_DATES_FORMAT).add(1, 'day').toDate();

        gantt.config.start_date = rangeStart;
        gantt.config.end_date = rangeEnd;
        gantt.config.is_apply_timeline_range = true;
        globalStore.commit('headerView/setUIConfig');
        ganttHelpers.hideShowAvatarAndTaskName(filterData);
      } else if (needRender) {
        gantt.config.end_date = gantt.calculateEndDate(range.end_date, endDateDuration, scaleUnit);
        gantt.config.start_date = gantt.calculateEndDate(range.start_date, spaceBefore * scaleAsideMultiplier, scaleUnit);
        gantt.config.is_apply_timeline_range = false;
        globalStore.commit('headerView/setUIConfig');
        ganttHelpers.hideShowAvatarAndTaskName(filterData);
      }
      // ganttHelpers.hideShowAvatarAndTaskName(filterData);
      return needRender;
    }

    return false;
  },
  afterTasksUpdated: _.debounce((masterType, cloneArray) => {
    if (gantt.config.auto_scheduling && _.includes(['correctDates', 'autoscheduling'], masterType)) {
      lastUpdatedTasks = cloneArray;

      const tasks = gantt.serialize().data.filter(task => task.type !== gantt.config.types.button);

      _.each(tasks, task => {
        const ganttTask = gantt.getTask(task.id);

        ganttTask.constraint_date = null;
        ganttTask.constraint_type = null;
      });

      // gantt.refreshData();
      gantt.autoSchedule();

      // const parentIDs = _.reduce(cloneArray, (res, task) => {
      //   const parents = collaborationComponent.getParentsIDs(task.id);
      //   parents.push(task.id);
      //   return _.union(res, parents);
      // }, []);

      gantt.render();
      gantt.callEvent('recalculateWorkload');
    }
  }, 500),
  ganttClearAll() {
    gantt.blockEvents();
    gantt.getDatastore('task').clearAll();
    gantt.getDatastore('link').clearAll();
    gantt.unblockEvents();

    const allProjects = projectsModel.getAllProjects();

    if (!allProjects || allProjects.length === 0) {
      moduleDisableGantt.init.hideCommonLeftSideBarButtons();
    }
  },
  ganttRender: _.debounce(callback => {
    if (gantt.isEditingState()) {
      reinitGantt.callAfterEditing(() => {
        ganttHandlers.ganttRender(callback);
      });

      return;
    }

    gantt.render();

    if (ganttSettings.filterRender === 1) {
      gantt.callEvent('onAfterGanttRender', ['filter']);
      ganttSettings.filterRender = 0;
    } else {
      gantt.callEvent('onAfterGanttRender');
    }

    callback && callback();
  }, 50),

  ganttScrollClosePopup() {
    if (!gantt._blockScrollEvent) {
      webix.callEvent('onClick', []);
    } else {
      gantt._blockScrollEvent = false;
    }
  },

  // updateGanttTask(taskIdUpdated, taskData, oldTaskData) {
  //   if (ganttSettings.filterData.active) {
  //     filtersModel.updateTaskInFilterMode(taskData);
  //   }
  // },
  afterGanttShowing() {
    let totalEstimateTask = {};
    const toDay = new Date();
    const ganttId = ganttSettings.ganttId;

    ganttHandlers.changeSkin(ganttSettings.ganttSkin);

    if (ganttSettings.initOptions && ganttSettings.initOptions.selectTaskId) {
      const taskId = ganttSettings.initOptions.selectTaskId;

      gantt.selectAndShowTask(taskId, true);

      if (ganttSettings.initOptions.showAttachments) {
        gantt.callEvent('onBeforeLightbox', [taskId, false, true]);
      } else if (ganttSettings.initOptions.showSettings) {
        gantt.callEvent('onBeforeLightbox', [taskId]);
      } else {
        gantt.callEvent('onBeforeLightbox', [taskId, true]);
      }

      ganttSettings.initOptions = {};

      return true;
    }

    const rootChild = gantt.getChildren(gantt.config.root_id)[0];

    totalEstimateTask = gantt.isTaskExists(rootChild) && gantt.getTask(rootChild);

    if (totalEstimateTask && totalEstimateTask.start_date.valueOf() > toDay.valueOf()) {
      gantt.showDate(totalEstimateTask.start_date);

      return true;
    }

    if (totalEstimateTask && totalEstimateTask.end_date.valueOf() < toDay.valueOf()) {
      gantt.showDate(totalEstimateTask.end_date);

      return true;
    }

    gantt.showDate(toDay);
  },
  settingsHide() {
    const projectData = ganttViewModel.getActiveProjectData();
    const projectConfig = ganttViewModel.getProjectConfig();

    if (ganttSettings.progressCalc !== projectConfig.progress) {
      ganttSettings.progressCalc = 0;
      this.progressTypeChange();
    }
    if (ganttSettings.criticalPath !== projectConfig.highlight_critical_path) {
      ganttSettings.criticalPath = projectConfig.highlight_critical_path;
      this.changeCriticalPath(projectConfig.highlight_critical_path);
    }
    if (ganttSettings.overdueHighlight !== projectConfig.highlight_overdue) {
      ganttSettings.overdueHighlight = projectConfig.highlight_overdue;
      this.changeOverdueHighlight(projectConfig.highlight_overdue);
    }
    if (ganttSettings.durationView !== projectConfig.duration_view) {
      ganttSettings.durationView = projectConfig.duration_view;
      this.changeDurationView(projectConfig.duration_view);
    }
    if (ganttSettings.viewMode !== projectConfig.view_mode) {
      ganttSettings.viewMode = projectConfig.view_mode;
      this.changeViewMode(projectConfig.view_mode);
    }
    if (ganttSettings.ganttSkin !== projectData.skin) {
      ganttSettings.ganttSkin = projectData.skin;
      ganttHandlers.changeSkin(projectData.skin);
    }
  },
};

window.checkerCount = 0;

var ganttInitFilter = {
  initHandlers() {
    app.on('hideFilterPopup', () => {
      if (ganttSettings.filterData.foundTasks.length === 1) {
        app.trigger('fullClearFilter');
      }
    });

    app.on('filter:set', ganttInitFilter.beforeApplyFilter);
    app.on('filter:clear', ganttInitFilter.clearFilter);

    app.on('filter:status:update', () => {
      if (ganttSettings.filterData.foundTasks.length === 1) {
        if ($$('filterPopup') && !$$('filterPopup').getNode().classList.contains('window_visible')) {
          if (app.config.mode.isLink && !_.isEmpty(GT.ganttData.filter)) {
            return;
          }

          app.trigger('fullClearFilter');
        }
      }
    });
  },
  checkFoundTask(taskId, task) {
    let enableFoundTask = !ganttSettings.filterData.foundTasks.length;

    if (!enableFoundTask) {
      enableFoundTask = (ganttSettings.filterData.foundTasks.indexOf(taskId) !== -1);
    }

    if (task.$virtual) {
      enableFoundTask = true;
    }

    if (ganttSettings.filterData.active && ganttSettings.filterData.foundTasks.length === 0) {
      enableFoundTask = false;

      if (task.type === gantt.config.types.project && +task.parent === 0) {
        enableFoundTask = true;
      }
    }

    return enableFoundTask;
  },
  checkAccessToTask(task) {
    const userId = rights.getUserId();
    const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](userId);

    if (task.type !== gantt.config.types.task && task.type !== gantt.config.types.milestone) return true;

    return task.resources.find(r => r.resource_id === resourceData.id);
  },
  beforeApplyFilter() {
    const viewData = ganttViewModel.getActiveViewData();
    const ganttIds = viewData.ganttIDs || [viewData.gantt_id];
    const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);

    if (filtersModel.isSetFilter(filterData)) {
      const ganttTasks = gantt.serialize();

      if (ganttIds.some(ganttId => !rights.project.hasRight(ganttId, 'all_tasks'))) {
        ganttTasks.data = ganttTasks.data.filter(task => {
          if (ganttInitFilter.checkAccessToTask(task)) {
            return true;
          }

          return rights.project.hasRight(task.gantt_id, 'all_tasks');
        });
      }

      ganttInitFilter.applyFilter(filterData, ganttTasks, true);
    } else {
      ganttInitFilter.clearFilter();
    }
  },
  applyFilter(filterData, ganttTasks, ganttRender, clearFilter) {
    if (globalStore.getters['filter/isDisable']) return;

    ganttInit.configurations.filter(filterData, ganttTasks, clearFilter);

    ganttSettings.filterRender = 1;
    ganttRender && ganttHandlers.ganttRender();
  },
  clearFilter() {
    const needRender = (ganttSettings.filterData.active && ganttSettings.filterData.foundTasks && ganttSettings.filterData.foundTasks.length);

    ganttSettings.filterData.foundTasks = [];
    ganttSettings.filterData.active = false;
    ganttSettings.filterData.directEnrty = 0;

    const viewData = ganttViewModel.getActiveViewData();
    const ganttIds = viewData.ganttIDs || [viewData.gantt_id];

    if (ganttIds.some(ganttId => !rights.project.hasRight(ganttId, 'all_tasks'))) {
      const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);
      const ganttTasks = gantt.serialize();

      ganttTasks.data = ganttTasks.data.filter(task => {
        if (ganttInitFilter.checkAccessToTask(task)) {
          return true;
        }

        return rights.project.hasRight(task.gantt_id, 'all_tasks');
      });

      ganttInitFilter.applyFilter(filterData, ganttTasks, true, true);

      return;
    }

    ganttSettings.filterRender = 1;
    needRender && ganttHandlers.ganttRender();
  },
};

var ganttInitModules = {
  init: {
    run() {
      ganttInitModules.init.modules.beforeInit(modulesConfig.beforeInit);
      ganttInitModules.init.modules.init(modulesConfig.init);
      ganttInitModules.init.modules.dependencyInit(modulesConfig.dependencyInit);
      ganttInitModules.init.modules.afterInit(modulesConfig.afterInit);
    },
    modules: {
      beforeInit(modules) {
        _.each(modules, module => module.init.beforeInit(dpInitor.initOnce()));
      },
      init(modules) {
        _.each(modules, module => module.init.run());
      },
      dependencyInit(dependencies) {
        _.each(dependencies, dependency => dependency.module.init.afterInit(dependency.callBack));
      },
      afterInit(modules) {
        _.each(modules, module => module.init.afterInit());
      },
    },
    taskTextTemplateContent: moduleTextAlign.init.taskTextTemplateContent,
    taskLeftSideTemplate: moduleTextAlign.init.leftSideText,
    taskRightSideTemplate: moduleTextAlign.init.rightSideText,
  },
};

const dpInitor = {
  initOnce() {
    if (this.dp) {
      return this.dp;
    }

    const gantt = window.gantt;

    gantt.config.csp = true; // https://docs.dhtmlx.com/gantt/api__gantt_csp_config.html

    if (GT && GT.appMode.isBase) {
      gantt.config.reorder_grid_columns = true;
    }

    gantt.init($$('dhxView-gantt').$view.firstChild);

    this.dp = new gantt.dataProcessor('/api/');

    const dp = this.dp;

    this.dp.init(gantt);
    this.dp.setTransactionMode({
      mode : "REST",
      headers:{
        "socketid": app.socket.id
      }
    });

    this.dp.attachEvent('onBeforeDataSending', function (id, state, data) {
      let childrenList = [];

      if(this._headers?.socketid !== app.socket.id) {
        this._headers.socketid = app.socket.id
      }

      if (!state.length || !app.config.mode.isBase || data.type === gantt.config.types.button) {
        return false;
      }

      if (data.callaborationAdd) {
        const task = gantt.isTaskExists(id) && gantt.getTask(id);

        if (task) {
          task.skipShowing = true;
          delete task.callaborationAdd;
        }

        this.setUpdated(id, false);
        delete data.callaborationAdd;

        return false;
      }

      if (state === 'inserted') {
        if (!data.type && data.taskType !== 'import') {
          return false;
        }

        data.gantt_id = data.gantt_id || ganttSettings.ganttId;
        data.sid = id;
        data.open = +data.open;

        if (data.source && data.target) {
          data.gantt_id = gantt.getTask(data.target).gantt_id;
        }

        if (!data.source && !data.target) {
          data.open = 1;
          childrenList = gantt.getChildren(data.parent);

          if (data.taskType !== 'import' && !data.sortorder) {
            data.sortorder = gantt.taskDataStoreIndex(childrenList[childrenList.length - 1]) - 1;
          }

          delete data.taskType;
        }
      }

      if (state === 'order') {
        data.sid = id; // fix add sid to post data
        this.setUpdated(id, false, state);

        return false;
      }

      if (state === 'updated') {
        const taskParams = gantt.isTaskExists(id) && gantt.getTask(id);

        if (taskParams) {
          if (data.type === gantt.config.types.task) {
            data.duration = data.duration || 1;
          }

          if (data.open !== taskParams.open) {
            data.open = taskParams.$open;
          }

          if (!data.sortorder) {
            data.sortorder = gantt.taskDataStoreIndex(taskParams.id);
          }

          data.sid = id; // fix add sid to post data
          data.gantt_id = data.gantt_id || ganttSettings.ganttId;
          data.open = +data.open;
        }
      }

      if (isNaN(data.open)) {
        data.open = 0;
      }

      return true;
    });

    this.dp.attachEvent('onAfterUpdate', (id, action, tid, response) => {
      let taskData = {};
      let linkData = {};

      if (response.error && action === 'inserted') {
        setTimeout(() => dp.ignore(() => {
          gantt.deleteTask(id);
          app.trigger('refresh:keyboardNavigation');
        }));
        webix.message({
          type: 'error',
          text: __('error_task_create'),
        });
        throw new Error('Task creating server error');
      }

      if (response.message && action === 'updated') {
        webix.message({ type: "error", text: __('error_tasks_api_operation')});
        throw new Error(`Tasks updating server error on '${response.details.context}'`);
      }

      if (response.message && action === 'inserted' && gantt.isLinkExists(id)) {
        userExtAnalytics.log('gantt_link_error', { gantt_id: gantt.config.gantt_id });
        webix.message({ type: "error", text: __('error_links_api_operation')});
        throw new Error(`Links creating server error on '${response.details.context}'`);
      }

      if (action === 'inserted') {
        taskData = gantt.isTaskExists(tid) && gantt.getTask(tid);
        linkData = gantt.isLinkExists(tid) && gantt.getLink(tid);
        taskData && globalStore.dispatch('tasksModel/addTask', taskData);
        linkData && globalStore.commit('tasksModel/addLink', linkData);
      } else if (action === 'updated') {
        linkData = gantt.isLinkExists(tid) && gantt.getLink(tid);

        linkData && globalStore.commit('tasksModel/updateLink', linkData);

        taskData = gantt.isTaskExists(tid) && gantt.getTask(tid);

        if (taskData) {
          delete taskData.silent;
          delete taskData.muteNotify;
          delete taskData.isHidden;
          delete taskData.multiview_id;
        }
      }
    });

    window.setInterval(() => {
      ganttInit.configurations.currentDayMarker(gantt.config.current_day_marker);
    }, 600000);

    (function updateOnDnD() { // very important modification //never change this
      const timeline = gantt.$ui.getView('timeline');
      const getPos = timeline.getItemPosition;
      const dndCoords = timeline._tasks_dnd._drag_task_coords;

      timeline.getItemPosition = function (task, start_date, end_date) {
        const state = gantt.getState();

        if (+task.id !== +state.drag_id) {
          return getPos.call(this, gantt.__scaleTask(task), start_date, end_date);
        }
        const isProgressOrResize = _.includes(['progress', 'resize'], state.drag_mode);

        if (isProgressOrResize && +state.drag_id === +task.id) {
          const copy = gantt.__scaleTask(task);

          if (state.drag_mode === 'resize') {
            if (state.drag_from_start) {
              copy.start_date = task.start_date;
            } else {
              copy.end_date = task.end_date;
            }
          }

          return getPos.call(this, copy, start_date, end_date);
        }

        return getPos.apply(this, arguments);
      };

      timeline._tasks_dnd._drag_task_coords = function (task, dnd) {
        return dndCoords.call(this, gantt.__scaleTask(task), dnd);
      };

      timeline._tasks_dnd._fix_dnd_scale_time = function (task, drag) {
        let unit = gantt.getScale().unit;
        let step = gantt.getScale().step;

        if (!gantt.config.round_dnd_dates) {
          unit = 'minute';
          step = gantt.config.time_step;
        }

        function fixStart(task) {
          if (!gantt.isWorkTime(task.start_date, undefined, task)) {
            task.start_date = gantt.getClosestWorkTime({
              date: task.start_date,
              dir: 'future',
              task,
            });
          }
        }

        function fixEnd(task) {
          if (!gantt.isWorkTime(task.end_date, undefined, task)) {
            if (new Date(task.end_date).valueOf() <= new Date(task.start_date).valueOf()) {
              task.end_date = gantt.calculateEndDate(task.start_date, 1);
            } else {
              task.end_date = gantt.getClosestWorkTime({
                date: task.end_date,
                dir: unit === 'hour' ? 'future' : 'past',
                task,
              });
            }
          }
        }

        if (drag.mode == gantt.config.drag_mode.resize) {
          if (drag.left) {
            task.start_date = gantt.roundDate({
              date: task.start_date,
              unit,
              step,
            });
            fixStart(task);
          } else {
            task.end_date = gantt.roundDate({
              date: task.end_date,
              unit,
              step,
            });
            fixEnd(task);
          }
        } else if (drag.mode == gantt.config.drag_mode.move) {
          task.start_date = gantt.roundDate({
            date: task.start_date,
            unit,
            step,
          });
          fixStart(task);

          task.end_date = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: task.duration,
            task,
            unit: gantt.config.duration_unit,
          });
        }
      };

      timeline._tasks_dnd._update_on_move = function (e) {
        const drag = this.drag;
        const config = timeline.$getConfig();

        if (drag.mode) {
          const pos = gantt.getRelativeEventPosition(e, gantt.$task_data);

          if (drag.pos && drag.pos.x == pos.x) return;

          drag.pos = pos;

          const curr_date = gantt.dateFromPos(pos.x);

          if (!curr_date || isNaN(curr_date.getTime())) return;

          let shift = pos.x - drag.start_x;
          const ev = gantt.getTask(drag.id);

          if (this._handlers[drag.mode]) {
            if (gantt.config.drag_project && drag.mode === config.drag_mode.move && ev && gantt.isSummaryTask(ev)) {
              const initialDrag = {};

              initialDrag[drag.id] = gantt.copy(drag);

              const dragMultiple = this.dragMultiple;

              _.each(dragMultiple, (obj, key) => {
                if (gantt.getTask(key).type === gantt.config.types.button) {
                  delete dragMultiple[key];
                }
              });

              // gantt.config.disableScale = true;
              const maxShift = this._find_max_shift(gantt.mixin(initialDrag, this.dragMultiple), shift);

              if (maxShift !== undefined) {
                shift = maxShift;
              }

              this._update_item_on_move(shift, drag.id, drag.mode, drag, e);
              for (const i in this.dragMultiple) {
                const childDrag = this.dragMultiple[i];

                this._update_item_on_move(shift, childDrag.id, childDrag.mode, childDrag, e);
              }
              // gantt.config.disableScale = false;
            } else {
              this._update_item_on_move(shift, drag.id, drag.mode, drag, e);
            }
          }
        }
      };

      timeline._tasks_dnd.on_mouse_move = function (e) {
        const drag = this.drag;

        if (drag.start_drag) {
          const task = gantt.getTask(drag.start_drag.id);
          const ganttId = task.gantt_id;
          const canMoveAndResizeTask = rights.project.hasRight(ganttId, 'static_fields');
          const canChangeProgressTask = rights.project.hasRightSomeOne(ganttId, ['static_fields', 'static_fields_4_only']);

          if (
              drag.start_drag.mode === gantt.config.drag_mode.move && !canMoveAndResizeTask ||
              drag.start_drag.mode === gantt.config.drag_mode.resize && !canMoveAndResizeTask ||
              drag.start_drag.mode === gantt.config.drag_mode.progress && !canChangeProgressTask
          ) {
            return;
          }

          this._start_dnd(e);
        }

        if (drag.mode) {
          if (!gantt._checkTimeout(this, 30)) { // limit update frequency
            return;
          }

          this._update_on_move(e);
        }
      };

      timeline._tasks_dnd._finalize_mouse_up = function (taskId, config, drag, e) {
        const ev = gantt.getTask(taskId);

        if (config.work_time && config.correct_work_time) {
          this._fix_working_times(ev, drag);
        }

        this._fix_dnd_scale_time(ev, drag);

        if (!this._fireEvent('before_finish', drag.mode, [taskId, drag.mode, gantt.copy(drag.obj), e])) {
          // drag.obj._dhx_changed = false;
          this.clear_drag_state();
          if (taskId == drag.id) {
            drag.obj._dhx_changed = false;
            gantt.mixin(drag.obj, ev, true);
          }

          // gantt.refreshTask(ev.id);
        } else {
          const drag_id = taskId;

          gantt._init_task_timing(ev);

          this.clear_drag_state();
          // gantt.refreshTask(ev.id);
          this._fireEvent('after_finish', drag.mode, [drag_id, drag.mode, e]);
        }

        return ev;
      };

      timeline._tasks_dnd.on_mouse_up = function (e) {
        const drag = this.drag;

        if (drag.mode && drag.id) {
          const config = timeline.$getConfig();
          const tasksToUpdateIDs = [];

          lastUpdatedTasks = [];

          // drop
          const ev = gantt.getTask(drag.id);
          const dragMultiple = this.dragMultiple;
          let dragProject = false;

          if (gantt.isSummaryTask(ev) && config.drag_project && drag.mode === config.drag_mode.move) {
            for (const i in dragMultiple) {
              this._finalize_mouse_up(dragMultiple[i].id, config, dragMultiple[i], e);
              tasksToUpdateIDs.push(dragMultiple[i].id);
              dragProject = true;
            }
          }

          if (drag && drag.obj) {
            drag.obj.start_date = ev.start_date;
            drag.obj.end_date = ev.end_date;
            drag.obj.duration = ev.duration;
          }

          const updatedTask = this._finalize_mouse_up(drag.id, config, drag, e);

          drag.mode === config.drag_mode.move && userExtAnalytics.log('gantt_task_timeline_drop');
          drag.mode === config.drag_mode.progress && userExtAnalytics.log('gantt_task_timeline_progress_changed');
          drag.mode === config.drag_mode.resize && userExtAnalytics.log('gantt_task_timeline_resize');

          if (drag.mode === config.drag_mode.progress) {
            gantt.callEvent('changeProgress', [updatedTask]);
          } else {
            !dragProject && gantt.updateTask(drag.id);
          }

          if (tasksToUpdateIDs.length > 0) {
            const tasksToUpdate = tasksToUpdateIDs.map(id => gantt.getTask(id));
            tasksToUpdate.forEach(task => {
              task.type !== constants.TASK_TYPES.project && gantt.refreshTask(task.id)
            })

            globalStore.dispatch('tasksModel/updateTasksAfterDragProjectDates', {
              ganttId: tasksToUpdate[0].gantt_id,
              ganttData: tasksToUpdate,
              actionHash: historyModel.generateActionHash(),
              taskName: updatedTask.text,
              updatedTask,
            });
            gantt.callEvent('checkTimelineDates', [ev]);
          }
        }
        this.clear_drag_state();
      };

      gantt.attachEvent('onParse', () => {
        gantt.eachTask(task => {
          if (task.type === gantt.config.types.project || task.type === gantt.config.types.button) {
            gantt.resetProjectDates(task);
          }
        });
      });
    }());
    gantt.attachEvent('onTaskLoading', task => {
      if (task.type === 'project') {
        task.bar_height = 10;
      }

      return true;
    });

    (function linksRender() {
      const timeline = gantt.$ui.getView('timeline');
      const getTaskPosition = timeline.getItemPosition;

      timeline.getItemPosition = function (task) {
        const position = getTaskPosition.apply(this, arguments);

        if (task.type === 'project') {
          position.top += 8;
          // task.bar_height = 10;
          // position.height = 10;
        }

        return position;
      };
    }());
    gantt.silentUpdateTask = function (taskId, taskData) {
      if (gantt.isTaskExists(taskId)) {
        let taskGanttData = gantt.isTaskExists(taskId) && gantt.getTask(taskId);

        if (taskGanttData) {
          taskGanttData = _.assign(taskGanttData, _.omit(taskData, ['$source', '$target']));

          // dirty hack
          gantt._init_task_timing(taskGanttData);

          gantt.silent(() => {
            gantt.$data.tasksStore.updateItem(taskId, taskGanttData);

            gantt._update_parents(taskId);
          });
        }

        gantt.$data.tasksStore.refresh(taskId);
        collaborationComponent.refreshParents(taskId);

        gantt.callEvent('checkTimelineDates', [taskData]);
      }
    };

    gantt.silentDeleteTask = function (taskId, muteRefresh) {
      if (gantt.isTaskExists(taskId)) {
        if (gantt.isTaskExists(taskId)) {
          gantt.silent(() => {
            gantt.$data.tasksStore.removeItem(taskId);
          });
        }

        // gantt.callEvent("afterSilentChanges"); // !DEPRECATE

        !muteRefresh && gantt.refreshData();
      }
    };

    gantt.silentDeleteTaskCollab = function (taskId, muteRefresh) {
      if (gantt.isTaskExists(taskId)) {
        gantt._dp.setUpdateMode('off');
        gantt.silent(() => {
          gantt.deleteTask(taskId);
        });
        gantt._dp.setUpdateMode('cell');
      }

      !muteRefresh && gantt.refreshData();
    };

    gantt.silentDeleteTasks = function (ganttId, taskIds) {
      if (ganttId === ganttSettings.ganttId) {
        gantt._dp.setUpdateMode('off');
        taskIds.forEach(idTask => {
          if (gantt.isTaskExists(idTask)) {
            const children = gantt.getChildren(gantt.getTask(idTask).parent);

            gantt.silent(() => {
              gantt.deleteTask(idTask);
            });

            if (children.length > 1) {
              const button = gantt.getTask(children[children.length - 1]);

              if (button && button.type === constants.TASK_TYPES.button) {
                button.$wbs = '';// for recalculating button wbs after silent delete
              }
            }
          }
        });

        gantt._dp.setUpdateMode('cell');
      }
    };

    gantt.silentUpdateTasks = function (ganttId, updTasksData) {
      if (ganttId === ganttViewModel.getActiveGanttId()) {
        gantt._dp.setUpdateMode('off');

        updTasksData.forEach(updTaskEntry => {
          const __idTask = updTaskEntry.id;

          if (gantt.isTaskExists(__idTask)) {
            const __taskEntry = gantt.getTask(__idTask);

            gantt.updateTask(__idTask, {
              ...__taskEntry,
              ...updTaskEntry,
            });
          }
        });

        gantt._dp.setUpdateMode('cell');
      }
    };

    gantt.silentAddLink = function (linkId, linkData) {
      const ganttIDs = ganttViewModel.getGanttIDs();

      if (_.includes(ganttIDs, +linkData.gantt_id)) {
        gantt.silent(() => {
          gantt.addLink(linkData);
          gantt.$data.linksStore.fullOrder.push(linkId);
          gantt.$data.linksStore.visibleOrder.push(linkId);
          gantt.sync_link(linkData);
        });

        gantt.refreshLink(linkId);
        gantt.refreshTask(linkData.target);
      }
    };

    gantt.silentUpdateLink = function (linkId, linkData) {
      const ganttIDs = ganttViewModel.getGanttIDs();

      if (_.includes(ganttIDs, +linkData.gantt_id)) {
        gantt.silent(() => {
          gantt.updateLink(linkId, linkData);
          gantt.$data.linksStore.updateItem(linkId, linkData);
          gantt.sync_link(linkData);
        });
        gantt.refreshLink(linkId);

        // gantt.callEvent("afterSilentChanges");// !DEPRECATE

        // gantt.refreshData();
      }
    };

    gantt.silentDeleteLink = function (linkId, linkData) {
      const ganttIDs = ganttViewModel.getGanttIDs();

      if (_.includes(ganttIDs, +linkData.gantt_id)) {
        gantt.silent(() => {
          gantt.$data.linksStore.removeItem(linkId);
          gantt.deleteLink(linkId);
          gantt.refreshTask(linkData.source);
          gantt.refreshTask(linkData.target);
          gantt.sync_link_delete(linkData);
        });

        gantt.sync_links();

        gantt.refreshLink();
        gantt.refreshTask(linkData.source);
        gantt.refreshTask(linkData.target);

        // gantt.callEvent("afterSilentChanges"); // !DEPRECATE

        // gantt.refreshData();
      }
    };

    let counter = 0;

    gantt.updateTasks = function (tasksArray, masterType, actionHash, taskName) {
      const cloneArray = _.filter(tasksArray, task => task.type !== gantt.config.types.button && task.id !== 1);
      const isEqualWithLastUpdated = customHelper.compareArraysByFiled(cloneArray, lastUpdatedTasks, 'id');

      if (isEqualWithLastUpdated) {
        counter++;

        if (counter > 5) {
          gantt.callEvent('onAutoScheduleCircularLink', [[], lastUpdatedTasks]);
          lastUpdatedTasks = [];

          return Promise.resolve();
        }
      } else {
        counter = 0;
      }

      if (!_.isEmpty(cloneArray)) {
        return globalStore.dispatch('tasksModel/updateTasksByEvent', {
          updatedTasks: cloneArray,
          masterType,
          actionHash,
          taskName,
        }).then(() => {
          if (masterType === 'progress') {
            _.each(cloneArray, tA => {
              gantt.isTaskExists(tA.id) && gantt.refreshTask(tA.id);
            });
          }

          if (masterType !== 'progress') {
            // gantt.callEvent("ganttRender");

            gantt.callEvent('afterTasksUpdated', [masterType, cloneArray]);
            gantt.callEvent('tasks:correctDates', [true]);
          }
        })
          .catch(error => {
            console.warn('ERROR:', error);
          });
      }

      return Promise.resolve();
    };

    gantt.getTaskName = function (item) {
      if (gantt.config.multiview) {
        const isTotalEstimate = item.$level === 1;
        const isMainProject = item.$level === 0;

        if (isTotalEstimate) {
          const projectData = projectsModel.getProjectDataById(item.gantt_id);

          return projectData.name;
        } if (isMainProject) {
          const viewData = ganttViewModel.getActiveViewData();

          return viewData.name;
        }

        return item.text;
      }
      const isTotalEstimate = item.$level === 0;

      if (isTotalEstimate) {
        const projectData = projectsModel.getProjectDataById(item.gantt_id);

        return projectData.name;
      }

      return item.text;
    };

    Gantt.plugin(gantt => {
      gantt.hasRight = (ganttId, bitKey) => {
        return rights.project.hasRight(ganttId, bitKey);
      };
      gantt.hasRightSomeOne = (ganttId, bitKeys) => {
        return rights.project.hasRightSomeOne(ganttId, bitKeys);
      };
      gantt.hasRightToEditField = (ganttId, fieldName) => {
        const staticFields = ['attachments', 'text', 'resource_id', 'start_date', 'end_date', 'duration', 'estimation', 'time_tracking', 'progress', 'status', 'priority'];
        const staticFieldsFourOnly = ['attachments', 'time_tracking', 'progress', 'status'];
        const canEditStaticFields = rights.project.hasRight(ganttId, 'static_fields') && staticFields.includes(fieldName);
        const canEditStaticFieldsFourOnly = rights.project.hasRight(ganttId, 'static_fields_4_only') && staticFieldsFourOnly.includes(fieldName);
        const canEditCustomFields = rights.project.hasRight(ganttId, 'custom_field_edit') && fieldName.includes('custom');
        const canEditTaskLinks = rights.project.hasRight(ganttId, 'task_links') && fieldName === 'predecessors';
        const canEditBudget = gantt.canEditBudget(ganttId, fieldName);

        return canEditStaticFields || canEditStaticFieldsFourOnly || canEditCustomFields || canEditTaskLinks || canEditBudget;
      };
      gantt.canEditBudget = (ganttId, fieldName) => {
        return ['total_price', 'actual_cost'].includes(fieldName) && !projectsModel.getProjectDataById(ganttId)?.config.auto_budget;
      };
      gantt.hasAccessToTask = task => {
        if (!task) return false;
        if (rights.project.hasRight(task.gantt_id, 'all_tasks')) return true;

        const userId = rights.getUserId();
        const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](userId);

        return task.resources?.find(r => r.resource_id === resourceData.id);
      };
    });

    gantt.getTasksFilterState = function () {
      return ganttSettings.filterData;
    };

    gantt.attachEvent('onGanttScroll', _.debounce((oldX, oldY, newX, newY) => {
      if (newY === oldY) {
        return;
      }

      const old_tasks = gantt.$container.querySelector('.row_hover');

      _.each(old_tasks, t => {
        gantt.removeHoverForTaskRow(t);
      });
    }, 50));

    gantt.attachEvent('onGanttScroll', (oldX, oldY, newX, newY) => {
      if (newY === oldY) {
        return;
      }

      ganttHandlers.ganttScrollClosePopup();
    });

    gantt.ganttWorker = moduleGanttWorker;
    gantt.ganttWorker.init();

    return this.dp;
  },
  initLayers(gantt) {
    const renderDrawDeadline = function (task) {
      if (task.deadline && task.deadline.valueOf()) {
        const viewData = ganttViewModel.getActiveViewData();
        const filterData = filtersModel.getFilterDataByActiveProject(viewData.id);
        const format = helperDate.getDateFormatForGanttGrid();
        const el = document.createElement('div');
        const sizes = gantt.getTaskPosition(task, task.deadline);

        el.className = 'deadline';
        el.innerHTML = deadline_icon;

        if (filterData && filterData.options.rangeDate && filterData.options.rangeDate.start) {
          el.style.left = `${sizes.left + 2}px`;
        } else {
          el.style.left = `${sizes.left + 8}px`;
        }
        if (task.type === 'project') {
          el.style.top = `${sizes.top + ((gantt.config.row_height - 36) / 2)}px`;
        } else {
          el.style.top = `${sizes.top + ((gantt.config.row_height - 26) / 2)}px`;
        }

        el.setAttribute('title', gantt.date.date_to_str(format)(task.deadline));

        return el;
      }

      return false;
    };
    const getDeadLineRect = function (item, view) {
      if (!item.deadline) {
        return null;
      }

      return {
        top: view.getItemTop(item.id),
        height: view.getItemHeight(item.id),
        left: gantt.posFromDate(item.deadline),
        right: gantt.posFromDate(item.deadline) + 100,
      };
    };
    const renderOverdueTask = function (task) {
      const checkIsOverdue = moment(task.end_date).isBefore() && task.progress < 1 && task.$level !== 0 && task.type === 'task';

      if (checkIsOverdue && gantt.config.highlight_overdue) {
        const el = document.createElement('div');

        el.className = 'highlightOverdue';
        const sizes = gantt.getTaskPosition(task);

        el.style.top = `${sizes.top}px`;
        el.style.width = `${100}%`;
        el.style.height = `${gantt.config.row_height}px`;

        return el;
      }
    };

    const cutAreaLeft = function (task) {
      const timeline = gantt.$ui.getView('timeline').getScale();
      const scaleStart = timeline.min_date;

      if (task.type !== 'button' && gantt.config.start_date) {
        const leftCondition = task.start_date.valueOf() < scaleStart.valueOf() && task.end_date.valueOf() > scaleStart.valueOf();

        return leftCondition && ganttHelpers.renderCutArea(task, 'left');
      }
    };
    const cutAreaRight = function (task) {
      const timeline = gantt.$ui.getView('timeline').getScale();
      const scaleEnd = timeline.max_date;

      if (task.type !== 'button' && gantt.config.end_date) {
        const rightCondition = task.start_date.valueOf() < scaleEnd.valueOf() && task.end_date.valueOf() > scaleEnd.valueOf();

        return rightCondition && ganttHelpers.renderCutArea(task, 'right');
      }
    };

    gantt.addTaskLayer({
      renderer: {
        render: renderDrawDeadline,
        getRectangle: getDeadLineRect,
        getVisibleRange: gantt.getVisibleTasksRange,
      },
    });
    gantt.addTaskLayer({
      renderer: {
        render: renderOverdueTask,
        getVisibleRange: gantt.getVisibleTasksRange,
      },
    });
    gantt.addTaskLayer({
      renderer: {
        render: cutAreaRight,
        getVisibleRange: gantt.getVisibleTasksRange,
      },
    });
    gantt.addTaskLayer({
      renderer: {
        render: cutAreaLeft,
        getVisibleRange: gantt.getVisibleTasksRange,
      },
    });
  },
  initHandlers(gantt) {
    if (this.handlers) return this.handlers;

    this.handlers = [];

    gantt.attachEvent('onEmptyClick', e => ganttHandlers.onEmptyClick(e));

    gantt.attachEvent('onBeforeTaskDrag', (taskId, mode, e) => ganttHandlers.onBeforeTaskDrag(taskId, mode, e));

    gantt.attachEvent('onBeforeGanttRender', () => ganttHandlers.onBeforeGanttRender());

    gantt.attachEvent('onBeforeDataRender', () => ganttHandlers.onBeforeDataRender());

    gantt.attachEvent('onTaskLoading', task => ganttHandlers.onTaskLoading(task));

    gantt.attachEvent('onBeforeTaskDisplay', (id, task) => ganttHandlers.onBeforeTaskDisplay(id, task));

    gantt.attachEvent('onBeforeLightbox', (id, selectComment, selectAttachments) => ganttHandlers.onBeforeLightbox(id, selectComment, selectAttachments));

    gantt.attachEvent('ganttClearAndParse', (ganttTasks, restoreScrollState) => ganttHandlers.ganttClearAndParse(ganttTasks, restoreScrollState));

    gantt.attachEvent('onGanttRender', () => ganttHandlers.onGanttRender());

    gantt.attachEvent('onMouseMove', (id, e) => {
      const notTaskDND = gantt.getState().drag_mode !== 'move' && gantt.getState().drag_mode !== 'resize';

      if (!gantt.config.dndMove && notTaskDND) {
        if (id) {
          const task = gantt.getTask(id);

          if (task && task.type === gantt.config.types.button) {
            gantt.setHoverTaskRow();
          } else {
            gantt.setHoverTaskRow(id);
          }
        } else {
          gantt.setHoverTaskRow(id);
        }
      }
    });

    gantt.attachEvent('checkTimelineDates', task => {
      const isNearEndDate = new Date(task.end_date).valueOf() >= new Date(gantt.config.end_date).valueOf();
      const isNearStartDate = new Date(task.start_date).valueOf() <= new Date(gantt.config.start_date).valueOf();

      if (isNearEndDate || isNearStartDate) {
        gantt.render();

        return true;
      }

      return false;
    });

    app.on('gantt:reinit', () => {
      reinitGantt.run();
    });

    app.on('gantt:afterCollaboration', () => {
      reinitGantt.afterCollaboration();
    });

    // app.on('user:changedRole', () => {
    //   reinitGantt.run();
    // });

    app.on('gantt:reinitConfig', () => {
      reinitGantt.reinitConfig();
    });

    app.on('activeProject:set', async (ganttId, ganttData) => {
      if (!ganttData) {
        return;
      }
      
      const route = routerHelper.getCurrentRoute();
      
      if(route?.params.mode !== "overview") {
        app.trigger('gantt:progress:show');
      }
      

      ganttSettings.ganttId = +ganttId;
      ganttViewModel.reInit(ganttSettings.ganttId);

      const projectConfig = _.cloneDeep(userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(+ganttId, ganttData.config));

      if (!projectConfig.userSkin && ganttData.userSkin) {
        ganttHelpers.removeSkinClass();
        projectConfig.userSkin = ganttData.userSkin;
      }

      moduleDisableGantt.init.showCommonLeftSideBarButtons();

      await ganttHelpers.checkAndRequireProjectData([ganttId]);

      const callBack = function () {
        _.delay(() => {
          if (gantt.config.isMpp && !gantt.serialize().data.length) {
            mppModel.linkGanttIdWithImportedMpp(ganttId, ganttData.config.mppToken);
            // just created mpp file from inport
          }
          else if (rights.account.isOwner() || rights.account.isKingMode() || rights.project.getCurrentUserRole(ganttId)) {
            app.trigger('gantt:progress:hide', true);
          } // we don't have to hide loader in case of no tasks added (case of mpp import)
          else {
            let interval = null;

            interval = setInterval(() => {
              const role = rights.project.getCurrentUserRole(ganttId);

              if (role) {
                clearInterval(interval);
                app.trigger('gantt:progress:hide', true);
              }
            }, 200);
          }

          try {
            if (ganttSettings.ganttId !== ganttViewModel.globalGanttId) {
              // app.trigger('activeProject:afterSet', ganttSettings.ganttId, ganttData);
            }
          } catch (e) {
            console.error('catch activeProject:set');
            ganttViewModel.setActiveProject(ganttId);
          } finally {
            gantt.config.reiniting = false;
          }
        }, 50);
      };

      try {
        gantt.config.multiview_id = null;
        reinitGantt.run(ganttId, projectConfig, callBack, true);
      } catch (e) {
        console.error('catch reinitGantt.run');
        ganttViewModel.setActiveProject(ganttId);
      }
    });

    app.on('activeMultiProjects:set', async (id, ganttIds) => {
      app.trigger('gantt:progress:show');
      globalStore.commit('filter/setIsDisable', false);

      await ganttHelpers.checkAndRequireProjectData(ganttIds);

      const callBack = function () {
        gantt.config.reiniting = false;
        app.trigger('gantt:progress:hide');
        app.trigger('activeMultiProjects:afterSet', ganttIds);
      };

      reinitGantt.run(ganttIds, undefined, callBack, true);
    });

    window.addEventListener('resize', (e) => {
      const gridArea = document.querySelector('.gantt_grid_data');

      if (gridArea && document.documentElement.clientWidth <= gridArea.clientWidth + globalStore.getters['leftSidebarWidth'] + 18) {
        gantt.config.grid_width = document.documentElement.clientWidth - globalStore.getters['leftSidebarWidth'] - 18;
      }

      _.debounce(() => {
        gantt.callEvent('ganttRender');
        app.trigger('body:resize');
      }, 200);
    });

    return this.handlers;
  },
};

const ganttInit = {
  async initMultiView(multiView) {
    if (routerHelper.isMultiProjectsRoute() || routerHelper.isWorkloadViewRoute()) {
      app.trigger('gantt:progress:show');
    }
    const commonConfig = ganttViewModel.getProjectConfig();
    ganttInit.initConfiguration(commonConfig, commonConfig.userSkin);

    ganttInitModules.init.run();

    if (multiView.ganttIDs) {
      await ganttHelpers.checkAndRequireProjectData(multiView.ganttIDs);
    } else {
      console.error('Error 6235: ganttIds is not valid');
    }

    dpInitor.initLayers(gantt);
    dpInitor.initHandlers(gantt);

    const preparedTasks = ganttViewModel.getTasks();

    ganttHandlers.ganttClearAndParse(preparedTasks);

    ganttInitFilter.beforeApplyFilter();

    app.trigger('init:multiview');
    ganttInitFilter.initHandlers();
    app.trigger('gantt:init:after');
    ganttHandlers.changeSkin(commonConfig.userSkin);
    // app.trigger('gantt:progress:hide', true);
    window.addEventListener('resize', () => {
      gantt.callEvent('ganttRender');
    });
  },
  async initProject(project) {
    if (routerHelper.isGanttRoute() || routerHelper.isWorkloadViewRoute()) {
      app.trigger('gantt:progress:show');
    }

    const ganttConfig = ganttViewModel.getProjectConfig();
    app.trigger('gantt:init:before');
    ganttSettings.ganttId = +project.gantt_id;

    ganttViewModel.reInit(ganttSettings.ganttId);

    ganttInit.initConfiguration(ganttConfig, project.skin);

    ganttInitModules.init.run();

    if (+project.gantt_id >= 0) {
      await ganttHelpers.checkAndRequireProjectData([+project.gantt_id]);
    } else {
      console.error('Error 6234: ganttId is not valid');
    }

    dpInitor.initLayers(gantt);
    dpInitor.initHandlers(gantt);

    if (gantt.config.needUpdateTasksDatesAfterCreateProject) {
      globalStore.dispatch('tasksModel/recalculateStartDatesForTasksByGanttId', { ganttId: ganttSettings.ganttId });
      gantt.config.needUpdateTasksDatesAfterCreateProject = false;
    }

    ganttHandlers.ganttClearAndParse();

    ganttInitFilter.beforeApplyFilter();

    ganttHandlers.afterGanttShowing();

    // ganttInit.currentDayMarker(gantt, ganttConfig.current_day_marker);

    gantt.config.show_grid = true;
    if (!gantt.config.hide_workload) {
      gantt.callEvent("toggleWorkloadGrid", [false]);
    }

    app.trigger('gantt:init:after', ganttSettings.ganttId);

    if (ganttSettings.ganttId !== ganttViewModel.globalGanttId) {
      app.trigger('activeProject:afterSet', ganttSettings.ganttId, project);
    }

    if (ganttConfig.isMpp && !gantt.serialize().data.length) {
      mppModel.linkGanttIdWithImportedMpp(ganttSettings.ganttId, ganttConfig.mppToken);
      // just created mpp file from landing
    }

    // app.trigger('after:runInit');

    if (rights.account.isOwner() || rights.account.isKingMode() || rights.project.getCurrentUserRole(ganttSettings.ganttId)) {
      // app.trigger('gantt:progress:hide', true);
    } else {
      let interval = null;

      interval = setInterval(() => {
        const role = rights.project.getCurrentUserRole(ganttSettings.ganttId);

        if (role) {
          clearInterval(interval);
          // app.trigger('gantt:progress:hide', true);
        }
      }, 200);
    }
  },
  async runInit() {
    app.trigger('gantt:init:before:removePreload');
    if ((app.config.mode.isExport || app.config.mode.isLink) && GT.ganttData.multiviews) {
      const multiView = ganttViewModel.getActiveViewData();

      await ganttInit.initMultiView(multiView);

      return;
    }

    if (routerHelper.isMultiProjectsRoute()) {
      const multiView = ganttViewModel.getActiveViewData();

      await ganttInit.initMultiView(multiView);
    } else {
      const project = ganttViewModel.getActiveProjectData();

      if (!project && rights.account.hasRight('project_create')) {
        routerHelper.pushRoute({ name: 'newProject' });

        return;
      }

      if (!project && !rights.account.hasRight('project_create')) {
        routerHelper.pushRoute({ name: 'noProject' });

        return;
      }

      if (project.import) {
        gantt.config.needUpdateTasksDatesAfterCreateProject = true;
      }

      await ganttInit.initProject(project);
    }

    gantt.config.inited = true;
    gantt.render();

    // app.trigger('gantt:progress:hide', true);
    gantt.config.preInitedRoute && routerHelper.changeRoute(gantt.config.preInitedRoute);

    _.delay(() => {
      ganttHelpers.calcGridWidth();

      if (app.config.mode.isLink) {
        gantt.scrollTo(0, null);
      }
      // gantt.config.preInitedRoute && routerHelper.changeRoute(gantt.config.preInitedRoute);
    });
  },
  initConfiguration(ganttConfig, skinName) {
    ganttInit.configurations.grid();
    ganttConfig.start_monday = user.settings.start_monday;

    reinitGantt.updateGanttConfig(ganttConfig, skinName);
    ganttInit.configurations.setLocale(user.locale || 'en');
    ganttInit.configurations.filter();

    moduleDnD.init.beforeInit();
    moduleWorkload.init.initLayoutConfig(app.config.mode.isExport);

    gantt.formatFunc = gantt.date.date_to_str(helperDate.getDateFormat());
    gantt.timeParser = timeParser;
  },
  configurations: {
    assignInit(ganttConfig) {
      if (ganttConfig) {
        gantt.config = _.assign(gantt.config, _.omit(ganttConfig, ['columns', 'grid_width']));
        if (ganttConfig.multiview) gantt.config.isJira = false;
      }
    },
    base(ganttConfig) {
      gantt.config.current_day_marker = ganttConfig.current_day_marker || true;

      gantt.config.highlight_critical_path = ganttConfig.highlight_critical_path * 1 || false;
      gantt.config.highlight_overdue = ganttConfig.highlight_overdue * 1 || false;
      gantt.config.date_format = ganttSettings.dateFormat;
      gantt.config.touch = ganttSettings.touchEnabel;
      gantt.config.keep_grid_width = ganttSettings.stableGridWidth;
      gantt.config.grid_resize = !ganttSettings.stableGridWidth;
      gantt.config.round_dnd_dates = ganttSettings.roundDndDates;
      gantt.config.details_on_create = false;

      gantt.config.smart_rendering = true;
      gantt.config.static_background_cells = true;
      gantt.config.touch = false;
      gantt.config.smart_scales = true;
      gantt.config.scroll_on_click = true;
      gantt.config.show_unscheduled = true;
      gantt.config.cascade_delete = false;
      gantt.ignore_time = null;
      gantt.config.autoscroll = true;
      gantt.config.autoscroll_speed = 100;
      gantt.config.min_grid_column_width = 50;
      gantt.config.autofit = false;
      gantt.config.drag_project = true;
      gantt.config.initial_scroll = false;
      gantt.config.discoloration_tasks = ganttSettings.discoloration_tasks;
      gantt.config.cross_out_tasks = ganttSettings.cross_out_tasks;
    },
    initBaseLayout() {
      gantt.config.defaultLayout = {
        css: 'gantt_container',
        rows: [{
          cols: [{
            view: 'grid',
            scrollX: 'scrollHor',
            scrollY: 'scrollVer',
          },
          {
            resizer: true,
            width: 1,
          },
          {
            view: 'timeline',
            scrollX: 'scrollHor',
            scrollY: 'scrollVer',
          },
          {
            view: 'scrollbar',
            id: 'scrollVer',
          },
          ],
        },
        {
          height: 20,
          id: 'scrollHor',
          view: 'scrollbar',
        },
        ],
      };
    },
    grid() {
      gantt.templates.grid_open = function (task) {
        if (task.type === gantt.config.types.project) {
          return `<div class="gantt_tree_icon gantt_${task.$open ? 'close' : 'open'}">
          <div class="gantt_tree_icon_item">${task.$open ? expanded : collapsed}
          </div>
        </div>`;
        }

        return "<div class='gantt_tree_icon'><div class='gantt_tree_icon_item'></div></div>";
      };
    },
    showDrag() {
      gantt.config.show_drag_vertical = true;
      gantt.config.show_drag_dates = true;
      gantt.config.drag_label_width = 150;
      gantt.templates.drag_date = null;
      gantt.config.dnd_sensitivity = 2;
    },
    staticBackground() {
      gantt.config.static_background = true;
    },
    durationBase() {
      gantt.config.duration_unit = 'minute';
      gantt.config.duration_step = 1;
      gantt.config.min_duration = gantt.config.duration_step * 60 * 1000;

      gantt.config.time_step = 0;
      gantt.config.scale_offset_minimal = true;
    },
    durationViewMode(viewMode) {
      const viewModeStrategyRow = {
        auto: 30,
        compact: 24,
        touch: 44,
      };

      const viewModeStrategyTask = {
        auto: 22,
        compact: 16,
        touch: 36,
      };

      if (gantt.config.baseline > 0) {
        gantt.config.row_height = viewModeStrategyRow[viewMode] * 2;
      } else {
        gantt.config.row_height = viewModeStrategyRow[viewMode];
      }

      gantt.config.original_row_height = viewModeStrategyRow[viewMode];
      gantt.config.task_height = viewModeStrategyTask[viewMode];
      gantt.config.view_mode = viewMode;

      ganttHelpers.addViewModeClass(Object.keys(viewModeStrategyRow), viewMode);
    },
    criticalPath(criticalPathState) {
      gantt.config.highlight_critical_path = (app.config.mode.isBase || app.config.mode.isExport || app.config.mode.isLink) ? criticalPathState : false;
    },
    autoScheduling(autoSchedulingState) {
      if (routerHelper.isListViewRoute()) {
        autoSchedulingState = false;
      }

      gantt.config.auto_scheduling = autoSchedulingState;
      gantt.config.auto_scheduling_initial = autoSchedulingState;
      gantt.config.auto_scheduling_descendant_links = true;
      gantt.config.auto_scheduling_strict = true;
      gantt.config.auto_scheduling_compatibility = false;
    },
    autoBudget(autoBudgetState) {
      gantt.config.auto_budget = !!autoBudgetState;
    },
    expandButton() {
      gantt.config.expand_button_y = 35;
      gantt.config.expand_button_x = 45;
    },
    showMarkers() {
      gantt.config.show_markers = ganttSettings.enableMarkers;
    },
    leftRightTaskText() {
      gantt.config._letter_size_coefficient = 7;
      gantt.templates.leftside_text = ganttInitModules.init.taskLeftSideTemplate;
      gantt.templates.rightside_text = ganttInitModules.init.taskRightSideTemplate;
      gantt.templates.task_text = ganttInitModules.init.taskTextTemplateContent;
    },
    inlineEditors() {
      gantt.config.inline_editor_free_height = 2;
    },
    ganttLocaleLabels() {
      gantt.locale.labels.type_task = __('gantt_task');
      gantt.locale.labels.type_project = __('gantt_project');
      gantt.locale.labels.type_milestone = __('gantt_milestone');
      gantt.locale.labels.section_assignTo = __('gantt_assign_to');
      gantt.locale.labels.link = __('gantt_link');
      gantt.locale.labels.confirm_link_deleting = __('gantt_confirm_link_deleting');
    },
    initCustomTemplates() {
      gantt.templates.grid_row_class = ganttHelpers.gridTasksTemplate;
      gantt.templates.task_row_class = ganttHelpers.customButtonTemplate;
      gantt.templates.task_class = ganttHelpers.coloredClass;
    },
    initResourceLoadingType(type) {
      gantt.config.resource_loading_type = type || 'hours';
    },
    startOnMonday(startOnMonday) {
      if (!app.config.mode.isBase) {
        gantt.config.start_on_monday = +startOnMonday;
        webix.Date.startOnMonday = gantt.config.start_on_monday || +user.settings.start_monday;

        return;
      }

      if (user.settings && user.settings.start_monday !== null) {
        gantt.config.start_on_monday = +user.settings.start_monday;
      } else {
        gantt.config.start_on_monday = +startOnMonday;
      }
      ganttInit.updateStartMondayBack(gantt.config.start_on_monday);
      webix.Date.startOnMonday = gantt.config.start_on_monday;
    },
    setRightByUserRole() {
      const ganttConfig = ganttViewModel.getProjectConfig();

      if (ganttConfig.isGlobal || !app.config.mode.isBase || ganttSettings.isArchived || massChangeModel.isEnabled()) {
        gantt.config.readonly = true;
      } else {
        gantt.config.readonly = false;
      }
    },
    durationView(durationViewState) {
      gantt.config.duration_view = durationViewState;
      moduleCustomGrids.helpers.updateGanttGrid();
    },
    setSkin(ganttSkin) {
      ganttSettings.ganttSkin = ganttSkin;
    },
    highlightArea(durationData) {
      let needWork = false;
      let ganttTaskLayerMove = 0;
      let datesInDragModeLayer = 0;
      let filterTasksLayer = 0;

      _.each(ganttSettings.currentIdTasksLayout, layerTaskId => {
        gantt.removeTaskLayer(layerTaskId);
        needWork = true;
      });

      ganttSettings.currentIdTasksLayout = [];
      gantt.config.show_drag_vertical = true;
      gantt.templates.drag_date = null;

      gantt.config.drag_date = durationData.mode === 'hour' ? helperDate.getDateFormatForGanttGrid() : helperDate.getDateFormat();
      gantt.templates.drag_date = gantt.date.date_to_str(gantt.config.drag_date);

      const renderHighlightAreaOnDrag = function (task) {
        const state = gantt.getState();
        let copy = gantt.__scaleTask(task);

        if (state.drag_mode === 'resize') {
          if (state.drag_from_start) copy.start_date = task.start_date;
          if (!state.drag_from_start) copy.end_date = task.end_date;
        } else if (state.drag_mode === 'move') {
          copy = task;
        }

        const sizes = gantt.getTaskPosition(copy, copy.start_date, copy.end_date);
        const wrapper = document.createElement('div');

        ganttHelpers.addElement({
          css: 'drag_move_vertical',
          left: `${sizes.left}px`,
          top: 0,
          width: `${sizes.width}px`,
          height: `${gantt.getVisibleTaskCount() * gantt.config.row_height}px`,
          wrapper,
        });

        return wrapper;
      };
      const renderDatesOnDrag = function (task) {
        const state = gantt.getState();
        let paddingStart = 0;
        let paddingEnd = 0;
        const paddongDeltaPx = 15;
        let copy;

        if (state.drag_mode !== 'progress') {
          copy = task;
        } else {
          copy = gantt.__scaleTask(task);
        }

        if (state.drag_mode === 'resize') {
          if (state.drag_from_start) {
            copy.start_date = task.start_date;
            paddingEnd = paddongDeltaPx;
          }
          if (!state.drag_from_start) {
            copy.end_date = task.end_date;
            paddingStart = paddongDeltaPx;
          }
        } else if (state.drag_mode === 'move') {
          copy = task;
        }

        const sizes = gantt.getTaskPosition(copy, copy.start_date, copy.end_date);
        const wrapper = document.createElement('div');

        if (state.drag_mode !== 'progress' && task.type !== 'project') {
          ganttHelpers.addElement({
            css: 'drag_move_start drag_date',
            left: `${sizes.left - gantt.config.drag_label_width - paddingStart}px`,
            top: `${sizes.top}px`,
            width: `${gantt.config.drag_label_width}px`,
            height: `${gantt.config.row_height}px`,
            html: gantt.templates.drag_date(copy.start_date),
            wrapper,
          });

          ganttHelpers.addElement({
            css: 'drag_move_end drag_date',
            left: `${sizes.left + sizes.width + paddingEnd}px`,
            top: `${sizes.top}px`,
            width: `${gantt.config.drag_label_width}px`,
            height: `${gantt.config.row_height}px`,
            html: gantt.templates.drag_date(copy.end_date),
            wrapper,
          });
        }

        return wrapper;
      };
      const filterItemOnDrag = function (task) {
        return gantt.config.show_drag_vertical && task.id == gantt.getState().drag_id;
      };

      ganttTaskLayerMove = gantt.addTaskLayer({
        renderer: {
          render: renderHighlightAreaOnDrag,
          getVisibleRange: gantt.getVisibleTasksRange,
        },
        filter: filterItemOnDrag,
      });

      datesInDragModeLayer = gantt.addTaskLayer({
        renderer: {
          render: renderDatesOnDrag,
          getVisibleRange: gantt.getVisibleTasksRange,
        },
        filter: filterItemOnDrag,
      });

      const renderFilterTasks = function (task) {
        const sizes = gantt.getTaskPosition(task, task.start_date, task.end_date);
        const taskPosition = gantt.getTaskPosition(task);
        const taskWidth = taskPosition.width;
        const wrapper = document.createElement('div');
        const links = gantt.getLinks();
        const connections = _.filter(links, link => (link && (link.target === task.id || link.source === task.id)));
        let leftConnection = false;
        let rightConnection = false;

        connections.forEach(link => {
          if (link.source === task.id) {
            if (link.type === gantt.config.links.finish_to_start || link.type === gantt.config.links.finish_to_finish) {
              rightConnection = true;
            } else if (link.type === gantt.config.links.start_to_finish || link.type === gantt.config.links.start_to_start) {
              leftConnection = true;
            }
          } else if (link.target === task.id) {
            if (link.type === gantt.config.links.finish_to_start || link.type === gantt.config.links.start_to_start) {
              leftConnection = true;
            } else if (link.type === gantt.config.links.finish_to_finish || link.type === gantt.config.links.start_to_finish) {
              rightConnection = true;
            }
          }
        });

        if (rightConnection) {
          ganttHelpers.addElement({
            css: 'filter-link filter-link-right',
            left: `${taskPosition.left + taskWidth}px`,
            top: `${sizes.top + sizes.height / 2}px`,
            html: '',
            wrapper,
          });
        }

        if (leftConnection) {
          ganttHelpers.addElement({
            css: 'filter-link filter-link-left',
            left: `${taskPosition.left - 20}px`,
            top: `${sizes.top + sizes.height / 2}px`,
            html: '',
            wrapper,
          });
        }

        return wrapper;
      };

      filterTasksLayer = gantt.addTaskLayer({
        renderer: {
          render: renderFilterTasks,
          getVisibleRange: gantt.getVisibleTasksRange,
        },
        filter(task) {
          const foundTasks = ganttSettings.filterData.foundTasks;

          if (foundTasks.length === 0) {
            return false;
          }

          let visible = task.type === 'task' && foundTasks.indexOf(task.id) !== -1;
          const links = [];

          task.$source.forEach(linkId => {
            links.push(gantt.getLink(linkId));
          });
          task.$target.forEach(linkId => {
            links.push(gantt.getLink(linkId));
          });

          const hasConnections = links.some(link => foundTasks.indexOf(link.target) === -1
            || foundTasks.indexOf(link.source) === -1);

          if (isNaN(+task.parent) || task.parent === 0) {
            visible = true;
          }

          return visible && hasConnections;
        },
      });

      if (needWork) {
        const layers = gantt.$services.getService('layers').getDataRender('task');

        layers.getLayer(datesInDragModeLayer).clear();
        layers.getLayer(ganttTaskLayerMove).clear();
        layers.getLayer(filterTasksLayer).clear();
      }

      ganttSettings.currentIdTasksLayout.push(datesInDragModeLayer);
      ganttSettings.currentIdTasksLayout.push(ganttTaskLayerMove);
      ganttSettings.currentIdTasksLayout.push(filterTasksLayer);
    },
    setLocale(currentLocale) {
      gantt.locale = { date: {}, labels: { link_start: __('link_start'), link_end: __('link_end') } };
      // from "Styczeń, Luty, Marzec, Kwiecień, Maj, Czerwiec, Lipiec, Sierpień, Wrzesień, Październik, Listopad, Grudzień" to [Styczeń, Luty, Marzec, Kwiecień, Maj, Czerwiec, Lipiec, Sierpień, Wrzesień, Październik, Listopad, Grudzień]
      gantt.locale.date.day_full = __('calendar_days_full').split(',').map(string => string.trim());
      gantt.locale.date.day_short = __('calendar_days_short').split(',').map(string => string.trim());
      gantt.locale.date.month_full  = __('calendar_months_full').split(',').map(string => string.trim());
      gantt.locale.date.month_short = __('calendar_months_short').split(',').map(string => string.trim());
    },
    multiSelect() {
      gantt.config.multiselect = false;
      gantt.config.multiselect_one_level = false;
    },
    rightSideText(rightSideText) {
      if (rightSideText === true) {
        gantt.config.right_side_text = 'rightSideText';
      } else if (rightSideText === false) {
        gantt.config.right_side_text = 'insideText';
      } else {
        gantt.config.right_side_text = rightSideText;
      }
    },
    discolorationTasks(param) {
      if (param === undefined) {
        gantt.config.discoloration_tasks = true;
      } else {
        gantt.config.discoloration_tasks = param;
      }
    },
    crossOutTasks(param) {
      if (param === undefined) {
        gantt.config.cross_out_tasks = true;
      } else {
        gantt.config.cross_out_tasks = param;
      }
    },
    estimationModes(mode) {
      gantt.config.estimation_mode = mode || false;
    },
    applyResourceCalendar(mode) {
      gantt.config.apply_resource_calendar = mode || false;
    },
    initCustomButtons() {
      gantt.config.types.button = 'button';
    },
    initBindStatusAndProgressHelper() {
      gantt.config.bindStatusAndProgress = bindStatusAndProgressHelper;
    },
    progressType(progressType) {
      ganttSettings.progressCalc = progressType || 'duration';
      gantt.config.progress = ganttSettings.progressCalc;
    },
    filter(filterData, ganttTasks, clearFilter) {
      let filter;
      const saveFilterData = {
        data: [],
        links: [],
      };

      ganttSettings.filterData.foundTasks = [];
      ganttSettings.filterData.directEnrty = 0;

      if (filterData) {
        filter = ganttFilterHelper.getFilter(gantt, filterData, ganttTasks, task => {
          ganttHelpers.addFoundTask(task, ganttTasks.data);
        });

        let counterIndex = 0;

        const reserve = function (currentTask, tasks) {
          counterIndex += 1;
          currentTask.left = counterIndex;

          if (currentTask.type !== gantt.config.types.project) {
            counterIndex += 1;
            currentTask.right = counterIndex;

            return;
          }

          const childs = _.filter(tasks, tsk => (+tsk.parent === currentTask.id));

          if (_.isEmpty(childs)) {
            counterIndex += 1;
            currentTask.right = counterIndex;

            return;
          }

          _.each(childs, chl => {
            reserve(chl, ganttTasks.data);
          });

          counterIndex += 1;
          currentTask.right = counterIndex;
        };

        const rootTask = _.find(ganttTasks.data, tsk => !tsk.parent);

        rootTask && reserve(rootTask, ganttTasks.data);

        _.each(ganttTasks.data, tsk => {
          filter(tsk);
        });

        ganttSettings.filterData.foundTasks = _.uniqWith(ganttSettings.filterData.foundTasks);

        _.each(ganttSettings.filterData.foundTasks, taskId => {
          const findTask = _.find(ganttTasks.data, taskData => taskData.id === taskId);

          findTask && saveFilterData.data.push(webix.copy(findTask));
        });

        _.remove(ganttSettings.filterData.foundTasks, taskId => {
          const task = _.find(ganttTasks.data, taskData => taskData.id === taskId);

          if (task.type === gantt.config.types.button) {
            const hasParent = _.includes(ganttSettings.filterData.foundTasks, task.parent);

            if (!hasParent) {
              return true;
            }
          }

          return false;
        });

        _.each(ganttTasks.links, linkData => {
          if (_.includes(ganttSettings.filterData.foundTasks, linkData.source) && _.includes(ganttSettings.filterData.foundTasks, linkData.target)) {
            saveFilterData.links.push(linkData);
          }
        });

        const foundTasksWithoutButtons = _.filter(ganttSettings.filterData.foundTasks, taskId => {
          const task = gantt.getTask(taskId);

          return task.type !== gantt.config.types.button;
        });

        if (foundTasksWithoutButtons.length === 1 && !clearFilter) {
          ganttSettings.filterData.foundTasks = foundTasksWithoutButtons;

          app.trigger('filter:tasks:not:found');
        }

        if (app.config.mode.isExport) {
          filtersModel.updateFoundDataByFilter(ganttSettings.ganttId || gantt.config.multiview_id, webix.copy(saveFilterData));
        }
      }

      return saveFilterData;
    },
    currentDayMarker(currentDayMarker) {
      gantt.config.current_day_marker = currentDayMarker;

      gantt._markers.exists('gantt_today_marker') && gantt._markers.removeItem('gantt_today_marker');

      if (gantt.config.current_day_marker) {
        const formatStr = helperDate.getDateFormatForGanttGrid();

        gantt.addMarker({
          id: 'gantt_today_marker',
          start_date: new Date(),
          css: 'today',
          text: __('gantt_today'),
          title: gantt.date.date_to_str(formatStr)(new Date()),
        });

        return true;
      }
    },
    skipOffTime(skipOffTime) {
      gantt.config.skip_off_time = skipOffTime || false;
    },
    isMultiview(isMultiview) {
      gantt.config.multiview = isMultiview;
    },
    isHideWorkload() {
      gantt.config.hide_workload = moduleWorkload.helpers.isWorkloadHidden();
    },
    showResourceAvatar(showResourceAvatar) {
      gantt.config.show_resource_avatar = showResourceAvatar || false;
    },
    overdueHighlight(highlightOverdue) {
      gantt.config.highlight_overdue = ganttHelpers.checkPermission('overdue_tasks') ? highlightOverdue || false : false;
    },
  },
  updateStartMondayBack: startOnMonday => {
    if (user.settings) {
      if (+user.settings.start_monday === +startOnMonday) {
        return;
      }

      user.settings.start_monday = startOnMonday;
    }


    globalStore.dispatch('userNotification/updateUserSettings', { data :  { start_monday: startOnMonday} });

    // userNotificationsModel.updateUserSettings({ start_monday: startOnMonday });
    // if (user.settings) { user.settings.start_monday = startOnMonday; }
    // projectsModel.checkParamsToUpdateAndUpdate({key: "start_on_monday", value: startOnMonday});
  },
  archiveProject(ganttId) {
    /// TODO: MultiView

    ganttSettings.isArchived = ganttViewModel.isArchived ? ganttViewModel.isArchived(ganttId) : false;

    moduleDisableGantt.disableGanttChart(ganttSettings.isArchived, ganttSettings.isArchived);
  },
};

var reinitGantt = {
  afterCollaboration(ganttId, projectConfig, callback) {
    if (gantt.isEditingState()) {
      reinitGantt.callAfterEditing(() => {
        reinitGantt.run(ganttId, projectConfig);
      });

      return;
    }

    let firstVisibleDate = {};
    let needCalcScroll = false;
    const scrollState = gantt.getScrollState();
    const ganttConfig = projectConfig || ganttViewModel.getProjectConfig(ganttSettings.ganttId);
    const ganttTasks = globalStore.getters['tasksModel/getTasksForGantt'](ganttSettings.ganttId);

    gantt.config.readonly = true;
    gantt.config.reiniting = true;

    if (gantt.config.durationData.mode !== ganttConfig.durationData.mode) {
      needCalcScroll = true;
      firstVisibleDate = gantt.dateFromPos(scrollState.x);
    }

    if (projectConfig && projectConfig.userSkin) {
      ganttHelpers.removeSkinClass();
      ganttConfig.userSkin = projectConfig.userSkin;
    }

    if (!ganttId) {
      ganttConfig.show_grid = gantt.config.show_grid;
    }

    reinitGantt.updateGanttTasks(ganttTasks, true);

    ganttInitFilter.beforeApplyFilter();

    ganttHandlers.afterGanttShowing();

    reinitGantt.scrollToNeedDate(scrollState, needCalcScroll, firstVisibleDate);

    gantt.config.date_grid = helperDate.getDateFormatForGanttGrid();
    gantt.formatFunc = gantt.date.date_to_str(helperDate.getDateFormat());

    if (gantt._expand_button._html && gantt._workload_expand_button._html) {
      gantt._expand_button._html.style.display = 'none';
      gantt._workload_expand_button._html.style.display = 'none';
    }

    gantt.callEvent('ganttReInit');

    // ganttInit.setRightByUserRole(gantt);

    if (!callback) {
      gantt.config.reiniting = false;
    }

    callback && callback();
  },
  run(ganttId, projectConfig, callback, changeActiveProject) {
    if (gantt.isEditingState() && !changeActiveProject) {
      reinitGantt.callAfterEditing(() => {
        reinitGantt.run(ganttId, projectConfig);
      });

      return;
    }
    if (gantt.config.needUpdateTasksDatesAfterCreateProject) {
      if (projectConfig) {
        gantt.config.gantt_id = ganttId;
        moduleWorkDays.init.reinit(projectConfig.durationData);
      }

      globalStore.dispatch('tasksModel/recalculateStartDatesForTasksByGanttId', { ganttId });
      gantt.config.needUpdateTasksDatesAfterCreateProject = false;
    }

    const viewData = ganttViewModel.getActiveViewData();

    if (!viewData) {
      return;
    }

    let firstVisibleDate = {};
    let needCalcScroll = false;
    const scrollState = gantt.getScrollState();
    const ganttConfig = viewData.config;
    let ganttTasks = ganttViewModel.getTasks();

    gantt.config.readonly = true;
    gantt.config.reiniting = true;

    if (gantt.config.durationData && ganttConfig.durationData && gantt.config.durationData.mode !== ganttConfig.durationData.mode) {
      needCalcScroll = true;
      firstVisibleDate = gantt.dateFromPos(scrollState.x);
    }
    if (projectConfig && projectConfig.userSkin) {
      ganttHelpers.removeSkinClass();
      ganttConfig.userSkin = projectConfig.userSkin;
    }

    if (!ganttId) {
      ganttConfig.show_grid = gantt.config.show_grid;
    }

    reinitGantt.updateGanttConfig(ganttConfig);

    moduleWorkDays.init.reinit(ganttConfig.durationData);

    moduleCustomGrids.init.reinit();

    ganttTasks = moduleGridCustomButtons.handlers.addCustomButtonToProject(ganttTasks);// after update gantt config

    reinitGantt.updateGanttTasks(ganttTasks, true);

    ganttInitFilter.beforeApplyFilter();

    ganttHandlers.afterGanttShowing();

    setTimeout(() => {
      ganttHelpers.calcGridWidth();
      reinitGantt.scrollToNeedDate(scrollState, needCalcScroll, firstVisibleDate);
    }, 100);

    gantt.config.date_grid = helperDate.getDateFormatForGanttGrid();
    gantt.formatFunc = gantt.date.date_to_str(helperDate.getDateFormat());

    if (gantt._expand_button._html && gantt._workload_expand_button._html) {
      gantt._expand_button._html.style.display = 'none';
      gantt._workload_expand_button._html.style.display = 'none';
    }

    gantt.callEvent('ganttReInit');

    // ganttInit.setRightByUserRole(gantt);

    if (!callback) {
      gantt.config.reiniting = false;
    }

    callback && callback();

    viewData.isSingleProject && app.trigger('activeProject:afterSet', viewData.id);
  },
  callAfterEditing: _.debounce(calledFunction => {
    const eventIDs = [];

    const deattachEvents = function (eventIDs) {
      _.each(eventIDs, eventID => {
        gantt.detachEvent(eventID);
      });
    };

    eventIDs.push(gantt.attachEvent('onBeforeTaskUpdate', (id, item) => {
      calledFunction();

      deattachEvents(eventIDs);

      return true;
    }));

    eventIDs.push(gantt.attachEvent('unloadTaskEditors', _.debounce(() => {
      calledFunction();

      deattachEvents(eventIDs);
    }, 200)));

    eventIDs.push(gantt.attachEvent('onTaskIdChange', () => {
      calledFunction();

      deattachEvents(eventIDs);
    }));
  }, 100),
  reinitConfig() {
    const ganttConfig = ganttViewModel.getProjectConfig(ganttSettings.ganttId);

    ganttConfig.show_grid = gantt.config.show_grid;
    gantt.config.readonly = true;
    reinitGantt.updateGanttConfig(ganttConfig);
    moduleWorkDays.init.reinit(ganttConfig.durationData);
    gantt.callEvent('onParse');
    gantt.callEvent('ganttRender');

    // ganttInit.setRightByUserRole();
  },
  updateGanttConfig(ganttConfig, skinName) {
    ganttInit.configurations.assignInit(ganttConfig);
    ganttInit.configurations.base(ganttConfig);
    ganttInit.configurations.initBaseLayout();
    ganttInit.configurations.showDrag();
    ganttInit.configurations.staticBackground();
    ganttInit.configurations.durationBase();
    ganttInit.configurations.durationViewMode(ganttConfig.view_mode || 'auto');
    ganttInit.configurations.criticalPath(ganttConfig.highlight_critical_path);
    ganttInit.configurations.autoScheduling(ganttConfig.auto_scheduling);
    ganttInit.configurations.autoBudget(ganttConfig.auto_budget);
    ganttInit.configurations.expandButton();
    ganttInit.configurations.showMarkers();
    ganttInit.configurations.leftRightTaskText();
    ganttInit.configurations.inlineEditors();
    ganttInit.configurations.ganttLocaleLabels();
    ganttInit.configurations.initCustomTemplates();
    ganttInit.configurations.initResourceLoadingType(ganttConfig.resource_loading_type);
    ganttInit.configurations.startOnMonday(ganttConfig.start_monday);
    ganttInit.archiveProject(ganttSettings.ganttId);
    ganttInit.configurations.setRightByUserRole();
    ganttInit.configurations.durationView(ganttConfig.duration_view);
    ganttInit.configurations.initCustomButtons();
    ganttInit.configurations.initBindStatusAndProgressHelper();
    ganttInit.configurations.highlightArea(ganttConfig.durationData);
    ganttInit.configurations.multiSelect();
    ganttInit.configurations.progressType(ganttConfig.progress);
    ganttInit.configurations.rightSideText(ganttConfig.right_side_text || 'insideText');
    ganttInit.configurations.currentDayMarker(ganttConfig.current_day_marker);
    ganttInit.configurations.showResourceAvatar(ganttConfig.show_resource_avatar);
    ganttInit.configurations.skipOffTime(ganttConfig.skip_off_time);
    ganttInit.configurations.isMultiview(ganttConfig.multiview);
    ganttInit.configurations.overdueHighlight(ganttConfig.highlight_overdue);
    ganttInit.configurations.isHideWorkload();
    ganttInit.configurations.setSkin(ganttConfig.userSkin || 4);
    ganttInit.configurations.discolorationTasks(ganttConfig.discoloration_tasks);
    ganttInit.configurations.crossOutTasks(ganttConfig.cross_out_tasks);
  },
  updateGanttTasks(ganttTasks, restoreScrollState) {
    const ganttTasksWithButtons = ganttTasks;
    const oldScrollState = gantt.getScrollState();

    gantt.unloadTaskEditors();

    gantt.silent(() => {
      gantt.blockEvents();
      gantt.getDatastore('task').clearAll();
      gantt.getDatastore('link').clearAll();
      gantt.parse(ganttTasksWithButtons);
      gantt.unblockEvents();
    });

    gantt.$layout.resize();
    gantt.callEvent('onParse');
    gantt.callEvent('ganttRender');

    if (restoreScrollState) {
      gantt.scrollTo(oldScrollState.x, oldScrollState.y);
    }
  },
  scrollToNeedDate(baseScrollState, needCalcScroll, visibleDate) {
    let needX = gantt.posFromDate(new Date()) - 50;

    if (needCalcScroll) {
      needX = gantt.posFromDate(visibleDate);
    }
    gantt.scrollTo(needX, baseScrollState.y);

    // gantt.config.initial_scroll = true;
  },
};

app.on('settings:skin:change', ganttHandlers.changeSkin);
app.on('settings:advancedButtons', ganttInit.configurations.initCustomTemplates);

app.on('settings:viewMode:change', ganttHandlers.viewModeChange);

app.on('settings:resourceLoadingType:change', ganttHandlers.resourceLoadingTypeChange);

app.on('settings:durationView:change', ganttHandlers.durationViewChange);

app.on('settings:progressType:change', ganttInit.configurations.progressType);

app.on('settings:startOnMonday:change', ganttHandlers.startOnMondayChange);

app.on('settings:currentDayMarker:change', ganttHandlers.changeCurrentDayMarker);

app.on('settings:rightSideText:change', ganttHandlers.changeRightSideText);

app.on('settings:estimationModes:change', ganttHandlers.changeEstimationMode);

app.on('settings:applyResourceCalendar:change', ganttHandlers.changeApplyResourceCalendar);

app.on('settings:showResourceAvatar:change', ganttHandlers.changeShowResourceAvatar);

app.on('settings:skipOffTime:change', ganttHandlers.changeSkipOffTime);

app.on('settings:advancedButtons:change', ganttHandlers.advancedButtons);

app.on('settings:criticalPath:change', ganttHandlers.criticalPathChange);

app.on('settings:autoScheduling:change', ganttHandlers.changeAutoScheduling);

app.on('settings:autoBudget:change', ganttHandlers.changeAutoBudget);

app.on('settings:highlightOverdue:change', ganttHandlers.changeOverdueHighlight);

app.on('settings:hide', ganttHandlers.settingsHide);

app.on('taskSettings:save', ganttHandlers.saveTask);

app.on('taskSettings:delete', ganttHandlers.deleteTask);

app.on('ganttClearAll', ganttHandlers.ganttClearAll);

app.on('settings:crossOutTasks:change', ganttHandlers.changeCrossOutTasks);

app.on('settings:discolorationTasks:change', ganttHandlers.changeDiscolorationTasks);

app.on('changeUserDateTimeFormat', () => {
  ganttHandlers.changeCurrentDayMarker(gantt.config.current_day_marker);
});

app.on('settings:init', optionsInit => {
  ganttSettings.initOptions = optionsInit;
});

app.on('gantt:init:after', () => {
  window.addEventListener('resize', () => {
    gantt.callEvent('ganttRender');
  });

  ganttInitFilter.initHandlers();
});

app.on('app:initModels', () => {
  globalStore.getters['tasksModel/getAllData'].forEach(projectData => {
    const projectFromModel = projectsModel.getProjectDataById(projectData.id);

    gantt.addProjectCalendar(
      projectFromModel?.config.durationData || projectData.workloadData.durationData,
      projectData.id
    );
  });

  ganttInit.runInit().then(() => {
    gantt.config.preInited = true;
  });
});

app.on('tasks:model:updateTask', (taskId, taskData) => {
  gantt.silentUpdateTask(taskId, taskData);
});

app.on('tasks:model:deleteTask', (taskId, taskData) => {
  gantt.silentDeleteTask(taskId, taskData);
});

app.on(ID_EVENT_TASKS_MASSDELETE, (ganttId, taskIds) => {
  gantt.silentDeleteTasks(ganttId, taskIds);
});

app.on(ID_EVENT_TASKS_MASSUPDATE, (ganttId, updTasksData) => {
  gantt.silentUpdateTasks(ganttId, updTasksData);
});

app.on('tasks:model:addLink', (linkId, linkData) => {
  gantt.silentAddLink(linkId, linkData);
  if (linkData.gantt_id === gantt.config.gantt_id) {
    gantt.callEvent('recalculateCriticalPath');
  }
});

app.on('tasks:model:updateLink', (linkId, linkData) => {
  gantt.silentUpdateLink(linkId, linkData);
});

app.on('tasks:model:massChangeLink', linksData => {
  linksData.delete.forEach(linkData => {
    gantt.silentDeleteLink(linkData.id, linkData);
  });

  linksData.insert.forEach(linkData => {
    gantt.silentAddLink(linkData.id, linkData);
  });

  if (linksData.delete.length) {
    if (gantt.config.auto_scheduling && !gantt._autoscheduling_in_progress) {
      gantt.autoSchedule();
    }
  }
});

app.on('tasks:model:deleteLink', (linkId, linkData) => {
  gantt.silentDeleteLink(linkId, linkData);
  if (linkData.gantt_id === gantt.config.gantt_id && !gantt.config.auto_scheduling) {
    gantt.callEvent('recalculateCriticalPath');
  }
  if (gantt.config.auto_scheduling && !gantt._autoscheduling_in_progress) {
    gantt.autoSchedule();
  }
});

app.on('created:first:project', () => {
  gantt.config.preInited && !gantt.config.inited && ganttInit.runInit();
});

app.on('change:route', route => {
  routerHelper.changeRoute(route);
});

app.on('change:route:filter', query => {
  routerHelper.pushQuery(query);
});

app.on('tasksModel:onStoreUpdate', ({ ganttId, taskId, autoschedule }) => {
  if (taskId && gantt.isTaskExists(taskId)) {
    let task = gantt.getTask(taskId);

    if (gantt.config.multiview && _.isNull(task.parent) && task.$level === 1) {
      // fix update parent of total estimate in multiview
      task.parent = 1;
    }

    const customValues = globalStore.getters['tasksModel/getCustomValuesForTask'](ganttId, taskId);

    task = _.assign(task, customValues);

    if (gantt.$ui.getView('grid').$grid_data.querySelector(`[task_id="${taskId}"]`)) {
      gantt.refreshTask(taskId);
    }

    const taskData = gantt.getTask(taskId);
    let parentData = gantt.getTask(taskData.parent);

    if (parentData) {
      gantt.refreshTask(parentData.id);

      while (parentData.parent) {
        parentData = gantt.getTask(parentData.parent);

        gantt.refreshTask(parentData.id);
      }
    }

    if (autoschedule) {
      setTimeout(() => {
        if (routerHelper.isWorkloadViewRoute()) {
          task.auto_scheduling && gantt.autoSchedule();
        } else {
          gantt.config.auto_scheduling && gantt.autoSchedule();
        }
      }, 1000)
    }
  }
});

app.on('onAfterCollaboration', data => {
  if (data.event === 'TaskCreated') {
    const assignedTasks = data.tasksData.filter(task => task.resources.length);

    if (assignedTasks.length) {
      const viewData = ganttViewModel.getActiveViewData();
      const ganttIds = viewData.ganttIDs || [viewData.gantt_id];

      data.projects.forEach(ganttId => {
        const tasks = assignedTasks.filter(task => task.projectId === ganttId);
        const projectData = globalStore.getters['tasksModel/getItem'](ganttId);
        const resourcesToTasks = { ...projectData.resourcesToTasks };

        tasks.forEach(task => {
          resourcesToTasks[task.id] = task.resources.map(r => ({
            resource_id: r.resourceId,
            task_id: task.id,
            value: r.resourceValue / 60,
          }));
        });

        globalStore.dispatch('tasksModel/updateTasksResourcesByGanttId', { resourcesToTasks, ganttId });
      });

      if (data.projects.some(id => ganttIds.includes(id))) {
        app.trigger('refresh:filter');
      }
    }
  }

  if (data.event === 'TaskUpdated') {
    if (!data.resources) return; // called after special paste tasks

    const viewData = ganttViewModel.getActiveViewData();
    const ganttIds = viewData.ganttIDs || [viewData.gantt_id];
    let needRefresh = false;

    data.projects.forEach(ganttId => {
      const projectData = globalStore.getters['tasksModel/getItem'](ganttId);
      const resourcesToTasks = { ...projectData.resourcesToTasks };
      let needUpdate = false;

      data.tasks.forEach(taskId => {
        if (resourcesToTasks[taskId] && resourcesToTasks[taskId].length && !data.resources.find(r => r.taskId === taskId)) {
          delete resourcesToTasks[taskId];
          needUpdate = true;

          if (ganttIds.includes(ganttId)) {
            const task = gantt.getTask(taskId);

            if (task) task.resources = [];

            needRefresh = true;
          }
        }
      });

      if (needUpdate) {
        globalStore.dispatch('tasksModel/updateTasksResourcesByGanttId', { resourcesToTasks, ganttId });
      }
    });

    if (needRefresh) {
      app.trigger('refresh:filter');
    }
  }

  if (data.event === 'TaskResourceAssigned' || data.event === 'TaskResourceUnassigned') {
    data?.tasks.forEach(taskId => {
      if (gantt.isTaskExists(taskId)) {
        gantt.refreshTask(taskId);
      }
    })
  }
  if (data.event === 'ResourceOnProjectsUpdated') {
    if(ganttSettings.filterData.active) {
      app.trigger('refresh:filter');
    }
  }
});

GT.agent.family === 'IE' && document.body.addEventListener('mscontrolselect', evt => {
  evt.preventDefault();
});

window.addEventListener('click', e => {
  if (e.target.classList && e.target.classList.contains('gantt_link_point')) {
    userExtAnalytics.log('gantt_link_point_click');
  }

  if (e.target.closest('.column-options-cog')) {
    gantt.showContextMenu(e.target.closest('.column-options-cog'));
  }
}, false);

export default {};
