import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import zoomDayView from './zoomDays';
import zoomHoursPopup from './zoomHours';
import calendarLayout from '../../calendar/main';
import constants from '../../../helpers/constants';
import moduleWorkDays from '../../gantt/modules/workDays';
import _ from '../../../libs/lodash';
import routerHelper from '../../../helpers/router';
import globalStore from '../../../store/main';

const __ = window.__;

const innerObject = {
  views: {
    main: {
      id: 'zoomLayout',
    },
    zoomSlider: {
      id: 'zoomSlider',
    },
    hoursRangeLabel: {
      view: 'template',
      id: 'displayOptionRangeHoursLabel',
      height: 36,
      css: 'hours-template',
      borderless: false,
      template(obj) {
        let innerHtml = '';

        this.intervals.forEach(intervalString => {
          innerHtml += `<div class='dataview-hours-inline-item'>${intervalString}</div>`;
        });

        return `<div class='dataview-hours-inline'>${innerHtml}</div>`;
      },
      onClick: {
        'hours-template': function () {
          zoomHoursPopup.handlers.show(this.$view);
        },
      },
    },
    changeCustomDaysButton: {
      view: 'template',
      id: 'changeCustomDaysButton',
      css: 'tooltip-gantt change_custom_days',
      borderless: true,
      autoheight: true,
      toolTip: {
        y: 6,
        langKey: 'project_settings_custom_calendar_tooltip',
        position: 'left',
      },
      template() {
        return `<span class='change_custom_days_text'>${__('change_custom_days_text')}</span>`;
      },
      onClick: {
        change_custom_days() {
          calendarLayout.init();
        },
      },
      on: {
        onAfterRender() {
          const thisNode = this.getNode();

          thisNode.dataset.key = this.config.toolTip.langKey;
          thisNode.dataset.position = this.config.toolTip.position;
          thisNode.dataset.y = this.config.toolTip.y;
        },
      },
    },
    customDayInfoWorkTimeTitle: {
      view: 'label',
      id: 'customDayInfoSelectedDaysTitle',
      width: 440,
      height: 30,
      template: "<div class='ellipsis-line'>#days# | #hours#</div>",
      label: '',
      css: 'customDayInfoSelectedDaysTitle',
      toolTip: {
        y: -4,
      },
      on: {
        onAfterRender() {
          innerObject.handlers.addTooltipToCurrentWorkLabel(this);
        },
      },
    },
  },
  init: {
    run(changeProject) {
      let projectConfig = ganttViewModel.getProjectConfig();
      const currentDurationData = projectConfig.durationData;
      const isMultiview = ganttViewModel.isMultiview();

      if (app.config.mode.isLink && !app.config.mode.isHistory) {
        if (isMultiview) {
          projectConfig = GT.ganttData.multiviews[0].config;
        } else {
          projectConfig = GT.ganttData.project.userConfig || {};
          projectConfig.durationData = { ...projectConfig?.durationData, ...currentDurationData };
        }
      }

      innerObject.settings.updatedConfigParams = [];
      innerObject.settings.currentSettings = {};

      if (!this.checkDurationData(projectConfig)) {
        projectConfig.durationData = {};
      }

      // if (!app.config.mode.isLink) {
      this.zoom(projectConfig);
      // }

      this.daysRange(projectConfig);
      this.hoursRange(projectConfig, changeProject);

      innerObject.currentSettings = _.clone(projectConfig.durationData);

      innerObject.currentState.days = [innerObject.currentSettings.startDay, innerObject.currentSettings.endDay];
      innerObject.currentState.hours = [innerObject.currentSettings.startTime, innerObject.currentSettings.endTime];

      if (app.config.mode.isBase) {
        this.updateWorkTimeInfoInSettings();
      }
    },
    updateWorkTimeInfoInSettings() {
      const $$customDayInfoWorkTimeTitle = $$(innerObject.views.customDayInfoWorkTimeTitle.id);

      if ($$customDayInfoWorkTimeTitle) {
        $$customDayInfoWorkTimeTitle.config.days = zoomDayView.handlers.getLabelForIntervals();
        $$customDayInfoWorkTimeTitle.config.hours = zoomHoursPopup.handlers.getLabelForIntervals();
        $$customDayInfoWorkTimeTitle.refresh();
      }
    },
    checkDurationData(projectConfig) {
      return projectConfig.durationData;
    },
    setZoomSliderValue(value) {
      const $$zoomSlider = $$(innerObject.views.zoomSlider.id);

      if (!$$zoomSlider) {
        return;
      }

      $$zoomSlider.config.value = value;
      $$zoomSlider.refresh();
    },
    zoom(projectConfig) {
      projectConfig = this.checkDurationParamAndUpdateConfig('mode', projectConfig);
      projectConfig = this.checkOldParamsZoomValue('mode', projectConfig);
      const zoomValue = innerObject.settings.modeToZoomValue[projectConfig.durationData.mode];
      this.setZoomSliderValue(zoomValue);
      globalStore.commit('headerView/setZoom', zoomValue);
    },
    daysRange(projectConfig) {
      projectConfig = this.checkDurationParamAndUpdateConfig('startDay', projectConfig);
      projectConfig = this.checkDurationParamAndUpdateConfig('endDay', projectConfig);
      projectConfig = this.checkDurationParamAndUpdateConfig('showDay', projectConfig);

      if (app.config.mode.isBase) {
        projectConfig.start_on_monday = +user.settings.start_monday;
        //! projectConfig.multiview && ganttViewModel.updateGanttSettings();
      }

      zoomDayView.init(projectConfig.durationData, projectConfig.start_on_monday);
    },
    hoursRange(projectConfig, changeProject) {
      projectConfig = this.checkDurationParamAndUpdateConfig('startTime', projectConfig);
      projectConfig = this.checkDurationParamAndUpdateConfig('endTime', projectConfig);
      projectConfig = this.checkDurationParamAndUpdateConfig('showTime', projectConfig);

      zoomHoursPopup.handlers.init(projectConfig.durationData, changeProject);
    },

    daysRangeSetLabel() {},
    checkOldParamsZoomValue(checkParam, projectConfig) {
      if (_.isString(checkParam) && checkParam === 'mode'
        && innerObject.settings.oldDurationValues.includes(projectConfig.durationData[checkParam].toLowerCase())) {
        projectConfig.durationData[checkParam] = innerObject.defaultSettings[checkParam];
        innerObject.settings.updatedConfigParams.push({
          key: checkParam,
          value: projectConfig.durationData[checkParam],
        });
        innerObject.settings.needUpdateConfig = true;
      }

      return projectConfig;
    },
    checkDurationParamAndUpdateConfig(checkParam, projectConfig) {
      if (_.isString(checkParam) && !projectConfig.durationData[checkParam]) {
        projectConfig.durationData[checkParam] = innerObject.defaultSettings[checkParam];
        innerObject.settings.updatedConfigParams.push({
          key: checkParam,
          value: projectConfig.durationData[checkParam],
        });
        innerObject.settings.needUpdateConfig = true;
      }

      return projectConfig;
    },
    updateProjectWithNewParams(projectConfig) {
      if (innerObject.settings.needUpdateConfig && app.config.mode.isBase) {
        projectConfig.durationData = projectConfig.durationData || {};
        innerObject.settings.updatedConfigParams.forEach(configParam => {
          projectConfig.durationData[configParam.key] = configParam.value;
        });

        ganttViewModel.checkParamsToUpdateAndUpdate({ key: 'durationData', value: projectConfig.durationData });

        innerObject.settings.needUpdateConfig = false;
        innerObject.settings.updatedConfigParams = [];
      }
    },
  },
  handlers: {
    addTooltipToCurrentWorkLabel(webixNode) {
      const $$customDayInfoWorkTimeTitle = webixNode || $$(innerObject.views.customDayInfoWorkTimeTitle.id);
      const thisNode = $$customDayInfoWorkTimeTitle.getNode();

      thisNode.dataset.html = 'true';
      thisNode.innerHTML = `${thisNode.innerHTML
      }<div class='tooltip-gantt-html'>`
          + `<div class='tooltip-gantt-html-title'>${
            $$customDayInfoWorkTimeTitle.config.days} | ${
            $$customDayInfoWorkTimeTitle.config.hours
          }</div>`
        + '</div>';
    },
    zoomSliderChange(newValue) {
      innerObject.currentSettings.mode = innerObject.settings.zoomValueToMode[newValue];

      innerObject.currentSettings.showTime = zoomHoursPopup.handlers.getNewHoursRange();
      innerObject.currentSettings.showDay = zoomDayView.handlers.getNewDaysRange();
      innerObject.handlers.changeZoom(newValue);
    },
    changeZoom(newValue) {
      moduleWorkDays.init.reinit({
        mode: constants.ZOOM.zoomValueToMode[newValue],
        showDay: innerObject.currentSettings.showDay,
        showTime: innerObject.currentSettings.showTime,
      });
      gantt.config.durationData.mode = constants.ZOOM.zoomValueToMode[newValue];

      globalStore.commit('headerView/setZoom', newValue);

      if (!gantt.is_workload_mode) {
        !gantt.config.hide_workload && gantt.callEvent('workloadExpandButtonClick', [{ value: !gantt.config.hide_workload }]);
        gantt.callEvent('ganttRender');
      } else {
        gantt.callEvent('workload:change:zoom');
      }
    },
    getZoomData(ganttId) {
      if ($$(innerObject.views.zoomSlider.id)) {
        return $$(innerObject.views.zoomSlider.id).getValue();
      }

      if (app.config.mode.isExport) {
        const projectConfig = ganttViewModel.getProjectConfig(ganttId);

        return innerObject.settings.modeToZoomValue[projectConfig.durationData.mode];
      }

      return globalStore.getters['headerView/zoom'];
    },
    addCurrentSettingsParamToUpdate(ganttId) {
      const projectConfig = ganttViewModel.getProjectConfig(ganttId);
      const durationData = _.clone(projectConfig.durationData);

      this.helpers.checkNewDurationData(durationData, ganttId);
    },
    initLinkMode() {
      $$(innerObject.views.main.id).hide();
    },
    initBaseMode() {
      $$(innerObject.views.main.id).show();
    },
    helpers: {
      checkNewDurationData(durationData, ganttId) {
        const zoomData = innerObject.handlers.getZoomData(ganttId);
        const viewData = ganttViewModel.getActiveViewData();
        let needUpdate = false;
        let needReinit = false;
        let needRecalc = false;

        durationData.startTime = 0;
        durationData.endTime = 0;

        if (zoomHoursPopup.handlers.checkNewData(durationData.showTime)) {
          needUpdate = true;
          needReinit = true;
          durationData.showTime = zoomHoursPopup.handlers.getNewHoursRange();
        }

        if (zoomDayView.handlers.checkNewData(durationData.showDay)) {
          needUpdate = true;
          needReinit = true;
          needRecalc = true;
          durationData.showDay = zoomDayView.handlers.getNewDaysRange();
          durationData.startDay = durationData.showDay[0];
          durationData.endDay = durationData.showDay[durationData.showDay.length - 1];
        }

        if (durationData.mode !== innerObject.settings.zoomValueToMode[zoomData]) {
          durationData.mode = innerObject.settings.zoomValueToMode[zoomData];
          needUpdate = true;
        }

        if (needUpdate && ganttViewModel.checkParamsToUpdateAndUpdate({ key: 'durationData', value: durationData })) {
          // ganttViewModel.initProjectCalendar(ganttViewModel.getActiveViewData());
          if (!routerHelper.isMultiProjectsRoute()) {
            app.trigger('change:work:time');
          } else {
            app.trigger('gantt:reinitConfig');
            app.trigger('gantt:reinit');
          }

          // app.trigger('gantt:reinitConfig');

          // app.trigger('gantt:reinit');
          // if (needReinit) {
          //   if (needRecalc) {
          //
          //   }
          // } else {
          //   app.trigger('gantt:reinitConfig');
          // }
        }
      },
    },
  },
  currentSettings: {},
  currentState: {
    days: {},
    hours: {},
  },
  settings: {
    projectId: null,
    needUpdateConfig: false,
    updatedConfigParams: [],
    oldDurationValues: ['fullDay', 'officeHours', 'fullWeek', 'workDays', 'workdays', 'fullday', 'officehours', 'fullweek'],
    modeToZoomValue: constants.ZOOM.modeToZoomValue,
    zoomValueToMode: constants.ZOOM.zoomValueToMode,
    modeToZoomTitle: [
      __('zoom_scale_hours'),
      __('zoom_scale_days'),
      __('zoom_scale_weeks'),
      __('zoom_scale_months'),
      __('zoom_scale_quarters'),
      __('zoom_scale_years'),
    ],
    daysTranslateTitles: {
      1: __('common_full_mon'),
      2: __('common_full_tue'),
      3: __('common_full_wed'),
      4: __('common_full_thu'),
      5: __('common_full_fri'),
      6: __('common_full_sat'),
      7: __('common_full_sun'),
    },
  },
  defaultSettings: {
    endDay: 7,
    endTime: 24,
    mode: 'day',
    showDay: [],
    showTime: [],
    startDay: 1,
    startTime: 0,
    defaultZoomData: {
      id: 'day',
      label: __('settings_days'),
      display: [{ id: 'fullWeek' }, { id: 'workDays' }],
      index: 1,
    },
  },
};

const mainLayoutRows = [
  {
    width: 268,
    rows: [
      zoomDayView.views.currentWorkLabel,
      innerObject.views.customDayInfoWorkTimeTitle,
      innerObject.views.changeCustomDaysButton,
    ],
  },
];

const outputObject = {
  webixUI: {
    view: 'layout',
    id: innerObject.views.main.id,
    rows: mainLayoutRows,
  },
  // zoomSliderUI: innerObject.views.zoomSlider,
  zoomSliderOptions: {
    modeToZoomValue: innerObject.settings.modeToZoomValue,
    zoomValueToMode: innerObject.settings.zoomValueToMode,
    modeToZoomTitle: innerObject.settings.modeToZoomTitle,
  },
  handlers: {
    init(projectId) {
      const changeProject = (innerObject.settings.projectId !== projectId) || ganttViewModel.isMultiview();

      innerObject.settings.projectId = projectId;

      innerObject.init.run(changeProject || app.config.mode.isExport);
    },
    initLinkMode: innerObject.handlers.initLinkMode,
    initBaseMode: innerObject.handlers.initBaseMode,
    saveSettings(ganttId) {
      ganttId = ganttViewModel.getActiveGanttId();

      if (ganttId) {
        innerObject.handlers.addCurrentSettingsParamToUpdate(ganttId);
      }
    },
    updateLabels() {
      const $$customDayInfoWorkTimeTitle = $$(innerObject.views.customDayInfoWorkTimeTitle.id);

      $$customDayInfoWorkTimeTitle.config.days = zoomDayView.handlers.getLabelForIntervals();
      $$customDayInfoWorkTimeTitle.config.hours = zoomHoursPopup.handlers.getLabelForIntervals();
      $$customDayInfoWorkTimeTitle.refresh();

      zoomHoursPopup.handlers.houseRangeSetLabel();
    },
    setZoomSliderValue: innerObject.init.setZoomSliderValue,
  },
};

app.on('settings:days:collaboration', a => {
  outputObject.handlers.init(a);
});

app.on('settings:zoom:save', () => {
  outputObject.handlers.saveSettings();
});

app.on('settings:zoom:update:label', () => {
  if ($$(innerObject.views.zoomSlider.id) || $$(zoomHoursPopup.views.hoursRangeLabel.id)) {
    zoomHoursPopup.handlers.houseRangeSetLabel();
    innerObject.init.daysRangeSetLabel();
    outputObject.handlers.saveSettings();
  }
});

app.on('zoom:change', newValue => {
  if (app.config.mode.isBase) {
    innerObject.handlers.changeZoom(newValue);
    if (!gantt.is_workload_mode) {
      innerObject.currentSettings.mode = innerObject.settings.zoomValueToMode[newValue];
      innerObject.handlers.addCurrentSettingsParamToUpdate();
    }
  } else {
    innerObject.handlers.zoomSliderChange(newValue);
  }
});

export default outputObject;
