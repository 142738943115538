<template>
  <div class="table-group">
    <div class="group-title">
      {{ locales(`${group.title}`) }}
    </div>
    <table-row
      v-for="(feature, index) in group.features"
      :key="index"
      :feature="feature"
      :plans="plans"
    />
  </div>
</template>

<script>
import tableRow from './tableRow.vue';

export default {
  components: {
    tableRow,
  },
  props: [
    'plans',
    'group',
  ],
  data() {
    return {
      locales: __,
    };
  },
};
</script>

<style scoped src='../less/paymentPlans.less'></style>

    TableRow
