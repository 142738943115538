<template>
  <div>
    <div
      v-if="!access"
      class="workload-block-layout"
    >
      <div class="container">
        <div class="title-wrapper">
          <div class="main-title">
            {{ locales('workload_access_title') }}
          </div>
          <div class="pricing-title-container">
            {{ locales(planTitle) }}
          </div>
        </div>
        <div class="desc">
          <div class="desc-text">
            {{ locales('workload_access_desc') }}
            <div class="learn_more">
              <a
                class="pricing-popup-link"
                :href="locales('workload_learn_more')"
                target="_blank"
                @click="linkClick"
              >{{ locales('help_read_more') }}</a>
              <webix-ui
                class="arrow-left"
                :config="icons.arrowLeft"
              />
            </div>
          </div>
        </div>
        <upgrade-btn
          v-if="isOwner"
          :feature="'workload'"
        />
        <div class="pic">
          <img
            :src="pic"
            alt=""
          >
        </div>
      </div>
    </div>
    <div
      v-else-if="access"
      id="skeletonWrapper"
    >
      <skeleton />
      <skeleton-popup
        :show="needTimeForLoading && !requiredDataLoaded"
        :text="skeletonText"
      />
    </div>
    <webix-ui
      v-else
      :config="webixUI"
    />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { webixUI, init } from './workloadPage';
import pricingHelper from '../../../helpers/pricingHelper';

import upgradeBtn from '../../common/VueComponents/ugradeButton/upgradeButton.vue';
import svgIconForVue from '../../common/svgIconForVue';
import arrowLeft from '../../../svg/pricing/ic_arrow_left.svg';
import projectsModel from '../../../models/projects';
import rights from '../../../components/rights';
import routerHelper from '../../../helpers/router';
import app from '../../../app';
import skeleton from './workloadSkeleton.vue';
import skeletonPopup from '../../common/VueComponents/popups/skeletonPopup/skeletonPopup.vue';

export default {
  name: 'Workload',
  components: {
    upgradeBtn,
    skeleton,
    skeletonPopup,
  },
  data() {
    return {
      locales: __,
      webixUI,
      pic: `${GT.cdn}/imgs/workload/blocked_im_workload.png`,
      icons: {
        arrowLeft: svgIconForVue(arrowLeft, 27, 16),
      },
      needTimeForLoading: false,
      skeletonText: 'workload_loading_popup',
    };
  },
  computed: {
    ...mapGetters({
      currentRoute: 'headerView/currentRoute',
    }),
    access() {
      return pricingHelper.checkPricingAccess('workload');
    },
    planTitle() {
      const planTitle = `${pricingHelper.getPlans('workload')}_plan_locale`;

      return planTitle;
    },
    isOwner() {
      return rights.account.isOwner();
    },
    requiredDataLoaded() {
      let isCusomColumnsValuesLoaded = this.$store.getters['columns/valuesLoaded'];
      const isGlobalWorkloadRoute = this.currentRoute.name === 'workload';
      const isProjectWorkloadRoute = (this.currentRoute.name === 'project' || this.currentRoute.name === 'projectsMultiView') && this.currentRoute.path.includes('workload');

      if (isProjectWorkloadRoute) {
        isCusomColumnsValuesLoaded = true;
      }

      return isCusomColumnsValuesLoaded;
    },
  },
  watch: {
    requiredDataLoaded(val) {
      if (val) {
        this.$nextTick(() => {
          const accessToWorkload = pricingHelper.checkPricingAccess('workload');

          if (accessToWorkload) {
            app.trigger('gantt:progress:show');
            if (gantt.config.inited) {
              init();
            }
            this.$store.dispatch('updateWindowParams', {
              visibility: 'hidden',
            });
          }
        });
      }
    },
  },
  created() {
    this.$store.dispatch('updateWindowParams', {
      visibility: 'visible',
    });
  },
  mounted() {
    this.$nextTick(() => {
      const accessToWorkload = pricingHelper.checkPricingAccess('workload');

      if (accessToWorkload && this.requiredDataLoaded) {
        app.trigger('gantt:progress:show');
        if (gantt.config.inited) {
          init();
        }

        this.$store.dispatch('updateWindowParams', {
          visibility: 'hidden',
        });
      }
    });
    setTimeout(() => {
      this.needTimeForLoading = true;
    }, 5000);
  },
  beforeCreate() {
    app.on('onAfterCollaboration', ({ event }) => {
      if ((event === 'ResourceOnProjectsUpdated' || event === 'RoleAccountUpdated' || event === 'RoleProjectUpdated')) {
        const accessToWorkloadByRight = projectsModel.getAllProjects().some(o => rights.project.hasRight(o.gantt_id, 'workload'));

        if (!accessToWorkloadByRight) routerHelper.backRoute();
      }
    });
  },
  methods: {
    linkClick() {
      userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'workload' });
    },
  },
};
</script>

<style scoped src='./less/workloadVue.less'></style>
