<template>
  <div class="stub_wrap">
    <svgImage
      :name="stub.icon"
      class="stub_image"
    />
    <div class="stub_title">
      {{ stub.title }}
    </div>
    <div class="stub_description">
      {{ stub.body }}
    </div>
  </div>
</template>

<script>
import svgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';

export default {
  name: 'Stub',
  components: { svgImage },
  props: {
    type: {
      type: String,
      required: false,
      default: 'no-tasks',
      validator(value) {
        return ['no-views', 'no-tasks', 'no-fields'].includes(value);
      },
    },
  },

  data() {
    return {
      locales: {
        noTasks: {
          title: __('stub_title_no_tasks'),
          body: __('stub_body_no_tasks'),
        },
        noViews: {
          title: __('stub_title_no_views'),
          body: __('stub_body_no_views'),
        },
        noFields: {
          title: __('stub_title_no_fields'),
          body: __('stub_body_no_fields'),
        },
      },
    };
  },

  computed: {
    stub() {
      switch (this.type) {
      case 'no-tasks': return { ...this.locales.noTasks, icon: 'stub-eye_lock' };
      case 'no-views': return { ...this.locales.noViews, icon: 'stub-box' };
      case 'no-fields': return { ...this.locales.noFields, icon: 'stub-eye_lock_small' };
      default: return { ...this.locales.noViews, icon: 'stub-box' };
      }
    },
  },

};
</script>

<style lang="less" scoped>
  .stub{
    &_wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #0D0D0D;
      text-align: center;
      height: 100%;
      justify-content: center;
      line-height: 21px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    &_image{
      margin-bottom: 50px;
    }

    &_title{
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
    }

    &_description{
      max-width: 280px;
      font-size: 14px;
    }
  }

</style>
