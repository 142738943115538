<template>
  <cellTooltip
    :items="chacked"
    :template="'icon+text'"
    :group-by="'group'"
    :can-show="true"
    :icon-field="'picture'"
    :value-field="'name'"
  >
    <div
      :class="[$style.cell_trigger,
               disabled ? $style.disabled_cell :'' ,
               crossout && !isOpen ? $style.crossout :'',
               isOpen && editable ? $style.edit : '',
               !editable ? $style.not_allowed : '',
      ]"
      :style="{opacity: isOpen ? 1 : cellOpecity}"
      @click="clickOnCell"
    >
      <template v-if="chacked.length">
        <template v-if="chacked.length === 1">
          <div
            :style="{'background-image': `url(${chacked[0].picture})`}"
            :class="[$style.icon_wrap]"
          />
          <span :class="[$style.text_wrap, $style.select_none]">{{ chacked[0].name }}</span>
        </template>

        <template v-if="chacked && chacked.length > 1">
          <div
            v-for="index in 2"
            :key="componentKey +'i+t_s-' + index "
            :style="{'background-image': `url(${chacked[index-1].picture})`}"
            :class="[$style.icon_wrap]"
          />
          <span
            v-if="chacked.length > 2"
            :class="[$style.select_none]"
          > +{{ chacked.length - 2 }} </span>
        </template>
      </template>
      <span
        v-else
        :class="[$style.secondary, $style.text_wrap, $style.select_none]"
      > {{ local_noAsigned }} </span>
    </div>
  </cellTooltip>
</template>

<script>
import cellTooltip from './tooltip.vue';

export default {
  name: 'ResourcesCell',
  components: { cellTooltip },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      local_noAsigned: __('notAssigned'),
      isOpen: false,
      eventsIds: [],
      clickOnCurrentCell: false,
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },

    chacked() {
      const allResources = _.cloneDeep(this.$store.getters['resourcesModel/getResourcesByProjectIdForUI'](this.item.gantt_id));

      const chacked = allResources.filter(resource => {
        const taskResource = this.item.resources.filter(res => res.resource_id === resource.id)[0];

        return !!taskResource;
      });

      return chacked || [];
    },
  },
  watch: {
  },

  mounted() {
    document.addEventListener('contextmenu', this.contextMenuEvent);
    this.addPopupEventListheners();
  },

  beforeDestroy() {
    this.hidePopup()
    document.removeEventListener('contextmenu', this.contextMenuEvent);

    this.eventsIds.map(id => $$('resourcesSelectListPopup').detachEvent(id));
  },

  methods: {
    contextMenuEvent(e) {
      this.hidePopup();
    },

    clickOnCell(e) {
      if (this.disabled || !this.editable) {
        e.preventDefault();

        return;
      }

      this.clickOnCurrentCell = true;
      this.isOpen ? this.hidePopup() : this.openPopup();
    },

    hidePopup() {
      gantt.callEvent('hideResourcesListForTask');
      this.clickOnCurrentCell = false;
    },

    openPopup() {
      const pos = this.$el.getBoundingClientRectWrapper();

      gantt.callEvent('showResourcesListForTask', [
        pos,
        this.item.id,
        this.item.gantt_id,
        true,
        'listView',
      ]);
    },

    addPopupEventListheners() {
      const component = this;
      const popup = $$('resourcesSelectListPopup');

      const ev_id = popup.attachEvent('onHide', () => {
        component.isOpen = false;
        component.clickOnCurrentCell = false;
      });

      const ev_id_1 = popup.attachEvent('onShow', () => {
        component.clickOnCurrentCell && (component.isOpen = true);
      });

      this.eventsIds.push(ev_id, ev_id_1);
    },
  },
};
</script>

<style module src="./cell.less" lang="less">
</style>
