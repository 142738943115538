<template>
  <div class="settings-content settings-content-profile">
    <div
      class="settings-section personal-section"
    >
      <div class="user-info">
        <div class="main-title">
          {{ locales('settings_profile_personal_title') }}
        </div>
        <div class="edit-area">
          <div class="fullname">
            <input-with-label
              :id="'firstName'"
              :css="'profile-settings-input margin-right'"
              :label="locales('profile_first_name')"
              :placeholder="locales('profile_first_name_placeholder')"
              :value="firstName"
              :type="'text'"
              :handle-change="changeFirstName"
            />
            <input-with-label
              :id="'lastName'"
              :css="'profile-settings-input'"
              :label="locales('profile_last_name')"
              :placeholder="locales('profile_last_name_placeholder')"
              :value="lastName"
              :type="'text'"
              :handle-change="changeLastName"
            />
          </div>
          <input-with-label
            :css="'profile-settings-input'"
            :label="locales('admin_users_email')"
            :placeholder="locales('profile_password')"
            :value="email"
            :is-disable="true"
          />
          <div
            id="gp_autotest_delete_account_button"
            class="delete-profile-btn"
            @click="deleteProfile"
          >
            {{ locales("profile_cancel_account") }}
          </div>
        </div>
      </div>
      <div
        class="user-photo"
        @click="showAvaUploader"
      >
        <img
          :src="userAva"
          alt="avatar"
          class="ava"
        >
        <div
          class="overlay"
        >
          <img
            :src="cameraIcon"
            alt="camera"
            class="camera"
          >
        </div>
      </div>
    </div>
    <delimiter />
    <password-change-section class="settings-section" />
    <delimiter />
    <div class="settings-section workspace-section">
      <div class="main-title">
        {{ locales('settings_profile_workspace_title') }}
      </div>
      <v-select
        :label="locales('user_menu_lang')"
        :items="languageOptions"
        :current-item="userLocale"
        :target-value="'value'"
        :select-handler="changeLanguage"
        :styles="{marginBottom: 12 + 'px'}"
        :width="244"
        :position="'bottom'"
      />
      <v-select
        :label="locales('user_menu_dateformat')"
        :items="dateOptions"
        :current-item="userCurrentDate"
        :target-value="'value'"
        :select-handler="changeDateFormat"
        :styles="{marginBottom: 18 + 'px'}"
        :width="244"
        :position="'bottom'"
      />
      <v-select
        :label="locales('user_menu_numberformat')"
        :items="numberFormats"
        :current-item="userNumberFormat"
        :target-value="'value'"
        :select-handler="changeNumberFormat"
        :styles="{marginBottom: 18 + 'px'}"
        :width="244"
        :position="'bottom'"
        :input-description="locales('numberformat_input_description')"
      />
      <setting-row
        :is-toggle-on="userTimeFormat"
        :toggle-handler="toggleTimeFormat"
        :label="locales('common_24_time_format')"
      />
      <setting-row
        :is-toggle-on="isStartOnMonday"
        :toggle-handler="toggleMonday"
        :label="locales('settings_start_on_monday')"
      />
    </div>

    <image-uploader
      :show="isShowAvatarUploader"
      :title="locales('update_profile_photo_title')"
      :type="'avatar'"
      @onClose="isShowAvatarUploader = false"
      @onApply="onApplyUploadAvatar"
    />
  </div>
</template>

<script>
import Compressor from 'compressorjs';
import Vue from 'vue';
import app from '../../../app';
// import userNotificationsModel from '../../../models/userNotifications';
import plansModel from '../../../models/plans';
import numbersHelper from '../../../helpers/numbers';
import dateHelper from '../../../helpers/dateFormats';
import uploadAvatar from '../../../helpers/uploadAvatar';
import _ from '../../../libs/lodash';
import rights from '../../../components/rights/index';

import userAccountPopup from '../../userAccountPopup/main';

// Components
import vSelect from '../include/vSelect.vue';
import vInput from '../../common/VueComponents/vInput/vInput.vue';
import settingRow from '../include/settingRow.vue';
import delimiter from '../include/delimiter.vue';
import PasswordChangeSection from '../components/profile/PasswordChangeSection.vue';
import imageUploader from '../../imageUploader/imageUploader.vue';

export default {
  components: {
    PasswordChangeSection,
    vSelect,
    inputWithLabel: vInput,
    settingRow,
    delimiter,
    imageUploader,
  },

  data() {
    return {
      locales: __,
      cameraIcon: `${GT.cdn}/imgs/settings/camera.svg`,
      defaultPhoto: `${GT.cdn}/imgs/default/default-ava.svg`,
      userPic: `${GT.cdn}/imgs/settings/people.png`,
      firstName: '',
      lastName: '',
      email: '',
      userAva: '',
      languageOptions: [],
      dateOptions: [],
      userNumberFormat: null,
      userDateFormat: '',
      userTimeFormat: '',
      userLocale: '',
      isStartOnMonday: '',
      isShowAvatarUploader: false,
    };
  },
  computed: {
    userCurrentDate() {
      const dateFormats = this.dateOptions;
      const userDateFormat = this.userDateFormat;
      const currentFormat = dateFormats.find(item => item.id === userDateFormat);

      return currentFormat;
    },
    numberFormats() {
      return numbersHelper.numberFormats;
    },
  },
  beforeCreate() {
    if (this.$route.name === 'profile') {
      app.on('userProfile:update', () => this.setUserPhoto());
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      userExtAnalytics.log('general_profile_settings_open');
      this.dateOptions = dateHelper.dateFormatVariants;
      this.userNumberFormat = numbersHelper.getUserNumberFormat();
      this.userDateFormat = dateHelper.getUserDateFormat();
      this.userTimeFormat = dateHelper.getUserTimeFormat();
      this.isStartOnMonday = +user.settings.start_monday;

      _.each(GT.locales.supported, (val, key) => {
        if (!val.limitedAccess || (val.limitedAccess && user.userRole === 'admin')) {
          this.languageOptions.push({
            id: key, value: val.name,
          });
        }
      });
      this.userLocale = this.languageOptions.find(item => item.id === user.locale);
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.setUserPhoto();
    },
    setUserPhoto() {
      if (user.photo !== this.defaultPhoto) {
        this.userAva = user.photo;
      } else {
        this.userAva = this.userPic;
      }
    },
    async changeFirstName(newFirstName) {
      if (this.firstName !== newFirstName) {
        this.firstName = newFirstName;
        await this.$store.dispatch('profileSettings/sendUpdateOnServer', { firstName: newFirstName });
        this.userAva = user.photo;
      }
    },
    changeLastName(newLastName) {
      if (this.lastName !== newLastName) {
        this.lastName = newLastName;
        this.$store.dispatch('profileSettings/sendUpdateOnServer', { lastName: newLastName });
      }
    },
    deleteProfile() {
      if (rights.account.isOwner() && plansModel.isActiveSubscription() && !plansModel.isTrial()) {
        webix.message({ type: 'info', text: __('profile_errors_deleteAccountSuspend'), expire: 10000 });
      } else {
        const storeCallback = data => {
          $$('userAccountFormPopup').show();
          this.$store.dispatch('globalPopup/close');
        };

        // userAccountPopup.init();
        this.$store.dispatch('globalPopup/show', {
          type: 'confirm',
          popupType: 'delete',
          ok: ('delete_popup_footer_button_text_01'),
          cancel: ('delete_popup_footer_button_text_02'),
          message: `${__('delete_popup_body_text')}`,
          title: `${__('delete_popup_header_title')}`,
          additionalText: `${__('delete_popup_body_desc')} <a href="mailto:${__('support_mail')}">${__('support_mail')}</a>`,
          footerText: true,
          closeIcon: true,
          popupWidth: 636,
          buttonWidth: 'auto',
          confirm: storeCallback,
        });
      }
    },
    showAvaUploader() {
      this.isShowAvatarUploader = true;
    },
    changeLanguage(item) {
      const storeCallback = data => {
        this.$store.dispatch('profileSettings/sendUpdateOnServer', data);
      };

      window.userExtAnalytics.log('locale_change', { from: user.locale, to: item.id });

      if (user.locale !== item.id) {
        this.$store.dispatch('globalPopup/show', {
          type: 'confirm',
          ok: __('common_yes'),
          cancel: __('common_no'),
          message: __('settings_change_language'),
          footerText: true,
          buttonWidth: '90px',
          popupType: 'confirm',
          confirm: storeCallback,
          data: { locale: item.id },
        });
      }
    },
    async changeNumberFormat(format) {
      userExtAnalytics.log('number_format_changed', { example: format.value });
      await numbersHelper.setUserNumberFormat(format.id);
      this.userNumberFormat = numbersHelper.getUserNumberFormat();
      app.trigger('userNumberFormatChanged');
      this.$toast.success(this.locales('profile_saved'));
    },
    changeDateFormat(newDate) {
      dateHelper.setUserDateFormat(newDate.id);
      this.userDateFormat = newDate.id;
      this.$store.dispatch('profileSettings/sendUpdateOnServer', { dateFormat: newDate.id });
      app.trigger('changeUserDateTimeFormat');
    },
    toggleTimeFormat() {
      const format = this.userTimeFormat === 1 ? 0 : 1;

      dateHelper.setUserTimeFormat(format);
      this.userTimeFormat = format;
      this.$store.dispatch('profileSettings/sendUpdateOnServer', { timeformat: format });
      app.trigger('changeUserDateTimeFormat');
    },
    toggleMonday() {
      const startOnMonday = this.isStartOnMonday === 1 ? 0 : 1;

      this.$store.dispatch('userNotification/updateUserSettings', {
        data: {
          start_monday: startOnMonday,
        },
      }).then(() => {
        user.settings.start_monday = startOnMonday;
      });
      // userNotificationsModel.updateUserSettings({
      //   start_monday: startOnMonday,
      // }).then(() => {
      //   user.settings.start_monday = startOnMonday;
      // });
      this.isStartOnMonday = startOnMonday;
      app.trigger('settings:startOnMonday:change', startOnMonday);
      this.$toast.success(__('profile_saved'));
    },
    onApplyUploadAvatar(file) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 500,
        maxHeight: 500,
        success: async resFile => {
          const result = await uploadAvatar(resFile, user.id);

          this.userAva = result.photo;
          user.photo = result.photo;
          this.$store.dispatch('profileSettings/sendUpdateOnServer', { photo: result.photo });
          app.trigger('userAvatar:update', result.photo);
        },
      });
    },
  },

};
</script>

<style scoped src='../less/profileSettings.less'></style>
