/* eslint-disable no-new */
/* eslint-disable no-else-return */
import Vue from 'vue';
import Compressor from 'compressorjs';
import _ from '../../../libs/lodash';
import projectsModel from '../../../models/projects';
import globalStore from '$$store/main';
import rights from '../../../components/rights/index';
import notificationModal from '../../notificationModal/notificationModal';
import plansModel from '../../../models/plans';
import uploadAvatar from '../../../helpers/uploadAvatar';
import profileModel from '../../../models/profile';
import constants from '$$helpers/constants';
import statusHelper from '$$helpers/status';
import app from '../../../app';

export default class Resources {
  _accountHelper(accountRole, userId) {
    return {
      isMyAccount: user.id === userId,
      meIsKingAccount: rights.account.getUserRole(user.id)?.rights.find(bitObj => bitObj.bit === 2)?.status,
      meIsProjectOwner: rights.project.getUserRole(user.id, gantt.config.gantt_id)?.defaultType === constants.PROJECT_ROLES_DEFAULT.owner,
      isKingResource: accountRole?.rights.find(bitObj => bitObj.bit === 2)?.status,
    };
  }

  _roleHelper() {
    let accountRoles = globalStore.getters['roles/getAccountRoles'];
    let projectRoles = globalStore.getters['roles/getProjectRoles'];

    accountRoles = accountRoles.map(item => {
      const copy = { ...item };

      if (copy.defaultType) {
        copy.description = __(`account_${copy.title.toLowerCase()}_description`);
        copy.title = __(`account_${copy.title.toLowerCase()}`);
        copy.tooltip = { content: copy.title };
      } else {
        copy.description = __('account_custom_role_description');
        copy.tooltip = { content: copy.title };
      }

      return copy;
    });

    projectRoles = projectRoles.map(item => {
      const copy = { ...item };

      if (copy.defaultType) {
        copy.description = __(`project_${copy.title.toLowerCase()}_description`);
        copy.title = __(`project_${copy.title.toLowerCase()}`);

        copy.tooltip = { content: copy.title };
      } else {
        copy.description = __('project_custom_role_description');
        copy.tooltip = { content: copy.title };
      }

      return copy;
    });

    return {
      accountRoles,
      projectRoles,
    };
  }

  prepareAccountResources(resources) {
    const rightToRemoveResource = rights.account.hasRight('team_and_resources_remove_users');
    const hasAccessToCangeAccountRole = rights.account.hasRight('team_and_resources_account_role_change');
    const preparedResources = [];

    resources.forEach(item => {
      let resource = {};
      const role = this._roleHelper().accountRoles.find(role => role.id === item.accountRoleId);
      const accountHelper = this._accountHelper(role, item.userId);

      resource = { ...item };
      resource.isOwner = role?.defaultType === 'OWNER';
      resource.isKing = accountHelper.isKingResource;
      resource.role = role;
      resource.roleTitle = role?.title; // for sorting in vuetify table
      resource.accessToChangeRole = hasAccessToCangeAccountRole && resource.userId !== user.id; // access to role dropdown in account resources table
      resource.resourceProjects = this._prepareResourceProjects(resource);
      resource.isJira = resource.resourceProjects.some(rp => rp.isJira) && !resource.userId;
      resource.acсessToDelete = rightToRemoveResource ? !accountHelper.isMyAccount && role?.defaultType !== 'OWNER' : false;
      resource.accessToSettings = true;
      resource.accessToConvert = false;
      preparedResources.push(resource);
    });

    const ownerItem = preparedResources.find(item => item.isOwner);
    const index = preparedResources.indexOf(ownerItem);

    preparedResources.unshift(...preparedResources.splice(index, 1));
    preparedResources.forEach(data => {
      data.resourceProjects = _.uniqBy(data.resourceProjects, 'projectId');
    });

    return {
      people: preparedResources.filter(item => item.userId),
      material: preparedResources.filter(item => !item.userId),
    };
  }

  prepareProjectResources(resources) {
    const hasAccessToCangeProjectRole = rights.project.hasRight(gantt.config.gantt_id, 'resources_rights_edit');
    const preparedResources = [];

    resources.forEach(item => {
      let resource = {};
      const role = {
        account: this._roleHelper().accountRoles.find(role => role.id === item.accountRoleId),
        project: this._roleHelper().projectRoles.find(role => role.id === item.resourceProjects.find(o => o.projectId === gantt.config.gantt_id)?.projectRoleId),
        projectAdmin: this._roleHelper().projectRoles.find(role => role?.defaultType === constants.PROJECT_ROLES_DEFAULT.admin),
      };
      const accountHelper = this._accountHelper(role.account, item.userId);

      resource = { ...item };
      resource.isOwner = role.project?.defaultType === 'OWNER';
      resource.role = accountHelper.isKingResource && !resource.isOwner ? role.projectAdmin : role.project;
      resource.roleTitle = resource.role?.title; // for sorting in vuetify table
      resource.accessToChangeOwner = resource.isOwner && (accountHelper.meIsKingAccount || accountHelper.meIsProjectOwner); // for show change owner button in resources table
      resource.accessToChangeRole = hasAccessToCangeProjectRole && !accountHelper.isKingResource && !accountHelper.isMyAccount; // access to role dropdown in project resources table
      resource.resourceProjects = this._prepareResourceProjects(resource);
      resource.isJira = resource.resourceProjects.some(rp => rp.isJira);
      resource.accessToSettings = false;
      resource.accessToConvert = !resource.isJira;
      resource.acсessToDelete = role.project?.defaultType !== 'OWNER' && resource.userId !== user.id ? true : accountHelper.meIsProjectOwner || accountHelper.meIsKingAccount;
      preparedResources.push(resource);
    });

    preparedResources.forEach(data => {
      data.resourceProjects = _.uniqBy(data.resourceProjects, 'projectId');
    });

    return {
      people: preparedResources.filter(item => item.userId),
      material: preparedResources.filter(item => !item.userId),
    };
  }

  _prepareResourceProjects(resource) {
    const preparedResourceProjects = [];
    const accountRole = this._roleHelper().accountRoles.find(role => role.id === resource.accountRoleId);
    const accountHelper = this._accountHelper(accountRole, resource.userId);
    const projectRoles = this._roleHelper().projectRoles;

    resource.resourceProjects.forEach(project => {
      let projectData = projectsModel.getProjectDataById(project.projectId);

      if (!projectData) {
        projectData = projectsModel.getArchivedProject(project.projectId);
      }

      if (!projectData) return;

      let roleOnProject = projectRoles.find(role => role.id === project.projectRoleId);

      if ((accountHelper.isKingResource || accountRole?.defaultType === 'OWNER') && roleOnProject?.defaultType !== 'OWNER') {
        roleOnProject = projectRoles.find(role => role?.defaultType === 'ADMIN');
      }

      let accessToPeopleInProject = rights.project.hasRight(project.projectId, 'resources_page');
      const accessToChangeProjectRole = rights.project.hasRight(project.projectId, 'resources_rights_edit');

      if (accountHelper.meIsKingAccount) accessToPeopleInProject = true;

      const projectItem = { ...project };

      projectItem.isJira = projectData.is_jira;
      projectItem.name = projectData.name || '';
      projectItem.roleOnProject = roleOnProject;
      projectItem.isOwnerOnProject = roleOnProject?.defaultType === 'OWNER';
      projectItem.costAccess = rights.project.hasRight(projectItem.projectId, 'resources_cost_settings');
      projectItem.roleAccess = accessToChangeProjectRole && !projectItem.isOwnerOnProject && !accountHelper.isKingResource && resource.userId !== user.id;
      projectItem.removeAccess = !(projectData.is_jira && !resource.email)
      && accessToPeopleInProject
      && ((resource.userId === user.id && (accountHelper.isKingResource || projectItem.isOwnerOnProject)) || resource.userId !== user.id);
      projectItem.isArchived = projectData.is_archived;

      preparedResourceProjects.push(projectItem);
    });

    return preparedResourceProjects;
  }

  checkAccessToInviteSilent() {
    const resourcesLength = globalStore.getters['resourcesModel/getAllRealResources'].length;
    const invitationsCount = user.team.invitations - resourcesLength;

    if (invitationsCount <= 0) {
      return false;
    }
    if (plansModel.isOverSubscription() || plansModel.isExpiredTrial()) {
      return 'isOverSubscription';
    }

    return true;
  }

  upgradePlanShowFromResourcesHandler(force) {
    // [upgrage] -
    debugger;
    let emailsCountFromStorage = 0;
    const resourcesLength = globalStore.getters['resourcesModel/getAllRealResources'].length;
    const emailsFromStorage = webix.storage.local.get('inviteEmails');

    if (emailsFromStorage && emailsFromStorage.emails && emailsFromStorage.emails.length && emailsFromStorage.userId === user.id) {
      emailsCountFromStorage = emailsFromStorage.emails.length;
    }
    const invitationsCount = user.team.invitations - resourcesLength - emailsCountFromStorage;
    const userPlan = user.paymentPlans.find(plan => plan.id === user.subscription.payment_plans_id);

    // if (plansModel.isOverSubscription() || plansModel.isExpiredTrial()) {
    //   return 'isOverSubscription';
    // }

    // if (maxInvitesUsedToBeAbleToInviteMore) {
    //   app.trigger('messageUsForm:show', 'price-app-contact-us-form');

    //   return false;
    // }

    if (force && plansModel.isCanNotBeUpdatedWithoutSales() && rights.account.isOwner()) {
      // force - это костыль, пока что б показать попап при нажатии на желтую плашку, если приглашения закончились
      app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

      return false;
    }

    if (invitationsCount <= 0 && force) {
      if (rights.account.isOwner()) {
        if (plansModel.isCanNotBeUpdatedWithoutSales()) {
          app.trigger('messageUsForm:show', 'upgrade-via-sale-contact-us-form');

          return false;
        }

        globalStore.dispatch('globalPopup/show', {
          type: 'upgradePlan',
          title: 'change_team_size_title',
          mode: 'change-team-size',
          currentPlan: userPlan,
          newPlan: userPlan,
          isAnnual: user.subscription.is_annual,
        });
      } else {
        notificationModal.init({
          title: __('resource_you_can_not_buy_title'),
          width: 420,
          body: {
            css: '',
            template: __('resource_you_can_not_buy_body'),
          },
          buttons: [
            {
              width: 180,
              text: __('resource_you_can_not_buy_button'),
              css: '',
              callback() {
                $$('notificationModal').hide();
              },
            },
          ],
        });
      }

      return false;
    }

    return true;
  }

  handleInviteFromResources() {
    debugger;
    const resourcesLength = globalStore.getters['resourcesModel/getAllRealResources'].length;
    const invitationsCount = user.team.invitations - resourcesLength;

    if (!this.upgradePlanShowFromResourcesHandler(invitationsCount === 0)) {
      console.log('handleInviteFromResources false on upgradePlanShowFromResourcesHandler');

      return false;
    }

    return true;
  }

  upgradePlanShowFromResourcesForce(force = true) {
    debugger;
    this.upgradePlanShowFromResourcesHandler(force);
  }

  inviteResources(data) {
    const payload = data.emails.map(email => ({
      type: 'real',
      email,
      projectIds: data.projectIds,
      accountRoleId: data.accountRoleId ? data.accountRoleId : null,
      projectRoleId: data.projectRoleId ? data.projectRoleId : null,
    }));

    globalStore.dispatch('resourcesModel/createResources', payload);
  }

  createResources(data) {
    if (data.name === '') {
      return;
    }

    globalStore.dispatch('resourcesModel/createResources', [{
      type: 'material',
      name: data.name,
      projectIds: data.projectIds,
      colorId: data.colorId,
    }]);
  }

  removeResourcesFromAccount(data = []) {
    globalStore.dispatch('resourcesModel/removeResources', data);
  }

  removeResourcesFromProject(data = []) {
    globalStore.dispatch('resourcesModel/removeResourcesFromProjects', data);
  }

  changeResourceCostType(data) {
    globalStore.dispatch('resourcesModel/changeResourcesOnProjects', [
      {
        resourceId: data.resourceId,
        projectIds: data.projectIds,
        updated: {
          type: data.newTypeId,
          cost: 0,
        },
      },
    ]);
  }

  changeResourceCost(data) {
    let cost = data.newCost.replace(/^0(?![.,])/, '');

    if (cost === '') cost = 0;

    globalStore.dispatch('resourcesModel/changeResourcesOnProjects', [
      {
        resourceId: data.resourceId,
        projectIds: data.projectIds,
        updated: {
          cost,
        },
      },
    ]);
  }

  async addResourcesToProject(data) {
    if (data.resourcesType === 'people') {
      userExtAnalytics.log('project_people_add', { type: 'person' });
    }

    if (data.resourcesType === 'material') {
      userExtAnalytics.log('project_people_add', { type: 'virtual resource' });
    }

    const payload = data.resources.map(resource => ({
      resourceId: resource.id,
      projectRoleId: resource.projectRoleId,
      projectIds: data.projectIds,
    }));

    statusHelper.handlers.showMainSpinner();
    await globalStore.dispatch('resourcesModel/addResourcesToProjects', payload);
    statusHelper.handlers.hideMainSpinner();
  }

  changeAccountRole(data) {
    const { roleId, resource } = data;

    if (roleId === resource.role.id) return;

    globalStore.dispatch('resourcesModel/changeResourceAccountRole', {
      resourceId: resource.id,
      roleId,
      userId: resource.userId,
    });
  }

  changeProjectRole(data) {
    if (data.roleId === data.resource.resourceProjects.find(pr => pr.projectId === data.projectId)?.projectRoleId) return;

    globalStore.dispatch('resourcesModel/changeResourceProjectRole', {
      resourceId: data.resource.id,
      roleId: data.roleId,
      ganttId: data.projectId,
    });
  }

  updateResource(data) {
    globalStore.dispatch('resourcesModel/updateResource', {
      resourceId: data.resourceId,
      update: {
        name: data.name ? data.name : null,
        colorId: data.colorId ? data.colorId : null,
      },
    });
  }

  changeResourceAvatar(data) {
    const { file, userId, resourceId } = data;

    new Compressor(file, {
      quality: 0.6,
      maxWidth: 500,
      maxHeight: 500,
      success: async resFile => {
        const result = await uploadAvatar(resFile, userId);

        profileModel.updateProfile({ photo: result.photo, userId });
        globalStore.commit('resourcesModel/updateResource', {
          resourceId,
          updated: {
            photo: result.photo,
          },
        });
        Vue.$toast.success(__('profile_saved'));
      },
    });
  }

  convertResourcesOnProject(data) {
    globalStore.dispatch('resourcesModel/convertResourcesOnProject', data);
  }

  async multiAssignToProjects(data) {
    for (let i = 0; i < data.length; i++) {
      await globalStore.dispatch('resourcesModel/addResourcesToProjects', [{
        resourceId: data[i].resourceId,
        projectRoleId: data[i].roleId,
        projectIds: [data[i].projectId],
      }]);
    }
  }
}
