import app from './../app';
import _ from './../libs/lodash';

const __ = window.__;

let timeTracking = {};

const prepareLogsData = function(item) {
  _.mapKeys(item.tasks, (taskLogs, taskId) => {
    if (!_.isArray(taskLogs)) {
      return;
    }

    item.tasks[taskId] = {
      logs: taskLogs,
      sum: _.reduce(taskLogs, (sum, log) => sum + Number(log.time), 0)
    };
  });

};

if (!app.config.mode.isBase) {
  timeTracking = new webix.DataCollection({
    scheme: {
      $init: function(item) {
        prepareLogsData(item);
      },
      $update: function(item) {
        prepareLogsData(item);
      }
    }
  });


  if (GT.ganttData.timeTrackingData) {
    if (_.isArray(GT.ganttData.timeTrackingData)) {
      timeTracking.parse(GT.ganttData.timeTrackingData);
    } else {
      timeTracking.parse([GT.ganttData.timeTrackingData]);
    }
  } else {
    timeTracking.parse([{
      tasks: {},
      id: GT.ganttData.project.gantt_id
    }]);
  }
} else {
  timeTracking = new webix.DataCollection({
    url: 'rest->/api/time_tracking/?' + Date.now(),
    scheme: {
      $init: function(item) {
        prepareLogsData(item);
      },
      $update: function(item) {
        prepareLogsData(item);
      }
    }
  });
}

app.on('app:start', function () {
  webix.promise.all([timeTracking.waitData]).then(function() {
    app.checkInit('timeTracking');
  });
});

timeTracking.getLogsByParams = function(ganttId, taskId, logId) {
  const ganttLogs = _.cloneDeep(this.getItem(ganttId));

  return timeTracking._getLogsByParams(ganttLogs, taskId, logId)
};

timeTracking.getLogsByParamsNotCopy = function(ganttId, taskId, logId) {
  const ganttLogs = this.getItem(ganttId);

  return timeTracking._getLogsByParams(ganttLogs, taskId, logId)
};

timeTracking._getLogsByParams = function (ganttLogs, taskId, logId) {
  if (!ganttLogs) {
    return false;
  }

  let result = ganttLogs;

  if (taskId) {
    result = ganttLogs.tasks[taskId];
  }

  if (logId) {
    let taskData = ganttLogs.tasks[taskId];

    if (!taskData) {
      return false;
    }

    result = _.find(taskData.logs, log => log.id === +logId);
  }

  return result;
};

timeTracking.getLogsByResourceId = function(resourceId) {
  const allData = this.serialize();
  let resultData = [];

  _.each(allData, ganttItem => {
    let filteredGanttLogsData = [];

    _.each(_.values(ganttItem.tasks), taskLogsData => {
      let taskLogs = resourceId ? _.filter(taskLogsData.logs, log => log.resource_id === resourceId) : taskLogsData.logs;

      filteredGanttLogsData.push(taskLogs);
    });

    resultData.push(_.flatten(filteredGanttLogsData));
  });

  return _.flatten(resultData);
};

timeTracking.manualUpdateItemForTask = function(logData, responseData, isSilent) {
  if (responseData) {
    let ganttLogs = timeTracking.getItem(logData.gantt_id);

    if (!ganttLogs) {
      ganttLogs = {
        tasks: {
          [logData.task_id]: responseData
        },
        id: logData.gantt_id
      };

      timeTracking.add(ganttLogs);
    } else {
      ganttLogs.tasks[logData.task_id] = responseData;
      timeTracking.updateItem(logData.gantt_id, ganttLogs);
    }

    !isSilent && app.trigger('timeTrackingModel:change', logData.task_id);
  }
};

timeTracking.manualUpdateItemForTaskAfterDelete = logData => {
  const ganttLogs = timeTracking.getItem(logData.gantt_id);

  if (!ganttLogs) return;

  const taskLogs = ganttLogs.tasks[logData.task_id];
  const timeLogIndexToDelete = taskLogs.logs.findIndex(log => log.id === logData.id);

  if (timeLogIndexToDelete === -1) return;

  taskLogs.logs.splice(timeLogIndexToDelete, 1);
  taskLogs.sum -= logData.time;

  timeTracking.updateItem(logData.gantt_id, ganttLogs);

  app.trigger('timeTrackingModel:change', logData.task_id);
};

timeTracking.manualUpdateItem = function(data, ganttId, mode) {
  if (data) {
    let ganttLogs = timeTracking.getItem(ganttId);

    if (!ganttLogs) {
      timeTracking.add(data);
    } else {
      timeTracking.updateItem(ganttId, data);
    }
  }
};

timeTracking.createLog = function(logData) {
  const newLogData = _.assign({}, logData, { date: moment(logData.date).format('YYYY-MM-DD') });

  return webix.ajax().post('/api/time_tracking/', newLogData)
    .then(response => {
      timeTracking.manualUpdateItemForTask(logData, response.json());
    }).fail(err => {
      console.error(err);
    });
};

timeTracking.updateLog = function(logData, isSilent) {
  const newLogData = _.assign({}, logData, { date: moment(logData.date).format('YYYY-MM-DD') });

  return webix.ajax().put('/api/time_tracking/' + newLogData.id, newLogData)
    .then(response => {
      timeTracking.manualUpdateItemForTask(logData, response.json(), isSilent);

      return Promise.resolve();
    }).fail(err => {
      console.error(err);
    });
};

timeTracking.removeLog = function(logData) {
  return webix.ajax().del('/api/time_tracking/' + logData.id, logData)
    .then(() => {
      timeTracking.manualUpdateItemForTaskAfterDelete(logData);
    }).fail(err => {
      console.error(err);
    });
};

timeTracking.removeLogsForTask = function(taskData) {
  return webix.ajax().del('/api/time_tracking/task/' + taskData.id, taskData)
    .then(response => {
      timeTracking.manualUpdateItem(response.json(), taskData.gantt_id);
    }).fail(err => {
      console.error(err);
    });
};

export default timeTracking;
