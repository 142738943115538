import google_docs_icon from '../../../../svg/googleDrive/google_docs.svg';
import google_sheets_icon from '../../../../svg/googleDrive/google_sheets.svg';
import google_drawings_icon from '../../../../svg/googleDrive/google_drawings.svg';
import google_forms_icon from '../../../../svg/googleDrive/google_forms.svg';
import google_slides_icon from '../../../../svg/googleDrive/google_slides.svg';
import google_drive_icon from '../../../../svg/googleDrive/google_drive.svg';

const getMimeTypeIcon = mimeType => {
  const mimeTypeIcons = {
    'application/vnd.google-apps.document': google_docs_icon,
    'application/vnd.google-apps.spreadsheet': google_sheets_icon,
    'application/vnd.google-apps.drawing': google_drawings_icon,
    'application/vnd.google-apps.form': google_forms_icon,
    'application/vnd.google-apps.presentation': google_slides_icon,
  };

  return mimeTypeIcons[mimeType] || google_drive_icon;
};

const getUrlPartByMimeType = mimeType => {
  const mimeTypeMatches = {
    'application/vnd.google-apps.document': 'document',
    'application/vnd.google-apps.spreadsheet': 'spreadsheets',
    'application/vnd.google-apps.drawing': 'drawings',
    'application/vnd.google-apps.presentation': 'presentation',
  };

  return mimeTypeMatches[mimeType];
};

const previewAvailabilityByType = mimeType => {
  if (!mimeType) return false;
  const thumbnailAvailableMimeTypes = [
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.google-apps.drawing',
    'application/vnd.google-apps.presentation',
  ];

  return _.includes(thumbnailAvailableMimeTypes, mimeType);
};

export {
  getMimeTypeIcon,
  getUrlPartByMimeType,
  previewAvailabilityByType,
};
