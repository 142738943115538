<template>
  <div
    :id="'dropDownSelect-' + componentKey"
    :key="componentKey + redraw"
    v-click-outside="onClickOutside"
    :class="{
      [$style.active]: isHighlight,
      [$style.wrap]: true,
      [$style.open]: isOpen && !disabled,
      [$style.disable]: disabled,
      [$style.outline_none]: outline === 'none',
      [$style.outline_hover]: outline === 'hover',
      [$style.outline_always]: outline === 'always',
      'vgp-interactive-element': true,
    }"
    :style="{
      'font-size': view.fontSize + 'px',
      'height': view.itemHeight ? view.itemHeight + 'px' : '32px'
    }"
  >
    <div
      :id="gpAutotestName"
      ref="selected_wrap"
      :class="{
        [$style.select_inner]: true,
        [$style.select_avatar]: itemTemplate === 'user',
      }"
      @click="onClickDropTrigger"
    >
      <template v-if="useSearch && isOpen">
        <sprite
          :name="'search'"
          :type="'regular'"
          :size="24"
          :class="[ $style.search_icon,]"
        />
        <input
          :id="'select-drop-trigger-' + componentKey"
          ref="search_input"
          v-model="searchTxt"
          :class="[$style.search_input]"
          :placeholder="searchTxt ? '' : locale.search"
          :style="{...selectedInputWidthStyle}"
          type="text"
        >
      </template>

      <div
        :class="[$style.selected_items, (useSearch && isOpen) ? $style.transparent : '']"
        :style="{ ...selectedInputWidthStyle }"
      >
        <span
          v-if="!localSelected.length && !stubTextIfAllUnselected"
          :class="[$style.placeholder_txt]"
        >
          {{ placeholder }}
        </span>

        <template v-if="multiselect">
          <template v-if="(isSelectedAllItems && stubTextIfAllSelected ) || (stubTextIfAllUnselected && isUnselectedAllItems)">
            <vgpChip
              v-if="isSelectedAllItems && dropList.length"
              :gray-theme="true"
              :text="stubTextIfAllSelected"
              :preset="'text'"
            />
            <vgpChip
              v-if="isUnselectedAllItems"
              :gray-theme="true"
              :text="stubTextIfAllUnselected"
              :preset="'text'"
            />
          </template>

          <template v-else>
            <vgpRollItems
              v-if="multiSelected.length"
              :items="multiSelected"
              :offset="itemTemplate === 'user' ? -4 : 4"
            >
              <template #default="{item}">
                <vgpChip
                  :size="size"
                  :preset="(itemTemplate === 'user' && localSelected.length > 1) ? 'avatar' : itemTemplate"
                  :text="item[trackByText]"
                  :img-url="item[trackByImgUrl]"
                  :bg-color="item[trackByBgColor]"
                  :gray-theme="!['label', 'user'].includes(itemTemplate)"
                  :icon="item[trackByIconName]"
                  :fill-width="localSelected.length === 1"
                  :status-color="item[trackByStatusColor]"
                  :need-description="needDescription"
                />
              </template>
            </vgpRollItems>
          </template>
        </template>

        <template v-if="!multiselect">
          <vgpChip
            v-if="singleSelected"
            :size="size"
            :preset="itemTemplate"
            :text="singleSelected[trackByText]"
            :img-url="singleSelected[trackByImgUrl]"
            :bg-color="singleSelected[trackByBgColor]"
            :icon="singleSelected[trackByIconName]"
            :status-color="singleSelected[trackByStatusColor]"
            :txt-ellipsis="true"
            :fill-width="true"
          />
        </template>
      </div>

      <div
        ref="reght_selected"
        :class="[$style.d_flex, $style.ai_center, $style.ml_8]"
      >
        <sprite
          v-show="isShowResetBtn"
          :name="'close-2'"
          :type="'bold'"
          :size="16"
          :class="[ $style.mr_8, 'vgp_select_reset_btn']"
          @click="onClickResetBtn"
        />

        <sprite
          :name="'drop-down'"
          :type="'bold'"
          :size="24"
          :class="[ $style.drop_arrow, 'vgp_select_drop_arrow']"
          @click="toggleDropDown"
        />
      </div>
    </div>

    <div
      v-if="isOpen"
      ref="drop"
      v-scroll.self="onDropListScroll"
      :class="[$style.drop, 'gantt_scroll']"
      :style="{
        ...popupStyles,
        'max-height': popupMaxHeight + 'px',
      }"
    >
      <div>
        <div :style="{ 'height': '8px'}" />

        <template v-if="showItems.length">
          <div
            v-if="multiselect && useSelectAll"
            :class="{
              [$style.border_bottom]: !dropListInfo.isTop,
              [$style.select_all_btn_wrap]: true
            }"
          >
            <div
              :class="[$style.select_all_btn, $style.noselect]"
              @click="onSelectAllBtnClick"
            >
              <span>
                {{ isAllShowedItemsSelected ? locale.unselectAll : locale.selectAll }}
              </span>
            </div>
          </div>

          <div
            v-for="groupName in groupSet"
            :key="componentKey + groupName + dropWidth"
            :style="{width: dropWidth +'px', }"
          >
            <div
              v-if="groupName"
              :class="$style.group_devider"
              :style="{ color: deviderColor }"
            >
              <span :class="$style.group_devider_name"> {{ groupName }} </span>
              <div :class="$style.group_devider_line" />
            </div>

            <div
              v-for="item in getItemsByGroupName(groupName)"
              :key="dropTemplate + item[trackBy]"
              :class="[$style.drop_item, 'js-drop-item', trackByAdditionalForItem ? $style.drop_item_with_icon : null]"
              :title="useSystemTooltip ? item[trackByText] : ''"
            >
              <input
                v-if="multiselect"
                :id="multiselect + item[trackBy] + '-' + componentKey"
                v-model="multiSelected"
                type="checkbox"
                :value="item"
              >
              <input
                v-else
                :id="multiselect + item[trackBy] + '-' + componentKey"
                v-model="singleSelected"
                type="radio"
                :value="item"
              >

              <label
                v-if="tooltips(item)"
                v-tippy="tooltips(item)"
                :for="multiselect + item[trackBy] + '-' + componentKey"
                :style="{ 'height': view.itemHeight ? view.itemHeight + 'px' : 'auto', width: dropWidth + 'px' }"
              >
                <vgpChip
                  :text="item[trackByText]"
                  :description="item.description"
                  :need-description="needDescription"
                  :img-url="item[trackByImgUrl]"
                  :bg-color="item[trackByBgColor]"
                  :status-color="item[trackByStatusColor]"
                  :preset="itemTemplate"
                  :size="size"
                  :icon="item[trackByIconName]"
                  :txt-ellipsis="true"
                  :selected="singleSelected.id === item.id"
                />

                <vgpChip
                  v-if="trackByAdditionalForItem && item[trackByAdditionalForItem]"
                  :text="item[trackByAdditionalForItem].text"
                  :description="item.description"
                  :need-description="needDescription"
                  :txt-color="item[trackByAdditionalForItem].txtColor"
                  :img-url="item[trackByAdditionalForItem].img-url"
                  :bg-color="item[trackByAdditionalForItem].bgColor"
                  :status-color="item[trackByAdditionalForItem].statusColor"
                  :preset="item[trackByAdditionalForItem].preset"
                  :round="item[trackByAdditionalForItem].round"
                  :padding-left="item[trackByAdditionalForItem].paddingLeft"
                  :padding-right="item[trackByAdditionalForItem].paddingRight"
                  :gray-theme="item[trackByAdditionalForItem].grayTheme"
                  :selected="singleSelected.id === item.id"
                  :size="size"
                  :icon="item[trackByAdditionalForItem].iconName"
                  :txt-ellipsis="true"
                  :style="{ marginLeft: 'auto' }"
                />

              </label>

              <label
                v-else
                :for="multiselect + item[trackBy] + '-' + componentKey"
                :style="{ 'height': view.itemHeight ? view.itemHeight + 'px' : 'auto', width: dropWidth + 'px' }"
              >
                <vgpChip
                  :description="item.description"
                  :need-description="needDescription"
                  :text="item[trackByText]"
                  :img-url="item[trackByImgUrl]"
                  :bg-color="item[trackByBgColor]"
                  :status-color="item[trackByStatusColor]"
                  :preset="itemTemplate"
                  :size="size"
                  :icon="item[trackByIconName]"
                  :txt-ellipsis="true"
                  :selected="singleSelected.id === item.id"
                />

                <vgpChip
                  v-if="trackByAdditionalForItem && item[trackByAdditionalForItem]"
                  :text="item[trackByAdditionalForItem].text"
                  :description="item.description"
                  :need-description="needDescription"
                  :txt-color="item[trackByAdditionalForItem].txtColor"
                  :img-url="item[trackByAdditionalForItem].img-url"
                  :bg-color="item[trackByAdditionalForItem].bgColor"
                  :status-color="item[trackByAdditionalForItem].statusColor"
                  :preset="item[trackByAdditionalForItem].preset"
                  :gray-theme="item[trackByAdditionalForItem].grayTheme"
                  :round="item[trackByAdditionalForItem].round"
                  :padding-left="item[trackByAdditionalForItem].paddingLeft"
                  :padding-right="item[trackByAdditionalForItem].paddingRight"
                  :size="size"
                  :icon="item[trackByAdditionalForItem].iconName"
                  :txt-ellipsis="true"
                  :selected="singleSelected.id === item.id"
                  :style="{ marginLeft: 'auto' }"
                />

              </label>
            </div>
          </div>
        </template>

        <template v-else>
          <div
            :class="$style.no_search"
            :style="{width: popupStyles.width}"
          >
            <span> {{ noMatchesMessage }} </span>
          </div>
        </template>

        <div :style="{'height': '8px'}" />
      </div>
    </div>
  </div>
</template>

<script>
import vgpChip from './includes/chip/vgpChip.vue';
import vgpRollItems from '../rollItems/vgpRollItems.vue';
import tooltip from '../tooltips/tooltip.vue';
import sprite from '../VueIcon/svgSprite.vue';

export default {
  name: 'VgpDropdownSelect',
  components: {
    vgpChip, vgpRollItems, tooltip, sprite,
  },
  props: {
    gpAutotestName: { type: String, required: false, default: '' },
    dropList: { type: Array, required: true },
    selected: { type: [Array || Object], required: false, default: [] },
    multiselect: { type: Boolean, required: false, default: true },
    placeholder: { type: String, required: false, default: 'Placeholder' },
    groupBy: { type: String, required: false, default: '' },
    deviderColor: { type: String, required: false, default: '#424242' },
    useSearch: { type: Boolean, required: false, default: false },
    useSelectAll: { type: Boolean, required: false, default: false },
    showSelectedFirst: { type: Boolean, required: false, default: true },
    trackBy: { type: String, required: false, default: 'id' },
    trackByText: { type: String, required: false, default: 'name' },
    trackByIconName: { type: String, required: false, default: 'icon' },
    trackByImgUrl: { type: String, required: false, default: 'img' },
    trackByBgColor: { type: String, required: false, default: 'color' },
    trackByStatusColor: { type: String, required: false, default: 'color' },
    popupMaxHeight: { type: Number, required: false, default: 240 },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator(value) {
        return ['sm', 'lg', 'autoWidth'].includes(value);
      },
    },
    itemTemplate: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return ['text', 'user', 'label', 'withImg', 'withIcon', 'status'].includes(value);
      },
    },
    showResetBtn: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    highlightActiveState: { type: Boolean, required: false, default: false },
    outline: {
      type: String,
      required: false,
      default: 'always',
      validator(value) {
        return ['none', 'always', 'hover'].includes(value);
      },
    },
    onlyOneCanSelected: { type: Array, required: false, default() { return []; } },
    dropPosition: {
      type: String,
      required: false,
      default: 'absolute',
      validator(value) {
        return ['fixed', 'absolute'].includes(value);
      },
    },
    trackByTooltipContent: { type: String, required: false, default: '' },
    tooltipPosition: { type: String, required: false, default: 'left' },
    parentSelectorForCalcPositionDrop: { type: String, required: false, default: '' },
    trackByAdditionalForItem: { type: String, required: false, default: '' },
    trackByItemTooltip: { type: String, required: false, default: '' },

    // instead of placeholder
    stubTextIfAllSelected: { type: String, required: false, default: '' },
    stubTextIfAllUnselected: { type: String, required: false, default: '' },

    useSystemTooltip: { type: Boolean, required: false, default: false },

    noMatchesMessage: { type: String, required: false, default: __('no_matches_message_text') },
    fixedWidth: { type: Number, required: false, default: 0 },
    needDescription: { type: Boolean, required: false, default: false },
  },

  emits: {
    selected: [], // items[], emit after popup close if has changes selected item
    change: [], // items[], emit after every selected/unselected item
  },

  data() {
    return {
      locale: {
        search: __('filter_search_placeholder'),
        selectAll: __('multicombo_select_all_items'),
        unselectAll: __('multicombo_unselect_all_items'),
        // items: __('tag_items'),
      },
      isOpen: false,
      searchTxt: '',
      showItems: [],
      dropListInfo: {
        isTop: true,
      },
      localSelected: [],
      dropWidth: 0,
      popupStyles: {},
      redraw: 0,
      elementOnTop: false,
    };
  },

  computed: {
    selectedArray() {
      if (Array.isArray(this.selected)) {
        return this.selected;
      }

      if (this.selected) {
        return [this.selected];
      }

      return [];
    },

    multiSelected: {
      get() {
        return this.localSelected;
      },

      set(newSelected) {
        const preparedNewSelected = this.prepareNewMultiSelected(newSelected);

        this.localSelected = preparedNewSelected;
        this.emitChange(preparedNewSelected);
      },
    },

    singleSelected: {
      get() {
        return this.localSelected[0] || '';
      },

      set(newVal) {
        this.localSelected = [newVal];
        this.emitChange([newVal]);
        this.closePopup();
      },
    },

    componentKey() {
      return `drop_select_${new Date().getTime()}`;
    },

    selectedInputWidthStyle() {
      let delta = 31;

      this.isShowResetBtn && (delta += 40);
      this.useSearch && this.isOpen && (delta += 38);

      const width = `calc(100% - ${delta}px)`;

      return { width };
    },

    view() {
      switch (this.size) {
      case 'sm': return { fontSize: 14, itemHeight: 32 };
      case 'lg': return { fontSize: 16, itemHeight: 40 };
      case 'autoWidth': return { fontSize: 14 };
      default: return { fontSize: 14, itemHeight: 32 };
      }
    },

    isHighlight() {
      const highlightActive = this.highlightActiveState && (this.searchTxt || this.localSelected.length);

      return !this.disabled && (this.isOpen || highlightActive);
    },

    isShowResetBtn() {
      const length = this.localSelected.length;

      const case1 = this.multiselect && this.isOpen && this.useSearch && this.searchTxt;
      const case2 = this.multiselect && this.isOpen && !this.useSearch && length;
      const case3 = this.multiselect && !this.isOpen && length;
      const case4 = !this.multiselect && length;

      if ((length === this.dropList.length) && this.stubTextIfAllSelected) return false;

      return this.showResetBtn && (case1 || case2 || case3 || case4);
    },

    groupSet() {
      const set = [];

      this.showItems.forEach(item => {
        const groupName = (this.groupBy in item) ? item[this.groupBy] : null;

        if (!set.includes(groupName)) {
          groupName ? set.push(groupName) : set.unshift(groupName);
        }
      });

      return set;
    },

    isAllShowedItemsSelected() {
      if (this.localSelected.length < this.showItems.length) return false;

      const isAllSelected = this.showItems.every(item => this.localSelected.find(i => i[this.trackBy] === item[this.trackBy]));

      return isAllSelected;
    },

    isSelectedAllItems() {
      if (!this.localSelected) { return false; }

      if (!Array.isArray(this.localSelected)) { return false; }

      return this.localSelected.length === this.dropList.length;
    },

    isUnselectedAllItems() {
      return !this.multiSelected.length && !this.singleSelected;
    },

  },

  watch: {
    selectedArray: {
      handler(val) {
        this.localSelected = val;
      },
      immediate: true,
    },
    trackByIconName() {
      this.redraw++;
    },
    isOpen(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          this.sortShowedItemsBySelected();
          this.$refs.search_input && this.$refs.search_input.focus();
          this.checkPopupPosition();
          this.$emit('open');
        });
      } else {
        this.searchTxt = '';
        this.$emit('close');
      }
    },

    searchTxt(val) {
      this.updateShowedItemsBySearch();
    },

    showItems: {
      handler(val) {
        if (this.isOpen) {
          this.$nextTick(() => {
            this.checkPopupPosition();
          });
        }
      },
      deep: false,
    },

    dropList(data) {
      if (data.length) {
        data.forEach(item => {
          const isSelected = this.localSelected.find(s => s.id === item.id);
          const index = this.localSelected.indexOf(isSelected);

          if (index !== -1) {
            this.localSelected.splice(index, 1, item);
          }
        });
      }
      this.updateShowedItemsBySearch();
    },
  },

  beforeMount() {
    this.showItems = [...this.dropList];
  },

  mounted() {
    this.addListheners();

    if (this.fixedWidth) {
      this.dropWidth = this.fixedWidth;
    }
  },

  beforeDestroy() {
    this.removeListheners();
  },

  methods: {
    emitChange(val) {
      this.$emit('change', [...val]);
    },
    emitSelected(val) {
      this.$emit('selected', [...val]);
    },
    prepareNewMultiSelected(newSelected) {
      if (!this.onlyOneCanSelected || !this.onlyOneCanSelected.length) {
        return newSelected;
      }

      const onlyOneIds = this.onlyOneCanSelected ? this.onlyOneCanSelected.map(i => i[this.trackBy]) : [];
      const onlyOneAlreadySelected = this.localSelected.find(item => onlyOneIds.includes(item[this.trackBy]));
      const isOnlyOneExistInNewSelected = newSelected.some(item => onlyOneIds.includes(item[this.trackBy]));

      if (isOnlyOneExistInNewSelected && !onlyOneAlreadySelected) {
        return [...this.onlyOneCanSelected];
      }

      if (onlyOneAlreadySelected) {
        return newSelected.filter(item => item[this.trackBy] !== onlyOneAlreadySelected[this.trackBy]);
      }

      return newSelected;
    },
    tooltips(item) {
      const tooltip = item[this.trackByItemTooltip];

      if (!tooltip || !tooltip.content) {
        return;
      }

      return {
        placement: tooltip.placement ? tooltip.placement : 'left',
        interactive: tooltip.interactive ? tooltip.interactive : false,
        content: tooltip.content,
        delay: 0,
        duration: [0, 0],
      };
    },

    closePopup() {
      if (!this.isOpen) return;

      // if (this.isHasChangesInSelected()) {
      //   this.emitSelected([...this.localSelected]);
      // }

      this.emitSelected([...this.localSelected]);
      this.isOpen = false;
    },

    isHasChangesInSelected() {
      if (this.selectedArray.length !== this.localSelected.length) return true;

      const ids = this.selectedArray.map(i => i[this.trackBy]);
      const every = this.localSelected.every(i => ids.includes(i[this.trackBy]));

      return !every;
    },

    addListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.onScroll);
      });
    },

    removeListheners() {
      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.removeEventListener('scroll', this.onScroll);
      });
    },

    onScroll(e) {
      if (!this.isOpen) return;

      if (!e.target.closest(`#${this.componentKey}`)) {
        this.isOpen = false;
      }
    },

    onClickDropTrigger(e) {
      const path = e.path ? e.path : e.composedPath(e.target);

      if (!this.$refs.selected_wrap) {
        return;
      }

      const coords = this.$refs.selected_wrap.getBoundingClientRect();
      const elementOnTop = document.elementFromPoint(coords.left, coords.bottom);
      const elementOnTopRect = elementOnTop.getBoundingClientRect();

      if (elementOnTopRect && coords.bottom > elementOnTopRect.top && coords.bottom < elementOnTopRect.bottom - 2) {
        this.elementOnTop = true;
      } else {
        this.elementOnTop = false;
      }

      const isDropArrow = path.find(elem => {
        if (!elem.classList) return;

        return elem.classList.contains('vgp_select_drop_arrow') || elem.classList.contains('vgp_select_reset_btn');
      });

      if (isDropArrow) return;

      if (!this.multiselect) {
        this.toggleDropDown();

        return;
      }

      if (this.multiselect && !this.isOpen) {
        this.toggleDropDown();
      }
    },

    onClickResetBtn() {
      if (this.isOpen && this.useSearch) {
        this.searchTxt = '';

        return;
      }

      this.emitChange([]);
      this.emitSelected([]);
      this.localSelected = [];
    },

    getItemsByGroupName(name) {
      if (name === null) {
        return this.showItems.filter(item => !(this.groupBy in item));
      }

      return this.showItems.filter(item => item[this.groupBy] === name);
    },

    sortShowedItemsBySelected() {
      if (!this.showSelectedFirst) return;

      const res = [];

      this.groupSet.forEach(name => {
        const first = []; const
          second = [];
        const group = this.getItemsByGroupName(name);

        group.forEach(item => {
          if (this.localSelected.find(i => i[this.trackBy] === item[this.trackBy])) {
            first.push(item);
          } else second.push(item);
        });

        res.push(...first, ...second);
      });

      this.showItems = res;
    },

    updateShowedItemsBySearch() {
      if (!this.searchTxt) {
        this.showItems = [...this.dropList];

        return;
      }

      const str = this.searchTxt.toLowerCase();

      this.showItems = this.dropList.filter(item => {
        const value = item[this.trackByText].toLowerCase();

        if (value.includes(str)) {
          return true;
        }

        const additional = item[this.trackByAdditionalForItem];

        if (!additional || !additional.useInSearch) { return false; }

        return additional.text.toLowerCase().includes(str);
      });
    },

    onSelectAllBtnClick() {
      if (!this.multiselect) return;

      if (this.isAllShowedItemsSelected) {
        this.localSelected = this.localSelected.filter(item => !this.showItems.find(i => i[this.trackBy] === item[this.trackBy]));
      } else {
        this.localSelected = this.localSelected.concat(...this.showItems);
      }

      this.emitChange(this.localSelected);
      this.emitSelected(this.localSelected);
    },

    onDropListScroll(e) {
      if (this.$refs.drop.scrollTop === 0) {
        this.dropListInfo.isTop = true;
      } else {
        this.dropListInfo.isTop = false;
      }
    },

    onClickOutside(e) {
      this.closePopup();
    },

    toggleDropDown() {
      this.isOpen ? this.closePopup() : this.openPopup();
    },

    openPopup() {
      this.updateShowedItemsBySearch();
      this.isOpen = true;
    },

    checkPopupPosition() {
      if (this.dropPosition === 'fixed') {
        this.checkPopupPositionFixed();
      } else {
        this.checkPopupPositionAbsolute();
      }
    },

    checkPopupPositionAbsolute() {
      const coords = this.$refs.selected_wrap.getBoundingClientRect();

      const componentWidth = coords.width;

      const clientHeight = document.body.clientHeight;
      const dropHeight = this.$refs.drop.offsetHeight;

      const canShowBottom = (coords.bottom + dropHeight) < (clientHeight - 30);
      const canShowTop = (coords.top - dropHeight) > 10;
      const dropToBottom = (!canShowBottom && !canShowTop) || canShowBottom;

      this.popupStyles = {
        position: 'absolute',
        width: `${coords.width}px`,
        top: dropToBottom ? `${coords.height + 5}px` : `-${dropHeight + 5}px`,
      };

      this.$nextTick(() => {
        const dropWidthEl = this.$refs.drop.scrollWidth;

        if (this.fixedWidth) {
          this.dropWidth = dropWidthEl > this.fixedWidth ? dropWidthEl : this.fixedWidth;
        } else {
          this.dropWidth = dropWidthEl > componentWidth ? componentWidth : dropWidthEl;
        }
      });
    },

    checkPopupPositionFixed() {
      const coords = this.$refs.selected_wrap.getBoundingClientRect();
      const parent = document.querySelector(`${this.parentSelectorForCalcPositionDrop}`);
      let parentCoords = null;

      if (parent) {
        parentCoords = parent.getBoundingClientRect();
      }

      const componentWidth = coords.width;

      const clientHeight = document.body.clientHeight;
      const clientWidth = document.body.clientWidth;
      const dropHeight = this.$refs.drop.offsetHeight;

      const canShowBottom = clientHeight - coords.bottom - dropHeight > 30;
      const canShowTop = coords.top - dropHeight > 10;
      const dropToBottom = (!canShowBottom && !canShowTop) || canShowBottom;
      const topPosition = `${coords.top - dropHeight - (parentCoords ? parentCoords.top : 0) - 2}px`;
      const bottomPosition = `${coords.bottom - (parentCoords ? parentCoords.top : 0) + 5}px`;
      let top = null;
      let right = null;

      if (parent && this.elementOnTop && canShowTop) {
        top = topPosition;
      } else {
        top = dropToBottom ? bottomPosition : topPosition;
      }

      if (clientWidth < coords.left + this.fixedWidth) {
        right = clientWidth - coords.right;
      }

      this.popupStyles = {
        position: 'fixed',
        width: `${coords.width}px`,
        top,
        right: `${right}px`,
      };

      this.$nextTick(() => {
        const dropWidthEl = this.$refs.drop.scrollWidth;

        if (this.fixedWidth) {
          this.dropWidth = dropWidthEl > this.fixedWidth ? dropWidthEl : this.fixedWidth;
        } else {
          this.dropWidth = dropWidthEl > componentWidth ? componentWidth : dropWidthEl;
        }
      });
    },
  },
};
</script>

<style module src="./dropSelect.less" lang="less"></style>
