import app, { appIds } from '../../app';
import calculateMainWindowSize from "../../helpers/calculateMainWindowSize";

export default {
  window: function(id, vueComponent, options) {
    let _id = id.toLowerCase()
    Vue.component(`${_id}-vue`, vueComponent);
    let webixWindowView = {
      view: "window",
      id: id,
      css: options.css,
      borderless: options.borderless || false,
      zIndex: 9,
      width: options.width || 1255,
      position: function (state) {
        calculateMainWindowSize.apply(this, [state]);
      },
      modal: false,
      move: false,
      head: false,
      body: {
        rows: [
          {
            template: `<div class="${_id}_class" data-webix-window-id="${id}" id="${_id}-div-id"><${_id}-vue></${_id}-vue></div>`
          }
        ]
      },
      on: {
        onShow: function() {
          app.trigger("popup:closeOther", id);
          new Vue({ el: `#${_id}-div-id` });
        },
        onHide: function() {
          if (options.onHide) {
            options.onHide();
          }
        }
      }
    }

    app.on("body:resize", function (data) {
      $$(id) && $$(id).resize();
    });

    app.on("leftSideBar:changedMode", function (data) {
      $$(id) && $$(id).resize();
    });

    return webixWindowView;
  }
}