<template>
  <div
    :class="[
      $style.cell_trigger,
      isOpen ? $style.edit : '' ,
      crossout && !isOpen ? $style.crossout :'',
      !editable ? $style.not_allowed : '',
    ]"
    :style="{opacity: isOpen ? 1 : cellOpecity}"
    @click="clickOnCell"
  >
    <span
      :class="[$style.text_wrap,
               $style.text_right,
               $style.select_none,
      ]"
    >
      {{ timeText }}
    </span>
  </div>
</template>

<script>
import timeParser from '../../../../helpers/timeParser';
import projectsModel from '../../../../models/projects';
 

export default {
  name: 'TaskCell',
  components: { },
  props: {
    item: { type: Object, required: true },
    editable: { type: Boolean, required: false, default: true },
    property: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      durationData: {},
      isOpen: false,
      eventsIds: [],
      clickOnCurrentCell: false, 
    };
  },

  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },
    timeText() {
      this.durationData = projectsModel.getProjectConfig(this.item.gantt_id).durationData;
      const text = timeParser.output(this.item[this.property], {
        durationData: this.durationData,
        prop: this.property,
      });

      return text;
    },

    componentId(){
      let t = new Date().getTime();
      let random = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
      return t + '-' + random;
    }
  },

  mounted() {
    document.addEventListener('contextmenu', this.contextMenuEvent);
    this.addPopupEventListheners();
  },

  beforeDestroy() {
    this.hidePopup();
    document.removeEventListener('contextmenu', this.contextMenuEvent);
    this.eventsIds.map(id => $$('timeTrackingPopup').detachEvent(id));
  },

  methods: {
    clickOnCell(e) {
      if (this.disabled || !this.editable) {
        e.preventDefault();

        return;
      }

      this.clickOnCurrentCell = true;
      this.isOpen ? this.hidePopup() : this.openPopup();
    },
    contextMenuEvent(e) {
      this.hidePopup();
    },

    hidePopup() {
      gantt.callEvent('hideTimeTrackingPopup');
      this.clickOnCurrentCell = false;
    },

    openPopup() {
      const pos = this.$el.getBoundingClientRectWrapper();

      gantt.callEvent('showTimeTrackingPopup', [
        'task',
        pos,
        this.item.id,
      ]);
    },

    addPopupEventListheners() {
      const component = this;
      const popup = $$('timeTrackingPopup');

      const ev_id = popup.attachEvent('onHide', () => {
        component.isOpen = false;
        component.clickOnCurrentCell = false;
      });

      const ev_id_1 = popup.attachEvent('onShow', () => {
        let isSame = popup.config.task_id === component.item.id;
        component.isOpen = isSame;
        component.clickOnCurrentCell = isSame; 
      });

      this.eventsIds.push(ev_id, ev_id_1);
    },
  },
};
</script>

<style module src="./cell.less" lang="less">
</style>
