<template>
  <div class="row-wrapper">
    <div class="feature-name">
      <div class="name">
        {{ locales(feature.locale) }}
      </div>
    </div>
    <div class="plans-access">
      <table-cell
        v-for="(plan, index) in plans"
        :key="index"
        :plan="plan"
        :feature="feature"
      />
    </div>
  </div>
</template>

<script>
import tableCell from './tableCell.vue';

export default {
  components: { tableCell },
  props: [
    'plans',
    'feature',
  ],
  data() {
    return {
      locales: __
    };
  },
};
</script>

<style scoped src="../less/paymentPlans.less"></style>
