import Vue from 'vue';
import _ from 'lodash';
import app from '../app';
import { api, internalApi } from '$$store/api';
import projectComponent from '../components/Project';
import constants from '$$helpers/constants';

const TeamStore = {
  namespaced: true,
  state: {
    projectStatuses: [],
    colors: [],
    projectsWithStatus: null,
    usersAccountRole: [],
    currency: null,
    enableCurrency: false,
    // this is map for errors
    settingErrors: {
      profile: [],
      notifications: [],
      billing: [],
      resources: [],
      team: [],
      statuses: [],
      account_rights: [],
      project_rights: [],
      columns: [],
      security: [],
      integrations: [],
    },
  },
  getters: {
    statusDataForProject: state => id => {
      let activeOptionInProjectStatus;

      if (state.projectsWithStatus) activeOptionInProjectStatus = id && state.projectsWithStatus[+id];
      if (!activeOptionInProjectStatus) return null;

      const projectStatusData = state.projectStatuses?.find(statusItem => statusItem.id === activeOptionInProjectStatus?.status_id);
      const selectOption = projectStatusData && projectStatusData.options.find(option => option.id === activeOptionInProjectStatus.option_id);

      if (selectOption) {
        selectOption.labelColor = state.colors?.data?.find(color => color.id === selectOption.color).hex4;
        selectOption.background = state.colors?.data?.find(color => color.id === selectOption.color).hex3;
      }

      const options = _.cloneDeep(projectStatusData?.options);

      return (selectOption && options) && { selectOption, options: options?.sort((a, b) => a.order - b.order) };
    },
    usersAccountRole: state => state.usersAccountRole,
    getSettingErrors: state => state.settingErrors,
    getSettingErrorsByName: state => name => state.settingErrors[name],
    colors: state => state.colors,
    currency: state => state.currency,
    enableCurrency: state => state.enableCurrency,
    projectsWithStatus: state => id => { if (state.projectsWithStatus) return state.projectsWithStatus[id]; },
    projectStatuses: state => state.projectStatuses,
    getUserAccountRoleIdByUserId: state => id => state.usersAccountRole?.find(item => item.userId === id)?.roleAccountId,
  },
  mutations: {
    setColors(state, colors) {
      state.colors = colors;
    },
    setProjectStatuses(state, data) {
      state.projectStatuses = data;
    },
    setProjectsWithStatus(state, data) {
      state.projectsWithStatus = data;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setEnableCurrency(state, flag) {
      state.enableCurrency = flag;
    },
    setSettingErrorsByName(state, payload) {
      const newData = payload.data;
      const name = payload.name;

      if (name in state.settingErrors) {
        if (Array.isArray(newData)) {
          state.settingErrors[name] = [...state.settingErrors[name], ...newData];
        } else {
          state.settingErrors[name].push(newData);
        }
      }
    },

    deleteSettingErrorsByCategoryAndName(state, payload) {
      const key = payload.key;
      const name = payload.name;

      if (name in state.settingErrors) {
        state.settingErrors[name] = state.settingErrors[name].filter(item => item.key !== key);
      }
    },

    setUsersAccountRole(state, data) {
      state.usersAccountRole = data;
    },

    updateUserAccountRole(state, payload) {
      state.usersAccountRole = state.usersAccountRole.map(item => {
        if (item.userId === payload.user_id) {
          item.roleAccountId = payload.role_id;
        }

        return item;
      });
    },

    deleteUserAccountRole(state, payload) {
      state.usersAccountRole = state.usersAccountRole.filter(item => item.user_id !== payload.user_id);
    },

    addUserAccountRole(state, payload) {
      const { user_id, role_account_id } = payload;

      state.usersAccountRole = [...state.usersAccountRole, { userId: user_id, roleAccountId: role_account_id }];
    },

    updateProjectsWithStatus(state, data) {
      const projectsWithStatus = state.projectsWithStatus;

      switch (data.action) {
      case 'update':

        projectsWithStatus[data.currentProject].option_id = data.id;
        break;
      case 'delete':
        const defaultOption = state.projectStatuses[0].options.find(option => option.is_default === 1).id;

        for (const key in projectsWithStatus) {
          if (projectsWithStatus[key].option_id === data.optionId) {
            projectsWithStatus[key].option_id = defaultOption;
          }
        }
      }
      state.projectsWithStatus = projectsWithStatus;
    },
    addProjectsWithStatus(state, data) {
      const projectsWithStatus = state.projectsWithStatus;

      Vue.set(projectsWithStatus, [data.ganttId], {
        status_id: data.status_id,
        option_id: data.option_id,
      });
      state.projectsWithStatus = projectsWithStatus;
    },
    updateProjectStatusesOptions(state, data) {
      const statusesData = state.projectStatuses;
      const status = statusesData.find(item => item.id === (data.statusId || data.id)).options;

      switch (data.action) {
      case 'update':
        const option = status.find(item => item.id === (data.optionId || data.statusData.id));

        Object.keys(data.statusData).forEach(key => {
          if (['color', 'value', 'order'].includes(key)) {
            option[key] = data.statusData[key];
          }
        });
        break;
      case 'add':
        status.push(data.statusData);
        break;
      case 'delete':
        const statusItem = status.findIndex(item => item.id === data.optionId);

        status.splice(statusItem, 1);
        break;
      case 'updateOrders':
        status.forEach(option => {
          data.orders.forEach(item => {
            if (item.id === option.id) {
              option.order = item.order;
            }
          });
        });

        break;
      }

      state.statusesOptions = statusesData;
    },
  },
  actions: {
    init({ dispatch, rootState }) {
      if (rootState.appMode.isBase) {
        dispatch('loadStatusesData');
        dispatch('loadColors');
      }
      dispatch('loadCurrency');
      app.checkInit('team');
    },
    async loadStatusesData({ commit }) {
      const statusesData = await api.get('/projectStatuses');

      commit('setProjectStatuses', statusesData.data.statuses);
      commit('setProjectsWithStatus', statusesData.data.statusToProject);
    },

    setUsersAccountRole({ commit }, payload) {
      commit('setUsersAccountRole', payload);
    },

    setSettingErrorsByName({ commit }, payload) {
      commit('setSettingErrorsByName', payload);
    },
    loadCurrency({ commit }) {
      const { currency, isActiveCurrency } = user.team;

      commit('setCurrency', currency);
      commit('setEnableCurrency', !!isActiveCurrency);
    },
    async setCurrency({ dispatch }, payload) {
      await internalApi.put('/team', payload);
      dispatch('updateCurrency', payload);
    },
    updateCurrency({ commit }, payload) {
      commit('setCurrency', payload.currency);
      commit('setEnableCurrency', payload.isActiveCurrency);
      app.trigger('currencyUpdated');
    },
    async loadColors({ commit }) {
      const colors = await api.get('/colors');

      commit('setColors', colors);
    },
    async createNewStatus({ commit, state }) {
      // need statusId if we will have more statuses
      const order = state.projectStatuses[0].options.sort((a, b) => a.order - b.order).reverse()[0].order + 1;
      const data = {};
      const statusData = {
        value: '',
        color: 17,
        order,
        is_default: 0,
      };
      const id = await api.post('/projectStatuses/option', statusData);

      data.statusData = statusData;
      data.action = 'add';
      data.statusId = state.projectStatuses[0].id;
      data.statusData.id = id.data.id;
      commit('updateProjectStatusesOptions', data);
    },
    async updateStatus({ commit }, data) {
      await api.put(`/projectStatuses/option/${data.optionId}`, data.statusData);
      data.action = 'update';
      commit('updateProjectStatusesOptions', data);
    },
    async deleteStatus({ commit }, data) {
      await api.delete(`/projectStatuses/option/${data.optionId}`);
      data.action = 'delete';
      commit('updateProjectStatusesOptions', data);
      commit('updateProjectsWithStatus', data);
    },
    async updateProjectStatus({ commit }, data) {
      data.action = 'update';
      await projectComponent.updateProjectStatus(data.id, +data.currentProject, data.statusId);
      commit('updateProjectsWithStatus', data);
    },
    async addProjectStatus({ commit, state }, ganttId) {
      const projectStatuses = state.projectStatuses[0];
      const data = {};

      data.ganttId = ganttId;
      data.status_id = projectStatuses.id;
      data.option_id = projectStatuses.options.find(option => option.is_default === 1).id;
      commit('addProjectsWithStatus', data);
    },

    async updateOrders({ commit }, data) {
      data.action = 'updateOrders';
      commit('updateProjectStatusesOptions', data);
      await api.post('/projectStatuses/option/orders', data.orders);
    },
  },
};

export default TeamStore;
