const integrationWrongWorkspacePopupId = 'integrationWrongWorkspacePopup';
const integrationWorkspaceAlreadyUsedPopupId = 'integrationWorkspaceAlreadyUsedPopup';
const integrationUserAlreadyConnectedPopupId = 'integrationUserAlreadyConnectedPopup';

webix.ui({
  view: 'ganttproWindowPopup',
  id: integrationWrongWorkspacePopupId,
  zIndex: 150,
  css: 'integrationWrongWorkspacePopup',
  header: {
    label: __('integration_slack_wrong_workspace_title'),
  },
  body: {
    css: 'body',
    width: 405,
    rows: [
      {
        autoheight: true,
        template({ type }) {
          if (!type) return null;

          return `<div class="content"><p>${__(`integration_${type}_wrong_workspace_text`)}</p></div>`;
        },
        data: {},
      },
      {
        height: 25,
      },
      {
        view: 'button',
        css: 'button',
        label: __('common_ok'),
        width: 96,
        on: {
          onItemClick() {
            $$(integrationWrongWorkspacePopupId).hide();
          },
        },
      },
      { height: 25 },
    ],
  },
}).hide();

webix.ui({
  view: 'ganttproWindowPopup',
  id: integrationWorkspaceAlreadyUsedPopupId,
  zIndex: 150,
  css: 'integrationWorkspaceAlreadyUsedPopup',
  header: {
    label: __('integration_slack_workspace_already_used_title'),
  },
  body: {
    css: 'body',
    width: 405,
    rows: [
      {
        autoheight: true,
        template({ type }) {
          if (!type) return null;

          return `<div class="content"><p>${__(`integration_${type}_workspace_already_used_text`)}</p> </div>`;
        },
        data: {},
      },
      {
        height: 25,
      },
      {
        view: 'button',
        css: 'button',
        label: __('common_ok'),
        width: 96,
        on: {
          onItemClick() {
            $$(integrationWorkspaceAlreadyUsedPopupId).hide();
          },
        },
      },
      { height: 25 },
    ],
  },
}).hide();

webix.ui({
  view: 'ganttproWindowPopup',
  height: 205,
  zIndex: 150,
  id: integrationUserAlreadyConnectedPopupId,
  css: 'integrationUserAlreadyConnectedPopup',
  header: {
    label: __('integration_slack_user_already_connected_title'),
  },
  body: {
    css: 'body',
    width: 405,
    rows: [
      {
        autoheight: true,
        template({ type }) {
          if (!type) return null;

          return `<div class="content"><p>${__(`integration_${type}_user_already_connected_text`)}</p> </div>`;
        },
        data: {},
      },
      {
        height: 25,
      },
      {
        view: 'button',
        css: 'button',
        label: __('common_ok'),
        width: 96,
        on: {
          onItemClick() {
            $$(integrationUserAlreadyConnectedPopupId).hide();
          },
        },
      },
      { height: 25 },
    ],
  },
}).hide();

const POPUP_EVENTS_MAPPING = {
  member_or_admin_incorrect_workspace: {
    popupId: integrationWrongWorkspacePopupId,
    title: '_wrong_workspace_title',
  },
  workspace_already_connected_to_other_team: {
    popupId: integrationWorkspaceAlreadyUsedPopupId,
    title: '_workspace_already_used_title',
  },
  user_already_connected_to_integration: {
    popupId: integrationUserAlreadyConnectedPopupId,
    title: '_user_already_connected_title',
  },
};

const outerObject = {
  show(data) {
    const { type, code } = data;

    const { popupId, title } = POPUP_EVENTS_MAPPING[code];

    $$(popupId).getBody().getChildViews()[0].parse({ type });
    $$(popupId).updateHead(null, __(`integration_${type}${title}`));
    $$(popupId).show();
  },
};

export default outerObject;
