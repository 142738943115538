export default {
  taskMap: state => state.taskMap,
  isOpenPastePopup: state => state.isOpenPastePopup,
  hasCopiedTask: state => state.hasCopiedTask,
  copiedTask: state => state.copiedTask,
  specialPasteConfig: state => state.specialPasteConfig,
  specialPasteIconId: state => state.specialPasteIconId,
  popupCoordinates: state => state.popupCoordinates,
  isInsertingProcess: state => state.isInsertingProcess,
};
