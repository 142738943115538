const COLUMNS = {
  COST_COLUMNS: [
    {
      id: 'total_price',
      property: 'total_price',
      text: __('gantt_grid_total_price'),
      tooltip: __('total_price_custom_grid_tooltip'),
      extra: {
        helper: 'cost',
      },
      disabled: false,
      editor: 'number',
      align: 'center',
      resizable: true,
    },
    {
      id: 'actual_cost',
      property: 'actual_cost',
      text: __('gantt_grid_actual_cost'),
      tooltip: __('actual_cost_custom_grid_tooltip'),
      disabled: false,
      editor: 'number',
      align: 'center',
      resizable: true,
    },
  ],
  PREPEND_COLUMNS: [
    {
      id: 'comments',
      property: 'comments',
      text: __('gantt_task_comments'),
      tooltip: __('comments_custom_grid_tooltip'),
      width: 24,
      editor: 'icon',
      iconName: 'message',
      emptyHeader: true,
      resizable: false,
      hardWidth: true,
      stickyLeft: 1,
    },
    {
      id: 'attachments',
      property: 'attachments',
      text: __('gantt_grid_attachments'),
      tooltip: __('attachments_custom_grid_tooltip'),
      width: 22,
      editor: 'icon',
      iconName: 'clip',
      emptyHeader: true,
      resizable: false,
      hardWidth: true,
      stickyLeft: 2,
    },
  ],
  DEFAULT_COLUMNS: [
    {
      id: 'project_name',
      property: 'project_name', // project_name
      propertyExtra: 'project_extra',
      text: __('gantt_grid_project_name'),
      tooltip: __('project_name_custom_grid_tooltip'),
      editor: 'project',
      extra: {
        text: __('gantt_grid_option_project_name'),
        name: 'project_name_extra',
      },
      resizable: true,
    },
    {
      id: 'start_date',
      property: 'start_date',
      text: __('gantt_grid_start_date'),
      tooltip: __('start_date_custom_grid_tooltip'),
      editor: 'datepicker',
      disabled: false,
      extra: {
        text: __('gantt_grid_option_start_date'),
        name: 'start_date_extra',
      },
      resizable: true,
    },
    {
      id: 'end_date',
      property: 'end_date',
      text: __('gantt_grid_end_date'),
      tooltip: __('end_date_custom_grid_tooltip'),
      editor: 'datepicker',
      disabled: false,
      extra: {
        text: __('gantt_grid_option_end_date'),
        name: 'end_date_extra',
      },
      resizable: true,
    },
    {
      id: 'duration',
      property: 'duration',
      text: __('gantt_grid_duration'),
      tooltip: __('duration_custom_grid_tooltip'),
      editor: 'text',
      type: 'time',
      resizable: true,
    },
    {
      id: 'progress',
      property: 'progress',
      text: __('gantt_grid_progress'),
      tooltip: __('progress_custom_grid_tooltip'),
      editor: 'number',
      type: 'percent',
      resizable: true,
    },
    {
      id: 'estimation',
      property: 'estimation',
      text: __('gantt_grid_estimation'),
      tooltip: __('estimation_custom_grid_tooltip'),
      editor: 'text',
      type: 'time',
      resizable: true,
    },
    {
      id: 'assigned',
      property: 'assigned', // resource_id
      text: __('gantt_grid_assigned'),
      tooltip: __('assigned_custom_grid_tooltip'),
      editor: 'resources',
      resizable: true,
    },
    {
      id: 'status',
      property: 'status',
      text: __('gantt_grid_status'),
      tooltip: __('status_custom_grid_tooltip'),
      editor: 'selectList',
      listType: 'status',
      resizable: true,
      forJiraProject: {},
    },
    {
      id: 'priority',
      property: 'priority',
      text: __('gantt_grid_priority'),
      tooltip: __('priority_custom_grid_tooltip'),
      editor: 'selectList',
      listType: 'priority',
      resizable: true,
      forJiraProject: {},
    },
    {
      id: 'creator',
      property: 'creator', // creator
      text: __('gantt_grid_creator'),
      tooltip: __('creator_custom_grid_tooltip'),
      disabled: true,
      editor: false,
      align: 'left',
      resizable: true,
    },
    {
      id: 'created_at',
      property: 'created_at', // create_date
      text: __('gantt_grid_create_date'),
      tooltip: __('create_date_custom_grid_tooltip'),
      editor: 'datepicker',
      disabled: true,
      resizable: true,
    },
    {
      id: 'time_tracking',
      property: 'time_tracking',
      text: __('gantt_grid_time_tracking'),
      tooltip: __('time_tracking_custom_grid_tooltip'),
      editor: 'timelog',
      resizable: true,
    },
  ],
  STATIC_COLUMNS: [
    {
      id: 'text',
      width: 200,
      property: 'text',
      text: __('gantt_grid_task_name'),
      editor: 'task',
      resizable: true,
    },
  ],
};

export { COLUMNS };
