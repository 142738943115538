<template>
  <div
    :class="[$style.box]"
    :style="{background: params.background}"
  >
    <div
      :class="$style.icon"
    >
      <svg-sprite
        :name="params.iconName"
        :size="params.iconSize"
        :type="params.iconType"
        :color="params.iconColor"
      />
    </div>
    <div :class="$style.message">
      <div v-html="text" />
    </div>
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';

export default {
  name: 'InlineNotification',
  components: {
    svgSprite,
  },
  props: {
    type: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      params: {
        background: '#FFEBCC',
        iconName: 'warning-1',
        iconSize: '20',
        iconType: 'bold',
        iconColor: '#FF9A00',
      },
    };
  },
  watch: {
    type(val) {
      switch (val) {
      case 'warning': {
        this.params = {
          background: '#FFEBCC',
          iconName: 'warning-1',
          iconSize: '20',
          iconType: 'bold',
          iconColor: '#FF9A00',
        };
        break;
      }

      default: {
        break;
      }
      }
    },
  },

};
</script>

<style module src='./less/nofitication.less'></style>
