<template>
  <div
    :key="'vgp-chips-' + componentKey"
    :class="{
      [$style.vgp_chips]: true,
      [$style.vgp_chips_label]: preset === 'label',
      [$style.ellipsis_true]: txtEllipsis,
      [$style.width100]: fillWidth
    }"
    :style="presetStyle"
    @click="$emit('click', $event)"
  >
    <div
      v-if="isUse('imgUrl')"
      :class="{
        [$style.avatar]: isUse('isAvatar'),
        [$style.vgp_chips_image]: true
      }"
      :style="{
        'background-image': 'url('+ imgUrl + ')',
        width: imgSize + 'px',
        height: imgSize + 'px',
      }"
    />

    <div
      v-if="isUse('statusColor')"
      :class="[$style.circle_status]"
      :style="{'background-color': statusColor}"
    />
    <svg-sprite
      v-if="isUse('icon')"
      :name="icon.name"
      :type="icon.type"
      :size="icon.size"
      :color="selected ? 'inherit' : icon.color"
      :style="{ 'margin-right': '8px'}"
    />

    <div
      v-if="isUse('text') || (description && needDescription)"
      :class="[$style.vgp_chips_name_block, $style.ellipsis]"
    >
      <span
        :class="[ $style.vgp_chips_name, $style.ellipsis ]"
      >
        {{ text }}
      </span>
      <span
        v-if="description && needDescription"
        :class="[ $style.vgp_chips_description, selected ? $style.vgp_chips_description_select : '']"
      >
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
import svgSprite from '../../../VueIcon/svgSprite.vue';

export default {
  name: 'VgpChips',
  components: { svgSprite },
  props: {
    txtColor: { type: String, required: false, default: 'inherit' },
    bgColor: { type: String, required: false, default: 'transparent' },
    statusColor: { type: String, required: false, default: '' },
    imgUrl: { type: String, required: false, default: '' },
    icon: { type: Object, required: false, default: null },
    text: { type: String, required: false, default: '' },
    description: { type: String, required: false, default: '' },
    isAvatar: { type: Boolean, required: false, default: false },
    round: { type: Number, required: false, default: 60 },
    imgSize: { type: Number, required: false, default: 20 },
    preset: {
      type: String,
      required: false,
      default: 'custom',
      validator(value) {
        return ['text', 'user', 'label', 'withImg', 'withIcon', 'custom', 'avatar', 'status'].includes(value);
      },
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator(value) {
        return ['sm', 'lg'].includes(value);
      },
    },
    paddingTop: { type: Number, required: false, default: 0 },
    paddingBottom: { type: Number, required: false, default: 0 },
    paddingLeft: { type: Number, required: false, default: 0 },
    paddingRight: { type: Number, required: false, default: 0 },
    useInStylePresetFromOuter: { type: Array, required: false, default: () => [] },
    grayTheme: { type: Boolean, required: false, default: false },
    txtEllipsis: { type: Boolean, required: false, default: false },
    fillWidth: { type: Boolean, required: false, default: false },
    selected: { type: Boolean, required: false, default: false },
    needDescription: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      presetsOpt: {
        text: ['text', 'txtColor'],
        user: ['text', 'imgUrl', 'isAvatar'],
        withImg: ['text', 'imgUrl'],
        withIcon: ['text', 'icon'],
        label: ['text', 'bgColor', 'txtColor'],
        avatar: ['isAvatar', 'imgUrl', 'bgColor'],
        status: ['text', 'statusColor'],
      },

      presetStyles: {
        text: { paddings: [0, 8, 0, 8], borderRadius: 0 },
        user: { paddings: [0, 8, 0, 8], borderRadius: 0 },
        withImg: { paddings: [0, 8, 0, 8], borderRadius: 0 },
        withIcon: { paddings: [0, 8, 0, 8], borderRadius: 0 },
        label: { paddings: [0, 8, 0, 8], borderRadius: 4 },
        avatar: { paddings: [0, 0, 0, 0], borderRadius: 0 },
        status: { paddings: [0, 0, 0, 0], borderRadius: 0 },
      },
    };
  },

  computed: {
    componentKey() {
      const min = 1000;
      const max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },

    fontSize() {
      switch (this.size) {
      case 'sm': return '14px';
      case 'lg': return '16px';
      default: return '14px';
      }
    },

    paddings() {
      const customPaddings = [this.paddingTop, this.paddingLeft, this.paddingBottom, this.paddingRight];
      const definePaddings = this.preset === 'custom' ? customPaddings : this.presetStyles[this.preset].paddings;
      const checkPaddings = this.grayTheme || this.preset === 'label' || this.preset === 'custom' ? definePaddings : [0, 0, 0, 0];

      return getPaddings(...checkPaddings);

      function getPaddings(top, right, bottom, left) {
        return {
          'padding-left': `${left}px`,
          'padding-right': `${right}px`,
          'padding-top': `${top}px`,
          'padding-bottom': `${bottom}px`,
        };
      }
    },

    bgColorStyle() {
      if (this.grayTheme || this.preset === 'avatar') return '#f2f2f2';

      if (this.preset === 'custom'
          || this.preset === 'label'
          || this.useInStylePresetFromOuter.includes('bgColor')
          || this.isUse('bgColor')
      ) return this.bgColor;

      return 'transparent';
    },

    txtColorStyle() {
      if (this.preset === 'label') {
        return '#fff';
      }

      if (this.preset === 'custom'
          || this.useInStylePresetFromOuter.includes('txtColor')
          || this.isUse('txtColor')
      ) return this.txtColor;
      if (this.preset === 'text' && this.txtColor && !this.selected) return this.txtColor;

      return 'inherit';
    },

    borderRadius() {
      if (this.grayTheme || this.preset === 'avatar') {
        return '100px';
      }

      if (this.preset === 'custom'
          || this.useInStylePresetFromOuter.includes('round')
      ) return `${this.round}px`;

      return `${this.presetStyles[this.preset].borderRadius}px`;
    },

    presetStyle() {
      return {
        ...this.paddings,
        'border-radius': this.borderRadius,
        'font-size': this.fontSize,
        'background-color': this.bgColorStyle,
        color: this.txtColorStyle,
        'min-height': this.preset === 'avatar' ? '20px' : '22px',
      };
    },
  },

  methods: {
    isUse(opt) {
      const isBool = typeof this[opt] === 'boolean';
      const ifExistVal = isBool || !!this[opt];

      if (this.preset === 'custom') {
        return ifExistVal;
      }

      const userPreset = this.presetsOpt[this.preset];

      return userPreset.includes(opt) && ifExistVal;
    },
  },
};
</script>

<style module src="./chip.less" lang="less"></style>
