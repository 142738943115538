import { BrowserAuthError, ServerError, BrowserCacheLocation } from '@azure/msal-browser';
import * as msal from '@azure/msal-browser';
import { v4 as uuidv4 } from 'uuid';
import usersAttachmentTypeModel from '../../../../models/usersAttachmentType';
import globalStore from '../../../../store/main';

const OneDriveAttachmentsController = {

  loadMicrosoftApiAndSetMicrosoftUser() {
  },

  createDocument(mimeType, taskData) {

  },

  setActiveMicrosoftAccount(authResult) {

  },

  onFilesPicked({ selectedFilesData, taskData }) {
    return new Promise(async (resolve, reject) => {
      const payload = {
        ganttId: taskData.gantt_id,
        taskId: taskData.id,
        type: 'oneDrive',
        raw: JSON.stringify(selectedFilesData),
      };

      await globalStore.dispatch('attachments/createAttachmentFromExternalProvider', payload);
      // modelsAttachment.uploadFileFromExternalProvider(out, innerObject.task.gantt_id, innerObject.task.id);
      resolve();
    });
  },

  setMicrosoftUserToStore() {
  },

  async init() {
  },

  async getToken(selectAccount = false) {

  },

  async pickFiles(taskData) {
    this.taskData = taskData;
    let redirect_uri = GT.siteConfig + "/onedrive";

    var odOptions = {
      clientId: GT.attachmentExternal.oneDrive.clientId,
      action: "query",
      viewType: "files",
      multiSelect: true,
      advanced: {
        liginHint: "",
        queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl,webUrl,webDavUrl,parentReference",
        redirectUri: redirect_uri,
      },
      success: (files) => {
        if (files?.value?.length > 0) {
          this.onFilesPicked({
            selectedFilesData: files.value,
            taskData: this.taskData,
          });
        }
      },
      cancel: () => {},
      error: (error) => {
        console.error("Error OneDrive: " + error);
      }
    }

    OneDrive.open(odOptions);
  },

  async addAccount() {},
  async changeAccount(taskData) {
    // `https://login.live.com/oauth20_logout.srf?client_id=${client_id}&redirect_uri=${redirect_uri}`

    const client_id = GT.attachmentExternal.oneDrive.clientId;
    let redirect_uri = GT.siteConfig + "/onedrive/logout";

    const childWindow = window.open(`https://login.live.com/oauth20_logout.srf?client_id=${client_id}&redirect_uri=${redirect_uri}`, '', 'width=300, height=300');

    window.addEventListener('message', (event) => {
      if (event.source === childWindow) {
          // let type = event.data.type;

          // if (type === "onedriveLogout") {
          //   this.pickFiles(taskData)
          // }
      }
    });
  },

  async launchPicker(selectAccount = false) {},

  async messageListener(message) {},
};

export default OneDriveAttachmentsController;
