<template>
  <div
    :class="$.compare_dropdown"
    v-click-outside="close"
  >
    <div
      ref="button"
      :class="[
        $.button,
        showMenu ? $.active : '',
      ]"
      @click="toggle"
    >
      <svg-sprite
        :class="$.icon"
        name="filter"
        :size="20"
      />
      <div :class="$.label">
        {{ label }}
      </div>
      <svg-sprite
        :class="$.icon"
        :name="showMenu ? 'arrow-top' : 'arrow-down'"
        type="bold"
        :size="20"
      />
    </div>

    <div :class="$select.select">
      <context-menu
        v-if="showMenu"
        :position="positionMenu"
        style="min-width: 215px"
      >
        <template #body>
          <template v-for="(item, index) in menu">
            <div
              v-if="item.type === 'select'"
              :key="index"
              :class="[
                $select.menu_item,
                $select.item_level,
                showSubmenuName === item.name ? $select.active : '',
                !item.list.length ? $select.disabled : '',
              ]"
              @mouseenter="enterSelect(item.name, $event.target)"
              @mouseleave="leaveSelect"
            >
              <div :class="$select.text">
                {{ item.text }}
              </div>
              <svg-sprite
                :class="$select.icon"
                name="drop-right"
                type="bold"
                size="24"
                :style="{
                  'margin-right': 0,
                  'margin-left': 'auto',
                }"
              />

              <context-menu
                v-if="showSubmenuName === item.name && item.list.length"
                :position="positionSubmenu"
                side="right"
                :offset="{ top: -8 }"
                :style="{
                  'min-width': '215px',
                  'border-top-left-radius': 0,
                  'border-bottom-left-radius': 0,
                }"
              >
                <template #body>
                  <div
                    v-for="(item2, index2) in item.list"
                    :key="index2"
                    :class="[
                      $select.menu_item,
                      item.name === overviewType && item2.id === +baselineId ? $select.active : '',
                    ]"
                    @click="onSelect(item, item2)"
                  >
                    <div :class="$select.text">
                      {{ item2.name }}
                    </div>
                  </div>
                </template>
              </context-menu>
            </div>

            <div
              v-else
              :key="index"
              :class="[
                $select.menu_item,
                item.name === overviewType ? $select.active : '',
              ]"
              @click="onSelect(item)"
            >
              <div :class="$select.text">
                {{ item.text }}
              </div>
            </div>
          </template>
        </template>
      </context-menu>
    </div>
  </div>
</template>

<script>
import constants from '../../../helpers/constants';

export default {
  props: {
    overviewType: {
      type: String,
      default: constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED,
    },
    baselineId: {
      type: String,
      default: null,
    },
    baselines: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: {
    onOpen: null,
    onSelect: Object,
  },
  data() {
    return {
      showMenu: false,
      positionMenu: null,
      showSubmenuName: '',
      positionSubmenu: null,
    };
  },
  computed: {
    menu() {
      return [
        {
          type: 'item',
          name: constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED,
          text: __(`overview_type_${constants.OVERVIEW_TYPES.ACTUAL_TO_PLANNED}`),
        },
        {
          type: 'select',
          name: constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE,
          text: __(`overview_type_${constants.OVERVIEW_TYPES.ACTUAL_TO_BASELINE}`),
          list: this.baselines,
        },
      ];
    },
    label() {
      return this.menu.find(m => m.name === this.overviewType).text;
    },
  },
  methods: {
    toggle() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.positionMenu = this.$refs.button.getBoundingClientRect();
        this.$emit('onOpen');
      }
    },
    close() {
      this.showMenu = false;
      this.showSubmenuName = '';
    },
    enterSelect(selectName, el) {
      this.showSubmenuName = selectName;
      this.positionSubmenu = el.getBoundingClientRect();
    },
    leaveSelect() {
      this.showSubmenuName = '';
    },
    onSelect(item, baseline) {
      const typeOverview = item.name;
      const baselineId = baseline ? `${baseline.id}` : null;

      userExtAnalytics.log('overview_calc_type', { type: typeOverview });
      this.close();
      this.$emit('onSelect', { typeOverview, baselineId });
    },
  },
};
</script>

<style module="$" src="../less/compareDropdown.less"></style>
<style module="$select" src="../../header/less/select.less"></style>
