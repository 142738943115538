var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.componentKey,
      ref: "inputField",
      class:
        ((_obj = {}),
        (_obj[_vm.$style.active] = _vm.isHighlight),
        (_obj[_vm.$style.wrap] = true),
        (_obj[_vm.$style.open] = _vm.isOpen && !_vm.disable),
        (_obj[_vm.$style.disable] = _vm.disable),
        (_obj[_vm.$style.outline_none] = _vm.outline === "none"),
        (_obj[_vm.$style.outline_hover] = _vm.outline === "hover"),
        (_obj[_vm.$style.outline_always] = _vm.outline === "always"),
        (_obj[_vm.$style.pointer] = true),
        _obj),
      style: {
        "font-size": _vm.view.fontSize + "px",
        height: _vm.view.itemHeight + "px",
      },
      attrs: { id: _vm.componentKey },
      on: { click: _vm.inputClick },
    },
    [
      _c(
        "div",
        {
          class:
            ((_obj$1 = {}), (_obj$1[_vm.$style.select_inner] = true), _obj$1),
        },
        [
          _vm.selectedDate
            ? _c("span", { class: _vm.$style.main_txt }, [
                _vm._v(" " + _vm._s(_vm.stringSelected) + " "),
              ])
            : _c("span", { class: _vm.$style.placeholder_txt }, [
                _vm._v(" " + _vm._s(_vm.placeholder) + " "),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { class: [_vm.$style.d_flex, _vm.$style.ai_center] },
            [
              _c("sprite", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowResetBtn,
                    expression: "isShowResetBtn",
                  },
                ],
                class: [_vm.$style.mr_8, "vgp_select_reset_btn"],
                attrs: { name: "close-2", type: "bold", size: 16 },
                on: { click: _vm.onClickResetBtn },
              }),
              _vm._v(" "),
              _c("sprite", {
                attrs: { name: "calendar", type: "regular", size: 24 },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }