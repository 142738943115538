var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "spinner-container", style: _vm.spinnerSize },
    [
      _c("div", { staticClass: "circle loader" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle1" }),
      _vm._v(" "),
      _c("div", { staticClass: "circle circle2" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }