<template>
  <!-- <div :class="`header_head ${this.config.css || ''}`"> -->
  <!-- $style[this.config.css || '' -->
  <div
    ref="headerHead"
    :class="[
      $style.header_head,
      $style[config.css || ''],
      'header_head_common',
    ]"
  >
    <div
      :class="[$style.name_area, isEditingTitle ? $style.editing : '']"
    >
      <div
        v-if="config.ui.includes('publicUrlInfo')"
        :class="$style.public_url_title"
      >
        <div
          v-if="config.ui.includes('logo')"
          :class="[$style.logo_area, $style.tall_border]"
        >
          <vgp-svg-image
            name="logo"
          />
        </div>
        <div
          v-if="getPublicUrlInfo.logo"
          :class="[
            getPublicUrlInfo.css === 'image' ? $style.logo : $style.favicon,
          ]"
        >
          <img
            :src="getPublicUrlInfo.logo"
            alt=""
          >
        </div>
        <div
          v-if="getPublicUrlInfo.css !== 'image'"
          :class="[$style.text, $style.tall_border]"
        >
          {{ getPublicUrlInfo.teamName }}
        </div>
        <div
          :class="[$style.text, $style.title, 'project_name']"
          :style="{ maxWidth: parseInt(inputMaxWidth) - 200 + 'px' }"
        >
          {{ getPublicUrlInfo.projectName }}
        </div>
        <div :class="$style.text_small">
          {{ getPublicUrlInfo.sharedBy }}
        </div>
      </div>
      <div
        v-if="config.headIcon"
        :class="$style.project_icon"
      >
        <webix-ui :config="getHeadIcon" />
      </div>
      <div
        v-if="!config.ui.includes('noBaseTitle')"
        :class="$style.project_name"
      >
        <div :class="[$style.title_body]">
          <input
            v-if="editableTitle"
            ref="projectTitle"
            v-model="title"
            :class="[$style.title, $style.input_title, 'js_title']"
            :style="{ width: inputWidth, maxWidth: inputMaxWidth }"
            :readonly="!editableTitle"
            @click="onClickTitle"
            @keyup.enter="onEnterTitle"
          >
          <div
            v-else
            :class="$style.title"
            :style="{ maxWidth: parseInt(inputMaxWidth) - 35 + 'px' }"
          >
            {{ title }}
          </div>
          <div
            v-if="getHeadHelpIcon"
            :class="[$style.help_icon, 'help_icon_common']"
          >
            <webix-ui :config="getHeadHelpIcon" />
          </div>
          <div
            v-if="config.ui.includes('favorite')"
            :class="[$style.favorite, getFavorite ? $style.active : '']"
            @click="onClickFavorite"
          >
            <webix-ui :config="icons.favorite" />
          </div>
        </div>
        <div
          v-if="isEditingTitle"
          :class="$style.edit_controls"
        >
          <div :class="[$style.edit_btn, $style.check, 'header_title_control']">
            <webix-ui :config="icons.check" />
          </div>
          <div
            :class="[
              $style.edit_btn,
              $style.cancel,
              'js_title_cancel',
              'header_title_control',
            ]"
            @click.stop="onClickTitleCancel"
          >
            <webix-ui :config="icons.cancel" />
          </div>
        </div>
      </div>
      <div
        ref="statusSelectArea"
        :class="$style.status_area"
      >
        <status-select
          v-if="statusData && (config.headIcon === 'project' || config.headIcon === 'projectJira') "
          :status-data="statusData"
          :has-right-to-change-status="hasRightToChangeStatus()"
          :has-right-to-status-settings="hasRightToStatusSettings()"
          :project-config="config"
          @currentProjectForStatus="currentProjectForStatus = $event"
          @positionSelect="positionSelect = $event"
          @open="openStatus = $event"
        />
        <status-select-dropdown
          v-if="openStatus"
          :status-data="statusData"
          :position="positionSelect"
          :project-config="currentProjectForStatus"
          :is-open="openStatus"
          :has-right-to-change-status="hasRightToChangeStatus()"
          :has-right-to-status-settings="hasRightToStatusSettings()"
          @selectStatus="onChangeSelect"
          @closeStatus="closeStatusSelect"
          @onClickOutside="onClickOutsideStatus"
        />
      </div>
    </div>
    <div
      ref="toolsArea"
      :class="$style.tools_area"
    >
      <role-popup
        v-if="config.ui.includes('rolePopup')"
        :class="$style.role_popup"
      />

      <vgp-button
        v-if="config.ui.includes('unArchive')"
        :class="$style.archive_button"
        :label="locales('unarchive_project')"
        type="primary"
        small
        @onClick="onClickUnArchive"
      />

      <vgp-icon-button
        v-if="config.ui.includes('msteams') && isShowMSTeams"
        :icon="{name: 'msteam', type:'color-regular' }"
        :colors="buttonColors"
        :active="isShowMSTeamsPopup"
        :class="[
          $style.tools_btn,
          'js_slack_btn'
        ]"
        @onClick="onClickMSTeams"
      />

      <vgp-icon-button
        v-if="config.ui.includes('slack') && isShowSlack"
        :icon="{name: 'slack', type:'color-regular' }"
        :colors="buttonColors"
        :active="isShowSlackPopup"
        :class="[
          $style.tools_btn,
          'js_slack_btn'
        ]"
        @onClick="onClickSlack"
      />

      <vgp-icon-button
        v-if="config.ui.includes('history')"
        id="js_header_history_button"
        :icon="{ name:'history', type:'regular', size: 24 }"
        :colors="buttonColors"
        :class="[
          $style.tools_btn,
          'js_history_button',
          { free: !userIsPaid },
        ]"
        @onClick="onClickHistory"
      />

      <vgp-icon-button
        v-if="config.ui.includes('settings')"
        :icon="{ name:'settings', type:'regular', size: 24 }"
        :colors="buttonColors"
        :active="isShowSettingsPopup"
        :class="[
          $style.tools_btn,
          'js_settings_btn',
        ]"
        @onClick="onClickSettings"
      />

      <more-dropdown
        v-if="config.ui.includes('more') && moreItems"
        :class="$style.tools_btn"
        :on-click="onSelectMore"
        :config="config.moreItems"
        :button-colors="buttonColors"
      />

      <div
        v-if="config.ui.includes('signUpButton')"
        :class="$style.sign_up"
      >
        <!--<div :class="$style.label">{{locales('sing_up_to_customize_project')}}</div>-->
        <a
          href="#/signup"
          :class="$style.sign_up_btn"
          :data-logged-text="locales('logged_action_button_test')"
        >
          {{ locales("sign_up") }}
        </a>
      </div>

      <vgp-icon-button
        v-if="config.ui.includes('close')"
        :icon="{ name:'close-1', type:'bold', size: 24 }"
        :colors="buttonColors"
        :class="[
          $style.tools_btn,
          'js_close_btn',
        ]"
        @onClick="onClickClose"
      />
    </div>

    <div
      id="hiddenArea"
      ref="hiddenArea"
      :class="$style.hidden_area"
    >
      {{ title }}
    </div>

    <core-popup
      v-if="isShowPublicUrlPopup"
      :close-icon="true"
      header-style="withBorder"
      :show="isShowPublicUrlPopup"
      :title="locales('share_popup_public_url_filter_header')"
      @onClickCancel="togglePopup('isShowPublicUrlPopup')"
    >
      <template #body>
        <public-urls-pop-up />
      </template>
    </core-popup>
    <template-pop-up
      v-if="isShowTemplatePopup"
      :show="isShowTemplatePopup"
      @onClickCancel="togglePopup('isShowTemplatePopup')"
    />
    <core-confirm-popup
      v-if="isShowDeletePopup"
      :show="isShowDeletePopup"
      :popup-width="440"
      :close-icon="true"
      :title="locales('project_list_delete_project')"
      :text="locales('project_delete_confirm_text', {
          projectName: title ? title : ''
        })"
      :confirm-string="title ? title : ''"
      :confirm-string-input-placeholder="locales('delete_project_confirm_string_placeholder')"
      :ok-button="{
        title: locales('common_delete'),
        type: 'destructive',
      }"
      :cancel-button="{
        title: locales('common_cancel'),
        type: 'secondary',
      }"
      @onClickOk="onCloseDeletePopup('ok')"
      @onClickCancel="onCloseDeletePopup"
    />
    <!--    delete project popup-->
  </div>
</template>

<script>
import iconSettings from '../../svg/header_buttons/ic_project_settings_small.svg';
import iconHistory from '../../svg/header_buttons/history-small.svg';
import iconFavorite from '../../svg/star.svg';
import iconProject from '../../svg/ic_project.svg';
import iconProjectJira from '../../svg/project_popup/ic_project-jira.svg';
import iconMultiview from '../../svg/project_popup/ic_project-group.svg';
import iconListview from '../../svg/leftSideBar/wideSideBar/ic_my_task_green.svg';
import iconPortfolio from '../../svg/leftSideBar/wideSideBar/ic_portfolio.svg';
import iconWorkload from '../../svg/leftSideBar/wideSideBar/ic_workload_green.svg';
import iconMore from '../../svg/header_buttons/ic_header_menu.svg';
import iconCheck from '../../svg/task-settings/check.svg';
import iconCancel from '../../svg/task-settings/cancel.svg';
import iconTeamResources from '../../svg/leftSideBar/access_24.svg';
import iconReports from '../../svg/reports_icon.svg';
import iconCommentHub from '../../svg/commentHub.svg';
import iconTimeLog from '../../svg/leftSideBar/wideSideBar/ic_my_time_log_green.svg';
import iconClose from '../../svg/ic_close.svg';
import iconSlack from '../../svg/header_buttons/slack_integration.svg';
import iconMSteams from '../../svg/header_buttons/msteams_integration.svg';
import svgIconForVue from '../common/svgIconForVue';
import helpIconForVue from '../common/helpIconForVue';

import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import vgpButton from '$$vueCmp/globalButton/vgpButton.vue';

import plansModel from '../../models/plans';
import validationHelper from '../../helpers/custom';
import moreDropdown from './include/moreDropdown.vue';
import routerHelper from '../../helpers/router';
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import PublicUrlsPopUp from '../publicUrl/publicUrlsPopup.vue';
import TemplatePopUp from '../templatePopups/saveTemplatePopup.vue';
import rolePopup from './include/rolePopup.vue';

import rights from '../../components/rights';
import StatusSelectDropdown from '../common/VueComponents/select/statusSelect/statusSelectDropdown.vue';
import projectsModel from '../../models/projects';
import app from '../../app';
import vgpSvgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';
import CoreConfirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';

export default {
  components: {
    CoreConfirmPopup,
    vgpSvgImage,
    StatusSelectDropdown,
    PublicUrlsPopUp,
    TemplatePopUp,
    CorePopup,
    moreDropdown,
    vgpIconButton,
    vgpButton,
    rolePopup,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locales: __,
      isShowPublicUrlPopup: false,
      isShowTemplatePopup: false,
      isShowDeletePopup: false,
      headIcons: {
        project: svgIconForVue(iconProject, 24, 24),
        projectJira: svgIconForVue(iconProjectJira, 24, 24),
        multiView: svgIconForVue(iconMultiview, 26, 26),
        listView: svgIconForVue(iconListview, 24, 24),
        portfolio: svgIconForVue(iconPortfolio, 24, 24),
        teamResources: svgIconForVue(iconTeamResources, 24, 24),
        reports: svgIconForVue(iconReports, 24, 24),
        userLogs: svgIconForVue(iconTimeLog, 24, 24),
        workload: svgIconForVue(iconWorkload, 24, 24),
        commentHub: svgIconForVue(iconCommentHub, 24, 24),
      },
      headHelpIcons: {
        teamResources: helpIconForVue(
          'manageTeamLayoutHelpPopup',
          'manageTeamLayoutHelpPopup',
          30,
        ),
      },
      icons: {
        settings: svgIconForVue(iconSettings, 24, 24),
        slack: svgIconForVue(iconSlack, 24, 24),
        msteams: svgIconForVue(iconMSteams, 24, 24),
        history: svgIconForVue(iconHistory, 24, 24),
        favorite: svgIconForVue(iconFavorite, 24, 24),
        more: svgIconForVue(iconMore, 24, 24),
        close: svgIconForVue(iconClose, 24, 24),
        check: svgIconForVue(iconCheck, 30, 30),
        cancel: svgIconForVue(iconCancel, 30, 30),
      },
      inputWidth: '',
      inputMaxWidth: '',
      waitFavResponse: false,
      currentProjectForStatus: null,
      positionSelect: null,
      openStatus: false,
      closeStatus: true,
    };
  },
  computed: {
    getHeadIcon() {
      return this.headIcons[this.config.headIcon];
    },
    getHeadHelpIcon() {
      return this.headHelpIcons[this.config.headHelpIcon];
    },
    getFavorite() {
      return this.$store.getters['headerView/isFavorite'];
    },
    title: {
      get() {
        return (
          (this.config.title && __(this.config.title))
          || this.$store.getters['headerView/headTitle']
        );
      },
      set(value) {
        if (validationHelper.validateName(value)) {
          value = value.trim();
          this.$store.commit('headerView/setHeadTitle', value);
          this.inputWidth = `${document.getElementById('hiddenArea').clientWidth + 12}px`;
        } else {
          if (value === '' && this.isEditingTitle) {
            return;
          }
          webix.message({
            type: 'warning',
            text: __('settings_msg_project_name_invalid'),
          });
        }
      },
    },
    editableTitle() {
      return this.config && this.config.ui.find(str => str === 'editTitle');
    },
    userIsPaid() {
      return plansModel.getStatus() > 0;
    },
    isShowSlack() {
      return this.$store.getters['headerView/isShowSlack'];
    },
    isShowSlackPopup() {
      return this.$store.getters['headerView/isShowSlackPopup'];
    },
    isShowMSTeams() {
      return this.$store.getters['headerView/isShowMSTeams'];
    },
    isShowMSTeamsPopup() {
      return this.$store.getters['headerView/isShowMSTeamsPopup'];
    },
    isShowSettingsPopup() {
      return this.$store.getters['headerView/isShowSettingsPopup'];
    },
    getPublicUrlInfo() {
      return this.$store.getters['headerView/publicUrlInfo'];
    },
    isEditingTitle() {
      return this.$store.getters['headerView/isEditingTitle'];
    },
    statusData() {
      const currentProject = this.$store.getters['headerView/currentRoute'].params.projectId;

      return this.$store.getters['teamSetting/statusDataForProject'](currentProject);
    },
    moreItems() {
      const items = this.config.moreItems;
      let flag = false;

      if (items.length >= 1) {
        flag = true;
      }

      if (items.length === 1 && items[0] === 'delimiter') {
        flag = false;
      }

      return flag;
    },
  },
  watch: {
    getFavorite() {
      this.waitFavResponse = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickTitle);
    this.setInputWidth();
  },
  updated() {
    this.setInputWidth();
    this.isShowPublicUrlPopup = this.isShowPublicUrlPopup && this.checkAccessToPublicUrl();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickTitle);
  },
  methods: {
    togglePopup(propName) {
      this[propName] = !this[propName];
    },
    hasRightToChangeStatus() {
      const projectId = this.$store.getters['headerView/currentRoute'].params.projectId;

      return rights.project.hasRight(+projectId, 'project_statuses_edit');
    },
    hasRightToStatusSettings() {
      return rights.account.hasRight('project_statuses_settings');
    },
    onClickTitle(e) {
      if (!this.editableTitle) {
        return false;
      }

      if (
        !e.target.closest('.js_title')
        && !e.target.closest('.js_title_cancel')
      ) {
        this.isEditingTitle && this.$store.dispatch('headerView/saveHeadTitle');

        return;
      }

      this.$store.commit('headerView/setEditingTitle', true);
      this.oldTitle = this.title;
    },
    onEnterTitle(e) {
      e.target.blur();
      this.$refs.projectTitle.setSelectionRange(0, 0);
      this.$store.dispatch('headerView/saveHeadTitle');
    },
    onClickTitleCancel() {
      this.title = this.oldTitle;
      this.$refs.projectTitle.setSelectionRange(0, 0);
      this.$store.commit('headerView/setEditingTitle', false);
    },
    onClickFavorite() {
      if (this.waitFavResponse) return false;
      this.waitFavResponse = true;
      if (!this.getFavorite) {
        userExtAnalytics.log('project_favorite', {
          from: 'project',
        });
      }
      this.$store.dispatch('headerView/toggleFavorite');
    },

    checkAccessToPublicUrl() {
      return rights.account.hasRight('public_url');
    },

    onClickUnArchive() {
      this.$store.dispatch(
        'headerView/unArchiveProject',
        this.$store.getters['headerView/currentRoute'].params.projectId,
      );
    },
    onClickSettings() {
      this.$store.dispatch('headerView/toggleSettingsPopup');
    },
    onClickSlack() {
      this.$store.dispatch('headerView/toggleSlackPopup');
    },
    onClickMSTeams() {
      this.$store.dispatch('headerView/toggleMSTeamsPopup');
    },
    onClickHistory() {
      this.$store.dispatch('headerView/openHistoryWindow');
    },
    onSelectMore(item) {
      switch (item.name) {
      case 'publicUrl':
        // add name of props from data ( isShowPublicUrlPopup )
        this.togglePopup('isShowPublicUrlPopup');
        // this.$store.dispatch('headerView/openPublicUrlPopup');
        break;
      case 'publicUrl':
        // add name of props from data ( isShowPublicUrlPopup )
        this.togglePopup('isShowPublicUrlPopup');
        // this.$store.dispatch('headerView/openPublicUrlPopup');
        break;

      case 'template':
        this.togglePopup('isShowTemplatePopup');
        break;
      case 'template':
        this.togglePopup('isShowTemplatePopup');
        break;

      case 'archive':
        userExtAnalytics.log('project_archive', {
          from: 'project',
        });
        this.$store.dispatch('headerView/archiveProject');
        break;
      case 'archive':
        userExtAnalytics.log('project_archive', {
          from: 'project',
        });
        this.$store.dispatch('headerView/archiveProject');
        break;

      case 'delete':
        this.togglePopup('isShowDeletePopup');
        break;

      case 'deletePortfolio':
        const id = this.$store.getters['headerView/currentRoute'].params.multiviewID;
        const name = this.$store.getters['headerView/headTitle'];

        app.trigger('portfolio:deleteConfirm', { id, name });
        break;
      }
    },
    onClickClose() {
      const prevRoute = this.$store.getters['headerView/currentRoute'].prevRoute;

      if (!prevRoute.name) {
        routerHelper.pushRoute({ name: 'app' });

        return;
      }

      if (routerHelper.isTaskSettingsRoute(prevRoute) && !gantt.isTaskExists(prevRoute.params.taskId)) {
        routerHelper.pushRoute({ name: 'app' });

        return;
      }

      if (routerHelper.isMultiProjectsRoute()) {
        routerHelper.pushRoute({ name: 'portfolio' });

        return;
      }

      routerHelper.backRoute();
    },
    setInputWidth() {
      if (this.$el.offsetWidth) {
        this.inputMaxWidth = `${this.$refs.headerHead.clientWidth
          - this.$refs.toolsArea.clientWidth
          - this.$refs.statusSelectArea.clientWidth
          - 200
        }px`;
        this.inputWidth = `${document.getElementById('hiddenArea').clientWidth + 12}px`;
      }
    },
    closeStatusSelect(flag) {
      if (flag) {
        this.closeStatus = flag;
        this.openStatus = !flag;
      }
    },
    onClickOutsideStatus(e) {
      const icon = e.target.closest('.select__view');

      if (!icon) {
        this.closeStatus = true;
      }
    },
    onChangeSelect(id) {
      const currentProject = this.$store.getters['headerView/currentRoute'].params.projectId;
      const statusId = this.$store.getters['teamSetting/projectsWithStatus'](+currentProject).status_id;

      if (statusId) {
        this.$store.dispatch('teamSetting/updateProjectStatus', { id, currentProject, statusId });
      }
    },
    onCloseDeletePopup(type) {
      if (type === 'ok') {
        this.$store.dispatch('headerView/deleteProject', {});
      }
      this.isShowDeletePopup = false;
    },
  },
};
</script>

<style module src="./less/headView.less" lang="less"></style>
