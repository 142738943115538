export const ONBOARDING_INITIAL_TASKS = [
  {
    name: '',
    defaultName: __('onboarding_wizard_group1_default_name'),
    uniqueTemplateText: 'group1',
    hidden: true,
  },
  {
    name: '',
    defaultName: __('onboarding_wizard_task1_default_name'),
    uniqueTemplateText: 'task1',
    // subtasks: [
    //   {
    //     name: '',
    //     defaultName: 'Subtask 1',
    //   },
    //   {
    //     name: '',
    //     defaultName: 'Subtask 2',
    //   },
    // ],
  },
  {
    name: '',
    defaultName: __('onboarding_wizard_task2_default_name'),
    uniqueTemplateText: 'task2',
    // subtasks: [
    //   {
    //     name: '',
    //     defaultName: 'Subtask 1',
    //   },
    //   {
    //     name: '',
    //     defaultName: 'Subtask 2',
    //   },
    // ],
  },
  {
    name: '',
    defaultName: __('onboarding_wizard_task3_default_name'),
    uniqueTemplateText: 'task3',
    // subtasks: [
    //   {
    //     name: '',
    //     defaultName: 'Subtask 1',
    //   },
    //   {
    //     name: '',
    //     defaultName: 'Subtask 2',
    //   },
    // ],
  },
];

const DEFAULT_STEPS_ORDER = [
  'personalInfo',
  'membersInfo',
  'planningInfo',
  'familiarityInfo',
  'featuresInfo',
  'wizardFirstProject',
  'wizardFirstTask',
  // 'wizardFirstSubtask',
  'wizardFirstTeammate',
  'bookDemo',
];

export const ONBOARDING_FLOWS = {
  OWNER_MANUALLY: {
    requiredRegistrationFields: ['firstName', 'lastName', 'password'],
    displayedFields: ['fullName', 'user_company', 'password'],
    stepsOrder: DEFAULT_STEPS_ORDER,
  },
  OWNER_SOCIAL_NETWORKS: {
    requiredRegistrationFields: ['firstName', 'lastName'],
    displayedFields: ['fullName', 'user_company'],
    stepsOrder: DEFAULT_STEPS_ORDER,
  },
  TEAM_MEMBER_MANUALLY: {
    requiredRegistrationFields: ['firstName', 'lastName', 'password'],
    displayedFields: ['fullName', 'password'],
    stepsOrder: DEFAULT_STEPS_ORDER.slice(0, 1),
  },
  TEAM_MEMBER_SOCIAL_NETWORKS: {
    requiredRegistrationFields: ['firstName', 'lastName'],
    displayedFields: ['fullName'],
    stepsOrder: DEFAULT_STEPS_ORDER.slice(0, 1),
  },
};

export const STEPS_CONFIG = [
  {
    id: 'personalInfo',
    component: 'PersonalInfo',
    title: __('onboarding_personal_info_step_title'),
  },
  {
    id: 'membersInfo',
    component: 'MembersInfo',
    title: __('onboarding_members_info_step_title'),
  },
  {
    id: 'planningInfo',
    component: 'PlanningInfo',
    title: __('onboarding_planning_info_step_title'),
  },
  {
    id: 'familiarityInfo',
    component: 'FamiliarityInfo',
    title: __('onboarding_familiarity_info_step_title'),
  },
  {
    id: 'featuresInfo',
    component: 'FeaturesInfo',
    title: __('onboarding_features_info_step_title'),
  },
  {
    id: 'wizardFirstProject',
    component: 'WizardMain',
    title: __('onboarding_wizard_first_project_step_title'),
    description: __('onboarding_wizard_first_project_step_descr'),
    isWizard: true,
  },
  {
    id: 'wizardFirstTask',
    component: 'WizardMain',
    title: __('onboarding_wizard_first_task_step_title'),
    description: __('onboarding_wizard_first_task_step_descr'),
    isWizard: true,
  },
  // {
  //   id: 'wizardFirstSubtask',
  //   component: 'WizardMain',
  //   title: 'Now let’s create some tasks you need to complete',
  //   description: 'Specify several tasks that will be part of the project',
  //   isWizard: true,
  // },
  {
    id: 'wizardFirstTeammate',
    component: 'WizardMain',
    title: __('onboarding_wizard_first_teammate_step_title'),
    description: __('onboarding_wizard_first_teammate_step_descr'),
    isWizard: true,
  },
  {
    id: 'bookDemo',
    component: 'BookDemo',
    title: __('onboarding_book_demo_step_title'),
    description: __('onboarding_book_demo_step_descr'),
  },

];

export const ANALYTIC_TYPES = {
  GENERAL: 'general',
  INVITED: 'invited',
  MPP: 'mpp',
  TEMPLATE: 'template',
};

export const ANALYTIC_ACTIONS = {
  SKIP: 'skip',
  DONE: 'done',
  IMPORT_STARTED: 'importStarted',
  IMPORT_FINISHED: 'importFinished',
};
