var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$.status, !_vm.label && _vm.$.no_label],
      style: {
        backgroundColor: _vm.backgroundColor,
        color: _vm.color,
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }