var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cellTooltip",
    {
      attrs: {
        items: _vm.chacked,
        template: "icon+text",
        "group-by": "group",
        "can-show": true,
        "icon-field": "picture",
        "value-field": "name",
      },
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.$style.cell_trigger,
            _vm.disabled ? _vm.$style.disabled_cell : "",
            _vm.crossout && !_vm.isOpen ? _vm.$style.crossout : "",
            _vm.isOpen && _vm.editable ? _vm.$style.edit : "",
            !_vm.editable ? _vm.$style.not_allowed : "",
          ],
          style: { opacity: _vm.isOpen ? 1 : _vm.cellOpecity },
          on: { click: _vm.clickOnCell },
        },
        [
          _vm.chacked.length
            ? [
                _vm.chacked.length === 1
                  ? [
                      _c("div", {
                        class: [_vm.$style.icon_wrap],
                        style: {
                          "background-image":
                            "url(" + _vm.chacked[0].picture + ")",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class: [_vm.$style.text_wrap, _vm.$style.select_none],
                        },
                        [_vm._v(_vm._s(_vm.chacked[0].name))]
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.chacked && _vm.chacked.length > 1
                  ? [
                      _vm._l(2, function (index) {
                        return _c("div", {
                          key: _vm.componentKey + "i+t_s-" + index,
                          class: [_vm.$style.icon_wrap],
                          style: {
                            "background-image":
                              "url(" + _vm.chacked[index - 1].picture + ")",
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm.chacked.length > 2
                        ? _c("span", { class: [_vm.$style.select_none] }, [
                            _vm._v(" +" + _vm._s(_vm.chacked.length - 2) + " "),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ]
            : _c(
                "span",
                {
                  class: [
                    _vm.$style.secondary,
                    _vm.$style.text_wrap,
                    _vm.$style.select_none,
                  ],
                },
                [_vm._v(" " + _vm._s(_vm.local_noAsigned) + " ")]
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }