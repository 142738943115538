"use strict";

(function(ELEMENT) {
  ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
  ELEMENT.closest = ELEMENT.closest || function closest(selector) {
      if (!this) return null;
      if (this.matches(selector)) return this;
      if (!this.parentElement) {
        return null;
      }
      else return this.parentElement.closest(selector);
    };
  ELEMENT.getBoundingClientRectWrapper = function () {
    const res = this.getBoundingClientRect();
    if (!res.x) {
      res.x = res.left;
      res.y = res.top;
    }
    return res;
  }
}(Element.prototype));