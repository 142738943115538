<template>
  <div :class="$style.shared_wraper">
    <core-popup :show="isConfirmPopup">
      <template #body>
        <div :class="$style.shared_content">
          <div
            :class="$style.shared_logo"
            v-html="logo"
          />
          <div :class="[$style.shared_message, error ? $style.shared_message_error : '']">
            <span v-if="error">{{ locales('share_popup_message_error') }}</span>
            <span v-else>{{ locales('share_popup_message_info') }}</span>
          </div>
          <div :class="[$style.shared_form]">
            <vgp-label-slot :label="locales('share_popup_password_title')">
              <template #body>
                <vgp-input
                  v-model="password"
                  input-type="password"
                  type="status"
                  :is-error="error"
                  :placeholder="locales('share_popup_enter_password_placeholder')"
                />
                <vgp-button
                  type="status"
                  :label="locales('share_popup_password_submit_placeholder')"
                  @onClick="submit"
                />
              </template>
            </vgp-label-slot>
          </div>
          <div :class="$style.shared_help">
            {{ `${locales('share_popup_support_message')} ` }}
            <a :href="`mailto:${locales('share_popup_mailto')}`">{{ locales('share_popup_mailto') }}</a>
          </div>
        </div>
      </template>
    </core-popup>
    <portal-target name="popup" />
  </div>
</template>

<script>
import axios from 'axios';
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '../common/VueComponents/label/vgpLabelSlot.vue';
import logo from '../../svg/logo.svg';

export default {
  components: {
    VgpLabelSlot,
    CorePopup,
  },
  data() {
    return {
      locales: __,
      isConfirmPopup: true,
      password: '',
      error: false,
      logo,
    };
  },
  methods: {
    submit() {
      axios.post(window.location.pathname, {
        password: this.password,
      }).then(() => {
        this.error = false;
        window.location.reload();
      }).catch(() => {
        this.error = true;
      });
    },
  },

};
</script>

<style module src="./less/publicUrls.less"></style>
