import teamModel from '../../../models/team';
import globalStore from '$$store/main';

function onTeamUpdated({ data: { updated } }) {
  updated.forEach(update => {
    const { updatedFields } = update;

    if (updatedFields.name) {
      teamModel.changeTeamName(updatedFields.name);
    }

    if (updatedFields.isActiveCurrency !== undefined) {
      globalStore.dispatch('teamSetting/updateCurrency', updatedFields);
    }
  });
}

export default onTeamUpdated;
