var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$style.context_menu,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.emitContextMenu($event)
        },
      },
    },
    [
      _c("svg-sprite", {
        attrs: { type: "regular", name: "more-1", size: 24, color: "#B2B2B2" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }