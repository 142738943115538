<template>
  <div
    class="block-menu table-cell center sticky-right"
    :class="[ [skins] ]"
    :style="{width: '50px', 'flex-grow': 0, 'z-index': isOpenDrop ? 2 : 1 }"
  >
    <dropPopup
      v-model="isOpenDrop"
      class="more-cell"
      :suggest-height="72"
      :drop-width="236"
      :highlight="false"
    >
      <template #trigger>
        <div
          class="cell-trigger"
          :class="{'discolor':discolor}"
          @contextmenu="callContextMenu"
        >
          <icon :name="'more'" />
        </div>
      </template>

      <template #drop>
        <div
          class="settings-item"
          :id="'gp_autotest_listview_more_cell_item_settings'"
          @click="$emit('show:settings')"
        >
          <icon :name="'settings'" />
          <span class="settings-item--text"> {{ locale.setting }} </span>
        </div>
        <div
          v-if="canDeleteTask"
          class="settings-item red-item"
          :id="'gp_autotest_listview_more_cell_item_delete'"
          @click="deleteHandler"
        >
          <icon
              class="red-item"
              :name="'dustbin'" />
          <span class="settings-item--text"> {{ locale.delete }} </span>
        </div>
      </template>
    </dropPopup>
  </div>
</template>

<script>
import icon from '../icons/icon.vue';
import dropPopup from '../dropPopup.vue';
import rights from '../../../../components/rights';

export default {
  name: 'MoreCell',
  components: { icon, dropPopup },
  props: {
    item: { type: Object, required: true },
    discolor: { type: Boolean, required: false, default: false },
    skins: { type: String, required: false, default: '' },
    editable: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      isOpenDrop: false,
      locale: {
        setting: __('settings_task_settings'),
        delete: __('common_delete'),
      },
    };
  },
  computed: {
    canDeleteTask() {
      return rights.project.hasRight(this.item.gantt_id, 'delete_task');
    },
  },
  methods: {
    callContextMenu(e) {
      e.preventDefault();
      const coords = this.$el.getBoundingClientRect();

      this.$emit('contextmenu', { coords, item: this.item });
    },
    deleteHandler() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="less">
.cell-trigger {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
}
.settings-item {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  color: #424242;
  cursor: pointer;
  widows: 236px;
  transition: background-color .3s;
  font-family: "Lato-Regular", sans-serif;

  &--text {
    margin-left: 12px;
    // margin-top: 4px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  &:hover{
    background-color: #f3f3f3;
  }
}

.red-item {
  color: #DD3636;
}
.discolor{
  opacity: 0.5;
}
</style>
