<template>
  <div
    :class="$style.public_url_wrap"
  >
    <div :class="$style.public_url__subtitle">
      {{ locales('share_popup_public_url_filter_sub_header') }}
    </div>
    <div :class="$style.public_url__form">
      <public-urls-form
        :is-loading="isLoading"
        @onAddItem="addItem"
      />
    </div>
    <v-simple-table
      ref="table"
      :fixed-header="true"
      class="table"
      height="300px"
    >
      <template
        #default
        class="table"
      >
        <thead>
          <tr>
            <th>
              {{ locales('share_popup_public_url_list_header_description') }}
            </th>
            <th>
              {{ locales('common_filter') }}
            </th>
            <th>
              {{ locales('header_password') }}
            </th>
            <th>
              {{ locales('share_popup_public_url_list_header_snapshot') }}
            </th>
            <th>
              {{ locales('share_popup_public_url_list_header_url') }}
            </th>
            <th>
              {{ locales('share_popup_public_url_list_header_status') }}
            </th>
            <th :class="$style.last_th" />
          </tr>
        </thead>
        <tbody v-if="data.length>0">
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td :class="$style.field_title">
              <tooltip
                :content="format(item.created_at, item.firstName, item.lastName)"
              >
                <template #body>
                  {{ item.title }}
                </template>
              </tooltip>
            </td>
            <td :class="$style.field_filter">
              <div>
                <span>{{ getCurrentTitle(item) }}</span>
              </div>
            </td>
            <td
              v-if="togglePasswordId !== item.id"
              :class="$style.field_password"
              @mouseover="hoverItem(item.id, true)"
              @mouseleave="hoverItem(item.id, false)"
            >
              <div>
                <span :class="item.password ? $style.password : $style.empty_password">{{ item.password ? "••••" : '—' }}</span>
                <div
                  v-if="item.hover"
                  :class="$style.public_url__icons"
                >
                  <svg-sprite
                    name="pensil"
                    :class="$style.active_link"
                    @click="togglePassword(item.id)"
                  />
                  <svg-sprite
                    v-if="item.password"
                    name="copy"
                    :class="$style.active_link"
                    @click="copyPassword(item.password)"
                  />
                  <svg-sprite
                    v-if="item.password"
                    name="delete"
                    :class="$style.active_link"
                    @click="removePassword(item.id)"
                  />
                </div>
              </div>
            </td>
            <td
              v-else
              :class="$style.password_change"
            >
              <vgp-input-confirm
                ref="password"
                v-model="newPassword"
                :class="$style.passwordInput"
                type="text"
                :is-disabled="false"
                @onKeydown="editKeyPassword"
                @onOk="editPassword"
                @onCancel="togglePassword"
              />
            </td>
            <td>
              <div>
                <svg-sprite
                  color="#424242"
                  :name="item.is_snapshot ? 'check' : 'minus-1'"
                />
              </div>
            </td>
            <td>
              <div>
                <svg-sprite
                  color="#247ECC"
                  name="link-1"
                  :class="$style.active_link"
                  @click="createToken(item.token, item.user_id)"
                />
              </div>
            </td>
            <td>
              <square-toggle
                :is-toggle-on="item.status"
                @onChange="swithStatus(item.id, item.status)"
              />
            </td>
            <td>
              <div>
                <svg-sprite
                  name="delete"
                  color="#A8A8A8"
                  :class="[$style.active_link,
                           $style.active_link_password]"
                  @click="toggleConfirmDelete(item.id)"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="7"
              :class="$style.public_url__empty"
            >
              <div>
                {{ locales('share_popup_public_url_empty_list') }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <core-confirm-popup
      v-if="isConfirmPopup"
      :show="isConfirmPopup"
      :close-icon="false"
      :text="locales('public_link_window_delete_message')"
      :ok-button-title="locales('common_yes')"
      :cancel-button-title="locales('common_no')"
      :ok-button="{
        title : locales('common_yes'),
        type : 'destructive'
      }"
      :cancel-button="{
        title : locales('common_no'),
        type : 'secondary'
      }"
      @onClickOk="deleteItem"
      @onClickCancel="toggleConfirmDelete(null)"
    />
  </div>
</template>

<script>
import { api } from '$$store/api';
import PublicUrlsForm from './publicUrlsForm.vue';
import SquareToggle from '$$vueCmp/checkbox/squareToggle.vue';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';
import VgpInputConfirm from '$$vueCmp/vInput/vgpInputConfirm.vue';
import ganttViewModel from '../../models/ganttViewModel';

export default {
  name: 'PublicUrlsPopUp',
  components: {
    VgpInputConfirm,
    Tooltip,
    CoreConfirmPopup,
    SquareToggle,
    PublicUrlsForm,
  },

  filters: {
    formatDate(date) {
      return `${__('Created')} ${window.moment(date || new Date()).format('MMM D, YYYY')}`;
    },
  },

  data() {
    return {
      locales: __,
      isConfirmPopup: false,
      deleteId: null,
      hover: false,
      newPassword: null,
      togglePasswordId: null,
      filtersOptions: [],
      data: [],
      isLoading: true,
    };
  },

  mounted() {
    const viewData = ganttViewModel.getActiveViewData();
    const gantt_id = viewData.gantt_id;

    api.get('/filters').then(response => {
      this.filtersOptions = [...this.filtersOptions, ...response.data];
    });
    api.get('/shareUrl/all', {
      params: {
        gantt_id,
        multiview_id: ganttViewModel.isMultiview() ? viewData.id : null,
      },
    }).then(response => {
      this.data = response.data.map(item => {
        item.hover = false;

        return item;
      });

      this.isLoading = false;
    });
  },

  methods: {
    swithStatus(id, oldValue) {
      api.put('/shareUrl', {
        id,
        value: !oldValue,
      }).then(() => {
        this.data.forEach(item => {
          item.id === id ? item.status = !item.status : null;
        });
      });
    },

    getCurrentTitle(value) {
      // get title from filter request
      if (value.filter_id === null && (value.options === null || value.options === '')) return __('share_popup_public_url_filter_select');
      if (value.filter_id === null && (value.options !== null || value.options !== '')) return __('filter_current_filter');
      if (value.filter_id !== null) {
        let filterName = null;

        this.filtersOptions.forEach(item => {
          if (item.id === value.filter_id) filterName = item.name;
        });

        return filterName !== null ? filterName : value.filter_name;
      }
    },

    deleteItem() {
      const id = this.deleteId;

      api.delete('/shareUrl', { data: { id } }).then(() => {
        this.data = [...this.data.filter(item => item.id !== id)];
        this.toggleConfirmDelete(null);
      });
    },

    togglePassword(id) {
      if (id !== undefined) {
        this.togglePasswordId = id;
        this.$nextTick(() => this.$refs.password[0].$refs.input_confirm.$refs.input_field_icons.focus());
      } else {
        this.editPassword();
      }
    },

    editKeyPassword(value, e) {
      if (e.keyCode === 13) {
        this.editPassword(value, e);
      }
    },

    onBlur() {
      if (this.newPassword !== null && this.newPassword !== '') {
        this.editPassword(true);

        return true;
      } if (this.newPassword !== null && this.newPassword === '') {
        this.editPassword(false);

        return true;
      }

      if (this.newPassword === null) {
        this.togglePasswordId = null;
        this.newPassword = null;

        return true;
      }
    },

    editPassword(value) {
      const id = this.togglePasswordId;
      const password = this.newPassword === '' ? null : this.newPassword;

      if (value === undefined) {
        this.togglePasswordId = null;
        this.newPassword = null;

        return;
      }

      api.put('/shareUrl/password', { data: { id, password } }).then(() => {
        this.data = [...this.data.map(item => {
          if (item.id === id) {
            item.password = password;
          }

          return item;
        })];
        this.togglePasswordId = null;
        this.newPassword = null;
      });
    },

    copyPassword(password) {
      navigator.clipboard.writeText(password).then(() => {
        webix.message({ type: 'info', text: __('public_lick_copied'), expire: 5000 });
      }, err => {
        console.error('Async: Could not copy text: ', err);
      });
    },

    removePassword(id) {
      api.put('/shareUrl/password', { data: { id, password: null } }).then(() => {
        this.data = this.data.map(item => {
          const copy = { ...item };

          if (copy.id === id) {
            copy.password = null;
          }

          return copy;
        });
      });
    },

    createToken(hash, user_id) {
      const link = `${location.origin}/shared/token/${hash}/${user_id}`;

      navigator.clipboard.writeText(link).then(() => {
        webix.message({ type: 'info', text: __('public_lick_copied'), expire: 5000 });
      }, err => {
        console.error('Async: Could not copy text: ', err);
      });
    },

    addItem(item) {
      this.data = [{
        ...item, firstName: user?.firstName, lastName: user?.lastName, hover: false,
      }, ...this.data];

      this.$nextTick(() => {
        this.$refs.table.$el.querySelector('.v-data-table__wrapper table').scrollIntoView(true);
      });
    },

    hoverItem(id, type) {
      this.data = this.data.map(item => {
        if (item.id === id) {
          item.hover = type;
        }

        return item;
      });
    },

    toggleConfirmDelete(id) {
      this.isConfirmPopup = !this.isConfirmPopup;
      this.deleteId = id;
    },

    format(date, first, last) {
      return `<p>${__('share_popup_public_url_list_header_dateCreation')}: ${window.moment(date || new Date()).format('MMM D, YYYY')}<p>
              <p>${__('share_popup_public_url_creater')}: ${first} ${last}<p>
            `;
    },
  },

};
</script>

<style src="./less/publicUrls.less" module></style>
