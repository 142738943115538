var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("core-popup", {
        attrs: {
          show: _vm.show,
          "close-icon": true,
          width: 540,
          title: _vm.locale(_vm.integrationName + "_link_Generated"),
        },
        on: {
          onClickCancel: function () {
            return _vm.$emit("closePopup")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("vgp-label-slot", {
                  attrs: {
                    label: _vm.locale("integration_subscription_url_label"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { class: _vm.$.subscription_url_block },
                            [
                              _c("vgp-input", {
                                staticClass: "input-area",
                                class: _vm.$.subscription_url_input,
                                attrs: {
                                  value: "" + _vm.generatedLink + _vm.link.hash,
                                  "select-by-focus": true,
                                  readonly: true,
                                  border: false,
                                },
                                on: { onClick: _vm.onClickLink },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.$.integration_icon_item,
                                    _vm.$.integration_icon_item_link,
                                  ],
                                },
                                [
                                  _c("svg-sprite", {
                                    attrs: { name: "link-1", color: "#1565C0" },
                                    on: { click: _vm.onClickLink },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.instruction_container }, [
                            _c("div", {
                              class: _vm.$.instruction_image,
                              style: {
                                backgroundImage:
                                  "url(https://cdn.ganttpro.com/app/imgs/integration/" +
                                  _vm.integrationName +
                                  "-calendar/" +
                                  _vm.integrationName +
                                  "-calendar-integration-connection.png)",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { class: _vm.$.instruction_text }, [
                              _c(
                                "div",
                                { class: _vm.$.instruction_steps_label },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.locale("instructions_title")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { class: _vm.$.instruction_steps },
                                _vm._l(_vm.instructionSteps, function (step) {
                                  return _c(
                                    "div",
                                    { class: _vm.$.instruction_step },
                                    [
                                      _c("svg-sprite", {
                                        attrs: {
                                          name: "status-2",
                                          size: 16,
                                          color: "#191919",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.locale(step)),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.instruction_description }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.locale(
                                    _vm.integrationName +
                                      "_instructions_description"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.instruction_learn_more }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.locale(
                                    _vm.integrationName +
                                      "_integration_learn_more"
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.locale(
                                      _vm.integrationName +
                                        "_integration_learn_more_info"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }