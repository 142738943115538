import app from '../../../app';
import jiraModel from '../../../models/jira';
import _ from '../../../libs/lodash';
import firstTemplate from './templates/firstTemplate';
import secondTemplate from './templates/secondTemplate';
import thirdTemplate from './templates/thirdTemplate';
import fourthTemplate from './templates/fourthTemplate';
import tokenRejectModal from './tokenRejectModal';
import checkSVG from '../../../svg/integration/jira/check.svg';
import closeCross from '../../../svg/close_black.svg';
import calculateMainWindowSize from '../../../helpers/calculateMainWindowSize';

const { __ } = window;

const innerSettings = {
  integrationSteps: [
    {
      stepOrder: 1,
      template: firstTemplate,
      viewHeight: 690,
      id: 'jira-integration-first-step',
    },
    {
      stepOrder: 2,
      template: secondTemplate,
      viewHeight: 700,
      id: 'jira-integration-second-step',
    },
    {
      stepOrder: 3,
      template: thirdTemplate,
      viewHeight: 1360,
      id: 'jira-integration-third-step',
    },
    {
      stepOrder: 4,
      template: fourthTemplate,
      viewHeight: 750,
      id: 'jira-integration-fourth-step',
    },
  ],
  activeStep: 1,
  openWindow: {},
  jiraOAuthURL: '',
};

const innerHelper = {
  getStepItem(step) {
    return `<div class='jira-header-steps-item${
      (innerSettings.activeStep === step.stepOrder)
        ? ' active'
        : ''
    }${(innerSettings.activeStep > step.stepOrder)
      ? ' finished'
      : ''
    }'>`
      + `<div class='jira-header-steps-item-circle'>${
        innerSettings.activeStep > step.stepOrder
          ? checkSVG
          : step.stepOrder}</div>`
    + '</div>';
  },
  getHeaderSteps() {
    let html = "<div class='jira-header-steps'>";

    _.each(innerSettings.integrationSteps, step => {
      html += innerHelper.getStepItem(step);
    });

    html += '</div>';

    return html;
  },
  getHeaderView() {
    return `${"<div class='jira-header'>"
      + "<div class='jira-header-label'>"
      + "<div class='jira-header-cross'>"
      + "<div class='arrow'>"}${
      closeCross
    }</div>`
      + '</div>'
      + `</div>${
        this.getHeaderSteps()
      }</div>`;
  },
  getNavigationView(stepOrder) {
    return `<div class='jira-navigate'>${
      stepOrder !== 1 ? `<div class='jira-navigate-prev'><div class='jira-navigate-button'>${__('jira_integration_prev_button')}</div></div>` : '<div></div>'
    }<div class='jira-navigate-label'>${__('jira_integration_step')} ${stepOrder}</div>`
      + `<div class='jira-navigate-next'><div class='jira-navigate-button next ${stepOrder === 4 ? 'ready disabled' : ''}'>${stepOrder === 4 ? __('jira_integration_success_button') : __('jira_integration_next_button')}</div></div>`
      + '</div>';
  },
  getCellsForMultiview() {
    const cells = [];

    _.each(innerSettings.integrationSteps, step => {
      cells.push({
        id: step.id,
        cols: [
          { css: 'sideBackground' },
          {
            width: 924,
            rows: [
              {
                height: 109,
                borderless: true,
                template: innerHelper.getNavigationView(step.stepOrder),
                onClick: {
                  'jira-navigate-next': function (m, v, node) {
                    innerHandlers.changeStepView(node, 1);
                  },
                  'jira-navigate-prev': function (m, v, node) {
                    innerHandlers.changeStepView(node, -1);
                  },
                },
              },
              {
                height: step.viewHeight,
                borderless: true,
                template: step.template.getTemplate(),
                onClick: {
                  verify() {
                    const regexpAtlassian = new RegExp(/[-.a-zA-Z0-9]+\.atlassian\.net/g);
                    const regexpJira = new RegExp(/[-.a-zA-Z0-9]+\.jira\.com/g);
                    const hostAtlassian = document.querySelectorAll('.input-jira-host')[0].value.match(regexpAtlassian);
                    const hostJira = document.querySelectorAll('.input-jira-host')[0].value.match(regexpJira);

                    const jiraData = {
                      host: hostAtlassian || hostJira,
                      consumer_key: jiraModel.commonFields.consumer_key,
                    };

                    jiraData.host = jiraData.host ? jiraData.host[0] : jiraData.host;

                    if (!jiraData.host || (!regexpAtlassian.test(jiraData.host) && !regexpJira.test(jiraData.host))) {
                      webix.message({ type: 'warning', text: __('jira_incorrect_url') });
                      return;
                    }

                    innerHandlers.verifyJiraConnection(jiraData);
                  },
                },
              },
              { height: 102, css: 'sideBackground' },
            ],
          },
          { css: 'sideBackground' },
        ],
      });
    });

    return cells;
  },
};

const innerHandlers = {
  verifyJiraConnection(jiraData) {
    innerSettings.openWindow = window.open(innerSettings.jiraOAuthURL, 'jiraOAuth', 'width=1, height=1');

    jiraModel.authorizeStepOne(jiraData);
  },
  changeStepView(node, offsetStep) {
    if (node && node.children[0].classList.contains('disabled')) {
      return;
    }

    const header = $$('jiraIntegrationPopupHeaderId');

    innerSettings.activeStep += offsetStep;

    if (innerSettings.integrationSteps.length < innerSettings.activeStep) {
      $$('jiraIntegrationPopup').hide();
      // app.trigger('tabs:newProject:changeForm', "jiraProject"); // !FIXME
      userExtAnalytics.log('project_create_jira_open', { first: true });
      return;
    }

    const nextStep = _.find(innerSettings.integrationSteps, step => step.stepOrder === innerSettings.activeStep);

    header.define('template', innerHelper.getHeaderView());
    header.refresh();

    const viewTemplate = $$(nextStep.id).getChildViews()[1].getChildViews()[1];

    viewTemplate.define('template', nextStep.template.getTemplate());
    viewTemplate.refresh();

    $$('jiraIntegrationMultiview').setValue(nextStep.id);
  },
  showVerifyWindow() {
    innerSettings.openWindow.location.href = innerSettings.jiraOAuthURL;
    innerSettings.openWindow.resizeTo(600, 600);
    innerSettings.openWindow.focus();
  },
  jiraIntegrationSuccess() {
    const successButton = document.querySelectorAll('.jira-navigate-button.ready')[0];
    app.trigger('integration:updated');
    innerSettings.openWindow.close && innerSettings.openWindow.close();

    successButton.classList.remove('disabled');
  },
};

webix.ui({
  view: 'window',
  id: 'jiraIntegrationPopup',
  css: 'jiraIntegrationPopup',
  borderless: true,
  // header: false,
  head: false,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state]);
  },
  modal: false,
  move: false,
  body: {
    view: 'scrollview',
    minWidth: 972,
    scroll: 'y',
    body: {
      rows: [
        {
          id: 'jiraIntegrationPopupHeaderId',
          view: 'template',
          height: 148,
          borderless: true,
          template: innerHelper.getHeaderView(),
          onClick: {
            'jira-header-cross': function () {
              $$('jiraIntegrationPopup').hide();
            },
          },
        },
        {
          id: 'jiraIntegrationMultiview',
          view: 'multiview',
          cells: innerHelper.getCellsForMultiview(),
        },
        { css: 'sideBackground' },
      ],

    },
  },
  on: {
    onBeforeShow() {
      app.trigger('popup:show', true);
    },
    onHide() {
      app.trigger('popup:hide');
      webix.callEvent('onResize', []);
      app.trigger('newProject:reInit');
    },
    onShow() {
      webix.callEvent('onResize', []);
    },
  },
}).hide();

app.on('popup:show', () => {
  $$('jiraIntegrationPopup').hide();
});

app.on('popup:closeOther', id => {
  if (id !== 'jiraIntegrationPopup') {
    $$('jiraIntegrationPopup').hide();
  }
});

app.on('gantt:area:click', () => {
  $$('jiraIntegrationPopup').hide();
});

app.on('body:resize', data => {
  $$('jiraIntegrationPopup').resize();
});

app.on('leftSideBar:changedMode', data => {
  $$('jiraIntegrationPopup').resize();
});

app.on('tab:projectClick', data => {
  $$('jiraIntegrationPopup').hide();
});

const init = function () {
  jiraModel.data.attachEvent('getAuthorizeUrl', jiraOAuth => {
    innerSettings.jiraOAuthURL = jiraOAuth.url;
    innerHandlers.showVerifyWindow();
  });

  jiraModel.data.attachEvent('errorAuthorizeUrl', error => {
    innerSettings.openWindow.close();
  });

  jiraModel.data.attachEvent('jiraIntegration:success', () => {
    innerHandlers.jiraIntegrationSuccess();
  });

  jiraModel.data.attachEvent('jiraIntegration:denied', () => {
    innerSettings.openWindow.close();
  });

  return jiraModel.getJiraClientData()
    .then(responseRawData => {
      innerSettings.activeStep = 1;
      innerHandlers.changeStepView(null, 0);
      $$('jiraIntegrationPopup').show();
      return webix.promise.defer();
    })
    .fail(() => {
      webix.message({
        type: 'error',
        text: 'Something went wrong',
      });
    });
};

const refreshToken = function () {
  jiraModel.data.attachEvent('getAuthorizeUrl', jiraOAuth => {
    innerSettings.jiraOAuthURL = jiraOAuth.url;
    innerHandlers.showVerifyWindow();
  });

  jiraModel.data.attachEvent('errorAuthorizeUrl', error => {
    innerSettings.openWindow.close();
  });

  jiraModel.data.attachEvent('jiraIntegration:success', () => {
    tokenRejectModal.close();
    innerSettings.openWindow.close();
  });

  jiraModel.data.attachEvent('jiraIntegration:denied', () => {
    innerSettings.openWindow.close();
  });

  return jiraModel.getJiraClientData()
    .then(responseRawData => {
      const jiraData = {
        host: responseRawData.host,
        consumer_key: jiraModel.commonFields.consumer_key,
      };

      innerHandlers.verifyJiraConnection(jiraData);

      return webix.promise.defer();
    })
    .fail(() => {
      webix.message({
        type: 'error',
        text: 'Something went wrong in refreshToken',
      });
    });
};

app.socket.on('jira:token_rejected', data => {
  tokenRejectModal.show(refreshToken);
});

export default {
  init,
};
