// public/models/onBoarding/popups.js
// !TODO: DESCRIPTION

import app, { appIds } from '../../app';
import globalStore from '../../store/main';

const {
    events: {
        ID_EVENT_APP_ROUTECHANGED,
        ID_EVENT_APP_INIT_MODELS,
        ID_EVENT_POPUPS_CHANGED,
        ID_EVENT_WINDOW_STATE_CHANGED,
        ID_APP_EVENT_SUPPORTCHAT_HIDE,
        ID_APP_EVENT_SUPPORTCHAT_SHOW
    },
    popupViews: {
        ID_VIEW_POPUP_GLOBALSEARCH,
        ID_VIEW_POPUP_CUSTOMGRID
    }
} = appIds;

// views identifiers
const ID_VIEW_WINDOW_HISTORY = 'historyId';
const ID_VIEW_WINDOW_PROFILE_SETTINGS = 'userProfilePopup';
const ID_VIEW_WINDOW_PUBLIC_URL = 'publicLinkWindow';
const ID_VIEW_WINDOW_SAVE_TEMPLATE = 'saveAsUserTemplate';
const ID_VIEW_POPUP_BASELINE = 'baselinesPopup';
const ID_VIEW_POPUP_PROJECTS = 'projectsPopupId';
const ID_VIEW_POPUP_NOTIFICATIONS = 'notificationsTimeLineModal';
const ID_VIEW_POPUP_ASSIGN_RESOURCE = 'resourcesSelectListPopup';
const ID_VIEW_WINDOW_TASKVIEW = 'taskViewId';
const ID_VIEW_WINDOW_MESSAGEUS = 'messageUsForm';
const ID_VIEW_WINDOW_PAYMENT_PLAN = 'paymentPlanInfoPopup';
const ID_VIEW_WINDOW_HELP_CENTER = 'learningCenterPopup';
const ID_VIEW_WINDOW_PROJ_CALENDAR = 'calendarExceptionsPopup';
const ID_VIEW_WINDOW_MANAGE_TEAM = 'manageTeamLayout'; // ???
const ID_VIEW_WINDOW_TIME_TRACKING = 'timeTrackingPopup';
const ID_VIEW_WINDOW_TIME_PAYMENT = 'paymentPopup';
const ID_VIEW_WINDOW_HELPBLOCK = 'helpBlock';
const ID_VIEW_WINDOW_DELETE_USER = 'userAccountPopup';
const ID_VIEW_WINDOW_DELETE_USER_FINISH = 'userAccountFormPopup';
const ID_VIEW_WINDOW_INVITE_NOTIFY = 'notificationModal';
const ID_VIEW_WINDOW_REQUEST_PERMISSION = 'requestPermissionPopup';
const ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE = 'integrationWrongWorkspacePopup';
const ID_VIEW_POPUP_PROJ_SETTINGS = 'settings_popup'; // ???
const ID_VIEW_POPUP_INTEGRATION_SLACK_SETTINGS = 'integration_slack_settings';
const ID_VIEW_POPUP_FILTER = 'filterPopup';
const ID_VIEW_WINDOW_LINK_SETTINGS = 'settingsLinkPopupGantt';
const ID_VIEW_WINDOW_PROFILE_MENU = 'profilePopupId';
const ID_VIEW_WINDOW_LIVECHAT = 'liveChat';
// const ID_VIEW_WINDOW_WELCOME = 'welcomeLayout';
const ID_VIEW_WINDOW_NEWS_NOTIFY = 'notificationContainerModal';



const ID_EVENT_APP_MODAL_SHOW = 'app:modal:show';
const ID_EVENT_APP_MODAL_HIDE = 'app:modal:hide';

// @private: inner state of the model
const _state = {
    [ID_VIEW_WINDOW_HISTORY]: false,
    [ID_VIEW_WINDOW_PROFILE_SETTINGS]: false,
    [ID_VIEW_WINDOW_PUBLIC_URL]: false,
    [ID_VIEW_WINDOW_SAVE_TEMPLATE]: false,
    [ID_VIEW_POPUP_BASELINE]: false,
    [ID_VIEW_POPUP_PROJECTS]: false,
    [ID_VIEW_POPUP_CUSTOMGRID]: false,
    [ID_VIEW_POPUP_NOTIFICATIONS]: false,
    [ID_VIEW_POPUP_ASSIGN_RESOURCE]: false,
    [ID_VIEW_WINDOW_TASKVIEW]: false,
    [ID_VIEW_WINDOW_MESSAGEUS]: false,
    [ID_VIEW_WINDOW_PAYMENT_PLAN]: false,
    [ID_VIEW_WINDOW_PROJ_CALENDAR]: false,
    [ID_VIEW_WINDOW_MANAGE_TEAM]: false,
    [ID_VIEW_WINDOW_LIVECHAT]: false,
    [ID_VIEW_WINDOW_INVITE_NOTIFY]: false,
    [ID_VIEW_POPUP_PROJ_SETTINGS]: false,
    [ID_VIEW_POPUP_INTEGRATION_SLACK_SETTINGS]: false,
    [ID_VIEW_WINDOW_REQUEST_PERMISSION]: false,
    [ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE]: false,
    [ID_VIEW_POPUP_FILTER]: false,
    [ID_VIEW_WINDOW_PROFILE_MENU]: false,
    // [ID_VIEW_WINDOW_WELCOME]: false,
    [ID_VIEW_WINDOW_NEWS_NOTIFY]: false,
    [ID_VIEW_POPUP_GLOBALSEARCH]: false
    // [ID_VIEW_WINDOW_HELPBLOCK]: false
};

// {String, Boolean} -> {}
const _updateWindowStateVal = (idWnd, val) => {
    if (_state[idWnd] !== val) {
        _state[idWnd] = val;
        app.trigger(ID_EVENT_POPUPS_CHANGED, _state, idWnd);
    }
}

// ! each change of route
app.on(ID_EVENT_APP_INIT_MODELS, () => {
    //
    if ($$(ID_VIEW_WINDOW_HISTORY)) {
        $$(ID_VIEW_WINDOW_HISTORY).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_HISTORY, true));
        $$(ID_VIEW_WINDOW_HISTORY).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_HISTORY, false));
    }
    if ($$(ID_VIEW_WINDOW_PROFILE_SETTINGS)) {
        $$(ID_VIEW_WINDOW_PROFILE_SETTINGS).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PROFILE_SETTINGS, true));
        $$(ID_VIEW_WINDOW_PROFILE_SETTINGS).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PROFILE_SETTINGS, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_PUBLIC_URL)) {
        $$(ID_VIEW_WINDOW_PUBLIC_URL).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PUBLIC_URL, true));
        $$(ID_VIEW_WINDOW_PUBLIC_URL).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PUBLIC_URL, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_SAVE_TEMPLATE)) {
        $$(ID_VIEW_WINDOW_SAVE_TEMPLATE).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_SAVE_TEMPLATE, true));
        $$(ID_VIEW_WINDOW_SAVE_TEMPLATE).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_SAVE_TEMPLATE, false));
    }
    //
    if ($$(ID_VIEW_POPUP_BASELINE)) {
        $$(ID_VIEW_POPUP_BASELINE).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_BASELINE, true));
        $$(ID_VIEW_POPUP_BASELINE).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_BASELINE, false));
    }
    //
    if ($$(ID_VIEW_POPUP_PROJECTS)) {
        $$(ID_VIEW_POPUP_PROJECTS).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_POPUP_PROJECTS, $$(ID_VIEW_POPUP_PROJECTS).isShown());
        });
        $$(ID_VIEW_POPUP_PROJECTS).attachEvent('onAfterAnimationHide', () => {
            _updateWindowStateVal(ID_VIEW_POPUP_PROJECTS, $$(ID_VIEW_POPUP_PROJECTS).isShown());
        });
    }
    if ($$(ID_VIEW_POPUP_CUSTOMGRID)) {
        $$(ID_VIEW_POPUP_CUSTOMGRID).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_CUSTOMGRID, true));
        $$(ID_VIEW_POPUP_CUSTOMGRID).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_CUSTOMGRID, false));
    }

    if ($$(ID_VIEW_POPUP_NOTIFICATIONS)) {
        $$(ID_VIEW_POPUP_NOTIFICATIONS).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_NOTIFICATIONS, $$(ID_VIEW_POPUP_NOTIFICATIONS).isShown()));
        $$(ID_VIEW_POPUP_NOTIFICATIONS).attachEvent('onAfterAnimationHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_NOTIFICATIONS, $$(ID_VIEW_POPUP_NOTIFICATIONS).isShown()));
    }

    if ($$(ID_VIEW_POPUP_ASSIGN_RESOURCE)) {
        $$(ID_VIEW_POPUP_ASSIGN_RESOURCE).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_ASSIGN_RESOURCE, true));
        $$(ID_VIEW_POPUP_ASSIGN_RESOURCE).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_ASSIGN_RESOURCE, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_TASKVIEW)) {
        $$(ID_VIEW_WINDOW_TASKVIEW).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_TASKVIEW, true));
        $$(ID_VIEW_WINDOW_TASKVIEW).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_TASKVIEW, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_MESSAGEUS)) {
        $$(ID_VIEW_WINDOW_MESSAGEUS).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_MESSAGEUS, true);
        });
        $$(ID_VIEW_WINDOW_MESSAGEUS).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_MESSAGEUS, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_PAYMENT_PLAN)) {
        $$(ID_VIEW_WINDOW_PAYMENT_PLAN).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_PAYMENT_PLAN, true);
        });
        $$(ID_VIEW_WINDOW_PAYMENT_PLAN).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_PAYMENT_PLAN, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_HELP_CENTER)) {
        $$(ID_VIEW_WINDOW_HELP_CENTER).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_HELP_CENTER, true);
        });
        $$(ID_VIEW_WINDOW_HELP_CENTER).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_HELP_CENTER, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_PROJ_CALENDAR)) {
        $$(ID_VIEW_WINDOW_PROJ_CALENDAR).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_PROJ_CALENDAR, true);
        });
        $$(ID_VIEW_WINDOW_PROJ_CALENDAR).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_PROJ_CALENDAR, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_MANAGE_TEAM)) {
        $$(ID_VIEW_WINDOW_MANAGE_TEAM).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_MANAGE_TEAM, true);
        });
        $$(ID_VIEW_WINDOW_PROJ_CALENDAR).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_MANAGE_TEAM, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_TIME_TRACKING)) {
        $$(ID_VIEW_WINDOW_TIME_TRACKING).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_TIME_TRACKING, true);
        });
        $$(ID_VIEW_WINDOW_TIME_TRACKING).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_TIME_TRACKING, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_TIME_PAYMENT)) {
        $$(ID_VIEW_WINDOW_TIME_PAYMENT).attachEvent('onShow', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_TIME_PAYMENT, true);
        });
        $$(ID_VIEW_WINDOW_TIME_PAYMENT).attachEvent('onHide', () => {
            _updateWindowStateVal(ID_VIEW_WINDOW_TIME_PAYMENT, false);
        });
    }
    //
    if ($$(ID_VIEW_WINDOW_HELPBLOCK)) {
        $$(ID_VIEW_WINDOW_HELPBLOCK).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_HELPBLOCK, true));
        $$(ID_VIEW_WINDOW_HELPBLOCK).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_HELPBLOCK, false))
    }
    //
    if ($$(ID_VIEW_WINDOW_DELETE_USER)) {
        $$(ID_VIEW_WINDOW_DELETE_USER).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_DELETE_USER, true));
        $$(ID_VIEW_WINDOW_DELETE_USER).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_DELETE_USER, false))
    }
    //
    if ($$(ID_VIEW_WINDOW_INVITE_NOTIFY)) {
        $$(ID_VIEW_WINDOW_INVITE_NOTIFY).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_INVITE_NOTIFY, true));
        $$(ID_VIEW_WINDOW_INVITE_NOTIFY).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_INVITE_NOTIFY, false))
    }

    if ($$(ID_VIEW_POPUP_GLOBALSEARCH)) {
        $$(ID_VIEW_POPUP_GLOBALSEARCH).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_GLOBALSEARCH, $$(ID_VIEW_POPUP_GLOBALSEARCH).isShown()));
        $$(ID_VIEW_POPUP_GLOBALSEARCH).attachEvent('onAfterAnimationHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_GLOBALSEARCH, $$(ID_VIEW_POPUP_GLOBALSEARCH).isShown()));
    }
    //
    app.on(ID_APP_EVENT_SUPPORTCHAT_SHOW, () =>
        _updateWindowStateVal(ID_VIEW_WINDOW_LIVECHAT, true));
    app.on(ID_APP_EVENT_SUPPORTCHAT_HIDE, () =>
        _updateWindowStateVal(ID_VIEW_WINDOW_LIVECHAT, false));
    //
    app.on(ID_EVENT_APP_MODAL_SHOW, (data) => {
        if (data.idModal === ID_VIEW_WINDOW_INVITE_NOTIFY) {
            _updateWindowStateVal(ID_VIEW_WINDOW_INVITE_NOTIFY, true);
        }
    });
    app.on(ID_EVENT_APP_MODAL_HIDE, (data) => {
        if (data.idModal === ID_VIEW_WINDOW_INVITE_NOTIFY) {
            _updateWindowStateVal(ID_VIEW_WINDOW_INVITE_NOTIFY, false);
        }
    });
    // !FIXME
    // if ($$(ID_VIEW_POPUP_PROJ_SETTINGS)) {
    //     $$(ID_VIEW_POPUP_PROJ_SETTINGS).attachEvent('onShow', () =>
    //         _updateWindowStateVal(ID_VIEW_POPUP_PROJ_SETTINGS, true));
    //     $$(ID_VIEW_POPUP_PROJ_SETTINGS).attachEvent('onBlur', () =>
    //         _updateWindowStateVal(ID_VIEW_POPUP_PROJ_SETTINGS, $$(ID_VIEW_POPUP_PROJ_SETTINGS).config.isShown()))
    // }
    //
    if ($$(ID_VIEW_WINDOW_REQUEST_PERMISSION)) {
        $$(ID_VIEW_WINDOW_REQUEST_PERMISSION).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_REQUEST_PERMISSION, true));
        $$(ID_VIEW_WINDOW_REQUEST_PERMISSION).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_REQUEST_PERMISSION, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE)) {
        $$(ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE, true));
        $$(ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_INTEGRATION_WRONG_WORKSPACE, false));
    }
    //
    if ($$(ID_VIEW_POPUP_FILTER)) {
        $$(ID_VIEW_POPUP_FILTER).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_FILTER, true));
        $$(ID_VIEW_POPUP_FILTER).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_POPUP_FILTER, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_LINK_SETTINGS)) {
        $$(ID_VIEW_WINDOW_LINK_SETTINGS).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_LINK_SETTINGS, true));
        $$(ID_VIEW_WINDOW_LINK_SETTINGS).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_LINK_SETTINGS, false));
    }
    //
    if ($$(ID_VIEW_WINDOW_DELETE_USER_FINISH)) {
        $$(ID_VIEW_WINDOW_DELETE_USER_FINISH).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_DELETE_USER_FINISH, true));
        $$(ID_VIEW_WINDOW_DELETE_USER_FINISH).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_DELETE_USER_FINISH, false));
    }
    //
    // if ($$(ID_VIEW_WINDOW_WELCOME)) {
    //     $$(ID_VIEW_WINDOW_WELCOME).attachEvent('onShow', () =>
    //         _updateWindowStateVal(ID_VIEW_WINDOW_WELCOME, true));
    //     $$(ID_VIEW_WINDOW_WELCOME).attachEvent('onHide', () =>
    //         _updateWindowStateVal(ID_VIEW_WINDOW_WELCOME, false));
    // }
    //
    if ($$(ID_VIEW_WINDOW_PROFILE_MENU)) {
        $$(ID_VIEW_WINDOW_PROFILE_MENU).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PROFILE_MENU, true));
        $$(ID_VIEW_WINDOW_PROFILE_MENU).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_PROFILE_MENU, false))
    }
    //
    if ($$(ID_VIEW_WINDOW_NEWS_NOTIFY)) {
        $$(ID_VIEW_WINDOW_NEWS_NOTIFY).attachEvent('onShow', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_NEWS_NOTIFY, true));
        $$(ID_VIEW_WINDOW_NEWS_NOTIFY).attachEvent('onHide', () =>
            _updateWindowStateVal(ID_VIEW_WINDOW_NEWS_NOTIFY, false))
    }
});

// {} -> {Boolean}
const isAnyPopupOpened = () => {
    // Object.keys(_state).forEach((idPopupView) => {
    //     if ($$(idPopupView) &&
    //         ((typeof $$(idPopupView).isShown === 'function' && $$(idPopupView).isShown()) ||
    //         $$(idPopupView).isVisible())
    //     ) {
    //         _state[idPopupView] = true;
    //     } else {
    //         _state[idPopupView] = false;
    //     }
    // });

    const wndIds = Object.keys(_state);

    for (let i = 0, len = wndIds.length; i < len; i++) {
        if (_state[wndIds[i]] === true) {
            return true;
        }
    }

    return false;
};

// {} -> {}
const _updatePopupState = () => {
    setTimeout(() => {
        Object.keys(_state).forEach((idPopupView) => {
            if ($$(idPopupView)) {
                if (typeof $$(idPopupView).isShown === 'function') {
                    _state[idPopupView] = $$(idPopupView).isShown();
                }
                else if (
                    idPopupView === ID_VIEW_POPUP_PROJ_SETTINGS &&
                    $$(idPopupView).config &&
                    typeof $$(idPopupView).config.isShown === 'function'
                ) {
                    // !!!
                    _state[idPopupView] = $$(idPopupView).config.isShown();
                }
                else {
                    _state[idPopupView] = $$(idPopupView).isVisible();
                }
            } else {
                _state[idPopupView] = false;
            }
        });
        // if ($$(idPopupView) &&
        //     ((typeof $$(idPopupView).isShown === 'function' && $$(idPopupView).isShown()) ||
        //     $$(idPopupView).isVisible())
        // ) {
        //     _state[idPopupView] = true;
        // } else {
        //     _state[idPopupView] = false;
        // }
        //console.warn('[popups::_updatePopupState]', _state); // !DEBUG
        app.trigger(ID_EVENT_POPUPS_CHANGED, _state);
    }, 0);
}

app.on(ID_EVENT_APP_ROUTECHANGED, _updatePopupState);

app.on(ID_EVENT_WINDOW_STATE_CHANGED, function(data) {
    if (Object.keys(_state).indexOf(data.id) === -1) {
        console.warn('[popups] -> unknown window', id);
        return;
    }

    _updateWindowStateVal(data.id, data.isVisible);
})

export {
    isAnyPopupOpened
}
