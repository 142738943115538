import app from '../../../app';
import _ from '../../../libs/lodash';
import templates from '../../../templates/labels';

const __ = window.__;

if (!webix.env.touch && webix.ui.scrollSize) webix.CustomScroll.init();

const innerViews = {
  currentWorkLabel: {
    view: 'label',
    id: 'currentWorkLabel',
    width: 160,
    label: __('project_settings_current_work'),
  },
  daysRangeLabel: {
    view: 'label',
    id: 'daysRangeLabel',
    width: 160,
    label: __('project_settings_days'),
    css: 'label-with-spacer',
    template: templates.getTemplateForLabelWithSpacerAndNumber,
    toolTip: {
      y: 4,
      langKey: 'project_settings_range_days_tooltip',
    },
  },
  displayOptionsListDays: {
    view: 'daysList',
    id: 'displayOptionsListDays',
    label: __('settings_day_popup'),
    on: {
      onSelectChange() {
        innerHandlers.changeDays(true);
      },
    },
  },
};

const innerSettings = {
  currentSettings: {},
};

var innerHandlers = {
  init(currentSettings, startOnMonday) {
    const webixDayUI = $$(innerViews.displayOptionsListDays.id);

    if (!currentSettings.showDay.length) {
      for (let i = currentSettings.startDay; i <= currentSettings.endDay; i++) {
        currentSettings.showDay.push(i);
      }
    }

    currentSettings.showDay = currentSettings.showDay.map(day => {
      day = parseInt(day, 10);
      if (day === 0) {
        day = 7;
      }

      return day;
    });

    innerSettings.currentSettings = webix.copy(currentSettings);
    innerHandlers.startOnMondayChange(webixDayUI, startOnMonday);
  },
  changeDays(withoutSave) {
    const webixDayUI = $$(innerViews.displayOptionsListDays.id);
    let selectedDays = webixDayUI.getSelectedId();

    if (typeof selectedDays === 'number' || _.toSafeInteger(selectedDays)) {
      selectedDays = [selectedDays];
    }

    if (typeof selectedDays === 'string') {
      selectedDays = [];
    }

    selectedDays = selectedDays
      .sort((a, b) => a - b)
      .map(dayNumber => parseInt(dayNumber, 10));

    if (!selectedDays.length) {
      webixDayUI.blockEvent();
      webixDayUI.selectAll();
      selectedDays = webixDayUI.getSelectedId();
      webixDayUI.unblockEvent();
    }

    innerSettings.currentSettings.startDay = selectedDays[0];
    innerSettings.currentSettings.endDay = selectedDays[selectedDays.length - 1];
    innerSettings.currentSettings.showDay = selectedDays;
    this.updateNumberInLabel($$(innerViews.daysRangeLabel.id), selectedDays.length);
    app.trigger('updateCalendarMonths');
    if (withoutSave) return; // to update nly counter, without save

    app.trigger('settings:zoom:save');
  },
  updateZoomSettings(updatedCurrentData) {
    innerSettings.currentSettings = webix.copy(updatedCurrentData);
  },
  checkNewData(oldValue) {
    return !_.isEqual(oldValue, innerSettings.currentSettings.showDay);
  },
  getCurrentDays() {
    return [innerSettings.currentSettings.startDay, innerSettings.currentSettings.endDay];
  },
  getWorkingDays() {

    return innerSettings.currentSettings.showDay;
  },
  getNewDaysRange() {
    if (innerSettings.currentSettings.showDay) {
      this.updateNumberInLabel($$(innerViews.daysRangeLabel.id), innerSettings.currentSettings.showDay.length);
    }

    return webix.copy(innerSettings.currentSettings.showDay || []);
  },
  updateNumberInLabel(webixUI, number) {
    webixUI.config.number = `${number} ${__('d')}`;
    webixUI.refresh();
  },
  startOnMondayChange(webixDayUI, startOnMonday) {
    if (!innerSettings.currentSettings.showDay) {
      innerHandlers.changeDays();
    }

    webixDayUI = webixDayUI || $$(innerViews.displayOptionsListDays.id);
    webixDayUI.clearAll();

    if (startOnMonday) {
      webixDayUI.parse(innerConst.startOnMonday);
    } else {
      webixDayUI.parse(innerConst.startOnSunday);
    }

    webixDayUI.unselectAll();
    webixDayUI.blockEvent();
    webixDayUI.select(innerSettings.currentSettings.showDay);

    $$(innerViews.daysRangeLabel.id).config.number = `${innerSettings.currentSettings.showDay.length} ${__('d')}`;

    innerSettings.startOnMonday = startOnMonday || !!innerSettings.startOnMonday;
    webixDayUI.unblockEvent();
    webixDayUI.refresh();
  },
  getLabelForIntervals() {
    let labelWithIntervals = '';
    const labelDelimiter = ',&nbsp;';
    const intervals = [];
    let interValIndex = 0;
    const selectedDays = innerHandlers.getNewDaysRange();
    const showDaysIntervals = [];

    if (!innerSettings.startOnMonday) {
      let sundayIndex = 0;

      selectedDays.some((dayNumber, dayIndex) => {
        if (dayNumber === 7) {
          sundayIndex = dayIndex;
          selectedDays.splice(sundayIndex, 1);
          selectedDays.unshift(7);

          return true;
        }
      });
    }

    selectedDays.some(item => {
      if (!intervals[interValIndex]) {
        intervals[interValIndex] = {};
        intervals[interValIndex].interval = [];
        intervals[interValIndex].interval.push(parseInt(item));
        intervals[interValIndex].checkValue = parseInt(item);
      } else if (intervals[interValIndex].checkValue !== undefined
        && (intervals[interValIndex].checkValue + 1 === parseInt(item) || intervals[interValIndex].checkValue % 7 + 1 === parseInt(item))) {
        intervals[interValIndex].checkValue = parseInt(item);
      } else {
        intervals[interValIndex].interval.push(intervals[interValIndex].checkValue);
        interValIndex++;
        intervals[interValIndex] = {};
        intervals[interValIndex].interval = [];
        intervals[interValIndex].interval.push(parseInt(item));
        intervals[interValIndex].checkValue = parseInt(item);
      }
    });

    if (!intervals[interValIndex].interval) {
      intervals[interValIndex].interval = [];
    }
    intervals[interValIndex].interval.push(intervals[interValIndex].checkValue);

    intervals.forEach(interval => {
      showDaysIntervals.push(interval.interval[0]);
      showDaysIntervals.push(interval.interval[1]);
    });

    for (let i = 0; i < showDaysIntervals.length; i += 2) {
      if (showDaysIntervals[i + 1] !== showDaysIntervals[i] && showDaysIntervals[i + 1]) {
        labelWithIntervals += `${innerConst.daysTranslateShortTitles[showDaysIntervals[i]]} - ${
          innerConst.daysTranslateShortTitles[showDaysIntervals[i + 1]]}${labelDelimiter}`;
      } else {
        labelWithIntervals += innerConst.daysTranslateShortTitles[showDaysIntervals[i]] + labelDelimiter;
      }
    }

    return labelWithIntervals.slice(0, labelWithIntervals.length - labelDelimiter.length);
  },
};

var innerConst = {
  startOnMonday: [
    { id: 1, value: __('common_short_mon') },
    { id: 2, value: __('common_short_tue') },
    { id: 3, value: __('common_short_wed') },
    { id: 4, value: __('common_short_thu') },
    { id: 5, value: __('common_short_fri') },
    { id: 6, value: __('common_short_sat') },
    { id: 7, value: __('common_short_sun') },
  ],
  startOnSunday: [
    { id: 7, value: __('common_short_sun') },
    { id: 1, value: __('common_short_mon') },
    { id: 2, value: __('common_short_tue') },
    { id: 3, value: __('common_short_wed') },
    { id: 4, value: __('common_short_thu') },
    { id: 5, value: __('common_short_fri') },
    { id: 6, value: __('common_short_sat') },
  ],
  daysTranslateShortTitles: {
    1: __('common_short_mon'),
    2: __('common_short_tue'),
    3: __('common_short_wed'),
    4: __('common_short_thu'),
    5: __('common_short_fri'),
    6: __('common_short_sat'),
    7: __('common_short_sun'),
  },
};

const outputObject = {
  init: innerHandlers.init,
  views: {
    currentWorkLabel: innerViews.currentWorkLabel,
    daysRangeLabel: innerViews.daysRangeLabel,
    displayOptionsListDays: innerViews.displayOptionsListDays,
  },
  handlers: {
    checkNewData: innerHandlers.checkNewData,
    getCurrentDays: innerHandlers.getCurrentDays,
    getNewDaysRange: innerHandlers.getNewDaysRange,
    getWorkingDays: innerHandlers.getWorkingDays,
    getLabelForIntervals: innerHandlers.getLabelForIntervals,
    startOnMondayChange: innerHandlers.startOnMondayChange,
    updateNumberInLabel: innerHandlers.updateNumberInLabel,
    changeDays: innerHandlers.changeDays,
  },
};

app.on('settings:startOnMonday:change', startOnMonday => {
  innerHandlers.startOnMondayChange($$(innerViews.displayOptionsListDays.id), startOnMonday);
});

export default outputObject;
