class Sso {
  static get path() {
    return 'api/sso';
  }

  static get pathDomains() {
    return 'api/sso/domains';
  }

  static get pathExcludedEmails() {
    return 'api/sso/excluded_emails';
  }

  static getData() {
    return webix.ajax().get(Sso.path);
  }

  static update(data) {
    const res = webix.ajax().sync().put(Sso.path, data);

    if (res.status === 200) {
      return true;
    }

    return false;
  }

  static activate() {
    const res = webix.ajax().sync().post(Sso.path);

    if (res.status !== 200) { return false; }

    const sso = JSON.parse(res.responseText);

    if (sso) {
      user.sso = sso;
    }

    return Promise.resolve();
  }

  static disable() {
    return webix.ajax().put(Sso.path, {
      enabled: 0,
    })
      .then(() => {});
  }

  static addDomain(domain) {
    const item = {
      sso_id: user.sso.id,
      domain,
    };

    const res = webix.ajax().sync().post(Sso.pathDomains, item);

    if (res.status !== 200) { return false; }

    item.id = JSON.parse(res.responseText)[0];
    if (!item.id) { return 0; }

    user.sso.domains.push(item);

    return item;
  }

  static deleteDomain(id) {
    const res = webix.ajax().sync().del(Sso.pathDomains, {
      id,
    });

    if (res.status === 200) {
      const index = user.sso.domains.findIndex(item => item.id == id);

      if (index === -1) return;
      user.sso.domains.splice(index, 1);

      return true;
    }

    return false;
  }

  static addExcludedEmail(email) {
    const item = {
      sso_id: user.sso.id,
      email,
    };

    const res = webix.ajax().sync().post(Sso.pathExcludedEmails, item);

    if (res.status !== 200) { return false; }

    item.id = JSON.parse(res.responseText)[0];
    if (!item.id) { return 0; }

    user.sso.excluded_emails.push(item);
    if (!user.sso.sso_excluded_emails) { user.sso.sso_excluded_emails = item.email; }
    else { user.sso.sso_excluded_emails = `${user.sso.sso_excluded_emails},${item.email}`; }

    return item;
  }

  static deleteExcludedEmail(id) {
    const res = webix.ajax().sync().del(Sso.pathExcludedEmails, {
      id,
    });

    if (res.status === 200) {
      const index = user.sso.excluded_emails.findIndex(item => item.id == id);

      if (index === -1) return;
      const ssoExcludedEmails = user.sso.sso_excluded_emails.split(',');
      const filtered = ssoExcludedEmails.filter(email => user.sso.excluded_emails[index].email !== email);

      user.sso.sso_excluded_emails = filtered.join(',');
      user.sso.excluded_emails.splice(index, 1);

      return true;
    }

    return false;
  }
}

export default Sso;
