var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15 5H16V8.0854L15.5 7.5L15 8.0854V10.9146L15.5 11.5L16 10.9146V19H15V10.9146V8.0854V5Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M15.5 11C15.6753 11 15.8436 10.9699 16 10.9146C16.5826 10.7087 17 10.1531 17 9.5C17 8.8469 16.5826 8.2913 16 8.0854C15.8436 8.0301 15.6753 8 15.5 8C15.3247 8 15.1564 8.0301 15 8.0854C14.4174 8.2913 14 8.8469 14 9.5C14 10.1531 14.4174 10.7087 15 10.9146C15.1564 10.9699 15.3247 11 15.5 11ZM15.5 12C16.8807 12 18 10.8807 18 9.5C18 8.1193 16.8807 7 15.5 7C14.1193 7 13 8.1193 13 9.5C13 10.8807 14.1193 12 15.5 12Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8 5H9V13.0854L8.5 12.5L8 13.0854V15.9146L8.5 16.5L9 15.9146V19H8V15.9146V13.0854V5Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.5 16C8.67532 16 8.84361 15.9699 9 15.9146C9.5826 15.7087 10 15.1531 10 14.5C10 13.8469 9.5826 13.2913 9 13.0854C8.84361 13.0301 8.67532 13 8.5 13C8.32468 13 8.15639 13.0301 8 13.0854C7.4174 13.2913 7 13.8469 7 14.5C7 15.1531 7.4174 15.7087 8 15.9146C8.15639 15.9699 8.32468 16 8.5 16ZM8.5 17C9.88071 17 11 15.8807 11 14.5C11 13.1193 9.88071 12 8.5 12C7.11929 12 6 13.1193 6 14.5C6 15.8807 7.11929 17 8.5 17Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }