var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$.skeleton_row] }, [
    _c(
      "div",
      { class: _vm.$.firstCell },
      [
        _c("skeleton-item", { attrs: { type: "square", size: "lst" } }),
        _vm._v(" "),
        _c("skeleton-item", {
          style: { height: "17px" },
          attrs: { type: "bar", width: "middle" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wideCell },
      [
        _c("skeleton-item", {
          style: { height: "23px" },
          attrs: { type: "bar", width: "wide" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.resourceCell },
      _vm._l(_vm.resourceCell, function (i) {
        return _c(
          "div",
          [
            _c("skeleton-item", {
              key: i,
              attrs: { type: "circle", size: "bg" },
            }),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.tinyCell },
      [
        _c("skeleton-item", {
          style: { height: "11px" },
          attrs: { type: "bar", width: "tiny" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wideCell },
      [
        _c("skeleton-item", {
          style: { height: "23px" },
          attrs: { type: "bar", width: "wide" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wideCell },
      [
        _c("skeleton-item", {
          style: { height: "23px" },
          attrs: { type: "bar", width: "wide" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.tinyCell },
      [
        _c("skeleton-item", {
          style: { height: "11px" },
          attrs: { type: "bar", width: "tiny" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$.wideCell },
      [
        _c("skeleton-item", {
          style: { height: "23px" },
          attrs: { type: "bar", width: "wide" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }