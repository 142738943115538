import app from '../../../app';
import globalStore from '../../../store/main';

function onTaskAttachementCreated(event) {
  event.data.attachments.forEach(
    attachment => globalStore.dispatch('attachments/onWSAttachmentCreated', { projectId: attachment.projectId, attachment }),
  );

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.attachments.map(i => i.taskId),
  });
}

export default onTaskAttachementCreated;
