<template>
  <div :class="['table-body', className ? className : '']">
    <v-data-table
      v-model="selected"
      :class="['elevation-0 resources-table', resources.length ? 'gantt_scroll' : '']"
      fixed-header
      :height="tableHeight"
      :headers="headers"
      :items="resources"
      :item-key="id"
      hide-default-footer
      disable-pagination
      :show-select="resourcesType === 'material'"
      checkbox-color="#1565C0"
      :calculate-widths="true"
      :sort-by.sync="sortBy"
      @click:row="onClickRow"
    >
      <template #header.data-table-select="{ props, on }">
        <div
          class="table-checkbox table-header"
        >
          <vgp-checkbox
            id="gp_autotest_resource_table_header_checkbox_select_all"
            :size="16"
            :checked="props.value"
            :is-disable="isHasJiraResources || actionsDisabled"
            @onClick="on.input(!props.value)"
          />
        </div>
      </template>
      <template #item.data-table-select="{ item, select, isSelected }">
        <div
          class="table-checkbox"
          @click.stop="onClickRow"
        >
          <vgp-checkbox
            class="gp_autotest_resource_table_item_checkbox_select"
            :size="16"
            :checked="isSelected"
            :is-disable="disableJira(item) || actionsDisabled"
            @onClick="select(!isSelected)"
          />
        </div>
      </template>
      <template #item.name="{ item }">
        <div :class="['table-cell table-cell__info', resourcesType === 'people' ? 'people' : 'material']">
          <vgp-avatar
            :src="item.photo"
            size="large"
          />
          <div class="info">
            <div
              class="name"
              :style="{maxWidth: widthName}"
            >
              {{ item.name }}
            </div>
            <div class="email">
              {{ item.email }}
            </div>
          </div>
        </div>
      </template>
      <template #item.roleTitle="{ item }">
        <div
          :data-id="item.id"
          :class="['table-cell table-cell__role', item.isOwner ? 'owner-item' : '']"
        >
          <div
            v-if="item.isOwner"
            class="cell-text bold"
          >
            {{ item.role.title }}
          </div>
          <div
            v-else
            :style="[actionsDisabled ? {'pointer-events': 'none'} : {}, !item.accessToChangeRole ? {'width': 'inherit'} : {}]"
            @click.stop="onClickRow"
          >
            <div
              v-if="!item.accessToChangeRole"
              class="role-name"
            >
              {{ item.role.title }}
            </div>
            <div
              v-else
              class="dropdown"
            >
              <dropdown-select
                class="gp_autotest_resource_table_item_dropdown_select_roles"
                :drop-list="dropdownRoles"
                :disabled="actionsDisabled"
                :class="actionsDisabled ? 'disabled-dropdown' : ''"
                :multiselect="false"
                :show-selected-first="false"
                :show-reset-btn="false"
                :highlight-active-state="false"
                item-template="text"
                track-by-text="title"
                outline="hover"
                drop-position="fixed"
                track-by-item-tooltip="tooltip"
                :fixed-width="320"
                :size="'autoWidth'"
                :need-description="true"
                parent-selector-for-calc-position-drop="resources-table"
                :selected="item.role"
                @selected="onChangeRole($event, item)"
              />
            </div>
          </div>
        </div>
      </template>
      <template #item.type="{ item }">
        <div
          :key="costComponentKey"
          :data-id="item.id"
          :class="['table-cell table-cell__type']"
        >
          <div class="table-cost-dropdown">
            <dropdown-select
              class="gp_autotest_resource_table_item_dropdown_select_cost"
              :drop-list="costDropdown"
              :multiselect="false"
              :show-selected-first="false"
              :show-reset-btn="false"
              :highlight-active-state="false"
              item-template="text"
              track-by-text="text"
              outline="hover"
              track-by-item-tooltip="tooltip"
              :selected="costDropdown.find(i => i.id === getProjectData(item.resourceProjects).type)"
              @selected="$event => onChangeType($event, item, getProjectData(item.resourceProjects))"
            />
          </div>
        </div>
      </template>
      <template #item.cost="{ item }">
        <div
          :key="costComponentKey"
          :data-id="item.id"
          :class="['table-cell table-cell__cost']"
        >
          <div
            v-if="getProjectData(item.resourceProjects).type === 3"
            class="table-cost-input__disable"
          >
            <div class="item" />
          </div>
          <div
            v-else
            class="table-cost-input"
          >
            <vgp-row-input
              class="cost-input gp_autotest_resource_table_item_input_cost"
              :value="getProjectData(item.resourceProjects).cost"
              :font-size="12"
              :max-width="100"
              :input-type="'number'"
              :format="'currency'"
              :bold="true"
              @onChange="(value) => onChangeCost(value, item, getProjectData(item.resourceProjects))"
              @onKeydown="(value) => onChangeCost(value, item, getProjectData(item.resourceProjects))"
            />
          </div>
        </div>
      </template>
      <template #item.changeOwner="{ item }">
        <div
          v-if="item.accessToChangeOwner || (item.isOwner && (meIsKing || meIsProjectOwner))"
          :data-id="item.id"
          :class="['table-cell table-cell__change_owner']"
        >
          <vgp-button
            class="change_btn gp_autotest_resource_table_item_btn_change_owner"
            :label="locales('change_owner_btn')"
            type="text"
            :icon="{name: 'change', type: 'bold', size: 20}"
            icon-side="left"
            :small="true"
            @onClick="onChangeOwner(item)"
          />
        </div>
        <div
          v-else
          style="height: 0"
        />
      </template>
      <template #item.status="{ item }">
        <div
          :data-id="item.id"
          class="table-cell table-cell__activity"
        >
          <div
            v-if="!item.invite.status || item.userId === user.id"
            class="cell-text"
          >
            {{ locales('resource_active_account') }}
          </div>
          <div
            v-else
            class="pending"
          >
            <div class="cell-text">
              {{ locales('invite_pending_status') }}
            </div>
            <tooltip
              v-if="!item.invite.hasOldTeam"
              class="tooltip-wrapper"
              :content="locales('invited_locale')"
              :style="actionsDisabled ? {'pointer-events': 'none'} : {}"
            >
              <template #body>
                <div
                  class="gp_autotest_resource_table_item_btn_copy_invite_link"
                  @click.stop="onClickRow"
                  @click="copyInviteLink(item.email)"
                >
                  <icon-button
                    :icon="{ name:'link-1'}"
                    size="small"
                    type="colored"
                  />
                </div>
              </template>
            </tooltip>
          </div>
        </div>
      </template>
      <template #item.buttons="{ item }">
        <div :class="['table-cell table-cell__options']">
          <div
            v-if="item.accessToConvert && resourcesType === 'material'"
            @click="$emit('onConvertResources', item.id)"
            @click.stop="onClickRow"
          >
            <vgp-button
              class="convert_btn"
              :label="locales('convert_resources_button')"
              type="text"
              :small="true"
            />
          </div>
          <div
            v-if="item.accessToSettings"
            class="icon icon--settings gp_autotest_resource_table_item_btn_open_profile"
            @click="$emit('openProfileCard', item.id)"
          >
            <icon-button
              :icon="{ name:'settings' }"
              size="middle"
            />
          </div>
          <div
            v-if="item.acсessToDelete && selected.length === 0 && !disableJira(item)"
            class="icon icon--delete gp_autotest_resource_table_item_btn_delete"
            @click="$emit('onDeleteResource', item)"
            @click.stop="onClickRow"
          >
            <icon-button
              :icon="{ name:'delete' }"
              size="middle"
            />
          </div>
        </div>
      </template>
    </v-data-table>
    <input
      ref="hiddenLink"
      :style="{
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        zIndex: -1,
      }"
      :editable="false"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

import teamModel from '../../../../models/team';
import projectsModel from '../../../../models/projects';
import constants from '../../../../helpers/constants';

import IconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import tooltip from '$$vueCmp/tooltips/tooltip.vue';
import dropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import DropdownSelect from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import VgpAvatar from '../../../common/VueComponents/avatar/vgpAvatar.vue';
import VgpButton from '../../../common/VueComponents/globalButton/vgpButton.vue';

export default {
  name: 'ResourcesTable',
  components: {
    tooltip,
    dropdownSelect,
    DropdownSelect,
    VgpAvatar,
    IconButton,
    VgpButton,
  },
  props: {
    headers: { type: Array, required: true, default: () => [] },
    resources: { type: Array, required: true, default: () => [] },
    actionsDisabled: { type: Boolean, required: false, default: false },
    resourcesType: { type: String, required: false, default: 'people' },
    roles: { type: Array, required: false, default: () => [] },
    className: { type: String, required: false },
    meIsKing: { type: Boolean, required: false, default: false },
    meIsProjectOwner: { type: Boolean, required: false, default: false },
    costComponentKey: { type: Number, required: true, default: 0 },
  },
  emits: {
    onChangeRole: Object,
    onSelectResources: Array,
    onDeleteResource: Object,
    openProfileCard: Number,
    onChangeType: Object,
    onChangeCost: Object,
    onChangeOwner: String,
    onConvertResources: Object,
  },
  data() {
    return {
      locales: __,
      selected: [],
      constants,
      user,
      costDropdown: [
        {
          id: constants.RESOURCE_TIME_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_TIME_TYPE]),
          tooltip: { content: __('team_resources_per_hour') },
          tooltipPosition: 'right',
        },
        {
          id: constants.RESOURCE_ITEM_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_ITEM_TYPE]),
          tooltip: { content: __('team_resources_per_item') },
          tooltipPosition: 'right',
        },
        {
          id: constants.RESOURCE_COST_TYPE,
          text: __(constants.RESOURCE_TYPE_LOCALE[constants.RESOURCE_COST_TYPE]),
          tooltip: { content: __('team_resources_cost') },
          tooltipPosition: 'right',
        },
      ],
      tableHeight: '',
      widthName: '',
      sortBy: '',
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    isHasJiraResources() {
      let hasJira = false;

      if (this.route.name === 'resources') {
        const isHasJira = this.resources.find(res => res?.isJira);

        hasJira = !!isHasJira;
      }

      if (this.$route.name === 'project') {
        hasJira = this.resources.find(res => !res.email && res.resourceProjects.find(pr => +pr.projectId === +this.route?.params?.projectId)?.isJira);
      }

      return hasJira;
    },
    ganttId() {
      return projectsModel.getActiveProjectData().id;
    },
    dropdownRoles() {
      const roles = this.roles.filter(role => role?.defaultType !== 'OWNER');
      const withTooltip = roles.map(item => {
        const role = { ...item };

        role.tooltip = { content: item?.title };

        return role;
      });

      return withTooltip;
    },
    autoBudget() {
      return projectsModel.getActiveProjectData().config.auto_budget;
    },
  },
  watch: {
    selected(selectedItems) {
      const selected = selectedItems.filter(itm => this.resources.find(i => i.id === itm.id));

      this.$emit('onSelectResources', selected);
    },
    resources(newResources) {
      if (!newResources.length) {
        this.selected = [];
      }

      const selected = this.selected.filter(itm => newResources.find(i => i.id === itm.id));

      this.selected = selected;
      this.$emit('onSelectResources', selected);
      this.$nextTick(() => this.calcHeight());
    },
    resourcesType() {
      this.selected = [];
      this.sortBy = '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.calcHeight();
      this.calcWidthName();
    }, 0);
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    disableJira(resource) {
      if (this.route.name === 'resources') {
        return !resource.userId && resource.isJira;
      }

      if (this.route.name === 'project') {
        return !resource.userId && resource.isJira && resource.resourceProjects.find(pr => +pr.projectId === +this.route?.params?.projectId).isJira;
      }
    },
    getProjectData(resourceProjects) {
      return resourceProjects.find(project => project.projectId === this.ganttId);
    },
    calcWidthName() {
      const tableNode = this.$el;
      const coords = tableNode.getBoundingClientRect();
      const isNameColumn = this.headers.find(itm => itm.value === 'name');
      const width = isNameColumn && isNameColumn.width ? isNameColumn.width - 56 : coords.width - 250;

      this.widthName = `${width}px`;
    },
    calcHeight() {
      const tableNode = this.$el;
      const coords = tableNode.getBoundingClientRect();

      document.documentElement.style.setProperty('--headerTopPosition', coords.top);

      this.tableHeight = `${document.documentElement.clientHeight - coords.top - 30}px`;
      if (!this.resources.length) this.tableHeight = `${48}px`;
    },
    onResize() {
      this.calcHeight();
      this.calcWidthName();
    },
    async copyInviteLink(email) {
      const hiddenInput = this.$refs.hiddenLink;

      const res = await teamModel.getInviteLink(email);
      const data = res.json();

      if (data.error) {
        this.$toast.warning(__('permission_error_unknownError'));

        return;
      }

      hiddenInput.value = data.link;
      hiddenInput.select();
      document.execCommand('copy');
      this.$toast.info(__('public_lick_copied'));
    },
    onChangeRole(data, resourceData) {
      this.$emit('onChangeRole', {
        roleId: data[0].id,
        resource: resourceData,
        projectId: gantt.config.gantt_id,
      });
    },
    onChangeType(newType, resource, project) {
      this.$emit('onChangeType', {
        newTypeId: newType[0].id,
        oldTypeId: project.type,
        resourceId: resource.id,
        projectIds: [project.projectId],
      });
    },
    onChangeCost(value, resource, project) {
      let costValue = value.replace(/,/g, '.');

      if (value.length >= 15) costValue = project.price;

      this.$emit('onChangeCost', {
        oldCost: project.cost,
        newCost: costValue,
        resourceId: resource.id,
        projectIds: [project.projectId],
      });
    },
    onClickRow(rowData) {
      if (rowData && rowData.id) {
        this.$emit('openProfileCard', rowData.id);
      }
    },
    onChangeOwner(resource) {
      this.$emit('onChangeOwner', resource);
    },
  },
};
</script>

<style src='./resourcesTable.less' lang='less'></style>
