<template>
  <div
    id="skeletonListView"
    :class="$.skeleton_container"
    :style="{height: `${height}px`}"
  >
    <skeleton-wrapper
      id="skeletonWrapper"
      :style="{width: '100%'}"
    >
      <template #body>
        <table :style="{width: '100%'}">
          <thead :class="$.tableHeader">
            <tr
              :class="[$.headerRow]"
            >
              <th
                v-for="column in 8"
              >
                <skeleton-item
                  :style="{ height: '12px'}"
                  :type="'bar'"
                  :width="'tiny'"
                />
              </th>
            </tr>
          </thead>
        </table>
      </template>
    </skeleton-wrapper>
    <div :style="{width: '100%', height: '16px'}" />

    <div :class="$.skeleton_table">
      <skeleton-wrapper
        id="skeletonWrapper"
      >
        <template #body>
          <table>
            <tbody>
              <tr>
                <td :class="[$.firstRow, $.skeleton_row]">
                  <div :class="$.firstCell">
                    <skeleton-item
                      :style="{width: `107px`, height: '17px'}"
                      :type="'bar'"
                    />
                  </div>
                  <div :class="$.lastCell">
                    <skeleton-item
                      :style="{width: `107px`, height: '17px'}"
                      :type="'bar'"
                    />
                  </div>
                </td>
              </tr>
              <tr
                v-for="(row, index) in 10"
                :key="index"
              >
                <td>
                  <skeleton-row />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </skeleton-wrapper>
    </div>
  </div>
</template>

<script>

import skeletonRow from './skeletonListViewRow.vue';
import skeletonItem from '../../common/VueComponents/skeletons/skeletonItem.vue';
import skeletonWrapper from '../../common/VueComponents/skeletons/skeletonWrapper.vue';

export default {
  name: 'SkeletonMain',
  components: { skeletonWrapper, skeletonRow, skeletonItem },
  props: {
    height: { type: Number, required: false, default: 300 },
  },

};
</script>

<style module="$" src="./skeleton.less"></style>
