import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import projectsModel from '../../../models/projects';
import globalStore from '$$store/main';
import constants from '../../../helpers/constants';
import { getParentsIDs } from '../tasks/helpers';

class Superviser {
  constructor({
    mode, type, task_id, column_id, events, link_id,
  }) {
    this._mode = mode; // edit, create, contextMenu
    this._type = type; // gantt
    this._events = [...events, 'ProjectDeleted'];
    this._ganttIds = [];
    this._multiviewId = null;
    this._projectId = null;

    this._editedEntity = { task_id, column_id, link_id };
    this._collectedTasksInBlockedEvents = { deleted: [], created: [] };
    this._userResourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;

    const taskData = task_id ? globalStore.getters['tasksModel/getTask'](task_id) : null;

    if (taskData) {
      this._editedEntity.task_id = task_id;
      this._editedEntity.gantt_id = taskData.gantt_id;
      this._editedEntity.type = taskData.type;
      this._editedEntity.task = taskData;
      this._editedEntity.parents = getParentsIDs(task_id);
    }

    const link = link_id ? gantt.getLink(link_id) : null;

    if (link) {
      this._editedEntity.link_id = link_id;
      this._editedEntity.link = link;
    }

    const userCustomColumn = column_id ? globalStore.getters['columns/getCustomColumnById'](column_id) : null;

    if (userCustomColumn) {
      this._editedEntity.column_id = column_id;
      this._editedEntity.userCustomColumn = userCustomColumn;
    }

    this._defineGanttIds();
  }

  get ganttType() {
    return this._type === 'gantt';
  }

  get type() {
    return this._type;
  }

  get mode() {
    return this._mode;
  }

  get contextMenuMode() {
    return this._mode === 'contextMenu';
  }

  get createMode() {
    return this._mode === 'create';
  }

  get projectId() {
    return this._projectId;
  }

  get multiviewId() {
    return this._multiviewId;
  }

  _defineGanttIds() {
    const ids = [];
    const multiviewData = multiViewsProjectsModel.getActiveViewData();

    if (multiviewData) {
      ids.push(...multiviewData.ganttIDs);
      this._multiviewId = multiviewData.id;
    }

    this._projectId = projectsModel.getActiveGanttId();
    this._projectId && ids.push(this._projectId);

    this._ganttIds = ids;
  }

  isneedBlockEvent(e, collectedTasks) {
    this._collectedTasksInBlockedEvents = collectedTasks;

    if (!this._events.includes(e.event)) { return false; }

    if (this._changedUserRole(e)) { return true; }

    if (this.ganttType && this._needBlockForGantt(e)) { return true; }

    if (this._autoBudgetActivated(e)) { return true; }

    if (this._hasCommonUpdates(e) || this._hasUpdatesOnCurrentProject(e)) { return true; }

    return false;
  }

  _needBlockForGantt(e) {
    if (!this.ganttType) { return false; }

    return [
      'ResourceFromProjectsUnassigned',
      'ResourceToProjectsAssigned',
      'CustomFieldFromProjectUnassigned',
      'CustomFieldToProjectAssigned',
    ].includes(e.event);
  }

  _changedUserRole(e) {
    if (e.event !== 'ResourceRoleUpdated') { return false; }

    return e.data.updated.find(i => i.resourceId === this._userResourceId);
  }

  _autoBudgetActivated(e) {
    if (e.event !== 'ProjectConfigUpdated') {
      return false;
    }

    const updated = e.data.updated.find((i) => i.projectId === this._projectId);

    if (!updated) { return false; }

    const currentConfig = projectsModel.getProjectConfig(this._projectId);

    if (currentConfig.auto_budget || !updated.config.auto_budget) {
      return false;
    }

    return true;
  }

  _hasCommonUpdates(e) {
    const events = [
      'CustomFieldUpdated',
      'CustomFieldCreated',
      'CustomFieldDeleted',
      'ResourceDeleted',
      'ResourceCreated',
      'ResourceUpdated',
    ];

    return events.includes(e.event);
  }

  _hasUpdatesOnCurrentProject(e) {
    const updatedProjects = this._getUpdatedProjects(e);

    return !!this._ganttIds.find(id => updatedProjects.includes(id));
  }

  _getUpdatedProjects(e) {
    const ganttIds = [];

    if (e.event === 'ProjectCalendarUpdated') {
      const gids = e.data.updated.map(i => i.projectId);

      ganttIds.push(...gids);
    }

    if (e.event === 'ProjectDeleted') {
      ganttIds.push(e.data.project.projectId);
    }

    if (e.event === 'ProjectArchived') {
      ganttIds.push(e.data.project.projectId);
    }

    // onTask...
    if (['TaskTimeLogsCreated', 'TaskTimeLogDeleted', 'TaskTimeLogUpdated'].includes(e.event)) {
      const gid = e.innerSettings.gantt_id;

      ganttIds.push(gid);
    }

    if (e.event === 'TaskCommentCreated') {
      const gids = e.innerSettings.collaborationItems.map(i => i.gantt_id);

      ganttIds.push(...gids);
    }

    if (e.event === 'TaskCommentDeleted') {
      const gids = [];

      e.data.ids.forEach(commentId => {
        const comment = globalStore.getters['comments/getCommentById'](commentId);

        comment && gids.push(comment.gantt_id);
      });
      ganttIds.push(...gids);
    }

    if (['TaskAttachementCreated', 'TaskAttachementDeleted'].includes(e.event)) {
      const gids = e.innerSettings.attachmentForSockets.map(i => i.gantt_id);

      ganttIds.push(...gids);
    }

    if (['TaskResourceUnassigned', 'TaskResourceAssigned', 'TaskResourceUpdated'].includes(e.event)) {
      const gid = e.innerSettings.projectId;

      ganttIds.push(gid);
    }

    if (['TaskLinkUpdated', 'TaskLinkDeleted', 'TaskLinkCreated'].includes(e.event)) {
      const gids = e.innerSettings.collaborationItems.map(i => i.gantt_id);

      ganttIds.push(...gids);
    }

    if (e.event === 'TaskDeleted') {
      ganttIds.push(...e.data.projectIds);
    }

    if (e.event === 'TaskCreated') {
      const gids = e.data.tasks.map(i => i.projectId);

      ganttIds.push(...gids);
    }

    if (e.event === 'TaskUpdated') {
      const tids = e.data.updated.map(i => i.taskId);
      const gids = this._getProjectsByTasks(tids);
      const collect_gids = this._getGanttIds_IfExistInCollectedCreatedEvents(tids);

      ganttIds.push(...gids, ...collect_gids);
    }

    if (e.event === 'TaskCustomFieldUpdated') {
      const tids = e.data.customFields.map(i => i.taskId);
      const gids = this._getProjectsByTasks(tids);
      const collect_gids = this._getGanttIds_IfExistInCollectedCreatedEvents(tids);

      ganttIds.push(...gids, ...collect_gids);
    }

    if (e.event === 'ResourceToProjectsAssigned') {
      const gids = e.data.resourcesToProjects.map(i => i.projectId);

      ganttIds.push(...gids);
    }

    if (e.event === 'ResourceFromProjectsUnassigned') {
      const gids = e.data.resourcesFromProjects.map(i => i.projectId);

      ganttIds.push(...gids);
    }

    if (e.event === 'ResourceOnProjectsUpdated') {
      const gids = e.data.updated.map(i => i.projectId);

      ganttIds.push(...gids);
    }

    if (e.event === 'CustomFieldToProjectAssigned') {
      const gids = [];

      e.data.assignedCustomFields.forEach(i => gids.push(...i.projectIds));
      ganttIds.push(...gids);
    }

    if (e.event === 'CustomFieldFromProjectUnassigned') {
      const gids = [];

      e.data.unassignedCustomFields.forEach(i => gids.push(...i.projectIds));
      ganttIds.push(...gids);
    }

    if (e.event === 'ResourcesOnProjectsConverted') {
      const gids = e.data.resourcesToTasks.map(i => i.ganttId);

      ganttIds.push(...gids);
    }

    return [...new Set(ganttIds)];
  }

  _getGanttIds_IfExistInCollectedCreatedEvents(task_ids) {
    const created = this._collectedTasksInBlockedEvents.created;
    const projectId = [];

    Object.entries(created).find(([gantt_id, taskIds]) => {
      const exist = taskIds.find(id => task_ids.includes(id));

      exist && projectId.push(+gantt_id);

      return exist;
    });

    return projectId;
  }

  _getProjectsByTasks(tasksIds) {
    const ids = [];

    tasksIds.forEach(id => {
      const taskData = globalStore.getters['tasksModel/getTask'](id);

      taskData && ids.push(taskData.gantt_id);
    });

    return ids;
  }

  needEmergencyUnblock(e) {
    return this._isDeletedEditedEntity(e)
        || this._isEditedTaskBecameProject(e)
        || this._ifEditedTaskBecameMilestone(e)
        || this._isChangedLinksInEditedTask(e)
        || this._ifTaskWhereEditingLinkDeleted(e)
        || this._ifDowngradedEditRightsInCurrentProject(e)
        || this._ifDowngradeUserRole(e)
        || this._ifUnassignUserWithoutEditRightFromEditedTask(e)
        || this._ifUserEditing_startDate_endDate_duration(e)
        || this._ifUserEditing_resources_estimation_duration(e)
        || this._ifUserEditing_startDate_endDate_AndChangedCallendar(e)
        || this._ifUserEditingAssign_cameResourceOnProject(e)
        || this._ifEventTaskCreated(e)
        || this._ifParentBecameTask(e)
        || this._ifUserEditing_startDateInProject(e)
        || this._customFieldEdited(e)
        || this._ifUserEditing_costs_AndAutoBudgetActivated(e);
  }

  _customFieldEdited(e) {
    if (e.event === 'CustomFieldUpdated') {
      return e.data.updated.find(item => item.customFieldId === this._editedEntity?.userCustomColumn?.id);
    }
  }

  _isDeletedEditedEntity(e) {
    if (e.event === 'ProjectArchived') {
      return this._editedEntity.gantt_id === e.data.project.projectId;
    }

    if (e.event === 'ProjectDeleted') { return true; }

    if (e.event === 'TaskDeleted') {
      return e.data.ids.find(id => id === this._editedEntity.task_id);
    }

    if (e.event === 'TaskLinkDeleted' && this._editedEntity.link) {
      return e.data.id.includes(this._editedEntity.link.id);
    }

    if (e.event === 'CustomFieldDeleted') {
      return e.data.ids.find(id => id === this._editedEntity.column_id);
    }

    if (e.event === 'CustomFieldFromProjectUnassigned') {
      return e.data.unassignedCustomFields.find(item => {
        if (item.customFieldId === this._editedEntity.column_id) {
          return this._ganttIds.find(id => item.projectIds.includes(id));
        }

        return false;
      });
    }

    if (e.event === 'ResourceDeleted') {
      return e.data.ids.includes(this._userResourceId);
    }
  }

  _isEditedTaskBecameProject(e) {
    if (e.event !== 'TaskUpdated' || this._editedEntity.type === 'project') { return false; }

    const editableFieldsInProject = ['text', 'start_date', 'predecessors', 'deadline'];

    if (editableFieldsInProject.includes(this._editedEntity.column_id)) { return false; }

    const customColEdited = this._editedEntity.userCustomColumn;

    if (customColEdited && customColEdited.type !== constants.CUSTOM_COLUMNS_TYPES.number.id) {
      return false;
    }

    return e.data.updated.find(item => item.taskId === this._editedEntity.task_id && item.updatedFields.type === 'project');
  }

  _ifTaskWhereEditingLinkDeleted(e) {
    if (e.event !== 'TaskDeleted' || !this._editedEntity.link) { return false; }

    const ids = [this._editedEntity.link.source, this._editedEntity.link.target];

    return e.data.ids.find(task_id => ids.includes(task_id));
  }

  _isChangedLinksInEditedTask(e) {
    if (e.event !== 'TaskLinkCreated' || this._editedEntity.column_id !== 'predecessors') { return false; }

    return e.data.links.find(i => [i.source, i.target].includes(this._editedEntity.task_id));
  }

  _ifDowngradeUserRole(e) {
    if (e.event !== 'ResourceRoleUpdated' || !this._editedEntity.gantt_id) { return false; }

    const ifUserMember = e.data.updated.find(i => i.resourceId === this._userResourceId && i.roleId === constants.ROLES.MEMBER);
    const userProjects = e.innerSettings.projectSettingsData[this._userResourceId];
    const projectSettings = userProjects && userProjects.find(i => i.gantt_id === this._editedEntity.gantt_id);
    const hasResourceEditRights = projectSettings && projectSettings.access.edit;

    return ifUserMember && !hasResourceEditRights;
  }

  _ifDowngradedEditRightsInCurrentProject(e) {
    if (e.event !== 'ResourceOnProjectsUpdated') { return false; }

    const upd = e.data.updated.find(i => i.projectId === this._editedEntity.gantt_id && i.resourceId === this._userResourceId);

    if (!upd) return false;

    // return !upd.rights.edit;
    return true;
  }

  _ifUnassignUserWithoutEditRightFromEditedTask(e) {
    if (e.event !== 'TaskResourceUnassigned') { return false; }

    // check Irina this case
    return e.data.unassignedResources.find(i => {
      if (i.taskId === this._editedEntity.task_id) {
        return i.unassignedResourcesIds.includes(this._userResourceId);
      }

      return false;
    });
  }

  _ifEditedTaskBecameMilestone(e) {
    if (e.event !== 'TaskUpdated' || this._editedEntity.type === 'milestone') { return false; }

    const cameChangesForEditedTask = e.data.updated.find(i => i.taskId === this._editedEntity.task_id);

    if (!cameChangesForEditedTask) { return; }

    const nonEditableFieldsForMilestone = ['duration', 'endDate', 'end_date', 'estimation'];

    return cameChangesForEditedTask.updatedFields.type === 'milestone'
          && nonEditableFieldsForMilestone.includes(this._editedEntity.column_id);
  }

  _ifUserEditing_startDate_endDate_duration(e) {
    if (e.event !== 'TaskUpdated') { return false; }

    if (!['start_date', 'end_date', 'duration'].includes(this._editedEntity.column_id)) {
      return false;
    }

    const task = e.data.updated.find(t => t.taskId === this._editedEntity.task_id);

    if (!task || (task.updatedFields.type && task.updatedFields.type !== 'task')) { return false; }

    const updStart = this._editedEntity.column_id === 'start_date' && (task.updatedFields.duration || task.updatedFields.endDate);
    const updEnd = this._editedEntity.column_id === 'end_date' && (task.updatedFields.duration || task.updatedFields.startDate);
    const updDuration = this._editedEntity.column_id === 'duration' && task.updatedFields.startDate;

    return updStart || updEnd || updDuration;
  }

  _ifUserEditing_resources_estimation_duration(e) {
    if (!this._editedEntity.task_id) {
      return false;
    }

    if (e.event === 'ResourcesOnProjectsConverted') {
      return true;
    }

    // if user are editing resources and changed duration or estimation
    if (this._editedEntity.column_id === 'resource_id' && e.event === 'TaskUpdated') {
      const editingTaskChanges = e.data.updated.find(t => t.taskId === this._editedEntity.task_id);

      if (!editingTaskChanges) { return false; }

      return editingTaskChanges.updatedFields.estimation || editingTaskChanges.updatedFields.duration;
    }

    // if user are editing duration or estimation and changed resources
    if (!['estimation', 'duration'].includes(this._editedEntity.column_id)) { return false; }

    if (e.event === 'TaskResourceAssigned') {
      return e.data.assignedResources.find(i => i.taskId === this._editedEntity.task_id);
    }

    if (e.event === 'TaskResourceUnassigned') {
      return e.data.unassignedResources.find(i => i.taskId === this._editedEntity.task_id);
    }

    return false;
  }

  _ifUserEditing_startDate_endDate_AndChangedCallendar(e) {
    const editingDate = ['start_date', 'end_date'].includes(this._editedEntity.column_id);

    return editingDate && e.event === 'ProjectCalendarUpdated';
  }

  _ifUserEditing_costs_AndAutoBudgetActivated(e) {
    return this._autoBudgetActivated(e) && ['total_price', 'actual_cost'].includes(this._editedEntity.column_id);
  }

  _ifUserEditingAssign_cameResourceOnProject(e) {
    if (e.event !== 'ResourceOnProjectsUpdated' || this._editedEntity.column_id !== 'resource_id') { return false; }

    return e.data.updated.find(i => {
      const settings = globalStore.getters['resourcesModel/getResourceProjectSettings'](i.resourceId, i.projectId);

      if (!settings) return false;

      return i.type !== settings.type || i.cost !== settings.cost;
    });
  }

  _ifEventTaskCreated(e) {
    if (e.event !== 'TaskCreated') { return false; }

    return this.ganttType && (this.contextMenuMode || this.createMode);
  }

  _ifParentBecameTask(e) {
    if (e.event !== 'TaskUpdated' || !this._editedEntity.parents || !this._editedEntity.parents.length) { return; }

    return e.data.updated.find(item => this._editedEntity.parents.includes(item.taskId) && item.updatedFields.type === 'task');
  }

  _ifUserEditing_startDateInProject(e) {
    if (
      e.event !== 'TaskUpdated'
      || this._editedEntity.type !== 'project'
      || this._editedEntity.column_id !== 'start_date'
    ) { return false; }

    let changesEditedTask;
    const chengedParent = [];

    e.data.updated.forEach(t => {
      if (t.taskId === this._editedEntity.task_id && t.updatedFields.startDate) { changesEditedTask = t; }

      if (t.updatedFields.parent) { chengedParent.push(t); }
    });

    if (!changesEditedTask) return false;

    return chengedParent.find(i => {
      const taskFromModel = globalStore.getters['tasksModel/getTask'](i.taskId);

      return taskFromModel && taskFromModel.parent === this._editedEntity.task_id;
    });
  }
}

export default Superviser;
