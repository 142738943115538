import app from '../app';

const innerObject = {
  prepareSelectedHours(incomingArray, timeIntervals) {
    function compareTimes(time1, time2) {
      const [hour1, minute1] = time1.split(':').map(Number);
      const [hour2, minute2] = time2.split(':').map(Number);

      if (hour1 !== hour2) {
        return hour1 - hour2;
      }

      return minute1 - minute2;
    }

    if (Array.isArray(incomingArray) && incomingArray.length > 1) {
      incomingArray.sort(compareTimes);
    } else {
      incomingArray = [incomingArray];
    }

    let start = incomingArray[0];
    let end = incomingArray[0];
    const intervals = [];
    const getIndexByTime = time => timeIntervals.findIndex(interval => interval.id === time);

    for (let i = 1; i < incomingArray.length; i++) {
      const currentTime = incomingArray[i];
      const currentIndex = getIndexByTime(currentTime);
      const endIndex = getIndexByTime(end);

      if (currentIndex === endIndex + 1) {
        end = currentTime;
      } else {
        const endIntervalTime = start === '23:30' || end === '23:30' ? '24:00' : timeIntervals[getIndexByTime(end) + 1].id;

        intervals.push(`${start}-${endIntervalTime}`);
        start = currentTime;
        end = currentTime;
      }
    }
    const endIntervalTime = end === '23:30' ? '24:00' : timeIntervals[getIndexByTime(end) + 1].id;

    intervals.push(`${start}-${endIntervalTime}`);

    return intervals;
  },

  getHoursArrayByIntervalArray(hoursArray, timeList) {
    const selectedHoursByIntervals = [];
    let formattedArray = [];

    if (hoursArray.every(i => typeof i === 'number')) {
      formattedArray = hoursArray.map(i => `${i}:00`);
    } else {
      formattedArray = hoursArray.flatMap(timeRange => timeRange.split('-').map(time => (time.startsWith('0') && (time !== '0:00' && time !== '0:30') ? time.replace(/^0/, '') : time)));
    }

    for (let i = 0; i < formattedArray.length; i += 2) {
      const startHourIndex = timeList.findIndex(item => item.id === formattedArray[i]);

      let endHourIndex = timeList.findIndex(item => item.id === formattedArray[i + 1]);

      if (endHourIndex === -1) endHourIndex = timeList.length - 1;

      for (let index = startHourIndex; index < endHourIndex; index++) {
        const hour = timeList[index].id;

        selectedHoursByIntervals.push(hour);
      }
    }

    return selectedHoursByIntervals;
  },
};

const outputObject = {
  prepareSelectedHours: innerObject.prepareSelectedHours,
  getHoursArrayByIntervalArray: innerObject.getHoursArrayByIntervalArray,
};

export default outputObject;
