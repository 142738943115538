<template>
  <onboarding-section :animate-leave="animateLeave">
    <template #right>
      <div :class="$style.right__wrapper">
        <div
          :class="[
            $style.select__wrapper,
            animateEnter && !animateLeave ? $style.animateEnter : '',
            animateLeave && !animateEnter ? $style.animateLeave : '',
          ]"
        >
          <div
            v-for="item in featuresItems"
            :id="`gp_autotest_registration_5_step_selectItem_${item.value}`"
            :key="item.value"
            :class="[$style.item__wrapper, selectedItems.includes(item.value) ? $style.item__wrapper_selected : '']"
            @click="handleClickItem(item.value)"
          >
            <div :class="$style['item__inner-wrapper']">
              <svg-sprite
                v-if="selectedItems.includes(item.value)"
                :class="$style.selected_icon"
                name="check"
                :size="20"
                color="#ffffff"
              />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <portal
          to="onboarding-footer"
        >
          <vgp-button
            id="gp_autotest_registration_5_step_submit_button"
            :class="[
              $style.submit_button,
              animateEnter && !animateLeave ? $style.animateEnter : '',
              animateLeave && !animateEnter ? $style.animateLeave : '',
            ]"
            :type="!isAnyItemSelected ? 'text-green' : 'green'"
            :label="!isAnyItemSelected ? locales('onboarding_skip_step_btn_label') : locales('onboarding_continue_btn_label')"
            small
            @onClick="handleButtonClick"
          />
        </portal>
      </div>
    </template>
  </onboarding-section>
</template>

<script>
import OnboardingSection from '../OnboardingSection.vue';
import profile from '../../../../models/profile';
import { mapState } from 'vuex';
import  rights from '../../../../components/rights/index';
import { ANALYTIC_ACTIONS } from '../../onboarding.config';

export default {
  name: 'MembersInfo',
  components: {
    OnboardingSection,
  },
  data() {
    return {
      locales: __,
      animateEnter: false,
      animateLeave: false,
      featuresItems: [
        {
          label: __('onboarding_features_step_scheduling_label'),
          value: 'basic_project_scheduling',
        },
        {
          label: __('onboarding_features_step_planning_label'),
          value: 'high_level_planning',
        },
        {
          label: __('onboarding_features_step_collaboration_label'),
          value: 'team_collaboration',
        },
        {
          label: __('onboarding_features_step_management_label'),
          value: 'resource_management',
        },
        {
          label: __('onboarding_features_step_time_tracking_label'),
          value: 'time_tracking',
        },
        {
          label: __('onboarding_features_step_reporting_label'),
          value: 'reporting',
        },
      ],
      selectedItems: [],
    };
  },
  computed: {
    ...mapState('onboarding', ['analyticType', 'activeStepId']),
    isAnyItemSelected() {
      return !!this.selectedItems.length;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateEnter = true;
    }, 0);
  },
  methods: {
    handleClickItem(value) {
      if (this.selectedItems.includes(value)) {
        const index = this.selectedItems.findIndex(item => item === value);

        this.selectedItems.splice(index, 1);

        return;
      }
      this.selectedItems.push(value);
    },
    handleButtonClick() {
      if (this.animateLeave) {
        return;
      }

      if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.DONE });
      }
      if (this.isAnyItemSelected) {
        profile.saveSettings({ user_interested_features: this.selectedItems.join(', ') });
      } else if (rights.account.isOwner()) {
        userExtAnalytics.log('onboarding', { type: this.analyticType, step: this.activeStepId, action: ANALYTIC_ACTIONS.SKIP });
      }

      this.animateEnter = false;
      this.animateLeave = true;
      setTimeout(() => {
        this.$store.dispatch('onboarding/nextStep');
      }, 750);
    },
  },
};
</script>

<style lang="less" module>
@import "../../../../less/_variables.less";

.right__wrapper {
  width: 364px;
}

.select__wrapper {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

.item__wrapper {
  transition: all .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E3E8EC;
  border-radius: 5px;
  padding: 7px 24px;
  cursor: pointer;
  font-family: Lato-Bold, sans-serif;
  color: #586972;
  font-size: 16px;
  line-height: 26px;

  &_selected {
    background: #337870;
    color: #FFFFFF;
  }

  &:hover {
    color: #FFFFFF;
    background: #337870;
    box-shadow: 0px 6px 20px rgba(51, 120, 112, 0.35);
  }
}

.item__inner-wrapper {
  display: flex;
  align-items: center;
}

.selected_icon {
  margin-right: 6px;
  margin-bottom: 4px;
  svg path {
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.submit_button {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  &.animateLeave {
    transition: opacity .2s, transform .2s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .3s;
    opacity: 0;
    transform: translateY(-40px);
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
