var render = function () {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      key: _vm.componentKey + _vm.redraw,
      class:
        ((_obj = {}),
        (_obj[_vm.$style.active] = _vm.isHighlight),
        (_obj[_vm.$style.wrap] = true),
        (_obj[_vm.$style.open] = _vm.isOpen && !_vm.disabled),
        (_obj[_vm.$style.disable] = _vm.disabled),
        (_obj[_vm.$style.outline_none] = _vm.outline === "none"),
        (_obj[_vm.$style.outline_hover] = _vm.outline === "hover"),
        (_obj[_vm.$style.outline_always] = _vm.outline === "always"),
        (_obj["vgp-interactive-element"] = true),
        _obj),
      style: {
        "font-size": _vm.view.fontSize + "px",
        height: _vm.view.itemHeight ? _vm.view.itemHeight + "px" : "32px",
      },
      attrs: { id: "dropDownSelect-" + _vm.componentKey },
    },
    [
      _c(
        "div",
        {
          ref: "selected_wrap",
          class:
            ((_obj$1 = {}),
            (_obj$1[_vm.$style.select_inner] = true),
            (_obj$1[_vm.$style.select_avatar] = _vm.itemTemplate === "user"),
            _obj$1),
          attrs: { id: _vm.gpAutotestName },
          on: { click: _vm.onClickDropTrigger },
        },
        [
          _vm.useSearch && _vm.isOpen
            ? [
                _c("sprite", {
                  class: [_vm.$style.search_icon],
                  attrs: { name: "search", type: "regular", size: 24 },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTxt,
                      expression: "searchTxt",
                    },
                  ],
                  ref: "search_input",
                  class: [_vm.$style.search_input],
                  style: Object.assign({}, _vm.selectedInputWidthStyle),
                  attrs: {
                    id: "select-drop-trigger-" + _vm.componentKey,
                    placeholder: _vm.searchTxt ? "" : _vm.locale.search,
                    type: "text",
                  },
                  domProps: { value: _vm.searchTxt },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchTxt = $event.target.value
                    },
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.selected_items,
                _vm.useSearch && _vm.isOpen ? _vm.$style.transparent : "",
              ],
              style: Object.assign({}, _vm.selectedInputWidthStyle),
            },
            [
              !_vm.localSelected.length && !_vm.stubTextIfAllUnselected
                ? _c("span", { class: [_vm.$style.placeholder_txt] }, [
                    _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.multiselect
                ? [
                    (_vm.isSelectedAllItems && _vm.stubTextIfAllSelected) ||
                    (_vm.stubTextIfAllUnselected && _vm.isUnselectedAllItems)
                      ? [
                          _vm.isSelectedAllItems && _vm.dropList.length
                            ? _c("vgpChip", {
                                attrs: {
                                  "gray-theme": true,
                                  text: _vm.stubTextIfAllSelected,
                                  preset: "text",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isUnselectedAllItems
                            ? _c("vgpChip", {
                                attrs: {
                                  "gray-theme": true,
                                  text: _vm.stubTextIfAllUnselected,
                                  preset: "text",
                                },
                              })
                            : _vm._e(),
                        ]
                      : [
                          _vm.multiSelected.length
                            ? _c("vgpRollItems", {
                                attrs: {
                                  items: _vm.multiSelected,
                                  offset: _vm.itemTemplate === "user" ? -4 : 4,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c("vgpChip", {
                                            attrs: {
                                              size: _vm.size,
                                              preset:
                                                _vm.itemTemplate === "user" &&
                                                _vm.localSelected.length > 1
                                                  ? "avatar"
                                                  : _vm.itemTemplate,
                                              text: item[_vm.trackByText],
                                              "img-url":
                                                item[_vm.trackByImgUrl],
                                              "bg-color":
                                                item[_vm.trackByBgColor],
                                              "gray-theme": ![
                                                "label",
                                                "user",
                                              ].includes(_vm.itemTemplate),
                                              icon: item[_vm.trackByIconName],
                                              "fill-width":
                                                _vm.localSelected.length === 1,
                                              "status-color":
                                                item[_vm.trackByStatusColor],
                                              "need-description":
                                                _vm.needDescription,
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1188207558
                                ),
                              })
                            : _vm._e(),
                        ],
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.multiselect
                ? [
                    _vm.singleSelected
                      ? _c("vgpChip", {
                          attrs: {
                            size: _vm.size,
                            preset: _vm.itemTemplate,
                            text: _vm.singleSelected[_vm.trackByText],
                            "img-url": _vm.singleSelected[_vm.trackByImgUrl],
                            "bg-color": _vm.singleSelected[_vm.trackByBgColor],
                            icon: _vm.singleSelected[_vm.trackByIconName],
                            "status-color":
                              _vm.singleSelected[_vm.trackByStatusColor],
                            "txt-ellipsis": true,
                            "fill-width": true,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "reght_selected",
              class: [_vm.$style.d_flex, _vm.$style.ai_center, _vm.$style.ml_8],
            },
            [
              _c("sprite", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowResetBtn,
                    expression: "isShowResetBtn",
                  },
                ],
                class: [_vm.$style.mr_8, "vgp_select_reset_btn"],
                attrs: { name: "close-2", type: "bold", size: 16 },
                on: { click: _vm.onClickResetBtn },
              }),
              _vm._v(" "),
              _c("sprite", {
                class: [_vm.$style.drop_arrow, "vgp_select_drop_arrow"],
                attrs: { name: "drop-down", type: "bold", size: 24 },
                on: { click: _vm.toggleDropDown },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll.self",
                  value: _vm.onDropListScroll,
                  expression: "onDropListScroll",
                  modifiers: { self: true },
                },
              ],
              ref: "drop",
              class: [_vm.$style.drop, "gantt_scroll"],
              style: Object.assign({}, _vm.popupStyles, {
                "max-height": _vm.popupMaxHeight + "px",
              }),
            },
            [
              _c(
                "div",
                [
                  _c("div", { style: { height: "8px" } }),
                  _vm._v(" "),
                  _vm.showItems.length
                    ? [
                        _vm.multiselect && _vm.useSelectAll
                          ? _c(
                              "div",
                              {
                                class:
                                  ((_obj$2 = {}),
                                  (_obj$2[_vm.$style.border_bottom] =
                                    !_vm.dropListInfo.isTop),
                                  (_obj$2[
                                    _vm.$style.select_all_btn_wrap
                                  ] = true),
                                  _obj$2),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.$style.select_all_btn,
                                      _vm.$style.noselect,
                                    ],
                                    on: { click: _vm.onSelectAllBtnClick },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.isAllShowedItemsSelected
                                              ? _vm.locale.unselectAll
                                              : _vm.locale.selectAll
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.groupSet, function (groupName) {
                          return _c(
                            "div",
                            {
                              key: _vm.componentKey + groupName + _vm.dropWidth,
                              style: { width: _vm.dropWidth + "px" },
                            },
                            [
                              groupName
                                ? _c(
                                    "div",
                                    {
                                      class: _vm.$style.group_devider,
                                      style: { color: _vm.deviderColor },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: _vm.$style.group_devider_name,
                                        },
                                        [_vm._v(" " + _vm._s(groupName) + " ")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        class: _vm.$style.group_devider_line,
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.getItemsByGroupName(groupName),
                                function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.dropTemplate + item[_vm.trackBy],
                                      class: [
                                        _vm.$style.drop_item,
                                        "js-drop-item",
                                        _vm.trackByAdditionalForItem
                                          ? _vm.$style.drop_item_with_icon
                                          : null,
                                      ],
                                      attrs: {
                                        title: _vm.useSystemTooltip
                                          ? item[_vm.trackByText]
                                          : "",
                                      },
                                    },
                                    [
                                      _vm.multiselect
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.multiSelected,
                                                expression: "multiSelected",
                                              },
                                            ],
                                            attrs: {
                                              id:
                                                _vm.multiselect +
                                                item[_vm.trackBy] +
                                                "-" +
                                                _vm.componentKey,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              value: item,
                                              checked: Array.isArray(
                                                _vm.multiSelected
                                              )
                                                ? _vm._i(
                                                    _vm.multiSelected,
                                                    item
                                                  ) > -1
                                                : _vm.multiSelected,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.multiSelected,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = item,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.multiSelected =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.multiSelected = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.multiSelected = $$c
                                                }
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.singleSelected,
                                                expression: "singleSelected",
                                              },
                                            ],
                                            attrs: {
                                              id:
                                                _vm.multiselect +
                                                item[_vm.trackBy] +
                                                "-" +
                                                _vm.componentKey,
                                              type: "radio",
                                            },
                                            domProps: {
                                              value: item,
                                              checked: _vm._q(
                                                _vm.singleSelected,
                                                item
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.singleSelected = item
                                              },
                                            },
                                          }),
                                      _vm._v(" "),
                                      _vm.tooltips(item)
                                        ? _c(
                                            "label",
                                            {
                                              directives: [
                                                {
                                                  name: "tippy",
                                                  rawName: "v-tippy",
                                                  value: _vm.tooltips(item),
                                                  expression: "tooltips(item)",
                                                },
                                              ],
                                              style: {
                                                height: _vm.view.itemHeight
                                                  ? _vm.view.itemHeight + "px"
                                                  : "auto",
                                                width: _vm.dropWidth + "px",
                                              },
                                              attrs: {
                                                for:
                                                  _vm.multiselect +
                                                  item[_vm.trackBy] +
                                                  "-" +
                                                  _vm.componentKey,
                                              },
                                            },
                                            [
                                              _c("vgpChip", {
                                                attrs: {
                                                  text: item[_vm.trackByText],
                                                  description: item.description,
                                                  "need-description":
                                                    _vm.needDescription,
                                                  "img-url":
                                                    item[_vm.trackByImgUrl],
                                                  "bg-color":
                                                    item[_vm.trackByBgColor],
                                                  "status-color":
                                                    item[
                                                      _vm.trackByStatusColor
                                                    ],
                                                  preset: _vm.itemTemplate,
                                                  size: _vm.size,
                                                  icon: item[
                                                    _vm.trackByIconName
                                                  ],
                                                  "txt-ellipsis": true,
                                                  selected:
                                                    _vm.singleSelected.id ===
                                                    item.id,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.trackByAdditionalForItem &&
                                              item[_vm.trackByAdditionalForItem]
                                                ? _c("vgpChip", {
                                                    style: {
                                                      marginLeft: "auto",
                                                    },
                                                    attrs: {
                                                      text: item[
                                                        _vm
                                                          .trackByAdditionalForItem
                                                      ].text,
                                                      description:
                                                        item.description,
                                                      "need-description":
                                                        _vm.needDescription,
                                                      "txt-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].txtColor,
                                                      "img-url":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].img - _vm.url,
                                                      "bg-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].bgColor,
                                                      "status-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].statusColor,
                                                      preset:
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].preset,
                                                      round:
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].round,
                                                      "padding-left":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].paddingLeft,
                                                      "padding-right":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].paddingRight,
                                                      "gray-theme":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].grayTheme,
                                                      selected:
                                                        _vm.singleSelected
                                                          .id === item.id,
                                                      size: _vm.size,
                                                      icon: item[
                                                        _vm
                                                          .trackByAdditionalForItem
                                                      ].iconName,
                                                      "txt-ellipsis": true,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "label",
                                            {
                                              style: {
                                                height: _vm.view.itemHeight
                                                  ? _vm.view.itemHeight + "px"
                                                  : "auto",
                                                width: _vm.dropWidth + "px",
                                              },
                                              attrs: {
                                                for:
                                                  _vm.multiselect +
                                                  item[_vm.trackBy] +
                                                  "-" +
                                                  _vm.componentKey,
                                              },
                                            },
                                            [
                                              _c("vgpChip", {
                                                attrs: {
                                                  description: item.description,
                                                  "need-description":
                                                    _vm.needDescription,
                                                  text: item[_vm.trackByText],
                                                  "img-url":
                                                    item[_vm.trackByImgUrl],
                                                  "bg-color":
                                                    item[_vm.trackByBgColor],
                                                  "status-color":
                                                    item[
                                                      _vm.trackByStatusColor
                                                    ],
                                                  preset: _vm.itemTemplate,
                                                  size: _vm.size,
                                                  icon: item[
                                                    _vm.trackByIconName
                                                  ],
                                                  "txt-ellipsis": true,
                                                  selected:
                                                    _vm.singleSelected.id ===
                                                    item.id,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.trackByAdditionalForItem &&
                                              item[_vm.trackByAdditionalForItem]
                                                ? _c("vgpChip", {
                                                    style: {
                                                      marginLeft: "auto",
                                                    },
                                                    attrs: {
                                                      text: item[
                                                        _vm
                                                          .trackByAdditionalForItem
                                                      ].text,
                                                      description:
                                                        item.description,
                                                      "need-description":
                                                        _vm.needDescription,
                                                      "txt-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].txtColor,
                                                      "img-url":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].img - _vm.url,
                                                      "bg-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].bgColor,
                                                      "status-color":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].statusColor,
                                                      preset:
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].preset,
                                                      "gray-theme":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].grayTheme,
                                                      round:
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].round,
                                                      "padding-left":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].paddingLeft,
                                                      "padding-right":
                                                        item[
                                                          _vm
                                                            .trackByAdditionalForItem
                                                        ].paddingRight,
                                                      size: _vm.size,
                                                      icon: item[
                                                        _vm
                                                          .trackByAdditionalForItem
                                                      ].iconName,
                                                      "txt-ellipsis": true,
                                                      selected:
                                                        _vm.singleSelected
                                                          .id === item.id,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            class: _vm.$style.no_search,
                            style: { width: _vm.popupStyles.width },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.noMatchesMessage) + " "),
                            ]),
                          ]
                        ),
                      ],
                  _vm._v(" "),
                  _c("div", { style: { height: "8px" } }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }