<template>
  <div>
    <div
      v-if="isShowResourceCard"
      :class="$.header_overlay"
    />
    <div :class="$.description">
      {{ activeLayout === 'people'
        ? locales('resources_manage_layout_header_txt_1')
        : locales('resources_manage_layout_header_txt_2') }}
    </div>
    <div
      ref="tablePanel"
      :class="$.account_table_panel"
    >
      <div :class="$.panel_left">
        <div
          :class="$.search"
          :style="isShowResourceCard ? {'pointer-events': 'none'} : {}"
        >
          <search-input
            :placeholder="activeLayout === 'people'
              ? locales('people_resources_search_placeholder')
              : locales('material_resources_search_placeholder')"
            :value="searchValue"
            @onInput="onSearch"
          />
        </div>
        <div :class="$.count_label">
          {{ activeLayout === 'people'
            ? locales('common_resources_count', { resourcesCount: tableResources.length })
            : locales('common_material_resources_count', { resourcesCount: tableResources.length }) }}
        </div>
      </div>
      <div :class="$.panel_buttons">
        <vgp-button
          v-if="activeLayout === 'material' && selectedResources.length > 0 && removeResourcesAccess"
          :class="$.delete_btn"
          type="destructive-border"
          :label="locales('resources_delete_btn')"
          :small="true"
          @onClick="onClickDeleteResources"
        />
        <vgp-button
          v-if="createAndInviteResourcesAccess"
          type="primary"
          :disabled="activeLayout === 'material' && selectedResources.length > 0"
          :label="activeLayout === 'people'
            ? locales('resources_layout_header_invite_1')
            : locales('resources_layout_header_invite_2')"
          :icon="{
            name: activeLayout === 'people' ? 'add-user' : 'plus-1',
            size: activeLayout === 'people' ? '24' : '20',
            type: activeLayout === 'people' ? 'regular' : 'bold'
          }"
          icon-side="left"
          :small="true"
          @onClick="addResourceClick"
        />
      </div>
    </div>
    <resources-table
      :headers="tableHeaders"
      :resources="tableResources"
      :roles="accountRoles"
      :resources-type="activeLayout"
      :actions-disabled="isShowResourceCard || isOverSubscription"
      @openProfileCard="openProfileCard"
      @onDeleteResource="onClickDeleteResources"
      @onChangeRole="onChangeAccountRole"
      @onSelectResources="setSelectedResources"
    />
    <resource-card
      v-if="isShowResourceCard"
      :is-show="isShowResourceCard"
      :resource-data="resourceCardData"
      :account-roles="accountRoles"
      :project-roles="projectRoles"
      :me-is-account-owner="meIsAccountOwner"
      :remove-resources-access="removeResourcesAccess"
      :resource-calendar-access="resourceCalendarAccess"
      :pricing-access-to-resource-calendar="pricingAccessToResourceCalendar"
      :pricing-access-to-cost-settings="pricingAccessToCostSettings"
      :actions-disabled="isOverSubscription"
      @openProfileCard="openProfileCard"
      @onClose="onCloseResourceCard"
      @onChangeAccountRole="onChangeAccountRole"
      @onChangeCost="onChangeCost"
      @onChangeRight="onChangeRight"
      @onChangeCostType="onChangeCostType"
      @onDeleteResource="onClickDeleteResources"
      @onDeleteFromProject="onClickDeleteFromProject"
      @updateResource="updateResource"
      @onChangeProjectRole="onChangeProjectRole"
      @showAvatarUploader="isShowAvatarUploader = true"
      @assignToProjects="data => $emit('assignToProjects', data)"
    />
    <invite-to-account-popup
      v-if="isShowInvitePopup"
      :is-show="isShowInvitePopup"
      :projects="projects"
      :account-roles="accountRoles.filter(role => role.defaultType !== 'OWNER')"
      :access-to-change-account-role="accountRoleChangeAccess"
      @onClose="isShowInvitePopup = false"
      @onSendInvites="onSendInvites"
      @upgradePlanShowFromResourcesForce="upgradePlanShowFromResourcesForce"
    />
    <create-resource-popup
      v-if="isShowCreateResourcePopup"
      :is-show="isShowCreateResourcePopup"
      :projects="projects"
      @onClose="isShowCreateResourcePopup = false; isExistResourceName = false"
      @onCreate="onCreateResource"
    />
    <avatar-uploder-popup
      :show="isShowAvatarUploader"
      :title="locales('update_profile_photo_title')"
      :type="'avatar'"
      @onClose="isShowAvatarUploader = false"
      @onApply="onChangeUserAvatar"
    />
    <div
      v-if="!tableResources.length"
      :class="$.empty_area"
      :style="{ height: stubHeight }"
    >
      <div
        v-if="searchValue !== ''"
        :class="$.wrap"
        v-html="stubSearch"
      />
      <div
        v-else
        :class="$.wrap"
        v-html="stub"
      />
      <div :class="$.desc">
        {{ searchValue !== '' ? searchMessage[activeLayout] : locales("resources_no_resources_description") }}
      </div>
    </div>
  </div>
</template>

<script>
import tableHeaders from './resourcesTable/tableHeaders';
import stubSearch from '../../../svg/search_no_result_pic.svg';
import stub from '../../../svg/resources/empty_resources.svg';
import resourcesTable from './resourcesTable/resourcesTable.vue';
import resourceCard from './resourceCard/resourceCard.vue';
import inviteToAccountPopup from './popups/inviteToAccountPopup.vue';
import createResourcePopup from './popups/createResourcePopup.vue';
import searchInput from './inputSearch/inputSearch.vue';
import vgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';
import avatarUploderPopup from '../../imageUploader/imageUploader.vue';

export default {
  components: {
    resourcesTable,
    resourceCard,
    inviteToAccountPopup,
    createResourcePopup,
    searchInput,
    vgpButton,
    avatarUploderPopup,
  },
  provide() {
    return {
      checkExistResourceName: this.checkExistResourceName,
    };
  },
  inject: ['upgradePlanShowFromResourcesHandler', 'checkAccessToInviteSilent', 'handleInviteFromResources'],
  props: {
    resources: { type: Object, required: true, default: { people: [], material: [] } },
    accountRoles: { type: Array, required: true, default: () => [] },
    projectRoles: { type: Array, required: true, default: () => [] },
    projects: { type: Array, required: true, default: () => [] },

    meIsAccountOwner: { type: Boolean, required: true, default: false },
    createAndInviteResourcesAccess: { type: Boolean, required: false, default: false },
    accountRoleChangeAccess: { type: Boolean, required: false, default: false },
    removeResourcesAccess: { type: Boolean, required: false, default: false },
    resourceCalendarAccess: { type: Boolean, required: false, default: false },
    pricingAccessToResourceCalendar: { type: Boolean, required: false, default: false },
    pricingAccessToCostSettings: { type: Boolean, required: false, default: false },
    isOverSubscription: { type: Boolean, required: false, default: false },
  },
  emits: {
    onSendInvites: Object,
    onCreateResource: Object,
    removeResourcesFromAccount: Array,
    removeResourcesFromProject: Array,
    onChangeCostType: Object,
    onChangeCost: Object,
    showRemovePopup: Object,
    onChangeOwner: Object,
    upgradePlanShowFromResourcesForce: null,
    onChangeAccountRole: Object,
    onChangeUserAvatar: Object,
    showConvertPopup: Object,
    assignToProjects: Array,
  },
  data() {
    return {
      locales: __,
      searchValue: '',
      resourceCardId: null,
      selectedResources: [],
      isShowResourceCard: false,
      isShowInvitePopup: false,
      isShowCreateResourcePopup: false,
      isShowAvatarUploader: false,
      stubHeight: null,
      stubSearch,
      stub,
      searchMessage: {
        people: __('resources_no_search_result'),
        material: __('material_resources_no_search_result'),
      },
      scrolled: null,
    };
  },
  computed: {
    activeLayout() {
      return this.$route.params.activeView;
    },
    tableHeaders() {
      let peopleTable = tableHeaders.people;
      let materialTable = tableHeaders.material;

      peopleTable = peopleTable.filter(obj => !['type', 'cost', 'changeOwner'].includes(obj.value));
      materialTable = materialTable.filter(obj => !['type', 'cost'].includes(obj.value));
      peopleTable.forEach(obj => {
        if (obj.value === 'roleTitle') {
          obj.text = __('resources_table_head_role');
          obj.sortable = true;
        }
      });

      const headers = {
        people: peopleTable,
        material: materialTable,
      };

      return headers[this.activeLayout];
    },
    tableResources() {
      return this.resources[this.activeLayout].filter(resource => {
        const email = resource.email || '';

        return resource?.name.toLowerCase().startsWith(this.searchValue.toLowerCase())
        || email.toLowerCase().startsWith(this.searchValue.toLowerCase());
      });
    },
    resourceCardData() {
      const resourceData = this.tableResources.find(resource => resource.id === this.resourceCardId);

      if (!resourceData) {
        return this.tableResources[0];
      }

      return resourceData;
    },
  },
  watch: {
    tableResources(newResources) {
      const hasResourceForCard = newResources.find(resource => resource.id === this.resourceCardId);

      if (!hasResourceForCard) {
        this.isShowResourceCard = false;
      }
    },
    activeLayout() {
      this.searchValue = '';
    },
  },
  mounted() {
    this.$nextTick(() => this.calcStubHeight());
  },
  methods: {
    onSearch(value) {
      this.searchValue = value;
    },
    setSelectedResources(selected) {
      this.selectedResources = selected;
    },
    calcStubHeight() {
      this.stubHeight = `calc(100vh - ${this.$refs.tablePanel.getBoundingClientRect().bottom + 78}px)`;
    },
    checkExistResourceName(name) {
      const str = name.toLowerCase();
      const exist = this.tableResources.find(resource => resource.name.toLowerCase().startsWith(str));

      return exist && exist.name.length === name.length;
    },
    openProfileCard(id) {
      const el = this.$el;
      const resourcesTable = el.querySelector('.v-data-table.resources-table');
      const scrollTop = resourcesTable.scrollTop;

      this.scrolled = scrollTop;
      this.resourceCardId = id;
      this.isShowResourceCard = true;
      this.$nextTick(() => {
        resourcesTable.scrollTo(0, scrollTop);
      });
    },
    addResourceClick() {
      if (this.activeLayout === 'people') {
        if (this.checkAccessToInviteSilent() === 'isOverSubscription') {
          this.$toast.warning(__('error_cannot_add_user'));

          return;
        }

        if (this.handleInviteFromResources()) {
          this.isShowInvitePopup = true;
        }
      }

      if (this.activeLayout === 'material') {
        this.isShowCreateResourcePopup = true;
      }
    },
    onSendInvites(data) {
      this.$emit('onSendInvites', data);
      this.searchValue = '';
      this.isShowInvitePopup = false;
      this.$toast.info(__('common_info_resource_ivited'));
    },
    onCreateResource(data) {
      this.$emit('onCreateResource', data);
      this.searchValue = '';
      this.isShowCreateResourcePopup = false;
      this.$toast.info(__('common_info_resource_created'));
    },
    onClickDeleteResources(resourceData) {
      const resources = resourceData ? [resourceData] : this.selectedResources;
      const hasProjectsWhereOwner = resources.some(resource => resource.resourceProjects.some(pr => pr.isOwnerOnProject));

      if (hasProjectsWhereOwner) {
        this.$emit('onChangeOwner', {
          type: 'resource',
          resource_id: resourceData.id,
        });

        return;
      }

      this.$emit('showRemovePopup', {
        type: 'account',
        resources,
      });
    },
    onClickDeleteFromProject(data) {
      if (data.project.isOwnerOnProject) {
        this.$emit('onChangeOwner', {
          type: 'project',
          resource_id: data.resource.id,
          gantt_id: data.project.projectId,
        });

        return;
      }

      this.$emit('showRemovePopup', {
        type: 'project',
        resources: [data.resource],
        projectId: data.project.projectId,
      });
    },
    onChangeCostType(data) {
      this.$emit('onChangeCostType', data);
    },
    onChangeCost(data) {
      this.$emit('onChangeCost', data);
    },
    onChangeAccountRole(data) {
      this.$emit('onChangeAccountRole', data);
    },
    onChangeProjectRole(data) {
      this.$emit('onChangeProjectRole', data);
    },
    updateResource(data) {
      this.$emit('updateResource', data);
    },
    upgradePlanShowFromResourcesForce() {
      debugger;
      this.isShowInvitePopup = false;
      this.$emit('upgradePlanShowFromResourcesForce');
    },
    onChangeUserAvatar(file) {
      const data = {
        file,
        userId: this.resourceCardData.userId,
        resourceId: this.resourceCardData.id,
      };

      this.$emit('onChangeUserAvatar', data);
      this.isShowAvatarUploader = false;
    },
    onCloseResourceCard() {
      const resourcesTable = document.documentElement.querySelector('.v-data-table.resources-table');

      this.isShowResourceCard = false;
      this.$nextTick(() => {
        resourcesTable.scrollTo(0, this.scrolled);
      });
    },
  },
};
</script>

<style module="$" src="../less/resources.less" />
