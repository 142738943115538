import { api, internalApi } from '$$store/api';

const outlookIntegration = {
  namespaced: true,
  state: {
    outlookLinks: [],
    firstLink: false,
  },
  getters: {
    outlookLinks: state => state.outlookLinks,
    isFirstLink: state => state.firstLink,
  },
  mutations: {
    setLinks(state, data) {
      state.outlookLinks = data;
    },
    addNewLink(state, payload) {
      state.outlookLinks.push(payload);
    },
    updateLink(state, payload) {
      state.outlookLinks.forEach(link => {
        if (link.id === payload.id) {
          link.projects = payload.data.projects;
          link.connectionType = payload.data.connectionType;
        }
      });
    },
    removeLink(state, id) {
      state.outlookLinks = state.outlookLinks.filter(link => link.id !== id);
    },
    setFirstLink(state, data) {
      state.firstLink = data;
    },

    updateLinkName(state, payload) {
      state?.outlookLinks.forEach(link => {
        link.projects.forEach(project => {
          if (project.ganttId === payload.id) {
            project.name = payload.name;
          }
        });
      });
    },

  },
  actions: {
    async loadData({ commit }) {
      const data = await internalApi.get('/integrations/outlook/calendar');

      commit('setLinks', data.data);
    },
    async createLink({ commit }, payload) {
      try {
        const { data } = await internalApi.post('/integrations/outlook/calendar',
          { projectIds: [payload.data.projects[0].ganttId], connectionType: payload.data.connectionType });

        commit('addNewLink', data);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateLink({ commit, getters }, payload) {
      try {
        const { data } = await internalApi.put(`/integrations/outlook/calendar/${payload.id}`,
          { projectIds: [payload.data.projects[0].ganttId], connectionType: payload.data.connectionType });

        const link = getters.outlookLinks.find(item => item.id === payload.id);

        if (link) {
          userExtAnalytics.log('integration_outlook_calendar_updated', { fromType: link.connectionType, toType: payload?.data.connectionType });
        }

        commit('updateLink', payload);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteLink({ commit }, id) {
      return internalApi.delete(`/integrations/outlook/calendar/${id}`).then(() => {
        commit('removeLink', id);
      });
    },

    async removeAfterCollabaration({ commit, state }, payload) {
      const data = [];
      const promises = [];

      state?.outlookLinks.forEach(connection => {
        connection.projects.forEach(project => {
          if (project.ganttId === payload.id) {
            data.push(connection.id);
          }
        });
      });

      if (data.length) {
        for (const id of data) {
          promises.push(internalApi.delete(`/integrations/outlook/calendar/${id}`).then(() => {
            commit('removeLink', id);
          }));
        }
        await Promise.all(promises);
      }
    },

  },
};

export default outlookIntegration;
