var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.vgp_input_switch,
        _vm.isError ? _vm.$style.error : null,
        _vm.isFocus && _vm.isDisabledInput ? _vm.$style.focus : null,
        _vm.isDisabled ? _vm.$style.disabled : null,
      ],
      on: {
        click: function ($event) {
          return _vm.focusElement(true)
        },
      },
    },
    [
      _vm.togglePosition === "left"
        ? _c("square-toggle", {
            attrs: { "is-toggle-on": _vm.isDisabledInput },
            on: { onChange: _vm.activateElement },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vgp-input", {
        ref: "input",
        class: [
          _vm.togglePosition === "left"
            ? _vm.$style.vgp_input_switch_left
            : _vm.$style.vgp_input_switch_right,
        ],
        attrs: {
          small: _vm.isSmall,
          "input-type": _vm.type,
          "is-disabled": !_vm.isDisabledInput,
          placeholder: _vm.placeholder,
          "icons-right":
            _vm.icons.length && _vm.togglePosition === "left" ? _vm.icons : [],
          "icons-left":
            _vm.icons.length && _vm.togglePosition === "right" ? _vm.icons : [],
          border: false,
        },
        model: {
          value: _vm.data,
          callback: function ($$v) {
            _vm.data = $$v
          },
          expression: "data",
        },
      }),
      _vm._v(" "),
      _vm.togglePosition === "right"
        ? _c("square-toggle", {
            attrs: { "is-toggle-on": _vm.isDisabledInput },
            on: { onChange: _vm.activateElement },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }