/* eslint-disable no-undef, no-param-reassign, no-unused-vars */

import _ from '../../../libs/lodash';
import projectsModel from '../../../models/projects';
import customDayTemplatesModel from '../../../models/customDayTemplates';
import globalStore from '../../../store/main';

let _worker = null;
let _isWorkerInit = false;

function _createGanttDateWorker(workerPath) {
  const worker = new Worker(workerPath);

  return workerData => new Promise((resolve, reject) => {
    worker.onmessage = e => {
      worker.onmessage = null;
      worker.onerror = null;

      return resolve(e.data);
    };

    worker.onerror = e => {
      worker.onmessage = null;
      worker.onerror = null;

      return reject(e);
    };

    worker.postMessage(workerData);
  });
}

function _init() {
  if (_isWorkerInit) return;
  let version = Date.now();

  if (GT.productVersion && GT.productVersion.buildVersion) {
    version = GT.productVersion.buildVersion;
  }

  _worker = _createGanttDateWorker(`/workers/gantt_date_worker.js?${version}`);
  _isWorkerInit = true;
}

const _innerHelpers = {
  async calculateDuration(ganttId, items) {
    if (!ganttId || !items?.length) {
      return Promise.resolve({});
    }

    const totalData = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](ganttId);
    const projectCustomDays = customDayTemplatesModel.getTemplateDataByGanttIdAndActive(ganttId);
    const calendar = gantt.getCalendar(ganttId);
    const projectConfig = projectsModel.getProjectConfig(ganttId);

    const initialConfig = {
      calendar: {
        id: ganttId,
        worktime: {
          hours: calendar.getConfig().hours,
          days: _.values(calendar.getConfig().dates),
        },
      },
      ganttConfig: {
        auto_scheduling: projectConfig.auto_scheduling,
        estimation_mode: projectConfig.estimation_mode,
        apply_resource_calendar: projectConfig.apply_resource_calendar,
        progress: projectConfig.progress,
        start_on_monday: projectConfig.start_on_monday,
      },
      customDays: projectCustomDays?.customDays || [],
      totalDates: {
        start_date: totalData.start_date,
        end_date: totalData.end_date,
      },
    };

    const { duration } = await _worker({ ganttId, initialConfig, items });

    return Promise.resolve(duration);
  },
};

const outputObject = {
  init: _init,
  calculateDuration: _innerHelpers.calculateDuration,
};

export default outputObject;
