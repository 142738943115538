<template>
  <div :class="$style.outer__wrapper">
    <div :class="$style.inner_wrapper">
      <span>
        {{ locales('onboarding_preparing_project_progress') }}
      </span>
      <v-progress-linear
        indeterminate
        background-color="#E5E5E5"
        color="#337870"
        rounded
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectPreparationLoader',
  data() {
    return {
      locales: __,
    };
  },
};
</script>

<style lang="less" module>

.outer__wrapper {
  width: 100%;
}

.inner_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
  span {
    font-family: Lato-Regular, sans-serif;
    font-size: 29px;
    line-height: 35px;
    color: #000000;
  }

  div[role='progressbar'] {
    width: 160px;
  }
}

</style>
