// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inactive_m6yBHOx_ .connection_project_title_msteams,\n.inactive_m6yBHOx_ .channel_setting_container > div:not(.buttons) {\n  opacity: 0.5;\n}\n.tip_NINXUMqL svg path {\n  fill: #FF9A00;\n}\n.tip_NINXUMqL svg:hover path {\n  fill: #FF9A00 !important;\n}\n.tooltip_u1ceheVl {\n  display: flex;\n  align-items: center;\n  width: 36px;\n}\n", "",{"version":3,"sources":["webpack://./public/views/integration/msteams/components/integrationMSTeamsCommonStyles.less","webpack://./ConnectionEditItem.vue","webpack://./public/views/integration/msteams/components/ConnectionEditItem.vue"],"names":[],"mappings":"AAAA;;EAGM,YAAA;ACAN;ADGE;EAEM,aAAA;ACFR;ADAE;EAMM,wBAAA;ACHR;ACkKA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;ADhKF","sourcesContent":[".inactive {\n    :global(.connection_project_title_msteams),\n    :global(.channel_setting_container) > div:not(:global(.buttons)) {\n      opacity: 0.5;\n    }\n  }\n  .tip {\n    svg  path{\n        fill: #FF9A00\n    }\n\n    svg:hover path {\n        fill: #FF9A00 !important\n    }\n  }",".inactive :global(.connection_project_title_msteams),\n.inactive :global(.channel_setting_container) > div:not(:global(.buttons)) {\n  opacity: 0.5;\n}\n.tip svg path {\n  fill: #FF9A00;\n}\n.tip svg:hover path {\n  fill: #FF9A00 !important;\n}\n.tooltip {\n  display: flex;\n  align-items: center;\n  width: 36px;\n}\n","\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n@import \"./integrationMSTeamsCommonStyles.less\";\n\n.tooltip {\n  display: flex;\n  align-items: center;\n  width: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inactive": "inactive_m6yBHOx_",
	"tip": "tip_NINXUMqL",
	"tooltip": "tooltip_u1ceheVl"
};
export default ___CSS_LOADER_EXPORT___;
