<template>
  <div :class="[$style.cell_tooltip_wrap]">
    <div
      ref="cell"
      :class="[$style.cell_tooltip_trigger]"
      @mouseenter="onMouseenter"
      @mouseleave="onMouseleve"
    >
      <slot />
    </div>

    <div
      v-show="canShow && isShow && items && items.length"
      :class="[$style.cell_tooltip]"
      :style="{ width: width + 'px',
                bottom: coords.bottom,
                left: coords.left,
                right: coords.right
      }"
    >
      <div
        :class="$style.cell_tooltip_pointer"
        :style="{ left: triggerCoords.left + 15 + 'px',
                  top: triggerCoords.top - 7 + 'px' }"
      />
      <div
        v-for="group in groupSet"
        :key="componentKey + group"
      >
        <div
          v-if="group"
          :class="$style.select_group"
        >
          <span> {{ group }} </span>
          <div :class="$style.select_group_line" />
        </div>

        <div
          v-for="(item, i_i) in getGroupItems(group)"
          :key="componentKey + '-' + index + i_i"
          :class="$style.tooltip_item"
        >
          <template v-if="template === 'text'">
            <sapn :class="$style.text_wrap">
              {{ item[valueField] }}
            </sapn>
          </template>
          <template v-if="template === 'label'">
            <div
              :style="{'background-color': item.color}"
              :class="[$style.square]"
            />
            <sapn :class="$style.text_wrap">
              {{ item[valueField] }}
            </sapn>
          </template>
          <template v-if="template === 'icon+text'">
            <div
              :style="{'background-image': `url(${item[iconField]})`}"
              :class="[$style.icon_wrap]"
            />
            <sapn :class="$style.text_wrap">
              {{ item[valueField] }}
            </sapn>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleCell',
  props: {
    items: { type: Array, required: false },
    groupBy: { type: String, required: false, default: 'group' },
    iconField: { type: String, required: false, default: 'icon' },
    valueField: { type: String, required: false, default: 'value' },
    template: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return ['text', 'label', 'icon+text'].indexOf(value) !== -1;
      },
    },
    width: { type: Number, required: false, default: 221 },
    canShow: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      coords: {},
      triggerCoords: {},
      isShow: false,
      cursorOnElem: false,
    };
  },

  computed: {
    groupSet() {
      if (!this.items && !this.items.length) return [];

      const set = [];

      this.items.forEach(item => {
        const groupName = this.groupBy in item ? item[this.groupBy] : null;

        if (!set.includes(groupName)) {
          set.push(groupName);
        }
      });

      return set;
    },
    componentKey() {
      const min = 1000;
      const max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  mounted() {
  },
  methods: {
    calcPosition() {
      if (!this.$refs.cell) {
        return;
      }

      this.triggerCoords = this.$refs.cell.getBoundingClientRect();
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const isByRight = this.triggerCoords.left + this.width + 0 > clientWidth;

      this.coords = {
        left: isByRight ? 'auto' : `${this.triggerCoords.left}px`,
        right: isByRight ? '0px' : 'auto',
        bottom: `${clientHeight - this.triggerCoords.top + 2}px`,
      };
    },
    getGroupItems(groupName) {
      if (groupName === null) {
        return this.items.filter(item => !(this.groupBy in item));
      }

      return this.items.filter(item => item[this.groupBy] === groupName);
    },
    onMouseenter() {
      this.cursorOnElem = true;

      setTimeout(() => {
        if (!this.cursorOnElem) return;

        this.calcPosition();
        this.isShow = true;
      }, 1000);
    },
    onMouseleve() {
      this.cursorOnElem = false;
      this.isShow = false;
    },
  },
};
</script>

<style module src="./cell.less" lang="less" />
