var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["task-settings-header"] },
    [
      _c(
        "div",
        { class: _vm.$style["header__row"] },
        [
          _c("tooltip", {
            class: [_vm.$style["breadcrumbs-tooltip"]],
            attrs: {
              content: _vm.fullTaskBreadcrumbs,
              position: "bottom-center",
              hide: _vm.isBreadcrumbsFitInDiv,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c("div", { class: _vm.$style["header__breadcrumbs"] }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.shortenedBreadcrumbsIfDoesntFit) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style["header__actions"] },
            [
              _vm.contextMenuItems.length
                ? _c("vgp-icon-button", {
                    ref: "contextMenuBtn",
                    attrs: { icon: { name: "more-1" } },
                    on: { onClick: _vm.showContextMenu },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("vgp-icon-button", {
                attrs: { icon: { name: "link-3" } },
                on: { onClick: _vm.copyTaskToClipboard },
              }),
              _vm._v(" "),
              !_vm.commentHub
                ? _c("vgp-icon-button", {
                    attrs: { icon: { name: "close-1" } },
                    on: {
                      onClick: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style["header__row"] },
        [
          _c("color-palette", {
            style: { zIndex: 3 },
            attrs: {
              disabled: _vm.isFieldDisabled("color"),
              "select-type": "single",
              "select-field": "square",
              "selected-values": [_vm.taskData.color],
              colors: _vm.colors,
              "select-width": 72,
              "select-height": 32,
              small: "",
            },
            on: {
              selectColors: function ($event) {
                return _vm.handleChangeData("color", $event[0].id)
              },
            },
          }),
          _vm._v(" "),
          _vm.isFieldDisabled("text")
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: {
                        content: _vm.taskData.text,
                        interactive: true,
                        placement: "bottom-center",
                        onShow: function () {
                          return !_vm.isTaskNameFitInDiv
                        },
                      },
                      expression:
                        "{\n        content: taskData.text,\n        interactive: true,\n        placement: 'bottom-center',\n        onShow: () => !isTaskNameFitInDiv\n      }",
                    },
                  ],
                  class: [
                    _vm.$style["task-name-input"],
                    _vm.isFieldDisabled("text")
                      ? _vm.$style["task-name-input_disabled"]
                      : "",
                  ],
                },
                [_vm._v("\n      " + _vm._s(_vm.taskData.text) + "\n    ")]
              )
            : _c("vgp-input", {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: {
                      content: _vm.taskData.text,
                      placement: "bottom-center",
                      onShow: function () {
                        return !_vm.isTaskNameFitInDiv
                      },
                    },
                    expression:
                      "{ content: taskData.text, placement: 'bottom-center', onShow: () => !isTaskNameFitInDiv }",
                  },
                ],
                key: _vm.taskNameInputKey,
                class: _vm.$style["task-name-input"],
                attrs: {
                  value: _vm.taskData.text,
                  border: false,
                  maxlength: 254,
                },
                on: { onChange: _vm.handleChangeTaskName },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style["header__row"] }, [
        _c("div", { class: _vm.$style["task-creation-info"] }, [
          _c("span", [
            _vm._v(_vm._s(_vm.locales("task_settings_creator") + ":")),
          ]),
          _vm._v(" "),
          _c("span", { class: _vm.$style["task-creation-info_author"] }, [
            _vm._v(_vm._s(_vm.taskAuthorName + ",")),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.taskCreationDate))]),
        ]),
      ]),
      _vm._v(" "),
      _vm.positionContext && _vm.contextMenuItems.length
        ? _c("context-menu", {
            attrs: {
              items: _vm.contextMenuItems,
              position: _vm.positionContext,
            },
            on: {
              selectItemMenu: _vm.handleSelectContextItem,
              closePopup: function ($event) {
                _vm.positionContext = null
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("core-confirm-popup", {
        attrs: {
          show: _vm.confirmDeletionData.showPopup,
          text: _vm.confirmDeletionData.text,
          "ok-button": {
            type: "destructive",
            title: _vm.locales("common_yes"),
          },
          "cancel-button": {
            type: "secondary",
            title: _vm.locales("common_cancel"),
          },
          "popup-width": 440,
        },
        on: {
          onClickOk: _vm.handleConfirmDeleteTask,
          onClickCancel: _vm.handleCancelDeleteTask,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }