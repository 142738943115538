import app, { appIds } from '../../app';
import _ from '../../libs/lodash';
import icon_close from '../../svg/ic_close.svg';
import icon_settings from '../../svg/header_buttons/ic_project_settings_small.svg';
import emptySlackConnections from '../../svg/integration/settings/empty_slack_connections.svg';
import projectsModel from '../../models/projects';
import routerHelper from '../../helpers/router';
import integrationsModel from '../../models/integrations/integrations';
import globalStore from '../../store/main';
import rights from '../../components/rights';
import integrationSlackModel from '../../models/integrations/integrationsSlack';
import { integrationMSTeamsModel } from '../../models/integrations/integrationsMSteams';

const { __ } = window;

const {
  popupViews: {
    ID_VIEW_POPUP_INTEGRATION_SLACK_SETTINGS,
    ID_VIEW_POPUP_INTEGRATION_MSTEAMS_SETTINGS,
  },
} = appIds;

webix.protoUI({
  name: 'ltext',
  defaults: { attributes: {} },
  $cssName: 'text',
  maxLength_setter(value) {
    this.config.attributes.maxLength = value;
  },
}, webix.ui.text);

if (!webix.env.touch && webix.env.scrollSize) webix.CustomScroll.init();

const innerSettings = [{
  type: 'slack',
  routeName: 'integrationSlack',
  isOpened: {},
  _listChannelsId: 'channelsListId',
  _bodyChannelsId: 'bodyChannelsId',
  _listOfConnectionsId: 'integrationSlackSettingsMainScrollView',
  _emptyConnectionsListId: 'emptyConnectionsListId',
  _createButtonId: 'createButtonId',
  popupId: ID_VIEW_POPUP_INTEGRATION_SLACK_SETTINGS,
  getChannelsView(projectChannelConnections) {
    const rows = [];

    projectChannelConnections.forEach((projectChannelConnection, index) => {
      rows.push(
        this.getChannelsTemplate(projectChannelConnection, projectChannelConnections.length - 1 === index),
      );
    });

    return rows;
  },
  integrationModel: integrationSlackModel,
  emptyConnections: emptySlackConnections,
  setShowAction: 'headerView/setShowSlack',
  tooglePopupAction: 'headerView/toggleSlackPopup',
},
{
  type: 'msteams',
  routeName: 'integrationMsteams',
  isOpened: {},
  _listChannelsId: 'channelsListId_msteams',
  _bodyChannelsId: 'bodyChannelsId_msteams',
  _listOfConnectionsId: 'integrationMSTeamsSettingsMainScrollView',
  _emptyConnectionsListId: 'emptyConnectionsListId_msteams',
  _createButtonId: 'createButtonId_msteams',
  popupId: ID_VIEW_POPUP_INTEGRATION_MSTEAMS_SETTINGS,
  getChannelsView(projectChannelConnections) {
    const teamTree = projectChannelConnections.reduce((acc, item) => {
      const { msteams_team_id, msteams_team_name } = item;

      if (!acc[msteams_team_id]) {
        acc[msteams_team_id] = { msteams_team_id, msteams_team_name, channels: [] };
      }

      acc[msteams_team_id].channels.push(item);

      return acc;
    }, {});

    return Object.values(teamTree).map(team => {
      const channelsLenght = team.channels.length;
      const channelsRows = team.channels.map(
        (channel, index) => this.getChannelsTemplate(channel, channelsLenght - 1 === index),
      );

      return { header: team.msteams_team_name, body: { rows: channelsRows } };
    });
  },
  integrationModel: integrationMSTeamsModel,
  emptyConnections: emptySlackConnections,
  setShowAction: 'headerView/setShowMSTeams',
  tooglePopupAction: 'headerView/toggleMSTeamsPopup',
},
];

export const [
  integrationSettingsObjectSlack,
  integrationSettingsObjectMsteams,
] = innerSettings.map(settings => {
  const {
    popupId,
    _listChannelsId,
    _bodyChannelsId,
    _listOfConnectionsId,
    _emptyConnectionsListId,
    _createButtonId,
    type,
    routeName,
    integrationModel,
    getChannelsView,
    emptyConnections,
    setShowAction,
    tooglePopupAction,
  } = settings;
  const innerViews = {
    main: { id: popupId },
    getChannelsView,
    getChannelsTemplate({
      id, channel_name,
      integration_team_id,
      setting_assignee,
      setting_attachment,
      setting_comment,
      setting_new_start_date,
      setting_new_task,
      setting_status,
    }, isLastElement) {
      const settingsValues = {
        setting_assignee,
        setting_attachment,
        setting_comment,
        setting_new_start_date,
        setting_new_task,
        setting_status,
      };
      const channelsTemplate = {
        rows: [
          {
            cols: [
              { width: 18 },
              {
                css: 'channels_container',
                rows: [
                  {
                    view: 'label',
                    css: 'channel_name',
                    label: `#${channel_name}`,
                  },
                  { height: 10 },
                  {
                    css: 'channel_settings',
                    rows:
                      ['new_task',
                        'status',
                        'comment',
                        'attachment',
                        'assignee',
                        'new_start_date'].map(settingId => ({
                        view: 'checkbox',
                        id: `setting_${settingId}_${id}_${type}`,
                        height: 30,
                        labelRight: __(`integration_settings_page_settings_${settingId}`),
                        labelWidth: 0,
                        value: settingsValues[`setting_${settingId}`],
                        on: {
                          onChange(newv, oldv) {
                            innerHandlers.saveSetting(id, `setting_${settingId}`, newv);
                          },
                        },
                      })),
                  },
                ],
              },
            ],
          },
        ],
      };

      if (!isLastElement) {
        channelsTemplate.rows.push({ height: 1, css: 'channel_separator' });
      }

      return channelsTemplate;
    },
  };

  const innerHandlers = {
  // @private
    hidePopup: () => {
      const popupID = innerViews.main.id;

      if (settings.isOpened[popupID]) {
        innerHandlers.togglePopup(popupID);
      }
    },
    // @private
    showPopup() {
      const popupID = innerViews.main.id;

      innerHandlers.togglePopup(popupID);
    },

    togglePopup() {
      const popupID = innerViews.main.id;

      if (settings.isOpened[popupID]) {
        $$(innerViews.main.id).$view.style.transform = 'translateX(0)';
      } else {
        $$(popupID).show();
        _.delay(() => {
          $$(innerViews.main.id).$view.style.transform = 'translateX(-100%)';
        });
      }

      settings.isOpened[popupID] = !settings.isOpened[popupID];
      globalStore.commit(tooglePopupAction, settings.isOpened[popupID]);

      return settings.isOpened[popupID];
    },
    initList() {
      if ($$(_listChannelsId)) {
        $$(_bodyChannelsId).removeView(_listChannelsId);
      }
      const ganttId = projectsModel.getActiveGanttId();
      let data = integrationModel.getDataFromCollection();

      data = data.filter(el => el.gantt_id === ganttId);
      if (data && data.length) {
        $$(_listOfConnectionsId).show();
        $$(_emptyConnectionsListId).hide();
        $$(_createButtonId).hide();
        const _view = innerViews.getChannelsView(data);

        $$(_bodyChannelsId).addView({ id: _listChannelsId, multi: true, rows: _view });
      } else {
        $$(_emptyConnectionsListId).show();
        $$(_createButtonId).show();
        $$(_listOfConnectionsId).hide();
      }
    },
    saveSetting(id, settingType, value) {
      integrationModel.saveSetting({ id, settingType, value });
    },
    async checkAccessForActiveProject() {
      integrationsModel.getDataFromBackend().then(integrations => {
        if (rights.account.hasRight('integration_settings') && integrations.find(el => {
          if (
            el.type === type
            && el.settings
            && el.settings.active
            && el.users
            && el.users.length
            && !el.needReconnect
            && el.users.find(el2 => +el2.user_id === +GT.user.id)
          ) {
            return true;
          }

          return false;
        })) {
          globalStore.commit(setShowAction, true);
        } else {
          globalStore.commit(setShowAction, false);
        }
      });
    },
  };

  const innerObject = {
    init: {},
    views: {},
    settings: { isOpened: false },
    defaultSettings: {},
  };

  webix.ui({
    view: 'window',
    width: 300,
    id: popupId,
    css: 'integration_settings_popup clear-window-popup settings_popup-view',
    modal: false,
    hidden: true,
    zIndex: 100,
    borderless: true,
    move: false,
    // unClosable: true,
    isShown() {
      return innerObject.settings.isOpened;
    },
    head: {
      css: 'integration_settings_popup_header',
      height: 47,
      paddingY: 8,
      cols: [
        { width: 18 },
        {
          view: 'label',
          label: __(`intagration_${type}_popup_title`),
          width: 150,
        },
        { autowidth: true },
        {
          view: 'icon',
          width: 30,
          height: 30,
          css: 'close_button',
          template: `<div class='popup_settings_header_icon'>${icon_settings}</div>`,
          on: {
            onItemClick() {
              routerHelper.pushByNameAndParams(routeName);
            },
          },
        },
        { width: 8 },
        {
          view: 'icon',
          width: 30,
          height: 30,
          css: 'close_button',
          template: `<div class='popup_header_icon'>${icon_close}</div>`,
          on: {
            onItemClick() {
              innerHandlers.hidePopup(true);
              app.trigger('helpPopup:close');
              app.trigger('popup:hide');
            },
          },
        },
        { width: 8 },
      ],
    },
    body: {
      id: `integration_${type}_settings_popup-body`,
      css: 'integration_settings_popup-body',
      rows: [
        {
          view: 'scrollview',
          id: _listOfConnectionsId,
          // css: 'integrationSlackSettingsMainlView',
          body: {
            id: _bodyChannelsId,
            rows: [],
          },
          on: {
            onAfterScroll() {
              webix.callEvent('onClick', []);
            },
          },
        },
        {
          css: 'empty_connections_list',
          id: _emptyConnectionsListId,
          autoheight: true,
          rows: [
            {},
            {
              view: 'template',
              css: 'icon',
              borderless: true,
              height: 78,
              width: 119,
              template: emptyConnections,
            },
            { height: 18 },
            {
              width: 240,
              borderless: true,
              height: 160,
              css: 'title',
              view: 'template',
              template: __(`integration_${type}_popup_empty_add_title`),
            },
            {},
          ],
        },
        {
          css: 'button_done_container',
          id: _createButtonId,
          rows: [
            { height: 12 },
            {
              view: 'button',
              value: __(`integration_${type}_popup_button_create`),
              css: 'button_done',
              width: 275,
              height: 36,
              on: {
                onItemClick() {
                  routerHelper.pushByNameAndParams(routeName);
                },
              },
            },
            { height: 12 },
          ],
        },
      ],
    },
    position(state) {
      const headerHeight = (globalStore.getters['headerView/UIConfig'] && globalStore.getters['headerView/UIConfig'].sumHeight) || 0;

      state.height = document.body.clientHeight - headerHeight - 29 + 41;
      state.width = this.config.width;
      state.top = headerHeight - 41;
      state.left = state.maxWidth;

      if (innerObject.settings.isOpened) {
        return true;
      }
    },
    on: {
      onBeforeShow() {
        innerHandlers.initList();
        app.trigger('popup:closeOther', popupId);
      },
      onHide() {
        app.trigger('popup:hide');
      },
      onShow() {
      },
    },
  });

  // ???
  const outputObject = {
    handlers: {
    // @public
      togglePopup() {
        if (!innerObject.settings.isOpened) {
          innerHandlers.showPopup();
        } else {
          innerHandlers.hidePopup();
        }
      },
    },
  };

  app.on('activeProject:afterSet', (ganttId, projectData) => {
    innerHandlers.checkAccessForActiveProject(ganttId);
  });

  app.on('activeMultiProjects:afterSet', () => {
    innerHandlers.checkAccessForActiveProject();
  });

  app.on('integration:updated', () => {
    innerHandlers.checkAccessForActiveProject();
  });

  app.on('project:archive', (ganttId, projectData) => {
    if (gantt.config.gantt_id === ganttId) {
      innerHandlers.checkAccessForActiveProject(ganttId);
    }
  });

  // app.on('body:resize', () => {
  //   innerHandlers.initView();
  // });

  app.on('gantt:area:click', () => {
    innerHandlers.hidePopup(true);
  });

  app.on('popup:show', () => {
    innerHandlers.hidePopup(true);
  });

  app.on('popup:closeOther', id => {
    if (id !== popupId) {
      innerHandlers.hidePopup(true);
    }
  });

  // app.on('user:changedRole', ganttId => {
  //   _.delay(() => {
  //     innerObject.init.reInitByRights(ganttId);
  //   });
  // });

  app.on('closeWindowWithAnimation', () => {
    innerHandlers.hidePopup(true);
  });

  // webix.event(window, 'resize', () => {
  //   innerHandlers.initView();
  // });

  return outputObject;
});
