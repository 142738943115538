var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.$style.section }, [
    _c(
      "div",
      { ref: "sectionWrapper", class: [_vm.$style.section__wrapper] },
      [
        _c("wizard-left-section", {
          attrs: {
            title: _vm.title,
            description: _vm.description,
            "animate-enter": "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.section__right },
          [
            _c("div", { class: _vm.$style["photo-container"] }, [
              _c(
                "div",
                { class: _vm.$style["photo-badge"] },
                [
                  _c("svg-sprite", { attrs: { name: "timer", size: 20 } }),
                  _vm._v(" "),
                  _c("span", { class: _vm.$style["photo-badge__text"] }, [
                    _vm._v(
                      _vm._s(_vm.locales("onboarding_book_demo_duration"))
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style["quote-container"] }, [
              _c("div", {
                class: [_vm.$style.quote__img, _vm.$style.quote__img_top],
              }),
              _vm._v(" "),
              _c("div", { class: _vm.$style.quote__text }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("onboarding_book_demo_quote")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                class: [_vm.$style.quote__img, _vm.$style.quote__img_bottom],
              }),
            ]),
            _vm._v(" "),
            _c("vgp-button", {
              attrs: {
                type: "green",
                label: _vm.locales("onboarding_book_demo_btn"),
                small: "",
              },
              on: { onClick: _vm.openDemoScheduler },
            }),
            _vm._v(" "),
            _c("vgp-button", {
              class: _vm.$style["skip-button"],
              attrs: {
                type: "text-green",
                label: _vm.locales("onboarding_skip_step_btn_label"),
                small: "",
              },
              on: { onClick: _vm.skipBooking },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }