var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "headerHead",
      class: [
        _vm.$style.header_head,
        _vm.$style[_vm.config.css || ""],
        "header_head_common",
      ],
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.$style.name_area,
            _vm.isEditingTitle ? _vm.$style.editing : "",
          ],
        },
        [
          _vm.config.ui.includes("publicUrlInfo")
            ? _c("div", { class: _vm.$style.public_url_title }, [
                _vm.config.ui.includes("logo")
                  ? _c(
                      "div",
                      { class: [_vm.$style.logo_area, _vm.$style.tall_border] },
                      [_c("vgp-svg-image", { attrs: { name: "logo" } })],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getPublicUrlInfo.logo
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.getPublicUrlInfo.css === "image"
                            ? _vm.$style.logo
                            : _vm.$style.favicon,
                        ],
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.getPublicUrlInfo.logo, alt: "" },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.getPublicUrlInfo.css !== "image"
                  ? _c(
                      "div",
                      { class: [_vm.$style.text, _vm.$style.tall_border] },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getPublicUrlInfo.teamName) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [_vm.$style.text, _vm.$style.title, "project_name"],
                    style: {
                      maxWidth: parseInt(_vm.inputMaxWidth) - 200 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getPublicUrlInfo.projectName) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$style.text_small }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getPublicUrlInfo.sharedBy) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.config.headIcon
            ? _c(
                "div",
                { class: _vm.$style.project_icon },
                [_c("webix-ui", { attrs: { config: _vm.getHeadIcon } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.config.ui.includes("noBaseTitle")
            ? _c("div", { class: _vm.$style.project_name }, [
                _c("div", { class: [_vm.$style.title_body] }, [
                  _vm.editableTitle
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.title,
                            expression: "title",
                          },
                        ],
                        ref: "projectTitle",
                        class: [
                          _vm.$style.title,
                          _vm.$style.input_title,
                          "js_title",
                        ],
                        style: {
                          width: _vm.inputWidth,
                          maxWidth: _vm.inputMaxWidth,
                        },
                        attrs: { readonly: !_vm.editableTitle },
                        domProps: { value: _vm.title },
                        on: {
                          click: _vm.onClickTitle,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onEnterTitle($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.title = $event.target.value
                          },
                        },
                      })
                    : _c(
                        "div",
                        {
                          class: _vm.$style.title,
                          style: {
                            maxWidth: parseInt(_vm.inputMaxWidth) - 35 + "px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n        "
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  _vm.getHeadHelpIcon
                    ? _c(
                        "div",
                        { class: [_vm.$style.help_icon, "help_icon_common"] },
                        [
                          _c("webix-ui", {
                            attrs: { config: _vm.getHeadHelpIcon },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.ui.includes("favorite")
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.$style.favorite,
                            _vm.getFavorite ? _vm.$style.active : "",
                          ],
                          on: { click: _vm.onClickFavorite },
                        },
                        [
                          _c("webix-ui", {
                            attrs: { config: _vm.icons.favorite },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.isEditingTitle
                  ? _c("div", { class: _vm.$style.edit_controls }, [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$style.edit_btn,
                            _vm.$style.check,
                            "header_title_control",
                          ],
                        },
                        [
                          _c("webix-ui", {
                            attrs: { config: _vm.icons.check },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$style.edit_btn,
                            _vm.$style.cancel,
                            "js_title_cancel",
                            "header_title_control",
                          ],
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onClickTitleCancel($event)
                            },
                          },
                        },
                        [
                          _c("webix-ui", {
                            attrs: { config: _vm.icons.cancel },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "statusSelectArea", class: _vm.$style.status_area },
            [
              _vm.statusData &&
              (_vm.config.headIcon === "project" ||
                _vm.config.headIcon === "projectJira")
                ? _c("status-select", {
                    attrs: {
                      "status-data": _vm.statusData,
                      "has-right-to-change-status":
                        _vm.hasRightToChangeStatus(),
                      "has-right-to-status-settings":
                        _vm.hasRightToStatusSettings(),
                      "project-config": _vm.config,
                    },
                    on: {
                      currentProjectForStatus: function ($event) {
                        _vm.currentProjectForStatus = $event
                      },
                      positionSelect: function ($event) {
                        _vm.positionSelect = $event
                      },
                      open: function ($event) {
                        _vm.openStatus = $event
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.openStatus
                ? _c("status-select-dropdown", {
                    attrs: {
                      "status-data": _vm.statusData,
                      position: _vm.positionSelect,
                      "project-config": _vm.currentProjectForStatus,
                      "is-open": _vm.openStatus,
                      "has-right-to-change-status":
                        _vm.hasRightToChangeStatus(),
                      "has-right-to-status-settings":
                        _vm.hasRightToStatusSettings(),
                    },
                    on: {
                      selectStatus: _vm.onChangeSelect,
                      closeStatus: _vm.closeStatusSelect,
                      onClickOutside: _vm.onClickOutsideStatus,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "toolsArea", class: _vm.$style.tools_area },
        [
          _vm.config.ui.includes("rolePopup")
            ? _c("role-popup", { class: _vm.$style.role_popup })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("unArchive")
            ? _c("vgp-button", {
                class: _vm.$style.archive_button,
                attrs: {
                  label: _vm.locales("unarchive_project"),
                  type: "primary",
                  small: "",
                },
                on: { onClick: _vm.onClickUnArchive },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("msteams") && _vm.isShowMSTeams
            ? _c("vgp-icon-button", {
                class: [_vm.$style.tools_btn, "js_slack_btn"],
                attrs: {
                  icon: { name: "msteam", type: "color-regular" },
                  colors: _vm.buttonColors,
                  active: _vm.isShowMSTeamsPopup,
                },
                on: { onClick: _vm.onClickMSTeams },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("slack") && _vm.isShowSlack
            ? _c("vgp-icon-button", {
                class: [_vm.$style.tools_btn, "js_slack_btn"],
                attrs: {
                  icon: { name: "slack", type: "color-regular" },
                  colors: _vm.buttonColors,
                  active: _vm.isShowSlackPopup,
                },
                on: { onClick: _vm.onClickSlack },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("history")
            ? _c("vgp-icon-button", {
                class: [
                  _vm.$style.tools_btn,
                  "js_history_button",
                  { free: !_vm.userIsPaid },
                ],
                attrs: {
                  id: "js_header_history_button",
                  icon: { name: "history", type: "regular", size: 24 },
                  colors: _vm.buttonColors,
                },
                on: { onClick: _vm.onClickHistory },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("settings")
            ? _c("vgp-icon-button", {
                class: [_vm.$style.tools_btn, "js_settings_btn"],
                attrs: {
                  icon: { name: "settings", type: "regular", size: 24 },
                  colors: _vm.buttonColors,
                  active: _vm.isShowSettingsPopup,
                },
                on: { onClick: _vm.onClickSettings },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("more") && _vm.moreItems
            ? _c("more-dropdown", {
                class: _vm.$style.tools_btn,
                attrs: {
                  "on-click": _vm.onSelectMore,
                  config: _vm.config.moreItems,
                  "button-colors": _vm.buttonColors,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("signUpButton")
            ? _c("div", { class: _vm.$style.sign_up }, [
                _c(
                  "a",
                  {
                    class: _vm.$style.sign_up_btn,
                    attrs: {
                      href: "#/signup",
                      "data-logged-text": _vm.locales(
                        "logged_action_button_test"
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.locales("sign_up")) + "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.config.ui.includes("close")
            ? _c("vgp-icon-button", {
                class: [_vm.$style.tools_btn, "js_close_btn"],
                attrs: {
                  icon: { name: "close-1", type: "bold", size: 24 },
                  colors: _vm.buttonColors,
                },
                on: { onClick: _vm.onClickClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "hiddenArea",
          class: _vm.$style.hidden_area,
          attrs: { id: "hiddenArea" },
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.isShowPublicUrlPopup
        ? _c("core-popup", {
            attrs: {
              "close-icon": true,
              "header-style": "withBorder",
              show: _vm.isShowPublicUrlPopup,
              title: _vm.locales("share_popup_public_url_filter_header"),
            },
            on: {
              onClickCancel: function ($event) {
                return _vm.togglePopup("isShowPublicUrlPopup")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [_c("public-urls-pop-up")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1350488518
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowTemplatePopup
        ? _c("template-pop-up", {
            attrs: { show: _vm.isShowTemplatePopup },
            on: {
              onClickCancel: function ($event) {
                return _vm.togglePopup("isShowTemplatePopup")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowDeletePopup
        ? _c("core-confirm-popup", {
            attrs: {
              show: _vm.isShowDeletePopup,
              "popup-width": 440,
              "close-icon": true,
              title: _vm.locales("project_list_delete_project"),
              text: _vm.locales("project_delete_confirm_text", {
                projectName: _vm.title ? _vm.title : "",
              }),
              "confirm-string": _vm.title ? _vm.title : "",
              "confirm-string-input-placeholder": _vm.locales(
                "delete_project_confirm_string_placeholder"
              ),
              "ok-button": {
                title: _vm.locales("common_delete"),
                type: "destructive",
              },
              "cancel-button": {
                title: _vm.locales("common_cancel"),
                type: "secondary",
              },
            },
            on: {
              onClickOk: function ($event) {
                return _vm.onCloseDeletePopup("ok")
              },
              onClickCancel: _vm.onCloseDeletePopup,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }