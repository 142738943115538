<template>
  <div
    :class="$style.select"
    v-click-outside="close"
  >
    <select-button
      ref="contextMenuButton"
      :label="locales('workload_view_mode')"
      :value="getActive ? getActive.value : ''"
      :active="showMenu"
      @onClick="toggleShow"
    />

    <context-menu
      v-if="showMenu"
      :position="positionContext"
    >
      <template #body>
        <div
          v-for="(item, index) in types"
          :key="index"
          :class="[
            $style.menu_item,
            item.id === getActive.id ? $style.active : '',
            item.type === 'divider' ? $style.title : '',
          ]"
          @click="itemClicked(item)"
        >
          <div
            v-if="item.type === 'divider'"
            :class="$style.title_text"
          >
            {{ item.value }}
          </div>
          <div
            v-else
            :class="$style.text"
          >
            {{ item.value }}
          </div>
        </div>
      </template>
    </context-menu>
  </div>
</template>

<script>
import selectButton from './selectButton.vue';

export default {
  components: {
    selectButton,
  },
  data() {
    return {
      locales: __,
      showMenu: false,
      positionContext: null,
      types: [
        { id: 'hours', value: __('workload_header_hours_per_day') },
        { id: 'tasks', value: __('workload_header_tasks_per_day') },
        { id: 'percents', value: __('workload_header_percents_per_day') },
      ],
    };
  },
  computed: {
    getActive() {
      return this.types.find(obj => obj.id === this.$store.getters['headerView/activeWorkloadType']);
    },
  },
  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();
      }
    },
    itemClicked(item) {
      this.toggleShow();
      userExtAnalytics.log('header_select_workload_type', {
        type: item.id,
      });
      this.$store.dispatch('headerView/selectWorkloadType', item.id);
    },
    close() {
      this.showMenu = false;
    },
  },
};
</script>

<style module src="../less/select.less"></style>
