import moduleDnD from "../modules/dragndrop";
import moduleCustomGrids from "../modules/customGrids";
import moduleWorkload from "../modules/workload";
import moduleGridCustomButtons from "../modules/gridCustomButtons";
import moduleHistory from "../modules/history";
import moduleProgress from "../modules/progress";
import moduleResources from "../modules/resources";
import moduleCustomColumns from "../modules/customColumns";
import moduleEstimation from "../modules/estimation";
import moduleGridActionButtons from "../modules/gridActionButtons";
import moduleWorkDays from "../modules/workDays";
import moduleLink from "../modules/link";
import moduleKeyboard from "../modules/keyboard";
import moduleTextAlign from "../modules/textAlign";
import moduleBaseline from "../modules/baseline";
import moduleContextMenu from "../modules/contextMenu";
import moduleDisableGantt from "../modules/disableGantt";

export default {
  beforeInit: [
    moduleCustomColumns,
    moduleWorkload,
    moduleCustomGrids,
    moduleGridCustomButtons,
    moduleHistory,
    moduleContextMenu,
  ],
  init: [
    moduleProgress,
    moduleResources,
    moduleCustomColumns,
    moduleEstimation,
    moduleGridCustomButtons,
    moduleGridActionButtons,
    moduleWorkDays,
    moduleLink,
    moduleKeyboard,
    moduleTextAlign,
    moduleHistory,
    moduleBaseline,
    moduleContextMenu,
    moduleDisableGantt
  ],
  dependencyInit: [
    {
      module: moduleCustomGrids,
      callBack: () => {}
    },
    {
      module: moduleGridActionButtons,
      callBack: moduleResources.handlers.deleteResourceFromTask
    },
    {
      module: moduleDnD,
      callBack: moduleResources.handlers.deleteResourceFromTask
    },
    {
      module: moduleResources,
      callBack: () => {}
    }
  ],
  afterInit: [
    moduleDisableGantt
  ]
};