var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.access
        ? _c("div", { staticClass: "timelog-block-layout" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("div", { staticClass: "title-wrapper" }, [
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("timelog_access_title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-title-container" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales(_vm.planTitle)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _c("div", { staticClass: "desc-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("timelog_access_desc")) +
                        "\n          "
                    ),
                    _c(
                      "div",
                      { staticClass: "learn_more" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pricing-popup-link",
                            attrs: {
                              href: _vm.locales("timelog_learn_more"),
                              target: "_blank",
                            },
                            on: { click: _vm.linkClick },
                          },
                          [_vm._v(_vm._s(_vm.locales("help_read_more")))]
                        ),
                        _vm._v(" "),
                        _c("webix-ui", {
                          staticClass: "arrow-left",
                          attrs: { config: _vm.icons.arrowLeft },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isOwner
                  ? _c("upgrade-btn", { attrs: { feature: "time_tracking" } })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "pic" }, [
                  _c("img", { attrs: { src: _vm.pic, alt: "" } }),
                ]),
              ],
              1
            ),
          ])
        : _c("webix-ui", { attrs: { config: _vm.webixUI } }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }