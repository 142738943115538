<template>
  <div
    :class="[$style.vgp_input_switch, isError ? $style.error: null, isFocus && isDisabledInput ? $style.focus: null, isDisabled ? $style.disabled: null]"
    @click="focusElement(true)"
  >
    <square-toggle
      v-if="togglePosition === 'left'"
      :is-toggle-on="isDisabledInput"
      @onChange="activateElement"
    />
    <vgp-input
      ref="input"
      v-model="data"
      :small="isSmall"
      :class="[togglePosition === 'left' ? $style.vgp_input_switch_left : $style.vgp_input_switch_right]"
      :input-type="type"
      :is-disabled="!isDisabledInput"
      :placeholder="placeholder"
      :icons-right="icons.length && togglePosition === 'left' ? icons : []"
      :icons-left="icons.length && togglePosition === 'right' ? icons : []"
      :border="false"
    />
    <square-toggle
      v-if="togglePosition === 'right'"
      :is-toggle-on="isDisabledInput"
      @onChange="activateElement"
    />
  </div>
</template>

<script>

import vgpInput from './vgpInput.vue';
import SquareToggle from '../checkbox/squareToggle.vue'; 

export default {
  name: 'VgpInputSwitch',
  components: { SquareToggle, vgpInput },
  props: {
    placeholder: {
      type: String,
      required: true,
      default: '—',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: [Array],
      required: false,
      default: () => [],
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
    togglePosition: {
      type: String,
      required: true,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
  },
  emits: {
    input: 'string',
  },
  data() {
    return {
      isFocus: false,
      isDisabledInput: false,
      data: this.value ? this.value : '',
    };
  },
  computed: {
    classes() {
      return `input-${this.type}`;
    },
  },

  watch: {

    data() {
      this.$emit('input', this.data);
    },
    value() {
      if (this.value === null || this.value === undefined) {
        this.data = '';
        this.isDisabledInput = false;
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    focusElement(value) {
      this.isFocus = value;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.focusElement(false);
      }
    },
    activateElement() {
      if (this.isDisabledInput) {
        this.data = '';
      }
      this.isDisabledInput = !this.isDisabledInput;
      this.$nextTick(() => this.$refs.input.$refs.input_field_icons.focus());
    },
  },
};
</script>

<style module src='./vInput.less'></style>
