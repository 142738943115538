<template>
  <div
    :class="[
      $style.button,
      active ? $style.active : '',
    ]"
    @click="$emit('onClick', $event)"
  >
    <div
      v-if="checkLabel"
      :class="$style.label"
    >
      {{ label }}
    </div>
    <div
      v-if="checkValue"
      :class="$style.value"
    >
      {{ value }}
    </div>
    <svg-sprite
      :name="active ? 'arrow-top' : 'arrow-down'"
      :size="20"
      :height="24"
      :width="24"
      :class="$style.icon"
    />
  </div>
</template>

<script> 

export default {
  components: { 
  },
  props: {
    label: {
      type: [Number, String],
      default: '',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkLabel() {
      return this.check(this.label);
    },
    checkValue() {
      return this.check(this.value);
    },
  },
  methods: {
    check(value) {
      if (typeof value === 'number') return `${value}`;

      return value;
    },
  },
};
</script>

<style module src="../less/selectButton.less"></style>
