// public/views/comments/createComment.js

import Tribute from '../../libs/tributejs/tribute';
import customHelper from '../../helpers/custom';
import Quill from 'quill';
import rightsComponent from '../../components/rights';

// duplicate initi?
// import AutoLinks from 'quill-auto-links';
// Quill.register('modules/autoLinks', AutoLinks);

const __ = window.__;
const _ = window._;

const ID_EVENT_SUBMIT_COMMENT = 'submitCreateComment';
const ID_EVENT_CANCEL_COMMENT = 'cancelCreateComment';
const ID_EVENT_START_COMMENT = 'startCreateComment';

const __quillToolbarSettings = [
  [{ header: [1, 2, 3, false] }],
  [{ font: [] }],
  ['bold', 'italic', 'underline'],
  [
    { list: 'ordered' },
    { list: 'bullet' },
    { align: [] },
  ],
  ['link'],
  [
    { color: [] },
    { background: [] },
  ],
  ['clean'],
];

const quillData = {};
let _tribute = null;

const _templates = {
  //
  newMessageArea: ({ idEditArea }) => `
        <div class="ql-snow">
            <div id="${idEditArea}" class="ql-editor ql-view">
            </div>
            <div id="descComment" class="desc_comment ql-view">
                <div id="quillCancelCommentsButton" class="ql-button add-comment-button cancel">
                    ${__('task_view_comment_area_cancel')}
                </div>
                <div id="quillAddCommentsButton" class="ql-button add-comment-button add">
                    ${__('task_view_comment_area_add')}
                </div>
            </div>
        </div>`,
};

const _helpers = {
  // init tribute
  // !description
  initQuillArea: (idArea, toolbarSettings, dataStore) => {
    dataStore[idArea] = new Quill(`#${idArea}`, {
      theme: 'snow',
      modules: {
        toolbar: toolbarSettings,
        autoLinks: true,
        keyboard: {
          bindings: {
            tributeSelectOnEnter: {
              key: 50,
              shortKey: false,
              handler: event => {
                if (_tribute.isActive) {
                  _tribute.selectItemAtIndex(_tribute.menuSelected, event);
                  _tribute.hideMenu();

                  return false;
                }

                return true;
              },
            },
          },
        },
      },
    });
  },
  // !description
  cleanupQuillArea: (idArea, dataStore) => {
    if (dataStore[idArea]) {
      dataStore[idArea] = null;
    }
  },
};

webix.protoUI({
  name: 'add_comment',
  $cssName: 'template_body_footer',
  // default values for configuration settings
  defaults: {
    cols: [],
  },
  // 'property'_setter
  $init(config) {
    this.$ready.push(this._Init);
  },
  //
  ids: {
    btnView: null,
    editAreaView: null,
    editInputView: null,
    footerContainer: null,
  },
  // inner views used in the component layout
  views: {
    // {String, Function} -> {Object}
    getButtonView(idView, cbClick) {
      return {
        id: idView,
        view: 'button',
        css: 'button_gray new-comment-button',
        value: __('comments_add_new'),
        width: 300,
        height: 42,
        click: cbClick,
      };
    },
    // {String, String, Function, Function} -> {Object}
    getEditAreaView(
      idContainerView, idInputView, idQuillArea, cbSubmit, cbCancel,
    ) {
      return {
        css: 'task-view-block-area-header',
        id: idContainerView,
        rows: [{
          id: idInputView,
          css: 'task-view-block-area-header-new',
          view: 'template',
          autoheight: true,
          borderless: true,
          template: _templates.newMessageArea,
          data: { idEditArea: idQuillArea },
          onClick: { add: cbSubmit, cancel: cbCancel },
        }],
      };
    },
  },
  handlers: {
    //
    onStartComment() {
      const me = this;
      const { config: { ids, idtask }, views, handlers } = me;

      userExtAnalytics.log('general_comments_project_task_comment_add');
      $$(ids.footerContainer).removeView(ids.btnView);
      $$(ids.footerContainer).addView(views.getEditAreaView(
        ids.editAreaView,
        ids.editInputView,
        ids.commentsQuillArea,
        handlers.onSubmitComment.bind(me),
        handlers.onCancelComment.bind(me),
      ));

      setTimeout(() => {
        const debounceChanges = _.debounce(() => {
          $$(ids.editAreaView).resize();
        });

        _helpers.initQuillArea(ids.commentsQuillArea, __quillToolbarSettings, quillData /** !FIXME */);
        $$(ids.editAreaView).resize();

        quillData[ids.commentsQuillArea].focus();
        quillData[ids.commentsQuillArea].on('text-change', () => { debounceChanges(); });

        const editor = document.querySelector(`#${ids.commentsQuillArea} .ql-editor`);

        if (_tribute && editor) {
          _tribute.attach(editor);
        }

        me.callEvent(ID_EVENT_START_COMMENT, [{ idtask }]);
      }, 100);
    },
    //
    onCancelComment() {
      const me = this;
      const { config: { ids, idtask }, views, handlers } = me;

      setTimeout(() => {
        _helpers.cleanupQuillArea(ids.commentsQuillArea, quillData);

        $$(ids.footerContainer).removeView(ids.editAreaView);
        $$(ids.footerContainer).addView(views.getButtonView(ids.btnView, handlers.onStartComment.bind(me)));

        me.callEvent(ID_EVENT_CANCEL_COMMENT, [{ idtask }]);
      }, 0);
      userExtAnalytics.log('general_comments_project_task_comment_add_close');
    },
    //
    onSubmitComment() {
      const me = this;
      const { config: { ids, idtask }, views, handlers } = me;
      const __quillEntry = quillData[ids.commentsQuillArea];

      const __content = customHelper.validateName(__quillEntry.getInnerText().trim()) && __quillEntry.getHtml();

      setTimeout(() => {
        _helpers.cleanupQuillArea(ids.commentsQuillArea, quillData);

        $$(ids.footerContainer).removeView(ids.editAreaView);
        $$(ids.footerContainer).addView(views.getButtonView(ids.btnView, handlers.onStartComment.bind(me)));

        me.callEvent(ID_EVENT_SUBMIT_COMMENT, [{ idtask, content: __content }]);
      }, 0);
      userExtAnalytics.log('general_comments_project_task_comment_add_close');
    },
  },
  _Init() {
    const me = this;

    const {
      handlers, config,
      config: { id, teamMembers },
    } = me;

    // init identifiers
    const ids = config.ids = {};

    ids.btnView = `${id}_btnView`;
    ids.editAreaView = `${id}_editView`;
    ids.editInputView = `${id}_inputView`;
    ids.footerContainer = `${id}_footerContainer`;
    ids.commentsQuillArea = `idCommentsQuillArea_${id}`;

    _tribute = new Tribute({
      values: teamMembers.map(({
        username, email, online, id,
      }) => ({
        id,
        online,
        key: username,
        value: email,
      })),
      menuItemTemplate: item => {
        const __item = item.original || {};

        return `
                    <span class="${__item.online ? 'online' : 'offline'}"></span>
                    <b>${__item.key} </b>
                    ${__item.value}`;
      },
      selectTemplate: item => `[~${item.original.value}]`,
    });

    // add initial view // $getSize // $setSize
      me.addView({
        view: 'layout',
        css: 'template_body_footer',
        id: ids.footerContainer,
        rows: [me.views.getButtonView(ids.btnView, handlers.onStartComment.bind(this))],
      });
  },
}, webix.ui.layout, webix.EventSystem);
