var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["comment__row"] }, [
    _c("img", {
      class: _vm.$style["comment__author-image"],
      attrs: { src: _vm.comment.authorPhoto, alt: "Comment's author photo" },
    }),
    _vm._v(" "),
    _c("div", { class: _vm.$style["comment__info-wrap"] }, [
      _c("div", { class: _vm.$style["comment__header"] }, [
        _c("span", { class: _vm.$style["comment__author"] }, [
          _vm._v(
            "\n        " + _vm._s(_vm.comment.authorFullName) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("span", { class: _vm.$style["comment__date"] }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getCommentDisplayedDateString(_vm.comment)) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _vm.showCommentActions(_vm.comment)
          ? _c(
              "div",
              { class: _vm.$style["comment__actions"] },
              [
                _c("vgp-icon-button", {
                  attrs: { icon: { name: "delete", size: 16 } },
                  on: {
                    onClick: function ($event) {
                      return _vm.handleClickDeleteComment(_vm.comment.id)
                    },
                  },
                }),
                _vm._v(" "),
                _c("vgp-icon-button", {
                  staticClass: "edit-comment-icon-btn",
                  attrs: { icon: { name: "pensil", size: 16 } },
                  on: {
                    onClick: function ($event) {
                      return _vm.$emit("edit-comment", _vm.comment)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ql-snow" }, [
        _c("div", {
          staticClass: "ql-editor",
          class: _vm.$style["comment__content"],
          domProps: { innerHTML: _vm._s(_vm.comment.content) },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }