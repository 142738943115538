import { groupBy } from 'lodash';
import { internalApi } from '$$store/api';
import { replaceMentionsWithIds } from '$$helpers/comments';
import app from '../../app';
import rights from '../../components/rights';

import store from '../main';

export default {

  async fetchCommentsByProjectIds({ state, commit, getters }, { projectIds, refetch = false }) {
    try {
      const projectIdsArray = Array.isArray(projectIds) ? projectIds : [projectIds];
      let projectIdsNeedToFetch;

      if (refetch) {
        projectIdsNeedToFetch = projectIdsArray;
      } else {
        projectIdsNeedToFetch = projectIdsArray.filter(projectId => !getters.isCommentsAlreadyFetchedForProject(projectId));
      }

      if (!projectIdsNeedToFetch.length) {
        return Promise.resolve();
      }

      commit('setIsFetching', true);
      const { data } = await internalApi.post(
        '/comments/getByProjectIds',
        { projectId: projectIdsNeedToFetch },
      );
      const fetchedGroupedCommentsByProjectId = groupBy(data, 'projectId');

      projectIdsNeedToFetch.forEach(projectId => {
        if (fetchedGroupedCommentsByProjectId[projectId]) {
          commit('setComments', { projectId, comments: fetchedGroupedCommentsByProjectId[projectId] });
        } else {
          commit('setComments', { projectId, comments: [] });
        }
      });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      state.isFetching && commit('setIsFetching', false);
    }
  },

  async fetchCommentsActions({ commit }) {
    try {
      const { data } = await internalApi.get('/comments/actions', { headers: { 'user-id': window.user.id } });

      commit('setCommentsActions', data);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async markAsRead({ commit, rootGetters }, { taskIds, commentIds }) {
    try {
      if (!commentIds.length) return;

      await internalApi.post('/comments/actions/markAsRead', { userId: window.user.id, commentId: commentIds });

      commit('markAsReadCommentsActions', commentIds);

      taskIds.forEach(taskId => {
        const oldTaskData = rootGetters['tasksModel/tasksByIds'][taskId];

        if (oldTaskData && oldTaskData.id) {
          const newTaskData = {
            ...oldTaskData,
            newComments: false,
          };

          const projectTasksData = rootGetters['tasksModel/getItem'](oldTaskData.gantt_id);

          projectTasksData.commentActions[taskId] = false;

          store.commit('tasksModel/addProjectTasksData', projectTasksData);
          commit('tasksModel/updateTask', newTaskData, { root: true });
          app.trigger('tasks:model:updateTask', newTaskData.id, newTaskData);
        }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async modifyHasCommentsCountInTask({ dispatch, rootGetters }, { projectId, taskId, type }) {
    const taskData = rootGetters['tasksModel/getTaskByGanttId'](projectId, taskId);

    if (!taskData) {
      console.warn(`[incrementHasCommentsInTask] -> no task data for: ${projectId} ${taskId}`);

      return;
    }

    switch (type) {
    case 'increment':
      taskData.hasComments = +(taskData.hasComments || 0) + 1;
      break;
    case 'decrement':
      taskData.hasComments = +taskData.hasComments - 1;
      break;
    default:
      throw new Error('[modifyHasCommentsCountInTask]: Invalid operation type');
    }

    dispatch('tasksModel/updateTask', {
      taskChanges: taskData,
      taskId: taskData.id,
      ganttId: taskData.gantt_id,
    }, { root: true });
  },

  // We temporarily need it to sync data CommentsModel with data Vuex Comments store
  // Remove this method and all its usages after comment hub will be implemented
  // addCommentToOldCommentModel({ rootGetters }, { newComment, taskName }) {
  //   const resourceCommentData = rootGetters['resourcesModel/getResourceByUserId'](newComment.userId);
  //   const payloadForOldCommentModel = {
  //     comment: newComment.content,
  //     date: newComment.createdAt,
  //     firstName: newComment.user.firstName,
  //     gantt_id: newComment.projectId,
  //     id: newComment.id,
  //     lastName: newComment.user.lastName,
  //     photo: newComment.user.photo,
  //     project_name: projectsModel.getProjectDataById(newComment.projectId)?.name,
  //     read: 1,
  //     task_id: newComment.taskId,
  //     task_name: taskName,
  //     team_id: resourceCommentData.team_id,
  //     user_id: newComment.userId,
  //     username: resourceCommentData.email.split('@')[0],
  //   };
  //
  //   commentsModel.addNewCommentToModel(payloadForOldCommentModel);
  // },

  // Remove [taskName, needReplaceMentions] parameters when comment hub will be implemented
  async createComment({ commit, dispatch }, {
    projectId, taskId, content, taskName, needReplaceMentions = true,
  }) {
    try {
      const { data: { item } } = await internalApi.post('/comments', {
        taskId,
        userId: window.user.id,
        content: needReplaceMentions ? replaceMentionsWithIds(content) : content,
      });

      commit('addComment', item);
      commit('addCommentAction', {
        commentId: item.id,
        date: item.createdAt,
        read: 1,
        userId: window.user.id,
      });

      dispatch('markAsRead', { taskIds: [taskId], commentIds: [item.id] });
      dispatch('modifyHasCommentsCountInTask', { projectId, taskId, type: 'increment' });
      // dispatch('addCommentToOldCommentModel', { newComment: item, taskName });
      gantt.callEvent('taskEditor:task:changed', [{ id: taskId }]);

      return Promise.resolve(item);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  onWSCommentCreated({ commit, dispatch, rootGetters }, createdComment) {
    const { projectId, comment } = createdComment;
    const taskData = rootGetters['tasksModel/getTaskByGanttId'](projectId, comment.taskId);
    const resource = rootGetters['resourcesModel/getResourceByUserId'](user.id);
    const resourceOnProjects = rootGetters['resourcesModel/getResourcesByProjectId'](projectId);

    taskData.hasComments = +(taskData.hasComments || 0) + 1;
    taskData.newComments = 1;

    // uncomment this when commentsModel.onWSAddComment will be removed
    // dispatch('incrementHasCommentsInTask', { projectId: createdComment.gantt_id, taskId: createdComment.taskId });
    commit('addComment', comment);

    if (resourceOnProjects.find(item => item.id === resource.id)) {
      commit('addCommentAction', {
        commentId: comment.id,
        date: comment.createdAt,
        read: 0,
        userId: window.user.id,
      });
    }

    dispatch('tasksModel/updateTask', {
      taskChanges: taskData,
      taskId: taskData.id,
      ganttId: projectId,
    }, { root: true });
  },

  async editComment({ commit }, { projectId, commentId, content }) {
    try {
      content = replaceMentionsWithIds(content);

      await internalApi.put(`/comments/${commentId}`, { content });
      const updatedAt = new Date().toISOString();

      commit('updateComment', { projectId, commentId, payload: { content, updatedAt } });
      // remove after comment hub will be implemented
      // commentsModel._editCommentInModel({
      //   id: commentId,
      //   comment: content,
      //   date_edited: updatedAt,
      // });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  onWSCommentEdited({ commit, getters, rootGetters }, editedComment) {
    const storeComment = getters.getCommentById(editedComment.commentId);

    if (!storeComment) return;

    const resource = rootGetters['resourcesModel/getResourceByUserId'](user.id);
    const payload = {
      projectId: storeComment.projectId,
      commentId: storeComment.id,
      payload: editedComment.updatedFields,
    };

    commit('updateComment', payload);

    if (resource.resourceProjects.find(item => item.projectId === storeComment.projectId)) {
      commit('removeCommentAction', storeComment.id);
      commit('addCommentAction', {
        commentId: storeComment.id,
        date: storeComment.createdAt,
        read: 0,
        userId: window.user.id,
      });
    }
  },

  async deleteComment({ dispatch, commit }, { projectId, taskId, commentId }) {
    try {
      await internalApi.delete(`/comments/${commentId}`);
      commit('removeComment', { projectId, commentId });
      dispatch('modifyHasCommentsCountInTask', { projectId, taskId, type: 'decrement' });
      // remove after comment hub will be implemented
      // commentsModel.removeCommentFromModel(commentId);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  onWSCommentDeleted({
    commit, dispatch, getters, rootGetters,
  }, commentId) {
    const storeComment = getters.getCommentById(commentId);

    if (!storeComment) return;

    const taskData = rootGetters['tasksModel/getTaskByGanttId'](storeComment.projectId, storeComment.taskId);
    const comments = +taskData.hasComments - 1;
    const updatedTask = {
      ...taskData,
      hasComments: comments,
      newComments: comments,
    };

    const payload = {
      projectId: storeComment.projectId,
      commentId,
    };

    commit('removeComment', payload);
    commit('removeCommentAction', commentId);
    dispatch('tasksModel/updateTask', {
      taskChanges: updatedTask,
      taskId: updatedTask.id,
      ganttId: storeComment.projectId,
    }, { root: true });
  },
  deleteCommentsByTaskId({ state, commit }, payload) {
    const commentsToDelete = state.projectCommentsMap[payload.gantt_id]?.filter(item => item.taskId !== payload.id);

    delete state.taskCommentsMetaData[payload.id];

    commentsToDelete && commit('setComments', { projectId: payload.gantt_id, comments: commentsToDelete });
  },

};
