<template>
  <info-popup
    :position="position"
    :side="side"
    @closePopup="closePopup"
  >
    <template #body>
      <div
        class="projects-popup-list-item-adv"
        :data-id="project.id"
        :data-gantt_id="project.gantt_id"
      >
        <div class="projects-popup-list-item-row">
          <div class="projects-popup-list-item-column">
            <div class="projects-popup-list-item-adv-users">
              <div class="projects-popup-list-item-adv-users-icons">
                <div class="projects-popup-list-item-adv-users-icons-item">
                  <svg-sprite :name="'assigned'" />
                </div>
              </div>
              <div class="projects-popup-list-item-adv-users-count">
                {{ project.gantt_id ? locale('gantt_grid_owner'): locale('gantt_grid_creator') }}: <strong>{{ getCreatorName(project) }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="projects-popup-list-item-row">
          <div class="projects-popup-list-item-column">
            <div class="projects-popup-list-item-adv-users">
              <div class="projects-popup-list-item-adv-users-icons">
                <div class="projects-popup-list-item-adv-users-icons-item">
                  <svg-sprite :name="'team'" />
                </div>
              </div>
              <div class="projects-popup-list-item-adv-users-count">
                {{ locale('team_name') }}:
                <strong>{{ getUsersCount(project) }} {{ (getUsersCount(project) > 1) ? locale('locale_users') : locale('locale_user') }}</strong>
              </div>
            </div>
          </div>
          <div class="projects-popup-list-item-column">
            <div
              class="projects-popup-list-item-adv-unassigned"
              v-html="locale('project_unassignedTasks', { unassignedTasks: project.unassignedTasks })"
            />
          </div>
        </div>
        <div class="projects-popup-list-item-row">
          <div class="projects-popup-list-item-column">
            <div class="projects-popup-list-item-adv-icon">
              <svg-sprite :name="'pensil'" />
            </div>
            <div class="projects-popup-list-item-adv-last">
              {{ locale('project_last_change') }}:
              <strong>{{ getLastUpdateDate(project) }}</strong>
            </div>
          </div>
          <div class="projects-popup-list-item-column">
            <div
              class="projects-popup-list-item-adv-progress"
              v-html="locale('project_progress', { progress: project.progress})"
            />
          </div>
        </div>
        <div class="projects-popup-list-item-line" />
        <div class="projects-popup-list-item-row">
          <div
            class="projects-popup-list-item-column-3 green-color"
            v-html="locale('project_countOfPerSchedule', { count: project.countOfPerSchedule })"
          />
          <div
            class="projects-popup-list-item-column-3 orange-color"
            v-html="locale('project_countOfSlightlyOverdue', { count: project.countOfSlightlyOverdue })"
          />
          <div
            class="projects-popup-list-item-column-3 red-color"
            v-html="locale('project_countOfOverdue', { count: project.countOfOverdue })"
          />
        </div>

        <div class="projects-popup-list-item-progress-line">
          <div
            class="projects-popup-list-item-progress-line-green"
            :style="{width: `${(getSum(project) === 0 ? 1 : project.countOfPerSchedule / getSum(project)) * 100}%`}"
          />
          <div
            class="projects-popup-list-item-progress-line-orange"
            :style="{width: `${project.countOfSlightlyOverdue / getSum(project) * 100}%`}"
          />
          <div
            class="projects-popup-list-item-progress-line-red"
            :style="{width: `${project.countOfOverdue / getSum(project) * 100}%`}"
          />
        </div>
      </div>
    </template>
  </info-popup>
</template>

<script>

import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';
import globalStore from '$$store/main';

export default {
  name: 'PopupInfo',
  props: {
    project: { type: Object, required: true },
    position: { type: Object, required: true },
    showInfo: { type: Boolean, required: true },
    side: { type: String, required: false },
    ownerOnProject: { type: Object, required: false },
  },
  data() {
    return {
      locale: __,
    };
  },

  methods: {
    getLastUpdateDate(project) {
      return `${moment(project.last_update).format(user.dateFormat)}`;
    },
    getSum(project) {
      return project.countOfPerSchedule + project.countOfSlightlyOverdue + project.countOfOverdue;
    },
    getCreatorName(project) {
      if (this.ownerOnProject) {
        return this.ownerOnProject.name;
      }

      const creator = globalStore.getters['resourcesModel/getResourceByUserId'](project.user_id);

      return creator ? creator.name : '';
    },
    getUsersCount(project) {
      const isMultiview = project.type === 'multiview';
      let users = [];

      if (isMultiview) {
        users = _.reduce(project.ganttIDs, (res, ganttID) => {
          const resources = globalStore.getters['resourcesModel/getRealResourcesByGanttId'](ganttID);

          return _.merge(res, resources);
        }, []);
      } else {
        users = globalStore.getters['resourcesModel/getRealResourcesByGanttId'](project.gantt_id);
      }

      return users.length;
    },
    closePopup() {
      this.$emit('closePopup', true);
    },
  },
};
</script>
<style scoped src="../../../less/_projects_popup.less" lang="less"></style>
