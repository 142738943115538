import store from '../../../store/main';
import app from '../../../app';

function onTeamSubscriptionUpdated(event) {
  const isUpdatedFromApp = store.getters['paymentPlans/isUpdatedFromApp'];

  if (event.data.data.type === 'subscriptionTerminated') {
    document.location.reload();
  }

  if (event.data.data.type === 'subscriptionUpdated') {
    webix.message({ type: 'success', text: __('notifications_order_SubscriptionChargeSucceed') });

    if (!isUpdatedFromApp) {
      document.location.reload();
    }
  }

  if (event.data.data.type === 'orderCharged' && !isUpdatedFromApp && !user.subscription.is_trial) {
    document.location.reload();
  }

  if (GT.user.id !== GT.user.team.owner_id) {
    document.location.reload();
  }
}

export default onTeamSubscriptionUpdated;
