<template>
  <div
    :class="[$.toast_cmp, $.sso_toast_cmp]"
    class="gp_autotest_sso_toast"
  >
    <div
      :class="$.message"
      v-html="locales('warning_team_only_sso')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      locales: __,
    };
  },
};
</script>

<style module="$" src="./toasts.less"></style>
