var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "video",
      {
        attrs: {
          autoplay: _vm.autoplay,
          loop: _vm.loop,
          muted: "",
          poster: _vm.posterUrl,
        },
        domProps: { muted: true },
        on: {
          contextmenu: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [_c("source", { attrs: { src: _vm.videoUrl, type: "video/mp4" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }