var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.portfolios_page,
        (!_vm.portfolios.length || !_vm.accessToPortfolios) &&
          _vm.$style.no_portfolios,
      ],
    },
    [
      _c("div", { class: _vm.$style.head_section }, [
        !_vm.portfolios.length || !_vm.accessToPortfolios
          ? _c("div", { class: _vm.$style.welcome_title }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locale("portfolio_page_welcome_title")) +
                  "\n      "
              ),
              !_vm.accessToPortfolios
                ? _c("div", { staticClass: "pricing-title-container" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.locale(_vm.planTitle)) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: _vm.$style.description }, [
          _vm._v(
            "\n      " +
              _vm._s(
                !_vm.portfolios.length
                  ? _vm.locale("portfolio_page_welcome_description")
                  : _vm.locale("portfolio_page_description")
              ) +
              "\n      "
          ),
          !_vm.accessToPortfolios
            ? _c(
                "div",
                { staticClass: "learn_more" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pricing-popup-link",
                      attrs: {
                        href: _vm.locale("portfolio_view_learn_more"),
                        target: "_blank",
                      },
                      on: { click: _vm.linkClick },
                    },
                    [_vm._v(_vm._s(_vm.locale("help_read_more")))]
                  ),
                  _vm._v(" "),
                  _c("svg-sprite", {
                    attrs: {
                      type: "regular",
                      name: "arrow-right-2",
                      size: 16,
                      color: "#1565C0",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.portfolios.length && _vm.accessToPortfolios
          ? _c(
              "div",
              { class: _vm.$style.create_button },
              [
                _c("vgp-button", {
                  class: _vm.$style.new_button,
                  attrs: {
                    label: _vm.locale("portfolio_page_open_portfolio"),
                    type: "primary",
                    small: "",
                  },
                  on: { onClick: _vm.newPortfolioButtonHandler },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.accessToPortfolios && _vm.isOwner
          ? _c(
              "div",
              { class: _vm.$style.upgrade_button },
              [
                _vm.isOwner
                  ? _c("upgrade-btn", { attrs: { feature: "portfolio_view" } })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.portfolios.length || !_vm.accessToPortfolios
        ? _c(
            "div",
            { class: _vm.$style.demo_video },
            [
              _c("video-component", {
                attrs: {
                  "poster-url":
                    "https://cdn.ganttpro.com/statics/media/images/portfolio/demo_poster.png",
                  "video-url":
                    "https://cdn.ganttpro.com/statics/media/videos/portfolio/demo.mp4",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.portfolios.length && _vm.accessToPortfolios
        ? _c(
            "div",
            { class: _vm.$style.portfolios_list },
            [
              _c("card", {
                attrs: {
                  type: "button",
                  item: { name: _vm.locale("portfolio_page_new_button_label") },
                },
                on: { itemClick: _vm.newPortfolioButtonHandler },
              }),
              _vm._v(" "),
              _vm._l(_vm.portfolios, function (pf) {
                return _c("card", {
                  key: pf.id,
                  attrs: {
                    type: "portfolio",
                    item: pf,
                    metadata: [
                      _vm.getLastUpdateDate(pf),
                      _vm.getProjectsCount(pf),
                    ],
                  },
                  on: {
                    toggleFavorite: _vm.toggleFavorite,
                    updateName: _vm.updateName,
                    itemClick: function () {
                      return _vm.goToPortfolio(pf.id)
                    },
                    favClick: function () {
                      return _vm.toggleFavorite(pf.id)
                    },
                    startDelete: function () {
                      return _vm.onDelete(pf)
                    },
                  },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("core-popup", {
        attrs: {
          show: _vm.showCreate,
          title: _vm.locale("portfolio_page_new_project_portfolio"),
          "close-icon": true,
          "buttons-switch": true,
          "ok-button-title": _vm.locale("common_create"),
          "cancel-button-title": _vm.locale("common_cancel"),
          "disabled-cancel":
            !_vm.newPortfolioName || !_vm.newPortfolioProjects.length,
          "body-style": "portfolio_modal",
          "footer-style": "flex-end",
        },
        on: {
          onClickOk: _vm.createPortfolio,
          onClickCancel: _vm.toggleCreateModal,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("vgp-label-slot", {
                  attrs: {
                    label: _vm.locale("portfolio_page_name_of_portfolio"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("vgp-input", {
                            attrs: {
                              placeholder: _vm.locale(
                                "portfolio_page_enter_portfolio_name"
                              ),
                              value: _vm.newPortfolioName,
                              small: "",
                              "initial-focus": true,
                            },
                            on: { input: _vm.setNewPortfolioName },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("vgp-label-slot", {
                  attrs: { label: _vm.locale("common_project_tab") },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("dropdown-select", {
                            attrs: {
                              "drop-list": _vm.allProjects,
                              selected: _vm.newPortfolioProjects,
                              "use-select-all": false,
                              "use-search": true,
                              placeholder: _vm.locale(
                                "create_resource_projects_drop_placeholder"
                              ),
                              "popup-max-height": 217,
                            },
                            on: { change: _vm.projectsSelected },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("confirm-popup", {
        attrs: {
          show: _vm.deletePortfolioObj,
          "popup-width": 450,
          "close-icon": true,
          title: _vm.locale("project_list_delete_multiview"),
          text: _vm.deleteConfirmMessage,
          "confirm-message-class": "multiline",
          "cancel-button": {
            title: _vm.locale("common_cancel"),
            type: "secondary",
          },
          "ok-button": {
            title: _vm.locale("common_delete"),
            type: "destructive",
          },
        },
        on: {
          onClickCancel: _vm.closeModal,
          onClickOk: function ($event) {
            return _vm.deletePortfolio()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }