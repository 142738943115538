var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: _vm.isShow,
      title: _vm.locales("new_all_attachments"),
      "close-icon": true,
    },
    on: { onClickCancel: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                class: _vm.$.attachments_popup,
                style: !_vm.preparedAttachments ? { height: "auto" } : {},
              },
              [
                _c("div", { class: _vm.$.left_side }, [
                  _c("div", { class: _vm.$.title }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("attachments_projects_title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.preparedAttachments
                    ? _c(
                        "div",
                        { class: _vm.$.body },
                        [
                          _c("vgp-input", {
                            attrs: {
                              value: _vm.searchValue,
                              small: true,
                              placeholder: _vm.locales(
                                "attachments_search_placeholder"
                              ),
                              border: true,
                              "icons-left": [{ name: "search" }],
                            },
                            model: {
                              value: _vm.searchValue,
                              callback: function ($$v) {
                                _vm.searchValue = $$v
                              },
                              expression: "searchValue",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { class: [_vm.$.project_list, "gantt_scroll"] },
                            _vm._l(_vm.projects, function (project, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class: [
                                    _vm.$.list_item,
                                    project.projectId === _vm.activeProjectId
                                      ? _vm.$.active
                                      : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveProject(
                                        project.projectId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.$.project_title },
                                    [
                                      _c("svg-sprite", {
                                        key: index,
                                        attrs: {
                                          name: project.isJira
                                            ? "jira"
                                            : "project",
                                          size: 24,
                                          color:
                                            project.projectId ===
                                            _vm.activeProjectId
                                              ? "#ffffff"
                                              : "#B2B2B2",
                                          type: "regular",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { class: _vm.$.name }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(project.name) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$.right_side }, [
                  _c("div", { class: _vm.$.title }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.locales("attachments_tasks_title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.preparedAttachments
                    ? _c(
                        "div",
                        { class: [_vm.$.body, "gantt_scroll"] },
                        [
                          _vm.searchValue !== "" && !_vm.projects.length
                            ? _c("div", { class: _vm.$.stub_body }, [
                                _c("div", {
                                  class: _vm.$.stub_img,
                                  domProps: {
                                    innerHTML: _vm._s(_vm.searchStub),
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { class: _vm.$.stub_desc }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.locales(
                                          "attachments_stub_message_if_no_search_result"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            : !_vm.activeProjectId
                            ? _c("div", { class: _vm.$.stub_body }, [
                                _c("div", {
                                  class: _vm.$.stub_img,
                                  domProps: {
                                    innerHTML: _vm._s(_vm.attachmentsStub),
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { class: _vm.$.stub_desc }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.locales(
                                          "attachments_stub_message_if_project_dont_open"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            : _vm._l(
                                _vm.projectTasks,
                                function (attachments, taskId) {
                                  return _c("attachments-accordion", {
                                    key: taskId,
                                    attrs: {
                                      attachments: attachments,
                                      "task-name": _vm.tasksByIds[taskId]
                                        ? _vm.tasksByIds[taskId].text
                                        : "",
                                      "task-id": taskId,
                                      "has-rights": _vm.hasRightToAttachments,
                                    },
                                    on: {
                                      "open-task": _vm.openTask,
                                      "click-file": _vm.onClickFile,
                                      "files-upload": _vm.handleFilesUpload,
                                    },
                                  })
                                }
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            !_vm.preparedAttachments
              ? _c("div", { class: _vm.$.popup_stub }, [
                  _c("div", {
                    class: _vm.$.stub_img,
                    domProps: { innerHTML: _vm._s(_vm.attachmentsStub) },
                  }),
                  _vm._v(" "),
                  _c("div", { class: _vm.$.stub_desc }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.locales(
                            "attachments_stub_message_if_have_no_attachments"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }