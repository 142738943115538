import axios from 'axios';
import { saveAs } from 'file-saver';
import JsZip from 'jszip';

const getBlobAndFileName = async attachment => {
  let attachmentLink = attachment.link;

  // switch (attachment.attachmentTypeKey) {
  // case 'oneDrive': {
  //   // TODO replace
  //   // `${app.config.site.root()}/api/onedrive/downloadUrl`
  //   const { data: fileInfo } = await axios.get('https://app.ganttpro.io/api/onedrive/downloadUrl', {
  //     params: { id: attachment.id },
  //   });
  //
  //   attachmentLink = fileInfo.downloadUrl;
  //   break;
  // }
  // }
  const { data: blob } = await axios.get(attachmentLink, { responseType: 'blob' });

  return {
    blob,
    fileName: attachment.name,
  };
};

const downloadSingle = async attachment => {
  const { blob, fileName } = await getBlobAndFileName(attachment);

  return saveAs(blob, fileName);
};

const downloadMultipleAsZip = async attachments => {
  const blobsWithNames = await Promise.all(attachments.map(getBlobAndFileName));

  const zip = JsZip();

  blobsWithNames.forEach(({ blob, fileName }) => {
    zip.file(fileName, blob);
  });
  const zipFile = await zip.generateAsync({ type: 'blob' });
  const currentDate = new Date().getTime();
  const fileName = `GP_${currentDate}.zip`;

  return saveAs(zipFile, fileName);
};

export {
  downloadSingle,
  downloadMultipleAsZip,
};
