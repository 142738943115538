import app from "./../../app";

var outputObject = {
  show: function (template, data, options, confirmId) {
    if (!options) options = {};
    webix.message({
      type: (options.messageType || "info"),
      text: template,
      expire: options.expire || 8000
    });

    if (confirmId) {
      app.trigger("notification:confirm", confirmId);
    }
  }
};

export default outputObject;
