var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("animated-popup", {
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: ["popup-container"] }, [
              _c(
                "div",
                { staticClass: "allProjects-header" },
                [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      {
                        class: ["icon"],
                        attrs: {
                          id: "gp_autotest_sidebar_projects_close_button",
                        },
                        on: { click: _vm.sideBarBack },
                      },
                      [
                        _c("svg-sprite", {
                          attrs: { name: "arrow-left", type: "bold" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locale("all_projects_header_title")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.itemsForContext.length
                    ? _c(
                        "div",
                        {
                          ref: "addProjectBtn",
                          staticClass: "allProjects-header_button",
                        },
                        [
                          _c("vgp-button", {
                            attrs: {
                              id: "gp_autotest_sidebar_projects_add_project_button",
                              label: _vm.locale("create_new_project"),
                              type: "primary-border",
                              icon: { name: "plus-1", type: "bold", size: 20 },
                              "icon-side": "left",
                              small: true,
                            },
                            on: { onClick: _vm.addProjectClickBtn },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.addProjectClick
                    ? _c("context-menu", {
                        attrs: {
                          items: _vm.itemsForContext,
                          position: _vm.positionContext,
                        },
                        on: {
                          selectItemMenu: function ($event) {
                            return _vm.onSelectContextItem($event)
                          },
                          onClickOutside: _vm.onClickOutside,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "projects-popup-header-search-layout" },
                [
                  _c(
                    "div",
                    { staticClass: "projects-popup-header-search-input" },
                    [
                      _c("vgp-input", {
                        class: "search-input",
                        style: { outline: "#fff" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.locale(
                            "search_projects_placeholder"
                          ),
                          "icons-left": [{ name: "search" }],
                          small: true,
                          "select-by-focus": false,
                        },
                        model: {
                          value: _vm.searchStr,
                          callback: function ($$v) {
                            _vm.searchStr = $$v
                          },
                          expression: "searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "projects-popup-sort" },
                _vm._l(_vm.sortButtons, function (button, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "projects-popup-sort-button",
                        _vm.activeType === button
                          ? "active " + (_vm.sortDesc === 0 ? "asc" : "desc")
                          : "",
                      ],
                      attrs: { "data-type": button },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: ["projects-popup-sort-item"],
                          on: {
                            click: function ($event) {
                              _vm.clickSortedButtons(
                                _vm.sortedProjects,
                                button,
                                _vm.activeType === button
                                  ? _vm.sortDesc === 0
                                    ? 1
                                    : 0
                                  : 0
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "projects-popup-sort-button-text no_selection",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.locale(button + "_sort_button")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "projects-popup-sort-button-icon" },
                            [
                              _c("svg-sprite", {
                                attrs: { name: "priority", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.sortedProjects
                ? _c(
                    "div",
                    { staticClass: "projects-list gantt_scroll" },
                    [
                      _vm._l(_vm.sortedProjects, function (project) {
                        return _c(
                          "div",
                          {
                            key: project.id,
                            attrs: {
                              "data-id": project.id,
                              "data-type": project.type,
                              "data-gantt_id": project.gantt_id,
                            },
                          },
                          [
                            _c("project-item", {
                              attrs: {
                                project: project,
                                "multi-select-mode": _vm.multiSelectMode,
                                "edit-multiview-mode": _vm.editMultiviewMode,
                                "edit-multiview-gantt-ids":
                                  _vm.editMultiviewGanttIds,
                                "multi-select-mode-ids": _vm.multiSelectModeIds,
                                "project-open": _vm.projectOpen,
                                "owner-on-project": _vm.getOwnerOnProject(
                                  project.gantt_id
                                ),
                                "is-active-user": _vm.isActiveUser,
                                "user-rights": _vm.userRights,
                                "is-jira-project": _vm.isJiraProject(
                                  project.gantt_id
                                ),
                                "items-for-context-menu":
                                  _vm.getItemsForContextMenu(project),
                                "status-data": _vm.statusData(project),
                                "has-right-to-change-status":
                                  _vm.hasRightToChangeStatus(project),
                                "has-right-to-status-settings":
                                  _vm.hasRightToStatusSettings,
                                "open-status": _vm.openStatus,
                              },
                              on: {
                                currentProjectInfo: function ($event) {
                                  _vm.currentProjectInfo = $event
                                },
                                positionInfo: function ($event) {
                                  _vm.positionInfo = $event
                                },
                                positionContext: function ($event) {
                                  _vm.positionContext = $event
                                },
                                currentProjectContext: function ($event) {
                                  _vm.currentProjectContext = $event
                                },
                                currentProjectForStatus: function ($event) {
                                  _vm.currentProjectForStatus = $event
                                },
                                positionSelect: function ($event) {
                                  _vm.positionSelect = $event
                                },
                                openStatus: function ($event) {
                                  _vm.openStatus = $event
                                },
                                multiSelectMode: _vm.toggleMultiSelectMode,
                                checkboxHandler: _vm.checkboxHandler,
                                onSelectItemMenu: _vm.onSelectItemMenu,
                                favProjectClick: _vm.favProjectClick,
                                projectClick: _vm.projectClick,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm.archivedProjects.length &&
                      !_vm.multiSelectMode &&
                      !_vm.editMultiviewMode
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "archive-button-wrapper" },
                              [
                                _c("vgp-button", {
                                  attrs: {
                                    type: "secondary",
                                    label: _vm.locale(
                                      _vm.getLabelForArchiveButton
                                    ),
                                    width: "454px",
                                  },
                                  on: { onClick: _vm.archivedButtonClick },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.showArchivedProjects
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.archivedProjects,
                                    function (project) {
                                      return _c(
                                        "div",
                                        {
                                          key: project.id,
                                          attrs: {
                                            "data-archive": true,
                                            "data-id": project.id,
                                            "data-type": project.type,
                                            "data-gantt_id": project.gantt_id,
                                          },
                                        },
                                        [
                                          _c("project-item", {
                                            attrs: {
                                              project: project,
                                              archived: true,
                                              "project-open": _vm.projectOpen,
                                              "items-for-context-menu":
                                                _vm.getItemsForContextMenu(
                                                  project
                                                ),
                                              "owner-on-project":
                                                _vm.getOwnerOnProject(
                                                  project.gantt_id
                                                ),
                                              "is-active-user":
                                                _vm.isActiveUser,
                                              "user-rights": _vm.userRights,
                                              "status-data":
                                                _vm.statusData(project),
                                            },
                                            on: {
                                              positionContext: function (
                                                $event
                                              ) {
                                                _vm.positionContext = $event
                                              },
                                              currentProjectContext: function (
                                                $event
                                              ) {
                                                _vm.currentProjectContext =
                                                  $event
                                              },
                                              projectClick: _vm.projectClick,
                                              onSelectItemMenu:
                                                _vm.onSelectItemMenu,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.sortedProjects.length &&
                        !_vm.archivedProjects.length,
                      expression:
                        "!sortedProjects.length && !archivedProjects.length",
                    },
                  ],
                  staticClass: "no-data--wrap",
                },
                [
                  [
                    _c("div", { staticClass: "no-data noFiltredProjects" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "noFiltredProjects-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.locale("project_list_no_filtred")) +
                          "\n          "
                      ),
                    ]),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.multiSelectMode || _vm.editMultiviewMode,
                      expression: "multiSelectMode || editMultiviewMode",
                    },
                  ],
                  staticClass: "button-wrapper",
                },
                [
                  _c("tooltip", {
                    staticClass: "multiselect-btn",
                    attrs: {
                      content: _vm.multiSelectButton
                        ? _vm.locale("create_multiview_button_tooltip")
                        : "",
                      hide: !_vm.multiSelectButton,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-button", {
                              attrs: {
                                label: _vm.locale(
                                  _vm.getLabelForMultiSelectButton
                                ),
                                type: "primary",
                                width: "454px",
                                disabled: _vm.multiSelectButton,
                              },
                              on: { onClick: _vm.createOrUpdateMultiview },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.currentProjectInfo
              ? _c("popup-info", {
                  attrs: {
                    project: _vm.currentProjectInfo,
                    position: _vm.positionInfo,
                    "owner-on-project": _vm.getOwnerOnProject(
                      _vm.currentProjectInfo.gantt_id
                    ),
                    side: "right",
                  },
                  on: { closePopup: _vm.closePopup },
                  nativeOn: {
                    mouseover: function ($event) {
                      _vm.currentProjectInfo = null
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.currentProjectContext
              ? _c("context-menu", {
                  attrs: {
                    items: _vm.getItemsForContextMenu(
                      _vm.currentProjectContext
                    ),
                    position: _vm.positionContext,
                  },
                  on: {
                    selectItemMenu: function ($event) {
                      return _vm.onSelectItemMenu(
                        $event,
                        _vm.currentProjectContext
                      )
                    },
                    closePopup: _vm.closeContextPopup,
                    scroll: function ($event) {
                      _vm.scrollContext = $event
                    },
                    onClickOutside: _vm.onClickOutsideContext,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.openStatus
              ? _c("status-select-dropdown", {
                  style: { position: "fixed" },
                  attrs: {
                    "status-data": _vm.statusData(_vm.currentProjectForStatus),
                    position: _vm.positionSelect,
                    "project-config": _vm.currentProjectForStatus,
                    "is-open": _vm.openStatus,
                    "has-right-to-change-status": _vm.hasRightToChangeStatus(
                      _vm.currentProjectForStatus
                    ),
                    "has-right-to-status-settings":
                      _vm.hasRightToStatusSettings,
                  },
                  on: {
                    selectStatus: _vm.onChangeSelect,
                    closeStatus: _vm.closeStatusSelect,
                    onClickOutside: _vm.onClickOutsideStatus,
                    closeProjectsList: _vm.sideBarBack,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("core-confirm-popup", {
              attrs: {
                show: !!_vm.deleteInfoData,
                "popup-width": 440,
                "close-icon": true,
                title: _vm.isMultiviewDelete
                  ? _vm.locale("project_list_delete_multiview")
                  : _vm.locale("project_list_delete_project"),
                text: _vm.isMultiviewDelete
                  ? _vm.locale("settings_msg_delete_multiview_confirm_text")
                  : _vm.locale("project_delete_confirm_text", {
                      projectName: _vm.deleteInfoData
                        ? _vm.deleteInfoData.name
                        : "",
                    }),
                "confirm-string": _vm.isMultiviewDelete
                  ? ""
                  : _vm.deleteInfoData
                  ? _vm.deleteInfoData.name
                  : "",
                "confirm-string-input-placeholder": _vm.locale(
                  "delete_project_confirm_string_placeholder"
                ),
                "ok-button": {
                  title: _vm.locale("common_delete"),
                  type: "destructive",
                },
                "cancel-button": {
                  title: _vm.locale("common_cancel"),
                  type: "secondary",
                },
              },
              on: {
                onClickOk: function ($event) {
                  return _vm.onCloseDeletePopup("ok")
                },
                onClickCancel: _vm.onCloseDeletePopup,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }