<template>
  <v-row>
    <v-col
      md="8"
      sm="8"
    >
      <v-row align-content-md="flex-start">
        <v-col
          md="4"
          sm="4"
        >
          <vgp-label-slot :label="locales('share_popup_public_url_list_header_description')">
            <template #body>
              <vgp-input
                v-model="title"
                :placeholder="getDateNow"
                :class="['public-url-form-input']"
                :small="true"
              />
            </template>
          </vgp-label-slot>
        </v-col>
        <v-col
          md="4"
          sm="4"
        >
          <vgp-label-slot :label="locales('common_filter')">
            <template #body>
              <vgp-dropdown-select
                :key="filterDropdownKey"
                :highlight-active-state="false"
                :show-selected-first="true"
                :show-reset-btn="false"
                :selected="options.find(opt => +opt.value === +filter)"
                :drop-list="options"
                item-template="text"
                :multiselect="false"
                track-by-text="title"
                :class="['public-url-form-input-select', 'dropdown']"
                track-by="value"
                @selected="changeSelect"
              />
            </template>
          </vgp-label-slot>
        </v-col>
        <v-col
          md="4"
          sm="4"
        >
          <vgp-label-slot :label="locales('field_password')">
            <template #body>
              <vgp-input-switch
                v-model="password"
                type="text"
                class="public-url-form-input-password"
                placeholder="—"
              />
            </template>
          </vgp-label-slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      md="4"
      sm="4"
    >
      <v-row>
        <v-col
          md="5"
          sm="5"
        >
          <div class="public-url-form__actions">
            <tooltip
              :content="locales('share_popup_public_url_filter_checkbox_tooltip')"
            >
              <template #body>
                <vgp-checkbox
                  v-model="snapshot"
                  :value="snapshot"
                  :label="locales('share_popup_public_url_list_header_snapshot')"
                  label-position="right"
                  style="position:relative; top:8px; width: 100%"
                />
              </template>
            </tooltip>
          </div>
        </v-col>
        <v-col
          md="7"
          sm="7"
        >
          <div class="public-url-form__actions">
            <vgp-button
              type="primary"
              :disabled="isLoading"
              :small="true"
              :width="'140px'"
              :label="locales('share_popup_public_url_filter_btn')"
              @onClick="submitForm"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import { api } from '$$store/api';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import VgpInputSwitch from '$$vueCmp/vInput/vgpInputSwitch.vue';
import VgpDropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import ganttViewModel from '../../models/ganttViewModel';
import userProjectConfigs from '../../models/userProjectConfigs';
import filtersModel from '../../models/filters';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';

export default {
  name: 'PublicUrlsForm',
  components: {
    VgpInputSwitch,
    VgpLabelSlot,
    VgpDropdownSelect,
    Tooltip,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      title: '',
      filter: -1,
      password: '',
      snapshot: false,
      options: [
        {
          title: __('share_popup_public_url_filter_select'),
          value: -1,
        },
        {
          title: __('filter_current_filter'),
          value: 0,
        },
      ],
      filterDropdownKey: 0,
    };
  },

  computed: {
    getDateNow() {
      return window.moment(new Date()).format('MMM D, YYYY');
    },
  },
  mounted() {
    api.get('/filters').then(response => {
      const viewData = ganttViewModel.getActiveViewData();

      const newItems = [];

      response.data.forEach(item => {
        if (!ganttViewModel.isMultiview() && viewData.gantt_id === item.project_id) {
          newItems.push({
            title: item.name,
            value: item.id,
          });
        } if (ganttViewModel.isMultiview() && viewData.id === item.multiview_id) {
          newItems.push({
            title: item.name,
            value: item.id,
          });
        }
      });

      if (newItems.length) {
        this.options.push(...newItems);
        this.filterDropdownKey++;
      }
    });
  },
  methods: {

    submitForm() {
      const data = this.prepareData();

      api.post('/shareUrl', {
        ...data,
      }).then(response => {
        this.title = null;
        this.filter = -1;
        this.snapshot = false;
        this.password = null;
        this.$emit('onAddItem', response.data);
      });
    },

    changeSelect(response) {
      this.filter = response[0]?.value;
    },

    getSelectedText(id) {
      let text = '';

      this.options.forEach(item => {
        if (item.value === id) {
          text = item.title;
        }
      });

      return text;
    },

    prepareData() {
      const viewData = ganttViewModel.getActiveViewData();
      const currentGanttID = viewData.id;
      const filterValue = this.filter;
      const showAvatar = viewData.config.show_resource_avatar;
      let userConfigData;

      if (!ganttViewModel.isMultiview()) {
        userConfigData = userProjectConfigs.getUserConfigByGanttId(currentGanttID);
      } else {
        const fieldsForLink = [
          'initGridColumns',
          'durationData',
          'current_day_marker',
          'resource_loading_type',
          'right_side_text',
          'highlight_critical_path',
          'userSkin',
          'view_mode',
          'show_resource_avatar',
        ];

        if (this.snapshot) {
          fieldsForLink.push('order');
        }

        userConfigData = {
          id: viewData.id,
          userConfig: _.pick(viewData.config, fieldsForLink),
        };
      }

      let options = null;

      if (filterValue === 0) {
        const tasks = filtersModel.getFilterDataByActiveProject(viewData.id);

        if (tasks) {
          options = {};
          options.filterOptions = tasks.options;
        }
      }

      const newData = {
        gantt_id: ganttViewModel.isMultiview() ? null : currentGanttID,
        title: this.title !== '' && this.title !== null ? this.title : window.moment(new Date()).format('MMM D, YYYY'),
        is_snapshot: this.snapshot ? 1 : 0,
        password: this.password !== '' ? this.password : null,
        filter_id: this.filter > 0 ? this.filter : null,
        user_project_config: userConfigData,
        options: options !== null ? JSON.stringify(options) : null,
      };

      if (ganttViewModel.isMultiview()) {
        newData.multiview_id = viewData.id;
        newData.ganttIDs = viewData.ganttIDs;

        if (newData.is_snapshot) {
          userConfigData.ganttIDs = newData.ganttIDs;
        }
      } else {
        newData.gantt_id = viewData.id;
      }
      if (userConfigData && (showAvatar !== userConfigData.show_resource_avatar)) {
        newData.user_project_config.show_resource_avatar = Boolean(showAvatar);
      }

      newData.user_project_config = JSON.stringify(newData.user_project_config);

      return { ...newData };
    },
  },

};
</script>

<style lang="less" scoped>
.public-url-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.public-url-form-input-select {
    margin-bottom: 0px;
}

.public-url-form-input-password {
    -webkit-text-security: disc;
    text-security: disc;
    line-height: 30px;
}

.public-url-form__actions {
    display: flex;
    align-items: center;
    margin-top: 27px;
    @media all and(max-width: 600px) {
        margin-top: 0px;
    }
    button {
        width: 100% !important;
        max-height: 32px;
        min-width: 100% !important;
    }
}
</style>
