import app from "./../../../app";
import _ from "./../../../libs/lodash";

import jiraModel from "./../../../models/jira";
import projectModel from "./../../../models/projects";

import projectComponent from "./../../../components/Project";
import routerHelper from "./../../../helpers/router";

import svg from "./../../../helpers/svgToURI";
import status from "./../../../helpers/status";

import icon_close from "./../../../svg/close.svg";
import icon_task from "./../../../svg/task.svg";
import icon_milestone from "./../../../svg/milestone.svg";
import icon_highest from "./../../../svg/highest.svg";
import icon_high from "./../../../svg/high.svg";
import icon_medium from "./../../../svg/medium.svg";
import icon_low from "./../../../svg/low.svg";
import icon_lowest from "./../../../svg/lowest.svg";
import icon_resource from "./../../../svg/default/default-ava.svg";
import statusHelper from "../../../helpers/status";
import globalStore from "../../../store/main";

const __ = window.__;

var getHashColorByName = function (colorName) {
  var colors = {
    "yellow": "#ff9921",
    "blue-gray": "#55BED4",
    "green": "#14bb4c",
    "default": "#55BED4"
  };

  return (colors[colorName] || colors['default']);
};

var innerViews = {
  jiraProjectLayoutBody: {
    id: 'jiraProjectLayoutBody',
    css: 'jiraProjectLayoutBody'
  },
  jiraProjectName: {
    view: "richselectWithoutPoint",
    id: "jiraProjectName",
    name: "jiraProjectName",
    label: __('jira_new_project_name'),
    options: [],
    inputHeight: 36,
    height: 72,
    labelWidth: 120,
    on: {
      'onChange': function (id) {
        if (id && id !== "") {
          jiraModel.getJiraDataForFiltersByProjectKey($$("jiraProjectName").getValue())
            .then(function (resultRow) {
              var data = resultRow.json(),
                $resources = data.resources,
                $statuses = data.statuses,
                $priorities = data.priorities,
                $$statuses = $$(innerViews.jiraFilterOptionStatusDropdown.id),
                $$priorities = $$(innerViews.jiraFilterOptionPriorityDropdown.id),
                $$assignee = $$(innerViews.jiraFilterOptionAssigneeDropdown.id),
                resources = [{id: "-1", value: __("common_empty")}].concat($resources.map(function (res) {
                  return {
                    id: res.emailAddress,
                    picture: res.avatarUrls["16x16"],
                    value: res.displayName,
                  };
                }))
                  .map(function (item) {
                    item.picture = item.picture || svg(icon_resource);
                    return item;
                  }),
                statuses = $statuses.map(function (it) {
                  return {
                    id: it.id,
                    value: it.name,
                    color: getHashColorByName(it.statusCategory.colorName)
                  };
                }),
                priorities = $priorities.map(function (it) {
                  return {
                    id: it.id,
                    value: it.name,
                    picture: it.iconUrl || (GT.cdn + '/imgs/medium.svg'),
                  };
                });

              $$assignee.define('options', {data: resources, view: "iconsuggest"});
              $$priorities.define('options', {data: priorities, view: "iconsuggest"});
              $$statuses.define('options', {data: statuses, view: "tagsuggest"});
              $$assignee.refresh();
              $$statuses.refresh();
              $$priorities.refresh();
            });

          $$('buttonJiraImportId').enable();
          $$('jiraFilterOptionPriorityDropdown').enable();
          $$('jiraFilterOptionStatusDropdown').enable();
          $$('jiraFilterOptionAssigneeDropdown').enable();
          $$('jiraFilterOptionCreationDateStartPicker').enable();
          $$('jiraFilterOptionCreationDateEndPicker').enable();
        }
      }
    }
  },
  jiraFilterOptionTaskTypeDropdown: {
    view: "multiselectWithoutPoint",
    inputHeight: 48,
    height: 72,
    id: "jiraFilterOptionTaskTypeDropdown",
    labelAlign: 'top',
    labelPosition: 'top',
    label: __("filter_option_task_type"),
    width: 280,
    placeholder: __("filter_task_type_any"),
    css: "icon_multiselect",
    suggestCss: "jiraFilterOption",
    options: {
      view: "iconsuggest",
      data: [{
        id: 'task',
        value: __("filter_task_type_task"),
        picture: svg(icon_task)
      }, {
        id: 'milestone',
        value: __("filter_task_type_milestone"),
        picture: svg(icon_milestone)
      },]
    },
  },
  jiraFilterOptionStatusDropdown: {
    id: "jiraFilterOptionStatusDropdown",
    view: "multiselectWithoutPoint",
    inputHeight: 36,
    height: 72,
    disabled: true,
    labelAlign: 'top',
    labelPosition: 'top',
    label: __("filter_option_status_type"),
    width: 280,
    placeholder: __("filter_status_any"),
    css: "tag_multiselect",
    suggestCss: "jiraFilterOption",
    options: {
      view: "tagsuggest",
      css: "tag_multiselect",
      data: [{
        id: '1',
        value: __("filter_status_open"),
        color: '#55BED4'
      }, {
        id: '2',
        value: __("filter_status_in_progress"),
        color: '#ff9921'
      }, {
        id: '3',
        value: __("filter_status_done"),
        color: '#14bb4c'
      }]
    },
  },
  jiraFilterOptionAssigneeDropdown: {
    id: "jiraFilterOptionAssigneeDropdown",
    view: "multiselectWithoutPoint",
    inputHeight: 36,
    height: 72,
    labelAlign: 'top',
    disabled: true,
    labelPosition: 'top',
    label: __("filter_option_assignee"),
    placeholder: __("filter_any_resource"),
    css: "icon_multiselect",
    suggestCss: "jiraFilterOption",
    options: {
      view: "iconsuggest",
      data: []
    },
  },
  jiraFilterOptionPriorityDropdown: {
    id: "jiraFilterOptionPriorityDropdown",
    view: "multiselectWithoutPoint",
    inputHeight: 36,
    height: 72,
    labelAlign: 'top',
    disabled: true,
    labelPosition: 'top',
    label: __("filter_option_task_priority"),
    placeholder: __("filter_priority_any"),
    width: 280,
    css: "icon_multiselect",
    suggestCss: "jiraFilterOption",
    options: {
      view: "iconsuggest",
      data: [
        {id: '5', value: __("filter_priority_highest"), picture: svg(icon_highest)},
        {id: '6', value: __("filter_priority_high"), picture: svg(icon_high)},
        {id: '7', value: __("filter_priority_medium"), picture: svg(icon_medium)},
        {id: '8', value: __("filter_priority_low"), picture: svg(icon_low)},
        {id: '9', value: __("filter_priority_lowest"), picture: svg(icon_lowest)},
      ]
    },
  },
  jiraFilterOptionReporterDropdown: {
    id: "jiraFilterOptionReporterDropdown",
    view: "multiselectWithoutPoint",
    inputHeight: 36,
    height: 72,
    disabled: true,
    labelAlign: 'top',
    labelPosition: 'top',
    label: __("filter_option_reporter"),
    placeholder: __("filter_any_resource"),
    width: 280,
    css: "icon_multiselect",
    suggestCss: "jiraFilterOption",
    options: {view: "iconsuggest"},
  },
  jiraFilterOptionCreationDateStartPicker: {
    view: "datepicker",
    id: "jiraFilterOptionCreationDateStartPicker",
    inputHeight: 36,
    height: 72,
    labelAlign: 'top',
    disabled: true,
    labelPosition: 'top',
    label: __("filter_option_task_creation_date"),
    format: "%d %F %Y",
    placeholder: __("filter_option_task_any_creation_date_begin"),
    width: 265,
    suggestCss: "jiraFilterOptionDate",
  },
  jiraFilterOptionCreationDateEndPicker: {
    view: "datepicker",
    id: "jiraFilterOptionCreationDateEndPicker",
    height: 48,
    inputHeight: 36,
    disabled: true,
    format: "%d %F %Y",
    placeholder: __("filter_option_task_any_creation_date_end"),
    width: 265,
    suggestCss: "jiraFilterOptionDate",
  },
};

var innerSettings = {
  jiraProjects: []
};

var innerHelpers = {
  getHeaderView: function () {
    return "<div class='new-jira-project-header'>" + __('jira_new_project_title') + "</div><div class='window_header_icon'>" + icon_close + "</div>"
  }
};

var innerHandlers = {
  showJiraProjects: function (jiraRawProjects) {
    var jiraProjectName = $$("jiraProjectName");
    var jiraProjectSelectUI = jiraProjectName.getList();
    var jiraProjects = _.map(jiraRawProjects, function (jiraProjectData) {
      return {
        id: jiraProjectData.key,
        value: jiraProjectData.key + " - " + jiraProjectData.name,
        name: jiraProjectData.name
      };
    });

    innerSettings.jiraProjects = jiraProjects;

    jiraProjectSelectUI.clearAll();

    if (!jiraProjects.length) {
      jiraProjectName.setBottomText(__('no_jira_projects_for_select'));
      jiraProjectName.disable();
    } else {
      jiraProjectName.enable();
      jiraProjectName.define('placeholder', __('choose_jira_project'));
      jiraProjectSelectUI.parse(jiraProjects);
    }

  },
  importJiraProject: function () {

  }
};

webix.ui({
  view: "window",
  id: "newJiraProjectPopup",
  css: "newJiraProjectPopup",
  borderless: true,
  // header: false,
  head: false,
  height: 714,
  width: 650,
  position: "center",
  modal: true,
  scroll: false,
  move: false,
  body: {
    rows: [
      {
        id: "newJiraProjectPopupHeaderId",
        css: "newJiraProjectPopupHeaderId",
        view: 'template',
        height: 48,
        borderless: true,
        template: innerHelpers.getHeaderView(),
        onClick: {
          "window_header_icon": function () {
            $$("newJiraProjectPopup").hide();
            app.trigger('checkProjectForView');

            if (!projectModel.getActiveGanttId()) {
              projectModel.initActiveProject();
            }
          }
        }
      },

    ]
  },
  on: {
    'onBeforeShow': function () {
      app.trigger("popup:show", true);
    },
    'onHide': function () {
      app.trigger("popup:hide");
      webix.callEvent("onResize", []);
    },
    'onShow': function () {
      webix.callEvent("onResize", []);
    }
  }
}).hide();

export default {
  init: function () {
    var jiraProjectName = $$("jiraProjectName");
    var jiraProjectNameList = jiraProjectName.getList();

    jiraProjectName.setValue(null);
    $$('buttonJiraImportId').disable();
    $$('jiraFilterOptionPriorityDropdown').disable();
    $$('jiraFilterOptionStatusDropdown').disable();
    $$('jiraFilterOptionAssigneeDropdown').disable();
    $$('jiraFilterOptionCreationDateStartPicker').disable();
    $$('jiraFilterOptionCreationDateEndPicker').disable();
    $$('jiraFilterOptionPriorityDropdown').setValue();
    $$('jiraFilterOptionStatusDropdown').setValue();
    $$('jiraFilterOptionAssigneeDropdown').setValue();
    $$('jiraFilterOptionCreationDateStartPicker').setValue();
    $$('jiraFilterOptionCreationDateEndPicker').setValue();

    jiraProjectNameList && jiraProjectNameList.clearAll();
    jiraModel.getJiraProjects();

    jiraModel.data.attachEvent("getJiraProjects", function (jiraProjects) {
      innerHandlers.showJiraProjects(jiraProjects);
    });

  },
  view: {
    view: 'scrollview',
    id: innerViews.jiraProjectLayoutBody.id,
    css: innerViews.jiraProjectLayoutBody.css,
    borderless: true,
    autoheight: true,
    scroll: 'y',
    body: {
      borderless: true,
      cols: [
        {},
        {
          width: 570,
          rows: [
            {
              id: "newJiraProjectPopupForm",
              view: "form",
              padding: 0,
              borderless: true,
              elements: [
                {
                  view: 'label',
                  label: __('jira_import_project_explain'),
                  css: 'label_text newJiraProjectTitle',
                  inputHeight: 27,
                  height: 64,
                  paddingY: 10
                },
                {
                  height: 72,
                  cols: [
                    innerViews.jiraProjectName,
                  ]
                },
                {
                  height: 72,
                  cols: [
                    innerViews.jiraFilterOptionPriorityDropdown,
                    {width: 10},
                    innerViews.jiraFilterOptionStatusDropdown
                  ]
                },
                {
                  height: 72,
                  cols: [
                    innerViews.jiraFilterOptionAssigneeDropdown,
                  ]
                },
                {
                  height: 72,
                  cols: [
                    innerViews.jiraFilterOptionCreationDateStartPicker,
                    {width: 40, css: 'spacer'},
                    {
                      rows: [
                        {height: 36},
                        innerViews.jiraFilterOptionCreationDateEndPicker,
                      ]
                    },
                  ]
                },
                {
                  height: 72,
                  borderless: true,
                  template: "<div class='faq_link'>" + __("jira_new_project_import_faq_link") + "</div>"
                },
                {
                  view: "button",
                  id: "buttonJiraImportId",
                  css: 'green_btn',
                  height: 48,
                  disabled: true,
                  value: __('jira_new_project_import_button'),
                  click: function () {
                    var projectName = $$("jiraProjectName").getValue();
                    var filterOptions = {
                      taskType: "",
                      status: $$(innerViews.jiraFilterOptionStatusDropdown.id).getValue(),
                      assignee: $$(innerViews.jiraFilterOptionAssigneeDropdown.id).getValue(),
                      priority: $$(innerViews.jiraFilterOptionPriorityDropdown.id).getValue(),
                      reporter: "",
                      startDate: {
                        start: "",
                        end: ""
                      },
                      endDate: {
                        start: "",
                        end: ""
                      },
                      creationDate: {
                        start: $$(innerViews.jiraFilterOptionCreationDateStartPicker.id).getValue(),
                        end: $$(innerViews.jiraFilterOptionCreationDateEndPicker.id).getValue()
                      }
                    };

                    if (!projectName) {
                      webix.message({type: 'error', text: __('jira_new_project_empty_warn_message')});
                      return;
                    }

                    var projectData = _.find(innerSettings.jiraProjects, function (p) {
                      return (p.id === projectName);
                    });

                    projectData.filters = filterOptions;

                    statusHelper.handlers.showMainSpinner(false, 5000);

                    jiraModel.importProjectJira(projectData)
                      .then( function (rawData) {
                        var data = rawData.json();

                        status.handlers.hideMainSpinner();
                        // $$("createProjectLayout").hide();

                        globalStore.dispatch('teamSetting/addProjectStatus', data.ganttId);                        

                        projectComponent.addNewJiraProject(data.ganttId);

                        routerHelper.pushRoute({name: 'app'});
                        userExtAnalytics.log('project_create_done', {
                          type: 'import',
                          'import format': 'jira',
                        });
                      });
                  }
                }
              ],
              elementsConfig: {
                labelPosition: "top"
              }
            }
          ]
        },
        {}
      ]
    },
  }
};
