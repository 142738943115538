import app from '../app';
import projectsModel from '../models/projects';
import userProjectConfigModel from '../models/userProjectConfigs';
import globalStore from '$$store/main';
import customDaysTemplatesModel from '../models/customDayTemplates';
import timeTracking from '../models/timeTracking';
import _ from '../libs/lodash';
import ganttViewModel from '../models/ganttViewModel';
import collaborationManager from './collaboration/index';
import rights from './rights/index';

const collaborationComponent = {};

const actionTypesForLiteRefresh = {
  TIME_TRACKING: [141, 142, 143],
  USER_CUSTOM_COLUMNS: [
    // 121, 122, 123,
    64],
  CUSTOM_COLUMNS: [101],
  REPORTER: [131, 132, 133],
  PROGRESS_CHANGED: [71],
  ASSIGNEE: [51, 52, 53],
  TASK_UPDATE: [61],
  AUTO_SCHEDULING: [72, 73, 74],
  LINK_ACTIONS: [31, 32, 33],
  TASK_CREATE: [63],
  TASK_DELETE: [62],
  TASK_MOVE: [82],
};

collaborationComponent.data = new webix.DataStore();

collaborationComponent.getProjectDataFromServer = function (ganttId) {
  return webix.ajax()
    .get(`/api/collaboration/${ganttId}?${Date.now()}`);
};

collaborationComponent.freezeOpenCloseState = function (ganttId, taskData) {
  const currentTasks = globalStore.getters['tasksModel/getItem'](ganttId)?.tasks;
  const data = taskData.tasks.data;

  _.each(data, nTask => {
    let compareTask = null;

    if (nTask.type === gantt.config.types.project) {
      compareTask = _.find(currentTasks, t => +t.id === +nTask.id);
    }

    if (compareTask && nTask.type === compareTask.type) {
      nTask.open = compareTask.open;
    }
  });

  return taskData;
};

collaborationComponent.manualUpdateModels = function (projectData, responseData, ignoreUpdate, runAutoScheduling) {
  if (responseData.taskData) {
    globalStore.dispatch('tasksModel/addTasksAfterCreateNewProject', {
      ganttId: projectData.gantt_id,
      projectTasksData: collaborationComponent.freezeOpenCloseState(projectData.gantt_id, responseData.taskData),
    });
  }

  if (responseData.customDays) {
    customDaysTemplatesModel.manualAddTemplateToShareProject(responseData.customDays, projectData.gantt_id, 'collaboration');
  }

  if (responseData.resources) {
    globalStore.dispatch('resourcesModel/replaceAllData', responseData.resources);
  }

  if (responseData.projectData) {
    projectData.config = responseData.projectData.config;
    projectData.name = responseData.projectData.name;
    projectData.last_update = responseData.projectData.last_update;
    projectData.config = userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(projectData.gantt_id, projectData.config);
  }

  if (responseData.globalCustomColumnsData) {
    globalStore.commit('columns/setCustomColumns', responseData.globalCustomColumnsData);
  }

  if (responseData.userCustomColumnsData) {
    globalStore.commit('columns/updateProject', {
      projectId: projectData.gantt_id,
      updated: { ...responseData.userCustomColumnsData },
    });
    app.trigger('userCustomColumnsModel:change', projectData.gantt_id, 'update');
  }

  if (responseData.timeTrackingData) {
    timeTracking.manualUpdateItem(responseData.timeTrackingData, projectData.gantt_id, 'collaboration');
  }

  projectsModel.manualLiveUpdate(projectData, ignoreUpdate, runAutoScheduling);
};

collaborationComponent.updateProjectDataAfterImport = function (ganttId) {
  const projectData = projectsModel.getItem(ganttId);

  return collaborationComponent.getProjectDataFromServer(ganttId)
    .then(rawResponseData => {
      const responseData = rawResponseData.json();

      collaborationComponent.manualUpdateModels(projectData, responseData, false, gantt.config.auto_scheduling);

      gantt.blockEvents();
      app.trigger('gantt:reinit');
      gantt.unblockEvents();
    });
};

collaborationComponent.refreshParents = function (taskID) {
  if (gantt.isTaskExists(taskID)) {
    const task = gantt.getTask(taskID);

    gantt.refreshTask(task.id);
    const parentsIDs = collaborationComponent.getParentsIDs(taskID);

    collaborationComponent.refreshTasksByIDs(parentsIDs);
  }
};

collaborationComponent.getParentsIDs = function (taskID) {
  const task = gantt.isTaskExists(taskID) && gantt.getTask(taskID);
  const parentsIDs = [];

  if (task) {
    let pid = gantt.getParent(task);

    while (pid) {
      parentsIDs.push(pid);
      const parent = gantt.isTaskExists(pid) && gantt.getTask(pid);

      pid = parent && gantt.getParent(parent);
    }
  }

  return parentsIDs;
};

collaborationComponent.refreshTasksByIDs = function (taskIDs) {
  _.each(taskIDs, id => {
    gantt.isTaskExists(id) && gantt.refreshTask(id);
  });
};

collaborationComponent.showCollaborationMessage = function (eventData, historyEventData) {
  if (historyEventData) {
    historyEventData.userPic = eventData.userPic;
    collaborationComponent.data.callEvent('collaboration:event', [historyEventData]);
  }
};

collaborationComponent.hasRightsToShowCollaborationMessage = function (ganttId, eventData) {
  const accessToAllTask = rights.project.hasRight(ganttId, 'all_tasks');

  const currentResourceOnTask = taskId => globalStore.getters['resourcesModel/isUserAssignedToTask'](ganttId, taskId);

  // update task
  if (!accessToAllTask && eventData?.historyEventData?.data?.taskId) {
    const resourceOnTask = currentResourceOnTask(+eventData.historyEventData.data.taskId);

    if (!resourceOnTask) {
      return false;
    }
  }

  // 63 - create task
  if (!accessToAllTask && eventData.historyEventData.actionType === 63) {
    return false;
  }

  // 51 - assigne resource to task
  if (!accessToAllTask && eventData.historyEventData.actionType === 51 && +eventData.entityData.data.task_id) {
    const taskId = eventData.entityData.data.task_id;
    const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](user.id)?.id;
    const resourceOnTask = currentResourceOnTask(+taskId);

    if (eventData.entityData.data.taskResources?.length) {
      const addedResource = eventData.entityData.data.taskResources.find(o => +o.resource_id === +resourceId && o.action === 'insert');

      if (addedResource) {
        return true;
      }
    }

    if (!resourceOnTask) {
      return false;
    }
  }

  // 71 - update progress
  if (!accessToAllTask && eventData.historyEventData.actionType === 71 && +eventData.historyEventData?.data?.taskNames?.id) {
    const taskId = +eventData.historyEventData.data.taskNames.id;
    const resourceOnTask = currentResourceOnTask(+taskId);

    if (!resourceOnTask) {
      return false;
    }
  }

  // 101 - update status
  if (!accessToAllTask && eventData.historyEventData.actionType === 101 && +eventData.entityData?.data?.task_id) {
    const taskId = +eventData.entityData.data.task_id;
    const resourceOnTask = currentResourceOnTask(+taskId);

    if (!resourceOnTask) {
      return false;
    }
  }

  // 33 - link create/delete/update
  if (!accessToAllTask && (eventData.historyEventData.actionType === 33 || eventData.historyEventData.actionType === 32 || eventData.historyEventData.actionType === 31)) {
    if (!+eventData.entityData?.collaborationData?.source) {
      return false;
    }

    if (!+eventData.entityData?.collaborationData?.target) {
      return false;
    }

    const sourceResourceOnTask = currentResourceOnTask(+eventData.entityData.collaborationData.source);
    const targetResourceOnTask = currentResourceOnTask(+eventData.entityData.collaborationData.target);

    if (!sourceResourceOnTask && !targetResourceOnTask) {
      return false;
    }
  }

  return true;
};

app.socket.on('collaboration:notify', eventData => {
  const ganttIDs = ganttViewModel.getGanttIDs();
  const ganttId = +eventData.ganttId;
  const isSameProjectOpen = _.includes(ganttIDs, ganttId);
  const isAutoScheduling = _.includes(actionTypesForLiteRefresh.AUTO_SCHEDULING, eventData.historyEventData.actionType);
  const muteNotify = !!(eventData && eventData.entityData && eventData.entityData.data && eventData.entityData.data.muteNotify);

  if (isSameProjectOpen && !muteNotify && !isAutoScheduling && collaborationComponent.hasRightsToShowCollaborationMessage(+ganttId, eventData)) {
    collaborationComponent.showCollaborationMessage(eventData, eventData.historyEventData);
  }
});

app.socket.on('collaboration', event => {
  collaborationManager.pushEvent(event);
});

export default collaborationComponent;
