<template>
  <div
    :class="[inputWithButton.input_with_button, css ? inputWithButton[css] : '', isError ? inputWithButton.error : '']"
    :style="styles ? styles : ''"
  >
    <span
      v-if="isError"
      :class="inputWithButton.error_text"
    >
      {{ errorText }}
    </span>
    <input
      :ref="type ? type : 'input'"
      v-model="value"
      :class="inputWithButton.input"
      :placeholder="inputPlaceholder"
      :type="type"
      @keydown="onKeydown"
      @input="onHandleInput"
    >
    <button
      ref="button"
      :class="[inputWithButton.button, isDisable ? inputWithButton.disable : '', tooltipKey ? 'tooltip-gantt' : '']"
      :data-key="tooltipKey ? tooltipKey : ''"
      :data-position="'top'"
      :data-y="-5"
      @click="getValueToParent"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>

export default {
  props: [
    'inputPlaceholder',
    'buttonText',
    'css',
    'type',
    'getValue',
    'isDisable',
    'styles',
    'isError',
    'errorText',
    'tooltipKey',
    'onInput',
  ],
  data() {
    return {
      value: '',
    };
  },
  methods: {
    onFocus(data) {
      let input = null;

      if (data.email) {
        input = this.$refs.email;
      } else {
        input = this.$refs.input;
      }

      if (input) {
        input.focus();
      }
    },
    getValueToParent() {
      if (this.isDisable) {
        return;
      }
      if (this.value.trim() === '') {
        const refType = this.type ? this.type : 'input';

        this.$refs[refType].focus();
        this.getValue(this.value.trim());
        this.value = '';

        return;
      }

      this.getValue(this.value.trim());
      this.value = '';
    },
    onKeydown(e) {
      if (e.keyCode === 13) {
        this.getValueToParent();
      }
    },
    onHandleInput() {
      if (this.onInput) {
        this.onInput();
      }
    },
  },
};
</script>

<style module="inputWithButton" src="./inputWithButton.less"></style>
