<template>
  <div
    :class="[
      $.chart,
      $.chart_schedule,
    ]"
  >
    <div :class="$.header">
      <div :class="$.title">
        {{ locales(`chart_${chart.key}_title`) }}
      </div>
      <div
        v-if="data.mode && !statusSwitch"
        :class="$.mode"
      >
        {{ locales(`overview_type_${data.mode}`) }}
      </div>
      <vgp-switch
        v-if="statusSwitch"
        :is-toggle-on="chart.status"
        @onChange="$emit('onToggleStatus')"
      />
    </div>
    <div
      v-if="description"
      :class="$.description"
    >
      {{ locales(`chart_${chart.key}_description`) }}
    </div>
    <div :class="$.wrapper">
      <div
        ref="chart"
        :class="$.body"
      />
      <div
        v-if="isShowStub"
        :class="$.stub"
      >
        <div :class="$.stub_item" />
        <div :class="$.stub_item" />
        <div :class="$.stub_item" />
        <div :class="$.stub_text">
          {{ locales(`chart_${chart.key}_stub_text`) }}
        </div>
      </div>
      <loader :show="isShowLoader" />
    </div>
  </div>
</template>

<script>
import * as echarts from '../../../libs/echarts';
import Loader from './Loader.vue';
import VgpSwitch from '../../common/VueComponents/checkbox/squareToggle.vue';

export default {
  components: {
    Loader,
    VgpSwitch,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    statusSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      instance: null,
      id: 'chart',
      timeout: null,
      isShowLoader: false,
      tooltipTitle: __(`chart_${this.chart.key}_tooltip_title`),
      labelPlanned: __('overview_planned_progress'),
      labelActual: __('overview_actual_progress'),
      labelBehind: __('overview_behind'),
      labelAhead: __('overview_ahead'),
    };
  },
  computed: {
    isShowStub() {
      return !this.static && !this.data.items.some(value => value !== 0);
    },
    items() {
      return this.data.items.map(item => ({
        value: item,
        label: {
          position: item < 0 ? 'insideLeft' : 'insideRight',
        },
      }));
    },
    options() {
      return {
        color: ['#458C9A', '#1565C0', '#FF9A00'],
        grid: {
          left: '25%',
          right: '11%',
          top: '16%',
          bottom: '14%',
          containLabel: false,
        },
        tooltip: (this.static ? false : {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            z: 0,
            lineStyle: {
              type: 'solid',
              width: 36,
              color: '#D0E0F2',
            },
          },
          formatter: data => {
            const params = data.find(obj => obj.seriesName === this.id);

            return `<div class="chart_tooltip">
              <div class="tooltip_title">${this.tooltipTitle}</div>
              <div class="tooltip_info">
                <div class="tooltip_name">${this.getAxisLabel(params.value, params.dataIndex)}</div>
                <div class="tooltip_value">${params.value}%</div>
              </div>
            </div>`;
          },
          borderColor: '#808080',
          borderWidth: 1,
          borderRadius: 4,
          padding: 8,
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);',
        }),
        xAxis: {
          type: 'value',
          position: 'top',
          interval: 25,
          min: -100,
          max: 100,
          axisLabel: {
            color: '#191919',
            fontSize: 10,
            lineHeight: 12,
            fontFamily: 'Lato-Regular, sans-serif',
            margin: 12,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        yAxis: {
          type: 'category',
          inverse: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: (value, index) => {
              const itemValue = this.items[index].value;

              return this.getAxisLabel(itemValue, index);
            },
            width: 80,
            margin: 85,
            align: 'left',
            overflow: 'break',
            color: '#191919',
            fontSize: 12,
            lineHeight: 14,
            fontFamily: 'Lato-Regular, sans-serif',
          },
        },
        series: [{
          name: this.id,
          type: 'bar',
          colorBy: 'data',
          barWidth: 36,
          itemStyle: {
            borderRadius: 4,
          },
          label: {
            show: true,
            position: 'inside',
            formatter: params => `${params.value}%`,
            color: '#FFFFFF',
            fontSize: 12,
            lineHeight: 14,
            fontFamily: 'Lato-Bold, sans-serif',
            distance: 0,
            padding: [0, 12, 0, 12],
          },
          labelLayout: params => {
            const value = this.items[params.dataIndex].value;
            const dxDiv = value < 0 ? -2 : 2;

            return {
              fontSize: params.rect.width < params.labelRect.width - 24 ? 0 : 12,
              dx: params.rect.width < params.labelRect.width ? (params.labelRect.width - params.rect.width) / dxDiv : 0,
            };
          },
          data: this.items,
          animation: !this.static,
          silent: this.static,
        }],
      };
    },
  },
  watch: {
    data() {
      clearTimeout(this.timeout);
      this.isShowLoader = true;

      this.timeout = setTimeout(() => {
        this.isShowLoader = false;
        this.instance.setOption({
          series: [{
            name: this.id,
            data: this.items,
          }],
        });
      }, 1000);
    },
  },
  mounted() {
    this.instance = echarts.init(this.$refs.chart);
    this.instance.setOption(this.options);
  },
  beforeDestroy() {
    echarts.dispose(this.instance);
  },
  methods: {
    getAxisLabel(value, index) {
      const labels = [
        this.labelPlanned,
        this.labelActual,
        value < 0 ? this.labelBehind : this.labelAhead,
      ];

      return labels[index];
    },
  },
};
</script>

<style module="$" src="../less/chart.less"></style>
