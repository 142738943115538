import helpers from './helpers';

export default {
  setData(state, payload) {
    state.account = payload.account;
    state.project = payload.project;
  },
  addProjectRole(state, payload) {
    state.project.roles.push(payload);
  },
  addAccountRole(state, payload) {
    state.account.roles.push(payload);
  },

  setCollaborationData(state, payload) {
    state[payload.type].roles = state[payload.type].roles.map(item => {
      payload.updated.forEach(updatedItem => {
        if (item.id === updatedItem.id) {
          item = { ...item, ...updatedItem };
        }
      });

      return item;
    });
  },

  setDefaultAccountRole(state, payload) {
    state[payload.type].roles = state[payload.type].roles.map(item => {
      if (item.id === payload.id) {
        item.isDefault = 1;
      } else {
        item.isDefault = 0;
      }

      return item;
    });
  },

  setRoleTitle(state, payload) {
    state[payload.type].roles = state[payload.type].roles.map(item => {
      if (item.id === payload.id) {
        item.title = payload.title;
      }

      return item;
    });
  },

  deleteRole(state, payload) {
    let isUpdateDefaultRole = false;

    let updatedArray = state[payload.type].roles.filter(item => {
      if (item.id !== payload.id) {
        return true;
      }

      // if we delete defaultRole set flag true
      if (item.isDefault) {
        isUpdateDefaultRole = true;
      }

      return false;
    });

    // if we removed default role set MEMBER like a role by defult
    if (isUpdateDefaultRole) {
      updatedArray = updatedArray.map(item => {
        if (item.title === 'MEMBER') {
          item.isDefault = 1;
        }

        return item;
      });
    }

    state[payload.type].roles = updatedArray;
  },

  resetEdit(state) {
    state[state?.editMode?.type]?.roles.map(item => {
      if (state.editMode.roleId === item.id) {
        item.rights = [...state?.editMode?.rights];
      }

      return item;
    });

    state.editMode = {
      status: false,
      roleId: null,
      type: null,
      rights: [],
    };
  },

  deleteDataFromEditMode(state) {
    state.editMode = {
      status: false,
      roleId: null,
      type: null,
      rights: [],
    };
  },

  setEditMode(state, payload) {
    let copyRights;

    state[payload.type].roles.forEach(item => {
      if (payload.roleId === item.id) {
        // full copy without links
        copyRights = [...item.rights.map(right => ({ ...right }))];
      }
    });
    state.editMode = { ...payload, rights: copyRights };
  },

  setBitStatus(state, payload) {
    state[payload.type].roles = state[payload.type].roles.map(item => {
      // search current role
      if (item.id === payload.roleId) {
        let ids;
        let currentReverseRightIds; // for reverse

        if (payload.reverse) {
          ids = [...helpers.getChildReverseRightIds(state, payload).map(id => helpers.getBitIds(state, payload, id)).flat()];
          currentReverseRightIds = [...helpers.getBitIds(state, payload, payload.rightId)];
        } else if (payload.parentRightId !== 0 || (payload.parentRightId === 0 && payload.status)) {
          // its children right. We don't need to find a group of rights
          ids = helpers.getBitIds(state, payload, payload.rightId);
        } else if (payload.parentRightId === 0 && payload.status === false) {
          ids = [...helpers.getBitIds(state, payload, payload.rightId), ...helpers.getChildRightIds(state, payload, payload.rightId).map(id => helpers.getBitIds(state, payload, id)).flat()];
        }

        // update bits
        if (!payload.reverse) {
          // if this update isn't reverse
          item.rights = item.rights.map(bit => {
            // search current bit
            if (ids.includes(bit.id)) {
              bit.status = payload.status;
            }

            return bit;
          });
        } else {
          // if this update is reverse
          item.rights = item.rights.map(bit => {
            // search current bit
            if (ids.includes(bit.id)) {
              bit.status = false; // set all bits false
            }

            if (currentReverseRightIds.includes(bit.id)) {
              bit.status = payload.status; // set all bits to currect status
            }

            return bit;
          });
        }
      }

      return item;
    });
  },

};
