var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.box], style: { background: _vm.params.background } },
    [
      _c(
        "div",
        { class: _vm.$style.icon },
        [
          _c("svg-sprite", {
            attrs: {
              name: _vm.params.iconName,
              size: _vm.params.iconSize,
              type: _vm.params.iconType,
              color: _vm.params.iconColor,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.message }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }