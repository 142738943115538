import _ from '../libs/lodash';
import moment from '../libs/moment';
import constants from './constants';
import dateFormats from './dateFormats';
import timeIntervalHelper from './timeIntervals';

const __ = window.__;

const Parser = {
  prop: null,
  currentStep: null,
  steps: {},
  maxTimeLog: 60 * 24 - 1,
  locale: {
    minute: __('minute_in_time_string'),
    hour: __('hour_in_time_string'),
    day: __('day_in_time_string'),
    week: __('week_in_time_string'),
    month: __('month_in_time_string'),
  },
  prefixValue: null,
  input(value, config = {}) {
    this._init(config);
    const minutes = this._parse(value);

    if (!this._validate(minutes)) {
      return false;
    }

    return this._format(minutes);
  },
  output(value, config = {}) {
    this._init(config);

    let minutes = Math.round(value * 60);

    if (!minutes) {
      return '0';
    }

    if (this.prop === 'time_tracking' || this.prop === 'duration') {
      minutes = value;
    }

    if (this.prop === 'lag') {
      minutes = Math.round(parseInt(value, 10));
      this.prefixValue = minutes < 0 ? '-' : '';
    }

    return this._format(minutes).text;
  },
  _validate(minutes) {
    if (!minutes && minutes !== 0) {
      const mess = this.prop === 'time_tracking' ? 'message_time_log_input_wrong' : 'message_time_input_wrong';

      webix.message({ type: 'warning', text: __(mess) });

      return false;
    }

    if (this.prop === 'time_tracking' && (minutes > this.maxTimeLog || minutes === 0)) {
      webix.message({ type: 'warning', text: __('message_time_log_input_limits') });

      return false;
    }

    return true;
  },
  _init(config) {
    if (!config.durationData) {
      this.steps = {
        hour: 60,
      };
      this.currentStep = this.steps.hour;
    } else {
      let workHours = 0;
      const workDays = config.durationData.showDay.length;

      const defHours = dateFormats.getHoursArrayByUserTimeFormat();

      const selectedHoursByIntervals = timeIntervalHelper.getHoursArrayByIntervalArray(config.durationData.showTime, defHours);

      workHours = selectedHoursByIntervals.length / 2;

      this.steps = {
        hour: 60,
        day: workHours * 60,
        week: workDays * workHours * 60,
        month: 4 * workDays * workHours * 60,
      };

      this.currentStep = config.prop === 'duration' || config.prop === 'lag' ? this.steps[config.durationStep] : this.steps.hour;
    }

    this.durationStep = config.durationStep || 'day';
    this.prop = config.prop || 'time_tracking';
  },
  _parse(str) {
    this.prefixValue = null;

    if (
      this.prop === 'lag'
      && typeof str === 'string'
      && str.indexOf('-') !== -1
    ) {
      this.prefixValue = '-';
      str = str.toString().replace(/-/g, '');
    }

    str = str.toString().replace(/,/g, '.');

    const numberRegExp = /^(\d+\.?\d{0,9}|\.\d{1,9})$/;
    const stringRegExp = '(\\d+([\\.\\,]\\d+)?)\\s*';
    let numberMode = true;
    let result = false;

    _.each(['month', 'week', 'day', 'hour', 'minute'], step => {
      const matched = str.match(new RegExp(stringRegExp + this.locale[step]));

      if (matched) {
        const matchedValue = this.prop === 'lag' ? matched.input : matched[1];

        if (step === 'minute') {
          str = str.replace(matched[0], '');
          result += Math.round(parseFloat(matched[1]));
        } else {
          str = str.replace(matched[0], '');
          result += Math.round(parseFloat(matchedValue) * this.steps[step]); // matched[1]
        }
        numberMode = false;
      }
    });
    if (numberMode && numberRegExp.test(parseFloat(str).toFixed(9))) {
      if (this.prop === 'lag') {
        if (parseInt(str, 10) < 0) {
          return result = -Math.round(-parseFloat(str) * this.currentStep);
        }

        return result = Math.round(parseFloat(str) * this.currentStep);
      }
      result = Math.round(parseFloat(str) * this.currentStep);
    }
    if (!numberMode && /\S/.test(str) && (this.prop !== 'lag')) {
      return false;
    }

    return result; // in minutes
  },
  _format(minutes) {
    const result = {
      text: '',
      value: 0,
    };
    let rest = Math.abs(minutes);
    let steps = ['month', 'week', 'day', 'hour'];

    if (this.prop === 'duration' || this.prop === 'lag') {
      result.value = minutes;
      steps = steps.splice(steps.indexOf(this.durationStep));
    }

    if (this.prop === 'estimation') {
      result.value = Math.round((minutes / 60) * 100) / 100;
      steps = ['hour'];
    }

    if (this.prop === 'time_tracking') {
      result.value = minutes;
      steps = ['hour'];
    }

    _.each(steps, step => {
      const count = parseInt(rest / this.steps[step]);

      rest %= this.steps[step];

      if (count) {
        result.text += `${count + this.locale[step]} `;
      }

      if (step === 'hour' && rest > 0) {
        result.text += rest + this.locale.minute;
      }

      if (rest === 0) {
        result.text = result.text.trim();
      }
    });

    if (!result.text) {
      result.text = '0';
    }
    if (this.prefixValue && this.prop === 'lag') {
      result.value = this.prefixValue + result.value;
      result.text = this.prefixValue + result.text;
    }
    if (this.prop === 'lag' && this.durationStep === 'minute') {
      result.text = `${minutes}${this.locale[this.durationStep]}`;
    }

    return result;
  },
};

export default Parser;
