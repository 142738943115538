<template>
  <div
    v-if="UIConfig"
    id="header"
    class="header"
  >
    <head-view
      v-if="UIConfig.head"
      ref="headView"
      :config="UIConfig.head"
      :button-colors="buttonColors"
    />
    <tabs-view
      v-if="UIConfig.tabs && UIConfig.tabs.length"
      :config="UIConfig.tabs"
    />
    <toolbar-view
      v-if="UIConfig.toolbar"
      :config="UIConfig.toolbar"
      :button-colors="buttonColors"
      :data-loaded="dataLoaded"
    />
  </div>
</template>

<script>
import app from '../../app';

import ganttViewModel from '../../models/ganttViewModel';

import Store from '../../store/main';
import headView from './headView.vue';
import tabsView from './tabsView.vue';
import toolbarView from './toolbarView.vue';
import projectsModel from '../../models/projects';
import constants from '../../helpers/constants';
import routerHelper from '../../helpers/router';
import _ from '../../libs/lodash';
import { checkPricingAccess } from '../../helpers/pricingHelper';

export default {
  name: 'HeaderVue',
  store: Store,
  components: {
    'head-view': headView,
    'tabs-view': tabsView,
    'toolbar-view': toolbarView,
  },
  data() {
    return {
      buttonColors: {
        color: '#333333',
        labelColor: '#191919',
        activeColor: '#11519A',
        hoverBg: '#F2F2F2',
        activeBg: '#D0E0F2',
        activeHoverBg: '#A1C1E6',
      },
    };
  },
  computed: {
    UIConfig() {
      const UIConfig = this.$store.getters['headerView/UIConfig'];

      return UIConfig;
    },
    height() {
      if (this.UIConfig) {
        const mode = this.$store.getters['headerView/currentRoute'].params.mode || 'gantt';
        const specialHeight = this.UIConfig.sumHeightForTabs
          && this.UIConfig.sumHeightForTabs[mode];

        return specialHeight || this.UIConfig.sumHeight;
      }

      return 0;
    },
    currentRoute() {
      const currentRoute = this.$store.getters['headerView/currentRoute'];

      return currentRoute;
    },
    dataLoaded() {
      let isLoaded = true;
      const currentRoute = this.$store.getters['headerView/currentRoute'];

      // Required data for list view (my tasks) and workload view
      if (currentRoute.name === 'listView' || currentRoute.name === 'workload') {
        const customColumnsValuesLoaded = this.$store.getters['columns/valuesLoaded'];

        if (!customColumnsValuesLoaded) {
          isLoaded = false;
        }
      }

      return isLoaded;
    },
  },
  watch: {
    currentRoute(to, from) {
      if (to.path.includes('/gantt')) {
        const projectConfig = ganttViewModel.getProjectConfig();

        this.viewSettingConfig(projectConfig);
        this.$store.commit('headerView/setOverdueTasks', projectConfig.highlight_overdue);
      }
    },
    height(value) {
      const oldHeight = $$('header').config.height;

      $$('header').getNode().classList[!value ? 'add' : 'remove']('hide');

      if (value) {
        $$('header').define('height', value);
        $$('header').resize();
      }

      if (oldHeight !== value) {
        gantt.$ui.getView('ganttChart') && gantt.$ui.getView('ganttChart').resize();
      }
    },
  },

  beforeCreate() {
    const component = this;

    app.on('gantt:progress:show', () => {
      component.$store.commit('headerView/setUIConfigToolbarDisable', true);
    });

    app.on('gantt:progress:hide', () => {
      if (!routerHelper.isTaskSettingsRoute()) {
        component.$store.commit('headerView/setUIConfigToolbarDisable', false);
      }
    });

    app.on('activeProject:afterSet', () => {
      component.initData();
    });

    app.on('activeMultiProjects:afterSet', () => {
      component.initData();
      component.$store.commit('headerView/setUIConfig');
    });

    app.on('init:multiview', () => {
      component.initData();
    });

    app.on('project:archive', (ganttId, flag) => {
      const routeParams = component.$store.getters['headerView/currentRoute'].params;

      if (+routeParams.projectId === +ganttId) {
        if (flag && routeParams.mode !== 'gantt') {
          component.$store.dispatch('headerView/changeRoute', {
            name: component.$store.getters['headerView/currentRoute'].name,
            params: { mode: 'gantt' },
          });
        } else {
          component.$store.commit('headerView/setUIConfig');
        }
      }

      component.$store.commit('comments/deleteDraft', {
        projectId: ganttId,
      });
    });

    app.on('gantt:reinitConfig', () => {
      const routeParams = component.$store.getters['headerView/currentRoute'].params;

      if (+routeParams.projectId) {
        component.$store.commit('headerView/setUIConfig');
      }
    });

    app.on('user:changedRole', ganttId => {
      const routeParams = component.$store.getters['headerView/currentRoute'].params;

      if (+routeParams.projectId === +ganttId) {
        component.$store.dispatch('headerView/setUIConfig');
      }
    });

    app.on('app:route:after:changed', (to, from) => {
      component.$store.commit('headerView/setCurrentRoute', {
        name: to.name,
        path: to.path,
        params: to.params,
        prevRoute: from,
      });
      component.$store.commit('headerView/setUIConfig');
    });

    projectsModel.data.attachEvent(
      'updateProjectName',
      (ganttId, projectName) => {
        const routeParams = component.$store.getters['headerView/currentRoute'].params;

        if (+routeParams.projectId === +ganttId) {
          component.$store.commit('headerView/setHeadTitle', projectName);
        }

        this.$refs.headView?.$refs?.projectTitle?.setSelectionRange(0, 0);
      },
    );

    app.on('resources:model:addResourcesToProjects', data => {
      component.$store.commit('headerView/setUIConfig');
      // app.trigger('init:newProject');
      // app.trigger('gantt:reinit');
    });

    app.on('resources:model:removeResourcesFromProjects', (data, resourceIds) => {
      app.trigger('sideBar:update:favorite');
    });

    app.on('filter:status:update', value => {
      component.$store.commit('headerView/setActiveFilter', !!value);
    });

    app.on('masschange:enable', enable => {
      component.$store.commit('headerView/toggleBulkChange');
    });

    window.addEventListener(
      'resize',
      _.debounce(() => {
        component.$refs.headView && component.$refs.headView.setInputWidth();
      }, 100),
    );
  },
  created() {
    this.$store.commit('headerView/setUIConfig');
    this.$store.commit('headerView/setHeight', this.height);
    this.$emit('input', this.height);
  },
  mounted() {
    const component = this;

    component.$nextTick(() => {});
  },
  beforeUpdate() {
    this.$store.commit('headerView/setHeight', this.height);
    this.$emit('input', this.height);
  },
  methods: {
    checkPricingAccess,
    initData() {
      const viewData = ganttViewModel.getActiveViewData();
      const projectConfig = ganttViewModel.getProjectConfig();

      this.$store.commit('headerView/setHeadTitle', viewData ? viewData.name : '');
      this.$store.commit('headerView/setFavorite', viewData.is_favorite);
      this.viewSettingConfig(projectConfig);
      this.$store.commit('headerView/setOverdueTasks', projectConfig.highlight_overdue);
      this.$store.commit(
        'headerView/setCriticalPath',
        projectConfig.highlight_critical_path,
      );
      this.$store.dispatch('headerView/updatePublicUrlInfo');
      if (projectConfig.durationData) {
        this.$store.commit(
          'headerView/setZoom',
          constants.ZOOM.modeToZoomValue[projectConfig.durationData.mode],
        );
      }
    },
    viewSettingConfig(projectConfig) {
      if (projectConfig.right_side_text === true) {
        projectConfig.right_side_text = 'rightSideText';
      } else if (
        projectConfig.right_side_text === false
        || projectConfig.right_side_text === undefined
      ) {
        projectConfig.right_side_text = 'insideText';
      }
      if (projectConfig.cross_out_tasks === undefined) {
        projectConfig.cross_out_tasks = true;
      }
      if (projectConfig.discoloration_tasks === undefined) {
        projectConfig.discoloration_tasks = true;
      }

      this.$store.commit('headerView/setViewSettings', {
        taskName: projectConfig.right_side_text,
        completedTask: [
          projectConfig.cross_out_tasks && 'crossOutTasks',
          projectConfig.discoloration_tasks && 'discolorationTasks',
        ].filter(el => el),
        todayMarker: projectConfig.current_day_marker,
        avatar: projectConfig.show_resource_avatar,
        skipOffTime: !projectConfig.skip_off_time,
        buttons:
          projectConfig.show_advanced_buttons === undefined
            ? true
            : projectConfig.show_advanced_buttons,
        skins: +projectConfig.userSkin || 4,
        viewMode: projectConfig.view_mode,
      });
    },
  },
};
</script>

<style src="./less/main.less" lang="less"></style>
