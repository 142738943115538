<template>
  <div
    :class="[
      $style.box_wrapper,
      isDisable ? $style.disable : null,
      'vgp-interactive-element'
    ]"
  >
    <label :class="$style.checkbox">
      <span
        v-if="label && labelPosition==='left'"
        :class="$style.label_text"
        :style="{marginRight: `8px`}"
      >
        {{ label }}
      </span>

      <input
        :class="$style.check_input"
        type="checkbox"
        :checked="checked"
        :disabled="isDisable"
        @change="$emit('onChange', $event.target.checked)"
        @click="onClick"
      >
      <span
        :class="[$style.check_box, isError ? $style.check_box_error : '']"
        :style="{
          width: `${size}px`,
          height: `${size}px`,
          minWidth: `${size}px`,
          backgroundColor: checked ? color : '',
          borderColor: checked ? color : '',
        }"
      >
        <svg
          v-if="checked"
          :class="$style.check_inner"
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 0.841163L3.45714 8L0 4.71887L0.886286 3.8777L3.45714 6.31171L10.1137 0L11 0.841163Z"
            fill="#FFFFFF"
          />
        </svg>
      </span>

      <span
        v-if="label && labelPosition==='right'"
        :class="$style.label_text"
        :style="{marginLeft: `8px`}"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VGpCheckbox',
  model: {
    prop: 'checked',
    event: 'onChange',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 18,
    },
    label: {
      type: String,
      default: null,
    },
    labelPosition: {
      type: String, // left or right
      default: null,
    },
    color: {
      type: String,
      default: '#1565C0',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    onClick: null,
    onChange: Boolean,
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style module src="./checkbox.less"></style>
