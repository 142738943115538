var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["table-body", _vm.className ? _vm.className : ""] },
    [
      _c("v-data-table", {
        class: [
          "elevation-0 resources-table",
          _vm.resources.length ? "gantt_scroll" : "",
        ],
        attrs: {
          "fixed-header": "",
          height: _vm.tableHeight,
          headers: _vm.headers,
          items: _vm.resources,
          "item-key": _vm.id,
          "hide-default-footer": "",
          "disable-pagination": "",
          "show-select": _vm.resourcesType === "material",
          "checkbox-color": "#1565C0",
          "calculate-widths": true,
          "sort-by": _vm.sortBy,
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function ($event) {
            _vm.sortBy = $event
          },
          "click:row": _vm.onClickRow,
        },
        scopedSlots: _vm._u([
          {
            key: "header.data-table-select",
            fn: function (ref) {
              var props = ref.props
              var on = ref.on
              return [
                _c(
                  "div",
                  { staticClass: "table-checkbox table-header" },
                  [
                    _c("vgp-checkbox", {
                      attrs: {
                        id: "gp_autotest_resource_table_header_checkbox_select_all",
                        size: 16,
                        checked: props.value,
                        "is-disable":
                          _vm.isHasJiraResources || _vm.actionsDisabled,
                      },
                      on: {
                        onClick: function ($event) {
                          return on.input(!props.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.data-table-select",
            fn: function (ref) {
              var item = ref.item
              var select = ref.select
              var isSelected = ref.isSelected
              return [
                _c(
                  "div",
                  {
                    staticClass: "table-checkbox",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onClickRow($event)
                      },
                    },
                  },
                  [
                    _c("vgp-checkbox", {
                      staticClass:
                        "gp_autotest_resource_table_item_checkbox_select",
                      attrs: {
                        size: 16,
                        checked: isSelected,
                        "is-disable":
                          _vm.disableJira(item) || _vm.actionsDisabled,
                      },
                      on: {
                        onClick: function ($event) {
                          return select(!isSelected)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.name",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    class: [
                      "table-cell table-cell__info",
                      _vm.resourcesType === "people" ? "people" : "material",
                    ],
                  },
                  [
                    _c("vgp-avatar", {
                      attrs: { src: item.photo, size: "large" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          style: { maxWidth: _vm.widthName },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "email" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.email) + "\n          "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.roleTitle",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    class: [
                      "table-cell table-cell__role",
                      item.isOwner ? "owner-item" : "",
                    ],
                    attrs: { "data-id": item.id },
                  },
                  [
                    item.isOwner
                      ? _c("div", { staticClass: "cell-text bold" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.role.title) +
                              "\n        "
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            style: [
                              _vm.actionsDisabled
                                ? { "pointer-events": "none" }
                                : {},
                              !item.accessToChangeRole
                                ? { width: "inherit" }
                                : {},
                            ],
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickRow($event)
                              },
                            },
                          },
                          [
                            !item.accessToChangeRole
                              ? _c("div", { staticClass: "role-name" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.role.title) +
                                      "\n          "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "dropdown" },
                                  [
                                    _c("dropdown-select", {
                                      staticClass:
                                        "gp_autotest_resource_table_item_dropdown_select_roles",
                                      class: _vm.actionsDisabled
                                        ? "disabled-dropdown"
                                        : "",
                                      attrs: {
                                        "drop-list": _vm.dropdownRoles,
                                        disabled: _vm.actionsDisabled,
                                        multiselect: false,
                                        "show-selected-first": false,
                                        "show-reset-btn": false,
                                        "highlight-active-state": false,
                                        "item-template": "text",
                                        "track-by-text": "title",
                                        outline: "hover",
                                        "drop-position": "fixed",
                                        "track-by-item-tooltip": "tooltip",
                                        "fixed-width": 320,
                                        size: "autoWidth",
                                        "need-description": true,
                                        "parent-selector-for-calc-position-drop":
                                          "resources-table",
                                        selected: item.role,
                                      },
                                      on: {
                                        selected: function ($event) {
                                          return _vm.onChangeRole($event, item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.type",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    key: _vm.costComponentKey,
                    class: ["table-cell table-cell__type"],
                    attrs: { "data-id": item.id },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "table-cost-dropdown" },
                      [
                        _c("dropdown-select", {
                          staticClass:
                            "gp_autotest_resource_table_item_dropdown_select_cost",
                          attrs: {
                            "drop-list": _vm.costDropdown,
                            multiselect: false,
                            "show-selected-first": false,
                            "show-reset-btn": false,
                            "highlight-active-state": false,
                            "item-template": "text",
                            "track-by-text": "text",
                            outline: "hover",
                            "track-by-item-tooltip": "tooltip",
                            selected: _vm.costDropdown.find(function (i) {
                              return (
                                i.id ===
                                _vm.getProjectData(item.resourceProjects).type
                              )
                            }),
                          },
                          on: {
                            selected: function ($event) {
                              return _vm.onChangeType(
                                $event,
                                item,
                                _vm.getProjectData(item.resourceProjects)
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.cost",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    key: _vm.costComponentKey,
                    class: ["table-cell table-cell__cost"],
                    attrs: { "data-id": item.id },
                  },
                  [
                    _vm.getProjectData(item.resourceProjects).type === 3
                      ? _c(
                          "div",
                          { staticClass: "table-cost-input__disable" },
                          [_c("div", { staticClass: "item" })]
                        )
                      : _c(
                          "div",
                          { staticClass: "table-cost-input" },
                          [
                            _c("vgp-row-input", {
                              staticClass:
                                "cost-input gp_autotest_resource_table_item_input_cost",
                              attrs: {
                                value: _vm.getProjectData(item.resourceProjects)
                                  .cost,
                                "font-size": 12,
                                "max-width": 100,
                                "input-type": "number",
                                format: "currency",
                                bold: true,
                              },
                              on: {
                                onChange: function (value) {
                                  return _vm.onChangeCost(
                                    value,
                                    item,
                                    _vm.getProjectData(item.resourceProjects)
                                  )
                                },
                                onKeydown: function (value) {
                                  return _vm.onChangeCost(
                                    value,
                                    item,
                                    _vm.getProjectData(item.resourceProjects)
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.changeOwner",
            fn: function (ref) {
              var item = ref.item
              return [
                item.accessToChangeOwner ||
                (item.isOwner && (_vm.meIsKing || _vm.meIsProjectOwner))
                  ? _c(
                      "div",
                      {
                        class: ["table-cell table-cell__change_owner"],
                        attrs: { "data-id": item.id },
                      },
                      [
                        _c("vgp-button", {
                          staticClass:
                            "change_btn gp_autotest_resource_table_item_btn_change_owner",
                          attrs: {
                            label: _vm.locales("change_owner_btn"),
                            type: "text",
                            icon: { name: "change", type: "bold", size: 20 },
                            "icon-side": "left",
                            small: true,
                          },
                          on: {
                            onClick: function ($event) {
                              return _vm.onChangeOwner(item)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", { staticStyle: { height: "0" } }),
              ]
            },
          },
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    staticClass: "table-cell table-cell__activity",
                    attrs: { "data-id": item.id },
                  },
                  [
                    !item.invite.status || item.userId === _vm.user.id
                      ? _c("div", { staticClass: "cell-text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.locales("resource_active_account")) +
                              "\n        "
                          ),
                        ])
                      : _c(
                          "div",
                          { staticClass: "pending" },
                          [
                            _c("div", { staticClass: "cell-text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.locales("invite_pending_status")) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            !item.invite.hasOldTeam
                              ? _c("tooltip", {
                                  staticClass: "tooltip-wrapper",
                                  style: _vm.actionsDisabled
                                    ? { "pointer-events": "none" }
                                    : {},
                                  attrs: {
                                    content: _vm.locales("invited_locale"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gp_autotest_resource_table_item_btn_copy_invite_link",
                                                on: {
                                                  click: [
                                                    function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onClickRow(
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.copyInviteLink(
                                                        item.email
                                                      )
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c("icon-button", {
                                                  attrs: {
                                                    icon: { name: "link-1" },
                                                    size: "small",
                                                    type: "colored",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.buttons",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("div", { class: ["table-cell table-cell__options"] }, [
                  item.accessToConvert && _vm.resourcesType === "material"
                    ? _c(
                        "div",
                        {
                          on: {
                            click: [
                              function ($event) {
                                return _vm.$emit("onConvertResources", item.id)
                              },
                              function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickRow($event)
                              },
                            ],
                          },
                        },
                        [
                          _c("vgp-button", {
                            staticClass: "convert_btn",
                            attrs: {
                              label: _vm.locales("convert_resources_button"),
                              type: "text",
                              small: true,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.accessToSettings
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "icon icon--settings gp_autotest_resource_table_item_btn_open_profile",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("openProfileCard", item.id)
                            },
                          },
                        },
                        [
                          _c("icon-button", {
                            attrs: {
                              icon: { name: "settings" },
                              size: "middle",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.acсessToDelete &&
                  _vm.selected.length === 0 &&
                  !_vm.disableJira(item)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "icon icon--delete gp_autotest_resource_table_item_btn_delete",
                          on: {
                            click: [
                              function ($event) {
                                return _vm.$emit("onDeleteResource", item)
                              },
                              function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickRow($event)
                              },
                            ],
                          },
                        },
                        [
                          _c("icon-button", {
                            attrs: { icon: { name: "delete" }, size: "middle" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm._v(" "),
      _c("input", {
        ref: "hiddenLink",
        style: {
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0,
          zIndex: -1,
        },
        attrs: { editable: false },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }