<template>
  <div>
    <LayoutHeaderRightBar
      v-bind:title="layoutTitle"
      v-bind:key="layoutTitle"
      v-bind:iframe="GanttIframe"
      v-bind:paperWithSvg="PaperWithSvg"
      v-bind:rightBar="RightBar"
      v-bind:appFilter="appFilter"  
    > 
    </LayoutHeaderRightBar> 
  </div>
</template>

<script>
  import LayoutHeaderRightBar from './layout-with-header-and-right-bar/LayoutHeaderRightBar.vue';
  import RightBar from './RightBar.vue';
  import GanttIframe from './GanttIframe.vue';
  import PaperWithSvg from './PaperWithSvg.vue';
  import appFilter from '../filter/filter.vue'
  const __ = window.__;

  export default {
    data() {
      return {
        layoutTitle: __('export_header_title'),
        RightBar,
        GanttIframe,
        PaperWithSvg
      }
    },
    components: {
      LayoutHeaderRightBar,
      RightBar,
      GanttIframe,
      PaperWithSvg,
      appFilter
    },
    mounted: function () {
      userExtAnalytics.log('project_export_open');
    }
  }
</script>

<style></style>