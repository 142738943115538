import onTaskCreated from './onTaskCreated';
import onTaskDeleted from './onTaskDeleted';
import onTaskUpdated from './onTaskUpdated';
import onTaskCustomFieldUpdated from './onTaskCustomFieldUpdated';
import onTaskResourceAssigned from './onTaskResourceAssigned';
import onTaskResourceUnassigned from './onTaskResourceUnassigned';
import onTaskResourceUpdated from './onTaskResourceUpdated';
import onTaskLinkCreated from './onTaskLinkCreated';
import onTaskLinkDeleted from './onTaskLinkDeleted';
import onTaskLinkUpdated from './onTaskLinkUpdated';
import onTaskCommentCreated from './onTaskCommentCreated';
import onTaskCommentDeleted from './onTaskCommentDeleted';
import onTaskTimeLogsCreated from './onTaskTimeLogsCreated';
import onTaskTimeLogUpdated from './onTaskTimeLogUpdated';
import onTaskTimeLogDeleted from './onTaskTimeLogDeleted';
import onTaskAttachementCreated from './onTaskAttachementCreated';
import onTaskAttachementDeleted from './onTaskAttachementDeleted';
import onTaskCommentUpdated from './onTaskCommentUpdated';

export default {
  onTaskCreated,
  onTaskDeleted,
  onTaskUpdated,
  onTaskCustomFieldUpdated,
  onTaskResourceAssigned,
  onTaskResourceUnassigned,
  onTaskResourceUpdated,
  onTaskLinkCreated,
  onTaskLinkDeleted,
  onTaskLinkUpdated,
  onTaskCommentCreated,
  onTaskCommentDeleted,
  onTaskTimeLogsCreated,
  onTaskTimeLogUpdated,
  onTaskTimeLogDeleted,
  onTaskAttachementCreated,
  onTaskAttachementDeleted,
  onTaskCommentUpdated,
};
