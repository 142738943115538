import app from '../../../app';
import _ from '../../../libs/lodash';
import helperDate from '../../../helpers/dateFormats';
import helperTimeIntervals from '../../../helpers/timeIntervals';
import projectsModel from '../../../models/projects';
import templates from '../../../templates/labels';
import timeIntervalHelper from '../../../helpers/timeIntervals';

const __ = window.__;

const innerSettings = {
  workDays: [],
  workHours: [],
};

const innerConst = {
  startOnMonday: [
    { id: 1, value: __('common_short_mon') },
    { id: 2, value: __('common_short_tue') },
    { id: 3, value: __('common_short_wed') },
    { id: 4, value: __('common_short_thu') },
    { id: 5, value: __('common_short_fri') },
    { id: 6, value: __('common_short_sat') },
    { id: 7, value: __('common_short_sun') },
  ],
  startOnSunday: [
    { id: 7, value: __('common_short_sun') },
    { id: 1, value: __('common_short_mon') },
    { id: 2, value: __('common_short_tue') },
    { id: 3, value: __('common_short_wed') },
    { id: 4, value: __('common_short_thu') },
    { id: 5, value: __('common_short_fri') },
    { id: 6, value: __('common_short_sat') },
  ],
};

var innerViews = {
  daysRangeLabel: {
    view: 'label',
    id: 'daysRangeLabelNewProject',
    width: 160,
    label: __('project_settings_days'),
    css: 'label-with-spacer',
    template: templates.getTemplateForLabelWithSpacerAndNumber,
    toolTip: {
      y: 4,
      langKey: 'project_settings_range_days_tooltip',
    },
  },
  displayOptionsListDays: {
    view: 'daysList',
    id: 'displayOptionsListDaysNewProject',
    label: __('settings_day_popup'),
    height: 44,
    type: {
      width: 74,
      height: 42,
    },
    on: {
      onSelectChange() {
        innerHandlers.changeDays(this);
      },
    },
  },
  workHoursPopup: {
    id: 'workHoursPopupNewProject',
    css: 'workHoursPopupNewProject',
  },
  hoursRangeLabel: {
    view: 'template',
    id: 'displayOptionRangeHoursLabelNewProject',
    height: 44,
    css: 'hours-template gp_autotest_calendar_settings_hours_select',
    intervals: [],
    borderless: false,
    template(obj) {
      let innerHtml = '';

      this.intervals.forEach(intervalString => {
        innerHtml += `<div class='dataview-hours-inline-item gp_autotest_calendar_settings_hours_item'>${intervalString}</div>`;
      });

      return `<div class='dataview-hours-inline'>${innerHtml}</div>`;
    },
    onClick: {
      'hours-template': function () {
        $$(innerViews.workHoursPopup.id).toggle(this.$view, { pos: 'top', y: -5 });
      },
    },
  },
  optionsHoursLabel: {
    view: 'label',
    id: 'optionsHoursLabelNewProject',
    align: 'center',
    label: __('hours_label_desc_in_data_view'),
  },
  hoursRangeTitleLabel: {
    view: 'label',
    id: 'displayOptionRangeHoursTitleLabelNewProject',
    width: 160,
    label: __('project_settings_hours'),
    css: 'label-with-spacer',
    toolTip: {
      y: 4,
      langKey: 'project_settings_range_hours_tooltip',
    },
    number: 0,
    template: templates.getTemplateForLabelWithSpacerAndNumber,
  },
  displayOptionsHoursDataView: {
    view: 'dataview',
    id: 'displayOptionsHoursDataViewNewProject',
    select: true,
    multiselect: 'touch',
    xCount: 6,
    yCount: 8,
    scroll: false,
    align: 'center',
    css: 'dataview-hours displayOptionsHoursDataView',
    height: 320,
    type: {
      width: 72,
      height: 37,
      css: 'displayOptionsHoursDataView-row',
    },
    data: [],
    on: {
      onSelectChange() {
        innerHandlers.changeHours();
      },
    },
    template(obj) {
      return `<div class='dataview-hours-block gp_autotest_calendar_settings_hours_list_item'><div class='dataview-hours-item'>${helperDate.getUserTimeFormat() ? obj.id : obj.value}</div></div>`;
    },
  },
};

var innerHandlers = {
  init() {
    innerSettings.workDays = projectsModel.getDefaultWorkDays();
    innerSettings.workHours = projectsModel.getDefaultworkHours();

    innerHandlers.initListDays(+user.settings.start_monday);
    innerHandlers.initWorkHours();
  },
  initListDays(startOnMonday) {
    const webixDayUI = $$(innerViews.displayOptionsListDays.id);

    if (webixDayUI) {
      webixDayUI.clearAll();
      startOnMonday ? webixDayUI.parse(innerConst.startOnMonday) : webixDayUI.parse(innerConst.startOnSunday);

      webixDayUI.unselectAll();
      webixDayUI.blockEvent();
      webixDayUI.select(innerSettings.workDays);
      webixDayUI.unblockEvent();

      innerHandlers.updateNumberInDaysLabel();
    }
  },
  initWorkHours() {
    $$(innerViews.displayOptionsHoursDataView.id).parse(helperDate.getHoursArrayByUserTimeFormat().filter(i => i.id !== '24:00'));
    $$(innerViews.displayOptionsHoursDataView.id).blockEvent();

    const selectedHoursByIntervals = timeIntervalHelper.getHoursArrayByIntervalArray(innerSettings.workHours, helperDate.getHoursArrayByUserTimeFormat());

    if ((selectedHoursByIntervals.length / 2) % 1 !== 0) {
      $$(innerViews.hoursRangeTitleLabel.id).config.number = `${Math.floor(selectedHoursByIntervals.length / 2)} ${__('h')} 30 ${__('m')}`;
    } else {
      $$(innerViews.hoursRangeTitleLabel.id).config.number = `${selectedHoursByIntervals.length / 2} ${__('h')}`;
    }

    $$(innerViews.displayOptionsHoursDataView.id).select(selectedHoursByIntervals);
    $$(innerViews.displayOptionsHoursDataView.id).unblockEvent();
    innerHandlers.updateHoursLabels();
  },
  changeDays() {
    const webixDayUI = $$(innerViews.displayOptionsListDays.id);
    let selectedDays = webixDayUI.getSelectedId();

    if (typeof selectedDays === 'number' || _.toSafeInteger(selectedDays)) {
      selectedDays = [selectedDays];
    }

    if (typeof selectedDays === 'string') {
      selectedDays = [];
    }

    selectedDays = selectedDays
      .sort((a, b) => a - b)
      .map(dayNumber => parseInt(dayNumber, 10));

    if (!selectedDays.length) {
      webixDayUI.blockEvent();
      webixDayUI.selectAll();
      selectedDays = webixDayUI.getSelectedId();
      webixDayUI.unblockEvent();
    }

    innerSettings.startDay = selectedDays[0];
    innerSettings.endDay = selectedDays[selectedDays.length - 1];
    innerSettings.workDays = selectedDays;
    innerHandlers.updateNumberInDaysLabel();
    app.trigger('newProject:updateWorkDays', innerSettings.workDays);
  },
  updateNumberInDaysLabel() {
    const webixUI = $$(innerViews.daysRangeLabel.id);

    webixUI.config.number = `${innerSettings.workDays.length} ${__('d')}`;
    webixUI.refresh();
  },
  updateHoursLabels() {
    const delimiter = ';';
    const stringIntervals = innerHelpers.getLabelForIntervals(null, delimiter);
    // const hoursIntervals = stringIntervals.split(delimiter);
    const hoursRangeLabel = $$(innerViews.hoursRangeLabel.id);
    const titleView = $$(innerViews.hoursRangeTitleLabel.id);
    let selectedHours = $$(innerViews.displayOptionsHoursDataView.id).getSelectedId();
    const popup = $$(innerViews.workHoursPopup.id);

    if (typeof selectedHours === 'string') selectedHours = [selectedHours];

    if ((selectedHours.length / 2) % 1 !== 0) {
      titleView.config.number = `${Math.floor(selectedHours.length / 2)} ${__('h')} 30 ${__('m')}`;
    } else {
      titleView.config.number = `${selectedHours.length / 2} ${__('h')}`;
    }
    titleView.refresh();

    hoursRangeLabel.config.intervals = stringIntervals;
    hoursRangeLabel.config.height = 40 * Math.ceil(stringIntervals.length / 4) + 6;
    hoursRangeLabel.resize();
    hoursRangeLabel.refresh();

    if (popup.isVisible()) {
      popup.show(hoursRangeLabel.getNode(), { pos: 'top', y: -5 });
    }
  },
  showHoursList() {
    const oldSelect = $$(innerViews.displayOptionsHoursDataView.id).getSelectedId();
    const optionsHoursDataView = $$(innerViews.displayOptionsHoursDataView.id);

    optionsHoursDataView.blockEvent();
    optionsHoursDataView.clearAll();
    optionsHoursDataView.parse(helperDate.getHoursArrayByUserTimeFormat());
    optionsHoursDataView.select(oldSelect);
    optionsHoursDataView.unblockEvent();
  },
  changeHours(blockSend) {
    const optionsHoursDataView = $$(innerViews.displayOptionsHoursDataView.id);
    const selectedHours = optionsHoursDataView.getSelectedId();
    let showTimesIntervals = '';

    if (selectedHours.length) {
      showTimesIntervals = helperTimeIntervals.prepareSelectedHours(selectedHours, helperDate.getHoursArrayByUserTimeFormat());
    }
    if (!showTimesIntervals.length) {
      showTimesIntervals = ['9:00-13:00', '14:00-18:00'];
      optionsHoursDataView.blockEvent();
      optionsHoursDataView.select(timeIntervalHelper.getHoursArrayByIntervalArray(['9:00-13:00', '14:00-18:00'], helperDate.getHoursArrayByUserTimeFormat()));
      optionsHoursDataView.unblockEvent();
      optionsHoursDataView.callEvent('onSelectChange', []);
    }

    innerSettings.workHours = webix.copy(showTimesIntervals);

    innerHandlers.updateHoursLabels();
  },
  getWorkDays() {
    return innerSettings.workDays;
  },
  getWorkHours() {
    return innerSettings.workHours;
  },
};

var innerHelpers = {
  hour24toAMPM(digitHour) {
    let timeCorrector = 0;
    let AMPM = 'AM';

    if (digitHour === 0) {
      timeCorrector = -12;
    }

    if (digitHour === 12) {
      timeCorrector = 0;
      AMPM = 'PM';
    }
    if (digitHour > 12) {
      timeCorrector = 12;
      AMPM = 'PM';
    }
    if (digitHour === 24) {
      return '12 AM';
    }

    return (digitHour - timeCorrector + AMPM);
  },
  getLabelForIntervals(currentHoursArray) {
    const enableAM = !helperDate.getUserTimeFormat();

    const currentHours = innerSettings.workHours;

    if (enableAM) {
      const hoursArray = helperDate.getHoursArrayByUserTimeFormat();

      const formattedIntervals = currentHours.map(interval => {
        const [start, end] = interval.trim().split('-');
        const startTime = hoursArray.find(item => item.id === start).value.replace(/AM|PM/g, '');
        let endTime = '';

        if (end === '24:00') {
          endTime = enableAM ? '12AM' : '24:00';
        } else {
          endTime = hoursArray.find(item => item.id === end).value.replace(/AM|PM/g, '');
        }

        return `${startTime}-${endTime}`;
      });

      return formattedIntervals;
    }

    return currentHours;
  },
};

webix.ui({
  view: 'popupWith6Padding',
  id: innerViews.workHoursPopup.id,
  // css: innerViews.workHoursPopup.css,
  padding: 12,
  body: {
    width: 472,
    height: 353,
    // type: 'clean',
    paddingX: 0,
    paddingY: 0,
    borderless: true,
    rows: [
      {
        height: 320,
        css: 'displayOptionsHoursDataView',
        body:
        innerViews.displayOptionsHoursDataView,
      },
      // {width: 568},
      innerViews.optionsHoursLabel,
    ],
  },
  on: {
    onShow() {
      innerHandlers.showHoursList();
    },
  },
}).hide();

const outputObject = {
  init: innerHandlers.init,
  view: {
    css: 'calendar_settings',
    rows: [
      {
        // width: "100%",
        rows: [
          innerViews.daysRangeLabel,
          innerViews.displayOptionsListDays,
        ],
      },
      { height: 10 },
      {
        // width: "100%",
        rows: [
          innerViews.hoursRangeTitleLabel,
          innerViews.hoursRangeLabel,
        ],
      },
    ],
  },
  handlers: {
    getWorkDays: innerHandlers.getWorkDays,
    getWorkHours: innerHandlers.getWorkHours,
  },
};

app.on('settings:startOnMonday:change', startOnMonday => {
  // if ($$('workHoursPopupNewProject') && $$('workHoursPopupNewProject').isVisible()) {
  innerHandlers.initListDays(startOnMonday);
  // }
});

export default outputObject;
