import mainFilter from './mainFilter';
import logsFilter from './logsFilter';
import listviewFilter from './listviewFilter';
import workloadFilter from './workloadFilter';
import costsFilter from './costsFilter';
import app from '../../../app';
import CalendarFilter from './calendarView';

let currentFilter = null;

class Manager {
  static initFilter(name) {
    let type = name;

    currentFilter = null;

    if (app.config.mode.isExport) {
      type = gantt.config.multiview ? 'multiview' : 'project';
    }

    if (app.config.mode.isLink) {
      type = gantt.config.multiview ? 'multiview' : 'project';
    }

    currentFilter = Manager._defineFilter(type);

    currentFilter && currentFilter.init();

    return currentFilter;
  }

  static _defineFilter(name) {
    switch (name) {
    case 'project':
      return mainFilter.filterByType('project', 'export');
    case 'project_board': // project_board
    case 'project_gantt': // project_gantt
    case 'project_gantt_task': // project_gantt
      return mainFilter.filterByType('project');

    case 'project_calendar':
      return CalendarFilter.filterByType('calendarview');

    case 'multiview':
      return mainFilter.filterByType('multiview', 'export');
    case 'projects_gantt': // projectsMultiView_gantt
    case 'projects_gantt_task': // projectsMultiView_gantt
      return mainFilter.filterByType('multiview');

    case 'workload': // workload
    case 'projects_workload': // projectsMultiView_workload
      return workloadFilter.filterByType('workload', 'withProjects');
    case 'project_workload': // project_workload
      return workloadFilter.filterByType('workload');

    case 'tasks_list': // listView_list
    case 'project_list': // project_list
    case 'projects_list': // projectsMultiView_list
    case 'project_list_task':
    case 'projects_list_task':
    case 'tasks_list_task':
      return listviewFilter.filterByType();

    case 'reports_costslist': // reportsDetail
      return costsFilter.filterByType('costslist');
    case 'reports_timeloglist': // reportsDetail
      return logsFilter.filterByType('timeloglist', 'withResource');
    case 'timelog': // userLogs
      return logsFilter.filterByType('user-logs');

    default: return undefined;
    }
  }
}

export default Manager;
