var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: "",
      "close-icon": "",
      title: _vm.locales("app_learnwnd_caption"),
      "body-style": _vm.isNewsTab ? "noPaddingRight" : "",
    },
    on: {
      onClickCancel: function ($event) {
        return _vm.$emit("close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { class: _vm.$style["body-wrapper"] },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.subheader,
                      _vm.isNewsTab ? _vm.$style.subheader__news : "",
                    ],
                  },
                  [
                    _c("vgp-tabs", {
                      class: _vm.$style.tabs,
                      attrs: {
                        tabs: _vm.tabs,
                        "active-by": "type",
                        active: { type: _vm.currentTab },
                      },
                      on: { onToggle: _vm.handleOnToggleTab },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$style.links },
                      [
                        _vm._l(_vm.availableLinks, function (link, index) {
                          return _c(
                            "a",
                            {
                              key: index,
                              class: _vm.$style.link,
                              attrs: { href: link.url },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleLinkClick(link)
                                },
                              },
                            },
                            [
                              _c("span", { class: _vm.$style["link__text"] }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(link.name) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              link.newTabIcon
                                ? _c("svg-sprite", {
                                    attrs: { name: "link-2" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c("span"),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("keep-alive", [_c(_vm.currentTab, { tag: "component" })], 1),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }