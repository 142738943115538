<template>
  <div class="payment-popup">
    <div class="head">
      <div class="main-title">
        {{ locales('payment_popup_title') }}
      </div>
    </div>
    <div class="body">
      <div class="period-toggle">
        <div :class="['period-tabs', isAnnual ? 'annual' : 'month']">
          <div
            class="tab annually"
            @click="togglePeriod(true)"
          >
            <div :class="['save-item', isAnnual && nextCount === 1 ? 'dnone' : '']">
              {{ locales('save_up_locale') }}
            </div>
            <div>{{ locales('plans_annually_locale') }}</div>
          </div>
          <div
            class="tab monthly"
            @click="togglePeriod(false)"
          >
            {{ locales('plans_monthly_locale') }}
          </div>
        </div>
      </div>
      <div class="plans">
        <plan-item
          v-for="(plan, index) in plans"
          :key="index"
          :plan="plan"
          :is-annual="isAnnual"
          :is-popup="true"
          :number-of-plan="numberOfPlanItems"
        />
      </div>
      <div
        class="contact-footer"
        v-html="locales('payment_popup_contact_footer')"
      />
    </div>
  </div>
</template>

<script>
import planItem from '../components/planItem.vue';

export default {
  components: {
    planItem,
  },
  data() {
    return {
      locales: __,
      isAnnual: '',
      numberOfPlan: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        // 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
      ],
      numberOfPlanItems: [],
      plans: [],
      currentUserCount: null,
    };
  },
  computed: {
    nextCount() {
      return this.$store.getters['paymentPlans/nextCount'];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const counts = [user.subscription.user_count, this.$store.getters['resourcesModel/getAllRealResources'].length, 1];
      const maxCount = counts.reduce((max, number) => (number > max ? number : max));

      this.currentUserCount = this.numberOfPlan.find(num => num >= maxCount);

      this.plans = user.paymentPlans.filter(value => {
        if (value.id !== 17) {
          return value;
        }
      });
      this.isAnnual = user.accountPlanData.is_annual;
      this.$store.commit('paymentPlans/setNextCount', this.currentUserCount);
      this.setSelectItems();
    },
    setSelectItems() {
      const preparedCountItems = this.numberOfPlan.map(num => {
        const countConfig = {};

        countConfig.value = num;
        if (num < this.currentUserCount) {
          countConfig.disabled = true;
        } else {
          countConfig.disabled = false;
        }

        return countConfig;
      });

      this.numberOfPlanItems = preparedCountItems;
    },
    togglePeriod(flag) {
      this.isAnnual = flag;
      const selectItems = this.numberOfPlanItems;
      const nextCount = this.$store.getters['paymentPlans/nextCount'];
      const userCount = user.subscription.user_count;
      const firstItem = this.numberOfPlanItems.find(item => item.value === 1);

      firstItem.disabled = false;

      if (!flag && nextCount === 1) {
        this.$store.commit('paymentPlans/setNextCount', this.numberOfPlanItems[1].value);
      }

      if (!flag && userCount >= 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      } else if (flag && userCount > 1) {
        firstItem.disabled = true;
        selectItems.splice(0, 1, firstItem);
      }

      this.numberOfPlanItems = selectItems;
      userExtAnalytics.log('pricing_period_changed', { type: this.isAnnual ? 'annually' : 'monthly' });
    },
  },
};
</script>

<style scoped src='../less/paymentPlans.less'></style>
<style lang='less'>
  .contact-footer {
    a {
      padding-left: 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
