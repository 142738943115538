// views/newProject/_trialFinishedUser.js
// !TODO: description

import app from '../../app';
import common from './common';
import viewsModes from './../../helpers/viewModes';
import projectsModel from "../../models/projects";
import Store from '../../store/main'


const __ = window.__;

const ID_VIEW_SUBSCRIBE_FINISHED_WINDOW = 'subscribeFinishedPopup';
const ID_VIEW_SUBSCRIBE_FINISHED_BODY = 'subscribeFinishedPopupBody';

const {templates: {overSubscription}} = common;

// ?WTF
if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

const webixUI = {
  view: 'window',
  id: ID_VIEW_SUBSCRIBE_FINISHED_WINDOW,
  css: 'choose-subscribe is_member',
  borderless: true,
  header: false,
  headHeight: 0,
  zIndex: 50,
  modal: false,
  move: false,
  hidden: true,
  unClosable: true,
  padding: 10,
  position: function (state) {
    const leftSideBarWidth = Store.getters.leftSidebarWidth;

    state.height = document.body.clientHeight + 2;
    state.width = document.body.clientWidth - leftSideBarWidth + 1;
    state.left = leftSideBarWidth;
    state.top = 0;
  },
  body: {
    borderless: true,
    rows: [{
      view: 'template',
      id: ID_VIEW_SUBSCRIBE_FINISHED_BODY,
      css: 'trial_inner',
      borderless: true,
      scroll: true,
      data: {
        icon: GT.cdn + "/imgs/over_gantt_imgs/member.png",
        title: __('over_subscription_title_member'),
        text: __('over_subscription_text_member', {
          ownerName: user.team.owner_firstName + ' ' + user.team.owner_lastName,
          ownerEmail: user.team.owner_email
        }),
        btn: __('over_subscription_btn_member'),
        desc: __('over_subscription_desc_member'),
        userType: 'member'
      },
      template: overSubscription,
      onClick: {
        'js-init-lc': function () {
          app.trigger('app:footer:livechat:click')
        },
        'main_btn': function(){
          userExtAnalytics.log('member_subscription_suspended_popup_contact_owner_click');
        }
      }
    }]
  },
  on: {
    'onHide': onPopupHide,
    'onShow': onPopupShow
  }
};

const _events = {
  ['body:resize']: {
    fn: (data) => {
      $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW) && $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).resize();
    }
  },
  ['leftSideBar:changedMode']: {
    fn: () => {
      $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW) && $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).resize();
    }
  }
};

function onPopupShow() {
  const allProjects = projectsModel.getAllProjects();

  document.querySelectorAll('.webix_view .webix_disabled').forEach(item => item.style['background-color'] = 'inherit');

  if (!allProjects || allProjects.length === 0) {
    $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).getNode().style['background-color'] = '#BFBFBF';
  }

  Object.keys(_events).forEach((event) => {
    _events[event].id = app.on(event, _events[event].fn);
  });

  app.trigger('user:overTrialOrSubscription', true);
  $$(ID_VIEW_SUBSCRIBE_FINISHED_BODY).getNode().querySelector('.inner_template').classList.add('visible');

  userExtAnalytics.log('member_subscription_suspended_popup_show');
}

function onPopupHide() {
  Object.keys(_events).forEach((event) => {
    app.off(_events[event].id);
  });
}

function init() {
  console.info('[_subscribeFinishedUser] -> init'); // !DEBUG

  $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).show();
}

function cleanup() {
  console.info('_subscribeFinishedUser -> cleanup'); // !DEBUG

  $$(ID_VIEW_SUBSCRIBE_FINISHED_WINDOW).hide();
}

export {webixUI, init, cleanup};
