<template>
  <div class="integration_slack_settings_window">
    <div class="integration_slack_settings_window-header">
      <div class="left-side">
        <div class="icon">
          <webix-ui :config="icons.slackIcon" />
        </div>
      </div>
      <closer
        :on-close="closeWindow"
        :size="30"
      />
    </div>
    <div class="integration_slack_settings_window-body">
      <div class="scroll_container">
        <div class="container">
          <div class="container-description">
            <div class="main-title">
              <div class="text">
                {{ locales("integration_slack_settings_page_title") }}
              </div>
              <div
                v-if="removeIntegrationButton && !tablePreloder"
                class="button"
                @click="removeIntegration"
              >
                <div class="button-text">
                  {{ locales("integration_slack_settings_page_remove") }}
                </div>
              </div>
              <div
                v-if="!removeIntegrationButton && !tablePreloder"
                class="button"
                @click="disableIntegration"
              >
                <div class="button-text">
                  {{ locales("integration_slack_settings_page_disable") }}
                </div>
              </div>
            </div>
            <div class="description">
              {{ locales("integration_slack_settings_page_description") }}
            </div>
          </div>

          <!-- <div class="tips">
                <div v-for="(item, index) in tips" :key="index" class="tip">
                  <webix-ui :config="item.image"/>
                  <div class="text">{{item.text}}</div>
                </div>
              </div> -->

          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="!tablePreloder"
              key="table"
              class="connections_table"
            >
              <div class="header">
                <div class="project_title">
                  {{
                    locales("integration_settings_page_table_header_project")
                  }}
                </div>
                <div class="channel_title">
                  {{
                    locales(
                      "integration_slack_settings_page_table_header_channel"
                    )
                  }}
                </div>
                <div class="settings_title">
                  {{
                    locales("integration_settings_page_table_header_settings")
                  }}
                </div>
              </div>
              <div
                v-if="filteredProjectChannelConnections.length"
                class="connections"
              >
                <div
                  v-for="(
                    projectChannelConnection, index
                  ) in filteredProjectChannelConnections"
                  :key="index"
                  class="connection"
                  :class="{
                    full_border: projectChannelConnection.fullBorder,
                  }"
                >
                  <div
                    v-if="!projectChannelConnection.editMode"
                    class="view_container"
                  >
                    <div
                      v-if="projectChannelConnection.showProject"
                      class="connection_project_title"
                    >
                      <div
                        class="text"
                        :title="
                          getProjectByGanttId(projectChannelConnection.gantt_id)
                            .name
                        "
                      >
                        {{
                          getProjectByGanttId(projectChannelConnection.gantt_id)
                            .name
                        }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="connection_project_title"
                    />
                    <div
                      class="channel_setting_container"
                      :class="{
                        small_border: projectChannelConnection.smallBorder,
                      }"
                    >
                      <div class="channel_title">
                        <div
                          class="text"
                          :title="projectChannelConnection.channel_name"
                        >
                          {{ projectChannelConnection.channel_name }}
                        </div>
                      </div>
                      <div class="settings_title">
                        <div
                          class="text"
                          :title="
                            getSettingsString(projectChannelConnection)
                              .settingsText
                          "
                        >
                          {{
                            getSettingsString(projectChannelConnection)
                              .settingsText
                          }}
                        </div>
                      </div>
                      <div class="buttons">
                        <div
                          class="edit"
                          @click="
                            toggleEditView(projectChannelConnection.id)
                          "
                        >
                          <webix-ui :config="icons.editIcon" />
                        </div>
                        <div
                          class="delete"
                          @click="deleteItem(projectChannelConnection.id)"
                        >
                          <webix-ui :config="icons.deleteIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="edit_container"
                  >
                    <div class="inputs">
                      <div class="edit_project">
                        <integration-slack-select-vue
                          :value="selectedInputs.projectId"
                          :options="filteredProjectOptions"
                          :placeholder="
                            locales(
                              'integration_slack_settings_page_table_input_plh_project'
                            )
                          "
                          @focus="onFocusSelect"
                          @change="onChangeProjectId"
                        />
                      </div>
                      <div
                        class="channels_settings_container"
                        :class="{
                          small_border: projectChannelConnection.smallBorder,
                        }"
                      >
                        <div class="channels_settings_inputs">
                          <div
                            class="edit_channel"
                            :class="{ error: channelIsEmptyError }"
                          >
                            <div
                              v-if="channelIsEmptyError"
                              class="error"
                            >
                              {{
                                locales(
                                  "integration_slack_settings_page_table_input_error_cant_be_empty"
                                )
                              }}
                            </div>
                            <integration-slack-select-vue
                              :key="onChangesFlag"
                              :value="selectedInputs.channelId"
                              :options="filteredChannelOptions"
                              :placeholder="
                                locales(
                                  'integration_slack_settings_page_table_input_plh_channel'
                                )
                              "
                              @focus="onFocusSelect"
                              @change="onChangeChannelId"
                            />
                          </div>
                          <div class="edit_settings">
                            <integration-slack-multiselect-vue
                              :key="onChangesFlag"
                              :value="selectedInputs.settings"
                              :options="settingsOptions"
                              :placeholder="
                                locales(
                                  'integration_slack_settings_page_table_input_plh_settings'
                                )
                              "
                              @focus="onFocusSelect"
                              @change="onChangeSettings"
                            />
                          </div>
                        </div>
                        <div class="buttons">
                          <div
                            class="buttons_ok"
                            @click="editItem(projectChannelConnection.id)"
                          >
                            <webix-ui :config="icons.okIcon" />
                          </div>
                          <div
                            class="buttons_cancel"
                            @click="closeEditMode()"
                          >
                            <webix-ui :config="icons.cancelIcon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="no_connections"
              >
                <div class="text">
                  {{
                    locales(
                      "integration_slack_settings_page_table_no_connections"
                    )
                  }}
                </div>
              </div>
              <div
                v-if="!addMode"
                class="add_item_container"
              >
                <div
                  v-if="hasPossibleAddNewItem && !editMode"
                  class="add_button"
                  @click="onClickAdd"
                >
                  <div class="text">
                    {{ locales("integration_slack_settings_page_add_button") }}
                  </div>
                </div>
                <div
                  v-if="!hasPossibleAddNewItem && !editMode"
                  class="no_possible_add_new"
                >
                  <div class="msg">
                    <div class="msg_icon">
                      <webix-ui :config="icons.yellowInfo" />
                    </div>
                    <div class="msg_text">
                      {{
                        locales(
                          "integration_slack_settings_page_add_no_possible"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="add_item_inputs_container"
              >
                <div class="inputs_buttons_container">
                  <div class="inputs">
                    <div
                      class="input_project"
                      :class="{ is_error: projectIsEmptyError }"
                    >
                      <div
                        v-if="projectIsEmptyError"
                        class="is_error"
                      >
                        {{
                          locales(
                            "integration_slack_settings_page_table_input_error_cant_be_empty"
                          )
                        }}
                      </div>
                      <integration-slack-select-vue
                        :value="selectedInputs.projectId"
                        :options="filteredProjectOptions"
                        :placeholder="
                          locales(
                            'integration_slack_settings_page_table_input_plh_project'
                          )
                        "
                        @focus="onFocusSelect"
                        @change="onChangeProjectId"
                      />
                    </div>
                    <div
                      class="input_channel"
                      :class="{ is_error: channelIsEmptyError }"
                    >
                      <div
                        v-if="channelIsEmptyError"
                        class="is_error"
                      >
                        {{
                          locales(
                            "integration_slack_settings_page_table_input_error_cant_be_empty"
                          )
                        }}
                      </div>
                      <integration-slack-select-vue
                        :key="onChangesFlag"
                        :value="selectedInputs.channelId"
                        :options="filteredChannelOptions"
                        :placeholder="
                          locales(
                            'integration_slack_settings_page_table_input_plh_channel'
                          )
                        "
                        @focus="onFocusSelect"
                        @change="onChangeChannelId"
                      />
                    </div>
                    <div class="input_settings">
                      <integration-slack-multiselect-vue
                        :key="onChangesFlag"
                        :value="selectedInputs.settings"
                        :options="settingsOptions"
                        :placeholder="
                          locales(
                            'integration_slack_settings_page_table_input_plh_settings'
                          )
                        "
                        @focus="onFocusSelect"
                        @change="onChangeSettings"
                      />
                    </div>
                  </div>
                  <div class="buttons">
                    <div
                      class="buttons_ok"
                      @click="addItem"
                    >
                      <webix-ui :config="icons.okIcon" />
                    </div>
                    <div
                      class="buttons_cancel"
                      @click="closeAddMode()"
                    >
                      <webix-ui :config="icons.cancelIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              key="preloder"
              class="connections_table"
            >
              <div class="header">
                <div class="project_title">
                  {{
                    locales("integration_settings_page_table_header_project")
                  }}
                </div>
                <div class="channel_title">
                  {{
                    locales(
                      "integration_slack_settings_page_table_header_channel"
                    )
                  }}
                </div>
                <div class="settings_title">
                  {{
                    locales("integration_settings_page_table_header_settings")
                  }}
                </div>
              </div>
              <div
                v-for="item in [1, 2]"
                :key="item"
                class="connections"
              >
                <div
                  v-for="el in [1, 2, 3]"
                  :key="el"
                  class="connection"
                  :class="{ full_border: el === 3 }"
                >
                  <div class="view_container">
                    <div class="connection_project_title">
                      <div
                        v-if="el === 1"
                        class="text_preloder"
                      />
                    </div>
                    <div
                      class="channel_setting_container"
                      :class="{ small_border: el !== 3 }"
                    >
                      <div class="channel_title">
                        <div class="text_preloder" />
                      </div>
                      <div class="settings_title">
                        <div class="text_preloder" />
                      </div>
                      <div class="buttons">
                        <div class="button_preloder" />
                        <div class="button_preloder" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

// Icons
import slackIcon from '../../../svg/integration/settings/slack.svg';
import closeCross from '../../../svg/integration/settings/close.svg';
import tipImage from '../../../svg/integration/settings/image_tip.svg';
import deleteIcon from '../../../svg/integration/settings/delete.svg';
import editIcon from '../../../svg/integration/settings/edit.svg';
import okIcon from '../../../svg/integration/settings/button_ok.svg';
import cancelIcon from '../../../svg/integration/settings/button_cancel.svg';
import yellowInfo from '../../../svg/integration/settings/yellow_info.svg';

// Models
import projectsModel from '../../../models/projects';
import integrationsModel from '../../../models/integrations/integrations';
import integrationSlackModel from '../../../models/integrations/integrationsSlack';
import integrationSlackChannelsModel from '../../../models/integrations/integrationsSlackChannels';

// Helpers
import vueSvgIcon from '../../common/svgIconForVue';
import routerHelper from '../../../helpers/router';
import multiselect from '../multiselect';
import rights from '../../../components/rights/index';

// Components
import selectVue from '../selectVue.vue';
import multiselectVue from '../multiselectVue.vue';
import closer from '../../common/VueComponents/closer/closer.vue';

export default {
  components: {
    'integration-slack-select-vue': selectVue,
    'integration-slack-multiselect-vue': multiselectVue,
    closer,
  },
  props: [],
  data() {
    return {
      icons: {
        slackIcon: vueSvgIcon(slackIcon, 154, 18),
        closeCross: vueSvgIcon(closeCross, 14, 14),
        editIcon: vueSvgIcon(editIcon, 24, 24),
        deleteIcon: vueSvgIcon(deleteIcon, 24, 24),
        okIcon: vueSvgIcon(okIcon, 36, 36),
        cancelIcon: vueSvgIcon(cancelIcon, 36, 36),
        yellowInfo: vueSvgIcon(yellowInfo, 24, 24),
      },
      webix: {
        multiselect,
      },
      removeIntegrationButton: !!rights.account.isOwner(),
      locales: __,
      projects: projectsModel.getAllProjects(),
      channelsOptions: [],
      projectsOptions: [],
      projectChannelConnections: [],
      tablePreloder: true,
      addMode: false,
      editMode: false,
      selectedSelect: null,
      onChangesFlag: 0,
      onSelectInputFlag: 0,
      projectIsEmptyError: false,
      channelIsEmptyError: false,
      selectedInputs: {
        projectId: null,
        channelId: null,
        settings: null,
      },
      editTempInputsValues: {
        projectId: null,
        channelId: null,
        settings: null,
      },
      tips: [],
      settingsOptions: [],
      isDisableOkButton: false,
    };
  },
  computed: {
    hasPossibleAddNewItem: {
      get() {
        return (this.channelsOptions.length * this.projectsOptions.length) !== this.projectChannelConnections.length;
      },
    },
    filteredProjectChannelConnections: {
      get() {
        const _data = _.map(this.projectChannelConnections, _.clone);

        _data.sort((a, b) => +a.gantt_id - +b.gantt_id);

        let _ganttId = _data && _data.length ? _data[0].gantt_id : null;

        for (let i = 0; i < _data.length; i++) {
          if (i === 0) {
            _data[i].showProject = true;
          }
          if (i === _data.length - 1) {
            _data[i].fullBorder = true;
          } else if (_data[i + 1].gantt_id === _ganttId) {
            _data[i].smallBorder = true;
          } else {
            _data[i + 1].showProject = true;
            _ganttId = _data[i + 1].gantt_id;
            _data[i].fullBorder = true;
          }
        }

        return _data;
      },
    },
    filteredProjectOptions: {
      get() {
        const that = this;
        let projectsOptions = that.projectsOptions.slice();

        projectsOptions = projectsOptions.filter(item => {
          let countConnections = 0;
          let result = false;

          that.projectChannelConnections.forEach(el => {
            if (+el.gantt_id === +item.id) {
              countConnections++;
            }
          });

          result = countConnections < that.channelsOptions.length;
          if (that.editTempInputsValues.projectId === item.id) {
            result = true;
          }

          return result;
        });

        return projectsOptions;
      },
    },
    filteredChannelOptions: {
      get() {
        const that = this;
        let channelsOptions = that.channelsOptions.slice();

        if (that.selectedInputs.projectId) {
          channelsOptions = channelsOptions.filter(item => {
            let channelUsing = false;

            that.projectChannelConnections.forEach(el => {
              if (+el.gantt_id === +that.selectedInputs.projectId && el.channel_id === item.id) {
                channelUsing = true;
                if (that.editTempInputsValues.channelId === el.channel_id
                    && that.editTempInputsValues.projectId === that.selectedInputs.projectId) {
                  channelUsing = false;
                }
              }
            });

            return !channelUsing;
          });

          return channelsOptions;
        }

        return channelsOptions;
      },
    },
  },
  created() {
    this.channelsOptions = [];
    this.projectsOptions = [];
    this.settingsOptions = [];
    this.initSettingsOptions();
    this.initTips();
    this.loadData().then(() => {
      this.tablePreloder = false;
    });
  },
  mounted() {
    const that = this;
    // hide selectPopup on scroll

    document.querySelector('.integration_slack_settings_window-body').onscroll = function () {
      if (that.selectedSelect) {
        document.body.click();
        that.selectedSelect = null;
      }
    };
  },
  methods: {
    initSettingsOptions() {
      this.settingsOptions = [
        {
          id: 'new_task',
          value: this.locales('integration_settings_page_settings_new_task'),
        },
        {
          id: 'status',
          value: this.locales('integration_settings_page_settings_status'),
        },
        {
          id: 'comment',
          value: this.locales('integration_settings_page_settings_comment'),
        },
        {
          id: 'attachment',
          value: this.locales('integration_settings_page_settings_attachment'),
        },
        {
          id: 'assignee',
          value: this.locales('integration_settings_page_settings_assignee'),
        },
        {
          id: 'new_start_date',
          value: this.locales('integration_settings_page_settings_new_start_date'),
        },
      ];
    },
    initTips() {
      this.tips = [
        {
          image: vueSvgIcon(tipImage, 205, 120),
          text: this.locales('integration_slack_settings_page_tip_1'),
        },
        {
          image: vueSvgIcon(tipImage, 205, 120),
          text: this.locales('integration_slack_settings_page_tip_2'),
        },
        {
          image: vueSvgIcon(tipImage, 205, 120),
          text: this.locales('integration_slack_settings_page_tip_3'),
        },
        {
          image: vueSvgIcon(tipImage, 205, 120),
          text: this.locales('integration_slack_settings_page_tip_4'),
        },
      ];
    },
    loadData() {
      const that = this;

      return new Promise((resolve, reject) => {
        integrationsModel.getDataFromBackend().then(result => {
          const integration = integrationsModel.getByType('slack');
          const currentUserConnected = !!integration.users.find(el => el.user_id === GT.user.id);

          if (!integration.isActive || !currentUserConnected) {
            routerHelper.changeRoute('/');
            resolve();
          } else {
            integrationSlackChannelsModel.getData()
              .then(channelsOptions => {
                const _channelsOptions = channelsOptions && channelsOptions.length ? channelsOptions.slice() : [];

                that.channelsOptions = _channelsOptions.map((item, index) => ({
                  id: item.id,
                  value: item.name,
                }));
              })
              .then(integrationSlackModel.getData)
              .then(items => {
                that.projectChannelConnections = that.filterByProjects(items);

                return null;
              })
              .then(() => {
                that.projectsOptions = projectsModel.getAllProjects().filter(item => !item.is_jira).map(item => ({
                  id: item.gantt_id,
                  value: item.name,
                }));
                resolve();
              })
              .catch(err => console.error(err));
          }
        });
      })
        .catch(err => console.error(err));
    },
    filterByProjects(items) {
      const projectIds = projectsModel.getAllProjects().map(el => el.gantt_id);

      return items.filter(el => projectIds.indexOf(el.gantt_id) !== -1);
    },
    onClickAdd() {
      this.closeEditMode();
      this.addMode = true;
    },
    validateForm() {
      let result = true;

      if (!this.selectedInputs.projectId) {
        result = false;
        this.projectIsEmptyError = true;
      }

      if (!this.selectedInputs.channelId) {
        result = false;
        this.channelIsEmptyError = true;
      }

      return result;
    },
    addItem() {
      if (this.isDisableOkButton) {
        return;
      }

      const that = this;
      const status = that.validateForm();

      if (status) {
        const channelOption = that.channelsOptions.find(el => el.id === that.selectedInputs.channelId);

        this.isDisableOkButton = true;
        integrationSlackModel.addConnection({
          type: 'slack',
          ganttId: that.selectedInputs.projectId,
          channelId: that.selectedInputs.channelId,
          settings: that.selectedInputs.settings,
          channelName: channelOption.value,
        }).then(result => {
          if (result.status === 'ok') {
            that.projectChannelConnections.push(result.item);
            that.closeAddMode();
            that.$nextTick(() => {
              document.querySelector('.integration_slack_settings_window-body')
                .scrollTo(0, document.querySelector('.integration_slack_settings_window-body').scrollHeight);
            });
          }
          this.isDisableOkButton = false;
        });
      }
    },
    closeAddMode() {
      this.clearSelectedInputs();
      this.addMode = false;
      this.projectIsEmptyError = false;
      this.channelIsEmptyError = false;
    },
    clearSelectedInputs() {
      this.selectedInputs.projectId = null;
      this.selectedInputs.channelId = null;
      this.selectedInputs.settings = null;
    },
    clearEditTempInputs() {
      this.editTempInputsValues.projectId = null;
      this.editTempInputsValues.channelId = null;
      this.editTempInputsValues.settings = null;
    },
    onChangeProjectId(value) {
      this.selectedInputs.projectId = +value;
      this.projectIsEmptyError = false;
      if (this.selectedInputs.channelId) {
        this.projectChannelConnections.forEach(_el => {
          if (+_el.gantt_id === +this.selectedInputs.projectId) {
            if (_el.channel_id === this.selectedInputs.channelId) {
              this.selectedInputs.channelId = null;
            }
          }
        });
      }

      this.onChangesFlag++;
    },
    onChangeChannelId(value) {
      this.selectedInputs.channelId = value;
      this.channelIsEmptyError = false;
      this.onChangesFlag++;
    },
    onChangeSettings(value) {
      this.selectedInputs.settings = value;
    },
    onFocusSelect(el) {
      this.selectedSelect = el;
    },
    getProjectByGanttId(ganttId) {
      return this.projects.find(el => el.gantt_id === +ganttId);
    },
    getSettingsString(item) {
      const settingsArrayIds = [];
      const settingsArrayValues = [];

      if (item.setting_new_task) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'new_task')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'new_task')[0].value);
      }
      if (item.setting_status) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'status')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'status')[0].value);
      }
      if (item.setting_comment) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'comment')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'comment')[0].value);
      }
      if (item.setting_attachment) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'attachment')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'attachment')[0].value);
      }
      if (item.setting_assignee) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'assignee')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'assignee')[0].value);
      }
      if (item.setting_new_start_date) {
        settingsArrayIds.push(this.settingsOptions.filter(item => item.id === 'new_start_date')[0].id);
        settingsArrayValues.push(this.settingsOptions.filter(item => item.id === 'new_start_date')[0].value);
      }

      const settingsText = settingsArrayValues.length ? settingsArrayValues.join(', ') : '-';
      const settingsValue = settingsArrayIds.length ? settingsArrayIds.join(',') : '';

      return {
        settingsText,
        settingsValue,
      };
    },
    toggleEditView(channelProjectId) {
      const that = this;

      that.clearEditTempInputs();
      that.closeAddMode();

      this.editMode = true;

      this.projectChannelConnections = this.projectChannelConnections.map((el, index) => {
        Vue.set(this.projectChannelConnections[index], 'editMode', false);
        if (el.id === channelProjectId) {
          Vue.set(this.projectChannelConnections[index], 'editMode', true);
          that.selectedInputs.projectId = that.projectChannelConnections[index].gantt_id;
          that.selectedInputs.channelId = that.projectChannelConnections[index].channel_id;
          that.selectedInputs.settings = that.getSettingsString(that.projectChannelConnections[index]).settingsValue;
          that.editTempInputsValues.projectId = that.projectChannelConnections[index].gantt_id;
          that.editTempInputsValues.channelId = that.projectChannelConnections[index].channel_id;
          that.editTempInputsValues.settings = that.getSettingsString(that.projectChannelConnections[index]).settingsValue;
        }

        return el;
      });
    },
    editItem(id) {
      const that = this;
      const status = that.validateForm();

      if (status) {
        const channelOption = that.channelsOptions.find(el => el.id === that.selectedInputs.channelId);

        integrationSlackModel.editConnection({
          channelProjectId: id,
          ganttId: that.selectedInputs.projectId,
          channelId: that.selectedInputs.channelId,
          channelName: channelOption.value,
          settings: that.selectedInputs.settings,
        }).then(result => {
          if (result.status === 'ok') {
            that.projectChannelConnections.push(result.item);
            that.deleteProjectChannelConnectionById(id);
            that.closeEditMode();
          }
        });
      }
    },
    closeEditMode() {
      const that = this;

      that.projectIsEmptyError = false;
      that.channelIsEmptyError = false;
      that.clearSelectedInputs();
      that.clearEditTempInputs();
      this.editMode = false;

      this.projectChannelConnections = this.projectChannelConnections.map((el, index) => {
        Vue.set(this.projectChannelConnections[index], 'editMode', false);

        return el;
      });
    },
    deleteItem(channelProjectId) {
      const that = this;

      integrationSlackModel.removeConnection({ id: channelProjectId })
        .then(result => {
          if (result.status === 'ok') {
            that.deleteProjectChannelConnectionById(channelProjectId);
          }
        });
    },
    deleteProjectChannelConnectionById(channelProjectId) {
      const that = this;
      let _index = -1;

      that.projectChannelConnections.forEach((item, i) => {
        if (item.id === channelProjectId) {
          _index = i;
        }
      });

      if (_index !== -1) {
        that.projectChannelConnections.splice(_index, 1);
        that.onChangesFlag++;
      }
    },
    removeIntegration() {
      const that = this;

      webix.confirm({
        text: __('integration_remove_integration_slack'),
        ok: __('integration_remove_integration_ok'),
        cancel: __('integration_remove_integration_cancel'),
        callback(answer) {
          if (answer) {
            userExtAnalytics.log('integration_disconnect', { isOwner: true, type: 'slack' });
            userExtAnalytics.log('intergraration_slack_owner_disconnect');

            integrationsModel.removeIntegrationFromTeam('slack').then(() => {
              that.closeWindow();
            })
              .catch(err => console.error(err));
          }
        },
      });
    },
    disableIntegration() {
      const that = this;

      webix.confirm({
        text: __('integration_disconnect_admin_slack'),
        ok: __('integration_disconnect_admin_ok'),
        cancel: __('integration_disconnect_admin_cancel'),
        callback(answer) {
          if (answer) {
            const integration = integrationsModel.getByType('slack');

            integrationsModel.disconnectIntegrationUser('slack', +integration.id, +integration.settings.id)
              .then(data => {
                that.closeWindow();
              })
              .catch(err => console.error(err));
          }
        },
      });
    },
    closeWindow() {
      routerHelper.backRoute();
    },
  },
};
</script>

<style scoped lang="less">
.integration_slack_settings_window-header {
  position: relative;
}
</style>
