<template>
  <div>
    <webix-ui :config="webixUI" />
  </div>
</template>

<script>
import { webixUI, init, cleanup } from './_jiraProject';

export default {
  name: 'JiraProject',
  data() {
    return {
      webixUI,
    };
  },
  mounted() {
    const component = this;

    this.$nextTick(() => {
      init();
    });
  },
  beforeDestroy() {
    cleanup();
  },
};
</script>

<style scoped>
</style>
