import VueRouter from '../router/main';
import _ from '../libs/lodash';
import app from '../app';

function analyticsWorkload() {
  if (isCommonWorkloadViewRoute()) {
    userExtAnalytics.log('workload_open', { from: 'general' });
  }
}

const changeRoute = (path = '/', query = {}) => {
  if (VueRouter) {
    VueRouter.push({
      path,
      query,
    });
    analyticsWorkload();
  }
};

const pushRoute = route => {
  if (VueRouter) {
    VueRouter.push(route);
    analyticsWorkload();
  }
};

const pushByNameAndParams = (name = 'app', params = {}) => {
  // console.log('route param', name, { ...VueRouter.currentRoute.params, ...params })
  if (VueRouter) {
    VueRouter.push({
      name,
      params: { ...VueRouter.currentRoute.params, ...params },
    });
  }
};
const pushQuery = query => {
  changeRoute(getCurrentRoute().path, query);
};

const getCurrentRoute = () => VueRouter.currentRoute;

const isGanttRoute = () => {
  const route = VueRouter.currentRoute;
  return (route.name === 'project' || route.params.parent === 'project') && route.params.mode === 'gantt';
};

const isGanttGridRoute = () => {
  const route = VueRouter.currentRoute;
  return (route.name === 'project' || route.params.parent === 'project' || isMultiProjectsRoute()) && route.params.mode === 'gantt';
};

const isMultiProjectsRoute = () => {
  const route = VueRouter.currentRoute;

  if (app.config.mode.isExport || app.config.mode.isLink) {
    return gantt.config.multiview;
  }

  return (route.name === 'projectsMultiView' || route.name === 'multiviewTaskRoute');
};

const isBoardRoute = () => {
  const route = VueRouter.currentRoute;

  return route.params.mode === 'board';
};

const isSingleProjectRoute = () => {
  const route = VueRouter.currentRoute;

  return route.name === 'project' || route.name === 'app' || route.params.parent === 'project';
};

const isColumnsSettingsRoute = () => {
  const route = VueRouter.currentRoute;

  return route.path === '/settings/columns';
};

const isProfileSettings = () => {
  const route = VueRouter.currentRoute;

  return route.path.startsWith('/settings');
};

const isTaskSettingsRoute = (routeToCheck = null) => {
  const route = routeToCheck || VueRouter.currentRoute;
  const taskRoutes = ['taskRoute', 'listTaskRoute', 'multiviewTaskRoute', 'workloadTaskRoute'];
  return _.find(taskRoutes, name => name === route.name);
};

const isListViewRoute = () => {
  const route = VueRouter.currentRoute;
  return (route.name === 'listView' || route.name === 'listTaskRoute');
};

const isCommonRoute = () => {
  return !isSingleProjectRoute() && !isMultiProjectsRoute();
}

const isProjectList = () => {
  const route = VueRouter.currentRoute;
  return ( (route.name === 'project' || route.name === 'taskRoute' ) && route.params.mode === 'list');
};

const isMultiviewList = () => {
  const route = VueRouter.currentRoute;
  return ( (route.name === 'projectsMultiView' || route.name === 'multiviewTaskRoute') && route.params.mode === 'list');
};

const isTeamRoyte = () => {
  const route = VueRouter.currentRoute;
  return route.name === 'teamAndResources';
};

const isWorkloadViewRoute = () => {
  const route = VueRouter.currentRoute;
  return (route.name === 'workload' || route.name === 'workloadTaskRoute' || route.params.mode === 'workload');
};

const isCommonWorkloadViewRoute = () => {
  const route = VueRouter.currentRoute;

  return (route.name === 'workload' || route.name === 'workloadTaskRoute');
};

const isTrialCompleteViewRoute = () => {
  const route = VueRouter.currentRoute;

  return (route.name === 'trialCompleteUser' || route.name === 'trialCompleteOwner');
};

const isNoProjectViewRoute = () => {
  const route = VueRouter.currentRoute;

  return route.name === 'noProject';
};

const isExportRoute = () => {
  const route = VueRouter.currentRoute;

  return route.params.mode === 'export';
};

const backRoute = () => {
  VueRouter.back();
};

const analyticsFromRoute = () => {
  let from = '';
  const url = VueRouter.currentRoute.path;
  const urlArr = url.slice(1).split('/');
  const urlItems = urlArr.filter(item => !Number.parseInt(item));

  switch (urlItems[0]) {
  case 'project': from = 'project & portfolio'; break;
  case 'projects': from = 'project & portfolio'; break;
  case 'tasks': from = 'my tasks'; break;
  case 'workload': from = 'workload'; break;
  case 'timelog': from = 'my time log'; break;
  case 'team': from = 'team && resourse'; break;
  default: from = urlItems[0];
  }
  return from;
};

const analyticsRoute = () => {
  let route = '';
  const url = VueRouter.currentRoute.path;
  const urlArr = url.slice(1).split('/');
  const urlItems = urlArr.filter(item => !Number.parseInt(item));

  switch (urlItems[0]) {
  case 'project': route = `Project/${urlItems[1]}`; break;
  case 'projects': route = `Portfolio/${urlItems[1]}`; break;
  case 'tasks': route = 'MyTasks'; break;
  case 'workload': route = 'Workload'; break;
  case 'timelog': route = 'MyTasks'; break;
  default: route = url;
  }
  return route;
};

export default {
  analyticsRoute,
  analyticsFromRoute,
  isGanttGridRoute,
  changeRoute,
  pushRoute,
  pushQuery,
  getCurrentRoute,
  pushByNameAndParams,
  isGanttRoute,
  isBoardRoute,
  isSingleProjectRoute,
  isColumnsSettingsRoute,
  isTaskSettingsRoute,
  isMultiProjectsRoute,
  isListViewRoute,
  isWorkloadViewRoute,
  isExportRoute,
  isCommonWorkloadViewRoute,
  isTrialCompleteViewRoute,
  isNoProjectViewRoute,
  backRoute,
  isTeamRoyte,
  isMultiviewList,
  isProjectList,
  isProfileSettings,
  isCommonRoute
};
