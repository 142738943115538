<template>
  <div class="cell-task-project">
    <span> {{ item[extra ? propertyExtra : property] }} </span>
    <div
      v-if="extra"
      class="tooltip-gantt icon-projects"
      :data-key="item[extra ? propertyExtra : property]"
      :data-y="-5"
    />
  </div>
</template>

<script>

export default {
  name: 'ProjectCell',
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    propertyExtra: { type: String, required: true },
    extra: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
  },
};
</script>

<style scoped lang='less'>
.cell-task-project{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
    span{
      color:  rgba(69,69,69,0.5);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  .icon-projects{
    margin-left: 4px;
    width: 18px;
    height: 20px;
    flex-shrink: 0;
    background: url(https://cdn.ganttpro.com/app/imgs/ic_portfolio.svg) center no-repeat;

    &:hover{
        background: url(https://cdn.ganttpro.com/app/imgs/ic_portfolio_hover.svg) center no-repeat;
    }
  }
}
</style>
