import twoFaModel from '../../models/twoFa';

const twoFaStore = {
  namespaced: true,
  state: {
    qrPic: '',
    isActiveTwoFa: false,
  },
  getters: {
    qrPic: state => state.qrPic,
    isActiveTwoFa: state => state.isActiveTwoFa,
  },
  mutations: {
    setQrPic(state, pic) {
      state.qrPic = pic;
    },
    enableTwoFa(state, flag) {
      state.isActiveTwoFa = flag;
    }
  },
  actions: {
    loadQR({ commit }) {
      twoFaModel.getQR().then(qrData => {
        commit('setQrPic', qrData.qrcode);
      });
    },
  },
};

export default twoFaStore;
