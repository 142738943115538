var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      class: _vm.$style.select,
    },
    [
      _c("vgp-icon-button", {
        ref: "contextMenuButton",
        class: [],
        attrs: {
          icon: { name: _vm.showMenu ? "arrow-top" : "arrow-down", size: 20 },
          label: _vm.locales("group_by_label"),
          "icon-side": "right",
          colors: _vm.buttonColors,
          active: _vm.showMenu || _vm.activeItems.length,
        },
        on: { onClick: _vm.toggleShow },
      }),
      _vm._v(" "),
      _vm.activeItems.length
        ? _c(
            "div",
            { class: _vm.$style.chips },
            _vm._l(_vm.activeItems, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: _vm.$style.chips_item,
                  on: {
                    click: function ($event) {
                      return _vm.onClick(item.id)
                    },
                  },
                },
                [
                  _c("div", { class: _vm.$style.text }, [
                    _vm._v(_vm._s(item.text)),
                  ]),
                  _vm._v(" "),
                  _c("svg-sprite", {
                    class: _vm.$style.icon,
                    attrs: { name: "close-2", type: "bold", size: "20" },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showMenu
        ? _c("context-menu", {
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: [
                            _vm.$style.menu_item,
                            _vm.$style.item_check,
                            _vm.activeIds.includes(item.id)
                              ? _vm.$style.active
                              : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.onClick(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.$style.checkbox },
                            [
                              _c("vgp-checkbox", {
                                attrs: {
                                  size: "16",
                                  checked: _vm.activeIds.includes(item.id),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { class: _vm.$style.text }, [
                            _vm._v(
                              "\n          " + _vm._s(item.text) + "\n        "
                            ),
                          ]),
                        ]
                      )
                    })
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4066783627
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }