import plansModel from '../../models/plans';
import globalStore from '../../store/main';

export const VIDEO_GUIDE_YOUTUBE_ID = 'V1FwzVXxiZM';

export const VIDEO_GUIDE_TIME_CODES = [
  {
    id: 1, title: __('lc_video_interface_overview'), key_analytics: 'Interface overview', second: 37,
  },
  {
    id: 2, title: __('lc_video_create_a_new_project'), key_analytics: 'Create a new project', second: 193,
  },
  {
    id: 3, title: __('lc_video_add_tasks'), key_analytics: 'Add tasks', second: 211,
  },
  {
    id: 4, title: __('lc_video_customize_fields'), key_analytics: 'Customize fields', second: 222,
  },
  {
    id: 5, title: __('lc_video_arrange_tasks'), key_analytics: 'Arrange tasks', second: 238,
  },
  {
    id: 6, title: __('lc_video_add_team_members'), key_analytics: 'Add team members', second: 262,
  },
  {
    id: 7, title: __('lc_video_configure_access_rights'), key_analytics: 'Configure access rights', second: 281,
  },
  {
    id: 8, title: __('lc_video_track_workload'), key_analytics: 'Track workload', second: 343,
  },
  {
    id: 9, title: __('lc_video_allocate_effort'), key_analytics: 'Allocate effort', second: 385,
  },
  {
    id: 10, title: __('lc_video_set_up_project_calendar'), key_analytics: 'Set up project calendar', second: 412,
  },
  {
    id: 11, title: __('lc_video_plan_budget'), key_analytics: 'Plan budget', second: 465,
  },
  {
    id: 12, title: __('lc_video_manage_risks'), key_analytics: 'Manage risks', second: 501,
  },
  {
    id: 13, title: __('lc_video_collaborate'), key_analytics: 'Collaborate', second: 670,
  },
  {
    id: 14, title: __('lc_video_share_a_project'), key_analytics: 'Share a project', second: 716,
  },
  {
    id: 15, title: __('lc_video_create_project_portfolio'), key_analytics: 'Create project portfolio', second: 761,
  },
];

export const POPUP_TAB = {
  GUIDE: 'guide',
  NEWS: 'news',
};

export const POPUP_TABS = [
  {
    title: __('app_learnwnd_tab_setup'),
    type: POPUP_TAB.GUIDE,
    analyticType: 'setup guide',
  },
  {
    title: __('app_learnwnd_tab_whatsnew'),
    type: POPUP_TAB.NEWS,
    analyticType: "what's new",
  },
];

export const POPUP_LINKS = [
  {
    name: __('app_learnwnd_tab_videos'),
    analyticType: 'videos',
    newTabIcon: true,
    clickHandler: () => window.open(__('app_links_gantt_videos'), '_blank'),
  },
  {
    name: __('app_learnwnd_tab_help'),
    analyticType: 'help center',
    newTabIcon: true,
    clickHandler: () => window.open(__('app_links_help_center'), '_blank'),
  },
  {
    name: __('app_learnwnd_tab_demo'),
    analyticType: 'demo',
    hidden: !!plansModel.isPaidTeam() || user.settings.user_scoring_percent === 0,
    newTabIcon: false,
    clickHandler: () => {
      globalStore.dispatch('footer/demoClickHandler', { campaign: 'appLearningCenterTab' });
    },
  },
];
