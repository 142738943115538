export default {
  $onurl: function (route, config, arrayRoute, webixDom) {
    if (user.userRole !== "admin" && user.userRole !== "manager" && route === "dashboard") {
      window.location.assign("/");
      return false;
    }

    if (route === "importpreview" && !GT.importManual) {
      window.location.assign("/");
      return false;
    }
  }
};