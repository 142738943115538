<template>
  <div :class="$.chart_wrapper">
    <div ref="chart" :class="[$.chart_body, flexGrow && $.flex_grow]"></div>
    <div :class="$.no_data_message" v-if="!dataExists">{{ locale('chart_portfolio_milestones_date_no_items') }}</div>
    <div :class="[$.chart_legend, small && $.no_padding]">
      <div :class="[$.legend_item, !item.shouldFilter && $.disabled]" v-for="(item, i) in legend" :key="item.label"
        @click="toggleLegendItem(i)">
        <div :class="$.color_box" :style="{ backgroundColor: item.color }"></div>
        <div>{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from '../../../../libs/echarts';
import _ from 'lodash';
import helpers from '../../../../helpers/custom';
import constants from '../../../../helpers/constants';

export default {
  name: 'MilestonesChart',
  props: {
    flexGrow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {}
    },
    currentYear: {
      type: Number,
      default: new Date().getFullYear()
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const propsMilestones = [...this.data.milestones]
      .map(({ text, end_date, projectName, statusName, statusColor }) => ({
        name: text,
        date: end_date,
        project: projectName,
        status: statusName,
        color: statusColor
      })).sort((prev, next) =>
        prev.date > next.date ? 1 : prev.date < next.date ? -1 : 0
      );
    if (this.randomData) {
      this.milestones = [...Array(75).keys()]
        .map((item, i) => {
          const stateAndColor = this.randomStateAndColor();
          return {
            status: stateAndColor.label,
            color: stateAndColor.color,
            date: this.randomDate(),
            name: `Milestone ${i}: ${stateAndColor.label}`,
            project: 'Project ' + i
          };
        })
        .sort((prev, next) =>
          prev.date > next.date ? 1 : prev.date < next.date ? -1 : 0
        );
    } else {
      this.milestones = propsMilestones;
    }
    this.$nextTick(() => {
      this.legend = _.uniqBy(propsMilestones.filter((milestone) => {
        return milestone.date.getFullYear() === this.currentYear;
      }), 'status').map(({ status, color }) => ({
        label: status,
        color,
        shouldFilter: true
      })).sort((prev, next) => this.allTaskStatuses[prev.label].order - this.allTaskStatuses[next.label].order);

      this.$nextTick(() => {
        this.configureChart();
      });
    });

    const minYear = propsMilestones[0]?.date.getFullYear();
    const maxYear = propsMilestones[propsMilestones.length - 1]?.date.getFullYear();
    minYear && maxYear && this.$emit('setYearRange', { minYear, maxYear });
  },
  data() {
    return {
      locale: __,
      randomData: false,
      instance: null,
      legend: [],
      milestones: [],
      dataExists: true,
      moreShowing: false
    }
  },
  computed: {
    allTaskStatuses() {
      return Object.keys(constants.TASK_STATUSES).sort((prev, next) => prev - next).reduce((acc, key) => {
        const label = this.locale(constants.TASK_STATUSES[key].locale);
        acc[label] = {
          label,
          order: key
        };

        return acc;
      }, {});
    }
  },
  watch: {
    currentYear(newVal) {
      this.legend = _.uniqBy(
        [...this.milestones].filter((milestone) => {
          return milestone.date.getFullYear() === newVal;
        }),
        'status'
      ).map(({ status, color }) => ({
        label: status,
        color,
        shouldFilter: true
      })).sort((prev, next) => this.allTaskStatuses[prev.label].order - this.allTaskStatuses[next.label].order);

      this.$nextTick(() => {
        this.configureChart();
      });
    }
  },
  methods: {
    toggleLegendItem(index) {
      this.legend[index].shouldFilter = !this.legend[index].shouldFilter;
      this.$nextTick(() => {
        this.configureChart();
      });
    },
    randomStateAndColor() {
      const randomIndex = Math.floor(Math.random() * 4);

      return this.legend[randomIndex];
    },
    randomDate() {
      let start = new Date(new Date().getFullYear(), 0, 1); // Начало текущего года
      let end = new Date(new Date().getFullYear(), 11, 31); // Конец текущего года
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      );
    },
    noDataMilestones() {
      return [
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 0, 1),
          project: 'Project',
          status: '',
          color: '#C8C8C8',
          widths: [40, 120, 80]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 0, 2),
          project: 'Project',
          status: '',
          color: '#D7D7D7',
          widths: [40, 120, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 0, 3),
          project: 'Project',
          status: '',
          color: '#E5E5E5',
          widths: [40, 80, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 1, 1),
          project: 'Project',
          status: '',
          color: '#C8C8C8',
          widths: [40, 80, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 1, 2),
          project: 'Project',
          status: '',
          color: '#D7D7D7',
          widths: [40, 120, 80]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 1, 3),
          project: 'Project',
          status: '',
          color: '#E5E5E5',
          widths: [40, 80, 80]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 1, 4),
          project: 'Project',
          status: '',
          color: '#E5E5E5',
          widths: [40, 120, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 2, 1),
          project: 'Project',
          status: '',
          color: '#D7D7D7',
          widths: [40, 120, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 8, 1),
          project: 'Project',
          status: '',
          color: '#C8C8C8',
          widths: [40, 80, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 8, 2),
          project: 'Project',
          status: '',
          color: '#D7D7D7',
          widths: [40, 120, 120]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 8, 3),
          project: 'Project',
          status: '',
          color: '#E5E5E5',
          widths: [40, 120, 80]
        },
        {
          name: 'Milestone',
          date: new Date(this.currentYear, 9, 1),
          project: 'Project',
          status: '',
          color: '#C8C8C8',
          widths: [40, 120, 80]
        }
      ];
    },
    calcTipPosition(tipElem, mouseEvent) {
      let posX = mouseEvent.offsetX + 30;

      if (posX + tipElem.clientWidth > this.instance.getWidth()) {
        posX = mouseEvent.offsetX - 30 - tipElem.clientWidth;
      }

      let posY = mouseEvent.offsetY + 30;

      if (posY + tipElem.clientHeight > this.instance.getHeight()) {
        posY = mouseEvent.offsetY - 30 - tipElem.clientHeight;
      }

      return [posX, posY];
    },
    configureChart() {
      let firstY = 1;
      let data = [];

      const shouldDisplay = this.legend.filter((item) => item.shouldFilter).map((item) => item.label).join(',');
      let milestones = [...this.milestones].filter((milestone) => {
        return shouldDisplay.includes(milestone.status) && milestone.date.getFullYear() === this.currentYear;
      });

      const dataExists = milestones.length;
      this.dataExists = dataExists;

      if (!dataExists) {
        milestones = this.noDataMilestones();
      }

      let maxY = this.small ? 2 : 3;
      let minY = -maxY;
      const containerHeight = this.$refs.chart?.clientHeight;
      if (containerHeight > 0) {
        const maxYAbs = Math.floor((containerHeight - 24) / 60 / 2);
        maxY = maxYAbs;
        minY = -maxYAbs;
      }

      milestones = milestones.reduce((acc, curr) => {
        const month = curr.date.getMonth();
        if (!acc[month]) {
          acc[month] = [];
        }

        acc[month].push(curr);

        return acc;
      }, {});
      for (const month in milestones) {
        const aboveAxis = month % 2 === 0;
        const x = +month + (+month === 11 ? 0.9 : 0.1);
        const items = milestones[month].map((item, i) => {
          const y = (firstY + i) * (aboveAxis ? 1 : -1);

          return {
            value: [x, y],
            itemStyle: {
              color: item.color
            },
            data: {
              ...item,
              isLast: +month === 11
            }
          }
        });
        if (items.length <= maxY) {
          data.push(...items);
        } else {
          const withText = items.slice(0, maxY - 1);
          const withoutText = items.slice(maxY - 1, maxY + 2).map((item, i) => {
            const y = aboveAxis ? maxY - 0.8 + i * 0.2 : minY + 0.8 - i * 0.2;
            return {
              ...item,
              value: [x, y],
              data: {
                ...item.data,
                noText: true
              }
            };
          });
          data.push(
            ...withText,
            ...withoutText
          );

          if (items.length > withText.length + withoutText.length) {
            data.push({
              itemStyle: {
                color: '#F2F2F2'
              },
              value: [
                x,
                aboveAxis ? maxY : minY,
              ],
              data: {
                isMore: true,
                items,
                label: `More ${items.length - (withText.length + withoutText.length)}`
              }
            });
          }
        }
      }

      const dataBars = [];

      [...data].reverse().forEach((item, i) => {
        if (!i) {
          dataBars.push(item.value);
          return;
        }
        const lastElem = dataBars[dataBars.length - 1];
        if (lastElem && lastElem[0] !== item.value[0]) {
          dataBars.push(item.value);
        }
      });

      let axisLineData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        .map((month, i) => ([i, 0, month, 1]));

      let splitLinesData = [...axisLineData];
      splitLinesData.push([12, 0]);

      let todayDate = this.randomData ? this.randomDate() : new Date();
      let todayString = todayDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
      let todayX = +todayDate.getMonth() + +(todayDate.getDate() / new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0).getDate()).toFixed(2);
      let todayData = todayDate.getFullYear() === this.currentYear && dataExists ? [[
        todayX,
        0,
        todayString
      ]] : [];

      this.option = {
        grid: {
          left: 30,
          top: 0,
          right: 30,
          bottom: 0
        },
        xAxis: {
          type: 'value',
          min: 0,
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          min: minY - 0.2,
          max: maxY + 0.8
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'none',
          enterable: false,
          confine: true,
          className: 'milestones-popup'
        },
        series: [
          {
            data: todayData,
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
              var categoryIndex = api.value(0);
              var start = api.coord([categoryIndex, 0]);

              var dashedLine = {
                x: start[0],
                y: params.coordSys.y,
                height: params.coordSys.height
              };

              let pointer = {
                width: 6 * 2 / Math.sqrt(2),
                height: 6 * 2 / Math.sqrt(2),
                r: [2, 2, 2, 2]
              };

              let todayTextX = start[0] - 43;
              let todayText = {
                x: todayTextX < 0 ? 0 : (todayTextX + 86 > params.coordSys.width + params.coordSys.x ? params.coordSys.x + params.coordSys.width - 76 : todayTextX),
                y: params.coordSys.y,
                width: 86,
                height: 22,
                r: [4, 4, 4, 4]
              };

              return (
                {
                  type: 'group',
                  children: [
                    {
                      type: 'rect',
                      shape: dashedLine,
                      style: {
                        stroke: '#FF343480',
                        lineWidth: 2,
                        lineDash: [5, 5]
                      }
                    },
                    {
                      type: 'rect',
                      shape: pointer,
                      x: start[0] - 6,
                      y: params.coordSys.y + 22,
                      z2: 1,
                      rotation: 0.785398,
                    },
                    {
                      type: 'rect',
                      shape: todayText,
                      z2: 1,
                      textContent: {
                        type: 'text',
                        style: {
                          text: `Today ${api.value(2)}`,
                          fill: '#ffffffcc',
                          font: '12px "Lato-Regular", sans-serif',
                          width: api.size([1.2, 0])[0],
                          overflow: 'truncate'
                        }
                      },
                      textConfig: {
                        position: 'inside'
                      }
                    }
                  ]
                }
              );
            }
          },
          {
            data: axisLineData,
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
              let categoryIndex = api.value(0);
              let start = api.coord([categoryIndex, 0]);

              let rectShape = {
                x: start[0],
                y: start[1] - 12,
                width: api.size([1, 0])[0],
                height: 24
              };

              return (
                rectShape && {
                  type: 'rect',
                  shape: rectShape,
                  style: {
                    fill: dataExists ? '#E5E5E5' : '#F2F2F2'
                  },
                  textContent: {
                    type: 'text',
                    style: {
                      text: api.value(2),
                      fill: dataExists ? '#191919' : '#ffffff00',
                      font: '14px "Lato-Regular"',
                      width: api.size([1.2, 0])[0],
                      overflow: 'truncate'
                    }
                  },
                  textConfig: {
                    position: 'inside'
                  }
                }
              );
            }
          },
          {
            data: splitLinesData,
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
              let categoryIndex = api.value(0);
              let start = api.coord([categoryIndex, 0]);

              let rectShape = {
                x: start[0],
                y: params.coordSys.y,
                width: 1,
                height: params.coordSys.height
              };

              return (
                rectShape && {
                  type: 'rect',
                  shape: rectShape,
                  style: {
                    fill: dataExists ? '#E5E5E5' : '#E4E4E4'
                  }
                }
              );
            }
          },
          {
            data: dataBars,
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
              let categoryIndex = api.value(0);
              let start = api.coord([categoryIndex, api.value(1)]);
              let y = api.value(1) > 0 ? api.coord([0, api.value(1)])[1] : api.coord([categoryIndex, 0])[1] + 12;
              let x = start[0] - 0.5;
              let bar = {
                width: 1,
                height: api.size([0, api.value(1)])[1] - 12
              };
              let base = {
                x: start[0] - 2,
                y: y + (api.value(1) > 0 ? bar.height - 1 : 0),
                width: 4,
                height: 1
              };

              return (
                bar && {
                  type: 'group',
                  children: [
                    {
                      type: 'rect',
                      shape: bar,
                      x: x,
                      y: y,
                      style: {
                        fill: dataExists ? '#000' : '#C8C8C8'
                      },
                      enterFrom: {
                        y:
                          api.coord([categoryIndex, 0])[1] +
                          (api.value(1) > 0 ? -12 : 12),
                        scaleY: 0
                      },
                      enterAnimation: {
                        delay: 20 * (params.dataInsideLength - params.dataIndex),
                        duration: 100
                      },
                      transition: 'all'
                    },
                    {
                      type: 'rect',
                      shape: base,
                      style: {
                        fill: dataExists ? '#000' : '#C8C8C8'
                      },
                      enterFrom: {
                        style: { opacity: 0 }
                      }
                    }
                  ]
                }
              );
            }
          },
          {
            data: [[0,0]],
            type: 'custom',
            name: 'blankSpace',
            z: 10,
            renderItem: (params, api) => {
              const rectShape = {
                x: params.coordSys.x - 30,
                y: params.coordSys.y,
                width: params.coordSys.width + 60,
                height: params.coordSys.height
              };

              return {
                type: 'rect',
                shape: rectShape,
                invisible: true
              }
            }
          },
          {
            data: data,
            type: 'custom',
            name: 'milestones',
            silent: !dataExists,
            z: 20,
            tooltip: {
              formatter: (params) => {
                const dataItem = data[params.dataIndex].data;

                if (!dataItem.isMore) {
                  return `
                    <div class="content single">
                      <div class="milestone-date">${dataItem.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</div>
                      <div class="milestone-name font-14 break-words two-lines">${helpers.sanitizeText(dataItem.name)}</div>
                      <div class="milestone-project break-words two-lines">${helpers.sanitizeText(dataItem.project)}</div>
                    </div>
                  `;
                }

                const html = dataItem.items.map((item, i, arr) => {
                  return `
                    <div class="item">
                      <div class="icon" style="background: ${item.itemStyle.color};"></div>
                      <div class="milestone-details">
                        <div class="milestone-date">${item.data.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</div>
                        <div class="milestone-name break-words two-lines">${helpers.sanitizeText(item.data.name)}</div>
                        <div class="milestone-project break-words two-lines">${helpers.sanitizeText(item.data.project)}</div>
                      </div>
                    </div>
                  `
                }).join('');

                return `
                  <div class="content list">
                    ${html}
                  </div>
                `;
              }
            },
            renderItem: (params, api) => {
              const dataItem = data[params.dataIndex];
              let categoryIndex = api.value(0);
              let start = api.coord([categoryIndex, api.value(1)]);

              let itemToRender = {};

              if (!dataItem.data.isMore) {
                const iconRect = {
                  type: 'rect',
                  shape: {
                    width: 16 / Math.sqrt(2),
                    height: 16 / Math.sqrt(2)
                  },
                  x: start[0] - 8,
                  y:
                    api.coord([0, api.value(1)])[1],
                  style: {
                    fill: api.style().fill,
                    lineWidth: 1,
                    stroke: '#fff'
                  },
                  enterFrom: {
                    scaleX: 0,
                    y: api.coord([categoryIndex, 0])[1]
                  },
                  enterAnimation: {
                    delay: 20 * (params.dataIndex + 1),
                    duration: 200,
                    easing: 'exponentialOut'
                  },
                  transition: 'all',
                  rotation: 0.785398, // rotation measured in radians
                };

                const { name, project } = dataItem.data;
                const dateStr = dataItem.data.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
                let textContent = [];

                if (!dataItem.data.noText) {
                  textContent = [
                    {
                      text: dateStr,
                      fill: dataExists ? '#888' : '#ffffff00',
                      fontSize: '12px',
                      noDataWidth: dataItem.data.widths?.[0]
                    },
                    {
                      text: name,
                      fill: dataExists ? '#191919' : '#ffffff00',
                      fontSize: dataExists ? '14px' : '12px',
                      noDataWidth: dataItem.data.widths?.[1]
                    },
                    {
                      text: project,
                      fill: dataExists ? '#191919' : '#ffffff00',
                      fontSize: '12px',
                      noDataWidth: dataItem.data.widths?.[2]
                    }
                  ];
                }

                if (api.value(1) < 0) {
                  textContent = textContent.reverse();
                }

                const textRects = textContent.map(({ text, fill, fontSize, noDataWidth }, i) => ({
                  type: 'rect',
                  shape: {
                    width: dataExists ? api.size([1.2, 0])[0] : noDataWidth,
                    height: dataExists ? 15 : 12,
                    r: 4
                  },
                  x:
                    start[0] +
                    (dataItem.data.isLast ? -api.size([1.2, 0])[0] - 10 : 10),
                  y:
                    api.coord([0, api.value(1)])[1] +
                    (api.value(1) > 0 ? 0 + 15 * i : -10 - 15 * i),
                  style: {
                    fill: dataExists ? '#ffffff40' : '#F2F2F2'
                  },
                  scaleX: 1,
                  enterFrom: {
                    scaleX: 0,
                    x: start[0],
                    y: api.coord([categoryIndex, 0])[1]
                  },
                  enterAnimation: {
                    delay: 20 * (params.dataIndex + 1),
                    duration: 200
                  },
                  transition: 'all',
                  textContent: {
                    type: 'text',
                    textConfig: {
                      offset: 5
                    },
                    style: {
                      text,
                      fill,
                      font: `${fontSize} "Lato-Regular", sans-serif`,
                      width: api.size([1.2, 0])[0],
                      overflow: 'truncate'
                    },
                    enterFrom: {
                      style: { fill: '#ffffff00' }
                    },
                    enterAnimation: {
                      delay: 20 * (params.dataIndex + 1),
                      duration: 200
                    },
                  },
                  textConfig: {
                    position: dataItem.data.isLast ? 'insideRight' : 'insideLeft'
                  }
                }));

                itemToRender = {
                  type: 'group',
                  children: [
                    iconRect,
                    ...textRects
                  ]
                };
              } else {
                itemToRender = {
                  type: 'rect',
                  shape: {
                    width: 70,
                    height: 30,
                    r: [26, 26, 26, 26]
                  },
                  x: start[0] - 35,
                  y:
                    api.coord([0, api.value(1)])[1] - (api.value(1) > 0 ? 13 : 17),
                  style: {
                    fill: dataExists ? api.style().fill : '#F2F2F2',
                    lineWidth: 1,
                    stroke: '#fff'
                  },
                  enterFrom: {
                    scaleX: 0,
                    y: api.coord([categoryIndex, 0])[1]
                  },
                  enterAnimation: {
                    delay: 20 * (params.dataIndex + 1),
                    duration: 200,
                    easing: 'exponentialOut'
                  },
                  transition: 'all',
                  textContent: {
                    type: 'text',
                    textConfig: {
                      offset: 5
                    },
                    enterFrom: {
                      style: { fill: '#ffffff00' }
                    },
                    enterAnimation: {
                      delay: 20 * (params.dataIndex + 1),
                      duration: 200
                    },
                    style: {
                      text: dataItem.data.label.toUpperCase(),
                      fill: dataExists ? '#191919' : '#ffffff00',
                      font: '12px "Lato-Regular", sans-serif',
                      width: api.size([1.2, 0])[0],
                      overflow: 'truncate'
                    }
                  },
                  textConfig: {
                    position: 'inside'
                  },
                  emphasis: {
                    style: {
                      fill: '#E5E5E5'
                    }
                  }
                };
              }

              return itemToRender;
            }
          }
        ]
      };

      if (this.instance) {
        echarts.dispose(this.instance);
      }

      this.$refs.chart.style.height = `${60 * (maxY - minY + 1) + 24}px`;

      if (this.flexGrow) {
        this.$refs.chart.style.flex = 'unset';
      }

      this.instance = echarts.init(this.$refs.chart);
      this.instance.setOption(this.option);

      if (dataExists) {
        this.instance.on('mousemove', { seriesName: 'milestones' }, ({ data, seriesIndex, dataIndex, event }) => {
          if (data.data.isMore) return;

          if (this.moreShowing) return;

          this.instance.dispatchAction({
            type: 'showTip',
            seriesIndex,
            dataIndex,
            position: (point, param, dom, rect, size) => this.calcTipPosition(dom, event)
          });
        });
        this.instance.on('mouseout', ({ data, seriesName, seriesIndex, dataIndex, event }) => {
          if (seriesName !== 'milestones') {
            return;
          }

          if (data.data.isMore) return;

          if (this.moreShowing) return;

          this.instance.dispatchAction({
            type: 'hideTip'
          });
        });
        this.instance.on('click', ({ data, seriesName, seriesIndex, dataIndex, event }) => {
          if (seriesName === 'milestones' && data.data.isMore) {
            this.instance.dispatchAction({
              type: 'showTip',
              seriesIndex,
              dataIndex,
              position: (point, param, dom, rect, size) => this.calcTipPosition(dom, event)
            });
            this.moreShowing = true;

            return;
          }

          if (this.moreShowing) {
            this.instance.dispatchAction({
              type: 'hideTip'
            });
            this.moreShowing = false;
          }

          return;
        });

        this.instance.on('globalout', () => {
          if (this.moreShowing) {
            this.instance.dispatchAction({
              type: 'hideTip'
            });
            this.moreShowing = false;

            return;
          }
        });
      }
    }
  }
}
</script>

<style module="$" src="./less/milestonesChart.less"></style>
<style lang="less">
@import '../../../../less/_fonts.less';
@import '../../../../less/_mixins.less';

.milestones-popup {
  display: none;
  padding: 0 !important;
  box-shadow: 0px 4px 12.5px 0px rgba(0, 0, 0, 0.15) !important;
  pointer-events: all !important;

  .milestone-name {
    opacity: 0.8;
    font-size: 14px;
  }

  .milestone-date {
    opacity: .6;
  }

  .milestone-project {
    opacity: .8;
  }

  .break-words {
    overflow-wrap: break-word;
    white-space: normal;
  }

  .two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .font-14 {
    font-size: 14px;
  }

  .content {
    .FontLatoRegular();
    .fs(12px, 14px);
    color: #191919;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 226px;

    &::-webkit-scrollbar {
      width: 12px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      border: 4px solid #fff;
      background: #19191980;
    }

    &.single {
      min-width: 138px;
      max-width: 198px;
      padding: 8px;
    }

    &.list {
      max-width: 240px;
      min-width: 240px;
      padding: 12px 0 8px 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .item {
        display: flex;
        gap: 8px;
        align-items: baseline;
        padding-bottom: 4px;
        line-height: normal;

        &:not(:last-child) {
          border-bottom: 1px solid #EFEFEF;
        }

        .icon {
          min-width: 8px;
          min-height: 8px;
          max-width: 8px;
          max-height: 8px;
          transform: rotate(45deg);
        }

        .milestone-details {
          max-width: calc(100% - 8px * sqrt(2));
        }
      }
    }
  }
}
</style>