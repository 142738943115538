var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$.progress_toast,
        _vm.currentProgress.status === _vm.status.done
          ? _vm.$.progress_toast__success
          : "",
        _vm.currentProgress.status === _vm.status.fail
          ? _vm.$.progress_toast__error
          : "",
        _vm.config.spinnerType === "progress"
          ? _vm.$.progress_toast__progress_line
          : "",
      ],
    },
    [
      _vm.currentProgress.status === _vm.status.started ||
      _vm.currentProgress.status === _vm.status.update
        ? _c("div", { class: _vm.$.progress_toast__info }, [
            _vm.config.spinnerType === "spinner"
              ? _c(
                  "div",
                  { class: _vm.$.progress_toast__circle_indicator },
                  [_c("spinner")],
                  1
                )
              : _c("div", { class: _vm.$.progress_toast__value }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.currentProgress.progress + "%") +
                      "\n    "
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.$.progress_toast__message,
                style:
                  _vm.config.spinnerType === "spinner"
                    ? { paddingLeft: "40px" }
                    : "",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locales(_vm.currentProgress.title)) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.currentProgress.blockType !== "full" &&
            _vm.currentProgress.spinnerType !== "spinner"
              ? _c(
                  "div",
                  { class: _vm.$.progress_toast__hide },
                  [
                    _c("vgp-button", {
                      attrs: {
                        type: "text",
                        label: _vm.locales("progress_hide_btn"),
                        small: true,
                      },
                      on: {
                        onClick: function ($event) {
                          return _vm.onClickHide(_vm.currentProgress.token)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config.spinnerType === "progress"
              ? _c(
                  "div",
                  { class: _vm.$.progress_toast__linear_indicator },
                  [
                    _c("linear", {
                      attrs: { value: _vm.currentProgress.progress },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentProgress.status === _vm.status.done
        ? _c(
            "div",
            {
              class: [
                _vm.$.progress_toast__info,
                _vm.isMultilineMessage ? _vm.$.progress_toast_one_line : "",
              ],
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$.progress_toast__icon,
                    _vm.isMultilineMessage ? _vm.$.progress_toast_one_line : "",
                  ],
                },
                [
                  _c("sprite", {
                    attrs: { name: "success", type: "bold", size: 24 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                ref: "message",
                class: _vm.$.progress_toast__message,
                domProps: {
                  innerHTML: _vm._s(
                    _vm.locales(
                      _vm.currentProgress.title,
                      _vm.currentProgress.meta
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.progress_toast__close_btn },
                [
                  _c("icon-button", {
                    attrs: { icon: { name: "close-1" }, size: "small" },
                    on: { onClick: _vm.onClose },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentProgress.status === _vm.status.fail
        ? _c("div", { class: _vm.$.progress_toast__info }, [
            _c(
              "div",
              { class: _vm.$.progress_toast__icon },
              [
                _c("sprite", {
                  attrs: { name: "close-2", type: "bold", size: 24 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.$.progress_toast__message }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales(_vm.currentProgress.title)) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$.progress_toast__close_btn },
              [
                _c("icon-button", {
                  attrs: { icon: { name: "close-1" }, size: "small" },
                  on: { onClick: _vm.onClose },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }