import { resourceHelper } from './helpers';
import app from '../../../app';

async function onTaskResourceUnassigned(event) {
  const {
    innerSettings: {
      projectId: ganttId,
      resourcesToTasks,
      massAssignData,
    },
    data: { unassignedResources },
  } = event;

  await resourceHelper(ganttId,
    resourcesToTasks,
    massAssignData,
    unassignedResources
  );
  
  app.trigger('onAfterCollaboration', {
    event: event.event, 
    projects: [event.innerSettings.projectId],
    tasks: [event.innerSettings.taskId],
    unassignedResources,
  });
  
  app.trigger('refresh:filter');
}

export default onTaskResourceUnassigned;
