/* eslint-disable no-var */
import app from '../app';

const __ = window.__;

var innerObject = {
  handlers: {
    offLine(ignoreShowing, customZindex) {
      const mainApplication = $$('mainApplication');

      webix.callEvent('onClick', []);

      if (mainApplication && mainApplication.isVisible() && !app.config.mode.isExport) {
        mainApplication.disable();
        mainApplication.showProgress({
          type: 'icon',
        });

        if (customZindex) {
          const spinner = mainApplication.getNode().querySelector('.webix_progress_icon');

          if (spinner) {
            spinner.style.zIndex = customZindex;
          }
        }

        !ignoreShowing && app.trigger('popup:show', true);
      }
    },
    onLine() {
      const mainApplication = $$('mainApplication');

      if (mainApplication) {
        mainApplication.enable();
        mainApplication.hideProgress && mainApplication.hideProgress();

        const spinner = mainApplication.getNode().querySelector('.webix_progress_icon');

        if (spinner) {
          spinner.style.zIndex = '';
        }
      }
    },
    showAlertAboutNewVersion() {
      webix.alert({
        // title: __("product_version_title"),
        ok: __('product_version_ok'),
        text: __('product_version_text'),
        type: 'confirm-info one-btn',
        width: 420,
        callback(result) {
          window.location.reload();
        },
      });

      const minSecondsToReload = 200;
      const maxSecondsToReload = 10000;
      const secondsToReload = Math.floor((Math.random() * (maxSecondsToReload - minSecondsToReload + 1)) + minSecondsToReload);

      _.delay(() => {
        window.location.reload();
      }, secondsToReload * 1000);
    },
    removePreload() {
      if (!innerObject.settings.initSystem) {
        innerObject.settings.initSystem = true;
        setTimeout(() => {
          document.getElementById('preLoaderWrap').style.opacity = 0;
          document.getElementById('preLoaderWrap').pointerEvents = 'none';
          setTimeout(() => {
            document.getElementById('preLoaderWrap').remove();
          }, 600);
        }, 500);
      }
    },
  },
  settings: {
    initSystem: false,
  },
};

app.socket.on('disconnect', () => {
  innerObject.handlers.offLine();
});

app.socket.io.on('reconnect', () => {
  innerObject.handlers.onLine();
});

app.socket.on('checkVersion', serverVersion => {
  // console.log('CVCV', serverVersion);
  if (app.config.mode.isBase) {
    if (GT.productVersion.version !== serverVersion.version && serverVersion.client_update && app.config.mode.isBase) {
      innerObject.handlers.showAlertAboutNewVersion();
    }
  }
});

app.on('gantt:init:before:removePreload', () => {
  innerObject.handlers.removePreload();
});

window.addEventListener('offline', () => {
  innerObject.handlers.offLine();
});

window.addEventListener('online', () => {
  innerObject.handlers.onLine();
});

app.socket.on('userLogout', loginUrl => {
  location.href = loginUrl;
});

const outputObject = {
  handlers: {
    showMainSpinner: innerObject.handlers.offLine,
    hideMainSpinner: innerObject.handlers.onLine,
  },
};

export default outputObject;
