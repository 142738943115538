var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.expansion_panel_wrapper },
    [
      _c(
        "div",
        {
          class: _vm.$.expansion_panel_task_title,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggle($event)
            },
          },
        },
        [
          _c(
            "div",
            { class: _vm.$.wrapper },
            [
              _c("icon-button", {
                class: [_vm.$.expand_button, _vm.show ? _vm.$.open : ""],
                attrs: {
                  icon: { name: "drop-down", type: "bold" },
                  size: "small",
                },
              }),
              _vm._v(" "),
              _c("div", { class: _vm.$.name }, [
                _vm._v("\n        " + _vm._s(_vm.taskNameString) + "\n      "),
              ]),
              _vm._v(" "),
              _c("vgp-button", {
                class: _vm.$.all_attachments_button,
                attrs: {
                  type: "text-grey",
                  label:
                    _vm.locales("all_task_attacments") +
                    " " +
                    _vm.attachments.length,
                  icon: { name: "link-2", size: 20 },
                  "icon-side": "right",
                  small: true,
                },
                on: {
                  click: [
                    function ($event) {
                      $event.stopPropagation()
                    },
                    function ($event) {
                      $event.preventDefault()
                    },
                  ],
                  onClick: function ($event) {
                    return _vm.$emit("open-task", _vm.taskId)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "accordion" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            class: [_vm.$.expansion_panel_task_content_outer_wrap],
          },
          [
            _c("div", { class: [_vm.$.expansion_panel_task_content] }, [
              _c(
                "div",
                { class: _vm.$.expansion_panel_task_content_inner_wrap },
                [
                  _c(
                    "div",
                    { class: _vm.$.expansion_panel_attachments_list },
                    _vm._l(_vm.attachments, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: _vm.$.expansion_panel_attachments_list__item,
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click-file", item)
                            },
                          },
                        },
                        [
                          _c("div", { class: _vm.$.item_img }, [
                            !item.isStub
                              ? _c("img", {
                                  attrs: {
                                    src: item.thumbnailLink,
                                    loading: "lazy",
                                  },
                                  on: {
                                    error: function ($event) {
                                      $event.target.src =
                                        _vm.defaultImagePlaceholder
                                    },
                                  },
                                })
                              : _c("div", {
                                  domProps: { innerHTML: _vm._s(_vm.imgStub) },
                                }),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.item_info }, [
                            _c("span", { class: _vm.$.name }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { class: _vm.$.info }, [
                              _vm.checkResourceExist(item.userId)
                                ? _c("span", { class: _vm.$.info_author }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          item.user.firstName +
                                            " " +
                                            item.user.lastName
                                        ) +
                                        "  \n                  "
                                    ),
                                  ])
                                : _c("span", { class: _vm.$.info_author }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          item.user.firstName +
                                            " " +
                                            item.user.lastName +
                                            " (" +
                                            _vm.locales(
                                              "task_settings_author_deleted_label"
                                            ) +
                                            ")"
                                        ) +
                                        "  \n                  "
                                    ),
                                  ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s("" + item.uploadDate) + "  "),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.size))]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.hasRights
                    ? _c("div", { class: _vm.$.new_attachment_button }, [
                        _c(
                          "label",
                          [
                            _c("vgp-button", {
                              attrs: {
                                type: "text",
                                label: _vm.locales("attacments_add_button"),
                                icon: {
                                  name: "plus-1",
                                  size: 20,
                                  type: "bold",
                                },
                                "icon-side": "left",
                                small: true,
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "file", hidden: "", multiple: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "files-upload",
                                    $event,
                                    _vm.taskId
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }