<template>
  <div class="upgrade-thank-popup">
    <div class="content">
      <webix-ui
        class="pic"
        :config="pic"
      />
      <p class="main-title">
        {{ locales('upgrade_thank_title') }}
      </p>
      <p class="subtitle">
        {{ locales('upgrade_thank_subtitle') }}
      </p>
      <global-btn
        :config="{
          isGlobal: true,
          width: '180px',
          size: 'normal',
          color: 'blue',
          locale: locales('common_ok'),
          handler: onClickOk
        }"
      />
      <div class="support-link"
         @click="chatClick"
           v-html="locales('contact_support_link')"
      />
    </div>
  </div>
</template>

<script>
import globalBtn from '../../common/VueComponents/globalButton/globalButton.vue';
import thankPic from '../../../svg/upgrade_thank_popup_pic.svg';
import svgIconForVue from '../../common/svgIconForVue';

export default {
  components: {
    globalBtn,
  },
  data() {
    return {
      locales: __,
      pic: svgIconForVue(thankPic, 110, 86),
    };
  },
  methods: {
    onClickOk() {
      this.$store.dispatch('globalPopup/close');
      location.reload();
    },
    chatClick(e) {
      const target = e.target.closest('.support-link');
      const contactLink = target.querySelectorAll('span');

      contactLink && this.$store.dispatch('footer/chatClickHandler');
    },
  },
};
</script>

<style scoped src='../less/thankPopup.less'></style>
<style lang="less">
.support-link {
  span {
    border-bottom: 1px solid #424242;
    cursor: pointer;
  }
}
</style>
