// import 'babel-polyfill';
import app, { appIds } from "./../../app";
import _ from "./../../libs/lodash";

const __ = window.__;
const {
  controlViews: { ID_VIEW_TOOLBAR_BTN_CHAT },
  events: { ID_APP_EVENT_LIVECHAT_CLICK }
} =  appIds;

const ID_KEY_STORAGE_CHAT_MISSING = 'liceChatMissing';
const APP_EVENT_START = 'app:start';
const APP_EVENT_SUPPORTCHAT_INIT = 'init:supportChat';

const ID_APP_EVENT_SUPPORTCHAT_HIDE = 'app:livechat:hide';
const ID_APP_EVENT_SUPPORTCHAT_SHOW = 'app:livechat:show';

var LC_API = LC_API || {};

let cwc = null;

window.LC_API = LC_API;

LC_API.on_after_load = function (data) {
  window.LC_API.disable_sounds();

  cwc = document.getElementById('chat-widget-container');
  cwc.style.display = 'none';

  if (webix.storage.local.get(ID_KEY_STORAGE_CHAT_MISSING)) {
    // $$(ID_VIEW_TOOLBAR_BTN_CHAT).setValues({
    //   hasUnread: true
    // }, true);
  }

  app.on(ID_APP_EVENT_LIVECHAT_CLICK, () => {
    if (window.LC_API.chat_window_maximized()) {
      window.LC_API.minimize_chat_window();
    } else {
      window.LC_API.open_chat_window();
    }
  });
};


var innerObject = {
  init: function () {
    if (
      /** GT.siteConfig.indexOf("localhost") !== -1 ||*/
      app.config.mode.isHistory) {
      return true;
    }

    window.__lc = window.__lc || {};
    window.__lc.license = 8254491;
   

    if(user && user.subscription && user.subscription.payment_plans_id && user.subscription.payment_plans_id === 18){
      // https://my.livechatinc.com/agents/groups/6
      // we move all enterprise customers (payment_plans_id === 18) to a seperate livechat group
      window.__lc.group = 6;
    } else {
      window.__lc.group = GT.locales.supported[user.locale].liveChatId;
    }

    try {

      window.__lc.visitor = {
        name: (user.firstName && user.lastName) ? (user.firstName + ' ' + user.lastName) : user.userName,
        email: user.email
      };

      window.__lc.params = [
        {name: 'Email', value: user.email},
        {name: 'Registered', value: user.registrationTime},
        {name: 'Current plan', value: user.subscription.name || 'Free'},
        {name: 'Date to', value: user.subscription.date_to || '-'}
      ];
    } catch (e) {
      console.error('Livechat error while init', e);
    }

    app.config.mode.isBase && (function () {
      var lc = document.createElement('script');
      lc.type = 'text/javascript';
      lc.async = true;
      lc.src = 'https://cdn.livechatinc.com/tracking.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(lc, s);
    })();
  }
};

var outputObject = {};

app.on(APP_EVENT_SUPPORTCHAT_INIT, function () {
  innerObject.init();
});

app.on(APP_EVENT_START, function () {
  innerObject.init();
});

LC_API.on_chat_window_opened = function() {
  if (!cwc) {
    window.LC_API.minimize_chat_window();
    return;
  }

  cwc.style.display = 'block';
  cwc.style.bottom = '30px';

  webix.storage.local.put(ID_KEY_STORAGE_CHAT_MISSING, false);
 
  app.trigger(ID_APP_EVENT_SUPPORTCHAT_SHOW);
}

LC_API.on_chat_window_minimized = function() {
  cwc.style.display = 'none';
  app.trigger(ID_APP_EVENT_SUPPORTCHAT_HIDE);
}

LC_API.on_chat_window_hidden = function() {
  cwc.style.display = 'none';
  app.trigger(ID_APP_EVENT_SUPPORTCHAT_HIDE);
}

export default outputObject;
