import _ from '../../../libs/lodash';
import { checkPricingAccess, getAccessToDefaultColumns } from '../../../helpers/pricingHelper';
import listViewConstants from '../constants';

export function getSettingsFromConfig(config) {
  const id = config.id;
  const project_id = config.project_id;
  const multiview_id = config.multiview_id;

  const configColumns = (typeof config?.columns === 'string' ? JSON.parse(config.columns) : config?.columns) || listViewConstants.CONFIG.DEFAULT_COLUMNS;
  const configSettings = (typeof config?.settings === 'string' ? JSON.parse(config.settings) : config?.settings) || listViewConstants.CONFIG.DEFAULT_SETTINGS;

  const pricingAccessToCustomColumns = checkPricingAccess('custom_fields');
  const pricingAccessToDefaultColumns = getAccessToDefaultColumns([...listViewConstants.COLUMNS.PREPEND_COLUMNS, ...listViewConstants.COLUMNS.DEFAULT_COLUMNS]);
  const pricingAccessToCost = checkPricingAccess('costs');

  const columns = [];
  const overdue = checkPricingAccess('overdue_tasks') ? configSettings.overdue : false;
  const viewSettings = configSettings.viewSettings;

  Object.keys(configColumns).map(key => {
    const item = configColumns[key];

    if (item.hidden) return;

    if (pricingAccessToCustomColumns && item.custom) {
      columns.push(+key);
    }

    if (pricingAccessToDefaultColumns.includes(key)) {
      columns.push(key);
    }

    if (pricingAccessToCost && listViewConstants.COLUMNS.COST_COLUMNS.find(col => col.property === key)) {
      columns.push(key);
    }

    if (item.showTime) {
      columns.push(`${key}_extra`);
    }
  });

  return { id, project_id, multiview_id, columns, overdue, viewSettings };
}
