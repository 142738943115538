var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    class: _vm.popup.create_resource_popup,
    attrs: { id: "gp_autotest_create_resource_popup", show: _vm.isShow },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.popup.create_resource_popup_body }, [
              _c("div", { class: _vm.popup.title }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.locales("resources_create_resource_title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.popup.info_panel },
                [
                  _c("vgp-label-slot", {
                    attrs: { label: _vm.locales("add_resource_name_label") },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("vgp-input", {
                              attrs: {
                                placeholder: _vm.locales(
                                  "common_placeholder_name"
                                ),
                                type: "text",
                                small: true,
                                "gp-autotest-name":
                                  "gp_autotest_create_resources_popup_input_name",
                              },
                              on: {
                                onChange: _vm.inputChange,
                                input: _vm.debouncedValidateName,
                              },
                            }),
                            _vm._v(" "),
                            _vm.isExistResourceName
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.popup.warning_message,
                                    attrs: {
                                      id: "gp_autotest_create_resources_popup_input_warning_message",
                                    },
                                  },
                                  [
                                    _c("sprite", {
                                      attrs: {
                                        name: "warning-1",
                                        type: "bold",
                                        size: 20,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { class: _vm.popup.warning_message_text },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.locales(
                                                "create_popup_exist_warning"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.popup.colors_wrapper },
                    [
                      _c("vgp-label-slot", {
                        attrs: { label: _vm.locales("common_color") },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c("color-palette", {
                                  attrs: {
                                    "gp-autotest-name":
                                      "gp_autotest_create_resources_popup_color_palette_color",
                                    "select-type": "single",
                                    "select-field": "select",
                                    "selected-values": [17],
                                    colors: _vm.colors,
                                    "select-width": 72,
                                    "select-height": 32,
                                  },
                                  on: { selectColors: _vm.getColor },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.popup.project_dropdown },
                [
                  _c("vgp-label-slot", {
                    attrs: {
                      label: _vm.locales("add_resource_projects_label"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("dropdown-select", {
                              attrs: {
                                "gp-autotest-name":
                                  "gp_autotest_create_resources_popup_dropdown_select_projects",
                                "drop-list": _vm.dropdownProjects,
                                multiselect: true,
                                "use-select-all": true,
                                "use-search": true,
                                "popup-max-height": 180,
                                "show-reset-btn": false,
                                "highlight-active-state": false,
                                selected: _vm.selectedProjects,
                                placeholder: _vm.locales(
                                  "create_resource_projects_drop_placeholder"
                                ),
                              },
                              on: { selected: _vm.onSelect },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.popup.buttons },
                [
                  _c("vgp-button", {
                    attrs: {
                      id: "gp_autotest_create_resources_popup_btn_cancel",
                      type: "secondary",
                      label: _vm.locales("common_cancel"),
                      small: true,
                    },
                    on: { onClick: _vm.onClose },
                  }),
                  _vm._v(" "),
                  _c("vgp-button", {
                    style: { marginLeft: 16 + "px" },
                    attrs: {
                      id: "gp_autotest_create_resources_popup_btn_create",
                      type: "primary",
                      label: _vm.locales("common_create"),
                      small: true,
                      disabled: _vm.isDisableBtn,
                    },
                    on: { onClick: _vm.onCreateResource },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }