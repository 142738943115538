<template>
  <div :class="[$style.header_tabs, config.css ? $style[config.css] : '']">
    <div
      v-bind:class="[
        $style.tab_item,
        activeTab.name === item.name ? $style.active : '',
        item.cssClass || '',
      ]"
      v-for="(item, index) in visibleTabs"
      :key="index"
      v-on:click="onClickTab(item)"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>

import routerHelper from "../../helpers/router";

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          text: __('common_project_tab'),
          name: 'projects',
          routeMode: 'projects'
        },
        {
          text: __('timeline_tab'),
          name: 'timeline',
          routeMode: 'gantt'
        },
        {
          text: __('board_tab'),
          name: 'board',
          routeMode: 'board'
        },
        {
          text: __('list_tab'),
          name: 'list',
          routeMode: 'list'
        },
        {
          text: __('calendar_tab'),
          name: 'calendar',
          routeMode: 'calendar',
        },
        {
          text: __('workload_tab'),
          name: 'workload',
          routeMode: 'workload'
        },
        {
          text: __('resources_tab'),
          name: 'resources',
          routeMode: 'resources',
          cssClass: 'js_resources_tab'
        },
        {
          text: __('timelog_tab'),
          name: 'timelog',
          routeMode: 'timelog'
        },
        {
          text: __('overview_tab'),
          name: 'overview',
          routeMode: 'overview',
        },
        {
          text: __('dashboard_tab'),
          name: 'dashboard',
          routeMode: 'dashboard',
        },
      ]
    }
  },
  computed: {
    activeTab () {
      return this.visibleTabs.find(t => t.routeMode === this.$store.getters['headerView/currentRoute'].params.mode) || {};
    },
    visibleTabs () {
      const visibleTabs = [];

      this.tabs.forEach(tab => {
        if (this.config.includes(tab.name)) {
          visibleTabs.push(tab);
        }
      });

      return visibleTabs;
    },
  },
  methods: {
    onClickTab (tab) {
      const route = routerHelper.getCurrentRoute();
      let name = this.$store.getters['headerView/currentRoute'].name;

      if (route.path.includes('task/')) {
        name = route.params.parent;
      }

      userExtAnalytics.log('header_click_tab', { 
        tab: tab.routeMode === 'resources' ? 'people' : tab.routeMode,
      });
      
      if(tab.routeMode === 'workload'){
        userExtAnalytics.log('workload_open', { from: 'workload tab' });
      }

      gantt.saveTaskEditors(true, true);

      this.$store.dispatch('headerView/changeRoute', {
        name,
        params: {mode: tab.routeMode, taskId: null}
      });
    }
  }
}
</script>

<style module src="./less/tabsView.less" lang="less">
</style>