<template>
  <dropCell
    v-model="pickerOpen"
    :disabled="disabled"
    :editable="editable"
    :manual-open="true"
    :suggest-height="257"
    :drop-width="260"
    :class="datePickerId"
  >
    <template #trigger="{isOpen}">
      <div
        ref="trigger"
        :class="[$style.cell_trigger, $style.center, !editable ? $style.not_allowed : '']"
        @click="triggerClick"
      >
        <span
          :class="[$style.text_wrap,
                   $style.text_center,
                   disabled ? $style.text_disabled : '',
                   crossout && (disabled || !isOpen) ? $style.crossout :'',
                   isOverdue ? $style.overdue :'',
          ]"
          :style="{opacity: isOpen ? 1 : cellOpecity}"
        >
          {{ formattedDate }}
        </span>
      </div>
    </template>
    <template #drop>
      <webix-ui
        ref="drop"
        class="custom_datepicker_clear"
        :config="datepickerConfig"
        @click.native="calendarClick"
      />
    </template>
  </dropCell>
</template>

<script>
import dateHelper from '../../../../helpers/dateFormats';
import dropCell from '../dropPopup.vue';
import moment from '../../../../libs/moment';
import constants from '../../../../helpers/constants';

export default {
  name: 'CalendarCell',
  components: { dropCell },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    extra: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    forCustom: { type: Boolean, required: false, default: false },
    value: { type: Object, required: true },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
    overdue: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      pickerOpen: false,
      isCalendarClick: false,
      settingSelected: false,
    };
  },

  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },
    formattedDate() {
      const val = this.item[this.property];

      if (!val) return '';
      const date = new Date(val);
      const format = this.extra ? dateHelper.getDateFormatForGanttGrid() : dateHelper.getDateFormat();

      return webix.Date.dateToStr(format)(date);
    },

    datePickerId() {
      return `datePicker-${this.item.id}-${this.componentKey}`;
    },

    isOverdue() {
      return this.overdue && this.property === 'end_date';
    },

    componentKey() {
      const min = 1;
      const max = 100000000;
      const random = Math.floor(Math.random() * (max - min)) + min;

      return `${new Date().getTime()}-${random}`;
    },

    isShowTodayBtn() {
      const isWorkToday = !this.isBlockedDate(new Date());

      if (this.property === 'start_date') {
        return isWorkToday;
      }

      if (this.property === 'end_date') {
        const isTodayAfterStartDate = gantt.date.day_start(new Date(_.clone(this.item.start_date))).valueOf() < new Date().valueOf();

        return isTodayAfterStartDate && isWorkToday;
      }

      return true;
    },

    datepickerConfig() {
      const component = this;
      const date = this.value || null;
      const showTime = this.property === 'start_date' || this.property === 'end_date';
      const config = {
        view: 'calendar',
        css: 'webix_calendar',
        id: component.datePickerId,
        monthSelect: true,
        navigation: true,
        weekHeader: true,
        borderless: true,
        fillspace: true,
        height: 260,
        calendar: {
          height: 300,
          cellHeight: 24,
        },
        date,
        timepicker: showTime,
        icons: [{
          template() {
            if (component.forCustom) {
              return `
              <div class='d-flex justify-space-between align-center ' style="width:224px; margin-top:-6px">
                <span role='button' tabindex='0' class='webix_cal_icon_clear webix_cal_icon' >${component.item[component.property] ? webix.i18n.calendar.clear : ''}</span>
                <span role='button' tabindex='0' class='webix_cal_icon_today webix_cal_icon'> ${webix.i18n.calendar.today}</span>
              </div>
              `;
            }
            const todayBtn = `<span role='button' tabindex='0' class='webix_cal_icon_today webix_cal_icon' style="margin-top:-26px">${webix.i18n.calendar.today}</span>`;

            return component.isShowTodayBtn ? todayBtn : '';
          },
          on_click: {
            webix_cal_icon_today() {
              if (component.property === 'end_date') {
                this.setValue(gantt.getClosestWorkTime({ date: gantt.date.day_start(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)), dir: 'past' }));
              } else {
                this.setValue(gantt.getClosestWorkTime({ date: gantt.date.day_start(new Date()), dir: 'future' }));
              }
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
            webix_cal_icon_clear() {
              this.setValue('');
              this.callEvent('onDateClear', []);
            },
          },
        }],
        on: {
          onChange(val) {
            !component.settingSelected && component.selectDate(val);
            component.settingSelected = false;
          },
        },
        weekNumber: false,
        blockDates: component.isBlockedDate,
        blockTime: component.isBlockedTime,
        minuteStep: 1,
      };

      if (date) config.date = date;

      return config;
    },

  },

  watch: {
    value(val) {
    },
  },

  mounted() {
    document.addEventListener('click', this.documentClick);
    document.addEventListener('contextmenu', this.closeCalendar);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.documentClick);
    document.removeEventListener('contextmenu', this.closeCalendar);

    if (!webix.$$(this.popupId) || !webix.$$(this.popupId).destructor) { return; }

    webix.$$(this.popupId).destructor();
  },

  methods: {
    calendarClick(e) {
      this.isCalendarClick = true;
    },
    documentClick(e) {
      const isTrigger = this.$refs.trigger ? this.$refs.trigger.contains(e.target) : false;
      const isDrop = this.isCalendarClick;

      if (!isDrop && !isTrigger) {
        this.pickerOpen = false;
      }
      this.isCalendarClick = false;
    },
    closeCalendar() {
      this.pickerOpen = false;
    },
    selectDate(val) {
      const value = val ? val[0] : '';

      if ((new Date(value)).getTime() === (new Date(this.value)).getTime()) { return; }

      if (this?.item.start_date && this.property === 'end_date' && ((new Date(value)).getTime() === (new Date(this?.item.start_date)).getTime())) {
        this.pickerOpen = false;

        return;
      }

      const emitValue = value ? this.toStringDate(value) : '';

      this.$emit('change', emitValue);
      this.pickerOpen = false;
    },

    toStringDate(date) {
      return moment(date, constants.TASK_DATES_FORMAT).toDate();
    },

    setSelected() {
      this.settingSelected = true;
      const $$ui = $$(this.datePickerId);
      const date = this.value;

      $$ui && date && $$ui.selectDate(date);
    },

    async triggerClick() {
      this.pickerOpen = !this.pickerOpen;

      if (this.pickerOpen) {
        await this.$nextTick(this.setSelected);
      }
      if (!this.value) {
        this.settingSelected = false;
      }
    },

    isBlockedDate(date) {
      if (this.property !== 'start_date' && this.property !== 'end_date') return false;

      const projectCalendar = gantt.getCalendar(this.item.gantt_id);

      if (this.property === 'end_date') {
        if (new Date(date).valueOf() < gantt.date.day_start(_.clone(this.item.start_date)).valueOf()) {
          return true;
        }
      }

      if (gantt.isWorkDay(date, projectCalendar)) { return false; }

      return true;
    },

    isBlockedTime(date) {
      if (this.property !== 'start_date' && this.property !== 'end_date') return false;

      const projectCalendar = gantt.getCalendar(this.item.gantt_id);

      if (this.property === 'end_date' && gantt.isWorkEndTime(date, projectCalendar)) {
        return false;
      }

      if (this.property === 'start_date' && gantt.isWorkStartTime(date, projectCalendar)) {
        return false;
      }

      return true;
    },
  },

};
</script>

<style module src="./cell.less" lang="less" />
