<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="8" viewBox="0 0 80 8" fill="none">
    <defs>
      <mask id="progressMask">
        <rect width="100%" height="8" rx="4" fill="#ffffff" />
      </mask>
    </defs>
    <rect width="100%" height="8" rx="4" fill="#E5E5E5" />
    <rect :width="`${value}%`" height="8" fill="#4484CD" mask="url(#progressMask)"/>
  </svg>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    }
  }
}
</script>