var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "table",
      staticClass: "app-table",
    },
    [
      _c("addColumnsPopup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showColumnsPopup,
            expression: "showColumnsPopup",
          },
        ],
        attrs: {
          items: _vm.allDinamicHeaders,
          selected: _vm.allSelectedHeaders,
          height: _vm.tableHeight,
          "extra-info": _vm.extra,
          "show-custom-column-info": _vm.showCustomColumnInfo,
        },
        on: {
          close: _vm.closeAddColsPopup,
          "update:selected": _vm.updateShowColumns,
          "update:extra": _vm.changeExtraShow,
        },
      }),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          {
            name: "scroll",
            rawName: "v-scroll:#virtual-scroll-table",
            value: _vm.onScroll,
            expression: "onScroll",
            arg: "#virtual-scroll-table",
          },
          {
            name: "scroll",
            rawName: "v-scroll.self",
            value: _vm.closeAllPopupInApp,
            expression: "closeAllPopupInApp",
            modifiers: { self: true },
          },
        ],
        ref: "scrollable",
        staticClass: "elevation-0 v_table table-body virtual-scroll",
        class: { "settings-opened": _vm.isSettingsOpened },
        style: { height: _vm.currentTableHeight + "px" },
        attrs: {
          id: "virtual-scroll-table",
          headers: _vm.columnsToShow,
          items: _vm.dataItems,
          "custom-sort": _vm.customSort,
          "hide-default-footer": "",
          "hide-default-header": "",
          "disable-pagination": "",
          dense: "",
        },
        on: {
          "update:sort-by": _vm.sortByEvent,
          "update:sort-desc": _vm.sortDescEvent,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (ref) {
              var on = ref.on
              var props = ref.props
              return [
                _c(
                  "div",
                  { staticClass: "header-row table-row sticky-top" },
                  [
                    _vm._l(props.headers, function (header, index) {
                      return _c(
                        "div",
                        {
                          key: "header-" + header.property,
                          staticClass: "table-header-cell table-cell",
                          class: {
                            hardwidth: !!header.hardWidth,
                            prepend: !!header.emptyHeader,
                            "sticky-left": !!_vm.stickyLeft[header.property],
                            relative: !_vm.stickyLeft[header.property],
                            "last-in-row": props.headers.length === index + 1,
                          },
                          style: {
                            height: _vm.headerHeight + "px",
                            left: _vm.stickyLeft[header.property]
                              ? _vm.stickyLeft[header.property]
                              : "auto",
                          },
                          attrs: {
                            id:
                              "gp_autotest_listview_header_cell_" +
                              header.property,
                            "date-name": header.property,
                            "data-resizable": header.resizable,
                            "fix-width": header.width || 0,
                          },
                        },
                        [
                          header.property === "select_col"
                            ? _c(
                                "div",
                                [
                                  _c("checkboxCell", {
                                    attrs: {
                                      value: _vm.isSelectedAll,
                                      theme: "blue",
                                    },
                                    on: { change: _vm.onSelectAll },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !header.emptyHeader
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-wrap",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSort($event, on, header)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "cell-title",
                                      class: {
                                        bold:
                                          _vm.sortBy &&
                                          _vm.sortBy.coll.property ===
                                            header.property,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(header.text) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.sortBy &&
                          _vm.sortBy.coll.property == header.property &&
                          header.property != "select_col"
                            ? _c("div", {
                                staticClass: "sort-arrow",
                                class: {
                                  up: _vm.sortBy.desc,
                                  down: !_vm.sortBy.desc,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSort($event, on, header)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "header-cell-add table-cell sticky-right",
                        style: { width: "50px", "flex-grow": 0 },
                        on: {
                          click: function ($event) {
                            _vm.showColumnsPopup = true
                          },
                        },
                      },
                      [_c("icon", { attrs: { name: "add" } })],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "body",
            fn: function (ref) {
              var items = ref.items
              var headers = ref.headers
              return [
                _vm.dataItems.length
                  ? [
                      _vm.start > 0
                        ? _c("div", {
                            style: { "padding-top": +_vm.startHeight + "px" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.perPage, function (row) {
                        return _c("tableRow", {
                          key:
                            items[row + _vm.start - 1].id +
                            "-" +
                            _vm.headersLength +
                            "-" +
                            _vm.redrawRow,
                          attrs: {
                            id: row + _vm.start - 1 + "-row",
                            item: items[row + _vm.start - 1],
                            columns: headers,
                            height: _vm.rowHeight,
                            "active-task-id": _vm.activeTaskId,
                            "editing-task-id": _vm.editingTaskId,
                            "highlight-overdue-task": _vm.highlightOverdueTask,
                            "closed-task-discolor": _vm.closedTaskDiscolor,
                            "closed-task-crossout": _vm.closedTaskCrossout,
                            "sticky-left": _vm.stickyLeft,
                            skins: _vm.skins,
                            "select-mode": _vm.selectMode,
                            selected: _vm.selectedItemIds.includes(
                              items[row + _vm.start - 1].id
                            ),
                            extra: _vm.extra,
                          },
                          on: {
                            select: _vm.onSelectRow,
                            "show:itemSettings": function ($event) {
                              return _vm.$emit("show:itemSettings", $event)
                            },
                            change: function ($event) {
                              return _vm.$emit("change", $event)
                            },
                            "change:custom": function ($event) {
                              return _vm.$emit("change:custom", $event)
                            },
                            "show:task-name-editor": _vm.setEditTaskId,
                            delete: function ($event) {
                              return _vm.$emit("delete:item", $event)
                            },
                            "contextmenu:morecell":
                              _vm.callContextMenuFromMorecell,
                            contextmenu: function ($event) {
                              return _vm.callContextMenu(
                                $event,
                                items[row + _vm.start - 1]
                              )
                            },
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm.start + _vm.perPage < _vm.itemsLength
                        ? _c("div", {
                            staticClass: "table-row",
                            style: { "padding-top": +_vm.endHeight + "px" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("contextMenu", {
                        key: _vm.redrawRow,
                        attrs: { info: _vm.contextMenuData },
                        on: {
                          "menu:delete": _vm.deleteItem,
                          "menu:settings": _vm.showItemSettings,
                        },
                        model: {
                          value: _vm.contextMenuData,
                          callback: function ($$v) {
                            _vm.contextMenuData = $$v
                          },
                          expression: "contextMenuData",
                        },
                      }),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.createItemMode,
                              expression: "!createItemMode",
                            },
                          ],
                          staticClass: "no-data--wrap",
                          style: {
                            height:
                              _vm.currentTableHeight -
                              _vm.headerHeight -
                              18 +
                              "px",
                          },
                          attrs: { id: "gp_autotest_listview_no_data" },
                        },
                        [
                          _vm.typeNoTasksImg === "noFiltredTasks"
                            ? [
                                _c("div", {
                                  staticClass: "no-data noFiltredTasks",
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.locale.noFiltredTasks) +
                                      " "
                                  ),
                                ]),
                              ]
                            : [
                                _c("div", { staticClass: "no-data noTasks" }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.locale.noTasks) + " "
                                  ),
                                ]),
                              ],
                        ],
                        2
                      ),
                    ],
                _vm._v(" "),
                _vm.canCreateTasks
                  ? _c("creation-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCreateItem,
                          expression: "showCreateItem",
                        },
                      ],
                      attrs: {
                        disable: _vm.disableCreateItems,
                        "is-create-mode": _vm.createItemMode,
                        "select-mode": _vm.selectMode,
                        skins: _vm.skins,
                      },
                      on: {
                        "update:isCreateMode": function ($event) {
                          _vm.createItemMode = $event
                        },
                        "update:is-create-mode": function ($event) {
                          _vm.createItemMode = $event
                        },
                        create: _vm.onCreateItem,
                        beforeCreateItem: _vm.onBeforeCreateItem,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }