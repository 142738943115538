import historyModel from "./../../../models/history";

gantt.attachEvent("onBeforeTaskUpdate", function (taskId, taskData) {
  if (!taskData.actionHashManual) {
    taskData.actionHash = innerHanderls.generateActionHash();
  }
  return true;
});


gantt.attachEvent("onBeforeTaskAdd", function (taskId, taskData) {
  taskData.actionHash = innerHanderls.generateActionHash();
  return true;
});

gantt.attachEvent("onBeforeTaskDelete", function (taskId, taskData) {
  taskData.actionHash = innerHanderls.generateActionHash();
  innerSettings.deletedHashAction[taskId] = {
    actionHash: taskData.actionHash,
    ganttId: taskData.gantt_id
  }

  return true;
});

gantt.attachEvent("onBeforeLinkAdd", function (linkId, linkData) {
  linkData.actionHash = innerHanderls.generateActionHash();
  return true;
});

gantt.attachEvent("onBeforeLinkUpdate", function (linkId, linkData) {
  linkData.actionHash = innerHanderls.generateActionHash();
  return true;
});

gantt.attachEvent("onBeforeLinkDelete", function (linkId, linkData) {
  linkData.actionHash = innerHanderls.generateActionHash();
  innerSettings.deletedHashAction[linkId] = {
    actionHash: linkData.actionHash,
    ganttId: linkData.gantt_id
  };
  return true;
});

var innerHanderls = {
  init: {
    run: function () {

    },
    beforeInit: function (dataProcessor) {
      innerHanderls.init.initOnceGanttDP(dataProcessor);
    },
    initOnceGanttDP: function (dataProcessor) {
      if (this.dp) return this.dp;

      this.dp = dataProcessor;

      this.dp.attachEvent("onBeforeDataSending", function (id, state, data) {

        dataProcessor.setTransactionMode({
          mode: "REST",
          payload: {}
        }, true);

        if (state === 'deleted' && data.type !== gantt.config.types.button && innerSettings.deletedHashAction[id]) {
          dataProcessor.setTransactionMode({
            mode: "REST",
            payload: {
              actionHash: innerSettings.deletedHashAction[id].actionHash,
              ganttId: innerSettings.deletedHashAction[id].ganttId
            }
          }, true);

          delete innerSettings.deletedHashAction[id];
        }
        if (state === "updated" && !data.type && !data.text && !data.start_date) {
          return false;
        }

        return true;
      });
    }
  },
  generateActionHash: function () {
    return historyModel.generateActionHash();
  }
};

var innerSettings = {
  deletedHashAction: {}
};

var outputObject = {
  init: {
    run: innerHanderls.init.run,
    beforeInit: innerHanderls.init.beforeInit
  }
};

export default outputObject;
