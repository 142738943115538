var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      { class: _vm.$style["news-notify-popup-slot-wrap"] },
      _vm.$listeners
    ),
    [
      _c("svg-sprite", {
        class: _vm.$style["demo_modal__closer"],
        attrs: { name: "close-1", size: 20 },
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _vm._v(" "),
      _c("div", { class: _vm.$style["demo_modal__container"] }, [
        _c("div", { class: _vm.$style["container_view"] }, [
          _c(
            "div",
            {
              class: _vm.$style["view_img_container"],
              style: { backgroundColor: _vm.imgContainerBg },
            },
            [
              _c("div", { class: _vm.$style["view_img"] }, [
                _c("img", {
                  class: { rounded: _vm.isRoundedImg },
                  attrs: { src: _vm.imageSrc },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style["view_content"] }, [
            _c("div", { class: _vm.$style["content_title"] }, [
              _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style["content_text"] }, [
              _vm._v("\n          " + _vm._s(_vm.content) + "\n        "),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }