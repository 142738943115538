/* eslint-disable no-param-reassign */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
import app from '../app';
import _ from '../libs/lodash';

import userProjectConfigModel from './userProjectConfigs';
import activeTabs from './activeTabs';
import constants from '../helpers/constants';
import customDayTemplatesModel from './customDayTemplates';
import rights from '../components/rights';
import routerHelper from '../helpers/router';
import moment from '../libs/moment';


import globalStore from '../store/main';

let projects = {};
const preInitObject = {};
let archivedProjects = new webix.DataCollection();

preInitObject.prepareInitProjects = function (obj) {
  obj.gantt_id = +obj.gantt_id;
  obj.id = obj.gantt_id;
  obj.skin = obj.userSkin || userProjectConfigModel.getUserSkinByGanttId(obj.id) || obj.skin;

  if (_.isString(obj.userConfig)) {
    obj.userConfig = JSON.parse(obj.userConfig);
  }

  if (_.isString(obj.config)) {
    obj.config = JSON.parse(obj.config);
  }

  if (obj.userSkin) {
    obj.config.userSkin = obj.userSkin;
  }

  obj.config.isJira = !!obj.is_jira;

  if (_.isUndefined(obj.config.show_advanced_buttons)) {
    obj.config.show_advanced_buttons = 1; // default
  }

  if (_.isUndefined(obj.config.show_resource_avatar)) {
    obj.config.show_resource_avatar = 1; // default
  }
  if (_.isUndefined(obj.config.discoloration_tasks)) {
    obj.config.discoloration_tasks = 1; // default
  }
  if (_.isUndefined(obj.config.cross_out_tasks)) {
    obj.config.cross_out_tasks = 1; // default
  }
  if (_.isUndefined(obj.config.right_side_text)) {
    obj.config.right_side_text = 'insideText'; // default
  }

  return obj;
};

if (!app.config.mode.isBase) {
  projects = new webix.DataCollection({
    scheme: {
      $init(obj) {
        obj = preInitObject.prepareInitProjects(obj);
      },
      $update(obj) {
        obj = preInitObject.prepareInitProjects(obj);
      },
    },
  });

  if (GT.ganttData.projects) {
    projects.parse(GT.ganttData.projects);
  } else {
    projects.parse(GT.ganttData.project);
  }
} else if (user.team.status !== 2) {
  projects = new webix.DataCollection({
    url: `rest->/api/projects/?${new Date().getTime()}`,
    save: {
      url: 'rest->/api/projects/',
      updateFromResponse: true,
    },
    scheme: {
      $init(obj) {
        obj = preInitObject.prepareInitProjects(obj);
      },
      $update(obj) {
        obj = preInitObject.prepareInitProjects(obj);
      },
    },
  });

  archivedProjects = new webix.DataCollection({
    url: `rest->/api/projects/archived?${new Date().getTime()}`,
  });
}

if (user.team.status === 2) {
  projects = new webix.DataCollection();

  projects.parse([]);
}

app.on('app:start', () => {
  webix.promise.all([projects.waitData])
    .then(() => {
      projects.data.pull[projects.globalGanttId] = projects.getGlobalProjectData();
      const active = projects.serialize();
      const archived = archivedProjects.serialize();
      const allProjects = active.concat(archived);

      userProjectConfigModel.initProjects(allProjects);
      app.checkInit('projects');
    });
});

app.on('app:initModels:complete', () => {
  projects.initActiveProject();
  // projects.initCalendars();
});
app.on('app:initModels', () => {
  if (!app.config.mode.isBase) {
    projects.initActiveProject();
  }
});

projects.paramsToUpdate = [];
projects.ganttIdsForUpdate = [];
projects.cachedConfigs = [];
projects.activeGanttId = 0;
projects.defaultWorkDays = [1, 2, 3, 4, 5];
projects.defaultWorkHours = ['9:00-13:00', '14:00-18:00'];

projects.activeProjectMode = 'gantt';
projects.globalGanttId = 666;

projects.initActiveProject = function () {
  let activeGanttID = activeTabs.getActiveGanttId();
  const isProjectExist = projects.exists(activeGanttID);

  if (!isProjectExist) {
    const projectsSortedByUpdate = projects.serialize().sort((projectA, projectB) => moment(projectB.last_update).valueOf() - moment(projectA.last_update).valueOf());

    activeGanttID = _.first(projectsSortedByUpdate) ? _.first(projectsSortedByUpdate).gantt_id : null;
  }

  projects.setActiveProject(activeGanttID, true);
};

projects.initProjectCalendar = function (project) {
  if (project) {
    gantt.addProjectCalendar(project.config.durationData, +project.gantt_id);
  }
};

projects.getActiveGanttId = function () {
  return this.activeGanttId;
};

projects.getActiveProjectMode = function () {
  return this.activeProjectMode;
};

projects.getDefaultWorkDays = function () {
  return this.defaultWorkDays;
};

projects.getDefaultworkHours = function () {
  return this.defaultWorkHours;
};

projects.setActiveProjectMode = function (mode) {
  this.activeProjectMode = mode;
};

projects.setActiveProject = function (ganttId, isInit, fromRoute, actionHash) {
  const promise = webix.promise.defer();
  let projectData = {};

  gantt.config.gantt_id = +ganttId;
  gantt.config.multiview = false;

  if (projects.activeGanttId === +ganttId && !projects.forceSetActive && !fromRoute) {
    app.trigger('projectModel:activeProject:initFilter', +ganttId);
    promise.resolve();

    return promise;
  }

  projects.activeGanttId = +ganttId;
  projects.projectCount = 0;
  projects.forceSetActive = false;

  if (projects.activeGanttId !== projects.globalGanttId) {
    app.trigger('projectModel:activeProject:initFilter', +ganttId);
  }

  if (!projects.activeGanttId) {
    if (!rights.account.hasRight('project_create')) {
      routerHelper.pushRoute({ name: 'noProject' });
    } else {
      app.trigger('tabs:newProject', true);
    }

    app.trigger('ganttClearAll');

    return promise.resolve();
  }

  app.trigger('activeProject:set', projects.activeGanttId, projects.getActiveProjectData());

  !isInit && !fromRoute && app.trigger('projectModel:activeProject:changeRoute', projects.activeGanttId);

  // if (app.config.mode.isBase && projects.globalGanttId !== projects.activeGanttId) {
  //   app.trigger('history:openEvent', projects.activeGanttId, actionHash); // save view project event in history
  // }

  if (projects.count() <= 1) {
    app.trigger('created:first:project');
  }

  if (projects.ganttIdsForUpdate.indexOf(projects.activeGanttId) === -1) {
    promise.resolve();

    return promise;
  }

  projects.ganttIdsForUpdate.splice(projects.ganttIdsForUpdate.indexOf(projects.activeGanttId), 1);
  projectData = projects.getActiveProjectData();

  projects.getProjectDataFromServerByGanttId(projects.activeGanttId)
    .then(responseProjectData => {
      projects.data.callEvent('updateProjectData', [projects.getActiveProjectData(), responseProjectData.json()]);
      promise.resolve();
    });

  return promise;
};

projects.getActiveProjectData = function (property) {
  const projectData = this.getItem(projects.activeGanttId);

  if (!projectData) {
    return null;
  }

  if (property) {
    return projectData[property];
  }

  return projectData;
};

projects.getActiveViewData = function () {
  const projectData = this.getItem(projects.activeGanttId);

  if (!projectData) {
    return null;
  }

  projectData.isSingleProject = true;
  projectData.name = _.unescape(projectData.name);
  projectData.config = projects.getProjectConfig(projects.activeGanttId);

  return projectData;
};

projects.isMultiview = function () {
  return false;
};

projects.getDefaultConfig = function () {
  const config = {
    auto_scheduling: false,
    highlight_critical_path: false,
    show_resource_avatar: true,
    current_day_marker: true,
    duration_view: 'hour',
    view_mode: 'auto',
    progress: 'duration',
    date_scale: '%F, %Y',
    task_date: '%d %F %Y',
    time_picker: '%H:%i',
    task_attribute: 'task_id',
    link_attribute: 'link_id',
    in_minutes: true,
    durationData: {
      mode: 'day',
      durationUnit: 'hour',
      startTime: 0,
      endTime: 0,
      showTime: ['9:00-13:00', '14:00-18:00'],
      startDay: 1,
      endDay: 7,
      showDay: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
      ],
      step: 1,
      view: 'auto',
    },
    initGridColumns: {
      overdue: true,
      comments: false,
      attachments: false,
      wbs: true,
      start_date: true,
      end_date: true,
      duration: true,
      estimation: false,
      progress: true,
      total_price: false,
      actual_cost: false,
      resource_id: false,
      owner_id: false,
      priority: false,
      status: false,
      predecessors: false,
      jira_key: false,
      grid_width: 790,
      sizes: {
        overdue: {
          width: 12,
          min_width: 12,
        },
        comments: {
          width: 45,
          min_width: 45,
        },
        attachments: {
          width: 45,
          min_width: 45,
        },
        wbs: {
          width: 45,
          min_width: 45,
        },
        text: {
          width: 180,
          min_width: 50,
        },
        start_date: {
          width: 96,
          min_width: 50,
        },
        end_date: {
          width: 96,
          min_width: 50,
        },
        duration: {
          width: 86,
          min_width: 50,
        },
        estimation: {
          width: 100,
          min_width: 50,
        },
        time_tracking: {
          width: 100,
          min_width: 50,
        },
        progress: {
          width: 70,
          min_width: 50,
        },
        resource_id: {
          width: 100,
          min_width: 50,
        },
        owner_id: {
          width: 100,
          min_width: 50,
        },
        total_price: {
          width: 100,
          min_width: 50,
        },
        actual_cost: {
          width: 100,
          min_width: 50,
        },
        buttons: {
          width: 50,
          min_width: 50,
        },
        default: {
          width: 100,
          min_width: 50,
        },
        status: {
          min_width: 50,
          width: 78,
        },
        priority: {
          min_width: 50,
          width: 76,
        },
        predecessors: {
          min_width: 50,
          width: 70,
        },
      },
      advancedOptions: {
        start_date: {
          check: true,
        },
        end_date: {
          check: true,
        },
      },
    },
    discoloration_tasks: true,
    cross_out_tasks: true,
    right_side_text: 'insideText',
    userSkin: 4,
  };

  return config;
};

projects.getGlobalProjectData = function () {
  const projectData = {
    gantt_id: projects.globalGanttId,
    id: projects.globalGanttId,
    access: 100,
    advanced_rights: 15,
    is_archived: 0,
    name: '',
    skin: '4',
    status: 'team',
    user_id: user.id,
    userConfig: {},
    isGlobal: true,
    config: projects.getDefaultConfig(),
  };

  return projectData;
};

projects.getProjectDataById = function (ganttId) {
  return this.getItem(ganttId);
};

projects.getProjectConfig = function (ganttId) {
  const cId = ganttId || projects.activeGanttId;
  const projectData = projects.getItem(cId);

  if (!projectData) {
    return projects.getDefaultConfig();
  }

  const projectConfig = _.cloneDeep(projectData.config);

  return _.cloneDeep(userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(cId, projectConfig));
};

projects.removeLinkWithJira = function () {
  _.each(this.serialize(), project => {
    project.is_jira = null;
  });
};

projects.updateProjectConfig = function (updateProjectConfig) {
  this.updateItem(projects.activeGanttId, { config: updateProjectConfig });
};

projects.updateProjectFileStore = function (ganttId, newFileSize, mode) {
  const projectData = this.getItem(ganttId);
  let newSize;

  if (mode === 'upload') {
    newSize = (projectData?.fileStore_size || 0) + newFileSize;
  }

  if (mode === 'delete') {
    const result = projectData.fileStore_size - newFileSize;

    newSize = result > 0 ? result : 0;
  }

  if (!projectData) return;
  projectData.fileStore_size = newSize;
  projects.data.pull[ganttId] = webix.copy(projectData);
};

projects.getProjectDataFromServerByGanttId = function (ganttId) {
  return webix.ajax()
    .get(`api/collaboration/data/${ganttId}/type/`);
};

projects.updateGanttSettings = function (id = null) {
  if (!projects.paramsToUpdate.length) {
    return true;
  }

  const projectData = id ? this.getProjectDataById(id) : this.getActiveProjectData();
  const projectConfig = projects.getProjectConfig();
  let isCalendarChanged = false;
  let isNameChangedGlobal = false;

  if (!projectData || projectData.gantt_id === projects.globalGanttId) {
    return false;
  }

  projects.paramsToUpdate = userProjectConfigModel.analyzeParamsAndSplitToUserConfig(projects.paramsToUpdate, projectData.gantt_id, projectConfig);

  if (!projects.paramsToUpdate.length) {
    return true;
  }

  projectData.isDurationViewUpdate = (projects.paramsToUpdate.length === 1 && projects.paramsToUpdate[0].key === 'duration_view');

  _.each(projects.paramsToUpdate, param => {
    const isSkinChanged = param.key === 'skin';
    const isNameChanged = param.key === 'name';

    if (param.key === 'name') {
      isNameChangedGlobal = true;
    }

    if (param.key === 'durationData') {
      isCalendarChanged = true;
    }

    if (isSkinChanged || isNameChanged) {
      projectData[param.key] = param.value;

      if (isNameChanged) {
        projects.data.callEvent('updateProjectName', [projectData.id, projectData.name]);
      }

      if (isSkinChanged) {
        projectData.userSkin = param.value;
        projectConfig.userSkin = param.value;
      }
    } else {
      projectConfig[param.key] = _.isUndefined(param.value) ? false : param.value;
    }
  });

  projectData.config = userProjectConfigModel.getUserConfigByGanttIdAndMergeToProjectConfig(projectData.gantt_id, projectData.config);

  projectData.config = projectConfig;

  const projectDataForUpdate = _.cloneDeep(projectData);

  if (projectDataForUpdate.config.userSkin) {
    delete projectDataForUpdate.config.userSkin;
    delete projectDataForUpdate.userSkin;
    delete projectDataForUpdate.userConfig;
  }

  projectDataForUpdate.isCalendarChanged = isCalendarChanged;
  projectDataForUpdate.isNameChanged = isNameChangedGlobal;

  if (isNameChangedGlobal) {
    globalStore.commit('integrationGoogle/updateLinkName', { id: projectData.id, name: projectData.name });
    globalStore.commit('integrationOutlook/updateLinkName', { id: projectData.id, name: projectData.name });
  }
  projects.updateItem(projectData.id, projectDataForUpdate);

  if (projectData.firstInit) {
    delete projectData.firstInit;
  }

  projects.paramsToUpdate.length = 0;
};

function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}

projects.checkParamsToUpdate = function (checkParam, ganttId) {
  if (ganttId === projects.globalGanttId) {
    return false;
  }

  const projectConfig = projects.getProjectConfig();
  const isFromTemplate = projectConfig.isFromTemplate && projectConfig.isFromTemplate !== 'none';
  let hasChanges = false;

  if (_.isObject(checkParam.value) && _.isObject(projectConfig[checkParam.key])) {
    const diff = difference(checkParam.value, projectConfig[checkParam.key]);

    if (!_.isEmpty(diff)) {
      const keys = _.keys(diff);

      hasChanges = !(keys.length === 1 && keys[0] === 'sizes');
    }
  } else {
    hasChanges = !_.isEqual(checkParam.value, projectConfig[checkParam.key]);
  }

  if (hasChanges || isFromTemplate) {
    this.addParamsToUpdate(checkParam);

    return true;
  }

  return false;
};

projects.checkParamsToUpdateAndUpdate = function (checkParam, ganttId) {
  ganttId = ganttId || projects.getActiveGanttId();
  if (this.checkParamsToUpdate(checkParam, ganttId)) {
    this.updateGanttSettings();

    return true;
  }

  return false;
};

projects.addParamsToUpdate = function (paramsArray) {
  if (projects.activeGanttId !== projects.globalGanttId) {
    this.paramsToUpdate.push(paramsArray);
  }
};

projects.addNewProjectByImport = function (projectName, projectConfig, importProjectType, projectType) {
  this.add({
    user_id: GT.user.id,
    skin: '4',
    name: projectName,
    gantt_id: Date.now().valueOf(),
    status: 'import',
    projectStatus: projectType,
    config: projectConfig,
    importType: importProjectType,
  });
};

projects.initNewProject = function (projectData) {
  app.trigger('project:add', projectData.resources, projectData.tasks, parseInt(projectData.gantt_id));

  this.clearExtraData(projectData.id);
  this.data.pull[projectData.id].gantt_id = parseInt(projectData.gantt_id);
  this.data.pull[projectData.id].access = 100;

  if (projectData.status === 'import') {
    if (projectData.projectStatus.length) {
      projectData.status = projectData.projectStatus;
    } else {
      projectData.status = constants.DEFAULT_PROJECT_TYPE;
    }
  }

  this.data.pull[projectData.id].status = projectData.status || constants.DEFAULT_PROJECT_TYPE;
  app.trigger('project:add:finish', projectData.id);
};

projects.clearExtraData = function (projectId) {
  const project = this.data.pull[projectId];

  project.resources && delete project.resources;
  project.tasks && delete project.tasks;
  project.settings && delete project.settings;
  project.templateId && delete project.templateId;
  project.userId && delete project.userId;
};

projects.sortByFavorite = function (projectA, projectB) {
  if (+projectA.is_favorite !== +projectB.is_favorite) {
    return projectB.is_favorite - projectA.is_favorite;
  }

  if (projectA.id > projectB.id) {
    return 1;
  }

  if (projectA.id < projectB.id) {
    return -1;
  }

  return 0;
};

projects.getAllProjects = function () {
  const allProjects = webix.copy(projects.serialize());

  return allProjects.filter(pr => (!pr.is_archived && !pr.isGlobal));
};

projects.getAllFavorite = function () {
  return _.filter(projects.getAllProjects(), project => project.is_favorite);
};

projects.getAllJiraProjects = function () {
  return _.filter(projects.getAllProjects(), project => project.is_jira);
};

projects.addArchivedProjects = function (ganttID) {
  !archivedProjects.exists(ganttID) && archivedProjects.add(projects.getItem(ganttID));
};

projects.removeArchivedProjects = function (ganttID) {
  archivedProjects.exists(ganttID) && archivedProjects.remove(ganttID);
  delete archivedProjects.data.pull[ganttID];
};

projects.isArchived = function (ganttID) {
  return !!archivedProjects.getItem(ganttID);
};

projects.isProjectOwner = function (userID, ganttID) {
  const projectData = projects.getItem(ganttID) || archivedProjects.getItem(ganttID);

  if (projectData) {
    return projectData.user_id === userID;
  }

  return false;
};

projects.getArchivedProject = function (ganttID) {
  return archivedProjects.getItem(ganttID);
};

projects.getArchivedProjects = function () {
  return archivedProjects.serialize();
};

projects.openProjectAfterDelete = function () {
  if (!_.some(projects.data.order, ganttID => {
    if (!projects.isArchived(ganttID) && projects.data.pull[ganttID]) {
      app.trigger('openProject', ganttID); // set active top project in the order list

      return true;
    }
  })) {
    projects.setActiveProject();
  }
};

projects.manualRemoveProject = function (ganttId) {
  const projectData = this.getItem(ganttId);
  let pos = 0;

  delete projects.data.pull[ganttId];

  _.each(projects.data.order, (id, i) => {
    if (id == ganttId) { // because id and ganttId can be number or string
      pos = i;
    }
  });

  projects.data.order.splice(pos, 1);
  projects.refresh();

  projects.data.callEvent('projectListRefreshed');

  if (+gantt.config.gantt_id === +ganttId) {
    projects.data.callEvent('removeProject', [projectData.status, ganttId]);
    projects.openProjectAfterDelete();
  }

  projects.removeArchivedProjects(+ganttId);

  if (projects.count() <= 0) {
    app.trigger('removed:last:project');
  }
};

projects.manualRemoveAllTeamProjects = function () {
  _.each(projects.getAllProjects(), teamProject => {
    delete projects.data.pull[teamProject.id];
    projects.data.order.splice(projects.data.order.indexOf(teamProject.id), 1);
  });
};

projects.manualAddProject = function (projectDataInner, projectType, manualType) {
  const ganttId = parseInt(projectDataInner.gantt_id, 10);

  if (_.isUndefined(projectDataInner.last_update)) {
    projectDataInner.last_update = Date.now();
  }

  if (_.isUndefined(projectDataInner.project_id)) {
    projectDataInner.project_id = projectDataInner.id;
  }

  projectDataInner.id = ganttId;

  projects.data.pull[projectDataInner.id] = preInitObject.prepareInitProjects(webix.copy(projectDataInner));
  projects.data.pull[projectDataInner.id].status = projectType;
  projects.data.pull[projectDataInner.id].access = projectDataInner.rights;
  projects.clearExtraData(projectDataInner.id);

  if (projects.data.order.indexOf(projectDataInner.id) === -1) {
    projects.data.order.push(projectDataInner.id);
  }

  projects.refresh();
  projects.data.callEvent('projectListRefreshed');

  if (manualType !== 'silence') {
    projects.data.callEvent('addProject', [projectType, projectDataInner.id]);
  }
};

projects.manualUpdate = function (projectData) {
  projects.data.pull[projectData.id] = projectData;
  projects.data.callEvent('updateProject', [projectData.status, projectData.id]);
  app.trigger('projectModel:updateProject', projectData.id);

  globalStore.commit('integrationGoogle/updateLinkName', { id: projectData.id, name: projectData.name });
  globalStore.commit('integrationOutlook/updateLinkName', { id: projectData.id, name: projectData.name });
};

projects.manualUpdateAutoBudget = function (projectId, status) {
  const project = projects.getItem(projectId);

  project.config.auto_budget = status;
  projects.data.pull[projectId] = project;
};

projects.manualLiveUpdate = function (projectData, ignoreUpdate, runAutoScheduling) {
  projects.data.pull[projectData.id] = webix.copy(projectData);
  projects.data.callEvent('updateProjectName', [projectData.id, projectData.name]);
};

projects.manualUpdateAccess = function (projectData) {
  let oldProjectData = {};

  if (projects.exists(projectData.id)) {
    oldProjectData = projects.data.pull[projectData.id];
    projects.data.pull[projectData.id].access = parseInt(projectData.access, 10);
    projects.data.callEvent('updateProject', [oldProjectData.status, projectData.id]);
  }
};

// projects.manualUpdateRights = function (projectData) {
//   if (projects.exists(projectData.id)) {
//     projects.data.pull[projectData.id].advanced_rights = projectData.advanced_rights;
//     projects.data.callEvent('changeAdvancedRights', [projectData.status, projectData.id, projectData.gantt_id]);
//   }
// };

projects.manualUpdateFavorite = function (ganttId, isFavorite) {
  return webix.ajax().put(`/api/projects/${ganttId}/favorite`, { isFavorite: isFavorite ? 1 : 0 })
    .then(res => {
      const data = res.json();

      if (!(data.status && data.status == 'error') && data.updated) {
        const project = projects.getItem(ganttId);

        projects.data.pull[project.id].is_favorite = isFavorite;
        projects.data.blockEvent();
        projects.updateItem(project.id, { is_favorite: isFavorite });
        projects.data.unblockEvent();
      }
      app.trigger('refresh:favoriteList');

      return data.updated;
    });
};

projects.manualUpdateArchive = function (data) {
  if (!(data.status && data.status === 'error') && data.updated) {
    const project = projects.getItem(data.ganttId);

    if (!project) {
      return false;
    }
    projects.data.pull[project.id].is_archived = data.isArchived;
    project.is_archived = data.isArchived;
    project.archived_date = data.date;

    projects.data.blockEvent();
    projects.updateItem(data.ganttId, project);
    projects.data.unblockEvent();
  }

  return data.updated;
};

projects.markProjectForUpdate = function (ganttId) {
  if (projects.ganttIdsForUpdate.indexOf(ganttId) === -1) {
    projects.ganttIdsForUpdate.push(ganttId);
  }
};

projects.updateUserConfigInHistoryMode = function (ganttId, newDurationData) {
  userProjectConfigModel.updateUserConfigInHistoryMode(ganttId, newDurationData);
};

webix.dp(projects).attachEvent('onAfterSync', (obj, dataRaw, data) => {
  if (obj.status === 'insert' && data) {
    projects.initNewProject(data);
  }
});

export default projects;
