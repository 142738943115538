// public/helpers/passHelper.js
import TaiPasswordStrength from 'tai-password-strength';

const __ = window.__;
const TaiPasswordTester = new TaiPasswordStrength.PasswordStrength();
TaiPasswordTester.addTrigraphMap(TaiPasswordStrength.trigraphs);

const getPasswordStrengthColorByCode = strengthCode => {
  const STRENGTH_CODE_TO_COLOR = {
    VERY_WEAK: '#d50000',
    WEAK: '#ee7950',
    REASONABLE: '#ff9a00',
    STRONG: '#2e7d32',
    VERY_STRONG: '#1b5e20',
  };

  return STRENGTH_CODE_TO_COLOR[strengthCode] || '#9f9f9f';
};

const getPasswordStrengthNumberByCode = strengthCode => {
  const STRENGTH_CODE_TO_NUMBER = {
    VERY_WEAK: 1,
    WEAK: 2,
    REASONABLE: 3,
    STRONG: 4,
    VERY_STRONG: 5,
  };

  return STRENGTH_CODE_TO_NUMBER[strengthCode];
};

const renderPwdStrengthTpl = (strengthCode, maxStrength = 5) => {
  const strengthNumber = getPasswordStrengthNumberByCode(strengthCode);

  const _blocksArr = [];

  for (let i = 0; i < maxStrength; i++) {
    _blocksArr.push(
      i < strengthNumber
        ? getPasswordStrengthColorByCode(strengthCode)
        : '#9f9f9f'
    );
  }

  return `
    <div style="padding: 0; width: auto; box-sizing: border-box; height: 10px; line-height: 6px;">
      ${
        _blocksArr
          .map(entry => `<div style='margin: 0; margin-right: 12px; width: 36px; height: 4px; background-color: ${entry}; display: inline-block;'></div>`)
          .join('')
      }
    </div>
  `;
};


const getPasswordStrengthStatistics = password => {
  return TaiPasswordTester.check(password);
};

const getPasswordStrengthTextByCode = strengthCode => {
  const STRENGTH_CODE_TO_TEXT = {
    VERY_WEAK: __('pass_strength_tooweak'),
    WEAK: __('pass_strength_weak'),
    REASONABLE: __('pass_strength_average'),
    STRONG: __('pass_strength_strong'),
    VERY_STRONG: __('pass_strength_verystrong'),
  };

  return STRENGTH_CODE_TO_TEXT[strengthCode] || '';
};

export {
  renderPwdStrengthTpl,
  getPasswordStrengthNumberByCode,
  getPasswordStrengthStatistics,
  getPasswordStrengthTextByCode,
  getPasswordStrengthColorByCode,
};
