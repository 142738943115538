/* eslint-disable */
import filtersModel from "../../../models/filters";
import constants from "$$helpers/constants";
import _ from "../../../libs/lodash";
import taskToXlsxExportHelpers from './helpers/taskToXlsxExportHelpers';
import xlsxExport from "../xlsxExport";
import rights from "../../../components/rights";
import globalStore from "$$store/main";
import ganttFilterHelper from '$$helpers/gantt_filter';

const __ = window.__;
const baselinesPostfix = '_baseline';


var innerObject = {
  init: {
    run: function () {
    },
    afterInit: function () {
    },
  },

  handlers: {

    export: function (exportDateOptions, baseline) {
      let rows;
      let projectStartDate = exportDateOptions.server ? gantt.config.start_date : false;
      let tasksData;
      let baselineModel;
      let costRights = gantt.config.multiview_id || rights.project.hasCostRight(gantt.config.gantt_id);
      let allTasksRights = gantt.config.multiview_id || rights.project.hasRight(gantt.config.gantt_id, 'all_tasks');

      var exportOptions = {
        title: exportDateOptions.title,
        isBaseline: !!(exportDateOptions.export_baseline * 1), // id
        enablePrice: !!costRights,
        worksheets: [],
        projectStartDate,
        server: exportDateOptions.server || false,
      };

      tasksData = !exportOptions.isBaseline ? gantt.serialize() : baseline.tasksState;
      baselineModel = exportOptions.isBaseline ? baseline.baselineModelData : null;

      const resourceProjectDataCache = {};

      tasksData.data.forEach(task => {
        if (task.type === constants.TASK_TYPES.project) {
          return;
        }

        if (!resourceProjectDataCache[task.gantt_id]) {
          resourceProjectDataCache[task.gantt_id] = globalStore.getters['resourcesModel/getResourcesByProjectId'](task.gantt_id);
        }

        task.resources = task.resources.filter(tR => resourceProjectDataCache[task.gantt_id].find(r => r.id === tR.resource_id));
      });

      // let isFilterSet = filtersModel.isSetFilter(filterData);
      let isFilterSet = globalStore.getters['filter/isActive'];

      if (isFilterSet || !allTasksRights) {
        const filteredTasks = tasksData.data.filter(task => gantt.$data.tasksStore.visibleOrder.includes(task.id));

        // if (exportOptions.isBaseline) {
        //   filterData.ganttData.data = filterData.ganttData.data.map(item => {
        //     return tasksData.data.find(task => task.id === item.id);
        //   });
        // }

        rows = innerObject.handlers.ganttPrepareFilterGanttDataToExport(filteredTasks, { postfix: baselinesPostfix }, { projectStartDate }, baselineModel); // exportOptions.data =
      } else {
        rows = innerObject.handlers.ganttSerializeWithoutCustomButtonsForExport(tasksData, { postfix: baselinesPostfix }, { projectStartDate }, baselineModel); // exportOptions.data =
      }

      rows = rows.sort((a, b) => {
        return a.data.wbsDecimal - b.data.wbsDecimal;
      });

      let splitLevelsToColumns = {
        depth: innerObject.handlers.getMaxDepthBylevelColumn(rows, 'level'),
        indentColumn: 'text',
        levelColumn: 'level',
        label: __("export_excel_task_name"),
      };

      let columnsOptions = {
        splitLevelsToColumns: splitLevelsToColumns,
        excludedColumns: exportDateOptions.server ? ['project', 'type', 'level', 'created', 'creator'] : [],
      };

      if (exportOptions.isBaseline) {
        columnsOptions = {
          splitLevelsToColumns: false,
          compareWithBaselineOptions: {
            excludeBaselineColumns: ['wbs', 'color', 'created', 'actual_cost', 'creator', 'type', 'project', 'level', 'time_tracking'],
            postfix: baselinesPostfix,
          },
        };
      }

      let columns = taskToXlsxExportHelpers.getTaskColumns(exportOptions, columnsOptions);

      exportOptions.worksheets.push({
        title: exportOptions.title,
        rows,
        columns,
      });

      xlsxExport.export(exportOptions);
    },

    getMaxDepthBylevelColumn: function (rows, levelColumn) {
      let depth = 0;
      rows.map(function (row, j) {
        if (row.data[levelColumn] > depth) {
          depth = row.data[levelColumn];
        }
      });
      return depth;
    },

    ganttPrepareFilterGanttDataToExport: function (ganttFilterData, compareWithBaselineOptions, calculateOptions, baselineModel) {
      const ganttData = [];

      ganttFilterData.forEach((task, index) => {
        var level = gantt.calculateTaskLevel(task.id);
        if (task.type === gantt.config.types.button) {
          return;
        }
        if (task.type === gantt.config.types.project && task.parent && !level) {
          task.$virtual = true;
        }
        task.$level = level; // added here for xlsx export compability for filterd set
        task.$rendered_type = task.type; // added here for xlsx export compability for filterd set
        task.$index = gantt.taskDataStoreIndex(task.id);

        if (level === 0) return;
        ganttData.push(taskToXlsxExportHelpers.taskToRow(task, compareWithBaselineOptions, calculateOptions, baselineModel));
      });

      // resultData.data.sort(function (a, b) {
      //   return a.$index - b.$index;
      // });

      return ganttData;
    },

    ganttSerializeWithoutCustomButtonsForExport: function (tasks, compareWithBaselineOptions, calculateOptions, baselineModel) {
      let ganttData = [];
      let tasksData = tasks.data;
      tasksData.forEach(task => {
        let level = gantt.calculateTaskLevel(task.id);
        task.$level = level;
        if (task.resource && level) {
          task.parent = gantt.getChildren(0)[0];
        }
        if (task.type !== gantt.config.types.button) {
          if (task.parent || !level) {
            if (level === 0) return;
            ganttData.push(taskToXlsxExportHelpers.taskToRow(task, compareWithBaselineOptions, calculateOptions, baselineModel));
          }
        }
      });

      return ganttData;
    },
  },
};

var outputObject = {
  init: {
    run: innerObject.init.run,
    afterInit: innerObject.init.afterInit,
  },
  export: innerObject.handlers.export,
};

// app.on("gantt:export:xlsx", innerObject.handlers.export);

export default outputObject;

