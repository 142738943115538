var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: _vm.isShow,
      title: _vm.config.title || null,
      width: "440px",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "content",
                style: {
                  padding: "24px 0 28px 0",
                  "font-size": "16px",
                  "line-height": "24px",
                },
              },
              [
                _c("div", { staticClass: "message" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.config.message) + "\n      "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "footer",
                style: { display: "flex", "justify-content": "flex-end" },
              },
              [
                _c("vgp-button", {
                  attrs: {
                    label: _vm.locales("common_reload_btn"),
                    type: "primary",
                    small: true,
                  },
                  on: { onClick: _vm.onReload },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }