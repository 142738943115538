var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stub_wrap" },
    [
      _c("svgImage", {
        staticClass: "stub_image",
        attrs: { name: _vm.stub.icon },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "stub_title" }, [
        _vm._v("\n    " + _vm._s(_vm.stub.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stub_description" }, [
        _vm._v("\n    " + _vm._s(_vm.stub.body) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }