<template>
  <div
    :class="[$style['header']]"
  >
    <div :class="[$style['header-line-first']]">
      <div
        :class="[$style['header-dropdown']]"
      >
        <dropdownSelect
          class="gp_autotest_comment_hub_projects_dropdown"
          :drop-list="projects"
          :selected="filteredProjects"
          :multiselect="true"
          :use-search="true"
          :use-select-all="true"
          :track-by="'id'"
          item-template="text"
          track-by-text="name"
          :placeholder="'search'"
          :show-selected-first="true"
          :show-reset-btn="true"
          :stub-text-if-all-selected="locale('comment_hub_all_projects')"
          :stub-text-if-all-unselected="locale('comment_hub_all_projects')"
          @change="onChange"
        />
      </div>
      <div
        :style="{width: '332px'}"
      >
        <vgp-input
          v-model="searchStr"
          class="gp_autotest_comment_hub_search_input"
          :type="'text'"
          :placeholder="locale('comment_hub_search_tasks_and_comments')"
          :icons-left="[
            { name: 'search' }]"
          :icons-right="iconsRightConfig"
          :small="true"
          :select-by-focus="false"
          @onKeydown="search"
        />
      </div>
    </div>
    <div :class="[$style['header-line-second']]">
      <vgp-tabs
        :tabs="tabsItems"
        :active="filterButton"
        :type="'button'"
        :amounts="tabsAmounts"
        gp-autotest-name="gp_autotest_comment_hub_filter_buttons"
        @onToggle="(tab) => $emit('toggle', tab)"
      />
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import dropdownSelect from '../../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import vgpTabs from '../../common/VueComponents/tabs/vgpTabs.vue';

export default {
  name: 'FilterHeader',
  components: {
    dropdownSelect,
    vgpTabs,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    filteredProjects: {
      type: Array,
      required: true,
    },
    tabsItems: {
      type: Array,
      required: true,
    },
    tabsAmounts: {
      type: Object,
      required: true,
    },
    filterButton: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locale: __,
      searchStr: '',

    };
  },
  computed: {
    iconsRightConfig() {
      if (this.searchStr) {
        return [{
          name: 'close-4',
          type: 'bold',
          size: '24',
          handler: this.resetSelection,
        }];
      }

      return [];
    },
  },
  methods: {
    resetSelection() {
      this.searchStr = '';
      this.$emit('search', '');
    },
    onChange(opt) {
      this.$emit('change', opt);
    },

    search: _.debounce(function () {
      return this.$emit('search', this.searchStr);
    }, 1000),

  },
};
</script>

<style module src="../less/commentsHub.less" lang="less"></style>
