export const composeActivities = (scheme, data) => scheme.map(schemeEntry => {
  const dataEntry = data.find(entry => entry.name === schemeEntry);

  return {
    name: schemeEntry,
    activity_status: dataEntry
      ? dataEntry.activity_status
      : 0,
    date: dataEntry ? dataEntry.date : '',
    settings: dataEntry?.settings ? dataEntry.settings : null,
  };
});

export const handleError = (err) => {
  console.warn(err); // !DEBUG
  webix.message({
    type: 'error',
    text: `Failed to update user activity: ${err.message}`,
  });
};
