// !TODO: description

import app, { appIds } from '../../../app';
import massChangeModel from './massChange';
import projectsModel from '../../../models/projects';
import constants from "../../../helpers/constants";

const { events: { ID_EVENT_MASSCHANGE_UPDATESTATE, ID_EVENT_POPUP_CONFIRMATON_SHOW } } = appIds;

// !FIXME
// const paletteConfig = [
//     ['#5FC8F5', '#57B2F3', '#00A7E1', '#C196E3', '#957EB8', '#53C8D5'],
//     ['#7FCCC4', '#D6DF7F', '#ACC592', '#7DB182', '#3A978D', '#FFD16E'],
//     ['#FFB255', '#FF9B71', '#F89990', '#D860BB', '#D1D1D1', '#949494']
// ];

const ID_POPUP_COLOR_PICKER = 'gridColorboardPopup2'; // !FIXME
const ID_CONTROL_COLOR_BOARD = 'gridColorboard2'; // !FIXME

const _api = {
    execMassChangeActions: (actions, ganttId) =>
        webix.ajax()
            .post('/api/masschange/actions', {
                actions, gantt_id: ganttId
            })
            .then(response => response.json())
};

const _onSelectColor = (colorValue) => {
    app.trigger(ID_EVENT_POPUP_CONFIRMATON_SHOW, true);

    // !FIXME
    $$(ID_CONTROL_COLOR_BOARD).setValue(colorValue);
    $$(ID_CONTROL_COLOR_BOARD).refresh();

    // const __idColor = colorHelper.getColorId(colorValue);
    const __activeGanttId = projectsModel.getActiveGanttId();

    const __actions = [{
        actionType: 3, // !FIXME: constant
        actionPayload: {
            tasks: massChangeModel.getSelectedList().filter((idTask) => {
                const __taskEntry = gantt.getTask(idTask);
                return __taskEntry.parent && ['task', 'project', 'milestone'].indexOf(__taskEntry.type) !== -1;
            }),
            value: colorValue, //__idColor
        },
    }]

    massChangeModel.executeActions(__actions, __activeGanttId);
}

const _popupView = {
    view: 'popupWithoutPoint',
    css: 'grid-colorboard-popup masschange-colorboard-popup',
    id: ID_POPUP_COLOR_PICKER,
    padding: 8,
    hidden: true,
    body: {
        view: 'colorboardCst',
        id: ID_CONTROL_COLOR_BOARD,
        css: 'task-settings-color-board',
        width: 180,
        height: 120,
        palette: constants.PALETTE_CONFIG.palette.map(item => {return item.map(cell => cell.color)}),
        template: (obj) =>
          `<div class="color-bar" style="background: ${obj.val};"><div class="color-bar-inside-border"></div></div>`,
        on: {
            'onSelect': _onSelectColor
        }
    },
    on: {
        'onBeforeShow': function() {
            // !FIXME
            $$(ID_CONTROL_COLOR_BOARD).setValue();
            $$(ID_CONTROL_COLOR_BOARD).refresh();
        }
    }

};

app.on(ID_EVENT_MASSCHANGE_UPDATESTATE, (enable) => {
    if (!enable) {
        $$(ID_POPUP_COLOR_PICKER).hide();
    }
});

webix.ui(_popupView).hide();
