import { api, internalApi } from '$$store/api';

const googleIntegration = {
  namespaced: true,
  state: {
    googleLinks: [],
    firstLink: false,
  },
  getters: {
    googleLinks: state => state.googleLinks,
    isFirstLink: state => state.firstLink,
  },
  mutations: {
    setLinks(state, data) {
      state.googleLinks = data;
    },
    addNewLink(state, payload) {
      state.googleLinks.push(payload);
    },
    updateLink(state, payload) {
      state.googleLinks.forEach(link => {
        if (link.id === payload.id) {
          link.projects = payload.data.projects;
          link.connectionType = payload.data.connectionType;
        }
      });
    },
    removeLink(state, id) {
      state.googleLinks = state.googleLinks.filter(link => link.id !== id);
    },
    setFirstLink(state, data) {
      state.firstLink = data;
    },
    updateLinkName(state, payload) {
      state?.googleLinks.forEach(link => {
        link.projects.forEach(project => {
          if (project.ganttId === payload.id) {
            project.name = payload.name;
          }
        });
      });
    },
  },
  actions: {
    async loadData({ commit }) {
      const data = await internalApi.get('/integrations/google/calendar');

      commit('setLinks', data.data);
    },
    async createLink({ commit }, payload) {
      try {
        const { data } = await internalApi.post('/integrations/google/calendar',
          { projectIds: [payload.data.projects[0].ganttId], connectionType: payload.data.connectionType });

        commit('addNewLink', data);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateLink({ commit, getters }, payload) {
      try {
        const { data } = await internalApi.put(`/integrations/google/calendar/${payload.id}`,
          { projectIds: [payload.data.projects[0].ganttId], connectionType: payload.data.connectionType });
        const link = getters.googleLinks.find(item => item.id === payload.id);

        if (link) {
          userExtAnalytics.log('integration_google_calendar_updated', { fromType: link.connectionType, toType: payload?.data.connectionType });
        }
        commit('updateLink', payload);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    deleteLink({ commit }, id) {
      return internalApi.delete(`/integrations/google/calendar/${id}`).then(() => {
        commit('removeLink', id);
      });
    },

    async removeAfterCollabaration({ commit, state }, payload) {
      const data = [];
      const promises = [];

      state?.googleLinks.forEach(connection => {
        connection.projects.forEach(project => {
          if (project.ganttId === payload.id) {
            data.push(connection.id);
          }
        });
      });

      if (data.length) {
        for (const id of data) {
          promises.push(internalApi.delete(`/integrations/google/calendar/${id}`).then(() => {
            commit('removeLink', id);
          }));
        }

        await Promise.all(promises);
      }
    },
  },
};

export default googleIntegration;
