import app from '../../../../app';

const blockedEvents = [
  // 'TaskResourceUnassigned',
  // 'TaskResourceAssigned',
  // 'TaskResourceUpdated',
  'TaskDeleted',
  'TaskCreated',
  'TaskUpdated',
  // 'TaskCustomFieldUpdated',
  'TaskTimeLogsCreated',
  'TaskTimeLogDeleted',
  'TaskTimeLogUpdated',
  'TaskCommentCreated',
  // 'TaskCommentDeleted',
  'TaskAttachementCreated',
  'TaskAttachementDeleted',
  // 'TaskLinkDeleted',
  // 'TaskLinkCreated',

  'CustomFieldToProjectAssigned',
  'CustomFieldFromProjectUnassigned',
  'CustomFieldUpdated',
  'CustomFieldCreated',
  'CustomFieldDeleted',

  'ResourceToProjectsAssigned',
  'ResourceFromProjectsUnassigned',
  'ResourceDeleted',
  // 'ResourceCreated',
  'ResourceUpdated',
  'ResourceOnProjectsUpdated',
  'ResourceRoleUpdated',
  'ProjectArchived',
];

function activateBlockListheners(activateCollback) {
  app.on('gantt:contextMenu:showed', task_id => {
    activateCollback({
      task_id,
      events: blockedEvents,
      type: 'gantt',
      mode: 'contextMenu',
    });
  });
}

function deactivateBlockListheners(deactivateCollback) {
  app.on('gantt:contextMenu:hidden', deactivateCollback);
}

export default {
  activateBlockListheners,
  deactivateBlockListheners,
};
