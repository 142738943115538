var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: _vm.onBlur,
            closeConditional: function () {
              return _vm.active
            },
          },
          expression:
            "{\n    handler: onBlur,\n    closeConditional: () => active\n  }",
        },
      ],
      ref: "vgpQuillEditorWrapper",
      class: ["vgp-quill-editor-wrapper", _vm.draftBlur ? "ql-draft" : ""],
    },
    [
      _c("div", {
        ref: _vm.componentKey,
        class: [
          "vgp-interactive-element ql-container ql-snow",
          _vm.draftBlur ? "ql-draft " : "",
        ],
        attrs: { id: "vgpQuillEditorArea" },
      }),
      _vm._v(" "),
      _vm.withButtons
        ? _c(
            "div",
            { staticClass: "vgp-quill-editor-actions" },
            [
              _c("vgp-button", {
                attrs: {
                  type: "secondary",
                  label: _vm.locales("common_cancel"),
                  small: "",
                },
                on: { onClick: _vm.handleCancel },
              }),
              _vm._v(" "),
              _c("vgp-button", {
                attrs: {
                  disabled:
                    _vm.submitButtonDisabled || !!_vm.isImageUploading.length,
                  label: _vm.submitButtonLabel,
                  type: "primary",
                  small: "",
                },
                on: { onClick: _vm.handleSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }