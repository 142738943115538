var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.vgp_input_confirm,
        _vm.isDisabled ? _vm.$style.disabled : "",
        _vm.isError ? _vm.$style.error : null,
        _vm.isFocus ? _vm.$style.focus : null,
      ],
      on: {
        click: function ($event) {
          return _vm.focusElement(true)
        },
      },
    },
    [
      _vm.iconPosition === "left"
        ? [
            _c("sprite", {
              class: _vm.$style.vgpIcon,
              attrs: { color: "#909090", name: "check", size: 20 },
              on: { click: _vm.ok },
            }),
            _vm._v(" "),
            _c("sprite", {
              class: _vm.$style.vgpIcon,
              attrs: { color: "#909090", name: "close-1", size: 20 },
              on: { click: _vm.cancel },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("vgp-input", {
        ref: "input_confirm",
        class: _vm.$style.input,
        attrs: {
          small: _vm.isSmall,
          "input-type": _vm.type,
          placeholder: _vm.placeholder,
        },
        on: {
          onKeydown: _vm.onKeydown,
          input: _vm.onInput,
          change: _vm.onChange,
          onBlur: _vm.onBlur,
        },
        model: {
          value: _vm.data,
          callback: function ($$v) {
            _vm.data = $$v
          },
          expression: "data",
        },
      }),
      _vm._v(" "),
      _vm.iconPosition === "right"
        ? [
            _c("sprite", {
              class: _vm.$style.vgpIcon,
              attrs: { color: "#909090", name: "check", size: 20 },
              on: { click: _vm.ok },
            }),
            _vm._v(" "),
            _c("sprite", {
              class: _vm.$style.vgpIcon,
              attrs: { color: "#909090", name: "close-1", size: 20 },
              on: { click: _vm.cancel },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }