//import commentsModel from '../../../models/comments2';
import app from '../../../app';
import globalStore from '../../../store/main';


function onTaskCommentUpdated(event) {
  event.data.updated.forEach(commentUpdate => {
    const {
      commentId: id,
      updatedFields: { updatedAt: date_edited, content: comment },
    } = commentUpdate;

    //commentsModel.onWSEditComment({ data: { id, date_edited, comment } });

    globalStore.dispatch('comments/onWSCommentEdited', commentUpdate);
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: event.data.comments &&  event.data.comments.length ? event.data.comments.map(i => i.taskId) : [],
  });
}

export default onTaskCommentUpdated;
