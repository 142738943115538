import { composeActivities, handleError } from './helpers';
import { UserApi } from './api';
import app from '../../app';
import constants from '../../helpers/constants';

export default {
  async getActivities({ commit, rootState }) {
    if (!rootState.appMode.isLink) {
      const activities = await UserApi.getActivities();
      const composedActivities = composeActivities(Object.values(constants.USER_ACTIVITIES), activities);

      commit('setActivities', composedActivities);
      commit('setIsActivitiesLoaded', true);
      app.trigger('user:activities:loaded', composedActivities);
    }

    app.checkInit('userActivities');
  },
  async updateActivityStatus({ commit }, payload) {
    const { activityName, status } = payload;
    const activity = await UserApi.updateActivityStatus(activityName, status).catch(handleError);

    commit('updateActivity', activity);
    app.trigger('user:activity:status:updated', activity);

    return activity;
  },

  async updateActivitySettings({ commit, getters }, payload) {
    const { activityName, settings } = payload;
    const existingActivitySettings = getters.getActivitySettings(activityName) || {};
    let mergedOldAndNewSettings = false;

    if (settings) {
      mergedOldAndNewSettings = JSON.stringify({ ...existingActivitySettings, ...settings });
    }

    const activity = await UserApi.updateActivitySettings(activityName, mergedOldAndNewSettings).catch(handleError);

    commit('updateActivity', activity);

    return activity;
  },

};
