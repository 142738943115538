import { resourceHelper } from './helpers';
import app from '../../../app';

async function onTaskResourceAssigned(event) {
  const {
    innerSettings: {
      projectId: ganttId,
      resourcesToTasks,
      massAssignData,
    },
    data: { assignedResources },
  } = event;

  await resourceHelper(ganttId,
    resourcesToTasks,
    massAssignData,
    assignedResources);
  
  app.trigger('onAfterCollaboration', {
    event: event.event, 
    projects: [event.innerSettings.projectId],
    tasks: [event.innerSettings.taskId]
  });

  app.trigger('refresh:filter');
}

export default onTaskResourceAssigned;
