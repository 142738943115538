/* eslint-disable */
import globalStore from '../../../store/main';
import icon_close from "../../../svg/close.svg";
import icon_smile from "../../../svg/smile.svg";
import plansModel from '../../../models/plans';
import helperFeedback from "../../../helpers/feedback";
import app from "../../../app";
import projectsModel from "../../../models/projects";

import _ from "../../../libs/lodash";
import constants from "../../../helpers/constants";

const webix = window.webix;
const __ = window.__;

var innerSettings = {
  defaultTemplate: {
    view: 'template',
    borderless: true,
  },
  getConfigRatingResult: null,
  getConfigTextareaResult: null,
  getStepPosition: 1, // Position popup
  thankPopupVersion: 0,
  formType: 'nps-popup'
};

var innerViews = {
  getHeaderPopup: function (data) {
    return `
      <div class="popup_header">
        <div class="header_title"> ${__('nps_popup_header_label_' + (+data === 1 ? 0 : innerHandlers.getTypeText()))} </div>
        <div class="header_closer">${icon_close}</div>
      </div>
    `
  },
  getBodyStepFirst: function () {
    return `
      <div class="popup_body">
          <div class="body_rating">
            <div class="rating_title"> ${__("nps_popup_body_rat_text")} </div>
            <div class="rating_list">
                <div class="rating_list_box">
                    <div class="box_item" date-rate="1">${__("nps_popup_body_rat_01")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="2">${__("nps_popup_body_rat_02")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="3">${__("nps_popup_body_rat_03")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="4">${__("nps_popup_body_rat_04")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="5">${__("nps_popup_body_rat_05")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="6">${__("nps_popup_body_rat_06")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="7">${__("nps_popup_body_rat_07")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="8">${__("nps_popup_body_rat_08")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="9">${__("nps_popup_body_rat_09")}</div>
                 </div>
                <div class="rating_list_box">
                    <div class="box_item" date-rate="10">${__("nps_popup_body_rat_10")}</div>
                 </div>
            </div>
            <div class="rating_desc">
                <div class="desc_left"> ${__("nps_popup_rating_footer_item_01")} </div>
                <div class="desc_right"> ${__("nps_popup_rating_footer_item_02")} </div>
            </div>
      </div>
      </div>
    `
  },
  getFooterPopup: function (data) {
    return `
      <div class="popup_footer">
        <div class="popup_footer_button btn_cancel button_default">${__('nps_popup_footer_button_cancel_' + (+data === 1 ? 1 : 2))}</div>
        <div class="popup_footer_button btn_send button_send">${__('nps_popup_footer_button_send_2')}</div>
      </div>
    `
  },
  getTemplateStepFirst: function (data) {
    return innerViews.getHeaderPopup(data) + innerViews.getBodyStepFirst(data) + innerViews.getFooterPopup(data);
  },
  getBodyStepSecond: function () {
    return `
      <div class="popup_body">
        <div class="body_textarea">
            <div class="textarea_title"> ${__("nps_popup_textarea_title_" + innerHandlers.getTypeText())} </div>
            <textarea class="textarea_content" form="text" name="feedback__text" placeholder="${__("nps_popup_textarea_placeholder")}"></textarea>
        </div>
      </div>
    `
  },
  getTemplateStepSecond: function (data) {
    return innerViews.getHeaderPopup(data) +
      innerViews.getBodyStepSecond(data) +
      innerViews.getFooterPopup(data);
  },
  getThankPopupCloser: (configData) => configData ? `<div class="thank_closer">${icon_close}</div>` : '',
  getThankPopupImage: () => `
      <div class="thank_img">
        ${icon_smile}
      </div>`,
  getThankPopupTitle: (dataMessage) => `
      <div class="thank_title">
        <span class="title_content">${!dataMessage ? __('nps_thank_popup_title') : __('nps_thank_popup_title_' + dataMessage)}</span>
      </div>`,
  getThankPopupText: (configData) => {
    if (configData) {
      let chanceText = 'nps_thank_popup_text_' + (Math.floor(Math.random() * 2) + 1);

      return `
            <div class="thank_text">
              <span class="text_content">${__(chanceText)}</span>
            </div>`
    } else {
      return `
            <div class="thank_button">
              <div class="button button_send">${__('nps_thank_popup_btn')}</div>
            </div>`
    }
  },
  getThankPopupTemplate: function (configThankPopup, dataMessage) {
    return innerViews.getThankPopupCloser(configThankPopup) +
      innerViews.getThankPopupImage() +
      innerViews.getThankPopupTitle(dataMessage) +
      innerViews.getThankPopupText(configThankPopup);
  }
};

var innerHelpers = {
  getStatusDateRegistrationFromNow: function () {
    let dateNow = moment().valueOf();
    let dateReg = moment(user.registrationTime).valueOf();
    let fromToNow;

    fromToNow = (dateNow - dateReg) / (1000 * 60 * 60 * 24);

    return fromToNow;
  },
  getStatusDateByAnswerLater: function (feature) {
    let activityDateNpsPopup;
    let fromToLastActivity;

    if (feature.date) {
      activityDateNpsPopup = feature.date;
    } else if (!feature.date && user.activity.find(entry => entry.name === 'npsPopup')) {
      activityDateNpsPopup = user.activity.find(entry => entry.name === 'npsPopup').date;
    } else {
      return false;
    }

    let dateNow = moment().valueOf();
    let dateFromLastActivity = moment(activityDateNpsPopup).valueOf();

    fromToLastActivity = (dateNow - dateFromLastActivity) / (1000 * 60 * 60 * 24); // Days
    // fromToLastActivity = Math.floor(((dateNow - dateFromLastActivity) % 36e5) / 6e4); // minute
    return fromToLastActivity;
  },
  showNpsPopup: function () {
    setTimeout(function () {
      outputObject.init.run();
    }, 10000);
  },
  createThankPopup: function () {
    const resultValidateThank = innerHandlers.validateThankPopup();
    const configThankPopup = resultValidateThank ? 1 : 0;
    initThankPopup.init.run(configThankPopup);
  },
  createThankPopupView: function (configFullVersion, dataMessage) {
    return {
      // id: thankPopupID,
      css: configFullVersion ? 'wrapper_thank full_version' : 'wrapper_thank',
      view: 'ganttproWindowPopup',
      width: configFullVersion ? 430 : 430,
      // height: configFullVersion ? 256 : 236,
      autoheight: true,
      body: {
        css: 'thank_body',
        autoheight: true,
        ...innerSettings.defaultTemplate,
        template: innerViews.getThankPopupTemplate(configFullVersion, dataMessage),
        onClick: {
          'thank_closer': function () {
            let thisId = this.config.id;
            $$(thisId) && $$(thisId).hide();
          },
          'button': function (event, data, node) {
            let thisId = this.config.id;
            $$(thisId) && $$(thisId).hide();

            setTimeout(() => {
              if ($$(node.closest('.wrapper_thank'))) {
                let parentId = $$(node.closest('.wrapper_thank').getAttribute('view_id'));

                parentId && parentId.hide();
              }
            }, 100);
          }
        }
      },
      on: {
        'onHide': function () {
          if (document.querySelector('.popup_proto_shadow')) {
            document.querySelector('.popup_proto_shadow').classList.remove('show')
          }
        }
      }
    }
  },
  createViewBody: function (data, popupID) {
    return {
      id: bodyID,
      css: 'nps_popup_wrapper',
      ...innerSettings.defaultTemplate,
      template: innerViews.getTemplateStepFirst(data),
      onClick: {
        'header_closer': () => {
          if (+data === 1) {
            innerHandlers.showLaterPopup();
            userExtAnalytics.log('nps_popup_close');
          }

          innerHandlers.toggleShowHidePopup(false);

          setTimeout(function () {
            $$(popupID).hide();
          }, 500);
        },
        'btn_cancel': () => {
          if (+data === 1) {
            innerHandlers.showLaterPopup();
            userExtAnalytics.log('nps_popup_close');
          }
          if ($$('npsFeedbackView').config.getStep() === 2) {
            let ratingData = innerSettings.getConfigRatingResult;
            let typeData = innerSettings.formType;

            innerHandlers.doNotShowAgainPopup();
            innerHelpers.createThankPopup();
            innerHelpers.sendEmail(typeData, ratingData);

            userExtAnalytics.log('nps_popup_skip');
            userExtAnalytics.log('nps_popup_done',{score: innerSettings.getConfigRatingResult});
          }

          innerHandlers.toggleShowHidePopup(false);
          setTimeout(function () {
            $$(popupID).hide();
          }, 500);
        },
        'btn_send': function () {
          innerHandlers.switchCaseStep($$('npsFeedbackView').config.getStep());
        },
        'rating_list_box': function () {
          let ratingTitle = document.querySelector('.rating_title');
          let ratingWrap = document.querySelector('.body_rating');

          ratingWrap.querySelector('.current-active') && ratingTitle.classList.contains('error') ? ratingTitle.classList.remove('error') : '';
        }
      },
      on: {
        'onKeyPress': function (code) {
          let textAreaTitle = document.querySelector('.textarea_title');
          let textAreaElement = document.querySelector('.textarea_content');

          if (textAreaElement.value.trim() === '' && (code === 13 || code === 32)) {
            textAreaElement.classList.add('error');
            textAreaTitle.classList.add('error');
          } else {
            textAreaElement.classList.remove('error');
            textAreaTitle.classList.remove('error');
          }

          _.delay(function () {
            innerHandlers.checkErrorTextArea();
          })
        }
      }
    }
  },
  createViewPopup: function ({popupID, data}) {
    const body = innerHelpers.createViewBody(data, popupID);

    return {
      id: popupID,
      view: "notificationPopup",
      css: "nps_popup ",
      width: 583,
      // height: 241,
      minHeight: 228,
      autoheigth: true,
      head: false,
      body: body,
      getStep: function () {
        return innerSettings.getStepPosition;
      },
      position: function (state) {
        let popupWidth = state.width;
        let popupHeight = state.height;
        let popupMargin = 4;

        state.left = document.body.clientWidth - popupWidth - popupMargin * 2;
        state.top = document.body.clientHeight - popupHeight - popupMargin;
      },
      on: {
        'onShow': function () {
          innerHandlers.ratingConfig();
        }
      }
    }
  },
  sendEmail: function (type, rating, message) {
    helperFeedback.send({
        type: type,
        message: message || '',
        rating: rating || '',
    });
  },
  onInitChangeStatusPopup: function () {

    const helpNpsPopupActivity = globalStore.getters['user/getActivity'](constants.USER_ACTIVITIES.HELP_NPS_POPUP);
    const activityStatus = helpNpsPopupActivity.activity_status;

    if (activityStatus === -1) {
      return;
    }

    if (activityStatus === 0 || (activityStatus <= 1 && innerHelpers.getStatusDateByAnswerLater(helpNpsPopupActivity) >= 14)) {
      innerHelpers.showNpsPopup();
    }
  },
};

var innerHandlers = {
  switchCaseStep: function (data) {

    if (data === 1) {
      if (innerHandlers.validateResultRatingConfig(innerHandlers.getResultRatingConfig)) {
        if (innerSettings.getConfigRatingResult >= 9) {
          innerHandlers.toggleShowHidePopup(false, 'onlyOpacity');

          let typeData = innerSettings.formType;
          let ratingData = innerSettings.getConfigRatingResult;

          innerHelpers.sendEmail(typeData, ratingData);
          innerHelpers.createThankPopup();
          innerHandlers.doNotShowAgainPopup();

          setTimeout(function () {
            $$(popupID).hide();
          }, 1000);

          userExtAnalytics.log('nps_popup_done',{score: innerSettings.getConfigRatingResult});
        } else {
          innerHandlers.toggleShowHidePopup(false);

          setTimeout(function () {
            innerHandlers.secondStepPopup(2);
            innerHandlers.toggleShowHidePopup(true);
          }, 1000);

          innerSettings.getStepPosition = 2;
        }
      }
    }

    if (data === 2) {
      if (innerHandlers.validateResultTextArea(innerHandlers.getResultTextArea)) {
        innerHandlers.toggleShowHidePopup(false, 'onlyOpacity');

        setTimeout(function () {
          let ratingData = innerSettings.getConfigRatingResult;
          let textareaData = innerSettings.getConfigTextareaResult;
          let typeData = innerSettings.formType;

          innerHelpers.sendEmail(typeData, ratingData, textareaData);
          innerHelpers.createThankPopup();
          innerHandlers.doNotShowAgainPopup();

          $$(popupID).hide();

          userExtAnalytics.log('nps_popup_done',{score: innerSettings.getConfigRatingResult});
        }, 1000);
      }
    }
  },
  getTypeText: function () {
    let rateCost = innerSettings.getConfigRatingResult;
    let typeText;

    if (rateCost <= 6) {
      typeText = 1;
    }

    if (rateCost >= 6 && rateCost <= 8) {
      typeText = 2;
    }

    if (rateCost >= 10) {
      typeText = 3;
    }

    return typeText
  },
  secondStepPopup: function (data) {
    innerHandlers.resizeMainPopup(data);
    innerHandlers.defineMainPopup(data);

    $$(bodyID).resize();
  },
  toggleShowHidePopup: function (isShow, dateEnd) {
    var that = $$(popupID);

    if (dateEnd) {
      that.$view.style.transition = 'opacity 540ms';
      if (isShow) {
        that.$view.style.opacity = '1';
        that.$view.style.pointerEvents = 'auto';
      } else {
        that.$view.style.opacity = '0';
        that.$view.style.pointerEvents = 'none';
      }

      return false
    } else {
      that.$view.style.transition = 'transform 540ms, opacity 540ms';
      if (isShow) {
        that.$view.style.opacity = '1';
        that.$view.style.pointerEvents = 'auto';
        that.$view.style.transform = 'translateY(0)';
      } else {
        that.$view.style.opacity = '0';
        that.$view.style.pointerEvents = 'none';
        that.$view.style.transform = 'translateY(25px)';
      }
    }
  },
  defineMainPopup: function (data) {
    $$(bodyID).define('template', innerViews.getTemplateStepSecond(data));
    $$(bodyID).refresh();
  },
  resizeMainPopup: function () {
    let dataWidth = 525;
    let dataHeight = 325;

    $$(popupID).define('width', dataWidth);
    $$(popupID).define('height', dataHeight);
  },
  validateThankPopup: function () {
    let dataStep = innerSettings.getStepPosition;
    let dataRating = innerSettings.getConfigRatingResult;

    if (dataRating >= 9) {
      return true;
    } else {
      return false;
    }
  },
  ratingConfig: function () {
    var rating = document.querySelector('.rating_list');
    var ratingItem = document.querySelectorAll('.rating_list_box');

    rating.onclick = function (e) {
      var target = e.target;

      rating.classList.add("checked");
      if (target.classList.contains('rating_list_box')) {
        removeClass(ratingItem, 'current-active')
        target.classList.add('active', 'current-active');
      }

      var parentRatingItemCheck = document.querySelector(".current-active");
      getCurrentAllNextSiblings(parentRatingItemCheck);
      getCurrentAllPrevSiblings(parentRatingItemCheck);
    };
    rating.onmouseover = function (e) {
      var target = e.target;
      var ratingItemCheck = document.querySelector(".current-active");

      rating.classList.add('default_color');

      if (target.classList.contains('rating_list_box')) {
        removeClass(ratingItem, 'active')
        target.classList.add('active');
        mouseOverActiveClass(ratingItem);
      }

      if (rating.classList.contains('checked')) {
        getCurrentAllPrevSiblings(ratingItemCheck);
      }
    };
    rating.onmouseout = function () {
      let ratingCurrent = rating.querySelector('.current-active');
      let ratingListBoxArr = rating.querySelectorAll('.rating_list_box');

      if (!ratingCurrent && rating.classList.contains('default_color')) {
        rating.classList.remove('default_color');
      }

      ratingListBoxArr.forEach(function (item) {
        if (item.classList.contains('uncheck')) {
          item.classList.remove('active');
        }
      });
      mouseOutActiveClass(ratingItem);
    };

    function removeClass(arr) {
      for (var i = 0, iLen = arr.length; i < iLen; i++) {
        for (var j = 1; j < arguments.length; j++) {
          ratingItem[i].classList.remove(arguments[j]);
        }
      }
    }

    function mouseOverActiveClass(arr) {
      for (var i = 0, iLen = arr.length; i < iLen; i++) {
        if (arr[i].classList.contains('active')) {
          break;
        } else {
          arr[i].classList.add('active');
        }
      }
    }

    function mouseOutActiveClass(arr) {
      if (!document.querySelector('.rating_list').classList.contains('checked')) {
        arr.forEach(function (item) {
          item.classList.remove('active');
        })
      }
    }

    function getCurrentAllNextSiblings(ratingItemCheck) {
      var out = [];
      while (ratingItemCheck.nextElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.nextElementSibling);
      }

      out.forEach(function (item, index, arr) {
        item.classList.remove("active");
        item.classList.add("uncheck");
      });

      return out;
    }

    function getCurrentAllPrevSiblings(ratingItemCheck) {
      var out = [];
      while (ratingItemCheck.previousElementSibling) {
        out.push(ratingItemCheck = ratingItemCheck.previousElementSibling);
      }

      out.forEach(function (item, index, arr) {
        item.classList.add("active");
        item.classList.remove("uncheck");
      });

      return out;
    }
  },
  getResultRatingConfig: function () {
    let currentActiveRate = document.querySelector(".rating_list .current-active");
    let resultRat = currentActiveRate.querySelector('.box_item').getAttribute('date-rate');

    innerSettings.getConfigRatingResult = resultRat;
  },
  validateResultRatingConfig: function (callback) {
    let ratingList = document.querySelector('.rating_list');
    let ratingTitle = document.querySelector('.rating_title');

    !ratingList.classList.contains('checked') ? ratingTitle.classList.add('error') : ratingTitle.classList.remove('error');
    if (ratingList.classList.contains('checked')) {
      callback();
      return true;
    } else {
      return false;
    }
  },
  getResultTextArea: function (value) {
    return value;
  },
  validateResultTextArea: function (callback) {
    let textAreaTitle = document.querySelector('.textarea_title');
    let textAreaElement = document.querySelector('.textarea_content');

    if (textAreaElement.value !== '') {
      callback(textAreaElement.value);
      innerSettings.getConfigTextareaResult = textAreaElement.value;
      return true;
    } else {
      textAreaTitle.classList.add('error');
      textAreaElement.classList.add('error');
      return false;
    }
  },
  checkErrorTextArea: function () {
    let textAreaTitle = document.querySelector('.textarea_title');
    let textAreaElement = document.querySelector('.textarea_content');
    let valueArea = textAreaElement.value.trim();

    if (!valueArea) {
      textAreaTitle.classList.add('error');
      textAreaElement.classList.add('error');
      textAreaElement.value = '';
    } else {
      textAreaTitle.classList.remove('error');
      textAreaElement.classList.remove('error');
    }
  },
  showLaterPopup: function () {
    // ++innerSettings.getFeatureValue;
    let activityStatus = globalStore.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.HELP_NPS_POPUP);
    globalStore.dispatch('user/updateActivityStatus', {
      activityName: constants.USER_ACTIVITIES.HELP_NPS_POPUP,
      status: ++activityStatus,
    })
    // learningCenterModel.updateFeature(LC_ID_HELP_NPS_POPUP, innerSettings.getFeatureValue);
  },
  doNotShowAgainPopup: function () {
    // innerSettings.getFeatureValue = -1;
    globalStore.dispatch('user/updateActivityStatus', {
      activityName: constants.USER_ACTIVITIES.HELP_NPS_POPUP,
      status: -1,
    });
    // learningCenterModel.updateFeature(LC_ID_HELP_NPS_POPUP, innerSettings.getFeatureValue);
  }
};

const popupID = "npsFeedbackView";
const bodyID = popupID + 'Body';
const thankPopupID = "thankPopup";

const initThankPopup = {
  init: {
    run: function (configThankPopup, dataMessage) {
      webix.ui(innerHelpers.createThankPopupView(configThankPopup, dataMessage)).show();
    }
  }
};

const outputObject = {
  init: {
    run: function () {
      const config = {
        popupID: popupID,
        data: '1'
      };

      webix.ui(innerHelpers.createViewPopup(config));
      $$(popupID).show();

      userExtAnalytics.log('nps_popup_show');
    }
  },
  initThankPopup: initThankPopup.init.run
};

app.on('gantt:init:after', function () {
  let getAmountProjects = projectsModel.getAllProjects();
  let isPaid = plansModel.isPaidTeam();

  if (!app.config.mode.isLink && !app.config.mode.isExport) {
    if (isPaid && getAmountProjects.length >= 2 && innerHelpers.getStatusDateRegistrationFromNow() >= 14) {
      innerHelpers.onInitChangeStatusPopup();
    }
  }
});

export default outputObject;