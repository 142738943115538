<template>
  <div
    class="edit_container"
    :class="{add_mode: addMode}"
  >
    <div class="inputs">
      <div
        class="edit_project_msteams"
        :class="{is_error: projectIsEmptyError}"
      >
        <div
          v-if="projectIsEmptyError"
          class="is_error"
        >
          {{ locales("integration_slack_settings_page_table_input_error_cant_be_empty") }}
        </div>
        <integration-select-vue
          :value="selectedInputs.projectId"
          :options="projectOptions"
          :placeholder="locales('integration_slack_settings_page_table_input_plh_project')"
          @focus="onFocusSelect"
          @change="onChangeProjectId"
        />
      </div>
      <div
        class="channels_settings_container"
        :class="{small_border: projectChannelConnection.smallBorder}"
      >
        <div class="channels_settings_inputs">
          <div
            class="edit_team_msteams"
            :class="{is_error: teamIsEmptyError}"
          >
            <div
              v-if="teamIsEmptyError"
              class="is_error"
            >
              {{ locales("integration_slack_settings_page_table_input_error_cant_be_empty") }}
            </div>
            <integration-select-vue
              :key="onChangesFlag"
              :value="selectedInputs.teamId"
              :options="teamOptions"
              :placeholder="locales('integration_msteams_settings_page_table_input_plh_team')"
              @focus="onFocusSelect"
              @change="onChangeTeamId"
            />
          </div>
          <div
            class="edit_channel_msteams"
            :class="{is_error: channelIsEmptyError}"
          >
            <div
              v-if="channelIsEmptyError"
              class="is_error"
            >
              {{ locales("integration_slack_settings_page_table_input_error_cant_be_empty") }}
            </div>
            <integration-select-vue
              :key="onChangesFlag"
              :value="selectedInputs.channelId"
              :options="channelOptions"
              :placeholder="locales('integration_slack_settings_page_table_input_plh_channel')"
              :loading="isChannelsListLoading"
              @focus="onFocusSelect"
              @change="onChangeChannelId"
            />
          </div>
          <div class="edit_settings_msteams">
            <integration-multiselect-vue
              :key="onChangesFlag"
              :value="selectedInputs.settings"
              :options="settingsOptions"
              :placeholder="locales('integration_slack_settings_page_table_input_plh_settings')"
              @focus="onFocusSelect"
              @change="onChangeSettings"
            />
          </div>
        </div>
        <div class="buttons">
          <div
            v-if="noAvailableChannels"
            :class="['tooltip-gantt', $style.tooltip]"
            :data-key="locales('integration_msteams_settings_page_add_no_possible')"
            :data-position="'top'"
            :data-x="-6"
          >
            <webix-ui
              :class="$style.tip"
              :config="icons.tip"
            />
          </div>
          <div
            v-else
            class="buttons_ok"
            @click="onOk(projectChannelConnection.id)"
          >
            <webix-ui :config="icons.okIcon" />
          </div>
          <div
            class="buttons_cancel"
            @click="onCancel()"
          >
            <webix-ui :config="icons.cancelIcon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import okIcon from '../../../../svg/integration/settings/button_ok.svg';
import cancelIcon from '../../../../svg/integration/settings/button_cancel.svg';
import tip from '../../../../svg/integration/settings/tip.svg';
// // Helpers
import vueSvgIcon from '../../../common/svgIconForVue';
// Components
import selectVue from '../../selectVue.vue';
import multiselectVue from '../../multiselectVue.vue';

export default {
  components: {
    'integration-select-vue': selectVue,
    'integration-multiselect-vue': multiselectVue,
  },
  props: [
    'projectChannelConnection',
    'projectOptions',
    'teamOptions',
    'channelOptions',
    'settingsOptions',

    'selectedInputs',

    'onChangeProjectId',
    'onChangeChannelId',
    'onChangeTeamId',
    'onChangeSettings',

    'onFocusSelect',
    'onChangesFlag',
    'onOk',
    'onCancel',

    'channelIsEmptyError',
    'teamIsEmptyError',
    'projectIsEmptyError',
    'addMode',

    'isChannelsListLoading',
  ],
  data() {
    return {
      locales: __,
      icons: {
        okIcon: vueSvgIcon(okIcon, 36, 36),
        cancelIcon: vueSvgIcon(cancelIcon, 36, 36),
        tip: vueSvgIcon(tip, 24, 24),
      },
    };
  },
  computed: {
    noAvailableChannels() {
      return this.selectedInputs.teamId && !this.isChannelsListLoading && !this.channelOptions.length;
    },
  },
};
</script>
<style module lang="less">
  @import "./integrationMSTeamsCommonStyles.less";

  .tooltip {
    display: flex;
    align-items: center;
    width: 36px;
  }
</style>
