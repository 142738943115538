var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.$style.section },
    [
      _c(
        "div",
        {
          ref: "sectionWrapper",
          class: [
            _vm.$style.section__wrapper,
            _vm.firstInitAnimation
              ? _vm.$style.section__wrapper_firstInitAnimation
              : "",
          ].concat(_vm.animationClasses),
        },
        [
          _c(
            "wizard-left-section",
            {
              attrs: {
                title: _vm.title,
                description: _vm.description,
                "animate-leave": _vm.leftSectionAnimationLeave,
                "animate-enter": _vm.leftSectionAnimationEnter,
              },
            },
            [
              [
                _c(
                  "label",
                  {
                    ref: "dropZone",
                    class: [_vm.$style.dropzone].concat(_vm.animationClasses),
                    attrs: {
                      id: "gp_autotest_onboarding_wizard_1_step_fileImport_dropZone",
                      for: "file",
                    },
                  },
                  [
                    _c("input", {
                      ref: "fileUpload",
                      class: _vm.$style.fileInput,
                      attrs: {
                        id: "gp_autotest_onboarding_wizard_1_step_file_input",
                        name: "file",
                        type: "file",
                        accept: ".mpp",
                      },
                      on: { change: _vm.handleChangeFileInput },
                    }),
                    _vm._v(" "),
                    !_vm.isFileLoading
                      ? [
                          _c("vgp-button", {
                            class: _vm.$style.importButton,
                            attrs: {
                              id: "gp_autotest_onboarding_wizard_1_step_fileImport_button",
                              type: "secondary",
                              label: _vm.locales(
                                "onboarding_import_mpp_btn_label"
                              ),
                              icon: { name: "import", type: "bold" },
                              "icon-side": "left",
                            },
                            on: {
                              onClick: function ($event) {
                                return _vm.$refs.fileUpload.click()
                              },
                            },
                          }),
                        ]
                      : [
                          _c(
                            "div",
                            { class: _vm.$style.fileProgressWrap },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: "4",
                                  size: "36",
                                  indeterminate: "",
                                  color: "#337870",
                                },
                              }),
                              _vm._v(" "),
                              _vm.showImportLabelNotification
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.locales(
                                            "import_label_notification"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.section__right }, [
            _c(
              "div",
              {
                class: [_vm.$style.right__wrapper].concat(_vm.animationClasses),
              },
              [
                _c(
                  "div",
                  { class: [_vm.$style.sidebar].concat(_vm.animationClasses) },
                  [
                    _c(
                      "div",
                      {
                        ref: "sidebarHead",
                        class: [_vm.$style.sidebar__head].concat(
                          _vm.animationClasses
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "sidebarCompanyLogo",
                            class: [_vm.$style["sidebar__company-logo"]].concat(
                              _vm.animationClasses
                            ),
                          },
                          [
                            _vm.teamLabel.favicon
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.teamLabel.favicon,
                                    alt: "Company logo",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "sidebarCompanyName",
                            class: [
                              _vm.$style["sidebar__company-name"],
                              !_vm.teamLabel.favicon ? "order-first ml-0" : "",
                            ].concat(_vm.animationClasses),
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.teamLabel.name || "") +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "projectNameForm",
                        class: [_vm.$style.sidebar__form].concat(
                          _vm.animationClasses
                        ),
                      },
                      [
                        _c(
                          "div",
                          { class: _vm.$style.sidebar__input },
                          [
                            _c(
                              "div",
                              { class: _vm.$style.sidebar__input_label },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.locales(
                                        "onboarding_project_name_label"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("vgp-input", {
                              ref: "projectNameInput",
                              attrs: {
                                id: "gp_autotest_onboarding_wizard_1_step_projectName_input",
                                maxlength: "250",
                                "is-disabled": _vm.isFileLoading,
                                placeholder: _vm.locales(
                                  "onboarding_create_project_placeholder"
                                ),
                                type: "text",
                                small: "",
                              },
                              model: {
                                value: _vm.projectName,
                                callback: function ($$v) {
                                  _vm.projectName =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "projectName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("vgp-button", {
                          class: _vm.$style.sidebar__button,
                          attrs: {
                            id: "gp_autotest_onboarding_wizard_1_step_createProject_button",
                            type: "green",
                            disabled: _vm.isFileLoading,
                            label: _vm.locales(
                              "onboarding_create_project_btn_label"
                            ),
                            small: "",
                          },
                          on: { onClick: _vm.nextStep },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        class: [_vm.$style.sidebar__icons].concat(
                          _vm.animationClasses
                        ),
                      },
                      _vm._l(_vm.sidebarIcons, function (iconConfig, index) {
                        return _c(
                          "li",
                          { key: index },
                          [
                            _c(
                              "vgpSvgImage",
                              _vm._b({}, "vgpSvgImage", iconConfig, false)
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: [_vm.$style.main].concat(_vm.animationClasses) },
                  [
                    _c("wizard-right-section-header", {
                      attrs: {
                        title:
                          _vm.projectName ||
                          _vm.locales("onboarding_project_name_default_name"),
                        "active-nav-item": _vm.isFourthWizardStep
                          ? "people"
                          : "diagram",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "tasksWrapper",
                        class: [_vm.$style.tasks__wrapper].concat(
                          _vm.animationClasses
                        ),
                      },
                      [
                        _vm._l(_vm.visibleTasks, function (task, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: [_vm.$style.task__block].concat(
                                _vm.animationClasses
                              ),
                            },
                            [
                              _c("span", { class: _vm.$style.task__index }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(index + 1) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("vgp-input", {
                                ref: "taskInputs",
                                refInFor: true,
                                class: [
                                  _vm.$style.task__item,
                                  _vm.isInputDisabled(index)
                                    ? _vm.$style.customDisableInput
                                    : "",
                                ],
                                attrs: {
                                  id:
                                    "gp_autotest_onboarding_wizard_2_step_task_" +
                                    (index + 1) +
                                    "_input",
                                  "is-disabled": _vm.isInputDisabled(index),
                                  placeholder: task.defaultName,
                                  small: "",
                                  type: "text",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleOnTaskEnterPressed(index)
                                  },
                                },
                                model: {
                                  value: task.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      task,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "task.name",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { class: _vm.$style.task__arrow },
                                [
                                  _c("vgpSvgImage", {
                                    attrs: {
                                      name: "onboarding-task-link",
                                      color: "#D9E2E4",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm._l(2, function (i) {
                          return _c(
                            "div",
                            {
                              key: i + _vm.visibleTasks.length,
                              class: [_vm.$style.task__block].concat(
                                _vm.animationClasses
                              ),
                            },
                            [
                              _c("span", { class: _vm.$style.task__index }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(i + _vm.visibleTasks.length + 1) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { class: [_vm.$style.task__item] }),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ValidationObserver",
                      {
                        ref: "emailsForm",
                        class: [_vm.$style.teammates__wrapper].concat(
                          _vm.animationClasses
                        ),
                        attrs: { tag: "div" },
                      },
                      _vm._l(_vm.teammates, function (teammate, index) {
                        return _c(
                          "div",
                          { key: index, class: _vm.$style.teammate__wrap },
                          [
                            _c("vgpSvgImage", {
                              attrs: {
                                name: "onboarding-team",
                                color: "#CED9DC",
                              },
                            }),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              ref: "emailValidationProvider",
                              refInFor: true,
                              attrs: {
                                slim: "",
                                rules: _vm.emailRules,
                                "custom-messages": {
                                  email: _vm.locales(
                                    "onboarding_email_validation_error"
                                  ),
                                  is_not: _vm.locales(
                                    "onboarding_email_validation_error"
                                  ),
                                },
                                vid: "email" + index,
                                mode: function () {
                                  return { on: ["onBlur"] }
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vgp-input", {
                                          ref: "teammateEmails",
                                          refInFor: true,
                                          class: _vm.$style.teammate__input,
                                          attrs: {
                                            id:
                                              "gp_autotest_onboarding_wizard_3_step_teammate_" +
                                              (index + 1) +
                                              "_input",
                                            "is-error": !!errors[0],
                                            "is-disabled":
                                              !_vm.isFourthWizardStep,
                                            type: "text",
                                            small: "",
                                            placeholder: _vm.locales(
                                              "onboarding_email_placeholder"
                                            ),
                                          },
                                          on: {
                                            onFocus: function ($event) {
                                              return _vm.handleOnFocusEmail(
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: teammate.email,
                                            callback: function ($$v) {
                                              _vm.$set(teammate, "email", $$v)
                                            },
                                            expression: "teammate.email",
                                          },
                                        }),
                                        _vm._v(" "),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.$style
                                                    .teammate__input_error,
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(errors[0]) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", {
                      class: _vm.$style["main__vertical-right-blur"],
                    }),
                    _vm._v(" "),
                    _c("div", {
                      class: [
                        _vm.$style["main__horizontal-header-blur"],
                      ].concat(_vm.animationClasses),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isFirstWizardStep
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.$style.submit__button,
                          _vm.leftSectionAnimationEnter
                            ? _vm.$style.animateEnter
                            : "",
                          _vm.leftSectionAnimationLeave
                            ? _vm.$style.animateLeave
                            : "",
                        ],
                      },
                      [
                        _c("vgp-button", {
                          attrs: {
                            id:
                              "gp_autotest_onboarding_" +
                              _vm.activeStepId +
                              "_submit_button",
                            type: _vm.submitButtonType,
                            label: _vm.submitButtonText,
                            "is-loading": _vm.isValidatingEmails,
                            small: "",
                          },
                          on: { onClick: _vm.nextStep },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("project-preparation-loader", {
        class: [_vm.$style.section__loader].concat(_vm.animationClasses),
        attrs: {
          id: "gp_autotest_onboarding_wizard_project_preparation_loader",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }