var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.vgp_responsive_textarea },
    [
      _c(
        "div",
        {
          class: [
            _vm.$.wrap,
            _vm.isFocus && !_vm.readonly ? _vm.$.focus : "",
            _vm.isFocus && !_vm.readonly && _vm.isError ? _vm.$.error : "",
            _vm.readonly ? _vm.$.readonly : "",
          ],
        },
        [
          _c(
            "div",
            {
              ref: "scroll",
              class: _vm.$.scroll,
              style:
                _vm.formatHeight && !_vm.isExpanded
                  ? "height: " + _vm.formatHeight + "px;"
                  : "",
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.areaValue,
                    expression: "areaValue",
                  },
                ],
                ref: "area",
                class: _vm.$.area,
                attrs: {
                  readonly: _vm.readonly,
                  disabled: _vm.readonly,
                  spellcheck: "false",
                  tabindex: "-1",
                },
                domProps: { value: _vm.areaValue },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.areaValue = $event.target.value
                    },
                    _vm.onInput,
                  ],
                  change: _vm.onChange,
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isShowButton
        ? _c("vgp-icon-button", {
            style: { "margin-top": "4px" },
            attrs: {
              icon: {
                name: _vm.isExpanded ? "arrow-top" : "arrow-down",
                type: "bold",
                size: 20,
              },
              label: _vm.isExpanded
                ? _vm.locales("common_collapse")
                : _vm.locales("common_expand"),
              "icon-side": "right",
              bold: "",
              colors: {
                color: "#808080",
                hoverBg: "#F8F8F8",
              },
            },
            on: { onClick: _vm.onToggleExpand },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }