/* eslint-disable */
import app from '../../../app';

import customDaysModel from '../../../models/customDays';
import customDayTemplatesModel from '../../../models/customDayTemplates';
import globalStore from '../../../store/main';

import zoomHoursPopup from './zoomHours';
import zoomDaysPopup from './zoomDays';

import constants from '../../../helpers/constants';
import helperDate from '../../../helpers/dateFormats';
import timeIntervalHelper from '../../../helpers/timeIntervals';
import plansModel from '../../../models/plans';

import templates from '../../../templates/labels';

import icon_calendar from '../../../svg/custom_days/calendar.svg';
import icon_file from '../../../svg/webix_material/template.svg';
import icon_left_arrow from '../../../svg/custom_days/chevron_left.svg';
import icon_right_arrow from '../../../svg/custom_days/chevron_right.svg';
import icon_trash from '../../../svg/webix_material/trash.svg';
import icon_no_exceptions from '../../../svg/icons_calendar/ic_calendar.svg';
import _ from '../../../libs/lodash';
import moment from '../../../libs/moment';
import dateFormatsHelper from '$$helpers/dateFormats';

const __ = window.__;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

const constantsCalendars = constants.CALENDARS;
const hoursArray = helperDate.getHoursArrayByUserTimeFormat(false, true);

var innerObject = {
  views: {
    templateManagePopup: {
      id: 'templateManagePopup',
    },
    customDaysPopup: {
      id: 'customDaysWindow',
    },
    customDaysPopupHeader: {
      id: 'customDaysPopupHeader',
    },
    customDaysBottomToolbarProject: {
      id: 'customDaysBottomToolbarProject',
    },
    customDaysBottomToolbarTemplates: {
      id: 'customDaysBottomToolbarTemplates',
    },
    customDaysMainScrollView: {
      id: 'customDaysMainScrollView',
      view: 'scrollview',
      css: 'customCalendarScrollView',
      scroll: false,
      width: 889,
      height: 240 * 3,
      borderless: true,
      body: {},
      on: {
        onAfterScroll() {
          webix.callEvent('onClick', []);
        },
      },
    },
    templateEditorLayoutBodyToggle: {
      id: 'templateEditorLayoutBodyToggle',
    },
    yearSelectTemplate: {
      view: 'template',
      id: 'yearSelectTemplate',
      align: 'center',
      css: 'customDays_year',
      width: 108,
      currentYear: null,
      template() {
        return `<div class='customDays_arrowLeft'>${icon_left_arrow}</div>`
          + `<div class='customDays_yearText'>${this.currentYear}</div>`
          + `<div class='customDays_arrowRight'>${icon_right_arrow}</div>`;
      },
      height: 36,
      onClick: {
        customDays_arrowLeft() {
          innerObject.handlers.changeYear('left');
        },
        customDays_arrowRight() {
          innerObject.handlers.changeYear('right');
        },
      },
    },
    headerMenu: {
      view: 'menu',
      id: 'settingsCustomDaysMenu',
      layout: 'x',
      css: 'custom-days-header-menu',
      select: true,
      type: {
        width: 441,
        height: 60,
      },
      data: [
        {
          id: 'customCalendar',
          value: `${"<div class='custom-days-header-menu-item'>"
            + "<div class='custom-days-header-menu-item-icon'>"}${icon_calendar}</div>`
            + `<div class='custom-days-header-menu-item-text'>${__('custom_days_header_calendar')}</div>`
            + '</div>',
        },
        {
          id: 'customTemplates',
          value: `${"<div class='custom-days-header-menu-item'>"
            + "<div class='custom-days-header-menu-item-icon'>"}${icon_calendar}</div>`
            + `<div class='custom-days-header-menu-item-text'>${__('custom_days_header_templates')}</div>`
            + '</div>',
        },
      ],
      on: {
        onBeforeSelect(id) {
          id === 'customCalendar' && innerObject.init.initCustomDayProject();
          id === 'customTemplates' && innerObject.init.initCustomDayTemplates();
        },
      },
    },
    customDayInfoWorkTimeLabel: {
      view: 'label',
      id: 'customDayInfoWorkTimeLabel',
      width: 180,
      label: __('custom_days_header_title'),
    },
    customDayUserTemplateHeader: {
      id: 'customDayUserTemplateHeader',
    },
    customDayUserTemplateTitle: {
      view: 'label',
      css: 'customDayUserTemplatesTitle',
      id: 'customDayUserTemplateTitle',
      label: __('custom_days_user_template_title'),
      width: 150,
    },
    customDayUserTemplateSelect: {
      view: 'richselectWithoutPoint',
      id: 'customDayUserTemplateSelect',
      labelWidth: 100,
      label: __('custom_days_template_select_label'),
      width: 300,
      keyPressTimeout: 500,
      options: [],
      on: {
        onChange(newValue, oldValue) {
          innerObject.handlers.changeUserTemplate(newValue);
        },
      },
    },
    customDaySaveAsButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDaySaveAsButton',
      width: 180,
      value: __('common_save_as_template'),
      click() {
        innerObject.handlers.buttonSaveAsClick();
      },
    },
    customDayClearButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDayClearButton',
      width: 120,
      value: __('common_clear'),
      click() {
        innerObject.handlers.buttonClearClick();
      },
    },
    customDayLoadTemplateButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDayLoadTemplateButton',
      width: 180,
      value: __('common_load'),
      click() {
        innerObject.handlers.buttonLoadTemplatesClick();
      },
    },
    customDayDeleteTemplateButton: {
      view: 'button',
      css: 'button_gray',
      id: 'customDayDeleteTemplateButton',
      width: 100,
      value: __('common_delete'),
      template: `${"<div class='custom-days-delete'>"
        + "<div class='custom-days-delete-icon'>"}${icon_trash}</div>`
        + `<div class='custom-days-delete-text'>${__('common_delete')}</div>`
        + '</div>',
      click() {
        innerObject.handlers.buttonDeleteUserTemplateClick();
      },
    },
    customDayRenameTemplateButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDayRenameTemplateButton',
      width: 160,
      value: __('common_rename'),
      click() {
        innerObject.handlers.buttonRenameUserTemplateClick();
      },
    },
    customDayCopyTemplateButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDayCopyTemplateButton',
      width: 120,
      value: __('common_copy'),
      click() {
        innerObject.handlers.buttonCopyUserTemplateClick();
      },
    },
    customDayNewTemplateButton: {
      view: 'button',
      css: 'button_gray',
      align: 'right',
      id: 'customDayNewTemplateButton',
      width: 100,
      value: __('common_new'),
      click() {
        innerObject.handlers.buttonNewUserTemplateClick();
      },
    },
    customDayAdvancedInfo: {
      view: 'label',
      id: 'customDayAdvancedInfo',
      css: 'customDaysLabelDescription',
      typeCustomDay: __('custom_days_label_repeat_event'),
      advancedData: '13 March 2016 - 13 March 2016',
      template: '<span>#typeCustomDay#: #advancedData#</span>',
    },
    maketCalendarMonth: {
      view: 'calendar',
      monthSelect: false,
      navigation: false,
      width: 220,
      height: 240,
      monthNumber: 0,
      cacheData: {},
      weekHeader: true,
      borderless: true,
      holidays: [],
      events(date) {
        return innerObject.handlers.markEventDay(date, this.monthNumber);
      },
      blockDates(date) {
        return date.getMonth() !== this.config.monthNumber;
      },
      on: {
        onDateSelect(date) {
          innerObject.settings.editMode = false;
          innerObject.handlers.selectCalendarDay(date, this.config.monthNumber);
        },
        onBeforeRender(date) {
          this.config.holidays = zoomDaysPopup.handlers.getCurrentDays();
        },
      },
    },
    startPeriodTimePicker: {
      id: 'startPeriodTimePicker',
      view: 'datepickeredit',
      css: 'customDaySelect',
      width: 192,
      inputWidth: 196,
      value: new Date(),
      on: {
        onChange(newValue, oldValue) {
          const isEmpty = (newValue === '');
          const isValid = newValue instanceof Date && !isNaN(newValue);
          const startDatePicker = this;
          const endDatePicker = $$(innerObject.views.endPeriodTimePicker.id);

          if (!isValid || isEmpty) {
            this.blockEvent();
            this.setValue(oldValue);
            this.unblockEvent();

            webix.message({ type: 'warning', text: __('gantt_date_invalid') });
            this.config.isInvalid = true;

            return;
          }

          const value = helperDate.checkIfDateInLimit(newValue, true);

          if (value.getTime() !== newValue.getTime()) {
            this.blockEvent('onChange');
            this.setValue(value);
            this.unblockEvent('onChange');
            newValue = value;
          }

          const startTime = startDatePicker.getValue();
          const endTime = endDatePicker.getValue();

          if (startTime.valueOf() > endTime.valueOf() || $$(innerObject.views.repeatCustomDayToggle.id).getValue()) {
            endDatePicker.setValue(startTime);
          }

          innerObject.handlers.helpers.checkAndSetRepeatForAccessByPeriod(newValue, oldValue);
        },
        onEnter() {
          const popup = this.getPopup();

          this.callEvent('onBlur');

          if (popup.isVisible()) {
            popup.hide();
          }

          webix.UIManager.setFocus($$(innerObject.views.customPopupDayOption.main.id));
        },
      },
    },
    endPeriodTimePicker: {
      id: 'endPeriodTimePicker',
      view: 'datepickeredit',
      css: 'customDaySelect',
      width: 192,
      inputWidth: 196,
      value: new Date(),
      on: {
        onChange(newValue, oldValue) {
          const isEmpty = (newValue === '');
          const isValid = newValue instanceof Date && !isNaN(newValue);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startDate = $$(innerObject.views.startPeriodTimePicker.id).getValue();


          if (!isValid || isEmpty) {
            this.blockEvent();
            this.setValue(oldValue);
            this.unblockEvent();

            webix.message({ type: 'warning', text: __('gantt_date_invalid') });
            this.config.isInvalid = true;

            return;
          }

          const value = helperDate.checkIfDateInLimit(newValue, true);

          if (value.getTime() !== newValue.getTime()) {
            this.blockEvent('onChange');
            this.setValue(value);
            this.unblockEvent('onChange');
            newValue = value;
          }
          if(moment(newValue).startOf('day').isSame(moment(today).startOf('day'))) {
            if(moment(today).startOf('day') < moment(startDate).startOf('day')) {
              $$(innerObject.views.startPeriodTimePicker.id).setValue(newValue);
            }
          }
          innerObject.handlers.helpers.checkAndSetRepeatForAccessByPeriod();
        },
        onEnter() {
          const popup = this.getPopup();

          this.callEvent('onBlur');

          if (popup.isVisible()) {
            popup.hide();
          }

          webix.UIManager.setFocus($$(innerObject.views.customPopupDayOption.main.id));
        },
        "onBeforeRender": function () {
          let calendar = this.getPopup()
            .getBody();
          const startDate = $$(innerObject.views.startPeriodTimePicker.id).getValue();
          calendar.define("blockDates", function (date) {
            if (new Date(date).valueOf() < new Date(startDate).valueOf()) {
              return true;
            }

          });
        },
      },
    },
    advancedOptionEventLabel: {
      view: 'label',
      id: 'advancedOptionEventLabel',
      css: 'customDaysLabelDescription',
      align: 'center',
      height: 25,
      label: '',
    },
    customDayTitle: {
      height: 72,
      inputHeight: 36,
      inputWidth: 470,
      id: 'customDayTitle',
      view: 'text',
      placeholder: __('custom_day_resources_title_placeholder'),
      label: __('custom_day_resources_title_label'),
      labelPosition: 'top',
    },
    typeCustomDaysDataView: { //
      width: 470,
      paddingY: 16,
      view: 'dataview',
      id: 'typeCustomDaysDataView',
      css: 'custom-days-option-dataview',
      multiselect: false,
      borderless: true,
      xCount: 3,
      select: true,
      scroll: false,
      height: 36,
      value: '',
      data: [
        // { id: "normal", value: __("custom_days_type_normal"), $css: "custom-days-dataview-item-normal" },
        {
          id: 'weekend',
          $width: '50%',
          value: __('custom_days_type_weekend'),
          $css: 'custom-days-dataview-item-weekend',
        },
        {
          id: 'custom',
          $width: '50%',
          value: __('custom_days_type_custom_day'),
          $css: 'custom-days-dataview-item-custom',
        },
      ],
      on: {
        onSelectChange(newValue, oldValue) {
          innerObject.handlers.changeDayType(newValue[0], oldValue);
        },
      },
      type: {
        width: 143,
        height: 36,
      },
    },
    repeatCustomDayLayout: {
      id: 'repeatCustomDayLayout',
    },
    repeatCustomDaySelectLayout: {
      id: 'repeatCustomDaySelectLayout',
    },
    repeatCustomDayToggleLayout: {
      id: 'repeatCustomDayToggleLayout',
    },
    repeatCustomDayLabel: {
      id: 'repeatCustomDayLabel',
      view: 'label',
      label: __('custom_days_repeat_title'),
      css: 'label-with-spacer',
      template: templates.getTemplateForLabelWithSpacer,
    },
    repeatCustomDayToggle: {
      view: 'toggle',
      id: 'repeatCustomDayToggle',
      type: 'icon',
      width: 54,
      borderless: true,
      toolTip: {
        langKey: 'project_settings_custom_calendar_tooltip_toggle_disabled',
      },
      on: {
        onChange(newValue, oldValue) {
          innerObject.handlers.repeatChange(newValue, oldValue);
        },
        onAfterRender() {
          this.getNode().setAttribute('data-key', this.config.toolTip.langKey);
        },
      },
    },
    repeatForeverCustomDayCheckBox: {
      height: 36,
      view: 'checkbox',
      id: 'repeatForeverCustomDayCheckBox',
      value: 0,
      labelWidth: 0,
      labelRight: __('custom_days_repeat_forever_label'),
      width: 174,
      on: {
        onChange(newValue, oldValue) {
          innerObject.handlers.repeatForeverChange(newValue, oldValue);
        },
      },
    },
    repeatCustomDayTypeSelect: {
      height: 36,
      maxHeight: 36,
      css: 'repeatCustomDayTypeSelect',
      view: 'richselectWithoutPoint',
      id: 'repeatCustomDayTypeSelect',
      label: __('custom_days_repeat_type_title'),
      labelWidth: 232,
      value: 'week',
      icon: false,
      options: [
        { id: 'week', value: __('custom_days_repeat_every_week') },
        { id: 'month', value: __('custom_days_repeat_every_mont') },
        { id: 'year', value: __('custom_days_repeat_every_year') },
      ],
    },
    repeatCustomDayEndTimePicker: {
      id: 'repeatCustomDayEndTimePicker',
      view: 'datepicker',
      icon: 'fa-calendar',
      hidden: true,
      type: 'date',
      format: webix.i18n.longDateFormatStr,
      label: __('custom_days_repeat_to_label'),
      labelWidth: 110,
      width: 284,
      css: 'custom-day-popup-repeat-end',
      on: {
        onChange(newValue, oldValue) {
        },
      },
    },
    workingHoursLabel: {
      view: 'label',
      label: __('custom_day_working_hours'),
      number: '0h',
      id: 'workingHoursLabelPopup',
      css: 'label-with-spacer',
      template: templates.getTemplateForLabelWithSpacerAndNumber,
    },
    workingHoursLayout: {
      id: 'workingHoursLayout',
    },
    workingHoursIntervalLabel: {
      view: 'template',
      id: 'workingHoursIntervalLabel',
      width: 466,
      height: 38,
      css: 'hours-template',
      borderless: false,
      label: '',
      intervals: [],
      template(obj) {
        let innerHtml = '';

        this.intervals.forEach(intervalString => {
          innerHtml += `<div class='dataview-hours-inline-item'>${intervalString}</div>`;
        });

        return `<div class='dataview-hours-inline'>${innerHtml}</div>`;
      },
      onClick: {
        'hours-template': function () {
          innerObject.handlers.hoursIntervalLabelClick(this.$view);
        },
      },
    },
    countWorkingHoursLabel: {
      view: 'label',
      label: __('custom_day_working_hours'),
      css: 'label-with-spacer',
      height: 40,
      template: templates.getTemplateForLabelWithSpacer,
    },
    countWorkingHoursLayout: {
      id: 'countWorkingHoursLayout',
    },
    countWorkingHoursIntervalLabel: {
      view: 'template',
      id: 'countWorkingHoursIntervalLabel',
      height: 36,
      css: 'customDayCountTitle',
      borderless: true,
      value: 0.5,
      template(obj) {
          let hourItem;
        if (this.value === 0 || this.value === '0') {
          hourItem = '0 h';
        } else {

          hourItem = hoursArray.find(item => item.id === +this.value).value;
        }
          return `<div class='dataview-hours-inline'>${hourItem}</div>`;
      },
      onClick: {
        'customDayCountTitle': function () {
          $$(innerViews.resourceCustomHoursPopup.id).config.intervalView = $$(innerObject.views.countWorkingHoursIntervalLabel.id);
          innerHandlers.showHoursPopup($$(innerObject.views.countWorkingHoursLayout.id).getNode());
        },
      },
    },
    customDaysHoursPopup: {
      id: 'customDaysHoursPopup',
    },
    customDaysHoursDataView: {
      view: 'dataview',
      id: 'customDaysHoursDataView',
      select: true,
      multiselect: 'touch',
      xCount: 6,
      yCount: 8,
      scroll: false,
      // layout:"x",
      align: 'center',
      css: 'dataview-hours customDaysHoursDataView displayOptionsHoursDataView',
      type: {
        width: 68,
        height: 35,
      },
      data: [],
      template(obj) {
        return `<div class='dataview-hours-block'><div class='dataview-hours-item'>${helperDate.getUserTimeFormat() ? obj.id : obj.value}</div></div>`;
      },
      on: {
        onSelectChange(e) {
          innerObject.handlers.changeHours(this.config.intervalView);
        },
      },
    },
    customPopupWithOptions: {
      main: {
        id: 'customDaysPopupWithOptions',
      },
      userTemplateListLayout: {
        id: 'userTemplateListLayout',
      },
      userTemplatesList: {
        view: 'list',
        id: 'customDaysuserTemplatesList',
        css: 'custom-days-templates-list',
        borderless: false,
        height: 168,
        select: true,
        multiselect: false,
        data: [],
        template(obj) {
          return `${"<div class='custom-days-templates-list-item'>"
            + "<div class='custom-days-templates-list-item-icon'>"}${icon_file}</div>`
            + `<div class='custom-days-templates-list-item-text' >${obj.name}</div>`
            + '<div class=\'custom-days-templates-list-item-check\' ></div>'
            + '</div>';
        },
        type: {
          height: 48,
        },
        on: {
          onItemClick(id) {
            innerObject.handlers.customPopupWithOptions.userTemplateListSelect();
          },
        },
        onClick: {
          'js-deleteFile': function (event, id, node) {
            // modelsAttachment.deleteFile(id, ganttId, taskId);
          },
        },
      },
      optionsRadioButton: {
        view: 'radio',
        id: 'customPopupWithOptionsRadioButton',
        css: 'customDaysWithOptions',
        optionHeight: 36,
        minHeight: 120,
        maxHeight: 200,
        vertical: true,
        value: '',
        options: [],
        on: {
          onChange(newValue, oldValue) {
            innerObject.handlers.customPopupWithOptions.changeSolutionOption(newValue, oldValue);
          },
        },
      },
      newTemplateNameByProjectText: {
        view: 'text',
        id: 'customDayNewTemplateNameByProjectText',
        labelWidth: 150,
        label: __('custom_days_template_select_title'),
      },
      newTemplateNameByProjectLayout: {
        id: 'newTemplateNameByProjectLayout',
      },
      buttonOk: {
        view: 'button',
        css: 'webix_button_default button_raised',
        align: 'right',
        id: 'customPopupWithOptionsButtonOk',
        width: 100,
        value: __('common_ok'),
        click() {
          $$(innerObject.views.customPopupDayOption.main.id).hide();
          innerObject.handlers.customPopupWithOptions.buttonOkClick();
          innerObject.handlers.resetSelectedAndHideTemplatePopup();
          $$(innerObject.views.templateManagePopup.id).hide();
        },
      },
      buttonClose: {
        view: 'button',
        css: 'button_default',
        align: 'right',
        id: 'customPopupWithOptionsButtonClose',
        width: 100,
        value: __('common_cancel'),
        click() {
          innerObject.handlers.customPopupWithOptions.buttonCloseClick();
        },
      },
    },
    customPopupNewTemplate: {
      main: {
        id: 'customPopupNewTemplate',
      },
      templateNameText: {
        view: 'text',
        id: 'customDayNewTemplateNameText',
        labelWidth: 150,
        label: __('custom_days_template_select_title'),
      },
      buttonOk: {
        view: 'button',
        css: 'button_blue',
        align: 'right',
        id: 'customPopupNewTemplateButtonOk',
        width: 100,
        mode: '',
        value: __('common_ok'),
        click() {
          innerObject.handlers.customPopupNewTemplate.buttonOkClick();
          innerObject.handlers.resetSelectedAndHideTemplatePopup();
          $$(innerObject.views.templateManagePopup.id).hide();
        },
      },
      buttonClose: {
        view: 'button',
        css: 'button_default',
        align: 'right',
        id: 'customPopupNewTemplateButtonClose',
        width: 100,
        value: __('common_cancel'),
        click() {
          innerObject.handlers.customPopupNewTemplate.buttonCloseClick();
        },
      },
    },
    customPopupDayOption: {
      main: {
        id: 'customPopupDayOption',
      },
    },
    clearButton: {
      view: 'button',
      css: 'webix_button_default button_raised tooltip-gantt button_default',
      align: 'center',
      width: 120,
      id: 'clearButtonCustomDaysPopup',
      value: __('common_clear'),
      click() {
        innerObject.handlers.buttonClearClick();
      },
      toolTip: {
        langKey: 'custom_calendar_tooltip_clear_button',
      },
      on: {
        onAfterRender() {
          this.getNode().setAttribute('data-key', this.config.toolTip.langKey);
        },
      },
    },
    buttonOk: {
      view: 'button',
      css: 'webix_button_default button_raised',
      align: 'center',
      id: 'buttonOkCustomDaysPopup',
      width: 120,
      value: __('common_apply'),
      click() {
        let isInvalidDate = false;

        if (innerObject.settings.mainId === 'resCustomDaysMainScrollView') {
          isInvalidDate = innerObject.handlers.buttonOkResourceClick();
        } else {
          isInvalidDate = innerObject.handlers.buttonOkClick();
        }
        if (!isInvalidDate) {
          $$(innerObject.views.customPopupDayOption.main.id).hide();
        }
      },
    },
    buttonEditTemplate: {
      view: 'button',
      id: 'buttonEditTemplate',
      type: 'success',
      css: 'webix_button_default button_raised button_success',
      align: 'right',
      width: 160,
      value: __('custom_days_edit_template_button'),
      click() {
        innerObject.init.initCustomDayTemplates();
      },
    },
    buttonClose: {
      view: 'button',
      css: 'button_danger',
      align: 'right',
      id: 'buttonCloseCustomDaysPopup',
      width: 80,
      value: __('common_cancel'),
      click() {
        // innerObject.handlers.buttonUserTemplateClick();
      },
    },
  },
  init: {
    run(ganttId, ignoreScroll) {
      let webixUI = {};
      const datesPeriod = globalStore.getters['tasksModel/getMaxAndMinDateForProject'](ganttId, 0, false);
      const currentDate = helperDate.createDayStartedAt00(datesPeriod.minDate ? datesPeriod.minDate : null);

      innerObject.settings.ganttId = ganttId;

      innerObject.init.initUserAccountDetails();
      innerObject.init.initYearsToggles(currentDate);

      innerObject.init.initCustomDayProject();

      webixUI = innerObject.handlers.helpers.getWebixViewByMonth(currentDate.getMonth(), innerObject.settings.mainId);
      webixUI.blockEvent();
      $$(innerObject.views.customPopupDayOption.main.id).hide();
      !ignoreScroll && $$(innerObject.views.customDaysMainScrollView.id).scrollTo(0, webixUI.getNode().offsetTop);
      webixUI.unblockEvent();
    },
    initYearsToggles(currentDate) {
      const toggles = [
        'calendarLayoutBodyToggle',
        'resourceCalendarLayoutBodyToggle',
      ];

      _.each(toggles, toggleId => {
        const $$toggle = $$(toggleId);

        if ($$toggle) {

          $$toggle.config.currentYear = currentDate.getFullYear();
          $$toggle.refresh();
        }

      });

        $$('templateEditorLayoutBodyToggle').config.currentYear = new Date().getFullYear()
        $$('templateEditorLayoutBodyToggle').refresh();


      _.delay(() => {
        innerObject.handlers.setYear(currentDate.getFullYear());
      });
    },
    initUserAccountDetails() {
      innerObject.settings.userTeamAccount = plansModel.isActiveTeamAccount();
    },
    initCustomDayProject() {
      const $$customDaysBottomToolbarProject = $$(innerObject.views.customDaysBottomToolbarProject.id);
      const $$customDayUserTemplateSelect = $$(innerObject.views.customDayUserTemplateSelect.id);
      const $$customDaysBottomToolbarTemplates = $$(innerObject.views.customDaysBottomToolbarTemplates.id);
      const $$customDayAdvancedInfo = $$(innerObject.views.customDayAdvancedInfo.id);

      innerObject.settings.workMode = 'projectSettings';
      innerObject.settings.userAccess = customDayTemplatesModel.getUserAccessByGanttId(innerObject.settings.ganttId);
      innerObject.settings.workHoursDefault = zoomHoursPopup.handlers.getNewHoursRange();
    },
    initCustomDayTemplates(templateId) {
      innerObject.settings.workMode = 'templates';

      innerObject.handlers.helpers.clearAllCache();

      innerObject.init.initCustomDayUserTemplateList(templateId);
    },
    initProjectTemplateForCalendar() {
      const projectSettingsTemplate = customDayTemplatesModel.getActiveTemplateDataByGanttId(innerObject.settings.ganttId);

      customDayTemplatesModel.initCustomDaysByTemplateId(projectSettingsTemplate.id);

      innerObject.init.initUIButtonsForProjectTemplate();
    },
    initUIButtonsForProjectTemplate() {
      const templatesList = customDayTemplatesModel.getUserTemplateList();
      const customDaysForProject = customDaysModel.getDaysByActiveProject(innerObject.settings.ganttId);

      const $$customDayLoadTemplateButton = $$(innerObject.views.customDayLoadTemplateButton.id);
      const $$customDaySaveAsButton = $$(innerObject.views.customDaySaveAsButton.id);
      const $$customDayClearButton = $$(innerObject.views.customDayClearButton.id);

      if (!$$customDayLoadTemplateButton) {
        return;
      }

      if (templatesList.length) {
        $$customDayLoadTemplateButton.show();
      } else {
        $$customDayLoadTemplateButton.hide();
      }

      if (customDaysForProject.length) {
        $$customDaySaveAsButton.show();
        $$customDayClearButton.show();
      } else {
        $$customDaySaveAsButton.hide();
        $$customDayClearButton.hide();
      }
    },
    initCustomDayUserTemplateList(showTemplateId) {
      const templatesList = customDayTemplatesModel.getUserTemplateList();
      innerObject.settings.templateId = showTemplateId;

      if (!templatesList.length) {
        customDaysModel.initData([], null);
      }
    },
    getWebixCalendar(monthNumber) {
      const webixCalendar = webix.copy(innerObject.views.maketCalendarMonth);

      webixCalendar.id = `customDayCalendar${monthNumber}`;
      webixCalendar.date = new Date(new Date().setDate(1));
      webixCalendar.date.setMonth(monthNumber);
      webixCalendar.monthNumber = monthNumber;

      return webixCalendar;
    },
    getWebixCalendarByInterval(startMonth, endMonth) {
      const result = [];

      for (let monthNumber = startMonth - 1; monthNumber < endMonth; monthNumber++) {
        result.push(innerObject.init.getWebixCalendar(monthNumber));
      }

      return result;
    },
  },
  handlers: {
    getWebixCalendar(mainId, monthNumber) {
      const webixCalendar = webix.copy(innerObject.views.maketCalendarMonth);

      webixCalendar.id = `${mainId}-customDayCalendar${monthNumber}`;
      webixCalendar.date = new Date(new Date().setDate(1));
      webixCalendar.date.setMonth(monthNumber);
      webixCalendar.monthNumber = monthNumber;

      return webixCalendar;
    },
    getWebixCalendarByInterval(mainId, startMonth, endMonth) {
      const result = [];

      for (let monthNumber = startMonth - 1; monthNumber < endMonth; monthNumber++) {
        result.push(innerObject.handlers.getWebixCalendar(mainId, monthNumber));
      }

      return result;
    },
    createCalendarLayout(options) {
      // innerObject.settings.mainId = options.mainId;

      const scrollView = _.assign(_.cloneDeep(innerObject.views.customDaysMainScrollView), { id: options.mainId });

      scrollView.body.rows = [
        {
          cols: innerObject.handlers.getWebixCalendarByInterval(options.mainId, 1, 4),
        },
        {
          cols: innerObject.handlers.getWebixCalendarByInterval(options.mainId, 5, 8),
        },
        {
          cols: innerObject.handlers.getWebixCalendarByInterval(options.mainId, 9, 12),
        },
        {
          height: 40,
        },
      ];

      return scrollView;
    },
    customPopupWithOptions: {
      buttonOkClick() {
        const way = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id).getValue();
        const eventCurrentType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
        let newTemplateAsProjectSettingsId = 0;

        switch (way) {
        case 'toSplitInterval': // apply changing to all interval
          if (eventCurrentType === constantsCalendars.DAY_TYPES.WORKDAY) {
            innerObject.handlers.splitIntervalByNormalDay();
          } else {
            innerObject.handlers.splitInterval();
          }
          break;
        case 'toCustomDayInInterval': // add new custom day
          innerObject.handlers.splitIntervalByNewCustom();
          break;
        case 'toCustomDay': // add new custom day
          innerObject.handlers.createNewCustomDay();
          break;
        case 'toRepeat': // update repeat event by new params
          innerObject.handlers.updateCustomDay({
            from: innerObject.settings.customDayCache.from,
          });
          break;
        case 'toInterval': // update interval event
          innerObject.handlers.updateCustomDay({
            from: innerObject.settings.customDayCache.from,
            to: innerObject.settings.customDayCache.to,
          });
          break;
        case 'toSplitRepeat':
          innerObject.handlers.splitRepeatableByNewRepeatable();
          break;
        case 'deleteInterval':
          innerObject.handlers.deleteCustomEvent();
          break;
        case 'deleteRepeat':
          innerObject.handlers.deleteCustomEvent();
          break;
        case 'toSplitRepeatNewDay':
          innerObject.handlers.splitRepeatableByNewNormalDay();
          break;
        case 'toApplyUserTemplate':
          newTemplateAsProjectSettingsId = $$(innerObject.views.customPopupWithOptions.userTemplatesList.id).getSelectedId();
          customDayTemplatesModel.setUserTemplateAsProjectSettings(
            newTemplateAsProjectSettingsId,
            innerObject.settings.ganttId,
            { name: __('custom_days_default_project_template') },
          );
          // $$(innerObject.views.customDaySaveAsButton.id).show();
          // $$(innerObject.views.customDayClearButton.id).show();
          break;
        case 'toCreateNewAndApplyUserTemplate':
          newTemplateAsProjectSettingsId = $$(innerObject.views.customPopupWithOptions.userTemplatesList.id).getSelectedId();
          customDayTemplatesModel.saveProjectSettingsAndApplyNewTemplate(
            innerObject.settings.ganttId,
            $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectText.id).getValue(),
            newTemplateAsProjectSettingsId,
          );

          break;
        }
        app.trigger('updateCalendarMonths');
        $$(innerObject.views.customPopupWithOptions.main.id).hide();
      },
      buttonCloseClick() {
        $$(innerObject.views.customPopupWithOptions.main.id).hide();
      },
      changeSolutionOption(newValue, oldValue) {
        if (newValue === 'toCreateNewAndApplyUserTemplate') {
          $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectText.id).setValue(
            __('custom_days_default_user_template'),
          );
          $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).show();
        } else {
          $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).hide();
        }
        $$(innerObject.views.customPopupWithOptions.buttonOk.id).enable();
      },
      userTemplateListSelect() {
        $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id).enable();
      },
    },
    customPopupNewTemplate: {
      showPopup(templateName) {
        const setNewTemplate = templateName || __('custom_days_default_user_template');
        const $$templateNameText = $$(innerObject.views.customPopupNewTemplate.templateNameText.id);
        const $$customPopupNewTemplate = $$(innerObject.views.customPopupNewTemplate.main.id);

        $$templateNameText.setValue(setNewTemplate);
        $$customPopupNewTemplate.show();
        $$templateNameText.focus();
        $$templateNameText.getInputNode().select();
      },
      initForUserTemplates(mode, templateName) {
        $$(innerObject.views.customPopupNewTemplate.buttonOk.id).config.mode = mode;
        innerObject.handlers.customPopupNewTemplate.showPopup(templateName);
      },
      buttonOkClick() {
        let userTemplateName = _.escape($$(innerObject.views.customPopupNewTemplate.templateNameText.id).getValue());
        let userBaseTemplateId = 0;

        if (!userTemplateName.trim().length) {
          userTemplateName = __('custom_days_default_user_template');
        }

        switch ($$(innerObject.views.customPopupNewTemplate.buttonOk.id).config.mode) {
        case 'createNewTemplateBaseSettings':
          // if we don't have any user template - after add need show buttons
          customDayTemplatesModel.setProjectSettingsAsUserTemplate(
            innerObject.settings.ganttId,
            {
              name: userTemplateName,
              needRefresh: true,
            },
          );
          $$(innerObject.views.templateEditorLayoutBodyToggle.id).refresh();
          break;
        case 'createNewTemplateBaseCopyTemplate':
          userBaseTemplateId = innerObject.settings.templateID;
          customDayTemplatesModel.copyUserTemplate(userTemplateName, userBaseTemplateId);
          $$(innerObject.views.templateEditorLayoutBodyToggle.id).refresh();
          break;
        case 'createNewTemplateBaseNewTemplate':
          customDayTemplatesModel.addNewUserTemplate(userTemplateName, true);
          $$(innerObject.views.templateEditorLayoutBodyToggle.id).refresh();
          break;
        case 'renameTemplateBaseNewTemplate':
          userBaseTemplateId = innerObject.settings.templateID;
          customDayTemplatesModel.renameTemplate(userTemplateName, userBaseTemplateId);
          innerObject.init.initCustomDayUserTemplateList();
          $$(innerObject.views.templateEditorLayoutBodyToggle.id).refresh();
          break;
        }

        $$(innerObject.views.customPopupNewTemplate.main.id).hide();
      },
      buttonCloseClick() {
        $$(innerObject.views.customPopupNewTemplate.main.id).hide();
      },
    },
    markEventDay(date, monthNumber) {
      let getData = customDaysModel.getCustomTypeByDate(date, innerObject.settings.ganttId, monthNumber);
      let webixUI = {};
      let workDay = false;

      const resourceCalendar = $$('displayOptionsListDaysResource')

      if (innerObject.settings.mainId === 'resCustomDaysMainScrollView') {
        getData = innerObject.handlers.getResourceCustomDayTypeByDate(date, innerObject.settings.resourceID, monthNumber);

        // getData = customDaysResourcesModel.getCustomTypeByDate(date, innerObject.settings.resourceID, monthNumber);
        workDay = _.includes(resourceCalendar._selected, date.getDay());

        if(date.getDay() === 0 && (_.includes(resourceCalendar._selected, 7))) {
          workDay = true;
        }
      } else {

        workDay = _.includes(zoomDaysPopup.handlers.getWorkingDays(), date.getDay());
        if(date.getDay() === 0 && (_.includes(zoomDaysPopup.handlers.getWorkingDays(), 7))) {
          workDay = true;
        }
      }


      if (getData.eventType && getData.eventType !== '') {
        webixUI = innerObject.handlers.helpers.getWebixViewByMonth(monthNumber, innerObject.settings.mainId);
        webixUI.config.cacheData[date] = getData;
      }

      return getData.eventType || (workDay? '' : ' webix_cal_event ');
    },
    // legacy trash code for this old webix page
    getResourceCustomDayTypeByDate(controlDate, resourceId, monthNumber) {
      const getBasicArray = {
        exception: [],
        repeat: [],
      };

      const resourceData = globalStore.getters['resourcesModel/getResourceById'](resourceId);
      const customDaysArray = resourceData.customDays.map(o => ({
        id: o.id,
        from: o.from,
        to: o.to,
        hours_count: o.workingHours,
        repeat: o.repeat,
        repeat_from: o.repeatFrom,
        repeat_to: o.repeatTo,
        title: o.title,
        type: o.type,
      }));

      _.each(customDaysArray, dayData => {
        if (dayData.repeat) {
          getBasicArray.repeat.push(dayData);
        } else {
          getBasicArray.exception.push(dayData);
        }
      });

      getBasicArray.exception.sort((a, b) => {
        if (a.to && !b.to) {
          return -1;
        }

        if (a.to && b.to) {
          if (new Date(a.to).valueOf() - new Date(a.from).valueOf() <= new Date(b.to).valueOf() - new Date(b.from).valueOf()) {
            return 1;
          }

          return 0;
        }

        return 1;
      });

      getBasicArray.repeat.sort((a, b) => {
        if (a.repeat !== 'week' && b.reapeat === 'week') {
          return -1;
        }

        if (a.repeat === 'month' && b.repeat === 'year') {
          return -1;
        }

        if (a.repeat === 'week' && b.repeat !== 'week') {
          return 0;
        }

        if (a.repeat === b.repeat) {
          return 0;
        }

        return 1;
      });

      let customDayTo = {};
      let customDayFrom = {};
      let customDayRepeatTo = {};
      const resultData = {};
      let type = '';

      if (controlDate.getMonth() !== monthNumber) {
        resultData.eventType = '';

        return resultData;
      }

      _.some(getBasicArray.repeat, customDayData => {
        customDayFrom = dateFormatsHelper.createDayStartedAt00(customDayData.from);
        customDayRepeatTo = dateFormatsHelper.createDayStartedAt00(customDayData.repeat_to);

        switch (customDayData.repeat) {
        case 'week':
          if (
            (customDayData.repeat_to && customDayRepeatTo.valueOf() >= controlDate.valueOf()
                || !customDayData.repeat_to)
              && controlDate.valueOf() >= customDayFrom.valueOf()
              && customDayFrom.getDay() === controlDate.getDay()) {
            resultData.from = customDayFrom;
            resultData.repeat = true;
            resultData.repeatType = customDayData.repeat;
            resultData.repeat_to = customDayData.repeat_to;
            resultData.id = customDayData.id;
            resultData.title = customDayData.title;
            resultData.hours_count = +customDayData.hours_count;

            if (+customDayData.hours_count > 0 || customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
              type = 'repeat custom';
              resultData.type = 'custom';
            } else if (+customDayData.hours_count === 0 || customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
              type = 'repeat weekend';
              resultData.type = 'weekend';
            }
          }
          break;

        case 'month':
          if ((customDayData.repeat_to && customDayRepeatTo.valueOf() >= controlDate.valueOf()
                || !customDayData.repeat_to)
              && controlDate.valueOf() >= customDayFrom.valueOf()
              && customDayFrom.getDate() === controlDate.getDate()) {
            resultData.from = customDayFrom;
            resultData.repeat = true;
            resultData.repeat_to = customDayData.repeat_to;
            resultData.repeatType = customDayData.repeat;
            resultData.id = customDayData.id;
            resultData.title = customDayData.title;
            resultData.hours_count = +customDayData.hours_count;

            if (+customDayData.hours_count > 0 || customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
              type = 'repeat custom';
              resultData.type = 'custom';
            } else if (+customDayData.hours_count === 0 || customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
              type = 'repeat weekend';
              resultData.type = 'weekend';
            }
          }
          break;

        case 'year':
          if ((customDayData.repeat_to
                && customDayRepeatTo.valueOf() >= controlDate.valueOf()
                || !customDayData.repeat_to)
              && controlDate.valueOf() >= customDayFrom.valueOf()
              && customDayFrom.getMonth() === controlDate.getMonth()
              && customDayFrom.getDate() === controlDate.getDate()) {
            resultData.from = customDayFrom;
            resultData.repeat = true;
            resultData.repeat_to = customDayData.repeat_to;
            resultData.repeatType = customDayData.repeat;
            resultData.id = customDayData.id;
            resultData.title = customDayData.title;
            resultData.hours_count = +customDayData.hours_count;

            if (+customDayData.hours_count > 0 || customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
              type = 'repeat custom';
              resultData.type = 'custom';
            } else if (+customDayData.hours_count === 0 || customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
              type = 'repeat weekend';
              resultData.type = 'weekend';
            }
          }
          break;
        }
      });

      getBasicArray.exception.forEach(customDayData => {
        customDayFrom = dateFormatsHelper.createDayStartedAt00(customDayData.from);
        customDayTo = customDayData.to ? dateFormatsHelper.createDayStartedAt00(customDayData.to) : null;

        if (
          (
            customDayTo
            && controlDate.valueOf() >= customDayFrom.valueOf()
            && controlDate.valueOf() <= customDayTo.valueOf()
          )
          || (
            !customDayTo
            && controlDate.valueOf() === customDayFrom.valueOf()
          )) {
          resultData.repeat = false;
          resultData.from = customDayFrom;
          resultData.to = customDayTo || null;
          resultData.id = customDayData.id;

          resultData.title = customDayData.title;

          resultData.hours_count = +customDayData.hours_count;

          if (+customDayData.hours_count > 0 || customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
            type = 'exception custom';
            resultData.type = 'custom';
          } else if (+customDayData.hours_count === 0 || customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
            type = 'exception weekend';
            resultData.type = 'weekend';
          }

          if (customDayTo) {
            type += ' interval';
            if (controlDate.valueOf() === customDayFrom.valueOf()) {
              type += ' start';
            }
          }

          return true;
        }
      });

      resultData.eventType = type;

      return resultData;
    },

    selectCalendarDay(date, monthNumber, blockEvent) {
      let webixUI = innerObject.handlers.helpers.getWebixViewByMonth(monthNumber, innerObject.settings.mainId);
      const fixWebixUI = innerObject.handlers.helpers.getWebixViewByMonth(monthNumber, innerObject.settings.mainId);
      let oldDate = {};

      innerObject.settings.hoursArray = [];
      innerObject.settings.currentHoursArray = [];
      $$(innerObject.views.startPeriodTimePicker.id).setValue(date);
      $$(innerObject.views.endPeriodTimePicker.id).setValue(date);

      $$(innerObject.views.startPeriodTimePicker.id).refresh();
      $$(innerObject.views.endPeriodTimePicker.id).refresh();

      if (webixUI.config.cacheData[date]) {
        // set important variables
        innerObject.settings.editMode = true;
        innerObject.settings.customDayId = webixUI.config.cacheData[date].id;
        innerObject.settings.customDayCache = webix.copy(webixUI.config.cacheData[date]);

        $$(innerObject.views.customDayTitle.id).setValue(webixUI.config.cacheData[date].title);

        //$$(innerObject.views.countWorkingHoursIntervalLabel.id).setValue(webixUI.config.cacheData[date].hours_count);

        if(webixUI.config.cacheData[date].hours_count === 0 && webixUI.config.cacheData[date].type === 'weekend') {
          $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value = 0.5;
        } else {

          $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value = webixUI.config.cacheData[date].hours_count;
        }
        $$(innerObject.views.countWorkingHoursIntervalLabel.id).resize();
        $$(innerObject.views.countWorkingHoursIntervalLabel.id).refresh();

        innerObject.settings.currentHoursArray = webixUI.config.cacheData[date].hours;
        // //* if single interval
        // if (!webixUI.config.cacheData[date].repeat && webixUI.config.cacheData[date].to) {
        //   innerObject.handlers.helpers.initUIForNoneRepeatableIntervals(webixUI.config.cacheData[date]);
        // }
        // //* if not repeatable single event
        // if (!webixUI.config.cacheData[date].repeat && !webixUI.config.cacheData[date].to) {
        //   innerObject.handlers.helpers.initUIForNoneRepeatableSingle(webixUI.config.cacheData[date]);
        // }
        // // if single repeatable event
        // if (webixUI.config.cacheData[date].repeat && !webixUI.config.cacheData[date].to) {
        //   innerObject.handlers.helpers.initUIForRepeatableSingle(webixUI.config.cacheData[date]);
        // }
        $$(innerObject.views.clearButton.id).show();
      } else {
        innerObject.handlers.helpers.enableTimePicker($$(innerObject.views.endPeriodTimePicker.id));
        $$(innerObject.views.repeatCustomDayToggle.id).setValue(0);
        $$(innerObject.views.typeCustomDaysDataView.id).select('weekend');
        innerObject.handlers.helpers.hideWorkingHours();
        if (!innerObject.settings.editMode) {
          innerObject.settings.customDayId = 0;
        }
        innerObject.settings.customDayCache = {};
        $$(innerObject.views.customDayAdvancedInfo.id).hide();
        $$(innerObject.views.clearButton.id).hide();
        $$(innerObject.views.customDayTitle.id).setValue(null);
        innerObject.handlers.helpers.setDefaultSettingsForRepeat();
        $$(innerObject.views.repeatCustomDaySelectLayout.id).hide();
        $$(innerObject.views.endPeriodTimePicker.id).show();
        $$('сustomDayDatesDash').show();
        $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value = 0.5;
        $$(innerObject.views.countWorkingHoursIntervalLabel.id).resize();
        $$(innerObject.views.countWorkingHoursIntervalLabel.id).refresh();
      }

      for (let i = 0; i < 12; i++) {
        if (monthNumber !== i) {
          webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, innerObject.settings.mainId);
          if (webixUI.getSelectedDate()) {
            oldDate = webixUI.config.date;
            webixUI.setValue(null);
            webixUI.showCalendar(oldDate);
          }
        }
      }
      if (!blockEvent) {
        innerObject.handlers.helpers.showPopupWithCustomDayOptions(
          fixWebixUI.getNode().getElementsByClassName('webix_cal_select')[0],
        );
      }
    },
    hoursIntervalLabelClick(webixView, intervalView) {
      const hoursArray = innerObject.settings.hoursArray.length ? innerObject.settings.hoursArray : innerObject.settings.workHoursDefault;

      innerObject.handlers.helpers.initPopupWithHours(hoursArray, intervalView);
      $$(innerObject.views.customDaysHoursPopup.id).show(webixView);

      const popupNode = $$(innerObject.views.customDaysHoursPopup.id).getNode();
      const inputNodePosTop = webixView.getBoundingClientRect().y;
      const popupNodePosTop = popupNode.getBoundingClientRect().y;

      popupNode.classList.add('suggest_popup');

      if (inputNodePosTop > popupNodePosTop) {
        popupNode.classList.remove('suggest_popup_bottom');
        popupNode.classList.add('suggest_popup_top');
      } else {
        popupNode.classList.remove('suggest_popup_top');
        popupNode.classList.add('suggest_popup_bottom');
      }
    },
    changeHours(intervalView) {
      let showTimesIntervals;
      let selectedHours = $$(innerObject.views.customDaysHoursDataView.id).getSelectedId();
      if(!selectedHours) {
        showTimesIntervals = innerObject.settings.workHoursDefault;
        $$(innerObject.views.customDaysHoursPopup.id).hide();
      } else {
        showTimesIntervals = timeIntervalHelper.prepareSelectedHours(selectedHours, helperDate.getHoursArrayByUserTimeFormat());
      }


      intervalView = intervalView || $$(innerObject.views.workingHoursIntervalLabel.id);
      innerObject.handlers.helpers.setLabelForHoursIntervals(showTimesIntervals, selectedHours, intervalView);
      innerObject.settings.currentHoursArray = showTimesIntervals;

      if (!innerObject.settings.currentHoursArray || !innerObject.settings.currentHoursArray.length) {
        innerObject.handlers.hoursIntervalLabelClick(intervalView.$view);
      }
      //innerObject.settings.workHoursDefault = zoomHoursPopup.handlers.getNewHoursRange();
      $$(innerObject.views.customPopupDayOption.main.id).resize();
    },
    changeDayType(newValue, oldValue) {
      const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);

      $$customPopupDayOption.config.dayType = newValue;

      switch (newValue) {
      case constantsCalendars.DAY_TYPES.WORKDAY:
        innerObject.handlers.helpers.setDefaultSettingsForRepeat();
        innerObject.handlers.helpers.hideWorkingHours();
        break;
      case constantsCalendars.DAY_TYPES.WEEKEND:
        if (!innerObject.settings.customDayId) {
          innerObject.handlers.helpers.showRepeatWithDefaultSettings();
        } else {
          innerObject.handlers.helpers.showRepeatWithCustomDaySettings(innerObject.settings.customDayCache);
        }
        innerObject.handlers.helpers.hideWorkingHours();
        innerObject.settings.currentHoursArray = [];
        break;
      case constantsCalendars.DAY_TYPES.CUSTOM:
        if (innerObject.settings.resourceID) {
          $$(innerObject.views.countWorkingHoursLayout.id).show();
        } else {
          $$(innerObject.views.countWorkingHoursLayout.id).hide();
          innerObject.handlers.helpers.showWorkingHours();
        }
        if (innerObject.settings.customDayId) {
          innerObject.handlers.helpers.showRepeatWithCustomDaySettings(innerObject.settings.customDayCache);
          innerObject.handlers.helpers.setLabelForHoursIntervals(innerObject.settings.hoursArray);
        } else {
          innerObject.handlers.helpers.showRepeatWithDefaultSettings();
          innerObject.handlers.helpers.setLabelForHoursIntervals(innerObject.settings.workHoursDefault);
        }
        break;
      }
      innerObject.handlers.resizeCustomDayPopup();
    },
    repeatChange(newValue, oldValue) {
      const endDatePicker = $$(innerObject.views.endPeriodTimePicker.id);
      const datesDash = $$('сustomDayDatesDash');

      if (!$$(innerObject.views.repeatForeverCustomDayCheckBox.id).isEnabled()) {
        webix.message({ type: 'error', text: __('custom_days_cant_set_repeat') });
      }

      $$(innerObject.views.repeatCustomDayEndTimePicker.id).hide();

      if (newValue) {
        $$(innerObject.views.repeatCustomDaySelectLayout.id).show();
        $$(innerObject.views.repeatForeverCustomDayCheckBox.id).setValue(true);
        $$(innerObject.views.repeatCustomDayTypeSelect.id).setValue('week');

        // Hide endDate
        endDatePicker.hide();
        datesDash.hide();
      } else {
        $$(innerObject.views.repeatCustomDaySelectLayout.id).hide();

        // Show endDate
        endDatePicker.show();
        datesDash.show();
      }
      innerObject.handlers.resizeCustomDayPopup();
    },
    repeatForeverChange(newValue, oldValue) {
      const currentDate = $$(innerObject.views.startPeriodTimePicker.id).getValue();

      if (!newValue && $$(innerObject.views.repeatCustomDayToggle.id).getValue()) {
        $$(innerObject.views.repeatCustomDayEndTimePicker.id).setValue(currentDate);
        $$(innerObject.views.repeatCustomDayEndTimePicker.id).show();
      } else {
        $$(innerObject.views.repeatCustomDayEndTimePicker.id).hide();
      }
      innerObject.handlers.resizeCustomDayPopup();
    },
    resizeCustomDayPopup() {
      const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);
      const currentNode = $$customPopupDayOption.config.targetNode;
      let position = { x: 0, y: 0 };

      if (currentNode && $$customPopupDayOption.isVisible()) {
        const $$customDaysListNode = $$('customDaysList').getNode();
        const $$customDaysListNodePos = $$customDaysListNode.getBoundingClientRect();
        const $$customDaysListNodeWidth = $$customDaysListNode.offsetWidth;
        const $$customDaysListNodeHeight = $$customDaysListNode.offsetHeight;

        position.x = $$customDaysListNode.getBoundingClientRect().x + $$customDaysListNode.offsetWidth / 2 - $$customPopupDayOption.$width / 2;
        position.y = $$customDaysListNodePos.y - currentNode.offsetHeight / 2;

        position = innerObject.handlers.helpers.calcCustomDayPopupPosition(currentNode.closest('.custom-days-list-item'), $$customPopupDayOption);

        if (!position) return;

        if (position.x < 3 && position.y < 3) {
          $$customPopupDayOption.hide();
        } else {
          $$customPopupDayOption.show({ x: position.x, y: position.y });
        }
      }
    },
    buttonOkClick() {
      const customDaysData = innerObject.handlers.helpers.getRepeatData();
      const newEventType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
      const templateData = {};
      const way = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id).getValue();
      const startDatePicker = $$(innerObject.views.startPeriodTimePicker.id);
      const endDatePicker = $$(innerObject.views.endPeriodTimePicker.id);

      if (startDatePicker.config.isInvalid || endDatePicker.config.isInvalid) {
        startDatePicker.config.isInvalid = false;
        endDatePicker.config.isInvalid = false;

        return true;
      }

      if (!innerObject.settings.templateId) {
        webix.message({ type: 'error', text: __('custom_days_select_template_msg') });

        return true;
      }

      customDaysData.from = startDatePicker.getValue();
      customDaysData.to = endDatePicker.isEnabled() ? endDatePicker.getValue() : null;
      customDaysData.title = $$(innerObject.views.customDayTitle.id).getValue() || __(`custom_day_default_title_${newEventType}`);
      customDaysData.type = newEventType;

      if (templateData.user_create && innerObject.settings.workMode === 'projectSettings') {
        webix.message({ type: 'error', text: __('custom_days_not_access_msg') });

        return true;
      }

      // if user not change default settings
      if (customDaysData.type === constantsCalendars.DAY_TYPES.CUSTOM && (
        !innerObject.settings.currentHoursArray || !innerObject.settings.currentHoursArray.length
      )) {
        customDaysData.hours = innerObject.settings.workHoursDefault;
      }
      if (customDaysData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
        customDaysData.hours = [];
      }

      if (!innerObject.settings.customDayId && newEventType !== constantsCalendars.DAY_TYPES.WORKDAY) { // if new
        innerObject.handlers.createNewCustomDay();

        return false;
      }
      if (innerObject.settings.customDayId) { // if update or delete
        // if changing one Day from interval
        if (innerObject.settings.dayTypeMode === 'eventInterval'
          && customDaysData.from.valueOf() === customDaysData.to.valueOf()) {
          if (!customDaysData.repeat) {

          }
          innerObject.handlers.helpers.initPopupWithSolutions(customDaysData);

          return true;
        }
        if (innerObject.settings.dayTypeMode === 'eventRepeatSingle' && !customDaysData.repeat) {
          innerObject.handlers.helpers.initPopupWithSolutions(customDaysData);

          return true;
        }
        if (innerObject.settings.dayTypeMode === 'eventRepeatSingle' && customDaysData.repeat
          && customDaysData.from.valueOf() > innerObject.settings.customDayCache.from.valueOf()) {
          if (innerObject.settings.customDayCache.repeatType !== customDaysData.repeat) {
            innerObject.settings.dayTypeMode = 'eventRepeatSingleNewRepeatType';
          }
          innerObject.settings.dayTypeMode = 'eventRepeatSingle';
          innerObject.handlers.helpers.initPopupWithSolutions(customDaysData);

          return true;
        }
        if (newEventType === constantsCalendars.DAY_TYPES.WORKDAY) {
          innerObject.handlers.deleteCustomEvent();

          return true;
        }
        // else simply update data;
        innerObject.handlers.updateCustomDay();
        innerObject.handlers.helpers.clearCalendarCache();
        $$(innerObject.views.customPopupDayOption.main.id).hide();
      }
    },
    buttonOkResourceClick() {
      const customDaysData = innerObject.handlers.helpers.getRepeatData();
      const newEventType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
      const templateData = {};
      const startDatePicker = $$(innerObject.views.startPeriodTimePicker.id);
      const endDatePicker = $$(innerObject.views.endPeriodTimePicker.id);

      if (startDatePicker.config.isInvalid || endDatePicker.config.isInvalid) {
        startDatePicker.config.isInvalid = false;
        endDatePicker.config.isInvalid = false;

        return true;
      }

      customDaysData.from = startDatePicker.getValue();
      customDaysData.to = endDatePicker.getValue();
      customDaysData.type = newEventType;
      customDaysData.hours = innerObject.settings.currentHoursArray;
      customDaysData.resource_id = innerObject.settings.resourceID;

      if (templateData.user_create && innerObject.settings.workMode === 'projectSettings') {
        webix.message({ type: 'error', text: __('custom_days_not_access_msg') });

        return true;
      }

      customDaysData.title = $$(innerObject.views.customDayTitle.id).getValue()
        || __(`custom_day_default_title_${customDaysData.type}`);

      // if user not change default settings
      if (customDaysData.type === constantsCalendars.DAY_TYPES.CUSTOM && (!innerObject.settings.currentHoursArray || !innerObject.settings.currentHoursArray.length)) {
        customDaysData.hours_count = $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value;
      }

      if (customDaysData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
        customDaysData.hours_count = '0';
      }
      if (moment(customDaysData.to).diff(customDaysData.from, 'days') < 1) {
        customDaysData.to = null;
      }

      const preparedCustomDay = {
        from: customDaysData.from,
        to: customDaysData.to || undefined,
        workingHours: parseFloat(customDaysData.hours_count),
        repeat: customDaysData.repeat,
        repeatFrom: customDaysData.repeat_from,
        repeatTo: customDaysData.repeat_to,
        title: customDaysData.title,
        type: customDaysData.type,
      };

      if (!innerObject.settings.customDayId) {
        globalStore.dispatch('resourcesModel/createWorkCustomDays', [{
          resourceId: customDaysData.resource_id,
          customDays: [preparedCustomDay],
        }]);
      } else {
        preparedCustomDay.id = innerObject.settings.customDayId;
        globalStore.dispatch('resourcesModel/changeWorkCustomDays', [{
          resourceId: customDaysData.resource_id,
          customDays: [preparedCustomDay],
        }]);
      }
    },
    buttonLoadTemplatesClick() {
      innerObject.handlers.helpers.initPopupWithSolutionsForProjectSettings();
    },
    createNewCustomDay() {
      if (innerObject.settings.workMode === 'projectSettings') {
        innerObject.handlers.createNewCustomDayForProjectSettings();
      } else {
        innerObject.handlers.createNewCustomDayForUserTemplate();
      }
    },
    createNewCustomDayForUserTemplate() {
      const customDaysData = innerObject.handlers.helpers.collectAllDataToCustomDay();
      const customUserTemplateId = customDaysModel.activeTemplateId;

      delete customDaysData.type;

      customDaysData.template_id = customUserTemplateId;

      customDayTemplatesModel.addNewCustomDay(customDaysData, null,
        {
          template_id: customUserTemplateId,
          type: 'userTemplateByNewCustomDay',
          userCreate: 1,
          name: __('custom_days_default_user_template'),
        });
    },
    createNewCustomDayForProjectSettings() {
      const customDaysData = innerObject.handlers.helpers.collectAllDataToCustomDay();

      delete customDaysData.type;

      customDayTemplatesModel.addNewCustomDay(customDaysData, innerObject.settings.ganttId,
        {
          type: 'baseTemplateByNewCustomDay',
          userCreate: 0,
          name: __('custom_days_default_project_template'),
        });
    },
    createAndUpdateCustomDays(updateCustomDayData) {
      innerObject.handlers.createNewCustomDay();
      // and create new custom day
      customDaysModel.updateCustomDayById(innerObject.settings.customDayId, updateCustomDayData,
        innerObject.settings.ganttId);
    },
    updateCustomDay(advancedKeysValue) {
      const updateCustomDayData = innerObject.handlers.helpers.collectAllDataToCustomDay();

      if (typeof advancedKeysValue === 'object') {
        Object.keys(advancedKeysValue).forEach(customValueKey => {
          updateCustomDayData[customValueKey] = advancedKeysValue[customValueKey];
        });
      }
      delete updateCustomDayData.type;
      // TODO validation before update;

      customDaysModel.updateCustomDayById(
        innerObject.settings.customDayId,
        updateCustomDayData,
        innerObject.settings.ganttId,
      );
    },
    splitIntervalByNewRepeat(repeatData) {
      const selectedDay = $$(innerObject.views.startPeriodTimePicker.id).getValue();
      let stepDay = {};
      let controlDay = {};
      const newIntervals = [];
      let repeatDatesInInterval = [];
      const newIntervalData = {
        repeat: null,
        repeat_from: null,
        repeat_to: null,
        hours: innerObject.settings.customDayCache.hours,
      };

      if (innerObject.settings.customDayCache.to && innerObject.settings.customDayCache.from
        && selectedDay.valueOf() <= innerObject.settings.customDayCache.to.valueOf()
        && selectedDay.valueOf() >= innerObject.settings.customDayCache.from.valueOf()) {
        repeatDatesInInterval = customDaysModel.getAllDaysForRepeatOnInterval(
          innerObject.settings.customDayCache.from,
          innerObject.settings.customDayCache.to,
          selectedDay,
          repeatData.repeat,
        );
        repeatDatesInInterval.forEach((splitDay, index) => {
          // this day split interval for new two - one we will update - and one will add
          // calc fits interval - left from split day
          if (!index && splitDay.valueOf() !== innerObject.settings.customDayCache.from.valueOf()) {
            stepDay = new Date(splitDay).setDate(splitDay.getDate() - 1);
            controlDay = new Date(innerObject.settings.customDayCache.from);
            newIntervals.push({
              from: controlDay,
              to: new Date(stepDay),
            });
          }

          stepDay = new Date(splitDay).setDate(splitDay.getDate() + 1);
          if ((index + 1) === repeatDatesInInterval.length) { // if last date
            controlDay = new Date(innerObject.settings.customDayCache.to);
          } else {
            controlDay = new Date(repeatDatesInInterval[index + 1]);
            controlDay.setDate(controlDay.getDate() - 1);
          }

          if (splitDay.valueOf() !== innerObject.settings.customDayCache.to.valueOf()) {
            newIntervals.push({
              from: new Date(stepDay),
              to: controlDay,
            });
          }
        });

        newIntervals.forEach((intervalData, index) => {
          newIntervalData.from = intervalData.from;
          newIntervalData.to = intervalData.to;
          if (newIntervalData.from.valueOf() === newIntervalData.to.valueOf()) {
            newIntervalData.to = null;
          }
          if ((index + 1) === newIntervals.length) {
            customDaysModel.updateCustomDayById(
              innerObject.settings.customDayId,
              newIntervalData,
              innerObject.settings.ganttId,
            );
          } else {
            customDaysModel.addNewItem(webix.copy(newIntervalData), innerObject.settings.ganttId);
          }
        });

        innerObject.handlers.createNewCustomDay();
      }
    },
    splitIntervalByNewCustom() {
      const selectedDay = $$(innerObject.views.startPeriodTimePicker.id).getValue();
      let newFromToDates = {};
      const updateCustomDay = {};

      if (innerObject.settings.customDayCache.to && innerObject.settings.customDayCache.from
        && selectedDay.valueOf() < innerObject.settings.customDayCache.to.valueOf()
        && selectedDay.valueOf() > innerObject.settings.customDayCache.from.valueOf()) {
        // simple create new custom day
        innerObject.handlers.createNewCustomDay();

        return true;
      }

      // if select day = start interval day
      if (selectedDay.valueOf() === innerObject.settings.customDayCache.from.valueOf()) {
        // update current custom day = left start day
        newFromToDates = innerObject.handlers.helpers.getNewIntervalFromToDates('start');

        updateCustomDay.from = newFromToDates.from;
        updateCustomDay.to = newFromToDates.to;

        innerObject.handlers.createAndUpdateCustomDays(updateCustomDay);

        return true;
      }
      // if select day = end interval day
      if (innerObject.settings.customDayCache.to
        && selectedDay.valueOf() === innerObject.settings.customDayCache.to.valueOf()) {
        newFromToDates = innerObject.handlers.helpers.getNewIntervalFromToDates('end');

        updateCustomDay.from = newFromToDates.from;
        updateCustomDay.to = newFromToDates.to;

        innerObject.handlers.createAndUpdateCustomDays(updateCustomDay);

        return true;
      }
      innerObject.handlers.createNewCustomDay();
    },
    splitInterval() {
      const repeatData = innerObject.handlers.helpers.getRepeatData();

      if (repeatData.repeat) {
        innerObject.handlers.splitIntervalByNewRepeat(repeatData);
      } else {
        innerObject.handlers.splitIntervalByNewCustom();
      }
    },
    splitIntervalByNormalDay() {
      const selectedDay = $$(innerObject.views.startPeriodTimePicker.id).getValue();
      let newCustomDaysData = {};
      let oldCustomDaysData = {};
      let newFromToDates = {};

      // if select day = first day in interval

      oldCustomDaysData = webix.copy(innerObject.settings.customDayCache);
      if (selectedDay.valueOf() === innerObject.settings.customDayCache.from.valueOf()) {
        newFromToDates = innerObject.handlers.helpers.getNewIntervalFromToDates('start');
        innerObject.handlers.updateCustomDay({
          hours: oldCustomDaysData.hours,
          from: newFromToDates.from,
          to: newFromToDates.to,
        });
      }

      // if select day = last day in interval
      if (selectedDay.valueOf() === innerObject.settings.customDayCache.to.valueOf()) {
        newFromToDates = innerObject.handlers.helpers.getNewIntervalFromToDates('end');
        innerObject.handlers.updateCustomDay({
          hours: oldCustomDaysData.hours,
          from: newFromToDates.from,
          to: newFromToDates.to,
        });
      }

      // if select day - in interval
      if (selectedDay.valueOf() > innerObject.settings.customDayCache.from.valueOf()
        && selectedDay.valueOf() < innerObject.settings.customDayCache.to.valueOf()) {
        newCustomDaysData = webix.copy(innerObject.settings.customDayCache);
        // createNew
        newCustomDaysData.from = new Date(selectedDay);
        newCustomDaysData.from.setDate(newCustomDaysData.from.getDate() + 1);
        if (newCustomDaysData.from.valueOf() === newCustomDaysData.to.valueOf()) {
          newCustomDaysData.to = null;
        }
        customDaysModel.addNewItem({
          from: newCustomDaysData.from,
          to: newCustomDaysData.to,
          hours: newCustomDaysData.hours,
        }, innerObject.settings.ganttId);

        // oldUpdate
        oldCustomDaysData.to = new Date(selectedDay);
        oldCustomDaysData.to.setDate(oldCustomDaysData.to.getDate() - 1);
        if (oldCustomDaysData.to.valueOf() === oldCustomDaysData.from.valueOf()) {
          oldCustomDaysData.to = null;
        }

        customDaysModel.updateCustomDayById(
          innerObject.settings.customDayId,
          {
            from: oldCustomDaysData.from,
            to: oldCustomDaysData.to,
            hours: oldCustomDaysData.hours,
          },
          innerObject.settings.ganttId,
        );
      }
    },
    splitRepeatableByNewRepeatable() {
      const repeatTo = new Date($$(innerObject.views.startPeriodTimePicker.id).getValue());
      // update old data with new params

      innerObject.handlers.updateCustomDay({
        from: innerObject.settings.customDayCache.from,
        hours: innerObject.settings.customDayCache.hours,
        repeat: innerObject.settings.customDayCache.repeatType,
        repeat_to: new Date(repeatTo.setDate(repeatTo.getDate() - 1)),
      });
      innerObject.handlers.createNewCustomDay();
    },
    splitRepeatableByNewNormalDay() {
      const customDay = new Date($$(innerObject.views.startPeriodTimePicker.id).getValue());
      let nextRepeatDay = {};
      const newRepeatData = webix.copy(innerObject.settings.customDayCache);
      const oldRepeatData = webix.copy(innerObject.settings.customDayCache);
      // create new repeatEvent

      newRepeatData.repeat_to = new Date(customDay);
      newRepeatData.repeat_to.setDate(newRepeatData.repeat_to.getDate() - 1);
      if (newRepeatData.repeat_to.valueOf() > newRepeatData.from.valueOf()) {
        customDaysModel.addNewItem(
          {
            from: newRepeatData.from,
            hours: newRepeatData.hours,
            repeat_to: newRepeatData.repeat_to,
            repeat: newRepeatData.repeatType,
          },
          innerObject.settings.ganttId,
        );
      }

      // oldUpdate
      // calc next repeat day
      nextRepeatDay = customDaysModel.getNexRepeatableDay(
        customDay,
        oldRepeatData.repeatType,
      );
      oldRepeatData.from = new Date(nextRepeatDay);
      oldRepeatData.repeat_to = oldRepeatData.repeat_to ? new Date(oldRepeatData.repeat_to) : null;
      if (oldRepeatData.repeat_to && nextRepeatDay.valueOf() >= oldRepeatData.repeat_to.valueOf()) {
        // set not-repeatable event
        innerObject.handlers.deleteCustomEvent(oldRepeatData.id);
      } else {
        customDaysModel.updateCustomDayById(
          innerObject.settings.customDayId,
          {
            from: oldRepeatData.from,
            hours: oldRepeatData.hours,
            repeat_to: oldRepeatData.repeat_to,
            repeat: oldRepeatData.repeatType,
          },
          innerObject.settings.ganttId,
        );
      }
    },
    deleteCustomEvent(customDayId) {
      const deleteCustomDayId = customDayId || innerObject.settings.customDayId;

      customDaysModel.deleteCustomDay(deleteCustomDayId);
    },
    changeYear(directionStr, toggleId, mainId) {

      let webixUI = {};
      let calendarYear = {};
      const direction = directionStr === 'right' ? 1 : -1;

      $$(toggleId).config.currentYear += direction;

      $$(toggleId).refresh();
      for (let i = 0; i < 12; i++) {
        webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, mainId);
        webixUI.config.cacheData = {};
        calendarYear = new Date(webixUI.config.date);
        // issue with 29 february
        calendarYear.setDate(1);
        calendarYear.setFullYear(calendarYear.getFullYear() + direction);
        webixUI.config.date = calendarYear;
        webixUI.refresh();
      }
    },
    setYear(year) {
      for (let i = 0; i < 12; i++) {
        const webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, innerObject.settings.mainId);

        webixUI.config.cacheData = {};

        const calendarYear = new Date(webixUI.config.date);

        calendarYear.setDate(1);
        calendarYear.setFullYear(year);
        webixUI.config.date = calendarYear;
        webixUI.refresh();
      }
    },
    changeUserTemplate(newUserTemplateId) {
      const templateData = {};

      if (newUserTemplateId === '' || !newUserTemplateId) {
        return true;
      }
      innerObject.settings.templateID = newUserTemplateId;

      customDayTemplatesModel.initCustomDaysByTemplateId(newUserTemplateId);

      innerObject.handlers.helpers.clearCalendarCache();
    },
    changeTemplate(newTemplateId, oldValue) {
      const projectSettingsTemplate = customDayTemplatesModel.getActiveTemplateDataByGanttId(innerObject.settings.ganttId);
      let userTemplate = {};

      if (newTemplateId !== 'virtualTemplate') {
        userTemplate = customDayTemplatesModel.getTemplateDataByTemplateId(newTemplateId);
        if (userTemplate.user_create && true) {
          // newTemplateId !== "virtualTemplate"){
          innerObject.handlers.helpers.initPopupWithSolutionsForProjectSettings();
        } else {
          customDayTemplatesModel.initCustomDaysByTemplateId(projectSettingsTemplate.id);
          innerObject.handlers.helpers.clearCalendarCache();
        }
      }
    },
    buttonSaveAsClick() {
      innerObject.handlers.customPopupNewTemplate.initForUserTemplates('createNewTemplateBaseSettings');
    },
    buttonClearClick() {
      webix.confirm({
        ok: __('common_yes'),
        cancel: __('common_no'),
        width: 420,
        text: __('custom_day_clear_all_message'),
        type: 'confirm-error',
      }).then(result => {
        if (result) {
          const customDaysData = {};
          const newEventType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
          const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);
          const way = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id).getValue();

          customDaysData.from = $$(innerObject.views.startPeriodTimePicker.id).getValue();
          customDaysData.type = newEventType;
          customDaysData.id = $$customPopupDayOption.config.customDayId || innerObject.settings.customDayId;

          if ($$customPopupDayOption.config.workMode === constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR) {
            globalStore.dispatch('resourcesModel/removeWorkCustomDays', {
              resourceId: innerObject.settings.resourceID,
              customDayIds: [customDaysData.id],
            });
            // customDaysResourcesModel.deleteCustomDay(customDaysData.id);
          } else {
            customDaysModel.deleteCustomDay(customDaysData.id);
          }

          $$customPopupDayOption.hide();
        }
      });
    },
    buttonNewUserTemplateClick() {
      innerObject.handlers.customPopupNewTemplate.initForUserTemplates('createNewTemplateBaseNewTemplate');
    },
    buttonCopyUserTemplateClick() {
      const userBaseTemplateId = innerObject.settings.templateID;
      const currentTemplateData = customDayTemplatesModel.getTemplateDataByTemplateId(userBaseTemplateId);

      innerObject.handlers.customPopupNewTemplate.initForUserTemplates(
        'createNewTemplateBaseCopyTemplate',
        `${currentTemplateData.name} ${__('custom_days_template_name_copy')}`,
      );
    },
    buttonDeleteUserTemplateClick() {
      const userBaseTemplateId = innerObject.settings.templateID;
      // webix.alert({
      //   //title: __("custom_days_delete_template_title"),
      //   ok: __("common_yes"),
      //   cancel: __("common_no"),
      //   width: 420,
      //   text: __("custom_day_delete_template_message"),
      //   type: "confirm-error",
      //   callback: function (result) {
      //     if (result) {
      //       customDayTemplatesModel.deleteUserTemplate(userBaseTemplateId);
      //     }
      //   }
      // });

      webix.confirm({
        // title: __("custom_days_delete_template_title"),
        ok: __('common_yes'),
        cancel: __('common_no'),
        width: 420,
        text: __('custom_day_delete_template_message'),
        type: 'confirm-error',
      }).then(result => {
        if (result) {
          customDayTemplatesModel.deleteUserTemplate(userBaseTemplateId);
        }
      });
    },
    buttonRenameUserTemplateClick() {
      const userBaseTemplateId = innerObject.settings.templateID;
      const currentTemplateData = customDayTemplatesModel.getTemplateDataByTemplateId(userBaseTemplateId);

      innerObject.handlers.customPopupNewTemplate.initForUserTemplates(
        'renameTemplateBaseNewTemplate',
        currentTemplateData.name,
      );
    },
    resetSelectedAndHideTemplatePopup() {
      $$('templateManagePopup').getNode().querySelectorAll('.webix_list_item').forEach(item => {
        item.classList.remove('webix_selected');
      });

      $$('templateManagePopup').hide();
    },
    helpers: {
      collectAllDataToCustomDay() {
        const customDaysData = innerObject.handlers.helpers.getRepeatData();

        customDaysData.type = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
        customDaysData.hours = innerObject.settings.currentHoursArray;

        // if user not change default settings
        if (customDaysData.type === constantsCalendars.DAY_TYPES.CUSTOM && (!innerObject.settings.currentHoursArray || !innerObject.settings.currentHoursArray.length)) {
          customDaysData.hours = innerObject.settings.workHoursDefault;
        }
        if (customDaysData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
          customDaysData.hours = [];
        }
        customDaysData.from = $$(innerObject.views.startPeriodTimePicker.id).getValue();
        customDaysData.to = $$(innerObject.views.endPeriodTimePicker.id).isEnabled() ? $$(innerObject.views.endPeriodTimePicker.id).getValue() : null;

        customDaysData.title = $$(innerObject.views.customDayTitle.id).getValue()
          || __(`custom_day_default_title_${customDaysData.type}`);

        if (customDaysData.type === constantsCalendars.DAY_TYPES.CUSTOM && $$(innerObject.views.repeatCustomDayToggle.id).getValue()) {
          customDaysData.repeat = $$(innerObject.views.repeatCustomDayTypeSelect.id).getValue();
        }

        if (moment(customDaysData.to).diff(customDaysData.from, 'days') < 1) {
          customDaysData.to = null;
        }
        customDaysData.hours = JSON.stringify(customDaysData.hours);

        return customDaysData;
      },
      getRepeatData() {
        const repeatEvent = $$(innerObject.views.repeatCustomDayToggle.id).getValue();
        const repeatForever = $$(innerObject.views.repeatForeverCustomDayCheckBox.id).getValue();
        const repeatPeriod = $$(innerObject.views.repeatCustomDayTypeSelect.id).getValue();
        const repeatTo = $$(innerObject.views.repeatCustomDayEndTimePicker.id).getValue();
        const repeatObject = {
          repeat: null,
          repeat_from: null,
          repeat_to: null,
        };
        const customDayType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();

        if (customDayType !== constantsCalendars.DAY_TYPES.WORKDAY) {
          repeatObject.repeat = repeatEvent ? repeatPeriod : null;
          repeatObject.repeat_to = !repeatForever ? repeatTo : null;
        }

        return repeatObject;
      },
      changeLabelInWebixUI(webixUI, labelText) {
        webixUI.config.label = labelText;
        webixUI.refresh();
        webixUI.show();
      },
      enableTimePicker(webixUI) {
        if (!webixUI.isEnabled()) {
          webixUI.hide();
          webixUI.enable();
          webixUI.show();
        }
      },
      clearAllCache() {
        let webixUI = {}; let
          oldDate;
        // if ($$(innerObject.views.customDaysPopup.id).isVisible()) {

        innerObject.handlers.helpers.clearCalendarCache();
        innerObject.settings.customDayCache = {};
        innerObject.settings.customDayId = 0;
        for (let i = 0; i < 12; i++) {
          webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, innerObject.settings.mainId);
          oldDate = webixUI.getSelectedDate();
          if (oldDate && oldDate.getMonth() === i) {
            innerObject.handlers.selectCalendarDay(oldDate, oldDate.getMonth(), true);
          }
        }

        // }
      },
      clearCalendarCache() {
        let webixUI = {};

        for (let i = 0; i < 12; i++) {
          webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, innerObject.settings.mainId);
          webixUI.config.cacheData = {};
          webixUI.refresh();
        }
      },
      clearCalendarCacheAndResetDate(newDate) {
        let webixUI = {};

        for (let i = 0; i < 12; i++) {
          webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, innerObject.settings.mainId);
          // if(setDate && webixUI.getSelectedDate()){
          // 	webixUI.setValue(null);
          // }
          webixUI.config.cacheData = {};
          webixUI.config.date = new Date(newDate.setDate(1));
          webixUI.config.date.setMonth(i);
          webixUI.refresh();
        }
        innerObject.settings.customDayId = 0;
        innerObject.settings.customDayCache = {};
      },
      initUIForNoneRepeatableIntervals(customDayData) {
        const currentDaySelect = $$(innerObject.views.startPeriodTimePicker.id).getValue();

        innerObject.settings.dayTypeMode = 'eventInterval';

        $$(innerObject.views.endPeriodTimePicker.id).enable();
        $$(innerObject.views.endPeriodTimePicker.id).blockEvent();
        $$(innerObject.views.endPeriodTimePicker.id).setValue(customDayData.to);
        $$(innerObject.views.endPeriodTimePicker.id).unblockEvent();
        // innerObject.handlers.helpers.enableTimePicker($$(innerObject.views.endPeriodTimePicker.id));
        $$(innerObject.views.customDayAdvancedInfo.id).hide();

        $$(innerObject.views.typeCustomDaysDataView.id).select(customDayData.type);

        innerObject.handlers.helpers.showRepeatWithDefaultSettings();

        if (customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
          innerObject.handlers.helpers.setLabelForHoursIntervals(customDayData.hours);
          innerObject.handlers.helpers.showWorkingHours();
          // innerObject.handlers.helpers.changeLabelInWebixUI(
          // $$(innerObject.views.advancedOptionEventLabel.id),
          // 		__("custom_days_advanced_option_interval"));
          $$(innerObject.views.workingHoursLabel.id).hide();
        }
        if (customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
          innerObject.handlers.helpers.hideWorkingHours();
          //	$$(innerObject.views.advancedOptionEventLabel.id).hide();
        }
      },
      initUIForNoneRepeatableSingle(customDayData) {
        innerObject.settings.dayTypeMode = 'eventSingle';

        // innerObject.handlers.helpers.enableTimePicker($$(innerObject.views.endPeriodTimePicker.id));

        $$(innerObject.views.customDayAdvancedInfo.id).hide();
        // $$(innerObject.views.advancedOptionEventLabel.id).hide();

        $$(innerObject.views.typeCustomDaysDataView.id).select(customDayData.type);

        if (customDayData.from) {
          $$(innerObject.views.endPeriodTimePicker.id).blockEvent();
          $$(innerObject.views.endPeriodTimePicker.id).setValue(customDayData.from);
          $$(innerObject.views.endPeriodTimePicker.id).unblockEvent();
        }

        if (customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
          innerObject.handlers.helpers.setLabelForHoursIntervals(customDayData.hours);
          innerObject.handlers.helpers.showWorkingHours();
        }
        if (customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
          innerObject.handlers.helpers.hideWorkingHours();
          // $$(innerObject.views.advancedOptionEventLabel.id).hide();
        }
      },
      initUIForRepeatableSingle(customDayData) {
        innerObject.settings.dayTypeMode = 'eventRepeatSingle';

        // innerObject.handlers.helpers.enableTimePicker($$(innerObject.views.endPeriodTimePicker.id));
        $$(innerObject.views.endPeriodTimePicker.id).blockEvent();
        $$(innerObject.views.endPeriodTimePicker.id).setValue(customDayData.from);
        $$(innerObject.views.endPeriodTimePicker.id).unblockEvent();

        $$(innerObject.views.customDayAdvancedInfo.id).config.startDate = webix.i18n.longDateFormatStr(customDayData.from);

        $$(innerObject.views.customDayAdvancedInfo.id).config.typeCustomDay = __('custom_days_label_repeat_event');
        $$(innerObject.views.customDayAdvancedInfo.id).config.advancedData = webix.i18n.longDateFormatStr(customDayData.from);

        $$(innerObject.views.customDayAdvancedInfo.id).hide();
        // $$(innerObject.views.customDayAdvancedInfo.id).show();
        // $$(innerObject.views.customDayAdvancedInfo.id).refresh();

        // $$(innerObject.views.advancedOptionEventLabel.id).hide();

        $$(innerObject.views.typeCustomDaysDataView.id).select(customDayData.type);

        $$(innerObject.views.repeatCustomDayToggle.id).setValue(customDayData.repeat);
        $$(innerObject.views.repeatCustomDayTypeSelect.id).setValue(customDayData.repeatType || customDayData.repeat);

        $$(innerObject.views.repeatForeverCustomDayCheckBox.id).setValue(!customDayData.repeat_to);

        $$(innerObject.views.repeatCustomDayEndTimePicker.id).setValue(customDayData.repeat_to);

        $$(innerObject.views.repeatCustomDayLayout.id).show();

        if (customDayData.type === constantsCalendars.DAY_TYPES.CUSTOM) {
          innerObject.handlers.helpers.setLabelForHoursIntervals(customDayData.hours);
          innerObject.handlers.helpers.showWorkingHours();
        }
        if (customDayData.type === constantsCalendars.DAY_TYPES.WEEKEND) {
          innerObject.handlers.helpers.hideWorkingHours();
        }
      },
      checkUIToShowAdvancedButtons() {
        let // templatesList = customDayTemplatesModel.getUserTemplateList(),
          customDaysForProject = [];

        if (innerObject.settings.workMode === 'projectSettings') {
          // if (!$$(innerObject.views.customDaySaveAsButton.id).isVisible()) {
          customDaysForProject = customDaysModel.getDaysByActiveProject(innerObject.settings.ganttId);

          if (customDaysForProject.length) {
            $$(innerObject.views.customDaySaveAsButton.id).show();
            $$(innerObject.views.customDayClearButton.id).show();
          }
          // }
        }
      },
      getWebixViewByMonth(monthNumber, mainId) {
        if (!mainId) {
          mainId = innerObject.views.customDaysMainScrollView.id;
        }

        return $$(`${mainId}-customDayCalendar${monthNumber}`);
      },
      hideWorkingHours() {
        $$(innerObject.views.workingHoursLayout.id).hide();
        $$(innerObject.views.countWorkingHoursLayout.id).hide();
      },
      showWorkingHours() {
        if (innerObject.settings.mainId !== 'resCustomDaysMainScrollView') {
          $$(innerObject.views.workingHoursLayout.id).show();
        }
      },
      setLabelForHoursIntervals(hoursArray, selectedHours, intervalView) {
        let hoursSum = 0
        if (!hoursArray || !hoursArray.length) {
          hoursArray = innerObject.settings.workHoursDefault;
        }

        //const delimiter = ';';
        const stringIntervals = innerObject.handlers.helpers.getLabelForHours(hoursArray);
        //const hoursIntervals = stringIntervals.split(delimiter);
        const $$workingHoursIntervalLabel = intervalView || $$(innerObject.views.workingHoursIntervalLabel.id);

        $$workingHoursIntervalLabel.config.intervals = stringIntervals;
        $$workingHoursIntervalLabel.config.height = 38 * Math.ceil(hoursArray.length / 4);
        $$workingHoursIntervalLabel.resize();
        $$workingHoursIntervalLabel.refresh();

        innerObject.settings.hoursArray = hoursArray;

        if (!selectedHours) {
          selectedHours = timeIntervalHelper.getHoursArrayByIntervalArray(hoursArray, helperDate.getHoursArrayByUserTimeFormat());
        }

        if ($$workingHoursIntervalLabel.config.workMode) {
          zoomHoursPopup.handlers.updateHoursNumberLabel(
            $$(innerViews.newCustomDayWorkingHoursLabel.id + $$workingHoursIntervalLabel.config.workMode),
            selectedHours && typeof selectedHours !== 'string' && selectedHours.length > 0 ? selectedHours.length / 2 : 1,
          );

          innerHandlers.setLabelForHoursIntervals($$workingHoursIntervalLabel.config.workMode, hoursArray);

        }
        zoomHoursPopup.handlers.updateHoursNumberLabel($$(innerObject.views.workingHoursLabel.id), selectedHours && selectedHours.length > 0 ? selectedHours.length / 2 : 1);
      },
      getLabelForHours(hoursArray, delimiter) {
        return zoomHoursPopup.handlers.getLabelForIntervals(hoursArray, delimiter);
      },
      setDefaultSettingsForRepeat() {
        // $$(innerObject.views.repeatCustomDayLayout.id).hide();
        // $$(innerObject.views.repeatCustomDayTypeSelect.id).hide();
        // $$(innerObject.views.repeatCustomDayEndTimePicker.id).hide();
        // $$(innerObject.views.repeatForeverCustomDayCheckBox.id).hide();
        $$(innerObject.views.repeatCustomDayTypeSelect.id).setValue('week');
      },
      showRepeatWithCustomDaySettings(customDayData) {
        const $$repeatCustomDayToggle = $$(innerObject.views.repeatCustomDayToggle.id);

        $$(innerObject.views.repeatCustomDayLayout.id).show();
        $$repeatCustomDayToggle.config.value = null;
        $$repeatCustomDayToggle.setValue(customDayData.repeat);

        if (customDayData.repeat) {
          $$(innerObject.views.repeatForeverCustomDayCheckBox.id).show();
        }
        if (customDayData.repeat_to) {
          $$(innerObject.views.repeatForeverCustomDayCheckBox.id).setValue(false);
        } else {
          $$(innerObject.views.repeatForeverCustomDayCheckBox.id).setValue(true);
        }
        $$(innerObject.views.repeatCustomDayTypeSelect.id).setValue(customDayData.repeatType || customDayData.repeat);
      },
      showRepeatWithDefaultSettings() {
        // clear all previously settings
        $$(innerObject.views.repeatCustomDayLayout.id).show();
        $$(innerObject.views.repeatCustomDayToggle.id).show();
        $$(innerObject.views.repeatCustomDayLabel.id).show();
        $$(innerObject.views.repeatCustomDayToggle.id).setValue(false);

        innerObject.handlers.helpers.checkAndSetRepeatForAccessByPeriod();

        innerObject.handlers.helpers.setDefaultSettingsForRepeat();
      },
      checkAndSetRepeatForAccessByPeriod(newStartDate, oldStartDate) {
        const startTime = $$(innerObject.views.startPeriodTimePicker.id).getValue();
        const endTime = $$(innerObject.views.endPeriodTimePicker.id).getValue();
        const toggleButton = $$(innerObject.views.repeatCustomDayToggle.id);
        let webixUI = {};
        let oldWebxUI = {};

        if (!startTime) {
          return true;
        }

        if (toggleButton.isVisible() && !toggleButton.getValue() && startTime && endTime
          && startTime.valueOf() !== endTime.valueOf()) {
          toggleButton.define('css', 'tooltip-gantt');
          toggleButton.disable();
          toggleButton.setValue(false);
          innerObject.handlers.helpers.setDefaultSettingsForRepeat();
        } else {
          webix.html.removeCss(toggleButton.getNode(), 'tooltip-gantt');
          toggleButton.enable();
        }
        $$(innerObject.views.repeatCustomDayEndTimePicker.id).setValue(newStartDate);

        // check for select date in calendar

        webixUI = innerObject.handlers.helpers.getWebixViewByMonth(startTime.getMonth(), innerObject.settings.mainId);

        if (
          !innerObject.settings.editMode
          && (
            !webixUI.getSelectedDate()
            || (webixUI.getSelectedDate() && webixUI.getSelectedDate().valueOf() !== startTime.valueOf())
          )
        ) {
          // check if it's change start time for period
          if (oldStartDate) {
            oldWebxUI = innerObject.handlers.helpers.getWebixViewByMonth(oldStartDate.getMonth(), innerObject.settings.mainId);

            if (
              oldWebxUI.config.cacheData[oldStartDate]
              && oldWebxUI.config.cacheData[oldStartDate].eventType.indexOf('interval') !== -1
              && oldWebxUI.config.cacheData[oldStartDate].eventType.indexOf('start') !== -1
            ) {
              // to do something - at now - nothing
            } else {
              webixUI.blockEvent();
              webixUI.selectDate(startTime);
              innerObject.handlers.selectCalendarDay(startTime, startTime.getMonth(), true);
              webixUI.unblockEvent();
            }
          }
        }
      },
      initPopupWithHours(hoursArray, webixView) {
        const selectedHoursByIntervals = timeIntervalHelper.getHoursArrayByIntervalArray(hoursArray, helperDate.getHoursArrayByUserTimeFormat());
        const $$customDaysHoursDataView = $$(innerObject.views.customDaysHoursDataView.id);

        $$customDaysHoursDataView.clearAll();
        $$customDaysHoursDataView.parse(helperDate.getHoursArrayByUserTimeFormat().filter(i => i.id !== '24:00'));

        $$customDaysHoursDataView.config.intervalView = webixView;
        $$customDaysHoursDataView.blockEvent();
        $$customDaysHoursDataView.select(selectedHoursByIntervals);
        $$customDaysHoursDataView.unblockEvent();
      },
      initPopupWithSolutions() {
        const solutionsType = {
          eventRepeatSingle: [],
          eventInterval: [],
        };
        const repeatData = innerObject.handlers.helpers.getRepeatData();
        const eventCurrentType = $$(innerObject.views.typeCustomDaysDataView.id).getSelectedId();
        const optionRadioButtons = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id);

        optionRadioButtons.setValue('');
        $$(innerObject.views.customPopupWithOptions.buttonOk.id).disable();
        switch (innerObject.settings.dayTypeMode) {
        case 'eventRepeatSingleNewRepeatType':
          solutionsType.eventRepeatSingle = [
            { id: 'toRepeat', value: __('custom_days_solutions_apply_to_repeat') },
            { id: 'toCustomDay', value: __('custom_days_solutions_create_new_repeat') },
            { id: 'toSplitRepeat', value: __('custom_days_solutions_split_repeat') },
          ];
          optionRadioButtons.config.options = solutionsType.eventRepeatSingle;
          break;
        case 'eventRepeatSingle':
          if (repeatData.repeat) {
            solutionsType.eventRepeatSingle = [
              { id: 'toRepeat', value: __('custom_days_solutions_apply_to_repeat') },
              { id: 'toSplitRepeat', value: __('custom_days_solutions_split_repeat') },
            ];
          }
          if (!repeatData.repeat) {
            solutionsType.eventRepeatSingle = [
              { id: 'toRepeat', value: __('custom_days_solutions_apply_to_repeat') },
              { id: 'toCustomDay', value: __('custom_days_solutions_apply_to_custom_day') },
            ];
          }
          if (eventCurrentType === constantsCalendars.DAY_TYPES.WORKDAY) {
            solutionsType.eventRepeatSingle = [
              { id: 'toSplitRepeatNewDay', value: __('custom_days_solutions_split_repeat_new_day') },
              { id: 'deleteRepeat', value: __('custom_days_solutions_delete_repeat') },
            ];
            // optionRadioButtons.config.optionHeight = 40;
          }
          optionRadioButtons.data.options = solutionsType.eventRepeatSingle;
          break;
        case 'eventInterval':

          if (repeatData.repeat) {
            solutionsType.eventInterval = [
              { id: 'toSplitInterval', value: __('custom_days_solutions_split_interval') },
              { id: 'toCustomDay', value: __('custom_days_solutions_create_new_repeat') },
            ];
          }
          if (!repeatData.repeat) {
            solutionsType.eventInterval = [
              { id: 'toCustomDayInInterval', value: __('custom_days_solutions_apply_to_custom_day') },
              { id: 'toInterval', value: __('custom_days_solutions_apply_to_interval') },
            ];
          }
          if (eventCurrentType === constantsCalendars.DAY_TYPES.WORKDAY) {
            solutionsType.eventInterval = [
              { id: 'toSplitInterval', value: __('custom_days_solutions_split_interval') },
              { id: 'deleteInterval', value: __('custom_days_solutions_delete_interval') },
            ];
          }
          optionRadioButtons.config.options = solutionsType.eventInterval;
          break;
        }
        optionRadioButtons.enable();
        optionRadioButtons.show();
        optionRadioButtons.refresh();
        $$(innerObject.views.customPopupWithOptions.userTemplateListLayout.id).hide();
        $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).hide();
        $$(innerObject.views.customPopupWithOptions.main.id).show();

        optionRadioButtons.resize();
      },
      getNewIntervalFromToDates(direction) {
        let intervalFrom = {};
        let intervalTo = {};

        if (direction === 'end') {
          intervalFrom = new Date(innerObject.settings.customDayCache.from);
          intervalTo = new Date(innerObject.settings.customDayCache.to);
          intervalTo.setDate(intervalTo.getDate() - 1);

          if (intervalFrom.valueOf() === intervalTo.valueOf()) {
            intervalTo = null;
          }
        }
        if (direction === 'start') {
          intervalFrom = new Date(innerObject.settings.customDayCache.from);
          intervalTo = new Date(innerObject.settings.customDayCache.to);
          intervalFrom.setDate(intervalFrom.getDate() + 1);

          if (intervalFrom.valueOf() === intervalTo.valueOf()) {
            intervalTo = null;
          }
        }

        return { from: intervalFrom, to: intervalTo };
      },
      initPopupWithSolutionsForProjectSettings() {
        let solutions = [];
        let userTemplate = [];
        let projectSettingsData = [];
        const optionRadioButtonsUI = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id);

        optionRadioButtonsUI.setValue('');

        solutions = [
          { id: 'toCreateNewAndApplyUserTemplate', value: __('custom_days_solutions_save_settings_new_template') },
          { id: 'toApplyUserTemplate', value: __('custom_days_solutions_apply_new_template') },
        ];

        projectSettingsData = customDayTemplatesModel.getTemplateDataByGanttIdAndActive(innerObject.settings.ganttId);

        $$(innerObject.views.customPopupWithOptions.buttonOk.id).disable();
        if (projectSettingsData.id) {
          optionRadioButtonsUI.config.options = solutions;
          optionRadioButtonsUI.show();
          $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).show();
        } else {
          optionRadioButtonsUI.config.options = [
            { id: 'toApplyUserTemplate', value: __('custom_days_solutions_apply_new_template') },
          ];
          optionRadioButtonsUI.unblockEvent();
          optionRadioButtonsUI.setValue('toApplyUserTemplate');
          optionRadioButtonsUI.unblockEvent();
          optionRadioButtonsUI.hide();
          $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).hide();
        }

        optionRadioButtonsUI.config.options = solutions;
        optionRadioButtonsUI.refresh();

        userTemplate = customDayTemplatesModel.getUserTemplateList();

        $$(innerObject.views.customPopupWithOptions.userTemplatesList.id).clearAll();
        $$(innerObject.views.customPopupWithOptions.userTemplatesList.id).parse(userTemplate);
        $$(innerObject.views.customPopupWithOptions.userTemplateListLayout.id).show();

        $$(innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id).hide();
        optionRadioButtonsUI.disable();

        if (userTemplate.length === 1) {
          $$(innerObject.views.customPopupWithOptions.userTemplatesList.id).select(userTemplate[0].id);
          optionRadioButtonsUI.enable();
        }

        // TODO BUG webix - richselect hide and popup hide
        _.delay(() => {
          $$(innerObject.views.customPopupWithOptions.main.id).show();
        });
      },
      calcCustomDayPopupPosition(currentNode, $$customPopupDayOption) {
        if (!currentNode) return;

        $$customPopupDayOption = $$customPopupDayOption || $$(innerObject.views.customPopupDayOption.main.id);
        !$$customPopupDayOption.isVisible() && $$customPopupDayOption.show();

        let correctX;
        const popupNode = $$customPopupDayOption.getNode();
        const popupPositionInfo = popupNode.getBoundingClientRectWrapper();
        const nodePosition = currentNode.getBoundingClientRectWrapper();
        let correctY = nodePosition.top;
        const maxX = document.body.offsetWidth;
        const maxY = document.body.offsetHeight;
        const popupMaxWidth = popupPositionInfo.width || 478;
        const popupMaxHeight = 515;

        correctX = (currentNode.getBoundingClientRect().x + currentNode.offsetWidth / 2) - popupNode.offsetWidth / 2;

        if (maxY - nodePosition.top > popupNode.offsetHeight) {
          correctY = nodePosition.top + nodePosition.height;
        } else {
          correctY = nodePosition.top - popupNode.offsetHeight;
        }

        innerHandlers.removeActiveCustomDaysListCell();

        return {
          x: Math.abs(correctX),
          y: Math.abs(correctY),
        };
      },
      showPopupWithCustomDayOptions(webixNode, config) {
        const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);

        $$customPopupDayOption.config.workMode = innerObject.settings.mainId === 'resCustomDaysMainScrollView'
          ? constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR : constantsCalendars.LAYOUT_TYPES.PROJECT_CALENDAR;

        $$customPopupDayOption.config.customDayId = innerObject.settings.customDayId;
        $$customPopupDayOption.config.targetNode = webixNode;

        if (_.isEmpty(config) && webixNode) {
          const position = innerObject.handlers.helpers.calcCustomDayPopupPosition(webixNode, $$customPopupDayOption);

          $$customPopupDayOption.show(position);
        } else {
          $$customPopupDayOption.show(webixNode, config);
        }

        if (innerObject.settings.customDayCache && innerObject.settings.customDayCache.id) {
          innerObject.handlers.helpers.fillCustomDayPopup(innerObject.settings.customDayCache);
        }

        $$customPopupDayOption.resize();
      },
      showCustomDayEditPopup(options) {
        const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);
        const webixNode = options.node;
        const config = options.config;
        const data = options.data;
        let itemEditButton = webixNode.parentNode;

        if (!_.includes(itemEditButton.classList, 'custom-days-list-item-col-btn')) {
          itemEditButton = itemEditButton ? itemEditButton.querySelector('.custom-days-list-item-col-btn') : undefined;
        }

        innerObject.settings.editMode = true;

        $$customPopupDayOption.config.customDayId = data.id;
        $$customPopupDayOption.config.workMode = options.workMode;
        $$customPopupDayOption.config.targetNode = itemEditButton;

        if (_.isEmpty(config) && webixNode) {
          $$customPopupDayOption.show(innerObject.handlers.helpers.calcCustomDayPopupPosition(webixNode));
        } else {
          $$customPopupDayOption.show(webixNode, config);
        }

        if ($$customPopupDayOption.config.workMode === constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR) {
          $$(innerObject.views.countWorkingHoursLayout.id).show();
          $$(innerObject.views.workingHoursLayout.id).hide();
        } else {
          $$(innerObject.views.countWorkingHoursLayout.id).hide();
          $$(innerObject.views.workingHoursLayout.id).show();
        }

        if (innerObject.settings.editMode) {
          $$(innerObject.views.clearButton.id).show();
        } else {
          $$(innerObject.views.clearButton.id).hide();
        }

        innerObject.handlers.helpers.fillCustomDayPopup(data);
      },
      fillCustomDayPopup(data) {
        const $$startPeriodTimePicker = $$(innerObject.views.startPeriodTimePicker.id);
        const $$endPeriodTimePicker = $$(innerObject.views.endPeriodTimePicker.id);
        const $$datesDash = $$('сustomDayDatesDash');
        const $$typeCustomDaysDataView = $$(innerObject.views.typeCustomDaysDataView.id);
        const $$customPopupDayOption = $$(innerObject.views.customPopupDayOption.main.id);

        $$(innerObject.views.customDayTitle.id).setValue(null);

        if (data) {
          // set important variables
          innerObject.settings.customDayId = data.id;
          innerObject.settings.customDayCache = webix.copy(data);

          $$(innerObject.views.customDayTitle.id).setValue(data.title);

          if (data.from) {
            $$startPeriodTimePicker.blockEvent();
            $$startPeriodTimePicker.setValue(data.from);
            $$startPeriodTimePicker.unblockEvent();
          }

          // if (data.to) {
          //   $$endPeriodTimePicker.blockEvent();
          //   $$endPeriodTimePicker.setValue(data.to);
          //   $$endPeriodTimePicker.unblockEvent();
          // } else {
          //   $$endPeriodTimePicker.blockEvent();
          //   $$endPeriodTimePicker.setValue(data.from);
          //   $$endPeriodTimePicker.unblockEvent();
          // }

          // if (data.repeat) {
          //   webix.delay(() => {
          //     $$endPeriodTimePicker.hide();
          //     $$datesDash.hide();
          //   }, null, [], 50);
          // } else {
          //   webix.delay(() => {
          //     $$endPeriodTimePicker.show();
          //     $$datesDash.show();
          //   }, null, [], 50);
          // }

          if (!_.isUndefined(data.hours)) {
            data.type = data.type || ((data.hours === false || data.hours === '[]' || data.hours.length === 0) ? constantsCalendars.DAY_TYPES.WEEKEND : constantsCalendars.DAY_TYPES.CUSTOM);
            $$customPopupDayOption.config.dayType = data.type;
          }

          if (!isNaN(data.hours_count)) {
            if(data.hours_count === 0 && data.type === 'weekend') {
              $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value = 0.5;
            } else {

              $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value = data.hours_count;
            }
            $$(innerObject.views.countWorkingHoursIntervalLabel.id).resize();
            $$(innerObject.views.countWorkingHoursIntervalLabel.id).refresh();
          }
          $$typeCustomDaysDataView.select(data.type);
          innerObject.settings.currentHoursArray = data.hours;

          //* if single interval
          if (!data.repeat && data.to) {
            innerObject.handlers.helpers.initUIForNoneRepeatableIntervals(data);
          }
          //* if not repeatable single event
          if (!data.repeat && !data.to) {
            innerObject.handlers.helpers.initUIForNoneRepeatableSingle(data);
          }
          // if single repeatable event
          if (data.repeat && !data.to) {
            innerObject.handlers.helpers.initUIForRepeatableSingle(data);
            $$(innerObject.views.repeatCustomDayToggle.id).enable();
          }

          if (data.repeat && data.to) {
            $$endPeriodTimePicker.blockEvent();
            $$endPeriodTimePicker.setValue(data.from);
            $$endPeriodTimePicker.unblockEvent();
            $$(innerObject.views.repeatCustomDayToggle.id).enable();
          }

          $$(innerObject.views.customDayAdvancedInfo.id).hide();

          if (data.type === constantsCalendars.DAY_TYPES.WEEKEND) {
            innerObject.handlers.helpers.hideWorkingHours();
          }
          // else {
          //  $$(innerObject.views.countWorkingHoursLayout.id).show();
          // }
        }
      },
      removeTriangleDOM() {
        let triangleDOMs = document.getElementsByClassName('webix_point_top');

        if (!triangleDOMs.length) {
          triangleDOMs = document.getElementsByClassName('webix_point_bottom');
        }
        if (!triangleDOMs) {
          return false;
        }
        for (let domIndex = 0; domIndex < triangleDOMs.length; domIndex++) {
          triangleDOMs[domIndex].style.display = 'none';
        }
      },
    },
  },
  settings: {
    hoursArray: [],
    currentHoursArray: [],
    workHoursDefault: [],
    customDayId: 0,
    customDayCache: {},
    customDayOptionsView: {},
    dayTypeMode: '',
    workMode: '',
    userAccess: true,
    ganttId: 0,
    templateId: true,
  },
};

var innerViews = {
  addCustomDayLayout: {
    id: 'addCustomDayLayout',
  },
  addCustomDayBody: {
    id: 'addCustomDayBody',
  },
  newCustomDayTitleLayout: {
    id: 'newCustomDayTitleLayout',
  },
  newCustomDayTitle: {
    id: 'newCustomDayTitle',
    view: 'text',
    placeholder: __('custom_day_resources_title_placeholder'),
    // inputWidth: 464
  },
  newCustomDayLabel: {
    id: 'newCustomDayLabel',
    view: 'label',
    label: __('custom_day_resources_title_label'),
  },
  newCustomDayDatePickersLayout: {
    id: 'newCustomDayDatePickersLayout',
  },
  newCustomDayStartDatePicker: {
    id: 'newCustomDayStartDatePicker',
    view: 'datepickeredit',
    width: 192,
    value: new Date(),
    icon: 'fa-calendar',
    css: 'customDaySelect',
    on: {
      onChange(newValue, oldValue) {
        const startDatePicker = this;
        const endDatePicker = $$(innerViews.newCustomDayEndDatePicker.id + this.config.workMode);

        if (!startDatePicker || !endDatePicker) {
          return;
        }

        const isEmpty = (newValue === '');
        const isValid = newValue instanceof Date && !isNaN(newValue);

        if (!isValid || isEmpty) {
          this.blockEvent();
          this.setValue(oldValue);
          this.unblockEvent();

          webix.message({ type: 'warning', text: __('gantt_date_invalid') });
          this.config.isInvalid = true;

          return;
        }

        const value = helperDate.checkIfDateInLimit(newValue, true);

        if (value.getTime() !== newValue.getTime()) {
          this.blockEvent('onChange');
          this.setValue(value);
          this.unblockEvent('onChange');
        }

        const startTime = startDatePicker.getValue();
        const endTime = endDatePicker.getValue();

        if (startTime.valueOf() > endTime.valueOf() || $$(innerViews.newCustomDayRepeatToggle.id + this.config.workMode).getValue()) {
          endDatePicker.setValue(startTime);
          endDatePicker.refresh();
        }

        innerHandlers.checkAndSetRepeatForAccessByPeriodInForm(this.config.workMode, newValue, oldValue);
      },
      onEnter() {
        const popup = this.getPopup();

        this.callEvent('onBlur');

        if (popup.isVisible()) {
          popup.hide();
        }

        webix.UIManager.setFocus(document);
      },
    },
  },
  newCustomDayEndDatePicker: {
    id: 'newCustomDayEndDatePicker',
    view: 'datepickeredit',
    css: 'customDaySelect',
    width: 192,
    value: new Date(),
    icon: 'fa-calendar',
    on: {
      onChange(newValue, oldValue) {
        const isEmpty = (newValue === '');
        const isValid = newValue instanceof Date && !isNaN(newValue);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentPopup = this.config.workMode === 'Resource' ? "newCustomDayStartDatePickerResource" : 'newCustomDayStartDatePickerMain'
        const startDate =  $$(currentPopup).getValue();


        if (!isValid || isEmpty) {
          this.blockEvent();
          this.setValue(oldValue);
          this.unblockEvent();

          webix.message({ type: 'warning', text: __('gantt_date_invalid') });
          this.config.isInvalid = true;

          return;
        }

        const value = helperDate.checkIfDateInLimit(newValue, true);

        if (value.getTime() !== newValue.getTime()) {
          this.blockEvent('onChange');
          this.setValue(value);
          this.unblockEvent('onChange');
          newValue = value;
        }

        if(moment(newValue).startOf('day').isSame(moment(today).startOf('day'))) {
          if(moment(today).startOf('day') < moment(startDate).startOf('day')) {
            $$(currentPopup).setValue(newValue);
          }
        }

        innerHandlers.checkAndSetRepeatForAccessByPeriodInForm(this.config.workMode, newValue, oldValue);
      },
      onEnter() {
        const popup = this.getPopup();

        this.callEvent('onBlur');

        if (popup.isVisible()) {
          popup.hide();
        }

        webix.UIManager.setFocus(document);
      },
      "onBeforeRender": function () {
        let calendar = this.getPopup()
          .getBody();

        const currentPopup = this.config.workMode === 'Resource' ? "newCustomDayEndDatePickerResource" : 'newCustomDayStartDatePickerMain'
        const startDate =  $$(currentPopup).getValue();

        calendar.define("blockDates", function (date) {
          if (new Date(date).valueOf() < new Date(startDate).valueOf()) {
            return true;
          }

        });
      },
    },
  },
  newCustomDayType: {
    id: 'newCustomDayType',
    view: 'dataview',
    css: 'custom-days-option-dataview',
    multiselect: false,
    borderless: true,
    // xCount: 3,
    select: true,
    scroll: false,
    height: 36,
    // value: "",
    data: [
      {
        id: 'weekend',
        // $width: "50%",
        value: __('custom_days_type_weekend'),
        $css: 'custom-days-dataview-item-weekend',
      },
      {
        id: 'custom',
        // $width: "50%",
        value: __('custom_days_type_custom_day'),
        $css: 'custom-days-dataview-item-custom',
      },
    ],
    on: {
      onSelectChange(newValue, oldValue) {
        innerHandlers.changeDayType(newValue[0], this.config.workMode);
      },
    },
    type: {
      // width: 140,
      height: 36,
    },
  },
  newCustomDayRepeatLayout: {
    id: 'newCustomDayRepeatLayout',
  },
  newCustomDayRepeatToggleLayout: {
    id: 'newCustomDayRepeatToggleLayout',
  },
  newCustomDayRepeatLabel: {
    id: 'newCustomDayRepeatLabel',
    view: 'label',
    label: __('custom_days_repeat_title'),
    css: 'label-with-spacer',
    template: templates.getTemplateForLabelWithSpacer,
    width: 414,
  },
  newCustomDayRepeatToggle: {
    id: 'newCustomDayRepeatToggle',
    view: 'toggle',
    type: 'icon',
    width: 54,
    // borderless: true,
    toolTip: {
      langKey: 'project_settings_custom_calendar_tooltip_toggle_disabled',
    },
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.repeatChange(newValue, this.config.workMode);
      },
      onAfterRender() {
        this.getNode().setAttribute('data-key', this.config.toolTip.langKey);
      },
    },
  },
  newCustomDayRepeatSelectLayout: {
    id: 'newCustomDayRepeatSelectLayout',
  },
  newCustomDayRepeatSelect: {
    id: 'newCustomDayRepeatSelect',
    view: 'richselectWithoutPoint',
    label: __('custom_days_repeat_type_title'),
    // labelWidth: 110,
    labelWidth: 232, // 110
    value: 'week',
    options: [
      { id: 'week', value: __('custom_days_repeat_every_week') },
      { id: 'month', value: __('custom_days_repeat_every_mont') },
      { id: 'year', value: __('custom_days_repeat_every_year') },
    ],
  },
  newCustomDayRepeatForeverCheckbox: {
    id: 'newCustomDayRepeatForeverCheckbox',
    view: 'checkbox',
    value: 0,
    labelWidth: 0,
    labelRight: __('custom_days_repeat_forever_label'),
    width: 132,
    on: {
      onChange(newValue, oldValue) {
        innerHandlers.repeatForeverChange(newValue, this.config.workMode);
      },
    },
  },
  newCustomDayRepeatEndTimePicker: {
    id: 'newCustomDayRepeatEndTimePicker',
    view: 'datepicker',
    icon: 'fa-calendar',
    hidden: true,
    type: 'date',
    format: webix.i18n.longDateFormatStr,
    label: __('custom_days_repeat_to_label'),
    // labelWidth: 110,
    labelWidth: 232,
    // width: 284,
    value: new Date(),
    css: 'custom-day-popup-repeat-end',
    on: {
      onChange(newValue, oldValue) {
      },
    },
  },
  newCustomDayWorkingHoursLayout: {
    id: 'newCustomDayWorkingHoursLayout',
  },
  newCustomDayWorkingHoursCountLayout: {
    id: 'newCustomDayWorkingHoursCountLayout',
  },
  newCustomDayWorkingHoursCountLabel: {
    id: 'newCustomDayWorkingHoursCountLabel',
    view: 'label',
    label: __('custom_day_working_hours'),
    css: 'label-with-spacer',
    template: templates.getTemplateForLabelWithSpacer,
  },
  newCustomDayWorkingHoursLabel: {
    id: 'newCustomDayWorkingHoursLabel',
    view: 'label',
    label: __('custom_day_working_hours'),
    css: 'label-with-spacer',
    number: '8h',
    template: templates.getTemplateForLabelWithSpacerAndNumber,
  },
  newCustomDayWorkingHoursCountTitle: {
    view: 'template',
    id: 'newCustomDayWorkingHoursCountTitle',
    height: 36,
    css: 'resource-calendar-hours-title-table customDay',
    borderless: true,
    value: 0.5,
    template(obj) {
        let hourItem;
        if (this.value === 0 || this.value === '0') {
          hourItem = '0 h';
        } else {

          hourItem = hoursArray.find(item => item.id === +this.value).value;
        }
        return `<div class='dataview-hours-inline'>${hourItem}</div>`;
    },
    onClick: {
      'resource-calendar-hours-title-table': function () {
        $$(innerViews.resourceCustomHoursPopup.id).config.intervalView = $$('newCustomDayWorkingHoursCountTitleResource');
        innerHandlers.showHoursPopup($$('newCustomDayWorkingHoursCountLayoutResource').getNode());
      },
    },

  },
  newCustomDayWorkingHoursIntervalLabel: {
    view: 'template',
    id: 'newCustomDayWorkingHoursIntervalLabel',
    height: 38,
    css: 'hours-template',
    borderless: false,
    label: '',
    width: 466,
    template(obj) {
      let innerHtml = '';

      this.intervals.forEach(intervalString => {
        innerHtml += `<div class='dataview-hours-inline-item'>${intervalString}</div>`;
      });

      return `<div class='dataview-hours-inline'>${innerHtml}</div>`;
    },
    onClick: {
      'hours-template': function () {
        innerObject.handlers.hoursIntervalLabelClick(this.$view, this);
      },
    },
  },
  noExceptionsLayout: {
    id: 'no-exceptions',
  },
  cancelNewCustomDayButton: {
    id: 'cancelNewCustomDayButton',
    view: 'button',
    label: __('common_cancel'),
    width: 136,
    height: 36,
    css: 'button_default',
    on: {
      onItemClick() {
        const strategy = {
          Main: 'project',
          Resource: 'resource',
          Templates: 'template',
        };

        app.trigger(`cancel:customDay:${strategy[this.config.workMode]}`);
      },
    },
  },
  saveNewCustomDayButton: {
    id: 'saveNewCustomDayButton',
    view: 'button',
    label: __('common_save'),
    width: 136,
    height: 36,
    css: 'button_danger',
    on: {
      onItemClick() {
        innerHandlers.saveCustomDay(this.config.workMode);
      },
    },
  },
  resourceCustomHoursPopup: {
    id: 'customResourceHoursPopup',
  },
  displayResourceCustomOptionsHoursDataView: {
    view: 'dataview',
    id: 'displayResourceCustomOptionsHoursDataView',
    select: true,
    xCount: 6,
    yCount: 8,
    scroll: false,
    align: 'center',
    css: 'dataview-hours displayOptionsHoursDataView',
    height: 320,
    type: {
      width: 72,
      height: 37,
      template(obj) {
        return `<div class='dataview-hours-block'><div class='dataview-hours-item'>${obj.value}</div></div>`;
      },
    },
    data: [],
    on: {
      onItemClick(newV) {
        const hoursRangeTitleLabel = $$(innerViews.resourceCustomHoursPopup.id).config.intervalView;

          hoursRangeTitleLabel.config.value = newV;

        hoursRangeTitleLabel.refresh();
        $$(innerViews.resourceCustomHoursPopup.id).hide();
      },
    },

  },
};

var innerHandlers = {
  addViewsToDatePickersLayout(postfix) {
    const $$newCustomDayDatePickersLayout = $$(innerViews.newCustomDayDatePickersLayout.id + postfix);


    if(!$$($$newCustomDayDatePickersLayout.config.id).getChildViews().length) {
      $$newCustomDayDatePickersLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayStartDatePicker, postfix));
      $$newCustomDayDatePickersLayout.addView({
        view: 'label', label: '-', align: 'center', id: `newCustomDayDatesDash${postfix}`,
      });
      $$newCustomDayDatePickersLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayEndDatePicker, postfix));
    }

  },
  addViewsToRepeatLayout(postfix) {
    const $$newCustomDayRepeatLayout = $$(innerViews.newCustomDayRepeatToggleLayout.id + postfix);

    if(!$$($$newCustomDayRepeatLayout.config.id).getChildViews().length) {
      $$newCustomDayRepeatLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayRepeatLabel, postfix));
      $$newCustomDayRepeatLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayRepeatToggle, postfix));
    }

  },
  addViewsToRepeatSelectLayout(postfix) {
    const $$newCustomDayRepeatSelectLayout = $$(innerViews.newCustomDayRepeatSelectLayout.id + postfix);
    if(!$$($$newCustomDayRepeatSelectLayout.config.id).getChildViews().length) {
      $$newCustomDayRepeatSelectLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayRepeatSelect, postfix));
      // $$newCustomDayRepeatSelectLayout.addView({width: 12});
      $$newCustomDayRepeatSelectLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayRepeatForeverCheckbox, postfix));
    }

  },
  addViewsToBody(postfix) {
    const $$newCustomDayRepeatLayout = $$(innerViews.newCustomDayRepeatLayout.id + postfix);
    if(!$$($$newCustomDayRepeatLayout.config.id).getChildViews().length) {
      $$newCustomDayRepeatLayout.addView(innerHandlers.cloneView(innerViews.newCustomDayRepeatEndTimePicker, postfix), 2);
    }

  },
  addViewToForm(postfix) {
    innerHandlers.addViewsToDatePickersLayout(postfix);
    innerHandlers.addViewsToRepeatLayout(postfix);
    innerHandlers.addViewsToRepeatSelectLayout(postfix);
    innerHandlers.addViewsToBody(postfix);
    $$(innerViews.newCustomDayType.id + postfix).select(constantsCalendars.DAY_TYPES.WEEKEND);
  },
  cloneView(view, postfix) {
    return _.assign({}, view, { id: view.id + postfix, workMode: postfix });
  },
  createAddForm(postfix) {
    return _.assign({}, {
      id: innerViews.addCustomDayLayout.id + postfix,
      hidden: true,
      cols: [
        // {},
        {
          width: 468,
          borderless: true,
          // autoheight: true,
          body: {
            id: innerViews.addCustomDayBody.id + postfix,
            css: 'add-custom-day-form',
            rows: [
              {
                id: innerViews.newCustomDayTitleLayout.id + postfix,
                borderless: true,
                rows: [
                  innerHandlers.cloneView(innerViews.newCustomDayLabel, postfix),
                  innerHandlers.cloneView(innerViews.newCustomDayTitle, postfix),
                ],
              },
              { height: 12 },
              {
                id: innerViews.newCustomDayDatePickersLayout.id + postfix,
                borderless: true,
                cols: [],
              },
              { height: 12 },
              innerHandlers.cloneView(innerViews.newCustomDayType, postfix),
              { height: 12 },
              {
                borderless: true,
                id: innerViews.newCustomDayRepeatLayout.id + postfix,
                hidden: true,
                rows: [
                  {
                    id: innerViews.newCustomDayRepeatToggleLayout.id + postfix,
                    cols: [],
                  },
                  {
                    id: innerViews.newCustomDayRepeatSelectLayout.id + postfix,
                    paddingY: 12,
                    hidden: true,
                    rows: [],
                  },
                  { height: 12 },
                ],
              },
              {
                // borderless: true,
                id: innerViews.newCustomDayWorkingHoursLayout.id + postfix,
                hidden: true,
                autoheight: true,
                rows: [
                  innerHandlers.cloneView(innerViews.newCustomDayWorkingHoursLabel, postfix),
                  innerHandlers.cloneView(innerViews.newCustomDayWorkingHoursIntervalLabel, postfix),
                  { height: 12 },
                ],
              },
              {
                borderless: true,
                id: innerViews.newCustomDayWorkingHoursCountLayout.id + postfix,
                css: 'countWorkingHoursLayout',
                hidden: true,
                rows: [
                  {
                    cols: [
                      innerHandlers.cloneView(innerViews.newCustomDayWorkingHoursCountLabel, postfix),
                      { width: 24 },
                      innerHandlers.cloneView(innerViews.newCustomDayWorkingHoursCountTitle, postfix),
                    ],
                  },
                  { height: 12 },
                ],
              },
              {
                rows: [
                  {
                    cols: [
                      {},
                      innerHandlers.cloneView(innerViews.cancelNewCustomDayButton, postfix),
                      { width: 12 },
                      innerHandlers.cloneView(innerViews.saveNewCustomDayButton, postfix),
                    ],
                  },
                ],
              },
            ],
          },
        },
        // {}
      ],
    });
  },
  createNoExceptions(postfix) {
    return {
      id: innerViews.noExceptionsLayout.id + postfix,
      height: 260,
      cols: [
        {},
        {
          borderless: true,
          view: 'template',
          template() {
            return `${"<div class='calendar-layout-no-items'>"
              + "<div class='calendar-layout-no-items-icon'>"}${
              icon_no_exceptions
            }</div>`
              + `<div class='calendar-layout-no-items-text'>${
                __('no_exceptions')
              }</div>`
              + '</div>';
          },
        },
        {},
      ],
    };
  },
  checkAndSetRepeatForAccessByPeriodInForm(postfix, newStartDate, oldStartDate) {
    const startTime = $$(innerViews.newCustomDayStartDatePicker.id + postfix).getValue();
    const endTime = $$(innerViews.newCustomDayEndDatePicker.id + postfix).getValue();
    const toggleButton = $$(innerViews.newCustomDayRepeatToggle.id + postfix);
    const webixUI = {};
    const oldWebxUI = {};

    if (!startTime) {
      return true;
    }
    if (toggleButton.isVisible() && startTime && endTime
      && startTime.valueOf() !== endTime.valueOf()) {
      toggleButton.define('css', 'tooltip-gantt');
      toggleButton.disable();
      toggleButton.setValue(false);
      innerObject.handlers.helpers.setDefaultSettingsForRepeat();
    } else {
      webix.html.removeCss(toggleButton.getNode(), 'tooltip-gantt');
      toggleButton.enable();
    }
    $$(innerViews.newCustomDayRepeatEndTimePicker.id + postfix).setValue(newStartDate);
  },
  hideNoExceptions(postfix) {
    $$(innerViews.noExceptionsLayout.id + postfix).hide();
  },
  showNoExceptions(postfix) {
    $$(innerViews.noExceptionsLayout.id + postfix).show();
  },
  changeDayType(newType, postfix) {
    const workingHoursLayoutID = innerHandlers.getWorkingHoursLayoutID(postfix);

    if (newType === constantsCalendars.DAY_TYPES.WEEKEND) {
      $$(innerViews.newCustomDayRepeatLayout.id + postfix).show();
      $$(workingHoursLayoutID).hide();
    } else {
      $$(innerViews.newCustomDayRepeatLayout.id + postfix).show();
      $$(workingHoursLayoutID).show();
    }

    $$('newCustomDayRepeatSelectMain') && ($$('newCustomDayRepeatSelectMain').getNode().style = 'auto');
    $$('newCustomDayRepeatSelectLayoutMain') && ($$('newCustomDayRepeatSelectLayoutMain').getNode().style = 'auto');
    $$('newCustomDayRepeatLayoutMain') && ($$('newCustomDayRepeatLayoutMain').getNode().style = 'auto');
  },
  showAddingForm(postfix) {
    $$(innerViews.addCustomDayLayout.id + postfix).reconstruct();
    innerHandlers.addViewToForm(postfix);

    if (postfix !== constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR) {
      innerHandlers.initAddingForm(postfix);
    }
    webix.delay(() => {
      $$(innerViews.addCustomDayLayout.id + postfix).show();
    });
    innerHandlers.hideNoExceptions(postfix);
  },
  setLabelForHoursIntervals(postfix, hoursArray) {
    if (!hoursArray || !hoursArray.length) {

      hoursArray = innerObject.settings.workHoursDefault;
    }
    const delimiter = ',';
    const stringIntervals = innerHandlers.getLabelForHours(hoursArray, delimiter);
    const $$newCustomDayWorkingHoursLabel = $$(innerViews.newCustomDayWorkingHoursIntervalLabel.id + postfix);

    $$newCustomDayWorkingHoursLabel.config.intervals = stringIntervals;
    $$newCustomDayWorkingHoursLabel.config.height = 38 * Math.ceil(stringIntervals.length / 4);
    $$newCustomDayWorkingHoursLabel.refresh();
    $$newCustomDayWorkingHoursLabel.resize();

    innerObject.settings.hoursArray = hoursArray;
  },
  getWorkingHoursLayoutID(postfix) {
    return (postfix === constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR ? innerViews.newCustomDayWorkingHoursCountLayout.id : innerViews.newCustomDayWorkingHoursLayout.id) + postfix;
  },
  getLabelForHours(hoursArray, delimiter) {
    return zoomHoursPopup.handlers.getLabelForIntervals(hoursArray, delimiter);
  },
  initAddingForm(postfix) {
    innerHandlers.setLabelForHoursIntervals(postfix);
    const $$workingHoursIntervalLabel = $$(innerObject.views.workingHoursIntervalLabel.id);
    const selectedHours = timeIntervalHelper.getHoursArrayByIntervalArray(innerObject.settings.workHoursDefault, helperDate.getHoursArrayByUserTimeFormat());
    zoomHoursPopup.handlers.updateHoursNumberLabel(
      $$(innerViews.newCustomDayWorkingHoursLabel.id + postfix),
      selectedHours && typeof selectedHours !== 'string' && selectedHours.length > 0 ? selectedHours.length / 2 : 1,
    );
  },
  hideAddingForm(postfix) {
    $$(innerViews.addCustomDayLayout.id + postfix).hide();
    webix.delay(() => {
      $$(innerViews.addCustomDayLayout.id + postfix).reconstruct();
      innerHandlers.addViewToForm(postfix);
    });
  },
  prepareCustomDayFormData(postfix) {
    const customDay = {};

    customDay.type = $$(innerViews.newCustomDayType.id + postfix).getSelectedId();

    if (!customDay.type) {
      webix.message({ type: 'error', text: __('custom_day_form_fields_not_filled') });

      return;
    }

    const defaultTitle = __(`custom_day_default_title_${customDay.type}`);

    customDay.title = $$(innerViews.newCustomDayTitle.id + postfix).getValue() || defaultTitle;
    // customDay.resource_id = innerSettings.resourceId;
    if (customDay.title.length > 500) {
      webix.message({ type: 'error', text: __('custom_day_form_title_too_long') });

      return;
    }

    customDay.from = $$(innerViews.newCustomDayStartDatePicker.id + postfix).getValue();
    if ($$(innerViews.newCustomDayEndDatePicker.id + postfix).isEnabled()) {
      customDay.to = $$(innerViews.newCustomDayEndDatePicker.id + postfix).getValue();
    } else {
      customDay.to = null;
    }

    if (moment(customDay.from).format(constantsCalendars.DATE_FORMAT) === moment(customDay.to).format(constantsCalendars.DATE_FORMAT)
      || moment(customDay.from).isSameOrAfter(customDay.to)) {
      customDay.to = null;
    }

    if (postfix === constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR) {
      if ($$(innerViews.newCustomDayWorkingHoursCountLayout.id + postfix).isVisible()) {


        customDay.hours_count = $$(innerViews.newCustomDayWorkingHoursCountTitle.id + postfix).config.value;
      } else {
        customDay.hours_count = 0.5;
      }
    } else {
      if (customDay.type === constantsCalendars.DAY_TYPES.CUSTOM) {
        if (!innerObject.settings.currentHoursArray || !innerObject.settings.currentHoursArray.length) {
          customDay.hours = innerObject.settings.workHoursDefault;
        } else {
          customDay.hours = innerObject.settings.currentHoursArray;
        }
      } else {
        customDay.hours = [];
      }
      delete customDay.type;
    }

    if ($$(innerViews.newCustomDayRepeatToggle.id + postfix).getValue()) {
      customDay.repeat = $$(innerViews.newCustomDayRepeatSelect.id + postfix).getValue();

      if (!$$(innerViews.newCustomDayRepeatForeverCheckbox.id + postfix).getValue()) {
        customDay.repeat_to = $$(innerViews.newCustomDayRepeatEndTimePicker.id + postfix).getValue();
      } else {
        customDay.repeat_to = null;
      }
    }

    return customDay;
  },
  reconstructAddForm(postfix) {
    $$(innerViews.addCustomDayLayout.id + postfix).reconstruct();
  },
  repeatChange(newValue, postfix) {
    const endDatePicker = $$(innerViews.newCustomDayEndDatePicker.id + postfix);
    const datesDash = $$(`newCustomDayDatesDash${postfix}`);

    if (!$$(innerViews.newCustomDayRepeatForeverCheckbox.id + postfix).isEnabled()) {
      webix.message({ type: 'error', text: __('custom_days_cant_set_repeat') });
    }

    if (newValue) {
      $$(innerViews.newCustomDayRepeatSelectLayout.id + postfix).show();
      $$(innerViews.newCustomDayRepeatForeverCheckbox.id + postfix).setValue(true);
      $$(innerViews.newCustomDayRepeatSelect.id + postfix).setValue('week');
      $$(innerViews.newCustomDayRepeatSelect.id + postfix).show();

      $$(innerViews.newCustomDayRepeatSelect.id + postfix).getNode().style.height = 'auto';
      $$(innerViews.newCustomDayRepeatSelectLayout.id + postfix).getNode().style.height = 'auto';
      $$(innerViews.newCustomDayRepeatLayout.id + postfix).getNode().style.height = 'auto';

      // Hide endDate
      endDatePicker.hide();
      datesDash.hide();
    } else {
      $$(innerViews.newCustomDayRepeatSelectLayout.id + postfix).hide();
      $$(innerViews.newCustomDayRepeatSelect.id + postfix).hide();
      $$(innerViews.newCustomDayRepeatEndTimePicker.id + postfix).hide();

      // Show endDate
      endDatePicker.show();
      datesDash.show();
    }
  },
  repeatForeverChange(newValue, postfix) {
    if (!newValue) {
      $$(innerViews.newCustomDayRepeatEndTimePicker.id + postfix).show();
    } else {
      $$(innerViews.newCustomDayRepeatEndTimePicker.id + postfix).hide();
    }
  },
  saveProjectCalendar(customDay) {
    app.trigger('save:customDay:project', customDay);
  },
  saveResourceCalendar(customDay) {
    app.trigger('save:customDay:resource', customDay);
  },
  saveTemplateCalendar(customDay) {
    app.trigger('save:customDay:template', customDay);
  },
  saveCustomDay(workMode) {
    const strategy = {
      Main: innerHandlers.saveProjectCalendar,
      Resource: innerHandlers.saveResourceCalendar,
      Templates: innerHandlers.saveTemplateCalendar,
    };

    const startDatePicker = $$(innerViews.newCustomDayStartDatePicker.id + workMode);
    const endDatePicker = $$(innerViews.newCustomDayEndDatePicker.id + workMode);

    if (startDatePicker.config.isInvalid || endDatePicker.config.isInvalid) {
      startDatePicker.config.isInvalid = false;
      endDatePicker.config.isInvalid = false;

      return true;
    }

    const customDay = innerHandlers.prepareCustomDayFormData(workMode);

    customDay && strategy[workMode](customDay);
  },
  removeActiveCustomDaysListCell() {
    const $$customDaysList = $$('customDaysList');

    $$customDaysList.getNode().querySelectorAll('.webix_cell.active')
      .forEach(item => item.classList.remove('active'));
  },
  showHoursPopup(node) {
    $$(innerViews.resourceCustomHoursPopup.id).show(node);
  },
  showPopup(popup) {
    const $$displayOptionsHoursDataView = $$(innerViews.displayResourceCustomOptionsHoursDataView.id);

    let hourCount = $$(innerObject.views.countWorkingHoursIntervalLabel.id).config.value;

    if(popup.config.intervalView.config.id === 'newCustomDayWorkingHoursCountTitleResource') {
      hourCount = popup.config.intervalView.config.value;
    }
    $$displayOptionsHoursDataView.blockEvent();
    $$displayOptionsHoursDataView.clearAll();
    $$displayOptionsHoursDataView.parse(hoursArray);

    if(hourCount !== 0) {
      $$displayOptionsHoursDataView.select(hourCount);
    }
    $$displayOptionsHoursDataView.unblockEvent();
  },
};

// popup with work hours
webix.ui({
  view: 'popupWith6Padding',
  id: innerObject.views.customDaysHoursPopup.id,
  padding: 8,
  //height: 134,
  css: 'new_project_popup',
  body: {
    width: 472,
    height: 320,
    // type: 'clean',
    paddingX: 0,
    paddingY: 0,
    // paddingX: 10,
    borderless: true,
    // height: 120,
    rows: [
      {
        height: 320,
        css: 'displayOptionsHoursDataView',
        cols: [
          innerObject.views.customDaysHoursDataView,
        ],
      },
      //{ width: 428 },
    ],
  },
  on: {
    onShow() {
      innerObject.handlers.helpers.removeTriangleDOM();
    },
  },
}).hide();

// popup with solutions
webix.ui({
  view: 'ganttproWindowPopup',
  id: innerObject.views.customPopupWithOptions.main.id,
  css: 'new_project_popup',
  // header: false,
  head: false,
  body: {
    rows: [
      {
        view: 'form',
        autoheight: true,
        borderless: false,
        width: 450,
        paddingY: 36,
        paddingX: 36,
        elements: [
          {
            rows: [
              {
                id: innerObject.views.customPopupWithOptions.userTemplateListLayout.id,
                rows: [
                  innerObject.views.customPopupWithOptions.userTemplatesList,
                  { height: 24 },
                ],
              },
              innerObject.views.customPopupWithOptions.optionsRadioButton,
              {
                id: innerObject.views.customPopupWithOptions.newTemplateNameByProjectLayout.id,
                rows: [
                  { height: 24 },
                  innerObject.views.customPopupWithOptions.newTemplateNameByProjectText,
                ],
              },
            ],
          },
        ],
      },
      {
        height: 60,
        paddingX: 36,
        paddingY: 12,
        cols: [
          innerObject.views.customPopupWithOptions.buttonClose,
          {},
          innerObject.views.customPopupWithOptions.buttonOk,
        ],
      },
    ],
  },
  on: {
    onBeforeShow() {
      const optionRadioButtons = $$(innerObject.views.customPopupWithOptions.optionsRadioButton.id);

      optionRadioButtons.config.height = optionRadioButtons.config.options.length * 48;
    },
    onHide() {
    },
  },
}).hide();

// popup with project name
webix.ui({
  // view: "popupWithoutPadding",
  view: 'ganttproWindowPopup',
  id: innerObject.views.customPopupNewTemplate.main.id,
  // modal: true,
  // position: "center",
  css: 'custom-days-popup',
  // header: false,
  head: false,
  body: {
    borderless: true,
    width: 500,
    rows: [
      {
        view: 'form',
        width: 480,
        paddingX: 36,
        paddingY: 24,
        css: 'border-spacer',
        elements: [
          innerObject.views.customPopupNewTemplate.templateNameText,
        ],
      },
      {
        height: 60,
        paddingX: 36,
        paddingY: 12,
        cols: [
          innerObject.views.customPopupNewTemplate.buttonClose,
          {},
          innerObject.views.customPopupNewTemplate.buttonOk,
        ],
      },
    ],
  },
  on: {
    onShow() {
      // innerObject.handlers.showPopup();
    },
    onViewMoveEnd: () => {
    },
    onHide: () => {

    },
  },
}).hide();

// popup with day options
webix.ui({
  view: 'popupWithoutPoint',
  id: innerObject.views.customPopupDayOption.main.id,
  modal: false,
  css: 'custom-days-option',
  head: false,
  body: {
    paddingX: 0,
    paddingY: 0,
    // width: 478,
    // width: 518,
    borderless: true,
    rows: [
      {
        view: 'form',
        autoheight: true,
        borderless: false,
        width: 500,
        margin: 0,
        elements: [
          innerObject.views.customDayTitle,
          {
            paddingY: 16,
            autoheight: true,
            rows: [
              {
                height: 36,
                cols: [
                  innerObject.views.startPeriodTimePicker,
                  {
                    view: 'label', label: '-', align: 'center', id: 'сustomDayDatesDash',
                  },
                  innerObject.views.endPeriodTimePicker,
                ],
              },
              innerObject.views.customDayAdvancedInfo,
            ],
          },
          innerObject.views.typeCustomDaysDataView,
          {
            paddingY: 16,
            id: innerObject.views.repeatCustomDayLayout.id,
            hidden: true,
            rows: [
              {
                id: innerObject.views.repeatCustomDayToggleLayout.id,
                cols: [
                  innerObject.views.repeatCustomDayLabel,
                  innerObject.views.repeatCustomDayToggle,
                ],
              },
              {
                id: innerObject.views.repeatCustomDaySelectLayout.id,
                paddingY: 12,
                hidden: true,
                height: 90,
                rows: [
                  {
                    height: 36,
                    ...innerObject.views.repeatCustomDayTypeSelect,
                  },
                  {
                    height: 54,
                    rows: [
                      { height: 18 },
                      { ...innerObject.views.repeatForeverCustomDayCheckBox },
                    ],
                  },
                ],
              },
            ],
          },
          innerObject.views.repeatCustomDayEndTimePicker,
          {
            id: innerObject.views.workingHoursLayout.id,
            hidden: true,
            rows: [
              innerObject.views.workingHoursLabel,
              innerObject.views.workingHoursIntervalLabel,
            ],
          },
          {
            borderless: true,
            id: innerObject.views.countWorkingHoursLayout.id,
            hidden: true,
            css: 'countWorkingHoursLayout',

            rows: [
              {
                cols: [
                  innerObject.views.countWorkingHoursLabel,
                  { width: 24 },
                  innerObject.views.countWorkingHoursIntervalLabel,
                ],
              },
              { height: 12 },
            ],
          },
        ],
      },
      {
        height: 60,
        paddingX: 24,
        paddingY: 12,
        borderless: false,
        cols: [
          innerObject.views.clearButton,
          {},
          innerObject.views.buttonOk,
        ],
      },
    ],
  },
  on: {
    onShow() {
      if (this.config.workMode && this.config.workMode === constantsCalendars.LAYOUT_TYPES.RESOURCE_CALENDAR) {
        $$(innerObject.views.workingHoursLayout.id).hide();
      } else {
        $$(innerObject.views.countWorkingHoursLayout.id).hide();
      }

      if (this.config.dayType && this.config.dayType === constantsCalendars.DAY_TYPES.WEEKEND) {
        $$(innerObject.views.workingHoursLayout.id).hide();
        $$(innerObject.views.countWorkingHoursLayout.id).hide();
      }
    },
    onHide: innerHandlers.removeActiveCustomDaysListCell,
  },
}).hide();

webix.ui({
  view: 'popupWith6Padding',
  id: innerViews.resourceCustomHoursPopup.id,
  padding: 12,
  body: {
    width: 472,
    // type: 'clean',
    paddingX: 0,
    paddingY: 0,
    borderless: true,
    rows: [
      {
        height: 320,
        css: 'displayOptionsHoursDataView',
        body:
        innerViews.displayResourceCustomOptionsHoursDataView,
      },
    ],
  },
  on: {
    onShow() {
      innerHandlers.showPopup(this);
    },
    onHide() {
      $$(innerViews.resourceCustomHoursPopup.id).hide();
      // innerHandlers.changeDays();
    },
  },
}).hide();

const outputObject = {
  init: {
    run: innerObject.init.run,
    initCustomDayTemplates: innerObject.init.initCustomDayTemplates,
    changeUserTemplate: innerObject.handlers.changeUserTemplate,
    createAddForm: innerHandlers.createAddForm,
    createNoExceptions: innerHandlers.createNoExceptions,
    addViewToForm: innerHandlers.addViewToForm,
  },
  views: {
    customDaysMainScrollView: innerObject.handlers.createCalendarLayout({
      mainId: innerObject.views.customDaysMainScrollView.id,
    }),
  },
  handlers: {
    buttonRenameUserTemplateClick: innerObject.handlers.buttonRenameUserTemplateClick,
    buttonCopyUserTemplateClick: innerObject.handlers.buttonCopyUserTemplateClick,
    buttonDeleteUserTemplateClick: innerObject.handlers.buttonDeleteUserTemplateClick,
    buttonNewUserTemplateClick: innerObject.handlers.buttonNewUserTemplateClick,
    showAddingForm: innerHandlers.showAddingForm,
    hideAddingForm: innerHandlers.hideAddingForm,
    showNoExceptions: innerHandlers.showNoExceptions,
    hideNoExceptions: innerHandlers.hideNoExceptions,
    reconstructAddForm: innerHandlers.reconstructAddForm,
    clearAllCache: innerObject.handlers.helpers.clearAllCache,
  },
  helpers: {
    createCalendarLayout: innerObject.handlers.createCalendarLayout,
    changeYear: innerObject.handlers.changeYear,
    showPopupWithCustomDayOptions: innerObject.handlers.helpers.showPopupWithCustomDayOptions,
    showCustomDayEditPopup: innerObject.handlers.helpers.showCustomDayEditPopup,
    clearCalendarCache: innerObject.handlers.helpers.clearCalendarCache,
    initPopupWithSolutionsForProjectSettings: innerObject.handlers.helpers.initPopupWithSolutionsForProjectSettings,
    buttonSaveAsClick: innerObject.handlers.buttonSaveAsClick,
    resetSelectedAndHideTemplatePopup: innerObject.handlers.resetSelectedAndHideTemplatePopup,
    changeMainId(newMainId, resourceID) {
      innerObject.settings.mainId = newMainId;
      innerObject.settings.customDayId = null;
      innerObject.settings.resourceID = resourceID;
    },
    updateCustomDays(customDays) {
      innerObject.settings.customDays = customDays;
    },
  },
};

app.on('customDays:projectSettings:init', templateId => {
  if (innerObject.settings.workMode === 'projectSettings') {
    innerObject.init.initCustomDayProject();
  } else {
    innerObject.init.initCustomDayTemplates(templateId);
  }
});
app.on('customDays:init', () => {
  innerObject.handlers.helpers.clearAllCache();
});
app.on('customDays:insert', () => {
  innerObject.handlers.helpers.clearAllCache();
  // app.trigger('gantt:reinitConfig');
  // innerObject.handlers.helpers.checkUIToShowAdvancedButtons();
});
app.on('customDays:update', () => {
  innerObject.handlers.helpers.clearAllCache();
  // app.trigger('gantt:reinitConfig');
});
app.on('customDays:delete', () => {
  innerObject.handlers.helpers.clearAllCache();
  innerObject.init.initUIButtonsForProjectTemplate();
  // app.trigger('gantt:reinitConfig');
});
app.on('customDays:live:update', ganttId => {
  if ($$(innerObject.views.customDaysPopup.id) && $$(innerObject.views.customDaysPopup.id).isVisible()) {
    innerObject.init.run(ganttId, true);
  }
});

app.on('resources:model:changeWorkCustomDays', resourceIds => {
  innerObject.handlers.helpers.clearAllCache();
  gantt.ganttWorker.calculate(resourceIds[0] || resourceIds, 'afterChangeResourceCalendar', 'massCalculateTasksDuration');
});

app.on('templateEditor:close', () => {
  innerObject.settings.templateId = true;
})
app.on('updateCalendarMonths', (mainId) => {
    let webixUI = {};
    let calendarYear = {};

    for (let i = 0; i < 12; i++) {
      webixUI = innerObject.handlers.helpers.getWebixViewByMonth(i, mainId);
      webixUI.config.cacheData = {};
      calendarYear = new Date(webixUI.config.date);
      calendarYear.setDate(1);
      calendarYear.setFullYear(calendarYear.getFullYear());
      webixUI.config.date = calendarYear;
      webixUI.refresh();
    }
  }
)
app.on('settings:zoom:update:label', data => {
  innerObject.settings.workHoursDefault = zoomHoursPopup.handlers.getNewHoursRange();
  innerHandlers.initAddingForm('Main');
});

export default outputObject;
