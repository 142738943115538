var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-team" },
    [
      _c("div", { staticClass: "settings-section" }, [
        _c("div", { staticClass: "main-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.locales("team_screen_caption")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.locales("team_screen_description")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "team-editor" }, [
          _c("div", { staticClass: "team-info" }, [
            _c(
              "div",
              { staticClass: "team-img", on: { click: _vm.showLogoUploader } },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.teamLogo, alt: "" },
                }),
                _vm._v(" "),
                !_vm.isShowResetBtn
                  ? _c("div", { staticClass: "overlay" }, [
                      _c("img", { attrs: { src: _vm.overlayIcon, alt: "" } }),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: ["team-name", "wide"] },
              [
                _c("v-input", {
                  attrs: {
                    css: "profile-settings-input",
                    label: _vm.locales("team_name_label"),
                    value: _vm.teamName,
                    "handle-change": _vm.changeTeamName,
                  },
                }),
                _vm._v(" "),
                _vm.faviconImg && _vm.teamLogo === _vm.defaultLogo
                  ? _c(
                      "div",
                      { staticClass: "favicon-layout" },
                      [
                        _c("vgp-checkbox", {
                          attrs: { checked: !!_vm.isShowFavicon },
                          on: { onClick: _vm.toggleFavicon },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text favicon-checkbox-label" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.locales("show_team_logo_favicon")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "favicon-img",
                          attrs: { src: _vm.faviconImg },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isShowResetBtn
            ? _c(
                "div",
                { staticClass: "reset-logo-btn", on: { click: _vm.resetLogo } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.locales("team_screen_reset_settings")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("delimiter"),
      _vm._v(" "),
      _vm.isShowStoreValue
        ? _c("div", { staticClass: "settings-section" }, [
            _c("div", { staticClass: "main-title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("settings_storage_title")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "file-store-row" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.locales("settings_fileStoreUsed")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("spaceSize"),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("image-uploader", {
        attrs: {
          show: _vm.isShowLogoUploader,
          title: _vm.locales("upload_team_logo_title"),
          type: "teamLogo",
        },
        on: {
          onClose: function ($event) {
            _vm.isShowLogoUploader = false
          },
          onApply: _vm.onUploadLogo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }