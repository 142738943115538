var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.skeleton__header }, [
    _c("div", { class: _vm.$style.skeleton__title }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.title ||
              _vm.locales("onboarding_wizard_project_name_placeholder")
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { class: _vm.$style.skeleton__nav },
      _vm._l(_vm.navItems, function (item) {
        return _c(
          "li",
          {
            key: item.value,
            class: [
              _vm.$style.nav__item,
              item.value === _vm.activeNavItem
                ? _vm.$style.nav__item_active
                : "",
            ],
          },
          [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }