let mpp = {};

mpp.linkGanttIdWithImportedMpp = function (ganttId, mppToken) {
  // if(user.mpp && user.mpp.token){
    webix.ajax().post('/api/mpp/linkGanttIdWithImportedMpp', {
      ganttId,
      mppToken
    }, function (data) {});
  //}
};

export default mpp;

