import usersAttachmentTypeModel from '../../../../models/usersAttachmentType';
import globalStore from '../../../../store/main';
import status from '$$helpers/status';
import projectsModel from '../../../../models/projects';
import constants from '$$helpers/constants';
import google_drive_folder_icon from '../../../../svg/googleDrive/round-folder-24px.svg';
import google_drive_visibility_icon from '../../../../svg/googleDrive/outline-visibility-24px.svg';
import google_drive_icon from '../../../../svg/googleDrive/google_drive.svg';
import app from '../../../../app';
import { getMimeTypeIcon, getUrlPartByMimeType } from '../../helpers/googleDrive';

const _webixViewsEvents = {
  onContinueFileCreationClick: () => {},
  onAttachmentPreviewOpenExternalClick: () => {},
};

const _webixViews = {
  fileCreateDescriptionPopup: {
    view: 'ganttproWindowPopup',
    // position: 'center',
    id: 'fileCreateDescriptionPopup',
    zIndex: 1000,
    width: 600,
    height: 320,
    // head: false,
    body: {
      css: 'grey-background',
      rows: [
        {
          view: 'scrollview',
          scroll: 'y',
          css: 'grey-background',
          borderless: true,
          autoheight: true,

          body: {
            rows: [
              {
                view: 'template',
                css: 'attachment_external_file_create',
                id: 'fileCreateDescriptionPopupBody',
                borderless: true,
                template: '',
              },
              {
                css: '',
                id: 'fileCreateDescriptionFooter',
                height: 60,
                padding: 12,
                // paddingY: 14,
                // paddingX: 12,
                cols: [
                  {
                    id: 'fileCreateDescriptionCheckbox',
                    css: 'fileCreateDescriptionCheckbox',
                    labelWidth: 0,
                    width: 270,
                    height: 36,
                    view: 'checkbox',
                    labelRight: __('google_drive_file_create_descrioption_dont_show_again'),
                    value: 0,
                    on: {
                      onItemClick() {
                        globalStore.dispatch('user/updateActivityStatus', {
                          activityName: constants.USER_ACTIVITIES.HELP_GOOGLE_DRIVE,
                          status: 1,
                        });
                        // learningCenterModel.enableFeature(LC_ID_HELP_GOOGLE_DRIVE);
                        userExtAnalytics.log('gantt_task_google_drive_create_file_description_popup_dont_show_checkbox_click');
                      },
                    },
                  },
                  {
                    view: 'button',
                    type: 'success',
                    align: 'center',
                    id: 'fileCreateDescriptionFooterOk',
                    width: 270,
                    // paddingY: 12,
                    height: 36,
                    inputHeight: 36,
                    value: __('google_drive_file_create_descrioption_continue'),
                    on: {
                      onItemClick() {
                        _webixViewsEvents.onContinueFileCreationClick();
                        userExtAnalytics.log('gantt_task_google_drive_create_file_description_popup_continue_edit_button_click');
                      },
                    },
                  },
                  {},
                ],
              },
            ],
          },
        },
      ],
    },
  },
  previewPopup: {
    view: 'ganttproWindowPopup',
    css: 'attachments_external',
    id: 'externalFilePreview',
    zIndex: 1001,
    header: {
      icon: google_drive_icon,
      label: 'Google Drive',
    },
    position(state) {
      const padding = 50;

      state.width = window.document.body.clientWidth - padding * 2;
      state.height = window.document.body.clientHeight - padding * 2;
      state.left = padding;
      state.top = padding;
    },

    body: {
      css: 'grey-background',
      rows: [
        {
          view: 'scrollview',
          scroll: 'y',
          css: 'grey-background',
          borderless: true,
          autoheight: true,

          body: {
            rows: [
              {
                borderless: false,
                height: 1,
              },
              {
                view: 'iframe',
                id: 'externalFilePreviewFrame',
                css: 'external_file_preview_frame',
                borderless: true,
              },
              {
                view: 'template',
                css: 'attachment_external_preview_footer',
                id: 'attachmentExternalPreviewFooter',
                borderless: false,
                height: 60,
                template: `<div class='attachment_preview_open'>
                            <div class='attachment_preview_open_external'>
                              ${__('attachment_preview_open_external')}
                            </div>
                          </div>`,
                onClick: {
                  attachment_preview_open_external(e, r, t) {
                    _webixViewsEvents.onAttachmentPreviewOpenExternalClick();
                    userExtAnalytics.log('gantt_task_google_drive_preview_file_popup_continue_edit_button_click');
                  },
                },
              },

            ],
          },
        },
      ],
    },

    on: {
      onHide() {
        app.trigger('popup:hide');
      },

      onShow() {
        const iframe = $$('externalFilePreviewFrame').getIframe();

        iframe.setAttribute('allowFullScreen', '');
      },
    },
  },
};

const GoogleDriveAttachmentsController = {

  loadGoogleApiAndSetGoogleUser() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.onload = () => {
        window.gapi.load('client', async () => {
          try {
            await this.loadGoogleAuthApi();
            await this.loadGooglePickerApi();
            this.setGoogleUserToStore();
            resolve();
          } catch (e) {
            reject(e);
          }
        });
      };
      script.src = 'https://apis.google.com/js/client.js';
      document.body.appendChild(script);
    });
  },

  createDocument(mimeType, taskData) {
    return new Promise((resolve, reject) => {
      Promise.resolve()
        .then(this.checkGoogleAuthApiLoaded)
        .then(this.checkGooglePickerApiLoaded)
        .then(this.checkGoogleTokenValidity)
        .then(this.authoriseIfRequired)
        .then(() => ({ mimeType, taskData }))
        .then(this.createServerDocument)
        .then(this.onDocumentCreated)
        .then(resolve)
        .catch(reject);
    });
  },

  createServerDocument({ mimeType, taskData }) {
    return new Promise(async (resolve, reject) => {
      status.handlers.showMainSpinner(false, 10000);
      const project = projectsModel.getProjectDataById(taskData.gantt_id);
      const googleAttachmentUser = globalStore.getters['attachments/getGoogleUser'];
      const serverDocuemntData = {
        mimeType,
        parent: googleAttachmentUser.folder_id, // "1cKvpFiy1Sr5w7IN3sZjg9xkW97Md7xW_",
        access_token: googleAttachmentUser.access_token,
        name: `${taskData.text} (${project.name})`,
      };

      try {
        const createdDocument = await usersAttachmentTypeModel.createDocument(serverDocuemntData);
        const out = {
          ganttId: taskData.gantt_id,
          taskId: taskData.id,
          type: 'googleDrive',
          raw: JSON.stringify([createdDocument]),
        };

        await globalStore.dispatch('attachments/createAttachmentFromExternalProvider', out);
        resolve(createdDocument);
      } catch (e) {
        console.error('createDocument error', e);
      }
    });
  },

  onDocumentCreated(document) {
    status.handlers.hideMainSpinner();
    if (!globalStore.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.HELP_GOOGLE_DRIVE)) {
      GoogleDriveAttachmentsController.showFileCreateDescriptionPopup(document);
    } else {
      GoogleDriveAttachmentsController.openNewCreatedFileInNewTab(document);
    }
  },

  showFileCreateDescriptionPopup(item) {
    _webixViews.fileCreateDescriptionPopupUI = _webixViews.fileCreateDescriptionPopupUI || webix.ui(_webixViews.fileCreateDescriptionPopup);
    const googleAttachmentUser = globalStore.getters['attachments/getGoogleUser'];
    const folderName = googleAttachmentUser?.folder_name ? googleAttachmentUser.folder_name : __('google_drive_root_folder');

    $$('fileCreateDescriptionPopupBody').define('template', `
    <ul>
      <li>${__('google_drive_file_create_descrioption_1_1')}<strong>${getMimeTypeIcon(item.mimeType)}<span>${item.name}</span></strong>${__('google_drive_file_create_descrioption_1_2')}</li>
      <li>${__('google_drive_file_create_descrioption_2_1')}<strong>${google_drive_folder_icon}<span>${folderName}</span></strong>${__('google_drive_file_create_descrioption_2_2')} ${__('google_drive_whom')}. ${__('google_drive_file_create_descrioption_2_3')}</li>
      <li>${__('google_drive_file_create_descrioption_3_1')}<strong>${google_drive_visibility_icon}<span>${__('google_drive_view')}</span></strong> ${__('google_drive_file_create_descrioption_3_2')}</li>
    </ul>
    `);
    $$('fileCreateDescriptionPopupBody').refresh();
    _webixViewsEvents.onContinueFileCreationClick = () => {
      GoogleDriveAttachmentsController.openNewCreatedFileInNewTab(item);
      $$('fileCreateDescriptionPopup').hide();
    };
    _webixViews.fileCreateDescriptionPopupUI.show();
    userExtAnalytics.log('gantt_task_google_drive_create_file_description_popup_open');
  },

  showAndLoadPreviewPopup(item) {
    _webixViews.previewPopupUI = _webixViews.previewPopupUI || webix.ui(_webixViews.previewPopup);
    const url = `https://drive.google.com/open?id=${item.attacherExternalAccessToken}`;
    const previewUrl = `https://docs.google.com/${getUrlPartByMimeType(item.mimeType)}/d/${item.attacherExternalAccessToken}/preview`;
    const frame = $$('externalFilePreviewFrame');
    const popup = $$('externalFilePreview');

    webix.extend(frame, webix.ProgressBar);
    _webixViews.previewPopupUI.updateHead(getMimeTypeIcon(item.mimeType), item.name);
    frame.load('');
    if (frame.isVisible()) frame.hide();
    _webixViews.previewPopupUI.show();
    setTimeout(() => {
      frame.show();
      frame.showProgress({ type: 'icon' });
      frame.load(previewUrl);
      userExtAnalytics.log('gantt_task_google_drive_preview_file_popup_open');
      _webixViewsEvents.onAttachmentPreviewOpenExternalClick = () => {
        popup.hide();
        window.open(url, '_blank');
      };
    }, 200);
  },

  openNewCreatedFileInNewTab(item) {
    const url = `https://drive.google.com/open?id=${item.id}`;

    window.open(url, '_blank');
  },

  pickFiles(taskData) {
    return new Promise((resolve, reject) => {
      Promise.resolve()
        .then(this.checkGoogleAuthApiLoaded)
        .then(this.checkGooglePickerApiLoaded)
        .then(this.checkGoogleTokenValidity)
        .then(this.authoriseIfRequired)
        .then(this.createFilePicker)
        .then(selectedFilesData => ({ selectedFilesData, taskData }))
        .then(this.onFilesPicked)
        .then(resolve)
        .catch(reject);
    });
  },

  checkGoogleAuthApiLoaded() {
    return new Promise(resolve => {
      if (globalStore.getters['attachments/isGoogleAuthApiLoaded']) {
        return resolve();
      }

      return GoogleDriveAttachmentsController.loadGoogleAuthApi().then(() => {
        resolve();
      });
    });
  },

  checkGooglePickerApiLoaded() {
    return new Promise(resolve => {
      if (globalStore.getters['attachments/isGooglePickerApiLoaded']) {
        return resolve();
      }

      return GoogleDriveAttachmentsController.loadGooglePickerApi().then(() => {
        resolve();
      });
    });
  },

  checkGoogleTokenValidity() {
    return new Promise(async (resolve, reject) => {
      const googleAttachmentUser = globalStore.getters['attachments/getGoogleUser'];

      if (!googleAttachmentUser || !googleAttachmentUser.access_token) {
        return resolve({ isValidToken: false });
      }

      try {
        const profile = await usersAttachmentTypeModel.getProfileByToken({
          access_token: googleAttachmentUser.access_token,
          attachmentType: 'googleDrive',
        });

        if (profile.expired) {
          return resolve({ isValidToken: false });
        }

        return resolve({ isValidToken: true });
      } catch (e) {
        console.error('Get Google Profile By Token', e);

        return resolve({ isValidToken: false });
      }
    });
  },

  authoriseIfRequired(settings) {
    return new Promise((resolve, reject) => {
      if (settings.isValidToken) return resolve();

      window.gapi.auth.authorize({
        client_id: GT.attachmentExternal.googleDrive.clientId,
        scope: GT.attachmentExternal.googleDrive.scope,
        // immediate: true,
        authuser: -1,
        prompt: 'select_account',
      }, async authResult => {
        if (!authResult) {
          console.error('googleHandleAuthResults2', authResult);

          return reject(authResult);
        }

        if (authResult.error) {
          console.error('googleHandleAuthResults1', authResult.error);

          return reject(authResult.error);
        }

        try {
          const profile = await usersAttachmentTypeModel.getProfileByToken({
            access_token: authResult.access_token,
            attachmentType: 'googleDrive',
          });
          const googleAttachmentUser = globalStore.getters['attachments/getGoogleUser'];
          const isAccountNewOrChanged = !googleAttachmentUser || (profile.email !== googleAttachmentUser.email);

          usersAttachmentTypeModel.addUsersAttachmentType({
            access_token: authResult.access_token,
            expires_at: authResult.expires_at,
            rawtoken: JSON.stringify({
              login_hint: authResult.login_hint,
              scope: authResult.scope,
              status: authResult.status,
              issued_at: authResult.issued_at,
            }),
            rawprofile: JSON.stringify(profile),
            email: profile.email,
            folder_id: isAccountNewOrChanged ? null : googleAttachmentUser.folder_id,
            folder_name: isAccountNewOrChanged ? null : googleAttachmentUser.folder_name,
            rawfolder: isAccountNewOrChanged ? null : googleAttachmentUser.rawfolder,
            key: 'googleDrive',
          });
          GoogleDriveAttachmentsController.setGoogleUserToStore();

          return resolve();
        } catch (e) {
          console.error('Get Google Profile By Token', e);

          return reject(e);
        }
      });
    });
  },

  createFilePicker() {
    return new Promise(async resolve => {
      const googleAttachmentUser = globalStore.getters['attachments/getGoogleUser'];

      const currentFolderView = (new google.picker.DocsView(google.picker.ViewId.DOCS))
        .setIncludeFolders(true)
        .setOwnedByMe(true)
        .setParent(googleAttachmentUser.folder_id || __('google_drive_root_folder'))
        .setLabel(googleAttachmentUser.folder_name);

      const myFolderView = (new google.picker.DocsView(google.picker.ViewId.DOCS))
        .setIncludeFolders(true)
        .setOwnedByMe(true)
        // .setParent(googleAttachmentUser.folder_id || 'root')
        .setLabel(__('settings_google_folder_my_label'));

      let picker = new google.picker.PickerBuilder();

      picker = picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
      picker = picker.enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES);
      picker = picker.setAppId(GT.attachmentExternal.googleDrive.appId);
      picker = picker.setOAuthToken(googleAttachmentUser.access_token);

      if (googleAttachmentUser.folder_id) picker = picker.addView(currentFolderView);

      picker = picker.addView(myFolderView);

      picker = picker.setDeveloperKey(GT.attachmentExternal.googleDrive.developerKey);
      picker = picker.setCallback(gdata => {
        if (gdata.action === google.picker.Action.PICKED) {
          return resolve(gdata);
        }
      });

      picker = picker.addView((new google.picker.DocsView()).setIncludeFolders(true).setOwnedByMe(false).setSelectFolderEnabled(false));
      picker = picker.addView((new google.picker.DocsView()).setIncludeFolders(true).setOwnedByMe(false).setEnableTeamDrives(true));
      picker = picker.addView(new google.picker.DocsUploadView());

      picker = picker.setOrigin(`${window.location.protocol}//${window.location.host}`);

      picker = picker.build();
      picker.setVisible(true);
    });
  },

  onFilesPicked({ selectedFilesData, taskData }) {
    return new Promise(async (resolve, reject) => {
      const payload = {
        ganttId: taskData.gantt_id,
        taskId: taskData.id,
        type: 'googleDrive',
        raw: JSON.stringify(selectedFilesData.docs),
      };

      await globalStore.dispatch('attachments/createAttachmentFromExternalProvider', payload);
      resolve();
    });
  },

  changeAccount() {
    return new Promise((resolve, reject) => {
      Promise.resolve()
        .then(this.checkGoogleAuthApiLoaded)
        .then(this.checkGooglePickerApiLoaded)
        .then(() => ({
          isValidToken: false,
        }))
        .then(this.authoriseIfRequired)
        .then(() => userExtAnalytics.log('gantt_task_google_drive_context_menu_change_account_done'))
        .then(this.createFilePicker)
        .then(this.onFilesPicked)
        .then(resolve)
        .catch(reject);
    });
  },

  changeFolder() {
    return new Promise((resolve, reject) => {
      Promise.resolve()
        .then(this.checkGoogleAuthApiLoaded)
        .then(this.checkGooglePickerApiLoaded)
        .then(this.checkGoogleTokenValidity)
        .then(this.authoriseIfRequired)
        .then(this.createFolderPicker)
        .then(this.onFolderPicked)
        .then(resolve)
        .catch(reject);
    });
  },

  createFolderPicker() {
    return new Promise((resolve, reject) => {
      const docsView = new google.picker.DocsView()
        .setIncludeFolders(true)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setOwnedByMe(true)
        .setSelectFolderEnabled(true);

      const currentAttachmentUser = globalStore.getters['attachments/getGoogleUser'];
      const picker = new google.picker.PickerBuilder()
        .setTitle(__('settings_google_folder_picker_title'))
        .setOAuthToken(currentAttachmentUser.access_token)
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .addView(docsView)
        .setAppId(GT.attachmentExternal.googleDrive.appId)
        .setDeveloperKey(GT.attachmentExternal.googleDrive.developerKey)
        .setCallback(gdata => {
          if (gdata.action === google.picker.Action.PICKED) {
            return resolve(gdata);
          }
        })
        .build();

      picker.setVisible(true);
    });
  },

  onFolderPicked(selectedFolder) {
    return new Promise(resolve => {
      const data = selectedFolder.docs[0];
      const out = {
        folder_id: data.id,
        folder_name: data.name,
        rawfolder: JSON.stringify(selectedFolder.docs[0]),
        key: 'googleDrive',
      };

      const currentAttachmentUser = globalStore.getters['attachments/getGoogleUser'];

      usersAttachmentTypeModel.update(currentAttachmentUser.id, out);
      GoogleDriveAttachmentsController.setGoogleUserToStore();

      resolve();
    });
  },

  setGoogleUserToStore() {
    const user = usersAttachmentTypeModel.getAllUsersAttachmentTypes().find(item => item.key === 'googleDrive');

    globalStore.commit('attachments/updateGoogleDriveData', { user });
  },

  loadGoogleAuthApi() {
    return new Promise(resolve => {
      window.gapi.load('auth', {
        callback: () => {
          globalStore.commit('attachments/updateGoogleDriveData', { googleAuthApiLoaded: true });
          resolve();
        },
      });
    });
  },

  loadGooglePickerApi() {
    return new Promise(resolve => {
      window.gapi.load('picker', {
        callback: () => {
          globalStore.commit('attachments/updateGoogleDriveData', { googlePickerApiLoaded: true });
          resolve();
        },
      });
    });
  },
};

export default GoogleDriveAttachmentsController;
