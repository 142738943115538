var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.cell_trigger,
        _vm.isOpen ? _vm.$style.edit : "",
        _vm.crossout && !_vm.isOpen ? _vm.$style.crossout : "",
        !_vm.editable ? _vm.$style.not_allowed : "",
      ],
      style: { opacity: _vm.isOpen ? 1 : _vm.cellOpecity },
      on: { click: _vm.clickOnCell },
    },
    [
      _c(
        "span",
        {
          class: [
            _vm.$style.text_wrap,
            _vm.$style.text_right,
            _vm.$style.select_none,
          ],
        },
        [_vm._v("\n    " + _vm._s(_vm.timeText) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }