var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: _vm.outsideClick,
            include: _vm.includeOutside,
          },
          expression:
            "{\n    handler: outsideClick,\n    include: includeOutside,\n  }",
        },
      ],
      staticClass: "wrap",
      class: [
        _vm.createMode && !_vm.isDisabled ? "creation" : "base",
        [_vm.skins],
      ],
      attrs: { id: "gp_autotest_listview_create_new_item" },
    },
    [
      _vm.createMode && !_vm.selectMode
        ? _c(
            "div",
            {
              key: "input-creation",
              ref: "inputWrap",
              staticClass: "input-container sticky-left",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputName,
                    expression: "inputName",
                  },
                ],
                ref: "nameInput",
                attrs: {
                  id: "gp_autotest_listview_create_new_item_input_name",
                },
                domProps: { value: _vm.inputName },
                on: {
                  keyup: _vm.onKeyup,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.onPreventTabClick($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputName = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("sprite", {
                staticClass: "enter-arrow-icon",
                attrs: {
                  id: "gp_autotest_listview_create_new_item_input_btn_save",
                  name: "enter-2",
                },
                on: { click: _vm.saveItem },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "sticky-left d-flex align-center create-actions" },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectMode,
                    expression: "selectMode",
                  },
                ],
                staticClass: "select-block",
                style: { width: "40px" },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center",
                  class: { "disable-creation": _vm.isDisabled },
                  on: { click: _vm.addTask },
                },
                [
                  _c("sprite", {
                    staticClass: "circled-plus-icon",
                    attrs: { name: "plus-2", size: 20 },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      attrs: {
                        id: "gp_autotest_listview_create_new_item_btn_add_task",
                      },
                      on: { click: _vm.addTask },
                    },
                    [_vm._v(" " + _vm._s(_vm.locale.addTask) + " ")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "delimiter" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      attrs: {
                        id: "gp_autotest_listview_create_new_item_btn_add_milestone",
                      },
                      on: { click: _vm.addMilestone },
                    },
                    [_vm._v(" " + _vm._s(_vm.locale.addMilestone) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }