var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "avatar-item",
    style: _vm.styles,
    attrs: { src: _vm.src ? _vm.src : _vm.defaultSrc, alt: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }