import app from '../../../app';
import store from '../../../store/main';

function onRoleAccountUpdated(event) {
  store.commit('roles/setCollaborationData', { type: 'account', updated: event.data.updated });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    data: event.data,
  });
}

export default onRoleAccountUpdated;
