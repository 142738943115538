import app from '../app';
import _ from '../libs/lodash';
import moment from '../libs/moment';

const __ = window.__;

const history = new webix.DataCollection();

history.siteOrigin = GT.siteConfig;

history.generateActionHash = function () {
  let actionHash = Math.floor(Math.random() * Date.now() / Math.random() * 10000);

  while (actionHash > 9223372036854775807) { // limit of bigint
    actionHash = Math.floor(Math.random() * Date.now() / Math.random() * 10000);
  }

  return actionHash;
};

history.sendPostMessageToFrame = function (iFrame, data) {
  iFrame.postMessage(data, history.siteOrigin);
};

history.showHistoryEvent = function (iFrame, data) {
  history.sendPostMessageToFrame(iFrame, data);
};

history.getGanttDataByHistoryEvent = function (historyEventData) {
  return webix.ajax()
    .get(`${history.siteOrigin}/api/history/${historyEventData.azureKey}/event_id/${historyEventData.eventId}/gantt_id/${historyEventData.ganttId}/?${Date.now()}`);
};

history.prepareToListData = function (data) {
  const preparedDataNoHash = _.filter(data, obj => !obj.action_hash, // && !obj.action_description.isSystem;
  ).map((event, key) => {
    event.text = __(event.action_description.locale, event.action_description.data);

    return event;
  });

  const preparedDataWithHash = _.chain(
    _.filter(data, obj => !!obj.action_hash && +obj.action_id !== 43),
  )
    .groupBy('action_hash')
    .map((events, key) => {
      const mainEvent = _.find(events, event => !event.action_description.isSystem && !event.action_description.isHidden);
      let lastEvent = _.last(_.filter(events, event => event.action_description.isSystem || event.action_description.isHidden));

      const eventText = mainEvent
        ? __(mainEvent.action_description.locale, mainEvent.action_description.data)
        : __(lastEvent.action_description.locale, lastEvent.action_description.data);

      if (!lastEvent) {
        lastEvent = mainEvent;
        lastEvent.withHash = false;
      } else {
        lastEvent.withHash = true;
      }

      if (mainEvent) {
        lastEvent.action_id = mainEvent.action_id;
        lastEvent.action_description = mainEvent.action_description;
        lastEvent.created_at = mainEvent.created_at;
        lastEvent.user_pic = mainEvent.user_pic;
        lastEvent.user_id = mainEvent.user_id;
        lastEvent.user_name = mainEvent.user_name;
      }

      lastEvent.text = eventText;

      return lastEvent;
    })
    .value();

  const finishData = _.orderBy(_.union(preparedDataNoHash, preparedDataWithHash), o => +moment(o.created_at)).reverse();

  return finishData;
};

history.getHistoryDataByGanttId = function (ganttId, offset, limit) {
  webix.ajax()
    .get(`/api/history/gantt_id/${ganttId}?${Date.now()}&offset=${offset}&limit=${limit}`)
    .then(responseData => {
      const data = responseData.json();

      history.parse(data);
      history.data.callEvent('onStoreLoaded', [data]);
    })
    .fail(error => {
      console.warn(error);
      webix.message({ type: 'error', text: 'Something is broken...' });
    });
};

history.clearModel = function () {
  history.clearAll();
};

history.getHistoryData = function () {
  const data = this.serialize();
  const f_data = history.prepareToListData(data);

  return f_data;
};

history.getHistoryDataForList = function (filters) {
  const finishData = [];
  let currentDay = false;
  const _data = this.getHistoryDataByFilters(filters);

  _.each(_data, item => {
    if (+item.action_description.isHidden) {
      return;
    }

    const cDate = item.created_at.substring(0, 10);

    if (!currentDay || currentDay !== cDate) {
      currentDay = cDate;
      finishData.push({
        id: history.generateActionHash(),
        customTemplate: true,
        value: currentDay,
        $css: 'group-date',
      });
    }

    finishData.push(item);
  });

  return finishData;
};

history.getUsersByHistoryData = function () {
  const userData = [];
  const historyData = history.getHistoryData();
  // console.log('historyData', historyData);

  const groupDataByUser = _.groupBy(history.getHistoryData(), 'user_id');

  // console.log('groupDataByUser', groupDataByUser);
  _.each(groupDataByUser, (item, userId) => {
    userData.push({
      id: userId,
      username: item[0].user_name,
      photo: item[0].user_pic,
    });
  });

  return userData;
};

history.getDatesInHistoryEvents = function () {
  const groupDataByData = [];

  _.each(history.getHistoryData(), historyData => {
    groupDataByData.push(new Date(historyData.created_at));
  });

  return groupDataByData;
};

history.getStartAndFinishDate = function () {
  let startDate = new Date();
  let endDate = new Date();

  startDate.setFullYear(2200);
  endDate.setFullYear(1900);

  _.each(history.getDatesInHistoryEvents(), historyEventData => {
    if (historyEventData.valueOf() > endDate.valueOf()) {
      endDate = historyEventData;
    }
    if (historyEventData.valueOf() < startDate.valueOf()) {
      startDate = historyEventData;
    }
  });

  return { startDate, endDate };
};

history.getMinTimeAndMaxTimeByHistoryDay = function (historyDate) {
  const minTime = new Date(historyDate);
  let foundMinTime = false;
  let foundMaxTime = false;
  const maxTime = new Date(historyDate);

  minTime.setMinutes(59);
  minTime.setHours(23);

  maxTime.setMinutes(0);
  maxTime.setHours(0);

  _.each(history.getDatesInHistoryEvents(), eventDayDate => {
    if (eventDayDate.getFullYear() === historyDate.getFullYear()
      && eventDayDate.getMonth() === historyDate.getMonth()
      && eventDayDate.getDate() === historyDate.getDate()) {
      if (eventDayDate.valueOf() > maxTime.valueOf()) {
        foundMaxTime = true;
        maxTime.setHours(eventDayDate.getHours());
        maxTime.setMinutes(eventDayDate.getMinutes());
      }

      if (eventDayDate.valueOf() < minTime.valueOf()) {
        foundMinTime = true;
        minTime.setHours(eventDayDate.getHours());
        minTime.setMinutes(eventDayDate.getMinutes());
      }
    }
  });

  if (!foundMinTime) {
    minTime.setMinutes(0);
    minTime.setHours(0);
  }

  if (!foundMaxTime) {
    maxTime.setMinutes(59);
    maxTime.setHours(23);
  }

  return { minTime, maxTime };
};

history.getUserDataById = function (userId) {
  return _.find(history.getUsersByHistoryData(), { id: `${userId}` });
};

history.getHistoryDataByFilters = function (filters) {
  return _.filter(this.getHistoryData(), item => {
    if (item.action_id && (~~(item.action_id / 10) === 7 && item.action_id !== 71)) {
      return false;
    }

    if (item.customTemplate) {
      return true;
    }

    let returnV = true;
    let checkType = 0;
    let checkNum = 0;
    const eventType = ~~(item.action_id / 10);
    const actionId = item.action_id;
    const descriptionData = item.action_description.data;

    if (filters.byResourceConvert) {
      checkNum += 1;

      if (eventType !== 17) {
        checkType += 1;
      }
    }

    if (filters.byMassChange) {
      checkNum += 1;

      if (eventType !== 15) {
        checkType += 1;
      }
    }
    if (filters.byCascadeSort) {
      checkNum += 1;

      if (eventType !== 16) {
        checkType += 1;
      }
    }

    if (filters.byResource) {
      checkNum += 1;

      if (eventType !== 5) {
        checkType += 1;
      }
    }

    if (filters.byCustomDay) {
      checkNum += 1;

      if (eventType !== 1 && eventType !== 2) {
        checkType += 1;
      }
    }

    if (filters.byCopy) {
      checkNum += 1;

      if (eventType !== 11) {
        checkType += 1;
      }
    }

    if (filters.byLink) {
      checkNum += 1;

      if (eventType !== 3) {
        checkType += 1;
      }
    }

    if (filters.byTask) {
      checkNum += 1;

      if (eventType !== 6) {
        checkType += 1;
      }
    }

    if (filters.byColumn) {
      checkNum += 1;

      if (eventType !== 12) {
        checkType += 1;
      }
    }

    if (filters.bySettings) {
      checkNum += 1;

      if (eventType !== 4) {
        checkType += 1;
      }
    }

    if (filters.byTaskPriority) {
      checkNum += 1;

      if (actionId !== 101 || descriptionData.eventName !== 'priority') {
        checkType += 1;
      }
    }

    if (filters.byTaskProgress) {
      checkNum += 1;
      if (actionId !== 71) {
        checkType += 1;
      }
    }

    if (filters.byTaskStatus) {
      checkNum += 1;

      if (actionId !== 101 || descriptionData.eventName !== 'status') {
        checkType += 1;
      }
    }

    if (filters.byTaskDnD) {
      checkNum += 1;

      if (actionId !== 82 && actionId !== 83) {
        checkType += 1;
      }
    }

    if (checkType === checkNum && checkNum !== 0) {
      returnV = false;
    }

    if (filters.byUser.length && (filters.byUser.indexOf(item.user_id) === -1)) {
      returnV = false;
    }

    if (filters.byDate.from && (moment(item.created_at) < moment(filters.byDate.from))) {
      returnV = false;
    }

    if (filters.byDate.to && (moment(item.created_at) > moment(filters.byDate.to))) {
      returnV = false;
    }

    if (filters.byText && (item.text.toLowerCase().indexOf(filters.byText.toLowerCase()) === -1)) {
      returnV = false;
    }

    return returnV;
  });
};

history.groupBy = function (zoomValue, filters) {
  let zoomStr = 0;
  let result = [];
  const finishData = [];
  let currentDay = null;

  switch (zoomValue) {
  case 1:
    zoomStr = 13;
    break;
  case 2:
    zoomStr = 10;
    break;
  case 3:
    zoomStr = 7;
    break;
  }

  result = _.groupBy(history.getHistoryDataByFilters(filters), item => item.created_at.substring(0, zoomStr));

  result = _.map(result, history.groupToDay);
  result = _.orderBy(result, 'date', 'desc');

  _.each(result, item => {
    const cDate = item.date.substring(0, zoomStr - 3);

    if (!currentDay || currentDay !== cDate) {
      currentDay = cDate;
      finishData.push({
        customTemplate: true,
        value: item.date,
        $css: 'group-date',
      });
    }

    finishData.push(item);
  });

  return finishData;
};

history.groupToDay = function (group, day) {
  return {
    date: moment(group[0].created_at).minutes(0).format(),
    times: _.orderBy(group, 'id', 'desc'),
  };
};

history.initWindowListener = function () {
  history.initFrameListener = true;
  window.addEventListener('message', history.listenMainWindow);
};

history.getInitFrame = function () {
  return history.initFrameListener;
};

history.listenMainWindow = function (event) {
  if (event.data.master && event.data.master === 'history' && event.data.message) {
    if (event.data.message === 'showHistoryEvent') {
      history.data.callEvent('need:project:update', [event.data]);

      return true;
    }

    if (event.data.message === 'initHistoryFrame') {
      history.data.callEvent('initHistoryFrame');

      return true;
    }
  }
};

history.initWindowListener();

app.on('gantt:init:after', () => {
  if (app.config.mode.isHistory) {
    app.trigger('ganttHistory:initZoom');

    parent.postMessage({ master: 'history', message: 'initHistoryFrame' }, GT.siteConfig);
  }
});

export default history;
