<template>
  <div
    v-if="isShowSpecialPastePopup"
    v-click-outside="onClickOutside"
    :class="$style.special_paste_popup"
    :style="{top: popupCoordinates.top, bottom: popupCoordinates.bottom, left: popupCoordinates.left}"
  >
    <div :class="$style.special_paste_header">
      <span> {{ locale.title }} </span>
      <div :class="$style.header_icons">
        <span :class="$style.header_icons_info">
          <Tooltip
            :content="locale.info"
            :class="$style.tooltip"
            :position="tooltipPosition"
          >
            <template #body>
              <SvgSprite :name="'info'" />
            </template>
          </Tooltip>
        </span>
        <div :class="$style.header_icons_close">
          <icon-button
            :class="$style.icon"
            :icon="{ name:'close-1' }"
            size="small"
            @onClick="onClosePopup"
          />
        </div>
      </div>
    </div>

    <div :class="$style.special_paste_selectAll">
      <VgpButton
        :type="'text'"
        :label="isAllSelected ? locale.unselectAll : locale.selectAll"
        small
        @onClick="onClickAll"
      />
    </div>

    <div
      :class="$style.special_paste_body"
      class="gantt_scroll"
    >
      <div
        v-for="item in options"
        :key="'special-option' + item.id"
        :class="$style.special_paste_option"
      >
        <VgpCheckbox
          :label="item.label"
          :label-position="'right'"
          :size="16"
          :checked="isSelected(item.id)"
          @onClick="onSelectOption(item.id)"
        />
      </div>
    </div>
    <div :class="$style.special_paste_footer">
      <VgpButton
        :class="$style.special_paste_footer__button"
        :type="'primary'"
        :label="locale.okBtn"
        small
        @onClick="onClickOklBtn"
      />
      <div :class="$style.special_paste_footer__checkbox">
        <VgpCheckbox
          :key="'show_always_' + options.length"
          :label="locales('sp_popup_dont_show_checkbox')"
          :label-position="'right'"
          :size="16"
          :checked="!showAlwaysPopup"
          @onClick="onClickShowAlwaysPopup()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';
import app from '../../../app';
import controller from './controller';
import pricingHelper from '../../../helpers/pricingHelper';
import { DEFAULT_OPTIONS } from './constants';
import iconButton from '../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import projectsModel from '../../../models/projects';
import multiViewsModel from '../../../models/multiViewsProjects';
import statusHelper from '../../../helpers/status';

export default {
  name: 'SpecialPastePopup',
  components: { Tooltip, iconButton },
  data() {
    return {
      locales: __,
      events: [],
      locale: {
        title: __('special_paste_header'),
        info: __('special_paste_table_header_info'),
        cancelBtn: __('common_cancel'),
        okBtn: __('common_apply'),
        selectAll: __('multicombo_select_all_items'),
        unselectAll: __('multicombo_unselect_all_items'),
      },
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      copyData: 'copyPaste/copiedTask',
      isOpenPastePopup: 'copyPaste/isOpenPastePopup',
      customColumnIds: 'columns/getColumnIdsByProjectId',
      customColumnById: 'columns/getCustomColumnById',
      specialPasteConfig: 'copyPaste/specialPasteConfig',
      popupCoordinates: 'copyPaste/popupCoordinates',
      taskMap: 'copyPaste/taskMap',
      isInsertingProcess: 'copyPaste/isInsertingProcess',
    }),
    customOptions() {
      const columns = [];

      if (!pricingHelper.checkPricingAccess('custom_fields')) { return columns; }

      const customColumnIds = this.customColumnIds(+gantt.config.gantt_id);

      customColumnIds.forEach(columnId => {
        const column = this.customColumnById(columnId);

        if (column) {
          columns.push({
            label: column.name,
            id: +column.id,
          });
        }
      });

      return columns;
    },
    options() {
      const options = [...DEFAULT_OPTIONS, ...this.customOptions];

      // if (controller.isAvailablePastePopup()) options = [...DEFAULT_OPTIONS, ...this.customOptions];

      return options;
    },
    isAllSelected() {
      return this.selected.length === this.options.length;
    },
    selectedOptionsFromConfig() {
      let selectedOptions = [];

      if (this.specialPasteConfig) selectedOptions = this.specialPasteConfig.settings.selected;

      return selectedOptions;
    },
    showAlwaysPopup() {
      return this.specialPasteConfig.show_always_popup;
    },
    tooltipPosition() {
      const popupCoordinates = this.popupCoordinates;
      let position = 'top';

      if (popupCoordinates?.left) {
        const clientWidth = document.documentElement.clientWidth;
        const diff = clientWidth - parseInt(this.popupCoordinates.left, 10);

        if ((diff / 2) < 180) {
          position = 'left';
        }
      }

      return position;
    },
    isCurrentRoute() {
      const route = this.$route;
      const projectId = this.copyData?.newGanttId;
      const multiviewProject = multiViewsModel.getActiveViewData();

      if (route.name === 'project' && +route.params?.projectId === +projectId) {
        return true;
      }

      if (route.name === 'projectsMultiView' && multiviewProject?.ganttIDs.includes(projectId)) {
        return true;
      }

      return false;
    },
    isShowSpecialPastePopup() {
      return this.isOpenPastePopup && this.isCurrentRoute;
    },
    isShowSpinnerForInsertingProcess() {
      return this.isInsertingProcess && this.isCurrentRoute;
    },
  },

  watch: {
    // $route() {
    //   if (this.isShowSpecialPastePopup) {
    //     this.closePopup();
    //   }
    // },
    isShowSpinnerForInsertingProcess(flag) {
      if (flag) {
        statusHelper.handlers.showMainSpinner();
      } else {
        statusHelper.handlers.hideMainSpinner();
      }
    },
    'specialPasteConfig.id': function () {
      this.selected = [...this.selectedOptionsFromConfig];
    },
  },

  created() {
    const e_1 = app.on('onAfterCollaboration', event => {
      if (event.event === 'CustomFieldFromProjectUnassigned'
        && event.projects.find(projectId => projectId === projectsModel.getActiveGanttId())) {
        event.unassignedCustomFields.forEach(field => {
          const index = this.selected.indexOf(this.selected.find(id => id === field.customFieldId));

          if (index !== -1) this.selected.splice(index, 1);
        });
      }

      if (event.event === 'CustomFieldDeleted') {
        event.customFields.forEach(id => {
          const index = this.selected.indexOf(id);

          if (index !== -1) this.selected.splice(index, 1);
        });
      }

      if (event.event === 'ProjectDeleted') {
        if (this.isOpenPastePopup) {
          const dataForCopy = this.copyData;

          if (event.projects.includes(dataForCopy.newGanttId)) {
            this.closePopup();
          }
        }
      }

      if (event.event === 'TaskDeleted') {
        if (this.isOpenPastePopup) {
          const dataForCopy = this.copyData;
          const isTask = event.tasks.find(id => (id === dataForCopy.copyRootTaskId || dataForCopy.taskId));

          if (isTask) {
            this.closePopup();
          }
        }
      }
    });

    this.events.push(e_1);

    const e_2 = app.on('project:archive', (ganttId, isArchived) => {
      if (this.isOpenPastePopup) {
        const dataForCopy = this.copyData;

        if (ganttId === dataForCopy.newGanttId) {
          this.closePopup();
        }
      }
    });

    this.events.push(e_2);

    controller.initSocketListeners();
  },

  mounted() {
    window.addEventListener('click', this.onSpecialPasteIconClick);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onSpecialPasteIconClick);
  },

  methods: {
    isSelected(name) {
      return this.selected.includes(name);
    },

    async onClickOklBtn() {
      const disabledOptionsFromConfig = this.options.filter(opt => !this.selectedOptionsFromConfig.includes(opt.id)).map(o => o.id);
      const unselectedOptions = this.options.filter(opt => !this.selected.includes(opt.id)).map(o => o.id);
      const copyOptionIds = this.selected.filter(id => !this.selectedOptionsFromConfig.includes(id));
      const removeOptionIds = unselectedOptions.filter(id => !disabledOptionsFromConfig.includes(id));
      const links = this.getLinksForSpecialPaste();

      await this.$store.dispatch('copyPaste/updateConfig', { settings: { selected: this.selected } });
      this.$store.dispatch('copyPaste/applyCopyDataByOptions', {
        copyOptions: copyOptionIds,
        removeOptions: removeOptionIds,
        links,
      });
      this.closePopup();
    },

    getLinksForSpecialPaste() {
      const projectId = projectsModel.getActiveGanttId();
      const taskIds = Object.keys(this.taskMap).map(key => +this.taskMap[key]);
      const linkIds = [];

      taskIds.forEach(tid => {
        const sourceLinks = this.$store.getters['tasksModel/getSourceLinksByGanttIdAndTaskId'](projectId, tid).map(link => link.id);
        const targetLinks = this.$store.getters['tasksModel/getTargetLinksByGanttIdAndTaskId'](projectId, tid).map(link => link.id);

        linkIds.push(...sourceLinks);
        linkIds.push(...targetLinks);
      });

      return linkIds;
    },

    onClickAll() {
      this.selected = this.isAllSelected ? [] : [...this.options.map(i => i.id)];
    },

    onSelectOption(id) {
      const index = this.selected.findIndex(i => i === id);

      index < 0
        ? this.selected.push(id)
        : this.selected.splice(index, 1);
    },

    closePopup() {
      this.$store.commit('copyPaste/toggleSpecialPastePopup', false);
      // this.selected = [];
    },

    onClosePopup() {
      this.closePopup();

      const taskId = this.taskMap[String(this.copyData.taskId)];

      controller.showSpecialPasteIcon(taskId);
    },

    async onClickShowAlwaysPopup() {
      const flag = this.showAlwaysPopup ? 0 : 1;

      this.$store.dispatch('copyPaste/updateConfig', { show_always_popup: flag });
    },

    onClickOutside(e) {
      const ganttContainer = e.target.closest('.webix_view.gantt_pro_body_container');
      const footerContainer = e.target.closest('.webix_view.footer_vue');
      const sidebarContainer = e.target.closest('.webix_view.left-sidebar-wrapper');
      const ganttCell = e.target.closest('.gantt_cell');

      if (sidebarContainer || ganttContainer || footerContainer || ganttCell) {
        this.onClosePopup();
      }
    },

    onSpecialPasteIconClick(e) {
      controller.onSpecialPasteIconClick(e);
    },
  },
};

</script>

<style module src="./popup.less" lang="less" />
