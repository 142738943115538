var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.header_tabs,
        _vm.config.css ? _vm.$style[_vm.config.css] : "",
      ],
    },
    _vm._l(_vm.visibleTabs, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: [
            _vm.$style.tab_item,
            _vm.activeTab.name === item.name ? _vm.$style.active : "",
            item.cssClass || "",
          ],
          on: {
            click: function ($event) {
              return _vm.onClickTab(item)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(item.text) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }