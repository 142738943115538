var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statusData && _vm.statusData.options
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.onClickOutside,
              expression: "onClickOutside",
            },
          ],
          class: ["status-dropdown"],
        },
        [
          _c(
            "div",
            {
              ref: "statusDropdown",
              class: [
                "status-select",
                "select-area",
                _vm.isOpen ? "open" : "",
                _vm.statusData.options.length <= 4 ? "scroll-hidden" : "",
                _vm.statusData.options.length <= 1 ? "no-status" : "",
                _vm.isFirefox ? "scroll-ident-ff" : "",
              ],
              style: {
                top: _vm.coordinates.top,
                bottom: _vm.coordinates.bottom,
                left: _vm.coordinates.left,
                right: _vm.coordinates.right,
              },
            },
            [
              _c("div", { class: ["select__options gantt_scroll"] }, [
                _vm.statusData.options.length > 1 && _vm.hasRightToChangeStatus
                  ? _c(
                      "div",
                      { class: ["options-area"] },
                      _vm._l(_vm.statusData.options, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            class: [
                              "select__options-item",
                              item.icon ? "icon" : "",
                              item.value === _vm.statusData.selectOption.value
                                ? "active"
                                : "",
                            ],
                            style: {
                              backgroundColor: _vm.getColorBack(item.color),
                              color: _vm.colorLabel(item.color),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeValue(item.id, item.value)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                class: [
                                  "select__options-item-text",
                                  _vm.statusData.selectOption.id === item.id
                                    ? "select__options-item-text_bold"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.localeArray.includes(item.value)
                                      ? _vm.locale(item.value)
                                      : item.value
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.statusData.selectOption.id === item.id
                              ? _c("svg-sprite", {
                                  class: ["select__options-item-icon"],
                                  style: { color: _vm.colorLabel(item.color) },
                                  attrs: {
                                    name: "check",
                                    type: "regular",
                                    size: 24,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.statusData.options.length <= 1 &&
                _vm.hasRightToStatusSettings &&
                _vm.hasRightToChangeStatus
                  ? _c(
                      "div",
                      { staticClass: "no-result-template" },
                      [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.locale("no_result_locale")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _c("vgp-button", {
                          staticClass: "button-area",
                          attrs: {
                            type: "text",
                            label: _vm.locale("add_status_locale"),
                            small: true,
                            icon: { name: "plus-1", type: "bold", size: 20 },
                          },
                          on: { onClick: _vm.redirectToStatuses },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "delimiter" }, [
      _c("div", { staticClass: "delimiter_line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }