<template>
  <section :class="$style['task-settings-primary-info']">
    <div :class="$style['task-settings-primary-info__left']">
      <div :class="$style['progress-wrapper']">
        <input-range-slider
          :value="taskProgress"
          :disabled="isFieldDisabled('progress')"
          :class="$style['progress-wrapper__slider']"
          :min="0"
          :max="100"
          :step="10"
          :width="120"
          @update="taskProgress = $event"
          @change="handleChangeData('progress', $event)"
        />
        <vgp-input
          :key="inputProgressKey"
          :value="taskProgress"
          :is-disabled="isFieldDisabled('progress')"
          input-type="number"
          postfix="%"
          select-by-focus
          :border="false"
          :class="$style['progress-wrapper__input']"
          :min="0"
          :max="100"
          @onChange="handleInputProgressChange"
        />
      </div>
      <button-dropdown-select
        v-if="!isFieldDisabled('status_visibility')"
        v-model="taskStatus"
        :items="statusOptions"
        :disabled="isFieldDisabled('status')"
        @change="handleChangeData('status', $event)"
      />
      <button-dropdown-select
        v-if="!isFieldDisabled('priority_visibility')"
        v-model="taskPriority"
        :items="priorityOptions"
        :disabled="isFieldDisabled('priority')"
        icon-type="link"
        @change="handleChangeData('priority', $event)"
      />
    </div>
    <div :class="$style['task-settings-primary-info__right']">
      <span>{{ loggedTimeInfo }}</span>
    </div>
  </section>
</template>

<script>
import ButtonDropdownSelect from './includes/ButtonDropdownSelect.vue';
import InputRangeSlider from './includes/InputRangeSlider.vue';
import timeTrackingModel from '../../../../models/timeTracking';
import timeParser from '$$helpers/timeParser';
import app from '../../../../app';

export default {
  name: 'TaskSettingsPrimaryInfo',
  components: { InputRangeSlider, ButtonDropdownSelect },
  inject: ['isFieldDisabled'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      statusOptions: [],
      priorityOptions: [],
      inputProgressKey: 0,
      loggedTimeInfoKey: 0,
      loggedTimeInfo: null,
      taskProgress: null,
      taskPriority: null,
      taskStatus: null,
    };
  },
  watch: {
    'taskData.id': {
      handler() {
        this.statusOptions = this.$store.getters['columns/getColumnByName']('status', this.taskData.gantt_id).options;
        this.priorityOptions = this.$store.getters['columns/getColumnByName']('priority', this.taskData.gantt_id).options;
        this.taskProgress = Math.round(this.taskData.progress * 100);
        this.taskPriority = this.taskData.priority;
        this.taskStatus = this.taskData.status;

        this.setLoggedTimeInfo();
      },
      immediate: true,
    },
    'taskData.progress': function (val) {
      const updatedTaskProgress = Math.round(val * 100);

      if (updatedTaskProgress !== this.taskProgress) {
        this.taskProgress = updatedTaskProgress;
      }
    },
    'taskData.priority': function (val) {
      if (val !== this.taskPriority) {
        this.taskPriority = val;
      }
    },
    'taskData.status': function (val) {
      if (val !== this.taskStatus) {
        this.taskStatus = val;
      }
    },
  },
  created() {
    this.statusOptions = this.$store.getters['columns/getColumnByName']('status', this.taskData.gantt_id).options;
    this.priorityOptions = this.$store.getters['columns/getColumnByName']('priority', this.taskData.gantt_id).options;

    this.setLoggedTimeInfo();

    app.on('timeTrackingModel:change', this.setLoggedTimeInfo);
  },
  methods: {
    handleInputProgressChange(event) {
      const { target: { value } } = event;

      let newValue = value.replace(',', '.');

      if (newValue.trim() === '' || newValue === '.') {
        return this.inputProgressKey++;
      }

      if (Number(newValue) < 0.5) {
        newValue = 0;
      }

      this.handleChangeData('progress', newValue);
    },
    handleChangeData(property, value) {
      let newValue = value;

      if (property === 'progress') {
        newValue /= 100;
      }

      if (this.taskData[property] === newValue) return;

      this.$emit('change', { property, payload: newValue });
    },
    setLoggedTimeInfo() {
      const logsData = timeTrackingModel.getLogsByParams(this.taskData.gantt_id, this.taskData.id);
      const selfLoggedTime = logsData ? logsData.sum : 0;
      const convertedTime = timeParser.output(selfLoggedTime, {});

      this.loggedTimeInfo = `${__('settings_logged_time_label')}: ${convertedTime}`;
    },
  },
};
</script>

<style module lang="less">

.task-settings-primary-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 12px;
  padding: 0 64px;
  height: 28px;

  &__left {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: Lato-Bold, sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
    }
  }
}

.progress-wrapper {
  display: flex;
  gap: 4px;

  &__input {
    width: 53px !important;
    height: 26px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}

</style>
