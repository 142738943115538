var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: _vm.show,
      title: _vm.locales("overview_project_description_title"),
      "class-list": _vm.$.description_modal,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: [_vm.$.editor, _vm.$.focus] }, [
              _c("div", { ref: "editor" }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$.actions },
              [
                _c("vgp-button", {
                  class: _vm.$.action_button,
                  attrs: {
                    type: "secondary",
                    label: _vm.locales("common_cancel"),
                    small: "",
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.$emit("onCancel")
                    },
                  },
                }),
                _vm._v(" "),
                _c("vgp-button", {
                  class: _vm.$.action_button,
                  attrs: {
                    type: "primary",
                    label: _vm.locales("common_save"),
                    small: "",
                  },
                  on: { onClick: _vm.save },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }