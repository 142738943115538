<template xmlns:skeleton-item="http://www.w3.org/1999/html">
  <div
    id="skeletonHeader"
  >
    <skeleton-wrapper>
      <template #body>
        <div>
          <div
            :class="[$.headerRow]"
          >
            <div :class="$.firstCell">
              <skeleton-item
                :type="'square'"
                :size="'lst'"
              />
              <skeleton-item
                :style="{width: `430px`, height: '24px'}"
                :type="'bar'"
              />
            </div>
            <div :class="$.lastCell">
              <skeleton-item
                :style="{width: `53px`, height: '24px'}"
                :type="'bar'"
              />
              <skeleton-item
                :style="{width: `53px`, height: '24px'}"
                :type="'bar'"
              />
            </div>
          </div>
        </div>
      </template>
    </skeleton-wrapper>
  </div>
</template>

<script>

import SkeletonItem from '../common/VueComponents/skeletons/skeletonItem.vue';
import SkeletonWrapper from '../common/VueComponents/skeletons/skeletonWrapper.vue';

export default {
  name: 'SkeletonMain',
  components: { SkeletonWrapper, SkeletonItem },

};
</script>

<style module="$" lang="less">
.skeleton {
  &_row {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 16px;
  }
}

.firstCell {
  display: flex;
  align-items: center;
}

.lastCell {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerRow{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;

  th{
    width: 132px;
    padding-left: 12px;
    &:first-child{
      width: 298px;
      margin-left: 8px;
    }
  }
}
</style>
