<template>
  <animated-popup>
    <template #body>
      <div
        :class="['popup-container']"
      >
        <div class="allProjects-header">
          <div class="title">
            <div
              id="gp_autotest_sidebar_projects_close_button"
              :class="['icon']"
              @click="sideBarBack"
            >
              <svg-sprite
                name="arrow-left"
                type="bold"
              />
            </div>

            <div class="text">
              {{ locale(`all_projects_header_title`) }}
            </div>
          </div>
          <div
            v-if="itemsForContext.length"
            ref="addProjectBtn"
            class="allProjects-header_button"
          >
            <vgp-button
              id="gp_autotest_sidebar_projects_add_project_button"
              :label="locale('create_new_project')"
              :type="'primary-border'"
              :icon="{name: 'plus-1', type:'bold', size: 20}"
              :icon-side="'left'"
              :small="true"
              @onClick="addProjectClickBtn"
            />
          </div>
          <context-menu
            v-if="addProjectClick"
            :items="itemsForContext"
            :position="positionContext"
            @selectItemMenu="onSelectContextItem($event)"
            @onClickOutside="onClickOutside"
          />
        </div>
        <div class="projects-popup-header-search-layout">
          <div class="projects-popup-header-search-input">
            <vgp-input
              v-model="searchStr"
              :style="{outline: '#fff'}"
              :class="'search-input'"
              :type="'text'"
              :placeholder="locale('search_projects_placeholder')"
              :icons-left="[
                { name: 'search' }]"
              :small="true"
              :select-by-focus="false"
            />
          </div>
        </div>
        <div class="projects-popup-sort">
          <div
            v-for="(button, index) in sortButtons"
            :key="index"
            :class="['projects-popup-sort-button', activeType === button ? `active ${sortDesc === 0 ? 'asc' : 'desc'}` : '' ]"
            :data-type="button"
          >
            <div
              :class="['projects-popup-sort-item' ]"
              @click="clickSortedButtons(sortedProjects, button, (activeType === button ? sortDesc === 0 ? 1 : 0 : 0) )"
            >
              <div
                class="projects-popup-sort-button-text no_selection"
              >
                {{ locale(`${button}_sort_button`) }}
              </div>
              <div class="projects-popup-sort-button-icon">
                <svg-sprite
                  :name="'priority'"
                  :size="16"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="sortedProjects"
          class="projects-list gantt_scroll"
        >
          <div
            v-for="project in sortedProjects"
            :key="project.id"
            :data-id="project.id"
            :data-type="project.type"
            :data-gantt_id="project.gantt_id"
          >
            <project-item
              :project="project"
              :multi-select-mode="multiSelectMode"
              :edit-multiview-mode="editMultiviewMode"
              :edit-multiview-gantt-ids="editMultiviewGanttIds"
              :multi-select-mode-ids="multiSelectModeIds"
              :project-open="projectOpen"
              :owner-on-project="getOwnerOnProject(project.gantt_id)"
              :is-active-user="isActiveUser"
              :user-rights="userRights"
              :is-jira-project="isJiraProject(project.gantt_id)"
              :items-for-context-menu="getItemsForContextMenu(project)"
              :status-data="statusData(project)"
              :has-right-to-change-status="hasRightToChangeStatus(project)"
              :has-right-to-status-settings="hasRightToStatusSettings"
              :open-status="openStatus"
              @currentProjectInfo="currentProjectInfo = $event"
              @positionInfo="positionInfo = $event"
              @positionContext="positionContext = $event"
              @currentProjectContext="currentProjectContext = $event"
              @currentProjectForStatus="currentProjectForStatus = $event"
              @positionSelect="positionSelect = $event"
              @openStatus="openStatus = $event"
              @multiSelectMode="toggleMultiSelectMode"
              @checkboxHandler="checkboxHandler"
              @onSelectItemMenu="onSelectItemMenu"
              @favProjectClick="favProjectClick"
              @projectClick="projectClick"
            />
          </div>
          <div v-if="archivedProjects.length && !multiSelectMode && !editMultiviewMode">
            <div class="archive-button-wrapper">
              <vgp-button
                :type="'secondary'"
                :label="locale(getLabelForArchiveButton)"
                :width="'454px'"
                @onClick="archivedButtonClick"
              />
            </div>
            <div v-if="showArchivedProjects">
              <div
                v-for="project in archivedProjects"
                :key="project.id"
                :data-archive="true"
                :data-id="project.id"
                :data-type="project.type"
                :data-gantt_id="project.gantt_id"
              >
                <project-item
                  :project="project"
                  :archived="true"
                  :project-open="projectOpen"
                  :items-for-context-menu="getItemsForContextMenu(project)"
                  :owner-on-project="getOwnerOnProject(project.gantt_id)"
                  :is-active-user="isActiveUser"
                  :user-rights="userRights"
                  :status-data="statusData(project)"
                  @positionContext="positionContext = $event"
                  @currentProjectContext="currentProjectContext = $event"
                  @projectClick="projectClick"
                  @onSelectItemMenu="onSelectItemMenu"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="!sortedProjects.length && !archivedProjects.length"
          class="no-data--wrap"
        >
          <template>
            <div class="no-data noFiltredProjects" />
            <div class="noFiltredProjects-text">
              {{ locale('project_list_no_filtred') }}
            </div>
          </template>
        </div>
        <div
          v-show="multiSelectMode || editMultiviewMode"
          class="button-wrapper"
        >
          <tooltip
            class="multiselect-btn"
            :content="multiSelectButton ? locale('create_multiview_button_tooltip') : ''"
            :hide="!multiSelectButton"
          >
            <template #body>
              <vgp-button
                :label="locale(getLabelForMultiSelectButton)"
                :type="'primary'"
                :width="'454px'"
                :disabled="multiSelectButton"
                @onClick="createOrUpdateMultiview"
              />
            </template>
          </tooltip>
        </div>
      </div>
      <popup-info
        v-if="currentProjectInfo"
        :project="currentProjectInfo"
        :position="positionInfo"
        :owner-on-project="getOwnerOnProject(currentProjectInfo.gantt_id)"
        :side="'right'"
        @closePopup="closePopup"
        @mouseover.native="currentProjectInfo = null"
      />
      <context-menu
        v-if="currentProjectContext"
        :items="getItemsForContextMenu(currentProjectContext)"
        :position="positionContext"
        @selectItemMenu="onSelectItemMenu($event, currentProjectContext)"
        @closePopup="closeContextPopup"
        @scroll="scrollContext = $event"
        @onClickOutside="onClickOutsideContext"
      />
      <status-select-dropdown
        v-if="openStatus"
        :status-data="statusData(currentProjectForStatus)"
        :position="positionSelect"
        :project-config="currentProjectForStatus"
        :is-open="openStatus"
        :has-right-to-change-status="hasRightToChangeStatus(currentProjectForStatus)"
        :has-right-to-status-settings="hasRightToStatusSettings"
        :style="{position: 'fixed'}"
        @selectStatus="onChangeSelect"
        @closeStatus="closeStatusSelect"
        @onClickOutside="onClickOutsideStatus"
        @closeProjectsList="sideBarBack"
      />
      <core-confirm-popup
        :show="!!deleteInfoData"
        :popup-width="440"
        :close-icon="true"
        :title="isMultiviewDelete ? locale('project_list_delete_multiview') : locale('project_list_delete_project')"
        :text="isMultiviewDelete ? locale('settings_msg_delete_multiview_confirm_text') : locale('project_delete_confirm_text', {
          projectName: deleteInfoData ? deleteInfoData.name : ''
        })"
        :confirm-string="isMultiviewDelete ? '' : deleteInfoData ? deleteInfoData.name : ''"
        :confirm-string-input-placeholder="locale('delete_project_confirm_string_placeholder')"
        :ok-button="{
          title: locale('common_delete'),
          type: 'destructive',
        }"
        :cancel-button="{
          title: locale('common_cancel'),
          type: 'secondary',
        }"
        @onClickOk="onCloseDeletePopup('ok')"
        @onClickCancel="onCloseDeletePopup"
      />
    </template>
  </animated-popup>
</template>

<script>
import AnimatedPopup from '../../common/VueComponents/popups/animatedPopup/animatedPopup.vue';
import projectComponent from '../../../components/Project';
import multiViewsProjects from '../../../models/multiViewsProjects';
import projectsModel from '../../../models/projects';
import _ from '../../../libs/lodash';
import plansModel from '../../../models/plans';
import projectItem from './projectItem.vue';
import routerHelper from '../../../helpers/router';
import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import globalStore from '../../../store/main';
import CoreConfirmPopup from '../../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import Tooltip from '../../common/VueComponents/tooltips/tooltip.vue';
import { checkPricingAccess, getPlans } from '../../../helpers/pricingHelper';
import rights from '../../../components/rights';
import popupInfo from './infoProjectPopup.vue';
import StatusSelectDropdown from '../../common/VueComponents/select/statusSelect/statusSelectDropdown.vue';

export default {
  name: 'ProjectListPopup',
  components: {
    StatusSelectDropdown,
    popupInfo,
    Tooltip,
    CoreConfirmPopup,
    AnimatedPopup,
    projectItem,
  },
  props: {
    multiselectModeFromSidebar: { type: Boolean, required: true },
    open: { type: Boolean, required: true },
    userRights: { type: Object, required: true },
    multiselectProject: { type: Object, required: false, default: null },
    itemsForContext: { type: Array, required: false, default: null },
  },
  data() {
    return {
      isShow: false,
      locale: __,
      projects: [],
      sortButtons: ['last_change', 'name', 'overdue', 'progress', 'unassigned'],
      multiSelectMode: false,
      multiSelectModeIds: [],
      editMultiviewMode: false,
      editMultiviewID: null,
      editMultiviewGanttIds: [],
      activeType: user.settings.type || 'lastChange',
      sortDesc: user.settings.projects_sort_desc || 0,
      searchStr: '',
      multiSelectButton: true,
      showArchivedProjects: false,
      addProjectClick: false,
      positionContext: null,
      deleteInfoData: false,
      isMultiviewDelete: false,
      listenersMultiview: [],
      listenersApp: [],
      currentProjectInfo: null,
      positionInfo: null,
      currentProjectContext: null,
      currentProjectForStatus: null,
      positionSelect: null,
      openStatus: false,
      restoreItem: {
        name: 'project_popup_menu_restore_project',
        id: 'restore',
        icon: 'history',
        gpTestId: 'gp_autotest_sidebar_projects_context_menu_restore',
        iconType: 'regular',
        iconSize: 24,
      },
      deleteItem: {
        name: 'project_popup_menu_delete_project',
        id: 'delete',
        icon: 'delete',
        iconColor: '#DD3636',
        labelColor: '#DD3636',
        gpTestId: 'gp_autotest_sidebar_projects_context_menu_delete',
        iconType: 'regular',
        iconSize: 24,
      },
      // editMultiviewItem: {
      //   name: 'project_popup_menu_edit_portfolio',
      //   id: 'edit_portfolio',
      //   icon: 'pensil',
      //   gpTestId: 'gp_autotest_sidebar_projects_context_menu_edit_multiview',
      //   iconType: 'regular',
      //   iconSize: 24,
      // },
      archiveItem: {
        name: 'project_popup_menu_archive_project',
        id: 'archive',
        icon: 'archive',
        gpTestId: 'gp_autotest_sidebar_projects_context_menu_archive',
        iconType: 'regular',
        iconSize: 24,
      },
      favoriteItem: isFav => ({
        name: isFav ? 'project_popup_menu_unfavorite_project' : 'project_popup_menu_favorite_project',
        id: 'favorite',
        icon: isFav ? 'unfavorite' : 'star',
        gpTestId: 'gp_autotest_sidebar_projects_context_menu_favorite',
        iconType: 'regular',
        iconSize: 24,
      }),
      scrollContext: false,
      scrollStatus: false,
    };
  },
  computed: {
    hasRightToStatusSettings() {
      return rights.account.hasRight('project_statuses_settings');
    },
    getLabelForMultiSelectButton() {
      if (this.editMultiviewMode) {
        return 'update_multiview_button';
      }
      if (this.multiSelectMode && this.multiSelectButton) {
        return 'create_multiview_button_disabled';
      }

      return 'create_new_multiview';
    },
    getLabelForArchiveButton() {
      if (this.showArchivedProjects) {
        return 'hide_archived_projects_button';
      }

      return 'show_archived_projects_button';
    },
    isActiveUser() {
      return plansModel.isActive();
    },
    sortedProjects() {
      let sortedProjects = this.projects.filter(project => !project.is_archived);

      this.sortProjectsByType(sortedProjects);
      if (this.editMultiviewMode || this.multiSelectMode) {
        sortedProjects = sortedProjects.filter(project => !!project.gantt_id && !project.is_jira);
      }

      if (this.editMultiviewMode) {
        // sorteShowedItemsBySelected() {
        //   if (!this.showSelectedFirst) return;
        //
        //   const res = [];
        //
        //   this.groupSet.forEach(name => {
        //     const first = []; const
        //         second = [];
        //     const group = this.getItemsByGroupName(name);
        //
        //     group.forEach(item => {
        //       if (this.inner_selected.find(i => i[this.trackBy] === item[this.trackBy])) {
        //         first.push(item);
        //       } else second.push(item);
        //     });
        //
        //     res.push(...first, ...second);
        //   });
        //
        //   this.showItems = res;
        // },

        // this.sortProjects.sort((a, b) => this.editMultiviewGanttIds.includes(b.gantt_id) - this.editMultiviewGanttIds.includes(a.gantt_id));
      }
      if (!rights.account.hasRight('multiview_all_features') || !checkPricingAccess('portfolio_view')) {
        sortedProjects = sortedProjects.filter(p => p.type !== 'multiview');
      }
      if (this.searchStr) {
        sortedProjects = sortedProjects.filter(item => {
          const value = item.name.toLowerCase();

          if (value.includes(this.searchStr.toLowerCase())) {
            return item;
          }

          return false;
        });
      }

      return sortedProjects;
    },
    archivedProjects() {
      if (!this.searchStr) {
        return this.projects.filter(project => project.is_archived);
      }

      return this.projects.filter(item => {
        const value = item.name.toLowerCase();

        if (value.includes(this.searchStr.toLowerCase()) && item.is_archived) {
          return item;
        }

        return false;
      });
    },
    projectOpen() {
      const route = routerHelper.getCurrentRoute();

      if (!route.path) return;

      if (route.path.startsWith('/project')) {
        return route.params.projectId || route.params.multiviewID;
      }
    },
  },
  created() {
    const listenerMultiview_1 = multiViewsProjects.data.attachEvent('onDataUpdate', id => {
      const activeMultiView = multiViewsProjects.getActiveViewData();

      if (activeMultiView?.id !== id) {
        this.openMultiProjectView(id);
      }
    });

    const listenerMultiview_2 = multiViewsProjects.data.attachEvent('removeMultiview', id => {
      this.$emit('clickFavProject');
    });

    const listenerMultiview_3 = multiViewsProjects.data.attachEvent('onStoreUpdated', (id, data, method) => {
      if (method === 'update' && data.updateGanttIDs) {
        const activeMultiView = multiViewsProjects.getActiveViewData();

        if (!activeMultiView || activeMultiView.id !== id) {
          this.openMultiProjectView(id);
        } else {
          const multiview = multiViewsProjects.setActiveMultiView(id);

          app.trigger('activeMultiProjects:set', id, multiview.ganttIDs);
        }

        this.$emit('clickFavProject');
      }
    });

    this.listenersMultiview.push(
      listenerMultiview_1,
      listenerMultiview_2,
      listenerMultiview_3,
    );

    projectsModel.data.attachEvent('removeProject', () => {
      this.sideBarBack();
    });

    const listenerApp_1 = app.on('app:initModels', () => {
      this.loadProjectsData();
    });

    const listenerApp_2 = app.on('sideBar:toggleFavorite', ({ id, newState }) => {
      this.projects = this.projects.map(project => {
        if (project.id === id) {
          return { ...project, ...{ is_favorite: newState } };
        }

        return project;
      });
    });

    const listenerApp_4 = app.on('project:archive', data => {
      if (data === this.currentProjectContext?.id) {
        this.currentProjectContext = null;
      }
      if (data === this.currentProjectForStatus?.id) {
        this.currentProjectContext = null;
        this.openStatus = false;
      }

      this.loadProjectsData();
    });

    const listenerApp_3 = app.on('onAfterCollaboration', ({ event, projects }) => {
      if (event === 'ResourceToProjectsAssigned' || event === 'ResourcesOnProjectsConverted') {
        const addProjects = [];

        projects.forEach(ganttId => {
          const resources = globalStore.getters['resourcesModel/getResourcesByProjectId'](ganttId);
          const isAccess = resources.find(resource => resource.userId === user.id);
          const isExistProject = this.projects.find(project => project.gantt_id === ganttId);

          if (isAccess && !isExistProject) {
            const projectsData = projectComponent.getProjectsList();
            const project = projectsData.find(p => p.gantt_id === ganttId);

            if (project) addProjects.push(project);
          }
        });

        if (addProjects.length) this.projects = this.projects.concat(addProjects);
      }

      if (event === 'ResourceFromProjectsUnassigned' || event === 'ProjectDeleted') {
        const removeGanttIDs = [];
        const removeMultiviewIDs = [];

        if (rights.account.hasRight('king_mode') && event === 'ResourceFromProjectsUnassigned') {
          return;
        }

        projects.forEach(ganttId => {
          const project = this.projects.find(p => p.gantt_id === ganttId);

          if (event === 'ProjectDeleted') {
            if (ganttId === this.currentProjectContext?.id) {
              this.currentProjectContext = null;
            }
            if (ganttId === this.currentProjectForStatus?.id) {
              this.currentProjectContext = null;
              this.openStatus = false;
            }
          }
          if (project) {
            removeGanttIDs.push(ganttId);

            if (this.multiSelectMode || this.editMultiviewMode) {
              this.checkboxHandler(projects[0]);

              return false;
            }

            this.projects = this.projects.map(p => {
              if (p.type === 'multiview' && p.ganttIDs.includes(ganttId)) {
                const ganttIDs = p.ganttIDs.filter(id => id !== ganttId);

                if (ganttIDs.length < 1) removeMultiviewIDs.push(p.id);

                return { ...p, ...{ ganttIDs } };
              }

              return p;
            });
          }
        });

        if (removeGanttIDs.length) {
          this.projects = this.projects.filter(project => !removeGanttIDs.includes(project.gantt_id) && !removeMultiviewIDs.includes(project.id));
          app.trigger('sideBar:update:favorite');
        }
      }

      if ((event === 'ResourceOnProjectsUpdated' || event === 'RoleAccountUpdated' || event === 'RoleProjectUpdated') && this.open) {
        this.loadProjectsData();
      }

      if (event === 'ResourceUpdated' && this.deleteInfoData) {
        this.onCloseDeletePopup();
      }
    });

    this.listenersApp.push(
      listenerApp_1,
      listenerApp_2,
      listenerApp_3,
      listenerApp_4,
    );

    this.loadProjectsData();
  },
  mounted() {
    document.addEventListener('click', this.close);

    setTimeout(() => {
      this.animatedClass();
    }, 0);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);

    this.listenersMultiview.forEach(id => multiViewsProjects.data.detachEvent(`${id}`));
    this.listenersApp.forEach(id => app.off(id));
  },
  methods: {
    loadProjectsData() {
      const projectsData = projectComponent.getProjectsList();
      const archivedProjects = this.getArchivedProjects();

      archivedProjects.forEach(o => {
        o.type = 'project';
      });

      this.projects = _.cloneDeep(projectsData.filter(item => item.type !== 'multiview').concat(archivedProjects));

      if (this.multiselectModeFromSidebar) {
        this.toggleMultiSelectMode();
      }
      if (this.editMultiviewMode) {
        this.multiSelectModeIds = this.multiSelectModeIds.filter(id => projectsData.some(obj => obj.gantt_id === id));
        this.multiSelectButton = !(this.multiSelectModeIds.length >= 2);
      }
    },
    hasRightToChangeStatus(project) {
      return rights.project.hasRight(+project.id, 'project_statuses_edit');
    },
    sortProjectsByType(projects) {
      const compareMap = {
        last_change: 'last_update',
        progress: 'progress',
        overdue: 'countOfOverdue',
        unassigned: 'unassignedTasks',
        name: 'name',
      };

      projects.sort((a, b) => {
        if (this.activeType === 'last_change') {
          a[compareMap[this.activeType]] = new Date(a[compareMap[this.activeType]]).valueOf();
          b[compareMap[this.activeType]] = new Date(b[compareMap[this.activeType]]).valueOf();
        }

        const aValue = _.isString(a[compareMap[this.activeType]]) ? this.replaceHtmlEntites(a[compareMap[this.activeType]].toLowerCase()) : +a[compareMap[this.activeType]];
        const bValue = _.isString(b[compareMap[this.activeType]]) ? this.replaceHtmlEntites(b[compareMap[this.activeType]].toLowerCase()) : +b[compareMap[this.activeType]];

        if (this.sortDesc) {
          if (aValue > bValue) {
            return 1;
          }
          if (aValue < bValue) {
            return -1;
          }

          return 0;
        }
        if (aValue > bValue) {
          return -1;
        }
        if (aValue < bValue) {
          return 1;
        }

        return 0;
      });
    },
    clickSortedButtons(projects, type, desc) {
      if (type !== this.activeType || desc !== this.sortDesc) {
        projectComponent.updateProjectSortType(type, +desc);
      }
      this.activeType = type;
      this.sortDesc = desc;
      user.settings.type = type;
      user.settings.projects_sort_desc = desc;
    },

    replaceHtmlEntites(s) {
      return s.replace(/\s+/g, ' ');
    },
    getArchivedProjects() {
      return projectsModel.getArchivedProjects()

        .filter(project => this.userRights.owner || rights.account.hasRightToActionsWithProjects(project.id));
    },
    archivedButtonClick() {
      this.showArchivedProjects = !this.showArchivedProjects;
    },
    statusData(project) {
      return globalStore.getters['teamSetting/statusDataForProject'](project.id);
    },
    close(e) {
      if (this.open) {
        const container = document.querySelector('.popup-container');
        const btn = document.querySelectorAll('.allProjects-btn');
        const background = document.querySelectorAll('.background_container');
        const path = e.path ? e.path : e.composedPath(e.target);

        const isDropArrow = path.find(elem => {
          if (!elem.classList) return;

          return elem.classList.contains('context_popup');
        });

        if (isDropArrow) return;
        if (btn.length && btn[0].contains(e.target)) return;
        if (this.open && (background && !background[0].contains(e.target))) return;

        if ((background && background[0].contains(e.target)) || (container && !container.contains(e.target))) {
          this.editMultiviewMode = false;
          this.multiSelectButton = true;
          this.multiSelectMode = false;
          this.multiSelectModeIds = [];
          this.currentProjectContext = null;

          this.sideBarBack();
        }
      }
    },
    toggleMultiSelectMode(gantt) {
      if (!plansModel.isActive()) {
        return;
      }
      gantt = this.multiselectProject ? this.multiselectProject : gantt;
      if ((gantt && gantt?.ganttIDs)) {
        this.editMultiviewID = gantt.id;
        this.editMultiviewMode = true;
        this.multiSelectButton = !this.multiSelectButton;
        this.multiSelectModeIds = this.multiSelectModeIds.concat(gantt.ganttIDs);
      } else {
        this.multiSelectMode = !this.multiSelectMode;
        gantt ? this.multiSelectModeIds.push(gantt.id) : this.multiSelectModeIds = [];
      }
      this.multiSelectButton = !(this.multiSelectModeIds.length >= 2);
    },
    checkboxHandler(ganttId) {
      if (this.multiSelectModeIds.includes(ganttId)) {
        this.multiSelectModeIds = this.multiSelectModeIds.filter(id => id !== ganttId);
      } else {
        this.multiSelectModeIds.push(ganttId);
      }
      this.multiSelectButton = !(this.multiSelectModeIds.length >= 2);
    },
    createOrUpdateMultiview() {
      const projectWithoutArchive = this.projects.filter(project => !project.is_archived);
      const ganttIDs = this.multiSelectModeIds.filter(id => projectWithoutArchive.find(item => item.id === id)?.id);

      if (this.editMultiviewMode) {
        if (ganttIDs.length) {
          multiViewsProjects.updateMultiview(this.editMultiviewID, ganttIDs);
        } else {
          projectsModel.openProjectAfterDelete();
          this.sideBarBack();
        }
        this.editMultiviewMode = false;
        this.multiSelectModeIds = [];
        // this.sideBarBack();
        userExtAnalytics.log('edit_portfolio_done', { 'projects number': ganttIDs.length });
      } else {
        multiViewsProjects.createMultiview(ganttIDs);

        this.multiSelectMode = !this.multiSelectMode;
        this.multiSelectModeIds = [];
        // this.sideBarBack();
        userExtAnalytics.log('create_portfolio_done', { 'projects number': ganttIDs.length });
      }
    },
    closePopup(flag) {
      if (flag) {
        this.currentProjectInfo = null;
      }
    },

    closeStatusSelect(flag) {
      if (flag) {
        this.openStatus = !flag;
      }
    },
    closeContextPopup(flag) {
      if (this.scrollContext) {
        this.currentProjectContext = null;

        return;
      }
      if (flag && !this.currentProjectContext) {
        this.currentProjectContext = null;
      }
    },
    sideBarBack() {
      this.closePopup();
      this.animatedClass();
      setTimeout(() => { this.$emit('sideBarBack'); }, 300);
    },
    animatedClass() {
      const popup = document.querySelector('.animated_popup_wrap');
      const background = document.querySelector('.background_container');

      if (!popup || !background) return;
      if (popup || background) {
        if (popup.classList.contains('show')) {
          popup.classList.remove('show');

          return;
        }
        popup.classList.add('show');

        if (background.classList.contains('show')) {
          background.classList.remove('show');

          return;
        }
        background.classList.add('show');
      }
    },
    addProjectClickBtn() {
      const sortedProjects = this.sortedProjects.filter(project => !!project.gantt_id && !project.is_jira);

      this.addProjectClick = true;
      this.itemsForContext.forEach(item => {
        if (item.id === 'select_multiple') item.disable = sortedProjects.length < 1;
      });
      this.positionContext = this.$refs.addProjectBtn.getBoundingClientRect();
    },
    onSelectContextItem(id) {
      this.addProjectClick = false;
      switch (id) {
      case 'newProjectItem':
        routerHelper.pushRoute({ name: 'newProject' });
        userExtAnalytics.log('project_create_open');
        this.sideBarBack();
        break;
      case 'select_multiple':
        routerHelper.pushRoute({
          name: 'portfolio',
          query: {
            createNew: true,
          },
        });
        this.sideBarBack();
        break;
      }
    },
    onClickOutsideContext(e) {
      const icon = e.target.closest('.projects-popup-list-item-menu');
      const iconParent = e.target.parentNode.closest('.projects-popup-list-item-menu');

      if (!icon || !iconParent) {
        this.currentProjectContext = null;
      }
    },

    onClickOutsideStatus(e) {
      const icon = e.target.closest('.select__view');
      // const iconParent = e.target.parentNode.closest('.projects-popup-list-item-menu');

      if (!icon) {
        this.closeStatus = true;
      }
    },
    onClickOutside() {
      this.addProjectClick = false;
    },
    openMultiProjectView(multiviewID) {
      this.sideBarBack();
      setTimeout(() => { routerHelper.pushRoute(`/projects/${multiviewID}/gantt`); }, 400);
    },
    openProject(ganttId) {
      this.sideBarBack();
      setTimeout(() => { routerHelper.pushRoute(`/project/${ganttId}/gantt`); }, 400);
    },
    projectClick(e, projectId) {
      if (this.multiSelectMode || this.editMultiviewMode) {
        this.checkboxHandler(projectId);

        return false;
      }

      const ganttId = e.currentTarget.dataset.gantt_id || e.currentTarget.dataset.id;
      const path = e.path ? e.path : e.composedPath(e.target);

      const isDropArrow = path.find(elem => {
        if (!elem.classList) return;

        return elem.classList.contains('projects-popup-list-item-menu');
      });

      if (isDropArrow) {
        return false;
      }

      const isFavoriteNode = e.target.closest('.projects-popup-list-item-fav');
      const isStatusNode = e.target.closest('.status-select');

      const isProject = e.currentTarget.dataset.type === 'project';
      const isMultiview = e.currentTarget.dataset.type === 'multiview';
      const isArchive = e.currentTarget.dataset.archived;

      const isShowPaymentPage = globalStore.getters['paymentPlans/isShowPaymentPage'];

      if (isFavoriteNode || isStatusNode) {
        return false;
      }

      if (isProject && !isArchive) {
        if (isFavoriteNode) {
          return false;
        }

        this.openProject(ganttId);

        // return false;
      }
      const activeProject = ganttViewModel.getActiveProjectData();

      if (
        (routerHelper.isSingleProjectRoute() || routerHelper.isMultiProjectsRoute())
          && activeProject
          && activeProject.id === +projectId
          && routerHelper.getCurrentRoute().params.mode === 'gantt'
          && isShowPaymentPage
      ) {
        globalStore.commit('paymentPlans/close');
      }

      if (isMultiview) {
        if (this.multiSelectMode) {
          return false;
        }

        this.openMultiProjectView(+projectId);
      }

      if (isArchive) {
        this.selectArchivedProject(ganttId);
      }
    },
    favProjectClick(project) {
      const id = project.id;
      const isMultiview = project.type === 'multiview';

      globalStore.dispatch('headerView/toggleFavorite', { id, isMultiview });

      this.$emit('clickFavProject');

      return false;
    },
    selectArchivedProject(ganttID) {
      projectComponent.loadArchiveProject(ganttID)
        .then(() => {
          app.trigger('projectModel:activeProject:changeRoute', ganttID);
          this.sideBarBack();
        });
    },
    deleteProject(gantt) {
      const projects = this.projects;
      const project = _.find(projects, { id: gantt.id });

      const isMultiview = project.type === 'multiview';

      this.isMultiviewDelete = isMultiview === undefined ? ganttViewModel.isMultiview() : isMultiview;
      this.deleteInfoData = project;

      app.trigger('gantt:keyboard:disable');
    },

    onCloseDeletePopup(type) {
      if (type === 'ok') {
        globalStore.dispatch('headerView/deleteProject', { projectId: this.deleteInfoData.id });
        this.projects = this.projects.filter(item => item.id !== this.deleteInfoData.id);
      }
      this.deleteInfoData = null;
    },
    onSelectItemMenu(id, project) {
      this.currentProjectContext = null;
      switch (id) {
      case 'delete':
        this.deleteProject(project);
        break;
      case 'restore':
        this.unArchiveProject(project.id);
        break;
      case 'archive':
        this.archiveProject(project.id);
        break;
      case 'favorite':
        this.favProjectClick(project);
        break;
      }
    },
    isJiraProject(ganttID) {
      const project = projectsModel.getProjectDataById(ganttID);

      return project && project.is_jira;
    },
    getOwnerOnProject(ganttID) {
      return ganttID ? rights.project.getOwnerByGanttId(ganttID) : null;
    },
    archiveProject(ganttId) {
      const removeMultiviewIDs = [];

      const route = routerHelper.getCurrentRoute();

      this.projects = this.projects.map(project => {
        if (project.type === 'multiview' && project.ganttIDs.includes(ganttId)) {
          const ganttIDs = project.ganttIDs.filter(id => id !== ganttId);

          if (ganttIDs.length < 1) removeMultiviewIDs.push(project.id);

          return { ...project, ...{ ganttIDs } };
        }

        return project;
      });

      return projectComponent.archiveProjectByGanttId(ganttId)
        .then(() => {
          this.projects = this.projects.filter(item => !removeMultiviewIDs.includes(item.id));
          this.projects = this.projects.map(project => {
            if (project.id === ganttId) {
              return { ...project, ...{ is_archived: 1, is_favorite: false } };
            }

            return project;
          });

          app.trigger('sideBar:update:favorite');
          const activeProjects = this.projects.filter(project => project.is_archived !== 1);

          if (activeProjects.length < 1 && !route.path.startsWith('/project')) {
            this.sideBarBack();
            routerHelper.changeRoute('/newproject');
          }
        });
    },
    unArchiveProject(ganttId) {
      return projectComponent.unArchiveProjectByGanttId(ganttId)
        .then(() => {
          userExtAnalytics.log('project_unarchive', { from: 'project' });
          this.projects = this.projects.map(project => {
            if (project.id === ganttId) {
              if (ganttId === gantt.config.gantt_id) {
                globalStore.commit('headerView/setFavorite', false);
              }

              const projectData = globalStore.getters['tasksModel/getItem'](project.gantt_id)?.tasks;

              const totalEstimateTask = _.find(projectData, task => _.isNull(task.parent) || task.parent === 0);

              if (totalEstimateTask && totalEstimateTask.id) {
                project.progress = totalEstimateTask.progress * 100;
              } else {
                project.progress = 0;
              }

              project.progress = Number.isNaN(project.progress) ? 0 : Math.floor(project.progress);

              project.unassignedTasks = _.filter(projectData, task => task.type === gantt.config.types.task && task.resources?.length === 0).length;

              project.countOfOverdue = 0;
              project.countOfSlightlyOverdue = 0;
              project.countOfPerSchedule = 0;
              project.type = 'project';
              let unassignedTasksLength = 0;

              _.each(projectData, task => {
                if (task.type !== gantt.config.types.task) {
                  return;
                }
                if (task.type === gantt.config.types.task && task.resources.length === 0) {
                  unassignedTasksLength++;
                }

                const endDate = task.end_date || gantt.calculateEndDate({
                  start_date: new Date(task.start_date),
                  duration: task.duration,
                  task,
                });
                const date1 = new Date();

                if (date1 > new Date(endDate) && task.progress < 1 && task.type === 'task') {
                  project.countOfOverdue++;
                } else if (date1 > new Date(task.start_date) && date1 < new Date(endDate) && task.progress === 0) {
                  project.countOfSlightlyOverdue++;
                } else if (task.progress === 1) {
                  project.countOfPerSchedule++;
                }
              });
              project.unassignedTasks = unassignedTasksLength;

              project.last_update = new Date(project.last_update).valueOf();

              return { ...project, ...{ is_archived: 0 } };
            }

            return project;
          });
        });
    },
    getItemsForContextMenu(project) {
      const isMultiview = project.type === 'multiview';
      const isSingleProject = !isMultiview;
      const isArchived = project.is_archived;
      const isFavorite = project.is_favorite;
      const CURRENT_CONTEXT_MENU = [];
      const sortedProjects = this.projects.filter(p => !!p.gantt_id && !p.is_jira);
      const accessToActionsWithProjects = rights.account.hasRightToActionsWithProjects(project.id);
      const accessToMultiview = rights.account.hasRight('multiview_all_features');

      if (isSingleProject && accessToActionsWithProjects) {
        if (isArchived) {
          CURRENT_CONTEXT_MENU.push(this.restoreItem, this.deleteItem);
        } else {
          CURRENT_CONTEXT_MENU.push(this.favoriteItem(isFavorite));

          if (this.isJiraProject(project.gantt_id)) {
            CURRENT_CONTEXT_MENU.push(this.deleteItem);
          } else {
            CURRENT_CONTEXT_MENU.push(this.archiveItem, this.deleteItem);
          }
        }
      }

      if (isMultiview && accessToMultiview) {
        CURRENT_CONTEXT_MENU.push(this.editMultiviewItem, this.deleteItem);
      }

      return CURRENT_CONTEXT_MENU;
    },
    onChangeSelect(id, project) {
      const statusId = globalStore.getters['teamSetting/projectsWithStatus'](+project.id).status_id;

      if (statusId) {
        const currentProject = project.id;

        globalStore.dispatch('teamSetting/updateProjectStatus', { id, currentProject, statusId });
      }
    },
  },
};

</script>

<style src="../../../less/_projects_popup.less" lang="less"></style>
