var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowUpgradePopup
    ? _c("div", { class: _vm.$style.upgradeCard }, [
        _vm.userRights.owner
          ? _c("div", { class: [_vm.$style.btns] }, [
              _vm.discount
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.$style.upgradeCard_btn,
                        _vm.discount ? _vm.$style.discount : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.upgradeClick()
                        },
                      },
                    },
                    [
                      _c("b", [
                        _vm._v("\n        - "),
                        _vm.discount.isFixedDiscount
                          ? _c("span", [_vm._v("$ ")])
                          : _vm._e(),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.discount.percent) +
                            "\n      "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("i", [
                        !_vm.discount.isFixedDiscount
                          ? _c("span", [_vm._v("%")])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.discount.couponValidDays
                        ? _c("i", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.discount.coupon_till) +
                                " "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.discount.tillDate))]),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.isOpenSideBar
                      ? _vm.$style.upgradeCard_btn
                      : _vm.$style.upgrade,
                  ],
                  attrs: { id: "gp_autotest_sidebar_upgrade_button" },
                  on: {
                    click: function ($event) {
                      return _vm.upgradeClick()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.upgrade.btnName) + "\n    ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenSideBar
          ? _c(
              "div",
              { class: _vm.$style.upgradeCard_txt },
              [
                _c("span", [_vm._v(" " + _vm._s(_vm.upgrade.txt) + " ")]),
                _vm._v(" "),
                _c("tooltip", {
                  class: _vm.$style.tooltips,
                  attrs: { content: _vm.upgrade.tooltip },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("svg-sprite", {
                              class: [_vm.$style.upgradeCard_icon],
                              attrs: { name: "info", size: 20 },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2590244537
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenSideBar
          ? _c("div", { class: _vm.$style.upgradeCard_progressBar }, [
              _c("div", {
                class: _vm.$style.upgradeCard_progressBar_progress,
                style: { width: (_vm.daysLeft / 14) * 100 + "%" },
              }),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }