<template>
  <core-popup
    :show="show"
    :width="283"
  >
    <template #body>
      <div :class="$.create_new_event_popup">
        <div :class="$.header">
          <div :class="$.title">
            <div :class="$.title__date">
              {{ `${date.dayWeek}, ${date.dayMonth} ${date.month}` }}
            </div>
            <div :class="$.title__desc">
              {{ locales('create_new_event_title') }}
            </div>
          </div>
          <div :class="$.close">
            <icon-button
              :icon="{ name:'close-1', type:'bold' }"
              size="small"
              @onClick="onClose"
            />
          </div>
        </div>
        <div :class="$.body">
          <vgp-input
            ref="nameInput"
            v-model="taskName"
            :initial-focus="true"
            :value="taskName"
            :small="true"
            :placeholder="locales('new_event_task_name')"
          />
          <div :class="$.resources">
            <div
              v-if="!selectedResources.length && !isShowResourcesList"
              :class="$.resources__assign_button"
            >
              <icon-button
                :icon="{ name: 'assigned' }"
                size="middle"
                bold
                :label="locales('settings_task_resources_add_new')"
                :colors="buttonColors"
                :sizes="buttonLabelSizes"
                @onClick="openResourcesList"
              />
            </div>
            <vgp-dropdown
              v-else
              ref="dropdown"
              class="gp_autotest_calendar_view_event_popup_dropdown"
              :drop-list="preparedResources"
              :selected="selectedResources"
              :icon="{ name: 'user', size: 20, type: 'regular' }"
              :multiselect="true"
              :use-search="true"
              :use-select-all="true"
              :show-reset-btn="false"
              :item-template="'user'"
              :track-by-img-url="'photo'"
              :track-by="'id'"
              :track-by-text="'name'"
              :track-by-icon-name="'icon'"
              :track-by-bg-color="'color'"
              :group-by="'group'"
              :show-selected-first="true"
              :placeholder="''"
              @change="onChange"
              @close="closeResourcesList"
            />
          </div>
        </div>
        <div>
          <vgp-button
            type="primary"
            :disabled="taskNameString === ''"
            :label="locales('create_new_event_task')"
            @onClick="onCreateTask"
          />
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>
import CorePopup from '../../../common/VueComponents/popups/corePopup/corePopup.vue';
import IconButton from '../../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import VgpInput from '../../../common/VueComponents/vInput/vgpInput.vue';
import VgpButton from '../../../common/VueComponents/globalButton/vgpButton.vue';
import VgpDropdown from '../../../common/VueComponents/dropdownSelect/dropdownSelect.vue';

export default {
  name: 'CalendarNewEventPopup',
  components: {
    CorePopup,
    IconButton,
    VgpInput,
    VgpButton,
    VgpDropdown,
  },
  props: {
    show: { type: Boolean, required: true, default: false },
    date: { type: Object, required: true, default: () => {} },
    projectId: { type: Number || String, required: true },
  },
  emits: {
    close: null,
    createTask: Object,
  },
  data() {
    return {
      taskName: '',
      locales: __,
      rollingItemsKey: 0,
      selectedResources: [],
      selectedResourcesMap: null,
      isShowResourcesList: false,
      isDisabledButton: true,
      buttonColors: {
        color: '#1565C0',
        labelColor: '#1565C0',
        activeColor: '#11519A',
        hoverBg: '#D0E0F2',
        activeBg: '#A1C1E6',
        activeHoverBg: '#D0E0F2',
      },
      buttonLabelSizes: {
        labelFontSize: '14px',
        labelLineHeight: '24px',
      },
    };
  },
  computed: {
    resources() {
      const people = this.$store.getters['resourcesModel/getRealResourcesByGanttId'](this.projectId);
      const material = this.$store.getters['resourcesModel/getMaterialResourcesByGanttId'](this.projectId);

      return people.concat(material);
    },
    preparedResources() {
      const prepared = this.resources.map(r => this.prepareResourceGroup(r));

      return prepared;
    },
    resourcesLength() {
      return this.resources.filter(r => !r.groupTitle).length;
    },
    taskNameString() {
      return this.taskName.trim();
    },
  },
  watch: {
    resources: {
      handler(resourcesData) {
        if (resourcesData && this.show && this.selectedResources.length) {
          const selected = resourcesData.filter(resource => this.selectedResourcesMap.has(resource.id));

          this.selectedResourcesMap.clear();

          selected.length && selected.forEach(resource => {
            const resourceWithGroup = this.prepareResourceGroup(resource);

            this.selectedResourcesMap.set(resource.id, resourceWithGroup);
          });

          this.selectedResources = selected;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.selectedResourcesMap = new Map();
  },
  methods: {
    prepareResourceGroup(resource) {
      const prepared = {
        ...resource,
      };

      if (!resource.photo) {
        prepared.photo = `${GT.cdn}/imgs/default/default-ava.svg`;
      }

      if (!resource.userId) {
        prepared.group = `${__('resources_manage_layout_header_title_2')}`;
      }

      return prepared;
    },
    openResourcesList() {
      this.isShowResourcesList = true;
      setTimeout(() => {
        const dropdown = this.$refs.dropdown;

        dropdown.openPopup();
      }, 10);
    },
    closeResourcesList() {
      this.isShowResourcesList = false;
    },
    onClose() {
      this.$emit('close');

      this.selectedResources = [];
      this.selectedResourcesMap.clear();
      this.isShowResourcesList = false;
      this.taskName = '';
    },
    onChange(selectedResources) {
      this.selectedResourcesMap.clear();
      this.selectedResources = selectedResources;

      if (selectedResources.length) {
        selectedResources.forEach(r => {
          this.selectedResourcesMap.set(r.id, r);
        });
      }
    },
    onCreateTask() {
      const name = this.taskName.trim();

      if (name === '') {
        this.taskName = '';
        this.$refs.nameInput.focusElement();

        return;
      }

      const task = {
        created_at: new Date(),
        type: 'task',
        text: name,
        priority: 7,
        status: 1,
        resources: this.selectedResources,
      };

      this.$emit('createTask', task, this.date.fullDate);

      this.onClose();
    },
  },
};
</script>

<style module='$' src='./newEventPopup.less' lang='less'></style>
