var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.role_title] },
    [
      _vm.isOpen
        ? _c("dropdown-roles", {
            attrs: {
              "access-to-delete": _vm.accessToDelete,
              "access-to-edit": _vm.accessToEdit,
              type: _vm.type,
            },
            on: {
              onClick: _vm.toggleEdit,
              onDelete: _vm.toggleFromDelete,
              onClose: _vm.toggleOpen,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowForm
        ? _c("core-popup", {
            attrs: {
              "close-icon": true,
              show: _vm.isShowForm,
              title: _vm.locales("delete_" + _vm.type + "_role_title"),
            },
            on: { onClickCancel: _vm.toggleFromDelete },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("ValidationObserver", {
                        ref: "form",
                        attrs: { tag: "div" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var handleSubmit = ref.handleSubmit
                                return [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$style.add_form,
                                        _vm.$style.form_max,
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { class: [_vm.$style.form_line] },
                                        [
                                          _vm.isRoleSet
                                            ? _c("p", [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.locales(
                                                        "delete_role_" +
                                                          _vm.type +
                                                          "_info_description"
                                                      )
                                                    ) +
                                                    "\n            "
                                                ),
                                              ])
                                            : _c("p", [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.locales(
                                                        "delete_not_set_role_" +
                                                          _vm.type +
                                                          "_info_description"
                                                      )
                                                    ) +
                                                    "\n            "
                                                ),
                                              ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isRoleSet
                                        ? _c(
                                            "div",
                                            { class: [_vm.$style.form_line] },
                                            [
                                              _c("div", {
                                                class: [_vm.$style.delimiter],
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isRoleSet
                                        ? _c(
                                            "div",
                                            {
                                              class: [_vm.$style.form_line_15],
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.locales(
                                                      "delete_role_" +
                                                        _vm.type +
                                                        "_choose_description"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isRoleSet
                                        ? _c("vgp-label-slot", {
                                            attrs: {
                                              label: _vm.locales(
                                                "change_new_role_" +
                                                  _vm.type +
                                                  "_form_list_inherit"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "body",
                                                  fn: function () {
                                                    return [
                                                      _c("ValidationProvider", {
                                                        ref: "roleIdValidationProvider",
                                                        attrs: {
                                                          rules: "required",
                                                          vid: "roleId",
                                                          "custom-messages": {
                                                            required:
                                                              _vm.locales(
                                                                "error_required_field"
                                                              ),
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var errors =
                                                                  ref.errors
                                                                return [
                                                                  _c(
                                                                    "vgp-dropdown-select",
                                                                    {
                                                                      ref: "roleId",
                                                                      staticClass:
                                                                        "dropdown",
                                                                      class: [
                                                                        _vm
                                                                          .$style
                                                                          .vgp_select,
                                                                      ],
                                                                      attrs: {
                                                                        "gp-autotest-name":
                                                                          "gp_autotest_" +
                                                                          _vm.type +
                                                                          "_role_popup_delete_role_dropdown_select_change_role",
                                                                        "highlight-active-state": false,
                                                                        "show-selected-first": true,
                                                                        "show-reset-btn": false,
                                                                        selected:
                                                                          _vm.setDefault,
                                                                        "drop-list":
                                                                          _vm.defaultRolesSelect,
                                                                        "item-template":
                                                                          "text",
                                                                        multiselect: false,
                                                                        "track-by-text":
                                                                          "title",
                                                                        "fixed-width": 320,
                                                                        size: "autoWidth",
                                                                        "need-description": true,
                                                                        "is-error":
                                                                          !!errors[0],
                                                                      },
                                                                      on: {
                                                                        selected:
                                                                          _vm.changeSelectRole,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.roleId,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.roleId =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "roleId",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  errors[0]
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            [
                                                                              _vm
                                                                                .$style
                                                                                .error,
                                                                            ],
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                  " +
                                                                              _vm._s(
                                                                                errors[0]
                                                                              ) +
                                                                              "\n                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: [_vm.$style.form_line_buttons],
                                        },
                                        [
                                          _c("vgp-button", {
                                            attrs: {
                                              id:
                                                "gp_autotest_" +
                                                _vm.type +
                                                "_role_popup_delete_role_btn_cancel",
                                              type: "secondary",
                                              small: true,
                                              label: _vm.locales(
                                                "role_delete_cancel_btn"
                                              ),
                                            },
                                            on: {
                                              onClick: _vm.toggleFromDelete,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("vgp-button", {
                                            attrs: {
                                              id:
                                                "gp_autotest_" +
                                                _vm.type +
                                                "_role_popup_delete_role_btn_delete",
                                              type: "destructive",
                                              small: true,
                                              label:
                                                _vm.locales(
                                                  "role_delete_ok_btn"
                                                ),
                                            },
                                            on: {
                                              onClick: _vm.onClickDeleteRole,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1757012017
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              584519154
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.showInput
        ? _c(
            "div",
            {
              class: [
                _vm.$style.role_title_wrap,
                _vm.checkDefaultRole(_vm.name) ||
                !_vm.accessToEdit ||
                (_vm.isEditMode && _vm.itemId !== _vm.editItem)
                  ? null
                  : _vm.$style.hover,
              ],
            },
            [
              !_vm.checkDefaultRole(_vm.name)
                ? _c(
                    "span",
                    _vm._g(
                      {
                        attrs: {
                          id:
                            "gp_autotest_" +
                            _vm.type +
                            "_role_table_title_" +
                            (_vm.checkDefaultRole(_vm.name)
                              ? _vm.name.toLowerCase()
                              : "custom_" + _vm.itemId),
                        },
                      },
                      _vm.checkDefaultRole(_vm.name) ||
                        !_vm.accessToEdit ||
                        (_vm.isEditMode && _vm.itemId !== _vm.editItem)
                        ? { click: null }
                        : { click: _vm.activateForm }
                    ),
                    [
                      _c("tooltip", {
                        class: [_vm.$style.tooltip_title],
                        attrs: {
                          hide: !_vm.checkNameLength(_vm.name),
                          content: _vm.locales(
                            "" + _vm.replaceScriptTag(_vm.name)
                          ),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.name) +
                                      "\n        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1394582567
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      attrs: {
                        id:
                          "gp_autotest_" +
                          _vm.type +
                          "_role_table_title_" +
                          (_vm.checkDefaultRole(_vm.name)
                            ? _vm.name.toLowerCase()
                            : "custom_" + _vm.itemId),
                      },
                    },
                    [
                      _c("tooltip", {
                        class: [_vm.$style.tooltip_title],
                        attrs: {
                          hide: !_vm.checkNameLength(_vm.type + "_" + _vm.name),
                          content: _vm.locales(_vm.type + "_" + _vm.name),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.locales(_vm.type + "_" + _vm.name)
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3264275994
                        ),
                      }),
                    ],
                    1
                  ),
            ]
          )
        : _c(
            "div",
            { class: [_vm.$style.role_title_wrap_input] },
            [
              _c("vgp-input", {
                ref: "input",
                class: ["public-url-form-input"],
                attrs: {
                  "gp-autotest-name":
                    "gp_autotest_" +
                    _vm.type +
                    "_role_table_title_input_" +
                    _vm.itemId,
                  placeholder: _vm.name,
                  small: true,
                },
                on: {
                  onBlur: function ($event) {
                    $event.preventDefault()
                    return _vm.changeInput($event)
                  },
                  onKeydown: _vm.keyDownInput,
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.checkDefaultRole(_vm.name)
        ? _c("tooltip", {
            class: [_vm.$style.tooltips],
            attrs: {
              content: _vm.locales(
                "role_" + _vm.type + "_help_text_" + _vm.name.toLowerCase()
              ),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("svg-sprite", {
                        class: [_vm.$style.role_title_icon_help],
                        attrs: {
                          name: "help",
                          size: "24",
                          type: "regular",
                          color: "#B2B2B2",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              383517871
            ),
          })
        : _vm.accessToEditAndDelete && _vm.hasAccessSubscribe
        ? _c("icon-button", {
            class: [
              _vm.isEditMode && _vm.itemId !== _vm.editItem
                ? _vm.$style.button_no_hover
                : "",
              _vm.$style.button_icon,
            ],
            attrs: {
              id:
                "gp_autotest_" +
                _vm.type +
                "_role_table_title_dots_" +
                (_vm.checkDefaultRole(_vm.name)
                  ? _vm.name.toLowerCase()
                  : "custom_" + _vm.itemId),
              icon: { name: "more-2", type: "bold", size: 24 },
              active: _vm.isOpen,
            },
            on: { onClick: _vm.onClickEdit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }