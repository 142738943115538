import app from "./../../app";
import helpInfo from "./../../models/helpInfo";

var innerObject = {
  mainView: {
    id: 'globalVideoFullscreen'
  },
  videoTemplate: function () {
    return "<div class='global-video-fullscreen'>" +
      "<div class='global-video " + innerObject.pictureNode.target.getAttribute('data-video-class') + "'>" +
      "<div class='global-video-close js-show-global-video-fullscreen-close'>" +
      "<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14'><path fill='#FFF' fill-rule='evenodd' d='M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z'/></svg>" +
      "</div>" +
      "<iframe style='background: black' src='" + innerObject.pictureNode.target.getAttribute('data-video-src') + "?enablejsapi=1&autoplay=1&modestbranding=0&autohide=0&showinfo=0&controls=1&rel=0' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>" +
      "</div>" +
      "</div>";
  }
};

webix.ui({
  view: "window",
  id: innerObject.mainView.id,
  css: 'global-video-fullscreen-wrapper',
  // zIndex: 1001,
  fullscreen: true,
  modal: true,
  borderless: true,
  move: true,
  type: "line",
  margin: 0,
  isDrag: false,
  head: false,
  zIndex: 10000,
  body: {
    view: 'template',
    id: 'globalVideoFullscreenBody',
    css: 'global-video-fullscreen-inner',
    paddingX: 30,
    paddingY: 30,
    type: "space",
    margin: 1,
    template: function (e) {
      return innerObject.videoTemplate(e);
    },
    onClick: {
      "js-show-global-video-fullscreen-close": function (e) {
        $$('globalVideoFullscreen').hide();
      }
    }
  },
  on: {
    'onHide': function () {
      var el = $$('globalVideoFullscreen').getNode().querySelector('.global-video-fullscreen');

      el.parentNode.removeChild(el);
      app.trigger("popup:hide");
    }
  }
}).hide();

var outputObject = {
  init: function (e) {

    innerObject.pictureNode = e;

    if (!innerObject.pictureNode.target.getAttribute('data-video-src')) {
      console.error('No data-video-src found');
      return;
    }
    sendAnalytic(innerObject.pictureNode.target.getAttribute('data-video-id'))

    $$('globalVideoFullscreenBody').define('template', innerObject.videoTemplate());
    $$('globalVideoFullscreenBody').refresh();
    $$('globalVideoFullscreen').show();
  }
};

function sendAnalytic(popup_id) {
  let id = popup_id;
  switch (popup_id) {
    case 'workloadHelpBlock': id = 'workload'; break;
    case 'settingsHelpBlock': id = 'project settings'; break;
    case 'taskCostHelpBlock': id = 'cost field'; break;
    case 'publicLinkHelpBlock': id = 'public url'; break;
    case 'manageTeamLayoutHelpPopup': id = 'team & resources'; break;
    case 'saveUserTemplateHelpBlock': id = 'save template'; break;
    case 'newProjectBlock': id = 'new project'; break;
    case 'historyHelpPopup': id = 'history'; break;
    case 'customGridHelpBlock': id = 'custom grid columns'; break;
    case 'filterViewHelpPopup': id = 'filter settings'; break;
    case 'importProjectBlock': id = 'project import'; break;
    case 'resourcesLayoutHelpPopup': id = 'people'; break;
  }
  userExtAnalytics.log('help_video_popup_play', { id: id });
}

export default outputObject;
