<template>
  <skeleton-wrapper style="height: 100%">
    <template #body>
      <div
        :class="$.wrapper"
      >
        <div :class="$.controls">
          <skeleton-item
            :style="{width: `100px`, height: '12px'}"
            :type="'bar'"
          />
        </div>
        <div :class="$.right_side">
          <div :class="$.btn_group">
            <skeleton-item
              :style="{width: `100px`, height: '12px'}"
              :type="'bar'"
            />
          </div>
          <skeleton-item
            :style="{width: `100px`, height: '24px'}"
            :type="'bar'"
          />
        </div>
      </div>
    </template>
  </skeleton-wrapper>
</template>

<script>

import SkeletonItem from '$$vueCmp/skeletons/skeletonItem.vue';
import SkeletonWrapper from '$$vueCmp/skeletons/skeletonWrapper.vue';

export default {
  name: 'SkeletonMain',
  components: { SkeletonWrapper, SkeletonItem },

};
</script>

<style module="$" src="../less/overview.less"></style>
