<template>
  <div
    v-click-outside="close"
    :class="$style.select"
  >
    <vgp-icon-button
      ref="contextMenuButton"
      :icon="{ name: showMenu ? 'arrow-top' : 'arrow-down', size: 20 }"
      :label="locales('group_by_label')"
      icon-side="right"
      :colors="buttonColors"
      :active="showMenu || activeItems.length"
      :class="[]"
      @onClick="toggleShow"
    />

    <div
      v-if="activeItems.length"
      :class="$style.chips"
    >
      <div
        v-for="(item, index) in activeItems"
        :key="index"
        :class="$style.chips_item"
        @click="onClick(item.id)"
      >
        <div :class="$style.text">{{ item.text }}</div>
        <svg-sprite
          :class="$style.icon"
          name="close-2"
          type="bold"
          size="20"
        />
      </div>
    </div>

    <context-menu
      v-if="showMenu"
      :position="positionContext"
    >
      <template #body>
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="[
            $style.menu_item,
            $style.item_check,
            activeIds.includes(item.id) ? $style.active : '',
          ]"
          @click="onClick(item.id)"
        >
          <div :class="$style.checkbox">
            <vgp-checkbox
              size="16"
              :checked="activeIds.includes(item.id)"
            />
          </div>
          <div :class="$style.text">
            {{ item.text }}
          </div>
        </div>
      </template>
    </context-menu>
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpCheckbox from "$$vueCmp/checkbox/vgpCheckbox.vue";
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';

export default {
  components: {
    svgSprite,
    vgpCheckbox,
    vgpIconButton,
  },
  props: {
    onSelect: {
      type: Function,
      default: null,
    },
    buttonColors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locales: __,
      showMenu: false,
      positionContext: null,
    };
  },
  computed: {
    items() {
      return this.$store.getters['headerView/groupByData'];
    },
    activeIds() {
      return this.$store.getters['headerView/groupByActiveData'];
    },
    activeItems() {
      return this.items.filter(item => this.activeIds.includes(item.id));
    },
  },
  methods: {
    onClick(id) {
      let data;

      if (this.activeIds.includes(id)) {
        data = this.activeIds.filter(item => item !== id);
      } else {
        data = [...this.activeIds, id];
      }

      if (this.onSelect) this.onSelect(data);
    },
    toggleShow() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();
      }
    },
    close() {
      this.showMenu = false;
    },
  },
};
</script>

<style module src="../less/select.less"></style>
