<template>
  <section
    ref="commentHistory"
    :class="[$style['task-settings-comments']]"
    :style="{width: '100%'}"
  >
    <div
      v-if="showTask"
      :class="[$style.header, 'gp_autotest_comment_hub_comments_list_header']"
    >
      <h2>
        {{ locales('settings_taskComment') }}
      </h2>
    </div>
    <div
      ref="commentList"
      :class="[$style['comments__list'], 'gp_autotest_comment_hub_comments_list']"
    >
      <div v-for="(groupComments, groupName) in groupedCommentsByDate.grouped">
        <div
          v-if="commentHistoryLabelsShown"
          :class="$style['comments__group-history-date']"
        >
          {{ groupName }}
        </div>

        <div
          v-for="(comment, index) in groupComments"
          :id="comment.id"
          :key="comment.id"
        >
          <comment-item
            :id="`gp_autotest_comment_hub_comment_item_${comment.id}`"
            :comment="comment"
            @comment-delete="handleClickDeleteComment"
            @edit-comment="$emit('edit-comment', comment)"
          />
        </div>
      </div>

      <div
        v-if="groupedCommentsByDate.unread.length"
      >
        <div
          v-if="commentHistoryUnreadLabelsShown"
          :class="[$style['comments__group-history-date'], 'gp_autotest_comment_hub_comments_list_unread_label']"
        >
          {{ locales(commentHistoryUnreadLabel) }}
        </div>
        <div
          v-for="(comment, index) in groupedCommentsByDate.unread"
          :id="comment.id"
          :key="comment.id"
          ref="commentHistoryUnreadComments"
        >
          <comment-item
            :id="`gp_autotest_comment_hub_comment_item_unread_${comment.id}`"
            :comment="comment"
            @comment-delete="handleClickDeleteComment"
            @edit-comment="$emit('edit-comment', comment)"
          />
        </div>
      </div>
      <div
        :style="{ width: '100%', height: '24px', display: 'flex'}"
      />
    </div>
    <core-confirm-popup
      class="gp_autotest_comment_hub_confirm_popup"
      :show="currentCommentIdForDelete"
      :text="locales('settings_msg_delete_comment')"
      :ok-button="{ type: 'destructive', title: locales('common_yes') }"
      :cancel-button="{ type: 'secondary', title: locales('common_cancel') }"
      @onClickOk="handleConfirmDeleteComment"
      @onClickCancel="currentCommentIdForDelete = null"
    />
  </section>
</template>

<script>

import _ from 'lodash';
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import { replaceIdsWithMentionsHtml } from '$$helpers/comments';
import moment from '../../../libs/moment';
import constants from '../../../helpers/constants';
import commentItem from '../../taskSettings/sections/comments/CommentItem.vue';

export default {
  name: 'TaskSettingsComments',
  components: { CoreConfirmPopup, VgpIconButton, commentItem },
  inject: ['isFieldDisabled'],
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    taskData: {
      type: Object,
      default: null,
    },
    taskId: {
      type: String,
      required: false,
      default: '',
    },
    showTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    commentEditor: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      locales: __,
      tribute: null,
      currentCommentIdForDelete: null,
      openCommentsTimestamp: null,
      openCommentsTimestampUnread: null,
      commentHistoryUnreadLabel: 'comment_history_unread_label',
      unreadCommentIds: [],
    };
  },
  computed: {
    commentHistoryLabelsShown() {
      // Show labels if there are comments on at least two different days

      if (!this.groupedCommentsByDate.unread?.length) return true;

      return Object.keys(this.groupedCommentsByDate).length >= 2;
    },
    commentHistoryUnreadLabelsShown() {
      return !!(this.unreadCommentIds.length || this.groupedCommentsByDate?.unread?.length);
    },
    // unreadCommentIds() {
    //   const commentsIds = this.comments.map(comment => comment.id);
    //   const unreadTaskCommentsActions = this.$store.state.comments.commentsActions.filter(commentAction => (
    //     commentsIds.includes(commentAction.commentId) && !commentAction.read
    //   ));

    //   return unreadTaskCommentsActions.map(commentAction => commentAction.commentId);
    // },
    groupedCommentsByDate() {
      const today = moment().format(`dddd ${user.dateFormat}`);
      const yesterday = moment().add(-1, 'days').format(`dddd ${user.dateFormat}`);

      const unreadComments = [];

      const groupedComments = {};

      this.formattedComments.forEach(item => {
        const commentDate = moment(item.rawCreatedAt).format(`dddd ${user.dateFormat}`);
        const commentId = this.openCommentsTimestampUnread?.commentId || this.openCommentsTimestampUnread?.id;

        if (

          (this.openCommentsTimestampUnread && (item.id >= commentId) && user.id !== item.authorId)

            || (this.openCommentsTimestamp && (item.id > this.openCommentsTimestamp.commentId) && user.id !== item.authorId)) {
          unreadComments.push(item);

          return;
        }

        if (commentDate === today) {
          if (!groupedComments[this.locales('comment_history_today_label')]) {
            groupedComments[this.locales('comment_history_today_label')] = [];
          }
          if (!unreadComments.some(i => i.id !== item.id)) groupedComments[this.locales('comment_history_today_label')].push(item);
        } else if (commentDate === yesterday) {
          if (!groupedComments[this.locales('comment_history_yesterday_label')]) {
            groupedComments[this.locales('comment_history_yesterday_label')] = [];
          }
          if (!unreadComments.some(i => i.id !== item.id)) groupedComments[this.locales('comment_history_yesterday_label')].push(item);
        } else {
          if (!groupedComments[commentDate]) {
            groupedComments[commentDate] = [];
          }
          if (!unreadComments.some(i => i.id !== item.id)) groupedComments[commentDate].push(item);
        }
      });

      return {
        grouped: groupedComments,
        unread: unreadComments,
      };
    },
    formattedComments() {
      return [...this.comments].map(comment => {
        const {
          id, content, user: { resourceId, firstName, lastName }, createdAt, updatedAt, userId, taskId,
        } = comment;
        const momentTimeFormat = user.timeFormat ? 'HH:mm' : 'hh:mm A';
        const userResource = this.$store.getters['resourcesModel/getResourceById'](resourceId);
        const authorFullName = userResource
          ? userResource.name
          : `${firstName} ${lastName} (${__('task_settings_author_deleted_label')})`;
        const authorPhoto = userResource ? userResource.photo : constants.COMMON_USER_PHOTO;

        return {
          id,
          content: replaceIdsWithMentionsHtml(content),
          authorFullName,
          authorId: userId,
          authorPhoto,
          taskId,
          createdAt: moment(createdAt).format(`${user.dateFormat}, ${momentTimeFormat}`),
          rawCreatedAt: createdAt,
          updatedAt: updatedAt ? moment(updatedAt).format(`${user.dateFormat}, ${momentTimeFormat}`) : null,
        };
      }).sort((a, b) => a.id - b.id);
    },
    unread() {
      const commentsIds = this.comments.map(comment => comment.id);
      const result = [];

      commentsIds.forEach(commentId => {
        const commentAction = this.$store.state.comments.commentsActionsMap[commentId];

        if (!commentAction?.read) {
          result.push(commentAction);
        }
      });

      return result;
    },

    commentsShorts() {
      const commentsIds = this.comments.map(comment => comment.id);

      return commentsIds.map(commentId => this.$store.state.comments.commentsActionsMap[commentId]);
    },
  },
  watch: {
    taskData() {
      this.$nextTick(() => {
        this.$emit('comments-height', this.$refs.commentList?.getBoundingClientRect().height);
      });
    },
    taskId() {
      this.$nextTick(() => {
        this.init();
      });
    },
    comments() {
      this.updateUnreadData();
    },
    formattedComments: {
      handler(val, oldVal) {
        if (val.length > oldVal.length && oldVal[0].taskId === this.taskId) {
          this.$nextTick(() => {
            if (val.at(-1).authorId === user.id) {
              this.openCommentsTimestamp = val.at(-1);
              this.openCommentsTimestampUnread = null;
              this.$emit('new-comment');

              return;
            }
            const ref = this.$refs.commentHistoryUnreadComments;
            const commentElements = ref?.at(0);

            this.openCommentsTimestampUnread = val.find(c => c.id === +ref?.at(0).id);
            this.$emit('new-comment');

            ref && ref.forEach(element => {
              this.observer?.observe(element);
            });
          });
        }
      },
    },
    unreadCommentIds() {
      if (this.unreadCommentIds.length) {
        this.$nextTick(() => {
          this.observer = new IntersectionObserver(
            this.handleIntersectionDebounced,
            { root: null, rootMargin: '0px', threshold: 0.5 },
          );

          const commentElements = this.$refs.commentHistoryUnreadComments;

          commentElements && commentElements.forEach(element => {
            this.observer.observe(element);
          });
        });
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.$emit('comments-height', this.$refs.commentList?.getBoundingClientRect().height);
    });
    this.handleIntersectionDebounced = _.debounce(this.handleIntersection.bind(this), 500);
  },
  beforeDestroy() {
    this.openCommentsTimestamp = null;
    this.openCommentsTimestampUnread = null;
  },
  mounted() {
    this.init();
  },
  methods: {
    updateUnreadData() {
      if (this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments) {
        this.unreadCommentIds = [...this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments];
      }
    },
    init() {
      let oldestUnread;
      let oldestRead;

      this.updateUnreadData();

      if (this.unread.length) {
        oldestUnread = this.unread.reduce((old, current) => {
          if (new Date(current.date) < new Date(old.date)) {
            return current;
          }

          return old;
        });
      } else if (this.commentsShorts.length && !this.unread.length) {
        oldestRead = this.commentsShorts.reduce((oldest, current) => {
          if (!oldest || current.date > oldest.date) {
            return current;
          }

          return oldest;
        }, null);
      }

      this.openCommentsTimestampUnread = oldestUnread || 0;
      this.openCommentsTimestamp = oldestRead || 0;

      if (this.unreadCommentIds.length === 0) {
        this.$nextTick(() => {
          const el = this.$refs.commentList;
          const { scrollHeight } = el;

          if (el) {
            el.scrollTo({ top: scrollHeight });
            el.scrollIntoView({ block: 'end' });
          }
        });
      } else if (this.unreadCommentIds.length) {
        this.$nextTick(() => {
          let el = this.$refs.commentHistoryUnreadComments[0] || this.$refs.commentHistoryUnreadComments;

          if (el) {
            el.scrollIntoView({ block: 'nearest', inline: 'nearest' });
          }
          this.observer = new IntersectionObserver(
            this.handleIntersectionDebounced,
            { root: null, rootMargin: '0px', threshold: 0.5 },
          );

          const commentElements = this.$refs.commentHistoryUnreadComments;

          commentElements && commentElements.forEach(element => {
            this.observer.observe(element);
          });
        });
      }
    },
    handleIntersection(entries) {
      const commentIds = [];

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const metaData = this.$store.state.comments.taskCommentsMetaData[this.taskId];

          // Comment element is visible in the viewport
          metaData?.unreadComments.has(+entry.target.id) && commentIds.push(+entry.target.id);
        }
      });

      commentIds.length && this.$store.dispatch('comments/markAsRead', { taskIds: [this.taskId], commentIds });
    },

    getCommentDisplayedDateString(comment) {
      if (comment.updatedAt) {
        return `${this.locales('comment_edited_label')} ${comment.updatedAt}`;
      }

      return comment.createdAt;
    },
    showCommentActions(comment) {
      if (this.isFieldDisabled('comments')) return false;

      return comment.authorId === window.user.id;
    },
    handleClickDeleteComment(commentId) {
      this.currentCommentIdForDelete = commentId;
    },
    handleConfirmDeleteComment() {
      this.$emit('delete-comment', this.currentCommentIdForDelete);
      this.currentCommentIdForDelete = null;
    },
  },
};
</script>

<style module lang="less">

.task-settings-comments {

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 11px;
        h2 {
            font-family: Lato-Bold, sans-serif;
            font-size: 14px;
            line-height: 24px;
            color: #191919;
        }
    }

    .comments__list {
        display: flex;
        flex-direction: column;
        gap: 14px;

    }

    .comments__group-history-date {
        width: 100%;
        font-family: Lato-Regular, sans-serif;
        font-size: 10px;
        line-height: 16px;
        padding: 10px 0;
        text-transform: uppercase;
        text-align: center;
        color: #A6A6A6;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &::before, &::after {
            content: '';
            display: block;
            width: 80px;
            height: 1px;
            background: #E5E5E5;
        }
    }

    .comment {
        &__row {
            display: flex;
            align-items: flex-start;
            gap: 16px;
          &:last-child {
            padding-bottom: 24px;
          }
        }

        &__author-image {
            width: 32px;
            height: 32px;
            border-radius: 33px;
        }

        &__info-wrap {
            display: flex;
            flex-direction: column;
            border: 1px solid transparent;
            border-radius: 0 4px 4px 4px;
            max-width: 480px;
            padding: 4px 8px;
            &:hover {
                border: 1px solid #F2F2F2;
                background: #FCFCFC;
                .comment__actions {
                    opacity: 1;
                    transition: 200ms;
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            gap: 11px;
        }

        &__author {
            font-family: Lato-Bold, sans-serif;
            font-size: 12px;
            line-height: 18px;
            color: #808080;
        }

        &__date {
            font-family: Lato-Regular, sans-serif;
            font-size: 12px;
            line-height: 20px;
            color: #999999;
        }

        &__actions {
            display: flex;
            align-items: center;
            gap: 4px;
            opacity: 0;
        }

        &__content {
            font-family: Lato-Regular, sans-serif;
            font-size: 14px;
            line-height: 20px;
            max-width: 100%;
            padding: 4px 0 8px 0;
        }

    }

}

</style>
