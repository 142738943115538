<template>
  <div :class="$style.doughnut_chart_wrapper">
    <div ref="chart" :class="$style.chart_body"></div>
    <div :class="$style.legend">
      <tooltip
        v-for="item in items"
        :key="item.id"
        :content="item.name"
        :position="'top'"
        :delay="100"
        :hide="!isOverflowed[item.name] || isGrayedOut(item.name)"
      >
        <template #body>
          <div
            :class="[$style.legend__item, isGrayedOut(item.name) && $style.grayed_out]"
            @mouseover="highlightSeries(item.name)"
            @mouseleave="downplay(item.name)"
            @click="toggleSelect(item.name)"
          >
            <div :class="$style.legend_color_box" :style="{backgroundColor: item.color}"></div>
            <div ref="legendName" :class="$style.legend_name">{{ item.name }}</div>
          </div>
        </template>
      </tooltip>      
    </div>
  </div>
</template>

<script>
import * as echarts from '../../../../libs/echarts';
import Tooltip from '../tooltips/tooltip.vue';

export default {
  name: 'DoughnutChart',
  components: { Tooltip },
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: []
    },
    static: {
      type: Boolean,
      default: false,
    },
    tooltipTitle: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.$refs.legendName && this.$refs.legendName.length) {
      this.$refs.legendName.forEach((elem) => {
        if (elem.clientWidth === 200) {
          this.isOverflowed = {
            ...this.isOverflowed,
            [elem.textContent]: true
          };
        }
      });
    }

    this.instance = echarts.init(this.$refs.chart);
    this.instance.setOption(this.options);
    this.instance.on('legendselectchanged', (event) => {
      this.itemsSelectState = event.selected;
    });
  },
  beforeDestroy() {
    this.instance.off('legendselectchanged');
    echarts.dispose(this.instance);
  },
  data() {
    return {
      timeout: null,
      itemsSelectState: {},
      isOverflowed: {}
    }
  },
  methods: {
    isGrayedOut(name) {
      return this.itemsSelectState[name] !== undefined && !this.itemsSelectState[name];
    },
    toggleSelect(name) {
      if (this.static) {
        return;
      }

      this.instance.dispatchAction({
          type: 'legendToggleSelect',
          // legend name
          name
      })
    },
    highlightSeries(name) {
      if (this.static) {
        return;
      }

      this.instance.dispatchAction({
        type: 'highlight',
        seriesName: this.id,
        name
      });
    },
    downplay(name) {
      if (this.static) {
        return;
      }

      this.instance.dispatchAction({
        type: 'downplay',
        seriesName: this.id,
        name
      });  
    }
  },
  computed: {
    options() {
      return {
        legend: {
          show: false
        },
        tooltip: (this.static ? false : {
          trigger: 'item',
          formatter: params => `<div class="chart_tooltip">
              <div class="tooltip_title">${this.tooltipTitle}</div>
              <div class="tooltip_info">
                <div class="tooltip_color" style="background: ${params.color};"></div>
                <div class="tooltip_name">${params.name}</div>
                <div class="tooltip_qty">${params.data.qty}</div>
                <div class="tooltip_value">${params.value}%</div>
              </div>
            </div>`,
          borderColor: '#808080',
          borderWidth: 1,
          borderRadius: 4,
          padding: 8,
          confine: true,
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);',
        }),
        series: [{
          name: this.id,
          type: 'pie',
          radius: ['54%', '96%'],
          right: 0,
          itemStyle: {
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#FFFFFF',
            color: params => params.data.color,
          },
          label: {
            show: true,
            position: 'inner',
            formatter: params => (params.value ? `${params.value}%` : ''),
            color: '#FFFFFF',
            fontSize: 16,
            lineHeight: 20,
            fontFamily: 'Lato-Bold, sans-serif',
          },
          emphasis: (this.static ? false : {
            scaleSize: 4,
            itemStyle: {
              borderWidth: 4,
              borderRadius: 8,
            },
          }),
          labelLine: {
            show: false,
          },
          data: this.items,
          animation: !this.static,
          silent: this.static,
        }],
      };
    },
  },
  watch: {
    items() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.instance.setOption({
          series: [{
            name: this.id,
            data: this.items,
          }],
        });
      }, 1000);
    },
  },
}
</script>

<style module src="./less/doughnutChart.less"></style>
