var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.wrapper }, [
    _c(
      "div",
      { class: _vm.$style.dashboard_wrapper },
      _vm._l(_vm.charts, function (chart) {
        return _c(
          "div",
          { class: [_vm.$style.item, chart.large && _vm.$style.large] },
          [
            _c(chart.component, {
              key: chart.key,
              tag: "component",
              attrs: { chart: chart, data: chart.data },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }