import _ from '../../libs/lodash';

export default {
  getNotificationsCount: state => state?.newNotificationCount,

  getNotificationsSettings: state => state?.settings,

  getNotifications: state => state?.notifications,

  getNotificationStore: state => state,

  getIsLoaded: state => state.loaded,

  getUnreadCount: (state, getters) => {
    const notifications = getters.getNotifications;

    return notifications.length - _.filter(notifications, ['read', true]).length;
  },
};
