var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$.progress_bar_layout, _vm.$[_vm.layoutClass]] },
    _vm._l(_vm.targetEvents, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          class: [
            _vm.$.progress_bookmark,
            _vm.checkActive(item.token) ? _vm.$.show : "",
          ],
          attrs: { "data-bookmark-id": item.token },
          on: {
            click: function ($event) {
              return _vm.showProgressToast(item)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(item.progress + "%") + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }