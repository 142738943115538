/* eslint-disable */
import icon_close from "../../svg/ic_close.svg";

const webix = window.webix;

const __ = window.__;
const _PROTO_NAME = 'ganttproWindowPopup';
const _PROTO_CSS = ' ganttpro_window_popup';

const innerSettings = {
  defaultPadding: {
    width: 24,
  },
  defaultHeight: {
    height: 30,
  },
  defaultWidth: {
    width: 30,
  },
  defaultTemplate: {
    view: 'template',
    borderless: true,
  },
};

const _getIcon = function (headerIcon, headerType) {
  return `<div class="icon_item ${headerType}">${headerIcon}</div>`
};

const _getLabel = function () {
  return `<div class="label_item">${this.headerLabel}</div>`
};

const _getCloser = function () {
  return `<div class="closer_item">${icon_close}</div>`
};

const _getDefaultHeader = (mainId, headerIcon, headerLabel, headerHelp, getHelpId, closePopup, headerHeight, headerType) => {
  return {
    id: mainId("_headerId"),
    css: mainId("_headerCss popup_header ") + headerType,
    borderless: true,
    height: headerHeight ? headerHeight : 60,
    // paddingY: 10,
    rows: [
      { height: 20 },
      {
        cols: [
          {
            hidden: !headerIcon,
            css: 'header_view_icon',
            cols: [
              {
                ...innerSettings.defaultPadding,
              },
              {
                ...innerSettings.defaultTemplate,
                width: 20,
                css: 'header_icon',
                template: _getIcon(headerIcon)
              }
            ],
          },
          {
            css: 'header_view_label',
            cols: [
              {
                width: !headerIcon ? 24 : 12,
              },
              {
                ...innerSettings.defaultTemplate,
                css: 'header_label',
                id: mainId("_headerId_label"),
                headerLabel: headerLabel,
                template: _getLabel
              }
            ],
          },
          {
            hidden: !headerHelp,
            id: getHelpId.id,
            dataId: getHelpId.dataId,
            view: 'helpIcon',
            css: 'header_help help_icon_block',
            zIndex: 1,
            ...innerSettings.defaultHeight,
            ...innerSettings.defaultWidth,
          },
          {
            ...innerSettings.defaultTemplate,
            ...innerSettings.defaultWidth,
            css: `header_view_closer`,
            width: 30,
            height: 30,
            template: _getCloser,
            onClick: {
              'closer_item': closePopup,
            }
          },
          {
            width: 12
          }
        ],
      }
    ],
    on: {
      "onKeyPress": function (code, e) {

      }
    }
  }
};

webix.protoUI({
  name: _PROTO_NAME,
  getConfigView: ({
      mainId, header, headerIcon, headerLabel, headerHelp, closePopup, getHelpId, headerHeight, headerType
    }) => ({
    autoheight: true,
    position: 'center',
    borderless: true,
    move: false,
    head: header && header !== false ? _getDefaultHeader(mainId, headerIcon, headerLabel, getHelpId, headerHelp, closePopup, headerHeight, headerType) : false
  }),
  _id: null,
  _is_popup_shown: false,

  $init: function (config) {
    this._id = config.id;
    config.css += _PROTO_CSS;

    webix.extend(config, this.getConfigView({
      mainId: this._mainId.bind(this),
      header: config.header,
      headerHeight: (config.header && config.header.height) ? config.header.height : '',
      headerType: (config.header && config.header.height) ? config.header.type : '',
      headerLabel: (config.header && config.header.label) ? config.header.label : '',
      headerIcon: (config.header && config.header.icon) ? config.header.icon : '',
      headerHelp: (config.header && config.header.help) ? config.header.help : false,
      getHelpId: (config.header && config.header.help) ? config.header.help.id : '',
      closePopup: this.hide.bind(this),
    }));
  },

  updateHead: function(headerIcon, headerLabel){
    var head = $$(this._id + "_headerId_label");

    head.define('headerLabel', headerLabel);
    head.refresh();
  },

  _add_shadow: function (zIndex) {
    if (this.config.modal) {
      return;
    }

    var currentShadow = document.querySelector(".popup_proto_shadow");

    if (!currentShadow) {
      this._shadow = document.createElement('div');

      document.getElementsByTagName('body')[0].appendChild(this._shadow);

      this._shadow.className = 'popup_proto_shadow';
    } else {
      this._shadow = currentShadow;
    }

    this._shadow.style.zIndex = '' + (zIndex - 1);

    if (this._id === 'attachmentsListPopup' || this._id === 'commentsListPopup') {
      this._shadow.style.backdropFilter = 'blur(3px)';
    }

    _.delay(_.bind(function () {
      if(this._shadow) this._shadow.classList.add('show');
    }, this));

  },
  _remove_shadow: function () {
    if (this._shadow) {
      this._shadow.classList.remove('show');
      this._shadow.style.backdropFilter = '';
      this._shadow = null;
    } else {
      this.config.modal = false;
    }
  },
  _toggle_transform_popup: function (isShow) {
    var that = this;

    this.$view.style.transition = 'transform 240ms, opacity 240ms';
    this.$view.style.opacity = '0';
    this.$view.style.transform = 'translateY(25px)';
    this.$view.style.pointerEvents = 'none';
    this.$view.style.display = 'none';

    var callMethod = function () {
      if (!that.$view) {
        return false;
      }
      if (isShow) {
        that.$view.style.opacity = '1';
        that.$view.style.pointerEvents = 'auto';
        that.$view.style.transform = 'translateY(0)';
      } else {
        that.$view.style.opacity = '0';
        that.$view.style.pointerEvents = 'none';
        that.$view.style.transform = 'translateY(25px)';
      }
    };

    isShow ? this.$view.style.display = 'block' : this.$view.style.display = 'none';
    setTimeout(callMethod, 50);
  },
  show: function (node, mode, point) {
    var popupShow = _.bind(webix.ui.window.prototype.show, this);
    popupShow(node, mode, point);

    _.delay(_.bind(function () {

    }, this));

    this._add_shadow(this.getNode().style.zIndex);
    this.callEvent("onShow", []);
    this._toggle_transform_popup(true);
    this._is_popup_shown = true;
  },
  isShown: function () {
    return this._is_popup_shown;
  },
  hide: function (force) {
    var popupHide = _.bind(webix.ui.window.prototype.hide, this);

    if (this._is_popup_shown) {
      this.callEvent("onBeforeAnimationHide", []);
    }
    this._toggle_transform_popup(false);
    this._remove_shadow();
    this._is_popup_shown = false;
    this.callEvent("onAfterAnimationHide", []);
    popupHide(force);
  },
  _mainId: function (id) {
    return `${this._id}${id}`;
  },

}, webix.ui.window);
