import app from '../../../app';
import _ from '../../../libs/lodash';
import timeTrackingModel from '../../../models/timeTracking';
import projectsModel from '../../../models/projects';
import customDayTemplatesModel from '../../../models/customDayTemplates';
import groupByTable from './groupByTable';
import assigneeTemplateHelper from '../../../helpers/getAssigneeAvatarsTemplate';
import moment from '../../../libs/moment';
import exportModule from './export';
import svg from '../../../helpers/svgToURI';
import icon_resource from '../../../svg/default/default-ava.svg';
import calculateMainWindowSize from '../../../helpers/calculateMainWindowSize';
import estimationModule from '../../gantt/modules/estimation';
import globalStore from '../../../store/main';
import rights from '../../../components/rights';

const __ = window.__;

const TYPE = 'costslist';

const filterConfig = [
  {
    filterType: 'text',
    filteredProperty: 'task_name',
  },
  {
    filterType: 'multicombo',
    filteredProperty: 'gantt_id',
    getValues() {
      return _.map(projectsModel.getAllProjects(), proj => ({
        id: proj.id,
        value: proj.name,
      }));
    },
  },
  {
    filterType: 'multicomboseparator',
    filteredProperty: 'resource_id',
    localePostfix: TYPE,
    getValues() {
      const realResources = globalStore.getters['resourcesModel/getAllRealResources'];

      return _.map(_.cloneDeep(realResources), item => {
        item.picture = item.photo || svg(icon_resource);

        return item;
      });
    },
  },
  // {
  //   filterType: "number",
  //   filteredProperty: "cost",
  // },
  // {
  //   filterType: "number",
  //   filteredProperty: "actual_cost",
  // },
  {
    filterType: 'datepicker',
    filteredProperty: 'cost_range',
  },
];

const columnConfigs = [
  {
    id: 'project_name',
    groupId: 'gantt_id',
    css: 'project_name',
    fillspace: 2,
    header: __('group_by_table_project'),
    sort: 'string',
    template: 'groupProjectNameText',
    excelFormat: 'longText',
  },
  {
    id: 'task_name',
    css: 'task_name',
    fillspace: 3,
    header: __('group_by_table_task'),
    sort: 'string',
    template: 'textWithInfo',
    excelFormat: 'middleText',
  },
  {
    id: 'resources',
    css: 'resources',
    fillspace: 1,
    header: __('group_by_table_resources'),
    sort: 'int',
    template(obj) {
      if (obj.groupValue) {
        return '';
      }

      return assigneeTemplateHelper(obj.resource_data);
    },
    excelFormat: 'text',
  },
  {
    id: 'time_logs',
    groupId: 'time_logs',
    groupFormat: 'time',
    css: 'time align_right',
    fillspace: 1,
    header: __('group_by_table_time_logs'),
    sort: 'int',
    groupFunctor: 'sum',
    template: 'time',
    excelFormat: 'number',
  },
  {
    id: 'cost',
    css: 'cost',
    fillspace: 1,
    header: __('group_by_table_cost'),
    sort: 'cost',
    groupFunctor: 'sum_range',
    template(obj) {
      return obj.range_cost !== undefined
        ? String(estimationModule.helpers.formatWithCurrency(obj.range_cost))
        : String(estimationModule.helpers.formatWithCurrency(obj.cost));
    },
    excelFormat: 'number',
  },
  {
    id: 'actual_cost',
    css: 'cost',
    fillspace: 1,
    header: __('group_by_table_actual_cost'),
    sort: 'actual_cost',
    groupFunctor: 'sum_range',
    template(obj) {
      return obj.range_actual_cost !== undefined
      ? String(estimationModule.helpers.formatWithCurrency(obj.range_actual_cost))
      : String(estimationModule.helpers.formatWithCurrency(obj.actual_cost));
    },
    excelFormat: 'number',
  },
];

const groupByConfig = [
  {
    id: 'gantt_id',
    text: __('group_by_project'),
  },
  {
    id: 'time_logs',
    text: __('group_by_time_logs'),
  },
];

const innerSettings = {
  costWorker: null,
};

const webixUI = {
  view: 'window',
  id: 'costsListLayout',
  css: 'my-logs-layout tasks-view-list',
  borderless: true,
  // header: false,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  zIndex: 9,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state]);
  },
  body: {
    rows: [
      {
        view: 'layout',
        id: 'groupByTableContainer',
        rows: [],
      },
    ],
  },
  on: {
    onHide() {

    },
    onShow() {
    },
  },
};

const innerHandlers = {
  validateOnBeforeEditStop(obj) {
    //
  },
  onAfterLoadTable(tableData) {
    //
  },
  onAfterEditStopTable(newData, prop, valueState) {
    //
  },
  exportToExcel() {
    const data = $$('groupByTableTree').serialize(); // already filtered data

    if (!data.length) {
      webix.message({ type: 'warning', text: __('no_data_for_export_costs_list_report') });

      return;
    }

    const prepareData = function (obj) {
      if (obj.data) {
        obj.cost = String(obj.cost);
        obj.actual_cost = String(obj.actual_cost);

        _.each(obj.data, o => {
          prepareData(o);
        });
      } else {
        obj.resources = _.reduce(obj.resource_data, (str, res, i) => {
          const fullName = res.firstName || res.lastName ? ((res.firstName || '') + (res.lastName || '')) : res.name;

          return str += fullName + ((i + 1) < obj.resource_data.length ? ', ' : '');
        }, '');
        obj.cost = obj.range_cost !== undefined ? String(obj.range_cost) : String(obj.cost);
        obj.actual_cost = obj.range_actual_cost !== undefined ? String(obj.range_actual_cost) : String(obj.actual_cost);
      }
    };

    _.each(data, obj => {
      prepareData(obj);
    });

    const isTree = !!data[0].data;

    const options = {
      title: __('title_report_costs'),
      // sheetTitle: 'report_costs_list_sheet',
      isTree,
      columns: columnConfigs,
      data,
    };

    exportModule.exportToExcel(options);
    userExtAnalytics.log('report_costs_list_export_done');
  },
  onGroupByChange(value) {
    if (value) {
      const by_values = value.map(name => (name === 'gantt_id' ? 'project' : 'time'));

      userExtAnalytics.log('report_costs_list_group_by_change', {
        by: by_values,
      });
    }

    app.trigger('filter:data:costslist');
  },
  onAfterSortTable(value) {
    userExtAnalytics.log('report_costs_list_sort_by_change');
  },
  updateHeaderLayout(tableData, isSetFilter, filterRange) {
    let range = null;

    if (filterRange) {
      range = (filterRange.end ? '' : (`${__('from_date')} `))
        + moment(filterRange.start).format('D MMM, YYYY')
        + (filterRange.end ? (` - ${moment(filterRange.end).format('D MMM, YYYY')}`) : '');
    }

    globalStore.commit('headerView/setReportToolbarInfo', {
      range,
      total: null,
      total_locale: null,
      title_locale: 'reports_costs_list_title',
    });
  },
  onAfterFilterTable(isFilterApplied, filterState) {
    const table = $$('groupByTableTree');
    // const range = costsFilter.handlers.getDateRange();
    const range = filterState && filterState.cost_range ? filterState.cost_range : false;

    innerHandlers.updateHeaderLayout(false, isFilterApplied, range);

    _.each(innerSettings.allTableData, oldItem => {
      const item = table.getItem(oldItem.id);

      if (item.range_cost === undefined && item.range_actual_cost === undefined) {
        return;
      }

      item.range_cost = undefined;
      item.range_actual_cost = undefined;

      table.updateItem(oldItem.id, item);
    });

    if (!range) {
      table.callEvent('onAfterRangeCalculating');
    }

    if (innerSettings.costWorker && range) {
      innerHandlers.setTablePreload(true);
      innerHelpers.sendWorkerMessage(table.serialize(), range);
    }
  },
  onMouseMoveTable(obj, node) {
    const taskObj = $$('groupByTableTree').getItem(obj.row);

    gantt.callEvent('hideResourcesPreviewListForTask');

    if (obj.column === 'resources') {
      gantt.callEvent('showResourcesPreviewListForTask', [node, taskObj.id, taskObj.gantt_id]);
    }
  },
  setTablePreload(isLoad) {
    const table = $$('groupByTableTree');

    table[isLoad ? 'disable' : 'enable']();
    table.getNode().style.opacity = isLoad ? 0.3 : 1;
  },
};

const innerHelpers = {
  prepareTableData() {
    const resourceData = globalStore.getters['resourcesModel/getResourceByUserId'](user.id);
    const allTasks = _.reduce(globalStore.getters['tasksModel/getData'], (tasks, projectData) => {
      let filteredTasks = _.filter(projectData.tasks, t => t.parent && t.type !== gantt.config.types.project && !projectsModel.isArchived(t.gantt_id));
      const taskAccess = rights.project.hasRight(projectData.id, 'all_tasks');
      const costAccess = rights.project.hasRight(projectData.id, 'cost_field');
      const actualCostAccess = rights.project.hasRight(projectData.id, 'actual_cost_field');

      if (!(taskAccess && costAccess && actualCostAccess)) {
        filteredTasks = filteredTasks.filter(task => projectData.resourcesToTasks[task.id]?.find(o => o.resource_id === resourceData.id));
      }

      return _.concat(tasks, filteredTasks);
    }, []);
    const resultData = [];

    _.each(allTasks, taskData => {
      const projectData = projectsModel.getProjectDataById(taskData.gantt_id);
      const resources = globalStore.getters['resourcesModel/getResourcesByTaskIdAndGanttId'](taskData.gantt_id, taskData.id);
      const timeLogsData = timeTrackingModel.getLogsByParams(taskData.gantt_id, taskData.id);

      resultData.push({
        id: taskData.id,
        gantt_id: taskData.gantt_id,
        task_id: taskData.id, // do not remove
        project_name: projectData.name,
        task_name: taskData.text,
        task_data: taskData,
        info_text: taskData.note,
        resources,
        resource_id: _.map(resources, res => String(res.id)),
        resource_data: resources,
        time_logs: timeLogsData ? timeLogsData.sum : '0',
        cost: estimationModule.helpers.calculateCostChildTask(taskData) || 0,
        actual_cost: estimationModule.helpers.calculateActualCostChildTask(taskData) || 0,
        cost_range_start: taskData.start_date,
        cost_range_end: taskData.end_date,
      });
    });

    innerSettings.allTableData = resultData;

    return resultData;
  },
  getFlattenData(data) {
    const result = [];
    const getChildren = function (obj) {
      if (obj.data) {
        _.each(obj.data, o => {
          getChildren(o);
        });
      } else {
        result.push(obj);
      }
    };

    _.each(data, obj => {
      getChildren(obj);
    });

    return result;
  },
  sendWorkerMessage(tableData, range) {
    if (!range.start) return;

    const flattenData = innerHelpers.getFlattenData(tableData);
    const projectsSettings = _.map(projectsModel.serialize(), settings => ({
      ...settings,
      totalEstimate: globalStore.getters['tasksModel/getTotalEstimateDataForProject'](settings.gantt_id),
    }));

    range = {
      end: range.end ? range.end.valueOf() : range.end,
      start: range.start.valueOf(),
    };

    _.each(flattenData, obj => {
      obj.logsData = timeTrackingModel.getLogsByParams(obj.gantt_id, obj.id);
    });

    innerSettings.costWorker.postMessage({
      projectsSettings,
      customDaysData: customDayTemplatesModel.serialize(),
      tasksData: flattenData,
      resourcesData: globalStore.getters['resourcesModel/getAllResources'],
      range,
    });
  },
};

const _initEvents = function () {
  innerSettings.costWorker.onmessage = function (e) {
    const needUpdateItems = e.data;
    const table = $$('groupByTableTree');

    innerHandlers.setTablePreload(false);

    _.each(needUpdateItems, item => {
      const oldItem = table.getItem(item.id);

      oldItem.range_cost = item.cost;
      oldItem.range_actual_cost = item.actual_cost;

      table.updateItem(oldItem.id, oldItem);
    });

    table.callEvent('onAfterRangeCalculating');
  };

  innerSettings.costWorker.onerror = function (e) {
    webix.message({ type: 'error', text: __('costs_calculating_error'), expire: 10000 });
  };
};

const init = function () {
  userExtAnalytics.log('reports_costs_list_open');

  if (window.Worker && !innerSettings.costWorker) {
    let version = Date.now();

    if (GT.productVersion && GT.productVersion.buildVersion) {
      version = GT.productVersion.buildVersion;
    }

    innerSettings.costWorker = new Worker('/workers/costs_worker.js' + `?${version}`);
  }

  _initEvents();

  const tableData = innerHelpers.prepareTableData();
  const tableSettings = {
    sort: {
      as: 'string',
      dir: 'desc',
      by: 'task_name',
    },
    groupBy: ['gantt_id'],
    type: TYPE,
  }; // default settings

  $$('costsListLayout').show();

  const container = $$('groupByTableContainer');
  const childViews = container.getChildViews();
  const data = {
    tableData,
    tableSettings,
    groupBySelectData: groupByConfig,
    columnsData: _.cloneDeep(columnConfigs),
    emptyDataLocales: {
      data: 'empty_data_costs_group_by_table',
      filter: 'empty_filter_costs_group_by_table',
      prefix: 'costs',
    },
    callbacks: {
      onAfterLoad: innerHandlers.onAfterLoadTable,
      onAfterEditStop: innerHandlers.onAfterEditStopTable,
      validateOnBeforeEditStop: innerHandlers.validateOnBeforeEditStop,
      onGroupByChange: innerHandlers.onGroupByChange,
      onAfterSort: innerHandlers.onAfterSortTable,
      onAfterFilter: innerHandlers.onAfterFilterTable,
      onMouseMove: innerHandlers.onMouseMoveTable,
    },
  };

  if (childViews.length) {
    container.reconstruct();
  }

  container.addView(groupByTable.init(data));

  globalStore.commit('headerView/setGroupByData', groupByConfig);
  globalStore.commit('headerView/setReportToolbarInfo', {
    range: null,
    total: null,
    total_locale: null,
    title_locale: 'reports_costs_list_title',
  });

  app.trigger('filter:data:costslist');
};

app.on('leftSideBar:changedMode', () => {
  $$('costsListLayout') && $$('costsListLayout').resize();
});

app.on('header:export:report', type => {
  if (type === TYPE) {
    innerHandlers.exportToExcel();
  }
});

const outerObject = {
  init,
  webixUI,
};

export default outerObject;
