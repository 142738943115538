<template>
  <rightPopup
    :show="massChange && massChange.canUse && massChange.isOpen"
    :exclude-header="true"
    :exclude-footer="true"
    :width="300"
    class="bulk-changes-popup"
  >
    <template #body>
      <template v-if="massChange && massChange.canUse">
        <div
          id="massChangeHeader"
          ref="header"
        >
          <div
            :class="[$style.p_lr_wrap]"
            class="d-flex justify-space-between align-center"
          >
            <div>
              <div :class="[$style.title]">
                {{ locale.bulk_title }}
              </div>
              <div :class="[$style.subtitle]">
                {{ upperFirstLetter(locale.selected) }}: {{ massChange.countSelected || 0 }}
              </div>
            </div>

            <div
              :class="[$style.close_btn]"
              @click="onClosePopup"
            >
              <sprite
                :name="'close-1'"
                :type="'bold'"
              />
            </div>
          </div>

          <div :class="$style.devider" />
        </div>

        <stub
          v-if="massChange.showStub"
          :type="'no-fields'"
        />
        <div
          v-else
          ref="scrolled"
          :key="'masschange-' + massChange.project_id + massChange.isOpen + resetSelected"
          v-scroll.self="onScroll"
          class="gantt_scroll"
          :class="[$style.p_lr_wrap, $style.scrolled_area]"
          :style="{'flex-grow': 2}"
        >
          <vgpLabelSlot
            v-for="opt in massChange.getConfig()"
            :key="'bulk-' + opt.id"
            :label="opt.label"
          >
            <template #body>
              <div
                :class="[$style.item_p_wrap]"
              >
                <dropdownSelect
                  v-if="opt.type === 'select'"
                  :key="'mass-select' + opt.id"
                  :drop-list="opt.options"
                  :multiselect="opt.multiselect"
                  :use-search="opt.useSearch"
                  :use-select-all="opt.custom"
                  :item-template="opt.itemTemplate"
                  :track-by="'id'"
                  :track-by-text="'value'"
                  :track-by-img-url="'icon'"
                  :group-by="'group'"
                  :placeholder="locale.placeholder"
                  :disabled="isDisabledField(opt)"
                  :track-by-status-color="'color'"
                  :show-selected-first=" opt.property && !['priority', 'status'].includes(opt.property)"
                  :parent-selector-for-calc-position-drop="'.drop-select'"
                  :only-one-can-selected="opt.onlyOneCanSelected || []"
                  :drop-position="'fixed'"
                  @change="onChange(opt, $event)"
                />

                <dropdownSelectDate
                  v-if="opt.type === 'select-date'"
                  :key="'mass-select' + opt.id"
                  :placeholder="locale.placeholder"
                  :disable="isDisabledField(opt)"
                  @change="onChange(opt, $event)"
                />

                <colorPalette
                  v-if="opt.type === 'select-color'"
                  :key="'mass-select' + opt.id"
                  :colors="opt.options"
                  :select-field="'select'"
                  :select-type="'single'"
                  :placeholder="locale.placeholder"
                  :show-reset-btn="true"
                  :select-width="'100%'"
                  :select-height="'32'"
                  :disabled="isDisabledField(opt)"
                  :outline-if-has-selected="false"
                  :drop-position="'fixed'"
                  :illuminate-on-hover="true"
                  @selectColors="onChange(opt, $event)"
                />

                <bulkInput
                  v-if="opt.type === 'input'"
                  :key="'mass-select' + opt.id"
                  :item="opt"
                  :placeholder="locale.placeholder"
                  :is-disabled="isDisabledField(opt)"
                  @input="onChange(opt, $event)"
                />
              </div>
            </template>
          </vgpLabelSlot>
        </div>

        <div
          ref="footer"
          :class="[
            $style.p_b_wrap,
            $style.footer,
          ]"
        >
          <div
            v-show="!isEndScrolledContainer"
            :class="$style.haze"
          />
          <div :class="[$style.devider, $style.m_b_wrap]" />

          <vgpButton
            v-if="!massChange.showStub"
            type="primary"
            :label="locale.apply_changes_btn"
            :disabled="!massChange.hasChanges || disabledApllyButton"
            :width="254"
            :class="[$style.m_lr_wrap, $style.m_b_12]"
            @onClick="onApplyChanges"
          />

          <vgpButton
            v-if="accessToDeleteButton"
            :class="[$style.m_lr_wrap]"
            :disabled="!massChange.countSelected"
            :label="locale.delete_tasks_btn"
            type="destructive-border"
            :width="254"
            @onClick="showConfirmDelete = true"
          />
        </div>

        <confirmPopup
          :show="showConfirmDelete"
          :text="locale.confirmDelete_body"
          :title="locale.confirmDelete_title"
          :ok-button="confirm_okBtn"
          :cancel-button="confirm_cancelBtn"
          @onClickOk="onDeleteSelectedTasks"
          @onClickCancel="showConfirmDelete = false"
        />
      </template>
    </template>
  </rightPopup>
</template>

<script>
import rightPopup from '../common/VueComponents/popups/rightPopup/rightPopup.vue';
import MassChange from './massChange';
import dropdownSelect from '../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import dropdownSelectDate from '../common/VueComponents/dropdownSelect/vgpDropdownSelectDate.vue';
import vgpLabelSlot from '../common/VueComponents/label/vgpLabelSlot.vue';
import colorPalette from '../common/VueComponents/colorPalette/colorPalette.vue';
import sprite from '../common/VueComponents/VueIcon/svgSprite.vue';
import vgpButton from '../common/VueComponents/globalButton/vgpButton.vue';
import bulkInput from './bulkInput.vue';
import confirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import rights from '../../components/rights';
import stub from '../stubs/stub.vue';
import app from '../../app';

export default {
  name: 'MassChangePopup',
  components: {
    confirmPopup, rightPopup, dropdownSelectDate, dropdownSelect, vgpLabelSlot, colorPalette, sprite, vgpButton, bulkInput, stub,
  },

  data() {
    return {
      eventsIds: [],
      massChange: null,
      locale: {
        bulk_title: __('app_masschange_window_head'),
        selected: __('app_masschange_window_head_selected'),
        apply_changes_btn: __('app_masschange_window_btn_apply'),
        delete_tasks_btn: __('app_masschange_window_btn_remove'),
        placeholder: __('app_masschange_window_input_placeholder'),
        confirmDelete_title: __('confirm_deleteing_tasks_title'),
        confirmDelete_body: __('app_masschange_confirm_rm_text'),
      },
      confirm_okBtn: {
        type: 'destructive',
        title: __('common_yes'),
      },
      confirm_cancelBtn: {
        type: 'secondary',
        title: __('common_cancel'),
      },
      showConfirmDelete: false,
      resetSelected: 0,
      isEndScrolledContainer: false,
      disabledApllyButton: false,
    };
  },

  computed: {
    isOpenProjectsPopup() {
      return this.$store.getters['sidebar/isOpenProjectsPopup'];
    },
    accessToDeleteButton() {
      return rights.project.hasRight(this.massChange.project_id, 'delete_task');
    },
  },

  watch: {
    $route() {
      this.onClosePopup();
      this.massChange && this.massChange.reInit();
    },

    isOpenProjectsPopup(val) {
      val && this.$store.dispatch('headerView/closeBulkChange');
    },

    'massChange.countSelected': function (val) {
      !val && this.resetSelected++;
    },

    'massChange.isOpen': function (flag) {
      this.disabledApllyButton = false;
    },
  },

  created() {
    this.init(); 
  },

  beforeMount() {
    const component = this;
    const e_id = app.on('reloadModal:show', () => {
      component.showConfirmDelete = false;
    });

    this.eventsIds.push(e_id);
  },

  beforeDestroy() {
    this.onClosePopup();
    this.eventsIds.forEach(id => app.off(id));
  },

  methods: {
    init() {
      this.massChange = new MassChange();
    },

    isDisabledField(opt) {
      if (this.massChange.hasSelected === 'task') return false;

      if (this.massChange.hasSelected === 'project') {
        if (opt.custom) {
          return opt.inputType === 'number';
        }

        return !opt.custom && opt.property !== 'color';
      }

      return true;
    },

    onScroll(e) {
      const restDistance = e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight);

      this.isEndScrolledContainer = restDistance < 5;
    },

    onChange(opt, e) {
      this.massChange.onChange(opt, e);
    },

    onClosePopup() {
      this.showConfirmDelete = false;
      this.$store.dispatch('headerView/closeBulkChange');
    },

    async onApplyChanges() {
      this.disabledApllyButton = true;
      await this.massChange.apply();
    },

    async onDeleteSelectedTasks() {
      this.showConfirmDelete = false;
      await this.massChange.deleteTasks();
    },

    upperFirstLetter(str) {
      if (!str) return str;

      return str[0].toUpperCase() + str.slice(1);
    },

  },

};
</script>

<style scoped lang="less" module src="./mass.less">

</style>
