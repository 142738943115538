var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.config.hidden
    ? _c(
        "div",
        {
          class: [
            _vm.$style.menu_item,
            _vm.isOpenSideBar ? "" : "tooltip-gantt",
            _vm.config.css ? _vm.config.css : "",
            _vm.isActive === _vm.config.id ? _vm.$style.active : "",
            "gp_autotest_sidebar_menu_item_" + _vm.config.id,
          ],
          attrs: {
            id: "js-sidebar-menu-item-" + _vm.config.id,
            "data-position": "right",
            "data-key": _vm.config.tooltip,
            "data-x": "9",
            "data-y": "0",
          },
          on: {
            click: function ($event) {
              return _vm.clickItem(_vm.config.mainPath, _vm.config.id)
            },
          },
        },
        [
          _c("div", { class: [_vm.$style.wrapper] }, [
            _c(
              "div",
              { class: [_vm.$style.menu_icon] },
              [
                _c("svg-sprite", {
                  attrs: { name: _vm.config.icon, size: 20 },
                }),
                _vm._v(" "),
                _vm.checkErrorsInSetting(_vm.config) && !_vm.isOpenSideBar
                  ? _c(
                      "span",
                      { class: [_vm.$style.error_icon] },
                      [
                        _c("svg-sprite", {
                          attrs: {
                            name: "warning",
                            size: "16",
                            type: "color-regular",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { class: _vm.$style.menu_title }, [
              _vm._v(_vm._s(_vm.config.name)),
            ]),
          ]),
          _vm._v(" "),
          _vm.planTitle && !_vm.accessToFeature
            ? _c("div", { class: _vm.$style.plan_title }, [
                _vm._v("\n    " + _vm._s(_vm.locales(_vm.planTitle)) + "\n  "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.config.iconRight ||
          _vm.config.notificationsCount ||
          _vm.unreadCount ||
          _vm.mention
            ? _c(
                "div",
                {
                  class: [
                    _vm.$style.wrapper,
                    !_vm.isOpenSideBar ? _vm.$style.notify_wrapper : "",
                  ],
                },
                [
                  _vm.config.notificationsCount && _vm.isOpenSideBar
                    ? _c("div", { class: _vm.$style.notificationsCount }, [
                        _c(
                          "div",
                          { class: _vm.$style.notificationsCount_text },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.config.notificationsCount) +
                                "\n      "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mention &&
                  _vm.isOpenSideBar &&
                  _vm.config.id === "comments"
                    ? _c("div", { class: _vm.$style.comments }, [
                        _c(
                          "div",
                          { class: _vm.$style.comments_text },
                          [
                            _c("svg-sprite", {
                              style: { color: "#333333" },
                              attrs: {
                                name: "mentioned",
                                type: "regular",
                                size: 16,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mention &&
                  _vm.unreadCount &&
                  _vm.isOpenSideBar &&
                  _vm.config.id === "comments"
                    ? _c("div", { class: _vm.$style.comments }, [
                        _c("div", { class: _vm.$style.comments_text }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.unreadCount > 99 ? "99+" : _vm.unreadCount
                              ) +
                              "\n      "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.notificationsCount && !_vm.isOpenSideBar
                    ? _c("div", { staticClass: "notify" }, [
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              _vm.config.notificationsCount > 99
                                ? "99+"
                                : _vm.config.notificationsCount
                            ) +
                            "\n    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.mention || _vm.unreadCount) &&
                  _vm.config.id === "comments" &&
                  !_vm.isOpenSideBar
                    ? _c("div", { staticClass: "comments" }, [
                        _c("div", { class: [_vm.$style.comments_dot] }),
                        _vm._v(" "),
                        _vm.checkErrorsInSetting(_vm.config) &&
                        _vm.isOpenSideBar
                          ? _c(
                              "span",
                              { class: [_vm.$style.error_icon_open_menu] },
                              [
                                _c("svg-sprite", {
                                  attrs: {
                                    name: "warning-1",
                                    size: "20",
                                    color: "#FF9A00",
                                    type: "bold",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.iconRight
                    ? _c(
                        "div",
                        { class: [_vm.$style.menu_iconRight] },
                        [
                          _c("svg-sprite", {
                            attrs: { name: "arrow-right", size: 24 },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }