<template>
  <div
    :data-id="project.id"
    :data-gantt_id="project.gantt_id"
    :data-type="project.type"
    :data-archived="archived"
    :class="['project-item', +projectOpen === project.id ? 'active' : '', 'gp_autotest_sidebar_projects_item']"
    @click.capture="projectClick($event, project.id)"
    @dblclick.capture="projectClick($event, project.id)"
  >
    <div
      :class="['projects-popup-list-item']"
      :data-id="project.id"
      :data-gantt_id="project.gantt_id"
      :data-type="project.type"
    >
      <div
        v-if="!multiSelectMode && !editMultiviewMode"
        :class="[project.is_favorite ? 'active' : 'not_active', 'projects-popup-list-item-fav']"
        @click.prevent="favClick"
      >
        <svg-sprite
          v-if="!archived"
          :class="'project_item_icon'"
          :name="'star'"
          :type="project.is_favorite ? 'bold' : 'regular'"
          :size="20"
        />
      </div>
      <div
        v-if="multiSelectMode || editMultiviewMode"
        class="projects-popup-list-item-multiselect"
      >
        <vgp-checkbox
          :color="'#1565C0'"
          :size="16"
          :checked="multiSelectModeIds.includes(project.id)"
          @onClick="()=>checkboxHandler(project.id)"
        />
      </div>
      <div class="projects-popup-list-item-title">
        <div class="projects-popup-list-item-title-icon">
          <svg-sprite
            :name="getProjectIcon(project)"
            :size="20"
          />
        </div>
        <div class="projects-popup-list-item-title-container">
          <div :class="['projects-popup-list-item-title-name']">
            {{ project.name }}
          </div>
          <div class="projects-popup-list-item-title-last-update">
            {{ getLastUpdateDate(project) }}
          </div>
        </div>
        <status-select
          v-if="statusData"
          :class="[archived ? 'status-archived' : '', 'status-view', 'gp_autotest_sidebar_projects_item_status_select']"
          :status-data="statusData"
          :project-config="project"
          :block-select="multiSelectMode || editMultiviewMode"
          :has-right-to-change-status="hasRightToChangeStatus"
          :has-right-to-status-settings="hasRightToStatusSettings"
          :open-status="openStatus"
          @open="$emit('openStatus', $event);"
          @currentProjectForStatus="$emit('currentProjectForStatus', $event);"
          @positionSelect="$emit('positionSelect', $event);"
          @click.stop="blockStatusClick"
        />
        <div v-if="!archived">
          <div
            ref="infoPopup"
            class="projects-popup-list-item-info"
            :data-id="project.id"
            @mouseover="hoverItem(project)"
            @mouseleave="$emit('currentProjectInfo', null);"
          >
            <svg-sprite
              :name="'info'"
              :size="20"
            />
          </div>
        </div>
        <div v-else>
          <div class="projects-popup-list-item-info disabled" />
        </div>

        <div>
          <div
            v-if="itemsForContextMenu.length"
            ref="contextMenu"
            class="projects-popup-list-item-menu"
            :class="[{'disabled': multiSelectMode || editMultiviewMode}, 'gp_autotest_sidebar_projects_item_menu_button']"
            :data-id="project.id"
            @click="openMenu($event, project)"
          >
            <svg-sprite
              :name="'more-1'"
              :size="20"
            />
          </div>
          <div
            v-else
            class="projects-popup-list-item-empty-container"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import moment from '../../../libs/moment';

export default {
  name: 'ProjectItem',
  props: {
    project: { type: Object, required: true },
    archived: { type: Boolean, required: false, default: false },
    multiSelectMode: { type: Boolean, required: false, default: false },
    editMultiviewMode: { type: Boolean, required: false, default: false },
    userRights: { type: Object, required: false, default: false },
    isActiveUser: { type: Boolean, required: false, default: false },
    ownerOnProject: { type: Boolean, required: false, default: false },
    editMultiviewGanttIds: { type: Array, required: false, default: [] },
    multiSelectModeIds: { type: Array, required: false, default: [] },
    projectOpen: { type: Number, required: false, default: {} },
    projectStatus: { type: Object, required: false, default: {} },
    itemsForContextMenu: { type: Array, required: false, default: {} },
    statusData: { type: Object, required: false, default: {} },
    hasRightToChangeStatus: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasRightToStatusSettings: {
      type: Boolean,
      required: false,
      default: true,
    },
    openStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      locale: __,
      localeArray: ['no_status_locale', 'on_track_locale', 'at_risk_locale', 'off_track_locale'],
      positionInfo: null,
      positionContext: null,
      currentProjectContext: null,
      currentProjectInfo: null,
      waitFavResponse: false,
    };
  },
  computed: {

  },
  watch: {
    project(oldVal, newVal) {
      if (oldVal.is_favorite !== newVal.is_favorite) this.waitFavResponse = false;
    },
  },
  methods: {
    replaceHtmlEntites(s) {
      return s.replace(/\s+/g, ' ');
    },
    getProjectIcon(project) {
      let icon = '';
      const isMultiview = project.type && project.type === 'multiview';

      if (isMultiview || project.is_jira) {
        icon = project.is_jira ? 'jira' : 'portfolio';
      }

      if (project.is_archived) {
        icon = 'archive';
      }

      return icon;
    },
    getLastUpdateDate(project) {
      return `${__('project_last_change')}: ${moment(project.last_update).format(user.dateFormat)}`;
    },
    favClick() {
      if (this.archived || this.waitFavResponse) return false;
      this.waitFavResponse = true;
      this.$emit('favProjectClick', this.project);
    },
    projectClick(e, projectId) {
      this.$emit('projectClick', e, projectId);
    },

    hoverItem(project) {
      this.currentProjectInfo = project;

      this.$emit('currentProjectInfo', project);
      this.$emit('positionInfo', this.$refs.infoPopup.getBoundingClientRect());
      this.positionInfo = this.$refs.infoPopup.getBoundingClientRect();
    },

    toggleMultiSelectMode(gantt) {
      if (!this.isActiveUser) {
        return;
      }
      this.multiSelect = true;
      this.$emit('multiSelectMode', gantt);
    },
    openMenu(e, project) {
      if (this.multiSelectMode || this.editMultiviewMode || !this.itemsForContextMenu.length) return;
      this.currentProjectContext = project;
      this.positionContext = this.$refs.contextMenu?.getBoundingClientRect();

      this.$emit('currentProjectContext', project);
      this.$emit('positionContext', this.$refs.contextMenu?.getBoundingClientRect());
    },
    checkboxHandler(e, currentProjectContext) {
      this.$emit('checkboxHandler', e, currentProjectContext);
    },
    onSelectItemMenu(e, ganttId) {
      this.$emit('onSelectItemMenu', e, ganttId);
    },

    blockStatusClick() {
      if (this.multiSelectMode) return false;
    },

  },
};
</script>
