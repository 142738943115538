import _ from '../../../libs/lodash';

const profileSettingsMenu = [
  [{
    type: 'profile',
    icon: 'role',
    title: __('settings_profile'),
    hidden: false,
  },

  {
    type: 'notifications',
    icon: 'notifications',
    title: __('settings_notification'),
    hidden: false,
  },
  {
    type: 'billing',
    icon: 'billing',
    title: __('settings_billing'),
    hidden: false,
  },
  ],

  [
    {
      type: 'resources',
      icon: 'team',
      title: __('settings_resources'),
      hidden: false,
    },
    {
      type: 'team',
      icon: 'palette',
      title: __('settings_team'),
      hidden: false,
    },
    {
      type: 'statuses',
      icon: 'status-1',
      title: __('settings_statuses'),
      hidden: false,
    },
    {
      type: 'account_rights',
      icon: 'assigned',
      title: __('settings_account_right'),
      hidden: false,
    },
    {
      type: 'project_rights',
      icon: 'rights',
      title: __('settings_project_right'),
      hidden: false,
    },
    {
      type: 'columns',
      icon: 'custom-field',
      title: __('settings_columns'),
      hidden: false,
    },

  ],

  [
    {
      type: 'security',
      icon: 'security',
      title: __('settings_security'),
      hidden: false,
    },
    {
      type: 'integrations',
      icon: 'integration-1',
      title: __('settings_integration'),
      hidden: false,
    }],

  [
    {
      type: 'admin',
      icon: 'security16',
      title: __('settings_admin'),
      hidden: false,
    }],

  [{
    type: 'logout',
    icon: 'logout',
    title: __('settings_logout'),
    hidden: false,
  }],
];

export default {
  getMenuItems() {
    return _.cloneDeep(profileSettingsMenu);
  },
};
