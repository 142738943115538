<template>
  <div :class="$style.zoom_slider">
    <div :class="$style.wrap">
      <label :class="$style.label">
        <div :class="$style.dots">
          <div
            v-for="dot in dots"
            :key="dot"
            :class="$style.dot"
          ></div>
        </div>
        <input
          :class="$style.slider"
          type="range"
          step="1"
          :min="min"
          max="5"
          :value="value"
          @change="onChange"
        />
      </label>
    </div>
    <div :class="$style.title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      zoomTitles: [
        __('zoom_scale_hours'),
        __('zoom_scale_days'),
        __('zoom_scale_weeks'),
        __('zoom_scale_months'),
        __('zoom_scale_quarters'),
        __('zoom_scale_years'),
      ],
    };
  },
  computed: {
    isWorkload() {
      return this.$store.getters['headerView/currentRoute'].path.includes('workload');
    },
    min() {
      return this.isWorkload ? 1 : 0;
    },
    dots() {
      return this.zoomTitles.slice(this.min);
    },
    title() {
      return this.zoomTitles[this.value];
    },
  },
  methods: {
    onChange(e) {
      const value = +e.target.value;

      this.$emit('change', value);

      function defineTimeUnit(val) {
        switch (val) {
        case 0: return 'hours';
        case 1: return 'days';
        case 2: return 'weeks';
        case 3: return 'months';
        case 4: return 'quarters';
        case 5: return 'years';
        default: return val;
        }
      }

      userExtAnalytics.log('gantt_zoom_changed', {
        'new value': defineTimeUnit(value),
      });
    },
  },
};
</script>

<style module lang="less" src="../less/zoomSlider.less"></style>
