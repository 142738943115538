<template>
  <div :class="$.colors_list">
    <div
      v-for="(color, index) in colors"
      :key="index"
      :class="$.colors_list_item"
      :style="{'background-color': hoverColor === color.id ? color.hex2 : color.hex}"
      @mouseover="hoverColor = color.id"
      @mouseleave="hoverColor = null"
      @click="selectColor(color)"
    >
      <sprite
        v-if="isSelectedColor(color)"
        name="check"
      />
    </div>
  </div>
</template>

<script>
import sprite from '../../VueIcon/svgSprite.vue';

export default {
  components: {
    sprite,
  },
  props: {
    colors: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: [],
      hoverColor: null,
    };
  },
  watch: {
    selected() {
      this.setSelectedValues();
    },
  },
  mounted() {
    this.setSelectedValues();
  },
  methods: {
    setSelectedValues() {
      const checked = [];

      this.selected.forEach(item => {
        if (typeof item === 'number' || typeof item === 'string') {
          const color = this.colors.find(c => c.id === +item);

          color && checked.push(color);
        } else {
          checked.push(item);
        }
      });

      this.checked = this.multiselect || !checked.length ? checked : [checked[0]];
    },
    isSelectedColor(color) {
      return this.checked.some(c => c.id === color.id);
    },
    selectColor(color) {
      if (this.multiselect) {
        if (this.isSelectedColor(color)) {
          this.checked = this.checked.filter(c => c.id !== color.id);
        } else {
          this.checked.push(color);
        }
      } else {
        this.checked = [color];
      }

      this.$emit('selectColors', this.checked);
    },
  },
};
</script>

<style module="$" src="./colorsList.less"></style>
