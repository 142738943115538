<template>
  <div :class="'vgp_date_picker'">
    <vc-date-picker
      ref="calendar"
      v-model="calendarValue"
      mode="date"
      :is-required="true"
      :attributes="calendarAttributes"
      :locale="calendarLocale"
      :popover="{
        placement: 'top-end',
      }"
      @input="onInput"
      @popoverDidShow="onShow"
      @popoverDidHide="onHide"
    >
      <template #default="{ inputValue, togglePopover }">
        <slot
          v-if="$scopedSlots.default"
          :inputValue="inputValue"
          :togglePopover="togglePopover"
        />
        <div
          v-else
          :class="'vgp_date_picker_input'"
        >
          <svg-sprite
            :class="'vgp_date_picker_icon'"
            :name="'calendar'"
            :type="'regular'"
            :size="24"
            @click="() => togglePopover()"
          />
        </div>
      </template>

      <template #footer>
        <div :class="'vgp_date_picker_footer'">
          <slot
            v-if="$scopedSlots.footer"
            name="footer"
            :setValue="setValue"
          />
          <button
            v-else
            :class="'vgp_date_picker_footer_button'"
            @click.stop="() => setValue(new Date())"
          >
            {{ locales('datepiker_today') }}
          </button>
        </div>
      </template>
    </vc-date-picker>
  </div>
</template>

<script>
export default {
  name: 'VgpDatePicker',
  props: {
    value: {
      type: Date,
      default: new Date(),
    },
    workDays: {
      type: Array,
      required: false,
      default: () => [1, 2, 3, 4, 5],
    },
  },
  data() {
    return {
      locales: __,
      calendarLocale: {
        id: user.locale,
        firstDayOfWeek: +user.settings.start_monday ? 2 : 1,
        masks: {
          input: user.dateFormat ? user.dateFormat : 'YYYY-MM-DD',
        }
      },
    };
  },
  computed: {
    calendarValue() {
      return this.getClosestFutureWorkDay(this.value);
    },

    calendarWorkDays() {
      // Day of week begins on Sunday in v-calendar
      return this.workDays.map(day => day === 7 ? 1 : ++day);
    },

    calendarAttributes() {
      return [
        {
          highlight: {
            contentStyle: { color: '#B2B2B2', borderRadius: '4px' },
          },
          dates: {
            weekdays: [1, 2, 3, 4, 5, 6, 7].filter(day => !this.calendarWorkDays.find(workDay => workDay === day)),
          },
        },
      ];
    },
  },
  methods: {
    setValue(value) {
      const closestWorkDay = this.getClosestFutureWorkDay(value);

      this.$refs.calendar.updateValue(closestWorkDay);
      this.$refs.calendar.hidePopover();
    },

    onInput(value) {
      this.$emit('onChange', value);
    },

    onShow() {
      this.$emit('onShow');
    },

    onHide() {
      this.$emit('onClose');
    },

    getClosestFutureWorkDay(date) {
      if (!this.calendarWorkDays.length) {
        return date;
      }

      const closestDay = new Date(date);

      let i = 0;
      while (!this.calendarWorkDays.includes(closestDay.getDay() + 1) && i < 7) {
        closestDay.setDate(closestDay.getDate() + 1);
        i++;
      }

      return closestDay;
    },
  },
};
</script>

<style src="./datePicker.less" lang="less"></style>
