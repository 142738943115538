var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["learning-center-videos-tab-wrapper"] },
    [
      _c("div", { class: _vm.$style["video-container"] }, [
        _c("div", { attrs: { id: "video-player" } }),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style["timeline-list"] }, [
        _c(
          "ul",
          {
            staticClass: "gantt_scroll",
            class: _vm.$style["list-items"],
            on: { scroll: _vm.handleListScroll },
          },
          _vm._l(_vm.timeCodes, function (timeCode) {
            var _obj, _obj$1, _obj$2
            return _c(
              "li",
              {
                key: timeCode.id,
                ref: "timeCodes",
                refInFor: true,
                class: [
                  _vm.$style["list-item"],
                  ((_obj = {}),
                  (_obj[_vm.$style["list-item--selected"]] =
                    _vm.isTimeCodeActive(timeCode.id)),
                  _obj),
                ],
                on: {
                  click: function ($event) {
                    return _vm.handleTimeCodeClick(timeCode)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style["title-container"],
                      ((_obj$1 = {}),
                      (_obj$1[_vm.$style["selected"]] = _vm.isTimeCodeActive(
                        timeCode.id
                      )),
                      _obj$1),
                    ],
                  },
                  [
                    _vm.isTimeCodeActive(timeCode.id)
                      ? _c("svg-sprite", {
                          class: _vm.$style["play-icon"],
                          attrs: { name: "play", type: "bold", size: 20 },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style["title-step"] }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.locales("lc_video_step") + " " + timeCode.id
                          ) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style["item-data"],
                      ((_obj$2 = {}),
                      (_obj$2[_vm.$style["selected"]] = _vm.isTimeCodeActive(
                        timeCode.id
                      )),
                      _obj$2),
                    ],
                  },
                  [
                    _c("div", { class: _vm.$style.title }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(timeCode.title) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.time }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.getTimeStringFromSeconds(timeCode.second)
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", {
          class: [
            _vm.$style.blur,
            ((_obj = {}),
            (_obj[_vm.$style["blur--active"]] = _vm.needToBlurListBottom),
            _obj),
          ],
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }