export default {
  getActivityStatus: (state, getters) => activityName => {
    const activity = getters.getActivity(activityName);

    return activity?.activity_status;
  },

  getActivity: state => activityName => state.activities.find(entry => entry.name === activityName),

  getActivitySettings: (state, getters) => activityName => {
    const activity = getters.getActivity(activityName);

    return activity?.settings ? JSON.parse(activity?.settings) : null;
  },
};
