<template>
  <div v-if="!loading" class="size-value">
    {{ usedSpace }} / {{ spaceLimit }}
  </div>
  <div v-else class="size-value">
  </div>
</template>

<script>
import { api } from '$$store/api';
import humanFileSize from '../../../../helpers/fileSize';

export default {
  data: function() {
    return {
      loading: true,
      usedSpace: 0,
      spaceLimit: 0,
    };
  },

  beforeCreate() {
    this.loading = true;
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      let teamSpace = await api.get('/team/teamCloudSpace')
      if (teamSpace?.data) {
        this.usedSpace = humanFileSize( teamSpace?.data?.usedSpace);
        this.spaceLimit = humanFileSize(teamSpace?.data?.spaceLimit);
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped src="../../less/profileSettings.less"></style>