var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.project_page },
    [
      _c("stub"),
      _vm._v(" "),
      _vm.mode === "board" ? _c("kanban") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "list" ? _c("list-view") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "workload" ? _c("workload") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "resources" ? _c("resources") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "overview" ? _c("overview") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "calendar" ? _c("calendar") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "export" ? _c("export") : _vm._e(),
      _vm._v(" "),
      _c("vgp-core-popup", {
        attrs: {
          show: _vm.showBudgetModeConfirm,
          title: _vm.autoBudgetNewValue
            ? _vm.locale("budget_auto_mode_change_title")
            : _vm.locale("budget_manual_mode_change_title"),
          closeIcon: false,
          buttonsSwitch: true,
          okButtonTitle: _vm.locale("common_confirm"),
          cancelButtonTitle: _vm.locale("common_cancel"),
          footerStyle: "flex-end",
        },
        on: {
          onClickCancel: _vm.toggleAutoBudgetPopup,
          onClickOk: _vm.applyAutoBudget,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { class: _vm.$.budget_mode_confirm_wrapper }, [
                  _c("div", { class: _vm.$.confirm_message }, [
                    _vm._v(
                      _vm._s(
                        _vm.autoBudgetNewValue
                          ? _vm.locale("budget_auto_mode_confirm_message")
                          : _vm.locale("budget_manual_mode_confirm_message")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.autoBudgetNewValue
                    ? _c(
                        "div",
                        { class: _vm.$.clear_costs_switcher },
                        [
                          _c("vgp-checkbox", {
                            class: _vm.$.checkbox,
                            attrs: { size: 16, checked: _vm.clearCosts },
                            on: {
                              onClick: function ($event) {
                                _vm.clearCosts = !_vm.clearCosts
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: _vm.$.checkbox_label,
                              on: {
                                click: function ($event) {
                                  _vm.clearCosts = !_vm.clearCosts
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.locale("budget_delete_costs_values")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }