import cookie from '../helpers/cookie';

const __ = window.__;

const profile = {};

profile.saveSettings = data => {
  user.settings = Object.assign(user.settings, data);
  // update active global user settings

  webix.ajax()
    .post('/api/userSettings', data)
    .then(() => {});

  if (typeof data.password !== 'undefined') {
    delete data.password;
  } else if (typeof data.password === 'string') {
    data.password = data.password.trim();
  }

  if (typeof data.welcome_show !== 'undefined') delete data.welcome_show;
};

profile.updateProfile = async data => {
  const userId = data.userId ? data.userId : null;

  if (data.firstName) {
    // update active global user firstName
    user.firstName = data.firstName;
  }

  if (data.lastName) {
    // update active global user lastName
    user.lastName = data.lastName;
  }

  const response = await webix.ajax().post('/api/profile/', data).catch(error => {
    try {
      return Promise.reject(JSON.parse(error.response));
    } catch (e) {
      return Promise.reject(error.response);
    }
  });
  const updatedUser = await response.json();

  if (!userId || userId === user.id) {
    user.photo = updatedUser.photo;
  }

  return updatedUser;
};

profile.deleteProfile = function () {
  return webix.ajax().del('/api/profile/');
};

profile.getDefaultGanttTemplate = templates => {
  const gp_landing = decodeURIComponent(cookie.get('gp_landing'));
  const gp_referrer = decodeURIComponent(cookie.get('gp_referrer'));
  const gp_regsource = decodeURIComponent(cookie.get('gp_regsource'));
  let resultTemplate = null;

  _.each(templates, template => {
    const str = template.nameKey;

    if (str && (gp_regsource.indexOf(str) != -1 || gp_landing.indexOf(str) != -1 || gp_referrer.indexOf(str) != -1)) {
      resultTemplate = template;
    }
  });

  return resultTemplate;
};

profile.updateActivitySettings = data => webix.ajax().post('/api/userSettings/activity', data).then(res => {
  let userActivity;

  try {
    userActivity = res.json();
  } catch (err) {
    console.error('Error json parsing');
  }

  if (userActivity) {
    user.activity = userActivity;
  }
});

export default profile;
