var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("core-popup", {
    attrs: {
      show: _vm.isShow,
      title: _vm.locales("add_to_projects_popup_title"),
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.add_to_projects_popup }, [
              _c("div", { class: _vm.$.content }, [
                _c("div", { class: _vm.$.items_with_checkbox }, [
                  _c(
                    "div",
                    { class: _vm.$.items_with_checkbox__search },
                    [
                      _c("search", {
                        attrs: {
                          placeholder: _vm.locales("search_by_name_only"),
                        },
                        on: { onInput: _vm.onSearch },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.preparedProjects.length
                    ? _c("div", { class: _vm.$.items_with_checkbox__table }, [
                        _c(
                          "div",
                          { class: _vm.$.select_btn },
                          [
                            _c("vgp-button", {
                              attrs: {
                                label:
                                  _vm.visibleSelectedLength ===
                                  _vm.preparedProjects.length
                                    ? _vm.locales(
                                        "multicombo_unselect_all_items"
                                      )
                                    : _vm.locales(
                                        "multicombo_select_all_items"
                                      ),
                                type: "text",
                                small: true,
                              },
                              on: { onClick: _vm.onToggleSelectAll },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { class: _vm.$.labels }, [
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.locales("attachments_projects_title")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.resourceData.userId
                            ? _c("div", { class: _vm.$.labels__roles }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.locales("people_popup_roles_label")
                                    ) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: [_vm.$.list, "gantt_scroll"] },
                          _vm._l(_vm.preparedProjects, function (item, index) {
                            return _c(
                              "div",
                              { key: index, class: _vm.$.list__row },
                              [
                                _c(
                                  "div",
                                  {
                                    class: _vm.$.info,
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClickRow(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("vgp-checkbox", {
                                      class: _vm.$.checkbox,
                                      style: { "pointer-events": "none" },
                                      attrs: {
                                        checked: item.isChecked,
                                        size: 16,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { class: _vm.$.name },
                                      [
                                        _c("tooltip", {
                                          class: _vm.$.tooltip_wrapper,
                                          attrs: { content: item.name },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "body",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.name) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.resourceData.userId
                                  ? _c(
                                      "div",
                                      {
                                        class: [
                                          _vm.$.dropdown,
                                          !item.isAccessToChangeRole ||
                                          _vm.resourceData.isOwner ||
                                          _vm.resourceData.isKing
                                            ? _vm.$.disabled
                                            : "",
                                        ],
                                      },
                                      [
                                        _c("dropdown-select", {
                                          key: item.key,
                                          attrs: {
                                            "drop-list": _vm.dropdownRoles,
                                            multiselect: false,
                                            "show-selected-first": false,
                                            "show-reset-btn": false,
                                            "highlight-active-state": false,
                                            "item-template": "text",
                                            "track-by-text": "title",
                                            outline: !item.isAccessToChangeRole
                                              ? "none"
                                              : "hover",
                                            disabled:
                                              !item.isAccessToChangeRole ||
                                              _vm.resourceData.isOwner ||
                                              _vm.resourceData.isKing,
                                            "drop-position": "fixed",
                                            "parent-selector-for-calc-position-drop":
                                              ".core_popup_block",
                                            "track-by-item-tooltip": "tooltip",
                                            "fixed-width": 320,
                                            size: "autoWidth",
                                            "need-description": true,
                                            selected: _vm.dropdownRoles.find(
                                              function (role) {
                                                return role.id === item.roleId
                                              }
                                            ),
                                          },
                                          on: {
                                            selected: function ($event) {
                                              return _vm.onChangeRole(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _c("div", { class: _vm.$.items_with_checkbox__stub }, [
                        _c("div", { class: _vm.$.wrap }, [
                          _c("div", {
                            class: _vm.$.img,
                            domProps: { innerHTML: _vm._s(_vm.stub) },
                          }),
                          _vm._v(" "),
                          _c("div", { class: _vm.$.desc }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.searchValue !== ""
                                    ? _vm.locales(
                                        "add_projects_popup_no_search_result"
                                      )
                                    : _vm.locales(
                                        "add_projects_popup_no_projects"
                                      )
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: [_vm.$.footer, _vm.$.with_shadow] },
                [
                  _c("vgp-button", {
                    style: { marginRight: "16px" },
                    attrs: {
                      label: _vm.locales("common_cancel"),
                      type: "secondary",
                      small: true,
                    },
                    on: { onClick: _vm.onClose },
                  }),
                  _vm._v(" "),
                  _c("vgp-button", {
                    attrs: {
                      label: _vm.locales("common_add"),
                      type: "primary",
                      small: true,
                      disabled: !_vm.selected.length,
                    },
                    on: { onClick: _vm.onApply },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }