/* eslint-disable no-undef, no-param-reassign, no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
import app from '../../app';
import ganttViewModel from '../../models/ganttViewModel';
import projectsModel from '../../models/projects';
import massChangeModule from '../gantt/modules/massChange';
import projectComponent from '../../components/Project';
import UIConfigsByRoute from './include/UIConfigsByRoute';
import multiViewsProjects from '../../models/multiViewsProjects';
import userTemplateObject from '../settings/include/settingsUserTemplate';
import routerHelper from '../../helpers/router';
import temporaryHelper from '../../helpers/temporaryHelper';
import settingsObject from '../settings/main';
import historyModule from '../history/main';
import { integrationSettingsObjectSlack, integrationSettingsObjectMsteams } from '../integration/integrationHeaderPopup';
import statusHelper from '../../helpers/status';

Vue.use(Vuex);
const _helpers = {
  async sendDataToMassChange(data, ganttId, noHistoryFlag) {
    statusHelper.handlers.showMainSpinner();
    const queryResult = await massChangeModule._api.execMassChange(data, ganttId, noHistoryFlag);

    const { resources, actions: { succeeded } } = queryResult;

    await massChangeModule._processQueryActionsRes(queryResult.ganttId, succeeded, resources);
    gantt.callEvent('ganttClearAndParse', []);
    app.trigger('filter:set');
    statusHelper.handlers.hideMainSpinner();

    return Promise.resolve();
  },

  setGanttViewSettings(data, viewSettings) {
    switch (data.name) {
    case 'skins':
      webix.callEvent('onReconstruct', []);
      app.trigger('settings:skin:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'skin', value: data.value });
      break;
    case 'viewMode':
      app.trigger('settings:viewMode:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'view_mode', value: data.value });
      break;
    case 'taskName':
      app.trigger('settings:rightSideText:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'right_side_text', value: data.value });
      break;
    case 'completedTask':
      const lastState = viewSettings.completedTask;

      if (!data.value.includes('crossOutTasks') && lastState.includes('crossOutTasks')) {
        app.trigger('settings:crossOutTasks:change', data.value.includes('crossOutTasks'));
        ganttViewModel.addParamsToUpdate({ key: 'cross_out_tasks', value: data.value.includes('crossOutTasks') });
      } else if (data.value.includes('crossOutTasks') && !lastState.includes('crossOutTasks')) {
        app.trigger('settings:crossOutTasks:change', data.value.includes('crossOutTasks'));
        ganttViewModel.addParamsToUpdate({ key: 'cross_out_tasks', value: data.value.includes('crossOutTasks') });
      }

      if (!data.value.includes('discolorationTasks') && lastState.includes('discolorationTasks')) {
        app.trigger('settings:discolorationTasks:change', data.value.includes('discolorationTasks'));
        ganttViewModel.addParamsToUpdate({ key: 'discoloration_tasks', value: data.value.includes('discolorationTasks') });
      } else if (data.value.includes('discolorationTasks') && !lastState.includes('discolorationTasks')) {
        app.trigger('settings:discolorationTasks:change', data.value.includes('discolorationTasks'));
        ganttViewModel.addParamsToUpdate({ key: 'discoloration_tasks', value: data.value.includes('discolorationTasks') });
      }
      break;
    case 'avatar':
      app.trigger('settings:showResourceAvatar:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'show_resource_avatar', value: data.value });
      break;
    case 'skipOffTime':
      app.trigger('settings:skipOffTime:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'skip_off_time', value: !data.value });
      break;
    case 'todayMarker':
      app.trigger('settings:currentDayMarker:change', data.value);
      ganttViewModel.addParamsToUpdate({ key: 'current_day_marker', value: data.value });
      break;
    case 'buttons':
      app.trigger('settings:advancedButtons:change', data.value);
      app.trigger('filter:set');
      ganttViewModel.addParamsToUpdate({ key: 'show_advanced_buttons', value: data.value });
      break;
    }
  },

};

const headerStore = {
  namespaced: true,
  state: {
    height: 0,
    headTitle: '',
    isEditingTitle: false,
    isFavorite: false,
    viewSettings: {},
    activeSkin: 1,
    activeViewMode: false,
    activeTab: {},
    isActiveFilter: false,
    isBulkChange: false,
    isOverdueTasks: false,
    isCriticalPath: false,
    zoom: 1,
    currentRoute: {
      name: 'app',
      path: '/',
      params: {},
    },
    UIConfig: {},
    boardTypes: [],
    activeBoardType: '',
    workloadTypes: ['hours', 'tasks', 'percents'],
    activeWorkloadType: '',
    listTasksCount: 0,
    isMyTasksFilter: true,
    isShowSlack: false,
    isShowSlackPopup: false,
    isShowMSTeams: false,
    isShowMSTeamsPopup: false,
    isShowSettingsPopup: false,
    isShowMorePopup: false,
    isShowBaselinePopup: false,
    isShowFilter: false,
    groupByActiveData: [],
    groupByData: [],
    reportToolbarInfo: {},
    publicUrlInfo: {},
    calendarViewDate: null,
    calendarStep: 0,
    calendarTodayDate: null,
  },
  getters: {
    height: state => state.height,
    viewSettings: state => state.viewSettings,
    activeSkin: state => state.activeSkin,
    currentRoute: state => state.currentRoute,
    UIConfig: state => state.UIConfig,
    activeViewMode: state => state.activeViewMode,
    activeTab: state => state.activeTab,
    headTitle: state => state.headTitle,
    isEditingTitle: state => state.isEditingTitle,
    zoom: state => state.zoom,
    boardTypes: state => state.boardTypes,
    activeBoardType: state => state.activeBoardType,
    workloadTypes: state => state.workloadTypes,
    activeWorkloadType: state => state.activeWorkloadType,
    listTasksCount: state => state.listTasksCount,
    isActiveFilter: state => state.isActiveFilter,
    isBulkChange: state => state.isBulkChange,
    isFavorite: state => state.isFavorite,
    isOverdueTasks: state => state.isOverdueTasks,
    isCriticalPath: state => state.isCriticalPath,
    isMyTasksFilter: state => state.isMyTasksFilter,
    isShowSlack: state => state.isShowSlack,
    isShowSlackPopup: state => state.isShowSlackPopup,
    isShowMSTeams: state => state.isShowMSTeams,
    isShowMSTeamsPopup: state => state.isShowMSTeamsPopup,
    isShowSettingsPopup: state => state.isShowSettingsPopup,
    isShowMorePopup: state => state.isShowMorePopup,
    isShowBaselinePopup: state => state.isShowBaselinePopup,
    isShowFilter: state => state.isShowFilter,
    groupByActiveData: state => state.groupByActiveData,
    groupByData: state => state.groupByData,
    reportToolbarInfo: state => state.reportToolbarInfo,
    publicUrlInfo: state => state.publicUrlInfo,
    calendarViewDate: state => state.calendarViewDate,
    calendarStep: state => state.calendarStep,
  },
  mutations: {
    setHeight(state, height) {
      state.height = height;
    },
    setActiveSkin(state, skin) {
      state.activeSkin = skin;
    },
    setViewSettings(state, settings) {
      state.viewSettings = settings;
    },
    assignViewSettings(state, settings) {
      Object.assign(state.viewSettings, settings);
    },
    setHeadTitle(state, title) {
      state.headTitle = title;
    },
    setEditingTitle(state, flag) {
      state.isEditingTitle = flag;
    },
    setCurrentRoute(state, route) {
      state.currentRoute = route;
    },

    removeToolbar(state) {
      if (!state.UIConfig) return;

      state.UIConfig.toolbar = null;
      // state.UIConfig.sumHeight -= state.UIConfig.toolbarHeight;
    },

    setUIConfig(state) {
      state.UIConfig = UIConfigsByRoute.getUI(state.currentRoute);
      state.isEditingTitle = false;
    },
    setUIConfigToolbarDisable(state, flag) {
      if (state.UIConfig.toolbar) {
        state.UIConfig.toolbar.disable = flag;
      }
    },
    setActiveTab(state, tab) {
      state.activeTab = tab;
    },
    setActiveFilter(state, flag) {
      state.isActiveFilter = flag;
    },
    setActiveViewMode(state, mode) {
      state.activeViewMode = mode;
    },
    setFavorite(state, isFavorite) {
      state.isFavorite = isFavorite;
    },
    toggleSlackPopup(state, flag) {
      state.isShowSlackPopup = flag;
      if (flag) userExtAnalytics.log('intergraration_slack_popup_opened');
    },
    toggleMSTeamsPopup(state, flag) {
      state.isShowMSTeamsPopup = flag;
      if (flag) userExtAnalytics.log('intergraration_MSTeams_popup_opened');
    },
    toggleSettingsPopup(state, flag) {
      state.isShowSettingsPopup = flag;
    },
    toggleMorePopup(state, flag) {
      state.isShowMorePopup = flag;
    },
    toggleBaselinePopup(state, flag) {
      state.isShowBaselinePopup = gantt.config.baseline || flag;
    },
    toggleFilterPopup(state, flag) {
      state.isShowFilter = flag;
    },
    toggleBulkChange(state) {
      state.isBulkChange = !state.isBulkChange;
    },
    setOverdueTasks(state, flag) {
      state.isOverdueTasks = flag;
    },
    setCriticalPath(state, flag) {
      state.isCriticalPath = flag;
    },
    toggleCriticalPath(state) {
      state.isCriticalPath = !state.isCriticalPath;
    },
    setZoom(state, zoom) {
      state.zoom = zoom;
    },
    setBoardTypes(state, types) {
      state.boardTypes = types;
    },
    setActiveBoardType(state, type) {
      state.activeBoardType = type;
    },
    setActiveWorkloadType(state, type) {
      state.activeWorkloadType = type;
    },
    setListTasksCount(state, count) {
      state.listTasksCount = count;
    },
    setMyTasksFilter(state, flag) {
      state.isMyTasksFilter = flag;
    },
    setShowSlack(state, flag) {
      state.isShowSlack = flag;
    },
    setShowMSTeams(state, flag) {
      state.isShowMSTeams = flag;
    },
    setPublicUrlInfo(state, data) {
      state.publicUrlInfo = data;
    },
    setGroupByActiveData(state, data) {
      state.groupByActiveData = [...data];
    },
    setGroupByData(state, data) {
      state.groupByData = [...data];
    },
    setReportToolbarInfo(state, data) {
      state.reportToolbarInfo = { ...state.reportToolbarInfo, ...data };
    },
    setCalendarViewDate(state, payload) {
      state.calendarViewDate = payload.date;
    },
    setCalendarStep(state, step) {
      state.calendarStep = step;
    },
    setCalendarTodayDate(state, today) {
      state.calendarTodayDate = today;
    },
  },
  actions: {
    setUIConfig({ commit, state }) {
      commit('setUIConfig');

      if (
        state.UIConfig.tabs
        && !state.UIConfig.tabs.includes(state.currentRoute.params.mode)
        && !['gantt', 'export'].includes(state.currentRoute.params.mode)
      ) {
        routerHelper.pushByNameAndParams(state.currentRoute.params.parent || state.currentRoute.name,
          { mode: 'gantt', taskId: null });
      }
    },
    changeRoute({ commit, state }, data) {
      routerHelper.pushByNameAndParams(data.name, data.params);
    },
    openTemplatePopup({ commit }) {
      userTemplateObject.handlers.show();
    },
    toggleSettingsPopup({ commit }) {
      settingsObject.handlers.togglePopup();
    },
    setIsShowSettingsPopup({ commit }, flag) {
      settingsObject.handlers.setIsShowPopup(flag);
    },
    openHistoryWindow({ commit }) {
      historyModule.handlers.showPopup();
    },
    toggleSlackPopup({ commit }) {
      integrationSettingsObjectSlack.handlers.togglePopup();
    },
    toggleMSTeamsPopup({ commit }) {
      integrationSettingsObjectMsteams.handlers.togglePopup();
    },
    deleteProject({ commit, state }, { projectId }) {
      let id = projectId;

      app.trigger('gantt:keyboard:disable');

      if (!projectId) {
        id = state.currentRoute.params.projectId;
      }

      userExtAnalytics.log('project_delete', { from: id ? 'all projects' : 'project' });

      return projectComponent.deleteProjectByGanttId(id)
        .then(() => {
          app.trigger('sideBar:update:favorite');

          if (projectId === gantt.config.gantt_id) projectsModel.openProjectAfterDelete();
        })
        .finally(() => {
          app.trigger('gantt:keyboard:enable');
        });
    },
    editMultiview({ commit }) {
      const viewData = ganttViewModel.getActiveViewData();
      const isMultiview = ganttViewModel.isMultiview();

      if (isMultiview) {
        app.trigger('editMultiview', viewData);
      }
    },
    // openPublicUrlPopup({ commit }) {
    //   // this hack was made specially for team 484817, if you see this and don't sure if you can remove this, please check if team 484817 is still paying ;)
    //   // if team is not paying anymore -> please remove this
    //   if (user.team && user.team.id === 484817) {
    //     webix.message({ type: 'error', text: 'This feature is disabled for your account.', expire: 10000 });
    //     return false;
    //   }
    //   $$('publicLinkWindow').show();
    // },
    toggleFavorite({ commit }, props) {
      const id = (props && props.id) || ganttViewModel.getActiveViewData().id;
      let isMultiview = props && props.isMultiview;
      let view;

      if (isMultiview === undefined) {
        isMultiview = ganttViewModel.isMultiview();
      }

      if (isMultiview) {
        view = multiViewsProjects.getProjectDataById(id);
      } else {
        view = projectsModel.getProjectDataById(id);
      }

      const newState = !view.is_favorite;

      let promise;

      if (isMultiview) {
        promise = multiViewsProjects.manualUpdateFavorite(id, newState);
      } else {
        promise = projectsModel.manualUpdateFavorite(id, newState);
      }

      promise.then(() => {
        app.trigger('sideBar:update:favorite');
        app.trigger('sideBar:toggleFavorite', { id, newState });

        if (id === ganttViewModel.getActiveViewData().id) {
          commit('setFavorite', newState);
        }
      });
    },
    saveHeadTitle({ commit, state }) {
      if (window.user.isTemplateEditor) {
        temporaryHelper(state.headTitle, state.currentRoute.params.projectId);
      }
      commit('setHeadTitle', state.headTitle);
      commit('setEditingTitle', false);
      ganttViewModel.addParamsToUpdate({ key: 'name', value: state.headTitle });
      ganttViewModel.updateGanttSettings();
      app.trigger('sideBar:update:favorite');
      userExtAnalytics.log('header_change_project_name', { route: state.currentRoute.path.replace(/[0-9]/g, '') });
    },
    archiveProject({ commit, state }, ganttId) {
      return projectComponent.archiveProjectByGanttId(ganttId || state.currentRoute.params.projectId)
        .then(() => {
          app.trigger('sideBar:update:favorite');
          commit('setFavorite', !state.isFavorite);
          this.commit('comments/deleteDraft', {
            projectId: ganttId || state.currentRoute.params.projectId,
          });
        });
    },
    unArchiveProject({ commit, state }, ganttId) {
      projectComponent.unArchiveProjectByGanttId(ganttId);
      userExtAnalytics.log('project_unarchive', { from: 'project' });

      if (ganttViewModel.getActiveViewData().id === +ganttId) {
        commit('setFavorite', false);
        this.commit('comments/deleteDraft', {
          projectId: ganttId || state.currentRoute.params.projectId,
        });
      }
    },
    expandCollapse({ commit }, type) {
      const allTasks = gantt.serialize().data;
      const open = type === 'expand' ? 1 : 0; // expand = true
      const user_id = user.id;
      const gantt_tasks_id = [];
      const { multiview_id } = gantt.config;

      allTasks.forEach(task => {
        if (task.type === 'project' && gantt.getTask(task.id).parent) {
          gantt_tasks_id.push(task.id);
        }
      });

      const activeGanttId = projectsModel.getActiveGanttId();

      const actions = [{
        actionType: gantt.config.multiview ? 7 : 6,
        actionPayload: gantt.config.multiview ? {
          tasks: gantt_tasks_id.map(task_id => ({
            multiview_id, task_id, user_id, open,
          })),
        }
          : { tasks: gantt_tasks_id.map(gantt_tasks_id => ({ gantt_tasks_id, user_id, open })) },
      }];

      _helpers.sendDataToMassChange(actions, activeGanttId || multiview_id, true)
        .then(() => {
          gantt.render();// for update scrollbar
        });
    },
    toggleBulkChange({ commit, state }) {
      if (massChangeModule.isEnabled()) {
        massChangeModule.disable();
      } else {
        massChangeModule.enable();
      }
    },

    closeBulkChange({ commit, state }) {
      if (massChangeModule.isEnabled()) {
        massChangeModule.disable();
      }
    },

    cascadeSorting({ commit }) {
      let actions = [];
      const tasks = [];
      const activeGanttId = projectsModel.getActiveGanttId();
      const { multiview_id } = gantt.config;

      function sortByReq(arr) {
        arr.sort((a, b) => {
          const aDuration = gantt.calculateDuration(new Date(a.start_date), new Date(a.end_date), a);
          const bDuration = gantt.calculateDuration(new Date(b.start_date), new Date(b.end_date), b);

          if ((new Date(a.start_date)).valueOf() === (new Date(b.start_date)).valueOf()) {
            if (aDuration === bDuration) {
              return b.sortorder < a.sortorder;
            }

            return aDuration - bDuration;
          }

          return a.start_date - b.start_date;
        });

        return arr;
      }

      const cascadeSort = task => {
        const activeTasks = [];

        gantt.getChildren(task).forEach(item => {
          const currentTask = gantt.getTask(item);

          if (currentTask.type !== 'button') {
            activeTasks.push(currentTask);
          }
        });

        const sortArr = sortByReq(activeTasks);

        sortArr.forEach((item, index) => {
          if (item.parent !== 1) {
            tasks.push({ id: item.id, sortorder: index });
          }
        });

        for (let i = 0; i < sortArr.length; i++) {
          if (gantt.getChildren(sortArr[i].id)) {
            cascadeSort(sortArr[i].id);
          } else {
            return;
          }
        }
      };

      cascadeSort(gantt.getTaskByIndex(0).id);
      actions = [{
        actionType: 8,
        actionPayload: { tasks },
      }];
      _helpers.sendDataToMassChange(actions, activeGanttId || multiview_id)
        .then(() => {
          gantt.render();// for update scrollbar
        });
    },
    refreshFilter({ commit }) {
      app.trigger('header:refresh:filter');
    },
    openFilterPopup({ commit, state }) {
      if (massChangeModule.isEnabled()) {
        massChangeModule.disable();
      }
      app.trigger('filter:popup:toggle');
    },
    changeZoom({ commit, state }, value) {
      commit('setZoom', value);
      app.trigger('zoom:change', value);
    },
    exportAction({ commit, state }) {
      if (state.currentRoute.params.mode === 'gantt') {
        setTimeout(() => {
          const routerName = ganttViewModel.isMultiview() ? 'projectsMultiView' : 'project';

          routerHelper.pushByNameAndParams(routerName, { mode: 'export' });
          gantt.callEvent('ganttRender');
        }, 500);
      }

      if (state.currentRoute.path.includes('workload')) {
        app.trigger('header:export:workload');
      }

      if (state.currentRoute.name === 'reportsDetail') {
        app.trigger('header:export:report', state.currentRoute.params.type);
      }

      if (state.currentRoute.name === 'userLogs') {
        app.trigger('header:export:report', 'user-logs');
      }

      if (state.currentRoute.params.mode === 'list') {
        app.trigger('header:export:listView');
      }
    },
    selectBoardType({ commit }, type) {
      commit('setActiveBoardType', type);
      app.trigger('kanban:change:mode', type);
    },
    selectMyTasksFilter({ commit }) {
      commit('setMyTasksFilter', true);
      app.trigger('header:click:myTasks');
    },
    selectWorkloadType({ commit }, type) {
      commit('setActiveWorkloadType', type);
      app.trigger('workload:change:type', type);
    },
    selectMissedFeatures({ commit, state }) {
      const isWorkload = state.currentRoute.path.includes('workload');
      const isBoard = state.currentRoute.path.includes('board');
      const isMyList = state.currentRoute.path.includes('list');
      const isDashboard = state.currentRoute.path.includes('dashboard');
      const isCalendar = state.currentRoute.path.includes('calendar');
      let featuresName = '';

      if (isWorkload) featuresName = 'workload-feedback';
      if (isBoard) featuresName = 'board-feedback';
      if (isMyList) featuresName = 'mylist-feedback';
      if (isDashboard) featuresName = 'dashboard-feedback';
      if (isCalendar) featuresName = 'calendar-feedback';

      if (isBoard || isWorkload || isMyList || isDashboard || isCalendar || state.currentRoute.params.multiviewID) {
        app.trigger('messageUsForm:show', featuresName, null, 'workload-feedback');
      }
    },

    setViewSettings({
      commit, state, getters, dispatch,
    }, data) {
      if (getters.currentRoute.path.includes('/gantt')) {
        const viewSettings = { ...state.viewSettings };

        _helpers.setGanttViewSettings(data, viewSettings);
        ganttViewModel.updateGanttSettings();
      }
      commit('setViewSettings', { ...state.viewSettings, ...{ [data.name]: data.value } });

      if (getters.currentRoute.path.includes('/list') && getters.currentRoute.path.includes('project')) {
        const settings = {
          viewSettings: {
            skins: getters.viewSettings.skins,
            completedTask: getters.viewSettings.completedTask,
          },
          overdue: getters.isOverdueTasks,
        };

        app.trigger('listView:config:updateSettings', { settings });
      }
    },

    toggleOverdueTasks({ commit, state, getters }) {
      commit('setOverdueTasks', !state.isOverdueTasks);

      if (getters.currentRoute.path.includes('/list') && getters.currentRoute.path.includes('project')) {
        const settings = {
          viewSettings: {
            skins: getters.viewSettings.skins,
            completedTask: getters.viewSettings.completedTask,
          },
          overdue: getters.isOverdueTasks,
        };

        app.trigger('listView:config:updateSettings', { settings });

        return;
      }

      app.trigger('settings:highlightOverdue:change', state.isOverdueTasks);
      ganttViewModel.addParamsToUpdate({ key: 'highlight_overdue', value: state.isOverdueTasks });
      ganttViewModel.updateGanttSettings();
    },
    toggleCriticalPath({ commit, state }) {
      commit('setCriticalPath', !state.isCriticalPath);
      app.trigger('settings:criticalPath:change', state.isCriticalPath);
      ganttViewModel.addParamsToUpdate({ key: 'highlight_critical_path', value: state.isCriticalPath });
      ganttViewModel.updateGanttSettings();
    },
    toggleBaselinePopup({ commit, state }, targetNode) {
      const currentSelectedTask = gantt.selectTask();

      currentSelectedTask && gantt.unselectTask(currentSelectedTask);

      commit('toggleBaselinePopup', !state.isShowBaselinePopup);
      $$('baselinesPopup').toggle(targetNode);
    },
    changeReportGroupBy({ commit }, data) {
      commit('setGroupByActiveData', data);
      app.trigger('header:change:groupByTable', data);
    },

    updatePublicUrlInfo({ commit }) {
      if (!GT.appMode.isLink) return;

      let projectName = GT.ganttData.project.name;
      const favicon = (GT.ganttData.project.favicon && !!GT.ganttData.project.show_favicon) ? GT.ganttData.project.favicon : null;
      const teamLogo = GT.ganttData.project.teamLogo;
      const sharedByName = (GT.ganttData.user.lastName || GT.ganttData.user.firstName) ? `${GT.ganttData.user.lastName} ${GT.ganttData.user.firstName}` : '';

      if (projectName && projectName.indexOf && projectName.indexOf('/templates/') === 0) {
        // for public template we transtalte the last part of path
        const projectNameParts = projectName.split('/');

        projectName = __(projectNameParts[4]);
      }

      const dataForHeader = {
        projectName,
        teamName: GT.ganttData.project.teamName,
        sharedBy: `${__('shared_by_link_owner_title')} ${sharedByName}`,
        logo: teamLogo || favicon,
        css: teamLogo ? 'image' : 'favicon',
      };

      commit('setPublicUrlInfo', dataForHeader);
    },
  },
};

export default headerStore;
