<template>
  <div
    ref="cell"
    class="cell-number"
    :class="{'edit': editMode && !disabled && editable, 'select_none': !editMode, 'disabled': disabled, 'not_allowed': !editable}"
  >
    <div
      v-if="editMode && !disabled && editable"
      class=" edited-cell "
    >
      <div
        class="control-btn"
        @click="minusClick"
      >
        -
      </div>
      <input
        ref="input_field"
        v-model="inputTxt"
        type="text"
        :autofocus="editMode"
        @keypress="onKeypress"
      >
      <div
        class="control-btn"
        @click="plusClick"
      >
        +
      </div>
    </div>
    <span
      v-else
      class="one-wrap"
      :class="{crossout: crossout, discolor: discolor }"
      :style="{opacity: cellOpecity}"
    >  {{ formatedValue }}  </span>
  </div>
</template>

<script>
import estimationModule from '../../../gantt/modules/estimation';
import numbersHelper from '../../../../helpers/numbers';

export default {
  name: 'NumberCell',
  components: { },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    value: { type: [String, Number], required: true },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    type: {
      type: String,
      required: false,
      default: 'number',
      validator(value) {
        return (['number', 'percent'].indexOf(value) !== -1);
      },
    },
    discolor: { type: Boolean, required: false, default: false },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      editMode: false,
      inputTxt: 0,
      oldText: 0,
      formatedValue: '',
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) { return 0.8; }

      return val;
    },
  },

  watch: {
    editMode(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.$refs.input_field) return;

          this.$refs.input_field.focus();
          this.$refs.input_field.select();
        });
      } else {
        this.changeValue();
      }
    },
    value(val) {
      this.updateInnerVal();
    },
  },

  mounted() {
    this.updateInnerVal();
    document.addEventListener('click', this.documentClick);
  },

  beforeDestroy() {
    if (this.editMode) this.changeValue();
    document.removeEventListener('click', this.documentClick);
  },

  methods: {
    updateInnerVal() {
      this.updateFormatedVal(this.value);

      let val = +this.value ? +this.value : 0;

      val = this.type === 'percent' ? Math.round(val * 100) : val;

      this.inputTxt = val;
      this.oldText = val;
    },

    updateFormatedVal(val) {
      if ((typeof val === 'undefined')) {
        this.formatedValue = '';

        return;
      }

      if (!val) val = 0;

      if (this.type === 'percent') {
        this.formatedValue = `${Math.round(val * 100)}%`;

        return;
      }

      if (['total_price', 'actual_cost'].includes(this.property)) {
        this.formatedValue = estimationModule.helpers.formatWithCurrency(val);

        return;
      }

      this.formatedValue = numbersHelper.getFormatted(+val);
    },

    minusClick() {
      this.editMode = true;

      if (this.inputTxt <= 0) {
        this.inputTxt = 0;

        return;
      }

      this.inputTxt--;
      this.$refs.input_field.focus();
    },
    plusClick() {
      this.editMode = true;
      if (this.type === 'percent' && this.inputTxt == 100) { return; }

      this.inputTxt++;
      this.$refs.input_field.focus();
    },
    onKeypress(e) {
      if (e.which === 13) {
        this.editMode = false;
        this.changeValue();

        return;
      }

      const char = String.fromCharCode(e.keyCode);

      if (!/^[0-9.]$/.test(char)) {
        e.preventDefault();
        // webix.message({ type: 'warning', text: __('filter_number_custom_column_not_valid') });
      }
      if (char === '.' && this.inputTxt.toString().includes('.')) {
        e.preventDefault();
      }
      if (char === '.' && !this.inputTxt && this.inputTxt !== 0) {
        e.preventDefault();
      }
    },
    changeValue() {
      if (!this.editMode && this.oldText != this.inputTxt) {
        let val = +this.inputTxt;

        if (this.type === 'percent') {
          val = val > 100 ? 100 : val;
          this.inputTxt = val;
          val /= 100;
        }
        this.oldText = this.inputTxt;

        this.updateFormatedVal(val);
        this.$emit('change', val);
      }
    },
    documentClick(e) {
      const isCell = this.$refs.cell.contains(e.target);

      this.editMode = isCell;
    },
  },
};
</script>

<style scoped lang='less'>
.cell-number{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  border-width: 0px;
  padding: 0 10px;

  &.edit{
    // border: 1px solid #fbc02d;
    box-shadow: inset 0 0 0 1px #247ecc;
    background-color: #fff;
  }
  &.disabled{
    color: #9e9e9e;
  }

  &.not_allowed{
    cursor: not-allowed;
  }

  input{
    width: 100%;
    text-align: center;
    margin: 0 6px;
  }
}
.edited-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  .control-btn{
    margin-top: 1px;
    padding: 5px;
    color: #969696;
    font-size: 16px;
    font-family: "Courier New",Courier,monospace;
    user-select: none;
  }
}
.select_none{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.one-wrap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  display: inline-block;
}

.discolor{
    opacity: 0.5;
}

.crossout{
    text-decoration: line-through;
}
</style>
