var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "cell",
      staticClass: "cell-number",
      class: {
        edit: _vm.editMode && !_vm.disabled && _vm.editable,
        select_none: !_vm.editMode,
        disabled: _vm.disabled,
        not_allowed: !_vm.editable,
      },
    },
    [
      _vm.editMode && !_vm.disabled && _vm.editable
        ? _c("div", { staticClass: " edited-cell " }, [
            _c(
              "div",
              { staticClass: "control-btn", on: { click: _vm.minusClick } },
              [_vm._v("\n      -\n    ")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputTxt,
                  expression: "inputTxt",
                },
              ],
              ref: "input_field",
              attrs: { type: "text", autofocus: _vm.editMode },
              domProps: { value: _vm.inputTxt },
              on: {
                keypress: _vm.onKeypress,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputTxt = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "control-btn", on: { click: _vm.plusClick } },
              [_vm._v("\n      +\n    ")]
            ),
          ])
        : _c(
            "span",
            {
              staticClass: "one-wrap",
              class: { crossout: _vm.crossout, discolor: _vm.discolor },
              style: { opacity: _vm.cellOpecity },
            },
            [_vm._v("  " + _vm._s(_vm.formatedValue) + "  ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }