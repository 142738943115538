<template>
  <portal
    v-if="show"
    to="confirm-popup"
    order="5"
  >
    <div
      ref="confirm"
      :class="[$style.core_confirm_popup]"
      tabindex="-5"
      @keydown.esc="keyboardEsc"
      @keydown.enter="keyboardEnter"
      @click.stop
    >
      <div
        id="gp_autotest_confirm_popap"
        :class="[$style.container, $style.show]"
        :style="{width: popupWidth ? `${popupWidth}px` : 'auto'}"
      >
        <div :class="$style.body">
          <div
            v-if="title || closeIcon"
            :class="$style.head"
          >
            <div
              v-if="title"
              :class="$style.head__left"
            >
              {{ title }}
            </div>
            <div
              v-if="closeIcon"
              :class="$style.head__right"
            >
              <svg-sprite
                :name="'close-1'"
                :type="'bold'"
                :size="24"
                :color="'#999999'"
                :class="$style.close_icon"
                @click="onClickCancel"
              />
            </div>
          </div>
          <div
            v-if="text"
            :class="[$style.confirm_message, $style[confirmMessageClass]]"
            v-html="text"
          />
          <div
            v-if="additionalText"
            :class="[$style.confirm_message, $style.confirm_message_additional]"
            v-html="additionalText"
          />

          <slot
            :class="[$style.confirm_popup_body]"
            name="body"
          />

          <div v-if="confirmString" :class="[$style.confirm_input_container]">
            <vgp-input
              v-model="inputConfirmValue"
              :small="true"
              :placeholder="confirmStringInputPlaceholder"
              :border="true"
            />
          </div>
        </div>
        <div
          v-if="cancelButton || okButton"
          :class="$style.footer"
        >
          <div :class="$style.actions">
            <vgp-button
              v-if="cancelButton"
              :id="cancelButton.id"
              class="gp_autotest_confirm_popap_btn_cancel"
              :class="$style.action_btn"
              :type="cancelButton.type"
              :small="true"
              :width="buttonWidth"
              :label="cancelButton.title"
              @onClick="onClickCancel"
            />
            <vgp-button
              v-if="okButton"
              :id="okButton.id"
              :class="$style.action_btn"
              class="gp_autotest_confirm_popap_btn_ok"
              :type="okButton.type"
              :small="true"
              :disabled="disabledOk"
              :width="buttonWidth"
              :label="okButton.title"
              @onClick="onClickOk"
            />
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import _ from 'lodash';
import vgpButton from '$$vueCmp/globalButton/vgpButton.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import VgpInput from '$$vueCmp/vInput/vgpInput.vue';

export default {
  name: 'CoreConfirmPopup',
  components: {
    vgpButton,
    vgpIconButton,
    VgpInput,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    additionalText: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    popupWidth: {
      type: Number,
      required: false,
      default: 0,
    },
    buttonWidth: {
      type: String,
      required: false,
      default: '90px',
    },
    confirmString: {
      type: String,
      required: false,
      default: '',
    },
    confirmStringInputPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    okButton: {
      type: [Object],
      required: false,
      default: () => ({
        title: 'ok',
        type: 'secondary',
      }),
    },
    cancelButton: {
      type: [Object],
      required: false,
      default: () => ({
        title: 'cancel',
        type: 'secondary',
      }),
    },
    confirmMessageClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    inputConfirmValue: '',
  }),
  mounted() {
    this.$nextTick(() => {
      this.$refs?.confirm?.focus();
    });
  },
  computed: {
    disabledOk() {
      if (this.confirmString) {
        return this.inputConfirmValue !== this.confirmString;
      }
      return false;
    },
  },
  methods: {
    cleanValues() {
      this.inputConfirmValue = '';
    },
    keyboardEsc(e) {
      e.preventDefault();
      e.stopPropagation();
      this.cleanValues();
      this.$emit('onClickCancel');
    },
    keyboardEnter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.cleanValues();
      this.$emit('onClickOk');
    },

    onClickOk: _.debounce(function () {
      this.cleanValues();
      this.$emit('onClickOk');
    }, 1500, {
      leading: true,
    }),

    onClickCancel() {
      this.cleanValues();
      this.$emit('onClickCancel');
    },
  },
};
</script>

<style module src='./less/corePopup.less'></style>
