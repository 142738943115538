var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integration_slack_settings_window" }, [
    _c(
      "div",
      { staticClass: "integration_slack_settings_window-header" },
      [
        _c("div", { staticClass: "left-side" }, [
          _c(
            "div",
            { staticClass: "icon" },
            [_c("webix-ui", { attrs: { config: _vm.icons.slackIcon } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("closer", { attrs: { "on-close": _vm.closeWindow, size: 30 } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "integration_slack_settings_window-body" }, [
      _c("div", { staticClass: "scroll_container" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", { staticClass: "container-description" }, [
              _c("div", { staticClass: "main-title" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.locales("integration_slack_settings_page_title")
                      ) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _vm.removeIntegrationButton && !_vm.tablePreloder
                  ? _c(
                      "div",
                      {
                        staticClass: "button",
                        on: { click: _vm.removeIntegration },
                      },
                      [
                        _c("div", { staticClass: "button-text" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_slack_settings_page_remove"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.removeIntegrationButton && !_vm.tablePreloder
                  ? _c(
                      "div",
                      {
                        staticClass: "button",
                        on: { click: _vm.disableIntegration },
                      },
                      [
                        _c("div", { staticClass: "button-text" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_slack_settings_page_disable"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.locales("integration_slack_settings_page_description")
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              !_vm.tablePreloder
                ? _c(
                    "div",
                    { key: "table", staticClass: "connections_table" },
                    [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "project_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_settings_page_table_header_project"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "channel_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_slack_settings_page_table_header_channel"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "settings_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_settings_page_table_header_settings"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.filteredProjectChannelConnections.length
                        ? _c(
                            "div",
                            { staticClass: "connections" },
                            _vm._l(
                              _vm.filteredProjectChannelConnections,
                              function (projectChannelConnection, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "connection",
                                    class: {
                                      full_border:
                                        projectChannelConnection.fullBorder,
                                    },
                                  },
                                  [
                                    !projectChannelConnection.editMode
                                      ? _c(
                                          "div",
                                          { staticClass: "view_container" },
                                          [
                                            projectChannelConnection.showProject
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "connection_project_title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text",
                                                        attrs: {
                                                          title:
                                                            _vm.getProjectByGanttId(
                                                              projectChannelConnection.gantt_id
                                                            ).name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.getProjectByGanttId(
                                                                projectChannelConnection.gantt_id
                                                              ).name
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c("div", {
                                                  staticClass:
                                                    "connection_project_title",
                                                }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "channel_setting_container",
                                                class: {
                                                  small_border:
                                                    projectChannelConnection.smallBorder,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "channel_title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text",
                                                        attrs: {
                                                          title:
                                                            projectChannelConnection.channel_name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              projectChannelConnection.channel_name
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "settings_title",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "text",
                                                        attrs: {
                                                          title:
                                                            _vm.getSettingsString(
                                                              projectChannelConnection
                                                            ).settingsText,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.getSettingsString(
                                                                projectChannelConnection
                                                              ).settingsText
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "buttons" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "edit",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.toggleEditView(
                                                              projectChannelConnection.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("webix-ui", {
                                                          attrs: {
                                                            config:
                                                              _vm.icons
                                                                .editIcon,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              projectChannelConnection.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("webix-ui", {
                                                          attrs: {
                                                            config:
                                                              _vm.icons
                                                                .deleteIcon,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "edit_container" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "inputs" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "edit_project",
                                                  },
                                                  [
                                                    _c(
                                                      "integration-slack-select-vue",
                                                      {
                                                        attrs: {
                                                          value:
                                                            _vm.selectedInputs
                                                              .projectId,
                                                          options:
                                                            _vm.filteredProjectOptions,
                                                          placeholder:
                                                            _vm.locales(
                                                              "integration_slack_settings_page_table_input_plh_project"
                                                            ),
                                                        },
                                                        on: {
                                                          focus:
                                                            _vm.onFocusSelect,
                                                          change:
                                                            _vm.onChangeProjectId,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "channels_settings_container",
                                                    class: {
                                                      small_border:
                                                        projectChannelConnection.smallBorder,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "channels_settings_inputs",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "edit_channel",
                                                            class: {
                                                              error:
                                                                _vm.channelIsEmptyError,
                                                            },
                                                          },
                                                          [
                                                            _vm.channelIsEmptyError
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.locales(
                                                                            "integration_slack_settings_page_table_input_error_cant_be_empty"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "integration-slack-select-vue",
                                                              {
                                                                key: _vm.onChangesFlag,
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectedInputs
                                                                      .channelId,
                                                                  options:
                                                                    _vm.filteredChannelOptions,
                                                                  placeholder:
                                                                    _vm.locales(
                                                                      "integration_slack_settings_page_table_input_plh_channel"
                                                                    ),
                                                                },
                                                                on: {
                                                                  focus:
                                                                    _vm.onFocusSelect,
                                                                  change:
                                                                    _vm.onChangeChannelId,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "edit_settings",
                                                          },
                                                          [
                                                            _c(
                                                              "integration-slack-multiselect-vue",
                                                              {
                                                                key: _vm.onChangesFlag,
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectedInputs
                                                                      .settings,
                                                                  options:
                                                                    _vm.settingsOptions,
                                                                  placeholder:
                                                                    _vm.locales(
                                                                      "integration_slack_settings_page_table_input_plh_settings"
                                                                    ),
                                                                },
                                                                on: {
                                                                  focus:
                                                                    _vm.onFocusSelect,
                                                                  change:
                                                                    _vm.onChangeSettings,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "buttons",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "buttons_ok",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editItem(
                                                                  projectChannelConnection.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("webix-ui", {
                                                              attrs: {
                                                                config:
                                                                  _vm.icons
                                                                    .okIcon,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "buttons_cancel",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.closeEditMode()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("webix-ui", {
                                                              attrs: {
                                                                config:
                                                                  _vm.icons
                                                                    .cancelIcon,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _c("div", { staticClass: "no_connections" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.locales(
                                      "integration_slack_settings_page_table_no_connections"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                      _vm._v(" "),
                      !_vm.addMode
                        ? _c("div", { staticClass: "add_item_container" }, [
                            _vm.hasPossibleAddNewItem && !_vm.editMode
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "add_button",
                                    on: { click: _vm.onClickAdd },
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.locales(
                                              "integration_slack_settings_page_add_button"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.hasPossibleAddNewItem && !_vm.editMode
                              ? _c(
                                  "div",
                                  { staticClass: "no_possible_add_new" },
                                  [
                                    _c("div", { staticClass: "msg" }, [
                                      _c(
                                        "div",
                                        { staticClass: "msg_icon" },
                                        [
                                          _c("webix-ui", {
                                            attrs: {
                                              config: _vm.icons.yellowInfo,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "msg_text" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.locales(
                                                "integration_slack_settings_page_add_no_possible"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "div",
                            { staticClass: "add_item_inputs_container" },
                            [
                              _c(
                                "div",
                                { staticClass: "inputs_buttons_container" },
                                [
                                  _c("div", { staticClass: "inputs" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input_project",
                                        class: {
                                          is_error: _vm.projectIsEmptyError,
                                        },
                                      },
                                      [
                                        _vm.projectIsEmptyError
                                          ? _c(
                                              "div",
                                              { staticClass: "is_error" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.locales(
                                                        "integration_slack_settings_page_table_input_error_cant_be_empty"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("integration-slack-select-vue", {
                                          attrs: {
                                            value: _vm.selectedInputs.projectId,
                                            options: _vm.filteredProjectOptions,
                                            placeholder: _vm.locales(
                                              "integration_slack_settings_page_table_input_plh_project"
                                            ),
                                          },
                                          on: {
                                            focus: _vm.onFocusSelect,
                                            change: _vm.onChangeProjectId,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input_channel",
                                        class: {
                                          is_error: _vm.channelIsEmptyError,
                                        },
                                      },
                                      [
                                        _vm.channelIsEmptyError
                                          ? _c(
                                              "div",
                                              { staticClass: "is_error" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.locales(
                                                        "integration_slack_settings_page_table_input_error_cant_be_empty"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("integration-slack-select-vue", {
                                          key: _vm.onChangesFlag,
                                          attrs: {
                                            value: _vm.selectedInputs.channelId,
                                            options: _vm.filteredChannelOptions,
                                            placeholder: _vm.locales(
                                              "integration_slack_settings_page_table_input_plh_channel"
                                            ),
                                          },
                                          on: {
                                            focus: _vm.onFocusSelect,
                                            change: _vm.onChangeChannelId,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "input_settings" },
                                      [
                                        _c(
                                          "integration-slack-multiselect-vue",
                                          {
                                            key: _vm.onChangesFlag,
                                            attrs: {
                                              value:
                                                _vm.selectedInputs.settings,
                                              options: _vm.settingsOptions,
                                              placeholder: _vm.locales(
                                                "integration_slack_settings_page_table_input_plh_settings"
                                              ),
                                            },
                                            on: {
                                              focus: _vm.onFocusSelect,
                                              change: _vm.onChangeSettings,
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "buttons" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "buttons_ok",
                                        on: { click: _vm.addItem },
                                      },
                                      [
                                        _c("webix-ui", {
                                          attrs: { config: _vm.icons.okIcon },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "buttons_cancel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.closeAddMode()
                                          },
                                        },
                                      },
                                      [
                                        _c("webix-ui", {
                                          attrs: {
                                            config: _vm.icons.cancelIcon,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                    ]
                  )
                : _c(
                    "div",
                    { key: "preloder", staticClass: "connections_table" },
                    [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "project_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_settings_page_table_header_project"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "channel_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_slack_settings_page_table_header_channel"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "settings_title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.locales(
                                  "integration_settings_page_table_header_settings"
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l([1, 2], function (item) {
                        return _c(
                          "div",
                          { key: item, staticClass: "connections" },
                          _vm._l([1, 2, 3], function (el) {
                            return _c(
                              "div",
                              {
                                key: el,
                                staticClass: "connection",
                                class: { full_border: el === 3 },
                              },
                              [
                                _c("div", { staticClass: "view_container" }, [
                                  _c(
                                    "div",
                                    { staticClass: "connection_project_title" },
                                    [
                                      el === 1
                                        ? _c("div", {
                                            staticClass: "text_preloder",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "channel_setting_container",
                                      class: { small_border: el !== 3 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "channel_title" },
                                        [
                                          _c("div", {
                                            staticClass: "text_preloder",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "settings_title" },
                                        [
                                          _c("div", {
                                            staticClass: "text_preloder",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "buttons" }, [
                                        _c("div", {
                                          staticClass: "button_preloder",
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "button_preloder",
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      }),
                    ],
                    2
                  ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }