<template>
  <div
    :class="[
      $.chart,
      $.chart_project_info,
    ]"
  >
    <div :class="$.header">
      <div :class="$.title">
        {{ locales(`chart_${chart.key}_title`) }}
      </div>
      <vgp-switch
        v-if="statusSwitch"
        :is-toggle-on="chart.status"
        @onChange="$emit('onToggleStatus')"
      />
    </div>
    <div :class="$.wrapper">
      <div :class="$.row">
        <div :class="$.col">
          <div
            v-if="description"
            :class="$.description"
          >
            {{ locales(`chart_${chart.key}_description`) }}
          </div>
          <div
            v-else
            :class="[
              $.textarea,
              !data.description ? $.placeholder : '',
              'ql-snow',
            ]"
            @click="$emit('onOpenDescription', $event)"
          >
            <template v-if="!data.description">
              {{ locales('overview_project_description_placeholder') }}
            </template>
            <div
              v-else
              class="ql-editor"
              v-html="data.description"
            />
          </div>
        </div>
        <div :class="$.col">
          <div
            :class="[
              $.row,
              $.info,
            ]"
          >
            <div :class="$.col">
              <div :class="$.team">
                <div :class="$.line">
                  <div :class="$.label">
                    {{ locales('overview_owner') }}
                  </div>
                  <div
                    :class="$.avatar"
                    :style="`background-image: url('${data.owner.photo}');`"
                  />
                  <div :class="$.name">
                    {{ data.owner.name }}
                  </div>
                </div>
                <div :class="$.line">
                  <div :class="$.label">
                    {{ locales('overview_team') }}
                  </div>
                  <div
                    v-for="(item, index) in teamPreview"
                    :key="index"
                    :style="{display: 'flex', marginLeft: '-8px'}"
                    @mouseenter="onMouseEnterAvatar"
                    @mouseleave="onMouseLeaveAvatar"
                  >
                    <vgp-avatar
                      :custom-size="24"
                      :src="item.photo"
                    />
                  </div>
                  <div
                    v-if="data.team.length > teamCount"
                    ref="avatarEtc"
                    :class="[
                      $.avatar_etc,
                      isShowTeamDropdown ? $.active : '',
                    ]"
                    @click.stop="toggleTeamDropdown"
                  >
                    +{{ data.team.length - teamCount }}
                    <div :class="$.triangle" />
                  </div>
                  <context-menu
                    v-if="isShowTeamDropdown"
                    :position="positionTeamDropdown"
                    style="min-width: 226px; max-width: 300px;"
                    :offset="{ top: 4 }"
                    @closePopup="isShowTeamDropdown = false"
                  >
                    <template #body>
                      <div :class="$.list_title">
                        <span>{{ locales('common_people') }}</span>
                      </div>
                      <div
                        v-for="(item, index) in data.team"
                        :key="index"
                        :class="$.list_item"
                      >
                        <vgp-avatar
                          :custom-size="24"
                          :src="item.photo"
                        />
                        <div :class="$.name">
                          {{ item.name }}
                        </div>
                      </div>
                    </template>
                  </context-menu>
                </div>
              </div>
            </div>
            <div :class="$.col">
              <div :class="$.progress">
                <div :class="$.label">
                  {{ locales('overview_progress') }}: {{ data.progress }}%
                </div>
                <div :class="$.bar">
                  <span :style="`width: ${data.progress}%`" />
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              $.row,
              $.dates,
            ]"
          >
            <div :class="$.col">
              <div :class="$.item">
                <div :class="$.label">
                  {{ locales('overview_start_date') }}
                </div>
                <div :class="$.value">
                  {{ data.startDate }}
                </div>
              </div>
              <div :class="$.item">
                <div :class="$.value">
                  —
                </div>
              </div>
              <div :class="$.item">
                <div :class="$.label">
                  {{ locales('overview_end_date') }}
                </div>
                <div :class="$.value">
                  {{ data.endDate }}
                </div>
              </div>
            </div>
            <div :class="$.col">
              <div :class="$.item">
                <div :class="$.label">
                  {{ locales('overview_last_change') }}
                </div>
                <div :class="$.value">
                  {{ data.lastUpdate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VgpAvatar from '../../common/VueComponents/avatar/vgpAvatar.vue';
import VgpSwitch from '../../common/VueComponents/checkbox/squareToggle.vue';

export default {
  components: {
    VgpSwitch,
    VgpAvatar,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    statusSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      isShowTeamDropdown: false,
      positionTeamDropdown: null,
      teamCount: 5,
    };
  },
  computed: {
    teamPreview() {
      return this.data.team.slice(0, this.teamCount);
    },
  },
  methods: {
    toggleTeamDropdown() {
      this.isShowTeamDropdown = !this.isShowTeamDropdown;
      if (this.isShowTeamDropdown) {
        this.positionTeamDropdown = this.$refs.avatarEtc.getBoundingClientRect();
      }
    },
    onMouseEnterAvatar(e) {
      if (this.data.team.length > this.teamCount || this.static) return false;
      this.isShowTeamDropdown = true;
      this.positionTeamDropdown = e.target.getBoundingClientRect();
    },
    onMouseLeaveAvatar() {
      if (this.data.team.length > this.teamCount || this.static) return false;
      this.isShowTeamDropdown = false;
    },
  },
};
</script>

<style module="$" src="../less/chart.less"></style>
