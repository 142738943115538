// public/models/onBoarding/index.js
// !TODO: description

import app, { appIds } from '../../app';
import routerHelper from '../../helpers/router';

import OnBoardFeaturePointer from './FeaturePointer';
import OnBoardPopupManager from './onBoardModal';
import { isAnyPopupOpened } from './popups';
import stepIds from './stepIds';
import globalStore from '../../store/main';
import constants from "../../helpers/constants";
import rights from "../../components/rights";

const __ = window.__;

// default onboarding feature-pointer radius
const POINTER_RADIUS = 24;
const POLL_INTERVAL = 500;

const idPointer = 'idPointer';

const {
  events: {
    ID_EVENT_APP_ROUTECHANGED,
    ID_EVENT_APP_ONBOARD_NEXT, ID_EVENT_APP_ONBOARD_FINISH,
    ID_EVENT_POPUPS_CHANGED, ID_EVENT_APP_INIT_MODELS
  },
  popupViews: { },
  controlViews: {}
} = appIds;

const {
  FINISH_STEP,
  ADD_TASK_STEP,
  TASKS_CONNECTION_STEP,
  ASSIGN_TASK_STEP,
  CUSTOM_COLUMS_STEP,
} = stepIds;

// the current onboarding step
let _step = 0;

const featurePointer = new OnBoardFeaturePointer(idPointer, POINTER_RADIUS);
const popupManager = OnBoardPopupManager;

// !TODO: DESCRIPTION
// {String, Object, Object, Object}
const _displayTourStep = (idStep, config, thisArg, callbacks) => {
  const { data, handlers } = thisArg;
  const _node = callbacks.cbGetNode();

  if (!_node) {
    console.warn('[onboarding::displayTourStep] -> node not found!'); // !FIXME
    return;
  }

  if (!data.node || /**!!! */ data.node !== _node) {
    data.node = _node;
    if (callbacks.cbAssignNode) {
      callbacks.cbAssignNode(data.node);
    }
  }

  const nodeRect = _node.getBoundingClientRect();

  // console.warn('nodeRect -->', nodeRect); // !FIXME: DEBUG


  const pointerX = nodeRect.x + config.pointer.offsetX;
  const pointerY = nodeRect.y + config.pointer.offsetY;

  if (
    pointerX !== data.pointerX ||
    pointerY !== data.pointerY
  ) {
    data.pointerX = pointerX;
    data.pointerY = pointerY;

    featurePointer.show(pointerX, pointerY);

    const popup = _.cloneDeep(config.popup);

    if (popup.xWithoutWideBar) {
      if(!globalStore.getters['sidebar/isOpenSideBar']){
        popup.x = popup.xWithoutWideBar;
      }
    }

    popupManager.show(idStep, _node, popup);
  }

  if (callbacks.cbShowPointer && !callbacks.cbShowPointer()) {
    // console.warn('featurePointer.hide() -->'); // !FIXME: DEBUG
    featurePointer.hide();
  }

  if (callbacks.cbShowPopup && !callbacks.cbShowPopup()) {
    // console.warn('popupManager.hide(); -->'); // !FIXME: DEBUG
    popupManager.hide();
  }
};

// a global onboarding configuration
const stepCfg = {
  [FINISH_STEP]: {
    data: {},
    config: {
      name: 'FINISH_STEP',
    },
    routeName: 'app',
    idStep: FINISH_STEP,
    idNextStep: ADD_TASK_STEP,
    init: function () {
      const { data } = this;

      return new Promise((resolve, reject) => {
        data.initialized = true;
        return resolve(); // stub
      });
    },
    cleanup: function () {
      const { data } = this;

      return new Promise((resolve, reject) => {
        data.initialized = false;
        return resolve(); // stub
      });
    }
  },
  [ADD_TASK_STEP]: {
    routeName: 'project',
    data: {},
    config: {
      name: 'ADD_TASK_STEP',
      pointer: { offsetX: 12, offsetY: 18 },
      popup: { pos: 'right', x: 36, y: 25 }
    },
    idStep: ADD_TASK_STEP,
    idNextStep: TASKS_CONNECTION_STEP,
    handlers: {
      onAddTaskClick: function () {
        stepCfg[ADD_TASK_STEP].data.nodeUsed = true;
      },
      onTaskCreate: function (parent, newName) {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: TASKS_CONNECTION_STEP,
        });
      },
      onFinishOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: FINISH_STEP,
        });
      },
    },
    callbacks: {
      getNode: () => {
        const elems = document.getElementsByClassName('js_add_task');
        return elems.length !== 0 && elems[elems.length - 1];
      },
      cbAssignNode: (node) => {
        const {
          data,
          handlers: { onAddTaskClick }
        } = stepCfg[ADD_TASK_STEP];

        data.nodeUsed = false;

        node.addEventListener('click', onAddTaskClick);
      },
      cbShowPointer: () => {
        const { data } = stepCfg[ADD_TASK_STEP];
        return !data.nodeUsed;
      }
    },
    init: function () {
      const __this = this;
      const { data, handlers, config, callbacks } = __this;

      return new Promise((resolve, reject) => {
        if (data.initialized) {
          return resolve();
        }

        data.idEvtFinish = app.on(ID_EVENT_APP_ONBOARD_FINISH, handlers.onFinishOnboarding);
        data.createTaskId = app.on('createNewTask', handlers.onTaskCreate);

        data.idTimer = setInterval(() => _displayTourStep('addTaskStep', config, __this, {
          cbAssignNode: callbacks.cbAssignNode,
          cbShowPointer: callbacks.cbShowPointer,
          cbGetNode: callbacks.getNode
        }), POLL_INTERVAL);

        data.initialized = true;

        return resolve();
      });
    },
    cleanup: function () {
      const { data, handlers } = this;

      return new Promise((resolve, reject) => {
        if (data.idTimer) {
          clearInterval(data.idTimer);
          data.idTimer = null;
        }

        if (!!data.pointerX) { data.pointerX = null }
        if (!!data.pointerY) { data.pointerY = null; }

        if (data.node) {
          data.node.removeEventListener('click', handlers.onAddTaskClick);
          data.node = null;
        }

        if (data.createTaskId) {
          app.off(data.createTaskId);
          data.createTaskId = null;
        }

        if (data.idEvtFinish) {
          app.off(data.idEvtFinish);
          data.idEvtFinish = null;
        }

        popupManager.hide('addTaskStep');
        featurePointer.hide();

        data.initialized = false;
        return resolve();
      });
    },
  },
  // step_2 ============================
  [TASKS_CONNECTION_STEP]: {
    routeName: 'project',
    data: {},
    config: {
      name: 'TASKS_CONNECTION_STEP',
      pointer: { offsetX: 0, offsetY: 10 },
      popup: { pos: 'bottom', y: 12, x: 24 }
    },
    idStep: TASKS_CONNECTION_STEP,
    idNextStep: ASSIGN_TASK_STEP,
    handlers: {
      onLinkAdd: (id, item) => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: ASSIGN_TASK_STEP,
        });
      },
      onFinishOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: FINISH_STEP,
        });
      },
      onNextStepOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: PROJ_EXPORT_STEP,
        });
      },
    },
    callbacks: {
      getNode: () => {
        const elems = document.getElementsByClassName('gantt_task_line');
        return elems.length > 0 && elems[elems.length - 1];
      }
    },
    init: function () {
      const __this = this;
      const { data, handlers, config, callbacks } = __this;

      return new Promise((resolve, reject) => {
        if (data.initialized) {
          return resolve();
        }

        data.idEvtFinish = app.on(ID_EVENT_APP_ONBOARD_FINISH, handlers.onFinishOnboarding);
        data.idEvtNext = app.on(ID_EVENT_APP_ONBOARD_NEXT, handlers.onNextStepOnboarding);

        data.linkEventId = gantt.attachEvent('onAfterLinkAdd', handlers.onLinkAdd);

        data.idTimer = setInterval(() => _displayTourStep('connectionStep', config, __this, {
          cbGetNode: callbacks.getNode
        }), POLL_INTERVAL);

        data.initialized = true;

        return resolve();
      });
    },
    cleanup: function () {
      const { data } = this;

      return new Promise(function (resolve, reject) {
        if (data.idTimer) {
          clearInterval(data.idTimer);
          data.idTimer = null;
        }

        if (!!data.pointerX) { data.pointerX = null; }
        if (!!data.pointerY) { data.pointerY = null; }

        if (data.node) { data.node = null; }

        // if (data.ganttTaskElem) {
        //     data.ganttTaskElem = null;
        // }

        if (data.linkEventId) {
          gantt.detachEvent(data.linkEventId);
        }

        if (data.idEvtFinish) {
          app.off(data.idEvtFinish);
          data.idEvtFinish = null;
        }

        if (data.idEvtNext) {
          app.off(data.idEvtNext);
          data.idEvtNext = null;
        }

        popupManager.hide('connectionStep');
        featurePointer.hide();

        data.initialized = false;
        return resolve();
      });
    },
  },
  // step_3 ============================
  [ASSIGN_TASK_STEP]: {
    data: {},
    config: {
      name: 'ASSIGN_TASK_STEP',
      pointer: { offsetX: 12, offsetY: 16 },
      popup: { pos: 'right', y: 12 }
    },
    routeName: 'project',
    idStep: ASSIGN_TASK_STEP,
    idNextStep: CUSTOM_COLUMS_STEP,
    handlers: {
      onFinishOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: FINISH_STEP,
        });
      },
      onNextStepOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: CUSTOM_COLUMS_STEP,
        });
      },
      // !FIXME
      onUpdateTasksModel: ({ganttId, taskId}) => {
        const projectTasksData = globalStore.getters['tasksModel/getItem'](ganttId);

        if (!taskId && Object.keys(projectTasksData?.resourcesToTasks).length > 0) {
          globalStore.dispatch('user/updateActivityStatus', {
            activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
            status: CUSTOM_COLUMS_STEP,
          });
        }
      }
    },
    callbacks: {
      getNode: () => {
        const elems = document.getElementsByClassName('gantt_cell assign');
        return elems.length > 0 && elems[elems.length - 1];
      }
    },
    init: function () {
      const __this = this;
      const { data, handlers, config, callbacks } = __this;

      return new Promise((resolve, reject) => {
        if (data.initialized) {
          return resolve();
        }

        data.idEvtFinish = app.on(ID_EVENT_APP_ONBOARD_FINISH, handlers.onFinishOnboarding);
        data.idEvtNext = app.on(ID_EVENT_APP_ONBOARD_NEXT, handlers.onNextStepOnboarding);
        data.idEvtTaskStoreUpd = app.on('tasksModel:onStoreUpdate', handlers.onUpdateTasksModel);

        data.idTimer = setInterval(() => _displayTourStep('assignStep', config, __this, {
          cbGetNode: callbacks.getNode
        }), POLL_INTERVAL);

        data.initialized = true;
        return resolve();
      });
    },
    cleanup: function () {
      const { data } = this;

      return new Promise(function (resolve, reject) {
        if (data.idTimer) {
          clearInterval(data.idTimer);
          data.idTimer = null;
        }

        if (!!data.pointerX) { data.pointerX = null; }
        if (!!data.pointerY) { data.pointerY = null; }

        if (data.node) { data.node = null; }

        // if (data.assignTaskElem) {
        //     data.assignTaskElem  = null;
        // }

        if (data.idEvtTaskStoreUpd) {
          app.off(data.idEvtTaskStoreUpd);
          data.idEvtTaskStoreUpd = null;
        }

        if (data.idEvtFinish) {
          app.off(data.idEvtFinish);
          data.idEvtFinish = null;
        }

        if (data.idEvtNext) {
          app.off(data.idEvtNext);
          data.idEvtNext = null;
        }

        popupManager.hide('assignStep');
        featurePointer.hide();

        data.initialized = false;
        return resolve();
      });
    },
  },
  // step_4 ============================
  [CUSTOM_COLUMS_STEP]: {
    routeName: 'project',
    data: {},
    config: {
      name: 'CUSTOM_COLUMS_STEP',
      pointer: { offsetX: 25, offsetY: 23 },
      popup: { pos: 'bottom' }
    },
    idStep: CUSTOM_COLUMS_STEP,
    idNextStep: FINISH_STEP, // !FIXME
    handlers: {
      onFinishOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: FINISH_STEP,
        });
      },
      onNextStepOnboarding: () => {
        globalStore.dispatch('user/updateActivityStatus', {
          activityName: constants.USER_ACTIVITIES.HELP_ONBOARDING,
          status: FINISH_STEP,
        });
      },
    },
    callbacks: {
      getNode: () => {
        const elems = document.getElementsByClassName('gantt_header_custom_grid_icon');
        return elems.length > 0 && elems[elems.length - 1];
      }
    },
    init: function () {
      const __this = this;
      const { data, handlers, config, callbacks } = __this;

      return new Promise((resolve, reject) => {
        if (data.initialized) {
          return resolve();
        }

        data.idEvtFinish = app.on(ID_EVENT_APP_ONBOARD_FINISH, handlers.onFinishOnboarding);
        data.idEvtNext = app.on(ID_EVENT_APP_ONBOARD_NEXT, handlers.onNextStepOnboarding);

        data.idTimer = setInterval(() => _displayTourStep('columnStep', config, __this, {
          cbGetNode: callbacks.getNode
        }), POLL_INTERVAL);

        data.initialized = true;

        return resolve();
      });
    },
    cleanup: function () {
      const { data } = this;

      return new Promise(function (resolve, reject) {
        if (data.idTimer) {
          clearInterval(data.idTimer);
          data.idTimer = null;
        }

        if (!!data.pointerX) { data.pointerX = null; }
        if (!!data.pointerY) { data.pointerY = null; }

        if (data.node) { data.node = null; }

        if (data.idEvtFinish) {
          app.off(data.idEvtFinish);
          data.idEvtFinish = null;
        }

        if (data.idEvtNext) {
          app.off(data.idEvtNext);
          data.idEvtNext = null;
        }

        // if (data.customColElem) {
        //     data.customColElem  = null;
        // }

        popupManager.hide('columnStep');
        featurePointer.hide();

        data.initialized = false;

        return resolve();
      });
    },
  },
};

const _initOnboarding = () => {
  // console.info('[onboarding] -> _initOnboarding'); // !DEBUG
  if (!globalStore.state.appMode.isLink) {
    startOnboardingProccess();
  }
};

/**
 * Starts onboarding process if necessary agter "learning center" initialization
 * if current onboarding step equals 0 - perform nothing
 *
 * @param {Array} features - learning center features state
 */

const startOnboardingProccess = () => {
  _step = globalStore.getters['user/getActivityStatus'](constants.USER_ACTIVITIES.HELP_ONBOARDING);

  if (_step !== 0 /* && !userRoleHelper.isMember() */) {
    if (!rights.account.hasRight('project_create')) {
      _onBoardStep(0, true);
    } else {
      _onBoardStep(_step, true);
    }
  }
};

/**
 * Runs if learning-center feature was changed ()
 *
 * @param {Obhect} data - an object that describes changed feature data
 */

const onUserActivityStatusChangedHandler = ({ name, activity_status }) => {
  if (name === constants.USER_ACTIVITIES.HELP_ONBOARDING) {
    _onBoardStep(activity_status);
  }
};

// !TODO: description
// {Number, Boolean} -> {}
function _onBoardStep(stepVal, isInitial = false) {
  const prevStepEntry = stepCfg[_step];
  const curStepEntry = stepCfg[stepVal];

  const _isAnyPopupOpened = isAnyPopupOpened();

  prevStepEntry
    .cleanup()
    .then(() => {
      const _currentRouteEntry = routerHelper.getCurrentRoute();
      _step = stepVal;

      if( curStepEntry.config.name !== 'FINISH_STEP'){
        userExtAnalytics.log('onboarding_tour_perform_step', {
          step: analiticsReplaceOnboarding(curStepEntry.config.name)
        });
      }


      if (
        prevStepEntry.routeName !== curStepEntry.routeName &&
        _currentRouteEntry.name !== curStepEntry.routeName
      ) {
        routerHelper.pushRoute({
          name: curStepEntry.routeName
        });

        return;
      }

      if (
        _isAnyPopupOpened === false &&
        _currentRouteEntry.name === curStepEntry.routeName &&
        Object.keys(_currentRouteEntry.query).length === 0 &&
        (_currentRouteEntry.params.mode === 'gantt' || _currentRouteEntry.name === 'newProject')
      ) {

        if (!curStepEntry.data.initialized) {
          curStepEntry.init();
        }
      } else {
        if (curStepEntry.data.initialized) {
          curStepEntry.cleanup();
        }
      }
    });
}

// !TODO: description
const onRouteCHanged = () => {
  // console.info('[onboarding::onRouteCHanged] ->'); // !FIXME: debug

  const _currentStepEntry = stepCfg[_step];
  const _currentRouteEntry = routerHelper.getCurrentRoute();

  if (
    !rights.account.hasRight('project_create') ||
    _currentStepEntry.routeName !== _currentRouteEntry.name ||
    (_currentRouteEntry.name !== 'newProject' && _currentRouteEntry.params.mode !== 'gantt')
    || isAnyPopupOpened() || Object.keys(_currentRouteEntry.query).length !== 0
  ) {
    if (_currentStepEntry.data.initialized) {
      _currentStepEntry.cleanup();
    }
  } else {
    if (!_currentStepEntry.data.initialized) {
      _currentStepEntry.init();
    }
  }

  if(
    _currentStepEntry.config.name !== 'FINISH_STEP'
    &&  _currentStepEntry.routeName !== _currentRouteEntry.name
  ){
      // userExtAnalytics.log('onboarding_tour_change_route', {
      //   step: analiticsReplaceOnboarding(_currentStepEntry.config.name),
      //   routeName: _currentRouteEntry.name
      // });
  }
};

// !TODO: description
const onPopupStateChanged = () => {
  const _currentStepEntry = stepCfg[_step];

  const _isAnyPopupOpened = isAnyPopupOpened();
  const _currentRouteEntry = routerHelper.getCurrentRoute();

  //
  if (
    !rights.account.hasRight('project_create') ||
    _isAnyPopupOpened ||
    _currentRouteEntry.name !== _currentStepEntry.routeName ||
    (_currentRouteEntry.name !== 'newProject' &&
      _currentRouteEntry.params.mode !== 'gantt')
  ) {
    if (_currentStepEntry.data.initialized) {
      _currentStepEntry.cleanup();
    }
  } else {
    if (!_currentStepEntry.data.initialized) {
      _currentStepEntry.init();
    }
  }
};

app.on('user:activity:status:updated', onUserActivityStatusChangedHandler);


app.on(ID_EVENT_APP_INIT_MODELS, _initOnboarding);
app.on(ID_EVENT_APP_ROUTECHANGED, onRouteCHanged);
app.on(ID_EVENT_POPUPS_CHANGED, onPopupStateChanged);

//////////////////////////////// !FIXME
(function () {
  var attachEvent = document.attachEvent;
  var isIE = navigator.userAgent.match(/Trident/);
  var requestFrame = (function () {
    var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame ||
      function (fn) { return window.setTimeout(fn, 20); };
    return function (fn) { return raf(fn); };
  })();

  var cancelFrame = (function () {
    var cancel = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame ||
      window.clearTimeout;
    return function (id) { return cancel(id); };
  })();

  function resizeListener(e) {
    console.warn('resizeListener::', this, e); // !DEBUG
    var win = e.target || e.srcElement;
    if (win.__resizeRAF__) cancelFrame(win.__resizeRAF__);
    win.__resizeRAF__ = requestFrame(function () {
      var trigger = win.__resizeTrigger__;
      trigger.__resizeListeners__.forEach(function (fn) {
        fn.call(trigger, e);
      });
    });
  }

  function objectLoad(e) {
    // console.warn('objectLoad::', this, e); // !DEBUG
    this.contentDocument.defaultView.__resizeTrigger__ = this.__resizeElement__;
    this.contentDocument.defaultView.addEventListener('resize', resizeListener);
  }

  window.addResizeListener = function (element, fn) {
    console.info('addResizeListener', element); // !!!DEBUG
    if (!element.__resizeListeners__) {
      element.__resizeListeners__ = [];
      if (attachEvent) {
        element.__resizeTrigger__ = element;
        element.attachEvent('onresize', resizeListener);
      }
      else {
        if (getComputedStyle(element).position == 'static') element.style.position = 'relative';
        var obj = element.__resizeTrigger__ = document.createElement('object');
        obj.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
        obj.__resizeElement__ = element;
        obj.onload = objectLoad;
        obj.type = 'text/html';

        if (isIE) element.appendChild(obj);
        obj.data = 'about:blank';
        if (!isIE) element.appendChild(obj);
      }
    }
    element.__resizeListeners__.push(fn);
  };

  window.removeResizeListener = function (element, fn) {
    element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
    if (!element.__resizeListeners__.length) {
      if (attachEvent) element.detachEvent('onresize', resizeListener);
      else {
        if (
          element &&
          element.__resizeTrigger__ &&
          element.__resizeTrigger__.contentDocument &&
          element.__resizeTrigger__.contentDocument.defaultView
        ) {
          element.__resizeTrigger__.contentDocument.defaultView.removeEventListener('resize', resizeListener);
        }
        element.__resizeTrigger__ = !element.removeChild(element.__resizeTrigger__);
      }
    }
  }
})();
////////////////////////////////


function analiticsReplaceOnboarding(id) {
  switch(id) {
    case 'ADD_TASK_STEP': return '1) create a task';
    case 'TASKS_CONNECTION_STEP': return '2) create dependency';
    case 'ASSIGN_TASK_STEP': return '3) assign a task';
    case 'CUSTOM_COLUMS_STEP': return '4) add a column';
    default: return id;
  }
}
