var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.field_wrap, _vm.$style.single_select] },
    [
      _c("div", { class: [_vm.$style.input_label] }, [
        _c("span", { class: _vm.$style.txt_wrap }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasChacked,
                expression: "hasChacked",
              },
            ],
            class: _vm.$style.reset,
            attrs: {
              id: _vm.gpAutotestName + "_reset_btn",
              "gp-autotest-name": _vm.gpAutotestName,
            },
            on: {
              click: function ($event) {
                return _vm.resetChacked()
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.reset_locale) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "inputField", class: [_vm.$style.input_field_wrap] }, [
        _c(
          "div",
          {
            class: [
              _vm.$style.input_field,
              _vm.hasChacked || _vm.isShowDropList ? _vm.$style.active : "",
            ],
            on: { click: _vm.showDropList },
          },
          [
            _vm.chacked
              ? _c(
                  "div",
                  { class: _vm.$style.input_field_selected },
                  [
                    _vm.dropTemplate === "text"
                      ? [
                          _c("div", [
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.findItemById(_vm.chacked)[_vm.valueField]
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _c("div", { class: _vm.$style.input_placeholder }, [
                  _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      "),
                ]),
            _vm._v(" "),
            _c("webix-ui", { attrs: { config: _vm.icon_arrow } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "dropList",
            class: [
              _vm.$style.drop_list,
              _vm.$style.drop_scroll,
              "gantt_scroll",
              _vm.isShowDropList ? _vm.$style.show_list : "",
              _vm.isBottom ? _vm.$style.bottom_drop : _vm.$style.top_drop,
            ],
            style: {
              top: _vm.coordinates.top,
              bottom: _vm.coordinates.bottom,
              left: _vm.coordinates.left,
              right: _vm.coordinates.right,
              width: _vm.dropListWidth,
            },
          },
          _vm._l(_vm.dropData, function (item) {
            return _c(
              "div",
              {
                key: _vm.dropTemplate + item.id,
                class: [_vm.$style.list_item],
                on: {
                  click: function ($event) {
                    return _vm.selectItem(item)
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chacked,
                      expression: "chacked",
                    },
                  ],
                  attrs: {
                    id: item[_vm.trackBy] + _vm.componentKey,
                    type: "radio",
                  },
                  domProps: {
                    value: item[_vm.trackBy],
                    checked: _vm._q(_vm.chacked, item[_vm.trackBy]),
                  },
                  on: {
                    change: function ($event) {
                      _vm.chacked = item[_vm.trackBy]
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    class: [
                      item[_vm.trackBy] == _vm.chacked
                        ? _vm.$style.highlite_item
                        : "",
                    ],
                    attrs: { for: item[_vm.trackBy] + _vm.componentKey },
                  },
                  [
                    _vm.dropTemplate === "text"
                      ? [_c("span", [_vm._v(_vm._s(item[_vm.valueField]))])]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }