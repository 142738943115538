<template>
  <div
    v-click-outside="{
      handler: hideEditor,
      include: includeClickOutside,
      closeConditional: () => editorShown && !isCommentEditing
    }"
    :class="[$style['task-settings-comment-editor'], $style[commentHub ? 'fixed-footer' : 'footer-section']]"
    @click="clickOnEditor"
  >
    <div
      v-if="showScrollToBottomButton && commentHub"
      :class="$style['counter-section']"
      @click="$emit('scrollToBottom')"
    >
      <div :class="$style['counter-item']">
        <div
          v-if="unreadCommentIds.length && !mention"
          :class="$style['counter-number']"
        >
          {{ unreadCommentIds.length }}
        </div>
        <div
          v-if="mention && unreadCommentIds.length"
          :class="$style['counter-number']"
        >
          @
        </div>
        <div :class="$style['counter-item-icon']">
          <svg-sprite
            :name="'arrow-down'"
            :type="'bold'"
            :size="24"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!editorShown"
      :class="[
        'vgp-interactive-element',
        $style['placeholder-container'],
        isFieldDisabled('comments') ? $style['placeholder-container_disabled'] : ''
      ]"
    >
      <template v-if="!isFieldDisabled('comments')">
        <img
          :src="userPhoto"
          alt="Author photo"
          :class="$style['author-avatar']"
        >
        <span @click="activateEditor">
          {{ locales('settings_new_comment_placeholder') }}
        </span>
      </template>
      <template v-else>
        <vgp-svg-image
          name="not-allowed"
          :style="{ alignItems: 'unset' }"
        />
        <span>
          {{ locales('settings_new_comment_placeholder_disabled') }}
        </span>
      </template>
    </div>
    <vgp-quill-editor
      v-else
      ref="commentsEditor"
      :class="[$style['quill-container'], 'footer-quill-editor']"
      :value="currentValue"
      :active="quillEditorActive"
      :submit-button-label="currentEditComment ? locales('common_save') : locales('common_send')"
      :mention-items-callback="getMentionItems"
      :upload-image-callback="addImageToTask"
      :font-size="16"
      :max-height="212"
      :focus-on-init="focusOnInit"
      :toolbar-list-options="['ordered', 'bullet']"
      :draft-blur="draftBlur"
      @cancel="handleCancelButtonClick"
      @submit="handleSaveButtonClick"
      @focus="handleFocus"
      @blur="handleBlur"
      @imageUpload="isCommentEditing = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VgpQuillEditor from '../vgpQuillEditor.vue';
import VgpSvgImage from '$$vueCmp/VueIcon/vgpSvgImage.vue';
import teamModel from '../../../models/team';
import rights from '../../../components/rights';
import globalStore from '$$store/main';
import { internalApi } from '$$store/api';

export default {
  name: 'TaskSettingsFooter',
  components: { VgpSvgImage, VgpQuillEditor },
  inject: ['isFieldDisabled'],
  props: {
    currentEditComment: {
      type: Object,
      default: () => ({
        id: null,
        content: '',
      }),
    },
    taskData: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unreadCommentIds: {
      type: Array,
      default: [],
    },
    commentHub: {
      type: Boolean,
      required: false,
      default: false,
    },
    showScrollToBottomButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    mention: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      isCreatingNewComment: false,
      quillInstance: null,
      isSubmitting: false,
      quillEditorActive: false,
      isCommentEditing: false,
      draftBlur: false,
      focusOnInit: false,
    };
  },
  computed: {
    ...mapGetters({
      allRealResources: 'resourcesModel/getAllRealResources',
    }),
    userPhoto() {
      const currentUserResource = this.allRealResources.find(resource => resource.userId === user.id);

      return currentUserResource?.photo || '';
    },
    editorShown() {
      if (this.isFieldDisabled('comments')) return;

      return this.currentEditComment || this.isCreatingNewComment || this.draftContentFromStore;
    },
    draftContentFromStore() {
      return this.$store.state.comments.draftsMap[this.taskData.gantt_id]?.[this.taskData.id];
    },
    currentValue() {
      if (this.draftContentFromStore && !this.currentEditComment) return this.draftContentFromStore;
      if (this.currentEditComment) {
        return this.currentEditComment.content;
      }

      return '';
    },

  },
  watch: {
    disabled() {
      if (this.disabled) {
        this.$emit('openEditor', false);
        this.isCreatingNewComment = false;
        if (this.draftContentFromStore) {
          this.deleteDraftFromStore();
        }
      }
    },
    taskId() {
      this.quillEditorActive = false;
      if (this.draftContentFromStore) {
        this.draftBlur = true;
        this.$emit('openEditor', true);
      } else {
        this.draftBlur = false;
        this.isCreatingNewComment = false;
        this.$emit('openEditor', false);
      }
    },
    currentEditComment(value) {
      if (value) {
        this.deleteDraftFromStore();
        this.focusOnInit = true;
        this.quillEditorActive = true;
      }
    },
    isCommentEditing(value) {
      if (!value) {
        const content = this.$refs.commentsEditor.getHtml();

        this.saveDraftToStore(content);
      }
    },
  },
  created() {
    if (this.draftContentFromStore && !this.isFieldDisabled('comments')) {
      this.draftBlur = true;
      this.$emit('openEditor', true);
    }
  },
  beforeDestroy() {
    const task = this.$store.getters['tasksModel/tasksByIds'][this.taskId];
    const content = this.$refs.commentsEditor?.getHtml();
    const hasText = this.$refs.commentsEditor?.quillInstance.root.innerText.trim();

    if (content && hasText && task) {
      this.saveDraftToStore(content);
    }
  },
  methods: {
    handleBlur() {
      // this.quillEditorActive = false;
      if (this.currentEditComment) {
        this.$nextTick(() => {
          this.quillEditorActive = true;
        });
      }
    },
    handleFocus() {
      this.draftBlur = false;
    },

    includeClickOutside() {
      // do not close editor if clicked on edit comment icon
      const ignoreElements = [];

      const changeViewIconButton = document.querySelector('.change-view-btn');

      if (changeViewIconButton) {
        ignoreElements.push(changeViewIconButton);
      }

      const editCommentIconButtons = document.querySelectorAll('.edit-comment-icon-btn');

      if (editCommentIconButtons.length) {
        ignoreElements.push(...Array.from(editCommentIconButtons));
      }

      const editor = document.querySelector('.footer-quill-editor');

      if (editor) {
        ignoreElements.push(editor);
      }
      const content = this.$refs.commentsEditor?.getHtml();
      const hasText = this.$refs.commentsEditor?.quillInstance.root.innerText.trim();

      if ((content && hasText) && !editor) {
        this.saveDraftToStore(content);
      } else if (!editor) {
        this.$emit('openEditor', false);
        this.isCreatingNewComment = false;
        this.$emit('cancel');
      }

      return ignoreElements;
    },
    async getMentionItems(searchTerm, renderList) {
      let teamMembers = await teamModel.getTeamList();
      const projectData = this.$store.getters['tasksModel/getItem'](+this.taskData.gantt_id);
      const resourcesOnTask = projectData.resourcesToTasks[+this.taskData.id] || [];

      teamMembers = _.filter(teamMembers, member => {
        const memberHasRight = rights.project.userHasRight(member.id, +this.taskData.gantt_id, 'all_tasks');
        const resourceId = globalStore.getters['resourcesModel/getResourceByUserId'](member.id)?.id;

        if (memberHasRight || resourcesOnTask.find(i => i.resource_id === resourceId)) {
          return true;
        }
      });

      const mentionItems = _.map(teamMembers, member => {
        const value = member.firstName && member.lastName
          ? `${member.firstName} ${member.lastName}`
          : member.username;

        return {
          username: member.username,
          email: member.email,
          id: member.id,
          value,
        };
      });

      if (searchTerm.length === 0) {
        renderList(mentionItems, searchTerm);

        return;
      }

      const matches = mentionItems.filter(value => (
        value.username.toLowerCase().includes(searchTerm.toLowerCase())
          || value.email.toLowerCase().includes(searchTerm.toLowerCase())
      ));

      renderList(matches, searchTerm);
    },
    activateEditor() {
      !this.isFieldDisabled('comments') && (this.isCreatingNewComment = true);
      this.quillEditorActive = true;
      this.focusOnInit = true;
      this.draftBlur = false;
      this.$emit('openEditor', true);
    },
    hideEditor() {
      const hasText = this.$refs.commentsEditor?.quillInstance.root.innerText.trim();
      const content = this.$refs.commentsEditor?.getHtml();

      if (this.currentEditComment) {
        this.$emit('cancel');
        this.isCreatingNewComment = false;

        return;
      }

      if (hasText && content) {
        this.saveDraftToStore(content);
      } else if (content === null && this.draftContentFromStore) {
        this.deleteDraftFromStore();
        this.$emit('cancel');
        this.isCreatingNewComment = false;
      } else {
        this.$emit('openEditor', false);
        this.isCreatingNewComment = false;
        this.$emit('cancel');
      }
    },
    handleCancelButtonClick() {
      if (this.currentEditComment) {
        this.$emit('cancel');
      }
      if (this.draftContentFromStore) {
        this.deleteDraftFromStore();
        this.$emit('cancel');
      }
      this.isCreatingNewComment = false;

      this.$emit('openEditor', false);
    },
    async handleSaveButtonClick(value) {
      if (this.isSubmitting) return;

      if (value) {
        const currentActionType = this.currentEditComment ? 'edit' : 'create';
        const actionTypeMap = {
          edit: () => this.editComment(value),
          create: () => this.createComment(value),
        };

        this.draftContentFromStore && this.deleteDraftFromStore();

        this.isSubmitting = true;
        await actionTypeMap[currentActionType]();
        this.$emit('submit', currentActionType);
        this.isSubmitting = false;
        this.isCreatingNewComment = false;
        this.quillInstance = null;
      }
    },
    async createComment(content) {
      await this.$store.dispatch('comments/createComment', {
        projectId: this.taskData.gantt_id,
        taskId: this.taskData.id,
        taskName: this.taskData.text,
        content,
      });
      userExtAnalytics.log('task_comment_added', { from: 'task' });

      userExtAnalytics.log('task_settings_action', { action: 'comment', type: 'add' });
    },
    editComment(content) {
      gantt.callEvent('taskEditor:task:changed', [{ id: this.taskData.id }]);

      return this.$store.dispatch('comments/editComment', {
        projectId: this.taskData.gantt_id,
        commentId: this.currentEditComment.id,
        content,
      });
    },
    addImageToTask(file) {
      this.isCommentEditing = true;
      const formData = new FormData();

      formData.append('file', file);

      return internalApi
        .post(
          '/taskImages',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        .then(({ data }) => data.imageUrl);
    },
    saveDraftToStore(content) {
      this.draftBlur = true;

      if (!content) content = this.$refs.commentsEditor?.getHtml();
      if (content !== this.draftContentFromStore && !this.isCommentEditing) {
        this.$store.commit('comments/addOrUpdateDraft', {
          projectId: +this.taskData.gantt_id,
          taskId: +this.taskData.id,
          draft: content,
        });
      }
    },
    deleteDraftFromStore() {
      this.$store.commit('comments/deleteDraft', {
        projectId: +this.taskData.gantt_id,
        taskId: this.taskData.id,
      });
    },
    clickOnEditor() {
      const editor = document.querySelector('.footer-quill-editor');

      if (editor && this.draftBlur) {
        if (this.draftBlur) this.draftBlur = false;
      }
    },
  },
};
</script>

<style module lang="less">

.task-settings-comment-editor {
  box-shadow: 0 2px 32px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  word-break: break-word;
  padding: 0 32px;

  .placeholder-container {
    margin: 30px 0 16px;
    height: 64px;
    width: 100%;
    display: flex;
    gap: 11px;
    span {
      cursor: pointer;
      color: #9E9E9E;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
    }

    .author-avatar {
      width: 32px;
      height: 32px;
      border-radius: 33px;
    }

    &_disabled {
      pointer-events: none;
    }
  }

  .quill-container {
    margin: 12px 0;
  }
}
.counter {
    &-section {
        display: block;
        position: absolute;
        right: 35px;
        top: -50px;
        cursor: pointer;
    }

    &-item {
        background: #FFFFFf;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        right: 0;
        box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
            height: 100%;
        }

        &:hover {
            background-color: #D0E0F2;
        }
        &:active {
            background-color: #A1C1E6;
        }
    }
    &-number {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        padding: 0px 4px;
        background: #1565C0;
        border-radius: 4px;
        color: #ffffff;
        font-family: Lato-Bold, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        top: -5px;
        position: absolute;
        z-index: 99;
        right: 0;
    }

}
.footer-section {
    position: relative;
}

.fixed-footer {
    position: fixed !important;
    bottom: 30px;
    background: #fff;
    width: 815px;
}

</style>
