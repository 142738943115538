import _ from '../libs/lodash';
import moment from '../libs/moment';
import constants from './constants';
import globalStore from "$$store/main";

const parse = function (x) {
  return parseInt(x || 0);
};

const has = function (array, isNull) {
  const items = {};

  return function (value) {
    if (items[value]) {
      return true;
    }

    if (_.includes(array, value)) {
      items[value] = true;
      return true;
    }
    return false;
  };
};

const hasMultiAssign = function (array, taskResources) { 
  if (taskResources && !taskResources.length && +array[0] === -1) {
    return true;
  } 

  return !!_.intersectionWith(array, taskResources, (id, resource) => resource.resource_id === +id).length;
};


const checkByDate = function (filterDate, taskDate) {
  let satisfied = true;

  if (filterDate && (filterDate.start || filterDate.end)) {
    const dateForCompare = _.isString(taskDate) ? moment(taskDate, constants.TASK_DATES_FORMAT).toDate() : new Date(taskDate);

    let filterDateEnd = filterDate.end;

    if (filterDateEnd && moment(filterDateEnd).toDate().getHours() === 0) {
      filterDateEnd = moment(filterDateEnd).add({ hours: 23, minutes: 59, seconds: 59 }).toDate();// for including range end date whole day
    }

    const afterStart = filterDate && (dateForCompare >= moment(filterDate.start, constants.TASK_DATES_FORMAT).toDate());
    const beforeStart = filterDate && (dateForCompare <= moment(filterDateEnd, constants.TASK_DATES_FORMAT).toDate());
    const startEndNotSet = !filterDate || !filterDateEnd;
    const startBeginNotSet = !filterDate || !filterDate.start;
    satisfied = ((afterStart && startEndNotSet) || (beforeStart && startBeginNotSet) || (afterStart && beforeStart));
  }

  return satisfied;
};

const checkByDateRange = function (filterDate, taskStart, taskEnd) {
  let satisfied = true;

  if (filterDate && (filterDate.start || filterDate.end)) {
    const taskStartDate = _.isString(taskStart) ? moment(taskStart, constants.TASK_DATES_FORMAT).toDate() : new Date(taskStart);
    const taskEndDate = _.isString(taskEnd) ? moment(taskEnd, constants.TASK_DATES_FORMAT).toDate() : new Date(taskEnd);

    let filterDateEnd = filterDate.end;
    let beforeStart = null;

    if (filterDateEnd && moment(filterDateEnd).toDate().getHours() === 0) {
      filterDateEnd = moment(filterDateEnd).add({ hours: 23, minutes: 59, seconds: 59 }).toDate();// for including range end date whole day
    }
    const afterStart = filterDate && (taskStartDate >= moment(filterDate.start, constants.TASK_DATES_FORMAT).toDate() || (taskEndDate >= moment(filterDate.start, constants.TASK_DATES_FORMAT).toDate()));
    if (filterDate.start && filterDateEnd) {
      beforeStart = filterDate && (taskEndDate <= moment(filterDateEnd, constants.TASK_DATES_FORMAT).toDate() || taskStartDate <= moment(filterDateEnd, constants.TASK_DATES_FORMAT).toDate());
    }
    const startEndNotSet = !filterDate || !filterDateEnd;
    const startBeginNotSet = !filterDate || !filterDate.start;
    satisfied = ((afterStart && startEndNotSet) || (beforeStart && startBeginNotSet) || (afterStart && beforeStart));
  }

  return satisfied;
};

const checkByNumber = function (filterNumber, numberForCompare) {
  let satisfied = true;

  if (filterNumber && (filterNumber.start || filterNumber.end)) {
    const afterStart = filterNumber && (numberForCompare >= +filterNumber.start);
    const beforeStart = filterNumber && (numberForCompare <= +filterNumber.end);
    const startEndNotSet = !filterNumber || !filterNumber.end;
    const startBeginNotSet = !filterNumber || !filterNumber.start;

    satisfied = ((afterStart && startEndNotSet) || (beforeStart && startBeginNotSet) || (afterStart && beforeStart));
  }

  return satisfied;
};
const checkIsOverdue = function (filterState, task) {
  if (!(moment(task.end_date).isBefore() && task.progress < 1 && task.type === 'task') && filterState) {
    return false;
  }
  return true;
};

const checkByCustomColumns = function (filters, task) {
  if (!filters) return true;

  const allFiltersIsEmpty = Object.values(filters).every(filter => isEmptyFilterCondition(filter))

  if (allFiltersIsEmpty) return true;

  const projectCustomColumns = [];
  const projectCustomColumnIds = globalStore.getters['columns/getColumnIdsByProjectId'](task.gantt_id);

  projectCustomColumnIds.forEach(columnId => {
    const column = globalStore.getters['columns/getCustomColumnById'](columnId);

    column && projectCustomColumns.push(column);
  });

  let res = Object.entries(filters).every(([key, filter]) => {
    if (isEmptyFilterCondition(filter))
      return true;
      
    let columnId = +key;
    let column = projectCustomColumns.find(col => col.id === columnId);

    if (!column)
      return false;

    let check = true;
    const taskCustomValue = gantt.getUserCustomValue(task, columnId).value;

    const optionsTypeColumns = [
      constants.CUSTOM_COLUMNS_TYPES.select.id,
      constants.CUSTOM_COLUMNS_TYPES.color.id,
      constants.CUSTOM_COLUMNS_TYPES.multiselect.id,
      constants.CUSTOM_COLUMNS_TYPES.resources.id,
      constants.CUSTOM_COLUMNS_TYPES.tags.id,
    ];

    if (column.type === constants.CUSTOM_COLUMNS_TYPES.date.id) {
      check = checkByDate(filter, taskCustomValue);
    }

    if (column.type === constants.CUSTOM_COLUMNS_TYPES.number.id) {
      check = checkByNumber(filter, +taskCustomValue);
    }

    if (optionsTypeColumns.includes(column.type)) {
      check = false;
      if (taskCustomValue) {
        let filterOptions = _.isString(filter) ? [parse(filter)] : filter.map(parse);
        const selectedValues = taskCustomValue.split(',').map(id => parseInt(id, 10));

        check = !!filterOptions.find(option => { 
          return selectedValues.includes(option)
        });
      }
    }

    if (column.type === constants.CUSTOM_COLUMNS_TYPES.text.id) {
      check = taskCustomValue.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }

    if (column.type === constants.CUSTOM_COLUMNS_TYPES.checkbox.id) {
      check = (+taskCustomValue + 1) === +filter;
    }

    return check;
  });

  return res;

  function isEmptyFilterCondition(value) {
    if (value && value.start !== undefined) {
      return !value.start && !value.end;
    }

    return (value && !value.length) || !value;
  }
};

const getValues = (element, isNumber) => {
  if (_.isString(element)) {
    return element.split(',').map(parse);
  }

  if (_.isArray(element)) {
    return isNumber ? element.map(parse) : element;
  }
};

function sanitizeResources(resourceIds, taskId, ganttId) {
  const projectResources = globalStore.getters['resourcesModel/getResourcesByProjectId'](ganttId).map(t => t.id);

  return resourceIds.filter(id => projectResources.includes(id) || +id === -1);

  // WTF \|/
  // const resourcesOnTask = globalStore.getters['tasksModel/getItem'](ganttId)?.resourcesToTasks[taskId];
  // const assignedToTask = resourcesOnTask && resourcesOnTask.length && resourcesOnTask.find(t => resourceIds.includes(t.resource_id));

  // if (resourceIds.length)

  // return exist && assignedToTask ? exist : '-1';
}

function getFilter(gantt, filterData, ganttTasks, callback) {
  if (!filterData || !filterData.options) {
    return function () {
      return true;
    };
  }
  
  const searchTaskName = filterData.options.text ? filterData.options.text.toLowerCase() : '';
  const assignees = _.orderBy(getValues(filterData.options.assignee, true)) || [0];
  const reporters = getValues(filterData.options.reporter, true) || [0] ;
  const statuses = getValues(filterData.options.status, true) || [0];
  const priorities = getValues(filterData.options.priority, true) || [0];
  const projects = getValues(filterData.options.ganttId || '', true) || [0];
  const color = getValues(filterData.options.color, true) || [0];
  const taskTypes = getValues(filterData.options.taskType || filterData.options.type) || [0];

  const hasProjects = has(projects);
  const hasReporter = has(reporters);
  const hasStatus = has(statuses);
  const hasPriority = has(priorities);
  const hasTaskType = has(taskTypes);
  const hasColor = has(color);
  const anyProjects = projects.indexOf(0) !== -1;
  const anyAssignee = assignees.indexOf(0) !== -1;
  const anyReporter = reporters.indexOf(0) !== -1;
  const anyStatus = statuses.indexOf(0) !== -1;
  const anyPriority = priorities.indexOf(0) !== -1;
  const anyTaskType = taskTypes.indexOf(0) !== -1;
  const anyColorType = color.indexOf(0) !== -1;
  const filterByCustomColumns = filterData.options.customColumns; 

  return function filterIter(task) {
    if (task.type === gantt.config.types.button) {
      callback && callback(task, ganttTasks.data);

      return true;
    }

    if (task.parent === 0 || _.isNull(task.parent)) {
      callback && callback(task, ganttTasks.data);

      return true;
    }

    const project = anyProjects || hasProjects(task.gantt_id);
    const assignee = anyAssignee || hasMultiAssign(sanitizeResources(assignees, task.id, task.gantt_id), task.resources);

    const name = !searchTaskName.length || (task.text.toLowerCase().indexOf(searchTaskName) !== -1);
    const reporter = anyReporter || hasReporter(+task.owner_id || -1);
    const status = anyStatus || hasStatus(+task.status);
    const priority = anyPriority || hasPriority(+task.priority);
    const taskType = anyTaskType || hasTaskType(task.type);
    const color = anyColorType || hasColor(+task.color);
    const start = checkByDate(filterData.options.startDate, task.start_date);
    const end = checkByDate(filterData.options.endDate, task.end_date);
    const created = checkByDate(filterData.options.creationDate, task.created_at);
    const range = checkByDateRange(filterData.options.rangeDate, task.start_date, task.end_date);
    const customColumnsCheck = filterByCustomColumns ? checkByCustomColumns(filterByCustomColumns, task) : true;
    const overdue = checkIsOverdue(filterData.options.overdue, task);
    const elSatisfyFilter = name && assignee && reporter && status && priority && taskType && start && end && created && range && project && customColumnsCheck && color && overdue;
    if (assignee && task.type === gantt.config.types.project) {
      if (searchTaskName.length === 0 && (!filterByCustomColumns || (filterByCustomColumns && !customColumnsCheck))) {
        return true;
      }
    }

    if (task.type === gantt.config.types.project && searchTaskName.length !== 0 && elSatisfyFilter) {
      const childs = _.filter(ganttTasks?.data, dTk => (dTk.left > task.left && dTk.right < task.right));

      _.each(childs, ch => {
        callback && callback(ch, ganttTasks.data);
      });
    }

    if (elSatisfyFilter) {
      callback && callback(task, ganttTasks.data);

      return true;
    }
  };
}

export default {
  getFilter,
  checkByCustomColumns,
  checkIsOverdue,
};
