import app from "./../app";
import _ from "./../libs/lodash";
import globalStore from "$$store/main";

const __ = window.__;

var team = new webix.DataCollection();

team.members = [];

team.getTeamList = function (id) {
  var teamId = id || user.team.id;
  var promise = webix.promise.defer();

  if (teamId) {
    webix.ajax()
      .get("/api/team/" + teamId + '?' + Date.now())
      .then(function (responseRawData) {
        var teamMembers = responseRawData.json();

        promise.resolve(teamMembers);
      });
  } else {
    promise.resolve([]);
  }

  return promise;
};

team.getCountOfLeftInvitation = function () {
  return (user.team.invitations - team.getCountOfInvitedUsers());
};

team.getCountOfInvitedUsers = function () {
  return globalStore.getters['resourcesModel/getAllRealResources'].length;
};

team.changeName = function (newTeamName = '', getFromEmail = 0) {
  const options = { name: newTeamName ? newTeamName.trim() : ' ', getFromEmail: getFromEmail };

  return webix.ajax()
    .put('/api/team/changeName', options)
    .then(function (response) {
      const data = response.json();
      user.team.name = data.name || options.name ;
      app.trigger('refreshLogo');
    });
};

team.showFavicon = function (newValue) {
  const options = {show_favicon: newValue};

  webix.ajax()
    .put('/api/team/showFavicon', options)
    .then(function () {
      user.team.show_favicon = newValue;
      app.trigger('refreshLogo');
    });
};

team.addUserByEmail = function (email, ganttId) {
  webix.ajax().post("/api/team/teamMember", {
    email: email,
    teamId: user.team.id,
    ganttId: ganttId || null
  }, function (rawResponseText, rawResponseData) {
    var responseData = rawResponseData.json();

    if (responseData.errorStatus) {
      switch (responseData.errorStatus) {
        case 1:
          webix.message({type: "warning", text: __("permission_error_userNotFound")});
          break;
        case 2:
          webix.message({type: "warning", text: __("permission_error_alreadyIsTeam")});
          break;
        default:
          webix.message({type: "error", text: __("permission_error_unknownError")});
          break;
      }
    }
  });
};

team.changeUserRole = function (userId, roleId) {
  webix.ajax().put("/api/team/changeMember/" + user.team.id, {
    user_id: userId,
    role_id: roleId
  }, function (response) {
    var data = JSON.parse(response);

    if (data.status === 'fail') {
      webix.message({type: "error", text: __("permission_error_settingSave")});
      return false;
    }
  });
};

team.uploadLogo = function (file, teamId) {
  if (!file) return false;

  var formData = new FormData(),
    xhr = new XMLHttpRequest();

  formData.append('enctype', "multipart/form-data");
  formData.append('uploadFile[]', file.file);

  xhr.open("POST", "/api/team/logo");
  xhr.onreadystatechange = function () {
    if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
      var response = {};

      try {
        response = JSON.parse(xhr.responseText);
        user.team.logo = response.logo;
      } catch (e) {

      }

      app.trigger("refreshLogo");
    }
  };
  xhr.send(formData);
};

team.deleteLogo = function (teamId) {
  return webix.ajax()
    .del("/api/team/logo", {}, function (res) {
      const data = JSON.parse(res);

      if (!(data.status && (data.status == 'error' || data.status == 'cancel'))) {
        user.team.logo = false;
        app.trigger("teamLogo:deleted", false);
        app.trigger("refreshLogo");
      }
    });
};

team.changeTeamMemberState = function (newTeamMemberData) {
  if (newTeamMemberData.status) {
    user.team.status = parseInt(newTeamMemberData.status, 10);
    team.data.callEvent("teamChange", [user.team.status, "teamMemberChangeStatus"]);

    return true;
  }

  if (newTeamMemberData.role_id) {
    user.team.role_id = parseInt(newTeamMemberData.role_id, 10);
    team.data.callEvent("teamChange", [user.team.role_id, "teamMemberChangeRole"]);

    return true;
  }
};

team.changeTeamName = function (teamName) {
  user.team.name = teamName;
  app.trigger('refreshLogo');
};

team.addToTeam = function (teamData) {
  user.team = teamData.team;
  user.team.status = 2;
  team.data.callEvent("teamChange", [teamData, "addToTeam"]);
  user.team = {};
};

team.deleteFromTeam = function (teamMemberData) {
  team.data.callEvent("teamChange", [teamMemberData, "deleteFromTeam"]);
};

team.acceptInviteToTeam = function (options) {
  return webix.ajax().get(`/api/team/accept_invite_to_team/${options.token}?id=${options.userId}`);
};

team.getInviteLink = function (email) {
  return webix.ajax().get('/api/team/get_invite_link/' + email);
};

export default team;
