import moment from 'moment';
import app from '../../../app';
import _ from '../../../libs/lodash';
import ganttFilterHelper from '../../../helpers/gantt_filter';
import routerHelper from '../../../helpers/router';
import filterModel from '../../../models/filters';
import CustomColumnsFilter from './customColumns';
import dateRangeHelper from '../../../helpers/dateRange';
import multiViewsProjectsModel from '../../../models/multiViewsProjects';
import globalStore from '../../../store/main';

import icon_unassigned from '../../../svg/default/unassigned.svg';
import icon_resource from '../../../svg/default/default-ava.svg';
import projectsModel from '../../../models/projects';

const filterTypes = {
  workload: 'workload',
  project: 'project',
  multiview: 'multiview',
  timeloglist: 'timeloglist',
  listView: 'list',
  costslist: 'costslist',
  userLogs: 'user-logs',
};

class BaseFilter {
  constructor(type) {
    this.resetIfnotFoundTasks = true;
    this.model = filterModel;
    this.exportMode = app.config.mode.isExport;
    this.showSavedFilters = !app.config.mode.isLink;
    this.showSaveBtn = !app.config.mode.isLink && !app.config.mode.isExport;
    this.showHelpIcon = !app.config.mode.isLink && !app.config.mode.isExport;
    this.type = type;
    this.filterState = {};
    this.id = 0;
    this.initId = new Date().getTime();
    this.name = __('filter_current_filter');
    this.custom = new CustomColumnsFilter();
    this.project_id = null;
    this.multiview_id = null;
    this.hasUnsavedChanges = false;
    this.ganttIDs = null;
    this.appliedFirstFiltration = false;
  }

  get locales() {
    return {
      realResource: __('resources_layout_header_title_1'),
      virtualResource: __('resources_layout_header_title_2'),
      unassigned: __('notAssigned'),
    };
  }

  get icons() {
    return {
      unassigned: icon_unassigned,
      resource: GT.cdn ? (`${GT.cdn}/imgs/default/default-ava.svg`) : icon_resource,
    };
  }

  get isActiveFilter() {
    return this.hasFilterOptions(this.filterState);
  }

  get getSavedFilters() {
    const projectId = this.project_id || this.multiview_id;
    const savedFilters = this.model.getSavedFilters(this.type, projectId);

    return savedFilters || [];
  }

  init() {
    this.initGantIds();
    this.custom.init();
    this.loadActiveFilter();

    app.config.mode.isLink && this.initForLinkMode();
  }

  initForLinkMode() {
    app.trigger('filter:status:update', !_.isEmpty(GT.ganttData.filter));

    if (!_.isEmpty(GT.ganttData.filter)) {
      this._applyFilterByPreset(GT.ganttData.filter.filter);
    }
  }

  initGantIds() {
    if (this.exportMode) {
      if (gantt.config.multiview) {
        this.multiview_id = GT.ganttData.project.id;

        return;
      }
      this.project_id = GT.ganttData.project.id;

      return;
    }

    const routeParams = routerHelper.getCurrentRoute().params;

    if (!routeParams) return;

    if (routeParams.multiviewID) {
      this.multiview_id = +routeParams.multiviewID;
      this.ganttIDs = multiViewsProjectsModel.getActiveViewData().ganttIDs;
    }
    if (routeParams.projectId) {
      this.project_id = +routeParams.projectId;
      this.ganttIDs = [+routeParams.projectId];
    }
  }

  _applyFilterByPreset(preset) {
    this.setFilterState(preset, true);
    this.filterData();
  }

  updateCustomColumns() {
    this.custom.update();
    this._checkFilterState();
  }

  isTextField(field) {
    return _.isString(field) && !_.isEmpty(field);
  }

  isMultiSelectField(field) {
    return _.isArray(field);
  }

  isDateRangeField(field) {
    return _.isObject(field) && (field.start || field.period);
  }

  isNumberField(field) {
    return _.isObject(field) && (!_.isUndefined(field.from) || !_.isUndefined(field.to));
  }

  hasFilterOptions(filterOptions, isCustom = false) {
    const isActive = _.some(filterOptions, (option, key) => {
      if (key === 'customColumns') {
        const hasCustom = this.hasFilterOptions(option, true);

        if (!hasCustom) {
          filterOptions.customColumns = null;
          delete filterOptions.customColumns;
        }

        return hasCustom;
      }
      if (Array.isArray(option)) {
        return option.length;
      }
      if (_.isObject(option)) {
        return (option.start || option.end || option.period) || (option.from || option.to);
      }
      if (isCustom && !option) {
        filterOptions[key] = null;
        delete filterOptions[key];
      }

      return option && key !== 'undefined';
    });

    return isActive;
  }

  loadActiveFilter() {
    if (!this.model.getActiveFilter) return;

    const projectId = this.project_id || this.multiview_id || null;
    const activeFilter = this.model.getActiveFilter(this.type, projectId);
    const hasActiveFilter = this.hasFilterOptions(activeFilter);

    if (hasActiveFilter) {
      this._setCurrentFilter(activeFilter.id, activeFilter.name, activeFilter);
      this._checkFilterState();
    }
  }

  filterData(data, ignoreFields) {
    if (globalStore.getters['filter/isDisable']) return;

    const filterApplied = this.hasFilterOptions(this.filterState) || this.filterState.customColumns;
    let foundTask = false;

    const res = data.filter(a => {
      const keys = _.keys(this.filterState);

      if ('groupValue' in a) {
        return false;
      }
      const every = _.every(keys, key => {
        let satisfiesFilter = true;

        if (ignoreFields && ignoreFields.includes(key)) {
          return satisfiesFilter;
        }

        if (key === 'customColumns') {
          satisfiesFilter = ganttFilterHelper.checkByCustomColumns(this.filterState.customColumns, a);
        }

        if (key === 'overdue') {
          satisfiesFilter = ganttFilterHelper.checkIsOverdue(this.filterState.overdue, a);
        }

        if (this.isTextField(this.filterState[key])) {
          satisfiesFilter = a[key] && a[key].toLowerCase().indexOf(this.filterState[key].toLowerCase()) !== -1;
        }

        if (this.isMultiSelectField(this.filterState[key])) {
          satisfiesFilter = _.some(this.filterState[key], item => {
            if (!a[key]) return;
            if (Array.isArray(a[key])) {
              return a[key].find(i => i == item);
            }

            return item == a[key];
          });
        }

        if (this.isDateRangeField(this.filterState[key])) {
          let rangeStart = this.filterState[key].start;
          let rangeEnd = this.filterState[key].end;

          if (this.filterState[key].period) {
            const period = this.filterState[key].period;

            this.filterState[key] = dateRangeHelper.getByPeriodName(period);
            this.filterState[key].period = period;

            rangeStart = this.filterState[key].start;
            rangeEnd = this.filterState[key].end;
          } else if (this.filterState[key].end) {
            rangeEnd = moment(this.filterState[key].end).add({ hours: 23, minutes: 59, seconds: 59 }).toDate();// for including range end date whole day
          }

          if (a[key] === undefined) { // for comparing range vs range
            const start = a[`${key}_start`];
            const end = a[`${key}_end`];

            if (!rangeEnd) {
              satisfiesFilter = (moment(start).isSameOrBefore(rangeStart) && moment(end).isAfter(rangeStart))
                || moment(start).isAfter(rangeStart);
            } else {
              const startCheck = moment(start).isSameOrBefore(rangeStart) && moment(end).isAfter(rangeStart);
              const endCheck = moment(start).isSameOrAfter(rangeStart) && moment(start).isSameOrBefore(rangeEnd);

              satisfiesFilter = (startCheck || endCheck);
            }
          } else {
            const isAfterStart = moment(a[key]).isSameOrAfter(rangeStart);
            const isBeforeEnd = rangeEnd ? moment(a[key]).isSameOrBefore(rangeEnd) : true;

            satisfiesFilter = (isAfterStart && isBeforeEnd);
          }
        }

        if (this.isNumberField(this.filterState[key]) && a[key]) {
          const number = a[key];
          const fromValue = this.filterState[key].from;
          const toValue = this.filterState[key].to;

          const isMoreFrom = fromValue && _.isNumber(+fromValue) ? number >= +fromValue : true;
          const isLessTo = toValue && _.isNumber(+toValue) ? number <= +toValue : true;

          satisfiesFilter = (isMoreFrom && isLessTo);
        }

        return satisfiesFilter;
      });

      foundTask = foundTask ? true : every;

      return every;
    });

    if (data.callEvent) {
      data.callEvent('onAfterFilter', [filterApplied, this.filterState]);
    }
    app.trigger('filter:status:update', filterApplied);
    if (this.type !== 'workload') {
      !foundTask && app.trigger('filter:tasks:not:found');
    }

    return res;
  }

  setFilterState(filterState, isInit) {
    this._setCurrentFilter(0, null, filterState);
    this.hasUnsavedChanges = !isInit;
  }

  resetFilter(defaultState) {
    this._setCurrentFilter(0, null, defaultState || {});
    this.hasUnsavedChanges = true;
  }

  _setCurrentFilter(id, name, filterState) {
    this.setFilterName(name);
    this.id = id || 0;

    for (const property in filterState) {
      const value = filterState[property];

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        const existStartEnd = 'start' in value && 'end' in value;
        const emptyStartEnd = !value.start && !value.end;
        const existPeriod = 'period' in value;
        const emptytPeriod = !value.period;

        if ((existPeriod && emptytPeriod && !existStartEnd)
          || (existStartEnd && emptyStartEnd && !existPeriod)
        ) {
          filterState[property] = '';
        }
      }
    }

    this.filterState = filterState;
    globalStore.commit('filter/setFilterState', filterState);
  }

  setFilterName(name) {
    this.name = name || __('filter_current_filter');
  }

  saveActiveFilter() {
    if (!this.hasUnsavedChanges) return;

    if ('ganttData' in this.filterState) {
      delete this.filterState.ganttData;
    }

    return this.model.updateActiveFilter(this.type, this.filterState, {
      project_id: this.project_id,
      multiview_id: this.multiview_id,
    });
  }

  async save(name) {
    if (app.config.mode.isLink) return;

    const isRepeatName = this.getSavedFilters.find(i => name === i.value.trim());

    if (isRepeatName) {
      webix.message({ type: 'error', text: __('filter_save_error_duplicate_name'), expire: 4000 });

      return false;
    }

    const saveFilterData = {
      name,
      type: this.type,
      filter: this.filterState,
    };

    if (this.project_id) saveFilterData.project_id = this.project_id;
    if (this.multiview_id) saveFilterData.multiview_id = this.multiview_id;

    const saved = await this.model.saveFilter(saveFilterData);

    if (saved && ('id' in saved)) {
      $$('filterSavePopup_v').hide();
      this.setFilterName(name);
      this.id = saved.id;

      return true;
    }
    webix.message({ type: 'error', text: __(err), expire: 4000 });
    $$('filterSavePopupNameField_v').focus();

    return false;
  }

  async removeSavedFilter(item) {
    if (this.model.removeFilter) {
      await this.model.removeFilter(item.id);
    } else if (this.model.removeFilterByName) {
      await this.model.removeFilterByName(item.value);
    }
    if (item.id === this.id) {
      this._setCurrentFilter(0, null, this.filterState);
    }
  }

  editSavedFilter(item) {
    if (this.model.updateFilterName) {
      this.model.updateFilterName(item.id, item.value);
    } else if (this.model.updateFilterNameByName) {
      this.model.updateFilterNameByName(item.old_value, item.value);
    }

    (this.id === item.id) && this.setFilterName(item.value);
  }

  changeFilter(filterId, name) {
    let activeFilter;

    if (this.model.getFilterById) {
      activeFilter = this.model.getFilterById(filterId);
    } else if (name && this.model.getFilterByName) {
      activeFilter = this.model.getFilterByName(name);
    }

    if (activeFilter) {
      let filter = activeFilter.filter || activeFilter.filterOptions;

      if (_.isString(filter)) {
        filter = JSON.parse(filter);
      }
      this._setCurrentFilter(activeFilter.id, activeFilter.name, filter);
      this.hasUnsavedChanges = true;
    }
  }

  _checkFilterState() {
    checkSelectedCustomColumns.bind(this)();
    checkSelectedProjects.bind(this)();

    const isActive = this.hasFilterOptions(this.filterState);

    globalStore.commit('filter/setIsActiveFilter', isActive);

    function checkSelectedCustomColumns() {
      if (!this.filterState.customColumns) return;

      Object.entries(this.filterState.customColumns).forEach(([colId, val]) => {
        const customCol = this.custom.columns.find(col => col.id === +colId);

        if (!customCol) {
          delete this.filterState.customColumns[colId];

          return;
        }

        if (!Array.isArray(val) || !Array.isArray(customCol.data)) return;

        const indexes = [];

        val.forEach((optId, index) => {
          if (!customCol.data.find(opt => opt.id === optId)) indexes.push(index);
        });

        indexes.reverse().forEach(i => val.splice(i, 1));
      });
    }

    function checkSelectedProjects() {
      ['ganttId', 'gantt_id', 'projects'].forEach(key => {
        const selectedProjects = this.filterState[key];

        if (!selectedProjects || !Array.isArray(selectedProjects)) return;

        const allProjectsIds = projectsModel.getAllProjects().map(i => i.id);
        const indexes = [];

        selectedProjects.forEach((id, index) => {
          !allProjectsIds.includes(+id) && indexes.push(index);
        });

        indexes.reverse().forEach(i => selectedProjects.splice(i, 1));
      });
    }
  }
}

export default BaseFilter;
