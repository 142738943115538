var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["custom-field__row"] }, [
    _c(
      "div",
      {
        class: _vm.$style["custom-field__name"],
        attrs: { title: _vm.fieldColumn.name },
      },
      [_vm._v("\n    " + _vm._s(_vm.fieldColumn.name) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.$style["custom-field__component-wrapper"],
        on: {
          mouseenter: _vm.showTooltipIfNeeded,
          mouseleave: _vm.hideTooltip,
        },
      },
      [
        _c(
          _vm.customFieldComponentName,
          _vm._g(
            _vm._b(
              {
                key: _vm.componentKey,
                tag: "component",
                style: _vm.customFieldComponentStyle,
              },
              "component",
              _vm.customFieldComponentProps,
              false
            ),
            _vm.customFieldComponentListeners
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }