<template>
  <core-popup
    id="gp_autotest_resources_confirm_popup"
    :show="isShow"
    :class="popup.remove_popup"
    :title="title"
    :close-icon="true"
    @onClickCancel="$emit('onCancel')"
  >
    <template #body>
      <div :class="popup.message_body">
        <div
          :class="popup.message"
          v-html="message"
        />
      </div>
      <div :class="popup.buttons">
        <vgp-button
          id="gp_autotest_resources_confirm_popup_btn_cancel"
          type="secondary"
          :label="locales('common_cancel')"
          :small="true"
          @onClick="$emit('onCancel')"
        />
        <vgp-button
          id="gp_autotest_resources_confirm_popup_btn_ok"
          :style="{marginLeft: 16 + 'px'}"
          type="primary"
          :label="locales('common_change')"
          :small="true"
          @onClick="$emit('onApply')"
        />
      </div>
    </template>
  </core-popup>
</template>

<script>
import corePopup from '../../../common/VueComponents/popups/corePopup/corePopup.vue';

export default {
  components: { corePopup },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    message: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      locales: __,
    };
  },
};
</script>

<style module='popup' src='./popups.less' />
