<template>
  <div class="setting-row">
    <div
      class="label"
      v-html="label"
    />
    <div class="line">
      <div />
    </div>
    <v-select
      v-if="isSelect"
      :items="selectItems"
      :current-item="selected"
      :target-value="'value'"
      :width="selectWidth"
      :height="selectHeight"
      :styles="selectStyles"
      :select-type="config.id"
      :select-handler="selectHandler"
      :position="position"
    />
    <div
      v-if="mode && mode === 'checkbox'"
      class="checkbox-wrapper"
    >
      <vgp-checkbox
        :style="{marginRight: `${16}px`}"
        :checked="config.email"
        @onClick="()=>checkboxHandler(config.id, 'email', config.email)"
      />
      <vgp-checkbox
        :checked="config.desktop"
        :is-disable="disableCheckbox"
        :style="defaultPermission ? {'opacity': '0.5'} : {}"
        @onClick="()=>checkboxHandler(config.id, 'desktop', config.desktop)"
      />
    </div>
    <toggle
      v-else
      :is-toggle-on="isToggleOn"
      @onChange="onToggle(isToggleOn)"
    />
  </div>
</template>

<script>
import toggle from '../../common/VueComponents/checkbox/squareToggle.vue';
import vSelect from './vSelect.vue';

export default {
  components: {
    toggle,
    vSelect,
  },
  props: [
    'label',
    'mode',
    'config',
    'toggleHandler',
    'selectHandler',
    'checkboxHandler',
    'isToggleOn',
    'selectItems',
    'selectWidth',
    'selectHeight',
    'selectStyles',
    'position',
    'disableCheckbox',
    'notificationPermission',
  ],
  data() {
    return {
      locales: __,
      selected: null,
    };
  },
  computed: {
    isSelect() {
      let select = false;

      if (this.config) {
        select = this.config.select;
      }

      return select;
    },
    defaultPermission() {
      return this.notificationPermission !== 'granted' && this.notificationPermission === 'default';
    },
  },
  watch: {
    config: {
      handler(val) {
        if (val) {
          this.selected = this.selectItems.find(item => item.id === this.config?.delay);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onToggle(val) {
      this.toggleHandler(val);
    },
  },
};
</script>

<style scoped src='../less/settingRow.less'></style>
