<template>
  <div>
    <div
      v-if="loading"
      :class="$style.container"
    >
      <span> {{ locales("integration_msteams_settings_page_table_input_loading") }}</span>
      <loader />
    </div>
  </div>
</template>

<script>
import loader from './Loader.vue';

export default {
  components: { loader },
  props: ['value', 'options', 'placeholder', 'loading'],
  data() {
    return {
      locales: __,
    };
  },
  watch: {
    loading(val, oldVal) {
      if (val !== oldVal && !val) {
        this.webixId.getList().parse(this.options);
        const value = this.webixId.getValue();

        if (value) {
          const valueIndex = this.options.findIndex(({ id }) => value === id);

          if (valueIndex !== -1) this.webixId.getList().moveDown(value, valueIndex);
          else this.webixId.setValue();
        }
        this.webixId.getList().refresh();
        this.webixId.show();
      }
    },
  },
  mounted() {
    const component = this;
    const config = webix.copy({
      view: 'richselectWithoutPoint',
      css: 'richselect',
      suggestCss: 'filterOptionIntegrationSlack',
      placeholder: this.placeholder,
      value: this.value,
      options: {
        view: 'suggest',
        body: {
          view: 'list',
          data: this.options,
          template: '#value#',
        },
      },
      on: {
        onChange() {
          this.$scope.$emit('change', $$(component.webixId).getValue());
        },
        onFocus() {
          this.$scope.$emit('focus', component.webixId);
        },
      },
    });

    config.$scope = this;

    this.webixId = webix.ui(config, this.$el);
    this.loading && this.webixId.hide();
    $$(this.webixId).setValue(this.value);
  },
  destroyed() {
    webix.$$(this.webixId).destructor();
  },
};
</script>
<style module lang="less">
  .container{
    border: 1px solid #BDBDBD;
    padding: 0 8px 0 12px;
    border-radius: 2px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    font-family: Lato-Regular;
    -webkit-appearance: none;
    color: #757575;
    width: 100%;

    .webix_list_item {
      padding: 0 12px;
    }
  }
</style>
