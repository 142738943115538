import app from '../app';

app.socketNotify.on('notify:processingOrder', data => {
  app.trigger('notify:payment:orderProcessing', data);
  app.trigger('paymentPlanInfo:hide');
  app.trigger('btnUpgrade:hide');
});

// app.socketNotify.on('notify:orderCharged', data => {
//   alert('notify:orderCharged')
//   app.trigger('notify:payment:orderFilled', data);
// });

// app.socketNotify.on('notify:orderDeclined', data => {
//   alert('notify:orderDeclined')
//   app.trigger('notify:payment:orderFailed', data);
// });
