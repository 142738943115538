<template>
  <core-popup
    :show="showPopup"
    :width="'440'"
  >
    <template #body>
      <div
        ref="popup"
        :class="[$style.template_popup ]"
      >
        <div
          ref="popup_container"
          :class="[$style.template_popup_container ]"
        >
          <div
            :class="[$style.header]"
          >
            <div :class="[$style.header__title]">
              <span>
                {{ locales('new_project_advanced_title') }}
              </span>
            </div>
            <div>
              <vgp-icon-button
                :class="[$style.header__close_icon]"
                size="small"
                :colors="buttonColors"
                :icon="{ name:'close-1', type:'bold', size: 24 }"
                @onClick="onClickCancel"
              />
            </div>
          </div>
          <div
            :class="[$style.body]"
          >
            {{ locales('new_project_advanced_description') }}
          </div>

          <div :class="$style.separator" />

          <div :class="[$style.settings_list]">
            <div
              v-for="(item, index) in items"
              :key="'add-column-' + index"
              :class="[
                $style.settings,
                item.tooltipKey && !datePickerHovered && !datePickerOpened ? 'tooltip-gantt' : '',
                item.suggest ? $style.settings_suggest : '',
              ]"
              :data-key="item.tooltipKey ? item.tooltipKey : ''"
              :data-position="'left'"
              :data-y="8"
              :data-x="10"
            >
              <div
                :class="[
                  $style.settings,
                  item.disabled ? $style.settings_disabled : '',
                ]"
              >
                <div :class="[$style.setting_checkbox]">
                  <vgp-checkbox
                    size="16"
                    :checked="item.selected"
                    @onClick="handleCheckbox(item)"
                  />
                </div>

                <div
                  :class="[$style.setting_label]"
                  @click="handleCheckbox(item)"
                >
                  {{ locales(item.label) }}
                </div>
              </div>

              <div
                v-if="item.hasCalendar"
                @mouseover="datePickerHovered = true"
                @mouseleave="datePickerHovered = false"
              >
                <vgp-date-picker
                  :is-show-today-btn="true"
                  :class="[
                    $style.date_picker,
                  ]"
                  :value="projectStartDate"
                  :work-days="items.find(item => item.id === 'copyCalendar' && item.selected) ? templateWorkDays : defaultWorkDays"
                  @onChange="handleChangeStartDate"
                  @onShow="datePickerOpened = true"
                  @onClose="datePickerOpened = false"
                >
                  <template #default="{ inputValue, togglePopover }">
                    <div
                      :class="[
                        'vgp_date_picker_input',
                        isFieldDisabled('startDate') ? $style.date_input_disabled : null,
                      ]"
                      @click.stop="() => togglePopover()"
                    >
                      <svg-sprite
                        :class="['vgp_date_picker_input_icon']"
                        :name="'calendar'"
                        :type="'regular'"
                        :size="24"
                      />

                      <span :class="['vgp_date_picker_input_text']">{{ inputValue }}</span>
                    </div>
                  </template>

                  <template #footer="{ setValue }">
                    <button
                      :class="['vgp_date_picker_footer_button', $style.set_today_button]"
                      :style="!templateStartDate ? { opacity: 0.4, textDecoration: 'none' } : {}"
                      :disabled="!templateStartDate"
                      @click.stop="() => !isEqualDates(projectStartDate, templateStartDate) && setValue(templateStartDate)"
                    >
                      {{ locales('new_project_advanced_set_from_template_label') }}
                    </button>

                    <button
                      :class="'vgp_date_picker_footer_button'"
                      @click.stop="() => !isEqualDates(projectStartDate, new Date()) && setValue(new Date())"
                    >
                      {{ locales('datepiker_today') }}
                    </button>
                  </template>
                </vgp-date-picker>
              </div>
            </div>
          </div>

          <div :class="[$style.footer_button]">
            <div :class="[$style.apply]">
              <vgp-button
                type="primary"
                :small="true"
                :label="locales('common_apply')"
                @onClick="onClickOk"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </core-popup>
</template>

<script>

import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import app from '../../app';
import moment from '../../libs/moment';
import vgpDatePicker from '../common/VueComponents/datePicker/vgpDatePicker.vue';

export default {
  name: 'NewProjectFromTemplatePopup',
  components: {
    vgpDatePicker,
    CorePopup,
    vgpIconButton,
  },
  data() {
    return {
      locales: __,
      buttonColors: {
        color: '#808080',
        hoverBg: '#F8F8F8',
      },
      templateName: '',
      templateStartDate: null,
      templateWorkDays: [],
      defaultWorkDays: [],
      projectStartDate: null,
      showPopup: false,
      datePickerHovered: false,
      datePickerOpened: false,
      settings: [],
    };
  },
  computed: {
    items() {
      return [...this.settings];
    },
  },
  created() {
    const component = this;

    app.on('showTemplatePopup', ({ templateStartDate, templateWorkDays, defaultWorkDays }) => {
      component.init(templateStartDate, templateWorkDays, defaultWorkDays);
    });
  },
  methods: {
    init(templateStartDate, templateWorkDays, defaultWorkDays) {
      this.templateStartDate = templateStartDate || new Date();
      this.templateWorkDays = templateWorkDays || [1, 2, 3, 4, 5];
      this.defaultWorkDays = defaultWorkDays;

      this.projectStartDate = templateStartDate;
      this.settings = [
        {
          id: 'copyCalendar',
          label: 'new_project_advanced_copy_calendar_label',
          tooltipKey: 'new_project_advanced_copy_calendar_info',
          selected: true,
        },
        {
          id: 'copyCustomValue',
          label: 'new_project_advanced_copy_custom_label',
          tooltipKey: 'new_project_advanced_copy_custom_info',
          selected: true,
        },
        {
          id: 'copyResources',
          label: 'new_project_advanced_copy_resources_label',
          tooltipKey: 'new_project_advanced_copy_resources_info',
          selected: true,
        },
        {
          id: 'assignResources',
          label: 'new_project_advanced_assign_resources_label',
          tooltipKey: 'new_project_advanced_assign_resources_info',
          selected: true,
          suggest: true,
          disabled: false,
        },
        {
          id: 'startDate',
          label: 'new_project_advanced_start_date_label',
          tooltipKey: 'new_project_advanced_start_date_info',
          selected: true,
          hasCalendar: true,
        },
      ];

      this.showPopup = true;
    },
    handleCheckbox(val) {
      this.items.find(item => {
        if (item.id === val.id) {
          item.selected = !item.selected;

          const assignResources = this.items.find(item => item.id === 'assignResources');

          if (val.id === 'copyResources' && !item.selected) {
            assignResources.selected = false;
            assignResources.disabled = true;
          }

          if (val.id === 'copyResources' && item.selected) {
            assignResources.disabled = false;
          }
        }
      });
    },
    onClickOk() {
      const config = this.items.reduce((acc, item) => {
        if (item.id === 'startDate' && item.selected) {
          acc[item.id] = this.projectStartDate;
        } else {
          acc[item.id] = item.selected;
        }

        return acc;
      }, {});

      app.trigger('templateData', config);
      this.showPopup = false;
    },
    isFieldDisabled(val) {
      return !this.items.find(item => item.id === val)?.selected;
    },

    onClickCancel() {
      this.showPopup = false;
    },
    handleChangeStartDate(date) {
      this.projectStartDate = date;
    },
    isEqualDates(firstDate, secondDate) {
      return moment(firstDate).startOf('day').diff(moment(secondDate).startOf('day'), 'days', true) === 0
    }
  },
};
</script>
<style module src="./templates.less" lang="less"></style>
