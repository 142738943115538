<template>
  <div :class="$style['task-settings-header']">
    <div :class="$style['header__row']">
      <tooltip
        :content="fullTaskBreadcrumbs"
        :position="'bottom-center'"
        :hide="isBreadcrumbsFitInDiv"
        :class="[$style['breadcrumbs-tooltip']]"
      >
        <template #body>
          <div :class="$style['header__breadcrumbs']">
            {{ shortenedBreadcrumbsIfDoesntFit }}
          </div>
        </template>
      </tooltip>
      <div :class="$style['header__actions']">
        <vgp-icon-button
          v-if="contextMenuItems.length"
          ref="contextMenuBtn"
          :icon="{name: 'more-1'}"
          @onClick="showContextMenu"
        />
        <vgp-icon-button
          :icon="{name: 'link-3'}"
          @onClick="copyTaskToClipboard"
        />
        <vgp-icon-button
          v-if="!commentHub"
          :icon="{name: 'close-1'}"
          @onClick="$emit('close')"
        />
      </div>
    </div>
    <div :class="$style['header__row']">
      <color-palette
        :disabled="isFieldDisabled('color')"
        :style="{ zIndex: 3 }"
        select-type="single"
        select-field="square"
        :selected-values="[taskData.color]"
        :colors="colors"
        :select-width="72"
        :select-height="32"
        small
        @selectColors="handleChangeData('color', $event[0].id)"
      />
      <span
        v-if="isFieldDisabled('text')"
        v-tippy="{
          content: taskData.text,
          interactive: true,
          placement: 'bottom-center',
          onShow: () => !isTaskNameFitInDiv
        }"
        :class="[
          $style['task-name-input'],
          isFieldDisabled('text') ? $style['task-name-input_disabled'] : ''
        ]"
      >
        {{ taskData.text }}
      </span>
      <vgp-input
        v-else
        :key="taskNameInputKey"
        v-tippy="{ content: taskData.text, placement: 'bottom-center', onShow: () => !isTaskNameFitInDiv }"
        :value="taskData.text"
        :border="false"
        :maxlength="254"
        :class="$style['task-name-input']"
        @onChange="handleChangeTaskName"
      />
    </div>
    <div :class="$style['header__row']">
      <div :class="$style['task-creation-info']">
        <span>{{ `${locales('task_settings_creator')}:` }}</span>
        <span :class="$style['task-creation-info_author']">{{ `${taskAuthorName},` }}</span>
        <span>{{ taskCreationDate }}</span>
      </div>
    </div>
    <context-menu
      v-if="positionContext && contextMenuItems.length"
      :items="contextMenuItems"
      :position="positionContext"
      @selectItemMenu="handleSelectContextItem"
      @closePopup="positionContext = null"
    />
    <core-confirm-popup
      :show="confirmDeletionData.showPopup"
      :text="confirmDeletionData.text"
      :ok-button="{ type: 'destructive', title: locales('common_yes') }"
      :cancel-button="{ type: 'secondary', title: locales('common_cancel') }"
      :popup-width="440"
      @onClickOk="handleConfirmDeleteTask"
      @onClickCancel="handleCancelDeleteTask"
    />
  </div>
</template>

<script>
import { delay } from 'lodash';
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import ColorPalette from '$$vueCmp/colorPalette/colorPalette.vue';
import dateHelper from '$$helpers/dateFormats';
import moment from '../../../libs/moment';
import {
  closeTask, getTaskBreadcrumbs, isBreadcrumbsFitInDiv, shortenBreadcrumbs, memoizedIsTextFitInDiv,
} from '../helpers';
import routerHelper from '$$helpers/router';
import app from '../../../app';
import projectsModel from '../../../models/projects';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';
import customHelper from '$$helpers/custom';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import globalStore from '../../../store/main';

export default {
  name: 'TaskSettingsHeader',
  components: {
    CoreConfirmPopup,
    ColorPalette,
    VgpIconButton,
    Tooltip,
  },
  inject: ['isFieldDisabled'],
  props: {
    taskData: {
      type: Object,
      default: null,
    },
    commentHub: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      positionContext: null,
      taskBreadcrumbsArray: [],
      taskNameInputKey: 0,
      confirmDeletionData: {
        showPopup: false,
        text: '',
      },
      isBreadcrumbsFitInDiv: false,
      isTaskNameFitInDiv: true,
    };
  },
  computed: {
    fullTaskBreadcrumbs() {
      return this.taskBreadcrumbsArray.join(' / ');
    },
    shortenedBreadcrumbsIfDoesntFit() {
      return shortenBreadcrumbs({
        isFit: this.isBreadcrumbsFitInDiv,
        breadcrumbsArray: this.taskBreadcrumbsArray,
        separator: ' / ',
      });
    },
    taskAuthorName() {
      return this.$store.getters['resourcesModel/getResourceById'](this.taskData.owner_id)?.name || __('task_settings_author_deleted_label');
    },
    taskCreationDate() {
      return moment(this.taskData.created_at || this.taskData.start_date).format(dateHelper.getUserDateFormat());
    },
    colors() {
      return this.$store.getters['teamSetting/colors'].data;
    },
    isMilestone() {
      return this.taskData.type === 'milestone';
    },
    contextMenuItems() {
      const items = [];

      if (!this.isFieldDisabled('type')) {
        items.push(
          {
            id: 'change_type',
            name: this.isMilestone ? __('settings_convert_to_task_label') : __('settings_convert_to_milestone_label'),
            icon: 'milestone',
          },
        );
      }

      const {
        is_jira,
        gantt_id,
      } = projectsModel.getActiveProjectData() || {};
      const isJiraRootProject = is_jira && (gantt_id <= GT.lastProjectIdBeforeRootTask) && gantt.getTask(this.taskData.id).$level === 1;

      if (!this.isFieldDisabled('delete_task') && !isJiraRootProject) {
        items.push(
          {
            id: 'delete',
            name: __('common_delete'),
            labelColor: '#DD3636',
            icon: 'delete',
            iconColor: '#DD3636',
          },
        );
      }

      return items;
    },
  },
  watch: {
    'taskData.text': {
      handler(val) {
        delay(this.setIsTaskNameFitInDiv, 0, val);
      },
      immediate: true,
    },
    'taskData.id': {
      handler() {
        delay(() => {
          // this.taskBreadcrumbsArray = getTaskBreadcrumbs(this.taskData, null);
          this.taskBreadcrumbsArray = getTaskBreadcrumbs(this.taskData);
          this.setIsBreadcrumbsFitInDiv();
        }, 0);
      },
      immediate: true,
    },
  },
  methods: {
    setIsBreadcrumbsFitInDiv() {
      this.isBreadcrumbsFitInDiv = isBreadcrumbsFitInDiv({
        breadcrumbsArray: this.taskBreadcrumbsArray,
        separator: ' / ',
        classes: [this.$style.header__breadcrumbs],
        divWidth: 631,
      });
    },
    setIsTaskNameFitInDiv(taskName) {
      this.isTaskNameFitInDiv = memoizedIsTextFitInDiv({
        textContent: taskName,
        classes: [this.$style['task-name-input']],
        cssTextStyle: 'font-family: Lato-Bold, sans-serif; font-size: 18px; line-height: 28px;',
        divWidth: 702,
      });
    },
    handleChangeTaskName(event) {
      const value = event.target.value.trim();

      if (!value) {
        return this.taskNameInputKey++;
      }
      this.handleChangeData('text', value);
    },
    async copyTaskToClipboard() {
      try {
        const root = `${window.location.protocol}//${window.location.host}`;
        const taskUrl = `${root}/#/project/${this.taskData.gantt_id}/gantt/task/${this.taskData.id}`;

        await navigator.clipboard.writeText(taskUrl);
        this.$toast.success(this.locales('task_settings_task_link_copied'));
      } catch (e) {
        console.error('Failed to copy task: ', e);
      }
    },
    handleChangeData(property, value) {
      if (value === this.taskData[property]) return;
      this.$emit('change', {
        property,
        payload: value,
      });
    },
    handleSelectContextItem(itemId) {
      if (itemId === 'change_type') {
        const newTaskType = this.isMilestone ? 'task' : 'milestone';

        this.$emit('change', {
          property: 'type',
          payload: newTaskType,
        });
      }

      if (itemId === 'delete') {
        this.showDeleteConfirmationPopup();
      }
    },
    showContextMenu() {
      this.positionContext = this.$refs.contextMenuBtn.$el.getBoundingClientRect();
    },
    showDeleteConfirmationPopup() {
      const isJira = !!this.taskData.jira_key;
      const localeKey = `settings_msg_delete_task${isJira ? '_jira' : ''}`;

      const taskName = __(localeKey, { taskName: `<b>${customHelper.formatTaskName(this.taskData.text)}</b>` });

      this.confirmDeletionData.text = `<div>${taskName}</div>`;
      this.confirmDeletionData.showPopup = true;
    },
    cleanUpConfirmDeletionData() {
      this.confirmDeletionData.showPopup = false;
      this.confirmDeletionData.text = '';
    },
    handleCancelDeleteTask() {
      userExtAnalytics.log('gantt_task_delete_cancelled');
      this.cleanUpConfirmDeletionData();
    },
    handleConfirmDeleteTask() {
      userExtAnalytics.log('task_settings_action', { action: 'delete' });
      if (gantt.isTaskExists(this.taskData.id)) {
        const taskData = gantt.getTask(this.taskData.id);

        if (!taskData) return;

        if (taskData.$new) {
          gantt._deleteTask(this.taskData.id, true);
          gantt.refreshData();
        } else {
          app.trigger('taskSettings:delete', this.taskData.id);
        }

        userExtAnalytics.log('gantt_task_delete_confirmed');
      } else if (routerHelper.isListViewRoute()) {
        app.trigger('taskSettings:delete:commonList', this.taskData);
      } else {
        gantt.ganttWorker.calculate([this.taskData], 'changeTasks', 'removeTasks');
        globalStore.dispatch('tasksModel/backgroundDeleteTask', this.taskData);
      }

      closeTask();

      this.$store.commit('comments/deleteDraft', {
        projectId: +this.taskData.gantt_id,
        taskId: +this.taskData.id,
      });
    },
  },
};
</script>

<style module lang="less">

.task-settings-header {
  padding: 24px 32px 20px 32px;
  border-bottom: 1px solid #E5E5E5;
}

.header__row {
  display: flex;

  &:first-child {
    margin-bottom: 14px;
    justify-content: space-between;
  }

  &:nth-child(2) {
    margin-bottom: 4px;
  }
}

.breadcrumbs-tooltip {
  display: flex;
  align-items: center;
  max-width: 631px;
}

.header__breadcrumbs {
  font-family: Lato-Regular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header__actions {
  display: flex;
}

.task-name-input {
  margin-left: 8px;

  &:hover:not(&_disabled):not(&:focus-within) {
    background-color: rgba(0, 0, 0, 0.06);
  }

  :global(input), &_disabled {
    height: 28px !important;
    font-family: Lato-Bold, sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #191919;
  }

  &_disabled {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 16px;
  }
}

.task-creation-info {
  display: flex;
  align-items: center;
  font-family: Lato-Regular, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  margin-left: 40px;
  &_author {
    margin: 0 3px;
    max-width: 578px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.row-buttons {
  &__item {
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: 120px;
    }
  }
}

.row-status {

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: Lato-Bold, sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
    }
  }
}

.progress-wrapper {
  display: flex;
  gap: 4px;

  &__input {
    width: 53px;
    height: 26px;
  }
}

.button-dropdown-select {
  padding: 4px 8px 4px 4px;
  gap: 2px;
  background: #F2F2F2;
  border-radius: 4px;

  font-family: Lato-Bold, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #808080;
}

</style>
