<template>
  <span :class="$style['lds-ring']">
    <span />
    <span />
  </span>
</template>

<script>
export default {};
</script>
<style module lang="less">
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    opacity: 0.3;
  }
  .lds-ring span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  .lds-ring span:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring span:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring span:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .container{
    border: 1px solid #BDBDBD;
    padding: 0 0 0 12px;
    border-radius: 2px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    font-family: Lato-Regular;
    -webkit-appearance: none;
    color: #757575;
  }
</style>
