import app from '../../../../app';

const blockedEvents = [
  // 'TaskResourceUnassigned',
  // 'TaskResourceAssigned',
  // 'TaskResourceUpdated',
  'TaskDeleted',
  // 'TaskCreated',
  // 'TaskUpdated',
  // 'TaskCustomFieldUpdated',
  // 'TaskTimeLogsCreated',
  // 'TaskTimeLogDeleted',
  // 'TaskTimeLogUpdated',
  // 'TaskCommentCreated',
  // 'TaskCommentDeleted',
  // 'TaskAttachementCreated',
  // 'TaskAttachementDeleted',
  'TaskLinkDeleted',
  // 'TaskLinkCreated',

  // 'CustomFieldToProjectAssigned',
  // 'CustomFieldFromProjectUnassigned',
  // 'CustomFieldUpdated',
  // 'CustomFieldCreated',
  // 'CustomFieldDeleted',

  // 'ResourceToProjectsAssigned',
  // 'ResourceFromProjectsUnassigned',
  // 'ResourceDeleted',
  // 'ResourceCreated',
  // 'ResourceUpdated',
  // 'ResourceOnProjectsUpdated',
  // 'ResourceRoleUpdated',

  'ProjectArchived',
];

function activateBlockListheners(activateCollback) {
  app.on('opened:popup:settingsLink', link_id => {
    activateCollback({
      link_id,
      events: blockedEvents,
      type: 'gantt',
      mode: 'link_popup',
    });
  });
}

function deactivateBlockListheners(deactivateCollback) {
  app.on('closed:popup:settingsLink', deactivateCollback);
}

export default {
  activateBlockListheners,
  deactivateBlockListheners,
};
