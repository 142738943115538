<template>
  <core-popup
    :show="show"
    :title="locales('overview_project_description_title')"
    :class-list="$.description_modal"
  >
    <template #body>
      <div :class="[$.editor, $.focus]">
        <div ref="editor" />
      </div>

      <div :class="$.actions">
        <vgp-button
          :class="$.action_button"
          type="secondary"
          :label="locales('common_cancel')"
          small
          @onClick="$emit('onCancel')"
        />
        <vgp-button
          :class="$.action_button"
          type="primary"
          :label="locales('common_save')"
          small
          @onClick="save"
        />
      </div>
    </template>
  </core-popup>
</template>

<script>
import Quill from 'quill';
import CorePopup from '../../common/VueComponents/popups/corePopup/corePopup.vue';
import VgpButton from '../../common/VueComponents/globalButton/vgpButton.vue';

export default {
  components: {
    CorePopup,
    VgpButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: __('overview_project_description_placeholder'),
    },
  },
  emits: {
    onCancel: null,
    onSave: String,
  },
  data() {
    return {
      locales: __,
      instance: null,
    };
  },
  mounted() {
    this.$nextTick(this.init);
  },
  beforeDestroy() {
    this.instance = null;
    delete this.instance;
  },
  methods: {
    init() {
      this.instance = new Quill(this.$refs.editor, {
        theme: 'snow',
        bounds: this.$refs.editor,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            [{ font: [] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link'],
            [{ color: [] }, { background: [] }],
            ['clean'],
          ],
          autoLinks: true,
        },
        placeholder: this.placeholder,
      });

      this.instance.pasteHTML(this.description);

      this.instance.setSelection(this.instance.getLength(), 0);
    },
    getHtml() {
      const innerText = this.instance.getInnerText().trim();

      return innerText ? this.instance.getHtml() : '';
    },
    save() {
      this.$emit('onSave', this.getHtml());
    },
  },
};
</script>

<style module="$" src="../less/descriptionModal.less"></style>
