var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integration_msteams_settings_window" }, [
    _c(
      "div",
      { staticClass: "integration_msteams_settings_window-header" },
      [
        _c("div", { class: _vm.$style.integration_header_text }, [
          _c(
            "div",
            { staticClass: "icon" },
            [_c("webix-ui", { attrs: { config: _vm.icons.msteamsIcon } })],
            1
          ),
          _vm._v("\n      Microsoft Teams \n      "),
          _c("span", [
            _vm._v(
              _vm._s(
                "" +
                  _vm.locales("integration_msteams_settings_page_header_text")
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("closer", { attrs: { "on-close": _vm.closeWindow, size: 30 } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "integration_msteams_settings_window-body" }, [
      _c("div", { staticClass: "scroll_container" }, [
        _c(
          "div",
          { staticClass: "container_msteams" },
          [
            _c("description", {
              attrs: {
                title: _vm.locales("integration_msteams_settings_page_title"),
                description: _vm.locales(
                  "integration_msteams_settings_page_description"
                ),
                "remove-integration": _vm.removeIntegration,
                "table-preloder": _vm.tablePreloder,
                "disable-integration": _vm.disableIntegration,
                "remove-integration-button": _vm.removeIntegrationButton,
              },
            }),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                !_vm.tablePreloder
                  ? _c(
                      "div",
                      { key: "table", staticClass: "connections_table" },
                      [
                        _c("integration-header"),
                        _vm._v(" "),
                        _vm.filteredProjectChannelConnections.length
                          ? _c(
                              "div",
                              { staticClass: "connections" },
                              _vm._l(
                                _vm.filteredProjectChannelConnections,
                                function (projectChannelConnection, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "connection",
                                      class: {
                                        full_border:
                                          projectChannelConnection.fullBorder,
                                      },
                                    },
                                    [
                                      !projectChannelConnection.editMode
                                        ? _c("connection-item", {
                                            key: projectChannelConnection.id,
                                            attrs: {
                                              "project-channel-connection":
                                                projectChannelConnection,
                                              "get-project-by-gantt-id":
                                                _vm.getProjectByGanttId,
                                              "toggle-edit-view":
                                                _vm.toggleEditView,
                                              "delete-item": _vm.deleteItem,
                                              "get-settings-string":
                                                _vm.getSettingsString,
                                            },
                                          })
                                        : _c("connection-edit-item", {
                                            key: projectChannelConnection.id,
                                            attrs: {
                                              "project-channel-connection":
                                                projectChannelConnection,
                                              "project-options":
                                                _vm.filteredProjectOptions,
                                              "team-options":
                                                _vm.filteredTeamOptions,
                                              "channel-options":
                                                _vm.filteredChannelOptions,
                                              "settings-options":
                                                _vm.settingsOptions,
                                              "selected-inputs":
                                                _vm.selectedInputs,
                                              "on-change-project-id":
                                                _vm.onChangeProjectId,
                                              "on-change-team-id":
                                                _vm.onChangeTeamId,
                                              "on-change-channel-id":
                                                _vm.onChangeChannelId,
                                              "on-change-settings":
                                                _vm.onChangeSettings,
                                              "on-focus-select":
                                                _vm.onFocusSelect,
                                              "on-changes-flag":
                                                _vm.onChangesFlag,
                                              "on-ok": _vm.editItem,
                                              "on-cancel": _vm.closeEditMode,
                                              "channel-is-empty-error":
                                                _vm.channelIsEmptyError,
                                              "team-is-empty-error":
                                                _vm.teamIsEmptyError,
                                              "project-is-empty-error":
                                                _vm.projectIsEmptyError,
                                              "is-channels-list-loading":
                                                _vm.isChannelsListLoading,
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _c("div", { staticClass: "no_connections" }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.locales(
                                        "integration_slack_settings_page_table_no_connections"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                        _vm._v(" "),
                        !_vm.addMode
                          ? _c("div", { staticClass: "add_item_container" }, [
                              !_vm.editMode
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "add_button",
                                      on: { click: _vm.onClickAdd },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.locales(
                                                "integration_slack_settings_page_add_button"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("connection-edit-item", {
                              attrs: {
                                "project-channel-connection": {},
                                "project-options": _vm.filteredProjectOptions,
                                "team-options": _vm.filteredTeamOptions,
                                "channel-options": _vm.filteredChannelOptions,
                                "settings-options": _vm.settingsOptions,
                                "selected-inputs": _vm.selectedInputs,
                                "on-change-project-id": _vm.onChangeProjectId,
                                "on-change-team-id": _vm.onChangeTeamId,
                                "on-change-channel-id": _vm.onChangeChannelId,
                                "on-change-settings": _vm.onChangeSettings,
                                "on-focus-select": _vm.onFocusSelect,
                                "on-changes-flag": _vm.onChangesFlag,
                                "on-ok": _vm.addItem,
                                "on-cancel": _vm.closeAddMode,
                                "channel-is-empty-error":
                                  _vm.channelIsEmptyError,
                                "team-is-empty-error": _vm.teamIsEmptyError,
                                "project-is-empty-error":
                                  _vm.projectIsEmptyError,
                                "add-mode": true,
                                "is-channels-list-loading":
                                  _vm.isChannelsListLoading,
                              },
                            }),
                      ],
                      1
                    )
                  : _c("preloader"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }