<template>
  <section :class="$style.section">
    <div :class="$style.section__left">
      <div :class="$style.title__wrap">
        <h1
          :class="[
            $style.section__title,
            animateLeave && !animateEnter ? $style.animateLeave : '',
            animateEnter && !animateLeave? $style.animateEnter : ''
          ]"
        >
          {{ currentStepConfig.title }}
        </h1>
        <div
          :class="[
            $style.section__number,
            animateLeave && !animateEnter ? $style.animateLeave : '',
            animateEnter && !animateLeave ? $style.animateEnter : ''
          ]"
        >
          {{ currentStepIndex + 1 }}
        </div>
      </div>
    </div>
    <div :class="$style.section__right">
      <slot name="right" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingSection',
  props: {
    animateLeave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animateEnter: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStepConfig: 'onboarding/getCurrentStepConfig',
      currentStepIndex: 'onboarding/getCurrentStepIndex',
    }),
  },
  watch: {
    animateLeave(val) {
      if (val) {
        this.animateEnter = false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateEnter = true;
    }, 0);
  },
};
</script>

<style lang="less" module>

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.section__left {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section__right {
  height: 100%;
}

.title__wrap {
  display: flex;
  align-items: flex-start;
  position: relative;
  max-width: 364px;
  height: 198px;
}

.section__title {
  z-index: 2;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .2s, transform .2s cubic-bezier(0, 0, 0.58, 1);

  font-family: Lato-Regular, sans-serif;
  font-size: 29px;
  line-height: 35px;
  color: #000000;
  &_slot {
    margin-bottom: 32px;
    margin-top: 34px;
    width: 302px;
  }

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }

  &.animateLeave {
    transition: opacity .25s, transform .25s cubic-bezier(0.42, 0, 1, 1);
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-40px);
  }

}

.section__description {
  font-family: Lato-Regular, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 74px;
  width: 302px;
}

.section__number {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity .3s, transform .3s cubic-bezier(0, 0, 0.58, 1);

  font-family: Lato-Black, sans-serif;
  font-size: 222px;
  line-height: 266px;
  color: #F5F8F8;
  position: absolute;
  top: -70px;

  &.animateEnter {
    opacity: 1;
    transform: translateY(0);
  }

  &.animateLeave {
    transition: opacity .2s cubic-bezier(0.42, 0, 1, 1), transform .2s cubic-bezier(0.42, 0, 1, 1);
    transform: translateY(-40px);
    opacity: 0;
  }

}

</style>
