class Сolumns {
  constructor(table) {
    this.table = table;
    this.colls = {};
    this.defaultWidth = '131px';
    this.collsLength = 0;
    this.horizontalOverflow = false;

    this.updateColumns();
  }

  updateColumns() {
    const headerCells = this.table.querySelectorAll('.table-header-cell');

    if (!headerCells.length) return;

    const tableWidth = this.table.offsetWidth;
    let allFixWidth = 0;
    let fixCols = 0;
    let horizontalWidth = 50; // width add cell

    headerCells.forEach((cell, index) => {
      const name = cell.getAttribute('date-name');
      const coll = this._getColl(name);
      const fixWidth = +cell.getAttribute('fix-width');

      coll.cells = this._getCollCells(name);
      coll.name = name;
      coll.index = index;
      coll.width = null;

      if (fixWidth > 0) {
        coll.width = `${fixWidth}px`;
        allFixWidth += fixWidth;
        fixCols++;
      } else {
        coll.width = this.defaultWidth;
      }
      const width = +coll.width.substring(0, coll.width.length - 2);

      horizontalWidth += width;
    });

    this.collsLength = Object.keys(this.colls).length;
    this.horizontalOverflow = horizontalWidth > tableWidth;
    // this.defaultWidth = `${(tableWidth - allFixWidth - 50) / (this.collsLength - fixCols)}px`;

    Object.values(this.colls).forEach(coll => {
      this._setCollWidth(coll, coll.width);
    });
  }

  _setCollWidth(coll, width) {
    coll.cells.forEach(cell => {
      cell.style.width = width || this.defaultWidth;
    });
  }

  _getColl(name) {
    let coll = this.colls[name];

    if (!coll) {
      coll = {};
      this.colls[name] = coll;
    }

    return coll;
  }

  _getCollCells(name) {
    return this.table.querySelectorAll(`[date-name="${name}"]`);
  }

  updateColumnCells() {
    Object.values(this.colls).forEach(coll => {
      coll.cells = this._getCollCells(coll.name);
      this._setCollWidth(coll, coll.width);
    });
  }

  resize(name, width) {
    const coll = this.colls[name];

    coll.width = width;
    this._setCollWidth(coll, width);
  }
}

export default Сolumns;
