<template>
  <div
    ref="inputField"
    :style="{ position: 'relative' }"
    :class="{
      'colorPallete_disabled':disabled,
      'vgp-interactive-element': true,
    }"
  >
    <div
      v-if="selectField === 'select'"
      class="input_field_wrap"
      :style="{width: selectWidth, height: selectHeight + 'px' }"
    >
      <div
        :id="gpAutotestName"
        :class="[
          'input_field',
          illuminateOnHover ? 'input_hover': '',
          !border ? 'input_borderless' : '',
          isHightlightBorder ? 'active' : '',
          paletteOpen ? 'open_palette' : '',
        ]"
        @click="showPalette"
      >
        <div
          v-if="checked.length"
          :class="'input_field_selected ai-center'"
          :style="{'width': '100%'}"
        >
          <div v-if="multiselect">
            <vgpRollItems
              :items="checked || []"
              :offset="4"
            >
              <template #default="{item}">
                <div
                  :class="['color-palette_item']"
                  :style="{'background-color': colors.find(color => color.id === item).hex}"
                />
              </template>
            </vgpRollItems>
          </div>
          <div v-else>
            <div
              v-for="(item, index) in checked"
              :key="index + item.id"
            >
              <template>
                <div
                  :class="['color-palette_item']"
                  :style="{'background-color': colors.find(color => color.id === item.id).hex}"
                />
              </template>
            </div>
          </div>
        </div>

        <div
          v-if="!checked.length"
          :class="'input_placeholder'"
        >
          <span v-if="placeholder"> {{ placeholder }} </span>
        </div>

        <div class="d-flex align-center">
          <sprite
            v-show="isShowResetBtn"
            :name="'close-2'"
            :type="'bold'"
            :size="16"
            class="vgp_colorPallete_reset_icon"
            @click="onClickReset"
          />
          <sprite
            class="input_field_icon"
            name="drop-down"
            type="bold"
          />
        </div>
      </div>
    </div>

    <div v-if="selectField === 'square'">
      <div
        v-for="(item, index) in checked"
        :key="index + item.id"
        @click="showPalette"
      >
        <template>
          <div
            :class="['color-palette_item', 'item_offset']"
            :style="{'background-color': item.hex}"
          />
        </template>
      </div>
    </div>

    <div
      v-show="paletteOpen"
      ref="colorPalette"
      :class="['gantt_scroll', 'color-palette', dropToBottom ? 'bottom_drop' : 'top_drop']"
    >
      <div
        v-for="(option, index) in colors"
        :key="index + option.id"
        :class="['color-palette_item', 'item_offset']"
        :style="{'background-color': hoverColor === option.id ? option.hex2 : option.hex}"
        @mouseover="hoverColor = option.id"
        @mouseleave="hoverColor = null"
        @click="selectItem(option)"
      >
        <sprite
          v-if="checked.length"
          class="color-palette_item_icon"
          :style="{display: isSelectedColor(option) ? 'block' : 'none'}"
          color="white"
          name="check"
        />
      </div>
      <div
        v-if="multiselect && isHasSelectedColor"
        :class="'reset'"
        @click="onClickReset"
      >
        {{ 'Reset' }}
      </div>
    </div>
  </div>
</template>

<script>

import sprite from '../VueIcon/svgSprite.vue';
import vgpRollItems from '../rollItems/vgpRollItems.vue';

export default {
  components: {
    vgpRollItems, sprite,
  },
  props: {
    gpAutotestName: { type: String, required: false, default: '' },
    colors: {
      type: Array,
      required: true,
    },
    multiselect: { type: Boolean, required: false, default: false },
    selectField: {
      type: String,
      required: true,
      validator(value) {
        return (['select', 'square'].indexOf(value) !== -1);
      },
      default: 'square',
    },
    selectWidth: {
      type: String,
      required: false,
      default: '240px',
    },
    selectHeight: {
      type: String,
      required: false,
      default: '40',
    },
    selectedValues: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
    },
    showResetBtn: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    illuminateOnHover: { type: Boolean, required: false, default: false },
    outlineIfHasSelected: { type: Boolean, required: false, default: false },
    dropPosition: {
      type: String,
      required: false,
      default: 'absolute',
      validator(value) {
        return ['fixed', 'absolute'].includes(value);
      },
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    selectColors: Array,
  },
  data() {
    return {
      paletteOpen: false,
      checked: [],
      hoverColor: null,
      dropToBottom: true,
    };
  },

  computed: {

    isShowResetBtn() {
      return this.showResetBtn && this.isHasSelectedColor;
    },

    isHightlightBorder() {
      return this.outlineIfHasSelected ? (this.paletteOpen || this.isHasSelectedColor) : false;
    },

    isHasSelectedColor() {
      if (Array.isArray(this.checked)) return !!this.checked.length;

      return !!this.checked;
    },
  },
  watch: {
    selectedValues(val) {
      this.setSelectedValues();
    },
  },

  mounted() {
    this.setSelectedValues();
    document.addEventListener('click', this.close);
    document.addEventListener('mousedown', this.close);
    window.addEventListener('wheel', _.debounce(this.onScroll, 100));
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close);
    document.removeEventListener('mousedown', this.close);
    window.removeEventListener('wheel', this.onScroll);
  },

  methods: {
    setSelectedValues() {
      if (Array.isArray(this.selectedValues) && this.selectedValues.length) {
        this.selectedValues.forEach(item => {
          if (typeof item === 'number' || typeof item === 'string') {
            const color = this.colors.find(c => Number(c.id) === Number(item));

            color && this.checked.push(color);

            return;
          }
          this.checked.push(item);
        });
        this.checked = this.multiselect ? [...this.checked] : [this.checked.at(-1)];

        return;
      }

      if (this.selectedValues && typeof this.selectedValues === 'object') {
        this.checked = [this.selectedValues];

        return;
      }

      this.checked = [];
    },

    onScroll(e) {
      if (e.target.closest('.color-palette')) {
        return;
      }
      this.paletteOpen = false;
    },

    isSelectedColor(color) {
      return this.checked.find(c => c.id === color.id);
    },

    getColorById(colorId) {
      return this.colors.find(c => c.id === colorId);
    },

    selectItem(item) {
      if (!this.multiselect) {
        this.checked = [item];
        this.hoverColor = null;
        this.paletteOpen = false;
      }

      if (this.selectType === 'multi') {
        if (!this.checked) this.checked = [];

        this.hoverColor = null;
      }

      this.$emit('selectColors', this.checked);
    },

    showPalette(e) {
      if (this.disabled) { return; }

      const path = e.path ? e.path : e.composedPath(e.target);

      const isResetBtn = path.find(elem => {
        if (!elem.classList) return;

        return elem.classList.contains('vgp_colorPallete_reset_icon');
      });

      if (isResetBtn) { return; }

      this.paletteOpen = !this.paletteOpen;

      if (this.paletteOpen) {
        this.$nextTick(() => this.checkPopupPosition());
      }
    },

    onClickReset() {
      this.checked = [];
      this.$emit('selectColors', this.checked);
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.paletteOpen = false;
      }
    },

    checkPopupPosition() {
      if (this.dropPosition === 'fixed') {
        this.checkPopupPositionFixed();
      } else {
        this.checkPopupPositionAbsolute();
      }
    },

    checkPopupPositionAbsolute() {
      const drop = this.$refs.colorPalette;
      const clientHeight = document.body.clientHeight;
      const dropHeight = this.$refs.colorPalette.offsetHeight;

      const coords = this.$refs.inputField.getBoundingClientRect();

      const canShowBottom = (coords.bottom + dropHeight) < (clientHeight - 30);
      const canShowTop = (coords.top - dropHeight) > 37;
      const dropToBottom = (!canShowBottom && !canShowTop) || canShowBottom;

      drop.style.position = 'absolute';
      // drop.style.width = coords.width + 'px';
      drop.style.top = dropToBottom ? `${coords.height + 5}px` : `-${dropHeight + 5}px`;
    },

    checkPopupPositionFixed() {
      const drop = this.$refs.colorPalette;
      const clientHeight = document.body.clientHeight;
      const dropHeight = drop.offsetHeight;

      const coords = this.$refs.inputField.getBoundingClientRect();

      this.componentWidth = coords.width;

      const canShowBottom = clientHeight - coords.bottom - dropHeight > 37;
      const canShowTop = coords.top - dropHeight > 10;
      const dropToBottom = (!canShowBottom && !canShowTop) || canShowBottom;

      drop.style.position = 'fixed';
      drop.style.top = dropToBottom ? `${coords.bottom + 5}px` : `${coords.top - dropHeight - 5}px`;
    },
  },
};
</script>

<style scoped src='./colorPalette.less'></style>
