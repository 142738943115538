import { api } from '$$store/api';
import globalStore from '../../../store/main';
import app from '../../../app';
import ganttViewModel from '../../../models/ganttViewModel';
import timeTracking from '../../../models/timeTracking';
import projectComponent from '../../Project';
import _ from '../../../libs/lodash';
import { prepareTaskToRefresh, updateButtonInTask } from '../tasks/helpers';
import timer from '../../../helpers/Timer';

// dataFromServer = {
//   resourcesToTask: [],
//   tasksData: { tasks:[],  customValues: {}},
//   timelogs: [],
//   teamColumns: [],
//   customFieldsToProject: [],
//   customFields: [],
//   customValues: [],
//   links: [],
// }
class UpToDate {
  constructor() {
    this.dataFromServer = null;
  }

  async updateClientDataByParams(params, changedTasks) {
    timer.start('timeToGetExternalData');
    const res = await api.post('/collaboration/dataByParams', params);

    timer.end('timeToGetExternalData');

    const data = res.data;

    this.dataFromServer = data;

    data.resourcesToTask && await this._updateTaskResources(data.resourcesToTask);
    data.tasksData && this._updateTasks(data.tasksData);
    data.customValues && this._updateCustomValuesOfTasks(data.customValues);
    data.customFields && this._updateCustomFields(data.customFields);
    data.customFieldsToProject && this._updateCustomFieldsInProjects(data.customFieldsToProject);
    data.teamColumns && this._updateCustomColumnsInTeam(data.teamColumns);
    data.timelogs && this._updateTimelogs(data.timelogs);
    data.links && this._updateLinks(data.links);

    timer.start('ganttRerenderTime');
    this._updateGanttGrid(changedTasks);
    timer.end('ganttRerenderTime');

    this.dataFromServer = null;

    app.trigger('tasks:model:updateTask'); // for recalc workload

    data.resourcesToTask && app.trigger('refresh:filter');
  }

  async _updateTaskResources(data) {
    globalStore.dispatch('tasksModel/updateTaskResourcesByGanttIdsAndTaskIds', data);
    globalStore.dispatch('headerView/setUIConfig');
    const projectIds = gantt.config.multiview ? [...gantt.config.order] : [gantt.config.gantt_id];

    data.forEach(item => {
      if (!projectIds.includes(+item.ganttId)) return;

      gantt.batchUpdate(() => {
        Object.entries(item.resourcesToTasks).forEach(([taskId, resources]) => {
          const task = gantt.getTask(+taskId);

          task && (task.resources = [...resources]);
        });
      });
    });
  }

  _updateTasks(tasksData) {
    globalStore.commit('tasksModel/updateTasks', tasksData.tasks);
    globalStore.dispatch('tasksModel/updateCustomValues', { mapData: tasksData.customValues });
  }

  _updateCustomValuesOfTasks(values) {
    values.forEach(item => {
      globalStore.commit('columns/changeCustomValues', {
        task: { id: item.task_id, gantt_id: item.gantt_id },
        columnId: item.column_id,
        value: item.value,
      });
    });

    const ganttIDs = ganttViewModel.getGanttIDs();

    app.trigger('userCustomColumnsModel:change', ganttIDs[0], 'update');
  }

  _updateCustomFields(columns) {
    columns.forEach(column => {
      globalStore.commit('columns/updateCustomColumn', { columnId: column.id, updated: { ...column } });
    });

    const ganttIDs = ganttViewModel.getGanttIDs();

    app.trigger('userCustomColumnsModel:change', ganttIDs[0], 'update');
  }

  _updateCustomFieldsInProjects(data) {
    data.forEach(el => {
      const storeCustomColumns = globalStore.getters['columns/getColumnIdsByProjectId'](+el.ganttId);

      if (storeCustomColumns.length > el.columnIds.length) {
        const deletedColumnIds = storeCustomColumns.filter(colId => !el.columnIds.includes(colId));

        if (deletedColumnIds.length) {
          deletedColumnIds.forEach(id => globalStore.commit('columns/removeColumnFromProjects', { columnId: id, projectIds: [+el.ganttId] }));
        }
      } else {
        globalStore.commit('columns/addColumnsToProjects', { columnIds: el.columnIds, projectIds: [+el.ganttId] });
      }
    });

    app.trigger('userCustomColumnsModel:change', [], 'update');
  }

  _updateCustomColumnsInTeam(columns) {
    globalStore.commit('columns/setCustomColumns', columns);
    app.trigger('userCustomColumnsModel:change');
  }

  _updateGanttGrid(changedTaskIds) {
    if (!this.dataFromServer.tasksData || !changedTaskIds.length) { return; }

    const that = this;
    const changedTasks = this.dataFromServer.tasksData ? this.dataFromServer.tasksData.tasks : [];
    let needClearAndParseContext = false;

    gantt._dp.setUpdateMode('off');
    gantt.batchUpdate(() => {
      changedTasks.forEach(task => {
        if (!gantt.isTaskExists(task.id)) { return; }

        const taskInGantt = gantt.getTask(task.id);

        if (task.parent && taskInGantt.parent !== task.parent) {
          gantt.moveTask(task.id, task.sortorder, task.parent);
          updateButtonInTask(task.parent, task.gantt_id);

          if (taskInGantt.parent) {
            const oldParentTask = gantt.getTask(taskInGantt.parent);

            oldParentTask && updateButtonInTask(oldParentTask.id, task.gantt_id);
          }
        }

        if (taskInGantt.sortorder !== task.sortorder) {
          needClearAndParseContext = true;
        }

        const updateData = prepareTaskToRefresh(task);

        updateData.estimation = task.estimation;
        delete updateData.parent;

        const statusesAndPriority = that._getUpdatedStatusAndPriority(+task.gantt_id, task.id);

        statusesAndPriority && _.assign(updateData, statusesAndPriority);

        _.assign(taskInGantt, updateData);
      });
    });

    if (gantt.config.highlight_critical_path) {
      gantt.callEvent('recalculateCriticalPath');
    }

    gantt._dp.setUpdateMode('cell');

    // gantt.render();
    !gantt.is_workload_mode && needClearAndParseContext && gantt.callEvent('ganttClearAndParse', [null, true]);
  }

  _getUpdatedStatusAndPriority(gantd_id, task_id) {
    if (!this.dataFromServer && !this.dataFromServer.tasksData && !this.dataFromServer.tasksData.customValues) {
      return;
    }

    const progectVals = this.dataFromServer.tasksData.customValues[gantd_id];

    if (!progectVals) { return null; }

    const vals = progectVals.items.find(i => +i.taskId === +task_id);

    return vals ? vals.updates : null;
  }

  _updateTimelogs(data) {
    Object.entries(data).forEach(([taskId, taskLogs]) => {
      const gantt_id = globalStore.getters['tasksModel/getTask'](+taskId).gantt_id;

      timeTracking.manualUpdateItemForTask({
        task_id: +taskId,
        gantt_id,
      }, taskLogs);

      app.trigger('timeTrackingModel:change', +taskId);
    });
  }

  _updateLinks(links) {
    const projects = [...new Set(links.map(i => i.gantt_id))];
    const projectsHasUser = projects.filter(id => projectComponent.userHasProject(id));

    links.forEach(link => {
      if (!projectsHasUser.includes(link.gantt_id)) { return; }

      const modelLink = globalStore.getters['tasksModel/getLinkByGanttId'](link.gantt_id, link.id);
      const isExist = Object.keys(modelLink).length;

      if (isExist) {
        globalStore.commit('tasksModel/updateLink', link);
        app.trigger('tasks:model:updateLink', link.id, link);

        return;
      }

      globalStore.commit('tasksModel/addLink', link);
      app.trigger('tasks:model:addLink', link.id, link);
    });
  }
}

export default new UpToDate();
