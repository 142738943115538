import globalStore from '$$store/main';
import projectsModel from '../../../models/projects';
import routerHelper from '$$helpers/router';

export const getTaskBreadcrumbs = (taskData, projectTasksMap = {}) => {
  const breadCrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](taskData.id, taskData.gantt_id, projectTasksMap);
  const projectName = projectsModel.getItem(taskData.gantt_id).name;
  const breadcrumbsArray = [projectName];

  if (breadCrumbsTasks) breadCrumbsTasks.forEach(task => breadcrumbsArray.push(task.text));

  return breadcrumbsArray;
};

export const closeTask = () => {
  const multiviewRoute = routerHelper.isMultiProjectsRoute();

  const currentRoute = routerHelper.getCurrentRoute();
  const parent = currentRoute.path.split('/task/')[0];

  routerHelper.changeRoute(parent);
  multiviewRoute && gantt.callEvent('updateColumnsHeaders');
};

export const memoizedIsTextFitInDiv = (() => {
  const memo = {};

  const isTextFitInDivFn = ({
    textContent = '', cssTextStyle = '', classes = [], divWidth,
  }) => {
    const memoKey = `${textContent}-${cssTextStyle}-${classes.join(',')}-${divWidth}`;

    if (Object.prototype.hasOwnProperty.call(memo, memoKey)) {
      return memo[memoKey];
    }

    const hiddenElem = document.createElement('div');

    hiddenElem.style.cssText = `position:absolute;left:-9999px;max-width: unset;${cssTextStyle}`;
    hiddenElem.textContent = textContent;
    hiddenElem.classList.add(...classes);
    document.body.appendChild(hiddenElem);

    const textContentWidth = hiddenElem.offsetWidth;

    document.body.removeChild(hiddenElem);

    const isFit = textContentWidth <= divWidth;

    memo[memoKey] = isFit;

    return isFit;
  };

  isTextFitInDivFn.resetCache = () => {
    Object.keys(memo).forEach(key => delete memo[key]);
  };

  const CACHE_CLEAR_INTERVAL = 60 * 60 * 1000; // Clear cache every 1 hour

  setInterval(isTextFitInDivFn.resetCache, CACHE_CLEAR_INTERVAL);

  return isTextFitInDivFn;
})();

export const isBreadcrumbsFitInDiv = ({
  breadcrumbsArray,
  separator = '/',
  cssTextStyle = '',
  classes = [],
  divWidth,
}) => {
  const breadcrumbsStr = breadcrumbsArray.join(separator);

  return memoizedIsTextFitInDiv({
    textContent: breadcrumbsStr,
    cssTextStyle,
    classes,
    divWidth,
  });
};

export const shortenBreadcrumbs = ({
  isFit,
  breadcrumbsArray,
  separator = '/',
}) => {
  if (isFit || breadcrumbsArray.length <= 2) {
    return breadcrumbsArray.join(separator);
  }

  return [breadcrumbsArray[0], '...', breadcrumbsArray.at(-1)].join(separator);
};
