import colorHelper from './color';

const init = () => {
  initGt();
  colorHelper.importFromGTtoConstants();
}

const initGt = () => {
  window.GT = JSON.parse(document.querySelector(`script[type="application/json"][ganttpro-application-json-selector="gt"]`).textContent);
  window.user = window.GT.user;
};

init();
export default true;
