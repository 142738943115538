<template>
  <div
    v-if="!config.hidden"
    :id="`js-sidebar-menu-item-${config.id}`"
    :class="[$style.menu_item,
             isOpenSideBar ? '' : 'tooltip-gantt', config.css ? config.css : '',
             isActive === config.id ? $style.active : '',
             `gp_autotest_sidebar_menu_item_${config.id}`]"
    data-position="right"
    :data-key="config.tooltip"
    data-x="9"
    data-y="0"
    @click="clickItem(config.mainPath, config.id)"
  >
    <div :class="[$style.wrapper]">
      <div
        :class="[$style.menu_icon]"
      >
        <svg-sprite
          :name="config.icon"
          :size="20"
        />
        <span
          v-if="checkErrorsInSetting(config) && !isOpenSideBar"
          :class="[$style.error_icon]"
        >
          <svg-sprite
            name="warning"
            size="16"
            type="color-regular"
          />
        </span>
      </div>
      <span :class="$style.menu_title">{{ config.name }}</span>
    </div>
    <div
      v-if="planTitle && !accessToFeature"
      :class="$style.plan_title"
    >
      {{ locales(planTitle) }}
    </div>
    <div
      v-if="config.iconRight || config.notificationsCount || unreadCount || mention"
      :class="[$style.wrapper, !isOpenSideBar ? $style.notify_wrapper : '']"
    >
      <div
        v-if="config.notificationsCount && isOpenSideBar"
        :class="$style.notificationsCount"
      >
        <div :class="$style.notificationsCount_text">
          {{
            config.notificationsCount
          }}
        </div>
      </div>

      <div
        v-if="mention && isOpenSideBar && config.id === 'comments'"
        :class="$style.comments"
      >
        <div :class="$style.comments_text">
          <svg-sprite
            :style="{ color: '#333333'}"
            :name="'mentioned'"
            :type="'regular'"
            :size="16"
          />
        </div>
      </div>
      <div
        v-if="!mention && unreadCount && isOpenSideBar && config.id === 'comments'"
        :class="$style.comments"
      >
        <div :class="$style.comments_text">
          {{
            unreadCount > 99 ? '99+' : unreadCount
          }}
        </div>
      </div>

      <div
        v-if="config.notificationsCount && !isOpenSideBar"
        class="notify"
      >
        {{ config.notificationsCount > 99 ? '99+' : config.notificationsCount }}
      </div>
      <div
        v-if="((mention || unreadCount) && config.id === 'comments') && !isOpenSideBar"
        class="comments"
      >
        <div :class="[$style.comments_dot]" />

        <span
          v-if="checkErrorsInSetting(config) && isOpenSideBar"
          :class="[$style.error_icon_open_menu]"
        >
          <svg-sprite
            name="warning-1"
            size="20"
            color="#FF9A00"
            type="bold"
          />
        </span>
      </div>
      <div
        v-if="config.iconRight"
        :class="[$style.menu_iconRight]"
      >
        <svg-sprite
          name="arrow-right"
          :size="24"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pricingHelper from '../../../helpers/pricingHelper';
import store from '$$store/main';

export default {
  name: 'SidebarMenuItem',
  props: {
    config: { type: Object, required: true },
    isOpenSideBar: { type: Boolean, required: true },
    isActive: { type: String, required: true },
    unreadCount: { type: Number, required: false, default: 0 },
    mention: { type: Boolean, required: false },
  },
  data() {
    return {
      locales: __,
    };
  },
  computed: {
    planTitle() {
      let planTitle = null;

      if (this.config.feature) {
        planTitle = `${pricingHelper.getPlans(this.config.feature)}_plan_locale`;
      }

      return planTitle;
    },
    accessToFeature() {
      if (this.config.feature) {
        return pricingHelper.checkPricingAccess(this.config.feature);
      }

      return true;
    },
  },

  mounted() {
  },
  methods: {
    clickItem(path, id) {
      this.$emit('clickMenuItem', path, id);
    },

    checkErrorsInSetting(item) {
      let hasErrors = false;

      const errors = store.getters['teamSetting/getSettingErrors'];

      if (item.id === 'settings') {
        Object.keys(errors).forEach(key => {
          if (errors[key].length !== 0) hasErrors = true;
        });
      }

      return hasErrors;
    },

  },
};

</script>

<style module src="../style.less" lang="less"></style>
<style scoped lang="less">
.notify {
  position: absolute;
  height: 10px;
  min-width: 16px;
  font-size: 8px;
  line-height: 11px;
  border-radius: 2px;
  background-color: #F8F8F8;
  color: #191919;
  left: -12px;
  top: 4px;
  text-align: center;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-style: normal;
}
</style>
