// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".integration_header_text_S0cvEnMY {\n  font-family: 'Lato-Regular';\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 30px;\n  color: #555AAC;\n  display: flex;\n  align-items: center;\n  mix-blend-mode: normal;\n  margin-left: 12px;\n}\n.integration_header_text_S0cvEnMY svg {\n  width: 18px;\n  height: 18px;\n}\n.integration_header_text_S0cvEnMY .icon {\n  margin-right: 5.5px;\n}\n.integration_header_text_S0cvEnMY span {\n  color: #212121;\n  text-transform: lowercase;\n}\n", "",{"version":3,"sources":["webpack://./public/views/integration/msteams/IntegrationMSTeamsSettings.less","webpack://./IntegrationMSTeamsSettings.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EAEA,iBAAA;ACAJ;ADXA;EAaQ,WAAA;EACA,YAAA;ACCR;ADfA;EAkBQ,mBAAA;ACAR;ADlBA;EAsBQ,cAAA;EACA,yBAAA;ACDR","sourcesContent":[".integration_header_text {\n    font-family: 'Lato-Regular';\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 30px;\n    color: #555AAC;\n    display: flex;\n    align-items: center;\n    mix-blend-mode: normal;\n\n    margin-left: 12px;\n    svg {\n        width: 18px;\n        height: 18px;\n    }\n\n    :global(.icon) {\n        margin-right: 5.5px;\n    }\n\n    span {\n        color: #212121;\n        text-transform: lowercase;\n    }\n}\n\n\n\n\n\n\n\n\n\n",".integration_header_text {\n  font-family: 'Lato-Regular';\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 30px;\n  color: #555AAC;\n  display: flex;\n  align-items: center;\n  mix-blend-mode: normal;\n  margin-left: 12px;\n}\n.integration_header_text svg {\n  width: 18px;\n  height: 18px;\n}\n.integration_header_text :global(.icon) {\n  margin-right: 5.5px;\n}\n.integration_header_text span {\n  color: #212121;\n  text-transform: lowercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integration_header_text": "integration_header_text_S0cvEnMY"
};
export default ___CSS_LOADER_EXPORT___;
