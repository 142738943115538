import app from '../../app';
import _ from '../../libs/lodash';

import projectIcon from '../../svg/ic_project.svg';
import iconProjectJira from '../../svg/project_popup/ic_project-jira.svg';
import constants from '../../helpers/constants';
import composedPath from '../../helpers/composedPath';

import templates from '../../templates/labels';
import calendarTemplates from './commonTemplates';

import customDaysModel from '../../models/customDays';
import customDaysTemplatesModel from '../../models/customDayTemplates';
import projectsModel from '../../models/projects';

import zoomDays from '../settings/include/zoomDays';
import zoomHours from '../settings/include/zoomHours';
import settingsCustomDays from '../settings/include/settingsCustomDays';
import templatesEditor from './templatesEditor';
import arrowLeft from '../../svg/pricing/ic_arrow_left.svg';

import {
  arrowDown, closeCross, tableIcon, calendarIcon, icon_left_arrow, icon_right_arrow,
} from './icons';
import routerHelper from '../../helpers/router';
import calculateMainWindowSize from '../../helpers/calculateMainWindowSize';

import globalStore from '../../store/main';
import workload_block_icon from '../../svg/workload_block.svg';
import { getPlansObj } from '../../helpers/pricingHelper';
import rights from '../../components/rights/index';

function checkAccessToFeature(feature) {
  const access = globalStore.getters['paymentModel/getAccessToFeature'](user.paymentFeatures[feature].name);

  return access;
}

const __ = window.__;

const innerSettings = {
  isOpened: false,
  postfixForAddLayout: constants.CALENDARS.LAYOUT_TYPES.PROJECT_CALENDAR,
  ganttId: null,
  accessToExeptions: null,
};

const innerTemplates = {
  getLayoutHeader() {
    return `${''
      + "<div class='header_icon'>"}${projectsModel.getActiveProjectData('is_jira') ? iconProjectJira : projectIcon}</div>`
      + '<p class=\'header_title manage\'>'
      + `<span>${__('calendar_layout_title', { projectname: projectsModel.getActiveProjectData('name') })}</span>`
      + '</p>'
      + '<div class=\'header_button white\'>'
      + `<div class='arrow'>${
        closeCross
      }</div>`
      + '</div>';
  },
  getBodyHeader(config) {
    return `${''
      + "<div class='body_text'>"
      + "<p class='title'>"}${config.title}</p>`
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
  getBodyToggleLayout(config) {
    return `${''
      + "<div class='body_text custom-days-toggle'>"
      + "<div class='body_text_row'>"
      + "<div class='title'>"
      + '<p>'}${config.title}</p>`
      + '</div>'
      + `<div class='btn_group' style="display: ${innerSettings.accessToExeptions ? '' : 'none'}">`
      + `<div class='custom-days-year ${config.tableView ? 'hidden' : ''}'>`
      + `<div class='custom-days-arrowLeft'>${icon_left_arrow}</div>`
      + `<div class='custom-days-yearText'>${config.currentYear}</div>`
      + `<div class='custom-days-arrowRight'>${icon_right_arrow}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? 'active' : ''} btn-table'>`
      + `<div class='btn-icon'>${tableIcon}</div>`
      + `<div class='btn-text'>${__('table')}</div>`
      + '</div>'
      + `<div class='btn ${config.tableView ? '' : 'active'} btn-calendar'>`
      + `<div class='btn-icon'>${calendarIcon}</div>`
      + `<div class='btn-text'>${__('calendar')}</div>`
      + '</div>'
      + '</div>'
      + '</div>'
      + `<p class='txt'>${config.txt}</p>`
      + '</div>';
  },
};

const innerHandlers = {
  initAppHandlers: _.once(() => {

  }),
  saveNewCustomDay(ganttId, customDay) {
    customDaysTemplatesModel.addNewCustomDay(customDay, ganttId, {
      type: 'baseTemplateByNewCustomDay',
      userCreate: 0,
      name: __('custom_days_default_project_template'),
    });

    this.showCustomDaysInTableView();
    $$(innerViews.addCustomDayButton.id).show();
    settingsCustomDays.handlers.hideAddingForm(innerSettings.postfixForAddLayout);
  },
  showAddingCustomDayForm() {
    settingsCustomDays.handlers.showAddingForm(innerSettings.postfixForAddLayout);
  },
  noExtensionLayout(webixUI) {
    const $$customDaysList = webixUI || $$(innerViews.customDaysList.id);

    if ($$customDaysList.count() > 0 || !checkAccessToFeature('project_calendar_exceptions')) {
      settingsCustomDays.handlers.hideNoExceptions(innerSettings.postfixForAddLayout);
    } else {
      settingsCustomDays.handlers.showNoExceptions(innerSettings.postfixForAddLayout);
    }
  },
  showCustomDaysInTableView() {
    const customDaysForProject = customDaysModel.getDaysByActiveProject(gantt.config.gantt_id);
    const $$customDaysList = $$(innerViews.customDaysList.id);

    $$customDaysList.clearAll();
    $$customDaysList.parse(customDaysForProject);
    innerHandlers.noExtensionLayout($$customDaysList);

    $$customDaysList.refresh();
  },
  initCalendarView() {
    settingsCustomDays.helpers.changeMainId('customDaysMainScrollView');
    $$(innerViews.main.id).show();
    $$('calendarLayoutBodyToggle').config.tableView = false;
    $$(innerViews.customDaysListLayout.id).hide();
    $$(innerViews.customDaysCalendarLayout.id).show();
    this.showCustomDaysInTableView();
  },
  hideAddingCustomDayForm() {
    settingsCustomDays.handlers.hideAddingForm(innerSettings.postfixForAddLayout);
  },
  showAddButton() {
    $$(innerViews.addCustomDayButton.id).show();
  },
  showAddingButtonAndHideForm() {
    innerHandlers.hideAddingCustomDayForm();
    innerHandlers.showAddButton();
  },
};

const innerViews = {
  main: {
    id: 'calendarExceptionsPopup',
    css: 'ganttpro-layout calendar-layout',
  },
  addCustomDayButton: {
    view: 'button',
    id: 'addCustomDayButton',
    label: __('custom_day_add_button'),
    width: 300,
    css: 'custom-days-add-button addCustomDayButton',
    height: 48,
    on: {
      onItemClick() {
        innerHandlers.showAddingCustomDayForm();
        this.hide();
      },
    },
  },
  upgradeRateButton: {
    view: 'button',
    id: 'upgradeRateButton',
    label: __('upgrade_rate_btn'),
    width: 162,
    css: 'custom-days-upgrade-button upgradeRateButton',
    height: 36,
    on: {
      onItemClick() {
        globalStore.dispatch('globalPopup/show', {
          type: 'paymentPopup',
        });
      },
    },
  },
  calendarLayoutBody: {
    id: 'calendarLayoutBody',
    css: 'body',
  },
  calendarLayoutBodyHeader: {
    id: 'calendarLayoutBodyHeader',
    view: 'template',
    autoheight: true,
    borderless: true,
    template: innerTemplates.getBodyHeader({
      title: __('calendar_layout_body_title'),
      txt: __('calendar_layout_body_txt'),
    }),
    onClick: {},
    on: {},
  },
  calendarLayoutBodyToggle: {
    id: 'calendarLayoutBodyToggle',
    view: 'template',
    css: 'calendarLayoutBodyToggle',
    height: 140,
    borderless: true,
    currentYear: null,
    tableView: false,
    template(item, common) {
      return innerTemplates.getBodyToggleLayout({
        title: __('calendar_toggle_layout_body_title'),
        txt: __('calendar_toggle_layout_body_txt'),
        currentYear: this.currentYear,
        tableView: this.tableView,
      });
    },
    onClick: {
      'btn-table': function () {
        this.config.tableView = true;
        if (this.config.tableView) {
          $$(innerViews.customDaysCalendarLayout.id).hide();
          const $$customDaysListLayout = $$(innerViews.customDaysListLayout.id);

          $$customDaysListLayout.show();
          $$customDaysListLayout.resize();
        }
        $$(innerViews.calendarLayoutBodyToggle.id).refresh();
      },
      'btn-calendar': function () {
        this.config.tableView = false;
        if (!this.config.tableView) {
          $$(innerViews.customDaysListLayout.id).hide();
          $$(innerViews.customDaysCalendarLayout.id).show();
        }
        $$(innerViews.calendarLayoutBodyToggle.id).refresh();
      },
      'custom-days-arrowLeft': function () {
        settingsCustomDays.helpers.changeYear('left', innerViews.calendarLayoutBodyToggle.id);
      },
      'custom-days-arrowRight': function () {
        settingsCustomDays.helpers.changeYear('right', innerViews.calendarLayoutBodyToggle.id);
      },
    },
    on: {},
  },
  customDaysCalendarLayout: {
    id: 'customDaysCalendarLayout',
  },
  templateManageButton: {
    id: 'templateManageButton',
    css: 'custom-days-button',
    view: 'template',
    height: 36,
    width: 289,
    maxWidth: 289,
    template() {
      return `${"<div class='custom-days-button-inner'>"
        + "<div class='custom-days-button-inner-text'>"}${__('templates')}</div>`
        + `<div class='custom-days-button-inner-icon'>${arrowDown}</div>`
        + '</div>';
    },
    onClick: {
      'custom-days-button': function () {
        $$(innerViews.templateManagePopup.id).show($$(innerViews.templateManageButton.id).getNode(), { x: 0, y: 5 });
        $$('templateManagePopup').getNode().querySelectorAll('.webix_list_item').forEach(item => {
          item.classList.remove('webix_selected');
        });
      },
    },
  },
  templateManagePopup: {
    id: 'templateManagePopup',
    css: 'template-manage-popup',
    view: 'popupWithoutPadding',
    width: 289,
    zIndex: 100,
    body: {
      view: 'list',
      css: 'custom-days-templates-menu',
      data: [
        { id: 'save_as', name: __('templates_save_as') },
        { id: 'choose_template', name: __('templates_choose') },
        { id: 'open_editor', name: __('templates_editor') },
      ],
      template: '#name#',
      select: true,
      borderless: true,
      on: {
        onItemClick(id, e, node) {
          const strategyObject = {
            save_as: settingsCustomDays.helpers.buttonSaveAsClick,
            choose_template: settingsCustomDays.helpers.initPopupWithSolutionsForProjectSettings,
            open_editor() {
              templatesEditor.init(gantt.config.gantt_id);
            },
          };

          strategyObject[id](id);
        },
        onBeforeRender() {
          this.config.height = this.count() * 36;
        },
      },
    },
  },
  customDaysListLayout: {
    id: 'customDaysListLayout',
  },
  customDaysList: {
    view: 'datatable',
    id: 'customDaysList',
    css: 'custom-days-list',
    width: 889,
    autoheight: true,
    header: false,
    scroll: true,
    borderless: true,
    editable: true,
    scrollY: true,
    scrollX: false,
    prerender: false,
    rowHeight: 66,
    navigation: false,
    data: [],
    columns: [
      {
        id: 'id',
        fillspace: true,
        header: false,
        template(object) {
          const name = calendarTemplates.getCustomDayName(object);
          const info = calendarTemplates.getCustomDayInfo(object);
          const type = calendarTemplates.getCustomDayType(object);

          return calendarTemplates.customDaysListItem(name, info, type);
        },
      },
    ],
    onClick: {
      'custom-days-list-item': function (event, data, node) {
        const customDayData = this.getItem(data.row);
        const path = event.path || (event.composedPath && event.composedPath()) || composedPath(event.target);

        if (_.some(path, item => _.includes(item.className, 'custom-days-list-item-remove'))) {
          webix.confirm({
            ok: __('common_yes'),
            cancel: __('common_no'),
            width: 420,
            text: __('custom_day_remove_message'),
            type: 'confirm-error',
          }).then(result => {
            if (result) {
              customDaysModel.deleteCustomDay(data.row);
            }
          });
        } else if (checkAccessToFeature('project_calendar_exceptions')) {
          settingsCustomDays.helpers.showCustomDayEditPopup({
            node, // .querySelector('.custom-days-list-item-edit')
            config: {},
            data: customDayData,
            workMode: innerSettings.postfixForAddLayout,
          });
        }

        if ($$('customPopupDayOption') && $$('customPopupDayOption').isVisible()) {
          node.classList.add('active');
        }
      },
    },
    on: {
      onBeforeRender() {
        if (this.count() > 0) {
          this.config.rowHeight = 66;
          this.config.height = this.count() * this.config.rowHeight;
        } else {
          this.config.rowHeight = 0;
          this.config.height = 'auto';
        }
      },
    },
  },
  daysAndHoursSettings: {
    id: 'daysAndHoursSettings',
    width: 889,
    cols: [
      {
        height: 76,
        // width: "100%",
        minWidth: 430,
        rows: [
          zoomDays.views.daysRangeLabel,
          zoomDays.views.displayOptionsListDays,
        ],
      },
      { width: 24 },
      {
        autoheight: true,
        // width: "100%",
        minWidth: 430,
        rows: [
          zoomHours.views.hoursRangeTitleLabel,
          zoomHours.views.hoursRangeLabel,
        ],
      },
    ],
  },
};

webix.ui(innerViews.templateManagePopup);

webix.ui({
  view: 'window',
  id: innerViews.main.id,
  css: innerViews.main.css,
  borderless: true,
  hidden: true,
  // header: false,
  head: false,
  // unClosable: true,
  zIndex: 31,
  position(state) {
    calculateMainWindowSize.apply(this, [state, true]);
    state.top = 0;
  },
  modal: false,
  move: false,
  body: {
    rows: [
      {
        css: 'header topHeaderShadow calendar-layout-header',
        view: 'template',
        height: 60,
        borderless: true,
        id: `${innerViews.main.id}header`,
        template: innerTemplates.getLayoutHeader,
        onClick: {
          header_button() {
            $$(innerViews.main.id).hide();
          },
        },
      },
      {
        view: 'scrollview',
        borderless: true,
        id: innerViews.calendarLayoutBody.id,
        css: innerViews.calendarLayoutBody.css,
        scroll: 'y',
        autoheight: true,
        body: {
          rows: [
            { height: 48 },
            innerViews.calendarLayoutBodyHeader,
            { height: 6 },
            {
              cols: [
                {},
                innerViews.daysAndHoursSettings,
                {},
              ],
            },
            { height: 30 },
            innerViews.calendarLayoutBodyToggle,
            {
              id: 'exceptionsMain',
              rows: [
                {
                  id: 'blockedExceptionsLayout',
                  css: 'blockedExceptionLayout',
                  hidden: true,
                  scroll: 'y',
                  autoheight: true,
                  rows: [
                    { height: 17 },
                    {
                      css: 'blockedExceptionLayout',
                      cols: [
                        { css: 'blockedExceptionLayout' },
                        {
                          view: 'template',
                          css: 'blockedExceptionPic',
                          scroll: 'y',
                          autoheight: true,
                          width: 887,
                          borderless: true,
                          template() {
                            const upgradeBtn = rights.account.isOwner() ? ` <div class="upgrade_button">${__('upgrade_rate_btn')}</div>` : '';

                            return `<div class="center_calendar_part"> 
                             <div class='pic-wrapper' style='width: 300px; height: auto'>
                            <img style='width: 100%' src=${GT.cdn}/imgs/project_calendar/exception.png>
                             </div>
                            <div class="description_block">
                             <div class="text_wrapper"> 
                             <span class="title"> 
                             ${__('calendar_access_title')} 
                             <span class="plan_label">${__('pro_plan_locale')}</span>
                             </span> 
                             <div class="description">
                                 <div class="description-text">
                                    ${__('calendar_access_desc')}
                                    <div class="learn_more">
                                        <a class="pricing_popup_link" href="${__('project_calendar_exceptions_learn_more')}" target="_blank">${__('help_read_more')}</a>             
                                            <div class="arrow-left">${arrowLeft}</div>
                                    </div>  
                                 </div> 
                             </div>
                             </div>
                             ${upgradeBtn}
                             </div>
                            </div>`;
                          },
                          onClick: {
                            upgrade_button() {
                              userExtAnalytics.log('pricing_deadlock_upgrade_clicked', { type: 'project_calendar_exceptions' });
                              const newPlanObj = getPlansObj('project_calendar_exceptions');
                              const userPlanId = user.accountPlanData.payment_plans_id;
                              const userPlan = user.paymentPlans.find(plan => plan.id === userPlanId);

                              globalStore.dispatch('globalPopup/show', {
                                type: 'upgradePlan',
                                title: 'upgrade_plan_popup_title',
                                currentPlan: userPlan,
                                newPlan: newPlanObj,
                                // userCount: user.subscription.user_count,
                                isAnnual: user.subscription.is_annual,
                              });
                            },
                            pricing_popup_link() {
                              userExtAnalytics.log('pricing_deadlock_learn_more_clicked', { type: 'project_calendar_exceptions' });
                            },
                          },
                        },
                        { css: 'blockedExceptionLayout' },
                      ],
                    },
                  ],
                },
                { height: 2 },
                {
                  id: 'accessExceptionsLayout',
                  hidden: true,
                  rows: [
                    {
                      id: 'accessExceptionsLayout-space',
                      height: 12,
                    },
                    {
                      id: 'accessExceptionsLayout2-space',
                      height: 2,
                    },
                    {
                      id: 'templateManageButton-space',
                      cols: [
                        {},
                        {
                          width: 889,
                          rows: [
                            {
                              cols: [
                                innerViews.templateManageButton,
                                {},
                              ],
                            },
                          ],
                        },
                        {},
                      ],
                    },
                    {
                      id: 'customDaysListLayout-space',
                      height: 12,
                    },
                    {
                      id: innerViews.customDaysListLayout.id,
                      // minHeight: 338,
                      hidden: true,
                      autoheight: true,
                      rows: [
                        {
                          id: 'customDaysList-space',
                          height: 12,
                        },
                        {
                          cols: [
                            {},
                            innerViews.customDaysList,
                            {},
                          ],
                        },
                        { height: 24 },
                        {
                          cols: [
                            {},
                            innerViews.addCustomDayButton,
                            settingsCustomDays.init.createAddForm(innerSettings.postfixForAddLayout),
                            {},
                          ],
                        },
                        settingsCustomDays.init.createNoExceptions(innerSettings.postfixForAddLayout),
                      ],
                    },
                    {
                      id: innerViews.customDaysCalendarLayout.id,

                      autoheight: true,
                      rows: [
                        {
                          cols: [
                            {},
                            settingsCustomDays.views.customDaysMainScrollView,
                            {},
                          ],
                        },
                        { height: 24 },
                      ],
                    },
                    { height: 48 },
                  ],
                },
              ],
            },
          ],
        },
        on: {
          onAfterScroll: () => {
            webix.callEvent('onClick', []);
          },
        },
      },
    ],
  },
  on: {
    onHide() {
      innerSettings.isOpened = false;
      if (routerHelper.isSingleProjectRoute()) { zoomDays.handlers.changeDays(); }
      app.trigger('popup:hide');
      $$('zoomHoursPopup').hide();
      webix.callEvent('onResize', []);
      if (routerHelper.isSingleProjectRoute()) { app.trigger('change:work:time'); }
    },
    onShow() {
      innerSettings.isOpened = true;
      app.trigger('popup:closeOther', 'calendarExceptionsPopup');
      webix.callEvent('onResize', []);
      settingsCustomDays.init.run(gantt.config.gantt_id);
    },
  },
});

projectsModel.data.attachEvent('updateProjectName', (id, name) => {
  $$(`${innerViews.main.id}header`).refresh();
});

app.on('popup:show', () => {
  $$(innerViews.main.id).hide();
});

app.on('app:route:changed', () => {
  $$(innerViews.main.id).hide();
});

app.on('popup:closeOther', id => {
  if (id !== innerViews.main.id && id !== 'notificationsTimeLineModal') {
    $$(innerViews.main.id).hide();
  }
});

app.on('body:resize', data => {
  $$(innerViews.main.id).resize();
});

app.on('leftSideBar:changedMode', data => {
  $$(innerViews.main.id).resize();
});

app.on('customDays:insert', () => {
  innerHandlers.showCustomDaysInTableView();
  $$(innerViews.customDaysListLayout.id).resize();
});

app.on('customDays:live:update', () => {
  innerHandlers.showCustomDaysInTableView();
});

app.on('customDays:update', () => {
  innerHandlers.showCustomDaysInTableView();
  $$(innerViews.customDaysListLayout.id).resize();
});

app.on('customDays:delete', () => {
  innerHandlers.showCustomDaysInTableView();
  $$(innerViews.customDaysListLayout.id).resize();
});

app.on('gantt:init:before', () => {
  !routerHelper.isListViewRoute() && settingsCustomDays.init.addViewToForm(innerSettings.postfixForAddLayout);
});

app.on('save:customDay:project', customDay => {
  innerHandlers.saveNewCustomDay(gantt.config.gantt_id, customDay);
});

app.on('cancel:customDay:project', () => {
  innerHandlers.hideAddingCustomDayForm();
  innerHandlers.showAddButton();
  innerHandlers.noExtensionLayout();
});

app.on('init:customDay:project', () => {
  customDaysTemplatesModel.initCustomDaysByGanttId(gantt.config.gantt_id);
  innerSettings.isOpened = false;
  innerHandlers.showCustomDaysInTableView();

  // outputObject.init();
});

app.on('project:archive', ganttId => {
  const activeProjectId = projectsModel.getActiveGanttId();

  if (activeProjectId === ganttId) {
    $$(innerViews.main.id).hide();
  }
});

const outputObject = {
  init() {
    innerSettings.accessToExeptions = checkAccessToFeature('project_calendar_exceptions');

    if (!innerSettings.isOpened) {
      $$(innerViews.customDaysListLayout.id).reconstruct();
      $$(innerViews.calendarLayoutBodyToggle.id).config.currentYear = null;
      innerHandlers.initCalendarView();
      $$(innerViews.calendarLayoutBodyToggle.id).refresh();
      $$(innerViews.customDaysCalendarLayout.id).reconstruct();
      $$(`${innerViews.main.id}header`).refresh();
      $$(innerViews.customDaysListLayout.id).resize();

      if (innerSettings.accessToExeptions) {
        $$('blockedExceptionsLayout').hide();
        $$('accessExceptionsLayout').show();
        $$('accessExceptionsLayout2-space').hide();
      } else {
        $$('accessExceptionsLayout').show();
        $$('blockedExceptionsLayout').show();
        $$('customDaysListLayout').show();
        $$('templateManageButton').hide();
        $$('accessExceptionsLayout-space').hide();
        $$('accessExceptionsLayout2-space').show();
        $$('addCustomDayButton').hide();
        $$('customDaysCalendarLayout').hide();
        $$('customDaysListLayout-space').hide();
        $$('customDaysList-space').hide();
        $$('templateManageButton-space').hide();
      }

      userExtAnalytics.log('project_calendar_open');
    } else {
      $$(innerViews.main.id).hide();
    }
  },
};

export default outputObject;
