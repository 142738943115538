var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "taskNameContainer",
      staticClass: "cell-task-name select_none",
      class: { crossout: _vm.crossout && !_vm.editMode },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.emitShowSettings($event)
        },
      },
    },
    [
      _c(
        "span",
        {
          class:
            ((_obj = {
              edit: _vm.editMode,
              not_allowed: !_vm.editable,
            }),
            (_obj["tooltip-gantt"] = _vm.isEllipsied),
            (_obj["editable"] = _vm.canEdit && _vm.editable),
            _obj),
          attrs: {
            "data-y": "-5",
            "data-html": _vm.isEllipsied && !_vm.editMode ? true : "",
          },
          on: { click: _vm.clickToEditTaskName },
        },
        [
          _c(
            "div",
            {
              class: { overdue: _vm.overdue },
              style: { opacity: _vm.cellOpecity },
            },
            [_vm._v("\n      " + _vm._s(_vm.taskName) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-container" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "taskNameEditInput",
                attrs: { maxlength: 250 },
                domProps: { value: _vm.inputValue },
                on: {
                  blur: _vm.inputBlur,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      ) {
                        return null
                      }
                      return _vm.closeEdit($event)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.saveTaskName($event)
                    },
                  ],
                  focus: function ($event) {
                    return $event.target.select()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputValue = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("sprite", {
                staticClass: "enter-arrow-icon",
                attrs: { name: "enter-2" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    return _vm.submitButtonMouseDown($event)
                  },
                  mouseup: function ($event) {
                    $event.stopPropagation()
                    return _vm.submitButtonMouseUp($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEllipsied
            ? _c("div", { staticClass: "tooltip-gantt-html" }, [
                _c("div", { staticClass: "tooltip-gantt-html-title" }, [
                  _vm._v("\n        " + _vm._s(_vm.taskName) + "\n      "),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.canShowSettings && !_vm.editMode
        ? _c(
            "div",
            {
              staticClass:
                "task-name-cell-icon-container  show-only-row-hover ai-center",
              class: { edit: _vm.editMode },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gantt_notification_tooltip tooltip-gantt ai-center",
                  attrs: { "data-html": "true", "data-id": _vm.item.id },
                },
                [
                  _c("sprite", {
                    staticClass: "add-info-icons",
                    attrs: { name: "info", size: 20 },
                    on: { click: _vm.emitShowSettings },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tooltip-gantt-html",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("show:settings")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "tooltip-gantt-html-title" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.taskName) + "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.item.note
                        ? _c("div", {
                            staticClass:
                              "tooltip-gantt-html-description ql-snow ql-editor",
                            domProps: { innerHTML: _vm._s(_vm.item.note) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { class: "tooltip_gantt_show_more" }, [
                        _c("a", [_vm._v(_vm._s(_vm.locale.more))]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("sprite", {
                staticClass: "add-info-icons",
                attrs: { name: "open", size: 20 },
                on: { click: _vm.emitShowSettings },
              }),
              _vm._v(" "),
              _vm.canDeleteTask
                ? _c("sprite", {
                    staticClass: "add-info-icons",
                    attrs: { name: "delete", size: 20 },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("delete", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }