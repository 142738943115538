const __ = window.__;

import customHelper from './../../helpers/custom';

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}

const innerHandlers = {
  deleteTaskConfirmation: function (taskData, callback) {
    const isJira = !!taskData.jira_key;
    const localeKey = "settings_msg_delete_task" + (isJira ? "_jira" : "");

    webix.confirm({
      ok: __("common_yes"),
      cancel: __("common_cancel"),
      text: __(localeKey, {taskName: customHelper.formatTaskName(taskData.text)}),
      type: "confirm-error",
      width: 420,
      callback: callback
    });
  },
  deleteProjectConfirmation: function (taskData, callback) {
    const isJira = !!taskData.jira_key;
    const localeKey = "settings_msg_delete_project" + (isJira ? "_jira" : "");
    webix.confirm({
      ok: __("common_yes"),
      cancel: __("common_cancel"),
      text: __(localeKey, {taskName: taskData}),
      type: "confirm-error",
      width: 420,
      callback: callback
    });
  },
};


export {
  innerHandlers,
};

export default {};
