/* eslint-disable */
import app from "./../app";
import notificationHelper from "./../helpers/notifications";

const __ = window.__;

const innerHandlers = {
  updateUserStatus: function (userStatus) {
    user.subscription = userStatus.subscription;
    user.team = userStatus.team;
  }
};

app.on('notify:payment:orderProcessing', function (data) {
  notificationHelper.show(
    __("notifications_orderProcessing"),
    "info",
    // 8000000
  );
});

app.on('notify:payment:orderFilled', function (data) {
  if (data && data.userStatus) {
    innerHandlers.updateUserStatus(data.userStatus);
  }

  if (data && data.statusTextKeyLocale) {
    notificationHelper.show(
      __("notifications_order_" + data.statusTextKeyLocale),
      "success",
    );
    return true;
  }

  notificationHelper.show(
    __("notifications_orderFilled"),
    "success",
  );
});

app.on('notify:payment:orderFailed', function (data) {
  if (data && data.userStatus) {
    innerHandlers.updateUserStatus(data.userStatus);
  }

  if (data && data.statusTextKeyLocale) {
    notificationHelper.show(
      __("notifications_order_" + data.statusTextKeyLocale),
      "info",
    );
    return true;
  }
  notificationHelper.show(
    __("notifications_orderFailed"),
    "error",
  );
});

export default true;
