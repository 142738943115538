var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$.project_overview },
    [
      _c("overview-toolbar", {
        ref: "overviewToolbar",
        attrs: {
          "overview-data": _vm.overviewData,
          baselines: _vm.baselines,
          "gantt-id": _vm.ganttId,
          "is-initialized": _vm.isInitialized,
        },
        on: {
          updateBaselines: _vm.updateBaselines,
          updateOverview: _vm.updateOverview,
          updateOverviewCharts: _vm.updateOverviewCharts,
        },
      }),
      _vm._v(" "),
      _vm.isInitialized
        ? _c(
            "div",
            { class: [_vm.$.overview_body, "gantt_scroll"] },
            [
              _c("overview-charts", {
                attrs: {
                  "overview-data": _vm.overviewData,
                  "baseline-data": _vm.baselineData,
                  "gantt-id": _vm.ganttId,
                },
                on: { openManageModal: _vm.openManageModal },
              }),
            ],
            1
          )
        : _c(
            "div",
            { class: [_vm.$.overview_body_skeleton, "gantt_scroll"] },
            [_c("OverviewSkeleton")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }