var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.$style.section }, [
    _c("div", { class: _vm.$style.section__left }, [
      _c("div", { class: _vm.$style.title__wrap }, [
        _c(
          "h1",
          {
            class: [
              _vm.$style.section__title,
              _vm.animateLeave && !_vm.animateEnter
                ? _vm.$style.animateLeave
                : "",
              _vm.animateEnter && !_vm.animateLeave
                ? _vm.$style.animateEnter
                : "",
            ],
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.currentStepConfig.title) + "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.$style.section__number,
              _vm.animateLeave && !_vm.animateEnter
                ? _vm.$style.animateLeave
                : "",
              _vm.animateEnter && !_vm.animateLeave
                ? _vm.$style.animateEnter
                : "",
            ],
          },
          [_vm._v("\n        " + _vm._s(_vm.currentStepIndex + 1) + "\n      ")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.section__right }, [_vm._t("right")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }