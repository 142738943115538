<template>
  <div
    v-if="value"
    ref="contextmenu"
    class="context-settings"
    :style="style"
    :id="'gp_autotest_listview_context_menu'"
  >
    <div
      class="settings-item"
      @click="settingsClick"
      :id="'gp_autotest_listview_context_menu_item_settings'"
    >
      <icon :name="'settings'" />
      <span class="settings-item--text"> {{ locale.setting }} </span>
    </div>
    <div
      v-if="canDeleteTask"
      class="settings-item text-red"
      @click="deleteClick"
      :id="'gp_autotest_listview_context_menu_item_delete'"
    >
      <icon :name="'dustbin'" />
      <span class="settings-item--text"> {{ locale.delete }} </span>
    </div>
  </div>
</template>

<script>
import icon from './icons/icon.vue';
import dropPopup from './dropPopup.vue';
import rights from '../../../components/rights';

export default {
  name: 'MoreCell',
  components: { icon, dropPopup },
  props: {
    info: { type: Object, required: true }, // {item, top, left}
    value: { type: [Object || null], required: false, default: false },
  },
  data() {
    return {
      locale: {
        setting: __('settings_task_settings'),
        delete: __('common_delete'),
      },
      isShow: false,
    };
  },
  computed: {
    canDeleteTask() {
      return rights.project.hasRight(this.info.item.gantt_id, 'delete_task');
    },
    style() {
      const style = {
        top: `${this.info.top}px`,
        left: `${this.info.left}px`,
        width: `${this.info.width}px`,
      };

      const isOverRight = (this.info.left + this.info.width + 0) > document.body.clientWidth;

      if (isOverRight) {
        style.right = '0px';
        style.left = 'auto';
      }

      const isOverBottom = this.info.top + (75 + 30) > document.body.clientHeight;

      if (isOverBottom) {
        style.bottom = `${document.body.clientHeight - this.info.top}px`;
        style.top = 'auto';
      }

      return style;
    },
  },
  mounted() {
    document.addEventListener('click', this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    settingsClick() {
      this.$emit('menu:settings', this.info.item);
      this.$emit('input', null);
    },
    deleteClick() {
      const component = this;

      component.$emit('menu:delete', component.info.item);
      component.$emit('input', null);
    },

    documentClick(e) {
      if (!this.$refs.contextmenu) return;

      if (!this.$refs.contextmenu.contains(e.target)) {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style scoped lang="less">
.context-settings{
  position: fixed;
  background-color: #fff;
  z-index:  8;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 10%);
  border: 1px solid #efefef;
}
.settings-item {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  color: #424242;
  cursor: pointer;
  widows: 236px;
  transition: background-color 0.3s;
  font-size: 12px;
  font-family: "Lato-Regular", sans-serif;

  &--text {
    margin-left: 12px;
    // margin-top: 4px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  &:hover {
    background-color: #f3f3f3;
  }
}

.text-red {
  color: #f44336;
}
</style>
