/* eslint-disable */
"use strict";

module.exports = {
  CUSTOM_COLUMN_NAMES: {
    STATUS: {
      ID: 1,
      OPEN: 1,
      IN_PROGRESS: 2,
      CLOSE: 3
    },
    PRIORITY: 2,
  },
  
  FEATURES : {
    STATUS : {
      NOT_SELECTED: 0,
      SUCCESS: 1,
      ERROR: 2,
      IN_PROGRESS: 3
    },
    TIMEOUT: 25000,
    EXECUTION_LIMIT_MS: 100,
    HASH : "8c1dfbc0397b4ed8475503a29327f317",
    COMPONENTS : {
      projectsWithoutOwner : {
        DB : "temp_features_projects_without_owner",
      }
    }
  },
  
  TASK_NOTIFICATION: {
    MAX_DELAY: 7
  },
  
  INTEGRATION : {
    CALENDAR: {
      LANG: {
        en : "Open task in GanttPRO",
        ru : "Открыть задачу в GanttPRO",
        es : "Abrir tarea en GanttPRO",
        de : "Aufgabe in GanttPRO öffnen",
        pt : "Abrir tarefa no GanttPRO",
        kr : "GanttPRO에서 작업 열기"
      }
    }
  },
  
  ROLES: {
    OWNER: 1,
    ADMIN: 4,
    MEMBER: 3,
    DEFAULT_TYPE : {
      OWNER : 'OWNER',
      ADMIN : 'ADMIN',
      MEMBER_EDIT : 'MEMBER_EDIT',
      MEMBER : 'MEMBER',
      MEMBER_EDIT_COST : 'MEMBER_EDIT_COST',
      MEMBER_COST : 'MEMBER_COST',
    },
    ACCOUNT : {
      OWNER : '1023051110230543',
      ADMIN : '1020005910230543',
      MEMBER : '0000000000000032',
    },
    PROJECT : {
      OWNER : '102305091023',
      ADMIN : '102305091023',
      MEMBER_EDIT : '102303970967',
      MEMBER : '000103980896',
      MEMBER_OWN_TASKS : '000003980768',
      COMMENTATOR : '000103920897',
      //MEMBER_EDIT_COST : '102305090583',
      //MEMBER_COST : '000105100512',
    },
    
    PLANS_PROJECTS_ROLES : {
      12 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT','MEMBER_OWN_TASKS','COMMENTATOR'],
      14 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT'],
      15 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT'],
      16 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT','MEMBER_OWN_TASKS','COMMENTATOR'],
      17 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT','MEMBER_OWN_TASKS','COMMENTATOR'],
      18 : ['OWNER','ADMIN','MEMBER','MEMBER_EDIT','MEMBER_OWN_TASKS','COMMENTATOR'],
    },
    
    BITS : {
      ACCOUNT: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35], // account bits for check
      PROJECT: [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29], // project bits for check
    },

    // For PaymentSubscription
    OPTIONAL_ROLES: [
      {
        name: 'MEMBER_OWN_TASKS',
        bit: 50
      },
      {
        name: 'COMMENTATOR',
        bit: 51
      }
    ]

  },
  TEAM_USER_STATUS: {
    ACTIVE: 1,
    INACTIVE: 0
  },
  CURRENCY: [
    {
      "ID": "USD",
      "NAME": "US Dollar",
      "VALUE": "$"
    },
    {
      "ID": "EUR",
      "NAME": "Euro",
      "VALUE": "€"
    },
    {
      "ID": "JPY",
      "NAME": "Japanese Yen",
      "VALUE": "¥"
    },
    {
      "ID": "GBP",
      "NAME": "British Pound",
      "VALUE": "£"
    },
    {
      "ID": "CAD",
      "NAME": "Canadian Dollar",
      "VALUE": "C$"
    },
    {
      "ID": "AUD",
      "NAME": "Australian Dollar",
      "VALUE": "A$"
    },
    {
      "ID": "MXN",
      "NAME": "Mexican Peso",
      "VALUE": "MX$"
    },
    {
      "ID": "BRL",
      "NAME": "Brazilian Real",
      "VALUE": "R$"
    },
    {
      "ID": "KRW",
      "NAME": "South Korean Won",
      "VALUE": "₩"
    },
    {
      "ID": "CNY",
      "NAME": "Chinese Yuan",
      "VALUE": "¥"
    }
  ],
  NUMBER_FORMATS: [
    { ID: 'period-comma', VALUE: '1.234,56' },
    { ID: 'comma-period', VALUE: '1,234.56'},
    { ID: 'space-comma', VALUE: '1 234,56' },
    { ID: 'space-period', VALUE: '1 234.56' },
    { ID: 'plain-comma', VALUE: '1234,56' },
    { ID: 'plain-period', VALUE: '1234.56' },
  ],
  USER: {
    DEFAULT_FORMAT_NUMBER : 'comma-period',
    DEFAULT_FORMAT_CURRENCY : 'USD',
  },
  DEFAULT_TEAM_NAMES: {
    RU: "Моя команда",
    EN: "My Team"
  },
  RESOURCE_TYPES: {
    REAL: 'real',
    MATERIAL: 'material'
  },
  PAYMENT_STATUSES: {
    ORDER_CHARGED: {
      CODE: 1,
      TEXT: "OrderCharged"
    },
    ORDER_REFUNDED: {
      CODE: 2,
      TEXT: "OrderRefunded"
    },
    ORDER_CHARGED_BACK: {
      CODE: 3,
      TEXT: "OrderChargedBack"
    },
    ORDER_DECLINED: {
      CODE: 4,
      TEXT: "OrderDeclined"
    },
    ORDER_PARTIALLY_REFUNDED: {
      CODE: 5,
      TEXT: "OrderPartiallyRefunded"
    },
    SUBSCRIPTION_CHARGE_SUCCEED: {
      CODE: 6,
      TEXT: "SubscriptionChargeSucceed"
    },
    SUBSCRIPTION_CHARGE_FAILED: {
      CODE: 7,
      TEXT: "SubscriptionChargeFailed"
    },
    SUBSCRIPTION_SUSPENDED: {
      CODE: 8,
      TEXT: "SubscriptionSuspended"
    },
    SUBSCRIPTION_RENEWED: {
      CODE: 9,
      TEXT: "SubscriptionRenewed"
    },
    SUBSCRIPTION_TERMINATED: {
      CODE: 10,
      TEXT: "SubscriptionTerminated"
    },
    SUBSCRIPTION_FINISHED: {
      CODE: 11,
      TEXT: "SubscriptionFinished"
    },
    ORDER_PROCESSING: {
      CODE: 110,
      TEXT: "OrderProcessing"
    },
    PARTNER_ACTIVATE: {
      CODE: 8,
      TEXT: "PartnerActivate"
    },
    PARTNER_DEACTIVATE: {
      CODE: 8,
      TEXT: "PartnerDeactivate"
    },
    TRIAL_ACTIVE: {
      CODE: 8,
      TEXT: "TrialActive",
    }
  },
  EVENT_TYPES: {
    SUBSCRIPTION_CHANGED: 'SUBSCRIPTION_CHANGED',
    SUBSCRIPTION_RENEWED: 'SUBSCRIPTION_RENEWED',
    SUBSCRIPTION_SUSPENDED: 'SUBSCRIPTION_SUSPENDED',
    OWNER_CHANGED: 'OWNER_CHANGED',
    USERS_NUMBER_CHANGED: 'USERS_NUMBER_CHANGED',
    COMMENT_ADDED: 'COMMENT_ADDED',
    EMAIL_CHANGED: 'EMAIL_CHANGED',
    EXPIRATION_DATE_CHANGED: 'EXPIRATION_DATE_CHANGED',
    FREE_SUBSCRIPTION: 'FREE_SUBSCRIPTION',
    PRICE_PER_USER_CHANGED: 'PRICE_PER_USER_CHANGED',
  },
  PLANS: {
    FREE: 0,
    FOREVER_FREE: 1,
    ESSENTIAL: 2,
    TEAM_5: 4,
    TEAM_10: 5,
    TEAM_15: 6,
    TEAM_25: 6,
    TEAM_TRIAL: 12,
    GANTT_PRO: 13,
    BASE: 14,
    PRO: 15,
    BUSINESS: 16,
    ENTERPRISE_TEAM: 17,
    ENTERPRISE: 18

  },

  PAYMENT_TYPE : {
    UPGRADE : "upgrade",
    DOWNGRADE : "downgrade"
  },

  PAYMENT_PROVIDER: {
    GANTTPRO: 'ganttpro',
    CHECKOUT: '2checkout',
    PAYPRO: 'paypro',
  },
  CHECKOUT_PAYMENT: {
    SUBSCRIPTION_STATUS: {
      ACTIVE: 'ACTIVE',
      DISABLED: 'DISABLED',
      CANCELED: 'CANCELED',
    },
    ORDERSTATUS: {
      PENDING: 'PENDING',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      PAYMENT_AUTHORIZED: 'PAYMENT_AUTHORIZED',
      SUSPECT: 'SUSPECT',
      INVALID: 'INVALID',
      COMPLETE: 'COMPLETE',
      REFUND: 'REFUND',
      REVERSED: 'REVERSED',
      PURCHASE_PENDING: 'PURCHASE_PENDING',
      PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
      CANCELED: 'CANCELED',
    },
    MESSAGE_TYPE: {
      AUTH: 'AUTH',
      PENDING: 'PENDING',
      REFUND: 'REFUND',
    },
    REFUND_TYPE: {
      TOTAL: 'TOTAL',
      PARTIAL: 'PARTIAL',
    },
    ORDERFLOW: { // The type of ordering flow used for placing the order.
      PURCHASE_ORDER: 'PURCHASE_ORDER',
      REGULAR: 'REGULAR',
    },
    IPN_LICENSE_TYPE: { // Array with the type of subscription purchased for each product.
      REGULAR: 'REGULAR',
      RENEWAL: 'RENEWAL',
      UPGRADE: 'UPGRADE',
      TRIAL: 'TRIAL',
    },
    IPN_LICENSE_LIFETIME: { // Array indicating the products shoppers purchased opting for the lifetime/evergreen option
      YES: 'YES',
      NO: 'NO',
    },
    API_METHODS: {
      GetSubscriptionStatus: "https://api.2checkout.com/rest/6.0/subscriptions/",
    }
  },
  PAYMENT_API_METHODS: {
    Suspend: "https://store.payproglobal.com/api/Subscriptions/Suspend",
    Renew: "https://store.payproglobal.com/api/Subscriptions/Renew",
    GetSubscriptionStatus: "https://store.payproglobal.com/api/Subscriptions/GetSubscriptionStatus",
    GetSubscriptionDetails: "https://store.payproglobal.com/api/Subscriptions/GetSubscriptionDetails",
    ChangeRecurringPrice: "https://store.payproglobal.com/api/Subscriptions/ChangeRecurringPrice",
    DoRecurringPayment: "https://store.payproglobal.com/api/Subscriptions/DoRecurringPayment",
    ApplyDiscount: "https://store.payproglobal.com/api/Subscriptions/ApplyDiscount",
    ChangeNextPaymentDate: "https://store.payproglobal.com/api/Subscriptions/ChangeNextPaymentDate",
    ChangeBillingPeriod: "https://store.payproglobal.com/api/Subscriptions/ChangeBillingPeriod",
    ChangeName: "https://store.payproglobal.com/api/Subscriptions/ChangeName",
    ChangeCustomFields: "https://store.payproglobal.com/api/Subscriptions/ChangeCustomFields",
    GetOrderDetails: "https://store.payproglobal.com/api/Orders/GetOrderDetails",
    GetOnetimeLoginEmail: "https://store.payproglobal.com/api/Customers/SendOnetimeLoginEmail"
  },
  PAYMENT_MESSAGES: {
    RenewForUpgrade: "Renew for upgrade subscription",
    RenewDefault: "User renew subscription",
    RenewFromAdmin: "User renew subscription from admin panel",
    SuspendDefault: "User suspend subscription",
    SuspendFromAdmin: "User suspend subscription from admin panel",
  },
  LOCALES: {
    PLAN_NAME: {
      'en': { USER: 'user', USERS: 'users', PREFIX: 'GanttPRO license for', POSTFIX_ANNUAL: 'Annual', POSTFIX_MONTHLY: 'Monthly', MONTHS: 'months', YEARS: 'years', TRIAL: "Trial period" },
      'ru': { USER: 'пользователя', USERS: 'пользователей', PREFIX: 'Лицензия на GanttPRO для', POSTFIX_ANNUAL: 'Годовая', POSTFIX_MONTHLY: 'Месячная', MONTHS: 'месяцев', YEARS: 'года', TRIAL: "Пробный период" },
      'es': { USER: 'usuario', USERS: 'usuarios', PREFIX: 'Licencia de GanttPRO para', POSTFIX_ANNUAL: 'Anual', POSTFIX_MONTHLY: 'Mensual', MONTHS: 'meses', YEARS: 'años', TRIAL: "Período de prueba" },
      'de': { USER: 'Benutzer', USERS: 'Benutzer', PREFIX: 'GanttPRO-Lizenz für', POSTFIX_ANNUAL: 'Jährlich', POSTFIX_MONTHLY: 'Monatlich', MONTHS: 'Monate', YEARS: 'Jahre', TRIAL: "Probezeit" },
      'pt': { USER: 'usuário', USERS: 'usuários', PREFIX: 'Licença do GanttPRO para', POSTFIX_ANNUAL: 'Anual', POSTFIX_MONTHLY: 'Mensalmente', MONTHS: 'meses', YEARS: 'anos', TRIAL: "Período de teste" },
      'fr': { USER: 'utilisateur', USERS: 'utilisateurs', PREFIX: 'Licence GanttPRO pour', POSTFIX_ANNUAL: 'Annuel', POSTFIX_MONTHLY: 'Mensuel', MONTHS: 'mois', YEARS: 'ans', TRIAL: "Période d'essai" },
      'it': { USER: 'utente', USERS: 'utenti', PREFIX: 'Licenza GanttPRO per', POSTFIX_ANNUAL: 'Annuale', POSTFIX_MONTHLY: 'Mensile', MONTHS: 'mesi', YEARS: 'anni', TRIAL: "Periodo di prova" },
      'kr': { USER: '사용자', USERS: '사용자', PREFIX: 'GanttPRO 라이센스', POSTFIX_ANNUAL: '연간', POSTFIX_MONTHLY: '월간', MONTHS: '개월', YEARS: '년', TRIAL: "시험 기간" },
      //Эти переводы для польского (pl), китайского (упрощенного, zh), японского (ja), хинди (hi), турецкого (tr), нидерландского (nl), шведского (sv), вьетнамского (vi) и украинского (uk) языков. Все они используют письмо слева направо.
      'pl': { USER: 'użytkownik', USERS: 'użytkownicy', PREFIX: 'Licencja GanttPRO dla', POSTFIX_ANNUAL: 'Roczny', POSTFIX_MONTHLY: 'Miesięczny', MONTHS: 'miesiące', YEARS: 'lata', TRIAL: "Okres próbny" },
      'zh': { USER: '用户', USERS: '用户', PREFIX: 'GanttPRO 许可给', POSTFIX_ANNUAL: '年度', POSTFIX_MONTHLY: '每月', MONTHS: '个月', YEARS: '年', TRIAL: "试用期" },
      'ja': { USER: 'ユーザー', USERS: 'ユーザー', PREFIX: 'GanttPRO のライセンス', POSTFIX_ANNUAL: '年間', POSTFIX_MONTHLY: '月間', MONTHS: 'ヶ月', YEARS: '年', TRIAL: "試用期間" },
      'hi': { USER: 'उपयोगकर्ता', USERS: 'उपयोगकर्ताओं', PREFIX: 'GanttPRO लाइसेंस', POSTFIX_ANNUAL: 'वार्षिक', POSTFIX_MONTHLY: 'मासिक', MONTHS: 'महीने', YEARS: 'साल', TRIAL: "परीक्षण अवधि" },
      'tr': { USER: 'kullanıcı', USERS: 'kullanıcılar', PREFIX: 'GanttPRO lisansı için', POSTFIX_ANNUAL: 'Yıllık', POSTFIX_MONTHLY: 'Aylık', MONTHS: 'ay', YEARS: 'yıl', TRIAL: "Deneme süresi" },
      'nl': { USER: 'gebruiker', USERS: 'gebruikers', PREFIX: 'GanttPRO licentie voor', POSTFIX_ANNUAL: 'Jaarlijks', POSTFIX_MONTHLY: 'Maandelijks', MONTHS: 'maanden', YEARS: 'jaar', TRIAL: "Proefperiode" },
      'sv': { USER: 'användare', USERS: 'användare', PREFIX: 'GanttPRO-licens för', POSTFIX_ANNUAL: 'Årlig', POSTFIX_MONTHLY: 'Månatlig', MONTHS: 'månader', YEARS: 'år', TRIAL: "Prova period" },
      'vi': { USER: 'người dùng', USERS: 'người dùng', PREFIX: 'Giấy phép GanttPRO cho', POSTFIX_ANNUAL: 'Hàng năm', POSTFIX_MONTHLY: 'Hàng tháng', MONTHS: 'tháng', YEARS: 'năm', TRIAL: "Thời gian dùng thử" },
      'uk': { USER: 'користувач', USERS: 'користувачі', PREFIX: 'Ліцензія GanttPRO для', POSTFIX_ANNUAL: 'Щорічний', POSTFIX_MONTHLY: 'Щомісячний', MONTHS: 'місяців', YEARS: 'років', TRIAL: "Пробний період" }
    },
    PLAN_TYPE: {
      'en': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'ru': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'es': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'de': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'pt': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'fr': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'it': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'kr': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'pl': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'zh': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'ja': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'hi': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'tr': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'nl': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'sv': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'vi': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' },
      'uk': { 14: 'Basic', 15: 'PRO', 16: 'Business', 17: 'Team (Enterprise)', 18: 'Enterprise' }
    }
  },
  ERROR_EMAILS: [
    'dudin@xbsoftware.com',
    'dmedvedev@xbsoftware.com',
    'support@ganttpro.com'
  ],
  AUTO_SCHEDULING_ERROR_EMAILS: [
    'dmedvedev@xbsoftware.com',
  ],
  BILLING_PERIOD_TYPES: {
    DAY: 1,
    WEEK: 2,
    MONTH: 3,
    YEAR: 4
  },
  NOTIFICATION_ACTION_TYPE: {
    START_TASK: 8,
    DEADLINE: 9,
    EMAIL: 10,
    TEAM_INVITE: 11,
    END_TASK: 12,
    COMMENT_ADD: 3,
    RESOURCE_ASSIGN_TO_TASK : 2,
    ADD_ATTACHMENT : 4,
  },
  DEFAULT_USER_PHOTO: "/imgs/default/default-ava.svg", // mb new ava
  PAYMENT_USERS_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  TASK_FIELDS_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  CUSTOM_DAYS_DATE_FORMAT: "YYYY-MM-DD",
  NOT_REMOVED_STATUS: 0,
  REMOVED_STATUS: 1,
  IS_ACTIVE:1,
  RESOURCE_TIME_TYPE: 1,
  RESOURCE_ITEM_TYPE: 2,
  RESOURCE_COST_TYPE: 3,
  STATUSES : {
    ACTIVE : 1,
    NOT_ACTIVE : 0
  },
  CUSTOM_COLUMNS_TYPES: {
    SELECT: {
      ID: 1
    },
    TEXT: {
      ID: 2
    },
    NUMBER: {
      ID: 3
    },
    DATE: {
      ID: 4
    },
    CHECKBOX: {
      ID: 5
    },
    COLOR: {
      ID: 6
    },
    MULTISELECT: {
      ID: 7
    },
    RESOURCES: {
      ID: 8
    },
    TAGS: {
      ID: 9
    }
  },
  
  
  HISTORY: {
    CUSTOM_DAYS: {
      ACTION_TYPES: {
        UPDATE: 11,
        DELETE: 12,
        CREATE: 13,
      },
      LOCALES: {
        UPDATE: 'history_event_custom_day_updated',
        DELETE: 'history_event_custom_day_deleted',
        CREATE: 'history_event_custom_day_added',
      },
    },
    CUSTOM_DAYS_TEMPLATES: {
      ACTION_TYPES: {
        CLEAR: 21,
        UPDATE: 22,
        CREATE: 23,
      },
      LOCALES: {
        CLEAR: 'history_event_custom_day_template_cleared',
        UPDATE: 'history_event_custom_day_template_changed',
        CREATE: 'history_event_custom_day_template_added',
      },
    },
    LINKS: {
      ACTION_TYPES: {
        UPDATE: 31,
        DELETE: 32,
        CREATE: 33,
        UPDATE_PREDECESSOR: 34
      },
      LOCALES: {
        UPDATE: 'history_event_link_updated',
        DELETE: 'history_event_link_deleted',
        CREATE: 'history_event_link_added',
        UPDATE_PREDECESSOR: 'history_event_predecessor_column_updated'
      },
    },
    PROJECT: {
      ACTION_TYPES: {
        UPDATE: 41,
        CREATE: 42,
        VIEW: 43,
        ARCHIVE: 44,
        UNARCHIVE: 45,
      },
      LOCALES: {
        UPDATE: 'history_event_project_updated',
        CREATE: 'history_event_project_added',
        VIEW: 'history_event_project_viewed',
        ARCHIVE: 'history_event_project_archived',
        UNARCHIVE: 'history_event_project_unarchived',
      },
    },
    ASSIGNEE: {
      ACTION_TYPES: {
        UPDATE: 51,
        DELETE: 52,
        CREATE: 53,
      },
      LOCALES: {
        UPDATE: 'history_event_task_assign_changed',
        DELETE: 'history_event_task_assign_removed',
        CREATE: 'history_event_task_assign_added',
      },
    },
    TASKS: {
      ACTION_TYPES: {
        CHANGE_RESOURCE: 51,
        UPDATE: 61,
        DELETE: 62,
        CREATE: 63,
        COPY: 111,
      },
      LOCALES: {
        UPDATE: "history_event_task_updated",
        DELETE: "history_event_task_deleted",
        CREATE: "history_event_task_added",
        CHANGE_RESOURCE: 'history_event_task_resources_changed',
        COPY: "history_event_task_copy",
      },
    },
    TASKS_PACKAGE: {
      ACTION_TYPES: {
        PROGRESS_CHANGED: 71,
        AUTO_SCHEDULING: 72,
        AUTO_SCHEDULING_JIRA: 73,
        PROJECT_MOVE: 81,
        MOVE_TASKS: 82,
        DRAG_PROJECT_DATES: 83,
        CORRECT_DATES: 74,
      },
      LOCALES: {
        PROGRESS_CHANGED: "history_event_progress_changed",
        AUTO_SCHEDULING: "history_event_auto_scheduling",
        AUTO_SCHEDULING_JIRA: "history_event_auto_scheduling",
        PROJECT_MOVE: "history_event_move_project",
        MOVE_TASKS: "history_event_move_tasks",
        DRAG_PROJECT_DATES: "history_event_drag_project_dates",
        CORRECT_DATES: "history_event_correct_dates",
      },
    },
    REPORTER: {
      ACTION_TYPES: {
        UPDATE: 131,
        DELETE: 132,
        CREATE: 133,
      },
      LOCALES: {
        UPDATE: "history_event_task_reporter_changed",
        DELETE: 'history_event_task_reporter_removed',
        CREATE: "history_event_task_reporter_added",
      },
    },
    CUSTOM_COLUMNS: {
      ACTION_TYPES: {
        UPDATE: 101,
      },
      LOCALES: {
        UPDATE: 'history_event_task_custom_column_value_changed_',
      },
    },
    USER_CUSTOM_COLUMNS: {
      ACTION_TYPES: {
        CREATE: 121,
        UPDATE: 122,
        DELETE: 123,
        UPDATE_TASK_VALUE: 64
      },
      LOCALES: {
        CREATE: 'history_event_user_custom_column_created',
        UPDATE: 'history_event_user_custom_column_changed',
        DELETE: 'history_event_user_custom_column_deleted',
        UPDATE_TASK_VALUE: 'history_event_user_custom_column_task_value_changed'
      }
    },
    TIME_TRACKING: {
      ACTION_TYPES: {
        CREATE: 141,
        UPDATE: 142,
        DELETE: 143
      },
      LOCALES: {
        CREATE: 'history_event_time_tracking_log_created',
        UPDATE: 'history_event_time_tracking_log_changed',
        DELETE: 'history_event_time_tracking_log_deleted'
      }
    },
    MASS_CHANGE: {
      ACTION_TYPES: {
        UPDATE: 151
      },
      LOCALES: {
        UPDATE: 'history_event_mass_change_updated'
      }
    },
    CASCADE_SORT: {
      ACTION_TYPES: {
        UPDATE: 161
      },
      LOCALES: {
        UPDATE: 'history_event_cascade_sort_updated'
      }
    },
    RESOURCES: {
      ACTION_TYPES: {
        CONVERT: 171,
      },
      LOCALES: {
        CONVERT: 'history_event_resource_converted',
      },
    },
  },
  DEFAULT_TYPE_COLORS: {
    TASK: 1,
    GROUP: 10,
    PROJECT: 12,
    MILESTONE: 16,
    ESTIMATION: 18
  },
  RESOURCES: {
    DEFAULT_WORK_TIME: {
      HOURS_COUNT: 8,
      WORKING_DAYS: [1, 2, 3, 4, 5]
    }
  },
  PROJECT : {
    DEFAULT_TEMPLATE : 1673447383950 // default template (gantt_id) for making new project by API
  },
  PROJECT_DEFAULT_STATUSES: [{
    value: 'no_status_locale',
    color: 17,
    order: 1,
    is_default: 1,
  },
  {
    value: 'on_track_locale',
    color: 10,
    order: 2,
    is_default: 0,
  },
  {
    value: 'at_risk_locale',
    color: 11,
    order: 3,
    is_default: 0,
  },
  {
    value: 'off_track_locale',
    color: 15,
    order: 4,
    is_default: 0,
  }],
  PROGRESS_BAR : {
    BLOCK_TYPE: {
      FULL: 'full',
      PROJECT: 'project'
    },
    LOADER_TYPE: {
      SPINNER: 'spinner',
      PROGRESS: 'progress',
    },
    PROGRESS_MESSAGE : {
      STARTED: "progress_started",
      STARTED_IMPORT: "progress_import_started",
      UPDATED: "progress_updated",
      DONE: "progress_done",
      FAILED: "progress_failed"
    },
    STATUS: {
      STARTED: 1,
      UPDATE: 2,
      DONE: 3,
      FAIL: 4,
    }
  },
  ATTACHMENT_TYPE_KEYS: {
    FILE: 1,
    GOOGLE_DRIVE: 2,
    ONE_DRIVE: 3,
  },
  TEAM_DELETE_STEPS: {
    MAIL_1: 'mail_1',
    MAIL_2: 'mail_2',
    TO_DELETE: 'to_delete',
    DELETED: 'deleted',
  },

  // CL - Chile
  // CO - Colombia
  // JM - Jamaica
  // UY - Uruguya
  // PA - Panama
  // BO - Bolivia
  // EC - Ecuador
  // HN - Honduras
  // PY - Paraguay
  // GT - Guatemala
  // VE - Venezuela
  // SV - El Salvador
  // CR - Costa Rica
  // PE - Peru
  // DO - Dominican Republic
  // CU - Cuba
  // AR - Argentina
  // NI - Nicaragua
  // BR - Brazil
  // HT - Haiti

  DEMO_TARGET_COUNTRY_EXCEPTIONS: ['cl', 'co', 'jm', 'uy', 'pa', 'bo', 'ec', 'hn', 'py', 'gt', 've', 'sv', 'cr', 'pe', 'do', 'cu', 'ar', 'ni', 'br', 'ht'],
};
