<template>
  <div :class="$.resources">
    <account-resources
      v-if="isAccount"
      :resources="accountResources"
      :account-roles="accountRoles"
      :project-roles="projectRoles"
      :projects="projects"
      :account-role-change-access="accountRoleChangeAccess"
      :remove-resources-access="removeResourcesAccess"
      :resource-calendar-access="resourceCalendarAccess"
      :pricing-access-to-resource-calendar="pricingAccessToResourceCalendar"
      :create-and-invite-resources-access="createAndInviteResourcesAccess"
      :pricing-access-to-cost-settings="pricingAccessToCostSettings"
      :is-over-subscription="isOverSubscription"
      :me-is-account-owner="meIsAccountOwner"
      @onSendInvites="onSendInvites"
      @onCreateResource="onCreateResource"
      @onChangeCostType="onChangeCostType"
      @onChangeCost="onChangeCost"
      @onChangeProjectRole="onChangeProjectRole"
      @updateResource="updateResource"
      @upgradePlanShowFromResourcesForce="upgradePlanShowFromResourcesForce"
      @showRemovePopup="showRemovePopup"
      @onChangeOwner="onChangeOwner"
      @onChangeAccountRole="onChangeAccountRole"
      @onChangeUserAvatar="onChangeUserAvatar"
      @showConvertPopup="showConvertPopup"
      @assignToProjects="multiAssignToProjects"
    />
    <project-resources
      v-if="isProject"
      :project-resources="projectResources"
      :all-resources="accountResources"
      :account-roles="accountRoles"
      :project-roles="projectRoles"
      :has-right-to-cost-settings="hasRightToCostSettings"
      :pricing-access-to-cost-settings="pricingAccessToCostSettings"
      :create-and-invite-resources-access="createAndInviteResourcesAccess"
      :me-is-king="meIsKing"
      :me-is-project-owner="meIsProjectOwner"
      :account-role-change-access="accountRoleChangeAccess"
      :project-role-change-access="projectRoleChangeAccess"
      @onSendInvites="onSendInvites"
      @onCreateResource="onCreateResource"
      @addResourcesToProject="addResourcesToProject"
      @onChangeCostType="onChangeCostType"
      @onChangeCost="onChangeCost"
      @onChangeProjectRole="onChangeProjectRole"
      @onChangeOwner="onChangeOwner"
      @showRemovePopup="showRemovePopup"
      @showConvertPopup="showConvertPopup"
    />
    <transfer-ownership-modal-vue
      :show="isShowChangeOwnerPopup"
      :config="transferPopupConfig"
      @onClose="isShowChangeOwnerPopup = false, transferPopupConfig = null"
    />
    <remove-resource-popup-vue
      :is-show="isShowRemovePopup"
      :config="removePopupConfig"
      @onClose="closeRemovePopup"
      @removeResourcesFromAccount="removeResourcesFromAccount"
      @removeResourcesFromProject="removeResourcesFromProject"
    />
    <convert-resources-popup-vue
      v-if="isShowConvertPopup"
      :config="convertPopupConfig"
      @onClose="closeConvertPopup"
      @convertResourcesOnProject="convertResourcesOnProject"
    />
  </div>
</template>

<script>
import app from '../../app';
import Resources from './controller/main';
import projectsModel from '../../models/projects';
import pricingHelpers from '../../helpers/pricingHelper';
import rights from '../../components/rights/index';

import accountResources from './components/accountResources.vue';
import projectResources from './components/projectResources.vue';
import transferOwnershipModalVue from '../modals/transferOwnershipModal.vue';
import removeResourcePopupVue from './components/popups/removeResourcePopup.vue';
import convertResourcesPopupVue from './components/popups/convertResourcesPopup.vue';

let APP_EVENTS = [];

export default {
  name: 'Resources',
  components: {
    accountResources,
    projectResources,
    transferOwnershipModalVue,
    removeResourcePopupVue,
    convertResourcesPopupVue,
  },
  provide() {
    return {
      allProjects: this.getAllProjects,
      upgradePlanShowFromResourcesHandler: this.upgradePlanShowFromResourcesHandler,
      checkAccessToInviteSilent: this.checkAccessToInviteSilent,
      handleInviteFromResources: this.handleInviteFromResources,
      checkProjectRightToFeature: this.checkProjectRightToFeature,
      checkOpenedPopups: this.checkOpenedPopups,
    };
  },
  data() {
    return {
      controller: null,
      projects: [],
      removePopupConfig: null,
      convertPopupConfig: null,
      transferPopupConfig: null,
      isShowRemovePopup: false,
      isShowConvertPopup: false,
      isShowChangeOwnerPopup: false,
      isOverSubscription: false,
    };
  },
  computed: {
    meIsAccountOwner() {
      return rights.account.isOwner();
    },
    meIsKing() {
      return rights.account.getUserRole(user.id)?.rights.find(bitObj => bitObj.bit === 2)?.status;
    },
    meIsProjectOwner() {
      const role = rights.project.getUserRole(user.id, gantt.config.gantt_id);

      return role?.defaultType === 'OWNER';
    },
    isProject() {
      return this.$route.name === 'project';
    },
    isAccount() {
      return this.$route.name === 'resources';
    },
    accountRoles() {
      let roles = this.$store.getters['roles/getAccountRoles'].filter(role => role?.defaultType !== 'OWNER');

      roles = roles.map(item => {
        const copy = { ...item };

        if (copy.defaultType) {
          copy.description = __(`account_${copy.title.toLowerCase()}_description`);
          copy.title = __(`account_${copy.title.toLowerCase()}`);
        } else {
          copy.description = __('account_custom_role_description');
        }

        return copy;
      });

      return roles;
    },
    projectRoles() {
      let roles = this.$store.getters['roles/getProjectRoles'].filter(role => role?.defaultType !== 'OWNER');

      roles = roles.map(item => {
        const copy = { ...item };

        if (copy.defaultType) {
          copy.description = __(`project_${copy.title.toLowerCase()}_description`);
          copy.title = __(`project_${copy.title.toLowerCase()}`);
        } else {
          copy.description = __('project_custom_role_description');
        }

        return copy;
      });

      return roles;
    },
    accountResources() {
      const resources = this.$store.getters['resourcesModel/getAllResources'];

      return this.controller.prepareAccountResources(resources);
    },
    projectResources() {
      const resources = this.$store.getters['resourcesModel/getResourcesByProjectId'](gantt.config.gantt_id);

      return this.controller.prepareProjectResources(resources);
    },
    createAndInviteResourcesAccess() {
      return rights.account.hasRight('team_and_resources_create_and_invite_users');
    },
    accountRoleChangeAccess() {
      return rights.account.hasRight('team_and_resources_account_role_change');
    },
    projectRoleChangeAccess() {
      return rights.project.hasRight(projectsModel.getActiveGanttId(), 'resources_rights_edit');
    },
    removeResourcesAccess() {
      return rights.account.hasRight('team_and_resources_remove_users');
    },
    resourceCalendarAccess() {
      return rights.account.hasRight('team_and_resources_resource_calendar');
    },
    hasRightToCostSettings() {
      return rights.project.hasRight(projectsModel.getActiveGanttId(), 'resources_cost_settings');
    },
    pricingAccessToCostSettings() {
      return pricingHelpers.checkPricingAccess('resource_types');
    },
    pricingAccessToResourceCalendar() {
      return pricingHelpers.checkPricingAccess('personal_calendar');
    },
    route() {
      return this.$route;
    },
  },
  beforeCreate() {
    const event_id_1 = app.on('onAfterCollaboration', data => {
      const projectEvents = ['ProjectUpdated', 'ProjectRestored', 'ProjectArchived', 'ProjectDeleted', 'ProjectCreated'];

      if (projectEvents.includes(data.event)) {
        const projects = projectsModel.getAllProjects();

        this.$store.dispatch('resourcesModel/reloadData');
        this.projects = [...projects];
      }

      if (data.event === 'ResourceOnProjectsUpdated' && this.removePopupConfig && this.removePopupConfig.type === 'account') {
        const isCurrentResource = data.resourcesIds.find(id => id === this.removePopupConfig?.resources.find(res => res.id === id)?.id);

        if (isCurrentResource) {
          const resourceData = this.$store.getters['resourcesModel/getResourceById'](isCurrentResource);

          data.projects.forEach(ganttId => {
            const role = rights.project.getUserRole(resourceData.userId, ganttId);

            if (role?.defaultType === 'OWNER') {
              this.isShowRemovePopup = false;
              this.removePopupConfig = null;
            }
          });
        }
      }
    });

    APP_EVENTS.push(event_id_1);

    const event_id_2 = app.on('project:archive', () => {
      if (this.route.name === 'resources' || this.route.name === 'project') {
        const projects = projectsModel.getAllProjects();

        this.$store.dispatch('resourcesModel/reloadData');
        this.projects = [...projects];
      }
    });

    APP_EVENTS.push(event_id_2);
  },
  beforeDestroy() {
    this.removeAppEvents();
  },
  created() {
    this.controller = new Resources();
    this.projects = projectsModel.getAllProjects();
    this.isOverSubscription = this.controller.checkAccessToInviteSilent() === 'isOverSubscription';
  },
  methods: {
    removeAppEvents() {
      APP_EVENTS.forEach(id => app.off(id));
      APP_EVENTS = [];
    },
    onSendInvites(data) {
      this.controller.inviteResources(data);
    },

    onCreateResource(data) {
      this.controller.createResources(data);
    },

    removeResourcesFromAccount(data) {
      this.controller.removeResourcesFromAccount(data);
      this.closeRemovePopup();
    },

    removeResourcesFromProject(data) {
      this.controller.removeResourcesFromProject(data);
      this.closeRemovePopup();
    },

    addResourcesToProject(data) {
      this.controller.addResourcesToProject(data);
    },

    onChangeCostType(data) {
      this.controller.changeResourceCostType(data);
    },

    onChangeCost(data) {
      this.controller.changeResourceCost(data);
    },

    onChangeAccountRole(data) {
      this.controller.changeAccountRole(data);
    },

    onChangeProjectRole(data) {
      this.controller.changeProjectRole(data);
    },

    updateResource(data) {
      this.controller.updateResource(data);
    },

    upgradePlanShowFromResourcesForce() {
      this.controller.upgradePlanShowFromResourcesForce();
    },

    showRemovePopup(data) {
      this.removePopupConfig = data;
      this.isShowRemovePopup = true;
    },

    onChangeOwner(data) {
      this.transferPopupConfig = data;
      this.isShowChangeOwnerPopup = true;
    },

    closeRemovePopup() {
      this.removePopupConfig = null;
      this.isShowRemovePopup = false;
    },

    onChangeUserAvatar(data) {
      this.controller.changeResourceAvatar(data);
    },

    showConvertPopup(data) {
      this.convertPopupConfig = data;
      this.isShowConvertPopup = true;
    },

    closeConvertPopup() {
      this.convertPopupConfig = null;
      this.isShowConvertPopup = false;
    },

    convertResourcesOnProject(data) {
      this.controller.convertResourcesOnProject(data);
    },

    multiAssignToProjects(data) {
      this.controller.multiAssignToProjects(data);
    },

    getAllProjects() {
      return projectsModel.getAllProjects();
    },

    upgradePlanShowFromResourcesHandler(force) {
      return this.controller.upgradePlanShowFromResourcesHandler(force);
    },

    checkAccessToInviteSilent() {
      return this.controller.checkAccessToInviteSilent();
    },

    handleInviteFromResources() {
      return this.controller.handleInviteFromResources();
    },

    checkProjectRightToFeature(projectId, featureName) {
      return rights.project.hasRight(projectId, featureName);
    },
    checkOpenedPopups() {
      return this.isShowRemovePopup || this.isShowChangeOwnerPopup;
    },
  },
};
</script>

<style module="$" src="./less/resources.less" />
