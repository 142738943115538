import _ from '../../../libs/lodash';
import ganttViewModel from '../../../models/ganttViewModel';
import {
  prepareTaskToRefresh,
  refreshParents, updateButtonInTask,
} from './helpers';
import app from '../../../app';
import globalStore from '../../../store/main';

export function updateTasks(tasksToUpdate) {
  const result = {
    needClearAndParseContext: { needClearAndParse: false },
    hasActiveProject: false,
    projectIds: [],
    taskIds: [],
  };

  const ganttIDs = ganttViewModel.getGanttIDs();
  const customValuesToUpdate = {};

  tasksToUpdate.forEach(update => {
    const taskId = update.taskId;
    let updatedFields = update.updatedFields;
    const taskFromModel = globalStore.getters['tasksModel/getTask'](taskId);

    if (taskFromModel) {
      if (!result.hasActiveProject) {
        result.hasActiveProject = _.includes(ganttIDs, taskFromModel.gantt_id);
      }

      const oldTaskParent = taskFromModel.parent;
      const isSameProjectOpen = _.includes(ganttIDs, taskFromModel.gantt_id);

      updatedFields = prepareTaskToRefresh(updatedFields);
      Object.keys(updatedFields).forEach(key => {
        if (key === 'sortorder' && taskFromModel[key] !== updatedFields[key]) {
          result.needClearAndParseContext.needClearAndParse = true;
        }
        taskFromModel[key] = updatedFields[key];
      });

      globalStore.dispatch('tasksModel/updateTask', {
        taskChanges: taskFromModel,
        taskId: taskFromModel.id,
        ganttId: taskFromModel.gantt_id,
        silent: true,
      });

      if (gantt.isTaskExists(taskFromModel.id)) {
        const taskInGantt = gantt.getTask(taskFromModel.id);

        const updateData = {
          start_date: taskFromModel.start_date,
          end_date: taskFromModel.end_date,
          duration: taskFromModel.duration,
          actual_cost: taskFromModel.actual_cost,
          total_price: taskFromModel.total_price,
          note: taskFromModel.note,
          text: taskFromModel.text || taskInGantt.text,
        };

        if (updatedFields.status !== undefined || updatedFields.priority !== undefined || updatedFields.jira_key !== undefined || updatedFields.actual_cost !== undefined || updatedFields.total_price !== undefined) {
          if (updatedFields.status !== undefined) {
            updateData.status = updatedFields.status;
          }

          if (updatedFields.priority !== undefined) {
            updateData.priority = updatedFields.priority;
          }

          if (updatedFields.actual_cost !== undefined) {
            updateData.actual_cost = updatedFields.actual_cost;
          }

          if (updatedFields.total_price !== undefined) {
            updateData.total_price = updatedFields.total_price;
          }

          const updates = { status: taskFromModel.status, priority: taskFromModel.priority };

          if (updatedFields.jira_key !== undefined) {
            updates.jira_key = updatedFields.jira_key;
            updateData.jira_key = updatedFields.jira_key;
          }

          if (customValuesToUpdate[taskFromModel.gantt_id]) {
            customValuesToUpdate[taskFromModel.gantt_id].items.push({
              taskId: taskFromModel.id,
              updates,
            });
          } else {
            customValuesToUpdate[taskFromModel.gantt_id] = {
              ganttId: taskFromModel.gantt_id,
              items: [{
                taskId: taskFromModel.id,
                updates,
              }],
            };
          }
        }

        // -------------

        if (taskFromModel.type === gantt.config.types.task && +taskFromModel.estimation !== +taskInGantt.estimation) {
          const newEstimation = _.isNumber(+taskFromModel.estimation) && !isNaN(+taskFromModel.estimation) ? +taskFromModel.estimation : +taskInGantt.estimation;

          updateData.estimation = newEstimation;
        }

        if ((taskFromModel.deadline || _.isEmpty(taskFromModel.deadline)) && taskFromModel.deadline !== taskInGantt.deadline) {
          updateData.deadline = taskFromModel.deadline ? new Date(taskFromModel.deadline) : null;
        }

        if (taskFromModel.type && taskFromModel.type !== taskInGantt.type) {
          updateData.type = taskFromModel.type;
        }

        if (taskFromModel.color && taskFromModel.color !== taskInGantt.color) {
          updateData.color = taskFromModel.color;
        }

        if (+taskFromModel.progress !== undefined && +taskFromModel.progress !== +taskInGantt.progress && !isNaN(+taskFromModel.progress)) {
          updateData.progress = +taskFromModel.progress;
        }

        if (updatedFields.parent) {
          gantt.moveTask(taskFromModel.id, taskFromModel.sortorder, taskFromModel.parent);

          if (isSameProjectOpen) {
            const oldParentTask = gantt.getTask(oldTaskParent);

            if (oldParentTask) {
              updateButtonInTask(oldParentTask.id, taskFromModel.gantt_id);
            }
            if (taskFromModel.parent) {
              updateButtonInTask(taskFromModel.parent, taskFromModel.gantt_id);
            }
          }
        }

        if (_.isUndefined(taskFromModel.progress) && _.isUndefined(updateData.progress)) {
          taskFromModel.progress = 0;
        }

        _.assign(taskInGantt, updateData);
      }

      result.projectIds.push(+taskFromModel.gantt_id);
      result.taskIds.push(+update.taskId);
    }
  });

  globalStore.dispatch('tasksModel/updateCustomValues', { mapData: customValuesToUpdate });

  return result;
}

function onTaskUpdated(event) {
  const { data } = event;
  const {
    projectIds,
    taskIds,
    needClearAndParseContext,
    hasActiveProject,
  } = updateTasks(data.updated);

  if (gantt.config.highlight_critical_path) {
    gantt.callEvent('recalculateCriticalPath');
  }

  if (hasActiveProject) {
    gantt.render();
  }

  app.trigger('onAfterCollaboration', {
    event: event.event,
    tasks: [...new Set(taskIds)],
    resources: data.resources,
    projects: [...new Set(projectIds)],
  });

  // close copy-paste tabs on mutlitabs
  if (+data?.userId === +GT.user.id) {
    globalStore.commit('copyPaste/toggleSpecialPastePopup', false);
  }

  !gantt.is_workload_mode && needClearAndParseContext.needClearAndParse && gantt.callEvent('ganttClearAndParse', [null, true]);
  app.trigger('refresh:filter');
}

export default onTaskUpdated;
