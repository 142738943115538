const TooltipStore = {
  namespaced: true,
  state: {
    isShowPopup: false,
    config: {},

  },
  getters: {
    isShowPopup: state => state.isShowPopup,
    getConfig: state => state.config,
  },
  mutations: {
    showPricingPopup(state, flag) {
      state.isShowPopup = flag;
    },
    setConfig(state, config) {
      state.config = config;
    },

  },
  actions: {
  },
};

export default TooltipStore;
