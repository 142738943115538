var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.filter_header_wrap }, [
    _c(
      "div",
      { class: [_vm.$style.filter_header, _vm.$style.jc_space_between] },
      [
        _c("span", { class: _vm.$style.filter_header_title }, [
          _vm._v("\n      " + _vm._s(_vm.locales.filters) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.flex },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCleareBtn,
                    expression: "showCleareBtn",
                  },
                ],
                class: _vm.$style.filter_cleare_btn,
                attrs: { id: "gp_autotest_global_filter_btn_clear" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("reset")
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.locales.clear) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.showHelper
              ? _c("webix-ui", {
                  ref: "help_icon",
                  class: [
                    _vm.$style.filter_header_icon,
                    _vm.$style.icon_helper,
                  ],
                  attrs: {
                    id: "gp_autotest_global_filter_btn_help",
                    config: _vm.icons.helper,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("webix-ui", {
              class: _vm.$style.filter_header_icon,
              attrs: {
                id: "gp_autotest_global_filter_btn_close",
                config: _vm.icons.close,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _vm.checkPricingAccess("saving_filters") && _vm.showCurrent
      ? _c(
          "div",
          {
            class: [
              _vm.$style.filter_header,
              _vm.$style.filter_header_current,
              _vm.checkPricingAccess("saving_filters") ? "" : "pricing-tooltip",
            ],
            attrs: { id: "gp_autotest_global_filter_toggle_save_popup" },
            on: {
              click: function ($event) {
                return _vm.$emit("toggleSavedPopup")
              },
            },
          },
          [
            _c("webix-ui", { attrs: { config: _vm.icons.left_arrow } }),
            _vm._v(" "),
            _c("span", [_vm._v(" " + _vm._s(_vm.locales.filter) + " ")]),
            _vm._v(" "),
            _c(
              "span",
              { class: [_vm.$style.filter_current, _vm.$style.text_elipsis] },
              [_vm._v(" " + _vm._s(_vm.name) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }