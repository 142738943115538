<template>
  <div
    :class="[$.details_tooltip, 'calendar-page-event-details-tooltip']"
    :style="styles"
    :data-id="config.taskData.id"
  >
    <div :class="$.header">
      <div :class="$.breadcrumbs">
        {{ shortenedBreadcrumbsIfDoesntFit }}
      </div>
      <div :class="$.title">
        {{ config.taskData.text }}
      </div>
    </div>
    <div :class="$.body">
      <div :class="$.task_details">
        <div :class="$.task_details__icon">
          <svg-sprite
            :name="'calendar'"
            :size="20"
          />
        </div>
        <div :class="$.task_details__rows">
          <!-- Start date -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('gantt_grid_start_date')}:` }}
            </div>
            <div :class="$.row__value">
              {{ formattedDate.startDate }}
            </div>
          </div>

          <!-- End date -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('gantt_grid_end_date')}:` }}
            </div>
            <div :class="$.row__value">
              {{ formattedDate.endDate }}
            </div>
          </div>
        </div>
      </div>

      <div :class="$.task_details">
        <div :class="$.task_details__icon">
          <svg-sprite
            :name="'time'"
            :size="20"
          />
        </div>
        <div :class="$.task_details__rows">
          <!-- Duration -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('gantt_grid_duration')}:` }}
            </div>
            <div :class="$.row__value">
              {{ formattedDuration }}
            </div>
          </div>

          <!-- Estimation -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('gantt_grid_estimation')}:` }}
            </div>
            <div :class="$.row__value">
              {{ formattedEstimation }}
            </div>
          </div>
        </div>
      </div>

      <div :class="$.task_details">
        <div :class="$.task_details__icon">
          <svg-sprite
            :name="'settings'"
            :size="20"
          />
        </div>
        <div
          :class="$.task_details__rows"
          :style="{paddingTop: 0}"
        >
          <!-- Resources -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('filter_option_assignee_label')}:` }}
            </div>
            <div
              v-if="config.taskData.resources.length"
              :class="$.resources"
            >
              <div
                v-for="(item, index) in resourcesChips"
                :key="index"
                :class="$.chip"
              >
                <div
                  v-if="item.counter"
                  :class="$.chip__count"
                >
                  {{ `+${item.count}` }}
                </div>
                <vgp-avatar
                  v-else
                  :src="item.photo"
                  :custom-size="22"
                />
              </div>
            </div>
            <div
              v-else
              :class="$.resources"
            >
              <div :class="$.unassigned_icon">
                <svg-sprite
                  :name="'assigned'"
                  :size="20"
                  :type="'bold'"
                />
              </div>
              <div :class="$.unassigned_text">
                {{ locales('calendar_page_unassigned') }}
              </div>
            </div>
          </div>

          <!-- Status -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('filter_option_status_label')}:` }}
            </div>
            <div
              :class="$.row__status_ic"
              :style="{marginLeft: '-6px'}"
            >
              <span :style="{background: status.color}" />
            </div>
            <div :class="$.row__value">
              {{ status.locale }}
            </div>
          </div>

          <!-- Progress -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('overview_progress')}:` }}
            </div>
            <div :class="$.row__value">
              {{ config.taskData.progress === 0 ? `${config.taskData.progress}%` : `${config.taskData.progress * 100}%` }}
            </div>
          </div>

          <!-- Priority -->
          <div :class="$.row">
            <div :class="$.row__title">
              {{ `${locales('filter_option_priority_label')}:` }}
            </div>
            <svg-sprite
              :name="'priority'"
              :size="20"
              :style="{color: priority.color, marginLeft: '-6px'}"
            />
            <div :class="$.row__value">
              {{ priority.locale }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delay } from 'lodash';
import SvgSprite from '../../../common/VueComponents/VueIcon/svgSprite.vue';
import dateHelper from '../../../../helpers/dateFormats';
import timeParser from '../../../../helpers/timeParser';
import projectsModel from '../../../../models/projects';
import estimationModule from '../../../gantt/modules/estimation';
import VgpAvatar from '../../../common/VueComponents/avatar/vgpAvatar.vue';

import { PRIORITY, STATUSES } from '../../constants';
import {
  getTaskBreadcrumbs, isBreadcrumbsFitInDiv, shortenBreadcrumbs,
} from '../../../taskSettings/helpers/index';

export default {
  name: 'CalendarEventTooltip',
  components: { SvgSprite, VgpAvatar },
  props: {
    config: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      locales: __,
      taskBreadcrumbsArray: [],
      isBreadcrumbsFitInDiv: false,
    };
  },
  computed: {
    styles() {
      return {
        left: this.config.coords.left,
        top: this.config.coords.top,
        bottom: this.config.coords.bottom,
      };
    },
    formattedDate() {
      if (!this.config.taskData) return '';

      const startDate = new Date(this.config.taskData?.start_date);
      const endDate = new Date(this.config.taskData?.end_date);

      const format = dateHelper.getDateFormat();

      return {
        startDate: webix.Date.dateToStr(format)(startDate),
        endDate: webix.Date.dateToStr(format)(endDate),
      };
    },
    formattedDuration() {
      const projectId = projectsModel.getActiveGanttId();
      const durationData = projectsModel.getProjectConfig(projectId).durationData;

      return timeParser.output(this.config.taskData?.duration, {
        durationData,
        durationStep: gantt.config.duration_view,
        prop: 'duration',
      });
    },
    formattedEstimation() {
      return timeParser.output(
        estimationModule.helpers.getEstimationForTask(this.config.taskData),
        { prop: 'estimation' },
      );
    },
    priority() {
      return PRIORITY[this.config.taskData.priority];
    },
    status() {
      return STATUSES[this.config.taskData.status];
    },
    resourcesChips() {
      const chips = [];
      const resources = this.config.taskData.resources;

      for (let i = 0; i < resources.length; i++) {
        if (i > 4) break;

        if (i === 4) {
          chips.push({
            counter: true,
            count: resources.length - i,
          });

          break;
        }

        const resourceData = this.$store.getters['resourcesModel/getResourceById'](resources[i].resource_id);

        chips.push(resourceData);
      }

      const people = chips.filter(r => r.userId);
      const material = chips.filter(r => !r.userId);

      return people.concat(material);
    },
    shortenedBreadcrumbsIfDoesntFit() {
      return shortenBreadcrumbs({
        isFit: this.isBreadcrumbsFitInDiv,
        breadcrumbsArray: this.taskBreadcrumbsArray,
        separator: ' / ',
      });
    },
  },
  watch: {
    'config.taskData.id': {
      handler() {
        delay(() => {
          this.taskBreadcrumbsArray = getTaskBreadcrumbs(this.config.taskData);
          this.setIsBreadcrumbsFitInDiv();
        }, 0);
      },
      immediate: true,
    },
  },
  methods: {
    setIsBreadcrumbsFitInDiv() {
      this.isBreadcrumbsFitInDiv = isBreadcrumbsFitInDiv({
        breadcrumbsArray: this.taskBreadcrumbsArray,
        separator: ' / ',
        // classes: [this.$style.header__breadcrumbs],
        // divWidth: 631,
      });
    },
  },
};
</script>

<style module='$' src='./detailsTooltip.less'></style>
