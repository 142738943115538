var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "block-menu table-cell center sticky-right",
      class: [[_vm.skins]],
      style: {
        width: "50px",
        "flex-grow": 0,
        "z-index": _vm.isOpenDrop ? 2 : 1,
      },
    },
    [
      _c("dropPopup", {
        staticClass: "more-cell",
        attrs: { "suggest-height": 72, "drop-width": 236, highlight: false },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cell-trigger",
                    class: { discolor: _vm.discolor },
                    on: { contextmenu: _vm.callContextMenu },
                  },
                  [_c("icon", { attrs: { name: "more" } })],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "drop",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "settings-item",
                    attrs: {
                      id: "gp_autotest_listview_more_cell_item_settings",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("show:settings")
                      },
                    },
                  },
                  [
                    _c("icon", { attrs: { name: "settings" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "settings-item--text" }, [
                      _vm._v(" " + _vm._s(_vm.locale.setting) + " "),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.canDeleteTask
                  ? _c(
                      "div",
                      {
                        staticClass: "settings-item red-item",
                        attrs: {
                          id: "gp_autotest_listview_more_cell_item_delete",
                        },
                        on: { click: _vm.deleteHandler },
                      },
                      [
                        _c("icon", {
                          staticClass: "red-item",
                          attrs: { name: "dustbin" },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "settings-item--text" }, [
                          _vm._v(" " + _vm._s(_vm.locale.delete) + " "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.isOpenDrop,
          callback: function ($$v) {
            _vm.isOpenDrop = $$v
          },
          expression: "isOpenDrop",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }