const CONFIG = {
  DEFAULT_COLUMNS: {
    project_name: {
      hidden: false,
    },
    start_date: {
      hidden: false,
    },
    end_date: {
      hidden: true,
    },
    duration: {
      hidden: true,
    },
    estimation: {
      hidden: true,
    },
    time_tracking: {
      hidden: false,
    },
    progress: {
      hidden: true,
    },
    total_price: {
      hidden: true,
    },
    assigned: {
      hidden: false,
    },
    creator: {
      hidden: true,
    },
    status: {
      hidden: false,
    },
    priority: {
      hidden: true,
    },
    create_date: {
      hidden: true,
    },
  },
  DEFAULT_SETTINGS: {
    viewSettings: {
      skins: 4,
      completedTask: [], // 'crossOutTasks', 'discolorationTasks'
    },
    overdue: false,
  },
};

export { CONFIG };
