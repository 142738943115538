var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.box_wrapper, "vgp-interactive-element"],
      style: {
        height: _vm.size + "px",
      },
    },
    [
      _c("label", { class: _vm.$style.radioButton }, [
        _vm.label && _vm.labelPosition === "left"
          ? _c(
              "span",
              { class: _vm.$style.label_text, style: { marginRight: "8px" } },
              [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          class: _vm.$style.check_input,
          attrs: { type: "radio" },
          domProps: { checked: _vm.checked },
          on: {
            click: function ($event) {
              return _vm.$emit("onChange", $event.target.checked)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { class: _vm.$style.radio }, [
          _vm.checked
            ? _c(
                "div",
                {
                  style: {
                    width: _vm.size + "px",
                    height: _vm.size + "px",
                    minWidth: _vm.size + "px",
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("circle", {
                        attrs: {
                          cx: "12",
                          cy: "12",
                          r: "9",
                          fill: "white",
                          stroke: "#1565C0",
                          "stroke-width": "2",
                        },
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: { cx: "12", cy: "12", r: "5", fill: "#1565C0" },
                      }),
                    ]
                  ),
                ]
              )
            : _c("div", {
                class: [_vm.$style.radio, _vm.$style.radio_uncheked],
                style: {
                  width: _vm.size + "px",
                  height: _vm.size + "px",
                  minWidth: _vm.size + "px",
                },
              }),
        ]),
        _vm._v(" "),
        _vm.label && _vm.labelPosition === "right"
          ? _c(
              "span",
              { class: _vm.$style.label_text, style: { marginLeft: "8px" } },
              [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }