const __ = window.__;

function prepareXHR(file) {
  var formData = new FormData();

  formData.append('enctype', "multipart/form-data");
  formData.append('userId', user.id);
  formData.append('uploadFile[]', file);

  return formData;
}

function uploadLogo(file) {
  var promise = webix.promise.defer();
  var xhr = new XMLHttpRequest();
  xhr.onload = function (data, k, n) {
    var response = JSON.parse(data.currentTarget.response);

    promise.resolve(response);
    if (response.status !== "success") {
      webix.message({type: "warning", text: __("fileUpload_limitStore", {limit: humanFileSize(data.maxFileStore)})});
      promise.resolve();
    }
  };

  xhr.open('POST', '/api/upload/teamlogo/'); // avatar
  xhr.send(prepareXHR(file));
  return promise;
}

export default uploadLogo;