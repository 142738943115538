import moment from '../../libs/moment';
import zoomHours from '../settings/include/zoomHours';

import closeCross from '../../svg/close_black.svg';
import createIcon from '../../svg/icons_calendar/create.svg';
import { checkPricingAccess } from '../../helpers/pricingHelper';

const __ = window.__;

const outputObject = {
  getNameForDayWithTitle(dayObject) {
    return dayObject.title;
  },
  getNameForDayWithoutTitle(dayObject) {
    if (!dayObject.hours || dayObject.hours.length === 0 || dayObject.hours === '[]') {
      return __('custom_days_type_weekend');
    }

    return __('custom_days_type_custom_day');
  },
  getCustomDayName(dayObject) {
    return dayObject.title ? this.getNameForDayWithTitle(dayObject) : this.getNameForDayWithoutTitle(dayObject);
  },
  getCustomDayType(dayObject) {
    if (!dayObject.hours || dayObject.hours.length === 0 || dayObject.hours === '[]') {
      return 'weekend';
    }

    return 'custom';
  },
  getCustomDayInfo(dayObject) {
    const blocks = [];

    if (dayObject.from) {
      let dateBlock = '<div>';

      dateBlock += `${moment(dayObject.from).format(user.dateFormat)} `;

      if (dayObject.to) {
        dateBlock += `- ${moment(dayObject.to).format(user.dateFormat)}`;
      }

      dateBlock += '</div>';
      blocks.push(dateBlock);
    }

    if (dayObject.repeat) {
      let repeatBlock = '<div>';

      repeatBlock += __('custom_days_repeat_every_type', {
        duration: __(`custom_days_repeat_every_${dayObject.repeat}`),
      });

      repeatBlock += '</div>';
      blocks.push(repeatBlock);
    }

    if (dayObject.hours && dayObject.hours.length > 0 && dayObject.hours !== '[]') {
      let workingHours = '<div class="custom-days-list-items">';
      let hours = dayObject.hours;

      if (typeof dayObject.hours === 'string') {
        hours = JSON.parse(dayObject.hours);
      }

      workingHours += `${__('custom_day_working_hours')} ${zoomHours.handlers.getLabelForIntervals(hours, ', ')}`;

      workingHours += '</div>';
      blocks.push(workingHours);
    }

    return blocks.join('<div class="custom-days-list-item-divider"></div>');
  },
  customDaysListItem(name, info, type) {
    if (checkPricingAccess('project_calendar_exceptions')) {
      return `<div class="custom-days-list-item ${type}">`
        + `<div class="custom-days-list-item-color ${type}"></div>`
        + '<div class="custom-days-list-item-flex">'
        + '<div class="custom-days-list-item-col custom-days-list-item-col-info">'
        + `<div class="custom-days-list-item-name" title="${name}">${name}</div>`
        + `<div class="custom-days-list-item-info">${info}</div>`
        + '</div>'
        + '<div class="custom-days-list-item-col custom-days-list-item-col-btn">'
        + `<div class="custom-days-list-item-edit">${createIcon}</div>`
        + `<div class="custom-days-list-item-remove">${closeCross}</div>`
        + '</div>'
        + '</div>'
        + '</div>';
    }

    return `<div class="custom-days-list-item ${type}">`
        + `<div class="custom-days-list-item-color ${type}"></div>`
        + '<div class="custom-days-list-item-flex">'
        + '<div class="custom-days-list-item-col custom-days-list-item-col-info">'
        + `<div class="custom-days-list-item-name" title="${name}">${name}</div>`
        + `<div class="custom-days-list-item-info">${info}</div>`
        + '</div>'
        + '<div class="custom-days-list-item-col custom-days-list-item-col-btn">'
        + `<div class="custom-days-list-item-remove">${closeCross}</div>`
        + '</div>'
        + '</div>'
        + '</div>';
  },
};

export default outputObject;
