<template>
  <div :class="$style.toggle_field">
    <span
      :class="['tooltip-gantt',]"
      :data-position="tooltipPosition"
      :data-key="tooltip"
      data-y="12"
    >
      {{ label }}
    </span>
    <div :class="$style.toggle_field_line" />
    <toggle
      :is-toggle-on="value"
      @onChange="toggle"
    />
  </div>
</template>

<script>
import toggle from '../../common/VueComponents/checkbox/squareToggle.vue';

export default {
  name: 'ToggleFilterField',
  components: { toggle },
  props: {
    isToggle: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: 'toggle' },
    tooltip: { type: String, required: false, default: '' },
    tooltipPosition: { type: String, required: false, default: 'left' },
  },

  data() {
    return {
      value: false,
    };
  },
  watch: {
    isToggle(val) {
      this.value = this.isToggle;
    },
  },

  mounted() {
    this.value = this.isToggle;
  },

  methods: {
    toggle() {
      this.value = !this.value;
      this.$emit('toggle', this.value ? 1 : 0);
    },
  },
};
</script>

<style module src="../filter.less" lang="less" />
