<template>
  <div :class="$style.wrapper">
    <tanstack-table
      :config="tableConfig"
      @contextMenu="contextMenu"
      @sortingChanged="sortingChanged"
    />

    <context-menu
      v-if="projectForContext && !showRemove"
      :items="contextMenuItems"
      :position="contextMenuPosition"
      @selectItemMenu="onSelectContextItem($event)"
      @onClickOutside="onClickOutside"
    />

    <confirm-popup
      :show="showRemove && projectForContext"
      :popupWidth="450"
      :closeIcon="true"
      :title="locale('remove_project_from_portfolio_title')"
      :text="deleteConfirmMessage"
      :cancelButton="{
        title: locale('common_cancel'),
        type: 'secondary'
      }"
      :okButton="{
        title: locale('common_remove'),
        type: 'destructive'
      }"
      @onClickCancel="onClickOutside()"
      @onClickOk="removeProject()"
    />
  </div>
</template>

<script>
import projects from "../../models/projects";
import multiViewsProjects from "../../models/multiViewsProjects";
import projectComponent from "../../components/Project";
import svgSprite from "../common/VueComponents/VueIcon/svgSprite.vue";
import statusSelect from "../common/VueComponents/select/statusSelect/statusSelect.vue";
import progressBar from "../common/VueComponents/progressBar/progressBar.vue";
import tanstackTable from "../common/VueComponents/table/table.vue";
import ConfirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import routerHelper from '../../helpers/router';
import moment from "../../libs/moment";
import rights from '../../components/rights';
import app from '../../app';

export default {
  name: 'PortfolioProjects',
  components: { sprite: svgSprite, statusSelect, progressBar, tanstackTable, ConfirmPopup },
  created() {
    this.refreshProjectsList();
    this.portfolio = multiViewsProjects.getActiveViewData();

    const listenerApp_1 = app.on('activeMultiProjects:set', () => {
      this.projectForContext = null;
      this.refreshProjectsList();
    });

    const listenerApp_2 = app.on('portfolio:ganttIDs:remove', (multiviewIDs) => {
      if (multiviewIDs.includes(this.portfolio.id)) {
        this.projectForContext = null;
        this.refreshProjectsList();
      }
    });

    this.multiviewListener = multiViewsProjects.data.attachEvent('onDataUpdate', (id, data, old) => {
      if (this.portfolio.id === id) {
        const portfolio = multiViewsProjects.getActiveViewData();
        this.portfolio = portfolio;

        if (data.updateGanttIDs) {
          app.trigger('activeMultiProjects:set', portfolio.id, this.getSortedGanttIDs(portfolio));
        }
      }
    });

    this.listenersApp = [
      listenerApp_1,
      listenerApp_2
    ];
  },
  beforeDestroy() {
    this.listenersApp.forEach(id => app.off(id));
    multiViewsProjects.data.detachEvent(this.multiviewListener);
  },
  data() {
    return {
      locale: __,
      projects: [],
      portfolio: null,
      listenersApp: [],
      multiviewListener: null,
      projectForContext: null,
      showRemove: false,
      contextMenuPosition: null,
      contextMenuItems: [
        {
          name: 'gantt_go_to_project',
          id: 'go_to_project',
          icon: 'link-2',
          gpTestId: 'gp_autotest_sidebar_go_to_project',
          iconType: 'regular',
          iconSize: 24,
          iconColor: '#191919',
        },
        {
          name: 'remove_project_from_portfolio',
          id: 'remove_from_portfolio',
          icon: 'delete',
          gpTestId: 'gp_autotest_sidebar_remove_from_portfolio',
          iconType: 'regular',
          iconSize: 24,
          iconColor: '#DD3636',
          labelColor: '#DD3636'
        }
      ]
    }
  },
  methods: {
    refreshProjectsList() {
      const ganttIDs = this.getSortedGanttIDs(multiViewsProjects.getActiveViewData());
      this.projects = ganttIDs.map(ganttID => {
        const { id, name } = projects.getProjectDataById(ganttID);

        const status = this.getStatus(ganttID);
        const progress = this.getProgress(ganttID);
        const start_date = this.getStartDate(ganttID);
        const end_date = this.getEndDate(ganttID);
        const creator = this.getCreator(ganttID);

        return { id, name, status, progress, start_date, end_date, creator };
      });
    },
    getSortedGanttIDs(portfolio) {
      const ganttIDs = [ ...portfolio.ganttIDs ];
      const order = [ ...portfolio.config.order ];
      if (!order || !order.length) return ganttIDs;

      const orderMap = order.reduce((acc, curr, currIndex) => {
        acc[curr] = currIndex + 1;

        return acc;
      }, {})
      
      ganttIDs.sort((prev, next) => orderMap[prev] - orderMap[next]);

      return ganttIDs;
    },
    getProgress(ganttID) {
      return {
          percentage: Math.floor(this.getTotalEstimate(ganttID).progress * 100),
          ...this.getTasksCount(ganttID)
        };
    },
    getStatus(ganttID) {
      return this.$store.getters['teamSetting/statusDataForProject'](ganttID);
    },
    getTotalEstimate(ganttID) {
      return this.$store.getters['tasksModel/getTotalEstimateDataForProject'](ganttID);
    },
    getStartDate(ganttID) {
      return moment(this.getTotalEstimate(ganttID).start_date).format(user.dateFormat);
    },
    getEndDate(ganttID) {
      return moment(this.getTotalEstimate(ganttID).end_date).format(user.dateFormat);
    },
    getCreator(ganttID) {
      return rights.project.getOwnerByGanttId(ganttID) || { name: '', photo: null };
    },
    getTasksCount(ganttID) {
      const {
        countOfOverdue,
        countOfSlightlyOverdue,
        countOfPerSchedule
      } = projectComponent.getProjectsList().find(item => item.id === ganttID);

      return {
        countOfOverdue,
        countOfSlightlyOverdue,
        countOfPerSchedule
      }
    },
    contextMenu(event, row) {
      this.projectForContext = row.original;
      this.contextMenuPosition = {
        top: event.clientY,
        left: event.clientX,
        bottom: event.clientY + 1,
        right: event.clientX + 1,
        width: 1,
        height: 1
      };
    },
    goToProject(id) {
      routerHelper.pushRoute(`/project/${id}/gantt`);
    },
    onClickOutside() {
      this.showRemove = false;
      this.projectForContext = null;
    },
    onSelectContextItem(id) {
      switch (id) {
        case 'go_to_project':
          this.goToProject(this.projectForContext.id);
          break;
        case 'remove_from_portfolio':
          this.showRemove = true;
          break;
      }
    },
    removeProject() {
      if (this.portfolio.ganttIDs.length === 1) {
        multiViewsProjects.removeMultiview(this.portfolio.id);
        routerHelper.pushRoute('/portfolio');
        setTimeout(() => this.$toast.success(this.locale('empty_portfolio_deleted', { name : this.portfolio.name })), 100);
      } else {
        const newGanttIDs = this.getSortedGanttIDs(this.portfolio).filter((ganttID) => ganttID !== this.projectForContext.id);
        multiViewsProjects.updateMultiview(this.portfolio.id, newGanttIDs);
      }

      this.projectForContext = null;
      this.showRemove = false;
    },
    sortingChanged(state) {
      multiViewsProjects.updateProjectListSortingActiveMultiview(state);
    }
  },
  computed: {
    tableConfig() {
      return {
        columns: [
          {
            id: 'name',
            header: this.locale('gantt_grid_project_name'),
            type: 'text',
            showTooltip: true,
            tooltipContent: (row) => {
              return row.original.name;
            },
            enableResizing: false,
          },
          {
            id: 'status',
            header: this.locale('gantt_grid_status'),
            type: 'status',
            size: 170,
            sortingFn: (rowA, rowB, columnId) => {
              const aValue = rowA.getValue(columnId);
              const bValue = rowB.getValue(columnId);

              return aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
            },
            accessorFn: (row) => {
              return this.locale(row.status.selectOption.value);
            }
          },
          {
            id: 'progress',
            header: this.locale('gantt_grid_progress'),
            type: 'progress',
            size: 150,
            showTooltip: true,
            sortingFn: (rowA, rowB, columnId) => {
              const aValue = rowA.getValue(columnId).percentage;
              const bValue = rowB.getValue(columnId).percentage;

              return aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
            },
            tooltipContent: (row) => {
              const {
                countOfPerSchedule,
                countOfSlightlyOverdue,
                countOfOverdue
              } = row.original.progress;

              return `
                <div>${this.locale('project_countOfPerSchedule', { count: countOfPerSchedule })}</div>
                <div>${this.locale('project_countOfSlightlyOverdue', { count: countOfSlightlyOverdue })}</div>
                <div>${this.locale('project_countOfOverdue', { count: countOfOverdue })}</div>
              `;
            }
          },
          {
            id: 'start_date',
            header: this.locale('gantt_grid_start_date'),
            type: 'text',
            size: 130,
            sortingFn: (rowA, rowB, columnId) => {
              const aValue = moment(rowA.getValue(columnId), user.dateFormat);
              const bValue = moment(rowB.getValue(columnId), user.dateFormat);

              return aValue.diff(bValue, 'days') === 0 ? 0 : aValue.diff(bValue, 'days') > 0 ? 1 : -1;
            },
          },
          {
            id: 'end_date',
            header: this.locale('gantt_grid_end_date'),
            type: 'text',
            size: 130,
            sortingFn: (rowA, rowB, columnId) => {
              const aValue = moment(rowA.getValue(columnId), user.dateFormat);
              const bValue = moment(rowB.getValue(columnId), user.dateFormat);

              return aValue.diff(bValue, 'days') === 0 ? 0 : aValue.diff(bValue, 'days') > 0 ? 1 : -1;
            },
          },
          {
            id: 'creator',
            header: this.locale('project_owner'),
            type: 'person',
            size: 180,
            accessorFn: (row) => {
              return row.creator.name;
            },
            showTooltip: true,
            tooltipContent: (row) => {
              const creator = row.original.creator;

              return creator.name ? `<div style="display: flex; align-items: center; gap: 4px;">
                ${creator.photo ? `<div style="
                  background-image: url(${creator.photo});
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background-size: 100%;
                  flex-shrink: 0;
                  padding: 1.667px 2.333px 2.333px 1.667px;
                  "></div>` : ''}
                <div>${creator.name}</div>
                </div>` : '';
            }
          },
          {
            id: '_context_menu',
            header: '',
            type: 'contextMenu',
            size: 40,
            enableResizing: false
          }
        ],
        data: this.projects,
        state: {
          columnPinning: {
            left: ['name'],
            right: ['_context_menu']
          },
          sorting: this.portfolio?.config?.project_list_sorting || [{
            id: 'start_date',
            desc: false
          }]
        }
      }
    },
    deleteConfirmMessage() {
      return this.locale('delete_project_from_portfolio_confirm_message', { projectName: this.projectForContext?.name });
    }
  }
}
</script>

<style module src="./less/portfolioProjects.less"></style>