<template>
  <div
    id="role_create_item"
    :class="[$style.role_create]"
  >
    <div :class="[$style.role_create_select]">
      <span>{{ locales(`set_default_role_${type}_description`) }}</span>
      <vgp-dropdown-select
        :gp-autotest-name="'gp_autotest_create_role_dropdown_select_default_role'"
        :class="[$style.vgp_select]"
        :highlight-active-state="false"
        :show-selected-first="true"
        :show-reset-btn="false"
        :selected="setDefault"
        :drop-list="data"
        item-template="text"
        :multiselect="false"
        :fixed-width="320"
        :need-description="true"
        :size="'autoWidth'"
        track-by-text="title"
        use-system-tooltip
        class="dropdown"
        @selected="changeSelect"
      />
    </div>
    <div
      v-if="hasAccessSubscribe"
      :class="[$style.role_create_button]"
    >
      <vgp-button
        v-if="!isEditMode && accessToCreate"
        id="gp_autotest_create_role_btn_create_new_role"
        type="primary"
        :label="locales('create_new_role')"
        :icon="{name: 'plus-1', size: 20, type: 'bold'}"
        icon-side="left"
        :small="true"
        @onClick="togglePopup"
      />
      <div
        v-else-if="isEditMode"
        :class="[$style.buttons_group]"
      >
        <vgp-button
          id="gp_autotest_create_role_btn_cancel_changes_in_role"
          type="secondary"
          :label="locales('cancel_role_change')"
          icon-side="left"
          :small="true"
          @onClick="cancelRoleEdit"
        />
        <!-- :disabled="!hasChanges" -->
        <vgp-button
          id="gp_autotest_create_role_btn_save_changes_in_role"
          type="primary-border"
          :label="locales('confirm_role_change')"
          icon-side="left"
          :small="true"
          :disabled="!hasChanges"
          @onClick="confirmRoleEdit"
        />
      </div>
    </div>

    <core-popup
      v-if="isShowForm"
      id="gp_autotest_popup_create_new_role"
      :close-icon="true"
      :show="isShowForm"
      :title="locales(`create_new_role_${type}_title`)"
      @onClickCancel="togglePopup"
    >
      <template
        #body
      >
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <div
            :class="[$style.add_form]"
            :style="{width: '392px'}"
          >
            <vgp-label-slot
              :label="locales(`create_new_role_${type}_form_title`)"
              :class="$style.form_line"
            >
              <template #body>
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="titleValidationProvider"
                  rules="required|min:1"
                  vid="title"
                  :custom-messages="{ required: locales('error_required_field') }"
                >
                  <vgp-input
                    ref="title"
                    v-model="title"
                    :gp-autotest-name="'gp_autotest_popup_create_new_role_input_name_role'"
                    :is-error="!!errors[0]"
                    :placeholder="locales(`create_new_role_${type}_placeholder`)"
                    :class="['public-url-form-input']"
                    :small="true"
                  />
                  <span
                    v-if="errors[0]"
                    :class="[$style.error]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </template>
            </vgp-label-slot>
            <vgp-label-slot :label="locales(`create_new_role_${type}_form_list_inherit`)">
              <template #body>
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="roleIdValidationProvider"
                  rules="required"
                  vid="roleId"
                  :custom-messages="{ required: locales('error_required_field') }"
                >
                  <vgp-dropdown-select
                    ref="roleId"
                    v-model="roleId"
                    :gp-autotest-name="'gp_autotest_popup_create_new_role_dropdown_select_base_role'"
                    :class="[$style.vgp_select]"
                    :highlight-active-state="false"
                    :show-selected-first="true"
                    :show-reset-btn="false"
                    :selected="setDefaultForCreate"
                    :drop-list="selectList"
                    item-template="text"
                    :multiselect="false"
                    track-by-text="title"
                    class="dropdown"
                    :fixed-width="320"
                    :size="'autoWidth'"
                    :need-description="true"
                    :is-error="!!errors[0]"
                    @selected="changeSelectRole"
                  />
                  <span
                    v-if="errors[0]"
                    :class="[$style.error]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </template>
            </vgp-label-slot>
            <div :class="[$style.form_line_buttons]">
              <vgp-button
                id="gp_autotest_popup_create_new_role_btn_cancel"
                type="secondary"
                :small="true"
                :label="locales('role_create_cancel_btn')"
                @onClick="togglePopup"
              />
              <vgp-button
                id="gp_autotest_popup_create_new_role_btn_ok"
                type="primary"
                :small="true"
                :label="locales('role_create_ok_btn')"
                @onClick="handleSubmit(submitForm)"
              />
            </div>
          </div>
        </ValidationObserver>
      </template>
    </core-popup>
  </div>
</template>

<script>
import VgpDropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import corePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import { checkPricingAccess } from '$$helpers/pricingHelper';
import rightsComponent from '../../../../components/rights/index';
import app from '../../../../app';

export default {
  name: 'RoleCreate',

  components: {
    corePopup,
    VgpLabelSlot,
    VgpDropdownSelect,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'account',
    },
    data: {
      type: [Array],
      required: false,
      default: () => [],
    },
    selectList: {
      type: [Array],
      required: false,
      default: () => [],
    },
    accessToCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      locales: __,
      showInput: false,
      name: this.title,
      isShowForm: false,
      title: null,
      roleId: null,
      hasChanges: false,
      isRequestSend: false,
    };
  },
  computed: {
    setDefault() {
      let selectedItem = {};

      this.data.forEach(item => {
        if (item.isDefault === 1) {
          selectedItem = { ...item };
        }
      });

      return selectedItem;
    },

    setDefaultForCreate() {
      let selectedItem = {};

      const getItems = [...this.$store.getters[this.type === 'account' ? 'roles/getAccountRoles' : 'roles/getProjectRoles'].filter(item => !['OWNER'].includes(item.defaultType)).filter(item => item.id !== this.itemId)];

      for (const item of getItems) {
        if (item.isDefault === 1 && this.roleId === null) {
          selectedItem = { ...item };
          this.roleId = selectedItem.id;
          break;
        } else if (item.id === this.roleId) {
          selectedItem = { ...item };
        }
      }
      // getItems.forEach(item => {
      //
      // });

      if (selectedItem.defaultType) {
        selectedItem.description = this.locales(`${this.type}_${selectedItem.title.toLowerCase()}_description`);
        selectedItem.title = this.locales(`${this.type}_${selectedItem.title.toLowerCase()}`);
      } else {
        selectedItem.description = this.locales(`${this.type}_custom_role_description`);
      }

      return selectedItem;
    },

    hasAccessSubscribe() {
      return checkPricingAccess('custom_permissions');
    },

    isEditMode() {
      return this.$store.getters['roles/getEditModeStatus'];
    },

    rolesData() {
      const allData = this.$store.getters['roles/getAllData'];

      return allData[this.type].roles;
    },

    editModeData() {
      return this.$store.getters['roles/getEditModeData'];
    },
  },
  watch: {
    rolesData(newData) {
      if (this.isEditMode && newData) {
        const getEditItem = this.editModeData;

        if (getEditItem.status && this.checkChangedBits(getEditItem, newData)) {
          this.hasChanges = true;
        }
      }
    },
  },
  methods: {

    checkChangedBits(oldDataObject, arrayOfRoles) {
      const roleId = oldDataObject.roleId;

      const oldRights = oldDataObject.rights;
      let newRights = [];

      arrayOfRoles.forEach(item => {
        if (item.id === roleId) {
          newRights = [...item.rights];
        }
      });

      for (let i = 0; i < newRights.length; i++) {
        if (oldRights[i].status !== newRights[i].status) return true;
      }
    },

    togglePopup() {
      this.isShowForm = !this.isShowForm;
      if (!this.isShowForm) this.title = null;
    },

    cancelRoleEdit() {
      this.hasChanges = false;
      this.$store.dispatch('roles/resetEdit');
    },

    confirmRoleEdit() {
      const editData = this.editModeData;
      const currentAccountRole = rightsComponent.account.getCurrentUserRole();
      let selfAccountRoleUpdate = false;

      if (editData?.roleId === currentAccountRole.id && editData?.type === 'account') {
        selfAccountRoleUpdate = true;
      }

      this.hasChanges = false;
      this.$store.dispatch('roles/updateRole').then(() => {
        this.$toast.success(this.locales(`success_${this.type}_update_role`));

        if (selfAccountRoleUpdate) {
          app.trigger('reloadModal:show:selfRoleUpdate', 'account');
        }
      }).catch(e => {
        console.error(e);
        this.$toast.error(this.locales(`error_${this.type}_update_role`));
      });
    },

    changeSelect(data) {
      let selectedItem = {};

      this.data.forEach(item => {
        if (item.isDefault === 1) {
          selectedItem = { ...item };
        }
      });

      const id = data[0].id;

      if (selectedItem.id === id) return false;

      this.$store.dispatch('roles/setDefaultRole', {
        type: this.type,
        id,
      }).then(() => {
        this.$toast.success(this.locales(`success_${this.type}_update_default_role`));
        this.roleId = id;
      }).catch(() => {
        this.$toast.error(this.locales(`error_${this.type}_update_default_role`));
      });
    },

    changeSelectRole(data) {
      this.roleId = data[0].id;
    },

    checkDefaultRole(value) {
      return ['admin', 'owner', 'member'].includes(value.toLowerCase());
    },

    submitForm() {
      if (!this.isRequestSend) {
        this.isRequestSend = true;
        this.$store.dispatch('roles/addNewRole', {
          type: this.type,
          id: this.roleId,
          title: this.title,
        }).then(() => {
          this.title = null;
          this.roleId = null;
          this.$nextTick(() => document.querySelector('table tbody tr:nth-child(2) td:last-child').scrollIntoView(true));
          this.$toast.success(this.locales(`success_${this.type}_create_new_role`));
          this.togglePopup();
          this.isRequestSend = false;
        }).catch(() => {
          this.$toast.error(this.locales(`error_${this.type}_create_new_role`));
          this.isRequestSend = false;
        });
      }
    },
  },

};
</script>

<style module src="../../less/role.less"></style>
