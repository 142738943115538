import onProjectUpdated from './onProjectUpdated';
import onProjectConfigUpdated from './onProjectConfigUpdated';
import onProjectCreated from './onProjectCreated';
import onProjectDeleted from './onProjectDeleted';
import onProjectArchived from './onProjectArchived';
import onProjectRestored from './onProjectRestored';
import onProjectCalendarUpdated from './onProjectCalendarUpdated';

export default {
  onProjectUpdated,
  onProjectCreated,
  onProjectDeleted,
  onProjectArchived,
  onProjectRestored,
  onProjectCalendarUpdated,
  onProjectConfigUpdated,
};
