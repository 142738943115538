import app from '../../../app';
import _ from '../../../libs/lodash';

import historyModel from '../../../models/history';
import constants from '../../../helpers/constants';
import ganttViewModel from '../../../models/ganttViewModel';
import routerHelper from '../../../helpers/router';
import multiViewsProjects from '../../../models/multiViewsProjects';
import projectsModel from '../../../models/projects';
import dnd_arrow from '../../../svg/dnd_arrow.svg';
import Store from '../../../store/main';

import rights from '../../../components/rights';
import globalStore from "$$store/main";

const __ = window.__;
let updateOrder = false;
let globalTaskId;
let timeout = null;

gantt.attachEvent('onRowDragEnd', (id, target) => {
  innerObject.handlers.moveTasks(id, target);

  userExtAnalytics.log('gantt_task_grid_drop');

  if (innerObject.settings.needSorting) {
    gantt.sort('sortorder', false);
    innerObject.settings.needSorting = false;
  }

  return true;
});

gantt.attachEvent('manualMoveTask', (id, target, oldParentId, needSorting) => {
  if (oldParentId) {
    innerObject.settings.oldParentId = oldParentId;
  } else {
    innerObject.settings.oldParentId = gantt.getParent(id);
  }

  innerObject.handlers.moveTasks(id, target);
  if (innerObject.settings.needSorting || needSorting) {
    gantt.sort('sortorder', false);
    innerObject.settings.needSorting = false;
  }
});

gantt.attachEvent('onBeforeRowDragEnd', (sid, parent, tindex) => innerObject.handlers.beforeDragEnd(sid, parent, tindex));

gantt.attachEvent('onBeforeMoveTask', (taskID, parentID) => {
  const taskData = gantt.getTask(taskID);

  if (!rights.project.hasRight(taskData.gantt_id, 'drag_and_drop')) {
    return;
  }

  if (routerHelper.isTaskSettingsRoute()) {
    return;
  }

  const newParentData = gantt.getTask(parentID);
  const isMultiview = gantt.config.multiview;

  updateOrder = false;

  if (isMultiview && !newParentData.gantt_id && taskData.$level === 1) {
    updateOrder = true;
  }

  if (updateOrder) {
    return true;
  }

  if (taskData.gantt_id !== newParentData.gantt_id) {
    return false;
  }

  return true;
});

gantt.attachEvent('onRowDragStart', (id, target, e) => {
  const taskData = gantt.getTask(id);

  if (!rights.project.hasRight(taskData.gantt_id, 'drag_and_drop')) {
    return;
  }

  if (routerHelper.isTaskSettingsRoute()) {
    return;
  }

  if (timeout) innerObject.handlers.updateTasksBackground();
  clearTimeout(timeout);

  if (taskData.$level === 0) {
    return false;
  }

  if (taskData.type === gantt.config.types.button) {
    _.delay(() => {
      gantt.callEvent('manualResetDragDrop');
    });

    return false;
  }

  userExtAnalytics.log('gantt_task_grid_drag_start');

  app.trigger('popup:show', true);
  app.trigger('keyboard:enable');

  return true;
});

var innerObject = {
  init: {
    run() {
      gantt.config.order_branch = true;
      gantt.config.order_branch_free = true;
    },
    afterInit(deleteResourcesFromTask) {
      innerObject.handlersFromDependencyModule.deleteResourcesFromTask = deleteResourcesFromTask;
      innerObject.init.initPach();
    },
    initPach() {
      // patch start
      (function () {
        let verticalDND = false;
        let allowNextRender = false;
        let taskid;
        let startTaskData;
        let startTaskNode;
        let parentId;
        let parentNode;
        let innerNode;
        let el;
        let elArrow;
        let lastIndex;
        let elHighlighterParent;

        gantt.attachEvent('onRowDragStart', (id, target, e) => {
          clearTimeout(timeout);
          verticalDND = true;
          allowNextRender = true;
          startTaskData = gantt.copy(gantt.getTask(id));
          taskid = id;

          startTaskNode = gantt.getTaskRowNode(taskid);
          // startTaskNode.style.backgroundColor = "#257ECC";

          return true;
        });

        gantt.attachEvent('onAfterTaskMove', (id, parent, tindex) => {
          if (gantt.isTaskExists(parent)) {
            gantt.getTask(parent).$open = 1;
          }

          return true;
        });

        gantt.attachEvent('onBeforeRowDragEnd', (id, parent, tindex) => {
          verticalDND = false;
          startTaskData = null;
          taskid = null;
          parentId = null;
          lastIndex = null;

          if (startTaskNode) {
            startTaskNode.style.backgroundColor = null;
          }

          return true;
        });

        gantt.attachEvent('manualResetDragDrop', () => {
          verticalDND = false;
          startTaskData = null;
          taskid = null;
          parentId = null;
          lastIndex = null;

          if (startTaskNode) {
            startTaskNode.style.backgroundColor = null;
          }
        });

        gantt.manualRemoveDND = function () {
          if (innerNode) {
            innerNode.style.boxShadow = null;
          }

          if (el && el.parentNode) {
            el.parentNode.removeChild(el);
            el = null;
          }

          if (elArrow && elArrow.parentNode) {
            elArrow.parentNode.removeChild(elArrow);
            elArrow = null;
          }

          if (elHighlighterParent && elHighlighterParent.parentNode) {
            elHighlighterParent.parentNode.removeChild(elHighlighterParent);
            elHighlighterParent = null;
          }

          if (parentNode) {
            parentNode.style.backgroundColor = '';
            parentNode.style.boxShadow = null;
          }
        };

        gantt.manualRefreshDND = function (overId, afterId) {
          const task = gantt.getTask(overId);
          const dndTask = document.getElementsByClassName('gantt_drag_marker')[0];

          if (dndTask && dndTask.childNodes[0].childNodes[4].classList && dndTask.childNodes[0].childNodes[4].closest('.tooltip-gantt')) {
            dndTask.childNodes[0].childNodes[4].classList.remove('tooltip-gantt');
          }

          if (!dndTask.childNodes[1]) {
            const icon = document.createElement('div');

            if (task.type === 'project') {
              icon.innerHTML = gantt._getSvgIcon('project', 'regular', 20);
            } else {
              icon.innerHTML = gantt._getSvgIcon('file', 'regular', 20);
            }
            icon.className = 'task-icon';
            dndTask.appendChild(icon);
          }

          if (verticalDND && !allowNextRender) {
            if (!overId || !gantt.isTaskExists(overId)) {
              return;
            }

            const index = gantt.getGlobalTaskIndex(overId);
            const afterIndex = (afterId && gantt.getGlobalTaskIndex(afterId)) + 1;
            const globalTask = gantt.getTaskRowNode(globalTaskId);
            let offset;
            let top;

            if (innerNode) {
              innerNode.style.boxShadow = 'none';
            }

            if (elArrow && elArrow.parentNode) {
              elArrow.parentNode.removeChild(elArrow);
              elArrow = null;
            }

            const shadowTask = document.getElementsByClassName('gantt_transparent')[0];
            const shadowTaskId = shadowTask?.getAttribute('task_id');

            if (+shadowTaskId === overId) {
              return;
            }

            if (elHighlighterParent && elHighlighterParent.parentNode) {
              elHighlighterParent.parentNode.removeChild(elHighlighterParent);
              elHighlighterParent = null;
            }

            if (afterId) {
              if (el && el.parentNode) {
                el.parentNode.removeChild(el);
                el = null;
              }

              top = ((afterIndex * gantt.config.row_height) - gantt.getScrollState().y) + gantt.config.scale_height;
              offset = (gantt.calculateTaskLevel(task) + 1) * 15 + 8;

              var wbsColumn = _.find(gantt.config.columns, obj => obj.name === 'wbs');

              var commentsColumn = _.find(gantt.config.columns, obj => obj.name === 'comments');

              var attachmentsColumn = _.find(gantt.config.columns, obj => obj.name === 'attachments');

              if (wbsColumn && !wbsColumn.hide) {
                offset += 45;
              }

              if (attachmentsColumn && !attachmentsColumn.hide) {
                offset += 22;
              }

              if (commentsColumn && !commentsColumn.hide) {
                offset += 22;
              }

              if (parentNode) {
                parentNode.style.backgroundColor = '';
                parentNode.style.boxShadow = null;
              }

              parentNode = gantt.getTaskRowNode(task.parent);

              el = document.createElement('DIV');
              el.className = 'dnd_highlighter';
              el.style.top = `${top - 1}px`;
              el.style.left = `${offset + 23}px`;
              el.style.width = `${gantt.config.grid_width - offset - 2}px`;

              if (gantt.skin !== 'terrace') {
                el.style.left = `${offset + 10}px`;
              }

              globalTask?.classList.remove('dnd-highlight');

              gantt.$grid.appendChild(el);
            } else {
              top = (((index + 1) * gantt.config.row_height) - gantt.getScrollState().y) + gantt.config.scale_height;

              if (el && el.parentNode) {
                el.parentNode.removeChild(el);
                el = null;
              }

              const taskRow = gantt.getTaskRowNode(task.id);

              offset = (gantt.calculateTaskLevel(task) + 1) * 15;

              if (taskRow) {
                if (+globalTaskId !== task.id) {
                  globalTask?.classList.remove('dnd-highlight');

                  taskRow.classList.add('dnd-highlight');
                  globalTaskId = task.id;
                }
                taskRow.classList.add('dnd-highlight');
              } else {
                return;
              }

              var wbsColumn = _.find(gantt.config.columns, obj => obj.name === 'wbs');

              var commentsColumn = _.find(gantt.config.columns, obj => obj.name === 'comments');

              var attachmentsColumn = _.find(gantt.config.columns, obj => obj.name === 'attachments');

              if (wbsColumn && !wbsColumn.hide) {
                offset += 45;
              }

              if (attachmentsColumn && !attachmentsColumn.hide) {
                offset += 22;
              }

              if (commentsColumn && !commentsColumn.hide) {
                offset += 22;
              }

              // arrow center
              elArrow = document.createElement('DIV');
              const arrow = document.createElement('div');
              const column = document.querySelector('.gantt_grid_head_text');
              const leftPos = column.getBoundingClientRect().left;
              const leftSideBarWidth = Store.getters.leftSidebarWidth;

              arrow.innerHTML = task.type !== 'project' ? dnd_arrow : '';
              elArrow.appendChild(arrow);
              elArrow.className = 'dnd_arrow';
              elArrow.style.top = `${top - (parseInt(taskRow.style.height, 10) / 2) - 12}px`;

              elArrow.style.left = `${leftPos - leftSideBarWidth + 15}px`;
              gantt.$grid.appendChild(elArrow);

              // top-bot border
              elHighlighterParent = document.createElement('DIV');
              elHighlighterParent.className = 'dnd_highlighter_parent';
              elHighlighterParent.style.top = `${top - gantt.config.row_height}px`;
              elHighlighterParent.style.width = `${gantt.config.grid_width - 2}px`;
              elHighlighterParent.style.height = `${gantt.config.row_height + 1}px`;

              if (gantt.skin !== 'terrace') {
                elHighlighterParent.style.left = `${offset + 18}px`;
              }

              gantt.$grid.appendChild(elHighlighterParent);
            }

            return;
          }

          allowNextRender = false;

          return gantt.refreshData();
        };
      }());
      // patch end
    },
    helpers: {},
  },
  handlersFromDependencyModule: {},
  handlers: {
    moveTasks(sourceId, targetId) {
      let tempSortOrder = 0;
      let tasksToUpdate = [];
      const oldParentData = gantt.getTask(innerObject.settings.oldParentId);
      const sourceTaskData = gantt.getTask(sourceId);
      const ganttId = sourceTaskData.gantt_id;
      const newParentData = gantt.getTask(sourceTaskData.parent);
      let actionHash = 0;
      const needClearAndParse = false;

      const childsTask = _.filter(gantt.getChildren(sourceId), tId => {
        const tData = gantt.getTask(tId);

        return tData.type !== gantt.config.types.button;
      });

      if (_.isEmpty(childsTask)) {
        gantt.callEvent('customButtons:delete:from', [sourceTaskData]);
        gantt.callEvent('onUpdateToChild', [sourceTaskData]);
      } else if (sourceTaskData.parent === oldParentData.id) {
        gantt.callEvent('onUpdateToParent', [sourceTaskData]);
      } else {
        sourceTaskData.type = gantt.config.types.project;
        sourceTaskData.color = constants.DEFAULT_TYPE_COLORS.GROUP;
        gantt.callEvent('onUpdateToParent', [sourceTaskData]);
      }

      const childsOldParent = _.filter(gantt.getChildren(innerObject.settings.oldParentId), tId => {
        const tData = gantt.getTask(tId);

        return tData.type !== gantt.config.types.button;
      });

      if (_.isEmpty(childsOldParent) && oldParentData.$level > 0) {
        oldParentData.type = gantt.config.types.task;
        gantt.callEvent('customButtons:delete:from', [oldParentData]);
        gantt.updateTask(oldParentData.id);// for correct rendering new parent task
      }

      if (newParentData.type !== gantt.config.types.project || !newParentData.$open) {
        newParentData.type = gantt.config.types.project;
        newParentData.open = 1;
        newParentData.$open = 1;

        if (newParentData.status > 1 && !gantt.config.isJira) {
          gantt.config.bindStatusAndProgress.updateStatus(newParentData, 1);
        }

        gantt.callEvent('onChangeTaskToParent', [newParentData]);
        gantt.callEvent('customButtons:add:to', [newParentData]);
      }

      if (!projectsModel.getProjectConfig(newParentData.gantt_id)?.auto_budget) {
        newParentData.total_price = 0;
        newParentData.actual_cost = 0;
      }

      tasksToUpdate.push(newParentData);
      tasksToUpdate.push(sourceTaskData);
      tasksToUpdate.push(oldParentData);

      gantt.eachTask(task => {
        if (task.type !== gantt.config.types.button) {
          tempSortOrder = task.sortorder;
          task.sortorder = gantt.getTaskIndex(task.id);

          if (tempSortOrder !== task.sortorder) {
            const existTaskToUpdate = _.find(tasksToUpdate, ['id', task.id]);

            if (existTaskToUpdate) {
              existTaskToUpdate.sortorder = task.sortorder;
            } else if (task.gantt_id === ganttId) {
              tasksToUpdate.push(task);
            }
          }
        }
      });

      innerObject.settings.oldParentId = 0;

      if (tasksToUpdate.length) {
        actionHash = historyModel.generateActionHash();
      }

      if (gantt.config.multiview) {
        tasksToUpdate = webix.copy(tasksToUpdate);
        tasksToUpdate = tasksToUpdate.filter(task => {
          if (task.parent === 0) {
            return false;
          }

          if (task.parent === 1) {
            task.parent = null;
          }

          return true;
        });
      }

      gantt.callEvent('afterTaskMoved', [sourceId, targetId, actionHash]);

      tasksToUpdate = gantt.getClearTasksData(webix.copy(tasksToUpdate));

      Store.dispatch('tasksModel/updateTasksAfterMove', {
        ganttId,
        ganttData: tasksToUpdate,
        actionHash,
        taskName: sourceTaskData.text,
        sourceId,
        targetId,
      });
      gantt.callEvent('recalculateCriticalPath');

      if (gantt.config.auto_scheduling && !gantt._autoscheduling_in_progress) {
        gantt.autoSchedule(sourceId);
      }

      if (needClearAndParse) {
        gantt.callEvent('ganttClearAndParse', [null, true]);
      }
    },
    updateTasksBackground() {
      const taskIds = Object.keys(gantt.config.dnd_task_temp);

      gantt.config.dnd_task_temp = {};
      _.each(taskIds, task => {
        gantt.refreshTask(task);
      });
    },
    getAllHighlightTask(taskId) {
      const tasksIds = gantt.getChildren(taskId);
      let taskItem = gantt.getTask(taskId);

      gantt.config.dnd_task_temp[taskId] = 1;
      if (taskItem.type === 'project') {
        _.each(tasksIds, id => {
          taskItem = gantt.getTask(id);

          if (taskItem.type !== 'button') gantt.config.dnd_task_temp[id] = 1;

          if (taskItem.type === 'project') {
            this.getAllHighlightTask(taskItem.id);
          }
        });
      }
    },
    beforeDragEnd(taskId, oldParent, taskIndex) {
      const taskData = gantt.getTask(taskId);
      const childList = gantt.getChildren(gantt.getParent(taskId)).map(i => parseInt(i, 10));
      const activeView = ganttViewModel.getActiveProjectData();
      const addButtonsTo = [];

      gantt.config.dnd_task_temp = {};
      this.getAllHighlightTask(taskId);

      timeout = setTimeout(() => {
        this.updateTasksBackground();
      }, 2000);
      let newParentData = {};

      if (taskData.type === gantt.config.types.button) {
        return false;
      }

      if (!innerObject.helpers.checkOnDepth(taskId)) {
        return false;
      }

      newParentData = gantt.getTask(gantt.getParent(taskId));

      if (!newParentData) {
        return false;
      }
      if (newParentData.gantt_id !== taskData.gantt_id) {
        gantt.callEvent('manualResetDragDrop');

        if (updateOrder) {
          const newOrder = gantt.getSiblings(taskData.id).map(id => gantt.getTask(id).gantt_id);

          multiViewsProjects.updateOrderActiveMultiview(newOrder);
        }

        return false;
      }

      if (newParentData.type === gantt.config.types.button) {
        return false;
      }

      // TODO fix this situation http://screencast.com/t/c0ZmfBvKrML3
      if (
        parseInt(oldParent, 10) === parseInt(newParentData.id, 10)
        && gantt.getPrevSibling(taskId)
        && gantt.getTask(gantt.getPrevSibling(taskId)).type === gantt.config.types.button
      ) {
        return false;
      }

      if (
        gantt.getPrevSibling(taskId)
        && gantt.getTask(gantt.getPrevSibling(taskId)).type === gantt.config.types.button
      ) {
        innerObject.settings.needSorting = true;
      }

      if (
        childList[childList.length - 1] === parseInt(taskId, 10)
        || childList[childList.length - 1] === parseInt(gantt.getPrevSibling(taskId), 10)
      ) {
        newParentData.type = gantt.config.types.project;
        newParentData.open = 1;
        innerObject.handlersFromDependencyModule.deleteResourcesFromTask(newParentData);
        addButtonsTo.push({
          index: 2,
          parentData: newParentData,
        });
      }

      if (addButtonsTo.length) {
        addButtonsTo.forEach(whereButtonInsertObject => {
          gantt.callEvent('customButtons:add:to', [whereButtonInsertObject.parentData, whereButtonInsertObject.index]);
        });
      }

      innerObject.settings.oldParentId = oldParent;

      return true;
    },
    helpers: {},
  },
  settings: {
    ganttId: 0,
    oldParentId: 0,
    taskDataBeforeUpdate: {},
  },
  helpers: {
    toProject(taskData) {
      const changedResources = [];

      _.each(taskData.resources, resource => {
        const resourceData = globalStore.getters['resourcesModel/getResourceById'](resource.resource_id);

        changedResources.push({
          userId: resourceData.userId,
          id: resourceData.id,
          value: 0,
          action: 'delete',
        });
      });

      if (!_.isEmpty(changedResources)) {
        Store.dispatch('tasksModel/changeAssign', {
          changedResources,
          taskId: taskData.id,
          ganttId: taskData.gantt_id,
        });
      }

      return _.assign(taskData, {
        type: gantt.config.types.project,
        resources: [],
        estimation: 0,
        open: 1,
        priority: null,
        status: null,
      });
    },
    checkOnDepth(taskId) {
      let maxDepth = gantt.calculateTaskLevel(taskId);

      if (!maxDepth) {
        return false;
      }

      if (gantt.hasChild(taskId)) {
        gantt.eachTask(task => {
          if (gantt.calculateTaskLevel(task.id) > maxDepth) {
            maxDepth = gantt.calculateTaskLevel(task.id);
          }
        }, taskId);
      }

      return maxDepth < 14;
    },
  },
};

const outputObject = {
  init: {
    beforeInit: innerObject.init.run,
    run: innerObject.init.run,
    afterInit: innerObject.init.afterInit,
  },
};

export default outputObject;
