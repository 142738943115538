/* eslint-disable */
if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function (a) { setTimeout(a, 0) }
}

var innerObject = {
  debug: (window.location.host !== "app.ganttpro.com"),
  init: function () {
    var that = this;
    amplitude.getInstance().init(GT.amplitudeApiToken);

    if (window.Bugsnag && GT.bugsnagApiToken && GT.bugsnagApiToken.client) {
      var bugSnagClientConfig = {
        apiKey: GT.bugsnagApiToken.client,
        releaseStage: 'production',
        autoTrackSessions: true,
        sendCode: true,
        appVersion: GT.buildVersion,
      };
      // if (window.user) {
      //   bugSnagClientConfig.user = { id: window.user.id, email: window.user.email };
      // }
      Bugsnag.start(bugSnagClientConfig);
      if (window.GT) {
        Bugsnag.addMetadata(window.GT)
      }
    } else {
      console.warn("Bugsnag is not initialized. It is only working on ganttpro.com");
    }

    that.isEnabled = true;
    if (window.user && window.user.id) {

      if (window.bugsnagClient) {
        Bugsnag.setUser(_.pick(window.user, ['id', 'email', 'firstName', 'lastName', 'locale', 'photo', 'registrationTime', 'userProductVersion', 'subscription', 'team']));
      }

      let extractUrlParts = (url) => {
        try {
          const myUrl = new URL(url)
          return {
            base: myUrl.protocol + '//' + myUrl.hostname + myUrl.pathname.split('?')[0].split('&')[0].split(
              '#')[0],
            path: myUrl.pathname.split('?')[0].split('&')[0].split('#')[0],
            domain: myUrl.hostname,
          }
        } catch (e) {
          return {
            base: null,
            path: null,
            domain: null,
          }
        }
      }

      let userClone = Object.assign({}, user);

      let gp_landing = extractUrlParts(userClone.settings.gp_landing);
      let gp_regsource = extractUrlParts(userClone.settings.gp_regsource);
      let gp_referrer = extractUrlParts(userClone.settings.gp_referrer);

      let analyticsUser = {
        id: userClone.id,
        email: userClone.email,
        firstName: userClone.firstName,
        lastName: userClone.lastName,
        locale: userClone.locale,
        registrationTime: userClone.registrationTime,
        loginMethod: userClone.loginMethod
      }

      analyticsUser.settings = {
        country: userClone.settings.country,
        gp_landing: userClone.settings.gp_landing,
        gp_referrer: userClone.settings.gp_referrer,
        gp_regsource: userClone.settings.gp_regsource,

        gp_landing_base: gp_landing.base,
        gp_landing_path: gp_landing.path,
        gp_landing_domain: gp_landing.domain,

        gp_regsource_base: gp_regsource.base,
        gp_regsource_path: gp_regsource.path,
        gp_regsource_domain: gp_regsource.domain,

        gp_referrer_base: gp_referrer.base,
        gp_referrer_path: gp_referrer.path,
        gp_referrer_domain: gp_referrer.domain,

        gp_utm: userClone.settings.gp_utm,
        user_company: userClone.settings.user_company,
        user_industry: userClone.settings.user_industry,
        user_job_field: userClone.settings.user_job_field,
        user_tool_used_before: userClone.settings.user_tool_used_before,
        user_planning_use_for: userClone.settings.user_planning_use_for,
        user_interested_features: userClone.settings.user_interested_features,
        user_job_title: userClone.settings.user_job_title,
        user_team: userClone.settings.user_team,

        onboarding_is_finished: userClone.settings.onboarding_is_finished,
        user_scoring_percent: userClone.settings.user_scoring_percent,
        user_scoring_value: userClone.settings.user_scoring_value,
        ad_source: userClone.settings.ad_source,
      }


      analyticsUser.team = {
        id: userClone.team.id,
        is_owner: userClone.team.is_owner,
        owner_email: userClone.team.owner_email,
        owner_id: userClone.team.owner_id,
        is_jira: userClone.team.is_jira
      }

      analyticsUser.subscription = {
        date_to: userClone.subscription.date_to,
        is_annual: userClone.subscription.is_annual,
        is_trial: userClone.subscription.is_trial,
        payment_plans_id: userClone.subscription.payment_plans_id,
        provider: userClone.subscription.provider,
        user_count: userClone.subscription.user_count
      }

      analyticsUser.sso = {
        enabled: (userClone.sso && userClone.sso.enabled) ? true : false
      }

      analyticsUser.twoFa = {
        active: (userClone.twoFa && userClone.twoFa.active) ? true : false
      }

      analyticsUser.affiliate = {
        key: userClone?.affiliate?.key,
        coupon: userClone?.affiliate?.coupon,
      }

      amplitude.getInstance().setUserId(window.user.id);
      amplitude.getInstance().setUserProperties(analyticsUser); // window.user

      if (that.debug) {
        console.info('Analytics User', analyticsUser);
      }
    }
  },

  log: function (str, obj) {
    var that = this;
    obj = obj || {};
    if (!str) { return console.error('userExtAnalytics: log: nothig to log'); }
    if (!that.isEnabled) { return; }
    if (that.debug) {
      var log = '%c LOG: ' + str;
      var color = 'color: #bada55';
      if (obj) {
        console.info(log, color, obj);
      } else {
        console.info(log, color);
      }
    }
    // obj.isJira = gantt.config.isJira ? 1 : 0;
    return requestAnimationFrame(function () {

      amplitude.getInstance().logEvent(str, obj);

      // add same event to bugsnag
      if (window.Bugsnag) {
        // Bugsnag.leaveBreadcrumb(str, obj)
        // if (that.debug) {
        //   console.info('Add event to bugsnag', str, obj)
        // }
      }

      // add same event to GA
      if (window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({
          "event": str,
          "eventCategory": str,
          "eventValue": str,
          "eventAction": str,
          "eventLabel": str
        });
        if (that.debug) {
          // console.log('Add event to GA', str)
        }
      }

    });
  },
  on: function () {
    this.isEnabled = true;
  },
  off: function () {
    this.isEnabled = false;
  },
  initHotJarAndStartRecording: function(){
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2690383,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

    window.hj=window.hj||function(){(hj.q=hj.q||[]).push(arguments)};
    hj('trigger', 'startRecording');

    // qa
    // (function(h,o,t,j,a,r){
    //   h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //   h._hjSettings={hjid:2691214,hjsv:6};
    //   a=o.getElementsByTagName('head')[0];
    //   r=o.createElement('script');r.async=1;
    //   r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //   a.appendChild(r);
    // })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

  }
};

export default innerObject;
