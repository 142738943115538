var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "loader" },
          [
            _c("vue-ellipse-progress", {
              attrs: {
                size: 34,
                loading: "",
                line: "round",
                thickness: "10%",
                "empty-thickness": "10%",
                color: "#1565C0",
                "empty-color": "#73A3D94D",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }