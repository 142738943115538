import BaseFilter from './BaseFilter';
import BaseProperties from './BaseProperties';
import globalStore from '../../../store/main';
import logsHeaderHelper from '../../../helpers/logsHeaderHelper';
import timeParser from '../../../helpers/timeParser';
import app from '../../../app'; 

const _self = null;

class LogsFilter extends BaseFilter {
  static filterByType(type, withResource) {
    if (!_self) {
      this._self = new LogsFilter(type, withResource);
    }
    return this._self;
  }

  constructor(type, withResource) {
    super(type);
    this.withResource = withResource;
  }

  config() {
    const conf = [
      BaseProperties.taskName('task_name'),
      BaseProperties.projects(),
      BaseProperties.date('date'),
      BaseProperties.time(),
      BaseProperties.comment(),
    ];

    const withResourceConf = [
      BaseProperties.taskName('task_name'),
      BaseProperties.projects(),
      BaseProperties.realResources('resource_id'),
      BaseProperties.date('date'),
      BaseProperties.time(),
      BaseProperties.comment(),
    ];

    return this.withResource ? withResourceConf : conf;
  }

  init() {
    super.init(); 
    this.resetIfnotFoundTasks = false;
    if (!this.isActiveFilter) { 
      this.setFilterState({ date: { period: 'this_month' }}, true);
    } 

    this.addEventsHandler();
    // this.filterData();
  }

  filterData() {
    const dataTable = $$('groupByTableTree');
    dataTable && super.filterData(dataTable);
  }

  changeFilterState(filterState) {
    this.setFilterState(filterState);
    this.filterData();
    this.updateDataOnHeader();
  }

  refreshFilter() {
    this.changeFilterState(this.filterState);
  }

  selectSavedFilter(filter) {
    this.changeFilter(filter.id, filter.value);
    this.filterData();
  }

  resetFilter() {
    super.resetFilter();
    this.filterData();
    this.updateDataOnHeader();
  }

  updateDataOnHeader() {
    const filterRange = 'date' in this.filterState ? this.filterState.date : null;

    const data = $$('groupByTableTree').serialize();
    const headerData = logsHeaderHelper(data, filterRange);

    globalStore.commit('headerView/setReportToolbarInfo', {
      total: timeParser.output(headerData.sum, {}),
      range: headerData.range,
      title_locale: 'common_my_logs_title',
      total_locale: 'total_time_spent',
    });
  }

  addEventsHandler(){
    let event_name = 'filter:data:' + this.type;
    app.on(event_name, () => {
      this.filterData();
      this.updateDataOnHeader();
    })
  }
}

export default LogsFilter;
