var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.$style["task-settings-primary-info"] }, [
    _c(
      "div",
      { class: _vm.$style["task-settings-primary-info__left"] },
      [
        _c(
          "div",
          { class: _vm.$style["progress-wrapper"] },
          [
            _c("input-range-slider", {
              class: _vm.$style["progress-wrapper__slider"],
              attrs: {
                value: _vm.taskProgress,
                disabled: _vm.isFieldDisabled("progress"),
                min: 0,
                max: 100,
                step: 10,
                width: 120,
              },
              on: {
                update: function ($event) {
                  _vm.taskProgress = $event
                },
                change: function ($event) {
                  return _vm.handleChangeData("progress", $event)
                },
              },
            }),
            _vm._v(" "),
            _c("vgp-input", {
              key: _vm.inputProgressKey,
              class: _vm.$style["progress-wrapper__input"],
              attrs: {
                value: _vm.taskProgress,
                "is-disabled": _vm.isFieldDisabled("progress"),
                "input-type": "number",
                postfix: "%",
                "select-by-focus": "",
                border: false,
                min: 0,
                max: 100,
              },
              on: { onChange: _vm.handleInputProgressChange },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isFieldDisabled("status_visibility")
          ? _c("button-dropdown-select", {
              attrs: {
                items: _vm.statusOptions,
                disabled: _vm.isFieldDisabled("status"),
              },
              on: {
                change: function ($event) {
                  return _vm.handleChangeData("status", $event)
                },
              },
              model: {
                value: _vm.taskStatus,
                callback: function ($$v) {
                  _vm.taskStatus = $$v
                },
                expression: "taskStatus",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isFieldDisabled("priority_visibility")
          ? _c("button-dropdown-select", {
              attrs: {
                items: _vm.priorityOptions,
                disabled: _vm.isFieldDisabled("priority"),
                "icon-type": "link",
              },
              on: {
                change: function ($event) {
                  return _vm.handleChangeData("priority", $event)
                },
              },
              model: {
                value: _vm.taskPriority,
                callback: function ($$v) {
                  _vm.taskPriority = $$v
                },
                expression: "taskPriority",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style["task-settings-primary-info__right"] }, [
      _c("span", [_vm._v(_vm._s(_vm.loggedTimeInfo))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }