<template>
  <dropCell
    v-model="isOpenDrop"
    :suggest-height="dropHeight"
    :drop-width="dropWidth"
    :close-on-content-click="false"
    :disabled="disabled"
    :editable="editable"
  >
    <template #trigger="{isOpen}">
      <cellTooltip
        :items="chacked"
        :template="template"
        :group-by="groupBy"
        :can-show="!isOpenDrop"
      >
        <template>
          <div
            ref="cell"
            :class="[$style.cell_trigger,
                     isOpenDrop && !disabled && editable ? $style.edit : '',
                     disabled ? $style.disabled_cell : '',
                     !editable ? $style.not_allowed : '',
            ]"
            :style="{opacity: isOpen ? 1 : cellOpecity}"
            class="d-flex align-center multiselect-cell"
          >
            <template v-if="template === 'text'">
              <div :class="[$style.text_wrap, crossout && !isOpen ? $style.crossout :'']">
                <span
                  v-for="(s_item, index) in chacked"
                  :key="componentKey + 'c_text-' + index"
                >{{ s_item.value }}<span v-if="index < chacked.length-1">, </span>
                </span>
              </div>
            </template>

            <template v-if="template === 'icon+text' && chacked">
              <template v-if="chacked.length === 1">
                <div
                  :style="{ 'background-image': `url(${chacked[0].icon})` }"
                  :class="[$style.icon_wrap, crossout && !isOpen ? $style.crossout :'', ]"
                />
                <span :class="[$style.text_wrap, crossout && !isOpen ? $style.crossout :'']">{{ chacked[0].value }}</span>
              </template>
              <template v-if="chacked.length === 2 || chacked.length === 3">
                <div
                  v-for="index in chacked.length"
                  :key="componentKey + 'i+t_s3-' + index"
                  :style="{
                    'background-image': `url(${chacked[index - 1].icon})`,
                  }"
                  :class="[$style.icon_wrap, crossout && !isOpen ? $style.crossout :'']"
                />
              </template>
              <template v-if="chacked.length > 3">
                <div
                  v-for="index in 2"
                  :key="componentKey + 'i+t_s-' + index"
                  :style="{
                    'background-image': `url(${chacked[index - 1].icon})`,
                  }"
                  :class="[$style.icon_wrap, crossout && !isOpen ? $style.crossout :'', discolor ? $style.discolor :'']"
                />
                <span
                  v-if="chacked.length > 2"
                  :class="[discolor ? $style.discolor :'']"
                >
                  +{{ chacked.length - 2 }}
                </span>
              </template>
            </template>

            <template v-if="template === 'label'">
              <template v-if="chacked.length <= 2">
                <span
                  v-for="(s_item, index) in chacked"
                  :key="componentKey + 'c_label-' + index"
                  :class="[$style.template__label, $style.text_wrap,]"
                  :style="{ background: s_item.color }"
                >
                  {{ s_item.value }}
                </span>
              </template>
              <template v-else>
                <span
                  v-for="index in 2"
                  :key="componentKey + 'cc_label-' + index"
                  :class="[$style.template__label, $style.small, ]"
                  :style="{ background: chacked[index - 1].color }"
                >
                  {{ chacked[index - 1].value[0] }}
                </span>
                <span> +{{ chacked.length - 2 }} </span>
              </template>
            </template>
          </div>
        </template>
      </cellTooltip>
    </template>

    <template #drop>
      <div
        v-if="withSearch"
        :class="$style.search_input"
      >
        <div :class="$style.search" />
        <input
          ref="seach_input"
          v-model="txt_search"
          :placeholder="locale.search"
        >
      </div>
      <div
        ref="scrolled"
        class="gantt_scroll scrollable"
        :class="[withSearch ? $style.scrolable_list : '']"
      >
        <template v-if="showItems.length">
          <div
            v-for="groupname in groupSet"
            :key="componentKey + groupname"
          >
            <div
              v-if="groupname && getGroupItems(groupname).length"
              :class="$style.select_group"
            >
              <span> {{ groupname }} </span>
              <div :class="$style.select_group_line" />
            </div>
            <div
              v-for="(option, index) in getGroupItems(groupname)"
              :key="componentKey + '-' + index + option.id"
              :class="[$style.item_template]"
              class="d-flex align-center"
              @click="checkedHandler(option)"
            >
              <button
                :class="[
                  $style.btn_checkbox,
                  isChacked(option.id) ? $style.chacked : '',
                ]"
              />
              <template v-if="template === 'text'">
                <span :class="[$style.text_wrap]">{{ option.value }}</span>
              </template>

              <template v-if="template === 'icon+text'">
                <div
                  :style="{ 'background-image': `url(${option.icon})` }"
                  :class="[$style.icon_wrap, $style.mr_10]"
                />
                <span :class="[$style.text_wrap]">{{ option.value }}</span>
              </template>

              <template v-if="template === 'label'">
                <span
                  :class="[$style.template__label, $style.text_wrap]"
                  :style="{ background: option.color }"
                >
                  {{ option.value }}
                </span>
              </template>
            </div>
          </div>
        </template>

        <div
          v-else
          :class="[$style.no_data]"
        >
          <span> {{ locale.noFiltred }} </span>
        </div>
      </div>
    </template>
  </dropCell>
</template>

<script>
import dropCell from '../../dropPopup.vue';
import cellTooltip from '../tooltip.vue';

export default {
  name: 'SelectListCell',
  components: { dropCell, cellTooltip },
  props: {
    item: { type: Object, required: true },
    property: { type: String, required: true },
    options: { type: Array, required: true },
    groupBy: { type: String, required: false, default: 'group' },
    disabled: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: true },
    value: { type: [String, Array], required: true },
    template: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return ['text', 'label', 'icon+text'].indexOf(value) !== -1;
      },
    },
    discolor: { type: Number, required: false, default: 1 },
    crossout: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      showTooltip: false,
      locale: {
        search: __('app_footer_btn_search_text'),
        clear: __('common_clear'),
        noFiltred: __('no_matches_message_text'),
      },
      txt_search: '',
      scrollBottom: false,
      isOpenDrop: false,
      chacked: [],
      emitChanges: false,
    };
  },
  computed: {
    cellOpecity() {
      const val = +this.discolor || 1;

      if (this.disabled && val !== 1) return 0.8;

      return val;
    },
    selected() {
      let val = this.value;

      if (!val) val = [];

      return Array.isArray(val) ? val : [val];
    },
    dropWidth() {
      return 223;
    },
    dropHeight() {
      const cleareHeight = 30;
      const itemHeight = 36;

      if (this.options.length < 5) {
        return cleareHeight + itemHeight * this.options.length;
      }

      return 250;
    },
    withSearch() {
      return this.options.length > 5;
    },
    componentKey() {
      const min = 1000;
      const max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
    showItems() {
      return this.options.filter(item => {
        const value = item.value.toLowerCase();

        if (value.includes(this.txt_search.toLowerCase())) {
          return item;
        }

        return false;
      });
    },
    groupSet() {
      const set = [];

      this.options.forEach(item => {
        const groupName = this.groupBy in item ? item[this.groupBy] : null;

        if (!set.includes(groupName)) {
          set.push(groupName);
        }
      });

      return set;
    },

  },
  watch: {
    selected(val) {
      this.chacked = Array.isArray(val) ? [...val] : [];
    },
    isOpenDrop(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.seach_input && this.$refs.seach_input.focus();
        });
        this.emitChanges = false;
      } else {
        this.closePopup();
      }
    },
  },

  mounted() {
    this.chacked = Array.isArray(this.selected) ? [...this.selected] : [];
  },

  beforeDestroy() {
    this.isOpenDrop && this.closePopup();
  },

  methods: {
    getGroupItems(groupName) {
      if (groupName === null) {
        return this.showItems.filter(item => !(this.groupBy in item));
      }

      return this.showItems.filter(item => item[this.groupBy] === groupName);
    },
    isChacked(id) {
      return !!this.chacked.find(item => item.id === id);
    },
    checkedHandler(item) {
      const isChacked = this.isChacked(item.id);

      this.emitChanges = true;

      if (!isChacked) {
        this.chacked.push(item);
      } else {
        const index = this.chacked.findIndex(el => el.id === item.id);

        this.chacked.splice(index, 1);
      }
    },

    closePopup() {
      this.emitChanges && this.$emit('change', this.chacked);
      this.txt_search = '';
    },
  },
};
</script>

<style module src="../cell.less" lang="less">
</style>
