import projectComponent from '../../Project';
import app from '../../../app';
import globalStore from '$$store/main';
import rights from '../../rights';

async function onProjectCreated(event) {
  let resourceProjects;

  if (event?.innerSettings?.projectData && event?.innerSettings?.projectData.resources) {
    resourceProjects = event?.innerSettings?.projectData?.resources.find(res => res.userId === GT.user.id).resourceProjects;
  }
  const projectId = event.data.project.projectId;
  const updateData = {};
  const statusData = event.data.project;

  updateData.option_id = statusData.projectStatusOptionId;
  updateData.status_id = statusData.projectStatusId;
  updateData.ganttId = statusData.projectId;
  globalStore.commit('teamSetting/addProjectsWithStatus', updateData);
  const responseData = event.innerSettings.projectData;
  const isCreator = +event.innerSettings?.user?.id === +GT.user.id;
  const isAPI = event.innerSettings?.isAPI;
  const isCustomTemplate = event.innerSettings?.isCustomTemplate;
  const isImport = event.innerSettings.isImport;

  if (GT.user.id === GT.user.team.owner_id) {
    if (event.innerSettings.isImport) {
      if (event.innerSettings.isImport === 'jira') return projectComponent.addNewJiraProject(+projectId);
      if ((event.innerSettings.isImport === 'xlsx' || event.innerSettings.isImport === 'csv') && isCreator) return projectComponent.importFinishExcel(responseData);

      await projectComponent.addNewProjectByCollaboration(responseData, event.innerSettings.user, !isCreator, {
        isCreator,
        isAPI,
        isImport,
        isCustomTemplate,
      });
      // await projectComponent.addNewProjectByCollaboration(+projectId, event.innerSettings.user, false);
    } else {
      // await projectComponent.addNewProjectForOwner(+projectId);
      await projectComponent.addNewProjectByCollaboration(responseData, event.innerSettings.user, !isCreator, {
        isCreator,
        isAPI,
        isImport,
        isCustomTemplate,
      });
      // await projectComponent.addNewProjectByCollaboration(+projectId, event.innerSettings.user, false);
    }
  } else if (rights.account.hasRight('king_mode')) {
    projectComponent.addNewProjectByCollaboration(responseData, event.innerSettings.user, !isCreator, {
      isCreator,
      isAPI,
      isCustomTemplate,
      isImport,
    });
    // await projectComponent.addNewProjectByCollaboration(+projectId, event.innerSettings.user, false);
  } else if (GT.user.id !== GT.user.team.owner_id && (event.innerSettings.isImport === 'xlsx' || event.innerSettings.isImport === 'csv') && isCreator) {
    return projectComponent.importFinishExcel(responseData);
  } else if (GT.user.id !== GT.user.team.owner_id && event.innerSettings.isImport === 'jira' && isCreator) {
    return projectComponent.addNewJiraProject(+projectId);
  } else if (GT.user.id !== GT.user.team.owner_id && event.innerSettings.isImport && isCreator) {
    projectComponent.addNewProjectByCollaboration(responseData, event.innerSettings.user, !isCreator, {
      isCreator,
      isAPI,
      isImport,
    });
  } else if (resourceProjects && resourceProjects.length) {
    const project = resourceProjects.find(el => el.projectId === projectId);
    // const project = resourceProjects.find(el => el.gantt_id === projectId);

    if (project) {
      projectComponent.addNewProjectByCollaboration(responseData, event.innerSettings.user, !isCreator, {
        isCreator,
        isAPI,
        isImport,
        isCustomTemplate,
      });
      // projectComponent.addNewProjectByCollaboration(+projectId, event.innerSettings.user, true);
    } else if (GT.user.id !== GT.user.team.owner_id) {
      const projectTasksData = event.innerSettings.projectData.taskData;

      projectTasksData._onlyForWorkloadCalculation = true;
      globalStore.dispatch('tasksModel/addTasksAfterCreateNewProject', {
        ganttId: +projectId,
        projectTasksData,
      });
      gantt.addProjectCalendar(projectTasksData.workloadData.durationData, +projectId);
    }
  }

  globalStore.commit('columns/addProject', { projectId: +projectId });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    projects: [event.data.project.projectId],
  });
}

export default onProjectCreated;
