var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.wrapper },
    [
      _c("tanstack-table", {
        attrs: { config: _vm.tableConfig },
        on: {
          contextMenu: _vm.contextMenu,
          sortingChanged: _vm.sortingChanged,
        },
      }),
      _vm._v(" "),
      _vm.projectForContext && !_vm.showRemove
        ? _c("context-menu", {
            attrs: {
              items: _vm.contextMenuItems,
              position: _vm.contextMenuPosition,
            },
            on: {
              selectItemMenu: function ($event) {
                return _vm.onSelectContextItem($event)
              },
              onClickOutside: _vm.onClickOutside,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-popup", {
        attrs: {
          show: _vm.showRemove && _vm.projectForContext,
          popupWidth: 450,
          closeIcon: true,
          title: _vm.locale("remove_project_from_portfolio_title"),
          text: _vm.deleteConfirmMessage,
          cancelButton: {
            title: _vm.locale("common_cancel"),
            type: "secondary",
          },
          okButton: {
            title: _vm.locale("common_remove"),
            type: "destructive",
          },
        },
        on: {
          onClickCancel: function ($event) {
            return _vm.onClickOutside()
          },
          onClickOk: function ($event) {
            return _vm.removeProject()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }