import _ from '../../../libs/lodash';

const menuHeaderItems = [
  {
    icon: 'project',
    iconRight: true,
    tooltip: __('allProjects_left_side_label'),
    name: __('allProjects_left_side_label'),
    mainPath: '',
    id: 'allProjects',
    css: 'allProjects-btn',
    hidden: false,
  },
  {
    icon: 'portfolio',
    tooltip: __('portfolios_left_side_label'),
    name: __('portfolios_left_side_label'),
    mainPath: '/portfolio',
    feature: 'portfolio_view',
    id: 'portfolio',
    hidden: false,
  },
  {
    icon: 'list',
    tooltip: __('listView_left_side_label'),
    name: __('listView_left_side_label'),
    mainPath: '/tasks/list',
    id: 'listView',
    hidden: false,
  },
  {
    icon: 'time',
    tooltip: __('logs_left_side_label'),
    name: __('logs_left_side_label'),
    mainPath: '/timelog',
    feature: 'time_tracking',
    id: 'timelog',
    hidden: false,
  },
  {
    icon: 'report',
    tooltip: __('reports_left_side_label'),
    name: __('reports_left_side_label'),
    mainPath: '/reports',
    feature: 'reports',
    id: 'reports',
    hidden: false,
  },
  {
    icon: 'workload',
    tooltip: __('workload_left_side_label'),
    name: __('workload_left_side_label'),
    mainPath: '/workload',
    feature: 'workload',
    id: 'workload',
    hidden: false,

  },
  {
    icon: 'comments',
    tooltip: __('comments_left_side_label'),
    name: __('comments_left_side_label'),
    mainPath: '/comments',
    id: 'comments',
    hidden: false,
  },
];
const menuFooterItems = [
  {
    icon: 'notifications',
    tooltip: __('left_sidebar_notifications'),
    iconRight: true,
    name: __('notifications_left_side_label'),
    mainPath: '',
    id: 'notifications',
    notificationsCount: 0,
    hidden: false,
  },
  {
    icon: 'search',
    tooltip: __('left_sidebar_search_label'),
    iconRight: true,
    name: __('search_left_side_label'),
    mainPath: '',
    id: 'search',
    hidden: false,
  },
  {
    icon: 'settings',
    tooltip: __('settings_profile_tooltip'),
    iconRight: true,
    name: __('settings_left_side_label'),
    mainPath: '/settings/profile',
    id: 'settings',
    hidden: false,
  },
];

export default {
  getMenuHeaderItems() {
    return _.cloneDeep(menuHeaderItems);
  },
  getMenuFooterItems() {
    return _.cloneDeep(menuFooterItems);
  },
};
