import app from '../../../app';
import _ from '../../../libs/lodash';

import timeTrackingModel from '../../../models/timeTracking';
import projectsModel from '../../../models/projects';
import groupByTable from './groupByTable';
import timeParser from '../../../helpers/timeParser';
import moment from '../../../libs/moment';
import dateHelper from '../../../helpers/dateFormats';
import exportModule from './export';
import calculateMainWindowSize from '../../../helpers/calculateMainWindowSize';
import logsHeaderHelper from '../../../helpers/logsHeaderHelper';
import globalStore from '../../../store/main';
import rights from "../../../components/rights";

const __ = window.__;

const TYPE = 'timeloglist';

const columnConfigs = [
  {
    id: 'task_name',
    groupId: 'task_id',
    css: 'task_name',
    fillspace: 3,
    header: __('group_by_table_task'),
    sort: 'task_name',
    template: 'groupColumnWithInfo',
    excelFormat: 'middleText',
  },
  {
    id: 'project_name',
    groupId: 'gantt_id',
    css: 'project_name',
    fillspace: 2,
    header: __('group_by_table_project'),
    sort: 'string',
    template: 'projectNameText',
    excelFormat: 'longText',
  },
  {
    id: 'resource_name',
    groupId: 'resource_id',
    css: 'resource_name',
    fillspace: 1,
    header: __('group_by_table_user'),
    sort: 'string',
    template: 'text',
    excelFormat: 'text',
  },
  {
    id: 'date',
    groupId: 'string_date',
    groupFormat: 'date',
    groupSorting: 'task_name',
    css: 'date align_center editable',
    fillspace: 1,
    header: __('group_by_table_date'),
    sort: 'date',
    editor: 'date',
    format: 'date',
    excelFormat: 'date',
    suggest: {
      type: 'calendar',
      padding: 0,
      body: {
        icons: [{
          template() {
            return `<span class='webix_cal_icon_today webix_cal_icon'>${webix.i18n.calendar.today}</span>`;
          },
          on_click: {
            webix_cal_icon_today() {
              this.setValue(new Date());
              this.callEvent('onTodaySet', [this.getSelectedDate()]);
            },
          },
        }],
      },
    },
  },
  {
    id: 'time',
    groupId: 'time',
    groupFormat: 'time',
    css: 'time align_right editable',
    fillspace: 1,
    header: __('group_by_table_time'),
    sort: 'int',
    groupFunctor: 'sum',
    editor: 'text',
    editFormat: 'time',
    parseType: 'time',
    template: 'time',
    excelFormat: 'number',
  },
  {
    id: 'comment',
    css: 'comment editable',
    fillspace: 5,
    header: __('group_by_table_comment'),
    sort: 'string',
    editor: 'commentPopup',
    template: 'text',
    excelFormat: 'longText',
  },
];

const groupByConfig = [
  {
    id: 'task_id',
    text: __('group_by_task'),
  },
  {
    id: 'gantt_id',
    text: __('group_by_project'),
  },
  {
    id: 'resource_id',
    text: __('group_by_user'),
  },
  {
    id: 'string_date',
    text: __('group_by_date'),
  },
  {
    id: 'time',
    text: __('group_by_time'),
  },
];


const webixUI = {
  view: 'window',
  id: 'timeLogListLayout',
  css: 'my-logs-layout tasks-view-list',
  borderless: true,
  // header: false,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  zIndex: 9,
  // unClosable: true,
  position(state) {
    calculateMainWindowSize.apply(this, [state]);
  },
  body: {
    rows: [
      {
        view: 'layout',
        id: 'groupByTableContainer',
        rows: [],
      },
    ],
  },
  on: {
    onHide() {

    },
    onShow() {

    },
  },
};

const innerHandlers = {
  validateOnBeforeEditStop(log) {
    const logData = timeTrackingModel.getLogsByParams(log.gantt_id, log.task_id, log.id);
    const isArchiveProject = !!projectsModel.getArchivedProject(log.gantt_id);
    const taskData = globalStore.getters['tasksModel/getTask'](log.task_id);

    if (!taskData) {
      webix.message({ type: 'warning', text: __('task_was_removed_for_log_edit') });
      return false;
    }

    if (!logData) {
      webix.message({ type: 'warning', text: __('log_was_removed') });
      return false;
    }

    if (isArchiveProject) {
      webix.message({ type: 'warning', text: __('project_was_archived_for_log_edit') });
      return false;
    }

    return true;
  },
  onAfterLoadTable(tableData) {
    innerHandlers.updateHeaderLayout(tableData);
  },
  onAfterEditStopTable(newData, prop, valueState) {
    const logData = timeTrackingModel.getLogsByParams(newData.gantt_id, newData.task_id, newData.id);

    logData[prop] = newData[prop];
    logData.gantt_id = newData.gantt_id;

    logData.date = moment(logData.date).toDate();

    userExtAnalytics.log('report_time_log_list_log_change', {
      commentLength: logData.comment.length,
      time: logData.time,
    });

    return timeTrackingModel.updateLog(logData, true)
      .then(() => {
        gantt.refreshData();// for update data in grid
        // const data = $$('groupByTableTree').serialize();
        // return innerHelpers.prepareTableData(data);
        return innerHelpers.prepareTableData(timeTrackingModel.getLogsByResourceId());
      });
  },
  exportToExcel() {
    const data = $$('groupByTableTree').serialize(); // already filtered data

    if (!data.length) {
      webix.message({ type: 'warning', text: __('no_data_for_export_time_log_list_report') });
      return;
    }

    const isTree = !!data[0].data;

    const options = {
      title: __('title_report_time_tracking'),
      // sheetTitle: 'report_time_log_list_sheet',
      isTree,
      columns: columnConfigs,
      data,
    };

    exportModule.exportToExcel(options);
    userExtAnalytics.log('report_time_log_list_export_done');
  },
  onGroupByChange(value) {
    app.trigger('filter:data:timeloglist');

    if (value) {
      userExtAnalytics.log('report_time_log_list_group_by_change', {
        by: replaceValues(value),
      });
    }

    function replaceValues(values) {
      return values.map(name => {
        switch (name) {
        case 'task_id': return 'task';
        case 'gantt_id': return 'project';
        case 'resource_id': return 'user';
        case 'string_date': return 'date';
        case 'time': return 'time';
        default: return name;
        }
      });
    }
  },
  onAfterSortTable(value) {
    userExtAnalytics.log('report_time_log_list_sort_by_change');
  },
  updateHeaderLayout(tableData, isSetFilter, filterRange) {
    const data = tableData || $$('groupByTableTree').serialize();
    const headerData = logsHeaderHelper(data, filterRange);

    globalStore.commit('headerView/setReportToolbarInfo', {
      total: timeParser.output(headerData.sum, {}),
      range: headerData.range,
      title_locale: 'reports_time_log_list_title',
      total_locale: 'total_time_spent',
    });
  },
  onAfterFilterTable(isFilterApplied) {
    // innerHandlers.updateHeaderLayout(false, isFilterApplied, logsFilter.handlers.getDateRange());
  },
};

const innerHelpers = {
  prepareTableData(logs) {
    const resultData = [];

    _.each(logs, logData => {
      const taskData = globalStore.getters['tasksModel/getTask'](logData.task_id);

      if (!taskData) {
        return;
      }

      const isArchiveProject = !!projectsModel.getArchivedProject(taskData.gantt_id);

      if (isArchiveProject) {
        return;
      }

      const projectData = projectsModel.getProjectDataById(taskData.gantt_id);
      const date = moment(logData.date).startOf('day').toDate();
      let accessRights = rights.project.hasRight(taskData.gantt_id, 'all_tasks');
      const staticFields = rights.project.hasRightSomeOne(taskData.gantt_id, ['static_fields_4_only', 'static_fields'])

      if (!accessRights) {
        accessRights = globalStore.getters['resourcesModel/isUserAssignedToTask'](taskData.gantt_id, taskData.id);
      }

      if (!accessRights || !staticFields) {
        return;
      }

      resultData.push({
        id: logData.id,
        gantt_id: taskData.gantt_id,
        project_name: projectData.name,
        task_id: logData.task_id,
        task_name: taskData.text,
        info_text: taskData.note,
        resource_id: logData.resource_id,
        resource_name: logData.full_name && logData.full_name.trim() || logData.resource_name,
        date,
        string_date: webix.Date.dateToStr(dateHelper.getDateFormat())(date),
        time: logData.time,
        comment: logData.comment,
      });
    });

    return resultData;
  },
};

const init = function () {
  userExtAnalytics.log('reports_time_log_list_open');

  const tableData = innerHelpers.prepareTableData(timeTrackingModel.getLogsByResourceId());
  const tableSettings = {
    sort: {
      as: 'date',
      dir: 'desc',
      by: 'date',
    },
    groupBy: [],
    type: TYPE,
  };// default settings

  $$('timeLogListLayout').show();

  const container = $$('groupByTableContainer');
  const childViews = container.getChildViews();
  const data = {
    tableData,
    tableSettings,
    groupBySelectData: groupByConfig,
    columnsData: _.cloneDeep(columnConfigs),
    emptyDataLocales: {
      data: 'empty_data_logs_group_by_table',
      filter: 'empty_filter_logs_group_by_table',
      prefix: 'logs',
    },
    callbacks: {
      onAfterLoad: innerHandlers.onAfterLoadTable,
      onAfterEditStop: innerHandlers.onAfterEditStopTable,
      validateOnBeforeEditStop: innerHandlers.validateOnBeforeEditStop,
      onGroupByChange: innerHandlers.onGroupByChange,
      onAfterSort: innerHandlers.onAfterSortTable,
      onAfterFilter: innerHandlers.onAfterFilterTable,
    },
  };

  if (childViews.length) {
    container.reconstruct();
  }

  container.addView(groupByTable.init(data));

  globalStore.commit('headerView/setGroupByData', groupByConfig);

  app.trigger('filter:data:timeloglist')
};

app.on('leftSideBar:changedMode', () => {
  $$('timeLogListLayout') && $$('timeLogListLayout').resize();
});

app.on('header:export:report', type => {
  if (type === TYPE) {
    innerHandlers.exportToExcel();
  }
});

const outerObject = {
  init,
  webixUI,
};

export default outerObject;
