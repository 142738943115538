var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style["dependency__row"],
        _vm.isFieldDisabled("dependencies")
          ? _vm.$style["dependency__row_disabled"]
          : "",
      ],
    },
    [
      _c("div", { class: _vm.$style["dependency__row-inner-wrap"] }, [
        _c("img", {
          class: _vm.$style["dependency__image"],
          attrs: {
            src: "/svg/dependencyTypes/" + _vm.dependency.linkType + ".svg",
            alt: "Dependency type image",
          },
          on: {
            mouseenter: _vm.showPreview,
            mouseleave: function ($event) {
              return _vm.$emit("hide-preview")
            },
          },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style["dependency__wrap"] }, [
          _c("div", { class: _vm.$style["dependency__name-wrap"] }, [
            _c("span", { class: _vm.$style["dependency__task-name"] }, [
              _vm._v(
                "\n          " + _vm._s(_vm.dependency.taskName) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _vm.dependency.taskMaster
              ? _c("span", { class: _vm.$style["dependency__task-badge"] }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.locales("settings_dependency_predecessor_label")
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style["dependency__description"] },
            [
              _c("span", { class: _vm.$style["dependency__type"] }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.dependency.linkShortName) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.$style["dependency__lag-label"] }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.locales("settings_dependency_lag") + ":") +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.isFieldDisabled("dependencies")
                ? _c(
                    "span",
                    { class: _vm.$style["dependency__lag_disabled"] },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getConvertedLag(_vm.dependency.linkLag)) +
                          "\n        "
                      ),
                    ]
                  )
                : _c("vgp-input", {
                    key: _vm.dependencyLagInputKey,
                    class: _vm.$style["dependency__lag"],
                    attrs: {
                      value: _vm.getConvertedLag(_vm.dependency.linkLag),
                      border: false,
                      "select-by-focus": "",
                    },
                    on: { onChange: _vm.handleChangeDependencyLag },
                  }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.isFieldDisabled("dependencies")
        ? _c("vgp-icon-button", {
            class: _vm.$style["dependency__delete-button"],
            attrs: { icon: { name: "delete" } },
            on: {
              onClick: function ($event) {
                return _vm.$emit("delete")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }