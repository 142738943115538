<template>
  <div
    :key="'table-row-' + item.id"
    class="table-row with-custom-menu"
    :class="{
      'bg-blue': activeTaskId === item.id,
      'editing': editingTaskId === item.id,
      'ovedue-bg': highlightOverdueTask && item.overdue,
      'selected': selected,
    }"
    :style="{'height': +height+'px'}"
    @click.capture="clickRow($event)"
  >
    <cell
      v-for="(column, index) in columns"
      :key="column.property + '-' + item.id"
      :row-data="item"
      :column="column"
      :extra="extra"
      :options="column.data"
      :discolor="getOpacity"
      :crossout="closedTaskCrossout && item.closed"
      :overdue="highlightOverdueTask && item.overdue"
      :can-show-settings="!activeTaskId"
      :class="{
        'hardwidth': !!column.hardWidth,
        [skins] : true,
        'skins-border-right-0': !!column.emptyHeader,
        'sticky-left': !!stickyLeft[column.property],
        'last-in-row': columns.length === index + 1
      }"
      :is-selected-row="selected"
      :select-mode="selectMode"
      :style="{ left: stickyLeft[column.property] ? stickyLeft[column.property] : 'auto' }"
      @contextmenu.native="!selectMode && $emit('contextmenu', $event)"
      @show:itemSettings="$emit('show:itemSettings', {item: item, section: $event })"
      @change="$emit('change',{task:item, property:column.property, value: $event })"
      @change:custom="$emit('change:custom',{task:item, column:column, value: $event })"
      @show:task-name-editor="$emit('show:task-name-editor', $event)"
      @delete="$emit('delete', item)"
      @select:row="$emit('select', item.id)"
    />
    <moreCell
      :item="item"
      :discolor="(closedTaskDiscolor && item.closed) || selectMode"
      :skins="skins"
      :class="`gp_autotest_listview_item_cell_more`"
      @contextmenu="!selectMode && $emit('contextmenu:morecell' ,$event)"
      @delete="$emit('delete', item)"
      @show:settings="$emit('show:itemSettings', {item:item})"
    />
  </div>
</template>

<script>
import cell from './cells/cell.vue';
import moreCell from './cells/moreCell.vue';

export default {
  name: 'TableRow',

  components: { moreCell, cell },
  props: {
    columns: { type: Array, required: true },
    item: { type: Object, required: true },
    height: { type: Number, required: false, default: 29 },
    activeTaskId: { type: Number | null, required: false, default: null },
    editingTaskId: { type: Number | null, required: false, default: null },
    highlightOverdueTask: { type: Boolean, required: false, default: false },
    closedTaskDiscolor: { type: Boolean, required: false, default: false },
    closedTaskCrossout: { type: Boolean, required: false, default: false },
    stickyLeft: { type: Object, required: true },
    skins: { type: String, required: true },
    selectMode: { type: Boolean, required: false, default: false },
    selected: { type: Boolean, required: false, default: false },
    extra: { type: Array, required: true },
  },
  emits: {
    'delete:item': {}, // task
    'show:itemSettings': {}, // task
    'change:custom': { task: {}, column: {}, value: String },
    change: { task: {}, column: {}, value: String },
  },

  data() {
    return {

    };
  },

  computed: {
    getOpacity() {
      let opacity = 1;

      if (this.closedTaskDiscolor && this.item.closed) opacity -= 0.3;

      if (this.selectMode) opacity -= 0.2;

      return opacity;
    },
  },

  methods: {
    clickRow(e) {
      if (this.activeTaskId) {
        e.stopPropagation();
        this.$emit('show:itemSettings', { item: this.item });
      }

      if (this.selectMode) {
        e.stopPropagation();
        this.$emit('select', this.item.id);
      }
    },
  },

};
</script>

<style lang="less" >
.table-row{
  display: flex;
  transition: background-color .3s;
  background-color: #ffffff;

  &:not(.ovedue-bg):hover{
    background-color: #f3f3f3;
    cursor: pointer;
  }

  .show-only-row-hover{
    display: none;
  }

  &:hover .show-only-row-hover{
    display: flex !important;
  }

  &.bg-blue, &.editing{
    background-color: #e3f2fd !important;
  }

  &.ovedue-bg{
    background-color: #fbf3f3;
    cursor: pointer;
  }

  &.discolor-row{
    opacity: 0.8;
  }

  &.selected{
    background-color: #f3f3f3;
  }

}

</style>
