var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.dropdown, _vm.$style.active] }, [
    _c("ul", [
      _vm.accessToEdit
        ? _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("onClick")
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("roles_edit_" + _vm.type + "_dropdown")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.accessToDelete
        ? _c(
            "li",
            {
              staticClass: "element-li",
              on: {
                click: function ($event) {
                  return _vm.$emit("onDelete")
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.locales("roles_delete_" + _vm.type + "_dropdown")
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }