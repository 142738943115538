var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style["main-space"]] },
    [
      _c("filter-header", {
        staticClass: "gp_autotest_comment_hub_header",
        attrs: {
          projects: _vm.projects,
          "filter-button": _vm.filterButton,
          "filtered-projects": _vm.filteredProjects,
          "tabs-items": _vm.tabsItems,
          "tabs-amounts": _vm.amounts,
        },
        on: {
          change: _vm.selectProjects,
          search: _vm.searchTasks,
          toggle: _vm.buttonFilter,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style["main-sides"]] },
        [
          _vm.preparedTasks.length ||
          _vm.projects.length ||
          _vm.currentStub.stubSvg
            ? _c("tasks-list", {
                class: [
                  _vm.$style["left-side"],
                  "gp_autotest_comment_hub_tasks_list_left_side",
                ],
                attrs: {
                  tasks: _vm.preparedTasks,
                  "task-id": _vm.taskId,
                  "new-comments": _vm.hasNewComments,
                  "parent-paths": _vm.parentPathsMap,
                  height: _vm.calculatedHeight,
                  "current-stub": _vm.currentStub,
                  "scroll-top": _vm.scrollTasksList,
                },
                on: {
                  selectItem: _vm.selectItem,
                  readAllComments: _vm.readAllComments,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          (!_vm.preparedTasks.length && _vm.taskData) ||
          (_vm.preparedTasks.length && !_vm.taskData) ||
          (_vm.preparedTasks.length && _vm.taskData)
            ? _c("comments-task-setting-list", {
                ref: "commentsTaskList",
                class: [
                  _vm.$style["right-side"],
                  "gp_autotest_comment_hub_comments_list_right_side",
                ],
                attrs: { "task-id": _vm.taskId, height: _vm.calculatedHeight },
                on: { scrollTasksList: _vm.handleScrollTasksList },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentStub.stubSvg && !_vm.taskData
        ? _c(
            "div",
            {
              class: [
                _vm.$style["global-task-stub"],
                "gp_autotest_comment_hub_global_stub",
              ],
              style: { height: _vm.calculatedHeight + "px" },
            },
            [
              _c("svgImage", {
                class: [_vm.$style["current-stub"]],
                attrs: { name: _vm.currentStub.stubSvg },
              }),
              _vm._v(" "),
              _c("div", { class: [_vm.$style["no-search-title"]] }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locale(_vm.currentStub.stubTitle)) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { class: [_vm.$style["no-search-text"]] }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.locale(_vm.currentStub.stubText)) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }