var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("skeleton-wrapper", {
          style: { width: "100%" },
          attrs: { id: "skeletonWrapper", "is-calendar": true },
          scopedSlots: _vm._u([
            {
              key: "body",
              fn: function () {
                return [_vm.isShowSkeleton ? _c("skeleton") : _vm._e()]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        !_vm.pricingAccessToCalendar
          ? _c("pricing-stub", {
              attrs: { "is-owner": _vm.isOwner, "plan-title": _vm.planTitle },
            })
          : _c("div", {
              ref: "calendarView",
              class: [
                "calendar_view",
                _vm.isTaskSettingsOpened ? "disabled-layout" : "",
              ],
              style: _vm.styles,
              attrs: { id: "calendar_view_id" },
            }),
        _vm._v(" "),
        _c("new-event-popup", {
          attrs: {
            show: _vm.isShowNewEventPopup,
            date: _vm.newEventDate,
            "project-id": _vm.projectId,
          },
          on: {
            close: function ($event) {
              _vm.isShowNewEventPopup = false
            },
            createTask: _vm.createNewTask,
          },
        }),
        _vm._v(" "),
        _vm.tooltipConfig
          ? _c("details-tooltip", { attrs: { config: _vm.tooltipConfig } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }