var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { class: [_vm.$.skeleton_container] }, [
        _c("div", { class: [_vm.$.skeleton_popup] }, [
          _c("div", { class: _vm.$.loader_container }, [
            _c("div", { class: _vm.$.loader_icon }),
          ]),
          _vm._v(" "),
          _c("div", {
            class: _vm.$.text,
            domProps: { innerHTML: _vm._s(_vm.locale(_vm.text)) },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }