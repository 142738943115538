<template>
  <section
    ref="commentHistory"
    :class="[$style['task-settings-comments']]"
  >
    <div
      :class="$style.header"
    >
      <h2>
        {{ locales('settings_taskComment') }}
      </h2>
    </div>
    <div
      :class="$style['comments__list']"
    >
      <div v-for="(groupComments, groupName) in groupedCommentsByDate">
        <div
          v-if="commentHistoryLabelsShown"
          :class="$style['comments__group-history-date']"
        >
          {{ groupName }}
        </div>
        <div
          v-for="(comment, index) in groupComments"
          :id="comment.id"
          :key="comment.id"
          ref="groupName"
        >
          <comment-item
            :comment="comment"
            @comment-delete="handleClickDeleteComment"
            @edit-comment="$emit('edit-comment', comment)"
          />
        </div>
      </div>
    </div>
    <core-confirm-popup
      :show="currentCommentIdForDelete"
      :text="locales('settings_msg_delete_comment')"
      :ok-button="{ type: 'destructive', title: locales('common_yes') }"
      :cancel-button="{ type: 'secondary', title: locales('common_cancel') }"
      @onClickOk="handleConfirmDeleteComment"
      @onClickCancel="currentCommentIdForDelete = null"
    />
  </section>
</template>

<script>
import { groupBy } from 'lodash';
import CoreConfirmPopup from '$$vueCmp/popups/coreConfirmPopup/coreConfirmPopup.vue';
import { replaceIdsWithMentionsHtml } from '$$helpers/comments';
import moment from '../../../../libs/moment';
import globalStore from '$$store/main';
import constants from '../../../../helpers/constants';
import commentItem from './CommentItem.vue';

export default {
  name: 'TaskSettingsComments',
  components: { CoreConfirmPopup, commentItem },
  inject: ['isFieldDisabled'],
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      tribute: null,
      currentCommentIdForDelete: null,
    };
  },
  computed: {
    commentHistoryLabelsShown() {
      // Show labels if there are comments on at least two different days
      return Object.keys(this.groupedCommentsByDate).length >= 2;
    },

    unreadCommentIds() {
      const commentsIds = this.comments.map(comment => comment.id);
      const unreadTaskCommentsActions = this.$store.state.comments.commentsActions.filter(commentAction => (
        commentsIds.includes(commentAction.commentId) && !commentAction.read
      ));

      return unreadTaskCommentsActions.map(commentAction => commentAction.commentId);
    },

    groupedCommentsByDate() {
      const today = moment().format(`dddd ${user.dateFormat}`);
      const yesterday = moment().add(-1, 'days').format(`dddd ${user.dateFormat}`);

      return groupBy(this.formattedComments, item => {
        const commentDate = moment(item.rawCreatedAt).format(`dddd ${user.dateFormat}`);

        if (commentDate === today) {
          return this.locales('comment_history_today_label');
        }

        if (commentDate === yesterday) {
          return this.locales('comment_history_yesterday_label');
        }

        return commentDate;
      });
    },
    formattedComments() {
      return [...this.comments].map(comment => {
        const {
          id, content, user: { resourceId, firstName, lastName }, createdAt, updatedAt, userId,
        } = comment;
        const momentTimeFormat = user.timeFormat ? 'HH:mm' : 'hh:mm A';
        const userResource = globalStore.getters['resourcesModel/getResourceById'](resourceId);
        const authorFullName = userResource
          ? userResource.name
          : `${firstName} ${lastName} (${__('task_settings_author_deleted_label')})`;
        const authorPhoto = userResource ? userResource.photo : constants.COMMON_USER_PHOTO;

        return {
          id,
          content: replaceIdsWithMentionsHtml(content),
          authorFullName,
          authorId: userId,
          authorPhoto,
          createdAt: moment(createdAt).format(`${user.dateFormat}, ${momentTimeFormat}`),
          rawCreatedAt: createdAt,
          updatedAt: updatedAt ? moment(updatedAt).format(`${user.dateFormat}, ${momentTimeFormat}`) : null,
        };
      }).sort((a, b) => a.id - b.id);
    },
  },
  watch: {
    'taskData.id'() {
      this.$store.dispatch('comments/markAsRead', {
        taskIds: [this.taskData.id],
        commentIds: this.unreadCommentIds
      });
    },
  },

  created() {
    this.$store.dispatch('comments/markAsRead', {
      taskIds: [this.taskData.id],
      commentIds: this.unreadCommentIds,
    });
  },

  methods: {
    getCommentDisplayedDateString(comment) {
      if (comment.updatedAt) {
        return `${this.locales('comment_edited_label')} ${comment.updatedAt}`;
      }

      return comment.createdAt;
    },
    showCommentActions(comment) {
      if (this.isFieldDisabled('comments')) return false;

      return comment.authorId === window.user.id;
    },
    handleClickDeleteComment(commentId) {
      this.currentCommentIdForDelete = commentId;
    },
    handleConfirmDeleteComment() {
      this.$emit('delete-comment', this.currentCommentIdForDelete);
      this.currentCommentIdForDelete = null;
    },
  },
};
</script>

<style module lang="less">

.task-settings-comments {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h2 {
      font-family: Lato-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: #191919;
    }
  }

  .header-actions {
  }

  .comments__list {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .comments__group-history-date {
    width: 100%;
    font-family: Lato-Regular, sans-serif;
    font-size: 10px;
    line-height: 16px;
    padding: 10px 0;
    text-transform: uppercase;
    text-align: center;
    color: #A6A6A6;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &::before, &::after {
      content: '';
      display: block;
      width: 80px;
      height: 1px;
      background: #E5E5E5;
    }
  }

  .comment {
    &__row {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }

    &__author-image {
      width: 32px;
      height: 32px;
      border-radius: 33px;
    }

    &__info-wrap {
      display: flex;
      flex-direction: column;
      border: 1px solid transparent;
      border-radius: 0 4px 4px 4px;
      max-width: 480px;
      padding: 4px 8px;
      &:hover {
        border: 1px solid #F2F2F2;
        background: #FCFCFC;
        .comment__actions {
          opacity: 1;
          transition: 200ms;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 11px;
    }

    &__author {
      font-family: Lato-Bold, sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: #808080;
    }

    &__date {
      font-family: Lato-Regular, sans-serif;
      font-size: 12px;
      line-height: 20px;
      color: #999999;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 4px;
      opacity: 0;
    }

    &__content {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 20px;
      max-width: 100%;
      padding: 4px 0 8px 0;
    }

  }

}

</style>
