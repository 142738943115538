/* eslint-disable */

console.info('\x1b[32m░██████╗░░█████╗░███╗░░██╗████████╗████████╗██████╗░██████╗░░█████╗░\n██╔════╝░██╔══██╗████╗░██║╚══██╔══╝╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗\n██║░░██╗░███████║██╔██╗██║░░░██║░░░░░░██║░░░██████╔╝██████╔╝██║░░██║\n██║░░╚██╗██╔══██║██║╚████║░░░██║░░░░░░██║░░░██╔═══╝░██╔══██╗██║░░██║\n╚██████╔╝██║░░██║██║░╚███║░░░██║░░░░░░██║░░░██║░░░░░██║░░██║╚█████╔╝\n░╚═════╝░╚═╝░░╚═╝╚═╝░░╚══╝░░░╚═╝░░░░░░╚═╝░░░╚═╝░░░░░╚═╝░░╚═╝░╚════╝░\x1b[0m');


import Vue from 'vue';
import VueRouter from "vue-router";
import globalStore from '../store/main';
import app, { appIds } from '../app';
import projectsModel from './../models/projects';
import { parseQuery } from '../helpers/url';
import plansModel from './../models/plans';

import ProjectResources from '../views/resources/main.vue';
import WorkloadPage from '../views/main/workloadPage/workloadPage.vue';
import ProjectPage from './../views/main/ProjectPage.vue';
import PortfolioPage from './../views/portfolios/PortfolioPage.vue';
import HomePage from './../views/main/HomePage.vue';
import MultiviewPage from './../views/main/MultiviewPage.vue';
import ListViewPage from './../views/main/ListViewPage.vue';
import Reports from './../views/reports/reports.vue';
import ReportPage from './../views/reports/reportPage.vue';
import CommentHub from './../views/commentHub/commentHub.vue';
import UserLogs from './../views/timeTracking/userLogs.vue';
import importPreview from './../views/import/importPreview.vue';
// import TaskView from './../views/taskView/taskView.vue';
import TaskSettings from './../views/taskSettings/TaskSettings.vue';
import routerGuard from './guards';
import CommentsList from '../views/comments/CommentsList.vue';
import Integration from '../views/integration/Integration.vue';
import IntegrationSlack from '../views/integration/slack/IntegrationSlackSettings.vue';
import IntegrationMsteams from '../views/integration/msteams/IntegrationMSTeamsSettings.vue';
import NewProject from '../views/newProject/NewProject.vue';
import JiraProject from '../views/newProject/JiraProject.vue';
import ImportProject from '../views/newProject/ImportProject.vue';
import NoProject from '../views/newProject/NoProject.vue';
import TrialCompleteOwner from '../views/newProject/TrialCompleteOwner.vue';
import TrialCompleteUser from '../views/newProject/TrialCompleteUser.vue';
import SubscribeCompleteUser from '../views/newProject/SubscribeCompleteUser.vue';
import SubscribeCompleteOwner from '../views/newProject/SubscribeCompleteOwner.vue';
import vueSample from "../views/vueSample/routeVueComponent.vue";
// settings
import Settings from './../views/profileSettings/Settings.vue';
import Profile from './../views/profileSettings/pages/profile.vue';
import Billing from './../views/profileSettings/pages/billing.vue';
import Team from './../views/profileSettings/pages/team.vue';
import Columns from './../views/profileSettings/pages/columns.vue';
import Statuses from './../views/profileSettings/pages/statuses.vue';
import Security from './../views/profileSettings/pages/security.vue';
import Integrations from './../views/profileSettings/pages/integrations.vue';
import Notifications from './../views/profileSettings/pages/notifications.vue';
import Resources from './../views/resources/main.vue';
import AccountRights from './../views/profileSettings/pages/roleAccountRights.vue';
import ProjectRights from './../views/profileSettings/pages/roleProjectRights.vue';
import OnboardingPage from './../views/onboarding/OnboardingPage.vue';
import teamComponent from "../components/team/main";
import PasswordRestorePage from "../views/passwordRestore/PasswordRestorePage.vue";
import MilestonesChart from "../views/common/VueComponents/charts/MilestonesChart.vue";
import rights from "../components/rights";
import IntegrationGoogle from '../views/integration/googleIntegration/googleIntegration.vue'
import IntegrationOutlook from '../views/integration/outlookIntegration/outlookIntegration.vue'

Vue.use(VueRouter);

const ID_EVENT_APP_NEWPROJECT = 'tabs:newProject';
const ID_EVENT_APP_TEMPLATES_INIT = 'templates:init';
const ID_EVENT_PROJ_CHANGEROUTE = 'projectModel:activeProject:changeRoute';
const ROLES_MODEL_INIT = 'rolesModel:init';

// protects to access selected route by an unprivileged team member
const _privilegedMemberGuard = (to, from, next) => {
  if (!rights.account.hasRight('project_create')) {
    next(false);
    return;
  }

  next();
};

const _activeUser = (to, from, next) => {
  if (!plansModel.isActive()) {
    next(false);
    return;
  }

  next();
};

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'app',
      component: HomePage,
      beforeEnter: routerGuard.guard,
    },
    {
      path: '/password-restore',
      name: 'passwordRestore',
      component: PasswordRestorePage,
      beforeEnter: routerGuard.passwordRestoreGuard
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: OnboardingPage,
      beforeEnter: routerGuard.onboardingGuard
    },
    {
      path: '/newproject',
      name: 'newProject',
      component: NewProject,
      beforeEnter: _privilegedMemberGuard
    },
    {
      path: '/importproject',
      name: 'importProject',
      component: ImportProject,
      beforeEnter: _privilegedMemberGuard
    },
    {
      path: '/jiraproject',
      name: 'importJiraProject',
      component: JiraProject,
      beforeEnter: _privilegedMemberGuard
    },
    {
      path: '/noproject',
      name: 'noProject',
      component: NoProject,
    },
    {
      path: '/trialcompleteuser',
      name: 'trialCompleteUser',
      component: TrialCompleteUser,
      beforeEnter: (to, from, next) => {
        if (!plansModel.isExpiredTrial()) {
          next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/trialcompleteowner',
      name: 'trialCompleteOwner',
      component: TrialCompleteOwner,
      beforeEnter: (to, from, next) => {
        if (!plansModel.isExpiredTrial()) {
          next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/subscribecompleteowner',
      name: 'subscribeCompleteOwner',
      component: SubscribeCompleteOwner,
      beforeEnter: (to, from, next) => {
        if (plansModel.isOverSubscription()) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/subscribecompleteuser',
      name: 'subscribeCompleteUser',
      component: SubscribeCompleteUser,
      beforeEnter: (to, from, next) => {
        if (plansModel.isOverSubscription()) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: '/comments',
      component: CommentHub,
      name: 'comments',
      props: true,
    },
    {
      path: '/integration',
      name: 'integrationRoute',
      component: Integration,
      children: [
        {
          path: 'slack',
          name: 'integrationSlack',
          component: IntegrationSlack,
          beforeEnter: routerGuard.integrationsGuard
        },{
          path: 'msteams',
          name: 'integrationMsteams',
          component: IntegrationMsteams,
          beforeEnter: routerGuard.integrationsGuard
        },{
          path: 'google',
          name: 'integrationGoogle',
          component: IntegrationGoogle,
          // beforeEnter: routerGuard.integrationsGuard
        },{
          path: 'outlook',
          name: 'integrationOutlook',
          component: IntegrationOutlook,
          // beforeEnter: routerGuard.integrationsGuard
        }
      ],
    },
    {
      path: '/tasks/:mode',
      props: true,
      name: "listView",
      component: ListViewPage,
      children: [{
        path: 'task/:taskId',
        name: 'listTaskRoute',
        component: TaskSettings,
        props: function (route) {
          return _.assign(route.params, {parent: 'listView'});
        },
        beforeEnter: routerGuard.guardListRoute
      },
      ],

    },
    {
      path: '/project/:projectId/:mode',
      name: 'project',
      component: ProjectPage,
      props: true,
      children: [
        {
          path: 'task/:taskId',
          name: 'taskRoute',
          component: TaskSettings,
          props: function (route) {
            return _.assign(route.params, {parent: 'project'});
          }
        },
      ],
      beforeEnter: routerGuard.guard
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: PortfolioPage,
      beforeEnter: routerGuard.portfolioGuard
    },
    {
      path: '/importpreview',
      name: 'importPreview',
      component: importPreview,
      props: true
    },
    {
      path: '/workload',
      component: WorkloadPage,
      name: 'workload',
      props: true,
      children: [
        {
          path: 'task/:taskId',
          name: 'workloadTaskRoute',
          component: TaskSettings,
          props: function (route) {
            return _.assign(route.params, {parent: "workload"});
          }
        },
      ],
      beforeEnter: routerGuard.adminGuard
    },
    {
      path: '/reports',
      component: Reports,
      name: 'reports',
      props: true,
      beforeEnter: routerGuard.adminGuard
    },
    {
      path: '/reports/:type',
      component: ReportPage,
      name: 'reportsDetail',
      props: true,
      beforeEnter: routerGuard.adminGuard,
    },
    {
      path: '/settings',
      component: Settings,
      name: 'settings',
      props: true,
      beforeEnter: routerGuard.settingsGuard,
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: Profile,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'billing',
          name: 'billing',
          component: Billing,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'team',
          name: 'team',
          component: Team,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'columns',
          name: 'columns',
          component: Columns,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'statuses',
          name: 'statuses',
          component: Statuses,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'security',
          name: 'security',
          component: Security,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'integrations',
          name: 'integrations',
          component: Integrations,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: Notifications,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'resources/:activeView?',
          name: 'resources',
          props: true,
          component: Resources,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'account_rights',
          name: 'account_rights',
          props: true,
          component: AccountRights,
          beforeEnter: routerGuard.settingsGuard,
        },
        {
          path: 'project_rights',
          name: 'project_rights',
          props: true,
          component: ProjectRights,
          beforeEnter: routerGuard.settingsGuard,
        },
        // {
        //   path: 'resources/:activeView?',
        //   name: 'resources',
        //   props: true,
        //   component: Resources,
        //   beforeEnter: routerGuard.settingsGuard, 
        // duplicate
        // },
        {
          path: '*',
          name: 'defaultSetting',
          beforeEnter: routerGuard.settingsGuard,
        },
      ],
    },
    //
    {
      path: '/timelog',
      component: UserLogs,
      name: 'userLogs',
      props: true,
      beforeEnter: routerGuard.activeAccountGuard,
    },
    {
      path: '/projects/:multiviewID/:mode',
      name: 'projectsMultiView',
      component: MultiviewPage,
      props: true,
      children: [
        {
          path: 'task/:taskId',
          name: 'multiviewTaskRoute',
          component: TaskSettings,
          props: function (route) {
            return _.assign(route.params, {parent: 'projectsMultiView'});
          }
        },
      ],
      beforeEnter: routerGuard.multiViewGuard
    },
    {
      path: '/vue/sample/ganntprothebest',
      name: 'vueSampleGanttProTheBest',
      component: vueSample,
    },
    {
      path: '/milestones-chart',
      name: 'milestonesChart',
      component: MilestonesChart,
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.path.includes('signup')) {
    next();
    return;
  }

  if (to.matched.length === 0) {
    next({name: 'app'});
    return;
  }

  if ([
      'attachmentsRoute',
      'commentsRoute',
      'integrationRoute',
      'teamAndResources',
      'settings',
      'settingsPage',
      'onboarding',
      'passwordRestore'
    ].includes(to.name)
  ) {
    next();
    return;
  }

  if (user.isPasswordRestore) {
    next({ name: 'passwordRestore'});
    return;
  }

  const onboardingActivitySettings = globalStore.getters['user/getActivitySettings']('onboarding');
  if (onboardingActivitySettings && !onboardingActivitySettings.isFinished) {
    next({ name: 'onboarding'});
    return;
  }

  // if the team's trial was expired
  if (plansModel.isExpiredTrial()) {
    if(to.path.includes("settings")) {
      next();
      return;
    }
    if (rights.account.isOwner() && to.name !== 'trialCompleteOwner') {
      // for the owner
      next({name: 'trialCompleteOwner'});
      return;
    }

    if (!rights.account.isOwner() && to.name !== 'trialCompleteUser') {
      // for admins and other members
      next({name: 'trialCompleteUser'});
      return;
    }
  }

  if (plansModel.isOverSubscription()) {
    if(to.path.includes("settings")) {
      next();
      return;
    }
    if (rights.account.isOwner() && to.name !== 'subscribeCompleteOwner') {
      // for the owner
      next({name: 'subscribeCompleteOwner'});
      return;
    }

    if (!rights.account.isOwner() && to.name !== 'subscribeCompleteUser') {
      // for admins and other members
      next({name: 'subscribeCompleteUser'});
      return;
    }
  }

  // if active team has no projects
  const allProjects = projectsModel.getAllProjects();
  const archivedProjects = projectsModel.getArchivedProjects();
  const hasProjects = allProjects && allProjects.length > 0;
  const hasArchivedProjects =  archivedProjects && archivedProjects.length > 0;

  if (hasProjects && to.name === 'noProject') {
    next({name: 'app'});
    return;
  }

  if (!hasProjects) {
    // for the owner and admins
    if (
      rights.account.hasRight('project_create') &&
      plansModel.isActiveAccount() &&
      !hasArchivedProjects &&
      (
        to.name !== 'newProject' &&
        to.name !== 'importProject' &&
        to.name !== 'importJiraProject' &&
        to.name !== 'importPreview'
      )
    ) {
      if (to.path.includes("settings")) {
        next();
        return;
      } else if (
        to.name === 'comments'
      ) {
        next();
        return;
      } else if (
        to.name === 'portfolio'
      ) {
        next();
        return;
      } else if (
        to.path.includes("/integration/google") || to.path.includes("/integration/outlook" )
      ) {
        next();
        return;
      } else {
        next({ name: 'newProject' });
        return;
      }
    }

    // for all other users
    if (
      !rights.account.hasRight('project_create') &&
      plansModel.isActiveAccount() &&
      to.name !== 'noProject'
    ) {
      if(to.path.includes("settings") || to.path.includes("integration") || !app.config.mode.isBase) {
        next();
        return;
      }
      next({name: 'noProject'});
      return;
    }
  }

  if (to.name === 'project' || to.name === 'projectsMultiView') {
    app.trigger('hide:filter:popup');
    app.trigger("popup:show");
  }

  if( !rights.account.hasRight('project_create') && ['importJiraProject', 'newProject', 'importProject'].includes(to.name) ){
    next({name: 'app'});
    return;
  }

  next();
});

router.afterEach((to, from) => {

  // !TODO: separate function
  const keyName = 'notifypreview';
  const queryParsed = window.location.search.length > 0 && parseQuery(window.location.search);
  const previewLang = queryParsed && queryParsed[keyName];
  const previewData = previewLang && JSON.parse(localStorage.getItem(keyName));

  app.trigger('app:route:after:changed', to, from);

  // console.warn('==== queryParsed', previewLang, previewData); // !DEBUG
  if (previewData && user.userRole === 'admin') {
    app.trigger(appIds.events.ID_EVENT_ROUTER_SHOW_PREVIEW, previewLang, previewData);
    localStorage.removeItem(keyName);
  }
  ///

  app.trigger("popup:closeOther");

  const isProject = to.name === 'project';
  const isWorkload = to.name === 'workload' || to.name === 'workloadTaskRoute' || to.params.mode === 'workload';
  const fromWorkload = from.name === 'workload' || from.name === 'workloadTaskRoute' || from.params.mode === 'workload';
  const isListView = to.name === "listView";
  const isMultiView = to.name === "projectsMultiView";
  const isGanttMode = to.params.mode === 'gantt';

  if ((isProject && isGanttMode) || isMultiView) {
    app.trigger('gantt:keyboard:enable');
  } else {
    app.trigger('gantt:keyboard:disable');
  }

  gantt.is_workload_mode = isWorkload;

  if (isMultiView && !isWorkload) {
    app.trigger('init:workload');
  }

  if (fromWorkload && !isWorkload) {
    app.trigger('before:change:globalWorkload:route');
  }

  //temporary
  if (to.query.hasOwnProperty('showPricing') && rights.account.isOwner()()) {
    setTimeout(() => {
      if (plansModel.isTrial()) {
        app.trigger('paymentPlanInfo:show', {type: 'default'});
      } else {
        app.trigger('upgradePopup:show');
      }
    }, 100);
  }

  if (to.query.hasOwnProperty('filter')) {
    // if (!isListView) {
    // app.trigger('show:filter:popup');
    // }

    app.trigger('apply:filter:by:id', +to.query.filter);
  } else if (from.query.hasOwnProperty('filter')) {
    app.trigger('hide:filter:popup');
  }
});

app.on(ID_EVENT_PROJ_CHANGEROUTE, ganttId => {
  //////
  router.push('/project/' + ganttId + '/gantt');
});

// an "ID_EVENT_APP_NEWPROJECT" event is invoked by project creation button or from the
// project model ("setActiveProject" function)
app.on(ID_EVENT_APP_NEWPROJECT, () => {
  //////
  router.push({name: 'newProject'});
});

// after starting an app
app.on(ROLES_MODEL_INIT, function () {
  if (plansModel.isExpiredTrial()) {

    if (rights.account.isOwner()) {
      router.push({
        name: 'trialCompleteOwner'
      });
    } else {
      router.push({
        name: 'trialCompleteUser'
      });
    }
  }
});

// after starting an app
app.on(ROLES_MODEL_INIT, function () {
  if (plansModel.isOverSubscription()) {
    if (rights.account.isOwner()) {
      router.push({
        name: 'subscribeCompleteOwner'
      });
    } else {
      router.push({
        name: 'subscribeCompleteUser'
      });
    }
  }
});

export default router;
