import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const ResourcesStore = {
  namespaced: true,
  state: {
    data: [
      {
        id: 0,
        photo: 'string',
        name: 'string',
        email: 'user@example.com',
        userId: 0,
        teamId: 0,
        colorId: 0,
        description: '',
        isInvited: 0,
        accountRoleId: 0,
        resourceProjects: [
          {
            projectId: 0,
            projectRoleId: 0,
            cost: 0,
            type: 0,
            rights: null, // deprecated
          },
        ],
        customDays: [
          {
            id: 'uuid',
            from: 'string',
            to: 'string',
            workingHours: 'int',
            repeat: 'string',
            repeatFrom: 'string',
            repeatTo: 'string',
            title: 'string',
            type: 'string',
          },
        ],
        workingHours: 24,
        workingDays: [
          7,
        ],
      },
    ],
  },
  getters,
  mutations,
  actions,
};

export default ResourcesStore;
