import { isEmpty } from 'lodash';

export default {

  getAttachmentsByProjectAndTaskId: state => (projectId, taskId) => {
    if (state.projectAttachmentsMap[projectId]) {
      return state.projectAttachmentsMap[projectId].filter(item => item.taskId === taskId);
    }

    return [];
  },

  getAllAttachments(state) {
    if (isEmpty(state.projectAttachmentsMap)) {
      return [];
    }

    return Object.values(state.projectAttachmentsMap).flat();
  },

  getAttachmentById: (state, getters) => id => getters.getAllAttachments.find(item => item.id === id),

  isGoogleAuthApiLoaded: state => state.googleDriveData.googleAuthApiLoaded,
  isGooglePickerApiLoaded: state => state.googleDriveData.googlePickerApiLoaded,
  getGoogleUser: state => state.googleDriveData.user,

  isMicrosoftAuthApiLoaded: state => state.oneDriveData.microsoftAuthApiLoaded,
  isMicrosoftPickerApiLoaded: state => state.oneDriveData.microsoftPickerApiLoaded,
  getMicrosoftUser: state => state.oneDriveData.user,

  isAttachmentsAlreadyFetchedForProject: state => projectId => !!state.projectAttachmentsMap[projectId],
};
