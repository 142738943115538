<template>
  <div :class="$style.text">{{ cell.getValue() }}</div>
</template>

<script>

export default {
  name: "TextCell",
  props: ['context'],
  computed: {
    cell() {
      return this.context.cell;
    }
  }
}
</script>

<style module src="./less/textCell.less"></style>
