import app from '../../../app';
import _ from '../../../libs/lodash';

import dateHelper from '../../../helpers/dateFormats';
import customHelper from '../../../helpers/custom';
import timeParser from '../../../helpers/timeParser';

import globalStore from '../../../store/main';

const __ = window.__;

const _state = {
  columns: [],
  data: [],
  callbacks: {},
  tableSettings: {},
  cacheBreadCrumbs: {},
};

const init = function (data) {
  const columns = innerHelpers.prepareColumnsData(data.columnsData);
  const userActivity = _.find(user.activity, o => o.name === data.tableSettings.type);

  _state.tableSettings = data.tableSettings;
  _state.callbacks = data.callbacks;
  _state.cacheBreadCrumbs = {};

  if (userActivity) {
    _state.tableSettings = _.assign(JSON.parse(userActivity.settings), { type: data.tableSettings.type });
  }

  _state.columns = columns;
  _state.data = data.tableData;

  globalStore.commit('headerView/setGroupByActiveData', _state.tableSettings.groupBy);

  return {
    view: 'layout',
    id: 'groupByTableLayout',
    rows: [
      {
        view: 'treetableWithOverlay',
        id: 'groupByTableTree',
        css: 'group_by_table_tree',
        resizeColumn: true,
        headerRowHeight: 38, // 60
        rowHeight: 30,
        borderless: true,
        margin: 10,
        editable: true,
        scrollY: true,
        scrollX: false,
        prerender: false,
        fixedRowHeight: false,
        navigation: false,
        select: 'cell',
        columns,
        data: data.tableData,
        hover: 'hover_row',
        scheme: {
          $change(item) {
            if (item.data) {
              item.$css = 'parent';
            }
          },
        },
        type: {
          groupValue(obj, type) {
            const tree = $$('groupByTableTree');

            if (tree.isBranch(obj.id)) {
              tree.setRowHeight(obj.id, 36);
            }

            return obj.groupValue;
          },
        },
        on: {
          onAfterFilter(isFilterApplied, filterState) {
            const table = $$('groupByTableTree');
            const tableData = table.serialize();

            innerHandlers.updateGroupValues();

            if (!tableData.length) {
              const key = isFilterApplied ? 'filter' : 'data';

              this.showOverlay(`<div class="empty_${`${key}_${data.emptyDataLocales.prefix}`}">${__(data.emptyDataLocales[key])}</div>`);
            } else {
              this.hideOverlay();
            }

            data.callbacks.onAfterFilter(isFilterApplied, filterState);
          },
          onAfterLoad() {
            data.callbacks.onAfterLoad(this.serialize());

            const sortConfig = _state.tableSettings.sort;

            if (innerColumnHelpers.sort[sortConfig.as]) {
              sortConfig.as = innerColumnHelpers.sort[sortConfig.as];
            }

            this.blockEvent();
            innerHandlers.changeGroupBy(_state.tableSettings.groupBy);
            this.sort(sortConfig);

            setTimeout(() => {
              this.markSorting(sortConfig.by, sortConfig.dir);
            }, 0);

            this.unblockEvent();

            if (!this.serialize().length) {
              this.showOverlay(`<div class="empty_data_${data.emptyDataLocales.prefix}">${__(data.emptyDataLocales.data)}</div>`);
            } else {
              this.hideOverlay();
            }
          },
          onAfterSort() {
            const state = this.getState();
            const asSort = _.isFunction(this.getColumnConfig(state.sort.id).sort) ? state.sort.id : this.getColumnConfig(state.sort.id).sort;

            _state.tableSettings.sort = {
              as: asSort,
              dir: state.sort.dir,
              by: state.sort.id,
            };

            innerHandlers.saveUserSettings();

            if (_state.tableSettings.groupBy.length) {
              this.blockEvent();
              innerHandlers.changeGroupBy(_state.tableSettings.groupBy);
              this.unblockEvent();

              app.trigger('filter:data:user-logs');
            }

            state && state.sort && data.callbacks.onAfterSort(state.sort);
          },
          onAfterEditStart(cell) {
            const editor = this.getNode().querySelector('input');
            const columnConfig = this.getColumnConfig(cell.column);

            if (editor) {
              editor.className = columnConfig.css;
            }
          },
          onAfterEditStop(state, editor) {
            const editedRow = this.getItem(editor.row);
            const scrollState = this.getScrollState();
            let isEqual = state.old === state.value;

            if (editor.config.editor === 'date') {
              isEqual = +state.old === +state.value;
            }

            if (isEqual) {
              return;
            }

            data.callbacks.onAfterEditStop(editedRow, editor.column, state)
              .then(newTableData => {
                const table = $$('groupByTableTree');

                _state.data = newTableData;
                // innerHandlers.changeGroupBy(_state.tableSettings.groupBy);
                table.clearAll();
                table.parse(newTableData);

                if (_state.tableSettings.groupBy.length) {
                  this.blockEvent();
                  innerHandlers.changeGroupBy(_state.tableSettings.groupBy);
                  this.unblockEvent();

                  app.trigger('filter:data:user-logs');
                }

                data.callbacks.onGroupByChange();
                this.scrollTo(scrollState.x, scrollState.y);
              });
          },
          onBeforeSelect(cell, preserve) {
            const columnConfig = this.getColumnConfig(cell.column);

            if (!_.includes(['date', 'popup'], columnConfig.editor) || this.isBranch(cell.row)) {
              this.clearSelection();

              return false;
            }
          },
          onBeforeEditStart(cell) {
            if (this.isBranch(cell.row)) {
              return false;
            }
          },
          onBeforeEditStop(state, editor) {
            const editedRow = this.getItem(editor.row);

            if (editor.config.parseType === 'time') {
              const parsedObj = timeParser.input(state.value, {});

              state.value = parsedObj ? parsedObj.value : state.old;
            }

            if (_.isString(state.value)) {
              state.value = state.value.trim();
            }

            if (!data.callbacks.validateOnBeforeEditStop(editedRow)) {
              state.value = state.old;
            }
          },
          onScrollY() {
            webix.callEvent('onClick', []);
          },
          onMouseMove(obj, e, node) {
            data.callbacks.onMouseMove && data.callbacks.onMouseMove(obj, node);
          },
          // ----custom events----
          onAfterRangeCalculating() {
            innerHandlers.updateGroupValues();
          },
        },
      },
    ],
  };
};

const innerHandlers = {
  changeGroupBy(groupByIds, saveState) {
    const table = $$('groupByTableTree');
    const columnsData = _state.columns;
    let initData = _state.data;
    let groupedData = [];

    table.getNode().classList.add('grouped');

    _state.tableSettings.groupBy = groupByIds;

    saveState && innerHandlers.saveUserSettings();

    if (!groupByIds.length) {
      table.clearAll();
      table.parse(initData);
      table.getNode().classList.remove('grouped');

      return;
    }

    const grouping = function (data, groupById) {
      const column = _.find(columnsData, col => col.groupId === groupById);
      const groupByLevel = _.indexOf(groupByIds, groupById);
      const result = [];

      const groupedObj = _.groupBy(data, groupById);

      _.each(groupedObj, (value, key) => {
        const nextGroupById = groupByIds[groupByLevel + 1];
        const groupedItem = { open: true };
        let groupValue = _.find(initData, o => o[groupById] == key)[column.id];

        if (column.groupFormat) {
          if (column.groupSorting) {
            groupedItem[column.groupSorting] = groupValue;
          }
          groupValue = innerColumnHelpers.format[column.groupFormat](groupValue);
        }

        groupedItem.groupValue = groupValue;

        if (nextGroupById) {
          value = grouping(value, nextGroupById); // recursion
        }

        groupedItem.data = value;

        _.each(columnsData, column => {
          if (column.groupFunctor) {
            groupedItem[column.id] = innerHelpers.functor[column.groupFunctor](value, column.id);
          }
        });

        result.push(groupedItem);
      });

      return result;
    };

    if (groupByIds.includes('string_date')) {
      initData = initData.sort((a, b) => new Date(b.date) - new Date(a.date));

      const sortDirection = _state.tableSettings.sort.dir;

      if (sortDirection === 'desc') {
        initData.reverse();
      }
    }

    groupedData = grouping(initData, groupByIds[0]);

    table.clearAll();
    table.parse(groupedData);
  },
  saveUserSettings() {
    globalStore.dispatch('user/updateActivitySettings', {
      activityName: _state.tableSettings.type,
      settings: {
        groupBy: _state.tableSettings.groupBy,
        sort: _state.tableSettings.sort,
      },
    });
  },
  updateGroupValues() {
    const table = $$('groupByTableTree');
    const tableData = table.serialize();

    const updateValues = function (data) {
      _.each(data, obj => {
        if (obj.data) {
          updateValues(obj.data);

          _.each(_state.columns, column => {
            if (column.groupFunctor) {
              obj[column.id] = innerHelpers.functor[column.groupFunctor](obj.data, column.id);
              table.updateItem(obj.id, obj);
            }
          });
        }
      });
    };

    updateValues(tableData);
  },
};

const innerHelpers = {
  prepareColumnsData(columnConfigs) {
    const columnsData = [];

    _.each(columnConfigs, columnConfig => {
      _.each(innerColumnHelpers, (helper, helperKey) => {
        const configValue = columnConfig[helperKey];

        if (configValue) {
          if (helperKey === 'template') {
            if (!_.isFunction(columnConfig[helperKey])) {
              columnConfig[helperKey] = helper[configValue](columnConfig.id);
            }

            return;
          }
          columnConfig[helperKey] = helper[configValue] || configValue;
        }
      });

      columnsData.push(columnConfig);
    });

    return columnsData;
  },
  functor: {
    sum(data, prop) {
      const getSum = function (data) {
        return _.reduce(data, (sum, obj) => sum + (obj.data ? getSum(obj.data) : Number(obj[prop])), 0);
      };

      return getSum(data);
    },
    sum_range(data, prop) {
      const getSum = function (data) {
        return _.reduce(data, (sum, obj) => {
          let num = Number(obj[prop]);

          if (obj[`range_${prop}`] !== undefined) {
            num = obj[`range_${prop}`];
          }

          return sum + (obj.data ? getSum(obj.data) : num);
        }, 0);
      };

      return getSum(data);
    },
  },
  getInfoIconTemplate(title, description, taskId) {
    return `<div class='gantt_notification_tooltip tooltip-gantt' data-id='${taskId}' data-html='true'>
    <div class='tooltip-gantt-html'>
    
    <div class='tooltip-gantt-html-title'>${customHelper.formatTaskName(title)}</div>
    ${description ? `<div class='tooltip-gantt-html-description ql-snow ql-editor'>${description}
    </div><div class='tooltip_gantt_show_more'><a>${__('gantt_tooltip_show_more')}</a></div>` : ''}
    </div></div>`;
  },
  getProjectNameTemplateData(obj, field) {
    const id = obj.task_id || obj.id;
    let text = '';

    if (_state.cacheBreadCrumbs[id]) {
      text = _state.cacheBreadCrumbs[id];
    } else {
      const breadCrumbsTasks = globalStore.getters['tasksModel/getDataForBreadcrumbs'](id, obj.gantt_id, {});
      const projectNameWay = breadCrumbsTasks.reduce((sum, elem) => `${sum} / ${elem.text}`, '');

      text = obj[field] + projectNameWay;
      _state.cacheBreadCrumbs[id] = text;
    }

    text = customHelper.formatTaskName(text);

    return {
      text,
      icon: `<div class='gantt_notification_tooltip tooltip-gantt project-icon' data-html='true'>
      <div class='tooltip-gantt-html'>
      <div class='tooltip-gantt-html-description no-title ql-snow ql-editor'>${text}</div>
      </div></div>`,
    };
  },
  getSortRangeFunctor(prop) {
    return function (a, b) {
      if (!_.isObject(a) && !_.isObject(b)) {
        if (a === undefined && b === undefined) {
          return false;
        }

        return a > b ? 1 : -1;
      }

      if (a[prop] === undefined && b[prop] === undefined) {
        return false;
      }

      let itemA = a[prop];
      let itemB = b[prop];

      if (a[`range_${prop}`] !== undefined) {
        itemA = a[`range_${prop}`];
      }

      if (b[`range_${prop}`] !== undefined) {
        itemB = b[`range_${prop}`];
      }

      return itemA > itemB ? 1 : -1;
    };
  },
  getGroupSortFunctor(prop) {
    return function (a, b) {
      if ((!_.isObject(a) || _.isDate(a)) && (!_.isObject(b) || _.isDate(b))) {
        if (a === undefined && b === undefined) {
          return false;
        }

        return a > b ? 1 : -1;
      }

      if (a[prop] === undefined && b[prop] === undefined) {
        return false;
      }

      return a[prop] > b[prop] ? 1 : -1;
    };
  },
};

const innerColumnHelpers = {
  editFormat: {
    time(value) {
      return timeParser.output(value, {});
    },
  },
  format: {
    date(value) {
      return webix.Date.dateToStr(dateHelper.getDateFormat())(value);
    },
    time(value) {
      return timeParser.output(value, {});
    },
  },
  sort: {
    cost: innerHelpers.getSortRangeFunctor('cost'),
    actual_cost: innerHelpers.getSortRangeFunctor('actual_cost'),
    task_name: innerHelpers.getGroupSortFunctor('task_name'),
  },
  template: {
    time(field) {
      return function (obj) {
        return timeParser.output(obj[field], {});
      };
    },
    text(field) {
      return function (obj) {
        return `<div class="text">${obj[field] || ''}</div>`;
      };
    },
    textWithInfo(field) {
      return function (obj) {
        if (!obj[field]) {
          return '';
        }

        const icon = innerHelpers.getInfoIconTemplate(obj[field], obj.info_text, obj.task_id);
        const text = customHelper.formatTaskName(obj[field]);

        return `<div class="text_wrap" '><span class="text">${text}</span> <span class="icon">${icon}</span></div>`;
      };
    },
    groupColumn(field) {
      return function (obj, common) {
        const groupValue = common.groupValue(obj, common)
          ? `<span class="text">${common.groupValue(obj, common)}</span>`
          : `<span class="text">${obj[field]}</span>`;

        return common.treetable(obj, common) + groupValue;
      };
    },
    groupColumnWithInfo(field) {
      return function (obj, common) {
        let groupValue = common.groupValue(obj, common);

        groupValue = groupValue ? `<span class="text">${customHelper.formatTaskName(groupValue)}</span>` : false;
        let icon = '';
        let text = obj[field];

        if (!groupValue) {
          icon = innerHelpers.getInfoIconTemplate(obj[field], obj.info_text, obj.task_id);
        }

        text = text && (typeof text === 'string') ? customHelper.formatTaskName(text) : text;
        const textHtml = `<div class="text_wrap"><span class="text">${text}</span> <span class="icon">${icon}</span></div>`;

        return common.treetable(obj, common) + (groupValue || textHtml);
      };
    },
    projectNameText(field) {
      return function (obj) {
        if (!obj[field]) {
          return '';
        }

        const data = innerHelpers.getProjectNameTemplateData(obj, field);

        return `<div class="text_wrap"><span class="text">${data.text}</span> <span class="icon">${data.icon}</span></div>`;
      };
    },
    groupProjectNameText(field) {
      return function (obj, common) {
        const groupValue = common.groupValue(obj, common) ? `<span class="text">${common.groupValue(obj, common)}</span>` : false;
        let icon = '';
        let text = obj[field];

        if (!groupValue) {
          const data = innerHelpers.getProjectNameTemplateData(obj, field);

          text = data.text;
          icon = data.icon;
        }

        text = text && (typeof text === 'string') ? customHelper.formatTaskName(text) : text;

        const textHtml = `<div class="text_wrap"><span class="text">${text}</span> <span class="icon">${icon}</span></div>`;

        return common.treetable(obj, common) + (groupValue || textHtml);
      };
    },
  },
};

app.on('header:change:groupByTable', data => {
  innerHandlers.changeGroupBy(data || [], true);
  _state.callbacks.onGroupByChange(data);
});

export default {
  init,
};
