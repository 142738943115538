var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upgrade-plan-popup" }, [
    _c("div", { staticClass: "head" }, [
      _c("p", { staticClass: "main-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.locales("" + _vm.config.title)) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.config.subtitle
        ? _c("div", {
            staticClass: "desc",
            domProps: { innerHTML: _vm._s(_vm.config.subtitle) },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.loader
      ? _c("div", { staticClass: "loader-body" }, [_c("loader")], 1)
      : _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "edit-panel" }, [
            _c(
              "div",
              { staticClass: "edit-panel__controls" },
              [
                _c("user-count-select", {
                  attrs: {
                    label: _vm.locales("upgrade_select_label"),
                    "class-name": "borderless",
                    "is-annual": _vm.isAnnual,
                    "is-current-plan": _vm.isCurrentPlan,
                    "number-of-plan": _vm.numberOfPlanItems,
                    "is-popup": true,
                    type: _vm.config.mode,
                    plan: _vm.config.newPlan,
                  },
                }),
                _vm._v(" "),
                !_vm.config.isAnnual &&
                (_vm.config.mode === "upgrade-plan" ||
                  _vm.config.mode === "change-team-size")
                  ? _c(
                      "div",
                      {
                        class: [
                          "toggle-widget",
                          _vm.isAnnual ? "annual" : "month",
                        ],
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "toggle-widget__text toggle-widget__text--month",
                            on: {
                              click: function ($event) {
                                return _vm.toggleAnnual(false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.locales("plans_monthly_locale")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "toggle-rounded",
                            on: {
                              click: function ($event) {
                                return _vm.toggleAnnual(!_vm.isAnnual)
                              },
                            },
                          },
                          [_c("div", { staticClass: "toggle-rounded__target" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "toggle-widget__text toggle-widget__text--annual",
                            on: {
                              click: function ($event) {
                                return _vm.toggleAnnual(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.locales("plans_annually_locale")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "toggle-widget__sticky-item",
                              _vm.isAnnual && _vm.nextUserCount === 1
                                ? "dnone"
                                : "",
                            ],
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "toggle-widget__text light" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.locales("save_up_locale")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "edit-panel__plans" }, [
              _c("div", { staticClass: "plan-info" }, [
                _c("div", { staticClass: "plan-info__about" }, [
                  _c("p", { staticClass: "inner-text label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.locales("upgrade_popup_current_label")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "inner-text name" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.userCount +
                            " " +
                            "" +
                            (+_vm.userCount === 1
                              ? _vm.locales("one_user_locale")
                              : _vm.locales("users_locale"))
                        ) +
                        " "
                    ),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          "" +
                            _vm.locales(
                              _vm.config.currentPlan.name + "_plan_locale"
                            )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "inner-text sum" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          "$" +
                            _vm.planDetails.subscriptionPrice +
                            " " +
                            (_vm.subscriptionPeriod
                              ? _vm.locales("upgrade_period_annual")
                              : _vm.locales("upgrade_period_month"))
                        ) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isShowTotalPrice
                  ? _c("div", { staticClass: "plan-info__total" }, [
                      _c("p", { staticClass: "inner-text name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.locales("upgrade_balance_key")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "inner-text label" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              "$" + +_vm.planDetails.unusedBudget.toFixed(2)
                            )
                          ),
                        ]),
                        _vm._v(
                          " (" +
                            _vm._s(
                              _vm.planDetails.unusedDays +
                                " " +
                                _vm.locales("days_locale")
                            ) +
                            ")\n            "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "plan-info",
                    _vm.isCurrentUserCount &&
                    _vm.isCurrentPlan &&
                    _vm.isCurrentPeriod &&
                    (_vm.config.mode === "change-team-size" ||
                      _vm.config.mode === "upgrade-plan")
                      ? "is-disable"
                      : "",
                  ],
                },
                [
                  _c("div", { staticClass: "plan-info__about" }, [
                    _c("p", { staticClass: "inner-text label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.locales("upgrade_popup_updated_label")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "inner-text name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.nextUserCount +
                              " " +
                              "" +
                              (+_vm.nextUserCount === 1
                                ? _vm.locales("one_user_locale")
                                : _vm.locales("users_locale"))
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", { class: _vm.config.newPlan.name }, [
                        _vm._v(
                          _vm._s(
                            "" +
                              _vm.locales(
                                _vm.config.newPlan.name + "_plan_locale"
                              )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "inner-text sum" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            "$" +
                              _vm.newSubcsriptionPrice +
                              " " +
                              (_vm.isAnnual
                                ? _vm.locales("upgrade_period_annual")
                                : _vm.locales("upgrade_period_month"))
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.isShowTotalPrice
                    ? _c("div", { staticClass: "plan-info__total" }, [
                        _c("p", { staticClass: "inner-text name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.locales("upgrade_pay_extra_key")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "inner-text label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s("$" + _vm.payExtra) +
                              "\n            "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "edit-panel__period" }, [
              _c("p", { staticClass: "inner-text label" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.locales("next_payment_locale") +
                        ": " +
                        (_vm.isCurrentUserCount &&
                        _vm.isCurrentPlan &&
                        _vm.isCurrentPeriod &&
                        (_vm.config.mode === "change-team-size" ||
                          _vm.config.mode === "upgrade-plan")
                          ? _vm.currentNextPaymentDay
                          : _vm.newPaymentDay)
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "inner-text small payment-footer-info",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.locales("next_payment_description_locale")
                  ),
                },
                on: { click: _vm.liveChatClick },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-panel" }, [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c("p", { staticClass: "form-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.locales("upgrade_form_title")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-box" }, [
                  _c("div", { staticClass: "input-box__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("upgrade_form_label_plan")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        "input-box__input input-box__plan-name",
                        _vm.config.newPlan.name,
                      ],
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            "" +
                              _vm.locales(
                                _vm.config.newPlan.name + "_plan_locale"
                              )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-box" }, [
                  _c("div", { staticClass: "input-box__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("upgrade_form_label_team")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-box__input" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.nextUserCount +
                            " " +
                            _vm.locales("input_value_editors")
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-box" }, [
                  _c("div", { staticClass: "input-box__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("upgrade_form_label_period")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-box__input" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.newPaymentPeriod) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-total" }, [
                  _c("p", { staticClass: "form-total__text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.locales("upgrade_tottal_label")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-total__text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s("$" + _vm.payExtra) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "payment-remark-upgrade-info" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.locales("upgrade_remark_about_payment_and_tax")
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("global-btn", {
                  attrs: {
                    config: {
                      isGlobal: true,
                      size: "normal",
                      color: "blue",
                      width: "100%",
                      locale: _vm.locales("apply_locale"),
                      isDisable:
                        (_vm.isCurrentUserCount &&
                          _vm.isCurrentPlan &&
                          _vm.isCurrentPeriod &&
                          (_vm.config.mode === "change-team-size" ||
                            _vm.config.mode === "upgrade-plan")) ||
                        _vm.isDisabledApply,
                      handler: _vm.onApplyNewPlan,
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow-delimiter" }, [
      _c("div", { staticClass: "arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }