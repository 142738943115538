<template>
  <div
    :class="[
      $.chart,
      $.chart_milestones,
    ]"
  >
    <div :class="$.header">
      <div :class="$.title">
        {{ locales(`chart_${chart.key}_title`) }}
      </div>
      <vgp-switch
        v-if="statusSwitch"
        :is-toggle-on="chart.status"
        @onChange="$emit('onToggleStatus')"
      />
    </div>
    <div
      v-if="description"
      :class="$.description"
    >
      {{ locales(`chart_${chart.key}_description`) }}
    </div>
    <div :class="$.wrapper">
      <div :class="$.row">
        <div :class="$.col">
          <div
            ref="chart"
            :class="$.body"
          />
          <div
            :class="[
              $.footer,
              isShowStub ? $.invisible : '',
            ]"
          >
            <div :class="$.item">
              <div :class="$.label">
                {{ locales('overview_total_milestones') }}
              </div>
              <div :class="$.value">
                {{ data.total }}
              </div>
            </div>
            <div :class="$.item">
              <div :class="$.label">
                {{ locales('overview_overdue_milestones') }}
              </div>
              <div :class="$.value">
                {{ data.overdue }}
              </div>
            </div>
          </div>
        </div>
        <div :class="$.col">
          <div
            :class="[
              $.table,
              this.static ? $.static : '',
            ]"
          >
            <div
              :class="$.table_head"
              :style="scrollbarWidth ? `margin-right: ${scrollbarWidth}px;` : ''"
            >
              <div :class="[$.table_cell, $.cell_name, $.text_ellipsis]">
                {{ locales('overview_milestone') }}
              </div>
              <div :class="[$.table_cell, $.cell_date, $.text_ellipsis]">
                {{ locales('overview_date') }}
              </div>
              <div :class="[$.table_cell, $.cell_status, $.text_ellipsis]">
                {{ locales('overview_status') }}
              </div>
            </div>
            <div
              ref="tableBody"
              :class="[$.table_body, 'gantt_scroll']"
            >
              <div ref="tableInner">
                <div
                  :class="$.table_row"
                  v-for="(milestone, index) in data.milestones"
                  :key="milestone.id"
                  :id="milestone.id"
                  ref="tableRow"
                >
                  <tooltip
                    :class="[$.table_cell, $.cell_name]"
                    :content="`Project: ${milestone.projectName}`"
                    :position="'top'"
                    :delay="250"
                    :triggerTarget="$refs.tableRow && $refs.tableRow[index]"
                    :hide="!milestone.projectName"
                  >
                    <template #body>
                      <div :class="$.text_ellipsis">
                        {{ milestone.text }}
                      </div>
                    </template>
                  </tooltip>
                  <div :class="[$.table_cell, $.cell_date, $.text_ellipsis]">
                    {{ milestone.formatDate }}
                  </div>
                  <div
                    :class="[$.table_cell, $.cell_status, $.text_ellipsis]"
                    :style="`color: ${milestone.statusColor};`"
                  >
                    {{ milestone.statusName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isShowStub"
        :class="$.stub"
      >
        <div :class="$.stub_item" />
        <div :class="$.stub_text">
          {{ locales(`chart_${chart.key}_stub_text`) }}
        </div>
      </div>
      <loader :show="isShowLoader" />
    </div>
  </div>
</template>

<script>
import * as echarts from '../../../libs/echarts';
import Loader from './Loader.vue';
import VgpSwitch from '../../common/VueComponents/checkbox/squareToggle.vue';
import Tooltip from '../../common/VueComponents/tooltips/tooltip.vue';

export default {
  components: {
    Loader,
    VgpSwitch,
    Tooltip
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    statusSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: __,
      scrollbarWidth: 0,
      instance: null,
      id: 'milestones',
      timeout: null,
      isShowLoader: false,
      tooltipTitle: __(`chart_${this.chart.key}_tooltip_title`),
    };
  },
  computed: {
    isShowStub() {
      return !this.static && !this.data.items.length;
    },
    items() {
      return this.data.items.filter(item => !!item.value);
    },
    options() {
      return {
        legend: {
          show: this.items.length,
          top: 'center',
          left: '70%',
          orient: 'vertical',
          itemWidth: 26,
          itemHeight: 14,
          itemGap: 10,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#FFFFFF',
          },
          textStyle: {
            color: '#191919',
            fontSize: 10,
            lineHeight: 12,
            fontFamily: 'Lato-Regular, sans-serif',
            padding: [0, 0, 0, 2],
          },
          inactiveColor: '#CCCCCC',
          inactiveBorderColor: '#FFFFFF',
          inactiveBorderWidth: 1,
          selectedMode: !this.static,
        },
        tooltip: (this.static ? false : {
          trigger: 'item',
          formatter: params => `<div class="chart_tooltip">
              <div class="tooltip_title">${this.tooltipTitle}</div>
              <div class="tooltip_info">
                <div class="tooltip_color" style="background: ${params.color};"></div>
                <div class="tooltip_name">${params.name}</div>
                <div class="tooltip_qty">${params.data.qty}</div>
                <div class="tooltip_value">${params.value}%</div>
              </div>
            </div>`,
          borderColor: '#808080',
          borderWidth: 1,
          borderRadius: 4,
          padding: 8,
          backgroundColor: '#FFFFFF',
          extraCssText: 'box-shadow: 0px 9px 22px -2px rgba(0, 0, 0, 0.2);',
        }),
        series: [{
          name: this.id,
          type: 'pie',
          radius: '81%',
          right: '21%',
          itemStyle: {
            color: params => params.data.color,
          },
          label: {
            show: true,
            position: 'inner',
            formatter: params => (params.value ? `${params.value}%` : ''),
            color: '#FFFFFF',
            fontSize: 16,
            lineHeight: 20,
            fontFamily: 'Lato-Bold, sans-serif',
          },
          emphasis: {
            scaleSize: 4,
          },
          labelLine: {
            show: false,
          },
          data: this.items,
          animation: !this.static,
          silent: this.static,
        }],
      };
    },
  },
  watch: {
    data() {
      clearTimeout(this.timeout);
      this.isShowLoader = true;

      this.timeout = setTimeout(() => {
        this.isShowLoader = false;
        this.instance.setOption({
          legend: {
            show: this.items.length,
          },
          series: [{
            name: this.id,
            data: this.items,
          }],
        });
      }, 1000);
    },
  },
  mounted() {
    this.instance = echarts.init(this.$refs.chart);
    this.instance.setOption(this.options);

    this.getScrollbarWidth();
  },
  beforeDestroy() {
    echarts.dispose(this.instance);
  },
  methods: {
    getScrollbarWidth() {
      const body = this.$refs.tableBody;
      const inner = this.$refs.tableInner;

      if (!body || !inner) return;

      this.scrollbarWidth = body.offsetWidth - inner.offsetWidth;
    },
  },
};
</script>

<style module="$" src="../less/chart.less"></style>
