import app from '../../app';
import _ from '../../libs/lodash';

let webhooks = {};

if (app.config.mode.isBase) {
  webhooks = new webix.DataCollection({
    url: `rest->/api/team/webhooks?${new Date().getTime()}`,
  });

  webhooks.getData = function () {
    const data = webhooks.serialize(true);
    data.sort((a, b) => {
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });
    return data;
  };

  webhooks.create = function (url) {
    return webix.ajax().post('/api/team/webhooks', { url }, response => {
      const data = JSON.parse(response);
      if (data && data.webhook) {
        webhooks.add(data.webhook);
      }
    });
  };

  webhooks.delete = function (id) {
    return webix.ajax().del('/api/team/webhooks', { id }, response => {
      const data = JSON.parse(response);
      if (data && data.status) {
        webhooks.remove(id);
      }
    });
  };

  webhooks.reconnect = function (id) {
    webix.ajax().post('/api/team/webhooks/reconnect', { id }, response => {
      const data = JSON.parse(response);
      if (data && data.status) {
        const item = webhooks.getItem(id);
        item.error_count = 0;
        webhooks.updateItem(id, item);
      }
    });
  };

  webhooks.attachEvent('onAfterDelete', id => {
    app.trigger('webhooks:updated');
  });

  webhooks.attachEvent('onAfterAdd', id => {
    app.trigger('webhooks:updated');
  });

  webhooks.attachEvent('onDataUpdate', id => {
    app.trigger('webhooks:updated');
  });

  app.on('app:start', () => {
    webix.promise.all([webhooks.waitData]).then(() => {
      // app.checkInit('webhooks');
    });
  });
}

export default webhooks;
