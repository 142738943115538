var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "table-row-" + _vm.item.id,
      staticClass: "table-row with-custom-menu",
      class: {
        "bg-blue": _vm.activeTaskId === _vm.item.id,
        editing: _vm.editingTaskId === _vm.item.id,
        "ovedue-bg": _vm.highlightOverdueTask && _vm.item.overdue,
        selected: _vm.selected,
      },
      style: { height: +_vm.height + "px" },
      on: {
        "!click": function ($event) {
          return _vm.clickRow($event)
        },
      },
    },
    [
      _vm._l(_vm.columns, function (column, index) {
        var _obj
        return _c("cell", {
          key: column.property + "-" + _vm.item.id,
          class:
            ((_obj = {
              hardwidth: !!column.hardWidth,
            }),
            (_obj[_vm.skins] = true),
            (_obj["skins-border-right-0"] = !!column.emptyHeader),
            (_obj["sticky-left"] = !!_vm.stickyLeft[column.property]),
            (_obj["last-in-row"] = _vm.columns.length === index + 1),
            _obj),
          style: {
            left: _vm.stickyLeft[column.property]
              ? _vm.stickyLeft[column.property]
              : "auto",
          },
          attrs: {
            "row-data": _vm.item,
            column: column,
            extra: _vm.extra,
            options: column.data,
            discolor: _vm.getOpacity,
            crossout: _vm.closedTaskCrossout && _vm.item.closed,
            overdue: _vm.highlightOverdueTask && _vm.item.overdue,
            "can-show-settings": !_vm.activeTaskId,
            "is-selected-row": _vm.selected,
            "select-mode": _vm.selectMode,
          },
          on: {
            "show:itemSettings": function ($event) {
              return _vm.$emit("show:itemSettings", {
                item: _vm.item,
                section: $event,
              })
            },
            change: function ($event) {
              return _vm.$emit("change", {
                task: _vm.item,
                property: column.property,
                value: $event,
              })
            },
            "change:custom": function ($event) {
              return _vm.$emit("change:custom", {
                task: _vm.item,
                column: column,
                value: $event,
              })
            },
            "show:task-name-editor": function ($event) {
              return _vm.$emit("show:task-name-editor", $event)
            },
            delete: function ($event) {
              return _vm.$emit("delete", _vm.item)
            },
            "select:row": function ($event) {
              return _vm.$emit("select", _vm.item.id)
            },
          },
          nativeOn: {
            contextmenu: function ($event) {
              !_vm.selectMode && _vm.$emit("contextmenu", $event)
            },
          },
        })
      }),
      _vm._v(" "),
      _c("moreCell", {
        class: "gp_autotest_listview_item_cell_more",
        attrs: {
          item: _vm.item,
          discolor:
            (_vm.closedTaskDiscolor && _vm.item.closed) || _vm.selectMode,
          skins: _vm.skins,
        },
        on: {
          contextmenu: function ($event) {
            !_vm.selectMode && _vm.$emit("contextmenu:morecell", $event)
          },
          delete: function ($event) {
            return _vm.$emit("delete", _vm.item)
          },
          "show:settings": function ($event) {
            return _vm.$emit("show:itemSettings", { item: _vm.item })
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }