import app from '../app';
import _ from './lodash';
import routerHelper from '../helpers/router';
import store from '../store/main';
import massChangeModel from '../views/gantt/modules/massChange';

const __ = window.__;

app.on('app:start', () => {
  const tooltipClass = 'tooltip-gantt';
  let container;
  let container_triangle;
  let container_content;
  let html;
  const containerClassName = 'tooltip-gantt-container';

  function initContainer() {
    container_content = document.createElement('div');
    container_triangle = document.createElement('div');
    container = document.createElement('div');

    container.style.maxWidth = '250px';

    container_content.className = 'tooltip-gantt-content';
    container_triangle.className = 'tooltip-gantt-triangle';
    container.className = containerClassName;

    container.appendChild(container_content);
    container.appendChild(container_triangle);
    document.body.appendChild(container);

    container.style.visibility = 'hidden';
  }

  function showTooltip(event) {
    const target = event.target;
    const tooltipAnchor = getTooltipElement(target, 'pricing-tooltip');
    const debounced = _.debounce(() => {
      initTooltip(getTooltipElement(target, tooltipClass));
    });

    document.addEventListener('click', e => {
      if (e && e.target && !e.target.closest(`.${containerClassName}`)) {
        debounced.cancel();
        hideTooltip(true);

        return false;
      }
    });
    if (tooltipAnchor) {
      document.querySelectorAll('.pricing-tooltip').forEach(item => {
        item.classList.remove('pricing-border');
      });
    }

    if (tooltipAnchor || getTooltipElement(target, 'tooltip-container')) {
      store.commit('tooltip/showPricingPopup', true);
      if (tooltipAnchor && tooltipAnchor.dataset) {
        store.commit('tooltip/setConfig', {
          feature: tooltipAnchor.dataset.feature,
          target: tooltipAnchor.getBoundingClientRect(),
          position: tooltipAnchor.dataset.position,
          additionalDif: tooltipAnchor.dataset.additionalDif,
          class: tooltipAnchor.dataset.class,
        });
      }
    } else {
      store.commit('tooltip/showPricingPopup', false);
    }

    if (!hasFocusOnTooltip(target)) {
      debounced.cancel();
      hideTooltip();
    }

    debounced();
  }

  function initTooltip(element) {
    if (!element) {
      return;
    }

    if (element.offsetParent && element.offsetParent.classList.contains('estimate_total')) {
      return false;
    }

    element.addEventListener('mouseleave', () => {
      if (container.innerHTML && container.innerHTML.includes('<a href')) {
        return false;
      }

      let showMoreBtn = container.querySelector('.tooltip_gantt_show_more');
      if (showMoreBtn && !showMoreBtn.style.display) {
        return false;
      }

      debouncedTooltip.cancel();
      hideTooltip(true);

      return false;
    });

    const yOffset = ~~(element.dataset.y || 0);
    const xOffset = ~~(element.dataset.x || 0);
    const tooltipPosition = element.dataset.position || 'top';
    const align = element.dataset.align || null;
    const maxWidth = element.dataset.maxwidth || 250;
    const padding = element.dataset.padding || '16px';

    container.style.maxWidth = `${maxWidth}px`;
    container_content.style.padding = '8px 12px';

    const elementPosition = element.getBoundingClientRect();

    if (!element.classList.contains('pricing-tooltip')) {
      if (element.dataset.key) {
        setContentByKey(element.dataset.key);
      } else if (element.dataset.html === 'true') {
        let id = null;

        if (element.dataset.id) {
          id = element.dataset.id.includes('_') ? element.dataset?.id.split('_')[0] : +element.dataset.id;
        }

        html = element.querySelector('.tooltip-gantt-html');
        container.classList.add('tooltip-gantt-container-html');

        setContentByHTML(html.cloneNode(true));

        document.querySelector('.tooltip-gantt-container-html').querySelectorAll('a').forEach(item => {
          if (item.classList.contains('tooltip_gantt_show_more')) {
            return;
          }
          if (!item.getAttribute('target')) {
            item.setAttribute('target', '_self');
          }
          item.setAttribute('title', __('click_to_follow_by_link'));
        });

        if (id) {
          const link = container.querySelector('.tooltip_gantt_show_more');
          const tooltipMaxHeight = Math.round((document.body.clientHeight / 2) - 120);
          const tooltipHeight = container.clientHeight;

          if (tooltipHeight > tooltipMaxHeight) {
            const linkHeight = 15;
            const title = container.querySelector('.tooltip-gantt-html-title');
            const description = container.querySelector('.tooltip-gantt-html-description');
            const maxHeight = Math.round(tooltipMaxHeight - title.getBoundingClientRect().height - linkHeight);

            let resHeight = 0;

            if (description) {
              _.forEach(description.children, elem => {
                const elemHeight = elem.offsetHeight;
                if ((resHeight + elemHeight) < maxHeight) {
                  resHeight += elemHeight;
                } else {
                  const elemLineHeight = parseInt(getComputedStyle(elem).lineHeight);
                  const heightRemainder = maxHeight - resHeight;
                  const maxVisibleLines = Math.floor(heightRemainder / elemLineHeight);
                  resHeight += (maxVisibleLines * elemLineHeight);

                  return false;
                }
              });
              description.style.height = `${resHeight}px`;
            }

            if (link) {
              link.onclick = function (e) {
                gantt.callEvent('onShowMoreTooltipClick', [id]);
                hideTooltip(true);
              };
            }
          } else {
            if(link) link.style.display = 'none';
          }
        }
      }
      const container_width = container_content.clientWidth;
      const container_height = container_content.clientHeight;

      const deviation = getDiviation(container_width, container_height, elementPosition);

      setPosition(container, tooltipPosition, element, elementPosition, container_height, container_width, yOffset, xOffset, deviation, container_triangle);

      if (!container_content.innerHTML) {
        return false;
      }
      container.style.visibility = 'visible';

      container.style.opacity = '.85';
      container.style.transform = 'scale3d(1,1,1)';

      if (align) {
        setAlign(align, container, container_triangle, elementPosition, tooltipPosition);
      }
    }
  }

  function setAlign(align, container, container_triangle, elementPosition, tooltipPosition) {
    switch (align) {
      case 'center':
        break;
      case 'right':
        if (tooltipPosition === 'top' || tooltipPosition === 'bottom') {
          container.style.left = `${elementPosition.left - container.offsetWidth + elementPosition.width + 8}px`;
          container_triangle.style.transform = `translateX(${container.offsetWidth / 2 - 20}px)`
        }
        break;
      case 'left':
        if (tooltipPosition === 'top' || tooltipPosition === 'bottom') {
          container.style.left = `${elementPosition.left - 8}px`;
          container_triangle.style.transform = `translateX(${-(container.offsetWidth / 2 - 20)}px)`
        }
        break;
      default:
    }
  }

  function setPosition(container, tooltipPosition, element, elementPosition, container_height, container_width, yOffset, xOffset, deviation, container_triangle) {
    let elementWidth = element.offsetWidth  ||  elementPosition.width;
    let elementHeight = element.offsetHeight  ||  elementPosition.height;

    switch (tooltipPosition) {
    case 'top':
      if (elementPosition.top > container_height + 17) {
        container.className = `${containerClassName} triangle_bottom`;
        container.style.top = `${elementPosition.top - container.offsetHeight - 7 + yOffset}px`;
      } else {
        container.className = `${containerClassName} triangle_top`;
        container.style.top = `${elementPosition.top + elementHeight + 17 + yOffset - deviation.y}px`;
      }

      if (deviation.x > 110) {
        container.style.left = `${elementPosition.left - container_width + elementWidth}px`;
        container_triangle.style.transform = `translateX(${container_width / 2 - elementWidth / 2}px)`;
      } else {
        container.style.left = `${elementPosition.left + elementWidth / 2 - container_width / 2 - 1 + xOffset - deviation.x}px`;
        container_triangle.style.transform = `translateX(${0}px)`;
      }
      break;
    case 'bottom':
      container.className = `${containerClassName} triangle_top`;
      container.style.top = `${elementPosition.top + elementHeight + 17 + yOffset}px`;

      if (deviation.x > 110) {
        container.style.left = `${elementPosition.left - container_width + elementWidth}px`;
        container_triangle.style.transform = `translateX(${container_width / 2 - elementWidth / 2}px)`;
      } else {
        container.style.left = `${elementPosition.left + elementWidth / 2 - container_width / 2 - 1 + xOffset - deviation.x}px`;
        container_triangle.style.transform = `translateX(${0}px)`;
      }
      break;
    case 'left':
      container.style.left = `${elementPosition.left - container_content.clientWidth - 30 + xOffset}px`;
      container.style.top = `${elementPosition.top - yOffset - deviation.y}px`;
      container.className = `${containerClassName} triangle_right`;
      container_triangle.style.transform = `translateX(0px) translateY(${deviation.y}px)`;

      break;
    case 'right':
      const padding = parseInt(container_content.style.paddingTop);
      container.style.left = `${elementPosition.left + elementWidth + xOffset}px`;
      container.style.top = `${elementPosition.top + yOffset - deviation.y}px`;
      container.className = `${containerClassName} triangle_left`;
      container_triangle.style.transform = `translateX(0px) translateY(${deviation.y - container_height / 2 + padding + padding / 2}px)`;
      break;
    default:
    }
  }

  function getDiviation(containerWidth, containerHeight, elementPosition) {
    const deviation = {
      x: 0,
      y: 0,
    };

    if (containerWidth > (document.body.clientWidth - elementPosition.right)) {
      deviation.x = containerWidth - (document.body.clientWidth - elementPosition.right) + 17;
    } else if (containerWidth > elementPosition.left) {
      deviation.x = -(containerWidth / 2 - elementPosition.left + 17);
    }

    if (Math.abs(deviation.x) > (containerWidth - 10)) {
      deviation.x = deviation.x < 0 ? -(containerWidth - 10) : containerWidth - 10;
    }

    if (containerHeight > (document.body.clientHeight - elementPosition.top)) {
      deviation.y = containerHeight - (document.body.clientHeight - elementPosition.top);
    }

    return deviation;
  }

  function hideTooltip(force) {
    if (!force) {
      container.style.opacity = '0';
      container.style.transform = 'scale3d(1,.96,1)';
    }

    container.style.visibility = 'hidden';

    // store.commit('tooltip/showPricingPopup', false);
    container_content.innerHTML = '';
    container.classList.remove('tooltip-gantt-container-html');
  }

  function setContentByKey(key) {
    const exp = /([^"]|^)(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const res = key.replace(exp, `$1<a href="$2" target="_blank" title="${__('click_to_follow_by_link')}">$2</a>`);
    container_content.innerHTML = __(res.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
  }

  function setContentByHTML(html) {
    container_content.innerHTML = parseDescription(html.innerHTML);
  }

  function getTooltipElement(element, cls) {
    return element.closest(`.${cls}`);
  }

  function hasFocusOnTooltip(element) {
    let showMoreBtn = container.querySelector('.tooltip_gantt_show_more');

    if (showMoreBtn && container.contains(element) ) {
      return true;
    }

    while (element) {
      if (_.includes(element.classList, 'tooltip-gantt-content')) {

        if (!element.innerHTML.includes('<a href')) {
          return false;
        }

        return true;
      }
      element = element.parentNode;
    }
    return false;
  }

  function parseDescription(text) {
    return text.replace(/(?:\r\n|\r|\n)/g, '');
    /* var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var res = text.replace(exp, '<a href="$1" target="_blank" title="' + __('click_to_follow_by_link') + '">$1</a>');
    return res.replace(/(?:\r\n|\r|\n)/g, '<br/>'); */
  }

  let debouncedTooltip = _.debounce(event => {
    if (massChangeModel.isEnabled() && event.target.closest('.gantt_row')) {
      return;
    };
    showTooltip(event);
  }, 600);

  document.querySelector('body').addEventListener('mouseover', debouncedTooltip, false);

  initContainer();
});
