var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-content settings-content-security" },
    [
      _vm.accessToSsoSettings
        ? _c(
            "div",
            { staticClass: "settings-section sso" },
            [
              _c("div", { staticClass: "section-title" }, [
                _c(
                  "div",
                  {
                    class: [
                      "title-ic check-ic",
                      _vm.isShowSsoForm ? "active" : "",
                    ],
                  },
                  [_c("webix-ui", { attrs: { config: _vm.icon.checkIcon } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "main-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.locales("settings_sso_title")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                !_vm.accessSsoPricing && _vm.planTitle
                  ? _c("div", { staticClass: "pricing-title-container" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales(_vm.planTitle)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "text",
                style: !_vm.accessToSsoSettings ? { marginBottom: 0 } : "",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.accessToSsoSettings
                      ? _vm.locales("settings_sso_text")
                      : _vm.locales("settings_sso_text_if_not_enough_rights")
                  ),
                },
              }),
              _vm._v(" "),
              _vm.isShowSsoForm && _vm.accessToSsoSettings
                ? _c("sso-form", {
                    attrs: {
                      "hide-s-s-o-form": _vm.disableSso,
                      "add-sso-domain": _vm.addSsoDomain,
                      "delete-domain": _vm.deleteDomain,
                      "sso-config": _vm.ssoConfig,
                      "check-sso-url": _vm.checkSsoUrl,
                      "check-issuer": _vm.checkIssuer,
                      "check-certificate": _vm.checkCertificate,
                      "change-all-sso": _vm.changeAllSso,
                      "add-sso-excluded-email": _vm.addSsoExcludedEmail,
                      "delete-excluded-email": _vm.deleteExcludedEmail,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isShowSsoBtn
                ? _c(
                    "button",
                    {
                      class: [
                        "main-btn",
                        !_vm.accessSsoPricing ? "upgrade-btn" : "",
                      ],
                      on: { click: _vm.activateSso },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.accessSsoPricing
                              ? _vm.locales("sso_btn_activate")
                              : _vm.locales("upgrade_rate_btn")
                          ) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.accessToSsoSettings ? _c("delimiter") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "settings-section" }, [
        _c("div", { staticClass: "section-title" }, [
          _c(
            "div",
            { class: ["title-ic check-ic", _vm.isActiveTwoFa ? "active" : ""] },
            [_c("webix-ui", { attrs: { config: _vm.icon.checkIcon } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "main-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.locales("settings_twofa_title")) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "text",
          domProps: { innerHTML: _vm._s(_vm.locales("settings_twofa_text")) },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            class: ["main-btn", _vm.isActiveTwoFa ? "gray" : ""],
            on: { click: _vm.checkTwoFa },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.isActiveTwoFa
                    ? _vm.locales("settings_twofa_disable_button")
                    : _vm.locales("settings_twofa_enable_button")
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }