import Vue from 'vue';
import constants from '../../helpers/constants';
import helpers from './helpers';
import _ from 'lodash';

export default {
  setValuesLoaded(state, payload) {
    state.valuesLoaded = payload;
  },
  setStaticColumns(state, payload) {
    Vue.set(state, 'staticColumns', helpers.prepareOptionsLocale(payload));
  },
  setJiraColumns(state, payload) {
    Vue.set(state, 'jiraColumns', payload);
  },
  setCustomColumns(state, payload) {
    const columns = helpers.prepareCustomColumnsData(payload);

    Vue.set(state, 'columns', columns);
  },
  setProjectColumns(state, payload) {
    Vue.set(state, 'projectColumns', payload);
  },
  setProjectColumnsValues(state, payload) {
    const projects = _.cloneDeep(state.projectColumns);

    let loadedDataMap = {};

    payload.forEach(pr => {
      loadedDataMap[pr.id] = pr.values;
    });

    projects.forEach(project => {
      if (!project.values) {
        project.values = loadedDataMap[project.id];
      }
    });

    Vue.set(state, 'projectColumns', projects);
  },
  addCustomColumn(state, column) {
    state.columns.push(column);
  },
  updateCustomColumn(state, payload) {
    const index = state.columns.indexOf(state.columns.find(col => col.id === payload.columnId));

    if (index === -1) return;

    const updated = helpers.prepareCustomColumnsData([payload.updated]);

    Vue.set(state.columns, index, {
      ...state.columns[index],
      ...updated[0],
    });
  },
  removeCustomColumn(state, columnId) {
    const index = state.columns.indexOf(state.columns.find(col => col.id === +columnId));

    if (index === -1) return;

    state.columns.splice(index, 1);
    state.projectColumns.forEach((project, i) => {
      const columns = [...project.columns];
      const columnIndex = columns.indexOf(columns.find(col => col.id === +columnId));

      if (columnIndex === -1) return;

      columns.splice(columnIndex, 1);
      project.columns = columns;

      Vue.set(state.projectColumns, i, {
        ...state.projectColumns[i],
        ...project,
      });
    });
  },
  addProject(state, payload) {
    const { data, projectId } = payload;
    let projectData = data;

    if (!projectData) {
      projectData = {
        columns: [],
        id: projectId,
        values: {},
      };
    }

    if (!state.projectColumns.find(o => o.id === projectId)) {
      state.projectColumns.push(projectData);
    }
  },
  updateProject(state, payload) {
    const index = state.projectColumns.indexOf(state.projectColumns.find(pr => pr.id === +payload.projectId));

    if (index === -1) return;

    const updated = {
      ...payload.updated,
      id: +payload.projectId,
    };

    Vue.set(state.projectColumns, index, {
      ...state.projectColumns[index],
      ...updated,
    });
  },
  removeProject(state, projectId) {
    state.projectColumns.filter(pr => pr.id !== projectId);
  },
  changeCustomValues(state, payload) {
    // payload = {
    //   task: { id: 0, gantt_id: 0},
    //   columnId: 0,
    //   value: [] || 'String',
    // }

    const { task, columnId, value } = payload;
    const project = state.projectColumns.find(pr => pr.id === task.gantt_id);

    if (!project) return;

    const index = state.projectColumns.indexOf(state.projectColumns.find(pr => pr.id === project.id));
    const taskValues = [];
    const newValues = Array.isArray(value) ? value : [value];
    const columnType = state.columns.find(col => col.id === columnId).type;

    newValues.forEach(item => {
      let newValue;

      if (columnType === 2) {
        newValue = item;
      } else {
        newValue = isNaN(+item) ? item : +item;
      }

      taskValues.push({
        // gantt_id: task.gantt_id,
        column_id: columnId,
        task_id: task.id,
        task_value: newValue,
      });
    });

    if (project.values[task.id]) {
      project.values[task.id].forEach(item => {
        if (item.column_id !== columnId) {
          taskValues.push(item);
        }
      });
    }

    project.values[task.id] = taskValues;

    Vue.set(state.projectColumns, index, project);
  },
  addColumnsToProjects(state, payload) {
    const { columnIds, projectIds } = payload;
    const projects = [];

    state.projectColumns.forEach(project => {
      if (projectIds.includes(project.id)) {
        columnIds.forEach(id => {
          if (!project.columns.includes(id)) project.columns.push(id);
        });
      }

      projects.push(project);
    });

    Vue.set(state, 'projectColumns', projects);
  },
  removeColumnFromProjects(state, payload) {
    const { columnId, projectIds } = payload;
    const projects = [];

    state.projectColumns.forEach(project => {
      if (projectIds.includes(project.id)) {
        const columnIndex = project.columns.indexOf(columnId);

        if (columnIndex >= 0) {
          project.columns.splice(columnIndex, 1);
        }
      }

      projects.push(project);
    });

    Vue.set(state, 'projectColumns', projects);
  },
  removeResourceOptionById(state, resourceId) {
    const columnsAndOptions = [];

    state.columns.forEach((column, i) => {
      if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
        const optionData = column.options.find(opt => opt.resource_id === resourceId);

        if (optionData) {
          columnsAndOptions.push({
            columnId: column.id,
            optionId: optionData.id,
          });
        }
        column.options = column.options.filter(opt => opt.resource_id !== resourceId);

        Vue.set(state.columns, i, {
          ...state.columns[i],
          ...column,
        });
      }
    });

    state.projectColumns.forEach((project, i) => {
      const hasResourceColumn = project.columns.find(columnId => columnsAndOptions.find(item => item.columnId === columnId));

      if (hasResourceColumn) {
        const values = {};

        Object.values(project.values).forEach(taskData => {
          let taskId;
          const filtered = taskData.filter(item => {
            taskId = item.task_id;
            if (columnsAndOptions.find(obj => obj.optionId === item.task_value)) {
              return;
            }

            return item;
          });

          values[taskId] = filtered;
        });

        project.values = values;

        Vue.set(state.projectColumns, i, {
          ...state.projectColumns[i],
          ...project,
        });
      }
    });
  },
  removeResourceOptionFromProject(state, payload) {
    const { resourceId, projectId } = payload;
    const columnsAndOptions = [];

    state.columns.forEach((column, i) => {
      if (column.type === constants.CUSTOM_COLUMNS_TYPES.resources.id) {
        const optionData = column.options.find(opt => opt.resource_id === resourceId);

        if (optionData) {
          columnsAndOptions.push({
            columnId: column.id,
            optionId: optionData.id,
          });
        }
      }
    });

    state.projectColumns.forEach((project, i) => {
      const hasResourceColumn = project.columns.find(columnId => columnsAndOptions.find(item => item.columnId === columnId));

      if (hasResourceColumn && project.id === projectId) {
        const values = {};

        Object.values(project.values).forEach(taskData => {
          let taskId;
          const filtered = taskData.filter(item => {
            taskId = item.task_id;
            if (columnsAndOptions.find(obj => obj.optionId === item.task_value)) {
              return;
            }

            return item;
          });

          values[taskId] = filtered;
        });

        project.values = values;

        Vue.set(state.projectColumns, i, {
          ...state.projectColumns[i],
          ...project,
        });
      }
    });
  },
};
