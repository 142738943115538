import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const CommentsStore = {
  namespaced: true,
  state: {
    projectCommentsMap: {},
    commentsActions: [],
    taskCommentsMetaData: {},
    commentsMap: {},
    commentsActionsMap: {},
    isFetching: false,
    draftsMap: {},
  },
  getters,
  mutations,
  actions,
};

export default CommentsStore;
