import routerHelper from '../../helpers/router';
import BulkPopup from './BulkPopup';
import massChangeModel from '../gantt/modules/massChange';
import projectsModel from '../../models/projects';
import BulkActions from './bulkActions';
import app, { appIds } from '../../app';
import pricingHelpers from '../../helpers/pricingHelper';
import globalStore from '../../store/main';
import rights from '../../components/rights';

const {
  events: {
    ID_EVENT_MASSCHANGE_UPDATESTATE,
    ID_EVENT_MASSCHANGE_UPDATE_TASKS,
  },
} = appIds;

export default class massChange {
  get _isCanShowed() {
    const routeParams = routerHelper.getCurrentRoute().params;

    if (routeParams.multiviewID && routeParams.mode === 'gantt') { return false; }

    return ['list', 'gantt'].includes(routeParams.mode);
  }

  get _hasAccessRight() {
    return pricingHelpers.checkPricingAccess('bulk_change');
  }

  constructor() {
    this.project_id = null;
    this.countSelected = 0;
    this.eventsIds = [];
    this.enable = false;
    this.isOpen = this.enable;
    this.showStub = false;
    this.hasChanges = false;
    this.canUse = false;
    this.eventsIds = [];
    this.hasSelected = 'none';

    this.bulcActions = new BulkActions();
    this.init();
  }

  init() {
    this._update();
    this._addListheners();
  }

  reInit() {
    this._update();
  }

  _update() {
    this.canUse = this._isCanShowed && this._hasAccessRight;
    if (!this.canUse) { return; }

    this.project_id = this.canUse ? projectsModel.getActiveGanttId() : null;
    this.showStub = !(this.project_id && rights.project.hasRightSomeOne(this.project_id, [
      'static_fields',
      'static_fields_4_only',
      'custom_field_edit',
    ]));
  }

  getConfig() {
    this._update();

    if (!this.canUse) { return []; }

    return BulkPopup.getConfig(this.project_id);
  }

  onChange(opt, val) {
    this.bulcActions.onChange(opt, val);
    this.hasChanges = this.bulcActions.hasChanges();
  }

  async apply() {
    await this.bulcActions.applyChanges();
  }

  async deleteTasks() {
    const actions = [{
      actionType: 5, // !FIXME: identifier
      actionPayload: {
        tasks: massChangeModel.getSelectedList(),
      },
    }];

    const activeGanttId = projectsModel.getActiveGanttId();

    await massChangeModel.executeActions(actions, activeGanttId);
  }

  _addListheners() {
    addAppEvent(ID_EVENT_MASSCHANGE_UPDATE_TASKS, this.onUpdateSelectedTasks, this);
    addAppEvent(ID_EVENT_MASSCHANGE_UPDATESTATE, this.onUpdateState, this);
    addAppEvent('onAfterCollaboration', this.onAfterCollaboration, this);

    function addAppEvent(eventName, eventHandler, context) {
      const e_id = app.on(eventName, eventHandler.bind(context));

      context.eventsIds.push(e_id);
    }
  }

  _removeListheners() {
    this.eventsIds.forEach(id => app.off(id));
  }

  onUpdateSelectedTasks() {
    this._updSelectedCount();
  }

  onUpdateState(enable) {
    this.enable = enable;
    this.isOpen = enable;

    this._update();
    this._updSelectedCount();

    !enable && this.bulcActions.cleare();
  }

  _updSelectedCount() {
    this.hasSelected = 'none';

    const selectedList = massChangeModel.getSelectedList();
    const selectedTasks = selectedList.filter(idSelected => gantt.getTask(idSelected)).map(idSelected => gantt.getTask(idSelected));
    const accessToAllTasks = rights.project.hasRight(this.project_id, 'all_tasks');

    if (!accessToAllTasks) {
      const own_tasks = globalStore.getters['tasksModel/getTasksByGanttIds']([this.project_id]);

      if (!own_tasks.length) globalStore.dispatch('headerView/closeBulkChange');
    }
    if (selectedTasks.length === 1 && selectedTasks[0].parent !== 0) {
      this.hasSelected = 'project';
    }

    if (selectedTasks.some(task => task.type === 'task' || task.type === 'milestone')) {
      this.hasSelected = 'task';
    }

    this.countSelected = selectedTasks.length;

    if (!this.countSelected) {
      this.bulcActions.cleare();
      this.hasChanges = false;
    }
  }

  onAfterCollaboration(e) {
    if (!this.enable) return;

    if (e.event === 'ResourceRoleUpdated') {
      this._update();
      !this._hasAccessRight && globalStore.dispatch('headerView/closeBulkChange');
    }

    if (e.event === 'TaskResourceUnassigned') {
      this._updSelectedCount();
    }

    if (e.event === 'TaskDeleted') {
      this._updSelectedCount();
    }
  }
}
