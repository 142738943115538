<template>
  <div
    v-if="isShowUpgradePopup"
    :class="$style.upgradeCard"
  >
    <div
      v-if="userRights.owner"
      :class="[$style.btns]"
    >
      <div
        v-if="discount"
        :class="[$style.upgradeCard_btn, discount ? $style.discount : '']"
        @click="upgradeClick()"
      >
        <b>
          - <span v-if="discount.isFixedDiscount">$ </span>
          {{ discount.percent }}
        </b>
        <i> <span v-if="!discount.isFixedDiscount">%</span> </i>
        <i v-if="discount.couponValidDays">
          {{ discount.coupon_till }} <b>{{ discount.tillDate }}</b>
        </i>
      </div>

      <div
        id="gp_autotest_sidebar_upgrade_button"
        :class="[isOpenSideBar ? $style.upgradeCard_btn : $style.upgrade]"
        @click="upgradeClick()"
      >
        {{ upgrade.btnName }}
      </div>
    </div>

    <div
      v-if="isOpenSideBar"
      :class="$style.upgradeCard_txt"
    >
      <span> {{ upgrade.txt }} </span>
      <tooltip
        :content="upgrade.tooltip"
        :class="$style.tooltips"
      >
        <template #body>
          <svg-sprite
            :name="'info'"
            :size="20"
            :class="[ $style.upgradeCard_icon]"
          />
        </template>
      </tooltip>
    </div>

    <div
      v-if="isOpenSideBar"
      :class="$style.upgradeCard_progressBar"
    >
      <div
        :style="{ width: (daysLeft / 14) * 100 + '%' }"
        :class="$style.upgradeCard_progressBar_progress"
      />
    </div>
  </div>
</template>

<script>
import moment from '../../../libs/moment';
import Tooltip from '../../common/VueComponents/tooltips/tooltip.vue';

export default {
  name: 'UpgradeButton',
  components: { Tooltip },
  props: {
    isOpenSideBar: { type: Boolean, required: true },
    isShowUpgradePopup: { type: Boolean, required: true },
    userRights: { type: Object, required: true },
  },
  data() {
    return {
      discount: false,
      upgrade: {
        tooltip: (this.userRights.owner)
          ? __('trial_day_owner_left_text')
          : __('trial_day_member_left_text'),
        btnName: __('common_upgrade'),
        txt: null,
      },
    };
  },

  computed: {
    daysLeft() {
      let dayLeft = moment(user.subscription.date_to).subtract(4, 'hour').diff(moment(), 'days');

      if (dayLeft <= 0) {
        dayLeft = moment(user.subscription.date_to).diff(moment(), 'hours') > 0 ? 1 : 0;
      } else {
        dayLeft++;
      }

      return dayLeft;
    },
  },
  created() {
    this.updateDiscount();
    this.upgrade.txt = __('trial_day_left', { dayLeft: this.daysLeft });
  },

  mounted() {
  },
  methods: {
    upgradeClick() {
      this.$emit('upgradeClick');
    },
    updateDiscount() {
      const hasDiscount = user.affiliate && user.affiliate.isStillValid && user.affiliate.percent && user.affiliate.coupon;

      if (!hasDiscount) {
        this.discount = false;

        return;
      }

      const discount = {
        isFixedDiscount: user.affiliate.isFixedDiscount,
        percent: user.affiliate.percent,
        couponValidDays: user.affiliate.couponValidDays,
        tillDate: this.tillDateDiscountLocale(),
        coupon_till: __('coupon_till'),
      };

      this.discount = discount;
    },
    getMomentString(locale, month, day) {
      const momentPart = moment(user.affiliate.startTime).add(user.affiliate.couponValidDays, 'day');

      return `${momentPart.locale(locale).format(month).toUpperCase()} ${momentPart.locale(locale).format(day)}`;
    },

    tillDateDiscountLocale() {
      const momentPart = moment(user.affiliate.startTime).add(user.affiliate.couponValidDays, 'day');
      let month = momentPart.locale(user.locale).format('MMM').toUpperCase();
      const day = momentPart.locale(user.locale).format('Do');

      if (user.locale === 'ru') {
        if (month.length === 4) {
          month = month.split('.')[0];
        } else {
          month = month.substr(0, 3);
        }
      }
      if (user.locale === 'kr') {
        return this.getMomentString('ko', 'MMM', 'Do');
      } if (user.locale === 'ru') {
        return `${day} ${month}`;
      } if (user.locale === 'es') {
        return this.getMomentString(user.locale, 'MMM', 'D');
      } if (user.locale === 'pt') {
        return this.getMomentString(user.locale, 'MMM.', 'D');
      } if (user.locale === 'en') {
        return this.getMomentString(user.locale, 'MMM.', 'Do');
      } if (user.locale === 'de') {
        return this.getMomentString(user.locale, 'MMM', 'D');
      }

      return this.getMomentString(user.locale, 'MMM', 'Do');
    },
  },
};

</script>

<style module src="../style.less" lang="less"></style>
