import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const copyPasteStore = {
  namespaced: true,
  state: {
    taskMap: null,
    isOpenPastePopup: false,
    hasCopiedTask: false,
    copiedTask: null,
    specialPasteConfig: null,
    specialPasteIconId: null,
    popupCoordinates: null,
    isInsertingProcess: false,
  },
  getters,
  mutations,
  actions,
};

export default copyPasteStore;
