var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tooltip", {
    class: ["tooltip-wrapper"],
    attrs: {
      content:
        "" +
        _vm.taskName +
        "<div class='tooltip-separator'></div>" +
        "" +
        _vm.parentPath.join(" > "),
      position: "right",
      "full-width": true,
      delay: 500,
      interactive: true,
      hide: _vm.hideTooltip,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: [_vm.$style["task-item"]] }, [
              _c(
                "div",
                {
                  class: [
                    _vm.$style["task-item-name"],
                    _vm.counter || _vm.mention
                      ? _vm.$style["task-item-name_with_counter"]
                      : "",
                  ],
                },
                [_vm._v("\n        " + _vm._s(_vm.taskName) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.$style["task-item-first-line"],
                    "gp_autotest_comment_hub_task_item_project_breadcrumbs",
                  ],
                },
                [
                  _c("div", { class: [_vm.$style["project-line"]] }, [
                    _c(
                      "div",
                      { class: [_vm.$style["breadcrumbs-icon"]] },
                      [
                        _c("svg-sprite", {
                          attrs: { name: "project", type: "regular", size: 16 },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$style.breadcrumbs] }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shortenedBreadcrumbsIfDoesntFit.split(" > ")[0]
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$style["counter-block"]] }, [
                    _vm.counter && !_vm.mention
                      ? _c(
                          "div",
                          {
                            class: [
                              _vm.$style["task-item-counter"],
                              "gp_autotest_comment_hub_task_item_counter",
                            ],
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.counter) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mention
                      ? _c(
                          "div",
                          {
                            class: [
                              _vm.$style["task-item-counter"],
                              "gp_autotest_comment_hub_task_item_mention",
                            ],
                          },
                          [
                            _c("svg-sprite", {
                              style: { color: "#333333" },
                              attrs: {
                                name: "mentioned",
                                type: "regular",
                                size: 16,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.$style["task-item-second-line"],
                    _vm.$style.breadcrumbs,
                    "gp_autotest_comment_hub_task_item_path_breadcrumbs",
                  ],
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        (_vm.shortenedBreadcrumbsPartSecond.length
                          ? "  >  "
                          : "") +
                          _vm.shortenedBreadcrumbsPartSecond.join("  >  ")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }