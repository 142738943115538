import moment from 'moment';
import constants from '../../helpers/constants';
import globalStore from '$$store/main';

const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      const diff = {};

      for (const key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        let value2;

        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (const key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues(value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }

      return this.VALUE_UPDATED;
    },
    isFunction(x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray(x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate(x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject(x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue(x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
}());

const tasksHelpers = {
  prepareProjectTasksData(obj) {
    const tasks = obj?.tasks?.data || obj.tasks;
    const links = obj?.tasks?.links || obj.links;

    if (obj._onlyForWorkloadCalculation) {
      tasks.forEach(task => {
        task.resources = obj.resourcesToTasks[task.id] || [];

        if (_.isString(task.start_date)) {
          task.start_date = moment(task.start_date, constants.TASK_DATES_FORMAT).toDate();
        }

        if (_.isString(task.end_date)) {
          task.end_date = moment(task.end_date, constants.TASK_DATES_FORMAT).toDate();
        }

        if (_.isString(task.estimation)) {
          task.estimation = parseFloat(task.estimation);
        }
      });

      return {
        id: +obj.id,
        workloadData: obj.workloadData,
        resourcesToTasks: obj.resourcesToTasks || {},
        _onlyForWorkloadCalculation: !!obj._onlyForWorkloadCalculation,
        tasks,
      };
    }

    if (!obj.customValues) {
      obj.customValues = {};
    }

    if (!obj.defaultCustomValues) {
      obj.defaultCustomValues = {};
    }

    if (!obj.resourcesToTasks) {
      obj.resourcesToTasks = {};
    }

    if (!obj.commentActions) {
      obj.commentActions = {};
    }

    tasks.forEach(task => {
      task.status = (obj.customValues[task.id] && obj.customValues[task.id].status) || obj.defaultCustomValues.status;
      task.priority = (obj.customValues[task.id] && obj.customValues[task.id].priority) || obj.defaultCustomValues.priority;
      task.jira_key = (obj.customValues[task.id] && obj.customValues[task.id].jira_key) || '';
      task.resources = obj.resourcesToTasks[task.id] || [];

      task.newComments = obj.commentActions[task.id] || task.newComments || null;

      if (task.deadline) {
        task.deadline = moment(task.deadline, constants.TASK_DATES_FORMAT).toDate();
      }

      if (_.isString(task.start_date)) {
        task.start_date = moment(task.start_date, constants.TASK_DATES_FORMAT).toDate();
      }

      if (_.isString(task.end_date)) {
        task.end_date = moment(task.end_date, constants.TASK_DATES_FORMAT).toDate();
      }

      if (_.isString(task.estimation)) {
        task.estimation = parseFloat(task.estimation);
      }

      if (task.parent === null) {
        task.parent = 0;
      }
    });

    return {
      id: +obj.id,
      customValues: obj.customValues || {},
      defaultCustomValues: obj.defaultCustomValues || {},
      resourcesToTasks: obj.resourcesToTasks || {},
      commentActions: obj.commentActions || {},
      is_archived: obj.is_archived || 0,
      workloadData: obj.workloadData || {},
      _onlyForWorkloadCalculation: !!obj._onlyForWorkloadCalculation,
      tasks,
      links,
    };
  },
  getDiff(newData, currentData) {
    const preparedCloneData = tasksHelpers.smartClone(newData);
    const diff = deepDiffMapper.map(preparedCloneData, currentData);

    if (diff.start_date?.data) {
      diff.start_date.data = moment(diff.start_date.data).format(constants.TASK_DATES_FORMAT);
    }
    if (diff.end_date?.data) {
      diff.end_date.data = moment(diff.end_date.data).format(constants.TASK_DATES_FORMAT);
    }
    if (diff.deadline?.data) {
      diff.deadline.data = moment(diff.deadline.data).format(constants.TASK_DATES_FORMAT);
    }

    return diff;
  },
  prepareDefaultTaskValues(task, defaultTaskCustomValues) {
    if (task.type === constants.TASK_TYPES.task || task.type === constants.TASK_TYPES.milestone) {
      task.status = task.status || defaultTaskCustomValues.status;
      task.priority = task.priority || defaultTaskCustomValues.priority;
      task.total_price = 0;
      task.actual_cost = 0;
      task.resources = [];
      task.jira_key = '';
    }

    return task;
  },
  smartClone(taskData) {
    const cloneTask = _.cloneDeep(taskData);

    Object.keys(cloneTask).forEach(taskKey => {
      if (taskKey.indexOf('$') !== -1 || taskKey === 'baselineData') {
        delete cloneTask[taskKey];
      }
    });

    cloneTask.id = +cloneTask.id;

    if (cloneTask.start_date) {
      cloneTask.start_date = moment(cloneTask.start_date, constants.TASK_DATES_FORMAT).toDate();
    }

    if (cloneTask.end_date) {
      cloneTask.end_date = moment(cloneTask.end_date, constants.TASK_DATES_FORMAT).toDate();
    }

    if (cloneTask.deadline !== undefined) {
      cloneTask.deadline = cloneTask.deadline ? moment(cloneTask.deadline).toDate() : null;
    }

    if (cloneTask.parent === null) {
      cloneTask.parent = 0;
    }

    if (cloneTask.parent === 1 && !gantt.config.multiview && !gantt.is_workload_mode) {
      cloneTask.parent = 0;
    }

    if (cloneTask.parent === 0 && (gantt.config.multiview || gantt.is_workload_mode)) {
      cloneTask.parent = 1;
    }

    return cloneTask;
  },
  prepareNewTimeTypeTaskResources(taskData, changedResources) {
    const addedResources = changedResources.filter(r => r.action === 'insert');
    let newTimeTypeTaskResources = [];

    addedResources.forEach(r => {
      newTimeTypeTaskResources.push({ ...r, resource_id: r.resource_id || r.id });
    });

    taskData.resources.forEach(res => {
      const changedResource = changedResources.find(r => res.resource_id === (r.resource_id || r.id));

      if (changedResource && changedResource.action === 'delete') {
        return;
      }

      if (changedResource && changedResource.action === 'update') {
        newTimeTypeTaskResources.push({ ...res, value: changedResource.value });
      }

      if (!changedResource) {
        newTimeTypeTaskResources.push({ ...res });
      }
    });

    newTimeTypeTaskResources = newTimeTypeTaskResources.filter(res => {
      const resourceData = globalStore.getters['resourcesModel/getResourceById'](res.resource_id);
      const settings = resourceData?.resourceProjects.find(o => o.projectId === taskData.gantt_id);

      if (!settings || settings.type !== constants.RESOURCE_TIME_TYPE) {
        return false;
      }

      res.customDays = resourceData.customDays;
      res.workingDays = resourceData.workingDays;
      res.workingHours = resourceData.workingHours;

      return res;
    });

    return newTimeTypeTaskResources;
  },
  prepareTaskDataForServer(taskData) {
    const preparedTaskData = {};

    if (taskData.start_date) {
      preparedTaskData.start_date = moment(taskData.start_date).format(constants.TASK_DATES_FORMAT);
    }

    if (taskData.end_date) {
      preparedTaskData.end_date = moment(taskData.end_date).format(constants.TASK_DATES_FORMAT);
    }

    if (taskData.deadline !== undefined) {
      preparedTaskData.deadline = taskData.deadline ? moment(taskData.deadline).format(constants.TASK_DATES_FORMAT) : null;
    }

    if (taskData.diff) {
      preparedTaskData.diff = JSON.stringify(taskData.diff);
    }

    return { ...taskData, ...preparedTaskData };
  },
};

export default tasksHelpers;
