var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "v-global-input",
        _vm.css ? _vm.css : "",
        _vm.isError ? "is-error" : "",
      ],
    },
    [
      _vm.label
        ? _c("label", { staticClass: "label" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorText
        ? _c("span", { staticClass: "error-text" }, [
            _vm._v("\n    " + _vm._s(_vm.errorText) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.type ? _vm.type : null) === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            class: _vm.disable ? "not_editable" : "",
            attrs: {
              id: _vm.id ? _vm.id : "",
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              readonly: _vm.disable,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.inputValue)
                ? _vm._i(_vm.inputValue, null) > -1
                : _vm.inputValue,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
                _vm.onHandleChange,
              ],
              input: _vm.onHandleInput,
            },
          })
        : (_vm.type ? _vm.type : null) === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            class: _vm.disable ? "not_editable" : "",
            attrs: {
              id: _vm.id ? _vm.id : "",
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              readonly: _vm.disable,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.inputValue, null) },
            on: {
              change: [
                function ($event) {
                  _vm.inputValue = null
                },
                _vm.onHandleChange,
              ],
              input: _vm.onHandleInput,
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            class: _vm.disable ? "not_editable" : "",
            attrs: {
              id: _vm.id ? _vm.id : "",
              placeholder: _vm.placeholder ? _vm.placeholder : "",
              readonly: _vm.disable,
              type: _vm.type ? _vm.type : null,
            },
            domProps: { value: _vm.inputValue },
            on: {
              change: _vm.onHandleChange,
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
                _vm.onHandleInput,
              ],
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }