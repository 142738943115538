import humanFileSize from './fileSize';

const __ = window.__;

function prepareXHR(file, userId) {
  const formData = new FormData();

  formData.append('enctype', 'multipart/form-data');
  formData.append('userId', userId);
  formData.append('uploadFile[]', file);

  return formData;
}

function uploadAvatar(file, userId) {
  const promise = webix.promise.defer();
  const xhr = new XMLHttpRequest();

  xhr.onload = function (data, k, n) {
    const response = JSON.parse(data.currentTarget.response);

    promise.resolve(response);
    if (response.status !== 'success') {
      webix.message({ type: 'warning', text: __('fileUpload_limitStore', { limit: humanFileSize(data.maxFileStore) }) });
      promise.resolve();
    }
  };

  xhr.open('POST', '/api/upload/avatar/');
  xhr.send(prepareXHR(file, userId));

  return promise;
}

export default uploadAvatar;
