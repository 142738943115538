// public/views/filterGroup.js
import app, { appIds }  from '../../app';

import './globalSearch.less';

const { events: { ID_APP_EVENT_SEARCHFILTER_CHANGE } } = appIds;

const _fltUiCfg = {
    idSelected: 0,
    entries: [{
        id: 0,
        name: __('app_searchfilter_btn_all'),
        count: -1
    }, {
        id: 1,
        name: __('app_searchfilter_btn_name'),
        count: -1
    }, { 
        id: 2,
        name: __('app_searchfilter_btn_descr'),
        count: -1
    }, {
        id: 3,
        name: __('app_searchfilter_btn_comments'),
        count: -1
    }, {
        id: 4,
        name: __('app_searchfilter_btn_files'),
        count: -1
    }
    ]
};

const _handlers = {
    onFltBtnClick: function(e, id, elem) {
        const __idFilter = elem.dataset && parseInt(elem.dataset.idfilter, 10);

        if (!__idFilter) {
            console.warn('[_handlers::onFltBtnClick] -> failed to get filter id!'); // !DEBUG
        }

        app.trigger(ID_APP_EVENT_SEARCHFILTER_CHANGE, {
            idControl: this.config.id,
            idFilter: __idFilter
         });
    }
};

const _customRadioGroupView = {
    view: 'template',
    css: 'search-filter',
    // height: 80,
    height: 48,
    // height: 'auto',
    borderless: true,
    data: { ..._fltUiCfg },
    template: ({ idSelected, entries }) => {
        // console.info('render:', idSelected, entries); // !DEBUG
        return `
            <div class="search-filter-container">
                <span>View:</span>
                ${entries.map(({ id, name, count }) => `
                    <span data-idfilter=${id} class="filter-btn" active="${id == idSelected}">
                        ${name}&nbsp<span class="btn-counter">${count !== -1 ? count : " "}</span>
                    </span>`
                ).join('')}
            </div>
        `
    },
    onClick: {
        'filter-btn': _handlers.onFltBtnClick
    }
};

export default _customRadioGroupView;