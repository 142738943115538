var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentConfig
    ? _c("context-menu", {
        key: _vm.currentConfig.controlNodeId,
        style: { padding: 0, overflowY: "unset" },
        attrs: {
          position: _vm.currentConfig.positionContext,
          "horizontally-align-center": "",
          offset: { top: -6 },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "notify-popup-slot",
                    _vm._b(
                      {
                        on: {
                          close: _vm.handleOnCloseNotificationPopup,
                          click: _vm.handleOnClickNotificationPopup,
                          mouseover: function ($event) {
                            return _vm.toggleControlHighlight(true, $event)
                          },
                          mouseleave: function ($event) {
                            return _vm.toggleControlHighlight(false, $event)
                          },
                        },
                      },
                      "notify-popup-slot",
                      _vm.currentConfig,
                      false
                    )
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3882712211
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }