var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      key: "group-chips-" + _vm.componentKey + "-" + _vm.items.length,
      ref: "wrap",
      class: [_vm.$style.wrap],
      style: { height: _vm.wrapHeight + "px" },
    },
    [
      _vm._l(_vm.numberToShow, function (n) {
        return _c(
          "div",
          {
            key: _vm.componentKey + "-gr-ch-" + n,
            class: _vm.$style.group_chip_wrap,
            attrs: {
              id: _vm.componentKey + "-group-itemm-" + (n - 1),
              "data-chip": "chip",
            },
          },
          [_vm._t("default", null, { item: _vm.items[n - 1] })],
          2
        )
      }),
      _vm._v(" "),
      _vm.restCount
        ? _c(
            "div",
            {
              class: _vm.$style.rest_count_chip,
              style: {
                left: _vm.commonItemsWidth + "px",
                height: _vm.wrapHeight + "px",
              },
              attrs: { "data-chip": "rest" },
            },
            [_vm._v("\n    +" + _vm._s(_vm.restCount) + "\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }