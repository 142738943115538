<template>
  <div
    :class="[$style.toggle_box, isToggleOn ? $style.on : null, isDisabled ? $style.disabled : null]"
    @click="toggle"
  >
    <div :class="$style.switch" />
  </div>
</template>

<script>

export default {
  props: {
    isToggleOn: {
      type: Boolean,
      required: true,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    onChange: Boolean,
  },
  methods: {
    toggle() {
      this.$emit('onChange');
    },
  },
};
</script>

<style module src='./less/toggle.less?module'></style>
