import Vue from 'vue';
import helpers from './helpers';
import { api } from '$$store/api';

export default {
  async copy({ commit }, taskId) {
    // if (!helpers.isAvailablePastePopup()) { return; }

    try {
      const copiedData = helpers.prepareDataForCopy(taskId);

      const res = await api.get('/task/copy-settings', {
        params: { user_id: user.id, project_id: copiedData.ganttId },
      });

      const val = res.data;

      commit('hasCopiedTask', true);
      commit('setCopiedTask', { ...copiedData });
      commit('setSpecialPasteConfig', { ...val });
      Vue.$toast.info(__('task_was_copied'));
    } catch (error) {
      console.error(error);
    }
  },

  async paste({ commit, state }, data) {
    try {
      const pastedData = helpers.prepareDataForPaste(data, state.copiedTask);

      commit('setCopiedTask', { ...pastedData });
      commit('hasCopiedTask', true);
      await api.post('/task/copy-tasks', { ...pastedData });
    } catch (error) {
      console.error(error);
    }
  },

  async updateConfig({ commit, state }, updated = null) {
    const updatedConfig = {
      ...state.specialPasteConfig,
      ...updated,
    };

    await api.post('/task/copy-settings', updatedConfig);
    commit('updateConfig', updated);
  },

  async applyCopyDataByOptions({ state }, data) {
    try {
      await api.post('/task/copy-by-options', {
        copyData: state.copiedTask,
        taskMap: state.taskMap,
        copyOptions: data.copyOptions,
        removeOptions: data.removeOptions,
        links: data.links,
      });
    } catch (error) {
      console.error(error);
    }
  },
};
