<template>
  <div class="new-dependency-form">
    <div class="new-dependency-form__headers">
      <div class="header-label">
        {{ locales('settings_dependency_task_name') }}
      </div>
      <div class="header-label">
        {{ locales('settings_dependency_link_type') }}
      </div>
      <div class="header-label">
        {{ locales('settings_dependency_lag') }}
      </div>
    </div>
    <div class="new-dependency-form__body">
      <autocomplete-select
        ref="autocompleteSelect"
        v-model="form.targetTask"
        filter-by="text"
        additionally-filter-by="wbs"
        track-by="id"
        :get-items-callback="getAvailableTasksForDependencyCreation"
      />
      <div class="new-dependency__types">
        <div
          v-for="(item, index) in availableDependencyTypes"
          :key="index"
          ref="dependencyTypes"
          :class="[
            'new-dependency__type',
            form.dependencyType === item.value ? 'new-dependency__type_selected': ''
          ]"
          @click.stop="selectDependency(item.value)"
          @mouseenter="showDependencyPreview(index)"
          @mouseleave="dependencyPreviewProps.position = null"
          v-html="item.icon"
        />
        <dependency-preview
          v-if="dependencyPreviewProps.position"
          v-bind="dependencyPreviewProps"
        />
      </div>
      <vgp-input
        :key="inputLagKey"
        :value="formattedLag"
        placeholder="Lag"
        small
        @onChange="handleChangeLag"
      />
      <vgp-button
        type="primary"
        small
        :label="locales('common_save')"
        :width="saveButtonWidth"
        @onClick="addNewDependency"
      />
      <vgp-icon-button
        :icon="{ name: 'close-1' }"
        @onClick="$emit('close')"
      />
    </div>
  </div>
</template>

<script>

import FF from '../../../../../svg/dependencyTypes/finish_to_finish.svg';
import SF from '../../../../../svg/dependencyTypes/start_to_finish.svg';
import FS from '../../../../../svg/dependencyTypes/finish_to_start.svg';
import SS from '../../../../../svg/dependencyTypes/start_to_start.svg';

import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import AutocompleteSelect from './AutocompleteSelect.vue';
import linksModule from '../../../../gantt/modules/link';
import timeParser from '$$helpers/timeParser';
import DependencyPreview from './DependencyPreview.vue';
import globalStore from '$$store/main';
import routerHelper from '$$helpers/router';
import { getTaskBreadcrumbs } from '../../../helpers';
import TaskSettingsController from '../../../controllers';

export default {
  name: 'NewDependencyForm',
  components: { DependencyPreview, AutocompleteSelect, VgpIconButton },
  props: {
    existingDependencies: {
      type: Array,
      required: true,
    },
    taskData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locales: __,
      dependencyPreviewProps: {
        position: null,
        type: null,
        description: null,
      },
      form: {
        lag: 0,
        dependencyType: null,
        targetTask: null,
      },
      isCreatingDependency: false,
      inputLagKey: 0,
      availableDependencyTypes: [
        {
          icon: FS,
          value: 'finish_to_start',
          name: __('settings_dependency_FS'),
          description: __('settings_dependency_FS_description'),
        },
        {
          icon: FF,
          value: 'finish_to_finish',
          name: __('settings_dependency_FF'),
          description: __('settings_dependency_FF_description'),
        },
        {
          icon: SS,
          value: 'start_to_start',
          name: __('settings_dependency_SS'),
          description: __('settings_dependency_SS_description'),
        },
        {
          icon: SF,
          value: 'start_to_finish',
          name: __('settings_dependency_SF'),
          description: __('settings_dependency_SF_description'),
        },
      ],
    };
  },
  computed: {
    formattedLag() {
      return timeParser.output(this.form.lag, {
        durationData: gantt.config.durationData,
        durationStep: gantt.config.duration_view,
        prop: 'lag',
      });
    },
    projectTasks() {
      return this.$store.getters['tasksModel/getItem'](this.taskData.gantt_id).tasks;
    },
    saveButtonWidth() {
      if (window.user.locale === 'ru') return '100px';
      return null;
    },
  },
  mounted() {
    this.$el.scrollIntoView();
    this.$nextTick(() => {
      this.$refs.autocompleteSelect.$refs.searchInput.$refs.input_field_icons.focus();
    });
  },
  methods: {
    selectDependency(value) {
      this.form.dependencyType = value;
    },
    async getAvailableTasksForDependencyCreation() {
      const isCurrentTask = taskId => this.taskData.id === taskId;
      const isTotalEstimationTask = task => task.type === 'project' && !task.parent;
      const isParentOfCurrentTask = taskId => gantt.isChildOf(this.taskData.id, taskId);
      const isTaskAlreadyUsed = taskId => this.existingDependencies.some(dependency => dependency.taskId === taskId);
      const isTaskDependencyParent = taskId => this.existingDependencies.some(dependency => gantt.isChildOf(dependency.taskId, taskId));

      const filteredTasks = this.projectTasks.filter(task => {
        return !(isTaskAlreadyUsed(task.id) || isTaskDependencyParent(task.id) || isParentOfCurrentTask(task.id)
            || isCurrentTask(task.id) || isTotalEstimationTask(task));
      });

      if (!filteredTasks.length) return [];

      const projectTasksMap = this.$store.getters['tasksModel/getProjectsTasksMap'][this.taskData.gantt_id];
      const { tasksWBSCodes } = await TaskSettingsController.getTasksWbsCodes([this.taskData.gantt_id]);

      return filteredTasks.map(task => ({
        ...task,
        wbs: tasksWBSCodes[task.id] ?? '',
        parentPath: getTaskBreadcrumbs(task, projectTasksMap).join(' / '),
      }));
    },
    showDependencyPreview(index) {
      const dependencyRef = this.$refs.dependencyTypes[index];

      if (dependencyRef) {
        Object.assign(this.dependencyPreviewProps, {
          type: this.availableDependencyTypes[index].value,
          position: dependencyRef.getBoundingClientRect(),
          name: this.availableDependencyTypes[index].name,
          description: this.availableDependencyTypes[index].description,
          predecessorName: this.taskData.text,
          successorName: this.getSuccessorName(),
        });
      }
    },
    getSuccessorName() {
      if (!this.form.targetTask) {
        return '';
      }

      return this.$store.getters['tasksModel/getTask'](this.form.targetTask.id)?.text || '';
    },
    handleChangeLag(event) {
      const { value } = event.target;
      const parsedResult = timeParser.input(value, {
        durationData: gantt.config.durationData,
        durationStep: gantt.config.duration_view,
        prop: 'lag',
      });

      if (!parsedResult) {
        this.form.lag = 0;

        return;
      }

      if (parsedResult.value === this.form.lag) {
        this.inputLagKey++;

        return;
      }

      this.form.lag = parsedResult.value;
    },
    checkAddLink(sourceId, targetId, masterMode) {
      return !this.existingDependencies.some(dependencyObject => {
        if (masterMode && dependencyObject.taskId === sourceId) {
          return true;
        }
        if (!masterMode && dependencyObject.taskId === targetId) {
          return true;
        }
      });
    },
    async addNewDependency() {
      if (this.isCreatingDependency) return;

      if (!this.form.targetTask) {
        this.$toast.warning(__('gantt_msg_dependency_task_not_selected'));

        return;
      }

      if (!this.form.dependencyType) {
        this.$toast.warning(__('gantt_msg_dependency_type_not_selected'));

        return;
      }

      const projectTasksMap = this.$store.getters['tasksModel/getProjectsTasksMap'][this.taskData.gantt_id];

      if (this.form.targetTask && !projectTasksMap[this.form.targetTask.id]) {
        this.form.targetTask = null;
        this.$toast.warning(__('gantt_msg_dependency_task_was_deleted'));

        return;
      }

      this.isCreatingDependency = true;
      const sourceId = this.taskData.id;
      const targetId = this.form.targetTask.id;

      const link = {
        source: sourceId,
        target: targetId,
        gantt_id: this.taskData.gantt_id,
        type: gantt.config.links[this.form.dependencyType],
        lag: this.form.lag,
      };

      const startDate = linksModule.helpers.getStartAccordingToType(link);
      const calcDate = gantt.calculateToDuration(startDate, this.form.lag, gantt.config.duration_unit);

      link.lag = linksModule.helpers.customCalculateDuration(startDate, calcDate.endDate, gantt.config.duration_unit);

      if (!this.checkAddLink(sourceId, targetId, 1) || gantt.isCircularLink(link)) {
        this.$toast.info(__('gantt_msg_dependency_limit_error'));

        return;
      }

      const data = await globalStore.dispatch('tasksModel/backgroundAddLink', link);

      userExtAnalytics.log('gantt_link_add_done', { from: 'task settings' });
      // if (!routerHelper.isListViewRoute()) {
      //   gantt.callEvent('onAfterLinkUpdate', [data.id, data]);
      // }

      await gantt.ganttWorker.calculate([data], 'updateLinks');

      this.$emit('dependency-created');

      this.isCreatingDependency = false;

      Object.assign(this.form, {
        lag: 0,
        dependencyType: null,
        targetTask: null,
      });

      gantt.callEvent('taskEditor:task:changed', [{ id: this.taskData.id }]);

      userExtAnalytics.log('task_settings_action', { action: 'link_add', type: this.form.dependencyType });
    },

  },
};
</script>

<style scoped lang="less">

.new-dependency-form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 4px;
  margin-bottom: 20px;

  .new-dependency-form__headers, .new-dependency-form__body {
    display: grid;
    grid-template-columns: 300px 218px 95px auto 32px;
    grid-column-gap: 8px;
    align-items: center;
  }

  .header-label {
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
  }

  .new-dependency__types {
    display: flex;
    gap: 4px;
  }

  .new-dependency__type {
    width: 50px;
    height: 32px;
    &:hover, &_selected {
      cursor: pointer;
      ::v-deep svg g path {
        fill: #A1C1E6;
        stroke: #1565C0;
      }
      ::v-deep svg g path:nth-of-type(3) {
        fill: #1565C0;
        stroke: none;
      }
      ::v-deep svg rect {
        stroke: #1565C0;
      }
    }
  }

  .link-preview {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 171px;
      width: 300px;
      background: #FFFFFF;
      box-shadow: 0 12.6148px 30.8361px -2.80328px rgba(0, 0, 0, 0.2);
      border-radius: 5.60656px;
    }

    &__header {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #191919;
      padding: 13px 48px 0 26px;
      span {
        font-family: Lato-Bold, sans-serif;
      }
    }

    &__body {
      position: relative;
      height: 100%;
      width: 100%;
    }

    &__predecessor, &__successor {
      width: 145px;
      height: 26px;
      border-radius: 5.60656px;
      position: absolute;
    }

    &__predecessor {
      background: #7986CB;
      &_filled {
        height: 100%;
        width: 54px;
        background: #454D79;
        border-radius: 5.60656px 0px 0px 5.60656px;
      }
    }

    &__successor {
      background: #82DCD7;
      &_filled {
        height: 100%;
        width: 54px;
        background: #2DA59D;
        border-radius: 5.60656px 0px 0px 5.60656px;
      }
    }

    &__arrow {
      position: absolute;
    }
  }

}

</style>
