var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dropCell", {
    class: _vm.datePickerId,
    attrs: {
      disabled: _vm.disabled,
      editable: _vm.editable,
      "manual-open": true,
      "suggest-height": 257,
      "drop-width": 260,
    },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function (ref) {
          var isOpen = ref.isOpen
          return [
            _c(
              "div",
              {
                ref: "trigger",
                class: [
                  _vm.$style.cell_trigger,
                  _vm.$style.center,
                  !_vm.editable ? _vm.$style.not_allowed : "",
                ],
                on: { click: _vm.triggerClick },
              },
              [
                _c(
                  "span",
                  {
                    class: [
                      _vm.$style.text_wrap,
                      _vm.$style.text_center,
                      _vm.disabled ? _vm.$style.text_disabled : "",
                      _vm.crossout && (_vm.disabled || !isOpen)
                        ? _vm.$style.crossout
                        : "",
                      _vm.isOverdue ? _vm.$style.overdue : "",
                    ],
                    style: { opacity: isOpen ? 1 : _vm.cellOpecity },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.formattedDate) + "\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
      {
        key: "drop",
        fn: function () {
          return [
            _c("webix-ui", {
              ref: "drop",
              staticClass: "custom_datepicker_clear",
              attrs: { config: _vm.datepickerConfig },
              nativeOn: {
                click: function ($event) {
                  return _vm.calendarClick($event)
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.pickerOpen,
      callback: function ($$v) {
        _vm.pickerOpen = $$v
      },
      expression: "pickerOpen",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }