<template>
  <div>
    <div
      :class="[$style['comments-list-header']]"
    >
      <div
        v-if="taskData && !showTask"
        :class="[$style['comments-list-header_label'], 'gp_autotest_comment_hub_comments_list_header']"
      >
        <span
          :class="[$style['comments-list-header_label_text']]"
        >

          {{ taskData.text }}
        </span>
      </div>
      <div
        :class="[$style['comments-list-header_button'],
                 showTask ? $style['comments-list-header_button_open'] : '',
                 'gp_autotest_comment_hub_comments_list_change_view_button']"
      >
        <vgp-button
          v-if="taskData"
          class="change-view-btn"
          type="text"
          small
          :label="locales( !showTask ? 'comment-hub-open-task' : 'comment-hub-close-task')"
          @onClick="changeView"
        />
      </div>
    </div>
    <div
      :class="[ $style['comments-list-body'], showTask ? $style['comments-list-body-with-task'] : '', taskData ? '' : $style['comments-list-body-without-task'] ]"
    >
      <div>
        <div
          v-if="!showTask && !taskData"
          :class="[$style['no-selected-task-stub'], $style['static-comments-section'],
                   'gp_autotest_comment_hub_comments_list_no_selected_task_stub' ]"
        >
          <svgImage
            :name="'comment-hub-no-selected-task'"
            :class="[$style['current-stub']]"
          />
          <div
            :class="[$style['no-search-text']]"
          >
            {{
              stubTitle
            }}
          </div>
        </div>
      </div>

      <div
        v-if="taskId && taskData"
        ref="taskSettingsBodySection"
        :style="{height : currentHeight + 'px'}"
        class="gantt_scroll"
        @scroll="handleTaskSettingsBodyScroll"
      >
        <div
          :class="[ !showTask ? $style['task-settings'] : '' , isShifted ? $style['element-shifted'] : '' ,
                    'gp_autotest_comment_hub_comments_list_task_settings']"
        >
          <task-settings-main
            :task-id="taskId"
            :height="height"
            :hide-for-hub="!showTask"
            :comment-hub="true"
            @comment="setCommentEditor"
          />
        </div>
        <div
          v-if="taskId && !comments.length"
          ref="taskSettingsStubSection"
          :style="{ width: '100%', height: '100%'}"
          :class="[$style['static-comments-section'], $style['no-selected-task-stub'], isShifted ? $style['settings-main'] : '',
                   'gp_autotest_comment_hub_comments_list_no_comments_in_task_stub']"
        >
          <svgImage
            :name="'comment-hub-no-selected-task'"
            :class="[$style['current-stub']]"
          />
          <div
            :class="[$style['no-search-title']]"
          >
            {{
              locales('comment-hub-no-comments-in-task-title')
            }}
          </div>
          <div
            :class="[$style['no-search-text']]"
          >
            {{
              locales('comment-hub-no-comments-in-task')
            }}
          </div>
        </div>
        <div
          v-if="comments.length"
          :style="{ width: '100%', height: longComments || (!longComments && !isShifted) ? '100%' : ''}"
          :class="[$style['static-comments-section_list'],$style['static-comments-section'], isShifted ? $style['settings-main'] : '' ]"
        >
          <comments-list
            ref="taskSettingsCommentsSection"
            :class="[longComments ? '' : $style['static-comments-section_short'], 'gp_autotest_comment_hub_comments_list']"
            :comments="comments"
            :task-data="taskData"
            :task-id="taskId"
            :show-task="showTask"
            @delete-comment="handleDeleteComment"
            @comments-height="checkHeight"
            @edit-comment="handleClickEditComment"
            @new-comment="handleTaskSettingsBodyScroll"
            @dragstart.native.prevent
          />
        </div>

        <task-settings-footer
          ref="taskSettingsFooter"
          class="gp_autotest_comment_hub_comment_editor"
          :current-edit-comment="currentEditComment"
          :task-data="taskData"
          :task-id="taskId"
          :comment-hub="true"
          :unread-comment-ids="unreadCommentIds"
          :mention="mention"
          :show-scroll-to-bottom-button="showScrollToBottomButton"
          :disabled="isCommentDisabled"
          @submit="handleSubmitCommentEditor"
          @scrollToBottom="scrollToBottom"
          @cancel="cancelEditor"
          @openEditor="openEditor"
          @dragleave.native.stop
          @dragstart.native.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import TaskSettingsMain from '../../taskSettings/TaskSettingsMain.vue';
import svgImage from '../../common/VueComponents/VueIcon/vgpSvgImage.vue';
import TaskSettingsFooter from '../../taskSettings/footer/TaskSettingsFooter.vue';
import commentsList from './commentsList.vue';
import rights from '../../../components/rights';
import app from '../../../app';

export default {
  name: 'CommentsTaskSettingList',
  components: {
    TaskSettingsFooter,
    svgImage,
    commentsList,
    TaskSettingsMain,
  },
  provide() {
    return {
      isFieldDisabled: this.isFieldDisabled,
    };
  },
  props: {

    taskId: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locales: __,
      currentEditComment: null,
      showComments: false,
      showTask: false,
      commentEditor: 110,
      isShifted: false,
      disabledFieldsWithReasons: {},
      commentEditorCoordinates: null,
      longComments: false,
      showScrollToBottomButton: false,
      isCommentDisabled: false,
      currentHeight: this.height - 110,
      observer: null,
      unreadCommentIds: [],
      mention: false,
    };
  },
  computed: {
    stubTitle() {
      return __('comment-hub-no-selected-task');
    },

    // unreadCommentIds() {
    //   const commentsIds = this.comments.map(comment => comment.id);
    //   const unreadTaskCommentsActions = this.$store.state.comments.commentsActions.filter(commentAction => (
    //     commentsIds.includes(commentAction.commentId) && !commentAction.read
    //   ));

    //   return unreadTaskCommentsActions.map(commentAction => commentAction.commentId);
    // },
    stub() {
      return this.$refs.taskSettingsStubSection;
    },
    commentsActions() {
      const actions = this.$store.getters['comments/getCommentsActions'];

      return _.keyBy(actions, 'commentId');
    },
    // mention() {
    //   let mention = false;
    //   // const member = this.$store.getters['resourcesModel/getResourceByUserId'](user.id);

    //   this.comments.some(item => {
    //     if (item && this.commentsActions[item.id]?.read === 0) {
    //       const mentions = item.content.match(/\[\~(\d+)\]/g);
    //       const ids = mentions ? mentions?.map(mentionItem => parseInt(mentionItem.match(/\d+/)[0], 10)) : [];

    //       if (ids.length && ids.includes(user.id)) {
    //         mention = true;
    //       }
    //     }
    //   });

    //   return mention;
    // },
    taskData() {
      return this.$store.getters['tasksModel/tasksByIds'][this.taskId];
    },
    comments() {
      return this.$store.getters['comments/getCommentsByProjectAndTaskId'](this.taskData?.gantt_id, this.taskData?.id);
    },
    isTaskSettingsFooterVisible() {
      return !!(this.taskId && this.taskData);
    }
  },
  watch: {
    taskId() {
      this.showComments = true;
      this.showTask = false;
      this.isShifted = false;
      this.showScrollToBottomButton = false;

      const taskSettingsBodyRef = this.$refs.taskSettingsBodySection;

      if (this.stub) this.stub.style.display = 'flex';

      if (taskSettingsBodyRef) {
        const { scrollHeight } = taskSettingsBodyRef;

        if (!this.unreadCommentIds.length) taskSettingsBodyRef.scrollTo({ top: scrollHeight, behavior: 'smooth' });
      }
      this.setIsCommentDisabled();
    },

    taskData(data) {
      if (!data) {
        this.showTask = false;
      }
    },
    isTaskSettingsFooterVisible(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          this.observer.observe(this.$refs.taskSettingsFooter?.$el);
        });
      } else {
        this.observer.disconnect();
      }
    },
    comments(val, oldVal) {
      if (val.length !== oldVal.length) {
        this.handleTaskSettingsBodyScroll();
      }

      if (this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments) {
        this.unreadCommentIds = [...this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments];
      }
      this.mention = !!this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadMentions.size;
    },

    commentsActions() {
      if (this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments) {
        this.unreadCommentIds = [...this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadComments];
      }
      this.mention = !!this.$store.state.comments.taskCommentsMetaData[this.taskId]?.unreadMentions.size;
    },

  },
  mounted() {
    this.observer = new ResizeObserver(entries => {
      entries.forEach(data => {
        const height = data.contentRect.height;

        let oldVal = 0;

        if (height <= 208) this.currentHeight = (this.height - height);
        if (height > oldVal) {
          this.currentHeight = (this.height - height - oldVal);
          oldVal = data.contentRect.height;
        }
      });
    });
  },
  created() {
    app.on('onAfterCollaboration', data => {
      if (data.event === 'ResourceOnProjectsUpdated' && data.isUpdatedRole) {
        this.setIsCommentDisabled();
      }
      if (data.event === 'ResourceToProjectsAssigned') {
        rights.project.clearCacheGetUserRole();
        this.setIsCommentDisabled();
      }
    });
  },
  methods: {
    checkHeight(e) {
      this.longComments = e && (e > this.height);
    },
    setIsCommentDisabled() {
      this.isCommentDisabled = this.taskData && !rights.project.hasRight(this.taskData.gantt_id, 'comments');
    },
    isFieldDisabled(fieldName) {
      if (this.taskData && fieldName === 'comments') return this.isCommentDisabled;
    },
    changeView() {
      this.showComments = !this.showComments;
      this.showTask = !this.showTask;
      this.isShifted = !this.isShifted;

      if (this.isShifted) {
        this.$nextTick(() => {
          setTimeout(() => {
            const el = this.$refs.taskSettingsBodySection;

            el && el.scrollTo({ top: 0, behavior: 'smooth' });
            el.style.transform = 'translateY(0%)';
          }, 0);
          this.stub && setTimeout(() => {
            this.stub.style.display = 'none';
          }, 500);
        });
        this.$nextTick(() => {
          setTimeout(() => {
            const el = this.$refs.taskSettingsBodySection;

            el.style.transform = '';
          }, 0);
        });
      } else {
        this.$nextTick(() => {
          this.stub && setTimeout(() => {
            this.stub.style.display = 'flex';
          }, 0);
        });
      }
    },
    async handleSubmitCommentEditor() {
      this.currentEditComment = null;
      this.$refs.taskSettingsBodySection.scrollIntoView({ block: 'end' });
      this.scrollToBottom();
      this.$emit('scrollTasksList');
      this.currentHeight = this.height - 110;
    },
    setCommentEditor(val) {
      if (val) this.commentEditor = 208;
      else this.commentEditor = 110;
    },
    scrollToBottom() {
      const taskSettingsBodyRef = this.$refs.taskSettingsBodySection;
      const { scrollHeight } = taskSettingsBodyRef;

      if (this.unreadCommentIds.length) this.$store.dispatch('comments/markAsRead', { taskIds: [this.taskData.id], commentIds: this.unreadCommentIds });
      taskSettingsBodyRef.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    },
    openEditor(val) {
      if (!val) {
        this.cancelEditor();
      }
    },
    handleTaskSettingsBodyScroll: _.debounce(function () {
      const taskSettingsBodyRef = this.$refs.taskSettingsBodySection;

      if (taskSettingsBodyRef) {
        const { scrollTop, clientHeight } = taskSettingsBodyRef;

        const heightB = taskSettingsBodyRef.scrollHeight;
        const taskSettingsCommentsRef = this.$refs.taskSettingsCommentsSection?.$el;

        if (this.showTask) {
          this.showScrollToBottomButton = heightB < clientHeight ? false : scrollTop < heightB - clientHeight;

          return;
        }
        if (taskSettingsCommentsRef) {
          const { scrollHeight } = taskSettingsCommentsRef;

          this.showScrollToBottomButton = scrollHeight < clientHeight ? false : scrollTop < scrollHeight - clientHeight;
        }
      }
    }, 100),
    handleDeleteComment(commentId) {
      this.$store.dispatch('comments/deleteComment', {
        projectId: this.taskData.gantt_id,
        taskId: this.taskData.id,
        commentId,
      }).then(() => {
        this.cancelEditor();
      });
    },
    handleClickEditComment(comment) {
      this.currentEditComment = comment;
    },
    cancelEditor() {
      this.currentEditComment = null;
    },
  },
};

</script>
<style module src="../less/commentsHub.less" lang="less"></style>
