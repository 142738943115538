<template>
  <div
    id="skeletonListView"
    :class="$.skeleton_container"
  >
    <skeleton-wrapper
      id="skeletonWrapper"
      :style="{width: '100%'}"
    >
      <template #body>
        <div>
          <div
            :class="$.headerRow"
          >
            <div
              v-for="column in 4"
            >
              <skeleton-item
                :type="'bar'"
                :style="{width: `107px`, height: '17px'}"
              />
            </div>
          </div>
        </div>
        <table :class="$.skeleton_table">
          <tbody>
            <tr
              v-for="row in 10"
            >
              <td :class="$.firstCell">
                <div :class="$.resourcePart">
                  <skeleton-item
                    :type="'circle'"
                    :size="'bg'"
                    :style="{marginRight: '8px'}"
                  />
                  <skeleton-item
                    :type="'bar'"
                    :style="{height: '14px'}"
                    width="wide"
                  />
                </div>
                <skeleton-item
                  :type="'square'"
                  :size="'bg'"
                />
              </td>
              <td
                v-for="col in 80"
                :class="$.skeleton_col"
              >
                <skeleton-item
                  v-if="checkboxes[row][col]"
                  :type="'square'"
                  :size="'md'"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </skeleton-wrapper>
  </div>
</template>

<script>
import skeletonItem from '../../common/VueComponents/skeletons/skeletonItem.vue';
import skeletonWrapper from '../../common/VueComponents/skeletons/skeletonWrapper.vue';

export default {
  name: 'SkeletonMain',
  components: { skeletonWrapper, skeletonItem },
  data() {
    return {
      checkboxes: [],
    };
  },

  created() {
    this.generateCheckboxes();
  },
  methods: {

    generateCheckboxes() {
      for (let i = 1; i <= 10; i++) {
        this.checkboxes[i] = [];
        for (let j = 1; j <= 80; j++) {
          const random = Math.random();

          this.checkboxes[i][j] = random < 0.1;
        }
      }
    },

  },
};
</script>

<style module="$" lang="less">
.skeleton {

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_table {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    tbody {
      tr {
        display: flex;
        height: 36px;
        position: relative;
        &:last-child{
          .firstCell, .skeleton_col {
            border-bottom: none;
          }
        }
      }

    }
  }

  &_row {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 12px;

  }
}

.firstCell {
  width: 298px;
  display: flex;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  height: 36px;
}

.lastCell {
  width: 330px;

}

.headerRow{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 16px;

  th{
    width: 132px;
    padding-left: 12px;
    &:first-child{
      width: 298px;
      margin-left: 8px;
    }
  }
}
.skeleton_col {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 36px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resourcePart{
  display: flex;
  align-items: center;
}
</style>
