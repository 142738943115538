var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sso-form" },
    [
      _c("input-with-button", {
        attrs: {
          "input-placeholder": _vm.locales("sso_placeholder_domain"),
          "button-text": _vm.locales("sso_btn_domain"),
          "get-value": _vm.addSsoDomain,
          styles: "margin-bottom: 18px",
          "is-error": _vm.ssoConfig.isError,
          "error-text": _vm.locales("sso_error_input_validation_domain"),
          "on-input": _vm.onInputDomain,
        },
      }),
      _vm._v(" "),
      _vm.ssoConfig.domains && _vm.ssoConfig.domains.length
        ? _c("div", { staticClass: "list-container margin-bottom" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.locales("sso_list_title_domains")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.ssoConfig.domains, function (item) {
                return _c("div", { key: item.id, staticClass: "list-item" }, [
                  _c("div", { class: ["text", _vm.isCopied ? "copied" : ""] }, [
                    _vm._v("\n          " + _vm._s(item.domain) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "div",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function ($event) {
                            return _vm.deleteDomainClick(item.id)
                          },
                        },
                      },
                      [
                        _c("webix-ui", {
                          staticClass: "delete-icon",
                          attrs: { config: _vm.icDelete },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-input", {
        attrs: {
          css: "sso-form-input",
          label: _vm.locales("sso_label_identity_provider_single_signOn_url"),
          placeholder: _vm.locales("sso_placeholder_identity_provider"),
          "handle-change": _vm.checkSignOnUrl,
          value: _vm.ssoConfig.ssoUrl,
        },
      }),
      _vm._v(" "),
      _c("v-input", {
        attrs: {
          css: "sso-form-input",
          label: _vm.locales("sso_label_issuer"),
          placeholder: _vm.locales("sso_placeholder_issuer"),
          "handle-change": _vm.checkIssuerHandle,
          value: _vm.ssoConfig.issuer,
        },
      }),
      _vm._v(" "),
      _c("v-text-area", {
        attrs: {
          label: _vm.locales("sso_label_sertificate"),
          placeholder: _vm.locales("sso_placeholder_certificate"),
          styles: "height: 110px; resize: none",
          "handle-change": _vm.checkCertificateHandle,
          value: _vm.ssoConfig.certificate,
          "is-check": _vm.isForceAllSsoCheckbox,
        },
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            ref: "ssoCheckbox",
            class: [
              "checkbox-row",
              _vm.ssoConfig.disableAllSsoCheckbox
                ? "tooltip-gantt disable"
                : "",
            ],
            attrs: {
              "data-key": _vm.locales("sso_tooltip_force_all"),
              "data-position": "top",
              "data-x": _vm.tooltipPosition,
              "data-y": -3,
            },
            on: {
              click: function ($event) {
                return _vm.changeAllSso(
                  _vm.ssoConfig.checkboxValue,
                  _vm.ssoConfig.disableAllSsoCheckbox
                )
              },
            },
          },
          [
            _c("vgp-checkbox", {
              attrs: {
                checked: _vm.ssoConfig.checkboxValue,
                "is-disable": _vm.ssoConfig.disableAllSsoCheckbox,
              },
              on: {
                onChange: function ($event) {
                  return _vm.changeAllSso(
                    _vm.ssoConfig.checkboxValue,
                    _vm.ssoConfig.disableAllSsoCheckbox
                  )
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "label" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.locales("sso_label_force_all_team_members")) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.ssoConfig.checkboxValue
        ? _c(
            "div",
            { staticClass: "sso-excluded-emails" },
            [
              _c("div", {
                staticClass: "label",
                style: { marginBottom: 12 + "px" },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.locales("sso_description_force_all_team_members")
                  ),
                },
              }),
              _vm._v(" "),
              _c("input-with-button", {
                attrs: {
                  "input-placeholder": _vm.locales("sso_placeholder_excluded"),
                  "button-text": _vm.locales("sso_btn_excluded"),
                  "get-value": _vm.addSsoExcludedEmailClick,
                  styles: "margin-bottom: 18px",
                  "is-error": _vm.ssoConfig.isExcludedEmailError,
                  "error-text": _vm.locales(
                    "sso_error_input_validation_excluded_emails"
                  ),
                  "tooltip-key": _vm.locales("sso_tooltip_excluded_email_btn"),
                  "on-input": _vm.onInputExclude,
                },
              }),
              _vm._v(" "),
              _vm.ssoConfig.excludedEmails &&
              _vm.ssoConfig.excludedEmails.length
                ? _c("div", { staticClass: "list-container margin-bottom" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.locales("sso_list_title_excluded")) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(_vm.ssoConfig.excludedEmails, function (item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "list-item" },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.email) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "btns" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "delete-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteExcludedEmailClick(
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("webix-ui", {
                                    staticClass: "delete-icon",
                                    attrs: { config: _vm.icDelete },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "main-btn gray", on: { click: _vm.hideSSOForm } },
        [_vm._v("\n    " + _vm._s(_vm.locales("sso_btn_deactivate")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }