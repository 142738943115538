<template>
  <div :class="[$.card_block, $[type]]" @click.stop="itemClick">
    <template v-if="type === 'portfolio'">
      <div :class="$.portfolio_icon">
        <svg-sprite
          :type="'regular'"
          :name="'portfolio'"
          :size="72"
        />
      </div>
      <div :class="$.card_details">
        <div :class="$.name_wrapper">
          <div :class="[$.star, favorite && $.active]" @click.stop="favClick">
            <svg-sprite
              :type="favorite ? 'bold' : 'regular'"
              :name="'star'"
              :size="20"
            />
          </div>
          <div :class="$.item_name" v-if="!isEditing">{{ item.name }}</div>
          <div :class="$.item_input" v-else>
            <input
              ref="input"
              type="text"
              v-model="name"
              @click.stop
              @blur="updateName"
              @keyup.enter.stop="updateName"
            />
            <div :class="$.input_actions">
              <div @click.stop>
                <icon-button
                  id="confirm_edit"
                  type="colored"
                  :icon="{ name: 'check' }"
                />
              </div>
              <div @click.stop>
                <icon-button
                  :icon="{ name: 'close-1' }"
                />
              </div>
            </div>
          </div>
          <icon-button
            v-if="!isEditing"
            :class="$.edit"
            :icon="{ name:'more-2' }"
            size="small"
            @onClick="toggleContextMenu"
          />
        </div>
        <div :class="$.metadata">
          <div v-for="data in metadata" :key="data">
            {{ data }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="type === 'button'">
      <svg-sprite
        :type="'regular'"
        :name="'plus-1'"
        :color="'#1565C0'"
        :size="20"
      />
      <div :class="$.button_label">{{ item.name }}</div>
    </template>

    <context-menu 
      v-if="showContextMenu"
      :items="contextMenuItems"
      :position="contextMenuPosition"
      @selectItemMenu="onSelectContextItem($event)"
      @onClickOutside="onClickOutside"
      @closePopup="onClickOutside"
    />
  </div>
</template>

<script>
import iconButton from '../globalButton/withIcon/iconButton.vue';

export default {
  name: 'Card',
  components: { iconButton },
  props: {
    type: {
      type: 'portolio' | 'button',
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    metadata: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isEditing: false,
      name: this.item.name,
      showContextMenu: false,
      contextMenuPosition: null
    };
  },
  methods: {
    updateName(event) {
      if (!this.isEditing) {
        return;
      }

      this.isEditing = false;

      if (event.type === 'blur') {
        if (!event.relatedTarget || event.relatedTarget.id !== 'confirm_edit') {
          this.name = this.item.name;
          return;
        }
      }

      this.$emit('updateName', { item: this.item, name: this.name.trim()});
    },
    toggleContextMenu(e) {
      e.stopPropagation();
      this.contextMenuPosition = e.target.getBoundingClientRect();
      this.showContextMenu = !this.showContextMenu;
    },
    onSelectContextItem(menuItem) {
      switch (menuItem) {
        case 'rename_portfolio':
          this.startEdit();
          break;
        case 'favorite_portfolio':
          this.favClick();
          break;
        case 'delete_portfolio':
          this.startDelete();
          break;
        default:
          break;
      }
    },
    onClickOutside() {
      this.showContextMenu = false;
    },
    startEdit() {
      this.isEditing = !this.isEditing;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    startDelete() {
      this.$emit('startDelete');
    },
    itemClick() {
      this.$emit('itemClick');
    },
    favClick() {
      this.$emit('favClick');
    }
  },
  computed: {
    favorite() {
      return this.item.is_favorite;
    },
    contextMenuItems() {
      return [
        {
          name: 'common_rename',
          id: 'rename_portfolio',
          icon: 'pensil',
          gpTestId: 'gp_autotest_rename_portfolio',
          iconType: 'regular',
          iconSize: 24,
          iconColor: '#191919',
        },
        {
          name: this.favorite ? 'settings_multiview_unfavorite' : 'settings_multiview_favorite',
          id: 'favorite_portfolio',
          icon: this.favorite ? 'unfavorite' : 'star',
          gpTestId: 'gp_autotest_favorite_portfolio',
          iconType: 'regular',
          iconSize: 24,
          iconColor: '#191919',
        },
        {
          name: 'settings_multiview_delete',
          id: 'delete_portfolio',
          icon: 'delete',
          gpTestId: 'gp_autotest_delete_portfolio',
          iconType: 'regular',
          iconSize: 24,
          iconColor: '#DD3636',
          labelColor: '#DD3636'
        }
      ];
    }
  }
}
</script>

<style module="$" src="./less/card.less"></style>