/* eslint-disable */
import app from "./../../app";

import activeTab from "./../../models/activeTabs";
import suggest from "./../newProject/import/importsuggest";
import dateFormatHelper from "./../../helpers/dateFormats";
import customHelper from "./../../helpers/custom";
import constants from "./../../helpers/constants";
import pricingHelper from "../../helpers/pricingHelper";
import moment from "./../../libs/moment";
import _ from "./../../libs/lodash";
import "./../../libs/webix/custom/importXLSX";
import icon_close from "../../svg/close.svg";
import projectsModel from '../../models/projects'
import multiViewsProjects from '../../models/multiViewsProjects'
import Store from '../../store/main';
import {api} from '$$store/api';
const __ = window.__;

if (!webix.env.touch && webix.ui.scrollSize) {
  webix.CustomScroll.init();
}
const customColumnsFeatureKey = 'custom_fields';

var date_filter_columns = [];
var date_filter_count = 0;
var f_suggest = []; //suggest list with date formats
var durations_suggest; //suggest list with durations
var progress_suggest; //suggest list with durations
var innerObject = {
  settings: {
    fileType: "",
    fileName: "",
    projectType: "",
    fileData: [],
    importEnable: false,
    errorColumnId: [],
    errorRowIndex: {}
  }
};

const suggestTypes = [
  'start_date',
  'end_date',
  'duration',
  'progress'
]

const customColumnsTypes = [
  constants.CUSTOM_COLUMNS_TYPES.text.id,
  constants.CUSTOM_COLUMNS_TYPES.number.id,
  constants.CUSTOM_COLUMNS_TYPES.date.id
];

const defaultColumns = [
  {id: "not_used", value: __("import_not_used")},
  {id: "wbs", value: __("import_export_excel_wbs_number")},
  {id: "text", value: __("import_task_name")},
  {id: "start_date", value: __("import_gantt_grid_start_date")},
  {id: "startTime", value: __("import_start_time")},
  {id: "end_date", value: __("import_gantt_grid_end_date")},
  {id: "endTime", value: __("import_end_time")},
  {id: "duration", value: __("import_duration")},
  {id: "progress", value: __("import_export_excel_progress")},
  {id: "note", value: __("import_settings_description")},
  {id: "assigned", value: __("import_assigned_to")}
];

function getColumnTypes() {
  const isFeatureAvailable = pricingHelper.checkPricingAccess(customColumnsFeatureKey);
  let customColumns = [];

  if (isFeatureAvailable) {
    customColumns = getCustomColumnsTypes();
  }

  return [
    ...defaultColumns,
    ...customColumns
  ];
}

function trim() {
  var rows = [];

  $$("import:table").data.each(function (obj) {
    var empty = true;

    for (var i in obj) {
      if (i !== "id" && obj[i]) {
        empty = false;
        break;
      }
    }

    empty && rows.push(obj.id);
  });

  for (var i = 0; i <= rows.length; i++) {
    if (rows[i]) {
      $$("import:table").remove(rows[i]);
    }
  }
}

function validate_date(value, format) {
  if (!value) {
    return;
  }

  value = value.trim();

  const dateSplit = value.split('.');
  let yearError = false;

  dateSplit.forEach(i => {
    if (i.startsWith("0") && i.slice(1).length === 3) yearError = true;
  })

  if (!format || !moment(value, format, true).isValid() || yearError) {
    let formatError = format ? format : 'YYYY-MM-DD';

    return {code: 0, text: __('import_invalid_date_format', {date: value, format: formatError})}
  }

  return { code: 1, validate: moment(value, format) };
}

function manageSuggests(colId, newv, oldv, grid) {
  let used = [];
  let lower;
  const columnTypesList = getColumnTypes();
  const table = $$("import:table");
  const columns = grid.config.columns;
  const columnConfig = grid.getColumnConfig(colId);
  const tableData = grid.data.serialize();
  const dateSuggest = {
      content: "importTextFilter",
      suggest: f_suggest,
      placeholder: __("user_menu_dateformat").toLowerCase()
    };
  const durationSuggest = {content: "importSelectFilter", suggest: durations_suggest};
  const progressSuggest = {content: "importSelectFilter", suggest: progress_suggest};

  grid.config.customScrollState = grid.getScrollState();

  if (columnConfig.suggest) {
    columnConfig.suggest = null;
    columnConfig.editor = "text";
  }

  if (newv !== oldv || newv === 'not_used') {
    tableData.forEach((row, i) => {
      table.removeCellCss(row.id, colId, 'error_cell');
    })
  }

  if (suggestTypes.includes(newv) || isCustomDateColumn(newv)) {
    if (date_filter_columns.indexOf(colId) === -1) {
      date_filter_count++;
      date_filter_columns.push(colId);
    }

    _.each(columns, function (column) {
      if (column.id === colId) {
        if (column.import_id === 'duration') {
          lower = durationSuggest;
        } else if (column.import_id === 'progress') {
          lower = progressSuggest;
        } else {
          let suggestConfig = f_suggest.config;
          let suggestData = suggestConfig.data;

          let columnCells = column.node.children;
          let cache;

          let ln1 = suggestData.length > 50 ? 50 : suggestData.length;
          let ln2 = columnCells.length > 50 ? 50 : columnCells.length;



          for (var i = 0; i < ln1; ++i) {
            cache = suggestData[i];

            for (var j = 0; j < ln2; ++j) {
              let dateValidate = validate_date(columnCells[j].textContent, cache.value);

              if (dateValidate && dateValidate?.code === 1) {
                dateSuggest.value = cache.value;
              }
            }
          }

          lower = dateSuggest;
        }
      } else {
        lower = "";
      }

      if (!column.header[1] || !column.header[1].text) {
        column.header[1] = lower;
      } else if (column.import_id === newv) {
        column.header[1] = lower;
      }
    });
  } else if (oldv && (suggestTypes.includes(oldv) || isCustomDateColumn(oldv))) {
    date_filter_count--;
    date_filter_columns.splice(date_filter_columns.indexOf(colId), 1);

    _.each(columns, function (column) {
      if (date_filter_count === 0) {
        column.header.pop();
      } else if (column.id === colId) {
        column.header[1] = "";
      }
    });
  }

  grid.refreshColumns(setCurrentDateFormat(columns));

  used = _.reduce(columns, function (result, value) {
    if (value.import_id && value.import_id !== 'not_used') {
      result.push(value.import_id);
    }

    return result;
  }, []);

  table.eachColumn(function (colId, column) {
    if (colId === "-") {
      return;
    }

    const currentValue = column.import_id;
    const suggestList = $$(column.header[0].richselect).getList();

    const newTypesList = columnTypesList.filter(function (type) {
      return (used.indexOf(type.id) === -1 || type.id === currentValue);
    });

    suggestList.clearAll();
    suggestList.parse(newTypesList);
  });
}

function transformToTree(arr) {
  var nodes = {};
  var wbsKeys = _.map(arr, function (item) {
    return item.wbs;
  });

  arr.forEach(function (obj) {
    var id = obj.wbs;
    var parentId = _.includes(wbsKeys, obj.parentId) ? obj.parentId : null;

    nodes[id] = {...obj, ...nodes[id], children: []};
    // nodes[parentId] = nodes[parentId] || {children: []};
    parentId && nodes[parentId]["children"].push(nodes[id]);
  });

  return Object.values(nodes).filter(function (obj) {
    return obj.parentId === 0 || !obj.parentId;
  });
}

function getDurationValue(value) {
  var durationType = $$('suggest: duration').getValue(),
    durationValue;

  switch (durationType) {
    case "day":
      durationValue = 8 * value;
      break;
    case "week":
      durationValue = 8 * 5 * value;
      break;
    case "month":
      durationValue = 8 * 5 * 4 * value;
      break;
    default:
      durationValue = value;
      break;
  }

  return Math.ceil(durationValue * 60);
}

function preImport() {
  const table = $$('import:table');
  const columns = table.config.columns;
  const tableData = table.data.serialize();
  let is_wbs;
  let is_duration;
  let is_startDate;
  let is_endDate;
  let preImportData = [];
  let dataObject;
  let cellValue;
  let dataRow;
  let rowError = false;
  let progressType = $$('suggest: progress').getValue();

  innerObject.settings.errorColumnId = [];

  tableData.forEach(function (row) {
    table.removeRowCss(row.id, "webix_invalid");
  });

  tableData.forEach(function (row, i) {
    dataRow = {};
    let textColumnId = columns.some(item => item.import_id === 'text');

    if (!textColumnId) {
      webix.message({type: "info", text: __("import_task_name_empty")}); //don't import
      rowError = true;
    }

    columns.forEach(function (column) {
      cellValue = row[column.id] ? ("" + row[column.id]).trim() : null;

      switch (column.import_id) {
        case 'wbs':
          if (!cellValue) {
            webix.message({type: "info", text: __("import_wbs_empty")}); //don't import
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          } else if (!/^([0-9]+(([.]?([0-9]+))+)?)$/.test(cellValue)) {
            webix.message({type: "warning", text: __("import_wbs_invalid")}); //don't import
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          } else {
            dataRow[column.import_id] = cellValue;
          }

          break;
        case 'text':
          if (!cellValue) {
            webix.message({type: "info", text: __("import_task_name_empty")}); //don't import
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          } else {
            dataRow[column.import_id] = cellValue;
          }
          break;
        case 'start_date':
          if (!cellValue) {
            webix.message({type: "info", text: __("import_start_date_empty")}); //don't import
            innerObject.settings.errorColumnId.push(column);

            rowError = true;
          } else {
            const dateValidate = validate_date(cellValue, column.date_format);

            if (dateValidate && dateValidate?.code === 0) {
              webix.message({type: "warning", text: dateValidate.text, expire: 10000});
              innerObject.settings.errorColumnId.push(column);
              rowError = true;
            } else {
              dataRow[column.import_id] = dateValidate?.validate.toDate();
            }
          }
          break;
        case 'end_date':
          if (!cellValue) {
            webix.message({type: "info", text: __("import_end_date_empty")}); //don't import
            innerObject.settings.errorColumnId.push(column);

            rowError = true;
          } else {
            const dateValidate = validate_date(cellValue, column.date_format);

            if (dateValidate && dateValidate?.code === 0) {
              webix.message({type: "warning", text: dateValidate.text, expire: 10000});
              innerObject.settings.errorColumnId.push(column);
              rowError = true;
            } else {
              dataRow[column.import_id] = dateValidate?.validate.toDate();
            }
          }

          break;
        case 'duration':
          dataRow[column.import_id] = 0;
          if (!cellValue) {
            webix.message({type: "info", text: __("import_duration_empty")}); //don't import
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          }

          if (cellValue) {
            cellValue = cellValue.replace(/,/g, ".");
          } else {
            cellValue = '';
          }

          if (!parseFloat(cellValue)) {
            cellValue ? webix.message({type: "warning", text: __("import_duration_invalid", {value: cellValue})}) : ''; //don't import
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          } else {
            dataRow[column.import_id] = getDurationValue(parseFloat(cellValue));
          }

          break;
        case 'progress':
          cellValue = !!cellValue ? cellValue.replace(/,/g, ".") : 0;
          cellValue = parseFloat(cellValue) / (!!progressType ? progressType.substr(1) : 100);
          dataRow[column.import_id] = cellValue > 1 ? 1 : cellValue;
          break;
        case 'startTime':
          if (dateFormatHelper.isValidStringTime(cellValue)) {
            dataRow[column.import_id] = cellValue;
          } else {
            webix.message({type: "warning", text: __("import_start_time_invalid")}); //don't import
            rowError = true;
          }
          break;
        case 'endTime':
          if (dateFormatHelper.isValidStringTime(cellValue)) {
            dataRow[column.import_id] = cellValue;
          } else {
            webix.message({type: "warning", text: __("import_end_time_invalid")}); //don't import
            rowError = true;
          }
          break;
        case 'note':
          dataRow[column.import_id] = _.escape(cellValue);
          break;
        case 'assigned':
          dataRow[column.import_id] = cellValue || '';
          break;
        default:
          break;
      }

      if (isCustomTextColumn(column.import_id)) {
        dataRow.customColumns = {
          ...dataRow.customColumns,
          [column.import_id]: {
            id: column.import_id,
            type: constants.CUSTOM_COLUMNS_TYPES.text.id,
            value: cellValue || null
          }
        };
      } else if (isCustomNumberColumn(column.import_id)) {
        dataRow.customColumns = {
          ...dataRow.customColumns,
          [column.import_id]: {
            id: column.import_id,
            type: constants.CUSTOM_COLUMNS_TYPES.number.id,
            value: null
          }
        };

        if (cellValue) {
          const num = parseFloat(cellValue);

          if (num) {
            dataRow.customColumns[column.import_id].value = num;
          } else {
            webix.message({type: "info", text: __("import_number_invalid")});
            innerObject.settings.errorColumnId.push(column);
            rowError = true;
          }
        }
      } else if (isCustomDateColumn(column.import_id)) {
        const dateValidate = validate_date(cellValue, column.date_format);

        if (dateValidate && dateValidate?.code === 0) {
          webix.message({type: "warning", text: date_error.text, expire: 10000});
          innerObject.settings.errorColumnId.push(column);
          rowError = true;
        } else {
          dataRow.customColumns = {
            ...dataRow.customColumns,
            [column.import_id]: {
              id: column.import_id,
              type: constants.CUSTOM_COLUMNS_TYPES.date.id,
              value: dateValidate ? dateValidate?.validate.toDate() : null
            }
          };
        }
      }
    });

    if (rowError) {
      table.addRowCss(row.id, "webix_invalid");

      columns.forEach(column => {
        table.removeCellCss(row.id, column.id, 'error_cell');
      });

      innerObject.settings.errorColumnId.forEach(function (column) {
        innerObject.settings.errorRowIndex = table.getIndexById(row.id);

        table.addCellCss(row.id, column.id, 'error_cell');
      });

      let getVisibleRows = Math.floor(table.getVisibleCount()/2);

      if (+innerObject.settings.errorRowIndex) {
        if (table.getItem(table.getIdByIndex(innerObject.settings.errorRowIndex + getVisibleRows))) {
          table.showItemByIndex(innerObject.settings.errorRowIndex + getVisibleRows);
        } else {
          table.showItemByIndex(innerObject.settings.errorRowIndex);
        }
      }

      tableData.splice(i, tableData.length - i);
    } else {
      let defaultStartTime = '9';

      if (dataRow['startTime']) {
        dataRow['start_date'] = dateFormatHelper.setStringTimeToDateObject(dataRow['start_date'], dataRow['startTime']);
      } else if (dataRow['start_date'] && dataRow['start_date'].getHours() === 0) {
        dataRow['start_date'] = dateFormatHelper.setStringTimeToDateObject(dataRow['start_date'], defaultStartTime);
      }
      if (dataRow['endTime']) {
        dataRow['end_date'] = dateFormatHelper.setStringTimeToDateObject(dataRow['end_date'], dataRow['endTime']);
      } else if (dataRow['end_date'] && dataRow['end_date'].getHours() === 0) {
        dataRow['end_date'] = dateFormatHelper.setStringTimeToDateObject(dataRow['end_date'], '18');
      }
      preImportData.push(dataRow);
    }
  });

  if (rowError) {
    return false;
  }

  is_wbs = _.findKey(columns, ['import_id', 'wbs']);
  is_duration = _.findKey(columns, ['import_id', 'duration']);
  is_startDate = _.findKey(columns, ['import_id', 'start_date']);
  is_endDate = _.findKey(columns, ['import_id', 'end_date']);

  if (is_duration && is_endDate && !is_startDate) {
    _.map(preImportData, function (row) {
      let endDate = new Date(row.end_date);
      let durationHours = row.duration;

      row.start_date = moment(gantt.calculateEndDate(endDate, -durationHours)).toDate();
    });
  }

  if (is_duration && is_startDate && !is_endDate) {
    _.map(preImportData, function (row) {
      let statDate = new Date(row.start_date);
      let durationHours = row.duration;

      row.end_date = moment(gantt.calculateEndDate(statDate, durationHours)).toDate();
    });
  }

  if (is_duration && !is_startDate && !is_endDate) {
    _.map(preImportData, function (row) {
      let defaultStartTime = moment().hours(9).startOf('hour');
      let durationHours = row.duration;

      row.start_date = defaultStartTime;
      row.end_date = moment(gantt.calculateEndDate(new Date(row.start_date), durationHours)).toDate();
    });
  }

  if (!is_startDate) {
    _.map(preImportData, function (row) {
      if (!row.start_date) {
        let formatDate = is_endDate ? is_endDate.date_format : '';
        let defaultStartTime = moment.utc().hours(9).startOf('hour');

        row.start_date = defaultStartTime.format(`${formatDate ? formatDate : 'YYYY/MM/DD HH:mm'}`);
      }
    });
  }

  if (!is_endDate) {
    _.map(preImportData, function (row) {
      if (!row.end_date) {
        let formatDate = is_startDate ? is_startDate.date_format : '';
        let defaultDuration = row.duration ? row.duration : '480';

        row.end_date = moment(gantt.calculateEndDate(new Date(row.start_date), defaultDuration)).format(`${formatDate ? formatDate : 'YYYY/MM/DD HH:mm'}`);
      }
    });
  }

  if (is_wbs) {
    _.map(preImportData, function (row) {
      var wbsValue = row.wbs,
        wbsArray = _.split(wbsValue, '.');

      if (wbsArray.length < 2) {
        row.parentId = 0;
      } else {
        row.parentId = _.dropRight(wbsArray).join('.');
      }
    });

    preImportData = _.sortBy(preImportData, function (r) {
      return _.split(r.wbs, '.').length;
    });
  }

  if (!is_duration) {
    _.map(preImportData, function (row) {
      if (row.start_date.valueOf() == row.end_date.valueOf()) {
        row.end_date = gantt.date.add(row.start_date, 1, "day");
      }

      row.duration = gantt.calculateDuration(new Date(row.start_date), new Date(row.end_date)) || 1;
    });
  }

  preImportData.forEach(row => {
    row.start_date = moment(row.start_date).format('YYYY-MM-DD HH:mm');
    row.end_date = moment(row.end_date).format('YYYY-MM-DD HH:mm');

    _.forEach(row.customColumns, column => {
      const isDateColumn = isCustomDateColumn(column.id);

      if (isDateColumn && column.value) {
        column.value = moment(column.value).format('YYYY-MM-DD HH:mm');
      }
    });
  });

  _.map(preImportData, function (row) {
    row.estimation = row.assigned ? row.duration / 60 : 0;
    if (row.assigned) {
      row.resource = [row.assigned];
    }
  });

  if (is_wbs) {
    preImportData = transformToTree(preImportData);
  }

  dataObject = {
    projectData: {
      importType: innerObject.settings.fileType,
      name: innerObject.settings.fileName,
      durationType: $$('suggest: duration').getValue(),
      projectType: innerObject.settings.projectType
    },
    tasksData: preImportData,
    wbs: is_wbs
  };

  // app.trigger("gantt:progress:show", 'import');
  importData(dataObject);
  GT.importManual = false;
}
// todo: remove after deploy new templates
function endImport(data) {
  if (data) {
    activeTab.saveActiveGanttId(data.gantt_id);
  }
  // webix.ajax().post("/api/pipedrive", {projects_created: '+1'});
  let projects = projectsModel.getAllProjects().length
  let multiviews = multiViewsProjects.getAllMultiviews().length;
  webix.ajax().post("/api/pipedrive", {
    projects_created: multiviews + projects,
    is_first_task_created: 1,
  });

  window.location.assign("/");
}

function importData(data) {
   api.post('/projects', {
     data: data,
     status: 'import',
     importType : data.projectData.importType,
     name: innerObject.settings.fileName,
   })
    .then(function (success) {
      userExtAnalytics.log('project_create_done', {
        type: 'import',
        'import format': innerObject.settings.fileType,
        'template name': data.projectData.name,
      });
    });
}

function initTable(scope) {
  const columnTypesList = getColumnTypes();
  const table = $$("import:table");
  const columns = table.config.columns;
  let columnsLength = 10;
  let newSuggest;
  let i;

  webix.extend(table, webix.ProgressBar);
  table.showProgress({
    type: "icon"
  });
  if (typeof innerObject.settings.fileData == "string") {
    let temp = webix.DataDriver.csv.cell;
    let numOfColon = (innerObject.settings.fileData.match(/,/g) || []).length;
    let numOfSemiColon = (innerObject.settings.fileData.match(/;/g) || []).length;
    webix.DataDriver.csv.cell = (numOfSemiColon > numOfColon) ?";":",";
    webix.DataDriver.csv.row = "\n";
    table.parse(innerObject.settings.fileData, 'csv');
    webix.DataDriver.csv.cell = temp;
  } else {
    if (!innerObject.settings.fileData[0]) {
      return;
    }

    columnsLength = innerObject.settings.fileData[0].length > columnsLength ? innerObject.settings.fileData[0].length : columnsLength;

    for (i = 11; i < columnsLength; i++) {
      newSuggest = suggest.$i_suggest;

      newSuggest.id = "c" + webix.uid();

      columns.push({
        id: 'data' + i,
        header: [{content: 'importSelect', suggest: scope.ui(webix.copy(newSuggest))}],
        editor: 'text'
      });

    }

    table.refreshColumns(columns);
    table.parse(innerObject.settings.fileData, 'jsarray');
  }

  table.eachColumn(function (colId) {
    if (colId === "-") {
      return;
    }

    table.getFilter(colId).getPopup().getList().parse(columnTypesList);
    table.getFilter(colId).setValue("not_used");
  });
}

function initSuggests(scope) {
  f_suggest = scope.ui(suggest.$f_suggest);

  durations_suggest = scope.ui({
    view: "suggest",
    id: "suggest: duration",
    css: 'import-suggest',
    padding: 0,
    autoheight: true,
    value: 'hour',
    data: [
      {id: 'hour', value: __("settings_hours"), default: true},
      {id: 'day', value: __("settings_days")},
      {id: 'week', value: __("settings_weeks")},
      {id: 'month', value: __("settings_months")}
    ]
  });

  progress_suggest = scope.ui({
    view: "suggest",
    id: "suggest: progress",
    css: 'import-suggest',
    padding: 0,
    autoheight: true,
    value: '_100',
    data: [
      {id: '_1', value: "0..1", default: true},
      {id: '_100', value: "0..100%"}
    ]
  });
}

function getCellTemplate(obj, config, value) {
  if (value && _.isString(value)) {
    return customHelper.formatTaskName(value);
  }
  return value;
}

function getColumnsConfig() {
  return [
    {id: '-', template: "<span class='fa fa-times import_remove_item'></span>", header: '', width: 35},
    {id: 'data0', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data1', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data2', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data3', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data4', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data5', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data6', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data7', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data8', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data9', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data10', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data11', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data12', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data13', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data14', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
    {id: 'data15', header: [{content: 'importSelect', suggest: suggest.$i_suggest}], editor: 'text', template: getCellTemplate},
  ];
}

function getCustomColumnsTypes() {
  const customColumns = Store.getters['columns/getAllCustomColumns'];
  const filteredColumns = customColumns.filter(column => {
    return customColumnsTypes.includes(column.type);
  }).map(column => {
    return {
      id: `${column.id}`,
      value: column.name,
      type: column.type
    }
  })
  return _.sortBy(filteredColumns, (col) => col.value.toLowerCase());
}

function isCustomTextColumn(columnId) {
  const column = Store.getters['columns/getCustomColumnById'](columnId);
  return column && column.type === constants.CUSTOM_COLUMNS_TYPES.text.id;
}

function isCustomNumberColumn(columnId) {
  const column = Store.getters['columns/getCustomColumnById'](columnId);
  return column && column.type === constants.CUSTOM_COLUMNS_TYPES.number.id;
}

function isCustomDateColumn(columnId) {
  const column = Store.getters['columns/getCustomColumnById'](columnId);
  return column && column.type === constants.CUSTOM_COLUMNS_TYPES.date.id;
}

function setCurrentDateFormat(columns) {
  return columns.map(column => {
    if (column.date_format) {
      if (column.header[1]) {
        column.header[1].value = column.date_format;
      }
    }

    return column;
  });
}

var table = {
  view: 'datatable',
  id: 'import:table',
  css: 'import-viewer',
  editable: true,
  borderless: true,
  columnWidth: 140,
  minWidth: 1300,
  resizeColumn: true,
  resizeRow: true,
  headerRowHeight: 50,
  columns: getColumnsConfig(),
  on: {
    onItemClick: function (id, e, node) {
      if (id.column === "-") {
        this.blockEvent();
        var self = this;

        _.delay(function () {
          self.remove(id.row);
        });

        this.unblockEvent();
      }
    },
    onImportSelectChange: function (colId, newv, oldv) {
      var scrollState = this.getScrollState();

      manageSuggests(colId, newv, oldv, this);

      this.scrollTo(scrollState.x - 1, scrollState.y - 1);
    },
    onColumnResize: function (colId) {
      this.refreshColumns();
    },
    onAfterDelete: function () {
      if (!this.count()) {
        endImport();
      }
    },
    onDestruct: function () {
      //destroy custom filters
      if (this.destroy_with_me) {
        this.destroy_with_me.forEach(function (item) {
          item.destructor();
        });
      }
    },
    onAfterLoad: function () {
      trim();
      this.hideProgress();
    },
    onEditorChange: function (id, value) {
      let thisCell = innerObject.settings.errorColumnId.find(column => column.id === id.column);
      let rowId = id.row;
      let colId = id.column;
      let tableImport = $$('import:table');

      if (thisCell) {
        let indexErrorColumn = innerObject.settings.errorColumnId.findIndex(column => column.id === id.column);

        innerObject.settings.errorColumnId.slice(indexErrorColumn);
        tableImport.removeCellCss(rowId, colId, 'error_cell');
      }
    },
  }
};

var SAVE_BUTTON = {
  view: "button",
  css: "webix_button_default button_raised import-viewer-footer-save",
  label: __("import_start_import"),
  width: 200,
  click: preImport
};

var CANCEL_BUTTON = {
  view: "button",
  value: __("common_cancel"),
  width: 200,
  css: "import-viewer-footer-cancel button_default",
  click: function () {
    endImport();
  }
};

var ui = {
  margin: 10,
  padding: 10,
  view: "window",
  hidden: false,
  css: 'import_view',
  position: function (state) {
    state.top = 0;
    state.left = 0;
    state.height = document.body.clientHeight + 2;
    state.width = document.body.clientWidth;
    this.config.height = state.height;
  },
  modal: false,
  move: false,
  head: {
    css: 'importHeader',
    height: 158,
    rows: [
      {
        id: "importHeaderId",
        css: "import-header",
        height: 60,
        borderless: true,
        cols: [
          {
            view: 'template',
            borderless: true,
            id: 'importHeaderTitle',
            css: 'import-header-title',
            template: () => {
              return `<div class="title_text">${__('import_header_title')}</div>`
            }
          },
          {
            view: 'helpIcon',
            id: 'importProjectBlockView',
            dataId: 'importProjectBlock',
            css: 'help_icon_block title_help import_help',
            width: 60,
            height: 60,
            zIndex: 1
          },
          {
            width: 60,
            height: 60,
            view: "template",
            borderless: true,
            template: function () {
              return `<div class="title_closer">${icon_close}</div>`
            },
            onClick: {
              'title_closer': () => {
                endImport();
              }
            },
          }
        ]
      },
      {
        css: 'header-desc',
        view: 'template',
        minHeight: 100,
        borderless: true,
        template: () => {
          return `<div class="header-description">
                      <div class="description_container">
                          ${__('import_header_description')}
                      </div>
                  </div>`;
        }
      }
    ]
  },
  body: {
    rows: [
      {
        view: "scrollview",
        scroll: "xy",
        autoheight: true,
        body: {
          rows: [
            {
              cols: [
                {},
                table,
                {}
              ]
            }
          ]
        }
      },
      {
        height: 12,
      },
      {
        css: "import-viewer-footer",
        cols: [
          {},
          CANCEL_BUTTON,
          {width: 48},
          SAVE_BUTTON,
          {}
        ]
      },
      {
        height: 12,
      },
    ]
  }
};

app.socket.on('import:progress:excel', data => {
  if (data.finished) endImport(data.data);
});

export default {
  $oninit: function (scope) {
    if (!innerObject.settings.importEnable) {
      endImport();
    }

    initSuggests(scope);
    initTable(scope);

    innerObject.settings.fileData = null;
    innerObject.settings.firstSheet = null;
  },
  $ui: ui,
  handlers: {
    setFileName: function (fileName) {
      innerObject.settings.fileName = fileName.trim() !== '' ? fileName : __('default_template');
    },
    setFileData: function (fileData, fileType, fileName) {
      innerObject.settings.fileData = fileData;
      innerObject.settings.fileType = fileType;
      innerObject.settings.fileName = fileName.trim() !== '' ? fileName : __('default_template');
      innerObject.settings.importEnable = true;
      innerObject.settings.firstSheet = true;
      GT.importManual = true;
    },
    setProjectType: function (projectType) {
      innerObject.settings.projectType = projectType;
    },
  }
};
