var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.add_project_wrapper },
    [
      _c("vgp-button", {
        class: _vm.$style.new_button,
        attrs: {
          label: _vm.locale("add_project"),
          icon: { name: "add-project", size: 20, type: _vm.regular },
          iconSide: "left",
          type: "primary",
          xSmall: "",
        },
        on: { onClick: _vm.togglePopup },
      }),
      _vm._v(" "),
      _c("core-popup", {
        attrs: {
          show: _vm.showPopup,
          title: _vm.locale("add_project_to_portfolio"),
          closeIcon: true,
          buttonsSwitch: true,
          okButtonTitle: _vm.locale("common_add"),
          cancelButtonTitle: _vm.locale("common_cancel"),
          disabledCancel: !_vm.selectedProjects.length,
          bodyStyle: "addProjectToPortfolio",
          footerStyle:
            _vm.overflowedList && _vm.filteredProjects.length
              ? "flex-end-with-shadow"
              : "flex-end",
        },
        on: {
          onClickOk: _vm.addProjectsToPortfolio,
          onClickCancel: _vm.togglePopup,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { class: _vm.$style.popup_content }, [
                  _c(
                    "div",
                    { class: _vm.$style.search_wrapper },
                    [
                      _c("vgp-input", {
                        class: "search-input",
                        style: { outline: "#fff" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.locale(
                            "search_projects_placeholder"
                          ),
                          "icons-left": [{ name: "search" }],
                          small: true,
                          "select-by-focus": false,
                        },
                        model: {
                          value: _vm.searchStr,
                          callback: function ($$v) {
                            _vm.searchStr = $$v
                          },
                          expression: "searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { ref: "projectsWrapper", class: _vm.$style.projects },
                    [
                      _vm._l(_vm.filteredProjects, function (project) {
                        return _c(
                          "div",
                          {
                            class: _vm.$style.item,
                            on: {
                              click: function ($event) {
                                project.selected = !project.selected
                              },
                            },
                          },
                          [
                            _c("vgp-checkbox", {
                              attrs: {
                                size: 16,
                                label: project.name,
                                "label-position": "right",
                                checked: project.selected,
                              },
                              on: {
                                onClick: function ($event) {
                                  project.selected = !project.selected
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      !_vm.filteredProjects.length &&
                      _vm.availableProjects.length
                        ? _c(
                            "div",
                            { class: [_vm.$style.item, _vm.$style.no_items] },
                            [
                              _vm.overflowedList
                                ? _c("svg-image", {
                                    attrs: { name: "comment-hub-no-search" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: _vm.overflowedList
                                    ? _vm.$style.small_text
                                    : _vm.$style.medium_text,
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.locale("no_matches_message_text")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.availableProjects.length
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.$style.item,
                                _vm.$style.no_items,
                                _vm.$style.all_added,
                              ],
                            },
                            [
                              _c("svg-image", {
                                style: { width: "130px", height: "76px" },
                                attrs: { name: "comment-hub-no-tasks" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: _vm.overflowedList
                                    ? _vm.$style.small_text
                                    : _vm.$style.medium_text,
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.locale(
                                          "portfolio_all_projects_added"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }