export default {
  setActivities(state, payload) {
    state.activities = payload;
  },
  updateActivity(state, activity) {
    const { name, ...data } = activity;
    const activityIndex = state.activities.findIndex(item => item.name === name);

    if (activityIndex !== -1) {
      Object.assign(state.activities[activityIndex], data);
    } else {
      state.activities.push(activity);
    }
  },
  setIsActivitiesLoaded(state, value) {
    state.isActivitiesLoaded = value;
  },
};
