import store from '../../../store/main';

function onProgressEventUpdated(event) {
  const userIds = event.data.userIds;
  const userId = event.data.userId;

  console.log('====== update', event);
  if (userIds.find(id => id === user.id) || userId === user.id) {
    const eventsFromMap = store.getters['progress/eventsMap'];
    const eventInMap = eventsFromMap[event.data.token];
    const eventName = eventInMap && eventInMap.split('_')[0];
    const eventProgress = eventInMap && eventInMap.split('_')[1];

    if (eventInMap && eventName === 'ProgressEventFinished' || (eventName === 'ProgressEventUpdated' && +eventProgress > event.data.progress)) {
      return;
    }

    store.commit('progress/setEventToMap', { token: event.data.token, event: event.event, progress: event.data.progress });
    store.commit('progress/update', event.data);
  }
}

export default onProgressEventUpdated;
