'use strict';

import constants from './constants';

const outputHandlers = {

  importFromGTtoConstants: () => {
    constants.COLORS = {};
    constants.DARKEN_COLORS = {};
    constants.LIGHT_COLORS = {};
    constants.TITLE_COLORS = {};
    GT.constants && GT.constants.colors.forEach(item => {
      constants.COLORS[item.id] = item.hex;
      constants.DARKEN_COLORS[item.id] = item.hex2;
      constants.LIGHT_COLORS[item.id] = item.hex3;
      constants.TITLE_COLORS[item.id] = item.hex4;
    });
    
    let colors = GT.constants.colors.map( color => {
      return { id: color.id, color: color.hex} 
    })
    
    let palette = [];
    palette.push( colors.splice(0, 6) )
    palette.push( colors.splice(0, 6) )
    palette.push( colors.splice(0, 6) )
    palette.push( colors.splice(0, 6) )

    constants.PALETTE_CONFIG = { palette }

    delete GT.constants;
  },

  getColorFromPaletteConfig: () => {
    let constantColor = constants.PALETTE_CONFIG.palette;

    constantColor = constantColor.reduce((a, b) => a.concat(b), []);
    return constantColor;
  },

  getColorId: (color) => {
    if (!color) return;

    let constantColor = outputHandlers.getColorFromPaletteConfig();
    let currentColor = constantColor.find(colorItem => colorItem.color === color);

    return currentColor.id;
  },

  getColorStr: (colorId) => {
    if (!colorId) return;

    let constantColor = outputHandlers.getColorFromPaletteConfig();
    let currentColor = constantColor.find(colorItem => colorItem.id === +colorId);

    return currentColor.color;
  }
};



export default outputHandlers;