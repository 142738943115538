<template>
  <div :class="[$style.portfolios_page, (!portfolios.length || !accessToPortfolios) && $style.no_portfolios]">
    <div :class="$style.head_section">
      <div
        v-if="!portfolios.length || !accessToPortfolios"
        :class="$style.welcome_title"
      >
        {{ locale('portfolio_page_welcome_title') }}
        <div
          v-if="!accessToPortfolios"
          class="pricing-title-container"
        >
          {{ locale(planTitle) }}
        </div>
      </div>
      <div :class="$style.description">
        {{ !portfolios.length ? locale('portfolio_page_welcome_description') : locale('portfolio_page_description') }}
        <div
          v-if="!accessToPortfolios"
          class="learn_more"
        >
          <a
            class="pricing-popup-link"
            :href="locale('portfolio_view_learn_more')"
            target="_blank"
            @click="linkClick"
          >{{ locale('help_read_more') }}</a>
          <svg-sprite
            :type="'regular'"
            :name="'arrow-right-2'"
            :size="16"
            :color="'#1565C0'"
          />
        </div>
      </div>
      <div
        v-if="!portfolios.length && accessToPortfolios"
        :class="$style.create_button"
      >
        <vgp-button
          :class="$style.new_button"
          :label="locale('portfolio_page_open_portfolio')"
          type="primary"
          small
          @onClick="newPortfolioButtonHandler"
        />
      </div>
      <div
        v-if="!accessToPortfolios && isOwner"
        :class="$style.upgrade_button"
      >
        <upgrade-btn
          v-if="isOwner"
          :feature="'portfolio_view'"
        />
      </div>
    </div>

    <div
      v-if="!portfolios.length || !accessToPortfolios"
      :class="$style.demo_video"
    >
      <video-component
        :poster-url="'https://cdn.ganttpro.com/statics/media/images/portfolio/demo_poster.png'"
        :video-url="'https://cdn.ganttpro.com/statics/media/videos/portfolio/demo.mp4'"
      />
    </div>

    <div
      v-if="portfolios.length && accessToPortfolios"
      :class="$style.portfolios_list"
    >
      <card
        :type="'button'"
        :item="{ name: locale('portfolio_page_new_button_label') }"
        @itemClick="newPortfolioButtonHandler"
      />
      <card
        v-for="pf in portfolios"
        :key="pf.id"
        :type="'portfolio'"
        :item="pf"
        :metadata="[getLastUpdateDate(pf), getProjectsCount(pf)]"
        @toggleFavorite="toggleFavorite"
        @updateName="updateName"
        @itemClick="() => goToPortfolio(pf.id)"
        @favClick="() => toggleFavorite(pf.id)"
        @startDelete="() => onDelete(pf)"
      />
    </div>

    <core-popup
      :show="showCreate"
      :title="locale('portfolio_page_new_project_portfolio')"
      :close-icon="true"
      :buttons-switch="true"
      :ok-button-title="locale('common_create')"
      :cancel-button-title="locale('common_cancel')"
      :disabled-cancel="!newPortfolioName || !newPortfolioProjects.length"
      :body-style="'portfolio_modal'"
      :footer-style="'flex-end'"
      @onClickOk="createPortfolio"
      @onClickCancel="toggleCreateModal"
    >
      <template #body>
        <vgp-label-slot
          :label="locale('portfolio_page_name_of_portfolio')"
        >
          <template #body>
            <vgp-input
              :placeholder="locale('portfolio_page_enter_portfolio_name')"
              :value="newPortfolioName"
              small
              :initial-focus="true"
              @input="setNewPortfolioName"
            />
          </template>
        </vgp-label-slot>
        <vgp-label-slot
          :label="locale('common_project_tab')"
        >
          <template #body>
            <!-- :useSelectAll is "false" until further performance improvements (big load time on portfolio creation) -->
            <dropdown-select
              :drop-list="allProjects"
              :selected="newPortfolioProjects"
              :use-select-all="false"
              :use-search="true"
              :placeholder="locale('create_resource_projects_drop_placeholder')"
              :popup-max-height="217"
              @change="projectsSelected"
            />
          </template>
        </vgp-label-slot>
      </template>
    </core-popup>

    <confirm-popup
      :show="deletePortfolioObj"
      :popup-width="450"
      :close-icon="true"
      :title="locale('project_list_delete_multiview')"
      :text="deleteConfirmMessage"
      :confirm-message-class="'multiline'"
      :cancel-button="{
        title: locale('common_cancel'),
        type: 'secondary'
      }"
      :ok-button="{
        title: locale('common_delete'),
        type: 'destructive'
      }"
      @onClickCancel="closeModal"
      @onClickOk="deletePortfolio()"
    />
  </div>
</template>

<script>
import multiViewsProjects from '../../models/multiViewsProjects';
import projects from '../../models/projects';
import SvgSprite from '../common/VueComponents/VueIcon/svgSprite.vue';
import ConfirmPopup from '../common/VueComponents/popups/coreConfirmPopup/coreConfirmPopup.vue';
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import vgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import vgpInput from '../common/VueComponents/vInput/vgpInput.vue';
import dropdownSelect from '../common/VueComponents/dropdownSelect/dropdownSelect.vue';
import upgradeBtn from '../common/VueComponents/ugradeButton/upgradeButton.vue';
import moment from '../../libs/moment';
import routerHelper from '../../helpers/router';
import Card from '../common/VueComponents/card/Card.vue';
import pricingHelper from '../../helpers/pricingHelper';
import rights from '../../components/rights/index';
import app from '../../app';

export default {
  name: 'PortfolioPage',
  components: {
    SvgSprite, ConfirmPopup, CorePopup, vgpLabelSlot, vgpInput, dropdownSelect, Card, upgradeBtn,
  },
  data() {
    return {
      locale: __,
      portfolios: [],
      nameEditingId: null,
      deletePortfolioObj: null,
      deleteConfirmMessage: __('settings_msg_delete_multiview_confirm_text'),
      showCreate: false,
      newPortfolioName: '',
      newPortfolioProjects: [],
      listenersMultiview: [],
      listenersProject: [],
      listenersApp: [],
      editCancel: false,
      allProjects: [],
    };
  },
  computed: {
    accessToPortfolios() {
      return pricingHelper.checkPricingAccess('portfolio_view');
    },
    planTitle() {
      return `${pricingHelper.getPlans('portfolio_view')}_plan_locale`;
    },
    isOwner() {
      return rights.account.isOwner();
    },
  },
  watch: {
    '$route.query.createNew': {
      handler(newVal) {
        if (newVal) {
          if (this.portfolios.length || this.allProjects.length > 5) {
            this.newPortfolioButtonHandler();
          }

          this.$router.replace({ query: null });
        }
      },
      deep: true,
    },
  },
  created() {
    this.portfolios = multiViewsProjects.getAllMultiviews();
    this.allProjects = projects.getAllProjects().filter(project => !project.is_jira);

    const listenerMultiview_1 = multiViewsProjects.data.attachEvent('removeMultiview', id => {
      this.portfolios = multiViewsProjects.getAllMultiviews();
    });

    const listenerMultiview_2 = multiViewsProjects.data.attachEvent('onStoreUpdated', () => {
      this.portfolios = multiViewsProjects.getAllMultiviews();
    });

    const listenerProject_1 = projects.data.attachEvent('projectListRefreshed', () => {
      this.projectListRefreshedHandler();
    });

    const listenerApp_1 = app.on('sideBar:toggleFavorite', ({ id, newState }) => {
      this.portfolios = multiViewsProjects.getAllMultiviews().map(portfolio => {
        if (portfolio.id === id) {
          return { ...portfolio, is_favorite: newState };
        }

        return portfolio;
      });
    });

    const listenerApp_2 = app.on('portfolio:ganttIDs:remove', () => {
      this.portfolios = multiViewsProjects.getAllMultiviews();
    });

    const listenerApp_3 = app.on('project:archive', () => {
      this.projectListRefreshedHandler();
    });

    this.listenersMultiview = [
      listenerMultiview_1,
      listenerMultiview_2,
    ];

    this.listenersProject = [
      listenerProject_1,
    ];

    this.listenersApp = [
      listenerApp_1,
      listenerApp_2,
      listenerApp_3,
    ];
  },
  mounted() {
    if (this.$route.query.createNew) {
      if (this.portfolios.length || this.allProjects.length > 5) {
        this.newPortfolioButtonHandler();
      }

      this.$router.replace({ query: null });
    }
  },
  beforeDestroy() {
    this.listenersMultiview.forEach(id => multiViewsProjects.data.detachEvent(`${id}`));
    this.listenersProject.forEach(id => projects.data.detachEvent(`${id}`));
    this.listenersApp.forEach(id => app.off(id));
  },
  methods: {
    getLastUpdateDate(project) {
      return `${this.locale('project_last_change')}: ${moment(project.last_update).format(user.dateFormat)}`;
    },
    getProjectsCount(project) {
      return `${this.locale('common_project_tab')}: ${project.ganttIDs.length}`;
    },
    updateName({ item, name }) {
      multiViewsProjects.updateItem(item.id, { ...item, name });

      if (multiViewsProjects.getActiveViewData()?.id === item.id) {
        this.$store.commit('headerView/setHeadTitle', name);
      }
    },
    onDelete(pf) {
      this.deletePortfolioObj = pf;
    },
    projectsSelected(arr) {
      this.newPortfolioProjects = [...arr];
    },
    deletePortfolio() {
      let showDeleteToast = false;

      if (this.portfolios.length === 1) {
        showDeleteToast = true;
      }

      multiViewsProjects.removeMultiview(this.deletePortfolioObj.id);
      const name = this.deletePortfolioObj.name;

      this.closeModal();

      if (showDeleteToast) {
        this.$toast.success(this.locale('portfolio_page_portfolio_deleted', { name }));
      }
    },
    closeModal() {
      this.deletePortfolioObj = null;
    },
    goToPortfolio(id) {
      this.$store.commit('headerView/setHeadTitle', '');// reset cached title
      routerHelper.pushRoute(`/projects/${id}/projects`);
    },
    toggleCreateModal(forced = null) {
      if (this.showCreate) {
        this.newPortfolioName = '';
        this.newPortfolioProjects = [];
      }

      this.showCreate = forced !== null ? forced : !this.showCreate;
    },
    setNewPortfolioName(value) {
      this.newPortfolioName = value.trim();
    },
    createPortfolio() {
      const ganttIDs = this.newPortfolioProjects.map(({ id }) => id);

      multiViewsProjects.createMultiview(
        ganttIDs,
        this.newPortfolioName,
      ).then(obj => {
        this.toggleCreateModal(false);
        this.goToPortfolio(obj.id);
      });
      userExtAnalytics.log('create_portfolio_done', { 'projects number': ganttIDs.length });
    },
    showProjectsWarning() {
      this.$toast.warning(this.locale('portfolio_page_no_projects_warning'));
    },
    newPortfolioButtonHandler() {
      if (!this.allProjects.length) {
        this.showProjectsWarning();

        return;
      }

      if (!this.portfolios.length && this.allProjects.length <= 5) {
        this.newPortfolioName = this.locale('portfolio_page_first_portfolio_name');
        this.newPortfolioProjects = [...this.allProjects];
        this.createPortfolio();

        return;
      }

      return this.toggleCreateModal();
    },
    toggleFavorite(id) {
      const newState = !this.portfolios.find(pf => pf.id === id).is_favorite;
      const promise = multiViewsProjects.manualUpdateFavorite(id, newState);

      promise.then(() => {
        app.trigger('sideBar:toggleFavorite', { id, newState });
      });
    },
    projectListRefreshedHandler() {
      const allProjects = projects.getAllProjects().filter(project => !project.is_jira);

      this.allProjects = allProjects;
      const newPortfolioProjectsIds = this.newPortfolioProjects.map(item => item.id);

      this.newPortfolioProjects = allProjects.filter(project => newPortfolioProjectsIds.includes(project.id));
    },
  },
};
</script>

<style module src='./less/portfoliosMain.less'></style>
<style src="./less/pricingTitle.less"></style>
<style lang="less">
.learn_more {
  gap: 2px;

  &:hover {
    svg {
      color: #11519A;
    }
  }
}
</style>
