var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("skeleton-wrapper", {
    staticStyle: { height: "100%" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.$.wrapper }, [
              _c(
                "div",
                { class: _vm.$.controls },
                [
                  _c("skeleton-item", {
                    style: { width: "100px", height: "12px" },
                    attrs: { type: "bar" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$.right_side },
                [
                  _c(
                    "div",
                    { class: _vm.$.btn_group },
                    [
                      _c("skeleton-item", {
                        style: { width: "100px", height: "12px" },
                        attrs: { type: "bar" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("skeleton-item", {
                    style: { width: "100px", height: "24px" },
                    attrs: { type: "bar" },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }