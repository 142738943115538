import projectsModel from "../models/projects";
import _ from "../libs/lodash";
import globalStore from "../store/main";
import app from "../app";
import projectsComponent from "../components/Project";


const outputHandlers = {
  getSerializedData: function (updatedTasks) {
    let dataSerialized = {};

    if (!updatedTasks || _.isEmpty(updatedTasks)) {
      dataSerialized = gantt.serializeWithoutButtons();
    } else {
      dataSerialized = {
        data: _.map(updatedTasks, (taskID) => gantt.getTask(taskID))
      };
    }

    return dataSerialized;
  },
  run: function (updatedTasks) {
    let actionHash = gantt._correctDatesActionHash;
    const ganttID = projectsModel.getActiveProjectData('gantt_id') || gantt._correctDatesSourceGanttId;

    if (!actionHash || !ganttID || !app.config.mode.isBase) {
      return webix.promise.defer();
    }

    const dataSerialized = outputHandlers.getSerializedData(updatedTasks);
    let ganttData = globalStore.getters['tasksModel/getFilteredTasksToUpdate'](ganttID, dataSerialized);

    if (!gantt._moveTasksEvent && ganttData.length > 0) {
      ganttData = outputHandlers.leftOnlyDates(outputHandlers.checkDatesBeforeSending(ganttData));

      return globalStore.dispatch('tasksModel/updateTasksByEvent', {
        updatedTasks: ganttData,
        masterType: 'correctDates',
        actionHash
      }).then(function () {
        gantt.callEvent("afterTasksUpdated", ['correctDates', ganttData]);
        gantt.callEvent('recalculateCriticalPath');
        gantt._correctDatesActionHash = null;
      })
      .catch(function (error) {
        console.warn("ERROR:", error);
      });
    }

    return Promise.resolve();
  },
  leftOnlyDates: function (ganttData) {
    return _.map(_.filter(ganttData, function (task) {
      return (task && task.id);
    }), function (task) {
      return {
        id: task.id,
        gantt_id: task.gantt_id,
        last_update: task.last_update,
        start_date: task.start_date,
        end_date: task.end_date,
        duration: task.duration,
        parent: task.parent
      };
    });
  },
  checkDatesBeforeSending: function (ganttData) {
    ganttData = _.reduce(ganttData, function (result, taskForRequest) {
      var task = gantt.isTaskExists(taskForRequest.id) && gantt.getTask(taskForRequest.id);

      task && result.push(task);

      return result;
    }, []);

    return ganttData;
  },
  generateActionHashAndRun: function () {
    let actionHashPromise;

    if (gantt._correctDatesActionHash) {
      actionHashPromise = Promise.resolve(gantt._correctDatesActionHash);
    } else {
      actionHashPromise = projectsComponent.getLastActionHash(gantt.config.gantt_id);
    }

    return actionHashPromise
      .then((actionHash) => {
        gantt._correctDatesActionHash = actionHash;
        outputHandlers.run();
      })
      .catch(() => {});
  }
};

gantt.attachEvent("onAfterTaskDelete", function (taskId, taskData) {
  if (gantt.config.auto_scheduling && !taskData.unscheduled) {
    gantt._correctDatesActionHash = taskData.actionHash;
    gantt.autoSchedule();
  }
});

gantt.attachEvent("onAfterTaskAdd", function (taskId, taskData) {
  if (gantt.config.auto_scheduling && !taskData.unscheduled) {
    var parent = gantt.getTask(taskData.parent);

    if (new Date(parent.end_date).valueOf() >= new Date(taskData.end_date).valueOf()) {
      return;
    }

    gantt._correctDatesActionHash = taskData.actionHash;
    gantt.autoSchedule(taskId);
  }
});

gantt.attachEvent("onInlineEdit", function (taskId, key, nValue, oldValue) {
  if (gantt.config.auto_scheduling && key === "start_date" && !_.isEqual(new Date(oldValue).valueOf(), new Date(nValue).valueOf())) {
    let taskData = gantt.getTask(taskId);

    gantt._correctDatesActionHash = taskData.actionHash;

    // setTimeout(function() {
    //   gantt.autoSchedule();
    // });
  }
});


gantt.attachEvent("onAfterSmartTaskUpdate", function (taskId, taskData, properties) {
  if (gantt.config.auto_scheduling && !taskData.unscheduled && taskData.type !== gantt.config.types.project &&
    (_.includes(properties, 'start_date') || _.includes(properties, 'type') || _.includes(properties, 'duration'))) {
    gantt._correctDatesActionHash = taskData.actionHash;
    gantt.autoSchedule();
  }
});

gantt.attachEvent("ganttReInit", function (taskId, taskData, properties) {
  if (!app.config.mode.isBase) {
    return;
  }

  outputHandlers.generateActionHashAndRun();
});

gantt.attachEvent('tasks:correctDates', _.debounce(function (generateActionHash) {
  if (generateActionHash) {
    outputHandlers.generateActionHashAndRun();
  } else {
    outputHandlers.run();
  }
}, 100));

export default outputHandlers;