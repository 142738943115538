var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customFieldsColumns.length
    ? _c("section", [
        _c("div", { class: _vm.$style["section-name"] }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.locales("settings_global_columns_title")) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _vm.customFieldsValuesLoaded
          ? _c(
              "div",
              { class: _vm.$style["custom-field-list"] },
              _vm._l(_vm.customFieldsColumns, function (column) {
                return _c("custom-field-row", {
                  key: column.id,
                  attrs: {
                    "field-column": column,
                    "field-value": _vm.customFieldsValues[column.id],
                    disabled: _vm.isCustomFieldsDisabled,
                    "task-data": _vm.taskData,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("change", $event)
                    },
                  },
                })
              }),
              1
            )
          : _c(
              "div",
              { class: _vm.$style["custom-field-list"] },
              _vm._l(_vm.customFieldsColumns, function (column) {
                return _c(
                  "div",
                  [
                    _c("custom-field-skeleton-row", {
                      key: column.id,
                      attrs: { "column-name": column.name },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }