<template>
  <div :class="[$style.field_wrap, $style.single_select]">
    <div :class="[$style.input_label]">
      <span :class="$style.txt_wrap">{{ label }}</span>
      <div
        v-show="hasChacked"
        :id="gpAutotestName + '_reset_btn'"
        :class="$style.reset"
        :gp-autotest-name="gpAutotestName"
        @click="resetChacked()"
      >
        {{ reset_locale }}
      </div>
    </div>

    <div
      ref="inputField"
      :class="[$style.input_field_wrap]"
    >
      <div
        :class="[$style.input_field, (hasChacked || isShowDropList) ? $style.active : '']"
        @click="showDropList"
      >
        <div
          v-if="chacked"
          :class="$style.input_field_selected"
        >
          <template v-if="dropTemplate === 'text'">
            <div>  {{ findItemById(chacked)[valueField] }} </div>
          </template>
        </div>
        <div
          v-else
          :class="$style.input_placeholder"
        >
          {{ placeholder }}
        </div>

        <webix-ui :config="icon_arrow" />
      </div>

      <div
        ref="dropList"
        :class="[
          $style.drop_list,
          $style.drop_scroll,
          'gantt_scroll',
          isShowDropList ? $style.show_list : '',
          isBottom ? $style.bottom_drop : $style.top_drop
        ]"
        :style="{
          top: coordinates.top,
          bottom: coordinates.bottom,
          left: coordinates.left,
          right: coordinates.right,
          width: dropListWidth
        }"
      >
        <div
          v-for="item in dropData"
          :key="dropTemplate + item.id"
          :class="[$style.list_item]"
          @click="selectItem(item)"
        >
          <input
            :id="item[trackBy] + componentKey"
            v-model="chacked"
            type="radio"
            :value="item[trackBy]"
          >
          <label
            :for="item[trackBy] + componentKey"
            :class="[ item[trackBy] == chacked ? $style.highlite_item : '']"
          >
            <template v-if="dropTemplate === 'text'">
              <span>{{ item[valueField] }}</span>
            </template>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_downArrow from '../../../svg/filter/arrow_dropdawn.svg';
import svgIconForVue from '../../common/svgIconForVue';

export default {
  name: 'FilterVue',
  props: {
    placeholder: { type: String, required: false, default: 'placeholder' },
    label: { type: String, required: false, default: 'label' },
    dropTemplate: {
      type: String,
      required: false,
      default: 'text',
      validator(value) {
        return (
          ['text', 'color', 'icon+text', 'color+text'].indexOf(value) !== -1
        );
      },
    },
    dropItems: { type: Array, required: false, default: null },
    trackBy: { type: String, required: false, default: 'id' },
    valueField: { type: String, required: false, default: 'value' },
    iconField: { type: String, required: false, default: 'icon' },
    selected: { type: Array, required: false, default: null },
    gpAutotestName: { type: String, required: false, default: `gp_autotest_filter_option_${new Date().getTime()}` },
  },

  data() {
    return {
      reset_locale: __('common_reset'),
      icon_arrow: svgIconForVue(icon_downArrow, 15, 24),
      chacked: null,
      isShowDropList: false,
      coordinates: {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      },
      isBottom: true,
      dropListWidth: '300px',
      dropData: [],
      mountedMode: false,
      updateChacked: false,
      blockEmit: false,
    };
  },

  computed: {
    hasChacked() {
      return this.chacked;
    },
    componentKey() {
      const min = 1000; const
        max = 100000;

      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  watch: {
    selected(val) {
      this.blockEmit = true,
      this.chacked = val || '';
    },
    chacked(val) {
      // if(!val) !this.blockEmit && this.$emit('reset', this.chacked);
      // else !this.blockEmit && this.$emit('check',  this.chacked);

      // this.blockEmit = false;
    },
    dropItems(val) {
      this.dropData = val && val.length ? [...val] : [];
    },

    isShowDropList(val) {
      if (!val) return;

      this.$nextTick(() => {
        const width = this.$refs.inputField.offsetWidth;

        this.$refs.dropList.style.width = width;
      });
    },
    $route(to, from) {
      this.isShowDropList = false;
    },
  },
  mounted() {
    this.blockEmit = true;
    const inputField = this.$refs.inputField;

    this.dropData = this.dropItems && this.dropItems.length ? [...this.dropItems] : [];
    const coordinates = inputField.getBoundingClientRect();

    this.dropListWidth = `${coordinates.width}px`;

    this.chacked = this.selected ? this.selected : '';
    this.addListheners();
  },

  beforeDestroy() {
    this.removeListheners();
  },

  methods: {
    selectItem(item) {
      this.isShowDropList = false;
      this.$emit('check', item.id);
    },
    findItemById(id) {
      const res = this.dropData.find(item => item[this.trackBy] === id);

      return res;
    },
    svgForVue(icon) {
      return svgIconForVue(icon, 17, 17);
    },
    resetChacked() {
      this.chacked = '';
      this.$emit('reset', this.chacked);
    },
    documentClickHandler(e) {
      const node = this.$refs.inputField;

      if (!node.contains(e.target)) {
        this.isShowDropList = false;
      }
    },
    showDropList() {
      const coordinates = this.$refs.inputField.getBoundingClientRect();
      const clientHeight = document.documentElement.clientHeight;
      const dropHeight = 37 * this.dropItems.length < 216 ? (37 * this.dropItems.length) : 216;

      this.isBottom = (coordinates.bottom + dropHeight + 2) < clientHeight;

      if (this.isBottom) {
        this.coordinates = {
          left: `${coordinates.left}px`,
          right: 'auto',
          top: `${coordinates.bottom}px`,
          bottom: 'auto',
        };
      } else {
        this.coordinates = {
          left: `${coordinates.left}px`,
          right: 'auto',
          top: 'auto',
          bottom: `${clientHeight - coordinates.top}px`,
        };
      }
      this.isShowDropList = !this.isShowDropList;
    },

    scrollHandler() {
      this.isShowDropList = false;
    },

    addListheners() {
      document.addEventListener('click', this.documentClickHandler);

      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        if (!this.$refs.inputField.contains(div)) div.addEventListener('scroll', this.scrollHandler);
      });
    },
    removeListheners() {
      document.removeEventListener('click', this.documentClickHandler);
      window.removeEventListener('scroll', this.scrollHandler);

      const divs = document.querySelectorAll('div.gantt_scroll');

      divs.forEach(div => {
        div.addEventListener('scroll', this.scrollHandler);
      });
    },
  },
};
</script>

<style module src="./style.less" lang="less"></style>
