var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: _vm.hideCalendar,
            closeConditional: function () {
              return _vm.positionContext
            },
          },
          expression:
            "{\n    handler: hideCalendar,\n    closeConditional: () => positionContext,\n  }",
        },
      ],
      key: _vm.componentKey,
      class: [_vm.$style["date-picker__container"], "vgp-interactive-element"],
    },
    [
      _c("vgp-input", {
        ref: "datePickerInput",
        class: [
          !!_vm.positionContext ? _vm.$style.active : "",
          _vm.$style["custom-input-hover"],
        ],
        attrs: {
          value: _vm.formattedDate,
          "icons-left": _vm.iconsLeft,
          border: false,
          mask: _vm.dateMask,
          readonly: _vm.isTimePicker,
          "is-disabled": _vm.disabled,
          placeholder: _vm.placeholder,
          small: "",
        },
        on: { onFocus: _vm.showCalendar, onChange: _vm.handleChange },
        nativeOn: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.hideCalendar($event)
          },
        },
      }),
      _vm._v(" "),
      _vm.positionContext
        ? _c("context-menu", {
            attrs: { position: _vm.positionContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("webix-ui", {
                        attrs: { config: _vm.datepickerConfig },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1222764918
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }