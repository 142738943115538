import store from '../../../store/main';
import app from '../../../app';

function onRoleProjectDeleted(event) {
  event.data.ids.forEach(id => {
    store.commit('roles/deleteRole', { type: 'project', id });
  });

  app.trigger('onAfterCollaboration', {
    event: event.event,
    ids: event.data.ids,
  });
}

export default onRoleProjectDeleted;
