<template>
  <div
    :class="[
      $style['dependency__row'],
      isFieldDisabled('dependencies') ? $style['dependency__row_disabled'] : ''
    ]"
  >
    <div :class="$style['dependency__row-inner-wrap']">
      <img
        :src="`/svg/dependencyTypes/${dependency.linkType}.svg`"
        alt="Dependency type image"
        :class="$style['dependency__image']"
        @mouseenter="showPreview"
        @mouseleave="$emit('hide-preview')"
      >
      <div :class="$style['dependency__wrap']">
        <div :class="$style['dependency__name-wrap']">
          <span :class="$style['dependency__task-name']">
            {{ dependency.taskName }}
          </span>
          <span
            v-if="dependency.taskMaster"
            :class="$style['dependency__task-badge']"
          >
            {{ locales('settings_dependency_predecessor_label') }}
          </span>
        </div>
        <div :class="$style['dependency__description']">
          <span :class="$style['dependency__type']">
            {{ dependency.linkShortName }}
          </span>
          <span :class="$style['dependency__lag-label']">
            {{ locales('settings_dependency_lag') + ':' }}
          </span>
          <span
            v-if="isFieldDisabled('dependencies')"
            :class="$style['dependency__lag_disabled']"
          >
            {{ getConvertedLag(dependency.linkLag) }}
          </span>
          <vgp-input
            v-else
            :key="dependencyLagInputKey"
            :value="getConvertedLag(dependency.linkLag)"
            :class="$style['dependency__lag']"
            :border="false"
            select-by-focus
            @onChange="handleChangeDependencyLag"
          />
        </div>
      </div>
    </div>
    <vgp-icon-button
      v-if="!isFieldDisabled('dependencies')"
      :class="$style['dependency__delete-button']"
      :icon="{ name: 'delete' }"
      @onClick="$emit('delete')"
    />
  </div>
</template>

<script>
import VgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';
import timeParser from '$$helpers/timeParser';

export default {
  name: 'DependencyRow',
  components: { VgpIconButton },
  inject: ['isFieldDisabled'],
  props: {
    dependency: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locales: __,
      dependencyLagInputKey: 0,
    };
  },
  methods: {
    showPreview(event) {
      const position = event.target.getBoundingClientRect();

      this.$emit('show-preview', position);
    },
    async handleChangeDependencyLag(event) {
      const parsedNewLag = timeParser.input(event.target.value, {
        durationData: gantt.config.durationData,
        durationStep: gantt.config.duration_view,
        prop: 'lag',
        lag: true,
      });

      if (!parsedNewLag) {
        return this.dependencyLagInputKey++;
      }

      this.$emit('change-lag', parsedNewLag.value);
    },
    getConvertedLag(lag) {
      return timeParser.output(lag, {
        durationData: gantt.config.durationData,
        durationStep: gantt.config.duration_view,
        prop: 'lag',
      });
    },
  }
};
</script>

<style module lang="less">
.dependency {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 5px 0;

    &-inner-wrap {
      display: flex;
      align-items: center;
    }

    &::before {
      z-index: -123;
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 64px);
      height: 100%;
      background: #F2F2F2;
    }

    // full width border bottom
    &::after {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: -32px;
      width: calc(100% + 64px);
      height: 1px;
      background: #E3E3E3;
    }

    &:hover:not(&_disabled) {
      .dependency__delete-button {
        opacity: 1;
      }

      .dependency__lag {
        background: #F8F8F8;
        border-color: #E5E5E5 !important;
      }

      &::before {
        display: block;
      }
    }
  }

  &__image {
    margin: 0 12px;
    width: 50px;
    height: 32px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }

  &__name-wrap {
    display: flex;
    align-items: center;
  }

  &__task-name {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #191919;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__task-badge {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 4px;
    margin-left: 4px;
    background: rgba(171, 171, 171, 0.35);
    font-family: Lato-Regular, sans-serif;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
  }

  &__description {
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    display: flex;
    align-items: center;
  }

  &__type {
    margin-right: 12px;
  }

  &__lag {
    border: 1px solid transparent !important;
    margin-left: 4px;
    input {
      height: 26px !important;
      &:not(:focus-within) {
        color: #999999;
      }
    }
    max-width: 50px;

    &_disabled {
      margin-left: 8px;
      max-width: 100%;
      font-family: Lato-Regular, sans-serif;
      font-size: 12px;
      line-height: 26px;
      color: #191919;
    }
  }

  &__delete-button {
    opacity: 0;
    transition: 150ms;
  }

}

</style>
