var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: _vm.portalName } }, [
    _c(
      "div",
      {
        ref: "parent",
        class: [
          _vm.$style.right_popup,
          _vm.$style.bottom_border,
          _vm.borderTop ? _vm.$style.top_border : "",
          _vm.show && _vm.withAnimation
            ? _vm.$style.show_right_popup_animated
            : "",
          _vm.show && !_vm.withAnimation ? _vm.$style.show_right_popup : "",
        ],
        style: Object.assign({}, _vm.styles, { width: _vm.width + "px" }),
      },
      [_vm._t("body")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }