import store from '../../main';
import calendarHelpers from '../../../views/calendarView/helpers';

export const initAdapter = (calendarInstance, ganttInstance) => {
  const calendarState = calendarInstance.api.getReactiveState();

  store.subscribe((mutation, state) => {
    if (mutation.payload?.fromCalendar || !calendarInstance.api) return;

    if (mutation.type === 'tasksModel/updateTask') {
      const isEventExist = calendarInstance.getEvent({ id: mutation.payload.id });

      if ((mutation.payload.type === 'task' || mutation.payload.type === 'milestone') && isEventExist) {
        if (!calendarHelpers.hasAccessToTask(mutation.payload)) {
          calendarInstance.api.exec('delete-event', { id: mutation.payload.id, fromStore: true });

          return;
        }

        if (mutation.payload.type === 'milestone') {
          calendarInstance.api.exec('delete-event', { id: mutation.payload.id, fromStore: true });

          return;
        }

        const tasks = store.getters['tasksModel/getTasksForGantt'](mutation.payload.gantt_id).data;

        calendarInstance.api.exec('update-events', {
          projectId: mutation.payload.gantt_id,
          tasks,
        });
      }
    }

    if (mutation.type === 'tasksModel/updateTaskResourcesByGanttId') {
      const tasks = store.getters['tasksModel/getTasksForGantt'](mutation.payload.ganttId).data;

      calendarInstance.api.exec('update-events', {
        projectId: mutation.payload.ganttId,
        tasks,
      });

      return;
    }

    if (mutation.type.includes('tasksModel/updateProjectTasksData')) {
      calendarInstance.api.exec('update-events', {
        projectId: mutation.payload.id,
        tasks: mutation.payload.tasks,
      });
    }

    if (mutation.type.includes('tasksModel/addTask')) {
      const isEventExist = calendarInstance.getEvent({ id: mutation.payload.id });

      if (!isEventExist && calendarHelpers.hasAccessToTask(mutation.payload)) {
        const calendarEventByTask = calendarHelpers.prepareEvent(mutation.payload);

        calendarInstance.api.exec('add-event', { event: calendarEventByTask, taskData: mutation.payload, fromStore: true });
      }
    }

    if (mutation.type.includes('tasksModel/deleteTask')) {
      const isEventExist = calendarInstance.getEvent({ id: mutation.payload.id });

      if (isEventExist) {
        calendarInstance.api.exec('delete-event', { id: mutation.payload.id, fromStore: true });

        const parentsTasks = store.getters['tasksModel/getParentTasksByGanttId'](mutation.payload.gantt_id);

        parentsTasks.forEach(taskData => {
          const childTasks = store.getters['tasksModel/getChildByTask'](mutation.payload.gantt_id, taskData.id);

          if (!childTasks.length && taskData.parent !== 0) {
            taskData.type === 'task';

            const calendarEventByTask = calendarHelpers.prepareEvent(taskData);

            calendarInstance.api.exec('add-event', { event: calendarEventByTask, taskData, fromStore: true });
          }
        });
      }
    }

    if (mutation.type.includes('headerView/setCalendarStep')) {
      calendarInstance.api.exec('set-bound', { step: mutation.payload });
    }

    if (mutation.type.includes('headerView/setCalendarTodayDate')) {
      calendarInstance.api.exec('set-date', { value: mutation.payload });
    }
  });

  calendarState.date.subscribe(date => {
    store.commit('headerView/setCalendarViewDate', { date, fromCalendar: true });
  });

  calendarInstance.api.intercept('add-event', data => {
    if (calendarInstance.resourcesToNewTask && calendarInstance.resourcesToNewTask.length) {
      const resourcesToTask = calendarInstance.resourcesToNewTask;
      const parentData = gantt.getTaskByIndex(0);
      const assignedResources = resourcesToTask.map(resource => ({
        id: resource.id,
        userId: resource.userId,
        action: 'insert',
        value: 8,
      }));

      store.dispatch('tasksModel/changeAssign', {
        changedResources: assignedResources,
        taskId: data.event.id,
        ganttId: parentData.gantt_id,
      });

      calendarInstance.resourcesToNewTask = null;

      return {
        ...data,
        event: {
          ...data.event,
          resources: resourcesToTask,
        },
      };
    }
  });

  calendarInstance.api.on('update-task-by-worker', task => {
    ganttInstance.ganttWorker.calculate([task], 'changeTasks', 'updateTasks');
  });

  calendarInstance.api.on('create-task-from-calendar', data => {
    const { task, parentId, sortOrderAndIndex } = data;

    ganttInstance.createTask(webix.copy(task), parentId, sortOrderAndIndex);
  });
};

export default {
  initAdapter,
};
