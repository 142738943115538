<template>
  <div :class="[$.skeleton_row]">
    <div :class="$.firstCell">
      <skeleton-item
        :type="'square'"
        :size="'lst'"
      />
      <skeleton-item
        :style="{height: '17px'}"
        :type="'bar'"
        :width="'middle'"
      />
    </div>
    <div :class="$.wideCell">
      <skeleton-item
        :style="{height: '23px'}"
        :type="'bar'"
        :width="'wide'"
      />
    </div>
    <div :class="$.resourceCell">
      <div
        v-for="i in resourceCell"
      >
        <skeleton-item
          :key="i"
          :type="'circle'"
          :size="'bg'"
        />
      </div>
    </div>

    <div :class="$.tinyCell">
      <skeleton-item
        :type="'bar'"
        :style="{height: '11px'}"
        :width="'tiny'"
      />
    </div>
    <div :class="$.wideCell">
      <skeleton-item
        :type="'bar'"
        :style="{height: '23px'}"
        :width="'wide'"
      />
    </div>
    <div :class="$.wideCell">
      <skeleton-item
        :type="'bar'"
        :style="{height: '23px'}"
        :width="'wide'"
      />
    </div>
    <div :class="$.tinyCell">
      <skeleton-item
        :type="'bar'"
        :style="{height: '11px'}"
        :width="'tiny'"
      />
    </div>
    <div :class="$.wideCell">
      <skeleton-item
        :type="'bar'"
        :style="{height: '23px'}"
        :width="'wide'"
      />
    </div>
  </div>
</template>

<script>

import skeletonItem from '../../common/VueComponents/skeletons/skeletonItem.vue';

export default {
  name: 'SkeletonRow',
  components: { skeletonItem },

  computed: {
    resourceCell() {
      return this.getRandomNumber(1, 4);
    },
  },
  methods: {
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

  },
};
</script>

<style module="$"  src="./skeleton.less"></style>
