<template>
  <core-popup
    show
    body-style="noPaddings"
    background-image="https://cdn.ganttpro.com/statics/media/images/ganttprointerfaceblurred.jpg"
  >
    <template #body>
      <template v-if="!demoSchedulerShown">
        <div :class="[$style.body__wrapper, { [$style.body__wrapper_wide]: isBodyWrapperWide }]">
          <div :class="[$style.body__header, isBodyWrapperWide ? $style.body__header_mb33 : '']">
            <div :class="$style.logo">
              <vgp-svg-image
                :name="'logo'"
                color="#003D36"
              />
            </div>
          </div>
          <div :class="$style.body__content">
            <component
              :is="currentComponent"
              :team-label="teamLabel"
              @demo-scheduler-opened="demoSchedulerShown = true"
            />
          </div>
          <div
            v-if="!isOnboardingWizard && !isBookDemo"
            :class="$style.body__footer"
          >
            <div :class="$style.progress">
              <div
                :class="$style.progress__bar"
                :style="{ width: `${progressBarWidth}%` }"
              />
            </div>
            <portal-target
              :key="currentComponent"
              slim
              name="onboarding-footer"
            />
          </div>
        </div>
      </template>
    </template>
  </core-popup>
</template>

<script>

import { mapGetters } from 'vuex';
import { api } from '$$store/api';
import CorePopup from '../common/VueComponents/popups/corePopup/corePopup.vue';
import PersonalInfo from './components/registrationSteps/1-PersonalInfo.vue';
import MembersInfo from './components/registrationSteps/2-MembersInfo.vue';
import PlanningInfo from './components/registrationSteps/3-PlanningInfo.vue';
import FamiliarityInfo from './components/registrationSteps/4-FamiliarityInfo.vue';
import FeaturesInfo from './components/registrationSteps/5-FeaturesInfo.vue';
import WizardMain from './components/wizard/WizardMain.vue';
import BookDemo from './components/bookDemo/BookDemo.vue';
import app from '../../app';
import rights from '../../components/rights/index';
import vgpSvgImage from '../common/VueComponents/VueIcon/vgpSvgImage.vue';

export default {
  name: 'OnboardingPopup',
  components: {
    CorePopup,
    PersonalInfo,
    MembersInfo,
    PlanningInfo,
    FamiliarityInfo,
    FeaturesInfo,
    WizardMain,
    BookDemo,
    vgpSvgImage,
  },
  data() {
    return {
      teamLabel: {},
      demoSchedulerShown: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStepIndex: 'onboarding/getCurrentStepIndex',
      currentStepConfig: 'onboarding/getCurrentStepConfig',
    }),
    isBodyWrapperWide() {
      return this.isOnboardingWizard || this.isBookDemo;
    },
    progressBarWidth() {
      const registrationStepsLength = 5;

      return (100 / registrationStepsLength) * (this.currentStepIndex + 1);
    },
    currentComponent() {
      return this.currentStepConfig.component;
    },
    isOnboardingWizard() {
      return this.currentStepConfig.isWizard;
    },
    isBookDemo() {
      return this.currentStepConfig.id === 'bookDemo';
    },
  },
  created() {
    this.updateTeamLabel();

    app.on('userProfile:update', () => {
      this.updateTeamLabel();
    });

    app.on('refreshLogo', () => {
      this.updateTeamLabel();
    });

    if (rights.account.isOwner()) {
      this.fetchTeamFavicon();
      user.team.name === 'My Team' && this.fetchTeamNameFromEmail();
    }
  },
  methods: {
    updateTeamLabel() {
      this.teamLabel = {
        logo: user.team.logo,
        name: user.team.name !== 'My Team' ? user.team.name : '',
        userPhoto: user.photo,
        favicon: (user.team.show_favicon) ? user.team.favicon : null,
        showFavicon: !!user.team.show_favicon,
      };
    },
    fetchTeamFavicon() {
      api.post('/team/favicon')
        .then(({ data: faviconURL }) => {
          user.team.show_favicon = 1;
          user.team.favicon = faviconURL.toLowerCase() !== 'ok' ? faviconURL : null;
          app.trigger('refreshLogo', faviconURL);
        });
    },
    fetchTeamNameFromEmail() {
      const options = { name: '', getFromEmail: 1 };

      api.put('/team/changeName', options)
        .then(({ data: { name: companyName } }) => {
          user.team.name = companyName || options.name;
          app.trigger('refreshLogo');
        });
    },
  },
};
</script>

<style lang="less" module scoped>

.logo {
  width: 120px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body__header {
  display: flex;
}

.body__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 33px 40px 32px 40px;
  width: 840px;
  height: 530px;
  overflow: hidden;
  &_wide {
    transition: all .35s cubic-bezier(0.42, 0, 1, 1);
    padding: 33px 0 0 38px;
    width: 1046px;
    height: 660px;
  }
}

.body__header {
  margin-bottom: 67px;
  &_mb33 {
    margin-bottom: 33px;
  }
}

.body__content {
  height: 100%;
}

.body__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.progress {
  width: 118px;
  height: 5px;
  background: #E5E5E5;
  border-radius: 7px;
  &__bar {
    transition: width .4s linear;
    height: 5px;
    background: #337870;
    border-radius: 7px;
  }
}

.footer__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-family: Lato-Bold sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #00564C;
    margin-right: 7px;
  }

}

</style>
