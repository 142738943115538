import helpPopup from './helpPopup';
import app from '../../app';

const __ = window.__;
const _PROTO_NAME = 'helpIcon';

// {Object} -> {String}
const _getIconTemplate = ({ isUsed }) => `
  <div class="help-icon ${isUsed ? 'activity_used' : ''}">
    <div class="help-icon-inner">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22V18.9684C7 18.9684 4 15.2695 4 11C4 6.58737 7.5 3 12 3C16.4126 3 20 6 20 11C20 16 16 20 12 22ZM12 4C8 4 5 7 5 11C5 15 8.12458 18 12 18H13V20C16.0653 18.0632 19.0048 15 19 11C18.9952 7 16 4 12 4ZM11 14H13.0054L13 16H12.5H11V14ZM13 13H11C11 10.5 13 10.6842 13 9C13 8.44394 12.6568 8 12 8C11.5 8 11 8.5 11 9H9C9 7.13895 10.1389 6 12 6C14 6 15 7.5663 15 9C15 10.5 13 11.5 13 13Z" fill-rule="evenodd"/>
      </svg>
    </div>
  </div>
`;

// V P from MVP
webix.protoUI({
  name: _PROTO_NAME,
  _id: null,
  // ???
  $skin() {
    this.defaults.height = webix.skin.$active.inputHeight;
  },
  // initialization of the component
  $init(config) {
    // console.info('help icon $init', config.id, this); // !DEBUG

    this._id = config.dataId;
    this.id = config.id;

    // this._handleChangeFeature = this._handleChangeFeature.bind(this);
    // this._initModelComplete = this._initModelComplete.bind(this);

    app.on('user:activities:loaded', this._onUserActivitiesLoadedHandler.bind(this));

    if (app.callGlobalStore && this._id) {
      const activityStatus = app.callGlobalStore('getter', 'user/getActivityStatus', [this._id]);
      setTimeout(() => {
        $$(this.id) && $$(this.id).setValues({ isUsed: activityStatus === 1 });
      }, 0);
    }

    // this.setValues({ isUsed: true }); // !FIXME
  },
  defaults: {
    data: { isUsed: false },
    template: _getIconTemplate,
    width: 50,
    on: {
      onItemClick(iconId, mouseEvent) {
        helpPopup.showHelpBlock(mouseEvent, this._id); // compatibility
        app.callGlobalStore('action', 'user/updateActivityStatus', [{
          activityName: this._id,
          status: 1,
        }]);
        $$(this.id) && $$(this.id).setValues({ isUsed: true });
      },
    },
  },
  //
  _onUserActivitiesLoadedHandler(activities) {
    const currentHelpActivity = activities.find(entry => entry.name === this._id);

    if (!currentHelpActivity) {
      return;
    }

    const currentHelpActivityStatus = currentHelpActivity.activity_status;
    if (this.getNode() && this.getNode().hasChildNodes()) {
      this.setValues({
        isUsed: currentHelpActivityStatus === 1,
      });
    }
  },
  _onUserActivityUpdatedHandler(activity) {
    const { name, activity_status } = activity;

    if (name === this._id) {
      $$(this.id) && $$(this.id).setValues({ isUsed: activity_status === 1 } /* , true */);
    }
  },
}, webix.ui.template, webix.ui.button, webix.EventSystem);

export default {};

// onItemClick: function (iconId, mouseEvent) {
//   if (this.config.data === "importProjectBlock") {
//     this.config.id = "importProjectBlock";
//     this.getNode().firstChild.classList.add('activity_used');
//   } else if (this.config.data === "newProjectBlock") {
//     this.config.id = "newProjectBlock";
//     this.getNode().firstChild.classList.add('activity_used');
//   } else if (this.config.data === undefined){
//     // костыль
//     this.config.id = iconId;
//   }
// helpPopup.showHelpBlock(mouseEvent, this.config.id);
// // класс при открытом окне (backdrop)
// this.getNode().firstChild.classList.add('active-help');
// // серый цвет
// this.getNode().firstChild.classList.add('activity_used');
// }

// onItemClick: function (iconId, mouseEvent) {
//   if (this.config.data === "importProjectBlock") {
//     this.config.id = "importProjectBlock";
//     this.getNode().firstChild.classList.add('activity_used');
//   } else if (this.config.data === "newProjectBlock") {
//     this.config.id = "newProjectBlock";
//     this.getNode().firstChild.classList.add('activity_used');
//   } else if (this.config.data === undefined){
//     // костыль
//     this.config.id = iconId;
//   }
