<template>
  <div
    :class="[
      'js_add_task_entity',
      $style.btn, isDisabled ? $style.disabled : '',
    ]"
  >
    <div :class="$style.btn_part">
      <vgp-icon-button
        :icon="{ name: 'plus-1', type:'bold', size: 20}"
        :label="locale.add_new"
        :colors="buttonColors"
        bold
        :class="[
          'js_add_task',
          $style.left_btn,
        ]"
      />
    </div>

    <div
      :class="[
        $style.btn_part,
        select.select,
      ]"
    >
      <vgp-icon-button
        ref="contextMenuButton"
        v-click-outside="closePopup"
        :icon="{ name: isShowDrop ? 'arrow-top' : 'arrow-down', size: 20 }"
        :colors="buttonColors"
        :active="isShowDrop"
        :class="[
          $style.right_btn,
        ]"
        @onClick="togglePopup"
      />

      <context-menu
        v-show="isShowDrop"
        :position="positionContext"
      >
        <template #body>
          <div
            :class="[
              'js_add_task',
              select.menu_item,
            ]"
            @click="closePopup"
          >
            <svg-sprite
              :class="select.icon"
              name="task"
              type="regular"
              size="24"
            />
            <div :class="select.text">
              {{ locale.add_task }}
            </div>
          </div>

          <div
            :class="[
              'js_add_milestone',
              select.menu_item,
            ]"
            @click="closePopup"
          >
            <svg-sprite
              :class="select.icon"
              name="milestone"
              type="regular"
              size="24"
            />
            <div :class="select.text">
              {{ locale.add_milestone }}
            </div>
          </div>
        </template>
      </context-menu>
    </div>
  </div>
</template>

<script>
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import vgpIconButton from '$$vueCmp/globalButton/withIcon/iconButton.vue';

export default {
  components: {
    svgSprite,
    vgpIconButton,
  },
  data() {
    return {
      locale: {
        add_new: __('add_new'),
        add_task: __('gantt_add_task'),
        add_milestone: __('gantt_add_milestone'),
      },
      isShowDrop: false,
      positionContext: null,
      buttonColors: {
        color: '#FFFFFF',
        bg: '#1565C0',
        hoverBg: '#11519A',
        activeBg: '#0D3D73',
      },
    };
  },
  computed: {
    isDisabled() {
      return this.$store.getters['headerView/isBulkChange'];
    },
  },
  methods: {
    togglePopup() {
      this.isShowDrop = !this.isShowDrop;
      if (this.isShowDrop) {
        this.positionContext = this.$refs.contextMenuButton.$el.getBoundingClientRect();
      }
    },
    closePopup() {
      this.isShowDrop = false;
    },
  },
};
</script>

<style module src="../less/addTaskBtn.less"></style>
<style module="select" src="../less/select.less"></style>
