import { groupBy } from 'lodash';
import { api, internalApi } from '$$store/api';
import app, { appIds } from '../../app';
import projectsModel from '../../models/projects';

const { events: { ID_EVENT_COLLABORATION_UPDATEDMODELS } } = appIds;

export default {

  async fetchAttachmentsByProjectIds({ state, commit, getters }, { projectIds, refetch = false }) {
    try {
      const projectIdsArray = Array.isArray(projectIds) ? projectIds : [projectIds];
      let projectIdsNeedToFetch;

      if (refetch) {
        projectIdsNeedToFetch = projectIdsArray;
      } else {
        projectIdsNeedToFetch = projectIdsArray.filter(projectId => !getters.isAttachmentsAlreadyFetchedForProject(projectId));
      }

      if (!projectIdsNeedToFetch.length) {
        return Promise.resolve();
      }

      commit('setIsFetching', true);
      const { data } = await internalApi.get(
        '/attachments/getByProjectIds',
        { params: { projectId: projectIdsNeedToFetch } },
      );

      const fetchedGroupedAttachmentsByProjectId = groupBy(data, 'projectId');

      projectIdsNeedToFetch.forEach(projectId => {
        if (fetchedGroupedAttachmentsByProjectId[projectId]) {
          commit('setAttachments', { projectId, attachments: fetchedGroupedAttachmentsByProjectId[projectId] });
        } else {
          commit('setAttachments', { projectId, attachments: [] });
        }
      });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      state.isFetching && commit('setIsFetching', false);
    }
  },

  async createAttachment({ dispatch, commit }, { projectId, taskId, fileList }) {
    const formData = new FormData();

    formData.append('taskId', taskId);
    formData.append('userId', window.user.id);
    for (let i = 0; i < fileList.length; i++) {
      formData.append('file[]', fileList[i]);
    }

    const { data: { items } } = await internalApi.post('/attachments',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

    items.forEach(attachment => {
      dispatch('onWSAttachmentCreated', { projectId, attachment });
    });
    gantt.callEvent('taskEditor:task:changed', [{ id: taskId }]);

    return Promise.resolve(items);
  },

  onWSAttachmentCreated({ commit, dispatch, rootGetters }, createdAttachment) {
    const { projectId, attachment } = createdAttachment;
    const taskData = rootGetters['tasksModel/getTaskByGanttId'](projectId, attachment.taskId);

    if (!taskData) {
      console.warn(`[attachments::_wsHandleFileUpload] -> no task data for: ${projectId} ${attachment.taskId}`);

      return;
    }

    taskData.hasAttachments = +(taskData.hasAttachments || 0) + 1;

    const unreadComments = rootGetters['comments/getCommentsByProjectAndTaskId'](projectId, attachment.taskId).filter(comment => !comment.read);
    const hasUnreadComments = unreadComments.length ? 1 : 0;

    commit('addAttachment', { projectId, attachment });
    dispatch('tasksModel/updateTask', {
      taskChanges: taskData,
      taskId: taskData.id,
      ganttId: projectId,
    }, { root: true });

    gantt.callEvent('addTaskAttachmentNotification', [projectId, attachment.taskId, 0, taskData.hasAttachments, hasUnreadComments]);
    projectsModel.updateProjectFileStore(projectId, attachment.size, 'upload');
    app.trigger(ID_EVENT_COLLABORATION_UPDATEDMODELS);
    app.trigger('settingsProject:updateFileStore', attachment.size, { gantt_id: projectId, task_id: attachment.taskId }, 'upload');
  },

  async createAttachmentFromExternalProvider({ state, commit }, payload) {
    try {
      if (state.isUploading) {
        return Promise.resolve();
      }
      commit('setIsUploading', true);
      const { data: { ids } } = await api.post('/upload/uploadFileFromExternalProvider/', payload);
      // We need this because from request above we're getting only ids of created attachments
      const { data: allAttachments } = await internalApi.get('/attachments', { params: { taskId: [payload.taskId] } });
      const createdAttachments = allAttachments.filter(item => ids.includes(item.id));

      createdAttachments.forEach(attachment => commit('addAttachment', { projectId: attachment.projectId, attachment }));
      commit('setIsUploading', false);

      return Promise.resolve();
    } catch (e) {
      commit('setIsUploading', false);

      return Promise.reject(e);
    }
  },

  async deleteAttachment({ commit }, { projectId, attachmentId }) {
    try {
      await internalApi.delete(`/attachments/${attachmentId}`);

      // commit('removeAttachment', { projectId, attachmentId });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteAttachmentsMultiple({ commit }, { projectId, attachmentIds }) {
    try {
      await internalApi.delete('/attachments/delete/byIds', { data: { attachmentIds } });
      attachmentIds.forEach(id => commit('removeAttachment', { projectId, attachmentId: id }));
    } catch (error) {
      return Promise.reject(error);
    }
  },

  onWSAttachmentDeleted({
    commit, dispatch, getters, rootGetters,
  }, deletedId) {
    const storeAttachment = getters.getAttachmentById(deletedId);

    if (!storeAttachment) return;

    const taskData = rootGetters['tasksModel/getTaskByGanttId'](storeAttachment.projectId, storeAttachment.taskId);

    if (!taskData) {
      console.warn(`[attachments::_wsHandleFileUpload] -> no task data for: ${storeAttachment.projectId} ${storeAttachment.taskId}`);

      return;
    }

    taskData.hasAttachments = +taskData.hasAttachments - 1;

    commit('removeAttachment', { projectId: storeAttachment.projectId, attachmentId: deletedId });
    dispatch('tasksModel/updateTask', {
      taskChanges: taskData,
      taskId: taskData.id,
      ganttId: storeAttachment.projectId,
    }, { root: true });

    app.trigger(ID_EVENT_COLLABORATION_UPDATEDMODELS);
    gantt.callEvent('removeTaskAttachmentNotification', [storeAttachment.projectId, storeAttachment.taskId, 1, taskData.hasAttachments, storeAttachment]);
  },

};
