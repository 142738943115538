const passStrengthPopup = {
  namespaced: true,
  state: {
    isShowPassStrengthPopup: false,
    value: '',
    position: {},
  },
  getters: {
    isShowPassStrengthPopup: state => state.isShowPassStrengthPopup,
    value: state => state.value,
    position: state => state.position,
  },
  mutations: {
    isShowPassStrengthPopup(state, flag) {
      state.isShowPassStrengthPopup = flag;
    },
    setValue(state, value) {
      state.value = value;
    },
    setPosition(state, coords) {
      state.position = coords;
    },
  },
  actions: {
    show({ commit, state }, { value, el }) {
      const targetNodeCoords = el.getBoundingClientRect();

      commit('isShowPassStrengthPopup', true);
      commit('setValue', value);
      commit('setPosition', targetNodeCoords);
    },
    close({ commit }) {
      commit('isShowPassStrengthPopup', false);
      commit('setValue', '');
    },
  },
};

export default passStrengthPopup;
