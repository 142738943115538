<template>
  <div />
</template>

<script>
export default {
  props: ['value', 'options', 'placeholder'],

  mounted() {
    const component = this;
    const config = webix.copy({
      view: 'multiselectWithoutPoint',
      suggestCss: 'filterOptionSuggestIntegrationSlack',
      placeholder: this.placeholder,
      value: this.value,
      options: {
        body: {
          yCount: 6,
          data: this.options,
          template(item) {
            return `${item.value}`;
          }
        }
      },
      on: {
        onChange() {
          this.$scope.$emit('change', $$(component.webixId).getValue());
        },
        onFocus() {
          this.$scope.$emit('focus', component.webixId);
        }
      }
    });
    config.$scope = this;

    this.webixId = webix.ui(config, this.$el);
    $$(this.webixId).setValue(this.value);
  },
  destroyed() {
    webix.$$(this.webixId).destructor();
  }
};
</script>
