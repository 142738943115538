// views/newProject/_jiraProject.js
// An import of existing jira-project window

import app from '../../app';
import _ from '../../libs/lodash';
import jiraProjectFormView from './jiraProject';
import closeIcon from '../../svg/close_black.svg';
import routerHelper from '../../helpers/router';
import common from './common';

import importProjectIcon from '../../svg/projects_icons/import_project.svg';
import backIcon from '../../svg/projects_icons/back.svg';

const {
  helpers: { adjustPopupPosition },
  views: {
    headerTitleView, headerCloseBtnView, headerBackBtnView, spaceFillerView,
  },
  templates: { headerTitle, headerCloseBtn, headerBackBtn },
  uiHandlers: { onCloseBtnClick, onBackBtnClick },
  ids: { ID_VIEW_POPUP_HEADER, ID_VIEW_FORM_WINDOW },
} = common;

const ID_VIEW_JIRA_PROJ_WINDOW = 'jiraProjectLayout';

const webixUI = {
  view: 'window',
  id: ID_VIEW_JIRA_PROJ_WINDOW,
  css: 'create_project_layout',
  borderless: true,
  // header: false,
  head: false,
  modal: false,
  move: false,
  hidden: true,
  // unClosable: true,
  position: adjustPopupPosition,
  body: {
    rows: [{
      id: ID_VIEW_POPUP_HEADER,
      css: 'create_project_layout_header topHeaderShadow',
      height: 54,
      paddingY: 12,
      cols: [
        // headerBackBtnView(headerBackBtn, {
        //     icon: backIcon,
        //     value: __('back_btn')
        // }, onBackBtnClick),
        spaceFillerView(9),
        headerTitleView(headerTitle, {
          infoIcon: importProjectIcon,
          value: __('import_from_jira_project'),
        }),
        spaceFillerView(60),
        headerCloseBtnView(headerCloseBtn, {
          icon: closeIcon,
        }, onBackBtnClick),
        { width: 9 },
      ],
    }, {
      view: 'scrollview',
      autoheight: true,
      scroll: 'y',
      borderless: true,
      body: {
        rows: [{
          height: 20,
        }, {
          id: ID_VIEW_FORM_WINDOW,
          css: 'create_project_form',
          rows: [],
        }],
      },
    }],
  },
  on: {
    onHide: onPopupHide,
    onShow: onPopupShow,
  },
};

function onPopupHide() {
  Object.keys(_events).forEach(event => {
    app.off(_events[event].id);
  });
}

function onPopupShow() {
  Object.keys(_events).forEach(event => {
    _events[event].id = app.on(event, _events[event].fn);
  });
}

const _events = {
  'body:resize': {
    fn: data => {
      $$(ID_VIEW_JIRA_PROJ_WINDOW) && $$(ID_VIEW_JIRA_PROJ_WINDOW).resize();
    },
  },
  'leftSideBar:changedMode': {
    fn: () => {
      $$(ID_VIEW_JIRA_PROJ_WINDOW) && $$(ID_VIEW_JIRA_PROJ_WINDOW).resize();
    },
  },
  'tab:projectCreate': {
    fn: () => {
      // $$(ID_VIEW_JIRA_PROJ_WINDOW) && routerHelper.pushRoute({ name: 'app' });
    },
  },
};

function init() {
  $$(ID_VIEW_FORM_WINDOW).addView(
    _.isFunction(jiraProjectFormView.view)
      ? jiraProjectFormView.view()
      : jiraProjectFormView.view,
  );

  jiraProjectFormView.init();

  $$(ID_VIEW_JIRA_PROJ_WINDOW).show();
}

function cleanup() {
  $$(ID_VIEW_JIRA_PROJ_WINDOW).hide();
}

export {
  webixUI,
  init,
  cleanup,
};
