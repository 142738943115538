var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.input_search, "input-search"] },
    [
      _c("sprite", {
        class: _vm.$style.icon,
        attrs: { name: "search", color: "#B2B2B2" },
      }),
      _vm._v(" "),
      _c("vgp-input", {
        class: _vm.$style.input,
        attrs: {
          value: _vm.content,
          small: true,
          placeholder: _vm.placeholder,
          border: false,
          "gp-autotest-name": _vm.gpAutotestName,
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }