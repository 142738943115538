var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit_container", class: { add_mode: _vm.addMode } },
    [
      _c("div", { staticClass: "inputs" }, [
        _c(
          "div",
          {
            staticClass: "edit_project_msteams",
            class: { is_error: _vm.projectIsEmptyError },
          },
          [
            _vm.projectIsEmptyError
              ? _c("div", { staticClass: "is_error" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.locales(
                          "integration_slack_settings_page_table_input_error_cant_be_empty"
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("integration-select-vue", {
              attrs: {
                value: _vm.selectedInputs.projectId,
                options: _vm.projectOptions,
                placeholder: _vm.locales(
                  "integration_slack_settings_page_table_input_plh_project"
                ),
              },
              on: { focus: _vm.onFocusSelect, change: _vm.onChangeProjectId },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "channels_settings_container",
            class: { small_border: _vm.projectChannelConnection.smallBorder },
          },
          [
            _c("div", { staticClass: "channels_settings_inputs" }, [
              _c(
                "div",
                {
                  staticClass: "edit_team_msteams",
                  class: { is_error: _vm.teamIsEmptyError },
                },
                [
                  _vm.teamIsEmptyError
                    ? _c("div", { staticClass: "is_error" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.locales(
                                "integration_slack_settings_page_table_input_error_cant_be_empty"
                              )
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("integration-select-vue", {
                    key: _vm.onChangesFlag,
                    attrs: {
                      value: _vm.selectedInputs.teamId,
                      options: _vm.teamOptions,
                      placeholder: _vm.locales(
                        "integration_msteams_settings_page_table_input_plh_team"
                      ),
                    },
                    on: {
                      focus: _vm.onFocusSelect,
                      change: _vm.onChangeTeamId,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "edit_channel_msteams",
                  class: { is_error: _vm.channelIsEmptyError },
                },
                [
                  _vm.channelIsEmptyError
                    ? _c("div", { staticClass: "is_error" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.locales(
                                "integration_slack_settings_page_table_input_error_cant_be_empty"
                              )
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("integration-select-vue", {
                    key: _vm.onChangesFlag,
                    attrs: {
                      value: _vm.selectedInputs.channelId,
                      options: _vm.channelOptions,
                      placeholder: _vm.locales(
                        "integration_slack_settings_page_table_input_plh_channel"
                      ),
                      loading: _vm.isChannelsListLoading,
                    },
                    on: {
                      focus: _vm.onFocusSelect,
                      change: _vm.onChangeChannelId,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit_settings_msteams" },
                [
                  _c("integration-multiselect-vue", {
                    key: _vm.onChangesFlag,
                    attrs: {
                      value: _vm.selectedInputs.settings,
                      options: _vm.settingsOptions,
                      placeholder: _vm.locales(
                        "integration_slack_settings_page_table_input_plh_settings"
                      ),
                    },
                    on: {
                      focus: _vm.onFocusSelect,
                      change: _vm.onChangeSettings,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _vm.noAvailableChannels
                ? _c(
                    "div",
                    {
                      class: ["tooltip-gantt", _vm.$style.tooltip],
                      attrs: {
                        "data-key": _vm.locales(
                          "integration_msteams_settings_page_add_no_possible"
                        ),
                        "data-position": "top",
                        "data-x": -6,
                      },
                    },
                    [
                      _c("webix-ui", {
                        class: _vm.$style.tip,
                        attrs: { config: _vm.icons.tip },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "buttons_ok",
                      on: {
                        click: function ($event) {
                          return _vm.onOk(_vm.projectChannelConnection.id)
                        },
                      },
                    },
                    [_c("webix-ui", { attrs: { config: _vm.icons.okIcon } })],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "buttons_cancel",
                  on: {
                    click: function ($event) {
                      return _vm.onCancel()
                    },
                  },
                },
                [_c("webix-ui", { attrs: { config: _vm.icons.cancelIcon } })],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }