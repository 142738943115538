var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row-wrapper" }, [
    _c("div", { staticClass: "feature-name" }, [
      _c("div", { staticClass: "name" }, [
        _vm._v("\n      " + _vm._s(_vm.locales(_vm.feature.locale)) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "plans-access" },
      _vm._l(_vm.plans, function (plan, index) {
        return _c("table-cell", {
          key: index,
          attrs: { plan: plan, feature: _vm.feature },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }