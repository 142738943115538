<template>
  <div :class="[$style.role_title]">
    <dropdown-roles
      v-if="isOpen"
      :access-to-delete="accessToDelete"
      :access-to-edit="accessToEdit"
      :type="type"
      @onClick="toggleEdit"
      @onDelete="toggleFromDelete"
      @onClose="toggleOpen"
    />
    <core-popup
      v-if="isShowForm"
      :close-icon="true"
      :show="isShowForm"
      :title="locales(`delete_${type}_role_title`)"
      @onClickCancel="toggleFromDelete"
    >
      <template
        #body
      >
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <div :class="[$style.add_form, $style.form_max]">
            <div :class="[$style.form_line]">
              <p v-if="isRoleSet">
                {{ locales(`delete_role_${type}_info_description`) }}
              </p>
              <p v-else>
                {{ locales(`delete_not_set_role_${type}_info_description`) }}
              </p>
            </div>
            <div
              v-if="isRoleSet"
              :class="[$style.form_line]"
            >
              <div :class="[$style.delimiter]" />
            </div>
            <div
              v-if="isRoleSet"
              :class="[$style.form_line_15]"
            >
              <p>{{ locales(`delete_role_${type}_choose_description`) }}</p>
            </div>
            <vgp-label-slot
              v-if="isRoleSet"
              :label="locales(`change_new_role_${type}_form_list_inherit`)"
            >
              <template #body>
                <ValidationProvider
                  v-slot="{ errors }"
                  ref="roleIdValidationProvider"
                  rules="required"
                  vid="roleId"
                  :custom-messages="{ required: locales('error_required_field') }"
                >
                  <vgp-dropdown-select
                    ref="roleId"
                    v-model="roleId"
                    :gp-autotest-name="`gp_autotest_${type}_role_popup_delete_role_dropdown_select_change_role`"
                    :class="[$style.vgp_select]"
                    :highlight-active-state="false"
                    :show-selected-first="true"
                    :show-reset-btn="false"
                    :selected="setDefault"
                    :drop-list="defaultRolesSelect"
                    item-template="text"
                    :multiselect="false"
                    track-by-text="title"
                    class="dropdown"
                    :fixed-width="320"
                    :size="'autoWidth'"
                    :need-description="true"
                    :is-error="!!errors[0]"
                    @selected="changeSelectRole"
                  />
                  <span
                    v-if="errors[0]"
                    :class="[$style.error]"
                  >
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </template>
            </vgp-label-slot>
            <div :class="[$style.form_line_buttons]">
              <vgp-button
                :id="`gp_autotest_${type}_role_popup_delete_role_btn_cancel`"
                type="secondary"
                :small="true"
                :label="locales('role_delete_cancel_btn')"
                @onClick="toggleFromDelete"
              />
              <vgp-button
                :id="`gp_autotest_${type}_role_popup_delete_role_btn_delete`"
                type="destructive"
                :small="true"
                :label="locales('role_delete_ok_btn')"
                @onClick="onClickDeleteRole"
              />
            </div>
          </div>
        </ValidationObserver>
      </template>
    </core-popup>
    <!-- main view-->

    <div
      v-if="!showInput"
      :class="[$style.role_title_wrap, checkDefaultRole(name) || !accessToEdit || (isEditMode && (itemId !== editItem)) ? null : $style.hover]"
    >
      <span
        v-if="!checkDefaultRole(name)"
        :id="`gp_autotest_${type}_role_table_title_${ checkDefaultRole(name) ? name.toLowerCase() : 'custom_'+ itemId}`"
        v-on="checkDefaultRole(name) || !accessToEdit || (isEditMode && (itemId !== editItem)) ? {click: null} : {click : activateForm}"
      >
        <tooltip
          :hide="!checkNameLength(name)"
          :content="locales(`${replaceScriptTag(name)}`)"
          :class="[$style.tooltip_title]"
        >
          <template #body>
            {{ name }}
          </template>
        </tooltip>

      </span>
      <span
        v-else
        :id="`gp_autotest_${type}_role_table_title_${ checkDefaultRole(name) ? name.toLowerCase() : 'custom_'+ itemId}`"
      >
        <tooltip
          :hide="!checkNameLength(`${type}_${name}`)"
          :content="locales(`${type}_${name}`)"
          :class="[$style.tooltip_title]"
        >
          <template #body>
            {{ locales(`${type}_${name}`) }}
          </template>
        </tooltip>
      </span>
    </div>
    <div
      v-else
      :class="[$style.role_title_wrap_input]"
    >
      <vgp-input
        ref="input"
        v-model="name"
        :gp-autotest-name="'gp_autotest_' + type + '_role_table_title_input_' + itemId"
        :placeholder="name"
        :class="['public-url-form-input']"
        :small="true"
        @onBlur.prevent="changeInput"
        @onKeydown="keyDownInput"
      />
    </div>

    <tooltip
      v-if="checkDefaultRole(name)"
      :content="locales(`role_${type}_help_text_${name.toLowerCase()}`)"
      :class="[$style.tooltips]"
    >
      <template #body>
        <svg-sprite
          name="help"
          size="24"
          type="regular"
          color="#B2B2B2"
          :class="[$style.role_title_icon_help]"
        />
      </template>
    </tooltip>
    <icon-button
      v-else-if="accessToEditAndDelete && hasAccessSubscribe"
      :id="`gp_autotest_${type}_role_table_title_dots_${checkDefaultRole(name) ? name.toLowerCase() : 'custom_'+ itemId}`"
      :icon="{ name:'more-2', type:'bold', size: 24 }"
      :active="isOpen"
      :class="[(isEditMode && (itemId !== editItem)) ? $style.button_no_hover : '', $style.button_icon]"
      @onClick="onClickEdit"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import svgSprite from '$$vueCmp/VueIcon/svgSprite.vue';
import Tooltip from '$$vueCmp/tooltips/tooltip.vue';
import dropdownRoles from './dropdownRoles.vue';
import VgpDropdownSelect from '$$vueCmp/dropdownSelect/dropdownSelect.vue';
import corePopup from '$$vueCmp/popups/corePopup/corePopup.vue';
import VgpLabelSlot from '$$vueCmp/label/vgpLabelSlot.vue';
import IconButton from '../../../common/VueComponents/globalButton/withIcon/iconButton.vue';
import { checkPricingAccess } from '$$helpers/pricingHelper';
import rightsComponent from '../../../../components/rights';
import app from '../../../../libs/core';

export default {
  name: 'RoleTitle',
  components: {
    svgSprite,
    Tooltip,
    dropdownRoles,
    VgpDropdownSelect,
    corePopup,
    VgpLabelSlot,
    IconButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    itemId: {
      type: Number,
      required: true,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: 'account',
    },
    defaultType: {
      type: String,
      required: false,
    },
    accessToDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    accessToEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    accessToEditAndDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      roleId: null,
      editItem: null,
      isShowForm: false,
      locales: __,
      showInput: false,
      name: `${this.defaultType ? this.title.toLowerCase() : this.title}`,
      // name: `${this.title[0]}${this.title.slice(1).toLocaleLowerCase()}`,
      _,
    };
  },

  computed: {
    defaultRolesSelect() {
      let roles = [
        ...this.$store.getters[this.type === 'account' ? 'roles/getAccountRoles' : 'roles/getProjectRoles'].filter(item => !['OWNER'].includes(item.defaultType)).filter(item => item.id !== this.itemId),
      ];

      roles = roles.map(item => {
        const copy = { ...item };

        if (copy.defaultType) {
          copy.description = this.locales(`${this.type}_${copy.title.toLowerCase()}_description`);
          copy.title = this.locales(`${this.type}_${copy.title.toLowerCase()}`);
        } else {
          copy.description = this.locales(`${this.type}_custom_role_description`);
        }

        return copy;
      });

      return roles;
    },

    hasAccessSubscribe() {
      return checkPricingAccess('custom_permissions');
    },

    isRoleSet() {
      if (this.type === 'account') {
        return this.$store.getters['resourcesModel/getAllResources'].some(item => item.accountRoleId === this.itemId);
      }

      const items = [];

      this.$store.getters['resourcesModel/getAllResources'].forEach(item => {
        if (item.resourceProjects.some(o => o.projectRoleId === this.itemId)) {
          items.push(item);
        }
      });

      return items.length > 0;
    },

    setDefault() {
      let selectedItem = {};

      const getItems = [...this.$store.getters[this.type === 'account' ? 'roles/getAccountRoles' : 'roles/getProjectRoles'].filter(item => !['OWNER'].includes(item.defaultType))];

      getItems.forEach(item => {
        if (item.isDefault === 1 && this.roleId === null && item.id !== this.itemId) {
          selectedItem = { ...item };
          this.roleId = selectedItem.id;
        } else if (this.roleId === null && item.defaultType === 'MEMBER') {
          selectedItem = { ...item };
          this.roleId = selectedItem.id;
        } else if (item.id === this.roleId) {
          selectedItem = { ...item };
        }
      });

      if (selectedItem.defaultType) {
        selectedItem.description = this.locales(`${this.type}_${selectedItem.title.toLowerCase()}_description`);
        selectedItem.title = this.locales(`${this.type}_${selectedItem.title.toLowerCase()}`);
      } else {
        selectedItem.description = this.locales(`${this.type}_custom_role_description`);
      }

      return selectedItem;
    },

    isEditMode() {
      return this.$store.getters['roles/getEditModeStatus'];
    },
  },
  watch: {
    title(newVal) { // watch it
      this.name = newVal;
    },
  },

  methods: {
    activateForm() {
      this.showInput = !this.showInput;
      this.$nextTick(() => this.$refs.input.$refs.input_field_icons.focus());
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },

    changeSelectRole(data) {
      this.roleId = data[0].id;
    },

    onClickDeleteRole: _.debounce(function () {
      this.isShowForm = !this.isShowForm;
      this.submitForm();
    }, 1500, {
      leading: true,
    }),

    replaceScriptTag(data) {
      if (data) {
        return data.replace(/</ig, '&lt;').replace(/>/ig, '&gt;');
      }

      return data;
    },

    submitForm() {
      const currentRoleId = rightsComponent.account.getCurrentUserRole().id;

      this.$store.dispatch('roles/deleteRole', {
        type: this.type,
        roleId: this.roleId,
        id: this.itemId,
      }).then(() => {
        if (this.roleId) {
          this.$store.dispatch('resourcesModel/reloadData');
        }
        this.$toast.success(this.locales(`success_delete_${this.type}_role`));

        if (this.type === 'account' && currentRoleId === this.itemId) {
          app.trigger('reloadModal:show:selfRoleUpdate', 'account');
        }
      }).catch(() => {
        this.$toast.error(this.locales(`error_delete_${this.type}_role`));
      });
    },

    toggleFromDelete() {
      this.isOpen = false;
      this.isShowForm = !this.isShowForm;
      this.roleId = null;
    },

    toggleEdit() {
      this.isOpen = false;
      this.editItem = this.itemId;
      this.$store.dispatch('roles/setEditMode', {
        status: true,
        roleId: this.itemId,
        type: this.type,
      });
    },

    toggleEditMode() {
      this.isShowForm = !this.isShowForm;
    },

    keyDownInput(e) {
      if (e.keyCode === 13) {
        this.$refs.input.$refs.input_field_icons.blur();
      }
    },

    changeInput() {
      const validName = this.name.trim();

      if (this.name === this.title) {
        this.showInput = false;

        return;
      }

      if (!validName) {
        this.name = this.title;
        this.showInput = false;

        return;
      }

      this.$store.dispatch('roles/setRoleTitle', {
        title: validName,
        type: this.type,
        id: this.itemId,
      }).then(() => {
        this.showInput = false;
        this.$toast.success(this.locales(`success_update_${this.type}_role`));
      }).catch(() => {
        this.$toast.error(this.locales(`error_update_${this.type}_role`));
      });
    },

    checkDefaultRole(value) {
      if (value) {
        return ['admin', 'owner', 'member', 'member_edit', 'member_edit_cost', 'member_cost', 'member_own_tasks', 'commentator'].includes(value.toLowerCase()) && this.defaultType;
      }

      return null;
    },
    checkNameLength(value) {
      return value.length > 10;
    },

    onClickEdit() {
      if (!this.isEditMode) {
        this.toggleOpen();
      }

      return false;
    },
  },

};
</script>

<style module src="../../less/role.less"></style>
